import * as types from 'types/partnership';

export const isSubmittingReducer = (state = false, action) => {
  switch (action.type) {
    case types.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_REQUEST:
      return true;

    case types.CLEAR_PARTNERSHIP_ADDRESSES:
    case types.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_FAILURE:
    case types.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_SUCCESS:
      return false;

    default:
      return state;
  }
};

export default isSubmittingReducer;

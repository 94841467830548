import PropTypes from 'prop-types';
import React from 'react';

import { TooltipContentText } from 'components';

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

/**
 * The tooltip to display on the imported contact ("add them") button, in the
 * ContactsInteractionBar.
 * @param {ComponentProps} props
 * @param {string} props.ledgerName
 * @returns {StatelessComponent}
 */
const ImportedContactInfoTooltip = ({ ledgerName }) => (
  <p className="m-4 leading-5">
    <TooltipContentText>
      {`This will add the existing contact from ${ledgerName} to your ${PLATFORM_DISPLAY_SHORT_NAME} account.`}
    </TooltipContentText>
  </p>
);

ImportedContactInfoTooltip.propTypes = {
  ledgerName: PropTypes.string.isRequired,
};

export default ImportedContactInfoTooltip;

/**
 * @module actions/routines/tables/tables
 */

import { createRoutine } from 'redux-saga-routines';

import * as types from 'types/tables';

/**
 * @type {ReduxSagaRoutine}
 */
export const fetchItemStructureTablesRoutine = createRoutine(types.FETCH_ITEM_STRUCTURE_TABLES);

import React from 'react';
import { Text, TooltipMUIConditionalWrapper } from 'components';
import { CurrencyCodeUsd } from 'constants/currency';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { typography } from 'constants/styles';
import { TooltipPadding } from 'constants/tooltip';
const PreventInviteAndAddForInternationalVendorTooltip = ({ children, isShown, }) => (React.createElement(TooltipMUIConditionalWrapper, { tooltipProps: isShown
        ? {
            arrow: true,
            padding: TooltipPadding.SKINNY,
            title: (React.createElement(Text.Regular, { color: typography.TextColor.GREY_X_DARK, lineHeight: typography.TextLineHeight.MEDIUM, size: typography.TextSize.LEVEL_100 },
                `We cannot invite or add this vendor to ${PLATFORM_DISPLAY_SHORT_NAME} `,
                `because you haven't yet enabled payments to vendors using currencies other than ${CurrencyCodeUsd}. `,
                "Please speak to a Routable Customer Success Manager to enable this feature.")),
        }
        : {} }, children));
export default PreventInviteAndAddForInternationalVendorTooltip;

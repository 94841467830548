import { getAllBulkImportsRoutine } from 'actions/routines/bulkActions';

import { valueOrDefault } from 'helpers/utility';

const linksReducer = (state = {}, action) => {
  switch (action.type) {
    case getAllBulkImportsRoutine.SUCCESS:
      return valueOrDefault(action.payload.links, {});

    default:
      return state;
  }
};

export default linksReducer;

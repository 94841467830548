import React from 'react';

import { FieldWrapper } from '../FieldWrapper';

import ApiLookupBlockFieldBasic from './ApiLookupBlockField';
import CheckboxBlockFieldBasic from './CheckboxBlockField';
import CurrencyBlockFieldBasic from './CurrencyBlockField';
import CurrencyCodeBlockFieldBasic from './CurrencyCodeBlockField';
import DateBlockFieldBasic from './DateBlockField';
import LookupBlockFieldBasic from './LookupBlockField';
import NumberBlockFieldBasic from './NumberBlockField';
import PercentBlockFieldBasic from './PercentBlockField';
import PhoneBlockFieldBasic from './PhoneBlockField';
import PresentationalField, { PresentationalFieldBoolean } from './PresentationalField';
import StaticBlockFieldBasic from './StaticBlockField';
import TextareaBlockFieldBasic from './TextareaBlockField';
import TextBlockFieldBasic from './TextBlockField';

const ApiLookupBlockField = React.memo((props) => <FieldWrapper Component={ApiLookupBlockFieldBasic} {...props} />);
const CheckboxBlockField = React.memo((props) => <FieldWrapper Component={CheckboxBlockFieldBasic} {...props} />);
const CurrencyBlockField = React.memo((props) => <FieldWrapper Component={CurrencyBlockFieldBasic} {...props} />);
const CurrencyCodeBlockField = React.memo((props) => (
  <FieldWrapper Component={CurrencyCodeBlockFieldBasic} {...props} />
));
const DateBlockField = React.memo((props) => <FieldWrapper Component={DateBlockFieldBasic} {...props} />);
const LookupBlockField = React.memo((props) => <FieldWrapper Component={LookupBlockFieldBasic} {...props} />);
const NumberBlockField = React.memo((props) => <FieldWrapper Component={NumberBlockFieldBasic} {...props} />);
const PercentBlockField = React.memo((props) => <FieldWrapper Component={PercentBlockFieldBasic} {...props} />);
const PhoneBlockField = React.memo((props) => <FieldWrapper Component={PhoneBlockFieldBasic} {...props} />);
const StaticBlockField = React.memo((props) => <FieldWrapper Component={StaticBlockFieldBasic} {...props} />);
const TextareaBlockField = React.memo((props) => <FieldWrapper Component={TextareaBlockFieldBasic} {...props} />);
const TextBlockField = React.memo((props) => <FieldWrapper Component={TextBlockFieldBasic} {...props} />);

export {
  ApiLookupBlockField,
  CheckboxBlockField,
  CurrencyBlockField,
  CurrencyCodeBlockField,
  DateBlockField,
  LookupBlockField,
  NumberBlockField,
  PercentBlockField,
  PhoneBlockField,
  PresentationalField,
  PresentationalFieldBoolean,
  StaticBlockField,
  TextareaBlockField,
  TextBlockField,
};

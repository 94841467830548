import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { DebouncedSelectField } from 'components';

import * as propTypes from 'constants/propTypes';
import { FieldDebounceDelay } from 'constants/ui';

import { handleGetOption } from 'helpers/select';
import { returnFullOptionObjectAsValueForSelects } from 'helpers/selects';

import { useScrollAwareSelectField, useGetTableFieldProps } from 'modules/table/hooks';

/**
 * Allow users to input a search value to find a set of options from the server, to be rendered in a table cell.
 * @param {ComponentProps} props
 * @param {Object} props.getIsDisabled
 * @param {Object} props.getIsRequired
 * @param {string} props.lookupOptionLabelKey
 * @param {string} props.lookupOptionValueKey
 * @param {function} props.onLoadOptions
 * @param {Function|Function[]} props.validate
 * @param {RestOfProps} props.rest
 * @returns {StatelessComponent}
 */
const ApiLookupTableField = ({
  defaultOptions,
  formValues,
  getIsDisabled,
  getIsRequired,
  horizontalScrollGroupRef,
  label,
  lookupOptionLabelKey,
  lookupOptionValueKey,
  name,
  onLoadOptions,
  rowIdx,
  sectionPath,
  validate,
}) => {
  const {
    menuPortalStyles: menuStyles,
    menuPortalTarget,
    onInputFocus: onFocus,
    selectFieldRef: setWrapperRef,
  } = useScrollAwareSelectField(horizontalScrollGroupRef);

  const fieldPath = `${sectionPath}.${rowIdx}`;
  const { isDisabled, isRequired, validators } = useGetTableFieldProps({
    formValues,
    getIsDisabled,
    getIsRequired,
    fieldPath,
    rowIdx,
    validate,
  });

  return (
    <Field
      className="ledger-form-field create-item-field"
      closeOnSelect
      component={DebouncedSelectField}
      debounceDelay={FieldDebounceDelay.SELECT}
      defaultOptions={defaultOptions}
      getOptionLabel={handleGetOption(lookupOptionLabelKey)}
      getOptionValue={handleGetOption(lookupOptionValueKey)}
      hidePlaceholder
      isAsync
      isClearable
      isCreatable={false}
      isDebounced
      isDisabled={isDisabled}
      isRequired={isRequired}
      label={label}
      loadOptions={onLoadOptions}
      matchProp={lookupOptionLabelKey}
      name={name}
      noResultsText="No matches found"
      onBlurResetsInput={false}
      onCloseResetsInput={false}
      onFocus={onFocus}
      onSelectResetsInput={false}
      parseValue={returnFullOptionObjectAsValueForSelects}
      placeholder={
        <>
          Select
          {isRequired && <span className="asterisk">{' *'}</span>}
        </>
      }
      props={{
        maxMenuHeight: 200,
        menuPortalTarget,
        menuPlacement: 'bottom',
        setWrapperRef,
        styles: { menuPortal: menuStyles },
      }}
      validate={validators}
      valueKey={lookupOptionValueKey}
    />
  );
};

ApiLookupTableField.propTypes = {
  ...propTypes.tableFieldSharedPropTypes,
  onLoadOptions: PropTypes.func.isRequired,
  defaultOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

ApiLookupTableField.defaultProps = {
  ...propTypes.tableFieldSharedDefaultProps,
  defaultOptions: [],
};

export default React.memo(ApiLookupTableField);

import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleInviteTeamMemberModal, toggleViewTeamMembersModal } from 'actions/signUpFlow';
import { ButtonV2, IconNames, TooltipMUIConditionalWrapper } from 'components';
import { ButtonSize } from 'constants/button';
import { colors } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';
import { Intent } from 'constants/ui';
import { MEMBERSHIPS_LIMIT } from 'modules/signup-v3/constants/memberships';
import { isInviteDisabled } from '../../helpers/memberships';
import { InvitedTeamMembersContainer, ButtonContainer, Title, Description, InvitedTeamMembersHeadingContainer, } from './InvitedTeamMembers.styles';
import {} from './InvitedTeamMembers.types';
const InvitedTeamMembers = ({ currentCompanyMembers, pendingMembershipInvites, }) => {
    const dispatch = useDispatch();
    const invitedMembersNumber = currentCompanyMembers.length + pendingMembershipInvites.length;
    const tooltipProps = isInviteDisabled(invitedMembersNumber)
        ? {
            arrow: true,
            padding: TooltipPadding.LARGE,
            placement: TooltipPlacement.TOP,
            style: {
                fontSize: '.75rem',
            },
            title: `You've already invited the maximum of ${MEMBERSHIPS_LIMIT - 1} people. To invite another member of your team, revoke an existing invite first.`,
        }
        : undefined;
    return (React.createElement(InvitedTeamMembersContainer, null,
        React.createElement(InvitedTeamMembersHeadingContainer, null,
            React.createElement(Title, null, "Don't have this information?"),
            React.createElement(Description, null, "Invite team members who can help you fill out the details.")),
        React.createElement(ButtonContainer, null,
            React.createElement(ButtonV2, { intent: Intent.NEUTRAL, onClick: () => dispatch(toggleViewTeamMembersModal()), size: ButtonSize.MEDIUM, type: "button" }, "View team members"),
            React.createElement(TooltipMUIConditionalWrapper, { tooltipProps: tooltipProps },
                React.createElement(ButtonV2, { intent: Intent.NEUTRAL, isDisabled: isInviteDisabled(invitedMembersNumber), leftIconClassName: "margin-right--xm", leftIconColor: colors.colorGreyXDarkHex, leftIconName: IconNames.PLUS, onClick: () => dispatch(toggleInviteTeamMemberModal()), size: ButtonSize.MEDIUM, type: "button" }, "Invite a team member")))));
};
export default InvitedTeamMembers;

import queryString from 'query-string';
import { useLocation, useParams } from 'react-router-dom';
import {} from './useRoutableParams.types';
export const useRoutableParams = () => {
    const { search } = useLocation();
    const pathParams = useParams();
    const queryParams = queryString.parse(search);
    return {
        ...pathParams,
        ...queryParams,
    };
};

import { ExternalDepartmentTypeText } from 'constants/department';

import { isExternalPathAccept, isExternalPathTax } from 'helpers/external';

export { shouldShowProgressBar } from './shouldShowProgressBar';
export { default as submitUpdatePaymentMethod } from './submitUpdatePaymentMethod';

/**
 * Get department name by window location path
 * @return {string} - department
 */
export const getDepartmentByPath = () => {
  const isPayablesDepartment = isExternalPathAccept(window.location) || isExternalPathTax(window.location);

  if (isPayablesDepartment) {
    return ExternalDepartmentTypeText.PAYABLES;
  }

  return ExternalDepartmentTypeText.RECEIVABLES;
};

import PropTypes from 'prop-types';
import React from 'react';

import { IconNames } from 'components/icon';
import { LinkNewPage } from 'components/link';
import { TooltipIcon } from 'components/tooltip';

import { colors } from 'constants/styles';

const LedgerStatusLinkIcon = ({ ledgerLink, ledgerName }) => (
  <LinkNewPage className="relation--right-icon hover-primary" href={ledgerLink}>
    <TooltipIcon
      content={`View on ${ledgerName}.`}
      icon={IconNames.LOG_IN}
      iconColor={colors.colorMainJordan}
      tooltipProps={{
        statelessProps: { className: 'tooltip--item-block' },
      }}
    />
  </LinkNewPage>
);

LedgerStatusLinkIcon.propTypes = {
  ledgerLink: PropTypes.string.isRequired,
  ledgerName: PropTypes.string.isRequired,
};

export default LedgerStatusLinkIcon;

export const ResponseParsing = {
    NONE: 0,
    JSON_API: 1,
    RELATED_DATA: 2,
};
export const hashObject = (queryKey) => JSON.stringify(queryKey, (_, val) => typeof val === 'object' && !Array.isArray(val)
    ? Object.keys(val)
        .sort()
        .reduce((result, key) => {
        result[key] = val[key];
        return result;
    }, {})
    : val);

import { combineReducers } from 'redux';
import allIdsReducer from './allIdsReducer';
import byIdReducer from './byIdReducer';
import errorReducer from './errorReducer';
import isFetchingReducer from './isFetchingReducer';
const rootReducer = combineReducers({
    allIds: allIdsReducer,
    byId: byIdReducer,
    errors: errorReducer,
    isFetching: isFetchingReducer,
});
export default rootReducer;

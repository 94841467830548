import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { Form, FormControl, InputField, InternationalPhoneInput, ReduxFormFieldRenderInput } from 'components';

import { externalFormFields } from 'constants/formFields';
import { formNamesExternal } from 'constants/forms';
import { formStyles } from 'constants/styles';

import {
  internationalPhoneNumberValidator,
  phoneNumberLengthValidator,
  phoneNumberRequiredValidator,
  requiredValidator,
} from 'helpers/fieldValidation';

const formName = formNamesExternal.PARTNER_COMPANY_CONTACT;

/**
 * Renders form fields of PartnerCompanyContactForm, used in external
 * flow on-boarding process
 * @param {ComponentProps} props
 * @param {User} props.currentUser
 * @param {function} props.handleSubmit
 * @param {function} props.onFormSubmit
 * @return {StatelessComponent}
 */
const PartnerCompanyContactForm = ({ currentUser, handleSubmit, onFormSubmit, partnership }) => (
  <Form className="partner-company-contact-form" id={formName} name={formName} onSubmit={handleSubmit(onFormSubmit)}>
    <FormControl>
      <InputField
        className={formStyles.field.xl.left}
        isLocked
        name="email"
        placeholder="Your email"
        type="text"
        value={currentUser.email}
      />

      <Field
        className={formStyles.field.xl.right}
        component={InternationalPhoneInput}
        defaultCountry={partnership.countryCodeCompany}
        name={externalFormFields.MEMBERSHIP_PHONE_NUMBER}
        placeholder="Phone Number"
        validate={[phoneNumberRequiredValidator, phoneNumberLengthValidator, internationalPhoneNumberValidator]}
      />
    </FormControl>

    <FormControl>
      <Field
        component={ReduxFormFieldRenderInput}
        name={externalFormFields.MEMBERSHIP_USER_FIRST_NAME}
        props={{
          className: formStyles.field.xl.left,
          placeholder: 'First name',
          type: 'text',
        }}
        validate={requiredValidator}
      />

      <Field
        component={ReduxFormFieldRenderInput}
        name={externalFormFields.MEMBERSHIP_USER_LAST_NAME}
        props={{
          className: formStyles.field.xl.right,
          placeholder: 'Last name',
          type: 'text',
        }}
        validate={requiredValidator}
      />
    </FormControl>
  </Form>
);

PartnerCompanyContactForm.propTypes = {
  currentUser: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    membership: PropTypes.shape({
      user: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      phoneNumber: PropTypes.shape({}),
    }),
  }).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

export default PartnerCompanyContactForm;

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { PLATFORM_EMAILS } from 'constants/platform';

import ExternalPromptHeader from 'modules/external/externalPrompt/global/presenters/ExternalPromptHeader';
import ExternalPromptInstructions from 'modules/external/externalPrompt/global/presenters/ExternalPromptInstructions';
import NextSteps from 'modules/external/externalPrompt/global/presenters/NextSteps';

import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

const ExternalPromptCustomerSingleItemCanceled = ({ partnership }) => {
  const instructions = [
    {
      id: '1',
      key: '1',
      text: 'You should have received an email confirming this invoice cancellation.',
    },
    {
      id: '2',
      key: '2',
      text:
        'If you have any further questions, ' +
        `please contact ${partnership.name} by responding to the above mentioned email.`,
    },
    {
      id: '3',
      key: '3',
      text: `Never received an email? Please contact ${PLATFORM_EMAILS.SUPPORT}.`,
    },
  ];

  return (
    <React.Fragment>
      <ExternalPromptHeader showRequester text={`This invoice from ${partnership.name} has been canceled`} />

      <NextSteps text="Additional info:" />

      <ExternalPromptInstructions instructions={instructions} />
    </React.Fragment>
  );
};

ExternalPromptCustomerSingleItemCanceled.propTypes = {
  partnership: PropTypes.shape().isRequired,
};

const mapStateToProps = createStructuredSelector({
  partnership: partnershipFromQuerySelector,
});

export { ExternalPromptCustomerSingleItemCanceled };
export default connect(mapStateToProps)(ExternalPromptCustomerSingleItemCanceled);

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { currentMemberPermissionsSetSelector } from 'queries/membershipPermissionCompoundSelectors';

export const mapStateToProps = createStructuredSelector({
  currentMemberPermissionSet: currentMemberPermissionsSetSelector,
});

/**
 * An HOC which adds the currentMemberPermissionSet for the current user to the component.
 *
 * @param {AnyComponent} Component
 * @returns {AnyComponent} Decorated with currentMemberPermissionSet
 */
const withCurrentMemberPermissionSet = (Component) => {
  /**
   * Component which we connect to state to add the permissions held by the current membership to the wrapped component.
   *
   * @param {ComponentProps} props
   * @param {CurrentMemberPermissionSet} props.currentMemberPermissionSet
   * @param {RestOfProps} props.rest
   * @returns {AnyComponent}
   */
  const ComponentWithPermissionSet = ({ currentMemberPermissionSet, ...rest }) => (
    <Component currentMemberPermissionSet={currentMemberPermissionSet} {...rest} />
  );

  ComponentWithPermissionSet.propTypes = {
    currentMemberPermissionSet: PropTypes.instanceOf(Set).isRequired,
  };

  // HOCs don't forward refs on their own. This is problematic for this HOC, because if the permissions don't match
  // we may wrap one of the children in the Component with a tooltip..and the tooltip will need this ref.
  return React.forwardRef((props, ref) => <ComponentWithPermissionSet setRef={ref} {...props} />);
};

const enhance = compose(connect(mapStateToProps), withCurrentMemberPermissionSet);

export default (component) => enhance(component);

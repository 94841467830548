import { createIsDoingReducer } from 'store/redux';

import {
  GET_CURRENT_COMPANY_FAILURE,
  GET_CURRENT_COMPANY_REQUEST,
  GET_CURRENT_COMPANY_SUCCESS,
} from 'types/currentCompany';

const fetchReducer = createIsDoingReducer(
  [GET_CURRENT_COMPANY_REQUEST],
  [GET_CURRENT_COMPANY_FAILURE, GET_CURRENT_COMPANY_SUCCESS],
);

export default fetchReducer;

import { PartnershipRequestTypes } from 'constants/partnershipRequest';
import { isExternalFlowStepNameConfirmation } from 'helpers/external';
export const shouldRedirectToConfirmationUrl = ({ currentStep, isLoading, partnershipFundingStateHasDateCompleted, redirectUrl, requestType, }) => {
    const isConfirmationStep = isExternalFlowStepNameConfirmation(currentStep);
    if (isLoading || !redirectUrl || !isConfirmationStep || !requestType) {
        return false;
    }
    const isRequestTypeTaxForm = requestType === PartnershipRequestTypes.REQUEST_W8_FORM || requestType === PartnershipRequestTypes.REQUEST_W9_FORM;
    return isRequestTypeTaxForm ? true : !!partnershipFundingStateHasDateCompleted;
};

import React from 'react';

import MicroDepositsNeedVerificationHint from '../MicroDepositsNeedVerificationHint';

/**
 * Renders micro deposits need verification hint with additional callout
 * to connect bank account.
 * @return {StatelessComponent}
 */
const MicroDepositsNeedVerificationWithConnectHint = () => (
  <MicroDepositsNeedVerificationHint>
    Enter your micro-deposits once they arrive to verify this payment method. You may also select another payment
    method, or connect to your bank account to continue.
  </MicroDepositsNeedVerificationHint>
);

export default MicroDepositsNeedVerificationWithConnectHint;

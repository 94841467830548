import React from 'react';
import { Link } from 'react-router-dom';
import { Text, WhiteSpace } from 'components/text';
import { getCompanyRouteByPartnershipId } from 'helpers/routeHelpers';
const CantRemoveVendorIndividualTooltipContent = ({ partnershipId }) => (React.createElement(Text.Regular, null,
    `This contact can't be removed since the vendor is an individual. Instead you can`,
    React.createElement(WhiteSpace, null),
    React.createElement(Link, { className: "primary", to: { pathname: getCompanyRouteByPartnershipId(partnershipId) } }, "edit the contact"),
    "."));
export default CantRemoveVendorIndividualTooltipContent;

import React from 'react';
import { useSelector } from 'react-redux';
import { isFundingAccountEligibleForRTP } from 'helpers/funding';
import { isPaymentDeliveryOptionRTP } from 'helpers/paymentMethods';
import { currentCompanySettingsIsInternationalPaymentsEnabledSelector } from 'selectors/currentCompanySelectors';
import { useShouldDisableInternationalFundingAccount } from './PartnerPaymentMethodSelectionOption.hooks';
import { PartnerPaymentMethodSelectionOptionBankRadio } from './PartnerPaymentMethodSelectionOptionBankRadio';
import { PartnerPaymentMethodSelectionOptionPaymentMethod } from './PartnerPaymentMethodSelectionOptionPaymentMethod';
const PartnerPaymentMethodSelectionOption = ({ currencyCodeReceiver, partnerFundingAccount, fundingAccountsById, fundingInfoAddressesById, input, onSelectChangeToPrimary, onSelectViewAccountDetail, partnership, paymentDeliveryMethod, paymentDeliveryOption, }) => {
    const isDisabledByCurrencyCode = useShouldDisableInternationalFundingAccount(partnerFundingAccount.currencyCode, currencyCodeReceiver);
    const isInternationalPaymentsEnabled = useSelector(currentCompanySettingsIsInternationalPaymentsEnabledSelector);
    const isInputDisabled = isDisabledByCurrencyCode ||
        (isPaymentDeliveryOptionRTP(paymentDeliveryOption) && !isFundingAccountEligibleForRTP(partnerFundingAccount));
    if (isInternationalPaymentsEnabled) {
        return (React.createElement(PartnerPaymentMethodSelectionOptionPaymentMethod, { className: "margin-bottom--m", fundingAccount: fundingAccountsById[partnerFundingAccount.fundingAccount], input: {
                ...input,
                disabled: isInputDisabled,
            }, isSelectable: true, partnership: partnership, showFundingAccountId: true }));
    }
    return (React.createElement(PartnerPaymentMethodSelectionOptionBankRadio, { fundingAccountsById: fundingAccountsById, fundingInfoAddressesById: fundingInfoAddressesById, input: input, onSelectChangeToPrimary: onSelectChangeToPrimary, onSelectViewAccountDetail: onSelectViewAccountDetail, partnerFundingAccount: partnerFundingAccount, partnership: partnership, paymentDeliveryMethod: paymentDeliveryMethod, paymentDeliveryOption: paymentDeliveryOption }));
};
export default PartnerPaymentMethodSelectionOption;

import React from 'react';
import StaticPhoneNumberText from 'components/text/StaticPhoneNumberText';
import { anyValues } from 'helpers/utility';
import {} from '../ContactCellsTypes.types';
const ContactPhoneNumberCell = ({ rowData }) => {
    const { phoneNumber } = rowData;
    if (anyValues(phoneNumber)) {
        return React.createElement(StaticPhoneNumberText, { hasFlag: true, phoneNumber: phoneNumber });
    }
    return null;
};
export default ContactPhoneNumberCell;

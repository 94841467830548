import clsx from 'clsx';
import React from 'react';
import { formatNumber } from 'helpers/numbers';
import { isNum } from 'helpers/utility';
import TableCellTextWithSubtext from '../TableCellTextWithSubtext';
import {} from './RenderTextCell.types';
const RenderTextCell = ({ className, dataKey, rowData }) => {
    const text = isNum(rowData[dataKey]) ? formatNumber(rowData[dataKey]) : rowData[dataKey];
    return React.createElement(TableCellTextWithSubtext, { className: clsx(className, { empty: !text }), text: text });
};
export default RenderTextCell;

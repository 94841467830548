import SSO_LOGIN from 'global/icons/ic-two-factor-authentication.svg';
import googleLetterLogo from 'global/icons/logo_google_letter.svg';
import googleLetterLogoWhite from 'global/icons/logo_google_letter_white.svg';
import DOCUMENT_APPROVED_IMAGE from 'global/images/signup/document_approved.svg';
import DOCUMENT_APPROVED_IMAGE_CHAIN from 'global/images/signup/document_approved_chain.svg';
import ID_BLUR from 'global/images/signup/id_blur.jpg';
import ID_FAIL from 'global/images/signup/id_fail.jpg';
import ID_SUCCESS from 'global/images/signup/id_success.jpg';
import LICENSE_BLUR from 'global/images/signup/license_blur.jpg';
import LICENSE_FAIL from 'global/images/signup/license_fail.jpg';
import LICENSE_SUCCESS from 'global/images/signup/license_success.jpg';
import PASSPORT_BLUR from 'global/images/signup/passport_blur.jpg';
import PASSPORT_FAIL from 'global/images/signup/passport_fail.jpg';
import PASSPORT_SUCCESS from 'global/images/signup/passport_success.jpg';
import ACCOUNT_CREATION_HERO from 'global/images/signup/signup_account_creation_hero.svg';

import { S3_URL } from 'services/api';

export const googleLetterLogoMap = {
  light: googleLetterLogo,
  dark: googleLetterLogoWhite,
};

export const BOUNCED_EMAIL_IMAGE = `${S3_URL}/images/errors/error-bounced-email.svg`;

export const ERROR_UNKNOWN_IMAGE = `${S3_URL}/images/errors/error-unknown.svg`;

export const RESTRICTED_CONTENT_IMAGE = `${S3_URL}/access/RestrictedContent.svg`;

export const ROUTABLE_NETSUITE_CONNECT_WHITE = `${S3_URL}/images/_global/integrations/Routable-Netsuite-Connect-White.svg`;

export const ROUTABLE_NETSUITE_SYNC = `${S3_URL}/images/_global/integrations/Routable-NetSuite-Resync.svg`;

export const ROUTABLE_QBO_SYNC = `${S3_URL}/images/_global/integrations/Routable-QBO-Resync.svg`;

export const ROUTABLE_SAGE_INTACCT_CONNECT = `${S3_URL}/images/_global/integrations/Routable-Sage-Intacct-Connect.svg`;

export const ROUTABLE_SAGE_INTACCT_SYNC = `${S3_URL}/images/_global/integrations/Routable-Sage-Intacct-Resync.svg`;

export const ROUTABLE_XERO_SYNC = `${S3_URL}/images/_global/integrations/Routable-Xero-Resync.svg`;

export const TABLE_CREATE_ITEM = `${S3_URL}/images/tables/Table-create-item.svg`;

export const TABLE_EMPTY_APPROVALS_STATE = `${S3_URL}/images/tables/Table-empty-approvals-state.svg`;

export const TABLE_EMPTY_STATE = `${S3_URL}/images/tables/Table-empty-state.svg`;

export const TABLE_EMPTY_STATE__GROSS_DS = `${S3_URL}/images/tables/table-empty-state--gross-ds.svg`;

export const TABLE_EMPTY_STATE_WITHOUT_BORDERS = `${S3_URL}/images/tables/Table-empty-state-without-borders.svg`;

export const TEN_NINETY_NINE_ILLUSTRATION = `${S3_URL}/images/external-tax/1099-illustration.svg`;

export const PROCESSING_HERO = `${S3_URL}/images/signup-flow/processing_hero_standalone.svg`;

export const COMPLIANCE_CHECKS_PROMO_IMAGE = `${S3_URL}/images/promo/compliance-checks.svg`;

export {
  ACCOUNT_CREATION_HERO,
  ID_SUCCESS,
  ID_FAIL,
  ID_BLUR,
  LICENSE_SUCCESS,
  LICENSE_FAIL,
  LICENSE_BLUR,
  PASSPORT_SUCCESS,
  PASSPORT_BLUR,
  PASSPORT_FAIL,
  DOCUMENT_APPROVED_IMAGE,
  DOCUMENT_APPROVED_IMAGE_CHAIN,
  SSO_LOGIN,
};

export const QUICKSWITCH_IMAGE = `${S3_URL}/images/quickswitch/quickswitch.svg`;

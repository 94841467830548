import { IconNames } from 'components/icon';
export const fieldMappings = {
    dateDue: 'main.general.date_due',
    dateIssued: 'main.general.date_issued',
    invoiceNumber: 'main.general.invoice_number',
    currencyCode: 'main.general.bill_currency',
};
export const AttachmentStatuses = {
    PROCESSING: 'processing',
    SCANNING: 'scanning',
    DUPLICATE: 'duplicate',
    UNKNOWN: 'unknown',
    WARNING: 'warning',
    FINISHED: 'finished',
    DONE: 'done',
    NOT_STARTED: 'not_started',
    UPLOADED: 'uploaded',
    ERROR: 'error',
    SKIPPED: 'skipped',
    NO_LATEST_ANNOTATION: 'no_latest_annotation_status',
};
export const AttachmentScanningStatuses = {
    [AttachmentStatuses.SCANNING]: true,
    [AttachmentStatuses.NOT_STARTED]: true,
    [AttachmentStatuses.PROCESSING]: true,
};
export const AttachmentStatusIconTitles = {
    [AttachmentStatuses.PROCESSING]: 'Scanning attachment',
    [AttachmentStatuses.SCANNING]: 'Scanning attachment',
    [AttachmentStatuses.UPLOADED]: 'Scanning attachment',
    [AttachmentStatuses.NO_LATEST_ANNOTATION]: 'Scanning attachment',
    [AttachmentStatuses.DUPLICATE]: 'Attachment flagged as a duplicate',
    [AttachmentStatuses.UNKNOWN]: 'Unsupported attachment',
    [AttachmentStatuses.WARNING]: 'Issue scanning attachment',
    [AttachmentStatuses.ERROR]: 'Issue scanning attachment',
    [AttachmentStatuses.DONE]: 'Scanning complete',
    [AttachmentStatuses.FINISHED]: 'Scanning complete',
    [AttachmentStatuses.SKIPPED]: 'Scanning complete',
};
export const AttachmentStatusIconColors = {
    [AttachmentStatuses.PROCESSING]: '#3C65F1',
    [AttachmentStatuses.SCANNING]: '#3C65F1',
    [AttachmentStatuses.UPLOADED]: '#3C65F1',
    [AttachmentStatuses.NO_LATEST_ANNOTATION]: '#3C65F1',
    [AttachmentStatuses.DUPLICATE]: '#3C65F1',
    [AttachmentStatuses.UNKNOWN]: '#E49318',
    [AttachmentStatuses.WARNING]: '#E49318',
    [AttachmentStatuses.ERROR]: '#eb6345',
    [AttachmentStatuses.DONE]: '#76A86A',
    [AttachmentStatuses.FINISHED]: '#76A86A',
    [AttachmentStatuses.SKIPPED]: '#76A86A',
};
export const AttachmentStatusIconNames = {
    [AttachmentStatuses.PROCESSING]: IconNames.CIRCLE,
    [AttachmentStatuses.SCANNING]: IconNames.CIRCLE,
    [AttachmentStatuses.UPLOADED]: IconNames.CIRCLE,
    [AttachmentStatuses.NO_LATEST_ANNOTATION]: IconNames.CIRCLE,
    [AttachmentStatuses.DUPLICATE]: IconNames.DUPLICATE,
    [AttachmentStatuses.UNKNOWN]: IconNames.HEART_BROKEN,
    [AttachmentStatuses.WARNING]: IconNames.ISSUE,
    [AttachmentStatuses.ERROR]: IconNames.ERROR_OUTLINE,
    [AttachmentStatuses.DONE]: IconNames.CONFIRM,
    [AttachmentStatuses.FINISHED]: IconNames.CONFIRM,
    [AttachmentStatuses.SKIPPED]: IconNames.CONFIRM,
};
export const ATTACHMENT_DEFAULT_FILENAME = 'email.txt';

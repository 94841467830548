import { ItemKinds } from 'constants/item';

import { getModalReducerOpenAction } from 'store/redux';

import { MODAL_CREATE_ITEM_CLOSE, MODAL_CREATE_ITEM_OPEN } from 'types/modals';

export const initialState = {
  kind: ItemKinds.PAYABLE,
  isBillView: false,
  open: false,
};

// Reducer
const createItemModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_CREATE_ITEM_CLOSE:
      return initialState;

    case MODAL_CREATE_ITEM_OPEN:
      return getModalReducerOpenAction(state, action);

    default:
      return state;
  }
};

export default createItemModalReducer;

import { createSelector } from 'reselect';

import { modalNameVerifyFundingAccount } from 'constants/modals';

import { modalsSelector } from 'selectors/modalsSelector';

export const verifyFundingAccountModalSelector = createSelector(
  [modalsSelector],
  (modals) => modals[modalNameVerifyFundingAccount] || {},
);

export const vfaModalFundingAccountIdSelector = createSelector(
  [verifyFundingAccountModalSelector],
  (verifyFundingAccount) => verifyFundingAccount.fundingAccountId,
);

export const vfaModalFundingSourceIdSelector = createSelector(
  [verifyFundingAccountModalSelector],
  (verifyFundingAccount) => verifyFundingAccount.fundingSourceId,
);

export const vfaModalIsFetchingSelector = createSelector(
  [verifyFundingAccountModalSelector],
  (verifyFundingAccount) => verifyFundingAccount.isFetching,
);

export const vfaModalLastSubmittedSelector = createSelector(
  [verifyFundingAccountModalSelector],
  (verifyFundingAccount) => verifyFundingAccount.lastSubmitted,
);

import { isAmountNegative } from 'helpers/amounts';

/**
 * Get clean form values for balance transaction form.
 * @function
 * @param {number} balanceAmount
 * @param {string} balanceAccountId
 * @param {BalanceTransactionDirection} direction
 * @return {Object}
 */
export const getBalanceTransactionFormInitialValues = (balanceAmount, balanceAccountId, direction) => {
  const isNegative = isAmountNegative(balanceAmount);

  return {
    // if negative balance, we have to deposit exactly enough
    // to correct the account
    amount: isNegative ? Math.abs(balanceAmount) : 0,
    isCorrection: isNegative,
    fundingAccount: { id: null },
    id: balanceAccountId,
    kind: direction.getBalanceTransactionKind(),
  };
};

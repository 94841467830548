export const FETCH_BALANCE_TRANSACTIONS_REQUEST = 'FETCH_BALANCE_TRANSACTIONS_REQUEST';

export const FETCH_ITEM_TRANSACTIONS_FAILURE = 'FETCH_ITEM_TRANSACTIONS_FAILURE';
export const FETCH_ITEM_TRANSACTIONS_REQUEST = 'FETCH_ITEM_TRANSACTIONS_REQUEST';
export const FETCH_ITEM_TRANSACTIONS_SUCCESS = 'FETCH_ITEM_TRANSACTIONS_SUCCESS';

export const FETCH_TRANSACTIONS_FAILURE = 'FETCH_TRANSACTIONS_FAILURE';
export const FETCH_TRANSACTIONS_REQUEST = 'FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';

export const SUBMIT_TRANSACTION_PAYMENT_CONFIRMATION = 'SUBMIT_TRANSACTION_PAYMENT_CONFIRMATION';

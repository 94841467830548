import React from 'react';

import { BaseHint } from 'components/hintTypes';

import { Intent } from 'constants/ui';

import { multiplePartnershipHintText } from 'modules/external/ExternalCollectTaxInfo/constants/text';

import ConfirmTaxForMultiplePartnershipsButton from '../ConfirmTaxForMultiplePartnershipsButton';
import ExplainMultiplePartnershipTaxConfirmation from '../ExplainMultiplePartnershipTaxConfirmation';
import WhyDoYouHaveMyTaxInfoHelpLink from '../WhyDoYouHaveMyTaxInfoHelpLink';

const ReconfirmTaxesHintWithSubmit = (props) => (
  <BaseHint
    className="margin-top--m"
    content={
      <React.Fragment>
        <ConfirmTaxForMultiplePartnershipsButton {...props} />
        <WhyDoYouHaveMyTaxInfoHelpLink />
      </React.Fragment>
    }
    contentProps={{
      className: 'reconfirm-taxes-hint margin-top--m',
    }}
    intent={Intent.INFO}
    multiline
    text={<ExplainMultiplePartnershipTaxConfirmation />}
    title={multiplePartnershipHintText.TITLE}
  />
);

export default ReconfirmTaxesHintWithSubmit;

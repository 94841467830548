import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

import { isFn, isString } from 'helpers/utility';

/**
 * HOC which extracts information from rowData (common in tables) and applies that information as props to the wrapped
 * component.
 * @param {FunctionComponent} Component
 * @param {Object} propGetters
 * @return {FunctionComponent}
 */
const withRowDataGetters = (Component, propGetters = {}) => {
  const ComponentWithRowData = (props) => {
    const { rowData, ...rest } = props;

    const propsFromRowData = Object.keys(propGetters).reduce((acc, prop) => {
      const lookup = propGetters[prop];

      let propDataFromRow;

      if (isFn(lookup)) {
        propDataFromRow = lookup(rowData);
      }

      if (isString(lookup)) {
        propDataFromRow = _get(rowData, lookup);
      }

      return {
        ...acc,
        [prop]: propDataFromRow,
      };
    }, {});

    return <Component {...rest} {...propsFromRowData} />;
  };

  ComponentWithRowData.propTypes = {
    rowData: PropTypes.shape().isRequired,
  };

  return ComponentWithRowData;
};

export default withRowDataGetters;

import React from 'react';
import { Text, WhiteSpace } from 'components/text';
import { getPartnershipType } from 'helpers/partnerships';
import { apostrophiseString } from 'helpers/stringHelpers';
import {} from './PaymentMethodRoutingNumberLockedTooltipContent.types';
const PaymentMethodRoutingNumberLockedTooltipContent = ({ partnership, }) => {
    const partnershipType = getPartnershipType(partnership);
    return (React.createElement(Text.Regular, { className: "payment-method-routing-number-locked-tooltip-content" },
        "This routing number has been securely encrypted through your",
        React.createElement(WhiteSpace, null),
        apostrophiseString(partnershipType),
        React.createElement(WhiteSpace, null),
        "bank connection and we are unable to show the full routing number."));
};
export default PaymentMethodRoutingNumberLockedTooltipContent;

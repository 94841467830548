import PropTypes from 'prop-types';
import React from 'react';

import ActionNavChildren from './ActionNavChildren';
import ActionNavSearchPlaceholder from './ActionNavSearchPlaceholder';

const ActionNavBody = ({ children, searchData, searchPlaceholder }) => (
  <div className="action-nav--main-content">
    <ActionNavChildren searchData={searchData}>{children}</ActionNavChildren>
    <ActionNavSearchPlaceholder searchData={searchData} searchPlaceholder={searchPlaceholder} />
  </div>
);

ActionNavBody.propTypes = {
  children: PropTypes.node.isRequired,
  searchData: PropTypes.shape({
    execute: PropTypes.number,
    keyword: PropTypes.string,
  }),
  searchPlaceholder: PropTypes.string.isRequired,
};

ActionNavBody.defaultProps = {
  searchData: undefined,
  searchPlaceholder: undefined,
};

export default ActionNavBody;

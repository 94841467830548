import { CurrencyCodeUsd } from 'constants/currency';
import { formatCurrencyExplicit, formatCurrencyUSDExplicit } from 'helpers/currencyFormatter';
export const rejectMultiZeroesBeforeDecimalSeparator = (value = '') => !/^(\$)?(0{2,})(\.)/.test(value);
export const getExchangeRateText = (amount, currencyCode, currencyCodeMap, options) => `${formatCurrencyUSDExplicit(1)} = ${formatCurrencyExplicit(amount, currencyCode, currencyCodeMap, options)}`;
export const getCurrencyFromCode = (currencyCodeMap, currencyCode = '') => currencyCodeMap[currencyCode.toUpperCase()];
export const getCurrencyNameFromCode = (currencyCodeMap, currencyCode = '') => getCurrencyFromCode(currencyCodeMap, currencyCode)?.name;
export const getSymbolFromCode = (currencyCodeMap, currencyCode = '') => currencyCodeMap[currencyCode.toUpperCase()]?.symbol || '';
export const isCurrencyCodeUSD = (currencyCode) => currencyCode === CurrencyCodeUsd;
export const isCurrencyCodeNonUSD = (currencyCode) => !currencyCode || !isCurrencyCodeUSD(currencyCode);
export const mapCurrencyCodeToSelectOption = (currencyCode) => ({
    id: currencyCode,
    value: currencyCode,
    text: currencyCode,
});

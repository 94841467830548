import React from 'react';
import { Field } from 'redux-form';
import { CompanyAddressDataCard } from 'complexComponents';
import { Checkbox, FlexCol, Text } from 'components';
import { partnerFundingAccountFormFields } from 'constants/formFields';
import { formNamesFunding } from 'constants/forms';
import { typography } from 'constants/styles';
import { PartnershipCountryCodeKey } from 'enums/partnerships';
import { getCountryNameFromCode } from 'helpers/countries';
import { isInternationalPartnership } from 'helpers/partnerships';
import { useCountryCodesContext } from 'hooks';
import AddressContainer from 'modules/address/containers/AddressContainer';
const InternationalPartnerFundingAccountAddressDetails = ({ address, country, partnership, shouldApplyRegisteredAddress, showApplyRegisteredAddress = true, }) => {
    const countryCodesMap = useCountryCodesContext();
    const countryName = getCountryNameFromCode(countryCodesMap, country);
    const isInternational = isInternationalPartnership(partnership, PartnershipCountryCodeKey.PARTNER);
    return (React.createElement("div", { className: "international-partner-funding-account__address-details margin-top--large" },
        React.createElement(FlexCol, null,
            React.createElement(Text.Semibold, { color: typography.TextColor.GREY_XX_DARK, weight: typography.TextWeight.LEVEL_600 }, "Address details"),
            React.createElement(Text.Regular, { color: typography.TextColor.GREY_XX_DARK },
                "We're required to collect a billing address for each new bank account in",
                ` ${countryName}`,
                ".")),
        showApplyRegisteredAddress && (React.createElement(Field, { className: "margin-top--m-large", component: Checkbox, content: "Apply registered address", name: partnerFundingAccountFormFields.APPLY_REGISTERED_ADDRESS })),
        React.createElement("div", { className: "margin-top--m-large" }, shouldApplyRegisteredAddress ? (React.createElement(CompanyAddressDataCard, { address: address })) : (React.createElement(AddressContainer, { formId: formNamesFunding.ADD_MANUAL_BANK, isInternational: isInternational, lockedCountryReason: "This vendor's country has already been set.", stateKey: "partner" })))));
};
export default InternationalPartnerFundingAccountAddressDetails;

import { getMembershipNameOrEmail } from 'helpers/memberships';
import { capitalize } from 'helpers/stringHelpers';
import { YOUR_TEAM } from '../constants';
export const getCreatorOrCompanyNameText = ({ companyName, creator, isYourTeam, }) => {
    const creatorName = getMembershipNameOrEmail(creator);
    if (creatorName) {
        return creatorName;
    }
    if (isYourTeam) {
        return capitalize(YOUR_TEAM);
    }
    return companyName;
};
export const getTeamRelationText = ({ companyName, creator, isYourTeam, }) => {
    if (!creator) {
        return '';
    }
    if (isYourTeam) {
        return YOUR_TEAM;
    }
    return companyName;
};

import PropTypes from 'prop-types';
import React from 'react';

import { DisclosureListClassNames } from 'components/disclosureList/constants';
import { DetailsList } from 'components/listItems';

import { getClassNames } from 'helpers/ui';

import { DisclosureListHeader } from './components';

/**
 * Disclosure List- a list that, by default, has a header with a detail disclosure icon
 * and a body that is expandable/collapsible.
 *
 * Optionally, we can pass in a customHeader (prop) or customBody (prop) to override
 * the default components used for these.
 */
const DisclosureList = (props) => {
  const {
    dataFullStory,
    bodyRowProps,
    footer,
    headerClassName,
    headerComponent,
    headerIconProps,
    headerIconName,
    headerText,
    headerTooltipText,
    headerTooltipContentMargin,
    isBodyInset,
    items,
    keyExtractor,
    onToggleOpen,
    isOpenOverride,
    startOpen,
    style,
    useHeader,
    useTransitions,
  } = props;

  const useOpenOverride = isOpenOverride !== undefined;

  const [isOpen, toggleIsOpen] = React.useState(!useHeader || startOpen);
  const openState = useOpenOverride ? isOpenOverride : isOpen;

  const classes = getClassNames(props, {
    [DisclosureListClassNames.LIST]: true,
    [DisclosureListClassNames.LIST_TRANSITIONS]: useTransitions,
    'inset-body': isBodyInset,
    'no-header': !useHeader,
    open: openState,
  });

  const bodyClasses = getClassNames(
    props,
    {
      [DisclosureListClassNames.BODY]: true,
      [DisclosureListClassNames.BODY_TRANSITIONS]: useTransitions,
    },
    { classProp: 'bodyClassName' },
  );

  return (
    <div className={classes} style={style}>
      {useHeader && (
        <DisclosureListHeader
          className={headerClassName}
          headerComponent={headerComponent}
          icon={headerIconName}
          iconProps={headerIconProps}
          isOpen={openState}
          onToggleOpen={() => {
            if (useOpenOverride) {
              onToggleOpen();
            } else {
              toggleIsOpen(!openState);
            }
          }}
          text={headerText}
          tooltipContentMargin={headerTooltipContentMargin}
          tooltipText={headerTooltipText}
          useHeader={useHeader}
        />
      )}
      <DetailsList
        className={bodyClasses}
        dataFullStory={dataFullStory}
        footer={footer}
        items={items}
        keyExtractor={keyExtractor}
        rowProps={bodyRowProps}
      />
    </div>
  );
};

DisclosureList.propTypes = {
  /* bodyClassName used in getClassNames */
  /* eslint-disable-next-line react/no-unused-prop-types */
  bodyClassName: PropTypes.string,
  bodyRowProps: PropTypes.shape({}),
  /* className used in getClassNames */
  /* eslint-disable-next-line react/no-unused-prop-types */
  className: PropTypes.string,
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headerClassName: PropTypes.string,
  headerComponent: PropTypes.node,
  headerIconName: PropTypes.string,
  headerIconProps: PropTypes.shape(),
  headerText: PropTypes.string,
  headerTooltipContentMargin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  headerTooltipText: PropTypes.string,
  isBodyInset: PropTypes.bool,
  isOpenOverride: PropTypes.bool,
  items: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.node]),
  keyExtractor: PropTypes.func,
  onToggleOpen: PropTypes.func,
  startOpen: PropTypes.bool,
  style: PropTypes.shape({}),
  useHeader: PropTypes.bool,
  useTransitions: PropTypes.bool,
};

DisclosureList.defaultProps = {
  bodyClassName: undefined,
  bodyRowProps: {},
  className: undefined,
  footer: undefined,
  headerClassName: undefined,
  headerComponent: undefined,
  headerIconName: undefined,
  headerIconProps: undefined,
  headerText: undefined,
  headerTooltipContentMargin: undefined,
  headerTooltipText: undefined,
  // eslint-disable-next-line routable/default-props-prefer-undefined
  isBodyInset: false,
  isOpenOverride: undefined,
  items: [],
  keyExtractor: (obj) => obj.key,
  onToggleOpen: undefined,
  // eslint-disable-next-line routable/default-props-prefer-undefined
  startOpen: false,
  style: {},
  useHeader: true,
  useTransitions: true,
};

export default DisclosureList;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Label component for react-select options.
 * @param {ComponentProps} props
 * @param {*} props.children
 * @param {boolean} props.isDisabled
 * @return {StatelessComponent}
 */
const OptionLabel = ({ children, isDisabled }) => (
  <span
    className={classNames('base-text', 'base-option--label', {
      disabled: !!isDisabled,
    })}
  >
    <span className="truncate">{children}</span>
  </span>
);

OptionLabel.propTypes = {
  // passed to react-select Option component
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
};

OptionLabel.defaultProps = {
  children: undefined,
  isDisabled: undefined,
};

export default OptionLabel;

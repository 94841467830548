import React from 'react';
import { LabelLink } from 'components/link/LabelLink/LabelLink.styles';
import { Text, WhiteSpace } from 'components/text';
import { TooltipMUIConditionalWrapper } from 'components/tooltip';
import { typography } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';
import { getCompanyRouteByPartnershipId } from 'helpers/routeHelpers';
const PreventIncompleteContactsCustomerMatchTooltip = ({ children, isShown, onCloseModal, partnership, placement = TooltipPlacement.RIGHT, }) => {
    const linkToPartnerCompanyPage = getCompanyRouteByPartnershipId(partnership?.id);
    return (React.createElement(TooltipMUIConditionalWrapper, { tooltipProps: isShown
            ? {
                arrow: true,
                padding: TooltipPadding.SKINNY,
                placement,
                title: (React.createElement(Text.Regular, { color: typography.TextColor.GREY_X_DARK, lineHeight: typography.TextLineHeight.MEDIUM, size: typography.TextSize.LEVEL_100 },
                    "To match a customer, all company contact fields must be complete.",
                    partnership?.id && (React.createElement(React.Fragment, null,
                        React.createElement(WhiteSpace, null),
                        React.createElement(LabelLink, { onClick: () => {
                                onCloseModal();
                                window.open(linkToPartnerCompanyPage, '_blank');
                            } }, "Edit company contacts \u2197"))))),
            }
            : {} }, children));
};
export default PreventIncompleteContactsCustomerMatchTooltip;

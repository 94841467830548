import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

/**
 * Balance settings page text, account balance section.
 * @type {{ description: string, loading: string, title: string }}
 */
export const accountBalanceSection = {
  description: `Withdraw, deposit, and manage your ${PLATFORM_DISPLAY_SHORT_NAME} account balance.`,
  loading: 'Updating balance...',
  title: 'Account balance',
};

/**
 * Account balance hint text for various states of the balance.
 * @type {{ default: string, negativeBalance: string, pendingBalance: string }}
 */
export const accountBalanceHint = {
  default: `Successful transfers between your ${PLATFORM_DISPLAY_SHORT_NAME} balance and linked bank accounts will not incur any transaction fees.`,
  negativeBalance:
    'You have a negative balance and will not be able to make future transfers until you add funds to this account.',
};

/**
 * Balance settings page text, history section.
 * @type {{description: string, title: string}}
 */
export const balanceHistorySection = {
  description: 'All transfers in and out of your account balance will appear below.',
  title: 'Balance transfer history',
};

/**
 * Balance settings page text, balance amount block (account balance section).
 * @type {{ depositButton: string, fixNegativeButton: string, pendingChip: string, withdrawButton: string }}
 */
export const balanceAmountBlock = {
  addFundsTooltip: 'Add funds to your balance',
  depositButton: 'Deposit',
  fixNegativeButton: 'Fix negative balance',
  pendingChip: 'Pending balance:',
  viewSettingsButton: 'View balance settings',
  withdrawButton: 'Withdraw',
  withdrawFundsTooltip: 'Withdraw funds from your balance',
};

import { resetPasswordRoutine } from 'actions/routines/auth';

import { gatherReducerCasesFromRoutines } from 'helpers/routine';

import { createErrorReducer } from 'store/redux';

export const { failureCases, requestCases, successCases } = gatherReducerCasesFromRoutines([resetPasswordRoutine]);

export const successAndRequestCases = [...requestCases, ...successCases];

export default createErrorReducer(successAndRequestCases, failureCases);

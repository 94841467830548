import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { IconNames } from 'components';

import { AcceptFilesBillAttachments } from 'constants/document';
import { sizes } from 'constants/styles';

import { ConditionalRestrictedPermissionTooltip } from 'modules/auth';

import ActionNavAction from './ActionNavAction';

import './ActionNavUploadAction.scss';

const ActionNavUploadAction = ({
  accept,
  actionText,
  className,
  isDisabled,
  linkClass,
  name,
  onChange,
  required,
  tooltipPosition,
}) => {
  const inputRef = useRef();

  // When the file is selected, we want to trigger the onChange
  // callback, but we also want to clear the input value to ensure
  // the same file can be uploaded X times (onChange is not triggered
  // if the input already has the same file that is being selected).
  const handleOnChange = (evt) => {
    onChange(evt);
    inputRef.current.value = null;
  };

  return (
    <form className="form" id="action-nav-upload-form">
      <div className={clsx('action-nav-file-upload', className)}>
        <input
          accept={accept}
          data-testid="new-bill-upload--input"
          disabled={isDisabled}
          id="new-bill-upload-input"
          multiple
          name={name}
          onChange={handleOnChange}
          ref={inputRef}
          required={required}
          type="file"
        />

        <label htmlFor="new-bill-upload-input">
          <ConditionalRestrictedPermissionTooltip
            data-testid="upload-bill:tooltip"
            position={tooltipPosition}
            useTooltip={isDisabled}
          >
            <ActionNavAction
              className={linkClass}
              id="new-bill-action"
              isDisabled={isDisabled}
              leftIconClassName="margin-right--xm"
              leftIconName={IconNames.CLOUD_UPLOAD}
              leftIconSize={sizes.iconSizes.LARGE}
            >
              {actionText}
            </ActionNavAction>
          </ConditionalRestrictedPermissionTooltip>
        </label>
      </div>
    </form>
  );
};

ActionNavUploadAction.propTypes = {
  accept: PropTypes.string,
  actionText: PropTypes.string.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  linkClass: PropTypes.string,
  tooltipPosition: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

ActionNavUploadAction.defaultProps = {
  accept: AcceptFilesBillAttachments,
  className: '',
  isDisabled: undefined,
  linkClass: 'primary',
  required: undefined,
  tooltipPosition: undefined,
};

export default ActionNavUploadAction;

import { createSelector } from 'reselect';

import { groupAndFilterApprovalsByLevels } from 'helpers/approvals';
import { allValues } from 'helpers/utility';

import { itemSelector } from 'selectors/itemsSelectors';

/**
 * Selects the approvals by id from the state
 * @param {object} state - Redux state
 */
export const approvalsSelector = (state) => state.approvals.byId;

/**
 * Selects approvals and returns an array
 * @param {object} state - Redux state
 */
export const allApprovalsSelector = createSelector([approvalsSelector], (approvals) => allValues(approvals));

/**
 * Selects the item approvals filtered by itemId from state
 * @param {object} state - Redux state
 * @param {string} itemId
 */
export const itemApprovalLevelsSelector = createSelector([allApprovalsSelector, itemSelector], (allApprovals, item) => {
  const itemApprovalIds = item?.approvals ?? [];

  const approvalsForThisItem = allApprovals.filter((approval) => itemApprovalIds.includes(approval.id));
  return groupAndFilterApprovalsByLevels(approvalsForThisItem);
});

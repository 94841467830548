import * as types from 'types/item';

// ==========
// Fetch
// ==========
export const fetchAdditionalInvoicesSuccess = (payload) => ({
  payload,
  type: types.FETCH_ADDITIONAL_INVOICES_SUCCESS,
});

export const fetchInvoicesSuccess = (payload) => ({
  payload,
  type: types.FETCH_INVOICES_SUCCESS,
});

export const fetchInvoicesFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_INVOICES_FAILURE,
});

export const fetchItemsExportRequest = (params = {}) => ({
  payload: { params },
  type: types.FETCH_ITEMS_EXPORT_REQUEST,
});

export const fetchItemsExportSuccess = () => ({
  type: types.FETCH_ITEMS_EXPORT_SUCCESS,
});

export const fetchItemsExportFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_ITEMS_EXPORT_FAILURE,
});

export const fetchSingleItemRequest = (itemId, options = {}) => ({
  payload: { itemId, options },
  type: types.FETCH_ITEM_REQUEST,
});

export const fetchSingleItemSuccess = (payload) => ({
  payload,
  type: types.FETCH_ITEM_SUCCESS,
});

export const fetchSingleItemFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_ITEM_FAILURE,
});

export const fetchItemAndThreadRequest = (itemId, options = {}) => ({
  payload: { itemId, options },
  type: types.FETCH_ITEM_AND_THREAD_REQUEST,
});

// item edit

export const openItemHasBeenUpdatedSwal = ({ itemId }) => ({
  type: types.OPEN_ITEM_HAS_BEEN_UPDATED_SWAL,
  payload: { itemId },
});

export const resetItemHasBeenUpdatedSwal = ({ itemId }) => ({
  type: types.RESET_ITEM_HAS_BEEN_UPDATED_SWAL,
  payload: { itemId },
});

// OCR Status Change Events
export const changeOcrStatusItem = (data) => ({
  type: types.OCR_STATUS_ITEM_CHANGE,
  payload: { data },
});

export const earEvaluatedItemPayload = (payload) => ({
  type: types.EAR_EVALUATED_ITEM,
  payload,
});

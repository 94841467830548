import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Panel = ({ children, className }) => {
  const panelClassNames = classNames({
    panel: true,
    [className]: !!className,
  });

  return <div className={panelClassNames}>{children}</div>;
};

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Panel.defaultProps = {
  className: null,
};

export default Panel;

import { Flex } from '@routable/gross-ds';
import { formNamesItem } from '@routable/shared';
import React from 'react';
import { useSelector } from 'react-redux';
import { useAnnotations } from 'helpers/ocr';
import SummarizeLineItems from 'modules/dashboard/createItems/invoiceGenerator/components/InvoiceGeneratorView/SummarizeLineItems';
import { createItemsTableViewModelManagerForKindSelector } from 'queries/tableCompoundSelectors';
import { createFieldValueSelector } from 'selectors/createFormSelectors';
import { createItemFormItemIdSelector } from 'selectors/forms';
export const DataFedSummarizeTransfer = ({ sectionType }) => {
    const itemId = useSelector(createItemFormItemIdSelector);
    const { annotationData, isOcrEnabled, isInboxBillView } = useAnnotations(itemId);
    const isForcedConsolidatedLineItem = useSelector(createFieldValueSelector(formNamesItem.CREATE_ITEM, 'ui.isForcedConsolidatedLineItem'));
    const lineItems = useSelector(createFieldValueSelector(formNamesItem.CREATE_ITEM, 'line_items'));
    const viewModelManager = useSelector(createItemsTableViewModelManagerForKindSelector);
    return (React.createElement(React.Fragment, null, isOcrEnabled && isInboxBillView && (React.createElement(Flex, { align: "center" },
        React.createElement(SummarizeLineItems, { isForcedConsolidatedLineItem: isForcedConsolidatedLineItem, lineItems: lineItems, ocrAnnotation: annotationData, sectionType: sectionType, viewModelManager: viewModelManager })))));
};

import React from 'react';
import { localStorageRemove } from 'helpers/localStorage';
import { REFRESH_TABS_LOCAL_STORAGE_KEY } from 'helpers/refreshTabs/constants';
export const eventHandler = (event) => {
    const { key, newValue } = event;
    if (key === REFRESH_TABS_LOCAL_STORAGE_KEY && newValue === 'true') {
        window.location.reload();
        localStorageRemove(REFRESH_TABS_LOCAL_STORAGE_KEY);
    }
};
export const useRefreshTabListener = () => {
    React.useEffect(() => {
        window.addEventListener('storage', eventHandler);
        return () => window.removeEventListener('storage', eventHandler);
    }, []);
};

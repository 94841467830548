import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import SettingsDivider from '../SettingsDivider';

const SettingsPaddedDivider = ({ className }) => (
  <SettingsDivider
    className={classNames({
      [className]: !!className,
    })}
  />
);

SettingsPaddedDivider.propTypes = {
  className: PropTypes.string,
};

SettingsPaddedDivider.defaultProps = {
  className: undefined,
};

export default SettingsPaddedDivider;

import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { ButtonV2, IconNames } from 'components';

import { ButtonSize } from 'constants/button';
import { sizes } from 'constants/styles';
import { colorGreyDarkHex } from 'constants/styles/colors';
import { Intent } from 'constants/ui';

const ActionNavBackButton = ({ defaultBackURL }) => (
  <Link to={defaultBackURL}>
    <ButtonV2
      className="display--inline-flex margin-right--large"
      intent={Intent.NEUTRAL}
      leftIconColor={colorGreyDarkHex}
      leftIconName={IconNames.ARROW_LEFT}
      leftIconSize={sizes.iconSizes.MEDIUM}
      size={ButtonSize.SMALL}
    />
  </Link>
);

ActionNavBackButton.propTypes = {
  defaultBackURL: PropTypes.string.isRequired,
};

export default ActionNavBackButton;

/**
 * @file Helpers for pusher sockets, channels and events
 * Naming definitions:
 * Socket - websocket connection between the browser and pusher
 * Channel - a channel that we subscribe to in order to listen to updates (we can technically subscribe to more than one. We currently only subscribe to one, which is the company id).
 * Event - the instance of a notification that is happening when Pusher pushes something to the application (e.g. item update)
 */

import { asArrayElement } from 'helpers/utility';

import { PusherService } from 'services';
import { payloadToCamelCase } from 'services/api/formatHelpers';

/**
 * Helper method to subscribe the the socket channel
 * @param {string} channelName
 * @return {Channel}
 */
export const subscribeToSocketChannel = (channelName) => PusherService.subscribe(channelName);

/**
 * Helper method to unbind the socket from an event
 * @param {Channel} notificationChannel
 * @param {Object} event
 */
export const unbindSocketChannelEvent = (notificationChannel, event) => {
  if (!notificationChannel?.subscribed) {
    return;
  }

  notificationChannel.unbind(event);
};

/**
 * Helper method to bind the socket from an event(s)
 * @param {Channel} notificationChannel
 * @param {Object|Array<Object>} events
 * @param {Function} callback
 */
export const bindSocketChannelEvent = (notificationChannel, events, callback) => {
  if (!notificationChannel) {
    return;
  }

  asArrayElement(events).forEach((event) => {
    // Unbind from event first
    unbindSocketChannelEvent(notificationChannel, event);

    // Bind event with callback after camelCasing the event data
    notificationChannel.bind(event, (eventData) => callback(payloadToCamelCase(eventData)));
  });
};

/**
 * Helper method to disconnect the socket altogether
 */
export const disconnectSocket = () => {
  PusherService.disconnect();
};

import {} from 'interfaces/sso';
export const getSubmitButtonText = (companySSOSettings, loginWithPassword, canManageSsoSettings) => {
    const isSSOTurnedOnAndRequired = companySSOSettings && companySSOSettings.ssoRequired;
    const isSSOTurnedOnAndOptional = companySSOSettings && !companySSOSettings.ssoRequired;
    if (!loginWithPassword && !canManageSsoSettings) {
        if (isSSOTurnedOnAndRequired) {
            return 'Create account and log in with Google';
        }
        if (isSSOTurnedOnAndOptional) {
            return 'Log in with Google';
        }
    }
    return 'Create my account';
};

import { DASHBOARD } from 'constants/routes';

import { asPath } from 'helpers/routeHelpers';

import * as types from 'types/navigation';

export const pushHistory = (url) => ({
  payload: { url },
  type: types.PUSH_HISTORY,
});

export const goBackIfPreviousState = (fallbackUrl = asPath(DASHBOARD)) => ({
  payload: { fallbackUrl },
  type: types.GO_BACK_IF_PREVIOUS_STATE,
});

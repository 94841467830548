import { fetchSingleIntegrationLedgerPartnershipsRoutine } from 'actions/routines/integrations';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import {
  FETCH_LEDGER_PARTNERSHIPS_SUCCESS,
  FETCH_PLATFORM_PARTNERSHIPS_SEARCH_SUCCESS,
  FETCH_PLATFORM_PARTNERSHIPS_SUCCESS,
} from 'types/integrations';

const ledgerUnmatchedPartnershipContactsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_LEDGER_PARTNERSHIPS_SUCCESS:
    case fetchSingleIntegrationLedgerPartnershipsRoutine.SUCCESS:
    case FETCH_PLATFORM_PARTNERSHIPS_SUCCESS:
    case FETCH_PLATFORM_PARTNERSHIPS_SEARCH_SUCCESS:
      return deepMergeWithArrayReplacement(state, getObjectsByIdWithRelationships(action.payload.partnershipMember));

    default:
      return state;
  }
};

export default ledgerUnmatchedPartnershipContactsByIdReducer;

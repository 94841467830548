import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { partnershipFromMatchModalSelector } from 'queries/partnershipCompoundSelectors';
import { currentCompanySettingsIntegrationSelector, currentCompanySettingsSelector, } from 'selectors/currentCompanySelectors';
import { fundingSupportedCountriesPaymentOptionsCurrencyList } from 'selectors/fundingSelectors';
import { ledgerIntegrationSelector } from 'selectors/integrationsSelectors';
import MultiLineOptionWithTooltipForMatchCompanies from './MultiLineOptionWithTooltipForMatchCompanies';
export const mapStateToProps = createStructuredSelector({
    companyIntegrationSettings: currentCompanySettingsIntegrationSelector,
    companySettings: currentCompanySettingsSelector,
    ledger: ledgerIntegrationSelector,
    platformPartnership: partnershipFromMatchModalSelector,
    supportedCurrencies: fundingSupportedCountriesPaymentOptionsCurrencyList,
});
export default connect(mapStateToProps)(MultiLineOptionWithTooltipForMatchCompanies);

import PropTypes from 'prop-types';
import React from 'react';

import { EntityWidgetSection } from 'components';

import { colors } from 'constants/styles';

import {
  roleCardConfigProp,
  RoleCardEntityWidgetBodyPermissionList,
  RoleCardEntityWidgetBodySectionTitle,
} from './components';

/**
 * Map over the PermissionGroups to create DisclosureLists which enumerate the permissions related to this role.
 *
 * @param {ComponentProps} props
 * @param {RoleCardConfig} props.roleCardConfig
 * @param {boolean} [props.startPermissionListsOpen]
 * @returns {StatelessComponent}
 */
const RoleCardEntityWidgetBody = ({ roleCardConfig, startPermissionListsOpen }) => (
  <EntityWidgetSection
    className="padding-bottom--m-large padding-top--m-large padding-left--l"
    style={{ backgroundColor: colors.colorBlueExtraExtraLight }}
  >
    <RoleCardEntityWidgetBodySectionTitle />
    <RoleCardEntityWidgetBodyPermissionList
      roleCardConfig={roleCardConfig}
      startPermissionListsOpen={startPermissionListsOpen}
    />
  </EntityWidgetSection>
);

RoleCardEntityWidgetBody.propTypes = {
  roleCardConfig: roleCardConfigProp.isRequired,
  startPermissionListsOpen: PropTypes.bool,
};

RoleCardEntityWidgetBody.defaultProps = {
  startPermissionListsOpen: undefined,
};

export default RoleCardEntityWidgetBody;

import fileDownload from 'js-file-download';

import { addNotFoundError } from 'actions/errors';
import { maintenanceModeModalOpen, maintenanceModeOn } from 'actions/maintenance';
import { oldVersionModeModalOpen, oldVersionModeOn } from 'actions/oldVersion';

import defaultAlertErrors from 'components/error/components/defaultAlertErrors';

import { buildServerErrorAlert, parseServerErrors } from 'helpers/errors';

import { isDashboardFullyLoadedSelector } from 'selectors/dashboardLoadedSelectors';

import { payloadToCamelCase } from 'services/api/formatHelpers';
import FetchService from 'services/fetch';

import { storeAccessor as store } from 'store/accessor';

class ThunkFetch {
  constructor(props) {
    this.props = props;
    this.actions = this.getActions();
  }

  getActions() {
    const actions = {};
    ['error', 'request', 'success'].forEach((actionType) => {
      // Check they exist
      if (!this.props.actions[actionType]) {
        throw new Error(`Missing action - ${actionType}`);
      }

      // Action type or action creator
      if (typeof this.props.actions[actionType] === 'function') {
        // Action creators given in function form
        actions[actionType] = this.props.actions[actionType];
      } else {
        // Assume these are string action types - build the action creator
        actions[actionType] = (actionPayloadParams) => {
          const actionCreator = {
            type: this.props.actions[actionType],
          };

          if (actionPayloadParams) {
            actionCreator.payload = { ...actionPayloadParams };
          }

          return actionCreator;
        };
      }
    });

    return actions;
  }

  makeRequest() {
    return async (dispatch) => {
      const { error, request, success } = this.actions;

      const {
        accept,
        errorCallback,
        id,
        includeInRequest,
        muteAlerts,
        noCamelCase,
        payload,
        requestName,
        successCallback,
      } = this.props;

      // TODO: FRON-1300 - move this into a downloads saga
      // e.g. downloads saga?
      // CSV download
      if (accept === 'text/csv') {
        const csvResponse = await FetchService.request({
          ...this.props,
          transformers: [],
          responseType: 'blob',
        });

        const fileName = `${requestName || 'file'}.csv`;
        fileDownload(csvResponse.data, fileName);
        return null;
      }

      if (id) {
        dispatch(request({ id }));
      } else {
        dispatch(request(includeInRequest));
      }

      const response = await FetchService.request(this.props);

      if (!response) {
        dispatch(error({ errors: {} }));
        buildServerErrorAlert(defaultAlertErrors, response);
        return null;
      }

      // Set the response objects
      const jsonResponse = response.originalData;
      let parsedResponse = response.data;

      // *************************************
      // Bad response
      // *************************************
      if (!response.ok) {
        // Muting alerts if we have a specific behavior passed in from the thunk or a 404
        let shouldMuteAlerts = !!muteAlerts;

        // Check for 404 errors and dispatch a not found error
        if (response.status === 404) {
          dispatch(addNotFoundError());
          shouldMuteAlerts = true;
        }

        // Check if we are in maintenance mode
        // open a modal if the user is already on the dashboard
        // flip maintenance mode on in redux to trigger the full page version otherwise
        if (FetchService.isResponseMaintenanceMode(response)) {
          const reduxState = store.getState();
          const isDashboardFullyLoaded = isDashboardFullyLoadedSelector(reduxState);

          if (isDashboardFullyLoaded) {
            dispatch(maintenanceModeModalOpen());
          } else {
            dispatch(maintenanceModeOn());
          }

          shouldMuteAlerts = true;
        }

        // Check if current app version is not latest
        // open a modal if the user is already on the dashboard
        // trigger the full page version otherwise
        if (FetchService.isResponseOldVersionMode(response)) {
          const reduxState = store.getState();
          const isDashboardFullyLoaded = isDashboardFullyLoadedSelector(reduxState);

          if (isDashboardFullyLoaded) {
            dispatch(oldVersionModeModalOpen());
          } else {
            dispatch(oldVersionModeOn());
          }

          shouldMuteAlerts = true;
        }

        let parsedErrors = parseServerErrors(response, dispatch, shouldMuteAlerts);

        // Mutate the error response with a error callback, if exists
        if (errorCallback) {
          parsedErrors = errorCallback(parsedErrors, jsonResponse, payload, response);
        }

        const errorPayload = { errors: await parsedErrors };

        // Add id to the error payload if specified in the Thunk
        if (id) {
          errorPayload.id = id;
        }

        // Dispatch a failure action
        dispatch(error(errorPayload));

        // Return the error payload to the submit handler
        return errorPayload;
      }

      // *************************************
      // Good response
      // *************************************

      // Mutate the response with a success callback, if exists
      if (successCallback) {
        parsedResponse = successCallback(parsedResponse, jsonResponse, payload, response);
      }

      // Add id to the response payload if specified in the Thunk
      if (id) {
        parsedResponse.id = id;
      }

      // Sometimes we want the raw data
      if (noCamelCase) {
        dispatch(success(parsedResponse));
      } else {
        // Dispatch a success action
        dispatch(success(payloadToCamelCase(parsedResponse)));
      }

      // Return undefined to the submit handler -- signifying no errors
      return undefined;
    };
  }
}

export default ThunkFetch;

import PropTypes from 'prop-types';
import React from 'react';

import { reduxFormInputPropType } from 'components/commonProps';

import { isEqual } from 'helpers/utility';

import { componentHelpers } from 'modules/fundingAccount/partnerPaymentMethod/helpers';

import { PartnerPaymentMethodSelectionOption } from './components';
import PartnerPaymentMethod from './PartnerPaymentMethodContainer';

class PartnerPaymentMethodSelection extends React.Component {
  componentDidMount() {
    const { setAccountsDisplayOrder, updateSelectedFundingAccountIfNeeded } = this.props;
    updateSelectedFundingAccountIfNeeded(this.props);
    setAccountsDisplayOrder();
  }

  componentDidUpdate(prevProps) {
    const { updateDisplayOrderIfNeeded, updateSelectedFundingAccountIfNeeded } = this.props;
    updateSelectedFundingAccountIfNeeded(this.props, prevProps);
    // we want to make sure that if there are more than 3 account choices,
    // the one that's selected displays without needing to click the 'view all' button
    updateDisplayOrderIfNeeded(this.props, prevProps);
  }

  render() {
    const {
      currencyCodeReceiver,
      fundingAccountsById,
      fundingInfoAddressesById,
      input,
      onShowAllAccounts,
      partnership,
      paymentDeliveryMethod,
      paymentDeliveryOption,
      sortedFundingAccounts,
      ...rest
    } = this.props;

    const selectedFundingAccount = componentHelpers.getSelectedFundingAccount(this.props);

    return (
      <PartnerPaymentMethod
        onChangeSelectedFundingAccount={input.onChange}
        onShowAll={onShowAllAccounts}
        partnership={partnership}
        paymentDeliveryMethod={paymentDeliveryMethod}
        selectedFundingAccount={selectedFundingAccount}
        {...rest}
      >
        {sortedFundingAccounts.map((partnerFundingAccount) => (
          <PartnerPaymentMethodSelectionOption
            currencyCodeReceiver={currencyCodeReceiver}
            fundingAccountsById={fundingAccountsById}
            fundingInfoAddressesById={fundingInfoAddressesById}
            id={partnerFundingAccount.id}
            input={{
              ...input,
              checked: isEqual(partnerFundingAccount.id, selectedFundingAccount),
              value: partnerFundingAccount.id,
            }}
            key={partnerFundingAccount.id}
            partnerFundingAccount={partnerFundingAccount}
            partnership={partnership}
            paymentDeliveryMethod={paymentDeliveryMethod}
            paymentDeliveryOption={paymentDeliveryOption}
          />
        ))}
      </PartnerPaymentMethod>
    );
  }
}

PartnerPaymentMethodSelection.propTypes = {
  fundingAccountsById: PropTypes.shape().isRequired,
  fundingInfoAddressesById: PropTypes.shape().isRequired,
  input: reduxFormInputPropType.isRequired,
  onShowAllAccounts: PropTypes.func.isRequired,
  partnership: PropTypes.shape(),
  paymentDeliveryMethod: PropTypes.string,
  paymentDeliveryOption: PropTypes.string,
  setAccountsDisplayOrder: PropTypes.func.isRequired,
  sortedFundingAccounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateDisplayOrderIfNeeded: PropTypes.func.isRequired,
  updateSelectedFundingAccountIfNeeded: PropTypes.func.isRequired,
};

PartnerPaymentMethodSelection.defaultProps = {
  fundingInfoAddressesById: {},
  partnership: undefined,
  paymentDeliveryMethod: undefined,
  paymentDeliveryOption: undefined,
};

export default PartnerPaymentMethodSelection;

import { updateMembershipRelationships } from 'constants/relationships';

import { REST } from 'helpers/api';

import { payloadToJSONAPI } from 'services/api/formatHelpers';
import {
  deleteMembershipDocumentEndpoint,
  fetchMembershipInviteEmailEndpoint,
  getDisableMembershipEndpoint,
  getEnableMembershipEndpoint,
  getMembershipConfigEndpoint,
  getMembershipEndpoint,
  membershipsEndpoint,
  postMembershipDocumentEndpoint,
} from 'services/api/membershipsEndpoints';
import {
  membershipInviteEndpoint,
  membershipInvitesEndpoint,
  resendInviteEndpoint,
  revokeInviteEndpoint,
} from 'services/api/teamEndpoints';

/**
 * Make a POST to disable a membership. The body of the POST is the membership to be disabled.
 * @param {Object} data
 * @param {OptionsArg} options
 * @returns {Promise<*>}
 */
export const disableMembership = async (data, options = {}) =>
  REST.post({
    ...options,
    endpoint: getDisableMembershipEndpoint(data.id),
    payload: payloadToJSONAPI(data, 'Membership'),
    requireAuth: true,
  });

/**
 * Send a POST request to re-enable a membership.
 * @param {Object} data
 * @param {OptionsArg} options
 * @returns {Promise<*>}
 */
export const enableMembership = async (data, options = {}) =>
  REST.post({
    ...options,
    endpoint: getEnableMembershipEndpoint(data.id),
    payload: payloadToJSONAPI(data, 'Membership'),
    requireAuth: true,
  });

/**
 * Fetch a current memberships for the active user.
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchMemberships = async (options = {}) =>
  REST.get({
    ...options,
    endpoint: membershipsEndpoint,
    requireAuth: true,
    pageSize: 100,
  });

/**
 * Fetch all teammate membership invites.
 * @param {string} companyId
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchAllMembershipInvites = async (companyId, options = {}) => {
  const endpoint = membershipInvitesEndpoint(companyId);
  return REST.get({
    ...options,
    endpoint: `${endpoint}?page[size]=100`,
    requireAuth: true,
  });
};

/**
 * Fetch an individual teammate membership invites.
 * @param {string} companyId
 * @param {string} membershipInviteId
 * @param {string} membershipInviteToken
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchMembershipInvite = async (companyId, membershipInviteId, membershipInviteToken, options = {}) => {
  const endpoint = membershipInviteEndpoint(companyId, membershipInviteId, membershipInviteToken);
  return REST.get({
    ...options,
    endpoint,
    requireAuth: false,
  });
};

/**
 * Submit a new teammate membership invite.
 * @param {string} companyId
 * @param {Object} data
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const submitMembershipInvite = async (companyId, data, options = {}) => {
  const endpoint = membershipInvitesEndpoint(companyId);
  const payload = payloadToJSONAPI(data, 'MembershipInvite', 'role');

  return REST.post({
    ...options,
    endpoint,
    payload,
    requireAuth: true,
  });
};

/**
 * Resend a pending membership invite.
 * @param {string} companyId
 * @param {string} membershipId
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const resendMembershipInvite = async (companyId, membershipId, options = {}) => {
  const endpoint = resendInviteEndpoint(companyId, membershipId);
  return REST.post({
    ...options,
    endpoint,
    requireAuth: true,
  });
};

/**
 * Revoke a pending membership invite.
 * @param {string} companyId
 * @param {string} membershipId
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const revokeMembershipInvite = async (companyId, membershipId, options = {}) => {
  const endpoint = revokeInviteEndpoint(companyId, membershipId);
  return REST.post({
    ...options,
    endpoint,
    requireAuth: true,
  });
};

/**
 * Fetch data for a specific membership.
 * @param {string} membershipId
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchSingleMembership = async (membershipId, options = {}) => {
  const endpoint = getMembershipEndpoint(membershipId);
  return REST.get({
    ...options,
    endpoint,
    requireAuth: true,
  });
};

/**
 * Fetch data for a specific membership.
 * @param {string} membershipId
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchMembershipConfig = async (membershipId, options = {}) => {
  const endpoint = getMembershipConfigEndpoint(membershipId);
  return REST.get({
    ...options,
    endpoint,
    requireAuth: true,
  });
};

/**
 * Search for membership invites matching an email address.
 * @param {Object} queryParams
 * @param {StringMaybe} queryParams.email
 * @param {ObjectMaybe} [options={}]
 * @returns {Promise<*>}
 */
export const fetchMembershipInviteEmail = async (queryParams, options = {}) => {
  const endpoint = fetchMembershipInviteEmailEndpoint(queryParams);
  return REST.get({
    ...options,
    endpoint,
    requireAuth: true,
  });
};

/**
 * PATCH membership
 * related to thunks/updateMembership
 * Prefer using this saga over the outdated thunk
 * @param {string} membershipId
 * @param {string} userId
 * @param {Object} data
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const updateMembership = async (membershipId, userId, data, options = {}) => {
  const endpoint = getMembershipEndpoint(membershipId);
  const payload = payloadToJSONAPI(
    {
      ...data,
      id: membershipId,
      user: {
        ...data.user,
        id: userId,
      },
    },
    'Membership',
    ...updateMembershipRelationships,
  );

  return REST.patch({
    ...options,
    endpoint,
    payload,
    requireAuth: true,
  });
};

export const postMembershipDocument = async (currentMemberId, file) => {
  const endpoint = postMembershipDocumentEndpoint({ currentMemberId });
  const config = {
    endpoint,
    requireAuth: true,
    payload: {
      data: {
        attributes: {
          document_type: file.documentType,
          file: file.file,
          filename: file.filename,
        },
        type: 'Document',
      },
    },
  };

  return REST.post(config);
};

export const deleteMembershipDocument = async (currentMemberId, documentId) => {
  const endpoint = deleteMembershipDocumentEndpoint({
    currentMemberId,
    documentId,
  });
  const config = {
    endpoint,
    requireAuth: true,
  };

  return REST.delete(config);
};

import { deepMergeWithArrayReplacement } from 'helpers/transform';
import { allKeys } from 'helpers/utility';

import getRelationships from 'store/redux';

/**
 * Parses an API response to format notification settings email forwards objects by id,
 * with formatted attributes and relationships.
 * @param {Object} emailForwardsPayload
 * @return {ByIdCollection}
 */
export const getEmailForwardsById = (emailForwardsPayload = {}) =>
  allKeys(emailForwardsPayload).reduce((obj, emailForwardsId) => {
    const item = {
      id: emailForwardsPayload[emailForwardsId].id,
      ...emailForwardsPayload[emailForwardsId].attributes,
      ...getRelationships(emailForwardsPayload, emailForwardsId),
    };

    return {
      ...obj,
      [emailForwardsId]: item,
    };
  }, {});

/**
 * Returns parsed, formatted email forwards by id, merged with any pre-existing state, and
 * with formatted attributes and relationships.
 * @param {ByIdCollection} emailForwardsState
 * @param {Object} emailForwardsPayload
 * @return {ByIdCollection}
 */
export const getEmailForwardsByIdAfterFetch = (emailForwardsState, emailForwardsPayload) => {
  const emailForwardsById = getEmailForwardsById(emailForwardsPayload);
  return deepMergeWithArrayReplacement(emailForwardsState, emailForwardsById);
};

/**
 * Returns parsed, formatted email forwards by id, omitting the deleted email forward's data.
 * @param {ByIdCollection} emailForwardsState
 * @param {string} deletedForwardId
 * @return {ByIdCollection}
 */
export const getEmailForwardsByIdAfterDelete = (emailForwardsState, deletedForwardId) =>
  allKeys(emailForwardsState).reduce((obj, emailForwardId) => {
    if (emailForwardId === deletedForwardId) {
      return obj;
    }

    return {
      ...obj,
      [emailForwardId]: emailForwardsState[emailForwardId],
    };
  }, {});

import { getDynamicTableOutput } from '@routable/tablematic';
import { createSelector } from 'reselect';

import { tableNamesDashboard } from 'constants/tables';

import { isPaymentFilter } from 'modules/dashboard/itemFilters/helpers/isPaymentFilter';
import {
  getConstrainedMetaForItemKind,
  getTableSchemaParser,
  getTableSchemaParserForItemKind,
  getTableViewModelManager,
  getTableViewModelManagerForItemKind,
} from 'modules/table/helpers';

import { createItemFormAllValuesSelector } from 'selectors/forms';
import {
  createItemKindFromLocationSelector,
  getItemKindFromLocationSelector,
  tabParamSelector,
} from 'selectors/routerSelectors';
import {
  createItemsPayableDetailsSchemaSelector,
  createItemsPayableDetailsTableSchemaSelector,
  createItemsPayableTableIsLoadingSelector,
  createItemsPayableTableSchemaSelector,
  createItemsReceivableTableIsLoadingSelector,
  createItemsReceivableTableSchemaSelector,
  getState,
} from 'selectors/tableSelectors';

/**
 * A selector that, given state, returns an instance of SchemaParser for the CreateItems
 * payables table.
 * It's important to note that, thanks to selector memoization, once the schema is loaded, this
 * will always return the same instance object, which makes it safe to render with (as opposed to creating
 * an instance of SchemaParser inside a component function).
 * @function
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @returns {SchemaParser|null}
 */
export const createItemsPayableTableSchemaParserSelector = createSelector(
  [createItemsPayableTableIsLoadingSelector, createItemsPayableTableSchemaSelector],
  (isLoading, schema) => getTableSchemaParser({ isLoading, schema }),
);

/**
 * A selector that, given state, returns an instance of SchemaParser for the CreateItems
 * payables table.
 * It's important to note that, thanks to selector memoization, once the schema is loaded, this
 * will always return the same instance object, which makes it safe to render with (as opposed to creating
 * an instance of SchemaParser inside a component function).
 * @function
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @returns {SchemaParser|null}
 */
export const createItemsPayableDetailsSchemaParserSelector = createSelector(
  [createItemsPayableTableIsLoadingSelector, createItemsPayableDetailsSchemaSelector],
  (isLoading, schema) => getTableSchemaParser({ isLoading, schema }),
);

/**
 * A selector that, given state, returns an instance of SchemaParser for the CreateItems
 * payables table.
 * It's important to note that, thanks to selector memoization, once the schema is loaded, this
 * will always return the same instance object, which makes it safe to render with (as opposed to creating
 * an instance of SchemaParser inside a component function).
 * @function
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @returns {SchemaParser|null}
 */
export const createItemsPayableDetailsTableSchemaParserSelector = createSelector(
  [createItemsPayableTableIsLoadingSelector, createItemsPayableDetailsTableSchemaSelector],
  (isLoading, schema) => getTableSchemaParser({ isLoading, schema }),
);

/**
 * A selector that, given state, returns an instance of SchemaViewModelManager for the CreateItems
 * payables table.
 * It's important to note that, thanks to selector memoization, once the schema is loaded, this
 * will always return the same instance object, which makes it safe to render with (as opposed to creating
 * an instance of SchemaViewModelManager inside a component function).
 * @function
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @returns {SchemaViewModelManager|null}
 */
export const createItemsPayableDetailsViewModelManagerSelector = createSelector(
  [createItemsPayableDetailsSchemaParserSelector],
  (parser) => getTableViewModelManager(parser),
);

/**
 * A selector that, given state, returns an instance of SchemaViewModelManager for the CreateItems
 * payables table.
 * It's important to note that, thanks to selector memoization, once the schema is loaded, this
 * will always return the same instance object, which makes it safe to render with (as opposed to creating
 * an instance of SchemaViewModelManager inside a component function).
 * @function
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @returns {SchemaViewModelManager|null}
 */
export const createItemsPayableDetailsTableViewModelManagerSelector = createSelector(
  [createItemsPayableDetailsTableSchemaParserSelector],
  (parser) => getTableViewModelManager(parser),
);

/**
 * A selector that, given state, returns an instance of SchemaViewModelManager for the CreateItems
 * payables table.
 * It's important to note that, thanks to selector memoization, once the schema is loaded, this
 * will always return the same instance object, which makes it safe to render with (as opposed to creating
 * an instance of SchemaViewModelManager inside a component function).
 * @type {StandardSelector}
 * @returns {SchemaViewModelManager|null}
 */
export const createItemsPayableTableViewModelManagerSelector = createSelector(
  [createItemsPayableTableSchemaParserSelector],
  (parser) => getTableViewModelManager(parser),
);

/**
 * A selector that, given state, returns an instance of SchemaParser for the CreateItems
 * receivables table.
 * @see {createItemsPayableTableSchemaParserSelector} (above; the note there applies here)
 * @function
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @returns {SchemaParser|null}
 */
export const createItemsReceivableTableSchemaParserSelector = createSelector(
  [createItemsReceivableTableIsLoadingSelector, createItemsReceivableTableSchemaSelector],
  (isLoading, schema) => getTableSchemaParser({ isLoading, schema }),
);

/**
 * A selector that, given state, returns an instance of SchemaViewModelManager for the CreateItems
 * receivables table.
 * @see {createItemsPayableTableViewModelManagerSelector} (above; the note there applies here)
 * @function
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @returns {SchemaViewModelManager|null}
 */
export const createItemsReceivableTableViewModelManagerSelector = createSelector(
  [createItemsReceivableTableSchemaParserSelector],
  (parser) => getTableViewModelManager(parser),
);

/**
 * A selector that, given state and props, returns an instance of SchemaParser for the current item
 * kind, based on route.
 * @function
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @returns {SchemaParser|null}
 */
export const createItemsTableSchemaParserForKindSelector = createSelector(
  [
    createItemKindFromLocationSelector,
    createItemsPayableTableSchemaParserSelector,
    createItemsReceivableTableSchemaParserSelector,
  ],
  (itemKind, receivableParser, payableParser) =>
    getTableSchemaParserForItemKind({
      itemKind,
      receivableParser,
      payableParser,
    }),
);

/**
 * A selector that, given state and props, returns an instance of SchemaViewModelManager for the current item
 * kind, based on route. This works for create and edit item.
 *
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {SchemaViewModelManager|null}
 */
export const createItemsTableViewModelManagerForKindSelector = createSelector(
  [
    getItemKindFromLocationSelector,
    createItemsReceivableTableViewModelManagerSelector,
    createItemsPayableTableViewModelManagerSelector,
  ],
  (itemKind, receivableManager, payableManager) =>
    getTableViewModelManagerForItemKind({
      itemKind,
      receivableManager,
      payableManager,
    }),
);

/**
 * A selector that, given state and props, returns the result of running calculations on dynamic meta,
 * for the current item kind, based on route.
 * @function
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @returns {Object|null}
 */
export const createItemsTableConstrainedMetaForKindSelector = createSelector(
  [createItemsTableViewModelManagerForKindSelector, createItemFormAllValuesSelector],
  (viewModelManager, formValues) =>
    getConstrainedMetaForItemKind({
      formValues,
      viewModelManager,
    }),
);

export const createItemsTableOutForKindSelector = createSelector(
  [createItemsTableViewModelManagerForKindSelector, createItemFormAllValuesSelector],
  (viewModelManager, formValues) =>
    viewModelManager?.parser ? getDynamicTableOutput(viewModelManager.parser, formValues) : {},
);

/**
 * A selector that, given state and props, returns the calculated amount of the current item.
 * @function
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @returns {number|undefined|null}
 */
export const createItemsTableConstrainedMetaTotalSelector = createSelector(
  [createItemsTableConstrainedMetaForKindSelector],
  (constrainedMeta) => constrainedMeta?.total,
);

/**
 * Given state, returns table.payablesTable or table.receivablesTable
 * based on the current tab
 * @function
 * @param {ReduxState} state
 * @param {ComponentProps} ownProps
 * @returns {ObjectMaybe}
 */
export const tableSelectorByPropSelector = createSelector(
  [getState, tabParamSelector],
  (state, tab) => state[tableNamesDashboard[tab]],
);

/**
 * Given state, returns table.payablesTable.filters or table.receivablesTable.filters
 * based on the current tab
 * @function
 * @param {ReduxState} state
 * @param {ComponentProps} ownProps
 * @returns {Object[]}
 */
export const tableFiltersArrayByPropSelector = createSelector(
  [tableSelectorByPropSelector],
  (tableData) => tableData?.filters,
);

/**
 * Given state, returns the payment queryParam
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} ownProps
 * @returns {?PaymentFilter}
 */
export const tableFiltersPaymentIdFilterSelector = createSelector([tableFiltersArrayByPropSelector], (filters) =>
  filters?.find(isPaymentFilter),
);

/**
 * Given state, returns table.payablesTable.selected or table.receivablesTable.selected
 * based on the current tab
 * @function
 * @param {ReduxState} state
 * @param {ComponentProps} ownProps
 * @returns {Item[]}
 */
export const tableSelectedArrayByPropSelector = createSelector(
  [tableSelectorByPropSelector],
  (tableData) => tableData?.selected,
);

/**
 * Maps selected items into an array of ids
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} ownProps
 * @returns {String[]}
 */
export const tableSelectedIdsArrayByPropSelector = createSelector([tableSelectedArrayByPropSelector], (selected) =>
  selected?.map(({ id }) => id),
);

/**
 * Given state, returns table.payablesTable.lockedSelection or table.receivablesTable.lockedSelection
 * based on the current tab
 * @function
 * @param {ReduxState} state
 * @param {ComponentProps} ownProps
 * @returns {Item[]}
 */
export const tableLockedSelectionArrayByPropSelector = createSelector(
  [tableSelectorByPropSelector],
  (tableData) => tableData?.lockedSelection,
);

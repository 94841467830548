import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { createPartnershipFilteredItemsSelector, itemsSelector } from 'selectors/itemsSelectors';
import { itemIdQuerySelector, partnershipIdQuerySelector } from 'selectors/routerSelectors';

import ExternalItemsList from './ExternalItemsList';

const mapStateToProps = (state, ownProps) => {
  const partnershipId = partnershipIdQuerySelector(state, ownProps);
  const itemId = itemIdQuerySelector(state, ownProps);
  const selector = createPartnershipFilteredItemsSelector(itemId);

  return {
    items: itemsSelector(state),
    partnershipItems: selector(state, partnershipId),
  };
};

const enhance = compose(withRouter, connect(mapStateToProps));

export default enhance(ExternalItemsList);

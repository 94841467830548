import PropTypes from 'prop-types';
import React from 'react';

const ExternalBody = (props) => (
  <div className="external-body--container">
    <div className="external-body">{props.children}</div>
  </div>
);

ExternalBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ExternalBody;

import { createByIdReducer } from 'store/redux';

import * as actionTypes from './actionTypes';

const byIdReducer = createByIdReducer({
  initialState: {},
  key: 'fundingInfoInternational',
  types: actionTypes.SUCCESS,
});

export default byIdReducer;

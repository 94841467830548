import { useSelectorWithProps } from 'hooks';
import {} from 'interfaces/item';
import {} from 'interfaces/redux';
import { paymentIdByPropSelector } from 'selectors/paymentsSelectors';
export const usePaymentData = (payment) => {
    const paymentDataFromRedux = useSelectorWithProps(paymentIdByPropSelector, typeof payment === 'string' ? payment : undefined);
    if (typeof payment === 'object') {
        return payment;
    }
    if (paymentDataFromRedux) {
        return paymentDataFromRedux;
    }
    return null;
};

import React from 'react';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { FieldEditWrapper } from 'components/form';
import { FieldEditButton } from 'components/form/FieldEditWrapper/components';

import EditButtonInput from './EditButtonInput';

const EditButtonInputContainer = (props) => (
  <FieldEditWrapper>
    <EditButtonInput {...props} EditButton={FieldEditButton} />
  </FieldEditWrapper>
);

export default EditButtonInputContainer;

import { connect } from 'react-redux';
import { change } from 'redux-form';

import InternationalPhoneInput from './InternationalPhoneInput';

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    input: { onBlur, onFocus },
    meta: { form },
  } = ownProps;

  return {
    onValuesChange: (key, value) => dispatch(change(form, key, value)),
    updateFieldMeta: (isFocus) => (isFocus ? onFocus() : onBlur()),
  };
};

export { mapDispatchToProps };
export default connect(null, mapDispatchToProps)(InternationalPhoneInput);

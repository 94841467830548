import React from 'react';
import { Text } from 'components/text';
import { TooltipMUIConditionalWrapper } from 'components/tooltip';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { typography } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';
const CrossBorderPaymentsNotEnabledTooltip = ({ children, isShown, placement = TooltipPlacement.RIGHT, }) => (React.createElement(TooltipMUIConditionalWrapper, { tooltipProps: isShown
        ? {
            arrow: true,
            padding: TooltipPadding.SKINNY,
            placement,
            title: (React.createElement(Text.Regular, { color: typography.TextColor.GREY_X_DARK, lineHeight: typography.TextLineHeight.MEDIUM, size: typography.TextSize.LEVEL_100 },
                `You haven't yet enabled payments to vendors using currencies other than USD. `,
                `Please speak to a ${PLATFORM_DISPLAY_SHORT_NAME} Customer Success Manager to enable this feature.`)),
        }
        : {} }, children));
export default CrossBorderPaymentsNotEnabledTooltip;

import ThunkFetch from 'helpers/thunkFetch';

import { addFundingAccountBankManualEndpoint } from 'services/api/fundingEndpoints';

import * as types from 'types/connectBank';

const submitFundingAccountBankManual = (payload) => {
  const t = new ThunkFetch({
    actions: {
      error: types.SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_FAILURE,
      request: types.SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_REQUEST,
      success: types.SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_SUCCESS,
    },
    endpoint: addFundingAccountBankManualEndpoint,
    method: 'POST',
    payload,
    requireAuth: true,
  });

  return t.makeRequest();
};

export default submitFundingAccountBankManual;

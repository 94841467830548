import * as routines from 'actions/routines/permissions';

import { permissionRelationships } from 'constants/relationships';

import { createByIdReducer } from 'store/redux';

const byIdReducer = createByIdReducer({
  key: 'permission',
  initialState: {},
  types: [routines.fetchPermissionsRoutine.SUCCESS],
  // add any relationships here
  relationships: permissionRelationships,
});

export default byIdReducer;

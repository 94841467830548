export var ItemFilterLabels;
(function (ItemFilterLabels) {
    ItemFilterLabels["AMOUNT_DUE"] = "Amount due";
    ItemFilterLabels["BULK_ACTION"] = "Bulk Action Id";
    ItemFilterLabels["BULK_ACTION_STATUS"] = "Bulk Action Status";
    ItemFilterLabels["BANK_ACCOUNT"] = "Bank account";
    ItemFilterLabels["CREATED_DATE"] = "Created date";
    ItemFilterLabels["CURRENT_APPROVER"] = "Current approver";
    ItemFilterLabels["CURRENT_APPROVER_STATUS"] = "Current approver status";
    ItemFilterLabels["DUE_DATE"] = "Due date";
    ItemFilterLabels["INVOICE_NUMBERS"] = "Invoice number";
    ItemFilterLabels["ITEM_STATUS"] = "Item status";
    ItemFilterLabels["ITEM_ID"] = "Item Id";
    ItemFilterLabels["PAYMENT_ID"] = "Payment Id";
    ItemFilterLabels["PO_MATCH_TYPE"] = "PO matching type";
    ItemFilterLabels["PO_DISCREPANCY_TYPE"] = "PO discrepancy type";
})(ItemFilterLabels || (ItemFilterLabels = {}));
export var ItemFilterUrlParams;
(function (ItemFilterUrlParams) {
    ItemFilterUrlParams["FILTER_AMOUNT_DUE_URL_PARAM"] = "amount_due";
    ItemFilterUrlParams["FILTER_BANK_ACCOUNT_URL_PARAM"] = "funding_account";
    ItemFilterUrlParams["FILTER_BULK_ACTION_URL_PARAM"] = "bulk_action";
    ItemFilterUrlParams["FILTER_BULK_ACTION_STATUS_URL_PARAM"] = "bulk_action_status";
    ItemFilterUrlParams["FILTER_CREATED_DATE_URL_PARAM"] = "created__date";
    ItemFilterUrlParams["FILTER_DATE_DUE_URL_PARAM"] = "date_due";
    ItemFilterUrlParams["FILTER_INVOICE_NUMBERS_URL_PARAM"] = "invoice_numbers";
    ItemFilterUrlParams["FILTER_ITEM_STATUS_URL_PARAM"] = "status";
    ItemFilterUrlParams["FILTER_PAYMENT_ID_URL_PARAM"] = "payment";
    ItemFilterUrlParams["FILTER_PAYMENT_APPROVERS_URL_PARAM"] = "approver_id";
    ItemFilterUrlParams["FILTER_APPROVER_STATUS_URL_PARAM"] = "approver_status";
    ItemFilterUrlParams["FILTER_PO_MATCH_TYPE_URL_PARAM"] = "po_match_type";
    ItemFilterUrlParams["FILTER_PO_DISCREPANCY_TYPE_URL_PARAM"] = "po_discrepancy_type";
})(ItemFilterUrlParams || (ItemFilterUrlParams = {}));

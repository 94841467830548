import * as types from 'types/oldVersion';

export const initialState = {
  open: false,
};

const modalOldVersionModeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MODAL_OLD_VERSION_MODE_CLOSE:
      return initialState;

    case types.MODAL_OLD_VERSION_MODE_OPEN:
      return {
        ...state,
        ...action.payload,
        open: true,
      };

    default:
      return state;
  }
};

export default modalOldVersionModeReducer;

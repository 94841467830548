import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { currentCompanyCompanyTypeSelector } from 'selectors/currentCompanySelectors';

import PartnerCompanyBillingForm from './PartnerCompanyBillingForm';

const mapStateToProps = createStructuredSelector({
  companyType: currentCompanyCompanyTypeSelector,
});

export default connect(mapStateToProps)(PartnerCompanyBillingForm);

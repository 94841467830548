import { createAllIdsReducer } from 'store/redux';

import { FETCH_PARTNERSHIP_ITEMS_SUCCESS } from 'types/partnership';

/**
 * Action types on which the partnershipItems all ids state should be updated
 * @constant
 * @type {string[]}
 */
const actionTypes = [FETCH_PARTNERSHIP_ITEMS_SUCCESS];

/**
 * Partnership Items All Ids Reducer
 *
 * @type {ReduxReducer}
 */
const partnershipItemsAllIdsReducer = createAllIdsReducer({
  key: 'item',
  types: actionTypes,
});

export default partnershipItemsAllIdsReducer;

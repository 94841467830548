import { combineReducers } from 'redux';

import actionsReducer from 'reducers/partnershipsReducer/actionsReducer';
import allIdsReducer from 'reducers/partnershipsReducer/allIdsReducer';
import byIdReducer from 'reducers/partnershipsReducer/byIdReducer';
import errorReducer from 'reducers/partnershipsReducer/errorReducer';
import fetchReducer from 'reducers/partnershipsReducer/fetchReducer';
import partnershipFundingAccountsReducer from 'reducers/partnershipsReducer/fundingAccounts';
import lastFetchedReducer from 'reducers/partnershipsReducer/lastFetchedReducer';
import lastSubmittedReducer from 'reducers/partnershipsReducer/lastSubmittedReducer';
import submitReducer from 'reducers/partnershipsReducer/submitReducer';

const partnershipsReducer = combineReducers({
  actions: actionsReducer,
  allIds: allIdsReducer,
  byId: byIdReducer,
  errors: errorReducer,
  fundingAccounts: partnershipFundingAccountsReducer,
  isFetching: fetchReducer,
  isSubmitting: submitReducer,
  lastFetched: lastFetchedReducer,
  lastSubmitted: lastSubmittedReducer,
});

export default partnershipsReducer;

import React from 'react';
import { LabelLink } from 'components/link/LabelLink/LabelLink.styles';
import { WhiteSpace } from 'components/text';
import { ITEM_DETAILS_FILTERS } from 'enums/routes';
import { makeSimilarUrl } from 'helpers/urls';
import {} from '../../ContactRequiredToAllowSelectPaymentMethodHint.types';
const FinalizePaymentHintTextComponent = ({ history, onCloseExistingItemSidePanel, }) => {
    const partnerTabPath = makeSimilarUrl(ITEM_DETAILS_FILTERS.PARTNER);
    return (React.createElement("span", { className: "contact-required-to-select-payment-hint" },
        "To allow a vendor to select their payment method, please",
        React.createElement(WhiteSpace, null),
        React.createElement(LabelLink, { onClick: () => {
                onCloseExistingItemSidePanel();
                history.push(partnerTabPath);
            } }, "add a contact to this payment"),
        "."));
};
export default FinalizePaymentHintTextComponent;

import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/text';

import { typography } from 'constants/styles';

/**
 * Renders the label content for a single multi-select tag.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 */
const TagMultiValueLabel = ({ children }) => <Text.Bold color={typography.TextColor.BLUE_BOLD}>{children}</Text.Bold>;

TagMultiValueLabel.propTypes = {
  children: PropTypes.node,
};

TagMultiValueLabel.defaultProps = {
  children: undefined,
};

export default TagMultiValueLabel;

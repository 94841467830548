import PropTypes from 'prop-types';
import React from 'react';

import { EntityWidgetHintSection } from 'components/entityWidget/components';

import {
  isFundingAccountExternal,
  isFundingSourceInvalid,
  isFundingSourceInvalidMicroDepositsProcessed,
  isFundingSourceValid,
} from 'helpers/funding';
import { isBankAccountViewSubTypeExternal } from 'helpers/ui';

import { textHelpers } from 'modules/fundingAccount/bankAccount/helpers';

import AccountHintSectionPartnershipLinkMDActions from '../AccountHintSectionPartnershipLinkMDActions';
import AccountHintSectionVerifyActions from '../AccountHintSectionVerifyActions';

const BankAccountHintSections = (props) => {
  const {
    bankAccountContext,
    isSubmittingConvertFundingAccountFromExternal,
    onVerifyFundingAccountModalOpen,
    onSubmitConvertFundingAccountFromExternal,
    source,
  } = props;

  const { fundingAccount, fundingInfoBankAccount, viewSubType } = bankAccountContext;

  let sectionActions = null;

  const { dateMicroDepositsExpected, id: sourceId } = source;

  // If the funding source is valid, no hint is necessary!
  if (isFundingSourceValid(source)) {
    return null;
  }

  const isNeedToConvertExternalFundingAccount =
    isFundingAccountExternal(fundingAccount) &&
    isBankAccountViewSubTypeExternal(viewSubType) &&
    isFundingSourceInvalid(source);

  if (isFundingSourceInvalidMicroDepositsProcessed(source)) {
    sectionActions = (
      <AccountHintSectionVerifyActions
        accountNumber={fundingInfoBankAccount.accountNumber}
        key="AccountHintSectionVerifyActions"
        onButtonClick={() => onVerifyFundingAccountModalOpen(fundingAccount.id, sourceId)}
      />
    );
  }

  // This is only relevant for the external flow
  if (isNeedToConvertExternalFundingAccount) {
    sectionActions = (
      <AccountHintSectionPartnershipLinkMDActions
        isSubmitting={isSubmittingConvertFundingAccountFromExternal}
        key="AccountHintSectionPartnershipLinkMDActions"
        onButtonClick={() => onSubmitConvertFundingAccountFromExternal(fundingAccount.id)}
      />
    );
  }

  const hintTitle = textHelpers.getMicroDepositsHintTitle({
    expectedDate: dateMicroDepositsExpected,
    isNeedToConvertExternalFundingAccount,
    source,
  });

  const hintText = textHelpers.getMicroDepositsHintText({
    accountNumber: fundingInfoBankAccount.accountNumber,
    expectedDate: dateMicroDepositsExpected,
    isNeedToConvertExternalFundingAccount,
    source,
  });

  return (
    <EntityWidgetHintSection
      actions={sectionActions}
      dataFullStoryText
      key={`${fundingAccount.id}-${sourceId}`}
      text={hintText}
      title={hintTitle}
    />
  );
};

BankAccountHintSections.propTypes = {
  bankAccountContext: PropTypes.shape().isRequired,
  isSubmittingConvertFundingAccountFromExternal: PropTypes.bool,
  onVerifyFundingAccountModalOpen: PropTypes.func.isRequired,
  onSubmitConvertFundingAccountFromExternal: PropTypes.func.isRequired,
  source: PropTypes.shape().isRequired,
};

BankAccountHintSections.defaultProps = {
  isSubmittingConvertFundingAccountFromExternal: undefined,
};

export default BankAccountHintSections;

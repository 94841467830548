import {} from 'interfaces/webhooks';
import * as types from 'types/webhooks';
export const closeWebhookTestModal = () => ({
    type: types.CLOSE_WEBHOOKS_TEST_MODAL,
});
export const openWebhookTestModal = () => ({
    type: types.OPEN_WEBHOOKS_TEST_MODAL,
});
export const updateWebhookTest = (payload) => ({
    payload,
    type: types.UPDATE_WEBHOOKS_TEST,
});
export const removeWebhookTest = (payload) => ({
    payload,
    type: types.REMOVE_WEBHOOKS_TEST,
});

import queryString from 'query-string';

import { BASE_API_URL } from './index';

export const integrationsEndpoint = `${BASE_API_URL}/integrations/`;

export const integrationConfigsEndpoint = `${BASE_API_URL}/integrations/configs/`;

export const getDisconnectIntegrationConfigEndpoint = (integrationConfigId) =>
  `${BASE_API_URL}/integrations/configs/${integrationConfigId}/disconnect/`;

export const getIntegrationConfigEndpoint = (integrationConfigId) =>
  `${BASE_API_URL}/integrations/configs/${integrationConfigId}/`;

export const getIntegrationOAuthRedirectEndpoint = (integrationApp, redirect = window.location) =>
  `${BASE_API_URL}/integrations/connect/${integrationApp}/oauth_redirect/?redirect=${redirect}`;

export const getIntegrationConfigRefreshEndpoint = (integrationConfigId) =>
  `${BASE_API_URL}/integrations/configs/${integrationConfigId}/refresh/`;

// ledger
export const getIntegrationLedgerMatchingEndpoint = (configId, matchType, queryParams) => {
  const baseUrl = `${BASE_API_URL}/integrations/matching/${configId}/${matchType}/`;

  if (!queryParams) {
    return baseUrl;
  }
  return `${baseUrl}?${queryString.stringify(queryParams)}`;
};

/**
 * Returns endpoint used to fetch ledger account codes.
 * @param {string} integrationConfigId
 * @param {Object} options
 * @param {String} options.search
 * @return {string} the endpoint
 */
export const getIntegrationLedgerAccountCodesEndpoint = (integrationConfigId, options) => {
  const params = {
    'max-results': 10,
    ...options,
  };
  const queries = queryString.stringify(params);

  return `${BASE_API_URL}/integrations/configs/${integrationConfigId}/ledger-object-search/accounts/?${queries}`;
};

import {} from '../models';
export const internationalFieldsBaseQueryKey = 'internationalFields';
export const routeRulesBaseQueryKey = 'routeRules';
export const generateKeys = {
    routeRulesForCurrencyCode: (currencyCode, countryCode) => [
        routeRulesBaseQueryKey,
        currencyCode,
        { countryCode },
    ],
    internationalBankFieldsForCurrencyCode: (currencyCode, countryCode, businessType) => [internationalFieldsBaseQueryKey, currencyCode, { countryCode, businessType }],
    validateInternationalBeneficiary: ({ companyName, companyType, country, currency, firstName, lastName, ...rest }) => [
        country,
        currency,
        companyType,
        ...(companyName ? [companyName] : [firstName, lastName]),
        rest,
    ],
};

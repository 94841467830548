import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { ssoLoginRoutine } from 'actions/routines/auth';
import { isSSOSubmittingLoginSelector } from 'selectors/authSelectors';
import { currentSSOSettingsSelector } from 'selectors/ssoSelectors';
import { createMergeProps } from 'store/redux';
import OptionalSSOLoginForm from './OptionalSSOLoginForm';
const mapStateToProps = createStructuredSelector({
    currentSSOSettings: currentSSOSettingsSelector,
    isSSOSubmitting: isSSOSubmittingLoginSelector,
});
const mapDispatchToProps = {
    onSSOLoginSubmit: ssoLoginRoutine.trigger,
};
const mergeProps = createMergeProps((stateProps, dispatchProps) => {
    const { currentSSOSettings } = stateProps;
    return {
        onSSOLogin: () => dispatchProps.onSSOLoginSubmit({
            companyId: currentSSOSettings.company,
            ssoProvider: currentSSOSettings.ssoProvider,
            ssoProviderConnection: currentSSOSettings.ssoProviderConnection,
        }),
    };
});
const enhance = compose(connect(mapStateToProps, mapDispatchToProps, mergeProps));
export { mapDispatchToProps, mapStateToProps, mergeProps };
export default enhance(OptionalSSOLoginForm);

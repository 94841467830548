import {} from 'interfaces/fetchService';
import {} from 'interfaces/sso';
import { FetchService } from 'services';
import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { getSSOSettingsDetailEndpoint, ssoSettingsBaseEndpoint } from 'services/api/ssoEndpoints';
export const disableSSO = async (ssoSettingID, options = {}) => {
    const config = {
        ...options,
        endpoint: `${getSSOSettingsDetailEndpoint(ssoSettingID)}disable/`,
        method: 'POST',
        requireAuth: true,
    };
    return FetchService.request(config);
};
export const enableSSO = async (ssoSettingID, options = {}) => {
    const config = {
        ...options,
        endpoint: `${getSSOSettingsDetailEndpoint(ssoSettingID)}enable/`,
        method: 'POST',
        requireAuth: true,
    };
    return FetchService.request(config);
};
export const submitSSOSettings = async (payload, options = {}) => {
    const config = {
        ...options,
        endpoint: ssoSettingsBaseEndpoint,
        method: 'POST',
        payload: payloadToJSONAPI(payload, 'CompanySettingsSSO'),
        requireAuth: true,
    };
    return FetchService.request(config);
};
export const submitUpdateSSOSettings = async (payload, options = {}) => {
    const config = {
        ...options,
        endpoint: getSSOSettingsDetailEndpoint(payload.id),
        method: 'PATCH',
        payload: payloadToJSONAPI(payload, 'CompanySettingsSSO'),
        requireAuth: true,
    };
    return FetchService.request(config);
};

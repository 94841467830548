import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { InvoiceGeneratorTotalsDiscountPreTax } from '../invoiceGenerator';

/**
 * Renders the invoice generator totals panel field for pre-tax discount.
 * @param {ComponentProps} props
 * @param {string} props.name
 * @returns {StatelessComponent}
 */
const TotalsPanelDiscountPreTax = ({ name, ...rest }) => (
  <Field {...rest} component={InvoiceGeneratorTotalsDiscountPreTax} name={name} text="Discount" />
);

TotalsPanelDiscountPreTax.propTypes = {
  name: PropTypes.string.isRequired,
};

export default TotalsPanelDiscountPreTax;

import { revokeMembershipInviteRoutine } from 'actions/routines/inviteTeamMember';

import { createIsRevokingReducer } from 'store/redux';

export const finishCases = [revokeMembershipInviteRoutine.FAILURE, revokeMembershipInviteRoutine.SUCCESS];
export const requestCases = [revokeMembershipInviteRoutine.REQUEST];

const isRevokingReducer = createIsRevokingReducer(finishCases, requestCases);

export default isRevokingReducer;

import { Button, Popover } from '@routable/gross-ds';
import { ChevronDown } from '@carbon/icons-react';
import React, { useRef } from 'react';
import {} from 'react-stately';
import { filterQuickActionIconStyles, filterQuickActionPopoverStyles } from './FilterQuickAction.styles';
import {} from './FilterQuickAction.types';
export const FilterQuickAction = ({ children, onApply, onRemove, onReset, tag }) => {
    const state = useRef(null);
    const handleApplyFilter = () => {
        onApply();
        state.current?.close();
    };
    return (React.createElement(Popover, { content: React.createElement("div", { className: filterQuickActionPopoverStyles },
            tag,
            React.createElement(ChevronDown, { className: filterQuickActionIconStyles })), ref: state, keepOpen: true, onDismiss: onReset, popoverClassName: "min-w-[320px] max-w-[425px] rounded-lg", placement: "bottom" },
        React.createElement("div", { className: "w-full group", "data-quick-action": "true" },
            React.createElement("div", { className: "p-5" }, children),
            React.createElement("div", { className: "p-5 border-t border-grey-10 flex justify-end gap-3" },
                React.createElement(Button, { "data-testid": "filter-tag--clear-filter", onPress: onRemove, variant: "outline", size: "small" }, "Clear"),
                React.createElement(Button, { "data-testid": "filter-tag--apply-filter", onPress: handleApplyFilter, size: "small" }, "Apply")))));
};

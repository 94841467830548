import PropTypes from 'prop-types';
import React from 'react';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { Text } from 'components/text';

import { TextColor, TextSize } from 'constants/styles/typography';

/**
 * Wraps children with our tooltip text content styling.
 * @param {ComponentProps} props
 * @param {*} props.children
 * @param {string} [props.className]
 * @return {StatelessComponent}
 */
const TooltipContentText = ({ children, className }) => (
  <Text.Bold className={className} color={TextColor.WHITE} size={TextSize.LEVEL_100}>
    {children}
  </Text.Bold>
);

TooltipContentText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
};

TooltipContentText.defaultProps = {
  className: undefined,
};

export default TooltipContentText;

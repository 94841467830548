import React from 'react';
import { useSelector } from 'react-redux';
import { commonFormFields, partnerFundingAccountFormFields } from 'constants/formFields';
import { formNamesFunding } from 'constants/forms';
import { useSelectorWithProps } from 'hooks';
import { partnerCompanyAddressFromPartnershipPropSelector } from 'queries/companyCompoundSelectors';
import { createFieldValueSelector } from 'selectors/createFormSelectors';
import InternationalPartnerFundingAccountAddressDetails from './InternationalPartnerFundingAccountAddressDetails';
const countryFormFieldSelector = createFieldValueSelector(formNamesFunding.ADD_PARTNER_INTERNATIONAL_FUNDING_ACCOUNT, commonFormFields.PARTNER_COUNTRY);
const applyRegisteredAddressSelector = createFieldValueSelector(formNamesFunding.ADD_PARTNER_INTERNATIONAL_FUNDING_ACCOUNT, partnerFundingAccountFormFields.APPLY_REGISTERED_ADDRESS);
const InternationalPartnerFundingAccountAddressDetailsContainer = ({ partnership }) => {
    const country = useSelector(countryFormFieldSelector);
    const shouldApplyRegisteredAddress = useSelector(applyRegisteredAddressSelector);
    const address = useSelectorWithProps(partnerCompanyAddressFromPartnershipPropSelector, {
        partnership,
    });
    const showApplyRegisteredAddress = Boolean(address) && address?.country === partnership?.countryCodePartner;
    return (React.createElement(InternationalPartnerFundingAccountAddressDetails, { address: address, country: country, partnership: partnership, shouldApplyRegisteredAddress: shouldApplyRegisteredAddress, showApplyRegisteredAddress: showApplyRegisteredAddress }));
};
export default InternationalPartnerFundingAccountAddressDetailsContainer;

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { IconNames } from 'components/icon';
import { ModalFooter, ModalFooterButton, ModalFooterButtonAllDone, ModalFooterButtonCancel } from 'components/modal';

import { formNamesFunding } from 'constants/forms';
import { sizes } from 'constants/styles';

import { text } from 'modules/connectBank/verifyFundingAccount/constants';

import { vfaModalIsFetchingSelector, vfaModalLastSubmittedSelector } from 'queries/verifyFundingAccountModalSelectors';

const VerifyFundingAccountFormModalFooter = ({ isSubmitting, lastSubmitted, onCloseModal }) => {
  if (lastSubmitted) {
    return (
      <ModalFooter>
        <ModalFooterButtonAllDone onCloseModal={onCloseModal} />
      </ModalFooter>
    );
  }

  return (
    <ModalFooter>
      <div className="content--left">
        <ModalFooterButtonCancel onCloseModal={onCloseModal} />
      </div>

      <div className="content--right">
        <ModalFooterButton
          htmlFor={formNamesFunding.VERIFY_FUNDING_ACCOUNT}
          isActionButton
          isLoading={isSubmitting}
          leftIconName={IconNames.TICK_CIRCLE}
          leftIconSize={sizes.iconSizes.EXTRA_MEDIUM}
          type="submit"
        >
          {text.submitMicrodepositsButtonText}
        </ModalFooterButton>
      </div>
    </ModalFooter>
  );
};

VerifyFundingAccountFormModalFooter.propTypes = {
  isSubmitting: PropTypes.bool,
  lastSubmitted: PropTypes.instanceOf(Date),
  onCloseModal: PropTypes.func.isRequired,
};

VerifyFundingAccountFormModalFooter.defaultProps = {
  isSubmitting: undefined,
  lastSubmitted: undefined,
};

const mapStateToProps = (state) => ({
  isSubmitting: vfaModalIsFetchingSelector(state),
  lastSubmitted: vfaModalLastSubmittedSelector(state),
});

export { VerifyFundingAccountFormModalFooter };
export default connect(mapStateToProps)(VerifyFundingAccountFormModalFooter);

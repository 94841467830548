import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

import { isTinTypeEin, isTinTypeSsn } from 'helpers/taxes';

/**
 * Text for tooltip used in InfoCardAmount.
 * @param {string} partnershipName
 * @returns {string}
 */
export const createAmountPaidTooltipText = (partnershipName) => `The amount displayed on your actual 1099 will be the 
sum of all the payments to you (inside and outside of ${PLATFORM_DISPLAY_SHORT_NAME}) by ${partnershipName}. This could differ from the amount 
displayed.`;

/**
 * Text displayed after checkbox to confirm that vendors want their 1099 e-delivered
 * @param {string} companyName
 * @returns {string}
 */
export const getConsentText = (companyName) => `I consent to allow ${companyName} to send me my 1099 electronically.`;

/**
 * Final warning in hint in modal before entering TIN to confirm.
 * @param {string} tinType - ssn or ein
 * @returns {string}
 */
export const getDeliveryConsentText = (tinType) => `By entering your ${tinType.toUpperCase()} below and confirming your 
information you verify that all the provided information is accurate to your best knowledge, and are ready to have this 
info filed to the IRS.`;

/**
 * Hint title for final warning in modal before entering TIN to confirm.
 * @param {string} displayTinType - see getTinTypeForDisplay
 * @returns {string}
 */
export const getDeliveryConsentTitle = (displayTinType) => `In order to file your taxes, we need to reconfirm your 
${displayTinType}.`;

/**
 * Lengthens SSN or EIN to longform. If no option supplied, use TIN.
 * @param {string} tinType
 * @returns {string}
 */
export const getTinTypeForDisplay = (tinType) => {
  if (isTinTypeEin(tinType)) {
    return 'Business EIN';
  }

  if (isTinTypeSsn(tinType)) {
    return 'Social Security Number';
  }

  return 'Tax Identification Number';
};

import { fetchItemsRoutine, submitItemRoutine } from 'actions/routines/item';

import { allKeys } from 'helpers/utility';

import { CLEAR_APPROVALS } from 'types/approvals';
import { FETCH_ITEM_SUCCESS, SUBMIT_EXISTING_ITEMS_SUCCESS, UPDATE_EXISTING_ITEMS_SUCCESS } from 'types/item';

const allApprovalsReducer = (state = [], action) => {
  switch (action.type) {
    case fetchItemsRoutine.SUCCESS:
    case FETCH_ITEM_SUCCESS:
    case submitItemRoutine.FULFILL:
    case SUBMIT_EXISTING_ITEMS_SUCCESS:
    case UPDATE_EXISTING_ITEMS_SUCCESS:
      return Array.from(new Set([...state, ...allKeys(action.payload.itemSideApproval)]));

    case CLEAR_APPROVALS:
      return [];

    default:
      return state;
  }
};

export default allApprovalsReducer;

import { createBaseLogger } from '@routable/shared';
import { areDevtoolsEnabled } from 'helpers/env';
import { createConsoleTransport } from './consoleTransport';
import { LogLevel } from './systemLogger.types';
import { withLogLevelFilter } from './withLogLevelFilter';
const transports = [
    withLogLevelFilter(createConsoleTransport(), areDevtoolsEnabled() ? LogLevel.TRACE : LogLevel.INFO),
];
export const systemLogger = createBaseLogger({
    transports,
});

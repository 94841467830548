import { WarningAltFilled } from '@carbon/icons-react';
import { Badge, Skeleton } from '@routable/gross-ds';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ExistingItem } from 'context';
import { isItemStatusCanceled, isItemStatusCompleted, isItemStatusExternallyPaid } from 'helpers/items';
import { hasLength } from 'helpers/utility';
import { ledgerIntegrationSelector } from 'selectors/integrationsSelectors';
import { getFieldProps } from './helpers';
import RenderValue from './RenderValue';
const PresentationalField = ({ fieldType, options, placeholder, ...props }) => {
    const item = useContext(ExistingItem);
    const ledger = useSelector(ledgerIntegrationSelector);
    const { fieldPath, value } = getFieldProps({
        item,
        ledger,
        options,
        ...props,
    });
    if (item && fieldPath === 'ledgerStatus' && !value) {
        return null;
    }
    if (item && fieldPath === 'tagLinks' && !hasLength(value)) {
        return null;
    }
    const showOverdueBadge = fieldPath === 'dateDue' &&
        item?.daysUntilDue < 0 &&
        !isItemStatusCanceled(item) &&
        !isItemStatusCompleted(item) &&
        !isItemStatusExternallyPaid(item);
    return (React.createElement("div", { className: "flex flex-col border-b border-grey-10 pb-4" },
        React.createElement("span", { className: "text-sm text-grey-60" }, fieldPath === 'ledgerStatus' ? `${ledger?.name} status` : placeholder),
        React.createElement("div", { className: "flex flex-wrap" },
            React.createElement("span", { className: clsx('text-sm max-w-full leading-6', {
                    'text-black': !!value,
                    'text-grey-50': !value,
                    'w-full': !item || options?.length === 0,
                }) }, !item ? (React.createElement(Skeleton, { shape: "round" })) : (React.createElement(RenderValue, { fieldPath: fieldPath, fieldType: fieldType, item: item, options: options, value: value }))),
            showOverdueBadge && (React.createElement(Badge, { intent: "warning" },
                React.createElement(WarningAltFilled, { className: "w-3 h-3 mr-2" }),
                "Overdue")))));
};
export default React.memo(PresentationalField);

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { createItemUISelectedCompanyCurrencyCodePartnerSelector } from 'queries/createItemFormSelectors';

import { createItemFormMetaSelector } from 'selectors/forms';

import TotalsPanelDiscountPostTax from './TotalsPanelDiscountPostTax';

export const mapStateToProps = createStructuredSelector({
  currencyCode: createItemUISelectedCompanyCurrencyCodePartnerSelector,
  meta: createItemFormMetaSelector,
});

export default connect(mapStateToProps)(TotalsPanelDiscountPostTax);

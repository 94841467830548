import { call, put, spawn, take } from 'redux-saga/effects';

import * as routines from 'actions/routines/countries';

import { parseCaughtError, parseErrorResponse, getRequestErrorAction } from 'helpers/errors';

import * as api from './api';

/**
 * Handle fetching all countries
 * @returns {IterableIterator<*>}
 */
export function* fetchCountries() {
  let errorData = {};

  yield put(routines.fetchCountriesRoutine.request());

  try {
    const response = yield call(api.fetchCountries);

    if (response.ok) {
      yield put(routines.fetchCountriesRoutine.success(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(errorData);
  yield put(errorAction(routines.fetchCountriesRoutine.failure, errorData));
}

/**
 * Listens for redux actions related to countries.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([routines.fetchCountriesRoutine.TRIGGER]);

    switch (action.type) {
      case routines.fetchCountriesRoutine.TRIGGER:
        yield spawn(fetchCountries, action);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root countries saga.
 * @return {IterableIterator<*>}
 */
export default function* countries() {
  yield watch();
}

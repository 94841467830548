import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { itemApprovalsApi } from '@routable/ear';
import { systemLogger } from 'helpers/systemLogger';
import { propsSelector } from 'selectors/globalSelectors';
export const itemEarApprovalsRoutine = createAsyncThunk('get:items/ear/approvals', async (ids, thunkApi) => {
    if (ids.length === 0) {
        return thunkApi.fulfillWithValue([]);
    }
    try {
        const results = await itemApprovalsApi.getMany(ids);
        return thunkApi.fulfillWithValue(results.data);
    }
    catch (error) {
        systemLogger.log({
            level: 'ERROR',
            message: 'Error loading approvals',
            ids,
            error,
        });
        return thunkApi.rejectWithValue(ids);
    }
});
const initialState = {
    request: {},
    approvals: {},
};
export const itemEarApprovalsSlice = createSlice({
    name: 'itemEarApprovals',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(itemEarApprovalsRoutine.pending, (state, action) => {
            action.meta.arg.forEach((id) => {
                state.request[id] = 'LOADING';
            });
        })
            .addCase(itemEarApprovalsRoutine.fulfilled, (state, action) => {
            action.meta.arg.forEach((id) => {
                state.request[id] = 'FAILURE';
            });
            if (Array.isArray(action.payload)) {
                action.payload.forEach((approval) => {
                    state.request[approval.relatedObjectId] = 'SUCCESS';
                    state.approvals[approval.relatedObjectId] = approval;
                });
            }
        })
            .addCase(itemEarApprovalsRoutine.rejected, (state, error) => {
            error.meta.arg.forEach((id) => {
                state.request[id] = 'FAILURE';
            });
        });
    },
});
export const getItemEarApprovals = (reduxState) => reduxState[itemEarApprovalsSlice.name];
export const getItemEarApprovalRequestStatus = createSelector([getItemEarApprovals, propsSelector], (approvals, id) => approvals?.request[id]);

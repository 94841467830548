import React from 'react';
import { LinkNewPage } from 'components/link';
import { Text } from 'components/text';
import { TooltipMUIConditionalWrapper } from 'components/tooltip';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { typography } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';
import helpDocs from 'helpers/helpDocs';
const CurrencyNotEnabledOnLedgerTooltip = ({ children, currencyCode, isRelatedToPartnership, isShown, ledgerName, partnershipType, placement = TooltipPlacement.RIGHT, }) => {
    const partnershipText = isRelatedToPartnership ? `on this ${partnershipType} ` : '';
    const appendedText = isRelatedToPartnership
        ? '.'
        : `, and it is the currency associated with this ${partnershipType} on ${PLATFORM_DISPLAY_SHORT_NAME}.`;
    return (React.createElement(TooltipMUIConditionalWrapper, { tooltipProps: isShown
            ? {
                arrow: true,
                padding: TooltipPadding.SKINNY,
                placement,
                title: (React.createElement(Text.Regular, { color: typography.TextColor.GREY_X_DARK, lineHeight: typography.TextLineHeight.MEDIUM, size: typography.TextSize.LEVEL_100 },
                    `${currencyCode} is not enabled as a currency option ${partnershipText}in your ${ledgerName} account${appendedText} `,
                    React.createElement(LinkNewPage, { className: "primary", href: helpDocs.PARTNER_COUNTRY_CURRENCY_HINT }, "Learn more about how to fix this \u2197"))),
            }
            : {} }, children));
};
export default CurrencyNotEnabledOnLedgerTooltip;

import PropTypes from 'prop-types';
import React from 'react';

import { isCompanyTypePersonal } from 'helpers/currentCompany';

import PartnerCompanyTaxFormBusiness from 'modules/createPartnerCompany/presenters/PartnerCompanyTaxFormBusiness';
import PartnerCompanyTaxFormPersonal from 'modules/createPartnerCompany/presenters/PartnerCompanyTaxFormPersonal';

/**
 * Entity input fields for personal & business vendors/company.
 * @param {ComponentProps} props
 * @param {boolean} props.collectVendorTaxInfo
 * @param {UserTypes} props.companyType
 * @param {Object} props.formUIState
 * @param {boolean} props.hideLabel
 * @param {function} props.onToggleInputForTin
 * @param {function} props.onToggleSSNEIN
 * @returns {StatelessComponent}
 */
const PartnerCompanyTaxForm = (props) => {
  const { collectVendorTaxInfo, companyType } = props;

  // [DEV-2661] @adamjaffeback we do not show this for cross-border payments, but we're already hiding it
  // PartnerCompanyTaxForm in the parent component, so there's no need to check again here to render null
  if (isCompanyTypePersonal(companyType)) {
    if (collectVendorTaxInfo) {
      return <PartnerCompanyTaxFormPersonal {...props} />;
    }
    return null;
  }

  return <PartnerCompanyTaxFormBusiness {...props} />;
};

PartnerCompanyTaxForm.propTypes = {
  collectVendorTaxInfo: PropTypes.bool,
  companyType: PropTypes.string.isRequired,
  formUIState: PropTypes.shape({}).isRequired,
  hideLabel: PropTypes.bool,
  onToggleInputForTin: PropTypes.func.isRequired,
  onToggleSSNEIN: PropTypes.func.isRequired,
};

PartnerCompanyTaxForm.defaultProps = {
  collectVendorTaxInfo: true,
  hideLabel: undefined,
};

export default PartnerCompanyTaxForm;

import React from 'react';

import { Card, Col, Row } from 'components';
import { ReloadPageButton } from 'components/buttonTypes';
import BrandNav from 'components/nav/BrandNav';

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

import { OldVersionModeBody } from 'modules/maintenance/oldVersion/components';

import 'modules/maintenance/Maintenance.scss';
import { MaintenanceHeading } from '../components';

const OldVersionModeFullPage = () => (
  <div className="branded--container old-version-mode">
    <BrandNav />
    <div className="branded--contents">
      <Row cols={1} flexWrap="wrap">
        <Col>
          <MaintenanceHeading
            className="margin-bottom--large"
            title={`A new version of ${PLATFORM_DISPLAY_SHORT_NAME} was released.`}
          />
        </Col>
        <Col>
          <Card className="generic-card">
            <OldVersionModeBody />
            <ReloadPageButton className="margin-top--m-large" fullWidth />
          </Card>
        </Col>
      </Row>
    </div>
  </div>
);

export default OldVersionModeFullPage;

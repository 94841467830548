import { combineReducers } from 'redux';

import bulkActionReducer from './bulkActionReducer';
import currentStepReducer from './currentStepReducer';
import isOpenReducer from './isOpenReducer';

const bulkActionsModalReducer = combineReducers({
  bulkAction: bulkActionReducer,
  isOpen: isOpenReducer,
  currentStep: currentStepReducer,
});

export default bulkActionsModalReducer;

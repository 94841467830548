import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { BoxV2 } from 'components';
import { RESET_PASSWORD } from 'constants/routes';
import { SSOProviders } from 'enums/sso';
import { asPath } from 'helpers/routeHelpers';
import ChangeWorkspace from '../ChangeWorkspace';
import EmailPasswordFormContainer from '../EmailPasswordForm';
import LoginWithGoogleButton from '../LoginWithGoogleButton/LoginWithGoogleButton';
import LoginWithSSOButton from '../LoginWithSSOButton/LoginWithSSOButton';
import './RequiredSSOLoginForm.scss';
const RequiredSSOLoginForm = ({ currentSSOSettings, onSSOLogin, isSSOSubmitting, }) => {
    let buttonComponent;
    if (currentSSOSettings.ssoProvider === SSOProviders.GOOGLE_OAUTH2) {
        buttonComponent = React.createElement(LoginWithGoogleButton, { isLoading: isSSOSubmitting, onClick: onSSOLogin });
    }
    else {
        buttonComponent = React.createElement(LoginWithSSOButton, { isLoading: isSSOSubmitting, onClick: onSSOLogin });
    }
    const [showEmailPasswordForm, setShowEmailPasswordForm] = React.useState(false);
    const toggleLoginForm = React.useCallback(() => {
        setShowEmailPasswordForm(!showEmailPasswordForm);
    }, [showEmailPasswordForm]);
    return (React.createElement(BoxV2, { className: "box-v2--container--login required-sso-login-form", subtitle: React.createElement(ChangeWorkspace, null), title: "Log in with single sign-on" }, showEmailPasswordForm ? (React.createElement(React.Fragment, null,
        React.createElement(EmailPasswordFormContainer, { loginBtnText: "Log in as admin", usernameFieldLabel: "Admin login" }),
        React.createElement("div", { className: "links-container" },
            React.createElement(Link, { className: classNames({
                    link: true,
                    'margin-bottom--xm': true,
                }), to: asPath(RESET_PASSWORD) }, "Forgot your password?"),
            React.createElement("a", { className: "link", onClick: onSSOLogin, role: "presentation" }, "Login with Google")))) : (React.createElement(React.Fragment, null,
        buttonComponent,
        React.createElement("div", { className: "margin-top--m-large text-align--center" },
            React.createElement("span", { className: classNames({
                    'font-sm': true,
                    'font-weight--regular': true,
                    'font-color--grey--xx-dark': true,
                    'margin-right--sm': true,
                }) }, "Admins can also"),
            React.createElement("a", { className: classNames({
                    'dark-blue': true,
                    'font-sm': true,
                }), onClick: toggleLoginForm, role: "presentation" }, "login here"))))));
};
export default RequiredSSOLoginForm;

import {} from 'redux';
const filter = {};
export const generateThrottleMiddleware = (defaultTimeout = 100) => () => (next) => (action) => {
    if (!action?.meta?.throttle) {
        return next(action);
    }
    const id = (action?.meta?.throttle?.id ?? action.type);
    if (id && filter[id]) {
        return null;
    }
    const throttleTimeout = action?.meta?.throttle.timeout ?? defaultTimeout;
    filter[id] = setTimeout(() => {
        filter[id] = undefined;
    }, throttleTimeout);
    return next(action);
};

import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'components/icon';

import { sizes } from 'constants/styles';
import { TagType, TagTypeIcon } from 'constants/ui';

import { tagTypeProp } from 'helpers/propTypes';
import { allValues } from 'helpers/utility';

/**
 * Renders the tag label's icon.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 */
const TagIcon = ({ size, type }) => (
  <span className="tag-icon">
    <Icon className="not-modified" icon={TagTypeIcon[type]} size={size} />
  </span>
);

TagIcon.propTypes = {
  size: PropTypes.oneOf(allValues(sizes.iconSizes)),
  type: tagTypeProp,
};

TagIcon.defaultProps = {
  size: sizes.iconSizes.EXTRA_SMALL,
  type: TagType.DEFAULT,
};

export default TagIcon;

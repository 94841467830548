import { createSelector } from 'reselect';

import { allValues, firstValueOrDefault } from 'helpers/utility';

/**
 * Top level roles selector
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
const getState = (state) => state.roles;

/**
 * Selects all IDs of roles from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {string[]}
 */
export const rolesAllIdsSelector = createSelector([getState], (roles) => roles.allIds);

/**
 * Selects roles by id from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
export const rolesByIdSelector = createSelector([getState], (roles) => roles.byId);

/**
 * Selects first role from state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
export const firstRoleSelector = createSelector([rolesByIdSelector], (rolesById) =>
  firstValueOrDefault(allValues(rolesById), {}),
);

/**
 * Selects the roles errors from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
export const rolesErrorsSelector = createSelector([getState], (roles) => roles.errors);

/**
 * Selects the isFetching boolean for roles from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {boolean}
 */
export const isFetchingRolesSelector = createSelector([getState], (roles) => roles.isFetching);

/**
 * Selects the lastFetched data for roles from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {DateMaybe}
 */
export const rolesLastFetchedSelector = createSelector([getState], (roles) => roles.lastFetched);

import { combineReducers } from 'redux';
import allIdsReducer from './allIdsReducer';
import byIdReducer from './byIdReducer';
import errorsReducer from './errorsReducer';
import isFetchingReducer from './isFetchingReducer';
import lastFetchedReducer from './lastFetchedReducer';
const purposeCodeRootReducer = combineReducers({
    allIds: allIdsReducer,
    byId: byIdReducer,
    errors: errorsReducer,
    isFetching: isFetchingReducer,
    lastFetched: lastFetchedReducer,
});
export default purposeCodeRootReducer;

export const LineItemStyles = {
    ACCOUNT: 'account',
    ITEM: 'item',
};
export const QBOLineItemColumnText = {
    BILLABLE: 'Bill',
    CATEGORY: 'Category (Account)',
    CUSTOMER: 'Customer',
    ITEM: 'Product/Service',
    MARKUP: 'Markup',
    PRICE: 'Rate',
    TAX: 'Tax',
};
export const lineItemsBlockLabelInformal = 'informal';
export const lineItemCachedInfoAvailabilityDate = '2020-08-28';
export const lineItemsMinRowsShowMore = 3;
export const lineItemsDataFields = {
    ACCOUNT_NAME: 'account.name',
    CLASS: 'extended.class.name',
    DEPARTMENT_NAME: 'extended.department.name',
    ITEM_NAME: 'item.name',
    LOCATION_NAME: 'extended.location.name',
};
export const lineItemsTableFieldNames = {
    ACCOUNT: 'Account',
    ACCOUNT_CATEGORY: 'Category (Account)',
    CLASS: 'Class',
    DEPARTMENT: 'Department',
    ITEM: 'Item',
    LOCATION: 'Location',
    PRODUCT_SERVICE: 'Product/Service',
};
export const lineItemsDataFieldNames = {
    ACCOUNT_NAME: lineItemsTableFieldNames.ACCOUNT,
    CLASS: lineItemsTableFieldNames.CLASS,
    DEPARTMENT_NAME: lineItemsTableFieldNames.DEPARTMENT,
    ITEM_NAME: lineItemsTableFieldNames.ITEM,
    LOCATION_NAME: lineItemsTableFieldNames.LOCATION,
};
export const LINE_ITEMS_TABLE_TOOLTIP_MIN_WIDTH = 282;
export const LINE_ITEMS_TABLE_TOOLTIP_MAX_WIDTH = 350;
export const LineItemBillCheckbox = {
    BILLABLE: 'billable',
    NOT_BILLABLE: 'not-billable',
};
export const LINE_ITEM_EMAIL_DOCUMENT = 'Email Document';
export const LINE_ITEMS_ACCOUNT_PORTAL_ID = 'create-item--line-items-account';
export const LINE_ITEMS_ITEM_PORTAL_ID = 'create-item--line-items-item';
export const LINE_ITEMS_DRAWER_HEADER_HEIGHT_COLLAPSED = '50px';
export const LINE_ITEMS_DRAWER_HEADER_HEIGHT_OPEN = '270px';
export const LINE_ITEMS_DRAWER_TABS_EVENT_NAME = 'lineItemsDrawerTabs';

import React from 'react';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import EditButtonInput from 'components/form/EditButtonInput';

import { digitsOnly } from 'helpers/fieldNormalizers';
import { TINValidator, requiredValidator } from 'helpers/fieldValidation';

/**
 * Input field for editing/viewing a generic TIN, when we don't know whether the user is confirming a EIN or SSN.
 * @returns {StatelessComponent}
 * @constructor
 */
const GenericTINInput = (props) => {
  const tinValidator = React.useMemo(() => TINValidator('TIN'), []);

  return (
    <EditButtonInput
      {...props}
      maxLength={9}
      normalize={digitsOnly}
      placeholder="Tax Identification Number"
      staticValue="•••••••••"
      type="text"
      validate={[requiredValidator, tinValidator]}
    />
  );
};

export default GenericTINInput;

import { routableApi } from './routableApi';
const extendedExporterApi = routableApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        exporter: builder.query({
            query: (url) => ({ url }),
        }),
    }),
});
export const { useExporterQuery, useLazyExporterQuery } = extendedExporterApi;

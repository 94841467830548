import { createSelector } from 'reselect';

/**
 * Selects the errors object from the state
 * @function
 * @param {Object} state - Redux state
 * @return {Object}
 */
export const errorsSelector = (state) => state.errors;

/**
 * Selects the notFoundError boolean
 * @function
 * @param {ReduxState} Redux state
 * @return {boolean}
 */
export const notFoundErrorSelector = createSelector([errorsSelector], (errors) => errors.notFoundError);

/**
 * Selects the disabledUserError boolean
 * @function
 * @param {ReduxState} Redux state
 * @return {boolean}
 */
export const disabledUserErrorSelector = createSelector([errorsSelector], (errors) => errors.disabledUserError);

/**
 * Selects the disabledUserError boolean
 * @function
 * @param {ReduxState} Redux state
 * @return {boolean}
 */
export const approvalLevelErrorSelector = createSelector([errorsSelector], (errors) => errors.approvalLevelError);

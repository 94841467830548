import pluralize from 'pluralize';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { allKeys } from 'helpers/utility';

import { getAllIdsReducerResult } from 'store/redux';

import { FETCH_INTEGRATION_CONFIG_SUCCESS } from 'types/integrations';

/**
 * Handles FETCH_INTEGRATION_CONFIG_SUCCESS by mapping over ledger* config options, creating byId, allIds, and
 * lastFetched "reducers".
 * @function
 * @param {Object} state
 * @param {ReduxAction} action
 * @returns {Object}
 */
export const generateSubreducers = (state, action) => {
  const lastFetched = new Date();

  return (
    allKeys(action.payload)
      // get all of the extra keys that are ledger-specific
      .filter((key) => key.match(/ledger/))
      .reduce(
        (acc, key) => ({
          ...acc,
          // create a nested object for the extra key with reduced values
          [pluralize(key)]: {
            allIds: getAllIdsReducerResult(state, action.payload[key], true),
            byId: getObjectsByIdWithRelationships(action.payload[key]),
            lastFetched,
          },
        }),
        {},
      )
  );
};

const integrationConfigLedgerOptionsReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INTEGRATION_CONFIG_SUCCESS:
      return generateSubreducers(state, action);

    default:
      return state;
  }
};

export default integrationConfigLedgerOptionsReducer;

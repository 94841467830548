import * as Sentry from '@sentry/react';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { paymentsEndpoint } from 'services/api/paymentsEndpoint';
import { CardContainer, Container, ErrorContainer, SentryErrorDescription, SentryErrorId, SentryErrorTitle, } from './sentry.styled';
class CustomRoutableError extends Error {
    date;
    code;
    extraData;
    constructor(name, message, errorCode, extraData, includeErrorStack, ...params) {
        super(...params);
        if (Error.captureStackTrace && includeErrorStack) {
            Error.captureStackTrace(this, CustomRoutableError);
        }
        this.name = name;
        this.message = message;
        this.code = errorCode;
        this.date = new Date();
        this.cause = 'Sentry Test Error';
        this.extraData = extraData;
    }
}
const SentryError = ({ title, description, errorCode, }) => (React.createElement(React.Fragment, null,
    React.createElement(SentryErrorTitle, null, title),
    React.createElement(SentryErrorDescription, null, description),
    React.createElement(SentryErrorId, null,
        "Error Code: ",
        errorCode)));
export const SentryErrorPage = () => {
    const errorId = useRef(crypto.randomUUID());
    const [SentryErrorComponent, setSentryErrorComponent] = useState(React.createElement(SentryError, { description: "Sentry has sent a generic error to the server.", errorCode: errorId.current, title: "Generic Error" }));
    const pageParams = useParams();
    useEffect(() => {
        if (pageParams?.type) {
            switch (pageParams.type) {
                case '401':
                    fetch(paymentsEndpoint).then((resData) => {
                        const errorDescription = `Not authorized ${resData.status} for url ${resData.url}`;
                        setSentryErrorComponent(React.createElement(SentryError, { description: errorDescription, errorCode: errorId.current, title: "401 Not Authorized Error Test" }));
                        Sentry.captureException(new CustomRoutableError('SENTRYTEST:401', errorDescription, errorId.current, resData, false));
                    });
                    break;
                default:
                    setSentryErrorComponent(React.createElement(SentryError, { description: "Sentry has sent a generic error to the server.", errorCode: errorId.current, title: "Generic Error" }));
                    Sentry.captureException(new CustomRoutableError('SENTRYTEST:GENERIC', 'Generic Sentry Error', errorId.current, {}, false));
            }
        }
        else {
            Sentry.captureException(new CustomRoutableError('SENTRYTEST:GENERIC', 'Generic Sentry Error', errorId.current, {}, false));
        }
    }, [pageParams]);
    return (React.createElement(Container, null,
        React.createElement(CardContainer, { className: "max-w=[560px] shadow" },
            React.createElement(ErrorContainer, null, SentryErrorComponent))));
};

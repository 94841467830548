import { getQueryString } from 'helpers/queryParams';

import { BASE_API_URL } from 'services/api';

export const bulkImportEndpoint = `${BASE_API_URL}/bulk_importer/bulk_imports/`;

export const bulkActionsEndpoint = `${BASE_API_URL}/bulk_actions/bulk_action/`;

/**
 * Get endpoint for bulk actions with query params
 * @param {Object} [params={}]
 * @return {String}
 */
export const getBulkActionsEndpoint = (params = {}) => {
  if (params.url) {
    // Use URL if specified
    return params.url;
  }

  return `${bulkActionsEndpoint}?${getQueryString(params)}`;
};

/**
 * Get endpoint for bulk imports with query params
 * @param {Object} [params={}]
 * @return {String}
 */
export const getBulkImportEndpoint = (params = {}) => {
  if (params.url) {
    // Use URL if specified
    return params.url;
  }

  return `${bulkImportEndpoint}?${getQueryString(params)}`;
};

/**
 * Given a bulk import ID, returns the endpoint to use for that bulk import.
 * @param {string} id
 * @return {string}
 */
export const getBulkImportForIdEndpoint = (id) => `${bulkImportEndpoint}${id}/`;

/**
 * Given a bulk import ID, returns the endpoint to use for finalizing the import.
 * @param {string} id
 * @return {string}
 */
export const getBulkImportFinalizeEndpoint = (id) => `${getBulkImportForIdEndpoint(id)}finalize/`;

/**
 * Given an item kind, returns the endpoint for downloading the csv template file.
 * @param {ItemKind} itemKind
 * @return {string}
 */
export const getBulkImportDownloadCsvTemplateEndpoint = (itemKind) =>
  `${bulkImportEndpoint}template/?import_type=item_${itemKind}`;

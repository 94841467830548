import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

import { PLATFORM_URL } from '../../constants/platform';

import { getSiteTitle } from './helpers/title';

const RoutableHead = ({ children, description, image, noIndex, subtitle, title, twitterImage }) => {
  const siteTitle = getSiteTitle(title, subtitle);

  return (
    <Helmet>
      {/* Note: more settings are set in `public/index.html` */}

      {/* Common */}
      <title>{siteTitle}</title>

      {/* No index */}
      {noIndex && <meta content="noindex" name="robots" />}

      {/* Search Engine */}
      {description && <meta content={description} name="description" />}
      <meta content={image} name="image" />

      {/* Schema.org for Google */}
      {description && <meta content={description} itemProp="description" />}
      <meta content="Routable" itemProp="name" />
      <meta content={image} itemProp="image" />

      {/* Twitter */}
      <meta content="summary" name="twitter:card" />
      {description && <meta content={description} name="twitter:description" />}
      <meta content={siteTitle} name="twitter:title" />
      <meta content="@RoutableHQ" name="twitter:site" />
      <meta content={twitterImage} name="twitter:image:src" />

      {/* Open Graph general (Facebook, Pinterest & Google+) */}
      {description && <meta content={description} property="og:description" />}
      <meta content={image} property="og:image" />
      <meta content="en_US" property="og:locale" />
      <meta content="Routable" property="og:site_name" />
      <meta content={siteTitle} property="og:title" />
      <meta content="website" property="og:type" />
      <meta content={PLATFORM_URL} property="og:url" />
      <meta content="RoutableHQ" property="fb:admins" />

      {/* Misc */}
      {children}
    </Helmet>
  );
};

RoutableHead.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
  image: PropTypes.string,
  noIndex: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  twitterImage: PropTypes.string,
};

RoutableHead.defaultProps = {
  children: null,
  description: null,
  image: `${process.env.REACT_APP_STATIC_BASE_PATH}/images/sem/sem-generic.png`,
  noIndex: false,
  subtitle: null,
  title: 'Routable',
  twitterImage: `${process.env.REACT_APP_STATIC_BASE_PATH}/images/sem/sem-home-twitter.png`,
};

export default RoutableHead;

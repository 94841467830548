import PropTypes from 'prop-types';
import React from 'react';

import {
  DueDate,
  PartnerReference,
  PaymentTerms,
  Reference,
} from 'modules/external/externalReceipt/components/receiptItem';

const ExternalReceiptItem = ({ item }) => (
  <div className="panel-body external-receipt">
    <Reference item={item} />
    <PartnerReference item={item} />
    <PaymentTerms item={item} />
    <DueDate item={item} />
  </div>
);

ExternalReceiptItem.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default ExternalReceiptItem;

import { enableInternationalPaymentsRoutine } from 'actions/routines/currentCompany';
import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';

import getRelationships from 'store/redux';

import {
  GET_CURRENT_COMPANY_SUCCESS,
  UPDATE_CURRENT_COMPANY_SETTINGS_CURRENCY_SUCCESS,
  UPDATE_CURRENT_COMPANY_SETTINGS_SUCCESS,
  UPDATE_CURRENT_COMPANY_SETTINGS_COLLECT_VENDOR_TAXES_SUCCESS,
  UPDATE_CURRENT_COMPANY_SETTINGS_REQUIRE_TAX_FORM_BEFORE_PAYMENT_SUCCESS,
} from 'types/currentCompany';

const getCurrentCompanySettings = (settings) => {
  let currentCompanySettings = {};

  if (!settings) {
    return currentCompanySettings;
  }

  Object.keys(settings).forEach((settingId) => {
    const relationships = getRelationships(settings, settingId);

    currentCompanySettings = {
      id: settings[settingId].id,
      ...settings[settingId].attributes,
      ...relationships,
    };
  });

  return currentCompanySettings;
};

const companySettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CURRENT_COMPANY_SUCCESS:
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
    case enableInternationalPaymentsRoutine.SUCCESS:
      return getCurrentCompanySettings(action.payload.companySettings);

    case UPDATE_CURRENT_COMPANY_SETTINGS_REQUIRE_TAX_FORM_BEFORE_PAYMENT_SUCCESS:
    case UPDATE_CURRENT_COMPANY_SETTINGS_COLLECT_VENDOR_TAXES_SUCCESS:
    case UPDATE_CURRENT_COMPANY_SETTINGS_CURRENCY_SUCCESS:
    case UPDATE_CURRENT_COMPANY_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default companySettingsReducer;

import React from 'react';
import { Field } from 'redux-form';
import { Checkbox } from 'components';
import { isValueEmpty } from 'helpers/utility';
import {} from './InvoiceListCheckbox.types';
const defaultProps = {
    shouldStopPropagation: true,
};
const useNormalizeToggle = (initialValue = false, timeout = 100) => {
    const [value, setValue] = React.useState(initialValue);
    const [throttled, setThrottled] = React.useState(false);
    return (_updatedValue, prevValue) => {
        if (throttled) {
            return value;
        }
        const newValue = !(prevValue ?? value);
        setThrottled(true);
        setValue(newValue);
        setTimeout(setThrottled, timeout, false);
        return newValue;
    };
};
const InvoiceListCheckbox = ({ dataKey, rowData, tooltipProps }) => {
    const normalizeValue = useNormalizeToggle();
    return (React.createElement(Field, { component: Checkbox, id: rowData[dataKey], isDisabled: rowData.isCheckDisabled || !isValueEmpty(tooltipProps), name: `ledgerInvoiceRefs.${rowData[dataKey]}`, normalize: normalizeValue, props: defaultProps }));
};
export default InvoiceListCheckbox;

import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { textHelpers } from 'components/hintTypes/helpers';

import { Intent } from 'constants/ui';

import { isPaymentDeliveryOptionSpeedSameOrNextDay } from 'helpers/paymentMethods';

import { useRequestAnimationFrame } from 'hooks';

import BaseHint from '../BaseHint';
import PaymentAfterCutoffHint from '../PaymentAfterCutoffHint';

/**
 * Hint to display when a selected delivery option has a cutoff time.
 * @param {ComponentProps} props
 * @param {String} [props.className]
 * @param {String} [props.cutoff]
 * @param {ItemPaymentDeliveryMethod} [props.deliveryMethod]
 * @param {Boolean} [props.isCollapsible]
 * @param {Boolean} [props.isCollapsed]
 * @param {Boolean} [props.isForBulkAction]
 * @param {Boolean} [props.isPendingApproval]
 * @param {String|Number} [props.itemCount]
 * @param {Object} [props.speed]
 * @param {Object} [props.textProps]
 * @param {String} [props.title]
 * @param {Object} [props.titleProps]
 * @return {StatelessComponent}
 */
const PaymentCutoffHint = (props) => {
  const {
    className,
    cutoff,
    deliveryMethod,
    isCollapsible,
    isCollapsed,
    isForBulkAction,
    isPendingApproval,
    itemCount,
    speed,
    textProps,
    title,
    titleProps,
  } = props;

  const getTitle = () =>
    textHelpers.getPaymentCutoffCountdownTitle({
      cutoff,
      deliveryMethod,
      itemCount,
      speed,
      title,
    });

  const [countdownTitle, setCountdownTitle] = useState(getTitle());

  useRequestAnimationFrame(() => {
    if (cutoff) {
      const nextTitle = getTitle();
      setCountdownTitle(nextTitle);
    }
  }, 500);

  if (!cutoff) {
    return null;
  }

  const displayCountdownHint = Boolean(countdownTitle && isPaymentDeliveryOptionSpeedSameOrNextDay(speed));

  if (!displayCountdownHint) {
    return <PaymentAfterCutoffHint {...props} />;
  }

  const bodyText = textHelpers.getPaymentCutoffCountdownBody({
    cutoff,
    deliveryMethod,
    isForBulkAction,
    isPendingApproval,
    itemCount,
    speed,
    title,
  });

  return (
    <BaseHint
      className={className}
      intent={Intent.SCHEDULE}
      isCollapsed={isCollapsed}
      isCollapsible={isCollapsible}
      multiline
      text={bodyText}
      textProps={textProps}
      title={countdownTitle}
      titleProps={titleProps}
    />
  );
};

PaymentCutoffHint.propTypes = {
  className: PropTypes.string,
  cutoff: PropTypes.string,
  deliveryMethod: PropTypes.string,
  isCollapsible: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  isForBulkAction: PropTypes.bool,
  isPendingApproval: PropTypes.bool,
  itemCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  speed: PropTypes.shape(),
  textProps: PropTypes.shape(),
  title: PropTypes.string,
  titleProps: PropTypes.shape(),
};

PaymentCutoffHint.defaultProps = {
  className: undefined,
  cutoff: undefined,
  deliveryMethod: undefined,
  isCollapsible: undefined,
  isCollapsed: undefined,
  isForBulkAction: undefined,
  isPendingApproval: undefined,
  itemCount: undefined,
  speed: undefined,
  textProps: {},
  title: undefined,
  titleProps: {},
};

export default PaymentCutoffHint;

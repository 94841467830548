import React from 'react';

import { ExternalHintStatus } from 'constants/external';

import StepHintBase from 'modules/external/externalStepHint/StepHintBase';

/**
 * Component rendering External Step Hint with hint status of COMPLETED
 * @see {StepHintBase}
 *
 * @return {StatelessComponent}
 */
const CompletedStepHint = () => <StepHintBase hintStatus={ExternalHintStatus.COMPLETED} />;

export default CompletedStepHint;

import React from 'react';
import { useSelector } from 'react-redux';
import { getDefaultLineItem, getLineItemsPathFromStyle } from 'helpers/lineItems';
import { useDiscrepancyContext } from 'hooks';
import { isLineItemSimilarToDefault } from 'modules/dashboard/createItems/invoiceGenerator/components/InvoiceGeneratorView/summarizeAndTransfer.helpers';
import { createItemsTableViewModelManagerForKindSelector } from 'queries/tableCompoundSelectors';
import { createItemFormAccountLineItemsSelector, createItemFormItemLineItemsSelector } from 'selectors/forms';
import { LineItemDiscrepancyStatusPresenter } from './LineItemDiscrepancyStatusPresenter';
export const LineItemDiscrepancyStatusForCreateEditItem = ({ rowIdx: lineItemIndex, sectionPath, }) => {
    const accountBillLineItems = useSelector(createItemFormAccountLineItemsSelector) || [];
    const itemBillLineItems = useSelector(createItemFormItemLineItemsSelector) || [];
    const activeLineItems = sectionPath?.includes('account') ? accountBillLineItems : itemBillLineItems;
    const activeLineItem = activeLineItems[lineItemIndex] || {};
    const lineItemId = activeLineItem?.id || activeLineItem?.purchase_order_line_item_id;
    const { getDiscrepancyByLineItemId, lineItemIdsInLoading } = useDiscrepancyContext();
    const viewModelManager = useSelector(createItemsTableViewModelManagerForKindSelector);
    const isSimilarToDefaultLineItem = isLineItemSimilarToDefault(activeLineItem, getDefaultLineItem(viewModelManager, getLineItemsPathFromStyle(activeLineItem?.style)));
    const discrepancy = getDiscrepancyByLineItemId(lineItemId);
    const isLoading = lineItemIdsInLoading.includes(lineItemId);
    return (React.createElement(LineItemDiscrepancyStatusPresenter, { discrepancy: discrepancy, isLoading: isLoading, isSimilarToDefaultLineItem: isSimilarToDefaultLineItem, purchaseOrderLineItemId: activeLineItem?.purchase_order_line_item_id }));
};

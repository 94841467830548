import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components';
import FormFieldDescription from 'components/form/FormFieldDescription';

import { ItemApprovalTypes } from 'constants/item';

import { convertItemApprovalTypeToQuantity } from 'helpers/approvals';
import { allValues } from 'helpers/utility';

import { textHelpers } from './helpers';

/**
 * Component for rendering the text above every select in the Approvals section in CreateItemForm. Different messages
 * depending on if you're creating an item or selecting multiple invoices.
 * @param {ComponentProps} props
 * @param {boolean} props.importingInvoices - creating an invoice v.s. importing one from ledger
 * @param {ItemKind} props.itemKind
 * @param {number} props.position
 * @param {string} props.thresholdAmount
 * @param {ItemApprovalType} props.type
 * @return {StatelessComponent}
 */
const SelectApproverText = ({ importingInvoices, itemKind, position, thresholdAmount, type }) => {
  const options = textHelpers.getSelectApproverTextOptions({
    itemKind,
    position,
    thresholdAmount,
  });
  const { requirePrefix, forMultipleInvoicesSuffix } = options;

  return (
    <FormFieldDescription className="remove-margin-bottom">
      {`${requirePrefix} approval from `}
      <Text.Bold>{convertItemApprovalTypeToQuantity(type)}</Text.Bold>
      {' of the following team members'}
      {importingInvoices && forMultipleInvoicesSuffix}
    </FormFieldDescription>
  );
};

SelectApproverText.propTypes = {
  importingInvoices: PropTypes.bool.isRequired,
  itemKind: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  thresholdAmount: PropTypes.string.isRequired,
  type: PropTypes.oneOf(allValues(ItemApprovalTypes)).isRequired,
};

export default SelectApproverText;

/**
 * @fileOverview Defines the sub-reducer for whether we're currently fetching feature settings.
 * @module reducers/featureSettings
 */

import { getFeatureSettingsRoutine } from 'actions/routines/featureSettings';

import { createIsFetchingReducer } from 'store/redux';

export const finishCases = [
  getFeatureSettingsRoutine.FAILURE,
  getFeatureSettingsRoutine.FULFILL,
  getFeatureSettingsRoutine.SUCCESS,
];

export const requestCases = [getFeatureSettingsRoutine.TRIGGER];

/**
 * Reducer for reduxState.featureSettings.isFetching.
 * @type {ReduxReducer}
 */
const isFetchingReducer = createIsFetchingReducer(finishCases, requestCases);

export default isFetchingReducer;

import PropTypes from 'prop-types';
import React from 'react';

import { FormControl, SelectFieldV2 } from 'components';

import { text } from 'modules/fundingAccount/bankAccount/constants';
import { getFundingAccountDisplayDirection } from 'modules/fundingAccount/bankAccount/helpers';

const BankAccountUseSelect = ({ fundingAccount }) => {
  const displayDirection = getFundingAccountDisplayDirection(fundingAccount);
  const selOption = {
    direction: fundingAccount.direction,
    label: displayDirection,
  };

  return (
    <div className="width--100-percent">
      <FormControl className="direction-control">
        <SelectFieldV2
          displayFieldName="displayDirection"
          input={{
            name: 'direction',
            value: selOption,
          }}
          isDisabled
          label="Bank account use"
          options={[selOption]}
          placeholder={text.accountUsageSelectPlaceholder}
          valueKey="direction"
        />
      </FormControl>
    </div>
  );
};

BankAccountUseSelect.propTypes = {
  fundingAccount: PropTypes.shape({
    direction: PropTypes.string,
  }),
};

BankAccountUseSelect.defaultProps = {
  fundingAccount: {},
};

export default BankAccountUseSelect;

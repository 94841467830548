import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';

import { commonFormFields, createItemFormFields, createPartnershipFormFields } from 'constants/formFields';

import { MemberAccessProps } from 'data/resources/member';

import { isCompanyIdNotCurrentCompany } from 'helpers/currentCompany';
import { filterActiveItemMembersWithEmail } from 'helpers/itemMember';
import { getPartnershipMembersWithAccessAndEmail } from 'helpers/partnershipMembers';
import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { allValues, hasZeroLength, identity, reduceKeys, valueForFirstKey } from 'helpers/utility';

import { getFilteredPartnershipMembersFormValue, getFormSyncErrors } from './formProperties';

/**
 * Returns the next form state to set in relevant form plugins after dispatching
 * removeFormPartnershipMembers.trigger().
 * @param {ReduxFormState} state
 * @param {ReduxAction} action
 * @param {Object} options
 * @param {string} [options.partnershipMembersFieldName=createPartnershipFormFields.PARTNERSHIP_MEMBERS]
 * @return {Object}
 */
export const getNextFormStateForRemoveFormPartnershipMember = (state, action, options = {}) => {
  const { partnershipMembersFieldName = createPartnershipFormFields.PARTNERSHIP_MEMBERS } = options;

  const nextPartnershipMembers = getFilteredPartnershipMembersFormValue({
    fieldName: partnershipMembersFieldName,
    filterEmail: action.payload.email,
    values: state.values,
  });

  const nextState = { ...state };
  _set(nextState.values, partnershipMembersFieldName, nextPartnershipMembers);

  return nextState;
};

/**
 * Returns the next form state to set in relevant form plugins after dispatching
 * setFormPartnershipMembers.trigger().
 * @param {ReduxFormState} state
 * @param {ReduxAction} action
 * @param {Object} options
 * @param {string} [options.partnershipMembersFieldName]
 * @return {Object}
 */
export const getNextFormStateForSetFormPartnershipMembers = (state, action, options = {}) => {
  const { partnershipMembersFieldName = createPartnershipFormFields.PARTNERSHIP_MEMBERS } = options;

  const nextPartnershipMembers = getFilteredPartnershipMembersFormValue({
    appendValues: action.payload.values,
    fieldName: partnershipMembersFieldName,
    filterEmail: action.payload.contactEmail,
    values: state.values,
  });

  const nextSyncErrors = getFormSyncErrors({
    fieldName: partnershipMembersFieldName,
    hasError: hasZeroLength(nextPartnershipMembers),
    syncErrors: state.syncErrors,
  });

  const nextState = { ...state };

  _set(nextState.values, partnershipMembersFieldName, nextPartnershipMembers);
  _set(nextState.syncErrors, partnershipMembersFieldName, nextSyncErrors);

  return nextState;
};

/**
 * Returns the next form state to set in relevant form plugins after dispatching
 * createPartnershipMember.success().
 * @param {ReduxFormState} state
 * @param {ReduxAction} action
 * @param {Object} [options={}]
 * @param {string} [options.partnershipMembersFieldName=createPartnershipFormFields.PARTNERSHIP_MEMBERS]
 * @param {MemberAccessProps} [options.accessProperty=MemberAccessProps.defaultItem]
 * @param {Function} [options.transformPartnershipMember=identity]
 * @return {Object}
 */
export const getNextFormStateForAppendSinglePartnershipMember = (state, action, options = {}) => {
  const {
    accessProperty = MemberAccessProps.defaultItem,
    getAttributes,
    partnershipMembersFieldName = createPartnershipFormFields.PARTNERSHIP_MEMBERS,
    transformPartnershipMember = identity,
  } = options;

  const getObjectsByIdWithRelationshipsOptions = { getAttributes };

  const responseData = valueForFirstKey(
    getObjectsByIdWithRelationships(action.payload.partnershipMember, [], getObjectsByIdWithRelationshipsOptions),
  );

  if (!responseData) {
    return state;
  }

  const addedPartnershipMember = transformPartnershipMember(responseData);

  // generate the updated list of partnership members
  const nextPartnershipMembers = getFilteredPartnershipMembersFormValue({
    appendValues: getPartnershipMembersWithAccessAndEmail(accessProperty, [addedPartnershipMember]),
    fieldName: partnershipMembersFieldName,
    filterEmail: action.payload.email,
    values: state.values,
  });

  // update the form's bank of all partnership members available to be selected
  const nextMetaPartnershipMembersBank = {
    ...state.values.meta.partnershipMembers,
    [addedPartnershipMember.id]: addedPartnershipMember,
  };

  const nextState = _cloneDeep(state);

  _set(nextState.values, createItemFormFields.UI_PARTNERSHIP_MEMBER_CREATED, true);
  _set(nextState.values, partnershipMembersFieldName, nextPartnershipMembers);
  _set(nextState.values, commonFormFields.META_PARTNERSHIP_MEMBERS_BANK, nextMetaPartnershipMembersBank);

  return nextState;
};

/**
 * Returns the next form state to set in relevant form plugins after a
 * successful submission of create partnership.
 * @param {ReduxFormState} state
 * @param {ReduxAction} action
 * @param {Object} options
 * @param {string} [options.partnershipMembersFieldName='partnershipMembers']
 * @param {string} [options.accessProperty=MemberAccessProps.defaultItem]
 * @param {Function} [options.transformPartnershipMember=identity]
 * @return {Object}
 */
export const getNextFormStateForCreateOrFetchPartnership = (state, action, options = {}) => {
  const {
    getAttributes,
    partnershipMembersFieldName = createPartnershipFormFields.PARTNERSHIP_MEMBERS,
    transformPartnershipMember = identity,
  } = options;

  const getObjectsByIdWithRelationshipsOptions = { getAttributes };

  const parsedPartnershipMembers = getObjectsByIdWithRelationships(
    action.payload.partnershipMember,
    ['company', 'membership'],
    getObjectsByIdWithRelationshipsOptions,
  );

  const partnershipMembers = reduceKeys(
    parsedPartnershipMembers,
    (obj, partnershipMemberId) => {
      const partnershipMember = parsedPartnershipMembers[partnershipMemberId];
      // the response data will include members of the current company, but we don't want to display
      // those members in our form, so only include each partnership member if it is not "my company"
      if (isCompanyIdNotCurrentCompany(partnershipMember.company)) {
        return {
          ...obj,
          [partnershipMemberId]: transformPartnershipMember(partnershipMember),
        };
      }

      return obj;
    },
    {},
  );

  const nextState = {
    ...state,
    values: {
      ...state.values,
      meta: {
        ...state.values.meta,
        partnershipId: action.meta?.partnershipId,
        // this will be the data bank of all available partnership members, including those
        // that are not selected in the form (this property name can always be partnershipMembers)
        partnershipMembers,
      },
    },
  };

  /*
    Set all itemMembers on an item automatically only if it is not item edit or after
    a partnership member has been created.
  */
  if (!state?.values?.ui?.isItemEdit && !state.values.ui?.partnershipMemberCreated) {
    // This runs after fetching partnership members
    // It adds all allowed partnership members as selected item member contacts
    // It excludes any assigned access 'none' to the target access property and/or contact/s without email/s
    const filteredPartnershipMembers = filterActiveItemMembersWithEmail(allValues(partnershipMembers));

    // save to the property that will continue to maintain the visible form state
    // for partnershipMembers (at this point it could be itemMembers, etc, instead)
    _set(nextState.values, partnershipMembersFieldName, filteredPartnershipMembers);
  }

  return nextState;
};

import React from 'react';

import { LinkNewPage } from 'components';

import { CONTACT_SUPPORT_LOWERCASE_LABEL } from 'constants/labels';
import { PLATFORM_EMAILS } from 'constants/platform';

import { getMailtoUrl } from 'helpers/urls';

import ExternalErrorHint from '../ExternalErrorHint';

/**
 * Renders Unable to update payment method hint used in external
 * workflow
 * @return {StatelessComponent}
 */
const UnableToUpdatePaymentMethodHint = () => (
  <ExternalErrorHint hintTitle="Sorry, we are unable to process your request.">
    {`Please refresh this page. If the issue persists, please `}
    <LinkNewPage href={getMailtoUrl(PLATFORM_EMAILS.CHAT)}>{CONTACT_SUPPORT_LOWERCASE_LABEL}</LinkNewPage>.
  </ExternalErrorHint>
);

export default UnableToUpdatePaymentMethodHint;

import { useQuery } from '@tanstack/react-query';
import { queryContext } from '@routable/shared';
import {} from '../models';
import { itemApprovalsSettingsApi } from '../services/settings';
export const useItemApprovalsSettings = ({ enabled = true }) => useQuery({
    context: queryContext,
    enabled,
    queryKey: ['itemApprovalsSettings'],
    queryFn: async () => itemApprovalsSettingsApi.get(),
    select: (responseBody) => responseBody?.data,
});

/**
 * @module sagas/tables/api
 */

import { REST } from 'helpers/api';

import { itemStructureDataEndpoint } from 'services/api/dataEndpoints';

/**
 * Fetch the dynamic table configurations for payable and receivable items.
 * @return {Promise<*>}
 */
export const fetchItemStructureTables = async (options = {}) => {
  const config = {
    ...options,
    endpoint: itemStructureDataEndpoint,
    requireAuth: true,
    // the json-logic objects require special handling, as they contain operation-symbol object keys, for example:
    // { ">": [...] }
    // our json-api-normalizer library cannot handle these keys properly
    transformers: [],
  };
  return REST.get(config);
};

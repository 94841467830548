import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import ConfirmationSectionHeader from 'components/confirmation/ConfirmationSectionHeader';

import { BankAccountViewType } from 'constants/ui';

import { isItemCompletedACHPayment, isItemCompletedCheckPayment, isItemInitiatedACHPayment } from 'helpers/items';
import { getCheckArrivalDateRange } from 'helpers/paymentMethods';

import ExternalPromptHeader from 'modules/external/externalPrompt/global/presenters/ExternalPromptHeader';
import ExternalPromptInstructions from 'modules/external/externalPrompt/global/presenters/ExternalPromptInstructions';
import NextSteps from 'modules/external/externalPrompt/global/presenters/NextSteps';
import BankAccount from 'modules/fundingAccount/bankAccount';
import IsLoading from 'modules/isLoading/IsLoading';

import {
  fundingAccountFromQueryItemSelector,
  fundingInfoAddressFromQueryItemSelector,
} from 'queries/fundingCompoundSelectors';
import { itemFromQuerySelector } from 'queries/itemRouterSelectors';
import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

import PromptHeaderPayablePaid from './components/PromptHeaderPayablePaid';

class ExternalPromptVendorSingleItemConfirmation extends React.Component {
  renderHeaderPaymentRemitted = () => {
    const { item, partnership } = this.props;

    if (!isItemCompletedACHPayment(item)) {
      return null;
    }

    return <ExternalPromptHeader text={`Your payment from ${partnership.name} is remitted.`} />;
  };

  renderHeaderPaymentSent = () => {
    const { item } = this.props;

    if (!(isItemCompletedCheckPayment(item) || isItemInitiatedACHPayment(item))) {
      return null;
    }

    return <PromptHeaderPayablePaid />;
  };

  renderBodyItemCompletedAchPayment = () => {
    const { fundingAccount, item } = this.props;

    if (!isItemCompletedACHPayment(item)) {
      return null;
    }

    const instructions = [
      {
        id: '✔',
        key: '1',
        text: `Funds have been deposited to your selected bank account (${fundingAccount.name}).`,
      },
    ];

    return <ExternalPromptInstructions instructions={instructions} />;
  };

  renderBodyItemCompletedCheckPayment = () => {
    const { fundingAccount, fundingInfoAddress, item } = this.props;

    if (!isItemCompletedCheckPayment(item)) {
      return null;
    }

    const instructions = [
      {
        id: 1,
        key: '1',
        text: 'You should receive an email shortly confirming your payment.',
      },
      {
        id: 2,
        key: '2',
        text: `You will receive this check payment within ${getCheckArrivalDateRange(item)}.`,
      },
      {
        id: 3,
        key: '3',
        text: 'Future payments will be sent to your selected address',
      },
    ];

    return (
      <React.Fragment>
        <p className="external-prompt--p">Check mailed to:</p>

        <BankAccount
          fundingAccount={fundingAccount}
          fundingInfoAddress={fundingInfoAddress}
          viewType={BankAccountViewType.ADDRESS}
        />

        <NextSteps />
        <ExternalPromptInstructions instructions={instructions} />
      </React.Fragment>
    );
  };

  renderBodyItemInitiatedAchPayment = () => {
    const { fundingAccount, item } = this.props;

    if (!isItemInitiatedACHPayment(item)) {
      return null;
    }

    const instructions = [
      {
        id: 1,
        key: '1',
        text: 'You should receive an email shortly confirming your payment.',
      },
      {
        id: 2,
        key: '2',
        text: 'You will receive this payment within 2-3 business days.',
      },
      {
        id: 3,
        key: '3',
        text: `Future payments will be deposited to your selected bank account (${fundingAccount.name}).`,
      },
    ];

    return (
      <React.Fragment>
        <NextSteps />
        <ExternalPromptInstructions instructions={instructions} />
      </React.Fragment>
    );
  };

  render() {
    const { fundingAccount, item } = this.props;

    // Ensure item and fundingAccount are present (they might still be loading, or changing)
    if (!item || !fundingAccount) {
      return <IsLoading />;
    }

    return (
      <React.Fragment>
        <ConfirmationSectionHeader text="Confirmed" />

        {/* Prompt header */}
        {this.renderHeaderPaymentSent()}
        {this.renderHeaderPaymentRemitted()}

        {/* Prompt body */}
        {this.renderBodyItemCompletedAchPayment()}
        {this.renderBodyItemCompletedCheckPayment()}
        {this.renderBodyItemInitiatedAchPayment()}
      </React.Fragment>
    );
  }
}

ExternalPromptVendorSingleItemConfirmation.propTypes = {
  item: PropTypes.shape().isRequired,
  fundingAccount: PropTypes.shape(),
  fundingInfoAddress: PropTypes.shape(),
  partnership: PropTypes.shape().isRequired,
};

ExternalPromptVendorSingleItemConfirmation.defaultProps = {
  fundingAccount: null,
  fundingInfoAddress: null,
};

const mapStateToProps = (state, ownProps) => ({
  item: itemFromQuerySelector(state, ownProps),
  fundingAccount: fundingAccountFromQueryItemSelector(state, ownProps),
  fundingInfoAddress: fundingInfoAddressFromQueryItemSelector(state, ownProps),
  partnership: partnershipFromQuerySelector(state, ownProps),
});

export { ExternalPromptVendorSingleItemConfirmation };
export default connect(mapStateToProps)(ExternalPromptVendorSingleItemConfirmation);

export const getConvertExternalFormInitialValues = () => ({
  company: {
    namespace: '',
  },
  meta: {
    tosAgree: false,
  },
  user: {
    firstName: '',
    lastName: '',
  },
});

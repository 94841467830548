import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { v4 } from 'uuid';

import { ButtonV2 } from 'components/buttonV2';
// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { FileInput } from 'components/form';

import { ButtonSize } from 'constants/button';
import { Intent } from 'constants/ui';

import { oneOfValuesFromObject } from 'helpers/propTypes';

/**
 * An input which presents as a button, allowing users to upload files into a form.
 *
 * @param {ComponentProps} props
 * @param {string} [props.accept]
 * @param {string} [props.className]
 * @param {Object} [props.errors]
 * @param {string} [props.id]
 * @param {Object} [props.input]
 * @param {Intent} props.intent
 * @param {boolean} [props.isDisabledDueToPermissions]
 * @param {boolean} [props.isLoading]
 * @param {string} [props.leftIconClassName]
 * @param {string} [props.leftIconColor]
 * @param {string} [props.leftIconName]
 * @param {number} [props.leftIconSize]
 * @param {Object} [props.meta]
 * @param {boolean} [props.multiple]
 * @param {string} props.name
 * @param {function} props.onChange
 * @param {ButtonSize} [props.size=ButtonSize.SMALL]
 * @param {string} props.text
 * @returns {FunctionComponent}
 */
const AddFileInput = ({
  accept,
  className,
  dataTestId,
  errors,
  id,
  input,
  intent,
  isDisabledDueToPermissions,
  isLoading,
  leftIconClassName,
  leftIconColor,
  leftIconName,
  leftIconSize,
  meta,
  multiple,
  name,
  onChange,
  size,
  text,
}) => {
  const inputRef = React.useRef();
  const [inputKey, updatingInputKey] = React.useState();

  const handleOnClick = () => {
    if (inputRef?.current) {
      inputRef.current.click();
    }
  };

  return (
    <FileInput
      accept={accept}
      className={classNames({
        'file-cursor-hover': true,
        [className]: !!className,
      })}
      disabled={isDisabledDueToPermissions}
      errors={errors}
      id={id || v4()}
      input={input}
      inputRef={inputRef}
      keyName={inputKey}
      meta={meta}
      multiple={multiple}
      name={name}
      onChange={(e) => onChange(e, updatingInputKey)}
    >
      <ButtonV2
        dataTestId={dataTestId}
        id={`${name}_innerBtn`}
        intent={intent}
        isDisabled={isDisabledDueToPermissions}
        isLoading={isLoading}
        leftIconClassName={leftIconClassName}
        leftIconColor={leftIconColor}
        leftIconName={leftIconName}
        leftIconSize={leftIconSize}
        onClick={handleOnClick}
        size={size}
        type="button"
      >
        {text}
      </ButtonV2>
    </FileInput>
  );
};

AddFileInput.propTypes = {
  accept: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  errors: PropTypes.shape(),
  id: PropTypes.string,
  input: PropTypes.shape(),
  intent: PropTypes.oneOf([Intent.DANGER, Intent.NEUTRAL, Intent.GHOST, Intent.PRIMARY, Intent.SUCCESS]).isRequired,
  isDisabledDueToPermissions: PropTypes.bool,
  isLoading: PropTypes.bool,
  leftIconClassName: PropTypes.string,
  leftIconColor: PropTypes.string,
  leftIconName: PropTypes.string,
  leftIconSize: PropTypes.number,
  meta: PropTypes.shape(),
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  size: oneOfValuesFromObject(ButtonSize),
  text: PropTypes.string.isRequired,
};

AddFileInput.defaultProps = {
  accept: undefined,
  className: undefined,
  dataTestId: undefined,
  errors: undefined,
  id: undefined,
  input: undefined,
  isDisabledDueToPermissions: undefined,
  isLoading: undefined,
  leftIconClassName: undefined,
  leftIconColor: undefined,
  leftIconName: undefined,
  leftIconSize: undefined,
  meta: undefined,
  multiple: undefined,
  name: undefined,
  onChange: undefined,
  size: ButtonSize.SMALL,
};

export default AddFileInput;

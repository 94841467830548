import PropTypes from 'prop-types';
import React from 'react';

import ToggleSwitch from './ToggleSwitch';

/**
 * Switch, for use with redux-form.
 * @param {ComponentProps} props
 * @param {Maybe<string>} props.id
 * @param {Maybe<boolean>} props.isDisabled
 * @param {Maybe<boolean|Object>} props.icons
 * @param {ReduxFormInput} props.input
 * @return {StatelessComponent}
 * @constructor
 */
const ToggleSwitchReduxForm = (props) => {
  const { id, isDisabled, icons, input, ...rest } = props;

  const { name, onChange, value } = input;

  return (
    <ToggleSwitch
      {...rest}
      icons={icons}
      id={id}
      isChecked={!!value}
      isDisabled={isDisabled}
      name={name}
      onChange={onChange}
    />
  );
};

ToggleSwitchReduxForm.propTypes = {
  icons: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape()]),
  id: PropTypes.string,
  input: PropTypes.shape().isRequired,
  isDisabled: PropTypes.bool,
};

ToggleSwitchReduxForm.defaultProps = {
  icons: undefined,
  id: undefined,
  isDisabled: undefined,
};

export default ToggleSwitchReduxForm;

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { fundingAccountFromUpdatePaymentMethodFormSelector } from 'queries/currentWorkflowStepSelector';
import { partnershipFromCurrentPartnershipRequestSelector } from 'queries/partnershipCompoundSelectors';
import AcceptPendingPaymentsCheckboxField from './AcceptPendingPaymentsCheckboxField';
import {} from './AcceptPendingPaymentsCheckboxField.types';
export const mapStateToProps = createStructuredSelector({
    fundingAccount: fundingAccountFromUpdatePaymentMethodFormSelector,
    partnership: partnershipFromCurrentPartnershipRequestSelector,
});
export default connect(mapStateToProps)(AcceptPendingPaymentsCheckboxField);

import React from 'react';
import { SwalWrapperV2 } from 'components';
import BulletList from 'components/BulletList';
import { Intent } from 'constants/ui';
import { ModalContainer, Title, BulletContainer, BulletListContainer } from './DocumentModal.styles';
const DocumentModal = () => (React.createElement(ModalContainer, null,
    React.createElement(SwalWrapperV2, { intent: Intent.NEUTRAL, rightButtonProps: {
            onClick: () => { },
            children: 'Got it',
            intent: 'primary',
        }, shouldCloseOnClick: true, title: "Business verification forms" },
        React.createElement(BulletContainer, null,
            React.createElement(Title, null, "To verify your business on Routable you can upload:"),
            React.createElement(BulletListContainer, null,
                React.createElement(BulletList, { bullets: [
                        'Business License',
                        'Certificate of Good Standing',
                        'IRS-issued EIN letter',
                        'Filed and stamped Articles of Organization or Incorporation',
                        'Sales/Use Tax License',
                    ] }))),
        React.createElement(BulletContainer, null,
            React.createElement(Title, null, "Sole Proprietors using a fictitious name can use:"),
            React.createElement(BulletListContainer, null,
                React.createElement(BulletList, { bullets: [
                        'Fictitious Name Certificate/Statement',
                        'Certificate of Assumed name',
                        'Business License',
                        'Sales/Use Tax License',
                        'Registration of Trade Name',
                    ] }))))));
export default DocumentModal;

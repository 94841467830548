/**
 * @fileOverview Defines the sub-reducer for active feature flags.
 * @module reducers/featureFlags
 */

import { getFeatureFlagsRoutine } from 'actions/routines/featureFlags';

import { initialState } from './initialState';

/**
 * Reducer for reduxState.featureFlags.activeFlags.
 * @type {ReduxReducer}
 */
const activeFlagsReducer = (state = initialState.activeFlags, action) => {
  switch (action.type) {
    case getFeatureFlagsRoutine.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default activeFlagsReducer;

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  fundingAccountFromCurrentPartnershipRequestSelector,
  fundingInfoAddressFromCurrentPartnershipRequestSelector,
} from 'queries/fundingCompoundSelectors';

import { selectUseBillActiveItemKind } from 'selectors/itemsSelectors';

import ItemPaymentMethodHint from './ItemPaymentMethodHint';

const mapStateToProps = createStructuredSelector({
  fundingAccount: fundingAccountFromCurrentPartnershipRequestSelector,
  fundingInfoAddress: fundingInfoAddressFromCurrentPartnershipRequestSelector,
  itemKind: selectUseBillActiveItemKind,
});

export { mapStateToProps };
export default connect(mapStateToProps)(ItemPaymentMethodHint);

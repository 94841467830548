import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { currentCompanySelector } from 'selectors/currentCompanySelectors';

import PaymentMethodSameHint from './PaymentMethodSameHint';

export const mapStateToProps = createStructuredSelector({
  company: currentCompanySelector,
});

export default connect(mapStateToProps)(PaymentMethodSameHint);

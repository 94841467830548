import { LedgerSides } from 'constants/ledger';

import { getQueryString } from 'helpers/queryParams';

import {
  getIntegrationLedgerAccountCodesEndpoint,
  getIntegrationLedgerMatchingEndpoint,
} from 'services/api/integrationEndpoints';
import { partnershipsEndpoint } from 'services/api/partnershipsEndpoints';
import FetchService from 'services/fetch';

/**
 * Fetch ledger matching platform partnerships for select component with search
 * @param {string} ledgerId
 * @param {string} query
 * @return {Promise<*|*|*>}
 */
export const requestLedgerAccountCodes = async (ledgerId, query) => {
  const endpoint = getIntegrationLedgerAccountCodesEndpoint(ledgerId, {
    search: query,
  });

  return FetchService.request({
    method: 'GET',
    endpoint,
    requireAuth: true,
  });
};

/**
 * Fetch partnerships for select component with search and partnership type filter
 * @param {string} query
 * @param {object} params
 * @return {Promise<*|*|*>}
 */
export const requestPartnershipsForQuery = async (query, params = {}) => {
  const { source } = params;
  const queryString = getQueryString({ search: query, source });
  const endpoint = `${partnershipsEndpoint}?${queryString}`;

  return FetchService.request({
    method: 'GET',
    endpoint,
  });
};

/**
 * Fetch partnership ledger company with partnershipMembers data.
 * @param {string} integrationConfigId
 * @param {string} vendorOrCustomerRef
 * @return {Promise<*>}
 */
export const requestDataWithPartnershipMembers = async (integrationConfigId, vendorOrCustomerRef) => {
  const endpoint = getIntegrationLedgerMatchingEndpoint(integrationConfigId, 'partnerships', {
    ledger_ref: vendorOrCustomerRef,
    side: LedgerSides.LEDGER,
  });

  return FetchService.request({
    endpoint,
    method: 'GET',
    requireAuth: true,
  });
};

/**
 * Fetch ledger matching platform partnerships for select component with search
 * @param {string} ledgerId
 * @param {string} query
 * @return {Promise<*|*|*>}
 */
export const requestLedgerMatches = async (ledgerId, query) => {
  const endpoint = getIntegrationLedgerMatchingEndpoint(ledgerId, 'partnerships', {
    is_matched: true,
    search: query,
    side: LedgerSides.PLATFORM,
  });

  return FetchService.request({
    method: 'GET',
    endpoint,
  });
};

/**
 * Fetch ledger matching ledger only partnerships for select component with search
 * @param {string} ledgerId
 * @param {string} query
 * @return {Promise<*|*|*>}
 */
export const requestLedgerOnlyMatches = async (ledgerId, query) => {
  const endpoint = getIntegrationLedgerMatchingEndpoint(ledgerId, 'partnerships', {
    is_matched: false,
    ledger_only: true,
    search: query,
    side: LedgerSides.LEDGER,
  });

  return FetchService.request({
    method: 'GET',
    endpoint,
  });
};

/**
 * Fetch clearing accounts for select component with search
 * @param {string} ledgerId
 * @param {string} query
 * @return {Promise<*|*|*>}
 */
export const requestClearingAccountMatches = async (ledgerId, query) => {
  const endpoint = getIntegrationLedgerMatchingEndpoint(ledgerId, 'clearing_accounts', {
    search: query,
    side: LedgerSides.LEDGER,
  });

  return FetchService.request({
    method: 'GET',
    endpoint,
  });
};

import { useCallback, useEffect, useRef } from 'react';

/**
 * A useEffect hook for usage on top of requestAnimationFrame.
 * Accepts a callback function and a minimum interval at which to call it.
 * NOTE: Callback is not guaranteed to be called at exactly the interval
 * specified, only at AT LEAST that interval.
 *
 * @param {function:(number)} callback - Arg is the time delta from the last call.
 * @param {NumberMaybe} interval - If undefined, callback fired at every frame.
 * @return {void}
 */
const useRequestAnimationFrame = (callback, interval) => {
  const requestRef = useRef();
  const previousTimeRef = useRef();

  const animate = useCallback(
    (time) => {
      if (previousTimeRef.current !== undefined) {
        const deltaTime = time - previousTimeRef.current;
        if (!interval || deltaTime > interval) {
          previousTimeRef.current = time;
          callback(deltaTime);
        }
      } else {
        previousTimeRef.current = time;
      }
      requestRef.current = requestAnimationFrame(animate);
    },
    [callback, interval],
  );

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [animate]);
};

export default useRequestAnimationFrame;

import React from 'react';

import { WhiteSpace } from 'components';

import { PLATFORM_EMAILS } from 'constants/platform';

import { getMailtoUrl } from 'helpers/urls';

import { multiplePartnershipHintText } from 'modules/external/ExternalCollectTaxInfo/constants/text';

/**
 * Explains why we have their tax information already, because they've already confirmed with another partnership. Also
 * provides link to support email.
 * @returns {StatelessComponent}
 * @constructor
 */
const ExplainMultiplePartnershipTaxConfirmation = () => (
  <React.Fragment>
    {multiplePartnershipHintText.TEXT}
    <WhiteSpace />
    <a className="primary enable-pointer-events" href={getMailtoUrl(PLATFORM_EMAILS.SUPPORT)}>
      {PLATFORM_EMAILS.SUPPORT}
    </a>
    .
  </React.Fragment>
);

export default ExplainMultiplePartnershipTaxConfirmation;

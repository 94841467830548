import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { change, reduxForm, resetSection } from 'redux-form';

import { externalOnboardingDecrementStep, externalOnboardingIncrementStep } from 'actions/externalOnboarding';

import { formNamesExternal } from 'constants/forms';
import { toastDurationSeconds } from 'constants/ui';

import { getInvalidCountrySelectedMessage } from 'helpers/addressHelpers';

import { getOnboardingStepTwoInitialValues } from 'modules/createPartnerCompany/helpers/initialValues';
import { makeFormSubmitFailHandler } from 'modules/createPartnerCompany/helpers/submitFailure';
import { submitPartnerCompanyGeneral } from 'modules/createPartnerCompany/helpers/submitPartnerCompanyGeneral';
import PartnerCompanyGeneralForm from 'modules/createPartnerCompany/presenters/PartnerCompanyGeneralForm';

import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

import { companiesErrorSelector, isUpdatingCompanySelector } from 'selectors/companiesSelectors';
import { currentCompanyInfoSelector, currentCompanySelector } from 'selectors/currentCompanySelectors';
import { externalGeneralFormSelector } from 'selectors/forms';
import { modalSelector } from 'selectors/modalsSelector';

import { toaster } from 'services/toaster';

import updateOnboardingCompany from 'thunks/updateOnboardingCompany';

class PartnerCompanyGeneralContainer extends React.Component {
  formId = formNamesExternal.PARTNER_COMPANY_GENERAL;

  handleFormSubmit = async (values) =>
    submitPartnerCompanyGeneral(values, {
      ...this.props,
      submitSuccessCallback: this.submitSuccessCallback,
    });

  handleToggleInputForTin = () => {
    const { formUI, onValuesChange } = this.props;

    onValuesChange(formNamesExternal.PARTNER_COMPANY_GENERAL, 'formUI.displayInputForTin', !formUI.displayInputForTin);
  };

  // Clear tin field + update tinType when switching between EIN and SSN
  handleToggleSSNEIN = () => {
    const { onValuesChange } = this.props;
    onValuesChange(formNamesExternal.PARTNER_COMPANY_GENERAL, 'formUI.displayInputForTin', true);
  };

  submitSuccessCallback = (parsedResponse) => {
    const { onIncrementStep } = this.props;
    onIncrementStep();
    return parsedResponse;
  };

  onInvalidCountrySelected = () => {
    const { onClearFields, partnership } = this.props;

    onClearFields(this.formId, false, false, 'company.mailingAddress');

    const message = getInvalidCountrySelectedMessage({
      contactName: partnership && partnership.name,
    });

    toaster.danger(message, {
      duration: toastDurationSeconds.INFINITE,
      id: 'invalidCountry',
    });
  };

  render() {
    const { companyValues, handleSubmit, formUI, isUpdating, onDecrementStep } = this.props;

    return (
      <PartnerCompanyGeneralForm
        addressInfo={companyValues.mailingAddress}
        formId={this.formId}
        formUIState={formUI}
        isUpdating={isUpdating}
        onDecrementStep={onDecrementStep}
        onFormSubmit={handleSubmit(this.handleFormSubmit)}
        onInvalidCountry={this.onInvalidCountrySelected}
        onToggleInputForTin={this.handleToggleInputForTin}
        onToggleSSNEIN={this.handleToggleSSNEIN}
      />
    );
  }
}

PartnerCompanyGeneralContainer.propTypes = {
  companyValues: PropTypes.shape(),
  formUI: PropTypes.shape(),
  handleSubmit: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  onClearFields: PropTypes.func.isRequired,
  onDecrementStep: PropTypes.func.isRequired,
  onIncrementStep: PropTypes.func.isRequired,
  onValuesChange: PropTypes.func.isRequired,
  partnership: PropTypes.shape(),
};

PartnerCompanyGeneralContainer.defaultProps = {
  companyValues: {},
  formUI: {},
  partnership: undefined,
};

const createGeneralForm = reduxForm({
  form: formNamesExternal.PARTNER_COMPANY_GENERAL,
  onSubmitFail: makeFormSubmitFailHandler(formNamesExternal.PARTNER_COMPANY_GENERAL),
})(PartnerCompanyGeneralContainer);

const mapStateToProps = (state, ownProps) => ({
  companyErrors: companiesErrorSelector(state),
  companyValues: externalGeneralFormSelector(state, 'company'),
  currentCompany: currentCompanySelector(state),
  currentCompanyInfo: currentCompanyInfoSelector(state),
  formUI: externalGeneralFormSelector(state, 'formUI'),
  initialValues: getOnboardingStepTwoInitialValues(state),
  isUpdating: isUpdatingCompanySelector(state),
  modal: modalSelector(state, 'externalOnboarding'),
  partnership: partnershipFromQuerySelector(state, ownProps),
});

const mapDispatchToProps = {
  onClearFields: resetSection,
  onIncrementStep: externalOnboardingIncrementStep,
  onDecrementStep: externalOnboardingDecrementStep,
  onUpdateOnboardingCompany: updateOnboardingCompany,
  onValuesChange: change,
};

// partnershipFromQuerySelector needs withRouter
const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));

export { PartnerCompanyGeneralContainer };
export default enhance(createGeneralForm);

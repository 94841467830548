import { combineReducers } from 'redux';

import allMessagesReducer from './allMessagesReducer';
import errorReducer from './errorReducer';
import messagesByIdReducer from './messagesByIdReducer';
import submitReducer from './submitReducer';

const messagesReducer = combineReducers({
  allIds: allMessagesReducer,
  byId: messagesByIdReducer,
  errors: errorReducer,
  isSubmitting: submitReducer,
});

export default messagesReducer;

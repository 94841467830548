import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { FlexCol } from 'components';

import './ExternalBlockItem.scss';

/**
 * Component rendering single External Block Item
 * @param {ComponentProps} props
 * @param {Node} props.children
 * @param {string} [props.className]
 * @param {boolean} props.isDark
 * @returns {StatelessComponent}
 */
const ExternalBlockItem = ({ children, className, isDark }) => (
  <FlexCol
    className={classNames('external-block-item', {
      'external-block-item--is-dark': isDark,
      [className]: Boolean(className),
    })}
  >
    {children}
  </FlexCol>
);

ExternalBlockItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isDark: PropTypes.bool,
};

ExternalBlockItem.defaultProps = {
  className: undefined,
  isDark: undefined,
};

export default ExternalBlockItem;

import { combineReducers } from 'redux';

import allUsersReducer from './allUsersReducer';
import errorReducer from './errorReducer';
import fetchReducer from './fetchReducer';
import lastFetchedReducer from './lastFetchedReducer';
import usersByIdReducer from './usersByIdReducer';

const usersReducer = combineReducers({
  allIds: allUsersReducer,
  byId: usersByIdReducer,
  errors: errorReducer,
  isFetching: fetchReducer,
  lastFetched: lastFetchedReducer,
});

export default usersReducer;

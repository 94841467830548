import { RESTRICTED_CONTENT_IMAGE } from 'constants/images';

/**
 * This constant is here to ensure we're using the same styling
 * SWALs requires multiple classes to be passed as an array
 */
export const SwalButtonStyles = {
  CANCEL: 'btn-v2 btn--danger btn--small',
  GHOST_CANCEL: 'btn-v2 btn--neutral btn--small hover--danger',
  GHOST_PRIMARY: 'btn-v2 btn--neutral btn--small',
  PRIMARY: 'btn-v2 btn--primary btn--small',
};

/**
 * Text constants for the RestrictedFeatureModal, which is a SWAL.
 * @enum {string}
 */
export const RestrictedFeatureModalText = {
  CONFIRM_TEXT: 'Got it',
  IMAGE_ALT: 'Website with superimposed lock',
  IMAGE_SRC: RESTRICTED_CONTENT_IMAGE,
  TITLE: 'This feature is restricted',
};

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import { FETCH_AFFILIATE_CONNECTIONS_SUCCESS } from 'types/affiliates';

const byIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_AFFILIATE_CONNECTIONS_SUCCESS:
      return deepMergeWithArrayReplacement(
        state,
        getObjectsByIdWithRelationships(action.payload.affiliateConnection, ['affiliate']),
      );

    default:
      return state;
  }
};

export default byIdReducer;

import { PaginationSizes } from 'constants/pagination';

import { FETCH_LEDGER_PARTNERSHIPS_SUCCESS } from 'types/integrations';

export const paginationInitialState = {
  current: null,
  first: null,
  last: null,
  next: null,
  prev: null,
  count: 0,
  offset: 0,
  limit: PaginationSizes.INTEGRATION_MATCH_LIST,
};

const paginationReducer = (state = paginationInitialState, action) => {
  switch (action.type) {
    case FETCH_LEDGER_PARTNERSHIPS_SUCCESS:
      return {
        ...state,
        ...action.payload.links,
        ...action.payload.meta.pagination,
      };

    default:
      return state;
  }
};

export default paginationReducer;

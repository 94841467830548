import { useMemo } from 'react';
export const useGetBlockFieldProps = ({ formValues, getIsDisabled: { getIsDisabled }, getIsRequired: { getIsRequired }, validate: validateFns, }) => {
    const isRequired = Boolean(getIsRequired(formValues));
    const validators = useMemo(() => {
        if (formValues.ui.blockDetailsSections) {
            return () => undefined;
        }
        return isRequired ? validateFns : validateFns?.slice?.(1);
    }, [formValues.ui.blockDetailsSections, isRequired, validateFns]);
    const isDisabled = useMemo(() => getIsDisabled(formValues), [formValues, getIsDisabled]);
    return {
        isDisabled,
        isRequired,
        validators,
    };
};

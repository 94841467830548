import * as sidePanelNames from 'constants/sidePanels';

import { isEqual } from 'helpers/utility';

import * as types from 'types/sidePanels';

export const initialState = {
  open: false,
};

const { sidePanelNameItemThreadTags } = sidePanelNames;

const itemThreadTagSidePanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SIDE_PANEL_OPEN: {
      // to trigger this, use the generic openSidePanel actionCreator and pass this sidepanel's name
      return isEqual(action.payload.name, sidePanelNameItemThreadTags) ? { ...state, open: true } : state;
    }
    case types.SIDE_PANEL_CLOSE: {
      // to trigger this, use the generic closeSidePanel actionCreator and pass this sidepanel's name
      return isEqual(action.payload.name, sidePanelNameItemThreadTags) ? { ...state, open: false } : state;
    }

    default:
      return state;
  }
};

export default itemThreadTagSidePanelReducer;

import { createSelector } from 'reselect';

const getState = (state) => state.externalOnboarding;

/**
 * Selects external onboarding state step property
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {ExternalStep}
 */
export const externalOnboardingStepSelector = createSelector([getState], (state) => state.step);

/**
 * Selects external onboarding isSubmitting state property
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const externalOnboardingIsSubmittingSelector = createSelector([getState], (state) => state.isSubmitting);

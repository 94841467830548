export var SSOActionType;
(function (SSOActionType) {
    SSOActionType["CLOSE_DISABLE_MODAL"] = "CLOSE_DISABLE_MODAL";
    SSOActionType["CLOSE_SETUP_FORM_MODAL"] = "CLOSE_SETUP_FORM_MODAL";
    SSOActionType["CLOSE_SETUP_SPLASHSCREEN_MODAL"] = "CLOSE_SSO_SETUP_SPLASHSCREEN_MODAL";
    SSOActionType["DISABLE_SSO"] = "DISABLE_SSO";
    SSOActionType["FETCH_COMPANY_SETTINGS_SSO"] = "FETCH_COMPANY_SETTINGS_SSO";
    SSOActionType["FETCH_COMPANY_SETTINGS_SSO_BY_NAMESPACE"] = "FETCH_COMPANY_SETTINGS_SSO_BY_NAMESPACE";
    SSOActionType["OPEN_DISABLE_MODAL"] = "OPEN_DISABLE_MODAL";
    SSOActionType["OPEN_SETUP_FORM_MODAL_WITH_PROVIDER"] = "OPEN_SETUP_FORM_MODAL_WITH_PROVIDER";
    SSOActionType["OPEN_SETUP_SPLASHSCREEN_MODAL"] = "OPEN_SSO_SETUP_SPLASHSCREEN_MODAL";
    SSOActionType["REMOVE_SSO_SETTINGS"] = "REMOVE_SSO_SETTINGS";
    SSOActionType["SET_SETTINGS_PROVIDER"] = "SET_SETTINGS_PROVIDER";
    SSOActionType["SUBMIT_SSO_SETTINGS"] = "SUBMIT_SSO_SETTINGS";
    SSOActionType["SUBMIT_UPDATE_SSO_SETTINGS"] = "SUBMIT_UPDATE_SSO_SETTINGS";
})(SSOActionType || (SSOActionType = {}));
export var SSOProviders;
(function (SSOProviders) {
    SSOProviders["INVALID"] = "invalid";
    SSOProviders["GOOGLE_OAUTH2"] = "google-oauth2";
    SSOProviders["SAML"] = "saml";
})(SSOProviders || (SSOProviders = {}));
export var SSOSetupOrEdit;
(function (SSOSetupOrEdit) {
    SSOSetupOrEdit["SETUP"] = "SETUP";
    SSOSetupOrEdit["EDIT"] = "EDIT";
})(SSOSetupOrEdit || (SSOSetupOrEdit = {}));

import decompress from './deepObjectDifferenceConfigDecompress';

/* This config object represents all relationships and nested relationships for an item edit payload */
const compressedConfigObj = {
  item: {
    attachments: null,
    fundingAccount: null,
    extended: null,
    itemApprovers: {
      level: null,
      membership: null,
    },
    itemMembers: {
      partnershipMember: null,
    },
    lineItems: {
      extended: null,
    },
    partnership: null,
    partnerReceivableAccount: null,
  },
};

const extendedConfigObj = {
  name: 'extended',
  relations: [
    {
      name: 'custbody\\d\\d',
      id: ['id', 'value'],
      relations: [],
    },
    {
      name: 'custcol\\d\\d',
      id: ['id', 'value'],
      relations: [],
    },
  ],
};

const decompressed = decompress(compressedConfigObj);

decompressed.relations = decompressed.relations.map((rel) => (rel.name === 'extended' ? extendedConfigObj : rel));

const lineItemsIdx = decompressed.relations.findIndex((rel) => rel.name === 'lineItems');

decompressed.relations[lineItemsIdx].relations = decompressed.relations[lineItemsIdx].relations.map((rel) =>
  rel.name === 'extended' ? extendedConfigObj : rel,
);

export const decompressedConfigObj = decompressed;

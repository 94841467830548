import { allKeys } from 'helpers/utility';

import { FETCH_PLATFORM_PARTNERSHIPS_SEARCH_SUCCESS, FETCH_PLATFORM_PARTNERSHIPS_SUCCESS } from 'types/integrations';

const allPlatformPartnershipsReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_PLATFORM_PARTNERSHIPS_SEARCH_SUCCESS:
    case FETCH_PLATFORM_PARTNERSHIPS_SUCCESS:
      return Array.from(new Set([...state, ...allKeys(action.payload.partnership)]));

    default:
      return state;
  }
};

export default allPlatformPartnershipsReducer;

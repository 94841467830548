import React from 'react';
import { ExternalLink } from 'components';
export const whatInfoRepresentativeNeed = {
    title: 'What information does the representative need to provide?',
    answers: [
        {
            content: 'Only you will have access to your representative information. We only use your personal information for identity verification, and will never share it outside of Routable.',
        },
        {
            contents: [
                {
                    title: 'Personal information',
                    list: ['Your title at the company', 'Birthday', 'Phone number', 'Primary address', 'ID number'],
                },
                {
                    title: 'One ID document',
                    list: ['State-issued ID', 'Driver’s license', 'Passport'],
                },
            ],
        },
    ],
};
export const howRoutableUseThisInformation = {
    title: 'How will Routable use this information?',
    answers: [
        {
            title: 'Fighting financial crimes',
            content: (React.createElement("p", null,
                "Providing this information helps the US government fight the funding of terrorism and money laundering activities. Routable is required as a financial institution to collect, verify, and record information (e.g., name, address, date of birth, etc.) used in identifying every client. Learn more about how Routable uses your information by reading our",
                React.createElement(ExternalLink, { href: "https://routable.com/legal/privacy/" }, " privacy policy"),
                ".")),
        },
        {
            title: 'Routable’s commitment to security',
            content: 'No one other than you will have access to your representative information. We only use your personal information for identity verification and will never share it outside of Routable.',
        },
    ],
};

import { createSelector } from 'reselect';

import { allValues, isEqual } from 'helpers/utility';

import { idParamSelector } from './routerSelectors';

/**
 * Top level membership tagging selector
 * @function
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {object} - ReduxState under the tagging key
 */
export const getState = (state) => state.tagging;

/**
 * Selects the tags slice of the tagging reducer
 * @function
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {object} - ReduxState under the tagging.tags key
 */
export const tagsSelector = createSelector([getState], (tagging) => tagging.tags);

/**
 * Selects the tags.byId slice of the tagging reducer
 * @function
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {object} - ReduxState under the tagging.tags.byId key
 */
export const tagsByIdSelector = createSelector([tagsSelector], (tags) => tags.byId);

/**
 * Selects the itemTagLinks slice of the tagging reducer
 * @function
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {object} - ReduxState under the tagging.itemTagLinks key
 */
export const itemTagLinksSelector = createSelector([getState], (tagging) => tagging.itemTagLinks);

/**
 * Selects the itemTagLinks.byId slice of the itemTagLinks reducer
 * @function
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {object} - ReduxState under the tagging.itemTagLinks.byId key
 */
export const itemTagLinksByIdSelector = createSelector([itemTagLinksSelector], (itemTagLinks) => itemTagLinks.byId);

/**
 * Selects the tags with matching itemTagLinks
 * idParamSelector requires withRouter to wrap the container using this selector
 * @function
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {[Tag]} - all tags in the tagging.tags.byId slice which have relationships with the current item
 */
export const itemTagsSelector = createSelector(
  [tagsByIdSelector, itemTagLinksByIdSelector, (state, props) => idParamSelector(state, props)],
  (tagsById, itemTagLinksById, itemParamsId) =>
    Object.values(itemTagLinksById)
      .map(({ tag, taggedObject }) => (taggedObject === itemParamsId ? tagsById[tag] : undefined))
      .filter((el) => !!el), // remove undefined values
);

/**
 * tagLinksForCurrentItemSelector
 * Selects the tagLinks linked to the current item
 * Useful because the tagLink reducer may have data for other items (if other item pages were visited for eg)
 * @function
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {[Tag]} - all tags without relationships with the current item
 */
export const tagLinksForCurrentItemSelector = createSelector(
  [itemTagLinksByIdSelector, (state, props) => idParamSelector(state, props)],
  (itemTagLinksById, itemParamsId) =>
    allValues(itemTagLinksById)
      .filter((tagLink) => isEqual(tagLink.taggedObject, itemParamsId))
      .map((tagLink) => tagLink.tag),
);

/**
 * Selects the tags that don't match a given item's TagLinks
 * This is useful to remove noise in lists shown to the user (don't show tags already added to an item)
 * and only return the tags unknown to the current item
 * @function
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {[Tag]} - all tags without relationships with the current item
 */
export const tagsWithoutTagLinksForThisItemSelector = createSelector(
  [tagsByIdSelector, tagLinksForCurrentItemSelector],
  (tagsById, currentItemTagLinks) => allValues(tagsById).filter((tag) => !currentItemTagLinks.includes(tag.id)),
);

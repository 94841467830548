import PropTypes from 'prop-types';
import React from 'react';

import { FormControl, FormFieldLabel, TINInput } from 'components/form';

import { field } from 'constants/styles/formStyles';

/**
 * Input fields for entity's tin info
 * @param {ComponentProps} props
 * @param {UserTypes} props.companyType
 * @param {Object} props.formUIState
 * @param {boolean} props.hideLabel
 * @param {function} props.onToggleInputForTin
 * @returns {StatelessComponent}
 */
const PartnerCompanyTaxFormPersonal = ({ companyType, formUIState, hideLabel, onToggleInputForTin }) => (
  <>
    {!hideLabel && <FormFieldLabel>Registered entity info (for Form 1099-MISC)</FormFieldLabel>}

    <FormControl>
      <TINInput
        companyType={companyType}
        editAllowed
        editField={formUIState.displayInputForTin}
        fieldClassNames={field.xl.full}
        isDisabled={false}
        isSSN
        name="company.info.tin"
        onEdit={onToggleInputForTin}
        showField
      />
    </FormControl>
  </>
);

PartnerCompanyTaxFormPersonal.propTypes = {
  companyType: PropTypes.string.isRequired,
  formUIState: PropTypes.shape({
    displayInputForTin: PropTypes.bool,
  }).isRequired,
  hideLabel: PropTypes.bool,
  onToggleInputForTin: PropTypes.func.isRequired,
};

PartnerCompanyTaxFormPersonal.defaultProps = {
  hideLabel: undefined,
};

export default PartnerCompanyTaxFormPersonal;

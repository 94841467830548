import { combineReducers } from 'redux';

import ledgerClearingAccountsReducer from 'reducers/clearingAccountsReducer/ledgerClearingAccountsReducer';
import platformFundingAccountsReducer from 'reducers/clearingAccountsReducer/platformFundingAccountsReducer';
import submitMatchReducer from 'reducers/clearingAccountsReducer/submitMatchReducer';

const clearingAccountsReducer = combineReducers({
  ledgerClearingAccounts: ledgerClearingAccountsReducer,
  platformFundingAccounts: platformFundingAccountsReducer,
  submitMatch: submitMatchReducer,
});

export default clearingAccountsReducer;

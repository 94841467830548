import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getQueryParam } from 'helpers/queryParams';

import { partnershipSelector } from 'selectors/partnershipsSelectors';

const HeadingReceivablePaymentMethod = ({ partnership }) => {
  if (partnership.defaultReceivableFundingAccount) {
    return null;
  }

  return (
    <React.Fragment>
      <h3 className="font-color--greyXDark remove-margin-bottom">{`Getting paid from ${partnership.name}`}</h3>

      <p className="font-regular semibold font-color--dark-jordan margin-bottom--large margin-top--sm">
        {`Below are the payment methods you’ve added to get paid by ${partnership.name},
        feel free to make a change or add a new one. Once saved your future payment will be sent
        via your selected payment method.`}
      </p>
    </React.Fragment>
  );
};

HeadingReceivablePaymentMethod.propTypes = {
  partnership: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => {
  const partnershipId = getQueryParam('partnership_id');

  return {
    partnership: partnershipSelector(state, partnershipId),
  };
};

export { HeadingReceivablePaymentMethod };
export default connect(mapStateToProps)(HeadingReceivablePaymentMethod);

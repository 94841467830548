import PropTypes from 'prop-types';
import React from 'react';
import { v4 } from 'uuid';

import FlexCol from 'components/layout/FlexCol';
import FlexRow from 'components/layout/FlexRow';

import { getClassNames } from 'helpers/ui';

/**
 * A grouping of titles used within the TitleWithSubtitle. The titles are presented as a flex-column, with each
 * title rendered as a flex-row.
 *
 * @param {[]|Node[]} titles
 * @param {RestOfProps} rest
 * @returns {StatelessComponent}
 * @constructor
 */
const TitleBlock = ({ dataFullStory, titles, ...rest }) => {
  const keyRef = React.useRef(v4());

  return (
    <FlexCol {...rest}>
      {titles.map((title, i) => (
        <FlexRow
          className={getClassNames(
            rest,
            {
              'align-items--center': true,
              // If this is not the first row, add some margin on top
              'margin-top--xs': i > 0,
            },
            { classProp: 'rowClassName' },
          )}
          dataFullStory={dataFullStory}
          /* eslint-disable-next-line react/no-array-index-key */
          key={`${keyRef.current}-${i}`}
          stackOnMobile={false}
        >
          {title}
        </FlexRow>
      ))}
    </FlexCol>
  );
};

TitleBlock.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.node),
};

TitleBlock.defaultProps = {
  titles: [],
};

export default TitleBlock;

import PropTypes from 'prop-types';
import React from 'react';

import { Description } from 'components/text';

import { getClassNames } from 'helpers/ui';

const FormFieldDescription = ({ children, ...rest }) => (
  <Description className={getClassNames(rest, { 'form-field--description': true })}>{children}</Description>
);

FormFieldDescription.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

FormFieldDescription.defaultProps = {
  className: undefined,
};

export default FormFieldDescription;

import React from 'react';

import { CountryCodes } from 'context';

/**
 * When you want to get the value from CountryCodes, use this simple hook to fetch the context value.
 * @function
 * @returns {import('interfaces/countries').CountryCodeMap} - When invoked, returns the value from CountryCodes.Provider.
 */
const useCountryCodesContext = () => React.useContext(CountryCodes);

export default useCountryCodesContext;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'components/icon';
import { getDisplayStatusClass } from 'components/statusBadge/helpers';

import { AccountStatusIconMap } from 'constants/ui';

import './StatusBadge.scss';

const StatusBadge = ({ dataFullStory, displayStatus, displayText, iconClassName, status, textClassName }) => {
  // statuses can have spaces, but for constants, we'll just use the first part
  const splitStatus = status.split(' ')[0];
  const statusClass = splitStatus.toLowerCase();
  const displayStatusClass = getDisplayStatusClass(displayStatus);

  return (
    <React.Fragment>
      <Icon
        className={classNames({
          'status-badge--icon': true,
          [displayStatusClass]: !!displayStatusClass,
          [statusClass]: true,
          [iconClassName]: !!iconClassName,
        })}
        icon={AccountStatusIconMap[displayStatus]}
        marginRight={8}
        size={14}
      />
      <span
        className={classNames({
          'font-regular': true,
          'status-badge--text': true,
          [displayStatusClass]: !!displayStatusClass,
          [statusClass]: true,
          [textClassName]: !!textClassName,
        })}
        data-fullstory={dataFullStory}
      >
        {displayText}
      </span>
    </React.Fragment>
  );
};

StatusBadge.propTypes = {
  dataFullStory: PropTypes.bool,
  displayStatus: PropTypes.string,
  displayText: PropTypes.string,
  iconClassName: PropTypes.string,
  status: PropTypes.string.isRequired,
  textClassName: PropTypes.string,
};

StatusBadge.defaultProps = {
  dataFullStory: undefined,
  displayStatus: undefined,
  displayText: undefined,
  iconClassName: undefined,
  textClassName: undefined,
};

export default StatusBadge;

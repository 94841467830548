import React from 'react';
import { Text } from 'components/text';
import { TooltipMUIConditionalWrapper } from 'components/tooltip';
import { typography } from 'constants/styles';
import { TooltipPadding } from 'constants/tooltip';
const LimitedImportingToBaseCurrencyTooltip = ({ children, isShown, ledger, }) => (React.createElement(TooltipMUIConditionalWrapper, { tooltipProps: isShown
        ? {
            arrow: true,
            padding: TooltipPadding.SKINNY,
            title: (React.createElement(Text.Regular, { color: typography.TextColor.GREY_X_DARK, lineHeight: typography.TextLineHeight.MEDIUM, size: typography.TextSize.LEVEL_100 }, `Your account settings only allow you to import vendors associated with ${ledger.baseCurrencyCode} in your ${ledger.name} account.`)),
        }
        : {} }, children));
export default LimitedImportingToBaseCurrencyTooltip;

import * as routines from 'actions/routines/roles';

import { createErrorReducer } from 'store/redux';

export const failureCases = [routines.fetchRolesRoutine.FAILURE];

export const successCases = [routines.fetchRolesRoutine.REQUEST, routines.fetchRolesRoutine.SUCCESS];

const errorsReducer = createErrorReducer(successCases, failureCases);

export default errorsReducer;

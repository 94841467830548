import { INITIAL_ROUTE_KEY } from 'constants/sessionStorage';

import { routeAllowedForForwarding } from 'helpers/routeHelpers';

export const getSessionStorage = (g = global, w = window) => g.sessionStorage || w.sessionStorage;

/**
 * Get a key form session storage if it exists
 * @param key {string}
 * @param sessionStorage {?function}
 */
export const getSessionStorageValue = (key, sessionStorage = getSessionStorage()) => {
  if (!sessionStorage) {
    return undefined;
  }

  return sessionStorage.getItem(key);
};

/**
 * Removes a key-value pair in session storage if it exists
 * @param key {string}
 * @param sessionStorage {?function}
 */
export const removeSessionStorageValue = (key, sessionStorage = getSessionStorage()) => {
  if (!sessionStorage) {
    return false;
  }

  sessionStorage.removeItem(key);
  return true;
};

/**
 * Stores a key-value pair in session storage if it exists
 * @param key {string}
 * @param value {string}
 * @param sessionStorage {?function}
 */
export const setSessionStorageValue = (key, value, sessionStorage = getSessionStorage()) => {
  if (!sessionStorage) {
    return false;
  }

  sessionStorage.setItem(key, value);
  return true;
};

/**
 * Stores the given route in sessionStorage under the initial route key.
 * On login, the dashboard will forward to this url.
 * @param route {string}
 * @param sessionStorage {?function}
 */
export const storeRouteAsForwardingUrl = (route, sessionStorage = getSessionStorage()) => {
  if (!sessionStorage || !routeAllowedForForwarding(route)) {
    return false;
  }

  return setSessionStorageValue(INITIAL_ROUTE_KEY, route);
};

import React from 'react';
import styled from 'styled-components';

import { Text, TooltipMUIConditionalWrapper } from 'components';

import { colorBlueDarkHex, colorBlueXLight } from 'constants/styles/colors';
import { TextLineHeight, TextSize } from 'constants/styles/typography';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';

import {
  defaultStatuses,
  stepStatuses,
  customWaitingStatus,
} from 'modules/signup-v3/components/ProgressBarStepperV2/constants';

const PillLabel = styled.span`
  background-color: ${({ background }) => background || colorBlueXLight};
  color: ${({ color }) => color || colorBlueDarkHex};
  padding: 4px 8px;
  gap: 10px;
  border-radius: 100px;
  margin-right: 1rem;
`;

const statusConfig = {
  ...defaultStatuses,
  ...customWaitingStatus,
};

const StatusLabel = ({ status }) => {
  const tooltipProps = status === stepStatuses.waiting && {
    arrow: true,
    padding: TooltipPadding.SKINNY,
    placement: TooltipPlacement.TOP,
    title: (
      <Text.Regular lineHeight={TextLineHeight.EXTRA_MEDIUM} size={TextSize.LEVEL_100}>
        We&apos;re waiting for all representatives to complete their information.
      </Text.Regular>
    ),
  };

  return (
    <TooltipMUIConditionalWrapper tooltipProps={tooltipProps}>
      <PillLabel background={statusConfig[status]?.reviewBackground} color={statusConfig[status]?.reviewColor}>
        <Text.Semibold color={statusConfig[status]?.reviewColor || colorBlueDarkHex}>
          {statusConfig[status]?.reviewLabel || statusConfig[status]?.label}
        </Text.Semibold>
      </PillLabel>
    </TooltipMUIConditionalWrapper>
  );
};

export default StatusLabel;

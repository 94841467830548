import React from 'react';
import { FlexCol, Text } from 'components';
import { typography } from 'constants/styles';
const CompanyRegisteredAddressDisplayValue = ({ address, countryName, }) => (React.createElement(FlexCol, null,
    React.createElement(Text.Regular, { color: typography.TextColor.BLACK }, address.streetAddress),
    React.createElement(Text.Regular, { color: typography.TextColor.BLACK },
        address.postalcode,
        " ",
        address.city),
    React.createElement(Text.Regular, { color: typography.TextColor.BLACK }, countryName)));
export default CompanyRegisteredAddressDisplayValue;

import React from 'react';
import { Redirect } from 'react-router-dom';
import { BrandedError } from 'components';
import { LOGIN, NOT_FOUND } from 'constants/routes';
import { acceptingMembershipInviteShouldShowBrandedErrorPage, acceptingMembershipInviteShouldShowLoginPage, isAcceptingMembershipInvitePageErrorTypeNotFound, } from 'helpers/authentication';
import { IsLoadingBrandedPage } from 'modules/isLoading/IsLoading';
import AcceptMembershipInviteForm from '../AcceptMembershipInviteForm';
const AcceptMembershipInvitePage = (props) => {
    const { errorType, errors, isFetching, location, membershipInvite } = props;
    if (acceptingMembershipInviteShouldShowLoginPage(errorType, errors)) {
        return (React.createElement(Redirect, { to: {
                pathname: `/${LOGIN}`,
                state: { from: location },
            } }));
    }
    if (acceptingMembershipInviteShouldShowBrandedErrorPage(errorType)) {
        return (React.createElement(BrandedError, { errorText: "Double-check the URL, and make sure that it matches your original invitation. If the URLs match, your invitation probably expired. You'll need to contact your Routable admin for a new one.", errorTitle: "There's something wrong with your invitation", isV2: true }));
    }
    if (isAcceptingMembershipInvitePageErrorTypeNotFound(errorType)) {
        return (React.createElement(Redirect, { to: {
                pathname: `/${NOT_FOUND}`,
                state: { from: location },
            } }));
    }
    if (isFetching || !membershipInvite) {
        return React.createElement(IsLoadingBrandedPage, null);
    }
    return React.createElement(AcceptMembershipInviteForm, { ...props });
};
export default AcceptMembershipInvitePage;

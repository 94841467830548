/**
 * @fileOverview Defines the top-level reducer for feature flags.
 * @module reducers/featureFlags
 */

import { combineReducers } from 'redux';

import activeFlags from './activeFlags';
import errors from './errors';
import isFetching from './isFetching';

/**
 * The top-level reducer for reduxState.featureFlags.
 * @type {ReduxReducer}
 */
const featureFlagsReducer = combineReducers({
  activeFlags,
  errors,
  isFetching,
});

export default featureFlagsReducer;

import { companyType as companyTypes } from '@routable/companies-management';

import { RepresentativeIDTypes } from 'constants/document';

import { views } from 'modules/signup-v3/constants';

import { isCrossborderMultipleProvidersEnabled } from './helpers/featureFlags';

export const fields = {
  // it doesn't look like this is used anywhere
  addressCity: 'address.city',
  addressCountry: 'address.country',
  addressPostalCode: 'address.postalcode',
  addressState: 'address.state',
  addressStreetAddress: 'address.streetAddress',
  addressStreetAddressUnit: 'address.streetAddressUnit',
  agree: 'agree',
  agreeBusinessRepresentative: 'agreeBusinessRepresentative',
  agreeIdentity: 'agreeIdentity',
  agreeNoBeneficiaries: 'agree_no_beneficiaries',
  birthCountry: 'personalInfo.birthCountry',
  businessCategory: 'info.dwollaBusinessCategory',
  businessClassification: 'info.dwollaBusinessClassification',
  businessPhoneNumber: 'info.businessPhoneNumber',
  businessType: 'info.businessType',
  companyName: 'name',
  companyType: 'companyType',
  confirmBeneficialOwners: 'ui.confirmBeneficialOwners',
  dateOfBirth: 'personalInfo.dateOfBirth',
  dbaName: 'info.dbaName',
  documents: 'documents',
  documentsOwned: 'documentsOwned',
  documentType: 'documentType',
  documentUploadType: 'documentUploadType',
  TIN: 'info.tin',
  tinType: 'info.tinType',
  hasTIN: 'info.hasTin',
  uiBusinessWithoutEIN: 'ui.businessWithoutEIN',
  hasSavedDocumentNumber: 'ui.hasSavedDocumentNumber',
  isCompanyBeneficiary: 'personalInfo.isCompanyBeneficiary',
  legalFirstName: 'personalInfo.legalFirstName',
  legalLastName: 'personalInfo.legalLastName',
  legalName: 'info.legalName',
  mailingAddressCity: 'mailingAddress.city',
  mailingAddressCountry: 'mailingAddress.country',
  mailingAddressPostalCode: 'mailingAddress.postalcode',
  mailingAddressState: 'mailingAddress.state',
  mailingAddressStreetAddress: 'mailingAddress.streetAddress',
  mailingAddressStreetAddressUnit: 'mailingAddress.streetAddressUnit',
  ownershipPercentage: 'personalInfo.ownershipPercentage',
  passportCountry: 'personalInfo.passportCountry',
  passportNumber: 'personalInfo.passportNumber',
  phoneNumber: 'phoneNumber',
  physicalAddressCity: 'physicalAddress.city',
  physicalAddressCountry: 'physicalAddress.country',
  physicalAddressPostalCode: 'physicalAddress.postalcode',
  physicalAddressState: 'physicalAddress.state',
  physicalAddressStreetAddress: 'physicalAddress.streetAddress',
  physicalAddressStreetAddressUnit: 'physicalAddress.streetAddressUnit',
  representativeAddressCity: 'address.city',
  representativeAddressCountry: 'address.country',
  representativeAddressPostalCode: 'address.postalcode',
  representativeAddressState: 'address.state',
  representativeAddressStreetAddress: 'address.streetAddress',
  representativeAddressStreetAddressUnit: 'address.streetAddressUnit',
  representativeDocumentType: 'representativeDocumentType',
  sameAddresses: 'same_addresses',
  ssn: 'personalInfo.ssn',
  stateOfIncorporation: 'info.stateOfIncorporation',
  title: 'title',
};

export const mapViewToFields = {
  [views.legalBusinessName]: [
    {
      id: 'companyName',
      name: fields.companyName,
    },
    {
      id: 'legalName',
      name: fields.legalName,
    },
    {
      id: 'dbaName',
      name: fields.dbaName,
      notRequired: true,
    },
  ],
  [views.businessType]: [
    {
      name: fields.businessType,
      baseField: fields.companyType,
      isRequiredCondition: (companyType) => companyType === companyTypes.Enum.business,
    },
    {
      name: fields.stateOfIncorporation,
      notRequired: true,
    },
  ],

  [views.businessCategory]: [
    {
      name: fields.businessCategory,
      id: 'businessCategory',
    },
    {
      name: fields.businessClassification,
      id: 'businessClassification',
    },
  ],

  [views.businessAddress]: [
    {
      name: fields.physicalAddressCountry,
    },
    {
      name: fields.physicalAddressStreetAddress,
    },
    {
      name: fields.physicalAddressStreetAddressUnit,
      notRequired: true,
    },
    {
      name: fields.physicalAddressCity,
    },
    {
      name: fields.physicalAddressState,
    },
    {
      name: fields.physicalAddressPostalCode,
    },
    {
      name: fields.sameAddresses,
      notRequired: true,
    },
    {
      name: fields.mailingAddressCountry,
    },
    {
      name: fields.mailingAddressStreetAddress,
    },
    {
      name: fields.mailingAddressStreetAddressUnit,
      notRequired: true,
    },
    {
      name: fields.mailingAddressCity,
    },
    {
      name: fields.mailingAddressState,
    },
    {
      name: fields.mailingAddressPostalCode,
    },
  ],

  [views.businessPhoneNumber]: [
    {
      id: 'businessPhoneNumber',
      name: fields.businessPhoneNumber,
    },
  ],

  [views.uploadDocument]: [
    {
      id: 'documents',
      name: fields.documents,
    },
  ],
  [views.qualification]: [
    {
      id: 'agreeIdentity',
      name: fields.agreeIdentity,
    },
    {
      id: 'agreeBusinessRepresentative',
      name: fields.agreeBusinessRepresentative,
    },
  ],
  [views.personalInformation]: [
    {
      id: 'title',
      name: fields.title,
    },
    {
      id: 'isCompanyBeneficiary',
      name: fields.isCompanyBeneficiary,
      notRequired: true,
    },
    {
      id: 'legalFirstName',
      name: fields.legalFirstName,
    },
    {
      id: 'legalLastName',
      name: fields.legalLastName,
    },
    {
      id: 'phoneNumber',
      name: fields.phoneNumber,
    },
    {
      id: 'dateOfBirth',
      name: fields.dateOfBirth,
    },
    ...(isCrossborderMultipleProvidersEnabled()
      ? [
          {
            id: 'birthCountry',
            name: fields.birthCountry,
          },
          {
            id: 'ownershipPercentage',
            name: fields.ownershipPercentage,
          },
        ]
      : []),
  ],
  [views.representativeAddress]: [
    {
      name: fields.representativeAddressCountry,
    },
    {
      name: fields.representativeAddressStreetAddress,
    },
    {
      name: fields.representativeAddressStreetAddressUnit,
      notRequired: true,
    },
    {
      name: fields.representativeAddressCity,
    },
    {
      name: fields.representativeAddressState,
    },
    {
      name: fields.representativeAddressPostalCode,
    },
  ],
  [views.representativeIDNumber]: [
    {
      name: fields.representativeDocumentType,
    },
    {
      name: fields.ssn,
      baseField: fields.hasSavedDocumentNumber,
      isRequiredCondition: (hasSavedDocumentNumber) => !hasSavedDocumentNumber,
    },
    {
      name: fields.passportNumber,
      baseField: fields.hasSavedDocumentNumber,
      isRequiredCondition: (hasSavedDocumentNumber) => !hasSavedDocumentNumber,
    },
    {
      name: fields.passportCountry,
      baseField: fields.representativeDocumentType,
      isRequiredCondition: (documentType) => documentType === RepresentativeIDTypes.PASSPORT_NUMBER,
    },
  ],
  [views.representativeUploadDocument]: [
    {
      name: fields.documentUploadType,
    },
    {
      name: fields.documentsOwned,
    },
  ],
};

export const PLATFORM_DISPLAY_SHORT_NAME = 'Routable';
export const PLATFORM_LEGAL_NAME = 'Routable, Inc.';
export const PLATFORM_BOT_NAME = 'Routable';
export const PLATFORM_DOMAIN = 'routable.com';
export const PLATFORM_EMAIL_DOMAIN = 'routable-mail.com';
export const PLATFORM_URL = `https://${PLATFORM_DOMAIN}/`;
export const HELP_DOCS_URL = `https://docs.${PLATFORM_DOMAIN}`;
export const HELP_DOCS_URL_EXTERNAL = `https://faq.${PLATFORM_DOMAIN}`;
export const BLOG_URL = `https://blog.${PLATFORM_DOMAIN}`;
export const SYSTEM_STATUS_URL = `https://status.${PLATFORM_DOMAIN}/`;
export const LEGAL_PRIVACY_URL = `${PLATFORM_URL}legal/privacy/`;
export const LEGAL_TOS_URL = `${PLATFORM_URL}legal/terms-of-service/`;
export const SECURITY_URL = `${PLATFORM_URL}legal/security/`;
export const PLATFORM_EMAILS = {
    DEVELOPERS: `developers@${PLATFORM_DOMAIN}`,
    JOIN: `join@${PLATFORM_DOMAIN}`,
    LEGAL: `legal@${PLATFORM_DOMAIN}`,
    NO_REPLY: `no-reply@${PLATFORM_EMAIL_DOMAIN}`,
    PRIVACY: `privacy@${PLATFORM_DOMAIN}`,
    SALES: `sales@${PLATFORM_DOMAIN}`,
    SECURITY: `security@${PLATFORM_DOMAIN}`,
    SUPPORT: `support@${PLATFORM_DOMAIN}`,
    CHAT: `chat@${PLATFORM_DOMAIN}`,
};
export const PLATFORM_PHONE_NUMBERS = {
    MAIN: '(855) 800-5666',
};
export const PLATFORM_ADDRESS = {
    STREET: '600 California St',
    UNIT: 'Floor 11',
    CITY: 'San Francisco',
    STATE: 'CA',
    POSTALCODE: '94108',
    COUNTRY: 'United States',
};
export const PLATFORM_ADDRESS_LINE_1 = `${PLATFORM_ADDRESS.STREET} ${PLATFORM_ADDRESS.UNIT},`.trim();
export const PLATFORM_ADDRESS_LINE_2 = `${PLATFORM_ADDRESS.CITY}, ${PLATFORM_ADDRESS.STATE} ${PLATFORM_ADDRESS.POSTALCODE}`;
export const PLATFORM_VERIFY_ACCOUNT_DEPOSIT_MIN = 0.01;
export const PLATFORM_VERIFY_ACCOUNT_DEPOSIT_MAX = 0.99;

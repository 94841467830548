import {} from 'interfaces/actions';
import * as types from 'types/modals';
export const closeMatchPlatformPartnershipModal = () => ({
    type: types.MODAL_MATCH_PLATFORM_PARTNERSHIP_CLOSE,
});
export const closeMergeLedgerPartnershipModal = () => ({
    type: types.MODAL_MERGE_LEDGER_PARTNERSHIP_CLOSE,
});
export const openInternationalPaymentModal = ({ partnershipId, formContext, }) => ({
    payload: { partnershipId, formContext },
    type: types.MODAL_ADD_INTERNATIONAL_PAYMENT_OPEN,
});
export const closeInternationalPaymentModal = () => ({
    type: types.MODAL_ADD_INTERNATIONAL_PAYMENT_CLOSE,
});
export const openIntegrationSyncOptionsModal = () => ({
    type: types.MODAL_INTEGRATION_SYNC_OPTIONS_OPEN,
});

import React from 'react';
import { useFormContext } from 'react-hook-form';
import {} from '../../TaxFormRenderer.models';
import { fieldGroupMap } from './RenderSection.constants';
import { FieldsSection, FieldsSectionGroups, FieldsSectionTitle } from './RenderSection.styles';
const RenderSection = ({ fieldGroups, getTitle, title }) => {
    const { watch } = useFormContext();
    const companyType = watch('companyType');
    const titleText = title || getTitle?.({ companyType });
    return (React.createElement(FieldsSection, null,
        !!titleText && React.createElement(FieldsSectionTitle, null, titleText),
        React.createElement(FieldsSectionGroups, null, fieldGroups.map((groupId) => {
            const Component = fieldGroupMap[groupId];
            if (!Component) {
                return null;
            }
            return React.createElement(Component, { key: groupId });
        }))));
};
export default RenderSection;

import { submitPartnerCompanyGeneralInfoRoutine } from 'actions/routines/external';

import getRelationships from 'store/redux';

import {
  GET_CURRENT_COMPANY_SUCCESS,
  UPDATE_CURRENT_COMPANY_SUCCESS,
  UPDATE_ONBOARDING_COMPANY_SUCCESS,
  UPDATE_ONBOARDING_COMPANY_TYPE_SUCCESS,
} from 'types/currentCompany';

const getCurrentCompanyInfo = (info) => {
  let currentCompanyInfo = {};

  if (!info) {
    return currentCompanyInfo;
  }

  Object.keys(info).forEach((infoId) => {
    const relationships = getRelationships(info, infoId);

    currentCompanyInfo = {
      id: info[infoId].id,
      ...info[infoId].attributes,
      ...relationships,
    };
  });

  return currentCompanyInfo;
};

const companyInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CURRENT_COMPANY_SUCCESS:
    case submitPartnerCompanyGeneralInfoRoutine.SUCCESS:
    case UPDATE_CURRENT_COMPANY_SUCCESS:
    case UPDATE_ONBOARDING_COMPANY_SUCCESS:
    case UPDATE_ONBOARDING_COMPANY_TYPE_SUCCESS:
      return getCurrentCompanyInfo(action.payload.companyInfo);

    default:
      return state;
  }
};

export default companyInfoReducer;

import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';
import {
  createInternationalBankAccountRoutine,
  createInternationalFundingAccountRoutine,
} from 'actions/routines/funding';

import {
  FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_FAILURE,
  FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_REQUEST,
  FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_SUCCESS,
} from 'types/partnership';

export const REQUEST = [
  fetchExternalPartnershipRequestRoutine.REQUEST,
  FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_REQUEST,
  createInternationalFundingAccountRoutine.REQUEST,
  createInternationalBankAccountRoutine.REQUEST,
];

export const SUCCESS = [
  fetchExternalPartnershipRequestRoutine.SUCCESS,
  FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_SUCCESS,
  createInternationalFundingAccountRoutine.SUCCESS,
  createInternationalBankAccountRoutine.SUCCESS,
];

export const FAILURE = [
  fetchExternalPartnershipRequestRoutine.FAILURE,
  FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_FAILURE,
  createInternationalFundingAccountRoutine.FAILURE,
  createInternationalBankAccountRoutine.FAILURE,
];

export const ERROR_RESET = [...SUCCESS, ...REQUEST];

export const COMPLETE = [...FAILURE, ...SUCCESS];

import PropTypes from 'prop-types';
import React from 'react';

import Copyright from 'components/copyright/Copyright';
import { PrivacyPolicyLink, TermsOfServiceLink } from 'components/link';

import './ExternalFooter.scss';

const ExternalFooter = ({ securityFooter }) => (
  <footer className="external-footer">
    <div className="secure">
      {securityFooter ? (
        <div>
          <span className="icon-ic-shield" />
          <p className="small">Secure access</p>
        </div>
      ) : (
        <Copyright />
      )}
    </div>
    <div className="legal">
      <PrivacyPolicyLink />
      <TermsOfServiceLink />
    </div>
  </footer>
);

ExternalFooter.propTypes = {
  securityFooter: PropTypes.bool,
};

ExternalFooter.defaultProps = {
  securityFooter: undefined,
};

export default ExternalFooter;

import PropTypes from 'prop-types';
import React from 'react';

import { IconNames, StepHeaderAction } from 'components';

const DiscountToggle = ({ formUI, onToggle }) => (
  <StepHeaderAction
    actionText={formUI.showInvoiceDiscount ? 'Remove discount' : 'Add discount'}
    iconName={formUI.showInvoiceDiscount ? IconNames.CROSS : IconNames.ADD}
    onActionClick={() => onToggle(!formUI.showInvoiceDiscount)}
  />
);

DiscountToggle.propTypes = {
  formUI: PropTypes.shape().isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default DiscountToggle;

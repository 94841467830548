import { createIsSubmittingReducer } from 'store/redux';

import { SUBMIT_TAX_INFO_FAILURE, SUBMIT_TAX_INFO_REQUEST, SUBMIT_TAX_INFO_SUCCESS } from 'types/taxes';

export const finishCases = [SUBMIT_TAX_INFO_FAILURE, SUBMIT_TAX_INFO_SUCCESS];
export const requestCases = [SUBMIT_TAX_INFO_REQUEST];

const isSubmittingReducer = createIsSubmittingReducer(finishCases, requestCases);

export default isSubmittingReducer;

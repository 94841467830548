/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
import { BigNumber } from 'bignumber.js';

import { defaultDecimalPrecision } from 'constants/numeric';

import { isRoundingStyleCeil } from 'helpers/numbers';
import { valueOrZeroIfNull } from 'helpers/utility';

/**
 * Converts milliseconds to seconds.
 * @param {NumberMaybe} ms
 * @return {number}
 */
export const millisToSec = (ms = 0) => ms / 1000;

/**
 * Converts milliseconds to minutes.
 * @param {NumberMaybe} ms
 * @return {number}
 */
export const millisToMin = (ms = 0) => millisToSec(ms) / 60;

/**
 * Converts milliseconds to hours.
 * @param {NumberMaybe} ms
 * @return {number}
 */
export const millisToHours = (ms = 0) => millisToMin(ms) / 60;

/**
 * Converts seconds to milliseconds.
 * @param {NumberMaybe} sec
 * @return {number}
 */
export const secToMillis = (sec = 0) => sec * 1000;

/**
 * Converts seconds to minutes.
 * @param {NumberMaybe} sec
 * @return {number}
 */
export const secToMin = (sec = 0) => sec / 60;

/**
 * Converts seconds to hours.
 * @param {NumberMaybe} sec
 * @return {number}
 */
export const secToHours = (sec = 0) => secToMin(sec) / 60;

/**
 * Converts minutes to hours.
 * @param {NumberMaybe} min
 * @return {number}
 */
export const minToHours = (min = 0) => min / 60;

/**
 * Converts minutes to seconds.
 * @param {NumberMaybe} min
 * @return {number}
 */
export const minToSec = (min = 0) => min * 60;

/**
 * Converts minutes to milliseconds.
 * @param {NumberMaybe} min
 * @return {number}
 */
export const minToMillis = (min = 0) => minToSec(min) * 1000;

/**
 * Converts hours to minutes.
 * @param {NumberMaybe} hours
 * @return {number}
 */
export const hoursToMin = (hours = 0) => hours * 60;

/**
 * Converts hours to seconds.
 * @param {NumberMaybe} hours
 * @return {number}
 */
export const hoursToSec = (hours = 0) => hoursToMin(hours) * 60;

/**
 * Converts hours to milliseconds.
 * @param {NumberMaybe} hours
 * @return {number}
 */
export const hoursToMillis = (hours = 0) => hoursToSec(hours) * 1000;

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Rounds a number using a provided BigNumber rounding style (e.g. BigNumber.ROUND_HALF_UP).
 * @param {number} value
 * @param {number} style - Numeric enum imported from BigNumber, e.g. BigNumber.ROUND_HALF_UP
 * @param {number} [precision=2]
 * @return {number}
 */
export const roundWithStyle = (value, style, precision = defaultDecimalPrecision) => {
  const n = new BigNumber(value);
  // BigNumber will throw if this isn't a primitive number
  const primitivePrecision = Number.parseInt(precision, 10);
  return Number.parseFloat(n.toFixed(primitivePrecision, style));
};

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Rounds a value of N.5+ away from zero, to the given decimal precision.
 * This behavior was chosen because it aligns with BigNumber.
 * @example
 * roundHalfUp(4.5, 0); // 5
 * roundHalfUp(4.545); // 4.55 (using default precision of 2, so the value used for rounding is 454.5)
 * roundHalfUp(4.544); // 4.54 (using default precision of 2, so the value used for rounding is 454.4)
 * @param {number} value
 * @param {number} [precision=2]
 * @return {number}
 */
export const roundHalfUp = (value, precision = defaultDecimalPrecision) =>
  roundWithStyle(value, BigNumber.ROUND_HALF_UP, precision);

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Rounds a value of N.5- toward zero, to the given decimal precision.
 * This behavior was chosen because it aligns with BigNumber.
 * @example
 * roundHalfDown(4.5, 0); // 4
 * roundHalfDown(4.545, 2); // 4.54 (using default precision of 2, so the value used for rounding is 454.5)
 * roundHalfDown(4.546, 2); // 4.55 (using default precision of 2, so the value used for rounding is 454.6)
 * @param {number} value
 * @param {number} [precision=2]
 * @return {number}
 */
export const roundHalfDown = (value, precision = defaultDecimalPrecision) =>
  roundWithStyle(value, BigNumber.ROUND_HALF_DOWN, precision);

/**
 * A function that gets a percentage of the given amount, rounding the thousandths place with Math.floor.
 *
 * @example
 * const result = getPercentageRoundingToFloor(101, 12.5); // --> 12.62
 *
 * @param {number} amount - E.g. 101
 * @param {number} percentage - E.g. 12.5
 * @return {number}
 */
export const getPercentageRoundingToFloor = (amount, percentage) => Math.floor(amount * percentage) / 100;

/**
 * A function that gets a percentage of the given amount, rounding the thousandths place with Math.ceil.
 *
 * @example
 * const result = getPercentageRoundingToCeil(101, 12.5); // --> 12.63
 *
 * @param {number} amount - E.g. 101
 * @param {number} percentage - E.g. 12.5
 * @return {number}
 */
export const getPercentageRoundingToCeil = (amount, percentage) => Math.ceil(amount * percentage) / 100;

/**
 * A function that returns the rounding function to use for the given rounding style.
 * @param {RoundingStyle} roundingStyle - E.g. 101
 * @return {Function}
 */
export const getPercentageCalculationFunctionForRoundingStyle = (roundingStyle) => {
  if (isRoundingStyleCeil(roundingStyle)) {
    return getPercentageRoundingToCeil;
  }
  return getPercentageRoundingToFloor;
};

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Multiplies all arguments from left to right, using our floating point number instances instead of JS math operations.
 * @param {...number|string} values
 * @return {number}
 */
export const multiplyFloat = (...values) => {
  const operationResult = values.reduce((result, num) => {
    // mimic the JS (native) math behavior here, which is that N * null === N * 0, but N * undefined === NaN;
    // BigNumber will be NaN for null as well
    const multiplyNumber = valueOrZeroIfNull(num);
    return result.multipliedBy(new BigNumber(multiplyNumber));
    // always initialize with `1`, so if `values` is e.g. [3, 2, 1], the result of the first pass is 1*3, aka values[0]
  }, new BigNumber(1));

  return Number.parseFloat(operationResult.valueOf());
};

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Divides all arguments from left to right, using our floating point number instances instead of JS math operations.
 * @param {...number|string} values
 * @return {number}
 */
export const divideFloat = (...values) => {
  const [firstValue, ...divideValues] = values;

  const operationResult = divideValues.reduce((result, num) => {
    // mimic the JS (native) math behavior here, which is that N / null === N / 0, but N / undefined === NaN;
    // BigNumber will be NaN for null as well
    const divideNumber = valueOrZeroIfNull(num);
    return result.dividedBy(new BigNumber(divideNumber));
    // always initialize with the firstValue; since we are reducing with all values after the first,
    // if `values` is e.g. [8, 4, 2], we start with 8/4, aka values[0]/values[1]
  }, new BigNumber(firstValue));

  return Number.parseFloat(operationResult.valueOf());
};

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Adds all arguments from left to right, using our floating point number instances instead of JS math operations.
 * @param {...number|string} values
 * @return {number}
 */
export const plusFloat = (...values) => {
  const operationResult = values.reduce((result, num) => {
    // mimic the JS (native) math behavior here, which is that N + null === N + 0, but N + undefined === NaN;
    // BigNumber will be NaN for null as well
    const plusNumber = valueOrZeroIfNull(num);
    return result.plus(new BigNumber(plusNumber));
    // always initialize with `0`, so if `values` is e.g. [3, 4, 5], the result of the first pass is 0+3, aka values[0]
  }, new BigNumber(0));

  return Number.parseFloat(operationResult.valueOf());
};

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Subtracts all arguments from left to right, using our floating point number instances instead of JS math operations.
 * @param {...number|string} values
 * @return {number}
 */
export const minusFloat = (...values) => {
  const [firstValue, ...subtractValues] = values;

  const operationResult = subtractValues.reduce((result, num) => {
    // mimic the JS (native) math behavior here, which is that N - null === N - 0, but N - undefined === NaN;
    // BigNumber will be NaN for null as well
    const minusNumber = valueOrZeroIfNull(num);
    return result.minus(new BigNumber(minusNumber));
    // always initialize with firstValue; since we are reducing with all values after the first,
    // if `values` is e.g. [5, 2, 1], the result of the first pass is 5-2, aka values[0]-values[1]
  }, new BigNumber(firstValue));

  return Number.parseFloat(operationResult.valueOf());
};

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate constant. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Convenience/clarity export of accurate floating point math functions.
 * @type {Object.<string, Function>}
 */
export const FloatingPointMath = {
  divide: divideFloat,
  minus: minusFloat,
  multiply: multiplyFloat,
  plus: plusFloat,
  roundHalfDown,
  roundHalfUp,
};

//  A formatted version of a popular md5 implementation.
//  Original copyright (c) Paul Johnston & Greg Holt.
//  The function itself is now 42 lines long.
export function md5(inputString) {
  const hc = '0123456789abcdef';
  function rh(n) {
    let j;
    let s = '';
    for (j = 0; j <= 3; j++) {
      s += hc.charAt((n >> (j * 8 + 4)) & 0x0f) + hc.charAt((n >> (j * 8)) & 0x0f);
    }
    return s;
  }
  function ad(x, y) {
    const l = (x & 0xffff) + (y & 0xffff);
    const m = (x >> 16) + (y >> 16) + (l >> 16);
    return (m << 16) | (l & 0xffff);
  }
  function rl(n, c) {
    return (n << c) | (n >>> (32 - c));
  }
  function cm(q, a, b, x, s, t) {
    return ad(rl(ad(ad(a, q), ad(x, t)), s), b);
  }
  function ff(a, b, c, d, x, s, t) {
    return cm((b & c) | (~b & d), a, b, x, s, t);
  }
  function gg(a, b, c, d, x, s, t) {
    return cm((b & d) | (c & ~d), a, b, x, s, t);
  }
  function hh(a, b, c, d, x, s, t) {
    return cm(b ^ c ^ d, a, b, x, s, t);
  }
  function ii(a, b, c, d, x, s, t) {
    return cm(c ^ (b | ~d), a, b, x, s, t);
  }
  function sb(x) {
    let i;
    const nblk = ((x.length + 8) >> 6) + 1;
    const blks = new Array(nblk * 16);
    for (i = 0; i < nblk * 16; i++) {
      blks[i] = 0;
    }
    for (i = 0; i < x.length; i++) {
      blks[i >> 2] |= x.charCodeAt(i) << ((i % 4) * 8);
    }
    blks[i >> 2] |= 0x80 << ((i % 4) * 8);
    blks[nblk * 16 - 2] = x.length * 8;
    return blks;
  }
  let i;
  const x = sb(inputString);
  let a = 1732584193;
  let b = -271733879;
  let c = -1732584194;
  let d = 271733878;
  let olda;
  let oldb;
  let oldc;
  let oldd;
  for (i = 0; i < x.length; i += 16) {
    olda = a;
    oldb = b;
    oldc = c;
    oldd = d;
    a = ff(a, b, c, d, x[i + 0], 7, -680876936);
    d = ff(d, a, b, c, x[i + 1], 12, -389564586);
    c = ff(c, d, a, b, x[i + 2], 17, 606105819);
    b = ff(b, c, d, a, x[i + 3], 22, -1044525330);
    a = ff(a, b, c, d, x[i + 4], 7, -176418897);
    d = ff(d, a, b, c, x[i + 5], 12, 1200080426);
    c = ff(c, d, a, b, x[i + 6], 17, -1473231341);
    b = ff(b, c, d, a, x[i + 7], 22, -45705983);
    a = ff(a, b, c, d, x[i + 8], 7, 1770035416);
    d = ff(d, a, b, c, x[i + 9], 12, -1958414417);
    c = ff(c, d, a, b, x[i + 10], 17, -42063);
    b = ff(b, c, d, a, x[i + 11], 22, -1990404162);
    a = ff(a, b, c, d, x[i + 12], 7, 1804603682);
    d = ff(d, a, b, c, x[i + 13], 12, -40341101);
    c = ff(c, d, a, b, x[i + 14], 17, -1502002290);
    b = ff(b, c, d, a, x[i + 15], 22, 1236535329);
    a = gg(a, b, c, d, x[i + 1], 5, -165796510);
    d = gg(d, a, b, c, x[i + 6], 9, -1069501632);
    c = gg(c, d, a, b, x[i + 11], 14, 643717713);
    b = gg(b, c, d, a, x[i + 0], 20, -373897302);
    a = gg(a, b, c, d, x[i + 5], 5, -701558691);
    d = gg(d, a, b, c, x[i + 10], 9, 38016083);
    c = gg(c, d, a, b, x[i + 15], 14, -660478335);
    b = gg(b, c, d, a, x[i + 4], 20, -405537848);
    a = gg(a, b, c, d, x[i + 9], 5, 568446438);
    d = gg(d, a, b, c, x[i + 14], 9, -1019803690);
    c = gg(c, d, a, b, x[i + 3], 14, -187363961);
    b = gg(b, c, d, a, x[i + 8], 20, 1163531501);
    a = gg(a, b, c, d, x[i + 13], 5, -1444681467);
    d = gg(d, a, b, c, x[i + 2], 9, -51403784);
    c = gg(c, d, a, b, x[i + 7], 14, 1735328473);
    b = gg(b, c, d, a, x[i + 12], 20, -1926607734);
    a = hh(a, b, c, d, x[i + 5], 4, -378558);
    d = hh(d, a, b, c, x[i + 8], 11, -2022574463);
    c = hh(c, d, a, b, x[i + 11], 16, 1839030562);
    b = hh(b, c, d, a, x[i + 14], 23, -35309556);
    a = hh(a, b, c, d, x[i + 1], 4, -1530992060);
    d = hh(d, a, b, c, x[i + 4], 11, 1272893353);
    c = hh(c, d, a, b, x[i + 7], 16, -155497632);
    b = hh(b, c, d, a, x[i + 10], 23, -1094730640);
    a = hh(a, b, c, d, x[i + 13], 4, 681279174);
    d = hh(d, a, b, c, x[i + 0], 11, -358537222);
    c = hh(c, d, a, b, x[i + 3], 16, -722521979);
    b = hh(b, c, d, a, x[i + 6], 23, 76029189);
    a = hh(a, b, c, d, x[i + 9], 4, -640364487);
    d = hh(d, a, b, c, x[i + 12], 11, -421815835);
    c = hh(c, d, a, b, x[i + 15], 16, 530742520);
    b = hh(b, c, d, a, x[i + 2], 23, -995338651);
    a = ii(a, b, c, d, x[i + 0], 6, -198630844);
    d = ii(d, a, b, c, x[i + 7], 10, 1126891415);
    c = ii(c, d, a, b, x[i + 14], 15, -1416354905);
    b = ii(b, c, d, a, x[i + 5], 21, -57434055);
    a = ii(a, b, c, d, x[i + 12], 6, 1700485571);
    d = ii(d, a, b, c, x[i + 3], 10, -1894986606);
    c = ii(c, d, a, b, x[i + 10], 15, -1051523);
    b = ii(b, c, d, a, x[i + 1], 21, -2054922799);
    a = ii(a, b, c, d, x[i + 8], 6, 1873313359);
    d = ii(d, a, b, c, x[i + 15], 10, -30611744);
    c = ii(c, d, a, b, x[i + 6], 15, -1560198380);
    b = ii(b, c, d, a, x[i + 13], 21, 1309151649);
    a = ii(a, b, c, d, x[i + 4], 6, -145523070);
    d = ii(d, a, b, c, x[i + 11], 10, -1120210379);
    c = ii(c, d, a, b, x[i + 2], 15, 718787259);
    b = ii(b, c, d, a, x[i + 9], 21, -343485551);
    a = ad(a, olda);
    b = ad(b, oldb);
    c = ad(c, oldc);
    d = ad(d, oldd);
  }
  return rh(a) + rh(b) + rh(c) + rh(d);
}

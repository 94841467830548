import clsx from 'clsx';
import React from 'react';
import { useToggle } from 'hooks';
import { PaymentMethodBase, PaymentMethodDetails, PaymentMethodHeader, PaymentMethodStatus } from '..';
import { PaymentMethodVariant } from '../../enums';
import { getPaymentMethodHeader, getPaymentMethodIcon, getPaymentMethodStatus } from '../../helpers';
const PaymentMethodView = ({ isExpanded: isExpandedOverride, creator, fundingAccount, fundingInfoAddress, fundingInfoBankAccount, fundingInfoInternational, fundingSources, isPartnerAccountInDashboard, partnership, partnershipFundingAccount, showFundingAccountId, }) => {
    const [isExpanded, toggleExpanded] = useToggle(isExpandedOverride);
    const { subtitle, title } = getPaymentMethodHeader({
        fundingAccount,
        fundingInfoBankAccount,
        fundingInfoAddress,
    });
    const displaySubtitle = isExpanded ? '' : subtitle;
    const status = getPaymentMethodStatus({
        fundingAccount,
        fundingSources,
        isPartnerAccountInDashboard,
        partnershipFundingAccount,
    });
    return (React.createElement(PaymentMethodBase, { variant: PaymentMethodVariant.VIEW },
        React.createElement(PaymentMethodHeader, { currencyCode: fundingAccount.currencyCode, fundingIcon: getPaymentMethodIcon(fundingAccount), isExpanded: isExpanded, onToggleExpand: toggleExpanded, status: status, subtitle: displaySubtitle, title: title }),
        React.createElement("div", { className: clsx('payment_method__expandable', { expanded: isExpanded }) },
            React.createElement(PaymentMethodDetails, { creator: creator, fundingAccount: fundingAccount, fundingInfoAddress: fundingInfoAddress, fundingInfoBankAccount: fundingInfoBankAccount, fundingInfoInternational: fundingInfoInternational, isPartnerAccountInDashboard: isPartnerAccountInDashboard, partnership: partnership, partnershipFundingAccount: partnershipFundingAccount, showFundingAccountId: showFundingAccountId })),
        React.createElement(PaymentMethodStatus, { currencyCode: fundingAccount.currencyCode, status: status })));
};
export default PaymentMethodView;

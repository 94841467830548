import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Divider } from 'components/divider';
import { Instruction } from 'components/instruction';

import './StepHeader.scss';

const StepHeader = ({ children, description, hasDivider, num, stepHasContentAbove }) => (
  <div
    className={classNames({
      'step-header': true,
      'step-has-content-above': !!stepHasContentAbove,
    })}
  >
    <div className="step-header--instruction">
      <div className="content--left">
        <Instruction className="is-step-header" description={description} num={num} />
      </div>
      {children && <div className="content--right">{children}</div>}
    </div>

    {hasDivider && <Divider />}
  </div>
);

StepHeader.propTypes = {
  description: PropTypes.string.isRequired,
  hasDivider: PropTypes.bool,
  num: PropTypes.string,
  children: PropTypes.node,
  stepHasContentAbove: PropTypes.bool,
};

StepHeader.defaultProps = {
  children: undefined,
  hasDivider: true,
  num: undefined,
  stepHasContentAbove: undefined,
};

export default StepHeader;

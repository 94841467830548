import PropTypes from 'prop-types';
import React from 'react';

/**
 * InvoiceListText component that renders plain text content, potentially to be truncated.
 * @param {ComponentProps} props
 * @param {*} [props.children]
 * @param {boolean} [props.hasURL]
 * @param {Ref} [props.setRef]
 * @returns {StatelessComponent}
 */
const TextContent = ({ children, dataFullStory, dataTestId, hasURL, setRef }) => {
  if (hasURL) {
    return null;
  }

  return (
    <span
      className="display--inline-block font-xs font-color--darkBlue line-height--15 truncate table-cell-width-stabilization"
      data-fullstory={dataFullStory}
      data-testid={dataTestId}
      ref={setRef}
    >
      {children}
    </span>
  );
};

TextContent.propTypes = {
  children: PropTypes.node,
  dataFullStory: PropTypes.bool,
  dataTestId: PropTypes.string,
  hasURL: PropTypes.bool,
  setRef: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]).isRequired,
};

TextContent.defaultProps = {
  children: undefined,
  dataFullStory: undefined,
  dataTestId: undefined,
  hasURL: undefined,
};

export default TextContent;

import { ViewOff } from '@carbon/icons-react';
import { Button, TablematicTableColumnSelect, Tooltip } from '@routable/components';
import { RoutableEventsSync } from '@routable/framework';
import React from 'react';
import { SectionHeader } from './SectionHeaders.styled';
export const POSectionHeaderComponent = ({ columnSelectProps, is3WayMatching, isOnDetailsPage, subSectionId, }) => {
    const headersConfig = columnSelectProps.headersConfig.filter((header) => is3WayMatching || header.id !== 'receipt');
    const onHidePoLines = () => {
        const sectionType = subSectionId.includes('account') ? 'account' : 'item';
        RoutableEventsSync.Publish(`${sectionType}:po-section:toggle`, { value: false });
    };
    return (React.createElement(SectionHeader, { isOnDetailsPage: isOnDetailsPage },
        React.createElement("div", { className: "flex flex-row justify-between items-center text-xs pl-4 pr-2 h-full" },
            React.createElement("div", { className: "font-semibold" }, `${is3WayMatching ? 'Receipt and ' : ''}PO lines`),
            React.createElement("div", { className: "flex gap-3" },
                React.createElement(Tooltip, { "data-testid": "hide-po-lines-tooltip", tooltip: `Hide ${is3WayMatching ? 'receipt and ' : ''}PO lines` },
                    React.createElement(Button, { "data-testid": "po-header-view-off-column-settings", intent: "secondary", onPress: onHidePoLines, size: "small", variant: "floating" },
                        React.createElement(ViewOff, null))),
                React.createElement(TablematicTableColumnSelect, { ...columnSelectProps, headersConfig: headersConfig, isOnDetailsPage: isOnDetailsPage })))));
};

import React from 'react';
import Modal from 'components/modal';
import { width } from 'constants/styles';
import { getFundingProviderMemoModalTitle } from 'helpers/fundingProviderMemo';
import { isPaymentDeliveryOptionRTP, isPaymentDeliveryMethodInternational } from 'helpers/paymentMethods';
import { FundingProviderMemoModalBody } from './components';
import {} from './FundingProviderMemoModal.types';
const FundingProviderMemoModal = ({ amount, billingDataAchAddenda, billingDataRtpRemittance, companyName, defaultFundingProviderMemoValue, isModalOpen, onCloseModal, onUpdateFundingProviderMemo, currencyCode, paymentDeliveryMethod, paymentDeliveryOption, }) => {
    const isDeliveryOptionRTP = isPaymentDeliveryOptionRTP(paymentDeliveryOption);
    const isInternationalPayment = isPaymentDeliveryMethodInternational(paymentDeliveryMethod);
    return (React.createElement(Modal, { body: React.createElement(FundingProviderMemoModalBody, { amount: amount, billingDataAchAddenda: billingDataAchAddenda, billingDataRtpRemittance: billingDataRtpRemittance, companyName: companyName, currencyCode: currencyCode, defaultFundingProviderMemoValue: defaultFundingProviderMemoValue, isDeliveryOptionRTP: isDeliveryOptionRTP, isInternationalPayment: isInternationalPayment, onClose: onCloseModal, onUpdate: onUpdateFundingProviderMemo }), hasInlineFooter: true, modalHeader: getFundingProviderMemoModalTitle(defaultFundingProviderMemoValue, isDeliveryOptionRTP), modalState: isModalOpen, onCloseModal: onCloseModal, overrideStyle: { content: { width: width.contentWidth.MEDIUM } }, testId: "funding-provider-memo-modal" }));
};
export default FundingProviderMemoModal;

import { combineReducers } from 'redux';

import byKeyReducer from './byKeyReducer';
import fetchReducer from './fetchReducer';

const notificationTableDataReducer = combineReducers({
  byKey: byKeyReducer,
  isFetching: fetchReducer,
});

export default notificationTableDataReducer;

import {} from 'redux';
import {} from 'redux-saga-routines';
import { allKeys } from 'helpers/utility';
import {} from 'interfaces/jsonapi';
const initialStatus = {
    isLoading: false,
    isError: false,
    error: null,
};
export const generateStatusReducer = (routine, error) => (statusState = initialStatus, action) => {
    switch (action.type) {
        case routine.TRIGGER:
            return {
                isLoading: true,
                isError: false,
                error: null,
            };
        case routine.SUCCESS:
            return {
                isLoading: false,
                isError: false,
                error: null,
            };
        case routine.FAILURE:
            return {
                isLoading: false,
                isError: true,
                error,
            };
        default:
            return statusState;
    }
};
export const generateByIdReducer = (actions, fields) => (byIdState = {}, action) => {
    if (actions.includes(action.type)) {
        return {
            ...byIdState,
            ...fields.map((field) => action.payload[field]).reduce((acc, curr) => ({ ...curr, ...acc }), {}),
        };
    }
    return byIdState;
};
export const generateIdReducer = (idAction, field) => (allIdState = [], action) => {
    switch (action.type) {
        case idAction:
            return allKeys(action.payload[field]);
        default:
            return allIdState;
    }
};
export const generateLinksReducer = (routine, initialState = {}) => (linkState = initialState, action) => {
    switch (action.type) {
        case routine.SUCCESS:
            return action.payload.links;
        default:
            return linkState;
    }
};
export const generatePaginationReducer = (routine, initialState = {}) => (paginationState = initialState, action) => {
    switch (action.type) {
        case routine.SUCCESS:
            return action.payload.meta.pagination;
        default:
            return paginationState;
    }
};

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { IconNames } from 'components/icon';
// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { ItemDetailBlock } from 'components/itemBlock';

import { getLedgerReferenceText } from 'helpers/ledger';

import { ledgerIntegrationSelector } from 'selectors/integrationsSelectors';
import { itemSelector } from 'selectors/itemsSelectors';

const ItemBlockReference = ({ item, ledger }) => {
  if (!item.reference) {
    return null;
  }

  return (
    <ItemDetailBlock icon={IconNames.APPLICATION} text={item.reference} tooltipText={getLedgerReferenceText(ledger)} />
  );
};

ItemBlockReference.propTypes = {
  item: PropTypes.shape(),
  ledger: PropTypes.shape(),
};

ItemBlockReference.defaultProps = {
  item: {},
  ledger: null,
};

const mapStateToProps = (state, props) => {
  const itemId = props.match.params.id;

  return {
    item: props?.item ?? itemSelector(state, itemId),
    ledger: ledgerIntegrationSelector(state),
  };
};

const enhance = compose(withRouter, connect(mapStateToProps));

export { ItemBlockReference };
export default enhance(ItemBlockReference);

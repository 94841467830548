import { createSelector } from 'reselect';

const getState = (state) => state.ui;

/**
 * Returns whether the addPartnerFundingAccountOptions section is opened or not
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Boolean}
 */
export const isAddPartnerFundingAccountOptionsOpenSelector = createSelector(
  [getState],
  (state) => state.isAddPartnerFundingAccountOptionsOpen,
);

/**
 * Returns the data for the notification bar
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Boolean}
 */
export const notificationSelector = createSelector([getState], (state) => state.notification);

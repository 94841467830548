import { ResponseErrorCode } from 'constants/http';

import { isEqual } from 'helpers/utility';

/**
 * Returns whether a response error code is the connection aborted (timeout) code.
 * @param {?string} errorCode
 * @return {boolean}
 */
export const isResponseErrorCodeTimeout = (errorCode) => isEqual(errorCode, ResponseErrorCode.TIMEOUT);

import React from 'react';
import { useDispatch } from 'react-redux';
import { change, Field } from 'redux-form';
import LockedCountryInput from 'components/form/LockedCountryInput';
import { SelectFieldV2 } from 'components/selectV2';
import { InputTypes } from 'constants/ui';
import { requiredValidator } from 'helpers/fieldValidation';
import { lengthOf } from 'helpers/utility';
import {} from './CountryField.types';
import { useFormattedCountries } from './hooks';
const CountryField = ({ countryCodes, formName, isEnabled, label = 'Country', partnership, dataTestId = 'country-selection', styles, validate = [], ...rest }) => {
    const dispatch = useDispatch();
    const { prioritizedCountries } = useFormattedCountries(countryCodes, partnership);
    const onlyOneOptionAvailable = lengthOf(prioritizedCountries) === 1;
    React.useEffect(() => {
        if (onlyOneOptionAvailable) {
            dispatch(change(formName, rest.name, prioritizedCountries[0].value));
        }
    }, [dispatch, formName, onlyOneOptionAvailable, prioritizedCountries, rest.name]);
    return isEnabled ? (React.createElement(Field, { component: SelectFieldV2, dataTestId: dataTestId, isDisabled: onlyOneOptionAvailable, isLocked: onlyOneOptionAvailable, label: label, options: prioritizedCountries, styles: styles, type: InputTypes.TEXT, validate: [requiredValidator, ...validate], ...rest })) : (React.createElement(LockedCountryInput, { ...rest, dataTestId: dataTestId, label: label }));
};
export default CountryField;

import _get from 'lodash/get';

import { getPostalCodeTemplate } from 'data/validators/addressValidators/helpers';

import { isClientSideValidationDisabled } from 'helpers/formValidation';
import { getFieldBaseObjectPath } from 'helpers/stringHelpers';

export const postalCodeValidator = (value, allValues, props, name) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  if (!value) {
    return undefined;
  }

  const base = getFieldBaseObjectPath(name);
  const addressObj = _get(allValues, base);
  const templates = getPostalCodeTemplate(addressObj.country);
  const templateCount = templates.length;

  // Return undefined (no error) if no templates to evaluate
  if (templateCount === 0) {
    return undefined;
  }

  // Iterate over and check if at least one template passes validation
  for (let i = 0; i < templateCount; i += 1) {
    if (templates[i].test(value)) {
      return undefined;
    }
  }

  return ['Invalid postal code specified'];
};

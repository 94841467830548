import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';
import { FormControl } from 'components/form';
// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { BaseHint } from 'components/hintTypes';
import { textHelpers } from 'components/hintTypes/helpers';

import { SETTINGS_ACCOUNT_BANK_CONNECTIONS_ROUTE } from 'constants/routes';
import { Intent } from 'constants/ui';

import { noDefaultEventWithCallback } from 'helpers/events';
import { getLedgerIntegrationApplicationURL } from 'helpers/integrations';

/**
 * Renders hint when user has zero valid funding accounts
 * @param {ComponentProps} props
 * @param {History} props.history
 * @param {Boolean} props.isClearingAccount
 * @param {Boolean} [props.isNoValidAccounts]
 * @param {Boolean} [props.isRenderedInModal]
 * @param {Ledger} [props.ledger={}]
 * @param {Function} [props.onCloseModal]
 * @param {Function} [props.onCloseSidebar]
 * @returns {JSX.Element}
 */
const InvalidFundingAccountHint = ({
  history,
  isClearingAccount,
  isNoValidAccounts,
  isRenderedInModal,
  ledger,
  onCloseModal,
  onCloseSidebar,
}) => {
  const onButtonClick = () => {
    // Close sidebar if the close method is given
    if (onCloseSidebar) {
      onCloseSidebar();
    }

    if (isRenderedInModal) {
      onCloseModal();
    }

    const nextUrl = isNoValidAccounts
      ? SETTINGS_ACCOUNT_BANK_CONNECTIONS_ROUTE
      : getLedgerIntegrationApplicationURL(ledger);

    history.push(nextUrl);
  };

  const handleButtonClick = (evt) => noDefaultEventWithCallback(evt, onButtonClick);

  return (
    <FormControl>
      <BaseHint
        content={
          <ButtonV2
            className="margin-top--m"
            id="selectFundAcct_invalidLedger_matchBtn"
            intent={Intent.NEUTRAL}
            onClick={handleButtonClick}
          >
            {textHelpers.getMatchAccountHintButtonText(isNoValidAccounts)}
          </ButtonV2>
        }
        intent={Intent.WARNING}
        multiline
        text={textHelpers.getMatchAccountHintText(ledger.name, {
          isClearingAccount,
          isNoValidAccounts,
        })}
      />
    </FormControl>
  );
};

InvalidFundingAccountHint.propTypes = {
  history: PropTypes.shape().isRequired,
  isClearingAccount: PropTypes.bool,
  isNoValidAccounts: PropTypes.bool,
  isRenderedInModal: PropTypes.bool,
  ledger: PropTypes.shape(),
  onCloseModal: PropTypes.func,
  onCloseSidebar: PropTypes.func,
};

InvalidFundingAccountHint.defaultProps = {
  isClearingAccount: undefined,
  isNoValidAccounts: undefined,
  isRenderedInModal: undefined,
  ledger: {},
  onCloseModal: undefined,
  onCloseSidebar: undefined,
};

export default InvalidFundingAccountHint;

export const CLEAR_EMAIL_FORWARD = 'CLEAR_EMAIL_FORWARD';

export const DELETE_EMAIL_FORWARD_FAILURE = 'DELETE_EMAIL_FORWARD_FAILURE';
export const DELETE_EMAIL_FORWARD_REQUEST = 'DELETE_EMAIL_FORWARD_REQUEST';
export const DELETE_EMAIL_FORWARD_SUCCESS = 'DELETE_EMAIL_FORWARD_SUCCESS';

export const FETCH_NOTIFICATION_SETTINGS_FAILURE = 'FETCH_NOTIFICATION_SETTINGS_FAILURE';
export const FETCH_NOTIFICATION_SETTINGS_REQUEST = 'FETCH_NOTIFICATION_SETTINGS_REQUEST';
export const FETCH_NOTIFICATION_SETTINGS_SUCCESS = 'FETCH_NOTIFICATION_SETTINGS_SUCCESS';

export const FETCH_NOTIFICATION_TABLE_DATA_FAILURE = 'FETCH_NOTIFICATION_TABLE_DATA_FAILURE';
export const FETCH_NOTIFICATION_TABLE_DATA_REQUEST = 'FETCH_NOTIFICATION_TABLE_DATA_REQUEST';
export const FETCH_NOTIFICATION_TABLE_DATA_SUCCESS = 'FETCH_NOTIFICATION_TABLE_DATA_SUCCESS';

export const SUBMIT_EMAIL_FORWARD_FAILURE = 'SUBMIT_EMAIL_FORWARD_FAILURE';
export const SUBMIT_EMAIL_FORWARD_REQUEST = 'SUBMIT_EMAIL_FORWARD_REQUEST';
export const SUBMIT_EMAIL_FORWARD_SUCCESS = 'SUBMIT_EMAIL_FORWARD_SUCCESS';

export const UPDATE_NOTIFICATION_SETTINGS_FAILURE = 'UPDATE_NOTIFICATION_SETTINGS_FAILURE';
export const UPDATE_NOTIFICATION_SETTINGS_REQUEST = 'UPDATE_NOTIFICATION_SETTINGS_REQUEST';
export const UPDATE_NOTIFICATION_SETTINGS_SUCCESS = 'UPDATE_NOTIFICATION_SETTINGS_SUCCESS';

import { isCurrentCompanyTypePersonal } from 'helpers/currentCompany';

/**
 * Decorate every row for a contact with click handlers to archive, edit, and unarchive the contact.
 * @function
 * @para {ComponentProps} props
 * @param {PartnershipMember[]} props.data
 * @param {boolean} props.isPartnerSelfManaged
 * @param {function} props.onArchive
 * @param {function} props.onEdit
 * @param {function} props.onViewDetails
 * @param {function} props.onUnarchive
 * @returns {PartnershipMember[]}
 */
export const addContactActionClickHandlers = (props) => {
  const { company, data, isPartnerSelfManaged, onArchive, onEdit, onViewDetails, onUnarchive } = props;

  return data.map((row) => ({
    ...row,
    isArchiveDisabled: isCurrentCompanyTypePersonal(company),
    managed: isPartnerSelfManaged,
    onArchive: () => onArchive(row.id),
    onEdit: () => onEdit(row),
    onViewDetails: () => onViewDetails(row),
    onUnarchive: () => onUnarchive(row.id),
  }));
};

import * as types from 'types/affiliates';

export const fetchAffiliateConnectionsRequest = () => ({
  type: types.FETCH_AFFILIATE_CONNECTIONS_REQUEST,
});

export const fetchAffiliateConnectionsSuccess = (payload) => ({
  payload,
  type: types.FETCH_AFFILIATE_CONNECTIONS_SUCCESS,
});

export const fetchAffiliateConnectionsFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_AFFILIATE_CONNECTIONS_FAILURE,
});

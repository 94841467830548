import { FetchService } from 'services';
import { payloadToJSONAPI } from 'services/api/formatHelpers';
import {
  getPartnershipMembersEndpoint,
  getSinglePartnershipMemberEndpoint,
} from 'services/api/partnershipMemberEndpoints';

/**
 * GET PartnershipMembers for a Partnership.
 * @param {string} partnershipId
 * @param {ObjectMaybe} [options={}]
 * @return {Promise}
 */
export const fetchPartnershipMembers = async (partnershipId, options = {}) => {
  const { queryParams, ...rest } = options;
  const config = {
    ...rest,
    endpoint: getPartnershipMembersEndpoint(partnershipId, queryParams),
    method: 'GET',
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * GET one PartnershipMember for a Partnership.
 * @param {string} partnershipId
 * @param {string} partnershipMemberId
 * @param {ObjectMaybe} [options={}]
 * @return {Promise}
 */
export const fetchSinglePartnershipMember = async (partnershipId, partnershipMemberId, options = {}) => {
  const config = {
    ...options,
    endpoint: getSinglePartnershipMemberEndpoint(partnershipId, partnershipMemberId),
    method: 'GET',
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * PATCH an update to a PartnershipMember.
 * @param {string} partnershipId
 * @param {string} partnershipMemberId
 * @param {PartnershipMember} data - payload
 * @param {ObjectMaybe} [options={}]
 * @return {Promise}
 */
export const updatePartnershipMember = async (partnershipId, partnershipMemberId, data, options = {}) => {
  const payload = payloadToJSONAPI(data, 'PartnershipMember');
  const config = {
    ...options,
    endpoint: getSinglePartnershipMemberEndpoint(partnershipId, partnershipMemberId),
    method: 'PATCH',
    payload,
    requireAuth: true,
    setRequester: true,
  };
  return FetchService.request(config);
};

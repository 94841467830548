import { CurrencyCodeUsd } from 'constants/currency';
import { mapCurrencyCodeToSelectOption } from 'helpers/currency';
import { reduceKeys } from 'helpers/utility';
import { usePartnershipPaymentCurrencies } from 'hooks';
import {} from './usePartnershipPaymentOptions.types';
export const usePartnershipPaymentOptions = ({ partnershipId, }) => {
    const { data, isFetching } = usePartnershipPaymentCurrencies(partnershipId);
    const currencyCodesBillSelectable = data?.currencyCodesBillSelectable;
    const currencyCodesBill = currencyCodesBillSelectable?.length ? currencyCodesBillSelectable : [CurrencyCodeUsd];
    const currencyCodesBillOptions = currencyCodesBill.map(mapCurrencyCodeToSelectOption);
    const mapCurrencyReceiverToOptions = (routePayable) => mapCurrencyCodeToSelectOption(routePayable.currencyCodeReceiver);
    const { receiverOptions, defaultCurrencyCodeReceiver } = reduceKeys(data?.routesPayable, (accumulator, key) => ({
        receiverOptions: {
            ...accumulator.receiverOptions,
            [key.toUpperCase()]: data.routesPayable[key].map(mapCurrencyReceiverToOptions),
        },
        defaultCurrencyCodeReceiver: {
            ...accumulator.defaultCurrencyCodeReceiver,
            [key.toUpperCase()]: data.routesPayable[key][0].currencyCodeReceiver,
        },
    }), { receiverOptions: {}, defaultCurrencyCodeReceiver: {} });
    return {
        defaultBillCurrency: data?.defaultBillCurrency,
        defaultCurrencyCodeReceiverByBillCurrency: defaultCurrencyCodeReceiver,
        currencyCodesBillOptions,
        isFetching,
        hasData: Boolean(data),
        vendorCurrencyCodeReceiverOptionsByBillCurrency: receiverOptions,
    };
};

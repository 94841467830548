import { useQuery } from '@tanstack/react-query';
import { queryContext } from '@routable/shared';
import { policyApi } from '../services/policies/policies.service';
import { generateKeys } from './queryKeys.service';
export const useApprovalPolicies = ({ enabled = true }) => useQuery({
    context: queryContext,
    queryKey: generateKeys.list(),
    queryFn: () => policyApi.readAll(),
    select: (responseBody) => responseBody?.data,
    refetchOnMount: 'always',
    enabled,
});

/**
 * Date string format tokens.
 * @type {Object.<string, DateFormatToken>}
 */
export const DateFormats = {
  // numeric date string with largest unit first, example output: 2025-10-03
  FULL_NUMERIC_YEAR_MONTH_DAY: 'YYYY-MM-DD',
  // numeric date string, with slashes in between, example output: 06/18/2021
  FULL_NUMERIC_MONTH_DAY_YEAR_SLASHED: 'MM/DD/YYYY',
  // localized time string with meridian and timezone, example output: 8:30 PM PST
  LOCAL_TIME_AM_PM_TIMEZONE: 'LT zz',
  // localized short date string, example output: Sep 4, 1986
  LOCAL_DATE_SHORT: 'll',
  // localized time string with meridian, example output: 8:30 PM
  LOCAL_TIME_AM_PM: 'LT',
  // non-localized short date string, example output: Oct 03, 2025
  SHORT_ALPHA_MONTH_FULL_YEAR: 'MMM DD, YYYY',
  // non-localized short date string, example output: Oct 3, 2025
  SHORT_MONTH_SHORT_DATE_FULL_YEAR: 'MMM D, YYYY',
  // date and time string with meridian and timezone, example output: 06/11/2021 12:43pm
  SLASH_DATE_TIME: 'MM/DD/YYYY hh:mma',
  // date and time string with meridian and timezone, example output: 06/11/2021 12:43pm CDT
  SLASH_DATE_TIME_TIMEZONE: 'MM/DD/YYYY hh:mma zz',
};

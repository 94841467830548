import { combineReducers } from 'redux';

import allItemMembersReducer from './allItemMembers';
import itemMembersByIdReducer from './byIdReducer';

const itemMemberReducer = combineReducers({
  allIds: allItemMembersReducer,
  byId: itemMembersByIdReducer,
});

export default itemMemberReducer;

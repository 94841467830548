import { change } from 'redux-form';

import { formNamesMatchClearingAccounts } from 'constants/forms';

import * as types from 'types/clearingAccounts';

// *************************************
// Modal
// *************************************
export const closeMatchClearingAccountModal = () => ({
  type: types.MODAL_MATCH_CLEARING_ACCOUNT_CLOSE,
});

export const openMatchClearingAccountModal = (payload) => ({
  payload,
  type: types.MODAL_MATCH_CLEARING_ACCOUNT_OPEN,
});

// *************************************
// Filter
// *************************************
export const changeFilter = (newFilter) => change(formNamesMatchClearingAccounts.LIST, 'activeFilter', newFilter);

// *************************************
// Form
// *************************************
export const matchClearingAccountToBank = (type, ledgerAccountWithLedgerRef) =>
  change(formNamesMatchClearingAccounts.MODAL, type, ledgerAccountWithLedgerRef);

export const removeClearingAccountMatch = (type) => change(formNamesMatchClearingAccounts.MODAL, type, null);

// *************************************
// Fetch
// *************************************
export const fetchFundingAccountsForClearingMatchingRequest = (integrationConfigId, filter = {}) => ({
  payload: { integrationConfigId, filter },
  type: types.FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_REQUEST,
});

export const fetchFundingAccountsForClearingMatchingSuccess = (payload) => ({
  payload,
  type: types.FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_SUCCESS,
});

export const fetchFundingAccountsForClearingMatchingFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_FAILURE,
});

export const fetchUnmatchedLedgerClearingAccountsRequest = (integrationConfigId, filter = {}) => ({
  payload: { integrationConfigId, filter },
  type: types.FETCH_UNMATCHED_LEDGER_CLEARING_ACCOUNTS_REQUEST,
});

export const fetchUnmatchedLedgerClearingAccountsSuccess = (payload) => ({
  payload,
  type: types.FETCH_UNMATCHED_LEDGER_CLEARING_ACCOUNTS_SUCCESS,
});

export const fetchUnmatchedLedgerClearingAccountsFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_UNMATCHED_LEDGER_CLEARING_ACCOUNTS_FAILURE,
});

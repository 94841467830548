import _diffBy from 'lodash/differenceBy';
import _isEqual from 'lodash/isEqual';
import React from 'react';

import {
  getFundingInfoAddressForFundingAccountId,
  getFundingInfoBankAccountForFundingAccountId,
} from 'helpers/funding';
import { isPaymentDeliveryMethodAchOrInternational } from 'helpers/paymentMethods';
import { getPartnerBankAccountViewTypeForPaymentMethod } from 'helpers/ui';

import * as valueHelpers from './values';

/**
 * Returns the funding account that was just added to partnership funding accounts.
 * @function
 * @param {Object[]} prevFundingAccounts - current partnershipFundingAccounts
 * @param {Object[]} fundingAccounts - previous partnershipFundingAccounts
 * @return {StringMaybe} Id if the new funding account
 */
export const getNewlyAddedFundingAccount = (prevFundingAccounts = [], fundingAccounts = []) => {
  if (fundingAccounts.length === prevFundingAccounts.length + 1) {
    const added = _diffBy(fundingAccounts, prevFundingAccounts, (account) => account.id);
    return added[0].id;
  }

  return undefined;
};

/**
 * Returns the selected funding account if there already is one,
 * or the one best-fit for selection if one has not been chosen.
 * The end result is that we auto-select either the primary funding account
 * or the preferred funding account (in that order) if we can.
 * @param {Object} props - withPartnerFundingAccounts wrapped component props
 * @return {Object} Selected, or best-fit for selection, funding account
 */
export const getSelectedFundingAccount = ({
  currencyCodeReceiver,
  isReceivingCurrencyEnabled,
  partnershipFundingAccounts,
  paymentDeliveryMethod,
  input,
} = {}) => {
  const currencyCode = isReceivingCurrencyEnabled ? currencyCodeReceiver : null;

  return valueHelpers.getSelectedOrBestFitFundingAccount(
    input?.value,
    paymentDeliveryMethod,
    partnershipFundingAccounts,
    currencyCode,
  );
};

/**
 * Returns whether we should update the order in which accounts are displayed, in the ui.
 * @param {Object} prevProps - PartnerPaymentMethodSelection component previous props
 * @param {Object} currentProps - PartnerPaymentMethodSelection component current props
 * @param {Object} state - PartnerPaymentMethodSelection component state
 * @return {boolean} Whether we should update the displayed accounts sort-order
 */
export const shouldUpdateAccountsDisplayOrder = (prevProps = {}, currentProps = {}, state = {}) => {
  const { accountsDisplayMax, partnershipFundingAccounts, paymentDeliveryMethod } = currentProps;
  const { partnershipFundingAccounts: lastFundingAccounts } = prevProps;
  const { isShowingAllAccounts, sortedFundingAccounts } = state;

  if (!_isEqual(partnershipFundingAccounts, lastFundingAccounts)) {
    // data in funding accounts has changed -- should update
    return true;
  }

  if (
    currentProps.isInternationalPaymentsEnabled &&
    currentProps.currencyCodeReceiver !== prevProps.currencyCodeReceiver
  ) {
    return true;
  }

  const lastSelectedFundingAccount = getSelectedFundingAccount(prevProps);
  const selectedFundingAccount = getSelectedFundingAccount(currentProps);
  const displayedFundingAccountIds = sortedFundingAccounts
    .filter((fundingAccount) => fundingAccount.paymentDeliveryMethod === paymentDeliveryMethod)
    .map((fundingAccount) => fundingAccount.id);

  // if new funding account selected and is too far down in the list to be displayed -- should update
  return (
    selectedFundingAccount !== lastSelectedFundingAccount &&
    !isShowingAllAccounts &&
    displayedFundingAccountIds.indexOf(selectedFundingAccount) >= accountsDisplayMax
  );
};

/**
 * Returns funding accounts, sorted correctly for display in the ui.
 * @param {Object} props - PartnerPaymentMethodSelection component props
 * @return {Object[]} Funding accounts array
 */
export const getAccountsDisplayOrder = (props = {}) => {
  const { isReceivingCurrencyEnabled, partnershipFundingAccounts } = props;
  const currencyCodeReceiver = isReceivingCurrencyEnabled ? props.currencyCodeReceiver : null;

  const selectedFundingAccount = getSelectedFundingAccount(props);

  return valueHelpers.sortFundingAccountsForDisplay(
    partnershipFundingAccounts,
    selectedFundingAccount,
    currencyCodeReceiver,
  );
};

/**
 * Returns the information needed by the view-account-details modal to
 * display details for a given funding account.
 * @param {Object} props - PartnerPaymentMethodSelection component props
 * @param {Object} options - Additional data provided to the component callback
 * @return {Object} fundingInfoAddress, fundingInfoBankAccount, and viewType
 */
export const getViewAccountDetails = (props = {}, options = {}) => {
  const { fundingAccountsById, fundingInfoAddressesById, fundingInfoBankAccounts, paymentDeliveryMethod } = props;

  const { partnershipFundingAccount } = options;

  const fundingInfoBankAccount = getFundingInfoBankAccountForFundingAccountId(
    partnershipFundingAccount.fundingAccount,
    fundingAccountsById,
    fundingInfoBankAccounts,
  );

  const fundingInfoAddress = getFundingInfoAddressForFundingAccountId(
    partnershipFundingAccount.fundingAccount,
    fundingAccountsById,
    fundingInfoAddressesById,
  );

  const viewType = getPartnerBankAccountViewTypeForPaymentMethod(paymentDeliveryMethod);

  return { fundingInfoAddress, fundingInfoBankAccount, viewType };
};

/**
 * Returns the payload to use when submitting a request to change a funding account to primary.
 * @param {Object} partnershipFundingAccount - PartnerPaymentMethodSelection component props
 * @return {Object} Payload object
 */
export const getChangeToPrimaryFundingAccountPayload = (partnershipFundingAccount = {}) => ({
  isPrimary: true,
  name: partnershipFundingAccount.name,
});

/**
 * Returns filtered children that match passed paymentDeliveryMethod
 * @param {ReactChildren} children
 * @param {PaymentDeliveryMethodType} paymentDeliveryMethod
 * @param {Boolean} [isCreateInternationalItemEnabled=false]
 * @return {Node[]}
 */
export const getPartnerPaymentMethodFilteredChildren = (
  children,
  paymentDeliveryMethod,
  isCreateInternationalItemEnabled,
) => {
  const childrenArray = React.Children.toArray(children);

  return childrenArray.filter((child) => {
    const { fundingAccount, partnerFundingAccount } = child.props;
    const fundingAccountPaymentDeliveryMethod =
      // TODO: As part of $XB GA, the fundingAccount reference will be removed when we migrate
      // to the newer PaymentMethod component which only relies on partnerFundingAccount.
      partnerFundingAccount?.paymentDeliveryMethod || fundingAccount?.paymentDeliveryMethod;
    const isAchOrInternational = isPaymentDeliveryMethodAchOrInternational(paymentDeliveryMethod);

    // If selected payment delivery method is ACH (or international) and create international item feature flag
    // is enabled, treat the ACH and INTERNATIONAL payment delivery method as same
    if (isAchOrInternational && isCreateInternationalItemEnabled) {
      return isPaymentDeliveryMethodAchOrInternational(fundingAccountPaymentDeliveryMethod);
    }

    // otherwise, do a strict match between funding account's payment delivery method
    // and selected delivery method
    return fundingAccountPaymentDeliveryMethod === paymentDeliveryMethod;
  });
};

/**
 * Returns true if "Show all" partner accounts button should be displayed
 * @param {number} accountsDisplayMax
 * @param {Node[]} filteredChildren
 * @return {Boolean}
 */
export const shouldDisplayShowAllPartnerAccounts = (accountsDisplayMax, filteredChildren) =>
  accountsDisplayMax > -1 && (filteredChildren?.length ?? 0) > accountsDisplayMax;

/**
 * Returns visible partner payment accounts based on truthfulness of `shouldDisplayShowAll` param
 * @param {number} accountsDisplayMax
 * @param {Node[]} filteredChildren
 * @return {Node[]}
 */
export const getPartnerVisibleAccounts = (accountsDisplayMax, filteredChildren) =>
  shouldDisplayShowAllPartnerAccounts(accountsDisplayMax, filteredChildren)
    ? filteredChildren.slice(0, accountsDisplayMax)
    : filteredChildren;

/**
 * Returns true if length of visible partner accounts is greater than zero
 * @param {Node[]} displayChildren
 * @return {Boolean}
 */
export const hasVisiblePartnerAccounts = (displayChildren) => !!displayChildren?.length;

/**
 * Returns true if partner accounts list is expanded. We know that if we aren't maxing the number of accounts to show,
 * but the display max has changed.
 * @param {number} accountsDisplayMax
 * @param {number} initialAccountsDisplayMax
 * @return {Boolean}
 */
export const isPartnerAccountsListExpanded = (accountsDisplayMax, initialAccountsDisplayMax) =>
  accountsDisplayMax === -1 && initialAccountsDisplayMax !== accountsDisplayMax;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { isCompanyTypeBusiness } from 'helpers/currentCompany';
import { isFn } from 'helpers/utility';
import {} from '../../ContactRequiredToAllowSelectPaymentMethodHint.types';
import { CreateEditPaymentHintTextBusinessComponent } from '../CreateEditPaymentHintTextBusinessComponent';
import { CreateEditPaymentHintTextPersonalComponent } from '../CreateEditPaymentHintTextPersonalComponent';
import { FinalizePaymentHintTextComponent } from '../FinalizePaymentHintTextComponent';
const ContactRequiredHintText = ({ closeCreatePartnershipModal, isInviting, onCloseExistingItemSidePanel, onOpenContactSidePanel, selectedCompany, }) => {
    const history = useHistory();
    const isBusinessCompany = isCompanyTypeBusiness(selectedCompany?.partner?.companyType);
    const isInCreateEditPaymentOrCreatePartnership = isFn(onOpenContactSidePanel);
    if (isInCreateEditPaymentOrCreatePartnership && isBusinessCompany) {
        return (React.createElement(CreateEditPaymentHintTextBusinessComponent, { closeCreatePartnershipModal: closeCreatePartnershipModal, history: history, isInviting: isInviting, onOpenContactSidePanel: onOpenContactSidePanel, selectedCompany: selectedCompany }));
    }
    if (isInCreateEditPaymentOrCreatePartnership && !isBusinessCompany) {
        return (React.createElement(CreateEditPaymentHintTextPersonalComponent, { isInviting: isInviting, onOpenContactSidePanel: onOpenContactSidePanel, selectedCompany: selectedCompany }));
    }
    return (React.createElement(FinalizePaymentHintTextComponent, { history: history, onCloseExistingItemSidePanel: onCloseExistingItemSidePanel }));
};
export default ContactRequiredHintText;

import { useMaskContent } from '@routable/components';
import { View, ViewOff } from '@carbon/icons-react';
import React, { useState } from 'react';
export const TINWithRevealAction = ({ tin = '' }) => {
    const [visible, setVisible] = useState(false);
    const displayValue = useMaskContent({
        lastFour: false,
        visible,
        content: tin,
    });
    const handleToggleVisible = () => {
        setVisible((current) => !current);
    };
    const Icon = visible ? ViewOff : View;
    return (React.createElement("span", { className: "inline-flex gap-3 items-center" },
        displayValue,
        React.createElement(Icon, { "aria-label": visible ? 'Hide TIN value' : 'Reveal TIN value', className: "hover:cursor-pointer fill-grey-50", onClick: handleToggleVisible })));
};

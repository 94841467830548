import { detect } from 'detect-browser';
import _invert from 'lodash/invert';

import { MobileOperatingSystem } from 'constants/device';
import { DASHBOARD, EXTERNAL } from 'constants/routes';

import { isNodeEnvProduction } from 'helpers/env';

import { getCurrentUrlPath } from './urls';

/**
 * Get the major version of a browser (assumed format X.Y.Z or X.Y)
 * @param {string} version
 * @return {int}
 */
export const getMajorVersion = (version) => {
  if (!version) {
    return -1;
  }
  return parseInt(String(version).split('.')[0], 10) || -1;
};

/**
 * Method to check whether the current browser is supported.
 * We currently only reject IE < v11.
 * @param {DetectedBrowser} [browser]
 * @return {boolean}
 */
export const isBrowserSupported = (browser = detect()) => {
  if (!browser) {
    return false;
  }

  // Allow bots - allows Google to crawl our site
  if (browser.bot && isNodeEnvProduction()) {
    const url = getCurrentUrlPath();

    if (url.startsWith(`/${DASHBOARD}`) || url.startsWith(`/${EXTERNAL}`)) {
      return false;
    }

    return true;
  }

  // Reject unrecognized browsers or browser without a version
  if (!browser.name || !browser.version) {
    return false;
  }

  const majorVersion = getMajorVersion(browser.version);

  // Reject unknown browser version
  if (majorVersion < 0) {
    return false;
  }

  switch (browser.name) {
    case 'ie':
      if (majorVersion < 11) {
        return false;
      }
      return true;

    default:
      return true;
  }
};

/**
 * Method to check whether the current browser is supported for opening the file viewer.
 * First checks the result of our base `isBrowserSupported` function, then weeds out
 * all IE from there.
 * @param {DetectedBrowser} [browser]
 * @return {boolean}
 */
export const isBrowserSupportedForFileViewer = (browser = detect()) => {
  if (!browser) {
    return false;
  }

  if (!isBrowserSupported(browser)) {
    return false;
  }

  switch (browser.name) {
    case 'ie':
      return false;

    default:
      return true;
  }
};

/**
 * Returns whether the current OS is (probably) a mobile/handheld device.
 * @param {DetectedBrowser} [browser]
 * @returns {Boolean|undefined}
 */
export const isMobileOS = (browser = detect()) => {
  if (browser) {
    const { os } = browser;
    // the OS names have mixed capitalization and spacing, so
    // just inverting our enum to { name: enumConstant } is easiest
    return Boolean(_invert(MobileOperatingSystem)?.[os]);
  }

  return undefined;
};

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Text-only element (children can be strings or inline text-friendly elements)
 * for rendering section/content subtitles.
 * @function
 * @param {Object} props
 * @return {StatelessComponent}
 * @constructor
 */
const ContentSubtitle = ({ children, className }) => (
  <p
    className={classNames({
      'font-color--dark-jordan': true,
      'font-regular': true,
      'remove-margin-bottom': true,
      [className]: !!className,
    })}
  >
    {children}
  </p>
);

ContentSubtitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

ContentSubtitle.defaultProps = {
  children: undefined,
  className: undefined,
};

export default ContentSubtitle;

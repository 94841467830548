import PropTypes from 'prop-types';
import React from 'react';

import { ContactUs } from 'components/error';
import { TooltipContentText } from 'components/tooltip';

import { CONTACT_SUPPORT_LOWERCASE_LABEL } from 'constants/labels';

/**
 * The tooltip content for the transaction fee relation help icon.
 * If this is a failure resulting from an issue with a customer partner's funding account,
 * displays the "Customer" prefix. if this is a failure on our user's funding account,
 * no prefix is added.
 * @param {String} children
 * @return {StatelessComponent}
 */
const BalanceTransactionStatusTooltipContent = ({ failureCode, failureDescription, isCustomer = false }) => {
  <TooltipContentText>
    {`${isCustomer ? 'Customer ' : ''}${failureCode}: ${failureDescription}.`}
    <br />
    <br />
    {`If you have any questions please `}
    <ContactUs useChatBubble={false}>{CONTACT_SUPPORT_LOWERCASE_LABEL}</ContactUs>. If necessary, we will reach out to
    your team.
  </TooltipContentText>;
};

BalanceTransactionStatusTooltipContent.propTypes = {
  failureCode: PropTypes.string.isRequired,
  failureDescription: PropTypes.string.isRequired,
  isCustomer: PropTypes.bool,
};

export default BalanceTransactionStatusTooltipContent;

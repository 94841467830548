import { createIsFetchingReducer } from 'store/redux';

import {
  GET_CURRENT_COMPANY_FAILURE,
  GET_CURRENT_COMPANY_REQUEST,
  GET_CURRENT_COMPANY_SUCCESS,
} from 'types/currentCompany';

export const finishCases = [GET_CURRENT_COMPANY_FAILURE, GET_CURRENT_COMPANY_SUCCESS];
export const requestCases = [GET_CURRENT_COMPANY_REQUEST];

const fetchReducer = createIsFetchingReducer(finishCases, requestCases);

export default fetchReducer;

import React from 'react';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';

import {
  ReduxFormFieldRenderNumericInput,
  ReduxFormFieldRenderInput,
  ReduxFormFieldRenderDateInput,
  InternationalPhoneInput,
  Checkbox,
  SelectFieldV2,
} from 'components';
import CannotUpdateFieldHint from 'components/hintTypes/CannotUpdateFieldHint';

import { FEATURE_FLAG_CROSSBORDER_MULTIPLE_PROVIDERS } from 'constants/featureFlags';
import { NumericSuffix } from 'constants/numeric';

import { formatFieldDate } from 'helpers/fieldFormatters';
import { normalizeDate, numberOnly } from 'helpers/fieldNormalizers';
import {
  dateValidator,
  maxAgeValidator,
  minAgeValidator,
  requiredValidator,
  phoneNumberLengthValidator,
  phoneNumberRequiredValidator,
  internationalPhoneNumberValidator,
  ownershipPercentageValidator,
} from 'helpers/fieldValidation';

import { useFeatureFlag, useCountries } from 'hooks';

import Accordion from 'modules/signup-v3/components/Accordion';
import { containerWidthMap } from 'modules/signup-v3/constants/container';

import { currentCompanySelector } from 'selectors/currentCompanySelectors';
import {
  isUpdatingMembershipSelector,
  currentMembershipVerificationUpdateAllowedSelector,
} from 'selectors/membershipsSelector';

import { signupFlowForm } from '../constants';
import { howRoutableUseThisInformation } from '../constants/accordion';
import { mapViewToFields } from '../fields';
import FlowStepperMain from '../FlowStepperMain';
import { viewSteps } from '../helpers/viewSteps';

import { FieldWrapper, FieldsContainer } from './PersonalInformation.styled';

const minAge18Validator = minAgeValidator(18);
const maxAge120Validator = maxAgeValidator(120);
const formatDate = formatFieldDate();
const formValueSelector = getFormValues(signupFlowForm);

const getFields = (currentCompanyName) => ({
  title: {
    component: ReduxFormFieldRenderInput,
    dataTestId: 'companyTitle',
    isRequired: true,
    placeholder: 'Your title in the company',
    type: 'text',
    validate: [requiredValidator],
  },
  isCompanyBeneficiary: {
    component: Checkbox,
    dataTestId: 'companyBeneficiaryCheckbox',
    type: 'checkbox',
    content: `I am a beneficial owner of ${currentCompanyName}`,
    tooltip: 'An individual who owns (directly or indirectly) 25% or more of the equity interests of the company.',
    flex: true,
  },
  legalFirstName: {
    component: ReduxFormFieldRenderInput,
    dataTestId: 'firstName',
    isRequired: true,
    placeholder: 'First name',
    type: 'text',
    validate: [requiredValidator],
  },
  legalLastName: {
    component: ReduxFormFieldRenderInput,
    dataTestId: 'lastName',
    isRequired: true,
    placeholder: 'Last name',
    type: 'text',
    validate: [requiredValidator],
  },
  dateOfBirth: {
    component: ReduxFormFieldRenderDateInput,
    dataTestId: 'dateOfBirth',
    isRequired: true,
    placeholder: 'Date of birth (mm/dd/yyyy)',
    type: 'text',
    validate: [maxAge120Validator, minAge18Validator, dateValidator, requiredValidator],
    normalize: normalizeDate,
    format: formatDate,
  },
  phoneNumber: {
    component: InternationalPhoneInput,
    dataTestId: 'phoneNumber',
    isRequired: true,
    placeholder: 'Phone number',
    type: 'text',
    validate: [phoneNumberRequiredValidator, phoneNumberLengthValidator, internationalPhoneNumberValidator],
  },
  birthCountry: {
    component: SelectFieldV2,
    dataTestId: 'birthCountry',
    isRequired: true,
    label: 'Country of birth',
    validate: [requiredValidator],
  },
  ownershipPercentage: {
    component: ReduxFormFieldRenderNumericInput,
    dataTestId: 'ownershipPercentage',
    decimalScale: 0,
    isRequired: true,
    label: 'Percent of ownership',
    normalize: numberOnly,
    placeholder: '0',
    suffix: NumericSuffix.PERCENT,
    thousandSeparator: false,
    type: 'text',
    validate: [requiredValidator, ownershipPercentageValidator],
  },
});

export const PersonalInformationMain = ({ activeView, invalid }) => {
  const currentCompany = useSelector(currentCompanySelector);
  const updatedAllowed = useSelector(currentMembershipVerificationUpdateAllowedSelector);

  const crossborderMultipleProvidersEnabled = useFeatureFlag(FEATURE_FLAG_CROSSBORDER_MULTIPLE_PROVIDERS);
  const { countriesAsOptions } = useCountries();
  const formValues = useSelector(formValueSelector);
  const { isCompanyBeneficiary } = formValues?.personalInfo || {};

  const isUpdating = useSelector(isUpdatingMembershipSelector);
  const fields = getFields(currentCompany.name);

  const fieldsActiveView = mapViewToFields[activeView].reduce(
    (prev, currentField) => ({
      ...prev,
      [currentField.id]: {
        ...currentField,
      },
    }),
    {},
  );

  return (
    <FlowStepperMain
      containerWidth={containerWidthMap.small}
      fields={
        <>
          <FieldsContainer>
            {!updatedAllowed && <CannotUpdateFieldHint />}
            <FieldWrapper>
              <Field
                isDisabled={!updatedAllowed}
                isLocked={!updatedAllowed}
                name={fieldsActiveView.title.name}
                {...fields.title}
              />
            </FieldWrapper>

            {!crossborderMultipleProvidersEnabled && (
              <FieldWrapper flex>
                <Field
                  isDisabled={!updatedAllowed}
                  isLocked={!updatedAllowed}
                  name={fieldsActiveView.isCompanyBeneficiary.name}
                  {...fields.isCompanyBeneficiary}
                />
              </FieldWrapper>
            )}

            <FieldWrapper flex>
              <FieldWrapper grow>
                <Field
                  dataFullStory
                  isDisabled={!updatedAllowed}
                  isLocked={!updatedAllowed}
                  name={fieldsActiveView.legalFirstName.name}
                  {...fields.legalFirstName}
                />
              </FieldWrapper>
              <FieldWrapper grow>
                <Field
                  dataFullStory
                  isDisabled={!updatedAllowed}
                  isLocked={!updatedAllowed}
                  name={fieldsActiveView.legalLastName.name}
                  {...fields.legalLastName}
                />
              </FieldWrapper>
            </FieldWrapper>
            <FieldWrapper flex>
              <FieldWrapper grow>
                <Field
                  dataFullStory
                  isDisabled={!updatedAllowed}
                  isLocked={!updatedAllowed}
                  name={fieldsActiveView.phoneNumber.name}
                  {...fields.phoneNumber}
                />
              </FieldWrapper>
              <FieldWrapper grow>
                <Field
                  isDisabled={!updatedAllowed}
                  isLocked={!updatedAllowed}
                  name={fieldsActiveView.dateOfBirth.name}
                  {...fields.dateOfBirth}
                />
              </FieldWrapper>
            </FieldWrapper>
            {crossborderMultipleProvidersEnabled && (
              <>
                <FieldWrapper>
                  <Field
                    name={fieldsActiveView.birthCountry.name}
                    options={countriesAsOptions}
                    {...fields.birthCountry}
                  />
                </FieldWrapper>
                <FieldWrapper flex>
                  <FieldWrapper grow>
                    <Field
                      isDisabled={!updatedAllowed}
                      isLocked={!updatedAllowed}
                      name={fieldsActiveView.isCompanyBeneficiary.name}
                      {...fields.isCompanyBeneficiary}
                      className="w-full border border-grey-20 px-5 py-3 rounded-md [&_.checkbox--label]:!items-center [&_.checkbox--label]:gap-2"
                      content={
                        <div className="flex flex-col">
                          <span className="text-grey-70 font-medium">I am a beneficial owner</span>
                          <span>An individual with 25% or more ownership</span>
                        </div>
                      }
                      tooltip={null}
                    />
                  </FieldWrapper>
                  {isCompanyBeneficiary && (
                    <FieldWrapper maxWidth="160px">
                      <Field name={fieldsActiveView.ownershipPercentage.name} {...fields.ownershipPercentage} />
                    </FieldWrapper>
                  )}
                </FieldWrapper>
              </>
            )}
          </FieldsContainer>
          <Accordion {...howRoutableUseThisInformation} containerWidth={containerWidthMap.small} />
        </>
      }
      invalid={invalid || !updatedAllowed}
      onBack={viewSteps[activeView].previous}
      onNext={{
        ...viewSteps[activeView].next,
        isLoading: isUpdating,
      }}
      onSkip={updatedAllowed && viewSteps[activeView].skip}
      subTitle={{
        content:
          "Routable is required to collect this information in order to combat financial crime. Don't worry — only you will be able to view your information.",
      }}
      title={{ content: 'Tell us a bit more about yourself' }}
    />
  );
};

export default PersonalInformationMain;

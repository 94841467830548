import {
  FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_SUCCESS,
  SUBMIT_UNMATCHED_PLATFORM_FUNDING_ACCOUNT_SUCCESS,
} from 'types/integrations';

const unmatchedCounterReducer = (state = 0, action) => {
  switch (action.type) {
    case FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_SUCCESS:
      // Only update the pagination count for 'unmatched' api calls
      if (action.payload.links.current.includes('true')) {
        return state;
      }

      return action.payload.meta.pagination.count;

    case SUBMIT_UNMATCHED_PLATFORM_FUNDING_ACCOUNT_SUCCESS:
      return state - 1;

    default:
      return state;
  }
};

export default unmatchedCounterReducer;

import { deleteInboxItemRoutine } from 'actions/routines/item/delete';

import { createIsDoingReducer } from 'store/redux';

export const finishCases = [deleteInboxItemRoutine.FAILURE, deleteInboxItemRoutine.SUCCESS];
export const requestCases = [deleteInboxItemRoutine.REQUEST];

const isDeletingReducer = createIsDoingReducer(finishCases, requestCases);

export default isDeletingReducer;

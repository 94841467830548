import { SIGNUP_CONNECT_BANK, SIGNUP_CREATE_NAMESPACE, SIGNUP_VERIFY_EMAIL } from 'constants/routes';
export const getCurrentStepPositionFromPath = (path) => {
    switch (path) {
        case SIGNUP_VERIFY_EMAIL:
            return 1;
        case SIGNUP_CREATE_NAMESPACE:
            return 2;
        case SIGNUP_CONNECT_BANK:
            return 3;
        default:
            return 0;
    }
};

import {} from 'axios';
import {} from 'actions/routines/createApiRoutine';
import { parseFeatureSettings } from 'data/parse';
import { FetchService } from 'services';
import { featureSettingsEndpoint } from 'services/api/configEndpoints';
export const getFetchFeatureSettingsApiConfig = () => {
    const responseTransformers = [
        parseFeatureSettings.transform.attachIdToFeatureResponseObjects,
        ...FetchService.getTransformers({}),
        parseFeatureSettings.transform.settingsResponseToEnabledKeys,
    ];
    return {
        endpoint: featureSettingsEndpoint,
        method: 'GET',
        requireAuth: true,
        transformers: responseTransformers,
        headers: {},
    };
};

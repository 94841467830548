import {} from 'redux';
import { SubmissionError } from 'redux-form';
import { companySettingsSSOByNamespaceFetchHandler } from 'actions/routines/sso';
import {} from '../NamespaceForm.types';
const submit = async (payload, dispatch) => {
    try {
        await companySettingsSSOByNamespaceFetchHandler({ params: { namespace: payload.namespace } }, dispatch);
    }
    catch (e) {
        if (e?.errors?.detail) {
            const namespaceFormError = {
                namespace: [e.errors.detail],
            };
            throw new SubmissionError(namespaceFormError);
        }
    }
};
export default submit;

import PropTypes from 'prop-types';
import React from 'react';

import BrandFooter from 'components/footer/BrandFooter';

import { ERROR, EXTERNAL, SUCCESS, TAX } from 'constants/routes';

import { getJoinedPath } from 'helpers/routeHelpers';

import AllowAny from 'modules/app/AllowAny';
import SwitchOr404 from 'modules/app/SwitchOr404';
import ExternalHeader from 'modules/external/global/components/header/ExternalHeader';
import External from 'modules/external/global/presenters/External';
import ExternalFooter from 'modules/external/global/presenters/ExternalFooter';
import { IsLoadingHasWrapper } from 'modules/isLoading/IsLoading';

import { CollectTaxFailurePage, CollectTaxInfoPage, CollectTaxSuccessPage } from './components';

import './ExternalCollectTaxInfo.scss';

const ExternalCollectTaxInfo = ({ isFetchingRequirements, partnership }) => {
  if (isFetchingRequirements || !partnership) {
    return <IsLoadingHasWrapper />;
  }

  return (
    <External>
      <ExternalHeader />

      <SwitchOr404>
        <AllowAny component={CollectTaxInfoPage} exact path={getJoinedPath(EXTERNAL, TAX)} />
        <AllowAny component={CollectTaxFailurePage} exact path={getJoinedPath(EXTERNAL, TAX, ERROR)} />
        <AllowAny component={CollectTaxSuccessPage} exact path={getJoinedPath(EXTERNAL, TAX, SUCCESS)} />
      </SwitchOr404>

      <ExternalFooter>
        <BrandFooter hideSupport showSecure />
      </ExternalFooter>
    </External>
  );
};

ExternalCollectTaxInfo.propTypes = {
  isFetchingRequirements: PropTypes.bool,
  partnership: PropTypes.shape(),
};

ExternalCollectTaxInfo.defaultProps = {
  isFetchingRequirements: false,
  partnership: undefined,
};

export default ExternalCollectTaxInfo;

import { PURPOSE_CODE } from 'constants/routes';
import { getQueryString } from 'helpers/queryParams';
import {} from 'interfaces/purposeCode';
import { BASE_API_URL } from './index';
export const purposeCodeEndpoint = `${BASE_API_URL}/data/item/${PURPOSE_CODE}`;
export const getPurposeCodeQueryString = ({ companyType, countryCode }) => getQueryString({
    country_code: countryCode,
    entity_type: companyType,
});
export const getPurposeCodeEndpoint = ({ companyType, countryCode }) => {
    const queryString = getPurposeCodeQueryString({
        countryCode,
        companyType,
    });
    return `${purposeCodeEndpoint}/?${queryString}`;
};

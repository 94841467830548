import React from 'react';

/**
 * When the window resizes or the DOM mutates, track and report on the size of the target element. Useful for changing
 * UI based on screen size changes when CSS media queries won't cut it.
 * @function
 * @hook
 * @param {string} elementId
 * @returns {number[]} [width, height] in pixels
 */
const useOnElementResize = (elementId) => {
  const [size, setSize] = React.useState([0, 0]);

  React.useEffect(() => {
    const updateSize = () => {
      const el = document.getElementById(elementId);
      if (el) {
        setSize([el.clientWidth, el.clientHeight]);
      }
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [elementId]);

  return size;
};

export default useOnElementResize;

import React from 'react';
import { useDispatch } from 'react-redux';
import { SwiftChargeOptions } from 'enums/paymentMethods';
import { isPartnerOnlyElegibleForInternationalSwift } from 'helpers/partnerships';
import { isPaymentDeliveryOptionInternationalSwift } from 'helpers/paymentDeliveryOption';
export const paymentShouldIncludeSwiftFee = (item, partnerPaymentOptions) => isPartnerOnlyElegibleForInternationalSwift(partnerPaymentOptions) ||
    isPaymentDeliveryOptionInternationalSwift(item?.paymentDeliveryOption);
const useShouldShowSwiftFeeChargeToggle = ({ fieldName, item, onChange, partnerPaymentOptions, }) => {
    const dispatch = useDispatch();
    const shouldShowSwiftFeeChargeToggle = React.useMemo(() => paymentShouldIncludeSwiftFee(item, partnerPaymentOptions), [item, partnerPaymentOptions]);
    React.useEffect(() => {
        if (shouldShowSwiftFeeChargeToggle) {
            dispatch(onChange(fieldName, item.swiftChargeOption ?? SwiftChargeOptions.OUR));
        }
        else {
            dispatch(onChange(fieldName, undefined));
        }
    }, [dispatch, fieldName, item.swiftChargeOption, onChange, shouldShowSwiftFeeChargeToggle]);
    return shouldShowSwiftFeeChargeToggle;
};
export default useShouldShowSwiftFeeChargeToggle;

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { BoundaryCheckbox } from 'components/checkbox';

import * as propTypes from 'constants/propTypes';

import { useGetBlockFieldProps } from 'modules/table/hooks';

/**
 * Renders a checkbox field for boolean values to be rendered in a block section
 * @param {ComponentProps} props
 * @param {Object} props.formValues
 * @param {string} props.label
 * @param {string} props.name
 * @param {Function} props.validate
 * @returns {StatelessComponent}
 */
const CheckboxBlockField = ({ formValues, getIsDisabled, getIsRequired, label, name, validate }) => {
  const { isDisabled, isRequired, validators } = useGetBlockFieldProps({
    formValues,
    getIsDisabled,
    getIsRequired,
    validate,
  });

  return (
    <Field
      component={BoundaryCheckbox}
      content={label}
      isDebounced
      isDisabled={isDisabled}
      isRequired={isRequired}
      name={name}
      validate={validators}
    />
  );
};

CheckboxBlockField.propTypes = {
  ...propTypes.baseFieldSharedPropTypes,
  label: PropTypes.node.isRequired,
};

CheckboxBlockField.defaultProps = {
  ...propTypes.baseFieldSharedDefaultProps,
};

export default React.memo(CheckboxBlockField);

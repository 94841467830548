import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isTaxInfoStatusCompleted } from '@routable/taxes';
import { TaxInfoDataKey } from 'constants/taxes';
import {} from 'interfaces/taxes';
import { usePartnershipForIdQuery, useW8BenDetailsQuery, useW8BenEDetailsQuery, useW8BenETaxesQuery, useW8BenTaxesQuery, useW9DetailsQuery, useW9TaxesQuery, } from './partnership.endpoints';
export const useTaxesQuery = (partnershipId, taxInfoDataKey) => {
    const isW8Ben = taxInfoDataKey === TaxInfoDataKey.W8BEN;
    const isW8Bene = taxInfoDataKey === TaxInfoDataKey.W8BENE;
    const isW9 = taxInfoDataKey === TaxInfoDataKey.W9;
    const payload = { partnershipId };
    const taxesQueryMap = {
        [TaxInfoDataKey.W8BEN]: useW8BenTaxesQuery(isW8Ben ? payload : skipToken),
        [TaxInfoDataKey.W8BENE]: useW8BenETaxesQuery(isW8Bene ? payload : skipToken),
        [TaxInfoDataKey.W9]: useW9TaxesQuery(isW9 ? payload : skipToken),
    };
    return taxesQueryMap[taxInfoDataKey];
};
export const useCompletedTaxDocDetails = (partnershipId, dataKey) => {
    const taxesQuery = useTaxesQuery(partnershipId, dataKey);
    const taxInfoRecords = Object.values(taxesQuery.data?.[dataKey] || {});
    const completedDocId = taxInfoRecords.find((record) => isTaxInfoStatusCompleted(record.attributes.status))?.id;
    const payload = { partnershipId, docId: completedDocId };
    const fetchW8Ben = dataKey === TaxInfoDataKey.W8BEN && completedDocId;
    const fetchW8Bene = dataKey === TaxInfoDataKey.W8BENE && completedDocId;
    const fetchW9 = dataKey === TaxInfoDataKey.W9 && completedDocId;
    const taxDetailsQueryMap = {
        [TaxInfoDataKey.W8BEN]: useW8BenDetailsQuery(fetchW8Ben ? payload : skipToken),
        [TaxInfoDataKey.W8BENE]: useW8BenEDetailsQuery(fetchW8Bene ? payload : skipToken),
        [TaxInfoDataKey.W9]: useW9DetailsQuery(fetchW9 ? payload : skipToken),
    };
    const taxDetailsQuery = taxDetailsQueryMap[dataKey];
    const taxDetailsMapById = taxDetailsQuery.data?.[dataKey] || {};
    const taxDocDetailsEntity = taxDetailsMapById[completedDocId];
    const taxDocDetails = taxDocDetailsEntity?.attributes;
    const isLoading = completedDocId ? taxDetailsQuery.isLoading : taxesQuery.isLoading;
    return { isLoading, taxDocDetails };
};
export const getPartnerDataFromPartnershipApiResponse = (data, partnershipId) => {
    if (!data || !data.company || !data.partnership || !partnershipId) {
        return null;
    }
    const { relationships } = data.partnership[partnershipId];
    const { id: partnerId } = relationships.partner.data;
    return data.company[partnerId]?.attributes || null;
};
export const usePartnershipAndPartnerCompanyFromPartnershipIdQuery = (partnershipId) => usePartnershipForIdQuery(partnershipId || skipToken, {
    selectFromResult: ({ data, originalArgs, ...rest }) => ({
        partner: getPartnerDataFromPartnershipApiResponse(data, partnershipId),
        partnership: data?.partnership?.[originalArgs]?.attributes,
        ...rest,
    }),
});

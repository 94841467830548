import PropTypes from 'prop-types';
import React from 'react';

import Checkbox from 'components/checkbox';

const AcceptWithMethod = ({ children, isAvailable, isDomesticVendorFormat, ...rest }) => {
  if (!isAvailable) {
    return null;
  }

  // Based on crossborderCreateItemEnabled feature flag and domestic payable, build main text format
  const mainFormat = isDomesticVendorFormat ? 'Allow vendor to accept payment via' : 'Allow vendor to accept with:';

  return (
    <Checkbox
      className="margin-bottom--m"
      content={
        <span>
          {mainFormat} {children}
        </span>
      }
      {...rest}
    />
  );
};

AcceptWithMethod.propTypes = {
  children: PropTypes.node,
  input: PropTypes.shape().isRequired,
  isAvailable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isDomesticVendorFormat: PropTypes.bool,
};

AcceptWithMethod.defaultProps = {
  children: undefined,
  isAvailable: undefined,
  isDisabled: undefined,
  isDomesticVendorFormat: undefined,
};

export default AcceptWithMethod;

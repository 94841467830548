import PropTypes from 'prop-types';
import React from 'react';

import { MuiTooltipPoly } from 'components/MuiTooltipPoly';
// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { Text } from 'components/text';

import { TextColor, TextSize } from 'constants/styles/typography';

import { Position } from 'enums/ui';

import { isString } from 'helpers/utility';

/** @deprecated use @routable/components's Tooltip instead */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Tooltip = ({ children, className, content, contentMargin, contentSize, ...rest }) => {
  let tooltipContent = null;
  if (isString(content)) {
    tooltipContent = (
      <Text.Bold color={TextColor.WHITE} size={contentSize || TextSize.LEVEL_400}>
        {content}
      </Text.Bold>
    );
  } else {
    tooltipContent = content;
  }

  if (contentMargin) {
    tooltipContent = <p className="text-grey-70 leading-5">{tooltipContent}</p>;
  }

  return (
    <MuiTooltipPoly {...rest} className={className} placement={rest.position} text={tooltipContent}>
      {children}
    </MuiTooltipPoly>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  contentMargin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  contentSize: PropTypes.number,
  position: PropTypes.string,
};

Tooltip.defaultProps = {
  children: undefined,
  className: undefined,
  contentMargin: undefined,
  contentSize: undefined,
  position: Position.TOP,
};

export default Tooltip;

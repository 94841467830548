import PropTypes from 'prop-types';
import React from 'react';

import { ActionNavTab } from './components';

/**
 * A list of clickable tabs, part of the ActionNav, used to navigate users to another page or filter.
 * @param {ComponentProps} props
 * @param {Array} props.tabListItems
 * @returns {StatelessComponent}
 */
const ActionNavTabList = ({ tabListItems, onTabChange }) => (
  <ul className="action-nav-tabs--list" role="menubar">
    {tabListItems.map(({ tabFilter, ...listItemProps }) => (
      <ActionNavTab
        {...listItemProps}
        key={listItemProps.key}
        onClick={() => onTabChange?.({ tabFilter, ...listItemProps })}
      />
    ))}
  </ul>
);

ActionNavTabList.propTypes = {
  tabListItems: PropTypes.arrayOf(
    PropTypes.shape({
      alternatePaths: PropTypes.arrayOf(PropTypes.string),
      key: PropTypes.number.isRequired,
      numberOfItems: PropTypes.number,
      tabLink: PropTypes.string.isRequired,
      tabText: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onTabChange: PropTypes.func,
};

export default ActionNavTabList;

import { FetchService } from 'services';
import { apiTokenEndpoint, deleteApiTokenEndpoint, regenerateApiTokenEndpoint } from 'services/api/apiTokenEndpoints';

/**
 * Create an API token.
 * @param {string} companyId
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const createToken = async (companyId, options = {}) => {
  const endpoint = apiTokenEndpoint(companyId);
  const config = {
    ...options,
    endpoint,
    method: 'POST',
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * Delete an API token.
 * @param {string} companyId
 * @param {string} tokenId
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const deleteToken = async (companyId, tokenId, options = {}) => {
  const endpoint = deleteApiTokenEndpoint(companyId, tokenId);
  const config = {
    ...options,
    endpoint,
    method: 'DELETE',
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * Regenerate an API token.
 * @param {string} companyId
 * @param {string} tokenId
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const regenerateToken = async (companyId, tokenId, options = {}) => {
  const endpoint = regenerateApiTokenEndpoint(companyId, tokenId);
  const config = {
    ...options,
    endpoint,
    method: 'POST',
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * Fetch API tokens.
 * @param {string} companyId
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchTokens = async (companyId, options = {}) => {
  const endpoint = apiTokenEndpoint(companyId);
  const config = {
    ...options,
    endpoint,
    method: 'GET',
    requireAuth: true,
  };
  return FetchService.request(config);
};

import React from 'react';
import { FormControl, Text } from 'components';
import { commonFormFields } from 'constants/formFields';
import { isCompanyTypeBusiness } from 'helpers/currentCompany';
import { requiredValidator } from 'helpers/fieldValidation';
import { getVendorOrCustomerTitleFromItemKind } from 'helpers/partnerships';
import './PartnershipNameSettingsForm.scss';
import PartnershipNameSettingsFormField from './PartnershipNameSettingsFormField';
const PartnershipNameSettingsForm = ({ companyType, displayName, isLedgerIntegrationConnected, itemKind, ledgerName, partnerLedgerName, partnerName, partnershipType, updatePartnerField, }) => {
    const isCompanyBusiness = isCompanyTypeBusiness(companyType);
    const onResetFieldValue = React.useCallback((fieldName) => {
        updatePartnerField(fieldName, displayName);
    }, [displayName, updatePartnerField]);
    const resetFields = React.useCallback(() => {
        if (isCompanyBusiness) {
            onResetFieldValue(commonFormFields.PARTNER_NAME);
        }
        if (isLedgerIntegrationConnected) {
            onResetFieldValue(commonFormFields.PARTNER_LEDGER_NAME);
        }
    }, [isCompanyBusiness, isLedgerIntegrationConnected, onResetFieldValue]);
    const [isMounted, setIsMounted] = React.useState(false);
    React.useEffect(() => {
        if (isMounted) {
            resetFields();
        }
        else {
            setIsMounted(true);
        }
        return resetFields;
    }, [displayName, resetFields]);
    const partnershipTypeTitle = partnershipType || getVendorOrCustomerTitleFromItemKind(itemKind);
    return (React.createElement(FormControl, { className: "flex-direction--column legal-name--width" },
        React.createElement(Text.Regular, null, "We use the display name as your legal name by default. You can update it below."),
        isCompanyBusiness && (React.createElement(PartnershipNameSettingsFormField, { isInitialValueLocked: partnerName === displayName, label: "Legal entity name", name: commonFormFields.PARTNER_NAME, validate: requiredValidator, value: partnerName })),
        isLedgerIntegrationConnected && (React.createElement(PartnershipNameSettingsFormField, { isInitialValueLocked: partnerLedgerName === displayName, label: `${ledgerName} ${partnershipTypeTitle} profile`, name: commonFormFields.PARTNER_LEDGER_NAME, validate: requiredValidator, value: partnerLedgerName }))));
};
export default PartnershipNameSettingsForm;

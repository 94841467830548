import React from 'react';
import { LinkNewPage } from 'components/link';
import { LabelLink } from 'components/link/LabelLink/LabelLink.styles';
import { PartnershipMemberAccess } from 'enums/partnerships';
import { getCompanyRouteByPartnershipId } from 'helpers/routeHelpers';
import { getOnCreateContactOptions } from 'modules/contacts/components/GroupedContactSelectsInForm/logic';
const CreateEditPaymentHintTextBusinessComponent = ({ isInviting = false, onOpenContactSidePanel, selectedCompany, }) => {
    const createContactOptions = getOnCreateContactOptions('', selectedCompany, PartnershipMemberAccess.ACTIONABLE);
    const linkToPartnerCompanyPage = getCompanyRouteByPartnershipId(selectedCompany?.id);
    return (React.createElement("span", { className: "contact-required-to-select-payment-hint" },
        `To allow a vendor to ${isInviting ? 'add' : 'select'} their payment method, please include a contact above,`,
        React.createElement("br", null),
        React.createElement(LabelLink, { onClick: () => onOpenContactSidePanel(createContactOptions) }, "add a new contact"),
        `, or `,
        React.createElement(LinkNewPage, { href: linkToPartnerCompanyPage }, "edit existing contacts \u2197"),
        "."));
};
export default CreateEditPaymentHintTextBusinessComponent;

import { OCR_STATUS_ITEM_CHANGE } from 'types/item';

const annotationStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case OCR_STATUS_ITEM_CHANGE: {
      return state;
    }
    default:
      return state;
  }
};

export default annotationStatusReducer;

import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components';

import { ButtonSize } from 'constants/button';
import { Intent } from 'constants/ui';

const PartnerPaymentMethodAccounts = (props) => {
  const { children, hasAccounts, isFetching, onShowAll, shouldDisplayShowAll } = props;

  if (!hasAccounts || isFetching) {
    return null;
  }

  return (
    <div className="partner-payment-method--accounts">
      {children}

      {shouldDisplayShowAll && (
        <div className="partner-payment-method--actions">
          <ButtonV2 className="right" intent={Intent.NEUTRAL} onClick={onShowAll} size={ButtonSize.SMALL} type="button">
            Show All
          </ButtonV2>
        </div>
      )}
    </div>
  );
};

PartnerPaymentMethodAccounts.propTypes = {
  children: PropTypes.node,
  hasAccounts: PropTypes.bool,
  isFetching: PropTypes.bool,
  onShowAll: PropTypes.func,
  shouldDisplayShowAll: PropTypes.bool,
};

PartnerPaymentMethodAccounts.defaultProps = {
  children: undefined,
  hasAccounts: undefined,
  isFetching: undefined,
  onShowAll: undefined,
  shouldDisplayShowAll: undefined,
};

export default PartnerPaymentMethodAccounts;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { IFrame } from 'components/iframe';

/**
 * Renders an iframe element for document display.
 * @param {ComponentProps} props
 * @param {StringMaybe} props.iFrameClassName
 * @param {string} props.objectUrl
 * @param {StringMaybe} props.title
 * @return {StatelessComponent}
 * @constructor
 */
const DocumentViewer = (props) => {
  const { iFrameClassName, objectUrl, title, ...rest } = props;

  return (
    <IFrame
      {...rest}
      className={classNames({
        viewer: true,
        [iFrameClassName]: !!iFrameClassName,
      })}
      src={objectUrl}
      title={title}
    />
  );
};

DocumentViewer.propTypes = {
  iFrameClassName: PropTypes.string,
  objectUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
};

DocumentViewer.defaultProps = {
  iFrameClassName: undefined,
  title: undefined,
};

export default DocumentViewer;

import dayjs from 'dayjs';
import _omitBy from 'lodash/omitBy';
import { z } from 'zod';
import { baseId, createMultiOptionFilterModel, createdDateFilterModel, dateModel, offsetPaginationModel, operatorModel, } from '@routable/shared';
import { baseTaxVendorDataModel } from './vendor.base.model';
import { countryGroup, entityType, taxBusinessType, taxEmailContactStatus, taxInfoStatusFilter, taxRequestStatus, taxRiskSummary, } from '../status';
export const tax1099kStatusModel = z.enum(['qualified']);
export const tax1099kStatusFilterModel = z.object({
    status: z.array(tax1099kStatusModel),
    year: z.string(),
});
export const singleValueFilterModel = z.object({
    in: z.string(),
    value: z.string(),
});
export const multiValueFilterModel = z.object({
    in: z.string(),
    value: z.tuple([z.string(), z.string()]),
});
export const totalPaidInYearFilterModel = z.discriminatedUnion('operator', [
    multiValueFilterModel.extend({
        operator: z.literal(operatorModel.Enum.BETWEEN),
    }),
    singleValueFilterModel.extend({
        operator: z.literal(operatorModel.Enum.GTE),
    }),
    singleValueFilterModel.extend({
        operator: z.literal(operatorModel.Enum.LTE),
    }),
    singleValueFilterModel.extend({ operator: z.literal(operatorModel.Enum.EQ) }),
]);
export const taxToolsFiltersBaseModel = z.object({
    businessType: createMultiOptionFilterModel(taxBusinessType),
    countryCode: z.string().nullish(),
    countryGroup: createMultiOptionFilterModel(countryGroup),
    createdDate: createdDateFilterModel.nullish(),
    entityType: createMultiOptionFilterModel(entityType),
    lastTaxRequestStatus: createMultiOptionFilterModel(taxRequestStatus),
    search: z.string().nullish(),
    tax1099kStatus: tax1099kStatusFilterModel.nullish(),
    taxEmailContactStatus: createMultiOptionFilterModel(taxEmailContactStatus),
    taxInfoStatus: createMultiOptionFilterModel(taxInfoStatusFilter),
    totalPaidInYear: totalPaidInYearFilterModel.nullish(),
    riskSummary: createMultiOptionFilterModel(taxRiskSummary),
});
export const taxToolsFiltersModel = taxToolsFiltersBaseModel
    .transform(({ totalPaidInYear, ...rest }) => {
    if (!totalPaidInYear) {
        return rest;
    }
    const { in: year, operator, value } = totalPaidInYear;
    if (operator === operatorModel.Enum.BETWEEN) {
        const [gte, lte] = value;
        return {
            ...rest,
            totalPaidYear: year,
            totalPaidAmount__lte: lte.replaceAll(',', ''),
            totalPaidAmount__gte: gte.replaceAll(',', ''),
        };
    }
    const amountFilterName = operator === operatorModel.Enum.EQ ? 'totalPaidAmount' : `totalPaidAmount__${operator.toLowerCase()}`;
    return {
        [amountFilterName]: value.replaceAll(',', ''),
        totalPaidYear: year,
        ...rest,
    };
})
    .transform(({ createdDate, ...filters }) => {
    if (!createdDate) {
        return filters;
    }
    const CreatedAtParamMap = {
        [operatorModel.Enum.EQ]: 'createdAt',
        [operatorModel.Enum.LTE]: 'createdAt__lte',
        [operatorModel.Enum.GTE]: 'createdAt__gte',
    };
    const format = 'YYYY-MM-DD';
    if (createdDate.operator === operatorModel.Enum.BETWEEN) {
        const [gteDate, lteDate] = createdDate.value;
        return {
            ...filters,
            [CreatedAtParamMap.LTE]: dayjs(lteDate).format(format),
            [CreatedAtParamMap.GTE]: dayjs(gteDate).format(format),
        };
    }
    const createdAtParamName = CreatedAtParamMap[createdDate.operator];
    const dateValue = dayjs(createdDate.value).format(format);
    return {
        [createdAtParamName]: dateValue,
        ...filters,
    };
})
    .transform(({ tax1099kStatus, ...filters }) => {
    if (!tax1099kStatus) {
        return filters;
    }
    return {
        ...filters,
        qualified1099kYear: tax1099kStatus.year,
    };
});
export const taxToolsQueryParamsFiltersModel = taxToolsFiltersBaseModel
    .extend({
    businessType: z.string().nullish(),
    countryGroup: z.string().nullish(),
    entityType: z.string().nullish(),
    lastTaxRequestStatus: z.string().nullish(),
    taxEmailContactStatus: z.string().nullish(),
    taxInfoStatus: z.string().nullish(),
    totalPaidAmount: z.string().nullish(),
    totalPaidAmount__gte: z.string().nullish(),
    totalPaidAmount__lte: z.string().nullish(),
    totalPaidYear: z.string().nullish(),
    createdAt: dateModel.nullish(),
    createdAt__gte: dateModel.nullish(),
    createdAt__lte: dateModel.nullish(),
    qualified1099kYear: z.string().nullish(),
    riskSummary: z.string().nullish(),
})
    .strip()
    .omit({ search: true })
    .transform(({ totalPaidAmount, totalPaidAmount__gte, totalPaidAmount__lte, totalPaidYear, ...rest }) => {
    if (!totalPaidAmount && !totalPaidAmount__gte && !totalPaidAmount__lte) {
        delete rest.totalPaidInYear;
        return rest;
    }
    if (totalPaidAmount) {
        return {
            ...rest,
            totalPaidInYear: {
                operator: operatorModel.Enum.EQ,
                value: totalPaidAmount,
                in: totalPaidYear,
            },
        };
    }
    if (totalPaidAmount__gte && !totalPaidAmount__lte) {
        return {
            ...rest,
            totalPaidInYear: {
                operator: operatorModel.Enum.GTE,
                value: totalPaidAmount__gte,
                in: totalPaidYear,
            },
        };
    }
    if (!totalPaidAmount__gte && totalPaidAmount__lte) {
        return {
            ...rest,
            totalPaidInYear: {
                operator: operatorModel.Enum.LTE,
                value: totalPaidAmount__lte,
                in: totalPaidYear,
            },
        };
    }
    return {
        ...rest,
        totalPaidInYear: {
            operator: operatorModel.Enum.BETWEEN,
            value: [totalPaidAmount__gte, totalPaidAmount__lte],
            in: totalPaidYear,
        },
    };
})
    .transform(({ createdAt, createdAt__gte, createdAt__lte, ...rest }) => {
    if (!createdAt && !createdAt__gte && !createdAt__lte) {
        delete rest.createdDate;
        return rest;
    }
    if (createdAt) {
        return {
            ...rest,
            createdDate: { operator: operatorModel.Enum.EQ, value: createdAt },
        };
    }
    if (createdAt__gte && !createdAt__lte) {
        return {
            ...rest,
            createdDate: {
                operator: operatorModel.Enum.GTE,
                value: createdAt__gte,
            },
        };
    }
    if (!createdAt__gte && createdAt__lte) {
        return {
            ...rest,
            createdDate: {
                operator: operatorModel.Enum.LTE,
                value: createdAt__lte,
            },
        };
    }
    return {
        ...rest,
        createdDate: {
            operator: operatorModel.Enum.BETWEEN,
            value: [createdAt__gte, createdAt__lte],
        },
    };
})
    .transform(({ qualified1099kYear, ...rest }) => {
    if (!qualified1099kYear) {
        return rest;
    }
    return {
        ...rest,
        tax1099kStatus: {
            status: [tax1099kStatusModel.Enum.qualified],
            year: qualified1099kYear,
        },
    };
})
    .transform((filters) => {
    const multiValueFilters = _omitBy({
        businessType: filters.businessType?.split(','),
        countryGroup: filters.countryGroup?.split(','),
        entityType: filters.entityType?.split(','),
        lastTaxRequestStatus: filters.lastTaxRequestStatus?.split(','),
        taxEmailContactStatus: filters.taxEmailContactStatus?.split(','),
        taxInfoStatus: filters.taxInfoStatus?.split(','),
        riskSummary: filters.riskSummary?.split(','),
    }, (val) => val === undefined);
    return { ...filters, ...multiValueFilters };
});
export const taxVendorModel = baseId.and(baseTaxVendorDataModel);
export const taxToolsApiListModel = z.object({
    data: taxVendorModel.array(),
    pagination: offsetPaginationModel,
});

import { queryContext } from '@routable/shared';
import { useQuery } from '@tanstack/react-query';
import { taxTools } from '../helpers';
import { taxToolsApi } from '../services';
export const useTaxToolsVendor = ({ partnershipId, enabled }) => useQuery({
    context: queryContext,
    enabled,
    queryKey: [taxTools, partnershipId],
    queryFn: async () => taxToolsApi.get(partnershipId),
});

import { submitTransactionPaymentConfirmationRoutine } from 'actions/routines/transactions';
import { firstKey } from 'helpers/utility';
import {} from 'interfaces/routableApiResponse';
import { routableApi, RoutableTags } from './routableApi';
export const buildOnSuccessPayload = (transactionId, data) => {
    const transactionPaymentConfirmationId = firstKey(data.transactionPaymentConfirmation);
    return {
        ...data,
        transaction: {
            [transactionId]: {
                id: transactionId,
                relationships: {
                    transactionPaymentConfirmation: {
                        data: {
                            id: transactionPaymentConfirmationId,
                            type: 'TransactionPaymentConfirmation',
                        },
                    },
                },
            },
        },
    };
};
const extendedTransactionApi = routableApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        submitTransactionPaymentConfirmation: builder.mutation({
            query: ({ id }) => ({
                url: `/transactions/${id}/payment_confirmation/`,
                method: 'POST',
            }),
            invalidatesTags: [RoutableTags.Transactions],
            async onCacheEntryAdded(args, api) {
                const payload = await api.cacheDataLoaded;
                api.dispatch(submitTransactionPaymentConfirmationRoutine.success(buildOnSuccessPayload(args.id, payload.data)));
            },
        }),
    }),
});
export const { useSubmitTransactionPaymentConfirmationMutation } = extendedTransactionApi;

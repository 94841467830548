import PropTypes from 'prop-types';

import { companyBusinessTypes } from 'constants/company';
import { TinTypes } from 'constants/taxes';
import { UserTypes } from 'constants/user';

/**
 * filingDetailsSelector derives fields from different parts of state to display comprehensive tax filing information
 * in MailingAddressDisclosureList and FilingDetailsDisclosureControl. These components are use to view the filing
 * details in the tax confirmation modal and on the tax confirmation success page.
 * @example
 * CollectTaxSuccessPage.propTypes = {
 *   filingDetails: filingDetailsSelectorPropTypes(),
 * };
 */
export const filingDetailsSelectorPropTypes = (overrides = {}) =>
  PropTypes.shape({
    businessType: PropTypes.oneOf(Object.values(companyBusinessTypes)),
    companyName: PropTypes.string.isRequired,
    companyType: PropTypes.oneOf(Object.values(UserTypes)).isRequired,
    ein: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    mailingAddress: PropTypes.shape().isRequired,
    partnerCompanyName: PropTypes.string,
    submissionDate: PropTypes.string,
    tinType: PropTypes.oneOf(Object.values(TinTypes)).isRequired,
    ...overrides,
  });

export const filingDetailsDefaultProps = {
  businessType: undefined,
  ein: undefined,
  partnerCompanyName: undefined,
  submissionDate: undefined,
};

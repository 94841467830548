import {} from 'interfaces/reducers';
import { getModalReducerOpenAction } from 'store/redux';
import * as types from 'types/webhooks';
import {} from './webhookTestModalReducer.types';
export const initialState = {
    open: false,
};
const webhookTestModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CLOSE_WEBHOOKS_TEST_MODAL:
            return initialState;
        case types.OPEN_WEBHOOKS_TEST_MODAL:
            return getModalReducerOpenAction(state, action);
        default:
            return state;
    }
};
export default webhookTestModalReducer;

// Manual (account + routing numbers)
export const MODAL_CONNECT_BANK_MANUAL_CLOSE = 'MODAL_CONNECT_BANK_MANUAL_CLOSE';
export const MODAL_CONNECT_BANK_MANUAL_OPEN = 'MODAL_CONNECT_BANK_MANUAL_OPEN';

export const SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_FAILURE = 'SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_FAILURE';
export const SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_REQUEST = 'SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_REQUEST';
export const SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_SUCCESS = 'SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_SUCCESS';

// Token (Plaid) modal
export const MODAL_CONNECT_BANK_TOKEN_CLOSE = 'MODAL_CONNECT_BANK_TOKEN_CLOSE';
export const MODAL_CONNECT_BANK_TOKEN_OPEN = 'MODAL_CONNECT_BANK_TOKEN_OPEN';
export const MODAL_CONNECT_BANK_TOKEN_UPDATE = 'MODAL_CONNECT_BANK_TOKEN_UPDATE';

export const SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_FAILURE = 'SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_FAILURE';
export const SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_REQUEST = 'SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_REQUEST';
export const SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_SUCCESS = 'SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_SUCCESS';

export const CREATE_FUNDING_ACCOUNT_LINK_TOKEN_FAILURE = 'CREATE_FUNDING_ACCOUNT_LINK_TOKEN_FAILURE';
export const CREATE_FUNDING_ACCOUNT_LINK_TOKEN_REQUEST = 'CREATE_FUNDING_ACCOUNT_LINK_TOKEN_REQUEST';
export const CREATE_FUNDING_ACCOUNT_LINK_TOKEN_SUCCESS = 'CREATE_FUNDING_ACCOUNT_LINK_TOKEN_SUCCESS';

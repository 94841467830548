import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { AsyncSelect } from 'components';

import * as propTypes from 'constants/propTypes';

import { returnFullOptionObjectAsValueForSelects } from 'helpers/selects';

import { useGetBlockFieldProps } from 'modules/table/hooks';

/**
 * A field allowing users to input a search value to find a set of options from the server, to be rendered in a block.
 * @param {ComponentProps} props
 * @param {object} props.formValues
 * @param {string} props.lookupOptionLabelKey
 * @param {string} props.lookupOptionValueKey
 * @param {function} props.onLoadOptions
 * @returns {StatelessComponent}
 */
const ApiLookupBlockField = ({
  defaultOptions,
  formValues,
  getIsDisabled,
  getIsRequired,
  label,
  lookupOptionLabelKey,
  lookupOptionValueKey,
  name,
  onLoadOptions,
  placeholder,
  validate,
}) => {
  const { isDisabled, isRequired, validators } = useGetBlockFieldProps({
    formValues,
    getIsDisabled,
    getIsRequired,
    validate,
  });

  return (
    <Field
      className="api-lookup-block-select"
      component={AsyncSelect}
      defaultOptions={defaultOptions}
      isClearable
      isCreatable={false}
      isDisabled={isDisabled}
      isRequired={isRequired}
      label={label}
      labelKey={lookupOptionLabelKey}
      loadOptions={onLoadOptions}
      matchProp={lookupOptionLabelKey}
      name={name}
      noResultsText="No matches found"
      onBlurResetsInput={false}
      onCloseResetsInput={false}
      onSelectResetsInput={false}
      parseValue={returnFullOptionObjectAsValueForSelects}
      placeholder={`Select ${placeholder.toLowerCase()}`}
      validate={validators}
      valueKey={lookupOptionValueKey}
    />
  );
};

ApiLookupBlockField.propTypes = {
  ...propTypes.baseFieldSharedPropTypes,
  lookupOptionLabelKey: PropTypes.string.isRequired,
  lookupOptionValueKey: PropTypes.string.isRequired,
  onLoadOptions: PropTypes.func.isRequired,
  defaultOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

ApiLookupBlockField.defaultProps = {
  ...propTypes.baseFieldSharedDefaultProps,
  defaultOptions: [],
};

export default React.memo(ApiLookupBlockField);

export const MODAL_ADD_EMAIL_FORWARD_CLOSE = 'MODAL_ADD_EMAIL_FORWARD_CLOSE';
export const MODAL_ADD_EMAIL_FORWARD_OPEN = 'MODAL_ADD_EMAIL_FORWARD_OPEN';

export const MODAL_MATCH_PLATFORM_PARTNERSHIP_CLOSE = 'MODAL_MATCH_PLATFORM_PARTNERSHIP_CLOSE';
export const MODAL_MATCH_PLATFORM_PARTNERSHIP_OPEN = 'MODAL_MATCH_PLATFORM_PARTNERSHIP_OPEN';

export const MODAL_MERGE_LEDGER_PARTNERSHIP_CLOSE = 'MODAL_MERGE_LEDGER_PARTNERSHIP_CLOSE';
export const MODAL_MERGE_LEDGER_PARTNERSHIP_OPEN = 'MODAL_MERGE_LEDGER_PARTNERSHIP_OPEN';

export const MODAL_CREATE_ITEM_CLOSE = 'MODAL_CREATE_ITEM_CLOSE';
export const MODAL_CREATE_ITEM_OPEN = 'MODAL_CREATE_ITEM_OPEN';

export const MODAL_CREATE_MANUAL_ADDRESS_OPEN = 'MODAL_CREATE_MANUAL_ADDRESS_OPEN';
export const MODAL_CREATE_MANUAL_ADDRESS_CLOSE = 'MODAL_CREATE_MANUAL_ADDRESS_CLOSE';

export const MODAL_DEACTIVATE_TEAM_MEMBER = 'MODAL_DEACTIVATE_TEAM_MEMBER';

export const MODAL_ADD_INTERNATIONAL_PAYMENT_CLOSE = 'MODAL_ADD_INTERNATIONAL_PAYMENT_CLOSE';
export const MODAL_ADD_INTERNATIONAL_PAYMENT_OPEN = 'MODAL_ADD_INTERNATIONAL_PAYMENT_OPEN';

export const MODAL_INTEGRATION_SYNC_OPTIONS_OPEN = '@modals/MODAL_INTEGRATION_SYNC_OPTIONS_OPEN';

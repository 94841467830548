import { connect } from 'react-redux';

import { AddressTypes } from 'constants/address';
import { FundingSourceProviderSubClasses } from 'constants/funding';

import { fundingAccountsAllForProviderSubClassSelector } from 'queries/fundingCompoundSelectors';

import { currentCompanyAddressSelector, currentCompanySelector } from 'selectors/currentCompanySelectors';

import ConvertAddressToFundingAccount from './ConvertAddressToFundingAccount';

const mapStateToProps = (state) => ({
  companyMailingAddress: currentCompanyAddressSelector(state, AddressTypes.MAILING_ADDRESS),
  currentCompany: currentCompanySelector(state),
  fundingAccountsWithAddressFundingSources: fundingAccountsAllForProviderSubClassSelector(
    state,
    FundingSourceProviderSubClasses.ADDRESS,
  ),
});

export default connect(mapStateToProps)(ConvertAddressToFundingAccount);

import PropTypes from 'prop-types';
import React from 'react';

const NextSteps = ({ text }) => <p className="external-prompt--p has-dark-wrapper">{text}</p>;

NextSteps.propTypes = {
  text: PropTypes.string,
};

NextSteps.defaultProps = {
  text: 'What should I expect next?',
};

export default NextSteps;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Renders list item base and styles for the ActionNavTab.
 * @param {ComponentProps} props
 * @param {*} props.children
 * @param {StringMaybe} props.className
 * @param {AnyComponent[]} [props.Component='span']
 * @param {boolean} [props.isSelected]
 * @param {string} [props.tabIconClass]
 * @returns {StatelessComponent}
 */
const ActionNavTabBase = (props) => {
  const { children, className, Component, isSelected, style, onClick, tabIconClass, onKeyUp, ...rest } = props;

  return (
    <li
      className={classNames('tab-wrapper', className)}
      onClick={onClick}
      onKeyUp={onKeyUp}
      role="menuitem"
      style={style}
    >
      <Component {...rest} className={classNames('tab', { selected: isSelected })}>
        {tabIconClass && (
          <span className={classNames(tabIconClass, 'font-m', 'font-color--main-jordan', 'margin-right--sm')} />
        )}

        <span>{children}</span>
      </Component>
    </li>
  );
};

ActionNavTabBase.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  Component: PropTypes.elementType,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  onKeyUp: PropTypes.func,
  style: PropTypes.shape(),
  tabIconClass: PropTypes.string,
};

ActionNavTabBase.defaultProps = {
  onClick: undefined,
  onKeyUp: undefined,
  className: undefined,
  Component: 'span',
  isSelected: undefined,
  style: undefined,
  tabIconClass: undefined,
};

export default ActionNavTabBase;

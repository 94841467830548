import { sendPartnershipInviteRelationships, submitPartnershipRelationships } from 'constants/relationships';

import { FetchService } from 'services';
import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { getPartnershipMembersEndpoint } from 'services/api/partnershipMemberEndpoints';
import { getResendPartnershipInviteEndpoint, partnershipsEndpoint } from 'services/api/partnershipsEndpoints';

/**
 * POST to create a PartnershipMember attached to a Partnership
 * @param {string} partnershipId
 * @param {PartnershipMember} data - payload
 * @param {ObjectMaybe} [options={}]
 * @returns {Promise}
 */
export const createPartnershipMember = async (partnershipId, data, options = {}) => {
  const payload = payloadToJSONAPI(data, 'PartnershipMember');
  const config = {
    ...options,
    endpoint: getPartnershipMembersEndpoint(partnershipId),
    method: 'POST',
    payload,
    requireAuth: true,
    setRequester: true,
  };
  return FetchService.request(config);
};

/**
 * Create partnership submission.
 * @param {Object} data
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const submitCreatePartnership = async (data, options = {}) => {
  const payload = payloadToJSONAPI(data, 'partnership', ...submitPartnershipRelationships);
  const config = {
    ...options,
    endpoint: partnershipsEndpoint,
    payload,
    method: 'POST',
    requireAuth: true,
    setRequester: true,
  };
  return FetchService.request(config);
};

/**
 * Send an invite to a specific partnership.
 * @param {string} partnershipId
 * @param {Object} data
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const sendPartnershipInvite = async (partnershipId, data, options = {}) => {
  const payload = payloadToJSONAPI(data, 'partnership', ...sendPartnershipInviteRelationships);
  const config = {
    ...options,
    endpoint: getResendPartnershipInviteEndpoint(data.id),
    payload,
    method: 'POST',
    requireAuth: true,
    setRequester: true,
  };
  return FetchService.request(config);
};

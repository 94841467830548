import PropTypes from 'prop-types';
import React from 'react';

import { DateFormats } from 'constants/date';

import { getObjDate } from 'helpers/date';

import ExternalListTableText from '../ExternalItemsListTableText';

/**
 * Component rendering Date field in External Items table
 * @param {ComponentProps} props
 * @param {string} props.value
 * @returns {StatelessComponent}
 */
const ExternalItemsListTableDate = (props) => (
  <ExternalListTableText value={getObjDate(props, 'value', DateFormats.SHORT_ALPHA_MONTH_FULL_YEAR)} />
);

ExternalItemsListTableDate.propTypes = {
  // value prop is used by getObjDate helper
  // eslint-disable-next-line react/no-unused-prop-types
  value: PropTypes.string.isRequired,
};

export default ExternalItemsListTableDate;

import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';

import { simpleMetaCreator } from 'helpers/routine';

import {
  FETCH_PARTNERSHIP_ITEMS_REQUEST,
  LOOKUP_PARTNERSHIP_EMAILS,
  RESEND_PARTNERSHIP_INVITE,
  SEND_UPDATE_PAYMENT_LINK,
  SUBMIT_CREATE_PARTNERSHIP,
  UPDATE_PARTNERSHIP_GENERAL_INFO,
} from 'types/partnership';

export const fetchPartnershipItemsRoutine = createRoutine(FETCH_PARTNERSHIP_ITEMS_REQUEST);

export const lookupPartnershipEmailsRoutine = createRoutine(LOOKUP_PARTNERSHIP_EMAILS);
export const lookupPartnershipEmailsHandler = bindRoutineToReduxForm(lookupPartnershipEmailsRoutine);

export const resendPartnershipInviteRoutine = createRoutine(RESEND_PARTNERSHIP_INVITE);

export const submitCreatePartnershipRoutine = createRoutine(SUBMIT_CREATE_PARTNERSHIP, null, simpleMetaCreator);
export const submitCreatePartnershipHandler = bindRoutineToReduxForm(submitCreatePartnershipRoutine);

export const sendUpdatePaymentLinkRoutine = createRoutine(SEND_UPDATE_PAYMENT_LINK);
export const sendUpdatePaymentLinkHandler = bindRoutineToReduxForm(sendUpdatePaymentLinkRoutine);

export const updatePartnershipGeneralInfoRoutine = createRoutine(UPDATE_PARTNERSHIP_GENERAL_INFO);
export const updatePartnershipGeneralInfoHandler = bindRoutineToReduxForm(updatePartnershipGeneralInfoRoutine);

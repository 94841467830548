export var TimeFrames;
(function (TimeFrames) {
    TimeFrames["TODAY"] = "today";
    TimeFrames["YESTERDAY"] = "yesterday";
    TimeFrames["CURRENT_WEEK"] = "currentWeek";
    TimeFrames["CURRENT_MONTH"] = "currentMonth";
    TimeFrames["OLDER"] = "older";
})(TimeFrames || (TimeFrames = {}));
export var DateScheduledTypes;
(function (DateScheduledTypes) {
    DateScheduledTypes["TODAY"] = "today";
    DateScheduledTypes["FUTURE_DATE"] = "future_date";
})(DateScheduledTypes || (DateScheduledTypes = {}));
export var ItemDateScheduledTypes;
(function (ItemDateScheduledTypes) {
    ItemDateScheduledTypes["TODAY"] = "today";
    ItemDateScheduledTypes["FUTURE"] = "future";
    ItemDateScheduledTypes["SKIP"] = "skip";
})(ItemDateScheduledTypes || (ItemDateScheduledTypes = {}));
export var DateStringLength;
(function (DateStringLength) {
    DateStringLength[DateStringLength["BIRTH_DATE"] = 10] = "BIRTH_DATE";
})(DateStringLength || (DateStringLength = {}));
export var TimeUnit;
(function (TimeUnit) {
    TimeUnit["MILLISECONDS"] = "milliseconds";
    TimeUnit["SECONDS"] = "seconds";
    TimeUnit["MINUTES"] = "minutes";
    TimeUnit["HOURS"] = "hours";
})(TimeUnit || (TimeUnit = {}));
export var TimeUnitStyle;
(function (TimeUnitStyle) {
    TimeUnitStyle["SHORT"] = "SHORT";
    TimeUnitStyle["MED"] = "MED";
    TimeUnitStyle["LONG"] = "LONG";
})(TimeUnitStyle || (TimeUnitStyle = {}));
export var TimeZoneId;
(function (TimeZoneId) {
    TimeZoneId["UTC"] = "UTC";
})(TimeZoneId || (TimeZoneId = {}));

import { z } from 'zod';
const countryModel = z.object({
    id: z.string().uuid(),
    attributes: z.object({
        fullName: z.string(),
        countryCode: z.string(),
    }),
});
const countryOptionModel = z.object({
    id: z.string().uuid(),
    value: z.string(),
    label: z.string(),
});
const countriesModel = z.array(countryModel);
const countriesAsOptionsModel = z.array(countryOptionModel);
export const getCountriesModel = z.object({
    data: countriesModel,
});

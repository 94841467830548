import { call, put } from 'redux-saga/effects';

import { handleRequestErrors } from 'actions/errors';
import * as actions from 'actions/namespace';

import { parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { sagaWatcher } from 'helpers/saga';

import { CHECK_NAMESPACE } from 'types/namespace';

import * as api from './api';

export function* checkNamespace(action) {
  let errorData = {};
  const { payload } = action;

  try {
    yield put(actions.fetchNamespaceRequest());
    const response = yield call(api.checkNamespaceIsValid, payload);

    if (response.ok) {
      yield put(actions.fetchNamespaceSuccess());
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchNamespaceFailure, errorData));
}

export default function* namespace() {
  yield sagaWatcher([
    {
      type: CHECK_NAMESPACE,
      saga: checkNamespace,
    },
  ]);
}

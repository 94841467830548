import classNames from 'classnames';
import React from 'react';
import { Image } from 'components/media';
import { Text } from 'components/text';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { typography } from 'constants/styles';
import { LOGO } from 'global/images/platform';
import {} from './BoxV2.types';
import './BoxV2.scss';
const BoxV2 = ({ children, className, subtitle, title }) => (React.createElement("div", { className: classNames({
        'box-v2--container': true,
        [className]: Boolean(className),
    }) },
    React.createElement("div", { className: "box-v2--header" },
        React.createElement(Image, { alt: PLATFORM_DISPLAY_SHORT_NAME, className: "box-v2--header__logo margin-bottom--m", src: LOGO.NAVY }),
        React.createElement(Text.Regular, { className: "text-align--center", color: typography.TextColor.BLACK, lineHeight: typography.TextLineHeight.EXTRA_EXTRA_EXTRA_LARGE, size: typography.TextSize.LEVEL_600 }, title),
        subtitle && (React.createElement(Text.Regular, { className: "display--block margin-top--sm text-align--center", color: typography.TextColor.GREY_XX_DARK, lineHeight: typography.TextLineHeight.LARGE, size: typography.TextSize.LEVEL_200 }, subtitle))),
    React.createElement("div", { className: "box-v2--body" }, children)));
export default BoxV2;

import PropTypes from 'prop-types';
import React from 'react';

import { ToggleSwitch } from 'components/form';

import TableCellTextWithSubtext from '../TableCellTextWithSubtext';

/**
 * ToggleSwitch to be used in react-table
 * @param {Boolean} isChecked
 * @param {Function} onChange
 * @return {StatelessComponent}
 */
const ToggleSwitchCell = ({ isChecked, onChange }) => (
  <>
    <div className="left-indicator">
      <ToggleSwitch isChecked={isChecked} name="toggle" onChange={onChange} />
    </div>

    <TableCellTextWithSubtext text={isChecked ? 'Yes' : 'No'} textClassName="font-color--grey--x-dark" />
  </>
);

ToggleSwitchCell.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ToggleSwitchCell;

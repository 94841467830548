import { allKeys, firstKey, hasZeroLength, isFn, isNotEqual, isObject } from 'helpers/utility';

import getRelationships from 'store/redux';

import { setValuesForAllKeysInObjectDeep } from './objects';

/**
 * Returns objects from an API payload as a byId object,
 * with parsed relationships. Optional function argument
 * `getAttributes` allows for custom transformation of
 * attributes.
 * @param {Object} objects
 * @param {string[]} [relationshipKeys=[]]
 * @param {Object} [options={}]
 * @param {Object} [options.currentState={}]
 * @param {function(Object, Object, string): Object} [options.getAttributes]
 * @param {Object} [options.initialAttributes={}]
 * @return {*|{}}
 */
export const getObjectsByIdWithRelationships = (objects, relationshipKeys = [], options = {}) => {
  const { currentState = {}, getAttributes, initialAttributes = {} } = options;

  return allKeys(objects).reduce((objectsById, objectId) => {
    const relationships = getRelationships(objects, objectId, ...relationshipKeys);

    const mergedAttributes = isFn(getAttributes) ? getAttributes(currentState, objects, objectId) : {};

    return {
      ...objectsById,
      [objectId]: {
        id: objects[objectId].id,
        ...initialAttributes,
        ...objects[objectId].attributes,
        ...mergedAttributes,
        ...relationships,
      },
    };
  }, {});
};

/**
 * Parses attributes and relationships from an API payload and
 * returns them in a single object for the reducer.
 * @param {Object} payloadObject
 * @param {string[]} relationshipKeys
 * @param {Object} options
 * @return {Object}
 */
export const getAttributesAndRelationshipsForReducer = (payloadObject, relationshipKeys = [], options = {}) => {
  const { currentState = {}, getAttributes } = options;

  if (!isObject(payloadObject) || hasZeroLength(allKeys(payloadObject))) {
    return {};
  }

  const objectId = firstKey(payloadObject);

  const relationships = getRelationships(payloadObject, objectId, ...relationshipKeys);

  const mergedAttributes = isFn(getAttributes) ? getAttributes(currentState, payloadObject, objectId) : {};

  return {
    id: objectId,
    ...payloadObject[objectId].attributes,
    ...mergedAttributes,
    ...relationships,
  };
};

/**
 * Returns the next form state to set in relevant form plugins after a
 * successful validation of an async email check.
 * @param {Object} state - this reducer's state
 * @return {ReduxState}
 */
export const getNextStateForEmailOnValidateSuccess = (state) => {
  // This helper is used to remove `email` warnings from form state
  // One case that this recursive function covers is values.meta.warnings.partnershipMembers[0].email

  const updatedWarnings = setValuesForAllKeysInObjectDeep(state?.values?.meta?.warnings, 'email', null);
  const updatedSyncWarnings = setValuesForAllKeysInObjectDeep(state?.syncWarnings, 'email', null);

  return {
    ...state,
    syncWarnings: {
      ...updatedSyncWarnings,
    },
    values: {
      ...state.values,
      meta: {
        ...state.values.meta,
        warnings: {
          ...updatedWarnings,
        },
      },
    },
  };
};

/**
 * Returns the next form state to set in relevant form plugins after a
 * failed validation of an async email check.
 * @param {Object} state - this reducer's state
 * @param {ReduxAction} action
 * @return {Object}
 */
export const getNextStateForEmailOnValidateFailed = (state, action) => ({
  ...state,
  values: {
    ...state.values,
    meta: {
      ...state.values.meta,
      warnings: {
        ...state.values.meta?.warnings,
        ...action.payload?.warnings,
      },
    },
  },
});

/**
 * Given a list of IDs, remove the one that matches.
 * @param {string[]} listOfIds
 * @param {string} matchingId - the ID you wish to remove
 * @returns {string[]} the listOfIds, minus the matchingId if it was in the list
 */
export const filterOutMatchingId = (listOfIds, matchingId) => listOfIds.filter((id) => isNotEqual(id, matchingId));

/**
 * For a allReducer, remove an ID from the list of IDs.
 * @param {string[]} state - array of string IDs
 * @param {ReduxAction} action - action with the ID you wish to remove
 * @returns {string[]} - array of string IDs, minus the ID you're trying to remove if it was in the list
 */
export const removeResourceFromAllIds = (state, action) => filterOutMatchingId(state, action.payload.id);

/**
 * For byId reducer, remove an object from the list for a given ID.
 * @param {Object.<string, Object>} state
 * @param {ReduxAction} action - action with the ID you wish to remove
 * @returns {Object.<string, Object>}
 */
export const removeResourceFromById = (state, action) => {
  // the IDs, minus the ID we're trying to remove
  const remainingIds = filterOutMatchingId(allKeys(state), action.payload.id);

  // using the list of IDs, make a new byId object
  return remainingIds.reduce(
    (byId, id) => ({
      ...byId,
      [id]: {
        ...state[id],
      },
    }),
    {},
  );
};

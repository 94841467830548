/**
 * Suffixes which can be applied to the end of numbers.
 * @enum {string}
 */
export const NumericSuffix = {
  PERCENT: '%',
};

/**
 * Style of rounding function (ultimately used to determine whether to use Math.floor or Math.ceil).
 * @enum {string}
 */
export const RoundingStyle = {
  FLOOR: 'floor',
  CEIL: 'ceil',
};

export const defaultDecimalPrecision = 2;

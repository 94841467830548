import { Repeat, SubtractAlt } from '@carbon/icons-react';
import { Menu } from '@routable/gross-ds';
import React from 'react';
import PermissionResourceAction from 'constants/permissions';
import { useGrossDsPermissions } from 'hooks';
const TeamMemberListPopoverCell = ({ onResendInvite, onRevokeInvite, }) => {
    const { hasPermission, tooltipProps } = useGrossDsPermissions([PermissionResourceAction.SETTINGS_TEAM_MEMBERS_EDIT]);
    return (React.createElement(Menu, { buttonVariant: "floating", placement: "bottom" },
        React.createElement(Menu.Item, { disabled: !hasPermission, icon: React.createElement(Repeat, null), onAction: onResendInvite, tooltip: tooltipProps }, "Re-send invite"),
        React.createElement(Menu.Item, { disabled: !hasPermission, icon: React.createElement(SubtractAlt, null), onAction: onRevokeInvite, tooltip: tooltipProps }, "Revoke invite")));
};
export default TeamMemberListPopoverCell;

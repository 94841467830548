import { REST } from 'helpers/api';
import { getURLWithQueryParams } from 'helpers/urls';

import { membershipsEndpoint, getMembershipSwitchEndpoint } from 'services/api/membershipsEndpoints';

export function apiFetchMembershipList(searchTerm = null) {
  const requestParams = {
    quickswitch: 'true',
  };
  if (searchTerm) {
    requestParams.search = searchTerm;
  }
  return REST.get({
    endpoint: getURLWithQueryParams(membershipsEndpoint, requestParams),
  });
}

export function switchMembershipRequest(membershipId) {
  return REST.post({ endpoint: getMembershipSwitchEndpoint(membershipId) });
}

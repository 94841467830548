import PropTypes from 'prop-types';
import React from 'react';

import { getFilteredPartnershipContactsOnArchivedStatus } from 'helpers/contacts';
import { hasArchivedPartnershipMembers, hasOnlyArchivedPartnershipMembers } from 'helpers/partnerships';
import { hasLength1 } from 'helpers/utility';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import CompanyContactsTable from './CompanyContactsTable';

/**
 * Container for CompanyContactsTable
 * Decides if the archived contact toggle should be enabled or disabled, and whether it's active or not
 * @param {ComponentProps} props
 * @param {PartnershipMember[]} props.data
 * @return {FunctionComponent}
 */
const CompanyContactsTableContainer = (props) => {
  const { data: contacts } = props;

  const allContactsAreArchived = hasOnlyArchivedPartnershipMembers(contacts);

  // show archive contact toggle only if some contacts are archived
  const toggleIsVisible = hasArchivedPartnershipMembers(contacts);

  // if the toggle is shown, also disable it if all contacts are archived
  // (as there are no other users to show)
  const toggleIsDisabled = allContactsAreArchived;

  // default toggle to 'on' when:
  // - all contacts are archived (user needs to see them to un-archive)
  // - there is only 1 contact (this contact could be archived and user needs to see it to un-archive)
  // toggle defaults to off otherwise even if there are some archived contacts
  const isActive = hasLength1(contacts) || allContactsAreArchived;
  const [toggleIsActive, setToggleIsActive] = React.useState(isActive);

  // if user has both active and archived contacts, the toggle default to off
  // if user then archives all contacts, we must ensure that the toggle switches to on
  React.useEffect(() => {
    if (allContactsAreArchived) {
      setToggleIsActive(true);
    }
  }, [allContactsAreArchived]);

  const onClickArchivedContactsToggle = () => {
    setToggleIsActive(!toggleIsActive);
  };

  const filteredContacts = getFilteredPartnershipContactsOnArchivedStatus({
    contacts,
    showArchivedContacts: toggleIsActive || allContactsAreArchived,
  });

  const stateProps = {
    toggleIsActive,
    onClickArchivedContactsToggle,
    data: filteredContacts,
    toggleIsDisabled,
    toggleIsVisible,
  };

  return <CompanyContactsTable {...props} {...stateProps} />;
};

CompanyContactsTableContainer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default CompanyContactsTableContainer;

import { apiCall } from '@routable/framework';
import { policyApiListModel, policyApiSingularModel } from '../../models';
export const approvalsPoliciesUrl = 'approvals/policies/';
export const policyApi = {
    read: ({ id }) => apiCall({
        url: `${approvalsPoliciesUrl}${id}/`,
        method: 'get',
        responseModel: policyApiSingularModel,
        camelCaseResponse: true,
    }),
    readAll: async () => apiCall({
        url: approvalsPoliciesUrl,
        method: 'get',
        responseModel: policyApiListModel,
        camelCaseResponse: true,
    }),
    save: async ({ id, values, version }) => apiCall({
        url: id ? `${approvalsPoliciesUrl}${id}/` : approvalsPoliciesUrl,
        method: id ? 'put' : 'post',
        body: { ...values, id, version },
        camelCaseResponse: true,
        responseModel: policyApiSingularModel,
    }),
};

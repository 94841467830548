import React from 'react';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';

import { ReduxFormFieldRenderInput, Textarea } from 'components';
import CannotUpdateFieldHint from 'components/hintTypes/CannotUpdateFieldHint';
import InputValueHighlighter from 'components/input/InputValueHighlighter';

import { FEATURE_FLAG_CROSSBORDER_MULTIPLE_PROVIDERS } from 'constants/featureFlags';

import { requiredValidator, companyNameValidator } from 'helpers/fieldValidation';

import useFeatureFlag from 'hooks/useFeatureFlag';

import { containerWidthMap } from 'modules/signup-v3/constants/container';
import { mapViewToFields } from 'modules/signup-v3/fields';
import { openDocumentModal } from 'modules/signup-v3/helpers/helpDocs';

import {
  currentCompanyVerificationUpdateAllowedSelector,
  isUpdatingCurrentCompanySelector,
} from 'selectors/currentCompanySelectors';

import { signupFlowForm } from '../constants';
import { BUSINESS_NAME_VALIDATION_REGEX_HIGHLIGHTER } from '../constants/regex';
import FlowStepperMain from '../FlowStepperMain';
import { Label, LabelHelper } from '../FlowStepperMain.styles';
import { viewSteps } from '../helpers/viewSteps';

import { Container, LabelLink } from './LegalBusinessName.styles';

export const fields = {
  companyName: {
    label: 'Your company name',
    helper: <>The name used for communicating with vendors and customers.</>,
    placeholder: 'Company name',
    isRequired: true,
    validators: [requiredValidator],
  },
  legalName: {
    label: `What's the legal entity name of your business?`,
    helper: (
      <>
        The name you enter here must match the government-issued document from{' '}
        <LabelLink onClick={openDocumentModal}>this list</LabelLink>. This name will show up on bank statements.
      </>
    ),
    placeholder: 'Legal business name',
    isRequired: true,
    hasSyntaxHighlighter: true,
    validators: [requiredValidator, companyNameValidator],
  },
  dbaName: {
    label: `What's your Doing Business As (DBA) name?`,
    helper: 'Does your business go by a name other than its official, legal name? Enter it here.',
    placeholder: 'DBA name',
    isRequired: false,
  },
};

export const LegalBusinessNameField = ({ name, id, isDisabled }) => {
  const formValues = useSelector(getFormValues(signupFlowForm));

  return (
    <Container>
      <Label htmlFor={name}>{fields[id].label}</Label>
      <LabelHelper>{fields[id].helper}</LabelHelper>
      <Field
        component={ReduxFormFieldRenderInput}
        dataFullStory
        dataTestId={id}
        inputId={name}
        isDisabled={isDisabled}
        isLocked={fields[id].isLocked || isDisabled}
        isRequired={fields[id].isRequired}
        name={name}
        placeholder={fields[id].placeholder}
        type="text"
        validate={fields[id].validators}
      >
        {fields[id].hasSyntaxHighlighter && (
          <InputValueHighlighter
            regex={BUSINESS_NAME_VALIDATION_REGEX_HIGHLIGHTER}
            value={formValues?.info?.legalName}
          />
        )}
      </Field>
    </Container>
  );
};

const LegalBusinessNameMain = ({ activeView, invalid }) => {
  const updatedAllowed = useSelector(currentCompanyVerificationUpdateAllowedSelector);
  const isUpdating = useSelector(isUpdatingCurrentCompanySelector);
  const crossborderMultipleProvidersEnabled = useFeatureFlag(FEATURE_FLAG_CROSSBORDER_MULTIPLE_PROVIDERS);

  return (
    <FlowStepperMain
      containerWidth={containerWidthMap.small}
      fields={
        <Container>
          {!updatedAllowed && <CannotUpdateFieldHint />}
          {mapViewToFields[activeView].map(({ name, ...props }) => (
            <LegalBusinessNameField name={name} {...props} isDisabled={!updatedAllowed} key={name} />
          ))}
          {crossborderMultipleProvidersEnabled && (
            <Container>
              <Label htmlFor="business_description">What does your business do?</Label>
              <LabelHelper>
                Please share a brief description of your business and a link to your company&apos;s website if you have
                one.
              </LabelHelper>
              <Field
                component={Textarea}
                isRequired
                name="business_description"
                placeholder="Business description"
                rows={5}
                validate={[requiredValidator]}
              />
            </Container>
          )}
        </Container>
      }
      invalid={invalid || !updatedAllowed}
      onBack={viewSteps[activeView].previous}
      onNext={{
        ...viewSteps[activeView].next,
        isLoading: isUpdating,
      }}
      onSkip={updatedAllowed && viewSteps[activeView].skip}
      title={{ content: "Let's identify your business" }}
    />
  );
};

export default LegalBusinessNameMain;

import * as routines from 'actions/routines/currentCompany';

import { getAttributesAndRelationshipsForReducer } from 'helpers/reducer';

import { GET_CURRENT_COMPANY_SUCCESS } from 'types/currentCompany';

const companyItemApprovalSettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CURRENT_COMPANY_SUCCESS:
    case routines.fetchCurrentCompanySettingsItemApprovalRoutine.SUCCESS:
      return getAttributesAndRelationshipsForReducer(action.payload.companySettingsItemApproval, [
        'itemApprovalLevels',
      ]);

    default:
      return state;
  }
};

export default companyItemApprovalSettingsReducer;

import { colors } from 'constants/styles';
export const AmountTextProps = {
    CancelledItem: {
        className: 'text-decoration--line-through',
    },
    Default: {},
    OutstandingAmount: {
        color: colors.colorRedMediumHex,
    },
    ZeroAmount: {
        color: colors.colorGreyXDarkHex,
    },
};
export default AmountTextProps;

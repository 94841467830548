import PropTypes from 'prop-types';
import React from 'react';

import {
  IconNames,
  Modal,
  ModalBodyContent,
  ModalFooter,
  ModalFooterButton,
  ModalFooterButtonCancel,
} from 'components';

import { sizes } from 'constants/styles';

import PartnerPaymentMethodAccountsModalContent from './PartnerPaymentMethodAccountsModalContent';

class PartnerPaymentMethodModal extends React.Component {
  state = {
    selectedFundingAccount: this.props.selectedFundingAccount,
  };

  componentDidUpdate(prevProps) {
    const { isOpen, selectedFundingAccount } = this.props;
    const { isOpen: wasOpen } = prevProps;
    if (!wasOpen && isOpen) {
      this.setState({ selectedFundingAccount });
    }
  }

  handlePaymentMethodChanged = (selectedFundingAccount) => {
    this.setState({ selectedFundingAccount });
  };

  handleSelectPaymentMethod = () => {
    const { onClose, onSelectPaymentMethod } = this.props;
    const { selectedFundingAccount } = this.state;
    onSelectPaymentMethod(selectedFundingAccount);
    onClose();
  };

  render() {
    const { children, isOpen, onClose } = this.props;

    const { selectedFundingAccount } = this.state;

    const clonedChildren = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        ...child.props,
        isChecked: selectedFundingAccount === child.props.fundingAccount.id,
        onChange: this.handlePaymentMethodChanged,
      }),
    );

    return (
      <Modal
        body={
          <React.Fragment>
            <ModalBodyContent>
              <PartnerPaymentMethodAccountsModalContent {...this.props}>
                {clonedChildren}
              </PartnerPaymentMethodAccountsModalContent>
            </ModalBodyContent>

            <ModalFooter className="partner-payment-method--modal-footer">
              <ModalFooterButtonCancel onCloseModal={onClose} />

              <ModalFooterButton
                isActionButton
                leftIconName={IconNames.TICK_CIRCLE}
                leftIconSize={sizes.iconSizes.LARGE}
                onClick={this.handleSelectPaymentMethod}
              >
                Select payment method
              </ModalFooterButton>
            </ModalFooter>
          </React.Fragment>
        }
        hasInlineFooter
        modalHeader="Available partner payment methods"
        modalState={isOpen}
        onCloseModal={onClose}
      />
    );
  }
}

PartnerPaymentMethodModal.propTypes = {
  children: PropTypes.node,
  hasAccounts: PropTypes.bool,
  isFetching: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSelectPaymentMethod: PropTypes.func.isRequired,
  partnership: PropTypes.shape().isRequired,
  paymentDeliveryMethod: PropTypes.string.isRequired,
  selectedFundingAccount: PropTypes.string,
};

PartnerPaymentMethodModal.defaultProps = {
  children: undefined,
  hasAccounts: undefined,
  isFetching: undefined,
  isOpen: undefined,
  selectedFundingAccount: undefined,
};

export default PartnerPaymentMethodModal;

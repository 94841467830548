import {} from '@routable/framework';
import { css } from 'styled-components';
export const CssCase = (value) => {
    return value.replace(/[A-Z]/g, (match, offset) => (offset > 0 ? '-' : '') + match.toLowerCase());
};
export const BuildInlineCSS = (themeObject) => {
    const propertiesToArray = (obj) => {
        const isObject = (val) => typeof val === 'object' && !Array.isArray(val);
        const paths = (pathObj = {}) => {
            return Object.entries(pathObj).reduce((product, [key, value]) => {
                const fullPath = `--${CssCase(key)}:${value}`;
                return isObject(value) ? product.concat(paths(value, fullPath)) : product.concat(fullPath);
            }, []);
        };
        return paths(obj);
    };
    return `${propertiesToArray(themeObject).join(';')};`;
};
export const joinObjects = (obj, ...variable) => {
    const isObject = (value) => {
        if (typeof value === 'object' && !Array.isArray(value) && value !== undefined) {
            return true;
        }
        return false;
    };
    if (!variable.length)
        return obj;
    const source = variable.shift();
    if (isObject(obj) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!obj[key])
                    Object.assign(obj, { [key]: {} });
                joinObjects(obj[key], source[key]);
            }
            else {
                Object.assign(obj, { [key]: source[key] });
            }
        }
    }
    return joinObjects(obj, ...variable);
};
export const removeUndefined = (obj) => {
    return JSON.parse(JSON.stringify(obj));
};
export const checkObject = (obj) => {
    if (!obj || Object.keys(obj).length === 0) {
        return false;
    }
    return true;
};
export const getCssVariables = (cssKey, cssObject) => {
    const defaultCssVariable = cssObject?.default;
    if (!checkObject(defaultCssVariable)) {
        return css ``;
    }
    let activeCssVariable = {};
    if (!cssKey || cssKey === '' || !cssObject[cssKey]) {
        activeCssVariable = { ...defaultCssVariable };
    }
    else {
        activeCssVariable = cssObject[cssKey];
    }
    if (!checkObject(defaultCssVariable)) {
        return css ``;
    }
    if (checkObject(activeCssVariable)) {
        activeCssVariable = { ...defaultCssVariable, ...activeCssVariable };
    }
    return css `
    ${BuildInlineCSS(activeCssVariable)}
  `;
};
export const internalId = (prefix) => {
    return `${prefix ? prefix + '_' : ''}${crypto.randomUUID().replace(/[-]/g, '').substring(0, 10)}`;
};
export const isSupportedElement = (allowedItems, item) => {
    return allowedItems.some((it) => it === item?.type?.name);
};
export const getElementVariable = (item) => {
    return item?.type?.name?.toString()?.toLowerCase() || '';
};
export const hex2RGBA = (hex, alpha) => {
    const hexArray = hex.replace('#', '').match(/.{1,2}/g);
    if (hexArray && hexArray.length >= 2) {
        const hexValues = [parseInt(hexArray[0], 16), parseInt(hexArray[1], 16), parseInt(hexArray[2], 16)];
        return `rgba(${hexValues[0]},${hexValues[1]},${hexValues[2]},${alpha})`;
    }
    return `rgba(0,0,0,0)`;
};

import classNames from 'classnames';
import React from 'react';
import {} from './CheckList.types';
import { CheckListInnerList, CheckListItem } from './components';
import './CheckList.scss';
const CheckList = ({ children, className, title }) => {
    const itemList = React.createElement(CheckListInnerList, { className: "check-list--nested-list" }, children);
    return (React.createElement("div", { className: classNames({
            'check-list': true,
            [className]: !!className,
        }) },
        !!title && (React.createElement(CheckListInnerList, null,
            React.createElement(CheckListItem, { className: "check-list--title", isTitle: true },
                title,
                itemList))),
        !title && itemList));
};
export default CheckList;

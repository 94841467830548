import { put, select, spawn, take } from 'redux-saga/effects';

import * as actions from 'actions/oldVersion';

import { isDashboardFullyLoadedSelector } from 'selectors/dashboardLoadedSelectors';

import * as types from 'types/oldVersion';

/**
 * Handle switching to oldVersion mode.
 * @return {IterableIterator<*>}
 */
export function* handleOldVersionMode() {
  const isDashboardFullyLoaded = yield select(isDashboardFullyLoadedSelector);

  if (isDashboardFullyLoaded) {
    yield put(actions.oldVersionModeModalOpen());
  } else {
    yield put(actions.oldVersionModeOn());
  }
}

/**
 * Listens for redux actions related to UI.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([types.HANDLE_OLD_VERSION_MODE]);

    switch (action.type) {
      case types.HANDLE_OLD_VERSION_MODE:
        yield spawn(handleOldVersionMode);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root oldVersion saga.
 * @return {IterableIterator<*>}
 */
export default function* oldVersion() {
  yield watch();
}

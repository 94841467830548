import { z } from 'zod';
export const DismissApprovalsOnChangeOption = z.enum(['always', 'approvers_change', 'amount_change']);
export const itemApprovalsSettingsModel = z.object({
    allowApprovalByEmail: z.boolean(),
    allowCustomizeApprovalRequirements: z.boolean(),
    dismissApprovalsOnChange: DismissApprovalsOnChangeOption,
    enforceForApprovers: z.boolean(),
    limitApproverItemView: z.boolean(),
});
export const itemApprovalsSettingsApiModel = z.object({
    data: itemApprovalsSettingsModel,
});

import { fetchSingleIntegrationLedgerPartnershipsRoutine } from 'actions/routines/integrations';
import { submitCreatePartnershipRoutine } from 'actions/routines/partnership';

import { deepMergeWithArrayReplacement } from 'helpers/transform';

import {
  FETCH_LEDGER_PARTNERSHIPS_SUCCESS,
  SUBMIT_PLATFORM_PARTNERSHIP_SUCCESS,
  SUBMIT_LEDGER_PARTNERSHIP_MERGE_SUCCESS,
  SUBMIT_LEDGER_PARTNERSHIP_SUCCESS,
} from 'types/integrations';

import {
  getLedgerPartnershipsById,
  handleFetchLedgerPartnershipsSuccess,
  handleFetchSingleLedgerPartnershipsSuccess,
  handleSubmitLedgerPartnershipMergeSuccess,
  handleSubmitPartnershipSuccess,
  handleSubmitPlatformPartnershipSuccess,
} from './helpers';

const ledgerPartnershipsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case fetchSingleIntegrationLedgerPartnershipsRoutine.SUCCESS:
      return handleFetchSingleLedgerPartnershipsSuccess(state, action);

    case FETCH_LEDGER_PARTNERSHIPS_SUCCESS:
      return handleFetchLedgerPartnershipsSuccess(state, action);

    case SUBMIT_PLATFORM_PARTNERSHIP_SUCCESS:
      return handleSubmitPlatformPartnershipSuccess(state, action);

    case submitCreatePartnershipRoutine.SUCCESS:
      return handleSubmitPartnershipSuccess(state, action);

    case SUBMIT_LEDGER_PARTNERSHIP_MERGE_SUCCESS:
      return handleSubmitLedgerPartnershipMergeSuccess(state, action);

    case SUBMIT_LEDGER_PARTNERSHIP_SUCCESS:
      return deepMergeWithArrayReplacement(state, getLedgerPartnershipsById(action.payload.partnership));

    default:
      return state;
  }
};

export default ledgerPartnershipsByIdReducer;

import React from 'react';
import { Text, WhiteSpace } from 'components';
import { typography } from 'constants/styles';
import { getFormattedDetailsOfFundingInfoAddress, getFundingInfoAddressDisplayName, isFundingAccountBankAccount, } from 'helpers/funding';
const PaymentMethodHintBaseContent = ({ fundingAccount, fundingInfoAddress, }) => {
    const isBankAccount = isFundingAccountBankAccount(fundingAccount);
    if (isBankAccount) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Text.Bold, { color: typography.TextColor.BLACK, dataFullStory: true }, fundingAccount.name),
            React.createElement(WhiteSpace, null),
            React.createElement(Text.Regular, { color: typography.TextColor.GREY_X_DARK, dataFullStory: true }, fundingAccount.currencyCode)));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Text.Bold, { color: typography.TextColor.BLACK }, "Check mailing address:"),
        React.createElement(Text.Semibold, { color: typography.TextColor.BLACK, dataFullStory: true },
            ' ',
            getFundingInfoAddressDisplayName(fundingInfoAddress),
            ', ',
            fundingInfoAddress.streetAddress,
            ', ',
            getFormattedDetailsOfFundingInfoAddress(fundingInfoAddress))));
};
export default PaymentMethodHintBaseContent;

import PropTypes from 'prop-types';

const ActionNavChildren = ({ children, searchData }) => {
  if (searchData && searchData.execute) {
    return null;
  }

  return children;
};

ActionNavChildren.propTypes = {
  children: PropTypes.node.isRequired,
  searchData: PropTypes.shape({
    execute: PropTypes.number,
    keyword: PropTypes.string,
  }),
};

ActionNavChildren.defaultProps = {
  searchData: null,
};

export default ActionNavChildren;

import { PartnershipCountryCodeKey } from 'enums/partnerships';
import { isItemKindPayable } from 'helpers/items';
import { isInternationalPartnership } from 'helpers/partnerships';
import { selectedInvoicesCurrencyCodeSelector } from 'queries/createItemFormSelectors';
import { currentCompanySettingsIsInternationalPaymentsEnabledSelector } from 'selectors/currentCompanySelectors';
import { createItemFormUiSelectedCompanySelector, createItemFormItemSelector } from 'selectors/forms';
import { storeAccessor as store } from 'store/accessor';
export const isVendorReceivingCurrencyEnabled = ({ isInternational, isPayable, }) => {
    const state = store.getState();
    const isInternationalPaymentsEnabled = currentCompanySettingsIsInternationalPaymentsEnabledSelector(state);
    return isInternationalPaymentsEnabled && isInternational && isPayable;
};
export const isCreateItemVendorReceivingCurrencyEnabled = () => {
    const state = store.getState();
    const item = createItemFormItemSelector(state);
    const isPayable = isItemKindPayable(item);
    const selectedCompany = createItemFormUiSelectedCompanySelector(state);
    const isInternationalPartner = isInternationalPartnership(selectedCompany, PartnershipCountryCodeKey.PARTNER);
    const isInternational = item?.isInternational || isInternationalPartner;
    return isVendorReceivingCurrencyEnabled({ isInternational, isPayable });
};
export const getSelectedInvoicesCurrencyCode = () => {
    const state = store.getState();
    return selectedInvoicesCurrencyCodeSelector(state);
};

import React from 'react';

import { ContactsInteractionListView } from 'context';

/**
 * When you want to get the value from ContactsInteractListView, use this simple hook to fetch the context value.
 * @function
 * @returns {object} - When invoked, returns the value from ContactsInteractionListView.Provider.
 */
const useContactsInteractionListViewContext = () => React.useContext(ContactsInteractionListView);

export default useContactsInteractionListViewContext;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { LinkNewPage } from 'components/link';
import { BrandNav } from 'components/nav';

const BrandWrapper = ({ children, isLinkNewPage, linkUrl, linkText, text }) => {
  const textClasses = classNames({
    bold: !linkUrl,
    'font-color--white': true,
    'font-regular': true,
    'margin-right--sm': true,
    'nav--links-description': true,
  });

  const linkClasses = classNames({
    bold: true,
    'font-color--white': true,
    'font-regular': true,
  });

  return (
    <div className="branded--container">
      <BrandNav>
        <ul className="nav--links">
          <li>
            <span className={textClasses}>{text}</span>

            {linkUrl && isLinkNewPage && (
              <LinkNewPage className={linkClasses} href={linkUrl}>
                {linkText}
              </LinkNewPage>
            )}

            {linkUrl && !isLinkNewPage && (
              <Link className={linkClasses} to={linkUrl}>
                {linkText}
              </Link>
            )}
          </li>
        </ul>
      </BrandNav>

      {children}
    </div>
  );
};

BrandWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  linkUrl: PropTypes.string,
  isLinkNewPage: PropTypes.bool,
  linkText: PropTypes.string,
  text: PropTypes.string.isRequired,
};

BrandWrapper.defaultProps = {
  isLinkNewPage: true,
  linkUrl: undefined,
  linkText: undefined,
};

export default BrandWrapper;

import _isEqual from 'lodash/isEqual';
import _merge from 'lodash/merge';
import { SubmissionError } from 'redux-form';

import { isCurrentCompanyTypePersonal } from 'helpers/currentCompany';
import { getQueryParam } from 'helpers/queryParams';
import { anyValues } from 'helpers/utility';

/**
 * Only PATCH the update if there is a change in the forms. We determine if there is a change by deeply comparing the
 * initial values of the form against the values potentially being submitted.
 * @param {string} formKey - Where would errors be nested under the form
 * @param {object} initialValues
 * @param {object} newValues
 * @param {function} [successCallback] - Extracts new ID from the successful patch and adds it to the form for
 * subsequent calls to the BE.
 * @param {function} updateMethod
 * @returns {Promise<Object>} - Always resolves with an object which potentially contains errors from the call.
 */
export const updateIfNeeded = async ({ formKey, initialValues, newValues, successCallback, updateMethod }) => {
  const result = await new Promise((resolve, reject) => {
    if (_isEqual(newValues, initialValues)) {
      return resolve();
    }

    return updateMethod(newValues, successCallback).then(resolve).catch(reject);
  });

  let anyErrors = {};

  if (result && result.errors) {
    anyErrors = {
      [formKey]: {
        ...result.errors.fields,
      },
    };
  }

  return anyErrors;
};

/**
 * We dispatch all the errors at the same time with Promise.all. This method combines errors coming from, potentially,
 * three different PATCHes to the backend.
 * @param companyError - company.info or company.mailingAddress
 * @param personalError - membership.personalInfo
 * @param membershipError - membership.user
 * @returns {Object} - Returns an empty object if there are no errors or an object with errors
 */
const mergeErrors = ([companyError, personalError, membershipError]) => {
  // membership errors need to be merged because just spreading them into the membership object will overwrite the user
  // and personalInfo keys, because they come back from different calls
  const mergedMembershipErrors = [personalError, membershipError].reduce((combinedErrors, error) => {
    if (anyValues(error)) {
      return _merge(combinedErrors, error);
    }

    return combinedErrors;
  }, {});

  return [companyError, mergedMembershipErrors].reduce((combinedErrors, error) => {
    if (anyValues(error)) {
      return {
        ...combinedErrors,
        ...error,
      };
    }

    return combinedErrors;
  }, {});
};

/**
 * Get relationships for company and membership using the company_id or membership_id in the URL. The relationships
 * contain further ids, like those for mailingAddress or personalInfo.
 @example
 * // url.com/tax/?company_id=ABC
 * const response = {
 *   company: {
 *     ABC: {
 *       id: 'ABC',
 *       relationships: {},
 *     },
 *   },
 * };
 * getRelationShipsFromParsedResponse(response, 'company');
 * // 123ABC
 * @param {object} parsedResponse
 * @param {string} key
 * @returns {object} relationships
 */
export const getRelationshipsFromParsedResponse = (parsedResponse, key) => {
  const id = getQueryParam(`${key}_id`);
  const data = parsedResponse[key][id];
  return data.relationships;
};

/**
 * When the vendor finishes the first tax collect step by clicking the "Confirm information" button, dispatch updates
 * to the backend to create/update the company, membership, and personal membership.
 * @param {object} filingDetailsForm
 * @param {object} initialValues - Initial values provided on form start so we can determine if data changes,
 * necessitating and update to the backend
 * @param {object} options - ids and thunks
 * @returns {Promise<void>} - On success, open TIN confirmation modal. Throws SubmissionError if updates fail.
 */
const submitFilingUpdates = async (filingDetailsForm = {}, initialValues = {}, options = {}) => {
  const { company: companyForm, membership: membershipPayload, meta } = filingDetailsForm.form;

  const { company: initialCompanyValues, membership: initialMembershipValues } = initialValues.form;

  const { needsTos, tinType } = filingDetailsForm.ui;

  const {
    // bound change from redux-form
    change,
    onSuccessOpenModal,
    onUpdateOnboardingCompany,
    onUpdateOnboardingCompanyType,
    onUpdateOnboardingMembership,
  } = options;

  // Setting up the company payload, overriding tinType from the formUI
  const companyPayload = {
    ...companyForm,
    info: {
      ...companyForm.info,
      tinType,
    },
  };

  const companyTypePayload = {
    id: companyPayload.id,
    companyType: companyPayload.companyType,
  };

  // Add meta to company payload
  companyPayload.meta = {
    partnershipId: getQueryParam('partnership_id'),
  };

  // Remove companyType (it has its own call now)
  delete companyPayload.companyType;

  // Remove name if company type is personal
  if (isCurrentCompanyTypePersonal(companyForm)) {
    delete companyPayload.name;
  }

  // Add meta to company payload
  membershipPayload.meta = {
    ...meta,
    forTax: true,
    partnershipId: getQueryParam('partnership_id'),
  };

  // Remove sending TOS agree if not needed
  if (!needsTos) {
    delete membershipPayload.meta.tosAgree;
  }

  // First submit the company type call (as it will affect the values that can be sent in other calls)
  await updateIfNeeded({
    formKey: 'company',
    initialValues: { companyType: initialCompanyValues.companyType },
    newValues: companyTypePayload,
    updateMethod: onUpdateOnboardingCompanyType,
  });

  const results = await Promise.all([
    updateIfNeeded({
      formKey: 'company',
      initialValues: initialCompanyValues,
      newValues: companyPayload,
      updateMethod: onUpdateOnboardingCompany,
      successCallback: (parsedResponse) => {
        const { mailingAddress, info } = getRelationshipsFromParsedResponse(parsedResponse, 'company');

        // if the vendor never had an address or business type, it just got created. The form needs the address ID for
        // subsequent PATCHes and redux-forms enableReinitialize doesn't properly the update on it's own, likely because
        // there's no <Field /> for the ID.
        const addressId = mailingAddress.data.id;
        const companyInfoId = info.data.id;

        if (addressId) {
          change('form.company.mailingAddress.id', addressId);
        }

        if (companyInfoId) {
          change('form.company.info.id', companyInfoId);
        }

        return parsedResponse;
      },
    }),
    updateIfNeeded({
      formKey: 'membership',
      initialValues: initialMembershipValues,
      newValues: membershipPayload,
      updateMethod: onUpdateOnboardingMembership,
    }),
  ]);

  const errors = mergeErrors(results);

  if (anyValues(errors)) {
    throw new SubmissionError({
      // there is also a ui object on this form, so we have to specify the errors as form value errors
      form: {
        // error nesting already matches the structure of the form
        ...errors,
      },
    });
  }

  return onSuccessOpenModal();
};

export default submitFilingUpdates;

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import {
  FETCH_ADDITIONAL_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_SUCCESS,
  FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_SUCCESS,
  SUBMIT_UNMATCHED_PLATFORM_FUNDING_ACCOUNT_SUCCESS,
} from 'types/integrations';

import { ledgerUnmatchedFundingAccountOptionsForGetObjectsWithRelationships } from './constants';

const ledgerUnmatchedLedgerFundingAccountsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_SUCCESS:
      return getObjectsByIdWithRelationships(
        action.payload.fundingAccount,
        ['bank'],
        ledgerUnmatchedFundingAccountOptionsForGetObjectsWithRelationships,
      );

    case FETCH_ADDITIONAL_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_SUCCESS:
      return deepMergeWithArrayReplacement(
        state,
        getObjectsByIdWithRelationships(
          action.payload.fundingAccount,
          ['bank'],
          ledgerUnmatchedFundingAccountOptionsForGetObjectsWithRelationships,
        ),
      );

    case SUBMIT_UNMATCHED_PLATFORM_FUNDING_ACCOUNT_SUCCESS: {
      // update selected funding account to match, does not persists - only for current session
      const { matchId } = action.payload;
      return deepMergeWithArrayReplacement(state, {
        [matchId]: { ...state[matchId], matched: true },
      });
    }

    default:
      return state;
  }
};

export default ledgerUnmatchedLedgerFundingAccountsByIdReducer;

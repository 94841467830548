import React from 'react';
import { isLessThan } from 'helpers/utility';
const useOverflow = () => {
    const [isOverflowing, setIsOverflowing] = React.useState(false);
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current) {
            const { clientWidth, scrollWidth } = ref.current;
            setIsOverflowing(isLessThan(clientWidth, scrollWidth));
        }
    }, [ref]);
    return { isOverflowing, ref };
};
export default useOverflow;

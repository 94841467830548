import React from 'react';
import { TableCellTextWithSubtext } from 'complexComponents/Table';
import { TitleWithDetails, TitleWithSubtitle } from 'components';
import { typography } from 'constants/styles';
import { getMembershipFullName, getMembershipPhoneNumber } from 'helpers/memberships';
const ContactTitleWithSubtitle = ({ dataFullStory, isContactListItem, partnershipMember, }) => {
    const { email } = partnershipMember;
    let title = getMembershipFullName(partnershipMember);
    let subtitleText = email;
    if (!title && !email && isContactListItem) {
        subtitleText = getMembershipPhoneNumber(partnershipMember);
    }
    else if (title && !email) {
        subtitleText = '(No email added)';
    }
    if (!title && !email) {
        title = '(No name/email added)';
    }
    else if (!title) {
        title = '(No name added)';
    }
    if (isContactListItem) {
        return (React.createElement(TitleWithSubtitle, { dataFullStory: dataFullStory, subtitles: [subtitleText], titles: [React.createElement(TitleWithDetails, { dataFullStory: dataFullStory, key: "unique", main: title })] }));
    }
    return (React.createElement(TableCellTextWithSubtext, { subtext: subtitleText, text: React.createElement(TitleWithDetails, { dataFullStory: dataFullStory, main: title, mainProps: { color: typography.TextColor.BLUE_DARK } }) }));
};
export default ContactTitleWithSubtitle;

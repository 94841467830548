import PropTypes from 'prop-types';
import React from 'react';

const BoxFooter = ({ children }) => (
  <div className="box--footer">
    <div className="display--table-cell vertical-align--middle">{children}</div>
  </div>
);

BoxFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BoxFooter;

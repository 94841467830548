import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2, ModalBodyContent, ModalFooter, Text, WhiteSpace } from 'components';

import { ButtonSize } from 'constants/button';
import { typography } from 'constants/styles';
import { Intent } from 'constants/ui';

import { S3_URL } from 'services/api';

/**
 * Body content for export csv modal
 * @param {ComponentProps} props
 * @param {Function} props.onCloseModal
 * @return {StatelessComponent}
 */
const ExportCsvModalBody = ({ onCloseModal }) => (
  <>
    <ModalBodyContent>
      <div className="csv-modal--body">
        <img alt="Export CSV" className="csv-modal--image" src={`${S3_URL}/images/export/export-csv-modal.svg`} />

        <Text.Bold
          className="margin-top--m margin-bottom--xm"
          color={typography.TextColor.GREY_XX_DARK}
          size={typography.TextSize.LEVEL_400}
        >
          We&apos;re exporting your data now...
        </Text.Bold>
        <Text.Regular className="margin-bottom--m text-align--center">
          We&apos;ll email you a link to download your file as soon as it&apos;s ready.
          <WhiteSpace />
          This usually takes under a minute, but can take up to 3 hours depending on the size of your export.
        </Text.Regular>
      </div>
    </ModalBodyContent>

    <ModalFooter>
      <ButtonV2 className="hover--danger" intent={Intent.NEUTRAL} onClick={onCloseModal} size={ButtonSize.MEDIUM}>
        Close
      </ButtonV2>
    </ModalFooter>
  </>
);

ExportCsvModalBody.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
};

export default ExportCsvModalBody;

import PropTypes from 'prop-types';
import React from 'react';

import { textHelpers } from 'components/hintTypes/helpers';

import { Intent } from 'constants/ui';

import { doesPartnershipAlreadyExist } from 'helpers/partnerships';
import { shouldShowMatchCompanyHint } from 'helpers/ui';

import BaseHint from '../BaseHint';

const ExistingPartnerHint = (props) => {
  const { formUI, hasLedgerIntegration, ledgerSettings, partnershipType } = props;
  const { partnershipSubmitted, selectedCompany } = formUI;

  if (
    !doesPartnershipAlreadyExist({
      partnership: selectedCompany,
      partnershipSubmitted,
    })
  ) {
    return null;
  }

  if (
    shouldShowMatchCompanyHint({
      partnership: selectedCompany,
      partnershipType,
      hasLedgerIntegration,
      ledgerSettings,
    })
  ) {
    return null;
  }

  return (
    <BaseHint
      intent={Intent.DANGER}
      multiline
      text={textHelpers.getExistingPartnerHintText(selectedCompany.name, partnershipType)}
      title={textHelpers.getExistingPartnerHintTitle(partnershipType)}
    />
  );
};

ExistingPartnerHint.propTypes = {
  formUI: PropTypes.shape().isRequired,
  hasLedgerIntegration: PropTypes.bool.isRequired,
  ledgerSettings: PropTypes.shape(),
  partnershipType: PropTypes.string.isRequired,
};

ExistingPartnerHint.defaultProps = {
  ledgerSettings: undefined,
};

export default ExistingPartnerHint;

import * as types from 'types/externalOnboarding';

export const initialStep = 0;

// Reducer
const externalOnboardingStepReducer = (state = initialStep, action) => {
  switch (action.type) {
    case types.EXTERNAL_ONBOARDING_CHANGE_STEP:
      return action.payload.step;

    case types.EXTERNAL_ONBOARDING_DECREMENT_STEP:
      return state - 1;

    case types.EXTERNAL_ONBOARDING_INCREMENT_STEP:
      return state + 1;

    default:
      return state;
  }
};

export default externalOnboardingStepReducer;

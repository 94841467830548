import { createErrorReducer } from 'store/redux';

import {
  CLEAR_FUNDING_ACCOUNT_ADDRESS_MANUAL,
  SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_FAILURE,
  SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_REQUEST,
  SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_SUCCESS,
} from 'types/funding';

export const failureCases = [SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_FAILURE];
export const successCases = [
  CLEAR_FUNDING_ACCOUNT_ADDRESS_MANUAL,
  SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_REQUEST,
  SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_SUCCESS,
];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

import dayjs from 'dayjs';
import { DateFormats } from '../models';
export const formatDate = (dateObj, format = DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY) => dayjs(dateObj).format(format);
export const formatDateString = (dateStr, format = DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY) => formatDate(dayjs(dateStr), format);
export const formatValidDateString = (dateStr, format = DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY) => {
    const dayjsDate = dayjs(dateStr);
    if (dayjsDate.isValid()) {
        return formatDate(dayjsDate, format);
    }
    return undefined;
};
export const toIsoString = (fromDateInitializer) => {
    const initDate = fromDateInitializer || Date.now();
    return new Date(initDate).toISOString();
};

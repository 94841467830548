import PropTypes from 'prop-types';
import React from 'react';

import InputField from 'components/input';

import { getFieldErrors } from 'helpers/errors';

/* eslint-disable react/prefer-stateless-function */
class DatePickerCustomInput extends React.Component {
  render() {
    const { errors, id, input, meta, name, onBlur, ...rest } = this.props;

    let fieldErrors;
    let hasErrors;
    let inputName;
    let handleOnBlur;

    if (input) {
      // This is a redux-form input
      inputName = input.name;
      fieldErrors = (meta.touched || meta.submitFailed) && meta.error;
      hasErrors = !!fieldErrors;
      handleOnBlur = () => input.onBlur(undefined, true);
    } else {
      // This is not a redux-form input
      inputName = name;
      fieldErrors = errors;
      hasErrors = !!getFieldErrors(errors, inputName);
      handleOnBlur = onBlur;
    }

    return (
      <InputField errors={fieldErrors} hasErrors={hasErrors} id={id} name={inputName} onBlur={handleOnBlur} {...rest} />
    );
  }
}

DatePickerCustomInput.propTypes = {
  addon: PropTypes.node,
  autoFocus: PropTypes.bool,
  isDisabled: PropTypes.bool,
  errors: PropTypes.shape(),
  hideLabel: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  input: PropTypes.shape(),
  meta: PropTypes.shape(),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  value: PropTypes.string,
};

DatePickerCustomInput.defaultProps = {
  addon: undefined,
  autoFocus: undefined,
  errors: {},
  hideLabel: undefined,
  label: undefined,
  id: undefined,
  input: undefined,
  isDisabled: undefined,
  isRequired: undefined,
  meta: undefined,
  name: '',
  onBlur: () => {},
  onClick: () => {},
  placeholder: '',
  value: '',
};

export default DatePickerCustomInput;

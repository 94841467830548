import { createSelector } from 'reselect';

import { currentMembershipSelector, membershipsByIdSelector } from 'selectors/membershipsSelector';
import { partnershipRequestFromLocationSelector } from 'selectors/partnershipRequestSelectors';

/**
 * Returns membership that belongs to the current partnership request from location
 * param
 * @type {StandardSelector}
 * @param {ReduxState} state - Redux state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @return {Membership}
 */
export const membershipFromPartnershipRequestSelector = createSelector(
  [membershipsByIdSelector, partnershipRequestFromLocationSelector],
  (memberships, partnershipRequest) => memberships[partnershipRequest?.membership],
);

/**
 * Returns the membership roleId associated with the current partnership request
 * param
 * @type {StandardSelector}
 * @param {ReduxState} state - Redux state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @return {Membership}
 */
export const membershipRoleIdFromPartnershipRequestSelector = createSelector(
  [membershipFromPartnershipRequestSelector],
  (membership) => membership?.role,
);

/**
 * Returns current membership or membership from current partnership request if the
 * current path is ExternalV2 path. Since partnershipRequestMembership is only present
 * in ExternalV2 flow, we can simply return it if it exists or return currentMembership
 * otherwise
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @return {Membership}
 */
export const currentMembershipOrMembershipFromPartnershipRequestSelector = createSelector(
  [currentMembershipSelector, membershipFromPartnershipRequestSelector],
  (currentMembership, partnershipRequestMembership) => partnershipRequestMembership || currentMembership,
);

import { ItemStatuses } from 'enums/items';

import { isItemKindPayable } from 'helpers/items';
import { isPaymentDeliveryMethodAny } from 'helpers/paymentMethods';

import { fundingAccountForIdSelector } from 'selectors/fundingSelectors';
import { partnershipsFundingAccountForIdSelector } from 'selectors/partnershipsSelectors';

import { storeAccessor } from 'store/accessor';

import { dateScheduledForCreateItem } from './item';

/**
 * Returns a payload for updating an existing item, via the finalize item flow.
 * @param {ReduxFormValues} values
 * @param {ComponentProps} props
 * @return {Object}
 */
export const existingItemForFinalizeItem = (values, props) => {
  const { item } = props;

  const { dateScheduled, paymentDeliveryMethod, paymentDeliveryMethodsAccepted, partnerReceivableAccount, ...rest } =
    values.form;

  const { sendItem } = values.ui;

  // We don't want the currency code in the payload
  delete rest.currencyCode;

  const payload = {
    ...rest,
    ...dateScheduledForCreateItem({
      item: { dateScheduled },
      ui: { sendItem },
    }),
  };

  const isPayable = isItemKindPayable(item);
  const isDeliveryMethodAny = isPaymentDeliveryMethodAny(paymentDeliveryMethod);

  if (!sendItem) {
    payload.status = ItemStatuses.READY_TO_SEND;
  }

  payload.version = item.version;

  if (isPayable) {
    if (isDeliveryMethodAny) {
      payload.paymentDeliveryMethodsAccepted = Object.keys(paymentDeliveryMethodsAccepted).filter(
        (method) => paymentDeliveryMethodsAccepted[method],
      );

      // if we previously set a delivery option but are changing to 'any' method,
      // need to clear the old delivery option with `null`
      if (item.paymentDeliveryOption) {
        payload.paymentDeliveryOption = null;
      }

      // if we previously set a partner funding account but are changing to 'any'
      // method, need to clear the old partner funding account with `{}`
      if (item.partnerFundingAccount) {
        payload.partnerReceivableAccount = {};
      }
    } else {
      payload.paymentDeliveryMethodsAccepted = [];
    }

    if (partnerReceivableAccount) {
      const state = storeAccessor.getState();

      const partnerFundingAccount = partnershipsFundingAccountForIdSelector(state, partnerReceivableAccount);

      const fundingAccount = fundingAccountForIdSelector(state, partnerFundingAccount.fundingAccount);

      payload.partnerReceivableAccount = {
        id: fundingAccount.id,
      };
    } else if (item.partnerFundingAccount && partnerReceivableAccount === null) {
      payload.partnerReceivableAccount = {};
    }

    payload.swiftChargeOption = values.item?.swiftChargeOption;
  } else {
    payload.paymentDeliveryOption = undefined;
    payload.paymentDeliveryMethodsAccepted = undefined;
  }

  return payload;
};

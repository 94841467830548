import PropTypes from 'prop-types';
import React from 'react';

import { isGreaterThan } from 'helpers/utility';

import LoadMore from './LoadMore';

/**
 * LoadMoreLogicWrapper
 * Container-style component to wrap LoadMore with appropriate props
 * @return {FunctionComponent}
 * @constructor
 */
const LoadMoreLogicWrapper = (props) => {
  const [buttonIsVisible, setButtonIsVisible] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(false);

  const onShowLoadMore = () => {
    if (!buttonIsVisible) {
      setButtonIsVisible(true);
    }
  };

  const onClickLoadMore = () => {
    setIsExpanded(true);
    setButtonIsVisible(false);
  };

  // we need to ensure every LoadMoreChild is re-rendered when new children are added
  // that way, each child can re-calculate whether it should show itself or the LoadMore button
  // otherwise, we get a bug whereby
  //   - user has added children taking up all the allowed space of {visibleRows}
  //   - user adds a new child that is displayed in one or the allowed {visibleRows}
  //   (for eg. a tag starting with "a" in a sorted list)
  //   - adding that child causes the last child in the list to be pushed to a new row
  //   without re-calculating whether it should switch with the LoadMore button
  const { children } = props;
  const [reRenderCount, setReRenderCount] = React.useState(children.length);
  React.useEffect(() => {
    if (isGreaterThan(children.length, 0)) {
      setReRenderCount(children.length);
    }
  }, [children.length]); // update each time a child is added

  const stateProps = {
    isExpanded,
    onShowLoadMore,
    onClickLoadMore,
    buttonIsVisible,
    reRenderCount,
  };

  return <LoadMore {...props} {...stateProps} />;
};

LoadMoreLogicWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  visibleRows: PropTypes.number.isRequired,
};

export default LoadMoreLogicWrapper;

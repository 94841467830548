import { connect } from 'react-redux';

import { createItemFormSelector } from 'selectors/forms';
import { hasLedgerIntegrationSelector } from 'selectors/integrationsSelectors';
import { ledgerSettingsSelector } from 'selectors/ledgerInfoSelectors';

import ExistingPartnerHint from './ExistingPartnerHint';

const mapStateToProps = (state) => ({
  formUI: createItemFormSelector(state, 'ui'),
  partnershipType: createItemFormSelector(state, 'partnershipType'),
  hasLedgerIntegration: hasLedgerIntegrationSelector(state),
  ledgerSettings: ledgerSettingsSelector(state),
});

export default connect(mapStateToProps)(ExistingPartnerHint);

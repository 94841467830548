import { Button, Dialog } from '@routable/gross-ds';
import React from 'react';
export const TableExportDialog = ({ emailAddress, onExport, isExporting, state }) => {
    return (React.createElement(Dialog, { footer: React.createElement(Dialog.Footer, null,
            React.createElement(Button, { "data-testid": "csv-export--button--cancel-export", disabled: isExporting, intent: "critical", onPress: state.close, size: "small", variant: "outline" }, "Cancel export"),
            React.createElement(Button, { "data-testid": "csv-export--button--confirm-export", intent: "primary", loading: isExporting, onPress: onExport, size: "small", variant: "filled" }, "Confirm")), header: React.createElement(Dialog.Header, { label: "Confirm your CSV export" }), state: state },
        React.createElement("div", { className: "flex flex-col gap-2" },
            React.createElement("span", null,
                "We'll send you an email to ",
                emailAddress,
                " as soon as your file is ready to download."),
            React.createElement("span", { className: "font-bold" }, "This export may contain sensitive financial information"),
            React.createElement("span", null, "Due to the sensitive nature of this export, we recommend taking precautions when storing, sending, and uploading this file."))));
};

import React from 'react';

import { addSortTypeToColumns, defaultTableSort } from './helpers/sort';
import Table from './Table';
import { tableDefaultProps, tablePropTypes } from './Table.propTypes';

/**
 * Container used for adding the sort type to sortable columns
 * @param {ComponentProps} props
 * @param {Object[]} props.columns
 * @param {RestOfProps} rest
 * @returns {StatelessComponent}
 */
const TableContainer = ({ columns, ...rest }) => {
  const columnsWithSortType = addSortTypeToColumns(columns, defaultTableSort);

  return <Table columns={columnsWithSortType} {...rest} />;
};

TableContainer.propTypes = tablePropTypes;

TableContainer.defaultProps = tableDefaultProps;

export default TableContainer;

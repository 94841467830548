import { commonFormFields } from '@routable/shared';
import useCurrencyContext from 'hooks/useCurrencyContext';
import useFieldValue from 'hooks/useFieldValue';
export const useFormSelectedCurrency = (formName) => {
    const selectedCurrencyCode = useFieldValue(formName, commonFormFields.CURRENCY_CODE);
    const currencies = useCurrencyContext();
    return {
        name: currencies?.[selectedCurrencyCode]?.name,
        code: selectedCurrencyCode,
    };
};

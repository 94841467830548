import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';

import { ButtonSize } from 'constants/button';
import { Intent } from 'constants/ui';

import { getNumberEntitiesToLoadOnNext } from 'helpers/pagination';

/**
 * Ghost link button that displays the text 'Load more'. If props.showCount
 * is true, displays 'Load N more', N being the smaller of either the number
 * of items left to load, or the page size (both derived from a pagination object).
 * Displays props.loadingText while loading (defaults to 'Loading...').
 * @param {ComponentProps} props
 * @return {null|StatelessComponent}
 * @constructor
 */
const LoadMoreButton = (props) => {
  const { className, isFetching, loadingText, onClick, pagination, showCount, ...rest } = props;

  // hide if there's no next url
  if (!pagination.next) {
    return null;
  }

  let buttonText;

  if (isFetching) {
    buttonText = loadingText;
  } else if (showCount) {
    const numToLoad = getNumberEntitiesToLoadOnNext(pagination);
    buttonText = `Load ${numToLoad} more`;
  } else {
    buttonText = 'Load more';
  }

  return (
    <ButtonV2
      className={className}
      intent={Intent.NEUTRAL}
      isDisabled={isFetching}
      onClick={onClick}
      size={ButtonSize.SMALL}
      {...rest}
    >
      {buttonText}
    </ButtonV2>
  );
};

LoadMoreButton.propTypes = {
  className: PropTypes.string,
  isFetching: PropTypes.bool,
  loadingText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  pagination: PropTypes.shape().isRequired,
  showCount: PropTypes.bool,
};

LoadMoreButton.defaultProps = {
  className: undefined,
  isFetching: undefined,
  loadingText: 'Loading...',
  showCount: undefined,
};

export default LoadMoreButton;

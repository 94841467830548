import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';

import { DASHBOARD, PAYMENTS_LIST_FILTERS, SELECTED_COMPANY_TABS } from 'constants/routes';

import { getJoinedPath } from 'helpers/routeHelpers';

/**
 * Component to redirect to a thread.
 *
 * @param itemId {string}
 * @param type {ItemKind}
 * @returns {*}
 * @constructor
 */
const RedirectToThread = ({ itemId, type }) => (
  <Redirect to={getJoinedPath(DASHBOARD, SELECTED_COMPANY_TABS.PAYMENTS, type, PAYMENTS_LIST_FILTERS.ALL, itemId)} />
);

RedirectToThread.propTypes = {
  itemId: PropTypes.string,
  type: PropTypes.string.isRequired,
};

RedirectToThread.defaultProps = {
  itemId: undefined,
};

export default RedirectToThread;

import { apiCall, convertToSnakeCase } from '@routable/framework';
import { itemsApprovalsSummaryApiModel } from '../../models';
export const approvalsSummaryUrl = 'approvals/summary/';
export const itemApprovalsSummaryApi = {
    itemsSummary: async (data) => apiCall({
        url: `${approvalsSummaryUrl}items/`,
        method: 'post',
        responseModel: itemsApprovalsSummaryApiModel,
        camelCaseResponse: true,
        body: await convertToSnakeCase({ objectIds: data }),
    }),
};

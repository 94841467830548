import * as routines from 'actions/routines/currencies';

import { createErrorReducer } from 'store/redux';

export const failureCases = [routines.fetchCurrenciesRoutine.FAILURE];

export const successCases = [routines.fetchCurrenciesRoutine.REQUEST, routines.fetchCurrenciesRoutine.SUCCESS];

const errorsReducer = createErrorReducer(successCases, failureCases);

export default errorsReducer;

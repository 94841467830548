import { FETCH_MEMBERSHIPS_SUCCESS } from 'types/memberships';

import { initialState } from './initialState';

const dashboardLoadedMembershipsReducer = (state = initialState.memberships, action) => {
  switch (action.type) {
    case FETCH_MEMBERSHIPS_SUCCESS:
      return true;

    default:
      return state;
  }
};

export default dashboardLoadedMembershipsReducer;

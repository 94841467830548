import PropTypes from 'prop-types';
import React from 'react';

import ProgressBar from 'components/progressBar/ProgressBar';
import { WhiteSpace } from 'components/text';

import { isGreaterThan } from 'helpers/utility';

/**
 * Custom loader with steps to be used when submitting or updating an item.
 * @param {ComponentProps} props
 * @param {number} props.lastStep
 * @param {array} props.steps
 * @param {string} props.title
 * @param {string} props.titleIcon
 * @return {StatelessComponent}
 */
const ItemSubmittingLoader = ({ lastStep, steps, title, titleIcon }) => {
  const timer = React.useRef();
  const [step, updateStep] = React.useState(0);

  React.useEffect(() => {
    timer.current = setInterval(() => {
      updateStep((s) => s + 1);
    }, 1300);

    return () => clearInterval(timer.current);
  }, []);

  React.useEffect(() => {
    if (isGreaterThan(step, lastStep)) {
      clearInterval(timer.current);
    }
  }, [lastStep, step]);

  return (
    <div className="create-item--body absolute bg-white z-[250]">
      <div className="align-center">
        <div className="loading--container">
          <h3 className="font-color--greyXDark">
            {title}
            <WhiteSpace />
            <span aria-label="money" role="img">
              {titleIcon}
            </span>
          </h3>

          <div className="loading--progress-container">
            <ProgressBar currentStepPosition={step} steps={steps} />
          </div>
        </div>
      </div>
    </div>
  );
};

ItemSubmittingLoader.propTypes = {
  lastStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      num: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.string,
};

ItemSubmittingLoader.defaultProps = {
  titleIcon: undefined,
};

export default ItemSubmittingLoader;

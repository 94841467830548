import _cloneDeep from 'lodash/cloneDeep';

/**
 * Submit transformer which adds an acknowledgement phrase in the case that we need to confirm deactivation.
 * @param {OptionsArg} options.
 * @param {StringMaybe} [options.acknowledge]
 * @param {Membership} options.membership
 * @returns {Object}
 */
export const maybeAcknowledgeMembershipDeactivation = ({ acknowledge, membership }) => {
  const payload = _cloneDeep(membership);

  /**
   * The endpoint using this transformer can be called twice: first to deactivate a RCTM and second to confirm the
   * deactivation. Confirmation isn't always required. Sometimes the BE has no issues deactivating a RCTM with the first
   * submission.
   *
   * When it might need confirmation: disabling a RCTM would result in an item getting "stuck" because you're
   * removing the item's only approver. The BE returns an "acknowledge" key after the first failed deactivation attempt.
   * When the user confirms they understands the ramifications of such a deactivation, we add the acknowledge key to the
   * meta payload for the second confirm deactivation call.
   */
  if (acknowledge) {
    payload.meta = {
      // preserve the original meta, if it exists
      ...membership.meta,
      [acknowledge]: true,
    };
  }

  return payload;
};

/**
 * Submit transformer which removes the user data if sending it is not allowed
 * @param {OptionsArg} options
 * @param {Object} options.form
 * @param {Object} options.meta
 * @returns {Object}
 */
export const removeUserDataWhenNotAllowed = ({ form, meta }) => {
  const formData = { ...form };

  if (meta.removeUserData) {
    delete formData.user;
  }

  return formData;
};

import { FetchService } from 'services';
import {
  getNotificationSettingsEndpoint,
  getNotificationTableDataEndpoint,
  modifyEmailForwardEndpoint,
} from 'services/api/notificationEndpoints';

/**
 * Fetch notification settings.
 * @param {string} notificationSettingsId
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchNotificationSettings = async (notificationSettingsId, options = {}) => {
  const config = {
    ...options,
    endpoint: getNotificationSettingsEndpoint(notificationSettingsId),
    method: 'GET',
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * Fetch notification table data.
 * @param {string} notificationSettingsId
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchNotificationTableData = async (notificationSettingsId, options = {}) => {
  const config = {
    ...options,
    endpoint: getNotificationTableDataEndpoint(notificationSettingsId),
    method: 'GET',
    requireAuth: true,
    transformers: [],
  };
  return FetchService.request(config);
};

/**
 * Delete a notifications email forward.
 * @param {string} notificationSettingsId
 * @param {string} emailForwardId
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const deleteEmailForward = async (notificationSettingsId, emailForwardId, options = {}) => {
  const config = {
    ...options,
    endpoint: modifyEmailForwardEndpoint(notificationSettingsId, emailForwardId),
    method: 'DELETE',
    requireAuth: true,
  };
  return FetchService.request(config);
};

import PropTypes from 'prop-types';
import React from 'react';

import { Hint, Text } from 'components';

import { typography } from 'constants/styles';
import { Intent } from 'constants/ui';

import './ExternalErrorHint.scss';

/**
 * Base error hint component used in external flow
 * @param {ComponentProps} props
 * @param {Node} props.children
 * @param {Node|string} props.hintTitle
 * @return {StatelessComponent}
 */
const ExternalErrorHint = ({ children, hintTitle }) => (
  <Hint className="external-error-hint" intent={Intent.DANGER}>
    <Text.Bold color={typography.TextColor.RED_BOLD} size={typography.TextSize.LEVEL_200}>
      {hintTitle}
    </Text.Bold>
    <Text.Regular color={typography.TextColor.BLACK} size={typography.TextSize.LEVEL_200}>
      {children}
    </Text.Regular>
  </Hint>
);

ExternalErrorHint.propTypes = {
  children: PropTypes.node.isRequired,
  hintTitle: PropTypes.string.isRequired,
};

export default ExternalErrorHint;

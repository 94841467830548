import { uploadBulkImportFileRoutine } from 'actions/routines/bulkActions';

import { createAllIdsReducer } from 'store/redux';

const watchingIdsReducer = createAllIdsReducer({
  key: 'bulkImport',
  types: [uploadBulkImportFileRoutine.SUCCESS],
});

export default watchingIdsReducer;

/**
 * Checks a list of requirements using given selectors and target values.
 * @param state {ReduxState}
 * @param requirements {[[Function, *]]}
 * @returns {*}
 */
const areAccessRequirementsMet = (state, requirements) =>
  Array.isArray(requirements) &&
  requirements.reduce((prevResult, requirement) => {
    const selector = requirement[0];
    const targetValue = requirement[1];
    return selector(state) === targetValue && prevResult;
  }, true);

export default areAccessRequirementsMet;

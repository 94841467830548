import { Tooltip } from '@routable/gross-ds';
import clsx from 'clsx';
import _debounce from 'lodash/debounce';
import React, { useEffect, useRef, useState } from 'react';
const setTextClampedOnSizeChange = (entries, setTextClamped, breakBasedOn = 'words') => {
    const { target } = entries[0];
    if (target.clientWidth === 0) {
        return;
    }
    const wordBreak = breakBasedOn === 'words' ? 'break-words' : 'break-all';
    const { width } = target.getBoundingClientRect();
    const targetClone = target.cloneNode(true);
    targetClone.style.cssText = `position:fixed;top:-99999px;left:-99999px;overflow:auto;visibility:hidden;pointer-events:none;width:${width}px;word-break:${wordBreak};`;
    ['line-height', 'font-size'].forEach((property) => {
        const styleMap = target.computedStyleMap?.();
        if (styleMap?.has(property)) {
            const { value, unit } = styleMap.get(property);
            targetClone.style.cssText += `${property}: ${value}${unit};`;
        }
    });
    targetClone.className = '';
    document.body.append(targetClone);
    const { height: cloneHeight } = targetClone.getBoundingClientRect();
    const hasOverflow = cloneHeight > target.clientHeight;
    setTextClamped(hasOverflow);
    targetClone.remove();
};
export const TruncatableTextMultiLineResponsive = ({ className, dataFullStory, maxNumberOfLines = 2, position = 'bottom', text, variant = 'light', breakBasedOn = 'words', }) => {
    const ref = useRef(null);
    const [textClamped, setTextClamped] = useState(false);
    useEffect(() => {
        const debouncedSetTextClampedOnSizeChange = _debounce((entries) => setTextClampedOnSizeChange(entries, setTextClamped), 600);
        const observer = new ResizeObserver(debouncedSetTextClampedOnSizeChange);
        const element = ref.current;
        if (element) {
            observer.observe(element);
        }
        return () => {
            if (element) {
                observer.unobserve(element);
            }
            observer.disconnect();
        };
    }, [textClamped]);
    return (React.createElement(Tooltip, { "data-testid": "truncated-text-tooltip", openDelay: 400, position: position, tooltip: textClamped ? text : null, variant: variant },
        React.createElement("div", { className: clsx([
                `line-clamp-${maxNumberOfLines}`,
                breakBasedOn === 'words' ? 'break-words' : 'break-all',
                className,
            ]), "data-fullstory": dataFullStory, "data-testid": "truncatable-text--multiline", ref: ref }, text)));
};

import merge from 'deepmerge';

import { IntegrationServiceTypes, IntegrationSyncingStatusTypes } from 'constants/integration';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';
import { allValues } from 'helpers/utility';

import {
  DISCONNECT_INTEGRATION_CONFIG_REQUEST,
  DISCONNECT_INTEGRATION_CONFIG_SUCCESS,
  FETCH_INTEGRATION_CONFIGS_SUCCESS,
  FETCH_INTEGRATION_CONFIG_SUCCESS,
  REFRESH_INTEGRATION_CONFIG_REQUEST,
  REFRESH_INTEGRATION_CONFIG_SUCCESS,
} from 'types/integrations';
import { LEDGER_DISCONNECTED, LEDGER_SYNC_START } from 'types/ledger';

export const initialAttributes = {
  isDisconnecting: false,
  isRefreshing: false,
  syncingStatus: IntegrationSyncingStatusTypes.NO,
};

const integrationConfigsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INTEGRATION_CONFIG_SUCCESS:
    case FETCH_INTEGRATION_CONFIGS_SUCCESS:
      return deepMergeWithArrayReplacement(
        state,
        getObjectsByIdWithRelationships(action.payload.integrationConfig, ['integration'], { initialAttributes }),
      );

    case REFRESH_INTEGRATION_CONFIG_REQUEST:
      return merge(state, { [action.payload.id]: { isRefreshing: true } });

    case REFRESH_INTEGRATION_CONFIG_SUCCESS:
      return merge(state, { [action.payload.id]: { isRefreshing: false } });

    case DISCONNECT_INTEGRATION_CONFIG_REQUEST:
      return merge(state, { [action.payload.id]: { isDisconnecting: true } });

    case DISCONNECT_INTEGRATION_CONFIG_SUCCESS:
      return merge(state, { [action.payload.id]: { isDisconnecting: false } });

    case LEDGER_SYNC_START:
      return merge(state, {
        [action.payload.id]: {
          syncingStatus: IntegrationSyncingStatusTypes.MANUAL_SYNC,
        },
      });

    // Disconnect ledger on a ledger error
    case LEDGER_DISCONNECTED: {
      let ledger = null;
      allValues(state).forEach((integrationConfig) => {
        if (integrationConfig.service === IntegrationServiceTypes.SERVICE_LEDGER) {
          ledger = { ...integrationConfig, connected: false };
        }
      });

      if (ledger) {
        return deepMergeWithArrayReplacement(state, { [ledger.id]: ledger });
      }

      return state;
    }

    default:
      return state;
  }
};

export default integrationConfigsByIdReducer;

import { getLedgerItemFilter, getLineItemOptionItemText } from 'helpers/lineItems';
import { sortObjectArray } from 'helpers/sort';
import { allValues, isEqual } from 'helpers/utility';

/**
 * Helper method to transform ledger item selector data
 * This will help testability and simplicity in the selector code
 * @param {Object} ledgerItems
 * @param {string} application
 * @param {string} itemKind
 * @returns {AugmentedLedgerItem[]}
 */
export const transformLedgerItemSelectorData = ({ ledgerItems, application, itemKind }) => {
  const filterItems = getLedgerItemFilter(application, itemKind);
  const mapItemText = (item) => ({
    text: getLineItemOptionItemText(item),
    ...item,
  });

  const augmentedLedgerItems = allValues(ledgerItems).filter(filterItems).map(mapItemText);

  return sortObjectArray(augmentedLedgerItems, 'text');
};

/**
 * Returns the ledgerTaxCode matching a given id
 * @param {LedgerTaxCode[]} allLedgerTaxCodes
 * @param {string} ledgerTaxCodeId
 * @param {LedgerTaxCode}
 */
export const getLedgerTaxCodeById = ({ allLedgerTaxCodes, ledgerTaxCodeId }) =>
  allLedgerTaxCodes.find((ledgerTaxCodeEl) => isEqual(ledgerTaxCodeEl.id, ledgerTaxCodeId));

/**
 * Returns the formatted name for a given ledgerTaxCode
 * @param {string} name
 * @param {string} taxRate
 * @returns {string}
 */
export const getLedgerTaxCodeNameText = ({ name, taxRate }) => `${name}: ${taxRate}%`;

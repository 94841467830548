import { getCurrentMembershipId } from 'helpers/localStorage';
import {} from 'types/quickswitch';
import { modalContentHeight, modalContentWidth } from './constants';
export const generateModalStyleOverride = (showSearchBar) => {
    if (showSearchBar) {
        return {
            maxHeight: modalContentHeight,
            height: modalContentHeight,
            width: modalContentWidth,
        };
    }
    return { maxHeight: modalContentHeight, width: modalContentWidth };
};
export const removeCurrentMembershipFromListAndSortByName = (memberships) => {
    const currentMembershipId = getCurrentMembershipId();
    return memberships
        .filter((membership) => membership.id !== currentMembershipId)
        .sort((membershipA, membershipB) => membershipA.companyName.localeCompare(membershipB.companyName));
};

import { combineReducers } from 'redux';

import allReducer from './allReducer';
import byIdReducer from './byIdReducer';
import isFetchingReducer from './isFetchingReducer';
import lastFetchedReducer from './lastFetchedReducer';

const affiliatesReducer = combineReducers({
  allIds: allReducer,
  byId: byIdReducer,
  isFetching: isFetchingReducer,
  lastFetched: lastFetchedReducer,
});

export default affiliatesReducer;

export const FETCH_EXTERNAL_CONFIG = '@external/FETCH_EXTERNAL_CONFIG';

export const FETCH_EXTERNAL_PARTNERSHIP_REQUEST = '@external/FETCH_EXTERNAL_PARTNERSHIP_REQUEST';

export const SUBMIT_EXTERNAL_FUNDING_STATE = '@external/SUBMIT_EXTERNAL_FUNDING_STATE';

export const SUBMIT_EXTERNAL_PARTNER_COMPANY_CONTACT_INFO = '@external/SUBMIT_EXTERNAL_PARTNER_COMPANY_CONTACT_INFO';

export const SUBMIT_EXTERNAL_PARTNER_COMPANY_GENERAL_INFO = '@external/SUBMIT_EXTERNAL_PARTNER_COMPANY_GENERAL_INFO';

export const SUBMIT_EXTERNAL_TERMS_OF_SERVICE = '@external/SUBMIT_EXTERNAL_TERMS_OF_SERVICE';

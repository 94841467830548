import { createSelector } from 'reselect';

const getState = (state) => state.maintenanceMode;

/**
 * Determine if the system is currently in maintenance mode.
 * @param {ReduxState} state - Redux state
 * @returns {boolean} - True/false = on/off
 */
export const maintenanceModeSelector = createSelector([getState], (state) => state.on);

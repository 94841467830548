import { combineReducers } from 'redux';

import allCountriesReducer from './allCountriesReducer';
import byCountryReducer from './byCountryReducer';
import errorsReducer from './errorsReducer';
import isFetchingReducer from './isFetchingReducer';
import lastFetchedReducer from './lastFetchedReducer';

const countriesRootReducer = combineReducers({
  allCountries: allCountriesReducer,
  byCountry: byCountryReducer,
  errors: errorsReducer,
  isFetching: isFetchingReducer,
  lastFetched: lastFetchedReducer,
});

export default countriesRootReducer;

import styled from 'styled-components';
export const Container = styled.div `
  background-color: var(--table-footer-bg-color);
  display: block;
  pointer-events: initial;
  touch-action: initial;
  cursor: default;
  height: 40px;
  border-top: 1px solid var(--color-grey20);
`;
export const Content = styled.div `
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  margin-top: -2px;
`;
export const RecordCount = styled.div `
  position: relative;
  display: inline-block;
  border-radius: 16px;
  background-color: var(--color-grey20);
  margin-left: 6px;

  height: 16px;
  top: 2px;

  & .show-more-record-count {
    display: block;
    color: var(--color-grey70);
    font-size: 11px;
    position: relative;
    padding: 4px;
    padding-left: 6px;
    padding-right: 6px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

import { getModalReducerOpenAction } from 'store/redux';

import * as types from 'types/clearingAccounts';

export const initialState = {
  open: false,
  partnershipId: null,
};

// Reducer
const matchClearingAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MODAL_MATCH_CLEARING_ACCOUNT_CLOSE:
      return initialState;

    case types.MODAL_MATCH_CLEARING_ACCOUNT_OPEN:
      return getModalReducerOpenAction(state, action);

    default:
      return state;
  }
};

export default matchClearingAccountsReducer;

import dayjs from 'dayjs';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTaxFormOptions } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.context';
import { taxCertificationType, taxFormEntryType, } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.models';
import { PenaltiesOfPerjuryHint } from 'components';
import { FormCertification } from 'constants/taxes';
import { isCompanyTypePersonal } from 'helpers/currentCompany';
import RenderDatePicker from '../RenderDatePicker';
import RenderFileUpload from '../RenderFileUpload';
import RenderRadioGroup from '../RenderRadioGroup';
import RenderTextInput from '../RenderTextInput';
import { Group, GroupSlot } from '../styles';
import { getTaxFormName, isEligibleForCertificationTypeSelect, translateFormTypeToTaxDoc, } from './FieldGroupFormCertification.helpers';
import { Divider, InstructionsTextPdfUpload, InstructionsTextSignatureWarning, } from './FieldGroupFormCertification.styles';
const FieldGroupFormCertification = () => {
    const { isSignatureAllowed, entryType, formType } = useTaxFormOptions();
    const { watch } = useFormContext();
    const selectedCertificationType = watch('certificationType');
    const companyType = watch('companyType');
    const isIndividual = isCompanyTypePersonal(companyType);
    const taxFormName = getTaxFormName(formType, isIndividual);
    const taxDoc = translateFormTypeToTaxDoc(formType);
    const shouldRenderCertificationType = isEligibleForCertificationTypeSelect(formType, isIndividual, isSignatureAllowed);
    const certificationOptions = [
        {
            optionText: 'Sign electronically',
            value: taxCertificationType.Enum.signedElectronically,
        },
        {
            optionText: `Upload signed ${taxFormName} PDF`,
            value: taxCertificationType.Enum.uploadSignedPDF,
        },
    ];
    const completedFormMaxSignedDate = new Date(Date.now());
    const completedFormMinSignedDate = dayjs().subtract(120, 'year').toDate();
    return (React.createElement(React.Fragment, null,
        shouldRenderCertificationType && React.createElement(RenderRadioGroup, { name: "certificationType", options: certificationOptions }),
        React.createElement(Divider, null),
        selectedCertificationType === taxCertificationType.Enum.signedElectronically && (React.createElement(Group, null,
            React.createElement(GroupSlot, { size: 6 },
                React.createElement(PenaltiesOfPerjuryHint, { taxDoc: taxDoc })),
            React.createElement(GroupSlot, { size: 6 },
                React.createElement(RenderTextInput, { isRequired: true, name: "signature", placeholder: "Signature", shouldUnregister: true }),
                React.createElement(InstructionsTextSignatureWarning, null, FormCertification.SIGNATURE_WARNING_TEXT)))),
        selectedCertificationType === taxCertificationType.Enum.uploadSignedPDF && (React.createElement(Group, null,
            React.createElement(GroupSlot, { size: 6 },
                React.createElement(InstructionsTextPdfUpload, null, `Upload ${entryType === taxFormEntryType.Enum.external ? 'your' : "the vendor's"} completed and signed ${taxFormName}. We can only accept an original IRS form in PDF format.`)),
            React.createElement(GroupSlot, { size: 6 },
                React.createElement(RenderFileUpload, { name: "completedForm", shouldUnregister: true })),
            React.createElement(GroupSlot, { size: 3 },
                React.createElement(RenderDatePicker, { isRequired: true, maxDate: completedFormMaxSignedDate, minDate: completedFormMinSignedDate, name: "completedFormSignedDate", placeholder: "Date uploaded form was signed", shouldUnregister: true }))))));
};
export default FieldGroupFormCertification;

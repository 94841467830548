import { governmentIdType } from '@routable/companies-management';
import { requiredValidator } from '@routable/shared';
import React from 'react';
import { Field } from 'redux-form';
import { ReduxFormFieldRenderInput } from 'components';
import { digitsOnly } from 'helpers/fieldNormalizers';
import { EINValidator, SSNValidator } from 'helpers/fieldValidation';
import helpDocs from 'helpers/helpDocs';
import { formatAsEIN, formatAsSSN } from 'helpers/numbers';
import { useSelectorWithProps } from 'hooks';
import { MaskedField } from 'modules/signup-v3/components/MaskedField';
import { fields } from 'modules/signup-v3/fields';
import { signUpFlowFormSelector } from 'selectors/signupSelectors';
export const SignupTaxInfoTin = ({ isDisabled }) => {
    const info = useSelectorWithProps(signUpFlowFormSelector, 'info');
    const [isSsnMasked, setIsSsnMasked] = React.useState(info.hasTin);
    React.useEffect(() => {
        setIsSsnMasked(info.hasTin);
    }, [info.hasTin]);
    if (info.tinType === governmentIdType.Enum.ein) {
        return (React.createElement("div", null,
            React.createElement(Field, { baseField: fields.hasTIN, className: "mb-3", component: ReduxFormFieldRenderInput, dataFullStory: true, dataTestId: "einField", format: formatAsEIN, isDisabled: isDisabled, isLocked: isDisabled, isRequired: true, isRequiredCondition: (hasTIN) => !hasTIN, maxLength: "10", name: fields.TIN, normalize: digitsOnly, placeholder: "Business EIN", type: "text", validate: [requiredValidator, EINValidator] }),
            React.createElement("a", { className: "text-sm text-blue-40", href: helpDocs.BUSINESS_VERIFICATION_DETAILS, rel: "noreferrer", target: "_blank" }, "Where can I find my EIN letter? \u2197")));
    }
    if (info.tinType === governmentIdType.Enum.ssn) {
        return (React.createElement(MaskedField, { baseField: fields.hasTIN, component: ReduxFormFieldRenderInput, dataFullStory: true, dataTestId: "ssnField", format: formatAsSSN, isDisabled: isDisabled, isLocked: isDisabled, isMasked: isSsnMasked, isRequired: true, isRequiredCondition: (hasTIN) => !hasTIN, mask: "\u2022\u2022\u2022-\u2022\u2022-\u2022\u2022\u2022\u2022", maxLength: "11", name: fields.TIN, normalize: digitsOnly, placeholder: "Social Security Number (SSN)", setIsMasked: setIsSsnMasked, type: "text", validate: [requiredValidator, SSNValidator] }));
    }
    return null;
};

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { universalRenderPropType } from 'helpers/propTypes';

import { createFeatureFlagSelector } from 'selectors/featureFlagsSelectors';

import Choose from '../Choose';

/**
 * Given a feature flag name, selects the feature flag from state, and if it is enabled,
 * renders children. If the flag is not enabled, renders the `fallback` prop (if provided).
 * @param {ComponentProps} props
 * @param {*} props.children
 * @param {*} props.fallback
 * @param {string} props.featureFlag
 * @return {AnyComponent}
 */
const WithFeatureFlag = ({ children, fallback, featureFlag }) => {
  const flagSelector = React.useMemo(() => createFeatureFlagSelector(featureFlag), [featureFlag]);
  const flagValue = useSelector(flagSelector);

  return (
    <Choose>
      <Choose.When condition={flagValue}>{children}</Choose.When>
      <Choose.Otherwise>{fallback}</Choose.Otherwise>
    </Choose>
  );
};

WithFeatureFlag.propTypes = {
  children: universalRenderPropType,
  fallback: universalRenderPropType,
  featureFlag: PropTypes.string.isRequired,
};

WithFeatureFlag.defaultProps = {
  children: undefined,
  fallback: undefined,
};

export default WithFeatureFlag;

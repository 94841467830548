import * as types from 'types/files';

/**
 * Creates an action to request a file create an object url.
 * @param {string} url - File url
 * @param {string} name - File name
 * @return {ReduxAction}
 */
export const fetchFileForObjectUrlRequest = (url, name) => ({
  payload: { name, url },
  type: types.FETCH_FILE_FOR_OBJECT_URL_REQUEST,
});

/**
 * Creates an success action for fetching a file and creating an object url.
 * @param {string} objectUrl
 * @return {ReduxAction}
 */
export const fetchFileForObjectUrlSuccess = (objectUrl) => ({
  payload: { objectUrl },
  type: types.FETCH_FILE_FOR_OBJECT_URL_SUCCESS,
});

/**
 * Creates an failure action for fetching a file and creating an object url.
 * @param {Object} errors
 * @return {ReduxAction}
 */
export const fetchFileForObjectUrlFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_FILE_FOR_OBJECT_URL_FAILURE,
});

/**
 * Creates an action to request a file and download it.
 * @param {string} url - File url
 * @param {string} name - File name
 * @return {ReduxAction}
 */
export const fetchFileForDownloadRequest = (url, name) => ({
  payload: { name, url },
  type: types.FETCH_FILE_FOR_DOWNLOAD_REQUEST,
});

/**
 * Creates an success action for fetching & downloading a file.
 * @return {ReduxAction}
 */
export const fetchFileForDownloadSuccess = () => ({
  type: types.FETCH_FILE_FOR_DOWNLOAD_SUCCESS,
});

/**
 * Creates an failure action for fetching/downloading a file.
 * @param {Object} errors
 * @return {ReduxAction}
 */
export const fetchFileForDownloadFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_FILE_FOR_DOWNLOAD_FAILURE,
});

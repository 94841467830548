import { createSelector } from 'reselect';

import { isFetchingCurrentCompanySelector } from 'selectors/currentCompanySelectors';
import { fundingAccountsIsFetchingSelector } from 'selectors/fundingSelectors';
import { isFetchingItemSelector } from 'selectors/itemsSelectors';
import { isFetchingPartnershipSelector } from 'selectors/partnershipsSelectors';
import { isFetchingPermissionsSelector } from 'selectors/permissionsSelectors';
import { isFetchingRolesSelector } from 'selectors/rolesSelectors';

/**
 * Selects isFetching from the state
 * @param {ReduxState} state
 * @return {boolean}
 */
export const isFetchingRequirementsSelector = createSelector(
  [
    fundingAccountsIsFetchingSelector,
    isFetchingCurrentCompanySelector,
    isFetchingItemSelector,
    isFetchingPartnershipSelector,
    isFetchingPermissionsSelector,
    isFetchingRolesSelector,
  ],
  (...selectors) => selectors.some(Boolean),
);

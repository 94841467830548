import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ZIndexLayers } from 'constants/ui';

import { getZIndex } from 'helpers/zIndex';

const ModalFooter = ({ children, className, zIndex, style }) => (
  <div
    className={classNames({
      'modal-footer': true,
      [className]: !!className,
    })}
    style={{
      zIndex: getZIndex(zIndex),
      ...style,
    }}
  >
    <div className="modal-footer--container">{children}</div>
  </div>
);

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  zIndex: PropTypes.number,
};

ModalFooter.defaultProps = {
  className: undefined,
  style: undefined,
  zIndex: ZIndexLayers.MODAL.BASE,
};

export default ModalFooter;

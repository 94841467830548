import { onFormRoutingNumberChange } from 'helpers/formChanges';

export const onConnectBankRoutingNumberChange = async (values, dispatch, props, previousValues) => {
  const formPaths = {
    bankInstitutionPath: props.bankInstitutionPath || 'ui.bankInstitutionName',
    routingNumberPath: props.routingNumberPath || 'form.bankRoutingNumber',
  };

  return onFormRoutingNumberChange(values, previousValues, dispatch, props.form, formPaths);
};

import { RoutableEventsSync, useEventSync } from '@routable/framework';
import { useAddToast } from '@routable/gross-ds';
import { queryContext } from '@routable/shared';
import { useMutation } from '@tanstack/react-query';
import React, { createContext, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { purchaseOrderService } from 'hooks/purchaseOrders/purchaseOrder.service';
import { useGetBillDiscrepancies } from 'hooks/purchaseOrders/useBillDiscrepancies/useGetBillDiscrepancies.hook';
import { usePurchaseOrder } from 'hooks/purchaseOrders/usePurchaseOrder.hook';
import { usePurchaseOrderItemData } from 'hooks/purchaseOrders/usePurchaseOrderItemData.hook';
import { fetchThreadRequest } from 'actions/thread';
import { trackEvent, TrackEventName } from 'helpers/eventTracking';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import { itemSelector } from 'selectors/itemsSelectors';
export const RefreshDiscrepanciesContext = createContext(undefined);
export const RefreshDiscrepanciesProvider = ({ children, id }) => {
    const [hasRefreshBeenTriggered, setRefreshHasBeenTriggered] = useState(false);
    const [isRefreshingDiscrepancies, setIsRefreshing] = useState(false);
    const item = useSelectorWithProps(itemSelector, id);
    const purchaseOrderId = item?.purchaseOrders?.[0]?.ledgerRef;
    const { isFetching: isFetchingDiscrepancies } = useGetBillDiscrepancies({
        id: !!purchaseOrderId && id,
    });
    useEventSync('set-refreshing-discrepancies', ({ isLoading }) => {
        setIsRefreshing(isLoading);
    });
    const mutationFn = async () => {
        setIsRefreshing(true);
        setRefreshHasBeenTriggered(true);
        purchaseOrderService.refreshDiscrepancies({ id });
    };
    const { mutate: refreshDiscrepancies } = useMutation({
        context: queryContext,
        mutationFn,
        mutationKey: ['purchase_orders', 'refresh_discrepancies', id],
    });
    const onRefreshDiscrepancies = () => {
        trackEvent(TrackEventName.REFRESH_PO_MATCHING);
        refreshDiscrepancies();
    };
    return (React.createElement(RefreshDiscrepanciesContext.Provider, { value: {
            hasRefreshBeenTriggered,
            isRefreshingDiscrepancies: isFetchingDiscrepancies || isRefreshingDiscrepancies,
            onRefreshDiscrepancies,
        } }, children));
};
export const useRefreshDiscrepanciesContext = () => {
    const refreshDiscrepanciesContext = useContext(RefreshDiscrepanciesContext);
    if (!refreshDiscrepanciesContext) {
        throw new Error('useRefreshDiscrepanciesContext must be used within a RefreshDiscrepanciesProvider');
    }
    return refreshDiscrepanciesContext;
};
export const useHandleRefreshDiscrepancies = ({ id }) => {
    const addToast = useAddToast();
    const dispatch = useDispatch();
    const item = useSelectorWithProps(itemSelector, id);
    const purchaseOrderId = item?.purchaseOrders?.[0]?.ledgerRef;
    const { refetch: onRefetchDiscrepancies } = useGetBillDiscrepancies({
        id: !!purchaseOrderId && id,
    });
    const { refetch: onRefetchPoData } = usePurchaseOrderItemData(purchaseOrderId, item?.ledgerRef);
    const { refetch: onRefetchPo } = usePurchaseOrder(purchaseOrderId);
    useEventSync(`refresh-po-discrepancies-${id}`, ({ isLoading, shouldRefetch }) => {
        if (shouldRefetch) {
            onRefetchPo();
            onRefetchPoData();
            onRefetchDiscrepancies();
            dispatch(fetchThreadRequest(item.thread));
            addToast({
                message: 'PO matching is up to date',
                type: 'success',
            });
        }
        RoutableEventsSync.Publish('set-refreshing-discrepancies', { isLoading });
    });
};

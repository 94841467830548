const { REACT_APP_STATIC_BASE_PATH } = process.env;

export const LOGO = {
  BLACK: `${REACT_APP_STATIC_BASE_PATH}/logo/logo-black.svg`,
  BLUE: `${REACT_APP_STATIC_BASE_PATH}/logo/logo-blue.svg`,
  DARK: `${REACT_APP_STATIC_BASE_PATH}/logo/logo-dark.svg`,
  NAVY: `${REACT_APP_STATIC_BASE_PATH}/logo/logo-navy.svg`,
  WHITE: `${REACT_APP_STATIC_BASE_PATH}/logo/logo-white.svg`,
};

export const LOGO_LETTER = {
  BLUE: `${REACT_APP_STATIC_BASE_PATH}/logo/logo-letter.svg`,
};

export const LOGO_CIRCLE = {
  BLUE: `${REACT_APP_STATIC_BASE_PATH}/logo/logo-circle-blue.svg`,
};

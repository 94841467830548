import styled from 'styled-components';
export const Container = styled.div `
  background-color: var(--color-grey5);
  display: flex;
  justify-content: space-between;

  border-top: solid 1px var(--color-grey20);

  padding: 12px;

  color: var(--color-black);
  font-size: var(--font-size-1);
  line-height: 18px;

  .page-count {
    color: var(--color-black);
    cursor: default;
  }

  .goto-page {
    &::placeholder {
      color: var(--color-black);
    }
    &:focus::placeholder {
      color: transparent;
    }
  }
`;

import React from 'react';
import { CurrencyAmountTextPlain, Text } from 'components';
import { ItemPaymentStatusText } from 'constants/item';
import { typography } from 'constants/styles';
import { isKindReceivable } from 'helpers/items';
import { ExternalBlock, ExternalBlockItem } from 'modules/external/ExternalBlocksSection';
import {} from './ExternalReceiptPaymentHeader.types';
import './ExternalReceiptPaymentHeader.scss';
const ExternalReceiptPaymentHeader = ({ itemKind, amount, currencyCode, }) => {
    const suffix = isKindReceivable(itemKind) ? ItemPaymentStatusText.DUE : ItemPaymentStatusText.PAID;
    return (React.createElement(ExternalBlock, { className: "external-receipt-payment-header" },
        React.createElement(ExternalBlockItem, null,
            React.createElement(Text.Regular, { color: typography.TextColor.GREY_X_DARK, size: typography.TextSize.LEVEL_200 }, `Amount ${suffix}`),
            React.createElement(Text.Regular, { className: "external-receipt-payment-header__price", color: typography.TextColor.BLACK, size: typography.TextSize.UNSET },
                React.createElement(CurrencyAmountTextPlain, { amount: amount, currencyCode: currencyCode })))));
};
export default ExternalReceiptPaymentHeader;

// This resize event runs in the background and is passive so wont
// effect painting but sets the width "in pixels" to a css variable
// called X which can then be used like var(--body-width-pixels)
// it is to resize/set max pixels on "tables" since they are using
// table, tr tags and the way the component works it can't use percentages.

// This is will also be used for determining left navigation issues
// down range where tablets and phones are having issues with media queries :)

// Get the base document
const rootElement = document.documentElement;

const handleResize = () => {
  // Generic try because we don't want it failing if
  // it cannot determine the root document
  // this will never happen but you don't want to get
  // trapped here so a simple try/do nothing wrapper.
  if (typeof rootElement === 'undefined' || !rootElement) {
    return; // exit early
  }

  // Extra safe prop accessing
  if (rootElement?.clientWidth && rootElement.style) {
    const { clientWidth } = rootElement;
    rootElement.style.setProperty('--body-width-pixels', `${clientWidth}px`);
  }
};

export const watchResize = () => {
  if (window.addEventListener) {
    window.addEventListener('resize', handleResize, false);
  } else if (window.attachEvent) {
    // Fall back if older device IE8
    // Browser support is on our roadmap to figure out
    // what browsers we are actually supporting so this entire if will
    // more than likely be deleted.
    window.attachEvent('resize', handleResize);
  } else {
    window.resize = handleResize;
  }

  // Init base width then watch for resize events.
  handleResize();

  // If return const is set it will dispose when called.
  // otherwise this event will dispose on page refresh or navigation away from routable.
  return () => {
    window.removeEventListener('resize', handleResize, false);
  };
};

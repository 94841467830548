import { getQueryParamValueFromUrl } from 'helpers/queryParams';
import {} from '../ResetPasswordComplete.types';
export const getResetPasswordCompleteInitialValues = () => ({
    form: {
        token: getQueryParamValueFromUrl('token'),
        newPassword: '',
        newPasswordConfirm: '',
    },
    meta: {
        removeAuthToken: true,
        shouldRedirectOnSuccess: true,
        showIndicatorOnSuccess: false,
        showNotificationBarOnSuccess: true,
    },
});

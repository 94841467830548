import { createErrorReducer } from 'store/redux';

import { FETCH_INTEGRATIONS_FAILURE, FETCH_INTEGRATIONS_REQUEST, FETCH_INTEGRATIONS_SUCCESS } from 'types/integrations';

export const failureCases = [FETCH_INTEGRATIONS_FAILURE];
export const successCases = [FETCH_INTEGRATIONS_REQUEST, FETCH_INTEGRATIONS_SUCCESS];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

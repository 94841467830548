import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ActionNavBody, ActionNavMobileMenuToggle, ActionNavSearch } from './components';

import './ActionNav.scss';

const ActionNav = (props) => {
  const {
    children,
    className,
    onSearchClear,
    onSearchExecute,
    onSearchInputChange,
    onToggleSidebar,
    searchData,
    searchPlaceholder,
    shouldAllowWrapping,
    shouldHideMenu,
    shouldHideMobileMenuToggle,
    noBorder,
  } = props;

  return (
    <div
      className={classNames({
        [className]: !!className,
        'action-nav': true,
        'action-nav--no-border': noBorder,
        'allows-wrapping': shouldAllowWrapping,
        'hide-mobile-menu': shouldHideMobileMenuToggle,
      })}
    >
      {/* TODO: remove after globally enabling FEATURE_FLAG_SIDE_NAVIGATION_V2 */}
      {onToggleSidebar && (
        <ActionNavMobileMenuToggle
          onToggleSidebar={onToggleSidebar}
          shouldHideMenu={shouldHideMenu}
          shouldHideMobileMenuToggle={shouldHideMobileMenuToggle}
        />
      )}
      {children && (
        <ActionNavBody searchData={searchData} searchPlaceholder={searchPlaceholder}>
          {children}
        </ActionNavBody>
      )}
      <ActionNavSearch
        onSearchClear={onSearchClear}
        onSearchExecute={onSearchExecute}
        onSearchInputChange={onSearchInputChange}
        searchData={searchData}
      />
    </div>
  );
};

ActionNav.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  noBorder: PropTypes.bool,
  onSearchClear: PropTypes.func,
  onSearchExecute: PropTypes.func,
  onSearchInputChange: PropTypes.func,
  onToggleSidebar: PropTypes.func,
  searchData: PropTypes.shape({
    execute: PropTypes.number,
    keyword: PropTypes.string,
  }),
  searchPlaceholder: PropTypes.string,
  shouldAllowWrapping: PropTypes.bool,
  shouldHideMenu: PropTypes.bool,
  shouldHideMobileMenuToggle: PropTypes.bool,
};

ActionNav.defaultProps = {
  children: undefined,
  className: undefined,
  noBorder: undefined,
  onSearchClear: undefined,
  onSearchExecute: undefined,
  onSearchInputChange: undefined,
  onToggleSidebar: undefined,
  searchData: undefined,
  searchPlaceholder: 'Results',
  shouldAllowWrapping: undefined,
  shouldHideMenu: undefined,
  shouldHideMobileMenuToggle: undefined,
};

export default ActionNav;

import dayjs from 'dayjs';
import _omitBy from 'lodash/omitBy';
import { z } from 'zod';
export const operatorModel = z.enum(['BETWEEN', 'GTE', 'LTE', 'EQ']);
export const operatorOptions = {
    GTE: 'is greater or equal to',
    EQ: 'is equal to',
    LTE: 'is less or equal to',
    BETWEEN: 'is between',
};
export const operatorSymbols = {
    GTE: '≥',
    LTE: '≤',
    BETWEEN: '<->',
    EQ: '=',
};
export const dateModel = z
    .string()
    .or(z.date())
    .transform((str) => new Date(str))
    .refine((date) => !isNaN(date.valueOf()), { message: 'Not a valid date' });
export const createdDateFilterModel = z.discriminatedUnion('operator', [
    z.object({
        operator: z.literal(operatorModel.Enum.BETWEEN),
        value: z.tuple([dateModel, dateModel]),
    }),
    z.object({ operator: z.literal(operatorModel.Enum.GTE), value: dateModel }),
    z.object({ operator: z.literal(operatorModel.Enum.LTE), value: dateModel }),
    z.object({ operator: z.literal(operatorModel.Enum.EQ), value: dateModel }),
]);
export const createDateFilterToQueryParamsTransformer = (filterName, paramName = 'createdAt') => ({ [filterName.value]: createdDate, ...filters }) => {
    const parsedCreatedDate = createdDateFilterModel.safeParse(createdDate);
    if (!createdDate || !parsedCreatedDate.success) {
        return filters;
    }
    const createdDateData = parsedCreatedDate.data;
    const CreatedAtParamMap = {
        [operatorModel.Enum.EQ]: paramName,
        [operatorModel.Enum.LTE]: `${paramName}__lte`,
        [operatorModel.Enum.GTE]: `${paramName}__gte`,
    };
    const format = 'YYYY-MM-DD';
    if (createdDateData.operator === operatorModel.Enum.BETWEEN) {
        const [gteDate, lteDate] = createdDateData.value;
        return {
            ...filters,
            [CreatedAtParamMap.LTE]: dayjs(lteDate).format(format),
            [CreatedAtParamMap.GTE]: dayjs(gteDate).format(format),
        };
    }
    const createdAtParamName = CreatedAtParamMap[createdDateData.operator];
    const dateValue = dayjs(createdDateData.value).format(format);
    return {
        [createdAtParamName]: dateValue,
        ...filters,
    };
};
export const createDateFilterFromQueryParamsTransformer = (filterName, paramName) => ({ [paramName]: createdAt, [`${paramName}__gte`]: createdAt__gte, [`${paramName}__lte`]: createdAt__lte, ...rest }) => {
    if (!createdAt && !createdAt__gte && !createdAt__lte) {
        delete rest[filterName];
        return rest;
    }
    if (createdAt) {
        return {
            ...rest,
            [filterName]: { operator: operatorModel.Enum.EQ, value: createdAt },
        };
    }
    if (createdAt__gte && !createdAt__lte) {
        return {
            ...rest,
            [filterName]: {
                operator: operatorModel.Enum.GTE,
                value: createdAt__gte,
            },
        };
    }
    if (!createdAt__gte && createdAt__lte) {
        return {
            ...rest,
            [filterName]: {
                operator: operatorModel.Enum.LTE,
                value: createdAt__lte,
            },
        };
    }
    return {
        ...rest,
        [filterName]: {
            operator: operatorModel.Enum.BETWEEN,
            value: [createdAt__gte, createdAt__lte],
        },
    };
};
export const createMultiValueFiltersFromQueryParamsTransformer = (...filtersToTransform) => (filters) => {
    const filterCandidates = filtersToTransform.reduce((acc, val) => {
        return { ...acc, [val]: filters[val]?.split(',') };
    }, {});
    const multiValueFilters = _omitBy(filterCandidates, (val) => val === undefined);
    return { ...filters, ...multiValueFilters };
};
const joinIfArrayElseSingleValue = (val) => (Array.isArray(val) ? val.join(',') : val);
export const createMultiOptionFilterModel = (enumModel) => enumModel.or(z.array(enumModel)).nullish().transform(joinIfArrayElseSingleValue);

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { formNamesFunding } from 'constants/forms';
import { openManuallyAddBankAccountSidePanel } from 'helpers/sidePanels';
import {} from 'interfaces/global';
import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';
import { createMergeProps } from 'store/redux';
import AddBankAccountManuallyButton from './AddBankAccountManuallyButton';
export const mapStateToProps = createStructuredSelector({
    partnership: partnershipFromQuerySelector,
});
export const mapDispatchToProps = {
    onOpenSidePanel: openManuallyAddBankAccountSidePanel,
};
export const mergeProps = createMergeProps((stateProps, dispatchProps) => ({
    onClick: () => {
        const { partnership } = stateProps;
        const { onOpenSidePanel } = dispatchProps;
        onOpenSidePanel({
            formContext: formNamesFunding.ADD_MANUAL_BANK,
            partnershipId: partnership?.id,
        });
    },
}));
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AddBankAccountManuallyButton);

import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

import { TableCellTextWithSubtext } from 'components/table';

/**
 * Used in transactions table for static text.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 * @constructor
 */
const BalanceTransactionCreatedCell = ({ date, rowData, title }) => {
  const dateValue = _get(rowData, date);
  const titleValue = _get(rowData, title);

  return (
    <TableCellTextWithSubtext
      className="truncate"
      subtext={titleValue ? dateValue : undefined}
      text={titleValue || dateValue}
    />
  );
};

BalanceTransactionCreatedCell.propTypes = {
  rowData: PropTypes.shape({}).isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default BalanceTransactionCreatedCell;

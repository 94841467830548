import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import ProgressBar from 'components/progressBar/ProgressBar';

import {
  isExternalFlowStepContactInfo,
  isExternalFlowStepEntityInfo,
  isExternalFlowStepPaymentInfo,
} from 'helpers/external';

import PartnerCompanyContactContainer from 'modules/createPartnerCompany/containers/PartnerCompanyContactContainer';
import PartnerCompanyGeneralContainer from 'modules/createPartnerCompany/containers/PartnerCompanyGeneralContainer';
import PartnerCompanyPaymentMethodContainer from 'modules/createPartnerCompany/containers/PartnerCompanyPaymentMethodContainer';
import {
  getExternalOnboardingCurrentStepIndex,
  getExternalOnboardingCurrentStepNumber,
  getExternalOnboardingSteps,
} from 'modules/createPartnerCompany/helpers/steps';
import ExternalReceiptContainer from 'modules/external/externalReceipt/containers/ExternalReceiptContainer';
import ExternalDeptAndItemId from 'modules/external/global/presenters/ExternalDeptAndItemId';

import 'modules/createPartnerCompany/presenters/PartnerCompanyForm.scss';
import './ExternalOnboardingForm.scss';

const ExternalOnboardingForm = ({ onCancel, stepIndex }) => {
  const steps = getExternalOnboardingSteps();
  const stepNumber = getExternalOnboardingCurrentStepNumber();

  return (
    <div className="external-onboarding-form--container">
      <div className="content--left">
        <div className="partner-company-form--wrapper">
          <div className="partner-company-form--container">
            {isExternalFlowStepContactInfo(stepNumber) && <PartnerCompanyContactContainer onCancel={onCancel} />}
            {isExternalFlowStepEntityInfo(stepNumber) && <PartnerCompanyGeneralContainer />}
            {isExternalFlowStepPaymentInfo(stepNumber) && <PartnerCompanyPaymentMethodContainer />}
          </div>
        </div>

        <ProgressBar currentStepPosition={stepIndex} isExternal steps={steps} />
      </div>

      <div className="content--right">
        <div className="external-receipt--wrapper">
          <ExternalReceiptContainer />
        </div>
        <ExternalDeptAndItemId />
      </div>
    </div>
  );
};

ExternalOnboardingForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  stepIndex: PropTypes.number.isRequired,
};

const mapStateToProps = () => ({
  stepIndex: getExternalOnboardingCurrentStepIndex(),
});

export default connect(mapStateToProps)(ExternalOnboardingForm);

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { bankAccountDetailsModalClose } from 'actions/funding';

import { modalNameBankAccountDetails } from 'constants/modals';

import { createModalSelector } from 'selectors/modalsSelector';

import BankAccountDetailsModal from './BankAccountDetailsModal';

const modalSelector = createModalSelector(modalNameBankAccountDetails);

const mapStateToProps = createStructuredSelector({
  modalState: modalSelector,
});

const mapDispatchToProps = {
  onCloseModal: bankAccountDetailsModalClose,
};

export default connect(mapStateToProps, mapDispatchToProps)(BankAccountDetailsModal);

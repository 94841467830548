import PropTypes from 'prop-types';
import React from 'react';

import { Text, WhiteSpace } from 'components/text';

import { TextColor } from 'constants/styles/typography';

/**
 * Renders the request id text in the ServerError swal.
 * @param {*} children
 * @return {StatelessComponent}
 */
const ServerErrorRequestId = ({ children }) => (
  <Text.Regular className="request-id-text display--block margin-top--m" color={TextColor.GREY_DARK}>
    <span className="bold">Request ID:</span>
    <WhiteSpace />
    {children}
  </Text.Regular>
);

ServerErrorRequestId.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ServerErrorRequestId;

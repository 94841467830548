import { getDomainOnly } from 'helpers/urls';
export const setCookie = (name, value, sameSite = 'lax', timeInMinutes = 15) => {
    const d = new Date(Date.now());
    d.setTime(d.getTime() + timeInMinutes * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const sameSiteString = `samesite=${sameSite}`;
    const domain = getDomainOnly();
    const domainString = `domain=${domain}`;
    document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)};${expires};${domainString};${sameSiteString};secure;path=/`;
};
export const getCookie = (name) => {
    const matches = document.cookie.match(new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`));
    return matches ? decodeURIComponent(matches[1]) : undefined;
};
export const removeCookie = (name) => {
    document.cookie = `${encodeURIComponent(name)}=;max-age=-1;path=/`;
};

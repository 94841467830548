import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';

import getRelationships from 'store/redux';

import { GET_CURRENT_COMPANY_SUCCESS, UPDATE_CURRENT_COMPANY_SUCCESS } from 'types/currentCompany';

const getCurrentCompanyBrand = (brand) => {
  let currentCompanyBrand = {};

  if (!brand) {
    return currentCompanyBrand;
  }

  Object.keys(brand).forEach((brandId) => {
    const relationships = getRelationships(brand, brandId);

    currentCompanyBrand = {
      id: brand[brandId].id,
      ...brand[brandId].attributes,
      ...relationships,
    };
  });

  return currentCompanyBrand;
};

const companyBrandReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CURRENT_COMPANY_SUCCESS:
    case UPDATE_CURRENT_COMPANY_SUCCESS:
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
      return getCurrentCompanyBrand(action.payload.companyBrand);

    default:
      return state;
  }
};

export default companyBrandReducer;

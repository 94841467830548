import React from 'react';

import { ExternalHintStatus } from 'constants/external';

import StepHintBase from 'modules/external/externalStepHint/StepHintBase';

/**
 * Component rendering External Step Hint with hint status of PAYMENT_FAILED
 * @see StepHintBase
 *
 * @return {StatelessComponent}
 */
const FailedPaymentStepHint = () => <StepHintBase hintStatus={ExternalHintStatus.FAILED_PAYMENT} />;

export default FailedPaymentStepHint;

import { RoutableEventsSync, RoutableStorage } from '@routable/framework';
import { Radio } from '@routable/gross-ds';
import React, { useContext, useEffect, useState } from 'react';
import {} from '../../../../types/global.types';
import { useDispatchTableEvent, useTableEvent } from '../../../hooks/useTableEvent.hook';
import { TableContext } from '../../../table-name.context';
import { useTableStore } from '../../../table.store';
import {} from '../rowselect.types';
export const RadioSelect = ({ isDisabled, shouldTriggerOnChange = true, value = '', onChange, }) => {
    const tableName = useContext(TableContext).tableName;
    const { setTableStateItem } = useTableStore(tableName);
    const [isChecked, setIsChecked] = useState(false);
    const changeSingleSelection = useDispatchTableEvent({
        tableName,
        event: 'selected:update:single',
    });
    useTableEvent({
        tableName,
        event: 'selected:update:single',
        fn: (data) => {
            if (data.includes(value)) {
                const currentTableSelection = [value];
                setTableStateItem('selections', currentTableSelection);
                RoutableEventsSync.Publish(`table:${tableName}:selected:updated`, currentTableSelection);
                setIsChecked(true);
            }
            else {
                setIsChecked(false);
            }
        },
    });
    const onChangeEvent = async () => {
        changeSingleSelection([value]);
        if (onChange) {
            onChange(value);
        }
        return true;
    };
    useEffect(() => {
        RoutableStorage.get('routableTableSelection', [
            {
                indexName: 'tableId',
                condition: '==',
                value: tableName,
            },
        ]).then((result) => {
            if (result?.data?.includes(value)) {
                setIsChecked(true);
            }
        });
    }, []);
    return (React.createElement("div", null,
        React.createElement(Radio, { onChange: shouldTriggerOnChange ? onChangeEvent : undefined, checked: isChecked, disabled: isDisabled, name: "table_select_radio", value: value })));
};

import * as types from 'types/inviteTeamMember';

export const clearMembershipInvite = () => ({
  type: types.CLEAR_MEMBERSHIP_INVITE,
});

export const fetchAllMembershipInvitesRequest = (companyId) => ({
  payload: { companyId },
  type: types.FETCH_MEMBERSHIP_INVITES_REQUEST,
});

export const fetchAllMembershipInvitesSuccess = (payload) => ({
  payload,
  type: types.FETCH_MEMBERSHIP_INVITES_SUCCESS,
});

export const fetchAllMembershipInvitesFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_MEMBERSHIP_INVITES_FAILURE,
});

export const fetchMembershipInviteRequest = (companyId, membershipInviteId, membershipInviteToken) => ({
  payload: { companyId, membershipInviteId, membershipInviteToken },
  type: types.FETCH_MEMBERSHIP_INVITE_REQUEST,
});

export const fetchMembershipInviteSuccess = (payload) => ({
  payload,
  type: types.FETCH_MEMBERSHIP_INVITE_SUCCESS,
});

export const fetchMembershipInviteFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_MEMBERSHIP_INVITE_FAILURE,
});

export const resendMembershipInvite = (payload) => ({
  payload,
  type: types.RESEND_MEMBERSHIP_INVITE,
});

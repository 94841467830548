import PropTypes from 'prop-types';
import React from 'react';

import BaseHint from 'components/hintTypes/BaseHint';

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { Intent } from 'constants/ui';

import { getBillOrInvoiceText, isExistingItemActionMarkAsPaid } from 'helpers/items';

/**
 * Returns a hint warning the user that the following action will cause a mismatch between Routable and Ledger Records
 * @param {ComponentProps} props
 * @param {string} props.action
 * @param {Item} props.item
 * @param {string} props.ledgerName
 * @returns {StatelessComponent}
 */
const PerformActionOnRoutableButNotLedgerHint = ({ action, item, ledgerName }) => {
  const itemKind = getBillOrInvoiceText(item);
  const actionWord = isExistingItemActionMarkAsPaid(action) ? 'paid' : 'canceled';

  return (
    <BaseHint
      intent={Intent.WARNING}
      multiline
      text={`Marking this ${itemKind} as ${actionWord} will cause a mismatch of records between 
      ${PLATFORM_DISPLAY_SHORT_NAME} and your ${ledgerName} account. This action is final and cannot be undone.`}
      title="Before you proceed..."
    />
  );
};

PerformActionOnRoutableButNotLedgerHint.propTypes = {
  action: PropTypes.string.isRequired,
  item: PropTypes.shape().isRequired,
  ledgerName: PropTypes.string.isRequired,
};

export default PerformActionOnRoutableButNotLedgerHint;

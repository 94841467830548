import { Panel } from '@routable/gross-ds';
import styled from 'styled-components';
export const Container = styled.div `
  position: relative;
  width: 100vw;
  height: 100vh;
`;
export const CardContainer = styled(Panel) `
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 200px;
  white-space: nowrap;
`;
export const ErrorContainer = styled.div `
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
export const SentryErrorTitle = styled.span `
  display: block;
  font-size: var(--font-size-3);
  font-weight: 600;
  color: var(--color-red50);
  margin-bottom: 10px;
`;
export const SentryErrorDescription = styled.span `
  display: block;
  font-size: var(--font-size-2);
  color: var(--color-neutral-black);
  margin-bottom: 10px;
`;
export const SentryErrorId = styled.span `
  display: block;
  font-size: var(--font-size-2);
  color: var(--color-red50);
`;

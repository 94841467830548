/**
 * Enum to describe the actions that can trigger
 * the Select input's onChange handler. Passed by react-select.
 * @enum {string}
 */
export const InputActions = {
  SET_VALUE: 'set-value',
  INPUT_CHANGE: 'input-change',
  INPUT_BLUR: 'input-blur',
  MENU_CLOSE: 'menu-close',
};

/**
 * Enum to describe the actions that can trigger
 * the Select's onChange handler. Passed by react-select.
 * @enum {string}
 */
export const SelectActions = {
  CLEAR: 'clear',
  CREATE_OPTION: 'create-option',
  DESELECT_OPTION: 'deselect-option',
  INPUT_CHANGE: 'input-change',
  POP_VALUE: 'pop-value',
  REMOVE_VALUE: 'remove-value',
  SELECT_OPTION: 'select-option',
  SET_VALUE: 'set-value',
};

/**
 * Enum to describe the position of the "create" option
 * in creatable Selects (whether it's first in the list,
 * or last). Values are those enforced by react-select.
 * @enum {string}
 * @see {@link https://react-select.com/props#creatable-props|Creatable props}
 */
export const CreateOptionPosition = {
  FIRST: 'first',
  LAST: 'last',
};

/**
 * Options that are used in multiple Select component types/scenarios.
 * Note that using these options may have side effects.
 * @type {Object.<string, Object>}
 */
export const CommonSelectOptions = {
  SELECT_ALL: { value: 'SELECT_ALL', label: 'Select all' },
  NOT_SET: { value: 'NOT_SET' },
};

/**
 * Numeric size constants used internally by Selects.
 * @type {Object.<string, number>}
 */
export const sizes = {
  screenEdgePadding: 24,
  defaultOptHeight: 38,
  defaultMaxHeight: 304, // optionHeight * 8
  defaultMinHeight: 114, // optionHeight * 3
};

import { universalRenderPropType } from 'helpers/propTypes';

/**
 * Renders children whether a given as a function or node.
 * Uses `ternaryCall` to safeguard executing code that might fail unless guarded.
 * @param {*} children
 * @return {*}
 */
const UniversalChildren = ({ children }) => (typeof children === 'function' ? children() : children);

UniversalChildren.propTypes = {
  children: universalRenderPropType,
};

UniversalChildren.defaultProps = {
  // defaults to null instead of undefined so we always return a node
  children: null,
};

export default UniversalChildren;

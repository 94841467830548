import React from 'react';
import swal from 'sweetalert';
import { IconNames, ShadedBlock, SwalWrapperV2, Text } from 'components';
import { ServerErrorRequestId } from 'components/error/components';
import { ButtonSize } from 'constants/button';
import { GENERIC_ERROR } from 'constants/error';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { colors } from 'constants/styles';
import { Intent } from 'constants/ui';
import ContactSupportLink from 'modules/dashboard/settings/user/profile/UserSettingsProfile/components/ContactSupportLink';
import { FetchService } from 'services';
const TaxInfoBulkRequestErrorSwal = ({ errorMessage, requestId, statusCode, }) => {
    const isErrorTooManyRequests = FetchService.isResponseTooManyRequests({
        status: statusCode,
    });
    const swalTitle = isErrorTooManyRequests ? 'Tax request limit reached' : GENERIC_ERROR;
    const handleClose = () => {
        swal.close();
    };
    const genericErrorContent = (React.createElement(React.Fragment, null,
        "Please contact ",
        React.createElement(ContactSupportLink, null, `${PLATFORM_DISPLAY_SHORT_NAME} support`),
        " and we'll be happy to assist."));
    return (React.createElement(SwalWrapperV2, { iconProps: {
            color: colors.colorRedBoldHex,
            name: IconNames.WARNING_SIGN,
        }, rightButtonProps: {
            onClick: handleClose,
            children: 'Okay',
            intent: Intent.PRIMARY,
            size: ButtonSize.MEDIUM,
        }, title: swalTitle },
        React.createElement(Text.Regular, null,
            !isErrorTooManyRequests && 'It looks like we ran into an issue.',
            isErrorTooManyRequests ? (React.createElement(React.Fragment, null, errorMessage)) : (React.createElement(React.Fragment, null,
                React.createElement(ShadedBlock, { className: "margin-bottom--m margin-top--m", intent: Intent.NEUTRAL }, errorMessage),
                genericErrorContent)),
            !!requestId && !isErrorTooManyRequests && React.createElement(ServerErrorRequestId, null, requestId))));
};
export default TaxInfoBulkRequestErrorSwal;

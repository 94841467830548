import React from 'react';
import ReactModal from 'react-modal';
import ModalBody from 'components/modal/components/body/ModalBody';
import ModalHeader from 'components/modal/components/header/ModalHeader';
import { colors } from 'constants/styles';
import { ZIndexLayers } from 'constants/ui';
import { getZIndex } from 'helpers/zIndex';
import './Modal.scss';
ReactModal.setAppElement('body');
const Modal = ({ body, hasInlineFooter, hideHeader, modalHeader = '', modalState, onCloseModal, overrideStyle = {}, testId, ...rest }) => (React.createElement(ReactModal, { contentLabel: "Modal", isOpen: modalState, onRequestClose: onCloseModal, style: {
        ...overrideStyle,
        content: {
            backgroundColor: colors.colorBlueLightHex,
            ...overrideStyle.content,
        },
        overlay: {
            backgroundColor: 'rgba(76, 84, 103, 0.3)',
            zIndex: getZIndex(ZIndexLayers.MODAL.OVERLAY),
            ...overrideStyle.overlay,
        },
    }, ...rest, ...(testId ? { data: { testid: testId } } : {}) },
    !hideHeader && (React.createElement(ModalHeader, null,
        React.createElement("h3", null, modalHeader),
        React.createElement("a", { className: "modal-header--close remove-hover-underline", onClick: onCloseModal, role: "presentation" },
            React.createElement("span", { className: "icon-ic-x-out" })))),
    React.createElement(ModalBody, { hasInlineFooter: hasInlineFooter }, body)));
export default Modal;

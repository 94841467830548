import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

import StatusWithText from 'components/statusWithText';

/**
 * Renders the status cell for a transactions table row.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 */
const BalanceTransactionStatusCell = ({ status, date, description, rowData }) => (
  <StatusWithText
    secondaryText={_get(rowData, date)}
    status={_get(rowData, status)}
    tooltipContent={_get(rowData, description)}
  />
);

BalanceTransactionStatusCell.propTypes = {
  rowData: PropTypes.shape().isRequired,
  status: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default BalanceTransactionStatusCell;

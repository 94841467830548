import { CheckmarkFilled } from '@carbon/icons-react';
import React from 'react';
import { CornerDialogCloseOnly } from 'components';
import { getItemToOrFromPlaceholderText, getPaymentOrInvoiceText } from 'helpers/items';
const CornerDialogItemAlreadyAccepted = ({ isShown, item, onClose, partnershipName, }) => {
    const paymentOrInvoice = getPaymentOrInvoiceText(item);
    const toOrFrom = getItemToOrFromPlaceholderText(item);
    return (React.createElement(CornerDialogCloseOnly, { isShown: isShown, onClose: onClose, title: React.createElement("div", { className: "inline-flex items-center" },
            React.createElement(CheckmarkFilled, { className: "fill-green-40 mr-3", size: 16 }),
            React.createElement("span", { className: "font-medium text-grey-70 text-base" }, "No further action is needed at this time.")) },
        `This ${paymentOrInvoice} has already been remitted ${toOrFrom} `,
        React.createElement("span", { className: "font-bold text-sm text-grey-60" }, partnershipName),
        "."));
};
export default CornerDialogItemAlreadyAccepted;

import { createInternationalFundingAccountRoutine } from 'actions/routines/funding';

import { createLastSubmittedReducer } from 'store/redux';

import { MODAL_CONNECT_BANK_MANUAL_OPEN, SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_SUCCESS } from 'types/connectBank';

export const successCases = [
  SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_SUCCESS,
  createInternationalFundingAccountRoutine.SUCCESS,
];
export const requestCases = [MODAL_CONNECT_BANK_MANUAL_OPEN, createInternationalFundingAccountRoutine.REQUEST];

const lastSubmittedReducer = createLastSubmittedReducer(successCases, requestCases);

export default lastSubmittedReducer;

import { createSelector } from 'reselect';

import { isMembershipInviteStatusPending } from 'helpers/memberships';

import { idSelector } from './globalSelectors';

/**
 * Top level membership invites selector
 * @param {object} state
 * @return {object}
 */
const getState = (state) => state.membershipInvites;

/**
 * Selects the isFetching bool for membership invites from the state
 * @param {object} state - Redux state
 */
export const isFetchingMembershipInvitesSelector = createSelector(
  [getState],
  (membershipInvites) => membershipInvites.isFetching,
);

/**
 * Selects the isSubmitting bool for membership invites from the state
 * @param {object} state - Redux state
 */
export const isSubmittingMembershipInviteSelector = createSelector(
  [getState],
  (membershipInvites) => membershipInvites.isSubmitting,
);

/**
 * Selects the lastSubmitted date for membership invites from the state
 * @param {object} state - Redux state
 */
export const lastSubmittedMembershipInviteSelector = createSelector(
  [getState],
  (membershipInvites) => membershipInvites.lastSubmitted,
);

/**
 * Selects membership invites by id from the state
 * @param {object} state - Redux state
 */
export const membershipInvitesByIdSelector = createSelector([getState], (membershipInvites) => membershipInvites.byId);

/**
 * Selects the membership invites errors from the state
 * @param {object} state - Redux state
 */
export const membershipInvitesErrorsSelector = createSelector(
  [getState],
  (membershipInvites) => membershipInvites.errors,
);

/**
 * Selects pending membership invites
 * @param {ReduxState} state - Redux state
 * @returns {MembershipInvite[]}
 */
export const pendingMembershipInvitesSelector = createSelector([membershipInvitesByIdSelector], (membershipInvites) =>
  Object.values(membershipInvites).filter(isMembershipInviteStatusPending),
);

/**
 * Selects a specific membership invite from the state
 * @param {object} state - Redux state
 */
export const membershipInviteSelector = createSelector(
  [membershipInvitesByIdSelector, idSelector],
  (membershipInvites, membershipInviteId) => membershipInvites[membershipInviteId],
);

/**
 * Selects the isRevoking bool for membership invites from the state
 * @param {object} state - Redux state
 */
export const isRevokingMembershipInviteSelector = createSelector(
  [getState],
  (membershipInvites) => membershipInvites.isRevoking,
);

import { useSelector } from 'react-redux';
import { usePartnershipPaymentOptions } from 'hooks';
import { getBillCurrencyTooltipText } from './helpers';
const useVendorReceivingCurrency = (partnershipSelector, billCurrencyCodeSelector) => {
    const partnership = useSelector(partnershipSelector);
    const billCurrencyCode = useSelector(billCurrencyCodeSelector);
    const tooltipText = getBillCurrencyTooltipText(billCurrencyCode);
    const { vendorCurrencyCodeReceiverOptionsByBillCurrency } = usePartnershipPaymentOptions({
        partnershipId: partnership.id,
    });
    const options = vendorCurrencyCodeReceiverOptionsByBillCurrency?.[billCurrencyCode] || [];
    const isLocked = options?.length <= 1;
    return { billCurrencyCode, isLocked, options, tooltipText };
};
export default useVendorReceivingCurrency;

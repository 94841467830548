import { createSelector } from 'reselect';

import { membershipInvitesByIdSelector } from 'selectors/membershipInvitesSelectors';
import {
  companyIdQuerySelector,
  membershipInviteTokenQuerySelector,
  membershipInviteIdQuerySelector,
} from 'selectors/routerSelectors';

import { companyFromQuerySelector } from './companyCompoundSelectors';

/**
 * Gets the `membershipInvite` by the id in query
 * @type {StandardSelector}
 */
export const membershipInviteFromQuerySelector = createSelector(
  [membershipInvitesByIdSelector, membershipInviteIdQuerySelector],
  (membershipInvites, membershipInviteId) => membershipInvites[membershipInviteId],
);

/**
 * Gets `initialValues` from state
 * @type {StandardSelector}
 */
export const acceptMembershipInviteInitialValuesSelector = createSelector(
  [
    membershipInviteFromQuerySelector,
    companyFromQuerySelector,
    companyIdQuerySelector,
    membershipInviteIdQuerySelector,
    membershipInviteTokenQuerySelector,
  ],
  (membershipInvite = {}, company = {}, companyId, membershipInviteId, membershipInviteToken) => ({
    form: {
      email: membershipInvite.email,
      firstName: membershipInvite.firstName,
      lastName: membershipInvite.lastName,
      namespace: company.namespace,
    },
    meta: {
      companyId,
      membershipInviteId,
      membershipInviteToken,
    },
  }),
);

import { uuidModel } from '@routable/shared';
import { z } from 'zod';
export const companyAddressModel = z.object({
    type: z.literal('Address'),
    id: uuidModel,
    attributes: z.object({
        city: z.string(),
        country: z.string(),
        postalcode: z.string(),
        state: z.string().nullish(),
        streetAddress: z.string(),
        streetAddressUnit: z.string().nullish(),
        created: z.string().datetime(),
        modified: z.string().datetime().nullish(),
    }),
});

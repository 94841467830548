import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'components/icon';

import { colors, margin } from 'constants/styles';

import './DetailBlock.scss';

const DetailBlock = ({ children, icon, iconClass, labelText }) => {
  const detailClasses = classNames({
    'content--left': true,
  });

  return (
    <div className="detail-block">
      <span className="detail-block--label">{labelText}</span>

      <div className="detail-block--content">
        {iconClass && (
          <div className={detailClasses}>
            <span className={iconClass} />
          </div>
        )}

        {icon && <Icon color={colors.colorMainJordan} icon={icon} marginRight={margin.extraMedium} size={12} />}

        <div className="content--right">{children}</div>
      </div>
    </div>
  );
};

DetailBlock.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  iconClass: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

DetailBlock.defaultProps = {
  icon: undefined,
  iconClass: undefined,
};

export default DetailBlock;

import PropTypes from 'prop-types';
import React from 'react';

import { FormFieldLabel } from 'components';

import { UserTypes } from 'constants/user';

import { isExclusivelyIndividualVendor, isPartnershipCustomer } from 'helpers/partnerships';
import { oneOfValuesFromObject } from 'helpers/propTypes';

import PartnerCompanyBillingForm from 'modules/createPartnerCompany/presenters/PartnerCompanyBillingForm/PartnerCompanyBillingForm';
import PartnerCompanyTaxForm from 'modules/createPartnerCompany/presenters/PartnerCompanyTaxForm/PartnerCompanyTaxForm';

import { TaxInfoFormFieldLabel } from './constants';
import { shouldShowFormFieldLabel } from './helpers';

/**
 * Determines which entity form to show based on company type & partnership
 * @param {ComponentProps} props
 * @param {boolean} props.collectVendorTaxInfo
 * @param {UserTypes} props.companyType
 * @param {Object} props.formUIState
 * @param {boolean} props.isInternational
 * @param {Function} props.onToggleInputForTin
 * @param {Function} props.onToggleSSNEIN
 * @param {Object} props.partnership
 * @returns {StatelessComponent}
 */
const PartnerCompanyEntityForm = ({
  collectVendorTaxInfo,
  companyType,
  formUIState,
  isInternational,
  onToggleInputForTin,
  onToggleSSNEIN,
  partnership,
}) => {
  // NOTE: Partnership isCustomer & isVendor attributes belong to the RCTM and not the partner company.
  // If a vendor is entering their entity info, then partnership isCustomer is true, since RCTM is a customer to the vendor
  // If a customer is entering their entity info, then partnership isVendor is true, since RCTM is a vendor to the customer
  const isVendorToRCTM = isPartnershipCustomer(partnership);
  const individualCustomerToRCTM = isExclusivelyIndividualVendor({
    partnership,
    companyType,
  });
  const showTaxFieldLabel = shouldShowFormFieldLabel(companyType, collectVendorTaxInfo);

  if (!companyType || individualCustomerToRCTM || isInternational) {
    return null;
  }

  return (
    <>
      {showTaxFieldLabel && <FormFieldLabel>{TaxInfoFormFieldLabel[companyType]}</FormFieldLabel>}
      <div className="margin-bottom--larger">
        {isVendorToRCTM ? (
          <PartnerCompanyTaxForm
            collectVendorTaxInfo={collectVendorTaxInfo}
            companyType={companyType}
            formUIState={formUIState}
            hideLabel
            onToggleInputForTin={onToggleInputForTin}
            onToggleSSNEIN={onToggleSSNEIN}
          />
        ) : (
          <PartnerCompanyBillingForm companyType={companyType} hideLabel />
        )}
      </div>
    </>
  );
};

PartnerCompanyEntityForm.propTypes = {
  collectVendorTaxInfo: PropTypes.bool,
  companyType: oneOfValuesFromObject(UserTypes).isRequired,
  formUIState: PropTypes.shape({}).isRequired,
  isInternational: PropTypes.bool,
  onToggleInputForTin: PropTypes.func.isRequired,
  onToggleSSNEIN: PropTypes.func.isRequired,
  partnership: PropTypes.shape({}).isRequired,
};

PartnerCompanyEntityForm.defaultProps = {
  collectVendorTaxInfo: true,
  isInternational: undefined,
};

export default PartnerCompanyEntityForm;

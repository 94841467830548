import { z } from 'zod';
import { approvalRuleModel } from './level.model';
export const itemApprovalsModel = z.object({
    relatedObjectId: z.string().nullish(),
    bypassedAt: z.string().optional().nullable(),
    bypassedBy: z.string().optional().nullable(),
    hash: z.string().optional().nullable(),
    levels: z.array(approvalRuleModel),
});
export const itemApprovalApiModel = z.object({
    data: itemApprovalsModel,
});
export const itemApprovalsListApiModel = z.object({
    data: itemApprovalsModel.array(),
});

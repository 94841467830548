import { PAYMENTS_LIST_FILTERS, PAYMENTS_LIST_TABS } from 'constants/routes';

import {
  isItemKindPayable,
  isItemKindReceivable,
  isItemNeedingMyApproval,
  isItemNeedingOthersApproval,
  isItemStatusCreated,
  isItemStatusNeedsApproval,
  isItemStatusNew,
  isItemStatusNotCreated,
  isItemStatusPending,
  isItemStatusScheduled,
} from './items';

export const isItemValidForTab = (item, tab) => {
  const isCreated = isItemStatusCreated(item);

  const validStates = {
    [PAYMENTS_LIST_TABS.INBOX]: isCreated,
    [PAYMENTS_LIST_TABS.PAYABLES]: !isCreated && isItemKindPayable(item),
    [PAYMENTS_LIST_TABS.RECEIVABLES]: !isCreated && isItemKindReceivable(item),
  };

  return validStates[tab];
};

/**
 * Returns true|false if the item is valid for the given filter
 * @param {object} props
 * @param {Item} props.item
 * @param {string} props.filter
 * @param {ItemSideApproval[]} allApprovals
 * @return {boolean}
 */
export const isItemValidForFilter = ({ item, filter, allApprovals }) => {
  const validStates = {
    [PAYMENTS_LIST_FILTERS.ALL]: isItemStatusNotCreated(item),
    [PAYMENTS_LIST_FILTERS.CREATED]: isItemStatusCreated(item),
    [PAYMENTS_LIST_FILTERS.MY_APPROVAL]: isItemNeedingMyApproval({
      item,
      allApprovals,
    }),
    [PAYMENTS_LIST_FILTERS.NEEDS_APPROVAL]: isItemStatusNeedsApproval(item),
    [PAYMENTS_LIST_FILTERS.TEAM_APPROVAL]: isItemNeedingOthersApproval({
      item,
      allApprovals,
    }),
    [PAYMENTS_LIST_FILTERS.SCHEDULED]: isItemStatusScheduled(item),
    [PAYMENTS_LIST_FILTERS.UNPAID_BILLS]: isItemStatusNew(item),
    [PAYMENTS_LIST_FILTERS.UNPAID_INVOICES]: isItemStatusPending(item),
    [PAYMENTS_LIST_FILTERS.WAITING_FOR_ME]: isItemStatusPending(item),
    [PAYMENTS_LIST_FILTERS.WAITING_FOR_OTHERS]: isItemStatusPending(item),
  };

  return validStates[filter];
};

/**
 * Returns true|false if the item is valid for the current tab filter
 * @param {object} props
 * @param {Item} props.item
 * @param {string} props.tab
 * @param {string} props.filter
 * @param {ItemSideApproval[]} props.allApprovals
 * @return {boolean}
 */
export const isItemValidForTabFilter = ({ item, tab, filter, allApprovals }) =>
  isItemValidForTab(item, tab) && isItemValidForFilter({ item, filter, allApprovals });

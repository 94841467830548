import React from 'react';
import DashboardToDashboardRestrictionTooltipContent from 'components/dashboardToDashboardRestrictionTooltipContent';
import { Text } from 'components/text';
import { getPartnershipMemberAccessActionText, getPartnershipMemberAccessScopeText, getPartnershipMemberAccessText, isPartnershipMemberAccessSelfManaged, } from 'helpers/partnershipMembers';
import {} from './ContactSettingsTooltipText.types';
const ContactSettingsTooltipText = ({ access, scope }) => {
    if (isPartnershipMemberAccessSelfManaged(access)) {
        return React.createElement(DashboardToDashboardRestrictionTooltipContent, null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Text.Bold, null, getPartnershipMemberAccessScopeText(scope)),
        React.createElement("br", null),
        React.createElement(Text.Regular, null,
            getPartnershipMemberAccessText(access),
            getPartnershipMemberAccessActionText(access))));
};
export default ContactSettingsTooltipText;

/**
 * shouldReplaceChildrenWithLoadMoreButton
 * Helps LoadMoreChild decide whether it should be shown
 * or whether the LoadMore button should be triggered to be shown
 * @param {object} rect getClientBoundedRect value returned by useClientRect hook
 * @param {number} marginBetweenRows
 * @param {number} wrapperRectTop
 * @param {number} visibleRows
 * @return {boolean}
 */
export const shouldReplaceChildrenWithLoadMoreButton = ({ rect, marginBetweenRows, wrapperRectTop, visibleRows }) => {
  if (!rect?.bottom || !rect?.height) {
    return false;
  }

  // rect doesn't include margin but rows may have top & bottom margins
  // so we calculate the height with margin(s)
  const rowHeight = rect.height + marginBetweenRows;

  // we find the last point on the y axis that we want to show
  // (ie. the bottom of the last visible row)
  const maxY = wrapperRectTop + visibleRows * rowHeight;

  // if at any point of the current child (ie. its bottom) is lower than maxY
  // then we should hide this child and ensure the 'load more' button is visible
  return Math.floor(rect.bottom - maxY) > 0;
};

/**
 * getNumberFromCSSValue
 * CSS values can include suffixes like the value type (eg "px")
 * This fn removes those suffixes so you only get the numerical value
 * getNumberFromCSSValue('10px') // 10
 * note: the cssValue string provided needs to start with a number or parseInt will return NaN
 * @param {string} cssValue
 * @return {number}
 */
export const getNumberFromCSSValue = (cssValue) =>
  cssValue === '' || typeof cssValue === 'undefined' ? 0 : parseInt(cssValue, 10);

/**
 * getMarginHeightForChildOfNode
 * returns the combined marginTop and marginBottom values of the first child of a given node
 * @param {object} node ReactDOM node
 * @return {number} marginHeight
 */
export const getMarginHeightForChildOfNode = (node) => {
  if (node?.children) {
    // for now, we just look at the margin of the first child
    // in the future, we may want to improve this to find a specific child in the tree of node.children
    // so users of <LoadMore /> can have more flexibility
    const firstChild = node.children.item(0);
    if (firstChild) {
      const { marginBottom, marginTop } = window.getComputedStyle(firstChild);
      const parsedMarginBottom = getNumberFromCSSValue(marginBottom);
      const parsedMarginTop = getNumberFromCSSValue(marginTop);

      return parsedMarginBottom + parsedMarginTop;
    }
  }
  return 0;
};

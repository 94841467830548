import { TextWeight, TextWeightToWords } from 'constants/styles/typography';

import { BaseText, makeTextComponent } from './components';

/**
 * Primary export of Text components.
 *
 * @typedef {Object} TextComponentNamespace
 * @property {React.ReactType} Thin
 * @property {React.ReactType} Light
 * @property {React.ReactType} Regular
 * @property {React.ReactType} Semibold
 * @property {React.ReactType} Bold
 * @property {React.ReactType} ExtraBold
 */

/**
 * Text Components
 * @deprecated Use native HTML tag with Tailwind classes instead. If you were planning
 * to use this component, you're probably after the `span` tag but others can be used
 * as well.
 * @type {TextComponentNamespace}
 */
const Text = BaseText;

/**
 * Make text components like Text.Thin, etc. Also assigns a displayName, which is broken when using
 * makeTextComponent
 */
Object.keys(TextWeightToWords).forEach((numericalWeight) => {
  /**
   * Thin, Regular, Bold...
   * @const
   * @type {string}
   */
  const lexicalWeight = TextWeightToWords[numericalWeight];

  // Make the component Text.Thin, Text.Regular...
  Text[lexicalWeight] = makeTextComponent(TextWeight[numericalWeight]);
  Text[lexicalWeight].displayName = `Text.${lexicalWeight}`;
});

export default Text;

import { unformat } from 'accounting';
import { SubmissionError } from 'redux-form';

export default async (values, props) => {
  const { fundingAccountId, fundingSourceId, onSubmitVerifyFundingAccount } = props;
  // to easily sync field errors to redux form, we have to use this casing
  /* eslint-disable-next-line camelcase */
  const { microDeposit_1, microDeposit_2 } = values;

  const submitPayload = {
    data: {
      type: 'FundingSource',
    },
    meta: {
      micro_deposit_1: unformat(microDeposit_1),
      micro_deposit_2: unformat(microDeposit_2),
    },
  };

  const result = await onSubmitVerifyFundingAccount(submitPayload, {
    fundingAccountId,
    fundingSourceId,
  });

  if (result && result.errors) {
    throw new SubmissionError(result.errors.fields);
  }

  return true;
};

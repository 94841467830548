import { EventKeyValues } from 'constants/events';

import { isEqual } from 'helpers/utility';

/**
 * Merge street address with street address unit if it exists
 * @param streetAddress
 * @param streetAddressUnit
 * @return {*}
 */
export const getStreetAddress = (streetAddress, streetAddressUnit = null) => {
  if (!streetAddressUnit) {
    return streetAddress;
  }

  return `${streetAddress} ${streetAddressUnit}`;
};

/**
 * Watch the autocomplete attribute of the input element that Google Places autocomplete is attached to
 * in order to overwrite the autocomplete="off" value that is aggressively plopped in there.
 * All of this to add a custom attribute value since Chrome ignores autocomplete="off". Wheee!
 * {@link https://stackoverflow.com/questions/29931712/chrome-autofill-covers-autocomplete-for-google-maps-api-v3/49161445#49161445}
 * @param {Node} inputElement
 * @return {Boolean}
 */
export const observeStreetAddress = (inputElement) => {
  if (!inputElement) {
    return false;
  }

  inputElement.addEventListener('keydown', (event) => {
    if (isEqual(event.key, EventKeyValues.ENTER)) {
      event.preventDefault();
    }
  });

  const autoCompleteObserver = new MutationObserver(() => {
    autoCompleteObserver.disconnect();
    inputElement.setAttribute('autocomplete', 'new-user-street-address');
  });

  autoCompleteObserver.observe(inputElement, {
    attributes: true,
    attributeFilter: ['autocomplete'],
  });

  return false;
};

import PropTypes from 'prop-types';
import React from 'react';

import { getClassNames } from 'helpers/ui';

/**
 * Convenience component for modal footer's right content className.
 * @param {ComponentProps} props
 * @param {*} [props.children]
 * @param {DOMProps} domFriendlyRest
 * @returns {StatelessComponent}
 */
const ModalFooterContentRight = ({ children, ...domFriendlyRest }) => (
  <div {...domFriendlyRest} className={getClassNames(domFriendlyRest, { 'content--right': true })}>
    {children}
  </div>
);

ModalFooterContentRight.propTypes = {
  children: PropTypes.node,
};

ModalFooterContentRight.defaultProps = {
  children: undefined,
};

export default ModalFooterContentRight;

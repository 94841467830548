import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import { isDocumentStatusRejected } from 'helpers/document';
import { sortObjectArray } from 'helpers/sort';
import { hasLength, lastIndexOf } from 'helpers/utility';

import { companySelector } from './companiesSelectors';
import { currentMembershipSelector, membershipSelector } from './membershipsSelector';

const getState = (state) => state.documents;

/**
 * Selects the documents by id from the state
 * @param {object} state - Redux state
 */
export const documentsSelector = createSelector([getState], (documents) => documents.byId);

export const documentsIsFetching = createSelector([getState], (documents) => documents.isFetching);

/**
 * Selects the document ids belonging to a specific company
 * @param {object} state - Redux state
 * @param {string} companyId - Company id
 */
export const companyDocumentIdsSelector = createSelector([companySelector], (company) => {
  if (!company || !company.documents) {
    return [];
  }
  return company.documents;
});

/**
 * Selects the documents belonging to a specific company
 * @param {object} state - Redux state
 * @param {string} companyId - Company id
 */
export const companyDocumentsSelector = createSelector(
  [documentsSelector, companyDocumentIdsSelector],
  (documents, companyDocumentIds) =>
    Object.values(documents).filter((document) => companyDocumentIds.includes(document.id) && !document.isUserDocument),
);

/**
 * Selects the document ids belonging to a specific user
 * @param {object} state - Redux state
 */
export const currentMembershipDocumentsOwnedIdsSelector = createSelector(
  [currentMembershipSelector],
  (currentMembership) => {
    if (!currentMembership || !currentMembership.documentsOwned) {
      return [];
    }
    return currentMembership.documentsOwned;
  },
);

/**
 * Selects the documents belonging to a specific membership
 * @param {object} state - Redux state
 */
export const currentMembershipDocumentsSelector = createSelector(
  [documentsSelector, currentMembershipDocumentsOwnedIdsSelector],
  (documents, currentMembershipDocumentIds) =>
    currentMembershipDocumentIds &&
    currentMembershipDocumentIds.map((currentMembershipDocumentId) => documents[currentMembershipDocumentId]),
);

/**
 * Selects documents from the state with a rejected status
 * @param {object} state - Redux state
 */
export const rejectedDocumentsSelector = createSelector([documentsSelector], (documents) =>
  Object.values(documents).filter(isDocumentStatusRejected),
);

/**
 * Selects membership documents from the state with a rejected status
 * @param {object} state - Redux state
 */
export const rejectedMembershipDocumentsSelector = createSelector([rejectedDocumentsSelector], (rejectedDocuments) =>
  rejectedDocuments.filter((document) => document.isUserDocument),
);

/**
 * Selects the membership documents from the state with a rejected status for a given member
 * ie. 'give me all the failed docs for a biz rep. to know whether that rep has been verified/KYC'ed
 * @param {object} state - Redux state
 */
export const rejectedMembershipDocumentsByMembershipSelector = createSelector(
  [rejectedMembershipDocumentsSelector, membershipSelector],
  (documents, membership) =>
    Object.values(documents).filter((document) => membership.documentsOwned.includes(document.id)),
);

/**
 * Selects company documents from the state with a rejected status
 * @param {object} state - Redux state
 */
export const rejectedCompanyDocumentsSelector = createSelector([rejectedDocumentsSelector], (rejectedDocuments) =>
  rejectedDocuments.filter((document) => !document.isUserDocument),
);

/**
 * Selects membership documents from the state with a rejected status
 * @param {object} state - Redux state
 * @param {string} membershipId - membership id
 */
export const documentsByMembershipSelector = createSelector(
  [documentsSelector, membershipSelector],
  (documents, membership) =>
    Object.values(documents).filter((document) => membership.documentsOwned.includes(document.id)),
);

/**
 * Selects the last membership document from the state
 * @param {object} state - Redux state
 * @param {string} membershipId - membership id
 */
export const lastMembershipDocumentSelector = createSelector([documentsByMembershipSelector], (documents) => {
  if (!hasLength(documents)) {
    return null;
  }

  const lastDocumentIndex = lastIndexOf(documents);
  const options = { comparisonFunc: dayjs };
  return sortObjectArray(documents, 'created', options)[lastDocumentIndex];
});

export const documentsIsPosting = createSelector([getState], (documents) => documents.isPosting);

export const documentsIsDeleting = createSelector([getState], (documents) => documents.isDeleting);

import { isObject } from 'helpers/utility';
import {} from 'interfaces/sso';
import { BASE_API_URL } from './index';
export const membershipInviteEndpoint = (companyId, inviteId, token) => `${BASE_API_URL}/companies/${companyId}/invites/${inviteId}/?token=${token}`;
export const resendInviteEndpoint = (companyId, membershipId) => `${BASE_API_URL}/companies/${companyId}/invites/${membershipId}/resend/`;
export const revokeInviteEndpoint = (companyId, membershipId) => `${BASE_API_URL}/companies/${companyId}/invites/${membershipId}/delete/`;
export const acceptMembershipInviteEndpoint = (companyId, inviteId, token) => `${BASE_API_URL}/companies/${companyId}/invites/${inviteId}/accept/?token=${token}`;
export const acceptMembershipInviteSSOEndpoint = (companyId, inviteId, token) => `${BASE_API_URL}/companies/${companyId}/invites/${inviteId}/sso_accept/?token=${token}`;
export const getAcceptMembershipInviteEndpoint = ({ companyId, companySSOSettings, data, inviteId, token, }) => isObject(companySSOSettings) && !data.password
    ? acceptMembershipInviteSSOEndpoint(companyId, inviteId, token)
    : acceptMembershipInviteEndpoint(companyId, inviteId, token);
export const membershipInvitesEndpoint = (companyId) => `${BASE_API_URL}/companies/${companyId}/invites/`;

import PropTypes from 'prop-types';
import React from 'react';

import { TransactionFeeRelation } from 'components/relationTypes';

import { getBillingDataForBillingCode } from 'helpers/billing';
import { allKeys } from 'helpers/utility';

/**
 * Block element that renders multiple transaction fees.
 * @param {Object} billingDataByCode
 * @param {Object} feesByCode
 * @return {StatelessComponent}
 * @constructor
 */
const TransactionFees = ({ billingDataByCode, feesByCode }) => (
  <div className="transaction-details--fees">
    {allKeys(feesByCode).map((feeCode) => {
      const feeBillingData = getBillingDataForBillingCode(billingDataByCode, feeCode);
      const feeAmount = feesByCode[feeCode];

      return <TransactionFeeRelation billingData={feeBillingData} feeAmount={feeAmount} key={feeCode} />;
    })}
  </div>
);

TransactionFees.propTypes = {
  billingDataByCode: PropTypes.shape({}).isRequired,
  feesByCode: PropTypes.shape({}).isRequired,
};

export default TransactionFees;

import classNames from 'classnames';
import React from 'react';
import { IsLoading } from 'modules/isLoading/IsLoading';
import {} from './FloatingIsLoading.types';
const FloatingIsLoading = ({ className, floatDirection, color }) => (React.createElement("div", { className: classNames({
        [`loading--float-${floatDirection}`]: !!floatDirection,
        [className]: !!className,
    }) },
    React.createElement(IsLoading, { color: color })));
FloatingIsLoading.defaultProps = {
    floatDirection: 'left',
};
export default FloatingIsLoading;

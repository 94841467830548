import {} from 'react';
import { cva } from 'class-variance-authority';
export const SUB_HEADER_EVENT_VISIBLE = 'page:sub:header:visible';
export const tabClassnames = cva(['inline-flex', 'text-sm', 'items-center', 'focus:outline-0'], {
    variants: {
        selected: {
            true: ['text-black', 'border-b-2', 'border-b-black'],
            false: ['text-grey-60', 'border-b-2', 'border-b-transparent'],
        },
        disabled: {
            true: ['text-opacity-30', 'cursor-not-allowed'],
            false: ['cursor-pointer'],
        },
        error: {
            true: ['text-red-40'],
            false: [],
        },
    },
    defaultVariants: {
        selected: false,
        disabled: false,
        error: false,
    },
});

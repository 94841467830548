import { isBankAccountViewTypePartnerAny, isBankAccountViewTypeThreadAny } from 'helpers/ui';

/**
 * shouldShowBankAccountHeaderContentRight
 * @param {String} viewType
 * @param {Object} partnershipFundingAccount
 * @returns {boolean}
 */
export const shouldShowBankAccountHeaderContentRight = ({ viewType, partnershipFundingAccount }) =>
  !(
    isBankAccountViewTypeThreadAny(viewType) ||
    !isBankAccountViewTypePartnerAny(viewType) ||
    !partnershipFundingAccount
  );

import {
  submitFundingStateRoutine,
  submitPartnerCompanyContactInfoRoutine,
  submitPartnerCompanyGeneralInfoRoutine,
  submitTermsOfServiceRoutine,
} from 'actions/routines/external';

import { createIsSubmittingReducer } from 'store/redux';

/**
 * External onboarding isSubmitting reducer request cases
 * @constant
 * @type {ReduxType[]}
 */
const requestCases = [
  submitFundingStateRoutine.REQUEST,
  submitPartnerCompanyContactInfoRoutine.REQUEST,
  submitPartnerCompanyGeneralInfoRoutine.REQUEST,
  submitTermsOfServiceRoutine.REQUEST,
];

/**
 * External onboarding isSubmitting reducer finish cases
 * @constant
 * @type {ReduxType[]}
 */
const finishCases = [
  submitFundingStateRoutine.SUCCESS,
  submitFundingStateRoutine.FAILURE,
  submitPartnerCompanyContactInfoRoutine.SUCCESS,
  submitPartnerCompanyContactInfoRoutine.FAILURE,
  submitPartnerCompanyGeneralInfoRoutine.SUCCESS,
  submitPartnerCompanyGeneralInfoRoutine.FAILURE,
  submitTermsOfServiceRoutine.SUCCESS,
  submitTermsOfServiceRoutine.FAILURE,
];

/**
 * External onboarding is submitting reducer
 * @type {ReduxReducer}
 */
const externalOnboardingIsSubmittingReducer = createIsSubmittingReducer(finishCases, requestCases);

export { requestCases, finishCases };
export default externalOnboardingIsSubmittingReducer;

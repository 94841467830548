import { combineReducers } from 'redux';

import isAddPartnerFundingAccountOptionsOpenReducer from './isAddPartnerFundingAccountOptionsOpenReducer';
import notificationBarReducer from './notificationBarReducer';

const uiReducer = combineReducers({
  isAddPartnerFundingAccountOptionsOpen: isAddPartnerFundingAccountOptionsOpenReducer,
  notification: notificationBarReducer,
});

export default uiReducer;

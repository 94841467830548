import {
  INVITE_TEAM_MEMBER_MODAL,
  VIEW_TEAM_MEMBERS_MODAL,
  DEACTIVATE_TEAM_MEMBER_MODAL,
  LOGOUT_MODAL,
  OPEN_SUBMIT_SIGN_UP_FLOW_MODAL,
  CLOSE_SUBMIT_SIGN_UP_FLOW_MODAL,
  TOGGLE_SKIP_STEP_MODAL,
  SET_NEXT_VIEW,
} from 'types/signup';

const modalReducer = (
  state = {
    inviteTeamMember: false,
    viewTeamMembers: false,
    submitSignUpFlowModal: false,
    deactivateTeamMember: {},
    logout: false,
    skipStep: false,
  },
  action,
) => {
  if (action?.type === INVITE_TEAM_MEMBER_MODAL) {
    return {
      ...state,
      inviteTeamMember: !state.inviteTeamMember,
    };
  }
  if (action?.type === VIEW_TEAM_MEMBERS_MODAL) {
    return {
      ...state,
      inviteTeamMember: false, // close invite single team member
      viewTeamMembers: !state.viewTeamMembers,
    };
  }
  if (action?.type === DEACTIVATE_TEAM_MEMBER_MODAL) {
    return {
      ...state,
      deactivateTeamMember: {
        ...state.deactivateTeamMember,
        [action.payload.membershipId]: !state.deactivateTeamMember[action.payload.membershipId],
      },
    };
  }

  if (action?.type === LOGOUT_MODAL) {
    return {
      ...state,
      logout: !state.logout,
    };
  }

  if (action?.type === OPEN_SUBMIT_SIGN_UP_FLOW_MODAL) {
    return {
      ...state,
      submitSignUpFlowModal: true,
    };
  }
  if (action?.type === CLOSE_SUBMIT_SIGN_UP_FLOW_MODAL) {
    return {
      ...state,
      submitSignUpFlowModal: false,
    };
  }
  if (action?.type === TOGGLE_SKIP_STEP_MODAL) {
    return {
      ...state,
      skipStep: !state.skipStep,
    };
  }
  if (action?.type === SET_NEXT_VIEW) {
    return {
      ...state,
      nextView: action?.payload,
    };
  }
  return state;
};

export default modalReducer;

import React from 'react';
import { convertItemApprovalTypeToQuantity } from 'helpers/approvals';
import { convertNumberToOrdinalString } from 'helpers/stringHelpers';
const ApprovalLevelHeader = ({ levelPosition, levelType }) => {
    const levelTypeLabel = convertItemApprovalTypeToQuantity(levelType);
    const ordinal = convertNumberToOrdinalString(levelPosition);
    return (React.createElement("div", { className: "display--flex margin-bottom--xm" },
        React.createElement("span", { className: "font-semibold text-grey-60 text-xs" }, `${ordinal} approval`),
        React.createElement("span", null, "\u00A0"),
        React.createElement("span", { className: "font-light text-grey-60 text-xs" }, `(${levelTypeLabel} required)`)));
};
export default ApprovalLevelHeader;

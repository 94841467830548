import PropTypes from 'prop-types';
import React from 'react';

import Icon, { IconNames } from 'components/icon';
import { Col, Row } from 'components/layout';

import { breakpointNames } from 'constants/mediaQuery';
import { colorBluePrimaryHex } from 'constants/styles/colors';

import { formatItemAmount, isItemKindPayable } from 'helpers/items';

import { useCurrencyContext } from 'hooks';

const ItemBlockHeader = ({ actions, item }) => {
  const currencyCodeMap = useCurrencyContext();
  const iconName = isItemKindPayable(item) ? IconNames.UPLOAD : IconNames.DOWNLOAD;

  return (
    <Row
      breakpointProps={{
        [breakpointNames.phoneLarge]: {
          flexWrap: 'no-wrap',
        },
      }}
      className="item-block--header"
      flexWrap="wrap"
    >
      <Col className="display--flex align-items--center">
        <Icon color={colorBluePrimaryHex} icon={iconName} size={14} title="" />
        <h3 className="font-m bold font-color--greyXDark display--inline margin-left--xm">
          {formatItemAmount(currencyCodeMap, item)}
        </h3>
        <span className="font-xxs semibold font-color--dark-jordan margin-left--sm">{item.currencyCode}</span>
      </Col>
      {actions}
    </Row>
  );
};

ItemBlockHeader.propTypes = {
  actions: PropTypes.node,
  item: PropTypes.shape().isRequired,
};

ItemBlockHeader.defaultProps = {
  actions: undefined,
};

export default ItemBlockHeader;

import { createSlice } from '@reduxjs/toolkit';
import _pick from 'lodash/pick';
import { combineReducers } from 'redux';
const earItemSummarySlice = createSlice({
    initialState: null,
    name: 'itemSummary',
    reducers: {
        setApprovalItemSummary(state, action) {
            return Object.fromEntries(action.payload.map((as) => [as.objectId, _pick(as, 'canApprove', 'canApproveAndSend')]));
        },
    },
});
export const { name, actions: { setApprovalItemSummary }, reducer, } = earItemSummarySlice;
const approvalSummaryReducer = combineReducers({
    itemSummary: reducer,
});
export default approvalSummaryReducer;

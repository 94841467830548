import PropTypes from 'prop-types';
import React from 'react';

import { LinkNewPage, Text, WhiteSpace } from 'components';

import { TagText, TagType } from 'constants/ui';

import helpDocs from 'helpers/helpDocs';
import { uncapitalize } from 'helpers/stringHelpers';

/**
 * Component rendering the content of SendUpdatePaymentMethodLink tooltip
 * @param {ComponentProps} props
 * @param {string} props.partnerName
 * @returns {StatelessComponent}
 */
const SendUpdatePaymentMethodLinkTooltipContent = ({ partnerName }) => (
  <Text.Regular>
    {partnerName}
    <WhiteSpace />
    manages their own payment methods, so you are not able to request updates to their payment preferences. Learn more
    about
    <WhiteSpace />
    <LinkNewPage className="primary" href={helpDocs.SELF_MANAGED_COMPANIES}>
      {`${uncapitalize(TagText[TagType.SELF_MANAGED])} companies`}
    </LinkNewPage>
    .
  </Text.Regular>
);

SendUpdatePaymentMethodLinkTooltipContent.propTypes = {
  partnerName: PropTypes.string.isRequired,
};

export default SendUpdatePaymentMethodLinkTooltipContent;

import { Popover, Checkbox, Button } from '@routable/gross-ds';
import { SettingsAdjust } from '@carbon/icons-react';
import { useTableStore } from '../';
import React from 'react';
import { useOverlayTriggerState } from 'react-stately';
import {} from '@routable/framework';
const pullHeaderConfigFromTableStore = (tableState) => {
    const { headers } = tableState;
    const config = Object.fromEntries(headers.map(({ columnId }) => [columnId, true]));
    if (headers) {
        for (const key in headers) {
            config[key] = headers[key];
        }
    }
    return config;
};
export const useColumnSelect = ({ headerConfig, tableName, }) => {
    const { activeTableState, setTableStateItem } = useTableStore(tableName);
    const state = useOverlayTriggerState({});
    const [newHeaderConfig, setNewHeaderConfig] = React.useState(headerConfig);
    const [checkboxesState, setCheckboxesState] = React.useState({});
    const pullHeaderConfigFromIndexDbAndSetAsync = async () => {
        if (activeTableState) {
            setCheckboxesState(pullHeaderConfigFromTableStore(activeTableState));
        }
    };
    React.useEffect(() => {
        pullHeaderConfigFromIndexDbAndSetAsync();
    }, []);
    const handleSubmit = () => {
        setNewHeaderConfig(headerConfig.map((header) => ({
            ...header,
            initialSettings: {
                ...header.initialSettings,
                hidden: !checkboxesState[header.columnId],
            },
        })));
        setTableStateItem('headers', checkboxesState);
        state.close();
    };
    const handleCheckboxToggle = (columnId) => () => setCheckboxesState({ ...checkboxesState, [columnId]: !checkboxesState[columnId] });
    return {
        columnSelect: (React.createElement("div", null,
            React.createElement(Popover, { placement: "bottom right", keepOpen: true, state: state, content: React.createElement(Button, { "data-testid": "column-settings", intent: "secondary", variant: "floating" },
                    React.createElement(SettingsAdjust, null)) },
                React.createElement("div", { className: "p-5 pr-9 flex flex-col gap-3 text-grey-70" }, headerConfig.map(({ columnId, caption }) => (React.createElement(Checkbox, { checked: checkboxesState[columnId], key: columnId, label: caption, name: columnId, onChange: handleCheckboxToggle(columnId) })))),
                React.createElement("div", { className: "p-5 text-right border-t-grey-10" },
                    React.createElement(Button, { "data-testid": "columns-config-apply", intent: "secondary", variant: "outline", onPress: handleSubmit }, "Apply"))))),
        headerConfig: newHeaderConfig,
    };
};

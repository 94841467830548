import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2, LinkNewPage } from 'components';

/**
 * InvoiceListText component that can render a button instead of plain text.
 * @param {ComponentProps} props
 * @param {string} [props.buttonLabel
 * @param {string} [props.fallbackText]
 * @param {boolean} [props.hasURL]
 * @param {string} [props.itemURL]
 * @param {Object} props.rowData
 * @returns {StatelessComponent}
 */
const InvoiceButton = ({ buttonLabel, fallbackText, hasURL, itemURL, rowData }) => {
  if (!hasURL || !rowData.showButton) {
    return null;
  }

  return (
    <LinkNewPage className="enable-pointer-events" href={itemURL}>
      <ButtonV2>{buttonLabel || fallbackText}</ButtonV2>
    </LinkNewPage>
  );
};

InvoiceButton.propTypes = {
  buttonLabel: PropTypes.string,
  fallbackText: PropTypes.string,
  hasURL: PropTypes.bool,
  itemURL: PropTypes.string,
  rowData: PropTypes.shape().isRequired,
};

InvoiceButton.defaultProps = {
  buttonLabel: undefined,
  fallbackText: undefined,
  hasURL: undefined,
  itemURL: undefined,
};

export default InvoiceButton;

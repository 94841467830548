import * as routines from 'actions/routines/currentCompany';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';

import { GET_CURRENT_COMPANY_SUCCESS } from 'types/currentCompany';

const companyItemApprovalLevelsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CURRENT_COMPANY_SUCCESS:
    case routines.fetchCurrentCompanySettingsItemApprovalRoutine.SUCCESS:
      return getObjectsByIdWithRelationships(action.payload.companySettingsItemApprovalLevel, ['qualifiedApprovers']);

    default:
      return state;
  }
};

export default companyItemApprovalLevelsReducer;

import { allKeys, uniqueArray } from 'helpers/utility';

import * as types from 'types/item';

const allInvoicesReducer = (state = [], action) => {
  switch (action.type) {
    case types.CLEAR_INVOICES:
      return [];

    case types.FETCH_INVOICES_SUCCESS:
      return allKeys(action.payload.itemImport);

    case types.FETCH_ADDITIONAL_INVOICES_SUCCESS:
      return uniqueArray([...state, ...allKeys(action.payload.itemImport)]);

    default:
      return state;
  }
};

export default allInvoicesReducer;

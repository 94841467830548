import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { formNamesAuthV2 } from 'constants/forms';
import { noop } from 'helpers/utility';
import { isSubmittingResetPasswordSelector } from 'selectors/authSelectors';
import ResetPasswordForm from './ResetPasswordForm';
export const mapStateToProps = createStructuredSelector({
    isSubmitting: isSubmittingResetPasswordSelector,
});
export const ResetPasswordReduxForm = reduxForm({
    form: formNamesAuthV2.RESET_PASSWORD,
    onSubmitFail: noop,
})(ResetPasswordForm);
const enhance = compose(connect(mapStateToProps));
export default enhance(ResetPasswordReduxForm);

import clsx from 'clsx';
import ReactDOM from 'react-dom';
import swal from 'sweetalert';

/**
 * Function for showing SWAL
 * @param {Object} options
 * @param {StatelessComponent|JSX.Element|React.FC} options.Content
 * @param {Object} [options.config={}]
 */
export const showSwal = ({ Content, config = {} }) => {
  const wrapper = document.createElement('div');

  ReactDOM.render(Content, wrapper, () => {
    swal({
      className: 'swal-error',
      content: wrapper.firstChild,
      buttons: false,
      ...config,
    });
  });
};

/**
 * Simple helper function to retrieve SWAL footer className based
 * on given intent
 * @param {string} intent
 * @param {string} addedClassname
 * @return {string}
 */
export const getSwalFooterClassname = (intent, addedClassname) =>
  clsx(`swal-wrapper__footer--${intent}`, addedClassname);

import { createErrorReducer } from 'store/redux';

import {
  SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_FAILURE,
  SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_REQUEST,
  SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_SUCCESS,
} from 'types/connectBank';

export const failureCases = [SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_FAILURE];
export const successCases = [SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_REQUEST, SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_SUCCESS];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { exitRoutedModal } from 'helpers/navigation';

/**
 * A hook that closes a full-screen modal rendered at a specific app route.
 */
const useExitRoutedModal = () => {
  const history = useHistory();
  const location = useLocation();

  return useCallback(() => {
    exitRoutedModal(history, location);
  }, [history, location]);
};

export default useExitRoutedModal;

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { formNamesSignup } from 'constants/forms';
import { onSubmitFailReduxForm } from 'helpers/errors';
import { getCurrentCompanyId, getCurrentMembershipId } from 'helpers/localStorage';
import { currentCompanyNamespaceSelector } from 'selectors/currentCompanySelectors';
import { isSubmittingSignupSelectNamespaceSelector, isFetchingNamespaceSelector, isNamespaceValidSelector, } from 'selectors/signupSelectors';
import submitSignupSelectNamespace from 'thunks/submitSignupSelectNamespace';
import SelectWorkspace from './SelectWorkspace';
export const mapStateToProps = (state) => ({
    initialValues: {
        id: getCurrentMembershipId(),
        company: {
            id: getCurrentCompanyId(),
            namespace: currentCompanyNamespaceSelector(state) || '',
        },
    },
    isFetchingNamespace: isFetchingNamespaceSelector(state),
    isNamespaceValid: isNamespaceValidSelector(state),
    isSubmitting: isSubmittingSignupSelectNamespaceSelector(state),
});
export const mapDispatchToProps = {
    onSubmitSignupSelectNamespace: submitSignupSelectNamespace,
};
const reduxFormWrapperSelectNameSpaceForm = reduxForm({
    enableReinitialize: true,
    form: formNamesSignup.SELECT_NAMESPACE,
    onSubmitFail: onSubmitFailReduxForm,
})(SelectWorkspace);
const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));
export default enhance(reduxFormWrapperSelectNameSpaceForm);

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { Hint, HintText } from 'components/hint';

import { getQueryParam } from 'helpers/queryParams';

import { partnershipSelector } from 'selectors/partnershipsSelectors';

const BlockUpdatePaymentMethodHint = ({ partnership }) => {
  if (!partnership.defaultReceivableFundingAccount) {
    return null;
  }

  return (
    <Hint className="margin-bottom--large" intent="danger" multiline>
      <HintText>
        {`To update your payment method please contact your direct representative at ${partnership.name}.`}
      </HintText>
    </Hint>
  );
};

BlockUpdatePaymentMethodHint.propTypes = {
  partnership: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => {
  const partnershipId = getQueryParam('partnership_id');

  return {
    partnership: partnershipSelector(state, partnershipId),
  };
};

export { BlockUpdatePaymentMethodHint };
export default connect(mapStateToProps)(BlockUpdatePaymentMethodHint);

import { createSelector } from 'reselect';

/**
 * Selection function for item members top-level state
 * @param {{ itemMembers: Object.<string, Object> }} state
 * @return {Object.<string, Object>}
 */
export const getState = (state) => state.itemMembers;

/**
 * Selects the itemMembers.byId object.
 * @function
 * @param {ReduxState} state
 * @return {Object}
 */
export const itemMembersByIdSelector = createSelector([getState], (itemMembers) => itemMembers.byId);

/**
 * Selects the itemMembers.allIds array.
 * @function
 * @param {ReduxState} state
 * @return {string[]}
 */
export const itemMembersAllIdsSelector = createSelector([getState], (itemMembers) => itemMembers.allIds);

import dayjs from 'dayjs';
import tk from 'timekeeper';

import { toggleItemQueueingInLocalStorage } from 'helpers/debug/bulkActions';
import {
  getFeatureFlagsFromLocalStorage,
  removeFeatureFlagsFromLocalStorage,
  removeSingleFeatureFlagFromLocalStorage,
  setSingleFeatureFlagInLocalStorage,
} from 'helpers/featureFlags';

window.routable.debug.bulkActions = {
  toggleItemQueueing: toggleItemQueueingInLocalStorage,
};

/**
 * In development, allow time traveling and other date-manipulation.
 * The window.routable.debug object is created in prelaunch.js, and
 * will thus exist by this time.
 * @type {Object.<string, Function>}
 */
window.routable.debug.time = {
  /**
   * Freeze time at the datetime given.
   * @function
   * @param {Date|string|number} datetime
   */
  freeze: tk.freeze,
  /**
   * Restore Date.
   * @function
   */
  restore: tk.reset,
  /**
   * Set time to today, at the hour given.
   * @function
   * @param {number} hour
   */
  set: (hour) => {
    tk.travel(dayjs().hour(hour).toDate());
  },
  /**
   * Travel in time to the datetime given.
   * @function
   * @param {Date|string|number} datetime
   */
  travel: tk.travel,
};

window.routable.debug.tablematic = {
  showHiddenFields: false,
};

window.routable.debug.featureFlags = {
  log: () => console.log(getFeatureFlagsFromLocalStorage()),
  remove: removeSingleFeatureFlagFromLocalStorage,
  removeAll: removeFeatureFlagsFromLocalStorage,
  set: setSingleFeatureFlagInLocalStorage,
};

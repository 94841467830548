import { Tooltip } from '@routable/components';
import PropTypes from 'prop-types';
import React from 'react';

import { IconNames } from 'components/icon';
import { TooltipIcon } from 'components/tooltip';

import { Position } from 'enums/ui';

import { isBrowserSupportedForFileViewer } from 'helpers/browser';
import { noDefaultEventWithCallback } from 'helpers/events';

import BaseRelation from '../BaseRelation';

import { DocumentRelationFileNameLink } from './components';

/**
 * Displays a document related to the resource being viewed.
 * @param props
 * @return {StatelessComponent}
 */
const DocumentRelation = (props) => {
  const { fileName, fileUrl, onDownload } = props;

  const isFileViewerSupported = isBrowserSupportedForFileViewer();

  // if file viewer isn't supported, we override on onClick with a call to
  // download the file (and also we show the 'download' tooltip text)
  const linkOnClickHandler = isFileViewerSupported ? undefined : (evt) => noDefaultEventWithCallback(evt, onDownload);
  const tooltipContent = isFileViewerSupported ? 'Open document in a new tab' : 'Click to download';

  return (
    <BaseRelation className="relation_document">
      <Tooltip position="right" tooltip={tooltipContent}>
        <DocumentRelationFileNameLink fileName={fileName} fileUrl={fileUrl} onClick={linkOnClickHandler} />
      </Tooltip>

      <TooltipIcon
        content="Click to download"
        icon={IconNames.CLOUD_DOWNLOAD}
        iconClassName="relation_document--icon-right"
        iconProps={{ onClick: onDownload }}
        tooltipPosition={Position.LEFT}
      />
    </BaseRelation>
  );
};

DocumentRelation.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired,
  onDownload: PropTypes.func.isRequired,
};

export default DocumentRelation;

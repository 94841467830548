import { membershipInviteAcceptV2Routine, membershipInviteSubmitRoutine } from 'actions/routines/inviteTeamMember';

import { createIsSubmittingReducer } from 'store/redux';

import { CLEAR_MEMBERSHIP_INVITE } from 'types/inviteTeamMember';

export const finishCases = [
  CLEAR_MEMBERSHIP_INVITE,
  membershipInviteAcceptV2Routine.FAILURE,
  membershipInviteAcceptV2Routine.SUCCESS,
  membershipInviteSubmitRoutine.FAILURE,
  membershipInviteSubmitRoutine.SUCCESS,
];
export const requestCases = [membershipInviteAcceptV2Routine.REQUEST, membershipInviteSubmitRoutine.REQUEST];

const submitReducer = createIsSubmittingReducer(finishCases, requestCases);

export default submitReducer;

import { DateFormats } from '@routable/shared';
import accounting from 'accounting';
import dayjs from 'dayjs';
import _get from 'lodash/get';
import _isObject from 'lodash/isObject';

import { DateStringLength } from 'constants/temporal';

import { isString } from 'helpers/utility';

/**
 * Return a number by stripping non-numeric characters
 * @param value
 * @return number
 */
export const numberOnly = (value) => accounting.unformat(value);

/**
 * Return a digits only
 * @param value
 * @return {String}
 */
export const digitsOnly = (value) => {
  if (!value) {
    return value;
  }

  return value.replace(/[^\d]/g, '');
};

/**
 * Return a digits and letters only omitting any special chars
 * @param value
 * @return {String}
 */
export const digitsAndLetters = (value) => {
  if (!value) {
    return value;
  }

  return value.replace(/[^a-zA-Z0-9]/g, '');
};

/**
 * Return the id of react select selection or empty string
 * @param value
 * @return {*}
 */
export const reactSelectIdValue = (value) => {
  if (!value || !value.id) {
    return '';
  }

  return value.id;
};

/**
 * If value is an object, returns extracted val for key, otherwise returns value.
 * @param value
 * @param key
 * @return {*}
 */
export const extractPropIfObject = (value, key) => {
  if (_isObject(value)) {
    return _get(value, key);
  }

  return value;
};

/**
 * Normalize date for server submission
 * @param value
 * @return {string}
 */
export const normalizeDate = (value) => {
  if (!value || value.length < DateStringLength.BIRTH_DATE) {
    return '';
  }

  return dayjs(value, DateFormats.FULL_NUMERIC_MONTH_DAY_YEAR_SLASHED).format(DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY);
};

/**
 * Trim leading and trailing spaces from a string.
 * If non-string given returns the original value.
 * @param {*} value
 * @return {*}
 */
export const trimString = (value) => {
  if (isString(value)) {
    return value.trim();
  }
  return value;
};

export const trimStartString = (value) => {
  if (isString(value)) {
    return value.trimStart();
  }
  return value;
};

import { Button, Menu, useToastController } from '@routable/gross-ds';
import { useToggleVendorTaxCollection } from '@routable/taxes';
import React from 'react';
import { FEATURE_FLAG_TAX_EXCLUDE_VENDOR } from 'constants/featureFlags';
import { useFeatureFlag } from 'hooks';
export const ToggleVendorTaxCollection = ({ displayType, partnershipId, partnershipName, }) => {
    const taxExcludeVendorEnabled = useFeatureFlag(FEATURE_FLAG_TAX_EXCLUDE_VENDOR);
    const toaster = useToastController();
    const { isLoading: isTogglingTaxCollection, actionNode: toggleTaxCollectionActionNode, mutate: toggleTaxCollection, } = useToggleVendorTaxCollection();
    const handleToggleTaxCollection = () => {
        toggleTaxCollection(partnershipId, {
            onSuccess: (updatedStatus) => {
                const message = updatedStatus === 'disabled'
                    ? `${partnershipName} excluded from automated tax collection`
                    : `Automated tax collection re-enabled for ${partnershipName}`;
                toaster.addToast({ type: 'success', message });
            },
        });
    };
    if (!taxExcludeVendorEnabled) {
        return null;
    }
    if (displayType === 'dropdown') {
        return (React.createElement(Menu, { "data-testid": "company-tax-and-docs-additional-actions-menu", intent: "secondary", placement: "bottom", size: "small" },
            React.createElement(Menu.Item, { key: "toggleExcludeVendorFromAutomatedTaxRequests", onAction: handleToggleTaxCollection, textValue: "Toggle vendor automated tax collection" }, toggleTaxCollectionActionNode)));
    }
    return (React.createElement(Button, { "data-testid": "button--toggle-exclude-vendor-from-automated-tax-requests", loading: isTogglingTaxCollection, onPress: handleToggleTaxCollection, size: "small", variant: "outline" }, toggleTaxCollectionActionNode));
};

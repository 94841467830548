import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'components/icon';
import { TooltipMUI } from 'components/tooltip';

import { sizes } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';

import { getAsyncValidateStatusFromProps, getAsyncValidateIconProps, isAsyncValidateStatusLoading } from 'helpers/ui';
import { becameFalsy } from 'helpers/utility';

import usePrevious from 'hooks/usePrevious';

import { IsLoadingInline } from 'modules/isLoading';

/**
 * Using values from redux-form meta, display the correct icon as a child of the input in ReduxFormFieldRenderInput.
 * @param {boolean} isWarn
 * @param {ReduxFormMeta} meta
 * @param {string} tooltip
 * @returns {null|FunctionComponent}
 * @constructor
 */
const AsyncInputStatusIcon = ({ isEmpty, isWarn, meta, tooltip }) => {
  const { asyncValidating } = meta;
  const [hasAsyncValidated, setHasAsyncValidated] = React.useState(false);
  const prevAsyncValidating = usePrevious(asyncValidating);

  React.useEffect(() => {
    // When async validation completes its run
    if (becameFalsy(prevAsyncValidating, asyncValidating)) {
      setHasAsyncValidated(true);
    } else if (isEmpty) {
      // set hasAsyncValidated back to false if the input is cleared
      setHasAsyncValidated(false);
    }
  }, [asyncValidating, prevAsyncValidating, isEmpty]);

  const status = getAsyncValidateStatusFromProps({
    hasAsyncValidated,
    isWarn,
    meta,
  });
  const { iconColor, iconType, showIcon } = getAsyncValidateIconProps(status);

  if (showIcon && tooltip) {
    return (
      <TooltipMUI
        arrow
        className="input-icon-wrapper right tooltip"
        padding={TooltipPadding.SKINNY}
        placement={TooltipPlacement.TOP}
        title={tooltip}
      >
        <Icon color={iconColor} icon={iconType} size={sizes.iconSizes.LARGE} />
      </TooltipMUI>
    );
  }

  if (showIcon) {
    return (
      <div className="input-icon-wrapper right">
        <Icon color={iconColor} icon={iconType} size={sizes.iconSizes.LARGE} />
      </div>
    );
  }

  if (isAsyncValidateStatusLoading(status)) {
    return (
      <div className="input-icon-wrapper right">
        <IsLoadingInline color={iconColor} />
      </div>
    );
  }

  return null;
};

AsyncInputStatusIcon.propTypes = {
  isEmpty: PropTypes.bool.isRequired,
  isWarn: PropTypes.bool,
  meta: PropTypes.shape(),
  tooltip: PropTypes.string,
};

AsyncInputStatusIcon.defaultProps = {
  isEmpty: true,
  isWarn: undefined,
  meta: {},
  tooltip: '',
};

export default AsyncInputStatusIcon;

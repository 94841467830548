import styled, { css } from 'styled-components';
export const Container = styled.div `
  position: sticky;
  position: -webkit-sticky;
  top: calc(var(--table-actions-height, 0px) + var(--table-selections-height, 0px));
  background-color: var(--table-header-bg-color);
  border-bottom: 1px solid var(--color-grey20) !important;
  z-index: 10;

  ${(props) => !props.isCondensed &&
    css `
      border-top: 1px solid var(--color-grey20) !important;
    `}

  // Since we're adding a border to the header, and the header is rendering
  // a row, we don't want to display a border in the row component to avoid
  // double appearance
  & > .table-row {
    border-bottom: none;
    height: unset;
  }
`;

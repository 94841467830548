import { RoutableEventsSync } from '@routable/framework';
import { Checkbox } from '@routable/gross-ds';
import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { useTableEvent, useDispatchTableEvent } from '../../hooks';
import { TableContext } from '../../table-name.context';
import { useTableStore } from '../../table.store';
import {} from './rowselect.types';
export const RowSelect = forwardRef(({ isCheckAll = false, isDisabled, onChange, shouldTriggerOnChange = true, value = '', valueKey = 'id' }, ref) => {
    const tableName = useContext(TableContext).tableName;
    const { getTableStateItem, setTableStateItem } = useTableStore(tableName);
    const [isChecked, setIsChecked] = useState(false);
    const [isSemiChecked, setIsSemiChecked] = useState(false);
    const changeAllSelection = useDispatchTableEvent({
        tableName,
        event: 'selected:update:all',
    });
    const updateTableSelection = (newDataSelection) => {
        setTableStateItem('selections', newDataSelection);
        RoutableEventsSync.Publish(`table:${tableName}:selected:updated`, newDataSelection);
    };
    useTableEvent({
        tableName,
        event: 'selected:update:all',
        fn: (data) => {
            if (!isCheckAll) {
                const currentSelection = getTableStateItem('selections');
                const updatedSelectionWithoutDuplicates = new Set([...currentSelection, ...data]);
                updateTableSelection([...updatedSelectionWithoutDuplicates]);
                setIsChecked(data.includes(value));
            }
        },
    });
    const changeSingleSelection = useDispatchTableEvent({
        tableName,
        event: 'selected:update:single',
    });
    useTableEvent({
        tableName,
        event: 'selected:update:single',
        fn: (data) => {
            if (data.includes(value)) {
                const currentTableSelection = [...getTableStateItem('selections')];
                if (currentTableSelection.includes(value)) {
                    currentTableSelection.splice(currentTableSelection.indexOf(value), 1);
                }
                else {
                    currentTableSelection.push(value);
                }
                updateTableSelection(currentTableSelection);
                setIsChecked(currentTableSelection.includes(value));
            }
        },
    });
    useTableEvent({
        tableName,
        event: 'selected:updated',
        fn: (data) => {
            if (isCheckAll) {
                const cachedData = getTableStateItem('cache');
                const pageIds = (cachedData || []).filter((item) => !item.isDisabled).map((item) => item[valueKey]);
                const areAllChecked = pageIds.every((id) => data.includes(id));
                const areAnyChecked = pageIds.some((id) => data.includes(id));
                setIsChecked(areAllChecked);
                setIsSemiChecked(!areAllChecked && areAnyChecked);
            }
        },
    });
    useTableEvent({
        tableName,
        event: 'data',
        fn: () => {
            if (isCheckAll) {
                const cachedData = getTableStateItem('cache');
                const pageIds = (cachedData || []).filter((item) => !item.isDisabled).map((item) => item[valueKey]);
                const currentTableSelection = getTableStateItem('selections');
                const areAllChecked = !!pageIds.length && pageIds.every((id) => currentTableSelection.includes(id));
                const areAnyChecked = !!pageIds.length && pageIds.some((id) => currentTableSelection.includes(id));
                setIsChecked(areAllChecked);
                setIsSemiChecked(!areAllChecked && areAnyChecked);
            }
        },
    });
    const onChangeEvent = () => {
        if (isCheckAll) {
            const cachedData = getTableStateItem('cache');
            const pageIds = (cachedData || []).filter((item) => !item.isDisabled).map((item) => item[valueKey]);
            const currentTableSelection = getTableStateItem('selections');
            const newIsChecked = !isChecked;
            setIsChecked(newIsChecked);
            const newTableSelection = newIsChecked
                ? Array.from(new Set([...currentTableSelection, ...pageIds]))
                : currentTableSelection.filter((id) => !pageIds.includes(id));
            changeAllSelection(newTableSelection);
            updateTableSelection(newTableSelection);
        }
        else {
            changeSingleSelection([value]);
        }
        if (onChange) {
            onChange(value);
        }
        return true;
    };
    useEffect(() => {
        const pageIds = getTableStateItem('cache').map?.((item) => item[valueKey]) || [];
        const rowSelections = getTableStateItem('selections');
        if (rowSelections.includes(value)) {
            setIsChecked(true);
        }
        if (rowSelections.length > 0 && isCheckAll) {
            if (pageIds.every((id) => rowSelections.includes(id))) {
                setIsChecked(true);
            }
            else if (pageIds.some((id) => rowSelections.includes(id))) {
                setIsSemiChecked(true);
            }
        }
    }, []);
    return (React.createElement("div", null,
        React.createElement(Checkbox, { ref: ref, onChange: shouldTriggerOnChange ? onChangeEvent : undefined, checked: isChecked, indeterminate: isSemiChecked, disabled: isDisabled, name: `table_select_check_${isCheckAll ? 'all' : 'single'}`, value: value })));
});

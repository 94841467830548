import { createSelector } from 'reselect';

import {
  getItemKindFromCreateItemPath,
  getItemKindFromEditItemPath,
  getItemKindFromExternalV2Path,
  isCurrentPathCreateItemBillView,
  isCurrentPathItemEdit,
} from 'helpers/items';
import { getQueryParam } from 'helpers/queryParams';
import { getLastPathSegment, getSecondLastPathSegment } from 'helpers/urls';

import { createConditionalLogicSelector, propsSelector } from 'selectors/globalSelectors';

export const matchSelector = createSelector([propsSelector], (props) => (props && props.match) || {});

export const routeParamsSelector = createSelector([matchSelector], (match) => match.params || {});

export const tabParamSelector = createSelector([routeParamsSelector], (params) => params.tab);

export const filterParamSelector = createSelector([routeParamsSelector], (params) => params.filter);

export const idParamSelector = createSelector([routeParamsSelector], (params) => params.id);

/**
 * Selects an id
 * @type {import('reselect').Selector<import('interfaces/redux').ReduxState, Location>}
 */
export const locationSelector = createSelector([propsSelector], (props) => props?.location || window.location);

export const searchQuerySelector = createSelector([locationSelector], (location) => getQueryParam('search', location));

export const partnershipIdQuerySelector = createSelector([locationSelector], (location) =>
  getQueryParam('partnership_id', location),
);

export const idQuerySelector = createSelector([locationSelector], (location) => getQueryParam('id', location));

export const itemIdQuerySelector = createSelector([locationSelector], (location) => getQueryParam('item_id', location));

/**
 * Gets the `company_id` value from query
 * @type {StandardSelector}
 */
export const companyIdQuerySelector = createSelector([locationSelector], (location) =>
  getQueryParam('company_id', location),
);

export const membershipIdQuerySelector = createSelector([locationSelector], (location) =>
  getQueryParam('membership_id', location),
);

/**
 * Gets the `invite_id` value from query
 * @type {StandardSelector}
 */
export const membershipInviteIdQuerySelector = createSelector([locationSelector], (location) =>
  getQueryParam('invite_id', location),
);

/**
 * Gets the `invite_token` value from query
 * @type {StandardSelector}
 */
export const membershipInviteTokenQuerySelector = createSelector([locationSelector], (location) =>
  getQueryParam('invite_token', location),
);

export const membershipRequesterQuerySelector = createSelector([locationSelector], (location) =>
  getQueryParam('requester_id', location),
);

/**
 * Gets the url query value for bulk_import.
 * @type {StandardSelector}
 */
export const bulkImportQuerySelector = createSelector([locationSelector], (location) =>
  getQueryParam('csv_upload', location),
);

/**
 * Derives the kind of item being created from the value of props.location.pathname
 * @type {StandardSelector}
 */
export const createItemKindFromLocationSelector = createSelector([locationSelector], (location) =>
  getItemKindFromCreateItemPath(location),
);

/**
 * Selects item kind from External V2 location
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {ItemKinds}
 */
export const itemKindFromExternalV2LocationSelector = createSelector([locationSelector], (location) =>
  getItemKindFromExternalV2Path(location),
);

/**
 * Get the ItemKind from the edit route.
 *
 * @type {StandardSelector}
 * @param {ReduxState} state - unused
 * @param {ComponentProps} ownProps
 * @param {Location} ownProps.location
 * @returns {ItemKinds}
 */
export const editItemKindFromLocationSelector = createSelector([locationSelector], (location) =>
  getItemKindFromEditItemPath(location),
);

/**
 * Get the correct ItemKind selector based on the route; whether we are creating or editing an item.
 *
 * @type {StandardSelector}
 * @param {ReduxState} state - unused
 * @param {ComponentProps} ownProps
 * @param {Location} ownProps.location
 * @returns {StandardSelector}
 */
export const getItemKindSelectorFromLocationSelector = createSelector([locationSelector], (location) =>
  isCurrentPathItemEdit(location) ? editItemKindFromLocationSelector : createItemKindFromLocationSelector,
);

/**
 * Get the correct ItemKind whether creating or editing and item.
 *
 * @param {ReduxState} state - unused
 * @param {ComponentProps} ownProps
 * @param {Location} ownProps.location
 * @returns {ItemKinds}
 */
export const getItemKindFromLocationSelector = createConditionalLogicSelector(getItemKindSelectorFromLocationSelector);

/**
 * Derives whether the bill view is open, from the value of props.location.pathname
 * @type {StandardSelector}
 * @returns {boolean}
 */
export const createItemIsBillViewFromLocationSelector = createSelector([locationSelector], (location) =>
  isCurrentPathCreateItemBillView(location),
);

/**
 * Selects the last segment from the value of props.location.pathname
 * @type {StandardSelector}
 * @returns {string}
 */
export const lastSegmentInLocationSelector = createSelector([locationSelector], (location) =>
  getLastPathSegment(location),
);

/**
 * Selects the second last segment from the value of props.location.pathname
 * @type {StandardSelector}
 * @returns {string}
 */
export const secondLastSegmentInLocationSelector = createSelector([locationSelector], (location) =>
  getSecondLastPathSegment(location),
);

export const bulkActionIdQuerySelector = createSelector([locationSelector], (location) =>
  getQueryParam('bulk_action', location),
);

export const bulkActionStatusQuerySelector = createSelector([locationSelector], (location) =>
  getQueryParam('bulk_action_status', location),
);

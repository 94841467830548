/**
 * @fileOverview Shared data-testid constant values for full page modals
 *
 * @module constants/dataCy/fullPageModal
 */

/**
 * @type {Object<string, string>}
 */
export const fullPageModalHeader = {
  MODAL_NAV_TAB_TEXT: 'modal_nav_tab_text',
};

/**
 * @fileOverview Defines constants related to feature settings.
 * @module constants/featureSettings
 */

// the standard prefix for settings used in env and on the backend,
// which we strip out before putting into state or local storage
export const FEATURE_SETTING_NAME_PREFIX = 'FEATURE_SETTING_';

// the name of the csv export max rows setting in state
export const FEATURE_SETTING_NAME_CSV_EXPORT_MAX_ROWS = 'csvExportMaxRows';

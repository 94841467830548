import React from 'react';

import { reduxFormInputPropType } from 'components/commonProps';

import InvoiceGeneratorTotalsDiscount from '../InvoiceGeneratorTotalsDiscount';

const InvoiceGeneratorTotalsDiscountPostTax = ({ input, ...rest }) => {
  const { value } = input;

  if (!parseFloat(value)) {
    return null;
  }

  return <InvoiceGeneratorTotalsDiscount {...rest} input={input} />;
};

InvoiceGeneratorTotalsDiscountPostTax.propTypes = {
  input: reduxFormInputPropType.isRequired,
};

export default InvoiceGeneratorTotalsDiscountPostTax;

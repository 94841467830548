import React from 'react';

import * as swal from 'helpers/swal';

import DocumentModal from 'modules/signup-v3/components/DocumentModal';

export const openDocumentModal = (e) => {
  e.preventDefault();

  swal.showSwal({
    Content: <DocumentModal />,
  });
};

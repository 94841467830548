import { TruncatableText } from '@routable/components';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'components/icon';
import { Text } from 'components/text';

import { colors, sizes, typography } from 'constants/styles';

import { getClassNames } from 'helpers/ui';
import { isArray, isLessThan } from 'helpers/utility';

/**
 * Renders inner text content for atomic or array (multi-line) values,
 * for BlockListItemValueContent.
 * @param {ComponentProps} props
 * @param {StringMaybe} props.url
 * @param {string|string[]} props.value
 * @param {string} props.valueIcon
 * @param {Object} props.rest
 * @return {StatelessComponent}
 */
const BlockListItemValueTextContent = ({
  dataFullStory,
  url,
  value,
  valueIcon,
  valueTextContentClassName,
  ...rest
}) => {
  const hasUrl = !!url;
  const hasTextValue = typeof value === 'string';

  const valueTextContentClasses = {
    'font-color--primary_force': hasUrl,
    bold: hasUrl,
    [valueTextContentClassName]: !!valueTextContentClassName,
  };

  if (isArray(value)) {
    return value.map((v, idx) => (
      <Text.Regular
        className={getClassNames(rest, {
          'block--multi-text': true,
          'margin-top--xs': isLessThan(idx, 1),
          ...valueTextContentClasses,
        })}
        dataFullStory={dataFullStory}
        key={v}
        size={typography.TextSize.LEVEL_100}
      >
        {v}
      </Text.Regular>
    ));
  }

  return (
    <Text.Regular
      className={getClassNames(rest, valueTextContentClasses)}
      dataFullStory={dataFullStory}
      size={typography.TextSize.LEVEL_100}
    >
      {valueIcon && (
        <Icon
          color={colors.colorMainJordan}
          icon={valueIcon}
          marginRight={sizes.spacing.SMALL}
          size={sizes.iconSizes.EXTRA_MEDIUM}
        />
      )}
      {hasTextValue ? <TruncatableText text={value} /> : value}
    </Text.Regular>
  );
};

BlockListItemValueTextContent.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  value: PropTypes.node,
  valueIcon: PropTypes.string,
};

BlockListItemValueTextContent.defaultProps = {
  className: undefined,
  url: undefined,
  value: undefined,
  valueIcon: undefined,
};

export default BlockListItemValueTextContent;

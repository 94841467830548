import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { getItemMembersEndpoint, getSingleItemMemberEndpoint } from 'services/api/itemMemberEndpoints';
import FetchService from 'services/fetch';

/**
 * TODO: FRON-1819 — Support adding contacts and read-only contacts to an item. This whole file is a guess; it's not
 * in Postman yet.
 */

/**
 * POSTs an ItemMember to attach it to an Item
 * @param {string} itemId
 * @param {ItemMember} data - payload
 * @param {ObjectMaybe} [options={}]
 * @returns {Promise}
 */
export const addItemMemberToItem = (itemId, data, options = {}) => {
  const payload = payloadToJSONAPI(data, 'ItemMember', 'partnershipMember');
  const config = {
    ...options,
    endpoint: getItemMembersEndpoint(itemId),
    method: 'POST',
    payload,
    requireAuth: true,
    setRequester: true,
  };
  return FetchService.request(config);
};

/**
 * PATCHes an update of an ItemMember already attached to an Item.
 * @param {string} itemId
 * @param {string} itemMemberId
 * @param {Object} data - payload
 * @param {ObjectMaybe} [options={}]
 * @returns {Promise}
 */
export const updateItemMemberOnItem = (itemId, itemMemberId, data, options = {}) => {
  const payload = payloadToJSONAPI(data, 'ItemMember', 'partnershipMember');
  const config = {
    ...options,
    endpoint: getSingleItemMemberEndpoint(itemId, itemMemberId),
    method: 'PATCH',
    payload,
    requireAuth: true,
    setRequester: true,
  };
  return FetchService.request(config);
};

import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { text } from 'components/hintTypes/constants';
import { WhiteSpace } from 'components/text';

import { RESET_PASSWORD } from 'constants/routes';
import { Intent } from 'constants/ui';

import BaseHint from '../BaseHint';

/**
 * Hint to notify the user about invalid login credentials.
 * @param {ComponentProps} props
 * @param {ObjectMaybe} props.errors
 * @return {StatelessComponent}
 * @constructor
 */
const InvalidLoginCredentialsHint = ({ errors }) => {
  if (!errors) {
    return null;
  }

  return (
    <BaseHint
      intent={Intent.DANGER}
      multiline
      text={
        <>
          {text.invalidLoginCredentialsHint.text}
          <WhiteSpace />
          <Link className="font-color--primary semibold" to={RESET_PASSWORD}>
            Forgot your password?
          </Link>
        </>
      }
      title={text.invalidLoginCredentialsHint.title}
    />
  );
};

InvalidLoginCredentialsHint.propTypes = {
  errors: PropTypes.shape(),
};

InvalidLoginCredentialsHint.defaultProps = {
  errors: undefined,
};

export default InvalidLoginCredentialsHint;

import styled from 'styled-components';
import { containerWidthMap } from 'modules/signup-v3/constants/container';
export const GuideSectionListWrapper = styled.div `
  display: flex;
  flex-direction: column;
  max-width: ${containerWidthMap.large};
`;
export const Hints = styled.div `
  width: 730px;
`;
export const Bold = styled.b `
  font-weight: 800;
`;

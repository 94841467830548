import { SpecialCaseTaxCodesQBO } from 'constants/ledger';

import { isItemKindPayable } from 'helpers/items';
import { isLedgerApplicationTypeQBO, isLedgerApplicationTypeXero } from 'helpers/ledger';
import { isValueEmpty } from 'helpers/utility';

// *************************************
// Ledger meta fields
// *************************************
export const getQBOMetaFields = (item) => {
  if (isItemKindPayable(item)) {
    return ['memo'];
  }

  return ['discount', 'discountCalculation', 'discountStyle', 'memo', 'taxAuto', 'taxCalculated', 'taxCode'];
};

export const getXeroMetaFields = () => ['memo', 'taxStyle'];

export const getLedgerMetaFields = (item, ledger = {}) => {
  if (isLedgerApplicationTypeQBO(ledger)) {
    return getQBOMetaFields(item);
  }

  if (isLedgerApplicationTypeXero(ledger)) {
    return getXeroMetaFields();
  }

  return [];
};

// *************************************
// Invoices meta fields
// *************************************
export const getProcessInvoiceMetaFields = (isMultiple) => [
  'addLedgerAttachments',
  'dontImportLedgerInvoice',
  'ignoreUnsupportedLedgerAttachments',
  'message',
  'modifiedBefore',
  'usePrimaryPartnerReceivableAccount',
  isMultiple ? 'ledgerRefs' : 'ledgerRef',
];

export const parseProcessInvoiceMetaFields = (rawMeta, isMultiple) => {
  const allowedMetaKeys = getProcessInvoiceMetaFields(isMultiple);

  // Initialize meta fields
  const meta = {};

  allowedMetaKeys.forEach((key) => {
    // Skip unused values
    if (isValueEmpty(rawMeta[key])) {
      return;
    }

    // Add meta key
    meta[key] = rawMeta[key];
  });

  return meta;
};

// *************************************
// Item meta fields
// *************************************
export const getCreateItemMetaFields = (item, ledger) => {
  const baseFields = ['addAttachmentsToLedger', 'approvers', 'dontImportLedgerInvoice', 'message'];

  const ledgerFields = getLedgerMetaFields(item, ledger);

  return baseFields.concat(ledgerFields);
};

export const parseCreateItemMetaFields = (rawMeta, item, ledger) => {
  const allowedMetaKeys = getCreateItemMetaFields(item, ledger);
  const QBOSpecialCaseTaxCodes = Object.keys(SpecialCaseTaxCodesQBO);

  // Initialize meta fields
  const meta = {};

  allowedMetaKeys.forEach((key) => {
    // Skip unused values
    if (isValueEmpty(rawMeta[key])) {
      return;
    }

    // Remove dummy taxCode values
    if (key === 'taxCode' && QBOSpecialCaseTaxCodes.includes(rawMeta[key])) {
      return;
    }

    // Add meta key
    meta[key] = rawMeta[key];
  });

  return meta;
};

import { CREATE_RECEIVABLE_ROUTE } from 'constants/routes';
import { checkPartnershipEligibilityForCrossborderActions, isPartnershipCustomer, isPartnershipTypeCustomer, } from 'helpers/partnerships';
export const checkPartnershipEligibilityForMatchCompanies = ({ companyIntegrationSettings, companySettings, ledger, partnership, platformPartnership, supportedCurrencies, }) => {
    const { isBaseCurrencyModeEnabledAndSupported, isBaseCurrencyNotSupportedByPartnership, isCrossBorderDisabledAndPartnershipIsInternational, isCrossBorderEnabledAndCurrencyNotSupportedByPlatform, isPartnershipInternational, } = checkPartnershipEligibilityForCrossborderActions({
        companyIntegrationSettings,
        companySettings,
        ledger,
        partnership,
        supportedCurrencies,
    });
    const hasCurrencyMismatch = !partnership.ledgerCurrencies?.includes(platformPartnership.currencyCodePartner);
    const isCustomer = isPartnershipCustomer(partnership);
    const isPlatformPartnershipCustomer = isPartnershipCustomer(platformPartnership);
    const isLimitedMatchingTooltipShown = Boolean(isBaseCurrencyNotSupportedByPartnership && !isCustomer);
    const isCrossBorderDisabledTooltipIsShown = Boolean(!isBaseCurrencyModeEnabledAndSupported &&
        !isLimitedMatchingTooltipShown &&
        isCrossBorderDisabledAndPartnershipIsInternational &&
        !isCustomer);
    const isInternationalCustomerTooltipShown = Boolean(isPartnershipInternational && isCustomer);
    const isMatchDomesticPartnershipsTooltipShown = Boolean(!isBaseCurrencyModeEnabledAndSupported &&
        !isLimitedMatchingTooltipShown &&
        !isCrossBorderDisabledTooltipIsShown &&
        isPlatformPartnershipCustomer &&
        isPartnershipInternational &&
        !isCustomer);
    const isCurrencyNotSupportedByPlatformTooltipShown = Boolean(!isBaseCurrencyModeEnabledAndSupported &&
        !isLimitedMatchingTooltipShown &&
        isCrossBorderEnabledAndCurrencyNotSupportedByPlatform &&
        !isCustomer);
    const isCurrencyMismatchTooltipShown = Boolean(!isBaseCurrencyModeEnabledAndSupported &&
        !isLimitedMatchingTooltipShown &&
        !isCrossBorderEnabledAndCurrencyNotSupportedByPlatform &&
        !isCrossBorderDisabledTooltipIsShown &&
        !isMatchDomesticPartnershipsTooltipShown &&
        hasCurrencyMismatch &&
        !isCustomer);
    const isDisabled = Boolean(isLimitedMatchingTooltipShown ||
        isCrossBorderDisabledTooltipIsShown ||
        isInternationalCustomerTooltipShown ||
        isMatchDomesticPartnershipsTooltipShown ||
        isCurrencyNotSupportedByPlatformTooltipShown ||
        isCurrencyMismatchTooltipShown);
    return {
        isCrossBorderDisabledTooltipIsShown,
        isCurrencyMismatchTooltipShown,
        isCurrencyNotSupportedByPlatformTooltipShown,
        isDisabled,
        isInternationalCustomerTooltipShown,
        isLimitedMatchingTooltipShown,
        isMatchDomesticPartnershipsTooltipShown,
    };
};
export const checkPartnershipEligibilityForMergeCompanies = ({ companyIntegrationSettings, companySettings, ledger, partnership, supportedCurrencies, }) => {
    const { isBaseCurrencyModeEnabledAndSupported, isBaseCurrencyNotSupportedByPartnership, isCrossBorderDisabledAndPartnershipIsInternational, isCrossBorderEnabledAndPartnershipIsInternational, } = checkPartnershipEligibilityForCrossborderActions({
        companyIntegrationSettings,
        companySettings,
        ledger,
        partnership,
        supportedCurrencies,
    });
    const isLimitedMergingTooltipShown = isBaseCurrencyNotSupportedByPartnership;
    const isBcsDisabled = !isLimitedMergingTooltipShown && !isBaseCurrencyModeEnabledAndSupported;
    const isMergeDisabledWithXBFlagOffTooltipShown = isBcsDisabled && isCrossBorderDisabledAndPartnershipIsInternational;
    const isMergeDisabledWithXBFlagOnTooltipShown = isBcsDisabled && isCrossBorderEnabledAndPartnershipIsInternational;
    const isDisabled = Boolean(isLimitedMergingTooltipShown || isMergeDisabledWithXBFlagOffTooltipShown || isMergeDisabledWithXBFlagOnTooltipShown);
    return {
        isLimitedMergingTooltipShown,
        isMergeDisabledWithXBFlagOffTooltipShown,
        isMergeDisabledWithXBFlagOnTooltipShown,
        isDisabled,
    };
};
export const checkPartnershipEligibilityForSearchCompanies = ({ companyIntegrationSettings, companySettings, formPartnershipType, ledger, ledgerCurrencies, location, partnership, supportedCurrencies, }) => {
    const { isBaseCurrencyModeEnabledAndSupported, isBaseCurrencyNotSupportedByPartnership, isCrossBorderDisabledAndPartnershipIsInternational, isCrossBorderEnabledAndCurrencyNotSupportedByLedger, isCrossBorderEnabledAndCurrencyNotSupportedByPlatform, } = checkPartnershipEligibilityForCrossborderActions({
        companyIntegrationSettings,
        companySettings,
        ledger,
        ledgerCurrencies,
        partnership,
        supportedCurrencies,
    });
    const isOnCreateReceivableRoute = location.pathname.includes(CREATE_RECEIVABLE_ROUTE);
    const isOnCreateCustomerForm = isPartnershipTypeCustomer(formPartnershipType);
    const isCreateReceivableOrCreateCustomer = isOnCreateReceivableRoute || isOnCreateCustomerForm;
    const isCreateNewOption = Boolean(partnership.__isNew__);
    const isLimitSendingPaymentsTooltipShown = Boolean(!isCreateNewOption && isBaseCurrencyNotSupportedByPartnership && !isCreateReceivableOrCreateCustomer);
    const isCrossBorderPaymentsNotEnabledTooltipShown = Boolean(!isCreateNewOption &&
        !isBaseCurrencyModeEnabledAndSupported &&
        !isLimitSendingPaymentsTooltipShown &&
        isCrossBorderDisabledAndPartnershipIsInternational &&
        !isCreateReceivableOrCreateCustomer);
    const isCurrencyNotSupportedByPlatformTooltipShown = Boolean(!isCreateNewOption &&
        !isBaseCurrencyModeEnabledAndSupported &&
        !isLimitSendingPaymentsTooltipShown &&
        !isCrossBorderPaymentsNotEnabledTooltipShown &&
        isCrossBorderEnabledAndCurrencyNotSupportedByPlatform &&
        !isCreateReceivableOrCreateCustomer);
    const isCurrencyNotSupportedByLedgerTooltipShown = Boolean(!isCreateNewOption &&
        !isBaseCurrencyModeEnabledAndSupported &&
        !isLimitSendingPaymentsTooltipShown &&
        !isCurrencyNotSupportedByPlatformTooltipShown &&
        isCrossBorderEnabledAndCurrencyNotSupportedByLedger &&
        !isCreateReceivableOrCreateCustomer);
    const isPreventInternationalCustomerMatchTooltipShown = Boolean(!isCreateNewOption && !partnership.supportsDomestic && isCreateReceivableOrCreateCustomer);
    const isDisabled = Boolean(!isCreateNewOption &&
        (isLimitSendingPaymentsTooltipShown ||
            isCrossBorderPaymentsNotEnabledTooltipShown ||
            isCurrencyNotSupportedByLedgerTooltipShown ||
            isCurrencyNotSupportedByPlatformTooltipShown ||
            isPreventInternationalCustomerMatchTooltipShown));
    return {
        isCrossBorderPaymentsNotEnabledTooltipShown,
        isCurrencyNotSupportedByLedgerTooltipShown,
        isCurrencyNotSupportedByPlatformTooltipShown,
        isLimitSendingPaymentsTooltipShown,
        isDisabled,
        isPreventInternationalCustomerMatchTooltipShown,
    };
};

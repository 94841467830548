import React from 'react';
import {} from 'interfaces/forms';
import { EnterExistingPasswordError } from './components';
import { EXISTING_ROUTABLE_ACCOUNT_ERROR } from './constants';
export const replaceExistingAccountError = (error, index, errors) => error === EXISTING_ROUTABLE_ACCOUNT_ERROR ? React.createElement(EnterExistingPasswordError, { errors }) : error;
export const replaceExistingAccountErrorFromProps = (props) => {
    if (!Array.isArray(props?.meta?.error)) {
        return props;
    }
    const error = props.meta.error.map(replaceExistingAccountError);
    return {
        ...props,
        meta: {
            ...props.meta,
            error,
        },
    };
};

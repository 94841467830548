import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';
import { updateMembershipRoutine } from 'actions/routines/membership';
import { updateCurrentUserRoutine } from 'actions/routines/user';

import { deepMergeWithArrayReplacement } from 'helpers/transform';

import getRelationships from 'store/redux';

import { FETCH_MEMBERSHIP_SUCCESS, FETCH_MEMBERSHIPS_SUCCESS } from 'types/memberships';

const getCurrentCompanyUsers = (users) => {
  const userList = {};

  if (!users) {
    return userList;
  }

  Object.keys(users).forEach((userId) => {
    const relationships = getRelationships(users, userId, 'documents');
    userList[userId] = {
      id: users[userId].id,
      ...users[userId].attributes,
      ...relationships,
    };
  });

  return userList;
};

const companyUsersReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MEMBERSHIPS_SUCCESS:
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
      return getCurrentCompanyUsers(action.payload.user);

    case FETCH_MEMBERSHIP_SUCCESS:
    case updateCurrentUserRoutine.SUCCESS:
    case updateMembershipRoutine.SUCCESS:
      return deepMergeWithArrayReplacement(state, getCurrentCompanyUsers(action.payload.user));

    default:
      return state;
  }
};

export default companyUsersReducer;

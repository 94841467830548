import { allKeys } from 'helpers/utility';

import { FETCH_AFFILIATE_CONNECTIONS_SUCCESS } from 'types/affiliates';

const allReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_AFFILIATE_CONNECTIONS_SUCCESS:
      return Array.from(new Set([...state, ...allKeys(action.payload.affiliate)]));

    default:
      return state;
  }
};

export default allReducer;

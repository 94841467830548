import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
export const useIsTitleOverflowing = (ref) => {
    const [isOverflowing, setIsOverflowing] = useState(false);
    useEffect(() => {
        const observerFn = () => {
            if (!ref.current?.hasChildNodes()) {
                return;
            }
            const { width: parentWidth } = ref.current.getBoundingClientRect();
            const { firstChild } = ref.current;
            if (typeof firstChild?.getBoundingClientRect === 'function') {
                const { width: childWidth } = firstChild.getBoundingClientRect();
                setIsOverflowing(childWidth !== 0 && childWidth >= parentWidth);
            }
        };
        const debounceObserverFn = debounce(observerFn, 100);
        const observer = new ResizeObserver(debounceObserverFn);
        observer.observe(ref.current, { box: 'border-box' });
        observerFn();
        return () => {
            debounceObserverFn.cancel();
            observer.disconnect();
        };
    }, [ref]);
    return isOverflowing;
};

import { DayjsPrecisionValues } from '@routable/shared';
import { webhookSettingsSubmitRoutine, webhookSettingsTestRoutine } from 'actions/routines/webhooks';
import { isDateBefore } from 'helpers/date';
import { deepMergeWithArrayReplacement } from 'helpers/transform';
import {} from 'interfaces/global';
import {} from 'interfaces/reducers';
import {} from 'interfaces/webhooks';
import * as types from 'types/webhooks';
import {} from './byIdReducer.types';
export const getWebhookTestById = (state, webhookTestData) => {
    if (!webhookTestData.webhookSettingsId) {
        return {};
    }
    const testDataFromState = state[webhookTestData.webhookSettingsId];
    if (testDataFromState &&
        isDateBefore(webhookTestData.executedOn, testDataFromState.executedOn, DayjsPrecisionValues.SECOND)) {
        return {};
    }
    return {
        [webhookTestData.webhookSettingsId]: {
            executedOn: webhookTestData.executedOn,
            id: webhookTestData.webhookSettingsId,
            results: webhookTestData.results,
            status: webhookTestData.status,
        },
    };
};
const byIdReducer = (state = {}, action) => {
    switch (action.type) {
        case types.UPDATE_WEBHOOKS_TEST:
            return deepMergeWithArrayReplacement(state, getWebhookTestById(state, action.payload));
        case types.REMOVE_WEBHOOKS_TEST:
        case webhookSettingsSubmitRoutine.SUCCESS:
        case webhookSettingsTestRoutine.REQUEST:
            return {};
        default:
            return state;
    }
};
export default byIdReducer;

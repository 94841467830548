import React from 'react';
import { useSubmitTransactionPaymentConfirmationMutation } from 'ducks/routableApi/transactionEndpoints';
import { ButtonV2 } from 'components';
import { ButtonSize } from 'constants/button';
import { Intent } from 'constants/ui';
import {} from 'interfaces/transactions';
const GeneratePaymentConfirmation = ({ transaction }) => {
    const [onSubmitTransactionPaymentConfirmation, apiCall] = useSubmitTransactionPaymentConfirmationMutation();
    return (React.createElement(ButtonV2, { intent: Intent.NEUTRAL, isLoading: apiCall.isLoading, onClick: () => onSubmitTransactionPaymentConfirmation(transaction), size: ButtonSize.SMALL, type: "button" }, "Generate PDF"));
};
export default GeneratePaymentConfirmation;

import React from 'react';
import { useSelector } from 'react-redux';
import DashboardToDashboardRestrictionTooltipContent from 'components/dashboardToDashboardRestrictionTooltipContent';
import { isPartnershipMemberStatusArchived } from 'helpers/contacts';
import { getPartnershipPermissionResourceForArchiveAction, getPartnershipPermissionResourceForEditAction, } from 'helpers/partnerships';
import { useGrossDsPermissions } from 'hooks';
import { partnershipFromPropsSelector } from 'selectors/partnershipsSelectors';
import {} from '../ContactCellsTypes.types';
import { ActiveContactActionsMenu, ArchivedContactActionsMenu, } from './components/ContactActionsCellMenuItems/components';
const ContactActionsCell = ({ rowData }) => {
    const { managed, status, ...rest } = rowData;
    const partnership = useSelector(partnershipFromPropsSelector);
    const archivePermissions = [getPartnershipPermissionResourceForArchiveAction(partnership)];
    const canArchive = useGrossDsPermissions(archivePermissions);
    const editPermissions = [getPartnershipPermissionResourceForEditAction(partnership)];
    const canEdit = useGrossDsPermissions(editPermissions);
    if (managed) {
        const tooltipProps = {
            hasPermission: false,
            tooltipProps: {
                tooltip: React.createElement(DashboardToDashboardRestrictionTooltipContent, null),
                'data-testid': 'tooltip--dashboard-to-dashboard-restriction',
            },
        };
        return React.createElement(ActiveContactActionsMenu, { ...rowData, canArchive: tooltipProps, canEdit: tooltipProps });
    }
    if (isPartnershipMemberStatusArchived(status)) {
        return React.createElement(ArchivedContactActionsMenu, { ...rest, canArchive: canArchive });
    }
    return React.createElement(ActiveContactActionsMenu, { ...rowData, canArchive: canArchive, canEdit: canEdit });
};
export default ContactActionsCell;

import PropTypes from 'prop-types';
import React from 'react';

import { isItemKindPayable } from '../../../../helpers/items';
import { parseCurrency } from '../../../../helpers/numbers';

const ExternalReceiptHeader = ({ item }) => (
  <div className="panel-heading external-receipt">
    <div className="panel-heading--row">
      <div className="panel-heading--title external-receipt--amount">
        <h3>
          Amount
          {isItemKindPayable(item) ? ' due' : ' sent'}
        </h3>
      </div>

      <div className="panel-heading--subtext external-receipt--amount">
        <h3>{parseCurrency(item.amount)}</h3>
      </div>
    </div>
  </div>
);

ExternalReceiptHeader.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default ExternalReceiptHeader;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import TotalsPanel from '../TotalsPanel';

/**
 * Renders the invoice generator totals panel fields; in the case of the standalone total (used when no ledger
 * is connected and we therefore have no subtotal, discount, or tax lines), a variant of the styles is used
 * that clips extra padding/margin.
 * @param {ComponentProps} props
 * @param {StringMaybe} props.className
 * @returns {StatelessComponent}
 */
const TotalsPanelStandaloneTotal = ({ className, ...rest }) => (
  <TotalsPanel {...rest} className={classNames(className, 'standalone-total')} />
);

TotalsPanelStandaloneTotal.propTypes = {
  className: PropTypes.string,
};

TotalsPanelStandaloneTotal.defaultProps = {
  className: undefined,
};

export default TotalsPanelStandaloneTotal;

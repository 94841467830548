import { createSelector } from 'reselect';

import { BulkImportItemsStep } from 'constants/bulkActions';

import { isStepGreaterThan, isStepLessThan } from 'helpers/bulkActions';
import { initialValuesBulkActions } from 'helpers/initialValues';
import { allValues } from 'helpers/utility';

const getBulkActionsState = (state) => state.bulkActions;

/**
 * Returns the bulkActions.bulkActionsModal state object.
 * @type {StandardSelector}
 * @returns {Object}
 */
export const bulkActionsModalSelector = createSelector([getBulkActionsState], (state) => state.bulkActionsModal);

/**
 * Returns the bulkActions.bulkActionsModal.bulkAction value.
 * @type {StandardSelector}
 * @returns {StringMaybe}
 */
export const bulkActionsModalBulkActionSelector = createSelector(
  [bulkActionsModalSelector],
  (state) => state.bulkAction,
);

/**
 * Returns the bulkActions.bulkActionsModal.isOpen value.
 * @type {StandardSelector}
 * @returns {Boolean}
 */
export const bulkActionsModalIsOpenSelector = createSelector([bulkActionsModalSelector], (state) => state.isOpen);

/**
 * Selector that returns the initialValues object for the bulk actions form.
 * @type {StandardSelector}
 * @returns {Object}
 */
export const bulkActionsFormInitialValuesSelector = createSelector([], () =>
  initialValuesBulkActions.getBulkActionsSummaryFormInitialValues(),
);

/**
 * Selector that returns the initialValues object for the bulk import form.
 * @type {StandardSelector}
 * @returns {Object}
 */
export const bulkImportItemsFormInitialValuesSelector = createSelector([], () =>
  initialValuesBulkActions.getBulkImportItemsFormInitialValues(),
);

/**
 * Returns the bulkActions.import state object.
 * @type {StandardSelector}
 * @returns {Object}
 */
export const bulkActionsImportSelector = createSelector([getBulkActionsState], (state) => state.import);

/**
 * Returns the bulkActions.import.allIds array.
 * @type {StandardSelector}
 * @returns {Id[]}
 */
export const bulkActionsImportAllIdsSelector = createSelector([bulkActionsImportSelector], (state) => state.allIds);

/**
 * Returns the bulkActions.import.byId object.
 * @type {StandardSelector}
 * @returns {Object}
 */
export const bulkActionsImportByIdSelector = createSelector([bulkActionsImportSelector], (state) => state.byId);

/**
 * Returns all of the bulk imports
 * @type {StandardSelector}
 * @returns {Object}
 */
export const allBulkActionsImportsSelector = createSelector([bulkActionsImportByIdSelector], (byId) => allValues(byId));

/**
 * Returns all of the bulk imports, with the user property set to the bulk import's creator property.
 * This lets us easily use e.g. the TeamMemberTableCell, without having too many variants of prop
 * configurations.
 * @type {StandardSelector}
 * @returns {Object}
 */
export const allBulkActionsImportsWithUserMemberIdSelector = createSelector(
  [allBulkActionsImportsSelector],
  (bulkImports) =>
    bulkImports.map((bulkImport) => ({
      ...bulkImport,
      user: bulkImport.creator,
    })),
);

/**
 * Returns all of the *complete* bulk imports, with the user property set to the bulk import's creator property.
 * This lets us easily use e.g. the TeamMemberTableCell, without having too many variants of prop
 * configurations.
 * @type {StandardSelector}
 * @returns {Object[]}
 */
export const allFinalizedBulkActionsImportsWithUserMemberIdSelector = createSelector(
  [allBulkActionsImportsWithUserMemberIdSelector],
  (bulkImports) => bulkImports.filter((bulkImport) => isStepGreaterThan(bulkImport.step, BulkImportItemsStep.summary)),
);

/**
 * Returns all of the *in progress* bulk imports.
 * @type {StandardSelector}
 * @returns {Object[]}
 */
export const allInProgressBulkActionsImports = createSelector([allBulkActionsImportsSelector], (bulkImports) =>
  bulkImports.filter((bulkImport) => isStepLessThan(bulkImport.step, BulkImportItemsStep.finalize)),
);

/**
 * Returns the bulkActions.import.isUploading boolean.
 * @type {StandardSelector}
 * @returns {Boolean}
 */
export const bulkActionsImportIsUploadingSelector = createSelector(
  [bulkActionsImportSelector],
  (state) => state.isUploading,
);

/**
 * Returns the bulkActions.import.isFetching boolean.
 * @type {StandardSelector}
 * @returns {Boolean}
 */
export const bulkActionsImportIsFetchingSelector = createSelector(
  [bulkActionsImportSelector],
  (state) => state.isFetching,
);

/**
 * Returns the bulkActions.import.links object.
 * @type {StandardSelector}
 * @returns {Object}
 */
export const bulkActionsImportLinksSelector = createSelector([bulkActionsImportSelector], (state) => state.links);

/**
 * Returns the bulkActions.import.pagination object.
 * @type {StandardSelector}
 * @returns {Object}
 */
export const bulkActionsImportPaginationSelector = createSelector(
  [bulkActionsImportSelector],
  (state) => state.pagination,
);

/**
 * Returns the bulkActions.import.errors object.
 * @type {StandardSelector}
 * @returns {Object}
 */
export const bulkActionsImportErrorsSelector = createSelector([bulkActionsImportSelector], (state) => state.errors);

/**
 * Returns the bulkActions.currentStep state object.
 * @type {StandardSelector}
 * @returns {string}
 */
export const bulkActionsModalCurrentStepSelector = createSelector(
  [bulkActionsModalSelector],
  (state) => state.currentStep,
);

import PropTypes from 'prop-types';
import React from 'react';

import { TooltipIcon } from 'components/tooltip/TooltipIcon';

import { DateFormats } from 'constants/date';

import { formatDateString } from 'helpers/date';

import { IconNames } from '../IconMapping';

const TimeTooltipIcon = ({ content, time, ...rest }) => {
  const contents = content || `Added on ${formatDateString(time, DateFormats.SHORT_ALPHA_MONTH_FULL_YEAR)}`;

  return <TooltipIcon content={contents} icon={IconNames.TIME} {...rest} />;
};

TimeTooltipIcon.propTypes = {
  content: PropTypes.string,
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

TimeTooltipIcon.defaultProps = {
  content: undefined,
  time: undefined,
};

export default TimeTooltipIcon;

import { ADD_NOT_FOUND_ERROR, REMOVE_NOT_FOUND_ERROR } from 'types/errors';

export const notFoundErrorReducer = (state = false, action) => {
  switch (action.type) {
    case ADD_NOT_FOUND_ERROR:
      return true;

    case REMOVE_NOT_FOUND_ERROR:
      return false;

    default:
      return state;
  }
};

export default notFoundErrorReducer;

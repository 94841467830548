import { EventKeyValues } from 'constants/events';

import { isEqual, isFn, or } from 'helpers/utility';

/**
 * Stops propagation of an event, passes it into the given callback function, and then returns
 * the result of the callback, or the event
 * @param {EventListener} callback
 * @return {function(Event|SyntheticUIEvent, ...*): *} An event callback that persists the event, and calls the original.
 */
export const createPersistedEventCallback =
  (callback) =>
  (event, ...rest) => {
    if (event?.persist) {
      event.persist();
    }

    if (isFn(callback)) {
      return callback(event, ...rest);
    }

    return event;
  };

/**
 * Returns a canceled event
 * @param {Object} [event]
 * @param {Function} [event.preventDefault]
 * @return {Object} Canceled event
 */
export const noDefaultEvent = (event) => {
  if (event && event.preventDefault) {
    event.preventDefault();
  }

  return event;
};

/**
 * Cancels an event, passes it into the given callback function, and then returns
 * the result of the callback, or the canceled event
 * @param {Event} event
 * @param {Function} callback
 * @return {Event|*} Canceled event or callback result
 */
export const noDefaultEventWithCallback = (event, callback) => {
  const evt = noDefaultEvent(event);

  if (isFn(callback)) {
    return callback(evt);
  }

  return evt;
};

/**
 * Returns a non-capturing/non-bubbling event
 * @param {Event} event
 * @return {Event} Non-propagating event
 */
export const noPropagationEvent = (event) => {
  if (event && event.stopPropagation) {
    event.stopPropagation();
  }

  return event;
};

/**
 * Stops propagation of an event, passes it into the given callback function, and then returns
 * the result of the callback, or the event
 * @param {Event} event
 * @param {EventListener} callback
 * @return {Event|*} Non-propagating event or callback result
 */
export const noPropagationEventWithCallback = (event, callback) => {
  const evt = noPropagationEvent(event);

  if (isFn(callback)) {
    return callback(evt);
  }

  return evt;
};

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Checks if event was triggered by pressing "Enter"
 * @param {Event} evt
 * @return {boolean}
 */
export const isEventKeyEnter = (evt) => isEqual(evt.key, EventKeyValues.ENTER);

/**
 * Checks if event was triggered by pressing "Space"
 * @param {Event} evt
 * @return {boolean}
 */
export const isEventKeySpace = (evt) => isEqual(evt.key, EventKeyValues.SPACE);

/**
 * Checks if event was triggered by pressing "Enter" or "Space"
 * @param {Event} evt
 * @return {boolean}
 */
export const isEventKeySpaceOrEnter = (evt) => or(isEventKeyEnter(evt), isEventKeySpace(evt));

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Returns a function that accepts an event as its only argument, and if the event is an "enter" keyboard
 * event, returns the result of callback(evt); Otherwise returns evt.
 * @param {Function} callback
 * @return {function(EventArgument): (*)}
 */
export const callOnEnterKeyEvent = (callback) => (evt) => {
  if (isEventKeyEnter(evt)) {
    return callback(evt);
  }

  return evt;
};

import _debounce from 'lodash/debounce';
import React from 'react';
import { useDispatch } from 'react-redux';
import { usePrevious } from 'react-use';
import { change } from 'redux-form';

import { FieldDebounceDelay } from 'constants/ui';

/**
 * A hook used when a field's value is connected to the redux store (primarily via redux-form's Field
 * component), and we want to debounce updates to the store (e.g. set the redux value every N milliseconds,
 * instead of every single time the onChange callback is fired). Returns a value and onChange handler,
 * which can be used whether the field should be debounced or not.
 * @param {Object} options
 * @param {number} options.debounceDelay
 * @param {boolean} options.isDebounced
 * @param {ReduxFormInput} options.input
 * @return {Pick<React.ComponentPropsWithoutRef<'input'>, 'value' | 'onChange'>}
 */
const useDebouncedField = (options) => {
  const { debounceDelay = FieldDebounceDelay.DEFAULT, formName, isDebounced, input = {} } = options;

  const dispatch = useDispatch();
  const [value, setValue] = React.useState(input.value);
  const previousValue = usePrevious(input.value);

  React.useEffect(() => {
    if (input.value !== previousValue) {
      setValue(input.value);
    }
  }, [input.value, previousValue]);

  const debouncedOnChange = React.useMemo(
    () => _debounce(input.onChange, debounceDelay),
    [debounceDelay, input.onChange],
  );

  const handleOnChange = React.useCallback(
    (evtOrValue, ...rest) => {
      if (evtOrValue?.persist) {
        evtOrValue.persist();
      }

      setValue(evtOrValue?.target?.value ?? evtOrValue);
      debouncedOnChange(evtOrValue, ...rest);
      // When evtOrValue is nullish, the onChange handler from reduxForm does not send a payload in the action
      // which in turn does not update the value in state
      // That's why we force dispatch a reduxForm change action, with the nullish value as payload
      if (!evtOrValue && formName) {
        dispatch(change(formName, input.name, evtOrValue));
      }
    },
    [debouncedOnChange, dispatch, formName, input.name],
  );

  return React.useMemo(
    () => ({
      onChange: isDebounced ? handleOnChange : input.onChange,
      value: isDebounced ? value : input.value,
    }),
    [handleOnChange, input.onChange, input.value, isDebounced, value],
  );
};

export default useDebouncedField;

import { fundingAccountRelationships } from 'constants/relationships';

import { isFundingAccountNumberMasked } from 'helpers/funding';
import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { becameTruthy } from 'helpers/utility';

/**
 * Returns the result of getObjectsByIdWithRelationships, passing in the relationships
 * specific to a funding account.
 * @param {Object} fundingAccounts
 * @return {ByIdCollection}
 */
export const getFundingAccountsById = (fundingAccounts) =>
  getObjectsByIdWithRelationships(fundingAccounts, fundingAccountRelationships);

/**
 * Custom function to get the merged attributes of a funding info bank account.
 * @param {ReduxState} currentState
 * @param {Object} bankAccounts
 * @param {string} bankAccountId
 * @return {Object}
 */
export const getFundingInfoBankAccountAttributes = (currentState, bankAccounts, bankAccountId) => {
  const { attributes } = bankAccounts[bankAccountId];

  // Making a new object to make sure we don't update the original data
  const newAttributes = { ...attributes };

  // Need to check whether current state has mask and new state has no mask
  if (bankAccountId in currentState) {
    const newAccountNumberIsMasked = isFundingAccountNumberMasked(newAttributes.accountNumber);
    const currentAttributes = currentState[bankAccountId];
    const currentAccountNumberIsMasked = isFundingAccountNumberMasked(currentAttributes.accountNumber);

    if (becameTruthy(currentAccountNumberIsMasked, newAccountNumberIsMasked)) {
      // This means we already had the full account number previously and are about to replace it with the mask.
      // We want to avoid this situation, so replacing the account number from the new payload with the current one.
      newAttributes.accountNumber = currentAttributes.accountNumber;
    }
  }

  return newAttributes;
};

/**
 * Returns the result of getObjectsByIdWithRelationships for bank accounts, with a
 * custom function to get the merged attributes of each bank account.
 * @param {Object} bankAccounts
 * @param {ReduxState} currentState
 * @return {ByIdCollection}
 */
export const getFundingInfoBankAccountsById = (bankAccounts, currentState) =>
  getObjectsByIdWithRelationships(bankAccounts, [], {
    currentState,
    getAttributes: getFundingInfoBankAccountAttributes,
  });

/**
 * Returns the result of getObjectsByIdWithRelationships, for funding info addresses.
 * @param {Object} addresses
 * @return {ByIdCollection}
 */
export const getFundingInfoAddressesById = (addresses) => getObjectsByIdWithRelationships(addresses);

import { combineReducers } from 'redux';

import allIdsReducer from './allIdsReducer';
import byIdReducer from './byIdReducer';
import currentFundingStateReducer from './currentFundingStateReducer';
import errorReducer from './errorReducer';

const stateFundingAccountsReducer = combineReducers({
  allIds: allIdsReducer,
  byId: byIdReducer,
  currentFundingState: currentFundingStateReducer,
  errors: errorReducer,
});

export default stateFundingAccountsReducer;

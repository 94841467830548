import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Radio } from 'components';
import { FieldRadioGroup } from './RenderRadioGroup.styles';
const RenderRadioGroup = ({ isHorizontal = true, name, options }) => {
    const { control } = useFormContext();
    return (React.createElement(Controller, { control: control, name: name, render: ({ field }) => (React.createElement(FieldRadioGroup, null, options.map((option) => (React.createElement(Radio, { id: `${field.name}_${option.value}`, input: {
                ...field,
                value: option.value,
                checked: field.value === option.value,
            }, isHorizontalLayout: isHorizontal, key: option.value, optionText: option.optionText, subText: option.subText, type: "radio" }))))) }));
};
export default RenderRadioGroup;

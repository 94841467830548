import { REST } from 'helpers/api';

import * as endpoints from 'services/api/countriesEndpoints';

/**
 * Fetch all the countries.
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchCountries = async (options = {}) =>
  REST.get({
    ...options,
    endpoint: endpoints.countriesEndpoint,
    requireAuth: true,
  });

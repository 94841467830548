import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { fetchSingleItemRequest } from 'actions/item';
import { fetchItemsRoutine } from 'actions/routines/item';
import { TABLE_DEFAULT_PAGE_SIZE } from 'constants/tables';
import { getApplyFiltersAction, isItemIdFilter, parseFiltersFromUrl } from 'helpers/itemFilters';
import { LogLevel, systemLogger } from 'helpers/systemLogger';
import {} from 'interfaces/itemFilters';
import { isFetchingItemSelector, itemsArraySelector, itemsErrorsSelector, itemsPaginationSelector, } from 'selectors/itemsSelectors';
import {} from 'types/UseApi';
import {} from './useBills.types';
const EMPTY_ARRAY = [];
export const generateUseBills = (getFilters) => ({ uniqueId, defaultPageSize = TABLE_DEFAULT_PAGE_SIZE, defaultFilters = EMPTY_ARRAY, skipInitialCall }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(isFetchingItemSelector);
    const error = useSelector(itemsErrorsSelector);
    const data = useSelector(itemsArraySelector);
    const { current, next, prev, count, offset, page, pages, pageSize, limit } = useSelector(itemsPaginationSelector);
    const [storedPageSize, setStoredPageSize] = useState(defaultPageSize);
    const loadPage = useCallback((number, size) => {
        systemLogger.log({
            level: LogLevel.DEBUG,
            message: 'useBill loadPage',
            pageNumber: number,
            pageSize: size,
        });
        dispatch(fetchItemsRoutine.trigger({
            page: {
                number,
                size,
            },
        }));
    }, [dispatch]);
    const gotoPage = (destinationPage) => {
        switch (destinationPage) {
            case 'PREV':
                dispatch(fetchItemsRoutine.trigger({ url: prev }));
                break;
            case 'NEXT':
                dispatch(fetchItemsRoutine.trigger({ url: next }));
                break;
            default:
                loadPage(destinationPage, storedPageSize);
        }
    };
    const setFilter = useCallback(({ filters, ...params }) => {
        const singleIdFilter = filters.find(isItemIdFilter);
        systemLogger.log({
            level: LogLevel.DEBUG,
            message: 'Bill Filter updated',
            filters,
            params,
        });
        if (singleIdFilter) {
            dispatch(fetchSingleItemRequest(singleIdFilter.value));
        }
        else {
            dispatch(getApplyFiltersAction(uniqueId)(filters, {
                page: { size: storedPageSize },
                ...params,
            }));
        }
    }, [storedPageSize, dispatch, uniqueId]);
    const refresh = () => {
        dispatch(fetchItemsRoutine.trigger({ url: current }));
    };
    const setPageSize = (size) => {
        setStoredPageSize(size);
        loadPage(1, size);
    };
    const activeFilters = Array.from(new Set([...defaultFilters, ...getFilters()]));
    useEffectOnce(() => {
        if (!skipInitialCall) {
            setFilter({ filters: activeFilters });
        }
    });
    return {
        isLoading,
        isError: !!error,
        error,
        data,
        pagination: {
            count,
            offset,
            page,
            pages,
            pageSize,
            limit,
        },
        gotoPage,
        setPageSize,
        refresh,
        activeFilters,
        setFilter,
    };
};
export const useBills = generateUseBills(parseFiltersFromUrl);

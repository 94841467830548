import { createSelector } from 'reselect';

/**
 * Top level countries selector
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
const getState = (state) => state.countries;

/**
 * Selects all IDs of countries from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {string[]}
 */
export const allCountriesSelector = createSelector([getState], (countries) => countries.allCountries);

/**
 * Selects countries by id from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
export const byCountrySelector = createSelector([getState], (countries) => countries.byCountry);

/**
 * Selects the countries errors from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
export const countriesErrorsSelector = createSelector([getState], (countries) => countries.errors);

/**
 * Selects the isFetching boolean for countries from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {boolean}
 */
export const isFetchingCountriesSelector = createSelector([getState], (countries) => countries.isFetching);

/**
 * Selects the lastFetched data for countries from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {DateMaybe}
 */
export const countriesLastFetchedSelector = createSelector([getState], (countries) => countries.lastFetched);

import { change } from 'redux-form';
import { showSuccessUi } from 'actions/ui';
import { createItemFormFields } from 'constants/formFields';
import { formNamesItem } from 'constants/forms';
import {} from 'interfaces/actions';
import * as types from '../types/fundingProviderMemo';
export const fundingProviderMemoModalOpen = () => ({
    type: types.FUNDING_PROVIDER_MEMO_OPEN,
});
export const fundingProviderMemoModalClose = () => ({
    type: types.FUNDING_PROVIDER_MEMO_CLOSE,
});
export const updateFundingProviderMemoOnCreateItem = (value, successMessage) => [
    change(formNamesItem.CREATE_ITEM, createItemFormFields.FUNDING_PROVIDER_MEMO, value),
    fundingProviderMemoModalClose(),
    showSuccessUi(successMessage),
];

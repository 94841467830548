import { PaymentDeliveryOptionType } from '@routable/shared';
import { createSelector } from 'reselect';

import { BalanceTransferBillingCode, BillingCodeBase, BillingCodeFundingMemo } from 'constants/billing';

import { getDeliveryOptionBillingCodesForAP } from 'helpers/billing';
import { every, isEqual, reduceKeys } from 'helpers/utility';

/**
 * Generated billing codes for accounts payable.
 * @enum {string}
 */
const APBillingCodes = getDeliveryOptionBillingCodesForAP();

const getState = (state) => state.billing;

/**
 * Returns state.billing object
 * @function
 * @param {ReduxState} state
 * @returns {Object}
 */
export const billingSelector = createSelector([getState], (state) => state);

/**
 * Returns all billing codes
 * @function
 * @param {ReduxState} state
 * @returns {string[]}
 */
export const billingAllCodesSelector = createSelector([billingSelector], (billing) => billing.allCodes);

/**
 * Returns billing data by code
 * @function
 * @param {ReduxState} state
 * @returns {Object}
 */
export const billingDataByCodeSelector = createSelector([billingSelector], (billing) => billing.byCode);

/**
 * Get billing data by ID.
 *
 * @function
 * @type {StandardSelector}
 * @param {ReduxState}
 * @returns {Object<BillingCodeData.id, BillingCodeData>}
 */
export const billingDataByIdSelector = createSelector([billingDataByCodeSelector], (byCode) =>
  reduceKeys(
    byCode,
    (byId, code) => {
      const billingCode = byCode[code];

      return {
        ...byId,
        [billingCode.id]: billingCode,
      };
    },
    {},
  ),
);

/**
 * Returns a selector creator, to get billing data for a given billing code
 * @param {string} billingCode
 * @returns {function(ReduxState): ObjectMaybe}
 */
export const createBillingDataForCodeSelector = (billingCode) =>
  createSelector([billingDataByCodeSelector], (billingDataByCode) => {
    if (!billingDataByCode) {
      return undefined;
    }

    return billingDataByCode[billingCode];
  });

/**
 * Selects billing data for AP addenda record
 * @function
 * @param {ReduxState} state
 * @returns {ObjectMaybe}
 */
export const addendaRecordBillingDataSelector = createBillingDataForCodeSelector(BillingCodeFundingMemo.ACH_ADDENDA);

/**
 * Selects billing data for AP next day ach.
 * @function
 * @param {ReduxState} state
 * @returns {ObjectMaybe}
 */
export const apNextDayBillingDataSelector = createBillingDataForCodeSelector(
  APBillingCodes[PaymentDeliveryOptionType.ACH_NEXT_DAY],
);

/**
 * Selects billing data for AP RTP.
 * @function
 * @param {ReduxState} state
 * @returns {ObjectMaybe}
 */
export const apRtpBillingDataSelector = createBillingDataForCodeSelector(APBillingCodes[PaymentDeliveryOptionType.RTP]);

/**
 * Selects billing data for AP same day ach.
 * @function
 * @param {ReduxState} state
 * @returns {ObjectMaybe}
 */
export const apSameDayBillingDataSelector = createBillingDataForCodeSelector(
  APBillingCodes[PaymentDeliveryOptionType.ACH_SAME_DAY],
);

/**
 * Selects billing data for AP balance.
 * @function
 * @param {ReduxState} state
 * @returns {ObjectMaybe}
 */
export const apBalanceBillingDataSelector = createBillingDataForCodeSelector(
  BalanceTransferBillingCode.AP_BALANCE_TRANSFER,
);

/**
 * Selects billing data for AR balance.
 * @function
 * @param {ReduxState} state
 * @returns {ObjectMaybe}
 */
export const arBalanceBillingDataSelector = createBillingDataForCodeSelector(
  BalanceTransferBillingCode.AR_BALANCE_TRANSFER,
);

/**
 * Returns a selector that gets billing data by code, where the base type is the given base type.
 * @param {string} base
 * @returns {function(ReduxState): ObjectMaybe}
 */
export const createBillingDataByCodeForBaseSelector = (base) =>
  createSelector([billingDataByCodeSelector], (billingDataByCode) =>
    reduceKeys(
      billingDataByCode,
      (obj, code) => {
        const billingData = billingDataByCode[code];

        if (every([isEqual(billingData.base, base), !isEqual(billingData.paymentDeliveryOptionMax, 0)])) {
          return {
            ...obj,
            [code]: billingData,
          };
        }

        return obj;
      },
      {},
    ),
  );

/**
 * Returns billing data by code, where the base type is transaction.
 * @function
 * @param {ReduxState} state
 * @returns {Object}
 */
export const billingDataByCodeWithTransactionBaseSelector = createBillingDataByCodeForBaseSelector(
  BillingCodeBase.TRANSACTION,
);

/**
 * Selects billing data for AP RTP remittance record
 * @function
 * @param {ReduxState} state
 * @returns {ObjectMaybe}
 */
export const remittanceDataBillingDataSelector = createBillingDataForCodeSelector(
  BillingCodeFundingMemo.RTP_REMITTANCE,
);

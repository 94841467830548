import PropTypes from 'prop-types';

import { fieldPropTypes, makeFieldDefaultProps } from 'components/commonProps';

export const propTypes = {
  ...fieldPropTypes,
  children: PropTypes.node,
  dataFullStory: PropTypes.bool,
  dataId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataTestId: PropTypes.string,
  errName: PropTypes.string,
  inputId: PropTypes.string,
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maximumCharacterCount: PropTypes.number,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  pattern: PropTypes.string,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  validator: PropTypes.string,
};

export const defaultProps = makeFieldDefaultProps({
  children: null,
  dataFullStory: undefined,
  dataId: null,
  dataTestId: undefined,
  defaultValue: '',
  errName: null,
  inputId: null,
  max: null,
  maximumCharacterCount: null,
  meta: {},
  min: null,
  onFocus: () => undefined,
  onChange: null,
  pattern: null,
  placeholder: '',
  step: null,
  type: 'text',
  validator: null,
});

import merge from 'deepmerge';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import {
  FETCH_INTEGRATIONS_SUCCESS,
  CONNECT_INTEGRATION_REQUEST,
  CONNECT_INTEGRATION_FAILURE,
} from 'types/integrations';

const options = {
  getAttributes: () => ({
    isConnecting: false,
  }),
};

const integrationsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INTEGRATIONS_SUCCESS:
      return deepMergeWithArrayReplacement(
        state,
        getObjectsByIdWithRelationships(action.payload.integration, [], options),
      );

    case CONNECT_INTEGRATION_REQUEST:
      return merge(state, {
        [action.payload.integration.id]: { isConnecting: true },
      });

    case CONNECT_INTEGRATION_FAILURE:
      return merge(state, {
        [action.payload.integration.id]: { isConnecting: false },
      });

    default:
      return state;
  }
};

export default integrationsByIdReducer;

import { call } from 'redux-saga/effects';

import { fetchCurrentMemberRequest } from 'actions/memberships';
import { deleteRepresentativeDocumentRoutine, postRepresentativeDocumentRoutine } from 'actions/routines/documents';
import { changeSaveStatus } from 'actions/signUpFlow';

import { createSaga } from 'helpers/saga';

import { handleDocumentDeleteError, handleDocumentUploadError } from 'sagas/shared/documentError';

import * as api from './api';

export function* postMembershipDocument({ payload }) {
  const { addFileToList, currentMemberId, file, documentType, dispatch } = payload;

  const options = {
    apiParams: [currentMemberId, { ...file, documentType }],
    onSuccess: [fetchCurrentMemberRequest, addFileToList, () => dispatch(changeSaveStatus.saved())],
    onError: handleDocumentUploadError,
  };

  yield call(createSaga, api.postMembershipDocument, postRepresentativeDocumentRoutine, options);
}

export function* deleteMembershipDocument({ payload }) {
  const { currentMemberId, documentId, removeFileFromList } = payload;
  const options = {
    apiParams: [currentMemberId, documentId],
    onSuccess: [fetchCurrentMemberRequest, removeFileFromList],
    onError: handleDocumentDeleteError,
  };

  yield call(createSaga, api.deleteMembershipDocument, deleteRepresentativeDocumentRoutine, options);
}

import PropTypes from 'prop-types';
import React from 'react';

import { AddressFundingAccount } from 'modules/address';

const CheckAddressList = ({ fundingAccountsWithAddressFundingSources, onFundingAccountSelected, selectedAccount }) => {
  if (fundingAccountsWithAddressFundingSources.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <p className="form-field--label">Mail to:</p>

      {fundingAccountsWithAddressFundingSources.map((fundingAccount) => (
        <div className="panel" key={fundingAccount.id}>
          <AddressFundingAccount
            fundingAccount={fundingAccount}
            onChange={onFundingAccountSelected}
            selectedAccount={selectedAccount}
          />
        </div>
      ))}
    </React.Fragment>
  );
};

CheckAddressList.propTypes = {
  selectedAccount: PropTypes.string,
  fundingAccountsWithAddressFundingSources: PropTypes.arrayOf(PropTypes.shape()),
  onFundingAccountSelected: PropTypes.func.isRequired,
};

CheckAddressList.defaultProps = {
  selectedAccount: undefined,
  fundingAccountsWithAddressFundingSources: [],
};

export default CheckAddressList;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { BalanceAccountFormats } from 'constants/currency';

import { parseCurrency } from 'helpers/numbers';

/**
 * Component to render an amount of currency.
 * Uses parseCurrency, which uses a default precision
 * of 2.
 * @param children
 * @param className
 * @param formatOptions
 * @return {StatelessComponent}
 * @constructor
 */
const CurrencyAmount = ({ children, className, formatOptions }) => (
  <span
    className={classNames({
      'currency-amount': true,
      [className]: !!className,
    })}
    data-fullstory
  >
    {parseCurrency(children, {
      ...formatOptions,
      format: BalanceAccountFormats,
    })}
  </span>
);

CurrencyAmount.propTypes = {
  children: PropTypes.number,
  className: PropTypes.string,
  formatOptions: PropTypes.shape({
    precision: PropTypes.number,
    separator: PropTypes.string,
    symbol: PropTypes.string,
  }),
};

CurrencyAmount.defaultProps = {
  children: 0,
  className: undefined,
  formatOptions: {},
};

export default CurrencyAmount;

import { createIsFetchingReducer } from 'store/redux';

import { FETCH_BILLING_CODES_FAILURE, FETCH_BILLING_CODES_REQUEST, FETCH_BILLING_CODES_SUCCESS } from 'types/billing';

export const finishCases = [FETCH_BILLING_CODES_FAILURE, FETCH_BILLING_CODES_SUCCESS];
export const requestCases = [FETCH_BILLING_CODES_REQUEST];

const isFetchingReducer = createIsFetchingReducer(finishCases, requestCases);

export default isFetchingReducer;

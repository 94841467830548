import { combineReducers } from 'redux';

import allAttachmentsReducer from './allAttachmentsReducer';
import attachmentsByIdReducer from './attachmentsByIdReducer';
import isDeletingReducer from './isDeletingReducer';

const attachmentsReducer = combineReducers({
  allIds: allAttachmentsReducer,
  byId: attachmentsByIdReducer,
  isDeleting: isDeletingReducer,
});

export default attachmentsReducer;

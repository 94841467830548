import React from 'react';
import { createContext, useState, useContext } from 'react';
const voidMethod = () => { };
export const TableContext = createContext({
    tableName: '',
    headers: [],
    display: 'default',
    tableConfig: undefined,
    updateConfig: voidMethod,
    updateHeaders: voidMethod,
});
export const TableProvider = ({ children, tableName, defaultTableConfig, display = 'default', }) => {
    const [headers, setHeaders] = useState([]);
    const [tableConfiguration, setTableConfiguration] = useState(defaultTableConfig || undefined);
    const updateHeaders = (newHeaders) => {
        setHeaders(newHeaders);
    };
    const updateConfig = (newConfig) => {
        setTableConfiguration(newConfig);
    };
    return (React.createElement(TableContext.Provider, { value: { tableName, display, headers, tableConfig: tableConfiguration, updateConfig, updateHeaders } }, children));
};
export const useTableContext = () => {
    return useContext(TableContext);
};

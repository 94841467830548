import { createAddressRoutine, updateAddressRoutine } from 'actions/routines/addresses';

import { createIsSubmittingReducer } from 'store/redux';

/**
 * List of reducer's finish cases
 * @type {ReduxType[]}
 */
export const finishCases = [
  createAddressRoutine.SUCCESS,
  createAddressRoutine.FAILURE,
  updateAddressRoutine.SUCCESS,
  updateAddressRoutine.FAILURE,
];

/**
 * List of reducer's request cases
 */
export const requestCases = [createAddressRoutine.REQUEST, updateAddressRoutine.REQUEST];

/**
 * Addresses is submitting reducer
 * @param {ReduxState} state
 * @param {ReduxAction} action
 * @returns {Boolean}
 */
const isSubmittingReducer = createIsSubmittingReducer(finishCases, requestCases);

export default isSubmittingReducer;

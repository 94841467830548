import React from 'react';

import { InfoCardHeader } from 'components/infoCard';

import { TEN_NINETY_NINE_ILLUSTRATION } from 'constants/images';

import './TenNinetyNineFaqIllustration.scss';

const TenNinetyNineFaqIllustration = () => (
  <React.Fragment>
    <img alt="Illustrated IRS 1099 form with red heart in bottom right corner" src={TEN_NINETY_NINE_ILLUSTRATION} />
    <InfoCardHeader className="ten-ninety-nine-faq-illustration--header">Frequently Asked Questions</InfoCardHeader>
  </React.Fragment>
);

export default TenNinetyNineFaqIllustration;

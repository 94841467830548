import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { formValueSelector } from 'redux-form';

import {
  currentCompanySettingsSelector,
  currentCompanySettingsIntegrationSelector,
} from 'selectors/currentCompanySelectors';
import { fundingSupportedCountriesPaymentOptionsCurrencyList } from 'selectors/fundingSelectors';
import { ledgerIntegrationSelector } from 'selectors/integrationsSelectors';
import { ledgerOptionsLedgerCurrenciesArraySelector } from 'selectors/ledgerInfoSelectors';

import SearchCompanies from './SearchCompanies';

export const mapStateToProps = (state, ownProps) => {
  const formSelector = formValueSelector(ownProps.formName);

  return {
    companyIntegrationSettings: currentCompanySettingsIntegrationSelector(state),
    companySettings: currentCompanySettingsSelector(state),
    formUI: formSelector(state, 'ui'),
    item: formSelector(state, 'item'),
    ledger: ledgerIntegrationSelector(state),
    ledgerCurrencies: ledgerOptionsLedgerCurrenciesArraySelector(state),
    partner: formSelector(state, 'partner'),
    partnershipType: formSelector(state, 'partnershipType'),
    supportedCurrencies: fundingSupportedCountriesPaymentOptionsCurrencyList(state),
  };
};

const enhance = compose(withRouter, connect(mapStateToProps));

export default enhance(SearchCompanies);

import { RoutableEventsSync, useEventSync } from '@routable/framework';
import { useContext, useEffect, useState } from 'react';
import { TableContext } from '../table-name.context';
import {} from '../table.extended.types';
import { useTableStore } from '../table.store';
export const useTableOptions = ({ name, headers }) => {
    const tableName = useContext(TableContext).tableName;
    const usedName = name || tableName;
    const [data, setData] = useState(headers || []);
    const { setTableStateItem, getTableStateItem } = useTableStore(tableName);
    useEventSync(`table:${usedName}:update:config`, (ev) => {
        setData(ev.data || []);
    });
    useEffect(() => {
        if (headers && headers.length > 0) {
            setTableStateItem('headers', headers);
        }
        const tableStoreHeaders = getTableStateItem('headers');
        setData(tableStoreHeaders);
        RoutableEventsSync.Publish(`table:${usedName}:update:config`, tableStoreHeaders);
    }, [headers]);
    return data;
};

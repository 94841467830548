import { combineReducers } from 'redux';

import externalOnboardingIsSubmittingReducer from './externalOnboardingIsSubmittingReducer';
import externalOnboardingStepReducer from './externalOnboardingStepReducer';

const externalOnboardingReducer = combineReducers({
  step: externalOnboardingStepReducer,
  isSubmitting: externalOnboardingIsSubmittingReducer,
});

export default externalOnboardingReducer;

import _omit from 'lodash/omit';
import _pick from 'lodash/pick';

import { getPartnershipCurrencyCodeCompany, getPartnershipCurrencyCodePartner } from 'helpers/partnerships';
import { lastElementIn } from 'helpers/utility';

/**
 * Transforms data for submitting selected funding account to partnership request api
 * @param {Object} action
 * @param {Object} action.defaultReceivable
 * @param {Object} action.meta
 * @param {PartnershipRequest} action.partnershipRequest
 * @returns {Object}
 */
export const getPartnershipRequestPayload = ({ defaultReceivable, meta, partnershipRequest }) => {
  const { acceptFuturePendingPayments, fundingAccount, paymentDeliveryMethod } = defaultReceivable;

  return {
    id: lastElementIn(partnershipRequest.fundingAccountStates),
    paymentDeliveryMethod,
    meta: {
      ...meta,
      acceptFuturePendingPayments,
    },
    fundingAccount: {
      id: fundingAccount,
    },
  };
};

/**
 * Convert form state and component props from ManuallyAddBankAccountSideSheet into pre-JSON:API payload.
 *
 * @param {OptionsArg} options
 * @param {Object} options.fields
 * @param {Partnership} options.partnership
 * @returns {Object}
 */
export const getCreatePartnershipInternationalManualBankAccountPayload = ({ fields, partnership }) => {
  const { countryCodeCompany, id } = partnership;
  const currencyCode = getPartnershipCurrencyCodeCompany(partnership);
  const shouldAddFundingInfoAddress = fields?.form?.applyRegisteredAddress ?? false;

  // Clean up the data not needed for the payload
  const accountFields = _omit(fields, 'partner');
  delete accountFields.form;

  const { governmentId, ...partner } = fields.partner || {};

  // If we are adding a new funding info address, create a relationship
  // to be included with the payload.
  const relationships =
    shouldAddFundingInfoAddress === false
      ? {
          fundingInfoAddress: {
            data: {
              type: 'FundingInfoAddress',
              attributes: partner,
            },
          },
        }
      : undefined;

  return {
    relationships,
    meta: {
      applyRegisteredAddress: shouldAddFundingInfoAddress,
      country: countryCodeCompany,
      currencyCode,
      partnershipId: id,
      governmentId,
      ...accountFields,
    },
  };
};

/**
 * Convert form state and component props from AddInternationalFundingAccountForm into pre-JSON:API payload.
 *
 * @param {OptionsArg} options
 * @param {Partnership} options.partnership
 * @param {Array} options.requiredFields
 * @param {Object} options.values
 * @returns {Object}
 */
export const getCreatePartnershipInternationalBankAccountPayload = ({ partnership, requiredFields, values }) => {
  const { form } = values;
  const { countryCodePartner, id } = partnership;
  const currencyCode = getPartnershipCurrencyCodePartner(partnership);

  // Only include required fields on the payload
  const accountFields = _pick(values, 'currencyCode', ...requiredFields.map(({ field }) => field));
  const formMeta = _omit(form, 'replaceFundingAccounts');

  const payload = {
    meta: {
      country: countryCodePartner,
      currencyCode,
      partnershipId: id,
      ...accountFields,
      ...formMeta,
    },
  };

  // If applyRegisteredAddress is not set to true, we want to manually
  // add fundingInfoAddresses by providing the form values
  if (!form.applyRegisteredAddress) {
    delete payload.meta.applyRegisteredAddress;
    payload.fundingInfoAddress = values.partner;
  }

  return payload;
};

import React from 'react';
import { useSelector } from 'react-redux';

import { BulletList } from 'components';

import { DOCUMENT_APPROVED_IMAGE, DOCUMENT_APPROVED_IMAGE_CHAIN } from 'constants/images';

import { containerWidthMap } from 'modules/signup-v3/constants/container';
import { openDocumentModal } from 'modules/signup-v3/helpers/helpDocs';

import { isUpdatingCurrentCompanySelector } from 'selectors/currentCompanySelectors';

import InvitedTeamMembers from '../components/InvitedTeamMembers';
import FlowStepperMain from '../FlowStepperMain';
import { viewSteps } from '../helpers/viewSteps';

import {
  DescriptionContainer,
  DescriptionTitle,
  DescriptionImage,
  BulletListContainer,
  BulletItemEmph,
  BulletItemLink,
} from './BusinessVerification.styles';

const businessVerificationItems = [
  {
    title: 'Details about your business',
    image: DOCUMENT_APPROVED_IMAGE_CHAIN,
    bulletpoints: [
      'Legal name',
      'Type and incorporation',
      'Employer Identification Number (EIN)',
      'Category and classification',
      'Address',
      'Phone number',
    ],
  },
  {
    image: DOCUMENT_APPROVED_IMAGE,
    title: 'Government-issued document',
    bulletpoints: [
      <>
        <BulletItemEmph>Preferred: </BulletItemEmph>
        IRS-issued EIN letter
      </>,
      <>
        <BulletItemEmph>Alternative: </BulletItemEmph>
        {`A document from `}
        <BulletItemLink onClick={openDocumentModal}>this list</BulletItemLink>
      </>,
    ],
  },
];

const DescriptionItem = ({ title, bulletpoints, image }) => (
  <div className="description-cell">
    <DescriptionImage alt="Document with a blue star" src={image} />
    <DescriptionTitle>{title}</DescriptionTitle>
    <BulletListContainer>
      <BulletList bullets={bulletpoints} />
    </BulletListContainer>
  </div>
);

export const BusinessVerificationRender = () => (
  <div>
    <DescriptionContainer>
      {businessVerificationItems.map(({ title, bulletpoints, image }) => (
        <DescriptionItem bulletpoints={bulletpoints} image={image} key={title} title={title} />
      ))}
    </DescriptionContainer>
    <InvitedTeamMembers />
  </div>
);

const BusinessVerification = ({ activeView }) => {
  const isUpdating = useSelector(isUpdatingCurrentCompanySelector);
  return (
    <FlowStepperMain
      containerWidth={containerWidthMap.large}
      fields={<BusinessVerificationRender />}
      onNext={{
        ...viewSteps[activeView].next,
        isLoading: isUpdating,
      }}
      onSkip={viewSteps[activeView].skip}
      submit={false}
      subTitle={{
        content:
          'Providing this information helps the government address financial crime, and keeps your business compliant.',
      }}
      title={{ content: 'Make sure you have a few items handy' }}
    />
  );
};

export default BusinessVerification;

import { PartnershipMemberStatus } from 'constants/contacts';
import {
  PartnershipMemberEmailStatus,
  PartnershipMemberAccess,
  PartnershipMemberAccessScope,
  PartnershipMemberAccessSubtitleText,
} from 'constants/partnershipMember';
import { colors } from 'constants/styles';
import { PartnershipMemberAccessIcon } from 'constants/ui';

import { MemberAccessProps } from 'data/resources/member';

import { isPartnershipMemberStatusArchived } from 'helpers/contacts';
import { isCompanyNotCurrentCompany } from 'helpers/currentCompany';
import { isEqual, lengthOf } from 'helpers/utility';

/**
 * Method to indicate if the partnership member has complete info (hasEmail, firstName, lastName)
 * @param {PartnershipMember} partnershipMember
 * @return {Boolean}
 */
export const isPartnershipMemberContactInfoComplete = (partnershipMember) =>
  Boolean(partnershipMember) &&
  partnershipMember.hasEmail &&
  Boolean(partnershipMember.firstName) &&
  Boolean(partnershipMember.lastName);

/**
 * Method to indicate if the partnership member email status is bounced
 * @param {PartnershipMember} partnershipMember
 * @return {Boolean}
 */
export const isPartnershipMemberEmailStatusBounced = (partnershipMember) =>
  Boolean(partnershipMember) && isEqual(partnershipMember.emailStatus, PartnershipMemberEmailStatus.BOUNCED);

/**
 * Method to indicate if the partnership member is from the partnership partner
 * @param {PartnershipMember} partnershipMember
 * @param {Partnership|Item} partnershipOrItem
 * @returns {boolean}
 */
export const isPartnershipMemberFromPartnershipPartner = (partnershipMember, partnershipOrItem) =>
  isEqual(partnershipMember.company, partnershipOrItem.partner);

/**
 * Method to indicate if the partnership member is not archived
 * @param {PartnershipMember} partnershipMember
 * @returns {boolean}
 */
export const isPartnershipMemberNotArchived = (partnershipMember) =>
  !isPartnershipMemberStatusArchived(partnershipMember.status);

/**
 * Returns boolean if access is ACTIONABLE
 * @param {string} access
 * @return {boolean}
 */
export const isPartnershipMemberAccessActionable = (access) => isEqual(access, PartnershipMemberAccess.ACTIONABLE);

/**
 * Returns boolean if access is NONE
 * @param {string} access
 * @return {boolean}
 */
export const isPartnershipMemberAccessNone = (access) => isEqual(access, PartnershipMemberAccess.NONE);

/**
 * Returns boolean if access is SELF_MANAGED
 * @param {string} access
 * @return {boolean}
 */
export const isPartnershipMemberAccessSelfManaged = (access) => isEqual(access, PartnershipMemberAccess.SELF_MANAGED);

/**
 * Returns boolean if access is READ_ONLY
 * @param {string} access
 * @return {boolean}
 */
export const isPartnershipMemberAccessReadOnly = (access) => isEqual(access, PartnershipMemberAccess.READ_ONLY);

/**
 * Returns boolean if access is HAS_DEFAULTS
 * @param {string} access
 * @return {boolean}
 */
export const isPartnershipMemberAccessHasDefaults = (access) => isEqual(access, PartnershipMemberAccess.HAS_DEFAULTS);

/**
 * Returns boolean if access is NO_DEFAULTS
 * @param {string} access
 * @return {boolean}
 */
export const isPartnershipMemberAccessNoDefaults = (access) => isEqual(access, PartnershipMemberAccess.NO_DEFAULTS);

/**
 * Returns boolean if access is set
 * Note: need to check `access` arg exists here because R2R partners can have team members
 * with empty default permission values (invisible, because self-managed)
 * @param {string} access
 * @return {boolean}
 */
export const isPartnershipMemberAccessSet = (access) =>
  Boolean(access && !isEqual(access, PartnershipMemberAccess.NONE));

/**
 * Given a partnershipMember object, returns whether their defaultGeneral access property is not 'none'.
 * @param {PartnershipMember} partnershipMember
 * @returns {boolean}
 */
export const isPartnershipMemberDefaultGeneralAccessSet = (partnershipMember) =>
  isPartnershipMemberAccessSet(partnershipMember[MemberAccessProps.defaultGeneral]);

/**
 * Returns boolean if scope is is ITEMS
 * @param {string} scope
 * @return {boolean}
 */
export const isPartnershipMemberAccessScopeItems = (scope) => isEqual(scope, PartnershipMemberAccessScope.ITEMS);

/**
 * Returns boolean if scope is is COMPANY_MANAGEMENT
 * @param {string} scope
 * @return {boolean}
 */
export const isPartnershipMemberAccessScopeCompanyManagement = (scope) =>
  isEqual(scope, PartnershipMemberAccessScope.COMPANY_MANAGEMENT);

/**
 * Given a partnershipMember object, returns whether the company prop points to the current company.
 * @param {PartnershipMember} partnershipMember
 * @returns {boolean}
 */
export const isPartnershipMemberCompanyPropNotCurrentCompany = (partnershipMember) =>
  isCompanyNotCurrentCompany(partnershipMember.company);

/**
 * Determines if only one remaining un-archived contact is in array.
 * @param {Array<PartnershipMember>} partnershipMembers
 * @returns {boolean}
 */
export const hasSingleNotArchivedPartnershipMember = (partnershipMembers) => {
  const notArchivedPartnershipMembers = partnershipMembers.filter(isPartnershipMemberNotArchived);

  return isEqual(lengthOf(notArchivedPartnershipMembers), 1);
};

/**
 * Returns whether all possible default access levels are set to "none" for a partnership member.
 * @param {PartnershipMember} partnershipMember
 * @return {Boolean}
 */
export const doesPartnershipMemberHaveNoDefaultAccessLevel = (partnershipMember) =>
  Boolean(
    isPartnershipMemberAccessNone(partnershipMember?.defaultGeneral) &&
      isPartnershipMemberAccessNone(partnershipMember?.defaultItem),
  );

/**
 * Returns whether any default access is set to something other than "none" for a partnership member.
 * @param {PartnershipMember} partnershipMember
 * @param {boolean} [returnConstant] - True if you want PartnershipMemberAccess returned instead of boolean
 * @return {Boolean|PartnershipMemberAccess}
 */
export const doesPartnershipMemberHaveDefaultAccessLevel = (partnershipMember, returnConstant) => {
  const hasDefaults = !doesPartnershipMemberHaveNoDefaultAccessLevel(partnershipMember);

  if (returnConstant) {
    return hasDefaults ? PartnershipMemberAccess.HAS_DEFAULTS : PartnershipMemberAccess.NO_DEFAULTS;
  }

  return hasDefaults;
};

/**
 * Contacts can "read and act on" notifications, while a read-only contact can "only read" notifications.
 * @param {string} access
 * @returns {string}
 */
export const getPartnershipMemberAccessActionText = (access) => {
  if (isPartnershipMemberAccessActionable(access)) {
    return `able to read and act on emails`;
  }
  if (isPartnershipMemberAccessReadOnly(access)) {
    return `only able to read emails`;
  }
  if (isPartnershipMemberAccessNone(access)) {
    return `won't be autofilled in any fields`;
  }

  return '';
};

/**
 * Contacts can "read and act on" notifications, while a read-only contact can "only read" notifications.
 * @param {string} access
 * @returns {string}
 */
export const getPartnershipMemberAccessText = (access) => {
  switch (access) {
    case PartnershipMemberAccess.ACTIONABLE:
      return 'Full permissions: ';
    case PartnershipMemberAccess.READ_ONLY:
      return 'Read-only permissions: ';
    case PartnershipMemberAccess.NONE:
      return 'No autofill: ';
    default:
      return '';
  }
};

/**
 * Gets the icon props depending on the access level of the PartnershipMember.
 * @param {PartnershipMember} partnershipMember
 * @returns {Object} props for icon
 */
export const getPartnershipMemberAccessIconProps = (partnershipMember) => {
  /** @type {PartnershipMemberAccess} */
  const hasDefaults = doesPartnershipMemberHaveDefaultAccessLevel(partnershipMember, true);

  return {
    color: isPartnershipMemberAccessHasDefaults(hasDefaults) ? colors.colorAquaBoldHex : colors.colorGreyXDarkHex,
    name: PartnershipMemberAccessIcon[hasDefaults],
  };
};

/**
 * Gets subtitle text depending on the access level of the PartnershipMember.
 * @param {PartnershipMember} partnershipMember
 * @returns {string}
 */
export const getPartnershipMemberAccessSubtitleText = (partnershipMember) => {
  /** @type {PartnershipMemberAccess} */
  const hasDefaults = doesPartnershipMemberHaveDefaultAccessLevel(partnershipMember, true);

  return PartnershipMemberAccessSubtitleText[hasDefaults];
};

export const flipPartnershipMemberAccess = (partnershipMember, targetAccess) => {
  const access = partnershipMember[targetAccess];
  const { ACTIONABLE, READ_ONLY } = PartnershipMemberAccess;

  return isPartnershipMemberAccessActionable(access) ? READ_ONLY : ACTIONABLE;
};

/**
 * Contacts can have different access levels for different scopes. For example, they can be read-only on company
 * management notifications, but take action on payments and invoices.
 * @param scope
 * @returns {string}
 */
export const getPartnershipMemberAccessScopeText = (scope) =>
  isPartnershipMemberAccessScopeItems(scope) ? 'Payments and invoices' : 'Company management';

/**
 * The backend does not have a single status proprerty on the partnershipMember, it is a value derived from a couple
 * of different boolean fields. A partnershipMember's status informs UI throughout the application. For convenience,
 * we derive the status from attributes as the partnershipMember is being added to state so that each component
 * doesn't have to derive the status itself.
 * @param {PartnershipMember} partnershipMember - This is a semi-raw partnershipMember, camelCased, but directly from
 * the backend
 * @returns {PartnershipMemberStatus}
 */
export const derivePartnershipMemberStatusFromAttributes = (partnershipMember) => {
  const {
    attributes: { isArchived },
    relationships: { notificationBounce },
  } = partnershipMember;
  let status = PartnershipMemberStatus.ACTIVE;

  if (notificationBounce?.data?.id) {
    status = PartnershipMemberStatus.ISSUE;
  }
  if (isArchived) {
    status = PartnershipMemberStatus.ARCHIVED;
  }

  return status;
};

/**
 * Returns the partnership member, after setting their accessItem property
 * to the current value of their defaultItem access property.
 * Primary use case: We just pulled in contacts after creating or selecting a
 * partnership, and want to populate form fields with the accessItem values
 * pre-filled from default settings.
 * For the purposes of the CreateItems UI, this also checks to see if the defaultItem
 * access is currently 'self_managed'. If so, for the time being, we will automatically
 * default it to actionable.
 * @param {PartnershipMember} partnershipMember
 */
export const getPartnershipMemberWithAccessItemFromDefaultItem = (partnershipMember) => ({
  ...partnershipMember,
  [MemberAccessProps.accessItem]: isPartnershipMemberAccessSelfManaged(partnershipMember[MemberAccessProps.defaultItem])
    ? PartnershipMemberAccess.ACTIONABLE
    : partnershipMember[MemberAccessProps.defaultItem],
});

/**
 * Returns the partnership member, after setting their notificationGeneral property
 * to the current value of their defaultGeneral access property.
 * Example use-case scenario: We're in the send-update-payment-link flow, and we create a new contact before submitting
 * the whole form. That contact will never have a notificationGeneral access set, but we want them to appear
 * in the correct select field after creation with the other selected contacts.
 * @param {PartnershipMember} partnershipMember
 * @return {PartnershipMember}
 */
export const getPartnershipMemberWithAccessNotificationGeneralFromDefaultGeneral = (partnershipMember) => ({
  ...partnershipMember,
  [MemberAccessProps.notificationGeneral]: partnershipMember[MemberAccessProps.defaultGeneral],
});

/**
 * Returns a list partnership members that have the passed access property set and have an email
 * @param {MemberAccessProps} access
 * @param {PartnershipMember[]} partnershipMembers
 * @return {PartnershipMember[]}
 */
export const getPartnershipMembersWithAccessAndEmail = (access, partnershipMembers) =>
  partnershipMembers.filter((member) => !isPartnershipMemberAccessNone(member[access]) && member?.hasEmail);

/**
 * removeMetaFromPartnershipMembers
 * Takes an array of partnershipMembers and returns the same array
 * with each partnershipMember cleaned up of its `meta` key/value
 * @param [partnershipMembers] partnershipMembers
 * @returns [PartnershipMember]
 */
export const removeMetaFromPartnershipMembers = (partnershipMembers) =>
  partnershipMembers.map((partnershipMember) => {
    const updatedMember = { ...partnershipMember };
    delete updatedMember.meta;
    return updatedMember;
  });

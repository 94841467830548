export const FETCH_CURRENT_COMPANY_SETTINGS_ITEM_APPROVAL =
  '@currentCompany/FETCH_CURRENT_COMPANY_SETTINGS_ITEM_APPROVAL';

export const UPDATE_CURRENT_COMPANY_SETTINGS_INTEGRATION =
  '@currentCompany/UPDATE_CURRENT_COMPANY_SETTINGS_INTEGRATION';

export const ENABLE_INTERNATIONAL_PAYMENTS = '@currentCompany/ENABLE_INTERNATIONAL_PAYMENTS';

export const EXTERNAL_PAYMENT_GET_CURRENT_COMPANY_REQUEST =
  '@currentCompany/EXTERNAL_PAYMENT_GET_CURRENT_COMPANY_REQUEST';

export const GET_CURRENT_COMPANY_FAILURE = '@currentCompany/GET_CURRENT_COMPANY_FAILURE';
export const GET_CURRENT_COMPANY_REQUEST = '@currentCompany/GET_CURRENT_COMPANY_REQUEST';
export const GET_CURRENT_COMPANY_SUCCESS = '@currentCompany/GET_CURRENT_COMPANY_SUCCESS';

export const UPDATE_CURRENT_COMPANY_FAILURE = '@currentCompany/UPDATE_CURRENT_COMPANY_FAILURE';
export const UPDATE_CURRENT_COMPANY_REQUEST = '@currentCompany/UPDATE_CURRENT_COMPANY_REQUEST';
export const UPDATE_CURRENT_COMPANY_RESET = '@currentCompany/UPDATE_CURRENT_COMPANY_RESET';

export const UPDATE_CURRENT_COMPANY_SETTINGS_FAILURE = '@currentCompany/UPDATE_CURRENT_COMPANY_SETTINGS_FAILURE';
export const UPDATE_CURRENT_COMPANY_SETTINGS_REQUEST = '@currentCompany/UPDATE_CURRENT_COMPANY_SETTINGS_REQUEST';
export const UPDATE_CURRENT_COMPANY_SETTINGS_SUCCESS = '@currentCompany/UPDATE_CURRENT_COMPANY_SETTINGS_SUCCESS';

export const UPDATE_CURRENT_COMPANY_SETTINGS_CURRENCY_SUCCESS =
  '@currentCompany/UPDATE_CURRENT_COMPANY_SETTINGS_CURRENCY_SUCCESS';

export const UPDATE_CURRENT_COMPANY_SUCCESS = '@currentCompany/UPDATE_CURRENT_COMPANY_SUCCESS';

export const UPDATE_ONBOARDING_COMPANY_FAILURE = '@currentCompany/UPDATE_ONBOARDING_COMPANY_FAILURE';
export const UPDATE_ONBOARDING_COMPANY_REQUEST = '@currentCompany/UPDATE_ONBOARDING_COMPANY_REQUEST';
export const UPDATE_ONBOARDING_COMPANY_SUCCESS = '@currentCompany/UPDATE_ONBOARDING_COMPANY_SUCCESS';

export const UPDATE_ONBOARDING_COMPANY_TYPE_FAILURE = '@currentCompany/UPDATE_ONBOARDING_COMPANY_TYPE_FAILURE';
export const UPDATE_ONBOARDING_COMPANY_TYPE_REQUEST = '@currentCompany/UPDATE_ONBOARDING_COMPANY_TYPE_REQUEST';
export const UPDATE_ONBOARDING_COMPANY_TYPE_SUCCESS = '@currentCompany/UPDATE_ONBOARDING_COMPANY_TYPE_SUCCESS';

export const UPDATE_CURRENT_COMPANY_SETTINGS_COLLECT_VENDOR_TAXES_SUCCESS =
  '@currentCompany/UPDATE_CURRENT_COMPANY_SETTINGS_COLLECT_VENDOR_TAXES_SUCCESS';
export const UPDATE_CURRENT_COMPANY_SETTINGS_REQUIRE_TAX_FORM_BEFORE_PAYMENT_SUCCESS =
  '@currentCompany/UPDATE_CURRENT_COMPANY_SETTINGS_REQUIRE_TAX_FORM_BEFORE_PAYMENT';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { v4 } from 'uuid';

import { colorDarkSilverHex, colorWhiteHex } from '../../constants/styles/colors';
import { IsLoadingInline } from '../../modules/isLoading/IsLoading';

/* eslint-disable react/button-has-type */
// react/button-has-type turned off because it does not support passing in a type dynamically
const Button = ({
  children,
  className,
  dataName,
  disabled,
  hasArrow,
  hasLoading,
  hasTag,
  htmlFor,
  id,
  isFetching,
  margin,
  onClick,
  padding,
  style,
  tagIcon,
  tagText,
  type,
  width,
  wrapperClassName,
}) => {
  const loadingColor = className && className.includes('ghost') ? colorDarkSilverHex : colorWhiteHex;

  return (
    <div
      className={classNames({
        [wrapperClassName]: !!wrapperClassName,
      })}
      style={{ width, margin, padding, ...style }}
    >
      <button
        className={className}
        data-name={dataName}
        disabled={disabled}
        form={htmlFor}
        id={id || v4()}
        onClick={onClick}
        type={type}
      >
        {children}
        {hasArrow && !isFetching && <span className="icon-ic-arrow-back icon-flip-180" />}
        {hasLoading && isFetching && <IsLoadingInline color={loadingColor} />}
        {hasTag && !isFetching && (
          <div className="tag inline-button has-primary-background float-right">
            <span className={tagIcon} />
            <span>{tagText}</span>
          </div>
        )}
      </button>
    </div>
  );
};
/* eslint-enable react/button-has-type */

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dataName: PropTypes.string,
  disabled: PropTypes.bool,
  hasArrow: PropTypes.bool,
  hasLoading: PropTypes.bool,
  hasTag: PropTypes.bool,
  htmlFor: PropTypes.string,
  id: PropTypes.string,
  isFetching: PropTypes.bool,
  margin: PropTypes.string,
  onClick: PropTypes.func,
  padding: PropTypes.string,
  style: PropTypes.shape(),
  tagIcon: PropTypes.string,
  tagText: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  width: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

Button.defaultProps = {
  className: '',
  dataName: '',
  disabled: false,
  hasArrow: false,
  hasLoading: false,
  hasTag: false,
  htmlFor: undefined,
  id: null,
  isFetching: false,
  margin: '0',
  onClick: null,
  padding: '0',
  style: {},
  tagIcon: '',
  tagText: '',
  type: undefined,
  width: '100%',
  wrapperClassName: undefined,
};

export default Button;

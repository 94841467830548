import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { externalAcceptPartnershipFormUISelector, externalPartnerCompanyTypeSelector } from 'selectors/forms';
import { partnershipRequestCollectVendorTaxInfoForCurrentUser } from 'selectors/partnershipRequestSelectors';

import PartnerCompanyEntityForm from './PartnerCompanyEntityForm';

export const mapStateToProps = createStructuredSelector({
  collectVendorTaxInfo: partnershipRequestCollectVendorTaxInfoForCurrentUser,
  companyType: externalPartnerCompanyTypeSelector,
  formUIState: externalAcceptPartnershipFormUISelector,
});

export default connect(mapStateToProps)(PartnerCompanyEntityForm);

import * as routines from 'actions/routines/countries';

import { createErrorReducer } from 'store/redux';

export const failureCases = [routines.fetchCountriesRoutine.FAILURE];

export const successCases = [routines.fetchCountriesRoutine.REQUEST, routines.fetchCountriesRoutine.SUCCESS];

const errorsReducer = createErrorReducer(successCases, failureCases);

export default errorsReducer;

import { Edit, SubtractAlt, UserAdmin, ViewFilled } from '@carbon/icons-react';
import { Menu } from '@routable/gross-ds';
import PropTypes from 'prop-types';
import React from 'react';

import PermissionResourceAction from 'constants/permissions';

import { useContactsInteractionListViewContext, useExistingItemContext, useGrossDsPermissions } from 'hooks';

import * as helpers from './helpers';

/**
 * Menu popover which allows the user to perform actions (e.g. edit, remove) on a PartnershipMember or ItemMember.
 * @param {ComponentProps} props
 * @param {Function} props.onRemove
 * @param {Function} props.onSetActionable
 * @param {Function} props.onSetReadOnly
 * @param {Function} props.onUpdate
 * @param {boolean} props.showRemove
 * @param {boolean} props.showSetAccess
 * @param {RestOfProps} props.contact - the contact details
 * @return {StatelessComponent}
 * @constructor
 */
const ContactListItemMenuPopover = (props) => {
  const { onRemove, onSetActionable, onSetReadOnly, onUpdate, showRemove, showSetAccess, ...contact } = props;

  const item = useExistingItemContext();
  const view = useContactsInteractionListViewContext();

  const { editText, removeText, makeReadOnlyText, makeActionableText } = helpers.getContactListMenuItemsText(
    view,
    contact,
    item,
  );

  const selfManagedProps = helpers.getCommonMenuItemProps(contact);

  const permissions = useGrossDsPermissions([
    PermissionResourceAction.PAYABLE_EDIT,
    PermissionResourceAction.RECEIVABLE_EDIT,
  ]);

  const commonProps = selfManagedProps || {
    disabled: !permissions.hasPermission,
    tooltip: permissions.tooltipProps,
  };
  const removeContactMenuItemProps = helpers.getRemoveContactMenuItemProps(item, selfManagedProps);

  return (
    <Menu buttonVariant="floating" label="Payment contacts" placement="top">
      {showSetAccess && (
        <Menu.Item icon={<UserAdmin />} onAction={onSetActionable} {...commonProps}>
          {makeActionableText}
        </Menu.Item>
      )}
      {showSetAccess && (
        <Menu.Item icon={<ViewFilled />} onAction={onSetReadOnly} {...commonProps}>
          {makeReadOnlyText}
        </Menu.Item>
      )}

      {!showSetAccess && (
        <Menu.Item icon={<Edit />} onAction={onUpdate} {...commonProps}>
          {editText}
        </Menu.Item>
      )}

      {showRemove && (
        <Menu.Item icon={<SubtractAlt />} onAction={onRemove} {...removeContactMenuItemProps}>
          {removeText}
        </Menu.Item>
      )}
    </Menu>
  );
};

ContactListItemMenuPopover.propTypes = {
  onRemove: PropTypes.func,
  onSetActionable: PropTypes.func,
  onSetReadOnly: PropTypes.func,
  onUpdate: PropTypes.func,
  showRemove: PropTypes.bool,
  showSetAccess: PropTypes.bool,
};

ContactListItemMenuPopover.defaultProps = {
  onRemove: undefined,
  onSetActionable: undefined,
  onSetReadOnly: undefined,
  onUpdate: undefined,
  showRemove: undefined,
  showSetAccess: undefined,
};

export default ContactListItemMenuPopover;

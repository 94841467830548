import React from 'react';
import { IconNames } from 'components/icon';
import { TooltipMUIIcon } from 'components/tooltip';
import { TooltipMaxWidth, TooltipPadding } from 'constants/tooltip';
import { PartnershipMemberAccess, PartnershipMemberAccessScope } from 'enums/partnerships';
import { ContactSettingsTooltipText } from './components';
import {} from './ContactAutofillIcons.types';
import { getIconColorForAccess } from './helpers';
const ContactAutofillIcons = ({ partnershipMember }) => {
    const { defaultGeneral, defaultItem, managed } = partnershipMember;
    const generalAccess = managed ? PartnershipMemberAccess.SELF_MANAGED : defaultGeneral;
    const itemAccess = managed ? PartnershipMemberAccess.SELF_MANAGED : defaultItem;
    const iconProps = { size: 16 };
    const tooltipProps = {
        arrow: true,
        maxWidth: managed ? undefined : TooltipMaxWidth.NONE,
        padding: TooltipPadding.SKINNY,
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { "data-testid": "autofill-icon--item" },
            React.createElement(TooltipMUIIcon, { icon: IconNames.SIDEBAR_PAYABLES, iconClassName: "margin--sm", iconColor: getIconColorForAccess(itemAccess), iconProps: iconProps, title: React.createElement(ContactSettingsTooltipText, { access: itemAccess, scope: PartnershipMemberAccessScope.ITEMS }), tooltipProps: tooltipProps })),
        React.createElement("div", { className: "ml-3", "data-testid": "autofill-icon--company" },
            React.createElement(TooltipMUIIcon, { icon: IconNames.SIDEBAR_COMPANIES, iconClassName: "margin--sm", iconColor: getIconColorForAccess(generalAccess), iconProps: iconProps, title: React.createElement(ContactSettingsTooltipText, { access: generalAccess, scope: PartnershipMemberAccessScope.COMPANY_MANAGEMENT }), tooltipProps: tooltipProps }))));
};
export default ContactAutofillIcons;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { openSubmitForVerificationConfirmation } from 'actions/signUpFlow';

import { ButtonV2 } from 'components';
import { IconNames } from 'components/icon';

import { ButtonSize } from 'constants/button';
import { colors } from 'constants/styles';

import { hasKYCOrKYBError } from 'selectors/kycKybSelectors';

const SubmitForVerificationButton = ({ withHeaderStyle }) => {
  const dispatch = useDispatch();
  const handleSubmitForVerification = () => {
    dispatch(openSubmitForVerificationConfirmation());
  };

  const { hasAnyVerificationErrors, isKycStatusRetry, isKycStatusDocument } = useSelector(hasKYCOrKYBError);

  let isDisabled = hasAnyVerificationErrors;
  if (isKycStatusRetry || isKycStatusDocument) {
    isDisabled = false; // let users resubmit on retry and document statuses so user can re-verify
  }

  return (
    <>
      <ButtonV2
        dataTestId="submit--button"
        isDisabled={isDisabled}
        onClick={handleSubmitForVerification}
        rightIconClassName="margin-left--xm"
        rightIconColor={colors.colorWhiteHex}
        rightIconName={withHeaderStyle ? undefined : IconNames.ARROW_RIGHT}
        size={withHeaderStyle ? ButtonSize.SMALL : ButtonSize.MEDIUM}
        type="button"
      >
        Submit for verification
      </ButtonV2>
    </>
  );
};

export default SubmitForVerificationButton;

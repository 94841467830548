import { combineReducers } from 'redux';

import errorReducer from './errorReducer';
import isCreatingReducer from './isCreatingReducer';
import isDeletingReducer from './isDeletingReducer';
import isFetchingReducer from './isFetchingReducer';
import isRegeneratingReducer from './isRegeneratingReducer';
import tokensReducer from './tokensReducer';

const apiTokensReducer = combineReducers({
  errors: errorReducer,
  isCreating: isCreatingReducer,
  isDeleting: isDeletingReducer,
  isFetching: isFetchingReducer,
  isRegenerating: isRegeneratingReducer,
  tokens: tokensReducer,
});

export default apiTokensReducer;

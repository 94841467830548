import typography from 'global/css/base/typography.scss';

import * as colors from './colors';
import * as sizes from './sizes';

/**
 * Levels of text size.
 * 300 here is our 'regular'.
 * @enum {number}
 */
export const TextSize = {
  LEVEL_100: 100, // .font-size--xs - 12px
  LEVEL_200: 200, // .font-size--sm - 14px
  LEVEL_300: 300, // .font-size--regular - 13px
  LEVEL_400: 400, // .font-size--m - 16px
  LEVEL_450: 450, // .font-size--18 - 18px
  LEVEL_475: 475, // .font-size--m-l - 20px
  LEVEL_500: 500, // .font-size--l - 22px
  LEVEL_600: 600, // .font-size--larger - 24px
  LEVEL_32: 32, // .font-size--32 - 32px
  UNSET: -1, // auto
};

/**
 * Levels of text weight.
 * 300 here is our 'regular'.
 * @enum {number}
 */
export const TextWeight = {
  LEVEL_100: TextSize.LEVEL_100, // .thin
  LEVEL_200: TextSize.LEVEL_200, // .light
  LEVEL_300: TextSize.LEVEL_300, // .regular
  LEVEL_400: TextSize.LEVEL_400, // .semibold
  LEVEL_500: TextSize.LEVEL_500, // .bold
  LEVEL_600: TextSize.LEVEL_600, // .extra-bold
  UNSET: -1, // auto
};

export const TextWeightToWords = {
  LEVEL_100: 'Thin',
  LEVEL_200: 'Light',
  LEVEL_300: 'Regular',
  LEVEL_400: 'Semibold',
  LEVEL_500: 'Bold',
  LEVEL_600: 'ExtraBold',
};

/**
 * Text colors.
 * @enum {string}
 */
export const TextColor = {
  AQUA_BOLD: colors.colorAquaBoldHex,
  BLACK: colors.colorBlackHex,
  BLACK_NEUTRAL: colors.colorBlackNeutralHex,
  BLUE_BOLD: colors.colorBlueBoldHex,
  BLUE_DARK: colors.colorBlueDarkHex,
  BLUE_X_DARK: colors.colorBlueXDarkHex,
  DARK_JORDAN: colors.colorDarkJordanHex,
  DARK_SILVER: colors.colorDarkSilverHex,
  DEFAULT: colors.colorGreyXDarkHex,
  GREEN_BOLD: colors.colorGreenBoldHex,
  GREEN_DARK: colors.colorGreenDark,
  GREY_DARK: colors.colorGreyDarkHex,
  GREY_X_DARK: colors.colorGreyXDarkHex,
  GREY_XX_DARK: colors.colorGreyXXDarkHex,
  GREY_MED: colors.colorGreyMed,
  MAIN_BLUE: colors.colorBluePrimaryHex,
  MAIN_JORDAN: colors.colorMainJordanHex,
  MAIN_PURPLE: colors.colorMainPurpleHex,
  NAVY: colors.colorBlueDarkHex,
  PURPLE_BOLD: colors.colorPurpleBoldHex,
  PURPLE_DARK: colors.colorPurpleDark,
  RED_BOLD: colors.colorRedBoldHex,
  RED_DARK: colors.colorRedDarkHex,
  STEEL: colors.colorSteelHex,
  WHITE: colors.colorWhiteHex,
  WHITE90: colors.colorWhite90Alpha,
  YELLOW_MEDIUM: colors.colorYellowMediumHex,
  YELLOW_DARK: colors.colorYellowDark,
};

/**
 * Text line heights.
 * @enum {string|number}
 */
export const TextLineHeight = {
  PARAGRAPH: typography.paragraph, // 1.555
  BASE: typography.baseLineHeight, // normal
  SMALL: typography.lineHeightSm, // 14px
  EXTRA_MEDIUM: typography.lineHeightXm, // 16px
  MEDIUM: typography.lineHeightM, // 18px
  LARGE: typography.lineHeightL, // 20px
  EXTRA_LARGE: typography.lineHeightXl, // 22px
  TWENTY_FOUR: typography.lineHeight24, // 24px
  EXTRA_EXTRA_LARGE: typography.lineHeightXxl, // 26px
  TWENTY_EIGHT: typography.lineHeight28, // 28px
  EXTRA_EXTRA_EXTRA_LARGE: typography.lineHeightXxxl, // 32px
  FORTY: typography.lineHeight40, // 40px
  ZERO: typography.lineHeightZero, // 0px
  UNSET: '', // auto
};

/**
 * Text letter-spacing px values.
 * @enum {string}
 */
export const LetterSpacing = {
  EXTRA_EXTRA_SMALL: typography.letterSpacingXxs,
};

/**
 * Style constants used throughout TitleWithSubtitle and subcomponents.
 * @const
 * @type {Object}
 */
export const TitleWithSubtitle = {
  iconProps: {
    marginRight: sizes.spacing.SMALL,
    size: sizes.iconSizes.LARGE,
  },
  textProps: {
    // the normal line height is too large for the title/subtitle combo
    lineHeight: typography.lineHeightXm,
    size: TextSize.LEVEL_100,
  },
};

import {} from 'redux';
import { isTabInbox, isTabPayables } from 'helpers/urls';
import { diffArrays, hasLength, intersection } from 'helpers/utility';
import * as types from 'types/tables';
const generateCheckboxAction = ({ select, deselect }) => ({ isSelect, tab, payload, meta }) => {
    const base = isTabPayables(tab) || isTabInbox(tab) ? types.TABLE_PAYABLES : types.TABLE_RECEIVEABLES;
    const actionType = isSelect ? select : deselect;
    const type = `${base}/${actionType}`;
    return {
        type,
        payload,
        meta,
    };
};
export const multiselectCheckboxAction = generateCheckboxAction({
    select: types.SELECT_MULTIPLE_TABLE_ROWS,
    deselect: types.DESELECT_MULTIPLE_TABLE_ROWS,
});
export const singleSelectCheckboxAction = generateCheckboxAction({
    select: types.SELECT_SINGLE_TABLE_ROW,
    deselect: types.DESELECT_SINGLE_TABLE_ROW,
});
export const areAllIdsSelectedFromCurrentPage = (selectedIds, currentPageIds) => hasLength(selectedIds) && diffArrays(currentPageIds, selectedIds).length === 0;
export const areSomeIdsSelectedFromCurrentPage = (selectedIds, currentPageIds) => hasLength(intersection(selectedIds, currentPageIds));

import React from 'react';
import { Text, WhiteSpace } from 'components';
import { getPartnershipCurrencyNameAndCode } from './helpers';
import {} from './ManuallyAddBankAccountHelpText.types';
const ManuallyAddBankAccountHelpText = ({ partnerCompany, partnershipCurrency, }) => (React.createElement(Text.Regular, null,
    "You're set up to receive payments in",
    React.createElement(WhiteSpace, null),
    getPartnershipCurrencyNameAndCode(partnershipCurrency),
    ". Contact",
    React.createElement(WhiteSpace, null),
    partnerCompany.name,
    React.createElement(WhiteSpace, null),
    "if you need to change your currency."));
export default ManuallyAddBankAccountHelpText;

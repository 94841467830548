import React from 'react';

import { ExternalUpdatePaymentFlowState } from 'constants/ui';

import UpdatePaymentIllustrationSvg from '../UpdatePaymentIllustrationSvg';

/**
 * Renders PendingIllustrationSvg based on external update payment flow state.
 * @return {StatelessComponent}
 */
const PendingIllustrationSvg = () => (
  <UpdatePaymentIllustrationSvg updatePaymentState={ExternalUpdatePaymentFlowState.PENDING} />
);

export default PendingIllustrationSvg;

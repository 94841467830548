import { addItemMemberToItemRoutine } from 'actions/routines/itemMember';

import { allKeys } from 'helpers/utility';

import * as types from 'types/item';

import initialState from './initialState';

const allItemMembersReducer = (state = initialState.allIds, action) => {
  switch (action.type) {
    case types.FETCH_ITEM_REQUEST:
      return initialState.allIds;

    case types.FETCH_ITEM_SUCCESS:
      return allKeys(action.payload.itemMember);

    case addItemMemberToItemRoutine.SUCCESS:
      return Array.from(new Set([...state, ...allKeys(action.payload.itemMember)]));

    default:
      return state;
  }
};

export default allItemMembersReducer;

import PropTypes from 'prop-types';
import React from 'react';

import { isFundingAccountNotUsableNoClearingMatch, isFundingAccountNotUsableNoLedgerMatch } from 'helpers/funding';

const withUnmatchedFundingAccountOrNull = (Component) => {
  const ComponentWithUnmatchedFundingAccountOrNull = (props) => {
    const { fundingAccount } = props;

    if (!fundingAccount) {
      return null;
    }

    const needsMatch = isFundingAccountNotUsableNoLedgerMatch(fundingAccount);
    const needsClearingMatch = isFundingAccountNotUsableNoClearingMatch(fundingAccount);

    if (!needsMatch && !needsClearingMatch) {
      return null;
    }

    return <Component isClearingAccount={needsClearingMatch} {...props} />;
  };

  ComponentWithUnmatchedFundingAccountOrNull.propTypes = {
    fundingAccount: PropTypes.shape(),
    ledger: PropTypes.shape(),
    onCloseSidebar: PropTypes.func,
  };

  ComponentWithUnmatchedFundingAccountOrNull.defaultProps = {
    fundingAccount: undefined,
    ledger: undefined,
    onCloseSidebar: undefined,
  };

  return ComponentWithUnmatchedFundingAccountOrNull;
};

export default withUnmatchedFundingAccountOrNull;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { IconNames } from 'components/icon';
import { TooltipIcon } from 'components/tooltip';

import { formStyles, sizes } from 'constants/styles';

/**
 * A simple button containing an icon and Tooltip on hover. Passed to children of FieldEditWrapper to standardize our
 * editable fields, for example: TINInput.
 * @param {Object} buttonProps
 * @param {function} onEdit
 * @param {Object} tooltipProps
 * @returns {StatelessComponent}
 * @see FieldEditWrapper
 * @constructor
 */
const FieldEditButton = ({ buttonProps, onEdit, tooltipProps }) => (
  <button
    className={classNames({
      'edit-btn-input': true,
      [formStyles.button.ghost.sm.primaryLink]: true,
    })}
    data-testid="input-edit-btn"
    onClick={onEdit}
    type="button"
    {...buttonProps}
  >
    <TooltipIcon
      icon={IconNames.ANNOTATION}
      iconProps={{
        size: sizes.iconSizes.MEDIUM,
      }}
      {...tooltipProps}
    />
  </button>
);

FieldEditButton.propTypes = {
  buttonProps: PropTypes.shape(),
  onEdit: PropTypes.func.isRequired,
  tooltipProps: PropTypes.shape(),
};

FieldEditButton.defaultProps = {
  buttonProps: {},
  tooltipProps: {
    content: 'Edit',
  },
};

export default FieldEditButton;

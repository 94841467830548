import * as types from 'types/transactions';

/**
 * Creates an action to request transactions data.
 * @param {Object} [payload={}]
 * @param {Object} [payload.queryParams]
 * @param {Object} [meta={}]
 * @param {boolean} [meta.append] - Whether to append the new data (falsey will replace)
 * @param {boolean} [meta.updateOffset] - Whether to update the next pagination offset value
 * @return {ReduxAction}
 */
export const fetchTransactionsRequest = (payload = {}, meta = {}) => ({
  meta,
  payload,
  type: types.FETCH_TRANSACTIONS_REQUEST,
});

/**
 * Creates an action with the successful result of fetching transactions.
 * @param {Object} [payload={}]
 * @param {Object} [payload.queryParams]
 * @param {Object} [meta={}]
 * @param {boolean} [meta.append] - Whether to append the new data (falsey will replace)
 * @param {Object} [meta.pagination]
 * @param {boolean} [meta.updateOffset] - Whether to update the next pagination offset value
 * @return {ReduxAction}
 */
export const fetchTransactionsSuccess = (payload = {}, meta = {}) => ({
  meta,
  payload,
  type: types.FETCH_TRANSACTIONS_SUCCESS,
});

/**
 * Creates an action with the errors resulting from fetching transactions.
 * @param {Object} [errors={}]
 * @return {ReduxAction}
 */
export const fetchTransactionsFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_TRANSACTIONS_FAILURE,
});

/**
 * Creates an action to request transactions data for the given item.
 * @param {String} itemId
 * @param {Object} [meta={}]
 * @return {ReduxAction}
 */
export const fetchItemTransactionsRequest = (itemId, meta = {}) => ({
  meta,
  payload: { itemId },
  type: types.FETCH_ITEM_TRANSACTIONS_REQUEST,
});

/**
 * Creates an action with the successful result of fetching transactions for the given item.
 * @param {Object} [payload={}]
 * @param {Object} [meta={}]
 * @return {ReduxAction}
 */
export const fetchItemTransactionsSuccess = (payload = {}, meta = {}) => ({
  meta,
  payload,
  type: types.FETCH_ITEM_TRANSACTIONS_SUCCESS,
});

/**
 * Creates an action with the errors resulting from fetching transactions for the given item.
 * @param {Object} [errors={}]
 * @return {ReduxAction}
 */
export const fetchItemTransactionsFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_ITEM_TRANSACTIONS_FAILURE,
});

/**
 * Creates an action to request balance transactions data.
 * This action is picked up by the saga, which dispatches
 * the normal fetchTransactionsRequest action with appropriate
 * query params, thus we don't use a success or failure action
 * specific to balance transactions.
 * @param {Object} [payload={}]
 * @param {Object} [payload.queryParams]
 * @param {Object} [meta={}]
 * @param {boolean} [meta.append] - Whether to append the new data (falsey will replace)
 * @param {boolean} [meta.updateOffset] - Whether to update the next pagination offset value
 * @return {ReduxAction}
 */
export const fetchBalanceTransactionsRequest = (payload = {}, meta = {}) => ({
  meta,
  payload,
  type: types.FETCH_BALANCE_TRANSACTIONS_REQUEST,
});

import { DateFormats } from '@routable/shared';
import dayjs from 'dayjs';

import { companyBusinessTypeOptions } from 'constants/company';

import { isTinTypeEin } from 'helpers/taxes';

/**
 * Create label/value pairings for BlockListItems in FilingDetailsDisclosureList if the companyType is business.
 * @param {string} businessType
 * @param {string} companyName
 * @param {boolean} showGenericTin
 * @param {string} tinType
 * @returns {array}
 */
export const createBusinessDisclosureListItems = ({ businessType, companyName, showGenericTin, tinType }) => {
  const businessTypeText = companyBusinessTypeOptions.find((type) => type.value === businessType).text;

  const items = [
    {
      label: 'Legal Business Name',
      value: companyName,
    },
    {
      label: 'Business Type',
      value: businessTypeText,
    },
  ];

  if (showGenericTin) {
    items.push({
      label: 'Tax Identification Number',
      value: '•••••••••',
    });
  } else if (isTinTypeEin(tinType)) {
    items.push({
      label: 'Business EIN',
      value: '••-•••••••',
    });
  } else {
    items.push({
      label: 'Social Security Number',
      value: '•••-••-••••',
    });
  }

  return items;
};

/**
 * Create label/value pairing to display the date the filing information was collected or confirmed. If the vendor is
 * currently going through the flow to confirm their details for a secondary partnership, we show them the date we last
 * collected their filing details. When they're complete with the flow, they have confirmed their filing details.
 * @param {boolean} isMultiPartnerConfirm - We've already collected tax info for the vendor, but they're confirming
 * for a secondary partnership
 * @param {string} [submissionDate] - Date details were last collected/confirmed.
 * @returns {array}
 */
export const createDateCompletedItem = ({ isMultiPartnerConfirm, submissionDate }) => {
  const TITLE = isMultiPartnerConfirm ? 'Collected' : 'Confirmed';
  // if user is on success page after confirming taxes for the first time,
  // we won't have the submission date from the backend. However, we know the submission date: today!
  const dayjsSubmissionDate = submissionDate ? dayjs(submissionDate) : dayjs();

  return {
    label: `Date ${TITLE} Filing Details`,
    value: dayjsSubmissionDate.format(DateFormats.SHORT_ALPHA_MONTH_FULL_YEAR),
  };
};

/**
 * Create label/value pairings for BlockListItems in FilingDetailsDisclosureList if the companyType is individual.
 * @param {string} firstName
 * @param {string} lastName
 * @returns {[]}
 */
export const createIndividualDisclosureListItems = ({ firstName, lastName }) => [
  {
    label: 'Legal First Name',
    value: firstName,
  },
  {
    label: 'Legal Last Name',
    value: lastName,
  },
  {
    label: 'Social Security No.',
    value: '•••-••-••••',
  },
];

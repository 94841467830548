import React from 'react';
import { ListEmpty, SettingsTable } from 'components';
import { TABLE_EMPTY_STATE } from 'constants/images';
import { hasLength } from 'helpers/utility';
import { TeamMembersListTableData } from './config';
const ActiveMembersTable = ({ currentCompanyMembers, isFetchingMembers, roles, }) => {
    const hasMembers = roles && hasLength(currentCompanyMembers);
    return (React.createElement("div", { className: "p-6" },
        hasMembers && (React.createElement(SettingsTable, { data: currentCompanyMembers, isLoadingTable: isFetchingMembers, tableConfig: TeamMembersListTableData })),
        !isFetchingMembers && !hasMembers && (React.createElement(ListEmpty, { image: TABLE_EMPTY_STATE, message: "You have not invited any team members." }))));
};
export default ActiveMembersTable;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { toggleLogoutModal } from 'actions/signUpFlow';

import { LinkNewPage, Icon, IconNames } from 'components';

import { HELP_DOCS_URL, SECURITY_URL } from 'constants/platform';
import { colors } from 'constants/styles';

import { isCurrentCompanyKycStatusUnverified } from 'helpers/currentCompany';

import { currentCompanySelector } from 'selectors/currentCompanySelectors';
import { getLogoutModal } from 'selectors/signupSelectors';

import LogoutModal from '../LogoutModal';

const StyledLogoutTray = styled.div`
  color: var(--grey--x-dark);
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  gap: 1rem;
`;

const LogoutLabel = styled.span`
  font-weight: 500;
  line-height: 22px;
`;

const HelperLink = styled(LinkNewPage)`
  color: var(--grey--x-dark);
  display: block;
  font-weight: 400;
  font-size: inherit;
  line-height: 22px;
`;

const LogoutButton = styled.button`
  align-items: center;
  border-top: 1px solid var(--grey--x-light);
  color: var(--grey--x-dark);
  cursor: pointer;
  display: flex;
  padding-top: 1rem;

  &:hover {
    text-decoration: underline;
  }
`;

const LogoutTray = () => {
  const dispatch = useDispatch();
  const logoutModalIsOpen = useSelector(getLogoutModal);

  const currentCompany = useSelector(currentCompanySelector);

  // we only want to show logout when currentCompany is unverified
  // on error/retry/document/pending states, users can 'x' out to go back to settings
  const showLogout = isCurrentCompanyKycStatusUnverified(currentCompany);

  const setIsOpenLogoutSwal = () => {
    dispatch(toggleLogoutModal());
  };

  const handleClickLogout = () => {
    setIsOpenLogoutSwal(true);
  };

  return (
    <StyledLogoutTray>
      <LogoutModal open={logoutModalIsOpen} />
      <HelperLink href={HELP_DOCS_URL} rel="noopener noreferrer" target="_blank">
        Help Docs
        <Icon className="margin-left--sm" color={colors.colorGreyXDarkHex} icon={IconNames.ARROW_TOP_RIGHT} size={12} />
      </HelperLink>
      <HelperLink href={SECURITY_URL} rel="noopener noreferrer" target="_blank">
        Safe & secure
        <Icon className="margin-left--sm" color={colors.colorGreyXDarkHex} icon={IconNames.ARROW_TOP_RIGHT} size={12} />
      </HelperLink>

      {showLogout && (
        <LogoutButton dataTestId="logout-button" onClick={handleClickLogout} type="button">
          <Icon className="margin-right--sm" color={colors.colorGreyXDarkHex} icon={IconNames.LOG_OUT} size={12} />
          <LogoutLabel>Logout</LogoutLabel>
        </LogoutButton>
      )}
    </StyledLogoutTray>
  );
};

export default LogoutTray;

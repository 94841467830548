import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

import { Icon, IconNames } from 'components/icon';

import { colors, sizes } from 'constants/styles';

import { isFn } from 'helpers/utility';

const { MultiValueRemove } = components;

/**
 * Renders the "remove" UI for a single multi-select tag value.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 * @constructor
 */
const TagMultiValueRemove = ({ data, innerProps, ...rest }) => {
  const { onClose } = data;
  const { onClick } = innerProps;

  return (
    <MultiValueRemove data={data} innerProps={{ ...innerProps, onClick: undefined }} {...rest}>
      <button
        aria-label="multi-value-tag-close-button"
        className="multi-value-tag--close-btn"
        onClick={(evt) => {
          onClick(evt);

          if (isFn(onClose)) {
            onClose(evt, data);
          }
        }}
        type="button"
      >
        <div className="multi-value-tag--close-bg">
          <Icon
            className="multi-value-tag--close-icon"
            color={colors.colorBlueBoldHex}
            icon={IconNames.CROSS}
            size={sizes.iconSizes.SMALL}
          />
        </div>
      </button>
    </MultiValueRemove>
  );
};

TagMultiValueRemove.propTypes = {
  data: PropTypes.shape({
    onClose: PropTypes.func,
    type: PropTypes.string,
  }).isRequired,
  innerProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
  }).isRequired,
};

export default TagMultiValueRemove;

import { combineReducers } from 'redux';

import allIdsReducer from './allIdsReducer';
import byIdReducer from './byIdReducer';
import errorReducer from './errorReducer';
import fetchReducer from './fetchReducer';
import partnershipRequestFormReducer from './formReducer';
import metaReducer from './metaReducer';

const partnershipRequestsReducer = combineReducers({
  allIds: allIdsReducer,
  byId: byIdReducer,
  errors: errorReducer,
  isFetching: fetchReducer,
  meta: metaReducer,
  form: partnershipRequestFormReducer,
});

export default partnershipRequestsReducer;

import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components';

import { PartnershipTypes } from 'constants/partnership';
import { AddedContactsSummaryTitleTextProps } from 'constants/ui';

import { capitalize } from 'helpers/stringHelpers';

/**
 * Names the partnership type and sets up for naming of first contact (and others).
 * @param {ComponentProps} props
 * @param {number} props.contactCount
 * @param {PartnershipTypes} props.partnershipType
 * @returns {StatelessComponent}
 */
const PartnershipTypePreamble = ({ contactCount, partnershipType }) => {
  let endingText;
  switch (contactCount) {
    case 0:
      endingText = 'has no contacts';
      break;
    case 1:
      endingText = 'contact is';
      break;
    default:
      endingText = 'contacts include';
  }

  // e.g. Vendor contacts include
  const text = `${capitalize(partnershipType)} ${endingText}`;

  return <Text.Regular {...AddedContactsSummaryTitleTextProps}>{text}</Text.Regular>;
};

PartnershipTypePreamble.propTypes = {
  contactCount: PropTypes.number.isRequired,
  partnershipType: PropTypes.oneOf([PartnershipTypes.CUSTOMER, PartnershipTypes.VENDOR]).isRequired,
};

export default PartnershipTypePreamble;

import React from 'react';
import { Field } from 'redux-form';

import { Checkbox, Col, ItemStatusBox } from 'components';

import { partnerFundingAccountFormFields } from 'constants/formFields';
import { formLabels } from 'constants/partnerFundingAccount';

import { ItemStatuses } from 'enums/items';

/**
 * Renders checkbox for "Apply to Pending" option in
 * AddPartnerFundingAccount form
 * @return {StatelessComponent}
 */
const AddPartnerFundingAccountOptionApplyPending = () => (
  <Col>
    <Field
      className="add-partner-funding-account--options--apply-pending"
      component={Checkbox}
      content={
        <>
          <span>{formLabels.APPLY_TO_PENDING_START}</span>
          &nbsp;
          <ItemStatusBox item={{ status: ItemStatuses.PENDING }} />
          &nbsp;
          <span>{formLabels.APPLY_TO_PENDING_END}</span>
        </>
      }
      name={partnerFundingAccountFormFields.APPLY_TO_PENDING}
    />
  </Col>
);

export default AddPartnerFundingAccountOptionApplyPending;

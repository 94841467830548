/**
 * For the PermissionSectionDisclosureList, are all, none, or some of the permissions enabled.
 *
 * @enum {string}
 */
export const PermissionSectionDisclosureListSummary = {
  ALL: 'all',
  NONE: 'none',
  SOME: 'some',
};

/**
 * Display the correct header prefix based on the PermissionSectionDisclosureListSummary.
 *
 * @enum {string}
 */
export const PermissionSectionDisclosureListHeaderTextPrefix = {
  [PermissionSectionDisclosureListSummary.ALL]: 'Full',
  [PermissionSectionDisclosureListSummary.NONE]: 'No',
  [PermissionSectionDisclosureListSummary.SOME]: 'Limited',
};

import * as types from 'types/cornerDialog';

export const cornerDialogUploadBillsInitialState = {
  body: '',
  open: false,
  title: '',
};

const cornerDialogUploadBillsReducer = (state = cornerDialogUploadBillsInitialState, action) => {
  switch (action.type) {
    case types.CORNER_DIALOG_UPLOAD_BILLS_CLOSE:
      return cornerDialogUploadBillsInitialState;

    case types.CORNER_DIALOG_UPLOAD_BILLS_OPEN:
      return {
        ...cornerDialogUploadBillsInitialState,
        ...action.payload,
        open: true,
      };

    default:
      return state;
  }
};

export default cornerDialogUploadBillsReducer;

import { change } from 'redux-form';

import {
  createItemsFetchPartnershipReceivableFundingAccountsRequest,
  createItemsFetchSinglePartnershipRequest,
} from 'actions/partnership';

import { CompanySearchPartnerTypes } from 'constants/company';
import { createItemFormFields } from 'constants/formFields';
import { formNamesItem } from 'constants/forms';
import { PartnershipAPIStyleParameters } from 'constants/partnership';

import { PartnershipCountryCodeKey } from 'enums/partnerships';

import { isItemKindPayable, isItemKindReceivable } from 'helpers/items';
import { isDomesticPartnership, isPartnershipFull } from 'helpers/partnerships';

import { fundingAccountsIdsWhereUsableForCurrentCompanySelector } from 'queries/fundingCompoundSelectors';

import { createItemFormSelector } from 'selectors/forms';

import { storeAccessor as store } from 'store/accessor';

/**
 * Get default funding account to use for this partnership
 * @param {{ fundingAccountIds: Array, item: Object, partnershipData: Object }}
 */
export const getDefaultPartnershipFundingAccountId = ({ fundingAccountIds, item, partnershipData }) => {
  if (isItemKindPayable(item) && partnershipData.defaultPayableFundingAccount) {
    if (fundingAccountIds.includes(partnershipData.defaultPayableFundingAccount.id)) {
      return partnershipData.defaultPayableFundingAccount.id;
    }
  }

  if (isItemKindReceivable(item) && partnershipData.defaultReceivableFundingAccount) {
    if (fundingAccountIds.includes(partnershipData.defaultReceivableFundingAccount.id)) {
      return partnershipData.defaultReceivableFundingAccount.id;
    }
  }

  return null;
};

/**
 * Return actions to set the default delivery method and delivery option when a partnership is
 * domestic
 * @param {string} form
 * @param {Partnership} partnership
 * @returns {ActionCreator[]}
 */
export const getDomesticDefaultPaymentRailActions = (form, partnership) => {
  const isDomestic = isDomesticPartnership(partnership, PartnershipCountryCodeKey.PARTNER);
  const { defaultPayablePaymentRail, defaultPayableFundingAccount } = partnership;

  /**
   * only for domestic partnerships as international payment rails are set based on the selected currency code receiver
   */
  const shouldUseDefaultPaymentRail =
    isDomestic && !!defaultPayablePaymentRail?.deliveryMethod && !!defaultPayableFundingAccount?.id;

  if (!shouldUseDefaultPaymentRail) {
    return null;
  }

  return [
    change(form, createItemFormFields.ITEM_PAYMENT_DELIVERY_METHOD, defaultPayablePaymentRail.deliveryMethod),
    change(form, createItemFormFields.ITEM_PAYMENT_DELIVERY_OPTION, defaultPayablePaymentRail.deliveryOption),
    change(form, createItemFormFields.ITEM_FUNDING_ACCOUNT_ID, defaultPayableFundingAccount.id),
  ];
};

/**
 * Handle selecting an existing company in SearchCompanies
 * @param {object} partnershipData - partnership data
 * @param {function} callBack - callback function to execute after updating
 * @param {boolean} updateMethod - we update the payment method for existing partners
 */
export const handleChangePartnerCompany = (partnershipData, callBack = null, updateMethod = true) => {
  const form = formNamesItem.CREATE_ITEM;

  const partnershipId = partnershipData.id;

  // Get the item form state
  const reduxState = store.getState();

  const item = createItemFormSelector(reduxState, 'item');
  const isPayable = isItemKindPayable(item);

  // Fetch extra partnership data
  if (!isPartnershipFull(partnershipData)) {
    const queryParams = {
      style: PartnershipAPIStyleParameters.COUNTS,
    };

    store.dispatch(createItemsFetchSinglePartnershipRequest(partnershipId, partnershipData, updateMethod, queryParams));
    store.dispatch(createItemsFetchPartnershipReceivableFundingAccountsRequest(partnershipId, item.kind));

    return;
  }

  store.dispatch([
    change(form, 'partner.name', partnershipData.partner.name),
    change(form, 'partnership.id', partnershipData.id),
  ]);

  // Add default funding account from partnership if exists
  const fundingAccountIds = fundingAccountsIdsWhereUsableForCurrentCompanySelector(reduxState);
  const defaultPartnershipFundingAccount = getDefaultPartnershipFundingAccountId({
    fundingAccountIds,
    item,
    partnershipData,
  });

  if (defaultPartnershipFundingAccount) {
    store.dispatch(change(form, 'item.fundingAccount.id', defaultPartnershipFundingAccount));
  }

  // Update selected company
  store.dispatch(
    change(form, 'ui.selectedCompany', {
      type: CompanySearchPartnerTypes.EXISTING,
      ...partnershipData,
    }),
  );

  // Update payment method for a payable
  if (isPayable && updateMethod) {
    const domesticDefaultPaymentRailActions = getDomesticDefaultPaymentRailActions(form, partnershipData);

    if (domesticDefaultPaymentRailActions) {
      store.dispatch(domesticDefaultPaymentRailActions);
    }
  }

  if (callBack) {
    callBack();
  }
};

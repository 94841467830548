import { parseTransactions as parse } from 'helpers/transactions';

import * as types from 'types/transactions';

import initialState from './initialState';

export default (state = initialState.allIds, action) => {
  const { payload, type } = action;

  switch (type) {
    case types.FETCH_ITEM_TRANSACTIONS_SUCCESS:
    case types.FETCH_TRANSACTIONS_SUCCESS:
      return parse.getTransactionSubObjectAllIdsAfterFetch(state, payload.transactionAch);

    default:
      return state;
  }
};

import React from 'react';
import { formatCurrency } from 'helpers/currencyFormatter';
import { useCurrencyContext } from 'hooks';
import {} from './CurrencyAmountTextPlain.types';
const CurrencyAmountTextPlain = ({ amount, currencyCode }) => {
    const currencyCodeMap = useCurrencyContext();
    const options = {
        format: '%v %s',
        symbol: currencyCode,
    };
    return React.createElement(React.Fragment, null, formatCurrency(amount, currencyCode, currencyCodeMap, options));
};
export default CurrencyAmountTextPlain;

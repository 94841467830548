import { createSelector } from 'reselect';

import { tableNamesCreateItems } from 'constants/tables';

/**
 * Given state, returns the table object.
 * @param {Object} state
 * @returns {Object}
 */
export const getState = (state) => state.table;

// =======================
// table.configs
// =======================

/**
 * Given state, returns the table.configs object.
 * @function
 * @param {Object} state
 * @returns {Object}
 */
export const tableConfigsSelector = createSelector([getState], (table) => table?.configs);

/**
 * Given state, returns the table.companyPaymentHistoryTable object.
 * @function
 * @param {Object} state
 * @returns {Object}
 */
export const tableCompanyPaymentHistoryTableSelector = createSelector(
  [getState],
  (table) => table?.companyPaymentHistoryTable,
);

/**
 * Given state, returns the table.payablesTable object.
 * @function
 * @param {Object} state
 * @returns {Object}
 */
export const tablePayablesTableSelector = createSelector([getState], (table) => table?.payablesTable);

/**
 * Given state, returns the table.receivablesTable object.
 * @function
 * @param {Object} state
 * @returns {Object}
 */
export const tableReceivablesTableSelector = createSelector([getState], (table) => table?.receivablesTable);

/**
 * Given state, returns the table.companyPaymentHistoryTable.sort string.
 * @function
 * @param {Object} state
 * @returns {string}
 */
export const tableCompanyPaymentHistoryTableSortSelector = createSelector(
  [tableCompanyPaymentHistoryTableSelector],
  (table) => table?.sort,
);

/**
 * Given state, returns the table.companyPaymentHistoryTable.partnershipId string.
 * @function
 * @param {Object} state
 * @returns {string}
 */
export const tableCompanyPaymentHistoryTablePartnershipIdSelector = createSelector(
  [tableCompanyPaymentHistoryTableSelector],
  (table) => table?.partnershipId,
);

/**
 * Given state, returns the table.payablesTable.sort string.
 * @function
 * @param {Object} state
 * @returns {string}
 */
export const tablePayablesTableSortSelector = createSelector([tablePayablesTableSelector], (table) => table?.sort);

/**
 * Given state, returns the table.receivablesTable.sort string.
 * @function
 * @param {Object} state
 * @returns {string}
 */
export const tableReceivablesTableSortSelector = createSelector(
  [tableReceivablesTableSelector],
  (table) => table?.sort,
);

/**
 * Given state, returns the table.configs.createItemsPayableTable object.
 * @function
 * @param {Object} state
 * @returns {Object}
 */
export const createItemsPayableTableSelector = createSelector(
  [tableConfigsSelector],
  (configs) => configs?.[tableNamesCreateItems.PAYABLE],
);

/**
 * Given state, returns the table.configs.createItemsReceivableTable.isLoading boolean.
 * @function
 * @param {Object} state
 * @returns {boolean}
 */
export const createItemsPayableTableIsLoadingSelector = createSelector(
  [createItemsPayableTableSelector],
  (payableTable) => payableTable?.isLoading,
);

/**
 * Given state, returns the table.configs.createItemsReceivableTable.schema object.
 * @function
 * @param {Object} state
 * @returns {ObjectMaybe}
 */
export const createItemsPayableTableSchemaSelector = createSelector(
  [createItemsPayableTableSelector],
  (payableTable) => payableTable?.schema,
);

/**
 * Given state, returns the table.configs.createItemsReceivableTable.payableDetailsSchema object.
 * @function
 * @param {Object} state
 * @returns {ObjectMaybe}
 */
export const createItemsPayableDetailsSchemaSelector = createSelector(
  [createItemsPayableTableSelector],
  (payableTable) => payableTable?.payableDetailsSchema,
);

/**
 * Given state, returns the table.configs.createItemsReceivableTable.payableDetailsSchema object.
 * @function
 * @param {Object} state
 * @returns {ObjectMaybe}
 */
export const createItemsPayableDetailsTableSchemaSelector = createSelector(
  [createItemsPayableTableSelector],
  (payableTable) => payableTable?.payableDetailsTableSchema,
);

/**
 * Given state, returns the table.configs.createItemsReceivableTable object.
 * @function
 * @param {Object} state
 * @returns {Object}
 */
export const createItemsReceivableTableSelector = createSelector(
  [tableConfigsSelector],
  (configs) => configs?.[tableNamesCreateItems.RECEIVABLE],
);

/**
 * Given state, returns the table.configs.createItemsReceivableTable.isLoading boolean.
 * @function
 * @param {Object} state
 * @returns {boolean}
 */
export const createItemsReceivableTableIsLoadingSelector = createSelector(
  [createItemsReceivableTableSelector],
  (receivableTable) => receivableTable?.isLoading,
);

/**
 * Given state, returns the table.configs.createItemsReceivableTable.schema object.
 * @function
 * @param {Object} state
 * @returns {ObjectMaybe}
 */
export const createItemsReceivableTableSchemaSelector = createSelector(
  [createItemsReceivableTableSelector],
  (receivableTable) => receivableTable?.schema,
);

/**
 * Given state, returns true if loading either the payable table or the receivable table data
 * @function
 * @param {Object} state
 * @returns {boolean}
 */
export const createItemsTableHasSchemaLoadedSelector = createSelector(
  [createItemsPayableTableSchemaSelector, createItemsReceivableTableSchemaSelector],
  (payableSchema, receivableSchema) => Boolean(payableSchema && receivableSchema),
);

/**
 * Given state, returns true if loading either the payable table or the receivable table data
 * @function
 * @param {Object} state
 * @returns {boolean}
 */
export const createItemsTableIsLoadingSelector = createSelector(
  [createItemsPayableTableIsLoadingSelector, createItemsReceivableTableIsLoadingSelector],
  (isLoadingPayable, isLoadingReceivable) => Boolean(isLoadingPayable || isLoadingReceivable),
);

import { DateFormats } from '@routable/shared';
import dayjs from 'dayjs';

import { DEPOSIT_INFORMATION_TEXT } from 'constants/paymentMethods';
import { PLATFORM_EMAILS } from 'constants/platform';

import { formatDate } from 'helpers/date';
import { isExternalPathAny } from 'helpers/external';
import { getFundingAccountMask, isFundingSourceInvalidMicroDepositsProcessed } from 'helpers/funding';
import { capitalize } from 'helpers/stringHelpers';
import {
  isFlairTypePreferred,
  isFlairTypePrimaryAndPreferred,
  shouldShowVirtualAccountNumberTooltip,
} from 'helpers/ui';
import { getMailtoUrl } from 'helpers/urls';

/**
 * Returns the account number tooltip text in the case of virtual account number is present
 * @param {BankAccountData} fundingInfoBankAccount
 * @param {BankAccountViewType} viewType
 * @return {StringMaybe}
 */
export const getAccountNumberTooltipContent = (fundingInfoBankAccount, viewType) => {
  if (!shouldShowVirtualAccountNumberTooltip(viewType)) {
    return null;
  }

  if (!fundingInfoBankAccount?.isVirtualAccountNumber) {
    return null;
  }

  return "This account number has been securely encrypted through your partner's bank connection and we are unable to show the full account number.";
};

export const getMicroDepositsHintTitle = (options = {}) => {
  const { expectedDate, isNeedToConvertExternalFundingAccount, source } = options;

  if (isFundingSourceInvalidMicroDepositsProcessed(source)) {
    return 'Your bank account should have two micro-deposits available';
  }

  if (isNeedToConvertExternalFundingAccount) {
    return 'This bank account has been previously added';
  }

  if (!expectedDate) {
    return 'Your bank account will soon receive micro-deposits for verification';
  }

  const dateStr = formatDate(dayjs(expectedDate), DateFormats.SHORT_ALPHA_MONTH);
  return `Your bank account will receive micro-deposits no later than ${dateStr} for verification`;
};

export const getMicroDepositsHintText = (options = {}) => {
  const { accountNumber, expectedDate, isNeedToConvertExternalFundingAccount, source } = options;

  if (!expectedDate && isFundingSourceInvalidMicroDepositsProcessed(source)) {
    return `We have a confirmation record of two small amounts deposited to your bank account (ending in ${getFundingAccountMask(
      accountNumber,
    )}). ${DEPOSIT_INFORMATION_TEXT}`;
  }

  if (isNeedToConvertExternalFundingAccount) {
    return `We have record of this bank account being added through the Routable network.
    Please confirm if you’d like to use this bank account for future payment transfers.`;
  }

  if (!expectedDate) {
    return `You will receive two small amounts deposited to your bank account (ending in ${getFundingAccountMask(
      accountNumber,
    )}) within the next 1-2 business days. ${DEPOSIT_INFORMATION_TEXT}`;
  }

  const dateStr = formatDate(dayjs(expectedDate), DateFormats.SHORT_ALPHA_MONTH);
  return `You will receive two small amounts deposited to your bank account (ending in ${getFundingAccountMask(
    accountNumber,
  )}) no later than ${dateStr} (1-2 business days). ${DEPOSIT_INFORMATION_TEXT}`;
};

export const getHelpEmailSubjectLine = (accountNumber) => {
  if (!accountNumber) {
    return "My bank account didn't receive micro deposits";
  }

  return `I didn't receive micro deposits for my bank account ending in ${getFundingAccountMask(accountNumber)}`;
};

export const getBankAccountActionsMailtoUrl = (accountNumber) =>
  isExternalPathAny()
    ? getMailtoUrl(PLATFORM_EMAILS.CHAT, {
        subject: getHelpEmailSubjectLine(accountNumber),
      })
    : getMailtoUrl(PLATFORM_EMAILS.SUPPORT, {
        subject: getHelpEmailSubjectLine(accountNumber),
      });

export const getPartnerBankAccountFlairTooltipText = (flairType, partnership = {}) => {
  let descriptor = 'primary';

  if (isFlairTypePrimaryAndPreferred(flairType)) {
    descriptor = 'primary and preferred';
  } else if (isFlairTypePreferred(flairType)) {
    descriptor = 'preferred';
  }

  if (partnership.name) {
    return `This is the ${descriptor} payment method used to pay ${partnership.name}`;
  }

  return `${capitalize(descriptor)} payment method`;
};

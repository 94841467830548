import { connect } from 'react-redux';

import { externalCollectTaxInfoFormSelector } from 'selectors/forms';
import { taxesInfoEditNotAllowedSelector } from 'selectors/taxesSelectors';

import FilingDetailsForm from './FilingDetailsForm';

const mapStateToProps = (state) => ({
  businessType: externalCollectTaxInfoFormSelector(state, 'form.company.info.businessType'),
  companyType: externalCollectTaxInfoFormSelector(state, 'form.company.companyType'),
  disableForm: taxesInfoEditNotAllowedSelector(state),
  hasEin: externalCollectTaxInfoFormSelector(state, 'ui.hasEin'),
  hasSsn: externalCollectTaxInfoFormSelector(state, 'ui.hasSsn'),
  noEin: externalCollectTaxInfoFormSelector(state, 'ui.noEin'),
  tinType: externalCollectTaxInfoFormSelector(state, 'ui.tinType'),
});

export default connect(mapStateToProps)(FilingDetailsForm);

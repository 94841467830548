import { call, put } from 'redux-saga/effects';

import { handleRequestErrors } from 'actions/errors';
import * as actions from 'actions/passwordInput';

import { parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { sagaWatcher } from 'helpers/saga';

import { CHECK_PASSWORD_STRENGTH } from 'types/passwordInput';

import * as api from './api';

export function* checkPasswordStrength(action) {
  let errorData = {};
  const { payload } = action;

  const requestBody = {
    data: {
      attributes: {
        password: payload,
      },
      type: 'Membership',
    },
  };

  try {
    yield put(actions.fetchPasswordStrengthRequest());
    const response = yield call(api.checkPasswordStrength, requestBody);

    if (response.ok) {
      yield put(actions.fetchPasswordStrengthCompleted(response.originalData.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchPasswordStrengthCompleted, errorData));
}

export default function* passwordInput() {
  yield sagaWatcher([
    {
      type: CHECK_PASSWORD_STRENGTH,
      saga: checkPasswordStrength,
    },
  ]);
}

import React from 'react';
import BaseHint from 'components/hintTypes/BaseHint';
import { textHelpers } from 'components/hintTypes/helpers';
import { WhiteSpace } from 'components/text';
import { Intent } from 'constants/ui';
import { getVendorOrCustomerTitleFromItemKind } from 'helpers/partnerships';
import {} from './CannotReplyNoReachableContactsHint.types';
const CannotReplyNoReachableContactsHint = ({ item }) => {
    const vendorOrCustomer = getVendorOrCustomerTitleFromItemKind(item.kind);
    const hintText = textHelpers.getCannotReplyNoReachableContactsHintText(vendorOrCustomer);
    return (React.createElement(BaseHint, { intent: Intent.WARNING, title: React.createElement(React.Fragment, null,
            hintText,
            React.createElement(WhiteSpace, null)), titleProps: { marginBottom: false } }));
};
export default CannotReplyNoReachableContactsHint;

import { isPartnershipArchived, isPartnershipMatched } from 'helpers/partnerships';
import { getObjectsByIdWithRelationships } from 'helpers/reducer';

import { FETCH_PLATFORM_PARTNERSHIPS_SUCCESS, SUBMIT_PLATFORM_PARTNERSHIP_SUCCESS } from 'types/integrations';

const unmatchedCounterReducer = (state = 0, action) => {
  switch (action.type) {
    case FETCH_PLATFORM_PARTNERSHIPS_SUCCESS: {
      const currentURL = action.payload.links.current;

      // Make sure not to update on search
      if (currentURL.includes('search=')) {
        return state;
      }

      // Only update on unmatched calls
      if (currentURL.includes('is_archived=true') || currentURL.includes('is_matched=true')) {
        return state;
      }

      return action.payload.meta.pagination.count;
    }

    case SUBMIT_PLATFORM_PARTNERSHIP_SUCCESS: {
      const partnershipList = getObjectsByIdWithRelationships(action.payload.partnership);
      const partnershipId = Object.keys(partnershipList)[0];
      const partnership = partnershipList[partnershipId];

      // Increment un-archiving
      if (!isPartnershipMatched(partnership) && !isPartnershipArchived(partnership)) {
        return state + 1;
      }

      // Decrement when matching or archiving
      return state - 1;
    }

    default:
      return state;
  }
};

export default unmatchedCounterReducer;

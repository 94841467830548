import * as types from 'types/transactions';

import initialState from './initialState';

export default (state = initialState.activeFilters, action) => {
  switch (action.type) {
    case types.FETCH_ITEM_TRANSACTIONS_REQUEST:
      return initialState.activeFilters;

    case types.FETCH_TRANSACTIONS_REQUEST:
      return action.payload.queryParams || {};

    default:
      return state;
  }
};

export const CLOSE_BULK_ACTION_MODAL = '@bulkActions/CLOSE_BULK_ACTION_MODAL';

export const DOWNLOAD_CSV_TEMPLATE = '@bulkActions/DOWNLOAD_CSV_TEMPLATE';
export const DOWNLOAD_FAILURE_REPORT = '@bulkActions/DOWNLOAD_FAILURE_REPORT';

export const FETCH_SINGLE_BULK_IMPORT = '@bulkActions/FETCH_SINGLE_BULK_IMPORT';
export const FINALIZE_BULK_IMPORT_ITEMS = '@bulkActions/FINALIZE_BULK_IMPORT_ITEMS';
export const FINALIZE_BULK_ACTION = '@bulkActions/FINALIZE_BULK_ACTION';

export const GET_ALL_BULK_IMPORTS = '@bulkActions/GET_ALL_BULK_IMPORTS';

export const OPEN_BULK_ACTION_APPROVE_MODAL = '@bulkActions/OPEN_BULK_ACTION_APPROVE_MODAL';
export const OPEN_BULK_ACTION_APPROVE_AND_SEND_MODAL = '@bulkActions/OPEN_BULK_ACTION_APPROVE_AND_SEND_MODAL';
export const OPEN_BULK_ACTION_EDIT_SEND_DATE_MODAL = '@bulkActions/OPEN_BULK_ACTION_EDIT_SEND_DATE_MODAL';
export const OPEN_BULK_ACTION_SEND_MODAL = '@bulkActions/OPEN_BULK_ACTION_SEND_MODAL';

export const RESET_BULK_ACTIONS_STATE = '@bulkActions/RESET_BULK_ACTIONS_STATE';

export const SUBMIT_BULK_ACTION = '@bulkActions/SUBMIT_BULK_ACTION';
export const SUBMIT_BULK_IMPORT_ITEMS = '@bulkActions/SUBMIT_BULK_IMPORT_ITEMS';

export const UPLOAD_BULK_IMPORT_FILE = '@bulkActions/UPLOAD_BULK_IMPORT_FILE';

import PropTypes from 'prop-types';
import React from 'react';

import { Icon, IconNames } from 'components/icon';
import { LinkNewPage } from 'components/link';

import { sizes } from 'constants/styles';

/**
 * Link with fileName text in a DocumentRelation.
 * Note: the icon here uses a class name for colorization (as opposed
 * to the normal method of passing in a JS-defined color prop) for
 * simplified management of hover states in CSS.
 * @param {ComponentProps} props
 * @param {string} props.fileName
 * @param {string} props.fileUrl
 * @param {Function} [props.onClick]
 * @return {StatelessComponent}
 * @constructor
 */
const DocumentRelationFileNameLink = ({ fileName, fileUrl, onClick }) => (
  <LinkNewPage className="relation_document--link" href={fileUrl} onClick={onClick}>
    <Icon className="font-color--main-jordan" icon={IconNames.DOCUMENT} marginRight={sizes.spacing.EXTRA_MEDIUM} />

    <span className="font-color--greyXDark font-size--xs">{fileName}</span>
  </LinkNewPage>
);

DocumentRelationFileNameLink.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

DocumentRelationFileNameLink.defaultProps = {
  onClick: undefined,
};

export default DocumentRelationFileNameLink;

import { Search } from '@carbon/icons-react';
import React, {} from 'react';
import { Switch } from '../../../Switch';
import { RowTemplate } from '../row';
import { SkeletonTemplate } from '../skeleton';
import {} from './tablebody.types';
const TABLE_EMPTY_STATE__GROSS_DS = `https://s3-us-west-2.amazonaws.com/routable/images/tables/table-empty-state--gross-ds.svg`;
const EmptyTableContentContainer = ({ children }) => (React.createElement("div", { className: "layer_2 flex flex-col w-max sticky top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 items-center" }, children));
const DefaultNoDataComponent = (React.createElement(EmptyTableContentContainer, null,
    React.createElement("img", { alt: "Table - No data", className: "w-[275px]", src: TABLE_EMPTY_STATE__GROSS_DS }),
    React.createElement("div", { className: "pb-6 text-center flex flex-col gap-1" },
        React.createElement("h2", { className: "font-normal text-lg text-black" }, "No data exists"))));
const DefaultNoDataForFiltersComponent = (React.createElement(EmptyTableContentContainer, null,
    React.createElement("div", { className: "p-3 rounded-lg bg-grey-5" },
        React.createElement(Search, { size: 20 })),
    React.createElement("div", { className: "p-3 text-center flex flex-col gap-1" },
        React.createElement("h2", { className: "font-normal text-lg text-black" }, "No data matches your filters"))));
export const TableBodyTemplate = ({ conditions = [], numOfSkeletonRows, isLoadingComponent = React.createElement(SkeletonTemplate, { numOfSkeletonRows: numOfSkeletonRows ? numOfSkeletonRows : 25 }), isLoadingKey = 'isLoading', noDataComponent = DefaultNoDataComponent, noDataForFiltersComponent = DefaultNoDataForFiltersComponent, results, Template, }) => {
    const { data, filters = {}, [isLoadingKey]: isLoading } = Object(results);
    const isEmpty = !isLoading && data?.length === 0;
    const appliedFiltersCount = Object.values(filters).filter(Boolean)?.length || 0;
    const showLoading = Boolean(isLoading && isLoadingComponent);
    const showNoDataForFilters = Boolean(isEmpty && appliedFiltersCount && noDataForFiltersComponent);
    const showNoData = Boolean(isEmpty && !appliedFiltersCount && noDataComponent);
    return (React.createElement(Switch, null,
        React.createElement(Switch.Case, { condition: showLoading }, isLoadingComponent),
        React.createElement(Switch.Case, { condition: showNoDataForFilters }, noDataForFiltersComponent),
        React.createElement(Switch.Case, { condition: showNoData }, noDataComponent),
        conditions.map(({ condition, Component }, index) => (React.createElement(Switch.Case, { key: `case-${index}`, condition: condition }, Component))),
        React.createElement(Switch.Default, null,
            React.createElement(RowTemplate, { Template: Template }))));
};

import PropTypes from 'prop-types';
import React from 'react';

import { IconNames } from 'components/icon';
import { TooltipIcon } from 'components/tooltip/TooltipIcon';

import { colors, sizes } from 'constants/styles';

import { getApprovalDate } from 'helpers/approvals';
import { getDateAndTime } from 'helpers/date';
import getContactName from 'helpers/user';
import { allValues, isEqual } from 'helpers/utility';

import { Approver } from './components';

/**
 * ApprovalLevelApproverStatus component
 * Displays the approver name + whether approval was made or is still pending
 * @param {ComponentProps} props
 * @param {string} props.itemKind
 * @param {string} props.approvalDate
 * @param {object} props.membershipsById
 * @param {string} props.membership
 * @return {StatelessComponent}
 */
const ApprovalLevelApproverStatus = ({ itemKind, membershipsById, membership: membershipId, approvalDate }) => {
  const approver = allValues(membershipsById).find(({ id }) => isEqual(id, membershipId));
  if (!approver) {
    return null;
  }

  const approverName = getContactName(approver);
  const formattedDate = getApprovalDate({ approvalDate });
  const dateAndTime = getDateAndTime(approvalDate);

  return (
    <div className="approver-level--approver-status">
      <Approver approver={approver} />
      {!!approvalDate && (
        <>
          <span className="font-xxs bold font-color--main-jordan margin-right--xm">{formattedDate}</span>
          <TooltipIcon
            content={`${approverName} approved this ${itemKind} on ${dateAndTime}.`}
            icon={IconNames.TICK}
            iconColor={colors.colorMainGreenHex}
            iconProps={{ size: sizes.iconSizes.LARGE }}
          />
        </>
      )}
      {!approvalDate && (
        <TooltipIcon
          content={`${approverName} has not yet approved this ${itemKind}.`}
          icon={IconNames.TIME}
          iconColor={colors.colorDarkYellowHex}
          iconProps={{ size: sizes.iconSizes.MEDIUM }}
        />
      )}
    </div>
  );
};

ApprovalLevelApproverStatus.propTypes = {
  itemKind: PropTypes.string.isRequired,
  membershipsById: PropTypes.shape().isRequired,
  membership: PropTypes.string.isRequired,
  approvalDate: PropTypes.string,
};

ApprovalLevelApproverStatus.defaultProps = {
  approvalDate: undefined,
};

export default ApprovalLevelApproverStatus;

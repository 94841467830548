import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { change, Field, getFormValues } from 'redux-form';

import { Hint, Checkbox, Text } from 'components';
import CannotUpdateFieldHint from 'components/hintTypes/CannotUpdateFieldHint';

import { countryListOptions } from 'constants/i18n';
import { typography } from 'constants/styles';
import { Intent } from 'constants/ui';

import AddressReduxForm from 'modules/address/presenters/AddressReduxForm';
import { signupFlowForm } from 'modules/signup-v3/constants';
import { containerWidthMap } from 'modules/signup-v3/constants/container';

import {
  isUpdatingCurrentCompanySelector,
  currentCompanyVerificationUpdateAllowedSelector,
} from 'selectors/currentCompanySelectors';

import { fields } from '../fields';
import FlowStepperMain from '../FlowStepperMain';
import { Label, LabelHelper, Container } from '../FlowStepperMain.styles';
import { viewSteps } from '../helpers/viewSteps';

const BusinessAddressMain = ({ activeView, invalid, form: signUpFormName }) => {
  const updatedAllowed = useSelector(currentCompanyVerificationUpdateAllowedSelector);
  const formValueSelector = getFormValues(signUpFormName);
  const dispatch = useDispatch();
  const { [fields.sameAddresses]: sameAddresses, physicalAddress, mailingAddress } = useSelector(formValueSelector);

  React.useEffect(() => {
    if (sameAddresses) {
      // make all mailingAddress fields match physicalAddress fields except for id
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, ...physicalAddressFields } = physicalAddress;
      Object.keys(physicalAddressFields).forEach((physicalAddressField) => {
        dispatch(
          change(signUpFormName, `mailingAddress.${physicalAddressField}`, physicalAddress[physicalAddressField]),
        );
      });
    }
  }, [dispatch, physicalAddress, sameAddresses, signUpFormName]);

  const isUpdating = useSelector(isUpdatingCurrentCompanySelector);
  const mailingCountryIsDefined = Boolean(mailingAddress?.country?.length);

  return (
    <FlowStepperMain
      containerWidth={containerWidthMap.medium}
      fields={
        <Container containerWidth={containerWidthMap.medium}>
          {!updatedAllowed && <CannotUpdateFieldHint />}
          <Hint className="margin-bottom--large" intent={Intent.WARNING}>
            <Text.Semibold color={typography.TextColor.YELLOW_DARK} size={typography.TextSize.LEVEL_200}>
              PO boxes aren&apos;t allowed. If you enter a PO box, your business verification will fail.
            </Text.Semibold>
          </Hint>

          <AddressReduxForm
            addressPath="physicalAddress"
            countryBeforeStreet
            countryFieldTooltipContent="Your registered business address must be in the US."
            countryOptions={countryListOptions.US_ONLY}
            enableCountryFieldTooltip
            formName={signupFlowForm}
            isCountryFieldDisabled
            isCountryFieldLocked
            isFormDisabled={!updatedAllowed}
          />

          <Field
            className="margin-top--m margin-bottom--m"
            component={Checkbox}
            content="This is also the mailing address"
            dataTestId="sameAddressesCheckbox"
            isDisabled={!updatedAllowed}
            name={fields.sameAddresses}
          />

          {!sameAddresses && (
            <div className="margin-top--m">
              <Label>What&apos;s your mailing address?</Label>
              <LabelHelper>To send checks, we need your company&apos;s mailing address.</LabelHelper>
              <AddressReduxForm
                addressPath="mailingAddress"
                countryBeforeStreet
                countryOptions={countryListOptions.US_AND_CANADA}
                formName={signupFlowForm}
                isFormDisabled={!updatedAllowed}
                showAddressFields={mailingCountryIsDefined}
              />
            </div>
          )}
        </Container>
      }
      invalid={invalid || !updatedAllowed}
      onBack={viewSteps[activeView].previous}
      onNext={{
        ...viewSteps[activeView].next,
        isLoading: isUpdating,
      }}
      onSkip={updatedAllowed && viewSteps[activeView].skip}
      subTitle={{
        content:
          'This address must match the one shown on your registered IRS documents, and it might be different than your mailing address.',
      }}
      title={{ content: "What's your registered business address?" }}
    />
  );
};

export default BusinessAddressMain;

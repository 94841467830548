import {} from '../meta.types';
export const withThrottle = ({ id, timeout = 100 }) => (action) => {
    const throttle = {
        id,
        timeout,
    };
    return {
        ...action,
        meta: {
            ...action.meta,
            throttle: {
                ...throttle,
            },
        },
    };
};

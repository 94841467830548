import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Checkbox } from 'components';

import { Intent } from 'constants/ui';

import { StatusLabel } from '../StatusLabel';

/**
 * Component to display a footer form
 * @param {ComponentProps} props
 * @param {string} props.footerCheckBoxText
 * @param {string} props.footerCheckBoxTooltip
 * @param {boolean} props.hasError
 * @param {boolean} props.isChecked
 * @param {function} props.onChange
 * @returns {StatelessComponent}
 */
const SegmentFooter = ({ footerCheckBoxText, footerCheckBoxTooltip, hasError, isChecked, onChange }) => (
  <div
    className={classNames('segment--footer', {
      'segment--footer-error': hasError,
    })}
  >
    <Checkbox content={footerCheckBoxText} isChecked={isChecked} onChange={onChange} tooltip={footerCheckBoxTooltip} />

    {hasError && (
      <div className="segment--footer-error--text">
        <StatusLabel intent={Intent.DANGER} statusLabel="Required Field" />
      </div>
    )}
  </div>
);

SegmentFooter.propTypes = {
  footerCheckBoxText: PropTypes.string.isRequired,
  footerCheckBoxTooltip: PropTypes.string,
  hasError: PropTypes.bool,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

SegmentFooter.defaultProps = {
  footerCheckBoxTooltip: undefined,
  hasError: undefined,
  isChecked: undefined,
  onChange: undefined,
};

export default SegmentFooter;

import { TaxCutoffMonthDay, TinTypes } from 'constants/taxes';

import { isBusinessTypeSoleProprietor } from 'helpers/currentCompany';
import { getCurrentDateLocal, isAfterNow } from 'helpers/date';
import { FloatingPointMath } from 'helpers/math';
import { isEqual } from 'helpers/utility';

/**
 * Helper to determine if the tinType provided is an EIN
 * @param {string} tinType
 * @returns {boolean}
 */
export const isTinTypeEin = (tinType) => isEqual(tinType, TinTypes.EIN);

/**
 * Helper to determine if the tinType provided is an SSN
 * @param {string} tinType
 * @returns {boolean}
 */
export const isTinTypeSsn = (tinType) => isEqual(tinType, TinTypes.SSN);

/**
 * Helper to validate whether a company has an already submitted TIN
 * @param {ObjectMaybe} companyInfo
 * @returns {boolean}
 */
export const doesCompanyHaveTin = (companyInfo) => Boolean(companyInfo && companyInfo.hasTin);

/**
 * Helper to get the EIN/SSN behavior for the onboarding tax form
 * @param companyInfo
 * @returns {boolean}
 */
export const getBusinessFormShouldShowSSN = (companyInfo) => {
  const isSoleProprietor = isBusinessTypeSoleProprietor(companyInfo.businessType);
  const tinTypeSSN = companyInfo && isTinTypeSsn(companyInfo.tinType);

  return Boolean(isSoleProprietor && tinTypeSSN);
};

/**
 * Display the correct tax year depending if the current date is past the tax cutoff date for the year
 * @return {Number}
 */
export const getCurrentTaxYear = () => {
  const currentYear = getCurrentDateLocal().year();
  const isBeforeTaxCutoffDate = isAfterNow(`${currentYear}-${TaxCutoffMonthDay}`);

  return isBeforeTaxCutoffDate ? currentYear - 1 : currentYear;
};

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Multiple tax rates in QBO can be applied to the same amount; this is called compound taxes. This function helps apply
 * taxes to a single amount.
 *
 * @param {string[]} value
 * @returns {Number}
 */
export const qboTaxReduce = (value) => {
  // value comes in as [amount, compoundTax1, compoundTax2, compoundTaxN]

  // Get the amount
  const amount = parseFloat(value[0]);

  // Get the compoundTaxes
  const compoundTaxes = value.slice(1);

  return compoundTaxes.reduce((totalTax, compoundTax) => {
    // Convert the compoundTax into a percent
    const compoundTaxPercent = parseFloat(compoundTax) / 100;

    // Get the amount of tax
    const taxAmount = FloatingPointMath.multiply(compoundTaxPercent, amount);

    // Round it up
    const roundedTaxAmount = FloatingPointMath.roundHalfUp(taxAmount);

    // Add it to the taxes total
    return totalTax + roundedTaxAmount;
  }, 0);
};

import PropTypes from 'prop-types';
import React from 'react';

import { WhiteSpace } from 'components';

const BackLink = ({ onBack }) => (
  <div className="form-control">
    <a className="a--xs dark-grey remove-hover-underline hover-primary" onClick={onBack} role="presentation">
      <span className="icon-ic-arrow-back icon-flip-180" />
      <WhiteSpace />
      Back
    </a>
  </div>
);

BackLink.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default BackLink;

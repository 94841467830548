/**
 * @fileOverview Defines the sub-reducer for feature flag request errors.
 * @module reducers/featureFlags
 */

import { getFeatureFlagsRoutine } from 'actions/routines/featureFlags';

import { createErrorReducer } from 'store/redux';

export const failureCases = [getFeatureFlagsRoutine.FAILURE];

export const successCases = [getFeatureFlagsRoutine.SUCCESS];

/**
 * Reducer for reduxState.featureFlags.errors.
 * @type {ReduxReducer}
 */
const errorsReducer = createErrorReducer(successCases, failureCases);

export default errorsReducer;

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ExternalCurrencyTypeAnnouncementHint } from 'components';

import { partnerCompanyNameFromPartnershipQuerySelector } from 'queries/companyCompoundSelectors';
import { itemKindFromQuerySelector, itemCurrencyCodeReceiverFromQuerySelector } from 'queries/itemRouterSelectors';
import {
  partnershipCurrencyCodeCompanyFromCurrentPartnershipRequestSelector,
  partnershipCurrencyNameFromCurrentPartnershipRequestSelector,
} from 'queries/partnershipCompoundSelectors';

import { byCurrencySelector } from 'selectors/currenciesSelectors';
import { isFetchingItemSelector } from 'selectors/itemsSelectors';

export const mapStateToProps = createStructuredSelector({
  companyName: partnerCompanyNameFromPartnershipQuerySelector,
  currencyCodeCompany: partnershipCurrencyCodeCompanyFromCurrentPartnershipRequestSelector,
  currencyCodeMap: byCurrencySelector,
  currencyName: partnershipCurrencyNameFromCurrentPartnershipRequestSelector,
  itemKind: itemKindFromQuerySelector,
  itemCurrencyCodeReceiver: itemCurrencyCodeReceiverFromQuerySelector,
  isItemLoading: isFetchingItemSelector,
});

export default connect(mapStateToProps)(ExternalCurrencyTypeAnnouncementHint);

import { addItemMemberToItemRoutine, updateItemMemberOnItemRoutine } from 'actions/routines/itemMember';

import { itemMemberRelationships } from 'constants/relationships';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import * as types from 'types/item';

import initialState from './initialState';

const itemMembersByIdReducer = (state = initialState.byId, action) => {
  switch (action.type) {
    case types.FETCH_ITEM_REQUEST:
      return initialState.byId;

    case types.FETCH_ITEM_SUCCESS:
      return getObjectsByIdWithRelationships(action.payload.itemMember, itemMemberRelationships);

    case addItemMemberToItemRoutine.SUCCESS:
    case updateItemMemberOnItemRoutine.SUCCESS:
      return deepMergeWithArrayReplacement(
        state,
        getObjectsByIdWithRelationships(action.payload.itemMember, itemMemberRelationships),
      );

    default:
      return state;
  }
};

export default itemMembersByIdReducer;

export const Displays = {
    default: {
        tableBorder: '0px solid',
        tableBorderColor: 'rgba(0,0,0,0)',
        tableBorderRadius: '0px',
        tableFirstRowTopMargin: '0px',
        tableHeaderBgColor: 'var(--color-grey05)',
        tableFooterBgColor: 'var(--color-grey05)',
    },
    ['full-width']: {
        tableBorder: '0px solid',
        tableBorderColor: 'rgba(0,0,0,0)',
        tableBorderRadius: '0px',
    },
    framed: {
        tableBorder: '1px solid',
        tableBorderColor: 'var(--color-grey20)',
        tableBorderRadius: '8px',
    },
    ['framed-in-segment']: {
        tableBorder: '0px solid',
        tableBorderColor: 'var(--color-grey20)',
        tableBorderRadius: '0px',
        tableHeaderBorder: '1px solid',
        tableHeaderBorderStyle: 'solid none',
        tableMinHeight: '500px',
    },
    ['framed-no-controls']: {
        tableBorder: '1px solid',
        tableBorderColor: 'var(--color-grey20)',
        tableBorderRadius: '8px',
        tableFirstRowTopMargin: '6px',
    },
    condensed: {
        tableBorder: '1px solid',
        tableBorderColor: 'var(--color-grey20)',
        tableBorderRadius: '8px',
        tableFirstRowTopMargin: '0px',
        tableHeaderBgColor: 'var(--color-neutral-white)',
        tableFooterBgColor: 'var(--color-neutral-white)',
    },
    form: {
        tableBorder: '1px solid',
        tableBorderColor: 'var(--color-grey20)',
        tableBorderRadius: '8px',
        tableFirstRowTopMargin: '0px',
    },
};
export const TableColumnWidthOptions = {
    ColumnWidth1: ';min-width: 40px; max-width:40px; flex-grow: 0;',
    ColumnWidth2: ';min-width: 60px; max-width:60px; flex-grow: 1;',
    ColumnWidth3: ';min-width: 80px; max-width:160px; flex-grow: 1;',
    ColumnWidth4: ';min-width: 120px; max-width:192px; flex-grow: 1;',
    ColumnWidth5: ';min-width: 160px; max-width:320px; flex-grow: 2;',
    ColumnWidth6: ';min-width: 240px; max-width:480px; flex-grow: 2;',
    ColumnWidth7: ';min-width: 320px; max-width:480px; flex-grow: 3;',
    ColumnWidth8: ';min-width: 480px; max-width:720px; flex-grow: 4;',
    FullColumn: ';flex: 1;',
};
export const E = {
    type: 'set-sort',
    data: '-updated',
};

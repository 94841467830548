import React from 'react';

import Icon, { IconNames } from 'components/icon';
import LinkNewPage from 'components/link/LinkNewPage';
import HeadingTitle from 'components/text/HeadingTitle';

import { colors, sizes } from 'constants/styles';

import helpDocs from 'helpers/helpDocs';

import './TaxHelpDocsButton.scss';

/**
 * Button at the bottom of the Tax FAQ info card linking to our docs pages.
 * @returns {StatelessComponent}
 * @constructor
 */
const TaxHelpDocsButton = () => (
  <LinkNewPage className="tax-help-docs-button" href={helpDocs.TAX_DOCS_COLLECTION}>
    <Icon
      color={colors.colorBluePrimaryHex}
      icon={IconNames.DOCUMENT}
      marginLeft={sizes.spacing.MEDIUM_LARGE}
      marginRight={sizes.spacing.EXTRA_MEDIUM}
      size={sizes.iconSizes.LARGE}
    />
    <HeadingTitle>View tax-related help docs</HeadingTitle>
    <div className="tax-help-docs--arrow-container">
      <Icon color={colors.colorBluePrimaryHex} icon={IconNames.ARROW_RIGHT} size={sizes.iconSizes.LARGE} />
    </div>
  </LinkNewPage>
);

export default TaxHelpDocsButton;

import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import { useGetRouteQuery } from 'ducks/routableApi';
import { createPartnershipFormFields } from 'constants/formFields';
import {} from 'interfaces/company';
import {} from 'interfaces/global';
import {} from 'interfaces/integrations';
import VendorReceivingCurrency from 'modules/dashboard/vendorReceivingCurrency/VendorReceivingCurrency';
import { currentCompanySettingsIntegrationSelector } from 'selectors/currentCompanySelectors';
import { ledgerIntegrationSelector } from 'selectors/integrationsSelectors';
import {} from './PartnerCurrencyField.types';
export const PartnerCurrencyField = ({ countryCode, className, formName, ...rest }) => {
    const dispatch = useDispatch();
    const response = useGetRouteQuery({
        field: 'receiver_currency',
        receiver_country: countryCode,
    });
    const companyIntegrationSettings = useSelector(currentCompanySettingsIntegrationSelector);
    const ledger = useSelector(ledgerIntegrationSelector);
    const [options, setOptions] = useState([]);
    useEffect(() => {
        if (!response?.data?.data) {
            setOptions([]);
        }
        const arr = [];
        response?.data?.data.forEach((currency) => {
            arr.push({ id: currency, value: currency, text: currency });
        });
        setOptions(arr);
        if (!companyIntegrationSettings.enableBaseCurrencyMode && arr.length === 1) {
            dispatch(change(formName, createPartnershipFormFields.PARTNER_LEDGER_CURRENCY_CODE, arr[0].id));
        }
    }, [dispatch, formName, response?.data?.data]);
    useEffect(() => {
        if (companyIntegrationSettings.enableBaseCurrencyMode) {
            dispatch(change(formName, createPartnershipFormFields.PARTNER_LEDGER_CURRENCY_CODE, ledger.baseCurrencyCode));
        }
    }, [companyIntegrationSettings.enableBaseCurrencyMode, dispatch, formName, ledger.baseCurrencyCode]);
    return (React.createElement("div", { className: clsx('form-control', className) },
        React.createElement(VendorReceivingCurrency, { className: "w-full", fieldLabel: `Assigned currency in ${ledger.name}`, fieldName: createPartnershipFormFields.PARTNER_LEDGER_CURRENCY_CODE, isLocked: response.isFetching ||
                companyIntegrationSettings.enableBaseCurrencyMode ||
                !countryCode ||
                options.length <= 1, options: options, tooltipText: undefined, ...rest })));
};

import { BulkActionTypes } from 'enums/bulkActions';

import * as types from 'types/bulkActions';

const bulkActionReducer = (state = null, action) => {
  switch (action.type) {
    case types.OPEN_BULK_ACTION_APPROVE_MODAL:
      return BulkActionTypes.APPROVE;

    case types.OPEN_BULK_ACTION_APPROVE_AND_SEND_MODAL:
      return BulkActionTypes.APPROVE_AND_SEND;

    case types.OPEN_BULK_ACTION_EDIT_SEND_DATE_MODAL:
      return BulkActionTypes.EDIT_SEND_DATE;

    case types.OPEN_BULK_ACTION_SEND_MODAL:
      return BulkActionTypes.SEND;

    case types.CLOSE_BULK_ACTION_MODAL:
      return null;

    default:
      return state;
  }
};

export default bulkActionReducer;

import { apiCall, convertToSnakeCase } from '@routable/framework';
import { itemApprovalsSettingsApiModel } from '../../models';
export const approvalsItemSettingsUrl = 'approvals/settings/item/';
export const itemApprovalsSettingsApi = {
    get: async () => apiCall({
        url: approvalsItemSettingsUrl,
        method: 'get',
        responseModel: itemApprovalsSettingsApiModel,
        camelCaseResponse: true,
    }),
    update: async (data) => apiCall({
        url: approvalsItemSettingsUrl,
        body: await convertToSnakeCase(data),
        method: 'patch',
        responseModel: itemApprovalsSettingsApiModel,
        camelCaseResponse: true,
    }),
};

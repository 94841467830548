import PropTypes from 'prop-types';

export const propTypes = {
  defaultReceivable: PropTypes.shape(),
  errors: PropTypes.shape(),
  formUI: PropTypes.shape(),
  // fundingAccounts is used by shouldUpdateFundingAccount
  /* eslint-disable-next-line react/no-unused-prop-types */
  fundingAccounts: PropTypes.shape().isRequired,
  fundingAccountsWithAchFundingSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fundingAccountsWithAddressFundingSources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isAddressModalOpen: PropTypes.bool,
  isConnectBankModalOpen: PropTypes.bool,
  isUpdatePaymentMethodFlow: PropTypes.bool,
  lastSubmittedFundingAccount: PropTypes.shape({}),
  onOpenCreateManualAddressModal: PropTypes.func.isRequired,
  onSubmitAllExistingItems: PropTypes.func.isRequired,
  onValuesChange: PropTypes.func.isRequired,
  paymentDeliveryMethod: PropTypes.string,
  shouldShowMicroDepositsHint: PropTypes.bool.isRequired,
  shouldShowMaxPaymentMethodsHint: PropTypes.bool.isRequired,
};

export const defaultProps = {
  defaultReceivable: {},
  formUI: { defaultReceivable: {} },
  errors: undefined,
  isAddressModalOpen: undefined,
  isConnectBankModalOpen: undefined,
  isUpdatePaymentMethodFlow: undefined,
  lastSubmittedFundingAccount: undefined,
  paymentDeliveryMethod: undefined,
};

import { call, put, spawn, take } from 'redux-saga/effects';

import * as actions from 'actions/billing';
import { handleRequestErrors } from 'actions/errors';

import { parseCaughtError, parseErrorResponse } from 'helpers/errors';

import * as types from 'types/billing';

import * as api from './api';

/**
 * Handle billing code data.
 * @return {IterableIterator<*>}
 */
export function* fetchBillingCodes() {
  let errorData = {};

  try {
    const response = yield call(api.fetchBillingCodes);

    if (response.ok) {
      yield put(actions.fetchBillingCodesSuccess(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchBillingCodesFailure, errorData));
}

/**
 * Listens for redux actions related to billing.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([types.FETCH_BILLING_CODES_REQUEST]);

    switch (action.type) {
      case types.FETCH_BILLING_CODES_REQUEST:
        yield spawn(fetchBillingCodes);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root affiliates saga.
 * @return {IterableIterator<*>}
 */
export default function* affiliates() {
  yield watch();
}

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { colorBlueExtraLight } from 'constants/styles/colors';

import { getQueryParam } from 'helpers/queryParams';

import { partnershipPartnerCompanySelector } from 'queries/partnershipCompoundSelectors';

import { companyBrandPrimaryColorSelector } from 'selectors/brandSelectors';

import ExternalHeaderPartnerInfo from './ExternalHeaderPartnerInfo';
import PoweredByBadge from './PoweredByBadge';

import './ExternalHeader.scss';

const ExternalHeader = ({ partnership, primaryColor }) => {
  if (!partnership) {
    return null;
  }

  return (
    <div className="external-header--container">
      <div
        className="external-header"
        style={
          primaryColor && {
            borderBottom: `2px solid ${primaryColor}`,
            boxShadow: `0 1px 2px ${colorBlueExtraLight}`,
          }
        }
      >
        <ExternalHeaderPartnerInfo />
        <PoweredByBadge />
      </div>
    </div>
  );
};

ExternalHeader.propTypes = {
  partnership: PropTypes.shape(),
  primaryColor: PropTypes.string,
};

ExternalHeader.defaultProps = {
  partnership: undefined,
  primaryColor: undefined,
};

const mapStateToProps = (state) => {
  const partnershipId = getQueryParam('partnership_id');
  const partnership = partnershipPartnerCompanySelector(state, partnershipId) || {};

  return {
    partnership,
    primaryColor: partnership.brand ? companyBrandPrimaryColorSelector(state, partnership.id) : undefined,
  };
};

export { ExternalHeader };
export default connect(mapStateToProps)(ExternalHeader);

import { fetchItemsRoutine } from 'actions/routines/item';

import { allKeys } from 'helpers/utility';

import * as types from 'types/item';

const initialState = null;

export const itemsSearchReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case fetchItemsRoutine.TRIGGER:
      // new fetch clears old search state
      return initialState;

    case fetchItemsRoutine.SUCCESS:
      return allKeys(action.payload.item);

    case fetchItemsRoutine.FAILURE:
      return initialState;

    case types.FETCH_ITEM_SUCCESS:
      return [Object.keys(action.payload.item)[0], ...(state || [])];

    default:
      return state;
  }
};

export default itemsSearchReducer;

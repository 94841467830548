import { IconNames } from 'components/icon';

import PermissionResourceAction from 'constants/permissions';
import { CREATE_ITEM_STATES, CREATE_ITEM_TABS, DASHBOARD } from 'constants/routes';

import { isItemPaymentDeliveryMethodAchOrInternational } from 'helpers/items';
import {
  getPartnershipPermissionResourceForEditAction,
  getPartnershipPermissionResourceForFundingAccountCreateAction,
  isPartnershipCustomer,
  isPartnershipTypeCustomer,
} from 'helpers/partnerships';
import { getJoinedPath } from 'helpers/routeHelpers';
import { isEqual } from 'helpers/utility';

import { handleChangePartnerCompany } from 'modules/dashboard/createItems/helpers/partnerships';

import {
  PartnerPaymentMethodStatusButtonType,
  PartnerPaymentMethodStatusButtonTypeIcon,
  PartnerPaymentMethodStatusButtonTypeText,
} from './constants/ui';

/**
 * Returns icon name to display in PartnerPaymentStatus based on the passed isEnabled boolean
 * @param {boolean} isEnabled
 * @returns {string}
 */
export const getStatusIconName = (isEnabled) => (isEnabled ? IconNames.TICK_CIRCLE : IconNames.WARNING_SIGN);

/**
 * Get the status for the left content related to ACH.
 *
 * @param {OptionsArg} options
 * @param {boolean} options.isEnabled
 * @param {string} options.partnerName
 * @param {PartnershipTypes} options.partnershipType
 * @returns {string}
 */
export const getBankTransferStatusText = ({ isEnabled, partnerName, partnershipType }) => {
  if (isEnabled) {
    if (isPartnershipTypeCustomer(partnershipType)) {
      return `${partnerName} is set up to pay with ACH`;
    }

    return `${partnerName} can receive Bank Transfers`;
  }

  if (isPartnershipTypeCustomer(partnershipType)) {
    return `${partnerName} is not set up to pay with ACH`;
  }

  return `${partnerName} is not set up to receive Bank Transfers`;
};

/**
 * Get the status for the left content related to checks.
 *
 * @param {OptionsArg} options
 * @param {boolean} options.isEnabled
 * @param {string} options.partnerName
 * @param {PartnershipTypes} options.partnershipType
 * @returns {string}
 */
export const getCheckStatusText = ({ isEnabled, partnerName, partnershipType }) => {
  if (isEnabled) {
    if (isPartnershipTypeCustomer(partnershipType)) {
      return `${partnerName} is set up to pay with Checks`;
    }

    return `${partnerName} can receive Check payments`;
  }

  if (isPartnershipTypeCustomer(partnershipType)) {
    return `${partnerName} is not set up to pay with Checks`;
  }

  return `${partnerName} is not set up to receive Check payments`;
};

/**
 * Get the status text for check or ACH.
 *
 *
 * @param {OptionsArg} options
 * @param {boolean} options.isEnabled
 * @param {string} options.partnerName
 * @param {PartnershipTypes} options.partnershipType
 * @param {PaymentDeliveryMethod} options.paymentDeliveryMethod
 * @returns {string}
 */
export const getStatusText = ({ isEnabled, partnerName, partnershipType, paymentDeliveryMethod }) => {
  if (isItemPaymentDeliveryMethodAchOrInternational({ paymentDeliveryMethod })) {
    return getBankTransferStatusText({
      isEnabled,
      partnerName,
      partnershipType,
    });
  }

  return getCheckStatusText({ isEnabled, partnerName, partnershipType });
};

/**
 * Get options related to partnership used throughout the PartnerPaymentMethodStatus
 *
 * @param {OptionsArg} options
 * @param {Partnership} options.partnership
 * @param {PartnershipTypes} options.partnershipType
 * @returns {{displayingCustomerType: Boolean, isCustomer: Boolean}}
 */
export const getCustomerOptions = ({ partnership, partnershipType }) => ({
  displayingCustomerType: isPartnershipTypeCustomer(partnershipType),
  isCustomer: isPartnershipCustomer(partnership),
});

/**
 * Determine which call to action button to display
 *
 * @param {OptionsArg} options
 * @param {Partnership} options.partnership
 * @param {PartnershipTypes} options.partnershipType
 * @returns {{displayCtaButton: boolean, displaySendInvoice: boolean}}
 */
export const determineWhichButtonToDisplay = ({ partnership, partnershipType }) => {
  // partnershipType = displayCustomerType
  const { displayingCustomerType, isCustomer } = getCustomerOptions({
    partnership,
    partnershipType,
  });
  const displaySendInvoice = displayingCustomerType && !isCustomer;

  return {
    displayCtaButton: !displayingCustomerType || displaySendInvoice,
    displaySendInvoice,
  };
};

/**
 * Equality helper to check if buttonType is for address.
 *
 * @param {PartnerPaymentMethodStatusButtonType} buttonType
 * @returns {boolean}
 */
export const isCtaButtonTypeAddress = (buttonType) => isEqual(buttonType, PartnerPaymentMethodStatusButtonType.ADDRESS);

/**
 * Equality helper to check if buttonType is for bank account.
 *
 * @param {PartnerPaymentMethodStatusButtonType} buttonType
 * @returns {boolean}
 */
export const isCtaButtonTypeBankAccount = (buttonType) =>
  isEqual(buttonType, PartnerPaymentMethodStatusButtonType.BANK_ACCOUNT);

/**
 * Equality helper to check if buttonType is for invoice.
 *
 * @param {PartnerPaymentMethodStatusButtonType} buttonType
 * @returns {boolean}
 */
export const isCtaButtonTypeInvoice = (buttonType) => isEqual(buttonType, PartnerPaymentMethodStatusButtonType.INVOICE);

/**
 * Define the permissions which we use to gate the call to action button.
 *
 * @param {OptionsArg} options
 * @param {PartnerPaymentMethodStatusButtonType} options.buttonType
 * @param {Partnership} options.partnership
 * @returns {[]|PermissionResourceAction[]}
 */
export const getCtaButtonRequiredPermissions = ({ buttonType, partnership }) => {
  const partnershipEdit = getPartnershipPermissionResourceForEditAction(partnership);
  const partnershipFundingAccountEdit = getPartnershipPermissionResourceForFundingAccountCreateAction();
  const requiredPermissions = [];

  // "Add address" or "Add bank account"
  if (isCtaButtonTypeAddress(buttonType) || isCtaButtonTypeBankAccount(buttonType)) {
    requiredPermissions.push(partnershipEdit, partnershipFundingAccountEdit);
  }

  if (isCtaButtonTypeInvoice(buttonType)) {
    requiredPermissions.push(PermissionResourceAction.RECEIVABLE_CREATE);
  }

  return requiredPermissions;
};

/**
 * Determine which kind of partner payment method this component is displaying to derive the button type.
 *
 * @param {OptionsArg} options
 * @param {boolean} options.displayingCustomerType
 * @param {boolean} options.isCustomer,
 * @param {string} options.paymentDeliveryMethod
 * @returns {PartnerPaymentMethodStatusButtonType}
 */
export const getCtaButtonType = ({ displayingCustomerType, isCustomer, paymentDeliveryMethod }) => {
  if (displayingCustomerType && !isCustomer) {
    return PartnerPaymentMethodStatusButtonType.INVOICE;
  }

  if (isItemPaymentDeliveryMethodAchOrInternational({ paymentDeliveryMethod })) {
    return PartnerPaymentMethodStatusButtonType.BANK_ACCOUNT;
  }

  return PartnerPaymentMethodStatusButtonType.ADDRESS;
};

/**
 * Gets all properties needed for our payment method status header's call-to-action
 * button.
 *
 * @param {OptionsArg} options
 * @param {Partnership} options.partnership
 * @param {PartnershipTypes} options.partnershipType
 * @param {string} options.paymentDeliveryMethod
 * @return {Object}
 */
export const getCtaButtonProperties = ({ partnership, partnershipType, paymentDeliveryMethod }) => {
  const buttonType = getCtaButtonType({
    ...getCustomerOptions({ partnership, partnershipType }),
    paymentDeliveryMethod,
  });

  return {
    buttonIcon: PartnerPaymentMethodStatusButtonTypeIcon[buttonType],
    buttonText: PartnerPaymentMethodStatusButtonTypeText[buttonType],
    requiredPermissions: getCtaButtonRequiredPermissions({
      buttonType,
      partnership,
    }),
    ...determineWhichButtonToDisplay({ partnership, partnershipType }),
  };
};

export const createHandleCtaButtonClick =
  ({ defaultCallback, displaySendInvoice, history, partnership }) =>
  async () => {
    if (displaySendInvoice) {
      // Waiting for path to re-render because handleChangePartnerCompany relies on createItem form existing
      await history.push(getJoinedPath(DASHBOARD, CREATE_ITEM_TABS.CREATE_RECEIVABLE, CREATE_ITEM_STATES.NEW));
      // call `handleChangePartnerCompany` to pre-populate the customer field on in createItem form
      // added fields to partnership object to match function requirements
      handleChangePartnerCompany({
        ...partnership,
        companyName: partnership.name,
        defaultPaymentTerms: undefined, // set to undefined to force fetch extra partnership data
        partner: {
          name: partnership.name,
        },
      });
    } else {
      defaultCallback();
    }
  };

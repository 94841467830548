import classNames from 'classnames';
import React from 'react';

import { IsLoading } from 'modules/isLoading';

/**
 * Loading indicator for tables in the dashboard
 * @return {StatelessComponent}
 */
const TableLoading = () => (
  <div
    className={classNames({
      'align-items--center': true,
      'display--flex': true,
      'item-list-wrapper': true,
      'item-list-wrapper--empty': true,
      'justify-content--center': true,
    })}
  >
    <IsLoading />
  </div>
);

export default TableLoading;

import { IconNames } from 'components/icon';

import { TextColor } from 'constants/styles/typography';

/**
 * Returns correct icon name for payment method hint based on the passed
 * isActive value.
 * @param {boolean} isActive
 * @returns {string}
 */
export const getFooterIcon = (isActive) => (isActive ? IconNames.TICK_CIRCLE : IconNames.TIME);

/**
 * Returns correct color for payment method hint footer background based on
 * the passed isActive value.
 * @param {boolean} isActive
 * @return {string}
 */
export const getFooterTextColor = (isActive) => (isActive ? TextColor.GREEN_BOLD : TextColor.YELLOW_MEDIUM);

/**
 * @fileOverview Defines action routines related to bulk actions.
 * @module actions/routines/bulkActions
 */

import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';

import * as types from 'types/bulkActions';

/**
 * @type {ReduxSagaRoutine}
 */
export const getSingleBulkImportRoutine = createRoutine(types.FETCH_SINGLE_BULK_IMPORT);

/**
 * @type {ReduxSagaRoutine}
 */
export const getAllBulkImportsRoutine = createRoutine(types.GET_ALL_BULK_IMPORTS);

/**
 * @type {ReduxSagaRoutine}
 */
export const uploadBulkImportFileRoutine = createRoutine(types.UPLOAD_BULK_IMPORT_FILE);

/**
 * @type {ReduxSagaRoutine}
 */
export const submitBulkActionRoutine = createRoutine(types.SUBMIT_BULK_ACTION);

/**
 * @type {BoundRoutineHandler}
 */
export const submitBulkActionHandler = bindRoutineToReduxForm(submitBulkActionRoutine);

/**
 * @type {ReduxSagaRoutine}
 */
export const finalizeBulkActionRoutine = createRoutine(types.FINALIZE_BULK_ACTION);

/**
 * @type {ReduxSagaRoutine}
 */
export const submitBulkImportItemsRoutine = createRoutine(types.SUBMIT_BULK_IMPORT_ITEMS);

/**
 * @type {BoundRoutineHandler}
 */
export const submitBulkImportItemsHandler = bindRoutineToReduxForm(submitBulkImportItemsRoutine);

/**
 * @type {ReduxSagaRoutine}
 */
export const finalizeBulkImportItemsRoutine = createRoutine(types.FINALIZE_BULK_IMPORT_ITEMS);

/**
 * @type {ReduxSagaRoutine}
 */
export const downloadFailureReportRoutine = createRoutine(types.DOWNLOAD_FAILURE_REPORT);

import { getModalReducerOpenAction } from 'store/redux';

import { MODAL_ADD_EMAIL_FORWARD_CLOSE, MODAL_ADD_EMAIL_FORWARD_OPEN } from 'types/modals';

export const initialState = { open: false };

const addEmailForwardReducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_ADD_EMAIL_FORWARD_CLOSE:
      return initialState;

    case MODAL_ADD_EMAIL_FORWARD_OPEN:
      return getModalReducerOpenAction(state, action);

    default:
      return state;
  }
};

export default addEmailForwardReducer;

import React from 'react';

import { WithFeatureFlag } from 'components';

import { universalRenderPropType } from 'helpers/propTypes';

/**
 * Function returning a convenience wrapper around WithFeatureFlag,
 * to gate a component's render behind the specified feature flag.
 * Defaults the fallback children to null (overrideable).
 * @param {string} featureFlag
 * @param {AnyComponent|null} [fallback]
 * @return {AnyComponent}
 */
const withFeatureFlag = (featureFlag, fallback) => {
  const WithFeatureFlagComponent = (props) => (
    <WithFeatureFlag fallback={fallback} featureFlag={featureFlag} {...props} />
  );

  WithFeatureFlagComponent.propTypes = {
    children: universalRenderPropType,
    fallback: universalRenderPropType,
  };

  WithFeatureFlagComponent.defaultProps = {
    children: undefined,
    fallback: null,
  };

  return WithFeatureFlagComponent;
};

export default withFeatureFlag;

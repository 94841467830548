import React from 'react';
import { NoRegisteredAddressesAddedHint } from 'components';
import CompanyAddressDataCard from '../CompanyAddressDataCard';
import {} from './CompanyAddressSection.types';
import './CompanyAddressSection.scss';
const CompanyAddressSection = ({ address, disableManageActions, disabledTooltipProps, isEdittingDisabled, isSubmitting, onAddRegisteredAddress, onEditRegisteredAddress, isInternational = false, }) => {
    if (address) {
        return (React.createElement("div", { className: "company-address-section" },
            React.createElement(CompanyAddressDataCard, { address: address, disabledTooltipProps: disabledTooltipProps, disableManageActions: disableManageActions, isEdittingDisabled: isEdittingDisabled, isSubmitting: isSubmitting, onEdit: onEditRegisteredAddress })));
    }
    return (React.createElement("div", { className: "company-address-section" },
        React.createElement(NoRegisteredAddressesAddedHint, { isInternational: isInternational, onClick: onAddRegisteredAddress })));
};
export default CompanyAddressSection;

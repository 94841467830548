import { Tooltip } from '@routable/components';
import PropTypes from 'prop-types';
import React from 'react';

import { TagType } from 'constants/ui';

import { tagTypeProp } from 'helpers/propTypes';
import { getClassNames } from 'helpers/ui';

/**
 * Renders the tag label wrapper element.
 * Determines the wrapper type (Tooltip if has
 * a tooltip) and renders it with children.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 */
const TagTooltip = (props) => {
  const { children, tooltipContent, tooltipProps, ...rest } = props;
  const wrapper = tooltipContent ? Tooltip : 'span';

  // we need to use tooltipClassName here instead of just className because the "className" passed to Tag
  // is intended for the Tag component and not this Tooltip wrapper
  const className = getClassNames(rest, {}, { classProp: 'tooltipClassName' });

  const tooltipPaneProps = {
    tooltip: tooltipContent,
    className,
    ...tooltipProps,
  };

  const wrapperProps = tooltipContent ? tooltipPaneProps : { className };

  return React.createElement(wrapper, wrapperProps, children);
};

TagTooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  tooltipContent: PropTypes.node,
  tooltipProps: PropTypes.shape(),
  type: tagTypeProp,
};

TagTooltip.defaultProps = {
  children: undefined,
  className: undefined,
  tooltipContent: undefined,
  tooltipProps: undefined,
  type: TagType.DEFAULT,
};

export default TagTooltip;

import PropTypes from 'prop-types';
import React from 'react';

import { IconNames } from 'components/icon';
import { TooltipMUIButton } from 'components/tooltip';

import { ButtonSize } from 'constants/button';
import { SETTINGS_ACCOUNT_BALANCE_ROUTE } from 'constants/routes';

import { text } from 'modules/dashboard/settings/account/balance/constants';

const BalanceAmountBlockSettingsLink = ({ history }) => (
  <React.Fragment>
    <TooltipMUIButton
      id="balanceAmountBlock_settingsLink_btn"
      leftIconClassName="margin-right--xm"
      leftIconName={IconNames.COG}
      onClick={() => history.push(SETTINGS_ACCOUNT_BALANCE_ROUTE)}
      rightIconClassName="margin-left--m"
      rightIconName={IconNames.ARROW_RIGHT}
      size={ButtonSize.MEDIUM}
      title={text.balanceAmountBlock.addFundsTooltip}
      type="button"
    >
      {text.balanceAmountBlock.viewSettingsButton}
    </TooltipMUIButton>
  </React.Fragment>
);

BalanceAmountBlockSettingsLink.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default BalanceAmountBlockSettingsLink;

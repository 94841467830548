import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, FieldArray, getFormValues } from 'redux-form';

import { deleteRepresentativeDocumentRoutine, postRepresentativeDocumentRoutine } from 'actions/routines/documents';

import { IconNames, Radio } from 'components';
import CannotUpdateFieldHint from 'components/hintTypes/CannotUpdateFieldHint';

import { DocumentStatuses, DocumentTypes } from 'constants/document';
import {
  ID_SUCCESS,
  ID_FAIL,
  ID_BLUR,
  PASSPORT_SUCCESS,
  PASSPORT_BLUR,
  PASSPORT_FAIL,
  LICENSE_SUCCESS,
  LICENSE_BLUR,
  LICENSE_FAIL,
} from 'constants/images';
import { colors } from 'constants/styles';

import { containerWidthMap } from 'modules/signup-v3/constants/container';
import { viewSteps } from 'modules/signup-v3/helpers/viewSteps';

import { currentMembershipDocumentsSelector } from 'selectors/documentsSelectors';
import {
  currentMembershipSelector,
  currentMembershipVerificationDocumentUpdateAllowedSelector,
  isFetchingMembershipSelector,
} from 'selectors/membershipsSelector';

import UploadFile from '../components/UploadFile';
import { fields as fieldNames } from '../fields';
import FlowStepperMain from '../FlowStepperMain';

import {
  Container,
  UploadedDocumentText,
  DocumentOptionsContainer,
  DocumentHintText,
  HintsContainer,
  HintContainer,
  HintIcon,
  HintImage,
  HintText,
} from './RepresentativeUploadDocument.style';

const documentHints = {
  [DocumentTypes.DRIVERS_LICENSE]: {
    text: 'Take a new photo of your document, keeping these three requirements in mind:',
    hints: [
      {
        id: `${DocumentTypes.DRIVERS_LICENSE}-1`,
        image: LICENSE_SUCCESS,
        valid: true,
        text: (
          <>
            <b>Do</b> place your document on a solid background, and use natural light rather than a flash.
          </>
        ),
      },
      {
        id: `${DocumentTypes.DRIVERS_LICENSE}-2`,
        image: LICENSE_FAIL,
        valid: false,
        text: (
          <>
            <b>Don&apos;t</b> take a photo that captures only some of the corners of your document. We need to see all
            four.
          </>
        ),
      },
      {
        id: `${DocumentTypes.DRIVERS_LICENSE}-3`,
        image: LICENSE_BLUR,
        valid: false,
        text: (
          <>
            <b>Don&apos;t</b> submit a photo that&apos;s out of focus, or includes any objects obscuring parts of your
            document.
          </>
        ),
      },
    ],
  },
  [DocumentTypes.IDENTIFICATION_CARD]: {
    id: `${DocumentTypes.IDENTIFICATION_CARD}-1`,
    text: 'Take a new photo of your document, keeping these three requirements in mind:',
    hints: [
      {
        image: ID_SUCCESS,
        valid: true,
        text: (
          <>
            <b>Do</b> place your document on a solid background, and use natural light rather than a flash.
          </>
        ),
      },
      {
        id: `${DocumentTypes.IDENTIFICATION_CARD}-2`,
        image: ID_FAIL,
        valid: false,
        text: (
          <>
            <b>Don&apos;t</b> take a photo that captures only some of the corners of your document. We need to see all
            four.
          </>
        ),
      },
      {
        id: `${DocumentTypes.IDENTIFICATION_CARD}-3`,
        image: ID_BLUR,
        valid: false,
        text: (
          <>
            <b>Don&apos;t</b> submit a photo that&apos;s out of focus, or includes any objects obscuring parts of your
            document.
          </>
        ),
      },
    ],
  },
  [DocumentTypes.PASSPORT]: {
    id: `${DocumentTypes.PASSPORT}-1`,
    text: 'Take a new photo of your document, keeping these three requirements in mind:',
    hints: [
      {
        image: PASSPORT_SUCCESS,
        valid: true,
        text: (
          <>
            <b>Do</b> place your document on a solid background, and use natural light rather than a flash.
          </>
        ),
      },
      {
        id: `${DocumentTypes.PASSPORT}-2`,
        image: PASSPORT_FAIL,
        valid: false,
        text: (
          <>
            <b>Don&apos;t</b> take a photo that captures only some of the corners of your document. We need to see all
            four.
          </>
        ),
      },
      {
        id: `${DocumentTypes.PASSPORT}-3`,
        image: PASSPORT_BLUR,
        valid: false,
        text: (
          <>
            <b>Don&apos;t</b> submit a photo that&apos;s out of focus, or includes any objects obscuring parts of your
            document.
          </>
        ),
      },
    ],
  },
};

const UploadDocumentField = ({ form, hasPassportNumber, isDisabled }) => {
  const currentMember = useSelector(currentMembershipSelector);
  const formValueSelector = getFormValues(form);
  const dispatch = useDispatch();
  const { documentUploadType } = useSelector(formValueSelector);
  const documentType = hasPassportNumber ? DocumentTypes.PASSPORT : documentUploadType;
  const currentMembershipDocuments = useSelector((state) => currentMembershipDocumentsSelector(state));
  const hasUploadedDocument = currentMembershipDocuments.some(
    (document) => document.status === DocumentStatuses.PENDING,
  );

  // Documents are returned on the member
  const isFetchingMembership = useSelector(isFetchingMembershipSelector);

  const documentHint = documentHints[documentType];

  return (
    <>
      {!hasPassportNumber && (
        <DocumentOptionsContainer>
          <Field
            component={Radio}
            id={DocumentTypes.DRIVERS_LICENSE}
            isDisabled={isDisabled || (hasUploadedDocument && documentType !== DocumentTypes.DRIVERS_LICENSE)}
            isHorizontalLayout
            name={fieldNames.documentUploadType}
            optionText="Driver's license"
            type="radio"
            value={DocumentTypes.DRIVERS_LICENSE}
          />
          <Field
            component={Radio}
            id={DocumentTypes.IDENTIFICATION_CARD}
            isDisabled={isDisabled || (hasUploadedDocument && documentType !== DocumentTypes.IDENTIFICATION_CARD)}
            isHorizontalLayout
            name={fieldNames.documentUploadType}
            optionText="State-issued ID card"
            type="radio"
            value={DocumentTypes.IDENTIFICATION_CARD}
          />
          <Field
            component={Radio}
            id={DocumentTypes.PASSPORT}
            isDisabled={isDisabled || (hasUploadedDocument && documentType !== DocumentTypes.PASSPORT)}
            isHorizontalLayout
            name={fieldNames.documentUploadType}
            optionText="Passport"
            type="radio"
            value={DocumentTypes.PASSPORT}
          />
        </DocumentOptionsContainer>
      )}

      {documentHint && <DocumentHintText>{documentHint.text}</DocumentHintText>}
      {documentHint && (
        <HintsContainer>
          {documentHint?.hints.map((documentHint) => (
            <HintContainer key={documentHint.id}>
              <HintIcon
                color={documentHint.valid ? colors.colorGreenBoldHex : colors.colorRedBoldHex}
                icon={documentHint.valid ? IconNames.TICK_CIRCLE : IconNames.BAN_CIRCLE}
                size={24}
              />
              <HintImage alt="document example" src={documentHint.image} />
              <HintText>{documentHint.text}</HintText>
            </HintContainer>
          ))}
        </HintsContainer>
      )}

      <FieldArray
        component={({ fields }) => {
          const handleDocumentDelete = (file) => {
            const documentId = currentMembershipDocuments[file].id;

            dispatch(
              deleteRepresentativeDocumentRoutine.trigger({
                documentId,
                currentMemberId: currentMember.id,
                removeFileFromList: () => fields.remove(file),
              }),
            );
          };

          const handleDocumentUpload = (file, addFileToList) => {
            dispatch(
              postRepresentativeDocumentRoutine.trigger({
                file,
                currentMemberId: currentMember.id,
                addFileToList,
                documentType,
                dispatch,
              }),
            );
          };
          return (
            <>
              {hasUploadedDocument && <UploadedDocumentText>Uploaded document:</UploadedDocumentText>}
              <UploadFile
                accept={['image/png', 'image/jpg', 'image/jpeg']}
                addFile={handleDocumentUpload}
                errorMessage="You can only upload PNG and JPG files. Please try again."
                fields={fields}
                isDisabled={isDisabled}
                isFetching={isFetchingMembership}
                remove={handleDocumentDelete}
                showDropzone={!hasUploadedDocument}
                supportText="PNG & JPG files are allowed"
              />
            </>
          );
        }}
        name={fieldNames.documentsOwned}
        validate={(fields) => (fields?.length > 0 ? undefined : 'Must have at least one document')}
      />
    </>
  );
};

const UploadDocumentMain = ({ activeView, form, invalid }) => {
  const updatedAllowed = useSelector(currentMembershipVerificationDocumentUpdateAllowedSelector);
  const formValueSelector = getFormValues(form);
  const {
    ui: { hasPassportNumber },
  } = useSelector(formValueSelector);

  const title = hasPassportNumber ? 'Upload an image of your passport' : 'Upload a document to verify your identity';
  return (
    <>
      <FlowStepperMain
        containerWidth={containerWidthMap.medium}
        fields={
          <Container>
            {!updatedAllowed && <CannotUpdateFieldHint />}
            <UploadDocumentField form={form} hasPassportNumber={hasPassportNumber} isDisabled={!updatedAllowed} />
          </Container>
        }
        invalid={invalid || !updatedAllowed}
        onBack={viewSteps[activeView].previous}
        onNext={viewSteps[activeView].next}
        onSkip={(invalid || !updatedAllowed) && viewSteps[activeView].skip}
        submit={false}
        title={{ content: title }}
      />
    </>
  );
};

export default UploadDocumentMain;

import * as routines from 'actions/routines/roles';

import { createAllIdsReducer } from 'store/redux';

const allIdsReducer = createAllIdsReducer({
  key: 'role',
  types: [routines.fetchRolesRoutine.SUCCESS],
});

export default allIdsReducer;

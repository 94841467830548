import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import _orderBy from 'lodash/orderBy';

import { PartnershipMemberAccess } from 'constants/partnershipMember';
import { TagText, TagType } from 'constants/ui';

import { PartnershipMemberProps } from 'data/resources/member';

import { formatting } from 'helpers/billing';
import { isPartnershipMemberStatusIssue } from 'helpers/contacts';
import {
  getMembershipEmailAddress,
  getMembershipFullName,
  getMembershipNameOrEmailOrPhoneNumber,
  showRemovedMembersToast,
} from 'helpers/memberships';
import { isPartnershipMemberEmailStatusBounced } from 'helpers/partnershipMembers';
import { isPaymentDeliveryMethodInternational } from 'helpers/paymentDeliveryOption';
import { getMultiSelectTagTypeForMember } from 'helpers/ui';
import { arrayWithout, diffArrays, isEqual, isGreaterThan, isLessThan, isValueEmpty, mapSet } from 'helpers/utility';

/**
 * Returns the details for a payment delivery option.
 * @param {BillingCodeData} optionBillingData
 * @return {string}
 */
export const getDeliveryOptionDetails = (optionBillingData) => {
  const rateDescription = formatting.getFormattedDeliveryOptionRateDescription(optionBillingData);
  const speedDescription = formatting.getFormattedBillingCodeSpeedDescription(optionBillingData);

  if (isPaymentDeliveryMethodInternational(optionBillingData.paymentDeliveryMethod)) {
    return speedDescription;
  }
  return `${speedDescription}, ${rateDescription}`;
};

/**
 * Sorts options by sort field (ASC) and formats them accordingly
 * @see {PaymentDeliveryOptionSelect}
 *
 * @param {Object} availableDeliveryOptions
 * @return {{ label: String, details: String, value: String }[]}
 */
export const getMethodDeliveryOptionsFormatted = (availableDeliveryOptions) =>
  _orderBy(availableDeliveryOptions, ['sort'], ['asc'])
    .filter(Boolean)
    .map((optionBillingData) => ({
      label: formatting.getBillingCodeDisplayTitleWithMethodTrimmed(optionBillingData),
      details: getDeliveryOptionDetails(optionBillingData),
      value: optionBillingData.paymentDeliveryOption,
    }));

/**
 * Given a partner member object, returns the tooltip content to use for their multi-select tag.
 * @param {PartnershipMember} member
 * @return {StringMaybe}
 */
export const getMultiSelectTagTooltipContentForMember = (member) =>
  isPartnershipMemberEmailStatusBounced(member)
    ? `This contact's email address has bounced. 
    Update their email address to enable notifications for ${getMembershipFullName(member)}.`
    : undefined;

export const getOptionTag = (member) => {
  let isDisabled = false;
  let optionTagText;
  let optionTagType;

  if (member.isArchived) {
    isDisabled = true;
    optionTagText = TagText[TagType.ARCHIVED];
    optionTagType = TagType.ARCHIVED;
  } else if (isValueEmpty(member.email)) {
    isDisabled = true;
    optionTagText = TagText[TagType.NO_EMAIL];
    optionTagType = TagType.NO_EMAIL;
  } else if (isPartnershipMemberStatusIssue(member?.status)) {
    isDisabled = true;
    optionTagText = TagText[TagType.BOUNCED_EMAIL];
    optionTagType = TagType.BOUNCED_EMAIL;
  }

  return [isDisabled, optionTagText, optionTagType];
};

/**
 * Given a partner member object, returns the data to use for its accompanying option in a select field.
 * @param {PartnershipMember} member
 * @return {PartnershipMemberSelectOption}
 */
export const getOptionDataFromPartnerMember = (member) => {
  const fullName = getMembershipFullName(member);
  const label = getMembershipNameOrEmailOrPhoneNumber(member);
  const [isDisabled, optionTagText, optionTagType] = getOptionTag(member);

  return {
    data: member,
    isDisabled,
    label,
    optionTagText,
    optionTagType,
    tooltipContent: getMultiSelectTagTooltipContentForMember(member),
    type: getMultiSelectTagTypeForMember(member),
    value: member.id,
    verboseLabel: fullName ? getMembershipEmailAddress(member) : undefined,
  };
};

/**
 * Given member option data objects array, return sorted array by optionTagText and label
 * @param {PartnershipMemberSelectOption[]} memberOptions
 * @return {PartnershipMemberSelectOption[]} - sorted
 */
export const sortPartnerMemberOptionsByNameAndTagText = (memberOptions) =>
  _orderBy(
    memberOptions,
    [(member) => member.optionTagText?.toUpperCase(), (member) => member.label?.toUpperCase()],
    ['desc', 'asc'],
  );

/**
 * Given a partner member select option, returns a partner member object.
 * @param {PartnershipMemberSelectOption} option
 * @return {PartnershipMember}
 */
export const getPartnerMemberFromOptionData = (option) => option.data;

/**
 * Given the current state of partner contact members, and the desired next state for the
 * select field being targeted, derives the next overall state, and returns the updated
 * array of partner members.
 * @param {PartnershipMemberProps} accessProperty
 * @param {Object} params
 * @property {PartnershipMemberSelectOption[]|null} params.currentSecondaryMembers - Current selections in other field
 * @property {PartnershipMemberSelectOption[]|null} params.currentTargetMembers - Current selections in target field
 * @property {string} params.access - Access to apply to selections in target field
 * @property {PartnershipMemberSelectOption[]|null} params.selectValue
 * @return {PartnershipMember[]} Updated array of all partner members
 */
export const getNextPartnerMembersForGroupedContactSelects = (accessProperty, params = {}) => {
  const { currentSecondaryMembers, currentTargetMembers, access, selectValue } = params;

  const value = selectValue || []; // null when nothing is selected

  // transform values back into PartnershipMember objects here first
  // (outside components always deal with the PartnershipMember and never select options)
  let nextTargetMembers = value.map(getPartnerMemberFromOptionData);
  const nextSecondaryMembers = currentSecondaryMembers.map(getPartnerMemberFromOptionData);

  // if we just selected new members to add, we have to update the access on those selections
  if (nextTargetMembers.length > currentTargetMembers.length) {
    // update the data for all members currently chosen in THIS select field
    nextTargetMembers = mapSet(nextTargetMembers, accessProperty, access);
  } else {
    // if we have just de-selected members, get those that have been unselected, and update each access
    const mappedCurrentTargetMembers = currentTargetMembers.map(getPartnerMemberFromOptionData);
    const removedTargetMembers = diffArrays(mappedCurrentTargetMembers, nextTargetMembers, PartnershipMemberProps.id);
    const updatedRemovedMembers = mapSet(removedTargetMembers, accessProperty, PartnershipMemberAccess.NONE);

    // join these back with the next target members array
    nextTargetMembers = nextTargetMembers.concat(updatedRemovedMembers);
  }

  // return a new super-array (as both selects are the same "field", and share a "value")
  return nextTargetMembers.concat(nextSecondaryMembers);
};

/**
 * Returns the value of the GroupedContactSelects field, grouped into two arrays, one for each
 * of the values of defaultItem (excluding 'none').
 * @param {PartnershipMember[]} selectValue
 * @param {PartnershipMemberProps} [accessProperty=PartnerMemberProps.defaultItem]
 * @return {{ readOnlyMembers: *[], actionMembers: *[] }}
 */
export const getContactSelectValuesByAccess = (selectValue, accessProperty = PartnershipMemberProps.defaultItem) => {
  // group the value property by what level of access has been set on each member
  const groupedMembers = _groupBy(selectValue, accessProperty);

  // these grouped values will be given to their respective select fields
  const actionMembers = groupedMembers[PartnershipMemberAccess.ACTIONABLE] || [];
  const readOnlyMembers = groupedMembers[PartnershipMemberAccess.READ_ONLY] || [];

  return { actionMembers, readOnlyMembers };
};

/**
 * Given an object representing a react-select option, and some user input, return whether
 * the given option should be displayed in the select menu.
 * @param {Object} option
 * @param {string} input
 * @return {Boolean}
 */
export const shouldDisplayOptionInContactSelectMenu = (option, input) => {
  const { label, verboseLabel } = option.data;

  const inputString = input.toLowerCase();
  const searchString = `${label} ${verboseLabel}`.toLowerCase();

  return searchString.includes(inputString);
};

/**
 * Updates the field value with the newly chosen options.
 * If the newly selected option was already selected in another field (for eg. a lower approval level),
 * the option is removed from that other field and the current user is notified of this removal via a toast
 * @param {Object} params
 * @property {string} params.currentFieldName - Field name
 * @property {PartnershipMemberSelectOption[]} params.currentlySelected - Current selections in field
 * @property {string} params.formName - Form name
 * @property {string} params.position - Level of select field
 * @property {PartnershipMemberSelectOption[]} params.selectValue - Updated array
 * @property {ApprovalSelectConfig[]} params.selectConfigs
 * @return {PartnershipMemberSelectOption[]} Updated array
 */
export const getNextPartnerMembersForMutuallyExclusiveSelects = (params = {}) => {
  const {
    currentFieldName,
    currentlySelected,
    formName,
    formValues,
    onFieldChange,
    onShowToast,
    position,
    selectValue,
    selectConfigs,
  } = params;

  // newly selected value
  const value = selectValue || []; // null when nothing is selected

  // if we have added a new option
  // we should then check if the newly added option
  // was included in the value of the other selects
  if (isGreaterThan(value.length, currentlySelected.length)) {
    // we map all of the selects
    selectConfigs.forEach((config) => {
      if (!formValues) {
        return;
      }
      // return if its the same field
      if (isEqual(config.fieldName, currentFieldName)) {
        return;
      }

      // We use get from lodash because the field name might be nested
      // ex. item.approvers-1
      const currentFieldValues = _get(formValues, config.fieldName);
      // return if the field doesn't have values yet
      if (!currentFieldValues) {
        return;
      }

      const newlyAdded = arrayWithout(value, currentlySelected, 'value');
      // nextFieldValues is the updated array of values of another select
      const nextFieldValues = arrayWithout(currentFieldValues, newlyAdded, 'value');

      // this means that the newly selected option was part of this select
      // by this select I mean the one we are currently mapping
      if (isLessThan(nextFieldValues.length, currentFieldValues.length)) {
        // show toast notification about removed option
        showRemovedMembersToast({
          members: newlyAdded.map(getPartnerMemberFromOptionData),
          position: config.position,
          onShowToast,
        });
        // update field value
        onFieldChange(formName, config.fieldName, nextFieldValues);
      }
    });
  } else {
    // this means that an option(s) has been removed and we just show a toast notification
    const removed = arrayWithout(currentlySelected, value, 'value');
    showRemovedMembersToast({
      members: removed.map(getPartnerMemberFromOptionData),
      position,
      onShowToast,
    });
  }

  return value;
};

/**
 * It checks if two item member objects are referencing to the same membership_id
 *
 * When a member's instance comes prefilled from the backend its membership_id is related to partnershipMember key
 * but, when it comes from a newly selected item member, the membership_id is related to the id key.
 * @property {Object} memberA
 * @property {Object} memberB
 * @return {Boolean}
 */
export const areSameItemMembers = (memberA, memberB) => {
  const memberAId = memberA.partnershipMember ?? memberA.id;
  const memberBId = memberB.partnershipMember ?? memberB.id;
  return isEqual(memberAId, memberBId);
};

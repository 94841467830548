import { Tooltip } from '@routable/gross-ds';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';
import { IconNames } from 'components/icon';
import RestrictedPermissionContent from 'components/RestrictedPermissionContent';

import { ButtonSize } from 'constants/button';

import { text } from 'modules/dashboard/settings/account/balance/constants';

/**
 * Button which helps a user fix a negative account balance.
 *
 * @param {ComponentProps} props
 * @param {boolean} [props.isDisabled]
 * @param {function} [props.onFixNegative]
 * @returns {StatelessComponent}
 */
const NegativeBalanceAmountButton = ({ isDisabled, onFixNegative }) => (
  <Tooltip
    data-testid="negative-balance-amount-tooltip"
    tooltip={isDisabled ? <RestrictedPermissionContent /> : text.balanceAmountBlock.addFundsTooltip}
  >
    <ButtonV2
      isDisabled={isDisabled}
      leftIconClassName="margin-right--xm"
      leftIconName={IconNames.PLUS}
      onClick={onFixNegative}
      size={ButtonSize.MEDIUM}
    >
      {text.balanceAmountBlock.fixNegativeButton}
    </ButtonV2>
  </Tooltip>
);

NegativeBalanceAmountButton.propTypes = {
  isDisabled: PropTypes.bool,
  onFixNegative: PropTypes.func,
};

NegativeBalanceAmountButton.defaultProps = {
  isDisabled: undefined,
  onFixNegative: undefined,
};

export default NegativeBalanceAmountButton;

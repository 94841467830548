import React from 'react';
import SignUpVisualAid from '../components/SignUpVisualAid';
import { formModuleSelector } from './helpers/formModuleSelector';
import { ModuleWrapperContainer, FormModule, HelperModule } from './ModuleWrapper.styles';
import {} from './types/ModuleWrapper.types';
const ModuleWrapper = ({ helperModule, formModule }) => (React.createElement(ModuleWrapperContainer, null,
    React.createElement(HelperModule, null,
        React.createElement(SignUpVisualAid, { component: helperModule })),
    React.createElement(FormModule, null, formModuleSelector(formModule))));
export default ModuleWrapper;

import PropTypes from 'prop-types';
import React from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';

import CellRenderer from 'components/table/CellRenderer';

import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from 'constants/tables';

import { isExternalPathAccept } from 'helpers/external';
import { isItemStatusNew } from 'helpers/items';
import { allKeys } from 'helpers/utility';

import { paymentTableData, requestTableData } from './data';

import './ExternalItemsListTable.scss';

/**
 * Component rendering table of items in external accept/pay flow
 * @param {ComponentProps} props
 * @param {Item[]} props.items
 * @param {Function} props.generateSimilarItemURL
 * @returns {StatelessComponent}
 */
const ExternalItemsListTable = ({ items, generateSimilarItemURL }) => {
  const TableData = isExternalPathAccept(window.location) ? paymentTableData : requestTableData;
  const numOfItems = items?.length;
  const tableHeight = numOfItems * TABLE_ROW_HEIGHT + TABLE_HEADER_HEIGHT;

  return (
    <div className="external-items-table-container" style={{ height: tableHeight }}>
      <AutoSizer>
        {({ width, height }) => (
          <Table
            className="invoice-table disable-hover"
            headerClassName="remove-border-top ledger"
            headerHeight={TABLE_HEADER_HEIGHT}
            height={height}
            rowClassName="disable-hover"
            rowCount={numOfItems}
            rowGetter={({ index }) => ({
              ...items[index],
              ...{
                itemURL: generateSimilarItemURL(items[index].id),
                showButton: isItemStatusNew(items[index]),
              },
            })}
            rowHeight={TABLE_ROW_HEIGHT}
            width={width}
          >
            {allKeys(TableData).map((column) => (
              <Column
                cellRenderer={CellRenderer}
                className={`${TableData[column].className}`}
                columnData={TableData[column]}
                dataKey={column}
                flexGrow={TableData[column].width}
                headerClassName={`${TableData[column].headerClassName}`}
                key={column}
                label={TableData[column].displayName}
                width={100}
              />
            ))}
          </Table>
        )}
      </AutoSizer>
    </div>
  );
};

ExternalItemsListTable.propTypes = {
  generateSimilarItemURL: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
};

export default ExternalItemsListTable;

import PropTypes from 'prop-types';
import React from 'react';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { FormControl } from 'components/form';

import { AddFileInput } from './components';

/**
 * Button which opens the FileExplorer for uploading a file on click
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 */
const AddFileButton = (props) => (
  <FormControl className="remove-margin-bottom">
    <AddFileInput {...props} />
  </FormControl>
);

AddFileButton.propTypes = {
  dataTestId: PropTypes.string,
};

AddFileButton.defaultProps = {
  dataTestId: undefined,
};

export default AddFileButton;

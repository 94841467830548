import { postCompanyDocumentRoutine, postRepresentativeDocumentRoutine } from 'actions/routines/documents';

const isPosting = (state = {}, action) => {
  switch (action.type) {
    case postCompanyDocumentRoutine.TRIGGER:
      return {
        ...state,
        isPosting: true,
        file: action.payload.file,
      };
    case postRepresentativeDocumentRoutine.TRIGGER:
      return {
        ...state,
        isPosting: true,
        file: action.payload.file,
      };
    case postCompanyDocumentRoutine.SUCCESS:
    case postCompanyDocumentRoutine.FAILURE:
    case postRepresentativeDocumentRoutine.SUCCESS:
    case postRepresentativeDocumentRoutine.FAILURE:
      return {
        ...state,
        isPosting: false,
      };
    default:
      return state;
  }
};

export default isPosting;

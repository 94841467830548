import classNames from 'classnames';
import React from 'react';
import {} from './ContentBlockShade.types';
import { noPropagationMouseEvent } from './helper';
const ContentBlockShade = ({ children, className, isShady, ...rest }) => (React.createElement("div", { className: classNames({
        'content-block--shade': true,
        shady: isShady,
        [className]: !!className,
    }), onClickCapture: (evt) => (isShady ? noPropagationMouseEvent(evt) : undefined), ...rest },
    children,
    React.createElement("div", { className: "content-block--shade--overlay" })));
export default ContentBlockShade;

import { createSelector } from 'reselect';

export const getState = (state) => state.files;

/**
 * Returns the files.isFetching boolean.
 * @function
 * @param {ReduxState} state
 * @return {boolean}
 */
export const filesIsFetchingSelector = createSelector([getState], (state) => state.isFetching);

/**
 * Returns the files.objectUrl string.
 * @function
 * @param {ReduxState} state
 * @return {string}
 */
export const filesObjectUrlSelector = createSelector([getState], (state) => state.objectUrl);

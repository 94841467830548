import { FETCH_INTEGRATIONS_SUCCESS } from 'types/integrations';

import { initialState } from './initialState';

const dashboardLoadedIntegrationsReducer = (state = initialState.integrations, action) => {
  switch (action.type) {
    case FETCH_INTEGRATIONS_SUCCESS:
      return true;

    default:
      return state;
  }
};

export default dashboardLoadedIntegrationsReducer;

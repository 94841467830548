import { queryContext } from '@routable/shared';
import { useQuery } from '@tanstack/react-query';
import { overlaysApi } from './overlays.service';
import { generateKeys } from './queryKeys';
export const useItemOverlays = ({ enabled = true, itemId, purchaseOrderNumber }) => {
    return useQuery({
        enabled,
        context: queryContext,
        queryKey: generateKeys.itemOverlays(itemId, purchaseOrderNumber),
        queryFn: async () => overlaysApi.getItemOverlays(itemId, purchaseOrderNumber),
        retry: false,
        staleTime: Infinity,
        select: (response) => response.data,
    });
};

import PropTypes from 'prop-types';
import React from 'react';

import { FeesChargedSeparatelyWarning } from 'complexComponents/tooltipTypes/sharedComponents';

import { Icon, IconNames } from 'components/icon';
import { TextAndIconsRelation } from 'components/relation';
import { TooltipMUIIcon } from 'components/tooltip';

import { colors, margin, sizes } from 'constants/styles';
import { TooltipPadding } from 'constants/tooltip';

import { formatCurrencyUSD } from 'helpers/currencyFormatter';

const TransactionFeeRelation = (props) => {
  const { billingData, feeAmount } = props;

  if (!billingData) {
    return null;
  }

  const transactionFee = formatCurrencyUSD(feeAmount);
  /**
   * Here, billingData.title is used instead of paymentDeliveryOptionExternalName. Title
   * is meant to be used for the fees associated with a payment.
   */
  const text = `${transactionFee} - ${billingData.title}`;

  return (
    <TextAndIconsRelation
      className="relation_transaction-fee"
      iconLeft={
        <Icon
          color={colors.colorAquaBoldHex}
          icon={IconNames.SMALL_PLUS}
          marginRight={margin.small}
          size={sizes.iconSizes.LARGE}
        />
      }
      iconRight={
        <TooltipMUIIcon
          icon={IconNames.INFO_SIGN}
          iconProps={{ size: sizes.iconSizes.LARGE }}
          tooltipProps={{
            className: 'relation--right-icon',
            arrow: true,
            padding: TooltipPadding.LARGE,
            title: <FeesChargedSeparatelyWarning />,
          }}
        />
      }
      text={text}
    />
  );
};

TransactionFeeRelation.propTypes = {
  billingData: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  feeAmount: PropTypes.number.isRequired,
};

export default TransactionFeeRelation;

import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';
import { IconNames } from 'components/icon';

import { ButtonAppearance, ButtonSize } from 'constants/button';
import { colors } from 'constants/styles';
import { Intent } from 'constants/ui';

/**
 * The "previous page" button in tables pagination.
 * @param {ComponentProps} props
 * @param {boolean} props.canPreviousPage
 * @param {Function} props.onPreviousPage
 * @return {StatelessComponent}
 */
const PagNavPreviousButton = ({ canPreviousPage, dataTestId, onPreviousPage }) => (
  <ButtonV2
    appearance={ButtonAppearance.BORDERLESS}
    dataTestId={dataTestId}
    intent={Intent.NEUTRAL}
    isDisabled={!canPreviousPage}
    leftIconColor={colors.colorGreyDarkHex}
    leftIconName={IconNames.CHEVRON_LEFT}
    onClick={onPreviousPage}
    size={ButtonSize.SMALL}
    type="button"
  />
);

PagNavPreviousButton.propTypes = {
  canPreviousPage: PropTypes.bool,
  onPreviousPage: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
};

PagNavPreviousButton.defaultProps = {
  canPreviousPage: undefined,
  dataTestId: undefined,
};

export default PagNavPreviousButton;

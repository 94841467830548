import { PaymentDeliveryOptionText } from 'constants/funding';
import { MembershipInvitesStatuses, MembershipMatchWarning } from 'constants/membership';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { SETTINGS_ACCOUNT_TEAM_MEMBERS_ROUTE } from 'constants/routes';

/**
 * Text constants for AboutNegativeBalanceHint.
 * @type {Object}
 */
export const aboutNegativeBalanceHint = {
  textAfterLink: 'about negative balances.',
  textBeforeLink:
    'A negative balance is likely caused by insufficient funds. Our records show you processed a payment and did not have enough money in your selected bank account.',
  title: 'About your negative balance',
};

/**
 * Text constants for AboutNegativeBalanceHint.
 * @type {Object}
 */
export const aboutPendingBalanceHintTitle = 'About your pending balance';

/**
 * Title for addenda record hint
 * @see {AddendaRecordMultipleItemsHint}
 * @type {string}
 */
export const addendaRecordMultipleItemsHintTitle = 'This addenda record will be added to all payments selected';

/**
 * Text constants for AmountTransactionMinimumHint.
 * @type {Object}
 */
export const amountTransactionMinimumHint = {
  learnMore: 'Learn more about transaction limits',
  title: "This transaction doesn't meet the minimum amount",
};

/**
 * Text constants for BalanceTooLowHintContent.
 * @type {Object}
 */
export const balanceTooLowHintContent = {
  primaryActionButton: `Switch to ${PaymentDeliveryOptionText.ACH_EXPEDITED}`,
  viewSettingsLink: 'View balance settings',
};

/**
 * Text constants for CannotInviteTeamMemberAlreadyUserHint.
 * @type {Object}
 */
export const cannotInviteTeamMemberAlreadyUserHint = {
  [MembershipMatchWarning[MembershipInvitesStatuses.ACCEPTED_AND_VERIFIED]]: {
    // TODO DEV-23: Add correct link
    linkPath: SETTINGS_ACCOUNT_TEAM_MEMBERS_ROUTE,
    linkText: 'View their team member account',
    textAfterLink: '.',
    textBeforeLink: 'Their account is currently active.',
  },
  [MembershipMatchWarning.INACTIVE]: {
    // TODO DEV-23: Add correct link
    linkPath: SETTINGS_ACCOUNT_TEAM_MEMBERS_ROUTE,
    linkText: 'managing their team member account',
    textAfterLink: '.',
    textBeforeLink: 'Their account is currently inactive. You can reactivate their account by',
  },
};

/**
 * Text constants for DeactivateTeamMemberApproverWarningHint
 * @type {Object}
 */
export const deactivateTeamMemberApproverWarningHint = {
  title: 'This team member is an approver',
  linkText: 'Contact support to re-assign pending approval requests.',
};

/**
 * Gets ItemsScheduledHint text
 * @return {string}
 */
export const itemsScheduledHintText = `You selected "Allow vendors to select payment method" for these payments. An email will be sent to your vendor to select their preferred payment method. It can take some time for vendors to fill out their payment information.`;

/**
 * Text constants for NotVerifiedForTransactionsHint.
 * @type {Object}
 */
export const notVerifiedForTransactionsHint = {
  verifyButton: 'Verify my account',
  text: `Prior to releasing payments, please verify your account by following the ${PLATFORM_DISPLAY_SHORT_NAME} Guide. There you will be able to verify your business entity and any representatives.`,
  title: 'Your account is not verified for transactions',
};

/**
 * Gets NumberOfErrorsShownHint text
 * @type {string}
 */
export const numberOfErrorsShownHintText = 'Download the error report below for a complete list of detected errors';

/**
 * Text constants for SendPaymentOnSpecificDateHint.
 * @type {Object}
 */
export const sendPaymentOnSpecificDateHint = {
  text: `A valid actionable contact is added (your actionable contacts for this vendor have all bounced or been archived) or a payment method is added (you cannot "allow your vendor to select their payment method" unless a valid contact is able to receive and act on the email).`,
};

/**
 * Text constants for CanManageContactHint.
 * @type {Object}
 */
export const canManageContactHint = {
  title: `A "Can Manage" contact will be sent all email notifications for this vendor and can take action on them (e.g., accept payments, communicate with Routable).`,
};

/**
 * Text constants for ReadOnlyContactHint.
 * @type {Object}
 */
export const readOnlyContactHint = {
  title: `A Read-Only contact can only view emails sent to the actionable contacts, and not interact with them.`,
};

/**
 * Text constants for BouncedEmailHint.
 * @type {Object}
 */
export const bouncedEmailHint = {
  text: 'In order for you to have this contact available to be included on emails sent you must provide a new, valid email address.',
};

/**
 * Text constants for LockedDomainHint.
 * @type {Object}
 */
export const lockedDomainHint = {
  title: 'This domain [routable.com] is locked.',
  text: 'A locked domain indicates that this user is a Routable user, and self manages their contacts. For this reason you cannot add any additional contacts.',
};

/**
 * Text constants for EmailAlreadyInvitedHint.
 * @type {Object}
 */
export const emailAlreadyInvitedHint = {
  textBeforeLink: 'You can resend or revoke their invite by',
  linkText: 'managing their invites.',
};

/**
 * Text constants for VendorInviteOptionsHint.
 * @type {Object}
 */
export const vendorInviteOptionsHint = {
  title: 'Vendor invite options require a contact.',
  text: 'To invite your vendor to select their own payment method and/or collect their tax information, please add a contact.',
};

/**
 * Text constants for UpdateContactLegacyDataHint.
 * @type {Object}
 */
export const updateContactLegacyDataHint = {
  title: `By updating this contact's name it will also change it for all legacy data.`,
  text: 'Legacy data would include all indications of this contact in your payment details, and communications with this contact. We would only suggest changing this in the case where the contact is the same, but there was either a typo in their name, or it has actually changed (like in the case of marriage). In all other cases, we suggest you create a new contact instead.',
};

/**
 * Text constants for EmailAssociatedWithOtherCompanies.
 * @type {Object}
 */
export const emailAssociatedWithOtherCompaniesHint = {
  title: 'This email is associated with at least one other company:',
  text: 'Confirm this contact should be associated with multiple companies',
};

/**
 * Text constants for InvalidLoginCredentialsHint.
 * @type {Object}
 */
export const invalidLoginCredentialsHint = {
  title: 'Invalid login credentials',
  text: 'The account details you entered did not match our records. Please double-check and try again.',
};

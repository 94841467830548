import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const LabelBody = ({ children, className }) => {
  const labelBodyClassNames = classNames({
    body: true,
    [className]: !!className,
  });

  return <div className={labelBodyClassNames}>{children}</div>;
};

LabelBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

LabelBody.defaultProps = {
  className: '',
};

export default LabelBody;

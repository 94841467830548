import tablematic from '@routable/tablematic';

import { isKindPayable, isKindReceivable } from 'helpers/items';

import { storeAccessor } from 'store/accessor';

import { tableUi } from './constants';

/**
 * Given the loading state and dynamic schema value for a table, returns an instance of SchemaParser
 * for that table. If the data is loading, returns null.
 * @param {Object} options
 * @param {boolean} options.isLoading
 * @param {Object[]} options.schema
 * @returns {SchemaParser|null}
 */
export const getTableSchemaParser = ({ isLoading, schema }) => {
  if (isLoading) {
    return null;
  }

  return new tablematic.SchemaParser(schema, storeAccessor, tableUi);
};

/**
 * Given an instance of SchemaParser for a table, returns an instance of SchemaViewModelManager
 * for that table.
 * @param {SchemaParser} parser
 * @returns {SchemaViewModelManager|null}
 */
export const getTableViewModelManager = (parser) => {
  if (!parser) {
    return null;
  }

  return new tablematic.SchemaViewModelManager(parser);
};

/**
 * Given an item kind, returns the view model manager for that kind's table schema.
 * @param {OptionsArg} options
 * @param {ItemKind} options.itemKind
 * @param {SchemaParser|null} options.receivableParser
 * @param {SchemaParser|null} options.payableParser
 * @returns {SchemaParser|null}
 */
export const getTableSchemaParserForItemKind = ({ itemKind, receivableParser, payableParser }) => {
  if (isKindPayable(itemKind)) {
    return payableParser;
  }

  if (isKindReceivable(itemKind)) {
    return receivableParser;
  }

  return null;
};

/**
 * Given an item kind, returns the view model manager for that kind's table schema.
 * @param {OptionsArg} options
 * @param {ItemKind} options.itemKind
 * @param {SchemaViewModelManager|null} options.receivableManager
 * @param {SchemaViewModelManager|null} options.payableManager
 * @returns {SchemaViewModelManager|null}
 */
export const getTableViewModelManagerForItemKind = ({ itemKind, receivableManager, payableManager }) => {
  if (isKindPayable(itemKind)) {
    return payableManager;
  }

  if (isKindReceivable(itemKind)) {
    return receivableManager;
  }

  return null;
};

/**
 * Given an item kind, returns the view model manager for that kind's table schema.
 * @param {OptionsArg} options
 * @param {ReduxFormValues} options.formValues
 * @param {SchemaViewModelManager|null} options.viewModelManager
 * @returns {SchemaViewModelManager|null}
 */
export const getConstrainedMetaForItemKind = ({ formValues, viewModelManager }) => {
  if (viewModelManager) {
    return viewModelManager.getConstrainedMeta({ values: formValues });
  }

  return {};
};

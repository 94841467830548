import { webhooksFetchRoutine } from 'actions/routines/webhooks';
import { tagLinkRelationships as relationships } from 'constants/relationships';
import { createByIdReducer } from 'store/redux';
const byIdReducer = createByIdReducer({
    key: 'webhook',
    types: [webhooksFetchRoutine.SUCCESS],
    relationships,
    replaceState: true,
});
export default byIdReducer;

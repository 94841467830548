import { connect } from 'react-redux';

import { closeAddPartnerFundingAccountOptions, openAddPartnerFundingAccountOptions } from 'actions/ui';

import { partnerFundingAccountFormFields } from 'constants/formFields';
import { sidePanelNameAddPartnerFundingAccount } from 'constants/sidePanels';

import { createFormSingleValueSelector } from 'selectors/createFormSelectors';
import { currentCompanySettingsIsInternationalPaymentsEnabledSelector } from 'selectors/currentCompanySelectors';
import { selectedCurrencyCodeDashboardFieldSelector } from 'selectors/forms';
import { fundingAccountsByIdSelector, fundingInfoAddressesByIdSelector } from 'selectors/fundingSelectors';
import { partnershipFundingAccountsForPartnershipIdSelector } from 'selectors/partnershipsSelectors';
import { createSidePanelSelector } from 'selectors/sidePanelsSelector';
import { isAddPartnerFundingAccountOptionsOpenSelector } from 'selectors/uiSelectors';

import AddPartnerFundingAccountOptions from './AddPartnerFundingAccountOptions';

export const mapStateToProps = (state, ownProps) => {
  const formValueSelector = createFormSingleValueSelector(ownProps.formName);
  const sidePanelSelector = createSidePanelSelector(sidePanelNameAddPartnerFundingAccount);
  const partnershipFundingAccounts = partnershipFundingAccountsForPartnershipIdSelector(
    state,
    ownProps.partnership?.id,
  );

  const { partnership, paymentDeliveryMethod } = ownProps;

  return {
    fundingAccountsById: fundingAccountsByIdSelector(state),
    fundingInfoAddressesById: fundingInfoAddressesByIdSelector(state),
    isAdvancedOptionsOpen: isAddPartnerFundingAccountOptionsOpenSelector(state),
    isInternationalPaymentsEnabled: currentCompanySettingsIsInternationalPaymentsEnabledSelector(state),
    partnership,
    partnershipFundingAccounts,
    paymentDeliveryMethod,
    selectedCurrencyCode: selectedCurrencyCodeDashboardFieldSelector(state),
    shouldDisableSetPrimary: formValueSelector(state, partnerFundingAccountFormFields.SHOULD_DISABLE_SET_PRIMARY),
    shouldShowReplaceFundingAccounts: formValueSelector(
      state,
      partnerFundingAccountFormFields.SHOULD_SHOW_REPLACE_FUNDING_ACCOUNTS,
    ),
    shouldShowSetAsPrimaryToggle: formValueSelector(
      state,
      partnerFundingAccountFormFields.SHOULD_SHOW_SET_AS_PRIMARY_TOGGLE,
    ),
    sidePanel: sidePanelSelector(state),
  };
};

export const mapDispatchToProps = {
  onCloseAdvancedOptions: closeAddPartnerFundingAccountOptions,
  onOpenAdvancedOptions: openAddPartnerFundingAccountOptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPartnerFundingAccountOptions);

import { combineReducers } from 'redux';

import allCompaniesReducer from './allCompaniesReducer';
import companiesByIdReducer from './companiesByIdReducer';
import companyInfoByIdReducer from './companyInfoByIdReducer';
import errorReducer from './errorReducer';
import lastUpdatedReducer from './lastUpdatedReducer';
import updateReducer from './updateReducer';

const companiesReducer = combineReducers({
  allIds: allCompaniesReducer,
  byId: companiesByIdReducer,
  errors: errorReducer,
  infoById: companyInfoByIdReducer,
  isUpdating: updateReducer,
  lastUpdated: lastUpdatedReducer,
});

export default companiesReducer;

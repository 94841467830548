import React from 'react';

import { ConfirmationSectionHeader } from 'components/confirmation';
import { Instruction } from 'components/instruction';

const ConnectBankManualConfirmation = () => (
  <React.Fragment>
    <ConfirmationSectionHeader />

    <p className="font-regular semibold font-color--dark-jordan">
      Your bank account is awaiting verification. Here are next steps:
    </p>

    <div className="next-steps margin-top--large">
      <Instruction
        description="We have made two small deposits to your bank account. These would be reflected in your bank statement in 1-2 business days."
        num="1"
      />
      <Instruction
        description="Before payments can be initiated from this account we will need you to verify the amounts that were credited."
        num="2"
      />
      <Instruction
        description="If you don't see two small credits in 3 business days, please contact support and we'll help get this issue resolved."
        num="3"
      />
    </div>
  </React.Fragment>
);

export default ConnectBankManualConfirmation;

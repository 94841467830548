import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { DisclosureListClassNames } from 'components/disclosureList/constants';
import Icon, { IconNames } from 'components/icon';

const DisclosureControl = ({ className, icon, isOpen, style }) => (
  <Icon
    className={classNames({
      [DisclosureListClassNames.CONTROL]: true,
      open: isOpen,
      [className]: !!className,
    })}
    icon={icon}
    style={style}
  />
);

DisclosureControl.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  style: PropTypes.shape(),
};

DisclosureControl.defaultProps = {
  className: undefined,
  icon: IconNames.CHEVRON_DOWN,
  style: {},
};

export default DisclosureControl;

export const getGridColClass = (cols) => {
  if (!cols) {
    return undefined;
  }

  switch (cols) {
    case 1:
      return 'stack-all';
    case 2:
      return 'halves';
    case 3:
      return 'thirds';
    case 4:
      return 'fourths';
    case 5:
      return 'fifths';
    case 6:
      return 'sixths';
    case 8:
      return 'eighths';
    case 10:
      return 'tenths';
    default:
      return undefined;
  }
};

export const getUtilityClassesConfig = (flexProps = {}, namespace = undefined) => {
  const config = {};

  const flexEntries = Object.entries(flexProps);

  flexEntries.forEach(([key, value]) => {
    if (value !== undefined) {
      let className = namespace ? `${namespace}--` : '';

      for (let i = 0, len = key.length; i < len; i += 1) {
        const char = key[i];

        if (char === char.toUpperCase()) {
          className = `${className}-${char.toLowerCase()}`;
        } else {
          className = `${className}${char}`;
        }
      }

      className = `${className}--${value}`;

      config[className] = true;
    }
  });

  return config;
};

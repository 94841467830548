import PropTypes from 'prop-types';
import React from 'react';

import Icon, { IconNames } from 'components/icon';

import { colors, sizes } from 'constants/styles';

const NoSearchTerm = ({ hasIcon, searchTerm }) => (
  <>
    {hasIcon && (
      <Icon
        className="margin-right--xm"
        color={colors.colorBlueBoldHex}
        icon={IconNames.SEARCH}
        size={sizes.iconSizes.MEDIUM}
      />
    )}
    {searchTerm}
  </>
);

NoSearchTerm.propTypes = {
  hasIcon: PropTypes.bool,
  searchTerm: PropTypes.string,
};

NoSearchTerm.defaultProps = {
  hasIcon: true,
  searchTerm: 'Type to search',
};

export default NoSearchTerm;

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

import { ItemLedgerStatuses } from 'enums/items';

import { isItemKindPayable, isReceivableCreatorPartnerCompany } from 'helpers/items';
import { isLedgerApplicationTypeQBO } from 'helpers/ledger';

/**
 * Method to get the action string for the pay/accept button
 * @param item
 * @return {string}
 */
export const getItemActionButtonText = (item) => {
  if (isReceivableCreatorPartnerCompany(item)) {
    return 'Accept payment';
  }

  return 'Make payment';
};

/**
 * Returns item ledger display statuses that are common between ledger types
 * @param ledger
 * @return {*}
 */
const getDefaultItemLedgerDisplayStatus = (ledger) => ({
  [ItemLedgerStatuses.CANCELED]: 'Voided',
  [ItemLedgerStatuses.DELETED]: `Deleted on ${ledger.name}`,
  [ItemLedgerStatuses.DRAFT]: `Unsupported by ${PLATFORM_DISPLAY_SHORT_NAME}`,
  [ItemLedgerStatuses.NEEDS_APPROVAL]: `Unsupported by ${PLATFORM_DISPLAY_SHORT_NAME}`,
  [ItemLedgerStatuses.PENDING]: 'Awaiting Payment',
  [ItemLedgerStatuses.PAID]: 'Paid',
});

/**
 * Returns item ledger display statuses that are specific to QBO
 * @param status
 * @param ledger
 * @param isPayable
 * @return {*}
 */
const getItemLedgerDisplayStatusQBO = (status, ledger, isPayable = false) =>
  ({
    ...getDefaultItemLedgerDisplayStatus(ledger),
    [ItemLedgerStatuses.PENDING]: isPayable ? 'Balance Due' : 'Open',
  }[status]);

/**
 * Translate the item ledgerStatus into something human-readable
 * @param item
 * @param ledger
 * @return {*}
 */
export const getItemLedgerDisplayStatus = (item, ledger) => {
  if (!item || !ledger) {
    return undefined;
  }

  const { ledgerStatus } = item;

  if (!ledgerStatus) {
    return undefined;
  }

  const isPayable = isItemKindPayable(item);

  if (isLedgerApplicationTypeQBO(ledger)) {
    return getItemLedgerDisplayStatusQBO(ledgerStatus, ledger, isPayable);
  }

  return getDefaultItemLedgerDisplayStatus(ledger)[ledgerStatus];
};

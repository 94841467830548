import PropTypes from 'prop-types';
import React from 'react';

import { FlexRow, Icon, Text } from 'components';

import { sizes } from 'constants/styles';

import { getPermissionDisclosureListColor, getPermissionDisclosureListRowIconProps } from './helpers';

/**
 * A row in a DisclosureList body which shows whether a granular permission is off or on.
 *
 * @param {ComponentProps} props
 * @param {boolean} props.enabled - is this permission on/off?
 * @param {string} props.resourceActionText - RCTM facing translation of Permission.resourceAction
 * @returns {StatelessComponent}
 */
const PermissionDisclosureListRow = ({ enabled, resourceActionText }) => (
  <FlexRow className="align-items--center" stackOnMobile={false}>
    <Icon
      marginRight={sizes.spacing.SMALL}
      size={sizes.iconSizes.LARGE}
      {...getPermissionDisclosureListRowIconProps(enabled)}
    />
    <Text.Semibold color={getPermissionDisclosureListColor(enabled)}>{resourceActionText}</Text.Semibold>
  </FlexRow>
);

PermissionDisclosureListRow.propTypes = {
  enabled: PropTypes.bool,
  resourceActionText: PropTypes.string.isRequired,
};

PermissionDisclosureListRow.defaultProps = {
  enabled: undefined,
};

export default PermissionDisclosureListRow;

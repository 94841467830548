import { deactivateTeamMemberModalRoutine } from 'actions/routines/modal';

import { createErrorReducer } from 'store/redux';

export const failureCases = [deactivateTeamMemberModalRoutine.FAILURE];
export const successCases = [
  deactivateTeamMemberModalRoutine.REQUEST,
  deactivateTeamMemberModalRoutine.SUCCESS,
  deactivateTeamMemberModalRoutine.TRIGGER,
];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

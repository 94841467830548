import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchFundingAccountsRequest } from 'actions/funding';
import { PROCESSING_HERO } from 'constants/images';
import * as modalNames from 'constants/modals';
import BankAccountSelector from 'modules/connectBank/bankAccountSelector';
import { VerifyFundingAccountFormModal } from 'modules/connectBank/verifyFundingAccount/components';
import { modalsSelector } from 'selectors/modalsSelector';
import { fundingAccountsWhereBankForCurrentCompanyCountSelector } from '../../../queries/fundingCompoundSelectors';
import BankConnections from '../../dashboard/settings/account/bankConnections/presenters/BankConnections';
import { Container, FormCenter, FormContainer, FormColumn, Heading, ImageContainer, LeadText, SubHeading, } from './Processing.styles';
const Processing = () => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(fetchFundingAccountsRequest());
    }, [dispatch]);
    const numberOfAccounts = useSelector(fundingAccountsWhereBankForCurrentCompanyCountSelector);
    const modals = useSelector(modalsSelector);
    return (React.createElement(Container, null,
        React.createElement(ImageContainer, null,
            React.createElement("img", { alt: "Processing", src: PROCESSING_HERO })),
        React.createElement(FormContainer, null,
            React.createElement(FormCenter, null,
                React.createElement(FormColumn, null,
                    React.createElement(Heading, null, "We\u2018re processing your information now"),
                    React.createElement(SubHeading, null, "You can expect an email from us in the next 1-2 business days.")),
                React.createElement(FormColumn, null, numberOfAccounts === 0 ? (React.createElement(React.Fragment, null,
                    React.createElement(LeadText, null, "Get a jump start by adding your bank account information now."),
                    React.createElement(BankAccountSelector, null))) : (React.createElement(React.Fragment, null,
                    React.createElement(LeadText, null, "Your bank account"),
                    React.createElement(BankConnections, { hideTab: true })))),
                modals[modalNames.modalNameVerifyFundingAccount]?.open && React.createElement(VerifyFundingAccountFormModal, null)))));
};
export default Processing;

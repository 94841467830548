import PropTypes from 'prop-types';
import React from 'react';

import { baseDefaultProps, basePropTypes } from 'components/commonProps';

import { tagTypeProp } from 'helpers/propTypes';
import { getClassNames } from 'helpers/ui';

import BaseOption from '../BaseOption';

import { OptionTag } from './components';

/**
 * Option component for react-select that displays a label and a tag.
 * @param {ComponentProps} props
 * @param {Object} props.data
 * @param {string} props.data.tagType
 * @param {string} props.label
 * @return {StatelessComponent}
 * @constructor
 */
const TaggedOption = (props) => {
  const { data } = props;

  const { __isNew__: isNewOption, optionTagText, optionTagType } = data;

  return (
    <BaseOption stackContentOnMobile={false} {...props} className={getClassNames(props, { 'tagged-option': true })}>
      {!isNewOption && <OptionTag type={optionTagType}>{optionTagText}</OptionTag>}
    </BaseOption>
  );
};

TaggedOption.propTypes = {
  ...basePropTypes,
  data: PropTypes.shape({
    // react-select's indicator of whether this is the "create" option
    __isNew__: PropTypes.bool,
    optionTagText: PropTypes.string.isRequired,
    optionTagType: tagTypeProp.isRequired,
  }).isRequired,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

TaggedOption.defaultProps = {
  ...baseDefaultProps,
  isDisabled: undefined,
};

export default TaggedOption;

import { allKeys } from 'helpers/utility';

import {
  DELETE_EMAIL_FORWARD_SUCCESS,
  FETCH_NOTIFICATION_SETTINGS_SUCCESS,
  SUBMIT_EMAIL_FORWARD_SUCCESS,
} from 'types/notifications';

export const allIdsReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_NOTIFICATION_SETTINGS_SUCCESS:
    case SUBMIT_EMAIL_FORWARD_SUCCESS:
      return Array.from(new Set([...state, ...allKeys(action.payload.notificationSettingsEmailForward)]));

    case DELETE_EMAIL_FORWARD_SUCCESS:
      return state.filter((id) => id !== action.payload.id);

    default:
      return state;
  }
};

export default allIdsReducer;

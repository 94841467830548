import { createSelector } from 'reselect';

/**
 * Selects an id
 * !NOTE: We return `id ?? props` here because previously this function only accepted 2 arguments (_state and id), and
 * returned the second argument. This caused problems, because 1) selectors will need both state and props to
 * recompute properly, and the id argument is often a constant value, and 2) other selectors will require the
 * props argument to access necessary values (like any selectors using props.location).
 * @param {ReduxState} _state
 * @param {ComponentProps|string} props
 * @param {string|undefined} [id]
 */
export const idSelector = (_state, props, id) => id ?? props;

/**
 * Selects the props argument
 * @param {object} _state - Redux state (not used)
 * @param {string|string[]|*} props - id
 */
export const propsSelector = (_state, props) => props;

/**
 * Selects the props argument
 * @param {string} propName
 * @returns {StandardSelector}
 */
export const createPropSelector = (propName) => createSelector([propsSelector], (props) => props?.[propName]);

/**
 * Selects all of state
 * @param {object} state
 * @return {object}
 */
export const allStateSelector = (state) => state;

/**
 * Selects options for compound selectors
 * @param {object} _state - Redux state (not used)
 * @param {*} options - an array of extra options to be supplied to the selector
 * @return {string}
 */
export const optionsSelector = (_state, ...options) => options;

/**
 * Get rowData from the props.
 * @type {StandardSelector}
 * @param {ReduxState} state - not actually used in this selector
 * @param {ComponentProps} props
 * @param {ObjectMaybe} props.rowData
 * @returns {ObjectMaybe} rowData
 */
export const rowDataSelector = createSelector([propsSelector], (props) => props?.rowData);

/**
 * Helper used to create a selector when the one of the input selectors may be decided by some outside logic.
 *
 * @see {itemFormCombinedInitialValuesSelector}
 * @param {StandardSelector} logicSelector
 * @returns {*}
 */
export const createConditionalLogicSelector = (logicSelector) =>
  createSelector([logicSelector, allStateSelector, propsSelector], (selector, state, ownProps) =>
    selector(state, ownProps),
  );

import React from 'react';
import { allKeys } from 'helpers/utility';
import { sideSheetsMap } from './data';
import {} from './ExternalSideSheets.types';
const ExternalSideSheets = ({ sideSheets }) => (React.createElement(React.Fragment, null, allKeys(sideSheets).map((sideSheetName) => {
    const sideSheet = sideSheets[sideSheetName];
    if (!sideSheet.open) {
        return null;
    }
    const Component = sideSheetsMap[sideSheetName];
    return React.createElement(Component, { key: sideSheetName });
})));
export default ExternalSideSheets;

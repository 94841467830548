import { getModalReducerOpenAction } from 'store/redux';

import * as types from 'types/maintenance';

export const initialState = {
  open: false,
};

const modalMaintenanceModeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MODAL_MAINTENANCE_MODE_CLOSE:
      return initialState;

    case types.MODAL_MAINTENANCE_MODE_OPEN:
      return getModalReducerOpenAction(state, action);

    default:
      return state;
  }
};

export default modalMaintenanceModeReducer;

import PropTypes from 'prop-types';
import React from 'react';

import { ExternalFlowHeaderLogo } from './components';

import './ExternalFlowHeader.scss';

/**
 * Renders external flow header with company logo and (optionally) additional header
 * items
 * @param {ComponentProps} props
 * @param {Brand} props.brand
 * @param {Company} props.company
 * @param {NodeMaybe} props.headerDueDate
 * @param {NodeMaybe} props.headerMenu
 * @return {StatelessComponent}
 */
const ExternalFlowHeader = ({ brand, company, headerDueDate, headerMenu }) => (
  <header className="external__header">
    <div className="external__header--company-logo">
      <ExternalFlowHeaderLogo brand={brand} company={company} />
    </div>

    <div className="external__header--items">
      {headerDueDate}
      {headerMenu}
    </div>
  </header>
);

ExternalFlowHeader.propTypes = {
  brand: PropTypes.shape({}).isRequired,
  company: PropTypes.shape({}).isRequired,
  headerMenu: PropTypes.node,
  headerDueDate: PropTypes.node,
};

ExternalFlowHeader.defaultProps = {
  headerMenu: undefined,
  headerDueDate: undefined,
};

export default ExternalFlowHeader;

import { submitInvoicesRoutine } from 'actions/routines/item';

import { createErrorReducer } from 'store/redux';

import * as types from 'types/item';

export const failureCases = [submitInvoicesRoutine.FAILURE, types.FETCH_INVOICES_FAILURE];

export const successCases = [
  submitInvoicesRoutine.REQUEST,
  submitInvoicesRoutine.SUCCESS,
  types.CLEAR_INVOICES,
  types.FETCH_ADDITIONAL_INVOICES_SUCCESS,
  types.FETCH_INVOICES_SUCCESS,
];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

import { createErrorReducer } from 'store/redux';

import { FETCH_PARTNERSHIP_FAILURE, FETCH_PARTNERSHIP_ITEMS_SUCCESS } from 'types/partnership';

/**
 * Action types representing success cases
 * @constant
 * @type {string[]}
 */
const successCases = [FETCH_PARTNERSHIP_ITEMS_SUCCESS];

/**
 * Action types representing failure cases
 * @constant
 * @type {string[]}
 */
const failureCases = [FETCH_PARTNERSHIP_FAILURE];

/**
 * Partnership Items Error
 *
 * @type {ReduxReducer}
 */
const partnershipItemsErrorReducer = createErrorReducer(successCases, failureCases);

export { failureCases, successCases };
export default partnershipItemsErrorReducer;

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getQueryParam } from 'helpers/queryParams';

import { getDepartmentByPath } from 'modules/external/helpers';

import { itemSelector } from 'selectors/itemsSelectors';
import { partnershipSelector } from 'selectors/partnershipsSelectors';

const ExternalDeptAndItemId = ({ item, partnership }) => (
  <div className="item-id--container">
    <span>{`${partnership.name} ${getDepartmentByPath()}`}</span>
    {item && <span className="item-id">[{item.id.slice(0, 4)}]</span>}
  </div>
);

ExternalDeptAndItemId.propTypes = {
  item: PropTypes.shape(),
  partnership: PropTypes.shape(),
};

ExternalDeptAndItemId.defaultProps = {
  item: undefined,
  partnership: { name: 'Company' },
};

const mapStateToProps = (state) => {
  const itemId = getQueryParam('item_id');
  const partnershipId = getQueryParam('partnership_id');

  return {
    item: itemSelector(state, itemId),
    partnership: partnershipSelector(state, partnershipId),
  };
};

export { ExternalDeptAndItemId };
export default connect(mapStateToProps)(ExternalDeptAndItemId);

import { PAYMENTS_LIST_TABS } from 'constants/routes';

export const STORAGE_KEY_PREFIX = 'routable_';

export const INITIAL_ROUTE_KEY = `${STORAGE_KEY_PREFIX}initialRoute`;

export const INITIAL_LOAD_BILLS_OR_INVOICES_BY_TAB = {
  [PAYMENTS_LIST_TABS.PAYABLES]: `${STORAGE_KEY_PREFIX}initialLoadBills`,
  [PAYMENTS_LIST_TABS.RECEIVABLES]: `${STORAGE_KEY_PREFIX}initialLoadInvoices`,
};

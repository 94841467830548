import { RoutableEventsSync } from '@routable/framework';
import classNames from 'classnames';
import debounce from 'es6-promise-debounce';
import PropTypes from 'prop-types';
import React from 'react';

import { ArrowRenderer, FormControl } from 'components/form';
import { AsyncSelect, selectComponents } from 'components/selectV2';

import { isMobileOS } from 'helpers/browser';
import { getFieldErrors } from 'helpers/errors';
import { createPartnershipSelectName, getVendorOrCustomerTitleFromItemKind } from 'helpers/partnerships';
import { isSelectedPartnerTypeNew } from 'helpers/searchCompanies';
import { returnFullOptionObjectAsValueForSelects } from 'helpers/selects';

import { MultiLineOptionWithTooltipForSearchCompanies } from 'modules/selects/components';
import { checkPartnershipEligibilityForSearchCompanies } from 'modules/selects/helpers/logic';

import { componentHelpers, textHelpers } from './helpers';

import './SearchCompanies.scss';

class SearchCompanies extends React.Component {
  searchDebouncer = debounce(componentHelpers.getPartnershipSearchOptions, 300);

  searchPartnerships = async (input) => {
    const {
      formName,
      partnershipType,
      item: { kind },
    } = this.props;
    const suffix = kind || partnershipType;

    const options = await this.searchDebouncer(input, formName, suffix);

    const { companyIntegrationSettings, companySettings, ledger, ledgerCurrencies, location, supportedCurrencies } =
      this.props;

    return options.map((option) => {
      const { isDisabled } = checkPartnershipEligibilityForSearchCompanies({
        companyIntegrationSettings,
        companySettings,
        formPartnershipType: partnershipType,
        ledger,
        ledgerCurrencies,
        location,
        partnership: option,
        supportedCurrencies,
      });

      return {
        ...option,
        isDisabled,
      };
    });
  };

  handleSelectionChange = (value) => {
    RoutableEventsSync.Publish('change:partnership');
    componentHelpers.handleSelectCompanyChange(value, this.props, {
      onReset: componentHelpers.handleResetForm,
      onNewPartnerCompany: componentHelpers.handleNewPartnerCompany,
    });
  };

  componentDidMount() {
    const { onLoad } = this.props;

    if (onLoad) {
      onLoad();
    }
  }

  render() {
    const { defaultOptions, errors, formUI, item, label, partnershipType } = this.props;
    const { isItemEdit, selectedCompany } = Object(formUI);
    const { kind } = Object(item);

    // eslint-disable-next-line no-underscore-dangle
    const isNewCompany = selectedCompany?.__isNew__;
    const isCreatedCompany = selectedCompany?.id && !isNewCompany;

    const kindTitle = partnershipType || getVendorOrCustomerTitleFromItemKind(kind);

    const getFieldLabel = () => {
      if (label) {
        return label;
      }
      if (isNewCompany || isCreatedCompany) {
        return 'Routable display name';
      }
      return undefined;
    };

    const fieldLabel = getFieldLabel();
    const companyNameErrors = getFieldErrors(errors, 'companyName');
    const selectAsyncClasses = classNames({
      error: !!companyNameErrors,
      'search-companies--select': true,
      'search-companies--no-label': !fieldLabel,
    });

    return (
      <FormControl>
        <div className="w-full">
          <AsyncSelect
            autoFocus={!selectedCompany}
            blurInputOnSelect={!isMobileOS()}
            className={selectAsyncClasses}
            clearIndicatorAlertMessage={textHelpers.getClearCompanyConfirmationText(kindTitle)}
            clearIndicatorShouldConfirm={isSelectedPartnerTypeNew(selectedCompany?.type)}
            components={{
              DropdownIndicator: ArrowRenderer,
              ClearIndicator: selectComponents.ClearIndicatorWithConfirm,
              Option: MultiLineOptionWithTooltipForSearchCompanies,
            }}
            dataFullStory
            defaultOptions={defaultOptions}
            errors={errors}
            filterConfig={{ ignoreCase: true }}
            getCreateLabel={(input) => `Create a new ${kindTitle} "${input}"`}
            getIsValidNewOption={(input) => input?.length > 0}
            getNewOptData={(inputVal, optLabel) => ({
              // match react-select's new-option indicator
              __isNew__: true,
              companyName: inputVal,
              id: inputVal,
              label: optLabel,
            })}
            getOptionLabel={(opt) => createPartnershipSelectName(opt) || opt.label}
            getOptionValue={(opt) => opt.id}
            idPrefix="asyncSelect-searchCo-"
            input={{
              name: 'asyncSelect-searchCo',
              onChange: this.handleSelectionChange,
              value: selectedCompany || null,
            }}
            isCreatable
            isDisabled={isItemEdit}
            isMulti={false}
            label={fieldLabel}
            loadOptions={this.searchPartnerships}
            parseValue={returnFullOptionObjectAsValueForSelects}
            placeholder={`Search for a ${kindTitle} or create a new one*`}
            valueKey="id"
          />
        </div>
      </FormControl>
    );
  }
}

SearchCompanies.propTypes = {
  defaultOptions: PropTypes.arrayOf(PropTypes.shape({})),
  errors: PropTypes.shape(),
  // formName used in componentHelpers
  // eslint-disable-next-line react/no-unused-prop-types
  formName: PropTypes.string.isRequired,
  formUI: PropTypes.shape({
    selectedCompany: PropTypes.shape(),
  }).isRequired,
  // handleChangePartnerCompany used in componentHelpers
  // eslint-disable-next-line react/no-unused-prop-types
  handleChangePartnerCompany: PropTypes.func.isRequired,
  item: PropTypes.shape(),
  label: PropTypes.string,
  ledger: PropTypes.shape({
    id: PropTypes.string,
  }),
  // partner used in componentHelpers
  // eslint-disable-next-line react/no-unused-prop-types
  partner: PropTypes.shape().isRequired,
  partnershipType: PropTypes.string,
  onLoad: PropTypes.func,
};

SearchCompanies.defaultProps = {
  defaultOptions: undefined,
  errors: {},
  item: {
    kind: undefined,
  },
  label: undefined,
  ledger: undefined,
  onLoad: undefined,
  partnershipType: undefined,
};

export default SearchCompanies;

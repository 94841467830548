import ThunkFetch from 'helpers/thunkFetch';

import { createFundingAccountLinkTokenEndpoint } from 'services/api/fundingEndpoints';

import * as types from 'types/connectBank';

// TODO DEV-1096: Refactor this thunk into a saga
/**
 * create funding account link token thunk
 * @param {Function} successCallback
 * @param {Function} errorCallback
 * @return {DispatchThunk} Thunk resolving to errors or false-y.
 */
export const createFundingAccountLinkToken = (successCallback, errorCallback) => {
  const t = new ThunkFetch({
    actions: {
      error: types.CREATE_FUNDING_ACCOUNT_LINK_TOKEN_FAILURE,
      request: types.CREATE_FUNDING_ACCOUNT_LINK_TOKEN_REQUEST,
      success: types.CREATE_FUNDING_ACCOUNT_LINK_TOKEN_SUCCESS,
    },
    endpoint: createFundingAccountLinkTokenEndpoint,
    method: 'GET',
    successCallback,
    errorCallback,
    requireAuth: true,
  });

  return t.makeRequest();
};

export default createFundingAccountLinkToken;

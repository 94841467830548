import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { WhiteSpace } from 'components/text';

import { getCurrentUrlPath } from 'helpers/urls';

import { ActionNavTabBase } from './components';
import { ui } from './helpers';

/**
 * A clickable tab to be included in the ActionNavTabList.
 * @param {ComponentProps} props
 * @param {string[]} [props.alternatePaths=[]]
 * @param {string} [props.className]
 * @param {function} [props.getIsSelected]
 * @param {AnyComponent[]} props.LinkComponent=Link
 * @param {number} [props.numberOfItems]
 * @param {string} [props.tabIconClass]
 * @param {string} props.tabLink - If the tab is clicked, navigate here.
 * @param {string} props.tabText
 * @returns {StatelessComponent}
 */
const ActionNavTab = (props) => {
  const { alternatePaths, getIsSelected, numberOfItems, tabIconClass, tabLink, tabText, ...rest } = props;

  const location = useLocation();
  const pathname = getCurrentUrlPath();

  return (
    <ActionNavTabBase
      {...rest}
      Component={Link}
      isSelected={ui.handleIsSelected({
        alternatePaths,
        getIsSelected,
        pathname,
        tabLink,
      })}
      tabIconClass={tabIconClass}
      to={{
        pathname: tabLink,
        state: location.state,
      }}
    >
      {tabText}
      <WhiteSpace />
      {ui.getFormattedNumberOfItems(numberOfItems)}
    </ActionNavTabBase>
  );
};

ActionNavTab.propTypes = {
  alternatePaths: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  getIsSelected: PropTypes.func,
  numberOfItems: PropTypes.number,
  tabIconClass: PropTypes.string,
  tabLink: PropTypes.string.isRequired,
  tabText: PropTypes.string.isRequired,
};

ActionNavTab.defaultProps = {
  alternatePaths: [],
  className: undefined,
  getIsSelected: undefined,
  numberOfItems: undefined,
  tabIconClass: undefined,
};

export default ActionNavTab;

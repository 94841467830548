import { useDispatchEventSync, useEventSync } from '@routable/framework';
import { useCallback, useContext } from 'react';
import {} from '../table.extended.types';
import { TableContext } from '../table-name.context';
export const useTableEvent = ({ tableName, event, fn }) => {
    const contextName = useContext(TableContext).tableName;
    return useEventSync(`table:${tableName || contextName}:${event}`, fn);
};
export const useDispatchTableEvent = ({ event, tableName }) => {
    const contextName = useContext(TableContext).tableName;
    const eventName = `table:${tableName || contextName}:${event}`;
    const dispatch = useDispatchEventSync();
    return useCallback((data) => dispatch(eventName, data), [eventName]);
};

import { combineReducers } from 'redux';

import allInfoReducer from './allInfoReducer';
import errorReducer from './errorReducer';
import infoByIdReducer from './infoByIdReducer';
import isFetchingReducer from './isFetchingReducer';
import isSubmittingReducer from './isSubmittingReducer';
import metaReducer from './metaReducer';
import submitSucceededReducer from './submitSucceededReducer';

export const taxesInfoReducer = combineReducers({
  allIds: allInfoReducer,
  byId: infoByIdReducer,
  errors: errorReducer,
  isFetching: isFetchingReducer,
  isSubmitting: isSubmittingReducer,
  meta: metaReducer,
  submitSucceeded: submitSucceededReducer,
});

export default taxesInfoReducer;

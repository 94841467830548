import { Clickable, Hint } from '@routable/components';
import React from 'react';
import { Link } from 'react-router-dom';
export const RiskChecksIssuesFoundHint = ({ hasPermissions, hasTinMismatch, holdPayables, onContactSupport, onManuallyMoveVendorToVerified, partnershipName, taxDocsPath, }) => {
    const title = holdPayables
        ? `Payments will fail to initiate to this vendor until ${hasTinMismatch ? 'TIN mismatch is resolved' : 'watch list hits have been reviewed and resolved'}.`
        : `${hasTinMismatch ? 'TIN mismatch' : 'Watchlist hits'} detected for ${partnershipName}`;
    return (React.createElement(Hint, { title: title, type: "critical" },
        React.createElement("span", null, "There's a few ways to resolve this:"),
        React.createElement("ul", { className: "list-disc pl-6" },
            hasPermissions ? (React.createElement("li", null,
                React.createElement(Clickable, { className: "font-medium", onClick: onManuallyMoveVendorToVerified }, "Manually move this vendor to verified"),
                ' ',
                "to ignore this warning until a new TIN is detected")) : (React.createElement("li", null, "Contact an administrator on your team to manually move this vendor to verified")),
            hasTinMismatch && (React.createElement("li", null,
                React.createElement(Link, { className: "text-blue-40", to: taxDocsPath }, "Add or request"),
                ' ',
                "new tax information")),
            React.createElement("li", null,
                React.createElement(Clickable, { className: "font-medium", onClick: onContactSupport }, "Contact support"),
                ' ',
                "to archive this vendor"))));
};

import PropTypes from 'prop-types';
import React from 'react';

import { EntityWidgetHeaderTitle } from 'components/entityWidget/components';

import { isBankAccountViewTypeAddressAny } from 'helpers/ui';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { AccountAddress } from 'modules/fundingAccount/bankAccount/components';

const BankAccountHeaderTitle = ({ fundingAccount, fundingInfoAddress, viewType }) => {
  if (isBankAccountViewTypeAddressAny(viewType)) {
    return (
      <EntityWidgetHeaderTitle dataFullStory>
        <AccountAddress {...fundingInfoAddress} />
      </EntityWidgetHeaderTitle>
    );
  }

  return <EntityWidgetHeaderTitle dataFullStory>{fundingAccount.name}</EntityWidgetHeaderTitle>;
};

BankAccountHeaderTitle.propTypes = {
  fundingAccount: PropTypes.shape(),
  fundingInfoAddress: PropTypes.shape(),
  fundingInfoBankAccount: PropTypes.shape(),
  viewType: PropTypes.string,
};

BankAccountHeaderTitle.defaultProps = {
  fundingAccount: {},
  fundingInfoAddress: {},
  fundingInfoBankAccount: {},
  viewType: undefined,
};

export default BankAccountHeaderTitle;

import styled, { css } from 'styled-components';
import {} from './combobox.component.types';
import { ChevronDown, ChevronUp } from '@carbon/icons-react';
import { Button } from '@routable/gross-ds';
export const OuterCont = styled.div `
  position: absolute;
  top: 0;
  bottom: 0;
  right: -1px;
  left: -1px;
`;
export const Container = styled.div `
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const PopupContainer = styled.div.attrs((props) => ({
    type: props.type,
    width: props.width,
    height: props.height,
})) `
  position: fixed;
  bottom: 0px;
  left: 0px;
  // Not sure on this right now is just for display
  min-height: 260px;
  min-height: ${(props) => `${props.height ?? 200}px`};
  border-color: var(--color-blue0);

  /* copied TW styles here */
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), rgb(148 152 163 / 0.2);

  // Need to revist this because setting a min width on a searchable
  // can causing popping when there is bigger items.
  width: ${(props) => (props.width ? `${props.width}px` : 'max-content')};
  min-width: 314px;

  z-index: 100001;
  border: 1px solid var(--color-grey20);
  background-color: var(--color-white);
  transition: opacity 300ms ease-in-out;
  opacity: 0;
  box-shadow: 0 4px 16px var(--color-grey20);
  border-radius: 8px;

  padding: var(--spacing-3);

  &.combobox-container-active {
    opacity: 1;
  }

  ${(props) => {
    if (props.type === 'modal') {
        return css `
        text-align: center;
        width: ${props.width ? `${props.width}px` : '200px'};
        height: ${props.height ? `${props.height}px` : 'max-content'};
      `;
    }
}};
`;
export const ComboxDisplayContainer = styled.div `
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
`;
export const ComboxDisplayAction = styled.div `
  position: absolute;
  right: ${({ hasClear }) => (hasClear ? '2px' : '18px')};
  top: 50%;
  transform: translate(0, -50%);

  & svg {
    color: var(--color-grey70);
    fill: var(--color-grey70);
  }

  &:hover svg {
    color: var(--color-neutral-black);
    fill: var(--color-neutral-black);
  }
`;
export const ComboBoxLabel = styled.span `
  padding-left: 12px;
  font-size: 12px;

  ${({ noValueSelected }) => noValueSelected &&
    css `
      color: var(--color-grey60);
      .asterisk {
        color: var(--color-neutral-black);
      }
    `}

  .hide-required-when-required-externally .asterisk {
    display: none;
  }
  .asterisk {
    flex-shrink: 0;
  }
`;
export const ComboBoxUpIcon = styled(ChevronUp) `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const ComboBoxDownIcon = styled(ChevronDown) `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const HandleActionsContainer = styled.div `
  // If we are in modal ComboBox view then dim the background
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 100000;
  transform: translate(0, 0);
  cursor: default;
  touch-action: initial;
  pointer-events: initial;

  &.is-combobox-modal {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
export const ComboBoxInput = styled.input `
  position: absolute;
  left: -1000px;
  top: -1000px;
  width: 0px;
  height: 0px;
  opacity: 0;
`;
export const ClearInputValueButton = styled(Button) `
  border: 0px solid rgba(0, 0, 0, 0) !important;
  outline-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;

  &:hover {
    background-color: transparent !important;
  }

  & * {
    outline-color: rgba(0, 0, 0, 0) !important;
    border: 0px solid rgba(0, 0, 0, 0) !important;
    box-shadow: none !important;
  }

  & svg {
    color: var(--color-grey70);
    fill: var(--color-grey70);
  }

  &:hover svg {
    color: var(--color-neutral-black);
    fill: var(--color-neutral-black);
  }
`;

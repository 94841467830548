import {
  deleteCompanyDocumentRoutine,
  deleteRepresentativeDocumentRoutine,
  postCompanyDocumentRoutine,
  postRepresentativeDocumentRoutine,
} from 'actions/routines/documents';

import { createIsFetchingReducer } from 'store/redux';

import {
  FETCH_DOCUMENTS_REQUEST,
  FETCH_DOCUMENT_REQUEST,
  FETCH_DOCUMENTS_FAILURE,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENT_FAILURE,
  FETCH_DOCUMENT_SUCCESS,
} from 'types/documents';

const finishCases = [
  FETCH_DOCUMENTS_REQUEST,
  FETCH_DOCUMENT_REQUEST,
  deleteCompanyDocumentRoutine.SUCCESS,
  deleteCompanyDocumentRoutine.FAILURE,
  deleteRepresentativeDocumentRoutine.SUCCESS,
  deleteRepresentativeDocumentRoutine.FAILURE,
  postCompanyDocumentRoutine.SUCCESS,
  postCompanyDocumentRoutine.FAILURE,
  postRepresentativeDocumentRoutine.SUCCESS,
  postRepresentativeDocumentRoutine.FAILURE,
];

const requestCases = [
  FETCH_DOCUMENTS_FAILURE,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENT_FAILURE,
  FETCH_DOCUMENT_SUCCESS,
  deleteCompanyDocumentRoutine.TRIGGER,
  deleteRepresentativeDocumentRoutine.TRIGGER,
  postCompanyDocumentRoutine.TRIGGER,
  postRepresentativeDocumentRoutine.TRIGGER,
];

const fetchReducer = createIsFetchingReducer(finishCases, requestCases);

export default fetchReducer;

import axios from 'axios';

import { FetchService } from 'services';

/**
 * Takes nested token information from the server and returns a flattened token object with an isNew flag.
 * @param {Object} arguments
 * @param {ApiToken} arguments.payload
 * @param {string} arguments.tokenId
 * @param {boolean} arguments.isNew
 * @returns {ApiToken}
 */
export const getApiTokenBody = ({ payload, tokenId, isNew }) => {
  const tokenContents = payload[tokenId];

  return {
    ...tokenContents,
    isNew,
  };
};

/**
 * flattenNameFromAttributes
 * Flattens the attributes.name property in an array of items
 * @param items
 * @returns {*}
 */
export const flattenNameFromAttributes = (items) => items.map((el) => ({ ...el, name: el.attributes.name }));

/**
 * REST
 * Make any REST request into our API service
 * @returns {{ [RestMethod]: (function(config): Promise<FetchService.request>) }}
 */
export const REST = {
  get: (config) => FetchService.request({ ...config, method: 'GET' }),
  post: (config) => FetchService.request({ ...config, method: 'POST' }),
  delete: (config) => FetchService.request({ ...config, method: 'DELETE' }),
  put: (config) => FetchService.request({ ...config, method: 'PUT' }),
  patch: (config) => FetchService.request({ ...config, method: 'PATCH' }),
};

/**
 * Generate axios cancel api token
 * @return {object}
 */
export const generateApiCancelSource = () => {
  const { CancelToken } = axios;
  return CancelToken.source();
};

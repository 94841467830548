import { Select, Tag, TextInput } from '@routable/gross-ds';
import _set from 'lodash/set';
import React, {} from 'react';
import { operatorModel, operatorOptions, operatorSelectOptions } from './TextMatchFilter.constants';
import {} from './TextMatchFilter.types';
export const TextMatchFilter = ({ onChange, label = 'Text match filter', value = { operator: operatorModel.Enum.IS_EXACT_IN_CSL, value: '' }, }) => {
    const handleOperatorChange = (operatorValue) => {
        onChange(_set({ ...value }, 'operator', operatorValue));
    };
    const handleValueChange = (evt) => {
        onChange(_set({ ...value }, 'value', evt.target.value));
    };
    return (React.createElement("div", { className: "flex flex-wrap gap-3 items-center" },
        React.createElement(Select, { "aria-label": `${label} - operator`, name: "operator", onChange: handleOperatorChange, options: operatorSelectOptions, value: value.operator }),
        React.createElement(TextInput, { containerClassName: "flex-1", "aria-label": label, name: "value", onChange: handleValueChange, placeholder: "Search by invoice number...", value: value.value })));
};
const Tags = ({ value, onRemove, size }) => (React.createElement(React.Fragment, null,
    operatorOptions[value.operator],
    React.createElement(Tag, { "data-testid": "tag:text-match-filter", intent: "primary", size: size, onRemove: onRemove }, value.value)));
TextMatchFilter.Tags = Tags;

import PropTypes from 'prop-types';
import React from 'react';

import { TooltipPlacement } from 'constants/tooltip';

import ConditionalRestrictedPermissionTooltip from 'modules/auth/ConditionalRestrictedPermissionTooltip';

/**
 * Generates the Tooltip for a restricted by permissions checkbox
 * @param {ComponentProps} props
 * @returns {StatelessComponent}
 */
const RestrictedCheckboxTooltip = ({ children }) => (
  <ConditionalRestrictedPermissionTooltip customization={{ position: TooltipPlacement.TOP }} useTooltip>
    {children}
  </ConditionalRestrictedPermissionTooltip>
);

RestrictedCheckboxTooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RestrictedCheckboxTooltip;

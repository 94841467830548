import { createSelector } from 'reselect';

import { isPartnershipMemberFromPartnershipPartner } from 'helpers/partnershipMembers';
import { isEqual, hasLength } from 'helpers/utility';

import { partnershipFromIdPropSelector, partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';
import { partnershipFromParamSelector } from 'queries/partnershipRouterSelectors';

import { currentCompanyMembersSelector } from 'selectors/currentCompanySelectors';
import { idSelector } from 'selectors/globalSelectors';
import { allPartnershipMembersSelector, partnershipMembersByIdSelector } from 'selectors/partnershipMemberSelectors';
import { partnershipFromPropsSelector, partnershipSelector } from 'selectors/partnershipsSelectors';
import { partnershipPropSelector } from 'selectors/propSelectors';
import { membershipRequesterQuerySelector } from 'selectors/routerSelectors';

/**
 * Selects partnership members where partnership partner (the other side)
 * using partnership id from route params
 * @param {object} state - Redux state
 * @param {object} ownProps - component's own props
 * @param {string} id - companyId
 */
export const allPartnershipMembersWherePartnershipPartnerFromParamSelector = createSelector(
  [allPartnershipMembersSelector, partnershipFromParamSelector],
  (partnershipMembers, partnership) =>
    partnershipMembers.filter((partnershipMember) =>
      isPartnershipMemberFromPartnershipPartner(partnershipMember, partnership),
    ),
);

/**
 * Selects partnership members where partnership partner (the other side)
 * using partnership id from query params
 * @param {object} state - Redux state
 * @param {string} id - companyId
 */
export const allPartnershipMembersWherePartnershipPartnerFromQuerySelector = createSelector(
  [allPartnershipMembersSelector, partnershipFromQuerySelector],
  (partnershipMembers, partnership) =>
    partnershipMembers.filter((partnershipMember) =>
      isPartnershipMemberFromPartnershipPartner(partnershipMember, partnership),
    ),
);

/**
 * Selects partnership members where partnership partner (the other side), getting the partnershipId from the props.
 * @param {ReduxState} state
 * @param {ComponentProps} props
 */
export const allPartnershipMembersWherePartnershipPartnerFromPropsSelector = createSelector(
  [allPartnershipMembersSelector, partnershipFromPropsSelector],
  (partnershipMembers, partnership) =>
    partnershipMembers.filter((partnershipMember) =>
      isPartnershipMemberFromPartnershipPartner(partnershipMember, partnership),
    ),
);

/**
 * Selects membership or partnership member given a membershipId
 * @param {object} state - Redux state
 * @param {string} id - companyId
 */
export const partnershipMemberOrMembershipFromMembershipIdSelector = createSelector(
  [allPartnershipMembersSelector, currentCompanyMembersSelector, idSelector],
  (partnershipMembers, currentCompanyMembers, membershipId) => {
    const currentCompanyMember = currentCompanyMembers.find((member) => isEqual(member.id, membershipId));

    if (currentCompanyMember) {
      return currentCompanyMember;
    }

    return partnershipMembers.find((partnershipMember) => isEqual(partnershipMember.membership, membershipId));
  },
);

/**
 * Selects partnership member given a membershipId (from query param)
 * using partnership id from query params
 * @param {object} state - Redux state
 * @param {string} id - companyId
 */
export const partnershipMemberFromMembershipQueryParamSelector = createSelector(
  [allPartnershipMembersSelector, membershipRequesterQuerySelector],
  (partnershipMembers, membershipId) =>
    partnershipMembers.find((partnershipMember) => isEqual(partnershipMember.membership, membershipId)),
);

/**
 * Returns partnership members for the other side, for the partnership in props.partnership.
 * @function
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @returns {PartnershipMember[]}
 */
export const partnershipMembersFromPartnershipPropSelector = createSelector(
  [partnershipPropSelector, partnershipMembersByIdSelector],
  (partnership, partnershipMembersById) => {
    if (partnership) {
      const { partnershipMembers } = partnership;
      return partnershipMembers
        .map((partnershipMemberId) => partnershipMembersById[partnershipMemberId])
        .filter((partnershipMember) => isPartnershipMemberFromPartnershipPartner(partnershipMember, partnership));
    }

    return [];
  },
);

/**
 * Returns partnership members for the other side, for the partnership in props.partnership, as an object.
 * @function
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @returns {Object.<Id, PartnershipMember>}
 */
export const partnershipMembersMapFromPartnershipPropSelector = createSelector(
  [partnershipMembersFromPartnershipPropSelector],
  (partnershipMembers) =>
    partnershipMembers.reduce(
      (obj, partnershipMember) => ({
        ...obj,
        [partnershipMember.id]: partnershipMember,
      }),
      {},
    ),
);

/**
 * Selects partnership member given a membershipId (from query param)
 * using partnership id from query params
 * @type {StandardSelector}
 * @param {string} id - companyId
 * @return {PartnershipMember[]}
 */
export const partnershipMembersFromPartnershipIdPropSelector = createSelector(
  [partnershipMembersByIdSelector, partnershipFromIdPropSelector],
  (partnershipMembersById, partnership) =>
    (partnership?.partnershipMembers || [])
      .map((partnershipMemberId) => partnershipMembersById[partnershipMemberId])
      .filter((partnershipMember) => isPartnershipMemberFromPartnershipPartner(partnershipMember, partnership)),
);

/**
 * Whether or not there are partnershipMembers in a partnership.
 * @param {ReduxState}
 * @returns {boolean}
 */
export const hasPartnershipMembersSelector = createSelector(
  [allPartnershipMembersWherePartnershipPartnerFromPropsSelector],
  (partnershipMembers) => hasLength(partnershipMembers),
);

/**
 * Selects partnership partner members given a partnership Id.
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {string} partnershipId
 * @return {PartnershipMember[] | undefined}
 */
export const partnershipMembersFromPartnershipIdSelector = createSelector(
  [partnershipMembersByIdSelector, partnershipSelector],
  (partnershipMembersById, partnership) =>
    partnership?.partnershipMembers
      ?.map((partnershipMemberId) => partnershipMembersById[partnershipMemberId])
      .filter((partnershipMember) => isPartnershipMemberFromPartnershipPartner(partnershipMember, partnership)),
);

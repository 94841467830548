import InvoiceListDate from 'modules/dashboard/createItems/invoiceListTable/components/InvoiceListDate';
import InvoiceListText from 'modules/dashboard/createItems/invoiceListTable/components/InvoiceListText';
import ItemStatusList from 'modules/itemStatus/presenters/ItemStatusList';

import ExternalItemsListAmount from '../ExternalItemsListAmount';

export const requestTableData = {
  invoiceNumber: {
    className: 'external-items-list-table-column--text show-mobile',
    displayName: 'Invoice No.',
    headerClassName: 'show-mobile',
    obj: InvoiceListText,
    props: {
      buttonLabel: 'Pay now',
      dataKey: 'reference',
      fallbackText: 'View invoice',
      hasLink: true,
    },
    width: 4,
  },
  reference: {
    className: 'external-items-list-table-column--text has-invoice-number',
    displayName: 'Reference',
    obj: InvoiceListText,
    props: {
      dataKey: 'partnerReference',
    },
    width: 0.8,
  },
  invoiceDate: {
    className: 'external-items-list-table-column--text date',
    displayName: 'Sent date',
    obj: InvoiceListDate,
    props: {
      dataKey: 'dateIssued',
    },
    width: 0.2,
  },
  dateDue: {
    className: 'external-items-list-table-column--text date',
    displayName: 'Due date',
    obj: InvoiceListDate,
    props: {
      dataKey: 'dateDue',
    },
    width: 0.2,
  },
  amountDue: {
    className: 'external-items-list-table-column--text amount',
    displayName: 'Amount',
    obj: ExternalItemsListAmount,
    width: 0.2,
  },
  status: {
    className: 'external-items-list-table-column--status',
    displayName: 'Status',
    obj: ItemStatusList,
    props: {
      dataKey: 'status',
    },
    width: 4,
  },
};

export const paymentTableData = {
  reference: {
    className: 'external-items-list-table-column--text show-mobile',
    displayName: 'Reference',
    headerClassName: 'show-mobile',
    obj: InvoiceListText,
    props: {
      buttonLabel: 'Accept now',
      dataKey: 'reference',
      fallbackText: 'View payment',
      hasLink: true,
    },
    width: 5,
  },
  invoiceDate: {
    className: 'external-items-list-table-column--text date',
    displayName: 'Sent date',
    obj: InvoiceListDate,
    props: {
      dataKey: 'dateIssued',
    },
    width: 0.2,
  },
  dateDue: {
    className: 'external-items-list-table-column--text date',
    displayName: 'Due date',
    obj: InvoiceListDate,
    props: {
      dataKey: 'dateDue',
    },
    width: 0.2,
  },
  amountDue: {
    className: 'external-items-list-table-column--text amount',
    displayName: 'Amount',
    obj: ExternalItemsListAmount,
    width: 0.2,
  },
  status: {
    className: 'external-items-list-table-column--status',
    displayName: 'Status',
    obj: ItemStatusList,
    props: {
      dataKey: 'status',
    },
    width: 3,
  },
};

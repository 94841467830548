import { ExternalPaymentMethodHintLabel } from 'constants/external';

import { isItemKindPayable } from 'helpers/items';

/**
 * Returns item payment method hint label text based on the passed item kind.
 * @param {ItemKind} itemKind
 * @returns {string}
 */
export const getItemPaymentMethodHintLabelText = (itemKind) =>
  isItemKindPayable({ kind: itemKind })
    ? ExternalPaymentMethodHintLabel.ITEM_PAYABLE
    : ExternalPaymentMethodHintLabel.ITEM_RECEIVABLE;

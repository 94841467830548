import React from 'react';

import CheckListCompleteBullet from './components/CheckListCompleteBullet';
import CheckListIncompleteBullet from './components/CheckListIncompleteBullet';

export const getCompleteBullet = (completeBullet) => {
  if (completeBullet) {
    return completeBullet;
  }

  return <CheckListCompleteBullet />;
};

export const getIncompleteBullet = (incompleteBullet) => {
  if (incompleteBullet) {
    return incompleteBullet;
  }

  return <CheckListIncompleteBullet />;
};

export const getItemBullet = (props) => {
  const { completeBullet, incompleteBullet, isComplete, isTitle } = props;

  if (isTitle) {
    return undefined;
  }

  if (isComplete) {
    return getCompleteBullet(completeBullet);
  }

  return getIncompleteBullet(incompleteBullet);
};

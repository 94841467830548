import { createIsSubmittingReducer } from 'store/redux';

import {
  SUBMIT_UNMATCHED_PLATFORM_FUNDING_ACCOUNT_FAILURE,
  SUBMIT_UNMATCHED_PLATFORM_FUNDING_ACCOUNT_REQUEST,
  SUBMIT_UNMATCHED_PLATFORM_FUNDING_ACCOUNT_SUCCESS,
} from 'types/integrations';

export const finishCases = [
  SUBMIT_UNMATCHED_PLATFORM_FUNDING_ACCOUNT_FAILURE,
  SUBMIT_UNMATCHED_PLATFORM_FUNDING_ACCOUNT_SUCCESS,
];
export const requestCases = [SUBMIT_UNMATCHED_PLATFORM_FUNDING_ACCOUNT_REQUEST];

const submitReducer = createIsSubmittingReducer(finishCases, requestCases);

export default submitReducer;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Base-level element used to display a small content block (generally, with text
 * and icons on one or both sides), that shows a reference to some resource that
 * is related to the resource currently being viewed.
 * Examples: Fees related to a transaction, a document related to an item.
 * @param {Object} props
 * @param {NodeMaybe} props.children
 * @param {String} [props.className]
 * @param {Object} [props.style]
 * @return {StatelessComponent}
 */
const BaseRelation = ({ children, className, style }) => (
  <div className={classNames('relation', { [className]: !!className })} style={style}>
    {children}
  </div>
);

BaseRelation.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

BaseRelation.defaultProps = {
  children: undefined,
  className: undefined,
  style: {},
};

export default BaseRelation;

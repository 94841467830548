import PropTypes from 'prop-types';
import React from 'react';

import './Notice.scss';

const Notice = (props) => <div className={`notice ${props.noticeClass}`}>{props.children}</div>;

Notice.propTypes = {
  noticeClass: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Notice;

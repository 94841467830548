import React from 'react';

import { reduxFormInputPropType } from 'components/commonProps';

import InvoiceGeneratorTotalsRow from '../InvoiceGeneratorTotalsRow';

const InvoiceGeneratorTotalsSubtotal = ({ input: { value }, ...rest }) => (
  <InvoiceGeneratorTotalsRow {...rest} amount={value} showOnAutoTax text="Subtotal" />
);

InvoiceGeneratorTotalsSubtotal.propTypes = {
  input: reduxFormInputPropType.isRequired,
};

export default InvoiceGeneratorTotalsSubtotal;

import React from 'react';
import helpDocs from 'helpers/helpDocs';
import { getOriginal } from 'helpers/tables';
import {} from 'interfaces/table.types';
import { ContactActionsCell, ContactDetailsCell, ContactPhoneNumberCell, ContactSettingsCell, ContactStatusCell, } from '../components';
import { InfoIconWithTooltip } from './InfoIconWithTooltip';
export const CompanyContactsTableConfig = [
    {
        accessor: 'details',
        Cell: (props) => React.createElement(ContactDetailsCell, { rowData: { ...getOriginal(props) } }),
        Header: 'Contact Info',
        width: 2,
    },
    {
        accessor: 'settings',
        Cell: (props) => React.createElement(ContactSettingsCell, { rowData: { ...getOriginal(props) } }),
        Header: 'Autofill Settings',
        props: {
            rightIndicator: (React.createElement(InfoIconWithTooltip, { link: helpDocs.CONTACT_TYPES, text: "Controls who can act on payment or email communications." })),
        },
        width: 1,
    },
    {
        accessor: 'phone',
        Cell: (props) => React.createElement(ContactPhoneNumberCell, { rowData: { ...getOriginal(props) } }),
        Header: 'Phone Number',
        width: 1,
    },
    {
        accessor: 'status',
        Cell: (props) => React.createElement(ContactStatusCell, { rowData: { ...getOriginal(props) } }),
        Header: 'Status',
        width: 2,
    },
    {
        accessor: 'action',
        Cell: (props) => React.createElement(ContactActionsCell, { rowData: { ...getOriginal(props) } }),
        className: 'checkbox-column',
        Header: '',
        width: 1,
    },
];

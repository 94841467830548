import React from 'react';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
const BalanceFAQItemUsageAnswer = ({ exampleAmount, nextDayTitle, rtpTitle, sameDayTitle, }) => {
    const rtpFragment = rtpTitle ? (React.createElement(React.Fragment, null,
        `To transact via ${rtpTitle}, funds need to be initiated from your ${PLATFORM_DISPLAY_SHORT_NAME} balance. \
          For example, if you intend to send ${exampleAmount} via RTP, your ${PLATFORM_DISPLAY_SHORT_NAME} balance needs \
          to have at least ${exampleAmount} pre-funded.`,
        React.createElement("br", null))) : null;
    return (React.createElement(React.Fragment, null,
        rtpFragment,
        `You can send ${sameDayTitle} and ${nextDayTitle} payments using the ${PLATFORM_DISPLAY_SHORT_NAME} balance as the funding source.`));
};
export default BalanceFAQItemUsageAnswer;

import { connect } from 'react-redux';
import { itemWithPartnerNameSelector } from 'queries/itemPartnershipSelectors';
import { currentCompanyItemApprovalLevelsAllSelector } from 'selectors/currentCompanySelectors';
import { createItemFormSelector } from 'selectors/forms';
import InvoiceListNumber from './InvoiceListNumber';
export const mapStateToProps = (state) => {
    const item = createItemFormSelector(state, 'item');
    return {
        approvalLevels: currentCompanyItemApprovalLevelsAllSelector(state),
        submittedItem: itemWithPartnerNameSelector(state, item.id),
    };
};
export default connect(mapStateToProps)(InvoiceListNumber);

import { useDispatchEventSync, useEventSync } from '@routable/framework';
import { TextInput, Select, ButtonGroup, Button } from '@routable/gross-ds';
import React, { useMemo, useState } from 'react';
import _debounce from 'lodash/debounce';
import { useTableStore } from '../../table.store';
import {} from '../../../types/global.types';
import { Container } from './footer.styled';
import {} from './footer.types';
import { ArrowLeft, ArrowRight } from '@carbon/icons-react';
const getCurrentPageValue = (pageSize, currentPage) => {
    return pageSize * currentPage;
};
const getPageRecordLabel = (total, pageSize, currentPage) => {
    const start = Math.max(getCurrentPageValue(pageSize, currentPage - 1), 0);
    const end = Math.min(getCurrentPageValue(pageSize, currentPage), total);
    return `${Math.min(end, start + 1)}-${end} of ${total.toLocaleString('en-US')}`;
};
const getGoToPageNumber = (e, pages) => {
    let gotoPageNumber = parseInt(e.target.value);
    if (!gotoPageNumber) {
        return;
    }
    if (gotoPageNumber > pages) {
        gotoPageNumber = pages;
    }
    return gotoPageNumber;
};
const pageSizeOptions = [
    {
        value: 25,
        label: '25',
    },
    {
        value: 50,
        label: '50',
    },
    {
        value: 75,
        label: '75',
    },
    {
        value: 100,
        label: '100',
    },
];
const DEBOUNCE_TIME = 400;
export const TableFooter = ({ tableName }) => {
    const dispatch = useDispatchEventSync();
    const [pagingData, setPagingData] = useState({
        hasNextPage: false,
        hasPrevPage: false,
        page: 1,
        pageSize: 25,
        pages: 1,
        total: 0,
    });
    const [pageSize, setPageSize] = useState(25);
    const [goToPage, setGoToPage] = useState('');
    const [goToPagePlaceholder, setGoToPagePlaceholder] = useState('1');
    const { activeTableState: { isFetching }, } = useTableStore(tableName);
    useEventSync(`table:${tableName}:pagination`, (data) => {
        if (data.pages === 0) {
            data.pages = 1;
        }
        setPagingData(data);
        setPageSize(data.pageSize);
        setGoToPagePlaceholder(data.page?.toString());
    });
    const handlePageSizeSelectChange = (newPageSize) => {
        dispatch(`table:${tableName}:page:update`, { pageSize: newPageSize });
        setPageSize(newPageSize);
    };
    const dispatchGoToPageChange = (e) => {
        const gotoPageNumber = getGoToPageNumber(e, pagingData.pages);
        dispatch(`table:${tableName}:page:update`, {
            page: gotoPageNumber,
        });
    };
    const handleGoToPageChangeDebounced = useMemo(() => {
        const debounced = _debounce((e) => dispatchGoToPageChange(e), DEBOUNCE_TIME);
        return function (e) {
            e.persist();
            const newPageNumber = getGoToPageNumber(e, pagingData.pages);
            if (!newPageNumber) {
                setGoToPage('');
                return;
            }
            setGoToPage(newPageNumber.toString());
            return debounced(e);
        };
    }, [pagingData.pages]);
    if (!pagingData || !pagingData.page) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(Container, { className: "table-footer-with-controls" },
        React.createElement("div", { className: "flex items-center" },
            React.createElement("span", null, "Rows per page"),
            React.createElement("div", { className: "ml-3 mr-5 w-[72px]" },
                React.createElement(Select, { "aria-label": "page size", className: "table-footer-page-select h-8", name: "pageSize", onChange: handlePageSizeSelectChange, options: pageSizeOptions, value: pageSize, valueAs: "number", size: "small" })),
            isFetching && React.createElement("span", null,
                "Showing ",
                getPageRecordLabel(0, 0, 0)),
            !isFetching && (React.createElement("span", null,
                "Showing ",
                getPageRecordLabel(pagingData.total, pagingData.pageSize, pagingData.page)))),
        React.createElement("div", { className: "flex items-center gap-5" },
            React.createElement("div", { className: "hidden md:flex items-center gap-3" },
                React.createElement("span", null, "Go to page"),
                React.createElement("div", { className: "w-12" },
                    React.createElement(TextInput, { disabled: isFetching || pagingData.pages <= 1, inputClassname: "h-8 goto-page", name: "goto-page-input", onChange: handleGoToPageChangeDebounced, placeholder: goToPagePlaceholder, size: "small", value: goToPage, onBlur: () => {
                            setGoToPagePlaceholder(goToPage || pagingData.page.toString());
                            setTimeout(() => {
                                setGoToPage('');
                            }, DEBOUNCE_TIME);
                        } }))),
            React.createElement(ButtonGroup, { size: "small" },
                React.createElement(Button, { "data-testid": "table:back-button", disabled: isFetching || !pagingData.hasPrevPage, onPress: () => dispatch(`table:${tableName}:page:update`, {
                        page: 'PREV',
                    }), size: "small", variant: "outline" },
                    React.createElement(ArrowLeft, null)),
                React.createElement(Button, { "data-testid": "table:pagination-button", disabled: true, size: "small", variant: "outline", className: "page-count" },
                    pagingData.page,
                    " of ",
                    pagingData.pages),
                React.createElement(Button, { "data-testid": "table:next-button", disabled: isFetching || !pagingData.hasNextPage, onPress: () => dispatch(`table:${tableName}:page:update`, {
                        page: 'NEXT',
                    }), size: "small", variant: "outline" },
                    React.createElement(ArrowRight, null))))));
};

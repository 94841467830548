import PropTypes from 'prop-types';
import React from 'react';

import Checkbox from 'components/checkbox';
import TermsOfServiceContent from 'components/form/TermsOfServiceContent';

/**
 * Component with checkbox and Terms of Service Content
 * @param {ComponentProps} props
 * @param {Object} props.errors
 * @param {Object} props.formState
 * @param {Function} props.onChange
 * @param {Boolean} props.isRequired
 * @returns {StatelessComponent}
 */
const CheckboxTermsOfService = ({ errors, formState, onChange, isRequired, ...props }) => (
  <Checkbox
    content={<TermsOfServiceContent />}
    errName="Terms of Service agreement"
    errors={errors}
    formState={formState}
    isRequired={isRequired}
    name="tosAgree"
    onChange={onChange}
    {...props}
  />
);

CheckboxTermsOfService.propTypes = {
  errors: PropTypes.shape(),
  formState: PropTypes.shape(),
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
};

CheckboxTermsOfService.defaultProps = {
  errors: {},
  formState: {},
  onChange: () => {},
  isRequired: true,
};

export default CheckboxTermsOfService;

import PropTypes from 'prop-types';
import React from 'react';

import { Row } from 'components';

import { breakpointNames } from 'constants/mediaQuery';

/**
 * Block dynamic tables section. Flex row that does not wrap.
 * @param {*} children
 * @param {StringMaybe} className
 * @param {*} label
 * @return {StatelessComponent}
 */
const BlockSectionNoWrap = ({ children, className, label }) => (
  <div className={className}>
    {label}

    <Row
      breakpointProps={{
        [breakpointNames.allMedia]: {
          flexWrap: 'no-wrap',
        },
      }}
    >
      {children}
    </Row>
  </div>
);

BlockSectionNoWrap.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.node,
};

BlockSectionNoWrap.defaultProps = {
  children: undefined,
  className: undefined,
  label: undefined,
};

export default BlockSectionNoWrap;

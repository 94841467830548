import React from 'react';
import { Field } from 'redux-form';
import { membershipInviteAcceptV2Handler } from 'actions/routines/inviteTeamMember';
import { BoxV2, FormControl, FormFieldLabel } from 'components';
import { formNamesAuth } from 'constants/forms';
import { formStyles } from 'constants/styles';
import { requiredValidator } from 'helpers/fieldValidation';
import { AcceptMembershipInviteFormActionButtons, AcceptMembershipInviteFormContent, ReduxFormFieldRenderInputWithUpdatedErrors, } from './components';
const AcceptMembershipInviteForm = ({ company, companySSOSettings, handleSubmit, isSubmitting, membershipInvite, }) => {
    const [loginWithPassword, setLoginWithPassword] = React.useState(false);
    const { canManageSsoSettings } = membershipInvite;
    return (React.createElement("div", { className: "branded--contents" },
        React.createElement(BoxV2, { title: `Join the ${company.name} team` },
            React.createElement("form", { className: "form", id: formNamesAuth.ACCEPT_MEMBERSHIP_INVITE, onSubmit: handleSubmit(membershipInviteAcceptV2Handler) },
                !loginWithPassword && (React.createElement(AcceptMembershipInviteFormContent, { canManageSsoSettings: canManageSsoSettings, companySSOSettings: companySSOSettings })),
                loginWithPassword && (React.createElement(React.Fragment, null,
                    React.createElement(FormFieldLabel, { className: "margin-top--m" }, "Add a secure password"),
                    React.createElement(FormControl, null,
                        React.createElement(Field, { className: formStyles.field.xl.full, component: ReduxFormFieldRenderInputWithUpdatedErrors, name: "form.password", placeholder: "Password", type: "password", validate: requiredValidator })))),
                React.createElement(AcceptMembershipInviteFormActionButtons, { canManageSsoSettings: canManageSsoSettings, companySSOSettings: companySSOSettings, isSubmitting: isSubmitting, loginWithPassword: loginWithPassword, setLoginWithPassword: () => setLoginWithPassword(true) })))));
};
export default AcceptMembershipInviteForm;

import merge from 'deepmerge';

import { isObject, isValueEmpty } from './utility';

/**
 * Recursive method to clean up the server submit payload, removing empty values
 * @param payload
 * @param options
 * @return {object}
 */
export const cleanObjectOfEmptyValues = (payload, options = {}) => {
  if (!isObject(payload)) {
    return payload;
  }

  const { allowEmptyString } = options;

  const newPayload = {};

  Object.keys(payload).forEach((key) => {
    const value = payload[key];

    if (isValueEmpty(value, allowEmptyString)) {
      return;
    }

    if (isObject(value)) {
      const newValue = cleanObjectOfEmptyValues(value);

      if (isValueEmpty(newValue, allowEmptyString)) {
        return;
      }

      newPayload[key] = newValue;
    } else {
      newPayload[key] = value;
    }
  });

  return newPayload;
};

/**
 * Deep merge objects with array replacement
 * @param {object} obj1
 * @param {object} obj2
 * @return {object}
 */
export const deepMergeWithArrayReplacement = (obj1, obj2) => {
  const arrayMergeStrategy = (oldArray, newArray) => newArray;
  return merge(obj1, obj2, { arrayMerge: arrayMergeStrategy });
};

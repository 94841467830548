import { submitCreatePartnershipRoutine, updatePartnershipGeneralInfoRoutine } from 'actions/routines/partnership';

import { createIsSubmittingReducer } from 'store/redux';

import * as types from 'types/partnership';

export const finishCases = [
  types.CLEAR_PARTNERSHIP,
  submitCreatePartnershipRoutine.SUCCESS,
  submitCreatePartnershipRoutine.FAILURE,
  submitCreatePartnershipRoutine.FULFILL,
  updatePartnershipGeneralInfoRoutine.SUCCESS,
  updatePartnershipGeneralInfoRoutine.FAILURE,
  updatePartnershipGeneralInfoRoutine.FULFILL,
];

export const requestCases = [submitCreatePartnershipRoutine.REQUEST, updatePartnershipGeneralInfoRoutine.REQUEST];

export default createIsSubmittingReducer(finishCases, requestCases);

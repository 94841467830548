import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ButtonV2, IconNames } from 'components';

import { ButtonSize } from 'constants/button';
import { sizes } from 'constants/styles';

import { isCurrentCompanyInInviteStage } from 'helpers/currentCompany';
import { parseCurrency } from 'helpers/numbers';

import PartnerCompanyPaymentMethodContainer from 'modules/createPartnerCompany/containers/PartnerCompanyPaymentMethodContainer';
import ExternalPromptHeader from 'modules/external/externalPrompt/global/presenters/ExternalPromptHeader';
import ExternalPromptInstructions from 'modules/external/externalPrompt/global/presenters/ExternalPromptInstructions';

import { itemFromQuerySelector } from 'queries/itemRouterSelectors';
import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

import { currentCompanySelector } from 'selectors/currentCompanySelectors';

class ExternalPromptCustomerSingleItemPending extends React.Component {
  renderBodyItemExisting = () => {
    const { currentCompany } = this.props;

    if (isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    return (
      <div className="margin-top--large">
        <PartnerCompanyPaymentMethodContainer />
      </div>
    );
  };

  renderBodyItemPending = () => {
    const { currentCompany, partnership } = this.props;

    if (!isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    const instructions = [
      {
        id: 1,
        key: '1',
        text: `Register as a ${partnership.name} customer by sharing your business info.`,
      },
      {
        id: 2,
        key: '2',
        text: 'Select a preferred payment method for paying your invoices.',
      },
      {
        id: 3,
        key: '3',
        text: `Receive a notification when funds have transferred to ${partnership.name}.`,
      },
    ];

    return <ExternalPromptInstructions instructions={instructions} isAboveButton />;
  };

  renderButtonItemPending = () => {
    const { currentCompany, item, onStart } = this.props;

    if (!isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    return (
      <ButtonV2
        className="external--light"
        leftIconClassName="margin-right--xm"
        leftIconName={IconNames.SHIELD}
        leftIconSize={sizes.iconSizes.LARGE}
        onClick={onStart}
        rightIconName={IconNames.ARROW_RIGHT}
        rightIconProps={{ style: { marginLeft: 'auto' } }}
        rightIconSize={sizes.iconSizes.LARGE}
        size={ButtonSize.LARGE}
      >
        {`Pay ${parseCurrency(item.amount)}`}
      </ButtonV2>
    );
  };

  render() {
    const { partnership } = this.props;

    return (
      <React.Fragment>
        <ExternalPromptHeader showRequester text={`New invoice from ${partnership.name}`} />

        {/* Prompt body */}
        {this.renderBodyItemExisting()}
        {this.renderBodyItemPending()}

        {/* Prompt button */}
        {this.renderButtonItemPending()}
      </React.Fragment>
    );
  }
}

ExternalPromptCustomerSingleItemPending.propTypes = {
  currentCompany: PropTypes.shape().isRequired,
  item: PropTypes.shape().isRequired,
  onStart: PropTypes.func.isRequired,
  partnership: PropTypes.shape().isRequired,
};

const mapStateToProps = createStructuredSelector({
  currentCompany: currentCompanySelector,
  item: itemFromQuerySelector,
  partnership: partnershipFromQuerySelector,
});

export { ExternalPromptCustomerSingleItemPending };
export default connect(mapStateToProps)(ExternalPromptCustomerSingleItemPending);

import PropTypes from 'prop-types';
import React from 'react';

import { IsLoadingComponent } from 'modules/isLoading/IsLoading';

const PartnerPaymentMethodLoading = ({ isFetching }) => {
  if (!isFetching) {
    return null;
  }

  return <IsLoadingComponent text="Loading bank accounts..." />;
};

PartnerPaymentMethodLoading.propTypes = {
  isFetching: PropTypes.bool,
};

PartnerPaymentMethodLoading.defaultProps = {
  isFetching: false,
};

export default PartnerPaymentMethodLoading;

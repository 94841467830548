import {} from 'interfaces/fetchService';
import {} from 'interfaces/request';
import {} from 'interfaces/webhooks';
import { FetchService } from 'services';
import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { getWebhooksEndpoint, getWebhookSettingsInstanceEndpoint, getWebhookSettingsTestEndpoint, webhookSettingsBaseEndpoint, } from 'services/api/webhooksEndpoints';
export const fetchWebhooks = async (params, options = {}) => {
    const config = {
        ...options,
        endpoint: getWebhooksEndpoint(params),
        method: 'GET',
        requireAuth: true,
    };
    return FetchService.request(config);
};
export const deleteWebhookSettings = async (webhookSettingsId, options = {}) => {
    const config = {
        ...options,
        endpoint: getWebhookSettingsInstanceEndpoint(webhookSettingsId),
        method: 'DELETE',
        requireAuth: true,
    };
    return FetchService.request(config);
};
export const fetchWebhookSettings = async (options = {}) => {
    const config = {
        ...options,
        endpoint: webhookSettingsBaseEndpoint,
        method: 'GET',
        requireAuth: true,
    };
    return FetchService.request(config);
};
export const submitWebhookSettings = async (payload, options = {}) => {
    const augmentedPayload = {
        ...payload,
        itemCreate: true,
        itemStatusChange: true,
    };
    const config = {
        ...options,
        endpoint: webhookSettingsBaseEndpoint,
        method: 'POST',
        payload: payloadToJSONAPI(augmentedPayload, 'WebhookSettings'),
        requireAuth: true,
    };
    return FetchService.request(config);
};
export const testWebhookSettings = async (webhookSettingsId, options = {}) => {
    const config = {
        ...options,
        endpoint: getWebhookSettingsTestEndpoint(webhookSettingsId),
        method: 'POST',
        requireAuth: true,
    };
    return FetchService.request(config);
};
export const updateWebhookSettings = async (payload, options = {}) => {
    const config = {
        ...options,
        endpoint: getWebhookSettingsInstanceEndpoint(payload.id),
        payload: payloadToJSONAPI(payload, 'WebhookSettings'),
        method: 'PATCH',
        requireAuth: true,
    };
    return FetchService.request(config);
};

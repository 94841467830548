import { call, put, spawn, take } from 'redux-saga/effects';

import * as actions from 'actions/affiliates';
import { handleRequestErrors } from 'actions/errors';

import { parseCaughtError, parseErrorResponse } from 'helpers/errors';

import * as types from 'types/affiliates';

import * as api from './api';

/**
 * Handle affiliate connections.
 * @return {IterableIterator<*>}
 */
export function* fetchAffiliateConnections() {
  let errorData = {};

  try {
    const response = yield call(api.fetchAffiliateConnections);

    if (response.ok) {
      yield put(actions.fetchAffiliateConnectionsSuccess(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchAffiliateConnectionsFailure, errorData));
}

/**
 * Listens for redux actions related to affiliates.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([types.FETCH_AFFILIATE_CONNECTIONS_REQUEST]);

    switch (action.type) {
      case types.FETCH_AFFILIATE_CONNECTIONS_REQUEST:
        yield spawn(fetchAffiliateConnections);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root affiliates saga.
 * @return {IterableIterator<*>}
 */
export default function* affiliates() {
  yield watch();
}

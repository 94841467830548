import { businessType as businessTypes, governmentIdType, } from '@routable/companies-management';
import { Checkbox } from '@routable/gross-ds';
import { requiredValidator } from '@routable/shared';
import React from 'react';
import { useDispatch } from 'react-redux';
import { change, Field } from 'redux-form';
import { SelectFieldV2 } from 'components';
import { usStates } from 'constants/i18n';
import { clearFormFields } from 'helpers/formChanges';
import { signupFlowForm } from 'modules/signup-v3/constants';
import { fields } from 'modules/signup-v3/fields';
import { canBusinessUseTinTypeSSN } from 'modules/signup-v3/helpers/business';
import { businessTypeOptions } from '../SignupTaxInfo.constants';
import { SignupTaxInfoTin } from './SignupTaxInfoTin';
export const SignupTaxInfoBusinessFields = ({ businessType, isDisabled, businessWithoutEIN, tinType, stateOfIncorporationIsRequired, }) => {
    const dispatch = useDispatch();
    const handleChangeBusinessType = (newBusinessType) => {
        const businessHadValue = (expected) => businessType === expected && newBusinessType !== expected;
        if (canBusinessUseTinTypeSSN(newBusinessType) && tinType === governmentIdType.Enum.ssn) {
            return;
        }
        const wasLLCSingleMember = businessHadValue(businessTypes.Enum.llc_single_member);
        const wasSoleProprietor = businessHadValue(businessTypes.Enum.sole_proprietorship);
        const wasTrustEstate = businessHadValue(businessTypes.Enum.llc_single_member);
        if ((wasLLCSingleMember || wasSoleProprietor || wasTrustEstate) && tinType === governmentIdType.Enum.ssn) {
            dispatch(change(signupFlowForm, fields.tinType, governmentIdType.Enum.ein));
            clearFormFields(signupFlowForm, [fields.TIN, fields.hasTIN, fields.uiBusinessWithoutEIN], null);
        }
    };
    return (React.createElement("div", { className: "flex flex-col gap-5" },
        React.createElement("div", { className: "flex gap-5" },
            React.createElement(Field, { component: SelectFieldV2, dataFullStory: true, dataTestId: "business-type", isDisabled: isDisabled, isLocked: isDisabled, isRequired: true, label: "Business type", name: fields.businessType, onChange: handleChangeBusinessType, options: businessTypeOptions, type: "text", validate: requiredValidator }),
            React.createElement(Field, { component: SelectFieldV2, dataFullStory: true, dataTestId: "state-of-incorporation", isClearable: true, isDisabled: isDisabled || !businessType, isLocked: isDisabled, isRequired: stateOfIncorporationIsRequired, label: "State of incorporation", name: fields.stateOfIncorporation, options: usStates, type: "text", validate: stateOfIncorporationIsRequired && requiredValidator })),
        React.createElement(SignupTaxInfoTin, { isDisabled: isDisabled }),
        canBusinessUseTinTypeSSN(businessType) && (React.createElement(Checkbox, { checked: businessWithoutEIN, label: "I do not have an Employer Identification Number (EIN)", name: fields.uiBusinessWithoutEIN, onChange: () => {
                const newbusinessWithoutEIN = !businessWithoutEIN;
                dispatch(change(signupFlowForm, fields.uiBusinessWithoutEIN, newbusinessWithoutEIN));
                clearFormFields(signupFlowForm, [fields.TIN, fields.hasTIN], null);
                dispatch(change(signupFlowForm, fields.tinType, newbusinessWithoutEIN ? governmentIdType.Enum.ssn : governmentIdType.Enum.ein));
            } }))));
};

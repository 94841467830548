import React from 'react';
import { CustomerServiceHelper } from 'modules/customerSupport/CustomerService';
import {} from './withChatOnClick.types';
const withChatOnClick = (Component, clickPropName) => {
    const ComponentWithChatOnClick = ({ fallbackEmail, onClick, ...rest }) => {
        const handleClick = (evt) => {
            if (onClick) {
                onClick(evt);
            }
            CustomerServiceHelper.show({ fallbackEmail });
        };
        const clickProps = { onClick: handleClick };
        if (clickPropName) {
            clickProps[clickPropName] = handleClick;
        }
        return React.createElement(Component, { ...rest, ...clickProps });
    };
    return ComponentWithChatOnClick;
};
export default withChatOnClick;

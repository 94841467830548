import React from 'react';
import { InternationalItemConversionRateTooltip } from 'complexComponents/tooltipTypes';
import { Icon, IconNames } from 'components';
import { colors } from 'constants/styles';
import { ItemCurrencyCodeKey } from 'enums/items';
import { isExternalPathAny } from 'helpers/external';
import { isInternationalItem, isItemAmountZero } from 'helpers/items';
import ShowAmountCell from '../ShowAmountCell';
import { getAmountProps } from './helpers';
const ShowItemAmountCell = ({ conversionAmountKey, currencyCodeKey = ItemCurrencyCodeKey.GENERAL, dataKey, partnership, isInbox = false, rowData, }) => {
    const isInternational = isInternationalItem(rowData);
    const isAmountZero = isItemAmountZero(rowData, dataKey);
    const isExternal = isExternalPathAny();
    const showConversionsTooltip = partnership && isInternational && !isAmountZero && !isExternal;
    return (React.createElement(ShowAmountCell, { amountProps: getAmountProps(rowData, dataKey), currencyCodeKey: currencyCodeKey, dataKey: dataKey, rowData: rowData },
        !showConversionsTooltip && isInternational && !isAmountZero && (React.createElement(Icon, { className: "tooltip-icon", color: colors.colorGreyMedHex, icon: IconNames.GLOBE })),
        !isInbox && showConversionsTooltip && (React.createElement(InternationalItemConversionRateTooltip, { conversionAmountKey: conversionAmountKey, item: rowData, partnership: partnership }))));
};
export default ShowItemAmountCell;

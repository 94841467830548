import { isFullAddress } from 'helpers/addressHelpers';

export const getEmptyAddressValues = ({ forceCountry } = {}) => ({
  city: '',
  country: forceCountry || '',
  postalcode: '',
  state: '',
  streetAddress: '',
  streetAddressUnit: '',
});

export const getEmptyFundingAddressValues = ({ forceCountry, partnerName } = {}) => ({
  ...getEmptyAddressValues({ forceCountry }),
  printCompany: partnerName || '',
  printName: '',
});

export const getInitialAddressValues = ({ address, forceCountry } = {}) => {
  if (address && isFullAddress(address)) {
    return address;
  }

  return getEmptyAddressValues({ forceCountry });
};

export const getInitialFundingAddressValues = ({
  address = undefined,
  forceCountry = undefined,
  partnerName = undefined,
} = {}) => {
  if (address && isFullAddress(address)) {
    return address;
  }

  return getEmptyFundingAddressValues({ forceCountry, partnerName });
};

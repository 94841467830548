import PropTypes from 'prop-types';
import React from 'react';

import Icon, { IconNames } from 'components/icon';

import { colors } from 'constants/styles';
import { iconSizes } from 'constants/styles/sizes';

const InfoIcon = (props) => {
  const { className, color, size } = props;

  return <Icon className={className} color={color} icon={IconNames.INFO_SIGN} size={size} />;
};

InfoIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};

InfoIcon.defaultProps = {
  className: undefined,
  color: colors.colorMainJordan,
  size: iconSizes.LARGE,
};

export default InfoIcon;

import PropTypes from 'prop-types';
import React from 'react';

import { Hint, Text } from 'components';

import { typography } from 'constants/styles';
import { Intent } from 'constants/ui';

import { getExternalPaymentMethodSameHintBody } from 'helpers/external';

import './PaymentMethodSameHint.scss';

/**
 * Renders hint with text explaining that no changes has been made to the payment
 * method. Meant to be used in External workflow.
 * @param {ComponentProps} props
 * @param {Company} props.company
 * @return {StatelessComponent}
 */
const PaymentMethodSameHint = ({ company }) => (
  <Hint className="payment-method-same-hint" intent={Intent.INFO}>
    <Text.Semibold color={typography.TextColor.PURPLE_BOLD} size={typography.TextSize.LEVEL_200}>
      Your payment method will remain the same
    </Text.Semibold>
    <Text.Regular color={typography.TextColor.BLACK} size={typography.TextSize.LEVEL_200}>
      {getExternalPaymentMethodSameHintBody(company)}
    </Text.Regular>
  </Hint>
);

PaymentMethodSameHint.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export default PaymentMethodSameHint;

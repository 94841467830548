import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { openCreateManualAddressModal } from 'actions/createManualAddress';

import { Radio } from 'components';
import { reduxFormInputPropType } from 'components/commonProps';

import { FundingSourceProviderSubClasses } from 'constants/funding';
import { CHECK_DELIVERY, checkArrivalTime } from 'constants/paymentMethods';

import { isPaymentMethodDeliveryCheck } from 'helpers/paymentMethods';

import { ConvertAddressToFundingAccount } from 'modules/address';
import CreateManualAddressFundingAccountContainer from 'modules/address/createManualAddressFundingAccount/CreateManualAddressFundingAccountContainer';
import CheckAddressList from 'modules/createPartnerCompany/presenters/components/CheckAddressList';

import { fundingAccountsAllForProviderSubClassSelector } from 'queries/fundingCompoundSelectors';

import { currentCompanySelector } from 'selectors/currentCompanySelectors';

const CheckOptionsRadio = (props) => {
  const {
    currentCompany,
    fundingAccount,
    fundingAccountsWithAddressFundingSources,
    input,
    onFundingAccountAddressSelected,
    onFundingAccountSelected,
    onOpenModal,
    paymentDeliveryMethod,
    ...rest
  } = props;

  return (
    <Radio
      {...rest}
      content={
        isPaymentMethodDeliveryCheck(paymentDeliveryMethod) && (
          <React.Fragment>
            <ConvertAddressToFundingAccount onFundingAccountAddressSelected={onFundingAccountAddressSelected} />

            <CheckAddressList
              fundingAccountsWithAddressFundingSources={fundingAccountsWithAddressFundingSources}
              onFundingAccountSelected={onFundingAccountSelected}
              selectedAccount={fundingAccount}
            />

            <CreateManualAddressFundingAccountContainer />

            <a
              className="font-xs semibold primary remove-hover-underline display--block margin-top--large"
              onClick={() => onOpenModal({ printCompany: currentCompany.name })}
              role="presentation"
            >
              <span className="font-xs icon-ic-plus-full margin-right--sm" />
              <span>Add another address</span>
            </a>
          </React.Fragment>
        )
      }
      id={`check-options-radio--${input.name}`}
      input={input}
      optionText={CHECK_DELIVERY}
      subText={checkArrivalTime}
    />
  );
};

CheckOptionsRadio.propTypes = {
  currentCompany: PropTypes.shape().isRequired,
  fundingAccount: PropTypes.string,
  fundingAccountsWithAddressFundingSources: PropTypes.arrayOf(PropTypes.shape()),
  input: reduxFormInputPropType.isRequired,
  onFundingAccountAddressSelected: PropTypes.func.isRequired,
  onFundingAccountSelected: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  paymentDeliveryMethod: PropTypes.string,
};

CheckOptionsRadio.defaultProps = {
  fundingAccount: undefined,
  fundingAccountsWithAddressFundingSources: [],
  paymentDeliveryMethod: undefined,
};

const mapStateToProps = (state) => ({
  currentCompany: currentCompanySelector(state),
  fundingAccountsWithAddressFundingSources: fundingAccountsAllForProviderSubClassSelector(
    state,
    FundingSourceProviderSubClasses.ADDRESS,
  ),
});

const mapDispatchToProps = {
  onOpenModal: openCreateManualAddressModal,
};

export { CheckOptionsRadio };
export default connect(mapStateToProps, mapDispatchToProps)(CheckOptionsRadio);

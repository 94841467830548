import PropTypes from 'prop-types';
import React from 'react';

import { sortByOrdinal } from 'helpers/sort';

import { roleCardConfigProp } from './propTypes';
import RoleCardEntityWidgetBodyPermissionList from './RoleCardEntityWidgetBodyPermissionList';

/**
 * Container which sorts the permission groups by ordinal.
 *
 * @param {ComponentProps} props
 * @param {RoleCardConfig} props.roleCardConfig
 * @param {boolean} [props.startPermissionListsOpen]
 * @returns {StatelessComponent}
 */
const RoleCardEntityWidgetBodyPermissionListContainer = ({ roleCardConfig, startPermissionListsOpen }) => {
  const sortedPermissionGroups = sortByOrdinal([...roleCardConfig]);

  return (
    <RoleCardEntityWidgetBodyPermissionList
      orderedRoleCardConfig={sortedPermissionGroups}
      startPermissionListsOpen={startPermissionListsOpen}
    />
  );
};

RoleCardEntityWidgetBodyPermissionListContainer.propTypes = {
  roleCardConfig: roleCardConfigProp.isRequired,
  startPermissionListsOpen: PropTypes.bool,
};

RoleCardEntityWidgetBodyPermissionListContainer.defaultProps = {
  startPermissionListsOpen: undefined,
};

export default RoleCardEntityWidgetBodyPermissionListContainer;

import { fetchSingleIntegrationLedgerPartnershipsRoutine } from 'actions/routines/integrations';

import { createIsFetchingReducer } from 'store/redux';

import {
  FETCH_LEDGER_PARTNERSHIPS_FAILURE,
  FETCH_LEDGER_PARTNERSHIPS_REQUEST,
  FETCH_LEDGER_PARTNERSHIPS_SUCCESS,
  FETCH_PLATFORM_PARTNERSHIPS_FAILURE,
  FETCH_PLATFORM_PARTNERSHIPS_REQUEST,
  FETCH_PLATFORM_PARTNERSHIPS_SEARCH_SUCCESS,
  FETCH_PLATFORM_PARTNERSHIPS_SUCCESS,
} from 'types/integrations';

export const finishCases = [
  fetchSingleIntegrationLedgerPartnershipsRoutine.FAILURE,
  fetchSingleIntegrationLedgerPartnershipsRoutine.SUCCESS,
  FETCH_LEDGER_PARTNERSHIPS_FAILURE,
  FETCH_LEDGER_PARTNERSHIPS_SUCCESS,
  FETCH_PLATFORM_PARTNERSHIPS_FAILURE,
  FETCH_PLATFORM_PARTNERSHIPS_SEARCH_SUCCESS,
  FETCH_PLATFORM_PARTNERSHIPS_SUCCESS,
];
export const requestCases = [
  fetchSingleIntegrationLedgerPartnershipsRoutine.REQUEST,
  FETCH_LEDGER_PARTNERSHIPS_REQUEST,
  FETCH_PLATFORM_PARTNERSHIPS_REQUEST,
];

const fetchReducer = createIsFetchingReducer(finishCases, requestCases);

export default fetchReducer;

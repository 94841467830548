import PropTypes from 'prop-types';
import React from 'react';

import Icon, { IconNames } from 'components/icon';
import InputField from 'components/input';
import { FlexRow } from 'components/layout';
import { Text } from 'components/text';

import { colors, typography } from 'constants/styles';

import './BottomSheetHeader.scss';

/**
 * Component rendering the content of BottomSheet header in scope
 * of React Select menu
 * @param {ComponentProps} props
 * @param {string} [props.inputValue]
 * @param {boolean} [props.isSearchable]
 * @param {string} props.label
 * @param {Function} props.onMenuClose
 * @return {StatelessComponent}
 */
const BottomSheetHeader = ({ inputValue, isSearchable, label, name, onMenuClose }) => (
  <FlexRow className="bottom-sheet__header align-items--center justify-content--space-between" stackOnMobile={false}>
    <Text.Semibold className="flex--1" color={typography.TextColor.BLUE_BOLD} size={typography.TextSize.LEVEL_200}>
      {`${label}:`}
      {isSearchable && (
        <InputField
          hideLabel
          inputClassName="width--100-percent"
          name={name}
          placeholder="Type to search..."
          value={inputValue}
        />
      )}
    </Text.Semibold>

    <Icon color={colors.colorGreyDarkHex} icon={IconNames.CROSS} onClick={onMenuClose} />
  </FlexRow>
);

BottomSheetHeader.propTypes = {
  inputValue: PropTypes.string,
  isSearchable: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onMenuClose: PropTypes.func.isRequired,
};

BottomSheetHeader.defaultProps = {
  inputValue: undefined,
  isSearchable: undefined,
  label: 'Select',
};

export default BottomSheetHeader;

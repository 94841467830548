import { combineReducers } from 'redux';

import bulkActionsModalReducer from './bulkActionsModalReducer';
import importReducer from './import';

const bulkActionsReducer = combineReducers({
  bulkActionsModal: bulkActionsModalReducer,
  import: importReducer,
});

export default bulkActionsReducer;

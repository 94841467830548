import PropTypes from 'prop-types';
import React from 'react';

import {
  CREATE_PASSWORD,
  DASHBOARD,
  EXTERNAL,
  EXTERNAL_V2,
  LOGIN,
  ONBOARDING,
  RESET_PASSWORD,
  SIGNUP,
  SITE_OFFLINE,
  GET_STARTED,
} from 'constants/routes';

import { getCurrentUrlPath } from 'helpers/urls';

import ExternalFooter from 'modules/external/externalFooter/ExternalFooter';

import './ExtWrapper.scss';

const ExtWrapper = ({ activeClass, children }) => {
  const pathname = getCurrentUrlPath();

  // Base paths to not show footer
  const noShowPaths = [
    `/${DASHBOARD}`,
    `/${GET_STARTED}`,
    `/${EXTERNAL}`,
    `/${EXTERNAL_V2}`,
    `/${ONBOARDING}`,
    `/${SIGNUP}`,
    `/${LOGIN}`,
    `/${CREATE_PASSWORD}`,
    `/${RESET_PASSWORD}`,
    `/${SITE_OFFLINE}`,
  ];

  const noShow = noShowPaths.some((element) => pathname.includes(element));

  return (
    <React.Fragment>
      <div className={`background ${activeClass}`}>{children}</div>

      {!noShow && <ExternalFooter />}
    </React.Fragment>
  );
};

ExtWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  activeClass: PropTypes.string,
};

ExtWrapper.defaultProps = {
  activeClass: '',
};

export default ExtWrapper;

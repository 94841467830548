import { Hint } from '@routable/components';
import React from 'react';
import { Link } from 'react-router-dom';
export const RiskChecksOutdatedHint = ({ taxDocsPath }) => {
    return (React.createElement(Hint, { title: "Tax information is out of date", type: "warning" },
        "Updated information about this vendor requires you to",
        ' ',
        React.createElement(Link, { className: "text-blue-40", to: taxDocsPath }, "add or request"),
        ' ',
        "new tax information in order to run new compliance checks."));
};

import { REST } from 'helpers/api';

import * as endpoints from 'services/api/rolesEndpoints';

/**
 * Fetch all the roles.
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchRoles = async (options = {}) =>
  REST.get({
    ...options,
    endpoint: endpoints.rolesEndpoint,
    requireAuth: true,
  });

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, getFormValues } from 'redux-form';

import { toggleInviteTeamMemberModal, changeRepresentativeLock } from 'actions/signUpFlow';

import { Checkbox, Icon, IconNames } from 'components';
import BaseHint from 'components/hintTypes/BaseHint';
import CannotUpdateFieldHint from 'components/hintTypes/CannotUpdateFieldHint';
import { TooltipMUI } from 'components/tooltip';

import { colors, sizes } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';
import { Intent } from 'constants/ui';

import { requiredValidator } from 'helpers/fieldValidation';
import getContactName from 'helpers/user';

import { text as helperText } from 'modules/dashboard/settings/user/representative/helpers';
import Accordion from 'modules/signup-v3/components/Accordion';
import { containerWidthMap } from 'modules/signup-v3/constants/container';

import { currentCompanySelector } from 'selectors/currentCompanySelectors';
import { currentUserSelector } from 'selectors/currentUserSelectors';
import {
  currentMembershipIsRepresentativeSelector,
  currentMembershipVerificationUpdateAllowedSelector,
  isUpdatingMembershipSelector,
} from 'selectors/membershipsSelector';
import { getInviteTeamMemberModal } from 'selectors/signupSelectors';

import InviteTeamMemberModal from '../components/InviteTeamMemberModal';
import { signupFlowForm } from '../constants';
import { whatInfoRepresentativeNeed, howRoutableUseThisInformation } from '../constants/accordion';
import { mapViewToFields } from '../fields';
import FlowStepperMain from '../FlowStepperMain';
import { viewSteps } from '../helpers/viewSteps';

import { Container, HelperContainer, Title, FieldsContainer, HintLink, FieldWrapper } from './Qualification.styles';

export const QualificationField = ({ name, id, isDisabled, currentCompany, currentUser }) => {
  const fields = {
    agreeIdentity: {
      content: helperText.getRepresentativeContactText(getContactName(currentUser), currentUser.email),
      hasTooltip: false,
      dataFullStory: true,
    },
    agreeBusinessRepresentative: {
      content: helperText.getRepresentativeCompanyNameText(currentCompany.name),
      hasTooltip: true,
      tooltipTitle:
        'A business representative is any individual who holds significant responsibilities to control, manage, or direct the company. They can also be a beneficial owner, an individual who owns (directly or indirectly) 25% or more of the equity interests of the company.',
    },
  };

  return (
    <FieldWrapper data-fullstory={fields[id]?.dataFullStory}>
      <Field
        component={Checkbox}
        content={fields[id].content}
        dataFullStory
        dataTestId={name}
        isDisabled={isDisabled}
        isRequired
        name={name}
        type="checkbox"
        validate={[requiredValidator]}
      />
      {fields[id].hasTooltip && (
        <TooltipMUI
          arrow
          dataTestId={fields[id].dataTestId}
          padding={TooltipPadding.LARGE}
          placement={TooltipPlacement.RIGHT}
          style={{
            fontSize: '.75rem',
            lineHeight: '1.25rem',
          }}
          title={fields[id].tooltipTitle}
        >
          <Icon color={colors.colorGreyDarkHex} icon={IconNames.INFO_SIGN} size={sizes.iconSizes.XX_LARGE} />
        </TooltipMUI>
      )}
    </FieldWrapper>
  );
};

export const QualificationMain = ({ activeView, invalid }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector);
  const currentCompany = useSelector(currentCompanySelector);
  const inviteTeamMemberModal = useSelector(getInviteTeamMemberModal);
  const formValues = useSelector(getFormValues(signupFlowForm));
  const currentMembershipIsRepresentative = useSelector(currentMembershipIsRepresentativeSelector);
  const updatedAllowed = useSelector(currentMembershipVerificationUpdateAllowedSelector);
  const isUpdating = useSelector(isUpdatingMembershipSelector);

  useEffect(
    () => () => {
      if (
        !(formValues?.agreeBusinessRepresentative && formValues?.agreeIdentity) ||
        !currentMembershipIsRepresentative
      ) {
        dispatch(changeRepresentativeLock(true));
      }
    },
    // disabled here 'cause we need to run this only when unmounting the component
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <FlowStepperMain
      containerWidth={containerWidthMap.medium}
      fields={
        <Container>
          {!updatedAllowed && <CannotUpdateFieldHint />}
          <Title>Confirm your identity</Title>
          <FieldsContainer>
            {mapViewToFields[activeView]?.map(({ name, ...props }) => (
              <QualificationField
                currentCompany={currentCompany}
                currentUser={currentUser}
                isDisabled={!updatedAllowed}
                key={name}
                name={name}
                {...props}
              />
            ))}
          </FieldsContainer>
          <InviteTeamMemberModal open={inviteTeamMemberModal} />

          <BaseHint
            intent={Intent.WARNING}
            title={
              <>
                You can&apos;t fill out this information on someone else&apos;s behalf. If you aren&apos;t a business
                {`representative of ${currentCompany.name}, `}
                <HintLink onClick={() => dispatch(toggleInviteTeamMemberModal())} type="button">
                  invite a team member
                </HintLink>
                .
              </>
            }
          />
          <HelperContainer>
            <Accordion {...whatInfoRepresentativeNeed} />
            <Accordion {...howRoutableUseThisInformation} />
          </HelperContainer>
        </Container>
      }
      invalid={invalid || !updatedAllowed}
      onBack={viewSteps[activeView].previous}
      onNext={{
        ...viewSteps[activeView].next,
        isLoading: isUpdating,
      }}
      subTitle={{
        content: 'To verify your company, we need a quick acknowledgement from you.',
      }}
      title={{
        content: 'Please confirm you qualify as a business representative',
      }}
    />
  );
};

export default QualificationMain;

import { createLastUpdatedReducer } from 'store/redux';

import {
  UPDATE_CURRENT_COMPANY_SETTINGS_SUCCESS,
  UPDATE_CURRENT_COMPANY_SETTINGS_REQUEST,
  UPDATE_CURRENT_COMPANY_SETTINGS_FAILURE,
} from 'types/currentCompany';

export const successCases = [UPDATE_CURRENT_COMPANY_SETTINGS_SUCCESS];
export const requestCases = [UPDATE_CURRENT_COMPANY_SETTINGS_REQUEST, UPDATE_CURRENT_COMPANY_SETTINGS_FAILURE];

const lastUpdatedReducer = createLastUpdatedReducer(successCases, requestCases);

export default lastUpdatedReducer;

import {} from '@routable/framework';
import React from 'react';
import { components } from 'react-select';
import { Text } from 'components/text';
import { TooltipMUIConditionalWrapper } from 'components/tooltip';
import { typography } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';
const { Option } = components;
const Colors = {
    Focused: {
        PrimaryLabelColor: 'var(--color-white)',
        SecondaryLabelColor: 'var(--color-white)',
    },
    Disabled: {
        PrimaryLabelColor: 'var(--color-grey50)',
        SecondaryLabelColor: 'var(--color-grey50)',
    },
    Normal: {
        PrimaryLabelColor: 'var(--color-grey70)',
        SecondaryLabelColor: 'var(--color-grey60)',
    },
};
const getFontColors = ({ isDisabled, isFocused }) => {
    if (isDisabled) {
        return Colors.Disabled;
    }
    if (isFocused) {
        return Colors.Focused;
    }
    return Colors.Normal;
};
const OptionWithTooltip = (props) => {
    const { data, label } = props;
    const { tooltipText, secondaryLabel } = data;
    const tooltipProps = tooltipText
        ? {
            arrow: true,
            padding: TooltipPadding.SKINNY,
            placement: TooltipPlacement.RIGHT,
            title: (React.createElement(Text.Regular, { color: "var(--color-grey70)", size: typography.TextSize.LEVEL_100 }, tooltipText)),
        }
        : {};
    const { PrimaryLabelColor, SecondaryLabelColor } = getFontColors(props);
    return (React.createElement(TooltipMUIConditionalWrapper, { tooltipProps: tooltipProps },
        React.createElement(Option, { ...props },
            React.createElement(Text.Semibold, { className: "margin-right--xm", color: PrimaryLabelColor, lineHeight: typography.TextLineHeight.MEDIUM, size: typography.TextSize.LEVEL_100 }, label),
            secondaryLabel && (React.createElement(Text.Regular, { color: SecondaryLabelColor, lineHeight: typography.TextLineHeight.MEDIUM, size: typography.TextSize.LEVEL_100 }, secondaryLabel)))));
};
export default OptionWithTooltip;

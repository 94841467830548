import PropTypes from 'prop-types';
import React from 'react';

import { getClassNames } from 'helpers/ui';

/**
 * Table body component for the invoice generator ledger field tables.
 * @param {ComponentProps} props
 * @param {*} children
 * @return {StatelessComponent}
 */
const InvoiceGeneratorTableBody = ({ children, ...rest }) => (
  <div
    className={getClassNames(rest, {
      'table-row--scroll': true,
    })}
  >
    {children}
  </div>
);

InvoiceGeneratorTableBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

InvoiceGeneratorTableBody.defaultProps = {
  children: undefined,
  className: undefined,
};

export default InvoiceGeneratorTableBody;

export var PaymentSources;
(function (PaymentSources) {
    PaymentSources["BALANCE"] = "balance";
    PaymentSources["BANK"] = "bank";
})(PaymentSources || (PaymentSources = {}));
export var PaymentSourcesLabel;
(function (PaymentSourcesLabel) {
    PaymentSourcesLabel["bank"] = "Bank account";
    PaymentSourcesLabel["balance"] = "Balance";
})(PaymentSourcesLabel || (PaymentSourcesLabel = {}));

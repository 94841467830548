import PropTypes from 'prop-types';
import React from 'react';

import { FieldEditButton } from './components';

/**
 * Adds a EditButton prop to any component passed as a child. The editButton prop contains a button which the field
 * may use to trigger editability. The button doesn't do much by itself; it just acts as a standardization of our edit
 * button style. Implementation, whether something is editable or not, is left up to the child component.
 * @example
 * <FieldEditWrapper>
 *   <Field component={InputField} />
 * </FieldEditWrapper>
 * @param {ChildNode} children
 * @returns {StatelessComponent}
 * @constructor
 */
const FieldEditWrapper = ({ children }) => React.cloneElement(children, { EditButton: FieldEditButton });

FieldEditWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export default FieldEditWrapper;

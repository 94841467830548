import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

export const IntegrationServiceTypes = {
  SERVICE_LEDGER: 'ledger',
};

export const IntegrationSyncingStatusTypes = {
  AUTO_SYNC: 'auto_sync',
  MANUAL_SYNC: 'manual_sync',
  NO: 'no',
};

export const NETSUITE = 'NetSuite';
export const QBO = 'QuickBooks Online';
export const SAGE_INTACCT = 'Sage Intacct';
export const XERO = 'Xero';

export const NETSUITE_AUTH_WELCOME_TEXT = `Enter your ${NETSUITE} login credentials to begin syncing payment and partner information
     between ${PLATFORM_DISPLAY_SHORT_NAME} and ${NETSUITE}. `;

export const NETSUITE_AUTH_LEARN_MORE = `Learn more about ${PLATFORM_DISPLAY_SHORT_NAME}'s ${NETSUITE} integration.`;

export const CONNECTING_TO_INTEGRATION_STORAGE_KEY = 'connectingToIntegration';

import { commonFormFields } from '@routable/shared';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { ItemCurrencyCodeKey } from 'enums/items';
import { mapCurrencyCodeToSelectOption } from 'helpers/currency';
import { isExternalPathAny } from 'helpers/external';
import {} from './useBankAccountReceivingCurrency.types';
export const useBankAccountReceivingCurrency = ({ formName, availableCurrencies, selectedCurrencyCode, partnership, }) => {
    const dispatch = useDispatch();
    const isExternalFlow = isExternalPathAny();
    const defaultCurrencyCodeKey = isExternalFlow ? ItemCurrencyCodeKey.COMPANY : ItemCurrencyCodeKey.PARTNER;
    const partnershipCurrencyCode = partnership?.[defaultCurrencyCodeKey];
    const activeCurrencyCode = selectedCurrencyCode || partnershipCurrencyCode;
    const hasSingleOption = availableCurrencies?.length === 1;
    const options = availableCurrencies?.map(mapCurrencyCodeToSelectOption);
    const tooltipText = hasSingleOption ? `Receiving currency has been set to ${availableCurrencies[0]}` : undefined;
    useEffect(() => {
        dispatch(change(formName, commonFormFields.CURRENCY_CODE, activeCurrencyCode));
    }, [dispatch, formName, activeCurrencyCode]);
    return {
        isLocked: hasSingleOption,
        options,
        tooltipText,
    };
};

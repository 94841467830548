import { useEventSync } from '@routable/framework';
import {} from '@routable/gross-ds';
import React, { memo, useEffect, useState } from 'react';
import { HEADER_EVENT_CAPTION_CHANGE } from './page.header.types';
import { Container, HeaderCaption, LeftHeader, RightHeader } from './page.header.styled';
export const PageHeader = memo(({ mobileMenu, caption, children }) => {
    const [headerCaption, setHeaderCaption] = useState(typeof caption === 'string' ? caption : undefined);
    useEventSync(HEADER_EVENT_CAPTION_CHANGE, (header) => {
        if (header && typeof header === 'string') {
            setHeaderCaption(header);
        }
    });
    useEffect(() => {
        if (typeof caption === 'string') {
            setHeaderCaption(caption);
        }
    }, [caption]);
    return (React.createElement(Container, null,
        React.createElement(LeftHeader, { "data-testid": "leftHeader" },
            mobileMenu,
            headerCaption && React.createElement(HeaderCaption, { "data-testid": "headerCaption" }, headerCaption)),
        React.createElement(RightHeader, { "data-testid": "rightHeader" }, children)));
});

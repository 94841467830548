import React from 'react';

import { isPartnershipMissingDetails } from 'helpers/partnerships';

/**
 * useEffect hook which watches for the component loading state and redux fetching state to mismatch, indicating that
 * a fetch for the partnership was made and is now fulfilled. When the partnership has been matched, close the menu
 * popover and open the modal.
 *
 * If the fetch happened, but the partnership wasn't found, we instruct the user to refresh their ledger. When the
 * ledger is refreshed, open the add or merge modal if the partnership has now been found.
 * @param {boolean} isFetchingPartnership - from redux
 * @param {boolean} isLoading - from component
 * @param {function} onOpen - Dispatched-bound action to open modal
 * @param {function} onOpenResync - Dispatched-bound action to open ledger resync modal
 * @param {Object} partnershipWithContacts
 */
export const useOpenModalAfterPartnershipLoad = ({
  isFetchingPartnership,
  isLoading,
  onOpen,
  onOpenResync,
  partnershipWithContacts,
}) =>
  React.useEffect(() => {
    /*
     * if the component is waiting on the partnership to fetch (isLoading) and the fetch has completed
     * (!isFetchingPartnership)
     */
    if (isLoading && !isFetchingPartnership) {
      // we were able to fetch a partnership
      if (!isPartnershipMissingDetails(partnershipWithContacts)) {
        // We did our best to get the partnership to fill the modal, so open the modal
        onOpen();

        // unable to fetch a partnership, so we probably need to sync the ledger
      } else {
        /**
         * ~Component update flow~
         * 1. PartnershipMatchingButtons: Click add button
         * 2. helpers/components.onMatchPartnership: No partnership, so go fetch it
         * 3. Here, helpers/hooks.useOpenModalAfterPartnershipLoad: Partnership fetch returns with no partnership
         * matching that ledgerRef
         * 4. ForceManualSyncLedgerModal: Open, click sync. Sync succeeds, close modal. Call success param, which is the
         * same function used when clicking the add button in step #1.
         * 5. helpers/components.onMatchPartnership: No partnership, so go fetch it
         * 6. Here: Partnership fetch returns new partnership!
         * 7. Here: close popover, open add partnership modal
         */
        onOpenResync(onOpen);
      }
    }
  }, [isFetchingPartnership, isLoading, onOpen, onOpenResync, partnershipWithContacts, partnershipWithContacts.id]);

import { FundingAccountDirectionText, FundingAccountTypesText } from 'constants/funding';

import { formatDateString } from 'helpers/date';
import { isRoutingNumberMissing } from 'helpers/funding';
import { getMembershipFullName } from 'helpers/memberships';
import { areCreationDetailsDisplayedForBankAccountViewType, getRTBIconNameForLedgerType } from 'helpers/ui';

import AccountingIntegrationSectionItem from 'modules/fundingAccount/bankAccount/components/AccountingIntegrationSection/AccountingIntegrationSectionItem';
import { getAccountNumberTooltipContent } from 'modules/fundingAccount/bankAccount/helpers/text';

export const getBankAccountDetailsData = (fundingInfoBankAccount, options = {}) => {
  const { accountNumber, accountType, institutionName, routingNumber } = fundingInfoBankAccount;

  const { creator, enableCsvImport, fundingAccount, viewType } = options;

  const accountDisplayType = FundingAccountTypesText[accountType];
  const shouldShowCreationDetails = areCreationDetailsDisplayedForBankAccountViewType(viewType);

  // 1. show bank name
  const availableData = [{ key: 'bank', label: 'Bank', value: institutionName }];

  // 2. show type of account
  if (accountDisplayType) {
    availableData.push({
      key: 'accountType',
      label: 'Account type',
      value: accountDisplayType,
    });
  }

  // 3. show routing number
  if (!isRoutingNumberMissing(routingNumber)) {
    availableData.push({
      key: 'routingNumber',
      label: 'Routing number',
      value: routingNumber,
    });
  }

  // 4. show account number
  availableData.push({
    key: 'accountNumber',
    label: 'Account number',
    value: accountNumber,
    tooltipContent: getAccountNumberTooltipContent(fundingInfoBankAccount, viewType),
  });

  // 5. show date added
  if (shouldShowCreationDetails && fundingAccount?.created) {
    const value = formatDateString(fundingAccount.created, 'MMMM DD, YYYY');
    availableData.push({
      key: 'dateAdded',
      label: 'Date added',
      value,
    });
  }

  // 6. show account creator (if on my team)
  if (shouldShowCreationDetails && creator) {
    const value = `${getMembershipFullName(creator)} (my team)`;
    availableData.push({
      key: 'addedBy',
      label: 'Account added by',
      value,
    });
  }

  // 7. Show funding account id
  if (enableCsvImport) {
    availableData.push({
      key: 'bankAccountId',
      label: 'Routable bank account ID',
      value: fundingAccount.id,
    });
  }

  return availableData;
};

export const getFundingAccountDisplayDirection = ({ direction }) => FundingAccountDirectionText[direction];

export const getBankAccountIntegrationData = (
  ledgerAccountPayable = {},
  ledgerAccountReceivable = {},
  ledgerAccount = {},
  ledgerApp = '',
  ledgerName = '',
) => {
  const { name: moneyOutName } = ledgerAccountPayable;
  const { name: moneyInName } = ledgerAccountReceivable;
  const { name: ledgerMatchName } = ledgerAccount;

  const availableData = [];

  if (moneyInName) {
    availableData.push({
      key: 'moneyIn',
      label: 'Money-in account',
      value: moneyInName,
    });
  }

  if (moneyOutName) {
    availableData.push({
      key: 'moneyOut',
      label: 'Money-out account',
      value: moneyOutName,
    });
  }

  if (ledgerMatchName) {
    const itemData = {
      iconName: getRTBIconNameForLedgerType(ledgerApp),
      label: `${ledgerName} match`,
      value: ledgerMatchName,
    };

    availableData.push({
      component: AccountingIntegrationSectionItem,
      data: { item: itemData },
      key: 'bankAccountIntegration',
    });
  }

  return availableData;
};

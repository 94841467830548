import React from 'react';
export const usePreventOverscrollLeft = (scrollableRef) => {
    React.useEffect(() => {
        const current = scrollableRef?.current;
        if (current) {
            current?.addEventListener('wheel', (e) => {
                const delta = e.deltaX;
                if (delta >= 0) {
                    return;
                }
                if (typeof current?.scrollLeft === 'number' && current.scrollLeft <= 0) {
                    e.preventDefault();
                }
            });
        }
    }, [scrollableRef.current]);
};

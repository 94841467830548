export var BulkActionTypes;
(function (BulkActionTypes) {
    BulkActionTypes["APPROVE"] = "Approve";
    BulkActionTypes["APPROVE_AND_SEND"] = "Approve and send";
    BulkActionTypes["EDIT_SEND_DATE"] = "Edit send date";
    BulkActionTypes["SEND"] = "Send";
})(BulkActionTypes || (BulkActionTypes = {}));
export var BulkActionSummarySteps;
(function (BulkActionSummarySteps) {
    BulkActionSummarySteps["INITIAL"] = "initial";
    BulkActionSummarySteps["LOADING"] = "loading";
    BulkActionSummarySteps["CONFIRMATION"] = "confirmation";
})(BulkActionSummarySteps || (BulkActionSummarySteps = {}));

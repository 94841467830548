import {
  fetchPartnershipMembersRoutine,
  fetchSinglePartnershipMemberRoutine,
} from 'actions/routines/partnershipMember';

import { gatherReducerCasesFromRoutines } from 'helpers/routine';

import { createIsFetchingReducer } from 'store/redux';

export const { failureCases, requestCases, successCases } = gatherReducerCasesFromRoutines([
  fetchPartnershipMembersRoutine,
  fetchSinglePartnershipMemberRoutine,
]);

export const finishCases = failureCases.concat(successCases);

export default createIsFetchingReducer(finishCases, requestCases);

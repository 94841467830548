import React from 'react';

import { TableCellText } from 'complexComponents';

/**
 * Empty state for InvoiceListText.
 * @returns {StatelessComponent}
 */
const EmptyState = () => <TableCellText className="font-color--grey--dark--important">Not available</TableCellText>;

export default EmptyState;

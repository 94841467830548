import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';

import { ButtonAppearance, ButtonSize } from 'constants/button';
import { Intent } from 'constants/ui';

import { isEqual } from 'helpers/utility';

/**
 * The button in tables pagination that shows the page count, and jumps to the last page.
 * @param {ComponentProps} props
 * @param {Function} props.onGoToPage
 * @param {number} props.pageCount
 * @param {number} props.pageIndex
 * @return {StatelessComponent}
 */
const PagNavLastPageButton = ({ dataTestId, onGoToPage, pageCount, pageIndex }) => (
  <ButtonV2
    appearance={ButtonAppearance.BORDERLESS}
    dataTestId={dataTestId}
    intent={Intent.NEUTRAL}
    isDisabled={isEqual(pageIndex, pageCount)}
    onClick={(ev) => onGoToPage(pageCount, ev)}
    size={ButtonSize.SMALL}
    type="button"
  >
    {pageCount}
  </ButtonV2>
);

PagNavLastPageButton.propTypes = {
  dataTestId: PropTypes.string,
  onGoToPage: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
};

PagNavLastPageButton.defaultProps = {
  dataTestId: undefined,
};

export default PagNavLastPageButton;

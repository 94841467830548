import React from 'react';

// https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
// https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780

/**
 * useClientRect
 * returns rect values for a DOM node accessed via a ref and the node itself
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
 * @return {Object}
 */
const useClientRect = () => {
  const [rect, setRect] = React.useState({});
  const [node, setNode] = React.useState(undefined);

  const ref = React.useCallback((refNode) => {
    if (refNode) {
      setRect(refNode.getBoundingClientRect());
      setNode(refNode);
    }
  }, []);

  return { node, rect, ref };
};

export default useClientRect;

import validator from 'validator';

import { FundingErrors } from 'constants/error';
import { TagType } from 'constants/ui';
import { SPECIAL_CHARS_VALIDATION_REGEX } from 'constants/validation';

import { areDevtoolsEnabled } from 'helpers/env';
import { checkRoutingNumber } from 'helpers/routingNumbers';
import { capitalize, camelCaseTextToWords } from 'helpers/stringHelpers';
import { isEmptyObject, isEqual } from 'helpers/utility';
import { isValidEmail } from 'helpers/validation';

import { toaster } from 'services/toaster';

/**
 * Helper to check whether the client side / FE validation is disabled
 * @return {boolean}
 */
export const isClientSideValidationDisabled = () => {
  if (areDevtoolsEnabled() && window.routable.debug.disableValidation) {
    return true;
  }

  return false;
};

/**
 * Check whether the error is value (either an array or an object)
 * @param currentError
 * @return {boolean}
 */
export const isErrorValid = (currentError) =>
  !!currentError && typeof currentError === 'object' && Object.keys(currentError).length > 0;

/**
 * Check for errors
 *
 * @param {object} errorState - The current form errorState
 * @param {string} formId - The DOM id of the form to validate [OPTIONAL]
 *
 * returns {bool}
 */
export const hasErrors = (errorState, formId) => {
  // Disable form validation
  if (isClientSideValidationDisabled()) {
    return false;
  }

  let hasError = false;
  let formEl = null;

  // Check if we have the right form in the document
  if (formId && document.forms[formId]) {
    formEl = document.forms[formId];
  }

  Object.keys(errorState).forEach((key) => {
    // skip key if the property is from prototype
    if (
      typeof key !== 'string' ||
      !Object.prototype.hasOwnProperty.call(errorState, key) ||
      key === 'non_field_errors'
    ) {
      return;
    }

    // skip if form given and key not in form
    if (key && formEl && formEl.querySelectorAll(`[name="${key}"]`).length === 0) {
      return;
    }

    if (errorState && typeof errorState[key] === 'object' && errorState[key].length > 0) {
      hasError = true;
    }
  });

  return hasError;
};

/**
 * Field error generators
 * Generic functions generating specific field type errors
 *
 * @param {object} element - The DOM element of the form to validate
 *
 * returns {array} - array of errors to display for this field type
 */
export const fieldErrorModule = {
  textError(element) {
    const textFieldErrors = [];

    // Validate max length
    if (
      element.value &&
      element.getAttribute('maxlength') &&
      element.value.length > element.getAttribute('maxlength')
    ) {
      textFieldErrors.push(
        `Value too long! The maximum number of characters allowed is: ${element.getAttribute('maxlength')}`,
      );
    }

    return textFieldErrors;
  },

  slugError(element) {
    const slugFieldErrors = [];
    const regex = /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/;

    if (element.value && !regex.test(element.value)) {
      slugFieldErrors.push('Invalid value! Only letters, numbers and hyphen allowed. (e.g. abc-123)');
    }

    return slugFieldErrors;
  },

  specialCharError(element) {
    const specialCharErrors = [];
    const regExp = new RegExp(SPECIAL_CHARS_VALIDATION_REGEX);

    if (element.value && element.value.match(regExp)) {
      specialCharErrors.push('Please remove any invalid characters highlighted in red');
    }

    return specialCharErrors;
  },

  numberError(element) {
    const numberFieldErrors = [];

    // Validate numeric
    if (element.value && (Number.isNaN(parseFloat(element.value)) || !Number.isFinite(element.value))) {
      numberFieldErrors.push('The value provided is not numeric!');
      return numberFieldErrors;
    }

    // Validate max
    if (element.max && parseFloat(element.value) && parseFloat(element.value) > parseFloat(element.max)) {
      numberFieldErrors.push(`Value too large! The maximum value allowed is: ${element.max}`);
    }

    // Validate min
    if (
      element.min &&
      (parseFloat(element.value) || parseFloat(element.value) === 0) &&
      parseFloat(element.value) < parseFloat(element.min)
    ) {
      numberFieldErrors.push(`Value too small! The minimum value allowed is: ${element.min}`);
    }

    return numberFieldErrors;
  },

  amountError(element) {
    const amountFieldErrors = [];

    // Validate max two decimals + currency formatting
    if (element.value && !validator.isCurrency(element.value)) {
      amountFieldErrors.push('Invalid amount format! Expecting a number with up to two decimals.');
    }

    // Validate > 0
    const floatValue = parseFloat(element.value.replace('$', ''));

    if (!Number.isNaN(floatValue) && floatValue <= 0) {
      amountFieldErrors.push('Amount has to be greater than zero.');
    }

    // Validate max
    if (element.max && floatValue && floatValue > parseFloat(element.max)) {
      amountFieldErrors.push(`Value too large! The maximum value allowed is: ${element.max}`);
    }

    // Validate min
    if (element.min && floatValue && floatValue < parseFloat(element.min)) {
      amountFieldErrors.push(`Value too small! The minimum value allowed is: ${element.min}`);
    }

    return amountFieldErrors;
  },

  emailError(element) {
    const emailFieldErrors = [];

    if (element.value && !isValidEmail(element.value)) {
      emailFieldErrors.push('Invalid email address');
    }

    return emailFieldErrors;
  },

  phoneError(element) {
    const phoneFieldErrors = [];
    const regex = /^((?=.*[0-9])[-+ ()0-9]){10,20}$/;

    if (element.value && !regex.test(element.value)) {
      phoneFieldErrors.push('Invalid phone number!');
    }

    return phoneFieldErrors;
  },

  ssnError(element) {
    const SSNFieldErrors = [];

    const ssnValue = element.value.replace(/[^0-9]/g, '');
    if (ssnValue.length !== 9) {
      SSNFieldErrors.push('SSN must be a 9-digit number!');
    }

    return SSNFieldErrors;
  },

  einError(element) {
    const EINFieldErrors = [];

    const EINValue = element.value.replace(/[^0-9]/g, '');
    if (EINValue.length !== 9) {
      EINFieldErrors.push('EIN must be a 9-digit number!');
    }

    return EINFieldErrors;
  },

  uuidError(element) {
    const UUIDFieldErrors = [];
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    if (element.value && !regex.test(element.value)) {
      UUIDFieldErrors.push('Invalid UUID!');
    }

    return UUIDFieldErrors;
  },

  bankAccountError(element) {
    const BankAccountFieldErrors = [];
    const regex = /^[0-9]{4,17}$/i;

    if (element.value && !regex.test(element.value)) {
      BankAccountFieldErrors.push('Invalid bank account number!');
    }

    return BankAccountFieldErrors;
  },

  bankRoutingError(element) {
    const BankRoutingFieldErrors = [];

    if (element.value && !checkRoutingNumber(element.value)) {
      BankRoutingFieldErrors.push(FundingErrors.INVALID_ROUTING_NUMBER);
    }

    return BankRoutingFieldErrors;
  },
};

/**
 * Validate individual field
 *
 * @param {object} element - The DOM element of the form to validate
 *
 * returns {array} - array of errors to display for this field
 */
export const validateField = (element) => {
  const isRequiredAndNoValue = (el) => el.required && (!el.value || el.value.trim() === '');

  const isRequiredAndNotChecked = (el) => el.required && !el.checked;

  const customErrors = (el) => {
    const errors = [];

    if (el.getAttribute('data-validator')) {
      const validators = el.getAttribute('data-validator').split(' ');

      validators.forEach((customValidator) => {
        let cErrors = [];

        if (fieldErrorModule[`${customValidator}Error`]) {
          cErrors = fieldErrorModule[`${customValidator}Error`](el);
        }

        if (cErrors.length > 0) {
          errors.push(...cErrors);
        }
      });
    }

    return errors;
  };

  const fieldErrors = [];

  // Skip validation if disabled!
  if (element.disabled) {
    return fieldErrors;
  }

  let errors = [];
  // Basic input validations
  switch (element.nodeName) {
    case 'INPUT':
      // Handle required
      if (element.type !== 'checkbox') {
        // Not a checkbox - check the value
        if (isRequiredAndNoValue(element)) {
          fieldErrors.push('Required');
        }
        // checkbox - check 'checked'
      } else if (isRequiredAndNotChecked(element)) {
        fieldErrors.push('Required');
      }

      // Handle validation based on type
      if (fieldErrorModule[`${element.type}Error`]) {
        errors = fieldErrorModule[`${element.type}Error`](element);
      }

      if (errors.length > 0) {
        fieldErrors.push(...errors);
      }

      break;

    case 'SELECT':
      if (isRequiredAndNoValue(element)) {
        fieldErrors.push('Required');
      }
      break;

    case 'TEXTAREA':
      if (isRequiredAndNoValue(element)) {
        fieldErrors.push('Required');
      }
      break;

    default:
      break;
  }

  return [...fieldErrors, ...customErrors(element)];
};

/**
 * Form validation functionality.
 *
 * @param {string} formId - The DOM id of the form to validate
 * @param {object} errorState - The current form errorState [OPTIONAL]
 *
 * returns {bool}
 */
export const validateForm = (formId, errorState = {}) => {
  const errors = { ...errorState };
  const form = document.forms[formId];

  if (!form || typeof form !== 'object' || !errors) {
    return false;
  }

  Array.prototype.forEach.call(form.elements, (element) => {
    if (element.name) {
      errors[element.name] = validateField(element);
    }
  });

  return errors;
};

/**
 * Grabs the text from the associated form field label
 *
 * @param {string} fieldName - The form field name to prettify
 * @param {string} formId - The id of the containing form
 *
 * returns {string}
 */
export const getPrettyFormFieldName = (fieldName, formId) => {
  const form = document.forms[formId];
  const prettyFieldName = capitalize(camelCaseTextToWords(fieldName));

  if (!form) {
    return prettyFieldName;
  }

  const errorField = form.elements[fieldName];

  if (!errorField) {
    return prettyFieldName;
  }

  const label = errorField.parentElement?.querySelector('label');

  if (label && label.innerText) {
    return capitalize(camelCaseTextToWords(label.innerText));
  }

  if (errorField.dataset && errorField.dataset.errname) {
    return capitalize(camelCaseTextToWords(errorField.dataset.errname));
  }

  return prettyFieldName;
};

export const alertAllFormErrors = (errorList, id = undefined) => {
  toaster.danger('Please correct the following errors.', {
    description: errorList,
    id,
  });
};

/**
 * Scrolls to the top of the visible area of the scrollable ancestor of a form that contains
 * one or more errors.
 *
 * @param {object} errors - An object containing all field names within the form and any
 * associated errors
 * @param {string} formId - The id of the containing form
 *
 * returns {bool}
 */
export const scrollToFormError = (errors, formId) => {
  const errorFieldNames = [];
  const form = document.forms[formId];

  if (!errors || !form) {
    return false;
  }

  Object.keys(errors).forEach((errorKey) => {
    const currentError = errors[errorKey];

    if (isErrorValid(currentError)) {
      errorFieldNames.push(errorKey);
    }
  });

  if (errorFieldNames.length > 0) {
    const firstErrorField = form?.elements[errorFieldNames[0]];

    if (firstErrorField?.parentElement?.scrollIntoView) {
      firstErrorField.parentElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  return true;
};

/**
 * validateTagForm
 */
export const validateTagForm = (values) => {
  const errors = {};

  // disable the submit button until the user has selected at least 1 tag
  if (isEmptyObject(values)) {
    errors.tags = 'Something went wrong';
  }

  if (values && values.tags) {
    if (values.tags.some((tag) => isEqual(tag.type, TagType.ERROR))) {
      // this error is not shown to the user but makes reduxForm invalid and disable the button
      errors.tags = 'Something went wrong';
    }
  }

  return errors;
};

import ThunkFetch from 'helpers/thunkFetch';

import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { signupSelectNamespaceEndpoint } from 'services/api/signupEndpoints';

import {
  SUBMIT_SIGNUP_SELECT_NAMESPACE_FAILURE,
  SUBMIT_SIGNUP_SELECT_NAMESPACE_REQUEST,
  SUBMIT_SIGNUP_SELECT_NAMESPACE_SUCCESS,
} from 'types/signup';

const submitSignupSelectNamespace = (submitPayload, options = {}) => {
  const { successCallback } = options;

  const t = new ThunkFetch({
    actions: {
      error: SUBMIT_SIGNUP_SELECT_NAMESPACE_FAILURE,
      request: SUBMIT_SIGNUP_SELECT_NAMESPACE_REQUEST,
      success: SUBMIT_SIGNUP_SELECT_NAMESPACE_SUCCESS,
    },
    endpoint: signupSelectNamespaceEndpoint,
    method: 'POST',
    payload: payloadToJSONAPI(submitPayload, 'membership', 'company'),
    successCallback,
    requireAuth: true,
  });

  return t.makeRequest();
};

export default submitSignupSelectNamespace;

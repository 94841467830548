import { InformationFilled } from '@carbon/icons-react';
import { Tooltip } from '@routable/components';
import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/text';

import { colors, typography } from 'constants/styles';

/**
 * Component responsible for showing the text in Checkbox.
 * @param {Object} props
 * @param {string|FunctionComponent} props.content - text to be shown
 * @param {boolean} props.isChecked
 * @param {boolean} props.isRequired
 * @param {string} props.tooltip
 * @return {StatelessComponent|null}
 */
const CheckboxTitle = ({ content, isChecked, isRequired, tooltip }) => {
  if (!content) {
    return null;
  }

  const textColor = isChecked ? typography.TextColor.GREY_XX_DARK : typography.TextColor.GREY_X_DARK;

  return (
    <div className="checkbox--text-wrapper flex items-center gap-3">
      <Text.Regular color={textColor} size={typography.TextSize.LEVEL_300}>
        {content}
        {!!isRequired && <span className="required-asterisk">*</span>}
      </Text.Regular>
      {!!tooltip && (
        <Tooltip data-testid="tooltip--checkbox-info" tooltip={tooltip} variant="light">
          <InformationFilled aria-hidden={false} data-testid="checkbox--tooltip-icon" fill={colors.colorMainJordan} />
        </Tooltip>
      )}
    </div>
  );
};

CheckboxTitle.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isChecked: PropTypes.bool,
  isRequired: PropTypes.bool,
  tooltip: PropTypes.node,
};

CheckboxTitle.defaultProps = {
  content: undefined,
  isChecked: undefined,
  isRequired: undefined,
  tooltip: undefined,
};

export default CheckboxTitle;

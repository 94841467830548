import {} from 'interfaces/actions';
import {} from 'interfaces/reducers';
import { OPEN_ITEM_HAS_BEEN_UPDATED_SWAL, RESET_ITEM_HAS_BEEN_UPDATED_SWAL } from 'types/item';
export const initialState = {
    itemsWithUpdatedVersion: {},
};
const itemEditAlertsReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_ITEM_HAS_BEEN_UPDATED_SWAL: {
            return {
                ...state,
                itemsWithUpdatedVersion: {
                    ...state.itemsWithUpdatedVersion,
                    [action.payload.itemId]: true,
                },
            };
        }
        case RESET_ITEM_HAS_BEEN_UPDATED_SWAL: {
            return {
                ...state,
                itemsWithUpdatedVersion: {
                    ...state.itemsWithUpdatedVersion,
                    [action.payload.itemId]: false,
                },
            };
        }
        default:
            return state;
    }
};
export default itemEditAlertsReducer;

import { z } from 'zod';
export var DateFormats;
(function (DateFormats) {
    DateFormats["FULL_NUMERIC_YEAR_MONTH_DAY"] = "YYYY-MM-DD";
    DateFormats["FULL_NUMERIC_MONTH_DAY_YEAR_SLASHED"] = "MM/DD/YYYY";
    DateFormats["LOCAL_TIME_AM_PM_TIMEZONE"] = "LT z";
    DateFormats["LOCAL_DATE_SHORT"] = "ll";
    DateFormats["LOCAL_DATE_SHORT_WITH_TIME"] = "ll [at] h:mm a";
    DateFormats["LOCAL_DATE_ORDINAL_SHORT_WITH_TIME"] = "MMM Do YYYY [at] h:mm a";
    DateFormats["LOCAL_TIME_AM_PM"] = "LT";
    DateFormats["SHORT_ALPHA_MONTH"] = "MMM DD";
    DateFormats["SHORT_ALPHA_MONTH_FULL_YEAR"] = "MMM DD, YYYY";
    DateFormats["ALPHA_MONTH_FULL_YEAR"] = "MMMM DD, YYYY";
    DateFormats["SHORT_MONTH_SHORT_DATE_FULL_YEAR"] = "MMM D, YYYY";
    DateFormats["SLASH_DATE_TIME"] = "MM/DD/YYYY hh:mma";
    DateFormats["SLASH_DATE_SHORT"] = "MM/DD/YY";
    DateFormats["SLASH_DATE_TIME_TIMEZONE"] = "MM/DD/YYYY hh:mma z";
})(DateFormats || (DateFormats = {}));
export const dateFormatModel = z.nativeEnum(DateFormats);
export var DayjsPrecisionValues;
(function (DayjsPrecisionValues) {
    DayjsPrecisionValues["DAY"] = "day";
    DayjsPrecisionValues["HOUR"] = "hour";
    DayjsPrecisionValues["MINUTE"] = "minute";
    DayjsPrecisionValues["MONTH"] = "month";
    DayjsPrecisionValues["SECOND"] = "second";
    DayjsPrecisionValues["YEAR"] = "year";
    DayjsPrecisionValues["WEEK"] = "week";
    DayjsPrecisionValues["QUARTER"] = "quarter";
    DayjsPrecisionValues["MILISECOND"] = "milisecond";
})(DayjsPrecisionValues || (DayjsPrecisionValues = {}));
export const dayjsPrecisionValuesModel = z.nativeEnum(DayjsPrecisionValues);

import { companyMailingAddressInfoRelationship } from 'constants/relationships';

import { REST } from 'helpers/api';
import { getLastPathSegment } from 'helpers/urls';

import { payloadToJSONAPI } from 'services/api/formatHelpers';
import {
  getSubmitPartnershipRequestCompanyInfoEndpoint,
  getSubmitPartnershipRequestMembershipInfoEndpoint,
  getSubmitPartnershipRequestTOSEndpoint,
} from 'services/api/partnershipsRequestEndpoints';

/**
 * Fetch a partnership request details based on the PartnershipRequest ID in the
 * current path.
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchPartnershipRequestDetails = async (options = {}) => {
  const config = {
    ...options,
    endpoint: `/partnership_requests/${getLastPathSegment()}/`,
  };

  return REST.get(config);
};

/**
 * Patch a external partnership partner company contact info
 * @param {object} options
 * @param {object} options.partnershipRequest
 * @param {object} options.payload
 * @return {Promise<*>}
 */
export const patchPartnershipRequestContactInfo = async ({ partnershipRequest, payload }) => {
  const endpoint = getSubmitPartnershipRequestMembershipInfoEndpoint(partnershipRequest.id);
  const normalizedPayload = payloadToJSONAPI(payload, 'membership', 'user');

  const config = {
    endpoint,
    payload: normalizedPayload,
    requireAuth: true,
  };

  return REST.patch(config);
};

/**
 * PATCH a external partnership partner company general info
 * @param {object} options
 * @param {object} options.partnershipRequest
 * @param {object} options.payload
 * @return {Promise<*>}
 */
export const patchPartnershipRequestCompanyInfo = async ({ partnershipRequest, payload }) => {
  const endpoint = getSubmitPartnershipRequestCompanyInfoEndpoint(partnershipRequest.id);
  const normalizedPayload = payloadToJSONAPI(payload, ...companyMailingAddressInfoRelationship);

  const config = {
    endpoint,
    payload: normalizedPayload,
    requireAuth: true,
  };

  return REST.patch(config);
};

/**
 * Makes PATCH request to submit Terms of service agreement
 * @param {Object} opts
 * @param {PartnershipRequest} opts.partnershipRequest
 * @return {Promise<*>}
 */
export const patchPartnershipTOS = async ({ partnershipRequest }) => {
  const endpoint = getSubmitPartnershipRequestTOSEndpoint(partnershipRequest.id);
  const rawPayload = {
    id: partnershipRequest.company,
    meta: {
      tosAgree: true,
    },
  };
  const config = {
    endpoint,
    payload: payloadToJSONAPI(rawPayload, 'company'),
    requireAuth: true,
  };

  return REST.post(config);
};

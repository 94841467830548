import React, { forwardRef } from 'react';
import { StyledBadgeFilled, StyledBadgeOutlined, StyledBadgeDefault } from './Badge.styled';
import {} from './Badge.types';
export const Badge = forwardRef((props, ref) => {
    switch (props.variant) {
        case 'filled':
            return React.createElement(StyledBadgeFilled, { ref: ref }, props.children);
        case 'outlined':
            return React.createElement(StyledBadgeOutlined, { ref: ref }, props.children);
        case 'default':
        default:
            return React.createElement(StyledBadgeDefault, { ref: ref }, props.children);
    }
});

import React from 'react';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import EditButtonInput from 'components/form/EditButtonInput';

import { digitsOnly } from 'helpers/fieldNormalizers';
import { TINValidator, requiredValidator } from 'helpers/fieldValidation';
import { formatAsEIN } from 'helpers/numbers';

/**
 * Input field for editing/viewing the tax EIN.
 * @param {object} props
 * @returns {StatelessComponent}
 * @constructor
 */
const EINInput = (props) => {
  const einValidator = React.useMemo(() => TINValidator('EIN'), []);

  return (
    <EditButtonInput
      {...props}
      format={formatAsEIN}
      maxLength={10}
      normalize={digitsOnly}
      placeholder="Business EIN"
      staticValue="••-•••••••"
      type="text"
      validate={[requiredValidator, einValidator]}
    />
  );
};

export default EINInput;

import classNames from 'classnames';
import React from 'react';
import './NumberChip.scss';
const NumberChip = ({ children, className }) => (React.createElement("span", { className: classNames('number-chip', className) }, children));
const defaultProps = {
    children: undefined,
    className: undefined,
};
NumberChip.defaultProps = defaultProps;
export default NumberChip;

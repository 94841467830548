import { createIsFetchingReducer } from 'store/redux';

import {
  FETCH_INTEGRATION_CONFIG_FAILURE,
  FETCH_INTEGRATION_CONFIG_REQUEST,
  FETCH_INTEGRATION_CONFIG_SUCCESS,
  FETCH_INTEGRATION_CONFIGS_FAILURE,
  FETCH_INTEGRATION_CONFIGS_REQUEST,
  FETCH_INTEGRATION_CONFIGS_SUCCESS,
} from 'types/integrations';

export const finishCases = [
  FETCH_INTEGRATION_CONFIG_FAILURE,
  FETCH_INTEGRATION_CONFIG_SUCCESS,
  FETCH_INTEGRATION_CONFIGS_FAILURE,
  FETCH_INTEGRATION_CONFIGS_SUCCESS,
];

export const requestCases = [FETCH_INTEGRATION_CONFIG_REQUEST, FETCH_INTEGRATION_CONFIGS_REQUEST];

export default createIsFetchingReducer(finishCases, requestCases);

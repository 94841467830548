import { MemberAccessProps } from 'data/resources/member';

import { isPartnershipMemberDefaultGeneralAccessSet } from 'helpers/partnershipMembers';

/**
 * Returns initial form values for sending an update partnership payment method link.
 * @param {Object} options
 * @param {Partnership} options.partnership
 * @param {PartnershipMember[]} options.partnershipMembers
 * @param {Object.<Id, PartnershipMember>} options.partnershipMembersBank
 * @returns {Object}
 */
export const getInitialValuesForSendUpdatePaymentMethodLink = (options = {}) => {
  const { partnership, partnershipMembers, partnershipMembersBank } = options;

  const autoSelectedPartnershipMembers = partnershipMembers
    .filter(isPartnershipMemberDefaultGeneralAccessSet)
    .map((partnershipMember) => ({
      ...partnershipMember,
      [MemberAccessProps.notificationGeneral]: partnershipMember[MemberAccessProps.defaultGeneral],
    }));

  return {
    partnership,
    partnershipMembers: autoSelectedPartnershipMembers,
    meta: {
      partnershipMembers: partnershipMembersBank,
    },
    ui: {
      selectedCompany: partnership,
    },
  };
};

import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2, FormFieldLabel, Hint, HintTitle, IconNames } from 'components';

import { ButtonSize } from 'constants/button';
import { sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

import {
  convertBusinessAddressToFundingAccountButtonText,
  convertBusinessAddressToFundingAccountHintTitle,
} from 'modules/address/constants/text';
import Address from 'modules/address/presenters/Address';
import { IsLoadingComponent } from 'modules/isLoading/IsLoading';

const ConvertAddressToFundingAccount = (props) => {
  // Hide if we already have a funding address
  const {
    companyMailingAddress,
    currentCompany,
    fundingAccountsWithAddressFundingSources,
    isSubmitting,
    lastSubmitted,
    onFundingAccountAddressSelected,
  } = props;

  if (fundingAccountsWithAddressFundingSources.length > 0 || lastSubmitted) {
    return null;
  }

  if (isSubmitting) {
    return <IsLoadingComponent hasShadow text="Loading your addresses" />;
  }

  const convertAddress = {
    printCompany: currentCompany.name,
    ...companyMailingAddress,
  };

  return (
    <React.Fragment>
      <FormFieldLabel>Mail to:</FormFieldLabel>

      <div className="panel">
        <div className="panel-body">
          <Address {...convertAddress} />

          <div className="margin-top--m">
            <Hint intent={Intent.INFO} multiline>
              <HintTitle>{convertBusinessAddressToFundingAccountHintTitle}</HintTitle>

              <ButtonV2
                className="margin-top--m"
                leftIconClassName="margin-right--sm"
                leftIconName={IconNames.TICK}
                leftIconSize={sizes.iconSizes.SMALL}
                onClick={() => onFundingAccountAddressSelected(convertAddress)}
                size={ButtonSize.SMALL}
                type="button"
              >
                {convertBusinessAddressToFundingAccountButtonText}
              </ButtonV2>
            </Hint>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ConvertAddressToFundingAccount.propTypes = {
  companyMailingAddress: PropTypes.shape(),
  currentCompany: PropTypes.shape().isRequired,
  fundingAccountsWithAddressFundingSources: PropTypes.arrayOf(PropTypes.shape()),
  isSubmitting: PropTypes.bool,
  lastSubmitted: PropTypes.instanceOf(Date),
  onFundingAccountAddressSelected: PropTypes.func.isRequired,
};

ConvertAddressToFundingAccount.defaultProps = {
  companyMailingAddress: {},
  fundingAccountsWithAddressFundingSources: [],
  isSubmitting: undefined,
  lastSubmitted: undefined,
};

export default ConvertAddressToFundingAccount;

import React from 'react';
import { useSelector } from 'react-redux';
import { TableDataRow, TableHeaderRow } from 'components/table';
import { currentCompanyRepresentativesSelector } from 'selectors/currentCompanySelectors';
import TeamMemberDeactivateCellContainer from '../ViewTeamMembersModal/ActiveMembersTable/TeamMemberDeactivateCell';
import CompanyRepTableName from './CompanyRepTableName';
import CompanyRepTableStatus from './CompanyRepTableStatus';
import { TableWrapper } from './RepresentativesTable.styles';
export const TableData = {
    name: {
        baseClassName: 'table-row--column',
        className: 'table-cell--width-30',
        displayName: 'Name',
        obj: CompanyRepTableName,
    },
    status: {
        baseClassName: 'table-row--column',
        className: 'table-cell--width-15',
        displayName: 'Status',
        obj: CompanyRepTableStatus,
    },
    action: {
        baseClassName: 'table-row--column',
        className: 'table-cell--width-5',
        displayName: ' ',
        obj: TeamMemberDeactivateCellContainer,
    },
};
const RepresentativesTable = () => {
    const currentCompanyRepresentatives = useSelector(currentCompanyRepresentativesSelector);
    if (!currentCompanyRepresentatives?.length) {
        return null;
    }
    return (React.createElement(TableWrapper, null,
        React.createElement("ul", { className: "platform-table--inline" },
            React.createElement(TableHeaderRow, { data: TableData }),
            React.createElement("div", null, currentCompanyRepresentatives.map((membership) => (React.createElement(TableDataRow, { data: TableData, key: membership.id, membership: membership, rowClassName: "table-row--container disable-hover", ...membership })))))));
};
export default RepresentativesTable;

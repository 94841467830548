import React from 'react';
import { Field } from 'redux-form';
import { InputField, ReduxFormFieldRenderInput } from 'components';
export const MaskedField = ({ dataTestId, isMasked, setIsMasked, isDisabled, isLocked, mask, placeholder, ...props }) => {
    const [focus, setFocus] = React.useState(false);
    if (isMasked) {
        return (React.createElement(InputField, { dataFullStory: true, dataTestId: `mask-${dataTestId}`, isDisabled: isDisabled, isLocked: isLocked || isDisabled, name: "masked-field", onFocus: () => {
                setIsMasked(false);
                setFocus(true);
            }, placeholder: placeholder, readOnly: true, value: mask }));
    }
    return (React.createElement(Field, { autoFocus: focus, component: ReduxFormFieldRenderInput, dataFullStory: true, dataTestId: dataTestId, isDisabled: isDisabled, isLocked: isLocked || isDisabled, onBlur: () => setFocus(false), placeholder: placeholder, type: "text", ...props }));
};

import styled from 'styled-components';
import { BaseHint } from 'components';
import { containerWidthMap } from 'modules/signup-v3/constants/container';
import { Label } from '../FlowStepperMain.styles';
export const RadioFieldWrapper = styled.div `
  display: flex;
  margin-bottom: 1.5rem;
`;
export const Fields = styled.div `
  display: flex;
  gap: 1rem;
`;
export const FieldWrapper = styled.div `
  width: 50%;
  max-width: ${containerWidthMap.small};
`;
export const FieldLabel = styled(Label) `
  margin-bottom: 0.825rem;
`;
export const PassportHint = styled(BaseHint) `
  margin-top: 2rem;
`;
export const HelperWrapper = styled.div `
  margin-top: 1.188rem;
`;

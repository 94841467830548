import PropTypes from 'prop-types';
import React from 'react';

import { EntityWidget } from 'components';

import { roleCardConfigProp, RoleCardEntityWidgetBody, RoleCardEntityWidgetHeader } from './components';

/**
 * An EntityWidget which shows a summary of a Role, including pricing information and the PermissionGroups/Permissions
 * assigned to each Role.
 *
 * @param {ComponentProps} props
 * @param {BillingCodeData} props.billingCode
 * @param {StringMaybe} [props.className]
 * @param {RoleCardConfig} props.roleCardConfig
 * @param {Role} props.role
 * @param {boolean} [props.startPermissionListsOpen]
 * @returns {StatelessComponent}
 */
const RoleCardEntityWidget = ({ billingCode, className, roleCardConfig, role, startPermissionListsOpen }) => (
  <EntityWidget
    body={
      <RoleCardEntityWidgetBody roleCardConfig={roleCardConfig} startPermissionListsOpen={startPermissionListsOpen} />
    }
    className={className}
    header={<RoleCardEntityWidgetHeader billingCode={billingCode} roleName={role.name} />}
    isCollapsible={false}
  />
);

RoleCardEntityWidget.propTypes = {
  billingCode: PropTypes.shape({
    rate: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  roleCardConfig: roleCardConfigProp.isRequired,
  role: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  startPermissionListsOpen: PropTypes.bool,
};

RoleCardEntityWidget.defaultProps = {
  className: undefined,
  startPermissionListsOpen: undefined,
};

export default RoleCardEntityWidget;

import { Edit, TrashCan } from '@carbon/icons-react';
import { Menu } from '@routable/gross-ds';
import React from 'react';
import {} from './ActiveContactActionsMenu.types';
const ActiveContactActionsMenu = ({ canArchive, canEdit, isArchiveDisabled, onArchive, onEdit, }) => {
    return (React.createElement(Menu, { buttonVariant: "floating", placement: "bottom" },
        React.createElement(Menu.Item, { disabled: !canEdit.hasPermission, onAction: () => onEdit(), tooltip: canEdit.tooltipProps },
            React.createElement(Edit, null),
            "Edit contact"),
        React.createElement(Menu.Item, { disabled: isArchiveDisabled || !canArchive.hasPermission, onAction: () => onArchive(), tooltip: canArchive.tooltipProps },
            React.createElement(TrashCan, null),
            "Archive contact")));
};
export default ActiveContactActionsMenu;

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate file. Until it is removed per the above ticket,
 * changes made to it also need to be made in packages/shared.
 */

export const END_OF_LINE_REGEX = /(?:\r\n|\r)/g;

/**
 * Convert thread message body to ensure the @ mention string is something the BE can use.
 * @const {RegExp}
 * @see removeNamesFromMentionsInText
 */
export const REMOVE_NAMES_FROM_MENTIONS_IN_TEXT_REGEX =
  /(\[mention:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}])(\((.*?)\))/gi;

/**
 * Matches a date string like this: 01/01/2001
 * @type {RegExp}
 */
export const DATE = /\d{1,2}\/\d{1,2}\/(\d{2}|\d{4})/i;

/**
 * Matches the same as DATE, but with no other string contents.
 * @type {RegExp}
 */
export const DATE_ONLY = new RegExp(`^${DATE.source}$`, 'i');

/**
 * Matches a date string like this: 2001-01-01
 * @type {RegExp}
 */
export const ISO_DATE = /\d{4}-\d{2}-\d{2}/i;

/**
 * Matches the same as ISO_DATE, but with no other string contents.
 * @type {RegExp}
 */
export const ISO_DATE_ONLY = new RegExp(`^${ISO_DATE.source}$`, 'i');

/**
 * Matches a time string like any of:
 * 11:00am
 * 1:00:30PM
 * 15:00
 * (any combo of hours + min, maybe seconds, maybe meridian)
 * @type {RegExp}
 */
export const TIME = /(\d{1,2}):(\d{2})(:(\d{2}))?\s?([ap]m)?/i;

/**
 * Matches the same as TIME, but with no other string contents.
 * @return {RegExp}
 */
export const TIME_ONLY = new RegExp(`^${TIME.source}$`, 'i');

/**
 * Matches a datetime string like any of:
 * 01/01/2001 11:00am
 * 01/01/2001 11:00PM
 * 01/01/2001 15:00
 * @type {RegExp}
 */
export const DATE_TIME = new RegExp(`^${DATE.source} ${TIME.source}$`, 'i');

/**
 * Matches script tags and its content
 * @type {RegExp}
 */
export const HTML_SCRIPT_CONTENT_REGEX = /<script\b[^<]*(?:(?!<\/script\s*>)<[^<]*)*<\/script\s*>/gi;

/**
 * Matches style tags and its content
 * @type {RegExp}
 */
export const HTML_STYLE_CONTENT_REGEX = /<style\b[^<]*(?:(?!<\/style\s*>)<[^<]*)*<\/style\s*>/g;

/**
 * Matches the style attribute of a html element
 * @type {RegExp}
 */
export const HTML_ELEMENT_STYLE_ATTRIBUTE_REGEX = /\s+style="[^"]*"/g;

/**
 * Matches a URL string
 * @type {RegExp}
 */
export const URL =
  // eslint-disable-next-line max-len
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

/**
 * Matches any numeric string
 * @type {RegExp}
 */
export const NUMERIC = /^[0-9]*$/;

/**
 * Matches any alphanumeric string
 * @type {RegExp}
 */
export const ALPHANUMERIC = /^[a-zA-Z0-9]*$/;

/**
 * Matches a currency string followed by a currency code
 * i.e. 1,500,000.00 USD
 * @type {RegExp}
 */
export const AMOUNT_WITH_CURRENCY = /\d([,.]\d{1,3})*\s([A-Z]{3})/;

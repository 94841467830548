import { hasProperty } from 'helpers/objects';
import { allValues } from 'helpers/utility';

export function mapResponseToMembership(response) {
  if (!hasProperty(response, 'membership')) {
    return [];
  }
  return allValues(response.membership).map((membership) => ({
    id: membership.id,
    companyName: response.company[membership.relationships.company.data.id].attributes.name,
    companyNamespace:
      response.company[membership.relationships.company.data.id].attributes.namespace ?? '[no team url]',
    isSSORequired: response.company[membership.relationships.company.data.id].attributes.isSsoRequired,
  }));
}

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { IconNames } from 'components/icon';
// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { ItemDetailBlock } from 'components/itemBlock';

import { getCustomerOrVendorTextByPartnership, isPartnershipCustomer } from 'helpers/partnerships';
import { getCompanyRouteByPartnershipId } from 'helpers/routeHelpers';
import { capitalize } from 'helpers/stringHelpers';

import { itemSelector } from 'selectors/itemsSelectors';
import { partnershipSelector } from 'selectors/partnershipsSelectors';

const ItemBlockPartnerCompany = ({ location, onClose, partnership }) => {
  if (!partnership) {
    return null;
  }

  const iconName = isPartnershipCustomer(partnership) ? IconNames.BRIEFCASE : IconNames.SHOP;
  const tooltipText = `${capitalize(getCustomerOrVendorTextByPartnership(partnership))} name`;
  const linkTo = getCompanyRouteByPartnershipId(partnership.id);

  return (
    <ItemDetailBlock
      icon={iconName}
      linkTo={linkTo}
      location={location}
      onClose={onClose}
      text={partnership.name}
      tooltipText={tooltipText}
    />
  );
};

ItemBlockPartnerCompany.propTypes = {
  // We use this in the container, but not the presenter
  // eslint-disable-next-line react/no-unused-prop-types
  item: PropTypes.shape(),
  location: PropTypes.shape().isRequired,
  onClose: PropTypes.func,
  partnership: PropTypes.shape(),
};

ItemBlockPartnerCompany.defaultProps = {
  item: undefined,
  partnership: undefined,
};

const mapStateToProps = (state, ownProps) => {
  const item = ownProps?.item ?? itemSelector(state, ownProps.match.params.id);

  return {
    partnership: item ? partnershipSelector(state, item.partnership) : undefined,
  };
};

const enhance = compose(withRouter, connect(mapStateToProps));

export { ItemBlockPartnerCompany };
export default enhance(ItemBlockPartnerCompany);

import { PaymentDeliveryMethodType } from '@routable/shared';

import { formNamesItem, formNamesPartnership } from 'constants/forms';

export const PartnershipCreateActions = {
  ADD: 'add',
  INVITE: 'invite',
};

/**
 * @enum {string}
 */
export const PartnershipTypes = {
  COMPANY: 'company', // generic
  CUSTOMER: 'customer',
  VENDOR: 'vendor',
};

export const PartnershipLedgerInfoKeyBase = {
  LEDGER_LINK: 'ledgerLink',
  LEDGER_NAME: 'ledgerName',
  REF: 'Ref',
};

/**
 * @enum {string}
 */
export const ResendPartnershipInviteActionsStatus = {
  FAILURE: 'failure',
  SUBMITTED: 'submitted',
  SUBMITTING: 'submitting',
};

export const SendPartnershipUpdatePaymentMethodLinkActionsStatus = {
  FAILURE: 'failure',
  REQUEST: 'request',
  SUCCESS: 'success',
};

export const PartnershipPaymentMethodsText = {
  [PaymentDeliveryMethodType.ACH]: 'Bank Transfer',
  [PaymentDeliveryMethodType.ANY]: 'Pending invite',
  [PaymentDeliveryMethodType.CHECK]: 'Check (Standard)',
  [PaymentDeliveryMethodType.INTERNATIONAL]: 'Bank Transfer',
};

export const PartnershipEmailWarning = {
  ASSOCIATED_TO_COMPANIES: 'ASSOCIATED_TO_COMPANIES',
};

export const PartnershipAPIStyleParameters = {
  COUNTS: 'counts',
  INVITES: 'invites',
  TAX: 'tax',
};

/**
 * @enum {string}
 */
export const PartnershipPartnerStatus = {
  ACCEPTED: 'accepted',
  ADDED: 'added',
  INVITED: 'invited',
};

/**
 * 'source' param Mapping for PartnershipSearch API
 */
export const PartnershipSearchSourceParamValue = {
  [formNamesItem.CREATE_ITEM]: 'create_item',
  [formNamesPartnership.CREATE]: 'create_partnership',
};

/**
 * success message string for resend partnership invitation action
 */
export const PartnershipResendReminderSuccessText = 'Invite sent!';

/**
 * success message string for export both taxes: tax1099 and track1099
 */
export const ExportTaxInfoSuccessText =
  "We're processing your export request. We'll send you an email as soon as it's complete.";

import { deactivateTeamMemberModalRoutine } from 'actions/routines/modal';

/**
 * Reducer to track the membership we are disabling in in deactivateTeamMemberModal.
 * @param {StringMaybe} state
 * @param {ReduxAction} action
 * @returns {StringMaybe}
 */
const membershipReducer = (state = null, action) => {
  switch (action.type) {
    case deactivateTeamMemberModalRoutine.TRIGGER:
      return action.payload.membership;

    default:
      return state;
  }
};

export default membershipReducer;

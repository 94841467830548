import PropTypes from 'prop-types';
import React from 'react';

import { ApprovalLevelApproverStatus, ApprovalLevelHeader } from './components';

import './ApprovalLevel.scss';

/**
 * ApprovalLevel component
 * Used for example in Approvers section of ThreadDetails
 * @param {ComponentProps} props
 * @param {string} props.itemKind
 * @param {ApprovalLevel} props.approvalLevel
 * @param {object} props.membershipsById
 * @return {StatelessComponent}
 */
const ApprovalLevel = ({ itemKind, approvalLevel: itemSideApprovals, membershipsById }) => {
  // all levelTypes within a level should be the same so we can grab the first one
  const [{ levelPosition, levelType }] = itemSideApprovals;
  return (
    <div className="approval-level">
      <ApprovalLevelHeader levelPosition={levelPosition} levelType={levelType} />
      {itemSideApprovals.map((itemSideApproval) => (
        <ApprovalLevelApproverStatus
          {...itemSideApproval}
          itemKind={itemKind}
          key={itemSideApproval.id}
          membershipsById={membershipsById}
        />
      ))}
    </div>
  );
};

ApprovalLevel.propTypes = {
  approvalLevel: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  membershipsById: PropTypes.shape().isRequired,
  itemKind: PropTypes.string.isRequired,
};

export default ApprovalLevel;

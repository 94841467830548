import { connect } from 'react-redux';

import { createItemFormSelector } from 'selectors/forms';

import DiscountBlock from './DiscountBlock';

const mapStateToProps = (state) => ({
  formUI: createItemFormSelector(state, 'ui'),
});

export default connect(mapStateToProps)(DiscountBlock);

import { Button, Tooltip } from '@routable/gross-ds';
import React from 'react';
import { useHistory, matchPath } from 'react-router-dom';
import { DashboardToDashboardW9RestrictionTooltipContent } from 'components';
import { Hint } from 'components/hint';
import PermissionResourceAction from 'constants/permissions';
import { COMPANIES_ROUTE, SELECTED_COMPANY_TABS } from 'constants/routes';
import { Intent } from 'constants/ui';
import { isPartnershipRoutableToRoutable } from 'helpers/partnerships';
import { getJoinedPath } from 'helpers/routeHelpers';
import { useGrossDsPermissions } from 'hooks';
import { AddTaxFormButton } from 'modules/dashboard/company/companyTaxInfo/components';
const RequestContactForTaxesHint = ({ partnership }) => {
    const history = useHistory();
    const permissions = useGrossDsPermissions([PermissionResourceAction.VENDOR_EDIT]);
    const handleClick = () => {
        const match = matchPath(history.location.pathname, {
            path: `${COMPANIES_ROUTE}/:filter/:id/:tab`,
        });
        const path = getJoinedPath(COMPANIES_ROUTE, match.params.filter, partnership.id, SELECTED_COMPANY_TABS.ABOUT);
        history.push(path);
    };
    const isSelfManaged = isPartnershipRoutableToRoutable(partnership);
    const isButtonDisabled = isSelfManaged || !permissions.hasPermission;
    let tooltipProps;
    if (isSelfManaged) {
        tooltipProps = {
            tooltip: (React.createElement(DashboardToDashboardW9RestrictionTooltipContent, { actionText: " to add contact details and request W-9 info" })),
        };
    }
    else if (!permissions.hasPermission) {
        tooltipProps = permissions.tooltipProps;
    }
    return (React.createElement(Hint, { intent: Intent.WARNING, shouldRemoveBackgroundColor: true },
        React.createElement("span", { className: "text-sm font-semibold text-blue-70 mb-1" }, `You don't have tax information recorded for this vendor`),
        React.createElement("p", { className: "text-blue-70 mb-5" }, "Add a company contact with a reachable email to request a tax form from your vendor."),
        React.createElement("div", { className: "flex-row gap-4" },
            React.createElement(Tooltip, { ...tooltipProps, position: "top", variant: "light" },
                React.createElement(Button, { "data-testid": "go-to-contacts-button", isDisabled: isButtonDisabled, onPress: handleClick, size: "small", variant: "outline" }, "Go to contacts")),
            React.createElement(AddTaxFormButton, null))));
};
export default RequestContactForTaxesHint;

import { FUNDING_ACCOUNT_REQUEST_STATES, PARTNERSHIP_REQUESTS } from 'constants/routes';

/**
 * Returns endpoint used to submit form data to the funding account api
 * @param {string} partnershipRequestsId
 * @param {string} fundingAccountRequestStateId
 * @returns {string}
 */
export const getSubmitPartnershipRequestFundingAccountStateEndpoint = (
  partnershipRequestsId,
  fundingAccountRequestStateId,
) =>
  `/${PARTNERSHIP_REQUESTS}/${partnershipRequestsId}/${FUNDING_ACCOUNT_REQUEST_STATES}/${fundingAccountRequestStateId}/`;

/**
 * Returns endpoint used to submit form data for partner company contact info api
 * @param {Id} partnershipRequestId
 * @returns {string}
 */
export const getSubmitPartnershipRequestCompanyInfoEndpoint = (partnershipRequestId) =>
  `/${PARTNERSHIP_REQUESTS}/${partnershipRequestId}/company/`;

/**
 * Returns endpoint used to submit form data for partner membership contact info api
 * @param {Id} partnershipRequestId
 * @returns {string}
 */
export const getSubmitPartnershipRequestMembershipInfoEndpoint = (partnershipRequestId) =>
  `/${PARTNERSHIP_REQUESTS}/${partnershipRequestId}/membership/`;

/**
 * Returns endpoint used to submit TOS agreement api
 * @param {Id} partnershipRequestId
 * @returns {string}
 */
export const getSubmitPartnershipRequestTOSEndpoint = (partnershipRequestId) =>
  `/${PARTNERSHIP_REQUESTS}/${partnershipRequestId}/tos/`;

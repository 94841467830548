import PropTypes from 'prop-types';
import React from 'react';

import { textHelpers } from 'components/hintTypes/helpers';

import { Intent } from 'constants/ui';

import BaseHint from '../BaseHint';

/**
 * Hint to display when an item cannot be sent in time to make the cutoff.
 * @param {ComponentProps} props
 * @param {String} [props.cutoff]
 * @param {ItemPaymentDeliveryMethod} [props.deliveryMethod]
 * @param {Boolean} [props.isCollapsible]
 * @param {Boolean} [props.isCollapsed]
 * @param {String|Number} [props.itemCount]
 * @param {Object} [props.speed]
 * @param {Object} [props.textProps]
 * @param {String} [props.title]
 * @param {Object} [props.titleProps]
 * @return {StatelessComponent}
 */
const PaymentAfterCutoffHint = ({
  cutoff,
  deliveryMethod,
  isCollapsible,
  isCollapsed,
  isPendingApproval,
  itemCount,
  speed,
  textProps,
  title,
  titleProps,
}) => {
  if (!cutoff) {
    return null;
  }

  const titleText = textHelpers.getPaymentAfterCutoffTitle({
    deliveryMethod,
    itemCount,
    speed,
    title,
  });
  const bodyText = textHelpers.getPaymentAfterCutoffBody({
    cutoff,
    deliveryMethod,
    itemCount,
    speed,
    title,
    isPendingApproval,
  });

  return (
    <BaseHint
      intent={Intent.WARNING}
      isCollapsed={isCollapsed}
      isCollapsible={isCollapsible}
      text={bodyText}
      textProps={textProps}
      title={titleText}
      titleProps={titleProps}
    />
  );
};

PaymentAfterCutoffHint.propTypes = {
  cutoff: PropTypes.string,
  deliveryMethod: PropTypes.string,
  isCollapsible: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  isPendingApproval: PropTypes.bool,
  itemCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  speed: PropTypes.shape(),
  textProps: PropTypes.shape(),
  title: PropTypes.string,
  titleProps: PropTypes.shape(),
};

PaymentAfterCutoffHint.defaultProps = {
  cutoff: undefined,
  deliveryMethod: undefined,
  isCollapsible: undefined,
  isCollapsed: true,
  isPendingApproval: undefined,
  itemCount: undefined,
  speed: undefined,
  textProps: undefined,
  title: undefined,
  titleProps: undefined,
};

export default PaymentAfterCutoffHint;

import { views } from '../constants';

import submitCompanyInfo from './submitCompanyInfo';
import submitForVerification from './submitForVerification';
import submitRepresentativeInfo from './submitRepresentativeInfo';
import { viewSteps } from './viewSteps';

const submitOptions = {
  [views.businessRepresentative]: submitRepresentativeInfo,
  [views.businessVerification]: submitCompanyInfo,
  [views.review]: submitForVerification,
};

const submit = (formValues, dispatch, props) => {
  const { activeView } = props;
  submitOptions[viewSteps[activeView].type](formValues, dispatch, props);
};

export default submit;

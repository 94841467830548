import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';

import * as types from 'types/partnershipMember';

export const createPartnershipMemberRoutine = createRoutine(types.CREATE_PARTNERSHIP_MEMBER);
export const createPartnershipMemberHandler = bindRoutineToReduxForm(createPartnershipMemberRoutine);

export const fetchPartnershipMembersRoutine = createRoutine(types.FETCH_PARTNERSHIP_MEMBERS);

export const fetchSinglePartnershipMemberRoutine = createRoutine(types.FETCH_SINGLE_PARTNERSHIP_MEMBER);

export const updatePartnershipMemberRoutine = createRoutine(types.UPDATE_PARTNERSHIP_MEMBER);
export const updatePartnershipMemberHandler = bindRoutineToReduxForm(updatePartnershipMemberRoutine);

export const removeFormPartnershipMember = createRoutine(types.REMOVE_FORM_PARTNERSHIP_MEMBER);

export const setFormPartnershipMembers = createRoutine(types.SET_FORM_PARTNERSHIP_MEMBERS);

import styled from 'styled-components';
export const Container = styled.div `
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  width: 100%;

  & .table-section:last-child {
    border-right: none !important;
  }
`;
export const TableSection = styled.div `
  // Relative to be able to hold headers
  flex: 1;
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  display: flex;
  border-right: 2px solid var(--color-grey30);
  flex-direction: column;
  border-radius: 0;

  &.table-section-line_items_item_line_status,
  &.table-section-line_items_item_line_actions {
    flex: none;
  }

  &.table-section-line_items_account_po_lines,
  &.table-section-line_items_item_po_lines {
    max-width: calc(50% - 40px);
  }

  &:last-child {
    border-right: none;

    /* This is to center the lineItems action button */
    .ledger-form-field:not(:has(> .po-cell)) {
      justify-content: center;
    }
  }

  &.horizontal-scroll-group {
    border-radius: 0;
  }
`;
export const TableSectionWrap = styled.div `
  flex: 1;
  display: flex;
  border-right: 2px solid var(--color-grey30);
  flex-direction: column;
  border-radius: 0;
  overflow-x: scroll;

  &:last-child {
    border-right: none;
  }

  &.horizontal-scroll-group {
    border-radius: 0;
  }
`;
export const FieldContainer = styled.div `
  padding: 10px;
  display: inline-block;
`;

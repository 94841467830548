import { updateMembershipRoutine } from 'actions/routines/membership';

import { createLastUpdatedReducer } from 'store/redux';

import {
  UPDATE_MEMBERSHIP_FAILURE,
  UPDATE_MEMBERSHIP_REQUEST,
  UPDATE_MEMBERSHIP_SUCCESS,
  UPDATE_ONBOARDING_MEMBERSHIP_FAILURE,
  UPDATE_ONBOARDING_MEMBERSHIP_REQUEST,
  UPDATE_ONBOARDING_MEMBERSHIP_SUCCESS,
} from 'types/memberships';

export const successCases = [
  UPDATE_MEMBERSHIP_SUCCESS,
  updateMembershipRoutine.SUCCESS,
  UPDATE_ONBOARDING_MEMBERSHIP_SUCCESS,
];
export const requestCases = [
  UPDATE_MEMBERSHIP_FAILURE,
  updateMembershipRoutine.FAILURE,
  UPDATE_MEMBERSHIP_REQUEST,
  updateMembershipRoutine.REQUEST,
  UPDATE_ONBOARDING_MEMBERSHIP_FAILURE,
  UPDATE_ONBOARDING_MEMBERSHIP_REQUEST,
];

const lastUpdatedReducer = createLastUpdatedReducer(successCases, requestCases);

export default lastUpdatedReducer;

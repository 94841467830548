import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { DebouncedSelectField } from 'components';

import { LedgerDiscountCalculationTypes } from 'constants/ledger';

import { requiredValidator } from 'helpers/fieldValidation';
import { getAtomicValueForOption, normalizeFullObject } from 'helpers/select';

const discountCalculationTypeOptions = [
  { id: LedgerDiscountCalculationTypes.AMOUNT, label: 'Applied as amount' },
  {
    id: LedgerDiscountCalculationTypes.PERCENTAGE,
    label: 'Applied as percentage',
  },
];

/**
 * Predefined dynamic table component for selecting the discount calculation type (amount/percentage).
 * @return {StatelessComponent}
 */
const DiscountCalculation = ({ name }) => (
  <Field
    className="hidden-label"
    component={DebouncedSelectField}
    displayFieldName="display"
    format={(value) =>
      getAtomicValueForOption(discountCalculationTypeOptions, value, {
        valueKey: 'id',
      })
    }
    isDebounced
    maxLength="100"
    name={name}
    normalize={normalizeFullObject(discountCalculationTypeOptions, 'id')}
    options={discountCalculationTypeOptions}
    validate={requiredValidator}
    valueFieldName="id"
    valueKey="id"
  />
);

DiscountCalculation.propTypes = {
  name: PropTypes.string.isRequired,
};

export default DiscountCalculation;

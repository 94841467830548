import { SubmissionError } from 'redux-form';

import { TinTypes } from 'constants/taxes';

import { isCurrentCompanyTypePersonal } from 'helpers/currentCompany';
import { isExternalPathPay } from 'helpers/external';

/**
 * Helper to get the correct tinType for the PartnerCompanyGeneral form submission
 * @param currentCompany
 * @param formUI
 */
export const getTinTypeForPartnerCompanyGeneralPayload = (currentCompany, formUI) => {
  if (isCurrentCompanyTypePersonal(currentCompany) || formUI.businessFormShouldShowSSN) {
    return TinTypes.SSN;
  }

  return TinTypes.EIN;
};

/**
 * Helper method to dynamically build the PartnerCompanyGeneral payload
 * @param values
 * @param options
 * @returns {*}
 */
export const buildPartnerCompanyGeneralPayload = (values, options) => {
  const { currentCompany = {}, formUI = {} } = options;
  const { company } = values;
  const { info, ...companyValues } = company;

  // Remove company name if submitting for a personal account
  if (isCurrentCompanyTypePersonal(currentCompany)) {
    delete companyValues.name;
  }

  // Remove `info` altogether if on /pay side
  if (isExternalPathPay()) {
    return companyValues;
  }

  // Add info to payload
  companyValues.info = { ...info };

  // Handle tin and tinType
  if (info && info.tin) {
    // Add tinType if submitting tin
    companyValues.info.tinType = getTinTypeForPartnerCompanyGeneralPayload(currentCompany, formUI);
  } else {
    // Remove tin if not updating it
    delete companyValues.info.tin;
  }

  return companyValues;
};

/**
 * Helper method to submit the partner company general form and throw errors on failure
 * @param values
 * @param options
 * @returns {Promise<boolean>}
 */
export const submitPartnerCompanyGeneral = async (values, options = {}) => {
  const { onUpdateOnboardingCompany, submitSuccessCallback } = options;

  const companyValues = buildPartnerCompanyGeneralPayload(values, options);

  const companyResult = await onUpdateOnboardingCompany(companyValues, submitSuccessCallback);

  if (companyResult && companyResult.errors) {
    throw new SubmissionError(companyResult.errors.fields);
  }

  return true;
};

import { useSelector } from 'react-redux';
import { SWIFT_CHARGE_OPTIONS_BILLING_CODES } from 'constants/paymentMethods';
import { formatCurrencyUSD } from 'helpers/currencyFormatter';
import { billingDataByCodeSelector } from 'selectors/billingSelectors';
const defaultOptions = {
    precision: 0,
    symbol: '$',
};
const useSwiftChargeOptionsCost = (options) => {
    const billingDataByCode = useSelector(billingDataByCodeSelector);
    const our = formatCurrencyUSD(billingDataByCode[SWIFT_CHARGE_OPTIONS_BILLING_CODES.our]?.rate, {
        ...defaultOptions,
        ...options,
    });
    const sha = formatCurrencyUSD(billingDataByCode[SWIFT_CHARGE_OPTIONS_BILLING_CODES.sha]?.rate, {
        ...defaultOptions,
        ...options,
    });
    return { our, sha };
};
export default useSwiftChargeOptionsCost;

import React from 'react';
import { ButtonV2, FlexRow, IconNames, Tag, Text } from 'components';
import { ButtonSize } from 'constants/button';
import { colors, sizes, typography } from 'constants/styles';
import { Intent, TagType } from 'constants/ui';
import { ExternalBlock, ExternalBlockItem } from 'modules/external/ExternalBlocksSection';
import {} from './ExternalReceiptPaymentPaginator.types';
import './ExternalReceiptPaymentPaginator.scss';
const ExternalReceiptPaymentPaginator = ({ onGoToPage, onViewAll, canPrevPage, canNextPage, page = 1, pages = 1, }) => (React.createElement(ExternalBlock, null,
    React.createElement(ExternalBlockItem, null,
        React.createElement(FlexRow, { className: "align-items--center justify-content--space-between", stackOnMobile: false },
            React.createElement(Text.Semibold, { size: typography.TextSize.LEVEL_200 }, "Invoice"),
            React.createElement(Tag, { isClosable: false, type: TagType.INFO }, `${page} of ${pages}`),
            React.createElement(FlexRow, { className: "external-receipt-payment-paginator__control", stackOnMobile: false },
                React.createElement(ButtonV2, { intent: Intent.NEUTRAL, isDisabled: !canPrevPage, isIconButton: true, leftIconColor: colors.colorGreyDarkHex, leftIconName: IconNames.ARROW_LEFT, leftIconSize: sizes.iconSizes.LARGE, onClick: () => onGoToPage('PREV'), size: ButtonSize.SMALL, type: "button" }),
                React.createElement(ButtonV2, { intent: Intent.NEUTRAL, isDisabled: !canNextPage, isIconButton: true, leftIconColor: colors.colorGreyDarkHex, leftIconName: IconNames.ARROW_RIGHT, leftIconSize: sizes.iconSizes.LARGE, onClick: () => onGoToPage('NEXT'), size: ButtonSize.SMALL, type: "button" }),
                React.createElement(ButtonV2, { intent: Intent.NEUTRAL, onClick: onViewAll }, "View all"))))));
export default ExternalReceiptPaymentPaginator;

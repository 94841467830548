/**
 * WARNING: This variable will be deprecated.
 * In order to better support international payments this method will be phased out.
 * [DEV-1597] Replace parseCurrency usage
 *
 * Map number format options for parseCurrency
 * @enum {string}
 */
export const CurrencyAmountFormats = {
  NEGATIVE: '(%s%v)',
  POSITIVE: '%s%v',
  ZERO: '%s%v',
};

/**
 * ParseCurrency formatting options, common for balance accounts and negative amounts.
 * */
export const BalanceAccountFormats = {
  neg: CurrencyAmountFormats.NEGATIVE,
  pos: CurrencyAmountFormats.POSITIVE,
  zero: CurrencyAmountFormats.ZERO,
};

/**
 * We're keeping this around because we use USD in most cases for comparisons.
 * @type {string}
 */
export const CurrencyCodeUsd = 'USD';

/**
 * String Enum for Currency Codes that comply with the ISO-4217 standard
 * @enum {string}
 */
export const CurrencyCode = {
  AUD: 'AUD',
  CAD: 'CAD',
  CNY: 'CNY',
  EUR: 'EUR',
  GBP: 'GBP',
  PHP: 'PHP',
  SGD: 'SGD',
  USD: 'USD',
};

/**
 * String Enum of symbols that represents each currency.
 * Currency symbols are not part of ISO 4217
 * @enum {string}
 */
export const CurrencySymbol = {
  [CurrencyCode.AUD]: '$',
  [CurrencyCode.CAD]: '$',
  [CurrencyCode.EUR]: '€',
  [CurrencyCode.GBP]: '£',
  [CurrencyCode.PHP]: '₱',
  [CurrencyCode.SGD]: '$',
  [CurrencyCode.USD]: '$',
};

/**
 * String Enum of Icon Names for each Currency Code.
 * Should get phased out eventually
 * @enum {string}
 */
export const CurrencyIconName = {
  AUD: 'dollar',
  CAD: 'dollar',
  EUR: 'euro',
  SGD: 'dollar',
  USD: 'dollar',
};

/**
 * Map of supported ISO 4217 Currencies
 * We're keeping this around because we use USD in most cases for comparisons.
 * @type {Record<string: CurrencyCodeDetail>}
 */
export const CurrencyCodeMapUsd = {
  [CurrencyCodeUsd]: {
    id: CurrencyCodeUsd,
    currencyCode: CurrencyCodeUsd,
    name: 'United States Dollar',
    symbol: '$',
  },
};

/**
 * In DEV-3277 we started integrating constant data for currencies, but the backend data doesn't
 * support digit precision.  It can easily be added to the BE in the future.
 * @type {number}
 */
export const DefaultCurrencyDigitPrecision = 2;

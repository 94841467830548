import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { isValid as reduxFormIsValid } from 'redux-form';

import { closeConnectBankManualModal } from 'actions/connectBank';

import { IconNames } from 'components/icon';
import { ModalFooter, ModalFooterButton, ModalFooterButtonAllDone, ModalFooterButtonCancel } from 'components/modal';

import { formNamesFunding } from 'constants/forms';
import { colors, sizes } from 'constants/styles';

import { fundingAddManualBank } from 'selectors/forms';
import {
  fundingAccountBankManualIsSubmittingSelector,
  fundingAccountBankManualLastSubmittedSelector,
} from 'selectors/fundingSelectors';

const ConnectBankManualFooter = ({ isSubmitting, isValid, lastSubmitted, onCloseModal }) => {
  if (lastSubmitted) {
    return (
      <ModalFooter>
        <div className="modal-footer--container">
          <div className="content--left">
            <ModalFooterButtonAllDone onCloseModal={onCloseModal} />
          </div>
        </div>
      </ModalFooter>
    );
  }

  return (
    <ModalFooter>
      <div className="modal-footer--container">
        <div className="content--left">
          <ModalFooterButtonCancel onCloseModal={onCloseModal} />
        </div>

        <div className="content--right">
          <ModalFooterButton
            htmlFor={formNamesFunding.ADD_MANUAL_BANK}
            isActionButton
            isDisabled={!isValid}
            isLoading={isSubmitting}
            leftIconColor={colors.colorWhiteHex}
            leftIconName={IconNames.TICK_CIRCLE}
            leftIconSize={sizes.iconSizes.EXTRA_MEDIUM}
            type="submit"
          >
            Add bank account
          </ModalFooterButton>
        </div>
      </div>
    </ModalFooter>
  );
};

ConnectBankManualFooter.propTypes = {
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  lastSubmitted: PropTypes.instanceOf(Date),
  onCloseModal: PropTypes.func.isRequired,
};

ConnectBankManualFooter.defaultProps = {
  isSubmitting: undefined,
  isValid: undefined,
  lastSubmitted: undefined,
};

const mapStateToProps = (state) => ({
  bankName: fundingAddManualBank(state, 'ui.bankInstitutionName'),
  isSubmitting: fundingAccountBankManualIsSubmittingSelector(state),
  isValid: reduxFormIsValid(formNamesFunding.ADD_MANUAL_BANK)(state),
  lastSubmitted: fundingAccountBankManualLastSubmittedSelector(state),
});

const mapDispatchToProps = {
  onCloseModal: closeConnectBankManualModal,
};

export { ConnectBankManualFooter };
export default connect(mapStateToProps, mapDispatchToProps)(ConnectBankManualFooter);

import PropTypes from 'prop-types';
import React from 'react';

import { ExternalContent } from 'complexComponents';

/**
 * ExternalWorkflowContent contains step content for the external workflow.
 * @param {ComponentProps} props
 * @param {Object} props.currentStep
 * @param {RestOfProps} props.rest
 * @returns {StatelessComponent}
 */
const ExternalWorkflowContent = ({ currentStep, ...rest }) => {
  if (!currentStep) {
    return null;
  }

  return <ExternalContent currentStep={currentStep} {...rest} />;
};

ExternalWorkflowContent.propTypes = {
  currentStep: PropTypes.shape({}),
};

ExternalWorkflowContent.defaultProps = {
  currentStep: undefined,
};

export default ExternalWorkflowContent;

import { createIsDeletingReducer } from 'store/redux';

import {
  DELETE_EMAIL_FORWARD_FAILURE,
  DELETE_EMAIL_FORWARD_REQUEST,
  DELETE_EMAIL_FORWARD_SUCCESS,
} from 'types/notifications';

export const finishCases = [DELETE_EMAIL_FORWARD_FAILURE, DELETE_EMAIL_FORWARD_SUCCESS];
export const requestCases = [DELETE_EMAIL_FORWARD_REQUEST];

const deleteReducer = createIsDeletingReducer(finishCases, requestCases);

export default deleteReducer;

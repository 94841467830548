import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParam } from 'react-use';
import { z } from 'zod';
import { useSubmitW8BenMutation, useSubmitW8BenEMutation } from 'ducks/routableApi/partnership';
import { updateCompany } from 'actions/companies';
import { externalOnboardingIncrementStep } from 'actions/externalOnboarding';
import { setW8FormIsSubmittingState, setW8FormIsValidState } from 'actions/partnershipRequests';
import { showErrorUi } from 'actions/ui';
import { TaxFormRenderer, taxCertificationType, taxFormEntryType, taxFormId, taxFormType, } from 'complexComponents';
import { ErrorIndicatorMessages } from 'constants/ui';
import { isCompanyPlatformTypeInInviteStage, isCompanyTypePersonal } from 'helpers/currentCompany';
import { companyFromQuerySelector } from 'queries/companyCompoundSelectors';
import { fundingAccountsForCompanyIdQuerySelector } from 'queries/fundingCompoundSelectors';
import { partnershipRequestMembershipIdSelector } from 'selectors/partnershipRequestSelectors';
import { payloadToCamelCase } from 'services/api/formatHelpers';
import { getDerivedApiFieldErrors } from '../taxForms.helpers';
export const SubstituteW8Form = () => {
    const partnershipId = useSearchParam('partnership_id');
    const [submitW8Ben] = useSubmitW8BenMutation();
    const [submitW8BenE] = useSubmitW8BenEMutation();
    const membershipId = useSelector(partnershipRequestMembershipIdSelector);
    const company = useSelector(companyFromQuerySelector);
    const fundingAccounts = useSelector(fundingAccountsForCompanyIdQuerySelector);
    const dispatch = useDispatch();
    const isPlatformTypeInInviteStage = isCompanyPlatformTypeInInviteStage(company?.platformType);
    const shouldShowPartnerCompanyType = isPlatformTypeInInviteStage && !fundingAccounts?.length;
    const handleFormSubmit = async (values) => {
        try {
            const parsedMembershipId = z.string().uuid().parse(membershipId);
            const parsedPartnershipId = z.string().uuid().parse(partnershipId);
            const submit = isCompanyTypePersonal(values.companyType) ? submitW8Ben : submitW8BenE;
            const data = await submit({
                membershipId: parsedMembershipId,
                partnershipId: parsedPartnershipId,
                values,
            }).unwrap();
            dispatch(updateCompany(data));
            dispatch(externalOnboardingIncrementStep());
            dispatch(setW8FormIsSubmittingState(false));
        }
        catch (err) {
            const errors = getDerivedApiFieldErrors(err);
            if (errors?.general) {
                dispatch(showErrorUi(errors.general.detail));
            }
            else {
                dispatch(showErrorUi(ErrorIndicatorMessages.AN_ERROR_OCCURRED));
            }
            if (errors?.fields) {
                return payloadToCamelCase(errors.fields);
            }
        }
        return null;
    };
    const handleFormValidStateChange = (isValid) => {
        dispatch(setW8FormIsValidState(isValid));
    };
    const handleFormSubmitStateChange = (isSubmitting) => {
        dispatch(setW8FormIsSubmittingState(isSubmitting));
    };
    return (React.createElement(TaxFormRenderer, { defaultValues: {
            certificationType: isCompanyTypePersonal(company?.companyType)
                ? taxCertificationType.Enum.signedElectronically
                : taxCertificationType.Enum.uploadSignedPDF,
            claimingTreatyBenefits: false,
            mailingAddressMatchPermanentAddress: true,
            companyType: company?.companyType,
        }, entryType: taxFormEntryType.Enum.external, formId: taxFormId.Enum.ext_substituteW8Form, formType: taxFormType.Enum.W8, onFormSubmitStateChange: handleFormSubmitStateChange, onFormValidStateChange: handleFormValidStateChange, onSubmit: handleFormSubmit, showCompanyTypeField: shouldShowPartnerCompanyType }));
};

import styled, { css } from 'styled-components';
import { TableHeaderCell } from '../../../Table/table.styled';
export const StyledHeaderCell = styled(TableHeaderCell) `
  align-items: center;
  border-bottom: 1px solid var(--color-grey20);
  display: flex;

  ${(props) => props.isCurrencyField &&
    css `
      justify-content: flex-end;
    `}

  ${(props) => props.isOnDetailsPage &&
    props.isQuantityField &&
    css `
      justify-content: center;
    `}

  .header-cell-text {
    color: var(--neutral-black);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
  }

  &:not(:last-child) {
    border-right: ${(props) => (props.isOnDetailsPage ? 'none' : '1px solid var(--color-grey20)')};
  }
`;
export const HeaderContainer = styled.div `
  white-space: nowrap;
  display: flex;
  background-color: var(--color-white);
  width: 100%;
  min-width: 100%;
  min-height: 40px;
  z-index: 10;
`;

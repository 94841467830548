import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { paymentsItemsRoutine, paymentsRoutine } from 'actions/payments';
import { paymentsListStateSelector, paymentsAllPaymentsSelector, paymentsLinkSelector, } from 'selectors/paymentsSelectors';
import {} from './usePayments.types';
export const usePayments = ({ kind, partnershipId }) => {
    const dispatch = useDispatch();
    const data = useSelector(paymentsAllPaymentsSelector);
    const apiState = useSelector(paymentsListStateSelector);
    const { current } = useSelector(paymentsLinkSelector);
    const refresh = () => {
        dispatch(paymentsRoutine.trigger({ endpoint: current }));
        dispatch(paymentsItemsRoutine.trigger({ params: { kind }, partnershipId }));
    };
    useEffectOnce(() => {
        dispatch(paymentsRoutine.trigger({ partnershipId }));
        dispatch(paymentsItemsRoutine.trigger({ params: { kind }, partnershipId }));
    });
    return {
        ...apiState,
        data,
        refresh,
    };
};

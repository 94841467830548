import { createIsFetchingReducer } from 'store/redux';

import {
  FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_FAILURE,
  FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_REQUEST,
  FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_SUCCESS,
  SEARCH_FUNDING_ACCOUNTS_REQUEST,
  SEARCH_FUNDING_ACCOUNTS_SUCCESS,
  SEARCH_FUNDING_ACCOUNTS_FAILURE,
} from 'types/integrations';

export const finishCases = [
  FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_FAILURE,
  FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_SUCCESS,
  SEARCH_FUNDING_ACCOUNTS_SUCCESS,
  SEARCH_FUNDING_ACCOUNTS_FAILURE,
];
export const requestCases = [FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_REQUEST, SEARCH_FUNDING_ACCOUNTS_REQUEST];

const fetchReducer = createIsFetchingReducer(finishCases, requestCases);

export default fetchReducer;

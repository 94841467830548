/**
 * Default form error indicator id, to be used in the showErrorIndicator
 * @type {string}
 */
export const defaultFormErrorIndicatorId = 'formErrorAlert';

/**
 * Default form error indicator message, to be used in the showErrorIndicator
 * @type {string}
 */
export const defaultFormErrorMessage = 'Failed!';

/**
 * Default form success indicator id, to be used in the showSuccessIndicator
 * @type {string}
 */
export const defaultFormSuccessIndicatorId = 'formSuccessAlert';

/**
 * Default form success indicator message, to be used in the showSuccessIndicator
 * @type {string}
 */
export const defaultFormSuccessMessage = 'Saved!';

/**
 * The default duration in seconds to show a toast indicator
 * @type {number}
 */
export const defaultIndicatorDuration = 10;

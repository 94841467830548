import { createIsDoingReducer } from 'store/redux';

import { DELETE_API_TOKEN_FAILURE, DELETE_API_TOKEN_REQUEST, DELETE_API_TOKEN_SUCCESS } from 'types/apiToken';

export const finishCases = [DELETE_API_TOKEN_FAILURE, DELETE_API_TOKEN_SUCCESS];
export const requestCases = [DELETE_API_TOKEN_REQUEST];

const isDeletingReducer = createIsDoingReducer(finishCases, requestCases);

export default isDeletingReducer;

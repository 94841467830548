import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { refreshIntegrationConfigRequest } from 'actions/integrations';

import { isFetchingIntegrationConfigsSelector } from 'selectors/integrationConfigsSelectors';
import { ledgerIntegrationSelector } from 'selectors/integrationsSelectors';

import IntegrationLedgerRefresh from './IntegrationLedgerRefresh';

const mapStateToProps = createStructuredSelector({
  isFetchingIntegrationConfig: isFetchingIntegrationConfigsSelector,
  ledgerIntegration: ledgerIntegrationSelector,
});

const mapDispatchToProps = {
  onRefreshIntegrationConfig: refreshIntegrationConfigRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationLedgerRefresh);

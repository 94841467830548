import ThunkFetch from 'helpers/thunkFetch';

import { addFundingAccountAddressManualEndpoint } from 'services/api/fundingEndpoints';

import {
  SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_FAILURE,
  SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_REQUEST,
  SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_SUCCESS,
} from 'types/funding';

const submitFundingAccountAddressManual = (payload) => {
  const t = new ThunkFetch({
    actions: {
      error: SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_FAILURE,
      request: SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_REQUEST,
      success: SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_SUCCESS,
    },
    endpoint: addFundingAccountAddressManualEndpoint,
    method: 'POST',
    payload,
    requireAuth: true,
  });

  return t.makeRequest();
};

export default submitFundingAccountAddressManual;

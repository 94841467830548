export const addClass = (elem, value) => {
    if (value && value.indexOf(' ') > -1) {
        const classNames = value.split(' ');
        for (let i = 0; i < classNames.length; i++) {
            elem.classList.add(classNames[i]);
        }
    }
    else {
        elem.classList.add(value);
    }
};
export const attr = (elem, key, value) => {
    if (value != undefined) {
        elem.setAttribute(key, value);
    }
    else {
        return elem.getAttribute(key);
    }
};
export const removeAttr = (elem, key) => {
    try {
        elem.removeAttribute(key);
    }
    catch (e) { }
};
export const removeClass = (elem, value) => {
    if (value && value.indexOf(' ') > -1) {
        let classNames = value.split(' ');
        for (let i = 0; i < classNames.length; i++) {
            elem.classList.remove(classNames[i]);
        }
    }
    else {
        elem.classList.remove(value);
    }
};

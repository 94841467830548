import {
  lookupMembershipInviteEmailRoutine,
  membershipInviteSubmitRoutine,
  revokeMembershipInviteRoutine,
} from 'actions/routines/inviteTeamMember';

import { getObjectsByIdWithRelationships, removeResourceFromById } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import { FETCH_MEMBERSHIP_INVITE_SUCCESS, FETCH_MEMBERSHIP_INVITES_SUCCESS } from 'types/inviteTeamMember';

const byIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MEMBERSHIP_INVITES_SUCCESS:
      return getObjectsByIdWithRelationships(action.payload.membershipInvite, ['company']);

    case FETCH_MEMBERSHIP_INVITE_SUCCESS:
    case lookupMembershipInviteEmailRoutine.FAILURE:
    case membershipInviteSubmitRoutine.SUCCESS:
      return deepMergeWithArrayReplacement(
        state,
        getObjectsByIdWithRelationships(action.payload.membershipInvite, ['company']),
      );

    case revokeMembershipInviteRoutine.SUCCESS:
      return removeResourceFromById(state, action);

    default:
      return state;
  }
};

export default byIdReducer;

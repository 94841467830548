import _isEqual from 'lodash/isEqual';
import { parsePhoneNumber } from 'react-phone-number-input';

import { StaticCountryCode } from 'constants/countries';

/**
 * Curried function which updates component state using the onBlur/onFocus handler.
 * @type {function}
 * @param {boolean} toggleIsFocusedTo - Whether or not the element is focused
 * @param {function} changeIsFocused - Fn which updates isFocused component state
 * @param {function} shouldShowLabel - Fn which updates showLabel component state
 * @param {function} updateFieldMeta - Calls either onBlur or onFocus
 * @returns {function}
 */
export const createHandleFocus =
  ({ toggleIsFocusedTo, changeIsFocused, shouldShowLabel, updateFieldMeta }) =>
  () => {
    // updates our floating labels
    changeIsFocused(toggleIsFocusedTo);
    /**
     * tl;dr: Improve UI/UX for phone number fields which are required
     *
     * The underlying third-party input doesn't update onFocus/onBlur in redux-form the way a vanilla input does.
     * As a result, we have to manually dispatch when the field has been focused/blurred for synchronous validation to
     * occur correct.
     *
     * At stake here is the field's transition from pristine to dirty. If the field doesn't become dirty after first
     * touch, synchronous required validation doesn't run until form submission.
     */
    updateFieldMeta(toggleIsFocusedTo);

    // onBlur, if there is no number in the input
    if (toggleIsFocusedTo === false) {
      // hide the floating label and use the placeholder
      shouldShowLabel(false);
    }
  };

/**
 * @typedef {function} internationalPhoneInputChangeHandler
 * @param {StringMaybe} newPhoneVal - Current numbers in the phone input causing this change to occur
 * @param {boolean} isFocused - Local state from component to show/hide floating label
 * @param {PhoneNumber} phoneObjInState - The current phone number object in state
 * @returns {Dispatch} Dispatches updated phone number to form state
 */

/**
 * Curried.
 *
 * Determine show/hide of floating label from phone input change and update the redux-form state.
 * @type {function}
 * @param {Object} options
 * @param {boolean} options.isDebounced - The name for the input in redux-form. Probably phoneNumber.
 * @param {Function} options.onDebouncedChange - The name for the input in redux-form. Probably phoneNumber.
 * @param {string} options.reduxFormKey - The name for the input in redux-form. Probably phoneNumber.
 * @param {function} options.shouldShowLabel - Updates component state to show/hide floating label
 * @param {function} options.onValuesChange - Dispatch change to redux-form state.
 * @returns {internationalPhoneInputChangeHandler}
 */
export const handleChange =
  ({ isDebounced, onDebouncedChange, onValuesChange, reduxFormKey, shouldShowLabel }) =>
  (newPhoneVal, isFocused, phoneObjInState) => {
    const updatedValues = {
      ...phoneObjInState,
      number: newPhoneVal,
    };

    // focus but no phone input
    if (!newPhoneVal && isFocused) {
      shouldShowLabel(true);
      // no focus and no phone input
    } else if (!newPhoneVal) {
      shouldShowLabel(false);
      // no focus, phone inputted
    } else {
      shouldShowLabel(true);
    }

    /**
     * If the phone number hasn't changed, don't update the country. While users are typing, the component will update the
     * country as it finds matches—this gives us the nice formatting and is correct, per the inputted phone number. This
     * allows users to manually override the country selection, if they're selecting the phone country for the inputted
     * phone number. See FRON-1498 for discussion:
     * https://warrenpay.atlassian.net/browse/FRON-1498?atlOrigin=eyJpIjoiYTllMThiNzAzZDYyNGM0ODgyYWU0MzdkZTdmYTkwZDYiLCJwIjoiaiJ9
     */
    if (newPhoneVal && newPhoneVal !== phoneObjInState.number) {
      const parsed = parsePhoneNumber(newPhoneVal);

      if (parsed && parsed.country) {
        updatedValues.country = parsed.country;
        // There is cases with US in which country is never set. And because it is the default, we set it to US if there is
        // no country value from parsePhoneNumber or there was none from before and number exists.
      } else if (!updatedValues.country) {
        updatedValues.country = StaticCountryCode.US;
      }
    }

    // We don't want the country value to stay here if there is no number, meaning the whole InternationalPhone field
    // is empty
    if (!updatedValues.number && updatedValues.country) {
      updatedValues.country = undefined;
    }

    if (!_isEqual(phoneObjInState, updatedValues)) {
      if (isDebounced) {
        onDebouncedChange(updatedValues);
      } else {
        onValuesChange(reduxFormKey, updatedValues);
      }
    }
  };

/**
 * When the country changes, either by the user selecting the dropdown or programatically,
 * update the country selection in redux-forms state.
 * @param {string} reduxFormKey - Probably phoneNumber
 * @param {function} onValuesChange
 * @returns {function} Curried function
 */
export const handleCountryChange = (reduxFormKey, onValuesChange) => (newCountry) => {
  const key = `${reduxFormKey}.country`;
  return onValuesChange(key, newCountry);
};

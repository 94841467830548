import { createIsSubmittingReducer } from 'store/redux';

import {
  SUBMIT_SIGNUP_SELECT_NAMESPACE_FAILURE,
  SUBMIT_SIGNUP_SELECT_NAMESPACE_REQUEST,
  SUBMIT_SIGNUP_SELECT_NAMESPACE_SUCCESS,
} from 'types/signup';

export const finishCases = [SUBMIT_SIGNUP_SELECT_NAMESPACE_FAILURE, SUBMIT_SIGNUP_SELECT_NAMESPACE_SUCCESS];
export const requestCases = [SUBMIT_SIGNUP_SELECT_NAMESPACE_REQUEST];

const submitReducer = createIsSubmittingReducer(finishCases, requestCases);

export default submitReducer;

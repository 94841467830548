import PropTypes from 'prop-types';
import React from 'react';

import { confirmAlert } from 'helpers/confirmAlert';
import { noDefaultEventWithCallback } from 'helpers/events';
import { isFn } from 'helpers/utility';

const PromptLink = (props) => {
  const { children, className, history, message, onNavigate, shouldPrompt, to } = props;

  const confirmPrompt = async () => {
    let shouldNavigate = true;

    if (shouldPrompt) {
      shouldNavigate = await confirmAlert(message);
    }

    if (shouldNavigate) {
      history.push(to);

      if (isFn(onNavigate)) {
        onNavigate();
      }
    }
  };

  return (
    <a className={className} href={to} onClick={(evt) => noDefaultEventWithCallback(evt, confirmPrompt)}>
      {children}
    </a>
  );
};

PromptLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  history: PropTypes.shape().isRequired,
  message: PropTypes.string.isRequired,
  onNavigate: PropTypes.func,
  shouldPrompt: PropTypes.bool,
  to: PropTypes.string.isRequired,
};

PromptLink.defaultProps = {
  children: undefined,
  className: undefined,
  onNavigate: undefined,
  shouldPrompt: true,
};

export default PromptLink;

import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';

import { FETCH_PARTNERSHIP_SUCCESS } from 'types/partnership';

const getAllBrands = (brands) => {
  if (!brands) {
    return [];
  }

  return Object.keys(brands);
};

const allReducer = (state = [], action) => {
  switch (action.type) {
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
    case FETCH_PARTNERSHIP_SUCCESS:
      return getAllBrands(action.payload.companyBrand);

    default:
      return state;
  }
};

export default allReducer;

import {
  FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_FAILURE,
  FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_REQUEST,
  FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_SUCCESS,
} from 'types/clearingAccounts';

const fetchReducer = (state = false, action) => {
  switch (action.type) {
    case FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_REQUEST:
      return true;

    case FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_FAILURE:
    case FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_SUCCESS:
      return false;

    default:
      return state;
  }
};

export default fetchReducer;

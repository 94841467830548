import * as types from 'types/transactions';

import initialState from './initialState';

export default (state = initialState.errors, action) => {
  switch (action.type) {
    case types.FETCH_TRANSACTIONS_FAILURE:
      return action.payload.errors;

    case types.FETCH_TRANSACTIONS_REQUEST:
    case types.FETCH_TRANSACTIONS_SUCCESS:
      return initialState.errors;

    default:
      return state;
  }
};

import { createLastUpdatedReducer } from 'store/redux';

import {
  UPDATE_CURRENT_COMPANY_SUCCESS,
  UPDATE_CURRENT_COMPANY_RESET,
  UPDATE_CURRENT_COMPANY_REQUEST,
  UPDATE_CURRENT_COMPANY_FAILURE,
} from 'types/currentCompany';

export const successCases = [UPDATE_CURRENT_COMPANY_SUCCESS];
export const requestCases = [
  UPDATE_CURRENT_COMPANY_RESET,
  UPDATE_CURRENT_COMPANY_REQUEST,
  UPDATE_CURRENT_COMPANY_FAILURE,
];

const lastUpdatedReducer = createLastUpdatedReducer(successCases, requestCases);

export default lastUpdatedReducer;

import _get from 'lodash/get';
import { createSelector } from 'reselect';

import {
  modalNameConnectBankManual,
  modalNameCreateManualAddress,
  modalNameSSODisable,
  modalAddInternationalPayment,
  modalNameMatchPlatformPartnership,
  modalNameMergeLedgerPartnership,
  modalIntegrationSyncOptions,
} from 'constants/modals';

import { idSelector } from './globalSelectors';

/**
 * Selects modals from the state
 * @param {object} state - Redux state
 */
export const modalsSelector = (state) => state.modals;

/**
 * Selects a specific modal from the state
 * @param {object} state - Redux state
 * @param {string} modalName
 */
export const modalSelector = createSelector([modalsSelector, idSelector], (modals, modalName) => modals[modalName]);

/**
 * Selects a specific modal from the state
 * @function
 * @param {string} modalName
 * @return {function(Object): ObjectMaybe}
 */
export const createModalSelector = (modalName) => createSelector([modalsSelector], (modals) => modals[modalName]);

/**
 * Selects a specific modal "open" value from the state
 * @function
 * @param {string} modalName
 * @return {function(Object): boolean}
 */
export const createModalIsOpenSelector = (modalName) =>
  createSelector([modalsSelector], (modals) => Boolean(modals[modalName]?.open));

/**
 * Select a field from the modal.
 * @function
 * @param {OptionsArg} options
 * @param {string} options.field
 * @param {string} options.modalName
 * @returns {StandardSelector}
 */
export const createModalValueSelector = ({ field, modalName }) => {
  const selectorForSpecificModal = createModalSelector(modalName);

  return createSelector([selectorForSpecificModal], (modal = {}) => _get(modal, field));
};

/**
 * Selects a specific modal from state, returns open property
 * @param {object} state - Redux state
 * @param {string} modalName
 */
export const modalIsOpenSelector = createSelector([modalsSelector, idSelector], (modals, modalName) =>
  Boolean(modals[modalName]?.open),
);

/**
 * Selects createManualAddress modal isOpen property
 * @type {StandardSelector}
 * @returns {boolean} - isOpen
 */
export const modalCreateManualAddressIsOpenSelector = createSelector([modalsSelector], (modals) =>
  Boolean(modals[modalNameCreateManualAddress]?.open),
);

/**
 * Selects connectBankManual modal isOpen property
 * @type {StandardSelector}
 * @returns {boolean} - isOpen
 */
export const modalConnectBankManualIsOpenSelector = createSelector([modalsSelector], (modals) =>
  Boolean(modals[modalNameConnectBankManual]?.open),
);

/**
 * Selects ssoDisable modal isOpen property
 * @type {StandardSelector}
 * @returns {boolean} - isOpen
 */
export const modalSSODisableIsOpenSelector = createSelector([modalsSelector], (modals) =>
  Boolean(modals[modalNameSSODisable]?.open),
);

/**
 * Selects addInternationalPayment modal isOpen property
 * @type {StandardSelector}
 * @returns {boolean} - isOpen
 */
export const modalAddInternationalPaymentIsOpenSelector = createSelector([modalsSelector], (modals) =>
  Boolean(modals[modalAddInternationalPayment]?.open),
);

/**
 * Selects modalNameMatchPlatformPartnership modal object
 * @type {StandardSelector}
 * @returns {Object}
 */
export const modalMatchPlatformPartnershipSelector = createSelector(
  [modalsSelector],
  (modals) => modals[modalNameMatchPlatformPartnership],
);

/**
 * Selects modalNameMergeLedgerPartnership modal object
 * @type {StandardSelector}
 * @returns {Object}
 */
export const modalMergeLedgerPartnershipSelector = createSelector(
  [modalsSelector],
  (modals) => modals[modalNameMergeLedgerPartnership],
);

/**
 * Selects integrationSyncOptions modal isOpen property
 * @type {StandardSelector}
 * @returns {boolean} - isOpen
 */
export const modalIntegrationSyncOptionsIsOpenSelector = createSelector([modalsSelector], (modals) =>
  Boolean(modals[modalIntegrationSyncOptions]?.open),
);

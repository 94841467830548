import React from 'react';
import { FlexCol, FlexRow, Row } from 'components/layout';
import { Text } from 'components/text';
import { colors } from 'constants/styles';
import './ToggleRow.scss';
import ToggleSwitch, {} from './ToggleSwitch';
const ToggleRow = ({ heading, isChecked, isDisabled, name, onChange, subheading }) => (React.createElement("div", { className: "w-full toggle-row-container" },
    React.createElement(Row, { className: "justify-content--space-between toggle-row" },
        React.createElement(FlexCol, null,
            React.createElement(Text.Semibold, { color: colors.colorGreyXXDarkHex }, heading),
            React.createElement(FlexRow, null,
                React.createElement(Text.Regular, { color: colors.colorGreyXDarkHex }, subheading))),
        React.createElement(ToggleSwitch, { isChecked: isChecked, isDisabled: isDisabled, name: name, onChange: onChange }))));
export default ToggleRow;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { changeView } from 'actions/signUpFlow';

import { BrandLogo, IconNames, Icon } from 'components';
import { Spinner } from 'components/buttonV2';

import { DASHBOARD } from 'constants/routes';
import { colors } from 'constants/styles';

import { LOGO } from 'global/images/platform';

import { isCurrentCompanyKycStatusPending, isCurrentCompanyKycStatusVerified } from 'helpers/currentCompany';
import { getQueryParam } from 'helpers/queryParams';
import { getJoinedPath } from 'helpers/routeHelpers';

import { IsLoadingHasWrapper } from 'modules/isLoading';
import RetryKYCHeader from 'modules/signup-v3/components/RetryKYCHeader';
import SignUpFlowForm from 'modules/signup-v3/SignUpFlowFormContainer';

import {
  currentCompanyRepresentativesSelector,
  currentCompanySelector,
  currentCompanyHasFetchedSelector,
} from 'selectors/currentCompanySelectors';
import { hasKYCOrKYBError } from 'selectors/kycKybSelectors';
import { activeViewSelector, getSaveStatus } from 'selectors/signupSelectors';

import InviteTeamMemberModal from './components/InviteTeamMemberModal';
import ViewTeamMembersModal from './components/ViewTeamMembersModal';
import { SAVE_STATUSES, views } from './constants';
import { Container, Header, HeaderRight, SaveStatus } from './SignUpFlow.styles';
import Processing from './views/Processing';

import './styles/Toaster.scss';
import './styles/variables.scss';

const saveStatusOptions = {
  saved: {
    iconColor: colors.colorGreenBoldHex,
    icon: IconNames.TICK_CIRCLE,
    text: 'Changes saved',
  },
  saving: {
    iconColor: colors.colorBlueBoldHex,
    text: 'Saving changes',
  },
  notSaved: {
    iconColor: colors.colorYellowMediumHex,
    icon: IconNames.WARNING_SIGN,
    text: 'Unsaved changes',
  },
};

const SignUpFlow = ({ inviteTeamMemberModal, viewTeamMembersModal }) => {
  const dispatch = useDispatch();
  const activeView = useSelector(activeViewSelector);
  const saveStatus = useSelector(getSaveStatus);
  const currentCompanyRepresentatives = useSelector(currentCompanyRepresentativesSelector)?.map(
    (representative) => representative.id,
  );
  const stepQueryParam = getQueryParam('step');
  const queryParamIsValid = Object.values(views).includes(stepQueryParam);

  const currentCompany = useSelector(currentCompanySelector);
  const isCompanyKYCStatusPending = isCurrentCompanyKycStatusPending(currentCompany);
  const hasFetched = useSelector(currentCompanyHasFetchedSelector);
  const isVerified = isCurrentCompanyKycStatusVerified(currentCompany);
  const { isKycStatusRetry, isKycStatusDocument } = useSelector(hasKYCOrKYBError);

  React.useEffect(() => {
    if (queryParamIsValid) {
      dispatch(changeView(stepQueryParam));
    } else if (!activeView) {
      dispatch(changeView(views.businessVerification));
    }
  }, [stepQueryParam, queryParamIsValid, dispatch, activeView]);

  const redirectToDashboard = [isKycStatusRetry, isKycStatusDocument, isCompanyKYCStatusPending].some(Boolean)
    ? false
    : isVerified;

  if (redirectToDashboard) {
    // if the status is a failed status but not a retry/document status, return to dashboard
    return <Redirect to={getJoinedPath(DASHBOARD)} />;
  }

  return (
    <Container>
      <InviteTeamMemberModal open={inviteTeamMemberModal} />
      <ViewTeamMembersModal open={viewTeamMembersModal} />
      <Header>
        <BrandLogo imageSrc={LOGO.DARK} />

        {hasFetched && !isCompanyKYCStatusPending && (
          <HeaderRight>
            <SaveStatus>
              {saveStatus === SAVE_STATUSES.saving ? (
                <Spinner />
              ) : (
                <Icon
                  color={saveStatusOptions[saveStatus]?.iconColor}
                  icon={saveStatusOptions[saveStatus]?.icon}
                  size={14}
                />
              )}
              {saveStatusOptions[saveStatus]?.text}
            </SaveStatus>
            <RetryKYCHeader />
          </HeaderRight>
        )}
      </Header>
      {/* checking for .id to avoid returning true if currentCompany is a truthy object */}
      {/* we want currentCompany to be an actual Company object to evaluate its kycStatus */}
      {(!currentCompany || !currentCompany?.id) && <IsLoadingHasWrapper />}

      {currentCompany?.id && isCompanyKYCStatusPending && <Processing />}

      {currentCompany?.id && !isCompanyKYCStatusPending && (
        <SignUpFlowForm
          activeView={activeView}
          representatives={currentCompanyRepresentatives}
          stepQueryParam={stepQueryParam}
        />
      )}
    </Container>
  );
};

export default SignUpFlow;

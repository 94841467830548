import ThunkFetch from 'helpers/thunkFetch';

import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { onboardingUpdateCompanyEndpoint } from 'services/api/onboardingEndpoints';

import {
  UPDATE_ONBOARDING_COMPANY_FAILURE,
  UPDATE_ONBOARDING_COMPANY_REQUEST,
  UPDATE_ONBOARDING_COMPANY_SUCCESS,
} from 'types/currentCompany';

const updateOnboardingCompany = (updatePayload, successCallback = null) => {
  const t = new ThunkFetch({
    actions: {
      error: UPDATE_ONBOARDING_COMPANY_FAILURE,
      request: UPDATE_ONBOARDING_COMPANY_REQUEST,
      success: UPDATE_ONBOARDING_COMPANY_SUCCESS,
    },
    endpoint: onboardingUpdateCompanyEndpoint,
    method: 'PATCH',
    payload: payloadToJSONAPI(
      updatePayload,
      'company',
      { name: 'mailingAddress', kind: 'Address' },
      { name: 'info', kind: 'CompanyInfo' },
    ),
    requireAuth: true,
    successCallback,
  });

  return t.makeRequest();
};

export default updateOnboardingCompany;

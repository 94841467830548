import { StatusIndicator } from '@routable/gross-ds';
import React from 'react';
import { TableCellTextWithSubtext } from 'complexComponents/Table/components';
import { IconNames } from 'components/icon';
import { TooltipMUIIcon } from 'components/tooltip';
import { colors, sizes } from 'constants/styles';
const StatusWithText = ({ children, status, className, secondaryText, tooltipContent }) => (React.createElement(React.Fragment, null,
    React.createElement(TableCellTextWithSubtext, { subtext: secondaryText, text: React.createElement(StatusIndicator, { status: status }, children), textClassName: className }),
    !!tooltipContent && (React.createElement("div", { className: "right-indicator" },
        React.createElement(TooltipMUIIcon, { icon: IconNames.INFO_SIGN, iconClassName: "vertical-align--bottom align-self--flex-end", iconColor: colors.colorMainJordanHex, iconProps: { size: sizes.iconSizes.LARGE }, title: tooltipContent })))));
export default StatusWithText;

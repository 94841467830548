import {} from '@routable/fund-routes';
import { requiredValidator } from '@routable/shared';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';
import { Field, change, untouch } from 'redux-form';
import { ReduxFormFieldRenderInput } from 'components';
export const BeneficiaryInputField = ({ className, form, placeholder, field }) => {
    const dispatch = useDispatch();
    useUnmount(() => {
        dispatch(change(form, field, undefined));
        dispatch(untouch(form, field));
    });
    return (React.createElement("div", { className: className },
        React.createElement(Field, { component: ReduxFormFieldRenderInput, isRequired: true, name: field, placeholder: placeholder, validate: [requiredValidator] })));
};

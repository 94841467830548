import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { Checkbox } from 'components';

import { isBusinessTypeSoleProprietor } from 'helpers/currentCompany';

const PartnerCompanySSNEINToggle = ({ companyInfo, onToggleSSNEIN }) => {
  if (!isBusinessTypeSoleProprietor(companyInfo.businessType)) {
    return null;
  }

  return (
    <Field
      component={Checkbox}
      content="I don't have a Business EIN"
      name="formUI.businessFormShouldShowSSN"
      onChange={onToggleSSNEIN}
      tooltip="Sole proprietorships may not require a Business EIN. In this case, please provide your Social Security Number for tax purposes."
    />
  );
};

PartnerCompanySSNEINToggle.propTypes = {
  companyInfo: PropTypes.shape(),
  onToggleSSNEIN: PropTypes.func.isRequired,
};

PartnerCompanySSNEINToggle.defaultProps = {
  companyInfo: {},
};

export default PartnerCompanySSNEINToggle;

export var WebhookSettingsStatuses;
(function (WebhookSettingsStatuses) {
    WebhookSettingsStatuses["INVALID"] = "invalid";
    WebhookSettingsStatuses["PENDING"] = "pending";
    WebhookSettingsStatuses["VALID"] = "valid";
})(WebhookSettingsStatuses || (WebhookSettingsStatuses = {}));
export var WebhookTestDisplayStatuses;
(function (WebhookTestDisplayStatuses) {
    WebhookTestDisplayStatuses["FAIL"] = "Tests failed";
    WebhookTestDisplayStatuses["PASS"] = "Test passed";
    WebhookTestDisplayStatuses["PENDING"] = "Testing...";
})(WebhookTestDisplayStatuses || (WebhookTestDisplayStatuses = {}));
export var WebhookTestStatuses;
(function (WebhookTestStatuses) {
    WebhookTestStatuses["FAIL"] = "fail";
    WebhookTestStatuses["PASS"] = "pass";
    WebhookTestStatuses["PENDING"] = "pending";
})(WebhookTestStatuses || (WebhookTestStatuses = {}));

import { submitTransactionPaymentConfirmationRoutine } from 'actions/routines/transactions';

import { parseTransactions as parse } from 'helpers/transactions';

import * as types from 'types/transactions';

import initialState from './initialState';

export default (state = initialState.byId, action) => {
  const { payload, type } = action;

  switch (type) {
    case types.FETCH_ITEM_TRANSACTIONS_SUCCESS:
    case types.FETCH_TRANSACTIONS_SUCCESS:
    case submitTransactionPaymentConfirmationRoutine.SUCCESS:
      return parse.getTransactionSubObjectByIdAfterFetch(state, payload.transactionPaymentConfirmation);

    default:
      return state;
  }
};

import { LedgerApplicationTypes, LedgerLineItemTaxIdsXero, LedgerTaxStyles } from 'constants/ledger';
import { LineItemStyles } from 'constants/lineItems';

import { initialValuesCreateItems } from 'helpers/initialValues';

import { currentCompanySettingsIntegrationSelector } from 'selectors/currentCompanySelectors';
import { hasLedgerIntegrationSelector, ledgerIntegrationSelector } from 'selectors/integrationsSelectors';
import { ledgerSettingsSelector } from 'selectors/ledgerInfoSelectors';

import { storeAccessor as store } from 'store/accessor';

import { isItemKindReceivable } from './items';

/**
 * Helper, not connected to state, which builds a line item for an existing Xero item.
 * @param {Object} baseLineItem
 * @param {Object} submitPayload
 * @returns {Object[]}
 */
export const buildXeroLineItem = ({ baseLineItem, submitPayload }) => [
  {
    ...baseLineItem,
    account: {
      id: submitPayload.account?.id,
    },
    tax: {
      id: LedgerLineItemTaxIdsXero.NO_TAX,
    },
  },
];

/**
 * Helper, not connected to state, which builds a line item for an existing QBO item.
 * @param {Object} baseLineItem
 * @param {Object} submitPayload
 * @returns {Object[]}
 */
export const buildQBOLineItem = ({ baseLineItem, submitPayload }) => {
  const QBOLineItem = { ...baseLineItem, isTaxable: false };

  if (submitPayload.account?.id) {
    QBOLineItem.account = { id: submitPayload.account.id };
    QBOLineItem.style = LineItemStyles.ACCOUNT;
  }

  return [QBOLineItem];
};

export const buildSubmitExistingItemLineItems = (item, submitPayload) => {
  const reduxState = store.getState();
  const ledger = ledgerIntegrationSelector(reduxState);

  // Base line item for submission
  const baseLineItem = {
    amount: item.amount,
    description: submitPayload.reference,
    quantity: 1,
    style: LineItemStyles.ITEM,
    unitPrice: item.amount,
  };

  switch (ledger.application) {
    case LedgerApplicationTypes.XERO:
      return buildXeroLineItem({ baseLineItem, submitPayload });

    case LedgerApplicationTypes.QBO:
      return buildQBOLineItem({ baseLineItem, submitPayload });

    case LedgerApplicationTypes.SIMPLE:
    default:
      return [baseLineItem];
  }
};

/**
 * The BE uses the same serializer for creating and updating (submitting an existing) item. This means that we need
 * to fudge the tax stuff a little bit, because the backend is VERY unhappy without it.
 * @param {Item} item
 * @returns {Object|null}
 */
export const buildSubmitExistingItemTaxAttributes = (item) => {
  const reduxState = store.getState();
  const ledger = ledgerIntegrationSelector(reduxState);

  switch (ledger.application) {
    case LedgerApplicationTypes.XERO:
      return { taxStyle: LedgerTaxStyles.NO_TAX };

    case LedgerApplicationTypes.QBO: {
      // We only have meta fields on receivables for QBO
      if (!isItemKindReceivable(item)) {
        return {};
      }

      // Update meta with desired fields from the initial values
      return { taxAuto: false };
    }

    case LedgerApplicationTypes.SIMPLE:
    default:
      return null;
  }
};

export const buildSubmitExistingItemMeta = (item) => {
  const reduxState = store.getState();
  const companySettingsIntegration = currentCompanySettingsIntegrationSelector(reduxState);
  const hasLedgerIntegration = hasLedgerIntegrationSelector(reduxState);
  const ledger = ledgerIntegrationSelector(reduxState);
  const ledgerSettings = ledgerSettingsSelector(reduxState);

  switch (ledger.application) {
    case LedgerApplicationTypes.QBO: {
      // We only have meta fields on receivables for QBO
      if (!isItemKindReceivable(item)) {
        return {};
      }

      // Get initial values for QBO meta (includes taxCode which we don't need)
      const initialMetaValues = initialValuesCreateItems.getInitialMeta({
        companySettingsIntegration,
        hasLedgerIntegration,
        kind: item.kind,
        ledgerSettings,
      });

      // Update meta with desired fields from the initial values
      return {
        discount: initialMetaValues.discount,
        discountCalculation: initialMetaValues.discountCalculation,
        discountStyle: initialMetaValues.discountStyle,
      };
    }

    case LedgerApplicationTypes.SIMPLE:
    default:
      return {};
  }
};

import { DateFormats } from '@routable/shared';
import dayjs from 'dayjs';

export const getProbableTimezone = (ignoreCache = false) => dayjs.tz.guess(ignoreCache);

export const getProbableTimezoneAbbr = (dateString) => {
  const tz = getProbableTimezone();
  return dayjs(dateString).tz(tz).format('z');
};

export const formatDateWithZoneAbbr = (dateString, format) => {
  const zoneAbbr = getProbableTimezoneAbbr(dateString);
  const formattedDate = dayjs(dateString).format(format);
  return `${formattedDate} ${zoneAbbr}`;
};

/**
 * Returns the given time string as a full date-time string in the current timezone.
 * @param {String} timeStr
 * @return {string}
 */
export const getTimeStringAsDateTimeString = (timeStr) => {
  const nowDateString = dayjs().format(DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY);
  return `${nowDateString}T${timeStr}Z`;
};

/**
 * Returns a dayjs object representing the given time string,
 * converted to the given timezone.
 * @param {string} timeStr
 * @param {string} timezoneId
 * @return {import('dayjs').Dayjs}
 */
export const getTimeStringConvertedToTimezoneObject = (timeStr, timezoneId) => {
  const cutoffDateString = getTimeStringAsDateTimeString(timeStr);
  return dayjs(cutoffDateString).tz(timezoneId);
};

/**
 * Returns a string representing the given time string,
 * converted to the given timezone.
 * @param {string} timeStr
 * @param {string} timezoneId
 * @return {string}
 */
export const getTimeStringFormattedForTimezone = (timeStr, timezoneId) => {
  const cutoffObj = getTimeStringConvertedToTimezoneObject(timeStr, timezoneId);
  return dayjs(cutoffObj).format('ha z');
};

/**
 * Returns a string representing the given time string,
 * converted to the timezone that the user is probably in.
 * @param {string} timeStr
 * @return {Object}
 */
export const getDateTimeObjectForProbableTimezone = (timeStr) => {
  const timezoneId = getProbableTimezone();
  return getTimeStringConvertedToTimezoneObject(timeStr, timezoneId);
};

/**
 * Returns a string representing the given time string,
 * converted to the timezone that the user is probably in.
 * @param {string} timeStr
 * @return {string}
 */
export const getTimeStringFormattedForProbableTimezone = (timeStr) => {
  const timezoneId = getProbableTimezone();
  return getTimeStringFormattedForTimezone(timeStr, timezoneId);
};

import { FetchService } from 'services';
import { billingCodesEndpoint } from 'services/api/billingEndpoints';

/**
 * Fetch billing code data.
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchBillingCodes = async (options = {}) => {
  const config = {
    ...options,
    endpoint: billingCodesEndpoint,
    method: 'GET',
    requireAuth: true,
  };
  return FetchService.request(config);
};

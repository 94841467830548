import { updateMembershipRoutine } from 'actions/routines/membership';

import { createIsFetchingReducer } from 'store/redux';

import {
  FETCH_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
} from 'types/user';

export const finishCases = [
  FETCH_USER_FAILURE,
  FETCH_USER_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_USERS_SUCCESS,
  updateMembershipRoutine.FAILURE,
  updateMembershipRoutine.SUCCESS,
];
export const requestCases = [FETCH_USER_REQUEST, FETCH_USERS_REQUEST, updateMembershipRoutine.REQUEST];

const fetchReducer = createIsFetchingReducer(finishCases, requestCases);

export default fetchReducer;

/**
 * @fileOverview Shared data-testid constant values for pagination related features.
 *
 * @module constants/dataCy/paginationDataCy
 */

/**
 * Bulk actions > bulk import.
 * @type {Object<string, string>}
 */
export const paginationComponent = {
  HEADER_INDEX: 'pagination_header_index',
  FOOTER_INDEX: 'pagination_footer_index',
  PAGE_INDEX: 'page_nav_page_index',
  PAGE_NAV_PREVIOUS: 'page_nav_previous',
  PAGE_NAV_NEXT: 'page_nav_next',
  PAGE_NAV_LAST_PAGE: 'page_nav_last_page',
  GO_TO_PAGE_INPUT: 'go_to_page_input',
  GO_TO_PAGE_ACTION: 'go_to_page_action',
};

import PropTypes from 'prop-types';
import React from 'react';

import { BlockListItem } from 'components/listItems';
import RTBIcon from 'components/rtbIcon';

const AccountingIntegrationSectionItem = ({ item }) => (
  <BlockListItem
    className="accounting-integration--item"
    key={item.label}
    label={item.label}
    value={
      <React.Fragment>
        {!!item.iconName && <RTBIcon className="chip-icon" icon={item.iconName} />}

        <span>{item.value}</span>
      </React.Fragment>
    }
  />
);

AccountingIntegrationSectionItem.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default AccountingIntegrationSectionItem;

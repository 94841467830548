import { combineReducers } from 'redux';

import fundingAccountAddressManualReducer from 'reducers/fundingAccountAddressManualReducer';
import fundingAccountBankManualReducer from 'reducers/fundingAccountBankManualReducer';
import fundingAccountBankTokenReducer from 'reducers/fundingAccountBankTokenReducer';
import fundingAccountsReducer from 'reducers/fundingAccountsReducer';
import fundingInfoAddressReducer from 'reducers/fundingInfoAddressReducer';
import fundingInfoBalanceReducer from 'reducers/fundingInfoBalanceReducer';
import fundingInfoBankReducer from 'reducers/fundingInfoBankReducer';
import fundingInfoInternationalBankValidatorReducer from 'reducers/fundingInfoInternationalBankValidatorReducer';
import fundingInfoInternationalReducer from 'reducers/fundingInfoInternationalReducer';
import fundingSourcesReducer from 'reducers/fundingSourcesReducer';
import fundingSupportedCountriesReducer from 'reducers/fundingSupportedCountriesReducer';

const fundingReducer = combineReducers({
  fundingAccountAddressManual: fundingAccountAddressManualReducer,
  fundingAccountBankManual: fundingAccountBankManualReducer,
  fundingAccountBankToken: fundingAccountBankTokenReducer,
  fundingAccounts: fundingAccountsReducer,
  fundingInfoAddresses: fundingInfoAddressReducer,
  fundingInfoBalances: fundingInfoBalanceReducer,
  fundingInfoBankAccounts: fundingInfoBankReducer,
  fundingInfoInternational: fundingInfoInternationalReducer,
  fundingInfoInternationalBankValidator: fundingInfoInternationalBankValidatorReducer,
  fundingSources: fundingSourcesReducer,
  fundingSupportedCountries: fundingSupportedCountriesReducer,
});

export default fundingReducer;

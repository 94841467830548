import { fetchItemsRoutine, sendSubmitBillToItemRequestRoutine, submitItemRoutine } from 'actions/routines/item';
import { deleteBillAttachmentRoutine } from 'actions/routines/itemAttachments';

import { allKeys } from 'helpers/utility';

import { SUBMIT_UPLOAD_BILL_SUCCESS } from 'types/bills';
import { FETCH_ITEM_SUCCESS } from 'types/item';
import { FETCH_PARTNERSHIP_ITEMS_SUCCESS } from 'types/partnership';

const allAttachmentsReducer = (state = [], action) => {
  switch (action.type) {
    case fetchItemsRoutine.SUCCESS:
    case FETCH_ITEM_SUCCESS:
    case FETCH_PARTNERSHIP_ITEMS_SUCCESS:
      return allKeys(action.payload.itemAttachment);

    case SUBMIT_UPLOAD_BILL_SUCCESS:
    case sendSubmitBillToItemRequestRoutine.SUCCESS:
    case submitItemRoutine.FULFILL:
      return Array.from(new Set([...state, ...allKeys(action.payload.itemAttachment)]));

    case deleteBillAttachmentRoutine.SUCCESS:
      return state.filter((attachmentId) => attachmentId !== action.payload.attachment.id);

    default:
      return state;
  }
};

export default allAttachmentsReducer;

import React from 'react';
import { Icon, IconNames } from 'components';
import { showErrorIndicator } from 'components/form/helpers/indicators';
import { colors } from 'constants/styles';
import { getBase64 } from 'helpers/fileHelpers';
import { DropzoneContainer, Text, SupportText, EmphasizedText } from './Dropzone.styles';
export const handleRejectedFile = (errorIndicatorMessage = 'Please try again') => showErrorIndicator(errorIndicatorMessage);
const Dropzone = ({ accept, addFile, errorMessage, helpMessage, isDisabled, name, supportText, }) => {
    const handleAcceptedFiles = (files) => {
        files.forEach(async (file) => {
            const fileObject = {
                ...file,
                documentType: 'other',
                file: await getBase64(file),
                filename: file.name,
                preview: file.preview,
                uploaded: false,
            };
            addFile(fileObject);
        });
    };
    return (React.createElement(DropzoneContainer, { accept: accept, disabled: isDisabled, name: name, onDropAccepted: handleAcceptedFiles, onDropRejected: () => handleRejectedFile(errorMessage) },
        React.createElement(Icon, { color: colors.colorBlueBoldHex, icon: IconNames.IMPORT, size: 24 }),
        React.createElement(Text, null,
            helpMessage || 'Drag and drop here',
            React.createElement("br", null),
            "or ",
            React.createElement(EmphasizedText, null, "browse files")),
        React.createElement(SupportText, null, supportText)));
};
export default Dropzone;

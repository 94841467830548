import width from 'global/css/variables/width.scss';

export const contentWidth = {
  FULL: width.contentWidthFull,
  MEDIUM: width.contentWidthMedium,
  SMALL: width.contentWidthSmall,
};

export const tooltipWidth = {
  MEDIUM: width.tooltipWidthMedium,
};

import * as types from 'types/passwordInput';

export const fetchPasswordStrengthRequest = (data) => ({
  type: types.FETCH_PASSWORD_STRENGTH_REQUEST,
  payload: data,
});

export const fetchPasswordStrengthCompleted = (response) => ({
  type: types.FETCH_PASSWORD_STRENGTH_COMPLETED,
  payload: response,
});

export const triggerPasswordStrengthRequest = (value) => ({
  type: types.CHECK_PASSWORD_STRENGTH,
  payload: value,
});

export const setRequirementsAreMet = () => ({
  type: types.SET_REQUIREMENTS_ARE_MET,
});

export const unsetRequirementsAreMet = () => ({
  type: types.UNSET_REQUIREMENTS_ARE_MET,
});

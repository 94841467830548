import { getModalReducerOpenAction } from 'store/redux';

import * as types from 'types/funding';
import * as partnershipTypes from 'types/partnership';

export const initialState = {
  formContext: null,
  isSubmitting: false,
  open: false,
  partnershipId: null,
  paymentDeliveryMethod: null,
};

const addPartnerFundingAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MODAL_ADD_PARTNER_FUNDING_ACCOUNT_CLOSE:
      return initialState;

    case types.MODAL_ADD_PARTNER_FUNDING_ACCOUNT_OPEN:
      return getModalReducerOpenAction(state, action);

    case partnershipTypes.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_FAILURE:
    case partnershipTypes.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_FAILURE:
      return {
        ...state,
        isSubmitting: false,
      };

    case partnershipTypes.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_REQUEST:
    case partnershipTypes.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_REQUEST:
      return {
        ...state,
        isSubmitting: true,
      };

    case partnershipTypes.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_SUCCESS:
    case partnershipTypes.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };

    default:
      return state;
  }
};

export default addPartnerFundingAccountReducer;

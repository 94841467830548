/**
 * @fileOverview Defines the sub-reducer for feature setting request errors.
 * @module reducers/featureSettings
 */

import { getFeatureSettingsRoutine } from 'actions/routines/featureSettings';

import { createErrorReducer } from 'store/redux';

export const failureCases = [getFeatureSettingsRoutine.FAILURE];

export const successCases = [getFeatureSettingsRoutine.FULFILL, getFeatureSettingsRoutine.SUCCESS];

/**
 * Reducer for reduxState.featureSettings.errors.
 * @type {ReduxReducer}
 */
const errorsReducer = createErrorReducer(successCases, failureCases);

export default errorsReducer;

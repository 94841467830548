import { StatusIndicator, statusVariants } from '@routable/gross-ds';
import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components';

import { typography } from 'constants/styles';

import { text } from 'modules/dashboard/settings/account/teamMembers/helpers';

/**
 * Displays member's status in a table cell.
 * @param {ComponentProps} props
 * @param {string} props.dateExpiration
 * @param {boolean} props.isExpired
 * @returns {StatelessComponent}
 */
const TeamMemberListStatusCell = ({ dateExpiration, isExpired }) => (
  <div className="align-cell column">
    <StatusIndicator className="font-xs" status={isExpired ? statusVariants.default : statusVariants.pending}>
      {text.getStatusTextTitle(isExpired)}
    </StatusIndicator>
    <Text.Regular
      color={typography.TextColor.GREY_X_DARK}
      size={typography.TextSize.LEVEL_100}
      style={{ lineHeight: typography.TextLineHeight.LARGE }}
    >
      {text.getStatusTextSubtitle(dateExpiration)}
    </Text.Regular>
  </div>
);

TeamMemberListStatusCell.propTypes = {
  dateExpiration: PropTypes.string.isRequired,
  isExpired: PropTypes.bool.isRequired,
};

export default TeamMemberListStatusCell;

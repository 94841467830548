import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { taxFilingDetailsSelector } from 'queries/taxesCompoundSelectors';

import CollectTaxSuccessPage from './CollectTaxSuccessPage';

const mapStateToProps = createStructuredSelector({
  filingDetails: taxFilingDetailsSelector,
});

export default connect(mapStateToProps)(CollectTaxSuccessPage);

import ThunkFetch from '../helpers/thunkFetch';
import { addFundingAccountBankTokenEndpoint } from '../services/api/fundingEndpoints';
import * as types from '../types/connectBank';

const submitFundingAccountBankToken = (payload, successCallback, errorCallback) => {
  const t = new ThunkFetch({
    actions: {
      error: types.SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_FAILURE,
      request: types.SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_REQUEST,
      success: types.SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_SUCCESS,
    },
    endpoint: addFundingAccountBankTokenEndpoint,
    method: 'POST',
    payload,
    successCallback,
    errorCallback,
    requireAuth: true,
  });

  return t.makeRequest();
};

export default submitFundingAccountBankToken;

import { combineReducers } from 'redux';

import { RESET_BULK_ACTIONS_STATE } from 'types/bulkActions';

import allIdsReducer from './allIdsReducer';
import byIdReducer from './byIdReducer';
import errorsReducer from './errorsReducer';
import isFetchingReducer from './isFetchingReducer';
import isUploadingReducer from './isUploadingReducer';
import linksReducer from './linksReducer';
import paginationReducer from './paginationReducer';
import watchingIdsReducer from './watchingIdsReducer';

const importReducer = combineReducers({
  allIds: allIdsReducer,
  byId: byIdReducer,
  errors: errorsReducer,
  isFetching: isFetchingReducer,
  isUploading: isUploadingReducer,
  links: linksReducer,
  pagination: paginationReducer,
  watchingIds: watchingIdsReducer,
});

const rootReducer = (state, action) => {
  switch (action.type) {
    case RESET_BULK_ACTIONS_STATE:
      return importReducer(undefined, action);

    default:
      return importReducer(state, action);
  }
};

export default rootReducer;

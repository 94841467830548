import PropTypes from 'prop-types';
import React from 'react';

import { Hint, HintContent, HintText, HintTitle } from 'components/hint';

import { Intent } from 'constants/ui';

const BaseHint = (props) => {
  const {
    className,
    content,
    contentProps,
    dataFullStoryTitle,
    intent,
    isCollapsible,
    isCollapsed,
    multiline,
    text,
    textProps,
    title,
    titleProps,
    ...rest
  } = props;

  const [collapsed, setIsCollapsed] = React.useState(isCollapsed);
  const showContent = isCollapsible ? !collapsed : true;

  React.useEffect(() => setIsCollapsed(isCollapsed), [isCollapsed]);

  return (
    <Hint className={className} intent={intent} isCollapsible={isCollapsible} multiline={multiline} {...rest}>
      {!!title && (
        <HintTitle
          collapsed={collapsed}
          dataFullStory={dataFullStoryTitle}
          isCollapsible={isCollapsible}
          setIsCollapsed={setIsCollapsed}
          {...titleProps}
        >
          {title}
        </HintTitle>
      )}

      {!!text && showContent && <HintText {...textProps}>{text}</HintText>}

      {!!content && showContent && <HintContent {...contentProps}>{content}</HintContent>}
    </Hint>
  );
};

BaseHint.propTypes = {
  className: PropTypes.string,
  content: PropTypes.node,
  contentProps: PropTypes.shape(),
  dataFullStoryTitle: PropTypes.bool,
  hideIcon: PropTypes.bool,
  intent: PropTypes.string,
  isCollapsed: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  multiline: PropTypes.bool,
  text: PropTypes.node,
  textProps: PropTypes.shape(),
  title: PropTypes.node,
  titleProps: PropTypes.shape(),
};

BaseHint.defaultProps = {
  className: undefined,
  content: undefined,
  contentProps: {},
  dataFullStoryTitle: undefined,
  hideIcon: undefined,
  intent: Intent.INFO,
  isCollapsed: undefined,
  isCollapsible: undefined,
  multiline: undefined,
  text: undefined,
  textProps: {},
  title: undefined,
  titleProps: {},
};

export default BaseHint;

import { updateMembershipRoutine } from 'actions/routines/membership';

import { createErrorReducer } from 'store/redux';

import {
  FETCH_MEMBERSHIPS_FAILURE,
  FETCH_MEMBERSHIPS_REQUEST,
  FETCH_MEMBERSHIPS_SUCCESS,
  FETCH_MEMBERSHIP_FAILURE,
  FETCH_MEMBERSHIP_REQUEST,
  FETCH_MEMBERSHIP_SUCCESS,
  UPDATE_MEMBERSHIP_FAILURE,
  UPDATE_MEMBERSHIP_REQUEST,
  UPDATE_MEMBERSHIP_SUCCESS,
  UPDATE_ONBOARDING_MEMBERSHIP_FAILURE,
  UPDATE_ONBOARDING_MEMBERSHIP_REQUEST,
  UPDATE_ONBOARDING_MEMBERSHIP_SUCCESS,
} from 'types/memberships';

export const failureCases = [
  FETCH_MEMBERSHIP_FAILURE,
  FETCH_MEMBERSHIPS_FAILURE,
  UPDATE_MEMBERSHIP_FAILURE,
  updateMembershipRoutine.FAILURE,
  UPDATE_ONBOARDING_MEMBERSHIP_FAILURE,
];
export const successCases = [
  FETCH_MEMBERSHIP_REQUEST,
  FETCH_MEMBERSHIP_SUCCESS,
  FETCH_MEMBERSHIPS_REQUEST,
  FETCH_MEMBERSHIPS_SUCCESS,
  UPDATE_MEMBERSHIP_REQUEST,
  updateMembershipRoutine.REQUEST,
  UPDATE_MEMBERSHIP_SUCCESS,
  updateMembershipRoutine.SUCCESS,
  UPDATE_ONBOARDING_MEMBERSHIP_REQUEST,
  UPDATE_ONBOARDING_MEMBERSHIP_SUCCESS,
];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

const PREFIX = `@webhooks`;

export const CLOSE_WEBHOOKS_TEST_MODAL = `${PREFIX}/CLOSE_WEBHOOKS_TEST_MODAL`;

export const DELETE_WEBHOOK_SETTINGS = `${PREFIX}/DELETE_WEBHOOK_SETTINGS`;

export const FETCH_WEBHOOKS = `${PREFIX}/FETCH_WEBHOOKS`;
export const FETCH_WEBHOOKS_SETTINGS = `${PREFIX}/FETCH_WEBHOOKS_SETTINGS`;

export const OPEN_WEBHOOKS_TEST_MODAL = `${PREFIX}/OPEN_WEBHOOKS_TEST_MODAL`;

export const REMOVE_WEBHOOKS_TEST = `${PREFIX}/REMOVE_WEBHOOKS_TEST`;

export const SUBMIT_WEBHOOKS_SETTINGS = `${PREFIX}/SUBMIT_WEBHOOKS_SETTINGS`;

export const TEST_WEBHOOKS_SETTINGS = `${PREFIX}/TEST_WEBHOOKS_SETTINGS`;

export const UPDATE_WEBHOOKS_TEST = `${PREFIX}/UPDATE_WEBHOOKS_TEST`;

export const UPDATE_WEBHOOK_SETTINGS = `${PREFIX}/UPDATE_WEBHOOK_SETTINGS`;

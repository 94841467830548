import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { resendPartnershipInviteRoutine } from 'actions/routines/partnership';

import { partnershipActionSelector } from 'selectors/partnershipsSelectors';

/**
 * Creates a wrapped component that will receive the passed-through partnership `id`
 * prop, plus two additional props: onResendInvite (function to invoke on resending
 * an invite), and resendAction (string status value of the resendInvite action for
 * this partnership).
 * @param {Function} Component
 * @return {Function}
 */
const withResendInvite = (Component) => {
  const ComponentWithResendInvite = (props) => {
    const { id, onResendInvite, partnership, resendAction, ...rest } = props;

    return (
      <Component
        {...rest}
        id={id}
        onResendInvite={onResendInvite}
        partnership={partnership}
        resendAction={resendAction}
      />
    );
  };

  ComponentWithResendInvite.propTypes = {
    id: PropTypes.string.isRequired,
    onResendInvite: PropTypes.func.isRequired,
    partnership: PropTypes.shape({}),
    resendAction: PropTypes.string,
  };

  ComponentWithResendInvite.defaultProps = {
    partnership: undefined,
    resendAction: undefined,
  };

  const mapStateToProps = (state, ownProps) => ({
    resendAction: partnershipActionSelector(state, ownProps.id, 'resendInvite'),
  });

  const mapDispatchToProps = {
    onResendInvite: resendPartnershipInviteRoutine.trigger,
  };

  return connect(mapStateToProps, mapDispatchToProps)(ComponentWithResendInvite);
};

export default withResendInvite;

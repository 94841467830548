import React from 'react';
import { ButtonV2, Hint, Text } from 'components';
import { ButtonSize } from 'constants/button';
import { TextLineHeight, TextSize } from 'constants/styles/typography';
import { Intent } from 'constants/ui';
import {} from './NoRegisteredAddressesAddedHint.types';
import './NoRegisteredAddressesAddedHint.scss';
const NoRegisteredAddressesAddedHint = ({ onClick, isInternational }) => (React.createElement(Hint, { className: "no-registered-addresses-added-hint", intent: Intent.INFO, multiline: true, shouldRemoveBackgroundColor: true },
    React.createElement(Text.ExtraBold, { className: "font-color--blue-bold--important", lineHeight: TextLineHeight.EXTRA_LARGE, size: TextSize.LEVEL_200 }, "You don't have any addresses for this vendor"),
    React.createElement(Text.Regular, { className: "font-color--blue-dark--important padding-bottom--m-large", lineHeight: TextLineHeight.EXTRA_LARGE, size: TextSize.LEVEL_200 }, `Add a registered address to make ${isInternational ? 'W-8' : 'W-9'} Tax collection easier.`),
    React.createElement(ButtonV2, { intent: Intent.NEUTRAL, onClick: onClick, size: ButtonSize.MEDIUM }, "Add registered address")));
export default NoRegisteredAddressesAddedHint;

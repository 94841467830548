import React from 'react';
import { useMedia } from 'react-use';
import DropdownMenuButton from 'complexComponents/DropdownMenuButton';
import { Intent } from 'constants/ui';
import ConditionalRestrictedPermissionTooltip from 'modules/auth/ConditionalRestrictedPermissionTooltip';
import { TableToolbarActionsMenuOption } from './components';
import {} from './TableToolbarActionsMenu.types';
const TableToolbarActionsMenu = ({ isDisabled, options, ...rest }) => {
    const queryMatches = useMedia('(min-width: 768px)');
    const isAnyOptionActionable = options.find((option) => Boolean(option.actionableCount));
    if (isDisabled === false) {
        return (React.createElement(ConditionalRestrictedPermissionTooltip, { useTooltip: isDisabled },
            React.createElement(DropdownMenuButton, { intent: Intent.NEUTRAL, menuAlign: "right", options: options, ...rest, isDisabled: isDisabled, upwards: !queryMatches })));
    }
    return (React.createElement(ConditionalRestrictedPermissionTooltip, { useTooltip: isDisabled },
        React.createElement(DropdownMenuButton, { intent: Intent.NEUTRAL, menuAlign: "right", options: options, ...rest, isDisabled: !isAnyOptionActionable || isDisabled, upwards: !queryMatches })));
};
TableToolbarActionsMenu.defaultProps = {
    isDisabled: undefined,
    OptionComponent: TableToolbarActionsMenuOption,
};
export default TableToolbarActionsMenu;

import PropTypes from 'prop-types';
import React from 'react';

import Modal from 'components/modal';

import { isFn } from 'helpers/utility';

import {
  filingDetailsDefaultProps,
  filingDetailsSelectorPropTypes,
} from 'modules/external/ExternalCollectTaxInfo/helpers/customPropTypes';

import { ConfirmTaxInfoModalBody } from './components';

/**
 * After the user has filled or made alterations to their mailing address and filing details, the user reviews that
 * information in this modal. At the bottom of the modal, they are asked to re-enter their TIN and elect for eDelivery.
 * @param props
 * @returns {*}
 * @constructor
 */
const ConfirmTaxInfoModal = (props) => {
  const {
    canSubmit,
    filingDetails,
    handleSubmit, // redux-form
    isConfirm,
    modalIsOpen,
    onCloseModal,
    onFailure,
    onSubmitModal,
    onSuccess,
    // redux-form below
    submitting,
    submitFailed,
    submitSucceeded,
  } = props;

  const modalBodyProps = {
    canSubmit,
    filingDetails,
    isConfirm,
    submitting,
    onCloseModal,
    // bind our submit handler with redux-form
    onSubmitModal: handleSubmit(onSubmitModal),
  };

  if (!submitting) {
    if (submitFailed && isFn(onFailure)) {
      return onFailure();
    }

    if (submitSucceeded && isFn(onSuccess)) {
      return onSuccess();
    }
  }

  return (
    <Modal
      body={<ConfirmTaxInfoModalBody {...modalBodyProps} />}
      hasInlineFooter
      modalHeader="Confirm your tax filing details"
      modalState={modalIsOpen}
      onCloseModal={onCloseModal}
    />
  );
};

ConfirmTaxInfoModal.propTypes = {
  canSubmit: PropTypes.bool,
  filingDetails: filingDetailsSelectorPropTypes({
    // tinType isn't always ready by the time ConfirmTaxModal gets the props
    tinType: PropTypes.string,
  }),
  handleSubmit: PropTypes.func.isRequired,
  isConfirm: PropTypes.bool,
  modalIsOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onFailure: PropTypes.func,
  onSubmitModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
};

ConfirmTaxInfoModal.defaultProps = {
  canSubmit: undefined,
  filingDetails: filingDetailsDefaultProps,
  isConfirm: undefined,
  onFailure: undefined,
  onSuccess: undefined,
};

export default ConfirmTaxInfoModal;

import PropTypes from 'prop-types';
import React from 'react';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { PositiveBalanceAmountButtons, NegativeBalanceAmountButton } from './components';

/**
 * Depending on whether or not the balance is negative, show the correct buttons for managing a balance. For example:
 * deposit and withdraw.
 *
 * @param {ComponentProps} props
 * @param {boolean} [props.isNegative]
 * @param {RestOfProps} props.rest
 * @returns {StatelessComponent}
 */
const BalanceAmountBlockActions = ({ isNegative, ...rest }) =>
  isNegative ? <NegativeBalanceAmountButton {...rest} /> : <PositiveBalanceAmountButtons {...rest} />;

BalanceAmountBlockActions.propTypes = {
  allowDeposit: PropTypes.bool,
  allowWithdraw: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isNegative: PropTypes.bool,
  onDeposit: PropTypes.func,
  onFixNegative: PropTypes.func,
  onWithdraw: PropTypes.func,
};

BalanceAmountBlockActions.defaultProps = {
  allowDeposit: true,
  allowWithdraw: true,
  isDisabled: undefined,
  isNegative: undefined,
  onDeposit: undefined,
  onFixNegative: undefined,
  onWithdraw: undefined,
};

export default BalanceAmountBlockActions;

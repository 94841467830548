import clsx from 'clsx';
import React from 'react';
import { EditButtonInput } from 'components';
import { TooltipPadding } from 'constants/tooltip';
import {} from './PartnershipNameSettingsFormProps.types';
const PartnershipNameSettingsFormField = ({ fieldClassName = '', isInitialValueLocked = false, label, name, value, validate, }) => {
    const [isFieldDisabled, setIsFieldDisabled] = React.useState(isInitialValueLocked);
    const inputRef = React.useRef(null);
    const onEditButtonClick = () => {
        setIsFieldDisabled((previousValue) => !previousValue);
    };
    React.useEffect(() => {
        if (!isFieldDisabled && inputRef?.current) {
            inputRef.current.focus();
        }
    }, [isFieldDisabled]);
    return (React.createElement(EditButtonInput, { editAllowed: true, editField: !isFieldDisabled, fieldClassNames: clsx({
            'margin-top--m': true,
            [fieldClassName]: true,
        }), isDisabled: isFieldDisabled, label: label, name: name, onEdit: onEditButtonClick, setRef: inputRef, showField: true, staticValue: value, tooltipProps: {
            padding: TooltipPadding.SKINNY,
        }, type: "text", validate: validate }));
};
export default PartnershipNameSettingsFormField;

import React from 'react';
import { useSelector } from 'react-redux';
import { Text } from 'components';
import { typography } from 'constants/styles';
import { TooltipPadding } from 'constants/tooltip';
import { currentCompanySettingsIsInternationalPaymentsEnabledSelector } from 'selectors/currentCompanySelectors';
import PaymentMethodManageActionButton, { PaymentMethodActionButtonType } from '../PaymentMethodManageActionButton';
import { shouldHideActionButtons, shouldDisableActionButtonPrimary, shouldDisableActionButtonUpdate } from './helpers';
const PaymentMethodManageActions = ({ currencyCode, isSubmitting, onDelete, onPrimary, onUpdate, status, }) => {
    const isInternationalPaymentsEnabled = useSelector(currentCompanySettingsIsInternationalPaymentsEnabledSelector);
    if (shouldHideActionButtons(status)) {
        return null;
    }
    const tooltipText = isInternationalPaymentsEnabled
        ? `Make this the primary payment method for receiving ${currencyCode} payments.`
        : 'Set as the primary payment method.';
    const isPrimaryDisabled = shouldDisableActionButtonPrimary(status, isSubmitting);
    const isUpdateDisabled = shouldDisableActionButtonUpdate(status, isSubmitting);
    const tooltipProps = isPrimaryDisabled
        ? {}
        : {
            arrow: true,
            padding: TooltipPadding.SKINNY,
            title: (React.createElement(Text.Regular, { color: typography.TextColor.GREY_X_DARK, lineHeight: typography.TextLineHeight.MEDIUM, size: typography.TextSize.LEVEL_100 }, tooltipText)),
        };
    return (React.createElement("div", { className: "payment_method__manage-actions-container" },
        React.createElement(PaymentMethodManageActionButton, { actionType: PaymentMethodActionButtonType.PRIMARY, isDisabled: isPrimaryDisabled, onClick: onPrimary, tooltipProps: tooltipProps }),
        React.createElement(PaymentMethodManageActionButton, { actionType: PaymentMethodActionButtonType.UPDATE, isDisabled: isUpdateDisabled, onClick: onUpdate }),
        React.createElement(PaymentMethodManageActionButton, { actionType: PaymentMethodActionButtonType.DELETE, isDisabled: isSubmitting, onClick: onDelete })));
};
export default PaymentMethodManageActions;

import PropTypes from 'prop-types';
import React from 'react';

import { FormControl } from 'components/form';
import { text } from 'components/hintTypes/constants';
import { textHelpers } from 'components/hintTypes/helpers';

import { Intent } from 'constants/ui';

import { getPaymentOrInvoiceText } from 'helpers/items';

import BaseHint from '../BaseHint';

/**
 * Hint to display when creating an item and not selecting a specific date.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 * @constructor
 */
const SendPaymentOnSpecificDateHint = ({ formUI, item }) => {
  if (formUI.sendItem) {
    return null;
  }

  const itemType = getPaymentOrInvoiceText(item);

  return (
    <FormControl>
      <BaseHint
        intent={Intent.DANGER}
        multiline
        text={text.sendPaymentOnSpecificDateHint.text}
        title={textHelpers.getSendPaymentOnSpecificDateHintTitle(itemType)}
      />
    </FormControl>
  );
};

SendPaymentOnSpecificDateHint.propTypes = {
  formUI: PropTypes.shape().isRequired,
  item: PropTypes.shape().isRequired,
};

export default SendPaymentOnSpecificDateHint;

import React from 'react';

import { isUndef } from 'helpers/utility';

/**
 * Handles updating (or ignoring updates) to a component's value.
 * If the component value is controlled externally, updates should be ignored.
 * If the value is controlled by internal state, updates should be processed
 * as called.
 * @param {Object} options
 * @param {*} options.defaultValue
 * @param {*} options.value
 * @return {ReactUseState}
 */
const useControlledOrUncontrolled = ({ defaultValue, value }) => {
  const isControlled = !isUndef(value);

  const [controlledValue, setControlledValue] = React.useState(defaultValue);

  const handleChangeIfUncontrolled = React.useCallback(
    (newValue) => {
      if (!isControlled) {
        setControlledValue(newValue);
      }
    },
    [isControlled],
  );

  return [isControlled ? value : controlledValue, handleChangeIfUncontrolled];
};

export default useControlledOrUncontrolled;

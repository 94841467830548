import { REST } from 'helpers/api';

import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { companyTagsEndpoint, createItemTagLinksEndpoint } from 'services/api/tagsEndpoints';

export const API_TYPES = {
  TAG: 'Tag',
  TAG_LINK: 'TagLink',
};

/**
 * createTag
 * Fetch all tags for the logged in user's company
 * @param {Object} props
 * @param {String} props.tagName
 * @param {object} options
 * @return {Promise<*>}
 */
export const createTag = async ({ tagName }, options = {}) => {
  const payload = payloadToJSONAPI({ name: tagName }, API_TYPES.TAG);

  return REST.post({
    ...options,
    endpoint: companyTagsEndpoint,
    requireAuth: true,
    setRequester: true,
    payload,
  });
};

/**
 * fetchCompanyTags
 * Fetch all tags for the logged in user's company
 * @param {Object} options
 * @return {Promise<*>}
 */
export const fetchCompanyTags = async (options = {}) =>
  REST.get({
    ...options,
    endpoint: companyTagsEndpoint,
    requireAuth: true,
  });

/**
 * createItemTagLinks
 * Create a TagLink between an item and a Tag
 * @param {object} props
 * @param {string} props.tagId
 * @param {string} props.itemId
 * @param {object} options
 */
export const createItemTagLinks = async ({ tagId, itemId }, options = {}) => {
  const payload = payloadToJSONAPI({ tag: { id: tagId } }, API_TYPES.TAG_LINK, 'tag');

  return REST.post({
    ...options,
    endpoint: createItemTagLinksEndpoint(itemId),
    requireAuth: true,
    payload,
  });
};

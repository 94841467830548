import PropTypes from 'prop-types';
import React from 'react';

import { FlexRow, Icon, TitleWithSubtitle, TooltipIcon } from 'components';
import { iconTooltipPropTypes } from 'components/commonProps';

import { sizes } from 'constants/styles';

import { getIntentColor, getIntentIconName } from 'helpers/ui';

import getIconTooltipProps from './helpers/getIconTooltipProps';

/**
 * Title and Subtitle wrapper meant to be used inside Segment.
 * @param {ComponentProps} props
 * @param {Object} [props.iconTooltipProp={}]
 * @param {string} [props.intent]
 * @param {string} [props.statusText]
 * @param {string} [props.subtitle]
 * @param {string|Node} [props.title]
 * @returns {StatelessComponent}
 */
const SegmentTitleWithSubtitle = (props) => {
  const { iconTooltipProps, intent, statusText, subtitle, title } = props;

  const iconTooltipProperties = getIconTooltipProps(iconTooltipProps);
  const titleEl = [
    <h3 className="segment--title" key="segment-title">
      {statusText && (
        <Icon
          className="margin-right--6"
          color={getIntentColor(intent)}
          icon={getIntentIconName(intent)}
          size={sizes.iconSizes.LARGE}
        />
      )}
      {statusText || title}
    </h3>,
  ];

  const subtitleEl = subtitle ? (
    <FlexRow className="align-items--center" stackOnMobile={false}>
      <p className="segment--subtitle remove-margin-bottom">{subtitle}</p>
      {iconTooltipProperties.icon && <TooltipIcon icon={iconTooltipProperties.icon} {...iconTooltipProperties} />}
    </FlexRow>
  ) : undefined;

  return (
    <TitleWithSubtitle
      subtitleProps={{ rowClassName: 'remove-margin-top' }}
      subtitles={[subtitleEl]}
      titles={titleEl}
    />
  );
};

SegmentTitleWithSubtitle.propTypes = {
  iconTooltipProps: iconTooltipPropTypes,
  intent: PropTypes.string,
  statusText: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

SegmentTitleWithSubtitle.defaultProps = {
  iconTooltipProps: {},
  intent: undefined,
  statusText: undefined,
  subtitle: undefined,
  title: undefined,
};

export default SegmentTitleWithSubtitle;

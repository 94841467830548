import { isCountryCodeUSOrUSTerritory } from 'helpers/countries';
export const isCompanyCountryCodeDefinedAndInternational = (company) => Boolean(company?.countryCode) && !isCountryCodeUSOrUSTerritory(company.countryCode);
export const splitPersonalCompanyNameToFirstLast = (personalCompanyName) => {
    const [legalFirstName, ...lastNameArr] = personalCompanyName?.split(' ') ?? [];
    const legalLastName = lastNameArr.length > 0 ? lastNameArr.join(' ') : undefined;
    return {
        legalFirstName,
        legalLastName,
    };
};

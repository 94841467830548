import React, { useRef } from 'react';
import { TablePagination, TableSelection } from '../../../Table/components';
import { useTableSelection } from '../../../Table/hooks';
import { RoutableEventsSync, useEventSync } from '@routable/framework';
import { Button } from '@routable/gross-ds';
import { Footer } from '@routable/gross-ds/dist/components/Dialog/DialogContent.component';
import { LookupTableContainer } from '../..//ModalLookupTable.styled';
import { ModalLookupTableSearch } from '../ModalLookupTableSearch';
import {} from './ModalLookupTableWrapper.types';
import { useTableStore } from '../../../Table';
export const ModalLookupTableWrapper = ({ children, isMultiSelect = true, onClose, onSubmit, searchPlaceHolderText = 'Search', setSearch, submitText = 'Confirm', tableName, }) => {
    const { setTableStateItem } = useTableStore(tableName, undefined, true);
    const selectedIds = useTableSelection({
        tableName,
    });
    const isMinWidthSet = useRef(false);
    useEventSync(`table:${tableName}:data`, () => {
        if (!isMinWidthSet.current) {
            const table = document.querySelector('.routable-table');
            if (table) {
                const compStyles = window.getComputedStyle(table);
                table.style.width = `min(calc(100vw - 128px), ${compStyles.width})`;
                isMinWidthSet.current = true;
            }
        }
    });
    const handleClearSelection = () => {
        setTableStateItem('selections', []);
        RoutableEventsSync.Publish(`table:${tableName}:selected:updated`, []);
    };
    const handleCloseModal = () => {
        onClose?.();
        handleClearSelection();
    };
    const handleSubmitModal = () => {
        onSubmit?.(selectedIds);
        handleClearSelection();
    };
    const showTableSelection = isMultiSelect && Boolean(selectedIds?.length);
    return (React.createElement("div", { className: "pb-2" },
        React.createElement("div", { className: "h-[38px] my-3 mx-[10px] flex justify-between" },
            React.createElement("div", { className: "flex flex-shrink-0 items-center gap-4" },
                setSearch && (React.createElement("div", { className: "w-[216px]" },
                    React.createElement(ModalLookupTableSearch, { searchPlaceHolderText: searchPlaceHolderText, setSearch: setSearch }))),
                showTableSelection && React.createElement(TableSelection, { numOfSelectedItems: selectedIds?.length, tableName: tableName })),
            React.createElement("div", null,
                React.createElement(TablePagination, { tableName: tableName }))),
        React.createElement(LookupTableContainer, null, children),
        (onClose || onSubmit) && (React.createElement(Footer, { className: "pb-4" },
            onClose && (React.createElement(Button, { onPress: handleCloseModal, variant: "outline", intent: "secondary", "data-testid": "modal-lookup:cancel" }, "Cancel")),
            onSubmit && (React.createElement(Button, { disabled: !selectedIds?.length, onPress: handleSubmitModal, "data-testid": "modal-lookup:confirm" }, submitText))))));
};

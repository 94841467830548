import { createIsFetchingReducer } from 'store/redux';

import {
  FETCH_THREAD_REQUEST,
  FETCH_THREADS_REQUEST,
  FETCH_THREADS_FAILURE,
  FETCH_THREADS_SUCCESS,
  FETCH_THREAD_FAILURE,
  FETCH_THREAD_SUCCESS,
} from 'types/thread';

export const finishCases = [FETCH_THREADS_FAILURE, FETCH_THREADS_SUCCESS, FETCH_THREAD_FAILURE, FETCH_THREAD_SUCCESS];
export const requestCases = [FETCH_THREAD_REQUEST, FETCH_THREADS_REQUEST];

const fetchReducer = createIsFetchingReducer(finishCases, requestCases);

export default fetchReducer;

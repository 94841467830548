/**
 * @module data/submitTransformers/itemMember
 */

import { ItemMemberProps } from 'data/resources/itemMember';

import { flipPartnershipMemberAccess } from 'helpers/partnershipMembers';
import { valueForFirstKey } from 'helpers/utility';

/**
 * Format a PartnershipMember for submit as an ItemMember.
 * @function
 * @param {Object} contactForm
 * @param {PartnershipMemberAccess} contactForm.accessItem
 * @param {Object} contactForm.value
 * @param {PartnershipMember.id} contactForm.value.id
 * @returns {Object} - payload
 */
export const addPartnershipMemberAsItemMember = ({ accessItem, value: { id } }) => ({
  accessItem,
  partnershipMember: { id },
});

/**
 * Extracts ItemMember data from a PartnershipMember/ItemMember combo.
 * @function
 * @param {ItemMemberAsPartnershipMember} contact
 * @param {ItemMember.id} contact.itemMember
 * @param {ItemMember.accessItem} contact.accessItem
 * @returns {ItemMember}
 */
export const reduceItemMemberAsPartnershipMemberToItemMember = ({ itemMember: id, accessItem }) => ({
  id,
  accessItem,
});

/**
 * Prepare an ItemMember for PATCH. Our selector combines the PartnershipMember and ItemMember for convenience,
 * so this extracts only the ItemMember data. Also makes it easy just to toggle between actionable/read-only
 * accessItem.
 * @param {ItemMemberAsPartnershipMember} partnershipMember
 * @param {boolean} [shouldFlipItemAccess]
 * @returns {ItemMember}
 */
export const partnershipMemberIntoItemMemberForUpdate = (partnershipMember, shouldFlipItemAccess) => {
  const { accessItem } = partnershipMember;

  // Make it easy to quick toggle users between read-only and actionable on items
  const newItemAccess = shouldFlipItemAccess
    ? flipPartnershipMemberAccess({ accessItem }, ItemMemberProps.accessItem)
    : accessItem;

  return reduceItemMemberAsPartnershipMemberToItemMember({
    ...partnershipMember,
    accessItem: newItemAccess,
  });
};

/**
 * When the createPartnershipMember saga yields, it yields a new PartnershipMember. Transform the PartnershipMember into
 * an ItemMember to ready it for submission.
 * @function
 * @param {Object} options
 * @param {Item.id} options.itemId
 * @param {Object} options.newPartnershipMember - { 'random-id': { id: 'random-id' } }
 * @param {PartnershipMemberAccess} options.accessItem
 * @returns {Object}
 */
export const transformNewPartnershipMemberIntoItemMemberPayload = (options) => {
  const { itemId, newPartnershipMember, accessItem } = options;
  const { id } = valueForFirstKey(newPartnershipMember);

  return {
    props: { item: { id: itemId } },
    values: {
      contacts: {
        accessItem,
        value: { id },
      },
    },
  };
};

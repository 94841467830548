import { all, call, put, spawn, take } from 'redux-saga/effects';

import { getRequestErrorAction, parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { isReduxSagaRoutine } from 'helpers/routine';
import { isArray, isEqual } from 'helpers/utility';

import { payloadToCamelCase } from 'services/api/formatHelpers';

/**
 * Helper which differentiates between a redux-saga-routine and manual action creators. If the argument passed is a
 * redux-saga-routine, it spreads the common action creators onto an object.
 * @param {ReduxSagaRoutine|*} [actions]
 * @returns {*}
 */
export const getActionDispatchers = (actions) => {
  if (isReduxSagaRoutine(actions)) {
    return {
      failure: actions.failure,
      request: actions.request,
      success: actions.success,
      trigger: actions.trigger,
    };
  }

  return actions;
};

/**
 * Boilerplate for a generic saga generator function.
 * @function
 * @see partnershipMember/sagas
 * @param {function} api - API configuration options
 * @param {ReduxSagaRoutine|Object} actions
 * @param {ObjectMaybe} [options={}]
 * @returns {IterableIterator<*>}
 */
export function* createSaga(api, actions, options = {}) {
  const { failure, request, success } = getActionDispatchers(actions);

  const {
    apiParams = [],
    onCaughtError = parseCaughtError,
    onParseError = parseErrorResponse,
    onSuccess,
    onSuccessCallback,
    onError,
  } = options;

  let errorData = {};

  if (request) {
    yield put(request());
  }

  try {
    const response = yield call(api, ...apiParams);

    if (response.ok) {
      const parsedResponse = payloadToCamelCase(response.data);

      // trigger the success action creator/routine
      // you can listen to this action creator to handle more side effects
      yield put(success(parsedResponse));

      // or use this onSuccess to handle anything in a callback
      if (onSuccess) {
        // if onSuccess is an array of actions, we want to map over those actions
        // and create a put effect for each action
        if (isArray(onSuccess)) {
          // for example: close a side sheet and open a success toast dispatch
          const actions = onSuccess.map((action) => put(action()));
          yield all(actions);
        } else {
          // for example: dispatch a success toast
          yield put(onSuccess());
        }
      }

      // Sometimes, we want to just call something on success instead
      // of dispatching an action (as we're doing above)
      if (onSuccessCallback && typeof onSuccessCallback === 'function') {
        yield call(onSuccessCallback);
      }

      return;
    }

    errorData = onParseError(response);

    if (onError) {
      yield put(onError(errorData));
    }
  } catch (error) {
    errorData = onCaughtError(error);
  }
  const errorAction = getRequestErrorAction(errorData);
  yield put(errorAction(failure, errorData));
}

/**
 * sagaWatcher
 * Creates a saga watcher so you don't have to:
 * How to use:
     export default function* existingItem() {
        yield sagaWatcher([
          {
             type: types.SUBMIT_EXISTING_ITEMS_FAILURE,
             saga: handleRequestErrors,
          }
       ]);
     }
 * @param sagasToWatch
 * @return {IterableIterator<*>}
 */
export function* sagaWatcher(sagasToWatch) {
  while (true) {
    const action = yield take(sagasToWatch.map((saga) => saga.type));

    const sagaToWatch = sagasToWatch.find((saga) => isEqual(saga.type, action.type));

    yield spawn(sagaToWatch.saga, action);
  }
}

import { getModalReducerOpenAction } from 'store/redux';

import * as types from 'types/ledger';

export const initialState = {
  open: false,
};

const forceManualLedgerSyncModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CLOSE_FORCE_MANUAL_LEDGER_SYNC_MODAL:
      return initialState;

    case types.OPEN_FORCE_MANUAL_LEDGER_SYNC_MODAL:
      return getModalReducerOpenAction(state, action);

    default:
      return state;
  }
};

export default forceManualLedgerSyncModalReducer;

export const FETCH_DOCUMENTS_FAILURE = 'FETCH_DOCUMENTS_FAILURE';
export const FETCH_DOCUMENTS_REQUEST = 'FETCH_DOCUMENTS_REQUEST';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';

export const FETCH_DOCUMENT_FAILURE = 'FETCH_DOCUMENT_FAILURE';
export const FETCH_DOCUMENT_REQUEST = 'FETCH_DOCUMENT_REQUEST';
export const FETCH_DOCUMENT_SUCCESS = 'FETCH_DOCUMENT_SUCCESS';

export const DELETE_COMPANY_DOCUMENT = 'DELETE_COMPANY_DOCUMENT';
export const DELETE_REPRESENTATIVE_DOCUMENT = 'DELETE_REPRESENTATIVE_DOCUMENT';

export const POST_COMPANY_DOCUMENT = 'POST_COMPANY_DOCUMENT';
export const POST_REPRESENTATIVE_DOCUMENT = 'POST_REPRESENTATIVE_DOCUMENT';

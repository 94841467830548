/**
 * Logical combiner for the currentMembershipPersonalInfoSelector
 * @param {Object.<String,Membership>} memberships
 * @param {Object.<String,Object>} membershipPersonalInfos
 * @param {string} membershipId
 * @return {Object}
 */
export const currentMembershipPersonalInfoCombiner = (memberships, membershipPersonalInfos, membershipId) => {
  const currentMembership = memberships[membershipId];

  if (!currentMembership?.personalInfo) {
    return {};
  }

  return membershipPersonalInfos[currentMembership.personalInfo] || {};
};

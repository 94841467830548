import { useEffect, useCallback, useRef } from 'react';
import { addClass, attr, removeAttr, removeClass } from './useClick.helpers';
export const useClick = (options) => {
    const elemHelper = useRef({});
    const clickingRef = useRef({ isClicking: false });
    const handlersRef = useRef(options);
    handlersRef.current = options;
    const onActionOver = useCallback((ev) => {
        if (handlersRef.current?.onOver &&
            handlersRef.current?.onOver !== undefined) {
            handlersRef.current.onOver(ev);
        }
    }, []);
    const onActionMove = useCallback((ev) => {
        if (handlersRef.current?.onMove &&
            handlersRef.current?.onMove !== undefined) {
            handlersRef.current.onMove(ev);
        }
    }, []);
    const onActionLeave = useCallback((ev) => {
        if (elemHelper.current) {
            removeClass(elemHelper.current, 'routable-active');
            removeClass(elemHelper.current, 'routable-hover');
        }
        if (handlersRef.current?.onOut &&
            handlersRef.current?.onOut !== undefined) {
            handlersRef.current.onOut(ev);
        }
    }, []);
    const onActionStart = useCallback((ev) => {
        if (ev.button === 2) {
            options?.onRightClick?.(ev);
        }
        if (clickingRef.current.isClicking || ev.button > 0) {
            return;
        }
        if (elemHelper.current) {
            addClass(elemHelper.current, 'routable-active');
        }
        if (options?.onDown) {
            options.onDown(ev);
        }
        clickingRef.current.isClicking = true;
    }, []);
    const onActionEnd = useCallback((ev) => {
        if (clickingRef.current.isClicking) {
            if (handlersRef.current?.onClick) {
                try {
                    handlersRef.current.onClick(ev);
                }
                catch {
                }
            }
            if (options?.onUp) {
                options.onUp(ev);
            }
            if (elemHelper.current) {
                removeClass(elemHelper.current, 'routable-active');
                removeClass(elemHelper.current, 'routable-hover');
            }
            if (options?.groupId &&
                options?.groupId !== undefined &&
                options?.groupId !== '') {
                const items = document.documentElement.querySelectorAll(`[data-group-id="${options?.groupId}"]`);
                if (items && items?.length) {
                    for (let i = 0; i < items?.length; i++) {
                        try {
                            items[i].classList.remove('routable-group-active');
                        }
                        catch {
                        }
                    }
                }
                if (elemHelper.current) {
                    addClass(elemHelper.current, 'routable-group-active');
                }
            }
            clickingRef.current.isClicking = false;
        }
    }, []);
    useEffect(() => {
        if (options?.element && options?.element?.current) {
            const elemCurrent = options.element.current;
            elemHelper.current = elemCurrent;
            if (handlersRef.current?.onClick &&
                handlersRef.current?.onClick !== undefined) {
                if (handlersRef.current.onMove ||
                    handlersRef.current.onOut ||
                    handlersRef.current.onOver) {
                    addClass(elemHelper.current, 'routable-interaction');
                }
                if (options && options.active && elemHelper.current) {
                    addClass(elemHelper.current, 'routable-group-active');
                }
                if (options?.groupId &&
                    options?.groupId !== undefined &&
                    options?.groupId !== '') {
                    attr(elemHelper.current, 'data-group-id', options?.groupId);
                }
                addClass(elemHelper.current, 'routable-click');
                elemCurrent.removeEventListener('pointerdown', onActionStart, false);
                elemCurrent.addEventListener('pointerdown', onActionStart, {
                    capture: true,
                    passive: true,
                });
                elemCurrent.removeEventListener('pointerup', onActionEnd, false);
                elemCurrent.addEventListener('pointerup', onActionEnd, {
                    capture: true,
                    passive: true,
                });
            }
            elemCurrent.removeEventListener('pointerover', onActionOver, false);
            elemCurrent.addEventListener('pointerover', onActionOver, {
                capture: true,
                passive: true,
            });
            if (handlersRef.current.onMove) {
                elemCurrent.removeEventListener('pointermove', onActionMove, false);
                elemCurrent.addEventListener('pointermove', onActionMove, {
                    capture: true,
                    passive: true,
                });
            }
            if (handlersRef.current.onOut) {
                elemCurrent.removeEventListener('pointerleave', onActionLeave, false);
                elemCurrent.addEventListener('pointerleave', onActionLeave, {
                    capture: true,
                    passive: true,
                });
            }
        }
        return () => {
            if (options.element && options.element.current) {
                const elemCurrent = options.element.current;
                if (elemHelper.current) {
                    removeClass(elemHelper.current, 'routable-click');
                    removeClass(elemHelper.current, 'routable-hover');
                    if (options?.groupId &&
                        options?.groupId !== undefined &&
                        options?.groupId !== '') {
                        removeAttr(elemHelper.current, 'data-group-id');
                    }
                }
                elemCurrent.removeEventListener('pointerdown', onActionStart, false);
                elemCurrent.removeEventListener('pointerup', onActionEnd, false);
                elemCurrent.removeEventListener('pointerover', onActionOver, false);
                elemCurrent.removeEventListener('pointerleave', onActionLeave, false);
                elemCurrent.removeEventListener('pointermove', onActionMove, false);
            }
        };
    }, [onActionStart, onActionEnd]);
};

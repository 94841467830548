import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Sidebar from 'react-sidebar';

import { defaultSidePanelWidth } from 'constants/sidePanels';

import { deepMergeWithArrayReplacement } from 'helpers/transform';

import { sidePanelWithOverlayStyles } from '../helpers';
import SidePanel from '../SidePanel';

import './SidePanelWithOverlay.scss';

// PSA: Design & QA refer to sidepanels as sidesheets. We will be renaming these as part of https://warrenpay.atlassian.net/browse/FRON-2424
const SidePanelWithOverlay = ({ children, className, headerText, onClose, sidePanel, sidePanelContent, styles }) => {
  const sidebarStyles = deepMergeWithArrayReplacement(sidePanelWithOverlayStyles, styles);

  const sidebar = (
    <SidePanel headerText={headerText} onClose={onClose}>
      {sidePanelContent}
    </SidePanel>
  );

  return (
    <Sidebar
      defaultSidebarWidth={defaultSidePanelWidth}
      docked={false}
      headerText={headerText}
      onClose={onClose}
      open={sidePanel.open}
      pullRight
      sidebar={sidebar}
      styles={sidebarStyles}
    >
      <div
        className={classNames('sidepanel--with-overlay__main-view--container', {
          [className]: !!className,
        })}
      >
        <div className="sidepanel--with-overlay__main-view--content">{children}</div>
      </div>
    </Sidebar>
  );
};

SidePanelWithOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  headerText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  sidePanel: PropTypes.shape({
    open: PropTypes.bool,
  }).isRequired,
  sidePanelContent: PropTypes.node.isRequired,
  styles: PropTypes.shape({}),
};

SidePanelWithOverlay.defaultProps = {
  className: undefined,
  headerText: undefined,
  styles: {},
};

export default SidePanelWithOverlay;

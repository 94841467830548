import React from 'react';

import { getFundingAccountForFundingBalanceInfo } from 'helpers/funding';

import { usePrevious } from 'hooks';

/**
 * Hook for use in <Balance /> settings component. Refreshes balance data.
 * @param {ComponentProps} props - Balance component props
 * @param {string} props.balanceInfoId
 * @param {ByIdCollection} props.fundingAccountsById
 * @param {boolean} props.hasSubmitSucceeded
 * @param {Function} props.onFetchBalanceFundingAccount
 * @param {Function} props.onFetchTransactions
 * @return {{areTransactionsRefreshed: Boolean}}
 */
export const useBalanceSettingsRefreshEffect = ({
  balanceInfoId,
  fundingAccountsById,
  hasSubmitSucceeded,
  onFetchBalanceFundingAccount,
  onFetchTransactions,
}) => {
  const [isBalanceRefreshed, setBalanceRefreshed] = React.useState(false);
  const [areTransactionsRefreshed, setTransactionsRefreshed] = React.useState(false);
  const prevSubmitSucceeded = usePrevious(hasSubmitSucceeded);

  React.useEffect(() => {
    if (!areTransactionsRefreshed) {
      onFetchTransactions();
      setTransactionsRefreshed(true);
    }

    if (!isBalanceRefreshed || (!prevSubmitSucceeded && hasSubmitSucceeded)) {
      const balanceFundingAccount = getFundingAccountForFundingBalanceInfo(balanceInfoId, fundingAccountsById);

      if (balanceFundingAccount) {
        setBalanceRefreshed(true);
        onFetchBalanceFundingAccount(balanceFundingAccount.id, {
          delay: 600,
        });
      }
    }
  }, [
    areTransactionsRefreshed,
    balanceInfoId,
    fundingAccountsById,
    hasSubmitSucceeded,
    isBalanceRefreshed,
    onFetchBalanceFundingAccount,
    onFetchTransactions,
    prevSubmitSucceeded,
  ]);

  return { areTransactionsRefreshed };
};

/**
 * Hook for use in <Balance /> settings component. Returns a callback to
 * be used for loading the next page of transaction data.
 * @param {ComponentProps} props - Balance component props
 * @param {Function} props.onFetchTransactions
 * @param {Object} props.pagination
 * @return {Function}
 */
export const useLoadMoreTransactionsCallback = (props) => {
  const { onFetchTransactions, pagination } = props;

  return React.useCallback(() => {
    const actionPayload = {
      queryParams: {
        'page[offset]': pagination.offset,
      },
    };
    const actionMeta = { append: true };
    onFetchTransactions(actionPayload, actionMeta);
  }, [onFetchTransactions, pagination.offset]);
};

import { Hint } from '@routable/components';
import { DateFormats } from '@routable/shared';
import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import React from 'react';
import {} from '../../models';
dayjs.extend(dayjsUtc);
export const ManuallyVerifiedHint = ({ dismissal }) => {
    const dismissedOn = dayjs.utc(dismissal.at).format(DateFormats.ALPHA_MONTH_FULL_YEAR);
    return React.createElement(Hint, { title: `${dismissal.name} manually verified this vendor on ${dismissedOn}.`, type: "info" });
};

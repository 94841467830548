import pluralize from 'pluralize';
import React from 'react';
import ExternalLink from 'components/ExternalLink';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { Intent } from 'constants/ui';
import helpDocs from 'helpers/helpDocs';
import { getCustomerOrVendorOrBothTextByPartnership, getPartnershipTypeLedgerLink } from 'helpers/partnerships';
import { lengthOf } from 'helpers/utility';
import BaseHint from '../BaseHint';
const CurrencyNotSupportedHint = ({ ledgerName, partnership }) => {
    const partnershipType = getCustomerOrVendorOrBothTextByPartnership(partnership);
    const partnershipCurrencies = partnership.ledgerCurrencies;
    const partnershipCurrenciesLength = lengthOf(partnershipCurrencies);
    const currenciesText = partnershipCurrenciesLength > 1
        ? `${partnershipCurrencies.slice(0, -1).join(', ')} and ${partnershipCurrencies.slice(-1)}`
        : partnershipCurrencies[0];
    return (React.createElement(BaseHint, { intent: Intent.DANGER, text: React.createElement(React.Fragment, null,
            `We cannot add this ${partnershipType} (`,
            React.createElement(ExternalLink, { className: "primary", href: getPartnershipTypeLedgerLink(partnership, partnershipType) }, partnership.name),
            `) to ${PLATFORM_DISPLAY_SHORT_NAME} because they are only configured for ${currenciesText} in ${ledgerName}. `,
            React.createElement(ExternalLink, { className: "primary", href: helpDocs.PARTNER_COUNTRY_CURRENCY_HINT }, "Learn more about supported currencies"),
            "."), title: `This ${partnershipType}'s ${pluralize('currency', partnershipCurrenciesLength)} (${partnershipCurrencies.join(', ')}) ${pluralize('is', partnershipCurrenciesLength)} currently not supported` }));
};
export default CurrencyNotSupportedHint;

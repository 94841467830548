import { SUBMIT_SIGNUP_CREATE_ACCOUNT_PASSWORD_ERROR } from 'types/signup';

const signupPasswordErrorReducer = (state = '', action) => {
  if (action.type === SUBMIT_SIGNUP_CREATE_ACCOUNT_PASSWORD_ERROR) {
    return action.payload;
  }

  return state;
};

export default signupPasswordErrorReducer;

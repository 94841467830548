import React from 'react';

import { IconNames, Text, TooltipMUIIcon, WhiteSpace } from 'components';

import { CONTACT_SUPPORT_LABEL } from 'constants/labels';
import { PLATFORM_EMAILS } from 'constants/platform';
import { colors, sizes } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';

import { getMailtoUrl } from 'helpers/urls';

/**
 * Renders an info icon with tooltip informing the user to check his email for a unique code
 * @return {StatelessComponent}
 */
const InvitationCodeInputTooltip = () => (
  <TooltipMUIIcon
    icon={IconNames.INFO_SIGN}
    iconProps={{
      className: 'not-modified tooltip-icon',
      color: colors.colorGreyXDarkHex,
      size: sizes.iconSizes.EXTRA_LARGE,
    }}
    title={
      <Text.Regular>
        This is a unique code you should have received via email. Please check your inbox and spam folder. Didn&apos;t
        receive your code?
        <WhiteSpace />
        <a className="primary enable-pointer-events" href={getMailtoUrl(PLATFORM_EMAILS.SALES)}>
          {CONTACT_SUPPORT_LABEL}
        </a>
      </Text.Regular>
    }
    tooltipProps={{
      arrow: true,
      padding: TooltipPadding.LARGE,
      placement: TooltipPlacement.TOP,
    }}
  />
);

export default InvitationCodeInputTooltip;

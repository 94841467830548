import _sortBy from 'lodash/sortBy';

import { TAG_MAX_CHAR_LENGTH } from 'constants/tags';
import { TagType } from 'constants/ui';

import { convertToLowerCase } from 'helpers/stringHelpers';
import { every, isGreaterThan, isLessOrEqual, lengthOf } from 'helpers/utility';

/**
 * createSelectOptionTag
 * Turns a tag object into an object that can be displayed as an option on a Select
 * @param {tag} tag
 * @param {String} id
 * @param {TagType} type
 * @param {String} name
 * @returns {[{ id: string, label: string, value: string }]} tags
 */
export const createSelectOptionTag = ({ id, name, ...tag }) => ({
  ...tag,
  id,
  label: name,
  value: name,
});

/**
 * createMetaTag
 * Turns a tag object into a meta tag
 * @param tag
 * @returns {{hasIcon: boolean, type: (TagType|string), isClosable: boolean}}
 */
export const createMetaTag = (tag) => ({
  ...tag,
  type: TagType.META,
  hasIcon: false,
  isClosable: false,
});

/**
 * createErrorTag
 * Turns a tag object into an error tag
 * @param tag
 * @returns {{hasIcon: boolean, type: (TagType|string), isClosable: boolean}}
 */
export const createErrorTag = ({ tagName, errors, onClose }) => ({
  type: TagType.ERROR,
  id: tagName,
  label: tagName,
  value: tagName,
  tooltipContent: errors,
  onClose,
});

/**
 * sortTagsAZ
 * Sorts an array of tags into an alphabetically sorted array of tags
 * @param {[Tag]} tags
 * @returns {[Tag]}
 */
export const sortTagsAZ = (tags) => _sortBy(tags, [(tag) => convertToLowerCase(tag.label || tag.name || tag.value)]);

/**
 * getTagName
 * Returns the name of a tag if it exists
 * @param {Tag} tag
 * @returns {String|undefined}
 */
export const getTagName = (tag) => tag?.name;

/**
 * doesListIncludeTag
 * Returns the name of a tag if it exists
 * @param {object} props
 * @param {[Tag]} props.list
 * @param {string} props.tagName
 * @returns {Boolean}
 */
export const doesListIncludeTag = ({ list = [], tagName }) => list.map(getTagName).includes(tagName);

/**
 * isValidNewTagOption
 * Checks if a tag input value is valid:
 * - at least 1 character
 * - less than 64 characters
 * - not included in a given list
 * This lets the "Create <tag>" option display/hide in TagsSidePanel
 * @param {[Tag]} list
 * @returns {function(value: string): boolean}
 */
export const isValidNewTagOption =
  (list = []) =>
  (value = '') =>
    every([
      isGreaterThan(lengthOf(value), 1),
      isLessOrEqual(lengthOf(value), TAG_MAX_CHAR_LENGTH),
      !doesListIncludeTag({ list, tagName: value }),
    ]);

/**
 * createTagsWithMetaSelectProps
 * Takes an array of tags and returns an array of tags with the right props to be:
 * - meta tags
 * - select option tags
 * @param {[Tag]} tags
 * @returns {{id: String, label: String, value: String}[]}
 */
export const createTagsWithMetaSelectProps = (tags = []) => tags.map(createMetaTag).map(createSelectOptionTag);

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';

import { getChildrenForTableColumn, getTableCellDisabledState } from './helpers/cells';
import TableColumn from './TableColumn';

/**
 * Renders a single row of data in a table. Has disabled and warning states. The data prop includes information about
 * how to iteratively create the columns.
 * @param {object} data
 * @param {boolean} [enforceFieldValidation=true]
 * @param {boolean} [isDisabled] - destructure renamed to isRowDisabled for readability
 * @param {boolean} [isWarn] - row warn state, f.e. needs partnership matching
 * @param {string} [linkURL] - If supplied, wraps the cells in a link
 * @returns {StatelessComponent}
 */
const TableDataRow = ({ data, enforceFieldValidation, isDisabled: isRowDisabled, isWarn, linkURL, ...props }) => {
  const elements = [];

  Object.keys(data).forEach((key) => {
    const column = data[key];
    const isDisabled = isRowDisabled || getTableCellDisabledState(column.props, props.rowData);
    const childrenForColumn = getChildrenForTableColumn(key, props.columnChildren);

    elements.push(
      <TableColumn
        baseClassName={column.baseClassName}
        className={classNames({
          [column.className]: true,
          disabled: isDisabled,
          warn: isWarn,
        })}
        contentClassName={column.contentClassName}
        key={`${props.idx !== undefined ? props.idx : v4()}-${key}`}
      >
        {
          /* Make sure override the prop isDisabled with newly calculated isDisabled */
          React.createElement(
            column.obj,
            {
              ...column.props,
              ...props,
              enforceFieldValidation,
              isDisabled,
            },
            childrenForColumn,
          )
        }
      </TableColumn>,
    );
  });

  return linkURL ? (
    <li style={props.rowStyle}>
      <Link className="table-row--link-wrapper remove-hover-underline" to={linkURL}>
        <div className={props.rowClassName}>{elements}</div>
      </Link>
    </li>
  ) : (
    <li className={props.rowClassName} style={props.rowStyle}>
      {elements}
    </li>
  );
};

TableDataRow.propTypes = {
  columnChildren: PropTypes.shape(),
  data: PropTypes.shape().isRequired,
  enforceFieldValidation: PropTypes.bool,
  idx: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDisabled: PropTypes.bool,
  isWarn: PropTypes.bool,
  linkURL: PropTypes.string,
  rowClassName: PropTypes.string,
  rowData: PropTypes.shape(),
  rowStyle: PropTypes.shape(),
  membership: PropTypes.shape(),
};

TableDataRow.defaultProps = {
  columnChildren: undefined,
  enforceFieldValidation: true,
  idx: undefined,
  isDisabled: undefined,
  isWarn: undefined,
  linkURL: null,
  rowClassName: 'table-row--container',
  rowData: {},
  rowStyle: {},
  membership: undefined,
};

export default TableDataRow;

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { updatePartnerField } from 'actions/partnership';
import { PartnershipNameSettingsForm } from 'modules/dashboard/createPartnership/components/PartnershipNameSettings/components';
import {} from 'modules/dashboard/createPartnership/components/PartnershipNameSettings/components/PartnershipNameSettingsFormProps.types';
import { createPartnershipFormCompanyTypeSelector, createPartnershipFormNameSelector, createPartnershipFormPartnershipTypeSelector, createPartnershipFormPartnerLedgerNameSelector, createPartnershipFormPartnerNameSelector, } from 'selectors/forms';
export const mapStateToProps = createStructuredSelector({
    companyType: createPartnershipFormCompanyTypeSelector,
    displayName: createPartnershipFormNameSelector,
    partnershipType: createPartnershipFormPartnershipTypeSelector,
    partnerLedgerName: createPartnershipFormPartnerLedgerNameSelector,
    partnerName: createPartnershipFormPartnerNameSelector,
});
export const mapDispatchToProps = {
    updatePartnerField,
};
export default connect(mapStateToProps, mapDispatchToProps)(PartnershipNameSettingsForm);

import ThunkFetch from 'helpers/thunkFetch';

import { signupVerifyEmailEndpoint } from 'services/api/signupEndpoints';

import {
  SUBMIT_SIGNUP_VERIFY_EMAIL_FAILURE,
  SUBMIT_SIGNUP_VERIFY_EMAIL_REQUEST,
  SUBMIT_SIGNUP_VERIFY_EMAIL_SUCCESS,
} from 'types/signup';

const submitSignupVerifyEmail = (submitPayload, successCallback) => {
  const t = new ThunkFetch({
    actions: {
      error: SUBMIT_SIGNUP_VERIFY_EMAIL_FAILURE,
      request: SUBMIT_SIGNUP_VERIFY_EMAIL_REQUEST,
      success: SUBMIT_SIGNUP_VERIFY_EMAIL_SUCCESS,
    },
    endpoint: signupVerifyEmailEndpoint,
    method: 'POST',
    payload: submitPayload,
    successCallback,
    requireAuth: true,
  });

  return t.makeRequest();
};

export default submitSignupVerifyEmail;

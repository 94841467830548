import * as routines from 'actions/routines/currencies';

import { referenceCurrency } from 'constants/relationships';

import { createAllIdsReducer } from 'store/redux';

const allIdsReducer = createAllIdsReducer({
  key: referenceCurrency,
  types: [routines.fetchCurrenciesRoutine.SUCCESS],
});

export default allIdsReducer;

export const FETCH_THREADS_FAILURE = 'FETCH_THREADS_FAILURE';
export const FETCH_THREADS_REQUEST = 'FETCH_THREADS_REQUEST';
export const FETCH_THREADS_SUCCESS = 'FETCH_THREADS_SUCCESS';

export const FETCH_THREAD_FAILURE = 'FETCH_THREAD_FAILURE';
export const FETCH_THREAD_REQUEST = 'FETCH_THREAD_REQUEST';
export const FETCH_THREAD_SUCCESS = 'FETCH_THREAD_SUCCESS';

export const SUBMIT_THREAD_REMINDER_FAILURE = 'SUBMIT_THREAD_REMINDER_FAILURE';
export const SUBMIT_THREAD_REMINDER_REQUEST = 'SUBMIT_THREAD_REMINDER_REQUEST';
export const SUBMIT_THREAD_REMINDER_SUCCESS = 'SUBMIT_THREAD_REMINDER_SUCCESS';

import { combineReducers } from 'redux';

import allFundingInfoAddressesReducer from './allFundingInfoAddressesReducer';
import errorReducer from './errorReducer';
import fetchReducer from './fetchReducer';
import fundingInfoAddressesByIdReducer from './fundingInfoAddressesByIdReducer';
import lastFetchedReducer from './lastFetchedReducer';

const fundingInfoAddressReducer = combineReducers({
  allIds: allFundingInfoAddressesReducer,
  byId: fundingInfoAddressesByIdReducer,
  errors: errorReducer,
  isFetching: fetchReducer,
  lastFetched: lastFetchedReducer,
});

export default fundingInfoAddressReducer;

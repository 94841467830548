import styled from 'styled-components';
export const GenericError = styled.span `
  background-color: var(--red--05);
  color: var(--fire-opal);
  font-size: var(--font--12);
  font-weight: var(--font-weight--semibold);
  width: fit-content;
  padding: 0.375rem 0.5rem;
  border-radius: 6.25rem;
  margin-top: 0.5rem;
`;

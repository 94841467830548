export const CLEAR_MEMBERSHIP_INVITE = 'CLEAR_MEMBERSHIP_INVITE';

export const SUBMIT_MEMBERSHIP_INVITE = 'SUBMIT_MEMBERSHIP_INVITE';
export const SUBMIT_MEMBERSHIP_SIGNUP_INVITE = 'SUBMIT_MEMBERSHIP_SIGNUP_INVITE';

export const SUBMIT_MEMBERSHIP_INVITE_ACCEPT_V2 = 'SUBMIT_MEMBERSHIP_INVITE_ACCEPT_V2';

export const FETCH_MEMBERSHIP_INVITE_FAILURE = 'FETCH_MEMBERSHIP_INVITE_FAILURE';
export const FETCH_MEMBERSHIP_INVITE_REQUEST = 'FETCH_MEMBERSHIP_INVITE_REQUEST';
export const FETCH_MEMBERSHIP_INVITE_SUCCESS = 'FETCH_MEMBERSHIP_INVITE_SUCCESS';

export const FETCH_MEMBERSHIP_INVITES_FAILURE = 'FETCH_MEMBERSHIP_INVITES_FAILURE';
export const FETCH_MEMBERSHIP_INVITES_REQUEST = 'FETCH_MEMBERSHIP_INVITES_REQUEST';
export const FETCH_MEMBERSHIP_INVITES_SUCCESS = 'FETCH_MEMBERSHIP_INVITES_SUCCESS';

export const LOOKUP_MEMBERSHIP_INVITE_EMAIL = 'LOOKUP_MEMBERSHIP_INVITE_EMAIL';

export const RESEND_MEMBERSHIP_INVITE = 'RESEND_MEMBERSHIP_INVITE';
export const REVOKE_MEMBERSHIP_INVITE = '@member/REVOKE_MEMBERSHIP_INVITE';

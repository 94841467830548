import styled from 'styled-components';
export const Container = styled.div `
  background-color: var(--white);
  border: 1px solid var(--blue--light);
  border-radius: 0.625rem;
  margin: 1rem 1.5rem;
`;
export const TableHeader = styled.div `
  align-items: center;
  border-bottom: 1px solid var(--blue--light);
  display: flex;
  gap: 1rem;
  padding: 1.5rem;
`;
export const TableTitle = styled.h3 `
  line-height: 1.25rem;
`;
export const TableDescription = styled.p `
  line-height: 1.125rem;
  margin: 0;
`;
export const ButtonContainer = styled.div `
  width: 221px;
`;

import { sendSubmitBillToItemRequestRoutine, submitItemEditRoutine, submitItemRoutine } from 'actions/routines/item';

import { createLastSubmittedReducer } from 'store/redux';

import * as types from 'types/item';

export const requestCases = [types.CLEAR_SUBMITTED_ITEM];

export const successCases = [
  types.SUBMIT_EXISTING_ITEMS_SUCCESS,
  sendSubmitBillToItemRequestRoutine.SUCCESS,
  submitItemEditRoutine.SUCCESS,
  submitItemRoutine.FULFILL,
];

const lastSubmittedReducer = createLastSubmittedReducer(successCases, requestCases);

export default lastSubmittedReducer;

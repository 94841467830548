/**
 * @fileOverview Defines the saga flows for feature flags.
 * @module sagas/featureFlags
 */
import { call } from 'redux-saga/effects';

import * as actions from 'actions/routines/featureFlags';

import { setFeatureFlagsInLocalStorage } from 'helpers/featureFlags';
import { sagaWatcher } from 'helpers/saga';

/**
 * Handle storing feature flag data.
 * @return {IterableIterator<*>}
 */
export function* storeFeatureFlags(action) {
  yield call(setFeatureFlagsInLocalStorage, action.payload);
}

/**
 * Root feature flags saga.
 * @return {IterableIterator<*>}
 */
export default function* featureFlags() {
  yield sagaWatcher([
    {
      type: actions.getFeatureFlagsRoutine.SUCCESS,
      saga: storeFeatureFlags,
    },
  ]);
}

import React, {} from 'react';
import { SwalWrapperV2, Text } from 'components';
import { typography } from 'constants/styles';
import { Intent } from 'constants/ui';
import { noop } from 'helpers/utility';
import './PaginationBlockCSVModal.scss';
export const PaginationBlockCSVModal = ({ emailAddress, onClick }) => (React.createElement(SwalWrapperV2, { leftButtonProps: { children: 'Cancel export', onClick: noop }, rightButtonProps: { children: 'Confirm', intent: Intent.PRIMARY, onClick }, shouldCloseOnClick: true, title: "Confirm your CSV export" },
    React.createElement("p", null,
        "We'll send you an email to ",
        emailAddress,
        " as soon as your file is ready to download."),
    React.createElement("p", null,
        React.createElement(Text.Bold, { color: typography.TextColor.GREY_XX_DARK, size: typography.TextSize.LEVEL_200, weight: typography.TextWeight.LEVEL_600 }, "This export may contain sensitive financial information")),
    React.createElement("p", null, "Due to the sensitive nature of this export, we recommend taking precautions when storing, sending, and uploading this file.")));

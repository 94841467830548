import { combineReducers } from 'redux';

import allIdsReducer from './allIdsReducer';
import byIdReducer from './byIdReducer';
import errorReducer from './errorReducer';
import fetchReducer from './fetchReducer';
import lastFetchedReducer from './lastFetchedReducer';
import paginationReducer from './paginationReducer';

const partnershipMemberReducer = combineReducers({
  allIds: allIdsReducer,
  byId: byIdReducer,
  errors: errorReducer,
  isFetching: fetchReducer,
  lastFetched: lastFetchedReducer,
  pagination: paginationReducer,
});

export default partnershipMemberReducer;

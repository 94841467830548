import PropTypes from 'prop-types';
import React from 'react';

import { ExternalLink } from 'components';

/**
 * InvoiceListText component that can render a link instead of plain text.
 * @param {ComponentProps} props
 * @param {string} [props.fallbackText]
 * @param {boolean} [props.hasURL]
 * @param {string} [props.itemURL]
 * @param {string} [props.title]
 * @returns {StatelessComponent}
 */
const InvoiceLink = ({ fallbackText, hasURL, itemURL, title }) => {
  if (!hasURL) {
    return null;
  }

  return (
    <ExternalLink className="!text-xs enable-pointer-events truncate" href={itemURL}>
      {title || fallbackText}
    </ExternalLink>
  );
};

InvoiceLink.propTypes = {
  fallbackText: PropTypes.string,
  hasURL: PropTypes.bool,
  itemURL: PropTypes.string,
  title: PropTypes.string,
};

InvoiceLink.defaultProps = {
  fallbackText: undefined,
  hasURL: undefined,
  itemURL: undefined,
  title: undefined,
};

export default InvoiceLink;

import { Tooltip } from '@routable/components';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';
import { IconNames } from 'components/icon';

import { colors, sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

import { getAddPartnershipButtonTooltipText } from '../helpers/text';

/**
 * Ghost button, for easy icon styling, with primary link hover state. Used to open the create partnership modal to add
 * a partnership for LedgerContactTypes.BOTH. The btn class automatically adds a bottom margin, but this ruins our
 * vertical alignment in the table cell in InvoiceTable, so we explicitly remove it with .remove-margin-bottom.
 *
 * TooltipPane's position is to the right, because if it appears above or below, it blocks other table information or
 * add buttons.
 * @param {boolean} [isLoading]
 * @param {Item} item
 * @param {function} onClick
 * @returns {StatelessComponent}
 */
const AddPartnershipButton = ({ isLoading, item, onClick }) => (
  <Tooltip position="right" tooltip={getAddPartnershipButtonTooltipText(item)}>
    <ButtonV2
      className="enable-pointer-events"
      intent={Intent.NEUTRAL}
      isLoading={isLoading}
      leftIconClassName="margin-right--xm"
      leftIconColor={colors.colorMainJordanHex}
      leftIconName={IconNames.ADD}
      leftIconSize={sizes.iconSizes.MEDIUM}
      onClick={onClick}
    >
      Add
    </ButtonV2>
  </Tooltip>
);

AddPartnershipButton.propTypes = {
  isLoading: PropTypes.bool,
  item: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
};

AddPartnershipButton.defaultProps = {
  isLoading: undefined,
};

export default AddPartnershipButton;

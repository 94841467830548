import { combineReducers } from 'redux';

import allInvoicesReducer from './allInvoicesReducer';
import errorReducer from './errorReducer';
import fetchReducer from './fetchReducer';
import invoicesByIdReducer from './invoicesByIdReducer';
import lastSubmittedReducer from './lastSubmittedReducer';
import lastUpdatedReducer from './lastUpdatedReducer';
import linksReducer from './linksReducer';
import metaReducer from './metaReducer';
import submitReducer from './submitReducer';

const invoicesReducer = combineReducers({
  allIds: allInvoicesReducer,
  byId: invoicesByIdReducer,
  errors: errorReducer,
  isFetching: fetchReducer,
  isSubmitting: submitReducer,
  lastSubmitted: lastSubmittedReducer,
  lastUpdated: lastUpdatedReducer,
  links: linksReducer,
  meta: metaReducer,
});

export default invoicesReducer;

import PropTypes from 'prop-types';
import React from 'react';

import { EntityWidgetHintSection } from 'components/entityWidget/components';

import { isFundingAccountExternal } from 'helpers/funding';

import { text } from 'modules/fundingAccount/bankAccount/constants';

import AccountHintSectionPreviouslyAddedAddress from '../AccountHintSectionPreviouslyAddedAddress';

const AddressAccountHintSections = (props) => {
  const {
    bankAccountContext,
    isSubmittingConvertFundingAccountFromExternal,
    onSubmitConvertFundingAccountFromExternal,
    source,
  } = props;

  const { fundingAccount } = bankAccountContext;

  const { id: sourceId } = source;

  const isExternal = isFundingAccountExternal(fundingAccount);

  let sectionActions;

  if (isExternal) {
    sectionActions = (
      <AccountHintSectionPreviouslyAddedAddress
        isSubmitting={isSubmittingConvertFundingAccountFromExternal}
        onButtonClick={() => onSubmitConvertFundingAccountFromExternal(fundingAccount.id)}
      />
    );
  }

  if (!sectionActions) {
    return null;
  }

  return (
    <EntityWidgetHintSection
      actions={sectionActions}
      key={`${fundingAccount.id}-${sourceId}`}
      text={text.previouslyAddedAddressHintText}
      title={text.previouslyAddedAddressHintTitle}
    />
  );
};

AddressAccountHintSections.propTypes = {
  bankAccountContext: PropTypes.shape().isRequired,
  isSubmittingConvertFundingAccountFromExternal: PropTypes.bool,
  onSubmitConvertFundingAccountFromExternal: PropTypes.func.isRequired,
  source: PropTypes.shape().isRequired,
};

AddressAccountHintSections.defaultProps = {
  isSubmittingConvertFundingAccountFromExternal: undefined,
};

export default AddressAccountHintSections;

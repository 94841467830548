import { ADMISSION, AdmissionRoutes } from 'constants/routes';
import { routableApi, RoutableTags } from '../routableApi';
import {} from './customRoles.types';
export const rolesEndpoint = `/${ADMISSION}/${AdmissionRoutes.CUSTOM_ROLES}/`;
export const extendedCustomRolesApi = routableApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getCustomRoles: builder.query({
            query: () => ({
                url: rolesEndpoint,
            }),
            providesTags: [RoutableTags.CustomRoles],
        }),
        upsertCustomRole: builder.mutation({
            query: ({ baseRole, permissions, id, ...payload }) => ({
                url: `${rolesEndpoint}${id ? `${id}/` : ''}`,
                method: id ? 'PUT' : 'POST',
                payload: {
                    data: {
                        id,
                        attributes: {
                            ...payload,
                            permissions,
                            ...(!id && { baseRole }),
                        },
                        type: 'Role',
                    },
                },
            }),
            invalidatesTags: [RoutableTags.CustomRoles],
        }),
        deleteCustomRole: builder.mutation({
            query: ({ id }) => ({
                url: `${rolesEndpoint}${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [RoutableTags.CustomRoles],
        }),
    }),
});
export const { useGetCustomRolesQuery, useUpsertCustomRoleMutation, useDeleteCustomRoleMutation, endpoints: { getCustomRoles }, } = extendedCustomRolesApi;

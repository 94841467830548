import React from 'react';

import MicroDepositsNeedVerificationHint from '../MicroDepositsNeedVerificationHint';

/**
 * Renders micro deposits need verification hint without additional callout
 * to connect bank account.
 * @return {StatelessComponent}
 */
const MicroDepositsNeedVerificationWithoutConnectHint = () => (
  <MicroDepositsNeedVerificationHint>
    Please wait until your micro-deposits have been verified or use another payment method to continue.
  </MicroDepositsNeedVerificationHint>
);

export default MicroDepositsNeedVerificationWithoutConnectHint;

import { bindRoutineToReduxForm, createRoutine, promisifyRoutine } from 'redux-saga-routines';

import { withNotification } from 'actions/modifiers';

import { AuthActionType } from 'enums/auth';

import { sharedFormSubmitRoutinePayloadCreators } from 'helpers/routine';

import { createPasswordEndpoint } from 'services/api/authEndpoints';

export const loginRoutine = createRoutine(AuthActionType.SUBMIT_LOGIN, sharedFormSubmitRoutinePayloadCreators);

export const loginHandler = promisifyRoutine(loginRoutine);

export const ssoLoginRoutine = createRoutine(AuthActionType.SUBMIT_SSO_LOGIN, sharedFormSubmitRoutinePayloadCreators);

export const ssoLoginHandler = promisifyRoutine(ssoLoginRoutine);

/**
 * Defines types and creates actions for handling sso redirect related side effects.
 */
export const ssoHandleRedirectRoutine = createRoutine(AuthActionType.SUBMIT_SSO_HANDLE_REDIRECT);

export const resetPasswordRoutine = createRoutine(
  AuthActionType.SUBMIT_RESET_PASSWORD,
  sharedFormSubmitRoutinePayloadCreators,
);

export const resetPasswordHandler = promisifyRoutine(resetPasswordRoutine);

export const changePasswordRoutine = createRoutine(
  AuthActionType.SUBMIT_CHANGE_PASSWORD,
  sharedFormSubmitRoutinePayloadCreators,
);

export const changePasswordHandler = promisifyRoutine(changePasswordRoutine);

export const convertExternalRoutine = createRoutine(
  AuthActionType.SUBMIT_CONVERT_EXTERNAL,
  sharedFormSubmitRoutinePayloadCreators,
);

export const convertExternalHandler = bindRoutineToReduxForm(convertExternalRoutine);

/**
 * userSettingsCreatePasswordRoutine
 */
const TRIGGER = `${AuthActionType.SUBMIT_CREATE_PASSWORD}/TRIGGER`;
const SUCCESS = `${AuthActionType.SUBMIT_CREATE_PASSWORD}/SUCCESS`;
const FAILURE = `${AuthActionType.SUBMIT_CREATE_PASSWORD}/FAILURE`;

const withSuccessNotification = (email) =>
  withNotification({
    type: 'success',
    message: `Please check your ${email || ''} email for a link to create your new password.`,
    options: {},
  });

const withFailureNotification = () =>
  withNotification({
    type: 'danger',
    message: 'Failed to send email. Please try again later.',
    options: {},
  });

const success = (email) => (data) => withSuccessNotification(email)({ type: SUCCESS, payload: data });

const failure = (data) => withFailureNotification()({ type: FAILURE, error: data });

const trigger = ({ email }) => ({
  type: TRIGGER,
  payload: {
    endpoint: createPasswordEndpoint,
    method: 'POST',
    requireAuth: true,
    onSuccess: success(email),
    onFailure: failure,
  },
});

export const userSettingsCreatePasswordRoutine = {
  TRIGGER,
  trigger,
  SUCCESS,
  success,
  FAILURE,
  failure,
};

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ButtonV2, IconNames } from 'components';

import { ButtonSize } from 'constants/button';
import { sizes } from 'constants/styles';

import { isCurrentCompanyInInviteStage } from 'helpers/currentCompany';
import { parseCurrency } from 'helpers/numbers';

import PartnerCompanyPaymentMethodContainer from 'modules/createPartnerCompany/containers/PartnerCompanyPaymentMethodContainer';
import ExternalPromptHeader from 'modules/external/externalPrompt/global/presenters/ExternalPromptHeader';
import ExternalPromptInstructions from 'modules/external/externalPrompt/global/presenters/ExternalPromptInstructions';

import { itemFromQuerySelector } from 'queries/itemRouterSelectors';
import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

import { currentCompanySelector } from 'selectors/currentCompanySelectors';

class ExternalPromptVendorSingleItemPending extends React.Component {
  renderBodyItemExisting = () => {
    const { currentCompany, onStart } = this.props;

    if (isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    return (
      <div className="margin-top--large">
        <PartnerCompanyPaymentMethodContainer onCloseModal={onStart} />
      </div>
    );
  };

  renderBodyItemPending = () => {
    const { currentCompany, partnership } = this.props;

    if (!isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    const instructions = [
      {
        id: 1,
        key: '1',
        text: `Register as a ${partnership.name} vendor by sharing your business info.`,
      },
      {
        id: 2,
        key: '2',
        text: 'Select a preferred payment method to receive payments.',
      },
      {
        id: 3,
        key: '3',
        text: 'Receive a notification when funds have been sent your way.',
      },
    ];

    return <ExternalPromptInstructions instructions={instructions} isAboveButton />;
  };

  renderButtonItemPending = () => {
    const { currentCompany, item, onStart } = this.props;

    if (!isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    return (
      <ButtonV2
        leftIconClassName="margin-right--m"
        leftIconName={IconNames.SHIELD}
        leftIconSize={sizes.iconSizes.LARGE}
        onClick={onStart}
        rightIconName={IconNames.ARROW_RIGHT}
        rightIconProps={{ style: { marginLeft: 'auto' } }}
        rightIconSize={sizes.iconSizes.LARGE}
        size={ButtonSize.LARGE}
      >
        {`Accept payment of ${parseCurrency(item.amount)}`}
      </ButtonV2>
    );
  };

  renderHeaderItemExisting = () => {
    const { currentCompany, partnership } = this.props;

    if (isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    return (
      <ExternalPromptHeader
        showRequester
        text={`Select a payment method to accept your payment from ${partnership.name}`}
      />
    );
  };

  renderHeaderItemPending = () => {
    const { currentCompany, partnership } = this.props;

    if (!isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    return <ExternalPromptHeader showRequester text={`You received a secure payment from ${partnership.name}`} />;
  };

  render() {
    return (
      <React.Fragment>
        {/* Prompt header */}
        {this.renderHeaderItemExisting()}
        {this.renderHeaderItemPending()}

        {/* Prompt body */}
        {this.renderBodyItemExisting()}
        {this.renderBodyItemPending()}

        {/* Prompt button */}
        {this.renderButtonItemPending()}
      </React.Fragment>
    );
  }
}

ExternalPromptVendorSingleItemPending.propTypes = {
  currentCompany: PropTypes.shape().isRequired,
  item: PropTypes.shape().isRequired,
  onStart: PropTypes.func.isRequired,
  partnership: PropTypes.shape().isRequired,
};

const mapStateToProps = createStructuredSelector({
  currentCompany: currentCompanySelector,
  item: itemFromQuerySelector,
  partnership: partnershipFromQuerySelector,
});

export { ExternalPromptVendorSingleItemPending };
export default connect(mapStateToProps)(ExternalPromptVendorSingleItemPending);

import { queryClient } from '@routable/shared';
import { connect } from 'react-redux';

import { generateKeys } from 'hooks/useOutstandingItems/queryKeys.service';

import { formActionMatchPartnership } from 'actions/forms';
import { openForceManualLedgerSyncModal } from 'actions/ledger';
import { openCreatePartnershipModal, openMergeLedgerPartnershipModal } from 'actions/partnership';

import { isItemKindReceivable, isPartnershipMatched } from 'helpers/items';

import {
  isFetchingLedgerOrPlatformPartnershipsSelector,
  ledgerSettingsSelector,
  ledgerUnmatchedPartnershipByCustomerRefSelector,
  ledgerUnmatchedPartnershipByVendorRefSelector,
  matchingPartnershipContactsSelector,
} from 'selectors/ledgerInfoSelectors';

import PartnershipMatchingButtons from './PartnershipMatchingButtons';

/**
 * Fetches data from state for PartnershipMatchingButtons. Composes a partnershipWithContacts from state if it the
 * root partnership can be found in the unmatched ledger partnerships.
 * @param {ReduxState} state
 * @param {ComponentProps} ownProps
 * @returns {object}
 */
export const mapStateToProps = (state, ownProps) => {
  const { item } = ownProps;
  const { companyRef } = item;

  // No need to look through all the partnerships for matching if the item comes from a matched partnership
  if (isPartnershipMatched(item)) {
    return {};
  }

  const isCustomer = isItemKindReceivable(item);
  const partnership = isCustomer
    ? ledgerUnmatchedPartnershipByCustomerRefSelector(state, companyRef)
    : ledgerUnmatchedPartnershipByVendorRefSelector(state, companyRef);

  return {
    isFetchingLedgerOrPlatformPartnerships: isFetchingLedgerOrPlatformPartnershipsSelector(state),
    ledgerSettings: ledgerSettingsSelector(state),
    partnershipWithContacts: {
      ...partnership,
      contacts: partnership ? matchingPartnershipContactsSelector(state, partnership.id) : undefined,
      isCustomer,
      isVendor: !isCustomer,
    },
  };
};

/**
 * Object, which, when passed to Redux connect(), binds the Redux action creators with dispatch for
 * PartnershipMatchingButtons.
 * @type {Object}
 */
export const mapDispatchToProps = {
  onOpenCreatePartnershipModal: openCreatePartnershipModal,
  onOpenForceManualLedgerSyncModal: openForceManualLedgerSyncModal,
  onOpenMergeLedgerPartnershipModal: openMergeLedgerPartnershipModal,
  resetForm: formActionMatchPartnership.reset,
};

/**
 * After we get mapStateToProps and mapDispatchToProps, there are two more functions we need to compose:
 *
 * onOpenMergeModal is the combination of two dispatched actions, resetForm and onOpenMergeLedgerPartnershipModal.
 *
 * @param {Object} stateProps
 * @param {Object} dispatchProps
 * @param {ComponentProps} ownProps
 * @returns {Object}
 */
export const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { onOpenMergeLedgerPartnershipModal, resetForm } = dispatchProps;

  // When a partnership is matched (merge/add) refresh the invoices
  const successCallback = (parsedResponse) => {
    // Invalidate data to trigger re-fetch of outstanding items (updates merge status of partnership)
    queryClient.invalidateQueries(generateKeys.list());

    return parsedResponse;
  };

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onOpenMergeModal: (payload) => {
      resetForm();
      onOpenMergeLedgerPartnershipModal(payload);
    },
    successCallback,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PartnershipMatchingButtons);

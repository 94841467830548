import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './HeadingTitle.scss';

const HeadingTitle = ({ children, className, style }) => (
  <p
    className={classNames({
      'heading-title': true,
      [className]: className,
    })}
    style={style}
  >
    {children}
  </p>
);

HeadingTitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

HeadingTitle.defaultProps = {
  children: undefined,
  className: undefined,
  style: {},
};

export default HeadingTitle;

import { combineReducers } from 'redux';

import dashboardLoadedBillingReducer from './dashboardLoadedBillingReducer';
import dashboardLoadedCountriesReducer from './dashboardLoadedCountriesReducer';
import dashboardLoadedCurrenciesReducer from './dashboardLoadedCurrenciesReducer';
import dashboardLoadedCurrentCompanyReducer from './dashboardLoadedCurrentCompanyReducer';
import dashboardLoadedFeatureFlagsReducer from './dashboardLoadedFeatureFlagsReducer';
import dashboardLoadedFeatureSettingsReducer from './dashboardLoadedFeatureSettingsReducer';
import dashboardLoadedFundingAccountsReducer from './dashboardLoadedFundingAccountsReducer';
import dashboardLoadedIntegrationConfigsReducer from './dashboardLoadedIntegrationConfigsReducer';
import dashboardLoadedIntegrationsReducer from './dashboardLoadedIntegrationsReducer';
import dashboardLoadedMembershipsReducer from './dashboardLoadedMembershipsReducer';
import dashboardLoadedPermissionGroupsReducer from './dashboardLoadedPermissionGroupsReducer';
import dashboardLoadedPermissionsReducer from './dashboardLoadedPermissionsReducer';
import dashboardLoadedRolesReducer from './dashboardLoadedRolesReducer';
import dashboardLoadedSupportedCountriesReducer from './dashboardLoadedSupportedCountriesReducer';

const dashboardLoadedReducer = combineReducers({
  billing: dashboardLoadedBillingReducer,
  countries: dashboardLoadedCountriesReducer,
  currencies: dashboardLoadedCurrenciesReducer,
  currentCompany: dashboardLoadedCurrentCompanyReducer,
  featureFlags: dashboardLoadedFeatureFlagsReducer,
  featureSettings: dashboardLoadedFeatureSettingsReducer,
  fundingAccounts: dashboardLoadedFundingAccountsReducer,
  integrationConfigs: dashboardLoadedIntegrationConfigsReducer,
  integrations: dashboardLoadedIntegrationsReducer,
  memberships: dashboardLoadedMembershipsReducer,
  permissionGroups: dashboardLoadedPermissionGroupsReducer,
  permissions: dashboardLoadedPermissionsReducer,
  roles: dashboardLoadedRolesReducer,
  supportedCountries: dashboardLoadedSupportedCountriesReducer,
});

export default dashboardLoadedReducer;

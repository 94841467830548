import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { AutosizeTextarea } from 'components';

import * as propTypes from 'constants/propTypes';

import { useGetTableFieldProps } from 'modules/table/hooks';

/**
 * Renders a textarea field for long string values to be rendered in a table cell.
 * @param {ComponentProps} props
 * @param {Object} props.getIsDisabled
 * @param {Object} props.getIsRequired
 * @param {number} props.maxLength
 * @param {string} props.name
 * @param {RestOfProps} props.rest
 * @param {FunctionMaybe} props.transformValue
 * @param {Function|Function[]} props.validate
 * @returns {StatelessComponent}
 */
const TextareaTableField = ({
  formValues,
  getIsDisabled,
  getIsRequired,
  maxLength,
  name,
  rowIdx,
  sectionPath,
  transformValue,
  validate,
}) => {
  const fieldPath = `${sectionPath}.${rowIdx}`;
  const { isDisabled, isRequired, validators } = useGetTableFieldProps({
    formValues,
    getIsDisabled,
    getIsRequired,
    fieldPath,
    rowIdx,
    validate,
  });

  return (
    <Field
      className="w-full ledger-form-field"
      component={AutosizeTextarea}
      isDebounced
      isDisabled={isDisabled}
      isRequired={isRequired}
      maxLength={maxLength}
      name={name}
      normalize={transformValue}
      validate={validators}
    />
  );
};

TextareaTableField.propTypes = {
  ...propTypes.tableTextFieldPropTypes,
  maxLength: PropTypes.number,
  transformValue: PropTypes.func,
};

TextareaTableField.defaultProps = {
  ...propTypes.tableTextFieldDefaultProps,
  maxLength: undefined,
  transformValue: undefined,
};

export default React.memo(TextareaTableField);

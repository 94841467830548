function createIntervalEvents(baseEventName) {
    return new Proxy({}, {
        get: (target, prop) => {
            if (prop in target) {
                return target[prop];
            }
            return `${baseEventName}:${prop}`;
        },
    });
}
export const LOGOUT_EVENT_NAMES = createIntervalEvents('auto-logout-inactivity');
export const FORCE_LOGOUT_EVENT_NAMES = createIntervalEvents('force-logout-inactivity');

import React from 'react';

import { PartnershipContext } from 'context';

/**
 * When you want to get the nearest partnership context, use this hook.
 * @function
 * @returns {function}
 */
const usePartnershipContext = () => React.useContext(PartnershipContext);

export default usePartnershipContext;

import { createIsFetchingReducer } from 'store/redux';

import {
  FETCH_MEMBERSHIP_INVITE_REQUEST,
  FETCH_MEMBERSHIP_INVITES_REQUEST,
  CLEAR_MEMBERSHIP_INVITE,
  FETCH_MEMBERSHIP_INVITE_FAILURE,
  FETCH_MEMBERSHIP_INVITE_SUCCESS,
  FETCH_MEMBERSHIP_INVITES_FAILURE,
  FETCH_MEMBERSHIP_INVITES_SUCCESS,
} from 'types/inviteTeamMember';

export const finishCases = [
  CLEAR_MEMBERSHIP_INVITE,
  FETCH_MEMBERSHIP_INVITE_FAILURE,
  FETCH_MEMBERSHIP_INVITE_SUCCESS,
  FETCH_MEMBERSHIP_INVITES_FAILURE,
  FETCH_MEMBERSHIP_INVITES_SUCCESS,
];

export const requestCases = [FETCH_MEMBERSHIP_INVITE_REQUEST, FETCH_MEMBERSHIP_INVITES_REQUEST];

const fetchReducer = createIsFetchingReducer(finishCases, requestCases);

export default fetchReducer;

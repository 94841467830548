import { Tooltip } from '@routable/components';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';
import { IconNames } from 'components/icon';

import { ButtonAppearance } from 'constants/button';
import { colors } from 'constants/styles';

class ToolbarButton extends React.PureComponent {
  state = {
    // necessary to keep this in state for showing tooltip programmatically
    isHovered: false,
  };

  hoveredOn = () => {
    this.setState(() => ({ isHovered: true }));
  };

  hoveredOff = () => {
    this.setState(() => ({ isHovered: false }));
  };

  render() {
    const {
      children,
      className,
      hoveredClassName,
      iconName,
      iconSize,
      onClick,
      showTooltip,
      tooltipContent,
      tooltipPosition,
      wrapperClassName,
    } = this.props;

    const { isHovered } = this.state;

    return (
      <div
        className={clsx('toolbar--button-wrapper', { [wrapperClassName]: !!wrapperClassName })}
        onMouseEnter={this.hoveredOn}
        onMouseLeave={this.hoveredOff}
      >
        <Tooltip
          // eslint-disable-next-line react/destructuring-assignment
          data-testId={`${this.props['data-testid']}:tooltip`}
          position={tooltipPosition}
          tooltip={showTooltip || isHovered ? tooltipContent : null}
        >
          <ButtonV2
            appearance={ButtonAppearance.BORDERLESS}
            className={clsx({
              'toolbar--button': true,
              'toolbar--control': true,
              hovered: isHovered,
              [className]: !!className,
              [hoveredClassName]: isHovered && !!hoveredClassName,
            })}
            leftIconClassName="toolbar--button-icon"
            leftIconColor={colors.colorGreyXXDarkHex}
            leftIconName={iconName}
            leftIconSize={iconSize}
            onClick={onClick}
            type="button"
          >
            {children}
          </ButtonV2>
        </Tooltip>
      </div>
    );
  }
}

ToolbarButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  hoveredClassName: PropTypes.string,
  iconName: PropTypes.string,
  iconSize: PropTypes.number,
  onClick: PropTypes.func,
  showTooltip: PropTypes.bool,
  tooltipContent: PropTypes.string,
  wrapperClassName: PropTypes.string,
  tooltipPosition: PropTypes.string,
  'data-testid': PropTypes.string,
};

ToolbarButton.defaultProps = {
  children: undefined,
  className: undefined,
  hoveredClassName: 'primary',
  iconName: IconNames.DOCUMENT,
  iconSize: 14,
  onClick: () => {},
  // show the tooltip externally (via override)
  showTooltip: undefined,
  tooltipContent: undefined,
  wrapperClassName: undefined,
  tooltipPosition: undefined,
  'data-testid': undefined,
};

export default ToolbarButton;

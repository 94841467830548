import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Renders subtext for a table cell.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 * @constructor
 */
const TableCellSubtext = ({ children, className, style }) => (
  <div
    className={classNames({
      'font-xs': true,
      'font-color--dark-jordan': true,
      truncate: true,
      [className]: !!className,
    })}
    style={style}
  >
    {children}
  </div>
);

TableCellSubtext.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

TableCellSubtext.defaultProps = {
  children: undefined,
  className: undefined,
  style: {},
};

export default TableCellSubtext;

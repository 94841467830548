import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './FillBar.scss';

const FillBar = ({ isLoading }) => (
  <div
    className={classNames({
      'fill-bar': true,
      'finish-loading': !isLoading,
    })}
  />
);

FillBar.propTypes = {
  isLoading: PropTypes.bool,
};
FillBar.defaultProps = {
  isLoading: undefined,
};

export default FillBar;

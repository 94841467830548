export var AuthActionType;
(function (AuthActionType) {
    AuthActionType["CLEAR_LOGIN_ERROR_STATE"] = "RESET_LOGIN_ERROR";
    AuthActionType["CLEAR_RESET_PASSWORD_STATE"] = "CLEAR_RESET_PASSWORD_STATE";
    AuthActionType["SUBMIT_CHANGE_PASSWORD"] = "SUBMIT_CHANGE_PASSWORD";
    AuthActionType["SUBMIT_CHANGE_PASSWORD_FAILURE"] = "SUBMIT_CHANGE_PASSWORD_FAILURE";
    AuthActionType["SUBMIT_CHANGE_PASSWORD_REQUEST"] = "SUBMIT_CHANGE_PASSWORD_REQUEST";
    AuthActionType["SUBMIT_CHANGE_PASSWORD_SUCCESS"] = "SUBMIT_CHANGE_PASSWORD_SUCCESS";
    AuthActionType["SUBMIT_CONVERT_EXTERNAL"] = "SUBMIT_CONVERT_EXTERNAL";
    AuthActionType["SUBMIT_CREATE_PASSWORD"] = "SUBMIT_CREATE_PASSWORD";
    AuthActionType["SUBMIT_LOGIN"] = "SUBMIT_LOGIN";
    AuthActionType["SUBMIT_LOGOUT_REQUEST"] = "SUBMIT_LOGOUT_REQUEST";
    AuthActionType["SUBMIT_LOGOUT_FAILURE"] = "SUBMIT_LOGOUT_FAILURE";
    AuthActionType["SUBMIT_LOGOUT_COMPLETE"] = "SUBMIT_LOGOUT_COMPLETE";
    AuthActionType["SUBMIT_LOGOUT_CLEANUP"] = "SUBMIT_LOGOUT_CLEANUP";
    AuthActionType["SUBMIT_RESET_PASSWORD"] = "SUBMIT_RESET_PASSWORD";
    AuthActionType["SUBMIT_SSO_LOGIN"] = "SUBMIT_SSO_LOGIN";
    AuthActionType["SUBMIT_SSO_HANDLE_REDIRECT"] = "SUBMIT_SSO_HANDLE_REDIRECT";
})(AuthActionType || (AuthActionType = {}));

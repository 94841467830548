import PropTypes from 'prop-types';
import React from 'react';

import { DocumentViewer } from 'components/document';

import { IsLoadingHasWrapper } from 'modules/isLoading';

/**
 * Deep link route component that fetches a file and displays it via objectUrl in
 * an iframe file viewer.
 * @param {ComponentProps} props
 * @param {Function} props.onFetchFile
 * @param {string} props.name
 * @param {string} props.objectUrl
 * @param {string} props.title
 * @param {string} props.url
 * @returns {StatelessComponent}
 * @constructor
 */
const ViewDocument = (props) => {
  const { isFetching, name, objectUrl, onFetchFile, title, url } = props;

  React.useEffect(() => {
    if (url) {
      onFetchFile(url);
    }
  }, [onFetchFile, url]);

  if (isFetching || !objectUrl) {
    return <IsLoadingHasWrapper />;
  }

  return <DocumentViewer name={name} objectUrl={objectUrl} title={title} />;
};

ViewDocument.propTypes = {
  isFetching: PropTypes.bool,
  name: PropTypes.string,
  objectUrl: PropTypes.string,
  onFetchFile: PropTypes.func.isRequired,
  title: PropTypes.string,
  url: PropTypes.string,
};

ViewDocument.defaultProps = {
  isFetching: false,
  name: undefined,
  objectUrl: undefined,
  title: undefined,
  url: undefined,
};

export default ViewDocument;

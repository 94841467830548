import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { InvoiceGeneratorTotalsTotal } from '../invoiceGenerator';

/**
 * Renders the invoice generator totals panel field for the total amount.
 * @param {ComponentProps} props
 * @param {string} props.name
 * @returns {StatelessComponent}
 */
const TotalsPanelTotal = ({ name, ...rest }) => <Field {...rest} component={InvoiceGeneratorTotalsTotal} name={name} />;

TotalsPanelTotal.propTypes = {
  name: PropTypes.string.isRequired,
};

export default TotalsPanelTotal;

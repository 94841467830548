import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTaxFormOptions } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.context';
import { taxCertificationType, taxFormType, } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.models';
import { UserType } from 'enums/user';
import { isCompanyTypeBusiness } from 'helpers/currentCompany';
import RenderRadioGroup from '../RenderRadioGroup';
import RenderSelect from '../RenderSelect';
import { Group, GroupSlot } from '../styles';
const FieldGroupBase = () => {
    const { watch, setValue, resetField } = useFormContext();
    const { countries, showCompanyTypeField, formType } = useTaxFormOptions();
    const currentCompanyType = watch('companyType');
    const isBusiness = isCompanyTypeBusiness(currentCompanyType);
    const userTypeOptions = [
        {
            optionText: 'I represent a business',
            subText: 'e.g. Sole proprietorship, Corporation',
            value: UserType.BUSINESS,
        },
        {
            optionText: 'I am an individual',
            subText: 'e.g. Contractor or freelancer',
            value: UserType.PERSONAL,
        },
    ];
    const countryFieldName = isBusiness ? 'countryOfIncorporationOrOrganization' : 'countryOfCitizenship';
    const countryPlaceholder = isBusiness
        ? 'Select country of incorporation or organization'
        : 'Select country of citizenship';
    useEffect(() => {
        const subscription = watch(({ companyType }, { name, type }) => {
            if (name === 'companyType' && type === 'change' && formType === taxFormType.Enum.W8) {
                if (isCompanyTypeBusiness(companyType)) {
                    setValue('certificationType', taxCertificationType.Enum.uploadSignedPDF);
                }
                else {
                    resetField('certificationType');
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, setValue, resetField, formType]);
    return (React.createElement(Group, null,
        showCompanyTypeField && (React.createElement(GroupSlot, { size: 6 },
            React.createElement(RenderRadioGroup, { name: "companyType", options: userTypeOptions }))),
        formType === taxFormType.Enum.W8 && (React.createElement(GroupSlot, { size: 6 },
            React.createElement(RenderSelect, { key: countryFieldName, label: "Country", name: countryFieldName, options: countries, placeholder: countryPlaceholder })))));
};
export default FieldGroupBase;

import styled from 'styled-components';
import { TextBody14 } from '../../components/Text';
export const StyledBadgeFilled = styled(TextBody14) `
  display: inline-block;
  margin-top: ${(p) => p.theme.sizing.spacing[4]};
  margin-bottom: ${(p) => p.theme.sizing.spacing[4]};
  padding: ${(p) => p.theme.sizing.spacing[2]} ${(p) => p.theme.sizing.spacing[12]};
  color: ${(p) => p.theme.color.black};
  background: ${(p) => p.theme.color.tokens.GREY[10]};
  border-radius: 100px;
`;
export const StyledBadgeOutlined = styled(TextBody14) `
  display: inline-block;
  padding: ${(p) => p.theme.sizing.spacing[0]} ${(p) => p.theme.sizing.spacing[8]};
  color: ${(p) => p.theme.color.tokens.GREY[60]};
  border: ${(p) => `1px solid ${p.theme.color.tokens.GREY[60]}`};
  background: transparent;
  border-radius: 100px;
  font-size: ${(p) => p.theme.typography.fontSize[1]};
  font-weight: ${(p) => p.theme.typography.weight.regular};
  line-height: ${(p) => p.theme.typography.lineHeight[1]};
`;
export const StyledBadgeDefault = styled(TextBody14) `
  display: inline-block;
  padding: ${(p) => p.theme.sizing.spacing[0]} ${(p) => p.theme.sizing.spacing[8]};
  color: ${(p) => p.theme.color.tokens.GREY[60]};
  background: transparent;
  border-radius: 100px;
  font-size: ${(p) => p.theme.typography.fontSize[1]};
  font-weight: ${(p) => p.theme.typography.weight.regular};
  line-height: ${(p) => p.theme.typography.lineHeight[1]};
`;

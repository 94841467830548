/**
 * @module actions/ui
 */
import * as types from 'types/ui';

import { withThrottle } from './modifiers';

/**
 * Dispatch success or error UI to show toast with message.
 * @type {Function}
 * @param {string} type
 * @param {string} message
 * @param {ObjectMaybe} [options]
 * @returns {Object}
 */
export const showUiToast = (type, message, options) =>
  withThrottle({ id: 'toast', timeout: 100 })({
    payload: { message, options },
    type,
  });

/**
 Creates an action which triggers display of UI after some failed task completion.
 * @type {Function}
 * @param {string} message
 * @param {ObjectMaybe} [options]
 * @returns {Object}
 */
export const showErrorUi = (message, options) => showUiToast(types.SHOW_ERROR_UI, message, options);

/**
 * Creates an action which triggers display of UI after some failed form submission.
 * @type {Function}
 * @param {string} form - Form name
 * @param {Object} options
 * @param {StringMaybe} options.message - Alert message
 * @param {StringMaybe} options.title - Alert title
 * @returns {ReduxAction}
 */
export const showFormErrorUi = (form, options = {}) => ({
  payload: { form, options },
  type: types.SHOW_FORM_ERROR_UI,
});

/**
 * Creates an action which triggers display of info UI after some task completion.
 * @type {Function}
 * @param {string} message
 * @param {ObjectMaybe} [options]
 * @returns {ReduxAction}
 */
export const showInfoUi = (message, options) => showUiToast(types.SHOW_INFO_UI, message, options);

/**
 * Creates an action which triggers display of UI after some successful task completion.
 * @type {Function}
 * @param {string} message
 * @param {ObjectMaybe} [options]
 * @returns {ReduxAction}
 */
export const showSuccessUi = (message, options) => showUiToast(types.SHOW_SUCCESS_UI, message, options);

/**
 * Creates an action which triggers display of warning UI after some task completion.
 * @type {Function}
 * @param {string} message
 * @param {ObjectMaybe} [options]
 * @returns {ReduxAction}
 */
export const showWarningUi = (message, options) => showUiToast(types.SHOW_WARNING_UI, message, options);

/**
 * Creates an action which closes the advanced settings section in AddPartnersFundingAccountOptions
 * @type {Function}
 * @returns {ReduxAction}
 */
export const closeAddPartnerFundingAccountOptions = () => ({
  type: types.PARTNERSHIP_FUNDING_ACCOUNT_OPTIONS_CLOSE,
});

/**
 * Creates an action which opens the notification bar
 * @param {string} message
 * @param {ObjectMaybe} [options]
 * @type {Function}
 * @returns {ReduxAction}
 */
export const openNotificationBar = (message, options) => ({
  payload: { message, ...options },
  type: types.NOTIFICATION_BAR_OPEN,
});

/**
 * Creates an action which closes the notification bar
 * @type {Function}
 * @returns {ReduxAction}
 */
export const closeNotificationBar = () => ({
  type: types.NOTIFICATION_BAR_CLOSE,
});

/**
 * Creates an action which opens the advanced settings section in AddPartnersFundingAccountOptions
 * @type {Function}
 * @returns {ReduxAction}
 */
export const openAddPartnerFundingAccountOptions = () => ({
  type: types.PARTNERSHIP_FUNDING_ACCOUNT_OPTIONS_OPEN,
});

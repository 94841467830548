import { useCallback, useEffect, useRef } from 'react';
import { hashObject } from '../../types/network.types';
export const useQueryPagination = ({ page, pageSize, totalRecords = 0, setPagination }, watch = []) => {
    const pages = Math.ceil(totalRecords / pageSize);
    const hashedWatch = watch.map(hashObject).join('|||');
    const previousHashedWatch = useRef();
    useEffect(() => {
        if (!previousHashedWatch?.current || previousHashedWatch.current === hashedWatch) {
            previousHashedWatch.current = hashedWatch;
            return;
        }
        setPagination({ page: 1, pageSize });
    }, [setPagination, hashedWatch, pageSize]);
    const setPageSize = useCallback((size, destinationPageIndex = 1) => {
        setPagination({ page: destinationPageIndex, pageSize: size });
    }, [setPagination]);
    const gotoPage = useCallback((destination) => {
        if (destination === 'NEXT') {
            setPagination({ page: Math.min(page + 1, pages), pageSize });
        }
        else if (destination === 'PREV') {
            setPagination({ page: page - 1 || 1, pageSize });
        }
        else if (destination > 0 && destination <= pages) {
            setPagination({ page: destination, pageSize });
        }
    }, [pages, setPagination, page, pageSize]);
    return {
        setPageSize,
        gotoPage,
        pagination: {
            page,
            pageSize,
            total: totalRecords,
            pages,
            hasPrevPage: page > 1,
            hasNextPage: page < pages,
        },
    };
};

import { FlashFilled } from '@carbon/icons-react';
import styled from 'styled-components';
import { LightningIconState, LightningIconColor } from './LightningIcon.constants';
const LightningIcon = styled(FlashFilled)
    .attrs((props) => ({
    state: props.state || LightningIconState.PRIMARY,
    title: 'ocr',
    ...props,
}))
    .withConfig({ displayName: 'LightningIcon' }) `
  color: ${({ state }) => LightningIconColor[state]};
 
  svg {
    display: block;
  }
`;
LightningIcon.defaultProps = {
    size: '16',
};
export default LightningIcon;

import { createInternationalFundingAccountRoutine } from 'actions/routines/funding';

import { createErrorReducer } from 'store/redux';

import {
  MODAL_CONNECT_BANK_MANUAL_OPEN,
  SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_FAILURE,
  SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_REQUEST,
  SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_SUCCESS,
} from 'types/connectBank';

export const failureCases = [
  SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_FAILURE,
  createInternationalFundingAccountRoutine.FAILURE,
];
export const successCases = [
  createInternationalFundingAccountRoutine.REQUEST,
  createInternationalFundingAccountRoutine.SUCCESS,
  MODAL_CONNECT_BANK_MANUAL_OPEN,
  SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_REQUEST,
  SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_SUCCESS,
];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { InfoCard, InfoCardBottom, InfoCardTop } from 'components/infoCard';

import { frequentlyAskedQuestions } from 'modules/external/ExternalCollectTaxInfo/constants/text';

import { TaxFaqDisclosureList, TaxHelpDocsButton, TenNinetyNineFaqIllustration } from './components';

/**
 * InfoCard displaying illustration, common questions, and a link to our help docs.
 * @param {string} [className]
 * @returns {StatelessComponent}
 * @constructor
 */
const TenNinetyNineFaqInfoCard = ({ className }) => (
  <InfoCard
    className={classNames({
      'constrain-width': true,
      [className]: !!className,
    })}
  >
    <InfoCardTop>
      <TenNinetyNineFaqIllustration />
    </InfoCardTop>

    <InfoCardBottom>
      {frequentlyAskedQuestions.map(({ answer, question }) => (
        <TaxFaqDisclosureList answer={answer} key={answer} question={question} />
      ))}

      <TaxHelpDocsButton />
    </InfoCardBottom>
  </InfoCard>
);

TenNinetyNineFaqInfoCard.propTypes = {
  className: PropTypes.string,
};

TenNinetyNineFaqInfoCard.defaultProps = {
  className: undefined,
};

export default TenNinetyNineFaqInfoCard;

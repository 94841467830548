import { testUUID1, testUUID2 } from '@routable/shared';
import { companyPlatformType, companyType } from '../models';
export const testCompanyMailingAddress = {
    type: 'Address',
    id: testUUID2,
    attributes: {
        city: 'New York City',
        country: 'US',
        created: '2024-09-04T09:01:30.912996Z',
        postalcode: '10000',
        streetAddress: '1st Test Street',
    },
};
export const generateTestCompanyMailingAddress = ({ id = testUUID1, attributes = {}, }) => ({
    ...testCompanyMailingAddress,
    id,
    attributes: {
        ...testCompanyMailingAddress.attributes,
        ...attributes,
    },
});
export const testCompany = {
    type: 'Company',
    id: testUUID1,
    attributes: {
        companyType: companyType.Enum.business,
        name: 'Acme Company Inc',
        platformType: companyPlatformType.Enum.added,
    },
    relationships: {
        mailingAddress: {
            data: {
                type: 'Address',
                id: testCompanyMailingAddress.id,
            },
        },
    },
};
export const generateTestCompany = ({ id = testUUID1, attributes = {}, relationships = {}, }) => ({
    ...testCompany,
    id,
    attributes: {
        ...testCompany.attributes,
        ...attributes,
    },
    relationships: {
        ...testCompany.relationships,
        ...relationships,
    },
});

import { fetchCurrenciesRoutine } from 'actions/routines/currencies';

import { initialState } from './initialState';

const dashboardLoadedCurrenciesReducer = (state = initialState.currencies, action) => {
  switch (action.type) {
    case fetchCurrenciesRoutine.SUCCESS:
      return true;

    default:
      return state;
  }
};

export default dashboardLoadedCurrenciesReducer;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * By default, renders an anchor element with onClick behavior that overrides the
 * default href navigation behavior. `href` can still be passed for DOM semantic
 * purposes, and default navigation to `href` will take over should `onClick` not
 * be provided.
 * @param {ComponentProps} props
 * @param {*} props.children
 * @param {StringMaybe} props.className
 * @param {Function} props.onClick
 * @param {ObjectMaybe} props.style
 * @param {Object} props.rest
 * @return {StatelessComponent}
 * @constructor
 */
const OnClickOverrideLink = ({ children, className, href, is, onClick, style, ...rest }) =>
  React.createElement(
    is,
    {
      className: classNames({
        [className]: !!className,
      }),
      // don't pass `href` to other tags that won't recognize it
      href: is === 'a' ? href : undefined,
      onClick,
      role: 'presentation',
      style,
      // only add the `to` prop if needed for rendering `Link`, as the DOM doesn't recognize it
      to: is === Link ? href : undefined,
      ...rest,
    },
    children,
  );

OnClickOverrideLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  is: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  onClick: PropTypes.func,
  style: PropTypes.shape({}),
};

OnClickOverrideLink.defaultProps = {
  children: undefined,
  className: undefined,
  // hash url for DOM semantics (empty href is non-ideal)
  href: '#',
  is: 'a',
  onClick: undefined,
  style: {},
};

export default OnClickOverrideLink;

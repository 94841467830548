import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ConfirmationSectionHeader from 'components/confirmation/ConfirmationSectionHeader';

import { getItemDateExternallyPaid, isItemStatusExternallyPaid } from 'helpers/items';

import { itemFromQuerySelector } from 'queries/itemRouterSelectors';

import ExternalPromptInstructions from '../global/presenters/ExternalPromptInstructions';
import NextSteps from '../global/presenters/NextSteps';

import PromptHeaderReceivablePaid from './components/PromptHeaderReceivablePaid';

const ExternalPromptCustomerSingleItemExternallyPaid = ({ item }) => {
  if (!isItemStatusExternallyPaid(item)) {
    return null;
  }

  const instructions = [
    {
      id: '✔',
      key: '1',
      text: `We have record of your payment made on ${getItemDateExternallyPaid(item)}.`,
    },
    {
      id: '✔',
      key: '2',
      text: 'No further action is needed.',
    },
  ];

  return (
    <React.Fragment>
      <ConfirmationSectionHeader text="Confirmed" />
      <PromptHeaderReceivablePaid />
      <NextSteps />
      <ExternalPromptInstructions instructions={instructions} />
    </React.Fragment>
  );
};

ExternalPromptCustomerSingleItemExternallyPaid.propTypes = {
  item: PropTypes.shape().isRequired,
};

const mapStateToProps = createStructuredSelector({
  item: itemFromQuerySelector,
});

export { ExternalPromptCustomerSingleItemExternallyPaid };
export default connect(mapStateToProps)(ExternalPromptCustomerSingleItemExternallyPaid);

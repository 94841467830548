import React from 'react';
import { Redirect } from 'react-router-dom';
import { DASHBOARD, LOGIN } from 'constants/routes';
import { getQueryParamsFromUrl } from 'helpers/queryParams';
import { asPath } from 'helpers/routeHelpers';
import QuickswitchErrorModal from '../QuickswitchErrorModal';
import QuickswitchSplashscreen from '../QuickswitchSplashscreen/QuickswitchSplashscreen';
const QuickswitchToBrandedSubdomainContent = ({ goToDashboard, goToLogin, showErrorModal, companyName, }) => {
    if (!('data' in getQueryParamsFromUrl()) || goToLogin) {
        return React.createElement(Redirect, { to: asPath(LOGIN) });
    }
    if (goToDashboard) {
        return React.createElement(Redirect, { to: asPath(DASHBOARD) });
    }
    if (showErrorModal) {
        return React.createElement(QuickswitchErrorModal, { isModalOpen: true });
    }
    return React.createElement(QuickswitchSplashscreen, { companyName: companyName });
};
QuickswitchToBrandedSubdomainContent.defaultProps = { companyName: undefined };
export default QuickswitchToBrandedSubdomainContent;

import React from 'react';
import { ModalFooter, ModalFooterButtonCancel, IconNames, ButtonV2 } from 'components';
import { ButtonSize } from 'constants/button';
import { colors, sizes } from 'constants/styles';
import {} from './InviteTeamMemberFooter.types';
const InviteTeamMemberFooter = ({ handleClose, disabled }) => (React.createElement(ModalFooter, { style: {
        backgroundColor: colors.colorWhiteHex,
    } },
    React.createElement("div", { className: "content--left" },
        React.createElement(ModalFooterButtonCancel, { onCloseModal: handleClose })),
    React.createElement("div", { className: "content--right" },
        React.createElement(ButtonV2, { isDisabled: disabled, leftIconClassName: "margin-right--m", leftIconName: IconNames.TICK_CIRCLE, leftIconSize: sizes.iconSizes.EXTRA_MEDIUM, size: ButtonSize.MEDIUM, type: "submit" }, "Send Invite"))));
export default InviteTeamMemberFooter;

import {
  fetchPartnershipMembersRoutine,
  fetchSinglePartnershipMemberRoutine,
} from 'actions/routines/partnershipMember';

import { createLastFetchedReducer } from 'store/redux';

import * as itemTypes from 'types/item';
import * as partnershipTypes from 'types/partnership';

export const successCases = [
  fetchPartnershipMembersRoutine.SUCCESS,
  fetchSinglePartnershipMemberRoutine.SUCCESS,
  itemTypes.FETCH_ITEM_SUCCESS,
  partnershipTypes.FETCH_PARTNERSHIP_SUCCESS,
];

export const clearCases = [
  fetchPartnershipMembersRoutine.FAILURE,
  fetchSinglePartnershipMemberRoutine.FAILURE,
  itemTypes.FETCH_ITEM_FAILURE,
  itemTypes.FETCH_ITEM_FAILURE,
  partnershipTypes.FETCH_PARTNERSHIP_FAILURE,
];

export default createLastFetchedReducer(successCases, clearCases);

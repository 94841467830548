import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { inviteTeamMemberFormFields } from 'constants/formFields';
import { SETTINGS_ACCOUNT_INVITED_TEAM_MEMBERS_ROUTE } from 'constants/routes';

import { getUserSettingsProfileRoute } from 'helpers/routeHelpers';

import { useFieldAsyncErrors, useFieldValue } from 'hooks';

import { getMembershipIdFromAsyncWarning } from './helpers/membership';
import { shouldHideHints } from './helpers/ui';
import InviteTeamMemberFormEmailHints from './InviteTeamMemberFormEmailHints';

/**
 * Do some of the dirty work for the InviteTeamMemberFormEmailHints so that the presentational component can just
 * feed props to the correct component without deriving the state itself.
 * @param {ComponentProps} props
 * @param {string} props.form
 * @param {History} props.history
 * @returns {StatelessComponent}
 */
const InviteTeamMemberFormEmailHintsContainer = ({ form, history }) => {
  // miscellaneous errors related to the email that the BE has given us, probably after we submitted the form
  const asyncErrors = useFieldAsyncErrors(form, inviteTeamMemberFormFields.EMAIL);
  // validation warnings from when we asked the BE, "Hey, can we invite this email?"
  const warningEmail = useFieldValue(form, inviteTeamMemberFormFields.META_WARNINGS_EMAIL);

  if (shouldHideHints({ asyncErrors, warningEmail })) {
    return null;
  }

  const membershipId = getMembershipIdFromAsyncWarning(warningEmail);
  const handleNavigateToSingleTeamMemberProfile = () => history.push(getUserSettingsProfileRoute(membershipId));
  const handleNavigateToTeamMemberInvites = () => history.push(SETTINGS_ACCOUNT_INVITED_TEAM_MEMBERS_ROUTE);

  return (
    <div className="margin-bottom--form-margin">
      <InviteTeamMemberFormEmailHints
        asyncErrors={asyncErrors}
        navigateToSingleTeamMemberProfile={handleNavigateToSingleTeamMemberProfile}
        navigateToTeamMemberInvites={handleNavigateToTeamMemberInvites}
        warningEmail={warningEmail}
      />
    </div>
  );
};

InviteTeamMemberFormEmailHintsContainer.propTypes = {
  form: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(InviteTeamMemberFormEmailHintsContainer);

import { PaymentDeliveryMethodType } from '@routable/shared';
import classNames from 'classnames';
import _cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, isDirty, reduxForm } from 'redux-form';

import { openCreateManualAddressModal } from 'actions/createManualAddress';
import { getCurrentCompanyRequest } from 'actions/currentCompany';
import { resetExternalUpdatePaymentMethodForm } from 'actions/externalUpdatePayment';
import { fetchFundingAccountsRequest } from 'actions/funding';
import { fetchSingleMembershipRequest } from 'actions/memberships';
import { fetchSinglePartnershipRequest } from 'actions/partnership';

import { BrandFooter, ButtonV2, ConfirmationSectionHeader, Divider, IconNames } from 'components';

import { ButtonSize } from 'constants/button';
import { updatePaymentMethodFormFields } from 'constants/formFields';
import { formNamesExternal } from 'constants/forms';
import { FundingSourceProviderSubClasses } from 'constants/funding';
import { sizes } from 'constants/styles';

import { isFundingAccountValid } from 'helpers/funding';
import { isPaymentDeliveryMethodAch, isPaymentMethodDeliveryCheck } from 'helpers/paymentMethods';
import { getQueryParam } from 'helpers/queryParams';
import { every } from 'helpers/utility';

import withWarnAboutUnsavedChanges from 'hoc/withWarnAboutUnsavedChanges';

import { shouldUpdateFundingAccount } from 'modules/createPartnerCompany/helpers/paymentMethodFormHelpers';
import { makeFormSubmitFailHandler } from 'modules/createPartnerCompany/helpers/submitFailure';
import PartnerCompanyAcceptPayment from 'modules/createPartnerCompany/presenters/PartnerCompanyAcceptPayment';
import ExternalPromptHeader from 'modules/external/externalPrompt/global/presenters/ExternalPromptHeader';
import ExternalPromptInstructions from 'modules/external/externalPrompt/global/presenters/ExternalPromptInstructions';
import NextSteps from 'modules/external/externalPrompt/global/presenters/NextSteps';
import BlockUpdatePaymentMethodHint from 'modules/external/externalUpdatePaymentMethod/components/BlockUpdatePaymentMethodHint';
import HeadingReceivablePaymentMethod from 'modules/external/externalUpdatePaymentMethod/components/HeadingReceivablePaymentMethod';
import {
  achInstructions,
  alertOptions,
  checkInstructions,
} from 'modules/external/externalUpdatePaymentMethod/containers/constants';
import ExternalHeader from 'modules/external/global/components/header/ExternalHeader';
import External from 'modules/external/global/presenters/External';
import ExternalBody from 'modules/external/global/presenters/ExternalBody';
import ExternalFooter from 'modules/external/global/presenters/ExternalFooter';
import { submitUpdatePaymentMethod } from 'modules/external/helpers';
import { IsLoadingHasWrapper } from 'modules/isLoading/IsLoading';

import { fundingAccountsAllForProviderSubClassSelector } from 'queries/fundingCompoundSelectors';
import { isFetchingPartnerUpdateFundingAccountRequirementsSelector } from 'queries/partnershipCompoundSelectors';

import { externalUpdatePaymentFormSelector } from 'selectors/forms';
import { fundingAccountsByIdSelector, fundingAccountsLastSubmittedSelector } from 'selectors/fundingSelectors';
import { isSubmittingItemSelector, lastSubmittedItemSelector } from 'selectors/itemsSelectors';
import { modalIsOpenSelector } from 'selectors/modalsSelector';
import { partnershipSelector } from 'selectors/partnershipsSelectors';

import submitAllExistingItems from 'thunks/submitAllExistingItems';

const membershipId = getQueryParam('membership_id');
const partnershipId = getQueryParam('partnership_id');

const formName = formNamesExternal.UPDATE_PAYMENT_METHOD;

export class ExternalUpdatePaymentMethodContainer extends React.Component {
  formId = formName;

  updateButtonRef = React.createRef();

  componentDidMount() {
    const { onFetchCurrentCompany, onFetchFundingAccounts, onFetchMembership, onFetchPartnership } = this.props;

    onFetchCurrentCompany();
    onFetchFundingAccounts();
    onFetchMembership(membershipId);
    onFetchPartnership(partnershipId);
  }

  componentDidUpdate(prevProps) {
    const fundingAccountNeedsUpdate = shouldUpdateFundingAccount(prevProps, this.props);
    const { isFormDirty } = this.props;

    if (fundingAccountNeedsUpdate) {
      this.updateReceivableFundingAccount(this.props);
    }
    // Checks if the form is dirty and if the update button is enabled
    // Should focus the update button when a new payment method is selected
    if (isFormDirty && this.isUpdateButtonEnabled()) {
      if (this.updateButtonRef.current && this.updateButtonRef.current.scrollIntoView) {
        this.updateButtonRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  getAchFundingAccount = (nextProps) => {
    const { fundingAccountsWithAchFundingSource, lastSubmittedFundingAccount } = nextProps;

    // partner submitted a bank funding account for the first time
    if (lastSubmittedFundingAccount && lastSubmittedFundingAccount.bank) {
      return lastSubmittedFundingAccount;
    }

    // partner has a single saved Ach funding account
    if (fundingAccountsWithAchFundingSource.length === 1) {
      return fundingAccountsWithAchFundingSource[0];
    }

    return null;
  };

  getCheckFundingAccount = (nextProps) => {
    const { fundingAccountsWithAddressFundingSources, lastSubmittedFundingAccount } = nextProps;

    // partner submitted a new address funding account
    if (lastSubmittedFundingAccount && lastSubmittedFundingAccount.address) {
      return lastSubmittedFundingAccount;
    }

    // partner has a single saved address funding account
    if (fundingAccountsWithAddressFundingSources.length === 1) {
      return fundingAccountsWithAddressFundingSources[0];
    }

    return null;
  };

  /**
   *  we use onSubmitAllExistingItems to accept all payments and update the partnership
   *  since there are no payments, we only pass the kind 'RECEIVABLE' and action 'accept'
   *  to update the receivable payment method and default funding account
   */
  handleSubmitReceivablePaymentMethod = async (values) => {
    const { isAddressModalOpen, isConnectBankModalOpen, onFormReset } = this.props;

    if (!isAddressModalOpen && !isConnectBankModalOpen) {
      await submitUpdatePaymentMethod(values, {
        ...this.props,
        partnershipId,
        successCallback: onFormReset,
      });
    }
  };

  /**
   * Helper function to check if the update button should be enabled
   */
  isUpdateButtonEnabled = () => {
    const { defaultReceivable, fundingAccounts, partnership = {} } = this.props;

    const selectedFundingAccountId = defaultReceivable.fundingAccount;
    const currentFundingAccount = fundingAccounts[selectedFundingAccountId];

    return every([
      currentFundingAccount,
      isFundingAccountValid(currentFundingAccount || {}),
      !partnership.defaultReceivableFundingAccount,
    ]);
  };

  /**
   * update the receivable funding account in state
   */
  updateReceivableFundingAccount = (nextProps, paymentDeliveryMethod = null, fundingAccount = null) => {
    const { onValuesChange } = this.props;
    const { defaultReceivable, formUI } = nextProps;

    const newFormState = _cloneDeep(defaultReceivable);
    const newFormUIState = _cloneDeep(formUI);

    const selectedPaymentMethod = paymentDeliveryMethod || defaultReceivable.paymentDeliveryMethod;

    let updatedFundingAccount;

    if (fundingAccount) {
      updatedFundingAccount = fundingAccount;
    } else if (isPaymentDeliveryMethodAch(selectedPaymentMethod)) {
      updatedFundingAccount = this.getAchFundingAccount(nextProps);
    } else if (isPaymentMethodDeliveryCheck(selectedPaymentMethod)) {
      updatedFundingAccount = this.getCheckFundingAccount(nextProps);
    }

    // Ensure we have an empty object for the funding account in case it's not updated
    if (!updatedFundingAccount) {
      updatedFundingAccount = {
        id: '',
        isValid: false,
      };
    }

    // Skip setting the state of it's the same funding account and payment method
    if (
      defaultReceivable.fundingAccount === updatedFundingAccount.id &&
      defaultReceivable.paymentDeliveryMethod === selectedPaymentMethod
    ) {
      return;
    }

    // Add receiver funding account or clear it if none selected
    newFormState.fundingAccount = updatedFundingAccount.id;
    newFormState.paymentDeliveryMethod = selectedPaymentMethod;
    newFormUIState.defaultReceivable.isFundingAccountValid = updatedFundingAccount.isValid;

    onValuesChange(formName, 'formUI', newFormUIState);
    onValuesChange(formName, 'defaultReceivable', newFormState);
  };

  /**
   * triggers form changes when check funding acount changes
   * @param {string} fundingAccountId
   */
  handleFundingAccountSelected = (fundingAccountId) => {
    const { onValuesChange } = this.props;
    onValuesChange(formName, updatePaymentMethodFormFields.FUNDING_ACCOUNT, fundingAccountId);
  };

  handleFundingAccountAddressSelected = (address) => {
    const { onOpenCreateManualAddressModal } = this.props;
    onOpenCreateManualAddressModal(address);
  };

  renderHeaderUpdatePaymentMethod = () => {
    const { lastSubmitted } = this.props;

    if (lastSubmitted) {
      return null;
    }

    return <ExternalPromptHeader showRequester text="Select a new payment method" />;
  };

  renderBodyUpdatePaymentMethod = () => {
    const { defaultReceivable, handleSubmit, partnership, lastSubmitted } = this.props;

    if (lastSubmitted) {
      return null;
    }

    return (
      <React.Fragment>
        <Divider />
        <BlockUpdatePaymentMethodHint />
        <HeadingReceivablePaymentMethod />

        <form
          className={classNames({
            form: true,
            'disable-pointer-events': !!partnership.defaultReceivableFundingAccount,
            'opacity-standard': !!partnership.defaultReceivableFundingAccount,
          })}
          id={this.formId}
          onSubmit={handleSubmit(this.handleSubmitReceivablePaymentMethod)}
        >
          <PartnerCompanyAcceptPayment
            fieldNamePrefix="defaultReceivable."
            formId={this.formId}
            fundingAccount={defaultReceivable.fundingAccount}
            onCheckFundingAccountSelected={this.handleFundingAccountSelected}
            onFundingAccountAddressSelected={this.handleFundingAccountAddressSelected}
            paymentDeliveryMethod={defaultReceivable.paymentDeliveryMethod}
          />
        </form>
      </React.Fragment>
    );
  };

  renderHeaderConfirmation = () => {
    const { lastSubmitted } = this.props;

    if (!lastSubmitted) {
      return null;
    }

    return (
      <React.Fragment>
        <ConfirmationSectionHeader text="Confirmed" />
        <ExternalPromptHeader text="Your payment preferences are saved!" />
      </React.Fragment>
    );
  };

  renderButton = () => {
    const { lastSubmitted } = this.props;

    if (lastSubmitted) {
      return null;
    }

    return (
      <ButtonV2
        htmlFor={this.formId}
        isDisabled={!this.isUpdateButtonEnabled()}
        leftIconClassName="margin-right--m"
        leftIconName={IconNames.SHIELD}
        leftIconSize={sizes.iconSizes.LARGE}
        rightIconName={IconNames.ARROW_RIGHT}
        rightIconProps={{ style: { marginLeft: 'auto' } }}
        rightIconSize={sizes.iconSizes.LARGE}
        setRef={this.updateButtonRef}
        size={ButtonSize.LARGE}
        type="submit"
      >
        Update my payment methods
      </ButtonV2>
    );
  };

  renderBodyConfirmationAch = () => {
    const { defaultReceivable, lastSubmitted } = this.props;

    if (!lastSubmitted) {
      return null;
    }

    if (!isPaymentDeliveryMethodAch(defaultReceivable.paymentDeliveryMethod)) {
      return null;
    }

    return (
      <div className="external-prompt">
        <NextSteps />
        <ExternalPromptInstructions instructions={achInstructions} />
      </div>
    );
  };

  renderBodyConfirmationCheck = () => {
    const { defaultReceivable, lastSubmitted } = this.props;

    if (!lastSubmitted) {
      return null;
    }

    if (!isPaymentMethodDeliveryCheck(defaultReceivable.paymentDeliveryMethod)) {
      return null;
    }

    return (
      <div className="external-prompt">
        <NextSteps />
        <ExternalPromptInstructions instructions={checkInstructions} />
      </div>
    );
  };

  render() {
    const { isFetchingRequirements, partnership } = this.props;

    if (!partnership || isFetchingRequirements) {
      return <IsLoadingHasWrapper hasNav />;
    }

    return (
      <External>
        <ExternalHeader department="Payment preferences" partnership={partnership} />
        <ExternalBody>
          <div className="no-item">
            {/* Header */}
            {this.renderHeaderConfirmation()}
            {this.renderHeaderUpdatePaymentMethod()}

            {/* Body */}
            {this.renderBodyConfirmationAch()}
            {this.renderBodyConfirmationCheck()}
            {this.renderBodyUpdatePaymentMethod()}

            {/* Button */}
            {this.renderButton()}
          </div>
        </ExternalBody>

        <ExternalFooter>
          <BrandFooter hideSupport showSecure />
        </ExternalFooter>
      </External>
    );
  }
}

ExternalUpdatePaymentMethodContainer.propTypes = {
  defaultReceivable: PropTypes.shape(),
  // used in destructure of `nextProps`
  /* eslint-disable-next-line react/no-unused-prop-types */
  formUI: PropTypes.shape(),
  fundingAccounts: PropTypes.shape().isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isAddressModalOpen: PropTypes.bool,
  isConnectBankModalOpen: PropTypes.bool,
  isFetchingRequirements: PropTypes.bool,
  isFormDirty: PropTypes.bool,
  // used in shouldUpdateFundingAccount helper
  /* eslint-disable-next-line react/no-unused-prop-types */
  isSubmittingItem: PropTypes.bool,
  onFetchCurrentCompany: PropTypes.func.isRequired,
  onFetchFundingAccounts: PropTypes.func.isRequired,
  onFetchMembership: PropTypes.func.isRequired,
  onFetchPartnership: PropTypes.func.isRequired,
  onOpenCreateManualAddressModal: PropTypes.func.isRequired,
  // used in submit helper
  /* eslint-disable-next-line react/no-unused-prop-types */
  onSubmitAllExistingItems: PropTypes.func.isRequired,
  lastSubmitted: PropTypes.instanceOf(Date),
  partnership: PropTypes.shape(),
  onValuesChange: PropTypes.func.isRequired,
  onFormReset: PropTypes.func.isRequired,
};

ExternalUpdatePaymentMethodContainer.defaultProps = {
  defaultReceivable: {},
  formUI: { defaultReceivable: {} },
  isAddressModalOpen: undefined,
  isConnectBankModalOpen: undefined,
  isFetchingRequirements: undefined,
  isFormDirty: undefined,
  isSubmittingItem: undefined,
  lastSubmitted: undefined,
  partnership: undefined,
};

const mapStateToProps = (state) => ({
  defaultReceivable: externalUpdatePaymentFormSelector(state, 'defaultReceivable'),
  formUI: externalUpdatePaymentFormSelector(state, 'formUI'),
  fundingAccounts: fundingAccountsByIdSelector(state),
  fundingAccountsWithAchFundingSource: fundingAccountsAllForProviderSubClassSelector(
    state,
    FundingSourceProviderSubClasses.ACH,
  ),
  fundingAccountsWithAddressFundingSources: fundingAccountsAllForProviderSubClassSelector(
    state,
    FundingSourceProviderSubClasses.ADDRESS,
  ),
  initialValues: {
    defaultReceivable: {
      fundingAccount: '',
      paymentDeliveryMethod: PaymentDeliveryMethodType.ACH,
    },
    formUI: {
      defaultReceivable: {
        isFundingAccountValid: false,
      },
    },
  },
  isAddressModalOpen: modalIsOpenSelector(state, 'createManualAddress'),
  isConnectBankModalOpen: modalIsOpenSelector(state, 'connectBankManual'),
  isFetchingRequirements: isFetchingPartnerUpdateFundingAccountRequirementsSelector(state),
  isFormDirty: isDirty(formName)(state),
  isSubmittingItem: isSubmittingItemSelector(state),
  lastSubmitted: lastSubmittedItemSelector(state),
  lastSubmittedFundingAccount: fundingAccountsLastSubmittedSelector(state),
  partnership: partnershipSelector(state, partnershipId),
  paymentDeliveryMethod: externalUpdatePaymentFormSelector(state, 'defaultReceivable.paymentDeliveryMethod'),
});

const mapDispatchToProps = {
  onFetchCurrentCompany: getCurrentCompanyRequest,
  onFetchFundingAccounts: fetchFundingAccountsRequest,
  onFetchMembership: fetchSingleMembershipRequest,
  onFetchPartnership: fetchSinglePartnershipRequest,
  onOpenCreateManualAddressModal: openCreateManualAddressModal,
  onSubmitAllExistingItems: submitAllExistingItems,
  onValuesChange: change,
  onFormReset: resetExternalUpdatePaymentMethodForm,
};

const createUpdatePaymentForm = reduxForm({
  form: formName,
  onSubmitFail: makeFormSubmitFailHandler(formName),
})(ExternalUpdatePaymentMethodContainer);

const connectedForm = connect(mapStateToProps, mapDispatchToProps)(createUpdatePaymentForm);

export default withWarnAboutUnsavedChanges(connectedForm, formName, alertOptions);

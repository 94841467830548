import PropTypes from 'prop-types';
import React from 'react';

/**
 * Table header component for the invoice generator ledger field tables.
 * @param {*} children
 * @param {string} className
 * @return {StatelessComponent}
 */
const InvoiceGeneratorTableHeader = ({ children, className }) => <div className={className}>{children}</div>;

InvoiceGeneratorTableHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

InvoiceGeneratorTableHeader.defaultProps = {
  children: undefined,
  className: undefined,
};

export default InvoiceGeneratorTableHeader;

import { IntegrationSyncingStatusTypes } from 'constants/integration';
import { LedgerApplicationTypes, LEDGER_APPLICATION_ID_SUBSTITUTION_SLUG } from 'constants/ledger';
import { SETTINGS_ACCOUNT_INTEGRATIONS_ROUTE } from 'constants/routes';
import { TagType } from 'constants/ui';

import helpDocs from 'helpers/helpDocs';
import {
  isApplicationTypeSageIntacct,
  isLedgerApplicationTypeNetsuite,
  isLedgerApplicationTypeQBO,
  isLedgerApplicationTypeSageIntacct,
  isLedgerApplicationTypeXero,
} from 'helpers/ledger';
import { sortObjectArray } from 'helpers/sort';
import { isDefined, isEqual } from 'helpers/utility';

export const isIntegrationConnected = (integration) => !!integration.connected;
export const isIntegrationEnabled = (integration) => typeof integration.connected === 'boolean';

export const isApplicationNetSuite = (application) => isEqual(application, LedgerApplicationTypes.NETSUITE);

/**
 * Get the route for managing a connected ledger integration.
 *
 * @param {Ledger} ledgerIntegration
 * @returns {string}
 */
export const getLedgerIntegrationApplicationURL = (ledgerIntegration) =>
  `${SETTINGS_ACCOUNT_INTEGRATIONS_ROUTE}/${ledgerIntegration.application}`;

/**
 * Click handler for navigating to route to manage a connected ledger integration.
 *
 * @param {OptionsArg} options
 * @param {History} options.history
 * @param {Ledger} options.ledgerIntegration
 */
export const navigateToLedgerIntegrationApplicationURL = ({ history, ledgerIntegration }) =>
  history.push(getLedgerIntegrationApplicationURL(ledgerIntegration));

/**
 * Returns a link for a ledger's help docs
 * @param {Ledger} ledger
 * @returns {string} URL string
 */
export const getMatchCompanyHelpDocLink = (ledger) => {
  if (isLedgerApplicationTypeQBO(ledger)) {
    return helpDocs.QBO_WHY_AM_I_SEEING_MATCH_CUSTOMER_OR_VENDOR_ALERT;
  }

  if (isLedgerApplicationTypeSageIntacct(ledger)) {
    return helpDocs.SAGE_INTACCT_COMPANY_MATCHING_ALERT;
  }

  if (isLedgerApplicationTypeXero(ledger)) {
    return helpDocs.XERO_SYNCING_VENDORS_AND_CUSTOMERS;
  }

  // Must be NetSuite
  if (isLedgerApplicationTypeNetsuite(ledger)) {
    return helpDocs.ORACLE_NETSUITE_SYNCING_VENDORS_AND_CUSTOMERS;
  }

  // we don't have a matching help doc for this ledger
  return undefined;
};

/**
 * Returns a link for help docs on connecting a ledger
 * @param {string} application
 * @returns {string} URL string
 */
export const getConnectLedgerHelpDocLink = (application) => {
  if (isApplicationTypeSageIntacct(application)) {
    return helpDocs.SAGE_INTACCT_INTEGRATING_SOFTWARE;
  }

  // Other ledger specific docs are not set up yet
  return helpDocs.INTEGRATING_ACCOUNTING_SOFTWARE;
};

/**
 * Returns a link for a ledger's add accounts app section with an optional applicationId variable replacement.
 * @param link {string} URL string
 * @param applicationId {string} Application id of the ledger.
 * @returns {string} URL string
 */
export const parseLedgerAddAccountLink = (link, applicationId) => {
  if (link?.includes(LEDGER_APPLICATION_ID_SUBSTITUTION_SLUG)) {
    if (isDefined(applicationId)) {
      return link.replace(LEDGER_APPLICATION_ID_SUBSTITUTION_SLUG, applicationId);
    }

    return undefined;
  }

  return link;
};

/**
 * Returns a link for ledger's add accounts app section for given ledger type.
 * @param {Ledger} ledger
 * @param {string} applicationId
 * @returns {string} URL string
 */
export const getAddAccountLinkForLedger = (ledger, applicationId) =>
  parseLedgerAddAccountLink(ledger.presentation.bankLinks.addBankAccount, applicationId);

/**
 * Returns a link for ledger ledger's bank feed resource for given ledger type.
 * @param {Ledger} ledger
 * @returns {string}  URL string
 */
export const getBankFeedResourceForLedger = (ledger) => ledger.presentation.bankLinks.connectBankFeed;

/**
 * Given an array of ledger unmatched funding accounts, and an optional balanceFundingAccount,
 * returns the unmatched funding accounts, filtering balance if it exists. Returned array is
 * sorted by 'name' property.
 * @param {Object[]} fundingAccounts
 * @param {ObjectMaybe} [balanceFundingAccount]
 * @return {Object[]}
 */
export const getAllLedgerUnresolvedLedgerFundingAccountsFilteringBalance = (fundingAccounts, balanceFundingAccount) => {
  let filteredFundingAccounts = fundingAccounts;

  if (balanceFundingAccount) {
    filteredFundingAccounts = filteredFundingAccounts.filter(
      (fundingAccount) => fundingAccount.ledgerRef !== balanceFundingAccount.ledgerRef,
    );
  }

  return sortObjectArray(filteredFundingAccounts, 'name');
};

/**
 * When NetSuite is connected, the applicationID is formatted as: {applicationId}:{subsidiaryID}
 * This helper returns each ID individually or the default state used by other ledgers
 * @param props
 * @param {string} props.application
 * @param {string} applicationID
 * @return {{subsidiaryID: string | undefined, applicationID: string}}
 */
export const getParsedApplicationID = ({ application, applicationID }) => {
  if (isApplicationNetSuite(application)) {
    const [appID, subsidiaryID] = applicationID.split(':');
    return {
      applicationID: appID,
      subsidiaryID,
    };
  }
  return {
    applicationID,
    subsidiaryID: undefined,
  };
};

/**
 * Return tag type based on the current ledger connection status
 * @param {OptionsArg} options
 * @param {Boolean} [options.isFetchingIntegrationConfig]
 * @param {Boolean} [options.isLedgerIntegrationConnected]
 * @return {string}
 */
export const getGenericDisplayTagType = (options = {}) => {
  const { isFetchingIntegrationConfig, isLedgerIntegrationConnected } = options;

  if (isFetchingIntegrationConfig) {
    return TagType.DEFAULT;
  }

  if (isLedgerIntegrationConnected) {
    return TagType.SUCCESS;
  }

  return TagType.ERROR;
};

/**
 * Returns boolean indicating whether the integration's status matches the syncing status
 * @param {string} syncingStatus
 * @return {boolean}
 */
export const integrationIsSyncing = (syncingStatus) =>
  isEqual(syncingStatus, IntegrationSyncingStatusTypes.MANUAL_SYNC);

/**
 * Returns boolean indicating whether the integration is connected
 * @param {object} integration
 * @return {boolean}
 */
export const integrationIsConnected = (integration) => integration?.connected;

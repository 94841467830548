import withIsDisabled from 'hoc/withIsDisabled';

import withPermissionRestriction from '../withPermissionRestriction';

/**
 * An HOC which returns the correct component based on whether the current membership has the correct permissions.
 *
 * @param {RequiredPermissions} requiredPermissions
 * @param {StringMaybe} [isDisabledProp='isDisabled']
 * @returns {function}
 */
const withIsDisabledWhenMissingPermissions =
  (requiredPermissions, isDisabledProp = 'isDisabled') =>
  (Component) => {
    const ComponentWithIsDisabled = withIsDisabled(Component, isDisabledProp);

    return withPermissionRestriction({
      requiredPermissions,
      WithoutPermissionComponent: ComponentWithIsDisabled,
      WithPermissionComponent: Component,
    });
  };

export default withIsDisabledWhenMissingPermissions;

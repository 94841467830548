import { PaginationSizes } from 'constants/pagination';

import { ITEM_SORT_TYPE_UPDATED_DATE } from 'enums/itemSorts';

import {
  getNextStateForEmailOnValidateFailed,
  getNextStateForEmailOnValidateSuccess,
  getObjectsByIdWithRelationships,
} from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';
import { allKeys, diffArrays, isNotEqual, uniqueArray, uniqueArrayByProperty } from 'helpers/utility';

/**
 * Transforms an array of Redux types into an easy lookup dictionary.
 * @function
 * @example
 * const types = ['DO_THING_SUCCESS', 'DO_THING_FAILURE'];
 * createTypeMap(types);
 * const returns = {
 *   'DO_THING_SUCCESS': true,
 *   'DO_THING_FAILURE': true,
 * };
 * @param {ReduxType[]} types
 * @returns {Object}
 */
export const createTypeMap = (types) => types.reduce((acc, type) => ({ ...acc, [type]: true }), {});

/**
 * Creates boilerplate error reducer.
 * @function
 * @param {ReduxType[]} successCases
 * @param {ReduxType[]} failureCases
 * @returns {ReduxReducer}
 */
export const createErrorReducer = (successCases, failureCases) => {
  const successMap = createTypeMap(successCases);
  const failureMap = createTypeMap(failureCases);

  return (state = null, action) => {
    const { type: actionType } = action;

    if (failureMap[actionType]) {
      return action?.payload?.errors || null;
    }

    if (successMap[actionType]) {
      return null;
    }
    return state;
  };
};

/**
 * Creates boilerplate isCreating/isFetching/isSubmitting/isUpdating reducer.
 * @function
 * @param {ReduxType[]} finishCases
 * @param {ReduxType[]} requestCases
 * @returns {ReduxReducer}
 */
export const createIsDoingReducer = (finishCases, requestCases) => {
  const finishMap = createTypeMap(finishCases);
  const requestMap = createTypeMap(requestCases);

  return (state = false, action) => {
    const { type: actionType } = action;

    if (requestMap[actionType]) {
      return true;
    }

    if (finishMap[actionType]) {
      return false;
    }

    return state;
  };
};

export const createIsCreatingReducer = createIsDoingReducer;
export const createIsDeletingReducer = createIsDoingReducer;
export const createIsDisablingReducer = createIsDoingReducer;
export const createIsEnablingReducer = createIsDoingReducer;
export const createIsFetchingReducer = createIsDoingReducer;
export const createIsOpenReducer = createIsDoingReducer;
export const createIsSubmittingReducer = createIsDoingReducer;
export const createIsRevokingReducer = createIsDoingReducer;
export const createIsUpdatingReducer = createIsDoingReducer;
export const createIsUploadingReducer = createIsDoingReducer;

/**
 * Creates boilerplate wasFetched reducer.
 * @function
 * @param {ReduxType[]} finishCases
 * @returns {ReduxReducer}
 */
export const createWasFetchedReducer = (finishCases) => {
  const finishMap = createTypeMap(finishCases);

  return (state = false, action) => {
    const { type: actionType } = action;

    if (finishMap[actionType]) {
      return true;
    }

    return state;
  };
};

/**
 * Creates boilerplate lastCreated/lastFetched/lastUpdated/lastSubmitted reducer.
 * @function
 * @param {ReduxType[]} successCases
 * @param {ReduxType[]} requestOrFailureCases
 * @returns {ReduxReducer}
 */
export const createLastDoneReducer = (successCases, requestOrFailureCases = []) => {
  const successMap = createTypeMap(successCases);
  const requestMap = createTypeMap(requestOrFailureCases);

  return (state = null, action) => {
    const { type: actionType } = action;

    if (requestMap[actionType]) {
      return null;
    }

    if (successMap[actionType]) {
      return new Date();
    }

    return state;
  };
};

export const createLastCreatedReducer = createLastDoneReducer;
export const createLastFetchedReducer = createLastDoneReducer;
export const createLastSubmittedReducer = createLastDoneReducer;
export const createLastUpdatedReducer = createLastDoneReducer;

/**
 * Creates a common object to describe pagination for pagination reducers.
 * @param {ObjectMaybe} [overrides={}]
 * @returns {Object}
 */
export const createPaginationReducerInitialState = (overrides = {}) => ({
  count: 0,
  page: 1,
  pages: 1,
  pageSize: PaginationSizes.DEFAULT,
  ...overrides,
});

/**
 * Curried.
 *
 * Create a boilerplate pagination reducer.
 * @function
 * @param {string[]} successCases
 * @param {ObjectMaybe} [initialState]
 * @returns {ReduxReducer}
 */
export const createPaginationReducer = (successCases, initialState = createPaginationReducerInitialState()) => {
  const successMap = createTypeMap(successCases);

  return (state = initialState, action) => {
    const { type: actionType } = action;

    if (successMap[actionType]) {
      return {
        current: action.payload.links?.current,
        first: action.payload.links?.first,
        last: action.payload.links?.last,
        next: action.payload.links?.next,
        prev: action.payload.links?.prev,

        count: action.payload.meta.pagination.count,
        page: action.payload.meta.pagination.page,
        pages: action.payload.meta.pagination.pages,
        pageSize: action.payload.meta.pagination.pageSize,
      };
    }

    return state;
  };
};

/**
 * Boilerplate for creating a table reducer
 * @param {TableReducerEventTypes} types
 * @return {ReduxReducer}
 */
export const createTableReducer = (types) => {
  const initialState = {
    sort: `-${ITEM_SORT_TYPE_UPDATED_DATE.PARAM}`,
    filters: [],
    lockedSelection: [],
    selected: [],
  };

  const lockSelectionMap = createTypeMap(types.lockSelection);

  return (state = initialState, action) => {
    switch (action.type) {
      case types.copyLockedSelection:
        return {
          ...state,
          selected: [...state.lockedSelection],
        };

      case types.deselectAll:
        return {
          ...state,
          lockedSelection: [],
          selected: [],
        };

      case types.deselectMultipleRows:
        return {
          ...state,
          selected: diffArrays(state.selected, action.payload.items, 'id'),
        };

      case types.deselectSingle:
        return {
          ...state,
          selected: state.selected.filter(({ id }) => isNotEqual(id, action.payload.item.id)),
        };
      case types.fetchPartnership:
        return {
          ...state,
          partnershipId: action?.payload?.partnershipId,
        };

      case types.selectMultipleRows:
        return {
          ...state,
          selected: uniqueArrayByProperty([...state.selected, ...action.payload.items]),
        };

      case types.selectSingle:
        return {
          ...state,
          selected: [...state.selected, action.payload.item],
        };

      case types.sortItems:
        return {
          ...state,
          sort: action.payload.sortParam,
        };

      case types.updateFilters:
        return {
          ...state,
          filters: action.payload.filters,
        };

      default:
        // make an immutable duplicate of the selected array
        if (lockSelectionMap[action.type]) {
          return {
            ...state,
            lockedSelection: [...state.selected],
          };
        }

        return state;
    }
  };
};

/**
 * Helper method to create redux open actions for the modal reducer
 * @param {ReduxState} state
 * @param {ReduxAction} action
 * @returns {Object}
 */
export const getModalReducerOpenAction = (state, action) => ({
  ...state,
  ...(action.payload || {}),
  open: true,
});

/**
 * Boilerplate for creating modal reducers
 * @param {ReduxType} closeModalType
 * @param {ReduxType} openModalType
 * @param {Object?} [initialState={open:false}]
 * @returns {ReduxReducer}
 */
export const createModalReducer =
  (closeModalType, openModalType, initialState) =>
  (state = initialState, action) => {
    const { type: actionType } = action;

    if (actionType === closeModalType) {
      return initialState;
    }

    if (actionType === openModalType) {
      return getModalReducerOpenAction(state, action);
    }

    return state;
  };

const getRelationships = (resources, resourceId, ...resourceRelationships) => {
  // adding child relationships to normalized redux resource
  // see https://redux.js.org/docs/recipes/reducers/NormalizingStateShape.html
  const relationshipObject = {};
  const resource = resources[resourceId];

  resourceRelationships.forEach((resourceRelationship) => {
    if (resource.relationships && resource.relationships[resourceRelationship]) {
      const resourceRelationshipData = resource.relationships[resourceRelationship].data;

      let resourceRelationshipIds;

      if (Array.isArray(resourceRelationshipData)) {
        resourceRelationshipIds = resourceRelationshipData.map((relObj) => relObj.id);
      } else {
        resourceRelationshipIds = resourceRelationshipData ? resourceRelationshipData.id : null;
      }

      relationshipObject[resourceRelationship] = resourceRelationshipIds;
    }
  });

  return relationshipObject;
};

/**
 * Boiler plate reduction helper to get all reducer results
 * @param {Object} state - current state
 * @param {Object} payload - action payload
 * @param {Boolean} replaceState - an optional boolean whether to replace the state or add to it
 * @param {Function} keyExtractor - an optional function to extract the key from the object
 * @returns {*[]|Array<*>}
 */
export const getAllIdsReducerResult = (state, payload, replaceState = false, keyExtractor = allKeys) => {
  const newKeys = keyExtractor(payload);

  if (replaceState) {
    return newKeys;
  }

  return uniqueArray([...state, ...newKeys]);
};

export const getAllResources = (resources) => {
  if (!resources) {
    return [];
  }

  return Object.keys(resources);
};

export const getResourcesById = (resources, ...relationships) => {
  const resourceList = {};

  if (!resources) {
    return resourceList;
  }

  Object.keys(resources).forEach((resourceId) => {
    const rels = getRelationships(resources, resourceId, ...relationships);
    resourceList[resourceId] = {
      id: resources[resourceId].id,
      ...resources[resourceId].attributes,
      ...rels,
    };
  });

  return resourceList;
};

/**
 * createAllIdsReducer
 * Creates boilerplate allIds reducer
 * @param {OptionsArg} options
 * @param {string} options.key - the payload key to use
 * @param {string[]} options.types - reducer cases to listen to
 * @returns {ReduxReducer}
 */
export const createAllIdsReducer =
  ({ key, replaceState, types }) =>
  (state = [], action = {}) => {
    if (types.includes(action.type)) {
      if (replaceState) {
        return Array.from(new Set(allKeys(action.payload[key])));
      }

      return Array.from(new Set([...state, ...allKeys(action.payload[key])]));
    }
    return state;
  };

/**
 * createByIdReducer
 * Creates boilerplate byId reducer
 * @param {OptionsArg} options
 * @param {*} [options.initialState=[]]
 * @param {string} options.key - the payload key to use
 * @param {string[]} [options.relationships]
 * @param {boolean} [options.replaceState]
 * @param {string[]} options.types
 * @returns {ReduxReducer}
 */
export const createByIdReducer =
  ({ initialState = [], key, relationships, replaceState = false, types }) =>
  (state = initialState, action = {}) => {
    if (types.includes(action.type)) {
      const objects = getObjectsByIdWithRelationships(action.payload[key], relationships);
      return replaceState ? objects : deepMergeWithArrayReplacement(state, objects);
    }
    return state;
  };

/**
 * When we're validating email addresses, we store the warning information in the same place each time. This
 * reducer helper reduces boilerplate.
 * @param {ReduxType[]} successCases
 * @param {ReduxType[]} failureCases
 * @returns {ReduxReducer}
 */
export const createEmailAsyncValidateFormReducer = (successCases, failureCases) => {
  const successMap = createTypeMap(successCases);
  const requestMap = createTypeMap(failureCases);

  return (state = undefined, action) => {
    const { type: actionType } = action;

    if (!state?.values?.meta) {
      return state;
    }

    if (requestMap[actionType]) {
      return getNextStateForEmailOnValidateFailed(state, action);
    }

    if (successMap[actionType]) {
      return getNextStateForEmailOnValidateSuccess(state);
    }

    return state;
  };
};

/**
 * Helper which spreads all of the state, dispatch, and component props (which is generally what we want) and also
 * takes a customizer function which does any sort of derivation.
 * @param {function} customization
 * @returns {import('react-redux').MergeProps} mergeProps function
 */
export const createMergeProps =
  (customization) =>
  (stateProps = {}, dispatchProps = {}, ownProps = {}) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    ...customization(stateProps, dispatchProps, ownProps),
  });

export default getRelationships;

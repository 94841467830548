import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { InvoiceGeneratorTotalsTax } from '../invoiceGenerator';

/**
 * Renders the invoice generator totals panel field for calculated tax.
 * @param {ComponentProps} props
 * @param {string} props.name
 * @returns {StatelessComponent}
 */
const TotalsPanelTax = ({ formValues, name, ...rest }) => (
  <Field {...rest} component={InvoiceGeneratorTotalsTax} formValues={formValues} name={name} />
);

TotalsPanelTax.propTypes = {
  formValues: PropTypes.shape({
    meta: PropTypes.shape({}),
  }).isRequired,
  name: PropTypes.string.isRequired,
};

export default TotalsPanelTax;

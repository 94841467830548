import { createIsFetchingReducer } from 'store/redux';

import { FETCH_INTEGRATIONS_FAILURE, FETCH_INTEGRATIONS_REQUEST, FETCH_INTEGRATIONS_SUCCESS } from 'types/integrations';

export const finishCases = [FETCH_INTEGRATIONS_FAILURE, FETCH_INTEGRATIONS_SUCCESS];
export const requestCases = [FETCH_INTEGRATIONS_REQUEST];

const fetchReducer = createIsFetchingReducer(finishCases, requestCases);

export default fetchReducer;

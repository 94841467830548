import getRelationships from 'store/redux';

import { FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_SUCCESS } from 'types/clearingAccounts';

const getById = (fundingAccounts) => {
  if (!fundingAccounts) {
    return {};
  }

  const integrationLedgerClassList = {};

  Object.keys(fundingAccounts).forEach((fundingAccountId) => {
    const relationships = getRelationships(fundingAccounts, fundingAccountId, 'bank');

    integrationLedgerClassList[fundingAccountId] = {
      id: fundingAccounts[fundingAccountId].id,
      ...fundingAccounts[fundingAccountId].attributes,
      ...relationships,
    };
  });

  return integrationLedgerClassList;
};

const byIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_SUCCESS:
      return getById(action.payload.fundingAccount);

    default:
      return state;
  }
};

export default byIdReducer;

import React from 'react';
import { BaseHint, Image, Text } from 'components';
import { typography } from 'constants/styles';
import { Intent } from 'constants/ui';
import { getStaticResourceFullPath } from 'helpers/assets';
import { convertToLowerCase, listAllElementsInArray } from 'helpers/stringHelpers';
import { lengthOf } from 'helpers/utility';
const LedgerSettingsLimitVendorCountriesHint = ({ companyIntegrationSettings, currencies, ledger, onRefresh, }) => {
    const image = getStaticResourceFullPath(`/images/_global/integrations/${convertToLowerCase(ledger.application)}.svg`);
    const currenciesText = listAllElementsInArray(currencies);
    const currenciesLength = lengthOf(currencies);
    return (React.createElement(BaseHint, { hideIcon: true, intent: Intent.INFO, title: React.createElement("div", { className: "display--flex align-items--center" },
            React.createElement(Image, { alt: ledger.name, className: "margin-right--m", src: image, style: {
                    height: 16,
                    width: 16,
                } }),
            React.createElement(Text.Bold, { color: typography.TextColor.BLUE_DARK, lineHeight: typography.TextLineHeight.EXTRA_LARGE, size: typography.TextSize.LEVEL_200 }, companyIntegrationSettings?.enableBaseCurrencyMode ? (`This vendor will be associated with ${ledger.baseCurrencyCode} in your ${ledger.name} account.`) : (React.createElement(React.Fragment, null,
                currenciesLength === 0 && (React.createElement(React.Fragment, null,
                    `${ledger.name} limits this vendor's country. `,
                    React.createElement("button", { className: "link-button font-weight--bold", onClick: () => onRefresh(ledger.id), type: "button" }, "Please re-sync"),
                    ` to ensure you have the latest data.`)),
                currenciesLength > 0 &&
                    currenciesLength <= 10 &&
                    `Your settings in ${ledger.name} limit this vendor to countries associated with ${currenciesText}.`,
                currenciesLength > 10 &&
                    `Your settings in ${ledger.name} may limit this vendor's available currencies.`)))) }));
};
export default LedgerSettingsLimitVendorCountriesHint;

import {} from '@routable/framework';
import _find from 'lodash/find';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LINE_ITEM_EMAIL_DOCUMENT, LineItemStyles } from 'constants/lineItems';
import { ATTACHMENT_DEFAULT_FILENAME, AttachmentStatuses } from 'helpers/ocr/constants';
import { deCamelCaseObjectKeys } from 'helpers/utility';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import {} from 'interfaces/attachment';
import {} from 'interfaces/redux/annotations';
import { LINE_ITEMS_BLOCKS, LINE_ITEMS_SUBSECTIONS, } from 'modules/dashboard/createItems/invoiceGenerator/components/InvoiceGeneratorView/constants';
import { currentCompanySettingsSelector } from 'selectors/currentCompanySelectors';
import { itemSelector, annotationsByIdsSelector } from 'selectors/itemsSelectors';
import { ocrInboxEnabledInFeatureFlagsAndCompanySettingsSelector } from 'selectors/ocrInboxEnabledSelectors';
import { storeAccessor as store } from 'store/accessor';
export const isOcrEnabled = () => ocrInboxEnabledInFeatureFlagsAndCompanySettingsSelector(store.getState());
export const useIsOcrEnabled = () => {
    const location = useLocation();
    const isInboxBillView = location?.pathname?.includes('create_bill/new') && location?.search?.includes('id=');
    const currentCompanySettings = useSelector(currentCompanySettingsSelector);
    const isEnabled = useSelector(ocrInboxEnabledInFeatureFlagsAndCompanySettingsSelector);
    const isFetchingOcrEnabled = !currentCompanySettings;
    return { isFetchingOcrEnabled, isInboxBillView, isOcrEnabled: isEnabled };
};
const annotationFields = ['amount', 'dateDue', 'id', 'status', 'partnership', 'partnerships', 'lineItems'];
export const hasAnyAnnotationsToProcess = (annotations, id) => {
    const annotationItem = _find(annotations, {
        relatedItem: { id },
    });
    return !!annotationItem?.lineItems?.length;
};
export const processAnnotations = (options) => {
    const { annotations, id } = options;
    const annotationItem = _find(annotations, {
        relatedItem: { id },
    });
    if (!annotationItem) {
        return {};
    }
    return annotationFields.reduce((fields, annotation) => Object.assign(fields, { [annotation]: annotationItem[annotation] }), {});
};
export const useAnnotations = (itemId) => {
    const { isOcrEnabled: isEnabled, isInboxBillView } = useIsOcrEnabled();
    const billViewItem = useSelectorWithProps(itemSelector, itemId);
    const annotations = useSelector(annotationsByIdsSelector);
    const hasAnnotations = hasAnyAnnotationsToProcess(annotations, billViewItem?.primaryAttachment);
    const annotation = useMemo(() => {
        if (isEnabled && billViewItem?.primaryAttachment) {
            return {
                ...processAnnotations({
                    annotations,
                    ...{
                        id: billViewItem?.primaryAttachment,
                        kind: billViewItem?.kind,
                    },
                }),
                linkedAttachment: billViewItem?.files?.find((file) => file.id === billViewItem?.primaryAttachment),
                item: billViewItem,
                ocr_filled: false,
            };
        }
        return null;
    }, [annotations, billViewItem, isEnabled]);
    return {
        annotationData: annotation,
        hasAnnotations: isEnabled && hasAnnotations,
        isOcrEnabled: isEnabled,
        isInboxBillView,
    };
};
export const areAllAnnotationAttachmentsProcessed = (annotationFiles) => {
    if (!annotationFiles?.length) {
        return false;
    }
    const attachmentsWithoutEmail = annotationFiles.filter((attachment) => {
        if (attachment?.filename === ATTACHMENT_DEFAULT_FILENAME || attachment?.filename === LINE_ITEM_EMAIL_DOCUMENT) {
            return false;
        }
        return true;
    });
    return attachmentsWithoutEmail.every((file) => {
        const fileStatus = file?.status?.toLowerCase() || file?.latestAnnotationStatus?.toLowerCase();
        const isAttachmentProcessed = !!fileStatus && fileStatus !== AttachmentStatuses.SCANNING && fileStatus !== AttachmentStatuses.UPLOADED;
        return isAttachmentProcessed;
    });
};
export const getInitialAnnotationLineItems = (defaultLineItems, annotationLineItems) => {
    const lineItems = { ...defaultLineItems };
    if (annotationLineItems?.length) {
        const deCamelizedAnnotationLineItems = annotationLineItems.map(deCamelCaseObjectKeys);
        const accountLineItems = deCamelizedAnnotationLineItems
            .filter((lineItem) => lineItem.style === LineItemStyles.ACCOUNT)
            .map((lineItem) => ({
            ...defaultLineItems?.[LINE_ITEMS_BLOCKS.ACCOUNT]?.[LINE_ITEMS_SUBSECTIONS.ACCOUNT]?.[0],
            ...lineItem,
            is_ocr: true,
        }));
        const itemLineItems = deCamelizedAnnotationLineItems
            .filter((lineItem) => lineItem.style === LineItemStyles.ITEM)
            .map((lineItem) => ({
            ...defaultLineItems?.[LINE_ITEMS_BLOCKS.ITEM]?.[LINE_ITEMS_SUBSECTIONS.ITEM]?.[0],
            ...lineItem,
            is_ocr: true,
        }));
        if (accountLineItems.length > 0) {
            lineItems[LINE_ITEMS_BLOCKS.ACCOUNT] = {
                ...lineItems[LINE_ITEMS_BLOCKS.ACCOUNT],
                [LINE_ITEMS_SUBSECTIONS.ACCOUNT]: accountLineItems,
            };
        }
        if (itemLineItems.length > 0) {
            lineItems[LINE_ITEMS_BLOCKS.ITEM] = {
                ...lineItems[LINE_ITEMS_BLOCKS.ITEM],
                [LINE_ITEMS_SUBSECTIONS.ITEM]: itemLineItems,
            };
        }
    }
    return lineItems;
};

import React, { forwardRef, memo } from 'react';
import { IconWrapper } from '../../components/IconWrapper';
import Spinner from '../../components/Spinner';
import { StyleAButton, StyleBButton, getIconSizeForButton, IconButton, SegmentedControlButton } from './Button.styled';
import {} from './Button.types';
const ButtonIcon = memo(({ edge, icon, size, spacing, }) => {
    if (!icon)
        return null;
    const relativeSpace = typeof spacing === 'number' ? spacing : size;
    const edgeMargin = -Math.round(relativeSpace * 0.25);
    const innerMargin = Math.round(size * 0.7);
    const marginLeft = edge === 'start' ? edgeMargin : innerMargin;
    const marginRight = edge === 'end' ? edgeMargin : innerMargin;
    return React.createElement(IconWrapper, { icon: icon, marginLeft: marginLeft, marginRight: marginRight, size: size });
});
const sizes = {
    small: {
        height: 24,
    },
    default: {
        height: 32,
    },
    large: {
        height: 40,
    },
};
export const Button = forwardRef((props, ref) => {
    const { children, variant, disabled, iconAfter, iconBefore, size = 'default', isActive = false, isLoading } = props;
    const { height } = sizes[size];
    const iconSize = getIconSizeForButton(height);
    switch (variant) {
        case 'style-a':
        default:
            return (React.createElement(StyleAButton, { "data-active": isActive || undefined, disabled: disabled || isLoading, ref: ref, size: size },
                isLoading && React.createElement(Spinner, null),
                React.createElement(ButtonIcon, { edge: "start", icon: iconBefore, size: iconSize, spacing: 1 }),
                children,
                React.createElement(ButtonIcon, { edge: "end", icon: iconAfter, size: iconSize, spacing: 1 })));
        case 'style-b':
            return (React.createElement(StyleBButton, { "data-active": isActive || undefined, disabled: disabled || isLoading, size: size },
                isLoading && React.createElement(Spinner, null),
                React.createElement(ButtonIcon, { edge: "start", icon: iconBefore, size: iconSize, spacing: 1 }),
                children,
                React.createElement(ButtonIcon, { edge: "end", icon: iconAfter, size: iconSize, spacing: 1 })));
        case 'icon-button':
            return (React.createElement(IconButton, { "data-active": isActive || undefined, disabled: disabled || isLoading, ref: ref, size: size },
                React.createElement(ButtonIcon, { edge: "none", icon: children, size: iconSize, spacing: 0 })));
        case 'segmented-control-button':
            return (React.createElement(SegmentedControlButton, { "data-active": isActive || undefined, disabled: disabled || isLoading, ref: ref, size: size },
                isLoading && React.createElement(Spinner, null),
                React.createElement(ButtonIcon, { edge: "start", icon: iconBefore, size: iconSize, spacing: 1 }),
                children,
                React.createElement(ButtonIcon, { edge: "end", icon: iconAfter, size: iconSize, spacing: 1 })));
    }
});

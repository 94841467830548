import { fetchItemsRoutine } from 'actions/routines/item';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import { FETCH_ITEM_SUCCESS } from 'types/item';

const annotationLineItemsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case fetchItemsRoutine.SUCCESS:
    case FETCH_ITEM_SUCCESS:
      return deepMergeWithArrayReplacement(
        state,
        getObjectsByIdWithRelationships(action.payload.annotationLineItem, 'latest_ocr_annotation'),
      );
    default:
      return state;
  }
};

export default annotationLineItemsByIdReducer;

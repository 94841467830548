import React from 'react';
import { ButtonV2, IconNames, InputField } from 'components';
import { formStyles, sizes } from 'constants/styles';
import { ButtonSize } from '../../../constants/button';
import { Intent } from '../../../constants/ui';
import SignUpFormFooter from '../components/SignUpFormFooter';
import SignUpFormHeader from '../components/SignUpFormHeader';
import { sectionHeaderClassname, pClassname } from '../constants/relevantClassnames';
import {} from './VerifyEmail.types';
const VerifyEmail = ({ errors, formState, isSubmitting, onFormSubmit, onInputChange }) => (React.createElement("div", { className: "submodule-container" },
    React.createElement("div", { className: "form-container" },
        React.createElement(SignUpFormHeader, { hasSignInOption: false }),
        React.createElement("form", { className: "form", id: "signup-verify-email-form" },
            React.createElement("h2", null, "Please check your email"),
            React.createElement("p", { className: sectionHeaderClassname }, "Add your verification code"),
            React.createElement("p", { className: pClassname }, "We have sent you a six-digit confirmation code to your e-mail. It will expire in 30 minutes, so enter your code soon."),
            React.createElement("div", { className: "form-control margin-top--m" },
                React.createElement(InputField, { className: formStyles.field.xl.full, dataTestId: "code", errors: errors, maxLength: "6", name: "code", onChange: onInputChange, placeholder: "Verification code", setIsRequired: true, type: "text", value: formState.code })),
            React.createElement("div", { className: "form-control remove-margin-bottom", id: "button-control" },
                React.createElement(ButtonV2, { className: "display--table-cell", dataTestId: "continue--button", intent: Intent.PRIMARY, isDisabled: !formState.code, isLoading: isSubmitting, onClick: (e) => {
                        if (e) {
                            e.preventDefault();
                        }
                        onFormSubmit();
                    }, rightIconClassName: "margin-left--m", rightIconName: IconNames.ARROW_RIGHT, rightIconSize: sizes.iconSizes.SMALL, size: ButtonSize.MEDIUM, type: "submit" }, "Continue"))),
        React.createElement(SignUpFormFooter, null))));
export default VerifyEmail;

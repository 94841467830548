import PropTypes from 'prop-types';
import React from 'react';

const TableColumn = ({ baseClassName, className, children, contentClassName }) => (
  <div className={`${baseClassName} ${className}`}>
    <div className={contentClassName}>{children}</div>
  </div>
);

TableColumn.propTypes = {
  baseClassName: PropTypes.string,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
};

TableColumn.defaultProps = {
  baseClassName: 'table-row--column',
  className: '',
  contentClassName: 'column-content',
};

export default TableColumn;

import { queryContext } from '@routable/shared';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchSingleItemSuccess } from 'actions/item';
import { itemsService } from './items.service';
export const useItemQuery = ({ id }) => {
    const dispatch = useDispatch();
    const results = useQuery({
        context: queryContext,
        enabled: Boolean(id),
        queryFn: () => itemsService.get({ id }),
        queryKey: ['item', 'by-id', id],
    });
    useEffect(() => {
        if (results.data) {
            dispatch(fetchSingleItemSuccess(results.data));
        }
    }, [dispatch, results.data]);
    return results;
};

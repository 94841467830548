import React from 'react';
export const Caption = ({ children, type = 'body' }) => {
    switch (type) {
        case 'modal-header':
            return React.createElement("span", { style: { font: 'var(--font-h3)' } }, children);
        case 'list-header':
            return React.createElement("span", { style: { font: 'var(--font-h5)' } }, children);
        case 'body':
            return (React.createElement("span", { style: {
                    font: 'var(--font-span)',
                    fontSize: 'var(--font-size-2)',
                } }, children));
        default:
            return React.createElement("span", { style: { font: 'var(--font-span)' } }, children);
    }
};

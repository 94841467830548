import React from 'react';
import { cva } from 'class-variance-authority';
import { FavoriteFilled, CheckmarkFilled, StarFilled, WarningFilled, RuleFilled, Error } from '@carbon/icons-react';
export const statusMapping = {
    active: {
        className: 'text-black bg-white',
        children: 'Active',
        icon: React.createElement(CheckmarkFilled, null),
    },
    primary: {
        className: 'text-green-70 bg-green-0',
        children: 'Primary',
        icon: React.createElement(StarFilled, null),
    },
    preferred: {
        className: 'magenta-50 bg-magenta-0',
        children: 'Preferred',
        icon: React.createElement(FavoriteFilled, null),
    },
    pending: {
        className: 'bg-gold-5 text-gold-70',
        children: 'Pending verification',
        icon: React.createElement(RuleFilled, null),
    },
    verify: {
        className: 'bg-gold-5 text-gold-70',
        children: 'Verify this account',
        icon: React.createElement(RuleFilled, null),
    },
    failed: {
        className: 'bg-red-0 text-red-70',
        children: 'Failed',
        icon: React.createElement(WarningFilled, null),
    },
    disabled: {
        className: 'bg-grey-5 text-grey-50',
        children: 'Disabled',
        icon: React.createElement(Error, null),
    },
};
const status = cva([
    'w-full',
    'flex',
    'font-semibold',
    'py-4',
    'gap-2',
    'justify-center',
    '@xs:px-5',
    '@xs:gap-3',
    '@xs:justify-end',
    'md:px-5',
    'md:gap-3',
    'md:justify-end',
]);
export const Status = ({ type, statusText }) => (React.createElement("div", { className: "@container" },
    React.createElement("div", { className: status(statusMapping[type]) },
        statusMapping[type].icon,
        statusText || statusMapping[type].children)));

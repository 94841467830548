import { createSlice, createSelector } from '@reduxjs/toolkit';
import {} from 'interfaces/redux';
import { idSelector } from 'selectors/globalSelectors';
const initialState = {};
const reduxShiftSelect = createSlice({
    name: 'reduxShiftSelect',
    initialState,
    reducers: {
        setLastClicked(state, action) {
            if (state[action.payload.formField]) {
                state[action.payload.formField].lastClickedId = action.payload.fieldId;
            }
        },
        setIdOrderList(state, action) {
            state[action.payload.formField] = {
                lastClickedId: null,
                allIds: action.payload.allIds.map((id) => `${action.payload.formField}.${id}`),
            };
        },
    },
});
const getShiftSelectorWatcher = (reduxState) => reduxState.reduxShiftSelect;
export const getShiftSelectData = createSelector([getShiftSelectorWatcher, idSelector], (state, id) => state[id] || { lastClickedId: undefined, allIds: [] });
export const { name, actions: { setIdOrderList, setLastClicked }, reducer, } = reduxShiftSelect;

import React from 'react';
import FileItem from './FileItem';
import { File } from './FileItem/FileItem';
import { FileListContainer } from './FileList.styles';
import {} from './FilesList.types';
const FileList = ({ files, remove, useFormField = true, isDisabled }) => {
    if (files.length === 0) {
        return null;
    }
    return (React.createElement(FileListContainer, null, files.map((file, index) => {
        const key = `file-${index}`;
        return useFormField ? (React.createElement(FileItem, { file: file, index: index, isDisabled: isDisabled, key: key, remove: remove })) : (React.createElement(File, { index: index, input: { value: file }, isDisabled: isDisabled, key: key, remove: remove }));
    })));
};
export default FileList;

import { combineReducers } from 'redux';

import allLedgerUnmatchedPartnershipContactsReducer from './allLedgerUnmatchedPartnershipContactsReducer';
import errorReducer from './errorReducer';
import fetchReducer from './fetchReducer';
import lastUpdatedReducer from './lastUpdatedReducer';
import ledgerUnmatchedPartnershipContactsByIdReducer from './ledgerUnmatchedPartnershipContactsByIdReducer';

const ledgerUnmatchedPartnershipContactsReducer = combineReducers({
  allIds: allLedgerUnmatchedPartnershipContactsReducer,
  byId: ledgerUnmatchedPartnershipContactsByIdReducer,
  errors: errorReducer,
  isFetching: fetchReducer,
  lastUpdated: lastUpdatedReducer,
});

export default ledgerUnmatchedPartnershipContactsReducer;

import React from 'react';
import { ContactAutofillIcons } from 'complexComponents/ContactAutofillIcons';
import { FlexRow } from 'components';
import { isContactsInteractionListViewTypeThread } from 'helpers/ui';
import { useContactsInteractionListViewContext } from 'hooks';
import { ContactListItemMenu, ContactListItemTitle } from './components';
import {} from './ContactListItem.types';
const ContactListItem = (props) => {
    const { contact } = props;
    const view = useContactsInteractionListViewContext();
    return (React.createElement(FlexRow, { className: "justify-content--space-between", stackOnMobile: true },
        React.createElement(ContactListItemTitle, { contact: contact }),
        React.createElement(FlexRow, { className: "align-items--center justify-content--flex-end", stackOnMobile: false },
            !isContactsInteractionListViewTypeThread(view) && React.createElement(ContactAutofillIcons, { partnershipMember: contact }),
            React.createElement(ContactListItemMenu, { ...props }))));
};
export default ContactListItem;

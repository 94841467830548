import React from 'react';

import { Box, BoxBody, BrandNav, ButtonV2, IconNames } from 'components';

import { ButtonSize } from 'constants/button';
import { CONTACT_SUPPORT_LABEL } from 'constants/labels';
import { sizes } from 'constants/styles';

import { CustomerServiceHelper } from 'modules/customerSupport/CustomerService';

const UnsupportedBrowser = () => {
  const onContactSupport = () => {
    CustomerServiceHelper.show();
  };

  return (
    <div className="branded--container">
      <BrandNav />

      <div className="branded--contents">
        <Box title="Unsupported browser detected">
          <BoxBody>
            <p className="font-regular semibold font-color--greyXDark margin-bottom--sm">Old browser detected</p>
            <p className="font-regular regular font-color--greyXDark">
              The browser you are using is not supported. For best results, please try using the latest version of
              Chrome, Firefox, Edge or Safari.
            </p>

            <div className="margin-top--large">
              <ButtonV2
                onClick={onContactSupport}
                rightIconName={IconNames.ARROW_RIGHT}
                rightIconProps={{ style: { marginLeft: 'auto' } }}
                rightIconSize={sizes.iconSizes.LARGE}
                size={ButtonSize.LARGE}
              >
                {CONTACT_SUPPORT_LABEL}
              </ButtonV2>
            </div>
          </BoxBody>
        </Box>
      </div>
    </div>
  );
};

export default UnsupportedBrowser;

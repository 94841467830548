import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';

import { sharedFormSubmitRoutinePayloadCreators } from 'helpers/routine';

import { FETCH_SINGLE_LEDGER_PARTNERSHIPS, SUBMIT_INTEGRATION_CONNECT_AUTH } from 'types/integrations';

/**
 * @type {ReduxSagaRoutine}
 */
export const fetchSingleIntegrationLedgerPartnershipsRoutine = createRoutine(FETCH_SINGLE_LEDGER_PARTNERSHIPS);

/**
 * @type {ReduxSagaRoutine}
 */
export const integrationConnectAuthRoutine = createRoutine(
  SUBMIT_INTEGRATION_CONNECT_AUTH,
  sharedFormSubmitRoutinePayloadCreators,
);

/**
 * @type {BoundRoutineHandler}
 */
export const integrationConnectAuthHandler = bindRoutineToReduxForm(integrationConnectAuthRoutine);

import PropTypes from 'prop-types';
import React from 'react';

import { useDebouncedField } from 'hooks';

import Textarea from './Textarea';

/**
 * A wrapper around our textarea field to debounce value updates.
 * @param {ComponentProps} props
 * @param {number|undefined} props.debounceDelay
 * @param {Object|undefined} props.formState
 * @param {ReduxFormInput|undefined} props.input
 * @param {boolean|undefined} props.isDebounced
 * @param {string|undefined} props.name
 * @param {Function|undefined} props.onInputChange
 * @return {FunctionComponent}
 */
const DebouncedTextarea = (props) => {
  const { debounceDelay, input, isDebounced, name, onInputChange, value: valueProp, ...rest } = props;

  const inputInterface = input || {
    onChange: onInputChange,
    value: valueProp,
  };

  const { onChange, value } = useDebouncedField({
    debounceDelay,
    isDebounced,
    input: inputInterface,
  });

  return (
    <Textarea
      {...rest}
      input={
        input && {
          ...input,
          onChange,
          value,
        }
      }
      name={name}
      onInputChange={onChange}
      value={value}
    />
  );
};

DebouncedTextarea.propTypes = {
  ...Textarea.propTypes,
  debounceDelay: PropTypes.number,
  isDebounced: PropTypes.bool,
};

DebouncedTextarea.defaultProps = {
  ...Textarea.defaultProps,
  debounceDelay: undefined,
  isDebounced: undefined,
};

export default DebouncedTextarea;

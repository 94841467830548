import PropTypes from 'prop-types';
import React from 'react';

import { PopoverSelectButton } from './components';

/**
 * Renders the area of the PopoverSelect that contains the primary action button.
 * @param {string} label
 * @param {boolean} [isDisabled]
 * @param {EventHandlerNonNull} onClick
 * @return {StatelessComponent}
 * @constructor
 */
const PopoverSelectActionContent = ({ label, isDisabled, onClick }) => (
  <div className="mt-3">
    <PopoverSelectButton isDisabled={isDisabled} onClick={onClick}>
      {label}
    </PopoverSelectButton>
  </div>
);

PopoverSelectActionContent.propTypes = {
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

PopoverSelectActionContent.defaultProps = {
  isDisabled: undefined,
};

export default PopoverSelectActionContent;

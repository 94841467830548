import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { IconNames } from 'components/icon';
import { ListItemLabel } from 'components/text';
import { TooltipIcon } from 'components/tooltip';

import { colors, sizes } from 'constants/styles';

import { BlockListItemValueContent } from './components';

/**
 * An item with label and value, to be rendered inside a DetailsList.
 * @param {ComponentProps} props
 * @param {StringMaybe} props.className
 * @param {String} props.label
 * @param {Object} props.labelProps
 * @param {Object} props.style
 * @param {string} props.target
 * @param {string} props.tooltipContent
 * @param {string|string[]} props.value
 * @param {string} props.valueIcon
 * @param {string} props.valueTextContentClassName
 * @return {StatelessComponent}
 */
const BlockListItem = (props) => {
  const {
    dataFullStory,
    className,
    label,
    labelProps,
    style,
    target,
    tooltipContent,
    url,
    value,
    valueIcon,
    valueTextContentClassName,
  } = props;

  return (
    <div
      className={classNames({
        'block-list-item': true,
        [className]: !!className,
      })}
      style={style}
    >
      <ListItemLabel {...labelProps}>
        {label}
        {tooltipContent && (
          <TooltipIcon
            content={tooltipContent}
            contentMargin={sizes.spacing.MEDIUM}
            icon={IconNames.INFO_SIGN}
            iconColor={colors.colorMainJordan}
            iconProps={{
              marginBottom: sizes.spacing.EXTRA_SMALL,
              marginLeft: sizes.spacing.EXTRA_MEDIUM,
              size: sizes.iconSizes.LARGE,
            }}
          />
        )}
      </ListItemLabel>
      <BlockListItemValueContent
        dataFullStory={dataFullStory}
        target={target}
        url={url}
        value={value}
        valueIcon={valueIcon}
        valueTextContentClassName={valueTextContentClassName}
      />
    </div>
  );
};

BlockListItem.propTypes = {
  dataFullStory: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.node,
  labelProps: PropTypes.shape({}),
  style: PropTypes.shape({}),
  target: PropTypes.string,
  tooltipContent: PropTypes.string,
  url: PropTypes.string,
  value: PropTypes.node,
  valueIcon: PropTypes.string,
  valueTextContentClassName: PropTypes.string,
};

BlockListItem.defaultProps = {
  dataFullStory: undefined,
  className: undefined,
  label: undefined,
  labelProps: undefined,
  style: {},
  target: undefined,
  tooltipContent: undefined,
  url: undefined,
  value: undefined,
  valueIcon: undefined,
  valueTextContentClassName: undefined,
};

export default BlockListItem;

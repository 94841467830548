import React from 'react';
import { InvitationCodeInputTooltip } from 'complexComponents';
import { BoxV2, ButtonV2, CheckboxTermsOfService, FormControl, FormFieldLabel, IconNames, InputField, SelectFieldV2, } from 'components';
import { ButtonSize } from 'constants/button';
import { createCompanyFields } from 'constants/formFields';
import { formNamesSignup } from 'constants/forms';
import { membershipTitles } from 'constants/membership';
import { formStyles, sizes } from 'constants/styles';
import {} from './CreateAccountUser.types';
const CreateAccountUser = ({ errors, formState, isSubmitting, onCheckboxInputChange, onFormSubmit, onInputChange, onSelectChange, }) => (React.createElement("div", { className: "branded--contents" },
    React.createElement(BoxV2, { title: "Create your account" },
        React.createElement("form", { className: "form", id: formNamesSignup.CREATE_ACCOUNT_USER },
            React.createElement(FormFieldLabel, null, "Your info"),
            React.createElement(FormControl, null,
                React.createElement(InputField, { className: formStyles.field.xl.left, errors: errors, name: createCompanyFields.USER_FIRST_NAME, onChange: (event) => onInputChange(event, 'form.user'), placeholder: "First name", setIsRequired: true, type: "text", value: formState.user[createCompanyFields.USER_FIRST_NAME] }),
                React.createElement(InputField, { className: formStyles.field.xl.right, errors: errors, name: createCompanyFields.USER_LAST_NAME, onChange: (event) => onInputChange(event, 'form.user'), placeholder: "Last name", setIsRequired: true, type: "text", value: formState.user[createCompanyFields.USER_LAST_NAME] })),
            React.createElement(FormControl, null,
                React.createElement(SelectFieldV2, { errors: errors, input: {
                        name: createCompanyFields.USER_TITLE,
                        onChange: (option) => onSelectChange(createCompanyFields.USER_TITLE, option, 'form'),
                        value: formState[createCompanyFields.USER_TITLE],
                    }, label: "Your title", options: membershipTitles, placeholder: "Select title" })),
            React.createElement(FormControl, null,
                React.createElement(InputField, { className: formStyles.field.xl.full, errors: errors, name: createCompanyFields.USER_EMAIL, onChange: (event) => onInputChange(event, 'form.user'), placeholder: "Company email", setIsRequired: true, type: "email", value: formState.user[createCompanyFields.USER_EMAIL] })),
            React.createElement(FormControl, null,
                React.createElement(InputField, { className: formStyles.field.xl.full, errors: errors, name: createCompanyFields.USER_PASSWORD, onChange: (event) => onInputChange(event, 'form.user'), placeholder: "Password", setIsRequired: true, type: "password", value: formState.user[createCompanyFields.USER_PASSWORD] })),
            React.createElement(FormControl, null,
                React.createElement(InputField, { addon: React.createElement(InvitationCodeInputTooltip, null), className: formStyles.field.xl.full, errors: errors, name: createCompanyFields.SIGNUP_CODE, onChange: (event) => onInputChange(event, 'form.meta'), placeholder: "Invitation code", setIsRequired: true, type: "text", value: formState.meta[createCompanyFields.SIGNUP_CODE] })),
            React.createElement(FormControl, null,
                React.createElement(CheckboxTermsOfService, { errors: errors, formState: formState.meta, onChange: (event, fallback) => onCheckboxInputChange(event, fallback, 'form.meta') })),
            React.createElement(FormControl, { className: "remove-margin-bottom" },
                React.createElement(ButtonV2, { isLoading: isSubmitting, onClick: (e) => {
                        e.preventDefault();
                        onFormSubmit();
                    }, rightIconName: IconNames.ARROW_RIGHT, rightIconProps: { style: { marginLeft: 'auto' } }, rightIconSize: sizes.iconSizes.LARGE, size: ButtonSize.LARGE }, "Create my account"))))));
CreateAccountUser.defaultProps = {
    errors: {},
};
export default CreateAccountUser;

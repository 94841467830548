import PropTypes from 'prop-types';
import React from 'react';

import Icon, { IconNames } from 'components/icon';

import './ConfirmationSectionHeader.scss';

const ConfirmationSectionHeader = ({ text }) => (
  <div className="confirmation-section-header">
    <Icon icon={IconNames.TICK_CIRCLE} marginRight={8} size={24} />
    <h3 className="remove-margin-bottom">{text}</h3>
  </div>
);

ConfirmationSectionHeader.propTypes = {
  text: PropTypes.string,
};

ConfirmationSectionHeader.defaultProps = {
  text: 'Confirmed',
};

export default ConfirmationSectionHeader;

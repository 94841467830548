import { useSelector } from 'react-redux';
import { useSelectorWithProps } from 'hooks';
import { createItemFormUiSelectedCompanyNameSelector } from 'selectors/forms';
import { partnershipSelector } from 'selectors/partnershipsSelectors';
export const usePartnerCompanyName = (partnershipId) => {
    const selectedCompanyName = useSelector(createItemFormUiSelectedCompanyNameSelector);
    const itemPartnership = useSelectorWithProps(partnershipSelector, partnershipId);
    const companyName = selectedCompanyName || itemPartnership?.name;
    return companyName;
};

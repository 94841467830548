import { sidePanelNameAddVendorRegisteredAddressInForm } from 'constants/sidePanels';
import * as types from 'types/sidePanels';
import {} from './addVendorRegisteredAddressForCreateItemReducer.types';
const initialState = {
    open: false,
    form: null,
};
const addVendorRegisteredAddressSideSheetForCreateItemReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SIDE_PANEL_OPEN: {
            return action.payload.name === sidePanelNameAddVendorRegisteredAddressInForm
                ? { ...state, ...action.payload.state, open: true }
                : state;
        }
        case types.SIDE_PANEL_CLOSE: {
            return action.payload.name === sidePanelNameAddVendorRegisteredAddressInForm
                ? { ...state, form: null, open: false }
                : state;
        }
        default:
            return state;
    }
};
export { initialState };
export default addVendorRegisteredAddressSideSheetForCreateItemReducer;

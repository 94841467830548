import React from 'react';

import { EventKeyValues } from 'constants/events';

import { digitsOnly } from 'helpers/fieldNormalizers';
import { isEqual } from 'helpers/utility';

import { handleGoToPage } from '../helpers';

/**
 * Handles the onKeyPress event in the pagination quick jumper.
 * If enter is pressed, calls onGoToPage with the current input.
 * @param {Object} params
 * @param {boolean} params.isDisabled
 * @param {Function} params.onGoToPage
 * @param {number} params.pageCount
 * @param {number} params.pageInput
 * @param {Function} params.setPageInput
 * @return {function(*): void}
 */
export const useQuickJumperKeyPress = ({ isDisabled, onGoToPage, pageCount, pageInput, setPageInput }) =>
  React.useCallback(
    (evt) => {
      if (!isDisabled && isEqual(evt.key, EventKeyValues.ENTER)) {
        evt.preventDefault();
        handleGoToPage({
          onGoToPage,
          pageCount,
          pageInput,
          setPageInput,
        });
      }
    },
    [isDisabled, onGoToPage, pageCount, pageInput, setPageInput],
  );

/**
 * Handles the onChange event in the pagination quick jumper.
 * Strips the input of non-digit characters, parses to an integer,
 * and sets the current value state.
 * @param params
 * @param {Function} params.setPageInput
 * @return {function(*): void}
 */
export const useQuickJumperInputChange = ({ setPageInput }) =>
  React.useCallback(
    (evt) => {
      const enteredPage = parseInt(digitsOnly(evt.target.value), 10);

      if (!Number.isNaN(enteredPage)) {
        setPageInput(enteredPage);
      } else {
        setPageInput('');
      }
    },
    [setPageInput],
  );

/**
 * Handles the input addon onClick event in the pagination quick jumper.
 * Calls onGoToPage with the current input.
 * @param params
 * @param {boolean} params.isDisabled
 * @param {Function} params.onGoToPage
 * @param {number} params.pageCount
 * @param {number} params.pageInput
 * @param {Function} params.setPageInput
 * @return {function(): void}
 */
export const useQuickJumperAddonClick = ({ isDisabled, onGoToPage, pageCount, pageInput, setPageInput }) =>
  React.useCallback(() => {
    if (!isDisabled) {
      handleGoToPage({
        onGoToPage,
        pageCount,
        pageInput,
        setPageInput,
      });
    }
  }, [isDisabled, onGoToPage, pageCount, pageInput, setPageInput]);

import React from 'react';

import Icon, { IconNames } from 'components/icon';

import { colors, sizes } from 'constants/styles';

const CheckListCompleteBullet = () => (
  <span className="check-list--item--bullet-wrapper">
    <Icon
      className="check-list--item--bullet check-list--item--bullet_complete"
      color={colors.colorMainGreenHex}
      icon={IconNames.TICK}
      size={sizes.iconSizes.SMALL}
    />
  </span>
);

export default CheckListCompleteBullet;

import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';

import { fields } from 'constants/partnershipRequestState';

import { createAllIdsReducer } from 'store/redux';

const allIdsReducer = createAllIdsReducer({
  key: fields.FUNDING_ACCOUNT,
  types: [fetchExternalPartnershipRequestRoutine.SUCCESS],
});

export default allIdsReducer;

import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  padding: 0 16px;
  width: 100%;
  background-color: var(--table-header-bg-color);
  border-bottom: 1px solid var(--color-grey10) !important;
  height: 58px;
  justify-content: space-between;
  align-items: center;
`;
export const LeftHeader = styled.div `
  display: flex;
  align-items: center;
  text-align: left;

  // Old action-menu for mobile
  & .action-nav {
    padding: 0px;
    & .dashboard-toggle {
      margin-right: 14px !important;
    }
  }
`;
export const RightHeader = styled.div `
  display: flex;
  align-items: center;
  text-align: right;
  gap: 10px;
`;
export const HeaderCaption = styled.h5 `
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  white-space: nowrap;
`;

import { useQuery } from '@tanstack/react-query';
import { queryContext } from '@routable/shared';
import {} from '../models';
import { itemApprovalsApi } from '../services/item';
export const useItemApprovals = ({ enabled = true, itemId }) => useQuery({
    context: queryContext,
    queryKey: ['ItemApprovals', itemId],
    queryFn: async () => itemApprovalsApi.get(itemId),
    refetchOnMount: 'always',
    select: (responseBody) => responseBody?.data,
    enabled: !!itemId && enabled,
});

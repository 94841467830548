import { createSelector } from 'reselect';

import { getPartnershipPrimaryReceivableFundingAccount } from 'helpers/partnerships';
import { allValues } from 'helpers/utility';

import { idSelector, optionsSelector } from 'selectors/globalSelectors';
import { derivePartnershipIdFromPropsSelector, partnershipIdPropSelector } from 'selectors/propSelectors';

const getState = (state) => state.partnerships;

/**
 * Selects the partnerships by id from the state
 * @function
 * @param {ReduxState} state
 * @return {object}
 */
export const partnershipsByIdSelector = createSelector([getState], (partnerships) => partnerships.byId);

/**
 * Selects all the partnerships from the state
 * @function
 * @param {ReduxState} state
 */
export const allPartnershipsSelector = createSelector([partnershipsByIdSelector], (partnerships) =>
  Object.values(partnerships),
);

/**
 * Selects the isFetching bool for partnerships from the state
 * @function
 * @param {ReduxState} state
 * @return {boolean}
 */
export const isFetchingPartnershipSelector = createSelector([getState], (partnerships) => partnerships.isFetching);

/**
 * Selects the isSubmitting bool for partnerships from the state
 * @function
 * @param {ReduxState} state
 * @return {boolean}
 */
export const isSubmittingPartnershipSelector = createSelector([getState], (partnerships) => partnerships.isSubmitting);

/**
 * Selects the lastFetched date for partnerships from the state
 * @function
 * @param {ReduxState} state
 * @return {Date}
 */
export const lastFetchedPartnershipSelector = createSelector([getState], (partnerships) => partnerships.lastFetched);

/**
 * Selects the lastSubmitted date for partnerships from the state
 * @function
 * @param {ReduxState} state
 * @return {Date}
 */
export const lastSubmittedPartnershipSelector = createSelector(
  [getState],
  (partnerships) => partnerships.lastSubmitted,
);

/**
 * Selects the partnership
 * @function
 * @param {ReduxState} state
 * @param {string} partnershipId - Partnership id
 */
export const partnershipSelector = createSelector(
  [partnershipsByIdSelector, idSelector],
  (partnerships, partnershipId) => partnerships[partnershipId],
);

/**
 * Selects the partnership based on the partnershipId passed to the component. Useful for
 * creating a structured selector.
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @return {Partnership|undefined}
 */
export const partnershipFromPropsSelector = createSelector(
  [partnershipsByIdSelector, partnershipIdPropSelector],
  (partnerships, partnershipId) => partnerships[partnershipId],
);

/**
 * Selects the partnerships actions from the state
 * @function
 * @param {ReduxState} state
 * @return {object}
 */
export const partnershipsActionsSelector = createSelector([getState], (partnerships) => partnerships.actions);

/**
 * Selects the partnership actions belonging to a given partnership id
 */
export const partnershipActionsForPartnershipIdSelector = createSelector(
  [partnershipsActionsSelector, idSelector],
  (partnershipsActions, partnershipId) => partnershipsActions[partnershipId],
);

/**
 * Selects a specific partnership action from the state
 * @function
 * @param {ReduxState} state
 * @param {string} partnershipId
 * @return {*}
 */
export const partnershipActionSelector = createSelector(
  [partnershipsActionsSelector, optionsSelector],
  (partnershipsActions, options) => {
    const [partnershipId, action] = options;

    if (!partnershipsActions[partnershipId]) {
      return null;
    }

    return partnershipsActions[partnershipId][action];
  },
);

/**
 * Selects resendInvite partnership action for partnership id retrieved from the props
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {Object} props
 * @returns {null|"submitted"|"submitting"}
 */
export const partnershipResendInviteActionFromPartnershipPropSelector = createSelector(
  [partnershipsActionsSelector, derivePartnershipIdFromPropsSelector],
  (partnershipsActions, partnershipId) => {
    if (!partnershipsActions[partnershipId]) {
      return null;
    }

    return partnershipsActions[partnershipId].resendInvite;
  },
);

/**
 * Selects the partnership funding accounts
 * @function
 * @param {ReduxState} state
 */
export const partnershipFundingAccountsSelector = createSelector(
  [getState],
  (partnerships) => partnerships.fundingAccounts,
);

/**
 * Selects the partnership funding accounts by id
 * @function
 * @param {ReduxState} state
 */
export const partnershipReceivableFundingAccountsByIdSelector = createSelector(
  [partnershipFundingAccountsSelector],
  (partnershipFundingAccounts) => partnershipFundingAccounts?.byId,
);

/**
 * Selects the partnership funding addresses from the state
 * @function
 * @param {ReduxState} state
 * @return {object}
 */
export const partnershipFundingAddressesSelector = createSelector(
  [partnershipFundingAccountsSelector],
  (fundingAccounts) => fundingAccounts.addresses,
);

/**
 * Selects the partnership funding bank accounts from the state
 * @function
 * @param {ReduxState} state
 * @return {object}
 */
export const partnershipFundingBankAccountsSelector = createSelector(
  [partnershipFundingAccountsSelector],
  (fundingAccounts) => fundingAccounts.banks,
);

/**
 * Selects the partnerships funding is submitting state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const partnershipFundingAccountsIsSubmittingSelector = createSelector(
  [partnershipFundingAccountsSelector],
  (fundingAccounts) => fundingAccounts.isSubmitting,
);

/**
 * Selects the errors for partnership addresses from the state
 * @function
 * @param {ReduxState} state
 * @return {object}
 */
export const partnershipAddressesErrorsSelector = createSelector(
  [partnershipFundingAddressesSelector],
  (addresses) => addresses.errors,
);

/**
 * Selects the lastSubmitted date for partnerships addresses from the state
 * @function
 * @param {ReduxState} state
 * @return {boolean}
 */
export const isSubmittingPartnershipAddressesSelector = createSelector(
  [partnershipFundingAddressesSelector],
  (addresses) => addresses.isSubmitting,
);
/**
 * Selects the lastSubmitted date for partnerships addresses from the state
 * @function
 * @param {ReduxState} state
 * @return {Date}
 */
export const lastSubmittedPartnershipAddressesSelector = createSelector(
  [partnershipFundingAddressesSelector],
  (addresses) => addresses.lastSubmitted,
);

/**
 * Selects the errors for partnership funding accounts from the state
 * @function
 * @param {ReduxState} state
 * @return {object}
 */
export const partnershipFundingAccountsErrorsSelector = createSelector(
  [partnershipFundingBankAccountsSelector],
  (banks) => banks.errors,
);

/**
 * Selects the isSubmitting bool for partnerships fundingAccounts from the state
 * @function
 * @param {ReduxState} state
 * @return {boolean}
 */
export const isSubmittingPartnershipFundingAccountsSelector = createSelector(
  [partnershipFundingBankAccountsSelector],
  (banks) => banks.isSubmitting,
);

/**
 * Selects the lastSubmitted date for partnerships fundingAccounts from the state
 * @function
 * @param {ReduxState} state
 * @return {Date}
 */
export const lastSubmittedPartnershipFundingAccountsSelector = createSelector(
  [partnershipFundingBankAccountsSelector],
  (banks) => banks.lastSubmitted,
);

/**
 * Selects the partnerships fundingAccounts byId object from state
 * @function
 * @param {Object} state - Redux state
 * @return {Object}
 */
export const partnershipsFundingAccountsByIdSelector = createSelector(
  [partnershipFundingAccountsSelector],
  (fundingAccounts) => fundingAccounts.byId,
);

/**
 * Selectes all partnerships funding accounts
 * @type {StandardSelector}
 * @param {ReduxState}
 * @returns {PartnershipFundingAccount[]}
 */
export const partnershipFundingAccountsAllValuesSelector = createSelector(
  [partnershipsFundingAccountsByIdSelector],
  (fundingAccountById) => allValues(fundingAccountById),
);

/**
 * Selects the partnerships fundingAccounts byId object from state
 * @function
 * @param {Object} state - Redux state
 * @return {Object}
 */
export const partnershipsFundingAccountForIdSelector = createSelector(
  [partnershipsFundingAccountsByIdSelector, idSelector],
  (fundingAccounts, id) => fundingAccounts[id],
);

/**
 * Selects the partnership receivable funding accounts
 * @function
 * @param {ReduxState} state
 */
export const allPartnershipReceivableFundingAccountsSelector = createSelector(
  [partnershipReceivableFundingAccountsByIdSelector],
  (partnershipFundingAccounts) => Object.values(partnershipFundingAccounts),
);

/**
 * Selects all funding accounts for a given partnership
 * @param {Object} state - Redux state
 * @param {string} partnershipId
 * @return {Object[]} Array of funding accounts
 */
export const partnershipFundingAccountsForPartnershipIdSelector = createSelector(
  [partnershipReceivableFundingAccountsByIdSelector, idSelector],
  (fundingAccounts, partnershipId) =>
    Object.keys(fundingAccounts).reduce((arr, id) => {
      const fundingAccount = fundingAccounts[id];

      if (fundingAccount.partnership === partnershipId) {
        return [...arr, fundingAccount];
      }

      return arr;
    }, []),
);

/**
 * Selects the partnership primary receivable funding account or returns undefined.
 * @function
 * @param {ReduxState} state
 * @param {Partnership.id} partnershipId
 * @returns {FundingAccount|undefined}
 */
export const partnershipPrimaryReceivableFundingAccountSelector = createSelector(
  [allPartnershipReceivableFundingAccountsSelector, idSelector],
  getPartnershipPrimaryReceivableFundingAccount,
);

/**
 * Selects either is submitting from partnership funding or addresses
 */
export const isSubmittingPartnershipFundingAccountOrAddressSelector = createSelector(
  [isSubmittingPartnershipAddressesSelector, isSubmittingPartnershipFundingAccountsSelector],
  (addresses, fundingAccounts) => !!(addresses || fundingAccounts),
);

/**
 * Selects either last submitted from partnership funding or addresses
 */
export const lastSubmittedPartnershipFundingAccountOrAddressSelector = createSelector(
  [lastSubmittedPartnershipAddressesSelector, lastSubmittedPartnershipFundingAccountsSelector],
  (addresses, fundingAccounts) => addresses || fundingAccounts,
);

import { TransactionStatus } from 'constants/transactions';
import { GenericDisplayStatus } from 'constants/ui';

/**
 * Transform the transaction status into a generic display status for status dot.
 * @param {TransactionStatus} transactionStatus
 * @return {GenericDisplayStatus}
 */
export const transactionStatusToGenericStatus = (transactionStatus) => {
  switch (transactionStatus) {
    case TransactionStatus.CANCELED:
      return GenericDisplayStatus.POST_DANGER;

    case TransactionStatus.FAILED:
    case TransactionStatus.ISSUE:
      return GenericDisplayStatus.NOT_STARTED;

    case TransactionStatus.INITIATED:
      return GenericDisplayStatus.STARTED;

    case TransactionStatus.PROCESSED:
      return GenericDisplayStatus.COMPLETE;

    default:
      return GenericDisplayStatus.POST_STARTED;
  }
};

import { Tooltip } from '@routable/gross-ds';
import React from 'react';

import Icon, { IconNames } from 'components/icon';

import { colors, sizes } from 'constants/styles';

const ApprovalRequiredTooltipIcon = () => (
  <Tooltip className="!w-auto" data-testid="approval-required-tooltip" tooltip="Requires approval">
    <Icon color={colors.colorMainJordan} icon={IconNames.TAKE_ACTION} size={sizes.iconSizes.XX_LARGE} />
  </Tooltip>
);

export default ApprovalRequiredTooltipIcon;

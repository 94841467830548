import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import { initializeTableStore } from '@routable/components';
import { Loader, RoutableIntervals } from '@routable/framework';
import { GrossDsTheme, ToastPortal } from '@routable/gross-ds';
import { QueryProvider } from '@routable/shared';
import * as Sentry from '@sentry/react';
import React from 'react';
import { render } from 'react-dom';
import { AppContainer as HotIfDevContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import { TABLE_LAST_USED_PURPOSE_CODES } from 'constants/routableDatabaseNames';

import { watchResize } from 'helpers/bodyWidth';

import AppContainer from 'modules/app/App';
import history from 'modules/app/history';
import { Auth0ProviderWithHistory } from 'modules/auth/Auth0ProviderWithHistory';

import configureStore from 'store/configureStore';

import 'helpers/window.pollyfills';
import 'config/dayjs';
import './global/css/main.scss';

// Setup the @routable/framework web workers via the loader
// we also setup the base indexedDb tables and indexes
initializeTableStore();

Loader({
  database: {
    dbName: 'routable',
    version: 24,
    tables: [
      {
        name: 'routableTableConfig',
        indexes: [
          { indexName: 'tableId', unique: true },
          { indexName: 'lastUpdate', unique: false },
        ],
      },
      {
        name: 'routableTableSelection',
        indexes: [
          { indexName: 'tableId', unique: true },
          { indexName: 'lastUpdate', unique: false },
        ],
      },
      {
        name: 'routableTableCache',
        indexes: [
          { indexName: 'tableId', unique: true },
          { indexName: 'lastUpdate', unique: false },
        ],
      },
      {
        name: 'routableTableManualCache',
        indexes: [
          { indexName: 'tableId', unique: true },
          { indexName: 'lastUpdate', unique: false },
        ],
      },
      {
        name: 'routableTableColumns',
        indexes: [{ indexName: 'tableId', unique: true }],
      },
      {
        name: 'routableSelectCache',
        indexes: [{ indexName: 'selectId', unique: true }],
      },
      {
        name: TABLE_LAST_USED_PURPOSE_CODES,
        indexes: [{ indexName: 'partnershipId' }, { indexName: 'currency' }],
      },
    ],
  },
});

const store = configureStore();
const getAppRoot = () => document.getElementById('root');

// Setup auto-logout intervals.
RoutableIntervals.ForceLogout.countdownInterval(1000, 300);
RoutableIntervals.AutoLogout.countdownInterval(1000, 300);

const renderApp = (Component) =>
  render(
    <HotIfDevContainer>
      <GrossDsTheme>
        <Sentry.ErrorBoundary>
          <QueryProvider>
            <Provider store={store}>
              <Router history={history}>
                <Auth0ProviderWithHistory>
                  <LastLocationProvider>
                    <Route component={Component} />
                  </LastLocationProvider>
                </Auth0ProviderWithHistory>
              </Router>
            </Provider>
          </QueryProvider>
        </Sentry.ErrorBoundary>
        <ToastPortal />
      </GrossDsTheme>
    </HotIfDevContainer>,
    getAppRoot(),
  );

renderApp(AppContainer);

// watchResize is init here so we can have a global width detection event (DOES NOT EFFECT RENDERING/PAINTING)
// the method watches for resize events (passive) and updates global width variable
// because our current table requires that the width be set in pixels this
// allows us to resize tables and/or set hard limits based on a pixel instead of percentage.
watchResize();

// does nothing in production (never runs)
if (module.hot) {
  module.hot.accept('./modules/app/App', () => {
    // eslint-disable-next-line global-require
    const NextApp = require('./modules/app/App').default;
    renderApp(NextApp);
  });
}

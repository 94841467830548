import { useQueryPagination, useSearchParams } from '@routable/framework';
import { queryContext } from '@routable/shared';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { generatePartnershipsQueryKeys } from '../../helpers';
import {} from '../../models';
import { partnershipsService } from '../../services';
export const usePartnershipsList = (partnershipType) => {
    const [{ search }, __, setSearch] = useSearchParams({ search: '' }, `${partnershipType}Search`);
    const [pagination, _, setPagination] = useSearchParams({ page: 1, pageSize: 25 }, `${partnershipType}Pagination`);
    const result = useQuery({
        context: queryContext,
        queryKey: generatePartnershipsQueryKeys.list(partnershipType, { pagination, search }),
        queryFn: async () => partnershipsService.list({ partnershipType, search, pagination }),
        select: (data) => {
            if (!data) {
                return null;
            }
            const mappedData = data.data.map((partnership) => {
                const { id, attributes, relationships } = partnership;
                const company = data.included
                    .filter((entity) => entity.type === 'Company')
                    .find((entity) => entity.id === relationships.partner?.data?.id);
                const mailingAddress = data.included
                    .filter((entity) => entity.type === 'Address')
                    .find((entity) => entity.id === company?.relationships.mailingAddress?.data?.id);
                return {
                    id,
                    ...attributes,
                    company: company ? { id: company.id, ...company.attributes } : null,
                    mailingAddress: mailingAddress ? { id: mailingAddress.id, ...mailingAddress.attributes } : null,
                };
            });
            return {
                ...data,
                data: mappedData,
            };
        },
    });
    const handleSearch = useCallback((searchQuery) => {
        setSearch({ search: searchQuery }, { removeExisting: false });
    }, [setSearch]);
    const paginationData = useQueryPagination({
        page: pagination.page,
        pageSize: pagination.pageSize,
        setPagination: (p) => setPagination(p, { removeExisting: false }),
        totalRecords: result.data?.meta?.pagination.count,
    });
    return {
        ...result,
        ...paginationData,
        data: result.data?.data,
        onSearch: handleSearch,
        search,
    };
};

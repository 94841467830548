import { getFeatureFlagsRoutine } from 'actions/routines/featureFlags';
import {} from 'interfaces/actions';
import { initialState } from './initialState';
const dashboardLoadedFeatureFlagsReducer = (state = initialState.featureFlags, action) => {
    switch (action.type) {
        case getFeatureFlagsRoutine.SUCCESS:
            return true;
        default:
            return state;
    }
};
export default dashboardLoadedFeatureFlagsReducer;

import PropTypes from 'prop-types';
import React from 'react';

import { reduxFormInputPropType } from 'components/commonProps';

import { isEqual } from 'helpers/utility';

import InvoiceGeneratorTotalsRow from '../InvoiceGeneratorTotalsRow';

const InvoiceGeneratorTotalsDiscount = ({ input, meta, ...rest }) => {
  const { value } = input;

  if (isEqual(value, 0)) {
    return null;
  }

  return <InvoiceGeneratorTotalsRow {...rest} amount={parseFloat(value)} meta={meta} />;
};

InvoiceGeneratorTotalsDiscount.propTypes = {
  input: reduxFormInputPropType.isRequired,
  meta: PropTypes.shape().isRequired,
  text: PropTypes.string.isRequired,
};

export default InvoiceGeneratorTotalsDiscount;

import { createIsFetchingReducer } from 'store/redux';

import {
  FETCH_NOTIFICATION_SETTINGS_FAILURE,
  FETCH_NOTIFICATION_SETTINGS_REQUEST,
  FETCH_NOTIFICATION_SETTINGS_SUCCESS,
} from 'types/notifications';

export const finishCases = [FETCH_NOTIFICATION_SETTINGS_FAILURE, FETCH_NOTIFICATION_SETTINGS_SUCCESS];
export const requestCases = [FETCH_NOTIFICATION_SETTINGS_REQUEST];

const fetchReducer = createIsFetchingReducer(finishCases, [FETCH_NOTIFICATION_SETTINGS_REQUEST]);

export default fetchReducer;

import { fetchItemsRoutine } from 'actions/routines/item';

import { allKeys } from 'helpers/utility';

import { FETCH_ITEM_SUCCESS } from 'types/item';

const allAnnotationsReducer = (state = [], action) => {
  switch (action.type) {
    case fetchItemsRoutine.SUCCESS:
    case FETCH_ITEM_SUCCESS:
      return allKeys(action.payload.annotation);
    default:
      return state;
  }
};

export default allAnnotationsReducer;

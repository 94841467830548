import { createIsFetchingReducer } from 'store/redux';

import {
  FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_FAILURE,
  FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_REQUEST,
  FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_SUCCESS,
} from 'types/integrations';

export const finishCases = [
  FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_FAILURE,
  FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_SUCCESS,
];
export const requestCases = [FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_REQUEST];

const fetchReducer = createIsFetchingReducer(finishCases, requestCases);

export default fetchReducer;

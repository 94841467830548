import { hasNumbersValidator, hasUpperLettersValidator, hasLowerLettersValidator } from 'helpers/fieldValidation';
import { availableRequirementValidators } from '../constants/availableRequirementValidators';
import { MINIMUM_LENGTH } from '../constants/passwordRequirementParameters';
import { hasMinimumLengthValidator } from './validators/lengthValidator';
export const passwordRequirements = {
    [availableRequirementValidators.HAS_UPPER_LETTER]: {
        label: '1 upper case letter',
        validator: hasUpperLettersValidator,
    },
    [availableRequirementValidators.HAS_LOWER_LETTER]: {
        label: '1 lower case letter',
        validator: hasLowerLettersValidator,
    },
    [availableRequirementValidators.HAS_NUMBERS]: {
        label: '1 number',
        validator: hasNumbersValidator,
    },
    [availableRequirementValidators.HAS_MINIMUM_LENGTH]: {
        label: `At least ${MINIMUM_LENGTH} characters`,
        validator: hasMinimumLengthValidator,
    },
};

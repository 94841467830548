import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import NavigationPrompt from 'react-router-navigation-prompt';
import { isDirty, isSubmitting } from 'redux-form';

import confirmAlert from 'helpers/confirmAlert';

const withWarnAboutUnsavedChanges = (WrappedComponent, formName, options = {}) => {
  const { alertTitle, alertText } = options;

  const WarnAboutUnsavedChanges = (props) => {
    const { isFormDirty, isFormSubmitting, onOpenAlert, shouldPromptOnSubmit } = props;

    // if the form isn't currently submitting, we'll always use the
    // isFormDirty flag to check whether to prompt before navigating.
    // BUT if the form is currently submitting, and we're attempting
    // to re-route the user anyway, it should be considered part of
    // the form's submit behavior, and we shouldn't interfere (there's
    // an escape hatch for this: pass shouldPromptOnSubmit={true}).
    const isNotSubmittingOrPromptAnyway = Boolean(!isFormSubmitting || shouldPromptOnSubmit);

    // shouldPrompt checks that we're doing the correct thing re: submitting,
    // that we haven't prompted the user for this previously, and that some
    // form data was actually changed.
    const shouldPrompt = Boolean(isNotSubmittingOrPromptAnyway && isFormDirty);

    const togglePrompt = async (onConfirm) => {
      const shouldRedirect = await onOpenAlert(alertText, alertTitle);

      if (shouldRedirect) {
        onConfirm();
      }
    };

    const handlePrompt = (onConfirm) => {
      togglePrompt(onConfirm);
      return null;
    };

    return (
      <Fragment>
        <WrappedComponent {...props} />
        <NavigationPrompt key={shouldPrompt} when={shouldPrompt}>
          {({ onConfirm }) => {
            handlePrompt(onConfirm);
          }}
        </NavigationPrompt>
      </Fragment>
    );
  };

  WarnAboutUnsavedChanges.propTypes = {
    isFormDirty: PropTypes.bool,
    isFormSubmitting: PropTypes.bool,
    onOpenAlert: PropTypes.func,
    shouldPromptOnSubmit: PropTypes.bool,
  };

  WarnAboutUnsavedChanges.defaultProps = {
    isFormDirty: undefined,
    isFormSubmitting: undefined,
    onOpenAlert: confirmAlert, // given as prop for testability
    shouldPromptOnSubmit: undefined,
  };

  const mapStateToProps = (state, ownProps) => ({
    isFormDirty: ownProps.isFormDirty ?? isDirty(formName)(state),
    isFormSubmitting: isSubmitting(formName)(state),
  });

  return connect(mapStateToProps)(WarnAboutUnsavedChanges);
};

export default withWarnAboutUnsavedChanges;

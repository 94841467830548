import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { BlockListItemValueTextContent } from './components';

/**
 * Renders content for BlockListItem's value prop.
 * @param {ComponentProps} props
 * @param {StringMaybe} props.url
 * @param {string|undefined} props.target
 * @param {string|string[]} props.value
 * @param {Object} props.rest
 * @return {StatelessComponent}
 * @constructor
 */
const BlockListItemValueContent = ({ url, target, value, ...rest }) => {
  const valueTextContent = <BlockListItemValueTextContent {...rest} url={url} value={value} />;

  const wrapperElement = url ? Link : React.Fragment;
  const wrapperProps = url ? { className: 'font-color--primary', to: url, target } : {};

  return React.createElement(wrapperElement, wrapperProps, valueTextContent);
};

BlockListItemValueContent.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  value: PropTypes.node,
};

BlockListItemValueContent.defaultProps = {
  className: undefined,
  url: undefined,
  value: undefined,
};

export default BlockListItemValueContent;

import colors from 'global/css/base/color.scss';

export const colorBlackHex = colors.black;
export const colorBlackNeutralHex = colors.blackNeutral;
export const colorBlueBoldHex = colors.blueBold;
export const colorDarkJordanHex = colors.darkJordan;
export const colorDarkGreenHex = colors.darkGreen;
export const colorDarkSilverHex = colors.darkSilver;
export const colorDarkYellowHex = colors.darkYellow;
export const colorBlueLightHex = colors.blueLight;
export const colorBlueXLight = colors.blueExtraLight;
export const colorLoftHex = colors.loft;
export const colorAquaBoldHex = colors.aquaBold;
export const colorBluePrimaryHex = colors.bluePrimary;
export const colorGreenLight = colors.greenLight;
export const colorMainGreenHex = colors.mainGreen;
export const colorGreenDark = colors.greenDark;
export const colorBlueExtraLight = colors.blueExtraLight;
export const colorBlueExtraExtraLight = colors.blueExtraExtraLight;
export const colorMainJordan = colors.mainJordan;
export const colorMainJordanHex = colors.mainJordan;
export const colorMainPurpleHex = colors.mainPurple;
export const colorRed05 = colors.red05;
export const colorRedBoldHex = colors.redBold;
export const colorRedLightHex = colors.redLight;
export const colorRedDarkHex = colors.redDark;
export const colorRedMediumHex = colors.redMedium;
export const colorMainSilverHex = colors.mainSilver;
export const colorBlueDarkHex = colors.blueDark;
export const colorBlueXDarkHex = colors.blueXDark;
export const colorGreenBoldHex = colors.greenBold;
export const colorGreyXLightHex = colors.greyXLight;
export const colorGreyDarkHex = colors.greyDark;
export const colorGreyMedHex = colors.greyMed;
export const colorGreyXDarkHex = colors.greyXDark;
export const colorGreyXXDarkHex = colors.greyXXDark;
export const colorGreyMed = colors.greyMed;
export const colorPurpleBoldHex = colors.purpleBold;
export const colorPurpleDark = colors.purpleDark;
export const colorSteelHex = colors.steel;
export const colorTransparent = colors.transparent;
export const colorWhiteHex = colors.white;
export const colorWhite90Alpha = colors.white90;
export const colorXeroHex = colors.xero;
export const colorYellowBoldHex = colors.yellowBold;
export const colorYellowLightHex = colors.yellowLight;
export const colorYellowMediumHex = colors.yellowMedium;
export const colorYellowDark = colors.yellowDark;

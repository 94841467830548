import React, { createContext, useContext, useState } from 'react';
import {} from '../../purchaseOrders/useBillDiscrepancies/discrepancies.models';
export const DiscrepancyContext = createContext(undefined);
export const DiscrepancyProvider = ({ children }) => {
    const [discrepancies, setDiscrepancies] = useState(null);
    const [lineItemIdsInLoading, setIdsInLoading] = useState([]);
    const getDiscrepancyByLineItemId = (billLineItemId) => {
        return discrepancies?.find((discrepancy) => discrepancy.bill_line_item_id === billLineItemId);
    };
    const hasDiscrepancy = (billLineItemId) => {
        return Boolean(discrepancies?.some((discrepancy) => discrepancy.bill_line_item_id === billLineItemId));
    };
    const hasDiscrepancyForColumn = (billLineItemId, column) => {
        const discrepancy = getDiscrepancyByLineItemId(billLineItemId);
        return Boolean(discrepancy?.[column]);
    };
    return (React.createElement(DiscrepancyContext.Provider, { value: {
            discrepancies,
            getDiscrepancyByLineItemId,
            hasDiscrepancy,
            hasDiscrepancyForColumn,
            lineItemIdsInLoading,
            setDiscrepancies,
            setIdsInLoading,
        } }, children));
};
export const useDiscrepancyContext = () => {
    const discrepancyContext = useContext(DiscrepancyContext);
    if (!discrepancyContext) {
        throw new Error('useDiscrepancyContext must be used within a DiscrepancyProvider');
    }
    return discrepancyContext;
};

import { resetPasswordRoutine } from 'actions/routines/auth';

import { AuthActionType } from 'enums/auth';

import { gatherReducerCasesFromRoutines } from 'helpers/routine';

import { createLastSubmittedReducer } from 'store/redux';

export const { failureCases, successCases, requestCases } = gatherReducerCasesFromRoutines([resetPasswordRoutine]);

export const requestOrFailureCases = [...failureCases, ...requestCases, AuthActionType.CLEAR_RESET_PASSWORD_STATE];

export default createLastSubmittedReducer(successCases, requestOrFailureCases);

/**
 * @module selectors/propsSelectors
 */

import { createSelector } from 'reselect';

import { valueOrDefault } from 'helpers/utility';

import { createPropSelector } from './globalSelectors';

/**
 * Returns props.company
 * @type {StandardSelector}
 */
export const companyPropSelector = createPropSelector('company');

/**
 * Returns props.form
 * @type {StandardSelector}
 */
export const formPropSelector = createPropSelector('form');

/**
 * Returns props.id
 * @type {StandardSelector}
 * @returns {Item}
 */
export const idPropSelector = createPropSelector('id');

/**
 * Returns props.row
 * @type {StandardSelector}
 * @returns {Object}
 */
export const rowPropSelector = createPropSelector('row');

/**
 * Returns props.item
 * @type {StandardSelector}
 * @returns {Item}
 */
export const itemPropSelector = createPropSelector('item');

/**
 * Returns props.rowData
 * @type {StandardSelector}
 * @returns {Object}
 */
export const rowDataPropSelector = createPropSelector('rowData');

/**
 * Returns props.query
 * @type {StandardSelector}
 * @returns {string}
 */
export const queryPropSelector = createPropSelector('query');

/**
 * Returns props.item.partner
 * @type {StandardSelector}
 * @returns {Item.partner}
 */
export const itemPartnerPropSelector = createSelector([itemPropSelector], (item) => item?.partner);

/**
 * Returns props.item.partnership
 * @function
 * @type {StandardSelector}
 * @returns {Item.partnership}
 */
export const itemPartnershipPropSelector = createSelector([itemPropSelector], (item) => item?.partnership);

/**
 * Returns props.row.original.partnership
 * @type {StandardSelector}
 * @returns {Item.partnership}
 */
export const itemPartnershipRowPropSelector = createSelector([rowPropSelector], (row) => row?.original?.partnership);

/**
 * Returns props.partnershipFundingAccount
 * @type {StandardSelector}
 */
export const partnershipFundingAccountPropSelector = createPropSelector('partnershipFundingAccount');

/**
 * Returns props.partnership
 * @type {StandardSelector}
 */
export const partnershipPropSelector = createPropSelector('partnership');

/**
 * Returns props.partnershipId
 * @type {StandardSelector}
 */
export const partnershipIdPropSelector = createPropSelector('partnershipId');

/**
 * Selects partnershipId or partnership.id from ownProps.
 * @function
 * @param {ReduxState}
 * @param {ComponentProps}
 * @returns {Partnership.id|undefined}
 */
export const derivePartnershipIdFromPropsSelector = createSelector(
  [partnershipPropSelector, partnershipIdPropSelector],
  (partnership, partnershipId) => valueOrDefault(partnershipId, partnership?.id),
);

/**
 * Returns props.isUpdatePaymentMethodFlow
 * @type {StandardSelector}
 */
export const isUpdatePaymentMethodFlowPropSelector = createPropSelector('isUpdatePaymentMethodFlow');

/**
 * Returns props.rowData.partnership
 * @function
 * @type {StandardSelector}
 * @returns {Id|undefined}
 */
export const rowDataPartnershipPropSelector = createSelector([rowDataPropSelector], (rowData) => rowData?.partnership);

/**
 * Returns props.rowData.partner
 * @type {StandardSelector}
 * @returns {Id|undefined}
 */
export const rowDataPartnerPropSelector = createSelector([rowDataPropSelector], (rowData) => rowData?.partner);

import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { submitTermsOfServiceRoutine } from 'actions/routines/external';

import { formNamesExternal } from 'constants/forms';

import TermsOfServiceHint from './TermsOfServiceHint';

export const mapDispatchToProps = {
  onTosSubmit: submitTermsOfServiceRoutine,
};

const createTermsOfServiceForm = reduxForm({
  initialValues: {
    company: {
      meta: {
        tosAgree: true,
      },
    },
  },
  form: formNamesExternal.TERM_OF_SERVICE,
})(TermsOfServiceHint);

export default connect(null, mapDispatchToProps)(createTermsOfServiceForm);

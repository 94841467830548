import React from 'react';

import BaseText from './BaseText';

const makeTextComponent = (weight) => {
  const TextComponent = ({ children, ...rest }) => (
    <BaseText {...rest} weight={weight}>
      {children}
    </BaseText>
  );

  TextComponent.propTypes = BaseText.propTypes;
  TextComponent.defaultProps = BaseText.defaultProps;

  return TextComponent;
};

export default makeTextComponent;

import { MemberAccessProps } from 'data/resources/member';

/**
 * Transforms metadata for a partnership member before submitting to the API.
 * @param {PartnershipMember} partnershipMember - Form values
 * @return {Object}
 */
export const partnershipMemberMeta = (partnershipMember) => {
  if (partnershipMember.meta?.knownPartnershipIds) {
    return {
      knownPartnershipIds: partnershipMember.meta.knownPartnershipIds,
    };
  }

  return undefined;
};

/**
 * Transforms data for a new partnership member before submitting to the API.
 * @param {PartnershipMember} partnershipMember - Form values
 * @return {Object}
 */
export const partnershipMemberForCreate = (partnershipMember) => ({
  [MemberAccessProps.defaultGeneral]: partnershipMember[MemberAccessProps.defaultGeneral],
  [MemberAccessProps.defaultItem]: partnershipMember[MemberAccessProps.defaultItem],
  email: partnershipMember.email,
  firstName: partnershipMember.firstName,
  lastName: partnershipMember.lastName,
  meta: partnershipMemberMeta(partnershipMember),
  phoneNumber: partnershipMember.phoneNumber?.number ? partnershipMember.phoneNumber : null,
});

/**
 * Transforms data for an existing partnership member before submitting to the API.
 * @param {PartnershipMember} partnershipMember - Form values
 * @param {Id} partnershipMemberId
 * @return {Object}
 */
export const partnershipMemberForUpdate = (partnershipMember, partnershipMemberId) => ({
  ...partnershipMemberForCreate(partnershipMember),
  id: partnershipMemberId,
  isArchived: partnershipMember.isArchived,
});

/**
 * Transforms data for a partnership member before submitting a send-invite request to the API.
 * @param {PartnershipMember} partnershipMember - Form values
 * @return {Object}
 */
export const partnershipMemberForSendInvite = (partnershipMember) => ({
  [MemberAccessProps.notificationGeneral]: partnershipMember[MemberAccessProps.defaultGeneral],
  id: partnershipMember.id,
});

/**
 * Transforms data for a partnership member before submitting a send-update-payment-method link request to the API.
 * @param {PartnershipMember} partnershipMember - Form values
 * @return {Object}
 */
export const partnershipMemberForSendUpdatePaymentLink = (partnershipMember) => ({
  [MemberAccessProps.notificationGeneral]: partnershipMember[MemberAccessProps.notificationGeneral],
  id: partnershipMember.id,
});

export var CompanySearchPartnerTypes;
(function (CompanySearchPartnerTypes) {
    CompanySearchPartnerTypes["EXISTING"] = "existing";
    CompanySearchPartnerTypes["NEW"] = "new";
})(CompanySearchPartnerTypes || (CompanySearchPartnerTypes = {}));
export var CompanySettingsPaymentsType;
(function (CompanySettingsPaymentsType) {
    CompanySettingsPaymentsType["DOMESTIC"] = "domestic";
    CompanySettingsPaymentsType["INTERNATIONAL"] = "international";
})(CompanySettingsPaymentsType || (CompanySettingsPaymentsType = {}));

import { combineReducers } from 'redux';

import allLedgerPartnershipsReducer from './allLedgerPartnershipsReducer';
import errorReducer from './errorReducer';
import fetchReducer from './fetchReducer';
import lastFetchedReducer from './lastFetchedReducer';
import ledgerPartnershipsByIdReducer from './ledgerPartnershipsByIdReducer';
import paginationReducer from './paginationReducer';
import submitReducer from './submitReducer';

const ledgerPartnershipsReducer = combineReducers({
  allIds: allLedgerPartnershipsReducer,
  byId: ledgerPartnershipsByIdReducer,
  errors: errorReducer,
  isFetching: fetchReducer,
  lastFetched: lastFetchedReducer,
  isSubmitting: submitReducer,
  pagination: paginationReducer,
});

export default ledgerPartnershipsReducer;

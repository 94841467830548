import _some from 'lodash/some';

import { BalanceAccountFormats } from 'constants/currency';

import { isAmountNegative } from 'helpers/amounts';
import { formatCurrencyUSD } from 'helpers/currencyFormatter';

/**
 * Reduce accumulator for getFundingAccountsWithSourceAllowedByPaymentDeliveryOption
 * (function below). Formats the funding accounts that are allowed.
 * @param {Object[]} accumulated
 * @param {FundingAccount} fundingAccount
 * @param {Object} options
 * @param {string[]} options.allowedSubClasses
 * @param {number} options.balanceAmount
 * @param {ByIdCollection} options.fundingSources
 * @param {Function} options.isFundingSourceProviderSubClassBalance
 * @return {Object[]}
 */
export const deliveryOptionFundingAccountsAccumulator = (accumulated, fundingAccount, options = {}) => {
  const { allowedSubClasses, balanceAmount, fundingSources, isFundingSourceProviderSubClassBalance } = options;

  const { fundingSources: fundingSourceIds } = fundingAccount;

  let isBalanceAccount = false;

  const subClassIsAllowed = _some(fundingSourceIds, (fundingSourceId) => {
    const fundingSource = fundingSources[fundingSourceId];
    isBalanceAccount = isFundingSourceProviderSubClassBalance(fundingSource);
    return allowedSubClasses.includes(fundingSource.providerSubClass);
  });

  if (!subClassIsAllowed) {
    return accumulated;
  }

  if (isBalanceAccount) {
    const amountStr = formatCurrencyUSD(balanceAmount, {
      format: BalanceAccountFormats,
    });
    const availableString = isAmountNegative(balanceAmount) ? amountStr : `(${amountStr} available)`;
    const formattedAccount = {
      ...fundingAccount,
      name: `Balance ${availableString}`,
    };

    return [...accumulated, formattedAccount];
  }

  return [...accumulated, fundingAccount];
};

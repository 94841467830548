import { createErrorReducer } from 'store/redux';

import {
  CREATE_API_TOKEN_FAILURE,
  DELETE_API_TOKEN_FAILURE,
  FETCH_API_TOKENS_FAILURE,
  REGENERATE_API_TOKEN_FAILURE,
  CREATE_API_TOKEN_REQUEST,
  CREATE_API_TOKEN_SUCCESS,
  DELETE_API_TOKEN_REQUEST,
  DELETE_API_TOKEN_SUCCESS,
  FETCH_API_TOKENS_REQUEST,
  FETCH_API_TOKENS_SUCCESS,
  REGENERATE_API_TOKEN_REQUEST,
  REGENERATE_API_TOKEN_SUCCESS,
} from 'types/apiToken';

export const failureCases = [
  CREATE_API_TOKEN_FAILURE,
  DELETE_API_TOKEN_FAILURE,
  FETCH_API_TOKENS_FAILURE,
  REGENERATE_API_TOKEN_FAILURE,
];

export const successCases = [
  CREATE_API_TOKEN_REQUEST,
  CREATE_API_TOKEN_SUCCESS,
  DELETE_API_TOKEN_REQUEST,
  DELETE_API_TOKEN_SUCCESS,
  FETCH_API_TOKENS_REQUEST,
  FETCH_API_TOKENS_SUCCESS,
  REGENERATE_API_TOKEN_REQUEST,
  REGENERATE_API_TOKEN_SUCCESS,
];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

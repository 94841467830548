// This file controls pollyfills for older browsers.
// this file will have more methods as we implement performance upgrades.

// crypto.randomUUID is not supported in all browsers.
if (!('randomUUID' in crypto)) {
  crypto.randomUUID = function randomUUID() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))) // eslint-disable-line no-bitwise, no-mixed-operators
        .toString(16),
    );
  };
}

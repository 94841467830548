import React from 'react';
import { FormFieldLabel, FormFieldDescription } from 'components/form';
import { LinkNewPage } from 'components/link';
import { HorizontalRadios } from 'components/radio';
import { WhiteSpace } from 'components/text';
import { SWIFT_CHARGE_OPTIONS_MESSAGES, SWIFT_CHARGE_OPTIONS_TOOLTIPS } from 'constants/paymentMethods';
import { SwiftChargeOptions } from 'enums/paymentMethods';
import helpDocs from 'helpers/helpDocs';
import useSwiftChargeOptionsCost from 'hooks/useSwiftChargeOptionsCost';
const SwiftFeeChargeToggle = ({ name }) => {
    const { our: ourCost, sha: shaCost } = useSwiftChargeOptionsCost();
    return (React.createElement(React.Fragment, null,
        React.createElement(FormFieldLabel, { className: "remove-margin-bottom" }, "How will the SWIFT fee be paid?"),
        React.createElement(FormFieldDescription, { className: "enable-pointer-events" },
            "Your choice could change the amount that your vendor receives.",
            React.createElement(WhiteSpace, null),
            React.createElement(LinkNewPage, { className: "primary", href: helpDocs.UNDERSTANDING_ROUTABLE_FEES }, "Learn more about SWIFT fees \u2197")),
        React.createElement(HorizontalRadios, { leftOptions: {
                id: `swift-fee-charge-toggle-${SwiftChargeOptions.OUR}`,
                tooltipProps: {
                    title: SWIFT_CHARGE_OPTIONS_TOOLTIPS.our(ourCost),
                },
                optionText: SWIFT_CHARGE_OPTIONS_MESSAGES.our,
                value: SwiftChargeOptions.OUR,
            }, name: name, rightOptions: {
                id: `swift-fee-charge-toggle-${SwiftChargeOptions.SHA}`,
                tooltipProps: {
                    title: SWIFT_CHARGE_OPTIONS_TOOLTIPS.sha(shaCost),
                },
                optionText: SWIFT_CHARGE_OPTIONS_MESSAGES.sha,
                value: SwiftChargeOptions.SHA,
            } })));
};
export default SwiftFeeChargeToggle;

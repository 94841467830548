import _cloneDeepWith from 'lodash/cloneDeepWith';

/**
 * Special object flattening method for error counting
 * @param obj
 */
export const flattenObjectForErrors = (obj = {}) => {
  const returnObj = {};

  Object.keys(obj).forEach((i) => {
    if (Array.isArray(obj[i]) && typeof obj[i][0] === 'string') {
      returnObj[i] = obj[i];
    } else if (typeof obj[i] === 'object') {
      const flatObject = flattenObjectForErrors(obj[i]);
      Object.keys(flatObject).forEach((j) => {
        returnObj[`${i}.${j}`] = flatObject[j];
      });
    } else {
      returnObj[i] = obj[i];
    }
  });

  return returnObj;
};

/**
 * Helper function for checking if given object has given property
 * @param {Object} obj
 * @param {String} prop
 * return {Boolean}
 */
export const hasProperty = (obj = {}, prop = '') => Object.prototype.hasOwnProperty.call(obj, prop);

/**
 * `Warning`: Recursive function -
 * Consider depth of object and performance impacts before using.
 *
 * This function clones original object and finds all instances of the `targetKey` and changes it's value
 * to `newValue`.
 * @param {Object} originalObject
 * @param {String} targetKey
 * @param {Any} newValue
 * @return {Object} clonedObj
 */
export const setValuesForAllKeysInObjectDeep = (originalObject, targetKey, newValue = null) => {
  const customizer = (val, key) => {
    if (key === targetKey) {
      return newValue;
    }
    return undefined;
  };

  return _cloneDeepWith(originalObject, customizer);
};

/**
 * @fileOverview Defines helper functions related to bulk actions.
 * @module helpers/bulkActions
 */

import _difference from 'lodash/difference';

import {
  BulkActionApiTypeToTypeMap,
  BulkActionTypesApiMap,
  BulkActionTypesPassiveText,
  BulkActionTypesPassiveTextForFutureScheduledDate,
  BulkActionTypesPresentTenseText,
  BulkActionTypesPresentTenseTextForFutureScheduledDate,
  BulkImportErrorCodes,
  BulkImportItemsStep,
  bulkImportItemsStepsOrdered,
} from 'constants/bulkActions';

import { BulkActionSummarySteps, BulkActionTypes } from 'enums/bulkActions';

import { isCurrentMembershipApproverOnItem } from 'helpers/approvals';
import { isDateToday } from 'helpers/date';
import { ItemApprovals } from 'helpers/itemApprovals';
import {
  hasPartnerContacts,
  isItemLocked,
  isItemPaymentDeliveryMethodUnset,
  isItemStatusBulkUploadComplete,
  isItemStatusNeedsApproval,
  isItemStatusNextReadyToSend,
  isItemStatusReadyToSend,
  isItemStatusScheduled,
} from 'helpers/items';
import { plusFloat } from 'helpers/math';
import { isIncluded, isValueEmpty } from 'helpers/utility';

/**
 * Get bulk action name with regards to the schedule date
 * @param {BulkActionTypes} bulkAction
 * @param {boolean} isFutureDate
 * @param {boolean} [isPastTense]
 * @returns {string}
 */
export const getActionNameByActionAndActionDate = (bulkAction, isFutureDate, isPastTense) => {
  const futureDateText = isPastTense
    ? BulkActionTypesPassiveTextForFutureScheduledDate[bulkAction]
    : BulkActionTypesPresentTenseTextForFutureScheduledDate[bulkAction];

  const passiveTenseText = isPastTense
    ? BulkActionTypesPassiveText[bulkAction]
    : BulkActionTypesPresentTenseText[bulkAction];

  return isFutureDate ? futureDateText : passiveTenseText;
};

/**
 * Returns bulk action from lock state string
 * @param {string} lockState
 * @return {BulkActionTypes}
 */
export const getBulkActionFromItemLockState = (lockState) => {
  // lockState format is: 'bulkAction|someUUID|item_approve'
  const bulkAction = lockState.split('|')[2];

  return BulkActionApiTypeToTypeMap[bulkAction];
};

/**
 * Returns the future bulk action's item.lockState with incorrect uuid
 * @param {BulkActionTypes} bulkAction
 * @return {string}
 */
export const getBulkActionFutureItemLockState = (bulkAction) => `bulk_action|uuid|${BulkActionTypesApiMap[bulkAction]}`;

/**
 * Checks if the given bulk action is "approve"
 * @param {BulkActionTypes} bulkAction
 * @return {boolean}
 */
export const isBulkActionApprove = (bulkAction) => bulkAction === BulkActionTypes.APPROVE;

/**
 * Checks if the given bulk action is "approve and send"
 * @param {BulkActionTypes} bulkAction
 * @return {boolean}
 */
export const isBulkActionApproveAndSend = (bulkAction) => bulkAction === BulkActionTypes.APPROVE_AND_SEND;

/**
 * Checks if the given bulk action is "edit send date"
 * @param {BulkActionTypes} bulkAction
 * @return {boolean}
 */
export const isBulkActionEditSendDate = (bulkAction) => bulkAction === BulkActionTypes.EDIT_SEND_DATE;

/**
 * Checks if the given bulk action is "send"
 * @param {BulkActionTypes} bulkAction
 * @return {boolean}
 */
export const isBulkActionSend = (bulkAction) => bulkAction === BulkActionTypes.SEND;

/**
 * Checks if the given bulk action is "send" or "edit send date"
 * @param {BulkActionTypes} bulkAction
 * @return {boolean}
 */
export const isBulkActionEligibleForChangingSendDate = (bulkAction) =>
  isBulkActionApproveAndSend(bulkAction) || isBulkActionSend(bulkAction) || isBulkActionEditSendDate(bulkAction);

/**
 * Checks if current step in bulk import process is 'approvals'
 * @param {String} step
 * @return {boolean}
 */
export const isCurrentStepApprovals = (step) => step === BulkImportItemsStep.approvals;

/**
 * Checks if current step in bulk import process is 'finalize'
 * @param {String} step
 * @return {boolean}
 */
export const isCurrentStepFinalize = (step) => step === BulkImportItemsStep.finalize;

/**
 * Checks if current step in bulk import process is 'complete'
 * @param {String} step
 * @return {boolean}
 */
export const isCurrentStepComplete = (step) => step === BulkImportItemsStep.complete;

/**
 * Checks if current step in bulk import process is 'select kind'
 * @param {String} step
 * @return {boolean}
 */
export const isCurrentStepSelectKind = (step) => step === BulkImportItemsStep.select_kind;

/**
 * Checks if current step in bulk import process is 'validation_file'
 * @param {String} step
 * @return {boolean}
 */
export const isCurrentStepValidationFile = (step) => step === BulkImportItemsStep.validation_file;

/**
 * Checks if current step in bulk import process is 'validation_data'
 * @param {String} step
 * @return {boolean}
 */
export const isCurrentStepValidationData = (step) => step === BulkImportItemsStep.validation_data;

/**
 * Checks if current step in bulk import process is 'validation_recommended'
 * @param {String} step
 * @return {boolean}
 */
export const isCurrentStepRecommendedValidation = (step) => step === BulkImportItemsStep.validation_recommended;

/**
 * Checks if current step in bulk import process is 'summary'
 * @param {String} step
 * @return {boolean}
 */
export const isCurrentStepSummary = (step) => step === BulkImportItemsStep.summary;

/**
 * Checks if current step in bulk import process is 'upload'
 * @param {String} step
 * @return {boolean}
 */
export const isCurrentStepUpload = (step) => step === BulkImportItemsStep.upload;

/**
 * Checks is the given bulk import id is being watched (should we get status updates for it when
 * push notifs come through?).
 * @param {Id} id
 * @param {Id[]} watchingBulkImportIds
 * @return {boolean}
 */
export const isBulkImportIdBeingWatched = (id, watchingBulkImportIds) => watchingBulkImportIds.includes(id);

/**
 * Checks if the given bulk import object has issues
 * @param {BulkImport} bulkImport
 * @return {Boolean}
 */
export const bulkImportHasIssues = (bulkImport) => !isValueEmpty(bulkImport.issues);

/**
 * Checks if given bulk import is completed.
 * @param {BulkImport} bulkImport
 * @return {Boolean}
 */
export const isBulkImportCompleted = (bulkImport) =>
  isCurrentStepComplete(bulkImport.step) && isItemStatusBulkUploadComplete(bulkImport);

/**
 * Checks if given bulk import is completed with failures
 * @param {BulkImport} bulkImport
 * @return {Boolean}
 */
export const isBulkImportCompletedWithFailures = (bulkImport) =>
  isBulkImportCompleted(bulkImport) && bulkImportHasIssues(bulkImport);

/**
 * Given the current form step and the max step reached, returns whether the confirmation
 * screen should be displayed.
 * @param {BulkImportItemsStep} maxStep
 * @returns {boolean}
 */
export const shouldDisplayBulkImportItemsConfirmation = (maxStep) =>
  // if we've started finalization, we can no longer step backwards in the modal flow,
  // so here we should only check against the max step reached
  isCurrentStepFinalize(maxStep) || isCurrentStepComplete(maxStep);

/**
 * Given the current form step, returns whether the upload screen should be displayed.
 * @param {BulkImportItemsStep} currentStep
 * @returns {boolean}
 */
export const shouldDisplayBulkImportItemsUpload = (currentStep) =>
  isCurrentStepUpload(currentStep) ||
  isCurrentStepValidationFile(currentStep) ||
  // eventually, data_validation will be broken out into multiple screens with more granular options
  isCurrentStepValidationData(currentStep);

/**
 * Checks if the first step provided is greater than the second step provided
 * (AKA comes after it in the import flow).
 * @param {BulkImportItemsStep} step
 * @param {BulkImportItemsStep} maybeSmallerStep
 * @returns {boolean}
 */
export const isStepGreaterThan = (step, maybeSmallerStep) =>
  bulkImportItemsStepsOrdered.indexOf(step) > bulkImportItemsStepsOrdered.indexOf(maybeSmallerStep);

/**
 * Checks if the first step provided is smaller than the second step provided
 * (AKA comes after it in the import flow).
 * @param {BulkImportItemsStep} step
 * @param {BulkImportItemsStep} maybeBiggerStep
 * @returns {boolean}
 */
export const isStepLessThan = (step, maybeBiggerStep) =>
  bulkImportItemsStepsOrdered.indexOf(step) < bulkImportItemsStepsOrdered.indexOf(maybeBiggerStep);

/**
 * Checks if the first step provided is between the second and third steps provided
 * (AKA it comes between them in the import flow).
 * @param {BulkImportItemsStep} step
 * @param {BulkImportItemsStep} maybeSmallerStep
 * @param {BulkImportItemsStep} maybeBiggerStep
 * @returns {boolean}
 */
export const isStepBetween = (step, maybeSmallerStep, maybeBiggerStep) =>
  isStepLessThan(step, maybeBiggerStep) && isStepGreaterThan(step, maybeSmallerStep);

/**
 * Return if an item only requires 1 final approval from the membership to have met its item approval settings.
 * @param {ItemSideApproval[]} approvals
 * @param {Item} item
 * @param {Membership.id} membershipId
 * @returns {boolean}
 */
export const isMemberFinalItemApprover = ({ approvals, item, membershipId }) => {
  if (!item.approvals || !approvals) {
    return false;
  }
  const itemSideApprovals = [];
  for (let i = 0; i < item.approvals.length; i += 1) {
    itemSideApprovals.push(approvals[item.approvals[i]]);
  }
  const itemApproval = new ItemApprovals(itemSideApprovals);
  return itemApproval.canMemberSatisfyItemApproval(membershipId);
};

/**
 * Given an item, all item approvals, and the current membership id, returns whether the item is
 * eligible for the "Approve" portion of an approval-related bulk action.
 * @param {Object} approvals
 * @param {Item} item
 * @param {Membership.id} membershipId
 * @param {Object} earSummary
 * @returns {boolean}
 */
export const isItemEligibleForApproveBulkAction = ({ approvals, item, membershipId, earSummary }) => {
  if (earSummary) {
    return Boolean(isItemStatusNeedsApproval(item) && earSummary[item.id]?.canApprove);
  }

  return Boolean(
    isItemStatusNeedsApproval(item) &&
      isCurrentMembershipApproverOnItem(
        membershipId,
        item.approvals?.map((approvalId) => approvals[approvalId]).filter((approval) => approval?.canApprove),
      ),
  );
};

/**
 * Given an item, all item approvals, and the current membership id, returns whether the item is
 * eligible for the "Approve and send" bulk action.
 * @param {ItemSideApproval[]} approvals
 * @param {Item} item
 * @param {Membership.id} membershipId
 * @param {Object} earSummary
 * @returns {boolean}
 */
export const isItemEligibleForApproveAndSendBulkAction = ({ approvals, item, membershipId, earSummary }) => {
  if (earSummary) {
    return Boolean(
      isItemStatusNeedsApproval(item) && isItemStatusNextReadyToSend(item) && earSummary[item.id]?.canApproveAndSend,
    );
  }

  return Boolean(
    isItemStatusNeedsApproval(item) &&
      isItemStatusNextReadyToSend(item) &&
      isMemberFinalItemApprover({ approvals, item, membershipId }),
  );
};

/**
 * Given an item, returns whether the item is eligible for the "Edit send date" bulk action.
 * @param {Item} item
 * @return {boolean}
 */
export const isItemEligibleForEditSendDateBulkAction = (item) =>
  // the item is scheduled for a date that is not today
  isItemStatusScheduled(item) && !isDateToday(item.dateScheduled);

/**
 * Given an item, returns whether the item partner contacts are valid.
 *
 * When item's payment_delivery_method is unset at least one contact is required otherwise
 * we return true.
 * @param {Item} item
 * @return {boolean}
 */
export const isItemContactsValidForBulkAction = (item) => {
  if (!isItemPaymentDeliveryMethodUnset(item)) {
    return true;
  }

  return hasPartnerContacts(item);
};

/**
 * Returns all items that are currently eligible for a given bulk action.
 * @param {OptionsArg} options
 * @param {Object} options.approvals
 * @param {Item[]} options.items
 * @param {BulkActionTypes} options.bulkAction
 * @param {Membership.id} options.membershipId
 * @param {Object} options.earSummary
 * @returns {Item[]}
 */
export const getItemsEligibleForBulkAction = ({ approvals, bulkAction, items = [], membershipId, earSummary }) => {
  switch (bulkAction) {
    case BulkActionTypes.APPROVE:
      return items.filter(
        (item) =>
          isItemEligibleForApproveBulkAction({
            approvals,
            item,
            membershipId,
            earSummary,
          }) && !isItemLocked(item),
      );

    case BulkActionTypes.APPROVE_AND_SEND:
      return items.filter(
        (item) =>
          isItemEligibleForApproveAndSendBulkAction({
            approvals,
            item,
            membershipId,
            earSummary,
          }) &&
          !isItemLocked(item) &&
          isItemContactsValidForBulkAction(item),
      );

    case BulkActionTypes.EDIT_SEND_DATE:
      return items.filter(
        (item) =>
          isItemEligibleForEditSendDateBulkAction(item) &&
          !isItemLocked(item) &&
          isItemContactsValidForBulkAction(item),
      );

    case BulkActionTypes.SEND:
      return items.filter(
        (item) => isItemStatusReadyToSend(item) && !isItemLocked(item) && isItemContactsValidForBulkAction(item),
      );

    default:
      return [];
  }
};

/**
 * Returns all items that are currently ineligible for a given bulk action.
 * @param {OptionsArg} options
 * @param {Object} options.approvals
 * @param {Item[]} options.items
 * @param {BulkActionTypes} options.bulkAction
 * @param {Membership.id} options.membershipId
 * @param {Object} options.earSummary
 * @returns {Item[]}
 */
export const getItemsIneligibleForBulkAction = (options) => {
  const { items } = options;
  const eligibleItems = getItemsEligibleForBulkAction(options);
  return _difference(items, eligibleItems);
};

/**
 * Checks if the BulkActionsSummaryModal is on the INITIAL step
 * @param step
 * @returns {boolean}
 */
export const isSummaryModalInitialStep = (step) => step === BulkActionSummarySteps.INITIAL;

/**
 * Checks if the BulkActionsSummaryModal is on the CONFIRMATION step
 * @param step
 * @returns {boolean}
 */
export const isSummaryModalConfirmationStep = (step) => step === BulkActionSummarySteps.CONFIRMATION;

/**
 * Checks if the BulkActionsSummaryModal is on the LOADING step
 * @param step
 * @returns {boolean}
 */
export const isSummaryModalLoadingStep = (step) => step === BulkActionSummarySteps.LOADING;

/**
 * Checks if the EMPTY_CSV error is present
 * @param {String[]} errors
 * @returns {boolean}
 */
export const hasEmptyCsvError = (errors = []) => isIncluded(errors, BulkImportErrorCodes.EMPTY_CSV);

/**
 * Checks if the INVALID_CSV error is present
 * @param {String[]} errors
 * @returns {boolean}
 */
export const hasInvalidCsvError = (errors = []) => isIncluded(errors, BulkImportErrorCodes.INVALID_CSV);

/**
 * Checks if the UNSUPPORTED_ENCODING error is present
 * @param {String[]} errors
 * @returns {boolean}
 */
export const hasUnsupportedEncodingError = (errors = []) =>
  isIncluded(errors, BulkImportErrorCodes.UNSUPPORTED_ENCODING);

/**
 * Checks if the next day and same day payment amounts are larger than the current balance
 * @param {number} balanceAmount
 * @param {number} sameAmount
 * @param {number} nextAmount
 * @returns {boolean}
 */
export const insufficientBalanceNextDaySameDay = (balanceAmount, sameAmount, nextAmount) =>
  plusFloat(sameAmount, nextAmount) > balanceAmount;

/**
 * Returns true if international items are included in the bulk import. This is true when
 * fees.international.count is greater than 0.
 * @param {BulkImportSummaryFees} fees
 * @returns {Boolean}
 */
export const areInternationalItemsIncludedInBulkImport = (fees) => fees?.international.count > 0;

import ThunkFetch from 'helpers/thunkFetch';

import { signupResendVerificationEmailEndpoint } from 'services/api/signupEndpoints';

import {
  SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_FAILURE,
  SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_REQUEST,
  SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_SUCCESS,
} from 'types/signup';

const submitResendVerificationEmail = () => {
  const t = new ThunkFetch({
    actions: {
      error: SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_FAILURE,
      request: SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_REQUEST,
      success: SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_SUCCESS,
    },
    endpoint: signupResendVerificationEmailEndpoint,
    method: 'POST',
    requireAuth: true,
  });

  return t.makeRequest();
};

export default submitResendVerificationEmail;

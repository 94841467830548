import Pusher from 'pusher-js';

// Define the socket outside of the class to be able to access it from different places if we'll need to
let socket;

/**
 * PusherService to send push notification to the UI
 */
class PusherService {
  /**
   * Disconnect a Pusher socket
   */
  static disconnect = () => {
    if (socket) {
      socket.disconnect();
    }

    // This destroys the websocket connection!
    // This will prohibit us from having multiple subscriptions that aren't disconnected at the same flow.
    socket = undefined;
  };

  /**
   * Creates a new Pusher socket instance
   * @return {Pusher}
   */
  static createNewPusherInstance = () =>
    new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      enabledTransports: ['ws'],
      forceTLS: true,
    });

  /**
   * Returns a the current socket
   * This is internal - for testing purposes only!
   * @return {Pusher}
   */
  static getCurrentSocket = () => socket;

  /**
   * Returns a Pusher instance (either an existing one, or creates a new one)
   * @return {Pusher}
   */
  static getSocket = () => {
    if (!socket) {
      socket = this.createNewPusherInstance();
    }

    return socket;
  };

  /**
   * Subscribe to a channel
   * @param channelName
   * @return {Channel}
   */
  static subscribe = (channelName) => this.getSocket().subscribe(channelName);
}

export default PusherService;

/**
 * @module sagas/shared/documentError
 */

import { showErrorIndicator } from 'components/form/helpers/indicators';

export function handleDocumentError(errorData, defaultErrorMessage) {
  const fileError = errorData?.fields?.file;
  if (fileError && Array.isArray(fileError)) {
    showErrorIndicator(fileError[0]);
  } else {
    showErrorIndicator(defaultErrorMessage);
  }
}

export function handleDocumentUploadError(errorData) {
  handleDocumentError(errorData, 'We couldn’t upload your document. Please try again or refresh the page.');
}

export function handleDocumentDeleteError(errorData) {
  handleDocumentError(errorData, 'We couldn’t delete your document. Please try again or refresh the page.');
}

import PropTypes from 'prop-types';
import React from 'react';

import { AmountField } from './components';

/**
 * In a discount block, input to enter the amount (dollar or percentage).
 * @param {ComponentProps} props
 * @param {string} props.name
 * @return {StatelessComponent}
 */
const DiscountAmount = (props) => {
  const spanClasses = 'font-regular semibold font-color--slate';

  return (
    <React.Fragment>
      <span className={`${spanClasses} margin-left--m margin-right--m`}>of</span>

      <AmountField {...props} />

      <span className={`${spanClasses} margin-left--m`}>of the subtotal.</span>
    </React.Fragment>
  );
};

DiscountAmount.propTypes = {
  decimalScale: PropTypes.number.isRequired,
  fixedDecimalScale: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

DiscountAmount.defaultProps = {
  fixedDecimalScale: undefined,
};

export default DiscountAmount;

import { combineReducers } from 'redux';

import activeFilters from './activeFiltersReducer';
import allIds from './allIdsReducer';
import byId from './byIdReducer';
import byItem from './byItemReducer';
import errors from './errorReducer';
import isFetching from './isFetchingReducer';
import pagination from './paginationReducer';
import transactionAchs from './transactionAchsReducer';
import transactionChecks from './transactionChecksReducer';
import transactionItems from './transactionItemsReducer';
import transactionPaymentConfirmations from './transactionPaymentConfirmationsReducer';

const transactionsReducer = combineReducers({
  activeFilters,
  allIds,
  byId,
  byItem,
  errors,
  isFetching,
  pagination,
  transactionAchs,
  transactionChecks,
  transactionItems,
  transactionPaymentConfirmations,
});

export default transactionsReducer;

import React from 'react';
import { Text } from 'components';
import { typography } from 'constants/styles';
import { DataCardTooltipConditional } from '../DataCardTooltipConditional';
import './DataCardDetail.scss';
const DataCardDetail = ({ icon, iconProps, iconRight, iconRightProps, label, tooltipContent, tooltipContentRight, value, }) => (React.createElement("div", { className: "data-card-detail" },
    React.createElement("div", { className: "data-card-detail__label" },
        React.createElement(Text.Regular, { color: typography.TextColor.GREY_X_DARK, size: typography.TextSize.LEVEL_200 }, label)),
    React.createElement("div", { className: "data-card-detail__value" },
        React.createElement(DataCardTooltipConditional, { icon: icon, iconProps: iconProps, tooltipContent: tooltipContent }),
        React.createElement(Text.Regular, { color: typography.TextColor.BLACK, size: typography.TextSize.LEVEL_400 }, value),
        React.createElement(DataCardTooltipConditional, { icon: iconRight, iconProps: iconRightProps, tooltipContent: tooltipContentRight }))));
export default DataCardDetail;

/**
 * A grouping of settings, some of which are nested.
 *
 * @enum {string|object}
 */
export const PermissionSection = {
  COMPANY_TAX: 'company_tax',
  CUSTOMER: 'customer',
  FUNDING_ACCOUNTS: 'funding_accounts', // used in funding_accounts:<action> (logged in user's own company's funding accounts)
  FUNDING_ACCOUNT: 'funding_account', // used in vendor|customer:funding_account:<action>
  MESSAGE: 'message',
  NOTE: 'note',
  PAYABLE: 'payable',
  PAYMENT_METHODS: 'payment_methods',
  PO_MATCHING: 'po_matching',
  RECEIVABLE: 'receivable',
  SETTINGS: {
    ACCOUNT: 'settings:account',
    API_ACCESS: 'settings:api',
    APPROVAL_POLICY: 'settings:approval_policy',
    BULK: 'settings:bulk',
    BUSINESS_BRANDING: 'settings:business_branding',
    BUSINESS_REPRESENTATIVES: 'settings:business_representatives',
    BUSINESS_VERIFICATION: 'settings:business_verification',
    EXPORT_DATA: 'settings:export_data',
    INTEGRATIONS: 'settings:integrations',
    LEDGER_FIELD_MAPPING: 'settings:ledger_field_mapping',
    SSO: 'settings:sso',
    ROLES: 'settings:roles',
    TEAM_MEMBERS: 'settings:team_members',
    CUSTOM_ROLES: 'settings:roles',
  },
  TAG: 'tag',
  TAX: {
    BASIC_MANAGEMENT: 'tax:basic_management',
    BULK_REQUEST: 'tax:bulk_request',
    EXPORT: 'tax:export',
    READ_ONLY_STATUS: 'tax:read_only_status',
    TEAM_MEMBER_ENTRY: 'tax:team_member_entry',
  },
  VENDOR: 'vendor',
  VENDOR_RISK: {
    FULL: 'vendor_risk:full',
    VIEW_TIN: 'vendor_risk:tin:view',
  },
};

/**
 * Actions which can be take on PermissionSections.
 *
 * @enum {string}
 */
const PermissionAction = {
  APPROVE: 'approve',
  APPROVE_DISCREPANCIES: 'approve_discrepancies',
  ARCHIVE: 'archive',
  BALANCE: 'balance',
  BULK: 'bulk',
  BYPASS_APPROVAL: 'bypass_approval',
  CANCEL: 'cancel',
  CREATE: 'create',
  EDIT: 'edit',
  FULL: 'full',
  MASKED: 'masked',
  MANAGE: 'manage',
  RESYNC: 'resync',
  SEND: 'send',
  UPDATE: 'update',
  VIEW: 'view',
};

/**
 * Format PermissionSection(s) together with an action to create a PermissionResourceAction. Kind of like getJoinedPath().
 * @param {PermissionSection|PermissionAction} sectionsOrActions
 * @returns {string|PermissionSection} the string is a PermissionResourceAction
 */
export const joinPermissionResourceAction = (...sectionsOrActions) => sectionsOrActions.join(':');

/**
 * This is the FE's master list of Permission resourceActions.
 * Please keep it organized alphabetically
 * @enum {Permission.resourceAction}
 */
export const PermissionResourceAction = {
  // company tax settings
  COMPANY_TAX_EDIT: joinPermissionResourceAction(PermissionSection.COMPANY_TAX, PermissionAction.EDIT),
  COMPANY_TAX_VIEW: joinPermissionResourceAction(PermissionSection.COMPANY_TAX, PermissionAction.VIEW),

  // customer
  CUSTOMER_ARCHIVE: joinPermissionResourceAction(PermissionSection.CUSTOMER, PermissionAction.ARCHIVE),
  CUSTOMER_CREATE: joinPermissionResourceAction(PermissionSection.CUSTOMER, PermissionAction.CREATE),
  CUSTOMER_EDIT: joinPermissionResourceAction(PermissionSection.CUSTOMER, PermissionAction.EDIT),
  CUSTOMER_VIEW: joinPermissionResourceAction(PermissionSection.CUSTOMER, PermissionAction.VIEW),

  // funding accounts
  FUNDING_ACCOUNTS_ARCHIVE: joinPermissionResourceAction(PermissionSection.FUNDING_ACCOUNTS, PermissionAction.ARCHIVE),
  FUNDING_ACCOUNTS_BALANCE: joinPermissionResourceAction(PermissionSection.FUNDING_ACCOUNTS, PermissionAction.BALANCE),
  FUNDING_ACCOUNTS_CREATE: joinPermissionResourceAction(PermissionSection.FUNDING_ACCOUNTS, PermissionAction.CREATE),
  FUNDING_ACCOUNTS_EDIT: joinPermissionResourceAction(PermissionSection.FUNDING_ACCOUNTS, PermissionAction.EDIT),
  FUNDING_ACCOUNTS_VIEW: joinPermissionResourceAction(PermissionSection.FUNDING_ACCOUNTS, PermissionAction.VIEW),
  FUNDING_ACCOUNTS_FULL: joinPermissionResourceAction(PermissionSection.FUNDING_ACCOUNTS, PermissionAction.FULL),

  // messages/notes
  MESSAGE_CREATE: joinPermissionResourceAction(PermissionSection.MESSAGE, PermissionAction.CREATE),
  NOTE_CREATE: joinPermissionResourceAction(PermissionSection.NOTE, PermissionAction.CREATE),

  // payables
  PAYABLE_APPROVE: joinPermissionResourceAction(PermissionSection.PAYABLE, PermissionAction.APPROVE),
  PAYABLE_BULK: joinPermissionResourceAction(PermissionSection.PAYABLE, PermissionAction.BULK),
  PAYABLE_BYPASS_APPROVAL: joinPermissionResourceAction(PermissionSection.PAYABLE, PermissionAction.BYPASS_APPROVAL),
  PAYABLE_CANCEL: joinPermissionResourceAction(PermissionSection.PAYABLE, PermissionAction.CANCEL),
  PAYABLE_CREATE: joinPermissionResourceAction(PermissionSection.PAYABLE, PermissionAction.CREATE),
  PAYABLE_EDIT: joinPermissionResourceAction(PermissionSection.PAYABLE, PermissionAction.EDIT),
  PAYABLE_SEND: joinPermissionResourceAction(PermissionSection.PAYABLE, PermissionAction.SEND),
  PAYABLE_VIEW: joinPermissionResourceAction(PermissionSection.PAYABLE, PermissionAction.VIEW),

  // payment methods
  PAYMENT_METHODS_EDIT: joinPermissionResourceAction(PermissionSection.PAYMENT_METHODS, PermissionAction.EDIT),

  // PO matching
  PO_MATCHING_APPROVE_DISCREPANCIES: joinPermissionResourceAction(
    PermissionSection.PO_MATCHING,
    PermissionAction.APPROVE_DISCREPANCIES,
  ),

  // receivables
  RECEIVABLE_APPROVE: joinPermissionResourceAction(PermissionSection.RECEIVABLE, PermissionAction.APPROVE),
  RECEIVABLE_BULK: joinPermissionResourceAction(PermissionSection.RECEIVABLE, PermissionAction.BULK),
  RECEIVABLE_CANCEL: joinPermissionResourceAction(PermissionSection.RECEIVABLE, PermissionAction.CANCEL),
  RECEIVABLE_CREATE: joinPermissionResourceAction(PermissionSection.RECEIVABLE, PermissionAction.CREATE),
  RECEIVABLE_EDIT: joinPermissionResourceAction(PermissionSection.RECEIVABLE, PermissionAction.EDIT),
  RECEIVABLE_SEND: joinPermissionResourceAction(PermissionSection.RECEIVABLE, PermissionAction.SEND),
  RECEIVABLE_VIEW: joinPermissionResourceAction(PermissionSection.RECEIVABLE, PermissionAction.VIEW),

  // settings
  SETTINGS_ACCOUNT_EDIT: joinPermissionResourceAction(PermissionSection.SETTINGS.ACCOUNT, PermissionAction.EDIT),
  SETTINGS_ACCOUNT_VIEW: joinPermissionResourceAction(PermissionSection.SETTINGS.ACCOUNT, PermissionAction.VIEW),
  SETTINGS_API_ACCESS_EDIT: joinPermissionResourceAction(PermissionSection.SETTINGS.API_ACCESS, PermissionAction.EDIT),
  SETTINGS_API_ACCESS_VIEW: joinPermissionResourceAction(PermissionSection.SETTINGS.API_ACCESS, PermissionAction.VIEW),
  SETTINGS_APPROVAL_POLICY_EDIT: joinPermissionResourceAction(
    PermissionSection.SETTINGS.APPROVAL_POLICY,
    PermissionAction.EDIT,
  ),
  SETTINGS_APPROVAL_POLICY_VIEW: joinPermissionResourceAction(
    PermissionSection.SETTINGS.APPROVAL_POLICY,
    PermissionAction.VIEW,
  ),
  SETTINGS_BULK_VIEW: joinPermissionResourceAction(PermissionSection.SETTINGS.BULK, PermissionAction.VIEW),
  SETTINGS_BUSINESS_BRANDING_EDIT: joinPermissionResourceAction(
    PermissionSection.SETTINGS.BUSINESS_BRANDING,
    PermissionAction.EDIT,
  ),
  SETTINGS_BUSINESS_BRANDING_VIEW: joinPermissionResourceAction(
    PermissionSection.SETTINGS.BUSINESS_BRANDING,
    PermissionAction.VIEW,
  ),
  SETTINGS_BUSINESS_REPRESENTATIVES_EDIT: joinPermissionResourceAction(
    PermissionSection.SETTINGS.BUSINESS_REPRESENTATIVES,
    PermissionAction.EDIT,
  ),
  SETTINGS_BUSINESS_REPRESENTATIVES_VIEW: joinPermissionResourceAction(
    PermissionSection.SETTINGS.BUSINESS_REPRESENTATIVES,
    PermissionAction.VIEW,
  ),
  SETTINGS_BUSINESS_VERIFICATION_EDIT: joinPermissionResourceAction(
    PermissionSection.SETTINGS.BUSINESS_VERIFICATION,
    PermissionAction.EDIT,
  ),
  SETTINGS_BUSINESS_VERIFICATION_VIEW: joinPermissionResourceAction(
    PermissionSection.SETTINGS.BUSINESS_VERIFICATION,
    PermissionAction.VIEW,
  ),
  SETTINGS_EXPORT_DATA: PermissionSection.SETTINGS.EXPORT_DATA,
  SETTINGS_INTEGRATIONS_EDIT: joinPermissionResourceAction(
    PermissionSection.SETTINGS.INTEGRATIONS,
    PermissionAction.EDIT,
  ),
  SETTINGS_INTEGRATIONS_RESYNC: joinPermissionResourceAction(
    PermissionSection.SETTINGS.INTEGRATIONS,
    PermissionAction.RESYNC,
  ),
  SETTINGS_INTEGRATIONS_VIEW: joinPermissionResourceAction(
    PermissionSection.SETTINGS.INTEGRATIONS,
    PermissionAction.VIEW,
  ),
  SETTINGS_TEAM_MEMBERS_EDIT: joinPermissionResourceAction(
    PermissionSection.SETTINGS.TEAM_MEMBERS,
    PermissionAction.EDIT,
  ),
  SETTINGS_TEAM_MEMBERS_VIEW: joinPermissionResourceAction(
    PermissionSection.SETTINGS.TEAM_MEMBERS,
    PermissionAction.VIEW,
  ),
  ROLES_MANAGE: joinPermissionResourceAction(PermissionSection.SETTINGS.CUSTOM_ROLES, PermissionAction.MANAGE),
  SETTINGS_SSO_MANAGE: joinPermissionResourceAction(PermissionSection.SETTINGS.SSO, PermissionAction.MANAGE),
  SETTINGS_ROLES_MANAGE: joinPermissionResourceAction(PermissionSection.SETTINGS.ROLES, PermissionAction.MANAGE),

  // tag
  TAG_EDIT: joinPermissionResourceAction(PermissionSection.TAG, PermissionAction.EDIT),

  // tax
  TAX_BASIC_MANAGEMENT: PermissionSection.TAX.BASIC_MANAGEMENT,
  TAX_BULK_REQUEST: PermissionSection.TAX.BULK_REQUEST,
  TAX_EXPORT: PermissionSection.TAX.EXPORT,
  TAX_READ_ONLY_STATUS: PermissionSection.TAX.READ_ONLY_STATUS,
  TAX_TEAM_MEMBER_ENTRY: PermissionSection.TAX.TEAM_MEMBER_ENTRY,

  // vendor
  VENDOR_ARCHIVE: joinPermissionResourceAction(PermissionSection.VENDOR, PermissionAction.ARCHIVE),
  VENDOR_CREATE: joinPermissionResourceAction(PermissionSection.VENDOR, PermissionAction.CREATE),
  VENDOR_EDIT: joinPermissionResourceAction(PermissionSection.VENDOR, PermissionAction.EDIT),
  VENDOR_FUNDING_ACCOUNT_CREATE: joinPermissionResourceAction(
    PermissionSection.VENDOR,
    PermissionSection.FUNDING_ACCOUNT,
    PermissionAction.CREATE,
  ),
  VENDOR_FUNDING_ACCOUNT_FULL: joinPermissionResourceAction(
    PermissionSection.VENDOR,
    PermissionSection.FUNDING_ACCOUNT,
    PermissionAction.FULL,
  ),
  VENDOR_FUNDING_ACCOUNT_MASKED: joinPermissionResourceAction(
    PermissionSection.VENDOR,
    PermissionSection.FUNDING_ACCOUNT,
    PermissionAction.MASKED,
  ),
  VENDOR_FUNDING_ACCOUNT_UPDATE: joinPermissionResourceAction(
    PermissionSection.VENDOR,
    PermissionSection.FUNDING_ACCOUNT,
    PermissionAction.UPDATE,
  ),
  VENDOR_VIEW: joinPermissionResourceAction(PermissionSection.VENDOR, PermissionAction.VIEW),

  // vendor risk
  VENDOR_RISK_FULL: PermissionSection.VENDOR_RISK.FULL,
  VENDOR_RISK_VIEW_TIN: PermissionSection.VENDOR_RISK.VIEW_TIN,
};

/**
 * This is the FE's master list of RCTM-facing text for each Permission resourceActions.
 *
 * @enum {Object<Permission.resourceAction, string>}
 */
export const PermissionResourceActionText = {
  // company tax settings
  [PermissionResourceAction.COMPANY_TAX_EDIT]: 'Edit tax collection and reporting',
  [PermissionResourceAction.COMPANY_TAX_VIEW]: 'View tax collection and reporting',

  // customer
  [PermissionResourceAction.CUSTOMER_ARCHIVE]: 'Archive customers',
  [PermissionResourceAction.CUSTOMER_CREATE]: 'Create customers',
  [PermissionResourceAction.CUSTOMER_EDIT]: 'Edit customers',
  [PermissionResourceAction.CUSTOMER_VIEW]: 'View customers',
  // funding accounts
  [PermissionResourceAction.FUNDING_ACCOUNTS_ARCHIVE]: 'Archive your bank accounts',
  [PermissionResourceAction.FUNDING_ACCOUNTS_BALANCE]: 'Deposit/withdraw from Routable balance',
  [PermissionResourceAction.FUNDING_ACCOUNTS_CREATE]: 'Add your bank accounts',
  [PermissionResourceAction.FUNDING_ACCOUNTS_EDIT]: 'Edit your bank accounts',
  [PermissionResourceAction.FUNDING_ACCOUNTS_VIEW]: 'View your bank accounts',
  [PermissionResourceAction.FUNDING_ACCOUNTS_FULL]: 'View unmasked bank account',
  // messages/notes
  [PermissionResourceAction.MESSAGE_CREATE]: 'Send messages to vendors and customers',
  [PermissionResourceAction.NOTE_CREATE]: 'Send internal notes on payables and receivables',
  // payables
  [PermissionResourceAction.PAYABLE_APPROVE]: 'Approve payables',
  [PermissionResourceAction.PAYABLE_BULK]: 'Can take bulk actions on payables and receivables',
  [PermissionResourceAction.PAYABLE_CANCEL]: 'Cancel payables and receivables',
  [PermissionResourceAction.PAYABLE_CREATE]: 'Create and edit payables and receivables',
  [PermissionResourceAction.PAYABLE_EDIT]: 'Edit payables and receivables',
  [PermissionResourceAction.PAYABLE_SEND]: 'Release payments and send invoices',
  [PermissionResourceAction.PAYABLE_VIEW]: 'View payables and receivables',
  [PermissionResourceAction.PAYABLE_BYPASS_APPROVAL]: 'Bypass approvals',
  // payment methods
  [PermissionResourceAction.PAYMENT_METHODS_EDIT]: 'Manage available payment methods',
  // po matching
  [PermissionResourceAction.PO_MATCHING_APPROVE_DISCREPANCIES]: 'Approve discrepancies',
  // receivables
  [PermissionResourceAction.RECEIVABLE_APPROVE]: 'Approve receivables',
  [PermissionResourceAction.RECEIVABLE_BULK]: 'Can take bulk actions on receivables',
  [PermissionResourceAction.RECEIVABLE_CANCEL]: 'Cancel receivables',
  [PermissionResourceAction.RECEIVABLE_CREATE]: 'Create receivables',
  [PermissionResourceAction.RECEIVABLE_EDIT]: 'Edit receivables',
  [PermissionResourceAction.RECEIVABLE_SEND]: 'Send invoices',
  [PermissionResourceAction.RECEIVABLE_VIEW]: 'View receivables',
  // settings
  [PermissionResourceAction.SETTINGS_ACCOUNT_EDIT]: 'Edit account information',
  [PermissionResourceAction.SETTINGS_ACCOUNT_VIEW]: 'View account information',
  [PermissionResourceAction.SETTINGS_API_ACCESS_EDIT]: 'Edit API access',
  [PermissionResourceAction.SETTINGS_API_ACCESS_VIEW]: 'View API access',
  [PermissionResourceAction.SETTINGS_APPROVAL_POLICY_EDIT]: 'Edit approval rules',
  [PermissionResourceAction.SETTINGS_APPROVAL_POLICY_VIEW]: 'View approval rules',
  [PermissionResourceAction.SETTINGS_BULK_VIEW]: 'View bulk imports',
  [PermissionResourceAction.SETTINGS_BUSINESS_BRANDING_EDIT]: 'Edit account branding settings',
  [PermissionResourceAction.SETTINGS_BUSINESS_BRANDING_VIEW]: 'View account branding settings',
  [PermissionResourceAction.SETTINGS_BUSINESS_REPRESENTATIVES_EDIT]: 'Edit business representatives',
  [PermissionResourceAction.SETTINGS_BUSINESS_REPRESENTATIVES_VIEW]: 'View business representatives',
  [PermissionResourceAction.SETTINGS_BUSINESS_VERIFICATION_EDIT]: 'Edit and submit business verification information',
  [PermissionResourceAction.SETTINGS_BUSINESS_VERIFICATION_VIEW]: 'View business verification information',
  [PermissionResourceAction.SETTINGS_EXPORT_DATA]: 'Export account data to CSV',
  [PermissionResourceAction.SETTINGS_INTEGRATIONS_EDIT]: 'Edit integrations',
  [PermissionResourceAction.SETTINGS_INTEGRATIONS_RESYNC]: 'Re-sync integrations',
  [PermissionResourceAction.SETTINGS_INTEGRATIONS_VIEW]: 'View integrations',
  [PermissionResourceAction.SETTINGS_ROLES_MANAGE]: 'Manage custom roles',
  [PermissionResourceAction.SETTINGS_SSO_MANAGE]: 'Manage single sign on',
  [PermissionResourceAction.SETTINGS_TEAM_MEMBERS_EDIT]: 'Edit team members',
  [PermissionResourceAction.SETTINGS_TEAM_MEMBERS_VIEW]: 'View team members',
  // tag
  [PermissionResourceAction.TAG_EDIT]: 'Edit tags on payables and receivables',
  // vendor
  [PermissionResourceAction.VENDOR_ARCHIVE]: 'Archive vendors and customers',
  [PermissionResourceAction.VENDOR_CREATE]: 'Create vendors and customers',
  [PermissionResourceAction.VENDOR_EDIT]: 'Edit vendors and customers',
  [PermissionResourceAction.VENDOR_FUNDING_ACCOUNT_CREATE]: 'Edit vendor payment methods',
  [PermissionResourceAction.VENDOR_FUNDING_ACCOUNT_FULL]: 'View unmasked vendor bank account',
  [PermissionResourceAction.VENDOR_FUNDING_ACCOUNT_MASKED]: 'View last 4 digits of any vendor bank account',
  [PermissionResourceAction.VENDOR_FUNDING_ACCOUNT_UPDATE]: 'Request an updated bank account from vendors',
  [PermissionResourceAction.VENDOR_VIEW]: 'View vendors and customers',
  // tax
  [PermissionResourceAction.TAX_BASIC_MANAGEMENT]: 'Invalidate and request tax forms for a single vendor',
  [PermissionResourceAction.TAX_BULK_REQUEST]: 'Bulk request tax forms from all vendors',
  [PermissionResourceAction.TAX_EXPORT]: 'Export and download tax information',
  [PermissionResourceAction.TAX_READ_ONLY_STATUS]: 'View vendor tax status',
  [PermissionResourceAction.TAX_TEAM_MEMBER_ENTRY]: 'Team member can add tax form',
  // vendor risk
  [PermissionResourceAction.VENDOR_RISK_FULL]: 'Run or dismiss vendor compliance checks',
  [PermissionResourceAction.VENDOR_RISK_VIEW_TIN]: 'View unmasked TIN',
};

export default PermissionResourceAction;

import React from 'react';

import { Currency } from 'context';

/**
 * When you want to get the value from Currency, use this simple hook to fetch the context value.
 * @function
 * @returns {CurrencyCodeMap} - When invoked, returns the value from Currency.Provider.
 */
const useCurrencyContext = () => React.useContext(Currency);

export default useCurrencyContext;

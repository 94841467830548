import React from 'react';
import { Intent } from 'constants/ui';
import { isItemStatusExternallyPaid } from 'helpers/items';
import BaseHint from '../BaseHint';
import { getSelectPaymentMethodHintText, getSelectPaymentMethodHintTitle } from './helpers/text';
const SelectPaymentMethodHint = ({ item, partnerFundingAccount, partnership, }) => {
    if (partnerFundingAccount || isItemStatusExternallyPaid(item) || !partnership) {
        return null;
    }
    return (React.createElement(BaseHint, { dataFullStoryTitle: true, intent: Intent.WARNING, multiline: true, text: getSelectPaymentMethodHintTitle(item.kind), title: getSelectPaymentMethodHintText(partnership.name) }));
};
export default SelectPaymentMethodHint;

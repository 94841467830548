import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const PanelHeader = ({ children, className, hasPanelBody, title, titleIconClassName }) => {
  const headerClasses = classNames({
    'panel-heading': true,
    'no-panel-body': !hasPanelBody,
    [className]: !!className,
  });

  return (
    <div className={headerClasses}>
      <div className="panel-heading--row">
        <div className="panel-heading--title">
          {titleIconClassName && <span className={titleIconClassName} />}
          <p className="font-regular font-color--greyXDark">{title}</p>
        </div>

        {children}
      </div>
    </div>
  );
};

PanelHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  hasPanelBody: PropTypes.bool,
  title: PropTypes.string.isRequired,
  titleIconClassName: PropTypes.string,
};

PanelHeader.defaultProps = {
  children: undefined,
  className: undefined,
  hasPanelBody: true,
  titleIconClassName: undefined,
};

export default PanelHeader;

import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

import { TableCellTextWithSubtext } from 'components/table';

/**
 * Renders the bank account cell for a transactions table row.
 * If displaying the balance account, the account number will not be present.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 * @constructor
 */
const BalanceTransactionBankAccountCell = ({ accountName, accountNumber, rowData }) => {
  const name = _get(rowData, accountName);
  const number = _get(rowData, accountNumber);

  return (
    <div className="flex-col-center" data-fullstory>
      <TableCellTextWithSubtext subtext={number ? `Account number: ${number}` : undefined} text={name} />
    </div>
  );
};

BalanceTransactionBankAccountCell.propTypes = {
  accountName: PropTypes.string.isRequired,
  accountNumber: PropTypes.string.isRequired,
  rowData: PropTypes.shape({}).isRequired,
};

export default BalanceTransactionBankAccountCell;

/**
 * @module constants/partnershipMember
 */

/**
 * @enum {string} Status of a partnership member's email address
 */
export const PartnershipMemberEmailStatus = {
  BOUNCED: 'bounced',
};

/**
 * @enum {string} Access values for a partnership member (e.g. member.defaultItem)
 */
export const PartnershipMemberAccess = {
  ACTIONABLE: 'actionable',
  NONE: 'none',
  SELF_MANAGED: 'self_managed',
  READ_ONLY: 'read_only',
  // These bottom values are not from the database, they are derived on the frontend by helpers which determine if the
  // PartnershipMember has defaultItem and/or defaultGeneral set.
  HAS_DEFAULTS: 'has_defaults',
  NO_DEFAULTS: 'no_defaults',
};

/**
 * Our translation of the BE PartnershipMemberAccess to user-facing language.
 * @enum {string}
 */
export const PartnershipMemberAccessTranslation = {
  [PartnershipMemberAccess.ACTIONABLE]: 'contact',
  [PartnershipMemberAccess.READ_ONLY]: 'read-only',
};

/**
 * @enum {string}
 */
export const PartnershipMemberAccessSubtitleText = {
  [PartnershipMemberAccess.ACTIONABLE]: 'Can act on this',
  [PartnershipMemberAccess.HAS_DEFAULTS]: 'Has default contact settings',
  [PartnershipMemberAccess.NO_DEFAULTS]: 'No default contact settings',
  [PartnershipMemberAccess.READ_ONLY]: 'Can only view',
  [PartnershipMemberAccess.SELF_MANAGED]: 'Can act on this',
  [PartnershipMemberAccess.NONE]: 'Removed from this',
};

/**
 * Access scopes for a PartnershipMember. A PartnershipMember could have a "contact" access level on items, but "read-only"
 * access level for company management.
 * @enum {string}
 */
export const PartnershipMemberAccessScope = {
  COMPANY_MANAGEMENT: 'company_management',
  ITEMS: 'items',
};

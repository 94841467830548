import { ExistingItemActions } from 'constants/item';

import { buildSubmitExistingItemLineItems, buildSubmitExistingItemMeta } from 'helpers/existingItem';
import ThunkFetch from 'helpers/thunkFetch';
import { isEqualToAny } from 'helpers/utility';

import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { getSubmitExistingItemEndpoint } from 'services/api/itemEndpoints';

import {
  SUBMIT_EXISTING_ITEMS_FAILURE,
  SUBMIT_EXISTING_ITEMS_REQUEST,
  SUBMIT_EXISTING_ITEMS_SUCCESS,
} from 'types/item';

/**
 * This is called when paying an invoice from the external flow
 * As well as to handle cancelling and approving existing items
 * https://routablehq.slack.com/archives/C8UV1S84T/p1629130074133500
 * @param {object} payload
 * @param {string} partnershipId
 * @param {Item} item
 * @param {string} action
 * @param {Function} successCallback
 * @returns {function(*=): Promise<null|{errors}|any>}
 */
const submitExistingItemOld = (payload, partnershipId, item, action, successCallback) => {
  // no data submission
  const isActionApproveOrCancel = isEqualToAny(action, [ExistingItemActions.APPROVE, ExistingItemActions.CANCEL]);

  const basePayload = { id: item.id };

  let submitPayload;
  if (isActionApproveOrCancel) {
    submitPayload = basePayload;
  } else {
    submitPayload = {
      ...basePayload,
      ...payload,
      version: item.version,
    };

    if (submitPayload.fundingAccount) {
      submitPayload.fundingAccount = {
        id: submitPayload.fundingAccount,
      };
    }

    // Build line items
    submitPayload.lineItems = buildSubmitExistingItemLineItems(item, submitPayload);

    if (submitPayload.dateScheduled) {
      submitPayload.dateScheduled = submitPayload.dateScheduled.format('YYYY-MM-DD');
    }
  }

  // Normalize payload
  const normalizedPayload = payloadToJSONAPI(submitPayload, 'item', 'fundingAccount', 'lineItems');

  normalizedPayload.meta = {
    // if we want to pass custom meta for a form, we do that first
    ...(payload?.meta ?? {}),
    // then we built the meta required by each ledger
    ...buildSubmitExistingItemMeta(item),
  };

  const t = new ThunkFetch({
    actions: {
      error: SUBMIT_EXISTING_ITEMS_FAILURE,
      request: SUBMIT_EXISTING_ITEMS_REQUEST,
      success: SUBMIT_EXISTING_ITEMS_SUCCESS,
    },
    endpoint: getSubmitExistingItemEndpoint(partnershipId, item.id, action),
    method: 'POST',
    payload: normalizedPayload,
    requireAuth: true,
    setRequester: true,
    successCallback,
  });

  return t.makeRequest();
};

export default submitExistingItemOld;

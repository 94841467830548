import { createErrorReducer } from 'store/redux';

import {
  UPDATE_ONBOARDING_COMPANY_FAILURE,
  UPDATE_ONBOARDING_COMPANY_REQUEST,
  UPDATE_ONBOARDING_COMPANY_SUCCESS,
  UPDATE_ONBOARDING_COMPANY_TYPE_SUCCESS,
} from 'types/currentCompany';

export const successCases = [
  UPDATE_ONBOARDING_COMPANY_REQUEST,
  UPDATE_ONBOARDING_COMPANY_SUCCESS,
  UPDATE_ONBOARDING_COMPANY_TYPE_SUCCESS,
];
export const failureCases = [UPDATE_ONBOARDING_COMPANY_FAILURE];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

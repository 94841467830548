/**
 * @module reducers/tablesReducer/configsReducer/createItemsPayableReducer
 */

import { fetchItemStructureTablesRoutine } from 'actions/routines/tables';

import { tableNamesCreateItems } from 'constants/tables';

import initialState from './initialState';

const createItemsPayableReducer = (state = initialState.configs[tableNamesCreateItems.PAYABLE], action) => {
  switch (action.type) {
    case fetchItemStructureTablesRoutine.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case fetchItemStructureTablesRoutine.FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case fetchItemStructureTablesRoutine.SUCCESS:
      return {
        ...state,
        isLoading: false,
        schema: action.payload[tableNamesCreateItems.PAYABLE],
        payableDetailsSchema: action.payload.payableDetailsSchema,
        payableDetailsTableSchema: action.payload.payableDetailsTableSchema,
      };

    default:
      return state;
  }
};

export default createItemsPayableReducer;

import { lengthOf } from 'helpers/utility';

/**
 * Builds classNames objects for form field input wrappers.
 * @param props {{ addon?: any, className?: string, isDisabled?: boolean }} Input props object
 * @param options {?{ hasErrors?: boolean, [string]: * }} Additional options
 * @returns {{ widthClasses: Object, otherClasses: Object }} Two classNames objects.
 */
export const getInputClasses = ({ addon, className = '', isDisabled }, options = {}) => {
  const numOfAddons = lengthOf(addon?.props.children);
  const otherClasses = {
    error: options.hasErrors,
    disabled: isDisabled,
    'has-addon': !!addon,
    [`has-addon--${numOfAddons}`]: !!numOfAddons,
  };
  const widthClasses = {};

  // split classes to width and other
  className.split(' ').forEach((cls) => {
    // Regex magic to strip responsive prefixes, like md:, lg:, sm:, etc.
    // We want to treat responsive classes the same as regular ones when
    // deciding if a class is a "width" class or not.
    const candidate = cls.replace(/\b[a-z]{2}:(?!-)\b/, '');

    if (candidate.startsWith('w-') || candidate.startsWith('right')) {
      widthClasses[cls] = true;
    } else {
      otherClasses[cls] = true;
    }
  });

  return { widthClasses, otherClasses };
};

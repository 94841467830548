import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {} from 'interfaces/global';
import { companyNameSelector } from 'selectors/quickswitchSelectors';
import QuickswitchSplashscreen from './QuickswitchSplashscreen';
const mapStateToProps = createStructuredSelector({
    companyName: companyNameSelector,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(QuickswitchSplashscreen);

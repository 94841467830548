import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

import { BottomSheetHeader } from './components';

/**
 * Component rendering React Select menu as Bottom Sheet. Used for displaying
 * select options on mobile devices.
 * @param {ComponentProps} props
 * @param {Node} props.children
 * @param {object} props.selectProps
 * @param {RestOfProps} props.rest
 * @return {StatelessComponent}
 */
const BottomSheetMenu = ({ children, selectProps, ...rest }) => (
  <components.Menu {...rest} className="Select__menu--bottom-sheet">
    <BottomSheetHeader {...selectProps} />
    {children}
  </components.Menu>
);

BottomSheetMenu.propTypes = {
  children: PropTypes.node.isRequired,
  selectProps: PropTypes.shape({
    inputValue: PropTypes.string,
    isSearchable: PropTypes.bool,
    label: PropTypes.string,
    onMenuClose: PropTypes.func,
  }).isRequired,
};

export default BottomSheetMenu;

import { fetchItemsRoutine, submitItemRoutine } from 'actions/routines/item';

import { createErrorReducer } from 'store/redux';

import * as types from 'types/item';
import {
  FETCH_PARTNERSHIP_ITEMS_FAILURE,
  FETCH_PARTNERSHIP_ITEMS_REQUEST,
  FETCH_PARTNERSHIP_ITEMS_SUCCESS,
} from 'types/partnership';

export const failureCases = [
  fetchItemsRoutine.FAILURE,
  FETCH_PARTNERSHIP_ITEMS_FAILURE,
  submitItemRoutine.FAILURE,
  types.FETCH_ITEM_FAILURE,
  types.SUBMIT_EXISTING_ITEMS_FAILURE,
  types.UPDATE_EXISTING_ITEMS_FAILURE,
];

export const successCases = [
  fetchItemsRoutine.REQUEST,
  fetchItemsRoutine.SUCCESS,
  FETCH_PARTNERSHIP_ITEMS_REQUEST,
  FETCH_PARTNERSHIP_ITEMS_SUCCESS,
  submitItemRoutine.REQUEST,
  submitItemRoutine.FULFILL,
  types.CLEAR_SUBMITTED_ITEM,
  types.FETCH_ITEM_AND_THREAD_REQUEST,
  types.FETCH_ITEM_REQUEST,
  types.FETCH_ITEM_SUCCESS,
  types.SUBMIT_EXISTING_ITEMS_REQUEST,
  types.SUBMIT_EXISTING_ITEMS_SUCCESS,
  types.UPDATE_EXISTING_ITEMS_REQUEST,
  types.UPDATE_EXISTING_ITEMS_SUCCESS,
];

const itemsErrorReducer = createErrorReducer(successCases, failureCases);

export default itemsErrorReducer;

import { createSelector } from 'reselect';

import { currentUserSelector } from 'selectors/currentUserSelectors';

import { membershipFromPartnershipRequestSelector } from './membershipCompoundSelectors';

/**
 * Returns the initial values used in Partner Company Contact form
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @return {object} - Initial values
 */
export const partnerCompanyContactFormInitialValuesSelector = createSelector(
  [currentUserSelector, membershipFromPartnershipRequestSelector],
  (currentUser, partnershipRequestMembership) => ({
    membership: {
      user: {
        email: currentUser.email,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
      },
      phoneNumber: partnershipRequestMembership.phoneNumber,
    },
  }),
);

import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _set from 'lodash/set';

import { isTimeOnlyValue } from 'helpers/date';
import { processLineItems } from 'helpers/lineItems';
import { dashTextToCamelCase } from 'helpers/stringHelpers';
import { isObject, loopOverKeys } from 'helpers/utility';

/**
 * Turn an under_score_key into camelCase with special-casing for extended fields.
 *
 * @param {string} key
 * @returns {string}
 */
export const getCamelCaseKey = (key) => {
  let intermediaryKey = key;

  // if the key is part of an extended field, like `extended_customer_ref`
  if (key.match('extended_')) {
    // replace underscore with dot: `extended.customer_ref`
    intermediaryKey = key.replace('extended_', 'extended.').replace('_value', '.value');
  }

  // change the underscore_key to camelCase
  // line_items => lineItems
  // extended.customer_ref => extended.customerRef
  return dashTextToCamelCase(intermediaryKey);
};

/**
 * To set data when we get to the bottom of the tree, we need to keep track of the path. At the root, there is no path;
 * as we get layers down, we add a dot . between the existing path and the new path part.
 *
 * @param {StringMaybe} [existingPath]
 * @param {string} newPathPart
 * @returns {string}
 */
export const getNextPathForWalk = (existingPath, newPathPart) =>
  existingPath ? `${existingPath}.${newPathPart}` : newPathPart;

/**
 * When we're not setting data in `parseItemIntoInitialValues`, we need to recurse more deeply. This function sets up
 * the arguments for the next recurse.
 *
 * @param {OptionsArg} options
 * @param {string} options.newPathKey
 * @param {StringMaybe} [options.path]
 * @param {*} options.value
 * @returns {{ path: string, key: string, value: * }}
 */
export const getNextWalkStepConfigForParseItemIntoInitialValues = ({ newPathKey, path, value }) => ({
  key: newPathKey,
  path: getNextPathForWalk(path, newPathKey),
  value: value[newPathKey],
});

/**
 * Format a value from item so that it is usable by redux-form.
 *
 * This a semi-replacement for Tablematic's getSectionSingleFieldOutput helper. getSectionSingleFieldOutput has a lot of
 * metadata it gives to jsonLogic.apply to format field values, but we don't have that metadata in item edit. As a
 * result this function is just parsing edge cases, like time without date.
 *
 * @param {*} value
 * @returns {*}
 */
export const formatFieldValue = (value) => {
  // time fields look like '12:24:01'
  if (isTimeOnlyValue(value)) {
    // It needs to be a full date string before we send it to the form. The day will be stripped by Tablematic later
    return `1900-01-01T${value}`;
  }

  return value;
};

/**
 * Get and format a field value at the camelCaseKey location.
 *
 * @param {string} camelCaseKey - Full camelCasePath of the location where we expect to find the value
 * @param {Item} item
 * @returns {*}
 */
export const getItemFieldValue = (camelCaseKey, item) => formatFieldValue(_get(item, camelCaseKey));

/**
 * Using the initialValues from the viewModelManager, extract data from the item and convert it into a format we can use
 * to prefill the createItem form (when used as part of the Item Edit flow).
 * This function is a wrapper around a recursive function.
 *
 * Ideally, this function is the opposite of Tablematic's getDynamicTableOutput.
 *
 * @param {OptionsArg} options
 * @param {Object} options.initialValues
 * @param {item} options.item
 * @param {LedgerTaxCode[]} options.allLedgerTaxCodes
 * @returns {Object}
 */
export const generateInitialValuesFromItem = ({ initialValues, item, allLedgerTaxCodes }) => {
  // clone the initialValues arg to decouple it so we don't mutate the input
  const clonedInitialValues = _cloneDeep(initialValues);

  /**
   * Recursive function to walk down the keys in initial values. Once it gets to a key-value pair that contains settable
   * data, it sets the data at the correct path to prefill the form.
   *
   * @param {OptionsArg} options
   * @param {string} options.key - key location in the object
   * @param {string} [options.path=''] - As we walk deeper into the tree, we keep track of the path
   * @param {*} options.value - the value at the key location in the object
   */
  const parseItemIntoInitialValues = ({ key, path = '', value }) => {
    // if the value is an array with the key matching line_items_*, then we're in line items
    if (Array.isArray(value) && key?.match('line_items_')) {
      // the line items from initialValues are blank, which we can use to extract data from the item
      const defaultLineItem = value[0];
      // using the defaultLineItem, format item.lineItems
      const newLineItems = processLineItems({
        defaultLineItem,
        key,
        item,
        allLedgerTaxCodes,
      });
      _set(clonedInitialValues, path, newLineItems);
      return;
    }

    // if the value is a object
    if (isObject(value)) {
      // continue recursing down through the object to get to settable data, keeping track of the path as we do so,
      // which allows us to know where to set the data once we've reached the bottom of the tree
      loopOverKeys(value, (newPathKey) =>
        parseItemIntoInitialValues(
          getNextWalkStepConfigForParseItemIntoInitialValues({
            newPathKey,
            path,
            value,
          }),
        ),
      );
      return;
    }

    // we've reach a key-value pair that contains data and no longer needs to be walked down!

    // turn the under_score_key into camelCase
    const camelCaseKey = getCamelCaseKey(key);

    // get the value from the item[camelCase]. This is what we're going to fill into initialValues
    const itemValue = getItemFieldValue(camelCaseKey, item);

    _set(clonedInitialValues, path, itemValue);
  };

  // kick off the parsing for the first time, at the very root
  parseItemIntoInitialValues({ value: initialValues });

  // when connected to Xero, RCTMs can select a Tax field labeled "Amounts are" (for eg, "exclusive" or "inclusive")
  // in this case, we need to map item.extended.taxStyle back to formState.main.general.tax_style to pre-fill the form
  _set(clonedInitialValues, 'main.general.tax_style.id', item.extended?.taxStyle);

  // return a version of initialValues with prefilled data from item
  return clonedInitialValues;
};

import { getSingleBulkImportRoutine, uploadBulkImportFileRoutine } from 'actions/routines/bulkActions';

import { parseBulkActions } from 'data/parse';

import { initialValuesBulkActions } from 'helpers/initialValues';

const bulkImportItemsFormReducer = (state = undefined, action) => {
  if (!state?.values) {
    return state;
  }

  switch (action.type) {
    case uploadBulkImportFileRoutine.FAILURE:
      return {
        ...state,
        values: initialValuesBulkActions.getBulkImportItemsFormInitialValues(),
      };

    case uploadBulkImportFileRoutine.TRIGGER:
      return parseBulkActions.imports.getNextBulkImportItemsFormStateOnUploadTrigger(state);

    case uploadBulkImportFileRoutine.SUCCESS:
      return parseBulkActions.imports.getNextBulkImportItemsFormStateOnUploadSuccess(state, action);

    case getSingleBulkImportRoutine.SUCCESS:
      return parseBulkActions.imports.getNextBulkImportItemsFormStateOnFetchSingleImportSuccess(state, action);

    default:
      return state;
  }
};

export default bulkImportItemsFormReducer;

import PropTypes from 'prop-types';
import React from 'react';

const PanelBodyDescription = ({ children }) => <p className="panel-body--description margin-bottom--m">{children}</p>;

PanelBodyDescription.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PanelBodyDescription;

import PropTypes from 'prop-types';
import React from 'react';
import swal from 'sweetalert';

import { ButtonV2 } from 'components/buttonV2';
import { IconNames } from 'components/icon';
import { Image } from 'components/media';
import { Text } from 'components/text';

import { ButtonSize, ButtonText } from 'constants/button';
import { sizes } from 'constants/styles';
import { TextColor, TextSize } from 'constants/styles/typography';
import { Intent } from 'constants/ui';

import { getSwalFooterClassname } from 'helpers/swal';

import { CustomerServiceHelper } from 'modules/customerSupport/CustomerService';

import ServerErrorDefaultMessage from '../ServerErrorDefaultMessage';
import ServerErrorDefaultTitle from '../ServerErrorDefaultTitle';
import ServerErrorRequestId from '../ServerErrorRequestId';

/**
 * Component rendering an error alert SWAL.
 * @param {ComponentProps} props
 * @param {*} props.errorMessage
 * @param {string} props.imageSrc
 * @param {string} props.requestId
 * @param {string} props.title
 * @return {StatelessComponent}
 */
const ErrorAlertSwal = ({ errorMessage, imageSrc, requestId, title }) => {
  const onCloseSwal = () => {
    swal.close();
  };

  const onContactSupport = () => {
    CustomerServiceHelper.show();
    onCloseSwal();
  };

  return (
    <div className="swal-wrapper">
      <div className="swal-wrapper__heading">
        {imageSrc && (
          <div className="alert-error-image margin-bottom--m-large">
            <Image alt="Error alert graphic" src={imageSrc} />
          </div>
        )}

        <div className="swal-wrapper__heading--title">
          <Text.Bold color={TextColor.BLUE_DARK} size={TextSize.LEVEL_400}>
            {title || <ServerErrorDefaultTitle />}
          </Text.Bold>
        </div>
      </div>

      <div className="swal-wrapper__content">
        <Text.Regular color={TextColor.GREY_XX_DARK} size={TextSize.LEVEL_200}>
          {errorMessage || <ServerErrorDefaultMessage />}
        </Text.Regular>

        <div className="margin-bottom--m">{requestId && <ServerErrorRequestId>{requestId}</ServerErrorRequestId>}</div>
      </div>

      <div className={getSwalFooterClassname(Intent.DANGER, 'swal-wrapper__footer')}>
        <ButtonV2
          intent={Intent.NEUTRAL}
          leftIconClassName="margin-right--xm"
          leftIconName={IconNames.CHAT}
          leftIconSize={sizes.iconSizes.EXTRA_MEDIUM}
          onClick={onContactSupport}
          size={ButtonSize.SMALL}
        >
          {ButtonText.CONTACT_SUPPORT}
        </ButtonV2>

        <ButtonV2
          intent={Intent.DANGER}
          leftIconClassName="margin-right--xm"
          onClick={onCloseSwal}
          size={ButtonSize.SMALL}
        >
          {ButtonText.CLOSE}
        </ButtonV2>
      </div>
    </div>
  );
};

ErrorAlertSwal.propTypes = {
  errorMessage: PropTypes.node,
  imageSrc: PropTypes.string,
  requestId: PropTypes.string,
  title: PropTypes.node,
};

ErrorAlertSwal.defaultProps = {
  errorMessage: undefined,
  imageSrc: undefined,
  requestId: undefined,
  title: undefined,
};

export default ErrorAlertSwal;

import { createSelector } from 'reselect';

import { TABLE_DEFAULT_PAGE_SIZE } from 'constants/tables';

import { allValues } from 'helpers/utility';

const getState = (state) => state.invoices;

/**
 * Selects errors for invoices from the state
 * @param {object} state - Redux state
 */
export const invoicesErrorsSelector = createSelector([getState], (state) => state.errors);

/**
 * Selects if fetching state for invoices from the state
 * @param {object} state - Redux state
 */
export const isFetchingInvoicesSelector = createSelector([getState], (state) => state.isFetching);

/**
 * Selects the lastUpdated date for invoices from the state
 * @param {object} state - Redux state
 */
export const lastUpdatedInvoicesSelector = createSelector([getState], (state) => state.lastUpdated);

/**
 * Selects if submitting state for invoices from the state
 * @param {object} state - Redux state
 */
export const isSubmittingInvoicesSelector = createSelector([getState], (state) => state.isSubmitting);

/**
 * Selects the lastUpdated date for invoices from the state
 * @param {object} state - Redux state
 */
export const lastSubmittedInvoicesSelector = createSelector([getState], (state) => state.lastSubmitted);

/**
 * Selects all the invoices from the state
 * @param {object} state - Redux state
 */
export const invoicesSelector = createSelector([getState], (state) => state.byId);

/**
 * Selects all the invoices from the state
 * @param {object} state - Redux state
 * @return array
 */
export const allInvoicesSelector = createSelector([invoicesSelector], (invoices) => allValues(invoices));

/**
 * Selects links about the invoices from the state
 * @param {object} state - Redux state
 */
export const invoicesLinksSelector = createSelector([getState], (state) => state.links);

/**
 * Selects meta about the invoices from the state
 * @param {object} state - Redux state
 */
export const invoicesMetaSelector = createSelector([getState], (state) => state.meta);

/**
 * Selects invoices.meta.pagination from state.
 * @type {StandardSelector}
 * @returns {Object}
 */
export const invoicesMetaPaginationSelector = createSelector([invoicesMetaSelector], (meta) => meta?.pagination);

/**
 * Selects invoices.meta.pagination.size from state.
 * @type {StandardSelector}
 * @returns {number}
 */
export const invoicesMetaPaginationSizeSelector = createSelector(
  [invoicesMetaPaginationSelector],
  (pagination) => pagination?.size ?? TABLE_DEFAULT_PAGE_SIZE,
);

/**
 * Selects size/count of invoices returned from the server
 * @param {object} state - Redux state
 */
export const invoicesCountSelector = createSelector([invoicesMetaSelector], (invoicesMeta) => {
  if (!invoicesMeta) {
    return 0;
  }

  return invoicesMeta.size;
});

/**
 * Selects a only a subset of the invoices which have been recently submitted
 * @param {object} state - Redux state
 */
export const submittedInvoicesSelector = createSelector(
  [allInvoicesSelector, invoicesMetaSelector],
  (invoices, invoicesMeta) => {
    if (!invoicesMeta?.ledgerRefs) {
      return [];
    }

    return invoices.filter((invoice) => invoicesMeta.ledgerRefs.includes(invoice.ledgerRef));
  },
);

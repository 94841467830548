import { Environment, EnvVariable } from 'constants/env';

import { isEqual } from 'helpers/utility';

export const getEnvVariable = (varName) => process.env[varName];

/**
 * Returns the env variable with the given name. Falls back to the given defaultValue.
 * @param {string} varName
 * @param {*} defaultValue
 * @return {string|*}
 */
export const getEnvVariableOrDefault = (varName, defaultValue = undefined) => getEnvVariable(varName) || defaultValue;

/**
 * Returns whether the current env is development.
 * @return {boolean}
 */
export const isNodeEnvDevelopment = () => isEqual(getEnvVariable(EnvVariable.NODE_ENV), Environment.DEVELOPMENT);

/**
 * Returns whether the current env is sandbox.
 * @return {boolean}
 */
export const isNodeEnvSandbox = () => isEqual(getEnvVariable(EnvVariable.NODE_ENV), Environment.SANDBOX);

/**
 * Returns whether the current env is production.
 * @return {boolean}
 */
export const isNodeEnvProduction = () => isEqual(getEnvVariable(EnvVariable.NODE_ENV), Environment.PRODUCTION);

/**
 * Returns whether the current env is test.
 * @return {boolean}
 */
export const isNodeEnvTest = () => isEqual(getEnvVariable(EnvVariable.NODE_ENV), Environment.TEST);

/**
 * Returns whether the current env is production AND devtools are turned on (aka, we're testing a compiled build).
 * @return {boolean}
 */
export const areProductionDevtoolsEnabled = () => isEqual(getEnvVariable(EnvVariable.ENABLE_PRODUCTION_DEVTOOLS), '1');

/**
 * Returns whether devtools are turned on in the current setting.
 * @return {boolean}
 */
export const areDevtoolsEnabled = () => !isNodeEnvProduction() || areProductionDevtoolsEnabled();

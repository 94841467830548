import { changePasswordRoutine } from 'actions/routines/auth';

import { AuthActionType } from 'enums/auth';

import { gatherReducerCasesFromRoutines } from 'helpers/routine';

import { createLastSubmittedReducer } from 'store/redux';

const { failureCases, successCases: scsCases, requestCases } = gatherReducerCasesFromRoutines([changePasswordRoutine]);

export const requestOrFailureCases = [
  ...failureCases,
  ...requestCases,
  AuthActionType.SUBMIT_CHANGE_PASSWORD_FAILURE,
  AuthActionType.SUBMIT_CHANGE_PASSWORD_REQUEST,
];
export const successCases = [...scsCases, AuthActionType.SUBMIT_CHANGE_PASSWORD_SUCCESS];

export default createLastSubmittedReducer(successCases, requestOrFailureCases);

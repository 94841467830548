import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const ListItemLabel = ({ children, className, style }) => (
  <div
    className={classNames({
      'font-color--dark-jordan': true,
      'font-xxs': true,
      'list-item-label': true,
      semibold: true,
      [className]: !!className,
    })}
    style={style}
  >
    {children}
  </div>
);

ListItemLabel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.shape(),
};

ListItemLabel.defaultProps = {
  className: undefined,
  children: undefined,
  style: {},
};

export default ListItemLabel;

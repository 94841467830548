import { deepMergeWithArrayReplacement } from 'helpers/transform';
import { allKeys, getObjectDiff } from 'helpers/utility';

import getRelationships from 'store/redux';

/**
 * Parses an API response to format notification settings objects by id,
 * with formatted attributes and relationships.
 * @param {Object} notificationSettingsPayload
 * @return {ByIdCollection}
 */
export const getNotificationSettingsById = (notificationSettingsPayload) => {
  const notificationSettingsMap = {};

  allKeys(notificationSettingsPayload).forEach((notificationSettingsId) => {
    const relationships = getRelationships(notificationSettingsPayload, notificationSettingsId, 'emailForwards');

    notificationSettingsMap[notificationSettingsId] = {
      id: notificationSettingsPayload[notificationSettingsId].id,
      ...notificationSettingsPayload[notificationSettingsId].attributes,
      ...relationships,
    };
  });

  return notificationSettingsMap;
};

/**
 * Returns parsed, formatted notification settings by id, merged with any pre-existing state, and
 * with formatted attributes and relationships.
 * @param {ByIdCollection} notificationSettingsByIdState
 * @param {Object} notificationSettingsPayload
 * @return {ByIdCollection}
 */
export const getNotificationSettingsByIdAfterFetch = (notificationSettingsByIdState, notificationSettingsPayload) => {
  const parsedPayload = getNotificationSettingsById(notificationSettingsPayload);
  return deepMergeWithArrayReplacement(notificationSettingsByIdState, parsedPayload);
};

/**
 * Returns parsed, formatted notification settings by id, merged with any pre-existing state, but
 * with the new values of changed properties.
 * @param {ByIdCollection} notificationSettingsByIdState
 * @param {Object} notificationSettingsPayload
 * @return {ByIdCollection}
 */
export const getNotificationSettingsByIdAfterUpdateSuccess = (
  notificationSettingsByIdState,
  notificationSettingsPayload,
) => {
  const newNotificationSettings = getNotificationSettingsById(notificationSettingsPayload);
  const updates = getObjectDiff(notificationSettingsByIdState, newNotificationSettings);
  const updatedNotificationSettings = deepMergeWithArrayReplacement(newNotificationSettings, updates);
  return deepMergeWithArrayReplacement(notificationSettingsByIdState, updatedNotificationSettings);
};

/**
 * Returns parsed, formatted notification settings, adding the new email forward
 * to the its emailForwards array.
 * @param {ByIdCollection} notificationSettingsByIdState
 * @param {Object} notificationSettingsEmailForwardPayload
 * @return {ByIdCollection}
 */
export const getNotificationSettingsByIdAfterEmailForwardSubmit = (
  notificationSettingsByIdState,
  notificationSettingsEmailForwardPayload,
) => {
  const submittedEmailForwardIds = allKeys(notificationSettingsEmailForwardPayload);
  const settingsIds = allKeys(notificationSettingsByIdState);

  return settingsIds.reduce((obj, notificationSettingsId) => {
    const prevEmailForwards = notificationSettingsByIdState[notificationSettingsId].emailForwards || [];

    return {
      ...obj,
      [notificationSettingsId]: {
        ...notificationSettingsByIdState[notificationSettingsId],
        emailForwards: [...prevEmailForwards, ...submittedEmailForwardIds],
      },
    };
  }, {});
};

/**
 * Returns parsed, formatted notification settings, removing the deleted email forward id
 * from the emailForwards array.
 * @param {ByIdCollection} notificationSettingsByIdState
 * @param {string} deletedEmailForwardId
 * @return {ByIdCollection}
 */
export const getNotificationSettingsByIdAfterEmailForwardDelete = (
  notificationSettingsByIdState,
  deletedEmailForwardId,
) => {
  const settingsIds = allKeys(notificationSettingsByIdState);

  return settingsIds.reduce((obj, notificationSettingsId) => {
    const prevEmailForwards = notificationSettingsByIdState[notificationSettingsId].emailForwards || [];

    return {
      ...obj,
      [notificationSettingsId]: {
        ...notificationSettingsByIdState[notificationSettingsId],
        emailForwards: prevEmailForwards.filter((id) => id !== deletedEmailForwardId),
      },
    };
  }, {});
};

import { combineReducers } from 'redux';

import { DeactivateTeamMemberModalField } from 'constants/formFields';

import errorReducer from './errorReducer';
import membershipReducer from './membershipReducer';
import openReducer from './openReducer';

/**
 * @type {Reducer}
 * @returns {DeactivateTeamMemberModalState}
 */
const deactivateTeamMemberReducer = combineReducers({
  [DeactivateTeamMemberModalField.ERRORS]: errorReducer,
  [DeactivateTeamMemberModalField.MEMBERSHIP]: membershipReducer,
  open: openReducer,
});

export default deactivateTeamMemberReducer;

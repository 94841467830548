import { getPaymentOrInvoiceTextWithArticle } from 'helpers/items';
import { parseCurrency } from 'helpers/numbers';

/**
 * There are a lot of copy micro-variations in the SelectApproverText component. This function extracts the logic of those
 * variations and returns the pieces to the component for construction.
 * @param {Object} options
 * @param {ItemKinds} options.itemKind
 * @param {number} options.position
 * @param {string|number} options.thresholdAmount
 * @returns {object}
 */
export const getSelectApproverTextOptions = ({ itemKind, position, thresholdAmount }) => {
  const requirePrefix = position === 1 ? 'Require' : 'Then, require';
  const paymentOrInvoice = getPaymentOrInvoiceTextWithArticle({ kind: itemKind });
  const forMultipleInvoicesSuffix = ` when ${paymentOrInvoice} is over ${parseCurrency(thresholdAmount)}`;

  return {
    requirePrefix,
    forMultipleInvoicesSuffix,
  };
};

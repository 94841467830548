import { isFn, isNum } from 'helpers/utility';

/**
 * Returns the given number of items in parenthesis.
 * @param {number} numberOfItems
 * @return {string|boolean}
 */
export const getFormattedNumberOfItems = (numberOfItems) => isNum(numberOfItems) && `(${numberOfItems})`;

/**
 * Determines whether or not the ActionNavTab is currently the one selected.
 *
 * @param {OptionsArg} options
 * @param {string[]} [options.alternatePaths]
 * @param {function} [options.getIsSelected]
 * @param {string} options.pathname
 * @param {string} options.tabLink
 * @returns {boolean}
 */
export const handleIsSelected = ({ alternatePaths, getIsSelected, pathname, tabLink }) => {
  if (isFn(getIsSelected)) {
    return getIsSelected({ pathname, tabLink });
  }

  return Boolean(pathname.startsWith(tabLink) || alternatePaths?.includes(pathname));
};

import { LedgerSides } from 'constants/ledger';

import { REST } from 'helpers/api';

import {
  getDisconnectIntegrationConfigEndpoint,
  getIntegrationConfigEndpoint,
  getIntegrationConfigRefreshEndpoint,
  getIntegrationLedgerMatchingEndpoint,
  getIntegrationOAuthRedirectEndpoint,
  integrationConfigsEndpoint,
  integrationsEndpoint,
} from 'services/api/integrationEndpoints';

/**
 * Fetch active integrations.
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchIntegrations = async (options = {}) =>
  REST.get({
    ...options,
    endpoint: integrationsEndpoint,
    requireAuth: true,
  });

/**
 * Fetch active integration configs.
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchIntegrationConfigs = async (options = {}) =>
  REST.get({
    ...options,
    endpoint: integrationConfigsEndpoint,
    requireAuth: true,
  });

/**
 * Fetch a specific integration config.
 * @param {string} integrationId
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchSingleIntegrationConfig = async (integrationId, options = {}) =>
  REST.get({
    ...options,
    endpoint: getIntegrationConfigEndpoint(integrationId),
    requireAuth: true,
  });

/**
 * Fetch platform-side partnerships.
 * @param {string} integrationConfigId
 * @param {Object} filter
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchIntegrationPlatformPartnerships = async (integrationConfigId, filter, options = {}) => {
  const endpoint = getIntegrationLedgerMatchingEndpoint(integrationConfigId, 'partnerships', {
    ...filter,
    side: LedgerSides.PLATFORM,
  });
  return REST.get({
    ...options,
    endpoint,
    requireAuth: true,
  });
};

/**
 * Fetch ledger-side partnerships.
 * @param {string} integrationConfigId
 * @param {Object} filter
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchIntegrationLedgerPartnerships = async (integrationConfigId, filter, options = {}) => {
  const endpoint = getIntegrationLedgerMatchingEndpoint(integrationConfigId, 'partnerships', {
    ...filter,
    side: LedgerSides.LEDGER,
  });
  return REST.get({
    ...options,
    endpoint,
    requireAuth: true,
  });
};

/**
 * Fetch platform-side funding accounts.
 * @param {string} integrationConfigId
 * @param {Object} filter
 * @param {string} side
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchLedgerUnmatchedFundingAccounts = async (integrationConfigId, filter, side, options = {}) => {
  const endpoint = getIntegrationLedgerMatchingEndpoint(integrationConfigId, 'funding_accounts', {
    ...filter,
    side,
  });
  return REST.get({
    ...options,
    endpoint,
    requireAuth: true,
  });
};

/**
 * Search all funding accounts.
 * @param {string} integrationConfigId
 * @param {string} searchTerm
 * @return {Promise<*>}
 */
export const searchFundingAccounts = async (integrationConfigId, searchTerm) => {
  const endpoint = getIntegrationLedgerMatchingEndpoint(integrationConfigId, 'funding_accounts', {
    search: searchTerm,
    side: LedgerSides.ALL,
  });
  return REST.get({
    endpoint,
    requireAuth: true,
  });
};

/**
 * Fetch ledger-side funding accounts.
 * @param {string} integrationConfigId
 * @param {Object} filter
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchLedgerUnmatchedLedgerFundingAccounts = async (integrationConfigId, filter, options = {}) =>
  fetchLedgerUnmatchedFundingAccounts(integrationConfigId, filter, LedgerSides.LEDGER, options);

/**
 * Fetch platform-side funding accounts.
 * @param {string} integrationConfigId
 * @param {Object} filter
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchLedgerUnmatchedPlatformFundingAccounts = async (integrationConfigId, filter, options = {}) =>
  fetchLedgerUnmatchedFundingAccounts(integrationConfigId, filter, LedgerSides.PLATFORM, options);

/**
 * POST to disconnect an integration.
 * @param {string} integrationId
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const disconnectIntegrationConfig = async (integrationId, options = {}) =>
  REST.post({
    ...options,
    endpoint: getDisconnectIntegrationConfigEndpoint(integrationId),
    id: integrationId,
    requireAuth: true,
  });

/**
 * Fetch data for connecting a third-party OAuth integration.
 * @param {Object} integration
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const connectIntegrationOAuth = async (integration, options = {}) =>
  REST.get({
    ...options,
    endpoint: getIntegrationOAuthRedirectEndpoint(integration.application),
    // if integration config is passed the id needed is the integration id
    id: integration.integration || integration.id,
    requireAuth: true,
  });

/**
 * Refresh data for an integration.
 * @param {string} integrationConfigId
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const refreshIntegrationConfig = async (integrationConfigId, options = {}) =>
  REST.post({
    ...options,
    endpoint: getIntegrationConfigRefreshEndpoint(integrationConfigId),
    id: integrationConfigId,
    requireAuth: true,
  });

/**
 * Here we make a request to integration endpoint.
 * The payload could contain cancel case or the integration form data.
 * @param {string} endpoint
 * @param {ObjectMaybe} payload
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const connectIntegrationApi = async (endpoint, payload = {}, options = {}) =>
  REST.post({
    ...options,
    endpoint,
    payload,
    removeAuthToken: true,
    accept: 'application/json',
    overrideHeaders: {
      'Content-Type': 'application/json',
    },
  });

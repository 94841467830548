import PropTypes from 'prop-types';
import React from 'react';

import { assembleLedgerDetailsListItems, getClassNames } from 'helpers/ui';
import { hasZeroLength } from 'helpers/utility';

import DetailsListCard from '../DetailsListCard';

/**
 * DetailsListCard which shows details for ledger-connected dashboard users. Differentiates fields based on the
 * LedgerContactType.
 * @param {string} fullLedgerName
 * @param {LedgerSettings} ledgerSettings
 * @param {Partnership} partnership
 * @param {RestOfProps} rest
 * @returns {StatelessComponent}
 * @constructor
 */
const CompanyLedgerInfoCard = ({ fullLedgerName, ledgerSettings, partnership, ...rest }) => {
  const detailsListItems = assembleLedgerDetailsListItems(ledgerSettings, partnership);

  // not matched as a vendor or customer
  if (hasZeroLength(detailsListItems)) {
    return null;
  }

  return (
    <DetailsListCard
      className={getClassNames(rest)}
      dataFullStory
      detailsListItems={detailsListItems}
      title={fullLedgerName}
    />
  );
};

CompanyLedgerInfoCard.propTypes = {
  fullLedgerName: PropTypes.string.isRequired,
  ledgerSettings: PropTypes.shape({}).isRequired,
  partnership: PropTypes.shape({}).isRequired,
};

export default CompanyLedgerInfoCard;

import { DateInput, Select, Tag } from '@routable/gross-ds';
import clsx from 'clsx';
import dayjs from 'dayjs';
import _set from 'lodash/set';
import React from 'react';
import {} from '../../Filters.types';
import { operatorSelectOptions, tagPostText } from './DateFilter.constants';
import { dateOperatorModel, } from './DateFilter.types';
export const pillClasses = clsx([
    'py-1',
    'px-3',
    'rounded-full',
    'text-sm',
    'border',
    'bg-grey-5',
    'text-black',
    'border-grey-10',
]);
const Pill = ({ ...props }) => React.createElement("span", { className: pillClasses, ...props });
export const DateFilter = ({ maxDate, onChange, value: fieldValue = {
    operator: dateOperatorModel.Enum.GTE,
    value: undefined,
}, }) => {
    const handleOperatorChange = (operator) => {
        if (operator === dateOperatorModel.enum.BETWEEN) {
            onChange({
                ...fieldValue,
                operator,
                value: Array.isArray(fieldValue.value)
                    ? fieldValue.value
                    : [fieldValue.value ?? new Date(new Date().setHours(0, 0, 0, 0)), new Date(new Date().setHours(0, 0, 0, 0))],
            });
        }
        else {
            onChange({
                ...fieldValue,
                operator,
                value: Array.isArray(fieldValue.value) ? fieldValue.value[0] : fieldValue.value,
            });
        }
    };
    const createHandleDateValueChange = (fieldName) => (dateValue) => {
        if ((maxDate && dateValue > maxDate) ||
            (Array.isArray(fieldValue.value) &&
                ((fieldName === 'value.0' && dateValue > fieldValue.value[1]) ||
                    (fieldName === 'value.1' && dateValue < fieldValue.value[0])))) {
            return;
        }
        onChange(_set({ ...fieldValue }, fieldName, dateValue));
    };
    return (React.createElement("div", { className: "flex flex-wrap gap-3 items-center" },
        React.createElement(Select, { "aria-label": "Date - operator", name: "operator", onChange: handleOperatorChange, options: operatorSelectOptions, value: fieldValue.operator }),
        fieldValue.operator === dateOperatorModel.enum.BETWEEN && (React.createElement(React.Fragment, null,
            React.createElement(DateInput, { "aria-label": "Date - start filter", max: maxDate, name: "value.0", onChange: createHandleDateValueChange('value.0'), value: fieldValue.value?.[0] }),
            React.createElement(Pill, null, "to"),
            React.createElement(DateInput, { "aria-label": "Date - end filter", min: new Date(fieldValue.value[0]), max: maxDate, name: "value.1", onChange: createHandleDateValueChange('value.1'), value: fieldValue.value?.[1] }))),
        fieldValue.operator !== dateOperatorModel.enum.BETWEEN && (React.createElement(DateInput, { "aria-label": "Date", max: maxDate, name: "value", onChange: createHandleDateValueChange('value'), value: fieldValue.value }))));
};
export const getFormattedDate = (date) => dayjs(date).format('MM/DD/YYYY');
const Tags = ({ value: fieldValue, onRemove, size }) => {
    if (fieldValue?.operator === dateOperatorModel.enum.BETWEEN) {
        const startDate = getFormattedDate(fieldValue?.value?.[0]);
        const endDate = getFormattedDate(fieldValue?.value?.[1]);
        return (React.createElement(React.Fragment, null,
            React.createElement(Tag, { "data-testid": "tag:date-start", intent: "primary", size: size, onRemove: onRemove }, startDate),
            "to",
            React.createElement(Tag, { "data-testid": "tag:date-end", intent: "primary", size: size, onRemove: onRemove }, endDate)));
    }
    const dateValue = getFormattedDate(fieldValue?.value);
    const datePostText = tagPostText[fieldValue?.operator];
    return (React.createElement(React.Fragment, null,
        datePostText,
        React.createElement(Tag, { "data-testid": "tag:date", intent: "primary", size: size, onRemove: onRemove }, dateValue)));
};
DateFilter.Tags = Tags;

import { StaticCountryCode } from 'constants/countries';

import * as templates from 'data/validators/addressValidators/templates';

/**
 * Method to determine which country's validator to use.
 * If there's no match we return a validation function that will always pass.
 * @param country
 * @return {Array}
 */
export const getPostalCodeTemplate = (country) => {
  switch (country) {
    case StaticCountryCode.CA:
      return templates.caPostalCodeTemplates;

    case StaticCountryCode.US:
      return templates.usPostalCodeTemplates;

    default:
      return [];
  }
};

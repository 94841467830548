import { fetchItemsRoutine, sendSubmitBillToItemRequestRoutine, submitItemRoutine } from 'actions/routines/item';
import { deleteBillAttachmentRoutine } from 'actions/routines/itemAttachments';

import { attachmentRelationships } from 'constants/relationships';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import { SUBMIT_UPLOAD_BILL_SUCCESS } from 'types/bills';
import { FETCH_ITEM_SUCCESS } from 'types/item';
import { FETCH_PARTNERSHIP_ITEMS_SUCCESS } from 'types/partnership';

const attachmentsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case fetchItemsRoutine.SUCCESS:
    case FETCH_ITEM_SUCCESS:
    case FETCH_PARTNERSHIP_ITEMS_SUCCESS:
    case SUBMIT_UPLOAD_BILL_SUCCESS:
    case sendSubmitBillToItemRequestRoutine.SUCCESS:
    case submitItemRoutine.FULFILL:
      return deepMergeWithArrayReplacement(
        state,
        getObjectsByIdWithRelationships(action.payload.itemAttachment, attachmentRelationships),
      );

    case deleteBillAttachmentRoutine.SUCCESS:
      return Object.fromEntries(
        Object.entries(state).filter((attachmentEntry) => attachmentEntry[0] !== action.payload.attachment.id),
      );

    default:
      return state;
  }
};

export default attachmentsByIdReducer;

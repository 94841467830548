import React from 'react';
import { UpdatePaymentMethodFormInternational, UpdatePaymentMethodFormWithCheckboxAndHintsDomestic, } from './components';
import {} from './UpdatePaymentMethodFormWithCheckboxAndHints.types';
const UpdatePaymentMethodFormWithCheckboxAndHints = ({ isInternational, isUpdatePaymentMethodFlow, }) => {
    if (isInternational) {
        return React.createElement(UpdatePaymentMethodFormInternational, { isUpdatePaymentMethodFlow: isUpdatePaymentMethodFlow });
    }
    return React.createElement(UpdatePaymentMethodFormWithCheckboxAndHintsDomestic, { isUpdatePaymentMethodFlow: isUpdatePaymentMethodFlow });
};
export default UpdatePaymentMethodFormWithCheckboxAndHints;

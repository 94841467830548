import _find from 'lodash/find';

import { fetchedPartnershipFundingAccountRelationships } from 'constants/relationships';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

export const getPartnershipsFundingAccountsById = (state, partnershipFundingAccounts) => {
  const partnershipsById = getObjectsByIdWithRelationships(
    partnershipFundingAccounts,
    fetchedPartnershipFundingAccountRelationships,
  );

  return deepMergeWithArrayReplacement(state, partnershipsById);
};

export const getPartnershipsFundingAccountsByIdAfterPrimaryUpdate = (state, payload = {}) =>
  Object.keys(state).reduce((obj, id) => {
    if (id === payload.partnershipFundingAccountId) {
      // set the selected primary account to the new primary
      return {
        ...obj,
        [id]: {
          ...state[id],
          isPrimary: true,
        },
      };
    }

    // do not toggle isPrimary for fundingAccounts of a different currencyCode then the updated fundingAccount
    if (state[payload.partnershipFundingAccountId].currencyCode !== state[id].currencyCode) {
      return {
        ...obj,
        [id]: {
          ...state[id],
        },
      };
    }

    // set any others to not-primary
    return {
      ...obj,
      [id]: {
        ...state[id],
        isPrimary: false,
      },
    };
  }, {});

export const getPartnershipFundingAccountsAfterSendUpdateLink = (state, payload = {}) =>
  Object.keys(state).reduce((obj, id) => {
    const partnershipFundingAccount = state[id];
    const partnershipId = payload.id;

    if (partnershipFundingAccount.partnership === partnershipId) {
      // clear the primary funding account for this partnership
      return {
        ...obj,
        [id]: {
          ...state[id],
          isPrimary: false,
        },
      };
    }

    // keep other partnership data untouched
    return {
      ...obj,
      [id]: state[id],
    };
  }, {});

export const getPrimaryReceivableFundingAccount = (parsedResponse) => {
  const { partnershipFundingAccount } = parsedResponse;

  if (partnershipFundingAccount) {
    return _find(Object.values(partnershipFundingAccount), (fundingAccount) => fundingAccount.attributes.isPrimary);
  }

  return undefined;
};

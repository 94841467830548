import { getAllBulkImportsRoutine, getSingleBulkImportRoutine } from 'actions/routines/bulkActions';

import { createIsFetchingReducer } from 'store/redux';

export const finishCases = [
  getAllBulkImportsRoutine.FAILURE,
  getAllBulkImportsRoutine.SUCCESS,
  getSingleBulkImportRoutine.FAILURE,
  getSingleBulkImportRoutine.SUCCESS,
];
export const requestCases = [getAllBulkImportsRoutine.REQUEST, getSingleBulkImportRoutine.REQUEST];

const isFetchingReducer = createIsFetchingReducer(finishCases, requestCases);

export default isFetchingReducer;

import { DeepLinkType } from 'constants/deepLinks';
import { DEEP_LINK } from 'constants/routes';

import { getJoinedPathWithQueries } from 'helpers/routeHelpers';

/**
 * Constructs a url used to view files via deep link.
 * @param {string} fileUrl
 * @param {string} fileName
 * @param {Object} [params={}]
 * @param {string} [params.viewerTitle=fileName]
 */
export const getFileDeepLink = (fileUrl, fileName, params = {}) => {
  const { viewerTitle = fileName } = params;

  return getJoinedPathWithQueries(DEEP_LINK, {
    type: DeepLinkType.FILE,
    name: fileName,
    title: viewerTitle,
    url: fileUrl,
  });
};

import { FETCH_PARTNERSHIPS_EXPORT_FAILURE } from 'types/partnership';
import { NOTIFICATION_BAR_CLOSE, NOTIFICATION_BAR_OPEN } from 'types/ui';

export const initialState = {
  intent: undefined,
  message: undefined,
  visible: false,
};

export default function notificationBarReducer(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_BAR_OPEN:
      return {
        ...action.payload,
        visible: true,
      };

    case NOTIFICATION_BAR_CLOSE:
    case FETCH_PARTNERSHIPS_EXPORT_FAILURE:
      return {
        visible: false,
      };

    default:
      return state;
  }
}

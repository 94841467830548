import { createSlice, createSelector } from '@reduxjs/toolkit';
import {} from 'interfaces/redux';
const initialState = { isSubmitting: false };
const reduxSplitBill = createSlice({
    initialState,
    name: 'splitBill',
    reducers: {
        startIsSubmitting(state) {
            state.isSubmitting = true;
        },
        endIsSubmitting(state) {
            state.isSubmitting = false;
        },
    },
});
const getSplitBill = (reduxState) => reduxState.splitBill;
export const splitBillIsSubmittingSelector = createSelector([getSplitBill], (splitBill) => splitBill.isSubmitting);
export const { name, actions: { startIsSubmitting, endIsSubmitting }, reducer, } = reduxSplitBill;

import { IconNames } from 'components';

import { typography } from 'constants/styles';

export const getPermissionDisclosureListColor = (enabled) =>
  enabled ? typography.TextColor.GREY_XX_DARK : typography.TextColor.GREY_DARK;

export const getPermissionDisclosureListRowIconProps = (enabled) => {
  const color = getPermissionDisclosureListColor(enabled);
  const icon = enabled ? IconNames.SMALL_TICK : IconNames.SMALL_CROSS;

  return { color, icon };
};

import React from 'react';
import { IconNames } from 'components/icon';
import { TooltipMUI } from 'components/tooltip';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';
import { getDisplayName } from 'helpers/hoc';
import PaymentMethodDetailItemAction from '../../PaymentMethodDetailItemAction';
import { PaymentMethodDetailAction } from '../enums';
const withTooltipAction = (PaymentMethodDetailItem) => {
    const Component = (props) => {
        const newProps = { ...props };
        const { actions, actionsElements = [], tooltipContent } = props;
        const hasTooltip = actions.includes(PaymentMethodDetailAction.TOOLTIP);
        if (hasTooltip) {
            newProps.actionsElements = [
                ...actionsElements,
                React.createElement(TooltipMUI, { key: "tooltip-action", padding: TooltipPadding.SKINNY, placement: TooltipPlacement.TOP, title: tooltipContent },
                    React.createElement(PaymentMethodDetailItemAction, { icon: IconNames.TIME, key: "timestamp-action" })),
            ];
        }
        return React.createElement(PaymentMethodDetailItem, { ...newProps });
    };
    Component.displayName = `${getDisplayName(PaymentMethodDetailItem)}WithTooltipAction`;
    return Component;
};
export default withTooltipAction;

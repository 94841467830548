import * as routines from 'actions/routines/funding';
import { countryPaymentOption } from 'constants/relationships';
import { reduceKeys } from 'helpers/utility';
import {} from 'interfaces/actions';
import {} from 'interfaces/reducers';
const getCountryPaymentOptionsByCode = (supportedCountries) => {
    if (!supportedCountries) {
        return {};
    }
    const result = reduceKeys(supportedCountries, (obj, countryCodeId) => {
        const { attributes } = supportedCountries[countryCodeId];
        return {
            ...obj,
            [attributes.countryCode]: {
                ...attributes,
                id: countryCodeId,
            },
        };
    }, {});
    return {
        '': result.US,
        ...result,
    };
};
export const initialState = {};
const byCountryCodeReducer = (state = initialState, action) => {
    switch (action.type) {
        case routines.fetchSupportedCountriesRoutine.SUCCESS:
            return getCountryPaymentOptionsByCode(action.payload[countryPaymentOption]);
        default:
            return state;
    }
};
export default byCountryCodeReducer;

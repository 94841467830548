import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';
import { fetchItemsRoutine, sendSubmitBillToItemRequestRoutine, submitItemRoutine } from 'actions/routines/item';

import { allKeys } from 'helpers/utility';

import { FETCH_ITEM_SUCCESS } from 'types/item';
import * as types from 'types/partnership';

const allIdsReducer = (state = [], action) => {
  switch (action.type) {
    case fetchItemsRoutine.SUCCESS:
    case FETCH_ITEM_SUCCESS:
    case sendSubmitBillToItemRequestRoutine.SUCCESS:
    case submitItemRoutine.FULFILL:
    case types.FETCH_PARTNERSHIP_SUCCESS:
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
      return Array.from(new Set([...state, ...allKeys(action.payload.partnership)]));

    default:
      return state;
  }
};

export default allIdsReducer;

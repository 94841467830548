import { FetchService } from 'services';
import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { currentUserEndpoint, pingLogoutEndpoint } from 'services/api/userEndpoints';

/**
 * Fetch current user data.
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchCurrentUser = async (options = {}) => {
  const config = {
    ...options,
    endpoint: currentUserEndpoint,
    method: 'GET',
    requireAuth: true,
  };
  return FetchService.request(config);
};

/** Update the current user.
 * @param {Object} data
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const updateCurrentUser = async (data, options = {}) => {
  const payload = payloadToJSONAPI(data, 'user', 'address', 'documents');
  const config = {
    ...options,
    endpoint: currentUserEndpoint,
    payload,
    method: 'PATCH',
    requireAuth: true,
  };
  return FetchService.request(config);
};

// Ping the BE to stay logged in.
export const pingBackendServer = async () => {
  const config = {
    endpoint: pingLogoutEndpoint,
    method: 'POST',
    requireAuth: true,
  };
  return FetchService.request(config);
};

import * as types from 'types/cornerDialog';

// *************************************
// Upload bills
// *************************************
export const closeCornerDialogUploadBills = () => ({
  type: types.CORNER_DIALOG_UPLOAD_BILLS_CLOSE,
});

export const openCornerDialogUploadBills = (payload) => ({
  payload,
  type: types.CORNER_DIALOG_UPLOAD_BILLS_OPEN,
});

import { createLastSubmittedReducer } from 'store/redux';

import {
  SUBMIT_SIGNUP_VERIFY_EMAIL_FAILURE,
  SUBMIT_SIGNUP_VERIFY_EMAIL_REQUEST,
  SUBMIT_SIGNUP_VERIFY_EMAIL_SUCCESS,
} from 'types/signup';

export const requestCases = [SUBMIT_SIGNUP_VERIFY_EMAIL_FAILURE, SUBMIT_SIGNUP_VERIFY_EMAIL_REQUEST];
export const successCases = [SUBMIT_SIGNUP_VERIFY_EMAIL_SUCCESS];

const lastSubmittedReducer = createLastSubmittedReducer(successCases, requestCases);

export default lastSubmittedReducer;

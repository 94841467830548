/* eslint-disable */
const denormalize = (state, entity) => {
  const result = {
    id: entity.id,
    ...entity.attributes,
  };

  if (!entity.relationships) {
    return result;
  }

  return processRelationships(result, entity.relationships, state, []);
};

const processRelationships = (entity, relationships, state, included) => {
  for (const type in relationships) {
    const relationship = relationships[type];
    const { data } = relationship;

    if (!data) {
      continue;
    }

    const thisIncluded = { ...included };

    if (Array.isArray(data)) {
      const entities = [];
      data.forEach((item) => {
        const rel = createRelationship(item, state, thisIncluded);
        if (rel) entities.push(rel);
      });
      entity[type] = entities;
    } else {
      const currentEntity = createRelationship(data, state, thisIncluded);
      if (currentEntity) entity[type] = currentEntity;
    }
  }

  return entity;
};

const createRelationship = (data, state, included) => {
  if (
    !state[data.type] ||
    !state[data.type][data.id] ||
    (included.hasOwnProperty(data.type) &&
      included[data.type].includes(data.id))
  ) {
    return {
      id: data.id,
    };
  }

  const includedType = included[data.type] || [];
  includedType.push(data.id);
  included[data.type] = includedType;

  const entity = state[data.type][data.id];
  const result = {
    id: entity.id,
    ...entity.attributes,
  };

  if (!entity.relationships) {
    return result;
  }

  return processRelationships(result, entity.relationships, state, included);
};

export default denormalize;

import React from 'react';

import { LinkNewPage } from 'components/link';
import { WhiteSpace } from 'components/text';

import { PLATFORM_DISPLAY_SHORT_NAME, PLATFORM_EMAILS } from 'constants/platform';

import { getMailtoUrl } from 'helpers/urls';

/**
 * Default error message that displays in the ServerAlert swal.
 * @return {StatelessComponent}
 */
const ServerErrorDefaultMessage = () => (
  <span className="alert-error-text">
    Please contact
    <WhiteSpace />
    <LinkNewPage className="font-color--primary" href={getMailtoUrl(PLATFORM_EMAILS.SUPPORT)}>
      {`${PLATFORM_DISPLAY_SHORT_NAME} support`}
    </LinkNewPage>
    <WhiteSpace />
    and we’ll be happy to assist.
  </span>
);

export default ServerErrorDefaultMessage;

import ThunkFetch from 'helpers/thunkFetch';

import { payloadToJSONAPI, payloadToUnderscore } from 'services/api/formatHelpers';
import { saveTaxInfoEndpoint } from 'services/api/taxEndpoints';

import { SUBMIT_TAX_INFO_FAILURE, SUBMIT_TAX_INFO_REQUEST, SUBMIT_TAX_INFO_SUCCESS } from 'types/taxes';

const submitTaxInfoForConfirmation = (partnershipId, rawPayloadData) => {
  const payload = payloadToUnderscore(rawPayloadData);
  const body = payloadToJSONAPI(payload, 'PartnershipTaxInfo');

  const t = new ThunkFetch({
    actions: {
      error: SUBMIT_TAX_INFO_FAILURE,
      request: SUBMIT_TAX_INFO_REQUEST,
      success: SUBMIT_TAX_INFO_SUCCESS,
    },
    endpoint: saveTaxInfoEndpoint(partnershipId),
    method: 'POST',
    muteAlerts: true,
    payload: body,
    requireAuth: true,
  });

  return t.makeRequest();
};

export default submitTaxInfoForConfirmation;

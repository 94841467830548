import pluralize from 'pluralize';
import React from 'react';

import {
  capitalize,
  dashTextToCamelCase,
  uppercaseToSnakeCaseWithNumbers,
  uppercaseToUnderscore,
} from 'helpers/stringHelpers';
import {
  allKeys,
  hasLength,
  hasZeroLength,
  isArray,
  isEmptyObject,
  isEqual,
  isNull,
  isObject,
  isNotObject,
  isString,
  isUndef,
  objectHasKey,
  valueOrDefault,
} from 'helpers/utility';

/**
 * Method to check whether a specific key is a members of the relationships.
 * Relationships can be specified either as a string (e.g. attachments)
 * or as an object (e.g. { name: 'attachments', kind: 'ItemAttachment' })
 * @param key
 * @param relationships
 * @return {boolean}
 */
export const checkKeyInRelationships = (key, relationships) => {
  // Flat (i.e. string)
  if (relationships.includes(key)) {
    return true;
  }

  const filteredList = relationships.filter(
    (relationship) => isObject(relationship) && isEqual(relationship.name, key),
  );

  return hasLength(filteredList);
};

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Helper method to convert payload value to camelCase if needed
 * @param {String} value
 * @param {Boolean} shouldFormat
 * @returns {*}
 */
export const formatValueToCamelCase = (value, shouldFormat = true) => {
  const shouldTransform = shouldFormat && isString(value);
  return shouldTransform ? dashTextToCamelCase(value) : value;
};

/**
 * Helper to filter out keys that are relationships, leaving only pure base model attributes
 * @param {Object} payload
 * @param {Array} relationships
 * @returns {Object}
 */
export const generateBaseJSONAPIAttributes = (payload, relationships = []) =>
  Object.keys(payload).reduce((accumulator, key) => {
    if (checkKeyInRelationships(key, relationships)) {
      return accumulator;
    }

    return { ...accumulator, [key]: payload[key] };
  }, {});

/**
 * Simple helper to generate base JSONAPI payload structure
 * @param id
 * @param type
 * @param attributes
 * @param relationships
 * @returns {{attributes: *, id: *, type: *}}
 */
export const generateBaseJSONAPIPayload = (id, type, attributes, relationships) => {
  const basePayload = {
    attributes,
    id,
    type,
  };

  if (hasLength(allKeys(relationships))) {
    basePayload.relationships = relationships;
  }

  return basePayload;
};

/**
 * Helper to generate a relationship type from its name
 * @param relationshipKind
 * @returns {string}
 */
export const generateBaseRelationshipType = (relationshipKind) => capitalize(pluralize.singular(relationshipKind));

/**
 * Helper method to get the relationship kind and name and other meta data from a string or object relationship
 * @param {Object|string} relationship
 */
export const generateJSONAPIRelationshipMetadata = (relationship) => {
  let evalObject;

  if (isObject(relationship)) {
    evalObject = relationship;
  } else {
    evalObject = {
      kind: relationship,
      name: relationship,
    };
  }

  return {
    relationshipChildren: valueOrDefault(evalObject.children, []),
    relationshipIsPlural: valueOrDefault(evalObject.isPlural, false),
    relationshipKind: evalObject.kind,
    relationshipName: evalObject.name,
  };
};

/**
 * Helper to generate JSONAPI relationship payload
 * @param payload
 * @param relationship
 * @returns {{data: []}|{data: {attributes: *, id: *, type: *}}|undefined|{data: null}}
 */
export const generateJSONAPIRelationshipPayload = (payload, relationship) => {
  const { relationshipChildren, relationshipIsPlural, relationshipKind, relationshipName } =
    generateJSONAPIRelationshipMetadata(relationship);

  // Ensure data is present
  if (!payload) {
    return undefined;
  }

  // Empty object means clear out the data
  if (isEmptyObject(payload)) {
    return {
      data: null,
    };
  }

  // Handle singular record
  const isSingular = Boolean(pluralize.isSingular(relationshipName) && !relationshipIsPlural);
  const relationshipPayload = isSingular ? [payload] : payload;

  // Iterate through data and create relationship payload
  const relationshipsData = [];
  Object.values(relationshipPayload).forEach((instance) => {
    const { id: instanceId, ...instanceAttributes } = instance || {};
    const type = generateBaseRelationshipType(relationshipKind);
    const attributes = instanceAttributes && !isEmptyObject(instanceAttributes) ? instanceAttributes : undefined;

    // Build nested/child relationship
    const relationships = {};
    relationshipChildren.forEach((childRelationship) => {
      const { relationshipName: name } = generateJSONAPIRelationshipMetadata(childRelationship);

      if (objectHasKey(instance, name)) {
        // Add relationship data only if key existed, indicating intention of change
        const childRelationshipData = instance[name];
        relationships[name] = generateJSONAPIRelationshipPayload(childRelationshipData, childRelationship);
      }

      // Sometimes name key is not in attributes, if that is the case delete breaks js execution.
      if (attributes && objectHasKey(attributes, name)) {
        // Remove from attributes
        delete attributes[name];
      }
    });

    relationshipsData.push(generateBaseJSONAPIPayload(instanceId, type, attributes, relationships));
  });

  // If the original data was singular (i.e. isSingular is true),
  // make sure to grab the first element in the array, otherwise return the whole array
  return {
    data: isSingular ? relationshipsData[0] : relationshipsData,
  };
};

/**
 * Helper to compose relationships (multiple) payload and prepare it for server submission
 * @param payload
 * @param relationships
 * @returns {ObjectMaybe}
 */
export const generateJSONAPIRelationshipsPayload = (payload, relationships) => {
  // If there are no relationships, make sure we don't set this
  if (hasZeroLength(relationships)) {
    return undefined;
  }

  // Build relationships payload
  const relationshipsPayload = {};

  relationships.forEach((relationship) => {
    const { relationshipName: name } = generateJSONAPIRelationshipMetadata(relationship);
    relationshipsPayload[name] = generateJSONAPIRelationshipPayload(payload[name], relationship);
  });

  return relationshipsPayload;
};

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Helper method to convert camelCased payload into underscored payload
 * @param payload
 * @returns {*}
 */
export const payloadToUnderscore = (payload, { includeNumbers } = { includeNumbers: false }) => {
  if (isString(payload) || isNull(payload) || isUndef(payload)) {
    return payload;
  }

  if (isArray(payload)) {
    return payload.map((element) => payloadToUnderscore(element));
  }

  return Object.keys(payload).reduce((accumulator, key) => {
    const currentValue = payload[key];
    const currentKeyUnderscored = includeNumbers ? uppercaseToSnakeCaseWithNumbers(key) : uppercaseToUnderscore(key);

    if (isArray(currentValue) || isObject(currentValue)) {
      return {
        ...accumulator,
        [currentKeyUnderscored]: payloadToUnderscore(currentValue),
      };
    }

    return { ...accumulator, [currentKeyUnderscored]: currentValue };
  }, {});
};

/**
 * Helper method to convert underscored payload to camelCased payload
 * @param payload
 * @param {Boolean} applyToValues
 * @returns {*}
 */
export const payloadToCamelCase = (payload, applyToValues = false) => {
  if (isString(payload)) {
    return formatValueToCamelCase(payload, applyToValues);
  }

  if (isArray(payload)) {
    return payload.map((element) => payloadToCamelCase(element, applyToValues));
  }

  // we've already handled the special cases for string and array types; any other type of thing
  // that makes it to this point should get returned un-modified, unless it's an object (last
  // special case handling, below)
  if (isNotObject(payload) || React.isValidElement(payload)) {
    return payload;
  }

  return Object.keys(payload).reduce((accumulator, key) => {
    const currentValue = payload[key];
    const currentKeyCamelCased = dashTextToCamelCase(key);

    if (isArray(currentValue) || isObject(currentValue)) {
      return {
        ...accumulator,
        [currentKeyCamelCased]: payloadToCamelCase(currentValue, applyToValues),
      };
    }

    return {
      ...accumulator,
      [currentKeyCamelCased]: formatValueToCamelCase(currentValue, applyToValues),
    };
  }, {});
};

/**
 * Helper method to convert nested data per the JSONAPI format specification.
 * We added one layer of nested data that is not originally supported by the spec, and treat it the same as the
 * top level data structure
 * @param rawPayload
 * @param type
 * @param relationships
 * @returns {{data: {attributes: {}, type: (string)}}}
 */
export const payloadToJSONAPI = (rawPayload, type, ...relationships) => {
  // Pop off id and meta as they go on the body directly and don't need to be transformed
  const { id, meta, ...payload } = rawPayload;

  // Get attributes (that are not relationships)
  const attributes = generateBaseJSONAPIAttributes(payload, relationships);

  const body = {
    data: generateBaseJSONAPIPayload(id, capitalize(type), attributes),
  };

  if (meta && !isEmptyObject(meta)) {
    // Ensure the meta is properly underscored
    body.meta = meta;
  }

  body.data.relationships = generateJSONAPIRelationshipsPayload(payload, relationships);

  return payloadToUnderscore(body);
};

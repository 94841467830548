import React from 'react';
import { isItemStatusNeedsApproval, isItemStatusPending, isItemStatusReadyToSend, isItemStatusScheduled, } from 'helpers/items';
import {} from 'interfaces/item';
import { CantRemoveVendorIndividualTooltipContent } from '../components';
import {} from './CommonMenuItemProps.types';
export const getRemoveContactMenuItemProps = (item, commonMenuItemProps) => {
    if (commonMenuItemProps?.disabled) {
        return commonMenuItemProps;
    }
    const contactRemovalDisallowedConditions = [
        isItemStatusScheduled,
        isItemStatusReadyToSend,
        isItemStatusPending,
        isItemStatusNeedsApproval,
    ];
    const shouldDisableRemoveContact = contactRemovalDisallowedConditions.some((filter) => filter(item));
    const disabledRemoveContactTooltipProps = {
        tooltip: React.createElement(CantRemoveVendorIndividualTooltipContent, { partnershipId: item.partnership }),
    };
    const tooltip = shouldDisableRemoveContact ? disabledRemoveContactTooltipProps : undefined;
    return {
        ...commonMenuItemProps,
        disabled: shouldDisableRemoveContact,
        tooltip,
    };
};

import { all, call, put, spawn, take } from 'redux-saga/effects';

import { handleRequestErrors } from 'actions/errors';
import * as actions from 'actions/notifications';
import { showSuccessUi } from 'actions/ui';

import { SuccessIndicatorMessages } from 'constants/ui';

import { parseCaughtError, parseErrorResponse } from 'helpers/errors';

import { payloadToCamelCase } from 'services/api/formatHelpers';

import * as types from 'types/notifications';

import * as api from './api';

/**
 * Handle fetching notifications settings.
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* fetchNotificationSettings(action) {
  let errorData = {};

  try {
    const {
      payload: { notificationSettingsId },
    } = action;

    const response = yield call(api.fetchNotificationSettings, notificationSettingsId);

    if (response.ok) {
      yield put(actions.fetchNotificationSettingsSuccess(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchNotificationSettingsFailure, errorData));
}

/**
 * Handle fetching notifications table data.
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* fetchNotificationTableData(action) {
  let errorData = {};

  try {
    const {
      payload: { notificationSettingsId },
    } = action;

    const response = yield call(api.fetchNotificationTableData, notificationSettingsId);

    if (response.ok) {
      // Not being called as a transformer on purpose
      // Applies payloadToCamelCase on string values as well
      const parsedResponse = payloadToCamelCase(response.data, true);
      yield put(actions.fetchNotificationTableDataSuccess(parsedResponse.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchNotificationTableDataFailure, errorData));
}

/**
 * Handle fetching notifications settings.
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* deleteEmailForward(action) {
  let errorData = {};

  try {
    const {
      payload: { notificationSettingsId, emailForwardId },
    } = action;

    const response = yield call(api.deleteEmailForward, notificationSettingsId, emailForwardId);

    if (response.ok) {
      yield all([
        put(actions.deleteEmailForwardSuccess(emailForwardId)),
        put(showSuccessUi(SuccessIndicatorMessages.GLOBAL_CC_NOTIFICATION_UPDATE_SUCCESS)),
      ]);
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.deleteEmailForwardFailure, errorData));
}

/**
 * Listens for redux actions related to notifications.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([
      types.DELETE_EMAIL_FORWARD_REQUEST,
      types.FETCH_NOTIFICATION_SETTINGS_REQUEST,
      types.FETCH_NOTIFICATION_TABLE_DATA_REQUEST,
    ]);

    switch (action.type) {
      case types.FETCH_NOTIFICATION_SETTINGS_REQUEST:
        yield spawn(fetchNotificationSettings, action);
        break;

      case types.FETCH_NOTIFICATION_TABLE_DATA_REQUEST:
        yield spawn(fetchNotificationTableData, action);
        break;

      case types.DELETE_EMAIL_FORWARD_REQUEST:
        yield spawn(deleteEmailForward, action);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root notifications saga.
 * @return {IterableIterator<*>}
 */
export default function* notifications() {
  yield watch();
}

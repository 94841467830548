import React from 'react';
import { isString } from 'helpers/utility';
import './BulletList.scss';
const BulletList = ({ bullets }) => {
    if (!bullets.length) {
        return null;
    }
    return (React.createElement(React.Fragment, null, bullets
        .filter((bullet) => isString(bullet) || React.isValidElement(bullet))
        .map((bullet, index) => (React.createElement("div", { className: "display--flex bullet-list", key: index },
        React.createElement("div", { className: "bullet-list--bullet" }),
        React.createElement("span", { className: "bullet-list--text" }, bullet))))));
};
export default BulletList;

import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';
import { submitCreatePartnershipRoutine } from 'actions/routines/partnership';
import {
  createPartnershipMemberRoutine,
  fetchPartnershipMembersRoutine,
  fetchSinglePartnershipMemberRoutine,
  updatePartnershipMemberRoutine,
} from 'actions/routines/partnershipMember';

import { getAllResources } from 'store/redux';

import { FETCH_ITEM_SUCCESS, UPDATE_EXISTING_ITEMS_SUCCESS } from 'types/item';
import { SUBMIT_MESSAGE_SUCCESS } from 'types/messages';
import { FETCH_PARTNERSHIP_SUCCESS } from 'types/partnership';

const allIdsReducer = (state = [], action) => {
  switch (action.type) {
    case createPartnershipMemberRoutine.SUCCESS:
    case FETCH_ITEM_SUCCESS:
    case FETCH_PARTNERSHIP_SUCCESS:
    case fetchPartnershipMembersRoutine.SUCCESS:
    case fetchSinglePartnershipMemberRoutine.SUCCESS:
    case submitCreatePartnershipRoutine.SUCCESS:
    case SUBMIT_MESSAGE_SUCCESS:
    case UPDATE_EXISTING_ITEMS_SUCCESS:
    case updatePartnershipMemberRoutine.SUCCESS:
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
      return Array.from(new Set([...state, ...getAllResources(action.payload.partnershipMember)]));

    default:
      return state;
  }
};

export default allIdsReducer;

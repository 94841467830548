import PropTypes from 'prop-types';
import React from 'react';

import { DisclosureList } from 'components/disclosureList';
import { EntityWidgetSection } from 'components/entityWidget';

import { BankAccountViewType } from 'constants/ui';

import { isBankAccountViewTypeAddressAny, isBankAccountViewTypePartnerAch } from 'helpers/ui';

import AccountDetailsSectionItem from 'modules/fundingAccount/bankAccount/components/AccountDetailsSection/AccountDetailsSectionItem';
import { text } from 'modules/fundingAccount/bankAccount/constants';
import { componentHelpers, getBankAccountDetailsData } from 'modules/fundingAccount/bankAccount/helpers';

const AccountDetailsSection = (props) => {
  const {
    bankAccountContext,
    bodyRowProps,
    creator,
    enableCsvImport,
    fundingAccount,
    fundingInfoBankAccount,
    viewType,
  } = props;

  const { viewSubType } = bankAccountContext;

  // if this is an address funding account (receives checks), we don't show the details section,
  // only the bank account header containing the address
  if (isBankAccountViewTypeAddressAny(viewType)) {
    return null;
  }

  const detailsData = getBankAccountDetailsData(fundingInfoBankAccount, {
    creator,
    enableCsvImport,
    fundingAccount,
    viewSubType,
    viewType,
  });
  const isPartnerACHView = isBankAccountViewTypePartnerAch(viewType);
  const useHeader = componentHelpers.shouldUseBankAccountDetailsHeader(viewType);

  return (
    <EntityWidgetSection>
      <DisclosureList
        bodyRowProps={bodyRowProps}
        dataFullStory
        headerText={text.accountDetailsSectionTitle}
        itemRenderer={(item) => <AccountDetailsSectionItem item={item} />}
        items={detailsData}
        keyExtractor={(item) => item.label}
        startOpen={!isPartnerACHView}
        useHeader={useHeader}
      />
    </EntityWidgetSection>
  );
};

AccountDetailsSection.propTypes = {
  bankAccountContext: PropTypes.shape().isRequired,
  bodyRowProps: PropTypes.shape(),
  creator: PropTypes.shape(),
  enableCsvImport: PropTypes.bool,
  fundingAccount: PropTypes.shape(),
  fundingInfoBankAccount: PropTypes.shape(),
  viewType: PropTypes.oneOf(Object.values(BankAccountViewType)),
};

AccountDetailsSection.defaultProps = {
  bodyRowProps: {},
  creator: undefined,
  enableCsvImport: undefined,
  fundingAccount: {},
  fundingInfoBankAccount: {},
  viewType: BankAccountViewType.FULL,
};

export default AccountDetailsSection;

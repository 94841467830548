import _merge from 'lodash/merge';

import withRowDataGetters from 'hoc/withRowDataGetters';

import TeamMemberListNameCell from 'modules/dashboard/settings/account/teamMembers/components/TeamMemberListNameCell';
import { text } from 'modules/dashboard/settings/account/teamMembers/helpers';

import TeamMemberDeactivateCell from './TeamMemberDeactivateCell';

/**
 * Table configuration for TeamMembersListTable
 * @const
 */
export const TeamMembersListTableData = {
  name: {
    baseClassName: 'table-row--column',
    displayName: 'Name',
    flexGrow: 2,
    obj: withRowDataGetters(TeamMemberListNameCell, {
      avatar: 'avatar',
      email: 'email',
      id: 'id',
      isAffiliate: 'isAffiliate',
      isBusinessRepresentative: 'isBusinessRepresentative',
      firstName: 'firstName',
      lastName: 'lastName',
      name: text.getMemberFullName,
    }),
  },
  action: {
    baseClassName: 'table-row--column',
    obj: withRowDataGetters(TeamMemberDeactivateCell, {
      membership: _merge,
      firstName: 'firstName',
      lastName: 'lastName',
    }),
    width: 70,
  },
};

import { submitInvoicesRoutine } from 'actions/routines/item';

import { createLastSubmittedReducer } from 'store/redux';

import { CLEAR_INVOICES } from 'types/item';

export const successCases = [submitInvoicesRoutine.SUCCESS];
export const requestCases = [CLEAR_INVOICES];

const lastSubmittedReducer = createLastSubmittedReducer(successCases, requestCases);

export default lastSubmittedReducer;

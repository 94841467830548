import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { Row } from 'components';

import { colors } from 'constants/styles';
import { TooltipPlacement } from 'constants/tooltip';

import { WithPermissionRestriction, RestrictedPermissionTooltip } from 'modules/auth';

import { PartnerPaymentMethodStatusContentLeft, PartnerPaymentMethodStatusContentRight } from './components';
import { createHandleCtaButtonClick, getCtaButtonProperties, getStatusIconName, getStatusText } from './helpers';

/**
 * The status of a partner payment method at the top of the partner payment method block. Also includes a call to action
 * button to add a bank account/address or send an invoice, etc.
 *
 * @param {ComponentProps} props
 * @returns {StatelessComponent|null}
 */
const PartnerPaymentMethodStatus = ({
  className,
  hideStatusIcon,
  history,
  isEnabled,
  onButtonClick,
  partnership,
  partnershipType,
  paymentDeliveryMethod,
}) => {
  if (!paymentDeliveryMethod) {
    return null;
  }

  // message indicating whether this partner is set up to use given payment method
  const statusText = getStatusText({
    isEnabled,
    partnerName: partnership.name,
    partnershipType,
    paymentDeliveryMethod,
  });

  // get button ui properties
  const { buttonIcon, buttonText, displayCtaButton, displaySendInvoice, requiredPermissions } = getCtaButtonProperties({
    partnership,
    partnershipType,
    paymentDeliveryMethod,
  });

  return (
    <Row
      className={classNames('partner-payment-method-status gap-4', {
        enabled: isEnabled,
        [className]: !!className,
      })}
      flexWrap="wrap"
      justifyContent="space-between"
    >
      <Row alignItems="center" className="partner-payment-method-status--text-wrap">
        <PartnerPaymentMethodStatusContentLeft
          statusIcon={hideStatusIcon ? null : getStatusIconName(isEnabled)}
          statusText={statusText}
        />
      </Row>

      <Row className="partner-payment-method-status--btn-wrapper" flexDirection="column" justifyContent="center">
        <WithPermissionRestriction
          requiredPermissions={requiredPermissions}
          WithoutPermissionComponent={
            <RestrictedPermissionTooltip position={TooltipPlacement.TOP_END} size="medium">
              <PartnerPaymentMethodStatusContentRight
                buttonIcon={buttonIcon}
                buttonText={buttonText}
                displayCtaButton={displayCtaButton}
                isButtonDisabled
              />
            </RestrictedPermissionTooltip>
          }
          WithPermissionComponent={
            <PartnerPaymentMethodStatusContentRight
              buttonIcon={buttonIcon}
              buttonIconColor={colors.colorMainJordan}
              buttonText={buttonText}
              displayCtaButton={displayCtaButton}
              onButtonClick={createHandleCtaButtonClick({
                defaultCallback: onButtonClick,
                displaySendInvoice,
                history,
                partnership,
              })}
            />
          }
        />
      </Row>
    </Row>
  );
};

PartnerPaymentMethodStatus.propTypes = {
  className: PropTypes.string,
  hideStatusIcon: PropTypes.bool,
  history: PropTypes.shape().isRequired,
  isEnabled: PropTypes.bool,
  onButtonClick: PropTypes.func,
  partnership: PropTypes.shape().isRequired,
  partnershipType: PropTypes.string.isRequired,
  paymentDeliveryMethod: PropTypes.string,
};

PartnerPaymentMethodStatus.defaultProps = {
  className: undefined,
  hideStatusIcon: undefined,
  isEnabled: undefined,
  onButtonClick: undefined,
  paymentDeliveryMethod: undefined,
};

export { PartnerPaymentMethodStatus };
export default withRouter(PartnerPaymentMethodStatus);

import React from 'react';
import { FlexRow, CurrencyAmountText } from 'components';
import { ItemCurrencyCodeKey } from 'enums/items';
import { getItemAmountString } from 'helpers/amounts';
import { useCurrencyContext } from 'hooks';
const ShowAmountCell = ({ amountProps, children, currencyCodeKey = ItemCurrencyCodeKey.GENERAL, dataKey, rowData, }) => {
    const currencyCodeMap = useCurrencyContext();
    const amount = getItemAmountString(currencyCodeMap, rowData, dataKey, {
        currencyCode: rowData.predictedCurrencyCode,
        currencyCodeKey,
    });
    return (React.createElement(FlexRow, { className: "w-full items-center justify-end inline-flex gap-4", stackOnMobile: false },
        children,
        React.createElement(CurrencyAmountText, { amount: amount, amountProps: amountProps, currencyCode: rowData.predictedCurrencyCode ?? rowData[currencyCodeKey], parseCurrencyOptions: { symbol: '' } })));
};
export default ShowAmountCell;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { Divider, SettingsDivider } from 'components/divider';

import { AccountStatus } from 'constants/accounts';

import AccountStatusTab from 'modules/dashboard/settings/account/bankConnections/presenters/AccountStatusTab';
import BankAccount from 'modules/fundingAccount/bankAccount';
import { IsLoadingComponent } from 'modules/isLoading/IsLoading';

import {
  fundingAccountsWhereBankByStatusSelector,
  fundingAccountsWhereBankForCurrentCompanyCountSelector,
} from 'queries/fundingCompoundSelectors';

import { fundingAccountsIsFetchingSelector } from 'selectors/fundingSelectors';

import './BankConnections.scss';

class BankConnections extends React.PureComponent {
  state = {
    activeStatus: AccountStatus.ACTIVE,
  };

  componentDidUpdate() {
    const { activeStatus } = this.state;
    const { accountsByStatus } = this.props;

    const statuses = Object.keys(accountsByStatus);

    // if we're using the default active status, but no accounts
    // have said status set, change the active status to the first in list
    if (activeStatus === AccountStatus.ACTIVE && !accountsByStatus[AccountStatus.ACTIVE] && statuses.length) {
      // disabling eslint here because this could only only run once after mount
      /* eslint-disable-next-line react/no-did-update-set-state */
      this.setState({ activeStatus: statuses[0] });
    }
  }

  handleTabClick = (status) => {
    this.setState({ activeStatus: status });
  };

  render() {
    const { accountsByStatus, numberOfAccounts, hideTab, isFetchingFundingAccounts } = this.props;
    const { activeStatus } = this.state;

    if (!isFetchingFundingAccounts && numberOfAccounts === 0) {
      return null;
    }

    if (isFetchingFundingAccounts) {
      return (
        <React.Fragment>
          <Divider />
          <IsLoadingComponent text="Loading your bank accounts..." />
        </React.Fragment>
      );
    }

    const accountStatuses = Object.keys(accountsByStatus);

    return (
      <React.Fragment>
        {!hideTab && (
          <>
            <SettingsDivider />
            <div className="account-status--tab-list">
              {accountStatuses.map((status) => (
                <AccountStatusTab
                  accountsForStatus={accountsByStatus[status]}
                  isSelected={activeStatus === status}
                  key={status}
                  onClick={this.handleTabClick}
                  status={status}
                />
              ))}
            </div>
          </>
        )}

        {accountStatuses.map((status) => (
          <div
            className={classNames({
              'account-status--panel': true,
              show: activeStatus === status,
            })}
            key={`${status}-panel`}
          >
            {accountsByStatus[status].map((acct) => (
              <div className="bank-funding-account" key={acct.id}>
                <BankAccount fundingAccount={acct} />
              </div>
            ))}
          </div>
        ))}
      </React.Fragment>
    );
  }
}

BankConnections.propTypes = {
  accountsByStatus: PropTypes.shape(),
  isFetchingFundingAccounts: PropTypes.bool,
  numberOfAccounts: PropTypes.number,
  hideTab: PropTypes.bool,
};

BankConnections.defaultProps = {
  hideTab: undefined,
  accountsByStatus: undefined,
  isFetchingFundingAccounts: undefined,
  numberOfAccounts: undefined,
};

const mapStateToProps = (state) => ({
  accountsByStatus: fundingAccountsWhereBankByStatusSelector(state),
  isFetchingFundingAccounts: fundingAccountsIsFetchingSelector(state),
  numberOfAccounts: fundingAccountsWhereBankForCurrentCompanyCountSelector(state),
});

export default connect(mapStateToProps)(BankConnections);

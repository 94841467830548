import { useState, useEffect } from 'react';
const state = {};
const tableStateListeners = {};
const proxyHandler = {
    set(target, tableName, tableState) {
        if (typeof tableState === 'object' && tableState !== null) {
            if (!target[tableName]) {
                target[tableName] = tableState;
            }
            else {
                const prevData = target[tableName].data;
                Object.assign(target[tableName], tableState);
                if (prevData !== tableState.data) {
                    if (tableStateListeners[tableName]) {
                        tableStateListeners[tableName].forEach((listener) => {
                            listener(tableName, 'data', tableState.data);
                        });
                    }
                }
            }
            if (tableStateListeners[tableName]) {
                for (const key in tableState) {
                    tableStateListeners[tableName].forEach((listener) => listener(tableName, key, tableState[key]));
                }
            }
            return true;
        }
        return false;
    },
    deleteProperty(target, tableName) {
        if (tableName in target) {
            delete target[tableName];
            return true;
        }
        return false;
    },
};
const proxyState = new Proxy(state, proxyHandler);
const TableStore = {
    setTableStateItem(tableName, key, value) {
        if (!proxyState[tableName]) {
            proxyState[tableName] = {
                table: tableName,
                config: undefined,
                headers: [],
                selections: [],
                data: {},
                cache: [],
                sort: {
                    oldColumnId: '',
                    sorted: false,
                    columnId: '',
                    caption: '',
                    sortOnProp: '',
                    direction: 'off',
                },
            };
        }
        if (key === 'selections') {
            localStorage.setItem(`table:selection:${tableName}`, JSON.stringify(value));
        }
        if (value !== undefined) {
            proxyState[tableName][key] = value;
        }
    },
    getTableStateItem(tableName, key) {
        const defaultValue = {
            table: '',
            config: undefined,
            headers: [],
            selections: [],
            data: {},
            cache: [],
            sort: {
                oldColumnId: '',
                sorted: false,
                columnId: '',
                caption: '',
                sortOnProp: '',
                direction: 'off',
            },
        };
        if (key === 'selections') {
            const storedSelections = localStorage.getItem(`table:selection:${tableName}`);
            if (storedSelections) {
                return JSON.parse(storedSelections);
            }
        }
        return proxyState[tableName]?.[key] ?? defaultValue[key];
    },
    watch(tableName, listener) {
        if (!tableStateListeners[tableName]) {
            tableStateListeners[tableName] = new Set();
        }
        tableStateListeners[tableName].add(listener);
    },
    stopWatching(tableName, listener) {
        if (tableStateListeners[tableName]) {
            tableStateListeners[tableName].delete(listener);
        }
    },
    disposeTable(tableName) {
        delete proxyState[tableName];
        delete tableStateListeners[tableName];
        localStorage.removeItem(`table:selection:${tableName}`);
    },
};
const useTableStore = (tableName, initialState, preserveSelections = false) => {
    const [activeTableState, setActiveTableState] = useState(() => {
        let selections = [];
        const storedSelections = localStorage.getItem(`table:selection:${tableName}`);
        if (storedSelections) {
            selections = JSON.parse(storedSelections);
        }
        if (initialState) {
            if (preserveSelections && proxyState[tableName]?.selections.length) {
                initialState.selections = proxyState[tableName].selections;
            }
            else if (storedSelections) {
                initialState.selections = selections;
            }
            proxyState[tableName] = initialState;
            return initialState;
        }
        return (proxyState[tableName] ?? {
            table: tableName,
            config: undefined,
            headers: [],
            selections: selections,
            data: {},
            cache: [],
            sort: {
                oldColumnId: '',
                sorted: false,
                columnId: '',
                caption: '',
                sortOnProp: '',
                direction: 'off',
            },
        });
    });
    useEffect(() => {
        const handleChange = (changedTableName, key, newValue) => {
            if (changedTableName === tableName) {
                setActiveTableState((prevState) => ({
                    ...prevState,
                    [key]: newValue,
                }));
            }
        };
        TableStore.watch(tableName, handleChange);
        return () => {
            TableStore.stopWatching(tableName, handleChange);
        };
    }, [tableName]);
    const setTableStateItem = (key, value) => {
        if (value !== undefined) {
            TableStore.setTableStateItem(tableName, key, value);
        }
    };
    const getTableStateItem = (key) => {
        return TableStore.getTableStateItem(tableName, key);
    };
    const disposeTable = () => {
        TableStore.disposeTable(tableName);
    };
    return { activeTableState, setTableStateItem, getTableStateItem, disposeTable };
};
const initializeTableStore = () => {
    const defaultTables = {
        inboxTable: {
            table: '',
            config: undefined,
            headers: [],
            selections: [],
            data: {},
            cache: [],
            sort: {
                oldColumnId: '',
                sorted: false,
                columnId: '',
                caption: '',
                sortOnProp: '',
                direction: 'off',
            },
        },
    };
    Object.entries(defaultTables).forEach(([tableName, tableState]) => {
        TableStore.setTableStateItem(tableName, 'table', tableState.table);
        TableStore.setTableStateItem(tableName, 'config', tableState.config);
        TableStore.setTableStateItem(tableName, 'headers', tableState.headers);
        TableStore.setTableStateItem(tableName, 'selections', tableState.selections);
        TableStore.setTableStateItem(tableName, 'data', tableState.data);
        TableStore.setTableStateItem(tableName, 'cache', tableState.cache);
        TableStore.setTableStateItem(tableName, 'sort', tableState.sort);
    });
};
export { initializeTableStore, TableStore, useTableStore };

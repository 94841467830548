import {
  CLEAR_UPLOAD_BILLS,
  SUBMIT_UPLOAD_BILL_FAILURE,
  SUBMIT_UPLOAD_BILL_REQUEST,
  SUBMIT_UPLOAD_BILL_SUCCESS,
} from 'types/bills';

const itemsUploadBillsReducer = (state = {}, action) => {
  switch (action.type) {
    case CLEAR_UPLOAD_BILLS:
      return {};

    case SUBMIT_UPLOAD_BILL_FAILURE: {
      // TODO: FRON-294 - Remove .errors - uploadId is nested under the errors key
      const { uploadId } = action.payload.errors;

      return {
        ...state,
        [uploadId]: {
          ...state[uploadId],
          isError: true,
          isUploading: false,
          isUploaded: false,
        },
      };
    }

    case SUBMIT_UPLOAD_BILL_REQUEST:
      return {
        ...state,
        [action.payload.uploadId]: {
          filename: action.payload.filename,
          isError: false,
          isUploaded: false,
          isUploading: true,
          uploadId: action.payload.uploadId,
        },
      };

    case SUBMIT_UPLOAD_BILL_SUCCESS:
      return {
        ...state,
        [action.payload.uploadId]: {
          ...state[action.payload.uploadId],
          isError: false,
          isUploading: false,
          isUploaded: true,
        },
      };

    default:
      return state;
  }
};

export default itemsUploadBillsReducer;

import { combineReducers } from 'redux';

import allLedgerUnmatchedPlatformFundingAccountsReducer from './allLedgerUnmatchedPlatformFundingAccountsReducer';
import errorReducer from './errorReducer';
import fetchReducer from './fetchReducer';
import lastSubmittedReducer from './lastSubmittedReducer';
import lastUpdatedReducer from './lastUpdatedReducer';
import ledgerUnmatchedPlatformFundingAccountsByIdReducer from './ledgerUnmatchedPlatformFundingAccountsByIdReducer';
import submitReducer from './submitReducer';
import unmatchedCounterReducer from './unmatchedCounterReducer';

const ledgerUnmatchedPlatformFundingAccountsReducer = combineReducers({
  allIds: allLedgerUnmatchedPlatformFundingAccountsReducer,
  byId: ledgerUnmatchedPlatformFundingAccountsByIdReducer,
  errors: errorReducer,
  isFetching: fetchReducer,
  isSubmitting: submitReducer,
  lastSubmitted: lastSubmittedReducer,
  lastUpdated: lastUpdatedReducer,
  unmatchedCounter: unmatchedCounterReducer,
});

export default ledgerUnmatchedPlatformFundingAccountsReducer;

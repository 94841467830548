import { createLastSubmittedReducer } from 'store/redux';

import { RESET_PLATFORM_PARTNERSHIP, SUBMIT_PLATFORM_PARTNERSHIP_SUCCESS } from 'types/integrations';

export const successCases = [SUBMIT_PLATFORM_PARTNERSHIP_SUCCESS];
export const requestCases = [RESET_PLATFORM_PARTNERSHIP];

const lastSubmittedReducer = createLastSubmittedReducer(successCases, requestCases);

export default lastSubmittedReducer;

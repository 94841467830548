import { updateCurrentUserRoutine } from 'actions/routines/user';

import { createIsUpdatingReducer } from 'store/redux';

export const finishCases = [updateCurrentUserRoutine.FAILURE, updateCurrentUserRoutine.SUCCESS];
export const requestCases = [updateCurrentUserRoutine.REQUEST];

const updateReducer = createIsUpdatingReducer(finishCases, requestCases);

export default updateReducer;

import React from 'react';
import { Field } from 'redux-form';
import { ReduxFormFieldRenderInput } from 'components';
import { requiredValidator } from 'helpers/fieldValidation';
import { InviteTeamMemberFormEmailField } from 'modules/dashboard/settings/account/InviteTeamMember/components/InviteTeamMemberForm/components';
import { Content, Fieldset, Legend, FieldWrapper, Row, LegendHelper } from './InviteTeamMemberContent.styles';
const InviteTeamMemberContent = () => (React.createElement(Content, null,
    React.createElement(Fieldset, null,
        React.createElement("div", null,
            React.createElement(Legend, null, "Who would you like to invite?"),
            React.createElement(LegendHelper, null, "Anyone you invite will have access to all of the information you've already added, except for other representatives' personal data.")),
        React.createElement(InviteTeamMemberFormEmailField, { placeholder: "Team member email" }),
        React.createElement(Row, null,
            React.createElement(FieldWrapper, null,
                React.createElement(Field, { component: ReduxFormFieldRenderInput, name: "form.firstName", placeholder: "First name", type: "text", validate: requiredValidator })),
            React.createElement(FieldWrapper, null,
                React.createElement(Field, { component: ReduxFormFieldRenderInput, name: "form.lastName", placeholder: "Last name", type: "text", validate: requiredValidator })))),
    React.createElement(Fieldset, null,
        React.createElement("div", null,
            React.createElement(Legend, null, "Provide instructions for your team member")),
        React.createElement(Field, { component: ReduxFormFieldRenderInput, isRequired: false, name: "form.inviteMessage", placeholder: "Your personalized message", type: "text" }))));
export default InviteTeamMemberContent;

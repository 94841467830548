import { z } from 'zod';
export const itemApiResponseModel = z.object({
    company: z.object({}).passthrough(),
    fundingAccount: z.object({}).passthrough(),
    fundingInfoBank: z.object({}).passthrough(),
    item: z.object({}).passthrough(),
    itemMember: z.object({}).passthrough(),
    membership: z.object({}).passthrough(),
    partnership: z.object({}).passthrough(),
    partnershipMember: z.object({}).passthrough(),
    thread: z.object({}).passthrough(),
    user: z.object({}).passthrough(),
});

import { combineReducers } from 'redux';

import allIdsReducer from './allIdsReducer';
import byIdReducer from './byIdReducer';
import errorReducer from './errorReducer';
import isFetchingReducer from './isFetchingReducer';
import lastFetchedReducer from './lastFetchedReducer';

const fundingInfoInternationalReducer = combineReducers({
  allIds: allIdsReducer,
  byId: byIdReducer,
  errors: errorReducer,
  isFetching: isFetchingReducer,
  lastFetched: lastFetchedReducer,
});

export default fundingInfoInternationalReducer;

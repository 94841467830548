import PropTypes from 'prop-types';
import React from 'react';

import { getItemsNotSentHintTitle } from 'components/hintTypes/helpers/text';
import { LinkLearnMore } from 'components/link';

import { ItemKinds } from 'constants/item';
import { Intent } from 'constants/ui';

import helpDocs from 'helpers/helpDocs';
import { isKindPayable } from 'helpers/items';
import { oneOfValuesFromObject } from 'helpers/propTypes';

import BaseHint from '../BaseHint';

/**
 * Hint which notifies the user how many items will remain in "Ready to send" status until a payment date is selected
 * @see {BulkImportItemsHints}
 *
 * @param {ComponentProps} props
 * @param {Number} props.itemCount
 * @param {ItemKind} props.itemKind
 * @return {StatelessComponent}
 */
const ItemsNotSentHint = ({ itemCount, itemKind }) => {
  const learnMoreURL = isKindPayable(itemKind)
    ? helpDocs.CREATE_AND_DONT_SEND_PAYABLE
    : helpDocs.CREATE_AND_DONT_SEND_RECEIVABLE;

  return (
    <BaseHint
      intent={Intent.INFO}
      title={
        <>
          {getItemsNotSentHintTitle(itemCount, itemKind)} <LinkLearnMore href={learnMoreURL} />
        </>
      }
    />
  );
};

ItemsNotSentHint.propTypes = {
  itemCount: PropTypes.number.isRequired,
  itemKind: oneOfValuesFromObject(ItemKinds).isRequired,
};

export default ItemsNotSentHint;

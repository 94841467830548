import { InformationFilled } from '@carbon/icons-react';
import { Tooltip } from '@routable/gross-ds';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { FlexCol, FlexRow } from 'components/layout';

/**
 * Component for showing text and tooltip inside of RadioInput.
 * @param {ComponentProps} props
 * @param {boolean} [props.isChecked]
 * @param {string} [props.optionText]
 * @param {string} [props.subText]
 * @param {object} [props.tooltipProps]
 * @returns {StatelessComponent}
 */
const RadioTitle = ({ isChecked, optionText, subText, tooltipProps }) => {
  if (!optionText) {
    return null;
  }

  return (
    <FlexRow className="radio--title">
      <FlexCol className="margin-left--m width--100-percent" stackOnMobile={false}>
        <span
          className={clsx('radio--optiontext text-sm bold', {
            'text-black': isChecked,
            'text-grey-70': !isChecked,
          })}
        >
          {optionText}
        </span>

        {subText && <span className="radio--subtext truncate text-grey-70 text-[13px]">{subText}</span>}
      </FlexCol>

      {tooltipProps?.title && (
        <div className="radio--tooltip">
          <Tooltip data-testid="radio-icon--tooltip" tooltip={tooltipProps.title} variant="light">
            <InformationFilled className="w-6 h-6 text-grey-50 flex-shrink-0" title="information" />
          </Tooltip>
        </div>
      )}
    </FlexRow>
  );
};

RadioTitle.propTypes = {
  isChecked: PropTypes.bool,
  optionText: PropTypes.string,
  subText: PropTypes.string,
  tooltipProps: PropTypes.shape(),
};

RadioTitle.defaultProps = {
  isChecked: undefined,
  optionText: undefined,
  subText: undefined,
  tooltipProps: undefined,
};

export default RadioTitle;

import { createIsFetchingReducer } from 'store/redux';

import {
  FETCH_FUNDING_ACCOUNTS_FAILURE,
  FETCH_FUNDING_ACCOUNTS_REQUEST,
  FETCH_FUNDING_ACCOUNTS_SUCCESS,
} from 'types/funding';

export const finishCases = [FETCH_FUNDING_ACCOUNTS_FAILURE, FETCH_FUNDING_ACCOUNTS_SUCCESS];
export const requestCases = [FETCH_FUNDING_ACCOUNTS_REQUEST];

const fetchReducer = createIsFetchingReducer(finishCases, requestCases);

export default fetchReducer;

import { View } from '@carbon/icons-react';
import { RoutableEventsSync, useEventSync } from '@routable/framework';
import { Button } from '@routable/gross-ds';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { poMatchTypeModel } from 'hooks/purchaseOrders/purchaseOrder.models';
import { LineItemStyles } from 'constants/lineItems';
import { useSelectorWithProps } from 'hooks';
import { DataFedSummarizeTransfer } from 'modules/dashboard/createItems/createItemForm/components/CreateItemForm/components/DataFedSummarizeTransfer';
import { createItemFormPoMatchTypeSelector, createItemFormPurchaseOrderSelector } from 'selectors/forms';
import { itemSelector } from 'selectors/itemsSelectors';
import { SectionHeader } from './SectionHeaders.styled';
export const BillSectionHeaderComponent = ({ isOnDetailsPage, subSectionId }) => {
    const purchaseOrder = useSelector(createItemFormPurchaseOrderSelector);
    const poMatchType = useSelector(createItemFormPoMatchTypeSelector);
    const params = useParams();
    const item = useSelectorWithProps(itemSelector, params.id);
    const is3WayMatching = (poMatchType || item?.poMatchType) === poMatchTypeModel.Enum['3-way'];
    const hasPo = item?.purchaseOrders?.[0]?.ledgerRef || purchaseOrder?.value;
    const [showPoLines, setShowPoLines] = useState(true);
    const sectionType = subSectionId.includes('account') ? LineItemStyles.ACCOUNT : LineItemStyles.ITEM;
    const onShowPoLines = () => {
        RoutableEventsSync.Publish(`${sectionType}:po-section:toggle`, { value: true });
    };
    useEventSync(`${sectionType}:po-section:toggle`, ({ value }) => setShowPoLines(value));
    return (React.createElement(SectionHeader, { isOnDetailsPage: isOnDetailsPage },
        React.createElement("div", { className: "flex flex-row justify-between items-center text-xs h-full pl-4 pr-2" },
            React.createElement("div", { className: "font-semibold" }, "Bill lines"),
            React.createElement("div", { className: "flex gap-3" },
                React.createElement(DataFedSummarizeTransfer, { sectionType: sectionType }),
                !showPoLines && hasPo && (React.createElement(Button, { "data-testid": "show-po-lines-button", intent: "secondary", onPress: onShowPoLines, size: "small", variant: "floating" },
                    React.createElement(View, { className: "w-4 h-4 text-grey-70" }),
                    React.createElement("span", { className: "text-xs text-black font-medium" }, `Show ${is3WayMatching ? 'receipt and ' : ''}PO lines`)))))));
};

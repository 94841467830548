import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'components/icon';

import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';

import { oneOfValuesFromObject } from 'helpers/propTypes';

import TooltipMUI from '../TooltipMUI';

/**
 * An icon that triggers a MUI tooltip.
 * @param {ComponentProps} props
 * @param {String} icon
 * @param {String} [iconClassName]
 * @param {String} [iconColor]
 * @param {Object} [iconProps]
 * @param {String} [padding]
 * @param {String} [placement]
 * @param {Node} title
 * @param {Object} [tooltipProps]
 * @return {StatelessComponent}
 * @deprecated use @routable/components's Tooltip instead
 */
const TooltipMUIIcon = ({ icon, iconClassName, iconColor, iconProps, padding, placement, title, tooltipProps }) => (
  <TooltipMUI padding={padding} placement={placement} title={title} {...tooltipProps}>
    <span aria-label={icon}>
      <Icon className={iconClassName} color={iconColor} icon={icon} {...iconProps} />
    </span>
  </TooltipMUI>
);

TooltipMUIIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  iconClassName: PropTypes.string,
  iconColor: PropTypes.string,
  iconProps: PropTypes.shape({}),
  padding: oneOfValuesFromObject(TooltipPadding),
  placement: oneOfValuesFromObject(TooltipPlacement),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  tooltipProps: PropTypes.shape({}),
};

TooltipMUIIcon.defaultProps = {
  iconClassName: undefined,
  iconColor: undefined,
  iconProps: {},
  title: '',
  padding: undefined,
  placement: undefined,
  tooltipProps: {},
};

export default TooltipMUIIcon;

import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert';
import { RestrictedFeatureModal } from 'complexComponents/modals';
import { DASHBOARD } from 'constants/routes';
import { showSwal } from 'helpers/swal';
const RestrictedPage = () => {
    useEffect(() => {
        showSwal({
            Content: React.createElement(RestrictedFeatureModal, { onConfirm: () => swal.close() }),
        });
    }, []);
    return React.createElement(Redirect, { to: `/${DASHBOARD}/` });
};
export default RestrictedPage;

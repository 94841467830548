import { isFundingAccountValid } from 'helpers/funding';

/**
 * Returns the current payment method hint label text based on the
 * passed isUpdated value.
 * @param {object} options
 * @param {FundingAccount} options.fundingAccount
 * @param {boolean} options.isPaymentMethodUpdated
 * @return {string}
 */
export const getHintLabelText = ({ fundingAccount, isPaymentMethodUpdated }) => {
  let prefix = 'Pending';

  if (isFundingAccountValid(fundingAccount)) {
    prefix = isPaymentMethodUpdated ? 'Updated' : 'Current';
  }

  return `${prefix} payment method:`;
};

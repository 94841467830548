import * as routines from 'actions/routines/countries';

import { createAllIdsReducer } from 'store/redux';

const allCountriesReducer = createAllIdsReducer({
  key: 'referenceCountry',
  types: [routines.fetchCountriesRoutine.SUCCESS],
});

export default allCountriesReducer;

import PropTypes from 'prop-types';
import React from 'react';

import './Box.scss';

const Box = ({ title, children }) => (
  <div className="box--container">
    <p className="box--header">{title}</p>

    <div className="box">
      <div className="box--step" />
      {children}
    </div>
  </div>
);

Box.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default Box;

import PropTypes from 'prop-types';

import { isCurrentCompanyInInviteStage } from 'helpers/currentCompany';
import { isExternalPathPay } from 'helpers/external';
import { isPartnershipCustomer } from 'helpers/partnerships';

import { handleExternalNeedVendorFlowSwal } from './helpers';

/**
 * This is a component that renders nothing, but loads a SWAL if needed.
 *
 * In the case where we try to load the external customer onboarding flow, and have a company that is both a
 * vendor and customer, we want to show a SWAL that has a link to the vendor flow and block them from proceeding.
 *
 * Note: we are checking if isPartnershipCustomer because on the external flow it's the other side's partnership
 * @param {Company} currentCompany
 * @param {Company} partner
 * @param {Partnership} partnership
 * @returns {null}
 */
const ExternalNeedVendorFlowAlert = ({ currentCompany, partner, partnership }) => {
  if (isExternalPathPay() && isPartnershipCustomer(partnership) && isCurrentCompanyInInviteStage(currentCompany)) {
    // noinspection JSIgnoredPromiseFromCall
    handleExternalNeedVendorFlowSwal(partner);
  }

  return null;
};

ExternalNeedVendorFlowAlert.propTypes = {
  currentCompany: PropTypes.shape().isRequired,
  partner: PropTypes.shape().isRequired,
  partnership: PropTypes.shape().isRequired,
};

export default ExternalNeedVendorFlowAlert;

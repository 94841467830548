import { LedgerMatchFilterTypes } from 'constants/ledger';

// Search
export const getMatchingSearchInitialValues = () => ({
  execute: null,
  keyword: '',
  searchedKeyword: null,
});

// Platform to Ledger matching
export const getMatchPlatformPartnershipInitialValues = () => ({
  add: null,
  archive: null,
  data: null,
  match: null,
});

export const getPlatformPartnershipInitialValues = () => ({
  platform: {
    partnership: {
      customer: getMatchPlatformPartnershipInitialValues(),
      vendor: getMatchPlatformPartnershipInitialValues(),
    },
  },
});

// General
export const getMatchingInitialValues = () => ({
  activeSide: null,
  activeFilter: LedgerMatchFilterTypes.UNMATCHED,
  pagination: {
    offset: 0,
  },
  search: getMatchingSearchInitialValues(),
});

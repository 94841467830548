import React from 'react';
import StatusWithText from 'components/statusWithText';
import { getItemStatusProps, getItemStatusText, getStatusToConsider } from 'helpers/status';
const ItemStatusList = ({ rowData }) => {
    const { statusVariant, statusText } = getItemStatusProps(rowData);
    const status = getStatusToConsider(rowData);
    const complementaryText = getItemStatusText(rowData, status);
    return (React.createElement(StatusWithText, { secondaryText: complementaryText, status: statusVariant }, statusText));
};
export default ItemStatusList;

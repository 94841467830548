import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Intent } from 'constants/ui';

import './ShadedBlock.scss';

const ShadedBlock = ({ children, className, intent }) => (
  <div
    className={classNames({
      'shaded-block': true,
      [className]: !!className,
      [intent]: !!intent,
    })}
  >
    {children}
  </div>
);

ShadedBlock.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  intent: PropTypes.oneOf(Object.values(Intent)).isRequired,
};

ShadedBlock.defaultProps = {
  className: undefined,
};

export default ShadedBlock;

import PropTypes from 'prop-types';
import React from 'react';

import { DisclosureList } from 'components/disclosureList';
import { EntityWidgetSection } from 'components/entityWidget';

import { BankAccountViewType } from 'constants/ui';

import { isBankAccountViewTypeFull } from 'helpers/ui';

import { text } from 'modules/fundingAccount/bankAccount/constants';
import { getBankAccountIntegrationData } from 'modules/fundingAccount/bankAccount/helpers';

const BankAccountAccountingIntegrationSection = (props) => {
  const { bodyRowProps, fundingAccount, ledgerApp, ledgerBankAccounts, ledgerName, viewType } = props;

  const integrationData = getBankAccountIntegrationData(
    ledgerBankAccounts[fundingAccount.ledgerRefPayable],
    ledgerBankAccounts[fundingAccount.ledgerRefReceivable],
    ledgerBankAccounts[fundingAccount.ledgerRef],
    ledgerApp,
    ledgerName,
  );

  if (!isBankAccountViewTypeFull(viewType)) {
    return null;
  }

  if (integrationData.length === 0) {
    return null;
  }

  return (
    <EntityWidgetSection>
      <DisclosureList
        bodyRowProps={bodyRowProps}
        headerText={text.accountingIntegrationSectionTitle}
        items={integrationData}
        keyExtractor={(item) => item.key}
      />
    </EntityWidgetSection>
  );
};

BankAccountAccountingIntegrationSection.propTypes = {
  bodyRowProps: PropTypes.shape({}),
  fundingAccount: PropTypes.shape({
    ledgerRef: PropTypes.string,
    ledgerRefPayable: PropTypes.string,
    ledgerRefReceivable: PropTypes.string,
  }),
  ledgerApp: PropTypes.string,
  ledgerBankAccounts: PropTypes.shape({}),
  ledgerName: PropTypes.string,
  viewType: PropTypes.oneOf(Object.values(BankAccountViewType)),
};

BankAccountAccountingIntegrationSection.defaultProps = {
  bodyRowProps: {},
  fundingAccount: {},
  ledgerApp: undefined,
  ledgerBankAccounts: {},
  ledgerName: undefined,
  viewType: BankAccountViewType.FULL,
};

export default BankAccountAccountingIntegrationSection;

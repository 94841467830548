import { getQueryString } from 'helpers/queryParams';
import { getURLWithQueryParams } from 'helpers/urls';

import { BASE_API_URL } from 'services/api';

export const partnershipsEndpoint = `${BASE_API_URL}/partnerships/`;

export const getPartnershipsEndpoint = (params) => {
  if (params.url) {
    // Use URL specified
    return params.url;
  }

  return `${partnershipsEndpoint}?${getQueryString(params)}`;
};

export const getPartnershipEndpoint = (partnershipId, queryParams) =>
  getURLWithQueryParams(`${partnershipsEndpoint}${partnershipId}/`, queryParams);

/**
 * Get URL to search PartnershipMembers' emails.
 * @param {ObjectMaybe} queryParams
 * @returns {string}
 */
export const getPartnershipEmailLookupEndpoint = (queryParams) =>
  getURLWithQueryParams(`${partnershipsEndpoint}email_lookup/`, queryParams);

export const getPartnershipReceivableFundingAccountsEndpoint = (partnershipId, queryParams) =>
  getURLWithQueryParams(`${partnershipsEndpoint}${partnershipId}/receivable_funding_accounts/`, queryParams);

export const getPartnershipReceivableFundingAccountSubmitEndpoint = (
  partnershipId,
  partnershipFundingAccountId,
  queryParams,
) =>
  getURLWithQueryParams(
    `${partnershipsEndpoint}${partnershipId}/receivable_funding_accounts/${partnershipFundingAccountId}/`,
    queryParams,
  );

export const getPartnershipAddFundingAccountBank = (partnershipId) =>
  `${partnershipsEndpoint}${partnershipId}/funding_account_bank/`;

export const getPartnershipAddFundingAccountAddress = (partnershipId) =>
  `${partnershipsEndpoint}${partnershipId}/funding_account_address/`;

export const getResendPartnershipInviteEndpoint = (partnershipId) =>
  `${partnershipsEndpoint}${partnershipId}/send_invite/`;

/**
 * Returns endpoint for fetching partnership items
 * @param {string} partnershipId
 * @param {object} [queryParams={}]
 * @return {string}
 */
export const getPartnershipItemsEndpoint = (partnershipId, queryParams = {}) => {
  if (queryParams?.url) {
    return queryParams.url;
  }

  return `${partnershipsEndpoint}${partnershipId}/items/?${getQueryString(queryParams)}`;
};

export const getSendPartnerUpdatePaymentMethodLink = (partnershipId) =>
  `${partnershipsEndpoint}${partnershipId}/funding_account_request/`;

import styled from 'styled-components';
export const HintContainer = styled.div `
  padding: var(--spacing-4);
  background-color: var(--color-grey05);
  border-radius: 8px;
`;
export const HintText = styled.span `
  font-family: var(--globals-font-semi-bold-family);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-1);
  color: var(--color-neutral-black);
  line-height: var(--line-height-1);
`;

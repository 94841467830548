import React from 'react';
import { Text } from 'components';
import { typography } from 'constants/styles';
import { hasLength } from 'helpers/utility';
const CompanyAddressDataCardItem = ({ actions, label, value }) => (React.createElement("div", { className: "company-address-data-card__expandable__item" },
    React.createElement("div", { className: "company-address-data-card__expandable__item__label-value-container" },
        React.createElement("div", { className: "company-address-data-card__expandable__item__label-value-container__label" },
            React.createElement(Text.Regular, { size: typography.TextSize.LEVEL_100 }, label)),
        React.createElement("div", { className: "company-address-data-card__expandable__item__label-value-container__value", "data-fullstory": true }, value)),
    hasLength(actions) && React.createElement("div", { className: "company-address-data-card__expandable__item__actions" }, actions)));
export default CompanyAddressDataCardItem;

import { Tooltip } from '@routable/components';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleDeactivateTeamMemberModal } from 'actions/signUpFlow';
import { IconNames } from 'components';
import { Icon } from 'components/icon';
import { colors } from 'constants/styles';
import DeactivateTeamMemberModal from './DeactivateTeamMemberModal';
const TeamMemberDeactivateCell = ({ currentMembershipId, onDeactivate, membership, firstName, lastName, deactivateTeamMemberModal, }) => {
    const dispatch = useDispatch();
    const { id: membershipId } = membership;
    const tooltipProps = {
        active: {
            content: 'Deactivate team member',
            iconClassNames: 'hover--blue-bold cursor--pointer',
            variant: 'dark',
        },
        inactive: {
            content: "You can't deactivate your own account.",
            iconClassNames: 'icon-tooltip',
            variant: 'light',
        },
    };
    const isDeactivateDisabled = membershipId === currentMembershipId;
    const { content, variant, iconClassNames } = isDeactivateDisabled ? tooltipProps.inactive : tooltipProps.active;
    const handleDeactivate = () => {
        onDeactivate({ membership: { id: membershipId, firstName, lastName } });
        dispatch(toggleDeactivateTeamMemberModal(membershipId));
    };
    return (React.createElement("div", { className: "w-full flex items-center justify-center" },
        React.createElement(DeactivateTeamMemberModal, { deactivateMember: handleDeactivate, firstName: firstName, lastName: lastName, membershipId: membershipId, open: deactivateTeamMemberModal[membershipId] }),
        React.createElement(Tooltip, { "data-testid": "deactivate-team-member-tooltip", tooltip: content, variant: variant },
            React.createElement("button", { "aria-label": "Deactivate team member", disabled: isDeactivateDisabled, onClick: () => dispatch(toggleDeactivateTeamMemberModal(membershipId)), type: "button" },
                React.createElement(Icon, { className: iconClassNames, color: colors.colorGreyMed, icon: IconNames.REMOVE })))));
};
export default TeamMemberDeactivateCell;

import PropTypes from 'prop-types';
import React from 'react';

import { FormControl } from 'components/form';
import BaseHint from 'components/hintTypes/BaseHint';

import { Intent } from 'constants/ui';

import { DeliveryMethodsAccepted } from './components';

const ExistingPartnerWaitingToElectHint = (props) => {
  const { availablePaymentMethods, formFieldPrefix, partnerName } = props;

  return (
    <FormControl>
      <BaseHint
        content={
          <DeliveryMethodsAccepted
            availablePaymentMethods={availablePaymentMethods}
            formFieldPrefix={formFieldPrefix}
          />
        }
        dataFullStoryTitle
        intent={Intent.WARNING}
        multiline
        text="Your vendor will be allowed to select and add their payment method information when accepting this payment."
        title={`Waiting for ${partnerName} to select a payment method.`}
      />
    </FormControl>
  );
};

ExistingPartnerWaitingToElectHint.propTypes = {
  availablePaymentMethods: PropTypes.arrayOf(PropTypes.string),
  formFieldPrefix: PropTypes.string,
  partnerName: PropTypes.string.isRequired,
};

ExistingPartnerWaitingToElectHint.defaultProps = {
  availablePaymentMethods: [],
  formFieldPrefix: '',
};

export default ExistingPartnerWaitingToElectHint;

import * as types from 'types/errors';

export const addNotFoundError = () => ({
  type: types.ADD_NOT_FOUND_ERROR,
});

export const removeNotFoundError = () => ({
  type: types.REMOVE_NOT_FOUND_ERROR,
});

export const addDisabledUserError = () => ({
  type: types.ADD_DISABLED_USER_ERROR,
});

export const handleRequestErrors = (actionCreator, errorData) => ({
  payload: { actionCreator, errorData },
  type: types.HANDLE_REQUEST_ERRORS,
});

export const setFormErrors = (actionCreator, submitErrors) => ({
  payload: { actionCreator, submitErrors },
  type: types.SET_FORM_ERRORS,
});

export const showServerErrorAlert = (errors, requestId) => ({
  payload: { errors, requestId },
  type: types.SHOW_SERVER_ERROR_ALERT,
});

export const showTimeoutErrorAlert = ({ requestId, options = {} }) => ({
  payload: { requestId, options },
  type: types.SHOW_TIMEOUT_ERROR_ALERT,
});

export const addApprovalLevelError = () => ({
  type: types.ADD_APPROVAL_LEVEL_ERROR,
});

export const removeApprovalLevelError = () => ({
  type: types.REMOVE_APPROVAL_LEVEL_ERROR,
});

import { toastDurationSeconds } from 'constants/ui';

import { getInvalidCountrySelectedMessage } from 'helpers/addressHelpers';
import { showFormFailureToast } from 'helpers/errors';

/**
 * Show a toast with invalid country error message
 * @param {string} contactName
 */
export const showInvalidCountryToast = (contactName) => {
  showFormFailureToast('invalidCountry', {
    duration: toastDurationSeconds.INFINITE,
    message: '', // overwrite default description
    title: getInvalidCountrySelectedMessage({ contactName }),
  });
};

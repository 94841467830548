import { CommonSelectOptions } from 'components/selectV2/utils/constants';

import { isArray } from 'helpers/utility';

/**
 * Extracts & returns value property from multi select option object
 * @param {{value: any}} option
 * @returns {any}
 */
export const getMultiSelectOptionValue = (option) => option?.value;

/**
 * Returns true if the given option object's value is select all
 * @param {{ value: any }} option
 * @returns {boolean}
 */
export const isOptionSelectAll = (option = {}) => option.value === CommonSelectOptions.SELECT_ALL.value;

/**
 * Returns true if the given option array contains a select-all option
 * @param {[{ value: any }]} options
 * @returns {boolean}
 */
export const isSelectAllOptionActive = (options = []) => options.some((opt) => isOptionSelectAll(opt));

/**
 * Returns true if the given option object's value is not set
 * @param {{ value: * }} option
 * @returns {boolean}
 */
export const isOptionApplyToNotSet = (option = {}) => option.value === CommonSelectOptions.NOT_SET.value;

/**
 * Returns true if the given option object's value is not set
 * @param {[{ value: any }]} options
 * @returns {boolean}
 */
export const isApplyToNotSetOptionActive = (options = []) => options.some((opt) => isOptionApplyToNotSet(opt));

/**
 * Returns a 1D array given a multi-dimensional array,
 * or an array containing objects that also contain an options property.
 * @param {Array<*>} options
 * @returns {Array<*>}
 */
export const getFlattenedOptions = (options = []) =>
  options.reduce((arr, opt) => {
    if (isArray(opt)) {
      return arr.concat(getFlattenedOptions(opt));
    }

    if (isArray(opt.options)) {
      return arr.concat(getFlattenedOptions(opt.options));
    }

    return [...arr, opt];
  }, []);

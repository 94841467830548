import PropTypes from 'prop-types';
import React from 'react';

import { CurrencyCodeUsd } from 'constants/currency';

import { formatAmount } from 'helpers/currencyFormatter';

/**
 * A table row in the totals panel.
 * @param {ComponentProps} props
 * @param {number} props.amount
 * @param {LedgerIntegration} props.ledger
 * @param {Object} props.meta
 * @param {boolean} props.showOnAutoTax
 * @param {string} props.text
 * @return {StatelessComponent}
 */
export const InvoiceGeneratorTotalsRow = (props) => {
  const { amount, currencyCode, decimalScale, formValues, getDisplayValue, text } = props;

  const displayValue = getDisplayValue?.(formValues);
  let amountText;

  if (displayValue) {
    amountText = displayValue;
  } else {
    const formattedAmount = formatAmount(amount, {
      precision: decimalScale,
    });

    amountText = `${formattedAmount} ${currencyCode}`;
  }

  return (
    <div className="totals-row">
      <div className="left">
        <p>{text}</p>
      </div>

      <div className="right">
        <p>{amountText}</p>
      </div>
    </div>
  );
};

InvoiceGeneratorTotalsRow.propTypes = {
  currencyCode: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  decimalScale: PropTypes.number,
  getDisplayValue: PropTypes.func,
  formValues: PropTypes.shape({}),
  text: PropTypes.string.isRequired,
};

InvoiceGeneratorTotalsRow.defaultProps = {
  currencyCode: CurrencyCodeUsd,
  decimalScale: 2,
  formValues: undefined,
  getDisplayValue: undefined,
};

export default InvoiceGeneratorTotalsRow;

import PropTypes from 'prop-types';
import React from 'react';

import { Text, TooltipConditionalWrapper } from 'components';

import { AddedContactsSummaryTitleTextProps } from 'constants/ui';

import { getMembershipNameOrEmailOrPhoneNumber } from 'helpers/memberships';

import { logicHelpers, textHelpers } from './helpers';

/**
 * Shows the first contact's name and summarizes the rest.
 * @param {ComponentProps} props
 * @param {PartnershipMember[]} props.partnershipMembers
 * @returns {StatelessComponent}
 */
const SummaryOfContactsText = ({ partnershipMembers }) => {
  const [firstMember, ...otherMembers] = partnershipMembers;

  const firstContact = getMembershipNameOrEmailOrPhoneNumber(firstMember);
  const othersText = textHelpers.getAndOthersSummaryOfContactsText(partnershipMembers);
  const tooltipProps = logicHelpers.getSummaryOfContactsTooltipProps(otherMembers);

  return (
    <TooltipConditionalWrapper tooltipProps={tooltipProps}>
      <Text.ExtraBold className="vertical-align--bottom" {...AddedContactsSummaryTitleTextProps}>
        {`${firstContact} ${othersText}`.trim()}
      </Text.ExtraBold>
    </TooltipConditionalWrapper>
  );
};

SummaryOfContactsText.propTypes = {
  partnershipMembers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default SummaryOfContactsText;

import { combineReducers } from 'redux';

import allIdsReducer from './allIdsReducer';
import byIdReducer from './byIdReducer';

const root = combineReducers({
  allIds: allIdsReducer,
  byId: byIdReducer,
});

export default root;

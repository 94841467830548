import { spawn, take } from 'redux-saga/effects';

import { buildServerErrorAlertV2, buildTimeoutErrorAlert, showFormFailureToast } from 'helpers/errors';
import { getShowErrorUI, getShowInfoUI, getShowSuccessUI, getShowWarningUI } from 'helpers/ui';
import { isEqual } from 'helpers/utility';

import { CustomerServiceHelper } from 'modules/customerSupport/CustomerService';

import * as errorTypes from 'types/errors';
import * as supportTypes from 'types/support';
import * as types from 'types/ui';

/**
 * Shows server error SWAL.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* showServerErrorAlert(action) {
  const {
    payload: { errors, requestId },
  } = action;

  buildServerErrorAlertV2(errors, requestId);

  yield null;
}

/**
 * Shows server error SWAL, for request timeouts.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* showTimeoutErrorAlert(action) {
  const {
    payload: { requestId, options },
  } = action;

  buildTimeoutErrorAlert({ itemKind: options.itemKind, requestId });

  yield null;
}

/**
 * Opens an Intercom chat window.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* openIntercomChatWindow(action) {
  const {
    payload: { requestId },
  } = action;

  CustomerServiceHelper.showAndSetRequestId({ requestId });

  yield null;
}

/**
 * Shows a error toast related to a form error
 * @param {Object} action
 * @returns {IterableIterator<*>}
 */
export function* showFormError(action) {
  const {
    payload: { form, options },
  } = action;
  showFormFailureToast(form, options);
  yield null;
}

/**
 * Shows success/error/info toasts
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* showUiToast(action) {
  const {
    type,
    payload: { message, options },
  } = action;

  if (isEqual(type, types.SHOW_ERROR_UI)) {
    getShowErrorUI(message, options)();
  }

  if (isEqual(type, types.SHOW_INFO_UI)) {
    getShowInfoUI(message, options)();
  }

  if (isEqual(type, types.SHOW_SUCCESS_UI)) {
    getShowSuccessUI(message, options)();
  }

  if (isEqual(type, types.SHOW_WARNING_UI)) {
    getShowWarningUI(message, options)();
  }

  yield null;
}

/**
 * Listens for redux actions related to UI.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([
      errorTypes.SHOW_SERVER_ERROR_ALERT,
      errorTypes.SHOW_TIMEOUT_ERROR_ALERT,
      supportTypes.OPEN_INTERCOM_CHAT_WINDOW,
      types.SHOW_ERROR_UI,
      types.SHOW_FORM_ERROR_UI,
      types.SHOW_INFO_UI,
      types.SHOW_SUCCESS_UI,
      types.SHOW_WARNING_UI,
    ]);

    switch (action.type) {
      case errorTypes.SHOW_SERVER_ERROR_ALERT:
        yield spawn(showServerErrorAlert, action);
        break;

      case errorTypes.SHOW_TIMEOUT_ERROR_ALERT:
        yield spawn(showTimeoutErrorAlert, action);
        break;

      case supportTypes.OPEN_INTERCOM_CHAT_WINDOW:
        yield spawn(openIntercomChatWindow, action);
        break;

      case types.SHOW_FORM_ERROR_UI:
        yield spawn(showFormError, action);
        break;

      case types.SHOW_ERROR_UI:
      case types.SHOW_INFO_UI:
      case types.SHOW_SUCCESS_UI:
      case types.SHOW_WARNING_UI:
      case types.SHOW_OCR_UI:
        yield spawn(showUiToast, action);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root UI saga.
 * @return {IterableIterator<*>}
 */
export default function* ui() {
  yield watch();
}

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * A wrapper component for usage with tables, which applies
 * our standard table border and border-radius styles.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 * @constructor
 */
const TableBorders = ({ children, className }) => (
  <div
    className={classNames({
      'table--borders': true,
      [className]: !!className,
    })}
  >
    {children}
  </div>
);

TableBorders.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

TableBorders.defaultProps = {
  children: undefined,
  className: undefined,
};

export default TableBorders;

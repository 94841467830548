import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { FormControl, FormFieldLabel, ReduxFormFieldRenderInput } from 'components';

import { field } from 'constants/styles/formStyles';

import { isCompanyTypePersonal } from 'helpers/currentCompany';
import { requiredValidator } from 'helpers/fieldValidation';

/**
 * Input field for entity's company name
 * @param {ComponentProps} props
 * @param {UserTypes} props.companyType
 * @param {boolean} props.hideLabel
 * @returns {StatelessComponent}
 */
const PartnerCompanyBillingForm = ({ companyType, hideLabel }) => {
  if (isCompanyTypePersonal(companyType)) {
    return null;
  }

  return (
    <React.Fragment>
      {!hideLabel && <FormFieldLabel>Entity info</FormFieldLabel>}

      <FormControl>
        <Field
          component={ReduxFormFieldRenderInput}
          name="company.name"
          props={{
            className: field.xl.full,
            placeholder: 'Legal company name',
            type: 'text',
          }}
          validate={requiredValidator}
        />
      </FormControl>
    </React.Fragment>
  );
};

PartnerCompanyBillingForm.propTypes = {
  companyType: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool,
};

PartnerCompanyBillingForm.defaultProps = {
  hideLabel: undefined,
};

export default PartnerCompanyBillingForm;

import { governmentIdType } from '@routable/companies-management';
import { baseId, labelValueModel } from '@routable/shared';
import { z } from 'zod';
export const governmentIdModel = z.object({
    type: governmentIdType,
    value: z.string(),
});
export const riskCheckStatus = z.enum(['passed', 'issues_found']);
export const riskSummary = z.enum([
    'not_evaluated',
    'queued',
    'cant_validate',
    'passed',
    'review_required',
    'dismissed',
    'outdated',
]);
export const riskStatusLevel = z.enum(['warning', 'error']);
export const riskStatusLabel = z.object({
    level: riskStatusLevel,
    message: z.string(),
});
export const watchlistHitModel = z.object({
    fields: z.array(labelValueModel),
    source: z.object({
        name: z.string(),
        region: z.string(),
    }),
    subjectName: z.string(),
    url: z.string().url(),
});
export const riskCheckType = z.enum(['tin', 'gov', 'watchlist']);
export const riskCheckTinCheckModel = z.object({
    details: z.object({}).nullable(),
    status: riskCheckStatus,
    type: z.literal(riskCheckType.Enum.tin),
});
export const riskCheckGovCheckModel = z.object({
    details: z.object({}).nullable(),
    status: riskCheckStatus,
    type: z.literal(riskCheckType.Enum.gov),
});
export const riskCheckWatchlistModel = z.object({
    details: z.object({
        hits: z.array(watchlistHitModel),
    }),
    updatedAt: z.string(),
    status: riskCheckStatus,
    type: z.literal(riskCheckType.Enum.watchlist),
});
export const riskCheckModel = z.discriminatedUnion('type', [
    riskCheckTinCheckModel,
    riskCheckGovCheckModel,
    riskCheckWatchlistModel,
]);
export const riskCheckReportDismissalModel = z.object({
    at: z.string(),
    name: z.string(),
    memberId: z.string().uuid(),
});
export const riskCheckReportModel = baseId.extend({
    checks: z.array(riskCheckModel),
    createdAt: z.string(),
    dismissal: riskCheckReportDismissalModel.nullable(),
    subject: z.object({
        governmentId: governmentIdModel,
        legalName: z.string(),
    }),
    summary: riskSummary,
});
export const manuallyMoveToVerifiedResponseModel = z.null();
export const bulkRiskChecksResponseModel = z.null();

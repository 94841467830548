import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import avatarModule from 'helpers/avatar';
import { hideImage } from 'helpers/images';

/**
 * Component rendering user Avatar
 *
 * @param {ComponentProps} props
 * @param {string} [props.avatar]
 * @param {string} [props.className]
 * @param {string} props.email
 * @param {string} [props.firstName]
 * @param {string} [props.lastName]
 * @param {string} props.sizeClass
 * @param {string} props.styleClass
 * @returns {StatelessComponent}
 */
const Avatar = ({ avatar, className, email, firstName, lastName, sizeClass, styleClass }) => {
  const containerClassNames = clsx('avatar--container', className);

  const imgClassNames = clsx('avatar', sizeClass, styleClass);
  const initialsClassNames = clsx('avatar', 'initials-container', sizeClass, styleClass);

  const initials = avatarModule.getInitials(firstName, lastName, email);
  const initialsColor = avatarModule.getColor(initials);

  return (
    <div className={containerClassNames}>
      {avatar && <img alt="avatar" className={imgClassNames} onError={hideImage} src={avatar} />}
      <div className={initialsClassNames} style={{ backgroundColor: initialsColor }}>
        <span className="missing-avatar">{initials}</span>
      </div>
    </div>
  );
};

Avatar.propTypes = {
  avatar: PropTypes.string,
  className: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  styleClass: PropTypes.string.isRequired,
  sizeClass: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

Avatar.defaultProps = {
  avatar: undefined,
  className: undefined,
  firstName: undefined,
  lastName: undefined,
};

export default Avatar;

import { formatPhoneNumberIntl } from 'react-phone-number-input';

import { MembershipInvitesStatuses, MembershipMatchWarning, membershipTexts } from 'constants/membership';

import { getCurrentMembershipId } from 'helpers/localStorage';
import { getRoleOrdinalForRoleId, isRoleAdministrator } from 'helpers/permissions';
import { convertNumberToOrdinalString } from 'helpers/stringHelpers';
import { isEqual, or } from 'helpers/utility';

/**
 * Check if the ID on the passed membership is the membership you're looking for.
 * @param {Membership} membership
 * @param {string} targetMembershipId
 * @returns {boolean}
 */
export const isMembershipTargetMembership = (membership, targetMembershipId) =>
  isEqual(membership?.id, targetMembershipId);

/**
 * A method to check whether a membership is the current membership.
 * This method is placed higher in this file because it must be defined before its usage in other helpers.
 * @see getMembershipFullNameAppendingTextToCurrent
 * @param {Membership} membership
 * @param {string} currentMembershipId
 * @return {boolean}
 */
export const isMembershipCurrentMembership = (membership, currentMembershipId = getCurrentMembershipId()) =>
  isMembershipTargetMembership(membership, currentMembershipId);

/**
 * Checks whether the membership is the controller of the company. This is actually the same check as
 * isMembershipTargetMembership, but provides better self-documentation.
 * @param {Membership} membership
 * @param {Company.controller} companyController
 * @returns {boolean}
 */
export const isMembershipCompanyController = (membership, companyController) =>
  isMembershipTargetMembership(membership, companyController);

/**
 * Checks whether the membership role is administrator
 * DO NOT USE these methods to gate functionality.
 * Use permissions instead:
 * https://www.notion.so/routable/Permissions-How-to-gate-a-feature-based-on-a-role-permissions-147c10aea0734ae8a2dd4147e5d05090#56be8cd5c011414db456a2cd6e62f0bb”
 * @param {Role} role
 * @return {boolean}
 */
export const isMembershipRoleAdministrator = (role) => isRoleAdministrator(role.name);

/**
 * Checks whether the member is an affiliate
 * @param {Membership} member
 * @return {boolean}
 */
export const isMembershipAffiliate = (member) => Boolean(!!member && member.isAffiliate);

/**
 * Returns whether or not member contains email
 * @param {Membership} member
 * @return {Boolean}
 */
export const doesMembershipHaveEmailAddress = (member) => Boolean(member && member.email);

/**
 * Returns whether or not the membership has an SSN
 * @param {Object} membership
 * @return {Boolean}
 */
export const doesMembershipHaveSSN = (membership) =>
  Boolean(membership && membership.personalInfo && membership.personalInfo.ssn);

/**
 * Returns the membership email address if present
 * @param {Membership} membership
 * @return {StringMaybe}
 */
export const getMembershipEmailAddress = (membership) =>
  doesMembershipHaveEmailAddress(membership) ? membership.email : undefined;

/**
 * Returns formatted membership phoneNumber.number if present
 * @param {Membership} membership
 * @return {StringMaybe}
 */
export const getMembershipPhoneNumber = (membership) =>
  formatPhoneNumberIntl(membership?.phoneNumber?.number) || undefined;

/**
 * Equality helper to determine if value is the MembershipMatchWarning for an accepted and verified Membership.
 * @param {*} value
 * @returns {boolean}
 */
export const isMembershipMatchWarningAcceptedAndVerified = (value) =>
  isEqual(value, MembershipMatchWarning[MembershipInvitesStatuses.ACCEPTED_AND_VERIFIED]);

/**
 * Equality helper to determine if value is the MembershipMatchWarning for an inactive Membership.
 * @param {*} value
 * @returns {boolean}
 */
export const isMembershipMatchWarningInactive = (value) => isEqual(value, MembershipMatchWarning.INACTIVE);

/**
 * Equality helper to determine if value is the MembershipMatchWarning for a pending MembershipInvite.
 * @param {*} value
 * @returns {boolean}
 */
export const isMembershipMatchWarningPending = (value) =>
  isEqual(value, MembershipMatchWarning[MembershipInvitesStatuses.PENDING]);

/**
 * Equality helper to determine if value is the MembershipMatchWarning for a pending MembershipInvite or if it is an existing Membership with current status.
 * @param {*} value
 * @returns {boolean}
 */
export const isMembershipDuplicated = (value) =>
  or(isMembershipMatchWarningPending(value), isMembershipMatchWarningAcceptedAndVerified(value));

/**
 * Returns the membership's full name. If only first name or last name returns that.
 * @param {Membership|PartnershipMember} membership
 * @return {StringMaybe}
 */
export const getMembershipFullName = (membership) => {
  if (!membership) {
    return undefined;
  }
  if (membership.firstName && membership.lastName) {
    return `${membership.firstName} ${membership.lastName}`;
  }

  if (membership.firstName || membership.lastName) {
    return membership.firstName || membership.lastName;
  }

  return undefined;
};

/**
 * Return an identifying string for the membership, with a preference for the membership's name, defaulting to the
 * email address.
 * @param {Membership|MembershipWithUserData} membership
 * @returns {string|undefined}
 */
export const getMembershipNameOrEmail = (membership) =>
  getMembershipFullName(membership) ?? getMembershipEmailAddress(membership);

/**
 * Return an identifying string for the membership, with a preference for the membership's name, defaulting to the
 * email address or phone number.
 * @param {Membership|MembershipWithUserData} membership
 * @returns {string|undefined}
 */
export const getMembershipNameOrEmailOrPhoneNumber = (membership) =>
  getMembershipFullName(membership) || getMembershipEmailAddress(membership) || getMembershipPhoneNumber(membership);

/**
 *
 * @param {Membership} membership
 * @param {string} appendText
 * @return {string}
 */
export const getMembershipFullNameAppendingTextToCurrent = (membership, appendText = '') => {
  const fullName = getMembershipFullName(membership);

  if (fullName && isMembershipCurrentMembership(membership)) {
    return `${fullName}${appendText}`;
  }

  return fullName;
};

/**
 * Returns a numeric value based on the given condition that will be used to sort the membership by role ordinal
 * and then alphabetically on first name within each role (i.e. if the result of subtracting the role ordinal is 0,
 * return the alphabetical order value)
 * @param {Membership} currentMembership
 * @param {Membership} nextMembership
 * @param {Role[]} roles
 * @return {number}
 */
export const getMembershipsSortedByRoleOrdinalOrAlphabetically = (currentMembership, nextMembership, roles) =>
  or(
    getRoleOrdinalForRoleId(roles, currentMembership.role) - getRoleOrdinalForRoleId(roles, nextMembership.role),
    getMembershipNameOrEmail(currentMembership).localeCompare(getMembershipNameOrEmail(nextMembership)),
  );

/**
 * Check if MembershipInvite is still pending.
 * @param {MembershipInvite} membershipInvite
 * @returns {boolean}
 */
export const isMembershipInviteStatusPending = (membershipInvite) =>
  isEqual(membershipInvite?.status, MembershipInvitesStatuses.PENDING);

/**
 * Returns a boolean depicting whether a membership is disabled
 * @param {Object} membership
 * @param {boolean} membership.isDisabled
 */
export const isMembershipDisabled = (membership) => Boolean(membership.isDisabled);

/**
 * Looks through a list of memberships, returning true if it finds a membership which is disabled.
 * @param {Membership[]} memberships
 * @returns {boolean}
 */
export const isOneMembershipDisabled = (memberships) => !!memberships.find(isMembershipDisabled);

/**
 * Look through a list of Memberships to find the one with the matching ID.
 * @param {Membership[]} memberships
 * @param {string} membershipId
 * @returns {Membership|null}
 */
export const findOneMembershipById = (memberships, membershipId) =>
  memberships?.find((member) => isMembershipTargetMembership(member, membershipId));

/**
 * Check if membership is a Business Representative
 * @param {Membership} membership
 * @returns {boolean}
 */
export const isBusinessRepresentative = (membership) =>
  Boolean(
    !membership?.isDisabled &&
      // with the new sign up flow, we start recording that members are potential representatives
      // when they check the 2 confirmation check-boxes
      ((membership?.agreeIdentity && membership?.agreeBusinessRepresentative) ||
        // once a user submits as a rep, we define this as .hasAllPersonalInfo
        membership?.hasAllPersonalInfo),
  );

/**
 * Returns a boolean whether the membership personal info is filled
 * @param {Object} personalInfo
 * @return {boolean}
 */
export const isMembershipPersonalInfoFilled = (personalInfo) => {
  if (!personalInfo) {
    return false;
  }

  return Boolean(personalInfo.legalFirstName && personalInfo.legalLastName && personalInfo.dateOfBirth);
};

/**
 * A method to map the memberships to select field options
 * @param {object} memberships
 * @return {{id: *, text: *, value: *}[]}
 */
export const mapMembershipsToOptions = (memberships) =>
  // Iterate over users and build the options arr
  Object.values(memberships).map((member) => ({
    id: member.id,
    text: getMembershipFullNameAppendingTextToCurrent(member, ' (me)'),
    value: member.id,
  }));

/**
 * Triggers a toast notification about removed members
 * @param {Object} props
 * @param {Membership[]} props.members
 * @param {number} props.position
 * @param {function} props.onShowToast - dispatches showSuccessUi
 */
export const showRemovedMembersToast = ({ members, position, onShowToast }) => {
  members.forEach((member) => {
    const ordinal = convertNumberToOrdinalString(position);
    onShowToast(`${member.firstName} was removed as a ${ordinal} approver`);
  });
};

/**
 * Returns title string for reactivate team member confirmation alert
 * @param {Membership} membership
 * @return {string}
 */
export const getReactivateTeamMemberConfirmationTitle = (membership) => {
  const memberFullName = getMembershipFullName(membership);
  const memberEmailAddress = getMembershipEmailAddress(membership);

  return `${membershipTexts.REACTIVATE_ACCESS} for ${memberFullName || memberEmailAddress}?`;
};

/**
 * Returns success message when disabling a team member.
 * @param {MembershipWithUserData} membership
 * @returns {string}
 */
export const getDisableSuccessIndicatorMessage = (membership) =>
  `${getMembershipNameOrEmail(membership)} was deactivated`;

/**
 * Returns success message when re-enabling a team member.
 * @param {MembershipWithUserData} membership
 * @returns {string}
 */
export const getEnableSuccessIndicatorMessage = (membership) =>
  `${getMembershipNameOrEmail(membership)} was reactivated`;

/**
 * Returns success message for successfully re-invited team member.
 * @function
 * @param {Membership} membership
 * @returns {string}
 */
export const getReInviteSuccessIndicatorMessages = (membership) =>
  `Invite re-sent to ${getMembershipNameOrEmail(membership)}`;

/**
 * Returns success message for successfully revoked invite.
 * @function
 * @param {Membership} membership
 * @returns {string}
 */
export const getRevokeInviteSuccessIndicatorMessages = (membership) =>
  `Invite for ${getMembershipNameOrEmail(membership)} has been revoked`;

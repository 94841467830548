import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutRequest } from 'actions/auth';
import { toggleLogoutModal } from 'actions/signUpFlow';
import { Modal, ModalFooter, ButtonV2 } from 'components';
import { Text } from 'components/text';
import { ButtonSize } from 'constants/button';
import { colors, typography } from 'constants/styles';
import { TextColor, TextSize } from 'constants/styles/typography';
import { Intent } from 'constants/ui';
import { getSaveStatus } from 'selectors/signupSelectors';
import { SAVE_STATUSES } from '../../constants';
import { BodyContainer, Subtext } from './LogoutModal.styles';
import {} from './LogoutModal.type';
export const ModalBody = ({ saveStatus, onCloseModal, onLogout }) => (React.createElement(React.Fragment, null,
    React.createElement(BodyContainer, null,
        React.createElement(Text.Bold, { color: TextColor.BLUE_DARK, size: TextSize.LEVEL_400 }, "Are you sure you want to log out?"),
        React.createElement(Subtext, null,
            React.createElement(Text.Regular, { color: typography.TextColor.GREY_XX_DARK, lineHeight: typography.TextLineHeight.EXTRA_LARGE, size: typography.TextSize.LEVEL_200 }, saveStatus === SAVE_STATUSES.saved ? ('We’ve saved your progress so far.') : (React.createElement(React.Fragment, null,
                "If you have unsaved changes, click",
                ' ',
                React.createElement(Text.Bold, { color: typography.TextColor.GREY_XX_DARK, lineHeight: typography.TextLineHeight.EXTRA_LARGE, size: typography.TextSize.LEVEL_200 }, "Keep working"),
                ", then save them before logging out."))),
            React.createElement(Text.Regular, { color: typography.TextColor.GREY_XX_DARK, lineHeight: typography.TextLineHeight.EXTRA_LARGE, size: typography.TextSize.LEVEL_200 }, "You'll need to log back in to Routable to verify your business."))),
    React.createElement(ModalFooter, { style: {
            backgroundColor: colors.colorWhiteHex,
        } },
        React.createElement("div", { className: "content--left" },
            React.createElement(ButtonV2, { intent: Intent.NEUTRAL, onClick: onLogout, size: ButtonSize.MEDIUM, type: "button" }, "Logout")),
        React.createElement("div", { className: "content--right" },
            React.createElement(ButtonV2, { onClick: onCloseModal, size: ButtonSize.MEDIUM, type: "button" }, "Keep working")))));
const LogoutModal = ({ open }) => {
    const dispatch = useDispatch();
    const onCloseModal = () => dispatch(toggleLogoutModal());
    const onLogout = () => {
        dispatch(toggleLogoutModal());
        dispatch(logoutRequest());
    };
    const saveStatus = useSelector(getSaveStatus);
    return (React.createElement(Modal, { body: React.createElement(ModalBody, { onCloseModal: onCloseModal, onLogout: onLogout, saveStatus: saveStatus }), hasInlineFooter: true, hideHeader: true, modalState: open, onCloseModal: onCloseModal, overrideStyle: {
            content: {
                maxWidth: '500px',
                backgroundColor: colors.colorWhiteHex,
            },
            overlay: {
                backgroundColor: 'rgba(60, 72, 88, 0.1)',
            },
        } }));
};
export default LogoutModal;

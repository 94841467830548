import ThunkFetch from 'helpers/thunkFetch';

import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { getSubmitAllExistingItemEndpoint } from 'services/api/itemEndpoints';

import {
  SUBMIT_EXISTING_ITEMS_FAILURE,
  SUBMIT_EXISTING_ITEMS_REQUEST,
  SUBMIT_EXISTING_ITEMS_SUCCESS,
} from 'types/item';

/**
 * This is called when submitting a payable/payment
 * https://routablehq.slack.com/archives/C8UV1S84T/p1629130074133500
 * @param {object} payload
 * @param {object} meta
 * @param {string} partnershipId
 * @param {string} action
 * @param {Function} successCallback
 * @returns {function(*=): Promise<null|{errors}|any>}
 */
const submitAllExistingItems = (payload, meta, partnershipId, action, successCallback = null) => {
  const submitPayload = { ...payload };

  // Move funding account to an object for relationship per JSON API format
  if (submitPayload.fundingAccount) {
    submitPayload.fundingAccount = {
      id: submitPayload.fundingAccount,
    };
  }

  // Normalize payload
  const normalizedPayload = payloadToJSONAPI(submitPayload, 'item', 'fundingAccount');

  // Add meta fields
  normalizedPayload.meta = meta;

  const t = new ThunkFetch({
    actions: {
      error: SUBMIT_EXISTING_ITEMS_FAILURE,
      request: SUBMIT_EXISTING_ITEMS_REQUEST,
      success: SUBMIT_EXISTING_ITEMS_SUCCESS,
    },
    endpoint: getSubmitAllExistingItemEndpoint(partnershipId, action),
    method: 'POST',
    payload: normalizedPayload,
    requireAuth: true,
    successCallback,
  });

  return t.makeRequest();
};

export default submitAllExistingItems;

import React from 'react';
import { LinkNewPage } from 'components/link';
import { WhiteSpace } from 'components/text';
import { PLATFORM_EMAILS } from 'constants/platform';
import { Intent } from 'constants/ui';
import { getMailtoUrl } from 'helpers/urls';
import BaseHint from '../BaseHint';
const CsvUnsupportedEncodingHint = () => (React.createElement(BaseHint, { intent: Intent.DANGER, text: React.createElement("span", null,
        "Please check that you are saving the .CSV file with UTF-8 encoding.",
        React.createElement(WhiteSpace, null),
        React.createElement(LinkNewPage, { className: "font-color--primary force-underline", href: "https://docs.routable.com/en/articles/5561195" }, "Click here"),
        React.createElement(WhiteSpace, null),
        "for more details or",
        React.createElement(WhiteSpace, null),
        React.createElement(LinkNewPage, { className: "font-color--primary force-underline", href: getMailtoUrl(PLATFORM_EMAILS.SUPPORT) }, "contact support"),
        React.createElement(WhiteSpace, null),
        "for assistance."), title: "Your file cannot be processed" }));
export default CsvUnsupportedEncodingHint;

import PropTypes from 'prop-types';
import React from 'react';

import { FlexCol, Text } from 'components';

import { typography } from 'constants/styles';

import { isValueEmpty } from 'helpers/utility';

import { ExternalBlockItem } from 'modules/external/ExternalBlocksSection';

/**
 * Component rendering External Receipt memo in Item Details block
 * @param {ComponentProps} props
 * @param {string} props.memo
 * @returns {StatelessComponent}
 */
const ExternalReceiptMemo = ({ memo }) => {
  if (isValueEmpty(memo)) {
    return null;
  }

  return (
    <ExternalBlockItem>
      <FlexCol>
        <Text.Regular color={typography.TextColor.GREY_XX_DARK} size={typography.TextSize.LEVEL_100}>
          Memo
        </Text.Regular>

        <Text.Regular color={typography.TextColor.BLACK} size={typography.TextSize.LEVEL_100}>
          {memo}
        </Text.Regular>
      </FlexCol>
    </ExternalBlockItem>
  );
};

ExternalReceiptMemo.propTypes = {
  memo: PropTypes.string,
};

ExternalReceiptMemo.defaultProps = {
  memo: undefined,
};

export default ExternalReceiptMemo;

import PropTypes from 'prop-types';
import React from 'react';

import { partnerFundingAccountFormFields } from 'constants/formFields';
import { formNamesFunding } from 'constants/forms';
import { countryListOptions } from 'constants/i18n';

import AddressReduxForm from 'modules/address/presenters/AddressReduxForm';

/**
 * Renders input fields for AddPartnerFundingAccount form when payment delivery
 * method type is equal to check
 * @param addressValue
 * @param shouldShowAddressPrintOnCheck
 * @return {StatelessComponent}
 */
const AddPartnerFundingAccountAddress = ({ addressValue, shouldShowAddressPrintOnCheck }) => (
  <AddressReduxForm
    address={addressValue}
    addressPath={partnerFundingAccountFormFields.ADDRESS}
    countryOptions={countryListOptions.US_AND_CANADA}
    formName={formNamesFunding.ADD_PARTNER_FUNDING_ACCOUNT}
    formUI={{ shouldShowAddressPrintOnCheck }}
  />
);

AddPartnerFundingAccountAddress.propTypes = {
  addressValue: PropTypes.shape(),
  shouldShowAddressPrintOnCheck: PropTypes.bool,
};

AddPartnerFundingAccountAddress.defaultProps = {
  addressValue: {},
  shouldShowAddressPrintOnCheck: undefined,
};

export default AddPartnerFundingAccountAddress;

import { uuidModel } from '@routable/shared';
import { z } from 'zod';
export const companyType = z.enum(['business', 'personal']);
export const companyPlatformType = z.enum([
    'added',
    'archived',
    'external',
    'fraud',
    'registered',
    'sanitized',
    'signup',
]);
export const companyAttributesModel = z.object({
    companyType,
    name: z.string(),
    platformType: companyPlatformType,
});
export const companyRelationshipsModel = z.object({
    mailingAddress: z.object({
        data: z.object({
            id: uuidModel,
            type: z.literal('Address'),
        }),
    }),
});
export const companyModel = z.object({
    type: z.literal('Company'),
    id: uuidModel,
    attributes: companyAttributesModel,
    relationships: companyRelationshipsModel,
});

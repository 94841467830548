import { FETCH_NOTIFICATION_TABLE_DATA_SUCCESS } from 'types/notifications';

const byKeyReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATION_TABLE_DATA_SUCCESS:
      return action.payload;

    default:
      return state;
  }
};

export default byKeyReducer;

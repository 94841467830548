import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import withPartnerFundingAccounts from 'hoc/withPartnerFundingAccounts';

import { isSubmittingPartnershipFundingAccountOrAddressSelector } from 'selectors/partnershipsSelectors';

import PartnerPaymentMethodSelection from './PartnerPaymentMethodSelection';

export const mapStateToProps = createStructuredSelector({
  isSubmitting: isSubmittingPartnershipFundingAccountOrAddressSelector,
});

const enhance = compose(connect(mapStateToProps), withPartnerFundingAccounts);

export default enhance(PartnerPaymentMethodSelection);

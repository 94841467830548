import { membershipInviteAcceptV2Routine, membershipInviteSubmitRoutine } from 'actions/routines/inviteTeamMember';

import { createErrorReducer } from 'store/redux';

import {
  CLEAR_MEMBERSHIP_INVITE,
  FETCH_MEMBERSHIP_INVITE_FAILURE,
  FETCH_MEMBERSHIP_INVITES_FAILURE,
  FETCH_MEMBERSHIP_INVITE_REQUEST,
  FETCH_MEMBERSHIP_INVITE_SUCCESS,
  FETCH_MEMBERSHIP_INVITES_REQUEST,
  FETCH_MEMBERSHIP_INVITES_SUCCESS,
} from 'types/inviteTeamMember';

export const failureCases = [
  FETCH_MEMBERSHIP_INVITE_FAILURE,
  FETCH_MEMBERSHIP_INVITES_FAILURE,
  membershipInviteAcceptV2Routine.FAILURE,
  membershipInviteSubmitRoutine.FAILURE,
];

export const successCases = [
  CLEAR_MEMBERSHIP_INVITE,
  FETCH_MEMBERSHIP_INVITE_REQUEST,
  FETCH_MEMBERSHIP_INVITE_SUCCESS,
  FETCH_MEMBERSHIP_INVITES_REQUEST,
  FETCH_MEMBERSHIP_INVITES_SUCCESS,
  membershipInviteAcceptV2Routine.REQUEST,
  membershipInviteAcceptV2Routine.SUCCESS,
  membershipInviteSubmitRoutine.REQUEST,
  membershipInviteSubmitRoutine.SUCCESS,
];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

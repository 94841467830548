import {
  ContactSidePanelType,
  sidePanelNameAddOrUpdateVendorRegisteredAddress,
  sidePanelNameAddVendorRegisteredAddressInForm,
} from 'constants/sidePanels';

import * as types from 'types/sidePanels';

/**
 * openSidePanel
 * generic action that can be used to open any sidepanel
 * @param {object} props
 * @param {string} props.name
 * @param {object} [props.state]
 * @returns {ReduxAction}
 */
export const openSidePanel = ({ name, state = {} }) => ({
  payload: { name, state },
  type: types.SIDE_PANEL_OPEN,
});

/**
 * closeSidePanel
 * generic action that can be used to close any sidepanel
 * @param {object} props
 * @param {string} props.name
 * @returns {ReduxAction}
 */
export const closeSidePanel = ({ name }) => ({
  payload: { name },
  type: types.SIDE_PANEL_CLOSE,
});

/**
 * Creates an action to open the ContactSidePanel.
 * @param {ComponentProps} payloadOptions
 * @param {Company} payloadOptions.company - Company to add this contact to (has companyType and name)
 * @param {string} [payloadOptions.form] - Name of the parent form that triggered the side panel, if applicable
 * @param {ObjectMaybe} [payloadOptions.initialValues] - Initial values for the form contained within the side panel
 * @param {StringMaybe} [payloadOptions.input]  - Input value provided by react-select, if creating contact via
 * a new select option
 * @param {StringMaybe} [payloadOptions.item] - Item to add this contact to, once it's created, if applicable
 * @param {boolean} [payloadOptions.onlyThisItem] - Whether to show the select field for accessItem
 * @param {StringMaybe} [payloadOptions.partnershipId] - ID of the partnership to add this contact to, if applicable
 * @param {ObjectMaybe} [payloadOptions.partnershipMember] - If updating an existing contact, this is their
 * PartnershipMember data
 * @param {function} [payloadOptions.submitHandler] - Use a custom submit handler when submitting the form contained
 * within the side panel
 * @param {ContactSidePanelType} payloadOptions.type - Which side panel variant to open (create/update)
 * @param {ObjectMaybe} [payloadOptions.ui] - Values to change the display/UI of the side panel
 * @return {ReduxAction}
 */
export const contactSidePanelOpen = (payloadOptions) => {
  const {
    company,
    form,
    initialPartnershipMember,
    initialValues,
    input,
    item,
    onlyThisItem,
    partnershipId,
    partnershipMember,
    submitHandler,
    type,
    ui,
  } = payloadOptions;

  return {
    payload: {
      company,
      form,
      initialPartnershipMember,
      initialValues,
      input,
      item,
      onlyThisItem,
      partnershipId,
      partnershipMember,
      submitHandler,
      type,
      ui,
    },
    type: types.CONTACT_SIDE_PANEL_OPEN,
  };
};

/**
 * Creates an action to open the CreateContactSidePanel (convenience action creator
 * so the ContactSidePanelType doesn't need to be imported).
 * @param {ComponentProps} props
 * @param {Company} props.company
 * @param {string} [props.form]
 * @param {ObjectMaybe} [props.initialValues]
 * @param {StringMaybe} props.input
 * @param {ObjectMaybe} [props.item]
 * @param {boolean} [props.onlyThisItem]
 * @param {StringMaybe} props.partnershipId
 * @param {ObjectMaybe} [props.partnershipMember]
 * @param {function} [props.submitHandler]
 * @param {ObjectMaybe} [props.ui]
 * @return {ReduxAction}
 */
export const createContactSidePanelOpen = ({
  company,
  form,
  initialValues,
  input,
  item,
  onlyThisItem,
  partnershipId,
  partnershipMember,
  submitHandler,
  ui,
}) =>
  contactSidePanelOpen({
    company,
    form,
    initialValues,
    input,
    item,
    onlyThisItem,
    partnershipId,
    partnershipMember,
    submitHandler,
    type: ContactSidePanelType.CREATE,
    ui,
  });

/**
 * Creates an action to open the UpdateContactSidePanel (convenience action creator
 * so the ContactSidePanelType doesn't need to be imported).
 * @param {Object} params
 * @param {Company} params.company
 * @param {string} params.form
 * @param {PartnershipMember} params.initialPartnershipMember
 * @param {StringMaybe} params.partnershipId
 * @param {PartnershipMember} params.partnershipMember
 * @return {ReduxAction}
 */
export const updateContactSidePanelOpen = ({
  company,
  form,
  initialPartnershipMember,
  partnershipId,
  partnershipMember,
}) =>
  contactSidePanelOpen({
    company,
    form,
    initialPartnershipMember,
    partnershipId,
    partnershipMember,
    type: ContactSidePanelType.UPDATE,
  });

/**
 * Creates an action to close the ContactSidePanel.
 * @return {{type: string}}
 */
export const contactSidePanelClose = () => ({
  type: types.CONTACT_SIDE_PANEL_CLOSE,
});

/**
 * Creates an action to close the AddOrUpdateVendorRegisteredAddress side panel
 * @returns {ReduxAction}
 */
export const closeAddOrUpdateVendorRegisteredAddressSidePanel = () =>
  closeSidePanel({
    name: sidePanelNameAddOrUpdateVendorRegisteredAddress,
  });

/**
 * Creates an action to close the AddVendorRegisteredAddressInForm side panel
 * @returns {ReduxAction}
 */
export const closeAddVendorRegisteredAddressInFormSidePanel = () =>
  closeSidePanel({
    name: sidePanelNameAddVendorRegisteredAddressInForm,
  });

import PropTypes from 'prop-types';
import React from 'react';

import { CountryFlag, Text } from 'components';

import { typography } from 'constants/styles';

import { formatItemAmount, getItemDueOrPaidText } from 'helpers/items';

import { useCurrencyContext } from 'hooks';

import { ExternalBlock, ExternalBlockItem } from 'modules/external/ExternalBlocksSection';

import { ExternalReceiptItemDetails } from './components';

import './ExternalReceiptHeader.scss';

/**
 * Component rendering External Receipt Single Item Header section
 * @param {Object} props
 * @param {Item} props.item
 * @param {CountryCode} [props.partnershipCountryCode]
 * @returns {FunctionComponent}
 */
const ExternalReceiptHeader = ({ item, partnershipCountryCode }) => {
  const currencyCodeMap = useCurrencyContext();
  const suffix = getItemDueOrPaidText(item);

  return (
    <ExternalBlock className="external-receipt-header">
      <ExternalBlockItem>
        <Text.Regular color={typography.TextColor.GREY_X_DARK} size={typography.TextSize.LEVEL_200}>
          {`Amount ${suffix}`}
        </Text.Regular>

        <Text.Regular
          className="external-receipt-header__price"
          color={typography.TextColor.BLACK}
          size={typography.TextSize.UNSET}
        >
          {formatItemAmount(currencyCodeMap, item)}

          {!!partnershipCountryCode && (
            <CountryFlag className="external-receipt-header__price__flag" countryCode={partnershipCountryCode} />
          )}
        </Text.Regular>
      </ExternalBlockItem>

      <ExternalReceiptItemDetails item={item} />
    </ExternalBlock>
  );
};

ExternalReceiptHeader.propTypes = {
  item: PropTypes.shape({
    amountDue: PropTypes.string,
    amountPaid: PropTypes.string,
  }).isRequired,
  partnershipCountryCode: PropTypes.string,
};

ExternalReceiptHeader.defaultProps = {
  partnershipCountryCode: undefined,
};

export default ExternalReceiptHeader;

import { Tooltip } from '@routable/components';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Wrap the children in a TooltipPane if the user supplies tooltipProps. Otherwise, just return the children.
 *
 * @deprecated Use Tooltip } from '@routable/components' instead
 * @see Storybook > MenuPopover > MenuPopoverTarget > Tooltip MenuItems? knob
 * @param {ComponentProps} props
 * @param {ChildNode} [props.children]
 * @param {ObjectMaybe} [props.tooltipProps]
 * @returns {StatelessComponent}
 */
const TooltipConditionalWrapper = ({ children = null, tooltipProps }) => {
  const { content: tooltipContent, tooltipPosition, tooltipProps: nestedTooltipProps } = tooltipProps || {};
  // There are two ways of how we pass tooltip position via props to the TooltipConditionalWrapper
  const position = tooltipPosition || nestedTooltipProps?.position;

  return tooltipContent ? (
    <Tooltip position={position} tooltip={tooltipContent}>
      {children}
    </Tooltip>
  ) : (
    children
  );
};

TooltipConditionalWrapper.propTypes = {
  children: PropTypes.node,
  tooltipProps: PropTypes.shape(),
};

TooltipConditionalWrapper.defaultProps = {
  children: undefined,
  tooltipProps: undefined,
};

export default TooltipConditionalWrapper;

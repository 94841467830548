import { combineReducers } from 'redux';

import errorReducer from './errorReducer';
import submitReducer from './submitReducer';

const authConvertExternalReducer = combineReducers({
  errors: errorReducer,
  isSubmitting: submitReducer,
});

export default authConvertExternalReducer;

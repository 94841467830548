import { queryContext } from '@routable/shared';
import { useMutation } from '@tanstack/react-query';
import { purchaseOrderService } from '../purchaseOrder.service';
export const useDiscrepancies = () => {
    return useMutation({
        context: queryContext,
        mutationFn: async (input) => purchaseOrderService.calculateDiscrepancies(input),
        mutationKey: ['purchase_orders', 'discrepancies'],
    });
};

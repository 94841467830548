import {} from '@routable/shared';
import { isFundingAccountEligibleForRTP } from 'helpers/funding';
import { sortArray } from 'helpers/sort';
export const getSelectedOrBestFitFundingAccount = (selectedFundingAccountId, selectedPaymentMethod, fundingAccounts = [], currencyCode) => {
    const applicableAccounts = [];
    let primaryAccountId;
    let preferredAccountId;
    fundingAccounts.forEach((fundingAccount) => {
        const currencyCodeMatches = !currencyCode || fundingAccount.currencyCode === currencyCode;
        if (fundingAccount.paymentDeliveryMethod === selectedPaymentMethod && currencyCodeMatches) {
            applicableAccounts.push(fundingAccount.id);
        }
        if (fundingAccount.isPrimary && currencyCodeMatches) {
            primaryAccountId = fundingAccount.id;
        }
        if (fundingAccount.isPreferred && currencyCodeMatches) {
            preferredAccountId = fundingAccount.id;
        }
    });
    if (selectedFundingAccountId && applicableAccounts.includes(selectedFundingAccountId)) {
        return selectedFundingAccountId;
    }
    if (primaryAccountId && applicableAccounts.includes(primaryAccountId)) {
        return primaryAccountId;
    }
    if (preferredAccountId && applicableAccounts.includes(preferredAccountId)) {
        return preferredAccountId;
    }
    if (applicableAccounts.length >= 1) {
        return applicableAccounts[0];
    }
    return undefined;
};
export const sortFundingAccountsForDisplay = (fundingAccounts = [], selectedFundingAccount, currencyCode) => {
    const primaryWeight = 8;
    const preferredWeight = 4;
    const rtpWeight = 2;
    const selectedWeight = 1;
    const currencyCodeWeight = 1 + primaryWeight + preferredWeight + rtpWeight + selectedWeight;
    const getTotalWeight = (fundingAccount) => (fundingAccount.isPrimary ? primaryWeight : 0) +
        (fundingAccount.isPreferred ? preferredWeight : 0) +
        (isFundingAccountEligibleForRTP(fundingAccount) ? rtpWeight : 0) +
        (fundingAccount.id === selectedFundingAccount ? selectedWeight : 0) +
        (fundingAccount.currencyCode === currencyCode ? currencyCodeWeight : 0);
    const comparisonFunc = (fundingAccountA, fundingAccountB) => {
        const totalFundingAccountA = getTotalWeight(fundingAccountA);
        const totalFundingAccountB = getTotalWeight(fundingAccountB);
        return totalFundingAccountB - totalFundingAccountA;
    };
    return sortArray(fundingAccounts, comparisonFunc);
};

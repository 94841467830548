import { useQuery } from '@tanstack/react-query';
import { queryContext } from '@routable/shared';
import { conditionApi, conditionDataApi } from '../services';
export const useConditions = () => useQuery({
    context: queryContext,
    queryKey: ['conditions'],
    queryFn: async () => conditionApi.readAll(),
    refetchOnMount: 'always',
});
export const useConditionsData = ({ name, url }) => useQuery({
    queryKey: ['conditionsData', name],
    queryFn: async () => conditionDataApi.readAll(url),
    refetchOnMount: 'always',
    context: queryContext,
});

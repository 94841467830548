import { createSelector } from 'reselect';

/**
 * Top level permissionGroups selector
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
const getState = (state) => state.permissionGroups;

/**
 * Selects all IDs of permissionGroups from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {string[]}
 */
export const permissionGroupsAllIdsSelector = createSelector([getState], (permissionGroups) => permissionGroups.allIds);

/**
 * Selects permissionGroups by id from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object<PermissionGroup.id, PermissionGroup>}
 */
export const permissionGroupsByIdSelector = createSelector([getState], (permissionGroups) => permissionGroups.byId);

/**
 * Selects the permissionGroups errors from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
export const permissionGroupsErrorsSelector = createSelector([getState], (permissionGroups) => permissionGroups.errors);

/**
 * Selects the isFetching boolean for permissionGroups from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {boolean}
 */
export const isFetchingPermissionGroupsSelector = createSelector(
  [getState],
  (permissionGroups) => permissionGroups.isFetching,
);

/**
 * Selects the lastFetched data for permissionGroups from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {DateMaybe}
 */
export const permissionGroupsLastFetchedSelector = createSelector(
  [getState],
  (permissionGroups) => permissionGroups.lastFetched,
);

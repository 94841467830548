import * as types from 'types/export';

/**
 * Redux action for requesting the cancellation of a CSV async export
 * @param exportFileId
 * @returns {ReduxAction}
 */
export const cancelExportRequest = (exportFileId) => ({
  type: types.CANCEL_EXPORT_REQUEST,
  payload: { exportFileId },
});

/**
 * Redux action for opening the exportCsv modal
 * @returns {ReduxAction}
 */
export const exportCsvModalOpen = () => ({
  type: types.MODAL_EXPORT_CSV_OPEN,
});

/**
 * Redux action for closing the exportCsv modal
 * @returns {ReduxAction}
 */
export const exportCsvModalClose = () => ({
  type: types.MODAL_EXPORT_CSV_CLOSE,
});

/**
 * Redux action for successfully cancelling the
 * export to csv
 * @returns {ReduxAction}
 */
export const cancelExportSuccess = () => ({
  type: types.CANCEL_EXPORT_SUCCESS,
});

/**
 * Redux action for clearing the export file id
 * @returns {ReduxAction}
 */
export const cancelClearExportFileId = () => ({
  type: types.CANCEL_CLEAR_EXPORT_FILE_ID,
});

/**
 * Redux action for closing the exportCsv modal
 * @returns {ReduxAction}
 */
export const cancelExportFailed = () => ({
  type: types.CANCEL_EXPORT_FAILED,
});

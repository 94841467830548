import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { fetchPartnershipReceivableFundingAccountsRequest } from 'actions/partnership';

import { partnerFundingAccountFormFields } from 'constants/formFields';
import { formNamesFunding } from 'constants/forms';

import {
  getInitialAddPartnerFundingAccountValues,
  onAddressToggleOffShowPrintOnCheck,
  onPartnerFundingAccountOptionsChange,
  onSubmitFailAddPartnerFundingAccountForm,
} from 'helpers/addPartnerFundingAccount';

import { onConnectBankRoutingNumberChange } from 'modules/connectBank/connectBankManual/helpers/bankName';

import { addPartnerFundingAccountFormMetaCompoundSelector } from 'queries/addPartnerFundingAccountCompoundSelectors';

import { fundingAccountsByIdSelector, fundingInfoAddressesByIdSelector } from 'selectors/fundingSelectors';
import {
  partnershipFromPropsSelector,
  partnershipFundingAccountsForPartnershipIdSelector,
} from 'selectors/partnershipsSelectors';

import {
  submitAddPartnershipFundingAccount,
  submitAddPartnershipFundingAddress,
  submitAddPartnershipFundingBankAccount,
} from 'thunks/submitAddPartnershipFundingAccount';

import AddPartnerFundingAccountForm from './AddPartnerFundingAccountForm';

const addPartnerFundingAccountForm = reduxForm({
  addressPath: partnerFundingAccountFormFields.ADDRESS,
  form: formNamesFunding.ADD_PARTNER_FUNDING_ACCOUNT,
  onChange: (...args) => {
    onConnectBankRoutingNumberChange(...args);
    onAddressToggleOffShowPrintOnCheck(...args);
    onPartnerFundingAccountOptionsChange(formNamesFunding.ADD_PARTNER_FUNDING_ACCOUNT, ...args);
  },
  onSubmit: submitAddPartnershipFundingAccount,
  onSubmitFail: onSubmitFailAddPartnerFundingAccountForm,
  routingNumberPath: partnerFundingAccountFormFields.BANK_ACCOUNT_ROUTING_NUMBER,
})(AddPartnerFundingAccountForm);

export const mapStateToProps = (state, ownProps) => {
  const { formValues, isFormInitialized, isInvalid, isSubmitting } =
    addPartnerFundingAccountFormMetaCompoundSelector(state);
  const partnership = partnershipFromPropsSelector(state, ownProps);
  const fundingAccountsById = fundingAccountsByIdSelector(state);
  const fundingInfoAddressesById = fundingInfoAddressesByIdSelector(state);
  const partnershipFundingAccounts = partnershipFundingAccountsForPartnershipIdSelector(state, ownProps.partnershipId);

  return {
    formValues,
    isFormInitialized,
    isInvalid,
    initialValues: getInitialAddPartnerFundingAccountValues({
      partnership,
      partnershipFundingAccounts,
      options: { fundingAccountsById, fundingInfoAddressesById, partnership },
    }),
    isSubmitting,
    partnershipFundingAccounts,
    partnership,
  };
};

export const mapDispatchToProps = {
  onFetchPartnershipReceivableFundingAccounts: fetchPartnershipReceivableFundingAccountsRequest,
  onSubmitAddPartnershipFundingAddress: submitAddPartnershipFundingAddress,
  onSubmitAddPartnershipFundingBankAccount: submitAddPartnershipFundingBankAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(addPartnerFundingAccountForm);

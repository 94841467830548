import { getApiTokenBody } from 'helpers/api';

import {
  CREATE_API_TOKEN_SUCCESS,
  DELETE_API_TOKEN_SUCCESS,
  FETCH_API_TOKENS_SUCCESS,
  REGENERATE_API_TOKEN_SUCCESS,
} from 'types/apiToken';

const tokensReducer = (state = [], action) => {
  switch (action.type) {
    case CREATE_API_TOKEN_SUCCESS:
      return [
        ...state.map((token) => ({ ...token, isNew: false })),
        getApiTokenBody({
          payload: action.payload.apiToken,
          tokenId: Object.keys(action.payload.apiToken)[0],
          isNew: true,
        }),
      ];
    case DELETE_API_TOKEN_SUCCESS:
      return state.filter((token) => token.id !== action.payload.tokenId);
    case FETCH_API_TOKENS_SUCCESS:
      if (action.payload.apiToken) {
        // if this ever support more apiTokens, this will simplify refactor
        return Object.keys(action.payload.apiToken).map((tokenId) =>
          getApiTokenBody({
            payload: action.payload.apiToken,
            tokenId,
            isNew: false,
          }),
        );
      }

      return state;
    case REGENERATE_API_TOKEN_SUCCESS:
      return [
        // remove old token
        ...state.filter((token) => token.id !== action.payload.tokenId).map((token) => ({ ...token, isNew: false })),
        // add new token
        getApiTokenBody({
          payload: action.payload.apiToken,
          tokenId: action.payload.tokenId,
          isNew: true,
        }),
      ];

    default:
      return state;
  }
};

export default tokensReducer;

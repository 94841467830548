import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Wrapper element to style the top section of the InfoCard.
 * @param {object} [children]
 * @param {string} [className]
 * @param {object} [style]
 * @returns {StatelessComponent}
 * @constructor
 */
const InfoCardHeader = ({ children, className, style }) => (
  <div
    className={classNames({
      'info-card--header': true,
      [className]: !!className,
    })}
    style={style}
  >
    {children}
  </div>
);

InfoCardHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

InfoCardHeader.defaultProps = {
  children: undefined,
  className: undefined,
  style: undefined,
};

export default InfoCardHeader;

import React from 'react';

import { LinkNewPage } from 'components';

import helpDocs from 'helpers/helpDocs';

/**
 * For ConnectBankManual, gets the mid-form text blurb
 * that displays, based on whether we're connecting a partner
 * funding account, or our own funding account.
 * @param {boolean} [isPartnerAccount=false]
 * @return {string}
 */
export const getConnectBankAccountMidFormLabelText = (isPartnerAccount = false) => {
  const explanationText = isPartnerAccount
    ? "Your vendor's bank account will be available for use as soon as it's entered."
    : 'To add your bank account, you will need to verify it with two small micro-deposits.';

  return (
    <>
      {explanationText}
      {' Please make sure you have the correct routing number. '}
      <LinkNewPage className="primary" href={helpDocs.ACCOUNT_AND_ROUTING_NUMBERS}>
        Learn more about types of routing numbers
      </LinkNewPage>
    </>
  );
};

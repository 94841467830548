import { createItemFormFields } from '@routable/shared';
import { createSelector } from 'reselect';

import { SORT_ORDER } from 'constants/sort';

import { PartnershipCountryCodeKey } from 'enums/partnerships';

import { isInvoiceApprovalRequired } from 'helpers/approvals';
import { isKindPayable } from 'helpers/items';
import { isInternationalPartnership, getPartnershipCompanyRefPerItem } from 'helpers/partnerships';
import { isBulkImportItemsRoute } from 'helpers/routeHelpers';
import { isSelectedPartnerTypeExisting } from 'helpers/searchCompanies';
import { sortObjectArray } from 'helpers/sort';
import { allValues, isEqual, lengthOf } from 'helpers/utility';

import { getPartnerPaymentOptions } from 'modules/dashboard/createItems/helpers/paymentOptions';

import {
  currentCompanyIsPoMatchingEnabledSelector,
  currentCompanyItemApprovalLevelsAllSelector,
  currentCompanySettingsIsInternationalPaymentsEnabledSelector,
} from 'selectors/currentCompanySelectors';
import {
  createItemFormUiSkipPurchaseOrderSelector,
  createItemFormUiSelectedCompanySelector,
  createItemFormSelector,
  createItemFormItemSelector,
  createItemFormMainSelector,
  createItemFormUiSelector,
  createItemFormUiIsPurchaseOrderFetchedSelector,
  createItemFormUiShowInvoiceGeneratorSelector,
  createItemFormUiIsItemEditSelector,
  createItemFormUiShowLineItemsWithinFormSelector,
  createItemFormUiIsReinitializingFormSelector,
} from 'selectors/forms';
import {
  fundingAccountsByIdSelector,
  fundingInfoInternationalByIdSelector,
  fundingSupportedCountriesByCountryCodeWithEmptyUS,
} from 'selectors/fundingSelectors';
import { allStateSelector } from 'selectors/globalSelectors';
import { allInvoicesSelector, invoicesSelector } from 'selectors/invoicesSelectors';
import { itemsArraySelector } from 'selectors/itemsSelectors';
import { partnershipPaymentByIdSelector } from 'selectors/partnershipPaymentSelector';
import { partnershipsFundingAccountsByIdSelector } from 'selectors/partnershipsSelectors';
import { locationSelector } from 'selectors/routerSelectors';

/**
 * Gets partnerFundingAccount from the form state for creating an item
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object}
 */
export const formItemPartnerFundingAccount = createSelector(
  createItemFormItemSelector,
  (item) => item?.partnerFundingAccount,
);

/**
 * Get the form partnership for creating an item
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object}
 */
export const formPartnershipSelector = createSelector([allStateSelector], (state) =>
  createItemFormSelector(state, createItemFormFields.PARTNERSHIP),
);

export const createItemUISelectedCompanyCurrencyCodePartnerSelector = createSelector(
  [createItemFormUiSelectedCompanySelector],
  (partnership) => partnership?.currencyCodePartner,
);

/**
 * Gets isItemEdit from createItem form's UI
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const formUIIsItemEditSelector = createSelector(createItemFormUiSelector, (ui) => ui?.isItemEdit);

/**
 * Get the amount from form state when creating an item
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {NumberMaybe}
 */
export const createItemAmountSelector = createSelector([createItemFormItemSelector], (item) => item?.amount);

/**
 * Get the fundingProviderMemo value from form state when creating an item
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {StringMaybe}
 */
export const createItemFundingProviderMemoSelector = createSelector(
  [createItemFormItemSelector],
  (item) => item?.fundingProviderMemo,
);

/**
 * Get the paymentDeliveryOption from form state when creating an item
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {StringMaybe}
 */
export const createItemPaymentDeliveryOptionSelector = createSelector(
  [createItemFormItemSelector],
  (item) => item?.paymentDeliveryOption,
);

/**
 * Get the paymentDeliveryMethod from form state when creating an item
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {StringMaybe}
 */
export const createItemPaymentDeliveryMethodSelector = createSelector(
  [createItemFormItemSelector],
  (item) => item?.paymentDeliveryMethod,
);

/**
 * Get the selected company partner from form UI
 * @type {StandardSelector}
 * @returns {Company|undefined}
 */
export const createItemSelectedCompanyPartnerSelector = createSelector(
  [createItemFormUiSelector],
  (ui) => ui?.selectedCompany?.partner,
);

/**
 * Get the selected company partner name from form UI
 * @type {StandardSelector}
 * @returns {Company.name|undefined}
 */
export const createItemSelectedCompanyNameSelector = createSelector(
  [createItemFormUiSelector],
  (ui) => ui?.selectedCompany?.name,
);

/**
 * Get the selected company partner name from form UI
 * @type {StandardSelector}
 * @returns {Company.Partner.name|undefined}
 */
export const createItemSelectedCompanyPartnerNameSelector = createSelector(
  [createItemSelectedCompanyPartnerSelector],
  (partner) => partner?.name,
);

/**
 * Should we send this item?
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const sendItemSelector = createSelector([createItemFormUiSelector], (ui) => ui?.sendItem);

/**
 * Get the partner company ref (vendorRef, customerRef) for an item being created
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {StringMaybe} vendorRef, customerRef, or null
 */
export const createItemPartnerCompanyRefSelector = createSelector(
  [createItemFormUiSelector, createItemFormItemSelector],
  (formUI, item) => getPartnershipCompanyRefPerItem(formUI.selectedCompany, item),
);

/**
 * Returns true if current form ui requires a purpose code
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const createItemUIIsPurposeCodeRequiredSelector = createSelector(
  [createItemFormUiSelector],
  (uiState) => uiState?.isPurposeCodeRequired,
);

/**
 * Returns item purpose code
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {StringMaybe}
 */
export const createItemPurposeCodeSelector = createSelector([createItemFormItemSelector], (item) => item?.purposeCode);

/**
 * Get all of the invoices from the ledger for a selected partner company
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {LedgerInvoice[]}
 */
export const createItemInvoicesSelector = createSelector(
  [allInvoicesSelector, createItemPartnerCompanyRefSelector],
  (allInvoices, companyRef) => {
    const invoices = companyRef
      ? allInvoices.filter((invoice) => isEqual(invoice.companyRef, companyRef))
      : allInvoices;

    const sortKeys = [{ dateDue: SORT_ORDER.DESC }, { dateModified: SORT_ORDER.DESC }];
    return sortObjectArray(invoices, sortKeys);
  },
);

/**
 * County the number of invoices from the ledger for a selected partner company
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {number}
 */
export const createItemInvoicesCountSelector = createSelector([createItemInvoicesSelector], (invoices) =>
  lengthOf(invoices),
);

/**
 * Get all of the selected ledgerInvoiceRefs from form state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {string[]} Array of invoice refs
 */
export const ledgerInvoiceRefsSelector = createSelector([allStateSelector], (state) =>
  createItemFormSelector(state, 'ledgerInvoiceRefs'),
);

/**
 * Count the number of selected ledgerInvoiceRefs from form state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {number}
 */
export const selectedInvoicesCountSelector = createSelector([ledgerInvoiceRefsSelector], (ledgerInvoiceRefs) =>
  lengthOf(allValues(ledgerInvoiceRefs).filter(Boolean)),
);

/**
 * Using the select invoice refs in the form, get the full invoice from state.
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {LedgerInvoice[]}
 */
export const selectedInvoicesSelector = createSelector(
  [invoicesSelector, ledgerInvoiceRefsSelector],
  (invoices, refs) => {
    if (!invoices || !refs) {
      return undefined;
    }

    return Object.keys(refs).reduce((accum, id) => {
      if (refs[id] && !!invoices[id]) {
        return accum.concat(invoices[id]);
      }

      return accum;
    }, []);
  },
);

/**
 * Get currency code of the first selected invoice
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {CurrencyCode|undefined}
 */
export const selectedInvoicesCurrencyCodeSelector = createSelector(
  [selectedInvoicesSelector],
  (selectedInvoices) => selectedInvoices?.[0]?.currencyCode,
);

/**
 * Get amounts listed on the selected invoices.
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {number[]|undefined}
 */
export const selectedInvoicesAmountsSelector = createSelector([selectedInvoicesSelector], (selectedInvoices) =>
  selectedInvoices?.map?.((invoice) => Number.parseFloat(invoice.amountDue)),
);

/**
 * Get selected invoices country codes.
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {string[]|[]}
 */
export const selectedInvoicesCountryCodeSelector = createSelector(
  [selectedInvoicesSelector],
  (invoices) => invoices?.filter((invoice) => invoice?.countryCode)?.map((invoice) => invoice.countryCode) ?? [],
);

/**
 * Sum the amount of selected invoices.
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {number}
 */
export const selectedInvoicesTotalAmountSelector = createSelector(
  [selectedInvoicesAmountsSelector],
  (invoiceAmounts) => invoiceAmounts?.reduce?.((sum, amt) => sum + amt, 0) ?? 0,
);

/**
 * Get the max amount of selected invoices amounts.
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {number}
 */
export const selectedInvoicesMaxAmountSelector = createSelector([selectedInvoicesAmountsSelector], (invoiceAmounts) =>
  invoiceAmounts?.length ? Math.max(...invoiceAmounts) : 0,
);

/**
 * Do the selected invoices require approval?
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const selectedInvoicesRequireApprovalSelector = createSelector(
  [selectedInvoicesSelector, currentCompanyItemApprovalLevelsAllSelector],
  (selectedInvoices, approvalLevels) =>
    (selectedInvoices || []).some((invoice) => isInvoiceApprovalRequired(approvalLevels, invoice)),
);

/**
 * Returns the selected bills in CreateItemForm
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object[]|undefined}
 */
export const createItemBillsSelector = createSelector([createItemFormItemSelector], (item) => item?.bills);

/**
 * Returns true if current form ui is bill view
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const createItemIsBillViewSelector = createSelector(
  [createItemFormUiSelector],
  (uiState) => uiState?.isBillView,
);

/**
 * Returns the items for approvals based on current route
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Item[]}
 */
export const selectedItemsForApprovalsSelector = createSelector(
  [itemsArraySelector, selectedInvoicesSelector, locationSelector],
  (items, selectedInvoices, location) => (isBulkImportItemsRoute(location) ? items : selectedInvoices),
);

/**
 * Returns an Item's MarkAsPaid status value.
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const selectMarkAsPaidStatusSelector = createSelector(
  [createItemFormUiSelector],
  (uiState) => uiState?.showMarkAsPaid,
);

/**
 * Gets Bill currency from the form state for creating an item
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {string}
 */
export const createItemBillCurrencySelector = createSelector(
  [createItemFormMainSelector],
  (main) => main?.general?.bill_currency,
);

/**
 * Gets currencyCode from the form state for creating an item
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {string}
 */
export const createItemCurrencyCodeSelector = createSelector(
  [createItemFormItemSelector],
  (item) => item?.currencyCode,
);

/**
 * Gets currencyCodeReceiver from the form state for creating an item
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {string}
 */
export const createItemCurrencyCodeReceiverSelector = createSelector(
  [createItemFormItemSelector],
  (item) => item?.currencyCodeReceiver,
);

/**
 * Get routesPayable object from selected company
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object}
 */
export const partnershipPaymentRoutesPayableSelector = createSelector(
  [partnershipPaymentByIdSelector, createItemFormUiSelectedCompanySelector],
  (partnershipPaymentById, selectedCompany) =>
    partnershipPaymentById[selectedCompany?.id]?.attributes.routesPayable || {},
);

/**
 * Get the form partnership payment options
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object}
 */
export const partnerPaymentOptionsSelector = createSelector(
  [
    fundingSupportedCountriesByCountryCodeWithEmptyUS,
    createItemFormUiSelectedCompanySelector,
    createItemBillCurrencySelector,
    createItemCurrencyCodeSelector,
    partnershipPaymentRoutesPayableSelector,
    createItemCurrencyCodeReceiverSelector,
    currentCompanySettingsIsInternationalPaymentsEnabledSelector,
  ],
  (
    supportedCountryCodes,
    selectedCompany,
    billCurrency,
    currencyCode,
    routesPayable,
    currencyCodeReceiver,
    isInternationalPaymentsEnabled,
  ) => {
    const isInternational = isInternationalPartnership(selectedCompany, PartnershipCountryCodeKey.PARTNER);

    return getPartnerPaymentOptions({
      billCurrency,
      currencyCode,
      currencyCodeReceiver,
      isInternationalPaymentsEnabled,
      isInternational,
      routesPayable,
      selectedCompany,
      supportedCountryCodes,
    });
  },
);

/**
 * Gets funding info international based on the selected funding account
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object}
 */
export const createItemFundingInfoInternationalSelector = createSelector(
  [
    createItemFormItemSelector,
    partnershipsFundingAccountsByIdSelector,
    fundingAccountsByIdSelector,
    fundingInfoInternationalByIdSelector,
  ],
  (item, partnershipsFundingAccountsById, fundingAccountsById, fundingInfoInternationalById) => {
    const partnerReceivableAccountId = item?.partnerReceivableAccount;
    if (!partnerReceivableAccountId) {
      return null;
    }

    const partnerReceivableAccount = partnershipsFundingAccountsById[partnerReceivableAccountId];
    const fundingAccount = fundingAccountsById[partnerReceivableAccount?.fundingAccount];
    const fundingInfoInternational = fundingInfoInternationalById[fundingAccount?.international];

    return fundingInfoInternational;
  },
);

/**
 * Determines whether to render PO section
 * @type {StandardSelector}
 * @return {Boolean}
 */
export const createItemFormShouldShowPoSectionSelector = createSelector(
  [createItemFormItemSelector, createItemFormUiShowInvoiceGeneratorSelector, currentCompanyIsPoMatchingEnabledSelector],
  (item, showInvoiceGenerator, isPoMatchingEnabled) =>
    isKindPayable(item?.kind) && showInvoiceGenerator && isPoMatchingEnabled,
);

/**
 * Determines if form sections below PO should be rendered
 * @type {StandardSelector}
 * @returns {Boolean}
 */
export const createItemFormShowRestOfFormSelector = createSelector(
  [
    createItemFormShouldShowPoSectionSelector,
    createItemFormUiIsItemEditSelector,
    createItemFormItemSelector,
    createItemFormUiSkipPurchaseOrderSelector,
    createItemFormUiIsPurchaseOrderFetchedSelector,
    createItemFormUiIsReinitializingFormSelector,
  ],
  (showPoSection, isItemEdit, item, isPoSkipped, isPOFetched, isReinitializingForm) => {
    // If in item edit, check if the item has a PO linked:
    // yes -> wait for the PO to be loaded before showing rest of form
    // no -> show rest of form
    if (showPoSection && isItemEdit) {
      return item.purchaseOrders?.length && !isPoSkipped ? isPOFetched : true;
    }

    // in PO Matching, we want to only show the rest of the form if a PO is selected,
    if (showPoSection && !isPoSkipped) {
      return !isReinitializingForm && isPOFetched;
    }

    // in non PO Matching or if user has clicked on "Skip PO", we always want to show the rest of the form at this point
    return true;
  },
);

/**
 * Determines if the line items drawer should be hidden
 * @type {StandardSelector}
 * @returns {Boolean}
 */
export const createItemFormLineItemsHiddenSelector = createSelector(
  [createItemFormUiSelector, createItemFormShowRestOfFormSelector, createItemFormUiShowLineItemsWithinFormSelector],
  (formUI, showRestOfForm, showLineItemsInDrawer) =>
    !showRestOfForm ||
    !formUI?.showInvoiceGenerator ||
    !showLineItemsInDrawer ||
    !isSelectedPartnerTypeExisting(formUI?.selectedCompany?.type),
);

import React from 'react';

import { TagType } from 'constants/ui';

import Tag from '../Tag';

/**
 * Locked tag that reads "Desktop only".
 * @returns {StatelessComponent}
 */
const DesktopOnlyTag = () => (
  <Tag className="desktop-only-tag" hasIcon isClosable={false} readOnly type={TagType.LOCKED}>
    <span className="desktop-only--text">Desktop only</span>
  </Tag>
);

export default DesktopOnlyTag;

import { connect } from 'react-redux';

import { fundingAccountsIsFetchingSelector, fundingInfoBankAccountForIdSelector } from 'selectors/fundingSelectors';

import BankAccount from './BankAccount';

const mapStateToProps = (state, ownProps) => ({
  fundingInfoBankAccount:
    ownProps.fundingInfoBankAccount || fundingInfoBankAccountForIdSelector(state, ownProps.fundingAccount?.bank),
  isFetchingFundingAccounts: fundingAccountsIsFetchingSelector(state),
});

export default connect(mapStateToProps)(BankAccount);

import { z } from 'zod';
import { ApproverStatus } from './approver.model';
export const approvalStatusSummaryModel = z.object({
    objectId: z.string().uuid(),
    canApprove: z.boolean(),
    canApproveAndSend: z.boolean(),
    userApprovalStatus: ApproverStatus,
});
export const itemsApprovalsSummaryApiModel = z.object({
    data: z.object({
        membershipId: z.string().uuid(),
        approvalStatuses: z.array(approvalStatusSummaryModel),
    }),
});

import { updateCurrentCompanySettingsIntegrationRoutine } from 'actions/routines/currentCompany';

import { createIsUpdatingReducer } from 'store/redux';

export const finishCases = [
  updateCurrentCompanySettingsIntegrationRoutine.FAILURE,
  updateCurrentCompanySettingsIntegrationRoutine.SUCCESS,
];
export const requestCases = [updateCurrentCompanySettingsIntegrationRoutine.REQUEST];

const isUpdatingReducer = createIsUpdatingReducer(finishCases, requestCases);

export default isUpdatingReducer;

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

/**
 * Keys on the Membership type.
 * @enum {string}
 */
export const MembershipKey = {
  DISABLED_BY_ID: 'disabledById',
  DISABLED_DATE: 'disabledDate',
};

/**
 * @enum {string}
 */
export const MembershipInvitesStatuses = {
  ACCEPTED_AND_VERIFIED: 'accepted_and_verified',
  EXPIRED: 'expired',
  PENDING: 'pending',
};

/**
 * When searching memberships for a matching email address, if you find the email address, we display one of the errors
 * below.
 * @enum {string}
 */
export const MembershipMatchWarning = {
  [MembershipInvitesStatuses.ACCEPTED_AND_VERIFIED]: MembershipInvitesStatuses.ACCEPTED_AND_VERIFIED,
  [MembershipInvitesStatuses.PENDING]: MembershipInvitesStatuses.PENDING,
  INACTIVE: 'inactive',
};

export const membershipTitles = [
  { id: 'd6a46600-22c3-4027-a6ba-2d2acd1fd019', value: 'CEO', text: 'CEO' },
  { id: '18603d30-067d-4b06-ad32-6c869ca6b245', value: 'CFO', text: 'CFO' },
  {
    id: '91f47cb4-c0e6-45a5-b2bf-e6f45ee0b7ca',
    value: 'Controller',
    text: 'Controller',
  },
  {
    id: 'f9dbc563-29cd-4347-b449-239bfa9fde52',
    value: 'Accounts Payable',
    text: 'Accounts Payable',
  },
  {
    id: '638d8a1d-9585-4cb6-aeb3-fe92c73089a1',
    value: 'Accounts Receivable',
    text: 'Accounts Receivable',
  },
  { id: '886fa4b5-1278-42d1-b3cc-1a6eb18f31f9', value: 'Other', text: 'Other' },
];

export const membershipTexts = {
  CONFIRM_REACTIVATION: 'Confirm reactivation',
  REACTIVATE_ACCESS: 'Reactivate access',
  REACTIVATE_NOTE: `Reactivating this team member will immediately give them access to your ${PLATFORM_DISPLAY_SHORT_NAME} account.`,
  REVOKE_INVITE: 'Revoke invite',
};

import PropTypes from 'prop-types';
import React from 'react';

import { getExistingPaymentsOnLedgerTextAndTitle } from 'components/hintTypes/helpers/text';

import { Intent } from 'constants/ui';

import { isValueEmpty } from 'helpers/utility';

import BaseHint from '../BaseHint';

/**
 * Hint to display information about companies with no associated contacts.
 * @param {Ledger} ledger
 * @return {StatelessComponent}
 */
const ExistingPaymentsOnLedgerHint = ({ ledger }) => {
  if (isValueEmpty(ledger)) {
    return null;
  }

  const { text, title } = getExistingPaymentsOnLedgerTextAndTitle(ledger);

  return <BaseHint intent={Intent.WARNING} multiline text={text} title={title} />;
};

ExistingPaymentsOnLedgerHint.propTypes = {
  ledger: PropTypes.shape(),
};

ExistingPaymentsOnLedgerHint.defaultProps = {
  ledger: undefined,
};

export default ExistingPaymentsOnLedgerHint;

import {} from '@reduxjs/toolkit/query/react';
import {} from 'axios';
import {} from 'actions/modifiers/meta.types';
import { getURLWithQueryParams } from 'helpers/urls';
import {} from 'interfaces/global';
import {} from 'interfaces/routableApiResponse';
import { FetchService } from 'services';
import { BASE_API_URL } from 'services/api';
export const generateAxiosBaseQuery = (fetchService) => ({ baseUrl = BASE_API_URL } = {}) => async ({ method = 'GET', data, params, url = '', endpoint, requestFile = false, ...args }) => {
    try {
        const endpointWithParams = getURLWithQueryParams(endpoint ?? baseUrl + url, params);
        if (requestFile) {
            const fileResponse = await fetchService.requestFile(endpointWithParams, {
                ...args,
                method,
                responseType: 'blob',
            });
            if (fileResponse.ok) {
                return { data: await fileResponse.blob() };
            }
            return {
                error: { status: fileResponse.status, data: fileResponse },
            };
        }
        const response = await fetchService.request({
            endpoint: endpointWithParams,
            method,
            data,
            ...args,
        });
        if (response.ok) {
            return { data: response.data };
        }
        return {
            error: {
                status: response.status,
                data: response,
                requestId: response.headers?.['request-id'],
            },
        };
    }
    catch (axiosError) {
        const err = axiosError;
        return {
            error: {
                status: err.response?.status,
                data: err.response?.data || err.message,
                requestId: err.response?.headers?.['request-id'],
            },
        };
    }
};
export const axiosBaseQuery = generateAxiosBaseQuery(FetchService);

import { updateCompany } from 'actions/companies';
import {
  fetchExternalPartnershipRequestRoutine,
  submitPartnerCompanyGeneralInfoRoutine,
} from 'actions/routines/external';

import { getAttributesAndRelationshipsForReducer } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import getRelationships from 'store/redux';

import { SUBMIT_COMPANY_AUTH_REP_SUCCESS } from 'types/company';
import {
  GET_CURRENT_COMPANY_SUCCESS,
  UPDATE_CURRENT_COMPANY_SUCCESS,
  UPDATE_ONBOARDING_COMPANY_SUCCESS,
  UPDATE_ONBOARDING_COMPANY_TYPE_SUCCESS,
} from 'types/currentCompany';
import {
  FETCH_MEMBERSHIPS_SUCCESS,
  FETCH_MEMBERSHIP_SUCCESS,
  UPDATE_ONBOARDING_MEMBERSHIP_SUCCESS,
} from 'types/memberships';

const getCurrentCompany = (companies) => {
  let company = {};

  if (!companies) {
    return company;
  }

  Object.keys(companies).forEach((companyId) => {
    const relationships = getRelationships(
      companies,
      companyId,
      'brand',
      'documents',
      'info',
      'mailingAddress',
      'physicalAddress',
      'settings',
      'settingsIntegration',
    );
    company = {
      id: companies[companyId].id,
      ...companies[companyId].attributes,
      ...relationships,
    };
  });

  return company;
};

/**
 * Returns current company for given partnership request by looking at the
 * "company" relation
 * @param {Payload} payload
 * @return {Company}
 */
const getCurrentCompanyForPartnershipRequest = (payload) => {
  // The "company" property contains the info about the RCTM's vendor/customer/company that is
  // present in the partnership request flow
  const { company: companyId } = getAttributesAndRelationshipsForReducer(payload.partnershipRequest, ['company']);

  // If for some reason company is not defined in partnershipRequest as a relation,
  // we return empty object
  if (!companyId) {
    return {};
  }

  // We grab the correct company info by using the company ID
  const company = payload.company[companyId];

  // We pass that company to the getCurrentCompany, which will grab all of the
  // necessary relationships
  return getCurrentCompany({ [companyId]: company });
};

const companyReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MEMBERSHIP_SUCCESS:
    case FETCH_MEMBERSHIPS_SUCCESS:
    case GET_CURRENT_COMPANY_SUCCESS:
    case SUBMIT_COMPANY_AUTH_REP_SUCCESS:
    case submitPartnerCompanyGeneralInfoRoutine.SUCCESS:
    case UPDATE_CURRENT_COMPANY_SUCCESS:
    case UPDATE_ONBOARDING_COMPANY_SUCCESS:
    case UPDATE_ONBOARDING_COMPANY_TYPE_SUCCESS:
    case UPDATE_ONBOARDING_MEMBERSHIP_SUCCESS:
    case updateCompany.type:
      return deepMergeWithArrayReplacement(state, getCurrentCompany(action.payload.company));

    case fetchExternalPartnershipRequestRoutine.SUCCESS:
      return deepMergeWithArrayReplacement(state, getCurrentCompanyForPartnershipRequest(action.payload));

    default:
      return state;
  }
};

export default companyReducer;

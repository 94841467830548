import { mapOverKeys } from 'helpers/utility';

import { FETCH_BILLING_CODES_SUCCESS } from 'types/billing';

const getAllBillingCodes = (billingCodes) =>
  mapOverKeys(billingCodes, (billingCodeId) => {
    const { attributes } = billingCodes[billingCodeId];
    return attributes.code;
  });

const allCodesReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_BILLING_CODES_SUCCESS:
      return getAllBillingCodes(action.payload.billingCode);

    default:
      return state;
  }
};

export default allCodesReducer;

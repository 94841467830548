import React, { forwardRef, memo } from 'react';
export const IconWrapper = memo(forwardRef(({ color, icon, size, title, ...props }, ref) => {
    if (!icon) {
        return null;
    }
    const iconProps = {
        color,
        size,
        title,
        width: size,
        height: size,
    };
    let iconWithProps = null;
    if (typeof icon === 'string' || typeof icon === 'function') {
        const Component = icon;
        iconWithProps = React.createElement(Component, { ref: ref, ...iconProps });
    }
    else if (React.isValidElement(icon)) {
        iconWithProps = React.cloneElement(icon, {
            ...iconProps,
            ...icon.props,
            ref,
        });
    }
    return React.createElement("div", { style: { display: 'inline-flex', ...props } }, iconWithProps);
}));

import {} from 'interfaces/item';
import {} from 'interfaces/itemFilters';
import { singleSelectCheckboxAction, multiselectCheckboxAction, } from 'modules/dashboard/itemListTables/helpers/helpers';
import * as types from 'types/tables';
import { withModifierKeys, withThrottle } from './modifiers';
import {} from './tables.types';
export const applyPayablesTableFilters = (filters, opt = {}) => ({
    payload: { filters, ...opt },
    type: types.APPLY_PAYABLES_TABLE_FILTERS,
});
export const applyReceivablesTableFilters = (filters, opt = {}) => ({
    payload: { filters, ...opt },
    type: types.APPLY_RECEIVABLES_TABLE_FILTERS,
});
export const applyPayablesTableSort = (sortParam) => ({
    payload: { sortParam },
    type: types.APPLY_PAYABLES_TABLE_SORT,
});
export const applyReceivablesTableSort = (sortParam) => ({
    payload: { sortParam },
    type: types.APPLY_RECEIVABLES_TABLE_SORT,
});
export const applyCompanyPaymentHistoryTableSort = (sortParam) => ({
    payload: { sortParam },
    type: types.APPLY_COMPANY_PAYMENT_HISTORY_TABLE_SORT,
});
export const toggleSingleTableRowSelection = (isSelected, tab, item, meta) => withThrottle({ id: `table-toggle-${item.id}`, timeout: 100 })(withModifierKeys(singleSelectCheckboxAction({
    isSelect: !isSelected,
    tab,
    payload: { item },
    meta,
})));
export const toggleAllTableRowsSelection = ({ areAllSelected, areSomeSelected, items, tab, }) => multiselectCheckboxAction({
    isSelect: !(areAllSelected || areSomeSelected),
    tab,
    payload: { items },
});
export const deselectAllRows = () => ({
    type: types.DESELECT_ALL_ROWS,
});

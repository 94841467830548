import { createIsDoingReducer } from 'store/redux';

import { SUBMIT_TAX_INFO_SUCCESS, SUBMIT_TAX_INFO_FAILURE, SUBMIT_TAX_INFO_REQUEST } from 'types/taxes';

export const finishCases = [SUBMIT_TAX_INFO_FAILURE, SUBMIT_TAX_INFO_REQUEST];
export const requestCases = [SUBMIT_TAX_INFO_SUCCESS];

const submitSucceededReducer = createIsDoingReducer(finishCases, requestCases);

export default submitSucceededReducer;

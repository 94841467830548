import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import StatusBadge from 'components/statusBadge';

const BankAccountInfoStatus = ({ displayStatus, displayText, status }) => {
  // Hide if no status exists (no partnershipFundingAccount)
  if (!status) {
    return null;
  }

  return (
    <span
      className={classNames({
        'account-status': true,
        [status.toLowerCase()]: true,
      })}
    >
      <StatusBadge
        dataFullStory
        displayStatus={displayStatus}
        displayText={displayText}
        status={status}
        textClassName="account-status--text"
      />
    </span>
  );
};

BankAccountInfoStatus.propTypes = {
  displayStatus: PropTypes.string,
  displayText: PropTypes.string,
  status: PropTypes.string,
};

BankAccountInfoStatus.defaultProps = {
  displayStatus: undefined,
  displayText: undefined,
  status: undefined,
};

export default BankAccountInfoStatus;

import PropTypes from 'prop-types';
import React from 'react';

import { isFn } from 'helpers/utility';

/**
 * Wrapper to normalize props across custom Option
 * components used in Selects.
 *
 * RS sometimes uses callback props like selectOption
 * and clearValue, and we use the onAction callback style,
 * so this ensures we can always pass/use onSelect/onClear.
 *
 * Also passes the current value of the Select, instead
 * of a function to acquire it.
 *
 * @param {constructor} Component
 * @returns {constructor}
 */
const withOptionWrapper = (Component) => {
  const ComponentWithOptionWrapper = (props) => {
    const { children, clearValue, data, getValue, onClear, onSelect, onSetValue, selectOption, setValue, ...rest } =
      props;

    return (
      <Component
        data={data}
        onClear={() => {
          if (isFn(onClear)) {
            // if we're passing the on-clear prop
            onClear();
          } else {
            // if react-select passes the on-clear prop
            clearValue();
          }
        }}
        onSelect={() => {
          if (isFn(onSelect)) {
            // if we're passing the on-select prop
            onSelect(data);
          } else {
            // if react-select passes the on-select prop
            selectOption(data);
          }
        }}
        onSetValue={(value, action) => {
          if (isFn(onSetValue)) {
            onSetValue(value, action);
          } else {
            setValue(value, action);
          }
        }}
        selectValue={getValue()}
        {...rest}
      >
        {children}
      </Component>
    );
  };

  ComponentWithOptionWrapper.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    clearValue: PropTypes.func.isRequired,
    getValue: PropTypes.func.isRequired,
    onClear: PropTypes.func,
    onSelect: PropTypes.func,
    onSetValue: PropTypes.func,
    selectOption: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    data: PropTypes.shape().isRequired,
  };

  ComponentWithOptionWrapper.defaultProps = {
    children: undefined,
    className: undefined,
    onClear: undefined,
    onSelect: undefined,
    onSetValue: undefined,
  };

  return ComponentWithOptionWrapper;
};

export default withOptionWrapper;

import getRelationships from 'store/redux';

import { FETCH_UNMATCHED_LEDGER_CLEARING_ACCOUNTS_SUCCESS } from 'types/clearingAccounts';

const getById = (ledgerClearingAccounts) => {
  if (!ledgerClearingAccounts) {
    return {};
  }

  const integrationLedgerClassList = {};

  Object.keys(ledgerClearingAccounts).forEach((ledgerClearingAccountId) => {
    const relationships = getRelationships(ledgerClearingAccounts, ledgerClearingAccountId);

    integrationLedgerClassList[ledgerClearingAccountId] = {
      id: ledgerClearingAccounts[ledgerClearingAccountId].id,
      ...ledgerClearingAccounts[ledgerClearingAccountId].attributes,
      ...relationships,
    };
  });

  return integrationLedgerClassList;
};

const byIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_UNMATCHED_LEDGER_CLEARING_ACCOUNTS_SUCCESS:
      return getById(action.payload.fundingAccount);

    default:
      return state;
  }
};

export default byIdReducer;

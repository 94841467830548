import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { LOGO } from 'global/images/platform';
import { getTopLevelDomain, isPathLogin } from 'helpers/urls';
import './BrandLogo.scss';
import { GET_STARTED } from '../../constants/routes';
import {} from './BrandLogo.types';
const BrandLogo = ({ imageSrc, path, location }) => {
    const logo = React.createElement("img", { alt: PLATFORM_DISPLAY_SHORT_NAME, src: imageSrc });
    if (isPathLogin(location)) {
        const domain = getTopLevelDomain();
        return (React.createElement("a", { className: "logo-container", href: domain }, logo));
    }
    let parsedPath = path;
    if (location.pathname.includes(GET_STARTED)) {
        parsedPath = location.pathname;
    }
    return (React.createElement(Link, { className: "logo-container", to: parsedPath }, logo));
};
BrandLogo.defaultProps = {
    imageSrc: LOGO.WHITE,
    path: '/',
};
export default withRouter(BrandLogo);

import { DomesticCountryCodes, StaticCountryCode } from 'constants/countries';
import { isEqual } from './utility';
export const isCountryCodeUSOrUSTerritory = (countryCode) => {
    const isCountryCodeDomestic = DomesticCountryCodes.includes(countryCode);
    return isCountryCodeDomestic || countryCode === '';
};
export const isCountryCodeCanada = (countryCode) => isEqual(countryCode, StaticCountryCode.CA);
export const getCountryNameFromCode = (countryCodeMap, countryCode) => countryCodeMap[countryCode]?.fullName;
export const mapCountryInfosToSelectOptions = (countryInfos = []) => countryInfos.map(({ fullName, id }) => ({ label: fullName, value: id }));
export const isCountryCodeUsTerritoryOrCanada = (countryCode) => isCountryCodeCanada(countryCode) || isCountryCodeUSOrUSTerritory(countryCode);

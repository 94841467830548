import React from 'react';
import { IconNames } from 'components/icon';
import { getDisplayName } from 'helpers/hoc';
import { or } from 'helpers/utility';
import useClipboard from 'hooks/useClipboard';
import PaymentMethodDetailItemAction from '../../PaymentMethodDetailItemAction';
import { PaymentMethodDetailAction } from '../enums';
const withCopyAction = (PaymentMethodDetailItem) => {
    const Component = (props) => {
        const newProps = { ...props };
        const { actions, actionsElements = [], clipboardValue, label, revealedValue, value } = props;
        const isCopyable = actions.includes(PaymentMethodDetailAction.COPY);
        const ref = useClipboard({
            copyValue: or(revealedValue, clipboardValue, value),
        });
        if (isCopyable) {
            newProps.actionsElements = [
                ...actionsElements,
                React.createElement(PaymentMethodDetailItemAction, { icon: IconNames.DUPLICATE, key: "payment-method-detail-item", ref: ref, tooltipTitle: `Copy ${label}` }),
            ];
        }
        return React.createElement(PaymentMethodDetailItem, { ...newProps });
    };
    Component.displayName = `${getDisplayName(PaymentMethodDetailItem)}WithCopyAction`;
    return Component;
};
export default withCopyAction;

import { createSelector } from 'reselect';

import { companySelector } from 'selectors/companiesSelectors';

import { idSelector } from './globalSelectors';

const getState = (state) => state.addresses;

/**
 * Selects the addresses by id from the state
 * @param {object} state - Redux state
 */
export const addressesSelector = createSelector([getState], (addresses) => addresses.byId);

/**
 * Selects a single address for a given id param. Returns null if address
 * is not found
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {Id} addressId
 * @returns {Address|null}
 */
export const addressSelector = createSelector(
  [addressesSelector, idSelector],
  (addressesById, addressId) => addressesById[addressId] ?? null,
);

/**
 * Selects the isSubmitting property of the addresses state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const addressesIsSubmittingSelector = createSelector([getState], (addresses) => addresses.isSubmitting);

/**
 * Selects the errors property of the addresses state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {UnknownObject|null}
 */
export const addressesErrorsSelector = createSelector([getState], (addressesState) => addressesState.errors);

/**
 * Selects the mailing address id belonging to a specific company
 * @param {object} state - Redux state
 * @param {string} companyId - Company id
 */
export const companyMailingAddressIdSelector = createSelector(
  [companySelector],
  (company = {}) => company.mailingAddress,
);

/**
 * Selects the mailing address belonging to a specific company
 * @param {object} state - Redux state
 * @param {string} companyId - Company id
 */
export const companyMailingAddressSelector = createSelector(
  [addressesSelector, companyMailingAddressIdSelector],
  (addresses, companyAddressId) => companyAddressId && addresses[companyAddressId],
);

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';
import { IconNames } from 'components/icon';

import { ButtonSize } from 'constants/button';
import { PLATFORM_EMAILS } from 'constants/platform';
import { colors, sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

import withChatOnClick from 'hoc/withChatOnClick';

import './MessageSupport.scss';

/**
 * Message support button which opens Intercom or Help Scout, falling back to email address. We use Intercom for
 * internal pages (f.e. dashboard, item creation) and Help Scout for external pages (f.e. accept payment).
 * @param {string} [className]
 * @param {string} [message] - Could be used to pass prefill information to Intercom or Help Scout.
 * @param {function} onClick
 * @returns {StatelessComponent}
 */
const MessageSupport = ({ className, onClick }) => (
  <ButtonV2
    className={classNames({
      [className]: !!className,
    })}
    intent={Intent.NEUTRAL}
    leftIconClassName="margin-right--xm"
    leftIconColor={colors.colorGreyDarkHex}
    leftIconName={IconNames.CHAT}
    leftIconSize={sizes.iconSizes.LARGE}
    onClick={onClick}
    size={ButtonSize.MEDIUM}
  >
    Message support
  </ButtonV2>
);

MessageSupport.propTypes = {
  className: PropTypes.string,
  // Both fallbackEmail and message used by HOC
  // eslint-disable-next-line react/no-unused-prop-types
  fallbackEmail: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  message: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

MessageSupport.defaultProps = {
  className: undefined,
  fallbackEmail: PLATFORM_EMAILS.SUPPORT,
  message: undefined,
};

export { MessageSupport };
export default withChatOnClick(MessageSupport);

import PropTypes from 'prop-types';
import React from 'react';

// Custom cell renderer for react-virtualized tables
const CellRenderer = ({ rowData, columnData }) =>
  React.createElement(columnData.obj, {
    ...columnData.props,
    rowData,
  });

CellRenderer.propTypes = {
  rowData: PropTypes.shape().isRequired,
  columnData: PropTypes.shape().isRequired,
};

export default CellRenderer;

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ItemStatuses } from 'enums/items';

import { itemFromQuerySelector } from 'queries/itemRouterSelectors';

import ExternalPromptSingleItemFailed from '../global/presenters/ExternalPromptSingleItemFailed';

import ExternalPromptVendorSingleItemCanceled from './ExternalPromptVendorSingleItemCanceled';
import ExternalPromptVendorSingleItemConfirmation from './ExternalPromptVendorSingleItemConfirmation';
import ExternalPromptVendorSingleItemExternallyPaid from './ExternalPromptVendorSingleItemExternallyPaid';
import ExternalPromptVendorSingleItemPending from './ExternalPromptVendorSingleItemPending';

const ExternalPromptVendorSingleItem = ({ item, onStart }) => {
  switch (item.status) {
    case ItemStatuses.EXTERNALLY_PAID:
      return <ExternalPromptVendorSingleItemExternallyPaid />;

    case ItemStatuses.NEW:
      return <ExternalPromptVendorSingleItemPending onStart={onStart} />;

    case ItemStatuses.PROCESSING:
    case ItemStatuses.COMPLETED:
    case ItemStatuses.INITIATED:
      return <ExternalPromptVendorSingleItemConfirmation />;

    case ItemStatuses.CANCELED:
      return <ExternalPromptVendorSingleItemCanceled />;

    case ItemStatuses.ISSUE:
    case ItemStatuses.FAILED:
      return <ExternalPromptSingleItemFailed />;

    default:
      return null;
  }
};

ExternalPromptVendorSingleItem.propTypes = {
  item: PropTypes.shape().isRequired,
  onStart: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  item: itemFromQuerySelector,
});

export { ExternalPromptVendorSingleItem };
export default connect(mapStateToProps)(ExternalPromptVendorSingleItem);

import { getClassNames } from 'helpers/ui';

/**
 * Assign a .static-phone-number class and apply merge any class names from the wrapperClassName via props.
 * @function
 * @param {ComponentProps} props
 * @returns {string}
 */
export const getClassNamesForWrapper = (props) =>
  getClassNames(props, { 'static-phone-number': true }, { classProp: 'wrapperClassName' });

/**
 * Assign .static-phone-number-flag class to the flag SVG. Merge any class names from the flagClassName via
 * props.
 * @function
 * @param {ComponentProps} props
 * @returns {string}
 */
export const getClassNamesForFlag = (props) => {
  const requiredClasses = { 'static-phone-number--flag': true };
  const options = { classProp: 'flagClassName' };

  return getClassNames(props, requiredClasses, options);
};

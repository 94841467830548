import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import ConfirmationSectionHeader from 'components/confirmation/ConfirmationSectionHeader';

import { isItemCompletedACHPayment, isItemInitiatedACHPayment } from 'helpers/items';

import ExternalPromptHeader from 'modules/external/externalPrompt/global/presenters/ExternalPromptHeader';
import ExternalPromptInstructions from 'modules/external/externalPrompt/global/presenters/ExternalPromptInstructions';
import NextSteps from 'modules/external/externalPrompt/global/presenters/NextSteps';
import { IsLoading } from 'modules/isLoading';

import { fundingAccountFromQueryItemSelector } from 'queries/fundingCompoundSelectors';
import { itemFromQuerySelector } from 'queries/itemRouterSelectors';
import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

import PromptHeaderReceivablePaid from './components/PromptHeaderReceivablePaid';

class ExternalPromptCustomerSingleItemConfirmation extends React.Component {
  renderHeaderPaymentRemitted = () => {
    const { item, partnership } = this.props;

    if (!isItemCompletedACHPayment(item)) {
      return null;
    }

    return <ExternalPromptHeader text={`Your payment to ${partnership.name} has deposited.`} />;
  };

  renderHeaderPaymentSent = () => {
    const { item } = this.props;

    if (!isItemInitiatedACHPayment(item)) {
      return null;
    }

    return <PromptHeaderReceivablePaid />;
  };

  renderBodyItemCompletedAchPayment = () => {
    const { fundingAccount, item } = this.props;

    if (!isItemCompletedACHPayment(item)) {
      return null;
    }

    const instructions = [
      {
        id: '✔',
        key: '1',
        text: `Funds have been withdrawn from your selected bank account (${fundingAccount.name}).`,
      },
    ];

    return <ExternalPromptInstructions instructions={instructions} />;
  };

  renderBodyItemInitiatedAchPayment = () => {
    const { fundingAccount, item, partnership } = this.props;

    if (!isItemInitiatedACHPayment(item)) {
      return null;
    }

    const instructions = [
      {
        id: 1,
        key: '1',
        text: 'You should receive an email shortly confirming your invoice payment.',
      },
      {
        id: 2,
        key: '2',
        text: `${partnership.name} will receive your payment within 2-3 business days.`,
      },
      {
        id: 3,
        key: '3',
        text:
          'Payments that you make in the future will be withdrawn ' +
          `from your selected bank account (${fundingAccount.name}).`,
      },
    ];

    return (
      <React.Fragment>
        <NextSteps />
        <ExternalPromptInstructions instructions={instructions} />
      </React.Fragment>
    );
  };

  render() {
    const { fundingAccount, item } = this.props;

    // Ensure item and fundingAccount are present (they might still be loading, or changing)
    if (!item || !fundingAccount) {
      return <IsLoading />;
    }

    return (
      <React.Fragment>
        <ConfirmationSectionHeader text="Confirmed" />

        {/* Prompt header */}
        {this.renderHeaderPaymentSent()}
        {this.renderHeaderPaymentRemitted()}

        {/* Prompt body */}
        {this.renderBodyItemCompletedAchPayment()}
        {this.renderBodyItemInitiatedAchPayment()}
      </React.Fragment>
    );
  }
}

ExternalPromptCustomerSingleItemConfirmation.propTypes = {
  fundingAccount: PropTypes.shape(),
  item: PropTypes.shape(),
  partnership: PropTypes.shape().isRequired,
};

ExternalPromptCustomerSingleItemConfirmation.defaultProps = {
  fundingAccount: undefined,
  item: undefined,
};

const mapStateToProps = (state, ownProps) => ({
  item: itemFromQuerySelector(state, ownProps),
  fundingAccount: fundingAccountFromQueryItemSelector(state, ownProps),
  partnership: partnershipFromQuerySelector(state, ownProps),
});

export { ExternalPromptCustomerSingleItemConfirmation };
export default connect(mapStateToProps)(ExternalPromptCustomerSingleItemConfirmation);

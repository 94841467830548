import {} from 'constants/partnershipMember';
import { ContactSidePanelType } from 'constants/sidePanels';
import {} from 'interfaces/item';
import {} from 'interfaces/partnerships';
import {} from './GroupedContactSelectsInForm.types';
export const getOnCreateContactOptions = (input, selectedCompany, autoFill, item) => {
    const options = {
        company: selectedCompany,
        input,
        item,
        partnershipId: selectedCompany.id,
        type: ContactSidePanelType.CREATE,
    };
    if (autoFill) {
        options.initialValues = {
            defaultGeneral: autoFill,
            defaultItem: autoFill,
        };
    }
    return options;
};

import { useMemo } from 'react';
import { getCountryNameFromCode } from 'helpers/countries';
import { useCountryCodesContext } from 'hooks';
import { byAlphabeticalOrder, filterCountryCodesByCountryPaymentOptions, putPopularCountriesFirst } from './helpers';
export const useFormattedCountries = (countryCodes, partnership) => {
    const countryCodeMap = useCountryCodesContext();
    return useMemo(() => {
        const filteredCountryCodes = filterCountryCodesByCountryPaymentOptions(countryCodes, partnership);
        const formattedCountries = Object.keys(filteredCountryCodes).map((countryCode, index) => ({
            id: index + 1,
            value: countryCode,
            label: getCountryNameFromCode(countryCodeMap, countryCode),
        }));
        const sortedCountries = [...formattedCountries].sort(byAlphabeticalOrder);
        const prioritizedCountries = putPopularCountriesFirst(sortedCountries);
        const countriesWithNationalities = Object.keys(filteredCountryCodes).map((countryCode, idx) => ({
            id: idx + 1,
            value: countryCode,
            label: `${countryCode} - ${countryCodeMap[countryCode].nationality}`,
        }));
        const sortedCountriesWithNationalities = [...countriesWithNationalities].sort(byAlphabeticalOrder);
        const prioritizedCountriesWithNationalities = putPopularCountriesFirst(sortedCountriesWithNationalities);
        return {
            countriesWithNationalities,
            formattedCountries,
            prioritizedCountries,
            prioritizedCountriesWithNationalities,
            sortedCountries,
            sortedCountriesWithNationalities,
        };
    }, [countryCodeMap, countryCodes, partnership]);
};

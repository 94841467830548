import {} from './paymentOptions.types';
export const paymentRailToPaymentOption = (paymentRail) => ({
    method: paymentRail.deliveryMethod,
    options: [paymentRail.deliveryOption],
});
const paymentRailByCurrencyCodeReceiver = (currencyCode) => (paymentRail) => paymentRail.currencyCodeReceiver === currencyCode;
export const getPartnerInternationalPaymentOptions = ({ billCurrencyCode, currencyCodeReceiver, routesPayable, }) => {
    const filteredRoutesPayable = (routesPayable?.[billCurrencyCode?.toLowerCase()] || [])
        .filter(paymentRailByCurrencyCodeReceiver(currencyCodeReceiver))
        .map(paymentRailToPaymentOption);
    if (filteredRoutesPayable.length) {
        return filteredRoutesPayable;
    }
    return null;
};
export const getPartnerPaymentOptions = ({ billCurrency, currencyCode, currencyCodeReceiver, isInternational, isInternationalPaymentsEnabled, routesPayable, selectedCompany, supportedCountryCodes, }) => {
    const { countryCode, currencyCodePartner } = selectedCompany || {};
    let currency = billCurrency || currencyCode || currencyCodePartner;
    if (isInternationalPaymentsEnabled && isInternational) {
        const partnerInternationalPaymentOptions = getPartnerInternationalPaymentOptions({
            billCurrencyCode: currency,
            currencyCodeReceiver,
            routesPayable,
        });
        if (partnerInternationalPaymentOptions) {
            return partnerInternationalPaymentOptions;
        }
        currency = currencyCodeReceiver || currency;
    }
    const countryData = supportedCountryCodes[countryCode];
    return countryData?.paymentOptions?.[currency?.toLowerCase()];
};

import classNames from 'classnames';
import React from 'react';
import { Text } from 'components';
import { colors, typography } from 'constants/styles';
import { formatCurrency } from 'helpers/currencyFormatter';
import { useCurrencyContext } from 'hooks';
const CurrencyAmountText = ({ amount, amountProps, className, currencyCode, currencyCodeProps, parseCurrencyOptions, }) => {
    const currencyCodeMap = useCurrencyContext();
    const formattedAmount = formatCurrency(amount, currencyCode, currencyCodeMap, parseCurrencyOptions);
    return (React.createElement("span", { className: className },
        React.createElement(Text.Regular, { color: colors.colorBlueDarkHex, size: typography.TextSize.LEVEL_100, ...amountProps }, formattedAmount),
        React.createElement(Text.Regular, { color: colors.colorGreyXDarkHex, size: typography.TextSize.LEVEL_100, ...currencyCodeProps, className: classNames('margin-left--sm-alt', currencyCodeProps?.className) }, currencyCode?.toUpperCase())));
};
export default CurrencyAmountText;

import { AcceptPartnershipComponentTypes, UpdatePaymentComponentTypes, ExternalButtonStates } from 'constants/ui';

import {
  isFundingAccountExternal,
  isFundingSourceInvalidMicroDepositsPending,
  isFundingSourceInvalidMicroDepositsProcessed,
} from 'helpers/funding';

/**
 * Predicate function that checks if column's components has update-payment form.
 * @param {ExternalColumn} col
 * @param {string} component
 * @returns {boolean}
 */
export const doesColumnIncludeComponent = (col, component) => !!col?.components.includes(component);

/**
 * Predicate function that determines if column has Contact Info Form
 * @param {ExternalColumn} column
 * @return {Boolean}
 */
export const isColumnContactInfo = (column) =>
  doesColumnIncludeComponent(column, AcceptPartnershipComponentTypes.PartnerCompanyContactForm);

/**
 * Predicate function that determines if column has SubstituteW9 Form
 * @param {ExternalColumn} column
 * @return {Boolean}
 */
export const isColumnW9TaxInfo = (column) =>
  doesColumnIncludeComponent(column, AcceptPartnershipComponentTypes.SubstituteW9Form);

/**
 * Predicate function that determines if column has SubstituteW8 Form
 * @param {ExternalColumn} column
 * @return {Boolean}
 */
export const isColumnW8TaxInfo = (column) =>
  doesColumnIncludeComponent(column, AcceptPartnershipComponentTypes.SubstituteW8Form);

/**
 * Predicate function that determines if column has Business Info Form
 * @param {ExternalColumn} column
 * @return {Boolean}
 */
export const isColumnBusinessInfo = (column) =>
  doesColumnIncludeComponent(column, AcceptPartnershipComponentTypes.PartnerCompanyGeneralForm);

/**
 * Predicate function that determines if column has Payment Method Form
 * @param {ExternalColumn} column
 * @return {Boolean}
 */
export const isColumnPaymentMethod = (column) =>
  doesColumnIncludeComponent(column, UpdatePaymentComponentTypes.UpdatePaymentMethodFormWithCheckboxAndHints) ||
  doesColumnIncludeComponent(column, UpdatePaymentComponentTypes.UpdatePaymentMethodFlowForm);

/**
 * Return the current button state for Business Info Form
 * @param {Boolean} isBusinessInfoFormValid
 * @returns {ExternalButtonStates}
 */
export const getBusinessInfoCurrentButton = (isBusinessInfoFormValid) =>
  isBusinessInfoFormValid ? ExternalButtonStates.VALID : ExternalButtonStates.INVALID;

/**
 * Return the current button state for Contact Info Form
 * @param {Boolean} isContactInfoFormValid
 * @returns {ExternalButtonStates}
 */
export const getContactInfoCurrentButton = (isContactInfoFormValid) =>
  isContactInfoFormValid ? ExternalButtonStates.VALID : ExternalButtonStates.INVALID;

/**
 * Return the current button state for W8 Form
 * @param {Boolean} isTaxFormValid
 * @returns {ExternalButtonStates}
 */
export const getTaxInfoCurrentButton = (isTaxFormValid) =>
  isTaxFormValid ? ExternalButtonStates.VALID : ExternalButtonStates.INVALID;

/**
 * Return the current button state for Payment Method Form
 * @param {Object} opts
 * @param {FundingAccount} opts.fundingAccount
 * @param {FundingSource[]} opts.fundingSources
 * @returns {ExternalButtonStates}
 */
export const getPaymentMethodCurrentButton = ({ fundingAccount, fundingSources }) => {
  const needsToConvertExternalFundingAccount = isFundingAccountExternal(fundingAccount);

  if (!fundingAccount || needsToConvertExternalFundingAccount) {
    return ExternalButtonStates.INVALID;
  }

  if (fundingSources.some(isFundingSourceInvalidMicroDepositsProcessed)) {
    return ExternalButtonStates.MICRO_DEPOSITS_READY;
  }

  if (fundingSources.some(isFundingSourceInvalidMicroDepositsPending)) {
    return ExternalButtonStates.MICRO_DEPOSITS_NOT_READY;
  }

  return ExternalButtonStates.VALID;
};

/**
 * Determines button to be shown based on column and funding.
 * @param {Object} opts
 * @param {ExternalColumn} opts.column
 * @param {FundingAccount} opts.fundingAccount
 * @param {FundingSource[]} opts.fundingSources
 * @param {Boolean} opts.isBusinessInfoFormValid
 * @param {Boolean} opts.isContactInfoFormValid
 * @param {Boolean} opts.isSubstituteW9FormValid
 * @returns {ExternalButtonStates}
 */
export const getCurrentButton = ({
  column,
  fundingAccount,
  fundingSources,
  isBusinessInfoFormValid,
  isContactInfoFormValid,
  isSubstituteW8FormValid,
  isSubstituteW9FormValid,
}) => {
  if (isColumnContactInfo(column)) {
    return getContactInfoCurrentButton(isContactInfoFormValid);
  }

  if (isColumnBusinessInfo(column)) {
    return getBusinessInfoCurrentButton(isBusinessInfoFormValid);
  }

  if (isColumnPaymentMethod(column)) {
    return getPaymentMethodCurrentButton({ fundingAccount, fundingSources });
  }

  if (isColumnW9TaxInfo(column)) {
    return getTaxInfoCurrentButton(isSubstituteW9FormValid);
  }

  if (isColumnW8TaxInfo(column)) {
    return getTaxInfoCurrentButton(isSubstituteW8FormValid);
  }

  return ExternalButtonStates.INITIAL;
};

/**
 * Add properties to column in step.
 * @param {Object} opts
 * @param {Object} opts.column
 * @param {number} opts.columnIndex
 * @param {FundingAccount} opts.fundingAccount
 * @param {FundingSource[]} opts.fundingSources
 * @param {Boolean} opts.isContactInfoFormValid
 * @param {Boolean} opts.isBusinessInfoFormValid
 * @returns {Object}
 */
export const createColumn = ({ column, columnIndex, ...stateMeta }) => {
  const currentButton = getCurrentButton({ column, ...stateMeta });
  const key = `col-${columnIndex + 1}`;

  return { ...column, currentButton, key };
};

/**
 * Add properties to step's columns and blocks.
 * @param {Object} opts
 * @param {FundingAccount} opts.fundingAccount
 * @param {FundingSource[]} opts.fundingSources
 * @param {Boolean} opts.isContactInfoFormValid
 * @param {Boolean} opts.isBusinessInfoFormValid
 * @param {Object} opts.step
 * @returns {ExternalStep}
 */
export const createStep = ({ step, ...stateMeta }) => {
  const blocks = step.blocks.map((block, blockIndex) => {
    const key = `block-${blockIndex + 1}`;
    const columns = block.columns.map((column, columnIndex) =>
      createColumn({
        column,
        columnIndex,
        ...stateMeta,
      }),
    );

    return { ...block, columns, key };
  });

  return { ...step, blocks };
};

/**
 * Gets the intial button text from the primary column
 * @param {ExternalStep} step
 * @returns {StringMaybe} buttonText
 */
export const getPrimaryColumnButtonText = (step) => {
  const primaryColumn = step.blocks[0].columns[0];
  const button = primaryColumn.buttonMap[ExternalButtonStates.INITIAL];

  return button?.text;
};

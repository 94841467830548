import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Form
 * @param {ComponentProps} props
 * @param {*} props.children
 * @param {String} [props.className]
 * @param {StringMaybe} [props.id]
 * @param {StringMaybe} [props.name]
 * @param {Function} props.onSubmit
 * @param {RestOfProps} rest
 * @return {StatelessComponent}
 */
const Form = ({ children, className, id, name, onSubmit, ...rest }) => (
  <form
    className={classNames({
      form: true,
      [className]: !!className,
    })}
    id={id}
    name={name}
    onSubmit={onSubmit}
    {...rest}
  >
    {children}
  </form>
);

Form.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  children: undefined,
  className: undefined,
  id: undefined,
  name: undefined,
  onSubmit: undefined,
};

export default Form;

import PropTypes from 'prop-types';
import React from 'react';

import { ModalNavTab } from 'components/modal';

/**
 * Tab component to be used in Toolbar
 * @param {ComponentProps} props
 * @param {String} props.text
 * @return {StatelessComponent}
 */
const ToolbarTab = ({ text }) => <ModalNavTab className="disable-pointer-events" tabText={text} />;

ToolbarTab.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ToolbarTab;

import { z } from 'zod';
import { companyType } from '@routable/companies-management';
export const bankFieldsToValidate = z.enum([
    'aba',
    'account_number',
    'bank_code',
    'bic_swift',
    'branch_code',
    'bsb_code',
    'clabe',
    'cnaps',
    'iban',
    'ifsc',
    'sort_code',
]);
const bankFieldsModel = z.object(Object.fromEntries(bankFieldsToValidate.options.map((field) => [field, z.string().optional()])));
export const validateInternationalBeneficiaryParams = z
    .object({
    companyName: z.string().optional(),
    companyType: companyType,
    country: z.string(),
    currency: z.string(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
})
    .and(bankFieldsModel);
const infoModel = z
    .object({
    businessType: z.string().optional(),
    name: z.string().optional(),
    country: z.string().optional(),
    currency: z.string().optional(),
})
    .passthrough()
    .and(bankFieldsModel);
const internationalBeneficiaryInfo = infoModel;
const internationalBeneficiaryWarnings = infoModel;
const internationalBeneficiaryData = z.object({
    info: internationalBeneficiaryInfo,
    warnings: internationalBeneficiaryWarnings,
});
const internationalBeneficiaryErrors = infoModel;
export const validateInternationalBeneficiaryApiResponse = z.object({
    data: internationalBeneficiaryData,
});
export const validateInternationalBeneficiaryErrorApiResponse = z.object({
    data: internationalBeneficiaryData,
    errors: internationalBeneficiaryErrors,
});

import React from 'react';
import { Table } from 'complexComponents';
import { getExternalItemsListTableData } from './data';
const ExternalItemsList = ({ itemsKind, partnershipItems = [] }) => {
    const columns = getExternalItemsListTableData({ itemsKind });
    if (!partnershipItems?.length) {
        return null;
    }
    return (React.createElement("div", { className: "external-items-list" },
        React.createElement(Table, { columns: columns, data: partnershipItems })));
};
export default ExternalItemsList;

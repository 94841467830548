import React from 'react';
import { SwalWrapperV2, Text, IconNames } from 'components';
import { ButtonText } from 'constants/button';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { typography, colors } from 'constants/styles';
import { iconSizes } from 'constants/styles/sizes';
import { Intent } from 'constants/ui';
import {} from './MulticurrencyDisabledSwal.types';
const textProps = {
    color: typography.TextColor.GREY_XX_DARK,
    lineHeight: typography.TextLineHeight.EXTRA_LARGE,
    size: typography.TextSize.LEVEL_200,
};
const MulticurrencyDisabledSwal = ({ ledger, onConfirm }) => (React.createElement(SwalWrapperV2, { iconProps: {
        color: colors.colorBlueBoldHex,
        name: IconNames.INFO_SIGN,
        size: iconSizes.XX_LARGE,
    }, intent: Intent.INFO, leftButtonProps: {
        children: ButtonText.CANCEL,
        className: 'hover--danger',
    }, rightButtonProps: {
        children: ButtonText.CONFIRM,
        intent: Intent.PRIMARY,
        onClick: onConfirm,
    }, title: `Your ${ledger.fullName} account does not have multi-currency enabled` },
    React.createElement(Text.Regular, { ...textProps },
        "You will be limited to only domestic payments.",
        ` This can be resolved at any time by turning multi-currency on in your ${ledger.fullName} account and re-syncing in ${PLATFORM_DISPLAY_SHORT_NAME}.`)));
export default MulticurrencyDisabledSwal;

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import {
  ButtonV2,
  CheckboxTermsOfService,
  Form,
  FormControl,
  FormFieldLabel,
  IconNames,
  InputField,
  InternationalPhoneInput,
  ReduxFormFieldRenderInput,
  StepHeader,
} from 'components';

import { ButtonSize } from 'constants/button';
import { colors, formStyles, sizes } from 'constants/styles';

import { noDefaultEventWithCallback } from 'helpers/events';
import {
  internationalPhoneNumberValidator,
  phoneNumberLengthValidator,
  phoneNumberRequiredValidator,
  requiredValidator,
} from 'helpers/fieldValidation';

import { isUpdatingCompanyOrMembershipSelector } from 'modules/createPartnerCompany/helpers/initialValues';
import {
  getExternalOnboardingCurrentStep,
  getExternalOnboardingNextStep,
} from 'modules/createPartnerCompany/helpers/steps';
import { CancelLink } from 'modules/createPartnerCompany/presenters/components';
import PartnerCompanyType from 'modules/createPartnerCompany/presenters/PartnerCompanyType';

import { currentUserSelector } from 'selectors/currentUserSelectors';

const PartnerCompanyContactForm = (props) => {
  const { currentUser, formName, isUpdating, onCancel, onFormSubmit } = props;

  // UI steps
  const currentStep = getExternalOnboardingCurrentStep();
  const nextStep = getExternalOnboardingNextStep();

  return (
    <React.Fragment>
      {currentStep && <StepHeader description={currentStep.description} num={`${currentStep.num}`} />}

      <Form id={formName} onSubmit={(e) => noDefaultEventWithCallback(e, onFormSubmit)}>
        <PartnerCompanyType />

        <FormFieldLabel>Direct contact info</FormFieldLabel>

        <FormControl>
          <InputField
            className={formStyles.field.xl.left}
            isDisabled
            name="email"
            placeholder="Your company email"
            type="text"
            value={currentUser.email}
          />

          <Field
            className={formStyles.field.xl.right}
            component={InternationalPhoneInput}
            name="membership.phoneNumber"
            placeholder="Phone Number"
            validate={[phoneNumberRequiredValidator, phoneNumberLengthValidator, internationalPhoneNumberValidator]}
          />
        </FormControl>

        <FormControl>
          <Field
            component={ReduxFormFieldRenderInput}
            name="membership.user.firstName"
            props={{
              className: formStyles.field.xl.left,
              placeholder: 'First name',
              type: 'text',
            }}
            validate={requiredValidator}
          />

          <Field
            component={ReduxFormFieldRenderInput}
            name="membership.user.lastName"
            props={{
              className: formStyles.field.xl.right,
              placeholder: 'Last name',
              type: 'text',
            }}
            validate={requiredValidator}
          />
        </FormControl>

        <FormControl>
          <Field
            component={CheckboxTermsOfService}
            isRequired={false}
            name="membership.meta.tosAgree"
            type="checkbox"
            validate={requiredValidator}
          />
        </FormControl>

        <FormControl>
          <ButtonV2
            htmlFor={formName}
            isLoading={isUpdating}
            rightIconColor={colors.colorWhiteHex}
            rightIconName={IconNames.ARROW_RIGHT}
            rightIconSize={sizes.iconSizes.LARGE}
            size={ButtonSize.LARGE}
            type="submit"
          >
            {`Continue to ${nextStep.description.toLowerCase()}`}
          </ButtonV2>
        </FormControl>
      </Form>

      <CancelLink onCancel={onCancel} />
    </React.Fragment>
  );
};

PartnerCompanyContactForm.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  formName: PropTypes.string.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: currentUserSelector(state),
  isUpdating: isUpdatingCompanyOrMembershipSelector(state),
});

export { PartnerCompanyContactForm };
export default connect(mapStateToProps)(PartnerCompanyContactForm);

/**
 * @enum {string}
 */
export const MobileOperatingSystem = {
  AMAZON: 'Amazon OS',
  ANDROID: 'Android OS',
  BLACKBERRY: 'BlackBerry OS',
  IOS: 'iOS',
  WINDOWS: 'Windows Mobile',
};

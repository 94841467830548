import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

export const accountDetailsSectionTitle = 'Details';

export const accountingIntegrationSectionTitle = 'Accounting Integration';

export const accountUsageSectionTitle = 'Bank account use';
export const accountUsageSelectPlaceholder = 'Enable bank account use for';

export const loadingAccountText = 'Loading account info...';

export const microDepositPartnershipLinkActionButtonText = 'Use this bank account';
export const microDepositPartnershipLinkActionLinkText = 'Why is there already a bank account added?';

export const microDepositVerificationActionButtonText = 'Enter deposits to verify';
export const microDepositVerificationActionLinkText = "Didn't receive deposit amounts?";

export const previouslyAddedAddressHintTitle = 'This is an address previously added';
export const previouslyAddedAddressHintText = `We have record of this address being added to receive payments
through the ${PLATFORM_DISPLAY_SHORT_NAME} network. Please confirm if you'd like to use this address to receive future
payments via check.`;
export const previouslyAddedAddressActionLinkText = 'Why is there already an address added?';
export const previouslyAddedAddressButtonText = 'Yes, use this address';

import { Renew } from '@carbon/icons-react';
import { Menu, StatusIndicator, Tooltip } from '@routable/components';
import React from 'react';
import { useSelector } from 'react-redux';
import PermissionResourceAction from 'constants/permissions';
import { useLedgerIntegrationStatus, usePermissions } from 'hooks';
import { isFetchingIntegrationConfigsSelector } from 'selectors/integrationConfigsSelectors';
import { toolbarButtonIndicatorStyles, toolbarButtonStyles } from './ToolbarLedgerButton.styles';
const ToolbarLedgerButton = ({ className, ledgerName, onResync, resyncEnabled }) => {
    const permissions = usePermissions([PermissionResourceAction.SETTINGS_INTEGRATIONS_RESYNC]);
    const isFetchingIntegrationConfig = useSelector(isFetchingIntegrationConfigsSelector);
    const { statusText, statusVariant } = useLedgerIntegrationStatus(ledgerName);
    return (React.createElement(Tooltip, { "data-testid": "toolbar-ledger-button--tooltip", position: "bottom", tooltip: permissions.tooltipProps?.title },
        React.createElement(Menu, { className: toolbarButtonStyles({ className, disabled: isFetchingIntegrationConfig }), disabled: isFetchingIntegrationConfig || !permissions.hasPermission, label: React.createElement(StatusIndicator, { className: toolbarButtonIndicatorStyles({ disabled: isFetchingIntegrationConfig }), status: statusVariant }, statusText), placement: "bottom right" },
            React.createElement(Menu.Item, { disabled: !resyncEnabled, onAction: onResync, textValue: `Re-sync ${ledgerName}` },
                React.createElement(Renew, { className: "fill-grey-70" }),
                " Re-sync ",
                ledgerName))));
};
export default ToolbarLedgerButton;

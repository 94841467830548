import {} from 'axios';
import { createRoutine } from 'redux-saga-routines';
import {} from './createApiRoutine.types';
import { withApiRequestFor } from './withApiRequestFor';
export const createApiRoutine = (key, baseOptions, payload, meta) => {
    const updatedBaseOptions = {
        ...baseOptions,
        headers: baseOptions.headers || {},
    };
    return withApiRequestFor('trigger', updatedBaseOptions, createRoutine(key, payload, meta));
};

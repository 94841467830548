import PropTypes from 'prop-types';
import React from 'react';

import { reduxFormInputPropType } from 'components/commonProps';

import { getClassNames } from 'helpers/ui';

/**
 * Renders a select field.
 * @param {ComponentProps} props
 * @param {string} props.name
 * @param {Function} props.onChange
 * @param {Function} props.onCreate
 * @param {Object[]} props.options
 * @param {Function} props.SelectComponent
 * @return {StatelessComponent}
 * @constructor
 */
const PopoverSelectField = (props) => {
  const { input, onCreate, options, SelectComponent, ...rest } = props;

  const { name } = input;

  return (
    <SelectComponent
      className={getClassNames(rest, {
        'select-alt': true,
        'popover-select--select': true,
      })}
      idPrefix={name}
      input={input}
      labelClassName="select-alt--label"
      name={name}
      onCreate={onCreate}
      options={options}
      styles={{
        indicatorsContainer: () => ({
          display: 'none',
        }),
      }}
      {...rest}
    />
  );
};

PopoverSelectField.propTypes = {
  className: PropTypes.string,
  input: reduxFormInputPropType.isRequired,
  onCreate: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  SelectComponent: PropTypes.elementType.isRequired,
};

PopoverSelectField.defaultProps = {
  className: undefined,
  onCreate: undefined,
  options: [],
};

export default PopoverSelectField;

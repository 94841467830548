import { createSelector } from 'reselect';

export const getState = (state) => state.passwordInput;

export const getStrengthState = createSelector([getState], (passwordInput) => passwordInput.passwordInputStrength);

export const getIsFetchingStrength = createSelector(
  [getState],
  (passwordInput) => passwordInput.isFetchingPasswordStrength,
);

export const getAreAllRequirementsMet = createSelector(
  [getState],
  (passwordInput) => passwordInput.areAllRequirementsMet,
);

export const getPasswordScore = createSelector([getStrengthState], (strengthState) => strengthState.score);

export const getPasswordFeedback = createSelector([getStrengthState], (strengthState) => strengthState.feedback);

import { createIsOpenReducer } from 'store/redux';

import {
  CLOSE_BULK_ACTION_MODAL,
  OPEN_BULK_ACTION_APPROVE_MODAL,
  OPEN_BULK_ACTION_APPROVE_AND_SEND_MODAL,
  OPEN_BULK_ACTION_EDIT_SEND_DATE_MODAL,
  OPEN_BULK_ACTION_SEND_MODAL,
} from 'types/bulkActions';

export const closeCases = [CLOSE_BULK_ACTION_MODAL];
export const openCases = [
  OPEN_BULK_ACTION_APPROVE_MODAL,
  OPEN_BULK_ACTION_APPROVE_AND_SEND_MODAL,
  OPEN_BULK_ACTION_EDIT_SEND_DATE_MODAL,
  OPEN_BULK_ACTION_SEND_MODAL,
];

const isOpenReducer = createIsOpenReducer(closeCases, openCases);

export default isOpenReducer;

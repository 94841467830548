import { companyType } from '@routable/companies-management';
import { useValidateInternationalBeneficiary } from '@routable/fund-routes';
import _pickBy from 'lodash/pickBy';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import { CurrencyCode } from 'constants/currency';
import { splitPersonalCompanyNameToFirstLast } from 'helpers/company';
export const getCompanyPayload = (company, partnership, countryCodeKey) => {
    const legalName = company.name || partnership.name;
    let companyName;
    let firstName;
    let lastName;
    if (company.companyType === companyType.Enum.business) {
        companyName = legalName;
    }
    else {
        const { legalFirstName, legalLastName } = splitPersonalCompanyNameToFirstLast(legalName);
        firstName = legalFirstName;
        lastName = legalLastName;
    }
    return {
        companyName,
        firstName,
        lastName,
        companyType: company.companyType,
        country: partnership[countryCodeKey],
    };
};
const getFormFieldsPayload = (formValues) => {
    const { aba, account_number, bank_code, bic_swift, branch_code, bsb_code, clabe, cnaps, iban, ifsc, sort_code } = formValues;
    return _pickBy({
        currency: formValues.currencyCode,
        aba,
        account_number,
        bank_code,
        bic_swift,
        branch_code,
        bsb_code,
        clabe,
        cnaps,
        iban,
        ifsc,
        sort_code,
    }, Boolean);
};
export const canValidateCurrentFormValues = (payloadData) => {
    const { currency, ...formPayload } = payloadData;
    if (currency === CurrencyCode.GBP) {
        return !!formPayload.account_number && !!formPayload.sort_code;
    }
    return !!Object.keys(formPayload).length;
};
export const useValidateInternationalBeneficiaryFields = (formName, partnership, company, countryCodeKey = 'countryCodePartner') => {
    const [canValidateForm, setCanValidateForm] = useState(false);
    const formValues = useSelector(getFormValues(formName));
    const formPayload = getFormFieldsPayload(formValues);
    const params = {
        ...getCompanyPayload(company, partnership, countryCodeKey),
        ...formPayload,
    };
    useEffect(() => {
        setCanValidateForm(canValidateCurrentFormValues(formPayload));
    }, [formPayload]);
    const { data, error, mutate, isLoading, reset: mutationReset } = useValidateInternationalBeneficiary(params);
    useEffect(() => {
        if (!canValidateForm) {
            mutationReset();
        }
    }, [canValidateForm]);
    const triggerValidation = () => {
        const canValidate = canValidateCurrentFormValues(formPayload);
        setCanValidateForm(canValidate);
        if (canValidate) {
            mutate();
        }
    };
    const parsedErrorData = error?.data;
    const parsedErrors = error?.errors;
    const parsedData = data?.data;
    const parsedInfo = parsedErrorData?.info || parsedData?.info;
    const parsedWarnings = parsedErrorData?.warnings || parsedData?.warnings;
    return {
        data: parsedData,
        errors: parsedErrors,
        info: parsedInfo,
        isLoading,
        triggerValidation,
        warnings: parsedWarnings,
    };
};

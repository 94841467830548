import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { HeadingBlockDisclosureList, Checkbox, IconNames } from 'components';

import { partnerFundingAccountFormFields } from 'constants/formFields';
import { formNamesFunding } from 'constants/forms';
import { formLabels } from 'constants/partnerFundingAccount';

import { isItemPaymentDeliveryMethodCheck } from 'helpers/items';

import {
  AddPartnerFundingAccountOptionAccounts,
  AddPartnerFundingAccountOptionApplyPending,
  AddPartnerFundingAccountOptionReplaceAccounts,
} from './components';

import './AddPartnerFundingAccountOptions.scss';

/**
 * Renders advanced options in AddPartnerFundingAccount form
 * @param props
 * @param {string} [props.className]
 * @param {string} [props.formName]
 * @param {boolean} props.isAdvancedOptionsOpen
 * @param {Function} props.onCloseAdvancedOptions
 * @param {Function} props.onOpenAdvancedOptions
 * @param {PaymentDeliveryMethod} props.paymentDeliveryMethod
 * @param {boolean} props.shouldDisableSetPrimary
 * @param {boolean} props.shouldShowSetAsPrimaryToggle
 * @return {FunctionComponent}
 */
const AddPartnerFundingAccountOptions = ({
  className,
  isAdvancedOptionsOpen,
  isInternationalPaymentsEnabled,
  onCloseAdvancedOptions,
  onOpenAdvancedOptions,
  paymentDeliveryMethod,
  selectedCurrencyCode,
  shouldDisableSetPrimary,
  shouldShowSetAsPrimaryToggle,
  sidePanel,
  ...rest
}) => {
  const items = React.useMemo(
    () => [
      {
        component: AddPartnerFundingAccountOptionApplyPending,
        key: 'applyPending',
        wrapWithCol: false,
      },
      {
        component: AddPartnerFundingAccountOptionReplaceAccounts,
        key: 'replaceAccounts',
        wrapWithCol: false,
      },
      {
        component: AddPartnerFundingAccountOptionAccounts,
        data: rest,
        key: 'accountsSelect',
        wrapWithCol: false,
      },
    ],
    [rest],
  );

  const toggleAdvancedOptionsSection = () => {
    if (isAdvancedOptionsOpen) {
      onCloseAdvancedOptions();
      return;
    }

    onOpenAdvancedOptions();
  };

  const { formContext } = sidePanel || {};
  const isInternational = formContext === formNamesFunding.ADD_PARTNER_INTERNATIONAL_FUNDING_ACCOUNT;
  const setAsPrimaryCheckboxContent =
    isInternationalPaymentsEnabled && isInternational
      ? formLabels.SET_PRIMARY_INTERNATIONAL(selectedCurrencyCode)
      : formLabels.SET_PRIMARY;

  return (
    <div className={classNames('add-partner-funding-account--options margin-top--m', { [className]: !!className })}>
      {isItemPaymentDeliveryMethodCheck({ paymentDeliveryMethod }) && (
        <Field
          className="margin-bottom--m"
          component={Checkbox}
          content={formLabels.SHOW_PRINT_CHECK}
          name={partnerFundingAccountFormFields.SHOULD_SHOW_ADDRESS_PRINT_ON_CHECK}
        />
      )}

      {shouldShowSetAsPrimaryToggle && (
        <Field
          className="margin-bottom--m"
          component={Checkbox}
          content={setAsPrimaryCheckboxContent}
          isDisabled={shouldDisableSetPrimary}
          name={partnerFundingAccountFormFields.SET_PRIMARY}
        />
      )}

      <HeadingBlockDisclosureList
        headerIconName={IconNames.SETTINGS}
        headerText="Advanced Options"
        isOpenOverride={isAdvancedOptionsOpen}
        items={items}
        onToggleOpen={toggleAdvancedOptionsSection}
      />
    </div>
  );
};

AddPartnerFundingAccountOptions.propTypes = {
  className: PropTypes.string,
  formName: PropTypes.string,
  fundingAccountsById: PropTypes.shape({}).isRequired,
  fundingInfoAddressesById: PropTypes.shape({}).isRequired,
  isAdvancedOptionsOpen: PropTypes.bool.isRequired,
  onCloseAdvancedOptions: PropTypes.func.isRequired,
  onOpenAdvancedOptions: PropTypes.func.isRequired,
  partnership: PropTypes.shape({}).isRequired,
  partnershipFundingAccounts: PropTypes.arrayOf(PropTypes.shape({})),
  paymentDeliveryMethod: PropTypes.string,
  shouldDisableSetPrimary: PropTypes.bool,
  shouldShowReplaceFundingAccounts: PropTypes.bool,
  shouldShowSetAsPrimaryToggle: PropTypes.bool,
};

AddPartnerFundingAccountOptions.defaultProps = {
  className: undefined,
  formName: undefined,
  partnershipFundingAccounts: [],
  paymentDeliveryMethod: undefined,
  shouldDisableSetPrimary: undefined,
  shouldShowReplaceFundingAccounts: undefined,
  shouldShowSetAsPrimaryToggle: undefined,
};

export default AddPartnerFundingAccountOptions;

import React from 'react';

import { formNamesBulkActions } from 'constants/forms';

import { initialValuesBulkActions } from 'helpers/initialValues';

/**
 * Default value used in the context for Bulk Import Items State,
 */
export const defaultValue = {
  form: formNamesBulkActions.IMPORT_ITEMS,
  formValues: initialValuesBulkActions.getBulkImportItemsFormInitialValues(),
  handleSubmit: null,
  hasLedgerIntegration: null,
  maxStep: null,
  onUpdateStep: null,
  resetForm: null,
  showConfirm: null,
  step: null,
};

/**
 * Create and initialize a context for the Bulk Import Items Form,
 */
export default React.createContext(defaultValue);

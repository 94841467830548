import { apiCall } from '@routable/framework';
import { internationalBankFieldsApiResponseModel } from '../models';
export const internationalFieldsService = {
    getBankFieldsForCurrencyCode: (currencyCode, countryCode, businessType) => apiCall({
        method: 'get',
        url: 'funding_accounts/international_bank_fields/',
        camelCaseResponse: true,
        queryParams: {
            business_type: businessType,
            country_code: countryCode,
            currency_code: currencyCode,
        },
        responseModel: internationalBankFieldsApiResponseModel,
    }),
};

import React from 'react';

import { ContentTitle } from 'components/text';

import { colors } from 'constants/styles';

import { text } from 'modules/dashboard/settings/account/balance/constants';
import { IsLoadingInline } from 'modules/isLoading/IsLoading';

const BalanceAmountLoading = () => (
  <div className="balance-block--loading">
    <ContentTitle>{text.accountBalanceSection.loading}</ContentTitle>

    <IsLoadingInline color={colors.colorDarkSilverHex} />
  </div>
);

export default BalanceAmountLoading;

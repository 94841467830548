import { sidePanelNameManuallyAddBankAccount } from 'constants/sidePanels';

import * as types from 'types/sidePanels';

/**
 * Manually Add Bank Account Side Panel Reducer State definitiion
 * @typedef {Object} ManuallyAddBankAccountReducerState
 * @property {Boolean} open
 */

/**
 * Manually Add Bank Account side Panel Initial state
 * @constant {ManuallyAddBankAccountReducerState}
 */
const initialState = {
  open: false,
};

/**
 * Redux reducer for Manually Add Bank Account Side Panel
 * @param {ManuallyAddBankAccountReducerState} state
 * @param {ReduxAction} action
 * @returns {ReduxReducer}
 */
const manuallyAddBankAccountSidePanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SIDE_PANEL_OPEN: {
      // to trigger this, use the generic openSidePanel actionCreator and pass this sidepanel's name
      return action.payload.name === sidePanelNameManuallyAddBankAccount ? { ...state, open: true } : state;
    }

    case types.SIDE_PANEL_CLOSE: {
      // to trigger this, use the generic closeSidePanel actionCreator and pass this sidepanel's name
      return action.payload.name === sidePanelNameManuallyAddBankAccount ? { ...state, open: false } : state;
    }

    default:
      return state;
  }
};

export { initialState };
export default manuallyAddBankAccountSidePanelReducer;

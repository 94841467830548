import React from 'react';
import { BaseHint } from 'components';
import { Intent } from 'constants/ui';
import {} from './InvalidLoginCredentialsHint.types';
const InvalidLoginCredentialsHint = ({ errors }) => {
    if (!errors) {
        return null;
    }
    return (React.createElement(BaseHint, { intent: Intent.DANGER, multiline: true, title: "The account details you entered did not match our records" }));
};
export default InvalidLoginCredentialsHint;

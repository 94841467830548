import { PaymentDeliveryMethodType } from '@routable/shared';

import { ItemKinds } from 'constants/item';

import { isCountryCodeUSOrUSTerritory as isUS } from 'helpers/countries';

/**
 * Get the payment methods accepted given a partnership and item (optional)
 * @param {object} partnership
 * @param {string} kind
 * @param {object} [item]
 */
export const getPaymentMethodsAccepted = (partnership, kind, item) => {
  if (item) {
    return item.paymentDeliveryMethodsAccepted;
  }

  if (kind === ItemKinds.PAYABLE) {
    return partnership.partnerPayablePaymentDeliveryMethodsAccepted;
  }

  return partnership.partnerReceivablePaymentDeliveryMethodsAccepted;
};

/**
 * Get the payment methods accepted given a currency code array
 * @param {string} [countryCodes]
 * @param {string} [companyPaymentMethods]
 */
export const getAcceptedPaymentMethodsByCountryCode = (countryCodes, companyPaymentMethods) => {
  const { CHECK, INTERNATIONAL, ACH } = PaymentDeliveryMethodType;
  const isUsCompany = !countryCodes.length || countryCodes.some(isUS);

  return {
    [CHECK]: companyPaymentMethods.includes(CHECK) && isUsCompany,
    [ACH]: companyPaymentMethods.includes(ACH) && isUsCompany,
    [INTERNATIONAL]: companyPaymentMethods.includes(INTERNATIONAL) && countryCodes.some((cc) => !isUS(cc)),
  };
};

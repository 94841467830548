import React from 'react';
import { Text } from 'components';
import { TextSize } from 'constants/styles/typography';
const Tooltip = ({ tooltipText, showTooltip }) => {
    if (!showTooltip) {
        return null;
    }
    return React.createElement(Text.Regular, { size: TextSize.LEVEL_100 }, tooltipText);
};
export default Tooltip;

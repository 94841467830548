import { membershipInviteAcceptV2Routine, membershipInviteSubmitRoutine } from 'actions/routines/inviteTeamMember';

import { createLastSubmittedReducer } from 'store/redux';

import { CLEAR_MEMBERSHIP_INVITE } from 'types/inviteTeamMember';

export const successCases = [membershipInviteAcceptV2Routine.SUCCESS, membershipInviteSubmitRoutine.SUCCESS];
export const requestCases = [CLEAR_MEMBERSHIP_INVITE];

const lastSubmittedReducer = createLastSubmittedReducer(successCases, requestCases);

export default lastSubmittedReducer;

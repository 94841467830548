import PropTypes from 'prop-types';
import React from 'react';

import { selectComponents } from 'components/selectV2';

import { tagTypeProp } from 'helpers/propTypes';

/**
 * Option component for react-select.
 * @param {ComponentProps} props
 * @param {Object} props.data
 * @return {StatelessComponent}
 * @constructor
 */
const SelectOption = (props) => {
  const { data, isDisabled } = props;

  const { optionTagType } = data;

  if (optionTagType) {
    return <selectComponents.TaggedOption isDisabled={isDisabled} {...props} />;
  }

  return <selectComponents.VerboseOption isDisabled={isDisabled} {...props} />;
};

SelectOption.propTypes = {
  data: PropTypes.shape({
    optionTagType: tagTypeProp,
  }).isRequired,
  isDisabled: PropTypes.bool,
};

SelectOption.defaultProps = {
  isDisabled: undefined,
};

export default SelectOption;

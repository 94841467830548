import PropTypes from 'prop-types';
import React from 'react';

import PermissionSectionDisclosureList from 'complexComponents/DisclosureLists/PermissionSectionDisclosureList';

import { roleCardConfigProp } from './propTypes';

/**
 * Loop over the PermissionGroups to generate PermissionSectionDisclosureLists.
 *
 * @param {ComponentProps} props
 * @param {RoleCardConfig} props.orderedRoleCardConfig
 * @param {boolean} [props.startPermissionListsOpen]
 * @returns {StatelessComponent}
 */
const RoleCardEntityWidgetBodyPermissionList = ({ orderedRoleCardConfig, startPermissionListsOpen }) =>
  orderedRoleCardConfig.map((permissionGroup) => (
    <PermissionSectionDisclosureList
      key={permissionGroup.id}
      permissionGroupName={permissionGroup.name}
      permissions={permissionGroup.permissions}
      startOpen={startPermissionListsOpen}
    />
  ));

RoleCardEntityWidgetBodyPermissionList.propTypes = {
  orderedRoleCardConfig: roleCardConfigProp.isRequired,
  startPermissionListsOpen: PropTypes.bool,
};

RoleCardEntityWidgetBodyPermissionList.defaultProps = {
  startPermissionListsOpen: undefined,
};

export default RoleCardEntityWidgetBodyPermissionList;

import {} from '../meta.types';
import {} from './withModifierKeys.types';
export const generateWithModifierKeys = (global) => {
    let keyState = {
        shift: false,
        alt: false,
    };
    const keyboardEventListener = ({ shiftKey: shift, altKey: alt }) => {
        keyState = {
            shift,
            alt,
        };
    };
    global.addEventListener('pageshow', () => {
        global.addEventListener('keyup', keyboardEventListener);
        global.addEventListener('keydown', keyboardEventListener);
    });
    global.addEventListener('pagehide', () => {
        global.removeEventListener('keyup', keyboardEventListener);
        global.removeEventListener('keydown', keyboardEventListener);
    });
    return (action) => ({
        ...action,
        meta: {
            modifierKeys: {
                ...keyState,
            },
            ...action.meta,
        },
    });
};
export const withModifierKeys = generateWithModifierKeys(window);

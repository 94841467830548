import { connect } from 'react-redux';

import { createEditHandler } from 'modules/external/ExternalCollectTaxInfo/helpers/actions';

import { externalCollectTaxInfoFormSelector } from 'selectors/forms';

import ConfirmFullName from './ConfirmFullName';

const mapStateToProps = (state) => {
  const formUI = externalCollectTaxInfoFormSelector(state, 'ui') || {};

  return {
    firstName: externalCollectTaxInfoFormSelector(state, 'form.membership.user.firstName'),
    editFirstName: formUI.editFirstName,
    lastName: externalCollectTaxInfoFormSelector(state, 'form.membership.user.lastName'),
    editLastName: formUI.editLastName,
  };
};

const mapDispatchToProps = {
  onEditFirstName: createEditHandler('ui.editFirstName'),
  onEditLastName: createEditHandler('ui.editLastName'),
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmFullName);

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { isSubmittingSignupCreateAccountSelector, signupCreateAccountErrorsSelector } from 'selectors/signupSelectors';
import submitSignupCreateAccount from 'thunks/submitSignupCreateAccount';
import CreateAccount from './CreateAccount';
export const mapStateToProps = createStructuredSelector({
    errors: signupCreateAccountErrorsSelector,
    isSubmitting: isSubmittingSignupCreateAccountSelector,
});
export const mapDispatchToProps = {
    onSignupCreateAccount: submitSignupCreateAccount,
};
const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));
export default enhance(CreateAccount);

import {
  FETCH_UNMATCHED_LEDGER_CLEARING_ACCOUNTS_FAILURE,
  FETCH_UNMATCHED_LEDGER_CLEARING_ACCOUNTS_REQUEST,
  FETCH_UNMATCHED_LEDGER_CLEARING_ACCOUNTS_SUCCESS,
} from 'types/clearingAccounts';

const fetchReducer = (state = false, action) => {
  switch (action.type) {
    case FETCH_UNMATCHED_LEDGER_CLEARING_ACCOUNTS_REQUEST:
      return true;

    case FETCH_UNMATCHED_LEDGER_CLEARING_ACCOUNTS_FAILURE:
    case FETCH_UNMATCHED_LEDGER_CLEARING_ACCOUNTS_SUCCESS:
      return false;

    default:
      return state;
  }
};

export default fetchReducer;

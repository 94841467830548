import PropTypes from 'prop-types';
import React from 'react';

import { LedgerIcon } from 'components/icon';
import { TextAndIconsRelation } from 'components/relation';

import { getItemLedgerDisplayStatus } from 'modules/dashboard/existingItem/helpers';

import { LedgerStatusLinkIcon, LedgerStatusTooltipIcon } from './components';

const LedgerStatusRelation = ({ item, ledger }) => {
  if (!item.ledgerStatus || !ledger.application) {
    return null;
  }

  const ledgerDisplayStatus = getItemLedgerDisplayStatus(item, ledger);

  if (!ledgerDisplayStatus) {
    return null;
  }

  const iconRight = item?.ledgerLink ? (
    <LedgerStatusLinkIcon ledgerLink={item.ledgerLink} ledgerName={ledger.fullName} />
  ) : (
    <LedgerStatusTooltipIcon item={item} ledger={ledger} />
  );

  return (
    <TextAndIconsRelation
      className="relation_ledger-status"
      iconLeft={<LedgerIcon className="item-ledger--logo" ledger={ledger} />}
      iconRight={iconRight}
      text={ledgerDisplayStatus}
    />
  );
};

LedgerStatusRelation.propTypes = {
  item: PropTypes.shape(),
  ledger: PropTypes.shape().isRequired,
};

LedgerStatusRelation.defaultProps = {
  item: {},
};

export default LedgerStatusRelation;

import { apiCall } from '@routable/framework';
import { approverDataApiListModel } from '../../models';
export const approversUrl = 'approvals/rules/approvers/';
export const approverApi = {
    readAll: async () => apiCall({
        url: approversUrl,
        method: 'get',
        camelCaseResponse: true,
        responseModel: approverDataApiListModel,
    }),
};

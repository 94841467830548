import { TinTypes } from 'constants/taxes';

import { isBusinessTypeSoleProprietor, isCurrentCompanyTypeBusiness } from 'helpers/currentCompany';

/**
 * When the form changes, use new values from the user to determine what the tinType should be and save it to the
 * ui.tinType.
 * @param {object} newValues - Get values for company, companyType, and businessType
 * @param {Dispatch} dispatch
 * @param {object} formProps - from redux-form
 * @param {function} formProps.change - change action which has been automagically bound to the redux-form
 * @param {object} previousValues
 * @param {string} previousValues.tinType - The previous tinType, so we only update when needed.
 * @returns {undefined} - Dispatches change as side-effect if tinType changes
 */
export const updateTinType = (newValues, dispatch, formProps, previousValues) => {
  const { change } = formProps;
  const { company } = newValues.form;
  const {
    companyType,
    info: { businessType },
  } = company;
  const { noEin } = newValues.ui;
  // flip the boolean for readability
  const willUseEin = !noEin;
  const { tinType: previousTinType } = previousValues.ui || {};

  const companyTypeIsBusiness = isCurrentCompanyTypeBusiness({ companyType });
  const businessTypeIsSoleProprietor = isBusinessTypeSoleProprietor(businessType);

  let newTinType = TinTypes.SSN;

  if (companyTypeIsBusiness) {
    // all businesses which are not SP must use EIN
    if (!businessTypeIsSoleProprietor) {
      newTinType = TinTypes.EIN;
    }

    // SP with EIN may use it
    if (businessTypeIsSoleProprietor && willUseEin) {
      newTinType = TinTypes.EIN;
    }
  }
  // else, all personal companyTypes use SSN

  // only update if tinType has changed
  if (newTinType !== previousTinType) {
    dispatch(change('ui.tinType', newTinType));
  }
};

/**
 * onChange handler passed to reduxForm(). Currently, only updates the tinType on change, but could do other side-
 * effects in the future if needed
 * @param {object} newValues
 * @param {Dispatch} dispatch
 * @param {object} formProps - props from redux-form
 * @param {object} prevValues
 * @returns {undefined} - Only dispatches side-effects
 */
export const onCollectTaxInfoFormChange = (newValues, dispatch, formProps, prevValues) => {
  updateTinType(newValues, dispatch, formProps, prevValues);
};

import {
  SUBMIT_CLEARING_ACCOUNTS_MATCH_FAILURE,
  SUBMIT_CLEARING_ACCOUNTS_MATCH_REQUEST,
  SUBMIT_CLEARING_ACCOUNTS_MATCH_SUCCESS,
} from 'types/clearingAccounts';

const isSubmittingReducer = (state = false, action) => {
  switch (action.type) {
    case SUBMIT_CLEARING_ACCOUNTS_MATCH_FAILURE:
    case SUBMIT_CLEARING_ACCOUNTS_MATCH_SUCCESS:
      return false;

    case SUBMIT_CLEARING_ACCOUNTS_MATCH_REQUEST:
      return true;

    default:
      return state;
  }
};

export default isSubmittingReducer;

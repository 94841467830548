import { updateCurrentUserRoutine } from 'actions/routines/user';

import { createErrorReducer } from 'store/redux';

import { GET_CURRENT_USER_FAILURE, GET_CURRENT_USER_REQUEST, GET_CURRENT_USER_SUCCESS } from 'types/user';

export const failureCases = [updateCurrentUserRoutine.FAILURE, GET_CURRENT_USER_FAILURE];
export const successCases = [
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_SUCCESS,
  updateCurrentUserRoutine.REQUEST,
  updateCurrentUserRoutine.SUCCESS,
];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

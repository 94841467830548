/**
 * @fileOverview Defines the sub-reducer for CSV upload request errors.
 * @module reducers/bulkActions
 */

import { uploadBulkImportFileRoutine } from 'actions/routines/bulkActions';

import { createErrorReducer } from 'store/redux';

export const failureCases = [uploadBulkImportFileRoutine.FAILURE];

export const successCases = [
  uploadBulkImportFileRoutine.FULFILL,
  uploadBulkImportFileRoutine.REQUEST,
  uploadBulkImportFileRoutine.SUCCESS,
];

/**
 * Reducer for reduxState.bulkActions.errors.
 * @type {ReduxReducer}
 */
const errorsReducer = createErrorReducer(successCases, failureCases);

export default errorsReducer;

import PropTypes from 'prop-types';
import React from 'react';

import { MuiTooltipPoly } from 'components/MuiTooltipPoly';

import { TooltipMaxWidth, TooltipPadding, TooltipPlacement } from 'constants/tooltip';

import { oneOfValuesFromObject } from 'helpers/propTypes';

import './TooltipMUI.scss';

/**
 * Tooltip wrapper component
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{readonly childRefContainerProps?: *, readonly presetStyle?: *,readonly maxWidth?: *, readonly arrow?: *, readonly style?: *, readonly placement?: *, readonly interactive?: *, readonly padding?: *, readonly title?: *, readonly children?: *}> & React.RefAttributes<unknown>>}
 * @param {ComponentProps} props
 * @param {*} props.children
 * @param {boolean} [props.interactive=true] - Interact with contents of the tooltip without the tooltip closing
 * @param {String} props.maxWidth
 * @param {String} props.padding
 * @param {String} props.presetStyle
 * @param {ObjectMaybe} [props.style]
 * @param {String|Node} props.title
 * @param {Object} props.childRefContainerProps
 * @param {String} props.placement
 * @param {Object} rest
 * @return {StatelessComponent}
 * @deprecated use @routable/components's Tooltip instead
 */
const TooltipMUI = React.forwardRef(
  (
    {
      children,
      interactive,
      maxWidth = TooltipMaxWidth.LARGE,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      padding,
      presetStyle,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      style,
      title,
      childRefContainerProps,
      ...rest
    },
    ref,
  ) => (
    <MuiTooltipPoly
      interactive={interactive}
      size={maxWidth}
      text={title}
      variant={presetStyle === 'dark' ? 'dark' : 'light'}
      {...rest}
    >
      <div {...childRefContainerProps} ref={ref}>
        {children}
      </div>
    </MuiTooltipPoly>
  ),
);

TooltipMUI.propTypes = {
  children: PropTypes.node.isRequired,
  interactive: PropTypes.bool,
  maxWidth: oneOfValuesFromObject(TooltipMaxWidth),
  padding: oneOfValuesFromObject(TooltipPadding),
  placement: oneOfValuesFromObject(TooltipPlacement),
  presetStyle: PropTypes.string,
  style: PropTypes.shape(),
  childRefContainerProps: PropTypes.shape(),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

TooltipMUI.defaultProps = {
  interactive: true,
  maxWidth: undefined,
  padding: TooltipPadding.X_SKINNY,
  placement: TooltipPlacement.TOP,
  presetStyle: undefined,
  style: undefined,
  childRefContainerProps: {},
};

export default TooltipMUI;

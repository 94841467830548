import pluralize from 'pluralize';

import { formatting } from 'helpers/billing';

/**
 * Get account balance pending hint title
 * @param {BillingCodeData} billingData
 * @return {string}
 */
export const getAccountBalancePendingBalanceHintTitle = (billingData) =>
  `Thanks for adding funds to your balance. We're waiting for the funds to transfer. This process typically takes ${formatting.getFormattedBillingCodeEarliestLatestDesc(
    billingData,
  )} to complete.`;

/**
 * Method to get the 'currency' available text (e.g. US Dollars available)
 * @param {CurrencyCodeDetail} formatOptions
 * @return {string}
 */
export const getBalanceAvailableText = (formatOptions) => `${pluralize(formatOptions.name, 2)} available`;

import { createSelector } from 'reselect';

import { idPropSelector } from 'selectors/propSelectors';

const getState = (state) => state.partnershipFundingStates;

/**
 * Selects the partnershipFundingState by id
 * @function
 * @param {ReduxState} state
 * @returns {object}
 */
export const partnershipFundingStatesByIdSelector = createSelector(
  [getState],
  (partnershipFundingStates) => partnershipFundingStates.byId,
);

/**
 * Selects partnershipFundingState for given id
 * @param {ReduxState} state
 * @param {Id} id
 * @returns {ObjectMaybe}
 */
export const partnershipFundingStatesForIdSelector = createSelector(
  [partnershipFundingStatesByIdSelector, idPropSelector],
  (partnershipFundingStates, id) => partnershipFundingStates[id],
);

/**
 * Selected the partnershipFundingState error
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {ObjectMaybe}
 */
export const partnershipFundingStatesErrorsSelector = createSelector(
  [getState],
  (partnershipFundingStates) => partnershipFundingStates.errors,
);

/**
 * Selects the PartnershipRequestStateFundingAccount funding account
 * @function
 * @param {ReduxState} state
 * @returns {ObjectMaybe}
 */
export const partnershipFundingStatesFundingAccountSelector = createSelector(
  [partnershipFundingStatesForIdSelector],
  (partnershipFundingState) => partnershipFundingState?.fundingAccount,
);

/**
 * Selects current funding state from partnership funding state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {ObjectMaybe}
 */
export const partnershipFundingStatesCurrentFundingStateSelector = createSelector(
  [getState],
  (partnershipFundingState) => partnershipFundingState.currentFundingState,
);

/**
 * Selects funding account id associated with current funding state from
 * partnership funding state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {?Id}
 */
export const partnershipFundingStatesCurrentFundingAccountIdSelector = createSelector(
  [partnershipFundingStatesCurrentFundingStateSelector],
  (currentFundingState) => currentFundingState?.fundingAccount,
);

/**
 * Returns true if current funding account state from partnership funding state
 * has funding account id associated with it
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const partnershipFundingStatesHasCurrentFundingAccountIdSelector = createSelector(
  [partnershipFundingStatesCurrentFundingAccountIdSelector],
  Boolean,
);

/**
 * Selects dateCompleted property of the Current Partnership Funding State
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {?Timestamp}
 */
export const partnershipFundingStatesCurrentFundingStateDateCompletedSelector = createSelector(
  [partnershipFundingStatesCurrentFundingStateSelector],
  (currentFundingState) => currentFundingState?.dateCompleted,
);

/**
 * Returns true if the Current Partnership Funding State has a value associated with the
 * dateCompleted property
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const partnershipFundingStateCurrentHasDateCompletedSelector = createSelector(
  [partnershipFundingStatesCurrentFundingStateDateCompletedSelector],
  (dateCompleted) => Boolean(dateCompleted),
);

import { createErrorReducer } from 'store/redux';

import {
  SUBMIT_SIGNUP_CREATE_ACCOUNT_FAILURE,
  SUBMIT_SIGNUP_CREATE_ACCOUNT_REQUEST,
  SUBMIT_SIGNUP_CREATE_ACCOUNT_SUCCESS,
} from 'types/signup';

export const failureCases = [SUBMIT_SIGNUP_CREATE_ACCOUNT_FAILURE];
export const successCases = [SUBMIT_SIGNUP_CREATE_ACCOUNT_REQUEST, SUBMIT_SIGNUP_CREATE_ACCOUNT_SUCCESS];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

import ClipboardJS from 'clipboard';
import React from 'react';
import { valueOrDefault } from 'helpers/utility';
import { toaster } from 'services/toaster';
const useClipboard = ({ copyValue, successText, elementId, }) => {
    const ref = React.useRef();
    React.useEffect(() => {
        let clipboard;
        const target = ref.current || elementId;
        if (target) {
            clipboard = new ClipboardJS(target, {
                text: () => copyValue,
            });
            clipboard.on('success', () => {
                const text = valueOrDefault(successText, 'Copied to clipboard!');
                toaster.success(text, { id: copyValue });
            });
        }
        return () => {
            if (clipboard) {
                clipboard.destroy();
            }
        };
    }, [copyValue, elementId, ref, successText]);
    return ref;
};
export default useClipboard;

export const MODAL_MATCH_CLEARING_ACCOUNT_CLOSE = 'MODAL_MATCH_CLEARING_ACCOUNT_CLOSE';
export const MODAL_MATCH_CLEARING_ACCOUNT_OPEN = 'MODAL_MATCH_CLEARING_ACCOUNT_OPEN';

export const FETCH_UNMATCHED_LEDGER_CLEARING_ACCOUNTS_FAILURE = 'FETCH_UNMATCHED_LEDGER_CLEARING_ACCOUNTS_FAILURE';
export const FETCH_UNMATCHED_LEDGER_CLEARING_ACCOUNTS_REQUEST = 'FETCH_UNMATCHED_LEDGER_CLEARING_ACCOUNTS_REQUEST';
export const FETCH_UNMATCHED_LEDGER_CLEARING_ACCOUNTS_SUCCESS = 'FETCH_UNMATCHED_LEDGER_CLEARING_ACCOUNTS_SUCCESS';

export const FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_FAILURE =
  'FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_FAILURE';
export const FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_REQUEST =
  'FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_REQUEST';
export const FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_SUCCESS =
  'FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_SUCCESS';

export const SUBMIT_CLEARING_ACCOUNTS_MATCH_FAILURE = 'SUBMIT_CLEARING_ACCOUNTS_MATCH_FAILURE';
export const SUBMIT_CLEARING_ACCOUNTS_MATCH_REQUEST = 'SUBMIT_CLEARING_ACCOUNTS_MATCH_REQUEST';
export const SUBMIT_CLEARING_ACCOUNTS_MATCH_SUCCESS = 'SUBMIT_CLEARING_ACCOUNTS_MATCH_SUCCESS';

import PropTypes from 'prop-types';
import React from 'react';

const AccountAddressLine = ({ children }) => <span className="bank-account--address-line">{children}</span>;

AccountAddressLine.propTypes = {
  children: PropTypes.node,
};

AccountAddressLine.defaultProps = {
  children: undefined,
};

export default AccountAddressLine;

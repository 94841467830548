import { fetchExternalConfigRoutine } from 'actions/routines/external';

export const initialState = [];

const progressBarStepperReducer = (state = initialState, action) => {
  switch (action.type) {
    case fetchExternalConfigRoutine.SUCCESS:
      return action.payload;

    default:
      return state;
  }
};

export default progressBarStepperReducer;

import PropTypes from 'prop-types';
import React from 'react';

import TableColumn from './TableColumn';

const TableHeaderRow = ({ data }) => {
  const elements = [];

  Object.keys(data).forEach((key) => {
    const { baseClassName, className, displayName } = data[key];

    elements.push(
      <TableColumn baseClassName={baseClassName} className={className} key={`header-${key}`}>
        <span>{`${displayName || key}`.trim()}</span>
      </TableColumn>,
    );
  });

  return <li className="table-row--header">{elements}</li>;
};

TableHeaderRow.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default TableHeaderRow;

const avatarModule = {
  getInitials(firstName, lastName, email) {
    if (firstName && lastName) {
      return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
    }

    if (firstName) {
      return firstName.charAt(0).toUpperCase();
    }

    if (email) {
      return email.charAt(0).toUpperCase();
    }

    return '';
  },

  getColor(initials, defaultColor) {
    let defColor = defaultColor;

    if (typeof defColor !== 'string') {
      defColor = '#000';
    }

    if (typeof initials !== 'string') {
      return defColor;
    }

    const availableColors = this.getAvailableColors();

    let shortInitials = initials;

    if (initials.length > 2) {
      shortInitials = initials[0] + initials[1];
    }

    let avgLetterPos = 0;

    for (let i = 0; i < shortInitials.length; i += 1) {
      const letterPos = this.getLetterPosition(shortInitials[i]);
      avgLetterPos += letterPos / 26.001;
    }

    const colorIndex = Math.floor((avgLetterPos / shortInitials.length) * availableColors.length);

    return availableColors[colorIndex];
  },

  getLetterPosition(letter) {
    const alphabet = [
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      't',
      'u',
      'v',
      'w',
      'x',
      'y',
      'z',
    ];

    return alphabet.indexOf(letter.toLowerCase()) + 1;
  },

  getAvailableColors() {
    return ['#3056BA', '#6DD8CD', '#25AC64', '#FD8D4E', '#AF53FF', '#F7AFAF', '#FDD54F', '#4D5C71', '#7F8DB9'];
  },
};

export default avatarModule;

import {} from '@routable/types';
export const tablematicMinFieldSizeMap = {
    1: 40,
    2: 60,
    3: 80,
    4: 120,
    5: 160,
    6: 240,
    7: 320,
    8: 480,
    20: 480,
};
export const fixCellWidths = (cell) => {
    if (cell && cell.style && cell.style.width) {
        cell.style.flex = `0 0 ${cell.style.width}`;
    }
    return cell;
};

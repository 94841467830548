import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Dropzone from 'complexComponents/Dropzone';
import { SelectedFileContainer } from 'complexComponents/Dropzone/components';
import { ButtonV2, IconNames } from 'components';
import { ButtonAppearance } from 'constants/button';
import { PDF } from 'constants/mimeTypes';
import { colors } from 'constants/styles';
const RenderFileUpload = ({ name, shouldUnregister, }) => {
    const { control, setValue } = useFormContext();
    const handleAddFile = (file) => {
        setValue(name, file, { shouldValidate: true, shouldTouch: true });
    };
    const handleRemoveSelectedFile = () => {
        setValue(name, null, { shouldValidate: true });
    };
    return (React.createElement(Controller, { control: control, name: name, render: ({ field: { value }, fieldState }) => {
            if (value) {
                return (React.createElement(SelectedFileContainer, null,
                    value.filename,
                    React.createElement(ButtonV2, { appearance: ButtonAppearance.BORDERLESS, isIconButton: true, leftIconColor: colors.colorBlueBoldHex, leftIconName: IconNames.TRASH, onClick: handleRemoveSelectedFile })));
            }
            return (React.createElement(Dropzone, { accept: [PDF], addFile: handleAddFile, errorMessage: fieldState.error?.message, name: name, supportText: "Only PDF files are allowed. Max file size 2 MB" }));
        }, shouldUnregister: shouldUnregister }));
};
export default RenderFileUpload;

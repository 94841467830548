import withRowDataGetters from 'hoc/withRowDataGetters';

import TeamMemberListNameCell from 'modules/dashboard/settings/account/teamMembers/components/TeamMemberListNameCell';
import TeamMemberListPopoverCell from 'modules/dashboard/settings/account/teamMembers/components/TeamMemberListPopoverCell';
import TeamMemberListStatusCell from 'modules/dashboard/settings/account/teamMembers/components/TeamMemberListStatusCell';
import { text } from 'modules/dashboard/settings/account/teamMembers/helpers';

/**
 * Table configuration for TeamMembersInvitesTable
 * @const
 */
export const TeamMembersInvitesTableData = {
  teamMember: {
    baseClassName: 'table-row--column',
    displayName: 'Team member',
    flexGrow: 2,
    obj: withRowDataGetters(TeamMemberListNameCell, {
      avatar: 'avatar',
      email: 'email',
      id: 'id',
      isAffiliate: 'isAffiliate',
      firstName: 'firstName',
      lastName: 'lastName',
      name: text.getMemberFullName,
    }),
  },
  status: {
    baseClassName: 'table-row--column',
    displayName: 'Status',
    flexGrow: 1,
    obj: withRowDataGetters(TeamMemberListStatusCell, {
      dateExpiration: 'dateExpiration',
      isExpired: 'isExpired',
    }),
  },
  action: {
    baseClassName: 'table-row--column',
    className: 'justify-content--center',
    obj: withRowDataGetters(TeamMemberListPopoverCell, {
      onResendInvite: 'onResendInvite',
      onRevokeInvite: 'onRevokeInvite',
    }),
    width: 70,
  },
};

export var ValueOption;
(function (ValueOption) {
    ValueOption["FREETEXT"] = "FREETEXT";
    ValueOption["DATE"] = "DATE";
    ValueOption["PERIOD"] = "PERIOD";
    ValueOption["CURRENCY"] = "CURRENCY";
    ValueOption["BANK_ACCOUNT"] = "BANK_ACCOUNT";
    ValueOption["BULK_ACTION_STATUS_MULTISELECT"] = "BULK_ACTION_STATUS_MULTISELECT";
    ValueOption["ITEM_STATUS_SELECT"] = "ITEM_STATUS_SELECT";
})(ValueOption || (ValueOption = {}));

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { externalOnboardingDecrementStep } from 'actions/externalOnboarding';

import FormFieldDescription from 'components/form/FormFieldDescription';
import FormFieldLabel from 'components/form/FormFieldLabel';
import StepHeader from 'components/form/StepHeader';

import { isExternalFlowStepInitial, isExternalPathAccept, isExternalPathPay } from 'helpers/external';

import { getExternalOnboardingCurrentStep } from 'modules/createPartnerCompany/helpers/steps';
import {
  getStepThreeFieldLabelDescription,
  getStepThreeFieldLabelText,
} from 'modules/createPartnerCompany/helpers/text';
import PartnerCompanyAcceptPayment from 'modules/createPartnerCompany/presenters/PartnerCompanyAcceptPayment';
import PartnerCompanyMakePayment from 'modules/createPartnerCompany/presenters/PartnerCompanyMakePayment';
import PartnerCompanyVerifyIdentity from 'modules/createPartnerCompany/presenters/PartnerCompanyVerifyIdentity';

import { externalOnboardingStepSelector } from 'selectors/externalOnboardingSelectors';
import { externalPaymentFormSelector } from 'selectors/forms';

import { BackLink, PaymentMethodSubmitButton } from './components';

const PartnerCompanyPaymentMethodForm = (props) => {
  const {
    currentStepIndex,
    disableFormSubmit,
    formButtonText,
    formId,
    formUI,
    fundingAccount,
    isSubmitting,
    onCheckFundingAccountSelected,
    onDecrementStep,
    onFundingAccountAddressSelected,
    onFormSubmit,
    paymentDeliveryMethod,
  } = props;

  const currentStep = getExternalOnboardingCurrentStep();
  const isPathAccept = isExternalPathAccept();
  const isPathPay = isExternalPathPay();

  return (
    <div className="margin-top--large">
      {currentStep && <StepHeader description={currentStep.description} num={`${currentStep.num}`} />}

      <form className="form" id={formId} onSubmit={onFormSubmit}>
        <FormFieldLabel className="has-description">{getStepThreeFieldLabelText(isPathAccept)}</FormFieldLabel>
        <FormFieldDescription>{getStepThreeFieldLabelDescription()}</FormFieldDescription>

        {isPathAccept && (
          <PartnerCompanyAcceptPayment
            formId={formId}
            fundingAccount={fundingAccount}
            onCheckFundingAccountSelected={onCheckFundingAccountSelected}
            onFundingAccountAddressSelected={onFundingAccountAddressSelected}
            paymentDeliveryMethod={paymentDeliveryMethod}
          />
        )}

        {isPathPay && (
          <PartnerCompanyMakePayment fundingAccount={fundingAccount} paymentDeliveryMethod={paymentDeliveryMethod} />
        )}

        {formUI.showKYC && <PartnerCompanyVerifyIdentity />}

        <PaymentMethodSubmitButton
          disableFormSubmit={disableFormSubmit}
          formButtonText={formButtonText}
          formId={formId}
          fundingAccount={fundingAccount}
          isSubmitting={isSubmitting}
        />

        {!isExternalFlowStepInitial(currentStepIndex) && <BackLink onBack={onDecrementStep} />}
      </form>
    </div>
  );
};

PartnerCompanyPaymentMethodForm.propTypes = {
  currentStepIndex: PropTypes.number.isRequired,
  disableFormSubmit: PropTypes.bool.isRequired,
  formButtonText: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  formUI: PropTypes.shape(),
  fundingAccount: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  onCheckFundingAccountSelected: PropTypes.func.isRequired,
  onFundingAccountAddressSelected: PropTypes.func.isRequired,
  onDecrementStep: PropTypes.func,
  onFormSubmit: PropTypes.func.isRequired,
  paymentDeliveryMethod: PropTypes.string,
};

PartnerCompanyPaymentMethodForm.defaultProps = {
  formUI: {},
  fundingAccount: undefined,
  onDecrementStep: undefined,
  paymentDeliveryMethod: undefined,
};

const mapStateToProps = (state) => ({
  currentStepIndex: externalOnboardingStepSelector(state),
  formUI: externalPaymentFormSelector(state, 'formUI'),
  fundingAccount: externalPaymentFormSelector(state, 'fundingAccount'),
  paymentDeliveryMethod: externalPaymentFormSelector(state, 'paymentDeliveryMethod'),
});

const mapDispatchToProp = {
  onDecrementStep: externalOnboardingDecrementStep,
};

export default connect(mapStateToProps, mapDispatchToProp)(PartnerCompanyPaymentMethodForm);

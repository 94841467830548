import { Hint, Button } from '@routable/components';
import React from 'react';
import { useHistory } from 'react-router-dom';
export const RiskChecksNotRunHint = ({ vendorName, taxToolsEligibleRoute }) => {
    const history = useHistory();
    const handleNavigateToTaxTools = () => {
        history.push(taxToolsEligibleRoute);
    };
    return (React.createElement(Hint, { title: `Run compliance checks from tax tools to initiate validation of ${vendorName}`, type: "info", variant: "outline" },
        React.createElement("div", { className: "flex flex-col gap-5" },
            React.createElement("span", null, "Once you do so, we will run a TIN and Watchlist check for this and other applicable vendors."),
            React.createElement(Button, { "data-testid": "go-to-tax-tab-button", onPress: handleNavigateToTaxTools, variant: "outline" }, "Go to Tax tools"))));
};

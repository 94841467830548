import {} from '@routable/framework';
export const dotNotation = (obj, is, value) => {
    if (typeof is == 'string') {
        return dotNotation(obj, is.split('.'), value);
    }
    else if (is.length === 1 && value !== undefined) {
        return (obj[is[0]] = value);
    }
    else if (is.length === 0) {
        return obj;
    }
    else {
        return dotNotation(obj[is[0]], is.slice(1), value);
    }
};
export const getDataAlign = (align) => {
    if (!align)
        return {};
    return {
        [`data-align-${align}`]: true,
    };
};

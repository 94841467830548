/**
 * @module types/tables
 */

export const TABLE_COMPANY_PAYMENT_HISTORY = '@tables/COMPANY_PAYMENT_HISTORY';
export const TABLE_PAYABLES = '@tables/PAYABLES';
export const TABLE_RECEIVEABLES = '@tables/RECEIVABLES';

export const APPLY_TABLE_FILTERS = 'APPLY_TABLE_FILTERS';
export const APPLY_TABLE_SORT = 'APPLY_TABLE_SORT';
export const DESELECT_MULTIPLE_TABLE_ROWS = 'DESELECT_MULTIPLE_TABLE_ROWS';
export const DESELECT_SINGLE_TABLE_ROW = 'DESELECT_SINGLE_TABLE_ROW';
export const SELECT_MULTIPLE_TABLE_ROWS = 'SELECT_MULTIPLE_TABLE_ROWS';
export const SELECT_SINGLE_TABLE_ROW = 'SELECT_SINGLE_TABLE_ROW';
export const TOGGLE_LOCK_SELECTION = 'TOGGLE_LOCK_SELECTION';

export const DESELECT_ALL_ROWS = '@tables/DESELECT_ALL_ROWS';
export const DESELECT_MULTIPLE_PAYABLES_TABLE_ROWS = `${TABLE_PAYABLES}/${DESELECT_MULTIPLE_TABLE_ROWS}`;
export const DESELECT_MULTIPLE_RECEIVABLES_TABLE_ROWS = `${TABLE_RECEIVEABLES}/${DESELECT_MULTIPLE_TABLE_ROWS}`;
export const DESELECT_SINGLE_PAYABLES_TABLE_ROW = `${TABLE_PAYABLES}/${DESELECT_SINGLE_TABLE_ROW}`;
export const DESELECT_SINGLE_RECEIVABLES_TABLE_ROW = `${TABLE_RECEIVEABLES}/${DESELECT_SINGLE_TABLE_ROW}`;

export const FETCH_ITEM_STRUCTURE_TABLES = '@tables/FETCH_ITEM_STRUCTURE_TABLES';

export const SELECT_MULTIPLE_PAYABLES_TABLE_ROWS = `${TABLE_PAYABLES}/${SELECT_MULTIPLE_TABLE_ROWS}`;
export const SELECT_MULTIPLE_RECEIVABLES_TABLE_ROWS = `${TABLE_RECEIVEABLES}/${SELECT_MULTIPLE_TABLE_ROWS}`;
export const SELECT_SINGLE_PAYABLES_TABLE_ROW = `${TABLE_PAYABLES}/${SELECT_SINGLE_TABLE_ROW}`;
export const SELECT_SINGLE_RECEIVABLES_TABLE_ROW = `${TABLE_RECEIVEABLES}/${SELECT_SINGLE_TABLE_ROW}`;

export const TOGGLE_LOCK_PAYABLES_SELECTION = `${TABLE_PAYABLES}/${TOGGLE_LOCK_SELECTION}`;
export const TOGGLE_LOCK_RECEIVABLES_SELECTION = `${TABLE_RECEIVEABLES}/${TOGGLE_LOCK_SELECTION}`;

export const APPLY_PAYABLES_TABLE_FILTERS = `${TABLE_PAYABLES}/${APPLY_TABLE_FILTERS}`;
export const APPLY_RECEIVABLES_TABLE_FILTERS = `${TABLE_RECEIVEABLES}/${APPLY_TABLE_FILTERS}`;

export const APPLY_COMPANY_PAYMENT_HISTORY_TABLE_SORT = `${TABLE_COMPANY_PAYMENT_HISTORY}/${APPLY_TABLE_SORT}`;
export const APPLY_PAYABLES_TABLE_SORT = `${TABLE_PAYABLES}/${APPLY_TABLE_SORT}`;
export const APPLY_RECEIVABLES_TABLE_SORT = `${TABLE_RECEIVEABLES}/${APPLY_TABLE_SORT}`;

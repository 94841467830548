import PropTypes from 'prop-types';
import React from 'react';

import Avatar from 'components/avatar/Avatar';
import { Row } from 'components/layout';

import { MemberNameAddOn } from 'constants/ui';

import { getMembershipFullNameAppendingTextToCurrent } from 'helpers/memberships';

const { MEMBER_NAME_ME } = MemberNameAddOn;

/**
 * Approver component
 * @param {ComponentProps} props
 * @param {Membership} props.approver
 * @return {StatelessComponent}
 */
const Approver = ({ approver }) => (
  <Row>
    <Avatar
      avatar={approver.avatar}
      email={approver.email}
      firstName={approver.firstName}
      lastName={approver.lastName}
      sizeClass="avatar--approval"
      styleClass="avatar--light"
    />

    <p className="font-xs bold font-color--greyXDark margin-left--xm remove-margin-bottom">
      {getMembershipFullNameAppendingTextToCurrent(approver, MEMBER_NAME_ME)}
    </p>
  </Row>
);

Approver.propTypes = {
  approver: PropTypes.shape().isRequired,
};

export default Approver;

import { createSelector } from 'reselect';

import { isCurrentCompanyKycStatusDocument, isCurrentCompanyKycStatusRetry } from 'helpers/currentCompany';

import {
  hasAnyBusinessRepresentativeKYCFailed,
  hasCompanyKYBFailed,
} from 'modules/dashboard/guide/global/helpers/statusChecks';

import { currentCompanySelector } from 'selectors/currentCompanySelectors';
import { rejectedCompanyDocumentsSelector, rejectedMembershipDocumentsSelector } from 'selectors/documentsSelectors';
import { allMembershipsWithFailedBeneficiaryStatus } from 'selectors/membershipsSelector';

export const hasKYCOrKYBError = createSelector(
  [
    // get company failed states
    rejectedCompanyDocumentsSelector,
    currentCompanySelector,

    // get member (ie. Representative) failed states
    rejectedMembershipDocumentsSelector,
    allMembershipsWithFailedBeneficiaryStatus,
  ],
  (rejectedCompanyDocuments, currentCompany, rejectedMembershipDocuments, failedBeneficiaries = []) => {
    const hasCurrentCompanyKYBFailed = hasCompanyKYBFailed({
      currentCompany,
      rejectedCompanyDocuments,
    });

    const hasAnyBusinessRepKYCFailed = hasAnyBusinessRepresentativeKYCFailed({
      failedBeneficiaries,
      rejectedDocuments: rejectedMembershipDocuments,
    });

    const isKycStatusRetry = isCurrentCompanyKycStatusRetry(currentCompany);
    const isKycStatusDocument = isCurrentCompanyKycStatusDocument(currentCompany);

    return {
      hasCurrentCompanyKYBFailed,
      hasAnyBusinessRepKYCFailed,
      hasAnyVerificationErrors: currentCompany?.id && (hasCurrentCompanyKYBFailed || hasAnyBusinessRepKYCFailed),
      // retry is technically an error state but we handle it with special hints to let the user re-submit for verification
      isKycStatusRetry,
      // document is also technically a failure state because dwolla needs more docs. Here again we have special handling
      // to let the user re-upload docs
      isKycStatusDocument,
    };
  },
);

import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { submitFundingStateRoutineWithPayload } from 'actions/routines/external';
import { formNamesExternal } from 'constants/forms';
import {} from 'interfaces/global';
import { makeFormSubmitFailHandler } from 'modules/createPartnerCompany/helpers/submitFailure';
import { fundingAccountsForCompanyIdQuerySelector } from 'queries/fundingCompoundSelectors';
import { updatePaymentMethodFormInternationalInitialValuesSelector } from 'queries/updatePaymentFormSelectors';
import { externalDefaultReceivableSelector } from 'selectors/forms';
import { fundingAccountsLastSubmittedIdSelector } from 'selectors/fundingSelectors';
import UpdatePaymentMethodFormInternational from './UpdatePaymentMethodFormInternational';
import {} from './UpdatePaymentMethodFormInternational.types';
const formName = formNamesExternal.UPDATE_PAYMENT_METHOD;
export const mapStateToProps = createStructuredSelector({
    defaultReceivable: externalDefaultReceivableSelector,
    fundingAccounts: fundingAccountsForCompanyIdQuerySelector,
    initialValues: updatePaymentMethodFormInternationalInitialValuesSelector,
    lastSubmittedFundingAccountId: fundingAccountsLastSubmittedIdSelector,
});
export const mapDispatchToProps = {
    onSubmitAllExistingItems: submitFundingStateRoutineWithPayload,
    onValuesChange: change,
};
const createUpdatePaymentInternationalForm = reduxForm({
    destroyOnUnmount: false,
    form: formName,
    onSubmitFail: makeFormSubmitFailHandler(formName),
})(UpdatePaymentMethodFormInternational);
export default connect(mapStateToProps, mapDispatchToProps)(createUpdatePaymentInternationalForm);

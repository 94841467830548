import clsx from 'clsx';
import React from 'react';
export const InputPlaceholder = ({ className, isRequired, placeholder, value }) => {
    if (!placeholder || value) {
        return null;
    }
    return (React.createElement("div", { className: clsx('placeholder', 'truncate', className) },
        placeholder,
        isRequired && React.createElement("span", null, ' *')));
};

import ModalBodyContent from './components/body/ModalBodyContent';
import ModalBodyContentFull from './components/body/ModalBodyContentFull';
import ModalFooter from './components/footer/ModalFooter';
import ModalFooterButton from './components/footer/ModalFooterButton';
import ModalFooterButtonAllDone from './components/footer/ModalFooterButtonAllDone';
import ModalFooterButtonCancel from './components/footer/ModalFooterButtonCancel';
import ModalFooterButtonCreateAnother from './components/footer/ModalFooterButtonCreateAnother';
import ModalFooterContentLeft from './components/footer/ModalFooterContentLeft';
import ModalFooterContentRight from './components/footer/ModalFooterContentRight';
import ModalNavTab from './components/nav/ModalNavTab';
import FullPageModal from './FullPageModal';
import Modal from './Modal';

export { ModalContentWrapper, ModalText, ModalTitle } from './Modal.styled';

export {
  FullPageModal,
  Modal,
  ModalBodyContent,
  ModalBodyContentFull,
  ModalFooter,
  ModalFooterButton,
  ModalFooterButtonAllDone,
  ModalFooterButtonCancel,
  ModalFooterButtonCreateAnother,
  ModalFooterContentLeft,
  ModalFooterContentRight,
  ModalNavTab,
};

export default Modal;

import PropTypes from 'prop-types';
import React from 'react';

import TimeTooltipIcon from 'components/icon/TimeTooltipIcon';

import { colors } from 'constants/styles';

const BankAccountHeaderContentDateAdded = ({ partnershipFundingAccount }) => {
  if (!partnershipFundingAccount) {
    return null;
  }

  return (
    <TimeTooltipIcon
      iconClassName="margin-top--sm"
      iconColor={colors.colorMainJordan}
      time={partnershipFundingAccount.created}
    />
  );
};

BankAccountHeaderContentDateAdded.propTypes = {
  partnershipFundingAccount: PropTypes.shape(),
};

BankAccountHeaderContentDateAdded.defaultProps = {
  partnershipFundingAccount: undefined,
};

export default BankAccountHeaderContentDateAdded;

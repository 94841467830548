import { deleteCompanyDocumentRoutine, deleteRepresentativeDocumentRoutine } from 'actions/routines/documents';

const isDeleting = (state = {}, action) => {
  switch (action.type) {
    case deleteCompanyDocumentRoutine.TRIGGER:
      return {
        ...state,
        isDeleting: true,
        file: action.payload.file,
      };
    case deleteRepresentativeDocumentRoutine.TRIGGER:
      return {
        ...state,
        isDeleting: true,
        file: action.payload.file,
      };
    case deleteCompanyDocumentRoutine.SUCCESS:
    case deleteCompanyDocumentRoutine.FAILURE:
    case deleteRepresentativeDocumentRoutine.SUCCESS:
    case deleteRepresentativeDocumentRoutine.FAILURE:
      return {
        ...state,
        isDeleting: false,
      };
    default:
      return state;
  }
};

export default isDeleting;

import { all, call, put, spawn, take } from 'redux-saga/effects';

import {
  addItemMemberToItemRoutine,
  createItemMemberForExistingItemRoutine,
  updateItemMemberOnItemRoutine,
} from 'actions/routines/itemMember';
import { fetchThreadRequest } from 'actions/thread';
import { showErrorUi, showSuccessUi } from 'actions/ui';

import { ErrorIndicatorMessages } from 'constants/ui';

import { itemMemberSubmitTransformers } from 'data/submitTransformers';

import { parseCaughtError, parseErrorResponse } from 'helpers/errors';
import {
  getMembershipSuccessIndicatorMessages,
  getMembershipErrorIndicatorMessages,
  getMembershipSuccessUpdateMessages,
  getMembershipErrorUpdateMessages,
} from 'helpers/itemMember';

import { createPartnershipMember } from 'sagas/shared/tasks';

import { payloadToCamelCase } from 'services/api/formatHelpers';

import * as api from './api';

/**
 * Add an ItemMember to an Item.
 * @param {ReduxSagaRoutineAction} action
 * @return {IterableIterator<*>}
 */
export function* addItemMemberToItem({ payload }) {
  const { props, values } = payload;
  const {
    item: { id: itemId },
  } = props;
  const { contacts, meta = {} } = values;
  const { shouldShowSuccessUi } = meta;

  const itemMember = itemMemberSubmitTransformers.addPartnershipMemberAsItemMember(contacts);
  const apiParams = [itemId, itemMember];
  let errors = {};

  try {
    yield put(addItemMemberToItemRoutine.request());
    const response = yield call(api.addItemMemberToItem, ...apiParams);

    if (response.ok) {
      const parsedResponse = payloadToCamelCase(response.data);

      yield put(addItemMemberToItemRoutine.success(parsedResponse));

      if (shouldShowSuccessUi) {
        yield put(showSuccessUi(getMembershipSuccessIndicatorMessages(contacts?.value)));
      }

      return;
    }

    errors = parseErrorResponse(response);
  } catch (error) {
    errors = parseCaughtError(error);
  }

  yield all([
    put(addItemMemberToItemRoutine.failure(errors)),
    put(showErrorUi(getMembershipErrorIndicatorMessages(contacts?.value))),
  ]);
}

/**
 * Create a new PartnershipMember. Once that PartnershipMember has been created, add them to an Item as an ItemMember.
 * @param {ReduxSagaRoutineAction} action
 * @return {IterableIterator<*>}
 */
export function* createItemMemberForExistingItem({ payload }) {
  const {
    values: {
      accessItem,
      meta: {
        item: { id: itemId, thread },
      },
    },
  } = payload;

  let errors = {};

  yield put(createItemMemberForExistingItemRoutine.request());

  try {
    const { partnershipMember } = yield call(createPartnershipMember, {
      payload,
    });

    const addContactPayload = itemMemberSubmitTransformers.transformNewPartnershipMemberIntoItemMemberPayload({
      itemId,
      newPartnershipMember: partnershipMember,
      accessItem,
    });

    yield call(addItemMemberToItem, { payload: addContactPayload });
    yield all([
      put(createItemMemberForExistingItemRoutine.success()),
      // fetch the thread to show the message that the contact was added to the item
      put(fetchThreadRequest(thread)),
    ]);

    return;
  } catch (error) {
    errors = parseCaughtError(error);
  }

  yield all([
    put(createItemMemberForExistingItemRoutine.failure(errors)),
    put(showErrorUi(ErrorIndicatorMessages.ADD_CONTACT)),
  ]);
}

/**
 * Update an ItemMember on an Item.
 * @param {ReduxSagaRoutineAction} action
 * @return {IterableIterator<*>}
 */
export function* updateItemMemberOnItem({ payload }) {
  const {
    contact,
    item: { id: itemId, thread, ...rest },
    shouldFlipItemAccess,
  } = payload;

  const itemMember = itemMemberSubmitTransformers.partnershipMemberIntoItemMemberForUpdate(
    contact,
    shouldFlipItemAccess,
  );

  const { id: itemMemberId } = itemMember;

  const apiParams = [itemId, itemMemberId, itemMember];
  let errors = {};

  try {
    yield put(updateItemMemberOnItemRoutine.request());
    const response = yield call(api.updateItemMemberOnItem, ...apiParams);

    if (response.ok) {
      const parsedResponse = payloadToCamelCase(response.data);
      yield all([
        put(updateItemMemberOnItemRoutine.success(parsedResponse)),
        // fetch the thread to show the message that the contact's access was updated
        put(fetchThreadRequest(thread)),
        put(showSuccessUi(getMembershipSuccessUpdateMessages(contact, rest))),
      ]);

      return;
    }

    errors = parseErrorResponse(response);
  } catch (error) {
    errors = parseCaughtError(error);
  }

  yield all([
    put(updateItemMemberOnItemRoutine.failure(errors)),
    put(showErrorUi(getMembershipErrorUpdateMessages(contact, rest))),
  ]);
}

/**
 * Listens for redux actions related to ItemMembers.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([
      addItemMemberToItemRoutine.TRIGGER,
      createItemMemberForExistingItemRoutine.TRIGGER,
      updateItemMemberOnItemRoutine.TRIGGER,
    ]);

    switch (action.type) {
      case addItemMemberToItemRoutine.TRIGGER:
        yield spawn(addItemMemberToItem, action);
        break;

      case createItemMemberForExistingItemRoutine.TRIGGER:
        yield spawn(createItemMemberForExistingItem, action);
        break;

      case updateItemMemberOnItemRoutine.TRIGGER:
        yield spawn(updateItemMemberOnItem, action);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root itemMember saga.
 * @return {IterableIterator<*>}
 */
export default function* itemMemberSagas() {
  yield watch();
}

import { connect } from 'react-redux';

import { closeConnectBankTokenModal, updateConnectBankTokenModal } from 'actions/connectBank';

import { modalNameConnectBankToken } from 'constants/modals';

import { modalSelector } from 'selectors/modalsSelector';

import PlaidLink from './PlaidLink';

const mapStateToProps = (state) => ({
  connectBankTokenModal: modalSelector(state, modalNameConnectBankToken),
});

const mapDispatchToProps = {
  onCloseTokenAccountModal: closeConnectBankTokenModal,
  onUpdateTokenAccountModal: updateConnectBankTokenModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaidLink);

import dayjs from 'dayjs';
import React from 'react';

import { PLATFORM_LEGAL_NAME } from 'constants/platform';

import './Copyright.scss';

export const getCopyrightYears = (year = dayjs().year()) => {
  let range = '2017';

  if (year !== 2017) {
    range += ` - ${year}`;
  }

  return range;
};

const Copyright = () => (
  <p className="copyright">
    © {getCopyrightYears()} {PLATFORM_LEGAL_NAME} All rights reserved.
  </p>
);

export default Copyright;

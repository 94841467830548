import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { fetchFileForObjectUrlRequest } from 'actions/files';

import withQueryParamProps from 'hoc/withQueryParamProps';

import { filesIsFetchingSelector, filesObjectUrlSelector } from 'selectors/filesSelectors';

import ViewDocument from './ViewDocument';

const mapStateToProps = createStructuredSelector({
  isFetching: filesIsFetchingSelector,
  objectUrl: filesObjectUrlSelector,
});

const mapDispatchToProps = {
  onFetchFile: fetchFileForObjectUrlRequest,
};

const enhance = compose(withQueryParamProps, connect(mapStateToProps, mapDispatchToProps));

export default enhance(ViewDocument);

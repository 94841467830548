import clsx from 'clsx';
export const filterQuickActionIconStyles = clsx(["group-aria-[expanded='true']:rotate-180", 'transition-transform']);
export const filterQuickActionPopoverStyles = clsx([
    'flex',
    'gap-3',
    'pr-3',
    'rounded',
    'items-center',
    'border',
    'border-grey-20',
    'transition-colors',
    'transition-shadow',
    'hover:border-grey-30',
    'hover:cursor-pointer',
    '[&>*]:border-0',
    '[&>*]:pr-0',
    "group-aria-[expanded='true']:border-blue-40",
    "group-aria-[expanded='true']:ring-4",
    "group-aria-[expanded='true']:ring-offset-0",
    "group-aria-[expanded='true']:ring-blue-40/20",
]);

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  fundingAccountFromCurrentPartnershipRequestSelector,
  fundingInfoAddressFromCurrentPartnershipRequestSelector,
} from 'queries/fundingCompoundSelectors';

import { partnershipFundingStatesHasCurrentFundingAccountIdSelector } from 'selectors/partnershipFundingStatesSelectors';

import CurrentPaymentMethodHint from './CurrentPaymentMethodHint';

export const mapStateToProps = createStructuredSelector({
  fundingAccount: fundingAccountFromCurrentPartnershipRequestSelector,
  fundingInfoAddress: fundingInfoAddressFromCurrentPartnershipRequestSelector,
  isPaymentMethodUpdated: partnershipFundingStatesHasCurrentFundingAccountIdSelector,
});

export default connect(mapStateToProps)(CurrentPaymentMethodHint);

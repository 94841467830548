import _isArray from 'lodash/isArray';
import _isNum from 'lodash/isNumber';
import { DateElement, regexp } from '../constants';
export const requiredValidator = (value) => {
    if (typeof value === 'string' && value.trim()) {
        return undefined;
    }
    if (_isNum(value)) {
        return undefined;
    }
    if (_isArray(value) && value.length === 0) {
        return ['Required'];
    }
    if (typeof value !== 'string' && !_isNum(value) && value) {
        return undefined;
    }
    return ['Required'];
};
export const createFreeformDatetimeValidator = ({ allowEmpty, dateElements }) => (value) => {
    const includesDate = dateElements.includes(DateElement.date);
    const includesTime = dateElements.includes(DateElement.time);
    let testValid;
    if (allowEmpty && !value) {
        testValid = true;
    }
    else if (includesDate && includesTime) {
        testValid = regexp.DATE_TIME.test(value);
    }
    else if (includesDate) {
        testValid = regexp.DATE_ONLY.test(value) || regexp.ISO_DATE_ONLY.test(value);
    }
    else {
        testValid = regexp.TIME_ONLY.test(value);
    }
    if (testValid) {
        return undefined;
    }
    return [`Please enter a valid ${dateElements.join('')}`];
};
export const createRegExpValidator = (regExpString, errorMessage) => (value) => {
    const regExp = new RegExp(regExpString);
    const stringValue = value?.toString?.();
    if (!regExp.test(stringValue)) {
        return [errorMessage];
    }
    return undefined;
};

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const ModalBody = ({ children, hasInlineFooter }) => (
  <div
    className={classNames({
      'modal-body': true,
      'has-footer': !!hasInlineFooter,
    })}
  >
    {children}
  </div>
);

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
  hasInlineFooter: PropTypes.bool,
};

ModalBody.defaultProps = {
  hasInlineFooter: undefined,
};

export default ModalBody;

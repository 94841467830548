import * as types from 'types/namespace';

export const fetchNamespaceRequest = () => ({
  type: types.FETCH_NAMESPACE_REQUEST,
});

export const fetchNamespaceSuccess = () => ({
  type: types.FETCH_NAMESPACE_SUCCESS,
});

export const fetchNamespaceFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_NAMESPACE_FAILURE,
});

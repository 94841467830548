import { submit } from 'redux-form';

import { formNamesMatchPartnership } from 'constants/forms';

import * as types from 'types/integrations';

// ========
// Fetch
// ========

export const fetchIntegrationsRequest = () => ({
  type: types.FETCH_INTEGRATIONS_REQUEST,
});

export const fetchIntegrationsSuccess = (payload) => ({
  payload,
  type: types.FETCH_INTEGRATIONS_SUCCESS,
});

export const fetchIntegrationsFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_INTEGRATIONS_FAILURE,
});

export const fetchIntegrationConfigsRequest = () => ({
  type: types.FETCH_INTEGRATION_CONFIGS_REQUEST,
});

export const fetchIntegrationConfigsSuccess = (payload) => ({
  payload,
  type: types.FETCH_INTEGRATION_CONFIGS_SUCCESS,
});

export const fetchIntegrationConfigsFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_INTEGRATION_CONFIGS_FAILURE,
});

export const fetchSingleIntegrationConfigRequest = (integrationId) => ({
  payload: { integrationId },
  type: types.FETCH_INTEGRATION_CONFIG_REQUEST,
});

export const fetchSingleIntegrationConfigSuccess = (payload) => ({
  payload,
  type: types.FETCH_INTEGRATION_CONFIG_SUCCESS,
});

export const fetchSingleIntegrationConfigFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_INTEGRATION_CONFIG_FAILURE,
});

export const fetchIntegrationPlatformPartnershipsRequest = (integrationConfigId, filter = {}, isSearch = false) => ({
  payload: { filter, integrationConfigId, isSearch },
  type: types.FETCH_PLATFORM_PARTNERSHIPS_REQUEST,
});

export const fetchIntegrationPlatformPartnershipsSuccess = (payload) => ({
  payload,
  type: types.FETCH_PLATFORM_PARTNERSHIPS_SUCCESS,
});

export const fetchIntegrationPlatformPartnershipsSearchSuccess = (payload) => ({
  payload,
  type: types.FETCH_PLATFORM_PARTNERSHIPS_SEARCH_SUCCESS,
});

export const fetchIntegrationPlatformPartnershipsFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_PLATFORM_PARTNERSHIPS_FAILURE,
});

export const fetchIntegrationLedgerPartnershipsRequest = (integrationConfigId, filter = {}) => ({
  payload: { filter, integrationConfigId },
  type: types.FETCH_LEDGER_PARTNERSHIPS_REQUEST,
});

export const fetchIntegrationLedgerPartnershipsSuccess = (payload) => ({
  payload,
  type: types.FETCH_LEDGER_PARTNERSHIPS_SUCCESS,
});

export const fetchIntegrationLedgerPartnershipsFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_LEDGER_PARTNERSHIPS_FAILURE,
});

export const fetchLedgerUnmatchedLedgerFundingAccountsRequest = (integrationConfigId, filter = {}) => ({
  payload: { filter, integrationConfigId },
  type: types.FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_REQUEST,
});

export const fetchLedgerUnmatchedLedgerFundingAccountsSuccess = (payload) => ({
  payload,
  type: types.FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_SUCCESS,
});

export const fetchAdditionalLedgerUnmatchedLedgerFundingAccountsSuccess = (payload) => ({
  payload,
  type: types.FETCH_ADDITIONAL_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_SUCCESS,
});

export const fetchLedgerUnmatchedLedgerFundingAccountsFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_FAILURE,
});

export const fetchLedgerUnmatchedPlatformFundingAccountsRequest = (integrationConfigId, filter = {}) => ({
  payload: { filter, integrationConfigId },
  type: types.FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_REQUEST,
});

export const fetchLedgerUnmatchedPlatformFundingAccountsSuccess = (payload) => ({
  payload,
  type: types.FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_SUCCESS,
});

export const fetchLedgerUnmatchedPlatformFundingAccountsFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_FAILURE,
});

export const searchFundingAccountsRequest = (integrationConfigId, searchTerm) => ({
  payload: { integrationConfigId, searchTerm },
  type: types.SEARCH_FUNDING_ACCOUNTS_REQUEST,
});

export const searchFundingAccountsSuccess = (payload) => ({
  payload,
  type: types.SEARCH_FUNDING_ACCOUNTS_SUCCESS,
});

export const searchFundingAccountsFailure = (errors) => ({
  payload: { errors },
  type: types.SEARCH_FUNDING_ACCOUNTS_FAILURE,
});

// ========
// Disconnect
// ========

export const disconnectIntegrationConfigRequest = (id, integrationName) => ({
  payload: { id, integrationName },
  type: types.DISCONNECT_INTEGRATION_CONFIG_REQUEST,
});

export const disconnectIntegrationConfigSuccess = (id) => ({
  payload: { id },
  type: types.DISCONNECT_INTEGRATION_CONFIG_SUCCESS,
});

export const disconnectIntegrationConfigFailure = (errors) => ({
  payload: { errors },
  type: types.DISCONNECT_INTEGRATION_CONFIG_FAILURE,
});

// ========
// Connect
// ========

export const connectIntegrationOAuthRequest = (integration) => ({
  payload: { integration },
  type: types.CONNECT_INTEGRATION_REQUEST,
});

export const connectIntegrationOAuthFailure = (errors) => ({
  payload: { errors },
  type: types.CONNECT_INTEGRATION_FAILURE,
});

// ========
// Refresh
// ========

export const refreshIntegrationConfigRequest = (id) => ({
  payload: { id },
  type: types.REFRESH_INTEGRATION_CONFIG_REQUEST,
});

export const refreshIntegrationConfigSuccess = (id) => ({
  payload: { id },
  type: types.REFRESH_INTEGRATION_CONFIG_SUCCESS,
});

export const refreshIntegrationConfigFailure = (errors) => ({
  payload: { errors },
  type: types.REFRESH_INTEGRATION_CONFIG_FAILURE,
});

// ========
// Submit
// ========

/**
 * Submit match platform partnership form action
 * @returns {ReduxAction}
 */
export const submitMatchPlatformPartnershipForm = () => submit(formNamesMatchPartnership.PLATFORM_TO_LEDGER);

/**
 * Submit merge ledger partnership form action
 * @returns {ReduxAction}
 */
export const submitMergeLedgerPartnershipForm = () => submit(formNamesMatchPartnership.LEDGER_TO_PLATFORM);

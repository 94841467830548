import { all, call, put, takeEvery } from 'redux-saga/effects';
import { changeBillViewSelectedBillIndex } from 'actions/billViewForm';
import { removeCreateItemBill } from 'actions/createItemForm';
import { handleRequestErrors } from 'actions/errors';
import { deleteBillAttachmentRoutine } from 'actions/routines/itemAttachments';
import { parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { getNextBillIndex } from 'helpers/itemAttachments';
import {} from 'interfaces/actions';
import {} from 'interfaces/fetchService';
import {} from 'interfaces/itemAttachment';
import { deleteItemAttachment } from './api';
export function* deleteBillAttachmentOnServer(action) {
    const handleReqErrors = handleRequestErrors.bind(null, deleteBillAttachmentRoutine.failure);
    try {
        const { itemId, attachment } = action.payload;
        yield put(deleteBillAttachmentRoutine.request());
        const response = yield call(deleteItemAttachment, itemId, attachment.id);
        if (response.ok) {
            yield put(deleteBillAttachmentRoutine.success(action.payload));
            return;
        }
        yield put(handleReqErrors(parseErrorResponse(response)));
    }
    catch (error) {
        yield put(handleReqErrors(parseCaughtError(error)));
    }
}
export function* handleDeleteBillAttachmentTrigger(action) {
    const { selectedAttachment, attachment, attachments } = action.payload;
    const nextIndex = getNextBillIndex(selectedAttachment.index, attachment.index, attachments);
    yield all([put(removeCreateItemBill(attachment.index)), put(changeBillViewSelectedBillIndex(nextIndex))]);
}
export default function* itemAttachments() {
    yield takeEvery(deleteBillAttachmentRoutine.TRIGGER, handleDeleteBillAttachmentTrigger);
}

import { REST } from 'helpers/api';

import { passwordInputEndpoint } from 'services/api/passwordInputEndpoints';

export const checkPasswordStrength = async (payload) =>
  REST.post({
    payload,
    endpoint: passwordInputEndpoint,
    requireAuth: false,
  });

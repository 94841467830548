export const achInstructions = [
  {
    id: 1,
    key: '1',
    text: 'You should receive an email shortly confirming your updated payment method.',
  },
  {
    id: 2,
    key: '2',
    text: 'You are set to receive your payments via Bank Transfer.',
  },
  {
    id: 3,
    key: '3',
    text: 'Future payments will be deposited to your selected bank account.',
  },
];

export const achInstructionsVendorRegistered = [
  {
    id: 1,
    key: '2',
    text: 'You should receive an email shortly confirming your vendor registration.',
  },
  {
    id: 2,
    key: '2',
    text: 'You are set to receive your payments via Bank Transfer.',
  },
  {
    id: 3,
    key: '3',
    text: 'Future payments will be deposited to your selected bank account.',
  },
];

export const checkInstructions = [
  {
    id: 1,
    key: '1',
    text: 'You should receive an email shortly confirming your updated payment method.',
  },
  {
    id: 2,
    key: '2',
    text: 'You are set to receive your payments by check.',
  },
  {
    id: 3,
    key: '3',
    text: 'Future payments will be delivered to your selected address.',
  },
];

export const alertOptions = {
  alertText: 'Please click the ‘Update my payment methods’ button to continue.',
  alertTitle: 'Are you sure you want to leave this page without updating your payment method?',
};

import { cva } from 'class-variance-authority';
export const filtersListItemStyles = cva(['flex', 'justify-between', 'py-1', 'text-sm'], {
    variants: {
        isDisabled: {
            true: ['text-grey-30'],
            false: ['hover:cursor-pointer'],
        },
        isSelected: {
            true: ['text-blue-40'],
            false: ['text-black'],
        },
    },
    defaultVariants: {
        isSelected: false,
        isDisabled: false,
    },
});

import PropTypes from 'prop-types';
import React from 'react';

import LinkNewPage from 'components/link/LinkNewPage';

const LinkLearnMore = ({ href, transformText }) => (
  <LinkNewPage className="primary enable-pointer-events" href={href}>
    {transformText('Learn more')}
  </LinkNewPage>
);

LinkLearnMore.propTypes = {
  href: PropTypes.string.isRequired,
  transformText: PropTypes.func,
};

LinkLearnMore.defaultProps = {
  transformText: (str) => str,
};

export default LinkLearnMore;

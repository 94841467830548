import {} from 'interfaces/auth';
export var QuickswitchActionType;
(function (QuickswitchActionType) {
    QuickswitchActionType["FETCH_MEMBERSHIP_LIST_REQUEST"] = "FETCH_MEMBERSHIP_LIST_REQUEST";
    QuickswitchActionType["FETCH_MEMBERSHIP_SEARCH_REQUEST"] = "FETCH_MEMBERSHIP_SEARCH_REQUEST";
    QuickswitchActionType["UPDATE_MEMBERSHIP_LIST"] = "UPDATE_MEMBERSHIP_LIST";
    QuickswitchActionType["FETCH_MEMBERSHIP_REQUEST_ERROR"] = "FETCH_MEMBERSHIP_REQUEST_ERROR";
    QuickswitchActionType["SWITCH_TO_BRANDED_SUBDOMAIN"] = "SWITCH_TO_BRANDED_SUBDOMAIN";
    QuickswitchActionType["SWITCH_MEMBERSHIP_REQUEST"] = "SWITCH_MEMBERSHIP_REQUEST";
    QuickswitchActionType["OPEN_MODAL"] = "OPEN_QUICKSWITCH_MODAL";
    QuickswitchActionType["CLOSE_MODAL"] = "CLOSE_QUICKSWITCH_MODAL";
    QuickswitchActionType["OPEN_ERROR_MODAL"] = "OPEN_QUICKSWITCH_ERROR_MODAL";
    QuickswitchActionType["CLOSE_ERROR_MODAL"] = "CLOSE_QUICKSWITCH_ERROR_MODAL";
    QuickswitchActionType["RE_LOGIN"] = "RE_LOGIN";
    QuickswitchActionType["RE_LOGIN_SUCCESS"] = "RE_LOGIN_SUCCESS";
    QuickswitchActionType["RE_LOGIN_ERROR"] = "RE_LOGIN_ERROR";
    QuickswitchActionType["REDIRECT_TO_SSO_LOGIN"] = "REDIRECT_TO_SSO_LOGIN";
    QuickswitchActionType["SWITCH_RESET_STATE"] = "SWITCH_RESET_STATE";
    QuickswitchActionType["SET_SEARCH_TERM"] = "SET_SEARCH_TERM";
    QuickswitchActionType["SET_TOTAL_NUMBER_OF_MEMBERSHIPS"] = "SET_TOTAL_NUMBER_OF_MEMBERSHIPS";
})(QuickswitchActionType || (QuickswitchActionType = {}));

import { partnershipRequestStateFundingAccountRelationships } from 'constants/relationships';

import { REST } from 'helpers/api';

import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { getSubmitPartnershipRequestFundingAccountStateEndpoint } from 'services/api/partnershipsRequestEndpoints';

/**
 * Submits form data to partnership request funding state endpoint
 * @param {Object} props
 * @param {String} props.fundingAccountRequestStateId
 * @param {String} props.partnershipRequestId
 * @param {Object} props.submitPayload
 * @return {Promise<*>}
 */
export const submitPaymentMethodFundingState = async ({
  fundingAccountRequestStateId,
  partnershipRequestId,
  submitPayload,
}) => {
  const endpoint = getSubmitPartnershipRequestFundingAccountStateEndpoint(
    partnershipRequestId,
    fundingAccountRequestStateId,
  );
  const normalizedPayload = payloadToJSONAPI(submitPayload, ...partnershipRequestStateFundingAccountRelationships);
  const config = {
    endpoint,
    payload: normalizedPayload,
  };

  return REST.patch(config);
};

import React from 'react';
import { Field } from 'redux-form';
import ApplyToFuturePendingPaymentsCheckbox from 'components/form/ApplyToFuturePendingPaymentsCheckbox';
import { updatePaymentMethodFormFields } from 'constants/formFields';
import { isPathExternalV2AcceptPartnershipOrItem } from 'helpers/external';
import { isFundingAccountValid } from 'helpers/funding';
import {} from './AcceptPendingPaymentsCheckboxField.types';
import './AcceptPendingPaymentsCheckboxField.scss';
const AcceptPendingPaymentsCheckboxField = ({ fundingAccount, partnership, }) => {
    const isInvalidFundingAccount = !isFundingAccountValid(fundingAccount);
    const isCustomerAcceptingPartnershipOrItem = isPathExternalV2AcceptPartnershipOrItem();
    if (isCustomerAcceptingPartnershipOrItem || isInvalidFundingAccount) {
        return null;
    }
    const applyToFuturePendingPaymentsCheckboxProps = {
        companyName: partnership.name,
        currencyCode: fundingAccount.currencyCode,
    };
    return (React.createElement(Field, { className: "accept-pending-payments-checkbox", component: ApplyToFuturePendingPaymentsCheckbox, isRequired: false, name: updatePaymentMethodFormFields.ACCEPT_FUTURE_PENDING_PAYMENTS, props: applyToFuturePendingPaymentsCheckboxProps, type: "checkbox" }));
};
export default AcceptPendingPaymentsCheckboxField;

import React from 'react';
import { connect } from 'react-redux';
import { removeNotFoundError } from 'actions/errors';
import { ProtectedRouteWarn } from 'components/routing';
import { DASHBOARD, LOGIN } from 'constants/routes';
import { hasAuthToken } from 'helpers/auth';
import { isExternalPathAny } from 'helpers/external';
import { asPath } from 'helpers/routeHelpers';
export const NotFound404 = ({ location, history, onRemoveNotFoundError }) => {
    const isAuthenticated = hasAuthToken();
    const isExternal = isExternalPathAny();
    React.useEffect(() => () => {
        onRemoveNotFoundError();
    }, [onRemoveNotFoundError]);
    const goBack = () => {
        history.goBack();
    };
    const redirectUser = () => {
        const redirectURL = isAuthenticated ? asPath(DASHBOARD) : asPath(LOGIN);
        history.push(redirectURL);
    };
    let actionText;
    if (isExternal) {
        actionText = undefined;
    }
    else if (isAuthenticated) {
        actionText = 'Go to my dashboard';
    }
    else {
        actionText = 'Take me to the login page';
    }
    let previousPathname;
    if (location?.state?.from) {
        previousPathname = location.state.from.pathname;
    }
    return (React.createElement(ProtectedRouteWarn, { actionText: actionText, message: "Oops! The page you're looking for does not exist or you do not have access.", onPrimaryAction: redirectUser, onReturnClick: goBack, returnClassName: "action-wrapper", returnText: !previousPathname &&
            !isExternal && (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "icon-ic-arrow-back" }),
            React.createElement("span", { className: "margin-left--m" }, "Take me back to the last page"))), title: "Error 404" }));
};
const mapDispatchToProps = {
    onRemoveNotFoundError: removeNotFoundError,
};
export default connect(null, mapDispatchToProps)(NotFound404);

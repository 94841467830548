import modal from 'global/css/variables/modal.scss';

export const navHeight = '62px';

/** @deprecated Use the desired value directly instead */
export const iconSizes = {
  EXTRA_SMALL: 10,
  SMALL: 12,
  MEDIUM: 14,
  EXTRA_MEDIUM: 15,
  LARGE: 16,
  EXTRA_LARGE: 17,
  XX_LARGE: 18,
};

/**
 * Numeric values (math-able), to accompany css/base/spacing.scss
 * @enum {number}
 */
export const spacingSize = {
  SPACING_32: 32,
};

/**
 * @enum {string}
 */
export const spacing = {
  NONE: '0',
  EXTRA_SMALL: '2px',
  /* TODO: [FRON-1565] move 6px items to 4px or 8px */
  SMALL_ALT: '4px',
  SMALL: '6px',
  EXTRA_MEDIUM: '8px',
  MEDIUM: '12px',
  MEDIUM_LARGE: '16px',
  LARGE: '24px',
  EXTRA_LARGE: '40px',
};

export const tagHeight = '20px';

export const { modalFooterHeight } = modal;

/**
 * Map of (known) error types
 * @enum {string}
 */
export const ErrorTypes = {
  UNAUTHORIZED: 'unauthorized',
  NOT_FOUND: 'not_found',
  CRITICAL: 'critical',
  GENERAL: 'general',
  LEDGER: 'ledger',
  LEDGER_DISCONNECTED: 'ledgerDisconnected',
  VALIDATION: 'validation',
  FIELDS: 'fields',
};

/**
 * Map of funding error types
 * @enum {string}
 */
export const FundingErrors = {
  INVALID_ROUTING_NUMBER: 'Invalid bank routing number!',
};

/**
 * Map of unauthorized error types
 * @enum {string}
 */
export const UnauthorizedErrors = {
  DISABLED_USER: 'User account is disabled.',
  AUTO_LOGOUT_INACTIVITY: 'Auto Logout: inactivity.',
  AUTO_LOGOUT_FORCE: 'Auto Logout: force.',
};

export const GENERIC_ERROR = 'Something went wrong';

export const UNPROCESSABLE_REQUEST_ERROR = 'Unable to process this request.';

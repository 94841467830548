import { isPartnershipRequestTypeUpdatePaymentMethod } from 'helpers/partnershipRequest';
import { getInitialStepIndexForExternalFlow } from 'helpers/progressBarStepper';
import { lastIndexOf } from 'helpers/utility';

/**
 * Get the steps from a httpResponse
 * @param {HttpResponse} response
 * @returns {ExternalStep[]}
 */
export const getStepsFromResponse = (response) => response.data.meta.steps;

/**
 * Get the partnershipRequest from a httpResponse
 * @param {HttpResponse} response
 * @returns {PartnershipRequest}
 */
export const getPartnershipRequestFromResponse = (response) => response.data.partnershipRequest;

/**
 * @param {ExternalStep[]} steps
 * @returns {number}
 */
export const getLastStepIndex = (steps) => lastIndexOf(steps);

/**
 * Get the initial step index from a httpResponse
 * @param {HttpResponse} response
 * @returns {number}
 */
export const getInitialStepIndexFromResponse = (response) => {
  const steps = getStepsFromResponse(response);
  const partnershipRequest = getPartnershipRequestFromResponse(response);
  const isUpdatePayment = isPartnershipRequestTypeUpdatePaymentMethod(partnershipRequest);

  return getInitialStepIndexForExternalFlow(steps, isUpdatePayment);
};

/**
 * Creates new steps from previous and next external steps
 * @param {ExternalStep[]} prevSteps
 * @param {ExternalStep[]} nextSteps
 * @returns {ExternalStep[]} steps
 */
export const updateSteps = (prevSteps, nextSteps) => {
  const nextStepsByName = nextSteps.reduce(
    (map, step) => ({
      ...map,
      [step.stepName]: step,
    }),
    {},
  );

  return prevSteps.map((currentStep) => nextStepsByName[currentStep.stepName] || currentStep);
};

/**
 * Add updated steps to new response object
 * @param {HttpResponse} data
 * @param {ExternalStep[]} steps
 * @returns {HttpResponse} response
 */
export const addNextStepsToResponseData = (data, steps) => ({
  ...data,
  meta: {
    steps,
  },
});

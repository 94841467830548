import {} from 'redux';
import {} from 'actions/modifiers/meta.types';
import { toaster } from 'services/toaster';
export const generateNotificationMiddleware = (toast) => () => (next) => (action) => {
    if (action?.meta?.notification) {
        const { type, message, options } = action?.meta?.notification;
        toast[type](message, options);
    }
    return next(action);
};
export const notificationMiddleware = generateNotificationMiddleware(toaster);

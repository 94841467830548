import { Badge, Button, Tag } from '@routable/gross-ds';
import { Filter } from '@carbon/icons-react';
import React, {} from 'react';
import { Item, useTabListState } from 'react-stately';
import { FilterQuickAction, FiltersModal, FilterTag } from './components';
export const Filters = ({ appliedFiltersList, buttonSize, filtersModalState, onClearFilter, onResetFilter, ...props }) => {
    const tabsState = useTabListState(props);
    const displayFiltersList = appliedFiltersList
        .filter(({ filter }) => Boolean(tabsState.collection.getItem(filter)))
        .map((filter) => {
        const item = tabsState.collection.getItem(filter.filter);
        return {
            ...filter,
            textValue: item?.textValue,
            children: item?.props.children,
            tag: item?.props.renderTags({
                filterName: filter.filter,
                value: filter.value,
                size: buttonSize === 'small' ? 'default' : 'large',
                onRemove: (val) => onClearFilter(filter.filter, val),
            }),
        };
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex flex-wrap gap-3" },
            React.createElement(Button, { "data-testid": "filters-trigger", variant: "outline", onPress: filtersModalState.open, size: buttonSize },
                React.createElement(Filter, { className: "fill-grey-50" }),
                React.createElement("span", null, "Filter"),
                !!displayFiltersList.length && (React.createElement(Badge, { "aria-label": "Number of filters", intent: "blue" }, displayFiltersList.length))),
            displayFiltersList.map(({ filter, tag, textValue, children }) => (React.createElement(FilterQuickAction, { key: filter, onApply: props.onApplyFilters, onRemove: () => onClearFilter(filter, null, { forceClear: true, shouldApplyFilters: true }), onReset: () => onResetFilter(filter), tag: React.createElement(FilterTag, { label: textValue, size: buttonSize }, tag) }, children)))),
        React.createElement(FiltersModal, { ...props, tabsState: tabsState, filtersModalState: filtersModalState, onClearFilter: onClearFilter })));
};
Filters.Item = Item;
Filters.ModalContent = ({ children }) => (React.createElement("div", { className: "group-data-[quick-action='true']:hidden" }, children));
Filters.createSingleTagRenderer =
    (valuesMap) => ({ value, onRemove, size }) => {
        return (React.createElement(Tag, { intent: "primary", size: size, onRemove: onRemove }, valuesMap[value]));
    };
Filters.createMultiTagsRenderer =
    (valuesMap) => ({ value, onRemove, size }) => {
        if (!value || !Array.isArray(value)) {
            return null;
        }
        return (React.createElement(React.Fragment, null, value.map((singleValue) => (React.createElement(Tag, { intent: "primary", size: size, key: singleValue, onRemove: () => onRemove(singleValue) }, valuesMap?.[singleValue])))));
    };

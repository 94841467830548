import { Select, NumberInput, Tag } from '@routable/gross-ds';
import { operatorModel, operatorOptions, operatorSymbols } from '@routable/shared';
import _set from 'lodash/set';
import React, {} from 'react';
export const AmountFilter = ({ onChange, value = { operator: operatorModel.Enum.GTE, value: '' }, }) => {
    const operatorSelectOptions = Object.entries(operatorOptions).map(([key, label]) => ({ label, value: key }));
    const handleOperatorChange = (operatorValue) => {
        const parsedOperator = operatorModel.safeParse(operatorValue);
        if (!parsedOperator.success) {
            return;
        }
        if (parsedOperator.data === 'BETWEEN') {
            onChange({
                ...value,
                operator: parsedOperator.data,
                value: Array.isArray(value.value) ? value.value : [value.value, ''],
            });
        }
        else {
            onChange({
                ...value,
                operator: parsedOperator.data,
                value: Array.isArray(value.value) ? value.value[0] : value.value,
            });
        }
    };
    const handleValueChange = (event) => {
        onChange(_set({ ...value }, event.target.name, event.target.value));
    };
    return (React.createElement("div", { className: "flex gap-3 items-center" },
        React.createElement("div", { className: "w-7/12" },
            React.createElement(Select, { "aria-label": "Amount operator", name: "operator", onChange: handleOperatorChange, options: operatorSelectOptions, value: value.operator })),
        value.operator === 'BETWEEN' ? (React.createElement(React.Fragment, null,
            React.createElement(NumberInput, { "aria-label": "Amount from", name: "value.0", onChange: handleValueChange, placeholder: "0.00", value: value.value?.[0] }),
            React.createElement("span", { className: "px-2" }, "-"),
            React.createElement(NumberInput, { "aria-label": "Amount to", name: "value.1", onChange: handleValueChange, placeholder: "0.00", value: value.value?.[1] }))) : (React.createElement(NumberInput, { "aria-label": "Amount", name: "value", onChange: handleValueChange, placeholder: "0.00", value: value.value }))));
};
const getFormattedCurrencyValue = (value) => `${value || '0.00'} USD`;
const Tags = ({ value, onRemove, size }) => {
    const operatorSymbol = operatorSymbols[value?.operator];
    const tagContent = value?.operator === 'BETWEEN'
        ? `${getFormattedCurrencyValue(value?.value?.[0])} ${operatorSymbol} ${getFormattedCurrencyValue(value?.value?.[1])}`
        : `${operatorSymbol} ${getFormattedCurrencyValue(value?.value)}`;
    return (React.createElement(Tag, { "data-testid": "tag:totalPaidInYear", intent: "primary", onRemove: onRemove, size: size }, tagContent));
};
AmountFilter.Tags = Tags;

import { createErrorReducer } from 'store/redux';

import { FETCH_BILLING_CODES_FAILURE, FETCH_BILLING_CODES_REQUEST, FETCH_BILLING_CODES_SUCCESS } from 'types/billing';

export const failureCases = [FETCH_BILLING_CODES_FAILURE];
export const successCases = [FETCH_BILLING_CODES_REQUEST, FETCH_BILLING_CODES_SUCCESS];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

import PropTypes from 'prop-types';
import React from 'react';

import { Intent } from 'constants/ui';

import ModalFooterButton from './ModalFooterButton';

const ModalFooterButtonAllDone = ({ isDisabled, onCloseModal }) => (
  <ModalFooterButton
    id="modalFooter_allDone_btn"
    intent={Intent.NEUTRAL}
    isDisabled={isDisabled}
    onClick={onCloseModal}
    type="button"
  >
    All done
  </ModalFooterButton>
);

ModalFooterButtonAllDone.propTypes = {
  isDisabled: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
};

ModalFooterButtonAllDone.defaultProps = {
  isDisabled: undefined,
};

export default ModalFooterButtonAllDone;

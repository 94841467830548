import { StatusIndicator } from '@routable/gross-ds';
import clsx from 'clsx';
import React from 'react';
import { getItemStatusProps } from 'helpers/status';
const ItemStatusBox = ({ className, item }) => {
    const { statusVariant, statusText } = getItemStatusProps(item);
    return (React.createElement("div", { className: clsx('inline-flex items-center py-1 px-2 border border-grey-20 rounded bg-white', className) },
        React.createElement(StatusIndicator, { className: "text-xs", status: statusVariant }, statusText)));
};
export default ItemStatusBox;

import {} from '@routable/tablematic';
import _set from 'lodash/set';
import {} from './overlays.models';
export const flattenOverlayDataBySource = (overlaysData, source = 'ocr') => {
    return Object.entries(overlaysData || {}).reduce((acc, [key, value]) => {
        if (key === 'lineItems') {
            const transformedLineItems = overlaysData.lineItems?.map((lineItem) => flattenOverlayDataBySource(lineItem));
            acc.lineItems = transformedLineItems;
            return acc;
        }
        const ocrValueFromOverlay = value.find((overlay) => overlay.source === source)?.value;
        return {
            ...acc,
            [key]: ocrValueFromOverlay,
        };
    }, {});
};
export const transformOverlayDataWithFieldMapping = (fieldMapping, overlaysData) => {
    if (!overlaysData || !fieldMapping) {
        return {};
    }
    const extractLineItemLabels = (mapping) => {
        return Object.entries(mapping).reduce((acc, [key, fieldPath]) => {
            const [, lineItemValueKey] = fieldPath.split('.');
            acc[key] = lineItemValueKey;
            return acc;
        }, {});
    };
    return Object.entries(fieldMapping).reduce((acc, [fieldKey, fieldPath]) => {
        if (fieldKey === 'lineItem') {
            const lineItemMappingLabels = extractLineItemLabels(fieldPath);
            const transformedLineItems = overlaysData.lineItems?.map((lineItem) => transformOverlayDataWithFieldMapping(lineItemMappingLabels, lineItem));
            acc.overlayLineItems = transformedLineItems;
            return acc;
        }
        const ocrValueFromOverlay = overlaysData[fieldKey]?.[0]?.value;
        if (ocrValueFromOverlay) {
            _set(acc, fieldPath, ocrValueFromOverlay);
        }
        return acc;
    }, {});
};

import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { ToolbarCloseButton } from 'components/toolbar';

import { SETTINGS_ACCOUNT_ROUTE, SETTINGS_ACCOUNT_FILTERS } from 'constants/routes';

import {
  isCurrentCompanyKycStatusPending,
  isCurrentCompanyKycStatusRetry,
  isCurrentCompanyKycStatusUnverified,
  isCurrentCompanyKycStatusDocument,
} from 'helpers/currentCompany';

import SubmitForVerificationButton from 'modules/signup-v3/components/SubmitForVerificationButton';
import { views } from 'modules/signup-v3/constants';

import { currentCompanySelector } from 'selectors/currentCompanySelectors';
import { itemsAllIdsSelector } from 'selectors/itemsSelectors';
import { allPartnershipsSelector } from 'selectors/partnershipsSelectors';
import { activeViewSelector } from 'selectors/signupSelectors';

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

const RetryKYCHeader = ({ history }) => {
  const currentCompany = useSelector(currentCompanySelector);
  const activeView = useSelector(activeViewSelector);
  const items = useSelector(itemsAllIdsSelector);
  const partners = useSelector(allPartnershipsSelector);

  const hasUsedRoutable = items?.length || partners?.length;

  const onClose = () => {
    history.push(`${SETTINGS_ACCOUNT_ROUTE}/${SETTINGS_ACCOUNT_FILTERS.COMPANY_LEGAL}`);
  };

  let showHeader = true;
  if (isCurrentCompanyKycStatusUnverified(currentCompany)) {
    showHeader = false;
  }

  let showCloseButton = true;
  if (
    // if user has never accessed /dashboard, don't show the 'x' button which redirects back to dashboard
    // if user has accessed dashboard
    !hasUsedRoutable &&
    (isCurrentCompanyKycStatusPending(currentCompany) ||
      isCurrentCompanyKycStatusRetry(currentCompany) ||
      isCurrentCompanyKycStatusDocument(currentCompany))
  ) {
    showCloseButton = false;
  }

  // when RCTM is in a error or retry state, they will be accessing Sign Up Flow from Account settings
  // In this case, we give them the option to go back to settings via this close button
  // as well as an extra submit button in case the Review page is expanded
  if (showHeader) {
    return (
      <Header>
        {activeView === views.review && <SubmitForVerificationButton withHeaderStyle />}
        {showCloseButton && <ToolbarCloseButton className="margin-left--m" onClick={onClose} />}
      </Header>
    );
  }

  return null;
};

export default withRouter(RetryKYCHeader);

import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import Icon, { IconNames } from 'components/icon';

import { colors, sizes } from 'constants/styles';

import { callWithArgsIfIsFn } from 'helpers/utility';

const HintTitle = ({ children, className, collapsed, dataFullStory, isCollapsible, marginBottom, setIsCollapsed }) => (
  <div
    aria-hidden="true"
    className={clsx('hint--title--container', { 'cursor--pointer': isCollapsible })}
    onClick={() => callWithArgsIfIsFn(setIsCollapsed, !collapsed)}
  >
    <p
      className={clsx('hint--title', className, { 'remove-margin-bottom': !marginBottom })}
      data-fullstory={dataFullStory}
    >
      {children}
    </p>

    {isCollapsible && (
      <Icon
        className={clsx('chevron-icon', { 'is-open': !collapsed })}
        color={colors.colorBlackHex}
        icon={IconNames.CHEVRON_RIGHT}
        size={sizes.iconSizes.XX_LARGE}
      />
    )}
  </div>
);

HintTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  collapsed: PropTypes.bool,
  dataFullStory: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  marginBottom: PropTypes.bool,
  setIsCollapsed: PropTypes.func,
};

HintTitle.defaultProps = {
  className: undefined,
  collapsed: undefined,
  dataFullStory: undefined,
  isCollapsible: undefined,
  marginBottom: true,
  setIsCollapsed: undefined,
};

export default HintTitle;

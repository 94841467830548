import { fetchedMembershipMatchRelationships } from 'constants/relationships';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';

/**
 * Parse an HTTPS response into a MembershipMatch with relationships.
 * @param {HttpResponse} response
 * @returns {Object}
 */
export const parseFetchedMembershipMatch = (response) =>
  getObjectsByIdWithRelationships(response.data.membershipMatch, fetchedMembershipMatchRelationships);

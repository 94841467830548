import React from 'react';
import { Text, LinkNewPage, WhiteSpace } from 'components';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import helpDocs from 'helpers/helpDocs';
const AutomatedBalanceTooltipText = () => (React.createElement(Text.Regular, null,
    `Your balance is automated by ${PLATFORM_DISPLAY_SHORT_NAME}.
    You are only able to deposit funds if your balance goes negative due to a payment failure.`,
    React.createElement(WhiteSpace, null),
    React.createElement(LinkNewPage, { className: "primary", href: helpDocs.EXPRESS_PAYMENTS_AUTOMATED_BALANCE }, "Learn more"),
    "."));
export default AutomatedBalanceTooltipText;

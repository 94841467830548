import { dateOperatorModel } from './DateFilter.types';
export const operatorOptions = {
    [dateOperatorModel.Enum.GTE]: 'is on or after',
    [dateOperatorModel.Enum.EQ]: 'is on',
    [dateOperatorModel.Enum.LTE]: 'is on or before',
    [dateOperatorModel.Enum.BETWEEN]: 'is between',
};
export const tagPostText = {
    [dateOperatorModel.Enum.GTE]: operatorOptions.GTE,
    [dateOperatorModel.Enum.EQ]: '',
    [dateOperatorModel.Enum.LTE]: operatorOptions.LTE,
};
export const operatorSelectOptions = Object.entries(operatorOptions).map(([key, label]) => ({
    label,
    value: key,
}));

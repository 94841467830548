import PropTypes from 'prop-types';
import React from 'react';

import ShadedBlock from 'components/shadedBlock';

import { Intent } from 'constants/ui';

import { getClassNames } from 'helpers/ui';

/**
 * Confirm alert SWAL content component. Must be rendered HTML for Sweetalert to accept it as content.
 * Note: this also has to be a single element, hence the <div> and not <React.Fragment>, for SWAL to render it properly
 * @param {*} blockContent
 * @param {*} bottomContent
 * @param {*} topContent
 * @param {RestOfProps} rest
 * @return {*}
 */
const ConfirmAlertContent = ({ blockContent, bottomContent, topContent, ...rest }) => (
  <div className={getClassNames(rest, { 'confirm-alert-content': true })}>
    {topContent && <p className="alert-error-text top-content">{topContent}</p>}

    {blockContent && (
      <ShadedBlock
        className="alert-error-text margin-bottom--large margin-top--m block-content"
        intent={Intent.NEUTRAL}
      >
        {blockContent}
      </ShadedBlock>
    )}

    {bottomContent && <span className="alert-error-text bottom-content">{bottomContent}</span>}
  </div>
);

ConfirmAlertContent.propTypes = {
  className: PropTypes.string,
  blockContent: PropTypes.node,
  bottomContent: PropTypes.node,
  topContent: PropTypes.node,
};

ConfirmAlertContent.defaultProps = {
  className: undefined,
  blockContent: undefined,
  bottomContent: undefined,
  topContent: undefined,
};

export default ConfirmAlertContent;

let eventMap = [];
export const RoutableEventsSync = {
    isActiveEvent: (eventName) => {
        return eventMap.findIndex((event) => event.eventName === eventName) > -1;
    },
    Subscribe: (eventName, onEvent, singleSubscription) => {
        if (RoutableEventsSync.isActiveEvent(eventName) && singleSubscription) {
            return;
        }
        const _internalCallback = (event) => {
            if (onEvent) {
                onEvent(event.detail);
            }
        };
        document.addEventListener(eventName, _internalCallback);
        eventMap.push({ eventName, onEvent: _internalCallback });
    },
    Dispose: (eventName) => {
        const eventIdx = eventMap.findIndex((event) => event.eventName === eventName);
        if (eventIdx > -1) {
            try {
                document.removeEventListener(eventName, eventMap[eventIdx].onEvent);
            }
            catch (ex) {
            }
            eventMap = eventMap.filter((event) => event.eventName !== eventName);
        }
    },
    Once: (eventName, onEvent) => {
        const handleEventOnce = (event) => {
            onEvent(event);
            RoutableEventsSync.Dispose(eventName);
        };
        RoutableEventsSync.Subscribe(eventName, handleEventOnce);
    },
    Publish: (eventName, data, options) => {
        document.dispatchEvent(new CustomEvent(eventName, {
            bubbles: options?.bubbles ?? true,
            cancelable: options?.cancelable ?? false,
            detail: data,
        }));
    },
};

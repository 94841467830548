import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';
import { createInternationalFundingAccountRoutine } from 'actions/routines/funding';

import { getFundingInfoBankAccountsById } from 'helpers/fundingReducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import { FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_SUCCESS } from 'types/clearingAccounts';
import {
  SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_SUCCESS,
  SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_SUCCESS,
} from 'types/connectBank';
import { FETCH_FUNDING_ACCOUNTS_SUCCESS, FETCH_SINGLE_FUNDING_ACCOUNT_SUCCESS } from 'types/funding';
import {
  FETCH_ADDITIONAL_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_SUCCESS,
  FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_SUCCESS,
  FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_SUCCESS,
  SEARCH_FUNDING_ACCOUNTS_SUCCESS,
} from 'types/integrations';
import { FETCH_ITEM_SUCCESS } from 'types/item';
import {
  FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_SUCCESS,
  FETCH_PARTNERSHIP_SUCCESS,
  SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_SUCCESS,
} from 'types/partnership';
import { FETCH_ITEM_TRANSACTIONS_SUCCESS, FETCH_TRANSACTIONS_SUCCESS } from 'types/transactions';

const fundingInfoBankAccountsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case createInternationalFundingAccountRoutine.SUCCESS:
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
    case FETCH_ADDITIONAL_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_SUCCESS:
    case FETCH_SINGLE_FUNDING_ACCOUNT_SUCCESS:
    case FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_SUCCESS:
    case FETCH_FUNDING_ACCOUNTS_SUCCESS:
    case FETCH_ITEM_SUCCESS:
    case FETCH_ITEM_TRANSACTIONS_SUCCESS:
    case FETCH_TRANSACTIONS_SUCCESS:
    case FETCH_PARTNERSHIP_SUCCESS:
    case FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_SUCCESS:
    case FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_SUCCESS:
    case FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_SUCCESS:
    case SEARCH_FUNDING_ACCOUNTS_SUCCESS:
    case SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_SUCCESS:
    case SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_SUCCESS:
    case SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_SUCCESS:
      return deepMergeWithArrayReplacement(
        state,
        getFundingInfoBankAccountsById(action.payload.fundingInfoBank, state),
      );

    default:
      return state;
  }
};

export default fundingInfoBankAccountsByIdReducer;

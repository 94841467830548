import PropTypes from 'prop-types';
import React from 'react';

import { Form, FormFieldLabel } from 'components/form';

import { externalCompanyGeneralFields } from 'constants/formFields';
import { formNamesExternal } from 'constants/forms';

import { isExternalPathV2Pay } from 'helpers/external';
import { isInternationalPartnership } from 'helpers/partnerships';

import AddressContainer from 'modules/address/containers/AddressContainer';
import PartnerCompanyType from 'modules/createPartnerCompany/presenters/PartnerCompanyType';

import { PartnerCompanyEntityForm } from './components';
import { showInvalidCountryToast } from './helpers';

import './PartnershipCompanyGeneralForm.scss';

const formName = formNamesExternal.PARTNER_COMPANY_GENERAL;

/**
 * Partnership type, address, and tax info forms for Partnership Requests
 * @param {Object} props
 * @param {Object} props.addressInfo
 * @param {function} props.change
 * @param {boolean} props.collectVendorTaxInfo
 * @param {boolean} props.displayInputForTin
 * @param {function} props.handleSubmit
 * @param {String} props.partnerCompanyName
 * @param {Partnership} props.partnership
 * @param {function} props.resetSection
 * @returns {StatelessComponent}
 */
const PartnershipCompanyGeneralForm = ({
  addressInfo,
  change,
  collectVendorTaxInfo,
  displayInputForTin,
  handleSubmit,
  partnerCompanyName,
  partnership,
  resetSection,
}) => {
  const isInternational = isInternationalPartnership(partnership);
  const label = collectVendorTaxInfo ? 'Mailing Address' : 'Registered Address';
  const lockedCountryReason = `If you need to modify your country, please reach out to ${partnerCompanyName}`;
  const isCustomerFlow = isExternalPathV2Pay();

  const handleCompanyTypeChange = () => {
    resetSection(externalCompanyGeneralFields.COMPANY_INFO);
  };

  const handleInvalidCountrySelected = () => {
    resetSection(externalCompanyGeneralFields.COMPANY_MAILING_ADDRESS);
    showInvalidCountryToast(partnership?.name);
  };

  const handleToggleInputForTin = () =>
    change(externalCompanyGeneralFields.FORM_UI_DISPLAY_INPUT_FOR_TIN, !displayInputForTin);

  const handleToggleSSNEIN = () => change(externalCompanyGeneralFields.FORM_UI_DISPLAY_INPUT_FOR_TIN, true);

  return (
    <Form className="partnership-company-form" id={formName} onSubmit={handleSubmit}>
      <PartnerCompanyType onChange={handleCompanyTypeChange} />
      <FormFieldLabel>{label}</FormFieldLabel>
      <div className="margin-bottom--larger">
        <AddressContainer
          addressInfo={addressInfo}
          formId={formName}
          isInternational={isInternational}
          lockedCountryReason={lockedCountryReason}
          onInvalidCountry={handleInvalidCountrySelected}
          partnership={partnership}
          stateKey="mailingAddress"
          stateKeyPrefix="company"
        />
      </div>
      {!isCustomerFlow && (
        <PartnerCompanyEntityForm
          isInternational={isInternational}
          onToggleInputForTin={handleToggleInputForTin}
          onToggleSSNEIN={handleToggleSSNEIN}
          partnership={partnership}
        />
      )}
    </Form>
  );
};

PartnershipCompanyGeneralForm.propTypes = {
  addressInfo: PropTypes.shape({}),
  change: PropTypes.func.isRequired,
  collectVendorTaxInfo: PropTypes.bool,
  displayInputForTin: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  partnerCompanyName: PropTypes.string.isRequired,
  partnership: PropTypes.shape({
    countryCodeCompany: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  resetSection: PropTypes.func.isRequired,
};

PartnershipCompanyGeneralForm.defaultProps = {
  addressInfo: {},
  collectVendorTaxInfo: true,
  displayInputForTin: undefined,
};

export default PartnershipCompanyGeneralForm;

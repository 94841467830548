import React from 'react';
import { Col } from 'components/layout';
import { Text, WhiteSpace } from 'components/text';
import { typography } from 'constants/styles';
const ContentBlockSimpleTitle = ({ title, titleLight }) => {
    if (!title) {
        return null;
    }
    return (React.createElement(Col, null,
        React.createElement(Text.Regular, { color: typography.TextColor.BLACK, lineHeight: typography.TextLineHeight.EXTRA_EXTRA_EXTRA_LARGE, size: typography.TextSize.LEVEL_600 }, title),
        titleLight && (React.createElement(React.Fragment, null,
            React.createElement(WhiteSpace, null),
            React.createElement(Text.Regular, { color: typography.TextColor.GREY_DARK, lineHeight: typography.TextLineHeight.EXTRA_EXTRA_EXTRA_LARGE, size: typography.TextSize.LEVEL_600 }, titleLight)))));
};
export default ContentBlockSimpleTitle;

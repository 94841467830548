import { createErrorReducer } from 'store/redux';

import {
  SUBMIT_SIGNUP_SELECT_NAMESPACE_FAILURE,
  SUBMIT_SIGNUP_SELECT_NAMESPACE_REQUEST,
  SUBMIT_SIGNUP_SELECT_NAMESPACE_SUCCESS,
} from 'types/signup';

export const failureCases = [SUBMIT_SIGNUP_SELECT_NAMESPACE_FAILURE];
export const successCases = [SUBMIT_SIGNUP_SELECT_NAMESPACE_REQUEST, SUBMIT_SIGNUP_SELECT_NAMESPACE_SUCCESS];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

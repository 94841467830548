import { IconNames } from 'components/icon';
import { PartnershipMemberAccess } from 'constants/partnershipMember';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { colors, typography } from 'constants/styles';
import { RoleTypes } from 'enums/ui';
import { getZIndex } from 'helpers/zIndex';
export const ZIndexLayers = {
    BELOW: -1,
    BASE: 1,
    ABOVE_BASE: 2,
    PICKER: 5,
    STICKY: 10,
    SIDE_NAV: {
        BASE: 20,
        DIALOG: 25,
        TOOLTIP: 26,
    },
    SLIDEOUT: {
        OVERLAY: 110,
        VIEW_CONTENT: 111,
        SIDEBAR: 117,
        PICKER: 118,
        TOOLTIP: 119,
    },
    POPOVER: {
        BASE: 120,
        SELECT: 121,
    },
    MODAL: {
        OVERLAY: 130,
        BASE: 131,
        PICKER: 132,
        TOOLTIP: 133,
        POPOVER: 134,
        SELECT: 135,
        DROPDOWN: 136,
    },
    HELP: 140,
    SLIDEOUT_PRIME: {
        OVERLAY: 141,
        VIEW_CONTENT: 142,
        SIDEBAR: 142,
        PICKER: 143,
        TOOLTIP: 144,
    },
    SELECT: {
        BOTTOM_SHEET: 150,
    },
    CORNER: 160,
    ALERT: 170,
    TOOLTIP: 180,
};
export const AccountStatusIconMap = {
    ACTIVE: IconNames.TICK_CIRCLE,
    ACTIVE_PARTNER: IconNames.CONFIRM,
    ACTIVE_PARTNER_ADDRESS: IconNames.CONFIRM,
    INACTIVE_PARTNER: IconNames.OUTDATED,
    AVAILABLE: IconNames.TICK_CIRCLE,
    DELETED: IconNames.TRASH,
    DISABLED: IconNames.DISABLE,
    PENDING: IconNames.TIME,
};
export const AsyncValidateStatus = {
    LOADING: 'loading',
    OK: 'ok',
    WARNING: 'warning',
    ERROR: 'error',
};
export const MemberNameAddOn = {
    MEMBER_NAME_ME: ' (me)',
    MEMBER_NAME_YOU: ' (you)',
};
export var Intent;
(function (Intent) {
    Intent["DANGER"] = "danger";
    Intent["EXTERNAL"] = "external";
    Intent["INFO"] = "info";
    Intent["LIGHT"] = "light";
    Intent["NEUTRAL"] = "neutral";
    Intent["GHOST"] = "ghost";
    Intent["PRIMARY"] = "primary";
    Intent["SCHEDULE"] = "schedule";
    Intent["STRONG"] = "strong";
    Intent["SUCCESS"] = "success";
    Intent["WARNING"] = "warning";
})(Intent || (Intent = {}));
export const BankAccountViewSubType = {
    DASHBOARD: 'dashboard',
    EXTERNAL: 'external',
};
export const BaseViewTypes = {
    FULL: 'FULL',
    THREAD: 'THREAD',
};
export const BankAccountViewType = {
    ADDRESS: 'ADDRESS',
    [BaseViewTypes.FULL]: BaseViewTypes.FULL,
    NEW_PARTNER: 'NEW_PARTNER',
    PARTNER_ACH: 'PARTNER_ACH',
    PARTNER_ADDRESS: 'PARTNER_ADDRESS',
    SIMPLE: 'SIMPLE',
    SIMPLE_VERIFY: 'SIMPLE_VERIFY',
    [BaseViewTypes.THREAD]: BaseViewTypes.THREAD,
    THREAD_PARTNER_ACH: 'THREAD_PARTNER_ACH',
    THREAD_PARTNER_ADDRESS: 'THREAD_PARTNER_ADDRESS',
};
export const ContactsInteractionListViewType = {
    [BaseViewTypes.FULL]: BaseViewTypes.FULL,
    [BaseViewTypes.THREAD]: BaseViewTypes.THREAD,
};
export const InputTypes = {
    TEXT: 'text',
};
export const GenericDisplayStatus = {
    ACTION_MISSED: 'ACTION_MISSED',
    ACTION_NEEDED: 'ACTION_NEEDED',
    ARCHIVED: 'ARCHIVED',
    AVAILABLE: 'AVAILABLE',
    CAUTION: 'CAUTION',
    COMPLETE: 'COMPLETE',
    DANGER: 'DANGER',
    DEFAULT: 'DEFAULT',
    ERROR: 'ERROR',
    NEUTRAL: 'NEUTRAL',
    NOT_STARTED: 'NOT_STARTED',
    POST_DANGER: 'POST_DANGER',
    POST_STARTED: 'POST_STARTED',
    READY: 'READY',
    STARTED: 'STARTED',
    SUCCESS: 'SUCCESS',
    TEMPORAL: 'TEMPORAL',
    QUEUED: 'QUEUED',
    WARN: 'WARN',
};
export const FlairTagType = {
    PRIMARY: 'PRIMARY',
    PREFERRED: 'PREFERRED',
    PRIMARY_AND_PREFERRED: 'PRIMARY_AND_PREFERRED',
    MANAGE_ITEMS: 'MANAGE_ITEMS',
    MANAGE_COMPANY: 'MANAGE_COMPANY',
    SELF_MANAGED: 'SELF_MANAGED',
};
export const RoleTypeIcon = {
    [RoleTypes.ADMINISTRATOR]: IconNames.CROWN,
    [RoleTypes.APPROVER]: IconNames.TAKE_ACTION,
    [RoleTypes.COLLABORATOR]: IconNames.MANUAL,
    [RoleTypes.CREATOR]: IconNames.EDIT,
    [RoleTypes.DEVELOPER]: IconNames.CODE,
    [RoleTypes.IT_ADMIN]: IconNames.APPLICATION,
    [RoleTypes.VENDOR_AND_CUSTOMER_MANAGER]: IconNames.PEOPLE,
};
export const RoleTypeIconColor = {
    [RoleTypes.ADMINISTRATOR]: colors.colorGreenBoldHex,
    [RoleTypes.APPROVER]: colors.colorPurpleBoldHex,
    [RoleTypes.COLLABORATOR]: colors.colorGreyXDarkHex,
    [RoleTypes.CREATOR]: colors.colorBlueBoldHex,
    [RoleTypes.DEVELOPER]: colors.colorYellowDark,
    [RoleTypes.IT_ADMIN]: colors.colorGreyXDarkHex,
    [RoleTypes.VENDOR_AND_CUSTOMER_MANAGER]: colors.colorRedDarkHex,
};
export const RoleTagType = {
    [RoleTypes.ADMINISTRATOR]: RoleTypes.ADMINISTRATOR,
    [RoleTypes.CREATOR]: RoleTypes.CREATOR,
    [RoleTypes.APPROVER]: RoleTypes.APPROVER,
    [RoleTypes.COLLABORATOR]: RoleTypes.COLLABORATOR,
    [RoleTypes.DEVELOPER]: RoleTypes.DEVELOPER,
    [RoleTypes.IT_ADMIN]: RoleTypes.IT_ADMIN,
    [RoleTypes.VENDOR_AND_CUSTOMER_MANAGER]: RoleTypes.VENDOR_AND_CUSTOMER_MANAGER,
};
export const TagType = {
    ARCHIVED: 'ARCHIVED',
    BOUNCED_EMAIL: 'BOUNCED_EMAIL',
    DEFAULT: 'DEFAULT',
    ERROR: 'ERROR',
    INFO: 'INFO',
    LOCKED: 'LOCKED',
    META: 'META',
    MISC: 'MISC',
    MULTISELECT: 'MULTISELECT',
    NOTE: 'NOTE',
    NO_EMAIL: 'NO_EMAIL',
    SUCCESS: 'SUCCESS',
    VARIABLE: 'VARIABLE',
    WARNING: 'WARNING',
    ...FlairTagType,
    ...RoleTagType,
};
export const TagShape = {
    RECT: 'RECT',
    ROUND: 'ROUND',
    SQUARE: 'SQUARE',
};
export const FlairTagShape = {
    [FlairTagType.PRIMARY]: TagShape.ROUND,
    [FlairTagType.PREFERRED]: TagShape.ROUND,
    [FlairTagType.PRIMARY_AND_PREFERRED]: TagShape.ROUND,
    [FlairTagType.MANAGE_ITEMS]: TagShape.RECT,
    [FlairTagType.MANAGE_COMPANY]: TagShape.RECT,
    [FlairTagType.SELF_MANAGED]: TagShape.RECT,
};
export const FlairTagTypeIcon = {
    [FlairTagType.PRIMARY]: IconNames.STAR,
    [FlairTagType.PREFERRED]: IconNames.HEART,
    [FlairTagType.PRIMARY_AND_PREFERRED]: IconNames.STAR,
    [FlairTagType.MANAGE_ITEMS]: IconNames.UPLOAD,
    [FlairTagType.MANAGE_COMPANY]: IconNames.BRIEFCASE,
    [FlairTagType.SELF_MANAGED]: IconNames.LOCK,
};
export const TagTypeIcon = {
    [TagType.ARCHIVED]: IconNames.FOLDER_CLOSE,
    [TagType.DEFAULT]: IconNames.TICK_CIRCLE,
    [TagType.ERROR]: IconNames.ERROR,
    [TagType.INFO]: IconNames.INFO_SIGN,
    [TagType.LOCKED]: IconNames.LOCK,
    [TagType.MISC]: IconNames.NOTIFICATIONS,
    [TagType.META]: IconNames.FOLDER_CLOSE,
    [TagType.NOTE]: IconNames.EDIT,
    [TagType.MULTISELECT]: IconNames.FOLDER_CLOSE,
    [TagType.SUCCESS]: IconNames.CONFIRM,
    [TagType.VARIABLE]: IconNames.ARROWS_HORIZONTAL,
    [TagType.WARNING]: IconNames.WARNING_SIGN,
    ...FlairTagTypeIcon,
    ...RoleTypeIcon,
};
export const TagText = {
    [TagType.ARCHIVED]: 'Archived',
    [TagType.BOUNCED_EMAIL]: 'Bounced email',
    [TagType.NO_EMAIL]: 'No email',
    [TagType.SELF_MANAGED]: 'Self-managed',
};
export const SuccessIndicatorMessages = {
    ADD_CONTACT: 'Successfully added contact',
    ADD_CUSTOMER: 'Customer created!',
    ADD_TAG: 'Success!',
    ADD_VENDOR: 'Vendor created!',
    APPROVAL_SETTINGS_UPDATED: 'Approval settings have been saved!',
    BANK_ACCOUNT_ADDED: 'Bank account added',
    BRAND: 'Business branding info saved!',
    GENERAL_INFO_UPDATED: 'General info updated!',
    GLOBAL_CC_NOTIFICATION_UPDATE_SUCCESS: '"Global CC" notification setting has been updated.',
    INFO: 'Account info saved!',
    INTERNATIONAL_PAYMENTS_ENABLED: "You've successfully enabled international payments.",
    LEGAL_BUSINESS: 'Business verification info saved!',
    LEGAL_NAME_SETTING_SUCCESS: '"Legal name changes" sync setting updated',
    MEMBERSHIP_INVITE_SUCCESS: 'Invite sent!',
    MEMBERSHIP_UPDATE_SUCCESS: 'Update to team member saved',
    PASSWORD_CHANGE_SUCCESS: 'New password saved!',
    PASSWORD_RESET_SUCCESS: 'Your password was successfully updated!',
    PAYABLE_APPROVALS_BYPASSED: 'You successfully bypassed approval rules',
    PAYABLE_APPROVED: 'You successfully approved',
    PAYABLE_CONFIGURATIONS: 'Payable configurations saved!',
    PAYMENT_REFERENCE_NUMBER_SETTING_SUCCESS: '"Payment reference number" sync setting updated',
    PROFILE_NAME_CHANGES_UPDATED: 'Profile name editing setting updated',
    TAX_INFO: 'Tax information has been requested',
    TAX_INFO_BULK_REQUEST_SENT: `We're sending your requests now. Come back later and refresh this page to review updated statuses.`,
    TAX_INFO_SUBMITTED: 'Tax information has been submitted',
    TAX_NEVER_FILING: 'Marked as "Never Filing"',
    UPDATE_CONTACT: 'Successfully updated contact',
    USER_PROFILE: 'Profile settings saved!',
    VENDOR_ADDRESS_ADDED: 'Vendor registered address added successfully!',
    VENDOR_ADDRESS_UPDATED: 'Vendor registered address updated successfully!',
    W9_TAX_DOCUMENT_DOWNLOADED: 'W-9 has been downloaded',
    W_8BEN_TAX_DOCUMENT_DOWNLOADED: 'W-8BEN has been downloaded',
    W_8BEN_E_TAX_DOCUMENT_DOWNLOADED: 'W-8BEN-E has been downloaded',
};
export const ErrorIndicatorMessages = {
    ADD_CONTACT: 'Error adding contact',
    AN_ERROR_OCCURRED: 'An error occurred',
    DOWNLOAD_TEMPLATE: 'Error downloading template',
    UPDATE_CONTACT: 'Error updating contact',
    RE_INVITE_CONTACT: 'Error re-sending invitation',
    REVOKE_INVITE_CONTACT: 'Error revoking invitation',
    UPDATE_MEMBERSHIP: 'Error updating team member',
};
export const toastDurationSeconds = {
    INFINITE: 20 * 60,
};
export const CompanyTypeSettings = {
    HORIZONTAL_MINIMUM_WIDTH: 575,
    ATTACHMENTS_TOOLBAR_HORIZONTAL_MINIMUM_WIDTH: 585,
};
export const PartnershipMemberAccessIcon = {
    [PartnershipMemberAccess.ACTIONABLE]: IconNames.EYE_ON,
    [PartnershipMemberAccess.READ_ONLY]: IconNames.EYE_OPEN,
    [PartnershipMemberAccess.HAS_DEFAULTS]: IconNames.GIT_MERGE,
    [PartnershipMemberAccess.NO_DEFAULTS]: IconNames.DISABLE,
    [PartnershipMemberAccess.NONE]: IconNames.EYE_OFF,
};
export const ItemMemberTagAccessTooltipText = {
    [PartnershipMemberAccess.ACTIONABLE]: 'Will be added with full access',
    [PartnershipMemberAccess.READ_ONLY]: 'Will be added as read-only',
};
export const SelectPlaceholderText = {
    SEARCH_OR_ADD_NEW_CONTACT: 'Search contacts or enter a new contact name...',
    SEARCH_FOR_CONTACTS: 'Search contacts...',
};
export const SelectMenuPlacement = {
    AUTO: 'auto',
    BOTTOM: 'bottom',
    TOP: 'top',
};
export const AddedContactsSummaryTitleTextProps = {
    color: typography.TextColor.GREY_XX_DARK,
    letterSpacing: typography.LetterSpacing.EXTRA_EXTRA_SMALL,
    size: typography.TextSize.LEVEL_300,
};
export const LedgerConnectLabel = {
    RECONNECT: 'Reconnect',
    CONNECT_YOUR_LEDGER: 'Connect your ledger',
};
export const PopperPlacements = {
    BOTTOM: 'bottom',
    BOTTOM_END: 'bottom-end',
    BOTTOM_START: 'bottom-start',
    LEFT: 'left',
    LEFT_END: 'left-end',
    LEFT_START: 'left-start',
    RIGHT: 'right',
    RIGHT_END: 'right-end',
    RIGHT_START: 'right-start',
    TOP: 'top',
    TOP_END: 'top-end',
    TOP_START: 'top-start',
};
export const FieldDebounceDelay = {
    DEFAULT: 225,
    SELECT: 5,
    TEXTAREA: 300,
};
export const sideBarPanelStyleOverrides = {
    overlay: {
        zIndex: getZIndex(ZIndexLayers.SLIDEOUT_PRIME.OVERLAY),
    },
    sidebar: {
        zIndex: getZIndex(ZIndexLayers.SLIDEOUT_PRIME.SIDEBAR),
    },
};
export const ExternalButtonTypes = {
    NEXT: 'next',
    SUBMIT: 'submit',
};
export const ExternalButtonTypeValues = {
    [ExternalButtonTypes.NEXT]: 'button',
    [ExternalButtonTypes.SUBMIT]: 'submit',
};
export const ExternalButtonStateTypes = {
    INITIAL: 'initial',
};
export const CommonExternalComponentTypes = {
    CancelledStepHint: 'CancelledStepHint',
    CompletedStepHint: 'CompletedStepHint',
    IncompleteStepHint: 'IncompleteStepHint',
    ProcessingStepHint: 'ProcessingStepHint',
};
export const UpdatePaymentComponentTypes = {
    AlreadyCompletedSideCard: 'AlreadyCompletedSideCard',
    CurrentPaymentMethod: 'CurrentPaymentMethod',
    MicroDepositsNeedVerificationWithoutConnectHint: 'MicroDepositsNeedVerificationWithoutConnectHint',
    MicroDepositsNotReadyHint: 'MicroDepositsNotReadyHint',
    MicroDepositsReadyHint: 'MicroDepositsReadyHint',
    NoLongerNeeded: 'NoLongerNeeded',
    PartnerCompanyContactForm: 'PartnerCompanyContactForm',
    PartnerCompanyGeneralForm: 'PartnerCompanyGeneralForm',
    PaymentMethodSameHint: 'PaymentMethodSameHint',
    PendingIllustrationSvg: 'PendingIllustrationSvg',
    PendingPaymentMethod: 'PendingPaymentMethod',
    PendingPaymentsToAcceptList: 'PendingPaymentsToAcceptList',
    RecentlyCompletedHint: 'RecentlyCompletedHint',
    SuccessIllustrationSvg: 'SuccessIllustrationSvg',
    UnauthorizedSvg: 'UnauthorizedSvg',
    UpdatedPaymentMethod: 'UpdatedPaymentMethod',
    UpdatePaymentMethodFlowForm: 'UpdatePaymentMethodFlowForm',
    UpdatePaymentMethodFormWithCheckboxAndHints: 'UpdatePaymentMethodFormWithCheckboxAndHints',
};
export const AcceptPartnershipComponentTypes = {
    PartnerCompanyContactForm: 'PartnerCompanyContactForm',
    PartnerCompanyGeneralForm: 'PartnerCompanyGeneralForm',
    TermsOfServiceHint: 'TermsOfServiceHint',
    PendingRegisterIllustrationSvg: 'PendingRegisterIllustrationSvg',
    RegisterIllustrationSvg: 'RegisterIllustrationSvg',
    SubstituteW9Form: 'SubstituteW9Form',
    SubstituteW8Form: 'SubstituteW8Form',
    SuccessRegisterIllustrationSvg: 'SuccessRegisterIllustrationSvg',
};
export const AcceptItemComponentTypes = {
    ExternalCurrencyTypeAnnouncementHint: 'ExternalCurrencyTypeAnnouncementHint',
    ExternalItemsList: 'ExternalItemList',
    ExternalPaymentsList: 'ExternalPaymentsList',
    ExternalReceiptPayment: 'ExternalReceiptPayment',
    ExternalReceiptSingleItem: 'ExternalReceiptSingleItem',
    FailedPaymentStepHint: 'PaymentFailedHint',
    ItemPaymentMethod: 'ItemPaymentMethod',
};
export const ExternalButtonStates = {
    ERROR_UPDATING: 'errorUpdating',
    INITIAL: 'initial',
    INVALID: 'invalid',
    MAX_ACCOUNTS: 'maxAccounts',
    MICRO_DEPOSITS_NOT_READY: 'microDepositsNotReady',
    MICRO_DEPOSITS_READY: 'microDepositsReady',
    VALID: 'valid',
};
export const ExternalProgressBarDisplayTypes = {
    HIDE: 'hide',
    SHOW: 'show',
};
export const InviteTeamMemberEmailTooltipText = 'This email is already associated with someone who has been invited to Routable, so you cannot invite them again.';
export const DeactivateTeamMemberModalType = {
    BUSINESS_REPRESENTATIVE: 'business representative',
    TEAM_MEMBER: 'team member',
    YOURSELF: 'yourself',
};
export const deactivateTeamMemberModalSubtitle = `Deactivating this account will immediately remove their access to ${PLATFORM_DISPLAY_SHORT_NAME}.`;
export const ExternalErrorType = {
    NOT_ALLOWED: 'not-allowed',
    NOT_NEEDED: 'not-needed',
};
export const ExternalErrorTitles = {
    [ExternalErrorType.NOT_ALLOWED]: "You can't perform this action",
    [ExternalErrorType.NOT_NEEDED]: 'You are no longer needed to update your payment method',
};
export const ExternalUpdatePaymentFlowState = {
    CONFIRMATION: 'confirmation',
    PENDING: 'pending',
};
export const ExternalAcceptPartnershipFlowState = {
    INCOMPLETE: 'incomplete',
    INITIAL: 'initial',
    SUCCESS: 'success',
};
export const ExternalStepDisplayStatus = {
    DISABLED: 'disabled',
    ENABLED: 'enabled',
};
export const DetailsToggleLabel = {
    SHOW: 'Show details',
    HIDE: 'Hide details',
};

import { getShowSuccessUI } from 'helpers/ui';

import { text } from 'modules/fundingAccount/partnerPaymentMethod/constants';
import { componentHelpers } from 'modules/fundingAccount/partnerPaymentMethod/helpers';

const showUpdatePrimaryReceivableSuccessUI = getShowSuccessUI(text.primaryAccountUpdatedMessage);

/**
 * Creates a disptch function that will emit multiple actions to mark a partnership as primary.
 */
export const createOnChangeToPrimary =
  ({
    partnership,
    partnershipFundingAccount,
    onClearSendPartnershipUpdatePaymentMethodUrlStatus,
    onFetchPartnershipReceivableFundingAccounts,
    onSubmitPartnershipPrimaryReceivableFundingAccount,
    onUpdatePartnershipPrimaryReceivableFundingAccount,
  }) =>
  () => {
    const payload = componentHelpers.getChangeToPrimaryFundingAccountPayload(partnershipFundingAccount);
    const successCallback = (response) => {
      onUpdatePartnershipPrimaryReceivableFundingAccount(partnershipFundingAccount.id);
      onFetchPartnershipReceivableFundingAccounts(partnership.id);
      onClearSendPartnershipUpdatePaymentMethodUrlStatus(partnership.id);
      showUpdatePrimaryReceivableSuccessUI();

      return response;
    };
    const options = { successCallback };

    onSubmitPartnershipPrimaryReceivableFundingAccount(payload, partnership.id, partnershipFundingAccount.id, options);
  };

import { useQueryPagination } from '@routable/framework';
import { useQuery } from '@tanstack/react-query';
import { queryContext } from '@routable/shared';
import { useState } from 'react';
import { generateKeys } from '../helpers';
import { taxToolsFiltersModel } from '../models';
import { taxToolsApi } from '../services';
export const useTaxTools = ({ params, type = 'all', enabled = true }) => {
    const [pagination, setPagination] = useState({
        page: params?.page ?? 1,
        pageSize: params?.pageSize ?? 25,
    });
    const { page, pageSize: limit } = pagination;
    const offset = (page - 1) * limit;
    const sort = params?.sort ?? '';
    const parsedInitialFilters = taxToolsFiltersModel.safeParse(params?.filters || {});
    const filters = parsedInitialFilters.success ? parsedInitialFilters.data : {};
    const queryKey = generateKeys.list(type, filters, sort, page, limit);
    const results = useQuery({
        context: queryContext,
        queryKey,
        enabled,
        queryFn: async () => taxToolsApi.readAll({ filters, sort, limit, offset }),
    });
    const paginationData = useQueryPagination({
        page,
        pageSize: limit,
        setPagination,
        totalRecords: results.data?.pagination.total,
    }, [`taxTools-${type}-pagination`, ...Object.values(filters)]);
    return {
        ...results,
        data: results.data?.data,
        ...paginationData,
    };
};

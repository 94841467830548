import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { ReduxFormFieldRenderNumericInput } from 'components';

import { formatNumberForFlexibleDecimalScale } from 'helpers/fieldFormatters';
import { numberOnly } from 'helpers/fieldNormalizers';
import { numberGreaterThanZeroValidator, requiredValidator } from 'helpers/fieldValidation';

const AmountField = ({ decimalScale, fixedDecimalScale, minDecimalScale, name }) => (
  <Field
    className="form-top-label hidden-label w-discount-field"
    component={ReduxFormFieldRenderNumericInput}
    decimalScale={decimalScale}
    fixedDecimalScale={fixedDecimalScale}
    format={(value) =>
      formatNumberForFlexibleDecimalScale({
        decimalScale,
        minDecimalScale,
        name,
        value,
      })
    }
    isDebounced
    min="0.01"
    name={name}
    normalize={numberOnly}
    type="text"
    validate={[requiredValidator, numberGreaterThanZeroValidator]}
  />
);

AmountField.propTypes = {
  decimalScale: PropTypes.number.isRequired,
  fixedDecimalScale: PropTypes.bool,
  minDecimalScale: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

AmountField.defaultProps = {
  fixedDecimalScale: undefined,
};

export default AmountField;

import {} from '@routable/fund-routes';
import _intersectionWith from 'lodash/intersectionWith';
import { allKeys, anyValues, hasZeroLength, isEqual } from 'helpers/utility';
import {} from 'interfaces/global';
export const shouldDisplayErrorHint = (errors, fields) => {
    const hasNoFieldErrors = hasZeroLength(_intersectionWith(allKeys(errors), fields, (errorKey, fieldDescription) => isEqual(errorKey, fieldDescription?.field)));
    if (!hasNoFieldErrors) {
        return false;
    }
    return anyValues(errors);
};
export const shouldLookupBankAccount = (fieldName, isInternational) => {
    if (!isInternational) {
        return false;
    }
    const bankFieldsToBeValidated = {
        aba: true,
        bic_swift: true,
        bsb_code: true,
        cnaps: true,
        iban: true,
        ifsc: true,
        routing_number: true,
        sort_code: true,
    };
    return bankFieldsToBeValidated[fieldName] || false;
};

import { getQueryParamsFromUrl, getQueryString } from 'helpers/queryParams';
export const getQueryStringFromSortParam = (urlQuery, sortParam) => {
    const queryPart = urlQuery.replace(/^\?+(.+)/, '$1');
    const params = getQueryParamsFromUrl(queryPart);
    return getQueryString({ ...params, sort: sortParam });
};
export const sortParamToUrl = (sortParam, window = global.window) => {
    const lastPathSection = window.location.pathname.split('/').pop();
    const hashPart = window.location.hash;
    const newQuery = getQueryStringFromSortParam(window.location.search, sortParam);
    const urlUpdate = `${lastPathSection}?${newQuery}${hashPart}`;
    window.history.replaceState(null, '', urlUpdate);
};

import PropTypes from 'prop-types';
import React from 'react';

import { IconNames } from 'components/icon';
import { ModalFooter, ModalFooterButton, ModalFooterButtonAllDone, ModalFooterButtonCancel } from 'components/modal';

import { colors, sizes } from 'constants/styles';

const Footer = ({ formId, isSubmitting, lastSubmitted, onCloseModal }) => {
  if (lastSubmitted) {
    return (
      <ModalFooter>
        <ModalFooterButtonAllDone onCloseModal={onCloseModal} />
      </ModalFooter>
    );
  }

  return (
    <ModalFooter>
      <div className="content--left">
        <ModalFooterButtonCancel onCloseModal={onCloseModal} />
      </div>

      <div className="content--right">
        <ModalFooterButton
          aria-label="Save address"
          htmlFor={formId}
          isActionButton
          isDisabled={isSubmitting}
          isFetching={isSubmitting}
          leftIconClassName="margin-right--m"
          leftIconColor={colors.colorWhiteHex}
          leftIconName={IconNames.TICK_CIRCLE}
          leftIconSize={sizes.iconSizes.EXTRA_MEDIUM}
          type="submit"
        >
          Save address
        </ModalFooterButton>
      </div>
    </ModalFooter>
  );
};

Footer.propTypes = {
  formId: PropTypes.string,
  isSubmitting: PropTypes.bool,
  lastSubmitted: PropTypes.instanceOf(Date),
  onCloseModal: PropTypes.func.isRequired,
};

Footer.defaultProps = {
  formId: undefined,
  isSubmitting: undefined,
  lastSubmitted: undefined,
};

export default Footer;

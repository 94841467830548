import { useMutation } from '@tanstack/react-query';
import { queryContext } from '@routable/shared';
import { internationalBeneficiaryService } from '../../services';
import {} from '../../models';
export const useValidateInternationalBeneficiary = (params) => {
    return useMutation({
        context: queryContext,
        mutationFn: () => internationalBeneficiaryService.validateBeneficiary(params),
    });
};

import React from 'react';

import { getQueryParam } from 'helpers/queryParams';
import { isValueEmpty } from 'helpers/utility';

/**
 * Used in useLoadDataOnInit hook to dispatch fetch actions for ExternalCollectTaxInfoContainer. This code could
 * live inside the hook, but it more easily tested like this, a standalone function. Returns nothing, only dispatches
 * requests for information.
 * @param {Company} currentCompany
 * @param {function} fetchCurrentCompanyCb - memoized dispatch-bound action
 * @param {function} fetchMembershipCb - memoized dispatch-bound action
 * @param {function} fetchPartnershipCb - memoized dispatch-bound action
 * @param {function} fetchTaxInfoCb - memoized dispatch-bound action
 * @param {boolean} isFetchingRequirements
 * @param {object} membership
 * @param {Partnership} partnership
 * @param {object} taxInfo
 */
export const fetchIfNeeded = ({
  currentCompany,
  fetchCurrentCompanyCb,
  fetchMembershipCb,
  fetchPartnershipCb,
  fetchTaxInfoCb,
  isFetchingRequirements,
  membership,
  partnership,
  taxInfo,
}) => {
  if (isFetchingRequirements) {
    return;
  }

  if (!currentCompany.name) {
    fetchCurrentCompanyCb();
  }

  if (isValueEmpty(membership)) {
    const membershipId = getQueryParam('membership_id');
    fetchMembershipCb(membershipId);
  }

  const partnershipId = getQueryParam('partnership_id');

  if (isValueEmpty(partnership)) {
    fetchPartnershipCb(partnershipId);
  }

  if (isValueEmpty(taxInfo.meta)) {
    fetchTaxInfoCb(partnershipId);
  }
};

/**
 * Hook used in ExternalCollectTaxInfoContainer. Initializes company, membership, partnership, and tax information
 * from the URL params for the tax collection flow to operate.
 * @param {Company} currentCompany
 * @param {boolean} isFetchingRequirements
 * @param {function} onFetchCurrentCompany - dispatch-bound action
 * @param {function} onFetchMembership - dispatch-bound action
 * @param {function} onFetchPartnership - dispatch-bound action
 * @param {function} onFetchTaxInfo - dispatch-bound action
 * @param {object} membership
 * @param {Partnership} partnership
 * @param {object} taxInfo
 */
export const useLoadDataOnInit = ({
  currentCompany,
  isFetchingRequirements,
  onFetchCurrentCompany,
  onFetchMembership,
  onFetchPartnership,
  onFetchTaxInfo,
  membership,
  partnership,
  taxInfo,
}) => {
  const fetchCurrentCompanyCb = React.useCallback(onFetchCurrentCompany, []);
  const fetchMembershipCb = React.useCallback(onFetchMembership, []);
  const fetchPartnershipCb = React.useCallback(onFetchPartnership, []);
  const fetchTaxInfoCb = React.useCallback(onFetchTaxInfo, []);

  React.useEffect(() => {
    fetchIfNeeded({
      currentCompany,
      fetchCurrentCompanyCb,
      fetchMembershipCb,
      fetchPartnershipCb,
      fetchTaxInfoCb,
      isFetchingRequirements,
      membership,
      partnership,
      taxInfo,
    });
  }, [
    currentCompany,
    fetchCurrentCompanyCb,
    fetchMembershipCb,
    fetchPartnershipCb,
    fetchTaxInfoCb,
    isFetchingRequirements,
    membership,
    partnership,
    taxInfo,
  ]);
};

import { fetchPermissionGroupsRoutine } from 'actions/routines/permissionGroups';

import { initialState } from './initialState';

const dashboardLoadedPermissionGroupsReducer = (state = initialState.permissionGroups, action) => {
  switch (action.type) {
    case fetchPermissionGroupsRoutine.SUCCESS:
      return true;

    default:
      return state;
  }
};

export default dashboardLoadedPermissionGroupsReducer;

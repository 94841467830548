import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { FormFieldErrors } from 'components';

import { formNamesFunding } from 'constants/forms';
import { PartnershipTypes } from 'constants/partnership';

import { PartnershipCountryCodeKey } from 'enums/partnerships';

import { isInternationalPartnership } from 'helpers/partnerships';
import { isPaymentDeliveryMethodInternational } from 'helpers/paymentMethods';
import { callIfIsFn } from 'helpers/utility';

import PartnerPaymentMethodStatus from 'modules/fundingAccount/partnerPaymentMethodStatus';

import { PartnerPaymentMethodAccounts, PartnerPaymentMethodLoading } from './components';
import {
  getPartnerPaymentMethodFilteredChildren,
  getPartnerVisibleAccounts,
  hasVisiblePartnerAccounts,
  isPartnerAccountsListExpanded,
  shouldDisplayShowAllPartnerAccounts,
} from './helpers/component';

/**
 * Renders partner payment method status and accounts
 * @param {ComponentProps} props
 * @param {number} props.accountsDisplayMax
 * @param {React.ReactChildren} props.children
 * @param {StringMaybe} props.className
 * @param {boolean} props.forceIsEnabled
 * @param {boolean} props.isFetching
 * @param {boolean} props.isInSideSheet
 * @param {boolean} props.isBillView
 * @param {function} props.onOpenAddPartnerFundingAccountModal
 * @param {function} props.onOpenAddPartnerFundingAccountSidePanel
 * @param {FunctionMaybe} props.onShowAll
 * @param {Partnership} props.partnership
 * @param {string} props.partnershipType
 * @param {string} props.paymentDeliveryMethod
 * @return {FunctionComponent}
 */
const PartnerPaymentMethod = ({
  accountsDisplayMax: initialAccountsDisplayMax,
  children,
  className,
  forceIsEnabled,
  hideStatusIcon,
  isFetching,
  isInSideSheet,
  isBillView,
  isInternationalPaymentsEnabled,
  meta,
  onOpenAddPartnerFundingAccountModal,
  onOpenAddInternationalFundingAccountModal,
  onOpenAddPartnerFundingAccountSidePanel,
  onShowAll,
  partnership,
  partnershipType,
  paymentDeliveryMethod,
}) => {
  const [accountsDisplayMax, setAccountsDisplayMax] = React.useState(initialAccountsDisplayMax);
  const errors = meta?.submitFailed && meta?.error;
  if (!partnership) {
    return null;
  }

  const handleShowAll = () => {
    setAccountsDisplayMax(-1);
    callIfIsFn(onShowAll);
  };

  const handleStatusButtonClick = () => {
    const isInternationalVendor = isInternationalPartnership(partnership, PartnershipCountryCodeKey.PARTNER);

    const isInternationalPayment = isPaymentDeliveryMethodInternational(paymentDeliveryMethod);

    const addPaymentModal = isInternationalPayment
      ? onOpenAddInternationalFundingAccountModal
      : onOpenAddPartnerFundingAccountModal;

    const openAction = isInSideSheet ? addPaymentModal : onOpenAddPartnerFundingAccountSidePanel;

    const formContext = isInternationalVendor
      ? formNamesFunding.ADD_PARTNER_INTERNATIONAL_FUNDING_ACCOUNT
      : formNamesFunding.ADD_PARTNER_FUNDING_ACCOUNT;

    openAction({
      // formContext is passed to the side-sheet/modal state with add partner funding
      // account since it contains it's own, encapsulated form. On createItem submit
      // those values are retrieved as needed.
      formContext,
      isBillView,
      partnershipId: partnership.id,
      paymentDeliveryMethod,
    });
  };

  const filteredChildren = getPartnerPaymentMethodFilteredChildren(
    children,
    paymentDeliveryMethod,
    isInternationalPaymentsEnabled,
  );
  const shouldDisplayShowAll = shouldDisplayShowAllPartnerAccounts(accountsDisplayMax, filteredChildren);
  const displayChildren = getPartnerVisibleAccounts(accountsDisplayMax, filteredChildren);
  const hasAccounts = hasVisiblePartnerAccounts(displayChildren);
  const isExpanded = isPartnerAccountsListExpanded(accountsDisplayMax, initialAccountsDisplayMax);

  return (
    <div
      className={classNames({
        expanded: isExpanded,
        'has-accounts': hasAccounts,
        'is-loading': isFetching,
        'partner-payment-method': true,
        [className]: !!className,
      })}
    >
      <PartnerPaymentMethodStatus
        className={classNames({
          'has-error': !!errors,
        })}
        hideStatusIcon={hideStatusIcon}
        isEnabled={forceIsEnabled || hasAccounts}
        onButtonClick={handleStatusButtonClick}
        partnership={partnership}
        partnershipType={partnershipType}
        paymentDeliveryMethod={paymentDeliveryMethod}
      />

      <PartnerPaymentMethodLoading isFetching={isFetching} />

      <PartnerPaymentMethodAccounts
        hasAccounts={hasAccounts}
        isFetching={isFetching}
        onShowAll={handleShowAll}
        shouldDisplayShowAll={shouldDisplayShowAll}
      >
        {displayChildren}
      </PartnerPaymentMethodAccounts>
      <FormFieldErrors errors={errors} fieldName="" />
    </div>
  );
};

PartnerPaymentMethod.propTypes = {
  accountsDisplayMax: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  forceIsEnabled: PropTypes.bool,
  hideStatusIcon: PropTypes.bool,
  isFetching: PropTypes.bool,
  isInSideSheet: PropTypes.bool,
  isBillView: PropTypes.bool,
  meta: PropTypes.shape(),
  onOpenAddPartnerFundingAccountModal: PropTypes.func.isRequired,
  onOpenAddInternationalFundingAccountModal: PropTypes.func.isRequired,
  onOpenAddPartnerFundingAccountSidePanel: PropTypes.func.isRequired,
  onShowAll: PropTypes.func,
  partnership: PropTypes.shape(),
  partnershipType: PropTypes.string,
  paymentDeliveryMethod: PropTypes.string,
};

PartnerPaymentMethod.defaultProps = {
  accountsDisplayMax: -1,
  children: undefined,
  className: undefined,
  forceIsEnabled: undefined,
  hideStatusIcon: undefined,
  isBillView: undefined,
  isFetching: undefined,
  isInSideSheet: undefined,
  meta: {},
  onShowAll: undefined,
  partnership: undefined,
  partnershipType: PartnershipTypes.VENDOR,
  paymentDeliveryMethod: undefined,
};

export default PartnerPaymentMethod;

import { apiCall, convertToSnakeCase } from '@routable/framework';
import { z } from 'zod';
import { prefilledItemSchema, purchaseOrdersByBillSchema, purchaseOrderSchema, singlePurchaseOrderModel, } from './purchaseOrder.models';
import { DiscrepanciesApiResponseModel, MatchResultModel } from './useBillDiscrepancies/discrepancies.models';
export const purchaseOrderService = {
    calculateDiscrepancies: async (data) => apiCall({
        url: `po_matching/discrepancies/items/`,
        method: 'post',
        responseModel: DiscrepanciesApiResponseModel,
        body: await convertToSnakeCase(data),
    }),
    get: ({ id }) => apiCall({
        camelCaseResponse: true,
        url: `integrations/purchase_orders/${id}/`,
        method: 'get',
        responseModel: singlePurchaseOrderModel,
    }),
    getAll: ({ ledgerRefs, offset, search, vendors, }) => apiCall({
        url: 'integrations/purchase_orders/',
        method: 'get',
        camelCaseResponse: true,
        queryParams: {
            ...(ledgerRefs && { ledgerRefs }),
            ...(offset && { offset }),
            ...(search && { purchase_order_numbers__icontains: search }),
            ...(vendors && { vendors }),
        },
        responseModel: purchaseOrderSchema,
    }),
    getByBill: ({ ledgerRefs }) => apiCall({
        camelCaseResponse: true,
        url: 'integrations/purchase_orders/by-bill/',
        method: 'get',
        responseModel: purchaseOrdersByBillSchema,
        queryParams: {
            ledger_refs: ledgerRefs.join(),
        },
    }),
    getDiscrepancies: async ({ id }) => apiCall({
        url: `po_matching/discrepancies/items/${id}/`,
        method: 'get',
        responseModel: MatchResultModel,
    }),
    approveDiscrepancies: ({ id }) => apiCall({
        url: `po_matching/discrepancies/items/${id}/approve/`,
        method: 'post',
        responseModel: z.never(),
    }),
    getPrefilled: ({ id, excludeBillLedgerRef }) => apiCall({
        url: `items/prefilled/purchase_order/${id}/`,
        method: 'get',
        responseModel: prefilledItemSchema,
        queryParams: {
            ...(excludeBillLedgerRef && { excludeBillLedgerRef }),
        },
    }),
    refreshDiscrepancies: async ({ id }) => apiCall({
        url: `po_matching/discrepancies/refresh/`,
        method: 'post',
        responseModel: z.never(),
        body: { item_id: id },
    }),
};

import { lookupMembershipInviteEmailRoutine } from 'actions/routines/inviteTeamMember';

import { createEmailAsyncValidateFormReducer } from 'store/redux';

export const successCases = [lookupMembershipInviteEmailRoutine.SUCCESS];
export const failureCases = [lookupMembershipInviteEmailRoutine.FAILURE];

/**
 * Keep track of warnings related to the email address validation when inviting a new team member.
 * @param {Object} state
 * @param {ReduxAction} action
 * @returns {Object}
 */
const inviteTeamMemberFormReducer = createEmailAsyncValidateFormReducer(successCases, failureCases);

export default inviteTeamMemberFormReducer;

import { z } from 'zod';
export const operatorModel = z.enum([
    'IS_CONTAINS_IN_CSL',
    'IS_EXACT_IN_CSL',
    'IS_STARTS_WITH_IN_CSL',
    'IS_ENDS_WITH_IN_CSL',
]);
export const operatorOptions = {
    [operatorModel.Enum.IS_EXACT_IN_CSL]: 'exactly matches',
    [operatorModel.Enum.IS_CONTAINS_IN_CSL]: 'contains',
    [operatorModel.Enum.IS_STARTS_WITH_IN_CSL]: 'starts with',
    [operatorModel.Enum.IS_ENDS_WITH_IN_CSL]: 'ends with',
};
export const operatorSelectOptions = Object.entries(operatorOptions).map(([value, label]) => ({ label, value }));

import styled from 'styled-components';
export const ModuleWrapperContainer = styled.div `
  display: flex;
  flex-direction: row;
  min-height: 100vh;
`;
export const HelperModule = styled.div `
  width: 40%;
  min-height: 100vh;
`;
export const FormModule = styled.div `
  width: 60%;
  min-height: 100vh;
  background-color: white;
  overflow-y: scroll;
`;

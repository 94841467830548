import PropTypes from 'prop-types';
import React from 'react';
import { Column, Table } from 'react-virtualized';

import { CellRenderer, TableBorders } from 'components/table';

import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from 'constants/tables';

import { mapOverKeys } from 'helpers/utility';

/**
 * Renders the transactions table components.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 * @constructor
 */
const RenderBalanceTransactionsTable = (props) => {
  const { height, tableConfig, tableData, width } = props;

  return (
    <TableBorders>
      <Table
        className="balance-table"
        headerClassName="balance-table--header"
        headerHeight={TABLE_HEADER_HEIGHT}
        height={height}
        rowClassName="balance-table--row disable-hover"
        rowCount={tableData.length}
        rowGetter={({ index }) => tableData[index]}
        rowHeight={TABLE_ROW_HEIGHT}
        width={width}
      >
        {mapOverKeys(tableConfig, (column) => (
          <Column
            cellRenderer={CellRenderer}
            className={tableConfig[column].className}
            columnData={tableConfig[column]}
            dataKey={column}
            flexGrow={tableConfig[column].width}
            key={column}
            label={tableConfig[column].displayName}
            width={100}
          />
        ))}
      </Table>
    </TableBorders>
  );
};

RenderBalanceTransactionsTable.propTypes = {
  height: PropTypes.number.isRequired,
  tableConfig: PropTypes.shape().isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  width: PropTypes.number.isRequired,
};

export default RenderBalanceTransactionsTable;

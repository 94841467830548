import clsx from 'clsx';
import React from 'react';
import { Icon } from 'components/icon';
import { ButtonAppearance, ButtonSize } from 'constants/button';
import { EventKeyValues } from 'constants/events';
import { sizes } from 'constants/styles';
import { Intent } from 'constants/ui';
import { noDefaultEvent } from 'helpers/events';
import {} from './ButtonV2.types';
import { FillBar, Spinner } from './components';
import './ButtonV2.scss';
const ButtonV2 = ({ appearance = ButtonAppearance.DEFAULT, 'aria-label': ariaLabel, children, className, dataTestId, htmlFor, id, innerRef, intent = Intent.PRIMARY, isDisabled, isIconButton, isLoading, leftIconColor, leftIconClassName, leftIconName, leftIconSize = sizes.iconSizes.MEDIUM, leftIconProps = {}, onClick, rightIconColor, rightIconClassName, rightIconName, rightIconSize = sizes.iconSizes.MEDIUM, rightIconProps = {}, size = ButtonSize.SMALL, type = 'submit', }) => {
    const onKeyUp = (e) => {
        if (e.key === EventKeyValues.ENTER || e.key === EventKeyValues.SPACE) {
            noDefaultEvent();
        }
    };
    return (React.createElement("button", { "aria-label": ariaLabel, className: clsx('btn-v2', `btn--${appearance}`, `btn--${intent}`, `btn--${size}`, className, {
            [`btn--loading`]: !!isLoading,
            [`btn--icon`]: !!isIconButton,
            'justify-content--space-between': !leftIconName && !!rightIconName,
            'justify-content--center': !leftIconName && !rightIconName,
        }), "data-testid": dataTestId, disabled: isDisabled, form: htmlFor, id: id, onClick: onClick, onKeyUp: onKeyUp, onMouseDown: (e) => {
            noDefaultEvent(e);
        }, ref: innerRef, type: type === 'submit' ? 'submit' : 'button' },
        React.createElement("div", { "aria-hidden": !isLoading, "aria-label": "button loading indicator", className: clsx('btn--overlay', { 'is-loading': !!isLoading }), role: "progressbar" },
            React.createElement(FillBar, { isLoading: isLoading }),
            React.createElement(Spinner, null)),
        leftIconName && (React.createElement(Icon, { className: leftIconClassName, color: leftIconColor, icon: leftIconName, size: leftIconSize, ...leftIconProps })),
        children,
        rightIconName && (React.createElement(Icon, { className: rightIconClassName, color: rightIconColor, icon: rightIconName, size: rightIconSize, ...rightIconProps }))));
};
export default ButtonV2;

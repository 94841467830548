import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import swal from 'sweetalert';

import { ButtonV2 } from 'components/buttonV2';
import { Image } from 'components/media';
import { Text } from 'components/text';

import { ButtonSize, ButtonText } from 'constants/button';
import { sizes } from 'constants/styles';
import { TextColor, TextSize } from 'constants/styles/typography';
import { Intent } from 'constants/ui';

import { getSwalFooterClassname } from 'helpers/swal';
import { anyValues, isFn } from 'helpers/utility';

import '../SwalWrapper.scss';

/**
 * Generic SWAL wrapper component, containing jsx for displaying
 * swal's header, content and footer.
 * @param {ComponentProps} props
 * @param {Object} props.confirmButtonProps
 * @param {string} props.contentClassname
 * @param {string} props.footerClassname
 * @param {boolean} props.hasCloseButton
 * @param {ObjectMaybe} [props.imageProps={}]
 * @param {string} props.intent
 * @param {boolean} [props.isLoading]
 * @param {boolean} props.shouldCloseOnClick
 * @param {StringMaybe | Component} props.subtitle
 * @param {string} props.title
 * @return {StatelessComponent}
 */
const SwalWrapper = ({
  children,
  confirmButtonProps,
  contentClassname,
  footerClassname,
  hasCloseButton,
  imageProps,
  intent,
  isLoading,
  shouldCloseOnClick,
  subtitle,
  title,
}) => {
  const { icon, onClick: onClickProp, text } = confirmButtonProps;

  const footerClassnames = getSwalFooterClassname(intent, footerClassname);

  const onClose = () => {
    swal.close();
  };

  const onClick = () => {
    if (isFn(onClickProp)) {
      onClickProp();
    }

    if (shouldCloseOnClick) {
      onClose();
    }
  };

  return (
    <div className="swal-wrapper">
      <div className="swal-wrapper__heading">
        {anyValues(imageProps) && (
          <div className="swal-wrapper__heading--image">
            <Image {...imageProps} />
          </div>
        )}

        <div className="swal-wrapper__heading--title">
          <Text.Bold color={TextColor.BLUE_DARK} size={TextSize.LEVEL_400}>
            {title}
          </Text.Bold>
        </div>

        {subtitle && (
          <div className="swal-wrapper__heading--subtitle">
            <Text.Regular size={TextSize.LEVEL_200}>{subtitle}</Text.Regular>
          </div>
        )}
      </div>

      <div
        className={classNames('swal-wrapper__content', {
          [contentClassname]: !!contentClassname,
        })}
      >
        {children}
      </div>

      <div
        className={classNames('swal-wrapper__footer', {
          [footerClassnames]: !!footerClassnames,
        })}
      >
        {hasCloseButton && (
          <ButtonV2 className="hover--danger" intent={Intent.NEUTRAL} onClick={onClose} size={ButtonSize.SMALL}>
            {ButtonText.CANCEL}
          </ButtonV2>
        )}

        <ButtonV2
          className={classNames({
            'margin-left--auto': !hasCloseButton,
          })}
          intent={intent}
          isLoading={isLoading}
          leftIconClassName="margin-right--xm"
          leftIconName={icon}
          leftIconSize={sizes.iconSizes.EXTRA_MEDIUM}
          onClick={onClick}
          size={ButtonSize.SMALL}
        >
          {text}
        </ButtonV2>
      </div>
    </div>
  );
};

SwalWrapper.propTypes = {
  children: PropTypes.node,
  confirmButtonProps: PropTypes.shape({
    icon: PropTypes.node,
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
  }).isRequired,
  contentClassname: PropTypes.string,
  footerClassname: PropTypes.string,
  hasCloseButton: PropTypes.bool,
  imageProps: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string,
  }),
  intent: PropTypes.oneOf([Intent.DANGER, Intent.NEUTRAL, Intent.GHOST, Intent.PRIMARY, Intent.SUCCESS]),
  isLoading: PropTypes.bool,
  shouldCloseOnClick: PropTypes.bool,
  subtitle: PropTypes.node,
  title: PropTypes.node.isRequired,
};

SwalWrapper.defaultProps = {
  children: undefined,
  contentClassname: undefined,
  footerClassname: undefined,
  hasCloseButton: true,
  imageProps: {},
  intent: Intent.PRIMARY,
  isLoading: undefined,
  shouldCloseOnClick: true,
  subtitle: undefined,
};

export default SwalWrapper;

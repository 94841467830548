import { z } from 'zod';
export const DiscrepancyDetailsModel = z.object({
    bill_value: z.string().or(z.number()),
    discrepancy: z.object({ value: z.string(), percentage: z.string() }),
    purchase_order_value: z.string().or(z.number()),
});
export const DiscrepancyModel = z.object({
    amount: DiscrepancyDetailsModel.nullish(),
    bill_line_item_id: z.string().uuid(),
    missing_receipt: z.boolean().nullish(),
    purchase_order_line_item_id: z.string().nullish(),
    quantity: DiscrepancyDetailsModel.nullish(),
    rate: DiscrepancyDetailsModel.nullish(),
    type: z.enum(['expense_match', 'missing_po_item', 'value_match']),
});
export const DiscrepancyDataModel = z.array(DiscrepancyModel);
export const MatchResultModel = z.object({
    id: z.string().uuid(),
    is_approved: z.boolean(),
    approved_at: z.date().optional(),
    discrepancies: DiscrepancyDataModel,
});
export const DiscrepanciesApiResponseModel = z.object({
    discrepancies: DiscrepancyDataModel,
});

import { connect } from 'react-redux';

import { createPartnershipFormSelector } from 'selectors/forms';

import SummaryOfContacts from './SummaryOfContacts';

const mapStateToProps = (state) => ({
  partnershipMembers: createPartnershipFormSelector(state, 'meta.partnershipMembers'),
  partnershipSubmitted: createPartnershipFormSelector(state, 'ui.partnershipSubmitted'),
  partnershipType: createPartnershipFormSelector(state, 'partnershipType'),
});

export default connect(mapStateToProps)(SummaryOfContacts);

import { createIsUpdatingReducer } from 'store/redux';

import {
  UPDATE_ONBOARDING_COMPANY_FAILURE,
  UPDATE_ONBOARDING_COMPANY_REQUEST,
  UPDATE_ONBOARDING_COMPANY_SUCCESS,
  UPDATE_ONBOARDING_COMPANY_TYPE_SUCCESS,
} from 'types/currentCompany';

export const finishCases = [
  UPDATE_ONBOARDING_COMPANY_FAILURE,
  UPDATE_ONBOARDING_COMPANY_SUCCESS,
  UPDATE_ONBOARDING_COMPANY_TYPE_SUCCESS,
];
export const requestCases = [UPDATE_ONBOARDING_COMPANY_REQUEST];

const updateReducer = createIsUpdatingReducer(finishCases, requestCases);

export default updateReducer;

export const PERSISTENCE_KEY = 'persistence/';
export const PAGE_SIZE = '-page-size';
export var PERSISTENCE_KEYS;
(function (PERSISTENCE_KEYS) {
    PERSISTENCE_KEYS["BULK_ITEMS_PAGE_SIZE"] = "bulk-imports-page-size";
    PERSISTENCE_KEYS["PAYABLES_PAGE_SIZE"] = "payables-page-size";
    PERSISTENCE_KEYS["RECEIVABLES_PAGE_SIZE"] = "receivables-page-size";
    PERSISTENCE_KEYS["INBOX_PAGE_SIZE"] = "inbox-page-size";
})(PERSISTENCE_KEYS || (PERSISTENCE_KEYS = {}));
export const PERSISTENCE_SHOW_LINE_ITEM_IN_DRAWER = `${PERSISTENCE_KEY}showLineItemsInDrawer`;

import PropTypes from 'prop-types';
import React from 'react';

import { reduxFormFieldDefaultProps, reduxFormFieldPropTypes } from 'components/commonProps';

import { useDebouncedField } from 'hooks';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import InputFieldWrapper from '../InputFieldWrapper';

/**
 * Helper function to render via redux-form's Field component;
 * normalizes prop names across usages.
 * e.g. - <Field component={ReduxFormFieldRenderInput} ... />
 * @param {ComponentProps} props - Props from redux-form Field
 * @param {number|undefined} props.debounceDelay
 * @param {Object} props.input - Props from redux-form Field
 * @param {boolean|undefined} props.isDebounced
 * @param {Object} props.meta - Info object from redux-form Field
 * @param {RestOfProps} props.rest - All other props passed to the component
 * @returns {React.ReactElement}
 */
const ReduxFormFieldRenderInput = ({ debounceDelay, input, isDebounced, meta, hasErrors, ...rest }) => {
  const errors = (meta.touched || meta.submitFailed) && meta.error;

  const { onChange, value } = useDebouncedField({
    debounceDelay,
    isDebounced,
    input,
  });

  return (
    <InputFieldWrapper
      {...rest}
      errors={errors}
      hasErrors={!!errors || hasErrors}
      meta={meta}
      name={input.name}
      onBlur={(event) => {
        rest.onBlur?.(event);
        input.onBlur(event);
      }}
      onChange={onChange}
      onFocus={input.onFocus}
      value={value}
    />
  );
};

ReduxFormFieldRenderInput.propTypes = {
  ...reduxFormFieldPropTypes,
  debounceDelay: PropTypes.number,
  isDebounced: PropTypes.bool,
};

ReduxFormFieldRenderInput.defaultProps = {
  ...reduxFormFieldDefaultProps,
  debounceDelay: undefined,
  isDebounced: undefined,
};

export default ReduxFormFieldRenderInput;

import { createErrorReducer } from 'store/redux';

import { SUBMIT_MESSAGE_FAILURE, SUBMIT_MESSAGE_REQUEST, SUBMIT_MESSAGE_SUCCESS } from 'types/messages';

export const failureCases = [SUBMIT_MESSAGE_FAILURE];
export const successCases = [SUBMIT_MESSAGE_REQUEST, SUBMIT_MESSAGE_SUCCESS];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

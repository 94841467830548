export const CLEAR_PARTNERSHIP = 'CLEAR_PARTNERSHIP';
export const CLEAR_PARTNERSHIP_ADDRESSES = 'CLEAR_PARTNERSHIP_ADDRESSES';
export const CLEAR_PARTNERSHIP_FUNDING_ACCOUNT = 'CLEAR_PARTNERSHIP_FUNDING_ACCOUNT';

export const CREATE_ITEMS_FETCH_PARTNERSHIP_REQUEST = 'CREATE_ITEMS_FETCH_PARTNERSHIP_REQUEST';
export const FETCH_PARTNERSHIP_FAILURE = 'FETCH_PARTNERSHIP_FAILURE';
export const FETCH_PARTNERSHIP_REQUEST = 'FETCH_PARTNERSHIP_REQUEST';
export const FETCH_PARTNERSHIP_SUCCESS = 'FETCH_PARTNERSHIP_SUCCESS';

export const FETCH_PARTNERSHIPS_EXPORT_FAILURE = 'FETCH_PARTNERSHIPS_EXPORT_FAILURE';
export const FETCH_PARTNERSHIPS_EXPORT_REQUEST = 'FETCH_PARTNERSHIPS_EXPORT_REQUEST';
export const FETCH_PARTNERSHIPS_EXPORT_SUCCESS = 'FETCH_PARTNERSHIPS_EXPORT_SUCCESS';

export const CREATE_ITEMS_FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_REQUEST =
  'CREATE_ITEMS_FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_REQUEST';
export const FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_FAILURE =
  'FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_FAILURE';
export const FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_REQUEST =
  'FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_REQUEST';
export const FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_SUCCESS =
  'FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_SUCCESS';

// Routine
export const LOOKUP_PARTNERSHIP_EMAILS = 'LOOKUP_PARTNERSHIP_EMAILS';

export const MODAL_CREATE_PARTNERSHIP_CLOSE = 'MODAL_CREATE_PARTNERSHIP_CLOSE';
export const MODAL_CREATE_PARTNERSHIP_OPEN = 'MODAL_CREATE_PARTNERSHIP_OPEN';

export const MODAL_UPDATE_PARTNERSHIP_PAYMENT_METHOD_CLOSE = 'MODAL_UPDATE_PARTNERSHIP_PAYMENT_METHOD_CLOSE';
export const MODAL_UPDATE_PARTNERSHIP_PAYMENT_METHOD_OPEN = 'MODAL_UPDATE_PARTNERSHIP_PAYMENT_METHOD_OPEN';

export const RESEND_PARTNERSHIP_INVITE = 'RESEND_PARTNERSHIP_INVITE';

export const SEND_PARTNERSHIP_UPDATE_PAYMENT_METHOD_URL_CLEAR = 'SEND_PARTNERSHIP_UPDATE_PAYMENT_METHOD_URL_CLEAR';

export const SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_FAILURE = 'SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_FAILURE';
export const SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_REQUEST = 'SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_REQUEST';
export const SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_SUCCESS = 'SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_SUCCESS';

export const SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_FAILURE = 'SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_FAILURE';
export const SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_REQUEST = 'SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_REQUEST';
export const SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_SUCCESS = 'SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_SUCCESS';

export const SUBMIT_PARTNERSHIP_PRIMARY_RECEIVABLE_FUNDING_ACCOUNT_FAILURE =
  'SUBMIT_PARTNERSHIP_PRIMARY_RECEIVABLE_FUNDING_ACCOUNT_FAILURE';
export const SUBMIT_PARTNERSHIP_PRIMARY_RECEIVABLE_FUNDING_ACCOUNT_REQUEST =
  'SUBMIT_PARTNERSHIP_PRIMARY_RECEIVABLE_FUNDING_ACCOUNT_REQUEST';
export const SUBMIT_PARTNERSHIP_PRIMARY_RECEIVABLE_FUNDING_ACCOUNT_SUCCESS =
  'SUBMIT_PARTNERSHIP_PRIMARY_RECEIVABLE_FUNDING_ACCOUNT_SUCCESS';

export const UPDATE_PARTNERSHIP_GENERAL_INFO = '@company/UPDATE_PARTNERSHIP_GENERAL_INFO';
export const UPDATE_PARTNERSHIP_GENERAL_INFO_SUCCESS = '@company/UPDATE_PARTNERSHIP_GENERAL_INFO/SUCCESS';

export const UPDATE_PARTNERSHIP_PRIMARY_RECEIVABLE_FUNDING_ACCOUNT =
  'UPDATE_PARTNERSHIP_PRIMARY_RECEIVABLE_FUNDING_ACCOUNT';

export const FETCH_PARTNERSHIP_ITEMS_FAILURE = 'FETCH_PARTNERSHIP_ITEMS_FAILURE';
export const FETCH_PARTNERSHIP_ITEMS_REQUEST = 'FETCH_PARTNERSHIP_ITEMS_REQUEST';
export const FETCH_PARTNERSHIP_ITEMS_SUCCESS = 'FETCH_PARTNERSHIP_ITEMS_SUCCESS';

export const SUBMIT_CREATE_PARTNERSHIP = 'SUBMIT_CREATE_PARTNERSHIP';

export const SEND_UPDATE_PAYMENT_LINK = 'SEND_UPDATE_PAYMENT_LINK';

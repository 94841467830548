/**
 * @module reducers/tablesReducer/configsReducer/root
 */

import { combineReducers } from 'redux';

import { tableNamesCreateItems } from 'constants/tables';

import createItemsPayableReducer from './createItemsPayableReducer';
import createItemsReceivableReducer from './createItemsReceivableReducer';

const configsRootReducer = combineReducers({
  [tableNamesCreateItems.PAYABLE]: createItemsPayableReducer,
  [tableNamesCreateItems.RECEIVABLE]: createItemsReceivableReducer,
});

export default configsRootReducer;

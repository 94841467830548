import styled from 'styled-components';
import { containerWidthMap } from 'modules/signup-v3/constants/container';
export const FieldWrapper = styled.div `
  max-width: ${({ maxWidth }) => maxWidth ?? containerWidthMap.small};

  ${({ flex }) => flex &&
    `
    align-items: flex-start;
    display: flex;
    gap: .5rem;
  `}

  ${({ grow }) => grow &&
    `
    flex: 1;
  `}
`;
export const FieldsContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  max-width: ${containerWidthMap.small};
`;

import { createIsFetchingReducer } from 'store/redux';

import { CLEAR_INVOICES, FETCH_INVOICES_FAILURE, FETCH_INVOICES_SUCCESS } from 'types/item';

export const finishCases = [CLEAR_INVOICES, FETCH_INVOICES_FAILURE, FETCH_INVOICES_SUCCESS];

export const requestCases = [];

const fetchReducer = createIsFetchingReducer(finishCases, requestCases);

export default fetchReducer;

import {} from 'interfaces/countries';
export const StaticCountryCode = {
    CA: 'CA',
    CN: 'CN',
    IN: 'IN',
    MX: 'MX',
    US: 'US',
};
export const DomesticCountryCodes = [
    'AS',
    'GU',
    'MP',
    'PR',
    'VI',
    'UM',
    'US',
];

import React from 'react';

import { Text } from 'components';

import { typography } from 'constants/styles';

/**
 * Simple piece of text that displays above the first PermissionGroup in the RoleCardEntityWidgetBody.
 *
 * @returns {StatelessComponent}
 */
const RoleCardEntityWidgetBodySectionTitle = () => (
  <Text.Regular
    className="display--inline-block padding-left--m padding-bottom--sm"
    color={typography.TextColor.BLUE_DARK}
  >
    Permissions under this role include:
  </Text.Regular>
);

export default RoleCardEntityWidgetBodySectionTitle;

import { createIsFetchingReducer } from 'store/redux';

import {
  FETCH_PLATFORM_PARTNERSHIPS_FAILURE,
  FETCH_PLATFORM_PARTNERSHIPS_REQUEST,
  FETCH_PLATFORM_PARTNERSHIPS_SUCCESS,
  FETCH_PLATFORM_PARTNERSHIPS_SEARCH_SUCCESS,
} from 'types/integrations';

export const finishCases = [
  FETCH_PLATFORM_PARTNERSHIPS_FAILURE,
  FETCH_PLATFORM_PARTNERSHIPS_SUCCESS,
  FETCH_PLATFORM_PARTNERSHIPS_SEARCH_SUCCESS,
];
export const requestCases = [FETCH_PLATFORM_PARTNERSHIPS_REQUEST];

const fetchReducer = createIsFetchingReducer(finishCases, requestCases);

export default fetchReducer;

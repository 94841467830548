import { sendPartnershipInviteRelationships, updatePartnershipRelationships } from 'constants/relationships';

import { FetchService } from 'services';
import { payloadToJSONAPI } from 'services/api/formatHelpers';
import {
  getPartnershipEmailLookupEndpoint,
  getPartnershipEndpoint,
  getPartnershipItemsEndpoint,
  getPartnershipReceivableFundingAccountsEndpoint,
  getPartnershipsEndpoint,
  getSendPartnerUpdatePaymentMethodLink,
} from 'services/api/partnershipsEndpoints';

/**
 * Fetch all partnerships.
 * @param {Object} params
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchPartnerships = async (params, options = {}) => {
  const { accept, ...rest } = params;

  const config = {
    ...options,
    accept,
    endpoint: getPartnershipsEndpoint(rest),
    method: 'GET',
    requireAuth: true,
  };

  return FetchService.request(config);
};

/**
 * Look up emails belonging to a Partnership to get PartnershipMembers already created with that email.
 * @param {Object} queryParams - ?email=
 * @param {ObjectMaybe} options
 * @return {Promise}
 */
export const fetchPartnershipEmails = async (queryParams, options = {}) => {
  const config = {
    ...options,
    endpoint: getPartnershipEmailLookupEndpoint(queryParams),
    method: 'GET',
    requireAuth: true,
  };

  return FetchService.request(config);
};

/**
 * Fetch a specific partnership.
 * @param {string} partnershipId
 * @param {Object} queryParams
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchSinglePartnership = async (partnershipId, queryParams, options = {}) => {
  const config = {
    ...options,
    endpoint: getPartnershipEndpoint(partnershipId, queryParams),
    method: 'GET',
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * Fetch items for a specific partnership.
 * @param {Object} params
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchPartnershipItems = async (params, options = {}) => {
  const { accept, partnershipId, ...restParams } = params;

  const config = {
    ...options,
    accept,
    endpoint: getPartnershipItemsEndpoint(partnershipId, restParams),
    method: 'GET',
    requireAuth: true,
  };

  return FetchService.request(config);
};

/**
 * Fetch receivable funding accounts for a specific partnership.
 * @param {string} partnershipId
 * @param {Object} queryParams
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchPartnershipReceivableFundingAccounts = async (partnershipId, queryParams, options = {}) => {
  const config = {
    ...options,
    endpoint: getPartnershipReceivableFundingAccountsEndpoint(partnershipId, queryParams),
    method: 'GET',
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * Update the partnership.
 * @param {Object} data
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const updatePartnership = async (data, options = {}) => {
  const payload = payloadToJSONAPI(data, 'partnership', ...updatePartnershipRelationships);
  const config = {
    ...options,
    endpoint: getPartnershipEndpoint(data.id),
    payload,
    method: 'PATCH',
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * Send a link to update a partner's payment method.
 * @param {Object} data
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const sendUpdatePaymentMethodLink = async (data, options = {}) => {
  // we use the same relationships structure here as when sending an invite
  const payload = payloadToJSONAPI(data, 'partnership', ...sendPartnershipInviteRelationships);
  const config = {
    ...options,
    endpoint: getSendPartnerUpdatePaymentMethodLink(data.id),
    payload,
    method: 'POST',
    setRequester: true,
    requireAuth: true,
  };
  return FetchService.request(config);
};

import { createSelector } from 'reselect';

import * as sidePanelNames from 'constants/sidePanels';

import { partnershipsByIdSelector } from 'selectors/partnershipsSelectors';

import { idSelector } from './globalSelectors';

/**
 * Selects side panels from the state
 * @param {object} state - Redux state
 */
export const sidePanelsSelector = (state) => state.sidePanels;

/**
 * Selects a specific side panel from the state
 * @param {object} state - Redux state
 * @param {string} sidePanelName
 */
export const sidePanelSelector = createSelector(
  [sidePanelsSelector, idSelector],
  (sidePanels, sidePanelName) => sidePanels[sidePanelName],
);

/**
 * Selects a specific side panel from the state
 * @function
 * @param {string} sidePanelName
 * @return {function(Object): ObjectMaybe}
 */
export const createSidePanelSelector = (sidePanelName) =>
  createSelector([sidePanelsSelector], (sidePanels) => sidePanels[sidePanelName]);

/**
 * Selects a specific side panel from state, returns open property
 * @param {object} state - Redux state
 * @param {string} sidePanelName
 */
export const sidePanelIsOpenSelector = createSelector([sidePanelsSelector, idSelector], (sidePanels, sidePanelName) =>
  sidePanels[sidePanelName] ? sidePanels[sidePanelName].open : false,
);

/**
 * Selects wether there's any opened side panel
 * @param {object} state - Redux state
 */
export const isAnySidePanelOpenSelector = createSelector([sidePanelsSelector], (sidePanels) =>
  Object.keys(sidePanels).some((sidePanelName) => sidePanels[sidePanelName].open),
);

/**
 * Selects that add partner funding account side panel state
 * @type {StandardSelector}
 * @param {ReduxState} state
 */
export const addPartnerFundingAccountSidePanelSelector = createSidePanelSelector(
  sidePanelNames.sidePanelNameAddPartnerFundingAccount,
);

/**
 * Selects addPartnerFundingAccount side panel partnership from the state
 * @param {ReduxState} state - Redux state
 */
export const addPartnerFundingAccountSidePanelPartnershipSelector = createSelector(
  [addPartnerFundingAccountSidePanelSelector, partnershipsByIdSelector],
  (sidePanel, partnerships) => partnerships[sidePanel.partnershipId],
);

/**
 * Selects existing item side panel state
 * @type {StandardSelector}
 * @param {ReduxState} state
 */
export const existingItemSidePanelSelector = createSidePanelSelector(sidePanelNames.sidePanelNameExistingItem);

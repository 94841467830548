import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import swal from 'sweetalert';

import { ButtonV2 } from 'components/buttonV2';
import Icon from 'components/icon';
import { Image } from 'components/media';
import { Text } from 'components/text';

import { colors, sizes } from 'constants/styles';
import { TextColor, TextSize } from 'constants/styles/typography';
import { Intent } from 'constants/ui';

import { getSwalFooterClassname } from 'helpers/swal';
import { anyValues, callIfIsFn, hasZeroLength, isEmptyObject } from 'helpers/utility';

import '../SwalWrapper.scss';

/**
 * Generic SWAL wrapper component, containing jsx for displaying
 * swal's header, content and footer.
 * @param {ComponentProps} props
 * @param {string} props.contentClassname
 * @param {string} props.footerClassname
 * @param {Object} props.iconProps
 * @param {ObjectMaybe} [props.imageProps={}]
 * @param {string} props.intent
 * @param {Object} [props.leftButtonProps]
 * @param {Object} [props.rightButtonProps]
 * @param {StringMaybe | Component} props.subtitle
 * @param {Boolean} [props.shouldCloseOnClick=true]
 * @param {string} props.title
 * @return {StatelessComponent}
 */
const SwalWrapperV2 = ({
  children,
  contentClassname,
  footerClassname,
  iconProps,
  imageProps,
  intent,
  leftButtonProps,
  rightButtonProps,
  shouldCloseOnClick,
  subtitle,
  title,
}) => {
  const footerClassnames = getSwalFooterClassname(intent, footerClassname);

  const onClose = () => {
    swal.close();
  };

  const onClick = (onClickProp) => {
    callIfIsFn(onClickProp);
    if (shouldCloseOnClick) {
      onClose();
    }
  };

  return (
    <div className="swal-wrapper">
      <div className="swal-wrapper__heading">
        {anyValues(imageProps) && (
          <div className="swal-wrapper__heading--image">
            <Image {...imageProps} />
          </div>
        )}

        <div className="swal-wrapper__heading--title">
          <Text.Bold color={TextColor.BLUE_DARK} size={TextSize.LEVEL_400}>
            {!isEmptyObject(iconProps) && !hasZeroLength(iconProps?.name) && (
              <Icon
                className="flex-shrink--0 margin-right--xm"
                color={iconProps.color}
                icon={iconProps.name}
                size={iconProps.size}
              />
            )}
            {title}
          </Text.Bold>
        </div>

        {subtitle && (
          <div className="swal-wrapper__heading--subtitle">
            <Text.Regular size={TextSize.LEVEL_200}>{subtitle}</Text.Regular>
          </div>
        )}
      </div>

      <div
        className={classNames('swal-wrapper__content', {
          [contentClassname]: !!contentClassname,
        })}
      >
        {children}
      </div>

      <div
        className={classNames('swal-wrapper__footer', {
          [footerClassnames]: !!footerClassnames,
        })}
      >
        {leftButtonProps && (
          <ButtonV2
            intent={Intent.NEUTRAL}
            leftIconClassName="margin-right--xm"
            leftIconColor={colors.colorGreyDarkHex}
            leftIconSize={sizes.iconSizes.EXTRA_MEDIUM}
            {...leftButtonProps}
            onClick={() => onClick(leftButtonProps.onClick)}
          />
        )}

        {rightButtonProps && (
          <ButtonV2
            className={classNames({
              'margin-left--auto': !leftButtonProps,
            })}
            intent={Intent.DANGER}
            leftIconClassName="margin-right--xm"
            leftIconSize={sizes.iconSizes.EXTRA_MEDIUM}
            {...rightButtonProps}
            onClick={() => onClick(rightButtonProps.onClick)}
          />
        )}
      </div>
    </div>
  );
};

SwalWrapperV2.propTypes = {
  children: PropTypes.node,
  contentClassname: PropTypes.string,
  footerClassname: PropTypes.string,
  iconProps: PropTypes.shape({
    color: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.number,
  }),
  imageProps: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string,
  }),
  intent: PropTypes.oneOf([Intent.DANGER, Intent.NEUTRAL, Intent.GHOST, Intent.PRIMARY, Intent.SUCCESS]),
  leftButtonProps: PropTypes.shape(),
  rightButtonProps: PropTypes.shape(),
  shouldCloseOnClick: PropTypes.bool,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

SwalWrapperV2.defaultProps = {
  children: undefined,
  contentClassname: undefined,
  footerClassname: undefined,
  iconProps: {
    color: colors.colorBluePrimaryHex,
    name: undefined,
    size: sizes.iconSizes.XX_LARGE,
  },
  imageProps: {},
  intent: Intent.PRIMARY,
  leftButtonProps: undefined,
  rightButtonProps: undefined,
  shouldCloseOnClick: true,
  subtitle: undefined,
};

export default SwalWrapperV2;

import PropTypes from 'prop-types';
import React from 'react';

import DocumentStatus from 'components/document/components/DocumentStatus';

import { button } from 'constants/styles/formStyles';

import { downloadFileFromURL } from 'helpers/fileHelpers';

const DocumentContentRight = ({
  documentName,
  documentStatus,
  documentURL,
  handleRemoveDocument,
  hasStatusTag,
  isClearable,
  isDownloadable,
}) => {
  if (isClearable) {
    // IF user is able to delete the document
    return <span className="icon-ic-x-out general-cursor-hover" onClick={handleRemoveDocument} role="presentation" />;
  }

  // IF user cannot delete the document
  if (hasStatusTag) {
    // document has a status, and cannot be downloaded
    return <DocumentStatus documentStatus={documentStatus} />;
  }

  if (documentName && documentURL && isDownloadable) {
    // document has NO status, and can be downloaded
    return (
      <a
        aria-hidden
        className={`${button.ghost.sm.primaryLink} remove-margin-bottom`}
        id="downloadDocument"
        onClick={() => downloadFileFromURL(documentURL, documentName)}
        role="button"
      >
        <span className="icon-ic-download icon-left" />
        <span>Download</span>
      </a>
    );
  }

  return null;
};

DocumentContentRight.propTypes = {
  documentName: PropTypes.string,
  documentStatus: PropTypes.string,
  documentURL: PropTypes.string,
  handleRemoveDocument: PropTypes.func,
  hasStatusTag: PropTypes.bool,
  isClearable: PropTypes.bool,
  isDownloadable: PropTypes.bool,
};

DocumentContentRight.defaultProps = {
  documentName: null,
  documentStatus: null,
  documentURL: '',
  handleRemoveDocument: () => {},
  hasStatusTag: false,
  isClearable: false,
  isDownloadable: false,
};

export default DocumentContentRight;

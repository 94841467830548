import { Badge } from '@routable/gross-ds';
import React from 'react';
import { DropdownMenuOption } from 'complexComponents/DropdownMenuButton';
import { isGreaterOrEqual } from 'helpers/utility';
const TableToolbarActionsMenuOption = ({ actionableCount, isDisabled, isDisabledWhenNoActionable = true, title, ...rest }) => {
    const hasActionableCount = isGreaterOrEqual(actionableCount, 1);
    return (React.createElement(DropdownMenuOption, { ...rest, className: "table-toolbar--dd-option", isDisabled: isDisabled || (isDisabledWhenNoActionable && !hasActionableCount), title: React.createElement("div", { className: "flex justify-between" },
            title,
            hasActionableCount && (React.createElement(Badge, { className: "action-count", intent: "blue", size: "small" }, actionableCount))) }));
};
export default TableToolbarActionsMenuOption;

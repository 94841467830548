import { change } from 'redux-form';

import { getBankNameFromRoutingNumber } from 'services/api/dataEndpoints';
import FetchService from 'services/fetch';

/**
 * Ensure a routing number is valid
 * See: https://en.wikipedia.org/wiki/ABA_routing_transit_number#Check_digit
 * @param routingNumber
 * @return {boolean}
 */
export const checkRoutingNumber = (routingNumber) => {
  const regex = /^[0-9]{9}$/i;
  const stringRoutingNumber = String(routingNumber);

  if (!regex.test(stringRoutingNumber)) {
    return false;
  }

  // Split to digits and ensure numeric
  const d = stringRoutingNumber.split('').map((val) => parseInt(val, 10));

  // Build checksum
  const checksum = 3 * (d[0] + d[3] + d[6]) + 7 * (d[1] + d[4] + d[7]) + (d[2] + d[5] + d[8]);

  // Ensure checksum's modulo 10 is 0
  return checksum % 10 === 0;
};

/**
 * Clear routing number from form
 * @param formName
 * @param fieldKey
 * @param dispatch
 */
export const clearBankNameFromRoutingNumber = (formName, fieldKey, dispatch) => {
  dispatch(change(formName, fieldKey, ''));
};

/**
 * Update routing number
 * @param bankInstitutionName
 * @param formName
 * @param fieldKey
 * @param dispatch
 */
export const updateBankNameFromRoutingNumber = (bankInstitutionName, formName, fieldKey, dispatch) => {
  dispatch(change(formName, fieldKey, bankInstitutionName));
};

export const getBankInstitutionNameFromRouting = async (bankRoutingNumber) => {
  const response = await FetchService.request({
    method: 'GET',
    endpoint: getBankNameFromRoutingNumber(bankRoutingNumber),
  });

  if (!response.ok) {
    return false;
  }

  return response.originalData.data.attributes.institution_name;
};

import { createIsSubmittingReducer } from 'store/redux';

import {
  SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_FAILURE,
  SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_REQUEST,
  SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_SUCCESS,
} from 'types/connectBank';

export const finishCases = [SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_FAILURE, SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_SUCCESS];
export const requestCases = [SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_REQUEST];

const submitReducer = createIsSubmittingReducer(finishCases, requestCases);

export default submitReducer;

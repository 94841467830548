import { getObjectsByIdWithRelationships } from 'helpers/reducer';

import { FETCH_TAX_INFO_SUCCESS, FETCH_VENDOR_TAX_INFO_SUCCESS } from 'types/taxes';

const infoByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TAX_INFO_SUCCESS:
    case FETCH_VENDOR_TAX_INFO_SUCCESS:
      return getObjectsByIdWithRelationships(action.payload.partnershipTaxData);

    default:
      return state;
  }
};

export default infoByIdReducer;

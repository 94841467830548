import React from 'react';

import { ExternalAcceptPartnershipFlowState } from 'constants/ui';

import AcceptPartnershipIllustrationBase from '../AcceptPartnershipIllustrationBase';

/**
 * Renders SuccessRegisterIllustrationSvg based on external accept partnership flow state.
 * @return {StatelessComponent}
 */
const SuccessRegisterIllustrationSvg = () => (
  <AcceptPartnershipIllustrationBase acceptPartnershipState={ExternalAcceptPartnershipFlowState.SUCCESS} />
);

export default SuccessRegisterIllustrationSvg;

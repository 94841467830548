import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

import { getPaymentOrInvoiceText } from 'helpers/items';
import { getVendorOrCustomerTitleFromItemKind } from 'helpers/partnerships';

/**
 * Tooltip text for AddPartnershipButton. Provides more context than the single Add button text can provide alone given
 * the limited space available in the table cell or MenuItem. Gives context based on the item kind.
 * @param {Item} item
 * @returns {string}
 */
export const getAddPartnershipButtonTooltipText = (item) => {
  const paymentOrInvoice = getPaymentOrInvoiceText(item);
  const customerOrVendor = getVendorOrCustomerTitleFromItemKind(item.kind);

  return `Add this ${customerOrVendor} to ${PLATFORM_DISPLAY_SHORT_NAME} to send this ${paymentOrInvoice}.`;
};

/**
 * Tooltip text for merge option in RequiresMatchPopoverOptions. Provides more context than the single merge button text
 * can provide alone given the limited space available in the MenuItem. Gives context based on the item kind.
 * @param {Item} item
 * @returns {string}
 */
export const getMergePartnershipButtonTooltipText = (item) => {
  const paymentOrInvoice = getPaymentOrInvoiceText(item);
  const customerOrVendor = getVendorOrCustomerTitleFromItemKind(item.kind);

  return `Merge this ${customerOrVendor} with an existing ${PLATFORM_DISPLAY_SHORT_NAME} ${customerOrVendor}
   to send this ${paymentOrInvoice}.`;
};

import { createSelector } from 'reselect';

import { BulkActionTypes } from 'enums/bulkActions';

import { getItemsEligibleForBulkAction, getItemsIneligibleForBulkAction } from 'helpers/bulkActions';

import { permissionHelpers } from 'modules/bulkActions/helpers';

import {
  tableLockedSelectionArrayByPropSelector,
  tableSelectedArrayByPropSelector,
} from 'queries/tableCompoundSelectors';

import { approvalsSelector } from 'selectors/approvalsSelectors';
import { earItemSummarySelector } from 'selectors/approvalSummarySelector';
import { bulkActionsImportByIdSelector, bulkActionsModalBulkActionSelector } from 'selectors/bulkActionsSelectors';
import { currentCompanyUsersSelector } from 'selectors/currentCompanySelectors';
import { bulkImportItemsIdFieldSelector } from 'selectors/forms';
import { ledgerIntegrationApplicationSelector } from 'selectors/integrationsSelectors';
import { currentMembershipIdSelector, membershipsByIdSelector } from 'selectors/membershipsSelector';
import { bulkImportQuerySelector } from 'selectors/routerSelectors';

/**
 * Based on the url tab param and the selected bulk action, this filters out the ineligible items for the selected action
 * @type {StandardSelector}
 */
export const bulkActionsEligibleItemsFromLockedSelectionForSelectedActionSelector = createSelector(
  [
    approvalsSelector,
    tableLockedSelectionArrayByPropSelector,
    bulkActionsModalBulkActionSelector,
    currentMembershipIdSelector,
    earItemSummarySelector,
  ],
  (approvals, items, bulkAction, membershipId, earSummary) =>
    getItemsEligibleForBulkAction({
      approvals,
      bulkAction,
      items,
      membershipId,
      earSummary,
    }),
);

/**
 * Based on the url tab param and the selected bulk action, this filters out the selected items eligible for submit
 * @type {StandardSelector}
 */
export const bulkActionsEligibleItemsFromSelectedForSelectedActionSelector = createSelector(
  [
    approvalsSelector,
    tableSelectedArrayByPropSelector,
    bulkActionsModalBulkActionSelector,
    currentMembershipIdSelector,
    earItemSummarySelector,
  ],
  (approvals, items, bulkAction, membershipId, earSummary) =>
    getItemsEligibleForBulkAction({
      approvals,
      bulkAction,
      items,
      membershipId,
      earSummary,
    }),
);

/**
 * Based on the url tab param and the selected bulk action, this filters out the ineligible items for the selected action
 * @type {StandardSelector}
 */
export const bulkActionsIneligibleItemsForSelectedActionSelector = createSelector(
  [
    approvalsSelector,
    tableLockedSelectionArrayByPropSelector,
    bulkActionsModalBulkActionSelector,
    currentMembershipIdSelector,
    earItemSummarySelector,
  ],
  (approvals, items, bulkAction, membershipId, earSummary) =>
    getItemsIneligibleForBulkAction({
      approvals,
      bulkAction,
      items,
      membershipId,
      earSummary,
    }),
);

/**
 * Selector creator. Returned selector gets all item selections that are eligible for the given action.
 * @param {BulkActionTypes} bulkAction
 * @return {StandardSelector}
 */
export const createBulkActionsEligibleItemsSelector = (bulkAction) =>
  createSelector(
    [approvalsSelector, tableSelectedArrayByPropSelector, currentMembershipIdSelector, earItemSummarySelector],
    (approvals, items = [], membershipId, earSummary) =>
      getItemsEligibleForBulkAction({
        approvals,
        bulkAction,
        items,
        membershipId,
        earSummary,
      }),
  );

/**
 * Returns an array of items that are eligible for the bulk action: Approve.
 * @type {StandardSelector}
 */
export const bulkActionsApproveEligibleItemsSelector = createBulkActionsEligibleItemsSelector(BulkActionTypes.APPROVE);

/**
 * Returns an array of items that are eligible for the bulk action: Approve and send.
 * @type {StandardSelector}
 */
export const bulkActionsApproveAndSendEligibleItemsSelector = createBulkActionsEligibleItemsSelector(
  BulkActionTypes.APPROVE_AND_SEND,
);

/**
 * Returns an array of items that are eligible for the bulk action: Send.
 * @type {StandardSelector}
 */
export const bulkActionsSendEligibleItemsSelector = createBulkActionsEligibleItemsSelector(BulkActionTypes.SEND);

/**
 * Returns an array of items that are eligible for the bulk action: Edit send date.
 * @type {StandardSelector}
 */
export const bulkActionsEditSendDateEligibleItemsSelector = createBulkActionsEligibleItemsSelector(
  BulkActionTypes.EDIT_SEND_DATE,
);

/**
 * Based on the url query param for bulk_import, pulls the BulkImport object from state.
 * @type {StandardSelector}
 */
export const bulkImportForQueryParamSelector = createSelector(
  [bulkActionsImportByIdSelector, bulkImportQuerySelector],
  (bulkImports, bulkImportId) => bulkImports[bulkImportId],
);

/**
 * Based on the url query param for bulk_import, pulls the BulkImport object from state.
 * Includes user data for the creator.
 * @type {StandardSelector}
 */
export const bulkImportForQueryParamWithUserDataSelector = createSelector(
  [bulkImportForQueryParamSelector, membershipsByIdSelector, currentCompanyUsersSelector],
  (bulkImport, memberships, users) => {
    if (bulkImport) {
      const { creator } = bulkImport;
      const creatorMembership = memberships?.[creator];

      if (creatorMembership) {
        const { user } = creatorMembership;
        return {
          ...bulkImport,
          user: users?.[user],
        };
      }
    }

    return undefined;
  },
);

/**
 * Based on active bulk import ID in form state, pulls the BulkImport object.
 * @type {StandardSelector}
 */
export const bulkImportItemsActiveImport = createSelector(
  [bulkActionsImportByIdSelector, bulkImportItemsIdFieldSelector],
  (bulkImportsById, id) => bulkImportsById[id],
);

/**
 * Selects currently active bulk import and returns bool value of bulkImport.issues
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const bulkImportItemsActiveImportHasIssuesSelector = createSelector(
  [bulkImportItemsActiveImport],
  (bulkImport) => Boolean(bulkImport?.issues),
);

/**
 * Pulls the summary for the active bulk import.
 * @type {StandardSelector}
 */
export const bulkImportItemsActiveImportSummarySelector = createSelector(
  [bulkImportItemsActiveImport],
  (activeImport) => activeImport?.summary,
);

/**
 * Pulls the import type for the active bulk import.
 * @type {StandardSelector}
 */
export const bulkImportItemsActiveImportTypeSelector = createSelector(
  [bulkImportItemsActiveImport],
  (activeImport) => activeImport?.importType,
);

/**
 * Returns whether CSV import is enabled, based on the active ledger (if one exists).
 * @type {StandardSelector}
 * @return {boolean}
 */
export const isCsvImportEnabledSelector = createSelector([ledgerIntegrationApplicationSelector], (ledgerApplication) =>
  permissionHelpers.isCSVUploadAllowed(ledgerApplication),
);

/**
 * @fileOverview Shared data-testid constant values for bulk actions related features.
 *
 * @module constants/dataCy/bulkActions
 */

/**
 * Bulk actions > bulk import.
 * @type {Object<string, string>}
 */
export const bulkImportModal = {
  BUTTON_FOOTER_PRIMARY: 'button_footer_primary',
  BUTTON_FOOTER_SECONDARY: 'button_footer_secondary',
  BUTTON_UPLOAD_PRIMARY: 'button_upload_primary',
  BUTTON_UPLOAD_SECONDARY: 'button_upload_secondary',
};

export const bulkActionModal = {
  ACTION_BUTTON: 'action_button',
};

import { createIsSubmittingReducer } from 'store/redux';

import {
  SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_FAILURE,
  SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_REQUEST,
  SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_SUCCESS,
} from 'types/signup';

export const finishCases = [
  SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_FAILURE,
  SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_SUCCESS,
];
export const requestCases = [SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_REQUEST];

const submitReducer = createIsSubmittingReducer(finishCases, requestCases);

export default submitReducer;

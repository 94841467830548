/**
 * @enum {string}
 */
export const Environment = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  SANDBOX: 'sandbox',
  STAGING: 'staging',
  TEST: 'test',
};

/**
 * Env variable names
 * @enum {string}
 */
export const EnvVariable = {
  ENABLE_PRODUCTION_DEVTOOLS: 'ROUTABLE_FE_ENABLE_PRODUCTION_DEVTOOLS',
  NODE_ENV: 'NODE_ENV',
  REQUEST_TIMEOUT_SECONDS: 'ROUTABLE_FE_REQUEST_TIMEOUT_SECONDS',
};

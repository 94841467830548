import { combineReducers } from 'redux';

import itemEditAlertsReducer from './itemEditAlertsReducer.ts';
import progressBarStepperReducer from './progressBarStepperReducer';

const componentConfigurationReducer = combineReducers({
  progressBarStepper: progressBarStepperReducer,
  itemEditAlerts: itemEditAlertsReducer,
});

export default componentConfigurationReducer;

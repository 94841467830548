import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';

import { openConfirmTaxFilingInfoModal } from 'actions/taxes';

import { formNamesExternal } from 'constants/forms';

import { showFormFailureToast } from 'helpers/errors';

import { onCollectTaxInfoFormChange } from 'modules/external/ExternalCollectTaxInfo/helpers/forms';
import { getCollectTaxFormInitialState } from 'modules/external/ExternalCollectTaxInfo/helpers/initialValues';

import { companyMailingAddressSelector } from 'selectors/addressesSelectors';
import { currentCompanyInfoSelector, currentCompanySelector } from 'selectors/currentCompanySelectors';
import { currentUserSelector } from 'selectors/currentUserSelectors';
import { externalCollectTaxInfoFormSelector } from 'selectors/forms';
import { currentMembershipSelector } from 'selectors/membershipsSelector';

import updateOnboardingCompany from 'thunks/updateOnboardingCompany';
import updateOnboardingCompanyType from 'thunks/updateOnboardingCompanyType';
import updateOnboardingMembership from 'thunks/updateOnboardingMembership';

import CollectTaxForm from './CollectTaxForm';

const formName = formNamesExternal.COLLECT_TAX_INFO;

const mapStateToProps = (state) => {
  const currentCompany = currentCompanySelector(state);
  const currentCompanyInfo = currentCompanyInfoSelector(state);
  const currentCompanyMailingAddress = companyMailingAddressSelector(state, currentCompany.id) || {};
  const currentMembership = currentMembershipSelector(state);
  const currentUser = currentUserSelector(state);

  const initialValues = getCollectTaxFormInitialState({
    currentCompany,
    currentCompanyInfo,
    currentCompanyMailingAddress,
    currentMembership,
    currentUser,
  });

  return {
    countrySelection: externalCollectTaxInfoFormSelector(state, 'form.company.mailingAddress.country'),
    filingDetails: getFormValues(formName)(state),
    initialValues,
  };
};

const mapDispatchToProps = {
  onSuccessOpenModal: openConfirmTaxFilingInfoModal,
  onUpdateOnboardingCompany: updateOnboardingCompany,
  onUpdateOnboardingCompanyType: updateOnboardingCompanyType,
  onUpdateOnboardingMembership: updateOnboardingMembership,
};

const reduxFormWrapperCollectTaxForm = reduxForm({
  form: formName,
  // Reads form values on change to derive and set the tinType
  onChange: onCollectTaxInfoFormChange,
  onSubmitFail: () => showFormFailureToast(formNamesExternal.COLLECT_TAX_INFO),
  updateUnregisteredFields: true,
})(CollectTaxForm);

export default connect(mapStateToProps, mapDispatchToProps)(reduxFormWrapperCollectTaxForm);

import Flags from 'country-flag-icons/react/3x2';
import React from 'react';
import { getCountryNameFromCode } from 'helpers/countries';
import { useCountryCodesContext } from 'hooks/context';
import {} from './CountryFlag.types';
const CountryFlag = ({ countryCode, ...props }) => {
    const countryCodeMap = useCountryCodesContext();
    const Flag = Flags[countryCode];
    const countryName = getCountryNameFromCode(countryCodeMap, countryCode);
    if (!Flag) {
        return null;
    }
    return React.createElement(Flag, { title: countryName, ...props });
};
export default CountryFlag;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const EntityWidgetHeaderTitle = ({ dataFullStory, children, className, style }) => {
  const classConfig = classNames({
    bold: true,
    'entity-widget--header-title': true,
    'font-color--greyXDark': true,
    'margin-bottom--xs': true,
    [className]: !!className,
  });

  return (
    <div className={classConfig} data-fullstory={dataFullStory} style={style}>
      {children}
    </div>
  );
};

EntityWidgetHeaderTitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

EntityWidgetHeaderTitle.defaultProps = {
  children: undefined,
  className: undefined,
  style: {},
};

export default EntityWidgetHeaderTitle;

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { createItemBillCurrencySelector } from 'queries/createItemFormSelectors';

import { createItemFormMetaSelector } from 'selectors/forms';
import { ledgerIntegrationSelector } from 'selectors/integrationsSelectors';
import { ledgerSettingsSelector } from 'selectors/ledgerInfoSelectors';

import TotalsPanelTotal from './TotalsPanelTotal';

export const mapStateToProps = createStructuredSelector({
  currencyCode: createItemBillCurrencySelector,
  ledger: ledgerIntegrationSelector,
  ledgerSettings: ledgerSettingsSelector,
  meta: createItemFormMetaSelector,
});

export default connect(mapStateToProps)(TotalsPanelTotal);

import { combineReducers } from 'redux';

import allApprovalsReducer from './allApprovalsReducer';
import approvalsByIdReducer from './approvalsByIdReducer';

const approvalsReducer = combineReducers({
  allIds: allApprovalsReducer,
  byId: approvalsByIdReducer,
});

export default approvalsReducer;

import { RoutableEventsSync, useEventSync } from '@routable/framework';
import React, { useEffect } from 'react';
export const useTabs = (defaultTabId, tabGroupId) => {
    const [activeTabId, setActiveTabId] = React.useState(defaultTabId);
    const getIsActive = (tabId) => activeTabId === tabId;
    const setActiveTabIdHandler = (tabId) => {
        if (getIsActive(tabId)) {
            return;
        }
        RoutableEventsSync.Publish(`tabs:${tabGroupId}:onUpdate`, tabId);
        setActiveTabId(tabId);
    };
    useEventSync(`tabs:${tabGroupId}:update`, setActiveTabIdHandler);
    const getActiveTabSetter = (tabId) => {
        return () => setActiveTabIdHandler(tabId);
    };
    const getTabState = (tabId) => {
        const isActive = getIsActive(tabId);
        return {
            isActive,
            'data-active': isActive,
            onClick: getActiveTabSetter(tabId),
        };
    };
    useEffect(() => {
        RoutableEventsSync.Publish(`tabs:${tabGroupId}:onUpdate`, defaultTabId);
    }, [defaultTabId, tabGroupId]);
    return {
        activeTabId,
        getTabState,
        setActiveTabId: setActiveTabIdHandler,
    };
};

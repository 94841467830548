import React from 'react';
import ExternalLink from 'components/ExternalLink';
import { Hint, HintText } from 'components/hint';
import { WhiteSpace } from 'components/text';
import helpDocs from 'helpers/helpDocs';
const MissingPartnerCountryHint = () => (React.createElement(Hint, { intent: "info", multiline: true },
    React.createElement(HintText, null,
        "Can\u2019t find your country? Learn more about",
        React.createElement(WhiteSpace, null),
        React.createElement(ExternalLink, { className: "font-color--blue-bold font-weight--regular remove-hover-underline", href: helpDocs.PARTNER_COUNTRY_CURRENCY_HINT }, "supported countries and currencies"))));
export default MissingPartnerCountryHint;

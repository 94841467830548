import * as routines from 'actions/routines/permissionGroups';

import { createIsFetchingReducer } from 'store/redux';

export const finishCases = [
  routines.fetchPermissionGroupsRoutine.FAILURE,
  routines.fetchPermissionGroupsRoutine.SUCCESS,
];

export const requestCases = [routines.fetchPermissionGroupsRoutine.REQUEST];

const isFetchingReducer = createIsFetchingReducer(finishCases, requestCases);

export default isFetchingReducer;

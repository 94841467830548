import { SectionMessage } from '@routable/gross-ds';
import _isEmpty from 'lodash/isEmpty';
import React from 'react';
import InfoList from './InfoList';
export const Warnings = ({ className, warnings, hasErrors = false }) => {
    if (_isEmpty(warnings)) {
        return null;
    }
    return (React.createElement(SectionMessage, { className: className, intent: "warning", title: "Some info doesn't match bank records or couldn't be verified." },
        !hasErrors && React.createElement("div", null, "You can still add this account and attempt payment."),
        React.createElement(InfoList, { data: warnings })));
};

import { QuickswitchActionType } from 'types/quickswitch';
export const initialState = {
    companyName: null,
    loadingMembershipsList: false,
    memberships: [],
    isModalOpen: false,
    isErrorModalOpen: false,
    searchTerm: '',
    totalNumberOfMemberships: 0,
};
const quickswitchReducer = (state = initialState, action = null) => {
    switch (action.type) {
        case QuickswitchActionType.FETCH_MEMBERSHIP_LIST_REQUEST:
        case QuickswitchActionType.FETCH_MEMBERSHIP_SEARCH_REQUEST:
            return { ...state, loadingMembershipsList: true };
        case QuickswitchActionType.UPDATE_MEMBERSHIP_LIST:
            return {
                ...state,
                loadingMembershipsList: false,
                memberships: action.payload.memberships,
            };
        case QuickswitchActionType.OPEN_MODAL:
            return { ...state, isModalOpen: true };
        case QuickswitchActionType.CLOSE_MODAL:
            return { ...state, isModalOpen: false };
        case QuickswitchActionType.OPEN_ERROR_MODAL:
            return {
                ...state,
                loadingMembershipsList: false,
                isErrorModalOpen: true,
            };
        case QuickswitchActionType.CLOSE_ERROR_MODAL:
            return { ...state, isErrorModalOpen: false };
        case QuickswitchActionType.SET_SEARCH_TERM:
            return {
                ...state,
                loadingMembershipsList: true,
                searchTerm: action.payload.searchTerm,
            };
        case QuickswitchActionType.SET_TOTAL_NUMBER_OF_MEMBERSHIPS:
            return {
                ...state,
                totalNumberOfMemberships: action.payload.totalNumberOfMemberships,
            };
        default:
            return state;
    }
};
export default quickswitchReducer;

import PropTypes from 'prop-types';
import React from 'react';

import { FlexCol } from 'components/layout';

import { getClassNames } from 'helpers/ui';

/**
 * Displays addons (node) if passed to the MultiLineOption
 * component used in react-select or null
 * @see MultiLineOption
 * @param {ComponentProps} props
 * @param {NodeMaybe} props.addons
 * @param {string} props.className
 * @param {RestOfProps} props.rest
 * @return {StatelessComponent}
 */
const MultiLineOptionAddons = ({ addons, ...rest }) => {
  if (!addons) {
    return null;
  }

  return (
    <FlexCol
      className={getClassNames(rest, {
        'multi-line-option--addons': true,
      })}
    >
      {addons}
    </FlexCol>
  );
};

MultiLineOptionAddons.propTypes = {
  addons: PropTypes.node,
};

MultiLineOptionAddons.defaultProps = {
  addons: undefined,
};

export default MultiLineOptionAddons;

import { Tooltip } from '@routable/gross-ds';
import React from 'react';

import RestrictedPermissionContent from 'components/RestrictedPermissionContent';

/**
 * A tooltip to show if the RCTM does not have adequate permissions to take an action.
 * @param {ComponentProps} props
 * @param {*} props.children
 */
const RestrictedPermissionTooltip = ({ children, ...props }) => (
  <Tooltip
    closeDelay={300}
    data-testid="restricted-permission-tooltip"
    position="top"
    tooltip={<RestrictedPermissionContent />}
    variant="light"
    {...props}
  >
    {children}
  </Tooltip>
);

export default RestrictedPermissionTooltip;

import styled from 'styled-components';
import {} from './Button.types';
export const pseudoSelectors = {
    active: '&:not([disabled]):active, &:not([disabled])[aria-expanded="true"], &:not([disabled])[data-active]',
    disabled: '&[disabled]',
    focus: '&:not([disabled]):focus',
    focusAndActive: '&:not([disabled]):focus:active, &:not([disabled])[aria-expanded="true"]:focus, &:not([disabled])[data-active]:focus',
    hover: '&:not([disabled]):hover',
};
export const getIconSizeForButton = (height) => {
    if (height <= 28)
        return 12;
    if (height <= 32)
        return 14;
    if (height <= 40)
        return 16;
    if (height <= 48)
        return 18;
    return 20;
};
export const StyleAButton = styled.button `
  width: fit-content;
  position: relative;
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  text-decoration: none;
  vertical-align: middle;
  border: none;
  outline: none;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid transparent;
  border-radius: 4px;
  color: var(--color-grey70);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: transparent;
  transition: background-color 80ms ease-in-out;

  ${(props) => props.size === 'small' &&
    `
  height: 32px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 7px;
  padding-bottom: 7px;
  `}

  ${(props) => props.size === 'default' &&
    `
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  `}

  ${(props) => props.size === 'large' &&
    `
  height: 56px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 16px;
  padding-bottom: 16px;`}

  &::-moz-focus-inner {
    border: 0;
  }

  ${pseudoSelectors.hover} {
    background-color: var(--color-grey20);
    color: var(--color-blue40);
  }

  ${pseudoSelectors.disabled} {
    background-color: var(--color-grey20);
    color: var(--color-grey70);
  }

  ${pseudoSelectors.active} {
    background-color: var(--color-blue20);
  }

  ${pseudoSelectors.disabled} {
    user-select: none;
    cursor: not-allowed;
  }
`;
export const StyleBButton = styled.button `
  width: fit-content;
  position: relative;
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  text-decoration: none;
  vertical-align: middle;
  outline: none;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid var(--color-grey20);
  border-radius: 4px;
  color: var(--color-grey70);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
  transition-property: color, border-color, box-shadow;
  transition-duration: 80ms;
  transition-timing-function: ease-in-out;
  box-shadow: 0px 0px 0px 4px transparent;

  ${(props) => props.size === 'small' &&
    `
  height: 32px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 7px;
  padding-bottom: 7px;
  `}

  ${(props) => props.size === 'default' &&
    `
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  `}

  ${(props) => props.size === 'large' &&
    `
  height: 56px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 16px;
  padding-bottom: 16px;`}

  &::-moz-focus-inner {
    border: 0;
  }

  ${pseudoSelectors.hover} {
    border: 1px solid var(--color-blue40);
    color: var(--color-blue40);
  }

  ${pseudoSelectors.disabled} {
    border: 1px solid var(--color-grey20);
    color: var(--color-grey70);
  }

  ${pseudoSelectors.focus} {
    border: 1px solid var(--color-blue20);
    color: var(--color-grey70);
    box-shadow: 0px 0px 0px 4px rgba(60, 101, 241, 0.16);
  }

  ${pseudoSelectors.active} {
    border: 1px solid var(--color-blue40);
    box-shadow: 0px 0px 0px 4px rgba(60, 101, 241, 0.16);
    color: var(--color-blue40);
  }

  ${pseudoSelectors.disabled} {
    user-select: none;
    cursor: not-allowed;
  }
`;
export const IconButton = styled(StyleAButton) `
  padding-left: 0;
  padding-right: 0;
`;
const segmentedControlButtonFocusStyle = `
border: 1px solid var(--color-blue40) !important;
background-color: var(--color-blue00);
color: var(--color-blue40);
box-shadow: none;
`;
export const SegmentedControlButton = styled(StyleAButton) `
  border: 1px solid var(--color-grey20) !important;
  background-color: var(--color-white);

  ${(props) => props.isActive && segmentedControlButtonFocusStyle}

  ${pseudoSelectors.hover} {
    background-color: var(--color-blue02);
    color: var(--color-blue40);
  }

  ${pseudoSelectors.active} {
    background-color: var(--color-blue00);
    color: var(--color-blue40);
    border: 1px solid var(--color-blue40) !important;
  }

  ${pseudoSelectors.disabled} {
    background-color: var(--color-blue02);
    color: var(--color-grey50);
    border: 1px solid var(--color-grey20) !important;
  }

  ${pseudoSelectors.focus} {
    ${segmentedControlButtonFocusStyle}
  }
`;

import _find from 'lodash/find';

import { isEqual, isObject, valueForKey } from 'helpers/utility';

/**
 * React select requires a function prop to derive the label and value from an option. This function binds the key
 * that this information can be found at within the object.
 * @param {string} optionKey
 * @returns {function(*=): *} - Returns a curried function
 */
export const handleGetOption = (optionKey) => (option) => valueForKey(option, optionKey);

/**
 * Given a select field's option set and current value, returns the value if it isn't an object.
 * If it is an object, returns the value property.
 * @param {Object[]} options
 * @param {*} value
 * @param {Object} params
 * @param {string} params.valueKey
 * @return {*}
 */
export const getAtomicValueForOption = (options, value, params) => {
  if (!isObject(value)) {
    return value;
  }

  const { valueKey } = params;

  const val = options.find((opt) => isEqual(opt[valueKey], value[valueKey]));

  return val?.[valueKey];
};

/**
 * When given an array of options, our <SelectField /> uses the ID of the object as the option value. When a user
 * selects one of the options, redux-form will dispatch the ID. However, if we want to dispatch the whole object
 * instead we need normalize using the function below.
 *
 * This function returns a function so we can bind the options and valueKey.
 * @example
 * const users = [
 *   { id: '1', text: 'Adam' },
 *   { id: '2', text: 'Emily' },
 * ];
 * <Field
 *   component={SelectField}
 *   normalize={normalizeFullObject(users, 'id')}
 * />
 * // User selects Adam, store holds { id: '1', text: 'Adam' }
 * // Without normalize, store would hold '1'
 * @param {Object[]} options - Options passed to the select
 * @param {string} valueKey - What part of the object is set on <option value={?} />
 * @returns {function(*=): *} - function which returns selected object
 */
export const normalizeFullObject = (options, valueKey) => (selection) =>
  _find(options, (option) => isEqual(valueForKey(option, valueKey), selection));

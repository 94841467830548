import React from 'react';

import { Image } from 'components';

import { ExternalAcceptPartnershipFlowState } from 'constants/ui';

import { oneOfValuesFromObject } from 'helpers/propTypes';
import { getAcceptPartnershipGraphicUrl } from 'helpers/ui';

/**
 * Renders SVG illustration based on external accept partnership flow state.
 * @param {ComponentProps} props
 * @param {string} props.acceptPartnershipState
 * @return {StatelessComponent}
 */
const AcceptPartnershipIllustrationBase = ({ acceptPartnershipState }) => (
  <Image alt={`${acceptPartnershipState} illustration`} src={getAcceptPartnershipGraphicUrl(acceptPartnershipState)} />
);

AcceptPartnershipIllustrationBase.propTypes = {
  acceptPartnershipState: oneOfValuesFromObject(ExternalAcceptPartnershipFlowState).isRequired,
};

export default AcceptPartnershipIllustrationBase;

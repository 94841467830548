import { combineReducers } from 'redux';

import allAnnotationLineItemsReducer from './allAnnotationLineItemsReducer';
import annotationLineItemsByIdReducer from './annotationLineItemsByIdReducer';

const annotationLineItemsReducer = combineReducers({
  allIds: allAnnotationLineItemsReducer,
  byId: annotationLineItemsByIdReducer,
});

export default annotationLineItemsReducer;

import { connect } from 'react-redux';

import { createEditHandler } from 'modules/external/ExternalCollectTaxInfo/helpers/actions';

import { externalCollectTaxInfoFormSelector } from 'selectors/forms';

import ConfirmLegalBusinessName from './ConfirmLegalBusinessName';

const mapStateToProps = (state) => {
  const formUI = externalCollectTaxInfoFormSelector(state, 'ui') || {};

  return {
    currentValue: externalCollectTaxInfoFormSelector(state, 'form.company.name'),
    editField: formUI.editLegalBusinessName,
  };
};

const mapDispatchToProps = {
  onEdit: createEditHandler('ui.editLegalBusinessName'),
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmLegalBusinessName);

import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { Icon, IconNames } from 'components';
import Spinner from 'components/buttonV2/components/Spinner';
import { DocumentStatuses } from 'constants/document';
import { colors } from 'constants/styles';
import { documentsIsDeleting } from 'selectors/documentsSelectors';
import {} from '../FilesList.types';
import { FileItemContainer, FileInput, FileIcon } from './FileItem.styles';
import {} from './FileItem.types';
export const File = ({ input, label, remove, index, isUploading, isDisabled }) => {
    const { isDeleting } = useSelector(documentsIsDeleting);
    const [isDeletingThisDoc, setIsDeletingThisDoc] = React.useState(isDeleting);
    React.useEffect(() => {
        if (!isDeleting) {
            setIsDeletingThisDoc(false);
        }
    }, [isDeleting]);
    const { value: { id = undefined, status = '', filename = '', canDelete = undefined, } = {}, } = input || {};
    const handleClickRemove = () => {
        remove(index, id);
        setIsDeletingThisDoc(true);
    };
    return (React.createElement(React.Fragment, null, (canDelete || isUploading) && (React.createElement(FileItemContainer, { uploaded: status === DocumentStatuses.PENDING, uploading: isUploading },
        React.createElement(FileInput, { ...input, "data-fullstory": true, placeholder: label, readOnly: isUploading, uploaded: canDelete || status === DocumentStatuses.PENDING, uploading: isUploading, value: filename }),
        isUploading && (React.createElement(FileIcon, { color: colors.colorWhiteHex, icon: IconNames.TICK_CIRCLE, isDeleting: isDeletingThisDoc, size: 14 })),
        isDeletingThisDoc && React.createElement(Spinner, { useBlueVariant: true }),
        !isUploading && !isDeletingThisDoc && (React.createElement("button", { "aria-label": "remove", disabled: isDisabled, onClick: handleClickRemove, type: "button" },
            React.createElement(Icon, { color: isDisabled ? colors.colorGreyMedHex : colors.colorBlueBoldHex, icon: IconNames.TRASH, size: 12 })))))));
};
const FileItem = ({ remove, file, index, isDisabled }) => (React.createElement(Field, { component: File, dataFullStory: true, index: index, isDisabled: isDisabled, name: `${file}`, remove: remove, type: "file" }));
export default FileItem;

import React from 'react';
import { ButtonV2 } from 'components';
import { ButtonAppearance, ButtonSize } from 'constants/button';
import { sizes } from 'constants/styles';
import { Intent } from 'constants/ui';
import {} from './ExternalBackButton.types';
import './ExternalBackButton.scss';
const ExternalBackButton = ({ onDecrementStep, ...rest }) => (React.createElement(ButtonV2, { appearance: ButtonAppearance.BORDERLESS, className: "external-button external-button--back", intent: Intent.NEUTRAL, leftIconClassName: "margin-right--xm", leftIconName: "arrow-left", leftIconSize: sizes.iconSizes.XX_LARGE, onClick: onDecrementStep, size: ButtonSize.LARGE, type: "button", ...rest },
    React.createElement("span", null, "Back")));
export default ExternalBackButton;

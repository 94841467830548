export const FETCH_TAX_INFO_FAILURE = 'FETCH_TAX_INFO_FAILURE';
export const FETCH_TAX_INFO_REQUEST = 'FETCH_TAX_INFO_REQUEST';
export const FETCH_TAX_INFO_SUCCESS = 'FETCH_TAX_INFO_SUCCESS';

export const FETCH_VENDOR_TAX_INFO_FAILURE = 'FETCH_VENDOR_TAX_INFO_FAILURE';
export const FETCH_VENDOR_TAX_INFO_REQUEST = 'FETCH_VENDOR_TAX_INFO_REQUEST';
export const FETCH_VENDOR_TAX_INFO_SUCCESS = 'FETCH_VENDOR_TAX_INFO_SUCCESS';

export const MODAL_CONFIRM_TAX_FILING_INFO_CLOSE = 'MODAL_CONFIRM_TAX_FILING_INFO_CLOSE';
export const MODAL_CONFIRM_TAX_FILING_INFO_OPEN = 'MODAL_CONFIRM_TAX_FILING_INFO_OPEN';

export const SUBMIT_TAX_INFO_FAILURE = 'SUBMIT_TAX_INFO_FAILURE';
export const SUBMIT_TAX_INFO_REQUEST = 'SUBMIT_TAX_INFO_REQUEST';
export const SUBMIT_TAX_INFO_SUCCESS = 'SUBMIT_TAX_INFO_SUCCESS';

import React from 'react';
import { Link } from 'react-router-dom';
import { BoxV2 } from 'components';
import { RESET_PASSWORD } from 'constants/routes';
import { asPath } from 'helpers/routeHelpers';
import ChangeWorkspace from '../ChangeWorkspace';
import EmailPasswordFormContainer from '../EmailPasswordForm';
const NoSSOLoginForm = () => (React.createElement(BoxV2, { className: "box-v2--container--login", subtitle: React.createElement(ChangeWorkspace, null), title: "Log in to your workspace" },
    React.createElement(EmailPasswordFormContainer, null),
    React.createElement("div", { className: "margin-top--large" },
        React.createElement(Link, { className: "font-sm semibold font-color--steel", to: asPath(RESET_PASSWORD) }, "Forgot your password?"))));
export default NoSSOLoginForm;

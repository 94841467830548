import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { openAddPartnerFundingAccountModal } from 'actions/funding';
import { openInternationalPaymentModal } from 'actions/modals';

import { openAddPartnerFundingAccountSidePanel } from 'helpers/sidePanels';

import { createItemIsBillViewSelector } from 'queries/createItemFormSelectors';

import { currentCompanySettingsIsInternationalPaymentsEnabledSelector } from 'selectors/currentCompanySelectors';

import PartnerPaymentMethod from './PartnerPaymentMethod';

const mapStateToProps = createStructuredSelector({
  isBillView: createItemIsBillViewSelector,
  isInternationalPaymentsEnabled: currentCompanySettingsIsInternationalPaymentsEnabledSelector,
});

const mapDispatchToProps = {
  onOpenAddPartnerFundingAccountModal: openAddPartnerFundingAccountModal,
  onOpenAddInternationalFundingAccountModal: openInternationalPaymentModal,
  onOpenAddPartnerFundingAccountSidePanel: openAddPartnerFundingAccountSidePanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerPaymentMethod);

import { fetchItemsRoutine, submitItemRoutine } from 'actions/routines/item';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import { CLEAR_APPROVALS } from 'types/approvals';
import {
  FETCH_ITEM_SUCCESS,
  SUBMIT_EXISTING_ITEMS_SUCCESS,
  UPDATE_EXISTING_ITEMS_SUCCESS,
  SEND_SUBMIT_ITEM_REQUEST,
} from 'types/item';

const approvalsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case fetchItemsRoutine.SUCCESS:
    case FETCH_ITEM_SUCCESS:
    case SEND_SUBMIT_ITEM_REQUEST:
    case submitItemRoutine.FULFILL:
    case SUBMIT_EXISTING_ITEMS_SUCCESS:
    case UPDATE_EXISTING_ITEMS_SUCCESS:
      return deepMergeWithArrayReplacement(
        state,
        getObjectsByIdWithRelationships(action.payload.itemSideApproval, ['membership']),
      );

    case CLEAR_APPROVALS: {
      return {};
    }

    default:
      return state;
  }
};

export default approvalsByIdReducer;

import * as types from 'types/funding';

export const initialState = {
  fundingAccountId: null,
  fundingSourceId: null,
  isFetching: false,
  lastSubmitted: null,
  open: false,
};

const verifyFundingAccountModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MODAL_VERIFY_FUNDING_ACCOUNT_CLOSE:
      // restrict the modal from closing while form is still submitting--
      // we want to display submission errors should any arise, and we
      // shouldn't destroy the associated redux form until we get an the api response
      return state.isFetching ? state : initialState;

    case types.MODAL_VERIFY_FUNDING_ACCOUNT_OPEN:
      return {
        ...state,
        fundingAccountId: action.payload.fundingAccountId,
        fundingSourceId: action.payload.fundingSourceId,
        open: true,
      };

    case types.SUBMIT_VERIFY_FUNDING_ACCOUNT_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case types.SUBMIT_VERIFY_FUNDING_ACCOUNT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case types.SUBMIT_VERIFY_FUNDING_ACCOUNT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default verifyFundingAccountModalReducer;

import { allKeys } from 'helpers/utility';

import * as types from 'types/transactions';

import initialState from './initialState';

export default (state = initialState.byItem, action) => {
  const { payload, type } = action;

  switch (type) {
    case types.FETCH_ITEM_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        [payload.itemId]: allKeys(payload.transaction),
      };

    default:
      return state;
  }
};

import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMembershipConfigRequest, fetchSingleMembershipRequest } from 'actions/memberships';
import { currentMembershipOrMembershipFromPartnershipRequestSelector } from 'queries/membershipCompoundSelectors';
import { currentUserSelector } from 'selectors/currentUserSelectors';
import { IntercomInternal } from './IntercomInternal.component';
export const IntercomWidget = () => {
    const currentMembership = useSelector(currentMembershipOrMembershipFromPartnershipRequestSelector);
    const currentUser = useSelector(currentUserSelector);
    const dispatch = useDispatch();
    const currentMembershipId = currentMembership?.id;
    const { REACT_APP_INTERCOM_APP_ID } = process.env;
    const payload = useMemo(() => {
        if (!REACT_APP_INTERCOM_APP_ID) {
            return null;
        }
        if (!currentMembership || !currentMembership?.id) {
            return {
                hide_default_launcher: true,
            };
        }
        if (!currentUser) {
            return null;
        }
        return {
            email: currentUser.email,
            hide_default_launcher: true,
            name: `${currentUser.firstName} ${currentUser.lastName}`.trim(),
            created_at: new Date(currentMembership.created).getTime(),
            user_hash: currentMembership.intercomHash,
            user_id: currentMembership.id,
        };
    }, [currentMembership, currentUser, REACT_APP_INTERCOM_APP_ID]);
    useEffect(() => {
        if (currentMembershipId && REACT_APP_INTERCOM_APP_ID) {
            dispatch(fetchMembershipConfigRequest(currentMembershipId));
            dispatch(fetchSingleMembershipRequest(currentMembershipId));
        }
    }, [currentMembershipId, dispatch, REACT_APP_INTERCOM_APP_ID]);
    if (!REACT_APP_INTERCOM_APP_ID || !payload?.user_hash) {
        return null;
    }
    return React.createElement(IntercomInternal, { appId: REACT_APP_INTERCOM_APP_ID, config: payload });
};

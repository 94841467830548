import { combineReducers } from 'redux';

import isSubmittingReducer from './isSubmittingReducer';
import lastSubmittedReducer from './lastSubmittedReducer';

const itemsSubmitReducer = combineReducers({
  isSubmitting: isSubmittingReducer,
  lastSubmitted: lastSubmittedReducer,
});

export default itemsSubmitReducer;

import React, { useMemo, useState } from 'react';
import { Renew } from '@carbon/icons-react';
import { useMutation } from '@tanstack/react-query';
import { queryContext } from '@routable/shared';
export const useToggleVendorTaxCollection = () => {
    const [taxCollectionStatus, setTaxCollectionStatus] = useState('enabled');
    const mutation = useMutation({
        context: queryContext,
        mutationFn: async (_id) => {
            await new Promise((r) => setTimeout(r, 500));
            const newStatus = taxCollectionStatus === 'enabled' ? 'disabled' : 'enabled';
            setTaxCollectionStatus(newStatus);
            return newStatus;
        },
    });
    const actionNode = useMemo(() => {
        if (taxCollectionStatus === 'disabled') {
            return (React.createElement(React.Fragment, null,
                React.createElement(Renew, null),
                " ",
                React.createElement("span", null, "Re-enable automated tax collection")));
        }
        return 'Exclude vendor from automated tax collection';
    }, [taxCollectionStatus]);
    return { actionNode, ...mutation };
};

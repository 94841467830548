import swal from 'sweetalert';

import { GENERIC_ERROR } from 'constants/error';
import { SwalButtonStyles } from 'constants/swal';

const errorAlert = (content, requestId, callback = () => {}, options = {}) => {
  swal({
    title: GENERIC_ERROR,
    className: 'swal-error',
    content,
    ...options,
    buttons: {
      support: {
        text: 'Contact support',
        value: { requestId },
        visible: true,
        className: SwalButtonStyles.GHOST_PRIMARY,
        closeModal: true,
      },
      close: {
        text: 'Close',
        value: null,
        visible: true,
        className: SwalButtonStyles.CANCEL,
        closeModal: true,
      },
    },
  }).then(callback);
};

export default errorAlert;

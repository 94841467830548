import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';
import { sendUpdatePaymentLinkRoutine } from 'actions/routines/partnership';

import { parsePartnerships as parse } from 'data/parse';

import * as types from 'types/partnership';

const byIdReducer = (state = {}, action) => {
  switch (action.type) {
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
      return parse.fundingAccounts.getPartnershipsFundingAccountsById(state, action.payload.fundingAccount);

    case types.FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_SUCCESS:
      return parse.fundingAccounts.getPartnershipsFundingAccountsById(state, action.payload.partnershipFundingAccount);

    case types.UPDATE_PARTNERSHIP_PRIMARY_RECEIVABLE_FUNDING_ACCOUNT:
      return parse.fundingAccounts.getPartnershipsFundingAccountsByIdAfterPrimaryUpdate(state, action.payload);

    case sendUpdatePaymentLinkRoutine.SUCCESS:
      return parse.fundingAccounts.getPartnershipFundingAccountsAfterSendUpdateLink(state, action.payload);

    default:
      return state;
  }
};

export default byIdReducer;

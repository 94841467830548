import fetch from 'isomorphic-fetch';
import PropTypes from 'prop-types';
import React from 'react';

import { newLineToBRTag } from 'helpers/stringHelpers';

class TextFileReader extends React.Component {
  state = {
    text: '',
  };

  componentDidMount() {
    this.readTextFile();
  }

  readTextFile = async () => {
    const { fileURL } = this.props;

    const response = await fetch(fileURL, {
      cache: 'no-cache',
      method: 'GET',
      mode: 'cors',
    });
    const textResponse = await response.text();

    if (!response.ok) {
      return;
    }

    this.setState({
      text: textResponse,
    });
  };

  render() {
    const { text } = this.state;

    return newLineToBRTag(text);
  }
}

TextFileReader.propTypes = {
  fileURL: PropTypes.string.isRequired,
};

export default TextFileReader;

import PropTypes from 'prop-types';
import React from 'react';

import { getClassNames } from 'helpers/ui';

/**
 * Renders an image with some sane, though very minimal, default styles.
 * @param {ComponentProps} props
 * @param {string} props.alt
 * @param {string} props.src
 * @return {StatelessComponent}
 */
const Image = ({ alt, src, style, ...rest }) => (
  <img
    alt={alt}
    className={getClassNames(rest, {
      'display--block': true,
      'max-width--full': true,
    })}
    src={src}
    style={style}
  />
);

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  style: PropTypes.shape(),
};

Image.defaultProps = {
  className: undefined,
  style: {},
};

export default Image;

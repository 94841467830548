import { ItemKinds, ItemKindsText } from 'constants/item';

import { getItemTransactionBaseText, isItemPayment } from 'helpers/items';
import { isUndef } from 'helpers/utility';

/**
 * Helper to get the thread item kind test based on an item
 * @param {Item} item
 * @returns {*}
 */
export const getThreadItemKind = (item) => {
  if (isItemPayment(item)) {
    return ItemKindsText[ItemKinds.PAYABLE];
  }

  return ItemKindsText[ItemKinds.RECEIVABLE];
};

/**
 * Helper to get the thread item title based on an item and creator
 * @param {Item} item
 * @param {Object} itemCreator - either a company or a partnership (for naming purposes)
 * @returns {string}
 */
export const getItemThreadTitle = (item, itemCreator) => {
  const baseTitle = `${getThreadItemKind(item)} from ${itemCreator.name}`;
  const transactionText = getItemTransactionBaseText(item);

  if (!transactionText) {
    return baseTitle;
  }

  return `${baseTitle} for ${transactionText}`;
};

/**
 * Helper to determine if a thread is fully loaded
 * @param {Thread} thread
 */
export const isThreadFullyLoaded = (thread) => !!thread && !isUndef(thread.messages);

import PropTypes from 'prop-types';
import React from 'react';

import { TitleWithSubtitle } from 'components';

import { PartnershipTypes } from 'constants/partnership';

import { AddedContactsSummaryTitle } from './components';

/**
 * Renders a confirmation icon and text to summarize contacts by showing the first contacts name and summarizing the
 * rest with a count.
 * @param {ComponentProps} props
 * @param {StringMaybe} [props.className]
 * @param {PartnershipMember[]} props.partnershipMembers
 * @param {PartnershipTypes} props.partnershipType
 * @returns {StatelessComponent}
 */
const AddedContactsSummaryTitleBlock = ({ className, partnershipMembers, partnershipType }) => (
  <TitleWithSubtitle
    className={className}
    titles={[
      <AddedContactsSummaryTitle
        key="added-contact-summary"
        partnershipMembers={partnershipMembers}
        partnershipType={partnershipType}
      />,
    ]}
  />
);

AddedContactsSummaryTitleBlock.propTypes = {
  className: PropTypes.string,
  partnershipMembers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  partnershipType: PropTypes.oneOf([PartnershipTypes.CUSTOMER, PartnershipTypes.VENDOR]).isRequired,
};

AddedContactsSummaryTitleBlock.defaultProps = {
  className: undefined,
};

export default AddedContactsSummaryTitleBlock;

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { currentCompanySettingsIntegrationSelector, currentCompanySettingsSelector, } from 'selectors/currentCompanySelectors';
import { fundingSupportedCountriesPaymentOptionsCurrencyList } from 'selectors/fundingSelectors';
import { ledgerIntegrationSelector } from 'selectors/integrationsSelectors';
import MultiLineOptionWithTooltipForMergeCompanies from './MultiLineOptionWithTooltipForMergeCompanies';
export const mapStateToProps = createStructuredSelector({
    companyIntegrationSettings: currentCompanySettingsIntegrationSelector,
    companySettings: currentCompanySettingsSelector,
    ledger: ledgerIntegrationSelector,
    supportedCurrencies: fundingSupportedCountriesPaymentOptionsCurrencyList,
});
export default connect(mapStateToProps)(MultiLineOptionWithTooltipForMergeCompanies);

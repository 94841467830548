import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const SelectLabel = ({ isFocused, label, name, isRequired }) => (
  <label
    className={classNames('select--label', {
      focused: !!isFocused,
    })}
    htmlFor={name}
  >
    {label}
    {isRequired && (
      <span className="text-blue-40 pl-1" data-testid={`${name}:required`}>
        *
      </span>
    )}
  </label>
);

SelectLabel.propTypes = {
  isFocused: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
};

SelectLabel.defaultProps = {
  isFocused: undefined,
  label: undefined,
  isRequired: undefined,
};

export default SelectLabel;

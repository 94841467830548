import PropTypes from 'prop-types';
import React from 'react';

import BaseHint from 'components/hintTypes/BaseHint';
import { textHelpers } from 'components/hintTypes/helpers';

import { partnershipMemberContactFormFields } from 'constants/formFields';
import { Intent } from 'constants/ui';

import { hasLength } from 'helpers/utility';

import { ConfirmAssociateEmailWithMultipleCompanies } from './components';

const EmailAssociatedWithOtherCompaniesHint = (props) => {
  const { associatedCompanies, className, errors, fieldName, isChecked, onChange } = props;

  if (!hasLength(associatedCompanies)) {
    return null;
  }

  return (
    <BaseHint
      className={className}
      content={
        <ConfirmAssociateEmailWithMultipleCompanies
          errors={errors}
          fieldName={fieldName}
          isChecked={isChecked}
          onChange={onChange}
        />
      }
      contentProps={{
        className: 'margin-top--m',
      }}
      intent={Intent.WARNING}
      multiline
      title={textHelpers.getEmailAssociatedWithOtherCompaniesHintTitle(associatedCompanies)}
    />
  );
};

EmailAssociatedWithOtherCompaniesHint.propTypes = {
  associatedCompanies: PropTypes.arrayOf(PropTypes.shape()),
  className: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  fieldName: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

EmailAssociatedWithOtherCompaniesHint.defaultProps = {
  associatedCompanies: [],
  errors: undefined,
  className: undefined,
  fieldName: partnershipMemberContactFormFields.META_KNOWN_PARTNERSHIP_IDS,
  isChecked: undefined,
};

export default EmailAssociatedWithOtherCompaniesHint;

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ItemStatuses } from 'enums/items';

import { itemFromQuerySelector } from 'queries/itemRouterSelectors';

import ExternalPromptSingleItemFailed from '../global/presenters/ExternalPromptSingleItemFailed';

import ExternalPromptCustomerSingleItemCanceled from './ExternalPromptCustomerSingleItemCanceled';
import ExternalPromptCustomerSingleItemConfirmation from './ExternalPromptCustomerSingleItemConfirmation';
import ExternalPromptCustomerSingleItemExternallyPaid from './ExternalPromptCustomerSingleItemExternallyPaid';
import ExternalPromptCustomerSingleItemPending from './ExternalPromptCustomerSingleItemPending';

const ExternalPromptCustomerSingleItem = ({ item, onStart }) => {
  switch (item.status) {
    case ItemStatuses.EXTERNALLY_PAID:
      return <ExternalPromptCustomerSingleItemExternallyPaid />;

    case ItemStatuses.NEW:
      return <ExternalPromptCustomerSingleItemPending onStart={onStart} />;

    case ItemStatuses.PROCESSING:
    case ItemStatuses.INITIATED:
    case ItemStatuses.COMPLETED:
      return <ExternalPromptCustomerSingleItemConfirmation />;

    case ItemStatuses.CANCELED:
      return <ExternalPromptCustomerSingleItemCanceled />;

    case ItemStatuses.ISSUE:
    case ItemStatuses.FAILED:
      return <ExternalPromptSingleItemFailed />;

    default:
      return null;
  }
};

ExternalPromptCustomerSingleItem.propTypes = {
  item: PropTypes.shape().isRequired,
  onStart: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  item: itemFromQuerySelector,
});

export { ExternalPromptCustomerSingleItem };
export default connect(mapStateToProps)(ExternalPromptCustomerSingleItem);

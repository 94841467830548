import styled from 'styled-components';
export const Container = styled.div `
  width: 113px;
  margin: 60px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const DropzoneCaption = styled.span `
  display: block;
  font-size: var(--font-size-2);
  line-height: var(--line-height-4);
  color: var(--color-grey70);

  &.drag-drop-caption {
    margin-top: 10px;
  }

  &.browse-files-caption {
    font-weight: var(--font-weight-medium);
    color: var(--color-blue40);
  }

  &.file-types {
    margin-top: 40px;
    font-size: var(--font-size-1);
    color: var(--color-grey50);
  }
`;

import React from 'react';
import { FlexCol, Text } from 'components';
import { typography } from 'constants/styles';
import {} from './ExternalReceiptItemEntry.types';
import './ExternalReceiptItemEntry.scss';
const ExternalReceiptItemEntry = ({ label, value }) => {
    if (!value) {
        return null;
    }
    return (React.createElement(FlexCol, { className: "external-receipt-item-entry" },
        React.createElement(Text.Regular, { size: typography.TextSize.LEVEL_200 }, label),
        React.createElement(Text.Semibold, { color: typography.TextColor.BLACK, size: typography.TextSize.LEVEL_200 }, value)));
};
export default ExternalReceiptItemEntry;

import PropTypes from 'prop-types';
import React from 'react';

import { BreadcrumbsTextVariant } from 'constants/breadcrumbs';

import { allValues, hasZeroLength, isLastIndex, secondLastElementIn } from 'helpers/utility';

import { ActionNavBackButton } from '../ActionNav';

import { Breadcrumb } from './components';

import './Breadcrumbs.scss';

/**
 * Given an list of links, show a user's navigational history.
 * @param {ComponentProps} props
 * @param {Object[]} [props.links=[]]
 * @param {BreadcrumbsTextVariant} variant
 * @return {null|StatelessComponent}
 */
const Breadcrumbs = ({ links, variant }) => {
  if (hasZeroLength(links)) {
    return null;
  }

  // the defaultBackUrl will always be the second link from the end
  const { url: defaultBackUrl } = secondLastElementIn(links) || {};

  return (
    <nav aria-label="breadcrumbs" className="nav-breadcrumbs">
      <ol className="display--flex align-items--center">
        {/* This has display:none; if not in mobile view */}
        {defaultBackUrl && (
          <li className="breadcrumb-li back-button">
            <ActionNavBackButton defaultBackURL={defaultBackUrl} />
          </li>
        )}

        {links.map((link, index) => {
          const { label } = link;
          const key = `${label}-${index}`;

          return <Breadcrumb isLastBreadCrumb={isLastIndex(links, index)} key={key} link={link} variant={variant} />;
        })}
      </ol>
    </nav>
  );
};

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      state: PropTypes.shape(),
      url: PropTypes.string,
    }),
  ),
  variant: PropTypes.oneOf(allValues(BreadcrumbsTextVariant)),
};

Breadcrumbs.defaultProps = {
  links: [],
  variant: BreadcrumbsTextVariant.DEFAULT,
};

export default Breadcrumbs;

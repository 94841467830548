import React from 'react';
import { Field, Form } from 'redux-form';
import { resetPasswordHandler } from 'actions/routines/auth';
import { FormControl, IconNames, ButtonV2, BoxV2, ReduxFormFieldRenderInput } from 'components';
import { ButtonSize } from 'constants/button';
import { formNamesAuthV2 } from 'constants/forms';
import { PLATFORM_EMAILS } from 'constants/platform';
import { sizes } from 'constants/styles';
import { field } from 'constants/styles/formStyles';
import { emailValidator, requiredValidator } from 'helpers/fieldValidation';
import {} from './ResetPasswordForm.types';
import './ResetPasswordForm.scss';
const ResetPasswordForm = ({ handleSubmit, isSubmitting }) => (React.createElement(BoxV2, { className: "box-v2--container--reset-password", title: "Reset your password" },
    React.createElement("p", { className: "font-m font-color--greyXDark text-align--center remove-margin-bottom margin-top--sm" }, `You might need to check your spam folder or make sure
             ${PLATFORM_EMAILS.NO_REPLY} isn't blocked.`),
    React.createElement(Form, { className: "form padding-top--l", id: formNamesAuthV2.RESET_PASSWORD, onSubmit: handleSubmit(resetPasswordHandler) },
        React.createElement(FormControl, null,
            React.createElement(Field, { className: field.xl.full, component: ReduxFormFieldRenderInput, dataFullStory: true, name: "form.email", placeholder: "Email", type: "email", validate: [requiredValidator, emailValidator] })),
        React.createElement(FormControl, { className: "remove-margin-bottom" },
            React.createElement(ButtonV2, { htmlFor: formNamesAuthV2.RESET_PASSWORD, id: "auth_resetPassword_btn", isLoading: isSubmitting, rightIconName: IconNames.ARROW_RIGHT, rightIconProps: { style: { marginLeft: 'auto' } }, rightIconSize: sizes.iconSizes.LARGE, size: ButtonSize.LARGE }, "Send link to reset my password")))));
export default ResetPasswordForm;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ssoHandleRedirectRoutine } from 'actions/routines/auth';
import { BrandWrapperV2, RoutableHead } from 'components';
import { getCompanyNamespaceFromUrl, getDomainWithOrWithoutCompanyNamespace } from 'helpers/urls';
import { useLoginIdp } from 'hooks/useLoginIdP';
import { Help } from 'modules/app/components';
import { DashboardNotificationBar } from 'modules/dashboard/components/Dashboard/components';
import { IsLoadingHasWrapper } from 'modules/isLoading';
import { authNamespaceValueSelector } from 'selectors/forms';
import { NamespaceForm, NoSSOLoginForm, OptionalSSOLoginForm, RequiredSSOLoginForm } from './components';
const Login = ({ authNamespaceHasSubmitSucceeded, currentSSOSettings, isSSOFetching, isSSORedirecting, onFetchCompanySettingsSSOByNamespace, }) => {
    const namespace = useSelector(authNamespaceValueSelector);
    const companyNamespace = getCompanyNamespaceFromUrl();
    const dispatch = useDispatch();
    const { idp, companyId } = useLoginIdp();
    React.useEffect(() => {
        if (companyNamespace && !isSSORedirecting) {
            onFetchCompanySettingsSSOByNamespace({
                params: { namespace: companyNamespace },
            });
        }
    }, [companyNamespace, isSSORedirecting, onFetchCompanySettingsSSOByNamespace]);
    React.useEffect(() => {
        if (idp) {
            const options = { authorizationParams: { login_from: 'idp' } };
            dispatch(ssoHandleRedirectRoutine.trigger({ options, companyId }));
        }
    }, [companyId, dispatch, idp]);
    if (authNamespaceHasSubmitSucceeded && namespace) {
        const lastPathSection = window.location.pathname.split('/').pop();
        const urlUpdate = `${getDomainWithOrWithoutCompanyNamespace(namespace)}/${lastPathSection}`;
        window.location.replace(urlUpdate);
        return null;
    }
    if ((companyNamespace && isSSOFetching) || isSSORedirecting) {
        return React.createElement(IsLoadingHasWrapper, null);
    }
    let formComponent;
    if (currentSSOSettings?.ssoRequired) {
        formComponent = React.createElement(RequiredSSOLoginForm, null);
    }
    else if (currentSSOSettings && !currentSSOSettings.ssoRequired) {
        formComponent = React.createElement(OptionalSSOLoginForm, null);
    }
    else if (!companyNamespace && !authNamespaceHasSubmitSucceeded) {
        formComponent = React.createElement(NamespaceForm, null);
    }
    else {
        formComponent = React.createElement(NoSSOLoginForm, null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(RoutableHead, { noIndex: true }),
        React.createElement(Help, null),
        React.createElement(BrandWrapperV2, null,
            React.createElement(DashboardNotificationBar, { className: "dashboard-notification-bar--brand-page" }),
            React.createElement("div", { className: "branded--contents remove-margin" }, formComponent))));
};
export default Login;

import { submitInvoicesRoutine, submitItemEditRoutine, submitItemRoutine } from 'actions/routines/item';

import { createIsSubmittingReducer } from 'store/redux';

import * as types from 'types/item';

export const finishCases = [
  types.CLEAR_SUBMITTED_ITEM,
  types.SUBMIT_EXISTING_ITEMS_FAILURE,
  types.SUBMIT_EXISTING_ITEMS_SUCCESS,
  submitItemEditRoutine.FAILURE,
  submitItemEditRoutine.SUCCESS,
  submitItemRoutine.FAILURE,
  submitItemRoutine.FULFILL,
  submitInvoicesRoutine.FAILURE,
  submitInvoicesRoutine.SUCCESS,
];

export const requestCases = [
  types.SUBMIT_EXISTING_ITEMS_REQUEST,
  submitItemEditRoutine.REQUEST,
  submitItemRoutine.REQUEST,
  submitInvoicesRoutine.REQUEST,
];

const isSubmittingReducer = createIsSubmittingReducer(finishCases, requestCases);

export default isSubmittingReducer;

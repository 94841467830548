import PropTypes from 'prop-types';
import React from 'react';

import { Icon, IconNames } from 'components';

import { colorDarkJordanHex, colorMainJordan } from 'constants/styles/colors';

import StatusLabel from './StatusLabel';

// TODO | SUF: Move this component to a generic location in /components so it can be used by Guide and Review

const GuideSectionList = ({
  children,
  iconName,
  listKey,
  listState,
  onToggleList,
  title,
  showStatusLabel = true,
  status,
}) => {
  const isListVisible = listState[listKey]?.open;
  const listClasses = ['guide-section-list'];
  let chevronDirection = IconNames.CHEVRON_RIGHT;

  if (isListVisible) {
    listClasses.push('highlighted');
    chevronDirection = IconNames.CHEVRON_DOWN;
  }

  return (
    <div className={listClasses.join(' ')}>
      <div className="guide-section-list--header" onClick={() => onToggleList(listKey)} role="presentation">
        <Icon className="header-icon" color={colorMainJordan} icon={iconName} size={18} title="" />
        <h3 className="font-color--greyXDark bold">{title}</h3>

        <div className="chevron-div">
          {showStatusLabel && <StatusLabel status={status} />}
          <Icon className="chevron" color={colorDarkJordanHex} icon={chevronDirection} title="" />
        </div>
      </div>

      {isListVisible && <div className="guide-section-list--body">{children}</div>}
    </div>
  );
};

GuideSectionList.propTypes = {
  children: PropTypes.node.isRequired,
  iconName: PropTypes.string.isRequired,
  listKey: PropTypes.string.isRequired,
  listState: PropTypes.shape().isRequired,
  onToggleList: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  showStatusLabel: PropTypes.bool,
  status: PropTypes.string,
};

GuideSectionList.defaultProps = {
  showStatusLabel: true,
  status: undefined,
};

export default GuideSectionList;

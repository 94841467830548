import { ZIndexLayers } from 'constants/ui';

import { getZIndex } from 'helpers/zIndex';

export const sidePanelStyles = {
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
  },
  sidebar: {
    zIndex: getZIndex(ZIndexLayers.SLIDEOUT.SIDEBAR),
    position: 'fixed',
    top: 0,
    bottom: 0,
    transition: 'transform .3s ease-out',
    willChange: 'transform',
    overflowY: 'auto',
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch',
    transition: 'left .3s ease-out, right .3s ease-out',
  },
  overlay: {
    zIndex: getZIndex(ZIndexLayers.SLIDEOUT.OVERLAY),
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity .3s ease-out, visibility .3s ease-out',
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  dragHandle: {
    position: 'fixed',
    top: 0,
    bottom: 0,
  },
};

export const sidePanelWithOverlayStyles = {
  ...sidePanelStyles,
  content: {
    ...sidePanelStyles.content,
    zIndex: getZIndex(ZIndexLayers.SLIDEOUT_PRIME.VIEW_CONTENT),
  },
  overlay: {
    ...sidePanelStyles.overlay,
    zIndex: getZIndex(ZIndexLayers.SLIDEOUT_PRIME.OVERLAY),
  },
  sidebar: {
    ...sidePanelStyles.sidebar,
    zIndex: getZIndex(ZIndexLayers.SLIDEOUT_PRIME.SIDEBAR, 1),
  },
};

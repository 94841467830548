import { combineReducers } from 'redux';

import itemTagLinksReducer from './itemTagLinksReducer';
import tagsReducer from './tagsReducer';

const taggingReducer = combineReducers({
  // tags are standalone objects with an id and a name, generally added by a membership user
  tags: tagsReducer,
  // tagLinks connect tags with other objects like, in this reducer, items
  itemTagLinks: itemTagLinksReducer,
});

export default taggingReducer;

import styled, { css } from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-grey20);
  height: var(--spacing-11);

  &.routable-click {
    pointer-events: initial;
    touch-action: initial;
    cursor: pointer;
    & * {
      pointer-events: initial;
      touch-action: initial;
      cursor: pointer;
    }
  }

  &.routable-click:hover {
    background-color: var(--color-grey05);
    border-bottom: 1px solid var(--color-grey30);
  }

  ${({ isDisabled = false }) => {
    if (isDisabled) {
        return css `
        background-color: var(--color-grey05);
        &.routable-click {
          cursor: default;
          & * {
            cursor: default;
          }
        }
      `;
    }
}}
`;

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { itemIdQuerySelector } from 'selectors/routerSelectors';

import ExternalPrompt from './ExternalPrompt';

const mapStateToProps = createStructuredSelector({
  itemId: itemIdQuerySelector,
});

const enhance = compose(connect(mapStateToProps));

export default enhance(ExternalPrompt);

import PropTypes from 'prop-types';
import React from 'react';

import { FlexCol, FlexRow, Text } from 'components';

import { typography } from 'constants/styles';

import { parseCurrency } from 'helpers/numbers';

import { ExternalBlockItem } from 'modules/external/ExternalBlocksSection';

/**
 * Component rendering single External Receipt Line Item
 * @param {ComponentProps} props
 * @param {LineItem} props.lineItem
 * @returns {StatelessComponent}
 */
const ExternalReceiptLineItem = ({ lineItem }) => {
  if (!lineItem.description) {
    return null;
  }

  return (
    <ExternalBlockItem>
      <FlexRow className="align-items--center justify-content--space-between" stackOnMobile={false}>
        <FlexCol>
          <Text.Semibold color={typography.TextColor.BLACK} size={typography.TextSize.LEVEL_100}>
            {lineItem.description}
          </Text.Semibold>

          {Boolean(lineItem.unitPrice) && (
            <FlexRow className="external-receipt-line-items__item-details" stackOnMobile={false}>
              <Text.Regular size={typography.TextSize.LEVEL_100}>
                {`Quantity: ${parseInt(lineItem.quantity, 10)}`}
              </Text.Regular>

              <Text.Regular size={typography.TextSize.LEVEL_100}>
                {`Rate: ${parseCurrency(lineItem.unitPrice)}`}
              </Text.Regular>
            </FlexRow>
          )}
        </FlexCol>

        {Boolean(lineItem.amount) && (
          <Text.Regular color={typography.TextColor.BLACK} size={typography.TextSize.LEVEL_200}>
            {parseCurrency(lineItem.amount)}
          </Text.Regular>
        )}
      </FlexRow>
    </ExternalBlockItem>
  );
};

ExternalReceiptLineItem.propTypes = {
  lineItem: PropTypes.shape({
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    description: PropTypes.string,
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    unitPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export default ExternalReceiptLineItem;

import _cloneDeep from 'lodash/cloneDeep';

import { fetchedLedgerPartnershipsRelationships } from 'constants/relationships';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';
import { allKeys, hasZeroLength, isGreaterThanZero } from 'helpers/utility';

export const getLedgerPartnershipsById = (ledgerPartnerships, singular = false) => {
  const partnershipList = getObjectsByIdWithRelationships(ledgerPartnerships, fetchedLedgerPartnershipsRelationships);

  if (!singular || hasZeroLength(allKeys(partnershipList))) {
    return partnershipList;
  }

  // Return a single partnership if desired
  const partnershipId = allKeys(partnershipList)[0];
  return partnershipList[partnershipId];
};

export const handleFetchLedgerPartnershipsSuccess = (state, action) => {
  // Append to the state when paginating
  if (isGreaterThanZero(action.payload.meta.pagination.offset)) {
    return deepMergeWithArrayReplacement(state, getLedgerPartnershipsById(action.payload.partnership));
  }

  // Otherwise, replace the current state
  return getLedgerPartnershipsById(action.payload.partnership);
};

export const handleFetchSingleLedgerPartnershipsSuccess = (state, action) =>
  deepMergeWithArrayReplacement(state, getLedgerPartnershipsById(action.payload.partnership));

export const handleSubmitPlatformPartnershipSuccess = (state, action) => {
  // If a Platform partnership is unskipped and we're on the ledger side we need to update it here too
  const partnership = getLedgerPartnershipsById(action.payload.partnership, true);

  if (state[partnership.id]) {
    return deepMergeWithArrayReplacement(state, getLedgerPartnershipsById(action.payload.partnership));
  }

  return state;
};

export const handleSubmitPartnershipSuccess = (state, action) => {
  // The ledger partnership is indexed by PartnershipLedger ids not partnership ids
  // Since a new one is now added, we can remove the old partnership ledger id from the state
  const newState = _cloneDeep(state);
  const newPartnership = getLedgerPartnershipsById(action.payload.partnership, true);
  const { customerLedgerId, vendorLedgerId } = newPartnership;
  if (customerLedgerId) {
    delete newState[customerLedgerId];
  }

  if (vendorLedgerId) {
    delete newState[vendorLedgerId];
  }

  return newState;
};

export const handleSubmitLedgerPartnershipMergeSuccess = (state, action) => {
  // If a Ledger partnership is merged to an existing partnership the PartnershipLedger id would exist in
  // both the state and the partnership object. We need to mark it as non-ledger only to remove from unmatched.
  const newState = _cloneDeep(state);
  const newPartnership = getLedgerPartnershipsById(action.payload.partnership, true);
  const { customerLedgerId, vendorLedgerId } = newPartnership;

  if (newState[customerLedgerId]) {
    newState[customerLedgerId].isLedgerOnly = false;
  }

  if (newState[vendorLedgerId]) {
    newState[vendorLedgerId].isLedgerOnly = false;
  }

  return newState;
};

import { text } from 'modules/address/constants';

export const formLabels = {
  APPLY_TO_NO_SET: 'Update payables that have not initiated',
  APPLY_TO_PENDING_START: 'Update',
  APPLY_TO_PENDING_END: 'payables with no payment method, and initiate payment',
  SHOW_PRINT_CHECK: text.editCheckNameField,
  SET_PRIMARY: 'Set as vendor primary payment method',
  SET_PRIMARY_INTERNATIONAL: (currencyCode) =>
    `Make this the vendor's primary payment method for receiving ${currencyCode} payments.`,
};

export const texts = {
  FORM_REPLACE_ACCOUNTS_INFO: 'Changes will affect all payments, including those with the status:',
  FORM_REPLACE_ACCOUNTS_PLACEHOLDER: 'Apply change to',
  OPTIONS_HEADER_TEXT: 'Advanced options',
  SELECT_GROUP_HEADING_ADDRESS: 'Payables with an address set (for checks)',
  SELECT_GROUP_HEADING_BANK_ACCOUNTS: 'Payables with a bank account set',
};

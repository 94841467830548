import PropTypes from 'prop-types';
import React from 'react';

import PaymentMethodHintBase from '../PaymentMethodHintBase';

import { getHintLabelText } from './helpers';

/**
 * Renders hint displaying information about current payment method
 * in the partnership associated with the external workflow.
 * @param {ComponentProps} props
 * @param {FundingAccount} props.fundingAccount
 * @param {FundingInfoAddress} props.fundingInfoAddress
 * @param {boolean} props.isPaymentMethodUpdated
 * @return {StatelessComponent}
 */
const CurrentPaymentMethodHint = ({ fundingAccount, fundingInfoAddress, isPaymentMethodUpdated }) => {
  const labelText = getHintLabelText({
    fundingAccount,
    isPaymentMethodUpdated,
  });

  return (
    <PaymentMethodHintBase fundingAccount={fundingAccount} fundingInfoAddress={fundingInfoAddress} label={labelText} />
  );
};

CurrentPaymentMethodHint.propTypes = {
  fundingAccount: PropTypes.shape({}),
  fundingInfoAddress: PropTypes.shape({}),
  isPaymentMethodUpdated: PropTypes.bool,
};

CurrentPaymentMethodHint.defaultProps = {
  fundingAccount: undefined,
  fundingInfoAddress: undefined,
  isPaymentMethodUpdated: undefined,
};

export default CurrentPaymentMethodHint;

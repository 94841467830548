import _cloneDeep from 'lodash/cloneDeep';

import { submitItemRoutine } from 'actions/routines/item';

import { PAYMENTS_LIST_FILTERS, PAYMENTS_LIST_TABS, itemKindToTabMap } from 'constants/routes';

import { isItemValidForTabFilter } from 'helpers/itemRouterHelpers';
import { isItemStatusNeedsApproval } from 'helpers/items';
import { getQueryParam } from 'helpers/queryParams';
import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { allValues, and, isEqual, valueForFirstKey } from 'helpers/utility';

import { itemPaginationCountSelector, itemPaginationCurrentSelector } from 'selectors/itemsSelectors';

const paginationMiddleware = () => (store) => (next) => (action) => {
  const { meta = {}, payload, type } = action;

  const isItemFulfilled = and(isEqual(type, submitItemRoutine.FULFILL), payload?.item);

  if (isItemFulfilled) {
    const { item, itemSideApproval } = payload;

    // the new item's key (id) is dynamic and unknown in advance
    const itemData = valueForFirstKey(item);

    if (itemData) {
      const { attributes = {}, relationships } = itemData;

      // our middleware function is called immediately after dispatching,
      // before any new state is computed by the reducers
      const state = store.getState();

      // get existing pagination count
      const paginationCount = itemPaginationCountSelector(state);
      const paginationCurrent = itemPaginationCurrentSelector(state);

      if (paginationCurrent) {
        const location = new URL(paginationCurrent);
        const paginationKind = getQueryParam('kind', location);
        const paginationStatus = getQueryParam('status', location);

        // set some sensible defaults
        let tab = PAYMENTS_LIST_TABS.PAYABLES;
        let filter = PAYMENTS_LIST_FILTERS.ALL;

        // if we have a kind query, assign the tab
        if (paginationKind) {
          tab = itemKindToTabMap[paginationKind];
        }

        // if we have a status query, assign the filter
        if (paginationStatus) {
          filter = paginationStatus;
        }

        if (isItemStatusNeedsApproval(attributes)) {
          attributes.approvals = relationships.approvals.data.map((approval) => approval.id);
        }

        // this middleware is hit before the reducer is updated by the action
        // so we need to mimic what the approvals reducer does to store the itemSideApprovals
        const itemSideApprovals = getObjectsByIdWithRelationships(itemSideApproval, ['membership']);
        const allApprovals = allValues(itemSideApprovals);

        // if this item is valid for the current pagination...
        if (
          isItemValidForTabFilter({
            item: attributes,
            tab,
            filter,
            allApprovals,
          })
        ) {
          // ... we'll clone any existing meta (setting a default value of `{}` for routable)
          // and then attach some additional metadata--
          const newMeta = _cloneDeep(meta);
          newMeta.routable = { ...(newMeta.routable || {}) };
          newMeta.routable.pagination = {
            ...(newMeta.routable.pagination || {}),
            count: paginationCount + 1,
          };

          const newAction = _cloneDeep(action);
          newAction.meta = newMeta;

          // forward the action with updated metadata (all other properties identical)
          return next(newAction);
        }
      }
    }
  }

  return next(action);
};

export default paginationMiddleware;

import { call, spawn, take } from 'redux-saga/effects';

import * as routines from 'actions/routines/roles';

import { createSaga } from 'helpers/saga';

import * as api from './api';

/**
 * Handle fetching all roles
 * @returns {IterableIterator<*>}
 */
export function* fetchRoles() {
  yield call(createSaga, api.fetchRoles, routines.fetchRolesRoutine);
}

/**
 * Listens for redux actions related to roles.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([routines.fetchRolesRoutine.TRIGGER]);

    switch (action.type) {
      case routines.fetchRolesRoutine.TRIGGER:
        yield spawn(fetchRoles, action);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root roles saga.
 * @return {IterableIterator<*>}
 */
export default function* roles() {
  yield watch();
}

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { externalOnboardingDecrementStep } from 'actions/externalOnboarding';

import { ButtonV2, IconNames } from 'components';

import { ButtonSize } from 'constants/button';
import { colors, sizes } from 'constants/styles';

import { externalPaymentFormSelector } from 'selectors/forms';

const PaymentMethodSubmitButton = (props) => {
  const { disableFormSubmit, formButtonText, formId, formUI, isSubmitting } = props;

  if (formUI.showKYC) {
    return null;
  }

  return (
    <div className="form-control">
      <ButtonV2
        htmlFor={formId}
        isDisabled={disableFormSubmit}
        isLoading={isSubmitting}
        leftIconClassName="margin-right--m"
        leftIconColor={colors.colorWhiteHex}
        leftIconName={IconNames.TICK_CIRCLE}
        leftIconSize={sizes.iconSizes.LARGE}
        rightIconColor={colors.colorWhiteHex}
        rightIconName={IconNames.ARROW_RIGHT}
        rightIconProps={{ style: { marginLeft: 'auto' } }}
        rightIconSize={sizes.iconSizes.LARGE}
        size={ButtonSize.LARGE}
        type="submit"
      >
        {formButtonText}
      </ButtonV2>
    </div>
  );
};

PaymentMethodSubmitButton.propTypes = {
  disableFormSubmit: PropTypes.bool.isRequired,
  formId: PropTypes.string.isRequired,
  formButtonText: PropTypes.string.isRequired,
  formUI: PropTypes.shape(),
  // used in helper method
  /* eslint-disable-next-line react/no-unused-prop-types */
  fundingAccount: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  // used in helper method
  /* eslint-disable-next-line react/no-unused-prop-types */
  paymentDeliveryMethod: PropTypes.string,
};

PaymentMethodSubmitButton.defaultProps = {
  formUI: {},
  fundingAccount: undefined,
  paymentDeliveryMethod: undefined,
};

const mapStateToProps = (state) => ({
  formUI: externalPaymentFormSelector(state, 'formUI'),
  fundingAccount: externalPaymentFormSelector(state, 'fundingAccount'),
  paymentDeliveryMethod: externalPaymentFormSelector(state, 'paymentDeliveryMethod'),
});

const mapDispatchToProp = {
  onDecrementStep: externalOnboardingDecrementStep,
};

export { PaymentMethodSubmitButton };
export default connect(mapStateToProps, mapDispatchToProp)(PaymentMethodSubmitButton);

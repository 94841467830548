import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const ModalBodyContent = ({ className, children }) => (
  <div
    className={classNames({
      'modal-body--content': true,
      [className]: !!className,
    })}
  >
    {children}
  </div>
);

ModalBodyContent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ModalBodyContent.defaultProps = {
  className: undefined,
};

export default ModalBodyContent;

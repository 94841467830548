import classNames from 'classnames';
import React from 'react';
import { Text } from 'components/text';
import { typography } from 'constants/styles';
import {} from 'constants/taxes';
import { Intent } from 'constants/ui';
import { isLastIndex } from 'helpers/utility';
import BaseHint from '../BaseHint';
import { penaltiesOfPerjurySections } from './PenaltiesOfPerjuryHint.data';
export const Title = 'Under Penalties of perjury, I certify that:';
const PenaltiesOfPerjuryHint = ({ taxDoc }) => {
    const perjurySections = penaltiesOfPerjurySections[taxDoc];
    if (!perjurySections) {
        return null;
    }
    return (React.createElement(BaseHint, { content: perjurySections.map(({ id, text }, idx) => (React.createElement(Text.Regular, { className: classNames('display--inline-block', {
                'margin-bottom--m': !isLastIndex(perjurySections, idx),
            }), color: typography.TextColor.BLUE_DARK, key: id }, text))), contentProps: { className: 'margin-top--sm' }, intent: Intent.INFO, isCollapsed: true, isCollapsible: true, title: Title }));
};
export default PenaltiesOfPerjuryHint;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './InfoCard.scss';

/**
 * Rounded-corner, white background card with predefined styling for the top and bottom sections.
 * @param {object} children
 * @param {string} [className]
 * @param {Boolean} [hasShadow]
 * @param {object} [style]
 * @returns {StatelessComponent}
 * @constructor
 */
const InfoCard = ({ children, className, hasShadow, style }) => (
  <div
    className={classNames({
      'info-card': true,
      'without-box-shadow': !hasShadow,
      [className]: !!className,
    })}
    style={style}
  >
    {children}
  </div>
);

InfoCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hasShadow: PropTypes.bool,
  style: PropTypes.shape(),
};

InfoCard.defaultProps = {
  className: undefined,
  hasShadow: true,
  style: {},
};

export default InfoCard;

import { Tooltip } from '@routable/components';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';
import { IconNames } from 'components/icon';
import { Text } from 'components/text';

import { ButtonAppearance } from 'constants/button';
import { colors, sizes, typography } from 'constants/styles';

/**
 * Button used for re-syncing ledger data.
 * @param {String} className
 * @param {Boolean} isDisabledDueToPermissions
 * @param {Boolean} isFetchingIntegrationConfig
 * @param {Object} ledgerIntegration
 * @param {Function} onRefreshIntegrationConfig
 * @param {React.Fragment} PermissionsTooltip
 * @param {Boolean} shouldDisplayText
 * @return {StatelessComponent}
 */
const IntegrationLedgerRefresh = ({
  className,
  isDisabledDueToPermissions,
  isFetchingIntegrationConfig,
  ledgerIntegration,
  onRefreshIntegrationConfig,
  InsufficientPermissionsTooltip,
  shouldDisplayText,
}) => {
  const button = (
    <ButtonV2
      appearance={ButtonAppearance.BORDERLESS}
      className={className}
      isDisabled={isDisabledDueToPermissions || isFetchingIntegrationConfig || ledgerIntegration.isRefreshing}
      leftIconColor={shouldDisplayText ? colors.colorGreyDarkHex : colors.colorWhiteHex}
      leftIconName={IconNames.REFRESH}
      leftIconSize={sizes.iconSizes.MEDIUM}
      onClick={() => onRefreshIntegrationConfig(ledgerIntegration.id)}
    >
      {shouldDisplayText && (
        <Text.Bold
          className="margin-left--xm"
          color={typography.TextColor.GREY_XX_DARK}
          size={typography.TextSize.LEVEL_100}
        >
          Re-sync connection
        </Text.Bold>
      )}
    </ButtonV2>
  );

  return isDisabledDueToPermissions ? (
    <InsufficientPermissionsTooltip>{button}</InsufficientPermissionsTooltip>
  ) : (
    <Tooltip className="inline-flex" tooltip="Re-sync connection">
      {button}
    </Tooltip>
  );
};

IntegrationLedgerRefresh.propTypes = {
  className: PropTypes.string,
  isDisabledDueToPermissions: PropTypes.bool,
  isFetchingIntegrationConfig: PropTypes.bool,
  ledgerIntegration: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isRefreshing: PropTypes.bool,
  }).isRequired,
  onRefreshIntegrationConfig: PropTypes.func.isRequired,
  InsufficientPermissionsTooltip: PropTypes.oneOfType([PropTypes.func, PropTypes.symbol]),
  shouldDisplayText: PropTypes.bool,
};

IntegrationLedgerRefresh.defaultProps = {
  className: undefined,
  isDisabledDueToPermissions: undefined,
  isFetchingIntegrationConfig: undefined,
  InsufficientPermissionsTooltip: React.Fragment,
  shouldDisplayText: undefined,
};

export default IntegrationLedgerRefresh;

import _omit from 'lodash/omit';

import { caProvinces, usStates } from 'constants/i18n';

import { AddressFormat } from 'enums/addresses';
import { googlePlacesAttrs, googlePlacesAttrsToAddressFields } from 'enums/google';

import { isEqual } from './utility';

/**
 * Format address from place returned by google api
 * @param {object} place
 * @returns {Object}
 */
export const formatAddressFromPlace = (place) => {
  const newAddress = {};

  // We need to keep track of the street number as it has to be displayed
  // in one field with the street name
  let streetNumber = null;

  // Set the values for fields from API response
  place.address_components.forEach((component) => {
    // attr name (e.g. street_number) from places API response
    const attrName = component.types[0];
    // attr value from place API response in chosen format (e.g. 'short_name')
    const attrValue = component[googlePlacesAttrs[attrName]];
    // choosing field to be updated
    const fieldName = googlePlacesAttrsToAddressFields[attrName];

    if (attrName === 'street_number') {
      streetNumber = attrValue;
    }

    if (fieldName) {
      let value;

      // If it is the street and there is street number we concatenate them
      if (attrName === 'route' && streetNumber) {
        value = `${streetNumber} ${attrValue}`;
      } else {
        value = attrValue;
      }

      newAddress[fieldName] = value;
    }
  });

  if (!newAddress?.city) {
    const addressComponentsByProp = place.address_components.reduce(
      (obj, component) => ({
        ...obj,
        [component.types[0]]: component,
      }),
      {},
    );

    if (addressComponentsByProp.sublocality_level_1) {
      newAddress.city = addressComponentsByProp.sublocality_level_1[googlePlacesAttrs.locality];
    }
  }

  return newAddress;
};

/**
 * Returns US states or CA provinces, based on given address
 * @param {Object} address
 * @returns {Object[]}
 */
export const getStatesByCountry = (address) => {
  if (!address) {
    return usStates;
  }

  if (address.country === 'CA') {
    return caProvinces;
  }

  return usStates;
};

/**
 * Returns whether the given address has all required fields
 * @param {Object} address
 * @returns {boolean}
 */
export const isFullAddress = (address) =>
  !!(address.city && address.country && address.postalcode && address.state && address.streetAddress);

/**
 * Returns true if given format is AddressFormat.FULL
 * @param {AddressFormat} format
 * @returns {Boolean}
 */
export const isAddressFormatFull = (format) => isEqual(format, AddressFormat.FULL);

/**
 * Returns true if given format is AddressFormat.CITY_AND_COUNTRY
 * @param {AddressFormat} format
 * @returns {Boolean}
 */
export const isAddressFormatCityAndCountry = (format) => isEqual(format, AddressFormat.CITY_AND_COUNTRY);

/**
 * Returns true if given format is AddressFormat.FULL_COUNTRY
 * @param {AddressFormat} format
 * @returns {Boolean}
 */
export const isAddressFormatFullCountry = (format) => isEqual(format, AddressFormat.FULL_COUNTRY);

/** Given an address returns city state and ZIP, ex. `San Francisco, CA 80294` */
export const getCityStateAndPostal = (address) => `${address.city}, ${address.state} ${address.postalcode}`;

/** Given an address returns street address and unit, ex. `123 Main St, Unit 2B` */
export const getStreetAddressAndUnit = (address) => {
  if (address.streetAddressUnit) {
    return `${address.streetAddress}, ${address.streetAddressUnit}`;
  }

  return address.streetAddress;
};

/**
 * Returns either the address as a string, or undefined if address isn't given.
 * @param {ObjectMaybe} address
 * @param {ObjectMaybe} options
 * @returns {StringMaybe}
 */
export const getAddressAsString = (address, options = {}) => {
  if (!address) {
    return undefined;
  }

  const { format } = options;
  const { city, country } = address;

  if (isAddressFormatCityAndCountry(format)) {
    return `${city}, ${country}`;
  }

  let addressString = getStreetAddressAndUnit(address);

  addressString += `, ${getCityStateAndPostal(address)}`;

  if (isAddressFormatFullCountry(format)) {
    addressString += ` ${country}`;
  }

  return addressString;
};

/**
 * Determine what label to apply to the form's state input based on the country selection.
 * @param {object} [address]
 * @param {string} [address.country]
 * @returns {string}
 */
export const getStateOrProvinceLabelByCountry = (address) => {
  if (address && address.country === 'CA') {
    return 'Province';
  }

  return 'State';
};

/**
 * Returns a message to display when an invalid country is selected in a form.
 * @param {Object} [options={}]
 * @param {string} [options.contactName]
 */
export const getInvalidCountrySelectedMessage = (options = {}) => {
  const { contactName } = options;

  let message = 'Currently, we only support addresses located in the USA, Canada, and US territories.';

  if (contactName) {
    message += ` If you do not have a business address in one of our supported regions, please contact ${contactName}.`;
  }

  return message;
};

/**
 * Given a full mailing address entity, it removes the system properties from
 * that entity and returns only address valeus
 * @param {MailingAddress} address
 * @returns {Address}
 */
export const getAddressValuesFromAddressEntity = (address) =>
  _omit(address, ['id', 'created', 'createdByUserFullName', 'lastUpdatedByUserFullName', 'modified']);

// singular modal names
export const modalNameAddEmailForward = 'addEmailForward';

export const modalNameFundingProviderMemo = 'fundingProviderMemo';

export const modalNameAddPartnerFundingAccount = 'addPartnerFundingAccount';

export const modalNameBalanceTransaction = 'balanceTransaction';

export const modalNameBankAccountDetails = 'bankAccountDetails';

export const modalNameConnectBankManual = 'connectBankManual';

export const modalNameConnectBankToken = 'connectBankToken';

export const modalNameConfirmTaxFilingInfo = 'confirmTaxFilingInfo';

export const modalNameCreateApiToken = 'createApiToken';

export const modalNameCreateItem = 'createItem';

export const modalNameCreateManualAddress = 'createManualAddress';

export const modalNameDeactivateTeamMember = 'deactivateTeamMember';

export const modalNameExportCsv = 'exportCsv';

export const modalNameForceManualLedgerSync = 'forceManualLedgerSync';

export const modalNameMatchClearingAccount = 'matchClearingAccount';

export const modalNameMaintenanceMode = 'maintenanceMode';

export const modalNameMatchPlatformPartnership = 'matchPlatformPartnership';

export const modalNameMergeLedgerPartnership = 'mergeLedgerPartnership';

export const modalNameOldVersionMode = 'oldVersionMode';

export const modalNameSSODisable = 'ssoDisable';

export const modalNameSSOSetupForm = 'ssoSetupForm';

export const modalNameSSOSetupSplashscreen = 'ssoSetupSplashscreen';

export const modalNameUpdatePartnerFundingAccount = 'updatePartnershipPaymentMethod';

export const modalNameVerifyFundingAccount = 'verifyFundingAccount';

export const modalNameWebhookTest = 'webhooksTest';

export const modalAddInternationalPayment = 'addInternationalPayment';

export const modalIntegrationSyncOptions = 'integrationSyncOptions';

// modal name objects
export const modalNamesPartnership = {
  CREATE: 'createPartnership',
  MERGE: 'mergeLedgerPartnership',
};

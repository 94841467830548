import { MODAL_MATCH_CLEARING_ACCOUNT_OPEN, SUBMIT_CLEARING_ACCOUNTS_MATCH_SUCCESS } from 'types/clearingAccounts';

const lastSubmittedReducer = (state = null, action) => {
  switch (action.type) {
    case MODAL_MATCH_CLEARING_ACCOUNT_OPEN:
      return null;

    case SUBMIT_CLEARING_ACCOUNTS_MATCH_SUCCESS:
      return new Date();

    default:
      return state;
  }
};

export default lastSubmittedReducer;

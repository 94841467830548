import { getClassNames } from 'helpers/ui';

/**
 * Generate a className based on the fieldClassName prop passed to InternationalAddressForm and each form element's
 * own size.
 *
 * @param {ComponentProps} props
 * @param {string} fieldSize
 * @returns {string}
 */
export const getFieldClassNames = (props, fieldSize) =>
  getClassNames(props, { [fieldSize]: true }, { classProp: 'fieldClassName' });

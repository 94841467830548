import React from 'react';
import ReactDOM from 'react-dom';
const createTeleporter = ({ fallbackChild, multiSources } = {}) => {
    const context = {};
    const sourceContext = {};
    const setElement = (element) => {
        context.value = element;
        if (context.set && context.set.current) {
            context.set.current(element);
        }
    };
    const setSourceElement = (element) => {
        sourceContext.value = element;
    };
    const useTargetRef = () => setElement;
    const Target = ({ as: As = 'div' }) => {
        const [children, setChildren] = React.useState(fallbackChild);
        const handleRef = useTargetRef();
        React.useLayoutEffect(() => {
            const updateTarget = () => {
                if (!sourceContext.value) {
                    setChildren(fallbackChild);
                }
                else {
                    setChildren(null);
                }
            };
            if (!sourceContext.updateTarget) {
                sourceContext.updateTarget = updateTarget;
            }
            updateTarget();
            return () => {
                sourceContext.updateTarget = undefined;
            };
        }, []);
        return React.createElement(As, { ref: handleRef }, children);
    };
    const Source = ({ children }) => {
        const [elementRef, setElementRef] = React.useState(null);
        React.useLayoutEffect(() => {
            const setRef = { current: setElementRef };
            let previousSet;
            if (context.set) {
                previousSet = context.set;
                if (!multiSources) {
                    context.set.current(null);
                }
            }
            context.set = setRef;
            setElementRef(context.value);
            setSourceElement(children);
            sourceContext.updateTarget?.();
            return () => {
                setRef.current = null;
                context.set = null;
                sourceContext.value = null;
                if (elementRef) {
                    sourceContext.updateTarget?.();
                }
                if (previousSet && previousSet.current) {
                    context.set = previousSet;
                    context.set.current(context.value);
                }
            };
        }, [children, elementRef]);
        if (!elementRef) {
            return null;
        }
        return ReactDOM.createPortal(children, elementRef);
    };
    return { Source, Target, useTargetRef };
};
export default createTeleporter;

import PropTypes from 'prop-types';
import React from 'react';

import { ButtonSize } from 'constants/button';
import { Intent } from 'constants/ui';

import ModalFooterButton from './ModalFooterButton';

/**
 * ModalFooterButtonCancel
 * @param {ComponentProps} props
 * @param {String} [props.buttonSize]
 * @param {String} [props.buttonText]
 * @param {Function} props.onCloseModal
 * @param {RestOfProps} rest
 * @returns {StatelessComponent}
 */
const ModalFooterButtonCancel = ({ buttonSize, buttonText, onCloseModal, ...rest }) => (
  <ModalFooterButton
    buttonClasses="hover--danger"
    id="modalFooter_cancel_btn"
    intent={Intent.NEUTRAL}
    onClick={onCloseModal}
    size={buttonSize}
    type="button"
    {...rest}
  >
    {buttonText}
  </ModalFooterButton>
);

ModalFooterButtonCancel.propTypes = {
  buttonSize: PropTypes.string,
  buttonText: PropTypes.string,
  dataTestId: PropTypes.string,
  onCloseModal: PropTypes.func.isRequired,
};

ModalFooterButtonCancel.defaultProps = {
  buttonSize: ButtonSize.MEDIUM,
  buttonText: 'Cancel',
  dataTestId: undefined,
};

export default ModalFooterButtonCancel;

import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';

const RedirectExternal = ({ path, to, exact }) => (
  <Route
    component={() => {
      window.location = to;
      return null;
    }}
    exact={exact}
    path={path}
  />
);

RedirectExternal.propTypes = {
  path: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  exact: PropTypes.bool,
};

export default RedirectExternal;

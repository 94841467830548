import {} from 'axios';
import { fetchItemsRoutine } from 'actions/routines/item';
import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { getPaymentsEndpoint } from 'services/api/paymentsEndpoint';
import { paymentRoutine } from './payment';
import {} from './paymentCancel.types';
const TRIGGER = `PAYMENT_CANCEL/TRIGGER`;
const SUCCESS = `PAYMENT_CANCEL/SUCCESS`;
const FAILURE = `PAYMENT_CANCEL/FAILURE`;
const success = (data) => [
    { type: SUCCESS, payload: data },
    paymentRoutine.success(data),
    fetchItemsRoutine.trigger(),
];
const failure = (data) => ({
    type: FAILURE,
    error: data,
});
const trigger = ({ id, payload }) => ({
    type: TRIGGER,
    payload: {
        id,
        endpoint: `${getPaymentsEndpoint({ paymentId: id })}cancel/`,
        method: 'POST',
        requireAuth: true,
        onSuccess: success,
        onFailure: failure,
        payload: payloadToJSONAPI(payload, 'payment'),
        setRequester: true,
        headers: {},
    },
});
export const paymentCancelRoutine = {
    TRIGGER,
    trigger,
    SUCCESS,
    success,
    FAILURE,
    failure,
};

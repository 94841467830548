import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { AsyncInput } from 'components';

import { inviteTeamMemberFormFields } from 'constants/formFields';

import { trimString } from 'helpers/fieldNormalizers';
import { inviteTeamMemberFormValidators } from 'helpers/fieldValidation';

/**
 * An input field which checks with the BE to ensure that the email entered is valid to be invited as a RCTM.
 * @param {ComponentProps} props
 * @param {StringMaybe} [props.className]
 * @returns {StatelessComponent}
 */
const InviteTeamMemberFormEmailField = ({ className, placeholder }) => (
  <Field
    className={className}
    component={AsyncInput}
    dataFullStory
    maxLength={100}
    name={inviteTeamMemberFormFields.EMAIL}
    normalize={trimString}
    placeholder={placeholder || 'Email address'}
    validate={inviteTeamMemberFormValidators.EMAIL}
  />
);

InviteTeamMemberFormEmailField.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

InviteTeamMemberFormEmailField.defaultProps = {
  className: undefined,
  placeholder: '',
};

export default InviteTeamMemberFormEmailField;

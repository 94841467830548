import { combineReducers } from 'redux';

import isFetching from './isFetchingReducer';
import objectUrl from './objectUrlReducer';

const filesReducer = combineReducers({
  isFetching,
  objectUrl,
});

export default filesReducer;

import classNames from 'classnames';
import React from 'react';
import { Field } from 'redux-form';
import { ButtonV2, BoxV2, FormControl, IconNames, ReduxFormFieldRenderInput, Form, LinkNewPage } from 'components';
import { ButtonSize } from 'constants/button';
import { formNamesAuthV2 } from 'constants/forms';
import { PLATFORM_EMAILS } from 'constants/platform';
import { formStyles, sizes } from 'constants/styles';
import { trimString } from 'helpers/fieldNormalizers';
import { requiredValidator, slugValidator, subdomainValidator } from 'helpers/fieldValidation';
import { getBrandedWorkspacesDisplayDomain } from 'helpers/urls';
import submit from './helpers/submit';
import {} from './NamespaceForm.types';
const NamespaceForm = ({ handleSubmit, isSSOFetching }) => (React.createElement(BoxV2, { className: "box-v2--container--login", title: "Enter your workspace name" },
    React.createElement(Form, { id: formNamesAuthV2.NAMESPACE, onSubmit: handleSubmit(submit) },
        React.createElement(FormControl, null,
            React.createElement(Field, { className: `${formStyles.field.xl.full} namespace`, component: ReduxFormFieldRenderInput, dataFullStory: true, name: "namespace", normalize: trimString, placeholder: "workspace-name", type: "text", validate: [requiredValidator, slugValidator, subdomainValidator] },
                React.createElement("div", { className: "inline-label inline-right white-background" },
                    React.createElement("span", { className: "font-color--steel font-weight--regular" }, `.${getBrandedWorkspacesDisplayDomain()}`)))),
        React.createElement("div", { className: "margin-top--m-large" },
            React.createElement(ButtonV2, { htmlFor: formNamesAuthV2.NAMESPACE, id: "namespace_continue_btn", isLoading: isSSOFetching, rightIconName: IconNames.ARROW_RIGHT, rightIconProps: { style: { marginLeft: 'auto' } }, rightIconSize: sizes.iconSizes.LARGE, size: ButtonSize.LARGE, type: "submit" }, "Continue")),
        React.createElement("div", { className: "margin-top--large" },
            React.createElement("span", { className: classNames({
                    'font-sm': true,
                    'font-weight--regular': true,
                    'font-color--grey--xx-dark': true,
                    'margin-right--sm': true,
                }) }, `Don't have an account?`),
            React.createElement(LinkNewPage, { className: classNames({
                    'dark-blue': true,
                    'font-sm': true,
                    'font-color--grey--xx-dark': true,
                    'margin-right--sm': true,
                }), href: `mailto:${PLATFORM_EMAILS.SALES}` }, "Contact sales")))));
export default NamespaceForm;

import { submit } from 'redux-form';

import { formNamesIntegrations } from 'constants/forms';

import * as types from 'types/currentCompany';

export const externalPaymentGetCurrentCompanyRequest = () => ({
  type: types.EXTERNAL_PAYMENT_GET_CURRENT_COMPANY_REQUEST,
});

export const getCurrentCompanyRequest = () => ({
  type: types.GET_CURRENT_COMPANY_REQUEST,
});

export const getCurrentCompanySuccess = (payload, meta) => ({
  meta,
  payload,
  type: types.GET_CURRENT_COMPANY_SUCCESS,
});

export const getCurrentCompanyFailure = (errors) => ({
  payload: { errors },
  type: types.GET_CURRENT_COMPANY_FAILURE,
});

export const updateCurrentCompanyReset = () => ({
  type: types.UPDATE_CURRENT_COMPANY_RESET,
});

export const submitIntegrationSyncOptionsForm = () => submit(formNamesIntegrations.INTEGRATION_SYNC_OPTIONS);

import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';

import { ERROR, SUCCESS } from 'constants/routes';

import { getJoinedPath } from 'helpers/routeHelpers';

import {
  CollectTaxForm,
  CollectTaxHeader,
  CollectTaxSidebar,
  ConfirmTaxInfoModal,
  ReconfirmTaxesSection,
} from './components';

/**
 * Collect mailing address and filing details for a vendor. Route users to the success page if they've already confirmed
 * taxes for this partnership.
 * @param {boolean} alreadyCollectedForDifferentPartnership - In this case, they'll just reconfirm their entry; nothing
 * is editable. However, they are required to re-enter their TIN and consent to eDelivery.
 * @param {boolean} collectedForThisPartnership
 * @param {object} location - from router
 * @param {object} partnerMember
 * @param {string} submissionDate
 * @returns {StatelessComponent}
 * @constructor
 */
const CollectTaxInfoPage = ({
  alreadyCollectedForDifferentPartnership,
  collectedForThisPartnership,
  location,
  submissionDate,
}) => {
  const { pathname, search } = location;
  const handleFailure = () => <Redirect to={getJoinedPath(pathname, ERROR, search)} />;
  const handleSuccess = () => <Redirect to={getJoinedPath(pathname, SUCCESS, search)} />;

  if (collectedForThisPartnership) {
    return handleSuccess();
  }

  // we've already collected taxes with another partnership, but haven't confirm for this partnership.
  // the user needs to consent for 1099 eDelivery for each partnership
  const isMultiPartnerConfirm = !collectedForThisPartnership && alreadyCollectedForDifferentPartnership;

  // We override the .partner-company-form--container's 600px max-width. This max-width is appropriate for a form,
  // but not for a page, as we'reusing here. As a matter of fact, this class has a default max-width of 720px, but
  // not when nested in the partner-company-form--wrapper
  const overridePartnerCompanyFormContainerMaxWidth = { maxWidth: '720px' };

  return (
    <div className="external-onboarding-form--container">
      <div className="content--left">
        <div className="partner-company-form--wrapper">
          <div className="partner-company-form--container" style={overridePartnerCompanyFormContainerMaxWidth}>
            <CollectTaxHeader />
            {isMultiPartnerConfirm && <ReconfirmTaxesSection />}
            {!isMultiPartnerConfirm && <CollectTaxForm />}
          </div>
        </div>
      </div>

      <div className="content--right">
        <div className="external-tax--sidebar">
          <CollectTaxSidebar />
        </div>
      </div>

      <ConfirmTaxInfoModal
        isConfirm={isMultiPartnerConfirm}
        onFailure={handleFailure}
        onSuccess={handleSuccess}
        submissionDate={submissionDate}
      />
    </div>
  );
};

CollectTaxInfoPage.propTypes = {
  alreadyCollectedForDifferentPartnership: PropTypes.bool,
  collectedForThisPartnership: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  submissionDate: PropTypes.string,
};

CollectTaxInfoPage.defaultProps = {
  alreadyCollectedForDifferentPartnership: undefined,
  collectedForThisPartnership: undefined,
  submissionDate: undefined,
};

export default CollectTaxInfoPage;

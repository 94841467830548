import { combineReducers } from 'redux';

import allIds from './allIdsReducer';
import byId from './byIdReducer';
import isFetching from './isFetchingReducer';

const transactionAchsReducer = combineReducers({
  allIds,
  byId,
  isFetching,
});

export default transactionAchsReducer;

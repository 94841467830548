import { ItemKinds } from 'constants/item';

export const InvoiceAwaitingPaymentDirection = {
  RECEIVABLES: 'receivables',
  PAYABLES: 'payables',
};

export const InvoiceTypes = {
  [ItemKinds.PAYABLE]: 'payable',
  [ItemKinds.RECEIVABLE]: 'receivable',
};

export const SendInvoiceOptions = {
  SINGLE_ENTITY: 'invoiceToCustomer',
  MULTIPLE_ENTITIES: 'invoicesAwaitingPayment',
  BULK_UPLOAD: 'bulkUpload',
};

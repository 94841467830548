import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { PopupContainer } from './combobox.component.styled';
import React from 'react';
import {} from './combobox.component.types';
import { ComboBoxFetch } from './combobox.fetch.component';
import { Spinner } from '@routable/gross-ds';
export const ComboBoxPopupContainer = ({ domRef, position, yOffset = 0, type = 'select', width, height, domId, loadOptions, getOptionValue, getOptionLabel, value, options, selectId, startingFetchString, predictive, }) => {
    const containerRef = useRef(null);
    const [loadingClassName, setLoadingClassName] = useState('');
    useEffect(() => {
        if (type === 'modal') {
            setTimeout(() => {
                setLoadingClassName('combobox-container-active');
            }, 1);
        }
        else {
            if (domRef && containerRef?.current) {
                const domRect = domRef.getBoundingClientRect();
                const container = containerRef.current;
                const scrollTop = window.scrollY || document.documentElement.scrollTop;
                container.style.left = `${position.x}px`;
                container.style.minWidth = `${width ? `${width}px` : `${domRect.width}px`}`;
                container.style.bottom = `${window.innerHeight - position.y + domRect.height + yOffset + scrollTop}px`;
                setTimeout(() => {
                    setLoadingClassName('combobox-container-active');
                    if (position.x + container.clientWidth > window.innerWidth) {
                        container.style.left = `${position.x + position.width - container.clientWidth}px`;
                    }
                }, 1);
            }
        }
    }, [domRef, position]);
    return createPortal(React.createElement(PopupContainer, { type: type, height: "44px", "data-popover": true, className: loadingClassName, ref: containerRef, onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
        } }, !loadingClassName ? (React.createElement(Spinner, { size: "s" })) : (React.createElement(ComboBoxFetch, { domId: domId, width: width, height: height, value: value, selectId: selectId, startingFetchString: startingFetchString, predictive: predictive, getOptionValue,
        getOptionLabel,
        options,
        loadOptions }))), document.body);
};

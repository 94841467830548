import { createSelector } from 'reselect';
import {} from 'reducers/quickswitchReducer';
const getState = (state) => state.quickswitch;
export const loadingMembershipsListSelector = createSelector([getState], (state) => state.loadingMembershipsList);
export const membershipsSelector = createSelector([getState], (state) => state.memberships);
export const isModalOpenSelector = createSelector([getState], (state) => state.isModalOpen);
export const companyNameSelector = createSelector([getState], (state) => state.companyName);
export const isErrorModalOpenSelector = createSelector([getState], (state) => state.isErrorModalOpen);
export const searchTermSelector = createSelector([getState], (state) => state.searchTerm);
export const totalNumberOfMembershipsSelector = createSelector([getState], (state) => state.totalNumberOfMemberships);

import { BulkActionsImportTypes } from 'constants/bulkActions';
import { ItemKinds } from 'constants/item';
import { bulkImportSubmitFinalizeItemRelationships, requesterRelationship } from 'constants/relationships';
import { FetchExportType } from 'constants/routes';

import { REST } from 'helpers/api';
import { getFileDownloadRequestOptions } from 'helpers/fileHelpers';

import { FetchService } from 'services';
import {
  bulkActionsEndpoint,
  bulkImportEndpoint,
  getBulkImportDownloadCsvTemplateEndpoint,
  getBulkImportEndpoint,
  getBulkImportFinalizeEndpoint,
  getBulkImportForIdEndpoint,
} from 'services/api/bulkImportEndpoints';
import * as formatHelpers from 'services/api/formatHelpers';

/**
 * Download CSV template file
 * @return {Promise<*>}
 */
export const downloadCsvTemplateFile = async () => {
  const config = {
    endpoint: getBulkImportDownloadCsvTemplateEndpoint(ItemKinds.PAYABLE),
    method: 'GET',
    setRequester: true,
    requireAuth: true,
    ...getFileDownloadRequestOptions(FetchExportType.CSV),
  };

  return FetchService.request(config);
};

/**
 * Fetch bulk import status.
 * @param {string} id
 * @param {Object} [options={}]
 * @return {String}
 */
export const getSingleBulkImport = async (id, options = {}) =>
  REST.get({
    ...options,
    endpoint: getBulkImportForIdEndpoint(id),
    requireAuth: true,
  });

/**
 * Fetch all bulk imports
 * @param {object} params
 * @param {object} cancelSource
 * @return {Promise<*>}
 */
export const getAllBulkImports = async (params, cancelSource) => {
  const config = {
    endpoint: getBulkImportEndpoint(params),
    method: 'GET',
    requireAuth: true,
    cancelToken: cancelSource.token,
  };

  return FetchService.request(config);
};

/**
 * Sends finalizing call to create items in bulk.
 * @param {Payload} payload
 * @return {Promise<*>}
 */
export const finalizeBulkImportItems = async (payload) =>
  REST.post({
    endpoint: getBulkImportFinalizeEndpoint(payload.id),
    payload: formatHelpers.payloadToJSONAPI(payload, 'BulkImport', ...bulkImportSubmitFinalizeItemRelationships),
    requireAuth: true,
  });

/**
 * Sends api call to bulk update items.
 * @param {Payload} payload
 * @return {Promise<*>}
 */
export const finalizeBulkAction = async (payload) =>
  REST.post({
    endpoint: bulkActionsEndpoint,
    payload: formatHelpers.payloadToJSONAPI(payload, 'BulkAction', requesterRelationship),
    requireAuth: true,
  });

/**
 * Upload bulk import file
 * @param {Payload} payload
 * @param {string} fileBase64
 * @return {Promise<*>}
 */
export const uploadBulkImportFile = async (payload, fileBase64) => {
  const { name: filename } = payload;

  const config = {
    endpoint: bulkImportEndpoint,
    payload: formatHelpers.payloadToJSONAPI(
      {
        file: fileBase64,
        filename,
        import_type: BulkActionsImportTypes[ItemKinds.PAYABLE],
      },
      'BulkImport',
    ),
    method: 'POST',
    setRequester: true,
    requireAuth: true,
  };

  return FetchService.request(config);
};

/**
 * Delete bulk items
 * @param {Payload} payload
 * @return {Promise<*>}
 */
export const deleteBulkItems = async (payload) => {
  const config = {
    endpoint: bulkActionsEndpoint,
    payload: formatHelpers.payloadToJSONAPI(payload, 'BulkAction', requesterRelationship),
    method: 'POST',
    setRequester: true,
    requireAuth: true,
  };

  return FetchService.request(config);
};

import { TruncatableText } from '@routable/components';
import PropTypes from 'prop-types';
import React from 'react';

import { getClassNames } from 'helpers/ui';

/**
 * Table header cell component for the invoice generator ledger field tables.
 * @param {ComponentProps} props
 * @param {*} props.children
 * @param {RestOfProps} rest
 * @return {StatelessComponent}
 */
const InvoiceGeneratorTableHeaderCell = ({ children, ...rest }) => (
  <div
    {...rest}
    className={getClassNames(rest, {
      'table-row--column': true,
      'remove-padding': true,
    })}
  >
    <div className="column-content">
      <TruncatableText position="top" text={children} />
    </div>
  </div>
);

InvoiceGeneratorTableHeaderCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

InvoiceGeneratorTableHeaderCell.defaultProps = {
  children: undefined,
  className: 'table-cell--width-8',
};

export default InvoiceGeneratorTableHeaderCell;

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { modalNameVerifyFundingAccount } from 'constants/modals';

import { VerifyFundingAccountFormModal } from 'modules/connectBank/verifyFundingAccount/components';

import { modalsSelector } from 'selectors/modalsSelector';

const ExternalModals = ({ modals }) => modals[modalNameVerifyFundingAccount].open && <VerifyFundingAccountFormModal />;

ExternalModals.propTypes = {
  modals: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  modals: modalsSelector(state),
});

export { ExternalModals };
export default connect(mapStateToProps)(ExternalModals);

import PropTypes from 'prop-types';
import React from 'react';

import CompanyRep from 'modules/external/global/presenters/CompanyRep';

import { getCompanyRepFromRequesterOrFirstPartnershipMember } from './helpers/logic';

const ExternalPromptHeader = ({ partnershipPartnerMembers, requester, showRequester, text }) => {
  const companyRep = getCompanyRepFromRequesterOrFirstPartnershipMember(requester, partnershipPartnerMembers);

  return (
    <React.Fragment>
      {showRequester && companyRep && <CompanyRep representative={companyRep} />}
      <h1 className="dark">{text}</h1>
    </React.Fragment>
  );
};

ExternalPromptHeader.propTypes = {
  partnershipPartnerMembers: PropTypes.arrayOf(PropTypes.shape()),
  requester: PropTypes.shape(),
  showRequester: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

ExternalPromptHeader.defaultProps = {
  partnershipPartnerMembers: [],
  requester: undefined,
  showRequester: undefined,
};

export default ExternalPromptHeader;

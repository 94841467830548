/**
 * @module sagas/createItem/sideEffects
 */
import React from 'react';
import { render } from 'react-dom';
import { change, stopSubmit } from 'redux-form';
import { all, put, select } from 'redux-saga/effects';

import { getCurrentCompanyRequest } from 'actions/currentCompany';
import { addApprovalLevelError } from 'actions/errors';
import { submitItemRoutine } from 'actions/routines/item';

import ConfirmAlertContent from 'complexComponents/ConfirmAlertContent';

import { UnsupportedFileTypeSwal } from 'components/error/components';

import { APPROVAL_LEVEL_ERROR_TITLE } from 'constants/createItem';
import { createItemFormFields } from 'constants/formFields';
import { formNamesItem } from 'constants/forms';

import * as confirmAlertHelpers from 'helpers/confirmAlert';
import { confirmAlert } from 'helpers/confirmAlert';
import {
  getBouncedContactsFromSubmitErrors,
  getCreateItemMissingRecommendedFieldsAlertBlockText,
  getCreateItemMissingRecommendedFieldsAlertBottomText,
  getCreateItemMissingRecommendedFieldsAlertTopText,
} from 'helpers/createItem';
import { showBouncedEmailErrorSWAL } from 'helpers/errors';
import * as itemHelpers from 'helpers/items';
import { showSwal } from 'helpers/swal';
import { asArrayElement, hasLength } from 'helpers/utility';

import { getDefaultPartnershipFundingAccountId } from 'modules/dashboard/createItems/helpers/partnerships';

import { currentCompanyItemApprovalLevelsFilteredSelector } from 'queries/currentCompanyCompoundSelectors';

import * as helpers from 'sagas/createItem/helpers';
import { getActionsForApproverFieldReset, getFirstFileAttachmentError } from 'sagas/createItem/helpers';

/**
 * Called by the create items sagas, when creating a new partnership in
 * the items form. Returns any side-effects that should be
 * dispatched before continuing.
 * @param {Object} options
 * @param {string[]} options.fundingAccountIds
 * @param {Object} options.partnershipData
 * @param {ComponentProps} options.props
 * @param {ReduxFormValues} options.values
 * @return {ReduxAction[]}
 */
export const getActionsForDefaultFundingAccount = (options) => {
  const { fundingAccountIds, partnershipData, props, values } = options;

  const { form } = props;
  const { item } = values;

  const actions = [];

  const defaultPartnershipFundingAccount = getDefaultPartnershipFundingAccountId({
    fundingAccountIds,
    item,
    partnershipData,
  });

  if (defaultPartnershipFundingAccount) {
    actions.push(change(form, 'item.fundingAccount.id', defaultPartnershipFundingAccount));
  }

  return actions;
};

/**
 * Called by the create items sagas, only when the ledger is QBO, and when confirmation is
 * required because no category line items with classes were selected.
 * Returns the result of getting user confirmation.
 * @param {Item} item
 * @param {Object} warnings
 * @return {Boolean}
 */
export const getRecommendedFieldWarningsConfirmation = async (item, warnings) => {
  const kindText = itemHelpers.getBillOrInvoiceText(item);
  const topContentText = getCreateItemMissingRecommendedFieldsAlertTopText(kindText);
  const blockContentText = getCreateItemMissingRecommendedFieldsAlertBlockText(warnings);
  const bottomContentText = getCreateItemMissingRecommendedFieldsAlertBottomText();

  const alertContent = (
    <ConfirmAlertContent
      blockContent={blockContentText}
      bottomContent={bottomContentText}
      topContent={topContentText}
    />
  );

  const alertWrapper = document.createElement('div');

  return new Promise((resolve) => {
    render(alertContent, alertWrapper, async () => {
      const result = await confirmAlertHelpers.confirmAlert('', undefined, {
        content: alertWrapper.firstChild,
      });

      resolve(result);
    });
  });
};

/**
 * Called with the result of no-class confirmation.
 * Returns any side-effects that should be dispatched before continuing.
 * @param {Boolean} confirmed
 * @return {ReduxAction[]}
 */
export const getActionsForWarningFieldsConfirmation = (confirmed) => {
  if (confirmed) {
    return asArrayElement(change(formNamesItem.CREATE_ITEM, createItemFormFields.UI_WARNINGS_DISMISSED, true));
  }

  return [stopSubmit(formNamesItem.CREATE_ITEM, null), submitItemRoutine.success()];
};

/**
 * Called by the create items sagas, when a new partnership was just created.
 * The rest of the functions in this file return an array of actions, even for
 * a singular action, because it's extremely easy to add to those with minimal
 * code changes. However, this function returns a single action object, as its
 * use-case is to be passed directly to a different generator, and not passed
 * to dispatch for general publishing.
 * @param {ReduxAction} originalAction
 * @return {ReduxAction}
 */
export const getActionForCreatePartnership = (originalAction) => {
  const isCustomer = itemHelpers.isCurrentPathCreateItemReceivable();

  return {
    ...originalAction,
    payload: {
      ...originalAction.payload,
      values: {
        ...originalAction.payload.values,
        isCustomer,
        isVendor: !isCustomer,
      },
    },
  };
};

/**
 * Called by the create items sagas, when side-effects must occur as a result of
 * an error response from the API.
 * @param {ReduxFormValues} values
 * @param {Object[]} values.lineItems
 * @param {Object} parsedErrors
 * @return {ReduxAction[]}
 */
export const getActionsForSubmitItemFieldErrors = (values, parsedErrors) => {
  // ensure we have field errors to parse
  if (!parsedErrors.fields) {
    return undefined;
  }

  const { dynamicErrors = {}, otherErrors = {} } = parsedErrors.fields;

  // Hack to rename the BE error for paymentDeliveryOption
  if (otherErrors.paymentDeliveryOption) {
    otherErrors.paymentDeliveryOption = ['Invalid option selected']; // eslint-disable-line no-param-reassign
  }

  // Add up all errors
  const itemErrors = {
    item: otherErrors,
    ...dynamicErrors,
  };

  return [stopSubmit(formNamesItem.CREATE_ITEM, itemErrors)];
};

/**
 * Called by the create items sagas, when side-effects must occur as a result of
 * a submit confirmation.
 * @return {ReduxAction[]}
 */
export const getActionsForFormConfirmation = () => [
  change(formNamesItem.CREATE_ITEM, createItemFormFields.UI_SHOW_CONFIRM_SUBMIT, true),
  stopSubmit(formNamesItem.CREATE_ITEM, null),
  submitItemRoutine.success(),
];

/**
 * maybeShowBouncedEmailsErrors
 * Checks if there are bounced emails and if so shows the error SWAL
 * @param {string} itemKind
 * @param {object} submitErrors
 * @param {partnershipMember[]} itemMembers
 * @param {string} currentCompanyName
 * @param {object} partnershipMembersById
 * @return {undefined}
 */
export const maybeShowBouncedEmailsErrors = ({
  itemKind,
  submitErrors,
  itemMembers,
  currentCompanyName,
  partnerCompanyName,
  partnershipMembersById,
}) => {
  const bouncedContacts = getBouncedContactsFromSubmitErrors({
    submitErrors,
    itemMembers,
    partnershipMembersById,
  });

  if (hasLength(bouncedContacts)) {
    showBouncedEmailErrorSWAL({
      partnerCompanyName,
      bouncedContacts,
      isInCreateItemForm: true,
      itemKind,
      currentCompanyName,
    });
  }
};

/**
 * Triggers the UnsupportedFileTypeErrorSwal if there are file field errors
 * @param {object} submitErrors
 */
export const maybeShowUnsupportedFileTypeErrorSwal = ({ submitErrors = {} }) => {
  const fileAttachmentError = getFirstFileAttachmentError(submitErrors);

  if (fileAttachmentError) {
    const [errorMsg] = fileAttachmentError;

    showSwal({
      Content: <UnsupportedFileTypeSwal errorMsg={errorMsg} />,
    });
  }
};

/**
 * maybeShowApprovalLevelErrors
 * handles higher level field errors (for eg., outdated approval rules or contacts bouncing
 * by showing parsing error messages, showing error alerts/SWALs and reset-ing state
 * @param {object} props
 * @param {object|array} props.submitErrors
 * @param {Item} props.item
 * @return {IterableIterator<*>}
 */
export function* maybeShowApprovalLevelErrors({ submitErrors, item }) {
  const itemApproverErrors = submitErrors?.fields?.itemApprovers;
  // maybe show error SWAL if approval levels config are outdated
  if (itemApproverErrors) {
    // no need to await (with yield call()) as this promise doesn't return until the swal is closed and we want
    // to let the rest of the logic below process too
    confirmAlert(helpers.getApprovalLevelErrorText(item), APPROVAL_LEVEL_ERROR_TITLE, {
      buttonsConfig: { showCancel: false, confirmButtonText: 'Ok' },
    });

    // lets SelectApproversForItems know it should show an error hint
    yield put(addApprovalLevelError());

    // reset the approval level data currently on the form
    const levels = yield select(currentCompanyItemApprovalLevelsFilteredSelector, { item });

    const actions = getActionsForApproverFieldReset({ levels });

    if (hasLength(actions)) {
      yield all(actions.map((act) => put(act)));
    }

    // fetch company settings so new levels can be displayed
    yield put(getCurrentCompanyRequest());
  }
}

import React from 'react';
import ExternalComponents from '../ExternalComponents';
import {} from './ExternalColumnHeader.types';
const ExternalColumnHeader = ({ text, ...rest }) => (React.createElement("div", { className: "external-column--header-container" },
    React.createElement(ExternalComponents, { className: "external-column--header-components", ...rest }),
    text && React.createElement("div", { className: "external-column--header-title" }, text)));
ExternalColumnHeader.defaultProps = {
    components: undefined,
    text: undefined,
};
export default ExternalColumnHeader;

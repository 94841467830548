import { createLastUpdatedReducer } from 'store/redux';

import {
  UPDATE_NOTIFICATION_SETTINGS_FAILURE,
  UPDATE_NOTIFICATION_SETTINGS_REQUEST,
  UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
} from 'types/notifications';

export const requestCases = [UPDATE_NOTIFICATION_SETTINGS_FAILURE, UPDATE_NOTIFICATION_SETTINGS_REQUEST];
export const successCases = [UPDATE_NOTIFICATION_SETTINGS_SUCCESS];

const lastUpdatedReducer = createLastUpdatedReducer(successCases, requestCases);

export default lastUpdatedReducer;

import { ItemCurrencyCodeKey } from 'enums/items';

import { formatCurrency } from 'helpers/currencyFormatter';
import {
  hasItemProcessed,
  isItemKindPayable,
  isItemKindReceivable,
  isItemStatusNew,
  isReceivableCreatorPartnerCompany,
} from 'helpers/items';
import { and, isEqual, isLessThan, or } from 'helpers/utility';

/**
 * getItemAmountString
 * Returns formatted item's amount to be shown in table
 * @see {PaymentRow}
 * @see {ShowAmountCell}
 *
 * @param {CurrencyCodeMap} currencyCodeMap
 * @param {unknown} item
 * @param {String} dataKey
 * @param {ObjectMaybe} [options]
 * @return {String}
 */
export const getItemAmountString = (currencyCodeMap, item, dataKey, options) => {
  const currencyCodeKey = options?.currencyCodeKey || ItemCurrencyCodeKey.GENERAL;
  const currencyCode = options?.currencyCode || item[currencyCodeKey];
  const numVal = parseFloat(item[dataKey]);
  const returnVal = formatCurrency(Math.abs(numVal), currencyCode, currencyCodeMap, options);

  if (or(isLessThan(numVal, 0), and(isItemKindPayable(item), hasItemProcessed(item), isEqual(dataKey, 'amountPaid')))) {
    return `(${returnVal})`;
  }

  return returnVal;
};

export const getItemAmountPaidPrefix = (item) => {
  if (isItemKindPayable(item)) {
    return 'Amount paid (by me)';
  }

  if (isItemKindReceivable(item)) {
    return 'Amount paid (by customer)';
  }

  return 'Amount paid';
};

export const getItemAmountDuePrefix = (item) => {
  if (isReceivableCreatorPartnerCompany(item) && isItemStatusNew(item)) {
    return 'Amount sent (by customer)';
  }

  return 'Amount due';
};

/**
 * Determines whether the given amount is negative.
 * @param {number} amount
 * @return {boolean}
 */
export const isAmountNegative = (amount) => amount < 0;

import React from 'react';

import context from './context';

const withBankAccount = (Component) => {
  // eslint-disable-next-line react/prefer-stateless-function
  class ComponentBankAccountContext extends React.Component {
    render() {
      const bankAccountContext = this.context;

      return <Component {...this.props} bankAccountContext={bankAccountContext} />;
    }
  }

  ComponentBankAccountContext.contextType = context;

  return ComponentBankAccountContext;
};

export default withBankAccount;

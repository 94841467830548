import { combineReducers } from 'redux';

import allDocumentsReducer from './allDocumentsReducer';
import documentsByIdReducer from './documentsByIdReducer';
import errorReducer from './errorReducer';
import fetchReducer from './fetchReducer';
import isDeletingReducer from './isDeleting';
import isPostingReducer from './isPosting';
import lastUpdatedReducer from './lastUpdatedReducer';

const documentsReducer = combineReducers({
  allIds: allDocumentsReducer,
  byId: documentsByIdReducer,
  errors: errorReducer,
  isFetching: fetchReducer,
  isPosting: isPostingReducer,
  isDeleting: isDeletingReducer,
  lastUpdated: lastUpdatedReducer,
});

export default documentsReducer;

import _debounce from 'lodash/debounce';
export const asyncDebounce = (func, wait) => {
    const debounced = _debounce((resolve, reject, args) => {
        func(...args)
            .then(resolve)
            .catch(reject);
    }, wait);
    return (...args) => new Promise((resolve, reject) => {
        debounced(resolve, reject, args);
    });
};

import _find from 'lodash/find';

import { fetchItemsRoutine } from 'actions/routines/item';

import { annotationRelationships } from 'constants/relationships';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';
import { allKeys, hasLength } from 'helpers/utility';

import { FETCH_ITEM_SUCCESS } from 'types/item';

const annotationsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case fetchItemsRoutine.SUCCESS:
    case FETCH_ITEM_SUCCESS: {
      const itemAttachments = action.payload?.itemAttachment || [];
      const relatedAttachmentKeys = allKeys(itemAttachments || {});

      const annotations = deepMergeWithArrayReplacement(
        state,
        getObjectsByIdWithRelationships(action.payload.annotation, annotationRelationships),
      );

      if (!annotations) {
        return state;
      }

      Object.entries(annotations).forEach(([key]) => {
        if (annotations[key] && hasLength(annotations[key]?.partnerships)) {
          if (annotations[key] && action.payload.partnership) {
            annotations[key].partnership = {
              ...(annotations[key]?.partnerships.map((id) => {
                const relatedPartnership = action.payload.partnership[id] || null;
                if (relatedPartnership) {
                  return {
                    id: relatedPartnership?.id,
                    name: relatedPartnership?.attributes?.name,
                  };
                }
                return false;
              })[0] || null),
            };
          }
        }
      });

      // If there are related attachment id's to annotation
      if (relatedAttachmentKeys && hasLength(relatedAttachmentKeys)) {
        let foundItem = null;
        Object.entries(annotations).forEach(([key]) => {
          if (annotations[key]) {
            const id = annotations[key]?.id || '';
            foundItem = _find(itemAttachments, {
              relationships: { latestOcrAnnotation: { data: { id } } },
            });
          }

          // Set a relatedItem property to the attachmentId
          // we do this here instead of in attachments/files because
          // there can be multiple types of relatedItems not just ocr data as the source of truth.
          if (foundItem && foundItem.id && annotations[key]) {
            annotations[key].relatedItem = {
              id: foundItem.id,
              type: 'annotation',
            };
          }
        });
      }

      return annotations;
    }
    default:
      return state;
  }
};

export default annotationsByIdReducer;

import React from 'react';
import { connect } from 'react-redux';
import { Prompt, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { ACCOUNT_CREATION_CREATE_NAMESPACE } from 'constants/routes';

import GenericContainer from 'helpers/containers';
import { getCurrentMembershipId, handleClearLocalStorage } from 'helpers/localStorage';

import {
  isSubmittingSignupVerifyEmailSelector,
  lastSubmittedSignupResendVerificationEmailSelector,
  signupVerifyEmailErrorsSelector,
} from 'selectors/signupSelectors';

import submitResendVerificationEmail from 'thunks/submitSignupResendVerificationEmail';
import submitSignupVerifyEmail from 'thunks/submitSignupVerifyEmail';

import VerifyEmail from './VerifyEmail';

/**
 * Container for verify email form
 */
class VerifyEmailContainer extends GenericContainer {
  state = {
    errors: {},
    form: {
      code: '',
    },
  };

  componentDidMount() {
    const { history } = this.props;

    this.isUnmounting = false;

    const unregisterHistoryListener = history.listen((newLocation, action) => {
      if (action !== 'PUSH') {
        // clear the local storage if user leaves the page via back button
        handleClearLocalStorage();
        // remove listener
        unregisterHistoryListener();
      } else if (this.isUnmounting) {
        // remove listener on unmount
        unregisterHistoryListener();
      }
    });
  }

  componentWillUnmount() {
    // removing the listener on unmount caused a race condition
    // where the listener was being removed beforehand, hence the use of the isUnmounting flag
    this.isUnmounting = true;
  }

  handleFormSubmit = () => {
    // Structure payload for meta form submit
    const metaPayload = {
      data: {
        type: 'Membership',
        id: getCurrentMembershipId(),
      },
      meta: {
        code: this.state.form.code,
      },
    };

    this.genericFormSubmit(metaPayload, this.props.onSubmitSignupVerifyEmail, 'signup-verify-email-form', [
      this.verifyEmailSuccess,
    ]);
  };

  verifyEmailSuccess = (parsedResponse) => {
    this.props.history.push(ACCOUNT_CREATION_CREATE_NAMESPACE);
    return parsedResponse;
  };

  render() {
    return (
      <>
        <VerifyEmail
          errors={this.state.errors}
          formState={this.state.form}
          isSubmitting={this.props.isSubmittingSignupVerifyEmail}
          lastSubmittedVerificationEmail={this.props.lastSubmittedVerificationEmail}
          onFormSubmit={this.handleFormSubmit}
          onInputChange={this.handleInputChange}
          onSubmitResendVerificationEmail={this.props.onSubmitResendVerificationEmail}
        />
        <Prompt
          message="Are you sure you want to lose your progress? If you click confirm, you will be redirected to the login page."
          when={!this.props.isSubmittingSignupVerifyEmail}
        />
      </>
    );
  }
}

export const mapStateToProps = createStructuredSelector({
  errors: signupVerifyEmailErrorsSelector,
  isSubmittingSignupVerifyEmail: isSubmittingSignupVerifyEmailSelector,
  lastSubmittedVerificationEmail: lastSubmittedSignupResendVerificationEmailSelector,
});

export const mapDispatchToProps = {
  onSubmitSignupVerifyEmail: submitSignupVerifyEmail,
  onSubmitResendVerificationEmail: submitResendVerificationEmail,
};

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));

export { VerifyEmailContainer };
export default enhance(VerifyEmailContainer);

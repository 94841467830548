import * as types from 'types/passwordInput';
import initialState from '../initialState';
import {} from '../types/passwordInputReducer.types';
const fetchPasswordStrengthCompletedReducer = (state = initialState.passwordInputStrength, action) => {
    if (action.type === types.FETCH_PASSWORD_STRENGTH_COMPLETED) {
        return action?.payload?.attributes?.strength || initialState.passwordInputStrength;
    }
    return state;
};
export default fetchPasswordStrengthCompletedReducer;

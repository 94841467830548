import classNames from 'classnames';
import React from 'react';
import { Icon } from 'components';
import { colors, sizes } from 'constants/styles';
import { isEventKeySpaceOrEnter } from 'helpers/events';
const DropdownMenuOption = ({ children, className, icon, isDisabled, onClick, onCloseMenu, title, }) => {
    const handleOnClick = React.useCallback((evt) => {
        if (isDisabled) {
            return;
        }
        onClick(evt);
        onCloseMenu(evt);
    }, [isDisabled, onClick, onCloseMenu]);
    const handleKeyPress = React.useCallback((evt) => {
        if (isEventKeySpaceOrEnter(evt)) {
            handleOnClick(evt);
        }
    }, [handleOnClick]);
    return (React.createElement("li", { className: classNames('dropdown-menu-option', className) },
        React.createElement("a", { "aria-disabled": isDisabled, className: classNames('remove-hover-underline', {
                disabled: !!isDisabled,
            }), "data-testid": `dropdown-menu-option-${title}`, onClick: handleOnClick, onKeyPress: handleKeyPress, role: "menuitem", tabIndex: 0, type: "button" },
            icon && (React.createElement(Icon, { className: "margin-right--xm", color: colors.colorGreyDarkHex, icon: icon, size: sizes.iconSizes.LARGE })),
            React.createElement("p", { className: "link--title semibold" }, title),
            children)));
};
DropdownMenuOption.defaultProps = {
    children: undefined,
    className: undefined,
    icon: undefined,
    isDisabled: undefined,
};
export default DropdownMenuOption;

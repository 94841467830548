import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';

import { ButtonAppearance, ButtonSize } from 'constants/button';
import { colors, sizes } from 'constants/styles';

import './StepHeaderAction.scss';

/**
 * Renders an action button for the step header
 * @param {ComponentProps} props
 * @param {String} props.actionText
 * @param {String} props.iconName
 * @param {Boolean?} [props.isDisabled]
 * @param {String?} [props.objectKey]
 * @param {Function?} [props.onActionClick]
 * @returns {StatelessComponent}
 */
const StepHeaderAction = ({ actionText, iconName, objectKey, onActionClick, ...props }) => (
  <ButtonV2
    appearance={ButtonAppearance.BORDERLESS}
    leftIconClassName="margin-right--xm"
    leftIconColor={colors.colorGreyXDarkHex}
    leftIconName={iconName}
    leftIconSize={sizes.iconSizes.MEDIUM}
    onClick={() => onActionClick(objectKey)}
    role="presentation"
    size={ButtonSize.SMALL}
    type="button"
    {...props}
  >
    {actionText}
  </ButtonV2>
);

StepHeaderAction.propTypes = {
  actionText: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  objectKey: PropTypes.string,
  onActionClick: PropTypes.func,
};

StepHeaderAction.defaultProps = {
  isDisabled: undefined,
  onActionClick: () => {},
  objectKey: undefined,
};

export default StepHeaderAction;

import { createItemTagLinksRoutine } from 'actions/routines/tags';

import { createAllIdsReducer } from 'store/redux';

import { FETCH_ITEM_SUCCESS } from 'types/item';

const allIdsReducer = createAllIdsReducer({
  key: 'tagLink',
  types: [FETCH_ITEM_SUCCESS, createItemTagLinksRoutine.SUCCESS],
});

export default allIdsReducer;

import { Tabs, Option } from '@routable/gross-ds';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'components/icon';

import { fullPageModalHeader } from 'constants/dataTestId/fullPageModal';
import { colors, sizes } from 'constants/styles';

/**
 * ModalNavTab
 * @param {ComponentProps} props
 * @param {String} [props.className]
 * @param {String} [props.icon]
 * @param {String} props.tabText
 * @return {StatelessComponent}
 */
const ModalNavTab = ({ className, icon, tabText }) => (
  <Tabs className={clsx('full-modal--tabs', className)} selectedKey={fullPageModalHeader.MODAL_NAV_TAB_TEXT}>
    <Option
      data-testid={fullPageModalHeader.MODAL_NAV_TAB_TEXT}
      key={fullPageModalHeader.MODAL_NAV_TAB_TEXT}
      title={
        <div className="flex gap-3 items-center">
          {icon && <Icon color={colors.colorGreyXXDarkHex} icon={icon} size={sizes.iconSizes.LARGE} />}
          <span>{tabText}</span>
        </div>
      }
    />
  </Tabs>
);

ModalNavTab.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  tabText: PropTypes.string.isRequired,
};

ModalNavTab.defaultProps = {
  className: undefined,
  icon: undefined,
};

export default ModalNavTab;

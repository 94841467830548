import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { partnerCompanyFromPartnershipQuerySelector } from 'queries/companyCompoundSelectors';
import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

import { currentCompanySelector } from 'selectors/currentCompanySelectors';

import ExternalNeedVendorFlowAlert from './ExternalNeedVendorFlowAlert';

const mapStateToProps = createStructuredSelector({
  currentCompany: currentCompanySelector,
  partner: partnerCompanyFromPartnershipQuerySelector,
  partnership: partnershipFromQuerySelector,
});

const enhance = compose(connect(mapStateToProps));

export default enhance(ExternalNeedVendorFlowAlert);

import { useRef } from 'react';
import { countriesMapping, countryListOptions } from 'constants/i18n';
import { formatAddressFromPlace } from 'helpers/addressHelpers';
export const useAddressAutocomplete = ({ addressPath, countryOptions, inputRef, onInvalidCountry, setValue, }) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const scriptUrl = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=Function.prototype`;
    const autocomplete = useRef(null);
    const countriesList = countriesMapping[countryOptions];
    const handleFillInAddress = () => {
        const place = autocomplete.current.getPlace();
        if (place?.address_components) {
            const countryComponent = place.address_components.find((component) => component.types.includes('country'));
            if (countryComponent) {
                const allowedCountry = countriesList.find(({ value }) => value === countryComponent.short_name);
                if (!allowedCountry) {
                    onInvalidCountry?.(countriesList, countryComponent);
                    return;
                }
            }
            const newAddress = formatAddressFromPlace(place);
            Object.keys(newAddress).forEach((key) => {
                const path = addressPath ? `${addressPath}.${key}` : key;
                setValue(path, newAddress[key]);
            });
        }
    };
    const handleGoogleMapsLoad = async () => {
        const componentRestrictions = countryOptions !== countryListOptions.ALL
            ? {
                country: countriesList.map(({ value: country }) => country.toLowerCase()),
            }
            : undefined;
        const options = {
            types: ['geocode'],
            componentRestrictions,
        };
        await google.maps.importLibrary('places');
        autocomplete.current = new google.maps.places.Autocomplete(inputRef.current, options);
        autocomplete.current.addListener('place_changed', handleFillInAddress);
    };
    return { onLoad: handleGoogleMapsLoad, scriptUrl };
};

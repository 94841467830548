import { PredefinedType } from '@routable/tablematic';
import { DiscountAmount, DiscountCalculation, DiscountStyle, LineItemActions, LineItemDiscrepancyStatus, TotalsPanelDiscountIncludes, TotalsPanelDiscountPostTax, TotalsPanelDiscountPreTax, TotalsPanelSubtotal, TotalsPanelTax, TotalsPanelTotal, } from 'modules/table/components';
export const predefinedComponents = {
    [PredefinedType.discount]: DiscountAmount,
    [PredefinedType.discount_calculation]: DiscountCalculation,
    [PredefinedType.discount_style]: DiscountStyle,
    [PredefinedType.line_item_actions]: LineItemActions,
    [PredefinedType.line_item_status]: LineItemDiscrepancyStatus,
    [PredefinedType.totals_discount_includes]: TotalsPanelDiscountIncludes,
    [PredefinedType.totals_discount_post_tax]: TotalsPanelDiscountPostTax,
    [PredefinedType.totals_discount_pre_tax]: TotalsPanelDiscountPreTax,
    [PredefinedType.totals_subtotal]: TotalsPanelSubtotal,
    [PredefinedType.totals_tax]: TotalsPanelTax,
    [PredefinedType.totals_total]: TotalsPanelTotal,
};

import { change } from 'redux-form';
import { applyPayablesTableFilters, applyReceivablesTableFilters } from 'actions/tables';
import { createItemFormFields } from 'constants/formFields';
import { formNamesItem } from 'constants/forms';
import { amountDueFieldOperatorOptions, bankAccountFieldOperatorOptions, dateFieldOperatorsOptions, invoiceNumbersFieldOperatorOptions, } from 'constants/itemFilters';
import { ItemFilterLabels } from 'enums/itemFilters';
import { isCurrentPathCreateItemStateAwaitingPayment, isCurrentPathCreateItemStateNew } from 'helpers/items';
import { getQueryParamsFromUrl } from 'helpers/queryParams';
import { isTabReceivables } from 'helpers/urls';
import { isArray, isEqual } from 'helpers/utility';
import { FilterOperator, } from 'interfaces/itemFilters';
import { createFilterListFromParams } from 'modules/itemFilters/createFilters';
import { filterToUrlParams } from 'modules/itemFilters/filterToUrlParams';
export const getApplyFiltersAction = (tab) => {
    if (isCurrentPathCreateItemStateAwaitingPayment() || isCurrentPathCreateItemStateNew()) {
        return (filters) => change(formNamesItem.CREATE_ITEM, createItemFormFields.UI_FILTERS, filters);
    }
    return isTabReceivables(tab) ? applyReceivablesTableFilters : applyPayablesTableFilters;
};
export const isFilterAmountDue = (label) => isEqual(label, ItemFilterLabels.AMOUNT_DUE);
export const isFilterBankAccount = (label) => isEqual(label, ItemFilterLabels.BANK_ACCOUNT);
export const isFilterCreatedDate = (label) => isEqual(label, ItemFilterLabels.CREATED_DATE);
export const isFilterCurrentApprover = (label) => isEqual(label, ItemFilterLabels.CURRENT_APPROVER);
export const isFilterDueDate = (label) => isEqual(label, ItemFilterLabels.DUE_DATE);
export const isFilterDateRelated = (label) => isFilterCreatedDate(label) || isFilterDueDate(label);
export const isFilterInvoiceNumbers = (filter) => isEqual(filter.label, ItemFilterLabels.INVOICE_NUMBERS);
export const isFilterOperatorBetween = (operator) => isEqual(operator, FilterOperator.BETWEEN);
export const isFilterOperatorEqual = (operator) => isEqual(operator, FilterOperator.EQUALS);
export const isFilterOperatorGreaterOrEqual = (operator) => isEqual(operator, FilterOperator.GREATER_THAN_OR_EQUAL);
export const isFilterOperatorInSet = (operator) => isEqual(operator, FilterOperator.IS_IN_SET);
export const isFilterOperatorLessOrEqual = (operator) => isEqual(operator, FilterOperator.LESS_THAN_OR_EQUAL);
export const getFilterOperatorOptionsForFilter = (label) => {
    if (isFilterAmountDue(label)) {
        return amountDueFieldOperatorOptions;
    }
    if (isFilterDateRelated(label)) {
        return dateFieldOperatorsOptions;
    }
    if (isFilterInvoiceNumbers({ label })) {
        return invoiceNumbersFieldOperatorOptions;
    }
    return bankAccountFieldOperatorOptions;
};
export const doesAtLeastOneFilterHaveValue = (filters) => filters.some((filter) => {
    if (isArray(filter.value)) {
        return isFilterBankAccount(filter.label) || isFilterCurrentApprover(filter.label)
            ? !!filter.value?.length
            :
                filter.value[0] && filter.value[1];
    }
    return Boolean(filter.value);
});
export const doesAtLeastOneFilterNotHaveValue = (filters) => filters.some((filter) => {
    if (isArray(filter.value)) {
        return isFilterBankAccount(filter.label) || isFilterCurrentApprover(filter.label)
            ? !filter.value?.length
            :
                !filter.value[0] || !filter.value[1];
    }
    return !filter.value;
});
export const isItemIdFilter = (filter) => filter.label === ItemFilterLabels.ITEM_ID;
export const parseFiltersFromUrl = () => {
    const params = getQueryParamsFromUrl();
    return createFilterListFromParams(params);
};
export const transformFiltersListToParamsObject = (filters) => filters.reduce((agg, f) => ({ ...agg, ...filterToUrlParams(f) }), {});

import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';
import { IconNames } from 'components/icon';
import { LinkNewPage } from 'components/link';

import { ButtonSize } from 'constants/button';
import { Intent } from 'constants/ui';

const HelpDocsLinkButton = ({ children, href, ...rest }) => (
  <LinkNewPage className="help-docs-link-btn" href={href}>
    <ButtonV2
      intent={Intent.NEUTRAL}
      rightIconClassName="ml-3"
      rightIconName={IconNames.ARROW_UP_RIGHT}
      rightIconSize={16}
      size={ButtonSize.SMALL}
      {...rest}
    >
      {children}
    </ButtonV2>
  </LinkNewPage>
);

HelpDocsLinkButton.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string.isRequired,
};

HelpDocsLinkButton.defaultProps = {
  children: undefined,
};

export default HelpDocsLinkButton;

import React from 'react';
import styled from 'styled-components';
const spinColors = {
    top: 'rgba(255,255,255,1)',
    mid: 'rgba(255,255,255,0.7)',
    half: 'rgba(255,255,255,0.5)',
    low: 'rgba(255,255,255,0.2)',
};
const SpinnerComponentBG = styled.div `
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0px;
  top: 0px;
  z-index: 8;
  pointer-events: initial;
  touch-action: initial;
  cursor: default;
  &:hover,
  &:focus,
  &.focused {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;
const SpinnerComponent = styled.div `
  display: block;
  position: absolute;
  width: 14px;
  height: 14px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
`;
const SpinnerIcon = styled.div `
  font-size: 14px;
  width: 1.3em;
  height: 1.3em;
  top: -3px;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: gdsSpin 1.1s infinite ease;
  transform: translateZ(0) scale(0.2);
  @keyframes gdsSpin {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em ${spinColors.top}, 1.8em -1.8em 0 0em ${spinColors.low},
        2.5em 0em 0 0em ${spinColors.low}, 1.75em 1.75em 0 0em ${spinColors.low}, 0em 2.5em 0 0em ${spinColors.low},
        -1.8em 1.8em 0 0em ${spinColors.low}, -2.6em 0em 0 0em ${spinColors.half}, -1.8em -1.8em 0 0em ${spinColors.mid};
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em ${spinColors.mid}, 1.8em -1.8em 0 0em ${spinColors.top},
        2.5em 0em 0 0em ${spinColors.low}, 1.75em 1.75em 0 0em ${spinColors.low}, 0em 2.5em 0 0em ${spinColors.low},
        -1.8em 1.8em 0 0em ${spinColors.low}, -2.6em 0em 0 0em ${spinColors.low}, -1.8em -1.8em 0 0em ${spinColors.half};
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em ${spinColors.half}, 1.8em -1.8em 0 0em ${spinColors.mid},
        2.5em 0em 0 0em ${spinColors.top}, 1.75em 1.75em 0 0em ${spinColors.low}, 0em 2.5em 0 0em ${spinColors.low},
        -1.8em 1.8em 0 0em ${spinColors.low}, -2.6em 0em 0 0em ${spinColors.low}, -1.8em -1.8em 0 0em ${spinColors.low};
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em ${spinColors.low}, 1.8em -1.8em 0 0em ${spinColors.half},
        2.5em 0em 0 0em ${spinColors.mid}, 1.75em 1.75em 0 0em ${spinColors.top}, 0em 2.5em 0 0em ${spinColors.low},
        -1.8em 1.8em 0 0em ${spinColors.low}, -2.6em 0em 0 0em ${spinColors.low}, -1.8em -1.8em 0 0em ${spinColors.low};
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em ${spinColors.low}, 1.8em -1.8em 0 0em ${spinColors.low},
        2.5em 0em 0 0em ${spinColors.half}, 1.75em 1.75em 0 0em ${spinColors.mid}, 0em 2.5em 0 0em ${spinColors.top},
        -1.8em 1.8em 0 0em ${spinColors.low}, -2.6em 0em 0 0em ${spinColors.low}, -1.8em -1.8em 0 0em ${spinColors.low};
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em ${spinColors.low}, 1.8em -1.8em 0 0em ${spinColors.low},
        2.5em 0em 0 0em ${spinColors.low}, 1.75em 1.75em 0 0em ${spinColors.half}, 0em 2.5em 0 0em ${spinColors.mid},
        -1.8em 1.8em 0 0em ${spinColors.top}, -2.6em 0em 0 0em ${spinColors.low}, -1.8em -1.8em 0 0em ${spinColors.low};
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em ${spinColors.low}, 1.8em -1.8em 0 0em ${spinColors.low},
        2.5em 0em 0 0em ${spinColors.low}, 1.75em 1.75em 0 0em ${spinColors.low}, 0em 2.5em 0 0em ${spinColors.half},
        -1.8em 1.8em 0 0em ${spinColors.mid}, -2.6em 0em 0 0em ${spinColors.top}, -1.8em -1.8em 0 0em ${spinColors.low};
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em ${spinColors.low}, 1.8em -1.8em 0 0em ${spinColors.low},
        2.5em 0em 0 0em ${spinColors.low}, 1.75em 1.75em 0 0em ${spinColors.low}, 0em 2.5em 0 0em ${spinColors.low},
        -1.8em 1.8em 0 0em ${spinColors.half}, -2.6em 0em 0 0em ${spinColors.mid}, -1.8em -1.8em 0 0em ${spinColors.top};
    }
  }
`;
const Spinner = () => (React.createElement(React.Fragment, null,
    React.createElement(SpinnerComponentBG, null),
    React.createElement(SpinnerComponent, null,
        React.createElement(SpinnerIcon, null))));
export default Spinner;

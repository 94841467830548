import React from 'react';
import { getClassNames } from 'helpers/ui';
import { hasLength } from 'helpers/utility';
import {} from './ExternalComponents.types';
const ExternalComponents = ({ components, renderExternalComponent, ...rest }) => {
    if (!hasLength(components)) {
        return null;
    }
    return (React.createElement("div", { className: getClassNames(rest) }, components.map((componentName) => {
        const ComponentEl = renderExternalComponent(componentName);
        if (!ComponentEl) {
            return null;
        }
        return (React.createElement("div", { className: "external-components--component-wrapper", key: componentName }, ComponentEl));
    })));
};
ExternalComponents.defaultProps = {
    components: undefined,
};
export default ExternalComponents;

import { enableMembershipRoutine } from 'actions/routines/membership';

import { createIsEnablingReducer } from 'store/redux';

export const finishCases = [enableMembershipRoutine.FAILURE, enableMembershipRoutine.SUCCESS];

export const requestCases = [enableMembershipRoutine.REQUEST];

const isEnablingReducer = createIsEnablingReducer(finishCases, requestCases);

export default isEnablingReducer;

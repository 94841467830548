import PropTypes from 'prop-types';
import React from 'react';

const AccountDetailsSectionItemValue = ({ children }) => <span>{children}</span>;

AccountDetailsSectionItemValue.propTypes = {
  children: PropTypes.node,
};

AccountDetailsSectionItemValue.defaultProps = {
  children: undefined,
};

export default AccountDetailsSectionItemValue;

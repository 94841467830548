import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

import { taxesAmountSelector } from 'selectors/taxesSelectors';

import CollectTaxSidebar from './CollectTaxSidebar';

const mapStateToProps = createStructuredSelector({
  amount: taxesAmountSelector,
  partnership: partnershipFromQuerySelector,
});

export default connect(mapStateToProps)(CollectTaxSidebar);

import { all, call, delay, put, select, spawn, take } from 'redux-saga/effects';
import { handleRequestErrors } from 'actions/errors';
import { companySettingsSSOFetchRoutine, ssoDisableRoutine, ssoSetupFormEditSubmitRoutine, ssoSetupFormSubmitRoutine, } from 'actions/routines/sso';
import { openSSOSetupFormSplashscreenModal } from 'actions/ssoActions';
import { showErrorUi, showSuccessUi } from 'actions/ui';
import { SSODisableFailUIText, SSODisableSuccessUIText, SSOSetupFailUIText, SSOSetupFormClickDelayDuration, SSOSetupSplashscreenDuration, SSOSetupSuccessUIText, } from 'constants/sso';
import {} from 'enums/sso';
import { parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { trackEvent, TrackEventName } from 'helpers/eventTracking';
import {} from 'interfaces/actions';
import {} from 'interfaces/fetchService';
import {} from 'interfaces/sso';
import { currentCompanySelector } from 'selectors/currentCompanySelectors';
import { enabledSSOSettingsSelector, ssoSettingsByIdSelector, ssoSetupFormProviderSelector, } from 'selectors/ssoSelectors';
import * as api from './api';
import { enableSSO } from './sideEffects';
export function* disableSSO() {
    let submitErrors = {};
    const enabledSSOSettings = yield select(enabledSSOSettingsSelector);
    const { ssoRequired, ssoProvider } = enabledSSOSettings;
    try {
        const response = yield call(api.disableSSO, enabledSSOSettings.id);
        if (response.ok) {
            const { created } = yield select(currentCompanySelector);
            const eventData = {
                companySignedUpOn: created,
                isSSOLoginRequiredOrEnabled: ssoRequired ? 'required' : 'enabled',
                provider: ssoProvider,
            };
            yield all([
                call(trackEvent, TrackEventName.SSO_DISABLED, eventData),
                put(companySettingsSSOFetchRoutine.trigger()),
                put(ssoDisableRoutine.success()),
                put(showSuccessUi(SSODisableSuccessUIText[enabledSSOSettings.ssoProvider])),
            ]);
            return;
        }
        submitErrors = parseErrorResponse(response);
    }
    catch (error) {
        submitErrors = parseCaughtError(error);
    }
    yield all([
        put(handleRequestErrors(ssoDisableRoutine.failure, submitErrors)),
        put(showErrorUi(SSODisableFailUIText[enabledSSOSettings.ssoProvider])),
    ]);
}
export function* submitSSOSettings(action) {
    yield all([
        put(ssoSetupFormSubmitRoutine.request()),
        delay(SSOSetupFormClickDelayDuration),
    ]);
    yield put(openSSOSetupFormSplashscreenModal());
    let submitErrors = {};
    const { payload } = action;
    const { ssoProvider, ssoRequired } = payload;
    try {
        const { response } = yield all({
            response: call(api.submitSSOSettings, payload),
            delay: delay(SSOSetupSplashscreenDuration),
        });
        if (response.ok) {
            const { created } = yield select(currentCompanySelector);
            const eventData = {
                companySignedUpOn: created,
                isSSOLoginRequiredOrEnabled: ssoRequired === 'true' ? 'required' : 'enabled',
                provider: ssoProvider,
            };
            yield all([
                call(trackEvent, TrackEventName.SSO_ENABLED, eventData),
                put(companySettingsSSOFetchRoutine.trigger()),
                put(ssoSetupFormSubmitRoutine.success()),
                put(showSuccessUi(SSOSetupSuccessUIText[ssoProvider].SETUP)),
            ]);
            return;
        }
        submitErrors = parseErrorResponse(response);
    }
    catch (error) {
        submitErrors = parseCaughtError(error);
    }
    yield all([
        put(handleRequestErrors(ssoSetupFormSubmitRoutine.failure, submitErrors)),
        put(showErrorUi(SSOSetupFailUIText[ssoProvider].SETUP)),
    ]);
}
export function* submitUpdateSSOSettings(action) {
    yield put(ssoSetupFormEditSubmitRoutine.request());
    let submitErrors = {};
    let status = 'EDIT';
    const ssoSetupFormProvider = yield select(ssoSetupFormProviderSelector);
    const ssoSettingsById = yield select(ssoSettingsByIdSelector);
    try {
        const { payload } = action;
        if (ssoSettingsById[payload.id] && !ssoSettingsById[payload.id].enableSso) {
            status = 'SETUP';
        }
        const { response } = yield all({
            response: call(api.submitUpdateSSOSettings, payload),
            delay: delay(SSOSetupFormClickDelayDuration),
        });
        if (response.ok) {
            if (status === 'SETUP') {
                const isSuccessful = yield call(enableSSO, payload.id, ssoSetupFormProvider);
                const { ssoProvider } = ssoSettingsById[payload.id];
                const { ssoRequired } = payload;
                const { created } = yield select(currentCompanySelector);
                const eventData = {
                    companySignedUpOn: created,
                    isSSOLoginRequiredOrEnabled: ssoRequired === 'true' ? 'required' : 'enabled',
                    provider: ssoProvider,
                };
                if (!isSuccessful) {
                    return;
                }
                yield call(trackEvent, TrackEventName.SSO_RE_ENABLED, eventData);
            }
            yield all([
                put(companySettingsSSOFetchRoutine.trigger()),
                put(ssoSetupFormEditSubmitRoutine.success()),
                put(showSuccessUi(SSOSetupSuccessUIText[ssoSetupFormProvider][status])),
            ]);
            return;
        }
        submitErrors = parseErrorResponse(response);
    }
    catch (error) {
        submitErrors = parseCaughtError(error);
    }
    yield all([
        put(handleRequestErrors(ssoSetupFormEditSubmitRoutine.failure, submitErrors)),
        put(showErrorUi(SSOSetupFailUIText[ssoSetupFormProvider][status])),
    ]);
}
export function* watch() {
    while (true) {
        const action = yield take([
            ssoDisableRoutine.TRIGGER,
            ssoSetupFormSubmitRoutine.TRIGGER,
            ssoSetupFormEditSubmitRoutine.TRIGGER,
        ]);
        switch (action.type) {
            case ssoDisableRoutine.TRIGGER:
                yield spawn(disableSSO);
                break;
            case ssoSetupFormSubmitRoutine.TRIGGER:
                yield spawn(submitSSOSettings, action);
                break;
            case ssoSetupFormEditSubmitRoutine.TRIGGER:
                yield spawn(submitUpdateSSOSettings, action);
                break;
            default:
                yield null;
        }
    }
}
export default function* sso() {
    yield watch();
}

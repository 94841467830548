import PropTypes from 'prop-types';

import { restrictAccessToComponentWithRequiredPermissions } from 'helpers/permissions';

/**
 * A component version of the withPermissionRestriction HOC which checks if the current membership has the necessary
 * permissions, then renders the correct component accordingly.
 *
 * @param {ComponentProps} props
 * @param {CurrentMemberPermissionSet} props.currentMemberPermissionSet
 * @param {RequiredPermissions} props.requiredPermissions
 * @param {AnyComponent} props.WithoutPermissionComponent
 * @param {AnyComponent} props.WithPermissionComponent
 * @returns {AnyComponent}
 */
const WithPermissionRestriction = (props) => restrictAccessToComponentWithRequiredPermissions(props);

WithPermissionRestriction.propTypes = {
  // All of these props are used in the helper.
  /* eslint-disable react/no-unused-prop-types */
  currentMemberPermissionSet: PropTypes.instanceOf(Set).isRequired,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  WithoutPermissionComponent: PropTypes.node.isRequired,
  WithPermissionComponent: PropTypes.node.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

export default WithPermissionRestriction;

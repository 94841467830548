import PropTypes from 'prop-types';
import React from 'react';

import { BankAccountViewType } from 'constants/ui';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import {
  AccountDetailsSection,
  AccountHintSection,
  AccountingIntegrationSection,
  BankAccountUseSection,
} from 'modules/fundingAccount/bankAccount/components';

const BankAccountBody = (props) => {
  const { bodyRowProps, fundingAccount, fundingInfoBankAccount, viewType } = props;

  return (
    <React.Fragment>
      <AccountDetailsSection
        bodyRowProps={bodyRowProps}
        fundingAccount={fundingAccount}
        fundingInfoBankAccount={fundingInfoBankAccount}
        viewType={viewType}
      />
      <BankAccountUseSection bodyRowProps={bodyRowProps} fundingAccount={fundingAccount} viewType={viewType} />
      <AccountingIntegrationSection fundingAccount={fundingAccount} viewType={viewType} />
      <AccountHintSection fundingAccount={fundingAccount} viewType={viewType} />
    </React.Fragment>
  );
};

BankAccountBody.propTypes = {
  bodyRowProps: PropTypes.shape(),
  fundingAccount: PropTypes.shape({
    bank: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    ledgerRefPayable: PropTypes.string,
    ledgerRefReceivable: PropTypes.string,
    name: PropTypes.string,
  }),
  fundingInfoBankAccount: PropTypes.shape(),
  viewType: PropTypes.oneOf(Object.values(BankAccountViewType)),
};

BankAccountBody.defaultProps = {
  bodyRowProps: {},
  fundingAccount: {},
  fundingInfoBankAccount: {},
  viewType: BankAccountViewType.FULL,
};

export default BankAccountBody;

import { lookupPartnershipEmailsRoutine } from 'actions/routines/partnership';

import { createEmailAsyncValidateFormReducer } from 'store/redux';

export const successCases = [lookupPartnershipEmailsRoutine.SUCCESS];
export const failureCases = [lookupPartnershipEmailsRoutine.FAILURE];

/**
 * Keep track of warnings related to the email address validation when adding a new contact.
 * @param {Object} state
 * @param {ReduxAction} action
 * @returns {Object}
 */
const contactFormsReducer = createEmailAsyncValidateFormReducer(successCases, failureCases);

export default contactFormsReducer;

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { fetchFundingAccountsRequest, verifyFundingAccountModalClose } from 'actions/funding';

import FormControl from 'components/form/FormControl';

import { formNamesFunding } from 'constants/forms';
import { modalNameVerifyFundingAccount } from 'constants/modals';

import { showFormFailureToast } from 'helpers/errors';

import {
  initialValuesHelpers,
  submitFundingAccountMicrodeposits,
  submitHelpers,
  textHelpers,
} from 'modules/connectBank/verifyFundingAccount/helpers';

import {
  vfaModalFundingAccountIdSelector,
  vfaModalFundingSourceIdSelector,
} from 'queries/verifyFundingAccountModalSelectors';

import { modalIsOpenSelector } from 'selectors/modalsSelector';

import submitVerifyFundingAccount from 'thunks/submitVerifyFundingAccount';

import VerifyFundingAccountInput from './components';

import './VerifyFundingAccountForm.scss';

const VerifyFundingAccountForm = ({ handleSubmit, ...rest }) => (
  <form
    className="form verify-funding-account-form"
    id={formNamesFunding.VERIFY_FUNDING_ACCOUNT}
    onSubmit={handleSubmit(async (values) => submitFundingAccountMicrodeposits(values, rest))}
  >
    <p className="font-regular regular font-color--greyXDark margin-bottom--large">
      {textHelpers.getVerificationFormInfoText()}
    </p>

    <FormControl className="remove-margin-bottom">
      <VerifyFundingAccountInput errName="Micro deposit 1" name="microDeposit_1" />
      <VerifyFundingAccountInput errName="Micro deposit 2" name="microDeposit_2" />
    </FormControl>
  </form>
);

/**
 * props used in submit helpers (outside of this file):
 * - fundingAccountId
 * - fundingSourceId
 * - onCloseModal
 * - onRefreshFundingAccounts
 * - onSubmitVerifyFundingAccount
 */
VerifyFundingAccountForm.propTypes = {
  fundingAccountId: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  fundingSourceId: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  handleSubmit: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  onRefreshFundingAccounts: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  onSubmitVerifyFundingAccount: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
};

VerifyFundingAccountForm.defaultProps = {
  fundingAccountId: undefined,
  fundingSourceId: undefined,
};

const reduxFormWrapperVerifyFundingAccount = reduxForm({
  form: formNamesFunding.VERIFY_FUNDING_ACCOUNT,
  onSubmitSuccess: submitHelpers.handleVerifyFundingAccountSuccess,
  onSubmitFail: () => showFormFailureToast(formNamesFunding.VERIFY_FUNDING_ACCOUNT),
})(VerifyFundingAccountForm);

const mapStateToProps = (state) => ({
  fundingAccountId: vfaModalFundingAccountIdSelector(state),
  fundingSourceId: vfaModalFundingSourceIdSelector(state),
  initialValues: initialValuesHelpers.getVerifyFundingAccountInitialValues(),
  isOpen: modalIsOpenSelector(state, modalNameVerifyFundingAccount),
});

const mapDispatchToProps = {
  onCloseModal: verifyFundingAccountModalClose,
  onRefreshFundingAccounts: fetchFundingAccountsRequest,
  onSubmitVerifyFundingAccount: submitVerifyFundingAccount,
};

export { VerifyFundingAccountForm };
export default connect(mapStateToProps, mapDispatchToProps)(reduxFormWrapperVerifyFundingAccount);

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';
import { IconNames } from 'components/icon';

import { sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

/**
 * Close button used in Toolbar
 * @param onClick
 * @return {StatelessComponent}
 */
const ToolbarCloseButton = ({ onClick, className }) => (
  <div
    className={classnames('toolbar--button-wrapper margin-left--xm', {
      [className]: !!className,
    })}
  >
    <ButtonV2
      className="toolbar--close-button"
      intent={Intent.NEUTRAL}
      isIconButton
      leftIconName={IconNames.CROSS}
      leftIconSize={sizes.iconSizes.LARGE}
      onClick={onClick}
    />
  </div>
);

ToolbarCloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ToolbarCloseButton.defaultProps = {
  className: undefined,
};

export default ToolbarCloseButton;

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import {
  taxesDateTaxInfoSubmittedSelector,
  taxesInfoEditNotAllowedSelector,
  taxesInfoSubmittedPartnership,
} from 'selectors/taxesSelectors';

import CollectTaxInfoPage from './CollectTaxInfoPage';

const mapStateToProps = createStructuredSelector({
  collectedForThisPartnership: taxesInfoSubmittedPartnership,
  alreadyCollectedForDifferentPartnership: taxesInfoEditNotAllowedSelector,
  submissionDate: taxesDateTaxInfoSubmittedSelector,
});

const enhance = compose(withRouter, connect(mapStateToProps));

export default enhance(CollectTaxInfoPage);

import PropTypes from 'prop-types';
import React from 'react';
import { SubmissionError } from 'redux-form';

import ModalBodyContent from 'components/modal/components/body/ModalBodyContent';
import Modal from 'components/modal/Modal';

import { formNamesExternal } from 'constants/forms';

import CreateManualAddressForm from 'modules/address/createManualAddressFundingAccount/components/CreateManualAddressForm';
import Footer from 'modules/address/createManualAddressFundingAccount/components/Footer';

class CreateManualAddressFundingAccount extends React.Component {
  formId = formNamesExternal.CREATE_MANUAL_ADDRESS;

  componentDidUpdate(prevProps) {
    const { modal, onClearManualAddressFundingAccount } = prevProps;
    const { modal: nextModal } = this.props;
    if (nextModal.open && !modal.open) {
      // Opened the modal - clear the funding account success state
      onClearManualAddressFundingAccount();
    }
  }

  handleFormSubmit = async ({ address }) => {
    const { currentCompany, onCloseModal, onSubmitFundingAccountAddressManual } = this.props;

    const meta = {
      ...address,
      printCompany: address.printCompany || currentCompany.name || undefined,
      streetAddressUnit: address.streetAddressUnit || undefined,
    };

    const result = await onSubmitFundingAccountAddressManual({
      data: { type: 'FundingAccount' },
      meta,
    });

    if (result && result.errors) {
      throw new SubmissionError({
        address: result.errors.fields,
      });
    } else {
      onCloseModal();
    }

    return true;
  };

  render() {
    const { handleSubmit, isSubmitting, lastSubmitted, modal, onCloseModal } = this.props;

    return (
      <Modal
        body={
          <form
            aria-label="Create manual address form"
            className="form"
            id={formNamesExternal.CREATE_MANUAL_ADDRESS}
            onSubmit={handleSubmit(this.handleFormSubmit)}
          >
            <ModalBodyContent>{modal.open && <CreateManualAddressForm />}</ModalBodyContent>

            <Footer
              formId={this.formId}
              isSubmitting={isSubmitting}
              lastSubmitted={lastSubmitted}
              onCloseModal={onCloseModal}
            />
          </form>
        }
        hasInlineFooter
        modalHeader="Add address"
        modalState={modal.open}
        onCloseModal={onCloseModal}
      />
    );
  }
}

CreateManualAddressFundingAccount.propTypes = {
  currentCompany: PropTypes.shape().isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  lastSubmitted: PropTypes.instanceOf(Date),
  modal: PropTypes.shape().isRequired,
  onClearManualAddressFundingAccount: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSubmitFundingAccountAddressManual: PropTypes.func.isRequired,
};

CreateManualAddressFundingAccount.defaultProps = {
  isSubmitting: undefined,
  lastSubmitted: undefined,
};

export default CreateManualAddressFundingAccount;

import { z } from 'zod';
export const internationalBankFieldCategory = z.enum(['bank', 'bank_account', 'beneficiary']);
export const internationalBeneficiaryFieldName = z.enum([
    'country_of_nationality',
    'in_fcra',
    'mx_curp',
    'mx_rfc',
    'phone_number',
    'residential_status',
]);
export const internationalBeneficiaryFieldModel = z.object({
    category: z.literal(internationalBankFieldCategory.Enum.beneficiary),
    field: internationalBeneficiaryFieldName,
});
const internationalBankField = z.enum([
    'aba',
    'account_number',
    'bank_code',
    'bic_swift',
    'branch_code',
    'bsb_code',
    'clabe',
    'cnaps',
    'iban',
    'ifsc',
    'sort_code',
]);
export const internationalBankAccountFieldModel = z.object({
    category: z.literal(internationalBankFieldCategory.Enum.bank_account),
    field: internationalBankField,
});
export const internationalFieldBankCategoryModel = z.object({
    category: z.literal(internationalBankFieldCategory.Enum.bank),
    field: internationalBankField,
});
export const internationalBankFieldModel = z.discriminatedUnion('category', [
    internationalFieldBankCategoryModel,
    internationalBankAccountFieldModel,
    internationalBeneficiaryFieldModel,
]);
export const internationalBankFieldsApiResponseModel = z.object({
    data: z.array(internationalBankFieldModel),
});

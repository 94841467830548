export const breakpointNames = {
  allMedia: 'all_media',
  phone: 'phone',
  phoneLarge: 'phone',
  tabletSmall: 'tabletSmall',
  tablet: 'tablet',
  tabletLarge: 'tabletLarge',
  laptop: 'laptop',
  laptopLarge: 'laptopLarge',
  monitor: 'monitor',
};

export const breakpointClassPrefixes = {
  [breakpointNames.allMedia]: 'all_media',
  [breakpointNames.phone]: 'phone',
  [breakpointNames.phoneLarge]: 'phone_large',
  [breakpointNames.tabletSmall]: 'tablet_small',
  [breakpointNames.tablet]: 'tablet',
  [breakpointNames.tabletLarge]: 'tablet_large',
  [breakpointNames.laptop]: 'laptop',
  [breakpointNames.laptopLarge]: 'laptop_large',
  [breakpointNames.monitor]: 'monitor',
};

export const breakpoints = {
  [breakpointNames.allMedia]: '0px',
  [breakpointNames.phone]: '320px',
  [breakpointNames.phoneLarge]: '480px',
  [breakpointNames.tabletSmall]: '600px',
  [breakpointNames.tablet]: '768px',
  [breakpointNames.tabletLarge]: '1024px',
  [breakpointNames.laptop]: '1280px',
  [breakpointNames.laptopLarge]: '1440px',
  [breakpointNames.monitor]: '2100px',
};

export const mobileMediaQuery = `(max-width: ${breakpoints[breakpointNames.tablet]})`;
export const phoneMediaQuery = `(max-width: 480px)`;
export const tabletMediaQuery = `(min-width: 481px) and (max-width: 1025px)`;

import _flow from 'lodash/flow';
import { StaticCountryCode } from 'constants/countries';
import { compact, isArray } from 'helpers/utility';
export const filterCountryCodesByCountryPaymentOptions = (countryCodes, partnership) => {
    if (isArray(partnership?.countryPaymentOptions)) {
        return _flow([
            Object.entries,
            (arr) => arr.filter(([key]) => partnership.countryPaymentOptions.includes(key)),
            Object.fromEntries,
        ])(countryCodes);
    }
    return countryCodes;
};
export const byAlphabeticalOrder = (a, b) => {
    if (a.label && b.label) {
        return a.label.localeCompare(b.label);
    }
    return 0;
};
export const putPopularCountriesFirst = (countries) => compact([
    countries.find((country) => country.value === StaticCountryCode.US),
    countries.find((country) => country.value === StaticCountryCode.CA),
    ...countries.filter((country) => country.value !== StaticCountryCode.US && country.value !== StaticCountryCode.CA),
]);

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Divider from './Divider';

const SettingsDivider = ({ className }) => (
  <Divider
    className={classNames({
      'margin-bottom--x-large': true,
      'margin-top--large': true,
      [className]: !!className,
    })}
  />
);

SettingsDivider.propTypes = {
  className: PropTypes.string,
};

SettingsDivider.defaultProps = {
  className: undefined,
};

export default SettingsDivider;

import { PaymentDeliveryMethodType, PaymentDeliveryOptionType } from '@routable/shared';

import { isEqual } from 'helpers/utility';

/**
 * Check if payment delivery option is International Swift
 * @param {PaymentDeliveryOptionType} paymentDeliveryOption
 * @return {boolean}
 */
export const isPaymentDeliveryOptionInternationalSwift = (paymentDeliveryOption) =>
  isEqual(paymentDeliveryOption, PaymentDeliveryOptionType.INTERNATIONAL_SWIFT);

/**
 * Check if payment delivery option is International ACH
 * @param {PaymentDeliveryOptionType} paymentDeliveryOption
 * @return {boolean}
 */
export const isPaymentDeliveryOptionInternationalAch = (paymentDeliveryOption) =>
  isEqual(paymentDeliveryOption, PaymentDeliveryOptionType.INTERNATIONAL_ACH);

/**
 * Returns true if Payment Delivery Option is Same Day or Next Day
 * @param {PaymentDeliveryOption} paymentDeliveryOption
 * @returns {boolean}
 */
export const isPaymentDeliveryMethodSameOrNextDay = (paymentDeliveryOption) =>
  [PaymentDeliveryOptionType.ACH_SAME_DAY, PaymentDeliveryOptionType.ACH_NEXT_DAY].includes(paymentDeliveryOption);

/**
 * Check if payment delivery method is International
 * @param {PaymentDeliveryMethodType} paymentDeliveryMethod
 * @return {boolean}
 */
export const isPaymentDeliveryMethodInternational = (paymentDeliveryMethod) =>
  isEqual(paymentDeliveryMethod, PaymentDeliveryMethodType.INTERNATIONAL);

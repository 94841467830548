import * as types from 'types/notifications';

export const fetchNotificationSettingsRequest = (notificationSettingsId) => ({
  payload: { notificationSettingsId },
  type: types.FETCH_NOTIFICATION_SETTINGS_REQUEST,
});

export const fetchNotificationSettingsSuccess = (payload) => ({
  payload,
  type: types.FETCH_NOTIFICATION_SETTINGS_SUCCESS,
});

export const fetchNotificationSettingsFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_NOTIFICATION_SETTINGS_FAILURE,
});

export const fetchNotificationTableDataRequest = (notificationSettingsId) => ({
  payload: { notificationSettingsId },
  type: types.FETCH_NOTIFICATION_TABLE_DATA_REQUEST,
});

export const fetchNotificationTableDataSuccess = (payload) => ({
  payload,
  type: types.FETCH_NOTIFICATION_TABLE_DATA_SUCCESS,
});

export const fetchNotificationTableDataFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_NOTIFICATION_TABLE_DATA_FAILURE,
});

export const deleteEmailForwardRequest = (notificationSettingsId, emailForwardId) => ({
  payload: { notificationSettingsId, emailForwardId },
  type: types.DELETE_EMAIL_FORWARD_REQUEST,
});

export const deleteEmailForwardSuccess = (emailForwardId) => ({
  payload: { id: emailForwardId },
  type: types.DELETE_EMAIL_FORWARD_SUCCESS,
});

export const deleteEmailForwardFailure = (errors) => ({
  payload: { errors },
  type: types.DELETE_EMAIL_FORWARD_FAILURE,
});

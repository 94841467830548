import { BASE_API_URL } from './index';

export const notificationEndpoint = `${BASE_API_URL}/notification/`;

export const getNotificationSettingsEndpoint = (notificationSettingsId) =>
  `${notificationEndpoint}settings/${notificationSettingsId}/`;

export const getNotificationTableDataEndpoint = (notificationSettingsId) =>
  `${notificationEndpoint}settings/${notificationSettingsId}/descriptions/`;

export const getEmailForwardEndpoint = (notificationSettingsId) =>
  `${notificationEndpoint}settings/${notificationSettingsId}/email-forward/`;

export const modifyEmailForwardEndpoint = (notificationSettingsId, emailForwardId) =>
  `${notificationEndpoint}settings/${notificationSettingsId}/email-forward/${emailForwardId}/`;

import {
  DASHBOARD,
  SETTINGS_ACCOUNT_INVITE_TEAM_MEMBERS_ROUTE,
  SETTINGS_ACCOUNT_TEAM_MEMBERS_ROUTE,
} from 'constants/routes';

import { asPath } from 'helpers/routeHelpers';

export const pushHistoryWithPreviousState = (history, location, next) => {
  history.push(next, { previous: location.pathname });
};

export const goBackIfPreviousState = (history, location, fallback = asPath(DASHBOARD)) => {
  if (location.state && location.state.previous) {
    history.goBack();
  } else {
    pushHistoryWithPreviousState(history, location, fallback);
  }
};

/**
 * Use to maybe navigate to a route, pending its existence
 * @param history {History}
 * @param route {?string}
 */
export const safeNavigate = (history, route) => {
  if (route) {
    history.push(route);
  }
};

// type aliases, for clarity (original helper names are accurate/descriptive, but
// the purpose of using them isn't obvious in the actual code)
export const navigateToRoutedModal = pushHistoryWithPreviousState;
export const exitRoutedModal = goBackIfPreviousState;

/**
 * redirects to the /team_members page
 * @param history
 * @return {*}
 */
export const redirectToTeamMembersPage = (history) => history.push(SETTINGS_ACCOUNT_TEAM_MEMBERS_ROUTE);

/**
 * redirects to the /invite_team_members page
 * @param history
 * @return {*}
 */
export const redirectToInviteTeamMembersPage = (history) => history.push(SETTINGS_ACCOUNT_INVITE_TEAM_MEMBERS_ROUTE);

/**
 * redirects back to the previous location if it's present in the lastLocation hook,
 * otherwise redirects to the fallback path
 * @param {OptionsArg} options
 * @param {string} options.fallbackPath
 * @param {Object} options.history
 * @param {ObjectMaybe} options.lastLocation
 */
export const goBackIfLastLocation = ({ fallbackPath, history, lastLocation }) => {
  if (lastLocation) {
    history.goBack();
  } else {
    history.push(fallbackPath);
  }
};

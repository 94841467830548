import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { FormControl, SelectFieldV2 } from 'components';

import { companyBusinessTypeOptions } from 'constants/company';

import { requiredValidator } from 'helpers/fieldValidation';

/**
 * Select field listing business types: Sole Proprietor, C-Corp, etc. Clears noEIN checkbox one change so only
 * Sole Proprietors can enter SSNs.
 * @param {boolean} isDisabled
 * @returns {StatelessComponent}
 * @constructor
 */
const BusinessTypeSelect = ({ isDisabled }) => (
  <FormControl>
    <Field
      component={SelectFieldV2}
      isDisabled={isDisabled}
      label="Business Type"
      name="form.company.info.businessType"
      options={companyBusinessTypeOptions}
      validate={requiredValidator}
    />
  </FormControl>
);

BusinessTypeSelect.propTypes = {
  isDisabled: PropTypes.bool,
};

BusinessTypeSelect.defaultProps = {
  isDisabled: undefined,
};

export default BusinessTypeSelect;

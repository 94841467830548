import React from 'react';

import { IsDisabled } from 'context';

/**
 * When you want to get the value from IsDisabled, use this simple hook to fetch the context value.
 * @function
 * @returns {function} - When invoked, returns the value from IsDisabled.Provider.
 */
const useIsDisabledContext = () => React.useContext(IsDisabled);

export default useIsDisabledContext;

import PropTypes from 'prop-types';

/**
 * Intended to be wrapped by MapDataToAccess (connected component), but can be used standalone.
 *
 * @param props
 * {{allowAccess: boolean, children: *, onAccessAllowed: Function, onAccessDenied: Function,
 * renderNoAccess: Function}}
 * @return {*}
 *
 * @example
 * <RenderMapDataToAccess
 *   allowAccess={<boolean>}
 *   onAccessAllowed={() => console.log('access allowed')}
 *   onAccessDenied={() => console.log('access denied')}
 *   renderNoAccess={() => <p>You will see me if denied</p>}
 * >
 *   <MyProtectedContent />
 * </RenderMapDataToAccess>
 *
 * @constructor
 */
const RenderMapDataToAccess = (props) => {
  const { allowAccess, children, onAccessAllowed, onAccessDenied, renderNoAccess, ...rest } = props;

  if (allowAccess) {
    if (typeof onAccessAllowed === 'function') {
      onAccessAllowed();
    }

    return children;
  }

  if (typeof onAccessDenied === 'function') {
    onAccessDenied();
  }

  return renderNoAccess(rest);
};

RenderMapDataToAccess.propTypes = {
  allowAccess: PropTypes.bool,
  children: PropTypes.node,
  onAccessAllowed: PropTypes.func,
  onAccessDenied: PropTypes.func,
  renderNoAccess: PropTypes.func.isRequired,
};

RenderMapDataToAccess.defaultProps = {
  allowAccess: false,
  children: null,
  onAccessAllowed: undefined,
  onAccessDenied: undefined,
};

export default RenderMapDataToAccess;

import { combineReducers } from 'redux';

import currentUserAddressesReducer from './currentUserAddressesReducer';
import errorReducer from './errorReducer';
import fetchReducer from './fetchReducer';
import lastUpdatedReducer from './lastUpdatedReducer';
import ssoOnlyReducer from './ssoOnlyReducer';
import updateReducer from './updateReducer';
import userReducer from './userReducer';

const currentUserReducer = combineReducers({
  address: currentUserAddressesReducer,
  errors: errorReducer,
  isFetching: fetchReducer,
  isUpdating: updateReducer,
  lastUpdated: lastUpdatedReducer,
  ssoOnly: ssoOnlyReducer,
  user: userReducer,
});

export default currentUserReducer;

import classNames from 'classnames';
import React from 'react';
import { Text, FlexRow } from 'components';
import { paginationDataTestId } from 'constants/dataTestId';
import { PageNavLastPageButton, PageNavNextButton, PageNavPreviousButton } from './components';
import {} from './PageNav.types';
import './PageNav.scss';
export const PageNav = React.forwardRef(({ canNextPage, canPreviousPage, className, onGoToPage, pageCount, pageIndex }, ref) => (React.createElement(FlexRow, { className: classNames('tlp--page-nav', className), ref: ref, stackOnMobile: false },
    React.createElement("div", { className: "tlp--page-nav-wrapper" },
        React.createElement("div", { className: "page-navigation-block" },
            React.createElement(PageNavPreviousButton, { canPreviousPage: canPreviousPage, dataTestId: paginationDataTestId.paginationComponent.PAGE_NAV_PREVIOUS, onPreviousPage: (ev) => onGoToPage('PREV', ev) })),
        React.createElement(Text.Semibold, { className: "tlp-page-nav--display current-page page-navigation-block user-select--none", "data-testid": paginationDataTestId.paginationComponent.PAGE_INDEX }, pageIndex),
        React.createElement(Text.Semibold, { className: "tlp-page-nav--display page-navigation-block user-select--none" }, "/"),
        React.createElement("div", { className: "page-navigation-block" },
            React.createElement(PageNavLastPageButton, { dataTestId: paginationDataTestId.paginationComponent.PAGE_NAV_LAST_PAGE, onGoToPage: onGoToPage, pageCount: pageCount, pageIndex: pageIndex })),
        React.createElement("div", { className: "page-navigation-block" },
            React.createElement(PageNavNextButton, { canNextPage: canNextPage, dataTestId: paginationDataTestId.paginationComponent.PAGE_NAV_NEXT, onNextPage: (ev) => onGoToPage('NEXT', ev) }))))));
PageNav.displayName = 'PageNav';

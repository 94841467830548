import { createIsSubmittingReducer } from 'store/redux';

import {
  SUBMIT_COMPANY_AUTH_REP_FAILURE,
  SUBMIT_COMPANY_AUTH_REP_REQUEST,
  SUBMIT_COMPANY_AUTH_REP_SUCCESS,
} from 'types/company';

export const finishCases = [SUBMIT_COMPANY_AUTH_REP_FAILURE, SUBMIT_COMPANY_AUTH_REP_SUCCESS];
export const requestCases = [SUBMIT_COMPANY_AUTH_REP_REQUEST];

export const submitAuthRepsReducer = createIsSubmittingReducer(finishCases, requestCases);

export default submitAuthRepsReducer;

export const ADDENDA_MULTIPLE_PAYMENTS_TEXT = 'Addenda records cannot be added to multiple payments.';
export const ADDENDA_COMPANY_TYPE_PERSONAL_TEXT = 'Addenda records are unavailable for payments to vendors who are "Individuals."';
export const ADD_ADDENDA_RECORD_MESSAGE = 'Add addenda record';
export const EDIT_ADDENDA_RECORD_MESSAGE = 'Edit addenda record';
export const UPDATE_ADDENDA_RECORD_MESSAGE = 'Update addenda record';
export const ADDENDA_ADDED_TOAST_MESSAGE = 'Your addenda record has been added!';
export const ADDENDA_UPDATED_TOAST_MESSAGE = 'Your addenda record has been updated!';
export const ADDENDA_DELETED_TOAST_MESSAGE = 'Your addenda record has been deleted';
export const ADDENDA_CONFIRM_DELETE_SWAL_TEXT = "You're about to delete the addenda record from this ACH transaction. Are you sure you want to proceed?";
export const ADDENDA_MAX_CHAR_LIMIT = 80;
export const ADD_REMITTANCE_INFO_RECORD_MESSAGE = 'Add remittance info';
export const EDIT_REMITTANCE_INFO_RECORD_MESSAGE = 'Edit remittance info';
export const REMITTANCE_INFO_ADDED_TOAST_MESSAGE = 'Your remittance info has been added!';
export const REMITTANCE_INFO_CONFIRM_DELETE_SWAL_TEXT = "You're about to delete the remittance info from this RTP transaction. Are you sure you want to proceed?";
export const REMITTANCE_INFO_UPDATED_TOAST_MESSAGE = 'Your remittance info has been updated!';
export const REMITTANCE_INFO_DELETED_TOAST_MESSAGE = 'Your remittance info has been deleted';
export const UPDATE_REMITTANCE_INFO_RECORD_MESSAGE = 'Update remittance info';
export const REMITTANCE_INFO_MULTIPLE_PAYMENTS_TEXT = 'Remittance info cannot be added to multiple payments.';
export const REMITTANCE_INFO_MAX_CHAR_LIMIT = 140;
export const DIFFENT_BILL_CURRENCY_MULTIPLE_PAYMENTS_TEXT = 'We can only create payables for bills in one currency at a time. This bill is in a different currency than the ones already selected.';

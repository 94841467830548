import { bindRoutineToReduxForm, createRoutine, promisifyRoutine } from 'redux-saga-routines';

import { sharedFormSubmitRoutinePayloadCreators } from 'helpers/routine';

import {
  LOOKUP_MEMBERSHIP_INVITE_EMAIL,
  REVOKE_MEMBERSHIP_INVITE,
  SUBMIT_MEMBERSHIP_INVITE,
  SUBMIT_MEMBERSHIP_INVITE_ACCEPT_V2,
} from 'types/inviteTeamMember';

export const lookupMembershipInviteEmailRoutine = createRoutine(LOOKUP_MEMBERSHIP_INVITE_EMAIL);
/** @type (...args: unknown[]) => Promise */
export const lookupMembershipInviteEmailHandler = bindRoutineToReduxForm(lookupMembershipInviteEmailRoutine);

export const membershipInviteAcceptV2Routine = createRoutine(
  SUBMIT_MEMBERSHIP_INVITE_ACCEPT_V2,
  sharedFormSubmitRoutinePayloadCreators,
);
export const membershipInviteAcceptV2Handler = promisifyRoutine(membershipInviteAcceptV2Routine);

export const membershipInviteSubmitRoutine = createRoutine(
  SUBMIT_MEMBERSHIP_INVITE,
  sharedFormSubmitRoutinePayloadCreators,
);
export const membershipInviteSubmitHandler = promisifyRoutine(membershipInviteSubmitRoutine);

/**
 * A routine to revoke an outstanding membership invite to become a team member.
 * @type {ReduxActionCreator}
 */
export const revokeMembershipInviteRoutine = createRoutine(REVOKE_MEMBERSHIP_INVITE);

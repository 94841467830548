export const dwollaBizCategories = [
  {
    text: 'Food retail and service',
    id: 'f0a75a89-694a-42e7-938a-89a5fb366e8c',
    value: '9ed3f669-7d6f-11e3-b545-5404a6144203',
  },
  {
    text: 'Manufacturing',
    id: 'd22a4825-9015-4ceb-b41d-01cfc05ce5fe',
    value: '9ed41d89-7d6f-11e3-beff-5404a6144203',
  },
  {
    text: 'Business to business',
    id: '5861615f-97b7-40f4-98bb-5fb5bc343117',
    value: '9ed35a3b-7d6f-11e3-83c8-5404a6144203',
  },
  {
    text: 'Services - other',
    id: '99cfaf86-583b-4bb5-9528-17cfff262644',
    value: '9ed4449e-7d6f-11e3-a32d-5404a6144203',
  },
  {
    text: 'Entertainment and media',
    id: '39f1b3bb-45dd-4a9c-a1c5-2911e9b791bc',
    value: '9ed3a866-7d6f-11e3-a0ce-5404a6144203',
  },
  {
    text: 'Home and garden',
    id: '360137c0-646c-4789-8f9a-91b9309cc053',
    value: '9ed41d75-7d6f-11e3-b151-5404a6144203',
  },
  {
    text: 'Baby',
    id: '9c542d05-094e-4aad-aadb-0793de57f300',
    value: '9ed35a29-7d6f-11e3-930b-5404a6144203',
  },
  {
    text: 'Travel',
    id: '723fcee6-8a7d-4a8c-9420-f41fd39e6b21',
    value: '9ed492c6-7d6f-11e3-80f4-5404a6144203',
  },
  {
    text: 'Clothing, accessories, and shoes',
    id: '32a4e865-8a70-4d13-a829-6d0e0b209fd7',
    value: '9ed38152-7d6f-11e3-9042-5404a6144203',
  },
  {
    text: 'Health and personal care',
    id: '220aaad1-0be5-4a84-b5e4-92da5391440c',
    value: '9ed3f686-7d6f-11e3-af6e-5404a6144203',
  },
  {
    text: 'Beauty and fragrances',
    id: 'a0987d8b-e28e-4038-a3ec-f1655f25a38a',
    value: '9ed35a2e-7d6f-11e3-a5cf-5404a6144203',
  },
  {
    text: 'Computers, accessories, and services',
    id: '8ccf1232-7e4a-40d1-b151-882a3e21163f',
    value: '9ed3a846-7d6f-11e3-8a79-5404a6144203',
  },
  {
    text: 'Retail',
    id: '27905b66-ec1f-4f98-a646-f34e3e0ce09c',
    value: '9ed44496-7d6f-11e3-865d-5404a6144203',
  },
  {
    text: 'Toys and hobbies',
    id: 'e3e72e57-36f9-4209-99db-594b1f368d04',
    value: '9ed492bc-7d6f-11e3-9a1b-5404a6144203',
  },
  {
    text: 'Pets and animals',
    id: '3ed0f26d-f6c6-4c7b-aa32-5b971c4c6f22',
    value: '9ed4448d-7d6f-11e3-aab2-5404a6144203',
  },
  {
    text: 'Education',
    id: 'bc6400dc-7c25-4862-b0ca-f7c96f1cd7d0',
    value: '9ed3a854-7d6f-11e3-a193-5404a6144203',
  },
  {
    text: 'Arts, crafts, and collectibles',
    id: '6b04de2a-20c6-414e-b1b0-201dd0d49f3c',
    value: '9ed248ae-7d6f-11e3-ba6e-5404a6144203',
  },
  {
    text: 'Financial services and products',
    id: '0d6a4474-8242-406d-9d46-e551c0df22bc',
    value: '9ed3cf5f-7d6f-11e3-8af8-5404a6144203',
  },
  {
    text: 'Government',
    id: '171b9394-94ea-4a23-8b52-0927c2e89aa9',
    value: '9ed3f67d-7d6f-11e3-bf40-5404a6144203',
  },
  {
    text: 'Vehicle sales',
    id: '09658247-01aa-44ca-a2ea-e9df89b0d874',
    value: '9ed4b9bc-7d6f-11e3-9133-5404a6144203',
  },
  {
    text: 'Nonprofit',
    id: '381f3d1b-cb46-4627-a692-12d87dd241f3',
    value: '9ed44486-7d6f-11e3-89f8-5404a6144203',
  },
  {
    text: 'Vehicle service and accessories',
    id: '13a46843-2259-48f8-8d7f-a06c4483e335',
    value: '9ed4b9c6-7d6f-11e3-a156-5404a6144203',
  },
  {
    text: 'Electronics and telecom',
    id: '8983556b-cbc8-4d98-b26c-f6ba0b40fc7e',
    value: '9ed3a85b-7d6f-11e3-8995-5404a6144203',
  },
  {
    text: 'Books and magazines',
    id: '3bca7def-1376-4740-88fd-564e0c3aca89',
    value: '9ed35a32-7d6f-11e3-9830-5404a6144203',
  },
  {
    text: 'Religion and spirituality (for profit)',
    id: '247a6112-8bc3-46c3-a90a-5d22957c763c',
    value: '9ed44492-7d6f-11e3-98d1-5404a6144203',
  },
  {
    text: 'Sports and outdoors',
    id: '2af8aab6-2b30-4957-b23c-ff14b1bb1f22',
    value: '9ed492ac-7d6f-11e3-a2d2-5404a6144203',
  },
  {
    text: 'Gifts and flowers',
    id: 'dee02b13-aee7-4923-9b1b-bc7a878aa8b4',
    value: '9ed3f677-7d6f-11e3-96a2-5404a6144203',
  },
];

export const getDwollaBizClassifications = (bizCategoryId) => {
  const industryClassifications = {
    '9ed4b9bc-7d6f-11e3-9133-5404a6144203': [
      {
        text: 'Vintage and collectibles',
        id: 'd22b0949-e572-4c13-9cba-c173c8e4bf6f',
        value: '9ed4b9c5-7d6f-11e3-8b92-5404a6144203',
      },
      {
        text: 'Boat dealer',
        id: '454ea9f4-dbf4-46f1-8206-8ac20b683ead',
        value: '9ed4b9c0-7d6f-11e3-9035-5404a6144203',
      },
      {
        text: 'Motorcycle dealer',
        id: '540bdab5-6c99-49dc-be88-86e3f5ad9121',
        value: '9ed4b9c2-7d6f-11e3-9147-5404a6144203',
      },
      {
        text: 'Recreational vehicle dealer',
        id: '6a514879-9196-4af7-8146-8b9685df9e2f',
        value: '9ed4b9c4-7d6f-11e3-97b9-5404a6144203',
      },
      {
        text: 'Mobile home dealer',
        id: '682ea0a8-4195-4c87-bdc6-7cc2c2cb67e5',
        value: '9ed4b9c1-7d6f-11e3-99a2-5404a6144203',
      },
      {
        text: 'Auto dealer - used only',
        id: '90b0185c-210e-4990-9230-af5599fd0e7c',
        value: '9ed4b9be-7d6f-11e3-a3eb-5404a6144203',
      },
      {
        text: 'Aviation',
        id: 'e41b31ef-ad41-4e67-b5c0-f090ad2e80ba',
        value: '9ed4b9bf-7d6f-11e3-ad02-5404a6144203',
      },
      {
        text: 'Recreational and utility trailer dealer',
        id: 'c20e584e-e4ed-4a4d-bc13-a70315798e93',
        value: '9ed4b9c3-7d6f-11e3-ba27-5404a6144203',
      },
      {
        text: 'Auto dealer - new and used',
        id: '639704ff-d4ce-46a1-8206-d3352e62173e',
        value: '9ed4b9bd-7d6f-11e3-bb61-5404a6144203',
      },
    ],
    '9ed35a2e-7d6f-11e3-a5cf-5404a6144203': [
      {
        text: 'Bath and body',
        id: '6e3b1723-1eb5-44b8-ad2f-83f8f3e25f01',
        value: '9ed35a2f-7d6f-11e3-8593-5404a6144203',
      },
      {
        text: 'Fragrances and perfumes',
        id: '601319cb-ae04-4903-8d51-54fae783f7f9',
        value: '9ed35a30-7d6f-11e3-b9d5-5404a6144203',
      },
      {
        text: 'Makeup and cosmetics',
        id: 'd3b8284b-1c5e-483e-bda4-68e4d0ee2efb',
        value: '9ed35a31-7d6f-11e3-bf68-5404a6144203',
      },
    ],
    '9ed492c6-7d6f-11e3-80f4-5404a6144203': [
      {
        text: 'Tours',
        id: 'aa9c5710-fca4-4b8a-b2c0-7ccc6c8be247',
        value: '9ed4b9b8-7d6f-11e3-8329-5404a6144203',
      },
      {
        text: 'Bus line',
        id: 'f1474be9-3d5d-49d9-b1b1-fc42408b31be',
        value: '9ed4b9b0-7d6f-11e3-8f2f-5404a6144203',
      },
      {
        text: 'Airline',
        id: '79e0d90c-01d9-4b20-a6c5-63ea9ad2fb9f',
        value: '9ed492c7-7d6f-11e3-8fc5-5404a6144203',
      },
      {
        text: 'Sporting and recreation camps',
        id: '1ee562f3-6680-40b7-82ea-1161da42835d',
        value: '9ed4b9b5-7d6f-11e3-9031-5404a6144203',
      },
      {
        text: 'Cruises',
        id: '6e078e06-0c5a-4f67-a754-db6c832f0efd',
        value: '9ed4b9b1-7d6f-11e3-9ce7-5404a6144203',
      },
      {
        text: 'Lodging and accommodations',
        id: '0e621f97-23f5-4564-987b-368fb5e5259c',
        value: '9ed4b9b2-7d6f-11e3-a1d5-5404a6144203',
      },
      {
        text: 'Timeshares',
        id: '4e921fea-0a01-4851-bbcd-91e8cb5bd346',
        value: '9ed4b9b7-7d6f-11e3-a615-5404a6144203',
      },
      {
        text: 'Luggage and leather goods',
        id: '27bdd981-64c8-4a0a-8e24-ed0806817b0a',
        value: '9ed4b9b3-7d6f-11e3-aea7-5404a6144203',
      },
      {
        text: 'Travel agency',
        id: '88c960f9-62cc-431d-94f3-f72be58ac025',
        value: '9ed4b9bb-7d6f-11e3-af9f-5404a6144203',
      },
      {
        text: 'Taxicabs and limousines',
        id: '3df29d52-fced-460b-935f-0e4df3a2c708',
        value: '9ed4b9b6-7d6f-11e3-b843-5404a6144203',
      },
      {
        text: 'Auto rental',
        id: 'eb5df862-cf7d-40c5-87ae-989c3f8a10f3',
        value: '9ed492c8-7d6f-11e3-ba44-5404a6144203',
      },
      {
        text: 'Transportation services - other',
        id: '73b7bbab-0951-4350-a2d0-b88880eb11d2',
        value: '9ed4b9ba-7d6f-11e3-bbf4-5404a6144203',
      },
      {
        text: 'Recreational services',
        id: 'b6f8039e-807d-4b6b-977e-af5ab682b519',
        value: '9ed4b9b4-7d6f-11e3-bcfd-5404a6144203',
      },
      {
        text: 'Trailer parks and campgrounds',
        id: 'fa76f35d-9346-42c0-bff7-f7621f7732cc',
        value: '9ed4b9b9-7d6f-11e3-bf00-5404a6144203',
      },
    ],
    '9ed492bc-7d6f-11e3-9a1b-5404a6144203': [
      {
        text: 'Hobby, toy, and game shops',
        id: '9b8c454a-55e9-4f2d-8a61-981e1840276e',
        value: '9ed492bf-7d6f-11e3-87d7-5404a6144203',
      },
      {
        text: 'Stationery, printing, and writing paper',
        id: 'abfbc6a5-b8c0-46d6-b189-e8fe9bfb78cf',
        value: '9ed492c3-7d6f-11e3-96a8-5404a6144203',
      },
      {
        text: 'Vintage and collectibles',
        id: 'd006f363-de92-4d20-9ea1-b1ff68df1456',
        value: '9ed492c4-7d6f-11e3-9f0c-5404a6144203',
      },
      {
        text: 'Video games and systems',
        id: '62c7ade0-d042-4590-9a5a-3e5c1845f2c1',
        value: '9ed492c5-7d6f-11e3-9fe5-5404a6144203',
      },
      {
        text: 'Arts and crafts',
        id: 'f3cf32a4-7d4f-454d-b4d3-996db93f4d77',
        value: '9ed492bd-7d6f-11e3-ab0a-5404a6144203',
      },
      {
        text: 'Memorabilia',
        id: '5bb6be3c-db86-439b-80cf-b4bc1e2e2f93',
        value: '9ed492c0-7d6f-11e3-b1b2-5404a6144203',
      },
      {
        text: 'Stamp and coin',
        id: 'e9c7c61a-8d89-413b-baa3-74ef8a81f405',
        value: '9ed492c2-7d6f-11e3-b58e-5404a6144203',
      },
      {
        text: 'Music store - instruments and sheet music',
        id: 'f4d18b01-3c6e-43fa-8ee5-bfb65bf9b6c2',
        value: '9ed492c1-7d6f-11e3-b5a1-5404a6144203',
      },
      {
        text: 'Camera and photographic supplies',
        id: '4025d398-83e1-4611-9bc7-817a1fe855cf',
        value: '9ed492be-7d6f-11e3-bf01-5404a6144203',
      },
    ],
    '9ed248ae-7d6f-11e3-ba6e-5404a6144203': [
      {
        text: 'Stamp and coin',
        id: '834c11da-a054-435c-8659-9f6f47b27b7f',
        value: '9ed35a26-7d6f-11e3-890f-5404a6144203',
      },
      {
        text: 'Digital art',
        id: 'a865d921-4fb0-4f2f-9f02-372266c19603',
        value: '9ed35a22-7d6f-11e3-9b52-5404a6144203',
      },
      {
        text: 'Sewing, needlework, and fabrics',
        id: 'b7530a6f-e225-456f-b2be-ecb25cc75f20',
        value: '9ed35a25-7d6f-11e3-a03c-5404a6144203',
      },
      {
        text: 'Camera and photographic supplies',
        id: 'e134a2fc-b153-4876-8f6d-322fb92c57c3',
        value: '9ed35a21-7d6f-11e3-a35c-5404a6144203',
      },
      {
        text: 'Antiques',
        id: '26228135-c961-4238-9ef1-0a99fbdd04ae',
        value: '9ed35a1e-7d6f-11e3-a517-5404a6144203',
      },
      {
        text: 'Stationery, printing and writing paper',
        id: '6fa97b05-2198-42dd-bb97-cc5a0c325d78',
        value: '9ed35a27-7d6f-11e3-aa1b-5404a6144203',
      },
      {
        text: 'Art dealers and galleries',
        id: '5418b041-f864-46a0-8dda-a89bbf5c1ed7',
        value: '9ed35a20-7d6f-11e3-ac6a-5404a6144203',
      },
      {
        text: 'Memorabilia',
        id: '5cc6629d-05af-4c74-8aa3-9415416096e4',
        value: '9ed35a23-7d6f-11e3-b222-5404a6144203',
      },
      {
        text: 'Music store - instruments and sheet music',
        id: '155c43e4-fb5f-4c22-9554-cd7ab7f08de4',
        value: '9ed35a24-7d6f-11e3-b2a3-5404a6144203',
      },
      {
        text: 'Art and craft supplies',
        id: '39bd5d83-7d46-4648-8d17-4913de0c213f',
        value: '9ed35a1f-7d6f-11e3-ba72-5404a6144203',
      },
      {
        text: 'Vintage and collectibles',
        id: 'd82b4bfb-7bea-49da-8b45-16f7de1430fd',
        value: '9ed35a28-7d6f-11e3-bdec-5404a6144203',
      },
    ],
    '9ed3f67d-7d6f-11e3-bf40-5404a6144203': [
      {
        text: 'Administration of economic programs',
        id: '2e09a3da-950f-44b4-b2ec-cca517947b75',
        value: '9ed3f683-7d6f-11e3-8a57-5404a6144203',
      },
      {
        text: 'Administration of housing programs, urban planning, and community development',
        id: '99f0fa4b-873e-471d-bddc-c8f251727e66',
        value: '9ed3f682-7d6f-11e3-925c-5404a6144203',
      },
      {
        text: 'Administration of human resource programs',
        id: '06497198-374e-48fc-956d-fc63f1ff3a89',
        value: '9ed3f680-7d6f-11e3-945b-5404a6144203',
      },
      {
        text: 'Administration and environmental quality programs',
        id: 'fbedb5f6-0732-4ac4-a2fb-b9eade1d7953',
        value: '9ed3f681-7d6f-11e3-99ff-5404a6144203',
      },
      {
        text: 'Justice, public order, and safety activities',
        id: 'b6445547-68f5-4e64-b789-a269fd1a4928',
        value: '9ed3f67f-7d6f-11e3-a83d-5404a6144203',
      },
      {
        text: 'Executive, legislative, and other general government support',
        id: '5a6a6dd4-fa2b-4b04-a0a4-05082d6268f6',
        value: '9ed3f67e-7d6f-11e3-b5c9-5404a6144203',
      },
      {
        text: 'Space research and technology',
        id: 'e85b41e4-0c72-4a11-8dc6-5334f3a606cd',
        value: '9ed3f684-7d6f-11e3-b716-5404a6144203',
      },
      {
        text: 'National security and international affairs',
        id: 'afd99e03-f68b-454c-bcc2-9b4b3f34e106',
        value: '9ed3f685-7d6f-11e3-ba5b-5404a6144203',
      },
    ],
    '9ed3f669-7d6f-11e3-b545-5404a6144203': [
      {
        text: 'Gourmet foods',
        id: 'd165280f-b482-47bf-a70e-0bc060956323',
        value: '9ed3f671-7d6f-11e3-803c-5404a6144203',
      },
      {
        text: 'Distilleries',
        id: '67ab2a23-8f58-464f-930e-9c738d8b70d3',
        value: '9ed3f66c-7d6f-11e3-86ae-5404a6144203',
      },
      {
        text: 'Breweries',
        id: '7308fa11-1933-45af-ab9d-302664e8e241',
        value: '9ed3f66a-7d6f-11e3-8acd-5404a6144203',
      },
      {
        text: 'Alcoholic beverage drinking places',
        id: '6bf0ee89-9142-4d3b-be6c-e051954442e4',
        value: '9ed3f66d-7d6f-11e3-9101-5404a6144203',
      },
      {
        text: 'Beer, wine, and liquor store',
        id: '098e5916-a1cd-447e-b5b1-83d49d5e46ed',
        value: '9ed3f66e-7d6f-11e3-9480-5404a6144203',
      },
      {
        text: 'Wineries',
        id: 'f866825f-0e1e-4d70-8e9d-8d53c740d99c',
        value: '9ed3f66b-7d6f-11e3-95ac-5404a6144203',
      },
      {
        text: 'Tobacco',
        id: 'cdb65ed3-93d3-4fc2-9a22-2408cdc25085',
        value: '9ed3f674-7d6f-11e3-9619-5404a6144203',
      },
      {
        text: 'Restaurant',
        id: 'c7d11c02-8944-483a-b969-e311f7be3823',
        value: '9ed3f673-7d6f-11e3-adb1-5404a6144203',
      },
      {
        text: 'Supplement store',
        id: '59273dc2-03ba-419d-b095-877cf5113b53',
        value: '9ed3f676-7d6f-11e3-af8e-5404a6144203',
      },
      {
        text: 'Pharmacy and drugstore',
        id: '30a899e3-a35c-4cae-bfbf-7e577adc53aa',
        value: '9ed3f675-7d6f-11e3-afad-5404a6144203',
      },
      {
        text: 'Coffee and tea',
        id: 'a2b1f96b-0c80-4c6f-9f30-7f788d1d5772',
        value: '9ed3f670-7d6f-11e3-b1ce-5404a6144203',
      },
      {
        text: 'Catering services',
        id: '4e7a8a02-d5ff-4c9b-a70a-a1ec40944864',
        value: '9ed3f66f-7d6f-11e3-b1df-5404a6144203',
      },
      {
        text: 'Specialty and miscellaneous food store',
        id: '37e06e01-93fb-4e49-b0c9-57c7a6933245',
        value: '9ed3f672-7d6f-11e3-b67a-5404a6144203',
      },
    ],
    '9ed41d75-7d6f-11e3-b151-5404a6144203': [
      {
        text: 'Antiques',
        id: 'c423f795-e256-4e28-a3c9-c0921efc3dc2',
        value: '9ed41d76-7d6f-11e3-81ef-5404a6144203',
      },
      {
        text: 'Glass, paint, and wallpaper',
        id: '00bbdd99-e878-46ec-9cc0-197cd6c23799',
        value: '9ed41d80-7d6f-11e3-857a-5404a6144203',
      },
      {
        text: 'Appliances',
        id: '9bd4d01e-db43-47ff-a8dc-c1c829ee992d',
        value: '9ed41d77-7d6f-11e3-8cda-5404a6144203',
      },
      {
        text: 'Exterminating and disinfecting services',
        id: 'd7d3f71a-45c1-4f3d-b772-d7a05f647f27',
        value: '9ed41d7c-7d6f-11e3-8fb6-5404a6144203',
      },
      {
        text: 'Drapery, window covering, and upholstery',
        id: '63a23e9d-1c32-496a-b5ae-ccd350644e64',
        value: '9ed41d7b-7d6f-11e3-9534-5404a6144203',
      },
      {
        text: 'Hardware and tools',
        id: '70af2de3-d555-4bc4-8255-b1976db5bfe8',
        value: '9ed41d81-7d6f-11e3-9904-5404a6144203',
      },
      {
        text: 'Rugs and carpets',
        id: 'f9a5530c-3753-4a1a-b790-61fa8ea76f9a',
        value: '9ed41d86-7d6f-11e3-9e98-5404a6144203',
      },
      {
        text: 'Furniture',
        id: 'de260868-8d4a-464d-a151-573b38a744a6',
        value: '9ed41d7e-7d6f-11e3-a3fc-5404a6144203',
      },
      {
        text: 'Construction material',
        id: '6ffc6fb9-139c-4ab4-8030-5360a0f24b82',
        value: '9ed41d7a-7d6f-11e3-ae21-5404a6144203',
      },
      {
        text: 'Lawn and garden equipment and supplies',
        id: '3c4b201c-bbfc-488c-9095-941eedaeb719',
        value: '9ed41d7f-7d6f-11e3-b918-5404a6144203',
      },
      {
        text: 'Home furnishings store',
        id: 'f2c22a36-7d31-4cac-91d0-5801d90de22d',
        value: '9ed41d84-7d6f-11e3-bc20-5404a6144203',
      },
      {
        text: 'Art dealers and galleries',
        id: 'a61ea693-f579-4769-9c30-d5c465fb1db2',
        value: '9ed41d78-7d6f-11e3-bd3f-5404a6144203',
      },
    ],
    '9ed3a866-7d6f-11e3-a0ce-5404a6144203': [
      {
        text: 'Toys and games',
        id: '4e2e1fa7-625b-4803-860c-f3324e08d851',
        value: '9ed3cf58-7d6f-11e3-81a4-5404a6144203',
      },
      {
        text: 'Music',
        id: '4e048686-b2ab-489d-a7b2-a56ebb5a659e',
        value: '9ed3cf50-7d6f-11e3-8ae8-5404a6144203',
      },
      {
        text: 'Gambling',
        id: 'd53a57bc-efa3-4d11-8578-236576a6aa82',
        value: '9ed3cf5c-7d6f-11e3-8d0e-5404a6144203',
      },
      {
        text: 'Cable, satellite, and other pay TV and radio broadcasting',
        id: 'fd434fd7-6321-49d4-89f8-ff78698b3d48',
        value: '9ed3cf53-7d6f-11e3-8ee9-5404a6144203',
      },
      {
        text: 'Slot machines',
        id: 'a2d86ed0-4fec-46fa-8e84-f7311264f635',
        value: '9ed3cf59-7d6f-11e3-9158-5404a6144203',
      },
      {
        text: 'Theater tickets',
        id: '7babf324-0379-4f5b-beaf-51f9c2d4083d',
        value: '9ed3cf57-7d6f-11e3-921d-5404a6144203',
      },
      {
        text: 'Motion picture and video',
        id: '44ea7995-de4c-4ae2-917c-2d40eed522cc',
        value: '9ed3cf4f-7d6f-11e3-97ea-5404a6144203',
      },
      {
        text: 'Digital content',
        id: '20436b09-fd90-433a-a3a8-e66afe3d55f0',
        value: '9ed3cf5a-7d6f-11e3-9a99-5404a6144203',
      },
      {
        text: 'Entertainers',
        id: 'a7803c0c-f95d-4d41-a4be-da7f9f55e5c2',
        value: '9ed3cf5b-7d6f-11e3-a368-5404a6144203',
      },
      {
        text: 'Memorabilia',
        id: '9c03d684-899e-4d4f-aeed-8e1ffc30ffc1',
        value: '9ed3a867-7d6f-11e3-a6e4-5404a6144203',
      },
      {
        text: 'Music store - CDs, cassettes and albums',
        id: '03a4a67b-4abc-42d8-95a1-a7139c1f1126',
        value: '9ed3cf52-7d6f-11e3-b0da-5404a6144203',
      },
      {
        text: 'Online gaming',
        id: '6cf5bbb7-ba5a-45f3-9339-dbd4816bfa41',
        value: '9ed3cf5d-7d6f-11e3-b35e-5404a6144203',
      },
      {
        text: 'Adult digital content',
        id: 'e06e3cdd-398f-4476-bc11-ea6c8e40bad8',
        value: '9ed3cf55-7d6f-11e3-b43c-5404a6144203',
      },
      {
        text: 'Movie store - DVDs, videotapes',
        id: '38f67781-efc1-4fec-8abc-37db892a91aa',
        value: '9ed3cf51-7d6f-11e3-b49f-5404a6144203',
      },
      {
        text: 'Video games and systems',
        id: 'ffe54fcb-db5e-4d8b-a976-4070ecff3758',
        value: '9ed3cf5e-7d6f-11e3-b9d5-5404a6144203',
      },
      {
        text: 'Concert tickets',
        id: '4a8c297a-a3df-4829-b87c-3546dd56d431',
        value: '9ed3cf56-7d6f-11e3-ba87-5404a6144203',
      },
      {
        text: 'Cable and other subscription programming',
        id: '283b005d-7a8e-469c-a066-ab32ffa44710',
        value: '9ed3cf54-7d6f-11e3-bf23-5404a6144203',
      },
    ],
    '9ed38152-7d6f-11e3-9042-5404a6144203': [
      {
        text: "Women's clothing",
        id: '24f1a6cb-fe93-4890-aedc-567a84953f11',
        value: '9ed38155-7d6f-11e3-83c3-5404a6144203',
      },
      {
        text: 'Military and civil service uniforms',
        id: '8d2ace01-7b6d-419a-b639-51c90f51af93',
        value: '9ed3a841-7d6f-11e3-954f-5404a6144203',
      },
      {
        text: "Children's and baby's clothing",
        id: '17c5a481-a0e9-411c-a07f-0af8e3dc03bc',
        value: '9ed38153-7d6f-11e3-97e3-5404a6144203',
      },
      {
        text: "Men's clothing",
        id: '0431103c-bae8-411f-97a5-aa2f799471ce',
        value: '9ed38154-7d6f-11e3-9b0d-5404a6144203',
      },
      {
        text: 'Wholesale - precious stones and metals',
        id: 'dca6f018-c5ab-497f-9117-eb3f65cabc32',
        value: '9ed3a844-7d6f-11e3-9d68-5404a6144203',
      },
      {
        text: 'Shoes',
        id: '56f0cc18-31d9-4f6c-8e3e-f9e3a7ce5932',
        value: '9ed3a840-7d6f-11e3-a314-5404a6144203',
      },
      {
        text: 'Fashion jewelry',
        id: '18795233-a889-453a-a70f-00e177552516',
        value: '9ed3a845-7d6f-11e3-b307-5404a6144203',
      },
      {
        text: 'Accessories',
        id: 'e0274079-7484-4ea0-8a0c-3e8cd10b58d6',
        value: '9ed3a842-7d6f-11e3-bba3-5404a6144203',
      },
      {
        text: 'Retail - fine jewelry and watches',
        id: 'aed2c12a-c5df-479c-9329-c8f20aff7a86',
        value: '9ed3a843-7d6f-11e3-bbb4-5404a6144203',
      },
    ],
    '9ed3a85b-7d6f-11e3-8995-5404a6144203': [
      {
        text: 'General electronic accessories',
        id: '7544c29a-def2-4309-962d-8f6c46d999ed',
        value: '9ed3a85f-7d6f-11e3-8e3b-5404a6144203',
      },
      {
        text: 'Cell phones, PDAs, and pagers',
        id: 'fcbdcb2e-721f-4d23-8747-9949f4620a08',
        value: '9ed3a85e-7d6f-11e3-9379-5404a6144203',
      },
      {
        text: 'Car audio and electronics',
        id: '21c1cc58-12b2-48eb-a45c-f868677d8179',
        value: '9ed3a85d-7d6f-11e3-9b4f-5404a6144203',
      },
      {
        text: 'Telecommunication equipment and sales',
        id: '9fde3bf0-a21e-4353-bc47-2cbc86c0dca4',
        value: '9ed3a863-7d6f-11e3-9f20-5404a6144203',
      },
      {
        text: 'Telephone cards',
        id: '22b2ab6a-0e37-4ff5-b3b9-b28aadc8c7c6',
        value: '9ed3a865-7d6f-11e3-a3d0-5404a6144203',
      },
      {
        text: 'Home electronics',
        id: '47fe595a-17a2-4903-911e-fedde629babb',
        value: '9ed3a861-7d6f-11e3-a572-5404a6144203',
      },
      {
        text: 'Cameras, camcorders and equipment',
        id: '1c3f7ef2-4531-4b7f-9036-19c4c1f77c8a',
        value: '9ed3a85c-7d6f-11e3-af39-5404a6144203',
      },
      {
        text: 'Investigation and security services',
        id: '4cab8803-5ee1-4caa-9bcb-d0d2ac324044',
        value: '9ed3a862-7d6f-11e3-b65b-5404a6144203',
      },
      {
        text: 'Telecommunication services',
        id: '33a6f4ab-b326-4b28-a138-dd723d17dd8a',
        value: '9ed3a864-7d6f-11e3-bcd4-5404a6144203',
      },
      {
        text: 'Home audio',
        id: '93ffa49a-6278-41f7-a091-4c9b2eb9a951',
        value: '9ed3a860-7d6f-11e3-befb-5404a6144203',
      },
    ],
    '9ed3a846-7d6f-11e3-8a79-5404a6144203': [
      {
        text: 'Maintenance and repair services',
        id: '83675278-f43a-42f3-a886-a44b89195878',
        value: '9ed3a84b-7d6f-11e3-8661-5404a6144203',
      },
      {
        text: 'Online gaming',
        id: 'f2940120-a4af-4145-a22f-d5975a1d7751',
        value: '9ed3a84e-7d6f-11e3-87df-5404a6144203',
      },
      {
        text: 'Software',
        id: '502b9fe3-8f77-41ed-be9d-dac1204288e5',
        value: '9ed3a851-7d6f-11e3-92a4-5404a6144203',
      },
      {
        text: 'Monitors and projectors',
        id: '278620b4-87dc-4afd-896f-f0dc3bf9b985',
        value: '9ed3a84c-7d6f-11e3-9a6e-5404a6144203',
      },
      {
        text: 'Networking',
        id: 'f83d4245-4e97-4133-8323-2fa3bed51233',
        value: '9ed3a84d-7d6f-11e3-9eca-5404a6144203',
      },
      {
        text: 'Web hosting and design',
        id: '47e0e2d3-2877-4637-be45-0a0322b8b5cc',
        value: '9ed3a853-7d6f-11e3-a35d-5404a6144203',
      },
      {
        text: 'Training services',
        id: 'c1afd410-f19e-4ae7-9b06-ce661f30a221',
        value: '9ed3a852-7d6f-11e3-aba3-5404a6144203',
      },
      {
        text: 'Peripherals',
        id: 'd0379c57-015c-4e92-b3f9-edf07511cde0',
        value: '9ed3a850-7d6f-11e3-ad7d-5404a6144203',
      },
      {
        text: 'Desktops, laptops, and notebooks',
        id: 'c8d46787-c8c3-4135-8f15-596e707a8830',
        value: '9ed3a848-7d6f-11e3-af5a-5404a6144203',
      },
      {
        text: 'Parts and accessories',
        id: '6be052b4-1e9b-4fac-b251-c22ccd1cdabd',
        value: '9ed3a84f-7d6f-11e3-b1a4-5404a6144203',
      },
      {
        text: 'eCommerce services',
        id: 'f019be31-2c66-4de7-9141-b55e4c665494',
        value: '9ed3a84a-7d6f-11e3-b682-5404a6144203',
      },
      {
        text: 'Computer and data processing services',
        id: '9cebd721-09b6-454c-96ca-d459d4d24d6a',
        value: '9ed3a847-7d6f-11e3-b813-5404a6144203',
      },
    ],
    '9ed35a29-7d6f-11e3-930b-5404a6144203': [
      {
        text: 'Safety and health',
        id: '499a48ab-a135-4b6f-9d53-a45295f5af8a',
        value: '9ed35a2d-7d6f-11e3-8284-5404a6144203',
      },
      {
        text: 'Furniture',
        id: '99f365ba-03d1-4a5c-a2bd-846e99e5e93b',
        value: '9ed35a2b-7d6f-11e3-942f-5404a6144203',
      },
      {
        text: "Children's and baby's clothing",
        id: '0b8bf1ff-0d4a-42db-91da-939c1ad7a60f',
        value: '9ed35a2a-7d6f-11e3-b976-5404a6144203',
      },
    ],
    '9ed3a854-7d6f-11e3-a193-5404a6144203': [
      {
        text: 'Vocational and trade schools',
        id: '21663da1-dc98-412f-b86b-fb975c5d87b6',
        value: '9ed3a85a-7d6f-11e3-889a-5404a6144203',
      },
      {
        text: 'Business and secretarial schools',
        id: 'cd9bfb8c-f4c3-4791-ba01-362769ba7072',
        value: '9ed3a855-7d6f-11e3-8d29-5404a6144203',
      },
      {
        text: 'Child care services',
        id: 'a2d1a28e-4b5a-4448-8fe0-c16c63cd35e1',
        value: '9ed3a856-7d6f-11e3-962b-5404a6144203',
      },
      {
        text: 'Dance halls, studios, and school',
        id: '71cb03ef-faf8-41ec-adca-736182da61a1',
        value: '9ed3a858-7d6f-11e3-9d57-5404a6144203',
      },
      {
        text: 'Colleges and universities',
        id: 'cc68863f-de94-46f1-acc6-a0c94d9f0a93',
        value: '9ed3a857-7d6f-11e3-b6c2-5404a6144203',
      },
      {
        text: 'Elementary and secondary schools',
        id: '7edb6f27-a852-4642-9273-672046f7821b',
        value: '9ed3a859-7d6f-11e3-bcbb-5404a6144203',
      },
    ],
    '9ed3f677-7d6f-11e3-96a2-5404a6144203': [
      {
        text: 'Gift, card, novelty, and souvenir shops',
        id: 'be25219d-f32f-4adc-b194-ae4a77bf5b64',
        value: '9ed3f679-7d6f-11e3-a884-5404a6144203',
      },
      {
        text: 'Nursery plants and flowers',
        id: '507a6f11-300e-4d79-ad48-d7528d1a5c38',
        value: '9ed3f67b-7d6f-11e3-aead-5404a6144203',
      },
      {
        text: 'Party supplies',
        id: '17be9a8f-53f6-41d8-9b13-29c91aeffb05',
        value: '9ed3f67c-7d6f-11e3-b1fb-5404a6144203',
      },
      {
        text: 'Florist',
        id: '5843ca7b-01f6-4eeb-b3c0-90c8e3d812cb',
        value: '9ed3f678-7d6f-11e3-b609-5404a6144203',
      },
      {
        text: 'Gourmet foods',
        id: '438af94e-062f-47bf-952d-15755b3b42e1',
        value: '9ed3f67a-7d6f-11e3-bb5b-5404a6144203',
      },
    ],
    '9ed3f686-7d6f-11e3-af6e-5404a6144203': [
      {
        text: 'Dental care',
        id: 'a648dfef-f67d-4a19-b8cb-cdcb463c1e95',
        value: '9ed41d70-7d6f-11e3-851b-5404a6144203',
      },
      {
        text: 'Vitamins and supplements',
        id: '1ef81650-79fe-43cc-b860-606c61a9f551',
        value: '9ed41d74-7d6f-11e3-8b34-5404a6144203',
      },
      {
        text: 'Vision care',
        id: '16535726-01a3-41da-9a18-446543c9c65e',
        value: '9ed41d73-7d6f-11e3-963f-5404a6144203',
      },
      {
        text: 'Pharmacy and drugstore',
        id: '9b698ef0-ec8a-46cb-b811-fd9e6a5dc51e',
        value: '9ed3f687-7d6f-11e3-a1ac-5404a6144203',
      },
      {
        text: 'Medical care',
        id: '948de271-99e6-4938-9268-40c9e579ccfe',
        value: '9ed41d71-7d6f-11e3-abed-5404a6144203',
      },
      {
        text: 'Medical equipment and supplies',
        id: 'c9cf9640-acec-4e5d-8415-96517d855fad',
        value: '9ed41d72-7d6f-11e3-b770-5404a6144203',
      },
    ],
    '9ed35a32-7d6f-11e3-9830-5404a6144203': [
      {
        text: 'Rare and used books',
        id: '052d4051-10f5-4fbd-8323-8eb4be6dc639',
        value: '9ed35a3a-7d6f-11e3-9579-5404a6144203',
      },
      {
        text: 'Printing',
        id: '808127e5-d871-4a8a-8e49-906d09896eae',
        value: '9ed35a39-7d6f-11e3-96ad-5404a6144203',
      },
      {
        text: 'Magazines',
        id: 'd8eb5215-2a4a-4172-84ef-2061a3e386b7',
        value: '9ed35a37-7d6f-11e3-a05d-5404a6144203',
      },
      {
        text: 'Publishing',
        id: '5f2f920b-a670-44e2-8915-428691b08470',
        value: '9ed35a38-7d6f-11e3-a24f-5404a6144203',
      },
      {
        text: 'Book store',
        id: '156bdcec-7d4d-4172-8d97-b9f55a354337',
        value: '9ed35a35-7d6f-11e3-a469-5404a6144203',
      },
      {
        text: 'Audio books',
        id: '30542e5e-5946-4781-8ca9-108e4709e299',
        value: '9ed35a33-7d6f-11e3-ba85-5404a6144203',
      },
    ],
    '9ed4b9c6-7d6f-11e3-a156-5404a6144203': [
      {
        text: 'Audio and video',
        id: '76f38187-bec5-46e1-82bd-c3dfadeb0ee8',
        value: '9ed4b9c9-7d6f-11e3-8d35-5404a6144203',
      },
      {
        text: 'Towing service',
        id: '8788321d-c2ce-4335-aab2-1756a28cf627',
        value: '9ed4b9d2-7d6f-11e3-90bc-5404a6144203',
      },
      {
        text: 'Auto service',
        id: '1e6a8d05-5a26-4bc3-8a96-a54109d73956',
        value: '9ed4b9cc-7d6f-11e3-940b-5404a6144203',
      },
      {
        text: 'Boat rental and leases',
        id: 'dd89f74e-ab38-4b7d-80f2-5debfa180a63',
        value: '9ed4b9ce-7d6f-11e3-95d6-5404a6144203',
      },
      {
        text: 'Tools and equipment',
        id: '04b76b6d-d5ec-4aae-9167-2aa4e3405088',
        value: '9ed4b9d1-7d6f-11e3-9894-5404a6144203',
      },
      {
        text: 'Car wash',
        id: 'b297d13e-e939-4f4c-bd64-07a803e876a7',
        value: '9ed4b9cf-7d6f-11e3-a74e-5404a6144203',
      },
      {
        text: 'Motorhome and recreational vehicle rental',
        id: 'e1a84509-9873-4061-96eb-3fa69e36a8a9',
        value: '9ed4b9d0-7d6f-11e3-a967-5404a6144203',
      },
      {
        text: 'Truck and utility trailer rental',
        id: '9311f920-f177-40d5-a83c-10cfd44b9287',
        value: '9ed4b9d3-7d6f-11e3-ad63-5404a6144203',
      },
      {
        text: 'Parts, supplies, and accessories',
        id: '503260cf-c060-4aaf-8c60-bcd94fd967e4',
        value: '9ed4b9d4-7d6f-11e3-aeef-5404a6144203',
      },
      {
        text: 'Auto body repair and paint',
        id: '15cd13c8-37aa-4bec-ab74-44bbdf3b4419',
        value: '9ed4b9ca-7d6f-11e3-af09-5404a6144203',
      },
      {
        text: 'Automotive tire supply and service',
        id: 'f850179c-bacd-468e-884f-cc44b2ae2c68',
        value: '9ed4b9cd-7d6f-11e3-b533-5404a6144203',
      },
      {
        text: 'Auto rental',
        id: '642bcbf1-d558-4dae-9569-08e8fe72efcd',
        value: '9ed4b9cb-7d6f-11e3-b5bb-5404a6144203',
      },
    ],
    '9ed44486-7d6f-11e3-89f8-5404a6144203': [
      {
        text: 'Educational',
        id: '15d8cb26-5ecf-4070-ac3c-1f7b11c7c273',
        value: '9ed4448c-7d6f-11e3-8ce5-5404a6144203',
      },
      {
        text: 'Charity',
        id: '5c1503ca-38b0-407a-b7da-92914839998f',
        value: '9ed44487-7d6f-11e3-9419-5404a6144203',
      },
      {
        text: 'Political',
        id: '3ab9efb1-badf-4854-a223-f3187d0f5f8b',
        value: '9ed44488-7d6f-11e3-9872-5404a6144203',
      },
      {
        text: 'Religious',
        id: '4e746a65-0a5d-4f49-8356-966ed0d90bf5',
        value: '9ed44489-7d6f-11e3-a09e-5404a6144203',
      },
      {
        text: 'Other',
        id: '95a2988d-2d6f-48de-8dc5-dfda729c6907',
        value: '9ed4448a-7d6f-11e3-a496-5404a6144203',
      },
      {
        text: 'Personal',
        id: '986a6984-147d-4660-91de-0c505f5153c2',
        value: '9ed4448b-7d6f-11e3-be19-5404a6144203',
      },
    ],
    '9ed4448d-7d6f-11e3-aab2-5404a6144203': [
      {
        text: 'Pet shops, pet food, and supplies',
        id: '14dc913d-177c-4c27-8f8f-6fb69f03987b',
        value: '9ed4448f-7d6f-11e3-8817-5404a6144203',
      },
      {
        text: 'Veterinary services',
        id: '09d85e57-49b9-459e-a664-d6f846df6817',
        value: '9ed44491-7d6f-11e3-a152-5404a6144203',
      },
      {
        text: 'Medication and supplements',
        id: 'e45bd19c-87fb-4dc7-96d2-a6f472df00fb',
        value: '9ed4448e-7d6f-11e3-b343-5404a6144203',
      },
      {
        text: 'Specialty and rare pets',
        id: '23cf0bdc-24e4-4ec7-9602-0eebd6e45151',
        value: '9ed44490-7d6f-11e3-bfda-5404a6144203',
      },
    ],
    '9ed44492-7d6f-11e3-98d1-5404a6144203': [
      {
        text: 'Membership services',
        id: '377b9017-fd7d-407b-b57f-65bf23628626',
        value: '9ed44493-7d6f-11e3-9b4f-5404a6144203',
      },
      {
        text: 'Services not elsewhere classified',
        id: 'dbc35802-03dc-4711-a54f-239db21fb90b',
        value: '9ed44495-7d6f-11e3-b2f6-5404a6144203',
      },
    ],
    '9ed35a3b-7d6f-11e3-83c8-5404a6144203': [
      {
        text: 'Agriculture - animal production and aquaculture',
        id: '0fe32bee-96da-43ed-a074-3c9224d0d566',
        value: '9ed38132-7d6f-11e3-815d-5404a6144203',
      },
      {
        text: 'Agriculture - fruit, vegetable, and crop production',
        id: '0d94c8d7-5e7f-4413-8798-3d49a8b63c8e',
        value: '9ed38131-7d6f-11e3-828e-5404a6144203',
      },
      {
        text: 'Consulting services',
        id: '344905b3-a63a-4758-adec-91ce2df314a9',
        value: '9ed3813c-7d6f-11e3-82cc-5404a6144203',
      },
      {
        text: 'Agriculture - fishing, hunting, and trapping',
        id: '935181b6-95fa-4678-997a-705935feb959',
        value: '9ed38134-7d6f-11e3-8529-5404a6144203',
      },
      {
        text: 'Construction - residential building',
        id: '7c977d8c-e1b0-42e4-9e3d-0e4382d954ca',
        value: '9ed38138-7d6f-11e3-862a-5404a6144203',
      },
      {
        text: 'Consumer goods rental',
        id: '5d16b103-e2c3-49e1-b9d4-28cb572d8cbb',
        value: '9ed3813e-7d6f-11e3-86eb-5404a6144203',
      },
      {
        text: 'Commercial and industrial goods rental',
        id: 'cad620c3-5dd1-4d77-bf0d-a0269b0f96bf',
        value: '9ed3813f-7d6f-11e3-8840-5404a6144203',
      },
      {
        text: 'Marketing',
        id: '10130f50-cbd7-4764-9983-2110e155935b',
        value: '9ed38146-7d6f-11e3-89d6-5404a6144203',
      },
      {
        text: 'Publishing',
        id: '4e9737fb-a08f-4369-abce-1f5ccf196939',
        value: '9ed3814a-7d6f-11e3-8c10-5404a6144203',
      },
      {
        text: 'Shipping and packing',
        id: 'fffa013f-3307-43eb-8518-a289a30d27c9',
        value: '9ed3814d-7d6f-11e3-8eb5-5404a6144203',
      },
      {
        text: 'Stenographic and secretarial support services',
        id: '1e67a74a-69b8-41a2-a500-80a7a5a7a81a',
        value: '9ed3814e-7d6f-11e3-9087-5404a6144203',
      },
      {
        text: 'Multi-level marketing',
        id: 'ce04f86c-72b1-4b6f-bff5-ce74674dd090',
        value: '9ed38147-7d6f-11e3-94f8-5404a6144203',
      },
      {
        text: 'Office and commercial furniture',
        id: '44d97617-1c45-429f-a726-393975c94e33',
        value: '9ed38148-7d6f-11e3-9820-5404a6144203',
      },
      {
        text: 'Agriculture - forestry and logging',
        id: '06d13b31-bb09-43cb-914a-56899a38e8b0',
        value: '9ed38133-7d6f-11e3-9892-5404a6144203',
      },
      {
        text: 'Printing',
        id: '5f7a916f-2458-4d94-a9d8-47e19fbc52a7',
        value: '9ed3814b-7d6f-11e3-9ed9-5404a6144203',
      },
      {
        text: 'Mailing lists',
        id: '24362f6c-a1ba-406d-b145-17259cd46672',
        value: '9ed38145-7d6f-11e3-a442-5404a6144203',
      },
      {
        text: 'Industrial and manufacturing supplies',
        id: '23be0ff4-5dbc-42ab-9d43-29bd16e89ce7',
        value: '9ed38144-7d6f-11e3-a4f3-5404a6144203',
      },
      {
        text: 'Commercial and industrial goods repair and maintenance',
        id: 'bbcc3dd6-584f-43d3-ae4e-af6ab73c3e8a',
        value: '9ed38141-7d6f-11e3-a5d2-5404a6144203',
      },
      {
        text: 'Quick copy and reproduction services',
        id: 'f65580f5-c728-4051-9935-3ff93bda236d',
        value: '9ed3814c-7d6f-11e3-a77b-5404a6144203',
      },
      {
        text: 'Commercial photography, art, and graphics',
        id: 'a08cf5e6-bada-4f22-9a34-3ffb77a8e43b',
        value: '9ed38137-7d6f-11e3-a92b-5404a6144203',
      },
      {
        text: 'Architectural, engineering, and surveying services',
        id: 'bdfb680e-893a-4ab5-adbc-8e1e67150441',
        value: '9ed38135-7d6f-11e3-a9f8-5404a6144203',
      },
      {
        text: 'Wholesale - durable goods',
        id: '6b42d1c3-ee59-4a95-8b6a-831205287496',
        value: '9ed3814f-7d6f-11e3-aaf6-5404a6144203',
      },
      {
        text: 'Consumer goods repair and maintenance',
        id: '34aa9245-6ace-43d5-ba28-fd8cb636d5e9',
        value: '9ed38142-7d6f-11e3-ab4a-5404a6144203',
      },
      {
        text: 'Wholesale - nondurable goods',
        id: '60cf7ef0-5803-4d2b-a43b-3128a9dff073',
        value: '9ed38150-7d6f-11e3-adfb-5404a6144203',
      },
      {
        text: 'Advertising and public relations',
        id: '5fa501eb-f8b3-4b27-855a-f4f83d949038',
        value: '9ed38130-7d6f-11e3-afd9-5404a6144203',
      },
      {
        text: 'Construction - specialty trade contractors',
        id: '8933eedb-97d1-4fc8-9b54-49470790420c',
        value: '9ed3813b-7d6f-11e3-b28d-5404a6144203',
      },
      {
        text: 'Office supplies and equipment',
        id: 'ea2b4460-5154-4837-b1da-63ce51b5723a',
        value: '9ed38149-7d6f-11e3-b520-5404a6144203',
      },
      {
        text: 'Career services',
        id: '629d3b0e-9248-4f40-82d6-a3d92af86cf4',
        value: '9ed38143-7d6f-11e3-bc00-5404a6144203',
      },
      {
        text: 'Electronic repair and maintenance',
        id: '0c6876ce-f71b-42bd-8c21-1541dd4ec60f',
        value: '9ed38140-7d6f-11e3-bc03-5404a6144203',
      },
      {
        text: 'Wholesale - electronic markets and agents and brokers',
        id: 'ddee68be-3b6b-4d5b-a1ca-0ee48e3b4aa8',
        value: '9ed38151-7d6f-11e3-bc8f-5404a6144203',
      },
      {
        text: 'Construction - heavy and civil engineering',
        id: 'fd1e0cc9-5f01-4e54-957a-4d574aa05f4c',
        value: '9ed3813a-7d6f-11e3-bc90-5404a6144203',
      },
      {
        text: 'Educational services',
        id: '7467d21f-2db0-40a2-81b4-4b00d9938546',
        value: '9ed3813d-7d6f-11e3-bd65-5404a6144203',
      },
      {
        text: 'Chemicals and allied products',
        id: '33904a79-09df-4bcb-927a-22dee1b5eccb',
        value: '9ed38136-7d6f-11e3-bd75-5404a6144203',
      },
      {
        text: 'Accounting',
        id: 'b2a5c901-f16a-4a04-8797-d02f0e7469e4',
        value: '9ed35a3c-7d6f-11e3-be28-5404a6144203',
      },
      {
        text: 'Construction - nonresidential building',
        id: 'a82d5cf5-3f77-48db-b60a-c653be4b4b4c',
        value: '9ed38139-7d6f-11e3-bf69-5404a6144203',
      },
    ],
    '9ed44496-7d6f-11e3-865d-5404a6144203': [
      {
        text: 'Department store',
        id: '9540329c-8219-4cd3-a578-3616d9f4469a',
        value: '9ed44498-7d6f-11e3-87b4-5404a6144203',
      },
      {
        text: 'Variety store',
        id: '8003906a-dd79-4970-b1b7-60af49130733',
        value: '9ed4449d-7d6f-11e3-9238-5404a6144203',
      },
      {
        text: 'Used and secondhand store',
        id: '39a3f2ed-44cc-465e-91d1-808adce28cbb',
        value: '9ed4449c-7d6f-11e3-9843-5404a6144203',
      },
      {
        text: 'Miscellaneous store retailer',
        id: '728b886c-d2ef-4da7-8bfd-cd687bbfef02',
        value: '9ed4449a-7d6f-11e3-a380-5404a6144203',
      },
      {
        text: 'Discount store',
        id: 'da8e028e-1b2e-4e56-bcf6-11b5d27f35b4',
        value: '9ed44499-7d6f-11e3-aa99-5404a6144203',
      },
    ],
    '9ed41d89-7d6f-11e3-beff-5404a6144203': [
      {
        text: 'Nonmetallic mineral product manufacturing',
        id: '98d634d3-6890-40a0-be42-6ffcc88b342c',
        value: '9ed41d96-7d6f-11e3-804a-5404a6144203',
      },
      {
        text: 'Furniture and related product manufacturing',
        id: '0883a273-ade3-4292-87e6-636a790215d3',
        value: '9ed44484-7d6f-11e3-8b25-5404a6144203',
      },
      {
        text: 'Plastics and rubber products manufacturing',
        id: '33c79d56-562d-41b4-b7bb-a24cb21a442b',
        value: '9ed41d95-7d6f-11e3-8b36-5404a6144203',
      },
      {
        text: 'Chemical manufacturing',
        id: '37e005ba-2685-496e-950a-f003e9b7ae39',
        value: '9ed41d94-7d6f-11e3-8dc3-5404a6144203',
      },
      {
        text: 'Primary metal manufacturing',
        id: '5c95da9a-d5b3-42dd-adfa-004d14b7d14d',
        value: '9ed41d97-7d6f-11e3-92aa-5404a6144203',
      },
      {
        text: 'Transportation equipment manufacturing',
        id: '9a44577d-5b56-40d9-8e03-40d3fde123e1',
        value: '9ed44483-7d6f-11e3-95ce-5404a6144203',
      },
      {
        text: 'Leather and allied product manufacturing',
        id: '03c24cb3-c15a-430e-984f-d24305ca5521',
        value: '9ed41d8f-7d6f-11e3-9762-5404a6144203',
      },
      {
        text: 'Textile product mills',
        id: '52b2357d-fa39-40d7-9f4f-38f68cbd64fe',
        value: '9ed41d8d-7d6f-11e3-9a24-5404a6144203',
      },
      {
        text: 'Printing and related support activities',
        id: '9632cdbe-6ed3-48b2-8208-ea02764ee690',
        value: '9ed41d92-7d6f-11e3-a0ad-5404a6144203',
      },
      {
        text: 'Miscellaneous manufacturing',
        id: '5cd03c43-c026-4a28-a5b0-cfdc2f704112',
        value: '9ed44485-7d6f-11e3-a2bc-5404a6144203',
      },
      {
        text: 'Food manufacturing',
        id: '679f45f1-fc7b-4a0d-b853-38336255fd03',
        value: '9ed41d8a-7d6f-11e3-a5dc-5404a6144203',
      },
      {
        text: 'Fabricated metal product manufacturing',
        id: 'd70eefc7-d3d1-4c70-9542-d983f1dabfb6',
        value: '9ed41d98-7d6f-11e3-a88a-5404a6144203',
      },
      {
        text: 'Wood product manufacturing',
        id: '867f32b3-1a02-4b31-af19-ebd40326912a',
        value: '9ed41d90-7d6f-11e3-a8f0-5404a6144203',
      },
      {
        text: 'Computer and electronic product manufacturing',
        id: 'b867531d-0ae8-4e9e-b508-2ca70e373538',
        value: '9ed44481-7d6f-11e3-aa45-5404a6144203',
      },
      {
        text: 'Paper manufacturing',
        id: '9520a449-42a0-4de1-8be8-61ac97095da1',
        value: '9ed41d91-7d6f-11e3-b262-5404a6144203',
      },
      {
        text: 'Machinery manufacturing',
        id: '99258ee5-8290-4e65-ba75-81048fa1c188',
        value: '9ed44480-7d6f-11e3-b289-5404a6144203',
      },
      {
        text: 'Apparel manufacturing',
        id: 'cdbb736a-322d-4b8e-ab53-64384c09fc11',
        value: '9ed41d8e-7d6f-11e3-b45c-5404a6144203',
      },
      {
        text: 'Petroleum and coal products manufacturing',
        id: '1ef28155-2b8a-442a-a646-b6d4a0e032d9',
        value: '9ed41d93-7d6f-11e3-b677-5404a6144203',
      },
      {
        text: 'Beverage and tobacco product manufacturing',
        id: 'a113e5ca-f718-4fef-925d-c4f51bc5d548',
        value: '9ed41d8b-7d6f-11e3-b721-5404a6144203',
      },
      {
        text: 'Electrical equipment, appliance, and component manufacturing',
        id: 'a5d546b9-bc95-4754-bb56-cae507c88294',
        value: '9ed44482-7d6f-11e3-babe-5404a6144203',
      },
      {
        text: 'Textile mills',
        id: '1a48c478-cb0e-4d46-b7c5-d1a0c47d3be9',
        value: '9ed41d8c-7d6f-11e3-bce3-5404a6144203',
      },
    ],
    '9ed3cf5f-7d6f-11e3-8af8-5404a6144203': [
      {
        text: 'Prepaid and stored value cards',
        id: '89add98b-7bd0-48b1-a5cd-e85e6a6f6470',
        value: '9ed3f663-7d6f-11e3-8a12-5404a6144203',
      },
      {
        text: 'Credit union',
        id: '388cf89c-5c9f-497e-adc8-3f0fdf034b92',
        value: '9ed3cf68-7d6f-11e3-8abb-5404a6144203',
      },
      {
        text: 'Security brokers and dealers',
        id: '5a5cad53-b602-4c89-b0f9-498e701afba6',
        value: '9ed3f667-7d6f-11e3-996b-5404a6144203',
      },
      {
        text: 'Remittance',
        id: '696a2534-bf43-4f7b-805c-38f477adf7f9',
        value: '9ed3f665-7d6f-11e3-99f4-5404a6144203',
      },
      {
        text: 'Rental property management',
        id: '9dd6ba0a-73c2-4b97-9291-81815638c515',
        value: '9ed3f666-7d6f-11e3-9a8d-5404a6144203',
      },
      {
        text: 'Investment banking and securities dealing',
        id: 'eed5dc7e-f162-434e-9781-0cfc3f5b5459',
        value: '9ed3cf69-7d6f-11e3-9dfb-5404a6144203',
      },
      {
        text: 'Real estate agent',
        id: '297f0471-b290-4f7d-8099-9bcdd3bf2d02',
        value: '9ed3f664-7d6f-11e3-9eaa-5404a6144203',
      },
      {
        text: 'Sales financing',
        id: '8de850d5-92d9-4ff5-834e-b4c007b06d74',
        value: '9ed3cf6b-7d6f-11e3-a38f-5404a6144203',
      },
      {
        text: 'Debt counseling service',
        id: '124a4267-887b-44cc-83a5-1b3593c40607',
        value: '9ed3cf67-7d6f-11e3-a43e-5404a6144203',
      },
      {
        text: 'Collection agency',
        id: '1710dcb4-b087-4b82-a4a1-585ccd7211ca',
        value: '9ed3cf61-7d6f-11e3-a622-5404a6144203',
      },
      {
        text: 'Securities and commodity exchanges',
        id: '0dcbf7f5-3e71-41ed-8754-deef367bbf6f',
        value: '9ed3cf65-7d6f-11e3-a76a-5404a6144203',
      },
      {
        text: 'Financial and investment advice',
        id: 'a462b451-3b17-4f0b-a4a9-af9b1b0255e4',
        value: '9ed3cf6e-7d6f-11e3-a77e-5404a6144203',
      },
      {
        text: 'Accounting',
        id: '7f3dbd9a-d152-4d92-be7d-02d6c64e13eb',
        value: '9ed3cf60-7d6f-11e3-a80d-5404a6144203',
      },
      {
        text: 'Escrow',
        id: 'c94b9752-1bde-4083-b76f-bd741a9a8c42',
        value: '9ed3cf6a-7d6f-11e3-a898-5404a6144203',
      },
      {
        text: 'Mortgage brokers and dealers',
        id: '157b86ef-c786-44f3-bf2d-2bb72daf52de',
        value: '9ed3cf75-7d6f-11e3-aa5a-5404a6144203',
      },
      {
        text: 'Financial transactions processing, reserve, and clearinghouse activities',
        id: '026fde02-5544-4837-b3ad-1d614a6a50c4',
        value: '9ed3cf74-7d6f-11e3-ac83-5404a6144203',
      },
      {
        text: 'Paycheck lender and cash advance',
        id: 'a87202ab-4533-408a-b2c8-16abd1941142',
        value: '9ed3f662-7d6f-11e3-ad1d-5404a6144203',
      },
      {
        text: 'Insurance - life and annuity',
        id: '138e2e95-2a2f-4eed-b679-146aa2f84935',
        value: '9ed3cf70-7d6f-11e3-afb0-5404a6144203',
      },
      {
        text: 'Other activities related to credit intermediation',
        id: 'd6c369ec-c493-4f19-9a7d-c1d3fd872ed9',
        value: '9ed3cf73-7d6f-11e3-b045-5404a6144203',
      },
      {
        text: 'Online gaming currency',
        id: 'e09109bd-6525-471d-85b6-8bfee303527e',
        value: '9ed3f661-7d6f-11e3-b15e-5404a6144203',
      },
      {
        text: 'Commodity contracts dealing',
        id: '70ccfa06-3b7e-4821-82d7-ae4a15b448ce',
        value: '9ed3cf62-7d6f-11e3-b2cd-5404a6144203',
      },
      {
        text: 'Commodity contracts brokerage',
        id: 'f0764831-0cc6-4b3e-852d-9d6667ef304c',
        value: '9ed3cf64-7d6f-11e3-b326-5404a6144203',
      },
      {
        text: 'Other investment pools and funds',
        id: 'e744e49d-c670-429b-84dc-ca33af90617c',
        value: '9ed3cf72-7d6f-11e3-b387-5404a6144203',
      },
      {
        text: 'Miscellaneous financial investment activities',
        id: 'c40348d6-2fec-46c9-adf1-81be02bf4fd7',
        value: '9ed3cf63-7d6f-11e3-b50c-5404a6144203',
      },
      {
        text: 'Wire transfer and money order',
        id: '83bd7e96-013a-4b5f-9607-2c68b3c4dcbb',
        value: '9ed3f668-7d6f-11e3-b821-5404a6144203',
      },
      {
        text: 'Consumer lending',
        id: '918e6fd2-98b5-40b2-b921-e0068620993a',
        value: '9ed3cf6c-7d6f-11e3-ba14-5404a6144203',
      },
      {
        text: 'Credit bureaus',
        id: '63684866-b883-455a-a042-c2eeed83638f',
        value: '9ed3cf66-7d6f-11e3-bc6c-5404a6144203',
      },
      {
        text: 'Insurance - auto and home',
        id: '23067199-b63b-4eea-a240-2bc33655b428',
        value: '9ed3cf6f-7d6f-11e3-bc97-5404a6144203',
      },
      {
        text: 'Offices of other holding companies',
        id: '2f6514cd-a045-4c6b-aaee-fe7e6dd96b9d',
        value: '9ed3cf6d-7d6f-11e3-bd75-5404a6144203',
      },
      {
        text: 'Other financial investment activities',
        id: '81990900-7939-45f7-8ea0-235f743f7b86',
        value: '9ed3cf71-7d6f-11e3-bf7b-5404a6144203',
      },
    ],
    '9ed4449e-7d6f-11e3-a32d-5404a6144203': [
      {
        text: 'Online dating',
        id: 'ab728700-d482-4090-b166-c8230d4bd235',
        value: '9ed46baf-7d6f-11e3-816d-5404a6144203',
      },
      {
        text: 'Vision care',
        id: '02db0983-9a17-4f0e-9c48-7769bb37aedb',
        value: '9ed492a7-7d6f-11e3-8278-5404a6144203',
      },
      {
        text: 'Consumer goods rental',
        id: 'dfbb353e-cbbe-4a56-88f3-c0c045616eae',
        value: '9ed46b98-7d6f-11e3-8308-5404a6144203',
      },
      {
        text: 'Printing',
        id: 'cb66962a-8941-4e59-ab1a-db8b1e0ae46b',
        value: '9ed46bac-7d6f-11e3-83b7-5404a6144203',
      },
      {
        text: 'All other personal services',
        id: '823c864f-7aad-45b8-901c-d91ed6781104',
        value: '9ed444a1-7d6f-11e3-858e-5404a6144203',
      },
      {
        text: 'Cleaning and maintenance',
        id: '5fcca683-be27-4135-a084-49415f0f7a20',
        value: '9ed444a5-7d6f-11e3-85d6-5404a6144203',
      },
      {
        text: 'Investigation and security services',
        id: '34e05052-c388-4793-8bd3-ff6b84c01757',
        value: '9ed46bb2-7d6f-11e3-861c-5404a6144203',
      },
      {
        text: 'Computer network services',
        id: 'c2d84343-68ce-41fb-bc2e-921beac1a34f',
        value: '9ed444a8-7d6f-11e3-87fd-5404a6144203',
      },
      {
        text: 'Telecommunication services',
        id: 'ffb74ec6-aac0-41dd-9f1e-4cb516f90cfd',
        value: '9ed492a5-7d6f-11e3-8d8f-5404a6144203',
      },
      {
        text: 'Entertainment',
        id: '9567a89f-4fcd-48bc-8a2b-5724cb75a56a',
        value: '9ed46b97-7d6f-11e3-8dd7-5404a6144203',
      },
      {
        text: 'Swimming pool services',
        id: 'c7b84ed8-79f2-458b-8c43-8944a695ea00',
        value: '9ed492a3-7d6f-11e3-8f04-5404a6144203',
      },
      {
        text: 'Warehouse clubs and supercenters',
        id: '18760514-39d1-4c69-9b4f-15efce0c7b24',
        value: '9ed444a0-7d6f-11e3-8fb1-5404a6144203',
      },
      {
        text: 'Real estate agent',
        id: '1f1ea6db-8662-4c74-bca2-08d6ce9ed3e5',
        value: '9ed46bb5-7d6f-11e3-9631-5404a6144203',
      },
      {
        text: 'Health and beauty spas',
        id: 'd065e0ed-6017-4d33-bfeb-58e21a4721bb',
        value: '9ed46b9f-7d6f-11e3-96b0-5404a6144203',
      },
      {
        text: 'Consulting services',
        id: '98be3b4c-9479-4f6d-94cb-8ac95f6a443a',
        value: '9ed46b91-7d6f-11e3-9704-5404a6144203',
      },
      {
        text: 'eCommerce services',
        id: 'eff79baf-c326-4844-ac86-4010e1140d83',
        value: '9ed46b95-7d6f-11e3-970b-5404a6144203',
      },
      {
        text: 'Watch, clock, and jewelry repair',
        id: 'ca30645c-aaf2-45a0-bb25-3339bb2d8448',
        value: '9ed492a8-7d6f-11e3-9894-5404a6144203',
      },
      {
        text: 'Real estate - other',
        id: '5c8727d9-92e9-4bf2-857b-889cdb83ba73',
        value: '9ed492ab-7d6f-11e3-9907-5404a6144203',
      },
      {
        text: 'Storage',
        id: '9b4cccf8-2ac6-4c18-82b1-03bd8e273aad',
        value: '9ed46bae-7d6f-11e3-9966-5404a6144203',
      },
      {
        text: 'Medical care',
        id: '7a7ec86e-9c08-4750-a16c-806a64af6e4d',
        value: '9ed46ba9-7d6f-11e3-9b47-5404a6144203',
      },
      {
        text: 'Services not elsewhere classified',
        id: 'c3906a11-1978-4415-8180-874f41dbf754',
        value: '9ed492a1-7d6f-11e3-9ccf-5404a6144203',
      },
      {
        text: 'Importing and exporting',
        id: '743a1848-21fd-4a00-858e-5bf2fa302aa5',
        value: '9ed46ba1-7d6f-11e3-9d1b-5404a6144203',
      },
      {
        text: 'Child care services',
        id: '6e34d734-2e62-440c-b64b-342452b3cb66',
        value: '9ed444a4-7d6f-11e3-9dc4-5404a6144203',
      },
      {
        text: 'Insurance - auto and home',
        id: '4cd1be9f-84b8-429c-a90f-66c0c98cb880',
        value: '9ed46ba3-7d6f-11e3-9efe-5404a6144203',
      },
      {
        text: 'Counseling services',
        id: '6c482613-af36-4526-96a3-ea19495d6b1f',
        value: '9ed46b92-7d6f-11e3-9f08-5404a6144203',
      },
      {
        text: 'Graphic and commercial design',
        id: 'a4eff336-dc2f-4b96-9ae7-153cff5d208f',
        value: '9ed46b9e-7d6f-11e3-9fec-5404a6144203',
      },
      {
        text: 'Moving',
        id: 'f8938689-da10-4045-b633-c78fa67119a0',
        value: '9ed46bad-7d6f-11e3-a006-5404a6144203',
      },
      {
        text: 'Event and wedding planning',
        id: '08659502-9a3a-479c-be43-3a94e46e1e34',
        value: '9ed46b9a-7d6f-11e3-a11b-5404a6144203',
      },
      {
        text: 'Electronic repair and maintenance',
        id: '4d878cf0-5ee6-40f1-84d4-2ebd38466b05',
        value: '9ed46b96-7d6f-11e3-a2ee-5404a6144203',
      },
      {
        text: 'Quick copy and reproduction services',
        id: '060fc9a4-1890-42b1-a4c2-48af46f257a7',
        value: '9ed46bb3-7d6f-11e3-a347-5404a6144203',
      },
      {
        text: 'Photofinishing',
        id: 'b2b7b79b-56d9-4ce2-bc23-aaef3a6ab102',
        value: '9ed46bb0-7d6f-11e3-a35d-5404a6144203',
      },
      {
        text: 'Radio, television, and stereo repair',
        id: 'd316b2bb-3ed3-4eab-90f9-ff0ac9cc6945',
        value: '9ed46bb4-7d6f-11e3-a3c3-5404a6144203',
      },
      {
        text: 'Publishing',
        id: '85134151-8021-4873-bdf8-cdb0c2d4a26d',
        value: '9ed46bab-7d6f-11e3-a401-5404a6144203',
      },
      {
        text: 'Lottery and contests',
        id: 'b1817691-ae33-40d4-b9c1-c9b51540b814',
        value: '9ed46ba8-7d6f-11e3-a726-5404a6144203',
      },
      {
        text: 'Research and development',
        id: '2283163e-e1ac-44c1-9ddd-4c85757d231d',
        value: '9ed492aa-7d6f-11e3-aca1-5404a6144203',
      },
      {
        text: 'Photographic studios - portraits',
        id: 'b10b5971-0d30-457c-8140-094fabbc560e',
        value: '9ed46bb1-7d6f-11e3-acac-5404a6144203',
      },
      {
        text: 'Commercial photography, art, and graphics',
        id: 'a715e454-91f3-4949-a2a6-f640f2c89505',
        value: '9ed444a6-7d6f-11e3-ad1c-5404a6144203',
      },
      {
        text: 'Construction - residential building',
        id: '7339d015-9eaa-4d94-8ad3-9363be8d72c5',
        value: '9ed46b9c-7d6f-11e3-ad21-5404a6144203',
      },
      {
        text: 'Membership clubs and organizations',
        id: 'aecf3c59-bc00-4d88-a8d9-006d92b17bfe',
        value: '9ed46baa-7d6f-11e3-ad74-5404a6144203',
      },
      {
        text: 'Dental care',
        id: 'ddb7ccee-7782-4eda-9325-fec5d5f10c2a',
        value: '9ed46b94-7d6f-11e3-ad88-5404a6144203',
      },
      {
        text: 'Legal services and attorneys',
        id: '921ae210-1f21-4b0a-80dc-d10fe531e0ad',
        value: '9ed46ba6-7d6f-11e3-ae95-5404a6144203',
      },
      {
        text: 'Mining',
        id: '09bdfec0-1f48-4f3c-b587-5bf60bc9e731',
        value: '9ed492a9-7d6f-11e3-af4b-5404a6144203',
      },
      {
        text: 'Landscaping and horticultural',
        id: '33c1383c-ec9b-4535-b053-a3e379318c28',
        value: '9ed46ba5-7d6f-11e3-b01c-5404a6144203',
      },
      {
        text: 'Information retrieval services',
        id: '0b57f9c9-f4a1-4d5e-a48e-b13625f9f0eb',
        value: '9ed46ba2-7d6f-11e3-b242-5404a6144203',
      },
      {
        text: 'Insurance - life and annuity',
        id: '840f062a-4042-4fc4-8a38-289e2183ccf0',
        value: '9ed46ba4-7d6f-11e3-b31e-5404a6144203',
      },
      {
        text: 'Gambling',
        id: '831f23b7-a2fa-457f-a39e-d0bd793fd499',
        value: '9ed46b9b-7d6f-11e3-b391-5404a6144203',
      },
      {
        text: 'Courier services',
        id: 'cb0c21c3-c45d-49bd-9ef1-256af433036a',
        value: '9ed46b93-7d6f-11e3-b3b9-5404a6144203',
      },
      {
        text: 'Tailors and alterations',
        id: 'a5178411-c33b-46ca-bc66-e3ab55f0f318',
        value: '9ed492a4-7d6f-11e3-b3dc-5404a6144203',
      },
      {
        text: 'Computer and data processing services',
        id: '684b0b60-3d10-4e6d-b702-f84caebeeded',
        value: '9ed444a7-7d6f-11e3-b42d-5404a6144203',
      },
      {
        text: 'Construction - nonresidential building',
        id: 'd4e9f979-9eea-4b72-a764-9b5460e4343e',
        value: '9ed46b9d-7d6f-11e3-b5da-5404a6144203',
      },
      {
        text: 'IDs, licenses, and passports',
        id: 'd5407be5-cd84-4689-ae8d-d607e7ce1448',
        value: '9ed46ba0-7d6f-11e3-b775-5404a6144203',
      },
      {
        text: 'Carpentry',
        id: 'af11dad0-9617-4ff2-b7b4-2a7f8e10e51e',
        value: '9ed444a3-7d6f-11e3-b785-5404a6144203',
      },
      {
        text: 'Local delivery service',
        id: '4f995f1c-527f-484e-b74b-64cb58146234',
        value: '9ed46ba7-7d6f-11e3-b85c-5404a6144203',
      },
      {
        text: 'Advertising and public relations',
        id: '3c47bfe2-e5a3-4537-b11f-c6b79010165c',
        value: '9ed4449f-7d6f-11e3-b867-5404a6144203',
      },
      {
        text: 'Reupholstery and furniture repair',
        id: '98c30d24-c6ea-453b-994c-19abbcfee436',
        value: '9ed46bb7-7d6f-11e3-b950-5404a6144203',
      },
      {
        text: 'Rental property management',
        id: 'e97ddbad-7782-4481-90a7-8d4b79dd6795',
        value: '9ed46bb6-7d6f-11e3-b9b2-5404a6144203',
      },
      {
        text: 'Career services',
        id: 'a537f155-57da-4097-b035-476387f4cb3c',
        value: '9ed444a2-7d6f-11e3-ba23-5404a6144203',
      },
      {
        text: 'Utilities',
        id: '0f8e845a-02a3-47a5-89b2-1dfdf2a89c39',
        value: '9ed492a6-7d6f-11e3-bbd2-5404a6144203',
      },
      {
        text: 'Commercial and industrial goods rental',
        id: '82e3a530-1f6c-4844-8545-2fbc2b92a41d',
        value: '9ed46b99-7d6f-11e3-be4c-5404a6144203',
      },
      {
        text: 'Shipping and packing',
        id: 'b01b190d-898e-49ce-9d27-b6be7ee871ff',
        value: '9ed492a2-7d6f-11e3-bfb3-5404a6144203',
      },
    ],
    '9ed492ac-7d6f-11e3-a2d2-5404a6144203': [
      {
        text: 'Dance halls, studios, and schools',
        id: '8d0467ec-810d-4349-8e7f-5838ca4e6d9d',
        value: '9ed492b1-7d6f-11e3-a276-5404a6144203',
      },
      {
        text: 'Other sporting goods',
        id: 'e8c763fe-d7ec-4da1-9a31-83186f15483e',
        value: '9ed492b9-7d6f-11e3-a5ed-5404a6144203',
      },
      {
        text: 'Firearms, knives, and martial arts weapons',
        id: '8ac319ff-a03f-465a-b743-b9568613c6f7',
        value: '9ed492ba-7d6f-11e3-ab20-5404a6144203',
      },
      {
        text: 'Swimming pools and spas',
        id: '3357185b-d6f6-4cf3-a1a3-402d6e4e0dfd',
        value: '9ed492bb-7d6f-11e3-b634-5404a6144203',
      },
      {
        text: 'Fan gear and memorabilia',
        id: '80729b96-0564-4ed5-bc6e-f36abf7e7bcb',
        value: '9ed492b3-7d6f-11e3-bbca-5404a6144203',
      },
    ],
  };

  return industryClassifications[bizCategoryId] || [];
};

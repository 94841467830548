import PropTypes from 'prop-types';
import React from 'react';

import { getItemDateDue } from 'helpers/items';

import ExternalReceiptRow from 'modules/external/externalReceipt/presenters/ExternalReceiptRow';

const DueDate = ({ item }) => {
  if (!item.dateDue) {
    return null;
  }

  return <ExternalReceiptRow description="Due date" text={getItemDateDue(item)} />;
};

DueDate.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default DueDate;

/**
 * Class names used in the DisclosureList subcomponents.
 * If using a custom header or body component, these classNames
 * can be used there to get the default animations.
 * @type {{ BODY: string, HEADER: string, LIST: string, CONTROL: string }}
 */
export const DisclosureListClassNames = {
  BODY: 'disclosure-body',
  BODY_TRANSITIONS: 'disclosure-body-transitions',
  CONTROL: 'disclosure-control',
  HEADER: 'disclosure-header',
  LIST: 'disclosure-list',
  LIST_TRANSITIONS: 'disclosure-list-transitions',
};

export const DisclosureListHeadingStyle = {
  DEFAULT: 'default',
  THIN: 'thin',
};

import PropTypes from 'prop-types';
import React from 'react';

import { PrivacyPolicyLink, TermsOfServiceLink } from 'components/link';

import { CONTACT_SUPPORT_LABEL } from 'constants/labels';
import { HELP_DOCS_URL, PLATFORM_DISPLAY_SHORT_NAME, PLATFORM_EMAILS } from 'constants/platform';

import './BrandFooter.scss';

class BrandFooter extends React.Component {
  renderLogo = () => {
    const { showSecure } = this.props;

    if (showSecure) {
      return null;
    }

    return (
      <React.Fragment>
        <span className="icon-ic-logo-letter font-color--dark-jordan" />
        <span className="font-color--dark-jordan margin-left--sm">{PLATFORM_DISPLAY_SHORT_NAME}</span>
      </React.Fragment>
    );
  };

  renderSecure = () => {
    const { showSecure } = this.props;

    if (!showSecure) {
      return null;
    }

    return (
      <React.Fragment>
        <span className="icon-ic-shield font-color--dark-jordan font-m" />
        <span className="font-color--main-jordan margin-left--sm">Safe & secure</span>
      </React.Fragment>
    );
  };

  renderSupport = () => {
    const { hideSupport } = this.props;

    if (hideSupport) {
      return null;
    }

    return (
      <li>
        <a className="font-color--dark-jordan" href={`mailto:${PLATFORM_EMAILS.SUPPORT}`}>
          {CONTACT_SUPPORT_LABEL}
        </a>
      </li>
    );
  };

  render() {
    return (
      <div className="brand--footer">
        <div className="footer--content">
          <div className="footer--content-left">
            {this.renderSecure()}
            {this.renderLogo()}
          </div>

          <div className="footer--content-right">
            <ul className="footer--links">
              {this.renderSupport()}
              <li>
                <a className="font-color--dark-jordan" href={HELP_DOCS_URL} rel="noopener noreferrer" target="_blank">
                  Help docs
                </a>
              </li>
              <li>
                <PrivacyPolicyLink className="font-color--dark-jordan" />
              </li>
              <li>
                <TermsOfServiceLink className="font-color--dark-jordan" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

BrandFooter.propTypes = {
  hideSupport: PropTypes.bool,
  showSecure: PropTypes.bool,
};

BrandFooter.defaultProps = {
  hideSupport: undefined,
  showSecure: undefined,
};

export default BrandFooter;

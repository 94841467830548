import { LedgerMatchFilterTypes } from 'constants/ledger';

/**
 * Returns correct API params based on passed filter type
 * @param {string} activeFilter
 * @returns {Object}
 */
export const filterTypeToAPIParams = (activeFilter) => {
  switch (activeFilter) {
    case LedgerMatchFilterTypes.MATCHED:
      return { is_archived: false, is_matched: true };

    case LedgerMatchFilterTypes.ARCHIVED:
      return { is_archived: true };

    case LedgerMatchFilterTypes.UNMATCHED:
    default:
      return { is_archived: false, is_matched: false };
  }
};

import { createSelector } from 'reselect';

import { idSelector } from 'selectors/globalSelectors';

const getState = (state) => state.companies;

/**
 * Selects the companies by id from the state
 * @param {object} state - Redux state
 */
export const companiesSelector = createSelector([getState], (companies) => companies.byId);

/**
 * Selects the companies infoById from the state
 * @param {object} state - Redux state
 */
export const companyInfoByIdSelector = createSelector([getState], (companies) => companies.infoById);

/**
 * Selects the companies errors from the state
 * @param {object} state - Redux state
 */
export const companiesErrorSelector = createSelector([getState], (companies) => companies.errors);

/**
 * Selects the company
 * @param {object} state - Redux state
 * @param {string} companyId - Company id
 */
export const companySelector = createSelector(
  [companiesSelector, idSelector],
  (companies, companyId) => companies[companyId],
);

/**
 * Selects the isFetching bool for companies from the state
 * @param {object} state - Redux state
 */
export const isFetchingCompanySelector = createSelector([getState], (companies) => companies.isFetching);

/**
 * Selects the isUpdating bool for companies from the state
 * @param {object} state - Redux state
 */
export const isUpdatingCompanySelector = createSelector([getState], (companies) => companies.isUpdating);

import PropTypes from 'prop-types';
import React from 'react';

import { EntityWidgetHeaderTitle, Text } from 'components';

import { typography } from 'constants/styles';

import { getRoleLabelText } from 'helpers/permissions';

import './RoleCardEntityWidgetHeaderTitle.scss';

/**
 * Create a header title with an icon according to the role name for the RoleCardEntityWidget.
 *
 * @param {ComponentProps} props
 * @param {Role.name} props.roleName
 * @returns {StatelessComponent}
 */
const RoleCardEntityWidgetHeaderTitle = ({ roleName }) => (
  <EntityWidgetHeaderTitle className="role-card-entity-widget__header-title ml-2">
    <Text.ExtraBold size={typography.TextSize.LEVEL_200}>{getRoleLabelText(roleName)}</Text.ExtraBold>
  </EntityWidgetHeaderTitle>
);

RoleCardEntityWidgetHeaderTitle.propTypes = {
  roleName: PropTypes.string.isRequired,
};

export default RoleCardEntityWidgetHeaderTitle;

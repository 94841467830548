import * as routines from 'actions/routines/permissionGroups';

import { createByIdReducer } from 'store/redux';

const byIdReducer = createByIdReducer({
  key: 'permissionGroup',
  initialState: {},
  types: [routines.fetchPermissionGroupsRoutine.SUCCESS],
  // add any relationships here
});

export default byIdReducer;

export const button = {
  full: {
    xl: {
      dark: 'btn btn--full btn--xl main dark',
      darker: 'btn btn--full btn--xl main darker',
      primary: 'btn btn--full btn--xl main primary',
      cancel: 'btn btn--full btn--xl main cancel',
      positive: 'btn btn--full btn--xl main positive',
      confirm: 'btn btn--full btn--xl main confirm',
      white: 'btn btn--full btn--xl main white',
      xero: 'btn btn--full btn--xl main xero',
      qbo: 'btn btn--full btn--xl main qbo',
      loft: 'btn btn--full btn--xl main loft',
    },
    m: {
      cancel: 'btn btn--m btn--inherit main cancel',
      dark: 'btn btn--m btn--inherit main dark',
      primary: 'btn btn--m btn--inherit main primary',
      confirm: 'btn btn--m btn--inherit main confirm',
      xero: 'btn btn--m btn--inherit main xero',
    },
    sm: {
      dark: 'btn btn--sm btn--inherit main dark',
      primary: 'btn btn--sm btn--inherit main primary',
      confirm: 'btn btn--sm btn--inherit main confirm',
    },
  },
  half: {
    xl: {
      darker: 'btn btn--half btn--xl main darker',
      xero: 'btn btn--half btn--xl main xero',
      qbo: 'btn btn--half btn--xl main qbo',
      loft: 'btn btn--half btn--xl main loft',
      netsuite: 'btn btn--half btn--xl main netsuite',
    },
  },
  ghost: {
    xl: {
      primary: 'btn btn--full btn--xl ghost ghost--primary',
    },
    m: {
      cancel: 'btn btn--full btn--m ghost ghost--cancel',
      primary: 'btn btn--full btn--m ghost ghost--primary',
      primaryLink: 'btn btn--m ghost ghost--primary-link',
    },
    sm: {
      cancel: 'btn btn--full btn--sm ghost ghost--cancel',
      primary: 'btn btn--sm ghost ghost--primary',
      primaryLink: 'btn btn--sm ghost ghost--primary-link',
      success: 'btn btn--sm ghost ghost--success',
    },
  },
  centered: {
    m: {
      primary: 'btn btn--m ghost ghost--primary',
      confirm: 'btn btn--m main confirm',
      cancel: 'btn btn--m ghost ghost--cancel',
      delete: 'btn btn--m main cancel',
    },
  },
};

export const field = {
  xl: {
    full: 'w-full',
    left: 'w-m',
    right: 'w-m right',
    third: 'w-third',
    twoThirds: 'w-two-third',
    threeQuarters: 'w-three-quarters',
    quarter: 'w-sm',
  },
};

import { SubmissionError } from 'redux-form';

import { ItemKinds } from 'constants/item';

import { isExternalPathAccept, isExternalPathPay } from 'helpers/external';

/**
 * Accept payments - will initiate/complete any pending items.
 */
export const handleAcceptPayments = async (values, options) => {
  const { onSubmitAllExistingItems, partnershipId, successCallback, item } = options;
  const { version, id } = Object(item);

  // Add payment kind via meta
  const meta = {
    kind: isExternalPathPay(window.location) ? ItemKinds.PAYABLE : ItemKinds.RECEIVABLE,
    id,
  };

  const result = await onSubmitAllExistingItems(
    {
      ...values,
      version,
    },
    meta,
    partnershipId,
    'accept',
    successCallback,
  );

  if (result && result.errors) {
    throw new SubmissionError(result.errors.fields);
  }
};

/**
 * Pay a single item
 */
const handlePayInvoice = async (values, options) => {
  const { onSubmitExistingItem, item, partnershipId, successCallback } = options;

  const result = await onSubmitExistingItem(values, partnershipId, item, 'pay', successCallback);

  if (result && result.errors) {
    throw new SubmissionError(result.errors.fields);
  }
};

const submitPartnerCompanyPaymentMethod = async (values, options = {}) => {
  const { fundingAccounts, itemId } = options;

  const myFundingAccount = fundingAccounts[values.fundingAccount];

  if (!myFundingAccount) {
    return;
  }

  const submitValues = {
    fundingAccount: values.fundingAccount,
    paymentDeliveryMethod: values.paymentDeliveryMethod,
  };

  // vendor is accepting an invite and any pending item(s)
  if (myFundingAccount.isValid && isExternalPathAccept(window.location)) {
    await handleAcceptPayments(submitValues, options);
    return;
  }

  if (myFundingAccount.isValid && isExternalPathPay(window.location)) {
    if (itemId) {
      await handlePayInvoice(submitValues, options);
      return;
    }

    // handleAcceptPayments is analogous to a hand-shake call. In the backend,
    // handlePayInvoice calls it after it's done so no need to do it if paying for an invoice.
    await handleAcceptPayments(submitValues, options);
  }
};

export default submitPartnerCompanyPaymentMethod;

import PropTypes from 'prop-types';
import React from 'react';

import { ItemPaymentTerms } from 'enums/items';

import { getItemPaymentTerms, isItemKindPayable } from 'helpers/items';
import { allValues } from 'helpers/utility';

import { ExternalBlockItem } from 'modules/external/ExternalBlocksSection';
import { ExternalReceiptItemEntry } from 'modules/external/externalReceiptV2/ExternalReceiptSingleItem/components';

/**
 * Component rendering External Receipt Item entries for item kind payable
 * @param {ComponentProps} props
 * @param {Item} props.item
 * @returns {StatelessComponent}
 */
const ExternalReceiptPayableItemEntries = ({ item }) => {
  const visible = isItemKindPayable(item);
  const paymentTerms = getItemPaymentTerms(item.paymentTerms);

  if (!visible) {
    return null;
  }

  return (
    <ExternalBlockItem>
      <ExternalReceiptItemEntry label="Invoice no." value={item.reference} />
      <ExternalReceiptItemEntry label="Reference" value={item.partnerReference} />
      <ExternalReceiptItemEntry label="Payment terms" value={paymentTerms} />
    </ExternalBlockItem>
  );
};

ExternalReceiptPayableItemEntries.propTypes = {
  item: PropTypes.shape({
    partnerReference: PropTypes.string,
    paymentTerms: PropTypes.oneOf(allValues(ItemPaymentTerms)),
    reference: PropTypes.string,
  }).isRequired,
};

export default ExternalReceiptPayableItemEntries;

import { combineReducers } from 'redux';

import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';
import {
  createInternationalBankAccountRoutine,
  createInternationalFundingAccountRoutine,
} from 'actions/routines/funding';
import { fetchItemsRoutine } from 'actions/routines/item';

import { getFundingAccountsById } from 'helpers/fundingReducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';
import { allKeys } from 'helpers/utility';

import { SUBMIT_CLEARING_ACCOUNTS_MATCH_SUCCESS } from 'types/clearingAccounts';
import {
  SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_SUCCESS,
  SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_SUCCESS,
} from 'types/connectBank';
import { SUBMIT_CONVERT_FUNDING_ACCOUNT_SUCCESS, SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_SUCCESS } from 'types/funding';
import * as fundingTypes from 'types/funding';
import { SUBMIT_UNMATCHED_PLATFORM_FUNDING_ACCOUNT_SUCCESS } from 'types/integrations';
import { FETCH_ITEM_SUCCESS } from 'types/item';
import {
  FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_SUCCESS,
  FETCH_PARTNERSHIP_SUCCESS,
  SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_SUCCESS,
  SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_SUCCESS,
} from 'types/partnership';
import { FETCH_ITEM_TRANSACTIONS_SUCCESS, FETCH_TRANSACTIONS_SUCCESS } from 'types/transactions';

const fundingAccountsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case createInternationalFundingAccountRoutine.SUCCESS:
    case createInternationalBankAccountRoutine.SUCCESS:
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
    case fetchItemsRoutine.SUCCESS:
    case fundingTypes.FETCH_FUNDING_ACCOUNTS_SUCCESS:
    case fundingTypes.FETCH_SINGLE_FUNDING_ACCOUNT_SUCCESS:
    case FETCH_ITEM_SUCCESS:
    case FETCH_ITEM_TRANSACTIONS_SUCCESS:
    case FETCH_TRANSACTIONS_SUCCESS:
    case FETCH_PARTNERSHIP_SUCCESS:
    case FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_SUCCESS:
    case SUBMIT_CLEARING_ACCOUNTS_MATCH_SUCCESS:
    case fundingTypes.SUBMIT_CONVERT_FUNDING_ACCOUNT_SUCCESS:
    case SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_SUCCESS:
    case SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_SUCCESS:
    case SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_SUCCESS:
    case SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_SUCCESS:
    case SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_SUCCESS:
    case SUBMIT_UNMATCHED_PLATFORM_FUNDING_ACCOUNT_SUCCESS:
      return deepMergeWithArrayReplacement(state, getFundingAccountsById(action.payload.fundingAccount));

    case fundingTypes.UPDATE_FUNDING_ACCOUNT:
      return {
        ...state,
        [action.fundingAccountId]: {
          ...state[action.payload.fundingAccountId],
          isValid: true,
        },
      };

    default:
      return state;
  }
};

const allFundingAccountsReducer = (state = [], action) => {
  switch (action.type) {
    case createInternationalFundingAccountRoutine.SUCCESS:
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
    case fetchItemsRoutine.SUCCESS:
    case fundingTypes.FETCH_FUNDING_ACCOUNTS_SUCCESS:
    case FETCH_ITEM_SUCCESS:
    case FETCH_ITEM_TRANSACTIONS_SUCCESS:
    case FETCH_TRANSACTIONS_SUCCESS:
    case FETCH_PARTNERSHIP_SUCCESS:
    case FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_SUCCESS:
    case fundingTypes.SUBMIT_CONVERT_FUNDING_ACCOUNT_SUCCESS:
    case SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_SUCCESS:
    case SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_SUCCESS:
    case SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_SUCCESS:
    case SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_SUCCESS:
    case SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_SUCCESS:
      return Array.from(new Set([...state, ...allKeys(action.payload.fundingAccount)]));

    default:
      return state;
  }
};

const fundingAccountLastSubmittedIdReducer = (state = null, action) => {
  switch (action.type) {
    case createInternationalFundingAccountRoutine.SUCCESS:
    case SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_SUCCESS:
    case SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_SUCCESS:
    case SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_SUCCESS:
      // The first key on the success action will be the id
      return Object.keys(action.payload.fundingAccount)[0];

    default:
      return state;
  }
};

const fundingAccountLastConvertedIdReducer = (state = null, action) => {
  switch (action.type) {
    case SUBMIT_CONVERT_FUNDING_ACCOUNT_SUCCESS:
      // The first key on the success action will be the id
      return allKeys(action.payload.fundingAccount)[0];

    case SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_SUCCESS:
    case SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_SUCCESS:
    case SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_SUCCESS:
      // if a new funding account is submitted, we want the last converted to be null
      return null;

    default:
      return state;
  }
};

const errorReducer = (state = null, action) => {
  switch (action.type) {
    case fundingTypes.FETCH_FUNDING_ACCOUNTS_FAILURE:
      return action.payload.errors;

    case fundingTypes.FETCH_FUNDING_ACCOUNTS_REQUEST:
    case fundingTypes.FETCH_FUNDING_ACCOUNTS_SUCCESS:
      return null;

    default:
      return state;
  }
};

const convertReducer = (state = false, action) => {
  switch (action.type) {
    case fundingTypes.SUBMIT_CONVERT_FUNDING_ACCOUNT_REQUEST:
      return true;

    case fundingTypes.SUBMIT_CONVERT_FUNDING_ACCOUNT_FAILURE:
    case fundingTypes.SUBMIT_CONVERT_FUNDING_ACCOUNT_SUCCESS:
      return false;

    default:
      return state;
  }
};

const fetchReducer = (state = false, action) => {
  switch (action.type) {
    case fundingTypes.FETCH_FUNDING_ACCOUNTS_REQUEST:
      return true;

    case fundingTypes.FETCH_FUNDING_ACCOUNTS_FAILURE:
    case fundingTypes.FETCH_FUNDING_ACCOUNTS_SUCCESS:
      return false;

    default:
      return state;
  }
};

const lastUpdatedReducer = (state = null, action) => {
  switch (action.type) {
    case fundingTypes.FETCH_FUNDING_ACCOUNTS_SUCCESS:
      return new Date();

    default:
      return state;
  }
};

const fundingAccountsReducer = combineReducers({
  allIds: allFundingAccountsReducer,
  byId: fundingAccountsByIdReducer,
  errors: errorReducer,
  isConverting: convertReducer,
  isFetching: fetchReducer,
  lastConvertedId: fundingAccountLastConvertedIdReducer,
  lastSubmittedId: fundingAccountLastSubmittedIdReducer,
  lastUpdated: lastUpdatedReducer,
});

export {
  allFundingAccountsReducer,
  convertReducer,
  errorReducer,
  fetchReducer,
  fundingAccountLastConvertedIdReducer,
  fundingAccountLastSubmittedIdReducer,
  fundingAccountsByIdReducer,
  lastUpdatedReducer,
};
export default fundingAccountsReducer;

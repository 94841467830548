import _get from 'lodash/get';
import { reset } from 'redux-form';
import { all, call, put } from 'redux-saga/effects';
import swal from 'sweetalert';

import { handleRequestErrors } from 'actions/errors';
import * as inviteActions from 'actions/inviteTeamMember';
import * as actions from 'actions/memberships';
import { deleteRepresentativeDocumentRoutine, postRepresentativeDocumentRoutine } from 'actions/routines/documents';
import {
  lookupMembershipInviteEmailRoutine,
  membershipInviteSubmitRoutine,
  revokeMembershipInviteRoutine,
} from 'actions/routines/inviteTeamMember';
import {
  enableMembershipRoutine,
  disableMembershipRoutine,
  updateMembershipRoutine,
} from 'actions/routines/membership';
import { deactivateTeamMemberModalRoutine } from 'actions/routines/modal';
import { toggleInviteTeamMemberModal } from 'actions/signUpFlow';
import { showErrorUi, showSuccessUi } from 'actions/ui';

import { inviteTeamMemberFormFields } from 'constants/formFields';
import { ErrorIndicatorMessages, SuccessIndicatorMessages } from 'constants/ui';

import { parseMembership } from 'data/parse';
import { membershipSubmitTransformers } from 'data/submitTransformers';

import { getRequestErrorAction, parseCaughtError, parseErrorResponse } from 'helpers/errors';
import {
  getDisableSuccessIndicatorMessage,
  getEnableSuccessIndicatorMessage,
  getReInviteSuccessIndicatorMessages,
  getRevokeInviteSuccessIndicatorMessages,
} from 'helpers/memberships';
import { sagaWatcher } from 'helpers/saga';

import { inviteTeamMemberForm } from 'modules/signup-v3/constants';
import { inviteTeamMemberSuccessMessage } from 'modules/signup-v3/helpers/message';

import { deleteMembershipDocument, postMembershipDocument } from 'sagas/memberships/membershipDocumentsSagas';

import { FetchService } from 'services';

import * as inviteTypes from 'types/inviteTeamMember';
import * as types from 'types/memberships';

import * as api from './api';
import * as tasks from './tasks';

const { deactivate, emailLookup } = parseMembership;

/**
 * Disable a membership.
 * @param {ReduxSagaRoutineAction} action
 * @param {Payload} action.payload
 * @returns {IterableIterator<*>}
 */
export function* disableMembership(action) {
  const { payload } = action;

  let errorData = {};

  yield put(disableMembershipRoutine.request());

  try {
    const transformedPayload = membershipSubmitTransformers.maybeAcknowledgeMembershipDeactivation(payload);
    const response = yield call(api.disableMembership, transformedPayload);

    if (response.ok) {
      yield all([
        put(disableMembershipRoutine.success(response.data)),
        put(
          deactivateTeamMemberModalRoutine.trigger({
            open: false,
            membership: null,
          }),
        ),
        put(showSuccessUi(getDisableSuccessIndicatorMessage(payload.membership))),
      ]);
      return;
    }

    if (FetchService.isResponseUnprocessableEntity(response)) {
      const errors = deactivate.getDeactivateMembershipErrors(response.errors);

      yield all([put(deactivateTeamMemberModalRoutine.failure({ errors })), put(disableMembershipRoutine.failure())]);
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(disableMembershipRoutine.failure, errorData));
}

/**
 * Re-enable a membership.
 * @param {ReduxSagaRoutineAction} action
 * @param {Payload} action.payload
 * @returns {IterableIterator<*>}
 */
export function* enableMembership(action) {
  const { payload } = action;

  let errorData = null;

  yield put(enableMembershipRoutine.request());

  try {
    const response = yield call(api.enableMembership, payload);

    if (response.ok) {
      yield all([
        put(enableMembershipRoutine.success(response.data)),
        put(showSuccessUi(getEnableSuccessIndicatorMessage(payload))),
        call(swal.close),
      ]);
    } else {
      errorData = parseErrorResponse(response);
    }
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  if (errorData) {
    yield put(handleRequestErrors(enableMembershipRoutine.failure, errorData));
  }
}

/**
 * Fetch a membership invite.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* fetchMembershipInvite(action) {
  const { payload } = action;

  let errorData = {};

  try {
    const { companyId, membershipInviteId, membershipInviteToken } = payload;

    const response = yield call(api.fetchMembershipInvite, companyId, membershipInviteId, membershipInviteToken);

    if (response.ok) {
      yield put(inviteActions.fetchMembershipInviteSuccess(response.data));
      return;
    }

    errorData = parseErrorResponse(response, { muteAlerts: true });
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(inviteActions.fetchMembershipInviteFailure, errorData));
}

/**
 * Fetch all membership (team-member) invites for company.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* fetchAllMembershipInvites(action) {
  const { payload } = action;

  let errorData = {};

  try {
    const { companyId } = payload;

    const response = yield call(api.fetchAllMembershipInvites, companyId);

    if (response.ok) {
      yield put(inviteActions.fetchAllMembershipInvitesSuccess(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(inviteActions.fetchAllMembershipInvitesFailure, errorData));
}

/**
 * Send a membership (teammate) invite.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* submitMembershipInvite(action) {
  const { payload } = action;

  yield put(membershipInviteSubmitRoutine.request());

  let submitErrors = {};

  try {
    const { form, meta } = payload;
    const response = yield call(api.submitMembershipInvite, meta.companyId, form);

    if (response.ok) {
      yield all([
        put(showSuccessUi(SuccessIndicatorMessages.MEMBERSHIP_INVITE_SUCCESS)),
        put(membershipInviteSubmitRoutine.success(response.data)),
      ]);
      return;
    }

    submitErrors = parseErrorResponse(response, { formPath: 'form' });
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(submitErrors);
  yield put(errorAction(membershipInviteSubmitRoutine.failure, submitErrors));
}

// created so we can handle default role, company id and custom error feedback
// in future to sign up v3
export function* submitMembershipSignUpInvite(action) {
  const { payload } = action;

  yield put(membershipInviteSubmitRoutine.request());
  let submitErrors = {};
  try {
    const { form, companyId, adminRole } = payload;
    const response = yield call(api.submitMembershipInvite, companyId, {
      ...form,
      role: {
        id: adminRole,
      },
    });

    if (response.ok) {
      yield all([
        put(
          showSuccessUi(inviteTeamMemberSuccessMessage(form), {
            dataFullStory: true,
          }),
        ),
        put(toggleInviteTeamMemberModal()),
        put(membershipInviteSubmitRoutine.success(response.data)),
        put(reset(inviteTeamMemberForm)),
      ]);
      return;
    }

    submitErrors = parseErrorResponse(response, { formPath: 'form' });
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(submitErrors);
  yield put(errorAction(membershipInviteSubmitRoutine.failure, submitErrors));
}
/**
 * Resend a membership (teammate) invite.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* resendMembershipInvite(action) {
  const { payload } = action;

  let errorData = {};

  try {
    const { company, id } = payload;

    const response = yield call(api.resendMembershipInvite, company, id);

    if (response.ok) {
      yield all([
        put(inviteActions.fetchAllMembershipInvitesSuccess(response.data)),
        put(showSuccessUi(getReInviteSuccessIndicatorMessages(payload))),
        put(inviteActions.fetchAllMembershipInvitesRequest()),
      ]);

      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield all([
    put(handleRequestErrors(inviteActions.fetchAllMembershipInvitesFailure, errorData)),
    put(showErrorUi(ErrorIndicatorMessages.RE_INVITE_CONTACT)),
  ]);
}

/**
 * Revoke a membership (teammate) invite.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* revokeMembershipInvite(action) {
  const { payload } = action;

  let errorData = {};

  yield put(revokeMembershipInviteRoutine.request());

  try {
    const { company, id } = payload;

    const response = yield call(api.revokeMembershipInvite, company, id);

    if (response.ok) {
      yield all([
        // Since the delete endpoint returns 204 right now, we need a way to remove the membership invite from the reducer.
        put(revokeMembershipInviteRoutine.success({ id })),
        put(showSuccessUi(getRevokeInviteSuccessIndicatorMessages(payload))),
      ]);

      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield all([
    put(handleRequestErrors(revokeMembershipInviteRoutine.failure, errorData)),
    put(showErrorUi(ErrorIndicatorMessages.REVOKE_INVITE_CONTACT)),
  ]);
}

/**
 * Fetch data for a specific membership.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* fetchSingleMembership(action) {
  let errorData = {};

  try {
    const {
      payload: { membershipId },
    } = action;

    const response = yield call(api.fetchSingleMembership, membershipId);

    if (response.ok) {
      const meta = { membershipId };
      yield put(actions.fetchSingleMembershipSuccess(response.data, meta));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchSingleMembershipFailure, errorData));
}

/**
 * Fetch config for a membership.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* fetchMembershipConfig(action) {
  let errorData = {};

  try {
    const {
      payload: { membershipId },
    } = action;

    const response = yield call(api.fetchMembershipConfig, membershipId);

    if (response.ok) {
      yield put(actions.fetchMembershipConfigSuccess(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchMembershipConfigFailure, errorData));
}

/**
 * When inviting a new team member to become a RCTM, send their email to the BE to validate.
 * @param {Object} action
 * @param {Object} action.payload
 * @returns {IterableIterator<*>}
 */
export function* fetchMembershipInviteEmail({ payload }) {
  const email = _get(payload.values, inviteTeamMemberFormFields.EMAIL);

  yield put(lookupMembershipInviteEmailRoutine.request());

  let errorData = {};

  try {
    const queryParams = { email };
    const response = yield call(api.fetchMembershipInviteEmail, queryParams);

    if (response.ok) {
      if (emailLookup.membershipEmailLookupHasNoWarnings(response)) {
        yield put(lookupMembershipInviteEmailRoutine.success());
        return;
      }

      const failurePayload = yield call(tasks.createEmailLookupWarningsPayload, response);
      yield put(lookupMembershipInviteEmailRoutine.failure(failurePayload));

      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(errorData);
  yield put(errorAction(lookupMembershipInviteEmailRoutine.failure, errorData));
}

/**
 * When updating a membership (ie. changing details on the user settings profile view)
 * note: we have a thunk which calls the same endpoint at thunks/updateMembership
 * @param {Object} action
 * @param {Object} action.payload
 * @returns {IterableIterator<*>}
 */
export function* updateMembership(action) {
  const { payload } = action;

  yield put(updateMembershipRoutine.request);

  let errorData = {};

  try {
    const { meta } = payload;

    // Remove user data conditionally (we don't allow updating an affiliate)
    const formData = membershipSubmitTransformers.removeUserDataWhenNotAllowed(payload);

    const response = yield call(api.updateMembership, meta.membershipId, meta.userId, formData);

    if (response.ok) {
      yield all([
        put(updateMembershipRoutine.success(response.data)),
        put(showSuccessUi(SuccessIndicatorMessages.MEMBERSHIP_UPDATE_SUCCESS)),
      ]);

      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield all([
    put(handleRequestErrors(updateMembershipRoutine.failure, errorData)),
    put(showErrorUi(ErrorIndicatorMessages.UPDATE_MEMBERSHIP)),
  ]);
}

/**
 * Root memberships saga.
 * @return {IterableIterator<*>}
 */
export default function* memberships() {
  yield sagaWatcher([
    {
      type: disableMembershipRoutine.TRIGGER,
      saga: disableMembership,
    },
    {
      type: enableMembershipRoutine.TRIGGER,
      saga: enableMembership,
    },
    {
      type: types.FETCH_MEMBERSHIPS_REQUEST,
      saga: tasks.fetchMemberships,
    },
    {
      type: membershipInviteSubmitRoutine.TRIGGER,
      saga: submitMembershipInvite,
    },
    {
      type: inviteTypes.SUBMIT_MEMBERSHIP_SIGNUP_INVITE,
      saga: submitMembershipSignUpInvite,
    },
    {
      type: inviteTypes.RESEND_MEMBERSHIP_INVITE,
      saga: resendMembershipInvite,
    },
    {
      type: inviteTypes.FETCH_MEMBERSHIP_INVITE_REQUEST,
      saga: fetchMembershipInvite,
    },
    {
      type: inviteTypes.FETCH_MEMBERSHIP_INVITES_REQUEST,
      saga: fetchAllMembershipInvites,
    },
    {
      type: lookupMembershipInviteEmailRoutine.TRIGGER,
      saga: fetchMembershipInviteEmail,
    },
    {
      type: revokeMembershipInviteRoutine.TRIGGER,
      saga: revokeMembershipInvite,
    },
    {
      type: types.FETCH_MEMBERSHIP_REQUEST,
      saga: fetchSingleMembership,
    },
    {
      type: types.FETCH_MEMBERSHIP_CONFIG_REQUEST,
      saga: fetchMembershipConfig,
    },
    {
      type: updateMembershipRoutine.TRIGGER,
      saga: updateMembership,
    },
    {
      type: postRepresentativeDocumentRoutine.TRIGGER,
      saga: postMembershipDocument,
    },
    {
      type: deleteRepresentativeDocumentRoutine.TRIGGER,
      saga: deleteMembershipDocument,
    },
  ]);
}

import PropTypes from 'prop-types';
import React from 'react';

import { DisclosureList } from 'components/disclosureList';
import { EntityWidgetSection } from 'components/entityWidget';

import { BankAccountViewType } from 'constants/ui';

import { isBankAccountViewTypeFull } from 'helpers/ui';

import { text } from 'modules/fundingAccount/bankAccount/constants';

import BankAccountUseSelect from './BankAccountUseSelect';

const BankAccountUseSection = ({ bodyRowProps, fundingAccount, viewType }) => {
  const disclosureListItems = React.useMemo(
    () => [
      {
        component: BankAccountUseSelect,
        data: { fundingAccount },
        key: 'bankAccountUseSelect',
        wrapWithCol: false,
      },
    ],
    [fundingAccount],
  );

  if (!isBankAccountViewTypeFull(viewType)) {
    return null;
  }

  return (
    <EntityWidgetSection>
      <DisclosureList
        bodyRowProps={bodyRowProps}
        headerText={text.accountUsageSectionTitle}
        items={disclosureListItems}
        keyExtractor={(item) => item.key}
        startOpen={false}
      />
    </EntityWidgetSection>
  );
};

BankAccountUseSection.propTypes = {
  bodyRowProps: PropTypes.shape({}),
  fundingAccount: PropTypes.shape({}),
  viewType: PropTypes.oneOf(Object.values(BankAccountViewType)),
};

BankAccountUseSection.defaultProps = {
  bodyRowProps: {},
  fundingAccount: {},
  viewType: BankAccountViewType.FULL,
};

export default BankAccountUseSection;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Emoji = ({ ariaLabel, className, text }) => (
  <span
    aria-label={ariaLabel}
    className={classNames({
      'font--emoji': true,
      [className]: !!className,
    })}
    role="img"
  >
    {text}
  </span>
);

Emoji.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  className: PropTypes.string,
  text: PropTypes.string,
};

Emoji.defaultProps = {
  className: undefined,
  text: undefined,
};

export default Emoji;

import { Button, Tooltip } from '@routable/gross-ds';
import React from 'react';
import { useDispatch } from 'react-redux';
import { openAddTaxFormSidePanel } from 'ducks/tax/taxInput';
import PermissionResourceAction from 'constants/permissions';
import { useGrossDsPermissions } from 'hooks';
const AddTaxFormButton = () => {
    const dispatch = useDispatch();
    const { hasPermission, tooltipProps } = useGrossDsPermissions([PermissionResourceAction.TAX_TEAM_MEMBER_ENTRY]);
    return (React.createElement(Tooltip, { ...tooltipProps, position: "top", variant: "light" },
        React.createElement(Button, { "data-testid": "add-tax-form-button", disabled: !hasPermission, intent: "secondary", onPress: () => dispatch(openAddTaxFormSidePanel({})), size: "small", variant: "outline" }, "Add tax form")));
};
export default AddTaxFormButton;

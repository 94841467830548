import PropTypes from 'prop-types';
import React from 'react';

import { getClassNames } from 'helpers/ui';

/**
 * Table body cell component for the invoice generator ledger field tables.
 * @param {ComponentProps} props
 * @param {*} props.children
 * @param {RestOfProps} rest
 * @return {StatelessComponent}
 */
const InvoiceGeneratorTableBodyCell = ({ children, ...rest }) => {
  const [isDisabled, setIsDisabled] = React.useState(false);

  return (
    <div
      {...rest}
      className={getClassNames(rest, {
        disabled: isDisabled,
        'table-row--column': true,
        'ledger-form-field': true,
        'create-item-field': true,
        'remove-padding': true,
      })}
    >
      <div className="column-content">
        {React.cloneElement(children, {
          ...children.props,
          setIsDisabled,
        })}
      </div>
    </div>
  );
};

InvoiceGeneratorTableBodyCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

InvoiceGeneratorTableBodyCell.defaultProps = {
  children: undefined,
  className: undefined,
};

export default InvoiceGeneratorTableBodyCell;

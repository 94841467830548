import { PLATFORM_DISPLAY_SHORT_NAME } from './platform';
export const BANK_ACCOUNT_TRANSFER = 'Bank account transfer';
export const achDepositTime = 'Estimated deposit time: Up to 5 business days';
export const CHECK_DELIVERY = 'Check delivery';
export const checkArrivalTime = 'Arrival time: 7-10 business days';
export const DEPOSIT_INFORMATION_TEXT = `The deposit descriptions begin with "${PLATFORM_DISPLAY_SHORT_NAME}", and each one is less than $1.00.`;
export const SWIFT_CHARGE_OPTIONS_MESSAGES = {
    our: 'Pay full SWIFT fee yourself',
    sha: 'Share SWIFT fee with vendor',
};
export const SWIFT_CHARGE_OPTIONS_BILLING_CODES = {
    our: 'ap_international_swift_full',
    sha: 'ap_international_swift_shared',
};
export const SWIFT_CHARGE_OPTIONS_TOOLTIPS = {
    our: (cost) => `Intermediary bank charges are covered by you and not deducted from the payment amount the vendor receives. This option is called SWIFT OUR and has a ${cost} fee.`,
    sha: (cost) => `Intermediary bank charges are shared between you and the vendor and deducted from the payment amount the vendor receives. This option is called SWIFT SHA and has a ${cost} fee.`,
};

import { connect } from 'react-redux';

import { verifyFundingAccountModalOpen } from 'actions/funding';

import { ledgerIntegrationApplicationSelector, ledgerNameSelector } from 'selectors/integrationsSelectors';
import { ledgerBankAccountsByIdSelector } from 'selectors/ledgerInfoSelectors';

import AccountingIntegrationSection from './AccountingIntegrationSection';

const mapStateToProps = (state) => ({
  ledgerApp: ledgerIntegrationApplicationSelector(state),
  ledgerBankAccounts: ledgerBankAccountsByIdSelector(state),
  ledgerName: ledgerNameSelector(state),
});

const mapDispatchToProps = {
  onVerifyFundingAccountModalOpen: verifyFundingAccountModalOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountingIntegrationSection);

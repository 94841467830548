import _set from 'lodash/set';
import { call, select } from 'redux-saga/effects';

import { fetchMembershipsFailure, fetchMembershipsSuccess } from 'actions/memberships';

import { inviteTeamMemberFormFields } from 'constants/formFields';

import { parseMembership } from 'data/parse';

import { parseErrorResponse } from 'helpers/errors';
import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { createSaga } from 'helpers/saga';
import { allValues, hasLength } from 'helpers/utility';

import { currentCompanyOneMemberWithUserInfoSelector } from 'selectors/currentCompanySelectors';

import * as api from './api';

const { emailLookup } = parseMembership;

/**
 * Fetch current memberships.
 * @return {IterableIterator<*>}
 */
export function* fetchMemberships(action) {
  const actions = {
    failure: fetchMembershipsFailure,
    success: fetchMembershipsSuccess,
  };

  const options = {
    onParseError: (response) => parseErrorResponse(response, { muteAlerts: true }),
  };

  yield call(
    createSaga,
    () =>
      api.fetchMemberships({
        skipCache: action?.payload?.skipCache,
      }),
    actions,
    options,
  );
}

/**
 * When inviting a new team member to become a RCTM, the BE has said that the email is no good. Create the payload
 * to dispatch with failure.
 * @param {HttpResponse} response
 * @returns {IterableIterator<*>}
 */
export function* createEmailLookupWarningsPayload(response) {
  const memberships = allValues(getObjectsByIdWithRelationships(response.data.membership));
  const membershipInvites = allValues(getObjectsByIdWithRelationships(response.data.membershipInvite));

  // When we have a warning, we're going to save a whole bunch of info about it so we can show the correct warning
  let warnings = _set({}, inviteTeamMemberFormFields.EMAIL, {
    memberships,
    membershipInvites,
    warningType: emailLookup.determineMembershipEmailWarningType({
      memberships,
      membershipInvites,
    }),
  });

  // the search returned a membership that the BE has, but we don't
  if (hasLength(memberships)) {
    // fetch all the memberships to get an updated list
    // NOTE: you can't fetchSingleMembership...you can only fetch your own membership that way ¯\_(ツ)_/¯
    yield call(fetchMemberships);
    // find the membership in state and add the User data so we have name/email
    const foundMembership = yield select(currentCompanyOneMemberWithUserInfoSelector, { id: memberships[0].id });

    // We will need this membership with User data to display name/email in a hint
    warnings = _set(warnings, `${inviteTeamMemberFormFields.EMAIL}.memberships`, [foundMembership]);
  }

  return {
    // we'll also update the memberships reducer
    memberships,
    // and the membershipInvites reducer
    membershipInvites,
    // these warnings go to form state
    warnings,
  };
}

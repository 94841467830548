import classNames from 'classnames';
import React from 'react';
import { HeadingTitle, Text } from 'components/text';
import { TextColor, TextSize } from 'constants/styles/typography';
import { isLtLastIndex } from 'helpers/utility';
const SimpleFAQ = ({ className, items }) => (React.createElement("div", { className: className }, items.map((item, idx) => (React.createElement("div", { className: classNames({
        'margin-bottom--large': isLtLastIndex(idx, items),
    }), key: item.question },
    React.createElement(HeadingTitle, { className: "has-description font-regular" }, item.question),
    React.createElement(Text.Regular, { className: "display--block", color: TextColor.DARK_JORDAN, size: TextSize.LEVEL_300 }, item.answer))))));
export default SimpleFAQ;

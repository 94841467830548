import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const HintContent = ({ children, className, marginBottom }) => (
  <span
    className={classNames({
      'hint--content': true,
      'remove-margin-bottom': !marginBottom,
      [className]: !!className,
    })}
  >
    {children}
  </span>
);

HintContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  marginBottom: PropTypes.bool,
};

HintContent.defaultProps = {
  className: undefined,
  marginBottom: false,
};

export default HintContent;

import PropTypes from 'prop-types';
import React from 'react';
import Script from 'react-load-script';

import { autoCompleteScriptUrl } from 'constants/google';
import { countryListOptions } from 'constants/i18n';

import initAutoComplete from '../../helpers/initAutoCompleteHelper';

/**
 * Componentized Google address autocomplete.
 * @param props
 * @returns {*}
 * @constructor
 */
const AutoComplete = (props) => <Script onLoad={() => initAutoComplete({ ...props })} url={autoCompleteScriptUrl} />;

/* eslint-disable react/no-unused-prop-types */
// All props to this component are passed to the initAutoComplete fn as parameters
AutoComplete.propTypes = {
  addressPath: PropTypes.string,
  autocompleteRef: PropTypes.shape().isRequired,
  countryOptions: PropTypes.oneOf(Object.values(countryListOptions)),
  formName: PropTypes.string.isRequired,
};
/* eslint-enable react/no-unused-prop-types */

AutoComplete.defaultProps = {
  addressPath: '',
  countryOptions: countryListOptions.US_AND_CANADA,
};

export default AutoComplete;

import React from 'react';
import { LinkNewPage } from 'components';
import { Image } from 'components/media';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { LOGIN } from 'constants/routes';
import { LOGO } from 'global/images/platform';
import { Header, HeaderLogo, HeaderLogin } from './SignUpFormHeader.styles';
import {} from './types/SignUpFormHeader.types';
const SignUpFormHeader = ({ hasSignInOption }) => (React.createElement(Header, null,
    React.createElement(HeaderLogo, { hasSignInOption: true },
        React.createElement(Image, { alt: PLATFORM_DISPLAY_SHORT_NAME, src: LOGO.NAVY })),
    hasSignInOption && (React.createElement(HeaderLogin, null,
        React.createElement("span", { className: "has-account" }, "Have an account? "),
        React.createElement(LinkNewPage, { href: `${window.location.origin}/${LOGIN}` }, "Log in")))));
export default SignUpFormHeader;

import PropTypes from 'prop-types';
import React from 'react';

import { FlexRow } from 'components';

import { ButtonSize } from 'constants/button';
import { SegmentSize } from 'constants/segment';

import { oneOfValuesFromObject } from 'helpers/propTypes';

/**
 * Button wrapper meant to be used inside Segment.
 * @param {ComponentProps} props
 * @param {Object[]} props.buttons
 * @param {string} props.size
 * @returns {StatelessComponent}
 */
const SegmentButtons = ({ buttons, size }) => {
  if (!buttons?.length) {
    return null;
  }

  const buttonSize = size === SegmentSize.LARGE ? ButtonSize.MEDIUM : ButtonSize.SMALL;

  // TODO: [DEV-419] - Missing key in the buttons.map leads to React errors
  return (
    <FlexRow className="segment--header-buttons margin-left--m" stackOnMobile={false}>
      {buttons.map((button) => React.cloneElement(button, { size: buttonSize }))}
    </FlexRow>
  );
};

SegmentButtons.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.node),
  size: oneOfValuesFromObject(SegmentSize),
};

SegmentButtons.defaultProps = {
  buttons: [],
  size: SegmentSize.MEDIUM,
};

export default SegmentButtons;

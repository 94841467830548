import { createItemFormFields } from '@routable/shared';
import { formValueSelector, getFormSyncErrors } from 'redux-form';
import { createSelector } from 'reselect';

import {
  billViewFormFields,
  bulkImportItemsFormFields,
  commonFormFields,
  createPartnershipFormFields,
  DEFAULT_RECEIVABLE,
  existingItemFinalizeFormFields,
  externalCompanyGeneralFields,
  FORM_UI,
  itemFormFields,
  UI,
  updatePaymentMethodFormFields,
} from 'constants/formFields';
import * as forms from 'constants/forms';

import { getCompanyNamespaceFromUrl } from 'helpers/urls';

import {
  createFieldValueSelector,
  createFormIsValidSelector,
  createGetFormValuesSelector,
  createFormHasSubmitSucceededSelector,
  createFieldSyncErrorsSelector,
  createFormIsSubmittingSelector,
  createFormInitialValuesSelector,
} from 'selectors/createFormSelectors';

import {
  LINE_ITEMS_SECTION_NAME,
  LINE_ITEMS_BLOCKS,
  LINE_ITEMS_SUBSECTIONS,
} from '../modules/dashboard/createItems/invoiceGenerator/components/InvoiceGeneratorView/constants';

// Bulk actions
export const bulkImportItemsIdFieldSelector = createFieldValueSelector(
  forms.formNamesBulkActions.IMPORT_ITEMS,
  bulkImportItemsFormFields.ID,
);

// Funding
export const fundingAddManualBank = formValueSelector(forms.formNamesFunding.ADD_MANUAL_BANK);

/**
 * Selects partner.country field from external add funding account form
 */
export const fundingAddManualBankFormPartnerCountrySelector = createFieldValueSelector(
  forms.formNamesFunding.ADD_MANUAL_BANK,
  commonFormFields.PARTNER_COUNTRY,
);

export const addPartnerFundingAccountFormSelector = formValueSelector(
  forms.formNamesFunding.ADD_PARTNER_FUNDING_ACCOUNT,
);

// Bulk Import
export const bulkImportItemsFormSelector = formValueSelector(forms.formNamesBulkActions.IMPORT_ITEMS);

// Create Item
export const createItemBillViewFormSelector = formValueSelector(forms.formNamesItem.BILL_VIEW);
/** @type {import('reselect').Selector<import('interfaces/redux').ReduxState, number>} */
export const createItemBillViewSelectedBillSelector = createFieldValueSelector(
  forms.formNamesItem.BILL_VIEW,
  billViewFormFields.SELECTED_BILL_INDEX,
);
export const createItemFormSelector = formValueSelector(forms.formNamesItem.CREATE_ITEM);
export const createItemFormSyncErrorsSelector = getFormSyncErrors(forms.formNamesItem.CREATE_ITEM);
/** @type {import('reselect').Selector<import('interfaces/redux').ReduxState, import('interfaces/item').CreateItemFormItem>} */
export const createItemFormItemSelector = createFieldValueSelector(forms.formNamesItem.CREATE_ITEM, 'item');
export const createItemFormItemIdSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  createItemFormFields.ITEM_ID,
);
export const generatePartnerGovernmentIdSelector = (formName) =>
  createFieldValueSelector(formName, 'partner.governmentId');

/** @type {import('reselect').Selector<import('interfaces/redux').ReduxState, import('interfaces/attachment').AttachmentWithPreview[]>} */
export const createItemFormItemBillsSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  itemFormFields.ITEM_BILLS,
);
export const createItemFormMetaApproversManualApproverIdSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  itemFormFields.META_APPROVERS_MANUAL_APPROVER_ID,
);
/** @type {import('reselect').Selector<import('interfaces/redux').ReduxState, import('interfaces/manualApprover').ManualApproverPlacement>} */
export const createItemFormMetaApproversManualPlacementSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  itemFormFields.META_APPROVERS_MANUAL_PLACEMENT,
);
export const createItemFormLedgerInvoiceRefsSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  'ledgerInvoiceRefs',
);
export const createItemFormMainSelector = createFieldValueSelector(forms.formNamesItem.CREATE_ITEM, 'main');
export const createItemFormItemKindSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  createItemFormFields.ITEM_KIND,
);
export const createItemFormMetaSelector = createFieldValueSelector(forms.formNamesItem.CREATE_ITEM, 'meta');
export const createItemFormNameSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  commonFormFields.NAME,
);
/** @type {import('reselect').Selector<import('interfaces/redux').ReduxState, import('interfaces/partnerships').Partnership>} */
export const createItemFormPartnershipSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  createItemFormFields.PARTNERSHIP,
);
export const createItemFormItemMembersSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  createItemFormFields.ITEM_MEMBERS,
);
export const createItemFormCompanyTypeSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  commonFormFields.PARTNER_COMPANY_TYPE,
);
export const createItemFormCurrencyCodeReceiverSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  createItemFormFields.ITEM_CURRENCY_CODE_RECEIVER,
);
export const createItemFormPurchaseOrderSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  createItemFormFields.PURCHASE_ORDER,
);
export const createItemFormPoMatchTypeSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  createItemFormFields.PO_MATCH_TYPE,
);
/** @type {import('reselect').Selector<import('interfaces/redux').ReduxState, import('interfaces/invoice').InvoiceFormUI>} */
export const createItemFormUiSelector = createFieldValueSelector(forms.formNamesItem.CREATE_ITEM, 'ui');
export const createItemFormUiIsBillViewSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  createItemFormFields.UI_IS_BILL_VIEW,
);
export const createItemFormUiIsItemEditSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  createItemFormFields.UI_IS_ITEM_EDIT,
);
export const createItemFormUiShowInvoiceGeneratorSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  createItemFormFields.UI_SHOW_INVOICE_GENERATOR,
);
export const createItemFormUiShowLineItemsWithinFormSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  createItemFormFields.UI_SHOW_LINE_ITEMS_IN_DRAWER,
);
export const createItemFormUiSelectedCompanySelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  createItemFormFields.UI_SELECTED_COMPANY,
);
export const createItemFormUiCountryCodePartnerSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  createItemFormFields.UI_COUNTRY_CODE_PARTNER,
);
export const createItemFormUiSelectedCompanyNameSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  createItemFormFields.UI_SELECTED_COMPANY_NAME,
);
export const createItemFormUiIsPurchaseOrderFetchedSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  createItemFormFields.UI_IS_PURCHASE_ORDER_FETCHED,
);
export const createItemFormUiSkipPurchaseOrderSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  createItemFormFields.UI_SKIP_PURCHASE_ORDER,
);
export const createItemFormUiIsReinitializingFormSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  createItemFormFields.UI_IS_REINITIALIZING_FORM,
);
export const createItemFormAllValuesSelector = createGetFormValuesSelector(forms.formNamesItem.CREATE_ITEM);
export const createItemFormInitialValuesSelector = createFormInitialValuesSelector(forms.formNamesItem.CREATE_ITEM);

/**
 * Selects the partner field value from the Create Item form
 * @type {StandardSelector}
 * @returns {PartnershipMember}
 */
export const createItemFormPartnerSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  createItemFormFields.PARTNER,
);

/**
 * Selects the partner.name field value from the Create Item form
 * @type {StandardSelector}
 * @returns {string}
 */
export const createItemFormPartnerNameSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  commonFormFields.PARTNER_NAME,
);

/**
 * Selects the partner.ledgerName field value from the Create Item form
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {string}
 */
export const createItemFormPartnerLedgerNameSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  commonFormFields.PARTNER_LEDGER_NAME,
);

/**
 * Selects the account line items from the Create Item form
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {LineItem[]}
 */
export const createItemFormAccountLineItemsSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  `${LINE_ITEMS_SECTION_NAME}.${LINE_ITEMS_BLOCKS.ACCOUNT}.${LINE_ITEMS_SUBSECTIONS.ACCOUNT}`,
);

/**
 * Selects the item line items from the Create Item form
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {LineItem[]}
 */
export const createItemFormItemLineItemsSelector = createFieldValueSelector(
  forms.formNamesItem.CREATE_ITEM,
  `${LINE_ITEMS_SECTION_NAME}.${LINE_ITEMS_BLOCKS.ITEM}.${LINE_ITEMS_SUBSECTIONS.ITEM}`,
);

// Existing item
export const existingItemCancelFormSelector = formValueSelector(forms.formNamesExistingItem.CANCEL);
export const existingItemFinalizePaymentFormSelector = formValueSelector(forms.formNamesExistingItem.FINALIZE);
export const existingItemMarkAsPaidFormSelector = formValueSelector(forms.formNamesExistingItem.MARK_AS_PAID);
export const existingItemCurrencyCodeReceiver = createFieldValueSelector(
  forms.formNamesExistingItem.FINALIZE,
  existingItemFinalizeFormFields.FORM_CURRENCY_CODE_RECEIVER,
);
export const existingItemCurrencyCode = createFieldValueSelector(
  forms.formNamesExistingItem.FINALIZE,
  existingItemFinalizeFormFields.FORM_CURRENCY_CODE,
);
export const existingItemIsInternational = createFieldValueSelector(
  forms.formNamesExistingItem.FINALIZE,
  existingItemFinalizeFormFields.UI_IS_INTERNATIONAL,
);
export const existingItemFormFundingAccountSelector = createFieldValueSelector(
  forms.formNamesExistingItem.FINALIZE,
  existingItemFinalizeFormFields.FORM_ITEM_FUNDING_ACCOUNT,
);

// Existing payment
export const existingPaymentCancelFormSelector = formValueSelector(forms.formNamesExistingItem.CANCEL_PAYMENT);

// Create partnership
export const createPartnershipFormSelector = formValueSelector(forms.formNamesPartnership.CREATE);
export const createPartnershipFormAllValuesSelector = createGetFormValuesSelector(forms.formNamesPartnership.CREATE);
export const sendUpdatePaymentLinkFormSelector = formValueSelector(forms.formNamesPartnership.SEND_UPDATE_PAYMENT_LINK);
export const getPartnershipMembers = createSelector(
  [(state, props) => createGetFormValuesSelector(props.form)(state).partnershipMembers],
  (partnershipMembers) => partnershipMembers,
);

// Auth namespace form
export const authNamespaceFormSelector = formValueSelector(forms.formNamesAuthV2.NAMESPACE);
export const authNamespaceHasSubmitSucceededSelector = createFormHasSubmitSucceededSelector(
  forms.formNamesAuthV2.NAMESPACE,
);
export const authNamespaceSelector = createFieldValueSelector(forms.formNamesAuthV2.NAMESPACE, 'namespace');

export const authNamespaceValueSelector = createSelector(
  [authNamespaceSelector],
  (authNamespace) => getCompanyNamespaceFromUrl() || authNamespace,
);

/**
 * Selects the form ui field value from the Create Partnership form
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object}
 */
export const createPartnershipFormUISelector = createFieldValueSelector(
  forms.formNamesPartnership.CREATE,
  createPartnershipFormFields.UI,
);

/**
 * Selects the partner field value from the Create Partnership form
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object}
 */
export const createPartnershipFormPartnerSelector = createFieldValueSelector(
  forms.formNamesPartnership.CREATE,
  createPartnershipFormFields.PARTNER,
);

/**
 * Selects the partner company type field value from the Create Partnership form
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object}
 */
export const createPartnershipFormCompanyTypeSelector = createFieldValueSelector(
  forms.formNamesPartnership.CREATE,
  commonFormFields.PARTNER_COMPANY_TYPE,
);

/**
 * Selects the name field value from the Create Partnership form
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {string}
 */
export const createPartnershipFormNameSelector = createFieldValueSelector(
  forms.formNamesPartnership.CREATE,
  commonFormFields.NAME,
);

/**
 * Selects the partner.name field value from the Create Partnership form
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {string}
 */
export const createPartnershipFormPartnerNameSelector = createFieldValueSelector(
  forms.formNamesPartnership.CREATE,
  commonFormFields.PARTNER_NAME,
);

/**
 * Selects the partner.ledgerName field value from the Create Partnership form
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {string}
 */
export const createPartnershipFormPartnerLedgerNameSelector = createFieldValueSelector(
  forms.formNamesPartnership.CREATE,
  commonFormFields.PARTNER_LEDGER_NAME,
);

/**
 * Selects partner's mailing address field value from the Create Partnership form
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {?CompanyAddress}
 */
export const createPartnershipFormPartnerMailingAddressSelector = createFieldValueSelector(
  forms.formNamesPartnership.CREATE,
  createPartnershipFormFields.ADDRESS,
);

/**
 * Selects the country code field value from the Create Partnership form
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Alpha2}
 */
export const createPartnershipFormPartnerCountryCodeSelector = createFieldValueSelector(
  forms.formNamesPartnership.CREATE,
  createPartnershipFormFields.PARTNER_COUNTRY_CODE,
);

export const createPartnershipFormPartnerCountryCodeErrorSelector = createFieldSyncErrorsSelector(
  forms.formNamesPartnership.CREATE,
  createPartnershipFormFields.PARTNER_COUNTRY_CODE,
);

/**
 * Selects the partnership field value from the Create Partnership form
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object}
 */
export const createPartnershipFormPartnershipSelector = createFieldValueSelector(
  forms.formNamesPartnership.CREATE,
  createPartnershipFormFields.PARTNERSHIP,
);

/**
 * Selects the partnership type field value from the Create Partnership form
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {String}
 */
export const createPartnershipFormPartnershipTypeSelector = createFieldValueSelector(
  forms.formNamesPartnership.CREATE,
  createPartnershipFormFields.PARTNERSHIP_TYPE,
);

/**
 * Selects the partnership members field value from the Create Partnership form
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object}
 */
export const createPartnershipFormPartnershipMembersSelector = createFieldValueSelector(
  forms.formNamesPartnership.CREATE,
  createPartnershipFormFields.PARTNERSHIP_MEMBERS,
);

// Settings
export const settingsBrandingFormSelector = formValueSelector(forms.formNamesSettings.BRAND);
export const settingsLegalBusinessFormSelector = formValueSelector(forms.formNamesSettings.LEGAL_BUSINESS);
export const settingsPayableConfigurationsSelector = formValueSelector(forms.formNamesSettings.PAYABLE_CONFIGURATIONS);
export const settingsUserRepresentativeFormSelector = formValueSelector(forms.formNamesSettings.USER_REPRESENTATIVE);

// External
export const externalCompanyContactFormSelector = formValueSelector(forms.formNamesExternal.PARTNER_COMPANY_CONTACT);
export const externalGeneralFormSelector = formValueSelector(forms.formNamesExternal.PARTNER_COMPANY_GENERAL);
export const externalPaymentFormSelector = formValueSelector(forms.formNamesExternal.PARTNER_COMPANY_PAYMENT_METHOD);
export const externalUpdatePaymentFormSelector = formValueSelector(forms.formNamesExternal.UPDATE_PAYMENT_METHOD);
export const externalCreateManualAddressFormSelector = formValueSelector(forms.formNamesExternal.CREATE_MANUAL_ADDRESS);
export const externalCollectTaxInfoFormSelector = formValueSelector(forms.formNamesExternal.COLLECT_TAX_INFO);
export const externalTaxEDeliveryConsent = formValueSelector(forms.formNamesExternal.TAX_E_DELIVERY_CONSENT);

// External: Update Payment Method Form Field Selectors
export const externalFormUISelector = createFieldValueSelector(forms.formNamesExternal.UPDATE_PAYMENT_METHOD, UI);
export const externalDefaultReceivableSelector = createFieldValueSelector(
  forms.formNamesExternal.UPDATE_PAYMENT_METHOD,
  DEFAULT_RECEIVABLE,
);
export const externalPaymentFundingAccountSelector = createFieldValueSelector(
  forms.formNamesExternal.UPDATE_PAYMENT_METHOD,
  updatePaymentMethodFormFields.FUNDING_ACCOUNT,
);
export const externalPaymentDeliveryMethodSelector = createFieldValueSelector(
  forms.formNamesExternal.UPDATE_PAYMENT_METHOD,
  updatePaymentMethodFormFields.PAYMENT_DELIVERY_METHOD,
);

// External: Accept Partnership Form Field Selectors
/**
 * Selects the ui/formUI field value from the PARTNER_COMPANY_GENERAL form
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object}
 */
export const externalAcceptPartnershipFormUISelector = createFieldValueSelector(
  forms.formNamesExternal.PARTNER_COMPANY_GENERAL,
  FORM_UI,
);

/**
 * Selects the displayInputForTin value in formUI field from the PARTNER_COMPANY_GENERAL form
 * @type {StandardSelector}
 * @returns {Boolean}
 */
export const displayInputForTinFormUISelector = createFieldValueSelector(
  forms.formNamesExternal.PARTNER_COMPANY_GENERAL,
  externalCompanyGeneralFields.FORM_UI_DISPLAY_INPUT_FOR_TIN,
);

/**
 * Selects the companyType field value from the PARTNER_COMPANY_GENERAL form
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object}
 */
export const externalPartnerCompanyTypeSelector = createFieldValueSelector(
  forms.formNamesExternal.PARTNER_COMPANY_GENERAL,
  externalCompanyGeneralFields.COMPANY_COMPANY_TYPE,
);

/**
 * Selector to determine if PARTNER_COMPANY_CONTACT is valid
 * @type {StandardSelector}
 * @returns {boolean} isInvalid
 */
export const partnerCompanyContactFormIsValidSelector = createFormIsValidSelector(
  forms.formNamesExternal.PARTNER_COMPANY_CONTACT,
);

/**
 * Selector to determine if PARTNER_COMPANY_GENERAL is valid
 * @type {StandardSelector}
 * @returns {boolean} isInvalid
 */
export const partnerCompanyGeneralFormIsValidSelector = createFormIsValidSelector(
  forms.formNamesExternal.PARTNER_COMPANY_GENERAL,
);

/**
 * Selector to determine if ADD_MANUAL_BANK is valid
 * @type {StandardSelector}
 * @returns {boolean} isInvalid
 */
export const manuallyAddBankAccountFormIsValidSelector = createFormIsValidSelector(
  forms.formNamesFunding.ADD_MANUAL_BANK,
);

/**
 * Selector to retrieve ADD_MANUAL_BANK.CURRENCY_CODE
 */
export const selectedCurrencyCodeExternalFieldSelector = createFieldValueSelector(
  forms.formNamesFunding.ADD_MANUAL_BANK,
  commonFormFields.CURRENCY_CODE,
);

/**
 * Selector to retrieve ADD_PARTNER_INTERNATIONAL_FUNDING_ACCOUNT.CURRENCY_CODE
 */
export const selectedCurrencyCodeDashboardFieldSelector = createFieldValueSelector(
  forms.formNamesFunding.ADD_PARTNER_INTERNATIONAL_FUNDING_ACCOUNT,
  commonFormFields.CURRENCY_CODE,
);

// Integrations
export const matchClearingAccountsValueSelector = (field) =>
  createFieldValueSelector(forms.formNamesMatchClearingAccounts.MODAL, field);

export const matchClearingAccountsFormMoneyInSelector = matchClearingAccountsValueSelector('moneyIn');

export const matchClearingAccountsFormMoneyOutSelector = matchClearingAccountsValueSelector('moneyOut');

export const matchClearingAccountsListFormActiveFilterSelector = createFieldValueSelector(
  forms.formNamesMatchClearingAccounts.LIST,
  'activeFilter',
);

export const matchPartnershipUIActiveFilterSelector = createFieldValueSelector(
  forms.formNamesMatchPartnership.UI,
  'activeFilter',
);

export const matchPartnershipUIActiveSideSelector = createFieldValueSelector(
  forms.formNamesMatchPartnership.UI,
  'activeSide',
);

export const matchPartnershipUIPaginationOffsetSelector = createFieldValueSelector(
  forms.formNamesMatchPartnership.UI,
  'pagination.offset',
);

export const matchPartnershipUISearchSelector = createFieldValueSelector(forms.formNamesMatchPartnership.UI, 'search');

export const matchPartnershipLedgerToPlatformFormPartnershipSelector = createFieldValueSelector(
  forms.formNamesMatchPartnership.LEDGER_TO_PLATFORM,
  'partnership',
);

export const matchPartnershipPlatformToLedgerFormMatchedCustomerSelector = createFieldValueSelector(
  forms.formNamesMatchPartnership.PLATFORM_TO_LEDGER,
  'platform.partnership.customer',
);

/**
 * Selects matched customer id (if it exists)
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {StringMaybe}
 */
export const matchPartnershipPlatformToLedgerFormMatchedCustomerIdSelector = createFieldValueSelector(
  forms.formNamesMatchPartnership.PLATFORM_TO_LEDGER,
  'platform.partnership.customer.data.id',
);

export const matchPartnershipPlatformToLedgerFormMatchedCustomerOrVendorSelector = (field) =>
  createFieldValueSelector(forms.formNamesMatchPartnership.PLATFORM_TO_LEDGER, `platform.partnership.${field}`);

export const matchPartnershipPlatformToLedgerFormMatchedVendorSelector = createFieldValueSelector(
  forms.formNamesMatchPartnership.PLATFORM_TO_LEDGER,
  'platform.partnership.vendor',
);

/**
 * Selects matched vendor id (if it exists)
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {StringMaybe}
 */
export const matchPartnershipPlatformToLedgerFormMatchedVendorIdSelector = createFieldValueSelector(
  forms.formNamesMatchPartnership.PLATFORM_TO_LEDGER,
  'platform.partnership.vendor.data.id',
);

/**
 * Selects the EDIT_GENERAL_INFO form state and returns true if the form
 * is valid
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const editCompanyGeneralInfoFormIsValidSelector = createFormIsValidSelector(
  forms.formNamesPartnership.EDIT_GENERAL_INFO,
);

/**
 * Selects the EDIT_GENERAL_INFO form state and returns true if the form
 * is currently submitting
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const editCompanyGeneralInfoFormIsSubmittingSelector = createFormIsSubmittingSelector(
  forms.formNamesPartnership.EDIT_GENERAL_INFO,
);

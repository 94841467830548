import {} from 'interfaces/attachment';
import {} from 'interfaces/item';
export const getItemAttachmentsFromMap = (item, attachmentsById) => (item?.attachments || []).map((id) => attachmentsById[id]).filter(Boolean);
export const getNextBillIndex = (currentIndex, removalIndex, bills) => {
    const removingBillAfter = removalIndex > currentIndex;
    if (removingBillAfter) {
        return currentIndex;
    }
    const removingBillBefore = removalIndex < currentIndex;
    if (removingBillBefore) {
        return currentIndex - 1;
    }
    const lastBillIndex = bills.length - 1;
    const hasBillsAfterCurrent = lastBillIndex > currentIndex;
    if (hasBillsAfterCurrent) {
        return currentIndex;
    }
    return currentIndex - 1;
};

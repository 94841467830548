import { PLATFORM_DISPLAY_SHORT_NAME } from '../../../constants/platform';

export const getSiteTitle = (title, subtitle = null) => {
  let siteTitle = title;

  if (subtitle) {
    siteTitle += ` - ${subtitle}`;
  }

  if (!siteTitle.endsWith(PLATFORM_DISPLAY_SHORT_NAME)) {
    siteTitle += ` | ${PLATFORM_DISPLAY_SHORT_NAME}`;
  }

  return siteTitle;
};

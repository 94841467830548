import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import React from 'react';
import { legacyIconToRoutableIconMap } from './IconMapping';
const Icon = ({ className, color, icon, isCustomIcon, marginLeft, marginRight, marginTop, size, ...rest }) => {
    if (!isCustomIcon) {
        const MappedIcon = legacyIconToRoutableIconMap[icon];
        if (!MappedIcon) {
            Sentry.captureMessage(`Icon ${icon} is not supported`, { level: 'warning', extra: { icon } });
            return null;
        }
        return (React.createElement(MappedIcon, { className: clsx('inline-block', className), "data-icon": icon, "data-testid": `icon-${icon}`, fill: color ?? 'currentColor', size: size, style: { marginLeft, marginRight, marginTop }, ...rest }));
    }
    return React.createElement("span", { className: clsx(`icon ${icon}`, className) });
};
export default Icon;

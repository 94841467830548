import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';
import { lookupMembershipInviteEmailRoutine } from 'actions/routines/inviteTeamMember';
import { disableMembershipRoutine, updateMembershipRoutine } from 'actions/routines/membership';

import { allKeys } from 'helpers/utility';

import {
  FETCH_MEMBERSHIPS_SUCCESS,
  FETCH_MEMBERSHIP_SUCCESS,
  UPDATE_MEMBERSHIP_SUCCESS,
  UPDATE_ONBOARDING_MEMBERSHIP_SUCCESS,
} from 'types/memberships';

const allReducer = (state = [], action) => {
  switch (action.type) {
    case disableMembershipRoutine.SUCCESS:
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
    case FETCH_MEMBERSHIP_SUCCESS:
    case FETCH_MEMBERSHIPS_SUCCESS:
    case lookupMembershipInviteEmailRoutine.FAILURE:
    case UPDATE_MEMBERSHIP_SUCCESS:
    case updateMembershipRoutine.SUCCESS:
    case UPDATE_ONBOARDING_MEMBERSHIP_SUCCESS:
      return Array.from(new Set([...state, ...allKeys(action.payload.membership)]));

    default:
      return state;
  }
};

export default allReducer;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParam } from 'react-use';
import { z } from 'zod';
import { useSubmitW9Mutation } from 'ducks/routableApi';
import { updateCompany } from 'actions/companies';
import { externalOnboardingIncrementStep } from 'actions/externalOnboarding';
import { setW9FormIsSubmittingState, setW9FormIsValidState } from 'actions/partnershipRequests';
import { showErrorUi } from 'actions/ui';
import { taxCertificationType, taxFormId, TaxFormRenderer, taxFormType } from 'complexComponents';
import { ErrorIndicatorMessages } from 'constants/ui';
import { isCompanyPlatformTypeInInviteStage } from 'helpers/currentCompany';
import { companyFromQuerySelector } from 'queries/companyCompoundSelectors';
import { partnershipRequestMembershipIdSelector } from 'selectors/partnershipRequestSelectors';
import { payloadToCamelCase } from 'services/api/formatHelpers';
import { getDerivedApiFieldErrors } from '../taxForms.helpers';
export const SubstituteW9Form = () => {
    const partnershipId = useSearchParam('partnership_id');
    const [submitW9] = useSubmitW9Mutation();
    const membershipId = useSelector(partnershipRequestMembershipIdSelector);
    const company = useSelector(companyFromQuerySelector);
    const dispatch = useDispatch();
    const isPlatformTypeInInviteStage = isCompanyPlatformTypeInInviteStage(company?.platformType);
    const handleFormSubmit = async (values) => {
        try {
            const parsedMembershipId = z.string().uuid().parse(membershipId);
            const parsedPartnershipId = z.string().uuid().parse(partnershipId);
            const data = await submitW9({
                membershipId: parsedMembershipId,
                partnershipId: parsedPartnershipId,
                values,
            }).unwrap();
            dispatch(updateCompany(data));
            dispatch(externalOnboardingIncrementStep());
            dispatch(setW9FormIsSubmittingState(false));
        }
        catch (err) {
            const errors = getDerivedApiFieldErrors(err);
            if (errors?.general) {
                dispatch(showErrorUi(errors.general.detail));
            }
            else {
                dispatch(showErrorUi(ErrorIndicatorMessages.AN_ERROR_OCCURRED));
            }
            if (errors?.fields) {
                return payloadToCamelCase(errors.fields);
            }
        }
        return null;
    };
    const handleFormValidStateChange = (isValid) => {
        dispatch(setW9FormIsValidState(isValid));
    };
    const handleFormSubmitStateChange = (isSubmitting) => {
        dispatch(setW9FormIsSubmittingState(isSubmitting));
    };
    return (React.createElement(TaxFormRenderer, { defaultValues: {
            certificationType: taxCertificationType.Enum.signedElectronically,
            companyType: company?.companyType,
        }, formId: taxFormId.Enum.ext_substituteW9Form, formType: taxFormType.Enum.W9, isSignatureAllowed: true, onFormSubmitStateChange: handleFormSubmitStateChange, onFormValidStateChange: handleFormValidStateChange, onSubmit: handleFormSubmit, showCompanyTypeField: isPlatformTypeInInviteStage }));
};

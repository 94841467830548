import fileDownload from 'js-file-download';
import _includes from 'lodash/includes';
import _some from 'lodash/some';

import { getISOFilenameFormatString } from './date';

export const getFileDownloadRequestOptions = (accept) => ({
  accept,
  transformers: [],
  responseType: 'blob',
});

/**
 * Extracts the filename from the content-disposition string in the response.headers
 * @param {HttpResponse} response
 * @returns {string}
 */
export const getFilenameFromResponseContentDispositionHeader = (response) => {
  const contentDisposition = response?.headers?.['content-disposition'];
  if (!contentDisposition) {
    return null;
  }

  // extracting the filename from string: https://stackoverflow.com/a/40940790
  const GET_FILENAME_FROM_CONTENT_DISPOSITION_REGEX = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const filename = contentDisposition.match(GET_FILENAME_FROM_CONTENT_DISPOSITION_REGEX)?.[1];

  return filename ? filename.replaceAll(/['"]/g, '') : null;
};

/**
 * Returns filename for downloaded zip file.
 * Will return filename from response.header.content-disposition
 * Otherwise will fallback to passed prefix value with the current timestamp
 * @param {HttpResponse|unknown} response
 * @param {string} prefix
 * @returns {string}
 */
export const getExportZipFilename = (response, prefix) =>
  getFilenameFromResponseContentDispositionHeader(response) || `${prefix}-${getISOFilenameFormatString()}.zip`;

export const downloadFile = (...args) => fileDownload(...args);

export const downloadFileFromURL = async (fileURL, fileName) => {
  const fetchResponse = await fetch(fileURL, {
    cache: 'no-cache',
    method: 'GET',
    mode: 'cors',
  });

  return downloadFile(await fetchResponse.blob(), fileName);
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new window.FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(Error(error));
    };

    reader.readAsDataURL(file);
  });

/**
 * Checks a file for disallowed characters
 * @param {Object} file
 * @param {RegExp} disallowedSetRegex
 * @return {boolean}
 */
export const hasDisallowedCharactersInFilename = (file, disallowedSetRegex) => {
  if (!file || !file.filename) {
    return false;
  }

  const { filename } = file;

  // . is not allowed  - should not compare the extension
  const excludeExtension = disallowedSetRegex.test('.');

  let hasExtension = false;
  const lastPeriodLocation = filename.lastIndexOf('.');
  if (lastPeriodLocation > 0) {
    hasExtension = true;
  }

  let compareFilename = filename;

  // If file has extension and we want to compare without it
  if (excludeExtension && hasExtension) {
    compareFilename = filename.substr(0, lastPeriodLocation);
  }

  const fileNameWithoutExtensionAlphanumeric = compareFilename.replace(disallowedSetRegex, '-');

  if (compareFilename !== fileNameWithoutExtensionAlphanumeric) {
    return true;
  }

  return false;
};

export const fileIncludes = (obj, find) => {
  if (!obj) {
    return false;
  }
  return _some(find, (el) => _includes(obj, el));
};

// Updated to check filename as well as fileType because when this section
// is first accessed it doesn't always contain the fileType unless it is already in the state.
export const isFileTypeEmail = (file) => {
  if (file && file?.fileType) {
    return file.fileType.startsWith('message/');
  }
  if (file && file?.filename) {
    return fileIncludes(file.filename, ['email.txt']);
  }
  return false;
};

export const isFileTypeImage = (file) => {
  if (file && file?.fileType) {
    return file.fileType.startsWith('image/');
  }
  if (file && file?.filename) {
    return fileIncludes(file.filename, ['.png', '.jpg', '.jpeg', '.svg', '.gif']);
  }
  return false;
};

export const isFileTypePDF = (file) => {
  if (file && file?.fileType) {
    return file.fileType.startsWith('application/pdf');
  }
  if (file && file?.filename) {
    return fileIncludes(file.filename, ['.pdf']);
  }
  return false;
};

export const mapFiles = (files) => {
  const fileToPlainObject = (file) => ({
    id: file.lastModified,
    name: file.name,
    type: file.type,
    size: file.size,
    lastModifiedDate: file.lastModifiedDate,
    dataURL: window.URL.createObjectURL(file),
  });

  const result = [];

  Object.values(files).forEach((file) => {
    if (file) {
      result.push(fileToPlainObject(file));
    }
  });

  return result;
};

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { ledgerIntegrationSelector } from 'selectors/integrationsSelectors';
import { itemSelector } from 'selectors/itemsSelectors';

import LedgerStatusRelation from './LedgerStatusRelation';

const mapStateToProps = (state, ownProps) => ({
  item: ownProps?.item ?? itemSelector(state, ownProps.match.params.id),
  ledger: ledgerIntegrationSelector(state),
});

const enhance = compose(withRouter, connect(mapStateToProps));

export default enhance(LedgerStatusRelation);

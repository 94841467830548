import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

import { getEnvVariable } from 'helpers/env';
import { hasLength } from 'helpers/utility';

/**
 * Curried.
 *
 * Set PlaidLink status to loading if Plaid is not already loaded.
 * @function
 * @param {boolean} plaidLinkLoaded
 * @param {function} onUpdateTokenAccountModal
 * @returns {Function}
 */
export const updatePlaidLinkStatusToLoading = (plaidLinkLoaded, onUpdateTokenAccountModal) => () => {
  // Set PlaidLink status to loading if not already loaded
  if (!plaidLinkLoaded) {
    onUpdateTokenAccountModal({ plaidLinkLoading: true });
  }
};

/**
 * Gets all current plaid link iframes that exist in the DOM.
 * @returns {NodeList}
 */
export const getAllCurrentPlaidFrames = () => document.querySelectorAll('*[id^="plaid-link-iframe"]');

/**
 * When the PlaidLink component unmounts call this function to clear plaidLinks in the DOM.
 * @function
 * @returns {*}
 */
export const removeAllPlaidFramesFromDOM = () => {
  // Remove Plaid Iframes created in the dom.
  const PlaidLinks = getAllCurrentPlaidFrames();

  PlaidLinks.forEach((plaidLink) => {
    plaidLink.remove();
  });
};

/**
 * Curried.
 *
 * When the Plaid script loads, configure Plaid and update the modal to allow opening.
 * @function
 * @param {String} plaidLinkToken
 * @param {ComponentProps} props - See PlaidLink propTypes
 * @param {function} setLinkHandler
 * @returns {Function}
 */
export const createLoadPlaidLinkHandler =
  ({ plaidLinkToken, props, setLinkHandler }) =>
  () => {
    const { longtail, onCloseTokenAccountModal, onSuccess, onUpdateTokenAccountModal, selectAccount, webhook } = props;

    const currentPlaidFrames = getAllCurrentPlaidFrames();

    if (hasLength(currentPlaidFrames)) {
      return undefined;
    }

    setLinkHandler(
      window.Plaid.create({
        apiVersion: 'v2',
        clientName: PLATFORM_DISPLAY_SHORT_NAME,
        env: getEnvVariable('REACT_APP_PLAID_ENV') || 'sandbox',
        longtail,
        onExit: onCloseTokenAccountModal,
        onSuccess,
        selectAccount,
        token: plaidLinkToken,
        webhook,
      }),
    );

    // Update our modal to denote that PlaidLink was loaded
    return onUpdateTokenAccountModal({
      plaidLinkLoaded: true,
      plaidLinkLoading: false,
    });
  };

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { fetchPartnershipItemsRequest } from 'actions/partnership';
import {} from 'interfaces/global';
import { partnershipIdFromCurrentPartnershipRequestSelector } from 'queries/partnershipCompoundSelectors';
import { partnershipItemsExceptCurrentOneSelector, werePartnershipItemsFetchedSelector, } from 'selectors/partnershipItemsSelectors';
import { itemKindFromExternalV2LocationSelector } from 'selectors/routerSelectors';
import { createMergeProps } from 'store/redux';
import ExternalItemsListWrapper from './ExternalItemsListWrapper';
import {} from './ExternalItemsListWrapper.types';
const mapStateToProps = createStructuredSelector({
    itemsKind: itemKindFromExternalV2LocationSelector,
    partnershipId: partnershipIdFromCurrentPartnershipRequestSelector,
    partnershipItems: partnershipItemsExceptCurrentOneSelector,
    wasFetched: werePartnershipItemsFetchedSelector,
});
const mapDispatchToProps = {
    onFetchPartnershipItems: fetchPartnershipItemsRequest,
};
const mergeProps = createMergeProps((stateProps, dispatchProps) => ({
    fetchPartnershipItems: () => dispatchProps.onFetchPartnershipItems({
        partnershipId: stateProps.partnershipId,
        kind: stateProps.itemsKind,
    }),
}));
export { mapDispatchToProps, mapStateToProps, mergeProps };
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ExternalItemsListWrapper);

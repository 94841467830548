import { getCurrentCompanyId } from 'helpers/localStorage';
import ThunkFetch from 'helpers/thunkFetch';
import { getShowSuccessUI } from 'helpers/ui';

import { submitCompanyVerificationEndpoint } from 'services/api/companyEndpoints';

import {
  SUBMIT_COMPANY_AUTH_REP_FAILURE,
  SUBMIT_COMPANY_AUTH_REP_REQUEST,
  SUBMIT_COMPANY_AUTH_REP_SUCCESS,
} from 'types/company';

const showSuccess = getShowSuccessUI('Your info has been submitted for verification!');

const submitCompanyReps = (metaPayload) => {
  const companyId = getCurrentCompanyId();

  const payload = {
    data: {
      id: companyId,
      type: 'Company',
    },
    meta: metaPayload,
  };

  const t = new ThunkFetch({
    actions: {
      error: SUBMIT_COMPANY_AUTH_REP_FAILURE,
      request: SUBMIT_COMPANY_AUTH_REP_REQUEST,
      success: SUBMIT_COMPANY_AUTH_REP_SUCCESS,
    },
    endpoint: submitCompanyVerificationEndpoint(companyId),
    method: 'POST',
    payload,
    requireAuth: true,
    successCallback: (parsedResponse) => {
      showSuccess();
      return parsedResponse;
    },
  });

  return t.makeRequest();
};

export default submitCompanyReps;

import * as types from 'types/ledger';

export const ledgerDisconnected = () => ({ type: types.LEDGER_DISCONNECTED });

export const ledgerSyncStart = (id) => ({
  payload: { id },
  type: types.LEDGER_SYNC_START,
});

export const closeForceManualLedgerSyncModal = () => ({
  type: types.CLOSE_FORCE_MANUAL_LEDGER_SYNC_MODAL,
});

export const openForceManualLedgerSyncModal = (onResyncSuccess) => ({
  type: types.OPEN_FORCE_MANUAL_LEDGER_SYNC_MODAL,
  payload: {
    onResyncSuccess,
  },
});

import React, {} from 'react';
import { useLazyLookupFundingBankAccountQuery } from 'ducks/routableApi/fundingEndpoints';
import { Text } from 'components';
import { typography } from 'constants/styles';
import { useSelectorWithProps } from 'hooks';
import { internationalBankValidationData } from 'selectors/fundingSelectors';
import { shouldLookupBankAccount } from './helpers';
export const useInternationalBankValidator = (fieldName, isInternational) => {
    const [triggerLookupBankAccount, { isFetching: isValidating }] = useLazyLookupFundingBankAccountQuery();
    const onBlur = (event) => {
        const fieldValue = event.target.value;
        if (!!fieldValue && shouldLookupBankAccount(fieldName, isInternational)) {
            triggerLookupBankAccount({ fieldName, fieldValue });
        }
    };
    const bankValidationData = useSelectorWithProps(internationalBankValidationData, fieldName);
    const fieldValidator = React.useMemo(() => () => !isValidating && !!bankValidationData.errors ? bankValidationData.errors : undefined, [bankValidationData.errors, isValidating]);
    const bankNameNode = bankValidationData.data?.bankName && (React.createElement("div", { className: "margin-top--xm" },
        React.createElement(Text.Regular, { size: typography.TextSize.LEVEL_100 }, bankValidationData.data?.bankName)));
    return { bankNameNode, fieldValidator, isValidating, onBlur };
};

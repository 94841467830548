import { queryContext } from '@routable/shared';
import { useQuery } from '@tanstack/react-query';
import { countriesApi } from './Countries.endpoint';
const defaultResult = { countries: [], countriesAsOptions: [] };
export const useCountries = () => {
    const result = useQuery({
        queryKey: ['countries', 'all'],
        context: queryContext,
        queryFn: () => countriesApi.getCountries(),
        select: ({ data }) => ({
            countries: data,
            countriesAsOptions: data.map(({ attributes: { fullName, countryCode } }) => ({
                id: countryCode,
                value: countryCode,
                label: fullName,
            })),
        }),
    });
    return result?.data || defaultResult;
};

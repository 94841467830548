import { useRoutableParams } from 'hooks/useRoutableParams';
import {} from './useLoginIdP.types';
export const useLoginIdp = () => {
    const { code, connection, company_id } = useRoutableParams();
    if (connection !== undefined) {
        return {
            idp: true,
            code,
            connection,
            companyId: company_id,
        };
    }
    return {
        idp: false,
        code: undefined,
        connection: undefined,
        companyId: undefined,
    };
};

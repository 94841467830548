import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Hint, HintText, HintTitle } from 'components/hint';

import { Intent } from 'constants/ui';

import { hasZeroLength } from 'helpers/utility';

import { getHintText } from './helpers';

/**
 * This hint let's other members know if another member have completed any steps already.
 * @param {ComponentProps} props
 * @param {string} props.completedDate
 * @param {PartnershipRequestUserFriendlyNames|PartnershipRequestNames} props.partnershipRequestUserFriendlyOrInternalName
 * @param {string[]} props.users
 * @returns {StatelessComponent}
 */
const RecentlyCompletedHint = ({ completedDate, partnershipRequestUserFriendlyOrInternalName, users }) => {
  if (!completedDate || hasZeroLength(users)) {
    return null;
  }

  const usersWhoCompletedSteps = getHintText({
    completedDate,
    partnershipRequestUserFriendlyOrInternalName,
    users,
  });

  return (
    <Hint intent={Intent.INFO}>
      <HintTitle>This was recently completed</HintTitle>
      <HintText>
        {usersWhoCompletedSteps.map(({ emphasize, key, text }) => (
          <span className={classNames({ 'font-weight--semibold': emphasize })} key={key}>
            {text}
          </span>
        ))}
      </HintText>
    </Hint>
  );
};

RecentlyCompletedHint.propTypes = {
  completedDate: PropTypes.string,
  partnershipRequestUserFriendlyOrInternalName: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.string),
};

RecentlyCompletedHint.defaultProps = {
  completedDate: undefined,
  partnershipRequestUserFriendlyOrInternalName: undefined,
  users: undefined,
};

export default RecentlyCompletedHint;

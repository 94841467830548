import * as FullStory from '@fullstory/browser';
import { AnalyticsBrowser } from '@segment/analytics-next';
import {} from './constants';
import {} from './eventTracking.types';
const analytics = AnalyticsBrowser.load({
    writeKey: process.env.ROUTABLE_FE_SEGMENT_WRITE_KEY,
});
const { REACT_APP_FULLSTORY_ORG_ID } = process.env;
export function identifyUserByMembershipId(membershipId, identityData) {
    analytics.identify(membershipId, identityData);
    if (REACT_APP_FULLSTORY_ORG_ID) {
        FullStory.identify(membershipId, identityData);
    }
}
let latestPageNameSent = null;
export function trackPage(name, additionalName) {
    const pageName = additionalName ? `${name} - ${additionalName}` : name;
    if (latestPageNameSent?.name === pageName && new Date().getTime() - latestPageNameSent?.calledAt < 750) {
        return;
    }
    analytics.track(`Page - ${pageName}`);
    latestPageNameSent = {
        name: pageName,
        calledAt: new Date().getTime(),
    };
}
export function trackEvent(name, properties) {
    analytics.track(name, properties);
}

import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Component used in InputFieldWrapper for showing the remaining number of characters (to input) available.
 * @param {String|Boolean|Object} errors
 * @param {Number} maximumCharacterCount
 * @param {Boolean} isCharacterCountShown
 * @param {String|Number} value
 * @return {StatelessComponent|null}
 */
const CharacterCount = ({ errors, maximumCharacterCount, isCharacterCountShown, value }) => {
  if (!maximumCharacterCount || !isCharacterCountShown || errors) {
    return null;
  }

  const difference = maximumCharacterCount - String(value).length;

  return (
    <div className="character-count margin-top--sm">
      <span className={clsx({ 'highlight-difference': difference <= 10 })}>{difference}</span>
      {' characters remaining.'}
    </div>
  );
};

CharacterCount.propTypes = {
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.bool,
    PropTypes.shape(),
  ]),
  maximumCharacterCount: PropTypes.number,
  isCharacterCountShown: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
CharacterCount.defaultProps = {
  errors: undefined,
  maximumCharacterCount: undefined,
  isCharacterCountShown: true,
  value: '',
};

export default CharacterCount;

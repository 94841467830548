import { ComboBox } from '@routable/components';
import PropTypes from 'prop-types';
import React from 'react';

import { reduxFormInputPropType, reduxFormMetaPropType } from 'components/commonProps';
import { FormFieldErrors } from 'components/error';
import { AsyncSelect, SelectFieldV2 } from 'components/selectV2';

import { getFieldErrors } from 'helpers/errors';

import { useDebouncedField } from 'hooks';

/**
 * A wrapper around our native select field to debounce value updates.
 * @param {ComponentProps} props
 * @param {number} [props.debounceDelay]
 * @param {String} [props.formName]
 * @param {Object} [props.formState]
 * @param {ReduxFormInput} [props.input]
 * @param {boolean} [props.isAsync]
 * @param {boolean} [props.isDebounced]
 * @param {string} [props.name]
 * @param {Function} [props.onInputChange]
 * @return {FunctionComponent}
 */
const DebouncedSelectField = (props) => {
  const { debounceDelay, errors, formName, input, isAsync, isDebounced, meta, ...rest } = props;

  const { onChange, value } = useDebouncedField({
    debounceDelay,
    formName,
    isDebounced,
    input,
  });

  const isInLineItems = input.name.startsWith('line_items');

  const fieldErrors = errors ? getFieldErrors(errors, input.name) : (meta.touched || meta.submitFailed) && meta.error;

  let SelectComponent = SelectFieldV2;

  if (isInLineItems) {
    SelectComponent = ComboBox;
  } else if (isAsync) {
    SelectComponent = AsyncSelect;
  }

  return (
    <>
      <SelectComponent
        {...rest}
        input={{
          ...input,
          onChange,
          value,
        }}
      />

      {Boolean(fieldErrors) && isInLineItems && <FormFieldErrors errors={fieldErrors} fieldName={input.name} />}
    </>
  );
};

DebouncedSelectField.propTypes = {
  debounceDelay: PropTypes.number,
  errors: PropTypes.shape(),
  input: reduxFormInputPropType.isRequired,
  isAsync: PropTypes.bool,
  isDebounced: PropTypes.bool,
  meta: reduxFormMetaPropType,
};

DebouncedSelectField.defaultProps = {
  debounceDelay: undefined,
  errors: undefined,
  isAsync: undefined,
  isDebounced: undefined,
  meta: {},
};

export default DebouncedSelectField;

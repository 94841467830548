import * as types from 'types/user';

export const fetchCurrentUserRequest = () => ({
  type: types.GET_CURRENT_USER_REQUEST,
});

export const fetchCurrentUserSuccess = (payload) => ({
  payload,
  type: types.GET_CURRENT_USER_SUCCESS,
});

export const fetchCurrentUserFailure = (errors) => ({
  payload: { errors },
  type: types.GET_CURRENT_USER_FAILURE,
});

import { apiCall } from '@routable/framework';
import { conditionApiListModel } from '../../models';
export const approvalsConditionsUrl = 'approvals/conditions/';
export const conditionApi = {
    readAll: async () => apiCall({
        url: 'approvals/conditions/',
        method: 'get',
        responseModel: conditionApiListModel,
        camelCaseResponse: true,
    }),
};

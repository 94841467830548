import clsx from 'clsx';
import React from 'react';
import { DateCell, ShowAmountCell, TableCellText } from 'complexComponents';
import { ButtonV2 } from 'components';
import { ButtonSize } from 'constants/button';
import { ItemKinds } from 'constants/item';
import { DETAILS_COLUMN_ACCESSOR_ID } from 'constants/tables';
import { Intent } from 'constants/ui';
import { ItemAmountKey } from 'enums/items';
import { getItemPaymentTerms } from 'helpers/items';
import { getOriginal } from 'helpers/tables';
import { isValueEmpty } from 'helpers/utility';
export const DecoratedCell = ({ value }) => (React.createElement(TableCellText, { className: clsx({ 'font-color--grey--dark': !value }) }, value || 'Not provided'));
export const ShowAmountOrDefaultCell = ({ row }) => !isValueEmpty(row.original.amount) ? (React.createElement(ShowAmountCell, { dataKey: ItemAmountKey.TOTAL, rowData: { ...getOriginal({ row }) } })) : (React.createElement(DecoratedCell, { value: "--" }));
const PayablesTableTableConfig = [
    {
        accessor: 'reference',
        Header: 'Invoice #',
        Cell: ({ row }) => React.createElement(DecoratedCell, { value: row.original.reference }),
        width: 1,
    },
    {
        accessor: 'partnerReference',
        Header: 'Reference',
        Cell: ({ row }) => React.createElement(DecoratedCell, { value: row.original.partnerReference }),
        width: 3,
    },
    {
        accessor: 'paymentTerms',
        Header: 'Payment terms',
        Cell: ({ value }) => React.createElement(TableCellText, null, getItemPaymentTerms(value)),
        width: 1,
    },
    {
        accessor: 'amount',
        Header: 'Amount',
        Cell: ShowAmountOrDefaultCell,
        width: 1,
    },
];
const ReceivablesTableConfig = [
    {
        accessor: 'reference',
        Header: 'Invoice #',
        Cell: ({ row }) => React.createElement(DecoratedCell, { value: row.original.reference }),
        width: 1,
    },
    {
        accessor: 'partnerReference',
        Header: 'Reference',
        Cell: ({ row }) => React.createElement(DecoratedCell, { value: row.original.partnerReference }),
        width: 3,
    },
    {
        accessor: 'dateDue',
        Header: 'Due date',
        Cell: DateCell,
        width: 1,
    },
    {
        accessor: 'amount',
        Header: 'Amount',
        Cell: ShowAmountOrDefaultCell,
        width: 1,
    },
];
export const InvoicesTableConfig = ({ itemKind, showDetailsColumn = false, onClick, }) => {
    const config = itemKind === ItemKinds.PAYABLE ? [...PayablesTableTableConfig] : [...ReceivablesTableConfig];
    if (showDetailsColumn) {
        config.push({
            accessor: DETAILS_COLUMN_ACCESSOR_ID,
            Header: 'Details',
            Cell: (props) => (React.createElement(TableCellText, null,
                React.createElement(ButtonV2, { intent: Intent.NEUTRAL, onClick: () => onClick(getOriginal(props)), size: ButtonSize.SMALL }, "View"))),
            width: 2,
        });
    }
    return config;
};
export const LineItemsItemsTableConfig = [
    {
        accessor: 'description',
        Header: 'Description',
        Cell: ({ row }) => React.createElement(DecoratedCell, { value: row.original.description }),
        width: 5,
    },
    {
        accessor: 'amount',
        Header: 'Amount',
        Cell: ShowAmountOrDefaultCell,
        width: 1,
    },
];
export const LineItemsExpensesTableConfig = LineItemsItemsTableConfig;

import {} from '@auth0/auth0-react';
import { localStorageGet } from 'helpers/localStorage';
export const initTestableAuth0Client = (auth0Contenxt) => {
    const auth0 = auth0Contenxt;
    if (localStorageGet('auth0Token')) {
        auth0.isAuthenticated = true;
    }
    const realGetAccessTokenSilently = auth0.getAccessTokenSilently;
    auth0.getAccessTokenSilently = (options) => {
        const devAuth0Token = localStorageGet('auth0Token');
        if (devAuth0Token) {
            auth0.isAuthenticated = true;
            return Promise.resolve(devAuth0Token);
        }
        return realGetAccessTokenSilently(options);
    };
};

import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { sharedFormSubmitRoutinePayloadCreators } from 'helpers/routine';
import * as types from 'types/webhooks';
export const webhooksFetchRoutine = createRoutine(types.FETCH_WEBHOOKS);
export const webhookSettingsDeleteRoutine = createRoutine(types.DELETE_WEBHOOK_SETTINGS);
export const webhookSettingsFetchRoutine = createRoutine(types.FETCH_WEBHOOKS_SETTINGS);
export const webhookSettingsTestRoutine = createRoutine(types.TEST_WEBHOOKS_SETTINGS);
export const webhookSettingsSubmitRoutine = createRoutine(types.SUBMIT_WEBHOOKS_SETTINGS, sharedFormSubmitRoutinePayloadCreators);
export const webhookSettingsSubmitHandler = promisifyRoutine(webhookSettingsSubmitRoutine);
export const webhookSettingsUpdateRoutine = createRoutine(types.UPDATE_WEBHOOK_SETTINGS);

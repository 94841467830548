import { formNamesFunding } from 'constants/forms';

import { getShowSuccessUI } from 'helpers/ui';

import { text } from 'modules/connectBank/verifyFundingAccount/constants';

const showVerifySubmitSuccessUI = getShowSuccessUI(text.microdepositSuccessMessage, {
  id: formNamesFunding.VERIFY_FUNDING_ACCOUNT,
});

export const handleVerifyFundingAccountSuccess = (result, dispatch, props) => {
  const { onCloseModal, onRefreshFundingAccounts } = props;

  onRefreshFundingAccounts();
  onCloseModal();
  showVerifySubmitSuccessUI();
};

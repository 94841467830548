/**
 * PartnershipRequest Types values
 * @enum {string}
 */
export const PartnershipRequestTypes = {
  ACCEPT_PARTNERSHIP: 'accept_partnership',
  UPDATE_PAYMENT_METHOD: 'update_payment_method',
  REQUEST_W8_FORM: 'request_w8_form',
  REQUEST_W9_FORM: 'request_w9_form',
};

/**
 * PartnershipRequest Types value names
 * @enum {string}
 */
export const PartnershipRequestNames = {
  [PartnershipRequestTypes.ACCEPT_PARTNERSHIP]: 'Accept Partnership',
  [PartnershipRequestTypes.UPDATE_PAYMENT_METHOD]: 'Update Payment',
};

/**
 * PartnershipRequest types client facing terms
 * @enum {string}
 */
export const PartnershipRequestUserFriendlyNames = {
  [PartnershipRequestTypes.ACCEPT_PARTNERSHIP]: 'registration',
};

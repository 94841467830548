import { createErrorReducer } from 'store/redux';

import {
  FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_FAILURE,
  FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_REQUEST,
  FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_SUCCESS,
} from 'types/integrations';

export const successCases = [
  FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_REQUEST,
  FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_SUCCESS,
];
export const failureCases = [FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_FAILURE];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

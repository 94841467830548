import _isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';

import { createFieldValueSelector } from 'selectors/createFormSelectors';

/**
 * Returns a value accessor for any redux-form fields, given the form name and names of the fields.
 * @param {ImmutableString} formName
 * @param {...ImmutableString} field
 * @return {Object}
 */
const useFieldValue = (formName, field) => {
  const valueSelector = createFieldValueSelector(formName, field);
  return useSelector(valueSelector, _isEqual);
};

export default useFieldValue;

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { Checkbox, FormControl, LinkNewPage, TINInput, WhiteSpace } from 'components';

import { formStyles } from 'constants/styles';
import { UserTypes } from 'constants/user';

import helpDocs from 'helpers/helpDocs';
import { isTinTypeSsn } from 'helpers/taxes';

import { getConsentText } from 'modules/external/ExternalCollectTaxInfo/helpers/text';

/**
 * Form to collect eDelivery consent and a re-entry of the user's TIN.
 * @param {string} companyType
 * @param {string} partnerCompanyName
 * @param {boolean} showGenericTIN
 * @param {string} tinType
 * @returns {StatelessComponent}
 * @constructor
 */
const ElectronicDeliveryConsentForm = ({ companyType, partnerCompanyName, showGenericTIN, tinType }) => (
  <React.Fragment>
    {/*
      The form receives a line-height value from .hint--content which lowers the floating label against the input.
      This inline style resets the line-height to that from the global body, which is what informs the styling of other
      inputs. The global body style is found in reset.scss.
    */}
    <FormControl style={{ lineHeight: '1' }}>
      <TINInput
        companyType={companyType}
        editAllowed={false}
        editField
        fieldClassNames={formStyles.field.xl.full}
        isDisabled={false}
        isSSN={isTinTypeSsn(tinType)}
        name="tin"
        showField
        showGenericTIN={showGenericTIN}
      />
    </FormControl>

    <FormControl>
      <Field
        component={Checkbox}
        content={
          <>
            {getConsentText(partnerCompanyName)}
            <WhiteSpace />
            <LinkNewPage className="font-color--primary" href={helpDocs.TAX_CONSENT_LEARN_MORE}>
              Learn more.
            </LinkNewPage>
          </>
        }
        name="electronicDeliveryConsent"
      />
    </FormControl>
  </React.Fragment>
);

ElectronicDeliveryConsentForm.propTypes = {
  companyType: PropTypes.oneOf(Object.values(UserTypes)).isRequired,
  partnerCompanyName: PropTypes.string.isRequired,
  showGenericTIN: PropTypes.bool,
  tinType: PropTypes.string.isRequired,
};

ElectronicDeliveryConsentForm.defaultProps = {
  showGenericTIN: undefined,
};

export default ElectronicDeliveryConsentForm;

import PropTypes from 'prop-types';
import React from 'react';

import { componentsMap } from './data';

/**
 * HOC which passes renderExternalComponent function to the wrapped
 * component
 * @param {FunctionComponent} WrappedComponent
 * @return {FunctionComponent}
 */
const withExternalComponents = (WrappedComponent) => {
  const ComponentWithExternalComponents = (props) => {
    /**
     * Render function for displaying external component
     * @see ExternalComponents
     * @param {string} componentName
     * @return {FunctionComponent}
     */
    const renderExternalComponent = (componentName) => {
      // We try to get the component from map of external components
      const Component = componentsMap[componentName];

      if (!Component) {
        // If no component is specified for given external component,
        // we return null
        return null;
      }

      // We return found component with component name as a key
      return <Component key={componentName} />;
    };

    return <WrappedComponent renderExternalComponent={renderExternalComponent} {...props} />;
  };

  ComponentWithExternalComponents.propTypes = {
    currentStep: PropTypes.shape({
      blocks: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  };

  ComponentWithExternalComponents.defaultProps = {
    currentStep: {},
  };

  return ComponentWithExternalComponents;
};

export default withExternalComponents;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { FormControl } from 'components/form';
import { SelectFieldV2 } from 'components/selectV2';

import { countriesMapping, countryListOptions } from 'constants/i18n';

import { requiredValidator } from 'helpers/fieldValidation';

/**
 * Select dropdown to choose a country.
 * @param {string} addressPath
 * @param {string} countryOptions
 * @param {string} fieldClassNames
 * @param {boolean} isDisabled
 * @returns {StatelessComponent}
 * @constructor
 */
const CountrySelect = ({ addressPath, countryOptions, fieldClassNames, isDisabled }) => (
  <FormControl>
    <Field
      className={classNames({
        [fieldClassNames]: !!fieldClassNames,
      })}
      component={SelectFieldV2}
      isDisabled={isDisabled}
      label="Country"
      name={`${addressPath}country`}
      options={countriesMapping[countryOptions]}
      type="text"
      validate={requiredValidator}
    />
  </FormControl>
);

CountrySelect.propTypes = {
  addressPath: PropTypes.string,
  countryOptions: PropTypes.oneOf(Object.values(countryListOptions)).isRequired,
  fieldClassNames: PropTypes.string,
  isDisabled: PropTypes.bool,
};

CountrySelect.defaultProps = {
  addressPath: '',
  fieldClassNames: undefined,
  isDisabled: undefined,
};

export default CountrySelect;

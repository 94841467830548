import { PLATFORM_DISPLAY_SHORT_NAME, PLATFORM_EMAILS } from 'constants/platform';

export const electronicDeliveryConsentTerms = [
  `By using the checkbox above and submitting this information you are agreeing and consenting to electronic delivery of 
tax forms, including IRS Form-1099.`,

  `If you desire to receive a paper copy in addition, you should get in touch with the payer using the contact 
information on the form, and request that a paper copy be mailed to you. If you do not consent or if you withdraw your 
consent prior to the electronic delivery of any form, you will thereafter receive your tax forms on paper, by mail.`,

  `You may withdraw your consent by contacting Routable via email at ${PLATFORM_EMAILS.SUPPORT} Request of a paper copy 
will not be treated as a withdrawal of your consent to electronic delivery. Routable will confirm any withdrawal of 
consent. Otherwise, this consent will remain in effect and you will continue to receive statements for each and every 
calendar year for which you receive reportable payments or tax forms via Routable.`,

  `If you do not receive reportable payments or tax forms via ${PLATFORM_DISPLAY_SHORT_NAME} for a calendar year, then 
${PLATFORM_DISPLAY_SHORT_NAME} will not be required to maintain tax forms for that year or any prior year. However, your 
tax forms for any particular calendar year will not be removed earlier than October 15 of the year following.`,

  `You may update your personal tax information at any time by contacting your payer.`,

  `You will need a computer or mobile device with current web browsing software and internet access to view your tax 
forms, and a printer to print the PDFs.`,
];

export const externalPromptHeader = 'Confirm your end-of-year tax information for your Form 1099';

export const frequentlyAskedQuestions = [
  {
    question: 'What is Form 1099?',
    answer: `A 1099 is an Internal Revenue Service (IRS) form that is used to report income received through sources 
    other than employment.`,
  },
  {
    question: 'Why am I receiving a Form 1099?',
    answer: `We are required to file a Form 1099 for anyone paid over $600. If you don't believe you should be receiving 
    a Form 1099, or would like more information, please contact support as soon as possible.`,
  },
];

export const headerParagraph = `The IRS requires that we file a Form 1099 summarizing your income. This form is provided
 to you and the IRS, and is designed to assist you in reporting your end-of-year income. If you need assistance or have 
 any questions, please contact support as soon as possible.`;

export const modalBodyText = `The information you have provided will be used to file your end-of-year taxes to the IRS. 
It is important that this information is accurate. Please review it below & reenter your TIN for confirmation and 
verification.`;

export const multiplePartnershipHintText = {
  TITLE: 'You have previously confirmed your tax information.',
  TEXT: `We have record of this tax information already being confirmed. This happens when you are being paid by 
  multiple companies through the Routable network. If you need to make any changes to this information, please contact 
  us at`,
  HELP: 'Why is my information already here?',
};

export const soleProprietorEinTooltip = `Sole proprietorships may not have a Business EIN. In this case you would need 
to provide your Social Security Number for tax purposes.`;

export const submitErrorHintText = `We've noticed a mismatch in our verification process. In order to make sure the tax 
information you have filed is accurate, we want to confirm with you manually. We will reach out to you shortly to clear 
this up, but if you have any questions in the meantime, please reach out to us.`;

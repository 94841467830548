import { apiCall } from '@routable/framework';
import { itemApprovalApiModel, itemApprovalsListApiModel } from '../../models';
export const itemApprovalsUrl = 'approvals/item/';
export const itemApprovalsApi = {
    get: async (itemId) => apiCall({
        url: `${itemApprovalsUrl}${itemId}/`,
        method: 'get',
        camelCaseResponse: true,
        responseModel: itemApprovalApiModel,
    }),
    getMany: async (itemIds) => apiCall({
        url: itemApprovalsUrl,
        method: 'get',
        camelCaseResponse: true,
        responseModel: itemApprovalsListApiModel,
        queryParams: {
            item_ids: itemIds.join(','),
        },
    }),
};

import { attachIdToFlagResponseObjects } from 'data/parse/featureFlags/transform';

import { getFormattedFeatureSettingName } from 'helpers/featureSettings';
import { allValues } from 'helpers/utility';

/**
 * Given the array of feature settings, transforms it into an object where each key is formatted feature setting
 * name and values are set by the Backend.
 * @param {Object} responseData
 * @return {Object}
 */
export const settingsResponseToEnabledKeys = (responseData = {}) => {
  const responseDataFeatureSetting = responseData.featureSetting;
  const settingObjects = allValues(responseDataFeatureSetting);

  const reducedSettingObjects = settingObjects.reduce((obj, settingData) => {
    const attributes = settingData?.attributes;

    if (attributes) {
      return {
        ...obj,
        [getFormattedFeatureSettingName(attributes.featureSetting)]: attributes.value,
      };
    }

    return obj;
  }, {});

  return {
    ...responseData,
    featureSetting: reducedSettingObjects,
  };
};

export const attachIdToFeatureResponseObjects = attachIdToFlagResponseObjects;

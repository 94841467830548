import { businessType as w9FormBusinessType } from '@routable/companies-management';
import { nonemptyString } from '@routable/shared';
import dayjs from 'dayjs';
import { z } from 'zod';
import { ALPHANUMERIC, NUMERIC } from 'constants/regex';
import { UserType } from 'enums/user';
import { digitsAndLetters, digitsOnly } from 'helpers/fieldNormalizers';
export const W8FormTypes = {
    BEN: 'W8-BEN',
    BEN_E: 'W8-BEN-E',
};
export const W9FormTypes = {
    W9: 'SUBSTITUTE_W9',
};
export const taxFormType = z.enum(['W8', 'W9']);
export const taxFormId = z.enum([
    'ext_substituteW8Form',
    'dashboard_substituteW8Form',
    'ext_substituteW9Form',
    'dashboard_substituteW9Form',
]);
export const taxFormEntryType = z.enum(['external', 'rctm']);
export const taxCertificationType = z.enum(['signedElectronically', 'uploadSignedPDF']);
export const w9ExemptPayeeCode = z.enum(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13']);
export const w9ExemptFatcaCode = z.enum(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M']);
export const w9MailingAddress = z.object({
    streetAddress: nonemptyString,
    streetAddressUnit: z.string().nullish(),
    country: z.string().min(2).max(2),
    city: nonemptyString,
    state: nonemptyString,
    postalcode: nonemptyString,
});
export const w8ChapterThreeStatus = z.enum([
    'Central bank of issue',
    'Complex trust',
    'Corporation',
    'Disregarded entity',
    'Estate',
    'Foreign government controlled entity',
    'Foreign government integral part',
    'Grantor trust',
    'International organization',
    'Partnership',
    'Private foundation',
    'Simple trust',
    'Tax exempt organization',
]);
export const w8ChapterFourStatus = z.enum([
    '501(c) organization',
    'Active NFFE',
    'Passive NFFE',
    'Publicly traded NFFE or NFFE affiliated of a publicly traded company',
]);
export const w8LimitationOnBenefits = z.enum([
    'Government',
    'Tax exempt pension trust or pension fund',
    'Other tax-exempt organization',
    'Publicly traded corporation',
    'Subsidiary of a publicly traded corporation',
    'Company that meets the ownership and base erosion test',
    'Company that meets the derivative benefits test',
    'Company with an item of income that meets active trade or business test',
    'Favorable discretionary determination by the US competent authority received',
    'Other specify article and paragraph',
    'No LOB article in treaty',
]);
const file = z.object({
    documentType: z.string(),
    file: z.string(),
    filename: z.string(),
    preview: z.string(),
    uploaded: z.boolean(),
});
const dateField = z.date().transform((d) => d && dayjs(d).format('YYYY-MM-DD'));
export const w8FormBaseModel = z.object({
    beneficialOwnerName: nonemptyString,
    claimingTreatyBenefits: z.boolean(),
    certificationType: taxCertificationType,
    completedForm: file.optional().nullable(),
    completedFormFilename: z.string().optional(),
    completedFormSignedDate: dateField.nullable().optional(),
    ftin: z
        .string()
        .max(18, 'Foreign Tax ID Number must contain at most 18 characters')
        .regex(ALPHANUMERIC, 'Foreign Tax ID Number can only contain alphanumeric characters')
        .nullish(),
    mailingAddressMatchPermanentAddress: z.boolean(),
    mailingCity: z.string().optional(),
    mailingCountry: z.string().optional(),
    mailingPostalcode: z.string().optional(),
    mailingState: z.string().optional(),
    mailingStreetAddress: z.string().optional(),
    residenceCity: nonemptyString,
    residenceCountry: nonemptyString,
    residencePostalcode: nonemptyString,
    residenceState: nonemptyString,
    residenceStreetAddress: nonemptyString,
    tin: z
        .string()
        .length(9, 'U.S. Taxpayer ID Number must contain exactly 9 characters')
        .regex(NUMERIC, 'U.S. Taxpayer ID Number can only contain numeric characters')
        .nullish()
        .or(z.literal('')),
    treatyBenefitsArticleAndParagraph: z.string().optional(),
    treatyBenefitsTypeOfIncome: z.string().optional(),
    treatyBenefitsWithholdingRate: z.string().optional(),
    companyType: z.nativeEnum(UserType),
});
export const w8BenFormBaseModel = w8FormBaseModel.extend({
    companyType: z.literal(UserType.PERSONAL),
    countryOfCitizenship: nonemptyString,
    signature: z.string().optional(),
});
export const w8BenEFormBaseModel = w8FormBaseModel.extend({
    chapterFourStatus: w8ChapterFourStatus,
    chapterThreeStatus: w8ChapterThreeStatus,
    companyType: z.literal(UserType.BUSINESS),
    countryOfIncorporationOrOrganization: nonemptyString,
    giin: z
        .string()
        .transform(digitsAndLetters)
        .pipe(z
        .string()
        .length(16, 'GIIN must contain exactly 16 characters')
        .regex(ALPHANUMERIC, 'GIIN can only contain alphanumeric characters'))
        .optional()
        .or(z.literal('')),
    paymentReceivedByIntermediaryOrThroughEntity: z.boolean().default(false),
    treatyBenefitsLimitationOnBenefits: w8LimitationOnBenefits.optional().nullable(),
});
const treatyFieldValidator = (fieldName) => (data) => {
    const anyTreatyFieldHasValue = [
        data.treatyBenefitsArticleAndParagraph,
        data.treatyBenefitsTypeOfIncome,
        data.treatyBenefitsWithholdingRate,
    ].some(Boolean);
    return data.claimingTreatyBenefits && anyTreatyFieldHasValue ? Boolean(data[fieldName]) : true;
};
const mailingAddressFieldValidator = (fieldName) => (data) => data.mailingAddressMatchPermanentAddress ? true : Boolean(data[fieldName]);
const isW8BenForm = (values) => values.companyType === UserType.PERSONAL;
export const w8FormModel = z
    .discriminatedUnion('companyType', [w8BenFormBaseModel, w8BenEFormBaseModel])
    .refine((values) => {
    if (!isW8BenForm(values)) {
        return true;
    }
    return values.certificationType === taxCertificationType.Enum.signedElectronically ? values.signature : true;
}, { message: 'Required', path: ['signature'] })
    .refine(({ certificationType, completedForm }) => certificationType === taxCertificationType.Enum.uploadSignedPDF ? completedForm : true, { message: 'Required', path: ['completedForm'] })
    .refine(({ certificationType, completedFormSignedDate }) => certificationType === taxCertificationType.Enum.uploadSignedPDF ? completedFormSignedDate : true, { message: 'Required', path: ['completedFormSignedDate'] })
    .refine(treatyFieldValidator('treatyBenefitsArticleAndParagraph'), {
    message: 'Required',
    path: ['treatyBenefitsArticleAndParagraph'],
})
    .refine(treatyFieldValidator('treatyBenefitsTypeOfIncome'), {
    message: 'Required',
    path: ['treatyBenefitsTypeOfIncome'],
})
    .refine(treatyFieldValidator('treatyBenefitsWithholdingRate'), {
    message: 'Required',
    path: ['treatyBenefitsWithholdingRate'],
})
    .refine(mailingAddressFieldValidator('mailingCity'), {
    message: 'Required',
    path: ['mailingCity'],
})
    .refine(mailingAddressFieldValidator('mailingCountry'), {
    message: 'Required',
    path: ['mailingCountry'],
})
    .refine(mailingAddressFieldValidator('mailingStreetAddress'), {
    message: 'Required',
    path: ['mailingStreetAddress'],
})
    .refine(mailingAddressFieldValidator('mailingPostalcode'), {
    message: 'Required',
    path: ['mailingPostalcode'],
})
    .refine(mailingAddressFieldValidator('mailingState'), {
    message: 'Required',
    path: ['mailingState'],
})
    .superRefine(({ claimingTreatyBenefits, tin, ftin }, ctx) => {
    if (claimingTreatyBenefits && !(tin || ftin)) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Either TIN or FTIN is required when claiming treaty benefits.',
        });
    }
})
    .transform(({ completedForm, ...rest }) => ({
    ...rest,
    completedForm: completedForm?.file,
    completedFormFilename: completedForm?.filename,
}));
export const w9FormBaseModel = z.object({
    address: w9MailingAddress,
    businessName: z.string().nullish(),
    businessType: w9FormBusinessType.optional(),
    businessTypeOtherDescription: z.string().optional(),
    certificationType: taxCertificationType,
    companyType: z.nativeEnum(UserType),
    completedForm: file.optional().nullable(),
    completedFormSignedDate: dateField.nullable().optional(),
    tin: nonemptyString.transform(digitsOnly).pipe(z.string()),
    exemptionFatcaCode: w9ExemptFatcaCode.nullish(),
    exemptionPayeeCode: w9ExemptPayeeCode.nullish(),
    hasTaxExemptions: z.boolean().default(false).nullish(),
    isBusinessTinTypeSSN: z.boolean().default(false).nullish(),
    legalName: z.tuple([nonemptyString]).or(z.tuple([nonemptyString, nonemptyString])),
    signature: z.string().optional(),
});
export const w9FormModel = w9FormBaseModel
    .refine(({ companyType, businessType }) => {
    return companyType === UserType.BUSINESS ? businessType : true;
}, { message: 'Required', path: ['businessType'] })
    .refine(({ businessType, businessTypeOtherDescription }) => businessType === w9FormBusinessType.Enum.other ? businessTypeOtherDescription : true, { message: 'Required', path: ['businessTypeOtherDescription'] })
    .refine(({ certificationType, completedForm }) => certificationType === taxCertificationType.Enum.uploadSignedPDF ? completedForm : true, { message: 'Required', path: ['completedForm'] })
    .refine(({ certificationType, completedFormSignedDate }) => certificationType === taxCertificationType.Enum.uploadSignedPDF ? completedFormSignedDate : true, { message: 'Required', path: ['completedFormSignedDate'] })
    .refine(({ certificationType, signature }) => certificationType === taxCertificationType.Enum.signedElectronically ? signature : true, { message: 'Required', path: ['signature'] })
    .superRefine(({ hasTaxExemptions, exemptionFatcaCode, exemptionPayeeCode }, ctx) => {
    if (hasTaxExemptions && !(exemptionFatcaCode || exemptionPayeeCode)) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Either Exempt Payee Code or Exempt FATCA Code is required when having tax exemptions.',
        });
    }
})
    .transform(({ completedForm, ...rest }) => ({
    ...rest,
    completedForm: completedForm?.file,
    completedFormFilename: completedForm?.filename,
}));

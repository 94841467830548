import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeNotificationBar } from 'actions/ui';
import { NotificationBar } from 'components/notificationBar';
import { callIfIsFn } from 'helpers/utility';
import { notificationSelector } from 'selectors/uiSelectors';
export const DashboardNotificationBar = ({ className }) => {
    const dispatch = useDispatch();
    const notification = useSelector(notificationSelector);
    if (!notification?.visible) {
        return null;
    }
    const handleCloseNotification = () => {
        dispatch(closeNotificationBar());
        callIfIsFn(notification.onCloseNotification);
    };
    return (React.createElement(NotificationBar, { className: className, hasBackgroundColor: true, ...notification, onCloseNotification: handleCloseNotification }, notification.message));
};

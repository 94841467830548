import Cleave from 'cleave.js/react';
import PropTypes from 'prop-types';
import React from 'react';

import * as commonProps from 'components/input/commonProps';

const DateInput = ({ onBlur, onChange, onFocus, input, isDisabled, placeholder, dataTestId }) => (
  <Cleave
    {...input}
    data-testid={dataTestId}
    disabled={isDisabled}
    onBlur={onBlur}
    onChange={onChange}
    onFocus={onFocus}
    options={{
      date: true,
      datePattern: ['m', 'd', 'Y'],
    }}
    placeholder={placeholder}
  />
);

DateInput.propTypes = {
  ...commonProps.propTypes,
  dataTestId: PropTypes.string,
  input: PropTypes.shape().isRequired,
};

DateInput.defaultProps = {
  ...commonProps.defaultProps,
  dataTestId: undefined,
};

export default DateInput;

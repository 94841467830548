import * as types from 'types/partnership';

const errorReducer = (state = null, action) => {
  switch (action.type) {
    case types.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_FAILURE:
      return action.payload.errors;

    case types.CLEAR_PARTNERSHIP_FUNDING_ACCOUNT:
    case types.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_REQUEST:
    case types.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_SUCCESS:
      return null;

    default:
      return state;
  }
};

export default errorReducer;

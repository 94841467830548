import { CompanySettingsPaymentsType } from 'enums/company';

import { REST } from 'helpers/api';

import { FetchService } from 'services';
import {
  getCompanyDocumentEndpoint,
  getCompanyDocumentsEndpoint,
  getCompanyEnableCrossborderEndpoint,
  getCompanyEndpoint,
  getCompanySettingsIntegrationEndpoint,
  getCompanySettingsItemApprovalEndpoint,
} from 'services/api/companyEndpoints';
import { payloadToJSONAPI } from 'services/api/formatHelpers';

/**
 * Enable international payments for current company
 * @param {Company.id} companyId
 * @param {CompanySettingsPaymentsType} paymentType
 * @param {ObjectMaybe} [options={}]
 * @return {Promise<*>}
 */
export const enableInternationalPayments = async (companyId, paymentType, options = {}) => {
  const endpoint = getCompanyEnableCrossborderEndpoint(companyId);
  const config = {
    ...options,
    endpoint,
    method: 'POST',
    payload: {
      data: {
        id: companyId,
        type: 'Company',
      },
      meta: {
        enabled: Boolean(paymentType === CompanySettingsPaymentsType.INTERNATIONAL),
      },
    },
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * Fetch current company data.
 * @param {Company.id} companyId
 * @param {ObjectMaybe} [options={}]
 * @return {Promise<*>}
 */
export const getCurrentCompany = async (companyId, options = {}) => {
  const endpoint = getCompanyEndpoint(companyId);
  const config = {
    ...options,
    endpoint,
    method: 'GET',
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * Fetch current company data.
 * @param {Company.id} companyId
 * @param {Id} settingsItemApprovalsId
 * @param {ObjectMaybe} [options={}]
 * @return {Promise<*>}
 */
export const getCurrentCompanySettingsItemApprovals = async (companyId, settingsItemApprovalsId, options = {}) => {
  const endpoint = getCompanySettingsItemApprovalEndpoint(companyId, settingsItemApprovalsId);
  const config = {
    ...options,
    endpoint,
    method: 'GET',
    requireAuth: true,
  };
  return FetchService.request(config);
};

export const updateCurrentCompanySettingsIntegration = (companyId, settingsIntegrationId, data, options = {}) => {
  const payload = payloadToJSONAPI(data, 'CompanySettingsIntegration');
  return FetchService.request({
    ...options,
    endpoint: getCompanySettingsIntegrationEndpoint(companyId, settingsIntegrationId),
    method: 'PATCH',
    payload,
    requireAuth: true,
    setRequester: true,
  });
};

export const postCompanyDocument = async (currentCompanyId, file) => {
  const endpoint = getCompanyDocumentsEndpoint({ currentCompanyId });
  const config = {
    endpoint,
    requireAuth: true,
    payload: {
      data: {
        attributes: {
          document_type: 'other',
          file: file.file,
          filename: file.filename,
        },
        type: 'Document',
      },
    },
  };

  return REST.post(config);
};

export const deleteCompanyDocument = async (currentCompanyId, documentId) => {
  const endpoint = getCompanyDocumentEndpoint({ currentCompanyId, documentId });
  const config = {
    endpoint,
    requireAuth: true,
  };

  return REST.delete(config);
};

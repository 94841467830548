import { submitInvoicesRoutine } from 'actions/routines/item';

import { createIsSubmittingReducer } from 'store/redux';

import { CLEAR_INVOICES } from 'types/item';

export const finishCases = [CLEAR_INVOICES, submitInvoicesRoutine.FAILURE, submitInvoicesRoutine.SUCCESS];

export const requestCases = [submitInvoicesRoutine.REQUEST];

const submitReducer = createIsSubmittingReducer(finishCases, requestCases);

export default submitReducer;

import styled from 'styled-components';
const baseFontFamily = `"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`;
export const TextH1 = styled.h1 `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.regular};
  font-size: 48px;
  line-height: 56px;
  margin: 0;
`;
export const TextH2 = styled.h2 `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.regular};
  font-size: 40px;
  line-height: 48px;
  margin: 0;
`;
export const TextH3 = styled.h3 `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.regular};
  font-size: 32px;
  line-height: 40px;
  margin: 0;
`;
export const TextH4 = styled.h4 `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.regular};
  font-size: 24px;
  line-height: 32px;
  margin: 0;
`;
export const TextH5 = styled.h5 `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.medium};
  font-size: 20px;
  line-height: 28px;
  margin: 0;
`;
export const TextH6 = styled.h6 `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.medium};
  font-size: 18px;
  line-height: 24px;
  margin: 0;
`;
export const TextSubtitle = styled.h6 `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.medium};
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`;
export const TextBody16 = styled.p `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.regular};
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`;
export const TextBody14 = styled.p `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.regular};
  font-size: 14px;
  line-height: 20px;
  margin: 0;
`;
export const TextButtonText = styled.span `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.semiBold};
  font-size: ${(p) => p.theme.typography.fontSize[2]};
  line-height: ${(p) => p.theme.typography.lineHeight[2]};
  margin: 0;
`;
export const TextButtonTextSmall = styled.span `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.semiBold};
  font-size: ${(p) => p.theme.typography.fontSize[1]};
  line-height: ${(p) => p.theme.typography.lineHeight[1]};
  margin: 0;
`;
export const TextInputFloatingLabel = styled.span `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.semiBold};
  font-size: ${(p) => p.theme.typography.fontSize[1]};
  line-height: 16px;
  margin: 0;
`;
export const TextInputText = styled.span `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.regular};
  font-size: ${(p) => p.theme.typography.fontSize[2]};
  line-height: ${(p) => p.theme.typography.lineHeight[4]};
  margin: 0;
`;
export const TextDetailListLabelSmall = styled.span `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.regular};
  font-size: ${(p) => p.theme.typography.fontSize[1]};
  line-height: 16px;
  margin: 0;
`;
export const TextDetailListLabelRegular = styled.span `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.regular};
  font-size: ${(p) => p.theme.typography.fontSize[2]};
  line-height: ${(p) => p.theme.typography.lineHeight[2]};
  margin: 0;
`;
export const TextDetailListContentSmall = styled.span `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.regular};
  font-size: ${(p) => p.theme.typography.fontSize[1]};
  line-height: ${(p) => p.theme.typography.lineHeight[2]};
  margin: 0;
`;
export const TextDetailListContentRegular = styled.span `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.regular};
  font-size: ${(p) => p.theme.typography.fontSize[3]};
  line-height: ${(p) => p.theme.typography.lineHeight[4]};
  margin: 0;
`;
export const TextHintHeader = styled.span `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.semiBold};
  font-size: ${(p) => p.theme.typography.fontSize[2]};
  line-height: ${(p) => p.theme.typography.lineHeight[3]};
  margin: 0;
`;
export const TextHintBody = styled.span `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.regular};
  font-size: ${(p) => p.theme.typography.fontSize[2]};
  line-height: ${(p) => p.theme.typography.lineHeight[3]};
  margin: 0;
`;
export const TextNotificationBar = styled.span `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.medium};
  font-size: ${(p) => p.theme.typography.fontSize[2]};
  line-height: ${(p) => p.theme.typography.lineHeight[3]};
  margin: 0;
`;
export const TextTag = styled.span `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.medium};
  font-size: ${(p) => p.theme.typography.fontSize[1]};
  line-height: ${(p) => p.theme.typography.lineHeight[4]};
  margin: 0;
`;
export const TextStatus = styled.span `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.medium};
  font-size: ${(p) => p.theme.typography.fontSize[1]};
  line-height: ${(p) => p.theme.typography.lineHeight[4]};
  margin: 0;
`;
export const TextProgressStepNavigation = styled.span `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.semiBold};
  font-size: ${(p) => p.theme.typography.fontSize[2]};
  line-height: ${(p) => p.theme.typography.lineHeight[3]};
  margin: 0;
`;
export const TextTabNavigation = styled.span `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.semiBold};
  font-size: ${(p) => p.theme.typography.fontSize[1]};
  line-height: ${(p) => p.theme.typography.lineHeight[3]};
  margin: 0;
`;
export const TextToastText = styled.span `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.medium};
  font-size: ${(p) => p.theme.typography.fontSize[2]};
  line-height: ${(p) => p.theme.typography.lineHeight[3]};
  margin: 0;
`;
export const TextTooltipTextHeader = styled.span `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.semiBold};
  font-size: ${(p) => p.theme.typography.fontSize[2]};
  line-height: ${(p) => p.theme.typography.lineHeight[3]};
  margin: 0;
`;
export const TextTooltipText = styled.span `
  font-family: ${baseFontFamily};
  font-weight: ${(p) => p.theme.typography.weight.regular};
  font-size: ${(p) => p.theme.typography.fontSize[1]};
  line-height: ${(p) => p.theme.typography.lineHeight[1]};
  margin: 0;
`;

import React from 'react';
import LinkNewPage from 'components/link/LinkNewPage';
import { Text, WhiteSpace } from 'components/text';
import { TagText, TagType } from 'constants/ui';
import helpDocs from 'helpers/helpDocs';
const DashboardToDashboardW9RestrictionTooltipContent = ({ actionText = 'to request W-9 tax info', }) => (React.createElement(Text.Regular, null,
    `A ${TagText[TagType.SELF_MANAGED].toLowerCase()} company means that they are also a Routable user, and because 
    of this, you are not able ${actionText}. Learn more about`,
    React.createElement(WhiteSpace, null),
    React.createElement(LinkNewPage, { className: "primary", href: helpDocs.SELF_MANAGED_COMPANIES }, `${TagText[TagType.SELF_MANAGED].toLowerCase()} companies`),
    "."));
export default DashboardToDashboardW9RestrictionTooltipContent;

import {} from './baseLogger.types';
export const createBaseLogger = (config) => {
    const log = async (message) => Promise.allSettled(config.transports.map((transport) => transport.transport([message])));
    const map = (transform) => createBaseLogger(transform(config));
    const mapTransports = (transform) => map((orgConfig) => ({
        ...orgConfig,
        transports: orgConfig.transports.map((transport) => transform(transport)),
    }));
    return {
        log,
        map,
        mapTransports,
        getConfig: () => config,
    };
};

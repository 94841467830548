import { FEATURE_FLAG_NAME_BULK_RECEIVABLES } from 'constants/featureFlags';

import { withFeatureFlag } from 'hoc';

/**
 * Convenience wrapper around WithFeatureFlag, to gate a
 * component's render behind the bulkReceivables feature flag.
 * Defaults the fallback children to null (overrideable).
 * @param {ComponentProps} props
 * @return {AnyComponent}
 */
const WithFeatureFlagBulkReceivables = withFeatureFlag(FEATURE_FLAG_NAME_BULK_RECEIVABLES);

WithFeatureFlagBulkReceivables.displayName = 'WithFeatureFlagBulkReceivables';

export default WithFeatureFlagBulkReceivables;

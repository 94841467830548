import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { oldVersionModeModalClose } from 'actions/oldVersion';

import { modalNameOldVersionMode } from 'constants/modals';

import { createModalSelector } from 'selectors/modalsSelector';

import OldVersionModeModal from './OldVersionModeModal';

const modalSelector = createModalSelector(modalNameOldVersionMode);

const mapStateToProps = createStructuredSelector({
  modalState: modalSelector,
});

const mapDispatchToProps = {
  onCloseModal: oldVersionModeModalClose,
};

export default connect(mapStateToProps, mapDispatchToProps)(OldVersionModeModal);

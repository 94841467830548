import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { FormControl, FormFieldLabel, Radio, TINInput } from 'components';

import { companyBusinessTypes } from 'constants/company';
import { formStyles } from 'constants/styles';
import { UserTypes, UserTypeDescriptionText } from 'constants/user';

import { isBusinessTypeSoleProprietor, isCurrentCompanyTypeBusiness } from 'helpers/currentCompany';
import { isTinTypeSsn } from 'helpers/taxes';

import { canEditBusinessTin } from 'modules/external/ExternalCollectTaxInfo/helpers/components';

import { BusinessTypeSelect, ConfirmFullName, ConfirmLegalBusinessName, MissingEinCheckbox } from './components';

/**
 * Form to collect information for 1099, such as company type, business type, TIN. Mailing address info is separately
 * collected by MailingAddress component.
 * @param {Object} props
 * @returns {FunctionComponent}
 * @constructor
 */
const FilingDetailsForm = (props) => {
  const { className, businessType, companyType, disableForm, hasSsn, noEin, hasEin, tinType } = props;

  const companyTypeIsBusiness = isCurrentCompanyTypeBusiness({ companyType });
  const isSoleProprietor = isBusinessTypeSoleProprietor(businessType);
  const needsBusinessTin = canEditBusinessTin({
    companyTypeIsBusiness,
    isSoleProprietor,
    hasEin,
    hasSsn,
    noEin,
  });

  return (
    <div
      className={classNames({
        [className]: !!className,
      })}
    >
      <FormFieldLabel>Filing Details</FormFieldLabel>

      <FormControl className="display--flex">
        <Field
          component={Radio}
          id="companyTaxTypeBusiness"
          isDisabled={disableForm}
          isHorizontalLayout
          name="form.company.companyType"
          optionText="I represent a business."
          subText={UserTypeDescriptionText[UserTypes.BUSINESS]}
          type="radio"
          value={UserTypes.BUSINESS}
        />

        <Field
          component={Radio}
          id="companyTaxTypeIndividual"
          isDisabled={disableForm}
          isHorizontalLayout
          name="form.company.companyType"
          optionText="I am an individual."
          subText={UserTypeDescriptionText[UserTypes.PERSONAL]}
          type="radio"
          value={UserTypes.PERSONAL}
        />
      </FormControl>

      {companyTypeIsBusiness && (
        <React.Fragment>
          <BusinessTypeSelect isDisabled={disableForm} />

          <FormControl>
            <ConfirmLegalBusinessName isDisabled={disableForm} />

            <TINInput
              companyType={companyType}
              editField={!disableForm && needsBusinessTin}
              fieldClassNames={formStyles.field.xl.right}
              isDisabled={disableForm || !needsBusinessTin}
              isSSN={isTinTypeSsn(tinType)}
              name="form.company.info.tin"
              showField
            />
          </FormControl>

          {isSoleProprietor && (
            <FormControl>
              <MissingEinCheckbox isDisabled={disableForm} name="ui.noEin" />
            </FormControl>
          )}
        </React.Fragment>
      )}

      {!companyTypeIsBusiness && (
        <React.Fragment>
          <FormControl>
            <ConfirmFullName isDisabled={disableForm} />
          </FormControl>

          <FormControl>
            <TINInput
              editField={!disableForm && !hasSsn}
              fieldClassNames={formStyles.field.xl.full}
              isDisabled={!!disableForm || !!hasSsn}
              isSSN
              name="form.company.info.tin"
              showField
            />
          </FormControl>
        </React.Fragment>
      )}
    </div>
  );
};

FilingDetailsForm.propTypes = {
  disableForm: PropTypes.bool,
  businessType: PropTypes.oneOf(Object.values(companyBusinessTypes)),
  className: PropTypes.string,
  companyType: PropTypes.oneOf(Object.values(UserTypes)),
  hasEin: PropTypes.bool,
  hasSsn: PropTypes.bool,
  noEin: PropTypes.bool,
  tinType: PropTypes.string,
};

FilingDetailsForm.defaultProps = {
  disableForm: undefined,
  businessType: undefined,
  companyType: undefined,
  className: undefined,
  hasEin: undefined,
  hasSsn: undefined,
  noEin: undefined,
  tinType: undefined,
};

export default FilingDetailsForm;

import React from 'react';
import { CountryFlag, FlexRow } from 'components';
import { useCountryCodesContext } from 'hooks';
import { getCountryAndCurrencyText, getInferredCountryCodeFromFundingData } from './helpers';
const PaymentMethodDetailsCountryCurrencyField = ({ fundingAccount, fundingInfoInternational, }) => {
    const countryCodeMap = useCountryCodesContext();
    const text = getCountryAndCurrencyText({
        countryCodeMap,
        fundingAccount,
        fundingInfoInternational,
    });
    return (React.createElement(FlexRow, { className: "align-items--center", stackOnMobile: false },
        React.createElement(CountryFlag, { className: "payment_method__detail-item-value-flag", countryCode: getInferredCountryCodeFromFundingData(fundingAccount, fundingInfoInternational) }),
        text));
};
export default PaymentMethodDetailsCountryCurrencyField;

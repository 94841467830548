import { BulkImportItemsStep } from 'constants/bulkActions';

import { isStepBetween } from 'helpers/bulkActions';
import { isItemStatusIssue } from 'helpers/items';
import { and, firstKey, hasLength, isNotEqual, or, valueForFirstKey } from 'helpers/utility';

/**
 * Given an API response, returns the step property of a BulkImport object.
 * @param {Object} response
 * @param {Id} [id]
 * @returns {BulkImportItemsStep|undefined}
 */
export const getBulkImportStepFromResponse = (response, id) => {
  const bulkImportId = or(id, firstKey(response.data?.bulkImport));
  const bulkImport = response.data?.bulkImport?.[bulkImportId];
  return bulkImport?.attributes?.step;
};

/**
 * Returns the next form state for bulk import items, after uploading a
 * file and receiving a success response.
 * @param {ReduxFormState} state
 * @returns {ReduxFormState}
 */
export const getNextBulkImportItemsFormStateOnUploadTrigger = (state) => ({
  ...state,
  values: {
    ...state.values,
    ui: {
      ...state.values.ui,
      isFileProcessing: true,
    },
  },
});

/**
 * Returns the next form state for bulk import items, after uploading a
 * file and receiving a success response.
 * @param {ReduxFormState} state
 * @param {ReduxAction} action
 * @returns {ReduxFormState}
 */
export const getNextBulkImportItemsFormStateOnUploadSuccess = (state, action) => {
  const bulkImportId = firstKey(action.payload.bulkImport);
  const bulkImport = action.payload.bulkImport[bulkImportId];

  const {
    attributes: { issues },
  } = bulkImport;

  return {
    ...state,
    values: {
      ...state.values,
      form: {
        ...state.values.form,
        id: bulkImportId,
      },
      ui: {
        ...state.values.ui,
        isFileProcessing: !hasLength(issues),
      },
    },
  };
};

/**
 * Returns the next form state for bulk import items, after uploading a
 * file and receiving a success response.
 * @param {ReduxFormState} state
 * @param {ReduxAction} action
 * @returns {ReduxFormState}
 */
export const getNextBulkImportItemsFormStateOnFetchSingleImportSuccess = (state, action) => {
  const { id: activeImportId } = state.values.form;
  const bulkImport = valueForFirstKey(action.payload.bulkImport);

  const {
    attributes: { issues, status, step: latestStep },
    id,
  } = bulkImport;

  if (isNotEqual(id, activeImportId)) {
    return state;
  }

  const isProcessingStep = isStepBetween(latestStep, BulkImportItemsStep.upload, BulkImportItemsStep.summary);

  return {
    ...state,
    values: {
      ...state.values,
      ui: {
        ...state.values.ui,
        isFileProcessing: and(isProcessingStep, !isItemStatusIssue({ status }), !hasLength(issues)),
        // maxStep will track the farthest point we've reached in this flow, and will
        // always be in sync with the step tracked by the API
        maxStep: latestStep,
        status,
        // step can manually be changed by the user navigating backward in the form,
        // in which case it will de-sync with the step tracked by the API
        step: latestStep,
      },
    },
  };
};

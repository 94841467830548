import { updateMembershipRoutine } from 'actions/routines/membership';

import { allKeys } from 'helpers/utility';

import { GET_CURRENT_COMPANY_SUCCESS } from 'types/currentCompany';
import {
  FETCH_MEMBERSHIP_SUCCESS,
  FETCH_MEMBERSHIPS_SUCCESS,
  UPDATE_MEMBERSHIP_SUCCESS,
  UPDATE_ONBOARDING_MEMBERSHIP_SUCCESS,
} from 'types/memberships';
import { GET_CURRENT_USER_SUCCESS } from 'types/user';

const allUsersReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_MEMBERSHIP_SUCCESS:
    case FETCH_MEMBERSHIPS_SUCCESS:
    case GET_CURRENT_COMPANY_SUCCESS:
    case GET_CURRENT_USER_SUCCESS:
    case UPDATE_MEMBERSHIP_SUCCESS:
    case updateMembershipRoutine.SUCCESS:
    case UPDATE_ONBOARDING_MEMBERSHIP_SUCCESS:
      return Array.from(new Set([...state, ...allKeys(action.payload.user)]));

    default:
      return state;
  }
};

export default allUsersReducer;

import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';
import { lookupMembershipInviteEmailRoutine } from 'actions/routines/inviteTeamMember';

import { deepMergeWithArrayReplacement } from 'helpers/transform';

import getRelationships from 'store/redux';

import { FETCH_MEMBERSHIP_SUCCESS, FETCH_MEMBERSHIPS_SUCCESS } from 'types/memberships';

const getCurrentCompanyMemberships = (memberships) => {
  const membershipList = {};

  if (!memberships) {
    return membershipList;
  }

  Object.keys(memberships).forEach((membershipId) => {
    const relationships = getRelationships(memberships, membershipId, 'user');
    membershipList[membershipId] = {
      id: memberships[membershipId].id,
      ...memberships[membershipId].attributes,
      ...relationships,
    };
  });

  return membershipList;
};

const companyMembershipsReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MEMBERSHIP_SUCCESS:
      return deepMergeWithArrayReplacement(state, getCurrentCompanyMemberships(action.payload.membership));

    case FETCH_MEMBERSHIPS_SUCCESS:
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
    case lookupMembershipInviteEmailRoutine.FAILURE:
      return getCurrentCompanyMemberships(action.payload.membership);

    default:
      return state;
  }
};

export default companyMembershipsReducer;

import { createIsOpenReducer } from 'store/redux';

import { PARTNERSHIP_FUNDING_ACCOUNT_OPTIONS_CLOSE, PARTNERSHIP_FUNDING_ACCOUNT_OPTIONS_OPEN } from 'types/ui';

export const closeCases = [PARTNERSHIP_FUNDING_ACCOUNT_OPTIONS_CLOSE];
export const openCases = [PARTNERSHIP_FUNDING_ACCOUNT_OPTIONS_OPEN];

const isOpenReducer = createIsOpenReducer(closeCases, openCases);

export default isOpenReducer;

import { PaymentDeliveryMethodType } from '@routable/shared';
import { SubmissionError, change, touch } from 'redux-form';

import { partnerFundingAccountFormFields } from 'constants/formFields';
import { formNamesFunding } from 'constants/forms';

import {
  getAddPartnershipFundingAccountPayload,
  getSuccessMessageForAddedPartnerFundingAccount,
} from 'helpers/addPartnerFundingAccount';
import { isItemPaymentDeliveryMethodCheck } from 'helpers/items';
import { getShowSuccessUI } from 'helpers/ui';

import { storeAccessor as store } from 'store/accessor';

import submitPartnershipFundingAccountAddress from './submitPartnershipFundingAccountAddress';
import submitPartnershipFundingAccountBank from './submitPartnershipFundingAccountBank';

const addPartnerFundingAccountForm = formNamesFunding.ADD_PARTNER_FUNDING_ACCOUNT;

/**
 * Add partnership funding bank account
 * @param {Object} addressValues
 * @param {string} partnershipId
 * @param {Function} onSuccess
 * @param {StringMaybe} [formContext]
 * @return {DispatchThunk} Thunk resolving to errors or false-y.
 */
export const submitAddPartnershipFundingAddress = (addressValues, partnershipId, onSuccess, formContext) => {
  const errorCallback = (parsedErrors) => {
    // We need to pop open the edit print on check if we want to ensure the error is visible
    if (parsedErrors.fields) {
      const { printCompany, printName } = parsedErrors.fields;

      const optionalFieldErrors = [];

      if (printName) {
        optionalFieldErrors.push('form.address.printName');
      }

      if (printCompany) {
        optionalFieldErrors.push('form.address.printCompany');
      }

      // Dispatch actions to show and touch the fields with errors
      if (optionalFieldErrors.length > 0) {
        store.dispatch([
          change(
            addPartnerFundingAccountForm,
            partnerFundingAccountFormFields.SHOULD_SHOW_ADDRESS_PRINT_ON_CHECK,
            true,
          ),
          touch(addPartnerFundingAccountForm, ...optionalFieldErrors),
        ]);
      }
    }

    return parsedErrors;
  };

  /**
   * Update current form partnerReceivableMethod value to CHECK, executes onSuccess
   * callback for adding partnership funding address and returns parsedResponse object
   * @param parsedResponse
   * @return {*}
   */
  const successCallback = (parsedResponse) => {
    if (formContext) {
      store.dispatch(
        change(formContext, 'ui.selectedCompany.partnerReceivableMethod', PaymentDeliveryMethodType.CHECK),
      );
    }

    onSuccess(parsedResponse);

    return parsedResponse;
  };

  const options = {
    errorCallback,
    partnershipId,
    successCallback,
  };

  return submitPartnershipFundingAccountAddress(addressValues, options);
};

/**
 * Add partnership funding bank account
 * @param {Object} bankValues
 * @param {string} partnershipId
 * @param {Function} onSuccess
 * @param {StringMaybe} [formContext]
 * @return {DispatchThunk} Thunk resolving to errors or false-y.
 */
export const submitAddPartnershipFundingBankAccount = (bankValues, partnershipId, onSuccess, formContext) => {
  const options = {
    partnershipId,
    successCallback: (parsedResponse) => {
      if (formContext) {
        store.dispatch(
          change(formContext, 'ui.selectedCompany.partnerReceivableMethod', PaymentDeliveryMethodType.ACH),
        );
      }

      onSuccess(parsedResponse);

      return parsedResponse;
    },
  };

  return submitPartnershipFundingAccountBank(bankValues, options);
};

/**
 * Determines the correct function to handle submission of a new partnership funding account
 * (e.g. handler for new address vs. new ach account)
 * @param {ComponentProps} props
 * @param {Object} options
 * @return {Function} The appropriate submit function
 */
export const getAddPartnershipFundingAccountSubmitFunction = (props, options = {}) => {
  const { onSubmitAddPartnershipFundingAddress, onSubmitAddPartnershipFundingBankAccount } = props;
  const { paymentDeliveryMethod } = options;

  if (isItemPaymentDeliveryMethodCheck({ paymentDeliveryMethod })) {
    return onSubmitAddPartnershipFundingAddress;
  }

  return onSubmitAddPartnershipFundingBankAccount;
};

/**
 * Determines the correct form path to show submission errors of a new partnership funding account
 * (e.g. error path for new address vs. new bank account)
 * @param options
 * @return {*}
 */
export const getAddPartnershipFundingAccountFormErrorsKey = (options = {}) => {
  const { paymentDeliveryMethod } = options;

  if (isItemPaymentDeliveryMethodCheck({ paymentDeliveryMethod })) {
    return 'address';
  }

  return 'bankAccount';
};

/**
 * Helper to submit an added partner funding account
 * @param {Object} values - Form values
 * @param {Function} dispatch
 * @param {ComponentProps} props - AddPartnerFundingAccountModalContainer props
 * @return {void}
 */
export const submitAddPartnershipFundingAccount = async (values, dispatch, props = {}) => {
  const {
    formName,
    onClose,
    onFetchPartnershipReceivableFundingAccounts,
    partnershipId,
    partnershipFundingAccounts,
    paymentDeliveryMethod,
  } = props;

  const showSuccessUI = getShowSuccessUI(getSuccessMessageForAddedPartnerFundingAccount(paymentDeliveryMethod), {
    id: addPartnerFundingAccountForm,
  });

  // on successful addition of partnership funding account,
  // refresh the partnership funding accounts, close the
  // modal/sidesheet, and show success ui
  const successCallback = () => {
    onFetchPartnershipReceivableFundingAccounts(partnershipId);
    onClose();
    showSuccessUI();
  };

  const valueOptions = { partnershipFundingAccounts, paymentDeliveryMethod };
  const formPath = getAddPartnershipFundingAccountFormErrorsKey(valueOptions);
  const payload = getAddPartnershipFundingAccountPayload(values, valueOptions);
  const submitFunction = getAddPartnershipFundingAccountSubmitFunction(props, valueOptions);

  const submissionErrors = await submitFunction(payload, partnershipId, successCallback, formName);

  // The BE responds with field errors that are for either the address or the bankAccount
  if (submissionErrors && submissionErrors.errors) {
    throw new SubmissionError({
      form: {
        [formPath]: submissionErrors.errors.fields,
      },
    });
  }
};

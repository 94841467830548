import { CornerDialog } from '@routable/components';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeCornerDialogUploadBills } from 'actions/cornerDialog';
import FileUploadProgress from 'components/fileUpload/FileUploadProgress';
import { useSelectorWithProps } from 'hooks';
import { cornerDialogSelector } from 'selectors/cornerDialogsSelectors';
import { itemUploadBillsSelector } from 'selectors/itemsSelectors';
const CornerDialogUploadBills = () => {
    const dispatch = useDispatch();
    const uploadBillsState = useSelector(itemUploadBillsSelector);
    const cornerDialogState = useSelectorWithProps(cornerDialogSelector, 'cornerDialogUploadBills');
    const [isClosing, setIsClosing] = useState(false);
    const allUploadedBills = Object.values(uploadBillsState);
    const title = cornerDialogState.count > 1 ? `Uploading ${cornerDialogState.count} bills` : 'Uploading a bill';
    const handleCloseCornerDialog = useCallback(() => {
        dispatch(closeCornerDialogUploadBills());
    }, [dispatch]);
    useEffect(() => {
        if (isClosing) {
            setTimeout(() => {
                handleCloseCornerDialog();
                setIsClosing(false);
            }, 2000);
        }
    }, [isClosing, handleCloseCornerDialog]);
    useEffect(() => {
        if (cornerDialogState.open && !isClosing) {
            if (allUploadedBills.length > 0 && allUploadedBills.every((bill) => bill.isUploaded)) {
                setIsClosing(true);
            }
        }
    }, [allUploadedBills, cornerDialogState.open, isClosing]);
    const handleCloseComplete = isClosing ? () => { } : handleCloseCornerDialog;
    return (React.createElement(CornerDialog, { className: "corner-dialog", contentClassName: "flex flex-col gap-2", hasFooter: false, isShown: cornerDialogState.open, onClose: handleCloseComplete, title: React.createElement("h4", { className: "text-xl text-grey-70 font-medium" }, title) }, allUploadedBills.map((row) => (React.createElement(FileUploadProgress, { key: row.uploadId, row: row })))));
};
export default CornerDialogUploadBills;

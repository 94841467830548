import PropTypes from 'prop-types';
import React from 'react';

import { NonBlockingSpace } from 'components';

import { PartnershipTypes } from 'constants/partnership';
import { AddedContactsSummaryTitleTextProps } from 'constants/ui';

import { lengthOf } from 'helpers/utility';

import { PartnershipTypePreamble, SummaryOfContactsText } from './components';

/**
 * Renders the text to summarize contacts by showing the first contacts name and summarizing the rest with a count.
 * @param {ComponentProps} props
 * @param {PartnershipMember[]} props.partnershipMembers
 * @param {PartnershipTypes} props.partnershipType
 * @returns {StatelessComponent}
 */
const AddedContactsSummaryTitle = ({ partnershipType, partnershipMembers }) => (
  <>
    <PartnershipTypePreamble contactCount={lengthOf(partnershipMembers)} partnershipType={partnershipType} />

    {lengthOf(partnershipMembers) > 0 && (
      <>
        <NonBlockingSpace
          style={{
            letterSpacing: AddedContactsSummaryTitleTextProps.letterSpacing,
          }}
        />
        <SummaryOfContactsText partnershipMembers={partnershipMembers} />
      </>
    )}
  </>
);

AddedContactsSummaryTitle.propTypes = {
  partnershipMembers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  partnershipType: PropTypes.oneOf([PartnershipTypes.CUSTOMER, PartnershipTypes.VENDOR]).isRequired,
};

export default AddedContactsSummaryTitle;

import { isAmountNegative } from 'helpers/amounts';

import { fundingInfoBalanceIdSelector } from 'queries/fundingCompoundSelectors';

import { fundingAccountsByIdSelector } from 'selectors/fundingSelectors';

import { storeAccessor as store } from 'store/accessor';

/**
 * Determines whether the account's net balance is negative (i.e. whether
 * the balance plus the pending balance amounts to less than 0).
 * @param {number} balance
 * @param {number} pendingBalance
 * @return {boolean}
 */
export const isNetBalanceNegative = (balance, pendingBalance) => balance + pendingBalance < 0;

/**
 * Determines whether we should allow withdrawals in the UI, i.e. if the value
 * of canTransact (from the API) is true, and we have a balance over zero.
 * @param {Boolean} canTransact
 * @param {number} balance
 * @return {Boolean}
 */
export const isWithdrawAllowed = (canTransact, balance) => Boolean(canTransact && balance > 0);

/**
 * Determines whether a funding account id points to the balance funding account.
 * @param {string} fundingAccountId
 * @return {Boolean}
 */
export const isBalanceAccountId = (fundingAccountId) => {
  const reduxState = store.getState();

  const fundingAccount = fundingAccountsByIdSelector(reduxState)[fundingAccountId];
  const balanceId = fundingInfoBalanceIdSelector(reduxState);

  return Boolean(fundingAccount && fundingAccount.balance === balanceId);
};

/**
 * Returns whether the state of the provided balance funding info data needs correction.
 * @param {Object} balanceInfo
 * @return {Boolean}
 */
export const doesBalanceNeedCorrection = (balanceInfo) =>
  // if we have balance data available, and canTransact is set to false,
  // we treat this as an invalid balance requiring correction
  Boolean(balanceInfo && !balanceInfo.canTransact);

/**
 * Returns whether the state of the provided balance funding info data is pending correction.
 * @param {Object} balanceInfo
 * @return {Boolean}
 */
export const isBalancePendingCorrection = (balanceInfo) => {
  // if we don't have any balance data, or if canTransact is set to true,
  // we treat this as a valid balance with no pending corrections
  if (!balanceInfo || balanceInfo.canTransact) {
    return false;
  }

  const balanceAmount = parseFloat(balanceInfo.balance);
  const pendingAmount = parseFloat(balanceInfo.balancePending);

  return Boolean(isAmountNegative(balanceAmount) && pendingAmount >= Math.abs(balanceAmount));
};

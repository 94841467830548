import { combineReducers } from 'redux';

import allReducer from './allReducer';
import byIdReducer from './byIdReducer';

const brandsReducer = combineReducers({
  allIds: allReducer,
  byId: byIdReducer,
});

export default brandsReducer;

import React from 'react';

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

const MaintenanceModeBody = () => (
  <div className="maintenance--body">
    <div className="maintenance--upper-body">
      <div className="maintenance-mode--construction-image" />
    </div>
    <p className="font-regular regular font-color--greyXDark line-height--standard remove-margin-bottom">
      {`${PLATFORM_DISPLAY_SHORT_NAME} has entered scheduled maintenance mode. Typical maintenance downtime is approximately 15 minutes. 
      In that time the ${PLATFORM_DISPLAY_SHORT_NAME} platform will be unavailable. Thank you for your patience!`}
    </p>
  </div>
);

export default MaintenanceModeBody;

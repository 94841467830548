import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { change, reduxForm } from 'redux-form';

import { externalOnboardingIncrementStep } from 'actions/externalOnboarding';

import { formNamesExternal } from 'constants/forms';

import {
  getOnboardingStepOneCompanyInitialValues,
  getOnboardingStepOneMembershipInitialValues,
} from 'modules/createPartnerCompany/helpers/initialValues';
import { makeFormSubmitFailHandler } from 'modules/createPartnerCompany/helpers/submitFailure';
import submitPartnerCompanyContact from 'modules/createPartnerCompany/helpers/submitPartnerCompanyContact';
import PartnerCompanyContactForm from 'modules/createPartnerCompany/presenters/PartnerCompanyContactForm';

import { companiesErrorSelector } from 'selectors/companiesSelectors';
import { currentCompanyInfoSelector } from 'selectors/currentCompanySelectors';
import { currentUserSelector } from 'selectors/currentUserSelectors';
import { externalCompanyContactFormSelector } from 'selectors/forms';
import { membershipErrorsSelector } from 'selectors/membershipsSelector';
import { modalSelector } from 'selectors/modalsSelector';

import {
  onboardingUpdateCompanyTypeEndpoint,
  onboardingUpdateMembershipEndpoint,
} from 'services/api/onboardingEndpoints';

import updateOnboardingCompanyType from 'thunks/updateOnboardingCompanyType';
import updateOnboardingMembership from 'thunks/updateOnboardingMembership';

class PartnerCompanyContactContainer extends React.Component {
  formName = this.props.form;

  componentDidUpdate(prevProps) {
    const {
      formUI: { passedCompanyCall, passedMembershipCall },
      onIncrementStep,
    } = this.props;
    const {
      formUI: { prevPassedCompanyCall, prevPassedMembershipCall },
    } = prevProps;
    if (!(prevPassedCompanyCall && prevPassedMembershipCall) && passedCompanyCall && passedMembershipCall) {
      onIncrementStep();
    }
  }

  handleFormSubmit = async (values) => {
    const { onValuesChange } = this.props;

    // Reset form status, validate and submit the form
    const formUIReset = {
      passedCompanyCall: false,
      passedMembershipCall: false,
    };

    onValuesChange(this.formName, 'formUI', formUIReset);

    return this.validateAndSubmitForm(values);
  };

  submitSuccessCallback = (parsedResponse, jsonResponse, payload, response) => {
    const { onValuesChange } = this.props;

    // Company call succeeded
    if (response.config.url === onboardingUpdateCompanyTypeEndpoint) {
      onValuesChange(this.formName, 'formUI.passedCompanyCall', true);
    }

    // Membership call succeeded
    if (response.config.url === onboardingUpdateMembershipEndpoint) {
      onValuesChange(this.formName, 'formUI.passedMembershipCall', true);
    }

    return parsedResponse;
  };

  validateAndSubmitForm = async (values) =>
    submitPartnerCompanyContact(values, {
      ...this.props,
      submitSuccessCallback: this.submitSuccessCallback,
    });

  render() {
    const { handleSubmit, onCancel } = this.props;

    return (
      <PartnerCompanyContactForm
        formName={this.formName}
        onCancel={onCancel}
        onFormSubmit={handleSubmit(this.handleFormSubmit)}
      />
    );
  }
}

PartnerCompanyContactContainer.propTypes = {
  form: PropTypes.string.isRequired,
  formUI: PropTypes.shape(),
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onIncrementStep: PropTypes.func.isRequired,
  onValuesChange: PropTypes.func.isRequired,
};

PartnerCompanyContactContainer.defaultProps = {
  formUI: {},
};

const createContactForm = reduxForm({
  form: formNamesExternal.PARTNER_COMPANY_CONTACT,
  onSubmitFail: makeFormSubmitFailHandler(formNamesExternal.PARTNER_COMPANY_CONTACT),
})(PartnerCompanyContactContainer);

const mapStateToProps = (state) => ({
  companyErrors: companiesErrorSelector(state),
  currentCompanyInfo: currentCompanyInfoSelector(state),
  currentUser: currentUserSelector(state),
  formUI: externalCompanyContactFormSelector(state, 'formUI'),
  initialValues: {
    company: getOnboardingStepOneCompanyInitialValues(state),
    membership: getOnboardingStepOneMembershipInitialValues(state),
    formUI: {
      passedCompanyCall: false,
      passedMembershipCall: false,
    },
  },
  // TODO: FRON-1383 - consume membershipErrors to show field errors from backend
  membershipErrors: membershipErrorsSelector(state),
  modal: modalSelector(state, 'externalOnboarding'),
});

const mapDispatchToProps = {
  onIncrementStep: externalOnboardingIncrementStep,
  onUpdateOnboardingCompanyType: updateOnboardingCompanyType,
  onUpdateOnboardingMembership: updateOnboardingMembership,
  onValuesChange: change,
};

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));

export { PartnerCompanyContactContainer };
export default enhance(createContactForm);

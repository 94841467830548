import styled from 'styled-components';
import { Icon } from 'components';
import { containerWidthMap } from 'modules/signup-v3/constants/container';
import {} from './Accordion.type';
export const ChevronIcon = styled(Icon) `
  display: flex;
  position: relative;
  top: 2px;
  transition: transform 0.15s;
`;
export const Summary = styled.summary `
  padding: 1rem .75rem;
  align-items: baseline;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  gap: .5rem;
  line-height: 1.5rem;
  padding-left: .375rem;
  max-width: ${containerWidthMap.large}

  &::marker {
    display: none;
  }

  &:hover {
    background-color: var(--blue--x-light);
    color: var(--blue--bold);
  }
`;
export const Details = styled.details `
  color: var(--grey--xx-dark);
  max-width: ${(props) => (props.containerWidth ? props.containerWidth : containerWidthMap.large)};

  &:not(:only-of-type) {
    border-bottom: 1px solid var(--blue--light);

    &:first-of-type {
      border-top: 1px solid var(--blue--light);
    }
  }

  &[open] {
    ${ChevronIcon} {
      transform: rotate(90deg);
    }

    ${Summary} {
      background-color: var(--blue--x-light);
      color: var(--neutral-black);
    }
  }
`;
export const DetailsContent = styled.div `
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  margin: 1rem 0;
  padding-left: 32px;
`;

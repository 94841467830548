import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchSingleMembershipRequest } from 'actions/memberships';
import { fetchPermissionsRoutine } from 'actions/routines/permissions';
import { fetchRolesRoutine } from 'actions/routines/roles';

import { BoxV2, ButtonV2, IconNames } from 'components';

import { ButtonSize } from 'constants/button';
import { modalNameConnectBankManual } from 'constants/modals';
import { DASHBOARD, GUIDE } from 'constants/routes';
import { sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

import GenericContainer from 'helpers/containers';
import { getCurrentMembershipId } from 'helpers/localStorage';
import { getJoinedPath } from 'helpers/routeHelpers';

import BankAccountSelector from 'modules/connectBank/bankAccountSelector';

import { isLoadingMembershipAndHasRolesAndPermissions } from 'queries/signupCompoundSelectors';

import {
  fundingAccountBankManualIsSubmittingSelector,
  fundingAccountBankManualLastSubmittedSelector,
  fundingAccountBankTokenIsSubmittingSelector,
  fundingAccountBankTokenLastSubmittedSelector,
  fundingAccountsLastSubmittedSelector,
} from 'selectors/fundingSelectors';
import { modalIsOpenSelector } from 'selectors/modalsSelector';

/**
 * Connect bank step during the onboarding/signup process
 * @param {ComponentProps} props
 * @param {Boolean} props.connectBankManualOpen
 * @param {Number} props.currentStepPosition
 * @param {Array} props.flowSteps
 * @param {Boolean} props.isLoading
 * @param {Boolean} props.isSubmittingManualAccount
 * @param {Boolean} props.isSubmittingTokenAccount
 * @param {String} props.lastSubmittedFundingAccount
 * @param {String} props.lastSubmittedManualAccount
 * @param {String} props.lastSubmittedTokenAccount
 * @param {Function} props.onFetchRoles,
 * @param {Function} props.onFetchPermissionsAndPermissionGroups,
 * @returns {ClassComponent}
 */
export class SignupConnectBankContainer extends GenericContainer {
  componentDidMount() {
    const { onFetchMembership, onFetchPermissionsAndPermissionGroups, onFetchRoles } = this.props;
    const currentMembershipId = getCurrentMembershipId();

    // NOTE: Without onFetchMembership right permissions won't be selected.
    onFetchMembership(currentMembershipId);
    onFetchPermissionsAndPermissionGroups();
    onFetchRoles();
  }

  navigateToGuideSection = () => {
    const { history } = this.props;
    history.push(getJoinedPath(DASHBOARD, GUIDE));
  };

  renderContinueButton = () => {
    if (!this.props.lastSubmittedTokenAccount && !this.props.lastSubmittedManualAccount) {
      return null;
    }

    return (
      <div className="form-control remove-margin-bottom">
        <ButtonV2
          className="margin-top--m-large"
          leftIconClassName="margin-right--xm"
          leftIconName={IconNames.TICK_CIRCLE}
          leftIconSize={sizes.iconSizes.LARGE}
          onClick={() => {
            this.props.history.push(`/${DASHBOARD}/${GUIDE}`);
          }}
          rightIconName={IconNames.ARROW_RIGHT}
          rightIconProps={{ style: { marginLeft: 'auto' } }}
          rightIconSize={sizes.iconSizes.LARGE}
          size={ButtonSize.LARGE}
        >
          Complete my account
        </ButtonV2>
      </div>
    );
  };

  render() {
    return (
      <div className="branded--contents">
        <BoxV2 title="Add a company bank account">
          <BankAccountSelector
            AddLaterButton={
              <div className="display--flex justify-content--center">
                <ButtonV2
                  className="margin-top--larger"
                  intent={Intent.NEUTRAL}
                  onClick={this.navigateToGuideSection}
                  size={ButtonSize.MEDIUM}
                >
                  Add bank account later
                </ButtonV2>
              </div>
            }
          />
          {this.renderContinueButton()}
        </BoxV2>
      </div>
    );
  }
}

SignupConnectBankContainer.propTypes = {
  connectBankManualOpen: PropTypes.bool.isRequired,
  currentStepPosition: PropTypes.string.isRequired,
  flowSteps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSubmittingManualAccount: PropTypes.bool.isRequired,
  isSubmittingTokenAccount: PropTypes.bool.isRequired,
  lastSubmittedFundingAccount: PropTypes.shape({}),
  lastSubmittedManualAccount: PropTypes.bool.isRequired,
  lastSubmittedTokenAccount: PropTypes.bool.isRequired,
  onFetchMembership: PropTypes.func.isRequired,
  onFetchPermissionsAndPermissionGroups: PropTypes.func.isRequired,
  onFetchRoles: PropTypes.func.isRequired,
};

SignupConnectBankContainer.defaultProps = {
  lastSubmittedFundingAccount: undefined,
};

export const mapStateToProps = (state) => ({
  connectBankManualOpen: modalIsOpenSelector(state, modalNameConnectBankManual),
  // NOTE: Checking roles.isFetching & permissions.isFetching doesn't work because on SignupConnectBankContainer's initial render.
  // Both attributes will initially be false causing the ConnectBankContainer to render prematurely and crashing.
  // We instead check if any role|permission id's exist.
  // Also, we can't check memberships.isLoading since the LiveChat component will trigger its own fetchMembership.
  // That causes the loading state to render twice.
  isLoading: isLoadingMembershipAndHasRolesAndPermissions(state),
  isSubmittingManualAccount: fundingAccountBankManualIsSubmittingSelector(state),
  isSubmittingTokenAccount: fundingAccountBankTokenIsSubmittingSelector(state),
  lastSubmittedFundingAccount: fundingAccountsLastSubmittedSelector(state),
  lastSubmittedManualAccount: !!fundingAccountBankManualLastSubmittedSelector(state),
  lastSubmittedTokenAccount: !!fundingAccountBankTokenLastSubmittedSelector(state),
});

export const mapDispatchToProps = {
  onFetchMembership: fetchSingleMembershipRequest,
  onFetchPermissionsAndPermissionGroups: fetchPermissionsRoutine,
  onFetchRoles: fetchRolesRoutine,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupConnectBankContainer));

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { InvoiceGeneratorTotalsSubtotal } from '../invoiceGenerator';

/**
 * Renders the invoice generator totals panel field for subtotal.
 * @param {ComponentProps} props
 * @param {string} props.name
 * @returns {StatelessComponent}
 */
const TotalsPanelSubtotal = ({ name, ...rest }) => (
  <Field {...rest} component={InvoiceGeneratorTotalsSubtotal} name={name} />
);

TotalsPanelSubtotal.propTypes = {
  name: PropTypes.string.isRequired,
};

export default TotalsPanelSubtotal;

import React from 'react';
import { LinkNewPage, WhiteSpace } from 'components';
import BaseHint from 'components/hintTypes/BaseHint';
import { SETTINGS_ACCOUNT_BALANCE_ROUTE } from 'constants/routes';
import { TooltipPlacement } from 'constants/tooltip';
import { Intent } from 'constants/ui';
import helpDocs from 'helpers/helpDocs';
import { parseCurrency } from 'helpers/numbers';
import ConditionalRestrictedPermissionTooltip from 'modules/auth/ConditionalRestrictedPermissionTooltip';
import {} from './BulkActionBalanceTooLowHint.types';
const BulkActionBalanceTooLowHint = ({ amount, isDisabledDueToPermissions, }) => (React.createElement(BaseHint, { content: React.createElement(React.Fragment, null,
        React.createElement("p", { className: "hint--text remove-margin-bottom font-color--black" },
            "Your total bill amount for Same Day and Next Day ACH payments exceeds your available balance.",
            React.createElement(WhiteSpace, null),
            "You may update the affected payments to Expedited ACH delivery option by editing or sending them individually.",
            React.createElement(WhiteSpace, null),
            React.createElement(LinkNewPage, { className: "font-sm font-weight--regular text-decoration--none font-color--blue-bold", href: helpDocs.BALANCE_TRANSACTIONS_ABOUT }, "Learn more about balance transactions.")),
        React.createElement("br", null),
        React.createElement("p", { className: "hint--text display--flex white-space-pre-wrap remove-margin-bottom" },
            "To avoid this issue in the future,",
            React.createElement(WhiteSpace, null),
            React.createElement(ConditionalRestrictedPermissionTooltip, { customization: { placement: TooltipPlacement.TOP }, useTooltip: isDisabledDueToPermissions },
                React.createElement(LinkNewPage, { className: "font-sm font-weight--regular text-decoration--none font-color--blue-bold", href: SETTINGS_ACCOUNT_BALANCE_ROUTE }, "add funds to your balance.")))), intent: Intent.DANGER, isCollapsed: true, isCollapsible: true, multiline: true, title: `Unselect ${parseCurrency(amount)} in Same Day and Next Day ACH payments to proceed` }));
export default BulkActionBalanceTooLowHint;

import _sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';

import { IntegrationServiceTypes } from 'constants/integration';

import { integrationIsConnected, integrationIsSyncing } from 'helpers/integrations';
import { allKeys, allValues, hasLength } from 'helpers/utility';

import { integrationConfigsSelector } from './integrationConfigsSelectors';

const getState = (state) => state.integrations;

/**
 * Selects all the integrations from the state
 * @param {object} state - Redux state
 */
export const integrationsByIdSelector = createSelector([getState], (integrationsState) => integrationsState.byId);

/**
 * Selects all the integrations + config data from the state
 * @param {object} state - Redux state
 */
export const allIntegrationsSelector = createSelector(
  [integrationsByIdSelector, integrationConfigsSelector],
  (integrations, integrationConfigs) => {
    const mapConfigToIntegration = {};
    allValues(integrationConfigs).forEach((config) => {
      mapConfigToIntegration[config.integration] = config;
    });

    return allValues(integrations).map((integration) => ({
      ...integration,
      ...mapConfigToIntegration[integration.id],
    }));
  },
);

/**
 * Selects all the integrations + config data from the state
 * @param {object} state - Redux state
 */
export const integrationsByApplicationSelector = createSelector([allIntegrationsSelector], (integrations) => {
  const integrationsByApp = {};

  integrations.forEach((integration) => {
    integrationsByApp[integration.application] = integration;
  });

  return integrationsByApp;
});

/**
 * Selects integrations by service ledger
 * @param {object} state - Redux state
 */
export const ledgerIntegrationsSelector = createSelector([allIntegrationsSelector], (integrations) =>
  integrations.filter((integration) => integration.service === IntegrationServiceTypes.SERVICE_LEDGER),
);

/**
 * Selects the integration that is the ledger from the state
 * @param {object} state - Redux state
 * @returns {Ledger}
 */
export const ledgerIntegrationSelector = createSelector([allIntegrationsSelector], (integrations) => {
  let ledger;

  integrations.forEach((integration) => {
    if (integration.service === IntegrationServiceTypes.SERVICE_LEDGER && typeof integration.connected === 'boolean') {
      ledger = integration;
    }
  });

  return ledger || {};
});

/**
 * Selects ledger integration's id
 * @function
 * @param {Object} state
 * @return {StringMaybe}
 */
export const ledgerIntegrationIdSelector = createSelector(
  [ledgerIntegrationSelector],
  (ledgerIntegration) => ledgerIntegration.id,
);

/**
 * Returns true if there is a ledger in the integration from the state
 * Assuming an integration object is always return and is only valid if it has keys
 * @param {object} state - Redux state
 */
export const hasLedgerIntegrationSelector = createSelector([ledgerIntegrationSelector], (ledger) =>
  hasLength(allKeys(ledger)),
);

/**
 * Returns ledger connected status
 * @param {object} state - Redux state
 * @return {boolean}
 */
export const isLedgerIntegrationConnectedSelector = createSelector(
  [ledgerIntegrationSelector],
  (ledger) => !!(ledger !== null && integrationIsConnected(ledger)),
);

/**
 * Returns whether the ledger integration must be reconnected.
 * @function
 * @param {Object} state - Redux state
 * @return {boolean}
 */
export const ledgerNeedsReconnectionSelector = createSelector(
  [hasLedgerIntegrationSelector, isLedgerIntegrationConnectedSelector],
  (hasLedgerIntegration, isLedgerConnected) => hasLedgerIntegration && !isLedgerConnected,
);

/**
 * Returns ledger application selector
 * @param {object} state - Redux state
 * @return {string}
 */
export const ledgerIntegrationApplicationSelector = createSelector(
  [ledgerIntegrationSelector],
  (ledger) => ledger.application,
);

/**
 * Returns ledger name.
 * @param {Object} state - Redux state
 * @return {?string} Ledger name or undefined.
 */
export const ledgerNameSelector = createSelector([ledgerIntegrationSelector], (ledger) => ledger.name);

/**
 * Returns ledger dateLastSync.
 * @param {Object} state - Redux state
 * @return {?string} Ledger date of last sync or undefined.
 */
export const ledgerDateLastSyncSelector = createSelector([ledgerIntegrationSelector], (ledger) => ledger.dateLastSync);

/**
 * Returns ledger fullName.
 * @param {ReduxState} state
 * @return {?string} Ledger full name or undefined.
 */
export const ledgerFullNameSelector = createSelector([ledgerIntegrationSelector], (ledger) => ledger.fullName);

/**
 * Returns whether the ledger is currently manually syncing.
 * @param {Object} state - Redux state
 * @return {boolean} Whether syncing
 */
export const ledgerIntegrationIsManuallySyncingSelector = createSelector([ledgerIntegrationSelector], (ledger) =>
  integrationIsSyncing(ledger.syncingStatus),
);

/**
 * sortedLedgerIntegrationsSelectors
 * @param {Object} state - Redux state
 * @return {LedgerIntegration[]}
 */
export const sortedLedgerIntegrationsSelectors = createSelector(
  [hasLedgerIntegrationSelector, ledgerIntegrationSelector, ledgerIntegrationsSelector],
  (hasLedgerIntegration, ledgerIntegration, ledgerIntegrations) => {
    const displayLedgerIntegrations = hasLedgerIntegration ? [ledgerIntegration] : ledgerIntegrations;
    return _sortBy(displayLedgerIntegrations, (integration) => integration.position);
  },
);

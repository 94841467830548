import PropTypes from 'prop-types';
import React from 'react';

import Text from 'components/text/Text';

import { TextColor, TextLineHeight } from 'constants/styles/typography';

import { getClassNames } from 'helpers/ui';

/**
 * General description text component.
 * @param {ComponentProps} props
 * @param {*} props.children
 * @return {StatelessComponent}
 * @constructor
 */
const Description = ({ children, ...rest }) => (
  <Text.Regular
    {...rest}
    className={getClassNames(rest, {
      description: true,
      'display--block': true,
    })}
  >
    {children}
  </Text.Regular>
);

Description.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Description.defaultProps = {
  color: TextColor.DARK_JORDAN,
  lineHeight: TextLineHeight.PARAGRAPH,
};

export default Description;

import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useLazyExporterQuery } from 'ducks/routableApi';

import { NOT_FOUND } from 'constants/routes';

import { downloadFileFromURL } from 'helpers/fileHelpers';
import { firstKey } from 'helpers/utility';

import { IsLoadingHasWrapper } from 'modules/isLoading';

/**
 * Component responsible for hitting the exporter api to get the download aws link
 * then it downloads the file. If it fails user is redirected to /not-found
 */
const ExporterComponent = () => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const [exporter, requestStatus] = useLazyExporterQuery();

  const fullpath = pathname + search;

  React.useEffect(() => {
    // make the api call to get the download url
    exporter(fullpath);
  }, [exporter, fullpath]);

  React.useEffect(() => {
    const handleError = () => {
      history.push(`/${NOT_FOUND}`);
    };

    const handleSuccess = async () => {
      const key = firstKey(requestStatus.data?.exportFile);
      const { filename, url } =
        requestStatus.data?.exportFile?.[key]?.attributes || {};

      try {
        await downloadFileFromURL(url, filename);
        window.close();
      } catch (error) {
        handleError();
      }
    };

    if (requestStatus.isError) {
      handleError();
    }

    if (requestStatus.isSuccess) {
      handleSuccess();
    }
    // eslint-disable-next-line
  }, [history, requestStatus.isError, requestStatus.isSuccess]);

  return <IsLoadingHasWrapper />;
};

export default ExporterComponent;

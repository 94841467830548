import { createLastUpdatedReducer } from 'store/redux';

import { UPDATE_ONBOARDING_COMPANY_SUCCESS, UPDATE_ONBOARDING_COMPANY_TYPE_SUCCESS } from 'types/currentCompany';
import { FETCH_ITEM_SUCCESS } from 'types/item';

export const successCases = [
  FETCH_ITEM_SUCCESS,
  UPDATE_ONBOARDING_COMPANY_SUCCESS,
  UPDATE_ONBOARDING_COMPANY_TYPE_SUCCESS,
];

const lastUpdatedReducer = createLastUpdatedReducer(successCases);

export default lastUpdatedReducer;

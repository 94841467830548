import React from 'react';

import { reduxFormFieldPropTypes, reduxFormFieldDefaultProps } from 'components/commonProps';
import { ReduxFormFieldWrapper } from 'components/reduxForm';

import SettingBar from './SettingBar';

/**
 * Wrapper for using the SettingBar as a redux-form field component.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 * @constructor
 */
const ReduxFormRenderSettingBar = ({ input, ...rest }) => (
  <ReduxFormFieldWrapper {...rest} Component={SettingBar} input={input} onSelectValue={input.onChange} />
);

ReduxFormRenderSettingBar.propTypes = {
  ...reduxFormFieldPropTypes,
};

ReduxFormRenderSettingBar.defaultProps = {
  ...reduxFormFieldDefaultProps,
};

export default ReduxFormRenderSettingBar;

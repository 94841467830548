import { inviteTeamMember } from 'actions/signUpFlow';

import { getCurrentCompanyId } from 'helpers/localStorage';

const submitInviteTeamMember = (form, dispatch, props) => {
  const companyId = getCurrentCompanyId();
  const adminRole = Object.values(props.roles).find((role) => role.name === 'administrator').id;
  dispatch(
    inviteTeamMember(
      {
        ...form,
        adminRole,
      },
      companyId,
    ),
  );
};

export default submitInviteTeamMember;

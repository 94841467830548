import ThunkFetch from 'helpers/thunkFetch';

import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { signupCreateAccountEndpoint } from 'services/api/signupEndpoints';

import {
  SUBMIT_SIGNUP_CREATE_ACCOUNT_FAILURE,
  SUBMIT_SIGNUP_CREATE_ACCOUNT_REQUEST,
  SUBMIT_SIGNUP_CREATE_ACCOUNT_SUCCESS,
} from 'types/signup';

const submitSignupCreateAccount = (submitPayload, successCallback) => {
  const t = new ThunkFetch({
    actions: {
      error: SUBMIT_SIGNUP_CREATE_ACCOUNT_FAILURE,
      request: SUBMIT_SIGNUP_CREATE_ACCOUNT_REQUEST,
      success: SUBMIT_SIGNUP_CREATE_ACCOUNT_SUCCESS,
    },
    endpoint: signupCreateAccountEndpoint,
    method: 'POST',
    payload: payloadToJSONAPI(submitPayload, 'membership', 'company', 'user'),
    successCallback,
  });

  return t.makeRequest();
};

export default submitSignupCreateAccount;

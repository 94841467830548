import PropTypes from 'prop-types';
import React from 'react';

import { Modal } from 'components';

import { getDeactivateTeamMemberModalSubtitle } from 'helpers/ui';

import { DeactivateTeamMemberModalBody } from './components';

/**
 * Modal which confirms that a RCTM would like to deactivate a team member. If the deactivation would result in items
 * getting "stuck" due to approval rules, we'll show them a warning.
 * @param {ComponentProps} props
 * @param {StringMaybe} [props.errorText]
 * @param {boolean} [props.isSubmitting]
 * @param {Membership} props.membership
 * @param {DeactivateTeamMemberModalState} props.modal
 * @param {function} props.onClose
 * @param {function} props.onHintClick
 * @param {function} props.onSubmit
 * @returns {StatelessComponent}
 */
const DeactivateTeamMemberModal = ({ errorText, isSubmitting, membership, modal, onClose, onHintClick, onSubmit }) => (
  <Modal
    body={
      <DeactivateTeamMemberModalBody
        errorText={errorText}
        isSubmitting={isSubmitting}
        onClose={onClose}
        onHintClick={onHintClick}
        onSubmit={onSubmit}
      />
    }
    hasInlineFooter
    modalHeader={getDeactivateTeamMemberModalSubtitle(membership)}
    modalState={modal.open}
    onCloseModal={onClose}
    testId="deactivate-team-member-modal"
  />
);

// TODO: DEV-11870
// These required need to be double checked because it caues dashboardmodals.test.js to fail.
// https://warrenpay.atlassian.net/browse/DEV-11870
DeactivateTeamMemberModal.propTypes = {
  errorText: PropTypes.string,
  isSubmitting: PropTypes.bool,
  membership: PropTypes.shape().isRequired,
  modal: PropTypes.shape({
    open: PropTypes.bool.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onHintClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

DeactivateTeamMemberModal.defaultProps = {
  errorText: undefined,
  isSubmitting: undefined,
};

export default DeactivateTeamMemberModal;

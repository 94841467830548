import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';

import { changeRepresentativeLock } from 'actions/signUpFlow';

import LogoutTray from 'modules/signup-v3/components/LogoutTray';

import {
  currentCompanyRepresentativesSelector,
  currentCompanyHasAllRepresentativeInfoSelector,
} from 'selectors/currentCompanySelectors';
import { currentMembershipIsRepresentativeSelector } from 'selectors/membershipsSelector';
import { activeViewSelector, isRepresentativeLockedSelector, getSaveStatus } from 'selectors/signupSelectors';

import ProgressBarStepperV2, { customWaitingStatus, getConfiguredSteps } from './components/ProgressBarStepperV2';
import { signupFlowForm } from './constants';
import steps from './constants/steps';
import { Container } from './FlowStepperLeft.styles';
import { triggerSkipStep } from './helpers/skipStep';

const formValueSelector = getFormValues(signupFlowForm);

const FlowStepperLeft = () => {
  const dispatch = useDispatch();
  const saveStatus = useSelector(getSaveStatus);

  const formValues = useSelector(formValueSelector);
  const activeView = useSelector(activeViewSelector);
  const businessRepresentatives = useSelector(currentCompanyRepresentativesSelector);
  const currentCompanyHasAllRepresentativeInfo = useSelector(currentCompanyHasAllRepresentativeInfoSelector);
  const currentMembershipIsRepresentative = useSelector(currentMembershipIsRepresentativeSelector);
  const currentCompanyRepresentatives = useSelector(currentCompanyRepresentativesSelector);
  const isRepresentativeLocked = useSelector(isRepresentativeLockedSelector);

  const handleClickStep = (index, stepId) => {
    triggerSkipStep(saveStatus, stepId);
  };

  useEffect(() => {
    if (currentCompanyRepresentatives.length && currentMembershipIsRepresentative) {
      dispatch(changeRepresentativeLock(false));
    }
  }, [currentCompanyRepresentatives.length, currentMembershipIsRepresentative, dispatch]);

  // lock the representative sub steps if the current user has not yet identified as a rep
  // show them otherwise so they can edit data
  const isLocked = isRepresentativeLocked && !currentMembershipIsRepresentative;

  const processedSteps = getConfiguredSteps({
    activeView,
    currentCompanyHasAllRepresentativeInfo,
    formValues,
    steps,
    isRepresentativeLocked: isLocked,
    currentMembershipIsRepresentative,
    businessRepresentatives,
  });

  return (
    <Container>
      <ProgressBarStepperV2 customStatus={customWaitingStatus} onClickStep={handleClickStep} steps={processedSteps} />
      <LogoutTray />
    </Container>
  );
};

export default FlowStepperLeft;

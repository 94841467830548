import React from 'react';
import { Intent } from 'constants/ui';
import { showSwal } from 'helpers/swal';
import { isGreaterOrEqual } from 'helpers/utility';
import { PaginationBlockCSVModal } from '../components';
export const shouldShowModalForAsyncExport = (totalResults, exportMaxRows) => isGreaterOrEqual(totalResults, exportMaxRows);
export const handlePaginationBlockCSVModalClick = ({ currentPageURL, onFetchExport, openNotification }) => {
    openNotification(React.createElement(React.Fragment, null, "We're processing your export request. We'll send you an email as soon as it's complete."), {
        intent: Intent.INFO,
    });
    onFetchExport(currentPageURL, 'text/csv');
};
export const handlePaginationBlockCSVButtonClick = ({ currentPageURL, emailAddress, exportMaxRowsNumber, onFetchExport, openNotification, totalResults, }) => {
    if (shouldShowModalForAsyncExport(totalResults, exportMaxRowsNumber)) {
        showSwal({
            Content: (React.createElement(PaginationBlockCSVModal, { emailAddress: emailAddress, onClick: () => {
                    handlePaginationBlockCSVModalClick({
                        currentPageURL,
                        onFetchExport,
                        openNotification,
                    });
                } })),
        });
    }
    else {
        onFetchExport(currentPageURL, 'text/csv');
    }
};

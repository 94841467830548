import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { FormControl } from 'components/form';
import { ReduxFormFieldRenderInput } from 'components/input';
import { SelectFieldV2 } from 'components/selectV2';

import { field } from 'constants/styles/formStyles';

import { postalCodeValidator } from 'data/validators/addressValidators';

import { getStateOrProvinceLabelByCountry, getStatesByCountry } from 'helpers/addressHelpers';
import { requiredValidator } from 'helpers/fieldValidation';

const CityRegionPostcodeInputs = ({ addressPath, country, isDisabled }) => {
  const stateOrProvince = getStateOrProvinceLabelByCountry({ country });

  return (
    <FormControl>
      <Field
        className={field.xl.third}
        component={ReduxFormFieldRenderInput}
        isDisabled={isDisabled}
        name={`${addressPath}city`}
        placeholder="City"
        type="text"
        validate={requiredValidator}
      />

      <Field
        className={field.xl.third}
        component={SelectFieldV2}
        isDisabled={isDisabled}
        label={stateOrProvince}
        name={`${addressPath}state`}
        options={getStatesByCountry({ country })}
        type="text"
        validate={requiredValidator}
      />

      <Field
        className={`${field.xl.third} right`}
        component={ReduxFormFieldRenderInput}
        isDisabled={isDisabled}
        name={`${addressPath}postalcode`}
        placeholder="Postal Code"
        type="text"
        validate={[requiredValidator, postalCodeValidator]}
      />
    </FormControl>
  );
};

CityRegionPostcodeInputs.propTypes = {
  addressPath: PropTypes.string,
  country: PropTypes.string,
  isDisabled: PropTypes.bool,
};

CityRegionPostcodeInputs.defaultProps = {
  addressPath: '',
  country: undefined,
  isDisabled: undefined,
};

export default CityRegionPostcodeInputs;

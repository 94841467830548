import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { currentExternalButtonStepSelector } from 'queries/currentWorkflowStepSelector';

import TermsOfServiceContentText from './TermsOfServiceContentText';

export const mapStateToProps = createStructuredSelector({
  buttonName: currentExternalButtonStepSelector,
});

export default connect(mapStateToProps)(TermsOfServiceContentText);

import PropTypes from 'prop-types';
import React from 'react';

import { InvoiceGeneratorTotalsPanel } from '../invoiceGenerator';

import './TotalsPanel.scss';

/**
 * Renders the invoice generator totals panel fields.
 * @param {ComponentProps} props
 * @param {StringMaybe} props.className
 * @returns {StatelessComponent}
 */
const TotalsPanel = (props) => <InvoiceGeneratorTotalsPanel {...props} />;

TotalsPanel.propTypes = {
  className: PropTypes.string,
};

TotalsPanel.defaultProps = {
  className: undefined,
};

export default TotalsPanel;

import { StaticCountryCode } from 'constants/countries';
import { getCountryNameFromCode } from 'helpers/countries';
import { isFundingAccountUSD } from 'helpers/funding';
import {} from 'interfaces/countries';
export const getInferredCountryCodeFromFundingData = (fundingAccount, fundingInfoInternational) => {
    if (isFundingAccountUSD(fundingAccount) && !fundingInfoInternational) {
        return StaticCountryCode.US;
    }
    return fundingInfoInternational?.countryCode;
};
export const getInferredCountryNameFromFundingData = ({ countryCodeMap, fundingAccount, fundingInfoInternational, }) => {
    const inferredCountryCode = getInferredCountryCodeFromFundingData(fundingAccount, fundingInfoInternational);
    return getCountryNameFromCode(countryCodeMap, inferredCountryCode);
};
export const getCountryAndCurrencyText = ({ countryCodeMap, fundingAccount, fundingInfoInternational, }) => {
    const { currencyCode } = Object(fundingAccount);
    const countryName = getInferredCountryNameFromFundingData({
        countryCodeMap,
        fundingAccount,
        fundingInfoInternational,
    });
    return `${countryName} / ${currencyCode}`;
};

import styled from 'styled-components';
export const VisualAid = styled.section `
  display: flex;
  flex-grow: 1;
  width: 100%;
  min-height: 100vh;
  background-color: var($grey--xx-light);

  .signup-hero {
    margin: 0 auto;
  }
`;

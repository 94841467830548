/**
 * Helper function for passing valueKey prop with the value
 * of 'value'
 * @param {string} value
 * @return {{valueKey: string}}
 */
export const getValueKey = (value = 'value') => ({ valueKey: value });

/**
 * Helper function for passing dataKey prop with either passed value or
 * the default value of 'data'
 * @param {string} value
 * @return {{dataKey: string}}
 */
export const getDataKey = (value = 'data') => ({ dataKey: value });

import PropTypes from 'prop-types';
import React from 'react';

import { FlexRow } from 'components';

import { ZIndexLayers } from 'constants/ui';

import { getIntentBackgroundColor, getIntentColor } from 'helpers/ui';
import { getZIndex } from 'helpers/zIndex';

/**
 * This is a label on the right side of the segment.
 * @param {ComponentProps} props
 * @param {string} props.statusLabel
 * @param {string} props.intent
 * @returns {StatelessComponent}
 */
const StatusLabel = ({ intent, statusLabel }) => (
  <FlexRow className="margin-left--m">
    <div
      className="segment--label"
      style={{
        zIndex: getZIndex(ZIndexLayers.ABOVE_BASE),
        background: getIntentBackgroundColor(intent),
      }}
    >
      <p style={{ color: getIntentColor(intent) }}>{statusLabel}</p>
    </div>
  </FlexRow>
);

StatusLabel.propTypes = {
  statusLabel: PropTypes.string.isRequired,
  intent: PropTypes.string,
};

StatusLabel.defaultProps = {
  intent: undefined,
};

export default StatusLabel;

import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'components/icon';

import { margin, sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

import { oneOfValuesFromObject } from 'helpers/propTypes';
import { getIntentColor, getIntentIconName } from 'helpers/ui';

/**
 * The Icon to display in a hint. It is normally standardized by Intent, but can be customized with props.
 * @param {ComponentProps} props
 * @param {Object} [props.customization={}]
 * @param {Intent} props.intent
 * @returns {StatelessComponent}
 */
const HintIcon = ({ customization, intent }) => (
  <Icon
    className="flex-shrink-0"
    color={getIntentColor(intent)}
    icon={getIntentIconName(intent)}
    marginRight={margin.extraMedium}
    marginTop={margin.smaller}
    size={sizes.iconSizes.XX_LARGE}
    {...customization}
  />
);

HintIcon.propTypes = {
  customization: PropTypes.shape({
    color: PropTypes.string,
    icon: PropTypes.string,
    size: PropTypes.number,
    // really any prop that Icon takes can be customized
  }),
  intent: oneOfValuesFromObject(Intent).isRequired,
};

HintIcon.defaultProps = {
  customization: undefined,
};

export default HintIcon;

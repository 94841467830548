/**
 * @fileOverview Defines propTypes and defaultProps that are shared/repeated among components.
 */

import PropTypes from 'prop-types';

import { inputNameOrNameProp } from 'helpers/propTypes';

// ================
// complex prop-types
// ================

/**
 * @type {PropType}
 */
export const reduxFormInputPropType = PropTypes.shape({
  checked: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  onFocus: PropTypes.func,
  // for value, "any" really is accurate
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
});

/**
 * @type {PropType}
 */
export const reduxFormMetaPropType = PropTypes.shape({
  active: PropTypes.bool,
  autofilled: PropTypes.bool,
  asyncValidating: PropTypes.bool,
  dirty: PropTypes.bool,
  dispatch: PropTypes.func,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.node),
  ]),
  form: PropTypes.string,
  // for initial, "any" really is accurate
  // eslint-disable-next-line react/forbid-prop-types
  initial: PropTypes.any,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  touched: PropTypes.bool,
  valid: PropTypes.bool,
  visited: PropTypes.bool,
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
});

// ================
// base props
// ================

/**
 * Shared propTypes definition.
 * All base components' propTypes should conform to this.
 * @type {PropTypesDefinition}
 */
export const basePropTypes = {
  className: PropTypes.string,
  setRef: PropTypes.oneOfType([
    PropTypes.shape({
      current: PropTypes.oneOfType([PropTypes.node, PropTypes.instanceOf(Element)]),
    }),
    PropTypes.func,
  ]),
  style: PropTypes.shape(),
};

/**
 * Shared defaultProps definition.
 * @type {DefaultPropsDefinition}
 */
export const baseDefaultProps = {
  className: undefined,
  setRef: undefined,
  style: {},
};

/**
 * Function that returns base default props, with overrides.
 * @type {DefaultPropsDefinition}
 */
export const makeBaseDefaultProps = (overrides = {}) => ({
  ...baseDefaultProps,
  ...overrides,
});

// ================
// form field props
// ================

/**
 * Shared form field propTypes definition.
 * All base form field components' propTypes should conform to this.
 * @type {PropTypesDefinition}
 */
export const fieldPropTypes = {
  ...basePropTypes,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.node),
  ]),
  hasErrors: PropTypes.bool,
  isDisabled: PropTypes.bool,
  input: reduxFormInputPropType, // optional, only things passed directly to Field.props.component will have
  meta: reduxFormMetaPropType, // optional, only things passed directly to Field.props.component will have
  name: inputNameOrNameProp,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.any,
};

/**
 * Shared form field defaultProps definition.
 * @type {DefaultPropsDefinition}
 */
export const fieldDefaultProps = {
  ...baseDefaultProps,
  defaultValue: undefined,
  errors: undefined,
  hasErrors: undefined,
  isDisabled: undefined,
  input: undefined,
  meta: undefined,
  name: undefined,
  onBlur: undefined,
  onFocus: undefined,
  onKeyPress: undefined,
  placeholder: undefined,
  isRequired: true,
  type: undefined,
  value: undefined,
};

/**
 * Function that returns field default props, with overrides.
 * @type {DefaultPropsDefinition}
 */
export const makeFieldDefaultProps = (overrides = {}) => ({
  ...fieldDefaultProps,
  ...overrides,
});

// ================
// redux-form field props
// ================

/**
 * Shared redux-form field propTypes definition.
 * All redux-form wrapper components' propTypes should conform to this.
 * @type {PropTypesDefinition}
 */
export const reduxFormFieldPropTypes = {
  ...fieldPropTypes,
  // required here, since these components are given to redux-form's Field.props.component
  input: reduxFormInputPropType.isRequired,
  // required here, since these components are given to redux-form's Field.props.component
  meta: reduxFormMetaPropType.isRequired,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
};

/**
 * Shared redux-form field defaultProps definition.
 * @type {DefaultPropsDefinition}
 */
export const reduxFormFieldDefaultProps = {
  ...fieldDefaultProps,
  validate: undefined,
};

/**
 * Function that returns redux form field default props, with overrides.
 * @type {DefaultPropsDefinition}
 */
export const makeReduxFormFieldDefaultProps = (overrides = {}) => ({
  ...reduxFormFieldDefaultProps,
  ...overrides,
});

/**
 * Prop types for use with our radio buttons.
 * @see {Radio}
 * @type {PropTypesDefinition}
 */
export const radioPropTypes = {
  ...fieldPropTypes,
  content: PropTypes.node,
  id: PropTypes.string.isRequired,
  isHorizontalLayout: PropTypes.bool,
  optionText: PropTypes.string.isRequired,
  subText: PropTypes.string,
  tooltipProps: PropTypes.shape(),
};

/**
 * Default props for use with our radio buttons.
 * @see {Radio}
 * @type {DefaultPropsDefinition}
 */
export const radioDefaultProps = makeFieldDefaultProps({
  content: undefined,
  isHorizontalLayout: undefined,
  subText: undefined,
  tooltipProps: undefined,
});

// ================
// Other component props
// ================
export const iconTooltipPropTypes = PropTypes.shape({
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  contentMargin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
  iconColor: PropTypes.string,
  iconProps: PropTypes.shape({}),
  title: PropTypes.string,
  tooltipClassName: PropTypes.string,
  tooltipProps: PropTypes.shape({}),
});

import React from 'react';
import { ItemKinds } from 'enums/items';
import { ExternalReceiptBill, ExternalReceiptPayment } from './components';
import {} from './ExternalReceiptSingleItem.types';
const ExternalReceiptSingleItem = ({ attachments, canNextPage, canPreviousPage, enableLineItems, item, payment, onGoToPage, onViewBills, onViewLineItems, pageCount, pageIndex, partnershipCountryCode, }) => {
    if (item.kind === ItemKinds.PAYABLE) {
        return (React.createElement(ExternalReceiptBill, { attachments: attachments, item: item, partnershipCountryCode: partnershipCountryCode }));
    }
    return (React.createElement(ExternalReceiptPayment, { amount: item.amountPartner, attachments: attachments, canNextPage: canNextPage, canPreviousPage: canPreviousPage, currencyCode: item.currencyCodeReceiver, enableLineItems: enableLineItems, item: item, onGoToPage: onGoToPage, onViewBills: onViewBills, onViewLineItems: onViewLineItems, pageCount: pageCount, pageIndex: pageIndex, payment: payment }));
};
export default ExternalReceiptSingleItem;

import styled from 'styled-components';
export const FieldRadioGroup = styled.div.withConfig({
    displayName: 'FieldRadioGroup',
}) `
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

import { convertExternalRoutine } from 'actions/routines/auth';

import { gatherReducerCasesFromRoutines } from 'helpers/routine';

import { createIsSubmittingReducer } from 'store/redux';

export const { failureCases, requestCases, successCases } = gatherReducerCasesFromRoutines([convertExternalRoutine]);

export const finishCases = [...failureCases, ...successCases];

export default createIsSubmittingReducer(finishCases, requestCases);

import { allKeys } from 'helpers/utility';

import * as types from 'types/transactions';

import initialState from './initialState';

export default (state = initialState.allIds, action) => {
  const { type } = action;

  switch (type) {
    case types.FETCH_TRANSACTIONS_SUCCESS:
      return Array.from(new Set([...state, ...allKeys(action.payload.item)]));

    default:
      return state;
  }
};

import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';

import * as types from 'types/itemMember';

export const addItemMemberToItemRoutine = createRoutine(types.ADD_ITEM_MEMBER_TO_ITEM);
export const addItemMemberToItemHandler = bindRoutineToReduxForm(addItemMemberToItemRoutine);

export const createItemMemberForExistingItemRoutine = createRoutine(types.CREATE_ITEM_MEMBER_FOR_EXISTING_ITEM);
export const createItemMemberForExistingItemHandler = bindRoutineToReduxForm(createItemMemberForExistingItemRoutine);

export const updateItemMemberOnItemRoutine = createRoutine(types.UPDATE_ITEM_MEMBER_ON_ITEM);

import { getFormattedFeatureFlagName } from 'helpers/featureFlags';
import { allValues } from 'helpers/utility';

/**
 * Given a feature flag object returned in an API response, grabs the nested flag name property.
 * @param {Object} flagObject
 * @return {StringMaybe}
 */
export const getFeatureFlagFromResponseObj = (flagObject) => flagObject?.attributes?.featureFlag;

/**
 * Given the array of feature flags, transforms it into an object where each flag is the key
 * and values are set to true.
 * @param {Object} responseData
 * @return {EnabledKeys}
 */
export const flagsResponseToEnabledKeys = ({ featureFlag } = {}) => {
  const flagObjects = allValues(featureFlag);

  return flagObjects.reduce((obj, flagData) => {
    const flagName = getFeatureFlagFromResponseObj(flagData);

    if (flagName) {
      return {
        ...obj,
        [getFormattedFeatureFlagName(flagName)]: true,
      };
    }

    return obj;
  }, {});
};

/**
 * Given a feature flags response, maps the data to an exact copy, except with an ID attached.
 * This ID is actually irrelevant, but our response formatters have to overwrite repeats.
 * @param {Object} response
 * @return {Object}
 */
export const attachIdToFlagResponseObjects = (response) => ({
  ...response,
  data: response.data.map((flagObject, idx) => ({
    ...flagObject,
    id: idx,
  })),
});

import { all, call, put, spawn, take } from 'redux-saga/effects';
import { handleRequestErrors } from 'actions/errors';
import { webhooksFetchRoutine, webhookSettingsDeleteRoutine, webhookSettingsFetchRoutine, webhookSettingsSubmitRoutine, webhookSettingsTestRoutine, webhookSettingsUpdateRoutine, } from 'actions/routines/webhooks';
import { showErrorUi, showSuccessUi } from 'actions/ui';
import { openWebhookTestModal } from 'actions/webhooks';
import { confirmAlert } from 'helpers/confirmAlert';
import { getRequestErrorAction, parseCaughtError, parseErrorResponse } from 'helpers/errors';
import {} from 'interfaces/actions';
import {} from 'interfaces/fetchService';
import {} from 'interfaces/request';
import {} from 'interfaces/webhooks';
import * as api from './api';
export function* fetchWebhooks(action) {
    yield put(webhooksFetchRoutine.request());
    let errorData = {};
    try {
        const { payload: params } = action;
        const response = yield call(api.fetchWebhooks, params);
        if (response.ok) {
            yield put(webhooksFetchRoutine.success(response.data));
            return;
        }
        errorData = parseErrorResponse(response);
    }
    catch (error) {
        errorData = parseCaughtError(error);
    }
    yield put(handleRequestErrors(webhooksFetchRoutine.failure, errorData));
}
export function* deleteWebhookSettings(action) {
    yield put(webhookSettingsDeleteRoutine.request());
    let errorData = {};
    try {
        const isAgreed = yield call(confirmAlert, 'Are you sure you want to delete your webhook subscription?');
        if (!isAgreed) {
            return;
        }
        const { payload } = action;
        const response = yield call(api.deleteWebhookSettings, payload);
        if (response.ok) {
            yield all([put(webhookSettingsFetchRoutine.trigger()), put(showSuccessUi('Subscription deleted'))]);
            return;
        }
        errorData = parseErrorResponse(response);
    }
    catch (error) {
        errorData = parseCaughtError(error);
    }
    yield put(handleRequestErrors(webhookSettingsDeleteRoutine.failure, errorData));
}
export function* fetchWebhookSettings() {
    yield put(webhookSettingsFetchRoutine.request());
    let errorData = {};
    try {
        const response = yield call(api.fetchWebhookSettings);
        if (response.ok) {
            yield put(webhookSettingsFetchRoutine.success(response.data));
            return;
        }
        errorData = parseErrorResponse(response);
    }
    catch (error) {
        errorData = parseCaughtError(error);
    }
    yield put(handleRequestErrors(webhookSettingsFetchRoutine.failure, errorData));
}
export function* submitWebhookSettings(action) {
    yield put(webhookSettingsSubmitRoutine.request());
    let submitErrors = {};
    try {
        const { payload } = action;
        const response = yield call(api.submitWebhookSettings, payload);
        if (response.ok) {
            yield all([
                put(webhookSettingsSubmitRoutine.success(response.data)),
                put(openWebhookTestModal()),
            ]);
            return;
        }
        submitErrors = parseErrorResponse(response);
    }
    catch (error) {
        submitErrors = parseCaughtError(error);
    }
    const errorAction = getRequestErrorAction(submitErrors);
    yield put(errorAction(webhookSettingsSubmitRoutine.failure, submitErrors));
}
export function* testWebhookSettings(action) {
    yield put(webhookSettingsTestRoutine.request());
    let submitErrors = {};
    try {
        yield put(openWebhookTestModal());
        const { payload } = action;
        const response = yield call(api.testWebhookSettings, payload);
        if (response.ok) {
            yield put(webhookSettingsTestRoutine.success(response.data));
            return;
        }
        submitErrors = parseErrorResponse(response);
    }
    catch (error) {
        submitErrors = parseCaughtError(error);
    }
    const errorAction = getRequestErrorAction(submitErrors);
    yield put(errorAction(webhookSettingsTestRoutine.failure, submitErrors));
}
export function* updateWebhookSettings(action) {
    yield put(webhookSettingsUpdateRoutine.request());
    let errorData = {};
    try {
        const { payload } = action;
        const { isPaused } = payload;
        const pausedOrUnpaused = isPaused ? 'paused' : 'unpaused';
        const response = yield call(api.updateWebhookSettings, payload);
        if (response.ok) {
            yield all([
                put(webhookSettingsUpdateRoutine.success(response.data)),
                put(showSuccessUi(`Subscription ${pausedOrUnpaused}`)),
            ]);
            return;
        }
        errorData = parseErrorResponse(response);
    }
    catch (error) {
        errorData = parseCaughtError(error);
    }
    yield all([
        put(handleRequestErrors(webhookSettingsUpdateRoutine.failure, errorData)),
        put(showErrorUi('Something went wrong')),
    ]);
}
export function* watch() {
    while (true) {
        const action = yield take([
            webhookSettingsDeleteRoutine.TRIGGER,
            webhooksFetchRoutine.TRIGGER,
            webhookSettingsFetchRoutine.TRIGGER,
            webhookSettingsSubmitRoutine.TRIGGER,
            webhookSettingsTestRoutine.TRIGGER,
            webhookSettingsUpdateRoutine.TRIGGER,
        ]);
        switch (action.type) {
            case webhooksFetchRoutine.TRIGGER:
                yield spawn(fetchWebhooks, action);
                break;
            case webhookSettingsDeleteRoutine.TRIGGER:
                yield spawn(deleteWebhookSettings, action);
                break;
            case webhookSettingsFetchRoutine.TRIGGER:
                yield spawn(fetchWebhookSettings);
                break;
            case webhookSettingsSubmitRoutine.TRIGGER:
                yield spawn(submitWebhookSettings, action);
                break;
            case webhookSettingsTestRoutine.TRIGGER:
                yield spawn(testWebhookSettings, action);
                break;
            case webhookSettingsUpdateRoutine.TRIGGER:
                yield spawn(updateWebhookSettings, action);
                break;
            default:
                yield null;
        }
    }
}
export default function* webhooks() {
    yield watch();
}

import { useToastController } from '@routable/gross-ds';
import { queryContext } from '@routable/shared';
import { useMutation } from '@tanstack/react-query';
import { useOverlayTriggerState } from 'react-stately';
import {} from '../../models';
import { partnershipsService } from '../../services';
export const usePartnershipsListExport = (partnershipType, search, { addNotification, onDownload, maxRecords = Infinity, totalRecords = 1 }) => {
    const toast = useToastController();
    const dialogState = useOverlayTriggerState({});
    const { mutateAsync, isLoading } = useMutation({
        context: queryContext,
        mutationFn: async () => partnershipsService.export({ partnershipType, search }),
    });
    const handleAsyncExport = async () => {
        try {
            await mutateAsync();
            dialogState.close();
            addNotification?.("We're processing your export request. We'll send you an email as soon as it's complete.", {
                intent: 'info',
            });
        }
        catch {
            dialogState.close();
            toast.addToast({ type: 'critical', message: 'Something went wrong during your export...' });
        }
    };
    const triggerExport = async () => {
        try {
            if (totalRecords >= maxRecords) {
                dialogState.open();
            }
            else {
                const response = await mutateAsync();
                onDownload?.(response);
            }
        }
        catch {
            toast.addToast({ type: 'critical', message: 'Something went wrong during your export...' });
        }
    };
    return {
        onAsyncExport: handleAsyncExport,
        triggerExport,
        exportDialogState: dialogState,
        isExporting: isLoading,
    };
};

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate file. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 */

/* eslint-disable no-var, import/no-mutable-exports */
var storeAccessor = {
  getState: undefined,
  dispatch: undefined,
};
/* eslint-enable no-var, import/no-mutable-exports */

const initStoreAccessor = (store) => {
  storeAccessor.dispatch = store.dispatch;
  storeAccessor.getState = () => {
    const state = store.getState();
    // the state object returned is read only; attempts to reconfigure
    // any properties of `state` should throw
    Object.freeze(state);
    return state;
  };
};

export { storeAccessor };
export default initStoreAccessor;

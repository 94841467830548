import PropTypes from 'prop-types';
import React from 'react';

import Checkbox from 'components/checkbox';

/**
 * Checkbox used in external update payment form
 * @param {ComponentProps} props
 * @param {Company.name} props.companyName
 * @param {CurrencyCode} props.currencyCode
 * @param {string} [props.paymentText="pending"]
 * @returns {StatelessComponent}
 */
const ApplyToFuturePendingPaymentsCheckbox = ({ companyName, currencyCode, paymentsText, ...rest }) => (
  <Checkbox
    content={
      <>{`Use this payment method to accept all ${paymentsText} ${currencyCode} payments from ${companyName}`}</>
    }
    id="apply-to-future-pending-payments"
    name="apply-to-future-pending-payments"
    {...rest}
  />
);

ApplyToFuturePendingPaymentsCheckbox.propTypes = {
  companyName: PropTypes.string.isRequired,
  currencyCode: PropTypes.string.isRequired,
  paymentsText: PropTypes.string,
};

ApplyToFuturePendingPaymentsCheckbox.defaultProps = {
  paymentsText: 'pending',
};

export default ApplyToFuturePendingPaymentsCheckbox;

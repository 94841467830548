import { createSelector } from 'reselect';

import { UserType } from 'enums/user';

import { or } from 'helpers/utility';

import { isFetchingCountriesSelector } from 'selectors/countriesSelectors';
import { isFetchingCurrenciesSelector } from 'selectors/currenciesSelectors';
import { isPartnershipRequestLoadingSelector } from 'selectors/partnershipRequestSelectors';
import { isFetchingPermissionsSelector } from 'selectors/permissionsSelectors';
import { isFetchingRolesSelector } from 'selectors/rolesSelectors';

import { companyFromQuerySelector } from './companyCompoundSelectors';
import { isFetchingSupportedCountriesSelector } from './fundingSupportedCountriesCompoundSelectors';

export const isExternalFlowLoading = createSelector(
  [
    isFetchingCountriesSelector,
    isFetchingCurrenciesSelector,
    isFetchingPermissionsSelector,
    isFetchingRolesSelector,
    isPartnershipRequestLoadingSelector,
    isFetchingSupportedCountriesSelector,
  ],
  (...args) => Boolean(or(...args)),
);

/**
 * Selects the Substitute W-9 Form initial values
 * @type {StandardSelector}
 * @param {import('interfaces/redux').ReduxState} state
 * @returns {Object}
 */
export const externalSubstituteW9FormInitialValuesSelector = createSelector([companyFromQuerySelector], (company) => ({
  company: { companyType: company?.companyType || UserType.BUSINESS },
  hasTaxExemptions: false,
}));

import { createIsSubmittingReducer } from 'store/redux';

import {
  SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_REQUEST,
  MODAL_CONNECT_BANK_MANUAL_OPEN,
  SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_FAILURE,
  SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_SUCCESS,
} from 'types/connectBank';

export const finishCases = [
  MODAL_CONNECT_BANK_MANUAL_OPEN,
  SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_FAILURE,
  SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_SUCCESS,
];
export const requestCases = [SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_REQUEST];

const submitReducer = createIsSubmittingReducer(finishCases, requestCases);

export default submitReducer;

import { queryClient } from '@routable/shared';
import { call, delay, put, select, takeLatest, takeLeading } from 'redux-saga/effects';

import { handleRequestErrors } from 'actions/errors';
import * as actions from 'actions/quickswitchActions';
import { disconnectSocket } from 'actions/socket';

import { QUICKSWITCH_TO } from 'constants/cookies';
import { LOGIN, QUICKSWITCH } from 'constants/routes';

import { hasAuthToken } from 'helpers/auth';
import { setCookie } from 'helpers/cookies';
import { parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { handleClearLocalStorage } from 'helpers/localStorage';
import { hasProperty } from 'helpers/objects';
import { sagaWatcher } from 'helpers/saga';
import { LogLevel, systemLogger } from 'helpers/systemLogger';
import { getDomainWithOrWithoutCompanyNamespace } from 'helpers/urls';
import { isValueEmpty } from 'helpers/utility';

import history from 'modules/app/history';
import { SEARCH_DEBOUNCE_DELAY } from 'modules/quickswitch/constants';

import * as authApi from 'sagas/auth/api';

import { currentUserSelector } from 'selectors/currentUserSelectors';
import { searchTermSelector } from 'selectors/quickswitchSelectors';

import { Auth0Client } from 'services/auth0';

import { QuickswitchActionType } from 'types/quickswitch';

import { apiFetchMembershipList, switchMembershipRequest } from './api';
import { mapResponseToMembership } from './helpers';

export function* fetchMembershipList() {
  try {
    const response = yield call(apiFetchMembershipList);
    if (response.ok) {
      yield put(actions.updateMembershipList(mapResponseToMembership(response.data)));
      yield put(actions.setTotalNumberOfMemberships(response.data.meta.pagination.count));
    } else {
      yield put(actions.updateMembershipListError());
    }
  } catch (error) {
    yield put(actions.updateMembershipListError());
  }
}

export function* fetchMembershipSearch(action) {
  const {
    payload: { searchTerm },
  } = action;
  try {
    const response = yield call(apiFetchMembershipList, searchTerm);
    if (response.ok) {
      yield put(actions.updateMembershipList(mapResponseToMembership(response.data)));
    } else {
      yield put(actions.updateMembershipListError());
    }
  } catch (error) {
    yield put(actions.updateMembershipListError());
  }
}

export function* fetchMemberships(debounce = false) {
  const searchTerm = yield select(searchTermSelector);
  if (debounce) {
    yield delay(SEARCH_DEBOUNCE_DELAY);
  }
  if (isValueEmpty(searchTerm)) {
    yield* fetchMembershipList();
  } else {
    yield* fetchMembershipSearch({ payload: { searchTerm } });
  }
}

export function* executeSwitchMembershipRequest(action) {
  const {
    payload: { membershipId },
  } = action;
  try {
    const response = yield call(switchMembershipRequest, membershipId);
    if (response.ok) {
      yield put(actions.switchToBrandedSubdomain(response.data.meta));
    } else {
      yield put(actions.closeQuickswitchModal());
      yield put(actions.openQuickswitchErrorModal());
    }
  } catch (error) {
    yield put(actions.closeQuickswitchModal());
    yield put(actions.openQuickswitchErrorModal());
    yield call(systemLogger.log, { level: LogLevel.ERROR, error });
  }
}

export function* switchToBrandedSubdomain(action) {
  const {
    payload: { meta },
  } = action;
  const { companyNamespace, companyName } = meta;
  const { email } = yield select(currentUserSelector);

  const shouldAuthenticateWithSSO = !hasProperty(meta, 'userAuthToken');
  const isAuthenticatedWithSSO = Auth0Client.isAuthenticated;
  const redirectURL = `${getDomainWithOrWithoutCompanyNamespace(companyNamespace)}/${QUICKSWITCH}`;
  const encodedData = encodeURIComponent(
    JSON.stringify({
      ...meta,
      shouldAuthenticateWithSSO,
      isAuthenticatedWithSSO,
      userEmail: email,
    }),
  );

  yield put(disconnectSocket());

  // Use a short-lived cookie to store the company name and namespace, allowing for rendering of the
  // splash screen and success toast on the destination subdomain.
  setCookie(QUICKSWITCH_TO, JSON.stringify({ companyNamespace, companyName }), 'none');
  yield call(() => queryClient.clear());
  yield call((...args) => window.location.replace(...args), `${redirectURL}?data=${encodedData}`);
}

export function* reLogin() {
  const isAuthenticated = hasAuthToken();

  // If the user is still authenticated, logout.
  if (isAuthenticated) {
    let submitErrors = {};
    try {
      const response = yield call(authApi.logout);
      if (response.ok) {
        yield put(actions.reLoginSuccess());
        return;
      }
      submitErrors = parseErrorResponse(response);
    } catch (error) {
      submitErrors = parseCaughtError(error);
    }
    yield put(handleRequestErrors(actions.reLoginError, submitErrors));
    return;
  }

  yield put(actions.reLoginSuccess());
}

export function* reLoginSuccess() {
  // cleanup and go to login page
  yield put(actions.closeQuickswitchErrorModal());
  yield put(disconnectSocket());
  yield call(handleClearLocalStorage);
  yield call(history.push, `/${LOGIN}`);
}

export default function* quickswitchSagas() {
  yield takeLatest(QuickswitchActionType.SET_SEARCH_TERM, () => fetchMemberships(true));
  // Prevent multiple api requests when a user multi-clicks to switch memberships
  yield takeLeading(QuickswitchActionType.SWITCH_MEMBERSHIP_REQUEST, executeSwitchMembershipRequest);

  yield sagaWatcher([
    {
      type: QuickswitchActionType.SWITCH_RESET_STATE,
      saga: fetchMembershipList,
    },
    {
      type: QuickswitchActionType.FETCH_MEMBERSHIP_LIST_REQUEST,
      saga: fetchMembershipList,
    },
    {
      type: QuickswitchActionType.FETCH_MEMBERSHIP_SEARCH_REQUEST,
      saga: fetchMembershipSearch,
    },
    {
      type: QuickswitchActionType.SWITCH_TO_BRANDED_SUBDOMAIN,
      saga: switchToBrandedSubdomain,
    },
    {
      type: QuickswitchActionType.RE_LOGIN,
      saga: reLogin,
    },
    {
      type: QuickswitchActionType.RE_LOGIN_SUCCESS,
      saga: reLoginSuccess,
    },
  ]);
}

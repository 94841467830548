import {} from '@routable/shared';
import { useQuery } from '@tanstack/react-query';
import { queryContext } from '@routable/shared';
import { generateKeys } from '../helpers';
import { vendorRiskChecksApi } from '../services';
export const useVendorLatestRiskReport = (partnershipId) => {
    const queryKey = generateKeys.latestReport(partnershipId);
    return useQuery({
        context: queryContext,
        enabled: !!partnershipId,
        queryKey,
        queryFn: async () => vendorRiskChecksApi.getLatestReport(partnershipId),
        retry: false,
    });
};

import { Panel, DetailList, useMaskContent, Button } from '@routable/gross-ds';
import React from 'react';
import { ChevronUp, Finance } from '@carbon/icons-react';
import { Status } from './Status.component';
export const PaymentMethodDetails = ({ type, statusText, open, defaultOpen, children, icon = React.createElement(Finance, null), title, accountNumber, onToggle, 'data-testid': testId = title, }) => {
    const sub = useMaskContent({ content: accountNumber, lastFour: true });
    const [internalOpen, setInternalOpen] = React.useState(defaultOpen);
    const visible = open ?? internalOpen;
    const handleToggle = () => {
        onToggle?.(!visible);
        setInternalOpen((v) => !v);
    };
    return (React.createElement(Panel, { className: "max-w-lg @container overflow-hidden" },
        React.createElement("div", { className: "flex justify-between items-center p-5 text-grey-60 pb-0" },
            icon,
            React.createElement(Button, { intent: "secondary", variant: "floating", className: "!p-0", "aria-label": "toggle showing payment methods", onPress: handleToggle, "data-testid": `${testId}:toggle` },
                React.createElement(ChevronUp, { className: `transition-all ${visible ? '' : 'rotate-180'}` }))),
        React.createElement("div", { className: "px-5 flex-col py-4" },
            React.createElement("span", { className: "font-semibold text-sm" }, title),
            React.createElement("span", { className: "text-grey-70 text-xs" }, sub)),
        visible && (React.createElement(DetailList, { variant: "highContrast", className: "flex-col [&>*]:px-5 [&>*]:py-4 overflow-hidden divide-grey-20 divide-y border-t border-grey-20", "data-testid": `${testId}:list` }, children)),
        React.createElement(Status, { type: type, statusText: statusText })));
};

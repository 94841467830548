import { DateFormats } from '@routable/shared';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDatePicker from 'react-datepicker';

import DatePickerCustomInput from 'components/form/CustomDatePickerInput';

import 'react-datepicker/dist/react-datepicker.css';

const DatePickerForReduxForm = (props) => {
  const {
    allowSameDay,
    dateFormat,
    disabled,
    hideLabel,
    id,
    input,
    isRequired,
    meta,
    minDate,
    placeholderText,
    popperModifiers,
    popperPlacement,
    selected,
    ...rest
  } = props;

  if (!input) {
    return null;
  }

  // The backend is providing dateFormats (displayDateFormat) with 'MM/DD/YYYY' and date-fns hates it.
  const dateFormatOverride = dateFormat?.replace('DD', 'dd')?.replace('YYYY', 'yyyy');

  const isInputValueValid = dayjs.isDayjs(input?.value) ? input.value.isValid() : input?.value;
  const coercedSelectedDate = (selected || isInputValueValid) && dayjs(selected || input?.value).toDate();

  return (
    <ReactDatePicker
      {...rest}
      allowSameDay={allowSameDay}
      customInput={<DatePickerCustomInput hideLabel={hideLabel} input={input} isRequired={isRequired} meta={meta} />}
      dateFormat={dateFormatOverride}
      disabled={disabled}
      id={id}
      isRequired={isRequired}
      minDate={minDate}
      name={input.name}
      onChange={(val) => {
        input.onChange(
          val ? dayjs(val).format(rest.showTimeSelect ? dateFormat : DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY) : null,
        );
      }}
      placeholderText={placeholderText}
      popperModifiers={popperModifiers}
      popperPlacement={popperPlacement}
      selected={coercedSelectedDate}
      todayButton="Today"
    />
  );
};

DatePickerForReduxForm.propTypes = {
  allowSameDay: PropTypes.bool,
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool,
  id: PropTypes.string,
  input: PropTypes.shape().isRequired,
  isRequired: PropTypes.bool,
  meta: PropTypes.shape().isRequired,
  minDate: PropTypes.shape(),
  placeholderText: PropTypes.string,
  popperModifiers: PropTypes.shape(),
  popperPlacement: PropTypes.string,
  selected: PropTypes.shape(),
};

DatePickerForReduxForm.defaultProps = {
  allowSameDay: true,
  hideLabel: undefined,
  disabled: undefined,
  id: undefined,
  isRequired: undefined,
  minDate: undefined,
  placeholderText: 'Select date',
  popperModifiers: undefined,
  popperPlacement: undefined,
  selected: undefined,
};

export default DatePickerForReduxForm;

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { openConfirmTaxFilingInfoModal } from 'actions/taxes';

import { taxFilingDetailsSelector } from 'queries/taxesCompoundSelectors';

import ReconfirmTaxesSection from './ReconfirmTaxesSection';

const mapStateToProps = createStructuredSelector({
  filingDetails: taxFilingDetailsSelector,
});

const mapDispatchToProps = {
  openModal: openConfirmTaxFilingInfoModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReconfirmTaxesSection);

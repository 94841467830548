import PropTypes from 'prop-types';
import React from 'react';

import './ActionNavSearchPlaceholder.scss';

const ActionNavSearchPlaceholder = ({ searchData, searchPlaceholder }) => {
  if (!searchData || !searchData.execute) {
    return null;
  }

  return (
    <div className="action-nav-search-placeholder">
      <span className="action-nav-search-placeholder--search">Search</span>

      {searchPlaceholder && <span className="action-nav-search-placeholder--placeholder">{searchPlaceholder}</span>}
    </div>
  );
};

ActionNavSearchPlaceholder.propTypes = {
  searchData: PropTypes.shape(),
  searchPlaceholder: PropTypes.string,
};

ActionNavSearchPlaceholder.defaultProps = {
  searchData: undefined,
  searchPlaceholder: undefined,
};

export default ActionNavSearchPlaceholder;

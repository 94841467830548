import { createSelector } from 'reselect';

import { FundingCustomerFailedStatuses, FundingCustomerStatuses } from 'constants/funding';

import { getCurrentMembershipId } from 'helpers/localStorage';
import { isBusinessRepresentative } from 'helpers/memberships';
import { allValues, isEqual, isIncluded } from 'helpers/utility';

import { addressesSelector } from 'selectors/addressesSelectors';
import { idSelector } from 'selectors/globalSelectors';

import { rolesByIdSelector } from './rolesSelectors';

/**
 * Top level memberships selector
 * @param {object} state
 * @return {object}
 */
export const getMembershipSelector = (state) => state?.memberships;

/**
 * Selects the isDisabling bool for memberships from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const isDisablingMembershipSelector = createSelector(
  [getMembershipSelector],
  (memberships) => memberships.isDisabling,
);

/**
 * Selects the isFetching bool for memberships from the state
 * @param {object} state - Redux state
 */
export const isFetchingMembershipSelector = createSelector(
  [getMembershipSelector],
  (memberships) => memberships.isFetching,
);

/**
 * Selects the isReactivating bool for memberships from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const isReactivatingMembershipSelector = createSelector(
  [getMembershipSelector],
  (memberships) => memberships.isReactivating,
);

/**
 * Selects the isUpdating bool for memberships from the state
 * @param {object} state - Redux state
 */
export const isUpdatingMembershipSelector = createSelector(
  [getMembershipSelector],
  (memberships) => memberships.isUpdating,
);

/**
 * Selects the lastUpdated for memberships from the state
 * @param {object} state - Redux state
 */
export const lastUpdatedMembershipSelector = createSelector(
  [getMembershipSelector],
  (memberships) => memberships.lastUpdated,
);

/**
 * Selects the membership errors from the state
 * @param {object} state - Redux state
 */
export const membershipErrorsSelector = createSelector([getMembershipSelector], (memberships) => memberships.errors);

/**
 * Selects the memberships from the state
 * @param {object} state - Redux state
 */
export const membershipsByIdSelector = createSelector([getMembershipSelector], (memberships) => memberships?.byId);

/**
 * Selects all memberships from the state
 * @param {object} state - Redux state
 * @return {Membership[]}
 */
export const allMembershipsSelector = createSelector([membershipsByIdSelector], (memberships) =>
  allValues(memberships),
);

/**
 * Selects the current membership id from localStorage
 * @param {object} _state - Redux state
 * @return {string} membership id
 */
// since this looks like a selector, and is used as one, i put state into it as unused
// so we could hopefully move towards putting into state properly
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const currentMembershipIdSelector = (_state) => getCurrentMembershipId();

/**
 * Selects a membership from the state
 * @param {object} state - Redux state
 */
export const membershipSelector = createSelector(
  [membershipsByIdSelector, idSelector],
  (memberships, membershipId) => memberships[membershipId],
);

/**
 * Selects the current membership
 * @param {object} state - Redux state
 */
export const currentMembershipSelector = createSelector(
  [membershipsByIdSelector, currentMembershipIdSelector],
  (memberships, membershipId) => memberships?.[membershipId],
);

export const isCurrentMembershipRoleSelector = createSelector(
  [currentMembershipSelector, idSelector],
  (currentMembership, roleId) => currentMembership?.role === roleId,
);

/**
 * Check if the current membership is a business representative
 * TODO SUF: we should rename this so it's clearer that they are prospective biz reps
 * but haven't submitted/confirmed yet (ie. hasAllPersonalInfo)
 * we may want to return both from this selector for backwards comptatibility
 * @param {object} state - Redux state
 */
export const currentMembershipIsRepresentativeSelector = createSelector([currentMembershipSelector], (membership) =>
  isBusinessRepresentative(membership),
);

/**
 * Selects the current membership's address
 * @param {object} state - Redux state
 */
export const currentMembershipAddressSelector = createSelector(
  [membershipsByIdSelector, addressesSelector, currentMembershipIdSelector],
  (memberships, addresses, membershipId) => {
    const currentMembership = memberships[membershipId];

    if (!currentMembership?.address) {
      return null;
    }

    return addresses[currentMembership.address];
  },
);

/**
 * Get the role id of the current membership.
 * @type StandardSelector
 * @param {ReduxState} state
 * @returns {Role.id} Role id for the current membership.
 */
export const currentMembershipRoleIdSelector = createSelector(
  [currentMembershipSelector],
  (currentMembership) => currentMembership?.role,
);

/**
 * Selects role data for the current membership.
 * @type {StandardSelector}
 * @param {ReduxState}
 * @returns {Role} Full role object for the current membership.
 */
export const currentMembershipRoleSelector = createSelector(
  [currentMembershipRoleIdSelector, rolesByIdSelector],
  (currentMembershipRoleId, roles) => roles[currentMembershipRoleId],
);

/**
 * Selects role data for the current membership.
 * @type {StandardSelector}
 * @param {ReduxState}
 * @returns {Role} Full role object for the current membership.
 */
export const membershipRoleSelector = createSelector(
  [membershipSelector, rolesByIdSelector],
  (membership, roles) => roles[membership.role],
);

/**
 * Selects all memberships with personal info added
 * @param {object} state - Redux state
 */
export const allMembershipsWithPersonalInfoAdded = createSelector([allMembershipsSelector], (memberships) =>
  memberships.filter((membership) => !!membership.hasAllPersonalInfo),
);

/**
 * Selects all memberships with a failed beneficiary status
 * @param {object} state - Redux state
 */
export const allMembershipsWithFailedBeneficiaryStatus = createSelector([allMembershipsSelector], (memberships) =>
  memberships.filter((membership) => isIncluded(FundingCustomerFailedStatuses, membership.beneficiaryStatus)),
);

/**
 * Selects all memberships with document status
 * https://developers.dwolla.com/guides/business-verified-customer/add-beneficial-owners#check-the-status-of-an-individual-beneficial-owner
 * @param {object} state - Redux state
 */
export const memberHasDocumentBeneficiaryStatus = createSelector(
  [allMembershipsSelector, idSelector],
  (memberships = [], memberId) =>
    !!memberships.find(
      (membership) => membership.id === memberId && membership.beneficiaryStatus === FundingCustomerStatuses.DOCUMENT,
    ),
);

/**
 * Selects all memberships by companyId
 * @param {object} state - Redux state
 * @param {string} itemId - Company Id
 */
export const allMembershipsByCompany = createSelector([allMembershipsSelector, idSelector], (memberships, companyId) =>
  memberships.filter((membership) => isEqual(membership.company, companyId)),
);

/**
 * Selects the current membership verificationDocumentUpdateAllowed from the state
 * @param {object} state - Redux state
 */
export const currentMembershipVerificationDocumentUpdateAllowedSelector = createSelector(
  [currentMembershipSelector],
  (currentMembership) => currentMembership?.verificationDocumentUpdateAllowed,
);

/**
 * Selects the current membership verificationUpdateAllowed from the state
 * @param {object} state - Redux state
 */
export const currentMembershipVerificationUpdateAllowedSelector = createSelector(
  [currentMembershipSelector],
  (currentMembership) => currentMembership?.verificationUpdateAllowed,
);

/**
 * Selects the current membership notificationSettings id from the state
 * @param {object} state - Redux state
 */
export const currentMembershipNotificationSettingsIdSelector = createSelector(
  [currentMembershipSelector],
  (currentMembership) => currentMembership.notificationSettings,
);

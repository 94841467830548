import { connect } from 'react-redux';
import { getBillingDataForPaymentDeliveryOption } from 'helpers/billing';
import { getPaymentSourceFromFundingAccount } from 'helpers/funding';
import { applicableBillingDataByCodeSelector, fundingAccountForItemFromCreateItemSelector, } from 'queries/fundingCompoundSelectors';
import { createItemFormSelector } from 'selectors/forms';
import InvoiceListCheckbox from './InvoiceListCheckbox';
export const mapStateToProps = (state) => {
    const billingDataByCode = applicableBillingDataByCodeSelector(state);
    const item = createItemFormSelector(state, 'item');
    const selectedFundingAccount = fundingAccountForItemFromCreateItemSelector(state);
    const paymentSource = getPaymentSourceFromFundingAccount(selectedFundingAccount);
    const billingData = getBillingDataForPaymentDeliveryOption({
        billingDataByCode,
        paymentDeliveryOption: item.paymentDeliveryOption,
        kind: item.kind,
        paymentSource,
    });
    return { billingData };
};
export default connect(mapStateToProps)(InvoiceListCheckbox);

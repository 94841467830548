import React from 'react';
import Text from 'components/text/Text';
import { typography } from 'constants/styles';
import { TextColor } from 'constants/styles/typography';
import {} from './ContentWithOptionalText.types';
const ContentWithOptionalText = ({ children, optional }) => (React.createElement(React.Fragment, null,
    children,
    optional && (React.createElement(React.Fragment, null,
        ' ',
        React.createElement(Text.Bold, { color: TextColor.GREY_DARK, ...typography.TitleWithSubtitle.textProps }, "(optional)")))));
export default ContentWithOptionalText;

import PropTypes from 'prop-types';
import React from 'react';

/**
 * A component used for highlighting invalid chars in different colors.
 * @param {ComponentProps} props
 * @param {String} props.regex
 * @param {StringMaybe} props.value
 * @return {StatelessComponent}
 */
const InputValueHighlighter = ({ regex, value }) => {
  const elementRef = React.createRef();

  React.useEffect(() => {
    if (!elementRef.current) {
      return;
    }

    const html = value.replace(new RegExp(regex), '<span class="invalid-char">$1</span>');
    elementRef.current.innerHTML = html;
  }, [value, elementRef, regex]);

  return <div className="special-char--validator" ref={elementRef} />;
};

InputValueHighlighter.propTypes = {
  regex: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)]).isRequired,
  value: PropTypes.string,
};

InputValueHighlighter.defaultProps = {
  value: '',
};

export default InputValueHighlighter;

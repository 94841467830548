import React from 'react';
import { components } from 'react-select';

import Icon, { IconNames } from 'components/icon';

const { ClearIndicator } = components;

const ClearRenderer = (props) => (
  <ClearIndicator {...props}>
    <Icon className="clear-indicator" icon={IconNames.CROSS} />
  </ClearIndicator>
);

export default ClearRenderer;

import React from 'react';
import ReactDOM from 'react-dom';
import { change } from 'redux-form';

import { openIntercomChatWindow } from 'actions/support';

import { ServerError, ServerTimeoutError } from 'components/error/components';

import { formNamesItem } from 'constants/forms';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

import errorAlert from 'helpers/errorAlert';
import { hasZeroLength } from 'helpers/utility';

import { CustomerServiceHelper } from 'modules/customerSupport/CustomerService';

import { ledgerNameSelector } from 'selectors/integrationsSelectors';

import { storeAccessor } from 'store/accessor';

export const buildServerErrorAlert = (alertErrors, response) => {
  if (hasZeroLength(alertErrors)) {
    return;
  }

  const callback = (params) => {
    if (!params) {
      return;
    }

    const { requestId } = params;

    CustomerServiceHelper.showAndSetRequestId({ requestId });
  };

  const requestId = response?.headers['request-id'];

  const serverErrorComp = <ServerError alertErrors={alertErrors} requestId={requestId} />;

  const wrapper = document.createElement('div');

  ReactDOM.render(serverErrorComp, wrapper, () => {
    errorAlert(wrapper.firstChild, requestId, callback);
  });
};

export const buildServerErrorAlertV2 = (alertErrors, requestId) => {
  if (!alertErrors || alertErrors.length === 0) {
    return;
  }

  const callback = (params) => {
    if (params && params.requestId) {
      const title = `Hi ${PLATFORM_DISPLAY_SHORT_NAME} support, I received an error and would love some help resolving it`;
      storeAccessor.dispatch(openIntercomChatWindow(title, params.requestId));
    }
  };

  const serverErrorComp = <ServerError alertErrors={alertErrors} requestId={requestId} />;

  const wrapper = document.createElement('div');

  ReactDOM.render(serverErrorComp, wrapper, () => {
    errorAlert(wrapper.firstChild, requestId, callback);
  });
};

export const buildTimeoutErrorAlert = ({ itemKind, requestId }) => {
  const callback = (result) => {
    // disable re-submitting to prevent item duplication
    // IMPORTANT: this value should not be switched back to false once true, unless the user closes the form
    storeAccessor.dispatch(change(formNamesItem.CREATE_ITEM, 'ui.isSubmitDisabledByTimeout', true));
    if (result) {
      // on message support button click
      const title = `Hi ${PLATFORM_DISPLAY_SHORT_NAME} support, I received an error and would love some help resolving it`;
      storeAccessor.dispatch(openIntercomChatWindow(title));
    }
  };

  const state = storeAccessor.getState();
  const ledgerName = ledgerNameSelector(state);

  const serverErrorComp = <ServerTimeoutError itemKind={itemKind} ledgerName={ledgerName} requestId={requestId} />;

  const wrapper = document.createElement('div');

  ReactDOM.render(serverErrorComp, wrapper, () => {
    errorAlert(wrapper.firstChild, requestId, callback, { title: undefined });
  });
};

import { firstValue } from 'helpers/utility';

import { toaster } from 'services/toaster';

/**
 * Success Callback used in onSubmitFundingTokenAccount
 * @param {Function} createFundingAccountLinkToken
 * @param {Function} onUpdateTokenAccountModal
 * @param {Function} successCallback
 * @returns {ComponentProps}
 */
export const createLinkToken = (createFundingAccountLinkToken, onUpdateTokenAccountModal, successCallback) =>
  createFundingAccountLinkToken(
    (linkTokenResponse) => {
      const linkToken = linkTokenResponse?.meta?.linkToken;
      onUpdateTokenAccountModal({ plaidLinkToken: linkToken || null });
      if (successCallback) {
        successCallback();
      }
    },
    () => onUpdateTokenAccountModal({ plaidLinkToken: null }),
  );

/**
 * Success Callback used in onSubmitFundingTokenAccount
 * @param {Object} parsedResponse
 * @returns {ComponentProps}
 */
const successCallback = (parsedResponse, props) => {
  if (!props.skipSucessToast) {
    toaster.success('Bank account added', {
      id: 'plaid-account-toast-success',
    });
  }

  return parsedResponse;
};

/**
 * error callback used in onSubmitFundingTokenAccount
 * @param {Object} parsedResponse
 * @returns {ComponentProps}
 */
const errorCallback = (parsedResponse) => {
  toaster.danger('Failed to add bank account', {
    id: 'plaid-account-toast-danger',
  });

  return parsedResponse;
};

/**
 * Merge props in ConnectBankContainer to create an onSubmitFundingTokenAccount for ConnectBankOptions.
 * @param {null} stateProps
 * @param {Object} dispatchProps
 * @param {ComponentProps} ownProps
 * @returns {ComponentProps}
 */
export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  ...dispatchProps,
  onSubmitFundingTokenAccount: (publicToken, metaData) => {
    const { onCloseTokenAccountModal, handleSubmitFundingTokenAccount } = dispatchProps;
    const { createCheckSource } = ownProps;
    const account = firstValue(metaData.accounts);

    const submitPayload = {
      data: {
        type: 'FundingAccount',
      },
      meta: {
        accountId: account.id,
        createCheckSource,
        plaidInstitutionId: metaData.institution.institution_id,
        plaidInstitutionName: metaData.institution.name,
        plaidLinkSessionId: metaData.link_session_id,
        publicToken,
      },
    };

    handleSubmitFundingTokenAccount(submitPayload, (res) => successCallback(res, ownProps), errorCallback);

    // Ensure we close the Plaid window on success
    onCloseTokenAccountModal();
  },
});

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';

import { changeView, changeRepresentativeLock } from 'actions/signUpFlow';

import { ButtonV2, IconNames, ExternalLink } from 'components';

import { ButtonSize } from 'constants/button';
import { colors } from 'constants/styles';
import { Intent } from 'constants/ui';

import Accordion from 'modules/signup-v3/components/Accordion';
import { containerWidthMap } from 'modules/signup-v3/constants/container';

import { currentMembershipIsRepresentativeSelector } from 'selectors/membershipsSelector';

import InvitedTeamMembers from '../components/InvitedTeamMembers';
import { allFieldsHaveValues } from '../components/ProgressBarStepperV2/helpers';
import RepresentativesTable from '../components/RepresentativesTable';
import { views, signupFlowForm } from '../constants';
import { whatInfoRepresentativeNeed, howRoutableUseThisInformation } from '../constants/accordion';
import { mapViewToFields } from '../fields';
import FlowStepperMain from '../FlowStepperMain';
import { viewSteps } from '../helpers/viewSteps';

import {
  Content,
  HeaderContent,
  HelperContainer,
  RepresentativesHeader,
  RepresentativesWrapper,
  SubtitleList,
  SubtitleItem,
  SubtitleEmph,
} from './BusinessRepresentative.styles';

export const BusinessRepresentativeRender = ({ activeView }) => {
  const dispatch = useDispatch();
  const currentMembershipIsRepresentative = useSelector(currentMembershipIsRepresentativeSelector);
  const formValues = useSelector(getFormValues(signupFlowForm));

  const handleAddMyInfo = () => {
    dispatch(changeView(viewSteps[activeView].next.path));
    if (!currentMembershipIsRepresentative) {
      dispatch(changeRepresentativeLock(false));
    }
  };

  const handleContinueEditing = () => {
    const representativeViews = Object.keys(viewSteps).reduce((prev, key) => {
      if (viewSteps[key].type === views.businessRepresentative && key !== views.businessRepresentative) {
        return [
          ...prev,
          {
            id: key,
            fields: mapViewToFields[key],
          },
        ];
      }
      return prev;
    }, []);
    const firstViewWithoutValue = representativeViews.find((view) => {
      if (!view.fields) {
        return true;
      }
      return allFieldsHaveValues({ fields: views.fields, formValues });
    });
    const viewToDispatch = firstViewWithoutValue ? firstViewWithoutValue.id : views.personalInformation;

    dispatch(changeView(viewToDispatch));
  };

  return (
    <div>
      <RepresentativesWrapper>
        <RepresentativesHeader>
          <div>
            <h3>Representatives</h3>
            <HeaderContent>
              <ExternalLink href="https://www.federalregister.gov/documents/2016/05/11/2016-10567/customer-due-diligence-requirements-for-financial-institutions#footnote-44-p29407">
                Federal due diligence rules
              </ExternalLink>{' '}
              require that a business representative verify their information.
            </HeaderContent>
          </div>
          {currentMembershipIsRepresentative ? (
            <ButtonV2
              intent={Intent.NEUTRAL}
              leftIconClassName="margin-right--xm"
              leftIconColor={colors.colorGreyXDarkHex}
              leftIconName={IconNames.EDIT}
              onClick={handleContinueEditing}
              size={ButtonSize.MEDIUM}
              type="submit"
            >
              Continue editing
            </ButtonV2>
          ) : (
            <ButtonV2
              dataTestId="addMyInfoButton"
              leftIconClassName="margin-right--xm"
              leftIconColor={colors.colorWhiteHex}
              leftIconName={IconNames.PLUS}
              onClick={handleAddMyInfo}
              size={ButtonSize.MEDIUM}
              type="submit"
            >
              Add your info
            </ButtonV2>
          )}
        </RepresentativesHeader>
        <RepresentativesTable />
      </RepresentativesWrapper>
      <InvitedTeamMembers />
      <HelperContainer>
        <Accordion {...whatInfoRepresentativeNeed} />
        <Accordion {...howRoutableUseThisInformation} />
      </HelperContainer>
    </div>
  );
};

const BusinessRepresentative = ({ activeView }) => (
  <FlowStepperMain
    containerWidth={containerWidthMap.large}
    fields={<BusinessRepresentativeRender activeView={activeView} />}
    onBack={viewSteps[activeView].previous}
    onSkip={viewSteps[activeView].skip}
    submit={false}
    subTitle={{
      content: (
        <Content>
          To move forward, you need to qualify as a business representative. If your company has a beneficial owner,
          they must add their information.
          <SubtitleList>
            <SubtitleItem>
              <SubtitleEmph>Business representatives</SubtitleEmph> hold significant responsibilities to control,
              manage, or direct the company.
            </SubtitleItem>
            <SubtitleItem>
              <SubtitleEmph>Beneficial owners</SubtitleEmph> hold 25% or more of the equity interests of the company
              (either directly or indirectly).
            </SubtitleItem>
          </SubtitleList>
        </Content>
      ),
    }}
    title={{ content: 'Business representatives, add your information' }}
  />
);

export default BusinessRepresentative;

import PropTypes from 'prop-types';
import React from 'react';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { FormFieldLabel } from 'components/form/index';
import { IconNames } from 'components/icon';
import { TooltipIcon } from 'components/tooltip';

import { colors, margin, width } from 'constants/styles';

import { Position } from 'enums/ui';

import { getClassNames } from 'helpers/ui';

/**
 * A FormFieldLabel with a TooltipIcon next to it, the icon being INFO_SIGN.
 * @param {string} labelText
 * @param {string} tooltipText
 * @param {*} rest
 * @return {StatelessComponent}
 */
const FormLabelWithTooltip = ({ labelText, tooltipText, ...rest }) => (
  <div
    className={getClassNames(rest, {
      'align-items--center': true,
      'display--flex': true,
      'flex-direction--row': true,
    })}
  >
    <FormFieldLabel className="remove-margin-bottom">{labelText}</FormFieldLabel>

    <TooltipIcon
      content={tooltipText}
      contentMargin={margin.medium}
      icon={IconNames.INFO_SIGN}
      iconColor={colors.colorMainJordan}
      iconProps={{ marginLeft: margin.extraMedium }}
      tooltipProps={{
        position: Position.TOP,
        statelessProps: { maxWidth: width.tooltipWidth.MEDIUM },
      }}
    />
  </div>
);

FormLabelWithTooltip.propTypes = {
  className: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
};

FormLabelWithTooltip.defaultProps = {
  className: undefined,
};

export default FormLabelWithTooltip;

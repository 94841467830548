import PropTypes from 'prop-types';
import React from 'react';

import { ContentBlockFooter } from 'components/contentBlock';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { BalanceAmountBlockActions, BalanceAmountBlockSettingsLink } from './components';

const BalanceAmountBlockFooter = (props) => {
  const { displayActions, displaySettingsLink } = props;

  return (
    <ContentBlockFooter>
      {displayActions && <BalanceAmountBlockActions {...props} />}
      {displaySettingsLink && <BalanceAmountBlockSettingsLink />}
    </ContentBlockFooter>
  );
};

BalanceAmountBlockFooter.propTypes = {
  allowDeposit: PropTypes.bool,
  allowWithdraw: PropTypes.bool,
  displayActions: PropTypes.bool,
  displaySettingsLink: PropTypes.bool,
  isNegative: PropTypes.bool,
  onDeposit: PropTypes.func,
  onFixNegative: PropTypes.func,
  onWithdraw: PropTypes.func,
};

BalanceAmountBlockFooter.defaultProps = {
  allowDeposit: true,
  allowWithdraw: true,
  displayActions: true,
  displaySettingsLink: false,
  isNegative: false,
  onDeposit: undefined,
  onFixNegative: undefined,
  onWithdraw: undefined,
};

export default BalanceAmountBlockFooter;

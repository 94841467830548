import * as types from 'types/thread';

export const fetchThreadRequest = (threadId) => ({
  payload: { threadId },
  type: types.FETCH_THREAD_REQUEST,
});

export const fetchThreadSuccess = (payload) => ({
  payload,
  type: types.FETCH_THREAD_SUCCESS,
});

export const fetchThreadFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_THREAD_FAILURE,
});

import { createIsFetchingReducer } from 'store/redux';

import {
  FETCH_TAX_INFO_REQUEST,
  FETCH_VENDOR_TAX_INFO_REQUEST,
  FETCH_TAX_INFO_SUCCESS,
  FETCH_TAX_INFO_FAILURE,
  FETCH_VENDOR_TAX_INFO_SUCCESS,
  FETCH_VENDOR_TAX_INFO_FAILURE,
} from 'types/taxes';

export const finishCases = [
  FETCH_TAX_INFO_SUCCESS,
  FETCH_TAX_INFO_FAILURE,
  FETCH_VENDOR_TAX_INFO_SUCCESS,
  FETCH_VENDOR_TAX_INFO_FAILURE,
];
export const requestCases = [FETCH_TAX_INFO_REQUEST, FETCH_VENDOR_TAX_INFO_REQUEST];

const isFetchingReducer = createIsFetchingReducer(finishCases, requestCases);

export default isFetchingReducer;

import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';
import { createInternationalBankAccountRoutine } from 'actions/routines/funding';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import { FETCH_FUNDING_ACCOUNTS_SUCCESS, SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_SUCCESS } from 'types/funding';
import { FETCH_ITEM_SUCCESS } from 'types/item';
import {
  FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_SUCCESS,
  FETCH_PARTNERSHIP_SUCCESS,
  SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_SUCCESS,
} from 'types/partnership';
import { FETCH_ITEM_TRANSACTIONS_SUCCESS } from 'types/transactions';

const fundingInfoAddressesByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
    case FETCH_FUNDING_ACCOUNTS_SUCCESS:
    case FETCH_ITEM_SUCCESS:
    case FETCH_ITEM_TRANSACTIONS_SUCCESS:
    case FETCH_PARTNERSHIP_SUCCESS:
    case FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_SUCCESS:
    case SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_SUCCESS:
    case SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_SUCCESS:
    case createInternationalBankAccountRoutine.SUCCESS:
      return deepMergeWithArrayReplacement(state, getObjectsByIdWithRelationships(action.payload.fundingInfoAddress));

    default:
      return state;
  }
};

export default fundingInfoAddressesByIdReducer;

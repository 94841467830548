import { all, call, delay, put } from 'redux-saga/effects';
import { handleRequestErrors } from 'actions/errors';
import { ssoSetupFormEditSubmitRoutine } from 'actions/routines/sso';
import { openSSOSetupFormSplashscreenModal } from 'actions/ssoActions';
import { showErrorUi } from 'actions/ui';
import { SSOSetupFailUIText, SSOSetupSplashscreenDuration } from 'constants/sso';
import {} from 'enums/sso';
import { parseCaughtError, parseErrorResponse } from 'helpers/errors';
import {} from 'interfaces/fetchService';
import * as api from './api';
export function* enableSSO(settingsId, ssoSetupFormProvider) {
    let submitErrors = {};
    try {
        const response = yield call(api.enableSSO, settingsId);
        if (response.ok) {
            yield all([put(openSSOSetupFormSplashscreenModal()), delay(SSOSetupSplashscreenDuration)]);
            return true;
        }
        submitErrors = parseErrorResponse(response);
    }
    catch (error) {
        submitErrors = parseCaughtError(error);
    }
    yield all([
        put(handleRequestErrors(ssoSetupFormEditSubmitRoutine.failure, submitErrors)),
        put(showErrorUi(SSOSetupFailUIText[ssoSetupFormProvider].SETUP)),
    ]);
    return false;
}

import PropTypes from 'prop-types';
import React from 'react';

import { Modal } from 'components';

import { contentWidth } from 'constants/styles/width';

import ExportCsvModalBody from './ExportCsvModalBody';

import './ExportCsvModal.scss';

/**
 * Modal which notifies the user about the process of exporting csv files
 * @param {ComponentProps} props
 * @param {object} props.modal
 * @param {function} props.onCloseModal
 * @returns {StatelessComponent}
 */
const ExportCsvModal = ({ modal, onCloseModal }) => (
  <Modal
    body={<ExportCsvModalBody onCloseModal={onCloseModal} />}
    hasInlineFooter
    modalHeader="Export in progress"
    modalState={modal.open}
    onCloseModal={onCloseModal}
    overrideStyle={{ content: { width: contentWidth.SMALL } }}
    testId="export-csv-modal"
  />
);

ExportCsvModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default ExportCsvModal;

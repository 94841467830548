import { IconNames } from 'components';

import { colors } from 'constants/styles';

const yellow = '#c47308';
const neutralBlack = '#2e2f33';

export const customWaitingStatus = {
  waiting: {
    label: 'Waiting',
    color: neutralBlack,
    activeStatusColor: yellow,
    statusColor: colors.colorGreyDarkHex,
    reviewBackground: colors.colorYellowLightHex,
    reviewColor: colors.colorYellowDark,
  },
};

export const stepStatuses = {
  none: 'none',
  progress: 'progress',
  completed: 'completed',
  notStarted: 'notStarted',
  active: 'active',
  disabled: 'disabled',
  locked: 'locked',
  waiting: 'waiting',
  failed: 'failed',
  verified: 'verified',
};

export const defaultStatuses = {
  [stepStatuses.progress]: {
    label: 'In progress',
    color: neutralBlack,
    statusColor: colors.colorGreyDarkHex,
    reviewLabel: 'Making progress',
  },
  [stepStatuses.completed]: {
    label: 'Done',
    color: neutralBlack,
    statusColor: colors.colorGreyDarkHex,
    reviewLabel: 'Ready to submit',
    reviewBackground: colors.colorGreenLight,
    reviewColor: colors.colorGreenDark,
  },
  [stepStatuses.notStarted]: {
    label: 'Not started',
    color: neutralBlack,
    statusColor: colors.colorGreyDarkHex,
  },
  [stepStatuses.active]: {
    color: colors.colorBlueBoldHex,
  },
  [stepStatuses.disabled]: {
    label: 'Not started',
    color: colors.colorGreyDarkHex,
  },
  [stepStatuses.locked]: {
    label: 'Not started',
    color: neutralBlack,
    statusColor: colors.colorGreyDarkHex,
  },
  [stepStatuses.failed]: {
    label: 'Verification Failed',
    color: neutralBlack,
    statusColor: colors.colorGreyDarkHex,
    reviewBackground: colors.colorRed05,
    reviewColor: colors.colorRedDarkHex,
  },
  [stepStatuses.verified]: {
    label: 'Verified',
    color: neutralBlack,
    statusColor: colors.colorGreyDarkHex,
  },
  [stepStatuses.none]: {
    label: undefined,
  },
};

export const substepDefaultStatuses = {
  progress: {
    color: colors.colorBlueBoldHex,
    icon: IconNames.ARROW_RIGHT,
  },
  completed: {
    color: colors.colorGreyXXDarkHex,
    icon: IconNames.TICK,
  },
  notStarted: {
    color: colors.colorGreyDarkHex,
    icon: IconNames.CIRCLE,
    iconColor: colors.colorGreyMedHex,
  },
  disabled: {
    color: colors.colorGreyDarkHex,
  },
};

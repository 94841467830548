import PropTypes from 'prop-types';
import React from 'react';

import { sizes } from 'constants/styles';

import ModalFooterButton from './ModalFooterButton';

const ModalFooterButtonCreateAnother = ({ buttonText, ...props }) => (
  <ModalFooterButton
    id="modalFooter_createAnother_btn"
    isActionButton
    leftIconClassName="margin-right--xm"
    leftIconSize={sizes.iconSizes.LARGE}
    type="button"
    {...props}
  >
    {buttonText}
  </ModalFooterButton>
);

ModalFooterButtonCreateAnother.propTypes = {
  buttonText: PropTypes.node.isRequired,
};

export default ModalFooterButtonCreateAnother;

import { createLastUpdatedReducer } from 'store/redux';

import {
  FETCH_LEDGER_PARTNERSHIPS_SUCCESS,
  FETCH_PLATFORM_PARTNERSHIPS_SEARCH_SUCCESS,
  FETCH_PLATFORM_PARTNERSHIPS_SUCCESS,
} from 'types/integrations';

export const successCases = [
  FETCH_LEDGER_PARTNERSHIPS_SUCCESS,
  FETCH_PLATFORM_PARTNERSHIPS_SEARCH_SUCCESS,
  FETCH_PLATFORM_PARTNERSHIPS_SUCCESS,
];

const lastUpdatedReducer = createLastUpdatedReducer(successCases);

export default lastUpdatedReducer;

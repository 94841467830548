import validator from 'validator';
export const stripNewlinesFromString = (str) => str.replace(/\n|\r\n/g, '');
export const dashTextToCamelCase = (text) => {
    if (typeof text !== 'string') {
        return '';
    }
    if (validator.isUUID(text)) {
        return text;
    }
    return text.replace(/([-_][a-z0-9])/g, ($1) => $1.toUpperCase().replace(/[-_]/, ''));
};
export const uppercaseToUnderscore = (text) => {
    if (typeof text !== 'string') {
        return '';
    }
    if (validator.isUUID(text)) {
        return text;
    }
    const underscoredText = text.replace(/([A-Z])/g, ($1) => `_${$1.toLowerCase()}`);
    if (underscoredText.charAt(1) === '_') {
        return underscoredText.substring(1);
    }
    return underscoredText;
};
export const uppercaseToSnakeCaseWithNumbers = (text) => {
    if (typeof text !== 'string') {
        return '';
    }
    const snakeCase = uppercaseToUnderscore(text);
    const underscoredText = snakeCase.replace(/\d+/, ($1) => `_${$1.toLowerCase()}`);
    if (underscoredText.charAt(1) === '_' || !!text.match(/^[1-9]/)) {
        return underscoredText.substring(1);
    }
    return underscoredText;
};
export const toPathString = (...strings) => strings.reduce((path, str) => {
    if (str.length > 0) {
        if (path.length > 0) {
            return `${path}.${str}`;
        }
        return str;
    }
    return path;
}, '');

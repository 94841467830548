import { z } from 'zod';
import { businessType, governmentIdType } from './businessType.model';
export const companyInfoModel = z.object({
    businessType,
    hasTin: z.boolean(),
    id: z.string().uuid(),
    legalName: z.string(),
    tin: z.string().optional(),
    tinType: governmentIdType,
});

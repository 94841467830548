import { SAVE_STATUSES } from 'modules/signup-v3/constants';

import { SAVE_STATUS_NOT_SAVED, SAVE_STATUS_SAVED, SAVE_STATUS_SAVING } from 'types/signup';

const saveReducer = (state = { status: SAVE_STATUSES.saved }, action) => {
  if (action?.type === SAVE_STATUS_SAVED) {
    return {
      ...state,
      status: SAVE_STATUSES.saved,
    };
  }
  if (action?.type === SAVE_STATUS_SAVING) {
    return {
      ...state,
      status: SAVE_STATUSES.saving,
    };
  }
  if (action?.type === SAVE_STATUS_NOT_SAVED) {
    return {
      ...state,
      status: SAVE_STATUSES.notSaved,
    };
  }
  return state;
};

export default saveReducer;

import { PaymentDeliveryMethodType } from '@routable/shared';
import PropTypes from 'prop-types';
import React from 'react';

import { partnerFundingAccountFormFields } from 'constants/formFields';

import { isItemPaymentDeliveryMethodCheck } from 'helpers/items';

import ConnectBankManualForm from 'modules/connectBank/connectBankManual/components/ConnectBankManualForm';

import AddPartnerFundingAccountAddress from './AddPartnerFundingAccountAddressContainer';

/**
 * Renders form fields for AddPartnerFundingAccount form in side panel
 * @param formName
 * @param paymentDeliveryMethod
 * @return {FunctionComponent}
 */
const AddPartnerFundingAccountFormFields = ({ formName, paymentDeliveryMethod }) => {
  const isCheckPaymentMethod = isItemPaymentDeliveryMethodCheck({
    paymentDeliveryMethod,
  });

  if (isCheckPaymentMethod) {
    return <AddPartnerFundingAccountAddress />;
  }

  return (
    <ConnectBankManualForm
      formName={formName}
      formPath={partnerFundingAccountFormFields.BANK_ACCOUNT}
      isPartnerAccount
    />
  );
};

AddPartnerFundingAccountFormFields.propTypes = {
  formName: PropTypes.string.isRequired,
  paymentDeliveryMethod: PropTypes.oneOf(Object.values(PaymentDeliveryMethodType)),
};

AddPartnerFundingAccountFormFields.defaultProps = {
  paymentDeliveryMethod: PaymentDeliveryMethodType.ACH,
};

export default AddPartnerFundingAccountFormFields;

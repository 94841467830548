import React from 'react';

import { ExternalAcceptPartnershipFlowState } from 'constants/ui';

import AcceptPartnershipIllustrationBase from '../AcceptPartnershipIllustrationBase';

/**
 * Renders RegisterIllustrationSvg based on external accept partnership flow state.
 * @return {StatelessComponent}
 */
const RegisterIllustrationSvg = () => (
  <AcceptPartnershipIllustrationBase acceptPartnershipState={ExternalAcceptPartnershipFlowState.INITIAL} />
);

export default RegisterIllustrationSvg;

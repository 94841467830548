import { isPartnershipMissingDetails } from 'helpers/partnerships';

import { handleLoadLedgerPartnerships } from 'modules/dashboard/settings/account/integrations/ledger/matching/helpers/partnerships';

/**
 * Curried function which determines if there is enough info in the partnership object we currently have to open the add
 * or merge modals. If not, we try to fetch the ledger partnership from the backend using the item's companyRef.
 * Updates some component-side hooks, like useState (for loading) and ref's the popover close fn so its not lost on
 * rerender.
 * @param {Item} item
 * @param {function} openModal - Dispatcher to open the appropriate modal
 * @param {Object} partnershipWithContacts
 * @param {function} updateIsLoading - useState hook from component to track loading state if we need to fetch the
 * partnership
 * @returns {function} - Curried function.
 */
export const onMatchPartnership =
  ({ item, openModal, partnershipWithContacts, updateIsLoading }) =>
  () => {
    // fetch partnership if needed before opening modal
    if (isPartnershipMissingDetails(partnershipWithContacts)) {
      const { companyRef } = item;
      handleLoadLedgerPartnerships({
        ledgerRef: companyRef,
        resetOffset: true,
      });
      updateIsLoading(true);

      // already have the full partnership
    } else {
      updateIsLoading(false);
      openModal();
    }
  };

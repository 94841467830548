import { call, put } from 'redux-saga/effects';

import { cancelClearExportFileId, cancelExportSuccess, cancelExportFailed } from 'actions/export';
import { closeNotificationBar, showUiToast } from 'actions/ui';

import { trackEvent, TrackEventName } from 'helpers/eventTracking';
import { sagaWatcher } from 'helpers/saga';

import { CANCEL_EXPORT_REQUEST } from 'types/export';
import { SHOW_ERROR_UI } from 'types/ui';

import { cancelExportApiRequest } from './api';

export function* cancelExportRequest(action) {
  const { exportFileId } = action.payload;
  yield put(closeNotificationBar());
  yield call(trackEvent, TrackEventName.CSV_EXPORT_CANCELLED);
  try {
    const response = yield call(cancelExportApiRequest, exportFileId);
    if (response.ok) {
      yield put(cancelExportSuccess());
      yield put(cancelClearExportFileId());
      yield put(showUiToast(SHOW_ERROR_UI, 'Export cancelled'));
    } else {
      yield put(cancelExportFailed());
    }
  } catch (error) {
    yield put(cancelExportFailed());
  }
}

export default function* exportFileSagas() {
  yield sagaWatcher([
    {
      type: CANCEL_EXPORT_REQUEST,
      saga: cancelExportRequest,
    },
  ]);
}

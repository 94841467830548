import { createSelector } from 'reselect';

import { idSelector } from './globalSelectors';

/**
 * Selects corner dialogs from the state
 * @param {object} state - Redux state
 */
export const cornerDialogsSelector = (state) => state.cornerDialogs;

/**
 * Selects push notification corner dialogs from the state
 * @param {object} state - Redux state
 */
export const cornerDialogSelector = createSelector(
  [cornerDialogsSelector, idSelector],
  (cornerDialogs, cornerDialog) => cornerDialogs[cornerDialog],
);

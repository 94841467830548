import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { ReduxFormFieldRenderInput } from 'components';

import { field } from 'constants/styles/formStyles';

/**
 * Renders a static (disabled) text input field to be rendered in a block section
 * @param {ComponentProps} props
 * @param {string} props.name
 * @param {string} props.placeholder
 * @returns {StatelessComponent}
 */
const StaticBlockField = ({ name, placeholder }) => (
  <Field
    className={classNames(field.xl.full, 'margin-top--m')}
    component={ReduxFormFieldRenderInput}
    isDisabled
    name={name}
    placeholder={placeholder}
    type="text"
  />
);

StaticBlockField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default React.memo(StaticBlockField);

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import {
  allPartnershipMembersWherePartnershipPartnerFromQuerySelector,
  partnershipMemberFromMembershipQueryParamSelector,
} from 'queries/partnershipMemberCompoundSelectors';

import ExternalPromptHeader from './ExternalPromptHeader';

const mapStateToProps = createStructuredSelector({
  partnershipPartnerMembers: allPartnershipMembersWherePartnershipPartnerFromQuerySelector,
  requester: partnershipMemberFromMembershipQueryParamSelector,
});

const enhance = compose(connect(mapStateToProps));

export default enhance(ExternalPromptHeader);

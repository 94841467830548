import { combineReducers } from 'redux';

import errorReducer from './errorReducer';
import lastSubmittedReducer from './lastSubmittedReducer';
import signupPasswordErrorReducer from './passwordErrors';
import submitReducer from './submitReducer';

const signupCreateAccountReducer = combineReducers({
  errors: errorReducer,
  isSubmitting: submitReducer,
  lastSubmitted: lastSubmittedReducer,
  passwordError: signupPasswordErrorReducer,
});

export default signupCreateAccountReducer;

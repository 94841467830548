import React from 'react';
import { SubtitleWithIcon, TitleWithDetails, TitleWithSubtitle } from 'components';
import { ItemMemberProps } from 'data/resources/itemMember';
import { formatEmailForDetailsTitle } from 'helpers/contacts';
import { getItemMemberAccessSubtitleUi } from 'helpers/itemMember';
import { getMembershipFullName } from 'helpers/memberships';
const ContactListItemTitleTypeThread = ({ dataFullStory, item, partnershipMember, }) => {
    const { email } = partnershipMember;
    const title = getMembershipFullName(partnershipMember);
    const detail = title ? formatEmailForDetailsTitle(email) : email;
    const { color: iconColor, name: iconName, text: subtitleText, } = getItemMemberAccessSubtitleUi(item, partnershipMember, ItemMemberProps.accessItem);
    return (React.createElement(TitleWithSubtitle, { subtitles: [
            React.createElement(SubtitleWithIcon, { icon: iconName, iconProps: {
                    color: iconColor,
                }, key: "subtitle-icon", text: subtitleText }),
        ], titles: [
            React.createElement(TitleWithDetails, { dataFullStory: dataFullStory, detail: detail, key: "title-with-details", main: title }),
        ] }));
};
export default ContactListItemTitleTypeThread;

import { FETCH_INTEGRATION_CONFIGS_SUCCESS } from 'types/integrations';

import { initialState } from './initialState';

const dashboardLoadedIntegrationConfigsReducer = (state = initialState.integrationConfigs, action) => {
  switch (action.type) {
    case FETCH_INTEGRATION_CONFIGS_SUCCESS:
      return true;

    default:
      return state;
  }
};

export default dashboardLoadedIntegrationConfigsReducer;

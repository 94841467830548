import { areDevtoolsEnabled } from 'helpers/env';
import { storeRouteAsForwardingUrl } from 'helpers/sessionStorage';
import { getUrlWithoutOrigin } from 'helpers/urls';

import { BASE_API_URL, BASE_WEBSITE_URL, getEnvironment } from 'services/api';

// eslint-disable-next-line no-console
console.log(`Running version: ${process.env.REACT_APP_VERSION}`);

// Log information on non-production environments
if (areDevtoolsEnabled()) {
  /* eslint-disable no-console */
  console.log(`On branch: ${process.env.ROUTABLE_FE_GIT_BRANCH}`);
  console.log(`Using environment: ${getEnvironment()}`);
  console.log(`Using API: ${BASE_API_URL}`);
  console.log(`Using website: ${BASE_WEBSITE_URL}`);
  /* eslint-enable no-console */

  // Add window routable object
  window.routable = {
    debug: {
      disableValidation: process.env.REACT_APP_DISABLE_FORM_VALIDATION === '1',
      // debug the (sanitized, prefixed) environment if set
      env: process.env.ROUTABLE_FE_DEV_DEBUG_ENV === '1' ? process.env : undefined,
      delay: {
        request: 0,
        item_create: 0,
      },
    },
  };
}

if (global.sessionStorage) {
  // store the initially loaded route in local storage, should
  // we need to intercept its loading, and return to it later
  // e.g. need to authenticate first
  const initialRoute = getUrlWithoutOrigin(window.location);
  storeRouteAsForwardingUrl(initialRoute);
}

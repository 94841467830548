import { combineReducers } from 'redux';

import approvalLevelErrorReducer from './approvalLevelErrorReducer';
import disabledUserErrorReducer from './disabledUserErrorReducer';
import notFoundErrorReducer from './notFoundErrorReducer';

export const errorsReducer = combineReducers({
  disabledUserError: disabledUserErrorReducer,
  notFoundError: notFoundErrorReducer,
  approvalLevelError: approvalLevelErrorReducer,
});

export default errorsReducer;

import React from 'react';
import { Emoji, FlexRow, Text, Icon, IconNames } from 'components';
import { typography, colors } from 'constants/styles';
const { REACT_APP_STATIC_BASE_PATH } = process.env;
const QuickswitchModalEmptySearchResults = ({ onUndoSearchClick, }) => (React.createElement("div", { className: "quickswitch-modal--empty-state" },
    React.createElement(FlexRow, { className: "justify-content--center align-items--center" },
        React.createElement("img", { alt: "No results", className: "display--block max-width--full border--none", src: `${REACT_APP_STATIC_BASE_PATH}/images/quickswitch/table-empty-state.svg` })),
    React.createElement(FlexRow, { className: "justify-content--center align-items--center" },
        React.createElement(Text.Bold, { className: "line-height--26--important", color: typography.TextColor.GREY_X_DARK, size: typography.TextSize.LEVEL_400 },
            "No accounts found",
            React.createElement(Emoji, { ariaLabel: "detective", text: "\uD83D\uDD75" }))),
    React.createElement(FlexRow, { className: "justify-content--center align-items--center" },
        React.createElement(Text.Semibold, { className: "margin-bottom--sm line-height--25--important", size: typography.TextSize.LEVEL_200 },
            React.createElement("a", { className: "quickswitch-modal--undo-search", onClick: onUndoSearchClick, onKeyDown: onUndoSearchClick, role: "link", tabIndex: 0 },
                React.createElement(Icon, { className: "margin-right--xm", color: colors.colorBlueBoldHex, icon: IconNames.UNDO, size: 12 }),
                React.createElement("span", null, "Undo search"))))));
export default QuickswitchModalEmptySearchResults;

import { getApiOffsetForPagination } from '@routable/framework';
import { queryContext } from '@routable/shared';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { purchaseOrderOptionModel } from './purchaseOrder.models';
import { purchaseOrderService } from './purchaseOrder.service';
export const generateUsePurchaseOrders = ({ getAll }) => ({ enabled, ledgerRefs, search, vendors, }) => {
    const { data, ...resp } = useInfiniteQuery({
        enabled,
        queryKey: ['purchaseOrders', ledgerRefs, search || 'all', vendors],
        queryFn: ({ pageParam = null }) => getAll({ ledgerRefs, search, offset: pageParam, vendors }),
        getNextPageParam: getApiOffsetForPagination,
        context: queryContext,
    });
    const mappedData = useMemo(() => {
        return (data?.pages
            .flatMap((p) => p.data?.purchaseOrders)
            .flatMap((purchaseOrder) => {
            const transformed = purchaseOrderOptionModel.safeParse(purchaseOrder);
            if (transformed.success) {
                return transformed.data;
            }
            return null;
        }) || []);
    }, [data]);
    return {
        ...resp,
        data: mappedData,
    };
};
export const usePurchaseOrders = generateUsePurchaseOrders(purchaseOrderService);

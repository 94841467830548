import { PaymentDeliveryMethodType } from '@routable/shared';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { BANK_ACCOUNT_TRANSFER } from 'constants/paymentMethods';

import { ConnectBankRadio } from './components';

const PartnerCompanyMakePayment = ({ fundingAccount, paymentDeliveryMethod }) => (
  <div className="form-container">
    <div className="margin-bottom--large">
      <Field
        component={ConnectBankRadio}
        name="paymentDeliveryMethod"
        optionText={BANK_ACCOUNT_TRANSFER}
        props={{
          fundingAccount,
          paymentDeliveryMethod,
        }}
        type="radio"
        value={PaymentDeliveryMethodType.ACH}
      />
    </div>
  </div>
);

PartnerCompanyMakePayment.propTypes = {
  fundingAccount: PropTypes.string,
  paymentDeliveryMethod: PropTypes.string,
};

PartnerCompanyMakePayment.defaultProps = {
  fundingAccount: undefined,
  paymentDeliveryMethod: undefined,
};

export default PartnerCompanyMakePayment;

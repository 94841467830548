import { combineReducers } from 'redux';

import allFundingInfoBankAccountsReducer from './allFundingInfoBankAccountsReducer';
import errorReducer from './errorReducer';
import fetchReducer from './fetchReducer';
import fundingInfoBankAccountsByIdReducer from './fundingInfoBankAccountsByIdReducer';
import lastFetchedReducer from './lastFetchedReducer';

const fundingInfoBankReducer = combineReducers({
  allIds: allFundingInfoBankAccountsReducer,
  byId: fundingInfoBankAccountsByIdReducer,
  errors: errorReducer,
  isFetching: fetchReducer,
  lastFetched: lastFetchedReducer,
});

export default fundingInfoBankReducer;

import React from 'react';
import { LinkNewPage } from 'components/link';
import { Text } from 'components/text';
import { TooltipMUIConditionalWrapper } from 'components/tooltip';
import { typography } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';
import helpDocs from 'helpers/helpDocs';
const PreventInternationalCustomerMatchTooltip = ({ children, isShown, placement = TooltipPlacement.RIGHT, }) => (React.createElement(TooltipMUIConditionalWrapper, { tooltipProps: isShown
        ? {
            arrow: true,
            padding: TooltipPadding.SKINNY,
            placement,
            title: (React.createElement(Text.Regular, { color: typography.TextColor.GREY_X_DARK, lineHeight: typography.TextLineHeight.MEDIUM, size: typography.TextSize.LEVEL_100 },
                "We do not currently support sending invoices to customers based in any country other than the United States",
                ` and its territories, so you are unable to take action. `,
                React.createElement(LinkNewPage, { className: "primary", href: helpDocs.PARTNER_COUNTRY_CURRENCY_HINT }, "Read more about supported countries and currencies \u2197"))),
        }
        : {} }, children));
export default PreventInternationalCustomerMatchTooltip;

import { Badge, Link } from '@routable/components';
import { Launch } from '@carbon/icons-react';
import { taxInfoStatus, taxRiskSummary } from '@routable/taxes';
import React from 'react';
import { riskSummaryToIntentMap, riskSummaryToLabelMap } from './RiskStatusSummary.constants';
export const RiskStatusSummary = ({ complianceChecksPath, riskSummary = taxRiskSummary.Enum.not_evaluated, taxStatus, taxFormType, }) => {
    return (React.createElement("div", { className: "flex flex-wrap gap-2 items-center pl-2" },
        taxStatus !== taxInfoStatus.Enum.completed ? (React.createElement(Badge, { intent: "warning" }, `${taxFormType} not found or invalid`)) : (React.createElement(React.Fragment, null, riskSummary === taxRiskSummary.Enum.passed ? (React.createElement("span", { className: "text-xs text-grey-60" }, "No compliance or tax issues found")) : (React.createElement(Badge, { intent: riskSummaryToIntentMap[riskSummary] }, riskSummaryToLabelMap[riskSummary])))),
        React.createElement(Link, { className: "text-blue-40 text-xs inline-flex items-center gap-1", href: complianceChecksPath, target: "_blank" },
            "View details",
            React.createElement(Launch, null))));
};

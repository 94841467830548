import React from 'react';
import { BrandWrapperV2 } from 'components';
import * as ROUTES from 'constants/routes';
import { asPath } from 'helpers/routeHelpers';
import NotAuthenticated from 'modules/app/NotAuthenticatedRoute';
import CreatePasswordV2 from '../ResetPassword/components/ResetPasswordComplete/ResetPasswordCompleteContainer';
const CreatePassword = () => (React.createElement(BrandWrapperV2, null,
    React.createElement("div", { className: "branded--contents remove-margin" },
        React.createElement(NotAuthenticated, { component: CreatePasswordV2, creatingNewPassword: true, path: asPath(ROUTES.CREATE_PASSWORD) }))));
export default CreatePassword;

import { fetchCompanyTagsRoutine, createItemTagLinksRoutine, createTagRoutine } from 'actions/routines/tags';

import { tagLinkRelationships as relationships } from 'constants/relationships';

import { createByIdReducer } from 'store/redux';

import { FETCH_ITEM_SUCCESS } from 'types/item';

const byIdReducer = createByIdReducer({
  key: 'tag',
  types: [
    FETCH_ITEM_SUCCESS,
    fetchCompanyTagsRoutine.SUCCESS,
    createTagRoutine.SUCCESS,
    createItemTagLinksRoutine.SUCCESS,
  ],
  relationships,
});

export default byIdReducer;

import PropTypes from 'prop-types';
import React from 'react';

import { parseCurrency } from 'helpers/numbers';

const ExternalItemsListAmount = ({ rowData }) => (
  <span className="like-p company">{parseCurrency(rowData.amount)}</span>
);

ExternalItemsListAmount.propTypes = {
  rowData: PropTypes.shape().isRequired,
};

export default ExternalItemsListAmount;

import React from 'react';
import Avatar from 'components/avatar/Avatar';
import { MemberNameAddOn } from 'constants/ui';
import { getMembershipFullNameAppendingTextToCurrent } from 'helpers/memberships';
import * as Cell from './CompanyRepTableName.styles';
const { MEMBER_NAME_ME } = MemberNameAddOn;
const CompanyRepTableName = ({ membership }) => {
    const { avatar, email, firstName, lastName, isCompanyBeneficiary } = membership;
    const memberFullName = getMembershipFullNameAppendingTextToCurrent(membership, MEMBER_NAME_ME);
    return (React.createElement(Cell.Wrapper, null,
        React.createElement(Cell.Name, null,
            React.createElement(Avatar, { avatar: avatar, email: email, firstName: firstName, lastName: lastName, sizeClass: "avatar--sm", styleClass: "avatar--light" }),
            React.createElement("span", { className: "margin-left--m font-color--greyXDark font-regular" }, memberFullName)),
        isCompanyBeneficiary && React.createElement(Cell.Tag, null, "Beneficial owner")));
};
export default CompanyRepTableName;

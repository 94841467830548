import { PERSISTENCE_KEY } from 'constants/persistence';
import { getQueryParamValueFromUrl } from './queryParams';
export const localStorageSet = (key, value) => localStorage.setItem(key, value);
export const localStorageGet = (key) => localStorage.getItem(key);
export const localStorageRemove = (key) => {
    localStorage.removeItem(key);
};
export const handleClearLocalStorage = () => {
    Object.keys(localStorage)
        .filter((key) => !key.includes(PERSISTENCE_KEY))
        .forEach(localStorageRemove);
};
export const getMembershipsDataFromLocalStorage = () => {
    let storageResult = [];
    try {
        storageResult = JSON.parse(localStorageGet('memberships'));
    }
    catch {
    }
    return storageResult;
};
export const getCurrentMembershipId = () => {
    const localStorageMembershipId = localStorageGet('currentMembershipId');
    if (localStorageMembershipId) {
        return localStorageMembershipId;
    }
    return getQueryParamValueFromUrl('membership_id');
};
export const getCurrentMembershipData = () => {
    const membershipsData = getMembershipsDataFromLocalStorage();
    const currentMembershipId = getCurrentMembershipId();
    if (membershipsData && currentMembershipId && membershipsData[currentMembershipId]) {
        return membershipsData[currentMembershipId];
    }
    return {};
};
export const getCurrentCompanyId = () => {
    const membershipData = getCurrentMembershipData();
    if (membershipData && membershipData.companyId) {
        return membershipData.companyId;
    }
    return getQueryParamValueFromUrl('company_id');
};
export const getCurrentUserId = () => {
    const membershipData = getCurrentMembershipData();
    if (membershipData && membershipData.userId) {
        return membershipData.userId;
    }
    return getQueryParamValueFromUrl('user_id');
};
export const getFromStore = (key, defaultValue) => {
    const storedValue = localStorageGet(key);
    if (storedValue) {
        try {
            return JSON.parse(storedValue);
        }
        catch (e) {
            console.warn(`failed to parse ${key}; using defaultValue instead`);
        }
    }
    return defaultValue;
};
export const setInStore = (key, value) => {
    localStorageSet(key, JSON.stringify(value));
};

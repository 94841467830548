import { AuthTokenComponent } from 'constants/auth';
import { identifyUserByMembershipId } from 'helpers/eventTracking';
import { reportError } from 'sagas/errors/api';
import { Auth0Client } from 'services/auth0';
import FetchService from 'services/fetch';
import {} from './auth.types';
import * as lsHelpers from './localStorage';
import { getQueryParamValueFromUrl } from './queryParams';
export const getAuth0Token = async () => {
    if (Auth0Client.isAuthenticated && lsHelpers.getCurrentMembershipId()) {
        try {
            return await Auth0Client.getAccessTokenSilently();
        }
        catch (e) {
            reportError(e, {
                context: 'src/helpers/auth.ts - getAuth0Token',
                device: { browser: navigator.userAgent },
                user: { membershipId: lsHelpers.getCurrentMembershipId() },
                severity: 'warning',
            });
            return null;
        }
    }
    return null;
};
export const getMembershipAuthTokenFromLocalStorage = () => {
    const membershipData = lsHelpers.getCurrentMembershipData();
    return membershipData.authToken;
};
export const getURLToken = () => getQueryParamValueFromUrl('token');
export const getAuthToken = async () => {
    const localStorageToken = getMembershipAuthTokenFromLocalStorage();
    if (localStorageToken) {
        return `${AuthTokenComponent.TOKEN} ${localStorageToken}`;
    }
    const URLToken = getURLToken();
    if (URLToken) {
        return `${AuthTokenComponent.JWT} ${URLToken}`;
    }
    const auth0Token = await getAuth0Token();
    if (auth0Token) {
        return `${AuthTokenComponent.JWT_AUTH} ${auth0Token}`;
    }
    return null;
};
export const handleAddAuthTokenAndCurrentIds = async (response) => {
    if (!response || !response.membershipId) {
        return;
    }
    const currentMembershipsData = lsHelpers.getMembershipsDataFromLocalStorage() || {};
    const newMembershipId = response.membershipId;
    const newMembershipData = {
        [newMembershipId]: {
            authToken: response.userAuthToken,
            companyId: response.companyId,
            userId: response.userId,
        },
    };
    const mergedMembershipsData = Object.assign(currentMembershipsData, newMembershipData);
    lsHelpers.localStorageSet('currentMembershipId', newMembershipId);
    lsHelpers.localStorageSet('memberships', JSON.stringify(mergedMembershipsData));
    const authToken = await getAuthToken();
    if (authToken) {
        FetchService.setAuthHeader(authToken);
    }
    identifyUserByMembershipId(newMembershipId, {
        companyId: mergedMembershipsData[newMembershipId].companyId,
        userId: mergedMembershipsData[newMembershipId].userId,
    });
};
export const hasAuthToken = () => {
    const localStorageToken = getMembershipAuthTokenFromLocalStorage();
    return Boolean(localStorageToken || (Auth0Client.isAuthenticated && lsHelpers.getCurrentCompanyId()));
};

import { z } from 'zod';
export const singleFieldErrorModel = z.array(z.string()).transform((val) => val.join(', '));
export const multiFieldErrorModel = z.record(z.string(), z.array(z.string())).transform((val) => {
    return Object.keys(val).reduce((acc, fieldName) => {
        acc[fieldName] = val[fieldName].join(', ');
        return acc;
    }, {});
});
export const fieldErrorModel = singleFieldErrorModel.or(multiFieldErrorModel);
export const apiErrorModel = z
    .object({
    data: z.object({
        errors: z.object({
            general: z.object({ detail: fieldErrorModel }).optional(),
            fields: z.record(z.string(), fieldErrorModel).optional(),
        }),
    }),
})
    .transform((data) => data.data.errors);
export const getDerivedApiFieldErrors = (apiError) => {
    if (!apiError) {
        return null;
    }
    const parsedApiError = apiErrorModel.safeParse(apiError);
    return parsedApiError.success ? parsedApiError.data : null;
};

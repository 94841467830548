import { combineReducers } from 'redux';

import modalReducer from './modalReducer';
import navigationReducer from './navigationReducer';
import saveReducer from './saveReducer';
import signupCreateAccountReducer from './signupCreateAccountReducer';
import signupResendVerificationEmailReducer from './signupResendVerificationEmailReducer';
import signupSelectNamespaceReducer from './signupSelectNamespaceReducer';
import signupVerifyEmailReducer from './signupVerifyEmailReducer';

const signupReducer = combineReducers({
  createAccount: signupCreateAccountReducer,
  resendVerificationEmail: signupResendVerificationEmailReducer,
  selectNamespace: signupSelectNamespaceReducer,
  verifyEmail: signupVerifyEmailReducer,
  navigation: navigationReducer,
  save: saveReducer,
  modal: modalReducer,
});

export default signupReducer;

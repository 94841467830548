import PropTypes from 'prop-types';
import React from 'react';

import { isString } from 'helpers/utility';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import TooltipContentText from '../TooltipContentText';

/**
 * If `children` is a string, returns the string wrapped in our tooltip
 * text styling component. Otherwise, returns `children` as-is.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 * @constructor
 */
const TooltipContent = ({ children }) => {
  if (isString(children)) {
    return <TooltipContentText>{children}</TooltipContentText>;
  }

  return children;
};

TooltipContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default TooltipContent;

/**
 * Accepts a layer value and optionally increments it
 * @param layer
 * @param incrementAmount
 * @return {*}
 */
export const getZIndex = (layer, incrementAmount = 0) => {
  if (!layer || typeof layer !== 'number') {
    return null;
  }

  return layer + incrementAmount;
};

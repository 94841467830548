import PropTypes from 'prop-types';
import React from 'react';

import Checkbox from 'components/checkbox';
import { text } from 'components/hintTypes/constants';
import { Text } from 'components/text';

import { TextSize } from 'constants/styles/typography';

const ConfirmAssociateEmailWithMultipleCompanies = (props) => {
  const { errors, fieldName, isChecked, onChange } = props;

  return (
    <Checkbox
      className="margin-bottom--m"
      content={
        <Text.Semibold size={TextSize.LEVEL_300}>{text.emailAssociatedWithOtherCompaniesHint.text}</Text.Semibold>
      }
      errors={errors}
      isChecked={isChecked}
      name={fieldName}
      onChange={onChange}
    />
  );
};

ConfirmAssociateEmailWithMultipleCompanies.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  fieldName: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

ConfirmAssociateEmailWithMultipleCompanies.defaultProps = {
  errors: undefined,
  isChecked: undefined,
};

export default ConfirmAssociateEmailWithMultipleCompanies;

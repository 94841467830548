import {
  LedgerDiscountCalculationTypes,
  LedgerDiscountStyles,
  LedgerSettingsDiscountModeTypes,
} from 'constants/ledger';

import { isCurrentPathCreateItemBillView, isItemKindReceivable } from 'helpers/items';

/**
 * The invoice generator should be forced open if we have a Simple Item or Bill view
 */
export const isInvoiceGeneratorForcedOpen = (hasLedgerIntegration, isBillView = isCurrentPathCreateItemBillView()) => {
  const isSimpleItem = !hasLedgerIntegration;
  return !!(isBillView || isSimpleItem);
};

// ************************
// Invoice generator details
// ************************

/**
 * This method is used to determine whether to show the invoice number
 * and whether the date issued is the left-most field.
 * @param {object} item
 * @return {boolean}
 */
export const showInvoiceNumber = (item) => isItemKindReceivable(item);

// *************************************
// Invoice generator discount (QBO only)
// *************************************

/**
 * This method is used to determine whether the createItems item discount
 * discountCalculation is 'amount'
 * @return {boolean}
 */
export const isDiscountCalculationAmount = (discountCalculation) =>
  discountCalculation === LedgerDiscountCalculationTypes.AMOUNT;

/**
 * This method is used to determine whether the createItems item discount
 * discountCalculation is 'percentage'
 * @return {boolean}
 */
export const isDiscountCalculationPercentage = (discountCalculation) =>
  discountCalculation === LedgerDiscountCalculationTypes.PERCENTAGE;

/**
 * This method is used to determine whether the createItems item discount
 * style is 'pre-tax'
 * @return {boolean}
 */
export const isDiscountStylePreTax = (discountStyle) => discountStyle === LedgerDiscountStyles.PRE_TAX;

/**
 * This method is used to determine whether the createItems item discount
 * style is 'pre-tax'
 * @return {boolean}
 */
export const isDiscountStylePostTax = (discountStyle) => discountStyle === LedgerDiscountStyles.POST_TAX;

// ************************
// Invoice generator totals
// ************************

/**
 * This method is used to determine to show the discount text by the ledger
 * settings discount mode (item, line item)
 * @return {string}
 */
export const getDiscountTextByDiscountMode = (settings) => {
  switch (settings.discountMode) {
    case LedgerSettingsDiscountModeTypes.LINE_ITEM:
      return 'Includes Discount';

    case LedgerSettingsDiscountModeTypes.ITEM:
    default:
      return 'Discount';
  }
};

import classNames from 'classnames';
import React from 'react';
import { BankAccountViewType } from 'constants/ui';
import { isFundingAccountEligibleForRTP } from 'helpers/funding';
import { isNotEqual } from 'helpers/utility';
import BankAccountHeaderContentDateAdded from '../BankAccountHeaderContentDateAdded';
import BankAccountHeaderContentFlairItems from '../BankAccountHeaderContentFlairItems';
import BankAccountHeaderRtpEnabledTag from '../BankAccountHeaderRtpEnabledTag';
import {} from './BankAccountHeaderContentRight.types';
const BankAccountHeaderContentRight = ({ fundingAccount, menu, partnership, partnershipFundingAccount, viewType, }) => (React.createElement("div", { className: classNames('bank-account--header--content-right', {
        'align-items--center': isNotEqual(viewType, BankAccountViewType.PARTNER_ADDRESS),
    }) },
    isFundingAccountEligibleForRTP(fundingAccount) && (React.createElement("div", { className: "margin-right--xm" },
        React.createElement(BankAccountHeaderRtpEnabledTag, null))),
    React.createElement(BankAccountHeaderContentFlairItems, { partnership: partnership, partnershipFundingAccount: partnershipFundingAccount }),
    React.createElement(BankAccountHeaderContentDateAdded, { partnershipFundingAccount: partnershipFundingAccount }),
    menu));
export default BankAccountHeaderContentRight;

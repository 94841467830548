import { z } from 'zod';
export const OverlayModel = z.object({
    source: z.string(),
    value: z.string(),
    type: z.string(),
});
const OverlayRecord = z.record(z.array(OverlayModel));
export const LineItemOverlayDataModel = z.object({ lineItems: z.array(OverlayRecord).optional() });
export const OverlayDataModel = OverlayRecord.and(LineItemOverlayDataModel);
export const OverlaysApiResponseModel = z.object({
    data: OverlayDataModel,
});

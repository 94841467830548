import PropTypes from 'prop-types';
import React from 'react';

import { IconNames, SwalWrapper } from 'components';

import { membershipTexts } from 'constants/membership';
import { Intent } from 'constants/ui';

import { RevokeInviteConfirmationModalTitle, RevokeInviteConfirmationModalSubtitle } from './components';

// TODO: Move this to a common folder, since we use this in multiple places now.
/**
 * Component rendering SWAL content for confirming invite revocation
 * action
 * @param {ComponentProps} props
 * @param {Membership} props.isLoading
 * @param {Membership} props.membership
 * @param {Function} props.onConfirm
 * @return {StatelessComponent}
 */
const RevokeInviteConfirmationModal = ({ isLoading, membership, onConfirm, warningIcon, ...rest }) => {
  const swalTitle = <RevokeInviteConfirmationModalTitle membership={membership} warningIcon={warningIcon} />;
  const swalContent = <RevokeInviteConfirmationModalSubtitle membership={membership} />;

  return (
    <SwalWrapper
      confirmButtonProps={{
        icon: IconNames.REMOVE,
        onClick: onConfirm,
        text: membershipTexts.REVOKE_INVITE,
      }}
      contentClassname="margin-bottom--large"
      footerClassname="footer-ghost"
      intent={Intent.DANGER}
      isLoading={isLoading}
      subtitle={swalContent}
      title={swalTitle}
      {...rest}
    />
  );
};

RevokeInviteConfirmationModal.propTypes = {
  isLoading: PropTypes.bool,
  membership: PropTypes.shape({}).isRequired,
  onConfirm: PropTypes.func.isRequired,
  warningIcon: PropTypes.bool,
  contentClassname: PropTypes.string,
};

RevokeInviteConfirmationModal.defaultProps = {
  isLoading: undefined,
  warningIcon: undefined,
  contentClassname: undefined,
};

export default RevokeInviteConfirmationModal;

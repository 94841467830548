export const microDepositFieldPlaceholder = {
  microDeposit_1: 'Deposit amount 1',
  microDeposit_2: 'Deposit amount 2',
};

export const microdepositSuccessMessage = 'Your bank account has been verified!';

export const submitMicrodepositsButtonText = 'Verify bank account';

export const verifyFundingAccountModalTitle = 'Verify bank account';

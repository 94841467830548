import { updateMembershipRoutine } from 'actions/routines/membership';

import { getAllIdsReducerResult } from 'store/redux';

import { GET_CURRENT_COMPANY_SUCCESS, UPDATE_CURRENT_COMPANY_SUCCESS } from 'types/currentCompany';
import { FETCH_MEMBERSHIP_SUCCESS, FETCH_MEMBERSHIPS_SUCCESS, UPDATE_MEMBERSHIP_SUCCESS } from 'types/memberships';

const allDocumentsReducer = (state = [], action) => {
  switch (action.type) {
    case GET_CURRENT_COMPANY_SUCCESS:
    case FETCH_MEMBERSHIP_SUCCESS:
    case FETCH_MEMBERSHIPS_SUCCESS:
    case UPDATE_CURRENT_COMPANY_SUCCESS:
    case UPDATE_MEMBERSHIP_SUCCESS:
    case updateMembershipRoutine.SUCCESS:
      return getAllIdsReducerResult(state, action.payload.document);

    default:
      return state;
  }
};

export default allDocumentsReducer;

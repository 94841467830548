/**
 * @fileOverview Defines the initial state for featureSettings in the redux store.
 * @module reducers/featureSettings/initialState
 */

export const initialState = {
  activeSettings: {},
  errors: null,
  isFetching: false,
};

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import {
  FETCH_PLATFORM_PARTNERSHIPS_SUCCESS,
  FETCH_PLATFORM_PARTNERSHIPS_SEARCH_SUCCESS,
  SUBMIT_PLATFORM_PARTNERSHIP_SUCCESS,
  SUBMIT_LEDGER_PARTNERSHIP_MERGE_SUCCESS,
} from 'types/integrations';

const platformPartnershipsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PLATFORM_PARTNERSHIPS_SUCCESS:
      // Append to the state when paginating
      if (action.payload.meta.pagination.offset > 0) {
        return deepMergeWithArrayReplacement(
          state,
          getObjectsByIdWithRelationships(action.payload.partnership, ['partnershipMembers']),
        );
      }

      // Otherwise, merge with state
      return {
        ...state,
        ...getObjectsByIdWithRelationships(action.payload.partnership, ['partnershipMembers']),
      };

    case FETCH_PLATFORM_PARTNERSHIPS_SEARCH_SUCCESS:
      // Append to the state when paginating
      if (action.payload.meta.pagination.offset > 0) {
        return deepMergeWithArrayReplacement(
          state,
          getObjectsByIdWithRelationships(action.payload.partnership, ['partnershipMembers']),
        );
      }

      // Otherwise, replace the current state
      return getObjectsByIdWithRelationships(action.payload.partnership, ['partnershipMembers']);

    case SUBMIT_LEDGER_PARTNERSHIP_MERGE_SUCCESS:
    case SUBMIT_PLATFORM_PARTNERSHIP_SUCCESS:
      return deepMergeWithArrayReplacement(
        state,
        getObjectsByIdWithRelationships(action.payload.partnership, ['partnershipMembers']),
      );

    default:
      return state;
  }
};

export default platformPartnershipsByIdReducer;

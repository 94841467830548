import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { ReduxFormFieldRenderInput } from 'components/input';

import onEditPropType from './onEditPropType';

import './EditButtonInput.scss';

/**
 * Input field with edit button on the right hand side. When the button is clicked, the field become editable. However,
 * the field may also be disabled, which omits the edit button and displays the values in read-only mode.
 * @param {ComponentProps} props
 * @returns {null|StatelessComponent}
 */
const EditButtonInput = ({
  editAllowed,
  EditButton,
  editField,
  fieldClassNames,
  format,
  isDisabled,
  isRequired,
  lockedTooltipContent,
  maxLength,
  name,
  normalize,
  onEdit,
  placeholder,
  showField,
  tooltipContent,
  tooltipProps,
  type,
  validate,
  label,
  setRef,
}) => {
  if (!showField) {
    return null;
  }

  const isLocked = Boolean(!editAllowed && isDisabled);
  const addonTooltipContent = isLocked ? lockedTooltipContent : tooltipContent;

  return (
    <Field
      className={clsx(fieldClassNames)}
      component={ReduxFormFieldRenderInput}
      EditButton={EditButton}
      format={format}
      isCharacterCountShown={false}
      isDisabled={isDisabled}
      isEditProtected={!editField && editAllowed}
      isLocked={!editField && isLocked}
      isRequired={isRequired}
      label={label}
      maximumCharacterCount={maxLength}
      name={name}
      normalize={normalize}
      onEdit={onEdit}
      placeholder={placeholder}
      setRef={setRef}
      tooltipContent={addonTooltipContent}
      tooltipProps={tooltipProps}
      type={type}
      validate={validate}
    />
  );
};

EditButtonInput.propTypes = {
  editAllowed: PropTypes.bool,
  EditButton: PropTypes.func,
  editField: PropTypes.bool,
  fieldClassNames: PropTypes.string,
  format: PropTypes.func,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  lockedTooltipContent: PropTypes.node,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  normalize: PropTypes.func,
  onEdit: onEditPropType,
  placeholder: PropTypes.string,
  setRef: PropTypes.oneOfType([PropTypes.shape({ current: PropTypes.node }), PropTypes.func]),
  showField: PropTypes.bool,
  tooltipContent: PropTypes.string,
  tooltipProps: PropTypes.shape({}),
  type: PropTypes.string,
  validate: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.func), PropTypes.func]),
};

EditButtonInput.defaultProps = {
  editAllowed: undefined,
  EditButton: undefined,
  editField: undefined,
  fieldClassNames: undefined,
  format: undefined,
  isDisabled: true,
  isRequired: undefined,
  lockedTooltipContent: undefined,
  label: undefined,
  maxLength: undefined,
  normalize: undefined,
  onEdit: undefined,
  placeholder: undefined,
  setRef: undefined,
  showField: undefined,
  tooltipContent: 'Edit',
  tooltipProps: {},
  type: 'text',
  validate: undefined,
};

export default EditButtonInput;

import React from 'react';
import { ButtonV2, TooltipMUIConditionalWrapper } from 'components';
import { ButtonSize } from 'constants/button';
import { colors, sizes } from 'constants/styles';
import { Intent } from 'constants/ui';
import { isFn } from 'helpers/utility';
import { IconToActionTypeMap } from './constants';
const PaymentMethodManageActionButton = ({ actionType, onClick, tooltipProps, ...props }) => {
    if (!isFn(onClick)) {
        return null;
    }
    return (React.createElement(TooltipMUIConditionalWrapper, { tooltipProps: tooltipProps },
        React.createElement(ButtonV2, { className: "payment_method__manage-action-button", intent: Intent.NEUTRAL, leftIconColor: colors.colorGreyXDarkHex, leftIconName: IconToActionTypeMap[actionType], leftIconSize: sizes.iconSizes.LARGE, onClick: onClick, size: ButtonSize.SMALL, ...props })));
};
export default PaymentMethodManageActionButton;

import React from 'react';
import { Icon, IconNames, TooltipMUIConditionalWrapper } from 'components';
import { colors, sizes } from 'constants/styles';
import { TooltipPadding } from 'constants/tooltip';
const DataCardTooltipConditional = ({ icon, iconProps, tooltipContent }) => {
    const iconName = tooltipContent ? icon || IconNames.INFO_SIGN : icon;
    const tooltipProps = tooltipContent
        ? {
            title: tooltipContent,
            arrow: true,
            padding: TooltipPadding.SKINNY,
        }
        : undefined;
    return (React.createElement(TooltipMUIConditionalWrapper, { tooltipProps: tooltipProps },
        React.createElement(Icon, { color: colors.colorGreyDarkHex, icon: iconName, size: sizes.iconSizes.XX_LARGE, ...iconProps })));
};
export default DataCardTooltipConditional;

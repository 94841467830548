import {} from 'react';
import { useDispatch } from 'react-redux';
import { openNotificationBar } from 'actions/ui';
const useNotification = () => {
    const dispatch = useDispatch();
    return (message, options) => {
        dispatch(openNotificationBar(message, options));
    };
};
export default useNotification;

import { ItemKinds } from 'constants/item';

export const itemForCompanyQueries = ['company_id', 'item_id'];

export const viewFileQueries = ['name', 'title', 'url'];

export const DeepLinkType = {
  FILE: 'file',
  INVOICE_PAYABLE: ItemKinds.PAYABLE,
  INVOICE_RECEIVABLE: ItemKinds.RECEIVABLE,
};

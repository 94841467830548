import PropTypes from 'prop-types';
import React from 'react';

import { DisclosureListHeadingStyle, HeadingBlockDisclosureList } from 'components/disclosureList';
import { IconNames } from 'components/icon';
import { dataHelpers, textHelpers } from 'components/transactionDetails/helpers';

import { margin } from 'constants/styles';

import { usePaymentData } from 'hooks';

const TransactionDetails = (props) => {
  const { transaction, payment: paymentOrPaymentId } = props;
  const payment = usePaymentData(paymentOrPaymentId);

  const listTitle = dataHelpers.getDetailsTitleForTransaction(transaction, props);
  const tooltipText = textHelpers.getTransactionDetailsHeaderTooltipText(transaction);

  const listItems = React.useMemo(
    () =>
      dataHelpers.getDetailsDataForTransaction(transaction, {
        ...props,
        payment,
      }),
    [transaction, props, payment],
  );

  return (
    <HeadingBlockDisclosureList
      bodyClassName="transaction-details--body"
      className="transaction-details"
      headerIconName={IconNames.DOLLAR}
      headerText={listTitle}
      headerTooltipContentMargin={margin.medium}
      headerTooltipText={tooltipText}
      headingStyle={DisclosureListHeadingStyle.THIN}
      isBodyInset
      items={listItems}
      keyExtractor={(item) => item.key}
      startOpen={false}
    />
  );
};

TransactionDetails.propTypes = {
  billingDataByCode: PropTypes.shape({}).isRequired,
  companiesById: PropTypes.shape({}).isRequired,
  /* destinationCompany needed in getDetailsDataForTransaction helper */
  /* eslint-disable-next-line react/no-unused-prop-types */
  destinationCompany: PropTypes.shape({}),
  fundingAccountsById: PropTypes.shape({}).isRequired,
  fundingInfoAddressesById: PropTypes.shape({}).isRequired,
  fundingInfoBankAccountsById: PropTypes.shape({}).isRequired,
  item: PropTypes.shape({}).isRequired,
  /* onDownloadFile needed in getDetailsDataForTransaction helper */
  /* eslint-disable-next-line react/no-unused-prop-types */
  onDownloadFile: PropTypes.func.isRequired,
  /* originationCompany needed in getDetailsDataForTransaction helper */
  /* eslint-disable-next-line react/no-unused-prop-types */
  originationCompany: PropTypes.shape({}),
  transaction: PropTypes.shape({}).isRequired,
  transactionAch: PropTypes.shape({}),
  transactionCheck: PropTypes.shape({}),
  transactionPaymentConfirmation: PropTypes.shape({}),
};

TransactionDetails.defaultProps = {
  destinationCompany: undefined,
  originationCompany: undefined,
  transactionAch: undefined,
  transactionCheck: undefined,
  transactionPaymentConfirmation: undefined,
};

export default TransactionDetails;

import PropTypes from 'prop-types';
import React from 'react';

import { BlockListItem } from 'components/listItems';

import AccountDetailsSectionItemValue from './AccountDetailsSectionItemValue';

const AccountDetailsSectionItem = ({ item }) => (
  <BlockListItem
    label={item.label}
    value={<AccountDetailsSectionItemValue>{item.value}</AccountDetailsSectionItemValue>}
  />
);

AccountDetailsSectionItem.propTypes = {
  item: PropTypes.shape().isRequired,
};

AccountDetailsSectionItem.defaultProps = {
  item: {},
};

export default AccountDetailsSectionItem;

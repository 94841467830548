import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { Text } from 'components';

import { BreadcrumbsTextVariant } from 'constants/breadcrumbs';
import { typography } from 'constants/styles';

import { getBreadcrumbLineHeight, getBreadcrumbTextSize } from 'helpers/breadcrumbs';
import { allValues } from 'helpers/utility';

/**
 * Component for rendering a single breadcrumb.
 * @param {ComponentProps} props
 * @param {boolean} props.isLastBreadCrumb
 * @param {Object} props.link
 * @param {BreadcrumbsTextVariant} variant
 * @return {StatelessComponent}
 */
const Breadcrumb = ({ isLastBreadCrumb, link, variant }) => {
  const { label, state = {}, url } = link;

  // it doesn't have a URL and it's not the last breadcrumb, so users can't navigate back to it by clicking, but
  // we still have some kind of visual filtering.
  const isLinklessMiddleBreadcrumb = !url && !isLastBreadCrumb;
  const isMiddleBreadcrumb = url || isLinklessMiddleBreadcrumb;

  const textColor = isMiddleBreadcrumb ? typography.TextColor.DEFAULT : typography.TextColor.BLACK;
  // Wrap it in a link if there is an url
  const linkOrLabel = url ? (
    <Link className="truncate primary only-hover" to={{ pathname: url, state }}>
      {label}
    </Link>
  ) : (
    label
  );

  const textSize = getBreadcrumbTextSize(variant);

  const lineHeight = getBreadcrumbLineHeight(variant);

  return (
    <li className="breadcrumb-li">
      <Text.Semibold
        className={classNames('truncate', {
          [lineHeight]: true,
        })}
        color={textColor}
        size={textSize}
      >
        {linkOrLabel}

        {isMiddleBreadcrumb && (
          <span aria-hidden="true" className="margin-left--xm margin-right--xm">
            /
          </span>
        )}
      </Text.Semibold>
    </li>
  );
};

Breadcrumb.propTypes = {
  isLastBreadCrumb: PropTypes.bool.isRequired,
  link: PropTypes.shape({
    label: PropTypes.string.isRequired,
    state: PropTypes.shape(),
    url: PropTypes.string,
  }).isRequired,
  variant: PropTypes.oneOf(allValues(BreadcrumbsTextVariant)).isRequired,
};

export default Breadcrumb;

import { queryClient } from '@routable/shared';
import { taxTools } from '@routable/taxes';
import { vendorRiskCheckReport } from '@routable/vendor-risk';
import { SubmissionError } from 'redux-form';
import { all, call, put, select } from 'redux-saga/effects';
import { handleRequestErrors } from 'actions/errors';
import { fetchSinglePartnershipRequest } from 'actions/partnership';
import { createAddressRoutine, updateAddressRoutine } from 'actions/routines/addresses';
import { closeSidePanel } from 'actions/sidePanels';
import { showSuccessUi } from 'actions/ui';
import { sidePanelNameAddOrUpdateVendorRegisteredAddress } from 'constants/sidePanels';
import { SuccessIndicatorMessages } from 'constants/ui';
import { parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { sagaWatcher } from 'helpers/saga';
import { partnerCompanyFromPartnershipPropSelector } from 'queries/companyCompoundSelectors';
import { createCompanyMailingAddress, updateCompanyMailingAddress } from './api';
export function* createAddressSaga(action) {
    let errorData = {};
    try {
        const { props, values } = Object(action.payload);
        const company = yield select(partnerCompanyFromPartnershipPropSelector, props);
        const response = yield call(createCompanyMailingAddress, values, company.id);
        if (response.ok) {
            yield all([
                put(createAddressRoutine.success(response.data)),
                put(showSuccessUi(SuccessIndicatorMessages.VENDOR_ADDRESS_ADDED)),
                put(closeSidePanel({
                    name: sidePanelNameAddOrUpdateVendorRegisteredAddress,
                })),
                put(fetchSinglePartnershipRequest(props.partnership.id)),
            ]);
            return;
        }
        errorData = parseErrorResponse(response);
    }
    catch (err) {
        errorData = parseCaughtError(err);
    }
    if (errorData?.fields) {
        const errorPayload = new SubmissionError(errorData.fields);
        yield put(createAddressRoutine.failure(errorPayload));
        return;
    }
    yield put(handleRequestErrors(createAddressRoutine.failure, errorData));
}
export function* updateAddressSaga(action) {
    let errorData = {};
    yield put(updateAddressRoutine.request());
    try {
        const { props, values } = action.payload;
        const company = yield select(partnerCompanyFromPartnershipPropSelector, props);
        const response = yield call(updateCompanyMailingAddress, company, values);
        if (response.ok) {
            yield all([
                put(updateAddressRoutine.success(response.data)),
                put(closeSidePanel({
                    name: sidePanelNameAddOrUpdateVendorRegisteredAddress,
                })),
                put(showSuccessUi(SuccessIndicatorMessages.VENDOR_ADDRESS_UPDATED)),
                put(fetchSinglePartnershipRequest(props.partnership.id)),
            ]);
            queryClient.invalidateQueries({ queryKey: [taxTools] });
            queryClient.invalidateQueries({ queryKey: [vendorRiskCheckReport] });
            return;
        }
        errorData = parseErrorResponse(response);
    }
    catch (err) {
        errorData = parseCaughtError(err);
    }
    if (errorData?.fields) {
        const errorPayload = new SubmissionError(errorData.fields);
        yield put(updateAddressRoutine.failure(errorPayload));
        return;
    }
    yield put(handleRequestErrors(updateAddressRoutine.failure, errorData));
}
export default function* addresses() {
    yield sagaWatcher([
        {
            type: createAddressRoutine.TRIGGER,
            saga: createAddressSaga,
        },
        {
            type: updateAddressRoutine.TRIGGER,
            saga: updateAddressSaga,
        },
    ]);
}

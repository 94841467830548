import { UserType } from 'enums/user';

/**
 * UserTypes describes the type of company.
 * @enum {string}
 */
export const UserTypes = {
  BUSINESS: UserType.BUSINESS,
  PERSONAL: UserType.PERSONAL,
};

export const UserTypeLabels = {
  [UserTypes.BUSINESS]: 'Business',
  [UserTypes.PERSONAL]: 'Personal',
};

export const UserTypeDescriptionText = {
  [UserTypes.BUSINESS]: 'e.g. Sole proprietorship, LLC, Corporation',
  [UserTypes.PERSONAL]: 'e.g. Contractor or Freelancer',
};

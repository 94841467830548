import PropTypes from 'prop-types';
import React from 'react';

import { LegalBusinessNameInput } from 'components/form';

import { formStyles } from 'constants/styles';

/**
 * Displays input which may/may not be editable for Legal Business Name. In the future, this field should
 * update for Individuals to be the same as First Name + Last Name.
 * @param {ComponentProps} props
 * @param {string} props.currentValue
 * @param {boolean} props.editField - If the field is in edit mode
 * @param {boolean} props.isDisabled
 * @param {function} props.onEdit - Should open the EditButtonInput for editing.
 * @returns {StatelessComponent}
 */
const ConfirmLegalBusinessName = ({ currentValue, editField, isDisabled, onEdit }) => (
  <LegalBusinessNameInput
    editAllowed={!isDisabled}
    editField={!currentValue || editField}
    fieldClassNames={formStyles.field.xl.left}
    isDisabled={isDisabled}
    name="form.company.name"
    onEdit={onEdit}
    showField
    verificationUpdateAllowed
  />
);

ConfirmLegalBusinessName.propTypes = {
  currentValue: PropTypes.string,
  editField: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
};

ConfirmLegalBusinessName.defaultProps = {
  currentValue: undefined,
  editField: undefined,
  isDisabled: undefined,
};

export default ConfirmLegalBusinessName;

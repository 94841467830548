import { isExternalPathAccept } from 'helpers/external';
import { getQueryParam } from 'helpers/queryParams';

import { externalOnboardingStepSelector } from 'selectors/externalOnboardingSelectors';

import { storeAccessor as store } from 'store/accessor';

/**
 * Helper to  get all onboarding steps
 * @return {Array}
 */
export const getExternalOnboardingSteps = () => {
  const itemId = getQueryParam('item_id');

  let steps = [];

  if (isExternalPathAccept(window.location)) {
    steps = [
      { num: 1, description: 'Add contact info' },
      { num: 2, description: 'Add tax info' },
      {
        num: 3,
        description: itemId ? 'Accept payment' : 'Select payment method',
      },
    ];
  } else {
    steps = [
      { num: 1, description: 'Add contact info' },
      { num: 2, description: 'Add billing info' },
      {
        num: 3,
        description: itemId ? 'Make payment' : 'Select payment method',
      },
    ];
  }

  return steps;
};

/**
 * Helper to get the current step number
 * @return {object}
 */
export const getExternalOnboardingCurrentStepNumber = () => {
  const reduxState = store.getState();
  return externalOnboardingStepSelector(reduxState);
};

/**
 * Helper to get the current step index/position in the array
 * @return {number}
 */
export const getExternalOnboardingCurrentStepIndex = () => {
  const stepNumber = getExternalOnboardingCurrentStepNumber();
  return stepNumber - 1;
};

/**
 * Helper to get the current step's information
 * @return {object}
 */
export const getExternalOnboardingCurrentStep = () => {
  const steps = getExternalOnboardingSteps();
  const currentStepIndex = getExternalOnboardingCurrentStepIndex();
  return steps[currentStepIndex];
};

/**
 * Helper to get the next step's information
 * @return {object}
 */
export const getExternalOnboardingNextStep = () => {
  const steps = getExternalOnboardingSteps();
  const currentStepIndex = getExternalOnboardingCurrentStepIndex();
  return steps[currentStepIndex + 1];
};

import PropTypes from 'prop-types';
import React from 'react';

import { fullCountryNameLookup } from 'constants/i18n';

import { getStreetAddress } from 'modules/address/helpers/streetAddress';

import './Address.scss';

const Address = ({ city, country, postalcode, printCompany, printName, state, streetAddress, streetAddressUnit }) => (
  <p className="address-container" data-fullstory>
    {printName && <span className="address-component">{printName}</span>}
    {printCompany && <span className="address-component">{printCompany}</span>}
    <span className="address-component">{getStreetAddress(streetAddress, streetAddressUnit)}</span>
    <span className="address-component">{`${city}, ${state} ${postalcode}`}</span>
    {country && <span className="address-component">{fullCountryNameLookup[country]}</span>}
  </p>
);

Address.propTypes = {
  city: PropTypes.string.isRequired,
  country: PropTypes.string,
  postalcode: PropTypes.string.isRequired,
  printCompany: PropTypes.string,
  printName: PropTypes.string,
  state: PropTypes.string.isRequired,
  streetAddress: PropTypes.string.isRequired,
  streetAddressUnit: PropTypes.string,
};

Address.defaultProps = {
  country: undefined,
  printCompany: undefined,
  printName: undefined,
  streetAddressUnit: undefined,
};

export default Address;

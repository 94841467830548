export const AccountStatus = {
  ACTIVE: 'ACTIVE',
  ACTIVE_PARTNER: 'ACTIVE_PARTNER',
  ACTIVE_PARTNER_ADDRESS: 'ACTIVE_PARTNER_ADDRESS',
  DELETED: 'DELETED',
  DISABLED: 'DISABLED',
  INACTIVE_PARTNER: 'INACTIVE_PARTNER',
  PENDING: 'PENDING',
};

export const AccountStatusText = {
  [AccountStatus.ACTIVE]: 'Active',
  [AccountStatus.ACTIVE_PARTNER]: 'Account added',
  [AccountStatus.ACTIVE_PARTNER_ADDRESS]: 'Address added',
  [AccountStatus.DELETED]: 'Deleted',
  [AccountStatus.DISABLED]: 'Disabled',
  [AccountStatus.INACTIVE_PARTNER]: 'Inactive',
  [AccountStatus.PENDING]: 'Pending account verification',
};

export const AccountTabsStatus = {
  ACTIVE: 'Available',
  DELETED: 'Deleted',
  DISABLED: 'Disabled',
};

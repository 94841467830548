import { StatusIndicator } from '@routable/gross-ds';
import React from 'react';
import { FlexCol, FlexRow } from 'components';
import { getExternalItemsItemActionText } from 'helpers/external';
import { itemStatusToText, itemStatusToVariant } from 'helpers/status';
import { getOriginal } from 'helpers/tables';
const ExternalItemsListTableItemStatus = ({ row }) => {
    const item = getOriginal({ row });
    const { status } = item;
    return (React.createElement(FlexCol, null,
        React.createElement(FlexRow, { className: "align-items--center", stackOnMobile: false },
            React.createElement(StatusIndicator, { className: "text-xs", status: itemStatusToVariant[status] }, itemStatusToText[status])),
        React.createElement("span", { className: "text-xs text-grey-60" }, getExternalItemsItemActionText(item))));
};
export default ExternalItemsListTableItemStatus;

import { combineReducers } from 'redux';

import allThreadsReducer from './allThreadsReducer';
import errorReducer from './errorReducer';
import fetchReducer from './fetchReducer';
import lastUpdatedReducer from './lastUpdatedReducer';
import threadsByIdReducer from './threadsByIdReducer';

const threadsReducer = combineReducers({
  allIds: allThreadsReducer,
  byId: threadsByIdReducer,
  errors: errorReducer,
  isFetching: fetchReducer,
  lastUpdated: lastUpdatedReducer,
});

export default threadsReducer;

import { createLastFetchedReducer } from 'store/redux';

import {
  FETCH_MEMBERSHIPS_FAILURE,
  FETCH_MEMBERSHIPS_REQUEST,
  FETCH_MEMBERSHIPS_SUCCESS,
  FETCH_MEMBERSHIP_FAILURE,
  FETCH_MEMBERSHIP_REQUEST,
  FETCH_MEMBERSHIP_SUCCESS,
} from 'types/memberships';

export const successCases = [FETCH_MEMBERSHIP_SUCCESS, FETCH_MEMBERSHIPS_SUCCESS];
export const requestCases = [
  FETCH_MEMBERSHIP_FAILURE,
  FETCH_MEMBERSHIP_REQUEST,
  FETCH_MEMBERSHIPS_REQUEST,
  FETCH_MEMBERSHIPS_FAILURE,
];

const lastFetchedReducer = createLastFetchedReducer(successCases, requestCases);

export default lastFetchedReducer;

import React from 'react';
import { Text, WhiteSpace } from 'components/text';
import { getPartnershipType } from 'helpers/partnerships';
import { apostrophiseString } from 'helpers/stringHelpers';
import {} from './PaymentMethodAccountNumberLockedTooltipContent.types';
const PaymentMethodAccountNumberLockedTooltipContent = ({ partnership, }) => {
    const partnershipType = getPartnershipType(partnership);
    return (React.createElement(Text.Regular, { className: "payment-method-account-number-locked-tooltip-content" },
        "This account number has been securely encrypted through your",
        React.createElement(WhiteSpace, null),
        apostrophiseString(partnershipType),
        React.createElement(WhiteSpace, null),
        "bank connection and we are unable to show the full account number."));
};
export default PaymentMethodAccountNumberLockedTooltipContent;

import PropTypes from 'prop-types';
import React from 'react';

import { Row } from 'components';

/**
 * Block dynamic tables section. Flex row that wraps.
 * @param {*} children
 * @param {StringMaybe} className
 * @param {*} sectionLabel
 * @return {StatelessComponent}
 */
const BlockSection = ({ children, className, label }) => (
  <div className={className}>
    {label}

    <Row flexWrap="wrap">{children}</Row>
  </div>
);

BlockSection.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.node,
};

BlockSection.defaultProps = {
  children: undefined,
  className: undefined,
  label: undefined,
};

export default BlockSection;

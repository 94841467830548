export const END_OF_LINE_REGEX = /(?:\r\n|\r)/g;
export const REMOVE_NAMES_FROM_MENTIONS_IN_TEXT_REGEX = /(\[mention:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}])(\((.*?)\))/gi;
export const DATE = /\d{1,2}\/\d{1,2}\/(\d{2}|\d{4})/i;
export const DATE_ONLY = new RegExp(`^${DATE.source}$`, 'i');
export const ISO_DATE = /\d{4}-\d{2}-\d{2}/i;
export const ISO_DATE_ONLY = new RegExp(`^${ISO_DATE.source}$`, 'i');
export const TIME = /(\d{1,2}):(\d{2})(:(\d{2}))?\s?([ap]m)?/i;
export const TIME_ONLY = new RegExp(`^${TIME.source}$`, 'i');
export const DATE_TIME = new RegExp(`^${DATE.source} ${TIME.source}$`, 'i');
export const HTML_SCRIPT_CONTENT_REGEX = /<script\b[^<]*(?:(?!<\/script\s*>)<[^<]*)*<\/script\s*>/gi;
export const HTML_STYLE_CONTENT_REGEX = /<style\b[^<]*(?:(?!<\/style\s*>)<[^<]*)*<\/style\s*>/g;
export const HTML_ELEMENT_STYLE_ATTRIBUTE_REGEX = /\s+style="[^"]*"/g;
export const URL = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
export const NUMERIC = /^[0-9]*$/;
export const ALPHANUMERIC = /^[a-zA-Z0-9]*$/;
export const AMOUNT_WITH_CURRENCY = /\d([,.]\d{1,3})*\s([A-Z]{3})/;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { shouldShowStepperProgressBar } from 'helpers/ui';
import { and, hasLength } from 'helpers/utility';

import ExternalOnboardingProgressBarStepperContainer from 'modules/external/externalOnboarding/ExternalOnboardingProgressBarStepperContainer';
import ExternalFlowFooter from 'modules/external/global/presenters/ExternalFlowFooter';
import ExternalFlowHeader from 'modules/external/global/presenters/ExternalFlowHeader';

import './ExternalWorkflowWrapper.scss';

/**
 * Wrapper component for rendering external workflow screens. It wraps passed children
 * with common external flow header and footer components and it adds some specific styling.
 * @param {ComponentProps} props
 * @param {Brand} props.brand
 * @param {Company} props.company
 * @param {NodeMaybe} props.children
 * @param {StringMaybe} props.className
 * @param {ObjectMaybe} props.currentStep
 * @param {Boolean} props.isCentered
 * @param {ObjectMaybe[]} props.steps
 * @return {StatelessComponent}
 */
const ExternalWorkflowWrapper = ({ brand, company, children, className, currentStep, isCentered, steps }) => {
  const showProgressBar = and(hasLength(steps), shouldShowStepperProgressBar(currentStep));

  return (
    <div className="external-flow-wrapper" style={{ borderLeftColor: brand.primaryColor }}>
      <ExternalFlowHeader brand={brand} company={company} />

      {showProgressBar && (
        <div className="external-flow-wrapper__progress-bar">
          <ExternalOnboardingProgressBarStepperContainer steps={steps} />
        </div>
      )}

      <div
        className={classNames('external-flow-wrapper__content', {
          'external-flow-wrapper__content--centered': isCentered,
          [className]: !!className,
        })}
      >
        {children}
      </div>

      <ExternalFlowFooter />
    </div>
  );
};

ExternalWorkflowWrapper.propTypes = {
  brand: PropTypes.shape({
    primaryColor: PropTypes.string,
    logo: PropTypes.string,
  }).isRequired,
  company: PropTypes.shape({}).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  currentStep: PropTypes.shape({}),
  isCentered: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.shape({})),
};

ExternalWorkflowWrapper.defaultProps = {
  children: undefined,
  className: undefined,
  currentStep: undefined,
  isCentered: undefined,
  steps: undefined,
};

export default ExternalWorkflowWrapper;

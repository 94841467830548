import { TinTypes } from 'constants/taxes';

import { isCurrentCompanyTypePersonal } from 'helpers/currentCompany';
import { isExclusivelyVendor } from 'helpers/partnerships';
import { anyValues } from 'helpers/utility';

/**
 * Helper to get the correct tinType for the PartnerCompanyGeneral form submission
 * @param {Company} company
 * @param {boolean} businessFormShouldShowSSN
 */
export const getTinTypeForPartnerCompanyGeneralPayload = (company, businessFormShouldShowSSN) => {
  if (isCurrentCompanyTypePersonal(company) || businessFormShouldShowSSN) {
    return TinTypes.SSN;
  }

  return TinTypes.EIN;
};

/**
 * Transforms data for submitting updated partner company info to update company api request
 * @param {object} options
 * @param {Company} options.company
 * @param {Company} options.currentCompany
 * @param {object} options.formUI
 * @param {Partnership} options.partnership
 * @return {object}
 */
export const getExternalPartnershipRequestCompanyPayload = ({
  company,
  companyInfoId,
  currentCompany,
  formUI = {},
  partnership,
  partner,
}) => {
  const isExclusivelyCustomerToRCTM = isExclusivelyVendor(partnership);
  const { info, ...companyValues } = company;
  const companyInfo = { ...info };
  const companyPayload = {
    id: currentCompany.id,
    ...companyValues,
  };

  // Remove company name if submitting for a personal account
  if (isCurrentCompanyTypePersonal(company)) {
    delete companyPayload.name;
  }

  if (isExclusivelyCustomerToRCTM) {
    // return without CompanyInfo resource
    return companyPayload;
  }

  // The ID of the companyInfo is necessary for patching data that has already
  // been submitted. Without this ID, the backend will return a 400 error.
  // DEV-4900 TODO: There is an edge case where a vendor tries switching between
  // a "business" and "personal" companyType while submitting this data.
  companyInfo.id = currentCompany.info;

  // Handle tin and tinType
  if (info?.tin) {
    // Add tinType if submitting tin
    companyInfo.tinType = getTinTypeForPartnerCompanyGeneralPayload(company, formUI.businessFormShouldShowSSN);
  } else {
    // Remove tin if not updating it
    delete companyInfo.tin;
  }

  // Create an object for companyInfo that drops the id because we don't care
  // about the id in future checks.
  const companyInfoWithoutId = { ...companyInfo };
  delete companyInfoWithoutId.id;

  // Only send CompanyInfo if it contains any values.
  // Sending empty object will cause BE errors.
  companyPayload.info = anyValues(companyInfoWithoutId) ? companyInfo : undefined;

  /**
   * If partner?.governmentId is present, info object is required.
   * governmentId is an object, with type and value. It will defined if there's
   * a government id definition for the country code of the vendor under constants.
   * We are changing from tin and tinType to governmentId,
   * which has sub-properties value and type.
   */
  if (partner?.governmentId) {
    companyPayload.info = {
      id: companyInfoId,
      governmentId: partner.governmentId,
    };
  }

  return companyPayload;
};

/**
 * Transforms data for submitting updated partner company info to update company api request
 * @param {object} options
 * @param {object} [options.meta]
 * @param {object} options.partnershipRequest
 * @param {object} options.payload
 * @param {Id} options.userId
 * @return {object}
 */
export const getExternalPartnershipRequestMembershipPayload = ({ meta, partnershipRequest, payload, userId }) => {
  const { phoneNumber, user } = payload.membership;

  return {
    id: partnershipRequest.membership,
    meta,
    phoneNumber,
    user: { ...user, id: userId },
  };
};

import getRelationships from 'store/redux';

import { GET_CURRENT_COMPANY_SUCCESS, UPDATE_CURRENT_COMPANY_SUCCESS } from 'types/currentCompany';

const getCurrentCompanyController = (info) => {
  let currentCompanyController = null;

  if (!info) {
    return currentCompanyController;
  }

  Object.keys(info).forEach((infoId) => {
    const relationships = getRelationships(info, infoId, 'controller');

    currentCompanyController = relationships.controller;
  });

  return currentCompanyController;
};

const companyControllerReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CURRENT_COMPANY_SUCCESS:
    case UPDATE_CURRENT_COMPANY_SUCCESS:
      return getCurrentCompanyController(action.payload.companyInfo);

    default:
      return state;
  }
};

export default companyControllerReducer;

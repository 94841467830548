import React from 'react';

import { Icon, IconNames } from 'components/icon';
import { VerticalCenterCol } from 'components/layout';

import { colors, sizes } from 'constants/styles';

/**
 * Icon for the creatable 'new' option.
 */
const NewOptionIcon = () => (
  <VerticalCenterCol>
    <Icon
      className="new-option-icon"
      color={colors.colorMainJordanHex}
      icon={IconNames.ADD}
      marginRight={sizes.spacing.EXTRA_MEDIUM}
    />
  </VerticalCenterCol>
);

export default NewOptionIcon;

import * as types from 'types/oldVersion';

/**
 * Redux action called when server returns status 406.
 * Either opens oldVersionMode modal or triggers the full-page version,
 * depending on if the dashboard is fully-loaded or not.
 */
export const handleOldVersionMode = () => ({
  type: types.HANDLE_OLD_VERSION_MODE,
});

/**
 * Redux action for opening the oldVersionMode modal
 */
export const oldVersionModeModalOpen = () => ({
  type: types.MODAL_OLD_VERSION_MODE_OPEN,
});

/**
 * Redux action for closing the oldVersionMode modal
 */
export const oldVersionModeModalClose = () => ({
  type: types.MODAL_OLD_VERSION_MODE_CLOSE,
});

/**
 * Redux action for triggering on the oldVersionMode full-page version
 */
export const oldVersionModeOn = () => ({
  type: types.OLD_VERSION_MODE_ON,
});

/**
 * Redux action for triggering off the oldVersionMode full-page version
 */
export const oldVersionModeOff = () => ({
  type: types.OLD_VERSION_MODE_OFF,
});

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { ssoLoginRoutine } from 'actions/routines/auth';
import { isSSOSubmittingLoginSelector } from 'selectors/authSelectors';
import { currentSSOSettingsSelector } from 'selectors/ssoSelectors';
import { createMergeProps } from 'store/redux';
import RequiredSSOLoginForm from './RequiredSSOLoginForm';
export const mapStateToProps = createStructuredSelector({
    currentSSOSettings: currentSSOSettingsSelector,
    isSSOSubmitting: isSSOSubmittingLoginSelector,
});
export const mapDispatchToProps = {
    onSSOLoginSubmit: ssoLoginRoutine.trigger,
};
export const mergeProps = createMergeProps((stateProps, dispatchProps) => {
    const { currentSSOSettings } = stateProps;
    return {
        onSSOLogin: () => dispatchProps.onSSOLoginSubmit({
            companyId: currentSSOSettings.company,
            ssoProvider: currentSSOSettings.ssoProvider,
            ssoProviderConnection: currentSSOSettings.ssoProviderConnection,
        }),
    };
});
const enhance = compose(connect(mapStateToProps, mapDispatchToProps, mergeProps));
export default enhance(RequiredSSOLoginForm);

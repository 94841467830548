import * as types from 'types/taxes';

export const closeConfirmTaxFilingInfoModal = () => ({
  type: types.MODAL_CONFIRM_TAX_FILING_INFO_CLOSE,
});
export const openConfirmTaxFilingInfoModal = () => ({
  type: types.MODAL_CONFIRM_TAX_FILING_INFO_OPEN,
});

export const fetchTaxInfoFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_TAX_INFO_FAILURE,
});

export const fetchTaxInfoRequest = (partnershipId) => ({
  payload: { partnershipId },
  type: types.FETCH_TAX_INFO_REQUEST,
});

export const fetchTaxInfoSuccess = (payload) => ({
  payload,
  type: types.FETCH_TAX_INFO_SUCCESS,
});

export const fetchVendorTaxInfoFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_VENDOR_TAX_INFO_FAILURE,
});

export const fetchVendorTaxInfoRequest = (partnershipId) => ({
  payload: { partnershipId },
  type: types.FETCH_VENDOR_TAX_INFO_REQUEST,
});

export const fetchVendorTaxInfoSuccess = (payload) => ({
  payload,
  type: types.FETCH_VENDOR_TAX_INFO_SUCCESS,
});

export const submitTaxInfoFailure = (errors) => ({
  payload: { errors },
  type: types.SUBMIT_TAX_INFO_FAILURE,
});

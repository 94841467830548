import React from 'react';
import { Field } from 'redux-form';

import { Checkbox, Col } from 'components';

import { partnerFundingAccountFormFields } from 'constants/formFields';
import { formLabels } from 'constants/partnerFundingAccount';

/**
 * Renders checkbox for "Replace Accounts" option in
 * AddPartnerFundingAccount form
 * @return {StatelessComponent}
 */
const AddPartnerFundingAccountOptionReplaceAccounts = () => (
  <Col>
    <Field
      component={Checkbox}
      content={formLabels.APPLY_TO_NO_SET}
      name={partnerFundingAccountFormFields.SHOULD_SHOW_REPLACE_FUNDING_ACCOUNTS}
    />
  </Col>
);

export default AddPartnerFundingAccountOptionReplaceAccounts;

import PropTypes from 'prop-types';
import React from 'react';

import SecureIcon from 'components/icon/SecureIcon';
import { TooltipMUI } from 'components/tooltip';

import { colors, sizes } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';

/**
 * Lock icon with an optional tooltip to be shown in a locked input.
 *
 * @param {ComponentProps} props
 * @param {NodeMaybe} props.tooltipContent
 * @param {ObjectMaybe} [props.tooltipProps={}]
 * @returns {StatelessComponent}
 */
const LockedInputTooltip = ({ className, tooltipContent: TooltipContent, tooltipProps }) => {
  if (!TooltipContent) {
    return (
      <div className={className}>
        <SecureIcon color={colors.colorGreyDarkHex} size={sizes.iconSizes.EXTRA_LARGE} />
      </div>
    );
  }

  return (
    <TooltipMUI
      arrow
      className={className}
      padding={TooltipPadding.LARGE}
      placement={TooltipPlacement.TOP}
      title={<TooltipContent />}
      {...tooltipProps}
    >
      <SecureIcon size={sizes.iconSizes.EXTRA_LARGE} />
    </TooltipMUI>
  );
};

LockedInputTooltip.propTypes = {
  className: PropTypes.string,
  tooltipContent: PropTypes.func,
  tooltipProps: PropTypes.shape({}),
};

LockedInputTooltip.defaultProps = {
  className: undefined,
  tooltipContent: undefined,
  tooltipProps: {},
};

export default LockedInputTooltip;

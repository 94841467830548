import React from 'react';

/**
 * Convenience component to add a non-blocking space as a component.
 * @param {ComponentProps} props
 * @returns {StatelessComponent}
 */
const NonBlockingSpace = (props) => <span {...props}>&nbsp;</span>;

export default NonBlockingSpace;

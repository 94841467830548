import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { commonFormFields, partnerFundingAccountFormFields } from 'constants/formFields';
import { formNamesFunding } from 'constants/forms';
import { partnerCompanyNameFromPartnershipPropSelector } from 'queries/companyCompoundSelectors';
import { createFieldValueSelector } from 'selectors/createFormSelectors';
import { currentCompanyMailingAddressSelector } from 'selectors/currentCompanySelectors';
import ManuallyAddBankAccountAddressDetails from './ManuallyAddBankAccountAddressDetails';
const countryFormFieldSelector = createFieldValueSelector(formNamesFunding.ADD_MANUAL_BANK, commonFormFields.PARTNER_COUNTRY);
const applyRegisteredAddressSelector = createFieldValueSelector(formNamesFunding.ADD_MANUAL_BANK, partnerFundingAccountFormFields.APPLY_REGISTERED_ADDRESS);
export const mapStateToProps = createStructuredSelector({
    address: currentCompanyMailingAddressSelector,
    country: countryFormFieldSelector,
    partnerCompanyName: partnerCompanyNameFromPartnershipPropSelector,
    shouldApplyRegisteredAddress: applyRegisteredAddressSelector,
});
export default connect(mapStateToProps)(ManuallyAddBankAccountAddressDetails);

/**
 * @fileOverview Defines the top-level reducer for feature settings.
 * @module reducers/featureSettings
 */

import { combineReducers } from 'redux';

import activeSettings from './activeSettings';
import errors from './errors';
import isFetching from './isFetching';

/**
 * The top-level reducer for reduxState.featureSettings.
 * @type {ReduxReducer}
 */
const featureSettingsReducer = combineReducers({
  activeSettings,
  errors,
  isFetching,
});

export default featureSettingsReducer;

import { getModalReducerOpenAction } from 'store/redux';

import * as types from 'types/partnership';

export const initialState = {
  doesCompanyExist: false,
  open: false,
  partnership: {},
  sendEmail: true,
};

// Reducer
const createPartnershipReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MODAL_CREATE_PARTNERSHIP_CLOSE:
      return initialState;

    case types.MODAL_CREATE_PARTNERSHIP_OPEN:
      return getModalReducerOpenAction(state, action);

    default:
      return state;
  }
};

export default createPartnershipReducer;

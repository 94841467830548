import React, { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InputField } from 'components';
import { getErrorsFromFieldState } from '../helpers';
const MaskedInputField = forwardRef(({ errors, formatter, isRequired, name, onBlur, onChange, placeholder, value }, ref) => {
    const handleChange = (evt) => {
        const evtCopy = evt;
        evtCopy.target.value = formatter(evtCopy.target.value);
        onChange(evtCopy);
    };
    return (React.createElement(InputField, { errors: errors, isRequired: isRequired, isUncontrolled: true, name: name, onBlur: onBlur, onChange: handleChange, placeholder: placeholder, setRef: ref, value: value }));
});
export const RenderMaskedTextInput = ({ isRequired, formatter, name, placeholder, shouldUnregister, }) => {
    const { control } = useFormContext();
    return (React.createElement(Controller, { control: control, name: name, render: ({ field, fieldState }) => (React.createElement(MaskedInputField, { ...field, errors: getErrorsFromFieldState(fieldState, field.name), formatter: formatter, isRequired: !!isRequired, placeholder: placeholder })), rules: { required: isRequired }, shouldUnregister: shouldUnregister }));
};

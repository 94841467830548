import { isFn, isObject, isString } from 'helpers/utility';

/**
 * Given an array of routines, extract and sort the action types into an object.
 * @function
 * @see reducers/partnershipMemberReducer/errorReducer.js
 * @param {ReduxSagaRoutine[]} routines
 * @returns {Object}
 */
export const gatherReducerCasesFromRoutines = (routines) => {
  const failureCases = routines.map((routine) => routine.FAILURE);
  const requestCases = routines.map((routine) => routine.REQUEST);
  const successCases = routines.map((routine) => routine.SUCCESS);

  return {
    failureCases,
    requestCases,
    successCases,
  };
};

/**
 * Quick-and-dirty test to determine if the argument is a redux-saga-routine.
 * @function
 * @param {ReduxSagaRoutine?} [routineMaybe]
 * @returns {boolean}
 */
export const isReduxSagaRoutine = (routineMaybe) =>
  isObject(routineMaybe) && isString(routineMaybe.TRIGGER) && isFn(routineMaybe.trigger);

/**
 * Custom payload creator object shared by routines used to handle form submission.
 * @type {Object}
 * @property {function(payload: *): Object} failure - Our regular action creators nest
 * error payload values under an `errors` key, and thus action.payload.errors is accessed
 * in most or all of the error reducers. The purpose of this action creator config is for
 * it to behave exactly like the others-- to take in an errors payload value, and nest it
 * under the `errors` key, keeping with the standard failure payload format.
 */
export const sharedFormSubmitRoutinePayloadCreators = {
  failure: (payloadValue = {}) => {
    if (!payloadValue.errors) {
      return { errors: payloadValue };
    }

    return payloadValue;
  },
};

/**
 * Custom simple meta creator. Can be used to configure `meta` as the second argument to an routine action creator,
 * with any routine action creator that uses first argument for payload.
 * @param {?Object} [payload]
 * @param {?Object} [meta={}]
 * @returns {Object}
 */
export const simpleMetaCreator = (payload, meta = {}) => meta;

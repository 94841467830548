import { call, put, spawn, take } from 'redux-saga/effects';

import * as routines from 'actions/routines/currencies';

import { parseCaughtError, parseErrorResponse, getRequestErrorAction } from 'helpers/errors';

import * as api from './api';

/**
 * Handle fetching all currencies
 * @returns {IterableIterator<*>}
 */
export function* fetchCurrencies() {
  let errorData = {};

  yield put(routines.fetchCurrenciesRoutine.request());

  try {
    const response = yield call(api.fetchCurrencies);

    if (response.ok) {
      yield put(routines.fetchCurrenciesRoutine.success(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(errorData);
  yield put(errorAction(routines.fetchCurrenciesRoutine.failure, errorData));
}

/**
 * Listens for redux actions related to currencies.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([routines.fetchCurrenciesRoutine.TRIGGER]);

    switch (action.type) {
      case routines.fetchCurrenciesRoutine.TRIGGER:
        yield spawn(fetchCurrencies, action);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root currencies saga.
 * @return {IterableIterator<*>}
 */
export default function* currencies() {
  yield watch();
}

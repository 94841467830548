import { createLastSubmittedReducer } from 'store/redux';

import { CLEAR_EMAIL_FORWARD, SUBMIT_EMAIL_FORWARD_SUCCESS } from 'types/notifications';

export const requestCases = [CLEAR_EMAIL_FORWARD];
export const successCases = [SUBMIT_EMAIL_FORWARD_SUCCESS];

const lastSubmittedEmailForwardReducer = createLastSubmittedReducer(successCases, requestCases);

export default lastSubmittedEmailForwardReducer;

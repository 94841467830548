import styled from 'styled-components';

import { containerWidthMap } from 'modules/signup-v3/constants/container';

export const Container = styled.div`
  max-width: ${containerWidthMap.medium};
`;

export const DocumentOptionsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 0.75rem;
  margin-bottom: 2rem;
  max-width: ${containerWidthMap.medium};
`;

export const Image = styled.img`
  height: 94px;
  width: 91px;
`;

export const RecommendedDocument = styled.h3`
  color: var(--grey--x-dark);
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
`;

export const RecommendedDocumentLink = styled.a`
  color: var(--blue--bold);
`;

export const TextWeight = styled.span`
  font-weight: ${({ weight }) => weight};
`;

export const DocumentOptions = styled.button`
  color: var(--blue--bold);
  cursor: pointer;
  line-height: 1.5rem;
  font-size: 1rem;
  margin-top: 0.125rem;
`;

export const UploadedDocuments = styled.div`
  margin-top: 1rem;
  max-width: ${containerWidthMap.medium};
`;

import styled from 'styled-components';
export const FieldsSection = styled.div.withConfig({
    displayName: 'FieldsSection',
}) `
  margin-bottom: var(--spacing-8);
`;
export const FieldsSectionGroups = styled.div.withConfig({
    displayName: 'FieldsSectionGroups',
}) `
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--spacing-5);
`;
export const FieldsSectionTitle = styled.span.withConfig({
    displayName: 'FieldsSectionTitle',
}) `
  font-family: var(--globals-font-bold-family);
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--line-height-4);
  color: var(--color-grey70);
  margin-bottom: var(--spacing-5);
  display: inline-block;
`;
export const FieldGroup = styled.div.withConfig({ displayName: 'FieldGroup' }) `
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: var(--spacing-5);
`;

/**
 * Given the previous state meta and the next meta (from the response), returns
 * the meta object to use after requesting a list with "load more" behavior.
 * @param {Object} prevMeta
 * @param {Object} nextMeta
 * @returns {{ pagination: (*&{ size: *, count: * }) }|*}
 */
export const transformLoadMoreMeta = (prevMeta, nextMeta) => {
  if (prevMeta?.pagination?.forwardCode) {
    return {
      ...nextMeta,
      pagination: {
        ...nextMeta.pagination,
        count: prevMeta.pagination.count + nextMeta.pagination.count,
        size: prevMeta.pagination.size + nextMeta.pagination.count,
      },
    };
  }

  return nextMeta;
};

import React from 'react';
import Toggle, {} from 'react-toggle';
import styled from 'styled-components';
import { callOnEnterKeyEvent, createPersistedEventCallback } from 'helpers/events';
import { callWithArgsIfIsFn } from 'helpers/utility';
export const StyledLabel = styled.label `
  align-items: center;
  cursor: pointer;
  display: flex;
`;
const ToggleSwitch = ({ children, htmlFor, id, isChecked, isDisabled, name, onChange, ...rest }) => {
    const handleKeyPress = React.useCallback(callOnEnterKeyEvent(createPersistedEventCallback((event) => {
        callWithArgsIfIsFn(onChange, event);
    })), [onChange]);
    return (React.createElement(StyledLabel, { htmlFor: htmlFor || name },
        React.createElement(Toggle, { ...rest, checked: isChecked, disabled: isDisabled, icons: false, id: id, name: name, onChange: onChange, onKeyPress: handleKeyPress }),
        children));
};
ToggleSwitch.defaultProps = {
    id: '',
};
export default ToggleSwitch;

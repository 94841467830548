import { Clickable, Hint } from '@routable/components';
import React from 'react';
import { useHistory } from 'react-router-dom';
export const NoTaxDocSubmittedHint = ({ taxDocsPath }) => {
    const history = useHistory();
    const handleNavigateToTaxTools = () => {
        history.push(taxDocsPath);
    };
    return (React.createElement(Hint, { title: "Tax information required to enroll vendor in ongoing compliance checks", type: "info", variant: "outline" },
        React.createElement("div", { className: "flex flex-col gap-5" },
            React.createElement("span", null,
                React.createElement(Clickable, { onClick: handleNavigateToTaxTools }, "Add or request new tax information"),
                " for this vendor."))));
};

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import * as types from 'types/item';

const invoicesByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case types.CLEAR_INVOICES:
      return {};

    case types.FETCH_INVOICES_SUCCESS:
      return getObjectsByIdWithRelationships(action.payload.itemImport);

    case types.FETCH_ADDITIONAL_INVOICES_SUCCESS:
      return deepMergeWithArrayReplacement(state, getObjectsByIdWithRelationships(action.payload.itemImport));

    default:
      return state;
  }
};

export default invoicesByIdReducer;

import { fetchCompanyTagsRoutine, createItemTagLinksRoutine, createTagRoutine } from 'actions/routines/tags';

import { createAllIdsReducer } from 'store/redux';

import { FETCH_ITEM_SUCCESS } from 'types/item';

const allIdsReducer = createAllIdsReducer({
  key: 'tag',
  types: [
    FETCH_ITEM_SUCCESS,
    fetchCompanyTagsRoutine.SUCCESS,
    createTagRoutine.SUCCESS,
    createItemTagLinksRoutine.SUCCESS,
  ],
});

export default allIdsReducer;

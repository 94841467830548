import PropTypes from 'prop-types';
import React from 'react';

import { DisclosureListClassNames } from 'components/disclosureList/constants';

import { ApprovalLevel } from './components';

/**
 * Approvers component
 * @param {ComponentProps} props
 * @param {ItemApprovalLevels} props.approvalLevels
 * @param {object} props.membershipsById
 * @return {StatelessComponent}
 */
const Approvers = ({ item, approvalLevels, membershipsById }) => (
  <div className={DisclosureListClassNames.BODY}>
    {approvalLevels.map((approvalLevel) => (
      <ApprovalLevel
        approvalLevel={approvalLevel}
        itemKind={item.kind}
        key={approvalLevel[0].levelId}
        membershipsById={membershipsById}
      />
    ))}
  </div>
);

Approvers.propTypes = {
  approvalLevels: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape())).isRequired,
  membershipsById: PropTypes.shape().isRequired,
  item: PropTypes.shape().isRequired,
};

export default Approvers;

import React, { useEffect } from 'react';
import { useSelector, connect, useDispatch } from 'react-redux';
import { change } from 'redux-form';

import { getCurrentCompanyRequest } from 'actions/currentCompany';
import { fetchAllMembershipInvitesRequest } from 'actions/inviteTeamMember';
import { fetchCurrentMemberRequest, fetchMembershipsRequest } from 'actions/memberships';
import { fetchPermissionsRoutine } from 'actions/routines/permissions';
import { fetchRolesRoutine } from 'actions/routines/roles';
import { fetchCurrentUserRequest } from 'actions/user';

import { Help } from 'modules/app/components';
import { IsLoadingHasWrapper } from 'modules/isLoading';

import { currentCompanyNameSelector, currentCompanySelector } from 'selectors/currentCompanySelectors';
import { currentUserSelector } from 'selectors/currentUserSelectors';
import { fundingAccountsSelector } from 'selectors/fundingSelectors';
import { isFetchingPermissionGroupsSelector } from 'selectors/permissionGroupsSelectors';
import { isFetchingPermissionsSelector } from 'selectors/permissionsSelectors';
import { getInviteTeamMemberModal, getViewTeamMembersModal } from 'selectors/signupSelectors';

import updateCurrentCompany from 'thunks/updateCurrentCompany';

import { signupFlowForm } from './constants';
import { fields } from './fields';
import SignUpFlow from './SignUpFlow';

export const SignUpFlowContainer = ({
  onFetchCurrentCompany,
  onFetchRoles,
  onFetchAllMembershipInvites,
  onFetchMemberships,
  onFetchCurrentMembership,
  onFetchPermissionsAndPermissionGroups,
  onFetchCurrentUser,
  ...props
}) => {
  const currentCompanyName = useSelector(currentCompanyNameSelector);
  const currentCompany = useSelector(currentCompanySelector);
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector);
  const isFetchingPermissions = useSelector(isFetchingPermissionsSelector);
  const isFetchingPermissionsGroups = useSelector(isFetchingPermissionGroupsSelector);

  useEffect(() => {
    if (currentCompanyName) {
      dispatch(change(signupFlowForm, fields.companyName, currentCompanyName));
    }
  }, [currentCompanyName, dispatch]);

  useEffect(() => {
    onFetchRoles();
    onFetchCurrentCompany();
    onFetchPermissionsAndPermissionGroups();
    onFetchCurrentUser();
    onFetchCurrentMembership();
  }, [
    onFetchCurrentCompany,
    onFetchPermissionsAndPermissionGroups,
    onFetchRoles,
    onFetchCurrentUser,
    onFetchCurrentMembership,
  ]);

  useEffect(() => {
    if (currentCompanyName) {
      onFetchAllMembershipInvites(currentCompany.id);
      onFetchMemberships({ skipCache: true });
    }
  }, [currentCompany.id, currentCompanyName, onFetchAllMembershipInvites, onFetchMemberships]);

  if (!currentUser || isFetchingPermissions || isFetchingPermissionsGroups) {
    return <IsLoadingHasWrapper />;
  }

  return (
    <>
      <Help />
      <SignUpFlow {...props} />
    </>
  );

  // TODO SUF: Fast follows
  // const permissionOptions = {
  //   requiredPermissions: [
  //     PermissionResourceAction.SETTINGS_BUSINESS_REPRESENTATIVES_EDIT,
  //     PermissionResourceAction.SETTINGS_BUSINESS_VERIFICATION_EDIT,
  //   ],
  //   WithPermissionComponent: () => <SignUpFlow {...props} />,
  //   WithoutPermissionComponent: RestrictedPage,
  // };
  //
  // return withPermissionRestriction(permissionOptions);
};

const mapStateToProps = (state) => ({
  inviteTeamMemberModal: getInviteTeamMemberModal(state),
  viewTeamMembersModal: getViewTeamMembersModal(state),
  fundingAccounts: fundingAccountsSelector(state),
});

const mapDispatchToProps = {
  onUpdateCurrentCompany: updateCurrentCompany,
  onFetchCurrentUser: fetchCurrentUserRequest,
  onFetchCurrentCompany: getCurrentCompanyRequest,
  onFetchCurrentMembership: fetchCurrentMemberRequest,
  onFetchMemberships: fetchMembershipsRequest,
  onFetchRoles: fetchRolesRoutine,
  onFetchAllMembershipInvites: fetchAllMembershipInvitesRequest,
  onFetchPermissionsAndPermissionGroups: fetchPermissionsRoutine,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpFlowContainer);

import React from 'react';
import { useSearchParam } from 'react-use';
import {} from 'enums/items';
import { getItemKindFromExternalV2Path } from 'helpers/items';
import { hasZeroLength } from 'helpers/utility';
import { usePayments } from 'hooks/usePayments';
import {} from 'interfaces/item';
import {} from 'interfaces/redux';
import { ExternalReceiptPaymentFullPageModal, ExternalReceiptPaymentLineItemsModal, } from '../externalReceiptV2/ExternalReceiptSingleItem/components/ExternalReceiptPayment/components';
import { ExternalPaymentsListTable } from './ExternalPaymentsListTable';
const ExternalPaymentsList = () => {
    const partnershipId = useSearchParam('partnership_id');
    const [activePayment, setActivePayment] = React.useState();
    const [activeItem, setActiveItem] = React.useState();
    const kind = getItemKindFromExternalV2Path();
    const { isLoading, isError, data: payments } = usePayments({ kind, partnershipId });
    const hasActivePayment = Boolean(activePayment);
    if (isLoading || isError || hasZeroLength(payments)) {
        return null;
    }
    return (React.createElement("div", { className: "external-payments-list" },
        React.createElement(ExternalPaymentsListTable, { data: payments, onClick: ({ payload }) => {
                setActivePayment(payload);
            } }),
        React.createElement(ExternalReceiptPaymentLineItemsModal, { item: activeItem, modalState: !!activeItem, onCloseModal: () => setActiveItem(undefined) }),
        hasActivePayment && (React.createElement(ExternalReceiptPaymentFullPageModal, { modalState: hasActivePayment, onCloseModal: () => setActivePayment(undefined), onSetActiveItem: setActiveItem, payment: activePayment }))));
};
export default ExternalPaymentsList;

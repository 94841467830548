import {} from '../meta.types';
import {} from './withNotification.types';
export const withNotification = (notification) => (action) => ({
    ...action,
    meta: {
        ...action.meta,
        notification: {
            ...notification,
        },
    },
});

import PropTypes from 'prop-types';
import React from 'react';

import InternationalPhoneInputContainer from './InternationalPhoneInputContainer';

/**
 * Helper function to render via redux-form's Field component;
 * normalizes prop names across usages.
 * e.g. - <Field component={ReduxFormFieldRenderDateInput} ... />
 * @param {ComponentProps} props
 * @param {NumberMaybe} props.debounceDelay
 * @param {boolean} props.isDebounced
 * @param {ReduxFormMeta} props.meta - Info object from redux-form Field
 * @param {RestOfProps} props.rest
 * @returns {InternationalPhoneInputContainer}
 */
const ReduxFormFieldRenderInternationalPhoneInput = ({ input, meta, ...rest }) => {
  const errors = (meta.touched || meta.submitFailed) && meta.error;

  return (
    <InternationalPhoneInputContainer
      {...rest}
      errors={errors}
      hasErrors={!!errors}
      input={input}
      meta={meta}
      name={input.name}
      onBlur={input.onBlur}
      onChange={input.onChange}
      onFocus={input.onFocus}
      value={input.value}
    />
  );
};

ReduxFormFieldRenderInternationalPhoneInput.propTypes = {
  debounceDelay: PropTypes.number,
  input: PropTypes.shape().isRequired,
  isDebounced: PropTypes.bool,
  meta: PropTypes.shape().isRequired,
};

ReduxFormFieldRenderInternationalPhoneInput.defaultProps = {
  debounceDelay: undefined,
  isDebounced: undefined,
};

export default ReduxFormFieldRenderInternationalPhoneInput;

/**
 * Teach Redux to handle arrays of actions.
 *
 * Usage:
 *
 * @example
 * // actions file:
 * const combinedAction = () => ([actionCreatorA(), actionCreatorB()]);
 * const combinedActionWithArgs = c => ([actionCreatorA(), actionCreatorC(c)]);
 *
 * // connect wrapper:
 * const mapDispatchToProps = { onA: combinedAction, onC: combinedActionWithArgs };
 *
 * // component:
 * onClick={this.props.onA}
 * onHover={() => this.props.onC('hi')}
 *
 * @return {function(*=): function(*): Function}
 */
const multiActionMiddleware = () => (store) => (next) => (action) => {
  if (Array.isArray(action)) {
    return action.map((a) => store.dispatch(a));
  }

  return next(action);
};

export default multiActionMiddleware;

import { internationalBeneficiaryFieldName } from '@routable/fund-routes';
import { requiredValidator } from '@routable/shared';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { Field, change } from 'redux-form';
import { SelectFieldV2 } from 'components';
import { useFormattedCountries } from 'components/form/CountryField/hooks';
import { useCountryCodesContext, useFieldValue } from 'hooks';
export const NationalityField = ({ className, defaultValue, form }) => {
    const dispatch = useDispatch();
    const selectedNationality = useFieldValue(form, internationalBeneficiaryFieldName.Enum.country_of_nationality);
    const countryCodeMap = useCountryCodesContext();
    const { prioritizedCountriesWithNationalities } = useFormattedCountries(countryCodeMap);
    useEffectOnce(() => {
        if (!selectedNationality) {
            dispatch(change(form, internationalBeneficiaryFieldName.Enum.country_of_nationality, defaultValue));
        }
        return () => {
            dispatch(change(form, internationalBeneficiaryFieldName.Enum.country_of_nationality, null));
        };
    });
    return (React.createElement("div", { className: className },
        React.createElement(Field, { component: SelectFieldV2, isRequired: true, label: "Nationality", name: internationalBeneficiaryFieldName.enum.country_of_nationality, options: prioritizedCountriesWithNationalities, validate: [requiredValidator] })));
};

import PropTypes from 'prop-types';
import React from 'react';

import { Form } from 'components/form';
import { ModalBodyContent } from 'components/modal';

import { formNamesExternal } from 'constants/forms';

import FilingDetailsDisclosureList from 'modules/external/ExternalCollectTaxInfo/components/FilingDetailsDisclosureList';
import MailingAddressDisclosureList from 'modules/external/ExternalCollectTaxInfo/components/MailingAddressDisclosureList';
import { modalBodyText } from 'modules/external/ExternalCollectTaxInfo/constants/text';

import { ConfirmTaxInfoModalFooter, ElectronicDeliveryConsentHint } from './components';

/**
 * Shows a summary of the tax collection information for the user to review before they certify and submit the modal to
 * receive their 1099.
 * @param {boolean} canSubmit
 * @param {object} filingDetails
 * @param {boolean} isConfirm - Whether the filing details should show the date the details were collected
 * @param {function} onCloseModal
 * @param {function} onSubmitModal
 * @param {boolean} submitting - Whether the form is submitting, via redux-form
 * @returns {StatelessComponent}
 * @constructor
 */
const ConfirmTaxInfoModalBody = ({ canSubmit, filingDetails, isConfirm, onCloseModal, onSubmitModal, submitting }) => (
  <React.Fragment>
    <ModalBodyContent>
      <p className="letter-spacing--xs line-height--large font-regular font-color--greyXDark remove-margin-bottom">
        {modalBodyText}
      </p>

      <FilingDetailsDisclosureList
        {...filingDetails}
        className="margin-top--m-large"
        isMultiPartnerConfirm={isConfirm}
        showGenericTin={isConfirm}
        viewCompletionDate={isConfirm}
      />

      <MailingAddressDisclosureList
        address={filingDetails.mailingAddress}
        className="margin-top--m-large margin-bottom--m-large"
      />

      <Form id={formNamesExternal.TAX_E_DELIVERY_CONSENT} onSubmit={onSubmitModal}>
        <ElectronicDeliveryConsentHint filingDetails={filingDetails} showGenericTIN={isConfirm} />
      </Form>
    </ModalBodyContent>

    <ConfirmTaxInfoModalFooter
      canSubmit={canSubmit}
      onCloseModal={onCloseModal}
      onSubmitModal={onSubmitModal}
      submitting={submitting}
    />
  </React.Fragment>
);

ConfirmTaxInfoModalBody.propTypes = {
  canSubmit: PropTypes.bool.isRequired,
  filingDetails: PropTypes.shape().isRequired,
  isConfirm: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  onSubmitModal: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

ConfirmTaxInfoModalBody.defaultProps = {
  isConfirm: undefined,
};

export default ConfirmTaxInfoModalBody;

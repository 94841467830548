import React from 'react';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';

import CannotUpdateFieldHint from 'components/hintTypes/CannotUpdateFieldHint';

import { countryListOptions } from 'constants/i18n';

import AddressReduxForm from 'modules/address/presenters/AddressReduxForm';
import Accordion from 'modules/signup-v3/components/Accordion';
import { signupFlowForm } from 'modules/signup-v3/constants';
import { containerWidthMap } from 'modules/signup-v3/constants/container';

import {
  currentMembershipVerificationUpdateAllowedSelector,
  isUpdatingMembershipSelector,
} from 'selectors/membershipsSelector';

import { howRoutableUseThisInformation } from '../constants/accordion';
import FlowStepperMain from '../FlowStepperMain';
import { Container } from '../FlowStepperMain.styles';
import { viewSteps } from '../helpers/viewSteps';

import { HelperWrapper } from './RepresentativeIDNumber.styles';

const RepresentativeAddress = ({ activeView, form: signUpFormName, invalid }) => {
  const formValueSelector = getFormValues(signUpFormName);
  const updatedAllowed = useSelector(currentMembershipVerificationUpdateAllowedSelector);
  const { address: { country } = {} } = useSelector(formValueSelector);
  const isUpdating = useSelector(isUpdatingMembershipSelector);

  const countryIsDefined = Boolean(country?.length);

  return (
    <FlowStepperMain
      containerWidth={containerWidthMap.medium}
      fields={
        <Container containerWidth={containerWidthMap.medium}>
          {!updatedAllowed && <CannotUpdateFieldHint />}
          <AddressReduxForm
            addressPath="address"
            countryBeforeStreet
            countryOptions={countryListOptions.ALL}
            formName={signupFlowForm}
            isFormDisabled={!updatedAllowed}
            showAddressFields={countryIsDefined}
            showStateAndPostalFields={countryIsDefined}
          />

          <HelperWrapper>
            <Accordion {...howRoutableUseThisInformation} containerWidth={containerWidthMap.medium} />
          </HelperWrapper>
        </Container>
      }
      invalid={invalid || !updatedAllowed}
      onBack={viewSteps[activeView].previous}
      onNext={{
        ...viewSteps[activeView].next,
        isLoading: isUpdating,
      }}
      onSkip={updatedAllowed && viewSteps[activeView].skip}
      subTitle={{
        content:
          "P.O. boxes and business addresses aren't allowed. This should be your residence for your personal verification.",
      }}
      title={{ content: "What's your primary residential address?" }}
    />
  );
};

export default RepresentativeAddress;

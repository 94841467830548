import styled from 'styled-components';
import { Icon } from 'components';
import { containerWidthMap } from 'modules/signup-v3/constants/container';
export const Container = styled.div `
  max-width: ${containerWidthMap.medium};
`;
export const UploadedDocumentText = styled.p `
  color: var(--grey--xx-dark);
  font-size: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;
export const DocumentOptionsContainer = styled.div `
  display: flex;
  margin-top: 1.563rem;
  margin-bottom: 2.188rem;
  max-width: ${containerWidthMap.medium};
`;
export const DocumentHintText = styled.p `
  color: var(--grey--xx-dark);
  font-size: 1rem;
  line-height: 1.313rem;
`;
export const HintsContainer = styled.div `
  display: flex;
  gap: 0.875rem;
  justify-content: space-between;
`;
export const HintContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  position: relative;
`;
export const HintIcon = styled(Icon) `
  position: absolute;
  top: 0;
  left: 0;
`;
export const HintImage = styled.img `
  height: 11.375rem;
  object-fit: contain;
  width: 11.375rem;
`;
export const HintText = styled.p `
  color: var(--grey--xx-dark);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.975rem;
  max-width: 11.375rem;
  padding-bottom: 1rem;

  b {
    font-weight: 600;
  }
`;

import _get from 'lodash/get';
import {
  arraySplice,
  change,
  destroy,
  submit,
  stopSubmit,
  reset,
  resetSection,
  unregisterField,
  untouch,
  updateSyncErrors,
} from 'redux-form';
import { v4 } from 'uuid';

import { initializeCreatePartnershipFormAction } from 'actions/partnership';

import { createItemFormFields } from 'constants/formFields';
import {
  formNamesAuth,
  formNamesBalance,
  formNamesItem,
  formNamesExistingItem,
  formNamesMatchPartnership,
  formNamesPartnership,
  formNamesAuthV2,
} from 'constants/forms';

import { getQueryParam } from 'helpers/queryParams';

/**
 * ReduxForm actions for authNamespace
 * @type {{ reset: ReduxActionCreator }}
 */
export const formActionsAuthNamespace = {
  /**
   * reset form
   * @return {ReduxAction}
   */
  reset: () => reset(formNamesAuthV2.NAMESPACE),
};

/**
 * ReduxForm actions for balanceTransaction
 * @type {{ [string]: ReduxActionCreator }}
 */
export const formActionsBalanceTransaction = {
  destroy: () => destroy(formNamesBalance.TRANSACTION),
};

/**
 * ReduxForm actions for changePassword
 * @type {{ [string]: ReduxActionCreator }}
 */
export const formActionsChangePassword = {
  reset: () => reset(formNamesAuth.CHANGE_PASSWORD),
};

/**
 * ReduxForm actions for balanceTransaction
 * @type {{ arraySplice: ReduxActionCreator, change: ReduxActionCreator, resetSections: ReduxActionCreator }}
 */
export const formActionsCreateItem = {
  /**
   * @param {string} path
   * @param {number} index
   * @param {number} removeNum
   * @return {ReduxAction}
   */
  arraySplice: (path, index, removeNum) => arraySplice(formNamesItem.CREATE_ITEM, path, index, removeNum),
  /**
   * @param {string} field
   * @param {*} value
   * @return {ReduxAction}
   */
  change: (field, value) => change(formNamesItem.CREATE_ITEM, field, value),
  /**
   * @param {...string} sectionPaths
   * @return {ReduxAction}
   */
  resetSections: (...sectionPaths) => resetSection(formNamesItem.CREATE_ITEM, ...sectionPaths),
};

/**
 * ReduxForm actions for existingItemFinalize
 * @type {{ change: ReduxActionCreator, stopSubmit: ReduxActionCreator }}
 */
export const formActionsExistingItemFinalize = {
  change: (field, value) => change(formNamesExistingItem.FINALIZE, field, value),
  /**
   * @param {Object} [errors]
   * @return {ReduxAction}
   */
  stopSubmit: (errors) => stopSubmit(formNamesExistingItem.FINALIZE, errors),
};

/**
 * ReduxForm actions for existingItemSelectFundingAccount
 * @type {{ [string]: ReduxActionCreator }}
 */
export const formActionExistingItemSelectFundingAccount = {
  change: (field, value) => change(formNamesExistingItem.SELECT_FUNDING_ACCOUNT, field, value),
};

/**
 * ReduxForm actions for matchPartnership modal
 * @type {{ reset: ReduxActionCreator }}
 */
export const formActionMatchPartnership = {
  reset: () => reset(formNamesMatchPartnership.LEDGER_TO_PLATFORM),
};

/**
 * Set a new item idempotency key after a form reset
 * @param formName
 * @returns {ReduxAction}
 */
export const setNewFormIdempotencyKey = (formName) => change(formName, 'idempotencyKey', v4());

/**
 * Remove old item id from the form state
 * @param formName
 * @returns {ReduxAction}}
 */
export const removeOldFormItemId = (formName) => change(formName, 'item.id', null);

/**
 * Array of actions to run when a new company is selected.
 * @param {object} props
 * @param {string} props.formName
 * @param {Object} props.formUI
 * @param {CompanySearchPartnerTypes} props.companyType
 * @param {string=} props.partnershipType
 * @return {ReduxAction[]}
 */
export const formActionsAfterCompanyChange = ({ formName, formUI, companyType, partnershipType, ...rest }) => {
  const actions = [reset(formName), change(formName, 'partner.companyType', companyType)];

  if (formName === formNamesPartnership.CREATE) {
    const initializePartnershipFormAction = initializeCreatePartnershipFormAction({
      formName,
      partnershipType,
      paymentMethodOptions: formUI.paymentMethodOptions,
      ...rest,
    });
    actions.unshift(initializePartnershipFormAction);
  }

  if (Object.values(formNamesItem).includes(formName)) {
    actions.push(setNewFormIdempotencyKey(formName));

    if (!getQueryParam('id')) {
      actions.push(removeOldFormItemId(formName));
    }
  }

  actions.push(updateSyncErrors(formNamesItem.CREATE_ITEM, undefined, undefined));

  return actions;
};

/**
 * ReduxForm actions for sending update payment method links.
 * @type {Object.<string, ReduxActionCreator>}
 */
export const formActionsSendPaymentUpdateLink = {
  submit: () => submit(formNamesPartnership.SEND_UPDATE_PAYMENT_LINK),
};

/**
 * Given the form name and the partner's country code, reset the partner registered
 * address form to the initial (blank) values
 * @param {String} formName
 * @param {CountryCode} partnerCountryCode
 * @param {CompanyAddress} [existingAddress]
 * @returns {ReduxAction[]}
 */
export const formActionsResetPartnerRegisteredAddress = (formName, partnerCountryCode, existingAddress) => {
  // Country code is not selectable when adding a partner's registered address, so we always
  // want to set it to something
  const actions = [change(formName, createItemFormFields.ADDRESS_COUNTRY, partnerCountryCode)];

  // These are the fields that we want to reset to be blank again
  const fieldsToReset = [
    createItemFormFields.ADDRESS_STREET_ADDRESS,
    createItemFormFields.ADDRESS_CITY,
    createItemFormFields.ADDRESS_STATE,
    createItemFormFields.ADDRESS_POSTALCODE,
    createItemFormFields.ADDRESS_PRINT_COMPANY,
    createItemFormFields.ADDRESS_PRINT_NAME,
    createItemFormFields.ADDRESS_STREET_ADDRESS_UNIT,
  ];

  // In some cases (when closing the form in the middle of editing), we want to pass the original
  // values, before we started editing and use those values to reset the form
  const existingValues = { address: existingAddress };

  // For each field, we want to do two actions:
  // - set it's value to an empty string
  // - set the "touched" property to false again (so that we don't show required validation error immediatelly)
  // - unregister fields to remove the form sync errors
  fieldsToReset.forEach((field) => {
    const value = _get(existingValues, field) || '';

    actions.push(change(formName, field, value));
    actions.push(untouch(formName, field));
    actions.push(unregisterField(formName, field));
  });

  // Finally, we return the generated actions. This list can now be used in a dispatch() call,
  // either in a component using useDispatch() hook, or in the form change handler using the
  // dispatched argument received by the redux form
  return actions;
};

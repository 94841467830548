import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { Radio } from 'components';

import { LedgerDiscountStyles } from 'constants/ledger';

/**
 * In a discount block, radios to select between applying discount pre-tax or post-tax.
 * @param {ComponentProps} props
 * @param {string} props.name
 * @return {StatelessComponent}
 */
const DiscountStyle = ({ name }) => (
  <div className="form-control remove-margin-bottom">
    <Field
      component={Radio}
      id={`${name}-pre`}
      isHorizontalLayout
      name={name}
      optionText="Apply pre-tax"
      type="radio"
      value={LedgerDiscountStyles.PRE_TAX}
    />

    <Field
      component={Radio}
      id={`${name}-post`}
      isHorizontalLayout
      name={name}
      optionText="Apply post-tax"
      type="radio"
      value={LedgerDiscountStyles.POST_TAX}
    />
  </div>
);

DiscountStyle.propTypes = {
  name: PropTypes.string.isRequired,
};

export default DiscountStyle;

import styled from 'styled-components';
import { containerWidthMap } from 'modules/signup-v3/constants/container';
export const Container = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  min-height: 100vh;
  width: 100%;
  overflow-y: hidden;
`;
export const ImageContainer = styled.div `
  background-color: var(--light-blue-background);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  min-width: 400px;
  flex-grow: 1;

  img {
    padding: 10% 4% 0 4%;
    width: 100%;
    max-width: 500px;
  }

  @media screen and (max-width: 999px) {
    img {
      padding: 10% 10% 10% 4%;
      max-width: 300px;
    }
  }
`;
export const FormContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 60%;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  @media screen and (max-width: 999px) {
    & {
      margin: 40px 0;
    }

    .entity-widget {
      max-width: 100%;
      width: 100%;
    }
  }
`;
export const FormCenter = styled.div `
  display: flex;
  flex-direction: column;
  max-width: 56.25rem;
  margin: 3rem 0rem;
  height: 100%;
  justify-content: space-evenly;
  margin: 10rem 2rem 13rem;
`;
export const FormColumn = styled.div `
  display: flex;
  flex-direction: column;
`;
export const Heading = styled.h1 `
  font-weight: var(--font-weight--regular);
  font-size: var(--font--50);
  max-width: ${containerWidthMap.medium};
  line-height: 3rem;
  letter-spacing: 0.02em;
  color: var(--neutral-black);
`;
export const SubHeading = styled.h3 `
  max-width: ${containerWidthMap.medium};
  font-weight: 400;
  letter-spacing: 0.01em;
  color: var(--darker-black);
  font-size: 28px;
  padding: 1rem 0rem 4rem;
  line-height: 2rem;
`;
export const LeadText = styled.p `
  margin: 0px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.35rem;
  letter-spacing: 0.01em;
  color: var(--darker-black);
  padding: 0rem 0rem 2rem 0rem;
`;

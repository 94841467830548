import { createSelector } from 'reselect';

import { currentMembershipPersonalInfoCombiner } from 'helpers/membershipPersonalInfo';

import { membershipPersonalInfosByIdSelector } from 'selectors/membershipsPersonalInfoSelector';
import { currentMembershipIdSelector, membershipsByIdSelector } from 'selectors/membershipsSelector';

/**
 * Selects the current membership's personal info
 * @type {StandardSelector}
 * @param {ReduxState} state
 */
export const currentMembershipPersonalInfoSelector = createSelector(
  [membershipsByIdSelector, membershipPersonalInfosByIdSelector, currentMembershipIdSelector],
  currentMembershipPersonalInfoCombiner,
);

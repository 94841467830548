import { submitCreatePartnershipRoutine, updatePartnershipGeneralInfoRoutine } from 'actions/routines/partnership';

import { createLastSubmittedReducer } from 'store/redux';

import * as types from 'types/partnership';

export const requestOrFailureCases = [types.CLEAR_PARTNERSHIP];

export const successCases = [submitCreatePartnershipRoutine.SUCCESS, updatePartnershipGeneralInfoRoutine.SUCCESS];

export default createLastSubmittedReducer(successCases, requestOrFailureCases);

import ThunkFetch from 'helpers/thunkFetch';

import { payloadToUnderscore } from 'services/api/formatHelpers';
import { getPartnershipReceivableFundingAccountSubmitEndpoint } from 'services/api/partnershipsEndpoints';

import * as types from 'types/partnership';

/**
 * Thunk for submitting partnership receivable funding account
 * @param {Object} payload
 * @param {Id} partnershipId
 * @param {Id} partnershipFundingAccountId
 * @param {Object} [options={}]
 * @returns {Promise<{errors: any}>}
 */
const submitPartnershipReceivableFundingAccount = (
  payload,
  partnershipId,
  partnershipFundingAccountId,
  options = {},
) => {
  const { queryParams, successCallback } = options;

  const submitPayload = {
    data: {
      type: 'PartnershipFundingAccount',
      id: partnershipFundingAccountId,
      attributes: payloadToUnderscore(payload),
    },
  };

  const t = new ThunkFetch({
    actions: {
      error: types.SUBMIT_PARTNERSHIP_PRIMARY_RECEIVABLE_FUNDING_ACCOUNT_FAILURE,
      request: types.SUBMIT_PARTNERSHIP_PRIMARY_RECEIVABLE_FUNDING_ACCOUNT_REQUEST,
      success: types.SUBMIT_PARTNERSHIP_PRIMARY_RECEIVABLE_FUNDING_ACCOUNT_SUCCESS,
    },
    endpoint: getPartnershipReceivableFundingAccountSubmitEndpoint(
      partnershipId,
      partnershipFundingAccountId,
      queryParams,
    ),
    method: 'PATCH',
    payload: submitPayload,
    requireAuth: true,
    successCallback,
  });

  return t.makeRequest();
};

export default submitPartnershipReceivableFundingAccount;

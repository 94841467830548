import React from 'react';
import CrossBorderPaymentsNotEnabledTooltip from 'complexComponents/tooltipTypes/CrossBorderPaymentsNotEnabledTooltip';
import CurrencyNotEnabledOnLedgerTooltip from 'complexComponents/tooltipTypes/CurrencyNotEnabledOnLedgerTooltip';
import CurrencyNotSupportedByPlatformTooltip from 'complexComponents/tooltipTypes/CurrencyNotSupportedByPlatformTooltip';
import LimitedMatchingToBaseCurrencyTooltip from 'complexComponents/tooltipTypes/LimitedMatchingToBaseCurrencyTooltip';
import MatchOnlyDomesticVendorsWithCustomersTooltip from 'complexComponents/tooltipTypes/MatchOnlyDomesticVendorsWithCustomersTooltip';
import PreventInternationalCustomerMatchTooltip from 'complexComponents/tooltipTypes/PreventInternationalCustomerMatchTooltip';
import { MultiLineOptionForSearchCompanies } from 'components/selectV2/components';
import { getCustomerOrVendorOrBothTextByPartnership, getPartnershipType } from 'helpers/partnerships';
import { checkPartnershipEligibilityForMatchCompanies } from '../../helpers/logic';
import { getDetailsTextForMatchCompanies } from '../../helpers/text';
const MultiLineOptionWithTooltipForMatchCompanies = ({ companyIntegrationSettings, companySettings, data, ledger, platformPartnership, supportedCurrencies, ...rest }) => {
    const { isCrossBorderDisabledTooltipIsShown, isCurrencyMismatchTooltipShown, isCurrencyNotSupportedByPlatformTooltipShown, isInternationalCustomerTooltipShown, isLimitedMatchingTooltipShown, isMatchDomesticPartnershipsTooltipShown, } = checkPartnershipEligibilityForMatchCompanies({
        companyIntegrationSettings,
        companySettings,
        ledger,
        partnership: data,
        platformPartnership,
        supportedCurrencies,
    });
    const ledgerName = ledger?.name;
    const customerOrVendorText = getCustomerOrVendorOrBothTextByPartnership(data);
    const detailsText = getDetailsTextForMatchCompanies({
        isCrossBorderDisabledTooltipIsShown,
        isCurrencyMismatchTooltipShown,
        isCurrencyNotSupportedByPlatformTooltipShown,
        isInternationalCustomerTooltipShown,
        isLimitedMatchingTooltipShown,
        isMatchDomesticPartnershipsTooltipShown,
        ledger,
        partnership: data,
        platformPartnershipCurrency: platformPartnership.currencyCodePartner,
    });
    return (React.createElement(LimitedMatchingToBaseCurrencyTooltip, { isShown: isLimitedMatchingTooltipShown, ledger: ledger },
        React.createElement(PreventInternationalCustomerMatchTooltip, { isShown: isInternationalCustomerTooltipShown },
            React.createElement(CrossBorderPaymentsNotEnabledTooltip, { isShown: isCrossBorderDisabledTooltipIsShown },
                React.createElement(MatchOnlyDomesticVendorsWithCustomersTooltip, { isShown: isMatchDomesticPartnershipsTooltipShown, ledgerName: ledger.name },
                    React.createElement(CurrencyNotSupportedByPlatformTooltip, { currencies: data.ledgerCurrencies, isShown: isCurrencyNotSupportedByPlatformTooltipShown, partnershipType: getPartnershipType(data) },
                        React.createElement(CurrencyNotEnabledOnLedgerTooltip, { currencyCode: platformPartnership.currencyCodePartner, isRelatedToPartnership: true, isShown: isCurrencyMismatchTooltipShown, ledgerName: ledgerName, partnershipType: customerOrVendorText },
                            React.createElement(MultiLineOptionForSearchCompanies, { ...rest, data: {
                                    ...data,
                                    details: data.isDisabled && detailsText,
                                } }))))))));
};
export default MultiLineOptionWithTooltipForMatchCompanies;

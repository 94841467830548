/**
 * @module reducers/tablesReducer/configsReducer/createItemsReceivableReducer
 */

import { fetchItemStructureTablesRoutine } from 'actions/routines/tables';

import { tableNamesCreateItems } from 'constants/tables';

import initialState from './initialState';

const createItemsReceivableReducer = (state = initialState.configs[tableNamesCreateItems.RECEIVABLE], action) => {
  switch (action.type) {
    case fetchItemStructureTablesRoutine.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case fetchItemStructureTablesRoutine.FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case fetchItemStructureTablesRoutine.SUCCESS:
      return {
        ...state,
        isLoading: false,
        schema: action.payload[tableNamesCreateItems.RECEIVABLE],
      };

    default:
      return state;
  }
};

export default createItemsReceivableReducer;

import { MemberNameAddOn } from 'constants/ui';

import { getDateFromNow, isAfterNow } from 'helpers/date';
import { getMembershipFullNameAppendingTextToCurrent } from 'helpers/memberships';

const { MEMBER_NAME_ME } = MemberNameAddOn;

/**
 * Returns member's full name, appending addon if it's the current one.
 * @param {Membership} rowData
 * @return {string}
 */
export const getMemberFullName = (rowData) =>
  getMembershipFullNameAppendingTextToCurrent({ ...rowData }, MEMBER_NAME_ME);

/**
 * Returns status text title
 * @param {boolean} isExpired
 * @return {string}
 */
export const getStatusTextTitle = (isExpired) => (isExpired ? `Invite expired` : `Invite pending`);

/**
 * Returns status text subtitle
 * @param {string} dateExpiration
 * @return {string}
 */
export const getStatusTextSubtitle = (dateExpiration) =>
  isAfterNow(dateExpiration)
    ? `Invite expires ${getDateFromNow(dateExpiration)}`
    : `Invite expired ${getDateFromNow(dateExpiration)}`;

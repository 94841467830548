import { Email } from '@carbon/icons-react';
import { Button, Tooltip } from '@routable/gross-ds';
import React from 'react';
import { getPartnershipPermissionResourceForEditAction, getPartnershipResendButtonProps } from 'helpers/partnerships';
import { useGrossDsPermissions } from 'hooks';
import {} from './ResendPartnershipInvite.types';
export const ResendPartnershipInvite = ({ id, onResendInvite, partnership, resendAction, variant = 'floating', }) => {
    const { buttonText, isDisabled, tooltip } = getPartnershipResendButtonProps(partnership, resendAction);
    const permission = useGrossDsPermissions([getPartnershipPermissionResourceForEditAction(partnership)]);
    const handleResendInvite = () => {
        onResendInvite({ id, partnership });
    };
    return (React.createElement(Tooltip, { "data-testid": "resend-partnership-invite--tooltip", tooltip: tooltip, ...permission.tooltipProps, variant: "light" },
        React.createElement(Button, { "data-testid": "resend-partnership-invite--button", disabled: isDisabled || !permission.hasPermission, onPress: handleResendInvite, size: "small", variant: variant },
            React.createElement(Email, null),
            buttonText)));
};

import PropTypes from 'prop-types';
import React from 'react';

import { getQueryParam } from 'helpers/queryParams';
import { dashTextToCamelCase } from 'helpers/stringHelpers';

const withQueryParamProps = (Component) => {
  const ComponentWithQueryParamProps = ({ queries, ...rest }) => {
    const queryProps = queries.reduce(
      (accum, query) => ({
        ...accum,
        [dashTextToCamelCase(query)]: getQueryParam(query),
      }),
      {},
    );

    return <Component {...queryProps} {...rest} />;
  };

  ComponentWithQueryParamProps.propTypes = {
    queries: PropTypes.arrayOf(PropTypes.string),
  };

  ComponentWithQueryParamProps.defaultProps = {
    queries: [],
  };

  return ComponentWithQueryParamProps;
};

export default withQueryParamProps;

import ThunkFetch from 'helpers/thunkFetch';

import { payloadToUnderscore } from 'services/api/formatHelpers';
import { getPartnershipAddFundingAccountAddress } from 'services/api/partnershipsEndpoints';

import * as types from 'types/partnership';

const submitPartnershipFundingAccountAddress = (payload, options = {}) => {
  const { errorCallback, partnershipId, successCallback } = options;

  const augmentedPayload = {
    data: {
      type: 'FundingAccount',
    },
    meta: payloadToUnderscore(payload),
  };

  const t = new ThunkFetch({
    actions: {
      error: types.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_FAILURE,
      request: types.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_REQUEST,
      success: types.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_SUCCESS,
    },
    endpoint: getPartnershipAddFundingAccountAddress(partnershipId),
    errorCallback,
    id: partnershipId,
    method: 'POST',
    payload: augmentedPayload,
    requireAuth: true,
    successCallback,
  });

  return t.makeRequest();
};

export default submitPartnershipFundingAccountAddress;

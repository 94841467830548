import { statusVariants } from '@routable/gross-ds';
import { DateFormats } from '@routable/shared';
import dayjs from 'dayjs';

import { ItemStatuses, ItemTimelineDisplayStatus } from 'enums/items';

import { bulkImportHasIssues } from 'helpers/bulkActions';
import { formatDate } from 'helpers/date';
import { getBillOrInvoiceText, getItemDateDue } from 'helpers/items';

import { getItemStatusText } from './getItemStatusText';
import { itemStatusToText, itemStatusToVariant } from './itemStatusHelpers';
import { getItemCompletedText, getOnDateText } from './text';

/**
 * Get the text for a completed bulk import.
 * @param {BulkImport} bulkImport
 * @return {string}
 */
export const getBulkImportCompletedText = (bulkImport) => {
  switch (bulkImport.status) {
    case ItemStatuses.BULK_UPLOAD_COMPLETE:
      return getItemCompletedText(bulkImport);

    default:
      return '';
  }
};

/**
 * Get text for importing from ledger.
 * @param {Object} item
 * @param {?string} ledgerName
 * @return {string}
 */
export const getImportText = (item, ledgerName) =>
  ledgerName
    ? `Loading ${getBillOrInvoiceText(item).toLowerCase()} from ${ledgerName}...`
    : `Loading ${getBillOrInvoiceText(item).toLowerCase()}...`;

/**
 * Get due-by-date text.
 * @param {?string} dateStr
 * @return {string}
 */
export const getDueByText = (dateStr) => (dateStr ? `Due by ${dateStr}` : '');

/**
 * Gets the main status to display, given a bulk import.
 * @param {BulkImport} bulkImport
 * @returns {string}
 */
export const getDisplayStatusForBulkImport = (bulkImport) => {
  const { status } = bulkImport;

  switch (status) {
    case ItemStatuses.BULK_UPLOAD_COMPLETE:
      return bulkImportHasIssues(bulkImport)
        ? `${itemStatusToText[ItemStatuses.COMPLETED]} (with failures)`
        : itemStatusToText[ItemStatuses.COMPLETED];

    default:
      return 'In progress';
  }
};

/**
 * Get the status, generic status, className, and text, given a bulk import.
 * @param {BulkImport} bulkImport
 * @return {Object}
 */
export const getBulkImportStatus = (bulkImport) => {
  const statusText = getDisplayStatusForBulkImport(bulkImport);
  const text = getBulkImportCompletedText(bulkImport);

  return {
    status: bulkImport.status === 'complete' ? statusVariants.success : statusVariants.pending,
    statusText,
    text,
  };
};

/**
 * Get formatted version of date for displaying in timeline status.
 * @param {string} date
 * @return {string}
 */
export const getFormattedTimelineStatusDate = (date) =>
  formatDate(dayjs(date), DateFormats.SHORT_ALPHA_MONTH_FULL_YEAR);

/**
 * Get the subtext to display in item timeline for given status.
 * @param {Object} item
 * @param {Object} timelineStatus
 * @param {{ ledgerName: ?string }} options
 * @return {string}
 */
export const getTimelineStatusText = (item, timelineStatus, options = {}) => {
  const { ledgerName } = options;

  const statusToConsider = timelineStatus.status;
  const statusDate = getFormattedTimelineStatusDate(timelineStatus.date);
  const dueDate = getItemDateDue(item);

  switch (statusToConsider) {
    case ItemStatuses.CREATED:
      return getOnDateText(statusDate);

    case ItemStatuses.IMPORT:
      return getImportText(item, ledgerName);

    case ItemStatuses.NEEDS_APPROVAL:
      return getDueByText(dueDate);

    default:
      return getItemStatusText(item, statusToConsider);
  }
};

const timelineStatusToText = {
  [ItemStatuses.CREATED]: ItemTimelineDisplayStatus.CREATED,
};

/**
 * Get the status, className, and text for a timeline status block.
 * @param {Object} item
 * @param {Object} timelineStatus
 * @param {{ ledgerName: ?string }} options
 * @return {Object}
 */
export const getTimelineStatusInfo = (item, timelineStatus, options = {}) => {
  const { status } = timelineStatus;
  return {
    variant: itemStatusToVariant[status],
    textStatus: timelineStatusToText[status] || itemStatusToText[status],
    textComplement: getTimelineStatusText(item, timelineStatus, options),
  };
};

export const shouldHideLedgerStatus = (item, ledger = {}) => !item.ledgerStatus || !ledger.application;

import { combineReducers } from 'redux';

import allIdsReducer from './allIdsReducer';
import byIdReducer from './byIdReducer';

const notificationSettingsEmailForwardsReducer = combineReducers({
  allIds: allIdsReducer,
  byId: byIdReducer,
});

export default notificationSettingsEmailForwardsReducer;

import { QBO } from 'constants/integration';

export const LedgerMatchFilterTypes = {
  UNMATCHED: 'unmatched',
  MATCHED: 'matched',
  ARCHIVED: 'archived',
};

export const LedgerSides = {
  LEDGER: 'ledger',
  PLATFORM: 'platform',
  ALL: 'all',
};

/**
 * @enum {string}
 * @typedef {string} LedgerApplicationType
 * @type {Object.<string, LedgerApplicationType>}
 */
export const LedgerApplicationTypes = {
  MICROSOFT_DYNAMICS: 'microsoft_dynamics',
  NETSUITE: 'netsuite',
  NONE: 'none',
  ORACLE: 'oracle',
  OTHER: 'other',
  QBO: 'qbo',
  SAGE_INTACCT: 'sage_intacct',
  SIMPLE: 'simple',
  XERO: 'xero',
};

/**
 * @type {EnabledKeys}
 */
export const LedgerMockOAuthApplications = {
  [LedgerApplicationTypes.NETSUITE]: true,
  [LedgerApplicationTypes.SAGE_INTACCT]: true,
};

export const LedgerContactType = {
  BOTH: 'both',
  SINGULAR: 'singular',
};

export const accountingSoftwareList = [
  {
    id: 'e5ebf327-0304-425a-bd69-62f45aa1f121',
    value: LedgerApplicationTypes.XERO,
    text: 'Xero',
  },
  {
    id: '6adfb959-1b69-4654-8313-2ba2a4cbcc37',
    value: LedgerApplicationTypes.QBO,
    text: QBO,
  },
  {
    id: '346a9895-6c83-47e2-8222-76ff8301cee0',
    value: LedgerApplicationTypes.NETSUITE,
    text: 'NetSuite',
  },
  {
    id: '00161b43-dd7c-46da-a865-bcaa3e98c9d7',
    value: LedgerApplicationTypes.SAGE_INTACCT,
    text: 'Sage Intacct',
  },
  {
    id: '64846f88-eca7-48fa-8ad2-00c3acd71fc8',
    value: LedgerApplicationTypes.MICROSOFT_DYNAMICS,
    text: 'Microsoft Dynamics',
  },
  {
    id: 'f8db9dcf-d5dd-4ad1-86e1-b048b9024dac',
    value: LedgerApplicationTypes.ORACLE,
    text: 'Oracle',
  },
  {
    id: '6dd95f2d-b4c4-428d-bf20-327badc0438d',
    value: LedgerApplicationTypes.OTHER,
    text: 'Other',
  },
  {
    id: '3b23f392-20e1-4c10-9f78-826e958067e0',
    value: LedgerApplicationTypes.NONE,
    text: "I don't use an accounting software",
  },
];

// *************************************
// Settings
// *************************************

export const LedgerSettingsClassModeTypes = {
  ITEM: 'item',
  LINE_ITEM: 'line_item',
  NONE: 'none',
};

export const LedgerSettingsDiscountModeTypes = {
  ITEM: 'item',
  LINE_ITEM: 'line_item',
  NONE: 'none',
};

export const LedgerSettingsTaxCalculationTypes = {
  LINE_ITEM: 'line_item',
  SUBTOTAL: 'subtotal',
};

export const LedgerSettingsTaxModeTypes = {
  AUTO: 'auto',
  MANUAL: 'manual',
};

// *************************************
// QBO
// *************************************

export const LedgerDiscountCalculationTypes = {
  AMOUNT: 'amount',
  PERCENTAGE: 'percentage',
};

export const LedgerDiscountStyles = {
  PRE_TAX: 'pre_tax',
  POST_TAX: 'post_tax',
};

export const SpecialCaseTaxCodesQBO = {
  AUTO_TAX: 'AUTO_TAX',
  NO_TAX: 'NO_TAX',
};

// *************************************
// Xero
// *************************************

export const LedgerTaxStyles = {
  EXCLUSIVE: 'exclusive',
  INCLUSIVE: 'inclusive',
  NO_TAX: 'none',
};

export const LedgerLineItemTaxIdsXero = {
  NO_TAX: 'NONE',
};

export const XeroDisallowedFileNameRegex = /([^0-9a-zA-Z_~`!@$^&()\-=[\]{},.'])+/g;

// *************************************
// External ledger resource links
// *************************************

export const LEDGER_APPLICATION_ID_SUBSTITUTION_SLUG = '{application_id}';

/**
 * The LedgerOptionsFormSection component is a set of two radio buttons. The radios ask RCTMs whether the
 * action they're taking (cancel, mark as paid) should happen both on Routable and the ledger or ONLY on
 * Routable.
 *
 * @enum {string}
 */
export const LedgerOptionsFormValue = {
  UPDATE_LEDGER: 'false',
  DO_NOT_UPDATE_LEDGER: 'true',
};

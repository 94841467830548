import { uuidModel } from '@routable/shared';
import { z } from 'zod';
export const partnershipMemberAccessType = z.enum(['actionable', 'none', 'read_only', 'self_managed']);
export const partnershipMemberModel = z.object({
    type: z.literal('PartnershipMember'),
    id: uuidModel,
    attributes: z.object({
        avatar: z.string().url().nullish(),
        defaultGeneral: partnershipMemberAccessType,
        defaultItem: partnershipMemberAccessType,
        email: z.string().email().nullish(),
        firstName: z.string().email().nullish(),
        hasEmail: z.boolean().default(false),
        isArchived: z.boolean().default(false),
        lastName: z.string().nullish(),
        notificationGeneral: partnershipMemberAccessType,
        phoneNumber: z.object({
            number: z.string(),
            country: z.string(),
        }),
        externalFlowUrl: z.string().url().nullish(),
    }),
    relationships: z.object({
        company: z.object({
            data: z
                .object({
                type: z.literal('Company'),
                id: uuidModel,
            })
                .nullish(),
        }),
        membership: z.object({
            data: z
                .object({
                type: z.literal('Membership'),
                id: uuidModel,
            })
                .nullish(),
        }),
    }),
});

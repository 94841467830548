import React from 'react';

import FlowStepperLeft from 'modules/signup-v3/FlowStepperLeft';
import BusinessAddressMain from 'modules/signup-v3/views/BusinessAddress';
import BusinessCategoryMain from 'modules/signup-v3/views/BusinessCategory';
import BusinessPhoneNumber from 'modules/signup-v3/views/BusinessPhoneNumber';
import BusinessRepresentativeMain from 'modules/signup-v3/views/BusinessRepresentative';
import BusinessVerificationMain from 'modules/signup-v3/views/BusinessVerification';
import LegalBusinessNameMain from 'modules/signup-v3/views/LegalBusinessName';
import PersonalInformationMain from 'modules/signup-v3/views/PersonalInformation';
import QualificationMain from 'modules/signup-v3/views/Qualification';
import RepresentativeAddress from 'modules/signup-v3/views/RepresentativeAddress';
import RepresentativeIDNumber from 'modules/signup-v3/views/RepresentativeIDNumber';
import RepresentativeUploadDocument from 'modules/signup-v3/views/RepresentativeUploadDocument';
import ReviewStep from 'modules/signup-v3/views/ReviewStep';
import SignupTaxInfo from 'modules/signup-v3/views/SignupTaxInfo';
import UploadDocumentMain from 'modules/signup-v3/views/UploadDocument';

import { signupFlowForm, views } from './constants';
import { Form, LeftContainer, MainContainer } from './SignUpFlow.styles';

const SignUpFlowForm = (props) => {
  const { handleSubmit, activeView } = props;

  if (!activeView) {
    return null;
  }

  const main = {
    [views.businessVerification]: <BusinessVerificationMain {...props} />,
    [views.legalBusinessName]: <LegalBusinessNameMain {...props} />,
    [views.businessType]: <SignupTaxInfo {...props} />,
    [views.businessCategory]: <BusinessCategoryMain {...props} />,
    [views.businessAddress]: <BusinessAddressMain {...props} />,
    [views.businessPhoneNumber]: <BusinessPhoneNumber {...props} />,
    [views.uploadDocument]: <UploadDocumentMain {...props} />,
    [views.representativeIDNumber]: <RepresentativeIDNumber {...props} />,
    [views.representativeAddress]: <RepresentativeAddress {...props} />,
    [views.businessRepresentative]: <BusinessRepresentativeMain {...props} />,
    [views.qualification]: <QualificationMain {...props} />,
    [views.personalInformation]: <PersonalInformationMain {...props} />,
    [views.representativeUploadDocument]: <RepresentativeUploadDocument {...props} />,
    [views.review]: <ReviewStep {...props} />,
  }[activeView];

  return (
    <div>
      <Form className="form" id={signupFlowForm} onSubmit={handleSubmit}>
        <LeftContainer>
          <FlowStepperLeft activeStep={activeView} />
        </LeftContainer>
        <MainContainer>{main}</MainContainer>
      </Form>
    </div>
  );
};

export default SignUpFlowForm;

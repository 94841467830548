import * as routines from 'actions/routines/roles';

import { roleRelationships } from 'constants/relationships';

import { createByIdReducer } from 'store/redux';

import { FETCH_MEMBERSHIP_INVITE_SUCCESS } from 'types/inviteTeamMember';

const byIdReducer = createByIdReducer({
  key: 'role',
  initialState: {},
  types: [FETCH_MEMBERSHIP_INVITE_SUCCESS, routines.fetchRolesRoutine.SUCCESS],
  relationships: roleRelationships,
});

export default byIdReducer;

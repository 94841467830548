import * as types from 'types/maintenance';

export const initialState = {
  on: false,
};

const maintenanceModeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MAINTENANCE_MODE_OFF:
      return initialState;

    case types.MAINTENANCE_MODE_ON:
      return {
        ...state,
        ...action.payload,
        on: true,
      };

    default:
      return state;
  }
};

export default maintenanceModeReducer;

import { StatusIndicator, statusVariants } from '@routable/gross-ds';
import React from 'react';
import {} from '../ContactCellsTypes.types';
const contactStatusToVariant = {
    active: statusVariants.success,
    archived: statusVariants.default,
    invalid: statusVariants.error,
};
const ContactStatusCell = ({ rowData }) => {
    return (React.createElement(StatusIndicator, { className: "font-xs", status: contactStatusToVariant[rowData.status] }, rowData.status));
};
export default ContactStatusCell;

import { AuthActionType } from 'enums/auth';
import {} from 'interfaces/auth';
export const clearLoginErrorState = () => ({
    type: AuthActionType.CLEAR_LOGIN_ERROR_STATE,
});
export const clearResetPasswordState = () => ({
    type: AuthActionType.CLEAR_RESET_PASSWORD_STATE,
});
export const logoutRequest = () => ({
    type: AuthActionType.SUBMIT_LOGOUT_REQUEST,
});
export const logoutComplete = () => ({
    type: AuthActionType.SUBMIT_LOGOUT_COMPLETE,
});
export const logoutCleanup = () => ({
    type: AuthActionType.SUBMIT_LOGOUT_CLEANUP,
});
export const logoutFailure = (errors) => ({
    payload: { errors },
    type: AuthActionType.SUBMIT_LOGOUT_FAILURE,
});

import React from 'react';
import { Table } from 'complexComponents/Table';
import { CompanyContactsTableConfig } from './constants/tableConfig';
import { addContactActionClickHandlers } from './helpers';
const CompanyContactsTableRender = (props) => {
    const decoratedData = addContactActionClickHandlers(props);
    const getRowProps = () => ({ className: 'disable-hover' });
    return React.createElement(Table, { columns: CompanyContactsTableConfig, data: decoratedData, getRowProps: getRowProps });
};
export default CompanyContactsTableRender;

import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'components';

import { colors, sizes } from 'constants/styles';

import { oneOfValuesFromObject } from 'helpers/propTypes';

import RestrictedPermissionTooltip from 'modules/auth/RestrictedPermissionTooltip';

/**
 * Displays an icon which is disabled. On hover, it says that you do not have sufficient permissions.
 *
 * @param {ComponentProps} props
 * @param {string} [props.color=grey--med]
 * @param {string} props.icon
 * @param {number} [props.size=14]
 * @returns {StatelessComponent}
 */
const RestrictedPermissionTooltipIcon = ({ color, icon, size, ...rest }) => (
  <RestrictedPermissionTooltip>
    <Icon color={color} icon={icon} size={size} {...rest} />
  </RestrictedPermissionTooltip>
);

RestrictedPermissionTooltipIcon.propTypes = {
  color: oneOfValuesFromObject(colors),
  icon: PropTypes.string.isRequired,
  size: oneOfValuesFromObject(sizes.iconSizes),
};

RestrictedPermissionTooltipIcon.defaultProps = {
  color: colors.colorGreyMed,
  size: sizes.iconSizes.LARGE,
};

export default RestrictedPermissionTooltipIcon;

import React from 'react';
import { DropdownMenuButton } from 'complexComponents';
import {} from './AddPaymentMethodDropdownButton.types';
import { AddPaymentMethodDropdownOption } from './components';
import { useGetAddPaymentMethodDropdownOptions } from './hooks';
import './AddPaymentMethodDropdownButton.scss';
const AddPaymentMethodDropdownButton = ({ isDisabled, onAddCheckAccount, onAddBankAccount, visible = true, }) => {
    const options = useGetAddPaymentMethodDropdownOptions({
        onAddBankAccount,
        onAddCheckAccount,
    });
    if (!visible) {
        return null;
    }
    return (React.createElement(DropdownMenuButton, { containerClassName: "add-payment-method-dropdown-button", isDisabled: isDisabled, menuAlign: "right", OptionComponent: AddPaymentMethodDropdownOption, options: options }, "Add a payment method"));
};
export default AddPaymentMethodDropdownButton;

import {
  lookupMembershipInviteEmailRoutine,
  membershipInviteSubmitRoutine,
  revokeMembershipInviteRoutine,
} from 'actions/routines/inviteTeamMember';

import { removeResourceFromAllIds } from 'helpers/reducer';
import { allKeys } from 'helpers/utility';

import { FETCH_MEMBERSHIP_INVITE_SUCCESS, FETCH_MEMBERSHIP_INVITES_SUCCESS } from 'types/inviteTeamMember';

const allReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_MEMBERSHIP_INVITES_SUCCESS:
      return allKeys(action.payload.membershipInvite);

    case FETCH_MEMBERSHIP_INVITE_SUCCESS:
    case lookupMembershipInviteEmailRoutine.FAILURE:
    case membershipInviteSubmitRoutine.SUCCESS:
      return Array.from(new Set([...state, ...allKeys(action.payload.membershipInvite)]));

    case revokeMembershipInviteRoutine.SUCCESS:
      return removeResourceFromAllIds(state, action);

    default:
      return state;
  }
};

export default allReducer;

import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

const { MultiValueContainer } = components;

/**
 * Container responsible for rendering the label and remove components
 * for a single multi-select tag value.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 * @constructor
 */
const TagMultiValueContainer = ({ children, ...rest }) => (
  <MultiValueContainer {...rest}>{children}</MultiValueContainer>
);

TagMultiValueContainer.propTypes = {
  children: PropTypes.node,
};

TagMultiValueContainer.defaultProps = {
  children: undefined,
};

export default TagMultiValueContainer;

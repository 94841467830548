import { connect } from 'react-redux';

import { externalGeneralFormSelector } from 'selectors/forms';

import PartnerCompanySSNEINToggle from './PartnerCompanySSNEINToggle';

const mapStateToProps = (state) => ({
  companyInfo: externalGeneralFormSelector(state, 'company.info'),
});

export default connect(mapStateToProps)(PartnerCompanySSNEINToggle);

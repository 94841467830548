import React from 'react';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import EditButtonInput from 'components/form/EditButtonInput';

import { digitsOnly } from 'helpers/fieldNormalizers';
import { TINValidator, requiredValidator } from 'helpers/fieldValidation';
import { formatAsSSN } from 'helpers/numbers';

/**
 * Input field for editing/viewing the tax SSN.
 * @param {object} props
 * @returns {StatelessComponent}
 * @constructor
 */
const SSNInput = (props) => {
  const ssnValidator = React.useMemo(() => TINValidator('SSN'), []);

  return (
    <EditButtonInput
      {...props}
      format={formatAsSSN}
      maxLength={11}
      normalize={digitsOnly}
      placeholder="Social Security Number"
      staticValue="•••-••-••••"
      type="text"
      validate={[requiredValidator, ssnValidator]}
    />
  );
};

export default SSNInput;

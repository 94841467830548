import React from 'react';

import { PLATFORM_DISPLAY_SHORT_NAME, PLATFORM_URL } from '../../../../../constants/platform';

import './PoweredByBadge.scss';

const PoweredByBadge = () => (
  <div className="powered-by">
    <a className="primary remove-hover-underline" href={PLATFORM_URL} rel="noopener noreferrer" target="_blank">
      <span className="icon-ic-logo-letter powered-by--logo" />
      <span className="powered-by--text">{`Powered by ${PLATFORM_DISPLAY_SHORT_NAME}`}</span>
    </a>
  </div>
);

export default PoweredByBadge;

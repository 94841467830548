import React from 'react';

import { reduxFormInputPropType } from 'components/commonProps';

import InvoiceGeneratorTotalsRow from '../InvoiceGeneratorTotalsRow';

const InvoiceGeneratorTotalsTotal = ({ input, ...rest }) => (
  <div className="total">
    <InvoiceGeneratorTotalsRow {...rest} amount={input.value} text="Total" />
  </div>
);

InvoiceGeneratorTotalsTotal.propTypes = {
  input: reduxFormInputPropType.isRequired,
};

export default InvoiceGeneratorTotalsTotal;

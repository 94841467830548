import queryString, {} from 'query-string';
import { allKeys, isObject } from 'helpers/utility';
export const getQueryParam = (paramName, location = window.location) => {
    const queryParams = queryString.parse(location.search);
    return queryParams[paramName];
};
export const getQueryParamsFromUrl = (query = window.location.search) => queryString.parse(query);
export const parseUrl = (url) => queryString.parseUrl(url);
export const getQueryParamValueFromUrl = (queryKey, urlString = window.location.search) => {
    const queryParams = getQueryParamsFromUrl(urlString);
    const queryValue = queryParams[queryKey];
    if (typeof queryValue === 'string' || typeof queryValue === 'undefined') {
        return queryValue;
    }
    throw new Error('This function only works to extract querystrings which are string, not arrays or null');
};
export const getQueryString = (params, options = {}) => {
    const result = [];
    allKeys(params)
        .sort()
        .forEach((key) => {
        if (!params[key]) {
            return;
        }
        const { prefix } = options;
        const value = params[key];
        const formattedKey = prefix ? `${prefix}[${key}]` : key;
        const encodedValue = isObject(value)
            ? getQueryString(value, { prefix: formattedKey })
            : queryString.stringify({ [formattedKey]: value });
        result.push(encodedValue);
    });
    return result.join('&');
};

export var googlePlacesAttrs;
(function (googlePlacesAttrs) {
    googlePlacesAttrs["street_number"] = "short_name";
    googlePlacesAttrs["route"] = "long_name";
    googlePlacesAttrs["locality"] = "long_name";
    googlePlacesAttrs["administrative_area_level_1"] = "short_name";
    googlePlacesAttrs["country"] = "short_name";
    googlePlacesAttrs["postal_code"] = "short_name";
})(googlePlacesAttrs || (googlePlacesAttrs = {}));
export var googlePlacesAttrsToAddressFields;
(function (googlePlacesAttrsToAddressFields) {
    googlePlacesAttrsToAddressFields["street_number"] = "streetAddress";
    googlePlacesAttrsToAddressFields["route"] = "streetAddress";
    googlePlacesAttrsToAddressFields["locality"] = "city";
    googlePlacesAttrsToAddressFields["administrative_area_level_1"] = "state";
    googlePlacesAttrsToAddressFields["country"] = "country";
    googlePlacesAttrsToAddressFields["postal_code"] = "postalcode";
    googlePlacesAttrsToAddressFields["address_unit"] = "streetAddressUnit";
})(googlePlacesAttrsToAddressFields || (googlePlacesAttrsToAddressFields = {}));

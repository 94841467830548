import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { hashObject } from '../../types/network.types';
export const usePagination = ({ data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage }, initial) => {
    const [page, setPage] = useState(initial?.page ?? 1);
    const [pageSize, setPageSize] = useState(initial?.pageSize ?? 25);
    const dataRef = useRef([]);
    dataRef.current = data?.pages?.flatMap((d) => d.data) ?? [];
    const total = data?.pages?.[0]?.pagination.total ?? 0;
    const pages = Math.ceil(total / pageSize);
    const initialPage = initial?.page ?? 1;
    useEffect(() => {
        setPage(initialPage);
    }, [initialPage, setPage]);
    const pageData = useMemo(() => {
        const slicedData = dataRef.current.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
        if (!isFetchingNextPage && !isFetching && hasNextPage && slicedData.length !== pageSize) {
            fetchNextPage();
        }
        return slicedData;
    }, [fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, page, pageSize]);
    return {
        data: pageData,
        setPageSize: useCallback((size, destinationPageIndex) => {
            setPageSize(size);
            if (destinationPageIndex) {
                setPage(destinationPageIndex);
            }
        }, []),
        gotoPage: useCallback((destination) => {
            if (destination === 'NEXT') {
                setPage((p) => Math.min(p + 1, pages));
            }
            else if (destination === 'PREV') {
                setPage((p) => p - 1 || 1);
            }
            else if (destination > 0 && destination <= pages) {
                setPage(destination);
            }
        }, [pages]),
        pagination: {
            page,
            pageSize,
            total,
            pages,
            hasPrevPage: page > 1,
            hasNextPage: page < pages,
        },
    };
};
export const usePaginated = ({ data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage }, { page, pageSize, setPagination, }, watch = []) => {
    const total = data?.pages?.[0]?.pagination?.total ?? 0;
    const pages = Math.ceil(total / pageSize);
    const hashedWatch = watch.map(hashObject).join('|||');
    useEffect(() => () => {
        setPagination({ page: 1 });
    }, [hashedWatch, setPagination]);
    const pageData = useMemo(() => {
        const altPageSize = parseInt(`${pageSize}`, 10);
        const slicedData = (data?.pages?.flatMap((d) => d?.data) ?? []).slice((page - 1) * altPageSize, (page - 1) * altPageSize + altPageSize);
        if (!isFetchingNextPage && !isFetching && hasNextPage && slicedData.length !== altPageSize) {
            fetchNextPage();
        }
        return slicedData;
    }, [data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, page, pageSize]);
    return {
        data: pageData,
        setPageSize: useCallback((size, destinationPageIndex = 1) => {
            setPagination({ page: destinationPageIndex, pageSize: size });
        }, [setPagination]),
        gotoPage: useCallback((destination) => {
            if (destination === 'NEXT') {
                setPagination({ page: Math.min(page + 1, pages) });
            }
            else if (destination === 'PREV') {
                setPagination({ page: page - 1 || 1 });
            }
            else if (destination > 0 && destination <= pages) {
                setPagination({ page: destination });
            }
        }, [pages, page, setPagination]),
        pagination: useMemo(() => ({
            page,
            pageSize,
            total,
            pages,
            hasPrevPage: page > 1,
            hasNextPage: page < pages,
        }), [page, pageSize, pages, total]),
    };
};

import PropTypes from 'prop-types';
import React from 'react';

import { getClassNames } from 'helpers/ui';

/**
 * Table container component for the invoice generator ledger field tables.
 * @param {ComponentProps} props
 * @param {*} children
 * @return {StatelessComponent}
 */
const InvoiceGeneratorTableContainer = ({ children, ...rest }) => (
  <ul
    {...rest}
    className={getClassNames(rest, {
      'platform-table--inline': true,
      'remove-margin-bottom': true,
    })}
  >
    {children}
  </ul>
);

InvoiceGeneratorTableContainer.propTypes = {
  children: PropTypes.node,
};

InvoiceGeneratorTableContainer.defaultProps = {
  children: undefined,
};

export default InvoiceGeneratorTableContainer;

import PropTypes from 'prop-types';
import React from 'react';

import { ExternalBlock } from 'modules/external/ExternalBlocksSection';

import { ExternalReceiptPayableItemEntries, ExternalReceiptReceivableItemEntries } from './components';

/**
 * Component rendering Item info in External Receipt
 * @param {ComponentProps} props
 * @param {Item} props.item
 * @returns {StatelessComponent}
 */
const ExternalReceiptItem = ({ item }) => (
  <ExternalBlock>
    <ExternalReceiptPayableItemEntries item={item} />
    <ExternalReceiptReceivableItemEntries item={item} />
  </ExternalBlock>
);

ExternalReceiptItem.propTypes = {
  item: PropTypes.shape({}).isRequired,
};

export default ExternalReceiptItem;

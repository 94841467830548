import React from 'react';
import { taxFormEntryType, taxFormType } from './TaxFormRenderer.models';
export const TaxFormOptionsContext = React.createContext({
    countries: [],
    entryType: taxFormEntryType.Enum.external,
    formType: taxFormType.Enum.W8,
    isSignatureAllowed: true,
    showCompanyTypeField: true,
});
export const useTaxFormOptions = () => React.useContext(TaxFormOptionsContext);

import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleViewTeamMembersModal, toggleInviteTeamMemberModal } from 'actions/signUpFlow';
import { Text, Modal, ButtonV2, IconNames, TooltipMUIConditionalWrapper } from 'components';
import { ButtonSize } from 'constants/button';
import { typography, colors } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';
import { teamMembers } from 'modules/signup-v3/constants/modal';
import { ALL, INVITES } from 'modules/signup-v3/constants/tabs';
import { isInviteDisabled } from 'modules/signup-v3/helpers/memberships';
import { MEMBERSHIPS_LIMIT } from '../../constants/memberships';
import ActiveMembersTable from './ActiveMembersTable';
import InvitedTeamMembersTable from './InvitedMembersTable';
import TeamMembersNavTab from './TeamMembersNavTab';
import ViewTeamMembersFooter from './ViewTeamMembersFooter';
import { Container, TableHeader, TableTitle, TableDescription, ButtonContainer } from './ViewTeamMembersModal.styles';
import {} from './ViewTeamMembersModal.types';
import './ViewTeamMembersModal.scss';
import 'complexComponents/ActionNav/ActionNav.scss';
const ViewTeamMembersModal = ({ open, currentCompanyMembers, pendingMembershipInvites, }) => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = React.useState(ALL);
    const invitedMembersNumber = currentCompanyMembers.length + pendingMembershipInvites.length;
    const tooltipProps = isInviteDisabled(invitedMembersNumber)
        ? {
            arrow: true,
            padding: TooltipPadding.LARGE,
            placement: TooltipPlacement.TOP,
            style: {
                fontSize: '.75rem',
            },
            title: `You've already invited the maximum of ${MEMBERSHIPS_LIMIT - 1} people. To invite another member of your team, revoke an existing invite first.`,
        }
        : undefined;
    const onCloseModal = () => {
        dispatch(toggleViewTeamMembersModal());
        setActiveTab(ALL);
    };
    const handleAddNewTeamMember = () => {
        dispatch(toggleViewTeamMembersModal());
        dispatch(toggleInviteTeamMemberModal());
    };
    const tabListItems = [
        {
            type: ALL,
            numberOfItems: currentCompanyMembers.length,
            label: 'All team members',
        },
        {
            type: INVITES,
            numberOfItems: pendingMembershipInvites.length,
            label: 'Invites',
        },
    ];
    return (React.createElement(Modal, { body: React.createElement(React.Fragment, null,
            React.createElement(TeamMembersNavTab, { activeTab: activeTab, setActiveTab: setActiveTab, tabListItems: tabListItems }),
            React.createElement(Container, null,
                React.createElement(TableHeader, null,
                    React.createElement("div", null,
                        React.createElement(TableTitle, null,
                            React.createElement(Text.Bold, { color: "#4D5C71", size: typography.TextSize.LEVEL_400 }, teamMembers[activeTab].title)),
                        React.createElement(TableDescription, null,
                            React.createElement(Text.Regular, { color: "#66788A" }, teamMembers[activeTab].description))),
                    activeTab === INVITES && (React.createElement(ButtonContainer, null,
                        React.createElement(TooltipMUIConditionalWrapper, { tooltipProps: tooltipProps },
                            React.createElement(ButtonV2, { isDisabled: isInviteDisabled(invitedMembersNumber), leftIconClassName: "margin-right--xm", leftIconName: IconNames.PLUS, onClick: handleAddNewTeamMember, size: ButtonSize.MEDIUM }, "Invite a team member"))))),
                activeTab === ALL && React.createElement(ActiveMembersTable, null),
                activeTab === INVITES && React.createElement(InvitedTeamMembersTable, null)),
            React.createElement(ViewTeamMembersFooter, { handleClose: onCloseModal })), hasInlineFooter: true, modalHeader: "Team members", modalState: open, onCloseModal: onCloseModal, overrideStyle: {
            content: {
                backgroundColor: colors.colorBlueExtraLight,
            },
        } }));
};
export default ViewTeamMembersModal;

import React from 'react';
import { Hint, Text, WhiteSpace } from 'components';
import { typography } from 'constants/styles';
import { Intent } from 'constants/ui';
const CustomerSetUpToPayWithACHHint = ({ partnership }) => (React.createElement(Hint, { className: "customer-set-up-to-pay-with-ach-hint", intent: Intent.SUCCESS },
    React.createElement(Text.Bold, { color: typography.TextColor.GREEN_DARK },
        partnership.name,
        React.createElement(WhiteSpace, null),
        "is set up to pay with ACH")));
export default CustomerSetUpToPayWithACHHint;

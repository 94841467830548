import React, { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Script from 'react-load-script';
import { InputField, SelectFieldV2 } from 'components';
import { StaticCountryCode } from 'constants/countries';
import { countriesMapping, countryListOptions } from 'constants/i18n';
import { getStatesByCountry } from 'helpers/addressHelpers';
import { useAddressAutocomplete } from './AddressHookForm.hook';
export const AddressHookForm = ({ addressPath = '', countryOptions = countryListOptions.ALL, onInvalidCountry, }) => {
    const { control, setValue, watch } = useFormContext();
    const streetAddressRef = useRef(null);
    const { onLoad, scriptUrl } = useAddressAutocomplete({
        addressPath,
        countryOptions,
        inputRef: streetAddressRef,
        onInvalidCountry,
        setValue,
    });
    const path = addressPath ? `${addressPath}.` : '';
    const selectedCountry = watch(`${path}country`);
    const stateOrProvince = selectedCountry === StaticCountryCode.CA ? 'Province' : 'State';
    return (React.createElement(React.Fragment, null,
        React.createElement(Script, { onLoad: onLoad, url: scriptUrl }),
        React.createElement("div", { className: "flex flex-col md:flex-row gap-5" },
            React.createElement(Controller, { control: control, name: `${path}streetAddress`, render: ({ field }) => (React.createElement(InputField, { autoComplete: "false", className: "w-full md:w-2/3", isRequired: true, isUncontrolled: true, name: field.name, onBlur: field.onBlur, onChange: field.onChange, placeholder: "Street address", setRef: streetAddressRef, value: field.value })), rules: { required: true } }),
            React.createElement(Controller, { control: control, name: `${path}streetAddressUnit`, render: ({ field: { ref, ...fieldProps } }) => (React.createElement(InputField, { ...fieldProps, className: "w-full md:w-1/3", isRequired: false, isUncontrolled: true, placeholder: "Street address unit", setRef: ref })) })),
        React.createElement(Controller, { control: control, name: `${path}country`, render: ({ field }) => (React.createElement(SelectFieldV2, { input: field, label: "Country", name: field.name, options: countriesMapping[countryOptions], required: true, setRef: field.ref, type: "text" })), rules: { required: true } }),
        React.createElement("div", { className: "flex flex-col md:flex-row gap-5" },
            React.createElement(Controller, { control: control, name: `${path}city`, render: ({ field: { ref, ...fieldProps } }) => (React.createElement(InputField, { ...fieldProps, className: "w-full md:w-1/3", isRequired: true, isUncontrolled: true, placeholder: "City", setRef: ref })), rules: { required: true } }),
            React.createElement(Controller, { control: control, name: `${path}state`, render: ({ field }) => (React.createElement(SelectFieldV2, { input: field, label: stateOrProvince, name: field.name, options: getStatesByCountry({ country: selectedCountry }), required: true, setRef: field.ref, wrapperClassName: "w-full md:w-1/3" })), rules: { required: true } }),
            React.createElement(Controller, { control: control, name: `${path}postalcode`, render: ({ field: { ref, ...fieldProps } }) => (React.createElement(InputField, { ...fieldProps, className: "w-full md:w-1/3", isRequired: true, isUncontrolled: true, placeholder: "Postal code", setRef: ref })), rules: { required: true } }))));
};

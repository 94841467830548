import { finalizeBulkActionRoutine, submitBulkActionRoutine } from 'actions/routines/bulkActions';

import { BulkActionSummarySteps } from 'enums/bulkActions';

import * as types from 'types/bulkActions';

const currentStepReducer = (state = BulkActionSummarySteps.INITIAL, action) => {
  switch (action.type) {
    case finalizeBulkActionRoutine.FAILURE:
    case submitBulkActionRoutine.FAILURE:
    case types.CLOSE_BULK_ACTION_MODAL:
    case types.OPEN_BULK_ACTION_APPROVE_MODAL:
    case types.OPEN_BULK_ACTION_APPROVE_AND_SEND_MODAL:
    case types.OPEN_BULK_ACTION_EDIT_SEND_DATE_MODAL:
    case types.OPEN_BULK_ACTION_SEND_MODAL:
    case types.RESET_BULK_ACTIONS_STATE:
      return BulkActionSummarySteps.INITIAL;

    case finalizeBulkActionRoutine.REQUEST:
      return BulkActionSummarySteps.LOADING;

    case finalizeBulkActionRoutine.SUCCESS:
      return BulkActionSummarySteps.CONFIRMATION;

    default:
      return state;
  }
};

export default currentStepReducer;

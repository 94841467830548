import { combineReducers } from 'redux';

import partnershipAddressesReducer from 'reducers/partnershipsReducer/fundingAccounts/address';
import allIdsReducer from 'reducers/partnershipsReducer/fundingAccounts/allIdsReducer';
import partnershipBankAccountsReducer from 'reducers/partnershipsReducer/fundingAccounts/bank';
import byIdReducer from 'reducers/partnershipsReducer/fundingAccounts/byIdReducer';
import isSubmittingReducer from 'reducers/partnershipsReducer/fundingAccounts/isSubmittingReducer';

const partnershipFundingAccountsReducer = combineReducers({
  addresses: partnershipAddressesReducer,
  allIds: allIdsReducer,
  banks: partnershipBankAccountsReducer,
  byId: byIdReducer,
  isSubmitting: isSubmittingReducer,
});

export default partnershipFundingAccountsReducer;

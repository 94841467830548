import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { clearLoginErrorState } from 'actions/auth';
import { formNamesAuthV2 } from 'constants/forms';
import { noop } from 'helpers/utility';
import {} from 'interfaces/global';
import { authLoginInitialValuesSelector, isSubmittingLoginSelector, loginErrorsSelector, } from 'selectors/authSelectors';
import EmailPasswordForm from './EmailPasswordForm';
import {} from './EmailPasswordForm.types';
const mapStateToProps = createStructuredSelector({
    errors: loginErrorsSelector,
    initialValues: authLoginInitialValuesSelector,
    isSubmitting: isSubmittingLoginSelector,
});
const mapDispatchToProps = {
    onClearLoginErrorState: clearLoginErrorState,
};
const EmailPasswordFormContainer = reduxForm({
    form: formNamesAuthV2.LOGIN,
    onSubmitFail: noop,
})(EmailPasswordForm);
const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));
export { mapDispatchToProps, mapStateToProps };
export default enhance(EmailPasswordFormContainer);

import { getModalReducerOpenAction } from 'store/redux';

import { MODAL_MATCH_PLATFORM_PARTNERSHIP_CLOSE, MODAL_MATCH_PLATFORM_PARTNERSHIP_OPEN } from 'types/modals';

export const initialState = {
  open: false,
  partnershipId: null,
};

// Reducer
const matchPlatformPartnershipReducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_MATCH_PLATFORM_PARTNERSHIP_CLOSE:
      return initialState;

    case MODAL_MATCH_PLATFORM_PARTNERSHIP_OPEN:
      return getModalReducerOpenAction(state, action);

    default:
      return state;
  }
};

export default matchPlatformPartnershipReducer;

import { apiCall } from '@routable/framework';
import { baseTaxVendorDataModel, taxToolsApiListModel } from '../models';
export const url = 'taxes/vendors/';
export const taxToolsApi = {
    readAll: async ({ limit = 25, offset = 0, filters, sort = '', }) => apiCall({
        url,
        method: 'get',
        responseModel: taxToolsApiListModel,
        camelCaseResponse: true,
        queryParams: {
            offset: `${offset}`,
            limit: `${limit}`,
            ...(sort ? { sort } : {}),
            ...Object.fromEntries(Object.entries(filters || {}).filter(([, v]) => !!v)),
        },
    }),
    get: async (partnershipId) => apiCall({
        url: `taxes/vendors/${partnershipId}/`,
        method: 'get',
        responseModel: baseTaxVendorDataModel,
        camelCaseResponse: true,
    }),
};

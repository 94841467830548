import PropTypes from 'prop-types';
import React from 'react';

import { Modal } from 'components/modal';

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

import { OldVersionModeModalBody } from 'modules/maintenance/oldVersion/components';

const OldVersionModeModal = ({ modalState, onCloseModal }) => (
  <Modal
    body={<OldVersionModeModalBody />}
    hasInlineFooter
    modalHeader={`A new version of ${PLATFORM_DISPLAY_SHORT_NAME} was released`}
    modalState={modalState.open}
    onCloseModal={onCloseModal}
    overrideStyle={{ content: { width: 600 } }}
    testId="old-version-mode-modal"
  />
);

OldVersionModeModal.propTypes = {
  modalState: PropTypes.shape().isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default OldVersionModeModal;

import PropTypes from 'prop-types';
import React from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input/min';

import CountryFlag from 'components/CountryFlag';

import { typography } from 'constants/styles';

import Text from '../Text';

import { componentHelpers } from './helpers';

import './StaticPhoneNumberText.scss';

/**
 * Displays a flag and phone number string with country code in international format.
 * @example
 * // returns Text.Regular component with fl-us +1 555 222 3333
 * <StaticPhoneNumberText phoneNumber={{ country: 'US', number: '+15552223333' }} />
 * @param {ComponentProps} props
 * @param {function} props.formatter
 * @param {boolean} props.hasFlag
 * @param {PhoneNumber} props.phoneNumber
 * @param {RestOfProps} rest
 * @returns {StatelessComponent}
 */
const StaticPhoneNumberText = ({ formatter, hasFlag, phoneNumber, ...rest }) => {
  const { country, number } = phoneNumber;

  return (
    <div className={componentHelpers.getClassNamesForWrapper(rest)}>
      {hasFlag && (
        <CountryFlag
          alt={`${country} flag icon`}
          className={componentHelpers.getClassNamesForFlag(rest)}
          countryCode={country}
        />
      )}
      <Text.Regular dataFullStory size={typography.TextSize.LEVEL_100} {...rest}>
        {formatter(number)}
      </Text.Regular>
    </div>
  );
};

StaticPhoneNumberText.propTypes = {
  formatter: PropTypes.func,
  hasFlag: PropTypes.bool,
  phoneNumber: PropTypes.shape({
    country: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
  }).isRequired,
};

StaticPhoneNumberText.defaultProps = {
  formatter: formatPhoneNumberIntl,
  hasFlag: true,
};

export default StaticPhoneNumberText;

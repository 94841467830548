import { submit } from 'redux-form';

import { formNamesFunding } from 'constants/forms';

import * as types from 'types/connectBank';

// Bank manual
export const closeConnectBankManualModal = () => ({
  type: types.MODAL_CONNECT_BANK_MANUAL_CLOSE,
});
export const openConnectBankManualModal = () => ({
  type: types.MODAL_CONNECT_BANK_MANUAL_OPEN,
});
export const submitConnectBankManualForm = () => submit(formNamesFunding.ADD_MANUAL_BANK);

// Bank token
export const closeConnectBankTokenModal = () => ({
  type: types.MODAL_CONNECT_BANK_TOKEN_CLOSE,
});
export const openConnectBankTokenModal = (payload) => ({
  payload,
  type: types.MODAL_CONNECT_BANK_TOKEN_OPEN,
});
export const updateConnectBankTokenModal = (payload) => ({
  payload,
  type: types.MODAL_CONNECT_BANK_TOKEN_UPDATE,
});

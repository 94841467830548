import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { itemFromQuerySelector } from 'queries/itemRouterSelectors';
import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

import PartnerCompanyAcceptPayment from './PartnerCompanyAcceptPayment';

const mapStateToProps = createStructuredSelector({
  item: itemFromQuerySelector,
  partnership: partnershipFromQuerySelector,
});

export { mapStateToProps };
export default connect(mapStateToProps)(PartnerCompanyAcceptPayment);

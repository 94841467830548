import { call, put } from 'redux-saga/effects';

import { handleRequestErrors } from 'actions/errors';
import { fetchCompanyTagsRoutine } from 'actions/routines/tags';

import { parseCaughtError, parseErrorResponse } from 'helpers/errors';

import * as api from './api';

/**
 * Request all tags for the company of the logged in user
 * @return {IterableIterator<*>}
 */
function* fetchCompanyTags() {
  let errorData = {};

  try {
    const response = yield call(api.fetchCompanyTags);

    if (response.ok) {
      const { tag } = response.data;

      yield put(fetchCompanyTagsRoutine.success({ tag }));
      return yield response;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  return yield put(handleRequestErrors(fetchCompanyTagsRoutine.failure, errorData));
}

export default fetchCompanyTags;

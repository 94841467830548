import { routableApi, RoutableTags } from './routableApi';
const extendedRoutesApi = routableApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getRoute: builder.query({
            query: (queryParams) => ({
                url: '/routes/',
                method: 'GET',
                params: queryParams,
                transformers: [],
            }),
            providesTags: [RoutableTags.Routes],
        }),
    }),
});
export const { useGetRouteQuery } = extendedRoutesApi;

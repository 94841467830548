import { statusVariants } from '@routable/gross-ds';
import { ItemStatuses } from 'enums/items';
import {} from 'interfaces/item';
export const getStatusToConsider = (item) => item?.lockState ? ItemStatuses.QUEUED : item?.status;
export const itemStatusToVariant = {
    [ItemStatuses.BULK_UPLOAD]: statusVariants.pending,
    [ItemStatuses.CANCELED]: statusVariants.error,
    cancelled: statusVariants.error,
    [ItemStatuses.COMPLETED]: statusVariants.success,
    [ItemStatuses.COMPLIANCE_HOLD]: statusVariants.default,
    [ItemStatuses.CREATED]: statusVariants.warning,
    [ItemStatuses.EXTERNALLY_PAID]: statusVariants.success,
    [ItemStatuses.FAILED]: statusVariants.error,
    [ItemStatuses.IMPORT]: statusVariants.pending,
    [ItemStatuses.INITIATED]: statusVariants.pending,
    [ItemStatuses.ISSUE]: statusVariants.error,
    [ItemStatuses.NEEDS_APPROVAL]: statusVariants.warning,
    [ItemStatuses.NEW]: statusVariants.warning,
    [ItemStatuses.PENDING]: statusVariants.warning,
    [ItemStatuses.PO_DISCREPANCY]: statusVariants.warning,
    [ItemStatuses.PROCESSING]: statusVariants.pending,
    [ItemStatuses.SCHEDULED]: statusVariants.scheduled,
    [ItemStatuses.READY_TO_SEND]: statusVariants.pending,
    [ItemStatuses.QUEUED]: statusVariants.scheduled,
};
export const itemStatusToText = {
    [ItemStatuses.BULK_UPLOAD]: 'Bulk upload',
    [ItemStatuses.CANCELED]: 'Canceled',
    cancelled: 'Canceled',
    [ItemStatuses.COMPLETED]: 'Completed',
    [ItemStatuses.COMPLIANCE_HOLD]: 'Compliance hold',
    [ItemStatuses.EXTERNALLY_PAID]: 'Completed',
    [ItemStatuses.FAILED]: 'Failed',
    [ItemStatuses.IMPORT]: 'Import',
    [ItemStatuses.INITIATED]: 'Initiated',
    [ItemStatuses.ISSUE]: 'Issue found',
    [ItemStatuses.NEEDS_APPROVAL]: 'Needs approval',
    [ItemStatuses.NEW]: 'Pending',
    [ItemStatuses.PENDING]: 'Pending',
    [ItemStatuses.PO_DISCREPANCY]: 'PO discrepancy',
    [ItemStatuses.PROCESSING]: 'Processing',
    [ItemStatuses.SCHEDULED]: 'Scheduled',
    [ItemStatuses.READY_TO_SEND]: 'Ready to send',
    [ItemStatuses.QUEUED]: 'Queued',
};
export const getItemStatusProps = (item) => {
    const status = getStatusToConsider(item);
    return {
        status,
        statusVariant: itemStatusToVariant[status],
        statusText: itemStatusToText[status],
    };
};

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const ToolbarDivider = ({ className }) => (
  <div
    className={classNames({
      'toolbar--divider': true,
      [className]: !!className,
    })}
  >
    <div className="toolbar--divider-inner" />
  </div>
);

ToolbarDivider.propTypes = {
  className: PropTypes.string,
};

ToolbarDivider.defaultProps = {
  className: undefined,
};

export default ToolbarDivider;

import { combineReducers } from 'redux';

import allPlatformPartnershipsReducer from './allPlatformPartnershipsReducer';
import errorReducer from './errorReducer';
import fetchReducer from './fetchReducer';
import lastFetchedReducer from './lastFetchedReducer';
import lastSubmittedReducer from './lastSubmittedReducer';
import paginationReducer from './paginationReducer';
import platformPartnershipsByIdReducer from './platformPartnershipsByIdReducer';
import submitReducer from './submitReducer';
import unmatchedCounterReducer from './unmatchedCounterReducer';

const platformPartnershipsReducer = combineReducers({
  allIds: allPlatformPartnershipsReducer,
  byId: platformPartnershipsByIdReducer,
  errors: errorReducer,
  isFetching: fetchReducer,
  lastFetched: lastFetchedReducer,
  isSubmitting: submitReducer,
  lastSubmitted: lastSubmittedReducer,
  pagination: paginationReducer,
  unmatchedCounter: unmatchedCounterReducer,
});

export default platformPartnershipsReducer;

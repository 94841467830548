import { useEventSync } from '@routable/framework';
import { useEffect, useState } from 'react';
import {} from '../table.extended.types';
import { useTableStore } from '../table.store';
export const useTableData = ({ tableName }) => {
    const [tableData, setTableData] = useState([]);
    const { getTableStateItem } = useTableStore(tableName);
    useEventSync(`table:${tableName}:data`, (result) => {
        setTableData(result || []);
    });
    useEffect(() => {
        setTableData(getTableStateItem('cache') || []);
    }, []);
    return tableData;
};

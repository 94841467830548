import PropTypes from 'prop-types';
import React from 'react';

import BaseHint from 'components/hintTypes/BaseHint';
import { text } from 'components/hintTypes/constants';
import { WhiteSpace } from 'components/text';
import TextLinkNewInternalPage from 'components/TextLinkNewInternalPage';

import { Intent } from 'constants/ui';

/**
 * A warning hint which shows if deactivating a team member who is an approver would result in "stuck" items. We get
 * the warning message from the failed BE request for cancellation.
 * @param {ComponentProps} props
 * @param {StringMaybe} [props.className]
 * @param {function} props.onClick
 * @param {string} props.serverErrorText
 * @returns {StatelessComponent}
 */
const DeactivateTeamMemberApproverWarningHint = ({ className, onClick, serverErrorText }) => (
  <BaseHint
    className={className}
    intent={Intent.WARNING}
    multiline
    text={
      <>
        {serverErrorText}
        <WhiteSpace />
        <TextLinkNewInternalPage onClick={onClick}>
          {text.deactivateTeamMemberApproverWarningHint.linkText}
        </TextLinkNewInternalPage>
      </>
    }
    title={text.deactivateTeamMemberApproverWarningHint.title}
  />
);

DeactivateTeamMemberApproverWarningHint.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  serverErrorText: PropTypes.string.isRequired,
};

DeactivateTeamMemberApproverWarningHint.defaultProps = {
  className: undefined,
};

export default DeactivateTeamMemberApproverWarningHint;

import { createSelector } from 'reselect';

/**
 * Top level memberships selector
 * @param state
 * @return {obj}
 */
export const getCurrentUserSelector = (state) => state.currentUser;

/**
 * Selects the current user object from the state
 * @param {object} state - Redux state
 */
export const currentUserSelector = createSelector([getCurrentUserSelector], (currentUser) => currentUser.user);

/**
 * Selects the ID from the current user object in the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {string}
 */
export const currentUserEmailSelector = createSelector(
  [currentUserSelector],
  (currentUserData) => currentUserData?.email,
);

/**
 * Selects the ID from the current user object in the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Id}
 */
export const currentUserIdSelector = createSelector([currentUserSelector], (currentUserData) => currentUserData?.id);

/**
 * Selects the current user object from the state
 * @param {object} state - Redux state
 */
export const currentUserFirstNameSelector = createSelector([currentUserSelector], (user) => user?.firstName);

/**
 * Selects whether the current user has verified their e-mail
 * @param {object} state - Redux state
 */
export const currentUserHasVerifiedEmail = createSelector([currentUserSelector], (user) => user?.isEmailVerified);

/**
 * Selects the ssoOnly bool for current user from the state
 * @param {object} state - Redux state
 */
export const ssoOnlyCurrentUserSelector = createSelector(
  [getCurrentUserSelector],
  (currentUser) => currentUser.ssoOnly,
);

/**
 * Selects the isFetching bool for current user from the state
 * @param {object} state - Redux state
 */
export const isFetchingCurrentUserSelector = createSelector(
  [getCurrentUserSelector],
  (currentUser) => currentUser.isFetching,
);

/**
 * Selects the isUpdating bool for current user from the state
 * @param {object} state - Redux state
 */
export const isUpdatingCurrentUserSelector = createSelector(
  [getCurrentUserSelector],
  (currentUser) => currentUser.isUpdating,
);

/**
 * Selects the last updated date for current user from the state
 * @param {object} state - Redux state
 */
export const lastUpdatedCurrentUserSelector = createSelector(
  [getCurrentUserSelector],
  (currentUser) => currentUser.lastUpdated,
);

/**
 * Selects the current user errors from the state
 * @param {object} state - Redux state
 */
export const currentUserErrorsSelector = (state) => state.currentUser.errors;

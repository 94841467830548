import PropTypes from 'prop-types';
import React from 'react';

import { Tag } from 'components/tag';

import { DocumentStatuses } from 'constants/document';
import { TagType } from 'constants/ui';

const DocumentStatus = ({ documentStatus }) => {
  let text = 'Error';
  let type = TagType.ERROR;

  switch (documentStatus) {
    case DocumentStatuses.PENDING:
      text = 'Pending';
      type = TagType.WARNING;
      break;

    case DocumentStatuses.VERIFIED:
      text = 'Verified';
      type = TagType.SUCCESS;
      break;

    case DocumentStatuses.REJECTED:
      text = 'Rejected';
      type = TagType.ERROR;
      break;

    default:
      break;
  }

  return (
    <Tag hasIcon={false} isClosable={false} readOnly type={type}>
      {text}
    </Tag>
  );
};

DocumentStatus.propTypes = {
  documentStatus: PropTypes.string.isRequired,
};

export default DocumentStatus;

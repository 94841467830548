import PropTypes from 'prop-types';
import React from 'react';

import { requiredValidator } from 'helpers/fieldValidation';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import EditButtonInput from '../EditButtonInput';

/**
 * Input using the EditButtonInput component to show/edit the Legal Business Name. The edit button click dispatches
 * a standardized type.
 * @param props
 * @returns {StatelessComponent}
 * @constructor
 */
const LegalBusinessNameInput = ({ onEdit, ...rest }) => (
  <EditButtonInput {...rest} onEdit={onEdit} placeholder="Legal Business Name" validate={[requiredValidator]} />
);

LegalBusinessNameInput.propTypes = {
  onEdit: PropTypes.func.isRequired,
};

export default LegalBusinessNameInput;

import { createIsFetchingReducer } from 'store/redux';

import {
  FETCH_AFFILIATE_CONNECTIONS_FAILURE,
  FETCH_AFFILIATE_CONNECTIONS_REQUEST,
  FETCH_AFFILIATE_CONNECTIONS_SUCCESS,
} from 'types/affiliates';

export const finishCases = [FETCH_AFFILIATE_CONNECTIONS_FAILURE, FETCH_AFFILIATE_CONNECTIONS_SUCCESS];
export const requestCases = [FETCH_AFFILIATE_CONNECTIONS_REQUEST];

const isFetchingReducer = createIsFetchingReducer(finishCases, requestCases);

export default isFetchingReducer;

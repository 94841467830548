import { useEffect } from 'react';
import { useLazyGetPartnershipPaymentOptionsQuery } from 'ducks/routableApi/paymentEndpoints';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import { partnershipPaymentCurrentPartnershipSelector } from 'selectors/partnershipPaymentSelector';
export const usePartnershipPaymentCurrencies = (partnershipId) => {
    const data = useSelectorWithProps(partnershipPaymentCurrentPartnershipSelector, partnershipId);
    const [triggerGetPartnershipPayment, { isFetching, isLoading }] = useLazyGetPartnershipPaymentOptionsQuery();
    useEffect(() => {
        if (partnershipId && !isLoading) {
            triggerGetPartnershipPayment({ partnershipId });
        }
    }, [isLoading, partnershipId, triggerGetPartnershipPayment]);
    return { data, isFetching };
};

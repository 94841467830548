import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const HeadingSubtitle = ({ children, className, style }) => (
  <p
    className={classNames({
      'font-color--greyXDark': true,
      'font-xs': true,
      'remove-margin-bottom': true,
      [className]: !!className,
    })}
    style={style}
  >
    {children}
  </p>
);

HeadingSubtitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

HeadingSubtitle.defaultProps = {
  children: undefined,
  className: undefined,
  style: {},
};

export default HeadingSubtitle;

import { FetchService } from 'services';
import {
  changePasswordEndpoint,
  loginEndpoint,
  logoutEndpoint,
  resetPasswordEndpoint,
} from 'services/api/authEndpoints';
import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { convertToRegisteredEndpoint } from 'services/api/signupEndpoints';
import { getAcceptMembershipInviteEndpoint } from 'services/api/teamEndpoints';

/**
 * Accept membership invite API.
 * @param {string} companyId
 * @param {string} membershipInviteId
 * @param {string} membershipInviteToken
 * @param {Object} data
 * @param {ObjectMaybe} companySSOSettings
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const acceptMembershipInvite = async (
  companyId,
  membershipInviteId,
  membershipInviteToken,
  data,
  companySSOSettings,
  options = {},
) => {
  const payload = payloadToJSONAPI(data, 'MembershipInvite');
  const endpoint = getAcceptMembershipInviteEndpoint({
    companyId,
    companySSOSettings,
    data,
    inviteId: membershipInviteId,
    token: membershipInviteToken,
  });

  const config = {
    ...options,
    endpoint,
    method: 'POST',
    payload,
    requireAuth: false,
  };
  return FetchService.request(config);
};

/**
 * Change password API.
 * @param {string} userId
 * @param {Object} data
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const changePassword = async (userId, data, options = {}) => {
  const payload = payloadToJSONAPI(data, 'user');
  const { removeAuthToken = false, ...rest } = options;
  const config = {
    ...rest,
    endpoint: changePasswordEndpoint(userId),
    method: 'POST',
    payload,
    removeAuthToken,
  };
  return FetchService.request(config);
};

/**
 * Convert external user to registered user API.
 * @param {Object} data
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const convertExternalToRegistered = async (data, options = {}) => {
  const payload = payloadToJSONAPI(data, 'membership', 'user', 'company');
  const config = {
    ...options,
    endpoint: convertToRegisteredEndpoint,
    method: 'POST',
    payload,
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * User login API.
 * @param {Object} data
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const login = async (data, options = {}) => {
  const payload = payloadToJSONAPI(data, 'user');
  const config = {
    ...options,
    endpoint: loginEndpoint,
    method: 'POST',
    payload,
    removeAuthToken: true,
    requireAuth: false,
  };
  return FetchService.request(config);
};

/**
 * Logout API.
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const logout = async (options = {}) => {
  const config = {
    ...options,
    endpoint: logoutEndpoint,
    method: 'POST',
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * Reset password API.
 * @param {Object} data
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const resetPassword = async (data, options = {}) => {
  const payload = payloadToJSONAPI(data, 'user');
  const config = {
    ...options,
    endpoint: resetPasswordEndpoint,
    method: 'POST',
    payload,
    removeAuthToken: true,
  };
  return FetchService.request(config);
};

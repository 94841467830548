import { breakpointNames } from 'constants/mediaQuery';

export const balanceAmountRowBreakpointProps = {
  [breakpointNames.phone]: {
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  [breakpointNames.tablet]: {
    cols: 3,
    flexDirection: 'row',
    flexWrap: 'no-wrap',
    justifyContent: 'space-between',
  },
};

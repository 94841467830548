import { PaymentDeliveryMethodType, PaymentDeliveryOptionType } from '@routable/shared';
import _reduce from 'lodash/reduce';
import { ExternallyPaidPaymentMethods, ItemPaymentDeliveryOptionText, ItemPaymentMethodsText } from 'constants/item';
import { PaymentSources } from 'enums/billing';
import { ItemKinds } from 'enums/items';
import { getBillingDataForPaymentDeliveryOption, formatting } from 'helpers/billing';
import { hasItemExternallyPaidMethod, isItemKindReceivable, isItemPaymentDeliveryMethodAchOrInternational, isItemPaymentDeliveryMethodCheck, isKindPayable, } from 'helpers/items';
import { capitalize } from 'helpers/stringHelpers';
export const getExternallyPaidPaymentMethods = () => Object.values(ExternallyPaidPaymentMethods).map((option) => ({
    id: option,
    value: option,
    text: capitalize(ItemPaymentMethodsText[option]),
}));
export const getPaymentMethodIconClass = (item) => {
    if (isItemPaymentDeliveryMethodAchOrInternational(item)) {
        return 'icon-ic-bank';
    }
    if (isItemPaymentDeliveryMethodCheck(item)) {
        return 'icon-ic-check';
    }
    if (hasItemExternallyPaidMethod(item)) {
        return 'icon-ic-edit';
    }
    return null;
};
export const isPaymentDeliveryMethodAch = (paymentDeliveryMethod) => paymentDeliveryMethod === PaymentDeliveryMethodType.ACH;
export const isPaymentDeliveryMethodInternational = (paymentDeliveryMethod) => paymentDeliveryMethod === PaymentDeliveryMethodType.INTERNATIONAL;
export const isPaymentDeliveryMethodAchOrInternational = (paymentDeliveryMethod) => isPaymentDeliveryMethodAch(paymentDeliveryMethod) || isPaymentDeliveryMethodInternational(paymentDeliveryMethod);
export const isPaymentDeliveryMethodAny = (paymentDeliveryMethod) => paymentDeliveryMethod === PaymentDeliveryMethodType.ANY;
export const isPaymentMethodDeliveryCheck = (paymentDeliveryMethod) => paymentDeliveryMethod === PaymentDeliveryMethodType.CHECK;
export const isPaymentDeliveryMethodCheckOrACH = (paymentDeliveryMethod) => isPaymentDeliveryMethodAch(paymentDeliveryMethod) || isPaymentMethodDeliveryCheck(paymentDeliveryMethod);
export const isPaymentDeliveryOptionAchSameDay = (paymentDeliveryOption) => paymentDeliveryOption === PaymentDeliveryOptionType.ACH_SAME_DAY;
export const isPaymentDeliveryOptionAchNextDay = (paymentDeliveryOption) => paymentDeliveryOption === PaymentDeliveryOptionType.ACH_NEXT_DAY;
export const isPaymentDeliveryOptionRTP = (paymentDeliveryOption) => paymentDeliveryOption === PaymentDeliveryOptionType.RTP;
export const isPaymentDeliveryOptionSpeedSameOrNextDay = (optionSpeed) => {
    if (!optionSpeed) {
        return false;
    }
    return Boolean(optionSpeed.earliest <= 1 && optionSpeed.earliest === optionSpeed.latest);
};
export const getAvailablePaymentMethods = (paymentMethodOptions, isInInternationalContext = false) => {
    let targetOptions = [...paymentMethodOptions];
    if (isInInternationalContext) {
        targetOptions = targetOptions.filter((paymentDeliveryMethod) => !isPaymentDeliveryMethodCheckOrACH(paymentDeliveryMethod));
    }
    else {
        targetOptions = targetOptions.filter((paymentDeliveryMethod) => !isPaymentDeliveryMethodInternational(paymentDeliveryMethod));
    }
    return targetOptions.map((option) => ({
        id: option,
        value: option,
        text: capitalize(ItemPaymentMethodsText[option]),
    }));
};
export const isPaymentDeliveryMethodAvailable = (availablePaymentDeliveryMethods, paymentDeliveryMethod) => {
    if (!availablePaymentDeliveryMethods || !paymentDeliveryMethod) {
        return false;
    }
    return availablePaymentDeliveryMethods.includes(paymentDeliveryMethod);
};
export const isPaymentMethodAvailableACH = (availablePaymentMethods) => isPaymentDeliveryMethodAvailable(availablePaymentMethods, PaymentDeliveryMethodType.ACH);
export const isPaymentMethodAvailableInternational = (availablePaymentMethods) => isPaymentDeliveryMethodAvailable(availablePaymentMethods, PaymentDeliveryMethodType.INTERNATIONAL);
export const isPaymentMethodAvailableCheck = (availablePaymentMethods) => isPaymentDeliveryMethodAvailable(availablePaymentMethods, PaymentDeliveryMethodType.CHECK);
export const filterPaymentDeliveryOptions = (availablePaymentMethods = [], removedPaymentDeliveryOption) => availablePaymentMethods.filter((method) => method !== removedPaymentDeliveryOption);
export const areMultiplePaymentMethodsAvailable = (availablePaymentMethods) => availablePaymentMethods.length > 1;
export const isPaymentDeliveryOptionAvailable = (availablePaymentDeliveryMethods, paymentDeliveryOption, billingDataByCode, itemKind) => {
    const billingData = getBillingDataForPaymentDeliveryOption({
        billingDataByCode,
        paymentDeliveryOption,
        kind: itemKind,
    });
    if (!billingData) {
        return false;
    }
    return isPaymentDeliveryMethodAvailable(availablePaymentDeliveryMethods, billingData.paymentDeliveryMethod);
};
export const getCheckArrivalDateRange = (item) => {
    switch (item.paymentDeliveryOption) {
        case PaymentDeliveryOptionType.CHECK_EXPEDITED:
            return '1-2 business days';
        case PaymentDeliveryOptionType.CHECK_INTERNATIONAL:
            return '10-14 business days';
        default:
            return '7-10 business days';
    }
};
export const getAvailablePaymentDeliveryOptionsForItem = (item, billingCodeData, paymentDeliveryOptions) => {
    const billingCodes = _reduce(billingCodeData, (arr, codeData) => {
        const paymentKindMatch = codeData.paymentKind === item.kind;
        const paymentOptionExist = paymentDeliveryOptions.find((option) => option === codeData.paymentDeliveryOption);
        if (paymentKindMatch && paymentOptionExist) {
            return [...arr, codeData];
        }
        return arr;
    }, []);
    return billingCodes;
};
const getFilteredDeliveryOptions = (item, billingCodeData, paymentDeliveryOptions) => {
    const optionsByBillingData = getAvailablePaymentDeliveryOptionsForItem(item, billingCodeData, paymentDeliveryOptions);
    const filteredOptions = optionsByBillingData.reduce((filteredOptionsArray, currentOption) => {
        const billingCodeOptionExists = paymentDeliveryOptions.find((option) => option === currentOption.paymentDeliveryOption);
        const currentOptionExistsOnFilteredOptions = filteredOptionsArray.find((option) => option.paymentDeliveryOption === currentOption.paymentDeliveryOption);
        if (billingCodeOptionExists && !currentOptionExistsOnFilteredOptions) {
            const newOption = {
                ...currentOption,
                title: formatting.getBillingCodePaymentDeliveryOptionText(currentOption),
            };
            return [...filteredOptionsArray, newOption];
        }
        return filteredOptionsArray;
    }, []);
    return filteredOptions;
};
export const getFilteredCodesByPaymentSource = ({ allBillingCodes, partnerPaymentOptions, paymentDeliveryMethodSelected, paymentFundingSourceSelected, }) => {
    const source = paymentFundingSourceSelected?.balance ? PaymentSources.BALANCE : PaymentSources.BANK;
    if (!partnerPaymentOptions || !paymentDeliveryMethodSelected) {
        return [];
    }
    const { options } = partnerPaymentOptions.find(({ method }) => method === paymentDeliveryMethodSelected);
    return options.reduce((filteredBillingCodes, currentOption) => {
        const foundCodes = allBillingCodes.filter(({ paymentKind, paymentDeliveryMethod, paymentDeliveryOption }) => paymentKind === ItemKinds.PAYABLE &&
            paymentDeliveryMethodSelected === paymentDeliveryMethod &&
            paymentDeliveryOption === currentOption);
        if (foundCodes.length === 0) {
            return filteredBillingCodes;
        }
        if (foundCodes.length > 1) {
            return [...filteredBillingCodes, foundCodes.find(({ paymentSource }) => paymentSource === source)];
        }
        return [...filteredBillingCodes, foundCodes[0]];
    }, []);
};
export const getAvailablePaymentDeliveryOptions = (item, billingCodeData, partnerPaymentOptions) => {
    const paymentDeliveryOptions = partnerPaymentOptions?.find((option) => option.method === item.paymentDeliveryMethod)?.options;
    if (!paymentDeliveryOptions?.length) {
        return [];
    }
    const shouldFilterOptions = isPaymentDeliveryMethodInternational(item.paymentDeliveryMethod);
    if (shouldFilterOptions) {
        return getFilteredDeliveryOptions(item, billingCodeData, paymentDeliveryOptions);
    }
    return getAvailablePaymentDeliveryOptionsForItem(item, billingCodeData, paymentDeliveryOptions);
};
export const getPaymentDeliveryOptions = ({ item, billingCodeData, partnerPaymentOptions, paymentFundingSourceSelected, }) => {
    const shouldFilterCodesByPaymentSource = isKindPayable(item.kind) && !isPaymentDeliveryMethodInternational(item.paymentDeliveryMethod);
    if (shouldFilterCodesByPaymentSource) {
        return getFilteredCodesByPaymentSource({
            allBillingCodes: Object.values(billingCodeData),
            partnerPaymentOptions,
            paymentDeliveryMethodSelected: item.paymentDeliveryMethod,
            paymentFundingSourceSelected,
        });
    }
    return getAvailablePaymentDeliveryOptions(item, billingCodeData, partnerPaymentOptions);
};
export const getFallbackDeliveryOptionForPaymentMethod = ({ billingDataByCode, itemKind, paymentMethod, }) => {
    if (isKindPayable(itemKind)) {
        if (isPaymentDeliveryMethodAch(paymentMethod)) {
            const billingData = getBillingDataForPaymentDeliveryOption({
                billingDataByCode,
                paymentDeliveryOption: PaymentDeliveryOptionType.ACH_EXPEDITED,
                kind: itemKind,
            });
            if (billingData && +billingData.paymentDeliveryOptionMax > 0) {
                return PaymentDeliveryOptionType.ACH_EXPEDITED;
            }
            return PaymentDeliveryOptionType.ACH_STANDARD;
        }
        if (isPaymentMethodDeliveryCheck(paymentMethod)) {
            return PaymentDeliveryOptionType.CHECK_STANDARD;
        }
        if (isPaymentDeliveryMethodInternational(paymentMethod)) {
            return PaymentDeliveryOptionType.INTERNATIONAL_SWIFT;
        }
    }
    if (isPaymentDeliveryMethodAch(paymentMethod)) {
        return PaymentDeliveryOptionType.ACH_STANDARD;
    }
    return null;
};
export const getPaymentDeliveryMethodInitialValueForItemEdit = (item) => isItemKindReceivable(item)
    ? PaymentDeliveryMethodType.ACH
    :
        item.paymentDeliveryMethod || PaymentDeliveryMethodType.ANY;
export const getPaymentMethodOptionsInitialValue = ({ isInternational = false, methodsAccepted = [], }) => {
    const paymentMethods = [PaymentDeliveryMethodType.ANY];
    if (methodsAccepted.includes(PaymentDeliveryMethodType.INTERNATIONAL) && isInternational) {
        paymentMethods.push(PaymentDeliveryMethodType.INTERNATIONAL);
    }
    if (methodsAccepted.includes(PaymentDeliveryMethodType.ACH) && !isInternational) {
        paymentMethods.push(PaymentDeliveryMethodType.ACH);
    }
    if (methodsAccepted.includes(PaymentDeliveryMethodType.CHECK) && !isInternational) {
        paymentMethods.push(PaymentDeliveryMethodType.CHECK);
    }
    return paymentMethods;
};
export const getPaymentDeliveryMethodsAcceptedInitialValueForItemEdit = ({ isInternational = false, methodsAccepted, }) => ({
    [PaymentDeliveryMethodType.ACH]: isPaymentMethodAvailableACH(methodsAccepted) && !isInternational,
    [PaymentDeliveryMethodType.CHECK]: isPaymentMethodAvailableCheck(methodsAccepted) && !isInternational,
    [PaymentDeliveryMethodType.INTERNATIONAL]: isPaymentMethodAvailableInternational(methodsAccepted) && isInternational,
});
export const getPaymentDeliveryOptionLockedTooltipText = (item, currencyCodeReceiver, companyName) => {
    const companyNameCopy = companyName ? `to ${companyName} ` : '';
    return (`Bank transfers in ${currencyCodeReceiver} ${companyNameCopy}` +
        `can only be sent via ${ItemPaymentDeliveryOptionText[item.paymentDeliveryOption]}.`);
};
export const getPartnerPaymentOptionsByCountryAndCurrencyCode = (supportedCountries, selectedInvoices) => {
    let paymentOptions = [];
    selectedInvoices.forEach(({ currencyCode, countryCode }) => {
        if (supportedCountries[countryCode]) {
            const country = supportedCountries[countryCode];
            const options = country.paymentOptions[currencyCode.toLowerCase()]?.[0];
            if (options) {
                paymentOptions = paymentOptions.concat(options);
            }
        }
    });
    return Array.from(new Set(paymentOptions));
};

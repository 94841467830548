import PropTypes from 'prop-types';
import React from 'react';

import { TextLinkNewInternalPage } from 'components';

import { typography } from 'constants/styles';

import { withChatOnClick } from 'hoc';

/**
 * Component for displaying "contact support" message within profile hints
 * @param {ComponentProps} props
 * @param {Node} props.children
 * @param {Function} props.onClick
 * @returns {StatelessComponent}
 */
export const ContactSupportLinkHelper = ({ children, onClick }) => (
  <TextLinkNewInternalPage onClick={onClick} size={typography.TextSize.LEVEL_300}>
    {children}
  </TextLinkNewInternalPage>
);

ContactSupportLinkHelper.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

const ContactSupportLink = withChatOnClick(ContactSupportLinkHelper);

export default ContactSupportLink;

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { FormControl } from 'components/form';
import { ReduxFormFieldRenderInput } from 'components/input';

import { countryListOptions } from 'constants/i18n';
import { field } from 'constants/styles/formStyles';

import { requiredValidator } from 'helpers/fieldValidation';

import { AutoComplete } from './components';
import handleStreetAddressFocus from './helpers/streetAddressFocusHelper';

/**
 * Collect street address information.
 * @param {string} addressPath
 * @param {string} countryOptions
 * @param {string} formName
 * @param {boolean} isDisabled
 * @returns {StatelessComponent}
 * @constructor
 */
const StreetAddressInputs = ({ addressPath, countryOptions, formName, isDisabled }) => {
  const autocomplete = React.useRef(undefined);

  return (
    <FormControl>
      <AutoComplete
        addressPath={addressPath}
        autocompleteRef={autocomplete}
        countryOptions={countryOptions}
        formName={formName}
      />

      <Field
        className={field.xl.twoThirds}
        component={ReduxFormFieldRenderInput}
        isDisabled={isDisabled}
        name={`${addressPath}streetAddress`}
        onStreetAddressFocus={handleStreetAddressFocus(autocomplete)}
        placeholder="Street address"
        type="text"
        validate={requiredValidator}
      />

      <Field
        className={`${field.xl.third} right`}
        component={ReduxFormFieldRenderInput}
        isDisabled={isDisabled}
        name={`${addressPath}streetAddressUnit`}
        placeholder="Apt, Building, Floor, Etc."
        type="text"
      />
    </FormControl>
  );
};

StreetAddressInputs.propTypes = {
  addressPath: PropTypes.string,
  countryOptions: PropTypes.oneOf(Object.values(countryListOptions)).isRequired,
  formName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

StreetAddressInputs.defaultProps = {
  addressPath: '',
  isDisabled: undefined,
};

export default StreetAddressInputs;

import { createIsSubmittingReducer } from 'store/redux';

import { SUBMIT_MESSAGE_FAILURE, SUBMIT_MESSAGE_REQUEST, SUBMIT_MESSAGE_SUCCESS } from 'types/messages';

export const finishCases = [SUBMIT_MESSAGE_FAILURE, SUBMIT_MESSAGE_SUCCESS];
export const requestCases = [SUBMIT_MESSAGE_REQUEST];

const submitReducer = createIsSubmittingReducer(finishCases, requestCases);

export default submitReducer;

import React from 'react';

import { colors } from 'constants/styles';

import { IsLoadingHasWrapper } from 'modules/isLoading';

import './ExternalLoadingIndicator.scss';

/**
 * Component rendering full page loading indicator when external
 * route is loading
 * @see ExternalRouteWrapper
 * @returns {StatelessComponent}
 */
const ExternalLoadingIndicator = () => (
  <IsLoadingHasWrapper className="external-loading-indicator" color={colors.colorBlackHex} />
);

export default ExternalLoadingIndicator;

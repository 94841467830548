import PropTypes from 'prop-types';
import React from 'react';

import { getClassNames } from 'helpers/ui';

import FlexCol from './FlexCol';

/**
 * Just a <FlexCol />, but adds the class to justify-content: center.
 * @param {ComponentProps} props
 * @param {*} children
 * @param {Object} rest
 * @return {StatelessComponent}
 * @constructor
 */
const VerticalCenterCol = ({ children, ...rest }) => (
  <FlexCol className={getClassNames(rest, { 'justify-content--center': true })}>{children}</FlexCol>
);

VerticalCenterCol.propTypes = {
  children: PropTypes.node,
};

VerticalCenterCol.defaultProps = {
  children: undefined,
};

export default VerticalCenterCol;

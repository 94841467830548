import { useCallback, useEffect, useRef, useState } from 'react';
import { RoutableEventsSync, useEventSync } from '@routable/framework';
export const useTablematicStickyScroll = () => {
    const headerContainerRef = useRef(null);
    const rowContainerRef = useRef(null);
    const [currentHeight, setCurrentHeight] = useState(0);
    const [innerWidth, setInnerWidth] = useState(0);
    const resetSize = useCallback(() => {
        if (headerContainerRef.current) {
            const newWidth = headerContainerRef.current.scrollWidth;
            if (newWidth) {
                setInnerWidth(newWidth);
            }
        }
        if (rowContainerRef.current) {
            const newHeight = rowContainerRef.current.scrollHeight;
            if (newHeight !== currentHeight) {
                RoutableEventsSync.Publish('tablematic:table:body:height', {
                    ref: rowContainerRef,
                    height: newHeight,
                });
                setCurrentHeight(newHeight);
            }
        }
    }, [headerContainerRef, rowContainerRef]);
    useEventSync('line:item:tab:click', () => {
        setTimeout(() => {
            resetSize();
        }, 300);
    });
    useEffect(() => {
        const mutationObserver = new MutationObserver(() => {
            resetSize();
        });
        if (rowContainerRef.current) {
            mutationObserver.observe(rowContainerRef.current, { childList: true, subtree: true });
        }
        return () => {
            if (rowContainerRef.current) {
                mutationObserver.disconnect();
            }
        };
    }, [rowContainerRef]);
    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            resetSize();
        });
        if (headerContainerRef.current) {
            resizeObserver.observe(headerContainerRef.current);
        }
        return () => {
            if (headerContainerRef.current) {
                resizeObserver.unobserve(headerContainerRef.current);
            }
            resizeObserver.disconnect();
        };
    }, [headerContainerRef]);
    return {
        currentHeight,
        headerContainerRef,
        innerWidth,
        resetSize,
        rowContainerRef,
        setCurrentHeight,
        setInnerWidth,
    };
};

import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';

import { fields } from 'constants/partnershipRequestState';
import { partnershipFundingStateRelationships } from 'constants/relationships';

import { createByIdReducer } from 'store/redux';

const byIdReducer = createByIdReducer({
  key: fields.FUNDING_ACCOUNT,
  relationships: partnershipFundingStateRelationships,
  types: [fetchExternalPartnershipRequestRoutine.SUCCESS],
  initialState: {},
});

export default byIdReducer;

import { isEqual } from 'helpers/utility';
import { PaymentMethodStatusType } from 'modules/fundingAccount/PaymentMethod/enums';
export const shouldHideActionButtons = (status) => isEqual(PaymentMethodStatusType.DISABLED, status);
export const shouldDisableActionButtonUpdate = (status, isSubmitting) => {
    const shouldDisableStatusMap = {
        [PaymentMethodStatusType.ERROR]: true,
        [PaymentMethodStatusType.PENDING_MICRO_DEPOSITS]: true,
        [PaymentMethodStatusType.PENDING_VERIFICATION]: true,
    };
    return isSubmitting || Boolean(shouldDisableStatusMap[status]);
};
export const shouldDisableActionButtonPrimary = (status, isSubmitting) => {
    const shouldDisableStatusMap = {
        [PaymentMethodStatusType.ERROR]: true,
        [PaymentMethodStatusType.PENDING_MICRO_DEPOSITS]: true,
        [PaymentMethodStatusType.PENDING_VERIFICATION]: true,
        [PaymentMethodStatusType.PRIMARY]: true,
    };
    return isSubmitting || Boolean(shouldDisableStatusMap[status]);
};

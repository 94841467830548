import React, { useState } from 'react';
import { useDispatchEventSync, useEventSync } from '@routable/framework';
import {} from '../../../types/global.types';
import { Container, Content, RecordCount } from './showmore.styled';
import {} from './showmore.types';
export const ShowMoreFooter = ({ tableName, defaultPageSize = 5 }) => {
    const dispatch = useDispatchEventSync();
    const [pagingData, setPagingData] = useState();
    const [showMoreButton, setShowMoreButton] = useState(false);
    const [moreCount, setMoreCount] = useState(0);
    const caculateMoreCount = (pageData) => {
        const currentPage = pageData.page || 1;
        const currentCount = currentPage * pageData.pageSize;
        if (currentCount > (pageData.total || 1)) {
            setShowMoreButton(false);
        }
        const returnCount = (pageData.total || 1) - currentCount;
        setMoreCount(returnCount);
        return returnCount;
    };
    const showMore = () => {
        if (pagingData) {
            if (pagingData.hasNextPage) {
                const nextPageSize = pagingData.pageSize + defaultPageSize;
                dispatch(`table:${tableName}:page:update`, {
                    pageSize: nextPageSize,
                });
            }
        }
    };
    useEventSync(`table:${tableName}:pagination`, (evPageData) => {
        if (evPageData) {
            if (evPageData.hasNextPage) {
                setShowMoreButton(true);
                setPagingData(evPageData);
                caculateMoreCount(evPageData);
            }
            else {
                setShowMoreButton(false);
                setMoreCount(0);
            }
        }
    });
    if (!showMoreButton) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(Container, { onClick: showMore, className: "table-footer-with-controls" },
        React.createElement(Content, null,
            React.createElement("span", null, "Show more "),
            React.createElement(RecordCount, null,
                React.createElement("span", { className: "show-more-record-count" }, moreCount)))));
};

import getRelationships from 'store/redux';

import { GET_CURRENT_COMPANY_SUCCESS } from 'types/currentCompany';
import { FETCH_MEMBERSHIP_SUCCESS } from 'types/memberships';

const convertExternalFormReducer = (state = undefined, action) => {
  switch (action.type) {
    case GET_CURRENT_COMPANY_SUCCESS: {
      if (!state?.values) {
        return state;
      }

      const { companyId } = action.meta;
      const { isTosAgree, name } = action.payload.company[companyId].attributes;

      return {
        ...state,
        values: {
          ...state.values,
          company: {
            ...state.values.company,
            id: companyId,
            name,
          },
          meta: {
            ...state.values.meta,
            tosAgree: isTosAgree,
          },
        },
      };
    }

    case FETCH_MEMBERSHIP_SUCCESS: {
      if (!state || !state.values) {
        return state;
      }

      const { meta, payload } = action;
      const { membership, user } = payload;
      const { membershipId } = meta;
      const { user: userId } = getRelationships(membership, membershipId, 'user');
      const { firstName, lastName } = user[userId].attributes;

      return {
        ...state,
        values: {
          ...state.values,
          user: {
            ...state.values.user,
            firstName,
            id: userId,
            lastName,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default convertExternalFormReducer;

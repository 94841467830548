import { getModalReducerOpenAction } from 'store/redux';

import * as types from 'types/apiToken';

export const initialState = {
  open: false,
};

const createApiTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MODAL_CREATE_API_TOKEN_CLOSE:
      return initialState;

    case types.MODAL_CREATE_API_TOKEN_OPEN:
      return getModalReducerOpenAction(state, action);

    default:
      return state;
  }
};

export default createApiTokenReducer;

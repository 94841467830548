import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import LinkNewPage from 'components/link/LinkNewPage';

const SecondaryActionLink = ({ children, className, href, toNewPage, ...rest }) =>
  React.createElement(
    toNewPage ? LinkNewPage : Link,
    {
      ...rest,
      className: classNames({
        'secondary-action-link': true,
        [className]: !!className,
      }),
      href,
      to: href,
    },
    children,
  );

SecondaryActionLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  toNewPage: PropTypes.bool,
};

SecondaryActionLink.defaultProps = {
  children: undefined,
  className: undefined,
  toNewPage: false,
};

export default SecondaryActionLink;

import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import Icon, { IconNames } from 'components/icon';
import { getIsFetchingStrength, getPasswordScore } from 'selectors/passwordInputSelectors';
import { availableRequirementValidators } from '../../constants/availableRequirementValidators';
import { passwordRequirements } from '../../helpers/passwordRequirements';
import {} from '../../types/PasswordInput.types';
import { iconByStrength, iconWhenFetching } from './constants/iconByStrength';
import { strengthScores, strengthTypes } from './constants/strengthScores';
import { getIconColor } from './helpers/iconColors';
import {} from './types/PasswordHints.types';
import './PasswordHints.module.scss';
export const IndividualHint = ({ formName, requirement, styling }) => {
    const formValues = useSelector(getFormValues(formName));
    const currentPassword = formValues.user?.password;
    const errors = passwordRequirements[requirement]?.validator(currentPassword);
    const isValid = !errors;
    return (React.createElement("div", { className: "individual-hint" },
        React.createElement("div", { className: "hint-icon-container" },
            React.createElement(Icon, { color: getIconColor(isValid, styling), dataTestId: "password-hint-icon", icon: isValid ? IconNames.SMALL_TICK : IconNames.SMALL_CROSS })),
        React.createElement("span", { className: classNames('hint-requirement', isValid ? 'valid' : 'invalid', styling) }, passwordRequirements[requirement].label)));
};
export const PasswordStrength = ({ styling }) => {
    const passwordScore = useSelector(getPasswordScore);
    const strength = strengthScores[passwordScore];
    const isFetching = useSelector(getIsFetchingStrength);
    const isValid = strength !== strengthTypes.WEAK;
    return (React.createElement("div", { className: classNames('password-strength-container', strength) },
        React.createElement("div", { className: "password-icon-span-container" },
            React.createElement("div", { className: classNames('password-strength-icon', styling) }, isFetching ? (iconWhenFetching) : (React.createElement(Icon, { color: getIconColor(isValid, styling), icon: iconByStrength[strength] }))),
            React.createElement("span", { className: classNames('password-strength', strength, styling) },
                "Password strength:",
                strength))));
};
const PasswordHints = ({ formName, requirementsAreMet, showStrength, styling }) => (React.createElement("div", { className: "password-hint-container" },
    showStrength && React.createElement(PasswordStrength, { styling: styling }),
    showStrength &&
        !requirementsAreMet &&
        Object.values(availableRequirementValidators).map((requirement, idx) => (React.createElement(IndividualHint, { formName: formName, key: `individualHint${idx.toString()}`, requirement: requirement, styling: styling })))));
export default PasswordHints;

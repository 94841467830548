import { CheckmarkFilled } from '@carbon/icons-react';
import { SectionMessage, Spinner } from '@routable/gross-ds';
import _isEmpty from 'lodash/isEmpty';
import React from 'react';
import { InfoList, Warnings } from './components';
export const InternationalBeneficiaryValidation = ({ data, errors, isLoading, warnings }) => {
    if (isLoading) {
        return (React.createElement("div", { className: "flex gap-4 items-center text-sm" },
            React.createElement(Spinner, { intent: "secondary", size: 2 }),
            "Verifying bank account details..."));
    }
    if (!_isEmpty(errors)) {
        return (React.createElement(React.Fragment, null,
            React.createElement(SectionMessage, { intent: "critical", title: "Please resolve these issues in order to add this bank account" },
                React.createElement(InfoList, { data: errors })),
            React.createElement(Warnings, { className: "mt-3", hasErrors: true, warnings: warnings })));
    }
    if (!_isEmpty(warnings)) {
        return React.createElement(Warnings, { warnings: warnings });
    }
    if (data) {
        return (React.createElement("div", { className: "flex items-center gap-3 text-sm" },
            React.createElement(CheckmarkFilled, { className: "text-green-40" }),
            "Bank account details successfully verified"));
    }
    return null;
};

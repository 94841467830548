import _omit from 'lodash/omit';
import { arrayPush, arraySplice, change, untouch } from 'redux-form';

import { formNamesSettings } from 'constants/forms';

import { noDefaultEvent } from 'helpers/events';
import { getBase64 } from 'helpers/fileHelpers';
import { getCurrentMembershipId } from 'helpers/localStorage';

import { storeAccessor as store } from 'store/accessor';

// *************************************
// General
// *************************************

/**
 * Adding ids to address and personal info to allow immediate re-submission
 * @param parsedResponse
 */
export const addIdsToFormRelationships = (parsedResponse) => {
  const addressId = Object.keys(parsedResponse.membershipAddress)[0];
  const personalInfoId = Object.keys(parsedResponse.personalInfo)[0];
  store.dispatch(change(formNamesSettings.USER_REPRESENTATIVE, 'personalInfo.id', personalInfoId));
  store.dispatch(change(formNamesSettings.USER_REPRESENTATIVE, 'address.id', addressId));
};

// *************************************
// Attachments
// *************************************

/**
 * Add files to item attachments
 * @param event - event
 * @return {Promise<void>}
 */
export const handleAddAttachment = async (event) => {
  noDefaultEvent(event);

  const { files } = event.target;
  if (!files || files.length === 0) {
    return;
  }

  // Iterate over given files and prep for form insertion
  const fileList = [];
  for (let i = 0; i < files.length; i += 1) {
    fileList.push(files[i]);
  }

  fileList.forEach(async (currentFile) => {
    store.dispatch(
      arrayPush(formNamesSettings.USER_REPRESENTATIVE, 'documentsOwned', {
        file: await getBase64(currentFile),
        filename: currentFile.name,
      }),
    );
  });
};

/**
 * Remove file from item attachment
 * @param {string} idx - numerical position in the array, or `all`
 */
export const handleRemoveAttachments = (idx = 'all') => {
  if (idx === 'all') {
    store.dispatch(change(formNamesSettings.USER_REPRESENTATIVE, 'documentsOwned', []));
    return;
  }
  store.dispatch(arraySplice(formNamesSettings.USER_REPRESENTATIVE, 'documentsOwned', idx, 1));
};

// *************************************
// Government ID
// *************************************

/**
 * Toggle SSN fields to show Passport ID field
 * @param {boolean} shouldShowSSNField
 */
export const handleToggleShowSSNField = (shouldShowSSNField) => {
  store.dispatch(change(formNamesSettings.USER_REPRESENTATIVE, 'ui.shouldShowSSNField', !shouldShowSSNField));
};

/**
 * Allow user to edit the SSN field
 * @param {boolean} shouldAllowSSNEdit
 */
export const handleAllowSSNEdit = (shouldAllowSSNEdit) => {
  store.dispatch(change(formNamesSettings.USER_REPRESENTATIVE, 'ui.shouldAllowSSNEdit', shouldAllowSSNEdit));
};

/**
 * Allow user to edit the Passport ID field
 * @param {boolean} shouldAllowPassportNumberEdit
 */
export const handleAllowPassportNumberEdit = (shouldAllowPassportNumberEdit) => {
  store.dispatch(
    change(formNamesSettings.USER_REPRESENTATIVE, 'ui.shouldAllowPassportNumberEdit', shouldAllowPassportNumberEdit),
  );
};

/**
 * Allow user to clear the government ID field when clicking the shouldShowSSNField checkbox
 * @param {object} formUI
 * @param {array} documentsOwned
 * @param {function} dispatch
 */
export const handleClearGovernmentID = (formUI, documentsOwned, dispatch) => {
  const formName = formNamesSettings.USER_REPRESENTATIVE;

  if (formUI.shouldShowSSNField) {
    // Clear passport information
    dispatch(change(formName, 'personalInfo.passportNumber', null));
    dispatch(change(formName, 'personalInfo.passportCountry', null));
    dispatch(untouch(formName, 'personalInfo.passportNumber', 'personalInfo.passportCountry'));
  } else {
    // Clear social security number
    dispatch(change(formName, 'personalInfo.ssn', null));
    dispatch(untouch(formName, 'personalInfo.ssn'));

    // Clear document type for each document to ensure no bad data is pushed up
    if (documentsOwned.length > 0) {
      const newDocumentsOwned = [];
      documentsOwned.forEach((document) => {
        newDocumentsOwned.push(_omit(document, 'documentType'));
      });

      dispatch(change(formName, 'documentsOwned', newDocumentsOwned));
    }
  }

  // Allow editing on both SSN and passport fields when checkbox is toggled
  handleAllowPassportNumberEdit(true);
  handleAllowSSNEdit(true);
};

/**
 * Disable editing of either SNN or Passport number field when personalInfo.hasSsn is true
 * @param {object} parsedResponse
 */
export const handleDisableIdNumberEdit = (parsedResponse) => {
  const membershipId = getCurrentMembershipId();
  const personalInfoId = parsedResponse.membership[membershipId].relationships.personalInfo.data.id;
  const personalInfo = parsedResponse.personalInfo[personalInfoId];

  if (personalInfo.attributes.hasSsn) {
    store.dispatch(change(formNamesSettings.USER_REPRESENTATIVE, 'ui.shouldAllowSSNEdit', false));
  }

  if (personalInfo.attributes.hasPassportNumber) {
    store.dispatch(change(formNamesSettings.USER_REPRESENTATIVE, 'ui.shouldAllowPassportNumberEdit', false));
  }
};

/**
 * Handle change in form and react to changes in show/hide SSN
 * @param values
 * @param dispatch
 * @param props
 * @param previousValues
 */
export const handleFormChange = (values, dispatch, props, previousValues) => {
  if (!values || !values.ui || !previousValues || !previousValues.personalInfo || !previousValues.ui) {
    return;
  }

  // Clear SSN or passport fields when clicking the shouldShowSSNField checkbox
  if (values.ui.shouldShowSSNField !== previousValues.ui.shouldShowSSNField) {
    handleClearGovernmentID(values.ui, values.documentsOwned, dispatch);
  }
};

import { createIsSubmittingReducer } from 'store/redux';

import {
  SUBMIT_PLATFORM_PARTNERSHIP_FAILURE,
  SUBMIT_PLATFORM_PARTNERSHIP_REQUEST,
  SUBMIT_PLATFORM_PARTNERSHIP_SUCCESS,
} from 'types/integrations';

export const finishCases = [SUBMIT_PLATFORM_PARTNERSHIP_FAILURE, SUBMIT_PLATFORM_PARTNERSHIP_SUCCESS];
export const requestCases = [SUBMIT_PLATFORM_PARTNERSHIP_REQUEST];

const submitReducer = createIsSubmittingReducer(finishCases, requestCases);

export default submitReducer;

import { useQuery } from '@tanstack/react-query';
import { queryContext } from '@routable/shared';
import _groupBy from 'lodash/groupBy';
import { generateKeys, getFieldsFromEntries, isInternationalFieldCategoryBankAccount, isInternationalFieldCategoryBeneficiary, mapFieldToByFieldNameTuple, } from '../../helpers';
import { internationalFieldsService } from '../../services';
import {} from './useInternationalBankFields.types';
export const useInternationalBankFields = ({ businessType, countryCode, currencyCode, }) => {
    return useQuery({
        context: queryContext,
        enabled: !!currencyCode,
        queryKey: generateKeys.internationalBankFieldsForCurrencyCode(currencyCode, countryCode, businessType),
        queryFn: async () => internationalFieldsService.getBankFieldsForCurrencyCode(currencyCode, countryCode, businessType),
        select: (data) => {
            if (!data?.data) {
                return null;
            }
            const bankFields = data.data.filter(isInternationalFieldCategoryBankAccount);
            const bankFieldsByFieldName = getFieldsFromEntries(bankFields.map(mapFieldToByFieldNameTuple));
            const beneficiaryFields = data.data.filter(isInternationalFieldCategoryBeneficiary);
            const beneficiaryFieldsByFieldName = getFieldsFromEntries(beneficiaryFields.map(mapFieldToByFieldNameTuple));
            return {
                all: data.data,
                bank: bankFields,
                bankByName: bankFieldsByFieldName,
                beneficiary: beneficiaryFields,
                beneficiaryByName: beneficiaryFieldsByFieldName,
            };
        },
    });
};

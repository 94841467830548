import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

import Avatar from 'components/avatar/Avatar';

const { ValueContainer } = components;

/**
 * An extension of the ValueContainer component for react-select,
 * for rendering an avatar beside the value
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 */
const ValueContainerWithAvatar = ({ children, ...rest }) => {
  const {
    selectProps: { value },
  } = rest;

  return (
    <ValueContainer className="has-avatar display--flex" {...rest}>
      {/* Check if value contains data before rendering <Avatar/> and avoid a crash */}
      {value?.data && (
        <Avatar
          avatar={value.data.avatar}
          email={value.data.email}
          firstName={value.data.firstName}
          lastName={value.data.lastName}
          sizeClass="avatar--xs"
          styleClass="avatar--light"
        />
      )}
      {children}
    </ValueContainer>
  );
};

ValueContainerWithAvatar.propTypes = {
  children: PropTypes.node,
};

ValueContainerWithAvatar.defaultProps = {
  children: undefined,
};

export default ValueContainerWithAvatar;

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { getApplySortActionByTab, getSortSelectorByTab } from 'helpers/itemSort';

import TableHeaderCell from './TableHeaderCell';

export const mapStateToProps = (state, ownProps) => {
  const itemSortSelector = getSortSelectorByTab(ownProps.match.params.tab);
  return {
    itemSortParam: itemSortSelector(state),
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  const onSort = getApplySortActionByTab(ownProps.match.params.tab);
  return {
    onSort: (sortParam) => dispatch(onSort(sortParam)),
  };
};

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));

export default enhance(TableHeaderCell);

export const lineItemsDataFieldNameLookups = {
  ACCOUNT_NAME: 'account',
  CLASS: 'extended_class',
  DEPARTMENT_NAME: 'extended_department',
  ITEM_NAME: 'item',
  LOCATION_NAME: 'extended_location',
};

export const TooltipPadding = {
  LARGE: 'LARGE',
  SKINNY: 'SKINNY',
  X_SKINNY: 'X_SKINNY',
};

export const TooltipPlacement = {
  BOTTOM_END: 'bottom-end',
  BOTTOM_START: 'bottom-start',
  BOTTOM: 'bottom',
  LEFT_END: 'left-end',
  LEFT_START: 'left-start',
  LEFT: 'left',
  RIGHT_END: 'right-end',
  RIGHT_START: 'right-start',
  RIGHT: 'right',
  TOP_END: 'top-end',
  TOP_START: 'top-start',
  TOP: 'top',
};

export const TooltipMaxWidth = {
  LARGE: 'large',
  /** @deprecated added to help handle tooltip conversion easier */
  MEDIUM: 'medium',
  NONE: 'none',
};

import PropTypes from 'prop-types';
import React from 'react';

import { FormControl } from 'components/form';
import { text } from 'components/hintTypes/constants';
import { textHelpers } from 'components/hintTypes/helpers';

import { Intent } from 'constants/ui';

import BaseHint from '../BaseHint';

/**
 * Hint to display information about bounced email.
 * @param {contactEmail} string
 * @return {StatelessComponent}
 * @constructor
 */
const BouncedEmailHint = ({ contactEmail }) => (
  <FormControl>
    <BaseHint
      intent={Intent.DANGER}
      multiline
      text={text.bouncedEmailHint.text}
      title={textHelpers.getBouncedEmailHintTitle(contactEmail)}
    />
  </FormControl>
);

BouncedEmailHint.propTypes = {
  contactEmail: PropTypes.string.isRequired,
};

export default BouncedEmailHint;

import { initStoreAccessor as initSharedStoreAccessor } from '@routable/shared';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import { routableApi } from 'ducks/routableApi';
import createRootReducer from 'rootReducer';
import rootSaga from 'sagas';

import { areProductionDevtoolsEnabled, isNodeEnvProduction } from 'helpers/env';

import history from 'modules/app/history';

import initStoreAccessor from './accessor';
import configureDevStore from './dev';
import { createMultiActionMiddleware, createPaginationMiddleware } from './middlewares';
import { notificationMiddleware } from './middlewares/notifications';
import { reduxShiftSelect } from './middlewares/shiftSelectItem';
import { generateThrottleMiddleware } from './middlewares/throttle';

const paginationMiddleware = createPaginationMiddleware();
const multiActionMiddleware = createMultiActionMiddleware();

const rootReducer = createRootReducer(history);

const configureStore = (hydrator = {}) => {
  const routerMiddleware = createRouterMiddleware(history);
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [
    generateThrottleMiddleware(100),
    routableApi.middleware,
    notificationMiddleware,
    routerMiddleware,
    thunk,
    sagaMiddleware,
    paginationMiddleware,
    multiActionMiddleware,
    reduxShiftSelect,
  ];

  let store;

  if (isNodeEnvProduction() && !areProductionDevtoolsEnabled()) {
    store = createStore(rootReducer, hydrator, applyMiddleware(...middlewares));
  } else {
    const composeEnhancers = composeWithDevTools({
      shouldCatchErrors: true,
      trace: true,
    });

    store = createStore(rootReducer, hydrator, composeEnhancers(applyMiddleware(...middlewares)));

    configureDevStore(store);
  }

  initStoreAccessor(store);
  initSharedStoreAccessor(store);

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;

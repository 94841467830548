import { getAllIdsReducerResult } from 'store/redux';

import { FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_SUCCESS } from 'types/integrations';

const allLedgerUnmatchedLedgerFundingAccountsReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_SUCCESS:
      return getAllIdsReducerResult(state, action.payload.fundingAccount);

    default:
      return state;
  }
};

export default allLedgerUnmatchedLedgerFundingAccountsReducer;

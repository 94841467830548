import React from 'react';
import { useDispatch } from 'react-redux';
import { reLogin } from 'actions/quickswitchActions';
import { Icon, IconNames } from 'components';
import { ButtonV2 } from 'components/buttonV2';
import { Col, Row } from 'components/layout';
import { ModalBodyContent, ModalFooter } from 'components/modal';
import Text from 'components/text/Text/Text';
import { ButtonSize } from 'constants/button';
import { CONTACT_SUPPORT_LOWERCASE_LABEL } from 'constants/labels';
import { colors } from 'constants/styles';
import { TextColor, TextSize } from 'constants/styles/typography';
import { Intent } from 'constants/ui';
import { CustomerServiceHelper } from 'modules/customerSupport/CustomerService';
const QuickswitchErrorModalContent = () => {
    const dispatch = useDispatch();
    const onLoginClick = () => {
        dispatch(reLogin());
    };
    const onSupportClick = () => {
        CustomerServiceHelper.show();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalBodyContent, { className: "quickswitch-error-modal--content" },
            React.createElement(Row, { className: "quickswitch-error-modal--title quickswitch-error-modal--paragraph" },
                React.createElement(Col, null,
                    React.createElement(Text.ExtraBold, { color: TextColor.BLACK, size: TextSize.LEVEL_400 },
                        React.createElement(Icon, { className: "margin-right--sm", color: colors.colorRedBoldHex, icon: IconNames.WARNING_SIGN }),
                        "Switching accounts failed"))),
            React.createElement(Row, { className: "quickswitch-error-modal--paragraph padding-bottom--m-large" },
                React.createElement(Col, null,
                    React.createElement(Text.Regular, { color: TextColor.BLACK, size: TextSize.LEVEL_200 }, "Looks like we\u2019re having trouble switching your Routable account. You will need to re-login in order to proceed."))),
            React.createElement(Row, { className: "quickswitch-error-modal--paragraph padding-bottom--l" },
                React.createElement(Col, null,
                    React.createElement(Text.Regular, { color: TextColor.BLACK, size: TextSize.LEVEL_200 },
                        "If you have any questions, or need our assistance in solving this issue, please\u00A0",
                        React.createElement("a", { className: "quickswitch-error-modal--link", onClick: onSupportClick, onKeyDown: onSupportClick, role: "link", tabIndex: 0 }, CONTACT_SUPPORT_LOWERCASE_LABEL),
                        "\u00A0and we'll be happy to help.")))),
        React.createElement(ModalFooter, { className: "quickswitch-error-modal--footer" },
            React.createElement(ButtonV2, { className: "hover--neutral", id: "quickswitch-error-modal-contact-support-btn", intent: Intent.NEUTRAL, onClick: onSupportClick, size: ButtonSize.MEDIUM }, "Contact Support"),
            React.createElement(ButtonV2, { id: "quickswitch-error-modal-login-btn", intent: Intent.PRIMARY, onClick: onLoginClick, size: ButtonSize.MEDIUM }, "Login"))));
};
export default QuickswitchErrorModalContent;

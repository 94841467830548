import { Button, Tooltip } from '@routable/gross-ds';
import { queryClient } from '@routable/shared';
import { taxTools } from '@routable/taxes';
import React, { useRef } from 'react';
import { usePartnershipAndPartnerCompanyFromPartnershipIdQuery, useTaxInfoSingleRequestMutation, } from 'ducks/routableApi/partnership';
import PermissionResourceAction from 'constants/permissions';
import { isCompanyPlatformTypeInInviteStage } from 'helpers/currentCompany';
import { getTaxDataKeyForPartnership, isPartnershipRoutableToRoutable, isPartnershipVendor, } from 'helpers/partnerships';
import { useGrossDsPermissions } from 'hooks';
import { getTaxInfoRequestButtonTooltipProps } from './TaxInfoRequestButton.helpers';
const TaxInfoRequestButton = ({ partnershipId }) => {
    const hasBeenTriggeredRef = useRef(false);
    const [taxInfoSingleRequest, { isLoading }] = useTaxInfoSingleRequestMutation();
    const { partner, partnership } = usePartnershipAndPartnerCompanyFromPartnershipIdQuery(partnershipId);
    const isTaxCollectionDisabled = !partnership.collectVendorTaxInfo;
    const permissions = useGrossDsPermissions([PermissionResourceAction.TAX_BASIC_MANAGEMENT]);
    const isR2R = isPartnershipRoutableToRoutable(partnership);
    const isPartnerStatusInvited = isCompanyPlatformTypeInInviteStage(partner?.platformType);
    const isVendor = isPartnershipVendor(partnership);
    const isWithoutContact = !partnership?.hasCompanyManagementContact;
    const isDisabled = Boolean(hasBeenTriggeredRef.current || isR2R || isWithoutContact || isTaxCollectionDisabled || !permissions.hasPermission);
    const tooltipProps = getTaxInfoRequestButtonTooltipProps({
        hasBeenTriggered: hasBeenTriggeredRef.current,
        isDisabled,
        isLoading,
        isPartnerStatusInvited,
        isTaxCollectionDisabled,
        isR2R,
        isWithoutContact,
        partnerName: partnership?.name,
        permissions,
    });
    const handleRequestTaxInfo = async () => {
        if (hasBeenTriggeredRef.current) {
            return;
        }
        try {
            const taxDataKey = getTaxDataKeyForPartnership({ partner, partnership });
            await taxInfoSingleRequest({ partnershipId, taxDataKey }).unwrap();
            queryClient.invalidateQueries({ queryKey: [taxTools, partnershipId] });
        }
        catch {
        }
        hasBeenTriggeredRef.current = true;
    };
    if (!isVendor) {
        return null;
    }
    return (React.createElement(Tooltip, { "data-testid": "request-tax-info-tooltip", ...tooltipProps, position: "top", variant: "light" },
        React.createElement(Button, { "data-testid": "request-tax-info-button", intent: "primary", isDisabled: isDisabled, loading: isLoading, onPress: handleRequestTaxInfo, size: "small" }, "Request tax information")));
};
export default TaxInfoRequestButton;

import { useModalState } from '@routable/gross-ds';
import { useState } from 'react';
export const useWatchlistHitSidesheet = () => {
    const sideSheetState = useModalState({});
    const [hitDetails, setHitDetails] = useState(null);
    const handleOpenWatchlistHitDetails = (watchlistHit) => {
        setHitDetails(watchlistHit);
        sideSheetState.open();
    };
    const handleCloseWatchlistHitDetails = () => {
        setHitDetails(null);
        sideSheetState.close();
    };
    return {
        state: sideSheetState,
        onOpen: handleOpenWatchlistHitDetails,
        onClose: handleCloseWatchlistHitDetails,
        hitDetails,
    };
};

import { combineReducers } from 'redux';

import partnershipItemsAllIdsReducer from './partnershipItemsAllIdsReducer';
import partnershipItemsByIdReducer from './partnershipItemsByIdReducer';
import partnershipItemsErrorReducer from './partnershipItemsErrorReducer';
import partnershipItemsIsFetchingReducer from './partnershipItemsIsFetchingReducer';
import partnershipItemsWasFetchedReducer from './partnershipItemsWasFetchedReducer';

/**
 * Partnership Items root reducer
 * @type {ReduxReducer}
 */
const partnershipItemsRootReducer = combineReducers({
  allIds: partnershipItemsAllIdsReducer,
  byId: partnershipItemsByIdReducer,
  error: partnershipItemsErrorReducer,
  isFetching: partnershipItemsIsFetchingReducer,
  wasFetched: partnershipItemsWasFetchedReducer,
});

export default partnershipItemsRootReducer;

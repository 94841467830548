import { combineReducers } from 'redux';

import * as modalNames from 'constants/modals';

import modalExportCsvReducer from 'reducers/modalsReducer//modalExportCsvReducer';
import addEmailForwardReducer from 'reducers/modalsReducer/addEmailForwardReducer';
import addInternationalPaymentMethodReducer from 'reducers/modalsReducer/addInternationalPaymentMethodReducer';
import addPartnerFundingAccountReducer from 'reducers/modalsReducer/addPartnerFundingAccountReducer';
import balanceTransactionReducer from 'reducers/modalsReducer/balanceTransactionReducer';
import bankAccountDetailsReducer from 'reducers/modalsReducer/bankAccountDetailsReducer';
import confirmTaxFilingInfoReducer from 'reducers/modalsReducer/confirmTaxFilingInfoReducer';
import connectBankManualReducer from 'reducers/modalsReducer/connectBankManualReducer';
import connectBankTokenReducer from 'reducers/modalsReducer/connectBankTokenReducer';
import createApiTokenReducer from 'reducers/modalsReducer/createApiTokenReducer';
import createPartnershipReducer from 'reducers/modalsReducer/createPartnershipReducer';
import deactivateTeamMemberReducer from 'reducers/modalsReducer/deactivateTeamMemberReducer';
import forceManualLedgerSyncModalReducer from 'reducers/modalsReducer/forceManualLedgerSyncModalReducer';
import matchClearingAccountsReducer from 'reducers/modalsReducer/matchClearingAccountsReducer';
import matchPlatformPartnershipReducer from 'reducers/modalsReducer/matchPlatformPartnershipReducer';
import mergeLedgerPartnershipReducer from 'reducers/modalsReducer/mergeLedgerPartnershipReducer';
import createItemModalReducer from 'reducers/modalsReducer/modalCreateItemsReducer';
import createManualAddressReducer from 'reducers/modalsReducer/modalCreateManualAddressReducer';
import modalFundingMemoProviderReducer from 'reducers/modalsReducer/modalFundingMemoProviderReducer';
import modalIntegrationSyncOptionsReducer from 'reducers/modalsReducer/modalIntegrationSyncOptionsReducer';
import modalMaintenanceModeReducer from 'reducers/modalsReducer/modalMaintenanceModeReducer';
import modalOldVersionModeReducer from 'reducers/modalsReducer/modalOldVersionModeReducer';
import modalSetupSSOReducer from 'reducers/modalsReducer/modalSetupSSOReducer';
import modalSetupSSOSplashscreenReducer from 'reducers/modalsReducer/modalSetupSSOSplashscreenReducer';
import modalSSODisableReducer from 'reducers/modalsReducer/modalSSODisableReducer';
import updatePartnershipPaymentMethodReducer from 'reducers/modalsReducer/modalUpdatePartnershipPaymentMethodReducer';
import verifyFundingAccountModalReducer from 'reducers/modalsReducer/verifyFundingAccountModalReducer';
import webhookTestModalReducer from 'reducers/modalsReducer/webhookTestModalReducer';

const modalsReducer = combineReducers({
  [modalNames.modalAddInternationalPayment]: addInternationalPaymentMethodReducer,
  [modalNames.modalNameAddEmailForward]: addEmailForwardReducer,
  [modalNames.modalNameAddPartnerFundingAccount]: addPartnerFundingAccountReducer,
  [modalNames.modalNameBalanceTransaction]: balanceTransactionReducer,
  [modalNames.modalNameBankAccountDetails]: bankAccountDetailsReducer,
  [modalNames.modalNameConfirmTaxFilingInfo]: confirmTaxFilingInfoReducer,
  [modalNames.modalNameConnectBankManual]: connectBankManualReducer,
  [modalNames.modalNameConnectBankToken]: connectBankTokenReducer,
  [modalNames.modalNameCreateApiToken]: createApiTokenReducer,
  [modalNames.modalNameCreateItem]: createItemModalReducer,
  [modalNames.modalNameCreateManualAddress]: createManualAddressReducer,
  [modalNames.modalNameDeactivateTeamMember]: deactivateTeamMemberReducer,
  [modalNames.modalIntegrationSyncOptions]: modalIntegrationSyncOptionsReducer,
  [modalNames.modalNamesPartnership.CREATE]: createPartnershipReducer,
  [modalNames.modalNameExportCsv]: modalExportCsvReducer,
  [modalNames.modalNameForceManualLedgerSync]: forceManualLedgerSyncModalReducer,
  [modalNames.modalNameFundingProviderMemo]: modalFundingMemoProviderReducer,
  [modalNames.modalNameMaintenanceMode]: modalMaintenanceModeReducer,
  [modalNames.modalNameMatchClearingAccount]: matchClearingAccountsReducer,
  [modalNames.modalNameMatchPlatformPartnership]: matchPlatformPartnershipReducer,
  [modalNames.modalNameMergeLedgerPartnership]: mergeLedgerPartnershipReducer,
  [modalNames.modalNameOldVersionMode]: modalOldVersionModeReducer,
  [modalNames.modalNameSSODisable]: modalSSODisableReducer,
  [modalNames.modalNameSSOSetupForm]: modalSetupSSOReducer,
  [modalNames.modalNameSSOSetupSplashscreen]: modalSetupSSOSplashscreenReducer,
  [modalNames.modalNameUpdatePartnerFundingAccount]: updatePartnershipPaymentMethodReducer,
  [modalNames.modalNameVerifyFundingAccount]: verifyFundingAccountModalReducer,
  [modalNames.modalNameWebhookTest]: webhookTestModalReducer,
});

export default modalsReducer;

import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';

import { partnershipRequestRelationships } from 'constants/relationships';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

const byIdReducer = (state = {}, action) => {
  switch (action.type) {
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
      return deepMergeWithArrayReplacement(
        state,
        getObjectsByIdWithRelationships(action.payload.partnershipRequest, partnershipRequestRelationships),
      );

    default:
      return state;
  }
};

export default byIdReducer;

import { getQueryString } from 'helpers/queryParams';
import {} from 'interfaces/request';
import { BASE_API_URL } from './index';
export const webhookBaseEndpoint = `${BASE_API_URL}/webhooks/`;
export const webhookSettingsBaseEndpoint = `${BASE_API_URL}/webhook_settings/`;
export const getWebhooksEndpoint = (params = {}) => {
    if (params.url) {
        return params.url;
    }
    return `${webhookBaseEndpoint}?${getQueryString(params)}`;
};
export const getWebhookSettingsInstanceEndpoint = (id) => `${webhookSettingsBaseEndpoint}${id}/`;
export const getWebhookSettingsTestEndpoint = (id) => `${webhookSettingsBaseEndpoint}${id}/test/`;

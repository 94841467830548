import PropTypes from 'prop-types';
import React from 'react';

import { itemsScheduledHintText } from 'components/hintTypes/constants/text';
import { getItemsScheduledHintTitle } from 'components/hintTypes/helpers/text';

import { Intent } from 'constants/ui';

import BaseHint from '../BaseHint';

/**
 * Shows how many items are waiting on the vendor to select a payment method
 * @see {BulkImportItemsHints}
 *
 * @param {ComponentProps} props
 * @param {Number} props.itemCount
 * @param {RestOfProps} rest
 * @return {StatelessComponent|null}
 */
const ItemsScheduledHint = ({ itemCount, ...rest }) => (
  <BaseHint
    intent={Intent.SCHEDULE}
    multiline
    text={itemsScheduledHintText}
    title={getItemsScheduledHintTitle(itemCount)}
    {...rest}
  />
);

ItemsScheduledHint.propTypes = {
  itemCount: PropTypes.number.isRequired,
};

export default ItemsScheduledHint;

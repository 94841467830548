export var PartnershipType;
(function (PartnershipType) {
    PartnershipType["CUSTOMER"] = "customer";
    PartnershipType["VENDOR"] = "vendor";
    PartnershipType["COMPANY"] = "company";
})(PartnershipType || (PartnershipType = {}));
export var PartnershipPartnerStatus;
(function (PartnershipPartnerStatus) {
    PartnershipPartnerStatus["ACCEPTED"] = "accepted";
    PartnershipPartnerStatus["ADDED"] = "added";
    PartnershipPartnerStatus["INVITED"] = "invited";
})(PartnershipPartnerStatus || (PartnershipPartnerStatus = {}));
export var PartnershipMemberEmailStatus;
(function (PartnershipMemberEmailStatus) {
    PartnershipMemberEmailStatus["BOUNCED"] = "bounced";
})(PartnershipMemberEmailStatus || (PartnershipMemberEmailStatus = {}));
export var PartnershipMemberAccess;
(function (PartnershipMemberAccess) {
    PartnershipMemberAccess["ACTIONABLE"] = "actionable";
    PartnershipMemberAccess["NONE"] = "none";
    PartnershipMemberAccess["SELF_MANAGED"] = "self_managed";
    PartnershipMemberAccess["READ_ONLY"] = "read_only";
    PartnershipMemberAccess["HAS_DEFAULTS"] = "has_defaults";
    PartnershipMemberAccess["NO_DEFAULTS"] = "no_defaults";
})(PartnershipMemberAccess || (PartnershipMemberAccess = {}));
export var PartnershipCountryCodeKey;
(function (PartnershipCountryCodeKey) {
    PartnershipCountryCodeKey["COMPANY"] = "countryCodeCompany";
    PartnershipCountryCodeKey["PARTNER"] = "countryCodePartner";
})(PartnershipCountryCodeKey || (PartnershipCountryCodeKey = {}));
export var PartnershipMemberAccessScope;
(function (PartnershipMemberAccessScope) {
    PartnershipMemberAccessScope["COMPANY_MANAGEMENT"] = "company_management";
    PartnershipMemberAccessScope["ITEMS"] = "items";
})(PartnershipMemberAccessScope || (PartnershipMemberAccessScope = {}));
export var PartnershipResendInviteAction;
(function (PartnershipResendInviteAction) {
    PartnershipResendInviteAction["SUBMITTING"] = "submitting";
    PartnershipResendInviteAction["SUBMITTED"] = "submitted";
})(PartnershipResendInviteAction || (PartnershipResendInviteAction = {}));

import { PaymentDeliveryMethodType } from '@routable/shared';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { noDefaultEvent } from 'helpers/events';

import { CheckOptionsRadio } from './components';

import './PartnerCompanyPaymentMethodCheck.scss';

const PartnerCompanyPaymentMethodCheck = ({
  fieldName,
  fundingAccount,
  onFundingAccountAddressSelected,
  onFundingAccountSelected,
  paymentDeliveryMethod,
}) => (
  <Field
    component={CheckOptionsRadio}
    name={fieldName}
    onBlur={(e) => noDefaultEvent(e)}
    props={{
      fundingAccount,
      onFundingAccountSelected,
      onFundingAccountAddressSelected,
      paymentDeliveryMethod,
    }}
    type="radio"
    value={PaymentDeliveryMethodType.CHECK}
  />
);

PartnerCompanyPaymentMethodCheck.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fundingAccount: PropTypes.string,
  onFundingAccountAddressSelected: PropTypes.func.isRequired,
  onFundingAccountSelected: PropTypes.func.isRequired,
  paymentDeliveryMethod: PropTypes.string,
};

PartnerCompanyPaymentMethodCheck.defaultProps = {
  fundingAccount: undefined,
  paymentDeliveryMethod: undefined,
};

export default PartnerCompanyPaymentMethodCheck;

import { ignoredUUID } from 'constants/uuids';

/**
 * Checks whether the given value is the ignored UUID.
 * @param {*} uuid
 * @return {boolean}
 */
export const isUUIDIgnoredUUID = (uuid) => uuid === ignoredUUID;

/**
 * Checks whether the given value is a valid email address formatted string.
 * @param {*} s
 * @return {boolean}
 */
export const isValidEmail = (s) => {
  const regex =
    // eslint-disable-next-line max-len
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(s);
};

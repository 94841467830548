import PropTypes from 'prop-types';
import React from 'react';

import './ActionNavSearchClear.scss';

const ActionNavSearchClear = ({ onSearchClear, searchData }) => {
  if (!searchData.keyword && !searchData.execute) {
    return null;
  }

  return <span className="action-nav-search-clear icon-ic-x-out" onClick={onSearchClear} role="presentation" />;
};

ActionNavSearchClear.propTypes = {
  onSearchClear: PropTypes.func.isRequired,
  searchData: PropTypes.shape().isRequired,
};

export default ActionNavSearchClear;

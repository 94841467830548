import { createSelector } from 'reselect';

import { anyValues, or, hasLength } from 'helpers/utility';

import { currentMembershipRoleIdSelector } from 'selectors/membershipsSelector';
import { permissionsAllIdsSelector, permissionsByIdSelector } from 'selectors/permissionsSelectors';
import { rolesAllIdsSelector, rolesByIdSelector } from 'selectors/rolesSelectors';

import { membershipRoleIdFromPartnershipRequestSelector } from './membershipCompoundSelectors';

/**
 * A selector which gets the role id from the currentMembership or the partnershipRequest's membership.
 *
 * @type {StandardSelector}
 * @param {ReduxState}
 * @returns {Role.id}
 */
export const currentOrPartnershipRequestMembershipRoleIdSelector = createSelector(
  [currentMembershipRoleIdSelector, membershipRoleIdFromPartnershipRequestSelector],
  (currentMembershipRoleId, membershipRoleIdFromPartnershipRequest) =>
    or(
      currentMembershipRoleId,
      // In external flows, we can't use currentMembershipRoleIdSelector
      // since there is not a membership stored localStorage.
      membershipRoleIdFromPartnershipRequest,
    ),
);

/**
 * A selector which uses the current membership's Role to generate a list of their permissions' resourceActions.
 *
 * @type {StandardSelector}
 * @param {ReduxState}
 * @returns {Permission.resourceAction[]}
 */
export const currentMemberPermissionsSelector = createSelector(
  [currentOrPartnershipRequestMembershipRoleIdSelector, permissionsByIdSelector, rolesByIdSelector],
  (currentMembershipRoleId, permissionsById, roles) => {
    // NOTE: Must type cast into object since currentMembershipRoleId might not exist
    // before roles & permissions in signup/connect-bank
    const role = Object(roles[currentMembershipRoleId]);
    /** @type {Permission.id[]} */
    const { permissions = [] } = role;

    if (!anyValues(permissionsById)) {
      return [];
    }

    return permissions.map((permissionId) => permissionsById[permissionId].resourceAction);
  },
);

/**
 * A selector which uses the current membership Role's name.
 *
 * @type {StandardSelector}
 * @param {ReduxState}
 * @returns {string}
 */
export const currentMembershipRoleNameSelector = createSelector(
  [currentOrPartnershipRequestMembershipRoleIdSelector, rolesByIdSelector],
  (currentMembershipRoleId, roles) => {
    const role = roles[currentMembershipRoleId];
    return role?.name || '';
  },
);

/**
 * A selector which uses the current membership's Role to generate a Set of their permissions' resourceActions.
 *
 * @type {StandardSelector}
 * @param {ReduxState}
 * @returns {CurrentMemberPermissionSet}
 */
export const currentMemberPermissionsSetSelector = createSelector(
  [currentMemberPermissionsSelector],
  (permissions = []) => new Set(permissions),
);

/**
 * A selector that return boolean when both roles and permissions exist
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} ownProps
 * @returns {Boolean}
 */
export const currentMembershipHasRolesAndPermissionsSelector = createSelector(
  [rolesAllIdsSelector, permissionsAllIdsSelector],
  (...rolesAndPermissions) => rolesAndPermissions.every(hasLength),
);

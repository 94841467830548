import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { ButtonV2, FormControl, FormFieldLabel, IconNames, ReduxFormFieldRenderInput, PrivacyPolicyLink, TermsOfServiceLink, } from 'components';
import PasswordInput from 'components/passwordInput';
import { MINIMUM_STRENGTH } from 'components/passwordInput/constants/passwordRequirementParameters';
import { signUpCreateAccountFields } from 'constants/formFields';
import { formNamesSignup } from 'constants/forms';
import { sizes } from 'constants/styles';
import { field } from 'constants/styles/formStyles';
import { trimString } from 'helpers/fieldNormalizers';
import { emailValidator, requiredValidator } from 'helpers/fieldValidation';
import { getPasswordScore } from 'selectors/passwordInputSelectors';
import { isSubmittingSignupCreateAccountSelector } from 'selectors/signupSelectors';
import { ButtonSize } from '../../../constants/button';
import { Intent } from '../../../constants/ui';
import SignUpFormFooter from '../components/SignUpFormFooter';
import SignUpFormHeader from '../components/SignUpFormHeader';
import { sectionHeaderClassname, pClassname, spanClassname } from '../constants/relevantClassnames';
const CreateAccount = ({ handleSubmit, invalid }) => {
    const [userAttemptedSubmitting, setUserAttemptedSubmitting] = useState(false);
    const handleSubmitClick = () => setUserAttemptedSubmitting(true);
    const isLoading = useSelector(isSubmittingSignupCreateAccountSelector);
    const passwordScore = useSelector(getPasswordScore);
    const isPasswordStrengthAcceptable = passwordScore >= MINIMUM_STRENGTH;
    return (React.createElement("div", { className: "submodule-container" },
        React.createElement("div", { className: "form-container" },
            React.createElement(SignUpFormHeader, { hasSignInOption: true }),
            React.createElement("form", { className: "form", id: formNamesSignup.CREATE_ACCOUNT_COMPANY, onSubmit: handleSubmit },
                React.createElement("h2", null, "Let\u2019s create your account"),
                React.createElement("div", null,
                    React.createElement(FormFieldLabel, { className: sectionHeaderClassname }, "Company name"),
                    React.createElement("p", { className: pClassname }, "This is the name your company uses when communicating with vendors and customers."),
                    React.createElement(FormControl, null,
                        React.createElement(Field, { className: field.xl.full, component: ReduxFormFieldRenderInput, dataFullStory: true, dataTestId: "companyName", isRequired: true, maxLength: 100, name: signUpCreateAccountFields.COMPANY_NAME, placeholder: "Company name", validate: requiredValidator }))),
                React.createElement("div", null,
                    React.createElement(FormFieldLabel, { className: sectionHeaderClassname }, "Account information"),
                    React.createElement(FormControl, null,
                        React.createElement(Field, { className: field.xl.full, component: ReduxFormFieldRenderInput, dataFullStory: true, dataTestId: "companyEmail", isRequired: true, maxLength: 100, name: signUpCreateAccountFields.USER_EMAIL, normalize: trimString, placeholder: "Company email", validate: [requiredValidator, emailValidator] })),
                    React.createElement(FormControl, null,
                        React.createElement(Field, { className: field.xl.left, component: ReduxFormFieldRenderInput, dataFullStory: true, dataTestId: "firstName", isRequired: true, maxLength: 100, name: signUpCreateAccountFields.USER_FIRST_NAME, placeholder: "First Name", validate: requiredValidator }),
                        React.createElement(Field, { className: field.xl.right, component: ReduxFormFieldRenderInput, dataFullStory: true, dataTestId: "lastName", isRequired: true, maxLength: 100, name: signUpCreateAccountFields.USER_LAST_NAME, placeholder: "Last name", validate: requiredValidator })),
                    React.createElement(FormControl, null,
                        React.createElement(PasswordInput, { formName: formNamesSignup.CREATE_ACCOUNT, name: signUpCreateAccountFields.USER_PASSWORD, userAttemptedSubmission: userAttemptedSubmitting }))),
                React.createElement(FormControl, { className: "form-control remove-margin-bottom", id: "button-control" },
                    React.createElement(ButtonV2, { className: "display--table-cell", dataTestId: "createAccountButton", intent: Intent.PRIMARY, isDisabled: invalid || !isPasswordStrengthAcceptable, isLoading: isLoading, onClick: handleSubmitClick, rightIconClassName: "margin-left--m", rightIconName: IconNames.ARROW_RIGHT, rightIconSize: sizes.iconSizes.SMALL, size: ButtonSize.MEDIUM, type: "submit" }, "Create account")),
                React.createElement("p", { className: spanClassname },
                    "By creating my account I am agreeing to Routable\u2019s ",
                    React.createElement(TermsOfServiceLink, { className: "font-color--greyXDark" }),
                    ' ',
                    "and acknowledging the ",
                    React.createElement(PrivacyPolicyLink, { className: "font-color--greyXDark" }),
                    ".")),
            React.createElement(SignUpFormFooter, { extraBottomPadding: true }))));
};
export default CreateAccount;

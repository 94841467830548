import React from 'react';
import CrossBorderPaymentsNotEnabledTooltip from 'complexComponents/tooltipTypes/CrossBorderPaymentsNotEnabledTooltip';
import CurrencyNotEnabledOnLedgerTooltip from 'complexComponents/tooltipTypes/CurrencyNotEnabledOnLedgerTooltip';
import CurrencyNotSupportedByPlatformTooltip from 'complexComponents/tooltipTypes/CurrencyNotSupportedByPlatformTooltip';
import LimitedSendingNonBaseCurrencyPaymentsTooltip from 'complexComponents/tooltipTypes/LimitedSendingNonBaseCurrencyPaymentsTooltip';
import PreventInternationalCustomerMatchTooltip from 'complexComponents/tooltipTypes/PreventInternationalCustomerMatchTooltip';
import { MultiLineOptionForSearchCompanies } from 'components/selectV2/components';
import { getCustomerOrVendorOrBothTextByPartnership } from 'helpers/partnerships';
import { checkPartnershipEligibilityForSearchCompanies } from '../../helpers/logic';
import { getDetailsTextForSearchCompanies } from '../../helpers/text';
const MultiLineOptionWithTooltipForSearchCompanies = ({ companyIntegrationSettings, companySettings, data, formPartnershipType, ledger, ledgerCurrencies, location, supportedCurrencies, ...rest }) => {
    const { isCrossBorderPaymentsNotEnabledTooltipShown, isCurrencyNotSupportedByLedgerTooltipShown, isCurrencyNotSupportedByPlatformTooltipShown, isLimitSendingPaymentsTooltipShown, isPreventInternationalCustomerMatchTooltipShown, } = checkPartnershipEligibilityForSearchCompanies({
        companyIntegrationSettings,
        companySettings,
        formPartnershipType,
        ledger,
        ledgerCurrencies,
        location,
        partnership: data,
        supportedCurrencies,
    });
    const partnershipType = getCustomerOrVendorOrBothTextByPartnership(data);
    const detailsText = getDetailsTextForSearchCompanies({
        isCrossBorderPaymentsNotEnabledTooltipShown,
        isCurrencyNotSupportedByLedgerTooltipShown,
        isCurrencyNotSupportedByPlatformTooltipShown,
        isLimitSendingPaymentsTooltipShown,
        isPreventInternationalCustomerMatchTooltipShown,
        ledger,
        partnership: data,
    });
    return (React.createElement(LimitedSendingNonBaseCurrencyPaymentsTooltip, { isShown: isLimitSendingPaymentsTooltipShown, ledger: ledger },
        React.createElement(CrossBorderPaymentsNotEnabledTooltip, { isShown: isCrossBorderPaymentsNotEnabledTooltipShown },
            React.createElement(CurrencyNotEnabledOnLedgerTooltip, { currencyCode: data.currencyCodePartner, isShown: isCurrencyNotSupportedByLedgerTooltipShown, ledgerName: ledger.name, partnershipType: partnershipType },
                React.createElement(CurrencyNotSupportedByPlatformTooltip, { currencies: data.ledgerCurrencies || [], isShown: isCurrencyNotSupportedByPlatformTooltipShown, partnershipType: partnershipType },
                    React.createElement(PreventInternationalCustomerMatchTooltip, { isShown: isPreventInternationalCustomerMatchTooltipShown },
                        React.createElement(MultiLineOptionForSearchCompanies, { ...rest, data: {
                                ...data,
                                details: data.isDisabled && detailsText,
                            } })))))));
};
export default MultiLineOptionWithTooltipForSearchCompanies;

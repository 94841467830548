import { combineReducers } from 'redux';

import areAllRequirementsMetReducer from './areAllRequirementsMetReducer';
import fetchPasswordStrengthCompletedReducer from './fetchPasswordStrengthCompletedReducer';
import isFetchingPasswordStrengthReducer from './isFetchingPasswordStrengthReducer';

const passwordInputReducer = combineReducers({
  passwordInputStrength: fetchPasswordStrengthCompletedReducer,
  isFetchingPasswordStrength: isFetchingPasswordStrengthReducer,
  areAllRequirementsMet: areAllRequirementsMetReducer,
});

export default passwordInputReducer;

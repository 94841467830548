import { isLevelTypeAny } from './approvals';
export class ItemApprovalLevel {
    itemSideApprovals;
    levelPosition;
    levelType;
    constructor(itemSideApprovals) {
        this.itemSideApprovals = itemSideApprovals;
        this.levelPosition = itemSideApprovals[0].levelPosition;
        this.levelType = itemSideApprovals[0].levelType;
    }
    get numberOfApprovals() {
        return this.itemSideApprovals.filter((v) => v.hasApproved === true).length;
    }
    get numberOfNotApproved() {
        return this.itemSideApprovals.length - this.numberOfApprovals;
    }
    isLevelSatisfied = () => {
        if (isLevelTypeAny(this)) {
            return this.numberOfApprovals > 0;
        }
        return this.numberOfApprovals === this.itemSideApprovals.length;
    };
    canMemberApproveLevel = (membershipId) => this.itemSideApprovals.some((isa) => isa.membership === membershipId && isa.canApprove && !isa.hasApproved);
    canMemberSatisfyLevel = (membershipId) => {
        if (isLevelTypeAny(this)) {
            return this.canMemberApproveLevel(membershipId);
        }
        return this.canMemberApproveLevel(membershipId) && this.numberOfNotApproved === 1;
    };
}
export class ItemApprovals {
    itemSideApprovals;
    approvalLevels;
    constructor(itemSideApprovals) {
        this.itemSideApprovals = itemSideApprovals;
        this.approvalLevels = [];
        const levels = new Set(itemSideApprovals.map((a) => a.levelPosition));
        levels.forEach((level) => {
            const approvalsForLevel = itemSideApprovals.filter((isa) => isa.levelPosition === level);
            this.approvalLevels.push(new ItemApprovalLevel(approvalsForLevel));
        });
    }
    canMemberSatisfyItemApproval = (membershipId) => {
        const levelsNotSatisfied = this.approvalLevels.filter((v) => !v.isLevelSatisfied());
        return levelsNotSatisfied.length === 1 && levelsNotSatisfied[0].canMemberSatisfyLevel(membershipId);
    };
}

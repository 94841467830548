import { uploadBulkImportFileRoutine, getSingleBulkImportRoutine } from 'actions/routines/bulkActions';

import { createIsUploadingReducer } from 'store/redux';

export const finishCases = [
  uploadBulkImportFileRoutine.FAILURE,
  // if the file upload request is successful, we're not going to consider
  // "uploading" to be complete until we've received the first resource response
  getSingleBulkImportRoutine.FAILURE,
  getSingleBulkImportRoutine.SUCCESS,
];
export const requestCases = [uploadBulkImportFileRoutine.REQUEST];

const isUploadingReducer = createIsUploadingReducer(finishCases, requestCases);

export default isUploadingReducer;

import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';

import { sharedFormSubmitRoutinePayloadCreators } from 'helpers/routine';

import { FETCH_COMPANY_TAGS, CREATE_TAG, CREATE_ITEM_TAG_LINKS } from 'types/tags';

/**
 * @type {ReduxSagaRoutine}
 */
export const fetchCompanyTagsRoutine = createRoutine(FETCH_COMPANY_TAGS);

/**
 * @type {ReduxSagaRoutine}
 */
export const createTagRoutine = createRoutine(CREATE_TAG, sharedFormSubmitRoutinePayloadCreators);

/**
 * @type {ReduxSagaRoutine}
 */
export const createTagHandler = bindRoutineToReduxForm(createTagRoutine);

/**
 * @type {ReduxSagaRoutine}
 */
export const createItemTagLinksRoutine = createRoutine(CREATE_ITEM_TAG_LINKS, sharedFormSubmitRoutinePayloadCreators);

/**
 * @type {ReduxSagaRoutine}
 */
export const createItemTagLinksHandler = bindRoutineToReduxForm(createItemTagLinksRoutine);

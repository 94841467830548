import {} from '@routable/shared';
import {} from '../systemLogger.types';
export const withLogLevelFilter = (transport, filterLevel, levelMap = {
    TRACE: 10,
    DEBUG: 20,
    INFO: 30,
    WARN: 40,
    ERROR: 50,
    FATAL: 60,
}) => ({
    name: transport.name,
    transport: async (messages) => {
        const filteredMessages = messages.filter((message) => levelMap[message.level] >= levelMap[filterLevel]);
        if (filteredMessages.length > 0) {
            transport.transport(filteredMessages);
        }
    },
});

import { isCompanyTypeBusiness, isCompanyTypePersonal } from 'helpers/currentCompany';

/**
 * Calculates whether the FormFieldLabel should be shown based on the companyType and taxCollection
 * @param companyType - business/personal
 * @param collectVendorTaxInfo - true/false
 * @returns {boolean}
 */
export const shouldShowFormFieldLabel = (companyType, collectVendorTaxInfo) =>
  isCompanyTypeBusiness(companyType) || (isCompanyTypePersonal(companyType) && collectVendorTaxInfo);

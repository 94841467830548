import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Avatar from 'components/avatar/Avatar';
import { FlexCol, Row } from 'components/layout';
import { NonBlockingSpace, Text } from 'components/text';

import { typography } from 'constants/styles';

import { and, or } from 'helpers/utility';

/**
 * An avatar on the left with name, title, email address, etc. on the right.
 * @param {ComponentProps} props
 * @param {Membership} props.member
 * @param {StringMaybe} [props.subtitle]
 * @param {StringMaybe} [props.titleDetail]
 * @param {StringMaybe} [props.titleMain]
 * @returns {StatelessComponent}
 */
const UserAvatarProfile = ({ member, subtitle, titleDetail, titleMain }) => {
  const someTitleExists = or(!!titleMain, !!titleDetail);
  // we need a bottom margin for the title if there is some title on top and a subtitle on the bottom
  const needBottomMarginForTitle = and(someTitleExists, !!subtitle);

  return (
    <Row>
      <Avatar
        {...member}
        className="display--flex display--flex-align-center"
        sizeClass="avatar--m"
        styleClass="avatar--light"
      />
      <FlexCol className="margin-left--m justify-content--center">
        <Row
          className={classNames({
            'margin-bottom--sm': needBottomMarginForTitle,
          })}
        >
          {!!titleMain && (
            <>
              <Text.Bold
                color={typography.TextColor.GREY_X_DARK}
                lineHeight="22px"
                size={typography.TextSize.LEVEL_200}
              >
                {titleMain}
              </Text.Bold>

              <NonBlockingSpace />
            </>
          )}

          {!!titleDetail && (
            <Text.Regular
              color={typography.TextColor.GREY_X_DARK}
              lineHeight={typography.TextLineHeight.PARAGRAPH}
              size={typography.TextSize.LEVEL_200}
            >
              {titleDetail}
            </Text.Regular>
          )}
        </Row>

        {!!subtitle && (
          <Text.Regular
            className="margin-bottom--sm"
            color={typography.TextColor.DEFAULT}
            lineHeight={typography.TextLineHeight.PARAGRAPH}
            size={typography.TextSize.LEVEL_200}
          >
            {subtitle}
          </Text.Regular>
        )}
      </FlexCol>
    </Row>
  );
};

UserAvatarProfile.propTypes = {
  member: PropTypes.shape().isRequired,
  subtitle: PropTypes.string,
  titleDetail: PropTypes.string,
  titleMain: PropTypes.string,
};

UserAvatarProfile.defaultProps = {
  subtitle: undefined,
  titleDetail: undefined,
  titleMain: undefined,
};

export default UserAvatarProfile;

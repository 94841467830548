import PropTypes from 'prop-types';
import React from 'react';

import { AddContactPopoverSelectButton } from 'complexComponents/AddContactPopoverSelectButton';

import PermissionResourceAction from 'constants/permissions';

import { WithPermissionRestriction } from 'modules/auth';

import { RestrictedAddContactButton } from './components';

/**
 * Gated AddContactButtonTarget. Toggles between a static button and the popover select button
 * @param {ComponentProps} props
 * @param {ReactNode} props.children
 * @param {boolean} props.isDisabled
 * @param {function} props.onClick
 * @param {Partnership} props.partnership
 * @returns {StatelessComponent}
 */
const GatedAddContactButton = (props) => {
  const { isDisabled, children, partnership } = props;

  return (
    <WithPermissionRestriction
      requiredPermissions={[PermissionResourceAction.VENDOR_EDIT, PermissionResourceAction.CUSTOMER_EDIT]}
      WithoutPermissionComponent={<RestrictedAddContactButton>{children}</RestrictedAddContactButton>}
      WithPermissionComponent={
        <AddContactPopoverSelectButton isDisabled={isDisabled} partnership={partnership} {...props} />
      }
    />
  );
};

GatedAddContactButton.propTypes = {
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  partnership: PropTypes.shape({}).isRequired,
};

GatedAddContactButton.defaultProps = {
  children: undefined,
  isDisabled: undefined,
};

export default GatedAddContactButton;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { itemBlockLongTextCharLimit } from 'components/itemBlock/constants/lengths';
import { TooltipIcon } from 'components/tooltip';

import { colors, sizes } from 'constants/styles';

import { isStringLengthGreaterThanLimit } from 'helpers/stringHelpers';

const ItemDetailBlock = ({ icon, linkTo, location, onClose, text, tooltipText }) => (
  <div className="item-block--detail-block" data-fullstory>
    <TooltipIcon
      content={tooltipText}
      icon={icon}
      iconClassName={classNames({
        'flex-shrink--zero': true,
        'align-icon-long-text': isStringLengthGreaterThanLimit(text, itemBlockLongTextCharLimit),
      })}
      iconColor={colors.colorMainJordan}
      iconProps={{
        size: sizes.iconSizes.SMALL,
        marginRight: sizes.spacing.EXTRA_MEDIUM,
      }}
      tooltipProps={{
        statelessProps: { className: 'tooltip--item-block' },
      }}
    />

    {linkTo && (
      <p className="font-xs semibold remove-margin word-wrap--break-word">
        <Link
          className="primary"
          onClick={onClose}
          to={{
            pathname: linkTo,
            state: { previous: location.pathname },
          }}
        >
          {text}
        </Link>
      </p>
    )}

    {!linkTo && <p className="font-xs font-color--greyXDark semibold remove-margin word-wrap--break-word">{text}</p>}
  </div>
);

ItemDetailBlock.propTypes = {
  icon: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
  location: PropTypes.shape(),
  onClose: PropTypes.func,
  text: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
};

ItemDetailBlock.defaultProps = {
  linkTo: undefined,
  location: undefined,
};

export default ItemDetailBlock;

import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';

import getRelationships from 'store/redux';

import { FETCH_PARTNERSHIP_SUCCESS } from 'types/partnership';

const getBrandsById = (brands) => {
  if (!brands) {
    return {};
  }

  const brandList = {};

  Object.keys(brands).forEach((brandId) => {
    const relationships = getRelationships(brands, brandId);

    brandList[brandId] = {
      id: brands[brandId].id,
      ...brands[brandId].attributes,
      ...relationships,
    };
  });

  return brandList;
};

const byIdReducer = (state = {}, action) => {
  switch (action.type) {
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
    case FETCH_PARTNERSHIP_SUCCESS:
      return getBrandsById(action.payload.companyBrand);

    default:
      return state;
  }
};

export default byIdReducer;

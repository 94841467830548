import PropTypes from 'prop-types';
import React from 'react';
import { v4 } from 'uuid';

import FlexCol from 'components/layout/FlexCol';
import FlexRow from 'components/layout/FlexRow';

import { getClassNames } from 'helpers/ui';

/**
 * A grouping of subtitles used within the TitleWithSubtitle. The subtitles are presented as a flex-column, with each
 * subtitle rendered as a flex-row.
 *
 * @param {[]|Node[]} [subtitles]
 * @param {RestOfProps} rest
 * @returns {StatelessComponent}
 * @constructor
 */
const SubtitleBlock = ({ subtitles, ...rest }) => {
  const keyRef = React.useRef(v4());

  return (
    <FlexCol {...rest}>
      {subtitles.map((subtitle, i) => (
        <FlexRow
          className={getClassNames(
            rest,
            {
              'align-items--center': true,
              'margin-top--sm-alt': true,
            },
            { classProp: 'rowClassName' },
          )}
          /* eslint-disable-next-line react/no-array-index-key */
          key={`${keyRef.current}-${i}`}
          stackOnMobile={false}
        >
          {subtitle}
        </FlexRow>
      ))}
    </FlexCol>
  );
};

SubtitleBlock.propTypes = {
  subtitles: PropTypes.arrayOf(PropTypes.node),
};

SubtitleBlock.defaultProps = {
  subtitles: [],
};

export default SubtitleBlock;

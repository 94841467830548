import { createIsCreatingReducer } from 'store/redux';

import { CREATE_API_TOKEN_FAILURE, CREATE_API_TOKEN_REQUEST, CREATE_API_TOKEN_SUCCESS } from 'types/apiToken';

export const finishCases = [CREATE_API_TOKEN_FAILURE, CREATE_API_TOKEN_SUCCESS];
export const requestCases = [CREATE_API_TOKEN_REQUEST];

const isCreatingReducer = createIsCreatingReducer(finishCases, requestCases);

export default isCreatingReducer;

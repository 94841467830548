import { createSelector } from 'reselect';

/**
 * Top level dashboard loaded selector
 * @param state
 * @return {obj}
 */
export const getDashboardLoadedSelector = (state) => state.dashboardLoaded;

/**
 * Returns a boolean depicting if all load dashboard calls are done
 * @param {object} state - Redux state
 */
export const isDashboardFullyLoadedSelector = createSelector([getDashboardLoadedSelector], (dashboardLoaded) =>
  Object.values(dashboardLoaded).every(Boolean),
);

import React from 'react';

import { ShowItemAmountCell } from 'complexComponents';

import { getOriginal } from 'helpers/tables';

/**
 * Component rendering Amount field in External Items table
 * @param {ComponentProps} props
 * @param {Object} props.row
 * @returns {StatelessComponent}
 */
const ExternalItemsListTableAmount = ({ row }) => (
  <ShowItemAmountCell dataKey="amount" rowData={getOriginal({ row })} />
);

export default ExternalItemsListTableAmount;

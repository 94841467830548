import PropTypes from 'prop-types';

export const flexProps = {
  alignContent: PropTypes.string,
  alignItems: PropTypes.string,
  flexWrap: PropTypes.string,
  justifyContent: PropTypes.string,
};

export const flexDefaults = {
  alignContent: undefined,
  alignItems: undefined,
  flexWrap: 'no-wrap',
  justifyContent: undefined,
};

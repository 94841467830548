import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';
import { IconNames } from 'components/icon';

import { sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

import TableCellTextWithSubtext from '../TableCellTextWithSubtext';

/**
 * Attach image cell variant
 * @param {Function} onClick
 * @param {String} [text]
 * @return {StatelessComponent}
 */
const AttachImageCell = ({ onClick, text }) => (
  <>
    <TableCellTextWithSubtext text={text} />

    <div className="right-indicator">
      <ButtonV2
        className="btn--icon"
        intent={Intent.NEUTRAL}
        leftIconName={IconNames.PAPERCLIP}
        leftIconSize={sizes.iconSizes.LARGE}
        onClick={onClick}
      />
    </div>
  </>
);

AttachImageCell.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
};

AttachImageCell.defaultProps = {
  text: undefined,
};

export default AttachImageCell;

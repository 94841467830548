import { updateMembershipRoutine } from 'actions/routines/membership';

import {
  FETCH_MEMBERSHIP_SUCCESS,
  UPDATE_MEMBERSHIP_SUCCESS,
  UPDATE_ONBOARDING_MEMBERSHIP_SUCCESS,
} from 'types/memberships';

const getAllMembershipPersonalInfos = (personalInfos) => {
  if (!personalInfos) {
    return [];
  }

  const personalInfoList = { ...personalInfos };

  return Object.keys(personalInfoList);
};

const allReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_MEMBERSHIP_SUCCESS:
    case UPDATE_MEMBERSHIP_SUCCESS:
    case updateMembershipRoutine.SUCCESS:
    case UPDATE_ONBOARDING_MEMBERSHIP_SUCCESS:
      return Array.from(new Set([...state, ...getAllMembershipPersonalInfos(action.payload.personalInfo)]));

    default:
      return state;
  }
};

export default allReducer;

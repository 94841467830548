import React from 'react';

import { LinkNewPage, WhiteSpace } from 'components';

import { CONTACT_SUPPORT_LOWERCASE_LABEL } from 'constants/labels';
import { PLATFORM_EMAILS } from 'constants/platform';

import { getMailtoUrl } from 'helpers/urls';

import ExternalErrorHint from '../ExternalErrorHint';

/**
 * Renders Max payment methods reached hint used in external
 * workflow
 * @return {StatelessComponent}
 */
const MaxPaymentMethodsReachedHint = () => (
  <ExternalErrorHint hintTitle="You've reached the maximum amount of payment methods">
    Please
    <WhiteSpace />
    <LinkNewPage href={getMailtoUrl(PLATFORM_EMAILS.CHAT)}>{CONTACT_SUPPORT_LOWERCASE_LABEL}</LinkNewPage>
    <WhiteSpace />
    to remove an existing account.
  </ExternalErrorHint>
);

export default MaxPaymentMethodsReachedHint;

import { StaticCountryCode } from 'constants/countries';

export const isSSNRequired = (personalInfo) => {
  if (!personalInfo) {
    return false;
  }

  if (personalInfo.passportCountry !== StaticCountryCode.US) {
    return false;
  }

  return true;
};

export const canSubmitRepresentativeForm = (params) => {
  const { formUI, personalInfo, verificationDocumentUpdateAllowed, verificationUpdateAllowed } = params;

  if (!verificationUpdateAllowed && !verificationDocumentUpdateAllowed) {
    return false;
  }

  if (!formUI.shouldShowSSNField && isSSNRequired(personalInfo)) {
    return false;
  }

  return true;
};

/**
 * Check React state to allow editing on CompanyRep form
 * @param {boolean} agreeIdentity
 * @param {boolean} agreeBusinessRepresentative
 * @return {boolean}
 */
export const canConfirmRepresentativeIdentity = (agreeIdentity, agreeBusinessRepresentative) =>
  agreeIdentity && agreeBusinessRepresentative;

/**
 * Selects the isFetching for integration configs from the state
 * @param {object} state - Redux state
 */
export const isFetchingIntegrationConfigsSelector = (state) => state.integrationConfigs.isFetching;

/**
 * Selects all the integration configs from the state
 * @param {object} state - Redux state
 */
export const integrationConfigsSelector = (state) => state.integrationConfigs.byId;

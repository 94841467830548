import { submitCreatePartnershipRoutine, updatePartnershipGeneralInfoRoutine } from 'actions/routines/partnership';

import { createErrorReducer } from 'store/redux';

import * as types from 'types/partnership';

export const failureCases = [
  types.FETCH_PARTNERSHIP_FAILURE,
  submitCreatePartnershipRoutine.FAILURE,
  updatePartnershipGeneralInfoRoutine.FAILURE,
];

export const successCases = [
  types.CLEAR_PARTNERSHIP,
  types.FETCH_PARTNERSHIP_REQUEST,
  types.FETCH_PARTNERSHIP_SUCCESS,
  submitCreatePartnershipRoutine.REQUEST,
  submitCreatePartnershipRoutine.SUCCESS,
  updatePartnershipGeneralInfoRoutine.SUCCESS,
];

export default createErrorReducer(successCases, failureCases);

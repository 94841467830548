import React from 'react';

import { text } from 'components/hintTypes/constants';
import { LinkNewPage } from 'components/link';

import { Intent } from 'constants/ui';

import helpDocs from 'helpers/helpDocs';

import BaseHint from '../BaseHint';

const AboutNegativeBalanceHint = () => (
  <BaseHint
    intent={Intent.DANGER}
    multiline
    text={
      <span>
        {`${text.aboutNegativeBalanceHint.textBeforeLink} `}
        <LinkNewPage
          className="font-sm semibold font-color--primary force-underline"
          href={helpDocs.BALANCE_TRANSACTIONS_WHAT_DOES_NEGATIVE_MEAN}
        >
          Learn more
        </LinkNewPage>
        {` ${text.aboutNegativeBalanceHint.textAfterLink}`}
      </span>
    }
    title={text.aboutNegativeBalanceHint.title}
  />
);

export default AboutNegativeBalanceHint;

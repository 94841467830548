export var ButtonAppearance;
(function (ButtonAppearance) {
    ButtonAppearance["DEFAULT"] = "default";
    ButtonAppearance["BORDERLESS"] = "borderless";
})(ButtonAppearance || (ButtonAppearance = {}));
export var ButtonSize;
(function (ButtonSize) {
    ButtonSize["SMALL"] = "small";
    ButtonSize["MEDIUM"] = "medium";
    ButtonSize["LARGE"] = "large";
})(ButtonSize || (ButtonSize = {}));
export var ButtonType;
(function (ButtonType) {
    ButtonType["CANCEL"] = "cancel";
    ButtonType["CONFIRM"] = "confirm";
    ButtonType["PRIMARY"] = "primary";
})(ButtonType || (ButtonType = {}));
export var ButtonText;
(function (ButtonText) {
    ButtonText["CANCEL"] = "Cancel";
    ButtonText["CLOSE"] = "Close";
    ButtonText["CONFIRM"] = "Confirm";
    ButtonText["CONTACT_SUPPORT"] = "Contact support";
})(ButtonText || (ButtonText = {}));

import React from 'react';
import { ContactTitleWithSubtitle } from 'complexComponents/ContactTitleWithSubtitle';
import {} from 'constants/ui';
import { isContactsInteractionListViewTypeThread } from 'helpers/ui';
import { useContactsInteractionListViewContext, useExistingItemContext } from 'hooks';
import { ContactListItemTitleTypeThread } from './components';
const ContactListItemTitle = ({ contact }) => {
    const item = useExistingItemContext();
    const view = useContactsInteractionListViewContext();
    if (isContactsInteractionListViewTypeThread(view)) {
        return React.createElement(ContactListItemTitleTypeThread, { dataFullStory: true, item: item, partnershipMember: contact });
    }
    return React.createElement(ContactTitleWithSubtitle, { dataFullStory: true, isContactListItem: true, partnershipMember: contact });
};
export default ContactListItemTitle;

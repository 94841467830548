import PropTypes from 'prop-types';
import React from 'react';

import { isFn, and } from 'helpers/utility';

import { ContactListItemMenuPopover } from './components';

/**
 * Menu for a ContactListItem.
 * @param {Function} props.onRemoveContact
 * @param {Function} props.onSetActionableContact
 * @param {Function} props.onSetReadOnlyContact
 * @param {Function} props.onUpdateContact
 * @param {ItemMember} props.contact
 * @return {StatelessComponent}
 */
const ContactListItemMenu = (props) => {
  const { onRemoveContact, onSetActionableContact, onSetReadOnlyContact, onUpdateContact, contact } = props;

  return (
    <ContactListItemMenuPopover
      onRemove={() => {
        onRemoveContact(contact);
      }}
      onSetActionable={() => {
        onSetActionableContact(contact);
      }}
      onSetReadOnly={() => {
        onSetReadOnlyContact(contact);
      }}
      onUpdate={() => {
        onUpdateContact(contact);
      }}
      showRemove={isFn(onRemoveContact)}
      showSetAccess={and(isFn(onSetActionableContact), isFn(onSetReadOnlyContact))}
      {...contact}
    />
  );
};

ContactListItemMenu.propTypes = {
  onRemoveContact: PropTypes.func,
  onSetActionableContact: PropTypes.func,
  onSetReadOnlyContact: PropTypes.func,
  onUpdateContact: PropTypes.func,
};

ContactListItemMenu.defaultProps = {
  onRemoveContact: undefined,
  onSetActionableContact: undefined,
  onSetReadOnlyContact: undefined,
  onUpdateContact: undefined,
};

export default ContactListItemMenu;

import { TinTypes } from 'constants/taxes';
import { UserTypes } from 'constants/user';

import { isBusinessTypeSoleProprietor, isCurrentCompanyTypePersonal } from 'helpers/currentCompany';
import { valueOrEmptyString, valueOrFalse, valueOrUndefined } from 'helpers/forms';
import { isTinTypeEin, isTinTypeSsn } from 'helpers/taxes';
import { valueOrDefault } from 'helpers/utility';

/**
 * Derive the type of TIN based on the company type and certain business type information.
 * @param {Company} company
 * @param {Object} companyInfo
 * @returns {string}
 */
export const getInitialTinTypeFromMetadata = (company, companyInfo) => {
  // all personal companies use SSN
  if (isCurrentCompanyTypePersonal(company)) {
    return TinTypes.SSN;
  }

  // businesses
  if (isBusinessTypeSoleProprietor(companyInfo.businessType) && isTinTypeSsn(companyInfo.tinType)) {
    return TinTypes.SSN;
  }

  return TinTypes.EIN;
};

/**
 * Initial editability in the form. For example, if the user hasn't already entered a first name or last name (they
 * haven't onboarded), let them edit the field. Many of these UI flags account for missing data.
 * @param {object} currentCompany
 * @param {object} currentCompanyInfo
 * @param {object} currentUser
 * @returns {object}
 */

export const getInitialUIState = ({ currentCompany, currentCompanyInfo, currentUser }) => {
  const { isTosAgree, name } = currentCompany;
  const { tin, tinType } = currentCompanyInfo;
  const { firstName, lastName } = currentUser;

  const hasTin = !!tin;
  const hasEin = hasTin && isTinTypeEin(tinType);
  const hasSsn = hasTin && isTinTypeSsn(tinType);

  return {
    editFirstName: !firstName,
    editLastName: !lastName,
    editLegalBusinessName: !name,
    hasEin,
    hasSsn,
    needsTos: !isTosAgree,
    noEin: hasSsn,
    tinType: getInitialTinTypeFromMetadata(currentCompany, currentCompanyInfo),
  };
};

/**
 * Gather pieces from state to initialize the tax collection state.
 * @param {object} params
 * @returns {object}
 */
export const getCollectTaxFormInitialState = (params) => {
  const {
    currentCompany = {},
    currentCompanyInfo = {},
    currentCompanyMailingAddress = {},
    currentMembership = {},
    currentUser = {},
  } = params;

  return {
    form: {
      company: {
        companyType: valueOrDefault(currentCompany.companyType, UserTypes.BUSINESS),
        name: valueOrEmptyString(currentCompany.name),
        id: valueOrUndefined(currentCompany.id),
        info: {
          businessType: valueOrEmptyString(currentCompanyInfo.businessType),
          tin: valueOrEmptyString(currentCompanyInfo.tin),
          tinType: getInitialTinTypeFromMetadata(currentCompany, currentCompanyInfo),
          id: valueOrUndefined(currentCompanyInfo.id),
        },
        mailingAddress: {
          city: valueOrEmptyString(currentCompanyMailingAddress.city),
          country: valueOrEmptyString(currentCompanyMailingAddress.country),
          id: valueOrUndefined(currentCompanyMailingAddress.id),
          postalcode: valueOrEmptyString(currentCompanyMailingAddress.postalcode),
          state: valueOrEmptyString(currentCompanyMailingAddress.state),
          streetAddress: valueOrEmptyString(currentCompanyMailingAddress.streetAddress),
          streetAddressUnit: valueOrEmptyString(currentCompanyMailingAddress.streetAddressUnit),
        },
      },
      membership: {
        id: valueOrUndefined(currentMembership.id),
        user: {
          firstName: valueOrEmptyString(currentUser.firstName),
          id: valueOrUndefined(currentUser.id),
          lastName: valueOrEmptyString(currentUser.lastName),
        },
      },
      meta: {
        tosAgree: valueOrFalse(currentMembership.tosAgree),
      },
    },
    ui: getInitialUIState({ currentCompany, currentCompanyInfo, currentUser }),
  };
};

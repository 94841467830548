import React from 'react';
import { hasLength } from 'helpers/utility';
import { AddBankAccountManuallyButton } from 'modules/fundingAccount';
import { AcceptPendingPaymentsCheckboxField, UpdatePaymentMethodFundingAccounts, UpdatePaymentFlowFormLabel, } from './components';
import {} from './UpdatePaymentMethodInternationalLayout.types';
const UpdatePaymentMethodInternationalLayout = ({ fundingAccounts, isUpdatePaymentMethodFlow, }) => {
    const hasFundingAccounts = hasLength(fundingAccounts);
    if (isUpdatePaymentMethodFlow && hasFundingAccounts) {
        return (React.createElement(React.Fragment, null,
            React.createElement(UpdatePaymentFlowFormLabel, null),
            React.createElement(AddBankAccountManuallyButton, null),
            React.createElement(UpdatePaymentFlowFormLabel, { labelText: "Select preferred payment method" }),
            React.createElement(UpdatePaymentMethodFundingAccounts, { fundingAccounts: fundingAccounts }),
            React.createElement(AcceptPendingPaymentsCheckboxField, null)));
    }
    if (hasFundingAccounts) {
        return (React.createElement(React.Fragment, null,
            React.createElement(UpdatePaymentMethodFundingAccounts, { fundingAccounts: fundingAccounts }),
            React.createElement(AcceptPendingPaymentsCheckboxField, null),
            React.createElement(UpdatePaymentFlowFormLabel, null),
            React.createElement(AddBankAccountManuallyButton, null)));
    }
    return React.createElement(AddBankAccountManuallyButton, null);
};
export default UpdatePaymentMethodInternationalLayout;

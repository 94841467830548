import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

/**
 * Function which returns the react-select Input component with added maxLength prop
 * @see {TagsSidePanel} for an example usage
 *
 * @param {NumberMaybe} maxLength
 * @return {Function}
 */
const MaxLengthInput = (maxLength) => (props) => <components.Input {...props} maxLength={maxLength} />;

MaxLengthInput.propTypes = {
  maxLength: PropTypes.number.isRequired,
};

export default MaxLengthInput;

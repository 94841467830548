import pluralize from 'pluralize';
import React from 'react';
import { LinkNewPage } from 'components/link';
import { Text } from 'components/text';
import { TooltipMUIConditionalWrapper } from 'components/tooltip';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { typography } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';
import helpDocs from 'helpers/helpDocs';
import { listAllElementsInArray } from 'helpers/stringHelpers';
import { lengthOf } from 'helpers/utility';
const CurrencyNotSupportedByPlatformTooltip = ({ children, currencies, isShown, partnershipType, placement = TooltipPlacement.RIGHT, }) => {
    const currenciesText = listAllElementsInArray(currencies);
    const currencyOrCurrencies = pluralize('currency', lengthOf(currencies));
    return (React.createElement(TooltipMUIConditionalWrapper, { tooltipProps: isShown
            ? {
                arrow: true,
                padding: TooltipPadding.SKINNY,
                placement,
                title: (React.createElement(Text.Regular, { color: typography.TextColor.GREY_X_DARK, lineHeight: typography.TextLineHeight.MEDIUM, size: typography.TextSize.LEVEL_100 },
                    `${PLATFORM_DISPLAY_SHORT_NAME} doesn't yet support this ${partnershipType}'s ${currencyOrCurrencies} (${currenciesText}). `,
                    React.createElement(LinkNewPage, { className: "primary", href: helpDocs.PARTNER_COUNTRY_CURRENCY_HINT }, "Learn more about supported countries and currencies \u2197"))),
            }
            : {} }, children));
};
export default CurrencyNotSupportedByPlatformTooltip;

import styled from 'styled-components';
export const GroupContainer = styled.div `
  & > * {
    &:focus {
      z-index: 2;
    }
    &:active {
      z-index: 2;
    }
    &[data-active] {
      z-index: 2;
    }
  }

  & > :first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > :not(:first-child):not(:last-child) {
    border-radius: 0;
    margin-left: -1px;
  }

  & > :last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
  }
`;

import React from 'react';
import { Text } from 'components/text';
import { TooltipMUIConditionalWrapper } from 'components/tooltip';
import { typography } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';
import {} from './LimitedMergingToBaseCurrencyTooltip.types';
const LimitedMergingToBaseCurrencyTooltip = ({ children, isShown, ledger, partnershipType, }) => (React.createElement(TooltipMUIConditionalWrapper, { tooltipProps: isShown
        ? {
            arrow: true,
            padding: TooltipPadding.SKINNY,
            placement: TooltipPlacement.RIGHT,
            title: (React.createElement(Text.Regular, { color: typography.TextColor.GREY_X_DARK, lineHeight: typography.TextLineHeight.MEDIUM, size: typography.TextSize.LEVEL_100 }, `Your account settings only allow you to merge ${partnershipType}s associated with ${ledger.baseCurrencyCode} in your ${ledger.name} account.`)),
        }
        : {} }, children));
export default LimitedMergingToBaseCurrencyTooltip;

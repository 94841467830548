import PropTypes from 'prop-types';
import React from 'react';

import EditButtonInput from 'components/form/EditButtonInput';

import { formStyles } from 'constants/styles';

import { requiredValidator } from 'helpers/fieldValidation';

/**
 * Shows fields which may/may not be edited for Legal First and Last Names.
 * @param {ComponentProps} props
 * @param {boolean} props.editFirstName - If the field is in edit mode
 * @param {boolean} props.editLastName
 * @param {boolean} props.isDisabled
 * @param {function} props.onEditFirstName - Dispatch change to open the input for edits
 * @param {function} props.onEditLastName
 * @returns {StatelessComponent}
 */
const ConfirmFullName = ({ editFirstName, editLastName, isDisabled, onEditFirstName, onEditLastName }) => {
  const commonProps = {
    editAllowed: !isDisabled,
    isDisabled,
    showField: true,
    validate: [requiredValidator],
  };

  return (
    <React.Fragment>
      <EditButtonInput
        {...commonProps}
        editField={editFirstName}
        fieldClassNames={formStyles.field.xl.left}
        name="form.membership.user.firstName"
        onEdit={onEditFirstName}
        placeholder="Legal First Name"
      />

      <EditButtonInput
        {...commonProps}
        editField={editLastName}
        fieldClassNames={formStyles.field.xl.right}
        name="form.membership.user.lastName"
        onEdit={onEditLastName}
        placeholder="Legal Last Name"
      />
    </React.Fragment>
  );
};

ConfirmFullName.propTypes = {
  editFirstName: PropTypes.bool,
  editLastName: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onEditFirstName: PropTypes.func.isRequired,
  onEditLastName: PropTypes.func.isRequired,
};

ConfirmFullName.defaultProps = {
  editFirstName: undefined,
  editLastName: undefined,
  isDisabled: undefined,
};

export default ConfirmFullName;

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { FormControl, LockedCountryInput, ReduxFormFieldRenderInput, SelectFieldV2 } from 'components';

import { field } from 'constants/styles/formStyles';

import { mapCountryInfosToSelectOptions } from 'helpers/countries';
import { requiredValidator } from 'helpers/fieldValidation';

import { useCountryCodesContext } from 'hooks';

import { getFieldClassNames } from './helpers/ui';

/**
 * Component rendering Internation address form
 * @param {Object} props
 * @param {String} props.addressPath
 * @param {Boolean} [props.isCountryEditable]
 * @param {String} [props.lockedCountryReason]
 * @returns {StatelessComponent}
 */
const InternationalAddressForm = ({ addressPath, lockedCountryReason, isCountryEditable, ...rest }) => {
  const countries = useCountryCodesContext();
  const countriesOptions = mapCountryInfosToSelectOptions(Object.values(countries));

  return (
    <>
      <FormControl>
        {isCountryEditable ? (
          <Field
            className={getFieldClassNames(rest, field.xl.full)}
            component={SelectFieldV2}
            label="Country"
            name={`${addressPath}.country`}
            options={countriesOptions}
            validate={requiredValidator}
          />
        ) : (
          <LockedCountryInput
            className={getFieldClassNames(rest, field.xl.full)}
            name={`${addressPath}.country`}
            reason={lockedCountryReason}
          />
        )}
      </FormControl>

      <FormControl>
        <Field
          className={getFieldClassNames(rest, field.xl.full)}
          component={ReduxFormFieldRenderInput}
          name={`${addressPath}.streetAddress`}
          placeholder="Street, Apartment, Suite number, or Rural route"
          type="text"
          validate={requiredValidator}
        />
      </FormControl>

      <FormControl>
        <Field
          className={getFieldClassNames(rest, field.xl.full)}
          component={ReduxFormFieldRenderInput}
          name={`${addressPath}.city`}
          placeholder="City"
          type="text"
          validate={requiredValidator}
        />
      </FormControl>

      <FormControl>
        <Field
          className={getFieldClassNames(rest, field.xl.left)}
          component={ReduxFormFieldRenderInput}
          name={`${addressPath}.state`}
          placeholder="State, Province, or Territory"
          type="text"
          validate={requiredValidator}
        />

        <Field
          className={getFieldClassNames(rest, field.xl.right)}
          component={ReduxFormFieldRenderInput}
          name={`${addressPath}.postalcode`}
          placeholder="Postal code"
          type="text"
          validate={requiredValidator}
        />
      </FormControl>
    </>
  );
};

InternationalAddressForm.propTypes = {
  addressPath: PropTypes.string.isRequired,
  isCountryEditable: PropTypes.bool,
  lockedCountryReason: PropTypes.string,
};

InternationalAddressForm.defaultProps = {
  lockedCountryReason: undefined,
};

export default InternationalAddressForm;

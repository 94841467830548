import PropTypes from 'prop-types';
import React from 'react';

import { TableCellText } from 'complexComponents';

import { getObjDate } from 'helpers/date';

const InvoiceListDate = ({ dataKey, rowData }) => (
  <TableCellText>{getObjDate(rowData, dataKey, 'MMM DD, YYYY') || '--'}</TableCellText>
);

InvoiceListDate.propTypes = {
  dataKey: PropTypes.string.isRequired,
  rowData: PropTypes.shape().isRequired,
};

export default InvoiceListDate;

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { IconNames } from 'components/icon';
// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { ItemDetailBlock } from 'components/itemBlock';

import { itemSelector } from 'selectors/itemsSelectors';

const ItemBlockMemo = ({ item }) => {
  if (!item?.extended?.memo) {
    return null;
  }

  return <ItemDetailBlock icon={IconNames.CITATION} text={item.extended.memo} tooltipText="Memo" />;
};

ItemBlockMemo.propTypes = {
  item: PropTypes.shape(),
};

ItemBlockMemo.defaultProps = {
  item: {},
};

const mapStateToProps = (state, ownProps) => ({
  item: ownProps?.item ?? itemSelector(state, ownProps.match.params.id),
});

const enhance = compose(withRouter, connect(mapStateToProps));

export { ItemBlockMemo };
export default enhance(ItemBlockMemo);

import { FetchService } from 'services';
import { getItemTransactionsEndpoint } from 'services/api/itemEndpoints';
import { getTransactionsEndpoint } from 'services/api/transactionsEndpoints';

/**
 * Fetch transactions data.
 * @param {Object} queryParams
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchTransactions = async (queryParams, options = {}) => {
  const config = {
    ...options,
    endpoint: getTransactionsEndpoint(queryParams),
    method: 'GET',
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * Fetch transactions data for a given item.
 * @param {String} itemId
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchItemTransactions = async (itemId, options = {}) => {
  const config = {
    ...options,
    endpoint: getItemTransactionsEndpoint(itemId),
    method: 'GET',
    requireAuth: true,
  };
  return FetchService.request(config);
};

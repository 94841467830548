import { Environment } from 'constants/env';

const getBaseWebsiteUrl = (location = window.location) => {
  const { host, protocol } = location;
  return `${protocol}//${host}`;
};

const getEnvironment = () => {
  const { REACT_APP_API_SUB_DOMAIN } = process.env;

  if (REACT_APP_API_SUB_DOMAIN) {
    if (REACT_APP_API_SUB_DOMAIN === Environment.STAGING) {
      return Environment.STAGING;
    }

    if (REACT_APP_API_SUB_DOMAIN === Environment.SANDBOX) {
      return Environment.SANDBOX;
    }

    return Environment.DEVELOPMENT;
  }

  return Environment.PRODUCTION;
};

const BASE_API_URL = process.env.API_URL;
const BASE_WEBSITE_URL = getBaseWebsiteUrl();
const S3_URL = 'https://s3-us-west-2.amazonaws.com/routable';

export { BASE_API_URL, BASE_WEBSITE_URL, S3_URL, getBaseWebsiteUrl, getEnvironment };

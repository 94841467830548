import PropTypes from 'prop-types';
import React from 'react';
import { v4 } from 'uuid';

import { ZIndexLayers } from 'constants/ui';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { getFieldErrors } from 'helpers/errors';
import { hasZeroLength, isArray } from 'helpers/utility';
import { getZIndex } from 'helpers/zIndex';

/**
 * Errors can either be passed in as props in the for of an array or object
 * @param props
 * @return {*}
 */
const FormFieldErrors = ({ errors, fieldName }) => {
  if (!errors) {
    return null;
  }

  let currentFormFieldErrors = errors;

  // Standardize error for redux form and old error structure
  if (typeof errors === 'object' && !Array.isArray(errors)) {
    // Old errors structure
    currentFormFieldErrors = getFieldErrors(errors, fieldName);
  }

  if (!currentFormFieldErrors || !isArray(currentFormFieldErrors) || hasZeroLength(currentFormFieldErrors)) {
    return null;
  }

  return (
    <div
      className="form-error-container"
      data-testid="formError"
      style={{ zIndex: getZIndex(ZIndexLayers.ABOVE_BASE) }}
    >
      {currentFormFieldErrors.length === 1 ? (
        <p className="remove-margin-bottom">{currentFormFieldErrors[0]}</p>
      ) : (
        currentFormFieldErrors.map((element) => (
          <p key={v4()}>
            {'• '}
            {element}
          </p>
        ))
      )}
    </div>
  );
};

FormFieldErrors.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.node, PropTypes.bool, PropTypes.shape(), PropTypes.string]),
  fieldName: PropTypes.string.isRequired,
};

FormFieldErrors.defaultProps = {
  errors: {},
};

export default FormFieldErrors;

import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { createInternationalFundingAccountHandler } from 'actions/routines/funding';
import { closeSidePanel } from 'actions/sidePanels';
import { formNamesFunding } from 'constants/forms';
import { sidePanelNameManuallyAddBankAccount } from 'constants/sidePanels';
import {} from 'interfaces/global';
import { makeFormSubmitFailHandler } from 'modules/createPartnerCompany/helpers/submitFailure';
import { companyFromQuerySelector } from 'queries/companyCompoundSelectors';
import { fundingAccountsForCompanyIdQuerySelector, manuallyAddBankAccountFormInitialValuesSelector, } from 'queries/fundingCompoundSelectors';
import { itemCurrencyCodeReceiverFromQuerySelector } from 'queries/itemRouterSelectors';
import { partnershipFromCurrentPartnershipRequestSelector, partnershipPartnerCompanyFromPartnershipRequestSelector, } from 'queries/partnershipCompoundSelectors';
import { byCurrencySelector } from 'selectors/currenciesSelectors';
import { manuallyAddBankAccountFormIsValidSelector } from 'selectors/forms';
import { fundingAccountBankManualErrorsSelector } from 'selectors/fundingSelectors';
import { partnershipRequestFundingCurrencyCodesSelector } from 'selectors/partnershipRequestSelectors';
import { createSidePanelSelector } from 'selectors/sidePanelsSelector';
import { createMergeProps } from 'store/redux';
import ManuallyAddBankAccountSideSheet from './ManuallyAddBankAccountSideSheet';
import {} from './ManuallyAddBankAccountSideSheet.types';
const formName = formNamesFunding.ADD_MANUAL_BANK;
const manuallyAddBankAccountSidePanelSelector = createSidePanelSelector(sidePanelNameManuallyAddBankAccount);
export const mapStateToProps = createStructuredSelector({
    company: companyFromQuerySelector,
    currencyCodeMap: byCurrencySelector,
    errors: fundingAccountBankManualErrorsSelector,
    fundingAccounts: fundingAccountsForCompanyIdQuerySelector,
    initialValues: manuallyAddBankAccountFormInitialValuesSelector,
    isFormValid: manuallyAddBankAccountFormIsValidSelector,
    itemCurrencyCodeReceiver: itemCurrencyCodeReceiverFromQuerySelector,
    partnerCompany: partnershipPartnerCompanyFromPartnershipRequestSelector,
    partnership: partnershipFromCurrentPartnershipRequestSelector,
    partnershipCurrencies: partnershipRequestFundingCurrencyCodesSelector,
    sidePanel: manuallyAddBankAccountSidePanelSelector,
});
export const mapDispatchToProps = {
    onCloseSidePanel: closeSidePanel,
};
export const mergeProps = createMergeProps(({ itemCurrencyCodeReceiver, partnershipCurrencies }, { onCloseSidePanel }) => ({
    onClose: () => onCloseSidePanel({ name: sidePanelNameManuallyAddBankAccount }),
    availableCurrencies: itemCurrencyCodeReceiver ? [itemCurrencyCodeReceiver] : partnershipCurrencies || [],
}));
const createManuallyAddBankAccountForm = reduxForm({
    destroyOnUnmount: true,
    form: formName,
    onSubmit: createInternationalFundingAccountHandler,
    onSubmitFail: makeFormSubmitFailHandler(formName),
})(ManuallyAddBankAccountSideSheet);
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(createManuallyAddBankAccountForm);

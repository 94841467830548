import * as types from 'types/item';

const linksReducer = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_ADDITIONAL_INVOICES_SUCCESS:
    case types.FETCH_INVOICES_SUCCESS:
      return action.payload.links || {};

    default:
      return state;
  }
};

export default linksReducer;

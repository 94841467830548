import PropTypes from 'prop-types';
import React from 'react';

import { BaseHint } from 'components/hintTypes';
import { FlexRow } from 'components/layout';
import UserAvatarProfile from 'components/UserAvatarProfile';

import { getClassNames } from 'helpers/ui';
import { anyValues } from 'helpers/utility';

import { getUserProfileStripTitleSubtitles } from './helpers/text';

import './UserProfileStrip.scss';

/**
 * Profile strip displays Avatar along with full name, email, and role.
 * @param {Object} props
 * @param {Membership} props.member
 * @param {Object} props.hintProps
 * @param {RestOfProps} props.rest
 * @returns StatelessComponent
 */
const UserProfileStrip = ({ member, hintProps, ...rest }) => {
  const { subtitle, titleDetail, titleMain } = getUserProfileStripTitleSubtitles(member);

  return (
    <FlexRow
      className={getClassNames(rest, {
        'user-profile-strip': true,
      })}
    >
      <UserAvatarProfile member={member} subtitle={subtitle} titleDetail={titleDetail} titleMain={titleMain} />

      {anyValues(hintProps) && (
        <div className="margin-left--large">
          <BaseHint {...hintProps} />
        </div>
      )}
    </FlexRow>
  );
};

UserProfileStrip.propTypes = {
  hintProps: PropTypes.shape({
    content: PropTypes.node,
    contentProps: PropTypes.shape(),
    intent: PropTypes.string,
    text: PropTypes.node,
    textProps: PropTypes.shape(),
    title: PropTypes.node,
    titleProps: PropTypes.shape(),
  }),
  member: PropTypes.shape({
    avatar: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};

UserProfileStrip.defaultProps = {
  hintProps: undefined,
};

export default UserProfileStrip;

import PropTypes from 'prop-types';
import React from 'react';

import { and } from 'helpers/utility';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import AddFileInput from './AddFileInput';

/**
 * Container which determines if the AddFileInput should be disabled and wrapped with a tooltip.
 *
 * @param {ComponentProps} props
 * @param {boolean} [props.isDisabledDueToPermissions]
 * @param {AnyComponent} [props.IsDisabledDueToPermissionsWrapper]
 * @param {RestOfProps} props.rest
 * @returns {StatelessComponent}
 */
const AddFileInputContainer = (props) => {
  const { isDisabledDueToPermissions, IsDisabledDueToPermissionsWrapper } = props;
  const shouldTooltip = and(isDisabledDueToPermissions, IsDisabledDueToPermissionsWrapper);

  if (shouldTooltip) {
    return (
      <IsDisabledDueToPermissionsWrapper>
        <AddFileInput {...props} />
      </IsDisabledDueToPermissionsWrapper>
    );
  }

  return <AddFileInput {...props} />;
};

AddFileInputContainer.propTypes = {
  dataTestId: PropTypes.string,
  isDisabledDueToPermissions: PropTypes.bool,
  IsDisabledDueToPermissionsWrapper: PropTypes.func,
};

AddFileInputContainer.defaultProps = {
  dataTestId: undefined,
  isDisabledDueToPermissions: undefined,
  IsDisabledDueToPermissionsWrapper: undefined,
};

export default AddFileInputContainer;

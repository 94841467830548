import ThunkFetch from 'helpers/thunkFetch';

import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { onboardingUpdateMembershipEndpoint } from 'services/api/onboardingEndpoints';

import {
  UPDATE_ONBOARDING_MEMBERSHIP_FAILURE,
  UPDATE_ONBOARDING_MEMBERSHIP_REQUEST,
  UPDATE_ONBOARDING_MEMBERSHIP_SUCCESS,
} from 'types/memberships';

const updateOnboardingMembership = (updatePayload, successCallback = undefined) => {
  const t = new ThunkFetch({
    actions: {
      error: UPDATE_ONBOARDING_MEMBERSHIP_FAILURE,
      request: UPDATE_ONBOARDING_MEMBERSHIP_REQUEST,
      success: UPDATE_ONBOARDING_MEMBERSHIP_SUCCESS,
    },
    endpoint: onboardingUpdateMembershipEndpoint,
    method: 'PATCH',
    payload: payloadToJSONAPI(updatePayload, 'membership', 'user'),
    requireAuth: true,
    successCallback,
  });

  return t.makeRequest();
};

export default updateOnboardingMembership;

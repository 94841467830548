import React from 'react';
import { ButtonV2 } from 'components';
import { TooltipPlacement } from 'constants/tooltip';
import { Intent } from 'constants/ui';
import { getPartnershipPermissionResourceForEditAction } from 'helpers/partnerships';
import ConditionalRestrictedPermissionTooltip from 'modules/auth/ConditionalRestrictedPermissionTooltip';
import WithPermissionRestriction from 'modules/auth/WithPermissionRestriction';
import {} from './CompanyContactsTableAddContactButton.types';
const CompanyContactsTableAddContactButton = ({ onClick, partnership, }) => {
    const permissionForEditAction = getPartnershipPermissionResourceForEditAction(partnership);
    const linkLabel = 'Add a contact';
    return (React.createElement(WithPermissionRestriction, { requiredPermissions: [permissionForEditAction], WithoutPermissionComponent: React.createElement(ConditionalRestrictedPermissionTooltip, { customization: { placement: TooltipPlacement.BOTTOM }, useTooltip: true },
            React.createElement(ButtonV2, { intent: Intent.NEUTRAL, isDisabled: true }, linkLabel)), WithPermissionComponent: React.createElement(ButtonV2, { intent: Intent.NEUTRAL, onClick: onClick }, linkLabel) }));
};
export default CompanyContactsTableAddContactButton;

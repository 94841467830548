import * as routines from 'actions/routines/currencies';

import { referenceCurrency } from 'constants/relationships';

import { createByIdReducer } from 'store/redux';

const byCurrencyReducer = createByIdReducer({
  key: referenceCurrency,
  types: [routines.fetchCurrenciesRoutine.SUCCESS],
  initialState: {},
});

export default byCurrencyReducer;

import React from 'react';

import { ConfirmationSectionHeader } from 'components/confirmation';
import { FormFieldDescription, FormFieldLabel, FormSection } from 'components/form';

import FilingDetailsDisclosureList from 'modules/external/ExternalCollectTaxInfo/components/FilingDetailsDisclosureList';
import MailingAddressDisclosureList from 'modules/external/ExternalCollectTaxInfo/components/MailingAddressDisclosureList';
import {
  filingDetailsDefaultProps,
  filingDetailsSelectorPropTypes,
} from 'modules/external/ExternalCollectTaxInfo/helpers/customPropTypes';
import ExternalPromptHeader from 'modules/external/externalPrompt/global/presenters/ExternalPromptHeader';
import ExternalBody from 'modules/external/global/presenters/ExternalBody';

/**
 * On successful submission of tax information, vendors are directed to this page and shown a summary of their
 * mailing address and filing details. In the future, we could also show them items which need action.
 * @returns {StatelessComponent}
 * @constructor
 */
const CollectTaxSuccessPage = ({ filingDetails }) => (
  <ExternalBody>
    <div className="external-tax--full-width-page">
      <ConfirmationSectionHeader />

      <ExternalPromptHeader text="Thank you for confirming your end-of-year tax information for your Form 1099" />

      <FormSection>
        <FormFieldLabel className="has-description margin-top--x-large">What&apos;s next?</FormFieldLabel>

        <FormFieldDescription>
          {`${filingDetails.partnerCompanyName} will now be able to provide you your tax documents. If you have any questions or need to 
          update this information, please don't hesitate to contact support.`}
        </FormFieldDescription>
      </FormSection>

      <FilingDetailsDisclosureList
        {...filingDetails}
        className="margin-top--m-large"
        showGenericTin
        viewCompletionDate
      />

      <MailingAddressDisclosureList
        address={filingDetails.mailingAddress}
        className="margin-top--m-large margin-bottom--m-large"
      />
    </div>
  </ExternalBody>
);

CollectTaxSuccessPage.propTypes = {
  filingDetails: filingDetailsSelectorPropTypes(),
};

CollectTaxSuccessPage.defaultProps = {
  filingDetails: filingDetailsDefaultProps,
};

export default CollectTaxSuccessPage;

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { fundingAccountFromUpdatePaymentMethodFormSelector } from 'queries/currentWorkflowStepSelector';
import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

import ApplyToFuturePendingPaymentsCheckboxField from './ApplyToFuturePendingPaymentsCheckboxField';

export const mapStateToProps = createStructuredSelector({
  fundingAccount: fundingAccountFromUpdatePaymentMethodFormSelector,
  partnership: partnershipFromQuerySelector,
});

export default connect(mapStateToProps)(ApplyToFuturePendingPaymentsCheckboxField);

import React from 'react';
import { Field, Form } from 'redux-form';
import { changePasswordHandler } from 'actions/routines/auth';
import { FormControl, IconNames, ReduxFormFieldRenderInput, ButtonV2, BoxV2 } from 'components';
import { ButtonSize } from 'constants/button';
import { formNamesAuthV2 } from 'constants/forms';
import { formStyles, sizes } from 'constants/styles';
import { hasAuthToken } from 'helpers/auth';
import { getPasswordMatchingValidator, requiredValidator } from 'helpers/fieldValidation';
import {} from './ResetPasswordComplete.types';
const passwordMatchingValidator = getPasswordMatchingValidator(['newPassword', 'newPasswordConfirm']);
const ResetPasswordComplete = ({ creatingNewPassword, handleSubmit, isSubmitting, onLogoutCleanup, }) => {
    React.useEffect(() => {
        if (hasAuthToken()) {
            onLogoutCleanup();
        }
    }, [onLogoutCleanup]);
    return (React.createElement(BoxV2, { className: "box-v2--container--reset-password", title: creatingNewPassword ? 'Create your password' : 'Reset your password' },
        React.createElement(Form, { className: "form", id: formNamesAuthV2.RESET_PASSWORD_COMPLETE, onSubmit: handleSubmit(changePasswordHandler) },
            React.createElement(FormControl, null,
                React.createElement(Field, { className: formStyles.field.xl.full, component: ReduxFormFieldRenderInput, dataFullStory: true, name: "form.newPassword", placeholder: "New password", type: "password", validate: [requiredValidator, passwordMatchingValidator] })),
            React.createElement(FormControl, null,
                React.createElement(Field, { className: formStyles.field.xl.full, component: ReduxFormFieldRenderInput, dataFullStory: true, name: "form.newPasswordConfirm", placeholder: "Confirm new password", type: "password", validate: [requiredValidator, passwordMatchingValidator] })),
            React.createElement(FormControl, { className: "remove-margin-bottom" },
                React.createElement(ButtonV2, { htmlFor: formNamesAuthV2.RESET_PASSWORD_COMPLETE, id: "auth_newPassword_btn", isLoading: isSubmitting, rightIconName: IconNames.ARROW_RIGHT, rightIconProps: { style: { marginLeft: 'auto' } }, rightIconSize: sizes.iconSizes.LARGE, size: ButtonSize.LARGE, type: "submit" }, "Save my new password")))));
};
export default ResetPasswordComplete;

import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2, IconNames } from 'components';

import { ButtonSize } from 'constants/button';
import { sizes } from 'constants/styles';

const ConfirmTaxForMultiplePartnershipsButton = ({ onClick, partnerCompanyName }) => (
  <ButtonV2
    className="width--100-percent"
    leftIconClassName="margin-right--xm"
    leftIconName={IconNames.TICK}
    leftIconSize={sizes.iconSizes.EXTRA_LARGE}
    onClick={onClick}
    size={ButtonSize.MEDIUM}
  >
    {`Confirm for ${partnerCompanyName}`}
  </ButtonV2>
);

ConfirmTaxForMultiplePartnershipsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  partnerCompanyName: PropTypes.string.isRequired,
};

export default ConfirmTaxForMultiplePartnershipsButton;

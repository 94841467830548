import { createSelector } from 'reselect';

import { idSelector } from 'selectors/globalSelectors';
import { currentMembershipIdSelector, membershipsByIdSelector } from 'selectors/membershipsSelector';

/**
 * Top level memberships personal info selector
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {object}
 */
export const getMembershipPersonalInfosSelector = (state) => state.membershipPersonalInfo;

/**
 * Selects the membership personal infos from the state
 * @param {object} state - Redux state
 * @return {object}
 */
export const membershipPersonalInfosByIdSelector = createSelector(
  [getMembershipPersonalInfosSelector],
  (membershipPersonalInfo) => membershipPersonalInfo.byId,
);

/**
 * Selects a specific membership personal info the state
 * @param {object} state - Redux state
 */
export const membershipPersonalInfoSelector = createSelector(
  [membershipPersonalInfosByIdSelector, idSelector],
  (personalInfos, personalInfoId) => personalInfos[personalInfoId],
);

/**
 * Selects the current membership's personal info
 * @param {object} state - Redux state
 */
export const currentMembershipPersonalInfoSelector = createSelector(
  [membershipsByIdSelector, membershipPersonalInfosByIdSelector, currentMembershipIdSelector],
  (memberships, membershipPersonalInfos, membershipId) => {
    const currentMembership = memberships[membershipId];

    if (!currentMembership?.personalInfo) {
      return {};
    }

    return membershipPersonalInfos[currentMembership.personalInfo] || {};
  },
);

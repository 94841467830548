import { FEATURE_SETTING_NAME_PREFIX } from 'constants/featureSettings';

import { dashTextToCamelCase, uncapitalize } from './stringHelpers';
import { isString } from './utility';

/**
 * Formats a feature setting name. E.g. any of the following--
 *
 * FEATURE_SETTING_NEW_THING
 * feature_setting_new_thing
 * featureSettingNewThing
 * newThing
 *
 * -- will be formatted to simply 'newThing';
 * @param {string} settingName
 * @return {string}
 */
export const getFormattedFeatureSettingName = (settingName) => {
  const prefixes = [FEATURE_SETTING_NAME_PREFIX, FEATURE_SETTING_NAME_PREFIX.toLowerCase(), 'featureSetting'];

  if (isString(settingName)) {
    const foundPrefix = prefixes.find((prefix) => settingName.includes(prefix));

    if (foundPrefix) {
      const trimmedSetting = settingName.replace(foundPrefix, '');

      if (trimmedSetting.includes('_')) {
        return dashTextToCamelCase(trimmedSetting.toLowerCase());
      }

      return uncapitalize(trimmedSetting);
    }

    return dashTextToCamelCase(settingName);
  }

  return settingName;
};

import { createSelector } from 'reselect';

import { itemFromLocationSelector, itemFromParamSelector } from 'queries/itemRouterSelectors';

import { itemSelector } from 'selectors/itemsSelectors';
import { partnershipsByIdSelector } from 'selectors/partnershipsSelectors';

/**
 * Selects a specific item from items by id with partner name
 * @param {object} state - Redux state
 * @param {string} itemId - Item id
 */
export const itemWithPartnerNameSelector = createSelector(
  [itemSelector, partnershipsByIdSelector],
  (item, partnerships) => {
    if (!item) {
      return undefined;
    }

    const partnership = partnerships[item.partnership];

    return {
      ...item,
      name: partnership.name,
    };
  },
);

/**
 * Selects a specific partnership from item that is passed in as route parameter
 * i.e. on a thread page the itemId is accessible via the route, grab it and then get it's associated partnership
 * @param {object} state - Redux state
 */
export const partnershipFromItemParamSelector = createSelector(
  [itemFromParamSelector, partnershipsByIdSelector],
  (item, partnerships) => {
    if (!item) {
      return undefined;
    }

    return partnerships[item.partnership];
  },
);

/**
 * Selects a specific partnership from item location path
 * i.e. on a item details page, the item id is accessible via the URL location, by looking
 * at the second to last path segment (url is structured as follows: .../<item_id>/item, or .../<item_id>/edit)
 * @param {Object} state - Redux state
 */
export const partnershipFromItemLocationSelector = createSelector(
  [itemFromLocationSelector, partnershipsByIdSelector],
  (item, partnerships) => {
    if (!item) {
      return undefined;
    }

    return partnerships[item.partnership];
  },
);

import { IconNames } from 'components/icon';
import { PaymentMethodStatusType } from 'modules/fundingAccount/PaymentMethod/enums';
export const PaymentMethodStatusMap = {
    [PaymentMethodStatusType.PRIMARY]: {
        icon: IconNames.STAR,
        text: 'Primary',
        theme: 'primary',
    },
    [PaymentMethodStatusType.ACTIVE]: {
        icon: IconNames.TICK_CIRCLE,
        text: 'Active',
        theme: 'active',
    },
    [PaymentMethodStatusType.PENDING_VERIFICATION]: {
        icon: IconNames.BADGE,
        text: 'Pending verification',
        theme: 'warning',
    },
    [PaymentMethodStatusType.PENDING_MICRO_DEPOSITS]: {
        icon: IconNames.BADGE,
        text: 'Verify this account',
        theme: 'warning',
    },
    [PaymentMethodStatusType.ERROR]: {
        icon: IconNames.ERROR,
        text: 'Failed',
        theme: 'error',
    },
    [PaymentMethodStatusType.DISABLED]: {
        icon: IconNames.DISABLE,
        text: 'Disabled',
        theme: 'disabled',
    },
};

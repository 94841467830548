import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { ButtonV2 } from 'components/buttonV2';
import { text } from 'components/hintTypes/constants';
import { IconNames } from 'components/icon';

import { ButtonSize } from 'constants/button';
import { DASHBOARD, GUIDE } from 'constants/routes';
import { sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

import { getJoinedPath } from 'helpers/routeHelpers';

import BaseHint from '../BaseHint';

const NotVerifiedForTransactionsHint = ({ className }) => (
  <BaseHint
    className={classNames({
      'padding-bottom--m': true,
      [className]: !!className,
    })}
    content={
      <Link className="text-decoration--none" to={getJoinedPath(DASHBOARD, GUIDE)}>
        <ButtonV2
          className="margin-top--m"
          leftIconClassName="margin-right--xm"
          leftIconName={IconNames.TICK_CIRCLE}
          leftIconSize={sizes.iconSizes.EXTRA_MEDIUM}
          rightIconClassName="margin-left--large"
          rightIconName={IconNames.ARROW_RIGHT}
          rightIconSize={sizes.iconSizes.EXTRA_MEDIUM}
          size={ButtonSize.SMALL}
        >
          {text.notVerifiedForTransactionsHint.verifyButton}
        </ButtonV2>
      </Link>
    }
    intent={Intent.WARNING}
    text={text.notVerifiedForTransactionsHint.text}
    title={text.notVerifiedForTransactionsHint.title}
  />
);

NotVerifiedForTransactionsHint.propTypes = {
  className: PropTypes.string,
};

NotVerifiedForTransactionsHint.defaultProps = {
  className: undefined,
};

export default NotVerifiedForTransactionsHint;

import React from 'react';
import { ButtonV2, FlexRow } from 'components';
import { ButtonAppearance } from 'constants/button';
import { DataCardDetail } from './components';
import './DataCard.scss';
const DataCard = ({ buttonProps, buttonText, entries }) => (React.createElement("div", { className: "data-card" },
    React.createElement(FlexRow, { className: "data-card__entries", stackOnMobile: false }, entries.map((entry) => (React.createElement(DataCardDetail, { key: entry.label, ...entry })))),
    buttonText && (React.createElement("div", { className: "data-card__footer" },
        React.createElement(ButtonV2, { ...buttonProps, appearance: ButtonAppearance.BORDERLESS, className: "font-sm--important font-color--blue-bold--important" }, buttonText)))));
export default DataCard;

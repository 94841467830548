import React, {} from 'react';
import { IconNames } from 'components/icon';
import { colors, sizes } from 'constants/styles';
import { PaginationBlockButton } from '../PaginationBlockButton';
export const PaginationBlockRefreshButton = ({ isSearchExecuted, isDisabled, onClick, ...rest }) => {
    if (isSearchExecuted) {
        return null;
    }
    return (React.createElement(PaginationBlockButton, { isDisabled: isDisabled, leftIconColor: colors.colorDarkSilverHex, leftIconName: IconNames.REPEAT, leftIconSize: sizes.iconSizes.LARGE, onClick: onClick, ...rest }));
};

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { externalPaymentGetCurrentCompanyRequest } from 'actions/currentCompany';
import { externalOnboardingChangeStep } from 'actions/externalOnboarding';
import { fetchFundingAccountsRequest } from 'actions/funding';
import { fetchSingleItemRequest } from 'actions/item';
import { fetchSingleMembershipRequest } from 'actions/memberships';
import { fetchPartnershipItemsRequest, fetchSinglePartnershipRequest } from 'actions/partnership';
import { fetchPermissionsRoutine } from 'actions/routines/permissions';
import { fetchRolesRoutine } from 'actions/routines/roles';

import { CornerDialogItemAlreadyAccepted, CornerDialogPartnerAlreadyAccepted } from 'complexComponents';

import BrandFooter from 'components/footer/BrandFooter';

import { ItemKinds } from 'constants/item';

import { ItemStatuses } from 'enums/items';

import { getPartnershipTypeFromExternalPath, isExternalFlowStepInitial, isExternalPathAccept } from 'helpers/external';
import { oneOfValuesFromObject } from 'helpers/propTypes';

import ExternalNeedVendorFlowAlert from 'modules/external/ExternalNeedVendorFlowAlert';
import { isFetchingRequirementsSelector } from 'modules/external/externalPayment/selectors';
import ExternalHeader from 'modules/external/global/components/header/ExternalHeader';
import External from 'modules/external/global/presenters/External';
import ExternalFooter from 'modules/external/global/presenters/ExternalFooter';
import ExternalOnboardingForm from 'modules/external/global/presenters/ExternalOnboardingForm';
import ExternalPaymentBody from 'modules/external/global/presenters/ExternalPaymentBody';
import { IsLoadingHasWrapper } from 'modules/isLoading/IsLoading';

import { partnershipItemsFromQuerySelector } from 'queries/itemCompoundSelectors';
import { itemFromQuerySelector } from 'queries/itemRouterSelectors';
import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

import { currentCompanyPlatformTypeSelector } from 'selectors/currentCompanySelectors';
import { externalOnboardingStepSelector } from 'selectors/externalOnboardingSelectors';
import { itemIdQuerySelector, membershipIdQuerySelector, partnershipIdQuerySelector } from 'selectors/routerSelectors';

import { uiHelpers } from './helpers';

// The component doesn't see these state items being used because their logic has been moved to the uiHelpers
/* eslint-disable react/no-unused-state */

/**
 * Fetches items needed to process an item or partnership invitation.
 * @returns {ClassComponent}
 */
class ExternalPaymentContainer extends React.Component {
  state = {
    hasShownItemCornerDialogOnce: false,
    hasShownPartnerCornerDialogOnce: false,
    isItemCornerDialogShown: false,
    isPartnerCornerDialogShown: false,
    originalCurrentCompanyPlatformType: undefined,
    originalItemStatus: undefined,
  };

  componentDidMount() {
    const {
      itemId,
      onFetchCurrentCompany,
      onFetchFundingAccounts,
      onFetchMembership,
      onFetchPartnership,
      onFetchPermissionsAndPermissionGroups,
      onFetchRoles,
      membershipId,
      partnershipId,
    } = this.props;

    onFetchCurrentCompany();
    onFetchFundingAccounts();
    onFetchMembership(membershipId);
    onFetchPartnership(partnershipId);
    onFetchPermissionsAndPermissionGroups();
    onFetchRoles();

    // Fetch item first if it exists, then fetch the other partnership items
    // If no item given, fetch the partnership items
    if (itemId) {
      // Fetch single item
      this.fetchSingleItem(itemId, partnershipId);
    } else {
      this.fetchPartnershipItems(partnershipId);
    }
  }

  componentDidUpdate() {
    // when the item and/or current company loads for the first time, save their status and platformType
    uiHelpers.updateOriginalModels(
      this.state,
      this.props,
      this.setOriginalItemStatus.bind(this),
      this.setOriginalCurrentCompanyPlatformType.bind(this),
    );
    // show partner dialog if partnership has already been accepted
    uiHelpers.evaluatePartnershipCornerDialogOnComponentDidUpdate(
      this.state,
      this.props,
      this.showPartnershipCornerDialog.bind(this),
    );
    // show item dialog if item has already been accepted
    uiHelpers.evaluateItemCornerDialogOnComponentDidUpdate(
      this.state,
      this.props,
      this.showItemCornerDialog.bind(this),
    );
  }

  fetchSingleItem = (itemId, partnershipId) => {
    const { onFetchItem } = this.props;
    onFetchItem(itemId);
    this.fetchPartnershipItems(partnershipId);
  };

  fetchPartnershipItems = (partnershipId) => {
    const { location, onFetchPartnershipItems } = this.props;

    // Fetch partnership items
    const options = {
      kind: isExternalPathAccept(location) ? ItemKinds.RECEIVABLE : ItemKinds.PAYABLE,
    };

    onFetchPartnershipItems({ partnershipId, ...options });
  };

  handleStart = () => {
    const { onChangeStep } = this.props;
    onChangeStep(1);
  };

  handleCancel = () => {
    const { onChangeStep } = this.props;
    onChangeStep(0);
  };

  setOriginalItemStatus() {
    const {
      item: { status },
    } = this.props;
    this.setState({ originalItemStatus: status });
  }

  setOriginalCurrentCompanyPlatformType() {
    const { currentCompanyPlatformType } = this.props;
    this.setState({
      originalCurrentCompanyPlatformType: currentCompanyPlatformType,
    });
  }

  showItemCornerDialog() {
    this.setState({
      hasShownItemCornerDialogOnce: true,
      isItemCornerDialogShown: true,
    });
  }

  showPartnershipCornerDialog() {
    this.setState({
      hasShownPartnerCornerDialogOnce: true,
      isPartnerCornerDialogShown: true,
    });
  }

  render() {
    const { currentStepIndex, isFetchingRequirements, item, itemId, partnership } = this.props;
    const { isItemCornerDialogShown, isPartnerCornerDialogShown } = this.state;

    const isInitialStep = isExternalFlowStepInitial(currentStepIndex);

    // Loading state for fetch calls
    if (!partnership || isFetchingRequirements) {
      return <IsLoadingHasWrapper />;
    }

    return (
      <>
        <ExternalNeedVendorFlowAlert />
        <External>
          <ExternalHeader />
          {isInitialStep ? (
            <ExternalPaymentBody
              isFetchingRequirements={isFetchingRequirements}
              itemId={itemId}
              onCancel={this.handleCancel}
              onStart={this.handleStart}
              partnership={partnership}
            />
          ) : (
            <ExternalOnboardingForm onCancel={this.handleCancel} />
          )}

          {isInitialStep && (
            <ExternalFooter>
              <BrandFooter hideSupport showSecure />
            </ExternalFooter>
          )}
        </External>

        {item && (
          <CornerDialogItemAlreadyAccepted
            isShown={isItemCornerDialogShown}
            item={item}
            onClose={() => this.setState({ isItemCornerDialogShown: false })}
            partnershipName={partnership.name}
          />
        )}

        <CornerDialogPartnerAlreadyAccepted
          isShown={isPartnerCornerDialogShown}
          onClose={() => this.setState({ isPartnerCornerDialogShown: false })}
          partnershipType={getPartnershipTypeFromExternalPath(window.location)}
        />
      </>
    );
  }
}

ExternalPaymentContainer.propTypes = {
  // use in previousProps, which the linter doesn't account for
  currentCompanyPlatformType: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  currentStepIndex: PropTypes.number.isRequired,
  isFetchingRequirements: PropTypes.bool,
  item: PropTypes.shape({ status: oneOfValuesFromObject(ItemStatuses) }),
  itemId: PropTypes.string,
  location: PropTypes.shape().isRequired, // eslint-disable-line react/no-unused-prop-types
  membershipId: PropTypes.string.isRequired,
  onChangeStep: PropTypes.func.isRequired,
  onFetchCurrentCompany: PropTypes.func.isRequired,
  onFetchFundingAccounts: PropTypes.func.isRequired,
  onFetchItem: PropTypes.func.isRequired,
  onFetchMembership: PropTypes.func.isRequired,
  onFetchPartnership: PropTypes.func.isRequired,
  onFetchPartnershipItems: PropTypes.func.isRequired,
  onFetchRoles: PropTypes.func.isRequired,
  onFetchPermissionsAndPermissionGroups: PropTypes.func.isRequired,
  partnership: PropTypes.shape({ name: PropTypes.string }),
  partnershipId: PropTypes.string.isRequired,
};

ExternalPaymentContainer.defaultProps = {
  currentCompanyPlatformType: undefined,
  isFetchingRequirements: undefined,
  item: undefined,
  itemId: undefined,
  partnership: undefined,
};

const mapStateToProps = createStructuredSelector({
  currentCompanyPlatformType: currentCompanyPlatformTypeSelector,
  currentStepIndex: externalOnboardingStepSelector,
  isFetchingRequirements: isFetchingRequirementsSelector,
  item: itemFromQuerySelector,
  itemId: itemIdQuerySelector,
  membershipId: membershipIdQuerySelector,
  partnership: partnershipFromQuerySelector,
  partnershipId: partnershipIdQuerySelector,
  partnershipItems: partnershipItemsFromQuerySelector,
});

const mapDispatchToProps = {
  onChangeStep: externalOnboardingChangeStep,
  onFetchCurrentCompany: externalPaymentGetCurrentCompanyRequest,
  onFetchFundingAccounts: fetchFundingAccountsRequest,
  onFetchItem: fetchSingleItemRequest,
  onFetchMembership: fetchSingleMembershipRequest,
  onFetchPartnership: fetchSinglePartnershipRequest,
  onFetchPartnershipItems: fetchPartnershipItemsRequest,
  onFetchPermissionsAndPermissionGroups: fetchPermissionsRoutine,
  onFetchRoles: fetchRolesRoutine,
};

export { ExternalPaymentContainer };
export default connect(mapStateToProps, mapDispatchToProps)(ExternalPaymentContainer);

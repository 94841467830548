import { FundingAccountTypes } from 'constants/funding';

export const getInitialValues = (createCheckSource) => ({
  form: {
    bankAccountNumber: '',
    bankAccountType: FundingAccountTypes.CHECKING,
    bankRoutingNumber: '',
    confirmBankAccountNumber: '',
    createCheckSource,
  },
  ui: {
    bankInstitutionName: '',
  },
});

import PropTypes from 'prop-types';
import React from 'react';

import { getClassNames } from 'helpers/ui';
import { callIfIsFn } from 'helpers/utility';

/**
 * A button to add a new contact from the ContactsInteractionBar.
 * @param {boolean} [hideAddContactButton] - Manual override to hide the button
 * @param {function} renderBarButtons
 * @param {RestOfProps} rest
 * @returns {StatelessComponent}
 * @constructor
 */
const ContactsInteractionBarAddContactButton = ({ hideAddContactButton, renderBarButtons, ...rest }) => (
  // if we want to hide the contact button manually or there is no onAddContact handler, make the button
  // visibility--hidden. Note: we're still rendering the button to keep the InteractionBar height the same, we're just
  // making the button visibility--hidden.
  <div
    className={getClassNames(rest, {
      'visibility--hidden': hideAddContactButton,
    })}
  >
    {callIfIsFn(renderBarButtons)}
  </div>
);

ContactsInteractionBarAddContactButton.propTypes = {
  hideAddContactButton: PropTypes.bool,
  renderBarButtons: PropTypes.func,
};

ContactsInteractionBarAddContactButton.defaultProps = {
  hideAddContactButton: undefined,
  renderBarButtons: undefined,
};

export default ContactsInteractionBarAddContactButton;

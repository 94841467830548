import { toastController } from '@routable/gross-ds';
import React from 'react';
const addToast = (type) => (message, { description, duration, ...options } = {}) => {
    toastController.addToast({
        message: description ? (React.createElement("div", { className: "flex-col justify-start" },
            React.createElement("span", { className: "font-semibold" }, message),
            React.createElement("span", { className: "" }, description))) : (message),
        type,
        timeout: duration,
        ...options,
    });
    return { title: message, duration };
};
export const toaster = {
    notify: addToast('info'),
    success: addToast('success'),
    warning: addToast('warning'),
    danger: addToast('critical'),
    closeAll: () => {
        toastController.clearToasts();
    },
};

import { PaymentDeliveryMethodType } from '@routable/shared';
import PropTypes from 'prop-types';
import React from 'react';

import { PartnershipTypes } from 'constants/partnership';

import { getPartnerBankAccountViewTypeForPaymentMethod, getShowSuccessUI } from 'helpers/ui';

import { text } from 'modules/fundingAccount/partnerPaymentMethod/constants';
import { componentHelpers } from 'modules/fundingAccount/partnerPaymentMethod/helpers';

const showUpdatePrimaryReceivableSuccessUI = getShowSuccessUI(text.primaryAccountUpdatedMessage);

/**
 * Returns a component class that renders the given component.
 * Adds props to said component that allow it to:
 * 1. Manage the display/order of partner funding accounts
 * 2. Update the primary funding account for a vendor
 * 3. Manage funding account selection inside of a redux form
 * 4. Launch the view-account-details modal for an account
 * @param {React.ComponentClass|React.FunctionComponent} Component
 * @return {React.Component}
 */
const withPartnerFundingAccounts = (Component) => {
  class ComponentWithPartnerFundingAccounts extends React.Component {
    state = {
      isShowingAllAccounts: this.props.accountsDisplayMax === -1,
      sortedFundingAccounts: [],
      hasSetInitialPartnershipFundingAccount: false,
    };

    setAccountsDisplayOrder = () => {
      const sortedFundingAccounts = componentHelpers.getAccountsDisplayOrder(this.props);
      this.setState({ sortedFundingAccounts });
    };

    updateDisplayOrderIfNeeded = (props = {}, prevProps = {}) => {
      // keep primary funding account first, preferred funding account second
      const shouldUpdateSortOrder = componentHelpers.shouldUpdateAccountsDisplayOrder(prevProps, props, this.state);

      if (shouldUpdateSortOrder) {
        this.setAccountsDisplayOrder();
      }
    };

    updateSelectedFundingAccountIfNeeded = (props = {}, prevProps = {}) => {
      const { partnershipFundingAccounts: prevFundingAccounts } = prevProps;
      const { input, partnershipFundingAccounts, isItemEdit, partnerFundingAccount } = props;
      const { onChange, value } = input;

      // first compare the current partnershipFundingAccounts array with
      // previous one; if we've just added an account, we'll update the new
      // account to be currently selected.
      let selectedFundingAccount = componentHelpers.getNewlyAddedFundingAccount(
        prevFundingAccounts,
        partnershipFundingAccounts,
      );

      // We need to do this to fill up partnershipFundingAccount on form values item and form initial item
      const needsToPrePopulateItemEdit =
        isItemEdit &&
        !selectedFundingAccount &&
        partnerFundingAccount &&
        !this.state.hasSetInitialPartnershipFundingAccount;
      if (needsToPrePopulateItemEdit) {
        this.setState({ hasSetInitialPartnershipFundingAccount: true });
        selectedFundingAccount = partnershipFundingAccounts.find(
          (partnershipFundingAccount) => partnershipFundingAccount.fundingAccount === partnerFundingAccount,
        )?.id;
      }

      // if we didn't just add a new partnership funding account, fallback
      // to the best-fit for selection
      if (!selectedFundingAccount) {
        selectedFundingAccount = componentHelpers.getSelectedFundingAccount(props);
      }

      if (selectedFundingAccount !== value) {
        onChange(selectedFundingAccount);
      }
    };

    updatePrimaryReceivableSuccessCallback = (parsedResponse, partnershipFundingAccountId) => {
      const {
        onClearSendPartnershipUpdatePaymentMethodUrlStatus,
        onUpdatePartnershipPrimaryReceivableFundingAccount,
        partnership,
      } = this.props;
      onUpdatePartnershipPrimaryReceivableFundingAccount(partnershipFundingAccountId);
      // re-allow sending of update-primary-payment-method links
      onClearSendPartnershipUpdatePaymentMethodUrlStatus(partnership.id);
      showUpdatePrimaryReceivableSuccessUI();
      return parsedResponse;
    };

    handleSelectChangeToPrimary = (partnershipFundingAccount, partnership) => {
      const { onSubmitPartnershipPrimaryReceivableFundingAccount } = this.props;

      const payload = componentHelpers.getChangeToPrimaryFundingAccountPayload(partnershipFundingAccount);

      onSubmitPartnershipPrimaryReceivableFundingAccount(payload, partnership.id, partnershipFundingAccount.id, {
        successCallback: (resp) => this.updatePrimaryReceivableSuccessCallback(resp, partnershipFundingAccount.id),
      });
    };

    handleSelectViewAccountDetails = (partnershipFundingAccount, partnership) => {
      const { onOpenBankAccountDetailsModal } = this.props;

      const viewAccountDetails = componentHelpers.getViewAccountDetails(this.props, { partnershipFundingAccount });

      const { fundingInfoAddress, fundingInfoBankAccount } = viewAccountDetails;

      const viewType = getPartnerBankAccountViewTypeForPaymentMethod(PaymentDeliveryMethodType.ACH);

      onOpenBankAccountDetailsModal({
        fundingAccount: partnershipFundingAccount,
        fundingInfoAddress,
        fundingInfoBankAccount,
        partnership,
        viewType,
      });
    };

    toggleIsShowingAll = () => {
      const { isShowingAllAccounts } = this.state;
      this.setState({ isShowingAllAccounts: !isShowingAllAccounts });
    };

    render() {
      // noinspection JSUnusedLocalSymbols
      const {
        onOpenBankAccountDetailsModal,
        onSubmitPartnershipPrimaryReceivableFundingAccount,
        onUpdatePartnershipPrimaryReceivableFundingAccount,
        ...rest
      } = this.props;

      const { sortedFundingAccounts } = this.state;

      return (
        <Component
          {...rest}
          onSelectChangeToPrimary={this.handleSelectChangeToPrimary}
          onSelectViewAccountDetail={this.handleSelectViewAccountDetails}
          onShowAllAccounts={this.toggleIsShowingAll}
          setAccountsDisplayOrder={this.setAccountsDisplayOrder}
          sortedFundingAccounts={sortedFundingAccounts}
          updateDisplayOrderIfNeeded={this.updateDisplayOrderIfNeeded}
          updateSelectedFundingAccountIfNeeded={this.updateSelectedFundingAccountIfNeeded}
        />
      );
    }
  }

  ComponentWithPartnerFundingAccounts.propTypes = {
    accountsDisplayMax: PropTypes.number,
    isItemEdit: PropTypes.bool,
    fundingAccountsById: PropTypes.shape().isRequired,
    fundingInfoAddressesById: PropTypes.shape().isRequired,
    fundingInfoBankAccounts: PropTypes.shape().isRequired,
    onChange: PropTypes.func,
    onClearSendPartnershipUpdatePaymentMethodUrlStatus: PropTypes.func.isRequired,
    onOpenBankAccountDetailsModal: PropTypes.func.isRequired,
    onSubmitPartnershipPrimaryReceivableFundingAccount: PropTypes.func.isRequired,
    onUpdatePartnershipPrimaryReceivableFundingAccount: PropTypes.func.isRequired,
    partnerFundingAccount: PropTypes.string,
    partnership: PropTypes.shape().isRequired,
    partnershipFundingAccounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    partnershipType: PropTypes.string,
    value: PropTypes.string,
  };

  ComponentWithPartnerFundingAccounts.defaultProps = {
    accountsDisplayMax: -1,
    isItemEdit: undefined,
    onChange: undefined,
    partnerFundingAccount: undefined,
    partnershipType: PartnershipTypes.VENDOR,
    value: undefined,
  };

  return ComponentWithPartnerFundingAccounts;
};

export default withPartnerFundingAccounts;

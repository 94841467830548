import { connect } from 'react-redux';

import {
  mapDispatchToProps,
  mapStateToProps,
  mergeProps,
  reduxFormWrapper,
} from 'modules/external/ExternalCollectTaxInfo/helpers/submitTaxConfirmation';

import ConfirmTaxInfoModal from './ConfirmTaxInfoModal';

const connectedToReduxForm = reduxFormWrapper(ConfirmTaxInfoModal);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(connectedToReduxForm);

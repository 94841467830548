import { spawn, take } from 'redux-saga/effects';

import { createItemTagLinksRoutine, createTagRoutine, fetchCompanyTagsRoutine } from 'actions/routines/tags';

import createItemTagLinks from './createItemTagLinksSaga';
import createTag from './createTagSaga';
import fetchCompanyTags from './fetchCompanyTagsSaga';

/**
 * Listens for redux actions related to tags/tagLinks
 * Tags are standalone objects encapsulating the tag's name
 * TagLinks are objects connecting Tags to something else (Item, for eg)
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([
      fetchCompanyTagsRoutine.TRIGGER,
      createTagRoutine.TRIGGER,
      createItemTagLinksRoutine.TRIGGER,
    ]);

    switch (action.type) {
      case fetchCompanyTagsRoutine.TRIGGER:
        yield spawn(fetchCompanyTags, action);
        break;
      case createTagRoutine.TRIGGER:
        yield spawn(createTag, action);
        break;
      case createItemTagLinksRoutine.TRIGGER:
        yield spawn(createItemTagLinks, action);
        break;
      default:
        yield null;
    }
  }
}

/**
 * Root tags saga.
 * @return {IterableIterator<*>}
 */
export default function* tags() {
  yield watch();
}

import React from 'react';
import { BaseHint } from 'components/hintTypes';
import { Intent } from 'constants/ui';
import EnterExistingPasswordErrorContent from '../EnterExistingPasswordErrorContent';
const EnterExistingPasswordError = ({ errors }) => {
    const isSingleError = errors.length === 1;
    if (isSingleError) {
        return (React.createElement(BaseHint, { className: "padding--xm--important", intent: Intent.DANGER, text: React.createElement(EnterExistingPasswordErrorContent, null) }));
    }
    return React.createElement(EnterExistingPasswordErrorContent, null);
};
export default EnterExistingPasswordError;

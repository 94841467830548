import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getCurrentCompanyRequest } from 'actions/currentCompany';
import { fetchSingleMembershipRequest } from 'actions/memberships';
import { fetchSinglePartnershipRequest } from 'actions/partnership';
import { fetchTaxInfoRequest } from 'actions/taxes';

import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

import { currentCompanySelector } from 'selectors/currentCompanySelectors';
import { currentMembershipSelector } from 'selectors/membershipsSelector';
import { taxesInfoSelector } from 'selectors/taxesSelectors';

import ExternalCollectTaxInfo from './ExternalCollectTaxInfo';
import { isFetchingRequirementsSelector } from './helpers/selectors';
import { useLoadDataOnInit } from './helpers/useLoadDataOnInit';

const ExternalCollectTaxInfoContainer = (props) => {
  useLoadDataOnInit(props);

  return <ExternalCollectTaxInfo {...props} />;
};

ExternalCollectTaxInfoContainer.propTypes = {
  currentCompany: PropTypes.shape(),
  isFetchingRequirements: PropTypes.bool,
  membership: PropTypes.shape(),
  onFetchCurrentCompany: PropTypes.func.isRequired,
  onFetchMembership: PropTypes.func.isRequired,
  onFetchPartnership: PropTypes.func.isRequired,
  onFetchTaxInfo: PropTypes.func.isRequired,
  partnership: PropTypes.shape(),
  taxInfo: PropTypes.shape(),
};

ExternalCollectTaxInfoContainer.defaultProps = {
  currentCompany: undefined,
  isFetchingRequirements: false,
  membership: undefined,
  partnership: undefined,
  taxInfo: undefined,
};

const mapStateToProps = createStructuredSelector({
  currentCompany: currentCompanySelector,
  isFetchingRequirements: isFetchingRequirementsSelector,
  membership: currentMembershipSelector,
  partnership: partnershipFromQuerySelector,
  taxInfo: taxesInfoSelector,
});

const mapDispatchToProps = {
  onFetchCurrentCompany: getCurrentCompanyRequest,
  onFetchMembership: fetchSingleMembershipRequest,
  onFetchPartnership: fetchSinglePartnershipRequest,
  onFetchTaxInfo: fetchTaxInfoRequest,
};

export { ExternalCollectTaxInfoContainer };
export default connect(mapStateToProps, mapDispatchToProps)(ExternalCollectTaxInfoContainer);

import ThunkFetch from 'helpers/thunkFetch';

import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { getMembershipEndpoint } from 'services/api/membershipsEndpoints';

import { UPDATE_MEMBERSHIP_FAILURE, UPDATE_MEMBERSHIP_REQUEST, UPDATE_MEMBERSHIP_SUCCESS } from 'types/memberships';

// this is an old thunk pattern used in RepresentativeFormField
// if you're in here and need a way to PATCH /membership, prefer using the updateMembership saga in sagas/memberships
const updateMembership = (payload, membershipId, successCallback = null, errorCallback = null) => {
  // Add membershipId to payload
  const updatePayload = {
    id: membershipId,
    ...payload,
  };
  // added here so we don't cause any regressions, 'cause in the current flow we always send address fields
  const address = payload.address && {
    name: 'address',
    kind: 'MembershipAddress',
  };
  const t = new ThunkFetch({
    actions: {
      error: UPDATE_MEMBERSHIP_FAILURE,
      request: UPDATE_MEMBERSHIP_REQUEST,
      success: UPDATE_MEMBERSHIP_SUCCESS,
    },
    endpoint: getMembershipEndpoint(membershipId),
    errorCallback,
    method: 'PATCH',
    payload: payloadToJSONAPI(updatePayload, 'membership', 'company', 'user', 'personalInfo', address, {
      name: 'documentsOwned',
      kind: 'Document',
      isPlural: true,
    }),
    requireAuth: true,
    successCallback,
  });

  return t.makeRequest();
};

export default updateMembership;

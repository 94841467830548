import React from 'react';
import { TooltipMUIConditionalWrapper } from 'components';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';
import { stepStatuses, substepDefaultStatuses } from '../constants';
import {} from '../ProgressBarStepperV2.types';
import { Item, SubstepIcon } from './ProgressBarSubstep.styles';
const ProgressBarSubstep = ({ step, onClickStep }) => {
    const tooltipProps = step.tooltip?.[step.status] && {
        arrow: true,
        padding: TooltipPadding.LARGE,
        placement: TooltipPlacement.RIGHT,
        title: step.tooltip?.[step.status],
        style: {
            fontSize: '0.75rem',
        },
    };
    const isDisabled = step.status === stepStatuses.disabled;
    return (React.createElement(TooltipMUIConditionalWrapper, { tooltipProps: tooltipProps },
        React.createElement(Item, { color: substepDefaultStatuses[step.status].color, disabled: isDisabled, isActive: step.isActive, onClick: () => onClickStep(0, step.id) },
            step.label,
            React.createElement(SubstepIcon, { icon: substepDefaultStatuses[step.status].icon }))));
};
export default ProgressBarSubstep;

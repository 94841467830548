import styled from 'styled-components';
import { Icon } from 'components/icon';
import { containerWidthMap } from 'modules/signup-v3/constants/container';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  max-width: ${containerWidthMap.large};
`;
export const FieldContainer = styled.div `
  max-width: 400px;
  position: relative;

  ${({ disabled }) => disabled &&
    `
      .Select__dropdown-indicator {
        display: none;
      }
  `}
`;
export const TooltipContainer = styled.div `
  position: absolute;
  right: 0.688rem;
  top: calc(50% - 0.5rem);
`;
export const InputIcon = styled(Icon) `
  margin: 0 0.25rem;
`;

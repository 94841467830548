import _cloneDeep from 'lodash/cloneDeep';
import _mapValues from 'lodash/mapValues';
import _omit from 'lodash/omit';

import { fetchItemsRoutine, sendSubmitBillToItemRequestRoutine, submitItemRoutine } from 'actions/routines/item';
import { deleteInboxItemRoutine } from 'actions/routines/item/delete';
import { deleteBillAttachmentRoutine } from 'actions/routines/itemAttachments';

import { attachmentRelationships, itemRelationships } from 'constants/relationships';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';
import { allKeys, hasLength } from 'helpers/utility';

import { SUBMIT_UPLOAD_BILL_SUCCESS } from 'types/bills';
import { RESET_BULK_ACTIONS_STATE } from 'types/bulkActions';
import * as types from 'types/item';
import { FETCH_PARTNERSHIP_ITEMS_SUCCESS } from 'types/partnership';

export const getItemAttributes = (currentState, items, itemId) => {
  const { attributes } = items[itemId];

  if (itemId in currentState) {
    const currentAttributes = currentState[itemId];
    return {
      ...currentAttributes,
      ...attributes,
    };
  }

  return attributes;
};

export const deleteFile = (itemsById, itemId, fileId) => {
  // Check item exists
  if (!itemsById[itemId]) {
    return itemsById;
  }

  const newItems = _cloneDeep(itemsById);

  // Filter the deleted file
  const filteredAttachments = newItems[itemId].attachments.filter((attachment) => attachment.id !== fileId);
  const filteredFiles = newItems[itemId].files.filter((file) => file.id !== fileId);

  // Return all itemsById with the file filtered for a specific item.
  newItems[itemId].attachments = filteredAttachments;
  newItems[itemId].files = filteredFiles;
  return newItems;
};

// OCR File Upload Relationship we do not want to flatten the sender object
const reconstitueSender = (items, action) => {
  const newItems = _cloneDeep(items);
  Object.entries(newItems).forEach(([itemKey]) => {
    const item = items[itemKey] || null;
    if (item && item?.relationships && item?.relationships.predictedPayeePartnership) {
      const partnerId = item.relationships?.predictedPayeePartnership?.data?.id || null;
      const partnerName = action?.payload?.partnership?.[partnerId]?.attributes?.name || null;
      newItems[itemKey].attributes.predictedPayeePartnership = {
        id: partnerId,
        name: partnerName,
      };
    }

    if (item && item?.relationships && item?.relationships.sender) {
      const senderId = item.relationships?.sender?.data?.id || null;
      if (senderId && action?.payload?.membership && hasLength(allKeys(action?.payload?.membership))) {
        const member = action.payload.membership[senderId];
        if (member && member?.relationships && member?.relationships?.user && member?.relationships?.user?.data) {
          // Merge backup
          const userId = member?.relationships?.user?.data?.id || null;
          if (userId && action?.payload?.user && hasLength(allKeys(action?.payload?.user))) {
            newItems[itemKey].attributes.addedBy = {
              id: action?.payload?.user[userId]?.id,
              ...action?.payload?.user[userId]?.attributes,
            };
            const partnerId = item.relationships?.predictedPayeePartnership?.data?.id || null;
            const partnerName = action?.payload?.partnership?.[partnerId]?.attributes?.name || null;
            newItems[itemKey].attributes.predictedPayeePartnership = {
              id: partnerId,
              name: partnerName,
            };
          }
        }
      }
    }
  });

  return { ...newItems };
};

const getItemsById = (items, currentState = {}, action, fees) => {
  if (!items) {
    return {};
  }

  let results = reconstitueSender(items, action);

  results = getObjectsByIdWithRelationships(results, itemRelationships, {
    currentState,
    getAttributes: getItemAttributes,
  });

  // @alcferreira Temporary implementation, to be removed on DEV-14604
  if (fees) {
    results = _mapValues(results, (item) => ({
      ...item,
      fees: item.fees.map((fee) => ({
        id: fees[fee].id,
        ...fees[fee].attributes,
      })),
    }));
  }

  return results;
};

const getItemsByIdSubmit = (items, currentState = {}) =>
  getObjectsByIdWithRelationships(items, itemRelationships, {
    currentState,
    getAttributes: getItemAttributes,
  });

/**
 * Correctly processes item data and merges with other items in state
 * @param {Object} item
 * @param {Object} currentState
 * @param {Object} action
 * @returns {Object}
 */
export const getItemsByIdOcrPreProcessed = (item, currentState = {}, action) => {
  const { payload } = action || {};
  const [itemId] = Object.keys(item || {});

  // If we don't have item id in the payload, don't bother transforming.
  // This should in theory never happen, only having this here as a
  // "safety net".
  if (!itemId) {
    return currentState;
  }

  const currentStateCopy = { ...currentState };
  const items = getItemsById(item, currentState, action);

  // BEGIN OCR TRANSFORMATION
  if (Object.keys(payload?.itemAttachment || {}).length > 0) {
    Object.entries(items).forEach(([itemKey]) => {
      const files = [];
      const attachments = getObjectsByIdWithRelationships(payload?.itemAttachment, attachmentRelationships);
      Object.entries(attachments).forEach(([, fileValue]) => {
        files.push(fileValue);
      });
      items[itemKey].files = files;
      items[itemKey].bills = files;
    });
  }
  // END OCR TRANSFORMATION

  // We want to delete the item that we're working with from the current
  // state's copy - otherwise, we will override it without the transformed
  // properties.
  delete currentStateCopy[itemId];
  return { [itemId]: items[itemId], ...currentStateCopy };
};

const itemsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case sendSubmitBillToItemRequestRoutine.SUCCESS:
    case SUBMIT_UPLOAD_BILL_SUCCESS:
    case types.SUBMIT_EXISTING_ITEMS_SUCCESS:
    case types.UPDATE_EXISTING_ITEMS_SUCCESS:
      return deepMergeWithArrayReplacement(state, getItemsById(action.payload.item, state, action));

    case types.FETCH_ITEM_SUCCESS:
      // Using this method to fill the newest item instead of deepMergeWithArrayReplacement, because the latter does
      // incorrect merging of item and line items extended fields.
      return getItemsByIdOcrPreProcessed(action.payload.item, state, action);

    case submitItemRoutine.FULFILL: {
      return getItemsByIdSubmit(action.payload.item);
    }
    case fetchItemsRoutine.SUCCESS: {
      return getItemsById(action.payload.item, state, action, action.payload.fee);
    }

    case FETCH_PARTNERSHIP_ITEMS_SUCCESS:
      return getItemsById(action.payload.item, state, action);

    case RESET_BULK_ACTIONS_STATE:
      return [];

    case deleteInboxItemRoutine.SUCCESS:
      return _omit(state, action.payload.id);

    case deleteBillAttachmentRoutine.SUCCESS:
      return deleteFile(state, action.payload.itemId, action.payload.attachment.id);

    default:
      return state;
  }
};

export default itemsByIdReducer;

import PropTypes from 'prop-types';
import React from 'react';
import { v4 } from 'uuid';

import { ButtonV2, Icon, IconNames, Modal } from 'components';

import { ButtonSize } from 'constants/button';
import { PermissionResourceAction } from 'constants/permissions';
import { colors, sizes } from 'constants/styles';

import { WithPermissionRestriction, RestrictedPermissionTooltip } from 'modules/auth';
import ConnectBankManual from 'modules/connectBank/connectBankManual/ConnectBankManual';
import PlaidLink from 'modules/plaidLink';

import './ConnectBank.scss';

const ConnectBankOptions = ({
  connectBankManualModal,
  connectBankTokenModal,
  createCheckSource,
  createFundingAccountLinkToken,
  isSubmitting,
  onCloseManualAccountModal,
  onOpenManualAccountModal,
  onOpenTokenAccountModal,
  onSubmitFundingTokenAccount,
  onUpdateTokenAccountModal,
}) => {
  const plaidSession = React.useRef(v4());
  const plaidSessionId = plaidSession.current;

  const { plaidLinkLoaded, plaidLinkLoading } = connectBankTokenModal;

  const isPlaidAvailable = !plaidLinkLoading && plaidLinkLoaded;

  const openPlaidLink = () => {
    if (!connectBankTokenModal.open) {
      onUpdateTokenAccountModal({ plaidLinkToken: null });
      onOpenTokenAccountModal({ plaidSessionId });
    }
  };

  return (
    <React.Fragment>
      <div className="bank-options">
        <div className="bank-options--plaid">
          <WithPermissionRestriction
            requiredPermissions={[PermissionResourceAction.FUNDING_ACCOUNTS_CREATE]}
            WithoutPermissionComponent={
              <RestrictedPermissionTooltip>
                <ButtonV2
                  isDisabled
                  leftIconClassName="margin-right--xm"
                  leftIconName={IconNames.BANK_ACCOUNT}
                  leftIconSize={sizes.iconSizes.LARGE}
                  rightIconName={IconNames.ARROW_RIGHT}
                  rightIconProps={{ style: { marginLeft: 'auto' } }}
                  rightIconSize={sizes.iconSizes.LARGE}
                  size={ButtonSize.LARGE}
                  type="button"
                >
                  Connect bank account
                </ButtonV2>
              </RestrictedPermissionTooltip>
            }
            WithPermissionComponent={
              <ButtonV2
                isLoading={!isPlaidAvailable || isSubmitting}
                leftIconClassName="margin-right--xm"
                leftIconColor={colors.colorWhiteHex}
                leftIconName={IconNames.BANK_ACCOUNT}
                leftIconSize={sizes.iconSizes.LARGE}
                onClick={openPlaidLink}
                rightIconColor={colors.colorWhiteHex}
                rightIconName={IconNames.ARROW_RIGHT}
                rightIconProps={{ style: { marginLeft: 'auto' } }}
                rightIconSize={sizes.iconSizes.LARGE}
                size={ButtonSize.LARGE}
                type="button"
              >
                Connect bank account
              </ButtonV2>
            }
          />

          <div className="inline-alert margin-top--sm">
            <span>
              Connecting to your bank is the most <strong>secure</strong> option.
            </span>
          </div>
        </div>

        <div className="bank-options--manual">
          <div className="link-manual">
            <WithPermissionRestriction
              requiredPermissions={[PermissionResourceAction.FUNDING_ACCOUNTS_CREATE]}
              WithoutPermissionComponent={
                <RestrictedPermissionTooltip>
                  <div className="display--flex align-items--center">
                    <Icon color={colors.colorGreyMedHex} icon={IconNames.ADD} size={sizes.iconSizes.LARGE} />
                    <a className="disabled font-xs font-color--grey--x-dark margin-left--xm" role="presentation">
                      Add bank account by entering account and routing numbers
                    </a>
                  </div>
                </RestrictedPermissionTooltip>
              }
              WithPermissionComponent={
                <div className="display--flex align-items--center">
                  <Icon color={colors.colorGreyDarkHex} icon={IconNames.ADD} size={sizes.iconSizes.LARGE} />
                  <a
                    className="font-xs font-color--grey--x-dark margin-left--xm"
                    onClick={onOpenManualAccountModal}
                    role="presentation"
                  >
                    Add bank account by entering account and routing numbers
                  </a>
                </div>
              }
            />
          </div>

          <Modal
            body={<ConnectBankManual createCheckSource={createCheckSource} />}
            hasInlineFooter
            modalHeader="Add a bank account"
            modalState={connectBankManualModal.open}
            onCloseModal={onCloseManualAccountModal}
          />
        </div>
      </div>

      <PlaidLink
        createFundingAccountLinkToken={createFundingAccountLinkToken}
        onSuccess={onSubmitFundingTokenAccount}
        sessionId={plaidSessionId}
      />
    </React.Fragment>
  );
};

ConnectBankOptions.propTypes = {
  connectBankManualModal: PropTypes.shape().isRequired,
  connectBankTokenModal: PropTypes.shape().isRequired,
  createCheckSource: PropTypes.bool.isRequired,
  createFundingAccountLinkToken: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onCloseManualAccountModal: PropTypes.func.isRequired,
  onOpenManualAccountModal: PropTypes.func.isRequired,
  onOpenTokenAccountModal: PropTypes.func.isRequired,
  onSubmitFundingTokenAccount: PropTypes.func.isRequired,
  onUpdateTokenAccountModal: PropTypes.func.isRequired,
};

export default ConnectBankOptions;

import * as routines from 'actions/routines/permissions';

import { createIsFetchingReducer } from 'store/redux';

export const finishCases = [routines.fetchPermissionsRoutine.FAILURE, routines.fetchPermissionsRoutine.SUCCESS];

export const requestCases = [routines.fetchPermissionsRoutine.REQUEST];

const isFetchingReducer = createIsFetchingReducer(finishCases, requestCases);

export default isFetchingReducer;

/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';

import { getClassNames } from 'helpers/ui';

/**
 * Renders text for a table cell.
 * @see {TableCellTextWithSubtext}
 *
 * @param {ComponentProps} props
 * @param {Node} props.children
 * @param {Object} [props.style]
 * @param {RestOfProps} rest
 * @return {StatelessComponent}
 */
const TableCellText = ({ children, dataFullStory, dataTestId, style, ...rest }) => (
  <div
    className={getClassNames(rest, {
      'text-overflow--ellipsis': true,
      'overflow--hidden': true,
      'align-items--center': true,
      'font-color--darkBlue': true,
      'font-size--xs': true,
      'line-height--15': true,
      'max-width--full': true,
    })}
    data-fullstory={dataFullStory}
    data-testid={dataTestId}
    style={style}
  >
    {children}
  </div>
);

TableCellText.propTypes = {
  dataFullStory: PropTypes.bool,
  dataTestId: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

TableCellText.defaultProps = {
  dataFullStory: undefined,
  children: undefined,
  className: undefined,
  style: undefined,
};

export default TableCellText;

import React from 'react';
import { Redirect } from 'react-router-dom';

import { DeepLinkType, itemForCompanyQueries, viewFileQueries } from 'constants/deepLinks';
import { DASHBOARD, PAYMENTS_LIST_TABS } from 'constants/routes';

import { getQueryParam } from 'helpers/queryParams';
import { asPath } from 'helpers/routeHelpers';

import { ViewDocument, ViewInvoice } from './components';

/**
 * Checks the type of deep link, and renders the corresponding component.
 * If we don't handle the type of deep link, redirects to dashboard.
 *
 * @return {*}
 *
 * @constructor
 */
const DeepLinks = () => {
  const type = getQueryParam('type');

  switch (type) {
    case DeepLinkType.INVOICE_PAYABLE:
      return <ViewInvoice queries={itemForCompanyQueries} type={PAYMENTS_LIST_TABS.PAYABLES} />;

    case DeepLinkType.INVOICE_RECEIVABLE:
      return <ViewInvoice queries={itemForCompanyQueries} type={PAYMENTS_LIST_TABS.RECEIVABLES} />;

    case DeepLinkType.FILE:
      return <ViewDocument queries={viewFileQueries} />;

    default:
      return <Redirect to={asPath(DASHBOARD)} />;
  }
};

export default DeepLinks;

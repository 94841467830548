import { createSelector } from 'reselect';

import {
  getPartnershipRequestName,
  getPartnershipRequestType,
  getPartnershipRequestUserFriendlyOrInternalName,
  isPartnershipRequestTypeAcceptPartnership,
  isPartnershipRequestTypeUpdatePaymentMethod,
  shouldPartnershipRequestCollectVendorTaxInfo,
} from 'helpers/partnershipRequest';
import { getStepsForCurrentUser } from 'helpers/progressBarStepper';
import { lastElementIn } from 'helpers/utility';

import { lastSegmentInLocationSelector } from './routerSelectors';

const getState = (state) => state.partnershipRequest;

/**
 * Selects the partnership requests by id from the state
 * @function
 * @param {ReduxState} state
 * @returns {object}
 */
export const partnershipRequestsByIdSelector = createSelector([getState], (partnershipRequests) =>
  Object(partnershipRequests?.byId),
);

/**
 * Selects the partnership request by id from the state by current location path
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {Object}
 */
export const partnershipRequestFromLocationSelector = createSelector(
  [partnershipRequestsByIdSelector, lastSegmentInLocationSelector],
  (partnershipRequestsById, partnershipRequestId) => partnershipRequestsById[partnershipRequestId],
);

/**
 * Selects the isFetching property from the state
 * @function
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const isPartnershipRequestLoadingSelector = createSelector(
  [getState],
  (partnershipRequests) => partnershipRequests.isFetching,
);

/**
 * Selects the last element fundingAccountState property from the current partnershipRequest
 * @function
 * @param {ReduxState} state
 * @returns {Id}
 */
export const partnershipRequestFundingAccountStateIdSelector = createSelector(
  [partnershipRequestFromLocationSelector],
  (partnershipRequest) => lastElementIn(partnershipRequest.fundingAccountStates),
);

/**
 * Selects the meta property from the state
 * @function
 * @param {ReduxState} state
 * @returns {object}
 */
export const partnershipRequestsMetaSelector = createSelector(
  [getState],
  (partnershipRequests) => partnershipRequests.meta,
);

/**
 * Selects the steps property from the state's meta
 * @function
 * @param {ReduxState} state
 * @returns {ExternalStep[]}
 */
export const partnershipRequestsStepsSelector = createSelector(
  [partnershipRequestsMetaSelector],
  (partnershipRequestsMeta) => partnershipRequestsMeta?.steps,
);

/**
 * Selects the steps property from the state's meta
 * @function
 * @param {ReduxState} state
 * @returns {ExternalStep[]}
 */
export const partnershipRequestStepsForCurrentUserSelector = createSelector(
  [partnershipRequestsStepsSelector],
  getStepsForCurrentUser,
);

/**
 * Selects the requestType from partnershipRequest
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {PartnershipRequestTypes} - requestType
 */
export const partnershipRequestTypeSelector = createSelector(
  [partnershipRequestFromLocationSelector],
  getPartnershipRequestType,
);

/**
 * Selects the requestType from partnershipRequest and checks if accept-partnership
 * @type {StandardSelector}
 * @returns {Boolean} - isAcceptPartnership
 */
export const isPartnershipRequestAcceptPartnershipSelector = createSelector(
  [partnershipRequestFromLocationSelector],
  isPartnershipRequestTypeAcceptPartnership,
);

/**
 * Selects the requestType from partnershipRequest and checks if it's update-payment
 * @type {StandardSelector}
 * @returns {Boolean} - isUpdatePayment
 */
export const isPartnershipRequestUpdatePaymentSelector = createSelector(
  [partnershipRequestFromLocationSelector],
  isPartnershipRequestTypeUpdatePaymentMethod,
);

/**
 * Selects the partnershipRequest name
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {PartnershipRequestNames} - partnershipRequestName
 */
export const partnershipRequestTypeNameSelector = createSelector(
  [partnershipRequestFromLocationSelector],
  getPartnershipRequestName,
);

/**
 * Selects the partnershipRequest client term or name
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {PartnershipRequestUserFriendlyNames|PartnershipRequestNames} - partnershipRequestUserFriendlyOrInternalName
 */
export const partnershipRequestTypeUserFriendlyOrInternalNameSelector = createSelector(
  [partnershipRequestFromLocationSelector],
  getPartnershipRequestUserFriendlyOrInternalName,
);

/**
 * Selector that determines whether to hide/show vendor tax collection section on external flow
 * @type {StandardSelector}
 * @returns {Boolean}
 */
export const partnershipRequestCollectVendorTaxInfoForCurrentUser = createSelector(
  [partnershipRequestFromLocationSelector],
  shouldPartnershipRequestCollectVendorTaxInfo,
);

/**
 * Selects confirmationRedirectUrl for partnershipRequest selected from the current location
 * partnership request Id
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {Boolean}
 */
export const partnershipRequestConfirmationRedirectUrlSelector = createSelector(
  [partnershipRequestFromLocationSelector],
  (partnershipRequest) => partnershipRequest?.confirmationRedirectUrl,
);

/**
 * Selects the funding_currency_codes for partnershipRequest selected from the current location
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {Array}
 */
export const partnershipRequestFundingCurrencyCodesSelector = createSelector(
  [partnershipRequestFromLocationSelector],
  (partnershipRequest) => partnershipRequest?.fundingCurrencyCodes,
);

/**
 * Selects the membership id for the partnershipRequest selected from the current
 * location
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {Object} props
 * @param {Location} props.location
 * @returns {?String}
 */
export const partnershipRequestMembershipIdSelector = createSelector(
  [partnershipRequestFromLocationSelector],
  (partnershipRequest) => partnershipRequest?.membership,
);

/**
 * Selects the "form" property of the partnershipRequest state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object}
 */
export const partnershipRequestFormsSelector = createSelector([getState], (state) => state.form);

/**
 * Selects the W8 form state from the partnershipRequest state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {{isValid: Boolean, isSubmitting: Boolean}}
 */
export const partnershipRequestW8FormStateSelector = createSelector(
  [partnershipRequestFormsSelector],
  (forms) => forms.w8,
);

/**
 * Selects the isValid property of the W8 form in the partnershipRequest state
 * @type {Selector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const partnershipRequestW8FormIsValidSelector = createSelector(
  [partnershipRequestW8FormStateSelector],
  (formState) => formState.isValid,
);

/**
 * Selects the isSubmitting property of the W8 form in the partnershipRequest state
 * @type {Selector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const partnershipRequestW8FormIsSubmittingSelector = createSelector(
  [partnershipRequestW8FormStateSelector],
  (formState) => formState.isSubmitting,
);

/**
 * Selects the W9 form state from the partnershipRequest state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {{isValid: Boolean, isSubmitting: Boolean}}
 */
export const partnershipRequestW9FormStateSelector = createSelector(
  [partnershipRequestFormsSelector],
  (forms) => forms.w9,
);

/**
 * Selects the isValid property of the W8 form in the partnershipRequest state
 * @type {Selector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const partnershipRequestW9FormIsValidSelector = createSelector(
  [partnershipRequestW9FormStateSelector],
  (formState) => formState.isValid,
);

/**
 * Selects the isSubmitting property of the W8 form in the partnershipRequest state
 * @type {Selector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const partnershipRequestW9FormIsSubmittingSelector = createSelector(
  [partnershipRequestW9FormStateSelector],
  (formState) => formState.isSubmitting,
);

import { TruncatableText } from '@routable/components';
import classNames from 'classnames';
import React from 'react';
import { Icon, IconNames } from 'components';
import { colors, sizes } from 'constants/styles';
const CompanyAddressDataCardHeader = ({ hasError, isExpanded, onToggleExpanded, title, }) => (React.createElement("div", { className: classNames('company-address-data-card__header', {
        'company-address-data-card__header--expanded': isExpanded,
    }), onClick: onToggleExpanded, role: "presentation" },
    React.createElement("div", { className: "company-address-data-card__header__left", "data-fullstory": true },
        React.createElement(Icon, { color: hasError ? colors.colorRedBoldHex : colors.colorGreyXDarkHex, icon: hasError ? IconNames.ERROR : IconNames.MAP_MARKER, size: sizes.iconSizes.LARGE }),
        React.createElement(TruncatableText, { className: "text-black text-sm font-semibold ml-5", maxNumberOfLines: 2, text: title })),
    React.createElement(Icon, { color: colors.colorGreyXDarkHex, icon: isExpanded ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN, size: sizes.iconSizes.LARGE })));
export default CompanyAddressDataCardHeader;

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import FormControl from 'components/form/FormControl';
import ItemStatusBox from 'components/itemStatusBox';
import { Col, Row } from 'components/layout';

import { partnerFundingAccountFormFields } from 'constants/formFields';
import { breakpointNames } from 'constants/mediaQuery';
import { texts } from 'constants/partnerFundingAccount';

import { ItemStatuses } from 'enums/items';

import { getPartnershipFundingAccountSelectionOptions } from 'helpers/addPartnerFundingAccount';
import { requiredValidator } from 'helpers/fieldValidation';
import { getValueKey } from 'helpers/keys';
import { hasLength } from 'helpers/utility';

import AddPartnerFundingAccountOptionsAccountSelect from './AddPartnerFundingAccountOptionsAccountSelect';

/**
 * Renders advanced options in AddPartnerFundingAccount form
 * @param props
 * @param {object} props.fundingAccountsById
 * @param {object} props.fundingInfoAddressesById,
 * @param {Partnership} props.partnership
 * @param {object[]} props.partnershipFundingAccounts,
 * @param {boolean} props.shouldShowReplaceFundingAccounts
 * @return {FunctionComponent}
 */
const AddPartnerFundingAccountOptionAccounts = (props) => {
  const {
    fundingAccountsById,
    fundingInfoAddressesById,
    partnership,
    partnershipFundingAccounts,
    shouldShowReplaceFundingAccounts,
  } = props;

  const partnershipHasAccounts = hasLength(partnershipFundingAccounts);
  const selectOptions = getPartnershipFundingAccountSelectionOptions(partnershipFundingAccounts, {
    fundingAccountsById,
    fundingInfoAddressesById,
    partnership,
  });

  if (!shouldShowReplaceFundingAccounts || !partnershipHasAccounts) {
    return null;
  }

  return (
    <Col>
      <div className="add-partner-funding-account--options--replace">
        <div className="add-partner-funding-account--options--replace-inner">
          <FormControl className="add-partner-account--options--control">
            <Field
              component={AddPartnerFundingAccountOptionsAccountSelect}
              name={partnerFundingAccountFormFields.REPLACE_FUNDING_ACCOUNTS}
              props={{
                options: selectOptions,
                partnership,
                ...getValueKey(),
              }}
              validate={requiredValidator}
            />
          </FormControl>

          <p className="font-xs semibold font-color--dark-jordan">{texts.FORM_REPLACE_ACCOUNTS_INFO}</p>

          <Row
            breakpointProps={{
              [breakpointNames.tablet]: {
                flexWrap: 'no-wrap',
              },
            }}
            className="add-partner-account--options--statuses gap-3"
            flexWrap="wrap"
          >
            <ItemStatusBox item={{ status: ItemStatuses.NEEDS_APPROVAL }} />
            <ItemStatusBox item={{ status: ItemStatuses.READY_TO_SEND }} />
            <ItemStatusBox item={{ status: ItemStatuses.SCHEDULED }} />
          </Row>
        </div>
      </div>
    </Col>
  );
};

AddPartnerFundingAccountOptionAccounts.propTypes = {
  fundingAccountsById: PropTypes.shape({}).isRequired,
  fundingInfoAddressesById: PropTypes.shape({}).isRequired,
  partnership: PropTypes.shape({}).isRequired,
  partnershipFundingAccounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  shouldShowReplaceFundingAccounts: PropTypes.bool,
};

AddPartnerFundingAccountOptionAccounts.defaultProps = {
  shouldShowReplaceFundingAccounts: undefined,
};

export default AddPartnerFundingAccountOptionAccounts;

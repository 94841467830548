import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { reduxFormInputPropType } from 'components/commonProps';
import FormControlBoundary from 'components/form/FormControlBoundary';

import { callOnEnterKeyEvent, createPersistedEventCallback } from 'helpers/events';
import { callWithArgsIfIsFn, isEqual } from 'helpers/utility';

import Checkbox from './Checkbox';

/**
 * Renders a Checkbox inside a FormControlBoundary.
 * @param {ComponentProps} props
 * @param {StringMaybe} props.className
 * @param {ReduxFormInput} props.input
 * @param {boolean} props.isDisabled
 * @returns {StatelessComponent}
 */
const BoundaryCheckbox = ({ className, input, isDisabled, ...props }) => {
  const { onBlur, onChange, onFocus, value } = input;

  const boundaryRef = React.useRef();

  // note, re: the event listener callbacks--
  // the boundary component needs to be able to stand-in for the form control itself in this instance.
  // so, it should accept clicks, key presses, etc, that are intended for the form control, and process
  // them as intuitively expected. here (since checkbox), that means calling the onChange prop.

  const handleFocus = React.useCallback(
    createPersistedEventCallback((evt, ...rest) => {
      callWithArgsIfIsFn(onFocus, evt, ...rest);
    }),
    [onFocus],
  );

  const handleBlur = React.useCallback(
    createPersistedEventCallback((evt, ...rest) => {
      callWithArgsIfIsFn(onBlur, evt, ...rest);
    }),
    [onBlur],
  );

  const handleClick = React.useCallback(
    createPersistedEventCallback((evt) => {
      // only fire the event if we clicked on the boundary (or would double-fire if checkbox clicked)
      if (isEqual(evt?.target, boundaryRef.current)) {
        callWithArgsIfIsFn(onChange, !value);
      }
    }),
    [onChange, value],
  );

  const handleKeyPress = React.useCallback(
    callOnEnterKeyEvent(
      createPersistedEventCallback(() => {
        callWithArgsIfIsFn(onChange, !value);
      }),
    ),
    [onChange, value],
  );

  const isActive = input.value;

  return (
    <FormControlBoundary
      className={classNames(className, {
        active: isActive,
        disabled: isDisabled,
      })}
      onBoundaryBlur={handleBlur}
      onBoundaryClick={handleClick}
      onBoundaryFocus={handleFocus}
      onBoundaryKeyPress={handleKeyPress}
      setRef={boundaryRef}
    >
      <Checkbox {...props} input={input} isDisabled={isDisabled} onFocus={handleFocus} />
    </FormControlBoundary>
  );
};

BoundaryCheckbox.propTypes = {
  className: PropTypes.string,
  input: reduxFormInputPropType.isRequired,
  isDisabled: PropTypes.bool,
};

BoundaryCheckbox.defaultProps = {
  className: undefined,
  isDisabled: undefined,
};

export default BoundaryCheckbox;

import { call, put, spawn, take } from 'redux-saga/effects';

import { handleRequestErrors } from 'actions/errors';
import { updateCurrentUserRoutine } from 'actions/routines/user';
import * as actions from 'actions/user';

import { getRequestErrorAction, parseCaughtError, parseErrorResponse } from 'helpers/errors';

import * as types from 'types/user';

import * as api from './api';

/**
 * Handle fetching current user data.
 * @return {IterableIterator<*>}
 */
export function* fetchCurrentUser() {
  let errorData = {};

  try {
    const response = yield call(api.fetchCurrentUser);

    if (response.ok) {
      yield put(actions.fetchCurrentUserSuccess(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchCurrentUserFailure, errorData));
}

/**
 * Handle updating current user's data.
 * @return {IterableIterator<*>}
 */
export function* updateCurrentUser(action) {
  let submitErrors = {};

  yield put(updateCurrentUserRoutine.request());

  try {
    const {
      payload: { form },
    } = action;

    const response = yield call(api.updateCurrentUser, form);

    if (response.ok) {
      yield put(updateCurrentUserRoutine.success(response.data));
      return;
    }

    submitErrors = parseErrorResponse(response);
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(submitErrors);
  yield put(errorAction(updateCurrentUserRoutine.failure, submitErrors));
}

/**
 * Listens for redux actions related to billing.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([types.GET_CURRENT_USER_REQUEST, updateCurrentUserRoutine.TRIGGER]);

    switch (action.type) {
      case types.GET_CURRENT_USER_REQUEST:
        yield spawn(fetchCurrentUser);
        break;

      case updateCurrentUserRoutine.TRIGGER:
        yield spawn(updateCurrentUser, action);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root user saga.
 * @return {IterableIterator<*>}
 */
export default function* user() {
  yield watch();
}

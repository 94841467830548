import { useMemo, useState } from 'react';
export const usePaginatedData = ({ data, options = { pageSize: 25, isMultipleSources: false }, }) => {
    const [page, setPage] = useState(1);
    const paginatedData = useMemo(() => {
        if (!data) {
            return undefined;
        }
        if (options.isMultipleSources) {
            return data.map((dat) => dat.slice((page - 1) * options.pageSize, page * options.pageSize));
        }
        return data.slice((page - 1) * options.pageSize, page * options.pageSize);
    }, [data, page]);
    const total = (data && (options.isMultipleSources ? data[0].length : data.length)) || 0;
    const totalPages = Math.ceil(total / options.pageSize) || 1;
    const hasPrevPage = page !== 1;
    const hasNextPage = page < totalPages;
    const gotoPage = (goTo) => {
        if (goTo === 'NEXT') {
            if (hasNextPage) {
                setPage(page + 1);
            }
            return;
        }
        if (goTo === 'PREV') {
            if (hasPrevPage) {
                setPage(page - 1);
            }
            return;
        }
        if (goTo >= 1 && goTo <= total) {
            setPage(goTo);
        }
    };
    return {
        pagination: {
            page,
            total,
            totalPages,
            pageSize: options.pageSize,
            hasPrevPage,
            hasNextPage,
        },
        paginatedData,
        gotoPage,
    };
};

import { UserTypes } from 'constants/user';

import { InternationalFundingBusinessType } from 'enums/funding';

export const ClearingAccountTypes = {
  MONEY_IN: 'moneyIn',
  MONEY_OUT: 'moneyOut',
};

export const FundingAccountTypes = {
  CHECKING: 'checking',
  SAVINGS: 'savings',
};

export const FundingAccountDirection = {
  RECEIVABLES: 'receivables',
  PAYABLES: 'payables',
  RECEIVABLES_AND_PAYABLES: 'receivables_and_payables',
};

export const FundingAccountDirectionText = {
  [FundingAccountDirection.RECEIVABLES]: 'Receivables',
  [FundingAccountDirection.PAYABLES]: 'Payables',
  [FundingAccountDirection.RECEIVABLES_AND_PAYABLES]: 'Payables and Receivables',
};

export const FundingAccountTypesText = {
  [FundingAccountTypes.CHECKING]: 'Checking account',
  [FundingAccountTypes.SAVINGS]: 'Savings account',
};

export const FundingAccountUsableStates = {
  NO_CLEARING_MATCH: 'no_clearing_match',
  NO_DELETED: 'no_deleted',
  NO_DISABLED: 'no_disabled',
  NO_LEDGER_MATCH: 'no_ledger_match',
  NO_NOT_VALID: 'no_not_valid',
  YES: 'yes',
};

export const FundingSourceProviderClasses = {
  ADDRESS: 'address',
  BANK: 'bank',
};

export const FundingSourceProviderStatuses = {
  INVALID: 'invalid',
  INVALID_MICRO_DEPOSITS_FAILED: 'invalid_micro_deposits_failed',
  INVALID_MICRO_DEPOSITS_FAILED_VERIFICATION: 'invalid_micro_deposits_failed_verification',
  INVALID_MICRO_DEPOSITS_PENDING: 'invalid_micro_deposits_pending',
  INVALID_MICRO_DEPOSITS_PROCESSED: 'invalid_micro_deposits_processed',
  VALID: 'valid',
};

export const FundingSourceProviderSubClasses = {
  ACH: 'bank_ach',
  ADDRESS: 'address_mailing',
  BALANCE: 'bank_balance',
  CHECK: 'bank_check',
  EXTERNAL_LEDGER: 'external_ledger',
};

export const FundingCustomerStatuses = {
  CANCELED: 'canceled',
  DOCUMENT: 'document',
  DEACTIVATED: 'deactivated',
  EXEMPT: 'exempt',
  INCOMPLETE: 'incomplete',
  PENDING: 'pending',
  RETRY: 'retry',
  SUSPENDED: 'suspended',
  UNVERIFIED: 'unverified',
  VERIFIED: 'verified',
};

export const FundingCustomerVerifiedStatuses = [FundingCustomerStatuses.VERIFIED, FundingCustomerStatuses.EXEMPT];

export const FundingCustomerFailedStatuses = [
  // show red hints & don't let user re-submit
  FundingCustomerStatuses.DEACTIVATED,
  FundingCustomerStatuses.SUSPENDED,
  FundingCustomerStatuses.CANCELED,

  // show yellow hints & let user re-submit
  FundingCustomerStatuses.RETRY,
];

export const FundingRequirementStatusTypes = {
  KYC_BENEFIT: 'kyc_benefit',
  KYC_ISSUE: 'kyc_issue',
  KYC_NEEDED: 'kyc_needed',
  KYC_PENDING: 'kyc_pending',
  OKAY: 'okay',
};

export const TransactionDirection = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAW: 'WITHDRAW',
};

export const PaymentDeliveryOptionText = {
  ACH_EXPEDITED: 'Expedited ACH',
};

/**
 * DEV-2786 @adamjaffeback Currencycloud's mapping on the BE uses 'company' and 'individual' for company type so we need
 * to convert our UserType to their lexicon.
 */
export const UserTypeToInternationalFundingBusinessType = {
  [UserTypes.BUSINESS]: InternationalFundingBusinessType.COMPANY,
  [UserTypes.PERSONAL]: InternationalFundingBusinessType.INDIVIDUAL,
};

/**
 * @type {Set<string>}
 */
export const FundingAccountFieldAcronyms = new Set(['BIC', 'BSB', 'CLABE', 'CNAPS', 'IBAN', 'IFSC', 'SWIFT']);

import PropTypes from 'prop-types';
import React from 'react';

const External = ({ children }) => <div className="external-container">{children}</div>;

External.propTypes = {
  children: PropTypes.node.isRequired,
};

export default External;

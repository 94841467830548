import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/text';

import { TextLineHeight, TextSize } from 'constants/styles/typography';

/**
 * Displays details passed to the MultiLineOption component used
 * in react-select
 * @see MultiLineOption
 * @param {ComponentProps} props
 * @param {Node} [props.children=null]
 * @return {StatelessComponent}
 */
const MultiLineOptionDetails = ({ children }) => (
  <Text.Regular className="multi-line-option__details" lineHeight={TextLineHeight.LARGE} size={TextSize.LEVEL_200}>
    {children}
  </Text.Regular>
);

MultiLineOptionDetails.propTypes = {
  children: PropTypes.node,
};

MultiLineOptionDetails.defaultProps = {
  children: undefined,
};

export default MultiLineOptionDetails;

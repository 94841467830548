import { disableMembershipRoutine } from 'actions/routines/membership';

import { createIsDisablingReducer } from 'store/redux';

export const finishCases = [disableMembershipRoutine.FAILURE, disableMembershipRoutine.SUCCESS];

export const requestCases = [disableMembershipRoutine.REQUEST];

const isDisablingReducer = createIsDisablingReducer(finishCases, requestCases);

export default isDisablingReducer;

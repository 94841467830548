import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { changeView } from 'actions/signUpFlow';

import { Text, TooltipMUI } from 'components';

import { GuideStatuses } from 'constants/guide';
import { TextLineHeight, TextSize } from 'constants/styles/typography';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';

import { getMembershipFullName, isMembershipCurrentMembership } from 'helpers/memberships';

import GuideSectionStatus from 'modules/dashboard/guide/global/presenters/GuideSectionStatus';

import { memberWithUserInfoSelector } from 'selectors/currentCompanySelectors';
import { rejectedMembershipDocumentsByMembershipSelector } from 'selectors/documentsSelectors';
import { allMembershipsWithFailedBeneficiaryStatus } from 'selectors/membershipsSelector';

import { views } from '../../constants';

/*
This component handles a set of cases with slightly different color highlights, copy, tooltips & enabled/disabled states
based on:
- if the representative for this component is the current user or not
- if that rep has submitted all info or still has some info to filled
*/

const RepresentativeInformation = (props) => {
  const {
    membership,
    notStarted, // a default state when no user has agreed to be a business rep yet
  } = props;

  const member = useSelector((state) => memberWithUserInfoSelector(state, membership.id));
  const failedBeneficiaries = useSelector(allMembershipsWithFailedBeneficiaryStatus) || [];
  const failedDocumentsForThisMember = useSelector((state) =>
    rejectedMembershipDocumentsByMembershipSelector(state, membership.id),
  );

  const isCurrentUser = isMembershipCurrentMembership(membership);
  const memberIsAFailedBeneficiary = failedBeneficiaries?.some((rep) => rep.id === membership.id);
  const fullName = getMembershipFullName(member);
  const bizRepMemberHasFailedKYC = memberIsAFailedBeneficiary || failedDocumentsForThisMember?.length;

  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(changeView(views.businessRepresentative));
  };

  const error = bizRepMemberHasFailedKYC
    ? {
        description: '',
        title: `${isCurrentUser ? "You'll" : `${fullName} will`} need to provide more information.`,
      }
    : undefined;

  let title;
  if (notStarted) {
    // if the current user is the first user going through this flow, it's possible they will not have any
    // business reps yet (ie. they have not yet agreed to the 2 checkboxes in the Representative step)
    // in this case, this component should just ask them to start:
    title = 'Complete your business representative information';
  } else {
    if (bizRepMemberHasFailedKYC) {
      title = 'Your representative information';
    } else if (membership.hasAllPersonalInfo) {
      // member has completed all info
      title = `${
        isCurrentUser ? 'You completed your' : `${fullName} has completed their`
      } business representative information`;
    } else {
      // member has not completed all their info
      title = `${
        isCurrentUser ? 'Complete your' : `${fullName} still needs to complete their`
      } business representative information`;
    }
  }

  let cta;
  if (notStarted) {
    cta = 'Add +';
  } else {
    cta = 'Update';
  }

  const el = (
    <GuideSectionStatus
      callToAction={cta}
      description={undefined}
      error={error}
      isDisabled={!isCurrentUser}
      isRenderedOutsideGuideList={false}
      onClick={onClick}
      sectionURL={window.location.pathname}
      showCtaWhenComplete
      status={notStarted || !membership.hasAllPersonalInfo ? GuideStatuses.INCOMPLETE : GuideStatuses.COMPLETE}
      title={title}
    />
  );

  if (!isCurrentUser) {
    return (
      <TooltipMUI
        arrow
        padding={TooltipPadding.SKINNY}
        placement={TooltipPlacement.TOP}
        title={
          <Text.Regular lineHeight={TextLineHeight.EXTRA_MEDIUM} size={TextSize.LEVEL_100}>
            You can&apos;t view someone else&apos;s representative information.
          </Text.Regular>
        }
      >
        {el}
      </TooltipMUI>
    );
  }

  return el;
};

export default RepresentativeInformation;

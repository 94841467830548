import { combineReducers } from 'redux';

import allReducer from './allReducer';
import byIdReducer from './byIdReducer';

const membershipPersonalInfoReducer = combineReducers({
  allIds: allReducer,
  byId: byIdReducer,
});

export default membershipPersonalInfoReducer;

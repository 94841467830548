import * as routines from 'actions/routines/roles';

import { createIsFetchingReducer } from 'store/redux';

export const finishCases = [routines.fetchRolesRoutine.FAILURE, routines.fetchRolesRoutine.SUCCESS];

export const requestCases = [routines.fetchRolesRoutine.REQUEST];

const isFetchingReducer = createIsFetchingReducer(finishCases, requestCases);

export default isFetchingReducer;

import _without from 'lodash/without';

import { fetchItemsRoutine, sendSubmitBillToItemRequestRoutine, submitItemRoutine } from 'actions/routines/item';
import { deleteInboxItemRoutine } from 'actions/routines/item/delete';

import { allKeys } from 'helpers/utility';

import { RESET_BULK_ACTIONS_STATE } from 'types/bulkActions';
import * as types from 'types/item';
import { FETCH_PARTNERSHIP_ITEMS_SUCCESS } from 'types/partnership';

export const itemsAllIdsReducer = (state = [], action) => {
  switch (action.type) {
    case sendSubmitBillToItemRequestRoutine.SUCCESS:
    case submitItemRoutine.FULFILL:
    case types.FETCH_ITEM_SUCCESS:
    case types.SUBMIT_EXISTING_ITEMS_SUCCESS:
    case types.UPDATE_EXISTING_ITEMS_SUCCESS:
      return Array.from(new Set([...allKeys(action.payload.item), ...state]));

    case fetchItemsRoutine.SUCCESS:
    case FETCH_PARTNERSHIP_ITEMS_SUCCESS:
      return allKeys(action.payload.item);

    case RESET_BULK_ACTIONS_STATE:
      return [];

    case deleteInboxItemRoutine.SUCCESS:
      return _without(state, action.payload.id);

    default:
      return state;
  }
};

export default itemsAllIdsReducer;

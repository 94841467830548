import { updateCurrentUserRoutine } from 'actions/routines/user';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';

import { GET_CURRENT_USER_SUCCESS } from 'types/user';

const currentUserAddressesReducer = (state = null, action) => {
  switch (action.type) {
    case GET_CURRENT_USER_SUCCESS:
    case updateCurrentUserRoutine.SUCCESS:
      return getObjectsByIdWithRelationships(action.payload.userAddress);

    default:
      return state;
  }
};

export default currentUserAddressesReducer;

import { GET_CURRENT_COMPANY_SUCCESS } from 'types/currentCompany';

import { initialState } from './initialState';

const dashboardLoadedCurrentCompanyReducer = (state = initialState.currentCompany, action) => {
  switch (action.type) {
    case GET_CURRENT_COMPANY_SUCCESS:
      return true;

    default:
      return state;
  }
};

export default dashboardLoadedCurrentCompanyReducer;

import { Tooltip } from '@routable/gross-ds';
import clsx from 'clsx';
import _debounce from 'lodash/debounce';
import React, { useEffect } from 'react';
const setTextClampedOnSizeChange = (entries, setTextClamped) => {
    const { target } = entries[0];
    if (target.clientWidth === 0) {
        return;
    }
    const hasOverflow = target.scrollWidth > target.clientWidth;
    setTextClamped(hasOverflow);
};
export const TruncatableTextSingleLineResponsive = ({ dataFullStory, className, text, position = 'bottom', tooltipClassName, variant = 'light', maxLength, }) => {
    const ref = React.useRef(null);
    let isLongerThanMaxLength = false;
    let renderText = text;
    if (maxLength && text?.length) {
        isLongerThanMaxLength = text.length > maxLength;
        if (isLongerThanMaxLength) {
            renderText = `${text.slice(0, maxLength - 3).trim()}...`;
        }
    }
    const [textClamped, setTextClamped] = React.useState(isLongerThanMaxLength);
    useEffect(() => {
        if (isLongerThanMaxLength) {
            return;
        }
        const debouncedSetTextClampedOnSizeChange = _debounce((entries) => setTextClampedOnSizeChange(entries, setTextClamped), 600);
        const observer = new ResizeObserver(debouncedSetTextClampedOnSizeChange);
        const element = ref.current;
        if (element) {
            observer.observe(element);
        }
        return () => {
            if (element) {
                observer.unobserve(element);
            }
            observer.disconnect();
        };
    }, [isLongerThanMaxLength, textClamped]);
    return (React.createElement(Tooltip, { className: clsx('max-w-full', tooltipClassName), "data-testid": "truncated-text-tooltip", openDelay: 400, position: position, tooltip: textClamped ? text : null, variant: variant },
        React.createElement("div", { className: clsx(['overflow-ellipsis', 'overflow-hidden', 'whitespace-nowrap', className]), "data-fullstory": dataFullStory, "data-testid": "truncatable-text--singleline", ref: ref }, renderText)));
};

import queryString from 'query-string';
import swal from 'sweetalert';

import { ACCEPT } from 'constants/routes';
import { button } from 'constants/styles/formStyles';
import { SwalButtonStyles } from 'constants/swal';

import { dropQueryParams } from 'helpers/queryStrings';
import { makeSimilarUrl } from 'helpers/urls';
import { isEqual } from 'helpers/utility';

import { ExternalNeedVendorFlowSwalActions } from 'modules/external/ExternalNeedVendorFlowAlert/constants';

/**
 * Text helper to return the correct text for the ExternalNeedVendorFlowSwal
 * @param {Company} partner company
 * @returns {string}
 */
export const getExternalNeedVendorFlowSwalText = (partner) =>
  `You have been invited by ${partner.name} to be both invoiced and paid. To ensure that information is entered in our system correctly, please enter your payment details first.
  
  Once you have done so, you can return to this page and fill out any remaining information needed for you to be invoiced.`;

/**
 * Helper method to generate the correct vendor flow URL (without any items) for the ExternalNeedVendorFlowSwal
 * @returns {string}
 */
export const generateVendorFlowWithoutItemURL = () => {
  const queryParams = queryString.parse(window.location.search);
  const newQueryParams = dropQueryParams(queryParams, ['item_id']);
  return makeSimilarUrl(`${ACCEPT}/?${queryString.stringify(newQueryParams)}`);
};

/**
 * Helper method to show the ExternalNeedVendorFlowSwal
 * @param {Company} partner company
 * @returns {Promise<any>}
 */
export const showExternalNeedVendorFlowSwal = (partner) => {
  const confirmClasses = button.full.m.primary.split(' ');
  confirmClasses.push('remove-margin');

  const reloadButtonClasses = button.ghost.m.primary.split(' ');
  reloadButtonClasses.push('remove-margin');

  return swal({
    buttons: {
      reload: {
        className: SwalButtonStyles.GHOST_PRIMARY,
        closeModal: false,
        text: 'Reload page',
        value: {
          action: ExternalNeedVendorFlowSwalActions.RELOAD,
        },
        visible: true,
      },
      enterPaymentInfo: {
        className: SwalButtonStyles.PRIMARY,
        closeModal: true,
        text: 'Enter payment info',
        value: {
          action: ExternalNeedVendorFlowSwalActions.REDIRECT,
        },
        visible: true,
      },
    },
    className: 'swal-confirmation',
    closeOnClickOutside: false,
    text: getExternalNeedVendorFlowSwalText(partner),
    title: 'Before you continue...',
  });
};

/**
 * Helper to handle showing (continuously) the SWAL for when an external customer needs to go through the vendor flow
 * Takes in either a redirect or reload action
 * @returns {Promise<void>}
 */
export const handleExternalNeedVendorFlowSwal = async (partner) => {
  const { action } = await showExternalNeedVendorFlowSwal(partner);

  if (isEqual(action, ExternalNeedVendorFlowSwalActions.REDIRECT)) {
    window.open(generateVendorFlowWithoutItemURL());

    // Reload the SWAL
    await handleExternalNeedVendorFlowSwal(partner);
  } else if (isEqual(action, ExternalNeedVendorFlowSwalActions.RELOAD)) {
    window.location.reload();
  }
};

import PropTypes from 'prop-types';
import React from 'react';

import { sizes, typography } from 'constants/styles';
import { TagShape, TagType } from 'constants/ui';

import { tagTypeProp } from 'helpers/propTypes';
import { getTextComponentForTagLabel } from 'helpers/tag';
import { getClassNames, isTagShapeRound, isTagShapeSquare } from 'helpers/ui';
import { allValues } from 'helpers/utility';

import TagClose from '../TagClose';
import TagIcon from '../TagIcon';
import TagTooltip from '../TagTooltip';

/**
 * Composes the tag body, label, and close button into a single structure.
 * @param {ComponentProps} props
 * @param {*} props.children
 * @param {boolean} props.hasIcon
 * @param {number} props.iconSize
 * @param {boolean} props.isClosable
 * @param {function} props.onClose
 * @param {Object} props.shape
 * @param {*} props.TextComponent
 * @param {number} props.textSize
 * @param {string} props.type
 * @param {*} props.tooltipContent
 * @param {object} props.tooltipProps
 * @param {boolean} props.readOnly
 * @return {StatelessComponent}
 */
const Tag = (props) => {
  const {
    children,
    hasIcon,
    iconSize,
    isClosable,
    onClose,
    shape,
    TextComponent,
    textSize,
    type,
    tooltipContent,
    tooltipProps,
    readOnly,
  } = props;

  const TextWrapper = getTextComponentForTagLabel(TextComponent, type);

  // round tags are 1:1 for width:height, and display an icon only,
  // thus should always use the icon, and should not use text
  const isRound = isTagShapeRound(shape);
  const isSquare = isTagShapeSquare(shape);
  const isRoundOrSquare = isRound || isSquare;

  const displayIcon = hasIcon || isRoundOrSquare;
  const displayText = !!children && !isRoundOrSquare;
  const displayCloseIcon = isClosable && !isRoundOrSquare;

  return (
    <TagTooltip tooltipContent={tooltipContent} tooltipProps={tooltipProps} type={type} {...props}>
      <div
        className={getClassNames(props, {
          tag: true,
          readOnly: !!readOnly,
          [shape.toLowerCase()]: true,
          [type.toLowerCase()]: true,
        })}
      >
        {displayIcon && <TagIcon size={iconSize} type={type} />}

        {displayText && (
          <span className="tag-label">
            <TextWrapper className="tag-label-text" size={textSize}>
              {children}
            </TextWrapper>
          </span>
        )}

        {displayCloseIcon && <TagClose onClick={onClose} shape={shape} type={type} />}
      </div>
    </TagTooltip>
  );
};

Tag.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  hasIcon: PropTypes.bool,
  iconSize: PropTypes.oneOf(allValues(sizes.iconSizes)),
  isClosable: PropTypes.bool,
  onClose: PropTypes.func,
  shape: PropTypes.string,
  readOnly: PropTypes.bool,
  TextComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  textSize: PropTypes.oneOf(allValues(typography.TextSize)),
  tooltipContent: PropTypes.node,
  tooltipProps: PropTypes.shape(),
  type: tagTypeProp,
};

Tag.defaultProps = {
  children: undefined,
  className: undefined,
  hasIcon: undefined,
  iconSize: sizes.iconSizes.LARGE,
  isClosable: true,
  onClose: undefined,
  readOnly: undefined,
  shape: TagShape.RECT,
  TextComponent: undefined,
  textSize: undefined,
  tooltipContent: undefined,
  tooltipProps: undefined,
  type: TagType.DEFAULT,
};

export default Tag;

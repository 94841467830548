import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2, IconNames } from 'components';

import { ButtonSize } from 'constants/button';
import { sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

const ActionPrimaryButton = ({ isDisabled, label, onClick }) => (
  <ButtonV2
    intent={Intent.PRIMARY}
    isDisabled={isDisabled}
    leftIconClassName="margin-right--sm"
    leftIconName={IconNames.COMPARISON}
    leftIconSize={sizes.iconSizes.LARGE}
    onClick={onClick}
    size={ButtonSize.SMALL}
  >
    <span className="bold">{label}</span>
  </ButtonV2>
);

ActionPrimaryButton.propTypes = {
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

ActionPrimaryButton.defaultProps = {
  isDisabled: undefined,
};

export default ActionPrimaryButton;

import PropTypes from 'prop-types';
import React from 'react';

import ShadedBlock from 'components/shadedBlock';
import { SwalWrapper } from 'components/swal';

import { Intent } from 'constants/ui';

import { convertSupportedMimeTypesToExtension } from 'helpers/mimeTypes';

import { title, subtitle, confirmButtonProps } from './constants/text';

/**
 * Returns a SWAL shown in CreateItem when submitting with an un-supported file type
 * @param {ComponentProps} props
 * @param {string} props.errorMsg
 * @return {StatelessComponent}
 * @constructor
 */
const UnsupportedFileTypeSwal = ({ errorMsg }) => (
  <SwalWrapper confirmButtonProps={confirmButtonProps} subtitle={subtitle} title={title}>
    <ShadedBlock intent={Intent.NEUTRAL}>{convertSupportedMimeTypesToExtension(errorMsg)}</ShadedBlock>
  </SwalWrapper>
);

UnsupportedFileTypeSwal.propTypes = {
  errorMsg: PropTypes.string.isRequired,
};

export default UnsupportedFileTypeSwal;

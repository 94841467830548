import { createSelector } from 'reselect';

import { TinTypes } from 'constants/taxes';

import { isBusinessTypeSoleProprietor, isCurrentCompanyTypePersonal } from 'helpers/currentCompany';

import { currentCompanyMailingAddressSelector } from 'queries/currentCompanyCompoundSelectors';
import { currentMembershipPersonalInfoSelector } from 'queries/membershipPersonalInfoCompoundSelectors';
import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

import {
  currentCompanyAddressSelector,
  currentCompanyInfoSelector,
  currentCompanySelector,
} from 'selectors/currentCompanySelectors';
import { currentUserSelector } from 'selectors/currentUserSelectors';
import { taxesDateTaxInfoSubmittedForPartnershipSelector } from 'selectors/taxesSelectors';

export const deriveTinTypeFromState = createSelector(
  [currentCompanySelector, currentMembershipPersonalInfoSelector],
  (currentCompany, currentMembershipPersonalInfo) => {
    const { ein } = currentCompany;
    const { hasSsn } = currentMembershipPersonalInfo || {};

    // all personal companies use SSN
    if (isCurrentCompanyTypePersonal(currentCompany)) {
      return TinTypes.SSN;
    }

    // businesses
    const soleProprietor = isBusinessTypeSoleProprietor(currentCompany.businessType);

    if (soleProprietor && !ein && hasSsn) {
      return TinTypes.SSN;
    }

    return TinTypes.EIN;
  },
);

export const taxFilingDetailsSelector = createSelector(
  [
    currentCompanyAddressSelector,
    currentCompanyInfoSelector,
    currentCompanyMailingAddressSelector,
    currentCompanySelector,
    currentUserSelector,
    deriveTinTypeFromState,
    partnershipFromQuerySelector,
    taxesDateTaxInfoSubmittedForPartnershipSelector,
  ],
  (
    currentCompanyAddress,
    currentCompanyInfo,
    currentCompanyMailingAddress,
    currentCompany,
    currentUser,
    tinType,
    partnership,
    submissionDate,
  ) => ({
    businessType: currentCompanyInfo.businessType,
    companyName: currentCompany.name,
    companyType: currentCompany.companyType,
    ein: currentCompanyInfo.ein,
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    mailingAddress: currentCompanyMailingAddress,
    partnerCompanyName: partnership.name,
    submissionDate,
    tinType,
  }),
);

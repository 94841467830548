import { connect } from 'react-redux';

import { bankAccountFields, FORM_FIELD_PREFIX } from 'constants/formFields';

import { every, isEqual } from 'helpers/utility';

import { createFormSingleValueSelector } from 'selectors/createFormSelectors';

import ConnectBankManualForm from './ConnectBankManualForm';

const mapStateToProps = (state, ownProps) => {
  const formPath = ownProps.formPath || FORM_FIELD_PREFIX;

  const accountNumberFieldName = `${formPath}.${bankAccountFields.ACCOUNT_NUMBER}`;
  const confirmAccountNumberFieldName = `${formPath}.${bankAccountFields.CONFIRM_ACCOUNT_NUMBER}`;

  const formSelector = createFormSingleValueSelector(ownProps.formName);

  const accountNumberValue = formSelector(state, accountNumberFieldName);
  const confirmAccountNumberValue = formSelector(state, confirmAccountNumberFieldName);

  // bank account number field is only valid when its not empty and it equals the confirm account number value
  // used for showing/hiding the success icon on the bankAccountNumber field
  const isBankAccountNumberValid = every([
    !!accountNumberValue,
    !!confirmAccountNumberValue,
    isEqual(accountNumberValue, confirmAccountNumberValue),
  ]);

  return {
    bankName: formSelector(state, 'ui.bankInstitutionName'),
    isBankAccountNumberValid,
  };
};

export default connect(mapStateToProps)(ConnectBankManualForm);

import { fetchExternalPartnershipRequestRoutine, submitFundingStateRoutine } from 'actions/routines/external';

const metaReducer = (state = {}, action) => {
  switch (action.type) {
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
    case submitFundingStateRoutine.SUCCESS:
      return action.payload.meta;

    default:
      return state;
  }
};

export default metaReducer;

import { REST } from 'helpers/api';
import { createCompanyAddressEndpoint, singleCompanyAddressEndpoint } from 'services/api/companyEndpoints';
import { payloadToJSONAPI } from 'services/api/formatHelpers';
export const createCompanyMailingAddress = async (values, companyId) => REST.post({
    endpoint: createCompanyAddressEndpoint(companyId),
    requireAuth: true,
    setRequester: true,
    payload: payloadToJSONAPI(values.address, 'address'),
});
export const updateCompanyMailingAddress = async (company, values) => REST.patch({
    endpoint: singleCompanyAddressEndpoint(company.id, company.mailingAddress),
    requireAuth: true,
    setRequester: true,
    payload: payloadToJSONAPI(values.address, 'address'),
});

import React from 'react';
import { IconNames, Text, TooltipMUIIcon } from 'components';
import { colors } from 'constants/styles';
import { TooltipMaxWidth, TooltipPadding } from 'constants/tooltip';
import { getCountryNameFromCode } from 'helpers/countries';
import { getExchangeRateText } from 'helpers/currency';
import { formatCurrencyExplicit } from 'helpers/currencyFormatter';
import { useCountryCodesContext, useCurrencyContext } from 'hooks';
import { TooltipEntry } from '..';
import {} from './CurrencyConversionRateTooltip.types';
const CurrencyConversionRateTooltip = ({ conversionCurrency, country, currencyCodeReceiver, exchangeRate, hasConversion, isExchangeRateLocked, plainAmount, }) => {
    const currencyCodeMap = useCurrencyContext();
    const countryCodeMap = useCountryCodesContext();
    const formattedExchangeRate = getExchangeRateText(exchangeRate, currencyCodeReceiver, currencyCodeMap, {
        precision: 4,
    });
    const formattedAmount = formatCurrencyExplicit(plainAmount, currencyCodeReceiver, currencyCodeMap);
    return (React.createElement(TooltipMUIIcon, { icon: IconNames.GLOBE, iconClassName: "tooltip-icon", iconColor: colors.colorGreyMedHex, padding: TooltipPadding.SKINNY, title: React.createElement("div", { className: "flex-col" },
            React.createElement("div", { className: "flex" },
                React.createElement(TooltipEntry, { label: "Country", value: getCountryNameFromCode(countryCodeMap, country) }),
                React.createElement(TooltipEntry, { label: "Amount", value: formattedAmount }),
                hasConversion && React.createElement(TooltipEntry, { label: "Conversion rate", value: formattedExchangeRate })),
            !isExchangeRateLocked && hasConversion && (React.createElement(Text.Regular, { className: "margin-top--m" }, `Amount in ${conversionCurrency} is estimated.`))), tooltipProps: {
            arrow: true,
            maxWidth: TooltipMaxWidth.NONE,
        } }));
};
export default CurrencyConversionRateTooltip;

import { combineReducers } from 'redux';

import allReducer from './allReducer';
import byIdReducer from './byIdReducer';
import errorReducer from './errorReducer';
import isDisablingReducer from './isDisablingReducer';
import isFetchingReducer from './isFetchingReducer';
import isReactivatingReducer from './isReactivatingReducer';
import isUpdatingReducer from './isUpdatingReducer';
import lastFetchedReducer from './lastFetchedReducer';
import lastUpdatedReducer from './lastUpdatedReducer';

const membershipsReducer = combineReducers({
  allIds: allReducer,
  byId: byIdReducer,
  errors: errorReducer,
  isDisabling: isDisablingReducer,
  isFetching: isFetchingReducer,
  isReactivating: isReactivatingReducer,
  isUpdating: isUpdatingReducer,
  lastFetched: lastFetchedReducer,
  lastUpdated: lastUpdatedReducer,
});

export default membershipsReducer;

import { createIsFetchingReducer } from 'store/redux';

import {
  FETCH_NOTIFICATION_TABLE_DATA_FAILURE,
  FETCH_NOTIFICATION_TABLE_DATA_REQUEST,
  FETCH_NOTIFICATION_TABLE_DATA_SUCCESS,
} from 'types/notifications';

export const finishCases = [FETCH_NOTIFICATION_TABLE_DATA_FAILURE, FETCH_NOTIFICATION_TABLE_DATA_SUCCESS];
export const requestCases = [FETCH_NOTIFICATION_TABLE_DATA_REQUEST];

export default createIsFetchingReducer(finishCases, requestCases);

import PropTypes from 'prop-types';
import React from 'react';

/**
 * Renders an approximation of a mock image, with configurable dimensions and content.
 * @param {Object} props
 * @param {string} props.content
 * @param {string|number} props.height
 * @param {string|number} props.width
 * @returns {StatelessComponent}
 */
export const MockImage = ({ content, height, width }) => (
  <div
    style={{
      backgroundColor: 'rgb(170,170,170)',
      wordBreak: 'break-all',
      display: 'inline-flex',
      flexDirection: 'column',
      fontSize: '18px',
      height: `${height}px`,
      justifyContent: 'center',
      padding: '8px',
      textAlign: 'center',
      width: `${width}px`,
    }}
  >
    {content.replace(/\+/g, ' ')}
  </div>
);

MockImage.propTypes = {
  content: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

MockImage.defaultProps = {
  content: '',
};

export const AlreadyCompletedSideCard = (props) => (
  <MockImage
    {...props}
    content="AlreadyCompletedSideCard"
    height="72"
    width="224"
  />
);

export const NoLongerNeeded = (props) => (
  <MockImage {...props} content="NoLongerNeeded" height="240" width="240" />
);

export const PendingPaymentsToAcceptList = (props) => (
  <MockImage
    {...props}
    content="PendingPaymentsToAcceptList"
    height="148"
    width="986"
  />
);

export const UnauthorizedSvg = (props) => (
  <MockImage {...props} content="UnauthorizedSvg" height="240" width="240" />
);

import React from 'react';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { ProtectedRouteWarn } from 'components/routing';
import * as text from 'components/routing/ProtectedRouteWarnDisabledUser/text';

import { DASHBOARD, LOGIN } from 'constants/routes';

import { handleClearLocalStorage } from 'helpers/localStorage';
import { asPath } from 'helpers/routeHelpers';

import { DisabledUserContactUsLink } from './components';

/**
 * The fallback component to render, should access be denied to to a disabled user.
 *
 * @returns {StatelessComponent}
 * @constructor
 */
const ProtectedRouteWarnDisabledUser = () => {
  const onSwitchAccounts = () => {
    // we can't actually fire the normal "logout" request here,
    // as the auth token is already destroyed, technically making us
    // not authorized to logout. thus, just clear local storage, and
    // redirect to the login page.
    handleClearLocalStorage();
    window.location.assign(asPath(LOGIN));
  };

  return (
    <ProtectedRouteWarn
      actionText={text.protectedRouteActionText}
      message={text.protectedRouteWarnMessage}
      onPrimaryAction={onSwitchAccounts}
      returnLinkAs={DisabledUserContactUsLink}
      returnText={text.protectedRouteReturnText}
      returnUrl={asPath(DASHBOARD)}
      title={text.protectedRouteWarnTitle}
    />
  );
};

export default ProtectedRouteWarnDisabledUser;

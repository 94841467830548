import { updatePartnershipGeneralInfoRoutine } from 'actions/routines/partnership';

import { logEntryRelationships } from 'constants/relationships';

import { createByIdReducer } from 'store/redux';

import { FETCH_PARTNERSHIP_SUCCESS } from 'types/partnership';

export default createByIdReducer({
  initialState: {},
  key: 'logEntry',
  relationships: logEntryRelationships,
  types: [FETCH_PARTNERSHIP_SUCCESS, updatePartnershipGeneralInfoRoutine.SUCCESS],
});

import PropTypes from 'prop-types';
import React from 'react';

import { DashboardToDashboardRestrictionTooltipContent, Text } from 'components';

import { getAddContactButtonDisabledTooltipText, isPartnershipRoutableToRoutable } from 'helpers/partnerships';

/**
 * We render a tooltip if the button is disabled.
 * @param {Partnership} partnership
 */
const AddContactButtonTooltipContent = ({ partnership }) => {
  const isRegisteredToRegistered = isPartnershipRoutableToRoutable(partnership);

  return (
    <>
      {isRegisteredToRegistered && <DashboardToDashboardRestrictionTooltipContent />}
      {!isRegisteredToRegistered && <Text.Regular>{getAddContactButtonDisabledTooltipText(partnership)}</Text.Regular>}
    </>
  );
};

AddContactButtonTooltipContent.propTypes = {
  partnership: PropTypes.shape({}).isRequired,
};

export default AddContactButtonTooltipContent;

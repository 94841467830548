// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const CSV = 'text/csv';
export const JPEG = 'image/jpeg';
export const PDF = 'application/pdf';
export const PNG = 'image/png';
export const TEXT = 'text/plain';
export const MESSAGE = 'message/rfc822';

// mime-types supported by the API
// we may support more in the future
// we also support message/rfc822
// this is used to try and make as many mimeTypes as possible easily to read by users in convertSupportedMimeTypesToExtension
export const SUPPORTED_MIME_TYPES = [MESSAGE, DOCX, XLSX, CSV, JPEG, PDF, PNG, TEXT];
export const BILL_ATTACHMENT_MIME_TYPES = SUPPORTED_MIME_TYPES;

import React from 'react';
import { useDebouncedField } from 'hooks';
import NumericInputWrapper from './NumericInputWrapper';
import {} from './ReduxFormFieldRenderNumericInput.types';
const ReduxFormFieldRenderNumericInput = ({ debounceDelay, input, isDebounced, meta, ...rest }) => {
    const { onChange, value } = useDebouncedField({
        debounceDelay,
        isDebounced,
        input,
    });
    const errors = (meta.touched || meta.submitFailed) && meta.error;
    return (React.createElement(NumericInputWrapper, { ...rest, errors: errors, hasErrors: !!errors, name: input.name, onBlur: input.onBlur, onChange: onChange, onFocus: input.onFocus, value: value }));
};
export default ReduxFormFieldRenderNumericInput;

import { createIsSubmittingReducer } from 'store/redux';

import {
  CLEAR_FUNDING_ACCOUNT_ADDRESS_MANUAL,
  SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_FAILURE,
  SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_REQUEST,
  SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_SUCCESS,
} from 'types/funding';

export const finishCases = [
  CLEAR_FUNDING_ACCOUNT_ADDRESS_MANUAL,
  SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_FAILURE,
  SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_SUCCESS,
];
export const requestCases = [SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_REQUEST];

const submitReducer = createIsSubmittingReducer(finishCases, requestCases);

export default submitReducer;

import { getURLWithQueryParams } from 'helpers/urls';

import { BASE_API_URL } from 'services/api/index';

export const membershipsEndpoint = `${BASE_API_URL}/memberships/`;
export const getMembershipEndpoint = (membershipId) => `${membershipsEndpoint}${membershipId}/`;
export const getMembershipConfigEndpoint = (membershipId) => `${membershipsEndpoint}${membershipId}/config/`;
export const getMembershipSwitchEndpoint = (membershipId) => `${membershipsEndpoint}${membershipId}/switch/`;

/**
 * URL to POST to for disabling a membership. Useful when "deactivating" a team member.
 * @param {Membership.id} membershipId
 * @returns {string} url
 */
export const getDisableMembershipEndpoint = (membershipId) => `${getMembershipEndpoint(membershipId)}disable/`;

/**
 * URL to POST to for enabling a membership. Used when reactivating a disabled team member.
 * @param {Membership.id} membershipId
 * @returns {string} url
 */
export const getEnableMembershipEndpoint = (membershipId) => `${getMembershipEndpoint(membershipId)}enable/`;

/**
 * Use a querystring to search for membership invites matching an email address.
 * @param {Object} queryParams
 * @param {StringMaybe} queryParams.email
 * @returns {string} url
 */
export const fetchMembershipInviteEmailEndpoint = (queryParams) =>
  getURLWithQueryParams(`${membershipsEndpoint}email_lookup/`, queryParams);

export const postMembershipDocumentEndpoint = ({ currentMemberId }) =>
  `${getMembershipEndpoint(currentMemberId)}documents/`;

export const deleteMembershipDocumentEndpoint = ({ currentMemberId, documentId }) =>
  `${getMembershipEndpoint(currentMemberId)}documents/${documentId}/`;

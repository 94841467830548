/**
 * International Billing codes
 * @enum {string}
 */
export const InternationalBillingCode = {
  /** International Currency Exchange */
  AP_INTERNATIONAL_CURRENCY_EXCHANGE: 'ap_international_currency_exchange',
  /** International Flat fee  */
  AP_INTERNATIONAL: 'ap_international',
  /** International cross-border fee */
  AP_INTERNTAIONAL_CROSSBORDER: 'ap_international_crossborder_fee',
};

/**
 * Billing codes used for balance account withdraw/deposit.
 * @enum {string}
 */
export const BalanceTransferBillingCode = {
  AP_BALANCE_TRANSFER: 'ap_balance_transfer',
  AR_BALANCE_TRANSFER: 'ar_balance_transfer',
};

/**
 * Billing code prefixes indicating whether it's a payable or receivable.
 * @enum {string}
 */
export const BillingCodePrefix = {
  ACCOUNTS_PAYABLE: 'ap_',
  ACCOUNTS_RECEIVABLE: 'ar_',
  /**
   * Billing code prefix for SWIFT and Local Tranfer.
   * The prefix is usually followed by a currency code.
   * ex: ap_international_transfer_sgd
   */
  INTERNATIONAL_TRANSFER: 'ap_international_transfer_',
  /**
   * Billing code prefix specifically for SWIFT USD
   * The prefix is followed by a tier number (1-4)
   * ex: ap_international_transfer_swift_tier_1
   */
  INTERNATIONAL_SWIFT_USD: 'ap_international_transfer_swift_tier',
};

/**
 * Billing code base types.
 * @enum {string}
 */
export const BillingCodeBase = {
  FAILURE: 'failure',
  SEAT: 'seat',
  TRANSACTION: 'transaction',
};

/**
 * Billing codes for adding funding memo, which is a bank line note that can be attached to a bank transfer
 * Two types here are the ACH addenda records and the RTP remittance info
 * @enum {string}
 */
export const BillingCodeFundingMemo = {
  ACH_ADDENDA: 'ap_ach_addenda',
  RTP_REMITTANCE: 'ap_rtp_remittance',
};

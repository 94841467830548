import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components';

import { typography } from 'constants/styles';

/**
 * Component rendering either company brand logo or company name as text
 * if no logo is set
 * @param {ComponentProps} props
 * @param {Brand} props.brand
 * @param {Company} props.company
 * @return {StatelessComponent}
 */
const ExternalFlowHeaderLogo = ({ brand, company }) => {
  const hasBrandLogo = Boolean(brand.logo);

  if (hasBrandLogo) {
    // Display company logo image if set
    return <img alt={company.name} src={brand.logo} />;
  }

  // Fallback to displaying company name if no brand logo is set
  return (
    <Text.Semibold className="external__header--company-logo--text" color={typography.TextColor.BLACK}>
      {company.name}
    </Text.Semibold>
  );
};

ExternalFlowHeaderLogo.propTypes = {
  brand: PropTypes.shape({
    logo: PropTypes.string,
  }).isRequired,
  company: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export default ExternalFlowHeaderLogo;

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ButtonV2, ConfirmationSectionHeader } from 'components';
import { IconNames } from 'components/icon';

import { ButtonSize } from 'constants/button';
import { sizes } from 'constants/styles';

import { isCurrentCompanyInInviteStage } from 'helpers/currentCompany';

import PartnerCompanyPaymentMethodContainer from 'modules/createPartnerCompany/containers/PartnerCompanyPaymentMethodContainer';
import ExternalPromptHeader from 'modules/external/externalPrompt/global/presenters/ExternalPromptHeader';
import ExternalPromptInstructions from 'modules/external/externalPrompt/global/presenters/ExternalPromptInstructions';
import NextSteps from 'modules/external/externalPrompt/global/presenters/NextSteps';

import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

import { currentCompanySelector } from 'selectors/currentCompanySelectors';

class ExternalPromptCustomerNoItems extends React.Component {
  renderBodyInvite = () => {
    const { currentCompany, partnership } = this.props;

    if (!isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    const instructions = [
      {
        id: 1,
        key: '1',
        text: `Register as a ${partnership.name} customer by sharing your business info.`,
      },
      {
        id: 2,
        key: '2',
        text: 'Select a preferred payment method for paying your invoices.',
      },
      {
        id: 3,
        key: '3',
        text: `Receive a notification when funds have transferred to ${partnership.name}.`,
      },
    ];

    return <ExternalPromptInstructions instructions={instructions} isAboveButton />;
  };

  renderBodyInviteExisting = () => {
    const { currentCompany, partnership } = this.props;

    if (isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    if (partnership.defaultPayableFundingAccount) {
      return null;
    }

    return (
      <div className="margin-top--large">
        <PartnerCompanyPaymentMethodContainer />
      </div>
    );
  };

  renderBodyConfirmation = () => {
    const { currentCompany, partnership } = this.props;

    if (isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    if (!partnership.defaultPayableFundingAccount) {
      return null;
    }

    const instructions = [
      {
        id: 1,
        key: '1',
        text: `You are set up as a ${partnership.name} customer.`,
      },
      {
        id: 2,
        key: '2',
        text: 'Your preferred payment method has been saved.',
      },
      {
        id: 3,
        key: '3',
        text: 'You will receive notifications when new invoices arrive.',
      },
    ];

    return (
      <React.Fragment>
        <NextSteps />
        <ExternalPromptInstructions instructions={instructions} />
      </React.Fragment>
    );
  };

  renderButtonInvite = () => {
    const { currentCompany, onStart } = this.props;

    if (!isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    return (
      <ButtonV2
        className="external--light"
        leftIconClassName="margin-right--xm"
        leftIconName={IconNames.SHIELD}
        leftIconSize={sizes.iconSizes.LARGE}
        onClick={onStart}
        rightIconName={IconNames.ARROW_RIGHT}
        rightIconProps={{ style: { marginLeft: 'auto' } }}
        rightIconSize={sizes.iconSizes.LARGE}
        size={ButtonSize.LARGE}
      >
        Become our customer
      </ButtonV2>
    );
  };

  renderHeaderInvite = () => {
    const { currentCompany, partnership } = this.props;

    if (!isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    return <ExternalPromptHeader showRequester text={`Become a ${partnership.name} customer`} />;
  };

  renderHeaderConfirmation = () => {
    const { currentCompany, partnership } = this.props;

    if (isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    if (!partnership.defaultPayableFundingAccount) {
      return null;
    }

    return (
      <React.Fragment>
        <ConfirmationSectionHeader text="Confirmed" />
        <ExternalPromptHeader text={`You are set up as a ${partnership.name} customer`} />
      </React.Fragment>
    );
  };

  renderHeaderInviteExisting = () => {
    const { currentCompany, partnership } = this.props;

    if (isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    if (partnership.defaultPayableFundingAccount) {
      return null;
    }

    return <ExternalPromptHeader showRequester text="Select a secure payment method for making payments" />;
  };

  render() {
    return (
      <React.Fragment>
        {/* Prompt header */}
        {this.renderHeaderConfirmation()}
        {this.renderHeaderInvite()}
        {this.renderHeaderInviteExisting()}

        {/* Prompt body */}
        {this.renderBodyConfirmation()}
        {this.renderBodyInvite()}
        {this.renderBodyInviteExisting()}

        {/* Prompt button */}
        {this.renderButtonInvite()}
      </React.Fragment>
    );
  }
}

ExternalPromptCustomerNoItems.propTypes = {
  currentCompany: PropTypes.shape().isRequired,
  onStart: PropTypes.func.isRequired,
  partnership: PropTypes.shape().isRequired,
};

ExternalPromptCustomerNoItems.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  currentCompany: currentCompanySelector,
  partnership: partnershipFromQuerySelector,
});

export default connect(mapStateToProps)(ExternalPromptCustomerNoItems);

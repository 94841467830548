import React from 'react';

import { measureElement } from 'helpers/dom';

/**
 * @param {OptionsArg} options
 * @param {React.Ref} options.elementRef
 * @return {*}
 */
const useRenderedSize = ({ elementRef }) => {
  const [size, setSize] = React.useState();

  React.useLayoutEffect(() => {
    if (!size && elementRef.current) {
      setSize(measureElement(elementRef.current));
    }
  }, [elementRef, size]);

  return size;
};

export default useRenderedSize;

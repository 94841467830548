import PropTypes from 'prop-types';
import React from 'react';

const BoxBody = ({ children }) => <div className="box--body">{children}</div>;

BoxBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BoxBody;

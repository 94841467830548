import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components';

import { ButtonSize } from 'constants/button';
import { Intent } from 'constants/ui';

import { getIconNameForStep, getRightIconPropsForStep, isStepDisabled } from 'helpers/progressBarStepper';
import { noop } from 'helpers/utility';

/**
 * StepperButtons
 * Renders steps and substeps for ProgressBarStepper
 * @see {ProgressBarStepper}
 *
 * @param {ComponentProps} props
 * @param {object[]} props.steps
 * @param {Function} [props.onClickStep]
 * @param {boolean} [props.isVertical]
 * @return {StatelessComponent}
 */
const StepperButtons = ({ isVertical, steps, onClickStep }) => {
  const iconSize = isVertical ? 18 : 14;
  const iconClassName = isVertical ? 'margin-right--m' : 'margin-right--xm';

  return steps.map((step, i) => (
    <div className="stepper--step" key={step.label}>
      <ButtonV2
        className={classNames('stepper--button display--inline-flex', {
          'font-color--black--important': !!step.isActive && !isVertical,
          'font-color--blue-bold--important': !!step.isActive && !!isVertical,
          'font-color--grey--dark--important': !step.isActive && !!isVertical,
        })}
        intent={Intent.GHOST}
        isDisabled={isStepDisabled(step)}
        leftIconClassName={iconClassName}
        leftIconName={getIconNameForStep(step)}
        leftIconSize={iconSize}
        onClick={() => onClickStep(i)}
        size={ButtonSize.SMALL}
        {...getRightIconPropsForStep(step, iconSize)}
      >
        <span>{step.label}</span>
      </ButtonV2>

      <div className="stepper--step--substeps">
        {!!step.isActive &&
          step.substeps?.map((substep) => (
            <ButtonV2
              className={classNames('stepper--button display--inline-flex', {
                'font-color--blue-bold--important': !!substep.isActive && !!isVertical,
                'font-color--grey--dark--important': !substep.isActive && !!isVertical,
              })}
              intent={Intent.GHOST}
              key={substep.label}
              leftIconClassName={iconClassName}
              leftIconName={getIconNameForStep(substep)}
              leftIconSize={iconSize}
              size={ButtonSize.SMALL}
            >
              <span>{substep.label}</span>
            </ButtonV2>
          ))}
      </div>
    </div>
  ));
};

StepperButtons.propTypes = {
  isVertical: PropTypes.bool,
  onClickStep: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

StepperButtons.defaultProps = {
  isVertical: undefined,
  onClickStep: noop,
};

export default StepperButtons;

import merge from 'deepmerge';
import _pickBy from 'lodash/pickBy';

import { CompanySearchPartnerTypes } from 'constants/company';
import PermissionResourceAction from 'constants/permissions';

import { getItemMembersInitialValuesForItemEdit } from 'helpers/itemMember';
import {
  getDateScheduledTypeInitialValueForItemEdit,
  getItemDateScheduledInitialValueForItemEdit,
  isItemKindReceivable,
  isItemStatusNextReadyToSend,
  isItemStatusReadyToSend,
} from 'helpers/items';
import { isPartnershipMemberFromPartnershipPartner } from 'helpers/partnershipMembers';
import { createPartnershipSelectName } from 'helpers/partnerships';
import {
  getPaymentDeliveryMethodInitialValueForItemEdit,
  getPaymentDeliveryMethodsAcceptedInitialValueForItemEdit,
  getPaymentMethodOptionsInitialValue,
} from 'helpers/paymentMethods';
import { checkMemberHasRequiredPermissions } from 'helpers/permissions';
import { cleanObjectOfEmptyValues } from 'helpers/transform';

import { getInitialValues } from '../createItems';

import { generateInitialValuesFromItem } from './reverseParser';

/**
 * Returns the initial values of the createItems form used in Item Edit flow
 * @param {object} props
 * @param {Company[]} props.companies
 * @param {Item} props.item
 * @param {ItemMember[]} props.itemMembers
 * @param {object} props.meta
 * @param {Partnership[]} props.partnerships
 * @param {PartnershipMember[]} partnershipMembers
 * @param {ItemPaymentDeliveryMethod[]} props.payablePaymentMethods
 * @param {ItemPaymentDeliveryMethod[]} props.receivablePaymentMethods
 * @param {object} props.ui
 * @param {object} props.viewModelManager
 * @param {LedgerTaxCode[]} props.allLedgerTaxCodes
 * @param {Attachment[]} props.attachments
 * @returns {Object} the initial values
 */
export const getItemEditInitialValues = ({
  companies,
  item,
  itemMembers,
  meta,
  partnerships,
  partnershipMembers,
  payablePaymentMethods,
  receivablePaymentMethods,
  ui,
  viewModelManager,
  attachments,
  allLedgerTaxCodes,
  currentMemberPermissionSet,
}) => {
  const itemMembersForItem = getItemMembersInitialValuesForItemEdit({
    item,
    itemMembers,
    partnershipMembers,
  });

  const partnershipMembersForItem = _pickBy(partnershipMembers, (el) =>
    isPartnershipMemberFromPartnershipPartner(el, item),
  );

  const paymentDeliveryMethod = getPaymentDeliveryMethodInitialValueForItemEdit(item);

  const currentCompanyPaymentMethods = isItemKindReceivable(item) ? receivablePaymentMethods : payablePaymentMethods;

  const paymentMethodOptions = getPaymentMethodOptionsInitialValue({
    isInternational: item?.isInternational,
    methodsAccepted: currentCompanyPaymentMethods,
  });

  const paymentDeliveryMethodsAccepted = getPaymentDeliveryMethodsAcceptedInitialValueForItemEdit({
    isInternational: item?.isInternational,
    methodsAccepted: item?.paymentDeliveryMethodsAccepted,
  });

  const partnership = partnerships?.[item.partnership] || {};

  const companyName = createPartnershipSelectName(partnership);

  const itemAttachments = item.attachments?.map?.((attachmentId) => attachments[attachmentId]) || [];

  const hasPermissionToSendItem = checkMemberHasRequiredPermissions({
    actualMemberPermissionSet: currentMemberPermissionSet,
    requiredPermissions: [PermissionResourceAction.PAYABLE_SEND, PermissionResourceAction.RECEIVABLE_SEND],
  });

  const dateScheduled = getItemDateScheduledInitialValueForItemEdit(item);
  const dateScheduledType = getDateScheduledTypeInitialValueForItemEdit(item, hasPermissionToSendItem);

  let sendItem = !(isItemStatusReadyToSend(item) || isItemStatusNextReadyToSend(item));

  /**
   * If item was created by a RCTM with :send permission but is edited by an RCTM
   * without :send permission, we make the sendItem false, and dateScheduledType
   * equal to SKIP,so the item gets sent as `ready-to-send` when submitted.
   * Note: we keep the item.dateScheduled on the initialValues so that it stays in state
   * and can be later removed by dateScheduledForCreateItem and then
   * diff'ed correctly by the item edit payload differ in getRequestActionForSingleItem
   * which compares initialValues with desired values to PATCH the item
   */

  if (!hasPermissionToSendItem) {
    sendItem = false;
  }

  const initialValues = getInitialValues({
    itemMembersForItem,
    item: {
      ...item,
      // fundingProviderMemo is returned as `null` if unset but the form expects an empty string
      fundingProviderMemo: item.fundingProviderMemo || '',
      paymentDeliveryMethod,
      paymentMethodOptions,
      paymentDeliveryMethodsAccepted,
      dateScheduled,
      fundingAccount: {
        id: item.fundingAccount,
      },
      attachments: itemAttachments,
    },
    partnership,
    meta: {
      ...meta,
      partnershipMembers: partnershipMembersForItem,
    },
    ui: {
      ...ui,
      isItemEdit: true,
      blockDetailsSection: false,
      dateScheduledType,
      sendItem,

      // for receivables, we want to let RCTMs pick Payment Terms and Due date
      showInvoiceGenerator: true,

      selectedCompany: {
        ...partnership,
        // SearchCompanies & Async.js need a label to display in field
        label: companyName,
        // searchCompanies.js needs this to remove the buttons
        // we can refactor this to use something else on the existing partnership
        type: CompanySearchPartnerTypes.EXISTING,
        partner: companies[partnership.partner],
      },
    },
    viewModelManager,
  });

  let parserDefaultValues = {};
  if (viewModelManager) {
    // viewModelManager can be null if the page is reloading as the RCTM re-syncs their ledger
    ({ parser: { initialValues: parserDefaultValues } = {} } = viewModelManager);
  }

  // Use the initialValues from the viewModelManager to extract and prefill data from the existing item
  const initialValuesWithDataFromItem = generateInitialValuesFromItem({
    initialValues: parserDefaultValues,
    item,
    allLedgerTaxCodes,
  });

  if (initialValues.tabs) {
    // Remove all keys from tabs object with undefined values / Tabs object is needed for some ledgers like Netsuite
    const sanitizedTabValues = cleanObjectOfEmptyValues(initialValuesWithDataFromItem.tabs);
    // Merged initialValues.tabs, that are the default values for tabs extended fields, sanitizedTabValues
    initialValuesWithDataFromItem.tabs = merge(initialValues.tabs, sanitizedTabValues);
  }

  return {
    ...initialValues,
    ...initialValuesWithDataFromItem,
  };
};

import { MemberNameAddOn } from 'constants/ui';

import { getMembershipFullName, isMembershipCurrentMembership } from 'helpers/memberships';

/**
 * Returns title and subtitle text used in UserProfileStrip
 * @param {Membership} member
 * @returns {{subtitle: string, titleMain: string, titleDetail: string}}
 */
export const getUserProfileStripTitleSubtitles = (member) => {
  let subtitle = member.email;
  let titleDetail = member.title || '';
  let titleMain = getMembershipFullName(member) || '';
  const selfText = MemberNameAddOn.MEMBER_NAME_YOU;

  if (isMembershipCurrentMembership(member)) {
    if (titleMain) {
      titleMain += selfText;
    } else if (titleDetail) {
      titleDetail += selfText;
    } else {
      subtitle += selfText;
    }
  }

  if (titleDetail && titleMain) {
    titleMain += ',';
  }

  return { subtitle, titleDetail, titleMain };
};

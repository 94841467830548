import { combineReducers } from 'redux';

import allReducer from './allReducer';
import byIdReducer from './byIdReducer';
import errorReducer from './errorReducer';
import fetchReducer from './fetchReducer';
import isRevokingReducer from './isRevokingReducer';
import lastFetchedReducer from './lastFetchedReducer';
import lastSubmittedReducer from './lastSubmittedReducer';
import submitReducer from './submitReducer';

const membershipInvitesReducer = combineReducers({
  allIds: allReducer,
  byId: byIdReducer,
  errors: errorReducer,
  isFetching: fetchReducer,
  isRevoking: isRevokingReducer,
  isSubmitting: submitReducer,
  lastFetched: lastFetchedReducer,
  lastSubmitted: lastSubmittedReducer,
});

export default membershipInvitesReducer;

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { Checkbox } from 'components';

import { soleProprietorEinTooltip } from 'modules/external/ExternalCollectTaxInfo/constants/text';

/**
 * Checkbox which give Sole Proprietors the option to enter a SSN instead of EIN for their business.
 * @param {boolean} isDisabled
 * @param {string} name - Field name for form
 * @returns {StatelessComponent}
 * @constructor
 */
const MissingEinCheckbox = ({ isDisabled, name }) => (
  <Field
    component={Checkbox}
    content="I don't have a Business EIN"
    disabled={isDisabled}
    name={name}
    tooltip={soleProprietorEinTooltip}
  />
);

MissingEinCheckbox.propTypes = {
  isDisabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

MissingEinCheckbox.defaultProps = {
  isDisabled: undefined,
};

export default MissingEinCheckbox;

import {
  getAllBulkImportsRoutine,
  getSingleBulkImportRoutine,
  uploadBulkImportFileRoutine,
} from 'actions/routines/bulkActions';

import { allKeys } from 'helpers/utility';

import initialState from './initialState';

const allReducer = (state = initialState.allIds, action) => {
  switch (action.type) {
    case getAllBulkImportsRoutine.SUCCESS:
      return allKeys(action.payload.bulkImport);

    case getSingleBulkImportRoutine.SUCCESS:
    case uploadBulkImportFileRoutine.SUCCESS:
      return Array.from(new Set([...state, ...allKeys(action.payload.bulkImport)]));

    default:
      return state;
  }
};

export default allReducer;

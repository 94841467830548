import PropTypes from 'prop-types';
import React from 'react';

import { isOptionSelectAll, isSelectAllOptionActive } from 'components/selectTypes/MultiCheckSelect/helpers';

/**
 * Custom MultiValue component to display a value in the select-box
 * for its corresponding selected option.
 *
 * This component will display whatever is given for data.valueLabel
 * *if* the corresponding option is select-all. Otherwise, it will display
 * a count of the options that have been selected.
 *
 * If this behavior isn't desired, a different custom MultiValue component
 * can be given to MultiCheckSelect.
 *
 * @param {{ valueLabel?: any, [string]: any }} data - Option value
 * @param {function(): Object} getStyles - Provided by react-select
 * @param {function(): any} getValue - Provided by react-select
 * @param {Object} rest
 * @returns {*}
 * @constructor
 */
export const MultiCheckValue = ({ data, getStyles, getValue, ...rest }) => {
  const selectValue = getValue();

  let contents;

  if (isSelectAllOptionActive(selectValue)) {
    if (!isOptionSelectAll(data)) {
      return null;
    }

    contents = data.valueLabel;
  } else {
    contents = `${selectValue.length} selected`;
  }

  return (
    <div className="Select__single-value" style={getStyles('singleValue', rest)}>
      {contents}
    </div>
  );
};

MultiCheckValue.propTypes = {
  data: PropTypes.shape().isRequired,
  getStyles: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
};

export default MultiCheckValue;

import PropTypes from 'prop-types';
import React from 'react';

import confirmAlert from 'helpers/confirmAlert';
import { associatedCallbackProp } from 'helpers/propTypes';
import { isFn } from 'helpers/utility';

/**
 * HOC that wraps the given component, and replaces the prop defined in props.confirmEvent,
 * firing the `onConfirm` prop first. If that function returns true, the original
 * event callback is fired.
 * @param {Function} Component
 * @return {Function}
 */
const withConfirmOnEvent = (Component) => {
  const ConfirmOnEvent = ({ confirmEvent, message, onConfirm, title, ...rest }) => {
    const originalCallback = rest[confirmEvent];

    const toggleConfirmAlert = async (...args) => {
      const userConfirmed = await onConfirm(message, title);

      if (userConfirmed && isFn(originalCallback)) {
        originalCallback(...args);
      }
    };

    const props = {
      ...rest,
      [confirmEvent]: toggleConfirmAlert,
    };

    return <Component {...props} />;
  };

  ConfirmOnEvent.propTypes = {
    confirmEvent: associatedCallbackProp,
    message: PropTypes.string.isRequired,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
  };

  ConfirmOnEvent.defaultProps = {
    confirmEvent: 'onClick',
    onConfirm: confirmAlert,
    title: undefined,
  };

  return ConfirmOnEvent;
};

export default withConfirmOnEvent;

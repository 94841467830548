import PropTypes from 'prop-types';
import React from 'react';

import { getClassNames } from 'helpers/ui';

import DetailsListCard from '../DetailsListCard';

import { getCompanyGeneralInfoCards } from './helpers/logic';

/**
 * A SimpleCard showing general information about a partner company.
 * @param {Partnership} partnership
 * @param {Company} partnershipCompany - company matching the partnership.partner
 * @param {RestOfProps} rest
 * @returns {StatelessComponent}
 * @constructor
 */
const CompanyGeneralInfoCard = ({ companies, partnership, partnershipCompany, info, ...rest }) => {
  const cards = getCompanyGeneralInfoCards({
    partnership,
    partnershipCompany,
    companies,
    info,
  });

  return <DetailsListCard className={getClassNames(rest)} dataFullStory detailsListItems={cards} title="General" />;
};

CompanyGeneralInfoCard.propTypes = {
  partnership: PropTypes.shape({
    created: PropTypes.string.isRequired, // datestring
    name: PropTypes.string.isRequired,
  }).isRequired,
  partnershipCompany: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default CompanyGeneralInfoCard;

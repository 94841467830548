import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { maintenanceModeModalClose } from 'actions/maintenance';

import { modalNameMaintenanceMode } from 'constants/modals';

import { createModalSelector } from 'selectors/modalsSelector';

import MaintenanceModeModal from './MaintenanceModeModal';

const modalSelector = createModalSelector(modalNameMaintenanceMode);

const mapStateToProps = createStructuredSelector({
  modalState: modalSelector,
});

const mapDispatchToProps = {
  onCloseModal: maintenanceModeModalClose,
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceModeModal);

import { deactivateTeamMemberModalRoutine } from 'actions/routines/modal';

/**
 * Reducer to track the open/close of the deactivateTeamMemberModal.
 * @param {boolean} state
 * @param {ReduxAction} action
 * @returns {boolean}
 */
const openReducer = (state = false, action) => {
  switch (action.type) {
    case deactivateTeamMemberModalRoutine.TRIGGER:
      return action.payload.open;

    default:
      return state;
  }
};

export default openReducer;

import PropTypes from 'prop-types';
import React from 'react';

import { isPartnershipMatched } from 'helpers/items';
import { isLedgerSettingsContactTypeBoth } from 'helpers/ledger';

import { AddPartnershipButton, RequiresMatchPopoverOptions } from './components';
import { componentHelpers, hooks } from './helpers';

/**
 * Hides the partnership matching buttons for items which have already been matched. If matching is needed, use the
 * ledger settings to determine if the ledger allows partnerships to be both a vendor and a customer, or if separate
 * partnerships need to be matched. Display the correct matching button for the setting. Get the partnership if it's
 * not already in state, showing a loading state in the buttons.
 * @param {ComponentProps} props
 * @param {Boolean} props.isFetchingLedgerOrPlatformPartnerships
 * @param {Object} props.item
 * @param {Object} props.ledgerSettings
 * @param {Function} props.onOpenCreatePartnershipModal
 * @param {Function} props.onOpenForceManualLedgerSyncModal
 * @param {Function} props.onOpenMergeModal
 * @param {Object} props.partnershipWithContacts
 * @param {Function} props.successCallback
 * @returns {FunctionComponent|null}
 */
const PartnershipMatchingButtons = ({
  isFetchingLedgerOrPlatformPartnerships,
  item,
  ledgerSettings,
  onOpenCreatePartnershipModal,
  onOpenForceManualLedgerSyncModal,
  onOpenMergeModal,
  partnershipWithContacts,
  successCallback,
}) => {
  const handleOpenCreatePartnershipModal = () =>
    onOpenCreatePartnershipModal({
      doesCompanyExist: true,
      partnership: partnershipWithContacts,
      sendEmail: false,
      successCallback,
    });

  const handleOpenMergeModal = () => {
    onOpenMergeModal({
      partnershipId: partnershipWithContacts.id,
      successCallback,
    });
  };

  /**
   * Start hooks
   */
  const [isLoadingAddPartnership, updateIsLoadingAddPartnership] = React.useState(false);
  const [isLoadingMergePartnership, updateIsLoadingMergePartnership] = React.useState(false);

  /**
   * The following two functions, onAdd and onMerge are not hooks, but rely on useRef and useState, while being used in
   * the subsequent hooks.useOpenModalAfterPartnershipLoad creation.
   *
   * In short: the declaration order of onAdd/onMerge matter
   */
  const onAdd = componentHelpers.onMatchPartnership({
    item,
    openModal: handleOpenCreatePartnershipModal,
    partnershipWithContacts,
    updateIsLoading: updateIsLoadingAddPartnership,
  });

  // Not a hook, but relies on component ref and state
  // Memoize with useCallback to prevent the property from reloading every time partnershipWithContacts is updated
  const onMerge = React.useCallback(
    componentHelpers.onMatchPartnership({
      item,
      openModal: handleOpenMergeModal,
      partnershipWithContacts,
      updateIsLoading: updateIsLoadingMergePartnership,
    }),
    [partnershipWithContacts?.id],
  );

  // useEffect hooks: Open add/merge modals if partnership is fetched or opens the force ledger sync modal
  hooks.useOpenModalAfterPartnershipLoad({
    isFetchingPartnership: isFetchingLedgerOrPlatformPartnerships,
    isLoading: isLoadingAddPartnership,
    onOpen: onAdd,
    onOpenResync: (onSuccess) => {
      onOpenForceManualLedgerSyncModal(onSuccess);
      updateIsLoadingAddPartnership(false);
    },
    partnershipWithContacts,
  });

  hooks.useOpenModalAfterPartnershipLoad({
    isFetchingPartnership: isFetchingLedgerOrPlatformPartnerships,
    isLoading: isLoadingMergePartnership,
    onOpen: onMerge,
    onOpenResync: onOpenForceManualLedgerSyncModal,
    partnershipWithContacts,
  });
  /**
   * End hooks
   */

  if (isPartnershipMatched(item)) {
    return null;
  }

  if (isLedgerSettingsContactTypeBoth(ledgerSettings)) {
    return <AddPartnershipButton isLoading={isLoadingAddPartnership} item={item} onClick={onAdd} />;
  }

  return (
    <RequiresMatchPopoverOptions
      isLoading={isLoadingAddPartnership || isLoadingMergePartnership}
      item={item}
      onAdd={onAdd}
      onMerge={onMerge}
    />
  );
};

PartnershipMatchingButtons.propTypes = {
  isFetchingLedgerOrPlatformPartnerships: PropTypes.bool.isRequired,
  item: PropTypes.shape().isRequired,
  ledgerSettings: PropTypes.shape().isRequired,
  onOpenCreatePartnershipModal: PropTypes.func.isRequired,
  onOpenForceManualLedgerSyncModal: PropTypes.func.isRequired,
  onOpenMergeModal: PropTypes.func.isRequired,
  partnershipWithContacts: PropTypes.shape().isRequired,
  successCallback: PropTypes.func.isRequired,
};

export default PartnershipMatchingButtons;

import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2, FormFieldLabel, IconNames, StepHeader } from 'components';

import { ButtonSize } from 'constants/button';
import { colors, sizes } from 'constants/styles';

import { isExternalPathAccept, isExternalPathPay } from 'helpers/external';

import AddressContainer from 'modules/address/containers/AddressContainer';
import {
  getExternalOnboardingCurrentStep,
  getExternalOnboardingNextStep,
} from 'modules/createPartnerCompany/helpers/steps';
import PartnerCompanyBillingForm from 'modules/createPartnerCompany/presenters/PartnerCompanyBillingForm';
import PartnerCompanyTaxForm from 'modules/createPartnerCompany/presenters/PartnerCompanyTaxForm';

import { BackLink } from './components';

const PartnerCompanyGeneralForm = (props) => {
  const {
    addressInfo,
    formId,
    formUIState,
    isUpdating,
    onDecrementStep,
    onFormSubmit,
    onInvalidCountry,
    onToggleInputForTin,
    onToggleSSNEIN,
  } = props;

  const currentStep = getExternalOnboardingCurrentStep();
  const nextStep = getExternalOnboardingNextStep();

  return (
    <React.Fragment>
      {currentStep && <StepHeader description={currentStep.description} num={`${currentStep.num}`} />}

      <form className="form" id={formId} onSubmit={onFormSubmit}>
        <FormFieldLabel className="margin-top--large">Business address</FormFieldLabel>

        <div className="margin-bottom--large">
          <AddressContainer
            addressInfo={addressInfo}
            formId={formId}
            onInvalidCountry={onInvalidCountry}
            stateKey="mailingAddress"
            stateKeyPrefix="company"
          />
        </div>

        {isExternalPathPay(window.location) && <PartnerCompanyBillingForm />}

        {isExternalPathAccept(window.location) && (
          <PartnerCompanyTaxForm
            formUIState={formUIState}
            onToggleInputForTin={onToggleInputForTin}
            onToggleSSNEIN={onToggleSSNEIN}
          />
        )}

        <div className="form-control">
          <ButtonV2
            htmlFor={formId}
            isLoading={isUpdating}
            rightIconColor={colors.colorWhiteHex}
            rightIconName={IconNames.ARROW_RIGHT}
            rightIconSize={sizes.iconSizes.LARGE}
            size={ButtonSize.LARGE}
            type="submit"
          >
            {`Continue to ${nextStep.description.toLowerCase()}`}
          </ButtonV2>
        </div>

        <BackLink onBack={onDecrementStep} />
      </form>
    </React.Fragment>
  );
};

PartnerCompanyGeneralForm.propTypes = {
  addressInfo: PropTypes.shape(),
  formId: PropTypes.string.isRequired,
  formUIState: PropTypes.shape().isRequired,
  isUpdating: PropTypes.bool.isRequired,
  onDecrementStep: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onInvalidCountry: PropTypes.func.isRequired,
  onToggleInputForTin: PropTypes.func.isRequired,
  onToggleSSNEIN: PropTypes.func.isRequired,
};

PartnerCompanyGeneralForm.defaultProps = {
  addressInfo: {},
};

export default PartnerCompanyGeneralForm;

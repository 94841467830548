/**
 * Helper function to determine the text used by the button in TableLoadMoreFooter.
 * @param {Object} props
 * @param {boolean} props.isValidNumber
 * @param {boolean} props.shouldShowLess
 * @return {string}
 */
export const getLoadMoreButtonText = ({ isValidNumber, shouldShowLess }) => {
  const showOrLoad = isValidNumber || shouldShowLess ? 'Show' : 'Load';
  const moreOrLess = shouldShowLess ? 'less' : 'more';

  return `${showOrLoad} ${moreOrLess}`;
};

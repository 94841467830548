import { Clickable, Popover, Spinner } from '@routable/gross-ds';
import clsx from 'clsx';
import React from 'react';
import { ExternalLink } from 'components';
export const InvoiceListPONumberCell = ({ rowData, isFetchingPoData }) => {
    const purchaseOrders = rowData?.purchaseOrders || [];
    if (isFetchingPoData) {
        return (React.createElement("div", { className: "flex gap-3 items-center" },
            React.createElement(Spinner, { size: "xxs" }),
            React.createElement("span", { className: "text-xs text-black" }, "Loading PO")));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: clsx('text-xs', {
                'text-grey-50': purchaseOrders.length === 0,
                'text-black': purchaseOrders.length > 1,
            }) },
            purchaseOrders.length === 0 && 'None',
            purchaseOrders.length === 1 && (React.createElement(ExternalLink, { href: purchaseOrders[0].ledgerLink, onClick: (evt) => evt.stopPropagation() }, purchaseOrders[0].purchaseOrderNumber || 'View PO')),
            purchaseOrders.length > 1 && (React.createElement(Popover, { content: React.createElement(Clickable, { className: "text-xs", onClick: (evt) => {
                        evt.preventDefault();
                    } }, `${purchaseOrders.length} POs`), keepOpen: true, placement: "top" },
                React.createElement("div", { className: "py-4 px-5 flex flex-col gap-3" },
                    React.createElement("span", { className: "text-xs text-black font-medium leading-[22px]" }, `${purchaseOrders.length} linked POs`),
                    purchaseOrders.map((purchaseOrder) => (React.createElement(ExternalLink, { className: "!text-xs", href: purchaseOrder.ledgerLink, key: purchaseOrder.ledgerRef }, purchaseOrder.purchaseOrderNumber || 'View PO')))))))));
};

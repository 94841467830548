import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './Label.scss';

const Label = ({ children, className }) => {
  const labelClassNames = classNames({
    label: true,
    [className]: !!className,
  });

  return <div className={labelClassNames}>{children}</div>;
};

Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Label.defaultProps = {
  className: '',
};

export default Label;

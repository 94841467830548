import { createIsSubmittingReducer } from 'store/redux';

import * as types from 'types/partnership';

/**
 * Reducer's finish cases
 * @type {ReduxType[]}
 */
const finishCases = [
  types.CLEAR_PARTNERSHIP_FUNDING_ACCOUNT,
  types.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_FAILURE,
  types.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_SUCCESS,
  types.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_FAILURE,
  types.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_SUCCESS,
  types.SUBMIT_PARTNERSHIP_PRIMARY_RECEIVABLE_FUNDING_ACCOUNT_FAILURE,
  types.SUBMIT_PARTNERSHIP_PRIMARY_RECEIVABLE_FUNDING_ACCOUNT_SUCCESS,
];

/**
 * Reducer's request cases
 * @type {ReduxType[]}
 */
const requestCases = [
  types.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_REQUEST,
  types.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_REQUEST,
  types.SUBMIT_PARTNERSHIP_PRIMARY_RECEIVABLE_FUNDING_ACCOUNT_REQUEST,
];

/**
 * Partnerships Funding Accounts isSubmitting reducer
 * @param {Boolean} state
 * @param {ReduxAction} action
 * @returns {Boolean}
 */
const isSubmittingReducer = createIsSubmittingReducer(finishCases, requestCases);

export { finishCases, requestCases };
export default isSubmittingReducer;

import { ApproverStatus, } from '../models';
export const getLevelsWithStatusRequested = (levels) => levels.filter((level) => level.status === ApproverStatus.Enum.requested);
export const getFirstRequestedLevelType = (levels = []) => {
    const [level] = getLevelsWithStatusRequested(levels);
    return level?.type || 'all';
};
export const mapLevelTypeToApprovers = (level) => level.approvers.map((approver) => ({ ...approver, levelType: level.type }));
export const isApproverInLevelType = (levels, approverId, levelType) => levels
    .map(mapLevelTypeToApprovers)
    .flat()
    .some((approver) => approver.id === approverId && approver.levelType === levelType);
export const getAllRequestedApproversForRequestedLevels = (levels) => getLevelsWithStatusRequested(levels)
    .map((level) => level.approvers)
    .flat()
    .filter((approver) => approver.status === ApproverStatus.Enum.requested);
export const isMembershipRequestedForApproval = (levels, membershipId) => {
    if (!levels || !membershipId) {
        return false;
    }
    const matchedApprover = getAllRequestedApproversForRequestedLevels(levels).find((approver) => approver.id === membershipId);
    return matchedApprover?.status === ApproverStatus.Enum.requested;
};

import {} from '@auth0/auth0-react';
const stub = () => {
    throw new Error('In order to use Auth0Client you need to call initAuth0Client with the auth0 context.');
};
export const Auth0Client = {
    buildAuthorizeUrl: stub,
    buildLogoutUrl: stub,
    getAccessTokenSilently: stub,
    getAccessTokenWithPopup: stub,
    getIdTokenClaims: stub,
    handleRedirectCallback: stub,
    isAuthenticated: undefined,
    loginWithPopup: stub,
    loginWithRedirect: stub,
    logout: stub,
    user: undefined,
};
export const initAuth0Client = (auth0Context) => {
    Object.entries(Auth0Client).forEach(([key]) => {
        Auth0Client[key] = auth0Context[key];
    });
};

/**
 * @fileOverview Defines the sub-reducer for active feature settings.
 * @module reducers/featureSettings
 */

import { getFeatureSettingsRoutine } from 'actions/routines/featureSettings';

import { initialState } from './initialState';

/**
 * Reducer for reduxState.featureSettings.activeSettings.
 * @type {ReduxReducer}
 */
const activeSettingsReducer = (state = initialState.activeSettings, action) => {
  switch (action.type) {
    case getFeatureSettingsRoutine.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default activeSettingsReducer;

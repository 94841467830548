import { parseNotifications as parse } from 'data/parse';

import {
  DELETE_EMAIL_FORWARD_SUCCESS,
  FETCH_NOTIFICATION_SETTINGS_SUCCESS,
  SUBMIT_EMAIL_FORWARD_SUCCESS,
} from 'types/notifications';

const byIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATION_SETTINGS_SUCCESS:
    case SUBMIT_EMAIL_FORWARD_SUCCESS:
      return parse.emailForwards.getEmailForwardsByIdAfterFetch(state, action.payload.notificationSettingsEmailForward);

    case DELETE_EMAIL_FORWARD_SUCCESS:
      return parse.emailForwards.getEmailForwardsByIdAfterDelete(state, action.payload.id);

    default:
      return state;
  }
};

export default byIdReducer;

import PropTypes from 'prop-types';
import React from 'react';

import { WhiteSpace, IconNames, Icon } from 'components';
import { WordBreakAll } from 'components/layout';

import { membershipTexts } from 'constants/membership';
import { colors, sizes } from 'constants/styles';

import { getMembershipNameOrEmail } from 'helpers/memberships';

/**
 * Returns title for revoke invite confirmation alert
 * @param {Membership} membership
 * @return {JSX.Element}
 */
const RevokeInviteConfirmationModalTitle = ({ membership, warningIcon }) => (
  <>
    {warningIcon && (
      <Icon
        className="margin-right--xm"
        color={colors.colorRedBoldHex}
        icon={IconNames.WARNING_SIGN}
        size={sizes.iconSizes.XX_LARGE}
      />
    )}
    {membershipTexts.REVOKE_INVITE}
    <WhiteSpace />
    for
    <WhiteSpace />
    <WordBreakAll>{getMembershipNameOrEmail(membership)}</WordBreakAll>?
  </>
);

RevokeInviteConfirmationModalTitle.propTypes = {
  membership: PropTypes.shape({}).isRequired,
};

RevokeInviteConfirmationModalTitle.defaultProps = {
  membership: undefined,
};

export default RevokeInviteConfirmationModalTitle;

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { FormControl, FormFieldLabel, ReduxFormFieldRenderInput, SelectFieldV2, TINInput } from 'components';

import { companyBusinessTypeOptions } from 'constants/company';
import { field } from 'constants/styles/formStyles';

import { requiredValidator } from 'helpers/fieldValidation';

import { PartnerCompanySSNEINToggle } from '../components';

/**
 * Input fields for entity's business name and type
 * @param {ComponentProps} props
 * @param {boolean} props.collectVendorTaxInfo
 * @param {UserTypes} props.companyType
 * @param {Object} props.formUIState
 * @param {boolean} props.hideLabel
 * @param {function} props.onToggleInputForTin
 * @param {function} props.onToggleSSNEIN
 * @returns {StatelessComponent}
 */
const PartnerCompanyTaxFormBusiness = ({
  collectVendorTaxInfo,
  companyType,
  formUIState,
  hideLabel,
  onToggleInputForTin,
  onToggleSSNEIN,
}) => {
  const showTaxFields = collectVendorTaxInfo;

  return (
    <>
      {!hideLabel && <FormFieldLabel>Registered entity info (for Form W-9)</FormFieldLabel>}

      {showTaxFields && (
        <FormControl>
          <Field
            component={SelectFieldV2}
            label="Business type"
            name="company.info.businessType"
            options={companyBusinessTypeOptions}
            validate={requiredValidator}
          />
        </FormControl>
      )}

      <FormControl>
        <Field
          component={ReduxFormFieldRenderInput}
          name="company.name"
          props={{
            className: field.xl.left,
            placeholder: 'Legal company name',
            type: 'text',
          }}
          validate={requiredValidator}
        />

        {showTaxFields && (
          <TINInput
            companyType={companyType}
            editAllowed
            editField={formUIState.displayInputForTin}
            fieldClassNames={field.xl.right}
            isDisabled={false}
            isSSN={formUIState.businessFormShouldShowSSN}
            name="company.info.tin"
            onEdit={onToggleInputForTin}
            showField
          />
        )}
      </FormControl>

      <PartnerCompanySSNEINToggle onToggleSSNEIN={onToggleSSNEIN} />
    </>
  );
};
PartnerCompanyTaxFormBusiness.propTypes = {
  collectVendorTaxInfo: PropTypes.bool,
  companyType: PropTypes.string.isRequired,
  formUIState: PropTypes.shape({
    displayInputForTin: PropTypes.bool,
    businessFormShouldShowSSN: PropTypes.bool,
  }).isRequired,
  hideLabel: PropTypes.bool,
  onToggleInputForTin: PropTypes.func.isRequired,
  onToggleSSNEIN: PropTypes.func.isRequired,
};

PartnerCompanyTaxFormBusiness.defaultProps = {
  collectVendorTaxInfo: true,
  hideLabel: undefined,
};

export default PartnerCompanyTaxFormBusiness;

import { change, getFormValues } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';

import { createTagRoutine } from 'actions/routines/tags';

import { formNamesTags } from 'constants/forms';

import { flattenNameFromAttributes } from 'helpers/api';
import { getRequestErrorAction, parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { getCurrentMembershipId } from 'helpers/localStorage';
import { createErrorTag, createTagsWithMetaSelectProps } from 'helpers/tagging';
import { allValues, isDefined } from 'helpers/utility';

import * as api from './api';

const { EXISTING_ITEM_TAGS } = formNamesTags;

/**
 * handleCreateTagSuccess
 * @params {Object} props
 * @params {[Tag]} props.currentTags
 * @params {Object} props.responseData
 * @returns {IterableIterator<*>}
 */
function* handleCreateTagSuccess({ currentTags, responseData }) {
  // on success, we save the newly create tag to the tagging.tag reducer
  yield put(createTagRoutine.success({ tag: responseData }));

  // to auto-select the tag in the MultiSelect form, simply add the correct props to new tag
  const tagsNew = allValues(responseData);
  const tagsFlattened = flattenNameFromAttributes(tagsNew);
  const newTags = createTagsWithMetaSelectProps(tagsFlattened);
  yield put(change(EXISTING_ITEM_TAGS, 'tags', [...currentTags, ...newTags]));
}

/**
 * handleCreateTagError
 * @params {Object} props
 * @params {[Tag]} props.currentTags
 * @params {String} props.tagName
 * @params {Object} props.errorData
 * @returns {IterableIterator<*>}
 */
function* handleCreateTagError({ currentTags, tagName, errorData }) {
  const {
    fields: { name = [] },
  } = errorData;
  const errors = name.join('');

  // to show the tag as an error in the Select field, simply add the correct props
  const errorTag = createErrorTag({ tagName, errors });
  yield put(change(EXISTING_ITEM_TAGS, 'tags', [...currentTags, errorTag]));

  const errorAction = getRequestErrorAction(errorData);
  return yield put(errorAction(createTagRoutine.failure, errorData));
}

/**
 * Creates a tag
 * @param {ReduxSagaRoutineAction} action
 * @return {IterableIterator<*> }
 */
export function* createTag(action) {
  let errorData = {};
  const {
    payload: {
      values: tagName,
      props: { formName },
    },
  } = action;

  const formValues = yield select((state) => getFormValues(formName)(state));
  let currentTags = [];
  // the first time a tag is created, formValues will be undefined as there isn't a tag in the select yet
  if (isDefined(formValues)) {
    currentTags = formValues.tags;
  }

  yield put(createTagRoutine.request());

  try {
    const currentMembershipId = getCurrentMembershipId();
    const response = yield call(api.createTag, {
      tagName,
      currentMembershipId,
    });

    if (response.ok) {
      const { tag } = response.data;
      yield handleCreateTagSuccess({ currentTags, responseData: tag });
      return yield response;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  return yield handleCreateTagError({ currentTags, tagName, errorData });
}

export default createTag;

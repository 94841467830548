import _cloneDeep from 'lodash/cloneDeep';
import _defaultsDeep from 'lodash/defaultsDeep';

import { DateFormats } from 'constants/date';
import { LedgerApplicationTypes } from 'constants/ledger';
import {
  LineItemBillCheckbox,
  lineItemCachedInfoAvailabilityDate,
  lineItemsBlockLabelInformal,
  LineItemStyles,
} from 'constants/lineItems';

import { formatDateString } from 'helpers/date';
import { getPaymentOrInvoiceText, isItemKindPayable } from 'helpers/items';
import { isLedgerApplicationTypeQBO } from 'helpers/ledger';
import { getLedgerTaxCodeById } from 'helpers/ledgerInfo';
import { capitalize, convertToUpperCase, uppercaseToUnderscore } from 'helpers/stringHelpers';
import { isEqual, isObject, isUndef, isValueEmpty, reduceKeys } from 'helpers/utility';

import {
  LINE_ITEMS_BLOCKS,
  LINE_ITEMS_SECTION_NAME,
  LINE_ITEMS_SUBSECTIONS,
} from 'modules/dashboard/createItems/invoiceGenerator/components/InvoiceGeneratorView/constants';

/**
 * Returns true if line item style is equal to LineItemStyles.ACCOUNT
 * @param {string} lineItemStyle
 * @return {boolean}
 */
export const isLineItemStyleAccount = (lineItemStyle) => isEqual(lineItemStyle, LineItemStyles.ACCOUNT);

/**
 * Returns true if line item style is equal to LineItemStyles.ITEM
 * @param {string} lineItemStyle
 * @return {boolean}
 */
export const isLineItemStyleItem = (lineItemStyle) => isEqual(lineItemStyle, LineItemStyles.ITEM);

/**
 * This method returns a filter function for selecting the proper lineItem item
 * by both leger application and item kind
 * @param {string} application
 * @param {string} kind
 * @return {function}
 */
export const getLedgerItemFilter = (application, kind) => {
  if (isLedgerApplicationTypeQBO({ application })) {
    // Return purchase or sale account based on item kind
    if (isItemKindPayable({ kind })) {
      return (ledgerItem) => !!ledgerItem.purchaseAccount;
    }

    return (ledgerItem) => !!ledgerItem.saleAccount;
  }

  // Do not filter, and return all lineItem items by default
  return () => true;
};

// ************************
// Column selection text
// ************************

/**
 * This method is used to determine what text style to render for the line item
 * account selection
 * @param {object} account
 * @return {string}
 */
export const getLineItemOptionAccountText = (account) => {
  if (account.code) {
    return `${account.code}: ${account.name}`;
  }

  return account.name;
};

/**
 * This method is used to determine what text style to render for the line item
 * class selection based on a ledger application type
 * @param {object} ledgerClass
 * @param {string} application
 * @return {string}
 */
export const getLineItemOptionClassText = (ledgerClass, application) => {
  switch (application) {
    case LedgerApplicationTypes.QBO:
    default:
      return ledgerClass.name;
  }
};

/**
 * Returns the formatted text used in the Account field
 * @param {object} props
 * @param {string} props.code
 * @param {string} props.name
 * @returns {`${string}: ${string}`}
 */
export const getLineItemAccountFieldNameText = ({ code, name }) => (isUndef(code) ? name : `${code}: ${name}`);

/**
 * This method is used to determine what text style to render for the line item
 * account selection based on a ledger application type
 * @param {object} item
 * @return {string}
 */
export const getLineItemOptionItemText = (item) => {
  if (!isValueEmpty(item?.code)) {
    return getLineItemAccountFieldNameText({
      code: item.code,
      name: item.name,
    });
  }

  return item.name;
};

/**
 * Returns label string for LineItems thread block
 * @param {?ItemKind} itemKind
 * @return {string} Block label
 */
export const getLineItemsTableLabel = (itemKind) => {
  const prefix = getPaymentOrInvoiceText({ kind: itemKind }) || lineItemsBlockLabelInformal;
  return `${capitalize(prefix)} coding`;
};

/**
 * Returns hint explaining why line items are not showing in the Item Thread
 * Details section
 * @param {ItemKind} itemKind
 * @return {string}
 */
export const getLineItemsCachedInfoNotAvailableHint = (itemKind) => {
  const date = formatDateString(lineItemCachedInfoAvailabilityDate, DateFormats.SHORT_ALPHA_MONTH_FULL_YEAR);
  const itemKindText = getPaymentOrInvoiceText({ kind: itemKind });

  return `Previews of ${itemKindText} coding are not available for ${itemKindText}s created before ${date}`;
};

/**
 * Check if value is strictly 'true' or LineItemBillCheckbox.BILLABLE to determine if the line item is billable.
 *
 * @param {StringMaybe | boolean} lineItemBillableValue
 * @returns {boolean}
 */
export const isLineItemBillable = (lineItemBillableValue) =>
  lineItemBillableValue === true || lineItemBillableValue === LineItemBillCheckbox.BILLABLE;

/**
 * Mark the line item as billable by converting the `billable` and `non-billable` values into booleans.
 *
 * @param {LineItem} lineItem
 * @returns {LineItem}
 */
export const markLineItemAsBillable = (lineItem) => {
  // extended_billable is either: 'billable', 'not-billable', or undefined
  const { extended_billable: extendedBillable } = lineItem;

  // if there is no billable field, no need to do the rest of the work
  if (!extendedBillable) {
    return lineItem;
  }

  const newLineItem = { ...lineItem };

  // convert the string/undefined into a boolean for the form
  newLineItem.extended_billable = isLineItemBillable(extendedBillable);

  return newLineItem;
};

/**
 * Line items are objects with their own 2-level-deep nesting (for extended fields, etc). The line item keys must be
 * converted to under_score and the extended fields needs to be un-nested.
 *
 * @param {LineItem} lineItem
 * @returns {LineItem}
 */
export const convertLineItemToUnderscore = (lineItem) => {
  // loop over the top-level keys in the line item
  const topLevelCorrectCase = reduceKeys(
    lineItem,
    (newLineItem, lineItemKey) => ({
      ...newLineItem,
      // convert the camelCaseKey to under_score_key
      [uppercaseToUnderscore(lineItemKey)]: lineItem[lineItemKey],
    }),
    {},
  );

  /**
   * extended fields look like this in the lineItem:
   * {
   *   someOtherKey: true,
   *   extended: {
   *     className: { id: '123' },
   *     memo: 'I am Iron Man',
   *   },
   * }
   */
  const { extended } = topLevelCorrectCase;

  /**
   * Extended fields need to be made on level less shallow:
   * {
   *   some_other_key: true,
   *   extended_class_name: { id: '123' },
   *   extended_memo: 'I am Iron Man',
   * }
   */
  const correctCaseLineItem = reduceKeys(
    extended,
    (newLineItem, extendedKey) => {
      let key = `extended_${uppercaseToUnderscore(extendedKey)}`;
      let value = extended[extendedKey];

      // some of the line item fields are extended objects and this helps prefill them
      if (isObject(value) && value.value) {
        key += '_value';
        value = extended[extendedKey].value;
      }

      const result = {
        ...newLineItem,
        [key]: value,
      };

      return result;
    },
    topLevelCorrectCase,
  );

  // handle ledgers which allow payables' line items to be marked as bills
  return markLineItemAsBillable(correctCaseLineItem);
};

/**
 * Line items are their own special little snowflakes, because they contain their own nested values which need to be
 * similarly made into underscore case. Extended fields need their own treatment.
 *
 * @param {object} props
 * @param {LineItem} props.defaultLineItem - Default line item from viewModelManager
 * @param {string} props.key - Where is this line item in the initialValues
 * @param {Item} item - Item we're trying extract line items from
 * @param {LedgerTaxCode[]} allLedgerTaxCodes
 * @returns {LineItem[]}
 */
export const processLineItems = ({ defaultLineItem, key, item, allLedgerTaxCodes }) => {
  const newLineItems = [];

  // line_items_item or line_items_account. item or account correlate to the style
  const lineItemStyle = key.replace('line_items_', '');
  // collect similarly styled line items together
  const matchingLineItems = item.lineItems.filter((lineItem) => isEqual(lineItem.style, lineItemStyle));

  // for each line item with the matching style
  matchingLineItems.forEach((lineItem) => {
    // convert line item to under_score_case, including some special-casing for extended fields
    const lineItemWithCorrectCasing = convertLineItemToUnderscore(lineItem);

    // Sometimes, the Item.line_items returned by the API doesn't exactly match what redux form
    // expects to seamlessly pre-fill each field...and so we need to handle special cases:
    const { account, tax } = lineItemWithCorrectCasing;

    if (account) {
      // On Create Item, the Account field renders as `{code} {name}`
      // To get the same when loading Item Edit, we need to reformat the account's name.
      lineItemWithCorrectCasing.account = {
        ...account,
        name: getLineItemAccountFieldNameText({
          code: account.code,
          name: account.name,
        }),
      };
    }

    if (tax) {
      // when connected to some ledgers, RCTMs can define how taxes are calculated on the item and on each line item
      // For eg on Xero, RCTMs can select a field labeled "Amounts are" (for eg, "exclusive"|"inclusive"|"No tax")
      // RCTMs can then select a tax rate in the "Tax rate" field on each line item
      // Based on whether taxes are "inclusive" or "exclusive" and whether there is a "Tax rate" applied to a line item
      // the form's "Total tax" and "Total" also need to update.
      // To get all these fields to prefill, we need to add the correct metadata from state.integrationConfigs.ledgerTaxCodes
      // to the line item's 'tax.id' value

      const ledgerTaxCode = getLedgerTaxCodeById({
        allLedgerTaxCodes,
        ledgerTaxCodeId: lineItemWithCorrectCasing.tax.id,
      });

      if (ledgerTaxCode) {
        lineItemWithCorrectCasing.tax = {
          ...tax,
          ...ledgerTaxCode,
          // the 'Tax Rate' field uses `name` value to render the tax rate's name with its tax rate
          // this is created by getLedgerTaxCodeNameText in allLedgerTaxCodesSelector
          name: ledgerTaxCode.text,
        };
      }
    }

    const newLineItem = {};

    // merge values we can prefill first, then fill in the rest with defaults
    _defaultsDeep(newLineItem, lineItemWithCorrectCasing, defaultLineItem);
    // add this correctly-formatted line item to list

    newLineItems.push(newLineItem);
  });

  // If we don't have any line items before returning ensure a blank one appears.
  // That way we don't have empty tables.
  if (newLineItems.length === 0) {
    newLineItems.push(defaultLineItem);
  }

  return newLineItems;
};

/**
 * Returns the default (ie. first) line item returned by the ViewModelManager
 * Useful to reset line items when clicking on the bin icon full right of a line item (LineItemDeleteContainer)
 * @param {SchemaViewModelManager} viewModelManager
 * @param {string} sectionPath
 * @returns {LineItem}
 */
export const getDefaultLineItem = (viewModelManager, sectionPath = '') => {
  const lineItems = viewModelManager?.initialValues?.line_items || {};

  const lineItemsAccount = lineItems?.[LINE_ITEMS_BLOCKS.ACCOUNT]?.[LINE_ITEMS_SUBSECTIONS.ACCOUNT] || [];
  const lineItemsItem = lineItems?.[LINE_ITEMS_BLOCKS.ITEM]?.[LINE_ITEMS_SUBSECTIONS.ITEM] || [];

  const resultItem = sectionPath.includes('account') ? lineItemsAccount[0] : lineItemsItem[0];

  return _cloneDeep(resultItem);
};

/**
 * Returns path for line items in create item form based on the line item style
 * @param {'account' | 'item'} lineItemStyle
 * @returns {string}
 */
export const getLineItemsPathFromStyle = (lineItemStyle) => {
  const type = convertToUpperCase(lineItemStyle);

  return `${LINE_ITEMS_SECTION_NAME}.${LINE_ITEMS_BLOCKS[type]}.${LINE_ITEMS_SUBSECTIONS[type]}`;
};

/** @description Callback for filter function, to filter out line items without user data entered into them */
export const isValidLineItem = (item) => {
  if (typeof item === 'object' && item !== null) {
    return Object.entries(item).some(([key, value]) => {
      // quantity/style/id are always populated, so we skip it when checking if the line item is "valid" to avoid adding extra empty line items
      if (key === 'quantity' || key === 'style' || key === 'id') {
        return false;
      }
      // any other field being present means it's not an empty item
      return !!value;
    });
  }
  return !!item;
};

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { AddressHookForm } from 'complexComponents/formField';
import {} from 'complexComponents/taxes/TaxFormRenderer';
import { useTaxFormOptions } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.context';
import { countryListOptions } from 'constants/i18n';
import RenderCheckbox from '../RenderCheckbox';
import RenderSelect from '../RenderSelect';
import RenderTextInput from '../RenderTextInput';
import { Group, GroupSlot } from '../styles';
const FieldGroupAddress = ({ fieldNamePrefix }) => {
    const { countries } = useTaxFormOptions();
    return (React.createElement(Group, null,
        React.createElement(GroupSlot, { size: 6 },
            React.createElement(RenderSelect, { isRequired: true, label: "Country", name: `${fieldNamePrefix}Country`, options: countries, placeholder: "Select Country", shouldUnregister: true })),
        React.createElement(GroupSlot, { size: 6 },
            React.createElement(RenderTextInput, { isRequired: true, name: `${fieldNamePrefix}StreetAddress`, placeholder: "Street, Apartment, Suite number, or Rural route", shouldUnregister: true })),
        React.createElement(GroupSlot, { size: 6 },
            React.createElement(RenderTextInput, { isRequired: true, name: `${fieldNamePrefix}City`, placeholder: "City", shouldUnregister: true })),
        React.createElement(GroupSlot, { size: 3 },
            React.createElement(RenderTextInput, { isRequired: true, name: `${fieldNamePrefix}State`, placeholder: "State, Province, or Territory", shouldUnregister: true })),
        React.createElement(GroupSlot, { size: 3 },
            React.createElement(RenderTextInput, { isRequired: true, name: `${fieldNamePrefix}Postalcode`, placeholder: "Postal code", shouldUnregister: true }))));
};
const FieldGroupPermanentAddress = () => React.createElement(FieldGroupAddress, { fieldNamePrefix: "residence" });
const FieldGroupMailingAddress = () => {
    const { watch, setValue } = useFormContext();
    const shouldMailingAddressMatchPermanentAddress = watch('mailingAddressMatchPermanentAddress');
    const residenceCountry = watch('residenceCountry');
    useEffect(() => {
        if (!shouldMailingAddressMatchPermanentAddress) {
            setValue('mailingCountry', residenceCountry);
        }
    }, [shouldMailingAddressMatchPermanentAddress, setValue, residenceCountry]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Group, null,
            React.createElement(GroupSlot, { size: 6 },
                React.createElement(RenderCheckbox, { label: "Same as permanent residence address", name: "mailingAddressMatchPermanentAddress" }))),
        !shouldMailingAddressMatchPermanentAddress && React.createElement(FieldGroupAddress, { fieldNamePrefix: "mailing" })));
};
const FieldGroupGenericAddress = () => {
    return React.createElement(AddressHookForm, { addressPath: "address", countryOptions: countryListOptions.US_AND_CANADA });
};
export { FieldGroupMailingAddress, FieldGroupPermanentAddress, FieldGroupGenericAddress };

/**
 * @fileOverview Shared data-testid constant values for create partnership (vendor/customer).
 *
 * @module constants/dataCy/createPartnership
 */

/**
 * @type {Object<string, string>}
 */
export const formBlocks = {
  BLOCK_CONFIRMATION: 'block_confirmation',
  BLOCK_CREATE_PARTNERSHIP_CONTACT_FORM: 'block_create_partnership_contact_form',
  BLOCK_INVITE_CUSTOMER: 'block_invite_customer',
  BLOCK_SELECT_VENDOR_PAYMENT_METHOD: 'block_select_vendor_payment_method',
};

/**
 * @type {Object<string, string>}
 */
export const hints = {
  HINT_ASSOCIATED_CONTACT_EMAIL: 'hint_associated_contact_email',
  HINT_CONTACT_REQUIRED_TO_SELECT_PAYMENT_METHOD: 'hint_contact_required_to_select_payment_method',
};

/**
 * @type {Object<string, string>}
 */
export const formFields = {
  BUTTON_CREATE_ANOTHER_PARTNERSHIP: 'button_create_another_partnership',
  BUTTON_CREATE_CONTACT: 'button_create_contact',
  BUTTON_CREATE_PARTNERSHIP: 'button_create_partnership',
  BUTTON_DONE: 'button_done',
  BUTTON_FINISH: 'button_finish',
  BUTTON_INVITE_MESSAGE: 'button_invite_message',
  BUTTON_SEND_INVITE: 'button_send_invite',
  BUTTON_UNIQUE_ID: 'button_unique_id',
  GROUP_SELECTS_INVITE_PARTNERSHIP: 'group_selects_invite_partnership',
  INPUT_SEARCH_PARTNERSHIPS: 'input_search_partnerships',
  LINK_PARTNERSHIP_NAME: 'link_partnership_name',
  RADIO_COMPANY_TYPE_BUSINESS: 'radio_company_type_business',
  RADIO_COMPANY_TYPE_INDIVIDUAL: 'radio_company_type_individual',
  TEXTAREA_INVITE_MESSAGE: 'textarea_invite_message',
};

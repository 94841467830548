import { CLEAR_FUNDING_ACCOUNT_ADDRESS_MANUAL } from 'types/funding';
import { MODAL_CREATE_MANUAL_ADDRESS_CLOSE, MODAL_CREATE_MANUAL_ADDRESS_OPEN } from 'types/modals';

export const clearFundingAccountManualAddress = () => ({
  type: CLEAR_FUNDING_ACCOUNT_ADDRESS_MANUAL,
});

export const closeCreateManualAddressModal = () => ({
  type: MODAL_CREATE_MANUAL_ADDRESS_CLOSE,
});

export const openCreateManualAddressModal = (address) => ({
  payload: {
    address,
  },
  type: MODAL_CREATE_MANUAL_ADDRESS_OPEN,
});

import PropTypes from 'prop-types';
import React from 'react';
import Sidebar from 'react-sidebar';

import { defaultSidePanelWidth } from 'constants/sidePanels';

import { deepMergeWithArrayReplacement } from 'helpers/transform';

import { sidePanelStyles } from '../helpers';
import SidePanel from '../SidePanel';

const SideBarPanel = ({ children, className, headerText, onClose, sidePanel, styles }) => {
  const sideBar = (
    <SidePanel className={className} headerText={headerText} isOpen={sidePanel.open} onClose={onClose}>
      {children}
    </SidePanel>
  );

  const sidebarStyles = deepMergeWithArrayReplacement(sidePanelStyles, styles);

  return (
    <Sidebar
      defaultSidebarWidth={defaultSidePanelWidth}
      docked={false}
      open={sidePanel.open}
      pullRight
      sidebar={sideBar}
      styles={sidebarStyles}
      touch
    >
      <div />
    </Sidebar>
  );
};

SideBarPanel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  headerText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  sidePanel: PropTypes.shape({
    open: PropTypes.bool,
  }).isRequired,
  // named "styles" because it's a non-standard style object that conforms to the requirements
  // of our side panel vendor component
  styles: PropTypes.shape({}),
};

SideBarPanel.defaultProps = {
  className: undefined,
  headerText: undefined,
  styles: {},
};

export default SideBarPanel;

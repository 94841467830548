import _cloneDeep from 'lodash/cloneDeep';

import { isPaymentDeliveryMethodAch, isPaymentMethodDeliveryCheck } from 'helpers/paymentMethods';
import { isLength } from 'helpers/utility';

/** Returns only a single funding account
 * @param {FundingAccount[]} fundingAccounts
 * @return {FundingAccount|null}
 */
const getSingleFundingAccountOrNull = (fundingAccounts) => {
  if (isLength(fundingAccounts, 1)) {
    return fundingAccounts[0];
  }

  return null;
};

/**
 * Returns last funding account if ACH
 * @param {OptionsArg} opts
 * @param {FundingAccount[]} opts.fundingAccountsWithAchFundingSource
 * @param {FundingSource} opts.lastSubmittedFundingAccount
 * @return {FundingAccount|null}
 */
export const getAchFundingAccount = ({ fundingAccountsWithAchFundingSource, lastSubmittedFundingAccount }) => {
  // partner submitted a bank funding account for the first time
  if (lastSubmittedFundingAccount?.bank) {
    return lastSubmittedFundingAccount;
  }

  // partner has a single saved Ach funding account
  return getSingleFundingAccountOrNull(fundingAccountsWithAchFundingSource);
};

/**
 * Returns last funding account if has address
 * @param {Object} opts
 * @param {FundingAccount[]} opts.fundingAccountsWithAddressFundingSources
 * @param {FundingSource} opts.lastSubmittedFundingAccount
 * @return {FundingAccount|null}
 */
export const getCheckFundingAccount = ({ fundingAccountsWithAddressFundingSources, lastSubmittedFundingAccount }) => {
  // partner submitted a new address funding account
  if (lastSubmittedFundingAccount?.address) {
    return lastSubmittedFundingAccount;
  }

  // partner has a single saved address funding account
  return getSingleFundingAccountOrNull(fundingAccountsWithAddressFundingSources);
};

/**
 * Returns last funding account
 * @param {Object} opts
 * @param {string} opts.paymentDeliveryMethod
 * @param {FundingAccount[]} opts.fundingAccountsWithAchFundingSource
 * @param {FundingAccount[]} opts.fundingAccountsWithAddressFundingSources
 * @param {FundingSource} opts.lastSubmittedFundingAccount
 * @return {FundingAccount|null}
 */
export const getLastUpdatedFundingAccount = ({
  paymentDeliveryMethod,
  fundingAccountsWithAchFundingSource,
  fundingAccountsWithAddressFundingSources,
  lastSubmittedFundingAccount,
}) => {
  let updatedFundingAccount;

  if (isPaymentDeliveryMethodAch(paymentDeliveryMethod)) {
    updatedFundingAccount = getAchFundingAccount({
      fundingAccountsWithAchFundingSource,
      lastSubmittedFundingAccount,
    });
  } else if (isPaymentMethodDeliveryCheck(paymentDeliveryMethod)) {
    updatedFundingAccount = getCheckFundingAccount({
      fundingAccountsWithAddressFundingSources,
      lastSubmittedFundingAccount,
    });
  }

  if (!updatedFundingAccount) {
    updatedFundingAccount = {
      id: '',
      isValid: false,
    };
  }

  return updatedFundingAccount;
};

/**
 * Returns new form states
 * @param {Object} opts
 * @param {Object} opts.defaultReceivable
 * @param {Object} opts.formUI
 * @param {FundingSource} opts.updatedFundingAccount
 * @return {Object}
 */
export const getNewFormState = ({ defaultReceivable, formUI, updatedFundingAccount }) => {
  const newDefaultReceivableState = _cloneDeep(defaultReceivable);
  const newFormUIState = _cloneDeep(formUI);

  // Add receiver funding account or clear it if none selected
  newDefaultReceivableState.fundingAccount = updatedFundingAccount.id;
  newDefaultReceivableState.paymentDeliveryMethod = defaultReceivable.paymentDeliveryMethod;

  // If a default receivable is being selected when none was previously selected
  // (i.e. in the case of an update payment method flow), we want to set the
  // acceptFuturePendingPayments flag to true. If a valid payment method was
  // previously selected, just use it's old setting.
  newDefaultReceivableState.acceptFuturePendingPayments =
    defaultReceivable?.acceptFuturePendingPayments === undefined ? true : defaultReceivable.acceptFuturePendingPayments;

  newFormUIState.defaultReceivable.isFundingAccountValid = updatedFundingAccount.isValid;

  return { newDefaultReceivableState, newFormUIState };
};

import { getCurrentMembershipId } from 'helpers/localStorage';

import * as actionTypes from 'types/memberships';

export const fetchMembershipsRequest = (options) => ({
  payload: { skipCache: options?.skipCache },
  type: actionTypes.FETCH_MEMBERSHIPS_REQUEST,
});

export const fetchMembershipsSuccess = (payload) => ({
  payload,
  type: actionTypes.FETCH_MEMBERSHIPS_SUCCESS,
});

export const fetchMembershipsFailure = (errors) => ({
  payload: { errors },
  type: actionTypes.FETCH_MEMBERSHIPS_FAILURE,
});

export const fetchSingleMembershipRequest = (membershipId) => ({
  payload: { membershipId },
  type: actionTypes.FETCH_MEMBERSHIP_REQUEST,
});

export const fetchCurrentMemberRequest = () => ({
  payload: { membershipId: getCurrentMembershipId() },
  type: actionTypes.FETCH_MEMBERSHIP_REQUEST,
});

export const fetchSingleMembershipSuccess = (payload, meta) => ({
  meta,
  payload,
  type: actionTypes.FETCH_MEMBERSHIP_SUCCESS,
});

export const fetchSingleMembershipFailure = (errors) => ({
  payload: { errors },
  type: actionTypes.FETCH_MEMBERSHIP_FAILURE,
});

export const fetchMembershipConfigRequest = (membershipId) => ({
  payload: { membershipId },
  type: actionTypes.FETCH_MEMBERSHIP_CONFIG_REQUEST,
});

export const fetchMembershipConfigSuccess = (payload) => ({
  payload,
  type: actionTypes.FETCH_MEMBERSHIP_CONFIG_SUCCESS,
});

export const fetchMembershipConfigFailure = (errors) => ({
  payload: { errors },
  type: actionTypes.FETCH_MEMBERSHIP_CONFIG_FAILURE,
});

import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';

import { getAllResources } from 'store/redux';

const allIdsReducer = (state = [], action) => {
  switch (action.type) {
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
      return Array.from(new Set([...state, ...getAllResources(action.payload.partnershipRequest)]));

    default:
      return state;
  }
};

export default allIdsReducer;

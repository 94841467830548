import { createErrorReducer } from 'store/redux';

import {
  SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_FAILURE,
  SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_REQUEST,
  SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_SUCCESS,
} from 'types/signup';

export const failureCases = [SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_FAILURE];
export const successCases = [
  SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_REQUEST,
  SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_SUCCESS,
];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

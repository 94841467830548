import { FEATURE_FLAG_NAME_BULK_PARTNERSHIPS } from 'constants/featureFlags';

import { withFeatureFlag } from 'hoc';

/**
 * Convenience wrapper around WithFeatureFlag, to gate a
 * component's render behind the bulkPartnerships feature flag.
 * Defaults the fallback children to null (overrideable).
 * @param {ComponentProps} props
 * @return {AnyComponent}
 */
const WithFeatureFlagBulkPartnerships = withFeatureFlag(FEATURE_FLAG_NAME_BULK_PARTNERSHIPS);

WithFeatureFlagBulkPartnerships.displayName = 'WithFeatureFlagBulkPartnerships';

export default WithFeatureFlagBulkPartnerships;

import { getQueryString } from 'helpers/queryParams';
import {} from 'interfaces/global';
import {} from './withApiRequestFor.types';
export const withApiRequestFor = (key, baseOptions, routine) => ({
    ...routine,
    [key]: (options) => {
        const { payload, ...rest } = routine[key](options, baseOptions);
        const endpoint = options?.params
            ? `${baseOptions.endpoint}?${getQueryString(options?.params)}`
            : baseOptions.endpoint;
        return {
            ...rest,
            payload: {
                onSuccess: routine.success,
                onFailure: routine.failure,
                ...baseOptions,
                endpoint,
                ...payload,
            },
        };
    },
});

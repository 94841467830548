import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';

import FormControl from 'components/form/FormControl';
import { Hint, HintText } from 'components/hint';

import { getBillOrInvoiceText } from 'helpers/items';
import { isEqual } from 'helpers/utility';

/**
 * How many invoice have been select which require approval
 * @param {number} invoiceCount
 * @param {Item} item
 * @returns {StatelessComponent|null}
 */
const InvoicesRequiringApprovalHint = ({ invoiceCount, item }) => {
  if (isEqual(invoiceCount, 0)) {
    return null;
  }

  return (
    <FormControl>
      <Hint className="margin-top--form-margin" intent="warning">
        <HintText>
          {`You have selected ${invoiceCount} ${pluralize(
            getBillOrInvoiceText(item),
            invoiceCount,
          )} requiring approval.`}
        </HintText>
      </Hint>
    </FormControl>
  );
};

InvoicesRequiringApprovalHint.propTypes = {
  invoiceCount: PropTypes.number,
  item: PropTypes.shape().isRequired,
};

InvoicesRequiringApprovalHint.defaultProps = {
  invoiceCount: 0,
};

export default InvoicesRequiringApprovalHint;

import { FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_SUCCESS } from 'types/clearingAccounts';

const getAllIds = (fundingAccounts) => {
  if (!fundingAccounts) {
    return [];
  }

  return Object.keys(fundingAccounts);
};

const allReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_SUCCESS:
      return getAllIds(action.payload.fundingAccount);

    default:
      return state;
  }
};

export default allReducer;

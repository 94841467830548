import classNames from 'classnames';
import React from 'react';
import { Text } from 'components';
import './TooltipEntry.scss';
const TooltipEntry = ({ isRow, label, value }) => (React.createElement("div", { className: classNames('tooltip-entry', {
        'is-row': !!isRow,
    }) },
    React.createElement(Text.Regular, null, label),
    React.createElement(Text.Bold, null, value)));
export default TooltipEntry;

import { SubmissionError } from 'redux-form';

const submitPartnerCompanyContact = async (values, options = {}) => {
  const { onUpdateOnboardingCompanyType, onUpdateOnboardingMembership, submitSuccessCallback } = options;
  const { company, membership } = values;

  const companyTypeResult = await onUpdateOnboardingCompanyType(company, submitSuccessCallback);

  const membershipResult = await onUpdateOnboardingMembership(membership, submitSuccessCallback);

  if (membershipResult && membershipResult.errors) {
    throw new SubmissionError({ membership: membershipResult.errors.fields });
  }

  if (companyTypeResult && companyTypeResult.errors) {
    throw new SubmissionError(companyTypeResult.errors.fields);
  }

  return true;
};

export default submitPartnerCompanyContact;

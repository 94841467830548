import React from 'react';
import { useGetItemListQuery } from 'ducks/routableApi';
import { Table, TablePagination } from 'complexComponents';
import TableLoading from 'complexComponents/Table/components/TableLoading';
import { FlexCol, FlexRow, FullPageModal, ModalFooter, ModalFooterButtonCancel, PaginationBlock, Text, } from 'components';
import { ButtonSize } from 'constants/button';
import { typography } from 'constants/styles';
import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { getItemStatusText, itemStatusToText, itemStatusToVariant } from 'helpers/status';
import { allValues } from 'helpers/utility';
import {} from 'interfaces/item';
import {} from 'interfaces/jsonapi';
import {} from 'types/UseApi';
import ExternalReceiptItemStatus from '../../../ExternalReceiptItemStatus';
import { InvoicesTableConfig } from '../../constants/tableConfig';
import ExternalReceiptPaymentHeader from '../ExternalReceiptPaymentHeader';
import {} from './ExternalReceiptPaymentFullPageModal.types';
import './ExternalReceiptPaymentFullPageModal.scss';
const ExternalReceiptPaymentFullPageModal = ({ modalState, onCloseModal, onSetActiveItem, payment, }) => {
    const [pageNumber, setPageNumber] = React.useState(1);
    const { data, isFetching } = useGetItemListQuery({
        params: {
            page: {
                size: 25,
                number: pageNumber,
            },
            payment: payment?.id,
        },
    });
    const goToPage = (destinationPage) => {
        switch (destinationPage) {
            case 'PREV':
                setPageNumber((current) => current - 1);
                break;
            case 'NEXT':
                setPageNumber((current) => current + 1);
                break;
            default:
                setPageNumber(destinationPage);
        }
    };
    let items = [];
    if (data?.item) {
        const itemsById = getObjectsByIdWithRelationships(data.item);
        items = allValues(itemsById);
    }
    const pagination = data?.meta?.pagination ?? {
        page: pageNumber,
        pageSize: 25,
    };
    const { amount, currencyCode, kind: itemKind, status: paymentStatus } = payment;
    const showDetailsColumn = items.some(({ lineItems }) => lineItems?.length);
    const text = items.length ? getItemStatusText(items[0], paymentStatus) : '';
    const paymentName = 'Batch payment';
    return (React.createElement(FullPageModal, { body: React.createElement(React.Fragment, null,
            React.createElement(FlexRow, null,
                React.createElement(FlexCol, { className: "flex--1 padding--m-large padding-bottom--remove--important" },
                    React.createElement(ExternalReceiptPaymentHeader, { amount: parseFloat(amount), currencyCode: currencyCode, itemKind: itemKind }),
                    React.createElement(ExternalReceiptItemStatus, { status: itemStatusToVariant[paymentStatus], statusText: itemStatusToText[paymentStatus], text: text })),
                React.createElement("div", { className: "flex--3 padding--m-large" },
                    React.createElement(PaginationBlock, { count: pagination.count, filterLabel: "All invoices in this payment", onGoToPage: goToPage, page: pagination.page, pages: pagination.pages, pageSize: pagination.pageSize }),
                    isFetching ? (React.createElement(TableLoading, null)) : (React.createElement(Table, { className: "border-top--none border-bottom--none", columns: InvoicesTableConfig({
                            itemKind,
                            showDetailsColumn,
                            onClick: onSetActiveItem,
                        }), data: items, pageSize: pagination.pageSize })),
                    React.createElement(TablePagination, { count: pagination.count, onGoToPage: goToPage, page: pagination.page, pages: pagination.pages, pageSize: pagination.pageSize }))),
            React.createElement(ModalFooter, { className: "external-receipt-payment-full-page-modal__footer" },
                React.createElement("div", { className: "content--left" }),
                React.createElement("div", { className: "content--right" },
                    React.createElement(ModalFooterButtonCancel, { buttonSize: ButtonSize.MEDIUM, buttonText: "Close", onCloseModal: onCloseModal })))), hasInlineFooter: true, header: React.createElement(Text.Semibold, { className: "padding-top--m-large padding-left--large", color: typography.TextColor.BLACK, size: typography.TextSize.LEVEL_200 }, paymentName), modalClassname: "external-receipt-payment-full-page-modal", modalState: modalState, onCloseModal: onCloseModal }));
};
export default ExternalReceiptPaymentFullPageModal;

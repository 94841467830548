import initialTransactionAchState from './transactionAchsReducer/initialState';
import initialTransactionCheckState from './transactionChecksReducer/initialState';
import initialTransactionItemsState from './transactionItemsReducer/initialState';

export default {
  activeFilters: {},
  allIds: [],
  byId: {},
  byItem: {},
  errors: null,
  isFetching: false,
  pagination: {
    count: 0,
    current: null,
    first: null,
    last: null,
    limit: 0,
    next: null,
    offset: 5,
    prev: null,
  },
  transactionAchs: initialTransactionAchState,
  transactionChecks: initialTransactionCheckState,
  transactionItems: initialTransactionItemsState,
};

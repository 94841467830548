import React from 'react';
import LimitedMergingToBaseCurrencyTooltip from 'complexComponents/tooltipTypes/LimitedMergingToBaseCurrencyTooltip';
import MergeOnlyDomesticPartnershipsCrossBorderDisabledTooltip from 'complexComponents/tooltipTypes/MergeOnlyDomesticPartnershipsCrossBorderDisabledTooltip';
import MergeOnlyDomesticPartnershipsTooltip from 'complexComponents/tooltipTypes/MergeOnlyDomesticPartnershipsTooltip';
import { MultiLineOptionForSearchCompanies } from 'components/selectV2/components';
import { getCustomerOrVendorTextByPartnership } from 'helpers/partnerships';
import { checkPartnershipEligibilityForMergeCompanies } from '../../helpers/logic';
import { getDetailsTextForMergeCompanies } from '../../helpers/text';
const MultiLineOptionWithTooltipForMergeCompanies = ({ companyIntegrationSettings, companySettings, data, ledger, supportedCurrencies, ...rest }) => {
    const { isLimitedMergingTooltipShown, isMergeDisabledWithXBFlagOffTooltipShown, isMergeDisabledWithXBFlagOnTooltipShown, } = checkPartnershipEligibilityForMergeCompanies({
        companyIntegrationSettings,
        companySettings,
        ledger,
        partnership: data,
        supportedCurrencies,
    });
    const partnershipType = getCustomerOrVendorTextByPartnership(data);
    const detailsText = getDetailsTextForMergeCompanies({
        isLimitedMergingTooltipShown,
        isMergeDisabledWithXBFlagOffTooltipShown,
        isMergeDisabledWithXBFlagOnTooltipShown,
        ledger,
        partnershipType,
    });
    return (React.createElement(LimitedMergingToBaseCurrencyTooltip, { isShown: isLimitedMergingTooltipShown, ledger: ledger, partnershipType: partnershipType },
        React.createElement(MergeOnlyDomesticPartnershipsCrossBorderDisabledTooltip, { isShown: isMergeDisabledWithXBFlagOffTooltipShown, ledgerName: ledger.name },
            React.createElement(MergeOnlyDomesticPartnershipsTooltip, { isShown: isMergeDisabledWithXBFlagOnTooltipShown, ledgerName: ledger.name },
                React.createElement(MultiLineOptionForSearchCompanies, { ...rest, data: {
                        ...data,
                        details: data.isDisabled && detailsText,
                    } })))));
};
export default MultiLineOptionWithTooltipForMergeCompanies;

import React from 'react';

import { Icon, Text } from 'components';

import { ExternalHintStatus } from 'constants/external';
import { typography } from 'constants/styles';

import {
  getExternalStepHintColorFromStatus,
  getExternalStepHintIconFromStatus,
  getExternalStepHintStatusLabel,
} from 'helpers/external';
import { oneOfValuesFromObject } from 'helpers/propTypes';

import './StepHintBase.scss';

/**
 * Component rendering External Step Hint. Meant to be used as a base for other
 * components displaying one specific status.
 *
 * @param {ComponentProps} props
 * @param {ExternalHintStatusType} props.hintStatus
 * @return {StatelessComponent}
 */
const StepHintBase = ({ hintStatus }) => {
  const hintIcon = getExternalStepHintIconFromStatus(hintStatus);
  const hintColor = getExternalStepHintColorFromStatus(hintStatus);

  return (
    <div className={`external-step-hint external-step-hint--${hintStatus}`}>
      <Icon color={hintColor} icon={hintIcon} />
      <Text.Semibold color={hintColor} size={typography.TextSize.LEVEL_450}>
        {getExternalStepHintStatusLabel(hintStatus)}
      </Text.Semibold>
    </div>
  );
};

StepHintBase.propTypes = {
  hintStatus: oneOfValuesFromObject(ExternalHintStatus).isRequired,
};

export default StepHintBase;

import { isCurrentCompanyPlatformTypeAlreadyAccepted } from 'helpers/currentCompany';
import { isExternalItemComplete } from 'helpers/items';
import { partnershipHasDeliveryMethod } from 'helpers/partnerships';
import { becameTruthy, every } from 'helpers/utility';

/**
 * Helper to determine if the partner or item already accepted corner dialogs should show.
 * @function
 * @param {boolean} isCorrectFlow
 * @param {boolean} hasNeverShownDialog
 * @param {boolean} dialogIsHidden
 * @param {boolean} actionComplete
 * @param {boolean} hasDefaultDeliveryMethod
 * @returns {boolean}
 */
export const shouldShowNoFurtherActionDialog = ({
  isCorrectFlow,
  hasNeverShownDialog,
  dialogIsHidden,
  actionComplete,
  hasDefaultDeliveryMethod,
}) =>
  every([
    // For example, if we're in the partnership accept flow with no item
    isCorrectFlow,
    // and we've never shown the corner dialog before
    hasNeverShownDialog,
    // and the corner dialog isn't showing right now
    dialogIsHidden,
    // and the user is opening the link after the partnership has already been accepted
    actionComplete,
    // and has already chosen a default delivery method
    hasDefaultDeliveryMethod,
  ]);

/**
 * Use state and props from ExternalPaymentContainer to determine if and when CornerDialogItemAlreadyAccepted
 * should show.
 * @function
 * @param {Object} state - local component state
 * @param {ComponentProps} props
 * @param {function} onShowItemCornerDialog - Updates component state
 * @returns {undefined} - only side-effects to local component state
 */
export const evaluateItemCornerDialogOnComponentDidUpdate = (state, props, onShowItemCornerDialog) => {
  const { hasShownItemCornerDialogOnce, isItemCornerDialogShown, originalItemStatus } = state;
  const { item } = props;

  const shouldShowDialogParams = {
    isCorrectFlow: item,
    hasNeverShownDialog: !hasShownItemCornerDialogOnce,
    dialogIsHidden: !isItemCornerDialogShown,
    actionComplete: isExternalItemComplete({ status: originalItemStatus }),
    hasDefaultDeliveryMethod: true,
  };

  // if the user is here to accept a partnership invite and the invite had already been completed
  if (shouldShowNoFurtherActionDialog(shouldShowDialogParams)) {
    // open the CornerDialog telling them no further action is needed
    onShowItemCornerDialog();
  }
};

/**
 * Use state and props from ExternalPaymentContainer to determine if and when CornerDialogPartnerAlreadyAccepted
 * should show.
 * @function
 * @param {Object} state - local component state
 * @param {ComponentProps} props
 * @param {function} onShowPartnershipCornerDialog - Updates component state
 * @returns {undefined} - only side-effects to local component state
 */
export const evaluatePartnershipCornerDialogOnComponentDidUpdate = (state, props, onShowPartnershipCornerDialog) => {
  const { hasShownPartnerCornerDialogOnce, isPartnerCornerDialogShown, originalCurrentCompanyPlatformType } = state;
  const { itemId, partnership } = props;

  const shouldShowDialogParams = {
    isCorrectFlow: !itemId,
    hasNeverShownDialog: !hasShownPartnerCornerDialogOnce,
    dialogIsHidden: !isPartnerCornerDialogShown,
    actionComplete: isCurrentCompanyPlatformTypeAlreadyAccepted(originalCurrentCompanyPlatformType),
    hasDefaultDeliveryMethod: partnershipHasDeliveryMethod(partnership),
  };

  // if the user is here to accept a partnership invite and the invite had already been completed
  if (shouldShowNoFurtherActionDialog(shouldShowDialogParams)) {
    // open the CornerDialog telling them no further action is needed
    onShowPartnershipCornerDialog();
  }
};

/**
 * As the item and/or partnership load, keep track of their original state. This will help us later when we're trying
 * to determine whether the item or partnership was completed during this session or was already completed when the
 * user first visited.
 * @param {Object} state - local component state
 * @param {ComponentProps} props
 * @param {function} saveItemStatus - Saves the item status to local component state
 * @param {function} savePartnerPlatformType - Saves the current company's partnerPlatform type to local component state
 */
export const updateOriginalModels = (state, props, saveItemStatus, savePartnerPlatformType) => {
  const { originalCurrentCompanyPlatformType, originalItemStatus } = state;
  const { currentCompanyPlatformType, item } = props;

  const loadedItemForFirstTime = becameTruthy(originalItemStatus, item?.status);
  const loadedPartnerTypeForFirstTime = becameTruthy(originalCurrentCompanyPlatformType, currentCompanyPlatformType);

  if (loadedItemForFirstTime) {
    // keep track of the original item status
    saveItemStatus();
  }

  if (loadedPartnerTypeForFirstTime) {
    // keep track of the original partnerType
    savePartnerPlatformType();
  }
};

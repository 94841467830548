import React from 'react';

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

const OldVersionModeBody = () => (
  <div className="maintenance--body">
    <div className="maintenance--upper-body">
      <div className="old-version-mode--construction-image" />
    </div>
    <p className="font-regular regular font-color--greyXDark line-height--standard remove-margin-bottom">
      {`Looks like your ${PLATFORM_DISPLAY_SHORT_NAME} is out-of-date! If you're ready to load the new version, reload your page and we'll bring you right back to the page you're on. 
      If you choose to continue browsing ${PLATFORM_DISPLAY_SHORT_NAME}, we'll automatically update your version on your next page load.`}
    </p>
  </div>
);

export default OldVersionModeBody;

import { Spinner } from '@routable/gross-ds';
import clsx from 'clsx';
import React from 'react';
import ThreeDots from 'react-spinkit';
import { colors } from 'constants/styles';
import { spacing } from 'constants/styles/sizes';
import ExtWrapper from 'modules/external/extWrapper/ExtWrapper';
import './IsLoading.scss';
export const IsLoading = ({ className, color = colors.colorMainJordanHex, fadeIn = 'half', }) => (React.createElement(ThreeDots, { className: clsx('sk-three-bounce', className), color: color, fadeIn: fadeIn, height: spacing.LARGE, style: { textAlign: 'center' }, width: spacing.EXTRA_MEDIUM }));
export const IsLoadingInline = ({ color }) => (React.createElement(ThreeDots, { className: "sk-three-bounce", color: color, fadeIn: "half" }));
export const IsLoadingComponent = ({ className, hasShadow, isReversed, loaderClassName, text, textClassName, }) => (React.createElement("div", { "aria-label": "loading", className: clsx('loading', {
        'has-shadow': !!hasShadow,
        [className]: !!className,
    }) },
    React.createElement("div", { className: "loading--body" }, isReversed ? (React.createElement(React.Fragment, null,
        React.createElement(IsLoading, { className: loaderClassName }),
        text && React.createElement("p", { className: clsx('text-black', textClassName) }, text))) : (React.createElement(React.Fragment, null,
        text && React.createElement("p", { className: clsx('text-black', textClassName) }, text),
        React.createElement(IsLoading, { className: loaderClassName }))))));
export const IsLoadingBrandedPage = () => (React.createElement("div", { className: "branded--contents" },
    React.createElement("div", { className: "branded--loading" },
        React.createElement(IsLoading, { color: "white" }))));
export const IsLoadingHasWrapper = ({ className, color, hasNav }) => (React.createElement(ExtWrapper, { activeClass: clsx('light', {
        'has-nav': hasNav,
        'has-loader': !hasNav,
        [className]: !!className,
    }) },
    React.createElement(IsLoading, { color: color })));
export const IsLoadingSpinnerHasWrapper = ({ children }) => (React.createElement("div", { className: "flex flex-col items-center justify-center gap-5 w-full h-full" },
    React.createElement(Spinner, { size: 10 }),
    children));
export const IsLoadingLedger = ({ color, ledgerName }) => (React.createElement(IsLoading, { className: `loading-ledger loading-ledger--${ledgerName}`, color: color, fadeIn: "full" }));
export const IsLoadingSSO = ({ color, ssoName }) => (React.createElement(IsLoading, { className: `loading-sso loading-sso--${ssoName}`, color: color, fadeIn: "full" }));
export default IsLoading;

import _find from 'lodash/find';

import { ExistingItemActions, ExistingItemActionsTitles } from 'constants/item';

import { isFundingAccountExternal } from 'helpers/funding';
import { isItemKindPayable } from 'helpers/items';
import { parseCurrency } from 'helpers/numbers';
import { isNotEqual, or } from 'helpers/utility';

import { getFundingRequirementEndpoint } from 'services/api/fundingEndpoints';
import FetchService from 'services/fetch';

export const getFormButtonText = (props = {}) => {
  const { item } = props;

  if (!item) {
    return 'Confirm and complete registration';
  }

  const actionWord = isItemKindPayable(item)
    ? ExistingItemActionsTitles[ExistingItemActions.PAY]
    : ExistingItemActionsTitles[ExistingItemActions.RECEIVE];

  return `Confirm and ${actionWord.toLowerCase()} ${parseCurrency(item.amount)}`;
};

export const getFundingRequirement = async (fundingAccountId, itemId) => {
  const response = await FetchService.request({
    endpoint: getFundingRequirementEndpoint(fundingAccountId, itemId),
    method: 'GET',
  });

  if (!response.ok) {
    return null;
  }

  // The `data` key is nested from the server response, hence we need originalData.data
  return response.originalData.data;
};

/**
 * Checks whether the last submitted funding account is a newly added account.
 * @param {Object} prevSubmittedFundingAccount
 * @param {Object} mostRecentlySubmittedFundingAccount
 * @returns {boolean}
 */
export const isLastSubmittedFundingAccountNew = (prevSubmittedFundingAccount, mostRecentlySubmittedFundingAccount) =>
  (!prevSubmittedFundingAccount && !!mostRecentlySubmittedFundingAccount) ||
  (!!prevSubmittedFundingAccount && prevSubmittedFundingAccount.id !== mostRecentlySubmittedFundingAccount.id);

export const getAchFundingAccount = (props) => {
  const { fundingAccount, fundingAccountsWithAchFundingSource, lastSubmittedFundingAccount } = props;

  // we need this check for length being exactly one.
  // on first load, we don't know the chosen funding account id.
  // this doesn't matter if there are multiple, because we force the user
  // to select one, meaning we now have the funding account id in state.
  // however, for a single account, there is no selection required,
  // so we need to assume usage of the only existing one here.
  if (fundingAccountsWithAchFundingSource.length === 1) {
    return fundingAccountsWithAchFundingSource[0];
  }

  if (fundingAccountsWithAchFundingSource.length > 1) {
    return _find(fundingAccountsWithAchFundingSource, (obj) => obj.id === fundingAccount);
  }

  // partner submitted a bank funding account for the first time
  if (lastSubmittedFundingAccount && lastSubmittedFundingAccount.bank) {
    return lastSubmittedFundingAccount;
  }

  return undefined;
};

export const getCheckFundingAccount = (props) => {
  const { fundingAccount, fundingAccountsWithAddressFundingSources, lastSubmittedFundingAccount } = props;

  // partner has a single saved address funding account
  if (fundingAccountsWithAddressFundingSources.length === 1) {
    return fundingAccountsWithAddressFundingSources[0];
  }

  if (fundingAccountsWithAddressFundingSources.length > 1) {
    return _find(fundingAccountsWithAddressFundingSources, (obj) => obj.id === fundingAccount);
  }

  // partner submitted a new address funding account
  if (lastSubmittedFundingAccount && lastSubmittedFundingAccount.address) {
    return lastSubmittedFundingAccount;
  }

  return null;
};

export const shouldUpdateFundingAccount = (prevProps, nextProps) => {
  const { fundingAccount, fundingAccounts, paymentDeliveryMethod } = nextProps;
  const {
    fundingAccount: prevFundingAccount,
    fundingAccounts: prevFundingAccounts,
    paymentDeliveryMethod: lastMethod,
  } = prevProps;

  const isDifferentFundingAccount = or(
    isNotEqual(paymentDeliveryMethod, lastMethod),
    isNotEqual(fundingAccount, prevFundingAccount),
    isNotEqual(fundingAccounts, prevFundingAccounts),
  );

  if (isDifferentFundingAccount) {
    return true;
  }

  const wasExternal = isFundingAccountExternal(prevFundingAccounts[fundingAccount]);
  const isExternal = isFundingAccountExternal(fundingAccounts[fundingAccount]);

  return isNotEqual(wasExternal, isExternal);
};

import * as types from 'types/oldVersion';

export const initialState = {
  on: false,
};

const oldVersionModeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.OLD_VERSION_MODE_OFF:
      return initialState;

    case types.OLD_VERSION_MODE_ON:
      return { ...state, on: true };

    default:
      return state;
  }
};

export default oldVersionModeReducer;

import PropTypes from 'prop-types';
import React from 'react';

import { EntityWidgetHeader } from 'components';

import { RoleCardEntityWidgetHeaderTitle } from './components';

/**
 * Header for the RoleCardEntityWidget. Includes the role's name and pricing information.
 *
 * @param {ComponentProps} props
 * @param {Role.name} props.roleName
 * @param {RestOfProps} props.rest - When this component gets cloned in EntityWidget, it adds some props so that
 * clicking on the this header open/closes the body. Those props are contained in this spread.
 * @returns {StatelessComponent}
 */
const RoleCardEntityWidgetHeader = ({ roleName, ...rest }) => (
  <EntityWidgetHeader
    contentLeft={<RoleCardEntityWidgetHeaderTitle roleName={roleName} />}
    contentRightProps={{ style: { flex: 'none' } }}
    {...rest}
  />
);

RoleCardEntityWidgetHeader.propTypes = {
  roleName: PropTypes.string.isRequired,
};

export default RoleCardEntityWidgetHeader;

export var PaymentMethodDetailAction;
(function (PaymentMethodDetailAction) {
    PaymentMethodDetailAction["COPY"] = "COPY";
    PaymentMethodDetailAction["LOCKED"] = "LOCKED";
    PaymentMethodDetailAction["REVEAL"] = "REVEAL";
    PaymentMethodDetailAction["TOOLTIP"] = "TOOLTIP";
})(PaymentMethodDetailAction || (PaymentMethodDetailAction = {}));
export var PaymentMethodDetailKey;
(function (PaymentMethodDetailKey) {
    PaymentMethodDetailKey["ACCOUNT_NUMBER"] = "accountNumber";
    PaymentMethodDetailKey["ACCOUNT_TYPE"] = "accountType";
    PaymentMethodDetailKey["ADDED_BY"] = "addedBy";
    PaymentMethodDetailKey["ADDRESS"] = "address";
    PaymentMethodDetailKey["BALANCE_ID"] = "balanceId";
    PaymentMethodDetailKey["BANK"] = "bank";
    PaymentMethodDetailKey["BANK_ACCOUNT_ID"] = "bankAccountId";
    PaymentMethodDetailKey["BANK_CODE"] = "bankCode";
    PaymentMethodDetailKey["BIC_SWIFT"] = "bicSwift";
    PaymentMethodDetailKey["BRANCH_CODE"] = "branchCode";
    PaymentMethodDetailKey["BSB_CODE"] = "bsbCode";
    PaymentMethodDetailKey["CLABE"] = "clabe";
    PaymentMethodDetailKey["CNAPS"] = "cnaps";
    PaymentMethodDetailKey["COUNTRY_CURRENCY"] = "countryCurrency";
    PaymentMethodDetailKey["IBAN"] = "iban";
    PaymentMethodDetailKey["IFSC"] = "ifsc";
    PaymentMethodDetailKey["ROUTING_NUMBER"] = "routingNumber";
    PaymentMethodDetailKey["SORT_CODE"] = "sortCode";
})(PaymentMethodDetailKey || (PaymentMethodDetailKey = {}));

import React from 'react';
import { FlexCol } from 'components';
import { DateFormats } from 'constants/date';
import { getDate } from 'helpers/date';
import { hasLength } from 'helpers/utility';
import { ExternalBlockItem } from 'modules/external/ExternalBlocksSection';
import { ExternalReceiptAttachments, ExternalReceiptItemEntry, } from 'modules/external/externalReceiptV2/ExternalReceiptSingleItem/components';
import {} from './ExternalReceiptReceivableItemEntries.types';
const ExternalReceiptReceivableItemEntries = ({ attachments, dateDue, reference, }) => (React.createElement(ExternalBlockItem, null,
    React.createElement(ExternalReceiptItemEntry, { label: "Reference", value: reference }),
    React.createElement(ExternalReceiptItemEntry, { label: "Due date", value: getDate(dateDue, DateFormats.SHORT_ALPHA_MONTH_FULL_YEAR) }),
    hasLength(attachments) && (React.createElement(FlexCol, { className: "margin-top--m-large" },
        React.createElement(ExternalReceiptAttachments, { attachments: attachments })))));
export default ExternalReceiptReceivableItemEntries;

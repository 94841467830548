import PropTypes from 'prop-types';
import React from 'react';

import './DashboardSidebarStrip.scss';

const DashboardSidebarStrip = ({ children }) => <div className="dashboard-sidebar-strip">{children}</div>;

DashboardSidebarStrip.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardSidebarStrip;

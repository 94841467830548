import React from 'react';
import { useFormContext } from 'react-hook-form';
import {} from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.models';
import { BaseHint } from 'components';
import { Intent } from 'constants/ui';
import RenderTextInput from '../RenderTextInput';
import { Group, GroupSlot } from '../styles';
const FieldGroupTaxPayerIdentificationNumber = () => {
    const { watch } = useFormContext();
    const [claimingTreatyBenefits, tin, ftin] = watch(['claimingTreatyBenefits', 'tin', 'ftin']);
    const shouldShowHint = claimingTreatyBenefits && !(tin || ftin);
    return (React.createElement(Group, null,
        React.createElement(GroupSlot, { size: 3 },
            React.createElement(RenderTextInput, { name: "tin", placeholder: "U.S. Taxpayer ID Number" })),
        React.createElement(GroupSlot, { size: 3 },
            React.createElement(RenderTextInput, { name: "ftin", placeholder: "Foreign Tax ID Number" })),
        shouldShowHint && (React.createElement(GroupSlot, { size: 6 },
            React.createElement(BaseHint, { intent: Intent.WARNING, title: "Either TIN or FTIN is required when claiming treaty benefits" })))));
};
export default FieldGroupTaxPayerIdentificationNumber;

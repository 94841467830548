// Redirect logic with React Router V4 history
const redirectTo = (history, toPath) => {
  let to = toPath;

  if (to === undefined) {
    to = '/login';
  }

  // Add next param (catch logout url)
  if (history && history.location.pathname && !history.location.pathname.startsWith('/logout')) {
    to += `?next=${history.location.pathname}`;

    // Add query strings from current url to be in the next param
    if (history.location.search) {
      to += encodeURIComponent(history.location.search);
    }
  }

  return to;
};

export default redirectTo;

import { views } from 'modules/signup-v3/constants';

import { fields as fieldNames, mapViewToFields } from '../../fields';

import { stepStatuses } from './constants';

// TODO: SUF | Typescript this file
/**
 * returns field value based on passed name
 * @param {string} name
 * @param {object} formValues
 */
export const getFieldValue = ({ name, formValues }) => {
  const splittedName = name.split('.');
  let value = formValues;
  let i = 0;
  while (i < splittedName.length) {
    value = value?.[splittedName[i]];
    i += 1;
  }

  return value;
};

/**
 * returns true if all given fields have formValues
 * @param {Field[]} fields
 * @param {object} formValues
 * @returns {boolean}
 */
export const allFieldsHaveValues = ({ fields, formValues }) =>
  fields?.every((field) => {
    if (field.notRequired) {
      return true;
    }
    // when field is required based on another field
    const baseFieldValue =
      field.baseField &&
      getFieldValue({
        name: field.baseField,
        formValues,
      });
    if (field.isRequiredCondition && !field.isRequiredCondition(baseFieldValue)) {
      return true;
    }

    const value = getFieldValue({ name: field.name, formValues });

    if (field.name === fieldNames.documents) {
      // empty arrays are truthy but we only want to consider this true if there is at least 1 doc
      return value?.length >= 1;
    }

    if (field.name === fieldNames.documentsOwned) {
      // empty arrays are truthy but we only want to consider this true if there is at least 1 doc
      return value?.length >= 1;
    }

    return value;
  });

/**
 * returns true if all fields on all substeps have formValues
 * @param {array} substeps
 * @param {object} formValues
 * @param {object} fieldMap
 * @returns {boolean}
 */
export const allSubStepsHaveValues = ({ substeps, formValues }) =>
  substeps?.every((substep) => {
    const stepFields = mapViewToFields[substep.id];
    if (!stepFields) {
      return true;
    }

    return allFieldsHaveValues({ fields: stepFields, formValues });
  });

/**
 * returns true if substep is completed
 * @param {object} substep
 * @param {object} formValues
 * @returns {string}
 */
export const substepIsCompleted = ({ substep, formValues }) => {
  const substepFields = mapViewToFields[substep.id];
  return allFieldsHaveValues({ fields: substepFields, formValues });
};

/**
 * returns the substep status
 * @param {object} substep
 * @param {object} formValues
 * @param {string} activeView
 * @returns {string}
 */
export const substepStatus = ({
  substep,
  formValues,
  activeView,
  isBusinessRepresentativeStep,
  currentMembershipIsRepresentative,
}) => {
  const isDisabled =
    !currentMembershipIsRepresentative && isBusinessRepresentativeStep && substep.id !== views.qualification;

  if (isDisabled) {
    return stepStatuses.disabled;
  }

  const isInProgress = substep.id === activeView;

  if (isInProgress) {
    return stepStatuses.progress;
  }

  const isCompleted = substepIsCompleted({ substep, formValues });

  if (isCompleted) {
    return stepStatuses.completed;
  }

  return stepStatuses.notStarted;
};

/**
 * returns the step status
 * @param {object} step
 * @param {object} formValues
 * @param {boolean} allSubStepsAreCompleted
 * @param {boolean} currentCompanyHasAllRepresentativeInfo
 * @returns {string}
 */
export const getStepStatus = ({
  step,
  formValues,
  allSubStepsAreCompleted,
  currentCompanyHasAllRepresentativeInfo,
  businessRepresentatives,
  currentMembershipIsRepresentative,
}) => {
  const hasCompletedSubstep = step.substeps?.some((substep) => {
    const fields = mapViewToFields[substep.id];
    return allFieldsHaveValues({ fields, formValues });
  });

  const isCompleted = allSubStepsAreCompleted;
  const isInProgress = hasCompletedSubstep && !isCompleted;

  if (isInProgress) {
    return stepStatuses.progress;
  }

  if (step.id === views.businessRepresentative) {
    if (businessRepresentatives?.length === 1 && currentMembershipIsRepresentative) {
      if (
        // there is only 1 biz rep (current user) and they have indicated they are a biz reP (agree checkboxes)
        // and have submitted all info
        businessRepresentatives[0].hasAllPersonalInfo
      ) {
        return stepStatuses.completed;
      }
      // TODO SUF instead of using array index, base this on current form field values
      // the current user hasn't submitted all biz rep info
      return stepStatuses.progress;
    }

    // TODO: make this more generic as a custom condition
    // waiting condition
    if (!currentCompanyHasAllRepresentativeInfo) {
      return stepStatuses.waiting;
    }
  }

  if (isCompleted) {
    return stepStatuses.completed;
  }

  return stepStatuses.notStarted;
};

/**
 * returns the steps with added metadata for status, etc
 * @param {array} steps
 * @param {string} activeView
 * @param {object} formValues
 * @returns {object}
 */
export const getConfiguredSteps = ({
  activeView,
  currentCompanyHasAllRepresentativeInfo,
  formValues,
  steps,
  isRepresentativeLocked,
  currentMembershipIsRepresentative,
  businessRepresentatives,
}) =>
  steps.map((step) => {
    const isStepActive = step.id === activeView;
    const isSubStepActive = step.substeps?.some((substep) => substep.id === activeView);
    const allSubStepsAreCompleted = allSubStepsHaveValues({
      substeps: step.substeps,
      formValues,
    });
    const stepStatus = getStepStatus({
      step,
      formValues,
      allSubStepsAreCompleted,
      currentCompanyHasAllRepresentativeInfo,
      businessRepresentatives,
      currentMembershipIsRepresentative,
    });

    const isBusinessRepresentativeStep = step.id === views.businessRepresentative;

    return {
      ...step,
      isActive: isStepActive || isSubStepActive,
      status: stepStatus,
      isLocked: step.id === views.businessRepresentative && isRepresentativeLocked,
      substeps: step.substeps?.map((substep) => ({
        ...substep,
        isActive: substep.id === activeView,
        isCompleted: substepIsCompleted({ substep, formValues }),
        status: substepStatus({
          substep,
          formValues,
          activeView,
          isBusinessRepresentativeStep,
          currentMembershipIsRepresentative,
        }),
      })),
    };
  });

export const completedSubsteps = ({ step }) =>
  step.substeps?.reduce((prev, substep) => (substep.isCompleted ? prev + 1 : prev), 0);

/**
 * returns true if substeps are displayed
 * @param step
 * @returns {*|boolean}
 */
export const stepHasShownSubsteps = ({ step }) => step.substeps?.length && !step.isLocked;

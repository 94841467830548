import React from 'react';
import { ModalNavTab } from 'components';
import FullPageModal from 'components/modal/FullPageModal';
import { ZIndexLayers } from 'constants/ui';
import { getZIndex } from 'helpers/zIndex';
import QuickswitchSplashscreenBody from './QuickswitchSplashscreenBody';
import './QuickswitchSplashscreen.scss';
const QuickswitchSplashscreen = ({ companyName }) => (React.createElement(FullPageModal, { body: React.createElement(QuickswitchSplashscreenBody, { companyName: companyName }), contentLabel: "Quickswitch splashcreen", header: React.createElement(ModalNavTab, { tabText: "Switch Routable account" }), modalClassname: "quickswitch-splashscreen", modalState: true, overrideStyle: {
        overlay: { zIndex: getZIndex(ZIndexLayers.MODAL.OVERLAY, 25) },
    } }));
export default QuickswitchSplashscreen;

import { useEventSync } from '@routable/framework';
import React, { useContext, useEffect, useState } from 'react';
import {} from '../../table.extended.types';
import { TableContext } from '../../table-name.context';
import { Container } from './header.styled';
import { useTableStore } from '../../table.store';
export const HeaderTemplate = ({ Template }) => {
    const tableContext = useContext(TableContext);
    const tableName = tableContext.tableName;
    const { getTableStateItem, activeTableState } = useTableStore(tableName);
    const [headerData, setHeaderData] = useState([]);
    useEventSync(`table:${tableName}:update:config`, (ev) => {
        setHeaderData(ev.data || []);
    });
    useEffect(() => {
        setHeaderData(getTableStateItem('headers'));
    }, [activeTableState]);
    if (!headerData) {
        return null;
    }
    return (React.createElement(Container, { className: "table-columns-header", isCondensed: tableContext.display === 'condensed' },
        React.createElement(Template, { data: headerData || [] })));
};

import { createSelector } from 'reselect';

import { createItemFormFields } from 'constants/formFields';

import { createItemFormSelector } from 'selectors/forms';
import { allStateSelector } from 'selectors/globalSelectors';

/**
 * Selects from the create item form whether or not the RCTM is currently creating a new invoice or if the invoice
 * is being selected from a list of invoices on the ledger.
 * @param {ReduxState}
 * @returns {StandardSelector} - true if creating a new invoices (v.s. selecting one from a ledger)
 */
export const creatingNewInvoiceSelector = createSelector([allStateSelector], (state) =>
  createItemFormSelector(state, createItemFormFields.UI_SHOW_INVOICE_GENERATOR),
);

/**
 * Use geolocation to set the bounds of autocomplete.
 * @param autocompleteRef
 * @returns {Function}
 */
const handleStreetAddressFocus = (autocompleteRef) => () => {
  // if the user shares the location
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      const geolocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };

      const circle = new window.google.maps.Circle({
        center: geolocation,
        radius: position.coords.accuracy,
      });

      autocompleteRef.current.setBounds(circle.getBounds());
    });
  }
};

export default handleStreetAddressFocus;

import PropTypes from 'prop-types';
import React from 'react';

import { ItemPaymentTermsText } from 'constants/item';

import { isItemKindPayable } from 'helpers/items';

import ExternalReceiptRow from 'modules/external/externalReceipt/presenters/ExternalReceiptRow';

const PaymentTerms = ({ item }) => {
  if (!isItemKindPayable(item)) {
    return null;
  }

  if (!item.paymentTerms) {
    return null;
  }

  return <ExternalReceiptRow description="Payment terms" text={ItemPaymentTermsText[item.paymentTerms]} />;
};

PaymentTerms.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default PaymentTerms;

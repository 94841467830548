import PropTypes from 'prop-types';
import React from 'react';

import { Icon, IconNames } from 'components/icon';

import { sizes } from 'constants/styles';
import { TagShape, TagType } from 'constants/ui';

import { tagTypeProp } from 'helpers/propTypes';
import { getClassNames } from 'helpers/ui';

/**
 * Renders the close icon section for closable tags.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 * @constructor
 */
const TagClose = ({ onClick, shape, type, ...rest }) => (
  <button
    aria-label="tag-close-icon-button"
    className={getClassNames(rest, {
      'tag-close': true,
      [type.toLowerCase()]: true,
    })}
    onClick={onClick}
    type="button"
  >
    <Icon className="tag-close-icon" icon={IconNames.CROSS} size={sizes.iconSizes.MEDIUM} />
  </button>
);

TagClose.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  shape: PropTypes.string,
  type: tagTypeProp,
};

TagClose.defaultProps = {
  className: undefined,
  onClick: undefined,
  shape: TagShape.RECT,
  type: TagType.DEFAULT,
};

export default TagClose;

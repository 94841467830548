import { updateCurrentCompanySettingsIntegrationRoutine } from 'actions/routines/currentCompany';
import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';

import { getAttributesAndRelationshipsForReducer } from 'helpers/reducer';

import { GET_CURRENT_COMPANY_SUCCESS } from 'types/currentCompany';

const companySettingsIntegrationReducer = (state = {}, action) => {
  switch (action?.type) {
    case GET_CURRENT_COMPANY_SUCCESS:
    case updateCurrentCompanySettingsIntegrationRoutine.SUCCESS:
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
      return getAttributesAndRelationshipsForReducer(action?.payload?.companySettingsIntegration);

    default:
      return state;
  }
};

export default companySettingsIntegrationReducer;

import PropTypes from 'prop-types';
import React from 'react';

import { reduxFormInputPropType } from 'components/commonProps';
import { SelectOption } from 'components/selectTypes/components';
import {
  getNextPartnerMembersForMutuallyExclusiveSelects,
  shouldDisplayOptionInContactSelectMenu,
} from 'components/selectTypes/helpers';
import { useMutuallyExclusiveSelectsData } from 'components/selectTypes/hooks';
// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import TagMultiSelect from 'components/selectTypes/TagMultiSelect';

import { prefillMutuallyExclusiveSelectsData } from 'helpers/approvals';

/**
 * A single TagMultiSelect component used for MutuallyExclusiveSelects.
 * @param {ComponentProps} props
 * @param {Array} props.allApprovals
 * @param {Object} props.approvalSettings
 * @param {*} props.components
 * @param {Object} props.config
 * @param {string} props.formName
 * @param {Object} props.formValues
 * @param {string} props.name
 * @param {Function} props.onChange
 * @param {boolean} props.isItemEdit
 * @param {Function} props.onFieldChange
 * @param {string} props.formName
 * @param {ApprovalSelectConfig[]} props.selectConfigs
 * @param {PartnershipMemberSelectOption[]} props.value
 * @return {StatelessComponent}
 */
const SingleSelect = (props) => {
  const {
    allApprovals,
    approvalSettings,
    components,
    config,
    errors,
    formName,
    formValues,
    input,
    isItemEdit,
    onFieldChange,
    onShowToast,
    selectConfigs,
    ...rest
  } = props;

  const { name, onChange } = input;

  const { isDisabled, label, position, placeholder, textComponent } = config;

  // pre-fill field on component mount
  React.useEffect(() => {
    prefillMutuallyExclusiveSelectsData({
      allApprovals,
      approvalSettings,
      levelConfig: config,
      formName,
      isItemEdit,
      onFieldChange,
    });
  }, [allApprovals, approvalSettings, config, formName, isItemEdit, onFieldChange]);

  // get current value and options
  const { currentlySelected, options } = useMutuallyExclusiveSelectsData(props);

  const handleSelectionsChanged = React.useCallback(
    (selectValue) => {
      // create params object needed for updating value
      const params = {
        currentFieldName: name,
        currentlySelected,
        formName,
        formValues,
        onFieldChange,
        onShowToast,
        position,
        selectValue,
        selectConfigs,
      };
      const nextValue = getNextPartnerMembersForMutuallyExclusiveSelects(params);
      onChange(nextValue);
    },
    [currentlySelected, formName, formValues, name, onChange, onFieldChange, onShowToast, position, selectConfigs],
  );

  return (
    <div>
      {textComponent}

      <TagMultiSelect
        {...rest}
        components={{
          ...components,
          Option: SelectOption,
        }}
        errors={errors}
        filterOption={shouldDisplayOptionInContactSelectMenu}
        idPrefix={name}
        input={{
          ...input,
          onChange: handleSelectionsChanged,
          value: currentlySelected,
        }}
        isCreatable={false}
        isDisabled={isDisabled}
        key={name}
        label={label}
        name={name}
        options={options}
        placeholder={placeholder || label}
      />
    </div>
  );
};

SingleSelect.propTypes = {
  allApprovals: PropTypes.arrayOf(PropTypes.shape()),
  approvalSettings: PropTypes.shape({}).isRequired,
  config: PropTypes.shape().isRequired,
  components: PropTypes.shape(),
  errors: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  formName: PropTypes.string.isRequired,
  formValues: PropTypes.shape(),
  input: reduxFormInputPropType.isRequired,
  isItemEdit: PropTypes.bool,
  onFieldChange: PropTypes.func.isRequired,
  onShowToast: PropTypes.func.isRequired,
  selectConfigs: PropTypes.arrayOf(PropTypes.shape()),
};

SingleSelect.defaultProps = {
  allApprovals: [],
  components: {},
  errors: undefined,
  formValues: undefined,
  isItemEdit: undefined,
  selectConfigs: [],
};

export default SingleSelect;

import { createLastFetchedReducer } from 'store/redux';

import {
  CLEAR_MEMBERSHIP_INVITE,
  FETCH_MEMBERSHIP_INVITE_SUCCESS,
  FETCH_MEMBERSHIP_INVITES_SUCCESS,
  FETCH_MEMBERSHIP_INVITE_FAILURE,
  FETCH_MEMBERSHIP_INVITE_REQUEST,
  FETCH_MEMBERSHIP_INVITES_FAILURE,
  FETCH_MEMBERSHIP_INVITES_REQUEST,
} from 'types/inviteTeamMember';

export const successCases = [FETCH_MEMBERSHIP_INVITE_SUCCESS, FETCH_MEMBERSHIP_INVITES_SUCCESS];
export const requestCases = [
  CLEAR_MEMBERSHIP_INVITE,
  FETCH_MEMBERSHIP_INVITE_FAILURE,
  FETCH_MEMBERSHIP_INVITE_REQUEST,
  FETCH_MEMBERSHIP_INVITES_FAILURE,
  FETCH_MEMBERSHIP_INVITES_REQUEST,
];

const lastFetchedReducer = createLastFetchedReducer(successCases, requestCases);

export default lastFetchedReducer;

import { getPartnerMemberFromOptionData } from 'components/selectTypes/helpers';

import { callWithArgsIfIsFn, isEqual, isValueEmpty } from 'helpers/utility';

/**
 * Helper onBlur function for GroupedContactSelects used to override the redux-form onBlur.
 * This is to properly trigger validation without losing its value.
 * @param {ReduxFormInput} input
 * @param {Object} config
 */
export const onBlurForGroupedContactSelects = (input, config) => {
  const { targetMembers = [], secondaryMembers = [] } = config;
  const combinedData = targetMembers.concat(secondaryMembers);

  callWithArgsIfIsFn(input.onBlur, combinedData.map(getPartnerMemberFromOptionData));
};

/**
 * Helper function for parsing value for SelectFieldV2
 * @param {*} option
 * @param {string} valueKey
 * @return {*}
 */
export const parseValueFromOptionForSelects = (option, valueKey) => {
  let val = option;

  if (isEqual(typeof option, 'object') && !isValueEmpty(option) && valueKey) {
    val = option[valueKey];
  }

  return val;
};

/**
 * Helper function for returning whole option as value for SelectFieldV2
 * @param {*} option
 * @param {string} valueKey
 * @return {{label: *}|*}
 */
export const returnFullOptionObjectAsValueForSelects = (option, valueKey) => {
  // eslint-disable-next-line no-underscore-dangle
  if (option?.__isNew__) {
    return { ...option, label: option[valueKey] };
  }

  return option;
};

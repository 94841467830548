import React from 'react';
import { text } from 'components/hintTypes/constants';
import { WhiteSpace } from 'components/text';
import TextLinkNewInternalPage from 'components/TextLinkNewInternalPage/TextLinkNewInternalPage';
const { emailAlreadyInvitedHint: hintText } = text;
export const EmailAlreadyInvitedHintText = ({ onNavigate }) => (React.createElement(React.Fragment, null,
    hintText.textBeforeLink,
    React.createElement(WhiteSpace, null),
    React.createElement(TextLinkNewInternalPage, { onClick: onNavigate }, hintText.linkText),
    hintText.textAfterLink));

import * as types from 'types/transactions';

import initialState from './initialState';

export default (state = initialState.pagination, action) => {
  switch (action.type) {
    case types.FETCH_ITEM_TRANSACTIONS_SUCCESS:
      return initialState.pagination;

    case types.FETCH_TRANSACTIONS_SUCCESS:
      return action.meta.pagination;

    default:
      return state;
  }
};

import * as types from 'types/billing';

export const fetchBillingCodesRequest = () => ({
  type: types.FETCH_BILLING_CODES_REQUEST,
});

export const fetchBillingCodesSuccess = (payload) => ({
  payload,
  type: types.FETCH_BILLING_CODES_SUCCESS,
});

export const fetchBillingCodesFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_BILLING_CODES_FAILURE,
});

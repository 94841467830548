import { PaymentDeliveryOptionType } from '@routable/shared';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';

import {
  BalanceTransferBillingCode,
  BillingCodeFundingMemo,
  BillingCodePrefix,
  InternationalBillingCode,
} from 'constants/billing';
import { SWIFT_CHARGE_OPTIONS_BILLING_CODES } from 'constants/paymentMethods';

import { SwiftChargeOptions } from 'enums/paymentMethods';

import { isPaymentDeliveryOptionInternationalSwift } from 'helpers/paymentDeliveryOption';
import { allValues, isEqual } from 'helpers/utility';

/**
 * Returns whether the given billing code is for AP balance transfers.
 * @param {string} billingCode
 * @return {boolean}
 */
export const isBillingCodePayableBalanceTransfer = (billingCode) =>
  billingCode === BalanceTransferBillingCode.AP_BALANCE_TRANSFER;

/**
 * Returns whether the given billing code is for AR balance transfers.
 * @param {string} billingCode
 * @return {boolean}
 */
export const isBillingCodeReceivableBalanceTransfer = (billingCode) =>
  billingCode === BalanceTransferBillingCode.AR_BALANCE_TRANSFER;

/**
 * Returns whether the given billing code is for AP.
 * @param {string} billingCode
 * @return {boolean}
 */
export const isBillingCodeAccountsPayable = (billingCode) =>
  billingCode && billingCode.startsWith(BillingCodePrefix.ACCOUNTS_PAYABLE);

/**
 * Returns whether the given billing code is for AR.
 * @param {string} billingCode
 * @return {boolean}
 */
export const isBillingCodeAccountsReceivable = (billingCode) =>
  billingCode && billingCode.startsWith(BillingCodePrefix.ACCOUNTS_RECEIVABLE);

/**
 * Returns the billing code data for the given billingCode.
 * @param {Object} billingDataByCode
 * @param {string} billingCode
 * @return {BillingCodeData}
 */
export const getBillingDataForBillingCode = (billingDataByCode, billingCode) => billingDataByCode[billingCode];

/**
 * Returns the billing code data for the given paymentDeliveryOption, checking paymentSource if provided.
 * @param {Object} params
 * @param {BillingCodeDataByCode} params.billingDataByCode
 * @param {PaymentDeliveryOptionType} params.paymentDeliveryOption
 * @param {ItemKinds} params.kind
 * @param {PaymentSources} [params.paymentSource] - 'bank' or 'balance'. Used as filter only when provided
 * @param {SwiftChargeOptions} [params.swiftChargeOption] - 'our' or 'sha'. Used to  between swift_full & swift_shared
 * @return {BillingCodeData}
 */
export const getBillingDataForPaymentDeliveryOption = ({
  billingDataByCode = {},
  paymentDeliveryOption,
  kind,
  paymentSource,
  swiftChargeOption = SwiftChargeOptions.OUR,
}) => {
  if (isPaymentDeliveryOptionInternationalSwift(paymentDeliveryOption)) {
    return billingDataByCode[SWIFT_CHARGE_OPTIONS_BILLING_CODES[swiftChargeOption]];
  }

  return _find(
    billingDataByCode,
    (codeData) =>
      codeData.paymentDeliveryOption === paymentDeliveryOption &&
      codeData.paymentKind === kind &&
      (!paymentSource || paymentSource === codeData.paymentSource),
  );
};

/**
 * Returns boolean representing the existence of a billing code matching provided data.
 * @param {Object} params
 * @param {BillingCodeDataByCode} params.billingDataByCode
 * @param {PaymentDeliveryMethodType} params.paymentDeliveryMethod
 * @param {PaymentDeliveryOptionType} params.paymentDeliveryOption
 * @param {ItemKinds} params.kind
 * @param {PaymentSources} params.paymentSource
 * @return {boolean}
 */
export const isValidBillingCode = ({
  billingDataByCode = {},
  paymentDeliveryMethod,
  paymentDeliveryOption,
  kind,
  paymentSource,
}) =>
  Object.values(billingDataByCode).some(
    (codeData) =>
      codeData.paymentDeliveryMethod === paymentDeliveryMethod &&
      codeData.paymentDeliveryOption === paymentDeliveryOption &&
      codeData.paymentKind === kind &&
      codeData.paymentSource === paymentSource,
  );

/**
 * Returns the billing code data for the given paymentDeliveryOption.
 * @param {Object} billingDataByCode
 * @param {string} paymentDeliveryMethod
 * @return {BillingCodeData}
 */
export const getBillingDataForPaymentDeliveryMethod = (billingDataByCode, paymentDeliveryMethod) =>
  _reduce(
    billingDataByCode,
    (obj, codeData) => {
      if (codeData.paymentDeliveryMethod === paymentDeliveryMethod) {
        return {
          ...obj,
          [codeData.code]: codeData,
        };
      }

      return obj;
    },
    {},
  );

/**
 * Returns the billing code data for the given payment kind.
 * @param {Object} billingDataByCode
 * @param {ItemKind} kind
 * @return {BillingCodeData}
 */
export const getBillingDataForPaymentKind = (billingDataByCode, kind) =>
  _reduce(
    billingDataByCode,
    (obj, codeData) => {
      if (codeData.paymentKind === kind) {
        return {
          ...obj,
          [codeData.code]: codeData,
        };
      }

      return obj;
    },
    {},
  );

/**
 * Returns the billing code data for the given payment kind and delivery method.
 * @param {Object} billingDataByCode
 * @param {ItemKind} kind
 * @param {PaymentDeliveryMethod} paymentDeliveryMethod
 * @return {BillingCodeData}
 */
export const getBillingDataForPaymentKindAndDeliveryMethod = (billingDataByCode, kind, paymentDeliveryMethod) => {
  const dataForMethod = getBillingDataForPaymentDeliveryMethod(billingDataByCode, paymentDeliveryMethod);
  return getBillingDataForPaymentKind(dataForMethod, kind);
};

/**
 * Returns the { earliest, latest } speed data for the given paymentDeliveryOption.
 * @param {Object} billingDataByCode
 * @param {string} paymentDeliveryOption
 * @param {ItemKind} kind
 * @return {{ earliest: number, latest: number }}
 */
export const getSpeedForPaymentDeliveryOption = (billingDataByCode, paymentDeliveryOption, kind) => {
  const billingData = getBillingDataForPaymentDeliveryOption({
    billingDataByCode,
    paymentDeliveryOption,
    kind,
  });

  return _get(billingData, 'paymentDeliveryOptionSpeed');
};

/**
 * Returns whether the paymentDeliveryOptionSpeed earliest property is the same as its latest.
 * @param {BillingCodeData} billingData
 * @return {boolean}
 */
export const isOptionSpeedEarliestSameAsLatest = (billingData = {}) => {
  if (!billingData) {
    return false;
  }

  const { paymentDeliveryOptionSpeed } = billingData;

  if (!paymentDeliveryOptionSpeed) {
    return false;
  }

  const { earliest, latest } = paymentDeliveryOptionSpeed;

  return earliest === latest;
};

/**
 * Determines whether a given amount is over the allowed limit for
 * the billing data of the chosen delivery option.
 * @param {string|number} amount
 * @param {ObjectMaybe} billingData
 * @param {StringMaybe|NumberMaybe} billingData.paymentDeliveryOptionMax
 * @return {boolean}
 */
export const isAmountOverLimitForBillingData = (amount, billingData = {}) => {
  if (!billingData) {
    return false;
  }

  const { paymentDeliveryOptionMax } = billingData;

  return parseFloat(amount) > parseFloat(paymentDeliveryOptionMax);
};

/**
 * Returns billing codes for all payment delivery options, for the given prefix (e.g. "ap_" / "ar_").
 * @example
 * const APBillingCodes = getDeliveryOptionBillingCodesForPrefix(BillingCodePrefix.ACCOUNTS_PAYABLE);
 * console.log(APBillingCodes[PaymentDeliveryOptionType.ACH_STANDARD]);
 * // > ap_ach_standard
 * @return {Object}
 */
export const getDeliveryOptionBillingCodesForPrefix = (prefix) => {
  let deliveryOptions = allValues(PaymentDeliveryOptionType);

  // filter out RTP for receivables
  if (isBillingCodeAccountsReceivable(prefix)) {
    deliveryOptions = deliveryOptions.filter((option) => option !== PaymentDeliveryOptionType.RTP);
  }

  return deliveryOptions.reduce(
    (obj, option) => ({
      ...obj,
      [option]: prefix.concat(option),
    }),
    {},
  );
};

/**
 * Returns billing codes for all payment delivery options, for the AP prefix.
 * @return {Object}
 */
export const getDeliveryOptionBillingCodesForAP = () =>
  getDeliveryOptionBillingCodesForPrefix(BillingCodePrefix.ACCOUNTS_PAYABLE);

/**
 * Returns billing codes for all payment delivery options, for the AR prefix.
 * @return {Object}
 */
export const getDeliveryOptionBillingCodesForAR = () =>
  getDeliveryOptionBillingCodesForPrefix(BillingCodePrefix.ACCOUNTS_RECEIVABLE);

/**
 * Returns whether the given billing code is for ACH Addenda
 * @param {string} billingCode
 * @return {boolean}
 */
export const isBillingCodeAchAddenda = (billingCode) =>
  billingCode && isEqual(billingCode, BillingCodeFundingMemo.ACH_ADDENDA);

/**
 * Returns whether the given billing code is for RTP remittance data
 * @param {string} billingCode
 * @return {boolean}
 */
export const isBillingCodeRtpRemittance = (billingCode) =>
  billingCode && isEqual(billingCode, BillingCodeFundingMemo.RTP_REMITTANCE);

/**
 * Checks if billing code is for a internataional flat fee.
 * @param {string} billingCode
 * @return {boolean}
 */
export const isBillingCodeInternationalFlatFee = (billingCode) =>
  isEqual(billingCode, InternationalBillingCode.AP_INTERNATIONAL);

/**
 * Checks if billing code is for a currency exchange fee.
 * @param {string} billingCode
 * @return {boolean}
 */
export const isBillingCodeInternationalCurrencyExchangeFee = (billingCode) =>
  isEqual(billingCode, InternationalBillingCode.AP_INTERNATIONAL_CURRENCY_EXCHANGE);

/**
 * Checks if billing code is for a international transfer (SWIFT or Local Transfer) fee.
 * @param {string} billingCode
 * @return {boolean}
 */
export const isBillingCodeInternationalTransferFee = (billingCode = '') => {
  if (billingCode === null) {
    return false;
  }

  return billingCode.startsWith(BillingCodePrefix.INTERNATIONAL_TRANSFER);
};

/**
 * Checks if billing code is for a SWIFT USD cross-border fee.
 * @param {string} billingCode
 * @returns {boolean}
 */
export const isBillingCodeCrossborderFee = (billingCode = '') => {
  if (billingCode === null) {
    return false;
  }

  return billingCode === InternationalBillingCode.AP_INTERNTAIONAL_CROSSBORDER;
};

/**
 * Checks if billing code is any kind of international fee
 * @param {string} billingCode
 * @return {boolean}
 */
export const isBillingCodeInternationalAnyFee = (billingCode = '') =>
  isBillingCodeInternationalFlatFee(billingCode) ||
  isBillingCodeInternationalCurrencyExchangeFee(billingCode) ||
  isBillingCodeInternationalTransferFee(billingCode);

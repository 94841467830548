import { PaymentDeliveryMethodType, PaymentDeliveryOptionType } from '@routable/shared';

import { CurrencyCodeUsd } from 'constants/currency';
import { ItemKinds } from 'constants/item';
import { LedgerMatchFilterTypes } from 'constants/ledger';
import {
  PartnershipLedgerInfoKeyBase,
  PartnershipPartnerStatus,
  PartnershipTypes,
  ResendPartnershipInviteActionsStatus,
} from 'constants/partnership';
import PermissionResourceAction from 'constants/permissions';
import { TaxInfoDataKey } from 'constants/taxes';

import { PartnershipCountryCodeKey, PartnershipType } from 'enums/partnerships';

import { isPartnershipMemberStatusActive, isPartnershipMemberStatusArchived } from 'helpers/contacts';
import { isCountryCodeUSOrUSTerritory, isCountryCodeCanada } from 'helpers/countries';
import { getCurrencyFromCode, isCurrencyCodeNonUSD } from 'helpers/currency';
import { isCompanyTypePersonal } from 'helpers/currentCompany';
import { valueOrUndefined } from 'helpers/forms';
import { isItemKindReceivable } from 'helpers/items';
import { isSearchExecuted } from 'helpers/search';
import { isSelectedPartnerTypeNew } from 'helpers/searchCompanies';
import { capitalize } from 'helpers/stringHelpers';
import { isObject, valueOrDefault } from 'helpers/utility';

// =========
// Vendor/customer
// =========

/**
 * Coerces partnership.isCustomer to a boolean.
 * @param {Partnership} partnership
 * @return {Boolean}
 */
export const isPartnershipCustomer = (partnership) => Boolean(partnership?.isCustomer);

/**
 * Coerces partnership.isVendor to a boolean.
 * @param {Partnership} partnership
 * @return {Boolean}
 */
export const isPartnershipVendor = (partnership) => Boolean(partnership?.isVendor);

/**
 * Coerces partnership.customerRef to a boolean.
 * @param {Partnership} partnership
 * @return {Boolean}
 */
export const isPartnershipCustomerMatched = (partnership) => Boolean(partnership?.customerRef);

/**
 * Coerces partnership.vendorRef to a boolean.
 * @param {Partnership} partnership
 * @return {Boolean}
 */
export const isPartnershipVendorMatched = (partnership) => Boolean(partnership?.vendorRef);

/**
 * predicate function that determines if partnership is strictly a customer only
 * @param {Partnership} partnership
 * @returns {boolean}
 */
export const isExclusivelyCustomer = (partnership) => {
  const isCustomer = isPartnershipCustomer(partnership);
  const isNotVendor = !isPartnershipVendor(partnership);

  return isCustomer && isNotVendor;
};

/**
 * predicate function that determines if partnership is strictly a vendor only
 * @param {Partnership} partnership
 * @returns {boolean}
 */
export const isExclusivelyVendor = (partnership) => {
  const isVendor = isPartnershipVendor(partnership);
  const isNotCustomer = !isPartnershipCustomer(partnership);

  return isVendor && isNotCustomer;
};

/**
 * predicate function that detmines if partnership is strictly an invividual and vendor only
 * @param {object} opts
 * @param {Partnership} opts.partnership
 * @param {UserTypes} opts.companyType
 * @returns {boolean}
 */
export const isExclusivelyIndividualVendor = ({ companyType, partnership }) => {
  const isPersonal = isCompanyTypePersonal(companyType);
  const isVendor = isExclusivelyVendor(partnership);

  return isPersonal && isVendor;
};

// =========
// Partnership attributes
// =========

/**
 * Helper to determine whether a partnership already exists (other than just submitted)
 * @param {Object} partnership
 * @param {boolean} partnershipSubmitted
 * @return {boolean}
 */
export const doesPartnershipAlreadyExist = ({ partnership, partnershipSubmitted }) => {
  if (!partnership) {
    return false;
  }

  if (isSelectedPartnerTypeNew(partnership.type)) {
    return false;
  }

  return !partnershipSubmitted;
};

/**
 * Get ledgerLinkVendor/ledgerNameVendor/vendorRef/etc (also for customer) from a partnership if the information exists.
 * @function
 * @see getPartnershipTypeLedgerLink
 * @param {function} makeKey
 * @param {Partnership} partnership
 * @param {PartnershipTypes} partnershipType
 * @returns {string|undefined}
 */
export const getPartnershipTypeLedgerInfo = ({ makeKey, partnership, partnershipType }) => {
  // partnershipLedgerInfoKeyBase = 'ledgerLink'
  // partnershipType = 'vendor'
  // capitalPartnershipType = 'Vendor';
  const capitalPartnershipType = capitalize(partnershipType);
  // example: ledgerLinkVendor or customerRef
  const ledgerInfoType = makeKey(capitalPartnershipType);

  return valueOrUndefined(partnership[ledgerInfoType]);
};

/**
 * Get ledgerLink{Vendor/Customer} from a partnership.
 * @function
 * @see createLedgerDetailsItemsPair
 * @param {Partnership} partnership
 * @param {PartnershipTypes} partnershipType
 * @returns {string|undefined}
 */
export const getPartnershipTypeLedgerLink = (partnership, partnershipType) => {
  const params = {
    // ledgerLinkVendor
    makeKey: (capitalPartnershipType) => `${PartnershipLedgerInfoKeyBase.LEDGER_LINK}${capitalPartnershipType}`,
    partnership,
    partnershipType,
  };

  return getPartnershipTypeLedgerInfo(params);
};

/**
 * Get ledgerName{Vendor/Customer} from a partnership.
 * @function
 * @see createLedgerDetailsItemsPair
 * @param {Partnership} partnership
 * @param {PartnershipTypes} partnershipType
 * @returns {string|undefined}
 */
export const getPartnershipTypeLedgerName = (partnership, partnershipType) => {
  const params = {
    // ledgerNameVendor
    makeKey: (capitalPartnershipType) => `${PartnershipLedgerInfoKeyBase.LEDGER_NAME}${capitalPartnershipType}`,
    partnership,
    partnershipType,
  };

  return getPartnershipTypeLedgerInfo(params);
};

/**
 * Get {customer/vendor}Ref from a partnership.
 * @function
 * @see createLedgerDetailsItemsPair
 * @param {Partnership} partnership
 * @param {PartnershipTypes} partnershipType
 * @returns {string|undefined}
 */
export const getPartnershipTypeLedgerRef = (partnership, partnershipType) => {
  const params = {
    // vendorRef
    makeKey: () => `${partnershipType}${PartnershipLedgerInfoKeyBase.REF}`,
    partnership,
    partnershipType,
  };

  return getPartnershipTypeLedgerInfo(params);
};

/**
 * Coerces partnership.partnerPayableMethod to a boolean.
 * @param {Partnership} partnership
 * @return {Boolean}
 */
export const doesPartnershipHavePaymentMethod = (partnership) => Boolean(partnership?.partnerPayableMethod);

/**
 * Coerces partnership.isArchived to a boolean.
 * @param {Partnership} partnership
 * @return {Boolean}
 */
export const isPartnershipArchived = (partnership) => Boolean(partnership?.isArchived);

/**
 * Returns true if at least one of the partnershipMembers is archived
 * @param {PartnershipMember[]} contacts
 * @return {boolean}
 */
export const hasArchivedPartnershipMembers = (contacts) =>
  contacts.some((contact) => isPartnershipMemberStatusArchived(contact.status));

/**
 * Returns true if all of the partnershipMembers are archived
 * @param {PartnershipMember[]} contacts
 * @return {boolean}
 */
export const hasOnlyArchivedPartnershipMembers = (contacts) =>
  contacts.every((contact) => isPartnershipMemberStatusArchived(contact.status));

/**
 * Returns true if any of the partnershipMembers are reachable (active and have an email)
 * @param {PartnershipMember[]} partnershipMembers
 * @return {boolean}
 */
export const hasAnyReachablePartnershipMembers = (partnershipMembers) =>
  partnershipMembers.some((member) => {
    const emailExists = Boolean(member?.email) && typeof member?.email === 'string';
    return emailExists && isPartnershipMemberStatusActive(member?.status);
  });

/**
 * Returns true if partnership.defaultPaymentTerms is defined.
 * @param {Partnership} partnership
 * @return {Boolean}
 */
export const isPartnershipFull = (partnership) => typeof partnership?.defaultPaymentTerms !== 'undefined';

/**
 * Coerces partnership.isLedgerOnly to a boolean.
 * @param {Partnership} partnership
 * @return {Boolean}
 */
export const isPartnershipLedgerOnly = (partnership) => Boolean(partnership?.isLedgerOnly);

/**
 * Returns true if partnership.partnerStatus is true.
 * @param {Partnership} partnership
 * @param {Partnership.partnerStatus} partnership.partnerStatus
 * @return {Boolean}
 */
export const isPartnershipPartnerStatusAccepted = (partnership) =>
  partnership?.partnerStatus === PartnershipPartnerStatus.ACCEPTED;

/**
 * Returns true if partnership.partnerStatus is equal to ADDED
 * @function isPartnershipPartnerStatusAdded
 * @param {Partnership} partnership
 * @param {Partnership.partnerStatus} partnership.partnerStatus
 * @return {Boolean}
 */
export const isPartnershipPartnerStatusAdded = (partnership) =>
  partnership?.partnerStatus === PartnershipPartnerStatus.ADDED;

/**
 * Returns true if partnership.partnerStatus is equal to INVITED
 * @param {Partnership} partnership
 * @param {Partnership.partnerStatus} partnership.partnerStatus
 * @return {Boolean}
 */
export const isPartnershipPartnerStatusInvited = (partnership) =>
  partnership?.partnerStatus === PartnershipPartnerStatus.INVITED;

/**
 * When we go to match a partnership, if we don't have a partnership ID, we're just creating a new partnership from
 * scratch. This doesn't help us to resolve matching issues for individual items. This helper shows/hides the matching
 * buttons in the create multiple items flow and is also used to display a warning state on the TableDataRow
 * @param {Partnership} [partnership]
 * @returns {boolean}
 */
export const isPartnershipMissingDetails = (partnership) => !partnership?.id;

/**
 * Returns whether a partnership's partnerPayableDeliveryMethod is ACH.
 * @param {Partnership} partnership
 * @return {Boolean}
 */
export const isPartnershipPayableDeliveryMethodAch = (partnership) =>
  partnership?.partnerPayableDeliveryMethod === PaymentDeliveryMethodType.ACH;

/**
 * Returns whether a partnership's defaultReceivableDeliveryMethod is ACH.
 * @param {Partnership} partnership
 * @return {Boolean}
 */
export const isPartnershipDefaultReceivableMethodAch = (partnership) =>
  partnership?.defaultReceivableDeliveryMethod === PaymentDeliveryMethodType.ACH;

/**
 * Returns whether a partnership's defaultReceivableDeliveryMethod is check.
 * @param {Partnership} partnership
 * @return {Boolean}
 */
export const isPartnershipDefaultReceivableMethodCheck = (partnership) =>
  partnership?.defaultReceivableDeliveryMethod === PaymentDeliveryMethodType.CHECK;

/**
 * Returns true for partnerships that are archived and/or ledger-only, both of which can't have
 * a 'matched' status.
 * @param {Partnership} partnership
 * @return {Boolean}
 */
export const isPartnershipNotMatchable = (partnership) =>
  isPartnershipArchived(partnership) || isPartnershipLedgerOnly(partnership);

/**
 * Returns true for partnerships matched to any partnership type.
 * @param {Partnership} partnership
 * @return {Boolean}
 */
export const isPartnershipMatched = (partnership) => {
  if (isPartnershipNotMatchable(partnership)) {
    return false;
  }

  return isPartnershipCustomerMatched(partnership) || isPartnershipVendorMatched(partnership);
};

/**
 * Returns the boolean on the partnership to indicate if the partnership is a Routable-to-Routable relationship.
 * @function
 * @param {Partnership} partnership
 * @returns {boolean}
 */
export const isPartnershipRoutableToRoutable = (partnership) => !!partnership?.isPartnerSelfManaged;

/**
 * Returns true for partnerships matched to all partnership types.
 * @param {Partnership} partnership
 * @return {Boolean}
 */
export const isPartnershipFullyMatched = (partnership) => {
  if (isPartnershipNotMatchable(partnership)) {
    return false;
  }

  return isPartnershipCustomerMatched(partnership) && isPartnershipVendorMatched(partnership);
};

/**
 * Returns whether or not a payment method exists for a partnership.
 * @param {Partnership} partnership
 * @param {object} partnershipPrimaryReceivableFundingAccount
 * @return {Boolean}
 */
export const partnershipHasPaymentMethod = (partnership, partnershipPrimaryReceivableFundingAccount) =>
  Boolean(
    (isPartnershipCustomer(partnership) && doesPartnershipHavePaymentMethod(partnership)) ||
      (isPartnershipVendor(partnership) && partnershipPrimaryReceivableFundingAccount),
  );

/**
 * Return whether or not a delivery method exists for a partnership
 * @param {object} partnership
 * @return {boolean}
 */
export const partnershipHasDeliveryMethod = (partnership) =>
  Boolean(
    (isPartnershipCustomer(partnership) && partnership.defaultPayableMethod) ||
      (isPartnershipVendor(partnership) && partnership.defaultReceivableDeliveryMethod),
  );

// =========
// Partnership type
// =========

/**
 * Returns true for company type.
 * @param {PartnershipTypes} partnershipType
 * @return {Boolean}
 */
export const isPartnershipTypeCompany = (partnershipType) => partnershipType === PartnershipTypes.COMPANY;

/**
 * Returns true for customer type.
 * @param {PartnershipTypes} partnershipType
 * @return {Boolean}
 */
export const isPartnershipTypeCustomer = (partnershipType) => partnershipType === PartnershipTypes.CUSTOMER;

/**
 * Returns true for vendor type.
 * @param {PartnershipTypes} partnershipType
 * @return {Boolean}
 */
export const isPartnershipTypeVendor = (partnershipType) => partnershipType === PartnershipTypes.VENDOR;

/**
 * getIsVendorAndIsCustomer
 * returns an object indicating whether the partnership type is isVendor or isCustomer
 * this is useful when initializing and resetting the createPartnership form because the initial values object
 * expects the isVendor|isCustomer key/values to be included
 * @param {PartnershipTypes} partnershipType
 * @return {{ isVendor: boolean, isCustomer: boolean }} isPartnershipTypes
 */
export const getIsVendorAndIsCustomer = (partnershipType) => ({
  isCustomer: isPartnershipTypeCustomer(partnershipType),
  isVendor: isPartnershipTypeVendor(partnershipType),
});

/**
 * Returns true if the partnership is matched as the given type.
 * For example:
 * partnership.isCustomer is true, and partnership has a customerRef.
 * @param {Partnership} partnership
 * @param {PartnershipTypes} partnershipType
 * @return {Boolean}
 */
export const isPartnershipTypeMatched = (partnership, partnershipType) => {
  if (isPartnershipNotMatchable(partnership)) {
    return false;
  }

  if (isPartnershipTypeCustomer(partnershipType)) {
    return isPartnershipCustomerMatched(partnership);
  }

  return isPartnershipVendorMatched(partnership);
};

/**
 * Returns true if the partnership is matched as something other than the given type.
 * For example:
 * partnership.isCustomer is true, and partnership has a vendorRef.
 * @param {Partnership} partnership
 * @param {PartnershipTypes} partnershipType
 * @return {Boolean}
 */
export const isPartnershipTypeOtherMatched = (partnership, partnershipType) => {
  if (isPartnershipArchived(partnership) || isPartnershipLedgerOnly(partnership)) {
    return false;
  }

  if (isPartnershipTypeCustomer(partnershipType)) {
    return isPartnershipVendorMatched(partnership);
  }

  return isPartnershipCustomerMatched(partnership);
};

// =========
// Resend partnership invite actions
// =========

/**
 * Returns true if the given action is FAILURE.
 * @param {ResendPartnershipInviteActionsStatus} actionStatus
 * @return {Boolean}
 */
export const isResendPartnershipInviteActionStatusFailure = (actionStatus) =>
  actionStatus === ResendPartnershipInviteActionsStatus.FAILURE;

/**
 * Returns true if the given action is SUBMITTED.
 * @param {ResendPartnershipInviteActionsStatus} actionStatus
 * @return {Boolean}
 */
export const isResendPartnershipInviteActionStatusSubmitted = (actionStatus) =>
  actionStatus === ResendPartnershipInviteActionsStatus.SUBMITTED;

/**
 * Returns true if the given action is SUBMITTING.
 * @param {ResendPartnershipInviteActionsStatus} actionStatus
 * @return {Boolean}
 */
export const isResendPartnershipInviteActionStatusSubmitting = (actionStatus) =>
  actionStatus === ResendPartnershipInviteActionsStatus.SUBMITTING;

// =========
// Getters
// =========

/**
 * Text for customer or vendor given a partnership.
 *
 * We commonly use partnerships.getCustomerOrVendorTextByPartnership to determine whether to show vendor or customer.
 * However, there are cases when a partnership may be BOTH a vendor and a customer, in which case that method might show
 * use the wrong text.
 * @param {Partnership} partnership
 * @return {PartnershipType}
 */
export const getCustomerOrVendorOrBothTextByPartnership = (partnership) => {
  if (isPartnershipCustomer(partnership) && isPartnershipVendor(partnership)) {
    return PartnershipType.COMPANY;
  }

  if (isPartnershipCustomer(partnership)) {
    return PartnershipType.CUSTOMER;
  }

  return PartnershipType.VENDOR;
};

/**
 * Text for customer or vendor given a partnership.
 * @param {Partnership} partnership
 * @return {string}
 */
export const getCustomerOrVendorTextByPartnership = (partnership) => {
  if (isPartnershipCustomer(partnership)) {
    return PartnershipTypes.CUSTOMER;
  }

  return PartnershipTypes.VENDOR;
};

/**
 * Text for customer or vendor given a partnership - other side.
 * (i.e. customer for vendor, and vendor for customer)
 * @param {Partnership} partnership
 * @return {string}
 */
export const getCustomerOrVendorTextByPartnershipOther = (partnership) => {
  if (isPartnershipCustomer(partnership)) {
    return PartnershipTypes.VENDOR;
  }

  return PartnershipTypes.CUSTOMER;
};

/**
 * Returns member values (either empty for new, or filled for existing).
 * @param {PartnershipMember} contact
 * @return {{ firstName: string, lastName: string, email: string }}
 */
export const getMemberInfoFromPartnershipContact = (contact = undefined) => {
  const memberValues = {
    email: '',
    firstName: '',
    lastName: '',
  };

  if (contact) {
    Object.keys(memberValues).forEach((field) => {
      if (contact[field]) {
        memberValues[field] = contact[field];
      }
    });
  }

  return memberValues;
};

/**
 * Returns the customer or ledger ref based on an item kind.
 * @param {Partnership|Undefined} partnership
 * @param {Company} partner
 * @return {Partnership|Company}
 */
export const getPartnerObjWithName = (partnership, partner) => (partnership?.name ? partnership : partner);

/**
 * Returns the customer or ledger ref based on an item kind.
 * @param {Partnership} partnership
 * @param {import('interfaces/item'.Item)} item
 * @return {StringMaybe}
 */
export const getPartnershipCompanyRefPerItem = (partnership, item) => {
  if (!partnership) {
    return null;
  }

  if (isItemKindReceivable(item)) {
    return partnership.customerRef;
  }

  return partnership.vendorRef;
};

/**
 * Returns the first contact from partnership contacts or empty object.
 * @param {Partnership} partnership
 * @return {PartnershipMember|Object}
 */
export const getPartnershipContact = (partnership) => partnership?.contacts?.[0] ?? {};

/**
 * Returns the text describing the type of payment you'll send to a partner, for their partnership type.
 * @param {Partnership} partnership
 * @return {string}
 */
export const getPartnershipPaymentTypeText = (partnership) => {
  if (isPartnershipCustomer(partnership) && !isPartnershipVendor(partnership)) {
    return 'invoices';
  }

  if (isPartnershipVendor(partnership) && !isPartnershipCustomer(partnership)) {
    return 'payments';
  }

  return 'invoices and payments';
};

/**
 * Returns the text describing the type of payment you'll send to a partner, for their partnership type.
 * @param {Partnership} partnership
 * @return {string}
 */
export const getPaymentTypeForPartnershipTypeText = (partnership) => {
  if (isPartnershipCustomer(partnership)) {
    if (isPartnershipVendor(partnership)) {
      // partnership is both customer and vendor
      return 'payables and receivables';
    }

    // partnership is customer only
    return 'receivables';
  }

  // partnership is vendor only
  return 'payables';
};

/**
 * Return an actual FundingAccount id based on the partnershipReceivableFundingAccount
 * @param {FundingAccount} partnershipReceivableFundingAccount
 * @returns {string}
 */
export const getPartnershipReceivableFundingAccountId = (partnershipReceivableFundingAccount) =>
  partnershipReceivableFundingAccount?.fundingAccount;

/**
 * Returns Partnership Type for given partnership entity
 * @param {Partnership} partnership
 * @returns {PartnershipType}
 */
export const getPartnershipType = (partnership) => {
  // If partnership is both a customer and a vendor, we return PartnershipTypes.COMPANY
  if (isPartnershipCustomer(partnership) && isPartnershipVendor(partnership)) {
    return PartnershipType.COMPANY;
  }

  // We return PartnershipType.CUSTOMER is parnership is customer, or PartnershipType.VENDOR if it is not customer
  return isPartnershipCustomer(partnership) ? PartnershipType.CUSTOMER : PartnershipType.VENDOR;
};

/**
 * Returns the text describing the partnership's type.
 * @param {Partnership} partnership
 * @return {string}
 */
export const getPartnershipTypeText = (partnership) => {
  if (isPartnershipCustomer(partnership) && !isPartnershipVendor(partnership)) {
    return 'customers';
  }

  if (isPartnershipVendor(partnership) && !isPartnershipCustomer(partnership)) {
    return 'vendors';
  }

  return 'vendors and customers';
};

/**
 * Get vendor or customer title for an item kind.
 *
 * We commonly use partnerships.getCustomerOrVendorTextByPartnership to determine whether to show vendor or customer.
 * However, there are cases when a partnership may be BOTH a vendor and a customer, in which case that method might show
 * use the wrong text.
 *
 * This method determines whether to show vendor or customer based on the item type. For example, you're in the Pay
 * bills flow with Xero connected, paying to a partnership which is both a vendor and a customer. We can't determine the
 * text from the partnership, so we must determine the text based on the item.
 * @param {ItemKind} itemKind
 * @returns {PartnershipType}
 */
export const getVendorOrCustomerTitleFromItemKind = (itemKind) => {
  switch (itemKind) {
    case ItemKinds.PAYABLE:
      return PartnershipType.VENDOR;

    case ItemKinds.RECEIVABLE:
      return PartnershipType.CUSTOMER;

    default:
      return PartnershipType.COMPANY;
  }
};

/**
 * Get only partnerships that have a matched customer from an array of partnerships
 * @param {Partnership[]} partnerships
 * @return {Partnership[]}
 */
export const getPartnershipsWithMatchedCustomers = (partnerships) => {
  if (!Array.isArray(partnerships)) {
    return [];
  }

  return partnerships.filter(isPartnershipCustomerMatched);
};

/**
 * Gets the primary receivable funding account for a partnership.
 * @function
 * @param {FundingAccount[]} partnershipFundingAccounts
 * @param {Partnership.id} partnershipId
 * @returns {FundingAccount|undefined}
 */
export const getPartnershipPrimaryReceivableFundingAccount = (partnershipFundingAccounts, partnershipId) => {
  const primaryArr = partnershipFundingAccounts.filter(
    (fundingAccount) => fundingAccount.isPrimary && fundingAccount.partnership === partnershipId,
  );

  return primaryArr[0];
};

/**
 * Determines if a selected crossborder partner is eligible only for international SWIFT.
 * @function
 * @param {PartnerPaymentOption[]} partnerPaymentOptions
 * @returns {Boolean}
 */
export const isPartnerOnlyElegibleForInternationalSwift = (partnerPaymentOptions) => {
  /**
   * If no argument is provided, if argument is not an array, if array is empty
   * return false
   */
  if (!Array.isArray(partnerPaymentOptions) || partnerPaymentOptions.length === 0) {
    return false;
  }

  /**
   * Extract the options of all provided payment methods and check them to be international_swift.
   */
  return partnerPaymentOptions
    .map(({ options }) => options)
    .flat()
    .some((option) => option === PaymentDeliveryOptionType.INTERNATIONAL_SWIFT);
};

/**
 * getPartnershipPartnerCompany
 * returns the company obj. for a given partnership in a set of companies
 * @param {Company[]} companies
 * @param {Partnership} partnership
 * @return {Company|undefined}
 */
export const getPartnershipPartnerCompany = (companies, partnership) => companies[partnership?.partner];

/**
 * Returns message string based on partnership.partnerStatus
 * @function getResendButtonMessage
 * @param {Partnership} partnership
 * @return {string}
 */
export const getPartnershipResendReminderButtonText = (partnership) =>
  isPartnershipPartnerStatusAdded(partnership) ? 'Send invite' : 'Resend invite';

/**
 * Returns "Resend invite" button state props
 * given a partnership, the invite resendAction state and the expected tooltip placement
 * @param {Partnership} partnership
 * @param {StringMaybe} resendAction
 * @return {object} Button props as buttonText, isSubmitting, isDisabled, tooltipProps
 */

export function getPartnershipResendButtonProps(partnership, resendAction) {
  const hasInvalidEmail = !partnership.hasValidEmail;
  const tooltip = hasInvalidEmail ? 'Contact email required to send an invite' : null;
  const isSubmitting = isResendPartnershipInviteActionStatusSubmitting(resendAction);
  const isDisabled = isSubmitting || hasInvalidEmail;
  const buttonText = getPartnershipResendReminderButtonText(partnership);

  return {
    buttonText,
    isSubmitting,
    isDisabled,
    tooltip,
  };
}

/**
 * Returns the correct permission for edit action according to the partnership type (Customer|Vendor)
 * @param {Partnership} partnership
 * @return {PermissionResourceAction}
 */
export const getPartnershipPermissionResourceForEditAction = (partnership) =>
  isPartnershipCustomer(partnership) ? PermissionResourceAction.CUSTOMER_EDIT : PermissionResourceAction.VENDOR_EDIT;

/**
 * Returns the correct permission for archive action according to the partnership type (Customer|Vendor)
 * @param {Partnership} partnership
 * @return {PermissionResourceAction}
 */
export const getPartnershipPermissionResourceForArchiveAction = (partnership) =>
  isPartnershipCustomer(partnership)
    ? PermissionResourceAction.CUSTOMER_ARCHIVE
    : PermissionResourceAction.VENDOR_ARCHIVE;

/**
 * Returns the permission for vendor funding account create action
 * @return {PermissionResourceAction}
 */
export const getPartnershipPermissionResourceForFundingAccountCreateAction = () =>
  PermissionResourceAction.VENDOR_FUNDING_ACCOUNT_CREATE;

/**
 * Returns filtered partnerships based on active filter type
 * @param {string} activeFilter
 * @param {Partnership} partnership
 * @param {Object} search
 * @returns {Boolean}
 */
export const filterPartnershipByActiveFilter = ({ activeFilter, partnership, search }) => {
  // Avoid filtering if on search - show all results
  if (isSearchExecuted(search)) {
    return true;
  }

  switch (activeFilter) {
    case LedgerMatchFilterTypes.MATCHED:
      return isPartnershipMatched(partnership);

    case LedgerMatchFilterTypes.ARCHIVED:
      return isPartnershipArchived(partnership);

    case LedgerMatchFilterTypes.UNMATCHED:
    default:
      return !isPartnershipMatched(partnership) && !isPartnershipArchived(partnership);
  }
};

/**
 * Returns a text to be used in a tooltip when users are unable to add more contacts to an existing Item.
 * @param {Partnership} partnership
 * @return {string}
 */
export const getAddContactButtonDisabledTooltipText = (partnership) =>
  `You are only able to add a single contact in the case that your ${getCustomerOrVendorOrBothTextByPartnership(
    partnership,
  )} is an individual.`;

/**
 * Returns correct partnership country code
 * @param {Partnership} partnership
 * @param {PartnershipCountryCodeKey} [countryCodeKey=PartnershipCountryCodeKey.COMPANY]
 * @returns {?CountryCode}
 */
export const getPartnershipCountryCode = (partnership, countryCodeKey = PartnershipCountryCodeKey.COMPANY) =>
  partnership?.[countryCodeKey];

/**
 * Returns correct partnership currency code
 * @param {Partnership} partnership
 * @returns {CurrencyCode}
 */
export const getPartnershipCurrencyCodeCompany = (partnership) => partnership?.currencyCodeCompany;

/**
 * Returns partnership partner's currency code
 * @param {Partnership} partnership
 * @returns {CurrencyCode}
 */
export const getPartnershipCurrencyCodePartner = (partnership) => partnership?.currencyCodePartner;

/**
 * Returns correct partnership currency
 * @param {CurrencyCodeMap} currencyCodeMap
 * @param {Partnership} partnership
 * @returns {CurrencyCodeDetail}
 */
export const getPartnershipCurrency = (currencyCodeMap, partnership) => {
  const currencyCode = getPartnershipCurrencyCodeCompany(partnership);
  return getCurrencyFromCode(currencyCodeMap, currencyCode);
};

/*
 * Creates the partnership name to be displayed in the SearchCompanies input
 * @param {Partnership} partnership
 * @returns {string}
 */
export const createPartnershipSelectName = (partnership) => {
  const partnerIsFullObject = isObject(partnership.partner);
  const partnerNameDiffersFromPartnershipName = partnership.name !== partnership.partner?.name;

  // If the partnership name differs from the partner name
  if (partnerIsFullObject && partnerNameDiffersFromPartnershipName) {
    // combine the two to display in the select
    return `${partnership.name} (${partnership.partner.name})`;
  }

  return partnership.name;
};

/**
 * Returns true if given partnership.countryCodeCompany is a US country code
 * @param {Partnership} partnership
 * @param {PartnershipCountryCodeKey} [countryCodeKey=PartnershipCountryCodeKey.COMPANY]
 * @returns {Boolean}
 */
export const isDomesticPartnership = (partnership, countryCodeKey = PartnershipCountryCodeKey.COMPANY) => {
  const countryCode = getPartnershipCountryCode(partnership, countryCodeKey);

  if (!countryCode) {
    // If partnership[countryCodeKey] is blank (not-set), we can assume that the partnership
    // is a domestic partnership
    return true;
  }

  // If partnership[countryCodeKey] is US country code, we also mark this partnership as a domestic
  return isCountryCodeUSOrUSTerritory(countryCode);
};

/**
 * Returns true if given partnership.countryCodeCompany is a CA country code
 * @param {Partnership} partnership
 * @param {PartnershipCountryCodeKey} [countryCodeKey=PartnershipCountryCodeKey.PARTNER]
 * @returns {Boolean}
 */
export const isCanadianPartnership = (partnership, countryCodeKey = PartnershipCountryCodeKey.PARTNER) => {
  const countryCode = getPartnershipCountryCode(partnership, countryCodeKey);

  return isCountryCodeCanada(countryCode);
};

/**
 * Returns true if given partnership is cross-border partnership. This is evaluated by looking
 * at the partnership's country code. It returns true for every non-US country codes.
 * @param {Partnership} partnership
 * @param {PartnershipCountryCodeKey} [countryCodeKey=PartnershipCountryCodeKey.COMPANY]
 * @returns {Boolean}
 */
export const isInternationalPartnership = (partnership, countryCodeKey = PartnershipCountryCodeKey.COMPANY) =>
  !isDomesticPartnership(partnership, countryCodeKey);

/**
 * Returns partnership with updated list of partnershipMemberIds with passed new partnership members
 * @param {Partnership} partnership
 * @param {Id[]} newPartnershipMembers
 * @returns {Partnership}
 */
export const getPartnershipWithUpdatedPartnershipMembers = (partnership, newPartnershipMembers = []) => {
  const existingMembers = valueOrDefault(partnership?.partnershipMembers, []);
  const updatedPartnershipMembers = [...existingMembers, ...newPartnershipMembers];

  return { ...partnership, partnershipMembers: updatedPartnershipMembers };
};

/**
 * Returns the tax data key depending on the type of partner
 * @param {Object} options
 * @param {ExternalCompany} options.partner
 * @param {Partnership} options.partnership
 * @returns {ValueOf<typeof TaxInfoDataKey>} - 'w9TaxInfo' | 'w8BenTaxInfo' | 'w8BeneTaxInfo'
 */
export const getTaxDataKeyForPartnership = ({ partner, partnership }) => {
  const isDomestic = isDomesticPartnership(partnership, PartnershipCountryCodeKey.PARTNER);
  const isIndividual = isCompanyTypePersonal(partner?.companyType);

  if (isDomestic) {
    return TaxInfoDataKey.W9;
  }

  // Here the partnership is implicitly an international company
  if (isIndividual) {
    return TaxInfoDataKey.W8BEN;
  }

  return TaxInfoDataKey.W8BENE;
};

/**
 * Returns the tax data key depending on the countryCode and entityType
 * @param {CountryCode} countryCode
 * @param {UserTypes} entityType
 * @returns {ValueOf<typeof TaxInfoDataKey>} - 'w9TaxInfo' | 'w8BenTaxInfo' | 'w8BeneTaxInfo'
 */
export const getTaxDataKeyForCountryCodeAndEntityType = (countryCode, entityType) => {
  if (isCountryCodeUSOrUSTerritory(countryCode)) {
    return TaxInfoDataKey.W9;
  }
  if (isCompanyTypePersonal(entityType)) {
    return TaxInfoDataKey.W8BEN;
  }
  return TaxInfoDataKey.W8BENE;
};

/**
 * Returns props which indicate partnership eligibility for particular XB actions
 * @param {OptionsArg} options
 * @param {CurrentCompanySettingsIntegration} options.companyIntegrationSettings
 * @param {CompanySettings} options.companySettings
 * @param {Ledger | EmptyObject} options.ledger
 * @param {CurrencyCode[]} options.ledgerCurrencies
 * @param {Partnership} options.partnership
 * @param {CurrencyCode[]} options.supportedCurrencies
 * @returns {Object}
 */
export const checkPartnershipEligibilityForCrossborderActions = ({
  companyIntegrationSettings,
  companySettings,
  ledger,
  ledgerCurrencies,
  partnership,
  supportedCurrencies,
}) => {
  // Return early only if not connected to ledger
  if (!ledger?.id) {
    // This will not trigger any tooltips
    return {};
  }

  const isCrossBorderEnabled = companySettings.crossBorderRegistrationComplete && ledger.crossborderEnabled;

  // Check if we have baseCurrencyCode set in company's integration settings
  let baseCurrencyCode;
  const isBaseCurrencyModeEnabled = Boolean(companyIntegrationSettings?.enableBaseCurrencyMode);
  if (isBaseCurrencyModeEnabled) {
    baseCurrencyCode = ledger.baseCurrencyCode;
  }

  const isLedgerPartnership = !!partnership.ledgerCurrencies?.length;
  // Check if baseCurrency is supported by given partnership (if baseCurrency exists)
  const isBaseCurrencyNotSupportedByPartnership = Boolean(
    baseCurrencyCode && isLedgerPartnership && !partnership.ledgerCurrencies?.includes(baseCurrencyCode),
  );

  const isCurrencySupportedByPlatform = isLedgerPartnership
    ? partnership.ledgerCurrencies?.some((currency) => supportedCurrencies.includes(currency))
    : true;

  const isCurrencySupportedByLedger =
    !!ledgerCurrencies?.length && !isLedgerPartnership
      ? ledgerCurrencies.includes(partnership.currencyCodePartner)
      : true;

  const isPartnershipInternational = isLedgerPartnership
    ? !partnership.ledgerCurrencies?.includes(CurrencyCodeUsd)
    : isCurrencyCodeNonUSD(partnership.currencyCodePartner);

  const isBaseCurrencyModeEnabledAndSupported = Boolean(
    isBaseCurrencyModeEnabled && !isBaseCurrencyNotSupportedByPartnership,
  );
  const isCrossBorderDisabledAndPartnershipIsInternational = Boolean(
    !isCrossBorderEnabled && isPartnershipInternational,
  );
  const isCrossBorderEnabledAndCurrencyNotSupportedByLedger = Boolean(
    isCrossBorderEnabled && !isCurrencySupportedByLedger,
  );
  const isCrossBorderEnabledAndCurrencyNotSupportedByPlatform = Boolean(
    isCrossBorderEnabled && !isCurrencySupportedByPlatform,
  );
  const isCrossBorderEnabledAndPartnershipIsInternational = Boolean(isCrossBorderEnabled && isPartnershipInternational);

  return {
    baseCurrencyCode,
    isBaseCurrencyModeEnabledAndSupported,
    isBaseCurrencyNotSupportedByPartnership,
    isCrossBorderDisabledAndPartnershipIsInternational,
    isCrossBorderEnabled,
    isCrossBorderEnabledAndCurrencyNotSupportedByLedger,
    isCrossBorderEnabledAndCurrencyNotSupportedByPlatform,
    isCrossBorderEnabledAndPartnershipIsInternational,
    isPartnershipInternational,
  };
};

import React from 'react';
import { DashboardToDashboardRestrictionTooltipContent } from 'components';
import { isPartnershipMemberAccessSelfManaged } from 'helpers/partnershipMembers';
import {} from 'interfaces/item';
import {} from './CommonMenuItemProps.types';
export const getCommonMenuItemProps = (contact) => {
    const isMemberSelfManaged = isPartnershipMemberAccessSelfManaged(contact?.accessItem);
    const selfManageTooltipProps = {
        tooltip: React.createElement(DashboardToDashboardRestrictionTooltipContent, null),
    };
    if (isMemberSelfManaged) {
        return {
            disabled: isMemberSelfManaged,
            tooltip: selfManageTooltipProps,
        };
    }
    return undefined;
};

import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import NotFound404 from 'components/error/components/NotFound404';

import { NOT_FOUND } from 'constants/routes';

import AllowAny from './AllowAny';

const SwitchOr404 = ({ children, location }) => (
  <Switch>
    {children}
    <AllowAny component={NotFound404} location={location} path={`/${NOT_FOUND}`} />
    <Route
      render={() => (
        <Redirect
          component={NotFound404}
          to={{
            pathname: `/${NOT_FOUND}`,
            state: { from: location },
          }}
        />
      )}
    />
  </Switch>
);

SwitchOr404.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape().isRequired,
};

export default withRouter(SwitchOr404);

import { parseNotifications as parse } from 'data/parse';

import {
  DELETE_EMAIL_FORWARD_SUCCESS,
  FETCH_NOTIFICATION_SETTINGS_SUCCESS,
  SUBMIT_EMAIL_FORWARD_SUCCESS,
  UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
} from 'types/notifications';

const byIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATION_SETTINGS_SUCCESS:
      return parse.settings.getNotificationSettingsByIdAfterFetch(state, action.payload.notificationSettings);

    case SUBMIT_EMAIL_FORWARD_SUCCESS:
      return parse.settings.getNotificationSettingsByIdAfterEmailForwardSubmit(
        state,
        action.payload.notificationSettingsEmailForward,
      );

    case UPDATE_NOTIFICATION_SETTINGS_SUCCESS:
      return parse.settings.getNotificationSettingsByIdAfterUpdateSuccess(state, action.payload.notificationSettings);

    case DELETE_EMAIL_FORWARD_SUCCESS:
      return parse.settings.getNotificationSettingsByIdAfterEmailForwardDelete(state, action.payload.id);

    default:
      return state;
  }
};

export default byIdReducer;

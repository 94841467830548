import React from 'react';
import ExternalColumn from 'complexComponents/ExternalColumn';
import { FlexRow } from 'components';
import { hasZeroLength } from 'helpers/utility';
import {} from './ExternalBlock.types';
import './ExternalBlock.scss';
const ExternalBlock = ({ columns = [], layoutType, ...rest }) => {
    if (hasZeroLength(columns)) {
        return null;
    }
    return (React.createElement(FlexRow, { className: `external-block ${layoutType}` }, columns.map((col, index) => (React.createElement(ExternalColumn, { className: `col-${index + 1}`, key: `col-${index + 1}`, ...col, ...rest })))));
};
export default ExternalBlock;

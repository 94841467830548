import { IconNames } from 'components';

/**
 * What kind of button are we showing in PartnerPaymentMethodStatus?
 *
 * @enum {string}
 */
export const PartnerPaymentMethodStatusButtonType = {
  ADDRESS: 'address',
  BANK_ACCOUNT: 'bank_account',
  INVOICE: 'invoice',
};

/**
 * Button icon for each PartnerPaymentMethodStatusButtonType
 *
 * @enum {string}
 */
export const PartnerPaymentMethodStatusButtonTypeIcon = {
  [PartnerPaymentMethodStatusButtonType.ADDRESS]: IconNames.GEOLOCATION,
  [PartnerPaymentMethodStatusButtonType.BANK_ACCOUNT]: IconNames.ADD,
  [PartnerPaymentMethodStatusButtonType.INVOICE]: IconNames.DOWNLOAD,
};

/**
 * Button text for each PartnerPaymentMethodStatusButtonType
 *
 * @enum {string}
 */
export const PartnerPaymentMethodStatusButtonTypeText = {
  [PartnerPaymentMethodStatusButtonType.ADDRESS]: 'Add address',
  [PartnerPaymentMethodStatusButtonType.BANK_ACCOUNT]: 'Add bank account',
  [PartnerPaymentMethodStatusButtonType.INVOICE]: 'Send invoice',
};

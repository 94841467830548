import PropTypes from 'prop-types';
import React from 'react';

import {
  IconNames,
  ServerErrorDefaultMessage,
  ServerErrorDefaultTitle,
  ShadedBlock,
  SwalWrapperV2,
  WhiteSpace,
} from 'components';

import { ButtonText } from 'constants/button';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { Intent } from 'constants/ui';

import { getPaymentsOrInvoicesText } from 'helpers/items';

import { CustomerServiceHelper } from 'modules/customerSupport/CustomerService';

/**
 * Swal content for when bulk import crashes
 * @param {ComponentProps} props
 * @param {ItemKind} props.itemKind
 * @param {Function} props.onResetBulkActionsState
 * @param {Function} props.onResetForm
 * @param {Function} props.reset
 * @return {StatelessComponent}
 */
const BulkImportCrashSwal = ({ itemKind, onResetBulkActionsState, onResetForm, reset }) => {
  const onCloseSwal = () => {
    onResetBulkActionsState();
    onResetForm(reset);
  };

  const onContactSupport = () => {
    CustomerServiceHelper.show();
    onCloseSwal();
  };

  return (
    <SwalWrapperV2
      intent={Intent.DANGER}
      leftButtonProps={{
        children: 'Contact support',
        leftIconName: IconNames.CHAT,
        onClick: onContactSupport,
      }}
      rightButtonProps={{
        children: ButtonText.CLOSE,
        onClick: onCloseSwal,
      }}
      subtitle={<ServerErrorDefaultTitle />}
      title="Something went wrong"
    >
      <ShadedBlock className="margin-bottom--large margin-top--m" intent={Intent.NEUTRAL}>
        <ul className="alert-error-list">
          <li>
            {`Your ${getPaymentsOrInvoicesText(
              itemKind,
              2,
            )} cannot be uploaded at this time due to an unexpected error.`}
            <WhiteSpace />
            {`Please try again. If the problem persists, please contact ${PLATFORM_DISPLAY_SHORT_NAME} support for assistance.`}
          </li>
        </ul>
      </ShadedBlock>

      <ServerErrorDefaultMessage />
    </SwalWrapperV2>
  );
};

BulkImportCrashSwal.propTypes = {
  itemKind: PropTypes.string.isRequired,
  onResetBulkActionsState: PropTypes.func.isRequired,
  onResetForm: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default BulkImportCrashSwal;

/**
 * Determine if a TIN may be edited.
 * @param {boolean} companyTypeIsBusiness
 * @param {boolean} hasEin
 * @param {boolean} hasSsn
 * @param {boolean} isSoleProprietor
 * @param {boolean} noEin
 * @returns {boolean}
 */
export const canEditBusinessTin = ({ companyTypeIsBusiness, hasEin, hasSsn, isSoleProprietor, noEin }) => {
  if (companyTypeIsBusiness) {
    // Sole proprietor without EIN, but hasn't entered SSN yet
    if (isSoleProprietor && noEin && !hasSsn) {
      return true;
    }

    // Sole proprietor who intends to use EIN, but hasn't entered the EIN yet
    if (isSoleProprietor && !noEin && !hasEin) {
      return true;
    }

    // Business who is not a sole proprietor and hasn't entered EIN yet
    if (!isSoleProprietor && !hasEin) {
      return true;
    }
  }

  // Individual, but hasn't provided SSN yet
  if (!companyTypeIsBusiness && !hasSsn) {
    return true;
  }

  // Appropriate EIN/SSN already logged on backend. Edit not available
  return false;
};

import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import Icon, { IconNames } from 'components/icon';
import { Text } from 'components/text';

import { sizes, typography } from 'constants/styles';
import { Intent } from 'constants/ui';

import { oneOfValuesFromObject } from 'helpers/propTypes';
import { getIntentIconName } from 'helpers/ui';

import { NotificationCloseButton } from './components';

import './NotificationBar.scss';

/**
 * Notification component similar to the Hint component
 * @param {ComponentProps} props
 * @param {Node} props.children
 * @param {string} [props.className]
 * @param {Boolean} [props.hasBackgroundColor=true]
 * @param {Intent} props.intent - can take 6 values - neutral, danger, success, warning, info, schedule
 * @param {String} [props.icon] - overrides intent icon with a custom one
 * @param {Boolean} [props.isDismissable=true] will show close icon when true
 * @param {Function} props.onCloseNotification
 * @return {StatelessComponent}
 */
const NotificationBar = ({
  children,
  className,
  hasBackgroundColor,
  icon,
  intent,
  isDismissable,
  onCloseNotification,
}) => {
  const textColor = intent === Intent.NEUTRAL ? typography.TextColor.BLACK : typography.TextColor.GREY_XX_DARK;

  return (
    <div className={clsx('notification', className, intent, { 'remove-background-color': !hasBackgroundColor })}>
      <Icon
        className="shrink-0"
        icon={icon || getIntentIconName(intent)}
        marginRight={sizes.spacing.MEDIUM}
        size={sizes.iconSizes.LARGE}
      />

      <div className="notification--body">
        <Text.Semibold color={textColor}>{children}</Text.Semibold>
      </div>

      {isDismissable && <NotificationCloseButton intent={intent} onCloseNotification={onCloseNotification} />}
    </div>
  );
};

NotificationBar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hasBackgroundColor: PropTypes.bool,
  icon: oneOfValuesFromObject(IconNames),
  intent: oneOfValuesFromObject(Intent),
  isDismissable: PropTypes.bool,
  onCloseNotification: PropTypes.func,
};

NotificationBar.defaultProps = {
  className: undefined,
  hasBackgroundColor: true,
  icon: undefined,
  intent: Intent.NEUTRAL,
  isDismissable: true,
  onCloseNotification: undefined,
};

export default NotificationBar;

import { updateMembershipRoutine } from 'actions/routines/membership';

import { deepMergeWithArrayReplacement } from 'helpers/transform';

import getRelationships from 'store/redux';

import {
  FETCH_MEMBERSHIPS_SUCCESS,
  FETCH_MEMBERSHIP_SUCCESS,
  UPDATE_MEMBERSHIP_SUCCESS,
  UPDATE_ONBOARDING_MEMBERSHIP_SUCCESS,
} from 'types/memberships';

const getMembershipPersonalInfoById = (personalInfos) => {
  const personalInfoList = {};

  if (!personalInfos) {
    return personalInfoList;
  }

  Object.keys(personalInfos).forEach((personalInfoId) => {
    const relationships = getRelationships(personalInfos, personalInfoId);
    personalInfoList[personalInfoId] = {
      id: personalInfos[personalInfoId].id,
      ...personalInfos[personalInfoId].attributes,
      ...relationships,
    };
  });

  return personalInfoList;
};

const byIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MEMBERSHIP_SUCCESS:
    case FETCH_MEMBERSHIPS_SUCCESS:
    case UPDATE_MEMBERSHIP_SUCCESS:
    case updateMembershipRoutine.SUCCESS:
    case UPDATE_ONBOARDING_MEMBERSHIP_SUCCESS:
      return deepMergeWithArrayReplacement(state, getMembershipPersonalInfoById(action.payload.personalInfo));

    default:
      return state;
  }
};

export default byIdReducer;

import { createIsDoingReducer } from 'store/redux';

import {
  REGENERATE_API_TOKEN_FAILURE,
  REGENERATE_API_TOKEN_REQUEST,
  REGENERATE_API_TOKEN_SUCCESS,
} from 'types/apiToken';

export const finishCases = [REGENERATE_API_TOKEN_FAILURE, REGENERATE_API_TOKEN_SUCCESS];
export const requestCases = [REGENERATE_API_TOKEN_REQUEST];

const isRegeneratingReducer = createIsDoingReducer(finishCases, requestCases);

export default isRegeneratingReducer;

import { hasFundingAccountLedgerRef, isFundingAccountLedgerOnly } from 'helpers/funding';
import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import {
  FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_SUCCESS,
  SEARCH_FUNDING_ACCOUNTS_SUCCESS,
  SUBMIT_UNMATCHED_PLATFORM_FUNDING_ACCOUNT_SUCCESS,
} from 'types/integrations';

const options = {
  getAttributes: (currState, objects, objectId) => ({
    matched: !!(
      !isFundingAccountLedgerOnly(objects[objectId].attributes) &&
      hasFundingAccountLedgerRef(objects[objectId].attributes)
    ),
  }),
};

const ledgerUnmatchedPlatformFundingAccountsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_SUCCESS:
    case SEARCH_FUNDING_ACCOUNTS_SUCCESS:
      return getObjectsByIdWithRelationships(action.payload.fundingAccount, ['bank'], options);

    case SUBMIT_UNMATCHED_PLATFORM_FUNDING_ACCOUNT_SUCCESS: {
      // update selected funding account to match, does not persist - only for current session
      const { fundingAccountId } = action.payload;

      return deepMergeWithArrayReplacement(state, {
        [fundingAccountId]: {
          ...state[fundingAccountId],
          ledgerRef: action.payload.fundingAccountLedgerRef,
          matched: true,
        },
      });
    }

    default:
      return state;
  }
};

export default ledgerUnmatchedPlatformFundingAccountsByIdReducer;

import { Skeleton } from '@routable/gross-ds';
import React, { useContext } from 'react';
import { TableRow } from '../../components';
import { TableCell } from '../../table.styled';
import {} from '../../table.extended.types';
import { TableContext } from '../../table-name.context';
import { useTableStore } from '../../table.store';
const DefaultSkeletonTemplate = ({ data = [] }) => (React.createElement(TableRow, { className: "border-none" }, data.map(({ columnId, initialSettings }) => {
    let extraClassNames = ``;
    if (initialSettings && initialSettings.width && initialSettings.width.indexOf('cell-width--') > -1) {
        extraClassNames = `${extraClassNames} ${initialSettings.width}`;
    }
    return (React.createElement(TableCell, { key: columnId, className: extraClassNames },
        React.createElement(Skeleton, { className: "py-4 w-full", shape: "round" })));
})));
export const SkeletonTemplate = ({ numOfSkeletonRows = 25, Template = DefaultSkeletonTemplate, }) => {
    const tc = useContext(TableContext);
    const tableName = tc.tableName;
    const { activeTableState } = useTableStore(tableName);
    const skeletons = new Array(numOfSkeletonRows).fill('row-skeleton');
    if (!activeTableState.headers) {
        return null;
    }
    return (React.createElement("div", { className: "table-columns-skeleton", role: "progressbar", "aria-label": `${tableName}:skeleton` }, skeletons.map((name, idx) => (React.createElement(Template, { key: `${name}-${idx}`, data: activeTableState.headers })))));
};

import { getModalReducerOpenAction } from 'store/redux';

import * as types from 'types/connectBank';

export const initialState = {
  open: false,
};

const connectBankManualReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MODAL_CONNECT_BANK_MANUAL_CLOSE:
      return initialState;

    case types.MODAL_CONNECT_BANK_MANUAL_OPEN:
      return getModalReducerOpenAction(state, action);

    default:
      return state;
  }
};

export default connectBankManualReducer;

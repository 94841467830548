import React from 'react';

import { Image } from 'components';

import { ExternalUpdatePaymentFlowState } from 'constants/ui';

import { oneOfValuesFromObject } from 'helpers/propTypes';
import { getUpdatePaymentGraphicUrl } from 'helpers/ui';

/**
 * Renders SVG illustration based on external update payment flow state.
 * @param {ComponentProps} props
 * @param {string} props.updatePaymentState
 * @return {StatelessComponent}
 */
const UpdatePaymentIllustrationSvg = ({ updatePaymentState }) => (
  <Image alt={`${updatePaymentState} illustration`} src={getUpdatePaymentGraphicUrl(updatePaymentState)} />
);

UpdatePaymentIllustrationSvg.propTypes = {
  updatePaymentState: oneOfValuesFromObject(ExternalUpdatePaymentFlowState).isRequired,
};

export default UpdatePaymentIllustrationSvg;

import PropTypes from 'prop-types';
import React from 'react';

import SecureIcon from 'components/icon/SecureIcon';
import { TooltipMUIConditionalWrapper } from 'components/tooltip';

import { sizes } from 'constants/styles';

import { mergeLockedIndicatorTooltipProps } from './helpers';

/**
 * A 🔒 icon to use in place of the normal dropdown ⌄ (down chevron) when the select is locked.
 *
 * @param {ComponentProps} props
 * @param {function} props.getStyles
 * @param {ObjectMaybe} [props.innerProps={}]
 * @param {ObjectMaybe} [props.tooltipProps]
 * @returns {StatelessComponent}
 */
const LockedIndicator = (props) => {
  const { getStyles, innerProps, tooltipProps } = props;

  return (
    <div {...innerProps} style={getStyles('clearIndicator', props)}>
      <TooltipMUIConditionalWrapper tooltipProps={mergeLockedIndicatorTooltipProps(tooltipProps)}>
        <SecureIcon size={sizes.iconSizes.EXTRA_LARGE} />
      </TooltipMUIConditionalWrapper>
    </div>
  );
};

LockedIndicator.propTypes = {
  getStyles: PropTypes.func.isRequired,
  innerProps: PropTypes.shape({
    ref: PropTypes.shape(),
  }),
  tooltipProps: PropTypes.shape({}),
};

LockedIndicator.defaultProps = {
  innerProps: {},
  tooltipProps: undefined,
};

export default LockedIndicator;

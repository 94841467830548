import React from 'react';

import { Tag } from 'components';

import { TagType } from 'constants/ui';

/**
 * Tag notifying the user that the funding account is eligible for RTP (Real-Time Payment)
 * (hidden behind the RTP feature flag)
 * @returns {StatelessComponent}
 */
const BankAccountHeaderRtpEnabledTag = () => (
  <Tag hasIcon={false} isClosable={false} readOnly type={TagType.ARCHIVED}>
    RTP-enabled
  </Tag>
);

export default BankAccountHeaderRtpEnabledTag;

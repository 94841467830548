import { getQueryParam } from 'helpers/queryParams';
import { getSessionStorageValue, setSessionStorageValue } from 'helpers/sessionStorage';

const utmTagsKey = 'utm_tags';

export const getUTMTagsFromSessionStorage = () => {
  const tagsJSON = getSessionStorageValue(utmTagsKey);

  if (!tagsJSON) {
    return undefined;
  }

  return JSON.parse(tagsJSON);
};

export const setUTMTagsToSessionStorage = () => {
  const tags = {
    utm_campaign: getQueryParam('utm_campaign'),
    utm_content: getQueryParam('utm_content'),
    utm_medium: getQueryParam('utm_medium'),
    utm_source: getQueryParam('utm_source') || 'landing',
    utm_term: getQueryParam('utm_term'),
  };

  setSessionStorageValue('utm_tags', JSON.stringify(tags));
};

export const CompanyBeneficialOwnerStatus = {
  DOCUMENT: 'document',
  INCOMPLETE: 'incomplete',
  PENDING: 'pending',
  VERIFIED: 'verified',
};

/**
 * TODO: [FRON-2153] Refactor CompanyPlatformTypes/PartnershipPartnerStatus enum to dedupe and create a single source of
 * truth.
 * @enum {string}
 */
export const CompanyPlatformTypes = {
  ADDED: 'added',
  ARCHIVED: 'archived',
  EXTERNAL: 'external',
  FRAUD: 'fraud',
  INVITED: 'invited',
  REGISTERED: 'registered',
  SIGNUP: 'signup',
};

export const CompanySearchPartnerTypes = {
  EXISTING: 'existing',
  NEW: 'new',
};

export const commonBusinessTypes = {
  C_CORP: 'c_corporation',
  S_CORP: 's_corporation',
  PARTNERSHIP: 'partnership',
  SOLE_PROPRIETOR: 'sole_proprietorship',
};

export const commonBusinessTypeLabels = {
  [commonBusinessTypes.C_CORP]: 'C-Corporation',
  [commonBusinessTypes.S_CORP]: 'S-Corporation',
  [commonBusinessTypes.PARTNERSHIP]: 'Partnership',
  [commonBusinessTypes.SOLE_PROPRIETOR]: 'Sole Proprietor',
};

export const companyBusinessTypes = {
  ...commonBusinessTypes,
  LLC: 'llc',
  TRUST_ESTATE: 'trust_estate',
  LLC_SINGLE_MEMBER: 'llc_single_member',
  LLC_C_CORP: 'llc_c_corp',
  LLC_S_CORP: 'llc_s_corp',
  LLC_PARTNERSHIP: 'llc_partnership',
  LLC_TRUST_ESTATE: 'llc_trust_estate',
};

export const companyBusinessTypeLabels = {
  [companyBusinessTypes.C_CORP]: 'C-Corporation',
  [companyBusinessTypes.S_CORP]: 'S-Corporation',
  [companyBusinessTypes.PARTNERSHIP]: 'Partnership',
  [companyBusinessTypes.SOLE_PROPRIETOR]: 'Sole Proprietor',
  [companyBusinessTypes.TRUST_ESTATE]: 'Trust / Estate',
  [companyBusinessTypes.LLC_SINGLE_MEMBER]: 'LLC (Single Member)',
  [companyBusinessTypes.LLC_C_CORP]: 'LLC (C-Corporation)',
  [companyBusinessTypes.LLC_S_CORP]: 'LLC (S-Corporation)',
  [companyBusinessTypes.LLC_PARTNERSHIP]: 'LLC (Partnership)',
  [companyBusinessTypes.LLC_TRUST_ESTATE]: 'LLC (Trust / Estate)',
};

export const companySizeList = [
  { id: '77c96bed-6890-4f63-8d78-01beb9149278', value: '1-50', text: '1-50' },
  {
    id: 'a862c587-351b-487d-a4df-88ade3459d7d',
    value: '51-100',
    text: '51-100',
  },
  {
    id: 'cd344a0e-0058-4735-903e-4a00a10b75be',
    value: '101-250',
    text: '101-250',
  },
  {
    id: '87c62bdd-3d74-4e2f-8d1b-024f60b77b11',
    value: '251-1000',
    text: '251-1000',
  },
  { id: '35c94b7b-1dfe-4247-ae74-0fb99d54aa1e', value: '1001+', text: '1001+' },
];

export const companyBusinessTypeOptions = [
  {
    id: '44fe8211-9807-4533-8b43-e51935ccfe31',
    value: companyBusinessTypes.C_CORP,
    text: companyBusinessTypeLabels[companyBusinessTypes.C_CORP],
  },
  {
    id: '788d0757-92dc-4e84-b195-5649da346274',
    value: companyBusinessTypes.S_CORP,
    text: companyBusinessTypeLabels[companyBusinessTypes.S_CORP],
  },
  {
    id: '3f9b00e0-88a6-414e-bcaf-cff0c778f819',
    value: companyBusinessTypes.PARTNERSHIP,
    text: companyBusinessTypeLabels[companyBusinessTypes.PARTNERSHIP],
  },
  {
    id: 'ec34899a-ae94-4d54-a763-59131201ea86',
    value: companyBusinessTypes.SOLE_PROPRIETOR,
    text: companyBusinessTypeLabels[companyBusinessTypes.SOLE_PROPRIETOR],
  },
  {
    id: '8edeab32-511c-4b20-9848-1a0467fcbd70',
    value: companyBusinessTypes.TRUST_ESTATE,
    text: companyBusinessTypeLabels[companyBusinessTypes.TRUST_ESTATE],
  },
  {
    id: '9ba6b794-2a35-4014-94cd-8e78015b1de9',
    value: companyBusinessTypes.LLC_SINGLE_MEMBER,
    text: companyBusinessTypeLabels[companyBusinessTypes.LLC_SINGLE_MEMBER],
  },
  {
    id: '7e79bb9e-7211-45ef-9c9b-0338c728bb2b',
    value: companyBusinessTypes.LLC_C_CORP,
    text: companyBusinessTypeLabels[companyBusinessTypes.LLC_C_CORP],
  },
  {
    id: 'ef65cec4-1abc-4099-af22-15e2976d80cc',
    value: companyBusinessTypes.LLC_S_CORP,
    text: companyBusinessTypeLabels[companyBusinessTypes.LLC_S_CORP],
  },
  {
    id: 'b58f3d3f-1d50-405d-855a-c772cac45fcd',
    value: companyBusinessTypes.LLC_PARTNERSHIP,
    text: companyBusinessTypeLabels[companyBusinessTypes.LLC_PARTNERSHIP],
  },
  {
    id: 'eecdb7c9-6bcd-439f-83c4-b9ab27e9121a',
    value: companyBusinessTypes.LLC_TRUST_ESTATE,
    text: companyBusinessTypeLabels[companyBusinessTypes.LLC_TRUST_ESTATE],
  },
];

import { combineReducers } from 'redux';

import companyBrandReducer from './companyBrandReducer';
import companyControllerReducer from './companyControllerReducer';
import companyInfoReducer from './companyInfoReducer';
import companyItemApprovalLevelsReducer from './companyItemApprovalLevelsReducer';
import companyItemApprovalSettingsReducer from './companyItemApprovalSettingsReducer';
import companyMembershipsReducer from './companyMembershipsReducer';
import companyReducer from './companyReducer';
import companySettingsIntegrationReducer from './companySettingsIntegrationReducer';
import companySettingsReducer from './companySettingsReducer';
import companyUsersReducer from './companyUsersReducer';
import errorReducer from './errorReducer';
import fetchReducer from './fetchReducer';
import hasFetchedReducer from './hasFetchedReducer';
import submitAuthRepsReducer from './submitAuthRepsReducer';
import updateReducer from './updateReducer';
import updateSettingsIntegrationReducer from './updateSettingsIntegrationReducer';
import updateSettingsReducer from './updateSettingsReducer';

const currentCompanyReducer = combineReducers({
  brand: companyBrandReducer,
  company: companyReducer,
  controller: companyControllerReducer,
  errors: errorReducer,
  isFetching: fetchReducer,
  hasFetched: hasFetchedReducer,
  info: companyInfoReducer,
  itemApprovalLevels: companyItemApprovalLevelsReducer,
  itemApprovalSettings: companyItemApprovalSettingsReducer,
  memberships: companyMembershipsReducer,
  settings: companySettingsReducer,
  settingsIntegration: companySettingsIntegrationReducer,
  isSubmitting: submitAuthRepsReducer,
  update: updateReducer,
  updateSettings: updateSettingsReducer,
  updateSettingsIntegration: updateSettingsIntegrationReducer,
  users: companyUsersReducer,
});

export default currentCompanyReducer;

import React from 'react';

import { FormFieldDescription, FormFieldLabel, FormSection } from 'components/form';

import { externalPromptHeader, headerParagraph } from 'modules/external/ExternalCollectTaxInfo/constants/text';
import ExternalPromptHeader from 'modules/external/externalPrompt/global/presenters/ExternalPromptHeader';

import './CollectTaxHeader.scss';

/**
 * Descriptive paragraph about the necessity for the information displayed in the form below which collects tax info.
 * @returns {StatelessComponent}
 * @constructor
 */
const CollectTaxHeader = () => (
  <FormSection className="padding-top--l">
    <div className="external-tax--header">
      <ExternalPromptHeader text={externalPromptHeader} />
      <FormFieldLabel className="has-description margin-top--x-large">Registered Entity Info</FormFieldLabel>
      <FormFieldDescription>{headerParagraph}</FormFieldDescription>
    </div>
  </FormSection>
);

export default CollectTaxHeader;

import React from 'react';
import { Text, FlexRow } from 'components';
import { paginationComponent } from 'constants/dataTestId/paginationDataTestId';
import { TextColor, TextSize } from 'constants/styles/typography';
import { PAGE_SIZE_OPTIONS } from 'constants/tables';
import {} from './PageSizeSelection.types';
import { Select } from './Select';
import './PageSizeSelection.scss';
export const PageSizeSelection = ({ pageSize, onPageSizeChange, isLoading }) => {
    if (isLoading || !onPageSizeChange) {
        return null;
    }
    return (React.createElement(FlexRow, { className: "tlp--page-size", stackOnMobile: false },
        React.createElement(Text.Regular, { className: "display", color: TextColor.GREY_X_DARK, "data-testid": paginationComponent.FOOTER_INDEX, size: TextSize.LEVEL_100 }, "Rows per page"),
        React.createElement("div", { className: "dropdown-container" },
            React.createElement(Select, { isSearchable: false, onChange: onPageSizeChange, options: PAGE_SIZE_OPTIONS, value: pageSize, valueKey: "label", placeholder: '' }))));
};

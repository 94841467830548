import { SearchInput } from '@routable/gross-ds';
import React, { forwardRef, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { useDebounce } from 'react-use';
import {} from './TableSearch.types';
export const TableSearch = forwardRef(({ 'aria-label': ariaLabel = 'Search table', 'data-testid': testId, placeholder = 'Search', initialSearchValue, onSearch, }, ref) => {
    const { watch, setValue } = useForm({ defaultValues: { search: initialSearchValue ?? '' } });
    const currentSearchQuery = watch('search');
    useImperativeHandle(ref, () => ({ setValue: (newValue) => setValue('search', newValue) }));
    useDebounce(() => {
        onSearch(currentSearchQuery);
    }, 300, [currentSearchQuery]);
    return (React.createElement(SearchInput, { "aria-label": ariaLabel, "data-testid": testId, onChange: (value) => setValue('search', value), onClear: () => setValue('search', ''), onSubmit: onSearch, placeholder: placeholder, value: currentSearchQuery }));
});

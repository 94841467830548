import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { TableCellTextWithSubtext } from 'components/table';

import { SETTINGS_ACCOUNT_BALANCE_ROUTE } from 'constants/routes';

/**
 * Renders the title (aka the type of transaction) and id for a transaction.
 * If the transaction is for an item payment, wraps these in a link to the item.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 * @constructor
 */
const BalanceTransactionReferenceCell = ({ id, item, rowData, title }) => {
  const idText = _get(rowData, id);
  const titleText = _get(rowData, title);
  const itemUrl = _get(rowData, item);

  const textContent = <TableCellTextWithSubtext subtext={idText} text={titleText} />;

  if (itemUrl) {
    return (
      <Link
        className="truncate font-color--primary"
        to={{
          pathname: itemUrl,
          state: { previous: SETTINGS_ACCOUNT_BALANCE_ROUTE },
        }}
      >
        {React.cloneElement(textContent, {
          textClassName: 'font-color--primary_force',
          subtextClassName: 'font-color--primary_force',
        })}
      </Link>
    );
  }

  return <div className="max-width--full flex-col-center">{textContent}</div>;
};

BalanceTransactionReferenceCell.propTypes = {
  id: PropTypes.string.isRequired,
  item: PropTypes.string,
  rowData: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
};

BalanceTransactionReferenceCell.defaultProps = {
  item: undefined,
};

export default BalanceTransactionReferenceCell;

import { createErrorReducer } from 'store/redux';

import {
  FETCH_DOCUMENTS_FAILURE,
  FETCH_DOCUMENT_FAILURE,
  FETCH_DOCUMENTS_REQUEST,
  FETCH_DOCUMENTS_SUCCESS,
} from 'types/documents';

const failureCases = [FETCH_DOCUMENTS_FAILURE, FETCH_DOCUMENT_FAILURE];

const successCases = [FETCH_DOCUMENTS_REQUEST, FETCH_DOCUMENTS_SUCCESS];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { handleAddAuthTokenAndCurrentIds, hasAuthToken } from 'helpers/auth';
import { BRANDED_WORKSPACE_REDIRECT_QUERY_PARAM_KEY } from 'helpers/constants';
import { getQueryParamValueFromUrl } from 'helpers/queryParams';
export const useHandleBrandedWorkspaceRedirect = () => {
    const history = useHistory();
    React.useEffect(() => {
        const dataParam = getQueryParamValueFromUrl(BRANDED_WORKSPACE_REDIRECT_QUERY_PARAM_KEY);
        if (dataParam) {
            if (!hasAuthToken()) {
                try {
                    const authData = JSON.parse(decodeURIComponent(dataParam));
                    handleAddAuthTokenAndCurrentIds(authData);
                }
                catch (error) {
                    return;
                }
            }
            const newUrl = new URL(window.location.href);
            newUrl.searchParams.delete('redirectBrandedSubdomainData');
            history.replace(`${newUrl.pathname}${newUrl.search}`);
        }
    }, [history]);
};

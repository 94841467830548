import { createSelector } from 'reselect';

import { currentMembershipSelector } from 'selectors/membershipsSelector';

import { currentMembershipHasRolesAndPermissionsSelector } from './membershipPermissionCompoundSelectors';

/**
 * A selector that checks if both roles and permissions exist and has membership
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} ownProps
 * @returns {Boolean}
 */
export const isLoadingMembershipAndHasRolesAndPermissions = createSelector(
  [currentMembershipSelector, currentMembershipHasRolesAndPermissionsSelector],
  (currentMembership, hasRolesAndPermissions) => Boolean(!currentMembership || !hasRolesAndPermissions),
);

import PropTypes from 'prop-types';
import React from 'react';

import Avatar from 'components/avatar/Avatar';

import { getMembershipFullName } from 'helpers/memberships';

const CompanyRep = ({ representative }) => (
  <div className="company-rep">
    <div className="company-rep--avatar">
      <Avatar
        avatar={representative.avatar}
        email={representative.email}
        firstName={representative.firstName}
        lastName={representative.lastName}
        sizeClass="avatar--sm"
        styleClass="avatar--light"
      />
    </div>

    <div className="company-rep--info">
      <p className="dark">{getMembershipFullName(representative)}</p>

      <p className="light-grey">to me</p>
    </div>
  </div>
);

CompanyRep.propTypes = {
  representative: PropTypes.shape({
    avatar: PropTypes.string,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};

export default CompanyRep;

/**
 * @fileOverview Defines the sub-reducer for whether we're currently fetching feature flags.
 * @module reducers/featureFlags
 */

import { getFeatureFlagsRoutine } from 'actions/routines/featureFlags';

import { createIsFetchingReducer } from 'store/redux';

export const finishCases = [getFeatureFlagsRoutine.FAILURE, getFeatureFlagsRoutine.SUCCESS];

export const requestCases = [getFeatureFlagsRoutine.TRIGGER];

/**
 * Reducer for reduxState.featureFlags.isFetching.
 * @type {ReduxReducer}
 */
const isFetchingReducer = createIsFetchingReducer(finishCases, requestCases);

export default isFetchingReducer;

import { FETCH_FUNDING_ACCOUNTS_SUCCESS } from 'types/funding';

import { initialState } from './initialState';

const dashboardLoadedFundingAccountsReducer = (state = initialState.fundingAccounts, action) => {
  switch (action.type) {
    case FETCH_FUNDING_ACCOUNTS_SUCCESS:
      return true;

    default:
      return state;
  }
};

export default dashboardLoadedFundingAccountsReducer;

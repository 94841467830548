import { SUBMIT_MEMBERSHIP_SIGNUP_INVITE } from 'types/inviteTeamMember';
import { CHECK_NAMESPACE } from 'types/namespace';
import {
  SAVE_STATUS_NOT_SAVED,
  SAVE_STATUS_SAVED,
  SAVE_STATUS_SAVING,
  SIGNUP_CHANGE_VIEW,
  CHANGE_REPRESENTATIVE_LOCK,
  INVITE_TEAM_MEMBER_MODAL,
  VIEW_TEAM_MEMBERS_MODAL,
  DEACTIVATE_TEAM_MEMBER_MODAL,
  LOGOUT_MODAL,
  OPEN_SUBMIT_SIGN_UP_FLOW_MODAL,
  CLOSE_SUBMIT_SIGN_UP_FLOW_MODAL,
  TOGGLE_SKIP_STEP_MODAL,
  SET_NEXT_VIEW,
} from 'types/signup';

export const changeView = (view) => ({
  type: SIGNUP_CHANGE_VIEW,
  payload: { view },
});

export const changeRepresentativeLock = (isLocked) => ({
  type: CHANGE_REPRESENTATIVE_LOCK,
  payload: { isLocked },
});

export const toggleInviteTeamMemberModal = () => ({
  type: INVITE_TEAM_MEMBER_MODAL,
});

export const toggleViewTeamMembersModal = () => ({
  type: VIEW_TEAM_MEMBERS_MODAL,
});

export const toggleDeactivateTeamMemberModal = (membershipId) => ({
  type: DEACTIVATE_TEAM_MEMBER_MODAL,
  payload: {
    membershipId,
  },
});

export const toggleLogoutModal = () => ({
  type: LOGOUT_MODAL,
});

export const toggleSkipStepModal = () => ({
  type: TOGGLE_SKIP_STEP_MODAL,
});

export const inviteTeamMember = (payload, companyId) => ({
  type: SUBMIT_MEMBERSHIP_SIGNUP_INVITE,
  payload: {
    ...payload,
    companyId,
  },
});

const saved = () => ({
  type: SAVE_STATUS_SAVED,
});

const saving = () => ({
  type: SAVE_STATUS_SAVING,
});

const notSaved = () => ({
  type: SAVE_STATUS_NOT_SAVED,
});

export const changeSaveStatus = {
  saved,
  saving,
  notSaved,
};

export const validateNamespace = (value) => ({
  type: CHECK_NAMESPACE,
  payload: value,
});

export const openSubmitForVerificationConfirmation = () => ({
  type: OPEN_SUBMIT_SIGN_UP_FLOW_MODAL,
});

export const closeSubmitForVerificationConfirmation = () => ({
  type: CLOSE_SUBMIT_SIGN_UP_FLOW_MODAL,
});

export const setNextView = (nextView) => ({
  type: SET_NEXT_VIEW,
  payload: nextView,
});

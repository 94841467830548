import { submitItemRoutine } from 'actions/routines/item';
import { lookupPartnershipEmailsRoutine, submitCreatePartnershipRoutine } from 'actions/routines/partnership';
import {
  createPartnershipMemberRoutine,
  removeFormPartnershipMember,
  setFormPartnershipMembers,
} from 'actions/routines/partnershipMember';

import { createItemFormFields } from 'constants/formFields';

import { parseFormItem } from 'data/parse/item';
import { parseFormPartnershipMember } from 'data/parse/partnershipMember';

import {
  derivePartnershipMemberStatusFromAttributes,
  getPartnershipMemberWithAccessItemFromDefaultItem,
} from 'helpers/partnershipMembers';
import { getNextStateForEmailOnValidateFailed, getNextStateForEmailOnValidateSuccess } from 'helpers/reducer';

import { FETCH_PARTNERSHIP_SUCCESS } from 'types/partnership';

const parserOptions = {
  getAttributes: (currentState, objects, objectId) => ({
    status: derivePartnershipMemberStatusFromAttributes(objects[objectId]),
  }),
  partnershipMembersFieldName: createItemFormFields.ITEM_MEMBERS,
  transformPartnershipMember: getPartnershipMemberWithAccessItemFromDefaultItem,
};

const createItemsFormReducer = (state = undefined, action) => {
  if (!state?.values) {
    return state;
  }

  switch (action.type) {
    case removeFormPartnershipMember.TRIGGER:
      return parseFormPartnershipMember.getNextFormStateForRemoveFormPartnershipMember(state, action);

    case createPartnershipMemberRoutine.SUCCESS:
      return parseFormPartnershipMember.getNextFormStateForAppendSinglePartnershipMember(state, action, parserOptions);

    case setFormPartnershipMembers.TRIGGER:
      return parseFormPartnershipMember.getNextFormStateForSetFormPartnershipMembers(state, action);

    case FETCH_PARTNERSHIP_SUCCESS:
    case submitCreatePartnershipRoutine.SUCCESS:
      return parseFormPartnershipMember.getNextFormStateForCreateOrFetchPartnership(state, action, parserOptions);

    case submitItemRoutine.FULFILL:
      return parseFormItem.getNextFormStateForCreateItemSubmitFulfilled(state, action);

    case lookupPartnershipEmailsRoutine.FAILURE:
      return getNextStateForEmailOnValidateFailed(state, action);

    case lookupPartnershipEmailsRoutine.SUCCESS:
      return getNextStateForEmailOnValidateSuccess(state, action);

    default:
      return state;
  }
};

export default createItemsFormReducer;

import React, { memo } from 'react';
import { tabClassnames } from './page.sub.header.types';
import { Container, Tab, Tabs } from './page.sub.header.styled';
const PageSubHeader = memo(({ children }) => {
    return (React.createElement(Container, { padded: false, scrollable: false }, children));
});
const pageSubHeader = PageSubHeader;
pageSubHeader.Tabs = ({ children, className }) => {
    return React.createElement(Tabs, { className: className ? className : '' }, children);
};
pageSubHeader.Tab = ({ tabKey, className, title, selected = false, onClick }) => {
    const handleClick = () => {
        if (onClick) {
            onClick(tabKey);
        }
    };
    return (React.createElement(Tab, { className: tabClassnames({ className, selected, error: false, disabled: false }), "data-selected": selected, onClick: handleClick, "data-testid": "subHeader" }, title));
};
export { pageSubHeader as PageSubHeader };

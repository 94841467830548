import {
  formNamesBulkActions,
  formNamesContact,
  formNamesItem,
  formNamesMembership,
  formNamesPartnership,
  formNamesSignup,
} from 'constants/forms';

import { inviteTeamMemberForm } from 'modules/signup-v3/constants';

import bulkImportItemsFormReducer from './bulkImportItemsFormReducer';
import contactFormsReducer from './contactFormsReducer';
import convertExternalFormReducer from './convertExternalFormReducer';
import createItemsFormReducer from './createItemsFormReducer';
import createPartnershipFormReducer from './createPartnershipFormReducer';
import inviteTeamMemberFormReducer from './inviteTeamMemberFormReducer';
import sendUpdatePaymentLinkFormReducer from './sendUpdatePaymentLinkFormReducer';

const formReducerPlugin = {
  [formNamesBulkActions.IMPORT_ITEMS]: bulkImportItemsFormReducer,
  [formNamesContact.CREATE]: contactFormsReducer,
  [formNamesContact.UPDATE]: contactFormsReducer,
  [formNamesItem.CREATE_ITEM]: createItemsFormReducer,
  [formNamesMembership.INVITE_TEAMMATE]: inviteTeamMemberFormReducer,
  [formNamesPartnership.CREATE]: createPartnershipFormReducer,
  [formNamesPartnership.SEND_UPDATE_PAYMENT_LINK]: sendUpdatePaymentLinkFormReducer,
  [formNamesSignup.CONVERT_EXTERNAL]: convertExternalFormReducer,
  [inviteTeamMemberForm]: inviteTeamMemberFormReducer,
};

export default formReducerPlugin;

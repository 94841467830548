import PropTypes from 'prop-types';
import React from 'react';

import { commonFormFields } from 'constants/formFields';
import { BankAccountViewType } from 'constants/ui';

import { isEqual } from 'helpers/utility';

import BankAccountRadio from 'modules/fundingAccount/bankAccountRadio';

/**
 * Component rendering Address Funding Account radio select input
 *
 * @param {ComponentProps} props
 * @param {FundingAccount} props.fundingAccount
 * @param {FundingInfoAddress} props.fundingInfoAddress
 * @param {Boolean} [props.isDisabled]
 * @param {Function} props.onChange
 * @param {Partnership} props.partnership
 * @param {string} [props.selectedAccount]
 * @returns {StatelessComponent}
 */
const AddressFundingAccount = ({
  fundingAccount,
  fundingInfoAddress,
  isDisabled,
  onChange,
  partnership,
  selectedAccount,
}) => (
  <BankAccountRadio
    displayBody
    fundingAccount={fundingAccount}
    fundingInfoAddress={fundingInfoAddress}
    id={fundingAccount.id}
    input={{
      checked: isEqual(fundingAccount.id, selectedAccount),
      name: commonFormFields.FUNDING_ACCOUNT,
      onChange,
      value: fundingAccount.id,
    }}
    isCollapsible={false}
    isDisabled={isDisabled}
    key={fundingAccount.id}
    partnership={partnership}
    viewType={BankAccountViewType.ADDRESS}
  />
);

AddressFundingAccount.propTypes = {
  fundingAccount: PropTypes.shape().isRequired,
  fundingInfoAddress: PropTypes.shape().isRequired,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  partnership: PropTypes.shape().isRequired,
  selectedAccount: PropTypes.string,
};

AddressFundingAccount.defaultProps = {
  isDisabled: undefined,
  selectedAccount: undefined,
};

export default AddressFundingAccount;

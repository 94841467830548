export var ITEM_SORT_TYPE_UPDATED_DATE;
(function (ITEM_SORT_TYPE_UPDATED_DATE) {
    ITEM_SORT_TYPE_UPDATED_DATE["LABEL"] = "Updated on";
    ITEM_SORT_TYPE_UPDATED_DATE["PARAM"] = "thread_modified";
})(ITEM_SORT_TYPE_UPDATED_DATE || (ITEM_SORT_TYPE_UPDATED_DATE = {}));
export var ITEM_SORT_TYPE_DUE_DATE;
(function (ITEM_SORT_TYPE_DUE_DATE) {
    ITEM_SORT_TYPE_DUE_DATE["LABEL"] = "Due on";
    ITEM_SORT_TYPE_DUE_DATE["PARAM"] = "date_due";
})(ITEM_SORT_TYPE_DUE_DATE || (ITEM_SORT_TYPE_DUE_DATE = {}));
export var ITEM_SORT_TYPE_INVOICE_DATE;
(function (ITEM_SORT_TYPE_INVOICE_DATE) {
    ITEM_SORT_TYPE_INVOICE_DATE["LABEL"] = "Invoice date";
    ITEM_SORT_TYPE_INVOICE_DATE["PARAM"] = "date_issued";
})(ITEM_SORT_TYPE_INVOICE_DATE || (ITEM_SORT_TYPE_INVOICE_DATE = {}));
export var ITEM_SORT_TYPE_AMOUNT_DUE;
(function (ITEM_SORT_TYPE_AMOUNT_DUE) {
    ITEM_SORT_TYPE_AMOUNT_DUE["LABEL"] = "Amount due";
    ITEM_SORT_TYPE_AMOUNT_DUE["PARAM"] = "amount_due";
})(ITEM_SORT_TYPE_AMOUNT_DUE || (ITEM_SORT_TYPE_AMOUNT_DUE = {}));
export var ITEM_SORT_TYPE_AMOUNT_PAID;
(function (ITEM_SORT_TYPE_AMOUNT_PAID) {
    ITEM_SORT_TYPE_AMOUNT_PAID["LABEL"] = "Amount paid";
    ITEM_SORT_TYPE_AMOUNT_PAID["PARAM"] = "amount_paid";
})(ITEM_SORT_TYPE_AMOUNT_PAID || (ITEM_SORT_TYPE_AMOUNT_PAID = {}));
export var ITEM_SORT_TYPE_ADDED_DATE;
(function (ITEM_SORT_TYPE_ADDED_DATE) {
    ITEM_SORT_TYPE_ADDED_DATE["LABEL"] = "Added date";
    ITEM_SORT_TYPE_ADDED_DATE["PARAM"] = "created";
})(ITEM_SORT_TYPE_ADDED_DATE || (ITEM_SORT_TYPE_ADDED_DATE = {}));

import { createIsSubmittingReducer } from 'store/redux';

import {
  SUBMIT_LEDGER_PARTNERSHIP_MERGE_REQUEST,
  SUBMIT_LEDGER_PARTNERSHIP_MERGE_FAILURE,
  SUBMIT_LEDGER_PARTNERSHIP_MERGE_SUCCESS,
} from 'types/integrations';

export const finishCases = [SUBMIT_LEDGER_PARTNERSHIP_MERGE_FAILURE, SUBMIT_LEDGER_PARTNERSHIP_MERGE_SUCCESS];
export const requestCases = [SUBMIT_LEDGER_PARTNERSHIP_MERGE_REQUEST];

const submitReducer = createIsSubmittingReducer(finishCases, requestCases);

export default submitReducer;

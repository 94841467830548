import React from 'react';

import Icon, { IconNames } from 'components/icon';

import { colors, sizes } from 'constants/styles';

const CheckListIncompleteBullet = () => (
  <span className="check-list--item--bullet-wrapper">
    <Icon
      className="check-list--item--bullet check-list--item--bullet_incomplete"
      color={colors.colorTransparent}
      icon={IconNames.BLANK}
      size={sizes.iconSizes.SMALL}
    />
  </span>
);

export default CheckListIncompleteBullet;

import _groupBy from 'lodash/groupBy';
import { LineItemStyles } from 'constants/lineItems';
import { LINE_ITEMS_SUBSECTIONS, LINE_ITEMS_BLOCKS, } from 'modules/dashboard/createItems/invoiceGenerator/components/InvoiceGeneratorView/constants';
export const fillLineItemWithPropertiesFromPoLineItem = ({ targetLineItem, poLineItem, isExtended, isOverride, }) => {
    let result = { ...targetLineItem };
    Object.keys(poLineItem).forEach((key) => {
        if (key === 'extended') {
            result = fillLineItemWithPropertiesFromPoLineItem({
                targetLineItem: result,
                poLineItem: poLineItem.extended,
                isExtended: true,
            });
            return;
        }
        const keyPath = isExtended ? `extended_${key}` : key;
        if (keyPath === 'account' || keyPath === 'item' || isExtended || !targetLineItem[keyPath]) {
            result[keyPath] = poLineItem[key];
        }
    });
    if (isOverride) {
        return {
            ...result,
            ...poLineItem,
        };
    }
    return result;
};
export const formatLineItemsFromPurchaseOrder = ({ purchaseOrderLineItems, defaultLineItems }) => {
    const groupedByStyle = _groupBy(purchaseOrderLineItems?.map((poLineItem) => {
        const subsection = LINE_ITEMS_SUBSECTIONS[poLineItem.style.toUpperCase()];
        const block = LINE_ITEMS_BLOCKS[poLineItem.style.toUpperCase()];
        return fillLineItemWithPropertiesFromPoLineItem({
            targetLineItem: defaultLineItems?.[block]?.[subsection]?.[0] || {},
            poLineItem,
            isOverride: true,
        });
    }), 'style');
    return {
        [LINE_ITEMS_BLOCKS.ACCOUNT]: {
            [LINE_ITEMS_SUBSECTIONS.ACCOUNT]: groupedByStyle?.[LineItemStyles.ACCOUNT] ||
                defaultLineItems?.[LINE_ITEMS_BLOCKS.ACCOUNT]?.[LINE_ITEMS_SUBSECTIONS.ACCOUNT],
        },
        [LINE_ITEMS_BLOCKS.ITEM]: {
            [LINE_ITEMS_SUBSECTIONS.ITEM]: groupedByStyle?.[LineItemStyles.ITEM] ||
                defaultLineItems?.[LINE_ITEMS_BLOCKS.ITEM]?.[LINE_ITEMS_SUBSECTIONS.ITEM],
        },
    };
};

import { change } from 'redux-form';

import { formNamesExternal } from 'constants/forms';

/**
 * Dispatches a change to the form to enable field edits for EditButton input.
 * @example
 * const mapDispatchToProps = {
 *   onFirstNameEdit: createEditHandler('ui.editFirstName'),
 * };
 * @function
 * @param {string} field
 * @returns {function}
 */
export const createEditHandler = (field) => () => change(formNamesExternal.COLLECT_TAX_INFO, field, true);

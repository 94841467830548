import PropTypes from 'prop-types';
import React from 'react';

import { Modal, ModalBodyContent } from 'components/modal';

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

import { MaintenanceModeBody } from 'modules/maintenance/maintenanceMode/components';

const MaintenanceModeModal = ({ modalState, onCloseModal }) => (
  <Modal
    body={
      <ModalBodyContent className="maintenance-mode">
        <MaintenanceModeBody />
      </ModalBodyContent>
    }
    hasInlineFooter
    modalHeader={`${PLATFORM_DISPLAY_SHORT_NAME} is currently in maintenance mode`}
    modalState={modalState.open}
    onCloseModal={onCloseModal}
    overrideStyle={{ content: { width: 600 } }}
    testId="maintenance-mode-modal"
  />
);

MaintenanceModeModal.propTypes = {
  modalState: PropTypes.shape().isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default MaintenanceModeModal;

import { useMemo, useEffect } from 'react';
import { usePrevious, useMount, useUnmount } from 'react-use';
const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
export const IntercomInternal = ({ appId, config }) => {
    const email = config?.email;
    const userId = config?.user_id;
    const isLoggedIn = useMemo(() => Boolean(email || userId), [email, userId]);
    const wasLoggedIn = usePrevious(isLoggedIn);
    const canUseIntercom = canUseDOM && !!window.Intercom;
    useMount(() => {
        if (!window.Intercom) {
            (function bootIntercom(w, d, id, s, _x) {
                function i() {
                    i.c(arguments);
                }
                i.q = [];
                i.c = function pushArguments(args) {
                    i.q.push(args);
                };
                w.Intercom = i;
                s = d.createElement('script');
                s.async = 1;
                s.src = `https://widget.intercom.io/widget/${id}`;
                d.head.appendChild(s);
            })(window, document, appId);
        }
        window.intercomSettings = { ...config, app_id: appId };
        window.Intercom?.('boot', config);
    });
    useEffect(() => {
        window.intercomSettings = { app_id: appId, ...config };
    }, [config, appId]);
    useEffect(() => {
        if (!canUseIntercom) {
            return;
        }
        if (!isLoggedIn && wasLoggedIn) {
            window.Intercom('shutdown');
            window.Intercom('boot', config);
        }
        else {
            window.Intercom('update', config);
        }
    }, [canUseIntercom, isLoggedIn, wasLoggedIn, config]);
    useUnmount(() => {
        if (!canUseIntercom) {
            return;
        }
        window.Intercom('shutdown');
        delete window.Intercom;
        delete window.intercomSettings;
    });
    return null;
};

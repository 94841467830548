import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2, IconNames, TooltipIcon } from 'components';

import { ButtonSize } from 'constants/button';
import { colors, margin, sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

import * as text from 'modules/fundingAccount/partnerPaymentMethodStatus/text';

/**
 * Component rendering the content on the right side of the Partner Payment Method
 * Status
 * @param {Object} props
 * @param {String} [props.buttonIcon]
 * @param {String} [props.buttonIconColor]
 * @param {String} [props.buttonText]
 * @param {Boolean} [props.displayCtaButton=true]
 * @param {Boolean} [props.isButtonDisabled]
 * @param {Function} [props.onButtonClick]
 * @returns {StatelessComponent}
 */
const PartnerPaymentMethodStatusContentRight = ({
  buttonIcon,
  buttonIconColor,
  buttonText,
  displayCtaButton,
  isButtonDisabled,
  onButtonClick,
}) => (
  <React.Fragment>
    {!displayCtaButton && (
      <TooltipIcon
        content={text.customerTypeInfoTooltip}
        contentMargin={margin.medium}
        icon={IconNames.INFO_SIGN}
        iconColor={colors.colorMainJordan}
        iconProps={{ size: sizes.iconSizes.MEDIUM }}
      />
    )}

    {displayCtaButton && (
      <ButtonV2
        className="right w-max"
        intent={Intent.NEUTRAL}
        isDisabled={isButtonDisabled}
        leftIconClassName="margin-right--xm"
        leftIconColor={buttonIconColor}
        leftIconName={buttonIcon}
        leftIconSize={sizes.iconSizes.LARGE}
        onClick={onButtonClick}
        size={ButtonSize.SMALL}
        type="button"
      >
        {buttonText}
      </ButtonV2>
    )}
  </React.Fragment>
);

PartnerPaymentMethodStatusContentRight.propTypes = {
  buttonIcon: PropTypes.string,
  buttonIconColor: PropTypes.string,
  buttonText: PropTypes.string,
  displayCtaButton: PropTypes.bool,
  isButtonDisabled: PropTypes.bool,
  onButtonClick: PropTypes.func,
};

PartnerPaymentMethodStatusContentRight.defaultProps = {
  buttonIcon: undefined,
  buttonIconColor: undefined,
  buttonText: undefined,
  displayCtaButton: true,
  isButtonDisabled: undefined,
  onButtonClick: undefined,
};

export default PartnerPaymentMethodStatusContentRight;

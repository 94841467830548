import { updateMembershipRoutine } from 'actions/routines/membership';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import { GET_CURRENT_COMPANY_SUCCESS, UPDATE_CURRENT_COMPANY_SUCCESS } from 'types/currentCompany';
import { FETCH_MEMBERSHIP_SUCCESS, FETCH_MEMBERSHIPS_SUCCESS, UPDATE_MEMBERSHIP_SUCCESS } from 'types/memberships';

const documentsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CURRENT_COMPANY_SUCCESS:
    case FETCH_MEMBERSHIP_SUCCESS:
    case FETCH_MEMBERSHIPS_SUCCESS:
    case UPDATE_CURRENT_COMPANY_SUCCESS:
    case UPDATE_MEMBERSHIP_SUCCESS:
    case updateMembershipRoutine.SUCCESS: {
      return deepMergeWithArrayReplacement(state, getObjectsByIdWithRelationships(action.payload.document));
    }

    default:
      return state;
  }
};

export default documentsByIdReducer;

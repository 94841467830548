import React from 'react';

/**
 * HOC which adds isDisabled prop to the component.
 *
 * @param {AnyComponent} Component
 * @param {StringMaybe} [isDisabledProp='isDisabled']
 * @returns {AnyComponent}
 */
const withIsDisabled = (Component, isDisabledProp = 'isDisabled') =>
  React.forwardRef((props, ref) => {
    const dynamicIsDisabledProp = { [isDisabledProp]: true };
    return <Component setRef={ref} {...props} {...dynamicIsDisabledProp} />;
  });

export default withIsDisabled;

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { fetchMembershipInviteRequest } from 'actions/inviteTeamMember';
import { companySettingsSSOByNamespaceFetchRoutine } from 'actions/routines/sso';
import { formNamesAuth } from 'constants/forms';
import { getAcceptingMembershipInvitePageErrorType } from 'helpers/authentication';
import { noop } from 'helpers/utility';
import { companyFromQuerySelector } from 'queries/companyCompoundSelectors';
import { acceptMembershipInviteInitialValuesSelector, membershipInviteFromQuerySelector, } from 'queries/membershipInviteCompoundSelectors';
import { isFetchingMembershipInvitesSelector, isSubmittingMembershipInviteSelector, membershipInvitesErrorsSelector, } from 'selectors/membershipInvitesSelectors';
import { firstRoleSelector } from 'selectors/rolesSelectors';
import { companyIdQuerySelector, membershipInviteIdQuerySelector, membershipInviteTokenQuerySelector, } from 'selectors/routerSelectors';
import { currentSSOSettingsSelector } from 'selectors/ssoSelectors';
import AcceptMembershipInvitePage from './AcceptMembershipInvitePage';
const AcceptMembershipInvitePageContainer = (props) => {
    const { company, companyId, errors, membershipInviteId, membershipInviteToken, onFetchCompanySettingsSSO, onFetchMembershipInvite, } = props;
    const errorType = getAcceptingMembershipInvitePageErrorType(errors);
    const memoFetchCompanySettingsSSO = React.useCallback(onFetchCompanySettingsSSO, []);
    const memoFetchMembershipInvite = React.useCallback(onFetchMembershipInvite, []);
    React.useEffect(() => {
        memoFetchMembershipInvite(companyId, membershipInviteId, membershipInviteToken);
    }, [companyId, membershipInviteId, membershipInviteToken, memoFetchMembershipInvite]);
    React.useEffect(() => {
        if (company?.namespace) {
            memoFetchCompanySettingsSSO({ params: { namespace: company.namespace } });
        }
    }, [company, memoFetchCompanySettingsSSO]);
    return React.createElement(AcceptMembershipInvitePage, { ...props, errorType: errorType });
};
export const mapStateToProps = createStructuredSelector({
    company: companyFromQuerySelector,
    companyId: companyIdQuerySelector,
    companySSOSettings: currentSSOSettingsSelector,
    errors: membershipInvitesErrorsSelector,
    initialValues: acceptMembershipInviteInitialValuesSelector,
    isFetching: isFetchingMembershipInvitesSelector,
    isSubmitting: isSubmittingMembershipInviteSelector,
    membershipInvite: membershipInviteFromQuerySelector,
    membershipInviteId: membershipInviteIdQuerySelector,
    membershipInviteToken: membershipInviteTokenQuerySelector,
    role: firstRoleSelector,
});
export const mapDispatchToProps = {
    onFetchCompanySettingsSSO: companySettingsSSOByNamespaceFetchRoutine.trigger,
    onFetchMembershipInvite: fetchMembershipInviteRequest,
};
const AcceptMembershipInviteReduxForm = reduxForm({
    enableReinitialize: true,
    form: formNamesAuth.ACCEPT_MEMBERSHIP_INVITE,
    onSubmitFail: noop,
})(AcceptMembershipInvitePageContainer);
const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));
export { AcceptMembershipInvitePageContainer };
export default enhance(AcceptMembershipInviteReduxForm);

import { SSOActionType } from 'enums/sso';
import {} from 'interfaces/sso';
export const closeSSODisableModal = () => ({
    type: SSOActionType.CLOSE_DISABLE_MODAL,
});
export const closeSSOSetupFormModal = () => ({
    type: SSOActionType.CLOSE_SETUP_FORM_MODAL,
});
export const closeSSOSetupFormSplashscreenModal = () => ({
    type: SSOActionType.CLOSE_SETUP_SPLASHSCREEN_MODAL,
});
export const openSSODisableModal = () => ({
    type: SSOActionType.OPEN_DISABLE_MODAL,
});
export const openSSOSetupFormModalWithProvider = (ssoProvider) => ({
    type: SSOActionType.OPEN_SETUP_FORM_MODAL_WITH_PROVIDER,
    payload: { ssoProvider },
});
export const openSSOSetupFormSplashscreenModal = () => ({
    type: SSOActionType.OPEN_SETUP_SPLASHSCREEN_MODAL,
});
export const removeSSOCompanySettings = () => ({
    type: SSOActionType.REMOVE_SSO_SETTINGS,
});

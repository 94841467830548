import clsx from 'clsx';
import React from 'react';
import { HintIcon } from './components';
import './Hint.scss';
const Hint = ({ bodyClassName, children, className, hideIcon, iconProps, intent, isCollapsible, multiline, shouldRemoveBackgroundColor, ...rest }) => {
    return (React.createElement("div", { className: clsx('hint', intent, className, {
            'remove-background-color': shouldRemoveBackgroundColor,
            multiline: !!multiline,
        }), ...rest },
        !hideIcon && React.createElement(HintIcon, { customization: iconProps, intent: intent }),
        React.createElement("div", { className: clsx('hint--body', bodyClassName, { 'width--100-percent': isCollapsible }) }, children)));
};
export default Hint;

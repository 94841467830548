import { createErrorReducer } from 'store/redux';

import { FETCH_USER_FAILURE, FETCH_USERS_FAILURE, FETCH_USERS_REQUEST, FETCH_USERS_SUCCESS } from 'types/user';

export const failureCases = [FETCH_USER_FAILURE, FETCH_USERS_FAILURE];
export const successCases = [FETCH_USERS_REQUEST, FETCH_USERS_SUCCESS];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

import {} from '../../baseLogger';
import {} from '../systemLogger.types';
export const createConsoleTransport = () => ({
    name: 'console',
    transport: async (messages) => {
        messages.forEach(({ level, message, ...rest }) => {
            if (Object.keys(rest).length) {
                console.groupCollapsed(level, message);
                Object.entries(rest).forEach(([key, value]) => console.log(key, value));
                console.groupEnd();
            }
            else {
                console.log(level, message);
            }
        });
    },
});

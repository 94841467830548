import merge from 'deepmerge';

import { allKeys } from 'helpers/utility';

import { SUBMIT_MESSAGE_SUCCESS } from 'types/messages';
import { FETCH_THREAD_SUCCESS } from 'types/thread';

const allMessagesReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_THREAD_SUCCESS:
      return allKeys(action.payload.message);

    case SUBMIT_MESSAGE_SUCCESS:
      return merge(state, allKeys(action.payload.message));

    default:
      return state;
  }
};

export default allMessagesReducer;

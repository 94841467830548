import { z } from 'zod';
import {} from './common.model';
import { conditionValuesModel } from './condition.model';
import { levelModel } from './level.model';
const baseRuleModel = z.object({
    label: z.string(),
    ordinal: z.number(),
    conditions: z.array(conditionValuesModel),
    levels: levelModel.array(),
});
const ruleDataModel = z.intersection(baseRuleModel, z.object({
    levels: z.array(levelModel),
}));
const policyDataModel = z.object({
    companyId: z.string().uuid(),
    lastModifiedById: z.string().uuid(),
    version: z.number(),
    policy: z.array(ruleDataModel),
});
export const policyModel = policyDataModel.extend({
    id: z.string().uuid(),
});
export const savePolicyDataModel = z.object({
    id: z.string().optional(),
    policy: z.array(ruleDataModel),
});
export const policyApiListModel = z.preprocess((raw) => {
    const resp = raw;
    return {
        ...resp,
        data: resp.data,
    };
}, z.object({
    data: policyModel.array(),
}));
export const policyApiSingularModel = z.preprocess((raw) => {
    const resp = raw;
    return {
        ...resp,
        data: resp.data,
    };
}, z.object({
    data: policyModel,
}));

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Row } from 'components/layout';
import { TitleWithDetails, TitleWithSubtitle } from 'components/text/TitleWithSubtitle';

import { breakpointNames } from 'constants/mediaQuery';

import './InteractionBar.scss';

/**
 * Display a short blurb of text with an optional icon, tooltip and up to two action buttons.
 * @param {Object} props
 * @param {ReactNode} [props.buttonLeft]
 * @param {ReactNode} [props.buttonRight]
 * @param {String} [props.className]
 * @param {ReactNode} props.leftContent
 * @param {Object} [props.iconProps]
 * @param {Object} [props.ledgerIconProps]
 * @param {Boolean} [props.isVisible=true]
 * @param {Object} [props.tooltipProps]
 * @return {StatelessComponent}
 */
const InteractionBar = ({
  buttonLeft,
  buttonRight,
  className,
  leftContent,
  iconProps,
  ledgerIconProps,
  isVisible,
  tooltipProps,
}) => {
  if (!isVisible) {
    return null;
  }

  let buttonRightElement;

  // Pass a classname in to the buttonRight element if it exists
  if (buttonRight) {
    buttonRightElement = React.cloneElement(buttonRight, {
      ...buttonRight.props,
      className: classNames({
        'button-right': true,
        [buttonRight.props.className]: !!buttonRight.props.className,
      }),
    });
  }

  return (
    <Row
      breakpointProps={{
        [breakpointNames.tablet]: {
          flexWrap: 'no-wrap',
        },
      }}
      className={classNames({
        'interaction-bar': true,
        [className]: !!className,
      })}
      flexWrap="wrap"
      justifyContent="space-between"
    >
      <Row alignItems="center" className="min-w-0">
        <TitleWithSubtitle
          iconProps={iconProps}
          ledgerIconProps={ledgerIconProps}
          titles={[<TitleWithDetails key="title-with-details" main={leftContent} tooltipProps={tooltipProps} />]}
        />
      </Row>
      <Row alignItems="center">
        {buttonLeft}
        {buttonRightElement}
      </Row>
    </Row>
  );
};

InteractionBar.propTypes = {
  buttonLeft: PropTypes.node,
  buttonRight: PropTypes.node,
  className: PropTypes.string,
  leftContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  iconProps: PropTypes.shape({
    color: PropTypes.string,
    icon: PropTypes.string,
    size: PropTypes.number,
  }),
  isVisible: PropTypes.bool,
  ledgerIconProps: PropTypes.shape({
    ledger: PropTypes.shape(),
    size: PropTypes.number,
  }),
  tooltipProps: PropTypes.shape({
    content: PropTypes.string,
  }),
};

InteractionBar.defaultProps = {
  buttonLeft: undefined,
  buttonRight: undefined,
  className: undefined,
  iconProps: undefined,
  isVisible: true,
  ledgerIconProps: undefined,
  tooltipProps: undefined,
};

export default InteractionBar;

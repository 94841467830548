import { createIsUpdatingReducer } from 'store/redux';

import {
  UPDATE_CURRENT_COMPANY_REQUEST,
  UPDATE_CURRENT_COMPANY_FAILURE,
  UPDATE_CURRENT_COMPANY_SUCCESS,
} from 'types/currentCompany';

export const finishCases = [UPDATE_CURRENT_COMPANY_FAILURE, UPDATE_CURRENT_COMPANY_SUCCESS];
export const requestCases = [UPDATE_CURRENT_COMPANY_REQUEST];

const isUpdatingReducer = createIsUpdatingReducer(finishCases, requestCases);

export default isUpdatingReducer;

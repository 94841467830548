import { RoutableStorage, useEventSync } from '@routable/framework';
import React, { useContext, useState } from 'react';
import { TableContext } from '../../table-name.context';
import {} from '../../table.extended.types';
import { useTableStore } from '../../table.store';
export const RowTemplate = ({ Template }) => {
    const { tableName } = useContext(TableContext);
    const { getTableStateItem, activeTableState } = useTableStore(tableName);
    const [data, setData] = useState(activeTableState.data || []);
    useEventSync(`table:${tableName}:data`, async (e) => {
        setData(Array.isArray(e) ? e : (await RoutableStorage.get(tableName)));
    });
    if (!data) {
        return React.createElement(React.Fragment, null);
    }
    const filteredResults = data.filter((d) => d !== null);
    return (React.createElement(React.Fragment, null, filteredResults.map((d, idx) => (React.createElement(Template, { key: d.id || `row_${idx}`, data: d, columns: getTableStateItem('headers').map(({ columnId }) => columnId) || [] })))));
};

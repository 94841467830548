import { showFormFailureToast } from 'helpers/errors';

/**
 * Returns a function to be called (by redux-form) when a form submit fails, using
 * the given form name.
 * @param formName {string}
 * @returns {function(): void}
 */
export const makeFormSubmitFailHandler = (formName) => (errors, dispatch, submitErr, props) => {
  if (props.isConnectBankModalOpen || props.isAddressModalOpen) {
    return;
  }

  showFormFailureToast(formName);
};

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { baseDefaultProps, basePropTypes } from 'components/commonProps';

import './FormControlBoundary.scss';

/**
 * Boundary container for form controls that don't naturally have one (e.g. labeled checkbox).
 * @param {ComponentProps} props
 * @param {children} props.children
 * @param {StringMaybe} props.className
 * @param {Function} props.onBoundaryBlur
 * @param {Function} props.onBoundaryClick
 * @param {Function} props.onBoundaryFocus
 * @param {Function} props.onBoundaryFocus
 * @param {Function} props.onBoundaryKeyPress
 * @param {Object|Function} props.setRef
 * @param {Object} props.style
 * @returns {StatelessComponent}
 */
const FormControlBoundary = (props) => {
  const {
    children,
    className,
    onBoundaryBlur,
    onBoundaryClick,
    onBoundaryFocus,
    onBoundaryKeyPress,
    setRef,
    style,
    ...rest
  } = props;

  return (
    <div
      {...rest}
      className={classNames('form-control-boundary', className, {
        clickable: Boolean(onBoundaryClick),
      })}
      onBlur={onBoundaryBlur}
      onClick={onBoundaryClick}
      onFocus={onBoundaryFocus}
      onKeyPress={onBoundaryKeyPress}
      ref={setRef}
      role="button"
      style={style}
      tabIndex={onBoundaryFocus && 0}
    >
      {children}
    </div>
  );
};

FormControlBoundary.propTypes = {
  ...basePropTypes,
  children: PropTypes.node,
  onBoundaryBlur: PropTypes.func,
  onBoundaryClick: PropTypes.func,
  onBoundaryFocus: PropTypes.func,
  onBoundaryKeyPress: PropTypes.func,
};

FormControlBoundary.defaultProps = {
  ...baseDefaultProps,
  children: undefined,
  onBoundaryBlur: undefined,
  onBoundaryClick: undefined,
  onBoundaryFocus: undefined,
  onBoundaryKeyPress: undefined,
};

export default FormControlBoundary;

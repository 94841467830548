import * as types from 'types/files';

import initialState from './initialState';

const objectUrlReducer = (state = initialState.objectUrl, action) => {
  switch (action.type) {
    case types.FETCH_FILE_FOR_OBJECT_URL_SUCCESS:
      return action.payload.objectUrl;

    case types.FETCH_FILE_FOR_OBJECT_URL_REQUEST:
    case types.FETCH_FILE_FOR_OBJECT_URL_FAILURE:
      return initialState.objectUrl;

    default:
      return state;
  }
};

export default objectUrlReducer;

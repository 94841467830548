import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';
import { dashTextToCamelCase, uppercaseToSnakeCaseWithNumbers, uppercaseToUnderscore } from './string';
export const payloadToUnderscore = (payload, { includeNumbers } = { includeNumbers: false }) => {
    if (typeof payload === 'string' || payload === null || payload === undefined) {
        return payload;
    }
    if (_isArray(payload)) {
        return payload.map((element) => payloadToUnderscore(element));
    }
    return Object.keys(payload).reduce((accumulator, key) => {
        const currentValue = payload[key];
        const currentKeyUnderscored = includeNumbers ? uppercaseToSnakeCaseWithNumbers(key) : uppercaseToUnderscore(key);
        if (_isArray(currentValue) || _isObject(currentValue)) {
            return {
                ...accumulator,
                [currentKeyUnderscored]: payloadToUnderscore(currentValue),
            };
        }
        return { ...accumulator, [currentKeyUnderscored]: currentValue };
    }, {});
};
export const formatValueToCamelCase = (value, shouldFormat = true) => {
    const shouldTransform = shouldFormat && typeof value === 'string';
    return shouldTransform ? dashTextToCamelCase(value) : value;
};

import * as routines from 'actions/routines/funding';
import { countryPaymentOption } from 'constants/relationships';
import { allValues } from 'helpers/utility';
const getAllSupportedCountryCodes = (supportedCountries) => {
    const result = allValues(supportedCountries).map((country) => {
        const { attributes } = country;
        return attributes.countryCode;
    });
    return ['', ...result];
};
const allCountryCodesReducer = (state = [], action) => {
    switch (action.type) {
        case routines.fetchSupportedCountriesRoutine.SUCCESS:
            return getAllSupportedCountryCodes(action.payload[countryPaymentOption]);
        default:
            return state;
    }
};
export default allCountryCodesReducer;

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { clearSendPartnershipUpdatePaymentMethodUrlStatus, fetchPartnershipReceivableFundingAccountsRequest, updatePartnershipPrimaryReceivableFundingAccount, } from 'actions/partnership';
import { createOnChangeToPrimary } from 'modules/fundingAccount/partnerPaymentMethod/helpers/createOnChangeToPrimary';
import { PaymentMethodManage } from 'modules/fundingAccount/PaymentMethod';
import { fundingAccountCreatorFromPropSelector, fundingAccountFundingSourcesFromFundingAccountPropSelector, } from 'queries/fundingCompoundSelectors';
import { partnershipFundingAccountForFundingAccountPropSelector } from 'queries/partnershipCompoundSelectors';
import { fundingInfoAddressForFundingAccountPropSelector, fundingInfoBankAccountForFundingAccountPropSelector, fundingInfoInternationalForFundingAccountPropSelector, } from 'selectors/fundingSelectors';
import { partnershipFundingAccountsIsSubmittingSelector } from 'selectors/partnershipsSelectors';
import { createMergeProps } from 'store/redux';
import submitPartnershipPrimaryReceivableFundingAccount from 'thunks/submitPartnershipPrimaryReceivableFundingAccount';
import {} from './PartnerPaymentMethodSelectionOptionPaymentMethod.types';
export const mapStateToProps = createStructuredSelector({
    creator: fundingAccountCreatorFromPropSelector,
    isSubmitting: partnershipFundingAccountsIsSubmittingSelector,
    fundingInfoAddress: fundingInfoAddressForFundingAccountPropSelector,
    fundingInfoBankAccount: fundingInfoBankAccountForFundingAccountPropSelector,
    fundingInfoInternational: fundingInfoInternationalForFundingAccountPropSelector,
    fundingSources: fundingAccountFundingSourcesFromFundingAccountPropSelector,
    partnershipFundingAccount: partnershipFundingAccountForFundingAccountPropSelector,
});
export const mapDispatchToProps = {
    onClearSendPartnershipUpdatePaymentMethodUrlStatus: clearSendPartnershipUpdatePaymentMethodUrlStatus,
    onFetchPartnershipReceivableFundingAccounts: fetchPartnershipReceivableFundingAccountsRequest,
    onSubmitPartnershipPrimaryReceivableFundingAccount: submitPartnershipPrimaryReceivableFundingAccount,
    onUpdatePartnershipPrimaryReceivableFundingAccount: updatePartnershipPrimaryReceivableFundingAccount,
};
export const mergeProps = createMergeProps((stateProps, dispatchProps, ownProps) => ({
    onPrimary: createOnChangeToPrimary({
        ...dispatchProps,
        partnership: ownProps.partnership,
        partnershipFundingAccount: stateProps.partnershipFundingAccount,
    }),
}));
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PaymentMethodManage);

import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useEnsuredForwardedRef, useDebounce, useWindowSize } from 'react-use';

import { ConditionalWrapper } from 'components/ConditionalWrapper';
import FormFieldErrors from 'components/error/components/FormFieldErrors';
import * as inputHelpers from 'components/form/helpers/inputs';
import { Text } from 'components/text';
import { TooltipMUI } from 'components/tooltip';

import { typography } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';

import { isElementTruncated } from 'helpers/dom';
import { getFieldErrors } from 'helpers/errors';

import CharacterCount from '../CharacterCount';
import * as commonProps from '../commonProps';
import InputAddon from '../InputAddon';
import InputField from '../InputField';
import InputLabel from '../InputLabel';
import { InputPlaceholder } from '../InputPlaceholder';
import InputValueHighlighter from '../InputValueHighlighter';

import '../Input.scss';

/**
 * InputFieldWrapper
 * @param {ComponentProps} props
 * @param {*} props.children
 * @param {RestOfProps} addonProps
 * @returns {StatelessComponent}
 */
const InputFieldWrapper = ({ children, ...addonProps }) => {
  const {
    EditButton,
    isEditProtected,
    isLocked,
    showValidationIcon,
    tooltipContent,
    tooltipProps,
    onEdit,
    setRef,
    ...inputProps
  } = addonProps;
  const {
    addon,
    className,
    dataTestId,
    dataFullStory,
    errors,
    hasErrors: hasErrorsProp,
    hideLabel,
    highlightRegex,
    inputClassName,
    isCharacterCountShown,
    isRequired,
    label,
    maximumCharacterCount,
    meta,
    name,
    placeholder,
    value,
  } = inputProps;
  const inputRef = useEnsuredForwardedRef(setRef);
  const { width } = useWindowSize();

  // Determine if the field should be disabled for any reason
  const isDisabled = isLocked || isEditProtected || inputProps.isDisabled;

  // Determine if a tooltip should be shown to display the full text in the input field
  const [shouldShowTooltip, setShouldShowTooltip] = React.useState(false);

  // Any time that the width of the browser window changes, calculate if the tooltip
  // should be displayed. Because the tooltip is not shown while resizing, we can
  // debounce quite agressively as this isn't an important visual change.
  useDebounce(
    () => {
      const isTruncated = isElementTruncated(inputRef.current);
      setShouldShowTooltip(isDisabled && isTruncated);
    },
    300,
    [isDisabled, setShouldShowTooltip, width],
  );

  // The 'truncate' class should only be applied if the input field is disabled but does
  // not necessarily mean that the value is truncated. It just adds styles to support
  // this case.
  const inputFieldClasses = clsx(inputClassName, { truncate: isDisabled });

  const hasErrors = hasErrorsProp !== undefined ? hasErrorsProp : Boolean(getFieldErrors(errors, name));
  const { widthClasses, otherClasses } = inputHelpers.getInputClasses({ addon, className, isDisabled }, { hasErrors });

  return (
    <div className={clsx(widthClasses)}>
      <div className={clsx('input-container', otherClasses)}>
        {!hideLabel && (
          <InputLabel isRequired={isRequired} label={label} name={name} placeholder={placeholder} value={value} />
        )}

        <ConditionalWrapper
          condition={shouldShowTooltip}
          wrapper={(wrapperChildren) => (
            <TooltipMUI
              arrow
              padding={TooltipPadding.LARGE}
              placement={TooltipPlacement.TOP}
              title={<Text.Regular size={typography.TextSize.LEVEL_100}>{value}</Text.Regular>}
            >
              {wrapperChildren}
            </TooltipMUI>
          )}
        >
          <InputField
            {...inputProps}
            className={inputFieldClasses}
            dataFullStory={dataFullStory}
            dataTestId={dataTestId}
            isDisabled={isDisabled}
            setRef={inputRef}
          />
        </ConditionalWrapper>
        <InputPlaceholder isRequired={isRequired} placeholder={placeholder} value={value} />
        {highlightRegex && <InputValueHighlighter regex={highlightRegex} value={value} />}

        {children}

        <InputAddon
          addon={addon}
          EditButton={EditButton}
          hasErrors={hasErrors}
          isEditProtected={isEditProtected}
          isLocked={isLocked}
          meta={meta}
          onEdit={onEdit}
          showValidationIcon={showValidationIcon}
          tooltipContent={tooltipContent}
          tooltipProps={tooltipProps}
          value={value}
        />
      </div>
      <CharacterCount
        errors={errors}
        isCharacterCountShown={isCharacterCountShown}
        maximumCharacterCount={maximumCharacterCount}
        value={value}
      />
      <FormFieldErrors errors={errors} fieldName={name} />
    </div>
  );
};

InputFieldWrapper.propTypes = {
  ...commonProps.propTypes,
  addon: PropTypes.node,
  dataTestId: PropTypes.string,
  EditButton: PropTypes.elementType,
  hideLabel: PropTypes.bool,
  highlightRegex: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)]),
  inputClassName: PropTypes.string,
  isCharacterCountShown: PropTypes.bool,
  isEditProtected: PropTypes.bool,
  isLocked: PropTypes.bool,
  isRequired: PropTypes.bool,
  onEdit: PropTypes.func,
  showValidationIcon: PropTypes.bool,
  tooltipContent: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  tooltipProps: PropTypes.shape({}),
};

InputFieldWrapper.defaultProps = {
  ...commonProps.defaultProps,
  addon: undefined,
  dataTestId: undefined,
  EditButton: undefined,
  hideLabel: undefined,
  highlightRegex: undefined,
  inputClassName: '',
  isCharacterCountShown: undefined,
  isEditProtected: undefined,
  isLocked: undefined,
  onEdit: undefined,
  showValidationIcon: undefined,
  tooltipContent: undefined,
  tooltipProps: undefined,
};

export default InputFieldWrapper;

import { ItemFilterLabels, ItemFilterUrlParams } from 'enums/itemFilters';
import {} from 'interfaces/itemFilters/filter';
import { FilterOperator } from 'interfaces/itemFilters/filterOperators';
export const baseAmountDueFilter = {
    urlParam: ItemFilterUrlParams.FILTER_AMOUNT_DUE_URL_PARAM,
    label: ItemFilterLabels.AMOUNT_DUE,
    value: null,
    selectedOperator: FilterOperator.GREATER_THAN_OR_EQUAL,
};
export const createAmountDueFilter = (value = null, filterOperator = FilterOperator.GREATER_THAN_OR_EQUAL) => ({
    ...baseAmountDueFilter,
    selectedOperator: filterOperator,
    value,
});
export const baseBankAccountFilter = {
    urlParam: ItemFilterUrlParams.FILTER_BANK_ACCOUNT_URL_PARAM,
    label: ItemFilterLabels.BANK_ACCOUNT,
    selectedOperator: FilterOperator.IS_IN_SET,
    value: null,
};
export const createBankAccountFilter = (value = null) => ({
    ...baseBankAccountFilter,
    selectedOperator: FilterOperator.IS_IN_SET,
    value,
});
export const baseBulkActionFilter = {
    urlParam: ItemFilterUrlParams.FILTER_BULK_ACTION_URL_PARAM,
    label: ItemFilterLabels.BULK_ACTION,
    selectedOperator: FilterOperator.EQUALS,
    value: null,
};
export const createBulkActionFilter = (value = null) => ({
    ...baseBulkActionFilter,
    value,
});
export const baseBulkActionStatusFilter = {
    urlParam: ItemFilterUrlParams.FILTER_BULK_ACTION_STATUS_URL_PARAM,
    label: ItemFilterLabels.BULK_ACTION_STATUS,
    selectedOperator: FilterOperator.IS_IN_SET,
    value: null,
};
export const createBulkActionStatusFilter = (value = null) => ({
    ...baseBulkActionStatusFilter,
    value,
});
export const baseCreatedDateFilter = {
    urlParam: ItemFilterUrlParams.FILTER_CREATED_DATE_URL_PARAM,
    label: ItemFilterLabels.CREATED_DATE,
    selectedOperator: FilterOperator.EQUALS,
    value: null,
};
export const createCreatedDateFilter = (value = null, filterOperator = FilterOperator.EQUALS) => ({
    ...baseCreatedDateFilter,
    selectedOperator: filterOperator,
    value,
});
export const baseCurrentApproverFilter = {
    urlParam: ItemFilterUrlParams.FILTER_PAYMENT_APPROVERS_URL_PARAM,
    label: ItemFilterLabels.CURRENT_APPROVER,
    selectedOperator: FilterOperator.IS_IN_SET,
    value: null,
};
export const createCurrentApproverFilter = (value = null) => ({
    ...baseCurrentApproverFilter,
    value,
});
export const baseCurrentApproverStatusFilter = {
    urlParam: ItemFilterUrlParams.FILTER_APPROVER_STATUS_URL_PARAM,
    label: ItemFilterLabels.CURRENT_APPROVER_STATUS,
    selectedOperator: FilterOperator.IS_IN_SET,
    value: null,
};
export const createCurrentApproverStatusFilter = (value = null) => ({
    ...baseCurrentApproverStatusFilter,
    value,
});
export const baseDateDueFilter = {
    urlParam: ItemFilterUrlParams.FILTER_DATE_DUE_URL_PARAM,
    label: ItemFilterLabels.DUE_DATE,
    value: null,
    selectedOperator: FilterOperator.EQUALS,
};
export const createDateDueFilter = (value = null, filterOperator = FilterOperator.EQUALS) => ({
    ...baseDateDueFilter,
    selectedOperator: filterOperator,
    value,
});
export const baseItemStatusFilter = {
    urlParam: ItemFilterUrlParams.FILTER_ITEM_STATUS_URL_PARAM,
    label: ItemFilterLabels.ITEM_STATUS,
    selectedOperator: FilterOperator.EQUALS,
    value: null,
};
export const createItemStatusFilter = (value = null) => ({
    ...baseItemStatusFilter,
    value,
});
export const createIdFilter = (value = null) => ({
    label: ItemFilterLabels.ITEM_ID,
    value,
});
export const basePaymentFilter = {
    urlParam: ItemFilterUrlParams.FILTER_PAYMENT_ID_URL_PARAM,
    label: ItemFilterLabels.PAYMENT_ID,
    selectedOperator: FilterOperator.EQUALS,
};
export const createPaymentFilter = (value) => ({
    ...basePaymentFilter,
    selectedOperator: Array.isArray(value) ? FilterOperator.IS_IN_SET : FilterOperator.EQUALS,
    value,
});
export const baseInvoiceNumbersFilter = {
    urlParam: ItemFilterUrlParams.FILTER_INVOICE_NUMBERS_URL_PARAM,
    label: ItemFilterLabels.INVOICE_NUMBERS,
    selectedOperator: FilterOperator.EXACT_MATCH_IN_COMMA_SEPARATED_LIST,
    value: null,
};
export const createInvoiceNumbersFilter = (value = '', selectedOperator = FilterOperator.EXACT_MATCH_IN_COMMA_SEPARATED_LIST) => ({
    ...baseInvoiceNumbersFilter,
    value,
    selectedOperator,
});
export const createPoMatchTypeFilter = (value = null) => ({
    label: ItemFilterLabels.PO_MATCH_TYPE,
    value,
    urlParam: ItemFilterUrlParams.FILTER_PO_MATCH_TYPE_URL_PARAM,
});
export const createPoDiscrepancyTypeFilter = (value = null) => ({
    label: ItemFilterLabels.PO_DISCREPANCY_TYPE,
    value,
    urlParam: ItemFilterUrlParams.FILTER_PO_DISCREPANCY_TYPE_URL_PARAM,
});

import React from 'react';
import { PoweredByWordMark, SafeAndSecureBadge } from 'components';
import { PrivacyPolicyLink, TermsOfServiceLink, HelpDocsLink, SystemStatusLink } from 'components/link';
import { CustomerServiceHelper } from 'modules/customerSupport/CustomerService';
import './ExternalFlowFooter.scss';
const ExternalFlowFooter = () => {
    return (React.createElement("footer", { className: "external__footer" },
        React.createElement("div", { className: "external__footer--wordmark" },
            React.createElement(PoweredByWordMark, null)),
        React.createElement("div", { className: "external__footer--items" },
            React.createElement(SafeAndSecureBadge, null),
            React.createElement(HelpDocsLink, null),
            React.createElement(SystemStatusLink, null),
            React.createElement(PrivacyPolicyLink, null),
            React.createElement(TermsOfServiceLink, null),
            React.createElement("a", { onClick: () => CustomerServiceHelper.show(), role: "presentation" }, "Contact us"))));
};
export default ExternalFlowFooter;

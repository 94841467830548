import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const InfoCardBottom = ({ children, className }) => (
  <div
    className={classNames({
      'info-card--bottom': true,
      [className]: !!className,
    })}
  >
    {children}
  </div>
);

InfoCardBottom.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

InfoCardBottom.defaultProps = {
  className: undefined,
};

export default InfoCardBottom;

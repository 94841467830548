import React, {} from 'react';
const Switch = (props) => {
    const child = React.Children.toArray(props.children).find((childNode) => {
        if (React.isValidElement(childNode)) {
            return (typeof childNode.props.condition === 'undefined' ||
                (typeof childNode.props.condition === 'boolean' && childNode.props.condition) ||
                (typeof childNode.props.condition === 'function' && childNode.props.condition()));
        }
    });
    return child || null;
};
Switch.Case = ({ children }) => React.createElement(React.Fragment, null, children);
Switch.Default = ({ children }) => React.createElement(React.Fragment, null, children);
export { Switch };

import React from 'react';

/**
 * A helper component to add a whitespace to text adjacent.
 * @param {ComponentProps} props
 * @returns {JSX.Element}
 * @deprecated Component is deprecated, use plain JSX instead
 */

const WhiteSpace = React.memo((props) => <span {...props}> </span>);

WhiteSpace.displayName = 'WhiteSpace';

export default WhiteSpace;

import { LINE_ITEM_EMAIL_DOCUMENT } from 'constants/lineItems';
import { downloadFileFromURL } from 'helpers/fileHelpers';
import { ATTACHMENT_DEFAULT_FILENAME } from 'helpers/ocr/constants';
import {} from 'interfaces/attachment';
import {} from 'interfaces/billAttachment';
export const AttachmentDisplayFileName = {
    [ATTACHMENT_DEFAULT_FILENAME]: LINE_ITEM_EMAIL_DOCUMENT,
};
export const onFileSelectOptionDownloadClick = (billAttachment) => downloadFileFromURL(billAttachment.file, billAttachment.originalFilename);
export const isPrimary = (attachment, primaryAttachmentId, attachments) => (attachment.id && primaryAttachmentId && attachment.id === primaryAttachmentId) ||
    (attachments.length === 1 && attachment.filename === ATTACHMENT_DEFAULT_FILENAME);
export const createTransformAttachmentsToBillAttachment = (primaryAttachmentId, selectedBillIndex) => (attachment, index, attachments) => ({
    ...attachment,
    index,
    isRemote: Boolean(attachment.id),
    originalFilename: attachment.filename,
    fileName: AttachmentDisplayFileName[attachment.filename] || attachment.filename,
    label: attachment.filename,
    value: attachment.id || attachment.filename,
    status: attachment.latestAnnotationStatus || 'error',
    preview: attachment.file,
    primary: isPrimary(attachment, primaryAttachmentId, attachments),
    selected: index === selectedBillIndex,
    isDeleting: false,
});
export const getSelectedBillIndexInitalValue = (billAttachments) => {
    if (!billAttachments.length) {
        return -1;
    }
    const primaryAttachmentIndex = billAttachments.findIndex((entry) => entry.primary);
    if (primaryAttachmentIndex > -1) {
        return primaryAttachmentIndex;
    }
    return 0;
};

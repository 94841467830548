import { apiCall } from '@routable/framework';
import { itemApproveApiModel, itemBypassApiModel } from '../../models';
export const approvalsUrl = 'approvals/';
export const itemApprovalActionsApi = {
    approve: async (itemId) => apiCall({
        url: `${approvalsUrl}approve/item/${itemId}/`,
        method: 'post',
        camelCaseResponse: true,
        responseModel: itemApproveApiModel,
    }),
    bypass: async (itemId) => apiCall({
        url: `${approvalsUrl}bypass/item/${itemId}/`,
        method: 'post',
        camelCaseResponse: true,
        responseModel: itemBypassApiModel,
    }),
};

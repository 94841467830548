import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchSingleMembershipRequest } from 'actions/memberships';
import { fetchPermissionsRoutine } from 'actions/routines/permissions';
import { fetchRolesRoutine } from 'actions/routines/roles';

import { ButtonV2, IconNames } from 'components';

import { ButtonSize } from 'constants/button';
import { CONTACT_SUPPORT_LOWERCASE_LABEL } from 'constants/labels';
import { modalNameConnectBankManual } from 'constants/modals';
import { DASHBOARD, GUIDE } from 'constants/routes';
import { sizes } from 'constants/styles';
import { BankAccountViewType } from 'constants/ui';

import GenericContainer from 'helpers/containers';
import { getCurrentMembershipId } from 'helpers/localStorage';

import ConnectBankContainer from 'modules/connectBank/global/ConnectBankContainer';
import BankAccount from 'modules/fundingAccount/bankAccount';
import { IsLoadingComponent } from 'modules/isLoading/IsLoading';

import { isLoadingMembershipAndHasRolesAndPermissions } from 'queries/signupCompoundSelectors';

import {
  fundingAccountBankManualIsSubmittingSelector,
  fundingAccountBankManualLastSubmittedSelector,
  fundingAccountBankTokenIsSubmittingSelector,
  fundingAccountBankTokenLastSubmittedSelector,
  fundingAccountsLastSubmittedSelector,
} from 'selectors/fundingSelectors';
import { modalIsOpenSelector } from 'selectors/modalsSelector';
import { permissionGroupsLastFetchedSelector } from 'selectors/permissionGroupsSelectors';
import { rolesLastFetchedSelector } from 'selectors/rolesSelectors';

/**
 * @param {ComponentProps} props
 * @param {Boolean} props.connectBankManualOpen
 * @param {Boolean} props.isLoading
 * @param {Boolean} props.isSubmittingManualAccount
 * @param {Boolean} props.isSubmittingTokenAccount
 * @param {String} props.lastSubmittedFundingAccount
 * @param {String} props.lastSubmittedManualAccount
 * @param {String} props.lastSubmittedTokenAccount
 * @param {Function} props.onFetchRoles,
 * @param {Function} props.onFetchPermissionsAndPermissionGroups,
 * @returns {ClassComponent}
 */
export class BankAccountSelector extends GenericContainer {
  componentDidMount() {
    const { onFetchMembership, onFetchPermissionsAndPermissionGroups, onFetchRoles, hasPermissionGroups, hasRoles } =
      this.props;
    const currentMembershipId = getCurrentMembershipId();

    // NOTE: Without onFetchMembership right permissions won't be selected.
    onFetchMembership(currentMembershipId);

    if (!hasPermissionGroups) {
      onFetchPermissionsAndPermissionGroups();
    }

    if (!hasRoles) {
      onFetchRoles();
    }
  }

  renderLoadingAccountInfo = () => {
    if (!this.props.isLoading) {
      return null;
    }
    return <IsLoadingComponent hasShadow text="Loading your account information" />;
  };

  renderLoadingTokenAccount = () => {
    if (!this.props.isSubmittingTokenAccount) {
      return null;
    }
    return <IsLoadingComponent hasShadow text="Loading your bank account" />;
  };

  renderConnectBank = () => {
    const {
      isLoading,
      isSubmittingTokenAccount,
      lastSubmittedManualAccount,
      lastSubmittedTokenAccount,
      connectBankManualOpen,
      AddLaterButton,
    } = this.props;

    if (
      isLoading ||
      ((isSubmittingTokenAccount || lastSubmittedManualAccount || lastSubmittedTokenAccount) && !connectBankManualOpen)
    ) {
      return null;
    }

    return (
      <>
        <ConnectBankContainer createCheckSource />
        {AddLaterButton || undefined}
      </>
    );
  };

  renderSuccessManualAccount = () => {
    if (!this.props.lastSubmittedManualAccount) {
      return null;
    }
    return (
      <div className="margin-bottom--large">
        <div className="alert-main note large-padding margin-bottom--large disable-pointer-events">
          <p className="text remove-margin-bottom">
            {`You will be able to verify your account through your dashboard. If
            you don&apos;t see two small credits in 3 business days, please ${CONTACT_SUPPORT_LOWERCASE_LABEL} `}
            You will be able to verify your account through your dashboard. If you don&apos;t see two small credits in 3
            business days, please and we&apos;ll help get this issue resolved.
          </p>
        </div>

        <BankAccount fundingAccount={this.props.lastSubmittedFundingAccount} viewType={BankAccountViewType.SIMPLE} />
      </div>
    );
  };

  renderSuccessTokenAccount = () => {
    if (!this.props.lastSubmittedTokenAccount) {
      return null;
    }

    return (
      <BankAccount fundingAccount={this.props.lastSubmittedFundingAccount} viewType={BankAccountViewType.SIMPLE} />
    );
  };

  renderContinueButton = () => {
    if (!this.props.lastSubmittedTokenAccount && !this.props.lastSubmittedManualAccount) {
      return null;
    }

    return (
      <div className="form-control remove-margin-bottom">
        <ButtonV2
          className="margin-top--m-large"
          leftIconClassName="margin-right--xm"
          leftIconName={IconNames.TICK_CIRCLE}
          leftIconSize={sizes.iconSizes.LARGE}
          onClick={() => {
            this.props.history.push(`/${DASHBOARD}/${GUIDE}`);
          }}
          rightIconName={IconNames.ARROW_RIGHT}
          rightIconProps={{ style: { marginLeft: 'auto' } }}
          rightIconSize={sizes.iconSizes.LARGE}
          size={ButtonSize.LARGE}
        >
          Complete my account
        </ButtonV2>
      </div>
    );
  };

  render() {
    return (
      <>
        {this.renderConnectBank()}
        {this.renderLoadingTokenAccount()}
        {this.renderLoadingAccountInfo()}
        {this.renderSuccessManualAccount()}
        {this.renderSuccessTokenAccount()}
      </>
    );
  }
}

BankAccountSelector.propTypes = {
  connectBankManualOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSubmittingManualAccount: PropTypes.bool,
  isSubmittingTokenAccount: PropTypes.bool.isRequired,
  lastSubmittedFundingAccount: PropTypes.shape({}),
  lastSubmittedManualAccount: PropTypes.bool.isRequired,
  lastSubmittedTokenAccount: PropTypes.bool.isRequired,
  onFetchMembership: PropTypes.func.isRequired,
  onFetchPermissionsAndPermissionGroups: PropTypes.func.isRequired,
  onFetchRoles: PropTypes.func.isRequired,
  AddLaterButton: PropTypes.node,
};

BankAccountSelector.defaultProps = {
  lastSubmittedFundingAccount: undefined,
  AddLaterButton: undefined,
};

export const mapStateToProps = (state) => ({
  connectBankManualOpen: modalIsOpenSelector(state, modalNameConnectBankManual),
  // NOTE: Checking roles.isFetching & permissions.isFetching doesn't work because on BankAccountSelector's initial render.
  // Both attributes will initially be false causing the ConnectBankContainer to render prematurely and crashing.
  // We instead check if any role|permission id's exist.
  // Also, we can't check memberships.isLoading since the LiveChat component will trigger its own fetchMembership.
  // That causes the loading state to render twice.
  isLoading: isLoadingMembershipAndHasRolesAndPermissions(state),
  isSubmittingManualAccount: fundingAccountBankManualIsSubmittingSelector(state),
  isSubmittingTokenAccount: fundingAccountBankTokenIsSubmittingSelector(state),
  lastSubmittedFundingAccount: fundingAccountsLastSubmittedSelector(state),
  lastSubmittedManualAccount: !!fundingAccountBankManualLastSubmittedSelector(state),
  lastSubmittedTokenAccount: !!fundingAccountBankTokenLastSubmittedSelector(state),
  hasRoles: rolesLastFetchedSelector(state),
  hasPermissionGroups: permissionGroupsLastFetchedSelector(state),
});

export const mapDispatchToProps = {
  onFetchMembership: fetchSingleMembershipRequest,
  onFetchPermissionsAndPermissionGroups: fetchPermissionsRoutine,
  onFetchRoles: fetchRolesRoutine,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BankAccountSelector));

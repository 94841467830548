import merge from 'deepmerge';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';

import { SUBMIT_MESSAGE_SUCCESS } from 'types/messages';
import { FETCH_THREAD_SUCCESS } from 'types/thread';

const messagesByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_THREAD_SUCCESS:
      return getObjectsByIdWithRelationships(action.payload.message, ['sender', 'thread']);

    case SUBMIT_MESSAGE_SUCCESS:
      return merge(state, getObjectsByIdWithRelationships(action.payload.message, ['sender', 'thread']));

    default:
      return state;
  }
};

export default messagesByIdReducer;

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { selectComponents, SelectFieldV2 } from 'components';

import { field } from 'constants/styles/formStyles';

import { requiredValidator } from 'helpers/fieldValidation';

/**
 * A dropdown select field which takes the role options from BE to be selected and sent to the form.
 * @param {ComponentProps} props
 * @param {Boolean} props.isDisabled
 * @param {Boolean} props.isLocked
 * @param {Object} props.lockedTooltipContent
 * @param {String} props.name
 * @param {Array} props.selectOptions
 * @param {String} props.staticValue
 * @returns {StatelessComponent}
 */
const TeamMemberRoleField = ({ isDisabled, isLocked, lockedTooltipContent, name, selectOptions, staticValue }) => (
  <Field
    className={field.xl.full}
    component={SelectFieldV2}
    components={{
      Option: selectComponents.MultiLineOption,
    }}
    isDisabled={isDisabled}
    isLocked={isLocked}
    isRequired
    label="Role"
    lockedTooltipProps={lockedTooltipContent}
    name={name}
    options={selectOptions}
    validate={requiredValidator}
    value={staticValue}
  />
);

TeamMemberRoleField.propTypes = {
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  lockedTooltipContent: PropTypes.shape(),
  name: PropTypes.string.isRequired,
  selectOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  staticValue: PropTypes.string,
};

TeamMemberRoleField.defaultProps = {
  isDisabled: undefined,
  isLocked: undefined,
  lockedTooltipContent: undefined,
  staticValue: undefined,
};

export default TeamMemberRoleField;

import * as types from 'types/externalOnboarding';

export const externalOnboardingChangeStep = (step) => ({
  type: types.EXTERNAL_ONBOARDING_CHANGE_STEP,
  payload: { step },
});

export const externalOnboardingIncrementStep = () => ({
  type: types.EXTERNAL_ONBOARDING_INCREMENT_STEP,
});

export const externalOnboardingDecrementStep = () => ({
  type: types.EXTERNAL_ONBOARDING_DECREMENT_STEP,
});

import { createSelector } from 'reselect';

import { companySelector } from './companiesSelectors';

/**
 * Top level brands selector
 * @param state
 * @return {obj}
 */
export const getBrandsSelector = (state) => state.brands;

/**
 * Selects the brands by Id from the state
 * @param {object} state - Redux state
 */
export const brandsSelector = createSelector([getBrandsSelector], (brands) => brands.byId);

/**
 * Selects the brand object for a company by Id
 * @param {object} state - Redux state
 * @param {string} companyId - Company id
 */
export const companyBrandSelector = createSelector(
  [brandsSelector, companySelector],
  (brands, company) => brands[company.brand],
);

/**
 * Selects the brand logo for a company by Id
 * @param {object} state - Redux state
 * @param {string} companyId - Company id
 */
export const companyBrandLogoSelector = createSelector([companyBrandSelector], (brand) => brand.logo);

/**
 * Selects the brand primaryColor for a company by Id
 * @param {object} state - Redux state
 * @param {string} companyId - Company id
 */
export const companyBrandPrimaryColorSelector = createSelector([companyBrandSelector], (brand) => brand.primaryColor);

import { hasLength } from 'helpers/utility';

/**
 * Method to get the CompanyRep from either the requester sent by the backend, or grabbing the first
 * partnershipPartnerMember
 * @param {PartnerMember} requester
 * @param {Array} partnershipPartnerMembers
 * @returns {ObjectMaybe}
 */
export const getCompanyRepFromRequesterOrFirstPartnershipMember = (requester, partnershipPartnerMembers) => {
  if (requester) {
    return requester;
  }

  if (hasLength(partnershipPartnerMembers)) {
    return partnershipPartnerMembers[0];
  }

  return undefined;
};

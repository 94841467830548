import { createSelector } from 'reselect';

import { getState } from 'selectors/fundingSelectors';

/**
 * Selects the state.funding.fundingSupportedCountries object
 * @function
 * @param {ReduxState} state
 * @return {Object}
 */
export const fundingSupportedCountriesSelector = createSelector(
  [getState],
  (fundingState) => fundingState.fundingSupportedCountries,
);

/**
 * Selects the country codes of all the supported countries from state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {CountryCode[]}
 */
export const allSupportedCountryCodesSelector = createSelector(
  [fundingSupportedCountriesSelector],
  (fundingSupportedCountries) => fundingSupportedCountries.allCountryCodes,
);

/**
 * Selects supported countries by country code from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {CountryCodeMap}
 */
export const supportedCountriesByCountryCodeSelector = createSelector(
  [fundingSupportedCountriesSelector],
  (fundingSupportedCountries) => fundingSupportedCountries.byCountryCode,
);

/**
 * Selects the supported country errors from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
export const supportedCountriesErrorsSelector = createSelector(
  [fundingSupportedCountriesSelector],
  (fundingSupportedCountries) => fundingSupportedCountries.errors,
);

/**
 * Selects the isFetching boolean for supported countries from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {boolean}
 */
export const isFetchingSupportedCountriesSelector = createSelector(
  [fundingSupportedCountriesSelector],
  (fundingSupportedCountries) => fundingSupportedCountries.isFetching,
);

/**
 * Selects the lastFetched data for supported countries from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {DateMaybe}
 */
export const supportedCountriesLastFetchedSelector = createSelector(
  [fundingSupportedCountriesSelector],
  (fundingSupportedCountries) => fundingSupportedCountries.lastFetched,
);

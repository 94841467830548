import { combineReducers } from 'redux';

import allAnnotationsReducer from './allAnnotationsReducer';
import annotationsByIdReducer from './annotationsByIdReducer';
import annotationStatusReducer from './annotationStatusReducer';

const annotationsReducer = combineReducers({
  allIds: allAnnotationsReducer,
  byId: annotationsByIdReducer,
  annotationStatus: annotationStatusReducer,
});

export default annotationsReducer;

import { createPaginationReducerInitialState } from 'store/redux';

export default {
  allIds: [],
  byId: {},
  errors: null,
  isFetching: false,
  isUploading: false,
  links: {},
  pagination: createPaginationReducerInitialState(),
  watchingIds: [],
};

import PropTypes from 'prop-types';

import { TABLE_DEFAULT_PAGE_SIZE } from 'constants/tables';

import { noop } from 'helpers/utility';

export const tablePropTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  compact: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dataTestId: PropTypes.string,
  getCellProps: PropTypes.func,
  getPaginationDetails: PropTypes.func,
  getRowProps: PropTypes.func,
  hasPagination: PropTypes.bool,
  pageSize: PropTypes.number,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  activeTab: PropTypes.string,
};

export const tableDefaultProps = {
  children: undefined,
  className: undefined,
  compact: undefined,
  dataTestId: undefined,
  getCellProps: noop,
  getPaginationDetails: undefined,
  getRowProps: noop,
  hasPagination: undefined,
  pageSize: TABLE_DEFAULT_PAGE_SIZE,
  hiddenColumns: [],
};

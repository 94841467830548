import PropTypes from 'prop-types';
import React from 'react';

import { ItemKinds } from 'constants/item';
import { Intent } from 'constants/ui';

import { isKindReceivable } from 'helpers/items';

import BaseHint from '../BaseHint';
import { getExternalCurrencyTypeAnnouncementHintTextTitle } from '../helpers/text';

/**
 * Hint used by external accept item
 *
 * @param {ComponentProps} props
 * @param {Company.name} props.companyName
 * @param {CurrencyCode} props.currencyCodeCompany
 * @param {CurrencyCodeMap} props.currencyCodeMap
 * @param {ItemKinds} props.itemKind
 * @param {CurrencyCode} props.itemCurrencyCodeReceiver
 * @param {RestOfProps} props.rest
 * @returns {StatelessComponent}
 */
const ExternalCurrencyTypeAnnouncementHint = ({
  companyName,
  currencyCodeCompany,
  currencyCodeMap,
  itemKind,
  itemCurrencyCodeReceiver,
  isItemLoading,
  ...rest
}) => {
  const { name: currencyLongName } = currencyCodeMap[currencyCodeCompany];
  const textHelpers = getExternalCurrencyTypeAnnouncementHintTextTitle({
    companyName,
    currencyCode: currencyCodeCompany,
    currencyLongName,
    itemKind,
  });

  /**
   * If is receivable for external partner (was payable for RCTM),
   * and item.currencyCodeReceiver is different from current company's
   * currency code, then do not show this hint.
   * This hint displays "you have been set to receive [local currency]",
   * being local currency the vendor's one in this case. If the item was set to be received
   * in a different currency, like USD, this hint is missleading.
   */
  const isReceivableNonLocalCurrency =
    isKindReceivable(itemKind) && itemCurrencyCodeReceiver && itemCurrencyCodeReceiver !== currencyCodeCompany;

  /**
   * Also hide if item is loading.
   */
  const shouldHide = isReceivableNonLocalCurrency || isItemLoading;

  if (shouldHide) {
    return null;
  }

  return <BaseHint intent={Intent.INFO} text={textHelpers.text} title={textHelpers.title} {...rest} />;
};

ExternalCurrencyTypeAnnouncementHint.propTypes = {
  className: PropTypes.string,
  companyName: PropTypes.string.isRequired,
  currencyCodeCompany: PropTypes.string.isRequired,
  currencyCodeMap: PropTypes.shape().isRequired,
  itemKind: PropTypes.string,
};

ExternalCurrencyTypeAnnouncementHint.defaultProps = {
  className: undefined,
  itemKind: ItemKinds.PAYABLE,
};

export default ExternalCurrencyTypeAnnouncementHint;

import React from 'react';
import { CountryFlag } from 'components';
import { getCountryNameFromCode } from 'helpers/countries';
import { useCountryCodesContext } from 'hooks';
import TableCellText from '../TableCellText';
import {} from './CountryCell.types';
import './CountryCell.scss';
const CountryCell = ({ rowData }) => {
    const countryCodeMap = useCountryCodesContext();
    return (React.createElement("div", { className: "display--flex align-items--center width--100-percent" },
        React.createElement(CountryFlag, { className: "country-cell--flag", countryCode: rowData.country }),
        React.createElement(TableCellText, { className: "truncate width--100-percent", dataTestId: "country-cell--name" }, getCountryNameFromCode(countryCodeMap, rowData.country))));
};
export default CountryCell;

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import decompressConfig from './deepObjectDifferenceConfigDecompress';

/**
 * Difference
 * Creates a new Difference object used to compare
 * two different objects by initial and changed.
 * @class
 */
const Difference = class {
  /**
   * isArray
   * Checks to see if an Object, String or Value is an Array
   * @param {any} val - Object, String, Value to check against.
   * @returns {boolean}
   */
  isArray = (val) => Object.prototype.toString.call(val) === '[object Array]';

  /**
   * isObject
   * Checks to see value is an Object
   * @param {any} val - Object, String, Value to check against.
   * @returns {boolean}
   */
  isObject = (val) => Object.prototype.toString.call(val) === '[object Object]';

  /**
   * getRelation
   * Scans configuration to find what the relationship
   * for a particluar property is.
   * @param {string} key - The property string to test the relationship against.
   * @param {array} relations - The relationships section of the config object.
   * @returns {object}
   */
  getRelation = (key, relations = []) => relations.find((rel) => RegExp(`^${rel.name}$`).test(key));

  /**
   * getIdKey
   * Returns the property string from and object;
   * @param {string} obj - The object to test against.
   * @param {string} relationIDKey - The key to find.
   * @returns {string}
   */
  getIdKey = (obj, relationIDKey) => {
    if (this.isArray(relationIDKey)) {
      return relationIDKey.find((relIdKey) => _get(obj, relIdKey));
    }
    return relationIDKey;
  };

  /**
   * getID
   * Returns the property by key
   * @param {string} obj - The object to test against.
   * @param {array} relationIDKey - The key to find to check against.
   * @returns {object}
   */
  getID = (obj, relationIDKey) => _get(obj, this.getIdKey(obj, relationIDKey));

  /**
   * isKeyId
   * Checks to see if the property is an id field.
   * @param {string} key - The property string to check.
   * @param {string} configIdKey - The "ID" field of the config object to check against.
   * @returns {boolean}
   */
  isKeyId = (key, configIdKey) => {
    if (this.isArray(configIdKey)) {
      return configIdKey.includes(key);
    }
    return key === configIdKey;
  };

  /**
   * resolveRemovedFieldDefaults
   * Checks to see if the initial values object is an array and
   * if it is a relationship key if so then it will return an empty array
   * otherwise it will return null.
   *
   * This is used to set the result property to nothing so it can be
   * removed later in the tree. Example resultObject.approvers will equal [] in the result.
   * @param {string} key - The property string to check.
   * @param {object} initialValues - The initial values that the diff object is checking against.
   * @param {boolean} isRelationKey - Is this a relationship, checked against the configurations object.
   * @returns {array | null}
   */
  resolveRemovedFieldDefaults = (key, initialValues, isRelationKey) => {
    if (isRelationKey && this.isArray(initialValues[key])) {
      return [];
    }
    return null;
  };

  /**
   * calculateDifference
   * The main entry point of the class
   * @param {object} initial - The initial object that you would like to compare against.
   * @param {object} values - The new object (or changes) that you would like to compare against initial.
   * @param {object} config - The configuration object used for IDs, and Relationships
   * @param {Boolean} isCompressedConfig - tells if the configuration object needs to be decompressed
   * @returns {object} - This will be the final object after it has been processed and compared.
   */
  calculateDifference = (initial, values, config = {}, isCompressedConfig = true) => {
    // Create a configuration object by decompressing multiple config objects
    // into our [{name<string>, id<string>, relations<array>}] format.
    const decompConfig = isCompressedConfig ? decompressConfig(config) : config;

    // Start the compare and return the diff'd object back to the caller.
    return this.calculateDifferenceRecursive(initial, values, decompConfig);
  };

  /**
   * calculateDifferenceRecursive
   * Internal recursive method used to compare initial and values objects
   * based on our custom configuration object.
   * @param {object} initial - The initial object that you would like to compare against.
   * @param {object} values - The new object (or changes) that you would like to compare against initial.
   * @param {object} config - The configuration object used for IDs, and Relationships
   * @param {object} accumulator - Internal build object passed inside of the recursive loop.
   * @returns {object} - This will be the final object after it has been processed and compared.
   */
  calculateDifferenceRecursive = (initial = {}, values = {}, config = {}, accumulator = {}) => {
    // NOTE: accumulator is the "return object" so references to "return object" in these
    // notes are referring to the accumulator recurssive object.

    // Create a reference to the base keys in the inital object.
    let removedInitialKeys = Object.keys(initial);
    let relationNames = [];

    // If the config object has relations, store the relationship names.
    // This will be used later in the tree to set (or remove) properties that
    // will not be used in the final diff object.
    if (config.relations) {
      relationNames = config.relations.map((relation) => relation.name);
    }

    // Run through each property in the values object
    // Note: the "values" object will change as we get deeper nested
    // so the first time through it is the top level, then if object the next
    // time through the method call it will be the next object in the tree
    // not the top level.
    Object.keys(values).forEach((key) => {
      // Find the keys that do not match.
      removedInitialKeys = removedInitialKeys.filter((initialKey) => initialKey !== key);

      // valIsEqual method: Use lodash to compare basic values against each other.
      const valIsEqual = _isEqual(initial[key], values[key]);

      // Check to see if this key has a relation in our config object.
      const relation = this.getRelation(key, config.relations);

      // If this is a relationship object then we need to
      // compare the two objects (initial/values) and if the
      // relationship is not equal then we will add it to the
      // return object otherwise we will scan deeper into the object.
      if (!valIsEqual && relation && this.isObject(values[key])) {
        if (!relation.id) {
          // Object is not a relationship (ie: it is simple property) so just add it.
          accumulator[key] = this.calculateDifferenceRecursive(initial[key], values[key], relation);
        } else if (!this.getID(values[key], relation.id)) {
          // If the object is not a relationship set the value to null or if array then empty array;
          // Nulls or empty arrays will be removed in the final clean before the method returns.
          accumulator[key] = this.resolveRemovedFieldDefaults(key, initial, Boolean(relation));
        } else {
          // Object is has a relationship so we need to find the
          // property form the initial and the values objects so we can
          // check to see if they are equal or not and add them to the
          // return object.
          const initialID = this.getID(initial[key], relation.id);
          const valuesID = this.getID(values[key], relation.id);

          // If the values do not match then just add the entire object
          // as it is inside of an relationship.
          if (initialID && valuesID && initialID !== valuesID) {
            accumulator[key] = values[key];
          } else {
            // Otherwise we need to run through the object and figure out what has
            // changed so call the method again with the checked object.
            accumulator[key] = this.calculateDifferenceRecursive(initial[key], values[key], relation);
          }
        }

        // If the object is an array we need loop through each item
        // in the array and do a call to this method to compare the objects.
      } else if (!valIsEqual && relation && this.isArray(values[key])) {
        // Set the base return object property to an empty array so we can push to it.
        accumulator[key] = [];

        // Loop through the array
        values[key].forEach((item) => {
          if (!this.getID(item, relation.id)) {
            // Object is not a relationship (ie: it is simple property) so just add it.
            accumulator[key].push(item);
          } else {
            // Get the item from the intial object
            const itemFromInitial = (initial[key] || []).find(
              (initItem) => this.getID(initItem, relation.id) === this.getID(item, relation.id),
            );

            // If the item exists in the inital object then we need to call this method
            // again which will scan through each object in the array and push it to the return object.
            if (itemFromInitial) {
              accumulator[key].push(this.calculateDifferenceRecursive(itemFromInitial, item, relation));
            } else {
              // Item does not exist in the inital object so we just need to add it because
              // it is a new item.
              accumulator[key].push(item);
            }
          }

          // This does a basic sort of the array so items from inital are in the
          // same order as items from the values.

          // TODO: We need to build a deep nested sort algo to handle children of children.
          // at the moment this works fine for our structure but as that changes we will
          // need to handle this.
          accumulator[key].sort(
            (oldItem, newItem) => this.getID(oldItem, relation.id) - this.getID(newItem, relation.id),
          );
        });

        // If the property we just came across is just a value || an ID
        // we will either set it to null or assign the values to the return object.
      } else if (!valIsEqual || this.isKeyId(key, config.id)) {
        if (values[key] === undefined) {
          accumulator[key] = null;
        } else {
          accumulator[key] = values[key];
        }
      }
    });

    // Loop through each item in the removed keys filter and set values to null || [];
    removedInitialKeys.forEach((key) => {
      const isRelationKey = relationNames.some((name) => RegExp(`^${name}$`).test(key));
      accumulator[key] = this.resolveRemovedFieldDefaults(key, initial, isRelationKey);
    });

    // Return the final object.
    return accumulator;
  };
};

export default Difference;

import { FETCH_BILLING_CODES_SUCCESS } from 'types/billing';

import { initialState } from './initialState';

const dashboardLoadedBillingReducer = (state = initialState.billing, action) => {
  switch (action.type) {
    case FETCH_BILLING_CODES_SUCCESS:
      return true;

    default:
      return state;
  }
};

export default dashboardLoadedBillingReducer;

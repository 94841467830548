import PropTypes from 'prop-types';
import React from 'react';

import { PartnershipTypes } from 'constants/partnership';

import PartnerPaymentMethodStatus from 'modules/fundingAccount/partnerPaymentMethodStatus';

import PartnerPaymentMethodAccounts from './PartnerPaymentMethodAccounts';

const PartnerPaymentMethodAccountsModalContent = (props) => {
  const { children, hasAccounts, isFetching, partnership, paymentDeliveryMethod } = props;

  return (
    <React.Fragment>
      <PartnerPaymentMethodStatus
        isEnabled={hasAccounts}
        partnership={partnership}
        partnershipType={PartnershipTypes.VENDOR}
        paymentDeliveryMethod={paymentDeliveryMethod}
      />

      <p className="font-color--dark-jordan font-regular remove-margin-top margin-bottom--large">
        Please select from any of the valid methods added for your partner.
      </p>

      <PartnerPaymentMethodAccounts hasAccounts={hasAccounts} isFetching={isFetching}>
        {children}
      </PartnerPaymentMethodAccounts>
    </React.Fragment>
  );
};

PartnerPaymentMethodAccountsModalContent.propTypes = {
  children: PropTypes.node,
  hasAccounts: PropTypes.bool,
  isFetching: PropTypes.bool,
  partnership: PropTypes.shape().isRequired,
  paymentDeliveryMethod: PropTypes.string.isRequired,
};

PartnerPaymentMethodAccountsModalContent.defaultProps = {
  children: undefined,
  hasAccounts: undefined,
  isFetching: undefined,
};

export default PartnerPaymentMethodAccountsModalContent;

import { call, put, spawn, take } from 'redux-saga/effects';

import { handleRequestErrors } from 'actions/errors';
import * as actions from 'actions/transactions';

import { parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { getPaginationFromParsedResponse } from 'helpers/pagination';

import { payloadToUnderscore } from 'services/api/formatHelpers';

import * as types from 'types/transactions';

import * as api from './api';

/**
 * Request transactions data.
 * @return {IterableIterator<*>}
 */
export function* fetchTransactions(action) {
  let errorData = {};

  try {
    const {
      meta,
      payload: { queryParams },
    } = action;

    const urlQueries = payloadToUnderscore(queryParams || {});
    const response = yield call(api.fetchTransactions, urlQueries);

    if (response.ok) {
      const nextMeta = {
        ...meta,
        pagination: getPaginationFromParsedResponse(response.data, meta),
      };

      yield put(actions.fetchTransactionsSuccess(response.data, nextMeta));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchTransactionsFailure, errorData));
}

/**
 * Request transactions data.
 * @return {IterableIterator<*>}
 */
export function* fetchBalanceTransactions(action) {
  const { meta, payload } = action;
  const { updateOffset = true } = meta;
  const { queryParams = {} } = payload;

  const mergedQueryParams = {
    ...queryParams,
    affectsBalance: true, // don't allow overriding
  };

  const mergedPayload = {
    ...payload,
    queryParams: mergedQueryParams,
  };

  const mergedMeta = {
    updateOffset, // overriding allowed
    ...meta,
  };

  yield put(actions.fetchTransactionsRequest(mergedPayload, mergedMeta));
}

/**
 * Request transactions data for a given item.
 * @return {IterableIterator<*>}
 */
export function* fetchItemTransactions(action) {
  let errorData = {};

  try {
    const {
      payload: { itemId },
    } = action;

    const response = yield call(api.fetchItemTransactions, itemId);

    if (response.ok) {
      yield put(
        actions.fetchItemTransactionsSuccess({
          ...response.data,
          itemId,
        }),
      );
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchItemTransactionsFailure, errorData));
}

/**
 * Listens for redux actions related to funding accounts, sources, and other data.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([
      types.FETCH_BALANCE_TRANSACTIONS_REQUEST,
      types.FETCH_ITEM_TRANSACTIONS_REQUEST,
      types.FETCH_TRANSACTIONS_REQUEST,
    ]);

    switch (action.type) {
      case types.FETCH_BALANCE_TRANSACTIONS_REQUEST:
        yield spawn(fetchBalanceTransactions, action);
        break;

      case types.FETCH_ITEM_TRANSACTIONS_REQUEST:
        yield spawn(fetchItemTransactions, action);
        break;

      case types.FETCH_TRANSACTIONS_REQUEST:
        yield spawn(fetchTransactions, action);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root transactions saga.
 * @return {IterableIterator<*>}
 */
export default function* transactions() {
  yield watch();
}

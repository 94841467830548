import classNames from 'classnames';
import _get from 'lodash/get';

import { PartnershipMemberStatus } from 'constants/contacts';

import { any } from 'helpers/utility';

/**
 * Curried Handler to dispatch onArchive or onUnarchive routines.
 * @function
 * @see CompanyAboutContactsTable
 * @param {ReduxSagaRoutine|ReduxAction} dispatcher - some routine/action which is bound to dispatch
 * @param {boolean} isArchived - Whether this function sets isArchived true or false
 * @param {string} partnershipId
 * @returns {function}
 */
export const createArchiveHandler =
  ({ dispatcher, isArchived, partnershipId }) =>
  (partnershipMemberId) =>
    dispatcher({
      values: {
        isArchived,
        meta: {
          partnershipId,
          partnershipMemberId,
        },
      },
    });

/**
 * If a contact's email is present when creating a TitleWithSubtitle title, wrap it in (parenthesis).
 * @function
 * @param {StringMaybe} [email]
 * @returns {string|undefined}
 */
export const formatEmailForDetailsTitle = (email) => {
  const emailExists = Boolean(email) && typeof email === 'string';
  return emailExists ? `(${email})` : undefined;
};

// =======================
// Status comparisons
// =======================

/**
 * Returns true if status is ACTIVE
 * @param {string} status
 * @return {boolean}
 */
export const isPartnershipMemberStatusActive = (status) => status === PartnershipMemberStatus.ACTIVE;

/**
 * Returns true if status is ARCHIVED
 * @param {string} status
 * @return {boolean}
 */
export const isPartnershipMemberStatusArchived = (status) => status === PartnershipMemberStatus.ARCHIVED;

/**
 * Returns true if status is ISSUE
 * @param {string} status
 * @return {boolean}
 */
export const isPartnershipMemberStatusIssue = (status) => status === PartnershipMemberStatus.ISSUE;

/**
 * A contact can only have default contact settings if active or has an issue.
 * @function
 * @param {PartnershipMemberStatus} status
 * @returns {boolean}
 */
export const statusCanHaveDefaultContactSettings = (status) =>
  isPartnershipMemberStatusActive(status) || isPartnershipMemberStatusIssue(status);

/**
 * Row class names to apply archived, error statues for special styling to contact rows.
 * @param {PartnershipMember} contact
 * @returns {string} className
 */
export const getRowClassNameForCompanyContactsTable = (contact) => {
  const status = _get(contact, 'status');

  return classNames({
    archived: isPartnershipMemberStatusArchived(status),
    'disable-hover': true,
    error: isPartnershipMemberStatusIssue(status),
  });
};

/**
 * Returns whether the contacts table is loading for the first time.
 * @function
 * @param {Object} options
 * @param {boolean} options.isFetching
 * @param {boolean} options.lastFetched
 * @returns {boolean}
 */
export const isContactsTableLoadingForFirstTime = ({ isFetching, lastFetched }) => isFetching && !lastFetched;

/**
 * Returns whether the contacts table's pagination is loading.
 * @function
 * @param {Object} options
 * @param {boolean} options.isFetching
 * @param {boolean} options.lastFetched
 * @returns {boolean}
 */
export const isContactsTablePaginationLoading = ({ isFetching, lastFetched }) => isFetching && Boolean(lastFetched);

/**
 * When we're creating a partnership with a company that exists on the ledger, but not in Routable,
 * the company may or may not have contacts already added into the ledger. If it does, we'll get data
 * back from the API. If it doesn't, we'll still get data back, but the only value in the contacts
 * array will be a LedgerContact object with all empty data, including an empty first name, empty last name,
 * and empty email.
 * These aren't real contacts, and if all of those data points are missing on any contact, we won't
 * treat it as a real contact or display its data.
 * @function
 * @param {LedgerContact} contact
 * @return {Boolean}
 */
export const isContactImportedFromLedgerReal = (contact = {}) =>
  any([contact.firstName, contact.lastName, contact.email]);

/**
 * getFilteredPartnershipContactsOnArchivedStatus
 * Returns an array of filtered contacts based on whether or not the contact is archived.
 * @param {Object} options
 * @param {PartnershipMember[]} options.contacts
 * @param {boolean} options.showArchivedContacts
 * @return {PartnershipMember[]}
 */
export const getFilteredPartnershipContactsOnArchivedStatus = ({ contacts, showArchivedContacts }) => {
  if (!showArchivedContacts) {
    // only return those that are not archived (ie. active, etc)
    return contacts.filter((contact) => !isPartnershipMemberStatusArchived(contact.status));
  }

  // return contacts including any archived contacts
  return contacts;
};

import { IconNames } from 'components';
import { getFormattedDetailsOfFundingInfoAddressWithAddress, getFundingAccountMask, getFundingInfoAddressDisplayName, isFundingAccountBalance, isFundingAccountBankAccount, isFundingAccountDeleted, isFundingAccountDisabled, isFundingAccountValid, isFundingSourceInvalid, isFundingSourceInvalidMicroDepositsFailed, isFundingSourceInvalidMicroDepositsFailedVerification, isFundingSourceInvalidMicroDepositsPending, isFundingSourceInvalidMicroDepositsProcessed, isFundingSourceValid, } from 'helpers/funding';
import { PaymentMethodStatusType } from './enums';
export const getPaymentMethodHeaderCheck = (fundingInfoAddress = {}) => {
    const title = getFundingInfoAddressDisplayName(fundingInfoAddress);
    const subtitle = getFormattedDetailsOfFundingInfoAddressWithAddress(fundingInfoAddress);
    return { title, subtitle };
};
export const getPaymentMethodHeaderBank = (fundingAccount, fundingInfoBankAccount) => {
    const { accountNumber = '' } = Object(fundingInfoBankAccount);
    const subtitle = getFundingAccountMask(accountNumber);
    const { name: title = '' } = Object(fundingAccount);
    return { title, subtitle };
};
export const getPaymentMethodHeaderBalance = (fundingAccount) => {
    const { name: title = '' } = Object(fundingAccount);
    return { title };
};
export const getPaymentMethodHeader = ({ fundingAccount, fundingInfoAddress, fundingInfoBankAccount, }) => {
    if (isFundingAccountBalance(fundingAccount)) {
        return getPaymentMethodHeaderBalance(fundingAccount);
    }
    if (isFundingAccountBankAccount(fundingAccount)) {
        return getPaymentMethodHeaderBank(fundingAccount, fundingInfoBankAccount);
    }
    return getPaymentMethodHeaderCheck(fundingInfoAddress);
};
export const getPaymentMethodStatus = ({ fundingAccount, fundingSources = [], isPartnerAccountInDashboard, partnershipFundingAccount, }) => {
    if (partnershipFundingAccount?.isPrimary) {
        return PaymentMethodStatusType.PRIMARY;
    }
    if (fundingSources.some(isFundingSourceInvalidMicroDepositsPending)) {
        return PaymentMethodStatusType.PENDING_MICRO_DEPOSITS;
    }
    if (fundingSources.some(isFundingSourceInvalidMicroDepositsProcessed)) {
        return PaymentMethodStatusType.PENDING_VERIFICATION;
    }
    if (fundingSources.some((source) => isFundingSourceInvalid(source) ||
        isFundingSourceInvalidMicroDepositsFailed(source) ||
        isFundingSourceInvalidMicroDepositsFailedVerification(source))) {
        return PaymentMethodStatusType.ERROR;
    }
    if (isFundingAccountDisabled(fundingAccount) || isFundingAccountDeleted(fundingAccount)) {
        return PaymentMethodStatusType.DISABLED;
    }
    if (isPartnerAccountInDashboard ||
        isFundingAccountValid(fundingAccount) ||
        fundingSources.some(isFundingSourceValid)) {
        return PaymentMethodStatusType.ACTIVE;
    }
    return undefined;
};
export const getPaymentMethodIcon = (fundingAccount) => {
    if (isFundingAccountBalance(fundingAccount)) {
        return { icon: IconNames.BANK_ACCOUNT };
    }
    return isFundingAccountBankAccount(fundingAccount)
        ? { isCustomIcon: true, icon: 'icon-ic-bank' }
        : { isCustomIcon: true, icon: 'icon-ic-mail' };
};

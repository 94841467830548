import PropTypes from 'prop-types';
import React from 'react';

import {
  DeactivateTeamMemberApproverWarningHint,
  IconNames,
  ModalBodyContent,
  ModalFooter,
  ModalFooterButton,
  ModalFooterButtonCancel,
  Text,
} from 'components';

import { typography, sizes } from 'constants/styles';
import { deactivateTeamMemberModalSubtitle, Intent } from 'constants/ui';

import { getDeactivateTeamMemberModalConfirmButtonText } from 'helpers/ui';

/**
 * Body contents for the DeactivateTeamMemberModal.
 * @param {ComponentProps} props
 * @param {StringMaybe} [props.errorText]
 * @param {boolean} [props.isSubmitting]
 * @param {function} props.onClose
 * @param {function} props.onHintClick
 * @param {function} props.onSubmit
 * @returns {StatelessComponent}
 */
const DeactivateTeamMemberModalBody = ({ errorText, isSubmitting, onClose, onHintClick, onSubmit }) => (
  <React.Fragment>
    <ModalBodyContent className="modal-body--content--white">
      <Text.Regular size={typography.TextSize.LEVEL_200} weight={typography.TextWeight.LEVEL_400}>
        {deactivateTeamMemberModalSubtitle}
      </Text.Regular>

      {errorText && (
        <DeactivateTeamMemberApproverWarningHint
          className="margin-top--m-large"
          onClick={onHintClick}
          serverErrorText={errorText}
        />
      )}
    </ModalBodyContent>

    <ModalFooter>
      <div className="content--left">
        <ModalFooterButtonCancel onCloseModal={onClose} />
      </div>

      <div className="content--right">
        <ModalFooterButton
          id="confirmDeactivateTeamMember_btn"
          intent={Intent.DANGER}
          isActionButton
          isLoading={isSubmitting}
          leftIconName={IconNames.REMOVE}
          leftIconSize={sizes.iconSizes.EXTRA_MEDIUM}
          onClick={onSubmit}
        >
          {getDeactivateTeamMemberModalConfirmButtonText(errorText)}
        </ModalFooterButton>
      </div>
    </ModalFooter>
  </React.Fragment>
);

DeactivateTeamMemberModalBody.propTypes = {
  errorText: PropTypes.string,
  isSubmitting: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onHintClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

DeactivateTeamMemberModalBody.defaultProps = {
  errorText: undefined,
  isSubmitting: undefined,
};

export default DeactivateTeamMemberModalBody;

import { createSelector } from 'reselect';

import { TinTypes } from 'constants/taxes';

import { anyValues } from 'helpers/utility';

import {
  currentCompanyInfoSelector,
  currentCompanyMailingAddressSelector,
  currentCompanySelector,
} from 'selectors/currentCompanySelectors';

import { partnershipCountryCodeFromQuerySelector } from './partnershipCompoundSelectors';

/**
 * Selects the initial values used by the PartnershipCompanyGeneralForm form
 * @type {StandardSelector}
 * @returns {Object} - initialValues
 */
export const partnerCompanyGeneralFormInitialValuesSelector = createSelector(
  [
    currentCompanySelector,
    currentCompanyInfoSelector,
    currentCompanyMailingAddressSelector,
    partnershipCountryCodeFromQuerySelector,
  ],
  (currentCompany, currentCompanyInfo, currentCompanyMailingAddress, countryCode) => {
    const mailingAddress = { ...currentCompanyMailingAddress };

    const info = {
      businessType: currentCompanyInfo?.businessType,
      tin: currentCompanyInfo?.tin,
    };

    if (countryCode) {
      mailingAddress.country = countryCode;
    }

    return {
      company: {
        companyType: currentCompany.companyType || '',
        mailingAddress,
        name: currentCompany.name || '',
        ...(anyValues(info) && { info }),
      },
      formUI: {
        displayInputForTin: true,
        businessFormShouldShowSSN: currentCompanyInfo?.tinType === TinTypes.SSN,
      },
    };
  },
);

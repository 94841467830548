import { createSelector } from 'reselect';

import { allValues } from 'helpers/utility';

import { currentCompanySelector } from 'selectors/currentCompanySelectors';
import { propsSelector } from 'selectors/globalSelectors';

const getState = (state) => state.partnershipMembers;

/**
 * Selects the partnership members by id from the state
 * @function
 * @param {ReduxState} state
 * @returns {object}
 */
export const partnershipMembersByIdSelector = createSelector(
  [getState],
  (partnershipMembers) => partnershipMembers.byId,
);

/**
 * Selects the all partnership members data
 * @param {ReduxState} state
 * @returns {PartnershipMember[]}
 */
export const allPartnershipMembersSelector = createSelector([partnershipMembersByIdSelector], (partnershipMembers) =>
  allValues(partnershipMembers),
);

/**
 * Selects the partnershipMembers for each given ID
 * @function
 * @param {ReduxState} state
 * @param {string[]} partnershipIds - Partnership id
 */
export const partnershipMembersForIdsSelector = createSelector(
  [allPartnershipMembersSelector, propsSelector],
  (partnershipMembers, partnershipMembersIds) =>
    (partnershipMembersIds || []).map((id) => partnershipMembers.find((member) => member.id === id)),
);

/**
 * Given an array of partnershipMemberIds, this selector selects the partnership members that are not tied to the current company
 * This is useful when trying to find the partnership members tied to a matched company - for eg in MergeLedgerPartnershipModalBodyConfirmed
 * @function
 * @param {ReduxState} state
 * @param {string[]} partnershipIds - Partnership id
 * @return {PartnershipMember[]}
 */
export const partnershipMembersForMatchedCompanySelector = createSelector(
  [partnershipMembersForIdsSelector, currentCompanySelector],
  (partnershipMembers, currentCompany) => partnershipMembers?.filter((member) => member?.company !== currentCompany.id),
);

/**
 * Selects if partnership members are fetching.
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const isFetchingPartnershipMembersSelector = createSelector(
  [getState],
  (partnershipMembers) => partnershipMembers.isFetching,
);

/**
 * Selects errors related to partnership members.
 * @param {ReduxState} state
 * @returns {ObjectMaybe}
 */
export const partnershipMembersErrorSelector = createSelector(
  [getState],
  (partnershipMembers) => partnershipMembers.errors,
);

/**
 * Selects lastFetched related to partnership members.
 * @param {ReduxState} state
 * @returns {Date|null}
 */
export const partnershipMemberLastFetchedSelector = createSelector(
  [getState],
  (partnershipMembers) => partnershipMembers.lastFetched,
);

/**
 * Selects pagination related to partnership members.
 * @param {ReduxState} state
 * @returns {Object}
 */
export const partnershipMemberPaginationSelector = createSelector(
  [getState],
  (partnershipMembers) => partnershipMembers.pagination,
);

/**
 * Gets a single PartnershipMember from state.
 * @param {ReduxState} state
 * @param {string} partnershipMemberId
 * @returns {PartnershipMember}
 */
export const onePartnershipMemberSelector = createSelector(
  [partnershipMembersByIdSelector, propsSelector],
  (partnershipMembers, { partnershipMemberId }) => partnershipMembers[partnershipMemberId],
);

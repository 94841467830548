import React from 'react';
import { BoxV2, ButtonV2, FormControl, FormFieldLabel, IconNames, InputField, SelectFieldV2 } from 'components';
import { ButtonSize } from 'constants/button';
import { companySizeList } from 'constants/company';
import { createCompanyFields } from 'constants/formFields';
import { formNamesSignup } from 'constants/forms';
import { accountingSoftwareList } from 'constants/ledger';
import { formStyles, sizes } from 'constants/styles';
import {} from './CreateAccountCompany.types';
const CreateAccountCompany = ({ errors, formState, onIncrementStep, onInputChange, onSelectChange, }) => (React.createElement("div", { className: "branded--contents" },
    React.createElement(BoxV2, { title: "Create your account" },
        React.createElement("form", { className: "form", id: formNamesSignup.CREATE_ACCOUNT_COMPANY },
            React.createElement(FormFieldLabel, null, "Company information"),
            React.createElement(FormControl, null,
                React.createElement(InputField, { className: formStyles.field.xl.full, errors: errors, name: createCompanyFields.COMPANY_NAME, onChange: (event) => onInputChange(event, 'form.company'), placeholder: "Company name", setIsRequired: true, type: "text", value: formState.company[createCompanyFields.COMPANY_NAME] })),
            React.createElement(FormControl, null,
                React.createElement(SelectFieldV2, { errors: errors, input: {
                        name: createCompanyFields.COMPANY_SIZE,
                        onChange: (option) => onSelectChange(createCompanyFields.COMPANY_SIZE, option, 'form.company'),
                        value: formState.company[createCompanyFields.COMPANY_SIZE],
                    }, label: "Company size", options: companySizeList })),
            React.createElement(FormControl, null,
                React.createElement(SelectFieldV2, { errors: errors, input: {
                        name: createCompanyFields.COMPANY_ACCOUNTING_SOFTWARE,
                        onChange: (option) => onSelectChange(createCompanyFields.COMPANY_ACCOUNTING_SOFTWARE, option, 'form.company'),
                        value: formState.company[createCompanyFields.COMPANY_ACCOUNTING_SOFTWARE],
                    }, label: "Current accounting software", options: accountingSoftwareList, placeholder: "Select accounting software" })),
            React.createElement(FormControl, { className: "remove-margin-bottom" },
                React.createElement(ButtonV2, { onClick: (e) => {
                        e.preventDefault();
                        onIncrementStep();
                    }, rightIconName: IconNames.ARROW_RIGHT, rightIconProps: { style: { marginLeft: 'auto' } }, rightIconSize: sizes.iconSizes.LARGE, size: ButtonSize.LARGE }, "Continue"))))));
CreateAccountCompany.defaultProps = {
    errors: {},
};
export default CreateAccountCompany;

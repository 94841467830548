import React from 'react';
import Avatar from 'components/avatar';
import { FlexRow } from 'components/layout';
import { Text, WhiteSpace } from 'components/text';
import { typography } from 'constants/styles';
import { getCreatorOrCompanyNameText, getTeamRelationText } from './helpers';
const PaymentMethodDetailsCreatorField = ({ companyName = '', creator, isYourTeam, }) => {
    const creatorOrCompanyName = getCreatorOrCompanyNameText({
        companyName,
        creator,
        isYourTeam,
    });
    const teamRelation = getTeamRelationText({
        companyName,
        creator,
        isYourTeam,
    });
    return (React.createElement(FlexRow, { className: "detail-item-creator-field align-items--center", stackOnMobile: false },
        !!creator && (React.createElement(Avatar, { ...creator, sizeClass: "avatar--payment-method", styleClass: "avatar--dark margin-right--xm" })),
        React.createElement(Text.Regular, { className: "detail-item-creator-field--creator-name", color: typography.TextColor.BLACK },
            creatorOrCompanyName,
            Boolean(teamRelation) && (React.createElement(React.Fragment, null,
                React.createElement(WhiteSpace, null),
                React.createElement(Text.Regular, { className: "detail-item-creator-field--team-relation" }, `(${teamRelation})`))))));
};
export default PaymentMethodDetailsCreatorField;

import { BigNumber } from 'bignumber.js';
export const mimicNativeJSNullBehavior = (num) => (num === null ? 0 : num);
export const roundWithStyle = (value, style, precision = '2') => {
    const n = new BigNumber(value);
    const primitivePrecision = Number.parseInt(precision, 10);
    return Number.parseFloat(n.toFixed(primitivePrecision, style));
};
export const roundHalfUp = (value, precision = '2') => roundWithStyle(value, BigNumber.ROUND_HALF_UP, precision);
export const roundHalfDown = (value, precision = '2') => roundWithStyle(value, BigNumber.ROUND_HALF_DOWN, precision);
export const multiplyFloat = (...values) => {
    const operationResult = values.reduce((result, num) => {
        const multiplyNumber = mimicNativeJSNullBehavior(num);
        return result.multipliedBy(new BigNumber(multiplyNumber));
    }, new BigNumber(1));
    return Number.parseFloat(operationResult.valueOf());
};
export const divideFloat = (...values) => {
    const [firstValue, ...divideValues] = values;
    const operationResult = divideValues.reduce((result, num) => {
        const divideNumber = mimicNativeJSNullBehavior(num);
        return result.dividedBy(new BigNumber(divideNumber));
    }, new BigNumber(firstValue));
    return Number.parseFloat(operationResult.valueOf());
};
export const plusFloat = (...values) => {
    const operationResult = values.reduce((result, num) => {
        const plusNumber = mimicNativeJSNullBehavior(num);
        return result.plus(new BigNumber(plusNumber));
    }, new BigNumber(0));
    return Number.parseFloat(operationResult.valueOf());
};
export const minusFloat = (...values) => {
    const [firstValue, ...subtractValues] = values;
    const operationResult = subtractValues.reduce((result, num) => {
        const minusNumber = mimicNativeJSNullBehavior(num);
        return result.minus(new BigNumber(minusNumber));
    }, new BigNumber(firstValue));
    return Number.parseFloat(operationResult.valueOf());
};
export const FloatingPointMath = {
    divide: divideFloat,
    minus: minusFloat,
    multiply: multiplyFloat,
    plus: plusFloat,
    roundHalfDown,
    roundHalfUp,
};
export const qboTaxReduce = (value) => {
    const amount = parseFloat(value[0]);
    const compoundTaxes = value.slice(1);
    return compoundTaxes.reduce((totalTax, compoundTax) => {
        const compoundTaxPercent = parseFloat(compoundTax) / 100;
        const taxAmount = FloatingPointMath.multiply(compoundTaxPercent, amount);
        const roundedTaxAmount = FloatingPointMath.roundHalfUp(taxAmount);
        return totalTax + roundedTaxAmount;
    }, 0);
};

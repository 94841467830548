import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';

import { ACCEPT, PAY } from 'constants/routes';

import { isItemKindPayable } from 'helpers/items';
import { makeSimilarUrl } from 'helpers/urls';
import { hasLength } from 'helpers/utility';

import { PartnershipPaymentItems, PartnershipRequestItems } from './components';

const ExternalItemsList = ({ items, partnershipItems }) => {
  /**
   * Generate URL for other items
   * @param {string} itemId
   * @return {string}
   */
  const generateSimilarItemURL = (itemId) => {
    const item = items[itemId];
    const side = isItemKindPayable(item) ? PAY : ACCEPT;

    // Add
    const queryParams = queryString.parse(window.location.search);
    queryParams.item_id = itemId;

    return makeSimilarUrl(`${side}?${queryString.stringify(queryParams)}`);
  };

  if (!partnershipItems || !hasLength(partnershipItems)) {
    return null;
  }

  return (
    <React.Fragment>
      <PartnershipPaymentItems generateSimilarItemURL={generateSimilarItemURL} />
      <PartnershipRequestItems generateSimilarItemURL={generateSimilarItemURL} />
    </React.Fragment>
  );
};

ExternalItemsList.propTypes = {
  items: PropTypes.shape().isRequired,
  partnershipItems: PropTypes.arrayOf(PropTypes.shape()),
};

ExternalItemsList.defaultProps = {
  partnershipItems: undefined,
};

export default ExternalItemsList;

import { setRequirementsAreMet, unsetRequirementsAreMet, fetchPasswordStrengthCompleted } from 'actions/passwordInput';
import { availableRequirementValidators } from '../constants/availableRequirementValidators';
import { passwordRequirements } from './passwordRequirements';
export const checkAllRequirements = (dispatch, password) => {
    let allRequirements = {};
    Object.values(availableRequirementValidators).forEach((requirement) => {
        allRequirements = {
            ...allRequirements,
            [requirement]: passwordRequirements[requirement].validator(password),
        };
    });
    const allValidationsAreTrue = Object.values(allRequirements).every((value) => value === undefined);
    if (allValidationsAreTrue) {
        dispatch(setRequirementsAreMet());
        return true;
    }
    dispatch(unsetRequirementsAreMet());
    dispatch(fetchPasswordStrengthCompleted({}));
    return false;
};

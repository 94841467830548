import { getQueryParamsFromUrl } from 'helpers/queryParams';

/**
 * Returns a new pagination block derived from a combination of
 * the current block, parsed response meta, and parsed response links.
 * @param {Object} parsedResponse
 * @param {Object} parsedResponse.meta
 * @param {Object} parsedResponse.meta.pagination
 * @param {Object} parsedResponse.links
 * @param {string} parsedResponse.links.next
 * @param {Object} options
 * @param {boolean} options.updateOffset
 * @return {Object}
 */
export const getPaginationFromParsedResponse = (parsedResponse, options = {}) => {
  const { meta, links } = parsedResponse;
  const { updateOffset } = options;

  const { count, limit, offset, page, pages, pageSize } = meta.pagination;

  const nextPagination = {
    ...links,
    ...meta.pagination,
  };

  if (updateOffset) {
    nextPagination.pageSize = pageSize || limit;
    nextPagination.page = page || offset / nextPagination.pageSize + 1;

    const parsedNextLink = getQueryParamsFromUrl(links.next);

    if (parsedNextLink && parsedNextLink['page[offset]']) {
      nextPagination.offset = parseInt(parsedNextLink['page[offset]'], 10);
    }
  }

  nextPagination.pages = pages || Math.ceil(count / nextPagination.pageSize);

  return nextPagination;
};

/**
 * Returns the number of resources that should be loaded with a call
 * to the 'next' url, according to the values in the given pagination block.
 * @param {Object} pagination
 * @return {number}
 */
export const getNumberEntitiesToLoadOnNext = (pagination) => {
  const { count, page, pageSize } = pagination;
  const remaining = Math.max(count - page * pageSize, 0);
  return Math.min(remaining, pageSize);
};

import PropTypes from 'prop-types';
import React from 'react';

import { FlexRow } from 'components/layout';
import { HeadingTitle } from 'components/text';

/**
 * Header for settings table.
 * @param {ComponentProps} props
 * @param {ChildNode} props.options
 * @param {String} props.title
 * @returns {StatelessComponent}
 */
const SettingsTableHeader = ({ options, title }) => (
  <FlexRow className="align-items--center margin-bottom--m justify-content--space-between" stackOnMobile={false}>
    {title && <HeadingTitle className="font-size--m bold font-color--darkBlue">{title}</HeadingTitle>}

    {options && <React.Fragment>{options}</React.Fragment>}
  </FlexRow>
);

SettingsTableHeader.propTypes = {
  options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.string,
};

SettingsTableHeader.defaultProps = {
  options: undefined,
  title: undefined,
};

export default SettingsTableHeader;

import {
  createPartnershipMemberRoutine,
  fetchPartnershipMembersRoutine,
  fetchSinglePartnershipMemberRoutine,
  updatePartnershipMemberRoutine,
} from 'actions/routines/partnershipMember';

import { gatherReducerCasesFromRoutines } from 'helpers/routine';

import { createErrorReducer } from 'store/redux';

export const { failureCases, successCases } = gatherReducerCasesFromRoutines([
  createPartnershipMemberRoutine,
  fetchPartnershipMembersRoutine,
  fetchSinglePartnershipMemberRoutine,
  updatePartnershipMemberRoutine,
]);

export default createErrorReducer(successCases, failureCases);

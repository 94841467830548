import { SubmissionError, getFormValues, isValid, reduxForm } from 'redux-form';

import { closeConfirmTaxFilingInfoModal } from 'actions/taxes';

import { formNamesExternal } from 'constants/forms';
import { modalNameConfirmTaxFilingInfo } from 'constants/modals';

import { getQueryParam } from 'helpers/queryParams';

import { deriveTinTypeFromState } from 'queries/taxesCompoundSelectors';

import { companyMailingAddressSelector } from 'selectors/addressesSelectors';
import { currentCompanyInfoSelector, currentCompanySelector } from 'selectors/currentCompanySelectors';
import { currentUserSelector } from 'selectors/currentUserSelectors';
import { externalCollectTaxInfoFormSelector } from 'selectors/forms';
import { modalIsOpenSelector } from 'selectors/modalsSelector';

import submitTaxInfoForConfirmation from 'thunks/submitTaxInfoForConfirmation';

/**
 * Now that a user has filled out all of their filing details, pull all the pieces from state together into one object.
 * We use this method frequently to display summaries of the filing details and submit tax info updates to the BE.
 * @param {ReduxState} state
 * @returns {object} filingDetails
 */
export const getFilingDetailsFromState = (state) => {
  const { companyType, id: currentCompanyId, name: companyName } = currentCompanySelector(state);
  const { businessType } = currentCompanyInfoSelector(state);
  const { firstName, lastName } = currentUserSelector(state);

  return {
    businessType,
    companyName,
    companyType,
    firstName,
    lastName,
    mailingAddress: companyMailingAddressSelector(state, currentCompanyId) || {},
    // if we still have access to the form, we have the tinType, but if we have to derive from state, it'll be our
    // best guess based on companyType, businessType, hasSsn, and ein.
    tinType: externalCollectTaxInfoFormSelector(state, 'ui.tinType') || deriveTinTypeFromState(state),
  };
};

const taxEDeliveryConsentValidator = isValid(formNamesExternal.TAX_E_DELIVERY_CONSENT);
const eDeliveryConsentFormValues = getFormValues(formNamesExternal.TAX_E_DELIVERY_CONSENT);

export const mapStateToProps = (state) => ({
  canSubmit: taxEDeliveryConsentValidator(state),
  eDeliveryConsent: eDeliveryConsentFormValues(state),
  filingDetails: getFilingDetailsFromState(state),
  modalIsOpen: modalIsOpenSelector(state, modalNameConfirmTaxFilingInfo),
});

export const mapDispatchToProps = {
  onCloseModal: closeConfirmTaxFilingInfoModal,
  onSubmitModal: submitTaxInfoForConfirmation,
};

/**
 * The little-used, third function in Redux's connect(). mapDispatchToProps needs to bind dispatch to
 * submitTaxInfoForConfirmation, mapDispatchToProps doesn't have access to state or the component state derived from
 * mapStateToProps. This function is called after mapStateToProps and mapDispatchToProps are called, which allows us
 * to pass the payload we've derived from state into the onSubmitModal function.
 * @param {object} stateProps
 * @param {object} dispatchProps
 * @param {object} ownProps
 * @returns {object}
 */
export const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const partnershipId = getQueryParam('partnership_id');
  const { eDeliveryConsent, filingDetails } = stateProps;
  const { onSubmitModal } = dispatchProps;

  // combine the filing details and the delivery consent forms for the submit payload
  const payload = {
    ...eDeliveryConsent,
    ...filingDetails,
    // the confirm tax endpoint wants tot he mailingAddress flattened
    ...filingDetails.mailingAddress,
  };

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSubmitModal: async () => {
      const result = await onSubmitModal(partnershipId, payload);

      if (result && result.errors) {
        throw new SubmissionError(result.errors);
      }

      return null;
    },
  };
};

export const reduxFormWrapper = reduxForm({
  form: formNamesExternal.TAX_E_DELIVERY_CONSENT,
  initialValues: {
    electronicDeliveryConsent: false,
  },
});

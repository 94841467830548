import { combineReducers } from 'redux';

import allCodesReducer from './allCodesReducer';
import byCodeReducer from './byCodeReducer';
import errorReducer from './errorReducer';
import isFetchingReducer from './isFetchingReducer';

const billingReducer = combineReducers({
  allCodes: allCodesReducer,
  byCode: byCodeReducer,
  errors: errorReducer,
  isFetching: isFetchingReducer,
});

export default billingReducer;

import { createIsSubmittingReducer } from 'store/redux';

import {
  SUBMIT_SIGNUP_CREATE_ACCOUNT_FAILURE,
  SUBMIT_SIGNUP_CREATE_ACCOUNT_REQUEST,
  SUBMIT_SIGNUP_CREATE_ACCOUNT_SUCCESS,
} from 'types/signup';

export const finishCases = [SUBMIT_SIGNUP_CREATE_ACCOUNT_FAILURE, SUBMIT_SIGNUP_CREATE_ACCOUNT_SUCCESS];
export const requestCases = [SUBMIT_SIGNUP_CREATE_ACCOUNT_REQUEST];

const submitReducer = createIsSubmittingReducer(finishCases, requestCases);

export default submitReducer;

import { SSOProviders } from 'enums/sso';
import LOGO_GOOGLE_LETTER from 'global/icons/logo_google_letter.svg';
export const SSOSetupFormClickDelayDuration = 500;
export const SSOSetupSplashscreenDuration = 3800;
export const AllowedSSOProviders = [SSOProviders.GOOGLE_OAUTH2];
export const SSO_PROVIDER_ICON = {
    [SSOProviders.GOOGLE_OAUTH2]: LOGO_GOOGLE_LETTER,
};
export const SSOSetupSplashscreenText = {
    [SSOProviders.GOOGLE_OAUTH2]: 'Google Apps SSO',
};
export const SSOSetupSuccessUIText = {
    [SSOProviders.GOOGLE_OAUTH2]: {
        SETUP: 'Google Apps SSO is now active for your workspace',
        EDIT: 'Google Apps SSO configuration has been updated!',
    },
};
export const SSOSetupFailUIText = {
    [SSOProviders.GOOGLE_OAUTH2]: {
        SETUP: 'We were not able to turn on Google Apps SSO. Please try again or contact support.',
        EDIT: 'We were not able to update Google Apps SSO. Please try again or contact support.',
    },
};
export const SSODisableSuccessUIText = {
    [SSOProviders.GOOGLE_OAUTH2]: 'Google Apps SSO has successfully been disabled!',
    [SSOProviders.SAML]: 'SAML SSO has successfully been disabled!',
};
export const SSODisableFailUIText = {
    [SSOProviders.GOOGLE_OAUTH2]: 'We were not able to disable Google Apps SSO. Please try again or contact support.',
    [SSOProviders.SAML]: 'We were not able to disable SAML SSO. Please try again or contact support.',
};
export const SSO_HINT_TEXT = {
    [SSOProviders.GOOGLE_OAUTH2]: 'Team members with disabled Google accounts are still Routable members until removed from your team in Routable. Depending on their role, this could impact pricing.',
    [SSOProviders.SAML]: 'Team members with disabled SAML accounts are still Routable members until removed from your team in Routable. Depending on their role, this could impact pricing.',
};

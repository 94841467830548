import PropTypes from 'prop-types';
import React from 'react';
import { useSearchParam } from 'react-use';

import { ItemKinds } from 'constants/item';

import { ItemFilterUrlParams } from 'enums/itemFilters';

import { oneOfValuesFromObject } from 'helpers/propTypes';

import { usePayment } from 'hooks';

import PaymentMethodHintBase from '../PaymentMethodHintBase';

import { getItemPaymentMethodHintLabelText } from './helpers';

/**
 * Renders hint displaying information about payment method associated with
 * the current item in the external partnership workflow.
 * @param {ComponentProps} props
 * @param {FundingAccount} [props.fundingAccount]
 * @param {FundingInfoAddress} [props.fundingInfoAddress]
 * @param {ItemKind} [props.itemKind]
 * @returns {StatelessComponent}
 */
const ItemPaymentMethodHint = ({ fundingAccount, fundingInfoAddress, itemKind }) => {
  const paymentId = useSearchParam(ItemFilterUrlParams.FILTER_PAYMENT_ID_URL_PARAM);
  const { data: payment } = usePayment({ initialPaymentId: paymentId });

  // If we do not have an info about the item kind, that means that the item is still
  // loading and we do not want to display the payment method hint
  if (!itemKind && !payment?.kind) {
    return null;
  }

  const kind = itemKind || payment?.kind;
  const label = getItemPaymentMethodHintLabelText(kind);

  return (
    <PaymentMethodHintBase fundingAccount={fundingAccount} fundingInfoAddress={fundingInfoAddress} label={label} />
  );
};

ItemPaymentMethodHint.propTypes = {
  fundingAccount: PropTypes.shape({}),
  fundingInfoAddress: PropTypes.shape({}),
  itemKind: oneOfValuesFromObject(ItemKinds),
};

ItemPaymentMethodHint.defaultProps = {
  fundingAccount: undefined,
  fundingInfoAddress: undefined,
  itemKind: undefined,
};

export default ItemPaymentMethodHint;

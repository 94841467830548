import clsx from 'clsx';
import _find from 'lodash/find';
import PropTypes from 'prop-types';
import React from 'react';
import { components as defaultComponents, createFilter } from 'react-select';
import Async from 'react-select/async';
import AsyncCreatable from 'react-select/async-creatable';

import { reduxFormInputPropType } from 'components/commonProps';

import { keyTabOrEnter } from 'helpers/keyEvents';
import { parseValueFromOptionForSelects } from 'helpers/selects';

import { BottomSheetMenu, LockedIndicator, NoSearchTerm } from './components';
import SelectFieldV2 from './SelectFieldV2';
import { getSelectComponents, getSelectComponentsStyles, getSelectPlaceholderValue } from './utils';

class AsyncSelectWrapper extends SelectFieldV2 {
  getSelectComponentClass = () => {
    const { isCreatable } = this.props;
    return isCreatable ? AsyncCreatable : Async;
  };

  getSelectProps = () => {
    const {
      avoidScreenEdge,
      className,
      classNamePrefix,
      components,
      dataFullStory,
      defaultOptions,
      filterConfig,
      hasAvatar,
      hideLabel,
      getCreateLabel,
      getIsValidNewOption,
      getNewOptData,
      noResultsText,
      noSearchTermText,
      getOptionLabel,
      getOptionValue,
      idPrefix,
      isClearable,
      isDisabled,
      isLocked,
      isLoading,
      isMulti,
      isOpen,
      isSearchable,
      label,
      loadOptions,
      lockedTooltipProps,
      input: { name },
      onCreate,
      placeholder,
      styles,
      useCache,
      ...rest
    } = this.props;

    const { hasFocus, hasInput, hasValue, isMobile } = this.state;

    const placeholderValue = getSelectPlaceholderValue({
      hideLabel,
      label,
      placeholder,
    });

    const filter = filterConfig ? createFilter(filterConfig) : undefined;
    const noOptionsMessage = () => (hasInput ? noResultsText : <NoSearchTerm searchTerm={noSearchTermText} />);

    const LockedIndicatorWithProps = (indicatorProps) => (
      <LockedIndicator {...indicatorProps} tooltipProps={lockedTooltipProps} />
    );

    const hasErrors = Boolean(this.getErrors());

    const componentProps = {
      ...rest,
      allowCreateWhileLoading: true,
      backspaceRemovesValue: true,
      cacheOptions: useCache,
      className: clsx(className, 'Select', {
        'has-avatar': !!hasAvatar,
        'has-error': !!hasErrors,
        'has-input': !!hasInput,
        'has-value': !!hasValue,
        'is-disabled': !!isDisabled,
        'is-focused': !!hasFocus,
      }),
      classNamePrefix,
      createOptionPosition: 'first',
      components: getSelectComponents({
        BottomSheetComponent: BottomSheetMenu,
        components,
        defaultComponents,
        LockIndicatorComponent: LockedIndicatorWithProps,
        isLocked,
        isMobile,
      }),
      dataFullStory, // pass to children components so it can show on Option
      'data-fullstory': dataFullStory, // pass to react-select so it can show on the wrapper
      defaultOptions,
      filterOption: filter,
      formatCreateLabel: getCreateLabel,
      getOptionLabel,
      getOptionValue,
      getNewOptionData: getNewOptData,
      hasAvatar,
      loadOptions,
      instanceId: idPrefix,
      isClearable,
      isDisabled,
      isLoading,
      isMulti,
      isValidNewOption: getIsValidNewOption,
      isSearchable,
      menuIsOpen: isOpen,
      menuPosition: this.props.menuPortalTarget === document.body ? 'fixed' : undefined,
      menuPortalTarget: this.props.menuPortalTarget,
      name,
      noOptionsMessage,
      onBlur: this.handleBlur,
      onChange: this.handleChange,
      onCreateOption: onCreate,
      onFocus: this.handleFocus,
      onInputChange: this.handleInputChange,
      placeholder: placeholderValue,
      shouldKeyDownEventCreateNewOption: keyTabOrEnter,
      styles: getSelectComponentsStyles({
        isDisabled,
        isLocked,
        isMobile,
        isSearchable,
        lockedTooltipProps,
        styles,
      }),
      value: this.getCurrentValue(),
    };

    if (avoidScreenEdge) {
      const { max, min } = this.getMenuMaxAndMin();
      componentProps.maxMenuHeight = max;
      componentProps.minMenuHeight = min;
    }

    return componentProps;
  };

  // value can be passed as the value object, or as the string value
  // identifying an option such that `option[valueKey] === value`
  getCurrentValue = () => {
    const {
      input: { value },
      valueKey,
    } = this.props;
    const { lastInput, lastValue } = this.state;

    const instance = this.selectRef.current;

    if (typeof value === 'string' && valueKey) {
      if (lastValue && lastValue[valueKey] === value) {
        return lastValue;
      }

      if (lastInput) {
        const { optionsCache } = instance;
        const loadedOptions = optionsCache ? optionsCache[lastInput] : undefined;

        if (loadedOptions && loadedOptions.length) {
          const option = _find(loadedOptions, (o) => o[valueKey] === value);

          if (option) {
            return option;
          }
        }
      }
    }

    return value;
  };
}

AsyncSelectWrapper.propTypes = {
  avoidScreenEdge: PropTypes.bool,
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  defaultOptions: PropTypes.arrayOf(PropTypes.shape()),
  components: PropTypes.shape(),
  filterConfig: PropTypes.shape(),
  getCreateLabel: PropTypes.func,
  getIsValidNewOption: PropTypes.func,
  getNewOptData: PropTypes.func,
  noResultsText: PropTypes.string,
  noSearchTermText: PropTypes.string,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  idPrefix: PropTypes.string,
  input: reduxFormInputPropType.isRequired,
  isClearable: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  isOpen: PropTypes.bool,
  isSearchable: PropTypes.bool,
  loadOptions: PropTypes.func.isRequired,
  lockedTooltipProps: PropTypes.shape({}),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onCreate: PropTypes.func,
  onFocus: PropTypes.func,
  onInputChange: PropTypes.func,
  placeholder: PropTypes.node,
  useCache: PropTypes.bool,
  valueKey: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

AsyncSelectWrapper.defaultProps = {
  avoidScreenEdge: undefined,
  className: undefined,
  classNamePrefix: 'Select',
  components: {},
  defaultOptions: [],
  filterConfig: undefined,
  getCreateLabel: undefined,
  getCreatePrompt: undefined,
  getIsNewOptValid: undefined,
  getIsValidNewOption: undefined,
  getNewOptData: undefined,
  getOptionLabel: (opt) => opt.label || opt.text || opt.name,
  getOptionValue: undefined,
  hasAvatar: undefined,
  idPrefix: undefined,
  isCreatable: undefined,
  isClearable: true,
  isDisabled: undefined,
  isLoading: undefined,
  isLocked: undefined,
  isMulti: undefined,
  isOpen: undefined,
  isRequired: true,
  isSearchable: true,
  label: '',
  labelClassName: undefined,
  lockedTooltipProps: undefined,
  menuPortalTarget: document.body,
  meta: {},
  noResultsText: 'No matches found',
  noSearchTermText: 'Type to search',
  onCreate: undefined,
  onInputChange: undefined,
  parseValue: parseValueFromOptionForSelects,
  placeholder: undefined,
  useCache: true,
  valueKey: 'value',
  wrapperClassName: undefined,
};

export default AsyncSelectWrapper;

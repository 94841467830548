import React from 'react';

import { sizes } from 'constants/styles';
import { ZIndexLayers } from 'constants/ui';

import { isUndef, valueOrDefault } from 'helpers/utility';

import { useClientRect } from 'hooks';

// Force Git Commit for Circle CI
/**
 * Handles the logic necessary in order to display select field correctly
 * inside horizontally scrollable table. In order to achieve that, the hook needs
 * reference to the parent scrolling element and rowIndex of the field.
 * @param {node} scrollingTargetRef
 * @return {Object} selectFieldConfigs
 */
const useScrollAwareSelectField = (scrollingTargetRef) => {
  const { rect: selectRect, ref: selectFieldRef, node: selectFieldNode } = useClientRect();

  const [initialScrollLeft, setInitialScrollLeft] = React.useState(undefined);
  const [scrollLeft, setScrollLeft] = React.useState(0);

  // Use passed horizontalScrollGroupRef.current as a horizontal scroll target;
  // this is the element that handles the horizontal scroll for the entire table
  const horizontalScrollingTarget = scrollingTargetRef?.current;

  // Due to overflow issues, select menu needs to be mounted outside horizontal scroll
  // target. Since we still want the element to be dependent on vertical scroll of
  // the entire page, we can't just mount it to the body, but rather one level up
  // of the horizontal scroll target
  const menuPortalTarget = React.useMemo(() => horizontalScrollingTarget?.parentNode, [horizontalScrollingTarget]);

  // Bounding rect of scrolling target is needed in order to correctly calculate positioning
  // of the select menu element in reference to it's respective field
  const scrollingTargetRect = React.useMemo(() => {
    if (horizontalScrollingTarget) {
      return horizontalScrollingTarget.getBoundingClientRect();
    }
    // Return default top and left values in case of horizontalScrollingTarget being
    // undefined in order to avoid extra checks when we use those value for calculations
    return { top: 0, left: 0 };
  }, [horizontalScrollingTarget]);

  // React-select does a lousy job at positioning select menu when the first relative parent
  // is not document.body. Here we grab the value of the select field where we want to
  // mount the menu and re-calculate menu position based on the table cell position
  const menuPortalOffsets = React.useMemo(() => {
    // Since it could be, that the values of selectRect are undefined we set the default
    // value of 0 for each of the properties that we later perform calculations
    // with (and therefore avoid any type mismatch).
    const selectHeight = valueOrDefault(selectRect?.height, 0);
    const selectWidth = valueOrDefault(selectRect?.width, 0);
    const selectLeftOffset = valueOrDefault(selectRect?.left, 0);
    const selectTopOffset = valueOrDefault(selectRect?.top, 0);

    // This is where the offsets magic happens:
    // - for the left offset, we subtract left offset of the scrolling container from the
    //   left offset of the select field
    // - for the top offset, we subtract top offset of the scrolling container from the
    //   top offset of the select field, and add select field height in order to push
    //   the menu to the bottom of the select field
    const leftOffset = selectLeftOffset - scrollingTargetRect.left + initialScrollLeft;
    const topOffset = selectTopOffset - scrollingTargetRect.top + selectHeight;

    return {
      left: `${leftOffset}px`,
      top: `calc(${topOffset}px - ${sizes.spacing.MEDIUM})`,
      minWidth: `calc(${selectWidth}px + ${sizes.spacing.LARGE})`,
      transform: `translate3d(-${scrollLeft}px, 0, 0)`,
    };
  }, [
    initialScrollLeft,
    scrollingTargetRect.left,
    scrollingTargetRect.top,
    scrollLeft,
    selectRect.height,
    selectRect.left,
    selectRect.top,
    selectRect.width,
  ]);

  // React Select styles override; zIndex needs to be bumped up, horizontal transform
  // is applied on scroll event for correct horizontal positioning when users scrolls
  // while the menu is open. Top and left properties are re-assigned since react-select
  // does not do the best job positioning select menu when the first relative parent
  // is not a document.body element
  const menuPortalStyles = (base) => ({
    ...base,
    ...menuPortalOffsets,
    zIndex: ZIndexLayers.MODAL.SELECT,
  });

  const onHorizontalScroll = React.useCallback(({ target }) => {
    setScrollLeft(target.scrollLeft);
  }, []);

  // When input field gains focus, we want to scroll that field wrapper into the view.
  // This function is returned from the hook and meant to be used as an onFocus
  // prop on target select field
  const onInputFocus = React.useCallback(() => {
    if (!selectFieldNode) {
      // Do nothing if selectFieldNode does not exists for some reason
      // (prevent app crashing)
      return;
    }

    selectFieldNode.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  }, [selectFieldNode]);

  // Attach scroll event listener on horizontalScrollingTarget once component is mounted
  // and remove it once the component is detached
  React.useEffect(() => {
    if (horizontalScrollingTarget) {
      horizontalScrollingTarget.addEventListener('scroll', onHorizontalScroll);

      if (isUndef(initialScrollLeft)) {
        setScrollLeft(horizontalScrollingTarget.scrollLeft);
        setInitialScrollLeft(horizontalScrollingTarget.scrollLeft);
      }
    }

    return () => {
      if (horizontalScrollingTarget) {
        horizontalScrollingTarget.removeEventListener('scroll', onHorizontalScroll);
      }
    };
  }, [horizontalScrollingTarget, initialScrollLeft, onHorizontalScroll]);

  return {
    menuPortalStyles,
    menuPortalTarget,
    onInputFocus,
    selectFieldNode,
    selectFieldRef,
  };
};

export default useScrollAwareSelectField;

import { createIsDoingReducer } from 'store/redux';

import * as types from 'types/errors';

export const finishCases = [types.REMOVE_APPROVAL_LEVEL_ERROR];
export const requestCases = [types.ADD_APPROVAL_LEVEL_ERROR];

const approvalLevelErrorReducer = createIsDoingReducer(finishCases, requestCases);

export default approvalLevelErrorReducer;

import { submitFundingStateRoutine } from 'actions/routines/external';

import { createErrorReducer } from 'store/redux';

const successCases = [submitFundingStateRoutine.SUCCESS];

const failureCases = [submitFundingStateRoutine.FAILURE];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

import { SubmissionError } from 'redux-form';

import { ItemKinds } from 'constants/item';

/**
 *  we use onSubmitAllExistingItems to accept all payments and update the partnership
 *  since there are no payments, we only pass the kind 'RECEIVABLE' and action 'accept'
 *  to update the receivable payment method and default funding account
 */
const submitUpdatePaymentMethod = async (values, options = {}) => {
  const { onSubmitAllExistingItems, partnershipId, successCallback } = options;
  const { defaultReceivable } = values;

  const meta = { kind: ItemKinds.RECEIVABLE };

  const result = await onSubmitAllExistingItems(defaultReceivable, meta, partnershipId, 'accept', successCallback);

  if (result && result.errors) {
    throw new SubmissionError(result.errors.fields);
  }
};

export default submitUpdatePaymentMethod;

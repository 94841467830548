import PropTypes from 'prop-types';
import React from 'react';

import { DetailsList, FlexCol, FlexRow, HeadingTitle, SimpleCard } from 'components';

import { getClassNames } from 'helpers/ui';

/**
 * DetailsList wrapped with a SimpleCard. Title optional.
 * @param {Object[]} detailsListItems
 * @param {StringMaybe} [title]
 * @param {RestOfProps} rest
 * @returns {StatelessComponent}
 * @constructor
 */
const DetailsListCard = ({ controls, dataFullStory, detailsListItems, title, ...rest }) => (
  <FlexCol className={getClassNames(rest)}>
    {title && <HeadingTitle>{`${title} info`}</HeadingTitle>}

    <SimpleCard className={getClassNames(rest, { 'margin-top--m': Boolean(title) })}>
      <FlexRow>
        <DetailsList dataFullStory={dataFullStory} items={detailsListItems} keyExtractor={(item) => item.label} />

        {controls}
      </FlexRow>
    </SimpleCard>
  </FlexCol>
);

DetailsListCard.propTypes = {
  controls: PropTypes.node,
  detailsListItems: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.node]).isRequired,
  title: PropTypes.string,
};

DetailsListCard.defaultProps = {
  controls: undefined,
  title: undefined,
};

export default DetailsListCard;

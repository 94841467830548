import { connect } from 'react-redux';

import { createItemFormSelector } from 'selectors/forms';
import { ledgerIntegrationSelector } from 'selectors/integrationsSelectors';
import { ledgerSettingsSelector } from 'selectors/ledgerInfoSelectors';

import TotalsPanel from './TotalsPanel';

const mapStateToProps = (state) => ({
  invoiceTotals: createItemFormSelector(state, 'invoiceTotals'),
  ledger: ledgerIntegrationSelector(state),
  ledgerSettings: ledgerSettingsSelector(state),
  meta: createItemFormSelector(state, 'meta'),
});

export default connect(mapStateToProps)(TotalsPanel);

import React from 'react';
import { Title, Content, Container, List, ListItem, Contents } from './Answer.styles';
import {} from './Answer.type';
const Answer = ({ title, content, list, contents }) => (React.createElement(Container, null,
    React.createElement(Title, null, title),
    React.createElement(Content, null, content),
    list && (React.createElement(List, null, list.map((item, index) => {
        const key = `list-item_${index}`;
        return React.createElement(ListItem, { key: key }, item);
    }))),
    contents?.length && (React.createElement(Contents, null, contents.map((subContent, index) => {
        const key = `subcontent_${index}`;
        return React.createElement(Answer, { key: key, ...subContent });
    })))));
export default Answer;

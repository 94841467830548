import { z } from 'zod';
export const optionModel = z.object({
    value: z.string().uuid(),
    label: z.string(),
    isValid: z.boolean().optional(),
});
export const optionsModel = optionModel.array();
export const valueModel = z.object({
    id: z.string(),
    value: z.string().optional(),
    label: z.string(),
    isValid: z.boolean().optional(),
});
export const valuesModel = valueModel.array();

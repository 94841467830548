import { SubmissionError } from 'redux-form';
import { all, call, delay, put, spawn, take } from 'redux-saga/effects';

import * as clearingActions from 'actions/clearingAccounts';
import { handleRequestErrors } from 'actions/errors';
import * as actions from 'actions/funding';
import { closeInternationalPaymentModal } from 'actions/modals';
import { fetchPartnershipReceivableFundingAccountsRequest } from 'actions/partnership';
import {
  createInternationalBankAccountRoutine,
  createInternationalFundingAccountRoutine,
  fetchSupportedCountriesRoutine,
} from 'actions/routines/funding';
import { closeSidePanel } from 'actions/sidePanels';
import { showSuccessUi } from 'actions/ui';

import { sidePanelNameAddPartnerFundingAccount, sidePanelNameManuallyAddBankAccount } from 'constants/sidePanels';
import { SuccessIndicatorMessages } from 'constants/ui';

import { externalFundingSubmitTransformers } from 'data/submitTransformers';

import { parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { isExternalPathAny } from 'helpers/external';
import { createSaga } from 'helpers/saga';

import { payloadToUnderscore } from 'services/api/formatHelpers';

import * as clearingTypes from 'types/clearingAccounts';
import * as types from 'types/funding';

import * as api from './api';

/**
 * Saga to submit an international funding account.
 *
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* createInternationalFundingAccount(action) {
  let errorData = {};
  yield put(createInternationalFundingAccountRoutine.request());

  try {
    const {
      payload: { props, values },
    } = action;
    // extract the UI from the form values. We only need this to potentially close the sidesheet
    const { ui, ...restOfValues } = values;
    const { partnership } = props;
    const submitPayload = externalFundingSubmitTransformers.getCreatePartnershipInternationalManualBankAccountPayload({
      fields: restOfValues,
      partnership,
    });

    const response = yield call(api.createInternationalFundingAccount, submitPayload);

    if (response.ok) {
      const successActions = [put(createInternationalFundingAccountRoutine.success(response.data))];

      // The toast will be skipped when in the external flow,
      // which improves the chances of the vendor clicking the completion step.
      if (!isExternalPathAny()) {
        successActions.push(put(showSuccessUi(SuccessIndicatorMessages.BANK_ACCOUNT_ADDED)));
      }

      yield all(successActions);

      // DEV-2836 @adamjaffeback if the payload includes closeAddFundingAccountSideSheetOnSuccess set to true, we want
      // to dispatch an action to close add funding account side sheet on request.success
      if (ui?.closeAddFundingAccountSideSheetOnSuccess) {
        yield put(closeSidePanel({ name: sidePanelNameManuallyAddBankAccount }));
      }
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  // If we have fields errors in the errorData, we want to dispatch an action with
  // new SubmissionError as a payload, so that the correct form submission errors
  // are set (https://github.com/afitiskin/redux-saga-routines#redux-saga-redux-form-redux-saga-routines-combo)
  if (errorData?.fields) {
    // Since the form fields names are in the underscore format (dictated by the BE),
    // we need to convert camelCased field names to underscored format
    const errorPayload = new SubmissionError(payloadToUnderscore(errorData.fields));
    yield put(createInternationalFundingAccountRoutine.failure(errorPayload));
    return;
  }

  yield put(handleRequestErrors(createInternationalFundingAccountRoutine.failure, errorData));
}

/**
 * Saga to submit an international bank account.
 *
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* createInternationalBankAccount(action) {
  let errorData = {};
  yield put(createInternationalBankAccountRoutine.request());
  const {
    payload: { props, values },
  } = action;
  const { partnership, requiredFields } = props;

  try {
    // extract the UI from the form values. We only need this to close the modal
    const { ui } = values;
    const submitPayload = externalFundingSubmitTransformers.getCreatePartnershipInternationalBankAccountPayload({
      partnership,
      requiredFields,
      values,
    });

    const response = yield call(api.createInternationalBankAccount, submitPayload);

    if (response.ok) {
      yield all([
        put(showSuccessUi(SuccessIndicatorMessages.BANK_ACCOUNT_ADDED)),
        put(closeSidePanel({ name: sidePanelNameAddPartnerFundingAccount })),
        put(createInternationalBankAccountRoutine.success(response.data)),
        put(fetchPartnershipReceivableFundingAccountsRequest(partnership.id)),
      ]);

      if (ui?.closeInternationalBankModalOnSuccess) {
        yield put(closeInternationalPaymentModal());
      }
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  // If we have fields errors in the errorData, we want to dispatch an action with
  // new SubmissionError as a payload, so that the correct form submission errors
  // are set (https://github.com/afitiskin/redux-saga-routines#redux-saga-redux-form-redux-saga-routines-combo)
  if (errorData?.fields) {
    // Since the form fields names are in the underscore format (dictated by the BE),
    // we need to convert camelCased field names to underscored format
    const errorPayload = new SubmissionError(payloadToUnderscore(errorData.fields));
    yield put(createInternationalBankAccountRoutine.failure(errorPayload));
    return;
  }

  yield put(handleRequestErrors(createInternationalBankAccountRoutine.failure, errorData));
}

/**
 * Request a single funding account's data.
 * @return {IterableIterator<*>}
 */
export function* fetchSingleFundingAccount(action) {
  const {
    payload: { accountId, options },
  } = action;

  let errorData = {};

  try {
    const response = yield call(api.fetchSingleFundingAccount, accountId);

    if (options.delay) {
      yield delay(options.delay);
    }

    if (response.ok) {
      yield put(actions.fetchSingleFundingAccountSuccess(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchSingleFundingAccountFailure, errorData));
}

/**
 * Request funding account data.
 * @return {IterableIterator<*>}
 */
export function* fetchFundingAccounts() {
  let errorData = {};

  try {
    const response = yield call(api.fetchFundingAccounts);

    if (response.ok) {
      yield put(actions.fetchFundingAccountsSuccess(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchFundingAccountsFailure, errorData));
}

/**
 * Request funding-clearing accounts matching data.
 * @return {IterableIterator<*>}
 */
export function* fetchFundingAccountsForClearingAccountsMatching(action) {
  let errorData = {};

  try {
    const {
      payload: { integrationConfigId, filter },
    } = action;

    const response = yield call(api.fetchFundingAccountsForClearingAccountsMatching, integrationConfigId, filter);

    if (response.ok) {
      yield put(clearingActions.fetchFundingAccountsForClearingMatchingSuccess(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(clearingActions.fetchFundingAccountsForClearingMatchingFailure, errorData));
}

/**
 * Request clearing accounts that are unmatched..
 * @return {IterableIterator<*>}
 */
export function* fetchUnmatchedLedgerClearingAccounts(action) {
  let errorData = {};

  try {
    const {
      payload: { integrationConfigId, filter },
    } = action;

    const response = yield call(api.fetchUnmatchedLedgerClearingAccounts, integrationConfigId, filter);

    if (response.ok) {
      yield put(clearingActions.fetchUnmatchedLedgerClearingAccountsSuccess(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(clearingActions.fetchUnmatchedLedgerClearingAccountsFailure, errorData));
}

/**
 * Handle fetching all supported countries
 * @returns {IterableIterator<*>}
 */
export function* fetchSupportedCountries() {
  yield call(createSaga, api.fetchSupportedCountries, fetchSupportedCountriesRoutine);
}

/**
 * Listens for redux actions related to funding accounts, sources, and other data.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([
      clearingTypes.FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_REQUEST,
      clearingTypes.FETCH_UNMATCHED_LEDGER_CLEARING_ACCOUNTS_REQUEST,
      createInternationalFundingAccountRoutine.TRIGGER,
      createInternationalBankAccountRoutine.TRIGGER,
      fetchSupportedCountriesRoutine.TRIGGER,
      types.FETCH_SINGLE_FUNDING_ACCOUNT_REQUEST,
      types.FETCH_FUNDING_ACCOUNTS_REQUEST,
    ]);

    switch (action.type) {
      case clearingTypes.FETCH_FUNDING_ACCOUNTS_FOR_CLEARING_ACCOUNTS_MATCHING_REQUEST:
        yield spawn(fetchFundingAccountsForClearingAccountsMatching, action);
        break;

      case clearingTypes.FETCH_UNMATCHED_LEDGER_CLEARING_ACCOUNTS_REQUEST:
        yield spawn(fetchUnmatchedLedgerClearingAccounts, action);
        break;

      case createInternationalFundingAccountRoutine.TRIGGER:
        yield spawn(createInternationalFundingAccount, action);
        break;

      case createInternationalBankAccountRoutine.TRIGGER:
        yield spawn(createInternationalBankAccount, action);
        break;

      case fetchSupportedCountriesRoutine.TRIGGER:
        yield spawn(fetchSupportedCountries, action);
        break;

      case types.FETCH_SINGLE_FUNDING_ACCOUNT_REQUEST:
        yield spawn(fetchSingleFundingAccount, action);
        break;

      case types.FETCH_FUNDING_ACCOUNTS_REQUEST:
        yield spawn(fetchFundingAccounts);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root funding saga.
 * @return {IterableIterator<*>}
 */
export default function* funding() {
  yield watch();
}

import PropTypes from 'prop-types';
import React from 'react';

import { FlairTag } from 'components';

import { FlairTagType, TagShape } from 'constants/ui';

import { textHelpers } from 'modules/fundingAccount/bankAccount/helpers';

const BankAccountHeaderContentFlairItems = (props) => {
  const { partnership, partnershipFundingAccount } = props;

  const { isPreferred, isPrimary } = partnershipFundingAccount;

  return (
    <>
      {isPreferred && isPrimary && (
        <div className="bank-account--header--flair-items">
          <FlairTag
            forcePointerEvents
            readOnly
            shape={TagShape.ROUND}
            tooltipContent={textHelpers.getPartnerBankAccountFlairTooltipText(
              FlairTagType.PRIMARY_AND_PREFERRED,
              partnership,
            )}
            type={FlairTagType.PRIMARY_AND_PREFERRED}
          />
        </div>
      )}

      {isPreferred && !isPrimary && (
        <div className="bank-account--header--flair-items">
          <FlairTag
            forcePointerEvents
            readOnly
            shape={TagShape.ROUND}
            tooltipContent={textHelpers.getPartnerBankAccountFlairTooltipText(FlairTagType.PREFERRED, partnership)}
            type={FlairTagType.PREFERRED}
          />
        </div>
      )}

      {!isPreferred && isPrimary && (
        <div className="bank-account--header--flair-items">
          <FlairTag
            forcePointerEvents
            readOnly
            shape={TagShape.ROUND}
            tooltipContent={textHelpers.getPartnerBankAccountFlairTooltipText(FlairTagType.PRIMARY, partnership)}
            type={FlairTagType.PRIMARY}
          />
        </div>
      )}
    </>
  );
};

BankAccountHeaderContentFlairItems.propTypes = {
  partnership: PropTypes.shape(),
  partnershipFundingAccount: PropTypes.shape(),
};

BankAccountHeaderContentFlairItems.defaultProps = {
  partnership: {},
  partnershipFundingAccount: {},
};

export default BankAccountHeaderContentFlairItems;

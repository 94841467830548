export const ADD_NOT_FOUND_ERROR = '@errors/ADD_NOT_FOUND_ERROR';
export const REMOVE_NOT_FOUND_ERROR = '@errors/REMOVE_NOT_FOUND_ERROR';

export const ADD_DISABLED_USER_ERROR = '@errors/ADD_DISABLED_USER_ERROR';

export const HANDLE_REQUEST_ERRORS = '@errors/HANDLE_REQUEST_ERRORS';

export const SET_FORM_ERRORS = '@errors/SET_FORM_ERRORS';

export const SHOW_SERVER_ERROR_ALERT = '@error/SHOW_SERVER_ERROR_ALERT';

export const SHOW_TIMEOUT_ERROR_ALERT = '@error/SHOW_TIMEOUT_ERROR_ALERT';

export const ADD_APPROVAL_LEVEL_ERROR = '@errors/ADD_APPROVAL_LEVEL_ERROR';
export const REMOVE_APPROVAL_LEVEL_ERROR = '@errors/REMOVE_APPROVAL_LEVEL_ERROR';

import { fetchPartnershipPaymentOptionsSuccess } from 'actions/payments';
import {} from 'interfaces/routableApiResponse';
import { routableApi, RoutableTags } from './routableApi';
const extendedPaymentApi = routableApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getPartnershipPaymentOptions: builder.query({
            query: ({ partnershipId }) => ({
                url: `/partnerships/${partnershipId}/payment/`,
            }),
            providesTags: [RoutableTags.Partnership],
            async onQueryStarted(_response, api) {
                const payload = await api.queryFulfilled;
                api.dispatch(fetchPartnershipPaymentOptionsSuccess(payload.data));
            },
        }),
    }),
});
export const { useLazyGetPartnershipPaymentOptionsQuery } = extendedPaymentApi;

import PropTypes from 'prop-types';
import React from 'react';

import { MapDataToAccess, ProtectedRouteWarnInvoice, RedirectToThread } from 'components/routing';

import withQueryParamProps from 'hoc/withQueryParamProps';

import { currentCompanyIdSelector } from 'selectors/currentCompanySelectors';

/**
 * Deep link route component that sets up access checks for viewing a particular invoice,
 * and sets up the fallback component to render should access be denied.
 *
 * @param companyId {string}
 * @param itemId {string}
 * @param type {ItemKind}
 * @returns {*}
 * @constructor
 */
const ViewInvoice = ({ companyId, itemId, type }) => (
  <MapDataToAccess renderNoAccess={ProtectedRouteWarnInvoice} requirements={[[currentCompanyIdSelector, companyId]]}>
    <RedirectToThread itemId={itemId} type={type} />
  </MapDataToAccess>
);

ViewInvoice.propTypes = {
  companyId: PropTypes.string,
  itemId: PropTypes.string,
  type: PropTypes.string.isRequired,
};

ViewInvoice.defaultProps = {
  companyId: undefined,
  itemId: undefined,
};

export default withQueryParamProps(ViewInvoice);

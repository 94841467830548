import { createSelector } from 'reselect';

/**
 * Top level currencies selector
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
const getState = (state) => state.currencies;

/**
 * Selects all IDs of currencies from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {CurrencyCode[]}
 */
export const allCurrenciesSelector = createSelector([getState], (currencies) => currencies.allIds);

/**
 * Selects currencies by id from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
export const byCurrencySelector = createSelector([getState], (currencies) => currencies.byId);

/**
 * Selects the currencies errors from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
export const currenciesErrorsSelector = createSelector([getState], (currencies) => currencies.errors);

/**
 * Selects the isFetching boolean for currencies from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {boolean}
 */
export const isFetchingCurrenciesSelector = createSelector([getState], (currencies) => currencies.isFetching);

/**
 * Selects the lastFetched data for currencies from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {DateMaybe}
 */
export const currenciesLastFetchedSelector = createSelector([getState], (currencies) => currencies.lastFetched);

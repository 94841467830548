import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2, Col, Icon, IconNames, LinkNewPage, Row } from 'components';

import { ButtonSize } from 'constants/button';
import { breakpointNames } from 'constants/mediaQuery';
import { colors, sizes } from 'constants/styles';

import helpDocs from 'helpers/helpDocs';

import { text } from 'modules/fundingAccount/bankAccount/constants';

/**
 * Component rendering Previously Added Address account hint
 *
 * @param {ComponentProps} props
 * @param {Boolean} [props.isSubmitting]
 * @param {Function} props.onButtonClick
 * @returns {StatelessComponent}
 */
const AccountHintSectionPreviouslyAddedAddress = ({ isSubmitting, onButtonClick }) => (
  <Row
    breakpointProps={{
      [breakpointNames.phone]: {
        flexWrap: 'no-wrap',
      },
    }}
    flexWrap="wrap"
  >
    <Col className="margin-bottom--m">
      <ButtonV2
        className="width--max-content"
        isDisabled={isSubmitting}
        leftIconClassName="margin-right--xm"
        leftIconName={IconNames.TICK}
        onClick={onButtonClick}
        size={ButtonSize.MEDIUM}
      >
        {text.previouslyAddedAddressButtonText}
      </ButtonV2>
    </Col>

    <Col className="flex-col-center  margin-bottom--m">
      <LinkNewPage
        className="bank-account--hint-action--link"
        href={helpDocs.WHY_WAS_MY_PAYMENT_METHOD_PREVIOUSLY_ADDED}
      >
        <Icon
          className="margin-right--sm"
          color={colors.colorMainJordan}
          icon={IconNames.HELP}
          size={sizes.iconSizes.LARGE}
        />
        {text.previouslyAddedAddressActionLinkText}
      </LinkNewPage>
    </Col>
  </Row>
);

AccountHintSectionPreviouslyAddedAddress.propTypes = {
  isSubmitting: PropTypes.bool,
  onButtonClick: PropTypes.func.isRequired,
};

AccountHintSectionPreviouslyAddedAddress.defaultProps = {
  isSubmitting: undefined,
};

export default AccountHintSectionPreviouslyAddedAddress;

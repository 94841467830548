import PropTypes from 'prop-types';
import React from 'react';
import { IconNames, ModalFooter, ModalFooterButton, ModalFooterButtonCancel } from 'components';
import { sizes } from 'constants/styles';
const AddPartnerFundingAccountFooter = ({ formId, isSubmitting, isSubmitDisabled, onCloseModal, submitButtonText, onClick, }) => (React.createElement(ModalFooter, null,
    React.createElement("div", { className: "modal-footer--container" },
        React.createElement("div", { className: "content--left" },
            React.createElement(ModalFooterButtonCancel, { onCloseModal: onCloseModal })),
        React.createElement("div", { className: "content--right" }, onClick ? (React.createElement(ModalFooterButton, { isActionButton: true, isDisabled: isSubmitting || isSubmitDisabled, isLoading: isSubmitting, leftIconName: IconNames.TICK_CIRCLE, leftIconSize: sizes.iconSizes.EXTRA_MEDIUM, onClick: onClick }, submitButtonText)) : (React.createElement(ModalFooterButton, { htmlFor: formId, isActionButton: true, isDisabled: isSubmitting || isSubmitDisabled, isLoading: isSubmitting, leftIconName: IconNames.TICK_CIRCLE, leftIconSize: sizes.iconSizes.EXTRA_MEDIUM, type: "submit" }, submitButtonText))))));
AddPartnerFundingAccountFooter.propTypes = {
    formId: PropTypes.string.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    submitButtonText: PropTypes.string.isRequired,
};
export default AddPartnerFundingAccountFooter;

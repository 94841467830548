import { fetchExternalPartnershipRequestRoutine, submitFundingStateRoutine } from 'actions/routines/external';

import { fields } from 'constants/partnershipRequestState';
import { partnershipFundingStateRelationships } from 'constants/relationships';

import { getAttributesAndRelationshipsForReducer } from 'helpers/reducer';

/**
 * Handles data flow of partnership request current funding state
 * @type {ReduxReducer}
 * @return {ObjectMaybe}
 */
const currentFundingStateReducer = (state = null, action) => {
  switch (action.type) {
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
    case submitFundingStateRoutine.SUCCESS:
      return getAttributesAndRelationshipsForReducer(
        action.payload[fields.FUNDING_ACCOUNT],
        partnershipFundingStateRelationships,
      );

    default:
      return state;
  }
};

export default currentFundingStateReducer;

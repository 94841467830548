import { createIsSubmittingReducer } from 'store/redux';

import {
  CLEAR_EMAIL_FORWARD,
  SUBMIT_EMAIL_FORWARD_FAILURE,
  SUBMIT_EMAIL_FORWARD_REQUEST,
  SUBMIT_EMAIL_FORWARD_SUCCESS,
} from 'types/notifications';

export const finishCases = [CLEAR_EMAIL_FORWARD, SUBMIT_EMAIL_FORWARD_FAILURE, SUBMIT_EMAIL_FORWARD_SUCCESS];
export const requestCases = [SUBMIT_EMAIL_FORWARD_REQUEST];

const isSubmittingEmailForwardReducer = createIsSubmittingReducer(finishCases, requestCases);

export default isSubmittingEmailForwardReducer;

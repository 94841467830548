import * as types from 'types/partnership';

export const lastSubmittedReducer = (state = null, action) => {
  switch (action.type) {
    case types.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_SUCCESS:
      return new Date();

    case types.CLEAR_PARTNERSHIP_ADDRESSES:
      return null;

    default:
      return state;
  }
};

export default lastSubmittedReducer;

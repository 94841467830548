import React from 'react';

import { reduxFormInputPropType } from 'components/commonProps';

import InvoiceGeneratorTotalsRow from '../InvoiceGeneratorTotalsRow';

const InvoiceGeneratorTotalsTax = ({ input: { value }, ...rest }) => (
  <InvoiceGeneratorTotalsRow {...rest} amount={value} text="Total tax" />
);

InvoiceGeneratorTotalsTax.propTypes = {
  input: reduxFormInputPropType.isRequired,
};

export default InvoiceGeneratorTotalsTax;

import { getItemEndpoint } from 'services/api/itemEndpoints';

// *************************************
// Partnership Member management on item
// *************************************

/**
 * Get URL for ItemMembers on an Item.
 * @param {string} itemId
 * @returns {string}
 */
export const getItemMembersEndpoint = (itemId) => `${getItemEndpoint(itemId)}members/`;

/**
 * Get URL for one ItemMember on an Item
 * @param {string} itemId
 * @param {string} itemMemberId
 * @returns {string}
 */
export const getSingleItemMemberEndpoint = (itemId, itemMemberId) =>
  `${getItemMembersEndpoint(itemId)}${itemMemberId}/`;

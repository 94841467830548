import _omit from 'lodash/omit';
import _pickBy from 'lodash/pickBy';
import _without from 'lodash/without';
import { useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useOverlayTriggerState } from 'react-stately';
import { getFiltersDiff, getFiltersListFromDictionary, pickActiveFilters } from './Filters.helpers';
export const useFilters = (options) => {
    const defaultFilters = options?.defaultFilters || {};
    const initialFilters = useRef(defaultFilters);
    const modalState = useOverlayTriggerState({});
    const { control, register, setValue, reset, resetField, watch } = useForm({
        defaultValues: defaultFilters,
    });
    const [appliedFilters, setAppliedFilters] = useState(defaultFilters);
    const [forceHiddenFilter, setForceHiddenFilter] = useState(null);
    const allFilters = watch();
    const activeFilters = _pickBy(allFilters, pickActiveFilters);
    const disabledFilters = useMemo(() => {
        return _without(options?.getDisabledFilters?.(activeFilters) ?? [], ...(options?.lockedFilters ?? []));
    }, [options?.getDisabledFilters, options?.lockedFilters, activeFilters]);
    const activeFiltersList = getFiltersListFromDictionary(_omit(activeFilters, disabledFilters));
    const appliedFiltersList = getFiltersListFromDictionary(appliedFilters);
    const handleClearFilterValue = (filter, value, opt = {
        shouldApplyFilters: true,
        forceClear: false,
    }, selectedFilterTab) => {
        const activeFilter = activeFilters[filter];
        if (Array.isArray(activeFilter)) {
            const newFilterValue = activeFilter.filter((val) => val !== value);
            const filterValue = opt?.forceClear || !newFilterValue.length ? null : newFilterValue;
            setValue(filter, filterValue);
            if (opt.shouldApplyFilters) {
                const updatedFilters = filterValue
                    ? { ...appliedFilters, [filter]: filterValue }
                    : _omit(appliedFilters, filter);
                options?.eventTracker?.(getFiltersDiff(appliedFilters, updatedFilters));
                setAppliedFilters(updatedFilters);
                options?.onFiltersChange?.(updatedFilters);
            }
        }
        else {
            const newActiveFilters = _omit(activeFilters, [filter, ...disabledFilters]);
            if (opt.shouldApplyFilters) {
                options?.eventTracker?.(getFiltersDiff(appliedFilters, newActiveFilters));
                setAppliedFilters(newActiveFilters);
                options?.onFiltersChange?.(newActiveFilters);
            }
            if (selectedFilterTab === filter) {
                setForceHiddenFilter(filter);
                reset(newActiveFilters);
                setForceHiddenFilter(null);
            }
            else {
                reset(newActiveFilters);
            }
        }
    };
    const handleClearFilters = (filters) => {
        if (filters) {
            initialFilters.current = filters;
        }
        const lockedFilters = options?.lockedFilters
            ? options.lockedFilters.reduce((lockedFiltersMap, lockedFilter) => {
                const lockedFilterValue = initialFilters.current[lockedFilter];
                return lockedFilterValue ? { ...lockedFiltersMap, [lockedFilter]: lockedFilterValue } : lockedFiltersMap;
            }, {})
            : {};
        const clearFiltersValue = filters || lockedFilters;
        options?.eventTracker?.(getFiltersDiff(appliedFilters, clearFiltersValue));
        setAppliedFilters(clearFiltersValue);
        options?.onClearAllFilters?.(clearFiltersValue);
        modalState.close();
        reset(clearFiltersValue, {
            keepDirty: false,
            keepDirtyValues: false,
            keepErrors: false,
        });
    };
    const handleApplyFilters = () => {
        options?.eventTracker?.(getFiltersDiff(appliedFilters, activeFilters));
        const filtersToApply = _omit(activeFilters, disabledFilters);
        setAppliedFilters(filtersToApply);
        options?.onFiltersChange?.(filtersToApply);
        modalState.close();
    };
    const handleResetFilter = (filterName) => {
        const currentFilterValue = appliedFilters[filterName];
        if (currentFilterValue) {
            setValue(filterName, currentFilterValue);
        }
        else {
            resetField(filterName);
        }
    };
    return {
        appliedFilters,
        clearAllFilters: handleClearFilters,
        filtersControl: control,
        filtersProps: {
            _forceHiddenFilter: forceHiddenFilter,
            activeFilters,
            activeFiltersList,
            appliedFiltersList,
            disabledKeys: disabledFilters,
            filtersModalState: modalState,
            onResetFilter: handleResetFilter,
            onClearFilter: handleClearFilterValue,
            onClearAllFilters: handleClearFilters,
            onApplyFilters: handleApplyFilters,
        },
        registerFilter: register,
        setFilterValue: setValue,
    };
};

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { areAccessRequirementsMet } from 'components/routing/MapDataToAccess/helpers';

import { accessRequirementsProp } from 'helpers/propTypes';

import RenderMapDataToAccess from './RenderMapDataToAccess';

/**
 *
 * @param state {ReduxState}
 * @param ownProps {Object}
 * @returns {{allowAccess: boolean}}
 */
const mapStateToProps = (state, ownProps) => {
  const { requirements } = ownProps;

  const allowAccess = areAccessRequirementsMet(state, requirements);

  return {
    allowAccess,
  };
};

/**
 * Connected wrapper for RenderMapDataToAccess.
 *
 * Determines if access to the requested content is allowed. If so, returns children.
 * If not, returns the result of props.renderNoAccess().
 *
 * The requirements prop is used to decide access. It takes an array of tuples, each
 * containing a selector function (how to get the value) and the target value (result
 * of selector should match this value to grant access).
 *
 * For example, if access is restricted to members of company with id '1234-a',
 * we can pass `requirements={[ [companyIdSelector, '1234-a'] ]}`.
 *
 * @param props
 * {{children: *, onAccessAllowed: Function, onAccessDenied: Function,
 * renderNoAccess: Function, requirements: [[Function, *]]}}
 * @return {*}
 *
 * @example
 * <MapDataToAccess
 *   onAccessAllowed={() => console.log('access allowed')}
 *   onAccessDenied={() => console.log('access denied')}
 *   renderNoAccess={() => <p>You will see me if denied</p>}
 *   requirements={[
 *     [selectorFn, 'allowed_value'],
 *     [selectorFn2, 'allowed_value_2'],
 *   ]}
 * >
 *   <MyProtectedContent />
 * </MapDataToAccess>
 */
const MapDataToAccess = connect(mapStateToProps)(RenderMapDataToAccess);

MapDataToAccess.propTypes = {
  children: PropTypes.node,
  onAccessAllowed: PropTypes.func,
  onAccessDenied: PropTypes.func,
  renderNoAccess: PropTypes.func.isRequired,
  requirements: accessRequirementsProp,
};

MapDataToAccess.defaultProps = {
  children: null,
  onAccessAllowed: undefined,
  onAccessDenied: undefined,
};

export default MapDataToAccess;

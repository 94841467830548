import { call, select, spawn, take } from 'redux-saga/effects';

import { maybeShowApprovalLevelErrors } from 'sagas/createItem/sideEffects';

import { itemSelector } from 'selectors/itemsSelectors';
import { existingItemSidePanelSelector } from 'selectors/sidePanelsSelector';

import * as types from 'types/item';

function* handleRequestErrors(action) {
  const submitErrors = action.payload.errors;
  const sidePanel = yield select(existingItemSidePanelSelector);
  const item = yield select(itemSelector, sidePanel.itemId);
  yield call(maybeShowApprovalLevelErrors, { submitErrors, item });
}

/**
 * Listens for redux actions related to errors.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([types.SUBMIT_EXISTING_ITEMS_FAILURE]);

    switch (action.type) {
      case types.SUBMIT_EXISTING_ITEMS_FAILURE:
        yield spawn(handleRequestErrors, action);
        break;

      default:
        break;
    }
  }
}

/**
 * Root existingItem saga.
 * @return {IterableIterator<*>}
 */
export default function* existingItem() {
  yield watch();
}

import * as types from 'types/sidePanels';

export const initialState = {
  company: undefined,
  form: undefined,
  initialPartnershipMember: undefined,
  initialValues: {},
  input: undefined,
  open: false,
  partnershipId: undefined,
  partnershipMember: undefined,
  submitHandler: undefined,
  type: undefined,
  ui: {},
};

/**
 * Reducer for the contact side panel. Provides open/close, meta, and UI storage so that the sidebar is more
 * customizable for different use-cases.
 * @function
 * @param {ReduxState} state
 * @param {ReduxAction} action
 * @returns {ReduxReducer}
 */
const contactSidePanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CONTACT_SIDE_PANEL_OPEN:
      return {
        ...state,
        company: action.payload.company,
        form: action.payload.form,
        initialPartnershipMember: action.payload.initialPartnershipMember,
        initialValues: action.payload.initialValues ?? initialState.initialValues,
        input: action.payload.input,
        item: action.payload.item,
        open: true,
        partnershipId: action.payload.partnershipId,
        partnershipMember: action.payload.partnershipMember,
        submitHandler: action.payload.submitHandler,
        type: action.payload.type,
        ui: action.payload.ui ?? initialState.ui,
      };

    case types.CONTACT_SIDE_PANEL_CLOSE:
      return initialState;

    default:
      return state;
  }
};

export default contactSidePanelReducer;

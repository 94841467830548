import React from 'react';
import { LinkNewPage } from 'components/link';
import { CONTACT_SUPPORT_LOWERCASE_LABEL } from 'constants/labels';
import { PLATFORM_EMAILS } from 'constants/platform';
import { DASHBOARD } from 'constants/routes';
import {} from 'enums/items';
import { getPaymentOrInvoiceOrInformalText } from 'helpers/items';
import { getJoinedPath } from 'helpers/routeHelpers';
import { getMailtoUrl } from 'helpers/urls';
const ServerErrorDefaultItemTimeoutMessage = ({ itemKind, ledgerName }) => {
    const noun = getPaymentOrInvoiceOrInformalText({ kind: itemKind });
    return (React.createElement("span", { className: "alert-error-text" },
        `Do not try to resubmit or recreate this ${noun} at this time. `,
        ledgerName ? (React.createElement(React.Fragment, null,
            `If you do not see this ${noun} in your `,
            React.createElement(LinkNewPage, { className: "font-color--primary", href: getJoinedPath(DASHBOARD) }, "dashboard"),
            ` in the next few minutes, check your ${ledgerName} account to see if it was created.`)) : (React.createElement(React.Fragment, null,
            `Wait a few minutes before checking your `,
            React.createElement(LinkNewPage, { className: "font-color--primary", href: getJoinedPath(DASHBOARD) }, "dashboard"),
            ` to see if this ${noun} was created. If you do not see it, you may try again to create it.`)),
        ` If you have any questions, please `,
        React.createElement(LinkNewPage, { className: "font-color--primary", href: getMailtoUrl(PLATFORM_EMAILS.SUPPORT) }, CONTACT_SUPPORT_LOWERCASE_LABEL),
        ` and we'll be happy to help.`));
};
export default ServerErrorDefaultItemTimeoutMessage;

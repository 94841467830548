import { createErrorReducer } from 'store/redux';

import { SUBMIT_TAX_INFO_FAILURE, SUBMIT_TAX_INFO_REQUEST, SUBMIT_TAX_INFO_SUCCESS } from 'types/taxes';

export const failureCases = [SUBMIT_TAX_INFO_FAILURE];
export const successCases = [SUBMIT_TAX_INFO_REQUEST, SUBMIT_TAX_INFO_SUCCESS];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

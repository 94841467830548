import { getItemKindFromExternalPath } from 'helpers/external';
import {} from 'interfaces/global';
import {} from './UpdatePaymentMethodFormInternational.types';
export const createSubmitHandler = (onSubmit) => async (formValues) => onSubmit({
    defaultReceivable: formValues.defaultReceivable,
    itemKind: getItemKindFromExternalPath(),
});
export const getUpdatedReceivableFormValue = ({ defaultReceivable, lastSubmittedFundingAccountId, previousLastSubmittedFundingAccountId, }) => {
    const justAddedNewAccount = previousLastSubmittedFundingAccountId !== lastSubmittedFundingAccountId;
    if (lastSubmittedFundingAccountId && justAddedNewAccount) {
        const updatedReceivable = {
            ...defaultReceivable,
            fundingAccount: lastSubmittedFundingAccountId,
        };
        return {
            shouldUpdate: true,
            updatedReceivable,
        };
    }
    return { shouldUpdate: false };
};

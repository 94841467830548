import React from 'react';
import { CurrencyAmountTextPlain, FlexCol } from 'components';
import { getItemPaymentTerms } from 'helpers/items';
import { hasLength } from 'helpers/utility';
import { ExternalBlockItem } from 'modules/external/ExternalBlocksSection';
import { ExternalReceiptAttachments, ExternalReceiptItemEntry, } from 'modules/external/externalReceiptV2/ExternalReceiptSingleItem/components';
import {} from './ExternalReceiptPayableItemEntries.types';
const ExternalReceiptPayableItemEntries = ({ amount, attachments, currencyCode, partnerReference, paymentTerms, reference, }) => (React.createElement(ExternalBlockItem, null,
    React.createElement(ExternalReceiptItemEntry, { label: "Invoice No.", value: reference }),
    React.createElement(ExternalReceiptItemEntry, { label: "Amount", value: React.createElement(CurrencyAmountTextPlain, { amount: amount, currencyCode: currencyCode }) }),
    React.createElement(ExternalReceiptItemEntry, { label: "Reference", value: partnerReference }),
    React.createElement(ExternalReceiptItemEntry, { label: "Payment terms", value: getItemPaymentTerms(paymentTerms) }),
    hasLength(attachments) && (React.createElement(FlexCol, { className: "margin-top--m-large" },
        React.createElement(ExternalReceiptAttachments, { attachments: attachments })))));
export default ExternalReceiptPayableItemEntries;

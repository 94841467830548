import { loginRoutine, ssoLoginRoutine, ssoHandleRedirectRoutine } from 'actions/routines/auth';
import { companySettingsSSOByNamespaceFetchRoutine } from 'actions/routines/sso';

import { AuthActionType } from 'enums/auth';

import { gatherReducerCasesFromRoutines } from 'helpers/routine';

import { createErrorReducer } from 'store/redux';

export const { failureCases, requestCases, successCases } = gatherReducerCasesFromRoutines([
  loginRoutine,
  ssoLoginRoutine,
  ssoHandleRedirectRoutine,
  companySettingsSSOByNamespaceFetchRoutine,
]);

export const finishCases = [...requestCases, ...successCases, AuthActionType.CLEAR_LOGIN_ERROR_STATE];

export default createErrorReducer(finishCases, failureCases);

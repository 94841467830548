import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';
import { toggleSkipStepModal, changeView, changeSaveStatus } from 'actions/signUpFlow';
import { Modal } from 'components';
import { colors } from 'constants/styles';
import { currentCompanyVerificationUpdateAllowedSelector } from 'selectors/currentCompanySelectors';
import { currentMembershipVerificationDocumentUpdateAllowedSelector, currentMembershipVerificationUpdateAllowedSelector, } from 'selectors/membershipsSelector';
import { activeViewSelector, getNextView, getSkipStepModal } from 'selectors/signupSelectors';
import { businessVerificationViews, representativeVerificationViews, signupFlowForm, views } from '../../constants';
import { clearViewFields } from '../../helpers/clearViewFields';
import SkipStepModalBody from './SkipStepModalBody';
import {} from './types/SkipStepModal.types';
const SkipStepModal = ({ invalid }) => {
    const dispatch = useDispatch();
    const activeView = useSelector(activeViewSelector);
    const isSkipModalActive = useSelector(getSkipStepModal);
    const nextView = useSelector(getNextView);
    const updateAllowedCurrentCompany = useSelector(currentCompanyVerificationUpdateAllowedSelector);
    const updatedAllowedMembership = useSelector(currentMembershipVerificationUpdateAllowedSelector);
    const updateAllowedDocumentMembership = useSelector(currentMembershipVerificationDocumentUpdateAllowedSelector);
    if (activeView === views.uploadDocument ||
        (activeView === views.representativeUploadDocument && !updateAllowedDocumentMembership) ||
        (businessVerificationViews.includes(activeView) &&
            activeView !== views.uploadDocument &&
            !updateAllowedCurrentCompany) ||
        (representativeVerificationViews.includes(activeView) &&
            activeView !== views.representativeUploadDocument &&
            !updatedAllowedMembership)) {
        return null;
    }
    const onClose = () => {
        dispatch(toggleSkipStepModal());
    };
    const goToNextView = () => {
        onClose();
        dispatch(changeView(nextView));
    };
    const onSkipStep = () => {
        goToNextView();
        dispatch(changeSaveStatus.saved());
        clearViewFields(activeView);
    };
    const onSaveChanges = () => {
        dispatch(submit(signupFlowForm));
        goToNextView();
    };
    return (React.createElement(Modal, { body: React.createElement(SkipStepModalBody, { invalid: invalid, onClose: onClose, onSaveChanges: onSaveChanges, onSkipStep: onSkipStep }), hasInlineFooter: true, hideHeader: true, modalState: isSkipModalActive, onCloseModal: onSkipStep, overrideStyle: {
            content: {
                maxWidth: '500px',
                backgroundColor: colors.colorWhiteHex,
            },
        } }));
};
export default SkipStepModal;

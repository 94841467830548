import { RoutableEvents } from '../index';
import { LOCALSTORAGE_DATABASE_STATUS, LOCALSTORAGE_RECORD_CLEARED, LOCALSTORAGE_RECORD_DELETE, LOCALSTORAGE_RECORD_DELETE_MULTIPLE, LOCALSTORAGE_RECORD_UPDATE, LOCALSTORAGE_RECORD_UPDATE_MULTIPLE, } from './localstorage.service.types';
const promisifyRequest = (request) => {
    return new Promise((resolve, reject) => {
        request.oncomplete = request.onsuccess = () => resolve(request.result);
        request.onabort = request.onerror = () => reject(request.error);
    });
};
const createStorage = (dbName, localStorageDbName) => {
    RoutableEvents.Publish(LOCALSTORAGE_DATABASE_STATUS, { open: false });
    const storageHandle = indexedDB.open(dbName);
    storageHandle.onupgradeneeded = () => storageHandle.result.createObjectStore(localStorageDbName);
    const storageOpenTransaction = promisifyRequest(storageHandle);
    return (txMode, callback) => storageOpenTransaction.then((db) => {
        RoutableEvents.Publish(LOCALSTORAGE_DATABASE_STATUS, { open: true });
        return callback(db
            .transaction(localStorageDbName, txMode)
            .objectStore(localStorageDbName));
    });
};
let defaultGetLocalStorageFunc;
const createLocalStorage = (dbName, localStorageDbName) => {
    defaultGetLocalStorageFunc = createStorage(dbName, localStorageDbName);
    return true;
};
const defaultGetLocalStorage = () => {
    if (!defaultGetLocalStorageFunc) {
        defaultGetLocalStorageFunc = createStorage('routable-local-storage', 'storage');
    }
    return defaultGetLocalStorageFunc;
};
const getItem = (key, customLocalStorage = defaultGetLocalStorage()) => {
    return customLocalStorage('readonly', (localStorageDb) => {
        return promisifyRequest(localStorageDb.get(key));
    });
};
const setItem = (key, value, customLocalStorage = defaultGetLocalStorage()) => {
    return customLocalStorage('readwrite', async (localStorageDb) => {
        localStorageDb.put(value, key);
        RoutableEvents.Publish(LOCALSTORAGE_RECORD_UPDATE, {
            key: key,
            value: value,
        });
        return promisifyRequest(localStorageDb.transaction);
    });
};
const setMultipleItems = (entries, customLocalStorage = defaultGetLocalStorage()) => {
    return customLocalStorage('readwrite', (localStorageDb) => {
        entries.forEach((entry) => localStorageDb.put(entry[1], entry[0]));
        RoutableEvents.Publish(LOCALSTORAGE_RECORD_UPDATE_MULTIPLE, entries);
        return promisifyRequest(localStorageDb.transaction);
    });
};
const getMultipleItems = (keys, customLocalStorage = defaultGetLocalStorage()) => {
    return customLocalStorage('readonly', (localStorageDb) => Promise.all(keys.map((key) => promisifyRequest(localStorageDb.get(key)))));
};
const mapStorageUpdate = (key, updater, customLocalStorage = defaultGetLocalStorage()) => {
    return customLocalStorage('readwrite', (localStorageDb) => new Promise((resolve, reject) => {
        localStorageDb.get(key).onsuccess = function () {
            try {
                localStorageDb.put(updater(this.result), key);
                resolve(promisifyRequest(localStorageDb.transaction));
            }
            catch (err) {
                reject(err);
            }
        };
    }));
};
const removeItem = (key, customLocalStorage = defaultGetLocalStorage()) => {
    return customLocalStorage('readwrite', (localStorageDb) => {
        localStorageDb.delete(key);
        RoutableEvents.Publish(LOCALSTORAGE_RECORD_DELETE, { key: key });
        return promisifyRequest(localStorageDb.transaction);
    });
};
const removeMultipleItems = (keys, customLocalStorage = defaultGetLocalStorage()) => {
    return customLocalStorage('readwrite', (localStorageDb) => {
        keys.forEach((key) => localStorageDb.delete(key));
        RoutableEvents.Publish(LOCALSTORAGE_RECORD_DELETE_MULTIPLE, keys);
        return promisifyRequest(localStorageDb.transaction);
    });
};
const clear = (customLocalStorage = defaultGetLocalStorage()) => {
    return customLocalStorage('readwrite', (localStorageDb) => {
        localStorageDb.clear();
        RoutableEvents.Publish(LOCALSTORAGE_RECORD_CLEARED, {});
        return promisifyRequest(localStorageDb.transaction);
    });
};
const storageCursor = (localStorageDb, callback) => {
    localStorageDb.openCursor().onsuccess = function () {
        if (!this.result) {
            return;
        }
        callback(this.result);
        this.result.continue();
    };
    return promisifyRequest(localStorageDb.transaction);
};
const allKeys = (customLocalStorage = defaultGetLocalStorage()) => {
    return customLocalStorage('readonly', (localStorageDb) => {
        if (localStorageDb.getAllKeys) {
            return promisifyRequest(localStorageDb.getAllKeys());
        }
        const items = [];
        return storageCursor(localStorageDb, (cursor) => items.push(cursor.key)).then(() => items);
    });
};
const allItems = (customLocalStorage = defaultGetLocalStorage()) => {
    return customLocalStorage('readonly', (localStorageDb) => {
        if (localStorageDb.getAll) {
            return promisifyRequest(localStorageDb.getAll());
        }
        const items = [];
        return storageCursor(localStorageDb, (cursor) => items.push(cursor.value)).then(() => items);
    });
};
const getAllStorageItems = (customLocalStorage = defaultGetLocalStorage()) => {
    return customLocalStorage('readonly', (localStorageDb) => {
        if (localStorageDb.getAll && localStorageDb.getAllKeys) {
            return Promise.all([
                promisifyRequest(localStorageDb.getAllKeys()),
                promisifyRequest(localStorageDb.getAll()),
            ]).then(([keys, values]) => keys.map((key, i) => [key, values[i]]));
        }
        const items = [];
        return customLocalStorage('readonly', (store) => storageCursor(store, (cursor) => items.push([cursor.key, cursor.value])).then(() => items));
    });
};
export const RoutableLocalStorage = {
    createLocalStorage: createLocalStorage,
    getItem: getItem,
    setItem: setItem,
    setMultipleItems: setMultipleItems,
    getMultipleItems: getMultipleItems,
    mapStorageUpdate: mapStorageUpdate,
    removeItem: removeItem,
    removeMultipleItems: removeMultipleItems,
    clear: clear,
    allKeys: allKeys,
    allItems: allItems,
    getAllStorageItems: getAllStorageItems,
};
export const LocalStorageTypes = {
    LOCALSTORAGE_DATABASE_STATUS,
    LOCALSTORAGE_RECORD_CLEARED,
    LOCALSTORAGE_RECORD_DELETE,
    LOCALSTORAGE_RECORD_DELETE_MULTIPLE,
    LOCALSTORAGE_RECORD_UPDATE,
    LOCALSTORAGE_RECORD_UPDATE_MULTIPLE,
};

import { combineReducers } from 'redux';

import { name as addTaxInfoSidePanelName, reducer as addTaxInfoSidePanelReducer } from 'ducks/tax/taxInput';

import * as sidePanelNames from 'constants/sidePanels';

import addOrUpdateVendorRegisteredAddressSideSheetReducer from 'reducers/sidePanelsReducer/addOrUpdateVendorRegisteredAddressReducer';
import addPartnerFundingAccountReducer from 'reducers/sidePanelsReducer/addPartnerFundingAccountReducer';
import addVendorRegisteredAddressSideSheetForCreateItemReducer from 'reducers/sidePanelsReducer/addVendorRegisteredAddressForCreateItemReducer';
import contactSidePanelReducer from 'reducers/sidePanelsReducer/contactSidePanelReducer';
import editCompanyGeneralInfoReducer from 'reducers/sidePanelsReducer/editCompanyGeneralInfoReducer';
import existingItemSidePanelReducer from 'reducers/sidePanelsReducer/existingItemSidePanelReducer';
import existingPaymentSidePanelReducer from 'reducers/sidePanelsReducer/existingPaymentSidePanelReducer';
import itemThreadTagSidePanelReducer from 'reducers/sidePanelsReducer/itemThreadTagSidePanelReducer';
import manuallyAddBankAccountSidePanelReducer from 'reducers/sidePanelsReducer/manuallyAddBankAccountSidePanelReducer';

const sidePanelsReducer = combineReducers({
  [sidePanelNames.sidePanelNameAddPartnerFundingAccount]: addPartnerFundingAccountReducer,
  [sidePanelNames.sidePanelNameContact]: contactSidePanelReducer,
  [sidePanelNames.sidePanelNameExistingItem]: existingItemSidePanelReducer,
  [sidePanelNames.sidePanelNameExistingPayment]: existingPaymentSidePanelReducer,
  [sidePanelNames.sidePanelNameItemThreadTags]: itemThreadTagSidePanelReducer,
  [sidePanelNames.sidePanelNameManuallyAddBankAccount]: manuallyAddBankAccountSidePanelReducer,
  [sidePanelNames.sidePanelNameAddOrUpdateVendorRegisteredAddress]: addOrUpdateVendorRegisteredAddressSideSheetReducer,
  [sidePanelNames.sidePanelNameAddVendorRegisteredAddressInForm]:
    addVendorRegisteredAddressSideSheetForCreateItemReducer,
  [sidePanelNames.sidePanelNameEditCompanyGeneralInfo]: editCompanyGeneralInfoReducer,
  [addTaxInfoSidePanelName]: addTaxInfoSidePanelReducer,
});

export default sidePanelsReducer;

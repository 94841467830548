import styled from 'styled-components';
import Icon from 'components/icon';
import { Image } from 'components/media';
import {} from './types/DynamicTextBrowser.types';
export const Browser = styled.div `
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 37.5rem;
  max-height: 19.5rem;
  margin: 16.5rem auto 0 auto;
  box-shadow: 6.58615px 6.58615px 19.7584px rgba(0, 0, 0, 0.05), 6.58615px 6.58615px 52.6892px rgba(69, 78, 255, 0.13);
  border-radius: 0.5rem;
  overflow: hidden;
`;
export const TabRow = styled.div `
  display: flex;
  gap: 2.25rem;
  margin-top: 0.875rem;
  align-items: flex-start;
`;
export const WindowControls = styled.div `
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
  padding: 0.5rem 0 0 0.75rem;
`;
export const WindowControlButton = styled.div `
  border-radius: 50%;
  height: 0.75rem;
  width: 0.75rem;
  background-color: ${({ color }) => color};
`;
export const OpenTab = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  background-color: var(--white-antiflash);
  color: var(--eerie-black);
  font-weight: 700;
  width: 10.625rem;
  padding: 0.625rem 5rem 0.625rem 1.25rem;
  border-radius: 1.25rem 1.25rem 0 0;
  font-size: 0.813rem;
`;
export const TabLogo = styled(Image) `
  height: 1rem;
  width: 1rem;
`;
export const ControlsRow = styled.div `
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  background-color: var(--white-antiflash);
  padding: 1.375rem 0 1.375rem 1.25rem;
  color: var(--eerie-black);
  font-weight: 500;
  width: 100%;
  align-items: center;
`;
export const PageControls = styled.div `
  width: 20%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;
export const NavigationIcon = styled(Icon) `
  color: var(--grey--dark);
`;
export const AddressBar = styled.div `
  width: 80%;
  background-color: var(--white);
  font-size: 0.813rem;
  font-weight: 600;
  padding: 0.5rem 0 0.5rem 1.125rem;
  border-radius: 1rem 0 0 1rem;
  flex-grow: 1;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
`;
export const Namespace = styled.span `
  color: ${({ isValid }) => (isValid ? `var(--blue-ultramarine)` : `var(--fire-opal)`)};
`;
export const GrayBar = styled.div `
  background-color: var(--white-antiflash--bold);
  height: 1.125rem;
  flex-grow: 1;
  width: ${({ type }) => (type === 'small' ? '50%' : '100%')};
`;
export const ContentRow = styled.div `
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  margin-left: 2.813rem;
  border-top: 0.078rem solid var(--white-azureish);
  border-left: 0.078rem solid var(--white-azureish);
`;
export const ContentIcon = styled(Icon) `
  color: #e1e5f4;
`;
export const BarContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 0.313rem;
  flex-grow: 1;
  padding-right: 1rem;
`;
export const MockContentContainer = styled.div `
  width: 100%;
`;
export const MockContentHeader = styled.div `
  padding: 1.25rem;
  width: 100%;
  border-bottom: 0.078rem solid var(--white-azureish);
`;
export const MockContentBubble = styled.div `
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  padding: 2rem 0 2.25rem 1.25rem;
  border-top-left-radius: 0.5rem;
  gap: 0.625rem;
  min-width: 100%;
`;
export const ContentIconContainer = styled.div `
  justify-content: flex-start;
`;

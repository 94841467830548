import { CompaniesListCellDataKeys } from 'constants/tables';
import {} from 'enums/items';
import { isItemAmountZero, isItemStatusCanceled } from 'helpers/items';
import { AmountTextProps } from '../constants';
const getAmountProps = (rowData, dataKey) => {
    const isAmountZero = isItemAmountZero(rowData, dataKey);
    const isItemCanceled = isItemStatusCanceled(rowData);
    const isOutstanding = dataKey === CompaniesListCellDataKeys.OutstandingSum;
    if (isItemCanceled) {
        return AmountTextProps.CancelledItem;
    }
    if (isAmountZero) {
        return AmountTextProps.ZeroAmount;
    }
    if (isOutstanding && !isAmountZero) {
        return AmountTextProps.OutstandingAmount;
    }
    return AmountTextProps.Default;
};
export default getAmountProps;

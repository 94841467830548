import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { usePrevious } from 'react-use';
import { useFormattedCountries } from 'components/form/CountryField/hooks';
import { isCompanyTypeBusiness } from 'helpers/currentCompany';
import { isObject } from 'helpers/utility';
import { useCountryCodesContext } from 'hooks';
import { RenderSection } from './components';
import { w8FormSchema, w9FormSchema } from './TaxFormRenderer.constants';
import { TaxFormOptionsContext } from './TaxFormRenderer.context';
import { taxFormEntryType, taxFormType, w8BenEFormBaseModel, w8BenFormBaseModel, w8FormModel, w9FormBaseModel, w9FormModel, } from './TaxFormRenderer.models';
const TaxFormRenderer = ({ defaultValues, entryType = taxFormEntryType.Enum.external, formType, formId, isSignatureAllowed = true, onFormSubmitStateChange, onFormValidStateChange, onSubmit, showCompanyTypeField = true, }) => {
    const schema = useMemo(() => (formType === taxFormType.Enum.W8 ? w8FormSchema : w9FormSchema), [formType]);
    const resolver = useMemo(() => (formType === taxFormType.Enum.W8 ? zodResolver(w8FormModel) : zodResolver(w9FormModel)), [formType]);
    const methods = useForm({
        defaultValues,
        mode: 'onBlur',
        resolver,
    });
    const { handleSubmit, formState: { isSubmitting, isValid }, setError, watch, } = methods;
    const countries = useCountryCodesContext();
    const wasSubmitting = usePrevious(isSubmitting);
    const wasValid = usePrevious(isValid);
    const companyType = watch('companyType');
    const shouldRenderBaseSection = formType === taxFormType.Enum.W8 || showCompanyTypeField;
    const { prioritizedCountries } = useFormattedCountries(countries);
    const handleSubmitTaxForm = async (values) => {
        const errors = await onSubmit(values);
        if (errors) {
            Object.entries(errors).forEach(([field, error]) => {
                const w8FormBaseModel = isCompanyTypeBusiness(companyType) ? w8BenEFormBaseModel : w8BenFormBaseModel;
                const model = formType === taxFormType.Enum.W8 ? w8FormBaseModel : w9FormBaseModel;
                const parsedField = model.keyof().safeParse(field);
                if (parsedField.success) {
                    if (isObject(error)) {
                        Object.values(error).forEach((fieldError, idx) => {
                            setError(`${parsedField.data}.${idx}`, { type: 'apiError', message: fieldError }, { shouldFocus: true });
                        });
                    }
                    else {
                        setError(parsedField.data, { type: 'apiError', message: error }, { shouldFocus: true });
                    }
                }
            });
        }
    };
    useEffect(() => {
        if (wasSubmitting !== isSubmitting) {
            onFormSubmitStateChange?.(isSubmitting);
        }
    }, [isSubmitting, wasSubmitting, onFormSubmitStateChange]);
    useEffect(() => {
        if (wasValid !== isValid) {
            onFormValidStateChange?.(isValid);
        }
    }, [isValid, wasValid, onFormValidStateChange]);
    return (React.createElement(TaxFormOptionsContext.Provider, { value: {
            countries: prioritizedCountries,
            entryType,
            formType,
            isSignatureAllowed,
            showCompanyTypeField,
        } },
        React.createElement(FormProvider, { ...methods },
            React.createElement("form", { id: formId, onSubmit: handleSubmit(handleSubmitTaxForm) },
                shouldRenderBaseSection && React.createElement(RenderSection, { fieldGroups: ['base'] }),
                !!companyType &&
                    schema.map((section) => (React.createElement(RenderSection, { fieldGroups: section.fieldGroups, getTitle: section.getTitle, key: section.fieldGroups.reduce((acc, val) => `${acc}_${val}`, ''), title: section.title })))))));
};
export default TaxFormRenderer;

import React from 'react';
import { ButtonV2 } from 'components';
import { colors, sizes } from 'constants/styles';
import { Intent } from 'constants/ui';
const CompanyAddressDataCardActionButton = ({ dataTestId, icon, isDisabled, onClick, }) => {
    if (!onClick) {
        return null;
    }
    return (React.createElement(ButtonV2, { className: "company-address-data-card__expandable__actions__button", dataTestId: dataTestId, intent: Intent.NEUTRAL, isDisabled: isDisabled, isIconButton: true, leftIconColor: colors.colorGreyXDarkHex, leftIconName: icon, leftIconSize: sizes.iconSizes.LARGE, onClick: onClick }));
};
export default CompanyAddressDataCardActionButton;

import { SubmissionError } from 'redux-form';
import { all, call, put, spawn, take } from 'redux-saga/effects';

import { parseServerErrorsV2 } from 'helpers/errors';
import { hasLength } from 'helpers/utility';

import * as types from 'types/errors';

import * as api from './api';

/**
 * Handle redux-form errors.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* handleFormErrors(action) {
  const {
    payload: { actionCreator, submitErrors },
  } = action;

  let errors;

  if (submitErrors) {
    if (submitErrors.fields) {
      errors = submitErrors.fields;
    } else if (submitErrors.general) {
      /**
       * redux-form: a general, form-wide error can be returned by using the "_error" key
       * {@link https://redux-form.com/6.0.1/examples/submitvalidation SubmitValidation}
       */
      errors = { _error: submitErrors.general.detail };
    }
  }

  yield put(actionCreator(new SubmissionError(errors)));
}

/**
 * Handle API request errors.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* handleRequestErrors(action) {
  const {
    payload: { actionCreator, errorData = {} },
  } = action;

  const { options, parsedErrors } = errorData;

  const { additionalActions, forbiddenErrors, generalErrors } = parseServerErrorsV2(parsedErrors, options);

  const errorsToHandle = generalErrors || forbiddenErrors;

  if (options?.report && parsedErrors instanceof Error) {
    yield call(api.reportError, parsedErrors);
  }

  if (actionCreator && errorsToHandle) {
    yield put(actionCreator({ errors: errorsToHandle }));
  } else if (actionCreator) {
    yield put(actionCreator({ errors: parsedErrors }));
  }

  if (hasLength(additionalActions)) {
    yield all(additionalActions.map((additionalAction) => put(additionalAction)));
  }
}

/**
 * Listens for redux actions related to errors.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([types.HANDLE_REQUEST_ERRORS, types.SET_FORM_ERRORS]);

    switch (action.type) {
      case types.HANDLE_REQUEST_ERRORS:
        yield spawn(handleRequestErrors, action);
        break;

      case types.SET_FORM_ERRORS:
        yield spawn(handleFormErrors, action);
        break;

      default:
        break;
    }
  }
}

/**
 * Root errors saga.
 * @return {IterableIterator<*>}
 */
export default function* errs() {
  yield watch();
}

const getHeaders = (isStyleItem) => [
    {
        columnId: 'id',
        caption: '',
        isSelectObj: true,
        initialSettings: {
            width: 'ColumnWidth1',
        },
    },
    {
        columnId: 'productName',
        caption: 'Product/Service',
        initialSettings: {
            width: 'ColumnWidth6',
        },
    },
    {
        columnId: 'description',
        caption: 'Description',
        initialSettings: {
            width: 'ColumnWidth6',
        },
    },
    isStyleItem && {
        columnId: 'unit_price',
        caption: 'Rate',
        hAlign: 'right',
        initialSettings: {
            width: 'ColumnWidth4',
        },
    },
    isStyleItem && {
        columnId: 'quantity',
        caption: 'Unbilled qty',
        hAlign: 'center',
        initialSettings: {
            width: 'ColumnWidth3',
        },
    },
    {
        columnId: 'amount',
        caption: 'Unbilled amt',
        hAlign: 'right',
        initialSettings: {
            width: 'ColumnWidth4',
        },
    },
].filter(Boolean);
export const getLinkBillLineToPoLineTableConfig = (isStyleItem) => ({
    tableName: 'linkBillLineToPoLineTable',
    type: 'linkBillLineToPoLine',
    headers: getHeaders(isStyleItem),
});
export const getAddLineFromPoTableConfig = (isStyleItem) => ({
    tableName: 'addLineFromPoTableTable',
    type: 'addLineFromPo',
    headers: getHeaders(isStyleItem),
});

import PropTypes from 'prop-types';
import React from 'react';

import { Tag } from 'components/tag';

import { getDocumentStatusTagTypeAndText } from 'helpers/document';

/**
 * Component rendering Document status Tag
 * @param {ComponentProps} props
 * @param {string} props.documentStatus
 * @returns {StatelessComponent}
 */
const DocumentStatus = ({ documentStatus }) => {
  const { text, type } = getDocumentStatusTagTypeAndText(documentStatus);

  return (
    <Tag hasIcon={false} isClosable={false} readOnly type={type}>
      {text}
    </Tag>
  );
};

DocumentStatus.propTypes = {
  documentStatus: PropTypes.string.isRequired,
};

export default DocumentStatus;

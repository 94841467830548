import PropTypes from 'prop-types';
import React from 'react';

import { BankAccountViewType } from 'constants/ui';

import {
  isFundingAccountDeleted,
  isFundingAccountDisabled,
  isFundingSourceProviderClassAddress,
  isFundingSourceProviderClassBank,
} from 'helpers/funding';
import { areHintsDisplayedForBankAccountViewType } from 'helpers/ui';

import {
  AddressAccountHintSections,
  BankAccountHintSections,
} from 'modules/fundingAccount/bankAccount/components/AccountHintSection/components';

const AccountHintSection = ({ fundingAccount, fundingSources, viewType }) => {
  if (isFundingAccountDeleted(fundingAccount)) {
    return null;
  }

  if (isFundingAccountDisabled(fundingAccount)) {
    return null;
  }

  if (!areHintsDisplayedForBankAccountViewType(viewType)) {
    return null;
  }

  const sourceSections = [];

  fundingSources.forEach((source) => {
    if (isFundingSourceProviderClassBank(source)) {
      sourceSections.push(<BankAccountHintSections key={`bank-${source.id}`} source={source} />);
    }

    if (isFundingSourceProviderClassAddress(source)) {
      sourceSections.push(<AddressAccountHintSections key={`address-${source.id}`} source={source} />);
    }
  });

  return sourceSections;
};

AccountHintSection.propTypes = {
  fundingAccount: PropTypes.shape(),
  fundingSources: PropTypes.arrayOf(PropTypes.shape),
  viewType: PropTypes.oneOf(Object.values(BankAccountViewType)),
};

AccountHintSection.defaultProps = {
  fundingAccount: {},
  fundingSources: [],
  viewType: BankAccountViewType.FULL,
};

export default AccountHintSection;

import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/text';

/**
 * Renders text to be used as the popover title.
 * @param {*} children
 * @return {StatelessComponent}
 * @constructor
 */
const PopoverSelectTitle = ({ children }) => <Text.Bold>{children}</Text.Bold>;

PopoverSelectTitle.propTypes = {
  children: PropTypes.node,
};

PopoverSelectTitle.defaultProps = {
  children: undefined,
};

export default PopoverSelectTitle;

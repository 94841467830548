export var TrackPageName;
(function (TrackPageName) {
    TrackPageName["DASHBOARD_PAYABLES"] = "Dashboard Payables";
    TrackPageName["SETTINGS"] = "Settings";
    TrackPageName["BILL"] = "Bill";
    TrackPageName["PAYABLE"] = "Payable";
    TrackPageName["RECEIVABLE"] = "Receivable";
})(TrackPageName || (TrackPageName = {}));
export var TrackEventName;
(function (TrackEventName) {
    TrackEventName["QUICKSWITCH_MODAL_OPENED"] = "Quick Switch: Modal Opened";
    TrackEventName["QUICKSWITCH_MODAL_CLOSED"] = "Quick Switch: Modal Closed";
    TrackEventName["CSV_EXPORT_CLICKED"] = "CSV Export: Button Click";
    TrackEventName["CSV_EXPORT_CANCELLED"] = "CSV Export: Cancelled";
    TrackEventName["SSO_ENABLED"] = "SSO: Google Turned On";
    TrackEventName["SSO_DISABLED"] = "SSO: Google Turned Off";
    TrackEventName["SSO_RE_ENABLED"] = "SSO: Google Turned Back On";
    TrackEventName["CREATE_COMPANY_NO_REACHABLE_VENDOR"] = "Companies: No reachable contact for vendor";
    TrackEventName["CODING_TIME_PAGE_LOADED"] = "Coding Time: Page loaded";
    TrackEventName["CODING_TIME_FORM_SUBMITTED"] = "Coding Time: Form submitted";
    TrackEventName["CODING_TIME_OCR_FIELD_CHANGED"] = "Coding Time: OCR field changed";
    TrackEventName["BULK_ACTION_SUBMITTED"] = "Bulk Action: Success";
    TrackEventName["RESYNC_EVENT"] = "Re-Sync";
    TrackEventName["IMPORT_ITEMS_FROM_LEDGER_TOGGLE_MATCHED_PARTNERSHIPS_CLICKED"] = "Import items from ledger: matched partnerships toggle clicked";
    TrackEventName["FILE_VIEWER_FAILED_LOADING_PDF"] = "File Viewer: Failed Loading PDF";
    TrackEventName["FILE_VIEWER_NO_URL_PROVIDED"] = "File Viewer: No URL Provided";
    TrackEventName["FINANCIAL_TAX_MANAGEMENT_VIEWED"] = "Financial: Tax management Page Viewed";
    TrackEventName["PAYABLE_APPROVALS_VIEWED"] = "Payable Approvals: Policy Page Viewed";
    TrackEventName["PAYABLE_APPROVALS_CREATE_NEW_RULE"] = "Payable Approvals: Create Rule Clicked";
    TrackEventName["PAYABLE_APPROVALS_EDIT_RULE"] = "Payable Approvals: Edit Rule Clicked";
    TrackEventName["PAYABLE_APPROVALS_DELETE_RULE"] = "Payable Approvals: Delete Rule Clicked";
    TrackEventName["PAYABLE_APPROVALS_RULE_MOVED"] = "Payable Approvals: Moved Rule";
    TrackEventName["PAYABLE_APPROVALS_SAVE_ERROR"] = "Payable Approvals: Save Error Notification Viewed";
    TrackEventName["PAYABLE_APPROVALS_EXIT_WITHOUT_SAVING"] = "Payable Approvals: Exit without Saving Confirmation Clicked";
    TrackEventName["PAYABLE_APPROVALS_SETTINGS_VIEWED"] = "Payable Approvals: Settings Page Viewed";
    TrackEventName["PAYABLE_APPROVALS_SETTINGS_EDITED"] = "Payable Approvals: Settings Edited";
    TrackEventName["PAYABLE_EDIT_CANCELLED"] = "Payable: Payable Edit Cancelled";
    TrackEventName["PAYABLE_MANUAL_APPROVER_CLICKED"] = "Payable: Manually add approver clicked";
    TrackEventName["REFRESH_PO_MATCHING"] = "Payable: Refresh PO matching";
    TrackEventName["TAX_TOOLS_TABLE_FILTER_ON"] = "Tax tools table: Filter on";
    TrackEventName["TAX_TOOLS_TABLE_FILTER_OFF"] = "Tax tools table: Filter off";
    TrackEventName["VENDORS_TABLE_FILTER_ON"] = "Vendors table: Filter on";
    TrackEventName["VENDORS_TABLE_FILTER_OFF"] = "Vendors table: Filter off";
})(TrackEventName || (TrackEventName = {}));

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timeZone from 'dayjs/plugin/timezone';
import toObject from 'dayjs/plugin/toObject';
import dayjsPluginUTC from 'dayjs/plugin/utc';
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(dayjsPluginUTC);
dayjs.extend(isSameOrBefore);
dayjs.extend(timeZone);
dayjs.extend(toObject);
dayjs.extend(calendar);
dayjs.extend(relativeTime);

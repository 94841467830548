import { getURLWithQueryParams } from 'helpers/urls';

import { partnershipsEndpoint } from './partnershipsEndpoints';

/**
 * Get URL for PartnershipMembers in a Partnership.
 * @param {string} partnershipId
 * @returns {string}
 */
export const partnershipMemberEndpoint = (partnershipId) => `${partnershipsEndpoint}${partnershipId}/members/`;

/**
 * Get URL for PartnershipMembers in a Partnership with query params.
 * @param {string} partnershipId
 * @param {ObjectMaybe} queryParams
 * @returns {string}
 */
export const getPartnershipMembersEndpoint = (partnershipId, queryParams) =>
  getURLWithQueryParams(partnershipMemberEndpoint(partnershipId), queryParams);

/**
 * Get URL for a PartnershipMember in a Partnership
 * @param {string} partnershipId
 * @param {string} partnershipMemberId
 * @returns {string}
 */
export const getSinglePartnershipMemberEndpoint = (partnershipId, partnershipMemberId) =>
  `${partnershipMemberEndpoint(partnershipId)}${partnershipMemberId}/`;

import React from 'react';

/**
 * Passes a partnership through context. Useful everywhere we need a reference back to a certain partnership.
 * @type {React.Context}
 */
const PartnershipContext = React.createContext({});

PartnershipContext.displayName = 'PartnershipContext';

export default PartnershipContext;

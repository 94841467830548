import * as routines from 'actions/routines/permissionGroups';

import { createErrorReducer } from 'store/redux';

export const failureCases = [routines.fetchPermissionGroupsRoutine.FAILURE];

export const successCases = [
  routines.fetchPermissionGroupsRoutine.REQUEST,
  routines.fetchPermissionGroupsRoutine.SUCCESS,
];

const errorsReducer = createErrorReducer(successCases, failureCases);

export default errorsReducer;

import classNames from 'classnames';
import React from 'react';
import { Icon, IconNames } from 'components/icon';
import { LinkNewPage } from 'components/link';
import { PLATFORM_URL } from 'constants/platform';
import { LEGAL, SECURITY } from 'constants/routes';
import {} from './SafeAndSecureBadge.types';
import './SafeAndSecureBadge.scss';
const SafeAndSecureBadge = ({ className, includeLink }) => {
    const badge = (React.createElement("span", { className: classNames('badge__safe-and-secure', {
            [className]: !!className,
        }) },
        React.createElement(Icon, { icon: IconNames.BADGE }),
        "Safe & secure"));
    if (includeLink) {
        return React.createElement(LinkNewPage, { href: `${PLATFORM_URL}${LEGAL}/${SECURITY}` }, badge);
    }
    return badge;
};
export default SafeAndSecureBadge;

import PropTypes from 'prop-types';
import React from 'react';

import { IconNames } from 'components/icon';
// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { InfoCard, InfoCardBottom, InfoCardHeader, InfoCardTop } from 'components/infoCard';
import { TooltipIcon } from 'components/tooltip';

import { CurrencyCodeUsd, CurrencyCodeMapUsd } from 'constants/currency';
import { colors, sizes } from 'constants/styles';

import { parseCurrency } from 'helpers/numbers';
import { isValueEmpty } from 'helpers/utility';

/**
 * InfoCard with large dollar amount on top and a summary with a tooltip below
 * @param {number|string} [amount]
 * @param {string} [amountPaidLabel]
 * @param {string} [className]
 * @param {Boolean} [hasShadow]
 * @param {string} [tooltipText]
 * @returns {null|StatelessComponent}
 * @constructor
 */
const InfoCardAmount = ({ amount, amountPaidLabel, className, hasShadow, tooltipText }) => {
  if (isValueEmpty(amount)) {
    return null;
  }

  const parsedCurrency = parseCurrency(amount);
  const { name: units } = CurrencyCodeMapUsd[CurrencyCodeUsd];

  return (
    <InfoCard className={className} hasShadow={hasShadow}>
      <InfoCardTop>
        <div className="paid-to-date--amount">{parsedCurrency}</div>
        <p className="paid-to-date--units">{`${units}`}</p>
      </InfoCardTop>

      <InfoCardBottom>
        <InfoCardHeader className="paid-to-date--label">
          {amountPaidLabel}

          <TooltipIcon
            content={tooltipText}
            contentMargin={sizes.spacing.MEDIUM}
            icon={IconNames.INFO_SIGN}
            iconProps={{
              color: colors.colorDarkSilverHex,
              marginBottom: sizes.spacing.EXTRA_SMALL,
              marginLeft: sizes.spacing.EXTRA_MEDIUM,
              position: 'relative',
              size: sizes.iconSizes.LARGE,
              top: sizes.spacing.EXTRA_SMALL,
            }}
          />
        </InfoCardHeader>
      </InfoCardBottom>
    </InfoCard>
  );
};

InfoCardAmount.propTypes = {
  amount: PropTypes.number,
  amountPaidLabel: PropTypes.string.isRequired,
  className: PropTypes.string,
  hasShadow: PropTypes.bool,
  tooltipText: PropTypes.string.isRequired,
};

InfoCardAmount.defaultProps = {
  amount: undefined,
  className: undefined,
  hasShadow: true,
  tooltipText: undefined,
};

export default InfoCardAmount;

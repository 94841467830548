import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';
import { IconNames } from 'components/icon';

import { ButtonSize } from 'constants/button';
import { colors, sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

import { oneOfValuesFromObject } from 'helpers/propTypes';

/**
 * Modal footer action button
 * @param {ComponentProps} props
 * @param {String} [props.buttonClasses]
 * @param {String} [props.buttonSize]
 * @param {*} props.children
 * @param {String} [props.dataTestId]
 * @param {String} [props.form]
 * @param {String} [props.htmlFor]
 * @param {String} [props.id]
 * @param {Intent} [props.intent]
 * @param {Boolean} [props.isActionButton]
 * @param {Boolean} [props.isDisabled]
 * @param {Boolean} [props.isLoading]
 * @param {String} [props.type]
 * @param {RestOfProps} rest
 * @returns {StatelessComponent}
 */
const ModalFooterButton = ({ buttonClasses, buttonSize, children, isActionButton, ...rest }) => (
  <div className="modal-footer--button">
    <ButtonV2
      className={classNames({
        'btn--inherit': isActionButton,
        'float-right': isActionButton,
        margin: true,
        right: !isActionButton,
        [buttonClasses]: !!buttonClasses,
      })}
      leftIconClassName="margin-right--m"
      rightIconClassName="margin-left--large"
      rightIconColor={colors.colorWhiteHex}
      rightIconName={isActionButton ? IconNames.ARROW_RIGHT : ''}
      rightIconSize={sizes.iconSizes.EXTRA_MEDIUM}
      size={buttonSize}
      {...rest}
    >
      {children}
    </ButtonV2>
  </div>
);

ModalFooterButton.propTypes = {
  buttonClasses: PropTypes.string,
  buttonSize: PropTypes.string,
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string,
  form: PropTypes.string,
  htmlFor: PropTypes.string,
  id: PropTypes.string,
  intent: oneOfValuesFromObject(Intent),
  isActionButton: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  leftIconName: PropTypes.string,
  leftIconSize: PropTypes.number,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

ModalFooterButton.defaultProps = {
  buttonClasses: '',
  buttonSize: ButtonSize.MEDIUM,
  dataTestId: undefined,
  form: undefined,
  htmlFor: undefined,
  id: undefined,
  intent: undefined,
  isActionButton: undefined,
  isDisabled: undefined,
  isLoading: undefined,
  leftIconName: undefined,
  leftIconSize: undefined,
  onClick: undefined,
  type: undefined,
};

export default ModalFooterButton;

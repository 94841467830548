import {} from 'axios';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { SSOActionType } from 'enums/sso';
import { sharedFormSubmitRoutinePayloadCreators } from 'helpers/routine';
import { ssoSettingsBaseEndpoint, ssoSettingsByNamespaceBaseEndpoint } from 'services/api/ssoEndpoints';
import { createApiRoutine } from './createApiRoutine';
export const companySettingsSSOFetchRoutine = createApiRoutine(SSOActionType.FETCH_COMPANY_SETTINGS_SSO, {
    endpoint: ssoSettingsBaseEndpoint,
    method: 'GET',
    requireAuth: true,
    headers: {},
});
export const companySettingsSSOByNamespaceFetchRoutine = createApiRoutine(SSOActionType.FETCH_COMPANY_SETTINGS_SSO_BY_NAMESPACE, {
    endpoint: ssoSettingsByNamespaceBaseEndpoint,
    method: 'GET',
    requireAuth: true,
    headers: {},
}, undefined, { trigger: () => ({ parseErrorResponseOptions: { muteAlerts: true } }) });
export const companySettingsSSOByNamespaceFetchHandler = promisifyRoutine(companySettingsSSOByNamespaceFetchRoutine);
export const ssoDisableRoutine = createRoutine(SSOActionType.DISABLE_SSO);
export const ssoSetupFormSubmitRoutine = createRoutine(SSOActionType.SUBMIT_SSO_SETTINGS, sharedFormSubmitRoutinePayloadCreators);
export const ssoSetupFormSubmitHandler = promisifyRoutine(ssoSetupFormSubmitRoutine);
export const ssoSetupFormEditSubmitRoutine = createRoutine(SSOActionType.SUBMIT_UPDATE_SSO_SETTINGS, sharedFormSubmitRoutinePayloadCreators);
export const ssoSetupFormEditSubmitHandler = promisifyRoutine(ssoSetupFormEditSubmitRoutine);

import ThunkFetch from '../helpers/thunkFetch';
import { getCompanyEndpoint } from '../services/api/companyEndpoints';
import { payloadToJSONAPI } from '../services/api/formatHelpers';
import {
  UPDATE_CURRENT_COMPANY_FAILURE,
  UPDATE_CURRENT_COMPANY_REQUEST,
  UPDATE_CURRENT_COMPANY_SUCCESS,
} from '../types/currentCompany';

const updateCurrentCompany = (updates, options = {}) => {
  const {
    successCallback,
    errorCallback,
    verificationUpdateAllowed,
    verificationUpdateDocumentAllowed,
    sendAddress = true,
  } = options;

  // some base relationships can be updated regardless of verification state;
  // additional relationships are context dependant
  const includeRelationships = [
    { name: 'brand', kind: 'CompanyBrand' },
    { name: 'mailingAddress', kind: 'Address' },
  ];

  if (verificationUpdateAllowed) {
    includeRelationships.push({ name: 'info', kind: 'CompanyInfo' });
    if (sendAddress) {
      includeRelationships.push({ name: 'physicalAddress', kind: 'Address' });
    }
  }

  if (verificationUpdateDocumentAllowed) {
    includeRelationships.push('documents');
  }

  const t = new ThunkFetch({
    actions: {
      error: UPDATE_CURRENT_COMPANY_FAILURE,
      request: UPDATE_CURRENT_COMPANY_REQUEST,
      success: UPDATE_CURRENT_COMPANY_SUCCESS,
    },
    endpoint: getCompanyEndpoint(updates.id),
    errorCallback,
    method: 'PATCH',
    payload: payloadToJSONAPI(updates, 'company', ...includeRelationships),
    requireAuth: true,
    successCallback,
  });

  return t.makeRequest();
};

export default updateCurrentCompany;

import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { FlairTagShape, FlairTagType } from 'constants/ui';

import { Position } from 'enums/ui';

import { flairTagTypeProp } from 'helpers/propTypes';
import { getIconSizeForFlair } from 'helpers/ui';

import Tag from '../Tag';

/**
 * A type of tag that should always have the same display, regardless of where it is used.
 * For a given flair type (e.g. MANAGE_ITEMS), the icon and text used will always
 * be the same for every usage.
 * This differs from usage of the normal <Tag />, which can have a unique label.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 */
const FlairTag = ({
  children,
  className,
  forcePointerEvents,
  tooltipContent,
  tooltipProps,
  type,
  hasIcon,
  shape,
  readOnly,
}) => {
  return (
    <Tag
      className={clsx('flair-tag', type.toLowerCase(), className, {
        'flair-labeled': !!children,
        'enable-pointer-events': forcePointerEvents,
      })}
      hasIcon={hasIcon}
      iconSize={getIconSizeForFlair(type, children)}
      isClosable={false}
      readOnly={readOnly}
      shape={shape || FlairTagShape[type]}
      tooltipContent={tooltipContent}
      tooltipProps={{
        position: Position.LEFT,
        ...tooltipProps,
      }}
      type={type}
    >
      {children}
    </Tag>
  );
};

FlairTag.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  forcePointerEvents: PropTypes.bool,
  tooltipContent: PropTypes.node.isRequired,
  tooltipProps: PropTypes.shape({}),
  hasIcon: PropTypes.bool,
  type: flairTagTypeProp,
  shape: PropTypes.string,
  readOnly: PropTypes.bool,
};

FlairTag.defaultProps = {
  children: undefined,
  className: undefined,
  forcePointerEvents: undefined,
  tooltipProps: undefined,
  type: FlairTagType.PRIMARY,
  hasIcon: true,
  shape: undefined,
  readOnly: undefined,
};

export default FlairTag;

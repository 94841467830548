import { taxFormType } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.models';
import { TaxDocType } from 'constants/taxes';
export const getTaxFormName = (formType, isIndividual) => {
    if (formType === taxFormType.Enum.W9) {
        return 'W-9';
    }
    return isIndividual ? 'W-8BEN' : 'W-8BEN-E';
};
export const isEligibleForCertificationTypeSelect = (formType, isIndividual, isSignatureAllowed) => {
    if (!isSignatureAllowed) {
        return false;
    }
    if (formType === taxFormType.Enum.W9) {
        return true;
    }
    return isIndividual;
};
export const translateFormTypeToTaxDoc = (formType) => formType === taxFormType.Enum.W9 ? TaxDocType.W9 : TaxDocType.W8BEN;

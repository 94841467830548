import React from 'react';
import { AddressFormat } from 'enums/addresses';
import { getAddressAsString } from 'helpers/addressHelpers';
import { isFundingAccountBankAccount, isFundingAccountNumberMasked, isFundingAccountUSD, getFundingAccountMask, isFundingAccountBalance, } from 'helpers/funding';
import { capitalize } from 'helpers/stringHelpers';
import { AccountAddress } from 'modules/fundingAccount/bankAccount/components';
import { PaymentMethodDetailAction, PaymentMethodDetailKey, PaymentMethodDetailsCreatorField, PaymentMethodDetailsCountryCurrencyField, } from './components';
import { PaymentMethodAccountNumberLockedTooltipContent, PaymentMethodFundingAccountAddedOnDate, PaymentMethodRoutingNumberLockedTooltipContent, } from './components/tooltips';
export const createPaymentMethodDetailItemProps = ({ actions = [], actionsElements = [], clipboardValue, key, label, value, revealedValue, tooltipContent, }) => ({
    actions,
    actionsElements,
    clipboardValue,
    key,
    label,
    value,
    revealedValue,
    tooltipContent,
});
export const getFundingInfoDomesticAccountTypeAndInstitutionDetailsItems = (props) => {
    const { fundingInfoBankAccount } = props;
    const paymentDetails = [];
    const { accountType, institutionName } = Object(fundingInfoBankAccount);
    if (institutionName) {
        paymentDetails.push(createPaymentMethodDetailItemProps({
            key: PaymentMethodDetailKey.BANK,
            label: 'Bank',
            value: institutionName,
        }));
    }
    if (accountType) {
        paymentDetails.push(createPaymentMethodDetailItemProps({
            key: PaymentMethodDetailKey.ACCOUNT_TYPE,
            label: 'Account type',
            value: capitalize(accountType),
        }));
    }
    return paymentDetails;
};
export const createMaskedValueDetailItemProp = (props) => {
    const { value: originalValue } = props;
    const isMasked = isFundingAccountNumberMasked(originalValue);
    const maskedDetailItemProps = {
        ...props,
        actions: [PaymentMethodDetailAction.COPY, PaymentMethodDetailAction.REVEAL],
        revealedValue: originalValue,
        value: getFundingAccountMask(originalValue),
    };
    const detailItemProps = isMasked ? props : maskedDetailItemProps;
    return createPaymentMethodDetailItemProps(detailItemProps);
};
export const shouldShowVANTooltip = ({ fundingInfoBankAccount, isPartnerAccountInDashboard, }) => Boolean(fundingInfoBankAccount?.isVirtualAccountNumber && isPartnerAccountInDashboard);
export const getDomesticAccountNumberDetailItemProps = (props) => {
    const { fundingInfoBankAccount, partnership } = props;
    const { accountNumber } = Object(fundingInfoBankAccount);
    const options = {
        key: PaymentMethodDetailKey.ACCOUNT_NUMBER,
        label: 'Account number',
        value: accountNumber,
    };
    if (shouldShowVANTooltip(props)) {
        return createPaymentMethodDetailItemProps({
            ...options,
            actions: [PaymentMethodDetailAction.LOCKED],
            tooltipContent: React.createElement(PaymentMethodAccountNumberLockedTooltipContent, { partnership: partnership }),
        });
    }
    return createMaskedValueDetailItemProp(options);
};
export const getBankNumbersDomesticVANRoutingNumberOverrides = (props) => {
    const { partnership } = props;
    if (!shouldShowVANTooltip(props)) {
        return undefined;
    }
    return {
        actions: [PaymentMethodDetailAction.LOCKED],
        tooltipContent: React.createElement(PaymentMethodRoutingNumberLockedTooltipContent, { partnership: partnership }),
    };
};
export const getFundingInfoDomesticAccountAndRoutingNumberDetailsItems = (props) => {
    const paymentDetails = [];
    const { fundingInfoBankAccount } = props;
    const { accountNumber, routingNumber } = Object(fundingInfoBankAccount);
    if (accountNumber) {
        paymentDetails.push(getDomesticAccountNumberDetailItemProps(props));
    }
    if (routingNumber) {
        paymentDetails.push(createPaymentMethodDetailItemProps({
            actions: [PaymentMethodDetailAction.COPY],
            key: PaymentMethodDetailKey.ROUTING_NUMBER,
            label: 'Routing number',
            tooltipContent: undefined,
            value: routingNumber,
            ...getBankNumbersDomesticVANRoutingNumberOverrides(props),
        }));
    }
    return paymentDetails;
};
export const getFundingInfoDomesticDetailsItems = (props) => [
    ...getFundingInfoDomesticAccountTypeAndInstitutionDetailsItems(props),
    ...getFundingInfoDomesticAccountAndRoutingNumberDetailsItems(props),
];
export const getFundingInfoInternationalDetailsItems = (fundingInfoInternational) => {
    const paymentDetails = [];
    const { accountNumber, bankCode, bicSwift, branchCode, bsbCode, clabe, cnaps, iban, ifsc, sortCode } = Object(fundingInfoInternational);
    if (accountNumber) {
        paymentDetails.push(createMaskedValueDetailItemProp({
            value: accountNumber,
            key: PaymentMethodDetailKey.ACCOUNT_NUMBER,
            label: 'Account number',
        }));
    }
    if (bsbCode) {
        paymentDetails.push(createPaymentMethodDetailItemProps({
            actions: [PaymentMethodDetailAction.COPY],
            key: PaymentMethodDetailKey.BSB_CODE,
            label: 'Bank state branch (BSB)',
            value: bsbCode,
        }));
    }
    if (bankCode) {
        paymentDetails.push(createPaymentMethodDetailItemProps({
            actions: [PaymentMethodDetailAction.COPY],
            key: PaymentMethodDetailKey.BANK_CODE,
            label: 'Bank code',
            value: bankCode,
        }));
    }
    if (branchCode) {
        paymentDetails.push(createPaymentMethodDetailItemProps({
            actions: [PaymentMethodDetailAction.COPY],
            key: PaymentMethodDetailKey.BRANCH_CODE,
            label: 'Branch code',
            value: branchCode,
        }));
    }
    if (sortCode) {
        paymentDetails.push(createPaymentMethodDetailItemProps({
            actions: [PaymentMethodDetailAction.COPY],
            key: PaymentMethodDetailKey.SORT_CODE,
            label: 'Sort code',
            value: sortCode,
        }));
    }
    if (bicSwift) {
        paymentDetails.push(createMaskedValueDetailItemProp({
            value: bicSwift,
            key: PaymentMethodDetailKey.BIC_SWIFT,
            label: 'Bank identifier code (BIC)',
        }));
    }
    if (iban) {
        paymentDetails.push(createMaskedValueDetailItemProp({
            value: iban,
            key: PaymentMethodDetailKey.IBAN,
            label: 'International bank account number (IBAN)',
        }));
    }
    if (clabe) {
        paymentDetails.push(createMaskedValueDetailItemProp({
            value: clabe,
            key: PaymentMethodDetailKey.CLABE,
            label: 'CLABE',
        }));
    }
    if (cnaps) {
        paymentDetails.push(createMaskedValueDetailItemProp({
            value: cnaps,
            key: PaymentMethodDetailKey.CNAPS,
            label: 'CNAPS',
        }));
    }
    if (ifsc) {
        paymentDetails.push(createPaymentMethodDetailItemProps({
            key: PaymentMethodDetailKey.IFSC,
            label: 'IFSC',
            value: ifsc,
        }));
    }
    return paymentDetails;
};
export const getFundingInfoDetailsItems = (props) => {
    const { fundingAccount, fundingInfoInternational } = props;
    if (isFundingAccountUSD(fundingAccount) && typeof fundingInfoInternational?.bicSwift !== 'string') {
        return getFundingInfoDomesticDetailsItems(props);
    }
    return getFundingInfoInternationalDetailsItems(fundingInfoInternational);
};
export const getAddedByTimestampProps = ({ fundingAccount, partnershipFundingAccount, }) => {
    const createdDate = fundingAccount.created || partnershipFundingAccount?.created;
    if (!createdDate) {
        return undefined;
    }
    return {
        actions: [PaymentMethodDetailAction.TOOLTIP],
        tooltipContent: React.createElement(PaymentMethodFundingAccountAddedOnDate, { createdDate: createdDate }),
    };
};
export const isFundingAccountAddedByYourTeam = ({ fundingAccount, partnershipFundingAccount, }) => {
    if (partnershipFundingAccount) {
        return partnershipFundingAccount.isCreatedByPartner === false;
    }
    return Boolean(fundingAccount?.created);
};
export const createPaymentMethodDetailItemPropsAddedBy = (props) => createPaymentMethodDetailItemProps({
    ...getAddedByTimestampProps(props),
    key: PaymentMethodDetailKey.ADDED_BY,
    label: 'Added by',
    value: (React.createElement(PaymentMethodDetailsCreatorField, { companyName: props.partnership?.name, creator: props.creator, isYourTeam: isFundingAccountAddedByYourTeam(props) })),
});
export const getBankDetailItems = (props) => {
    const { fundingAccount, fundingInfoInternational, showFundingAccountId } = props;
    const paymentDetails = [];
    paymentDetails.push(...getFundingInfoDetailsItems(props));
    paymentDetails.push(createPaymentMethodDetailItemProps({
        key: PaymentMethodDetailKey.COUNTRY_CURRENCY,
        label: 'Country / Currency',
        value: (React.createElement(PaymentMethodDetailsCountryCurrencyField, { fundingAccount: fundingAccount, fundingInfoInternational: fundingInfoInternational })),
    }));
    paymentDetails.push(createPaymentMethodDetailItemPropsAddedBy(props));
    if (showFundingAccountId) {
        paymentDetails.push(createPaymentMethodDetailItemProps({
            actions: [PaymentMethodDetailAction.COPY],
            key: PaymentMethodDetailKey.BANK_ACCOUNT_ID,
            label: 'Routable bank account ID',
            value: fundingAccount.id,
        }));
    }
    return paymentDetails;
};
export const getAddressDetailItems = (props) => {
    const { fundingInfoAddress } = props;
    const paymentDetails = [];
    const accountAddressDetailItem = createPaymentMethodDetailItemProps({
        actions: [PaymentMethodDetailAction.COPY],
        key: PaymentMethodDetailKey.ADDRESS,
        label: 'Mailing address',
        clipboardValue: getAddressAsString(fundingInfoAddress, {
            format: AddressFormat.FULL_COUNTRY,
        }),
        value: (React.createElement(AccountAddress, { ...fundingInfoAddress, printCompany: undefined, printName: undefined })),
    });
    const addedByDetailItem = createPaymentMethodDetailItemPropsAddedBy(props);
    return paymentDetails.concat(accountAddressDetailItem, addedByDetailItem);
};
export const getBalanceDetailItems = (props) => {
    const { fundingAccount } = props;
    const paymentDetails = [];
    paymentDetails.push(createPaymentMethodDetailItemProps({
        key: PaymentMethodDetailKey.COUNTRY_CURRENCY,
        label: 'Country / Currency',
        value: React.createElement(PaymentMethodDetailsCountryCurrencyField, { fundingAccount: fundingAccount }),
    }));
    paymentDetails.push(createPaymentMethodDetailItemProps({
        actions: [PaymentMethodDetailAction.COPY],
        key: PaymentMethodDetailKey.BALANCE_ID,
        label: 'Balance ID',
        value: fundingAccount.balance,
    }));
    paymentDetails.push(createPaymentMethodDetailItemPropsAddedBy(props));
    return paymentDetails;
};
export const getItems = (props) => {
    if (isFundingAccountBalance(props.fundingAccount)) {
        return getBalanceDetailItems(props);
    }
    if (isFundingAccountBankAccount(props.fundingAccount)) {
        return getBankDetailItems(props);
    }
    return getAddressDetailItems(props);
};

import React from 'react';

import { ReloadPageButton } from 'components/buttonTypes';
import { ModalBodyContent, ModalFooter } from 'components/modal';

import OldVersionModeBody from '../OldVersionModeBody';

const OldVersionModeModalBody = () => (
  <>
    <ModalBodyContent className="old-version-mode">
      <OldVersionModeBody />
    </ModalBodyContent>

    <ModalFooter className="old-version-footer">
      <ReloadPageButton />
    </ModalFooter>
  </>
);

export default OldVersionModeModalBody;

import { createSelector } from 'reselect';

import { formNamesFunding } from 'constants/forms';

import {
  createFormIsInvalidSelector,
  createFormIsSubmittingSelector,
  createGetFormValuesSelector,
  createIsFormStateInitializedSelector,
} from 'selectors/createFormSelectors';

/**
 * Returns true if addPartnerFundingAccountForm has been initialized
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const isAddPartnerFundingAccountFormInitializedSelector = createIsFormStateInitializedSelector(
  formNamesFunding.ADD_PARTNER_FUNDING_ACCOUNT,
);

/**
 * Returns true if addPartnerInternationalFundingAccountForm has been initialized
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const isAddPartnerInternationalFundingAccountFormInitializedSelector = createIsFormStateInitializedSelector(
  formNamesFunding.ADD_PARTNER_INTERNATIONAL_FUNDING_ACCOUNT,
);

/**
 * Returns form values of addPartnerFundingAccountForm
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object|undefined}
 */
export const addPartnerFundingAccountFormValuesSelector = createGetFormValuesSelector(
  formNamesFunding.ADD_PARTNER_FUNDING_ACCOUNT,
);

/**
 * Returns form values of addPartnerInternationalFundingAccountForm
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object|undefined}
 */
export const addPartneInternationalFundingAccountFormValuesSelector = createGetFormValuesSelector(
  formNamesFunding.ADD_PARTNER_INTERNATIONAL_FUNDING_ACCOUNT,
);

/**
 * Returns true if addPartnerFundingAccountForm is submitting
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const isAddPartnerFundingAccountFormSubmittingSelector = createFormIsSubmittingSelector(
  formNamesFunding.ADD_PARTNER_FUNDING_ACCOUNT,
);

/**
 * Returns true if addPartnerFundingAccountForm is submitting
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const isAddPartnerInternationalFundingAccountFormSubmittingSelector = createFormIsSubmittingSelector(
  formNamesFunding.ADD_PARTNER_INTERNATIONAL_FUNDING_ACCOUNT,
);

/**
 * Returns true if addPartnerFundingAccountForm is invalid
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const isAddPartnerFundingAccountFormInvalidSelector = createFormIsInvalidSelector(
  formNamesFunding.ADD_PARTNER_FUNDING_ACCOUNT,
);

/**
 * Returns true if addPartnerInternationalFundingAccountForm is invalid
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const isAddPartnerInternationalFundingAccountFormInvalidSelector = createFormIsInvalidSelector(
  formNamesFunding.ADD_PARTNER_INTERNATIONAL_FUNDING_ACCOUNT,
);

/**
 * Returns object containing addPartnerFundingAccountForm meta data
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object}
 */
export const addPartnerFundingAccountFormMetaCompoundSelector = createSelector(
  [
    isAddPartnerFundingAccountFormInitializedSelector,
    addPartnerFundingAccountFormValuesSelector,
    isAddPartnerFundingAccountFormSubmittingSelector,
    isAddPartnerFundingAccountFormInvalidSelector,
  ],
  (isFormInitialized, formValues, isSubmitting, isInvalid) => ({
    formValues,
    isFormInitialized,
    isSubmitting,
    isInvalid,
  }),
);

/**
 * Returns object containing addPartnerInternationalFundingAccountForm meta data
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object}
 */
export const addPartnerInternationalFundingAccountFormMetaCompoundSelector = createSelector(
  [
    isAddPartnerInternationalFundingAccountFormInitializedSelector,
    addPartneInternationalFundingAccountFormValuesSelector,
    isAddPartnerInternationalFundingAccountFormSubmittingSelector,
    isAddPartnerInternationalFundingAccountFormInvalidSelector,
  ],
  (isFormInitialized, formValues, isSubmitting, isInvalid) => ({
    formValues,
    isFormInitialized,
    isSubmitting,
    isInvalid,
  }),
);

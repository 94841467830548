import {} from '@routable/components';
export const riskSummaryToIntentMap = {
    cant_validate: 'critical',
    review_required: 'critical',
    queued: 'default',
    not_evaluated: 'default',
    dismissed: 'default',
    outdated: 'default',
};
export const riskSummaryToLabelMap = {
    cant_validate: 'Compliance issues found',
    review_required: 'Compliance issues found',
    queued: 'Compliance not evaluated',
    not_evaluated: 'Compliance not evaluated',
    outdated: 'Compliance check is outdated',
    dismissed: 'Compliance check manually dismissed',
};

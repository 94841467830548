import classNames from 'classnames';
import debounce from 'es6-promise-debounce';
import React, { useCallback, useState } from 'react';
import './PasswordInput.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { Field, getFormValues } from 'redux-form';
import { triggerPasswordStrengthRequest } from 'actions/passwordInput';
import { FormControl, IconNames, ReduxFormFieldRenderInput, TooltipIcon } from 'components';
import GenericFormError from 'components/GenericFormError/GenericFormError';
import { field } from 'constants/styles/formStyles';
import EnterExistingPasswordErrorContent from 'modules/auth/AcceptMembershipInvite/components/AcceptMembershipInviteForm/components/ReduxFormFieldRenderInputWithUpdatedErrors/components/EnterExistingPasswordErrorContent';
import { getPasswordScore, getAreAllRequirementsMet, getPasswordFeedback } from 'selectors/passwordInputSelectors';
import { signupCreateAccountPasswordErrorSelector } from 'selectors/signupSelectors';
import PasswordHints from './components/PasswordHints';
import { strengthNeedsFeedback, strengthScores, strengthTypes, } from './components/PasswordHints/constants/strengthScores';
import { ACCOUNT_ALREADY_EXISTS_ERROR } from './constants/passwordErrorMessages';
import { errorStyles } from './constants/passwordRequirementParameters';
import { standardPasswordValidators } from './constants/standardPasswordValidators';
import { checkAllRequirements } from './helpers/checkAllRequirements';
import {} from './types/PasswordInput.types';
const PasswordInput = ({ name, userAttemptedSubmission, formName }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showStrength, setShowStrength] = useState(false);
    const targetIcon = showPassword ? (React.createElement(TooltipIcon, { content: "Hide", icon: IconNames.EYE_OFF, iconProps: { size: 14 } })) : (React.createElement(TooltipIcon, { content: "Show", icon: IconNames.EYE_OPEN, iconProps: { size: 14 } }));
    const dispatch = useDispatch();
    const submissionPasswordErrors = useSelector(signupCreateAccountPasswordErrorSelector);
    const passwordFeedback = useSelector(getPasswordFeedback);
    const formValues = useSelector(getFormValues(formName));
    const passwordScore = useSelector(getPasswordScore);
    const formErrors = useSelector((state) => state.form[formName]?.syncErrors?.user?.password);
    const requirementsAreMet = useSelector(getAreAllRequirementsMet);
    const currentPassword = formValues.user?.password;
    const strength = strengthScores[passwordScore];
    const handlePasswordVisibility = () => setShowPassword(!showPassword);
    const handleChange = (event) => {
        if (checkAllRequirements(dispatch, event.target.value)) {
            dispatch(triggerPasswordStrengthRequest(event.target.value));
        }
    };
    const handleBlur = () => {
        if (strength === strengthTypes.WEAK && !currentPassword) {
            setShowStrength(false);
        }
    };
    const handleFocus = () => setShowStrength(true);
    const debouncedChange = useCallback(debounce(handleChange, 500), []);
    const passwordError = submissionPasswordErrors === ACCOUNT_ALREADY_EXISTS_ERROR ? (React.createElement(GenericFormError, null,
        React.createElement(EnterExistingPasswordErrorContent, null))) : (React.createElement(GenericFormError, { errorMessage: submissionPasswordErrors }));
    return (React.createElement("div", { className: "password-entry-container" },
        React.createElement(FormControl, { className: "password-entry-control" },
            React.createElement(Field, { className: classNames('password-entry-field', field.xl.full), component: ReduxFormFieldRenderInput, dataFullStory: true, dataTestId: "password", isRequired: true, name: name, onBlur: handleBlur, onChange: debouncedChange, onFocus: handleFocus, placeholder: "Password", type: showPassword ? 'text' : 'password', validate: standardPasswordValidators },
                React.createElement("button", { className: "toggle-password-reveal", onClick: handlePasswordVisibility, type: "button" }, targetIcon)),
            userAttemptedSubmission && submissionPasswordErrors && passwordError,
            strengthNeedsFeedback[strength] && !formErrors && !submissionPasswordErrors && (React.createElement(GenericFormError, { errorMessage: passwordFeedback }))),
        !submissionPasswordErrors && (React.createElement(PasswordHints, { formName: formName, requirementsAreMet: requirementsAreMet, showStrength: showStrength, styling: userAttemptedSubmission ? errorStyles.HIGHLIGHTED : errorStyles.MUTED }))));
};
export default PasswordInput;

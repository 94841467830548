import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submitFundingStateRoutine } from 'actions/routines/external';
import { externalPaymentFundingAccountSelector } from 'selectors/forms';
import { partnershipFundingStatesErrorsSelector } from 'selectors/partnershipFundingStatesSelectors';
export const useExternalPaymentFundingAccountHint = () => {
    const errors = useSelector(partnershipFundingStatesErrorsSelector);
    const selectedFundingAccount = useSelector(externalPaymentFundingAccountSelector);
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(submitFundingStateRoutine.failure());
    }, [dispatch, selectedFundingAccount]);
    return errors?.fields?.fundingAccount;
};

export var PaymentMethodStatusType;
(function (PaymentMethodStatusType) {
    PaymentMethodStatusType["ACTIVE"] = "ACTIVE";
    PaymentMethodStatusType["DISABLED"] = "DISABLED";
    PaymentMethodStatusType["ERROR"] = "ERROR";
    PaymentMethodStatusType["PENDING_MICRO_DEPOSITS"] = "PENDING_MICRO_DEPOSITS";
    PaymentMethodStatusType["PENDING_VERIFICATION"] = "PENDING_VERIFICATION";
    PaymentMethodStatusType["PRIMARY"] = "PRIMARY";
})(PaymentMethodStatusType || (PaymentMethodStatusType = {}));
export var PaymentMethodVariant;
(function (PaymentMethodVariant) {
    PaymentMethodVariant["EXTERNAL"] = "external";
    PaymentMethodVariant["MANAGE"] = "manage";
    PaymentMethodVariant["SELECT"] = "select";
    PaymentMethodVariant["VIEW"] = "view";
})(PaymentMethodVariant || (PaymentMethodVariant = {}));

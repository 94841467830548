/**
 * Helper to check if an image is OK
 * @param img
 * @return {boolean}
 */
export const isImageOk = (img) => {
  if (!img.complete) {
    return false;
  }

  if (typeof img.naturalWidth !== 'undefined' && img.naturalWidth === 0) {
    return false;
  }

  return true;
};

/**
 * Helper to hide broken image
 * @param event
 */
export const hideImage = (event) => {
  if (!event || !event.target || isImageOk(event.target)) {
    return;
  }

  // eslint-disable-next-line no-param-reassign
  event.target.style.visibility = 'hidden';
};

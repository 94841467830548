import PropTypes from 'prop-types';
import React from 'react';

const ExternalFooter = (props) => <div className="external-footer">{props.children}</div>;

ExternalFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ExternalFooter;

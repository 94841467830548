import { Account, Add, AddAlt, ArrowDown, ArrowLeft, ArrowRight, ArrowUp, ArrowUpRight, ArrowsHorizontal, Attachment, Badge, Book, Branch, Building, Calendar, CaretDown, ChartLine, ChartLineSmooth, ChartPopulation, Chat, ChatLaunch, Checkmark, CheckmarkFilled, CheckmarkOutline, ChevronDown, ChevronDownOutline, ChevronLeft, ChevronRight, ChevronUp, ChevronUpOutline, CircleFill, CircleSolid, CircleStroke, Close, CloseOutline, Code, CollapseCategories, Copy, CurrencyDollar, DataFormat, DirectionMerge, DocumentBlank, DocumentExport, DocumentImport, DocumentTasks, Download, EarthFilled, Edit, Email, Error, ErrorOutline, ExpandCategories, Eyedropper, Favorite, FavoriteFilled, Filter, Finance, FlagFilled, FloatingIp, FolderOpen, Folders, HardwareSecurityModule, HelpFilled, Home, Image, IncompleteCancel, InformationFilled, JoinRight, Launch, Layers, Link, ListBoxes, LocationCurrent, LocationFilled, Locked, Login, Logout, Maximize, Minimize, Network_2, NotificationFilled, OverflowMenuHorizontal, PanHorizontal, PauseFilled, Person, PiggyBank, PlayFilledAlt, Portfolio, Product, Quotes, RadioButtonChecked, RecentlyViewed, Redo, Renew, RetryFailed, RotateCounterclockwise, Search, Security, SelectWindow, Settings, SettingsAdjust, SkipForwardFilled, SquareFill, Stamp, Star, StarFilled, Store, Subtract, SubtractAlt, SubtractFilled, Tag, Task, TaskAdd, TimeFilled, TrashCan, Undo, UpdateNow, Upload, UserAdmin, UserCertification, UserMultiple, UserOnline, ViewFilled, ViewOffFilled, Warning, WarningAltFilled, WarningFilled, ZoomIn, ZoomOut, } from '@carbon/icons-react';
import { SidebarCompanies, SidebarInbox, SidebarPayables, SidebarReceivables } from '@routable/icons';
export const IconNames = {
    ADD: 'add',
    ADD_CIRCLE: 'add-circle',
    ADD_TO_ARTIFACT: 'add-to-artifact',
    ANNOTATION: 'annotation',
    APPLICATION: 'application',
    ARROWS_HORIZONTAL: 'arrows-horizontal',
    ARROW_DOWN: 'arrow-down',
    ARROW_LEFT: 'arrow-left',
    ARROW_RIGHT: 'arrow-right',
    ARROW_TOP_RIGHT: 'arrow-top-right',
    ARROW_UP: 'arrow-up',
    ARROW_UP_RIGHT: 'arrow-up-right',
    AUTOMATIC_UPDATES: 'automatic-updates',
    BADGE: 'badge',
    BANK_ACCOUNT: 'bank-account',
    BAN_CIRCLE: 'ban-circle',
    BLANK: 'blank',
    BLOCKED_PERSON: 'blocked-person',
    BRIEFCASE: 'briefcase',
    CALENDAR: 'calendar',
    CARET_DOWN: 'caret-down',
    CHART: 'chart',
    CHAT: 'chat',
    CHEVRON_DOWN: 'chevron-down',
    CHEVRON_LEFT: 'chevron-left',
    CHEVRON_RIGHT: 'chevron-right',
    CHEVRON_UP: 'chevron-up',
    CIRCLE: 'circle',
    CIRCLE_ARROW_DOWN: 'circle-arrow-down',
    CIRCLE_ARROW_LEFT: 'circle-arrow-left',
    CIRCLE_ARROW_RIGHT: 'circle-arrow-right',
    CIRCLE_ARROW_UP: 'circle-arrow-up',
    CITATION: 'citation',
    CLIPBOARD: 'clipboard',
    CLOUD_DOWNLOAD: 'cloud-download',
    CLOUD_UPLOAD: 'cloud-upload',
    CODE: 'code',
    COG: 'cog',
    COLLAPSE_ALL: 'collapse-all',
    COMPARISON: 'comparison',
    CONFIRM: 'confirm',
    CROSS: 'cross',
    CROWN: 'crown',
    DELETE: 'delete',
    DIRECTION_RIGHT: 'direction-right',
    DISABLE: 'disable',
    DOCUMENT: 'document',
    DOCUMENT_OPEN: 'document-open',
    DOCUMENT_SHARE: 'document-share',
    DOLLAR: 'dollar',
    DOUBLE_CHEVRON_DOWN: 'double-chevron-down',
    DOUBLE_CHEVRON_UP: 'double-chevron-up',
    DOWNLOAD: 'download',
    DUPLICATE: 'duplicate',
    EDIT: 'edit',
    ENVELOPE: 'envelope',
    ERROR: 'error',
    ERROR_OUTLINE: 'error_outline',
    EXPAND_ALL: 'expand-all',
    EYE_OFF: 'eye-off',
    EYE_ON: 'eye-on',
    EYE_OPEN: 'eye-open',
    FILTER_LIST: 'filter-list',
    FLAG: 'flag',
    FLOW_END: 'flow-end',
    FOLDER_CLOSE: 'folder-close',
    FOLDER_OPEN: 'folder-open',
    FORM: 'form',
    FULL_CIRCLE: 'full-circle',
    GEOLOCATION: 'geolocation',
    GIT_MERGE: 'git-merge',
    GIT_PUSH: 'git-push',
    GLOBE: 'globe',
    GRAPH_REMOVE: 'graph-remove',
    HEART: 'heart',
    HEART_BROKEN: 'heart-broken',
    HELP: 'help',
    HISTORY: 'history',
    HOME: 'home',
    IMPORT: 'import',
    INBOX: 'inbox',
    INFO_SIGN: 'info-sign',
    INHERITANCE: 'inheritance',
    ISSUE: 'issue',
    KEY: 'key',
    LAB_TEST: 'lab-test',
    LINK: 'link',
    LOCK: 'lock',
    LOG_IN: 'log-in',
    LOG_OUT: 'log-out',
    MANUAL: 'manual',
    MAP_MARKER: 'map-marker',
    MAXIMIZE: 'maximize',
    MEDIA: 'media',
    MINIMIZE: 'minimize',
    MINUS: 'minus',
    MORE: 'more',
    MULTI_SELECT: 'multi-select',
    NOTIFICATIONS: 'notifications',
    OFFICE: 'office',
    OUTDATED: 'outdated',
    PAPERCLIP: 'paperclip',
    PAUSE: 'pause',
    PEOPLE: 'people',
    PERSON: 'person',
    PIGGY_BANK: 'piggy-bank',
    PLAY: 'play',
    PLUS: 'plus',
    PROJECTS: 'projects',
    PROPERTIES: 'properties',
    REDO: 'redo',
    REFRESH: 'refresh',
    REMOVE: 'remove',
    REPEAT: 'repeat',
    ROTATE_PAGE: 'rotate-page',
    SAVED: 'saved',
    SEARCH: 'search',
    SELECT: 'select',
    SELECTION: 'selection',
    SEND_TO: 'send-to',
    SETTINGS: 'settings',
    SHARE: 'share',
    SHIELD: 'shield',
    SHOP: 'shop',
    SIDEBAR_COMPANIES: 'sidebar-companies',
    SIDEBAR_INBOX: 'sidebar-inbox',
    SIDEBAR_PAYABLES: 'sidebar-payables',
    SIDEBAR_RECEIVABLES: 'sidebar-receivables',
    SMALL_CROSS: 'small-cross',
    SMALL_PLUS: 'small-plus',
    SMALL_TICK: 'small-tick',
    STAR: 'star',
    STAR_EMPTY: 'star-empty',
    STEP_FORWARD: 'step-forward',
    SWAP_HORIZONTAL: 'swap-horizontal',
    TAG: 'tag',
    TAKE_ACTION: 'take-action',
    TICK: 'tick',
    TICK_CIRCLE: 'tick-circle',
    TIME: 'time',
    TIMELINE_LINE_CHART: 'timeline-line-chart',
    TRASH: 'trash',
    UNDO: 'undo',
    UNRESOLVE: 'unresolve',
    UPLOAD: 'upload',
    WARNING_SIGN: 'warning-sign',
    WHATEVER: 'whatever',
    ZOOM_IN: 'zoom-in',
    ZOOM_OUT: 'zoom-out',
};
export const legacyIconToRoutableIconMap = {
    [IconNames.ADD]: Add,
    [IconNames.ADD_CIRCLE]: AddAlt,
    [IconNames.ADD_TO_ARTIFACT]: TaskAdd,
    [IconNames.ANNOTATION]: Edit,
    [IconNames.APPLICATION]: Account,
    [IconNames.ARROWS_HORIZONTAL]: PanHorizontal,
    [IconNames.ARROW_DOWN]: ArrowDown,
    [IconNames.ARROW_LEFT]: ArrowLeft,
    [IconNames.ARROW_RIGHT]: ArrowRight,
    [IconNames.ARROW_TOP_RIGHT]: ArrowUpRight,
    [IconNames.ARROW_UP]: ArrowUp,
    [IconNames.ARROW_UP_RIGHT]: ArrowUpRight,
    [IconNames.AUTOMATIC_UPDATES]: UpdateNow,
    [IconNames.BADGE]: Badge,
    [IconNames.BANK_ACCOUNT]: Finance,
    [IconNames.BAN_CIRCLE]: SubtractFilled,
    [IconNames.BLANK]: SquareFill,
    [IconNames.BLOCKED_PERSON]: UserOnline,
    [IconNames.BRIEFCASE]: Portfolio,
    [IconNames.CALENDAR]: Calendar,
    [IconNames.CARET_DOWN]: CaretDown,
    [IconNames.CHART]: ChartLine,
    [IconNames.CHAT]: Chat,
    [IconNames.CHEVRON_DOWN]: ChevronDown,
    [IconNames.CHEVRON_LEFT]: ChevronLeft,
    [IconNames.CHEVRON_RIGHT]: ChevronRight,
    [IconNames.CHEVRON_UP]: ChevronUp,
    [IconNames.CIRCLE]: CircleStroke,
    [IconNames.CIRCLE_ARROW_DOWN]: ChevronDownOutline,
    [IconNames.CIRCLE_ARROW_LEFT]: ChevronLeft,
    [IconNames.CIRCLE_ARROW_RIGHT]: ChevronRight,
    [IconNames.CIRCLE_ARROW_UP]: ChevronUpOutline,
    [IconNames.CITATION]: Quotes,
    [IconNames.CLIPBOARD]: Task,
    [IconNames.CLOUD_DOWNLOAD]: Download,
    [IconNames.CLOUD_UPLOAD]: Upload,
    [IconNames.CODE]: Code,
    [IconNames.COG]: Settings,
    [IconNames.COLLAPSE_ALL]: CollapseCategories,
    [IconNames.COMPARISON]: ChartPopulation,
    [IconNames.CONFIRM]: CheckmarkOutline,
    [IconNames.CROSS]: Close,
    [IconNames.CROWN]: UserCertification,
    [IconNames.DELETE]: CloseOutline,
    [IconNames.DIRECTION_RIGHT]: LocationCurrent,
    [IconNames.DISABLE]: Error,
    [IconNames.DOCUMENT]: DocumentBlank,
    [IconNames.DOCUMENT_OPEN]: DocumentExport,
    [IconNames.DOCUMENT_SHARE]: DocumentExport,
    [IconNames.DOLLAR]: CurrencyDollar,
    [IconNames.DOUBLE_CHEVRON_DOWN]: ChevronDown,
    [IconNames.DOUBLE_CHEVRON_UP]: ChevronUp,
    [IconNames.DOWNLOAD]: ArrowDown,
    [IconNames.DUPLICATE]: Copy,
    [IconNames.EDIT]: Edit,
    [IconNames.ENVELOPE]: Email,
    [IconNames.ERROR]: WarningFilled,
    [IconNames.ERROR_OUTLINE]: ErrorOutline,
    [IconNames.EXPAND_ALL]: ExpandCategories,
    [IconNames.EYE_OFF]: ViewOffFilled,
    [IconNames.EYE_ON]: UserAdmin,
    [IconNames.EYE_OPEN]: ViewFilled,
    [IconNames.FILTER_LIST]: Filter,
    [IconNames.FLAG]: FlagFilled,
    [IconNames.FLOW_END]: FloatingIp,
    [IconNames.FOLDER_CLOSE]: Folders,
    [IconNames.FOLDER_OPEN]: FolderOpen,
    [IconNames.FORM]: DataFormat,
    [IconNames.FULL_CIRCLE]: CircleSolid,
    [IconNames.GEOLOCATION]: LocationCurrent,
    [IconNames.GIT_MERGE]: DirectionMerge,
    [IconNames.GIT_PUSH]: Branch,
    [IconNames.GLOBE]: EarthFilled,
    [IconNames.GRAPH_REMOVE]: IncompleteCancel,
    [IconNames.HEART]: FavoriteFilled,
    [IconNames.HEART_BROKEN]: Favorite,
    [IconNames.HELP]: HelpFilled,
    [IconNames.HISTORY]: RecentlyViewed,
    [IconNames.HOME]: Home,
    [IconNames.IMPORT]: DocumentImport,
    [IconNames.INBOX]: Download,
    [IconNames.INFO_SIGN]: InformationFilled,
    [IconNames.INHERITANCE]: Network_2,
    [IconNames.ISSUE]: Warning,
    [IconNames.KEY]: HardwareSecurityModule,
    [IconNames.LAB_TEST]: Eyedropper,
    [IconNames.LINK]: Link,
    [IconNames.LOCK]: Locked,
    [IconNames.LOG_IN]: Login,
    [IconNames.LOG_OUT]: Logout,
    [IconNames.MANUAL]: Book,
    [IconNames.MAP_MARKER]: LocationFilled,
    [IconNames.MAXIMIZE]: Maximize,
    [IconNames.MEDIA]: Image,
    [IconNames.MINIMIZE]: Minimize,
    [IconNames.MINUS]: Subtract,
    [IconNames.MORE]: OverflowMenuHorizontal,
    [IconNames.MULTI_SELECT]: Layers,
    [IconNames.NOTIFICATIONS]: NotificationFilled,
    [IconNames.OFFICE]: Building,
    [IconNames.OUTDATED]: RetryFailed,
    [IconNames.PAPERCLIP]: Attachment,
    [IconNames.PAUSE]: PauseFilled,
    [IconNames.PEOPLE]: UserMultiple,
    [IconNames.PERSON]: Person,
    [IconNames.PIGGY_BANK]: PiggyBank,
    [IconNames.PLAY]: PlayFilledAlt,
    [IconNames.PLUS]: Add,
    [IconNames.PROJECTS]: Product,
    [IconNames.PROPERTIES]: ListBoxes,
    [IconNames.REDO]: Redo,
    [IconNames.REFRESH]: Renew,
    [IconNames.REMOVE]: SubtractAlt,
    [IconNames.REPEAT]: Renew,
    [IconNames.ROTATE_PAGE]: RotateCounterclockwise,
    [IconNames.SAVED]: DocumentTasks,
    [IconNames.SEARCH]: Search,
    [IconNames.SELECT]: SelectWindow,
    [IconNames.SELECTION]: RadioButtonChecked,
    [IconNames.SEND_TO]: ChatLaunch,
    [IconNames.SETTINGS]: SettingsAdjust,
    [IconNames.SHARE]: Launch,
    [IconNames.SHIELD]: Security,
    [IconNames.SHOP]: Store,
    [IconNames.SIDEBAR_COMPANIES]: SidebarCompanies,
    [IconNames.SIDEBAR_INBOX]: SidebarInbox,
    [IconNames.SIDEBAR_PAYABLES]: SidebarPayables,
    [IconNames.SIDEBAR_RECEIVABLES]: SidebarReceivables,
    [IconNames.SMALL_CROSS]: Close,
    [IconNames.SMALL_PLUS]: Add,
    [IconNames.SMALL_TICK]: Checkmark,
    [IconNames.STAR]: StarFilled,
    [IconNames.STAR_EMPTY]: Star,
    [IconNames.STEP_FORWARD]: SkipForwardFilled,
    [IconNames.SWAP_HORIZONTAL]: ArrowsHorizontal,
    [IconNames.TAG]: Tag,
    [IconNames.TAKE_ACTION]: Stamp,
    [IconNames.TICK]: Checkmark,
    [IconNames.TICK_CIRCLE]: CheckmarkFilled,
    [IconNames.TIME]: TimeFilled,
    [IconNames.TIMELINE_LINE_CHART]: ChartLineSmooth,
    [IconNames.TRASH]: TrashCan,
    [IconNames.UNDO]: Undo,
    [IconNames.UNRESOLVE]: JoinRight,
    [IconNames.UPLOAD]: Upload,
    [IconNames.WARNING_SIGN]: WarningAltFilled,
    [IconNames.WHATEVER]: CircleFill,
    [IconNames.ZOOM_IN]: ZoomIn,
    [IconNames.ZOOM_OUT]: ZoomOut,
};

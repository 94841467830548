import styled from 'styled-components';
export const LabelLink = styled.a `
  color: var(--blue--bold) !important;
  font-weight: var(--font-weight--regular);

  &:hover {
    color: var(--blue--primary) !important;
    cursor: pointer;
    text-decoration: none;
  }

  &:visited {
    color: $blue--bold;
  }
`;
LabelLink.displayName = 'LabelLink';

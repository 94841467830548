import PropTypes from 'prop-types';
import React from 'react';

import { IconNames } from 'components/icon';
import { TooltipIcon } from 'components/tooltip';

import { colorMainJordan } from 'constants/styles/colors';

const FormTooltip = ({ content }) => {
  if (!content) {
    return null;
  }

  return (
    <div className="form-field--tooltip">
      <TooltipIcon
        content={content}
        contentMargin={12}
        icon={IconNames.INFO_SIGN}
        iconColor={colorMainJordan}
        iconProps={{
          marginLeft: 8,
          position: 'relative',
          top: 3,
        }}
        tooltipProps={{
          statelessProps: { maxWidth: 300 },
        }}
      />
    </div>
  );
};

FormTooltip.propTypes = {
  content: PropTypes.string,
};

FormTooltip.defaultProps = {
  content: '',
};

export default FormTooltip;

import { call, fork, join, put, spawn, take } from 'redux-saga/effects';

import { handleRequestErrors } from 'actions/errors';
import * as actions from 'actions/files';

import { parseCaughtError, parseErrorResponse } from 'helpers/errors';
import * as fileHelpers from 'helpers/fileHelpers';
import * as urlHelpers from 'helpers/urls';

import * as types from 'types/files';

import * as api from './api';

/**
 * Request a file.
 * @return {IterableIterator<*>}
 */
export function* fetchFile(action) {
  const {
    meta = {},
    payload: { accept, url },
  } = action;

  const { requireAuth = true } = meta;

  const requestOptions = { accept, requireAuth };

  // if no errors, this returns a blob; if errors,
  // returns a normal request object with `errors` property
  return yield call(api.fetchFile, url, requestOptions);
}

/**
 * Request file and create an object url in memory.
 * @return {IterableIterator<*>}
 */
export function* fetchFileForObjectUrl(action) {
  let errorData = {};

  try {
    const fetchFileTask = yield fork(fetchFile, action);
    yield join(fetchFileTask);
    const response = fetchFileTask.result();

    if (!response.errors) {
      const objectUrl = urlHelpers.createObjectUrl(response);
      yield put(actions.fetchFileForObjectUrlSuccess(objectUrl));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchFileForObjectUrlFailure, errorData));
}

/**
 * Request and download a file.
 * @return {IterableIterator<*>}
 */
export function* fetchFileForDownload(action) {
  let errorData = {};

  try {
    const fetchFileTask = yield fork(fetchFile, action);
    yield join(fetchFileTask);
    const response = fetchFileTask.result();

    if (!response.errors) {
      const {
        payload: { name },
      } = action;
      yield call(fileHelpers.downloadFile, response, name);
      yield put(actions.fetchFileForDownloadSuccess());
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchFileForDownloadFailure, errorData));
}

/**
 * Listens for redux actions related to funding accounts, sources, and other data.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([types.FETCH_FILE_FOR_OBJECT_URL_REQUEST, types.FETCH_FILE_FOR_DOWNLOAD_REQUEST]);

    switch (action.type) {
      case types.FETCH_FILE_FOR_OBJECT_URL_REQUEST:
        yield spawn(fetchFileForObjectUrl, action);
        break;

      case types.FETCH_FILE_FOR_DOWNLOAD_REQUEST:
        yield spawn(fetchFileForDownload, action);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root files saga.
 * @return {IterableIterator<*>}
 */
export default function* files() {
  yield watch();
}

import PropTypes from 'prop-types';
import React from 'react';

// beware of circular dependencies 😈
import LoadMore from 'components/loadMore';
// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { Tag } from 'components/tag';

import { TagType } from 'constants/ui';

import { hasZeroLength } from 'helpers/utility';

import './TagList.scss';

/**
 * TagList
 * Creates a homogenous list of tags separated by margin
 * @param {Array} tags
 * @returns {StatelessComponent}
 */
const TagList = ({ tags }) => {
  if (hasZeroLength(tags)) {
    return null;
  }

  return (
    <div className="tag-list">
      <LoadMore visibleRows={2}>
        {tags.map((tag) => (
          <Tag key={tag.id} readOnly {...tag}>
            {tag.label || tag.name}
          </Tag>
        ))}
      </LoadMore>
    </div>
  );
};

TagList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hasIcon: PropTypes.bool,
  type: PropTypes.string,
  isClosable: PropTypes.bool,
};

TagList.defaultProps = {
  hasIcon: undefined,
  tags: [],
  type: TagType.DEFAULT,
  isClosable: true,
};

export default TagList;

import { createSelector } from 'reselect';
import { createTransformAttachmentsToBillAttachment, getSelectedBillIndexInitalValue } from 'helpers/billAttachments';
import { getItemAttachmentsFromMap } from 'helpers/itemAttachments';
import {} from 'interfaces/attachment';
import {} from 'interfaces/billAttachment';
import { attachmentsSelector } from 'selectors/attachmentsSelectors';
import { createItemBillViewSelectedBillSelector, createItemFormItemBillsSelector } from 'selectors/forms';
import { itemFromParamSelector, itemPrimaryAttachmentFromParamValueFromUrlSelector } from './itemRouterSelectors';
export const itemAttachmentsSelector = createSelector([itemFromParamSelector, attachmentsSelector], getItemAttachmentsFromMap);
export const itemPrimaryAttachmentSelector = createSelector([itemAttachmentsSelector, itemFromParamSelector], (attachments, item) => attachments.find((attachment) => attachment.id === item?.primaryAttachment));
export const itemBillAttachmentsSelector = createSelector([itemAttachmentsSelector], (attachments) => attachments.map((attachment) => ({
    ...attachment,
    preview: attachment.file,
})));
export const billAttachmentsSelector = createSelector([
    createItemFormItemBillsSelector,
    createItemBillViewSelectedBillSelector,
    itemPrimaryAttachmentFromParamValueFromUrlSelector,
], (attachments = [], selectedBillIndex, primaryAttachment) => {
    const convertIntoBillAttachment = createTransformAttachmentsToBillAttachment(primaryAttachment, selectedBillIndex);
    return attachments.map(convertIntoBillAttachment);
});
export const selectedBillIndexInitialValueSelector = createSelector([billAttachmentsSelector], getSelectedBillIndexInitalValue);
export const selectedBillAttachmentSelector = createSelector([billAttachmentsSelector, createItemBillViewSelectedBillSelector], (billAttachments, selectedBillIndex) => billAttachments[selectedBillIndex]);
export const hasOnlyOneBillAttachmentSelector = createSelector([billAttachmentsSelector], (billAttachments) => billAttachments.length === 1);

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const PanelBodyTitle = ({ children, className }) => {
  const panelClassNames = classNames({
    'panel-body--title': true,
    [className]: !!className,
  });

  return <p className={panelClassNames}>{children}</p>;
};

PanelBodyTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

PanelBodyTitle.defaultProps = {
  className: null,
};

export default PanelBodyTitle;

/**
 * decompressConfig
 * Recursive method used to build out the configuration object used to compare
 * relationships and extended properties.
 * @param {object} compConfigObj - The initial configuration object
 * @param {object|array} accumulator - Internal build object passed inside of the recursive loop.
 * @param {object} isInitial - Declares if the method is the first pass of the recursive loop.
 * @returns {array} - The result will be an array of objects listed as  name<string>, id<string>, relations<array>,
 */
const decompressConfig = (compConfigObj, accumulator = {}, isInitial = true) => {
  // Check if we have an initial config object.
  if (compConfigObj) {
    // If not start of loop then scan next item.
    if (!isInitial) {
      const arrAccumulator = [];

      // For each key add to accumulator array because it is
      // a sub "relationship" item of the configuration.
      Object.keys(compConfigObj).forEach((key) => {
        arrAccumulator.push({
          name: key,
          id: 'id',
          relations: decompressConfig(compConfigObj[key], undefined, false), // Call decompress again until finished.
        });
      });
      return arrAccumulator;
    }

    // For each key add to the accumulator and return
    Object.keys(compConfigObj).forEach((key) => {
      accumulator.name = key;
      accumulator.id = 'id';
      accumulator.relations = decompressConfig(compConfigObj[key], undefined, false); // Call decompress again until finished.
    });
    return accumulator;
  }
  return [];
};

// Export the method as a default to be used in other areas.
export default decompressConfig;

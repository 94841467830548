import * as routines from 'actions/routines/permissionGroups';

import { createAllIdsReducer } from 'store/redux';

const allIdsReducer = createAllIdsReducer({
  key: 'permissionGroup',
  types: [routines.fetchPermissionGroupsRoutine.SUCCESS],
});

export default allIdsReducer;

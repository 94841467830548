import PropTypes from 'prop-types';
import React from 'react';

import TermsOfServiceContent from 'components/form/TermsOfServiceContent';
import { Text } from 'components/text';

import { typography } from 'constants/styles';

/**
 * Terms of service text content and links.
 * @param {Object} props
 * @param {String} props.buttonName
 * @returns {StatelessComponent}
 */
const TermsOfServiceContentText = ({ buttonName }) => (
  <Text.Regular color={typography.TextColor.GREY_X_DARK} size={typography.TextSize.LEVEL_100}>
    <TermsOfServiceContent
      prefix={`We use Routable to facilitate payments. By clicking the '${buttonName}' button below, you`}
    />
  </Text.Regular>
);

TermsOfServiceContentText.propTypes = {
  buttonName: PropTypes.string.isRequired,
};

export default TermsOfServiceContentText;

import React from 'react';

import { Hint, LinkNewPage, Text } from 'components';

import { CONTACT_SUPPORT_LOWERCASE_LABEL } from 'constants/labels';
import { PLATFORM_EMAILS } from 'constants/platform';
import { typography } from 'constants/styles';
import { Intent } from 'constants/ui';

import { getMailtoUrl } from 'helpers/urls';

/**
 * Renders hint displaying information about micro deposits not yet being ready.
 * @return {StatelessComponent}
 */
const MicroDepositsNotYetReadyHint = () => (
  <Hint className="micro-deposits-not-ready-hint hint--micro-deposits" intent={Intent.INFO} multiline>
    <Text.Semibold color={typography.TextColor.YELLOW_MEDIUM} size={typography.TextSize.LEVEL_200}>
      We&apos;re waiting on your micro-deposits
    </Text.Semibold>
    <Text.Semibold color={typography.TextColor.BLACK} size={typography.TextSize.LEVEL_200}>
      {`It doesn&apos;t look like your micro-deposits have arrived yet. If this seems to be a mistake, please `}
      <LinkNewPage href={getMailtoUrl(PLATFORM_EMAILS.CHAT)}>{`${CONTACT_SUPPORT_LOWERCASE_LABEL}.`}</LinkNewPage>
      {` If you would like to change your payment method, please continue.`}
    </Text.Semibold>
  </Hint>
);

export default MicroDepositsNotYetReadyHint;

import PropTypes from 'prop-types';
import React from 'react';

/**
 * Renders the text for tax FAQ disclosure list item.
 * (`value` prop is passed to every item of a disclosure list by default.)
 * @param {ComponentProps} props
 * @param {String} props.value
 * @return {StatelessComponent}
 * @constructor
 */
const TaxFaqListItem = ({ value }) => (
  <p className="font-color--greyXDark font-size--regular tax-faq-disclosure-list--item line-height--standard remove-margin-bottom remove-margin-top">
    {value}
  </p>
);

TaxFaqListItem.propTypes = {
  value: PropTypes.string.isRequired,
};

export default TaxFaqListItem;

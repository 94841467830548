import React from 'react';
import { ButtonV2, FlexRow, Text } from 'components';
import { typography } from 'constants/styles';
import { Intent } from 'constants/ui';
import { ExternalBlock, ExternalBlockItem } from 'modules/external/ExternalBlocksSection';
import {} from './ExternalReceiptPaymentViewDetails.types';
import './ExternalReceiptPaymentViewDetails.scss';
const ExternalReceiptPaymentViewDetails = ({ onClick }) => (React.createElement(ExternalBlock, null,
    React.createElement(ExternalBlockItem, { className: "external-receipt-payment-view-details__trigger" },
        React.createElement(FlexRow, { className: "external-receipt-single-item__details align-items--center justify-content--space-between", stackOnMobile: false },
            React.createElement(Text.Semibold, { size: typography.TextSize.LEVEL_200 }, "Invoice"),
            React.createElement(ButtonV2, { intent: Intent.NEUTRAL, onClick: onClick }, "View details")))));
export default ExternalReceiptPaymentViewDetails;

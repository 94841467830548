import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryContext } from '@routable/shared';
import { itemApprovalsSettingsApi } from '../services/settings';
export const useUpdateItemApprovalsSettings = () => {
    const queryClient = useQueryClient({ context: queryContext });
    return useMutation({
        context: queryContext,
        mutationKey: ['updateItemApprovalsSettings'],
        mutationFn: itemApprovalsSettingsApi.update,
        onSuccess: (data) => {
            queryClient.setQueryData(['itemApprovalsSettings'], data);
        },
    });
};

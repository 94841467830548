import styled from 'styled-components';
export const Container = styled.section `
  --header-height: 3.875rem;
  background: var(--white);
  min-height: 100vh;
`;
export const Header = styled.header `
  background-color: var(--white);
  border-bottom: 1px solid var(--blue--light);
  display: flex;
  justify-content: space-between;
  padding-left: 1.5rem;
  padding-right: 2rem;
  position: sticky;
  top: 0;
  width: 100%;
  height: var(--header-height);
  align-items: center;
  z-index: 1;
`;
export const HeaderRight = styled.div `
  display: flex;
  align-items: center;
`;
export const SaveStatus = styled.div `
  color: var(--grey--x-dark);
  display: flex;
  gap: 0.375rem;
  align-items: center;

  .lds-spinner div::after {
    background: var(--blue--bold);
  }
`;
export const Form = styled.form `
  display: flex;
  min-height: 100%;
`;
export const LeftContainer = styled.div `
  border-right: 1px solid var(--blue--light);
  height: calc(100vh - var(--header-height));
  position: sticky;
  top: var(--header-height);
`;
export const MainContainer = styled.div `
  flex-grow: 1;
  padding: 1rem;
  height: calc(100vh - var(--header-height));
  overflow-y: scroll;
`;

import * as routines from 'actions/routines/countries';

import { createByIdReducer } from 'store/redux';

const byCountryReducer = createByIdReducer({
  key: 'referenceCountry',
  initialState: {},
  types: [routines.fetchCountriesRoutine.SUCCESS],
});

export default byCountryReducer;

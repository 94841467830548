import { createLastUpdatedReducer } from 'store/redux';

import * as types from 'types/item';

export const successCases = [types.FETCH_ADDITIONAL_INVOICES_SUCCESS, types.FETCH_INVOICES_SUCCESS];
export const requestCases = [types.CLEAR_INVOICES];

const lastUpdatedReducer = createLastUpdatedReducer(successCases, requestCases);

export default lastUpdatedReducer;

import { ArrowRight } from '@carbon/icons-react';
import { Clickable, TruncatableText } from '@routable/components';
import clsx from 'clsx';
import React from 'react';
import { getDomainWithOrWithoutCompanyNamespace } from 'helpers/urls';
import { Auth0Client } from 'services/auth0';
import {} from 'types/quickswitch';
import QuickswitchMembershipListIcon from './QuickswitchMembershipListIcon';
const QuickswitchMembershipList = ({ memberships, onSwitchMembership }) => {
    if (!memberships?.length) {
        return null;
    }
    return (React.createElement("section", null, memberships.map((targetMembership, index) => {
        const brandedWorskpaceDomain = getDomainWithOrWithoutCompanyNamespace(targetMembership.companyNamespace).split('//')[1];
        return (React.createElement(Clickable, { className: clsx('cursor-pointer flex group hover:no-underline items-center justify-between p-7', {
                'border-t border-grey-20': index > 0,
            }), key: targetMembership.id, onClick: () => onSwitchMembership(targetMembership.id) },
            React.createElement("div", null,
                React.createElement(TruncatableText, { className: "font-semibold text-blue-70 text-sm group-hover:text-blue-40", position: "top", text: targetMembership.companyName }),
                React.createElement("div", { className: "flex items-center gap-2" },
                    React.createElement(QuickswitchMembershipListIcon, { isReauthenticationRequired: targetMembership.isSSORequired && !Auth0Client.isAuthenticated }),
                    React.createElement("span", { className: "text-sm text-grey-60" }, brandedWorskpaceDomain))),
            React.createElement(ArrowRight, { className: "text-grey-60 group-hover:text-blue-40" })));
    })));
};
export default QuickswitchMembershipList;

import { getItemMemberMenuText, getPastItemMemberMenuText } from 'helpers/itemMember';
import { isContactsInteractionListViewTypeThread } from 'helpers/ui';
import {} from 'interfaces/item';
export const getContactListMenuItemsText = (view, contact, item) => {
    if (isContactsInteractionListViewTypeThread(view)) {
        return {
            ...getItemMemberMenuText(contact, item),
            ...getPastItemMemberMenuText(contact, item),
        };
    }
    return {
        editText: 'Edit contact',
        removeText: 'Remove contact',
    };
};

import PropTypes from 'prop-types';
import React from 'react';

import { HeadingBlockDisclosureList } from 'components/disclosureList';
import { IconNames } from 'components/icon';

import { isCurrentCompanyTypeBusiness } from 'helpers/currentCompany';

import {
  createBusinessDisclosureListItems,
  createDateCompletedItem,
  createIndividualDisclosureListItems,
} from 'modules/external/ExternalCollectTaxInfo/helpers/createDisclosureListItems';

/**
 * Statically display filing details for a business, including the date these details for collected or confirmed.
 * @returns {StatelessComponent}
 * @constructor
 */
const FilingDetailsDisclosureList = (props) => {
  const {
    businessType,
    className,
    companyName,
    companyType,
    firstName,
    isMultiPartnerConfirm,
    lastName,
    showGenericTin,
    submissionDate,
    tinType,
    viewCompletionDate,
  } = props;

  const items = isCurrentCompanyTypeBusiness({ companyType })
    ? createBusinessDisclosureListItems({
        businessType,
        companyName,
        showGenericTin,
        tinType,
      })
    : createIndividualDisclosureListItems({ firstName, lastName });

  // Show the date the filing details were collected or confirmed
  if (viewCompletionDate) {
    const dateItem = createDateCompletedItem({
      isMultiPartnerConfirm,
      submissionDate,
    });
    items.push(dateItem);
  }

  return (
    <div className={className}>
      <HeadingBlockDisclosureList
        headerIconName={IconNames.FOLDER_OPEN}
        headerText="Filing details"
        items={items.map((item) => ({
          data: {
            className: 'add-partner-funding-account--options--body',
          },
          key: item.label,
          label: item.label,
          value: item.value,
        }))}
        keyExtractor={(item) => item.key}
        startOpen
      />
    </div>
  );
};

/**
 * TODO: [FRON-1376] use custom propType checkers for business v.s. personal display when building DisclosureListItems.
 * For example, if the companyType is business: businessType, companyName, and tinType must be defined.
 */
FilingDetailsDisclosureList.propTypes = {
  businessType: PropTypes.string,
  className: PropTypes.string,
  companyName: PropTypes.string,
  companyType: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  isMultiPartnerConfirm: PropTypes.bool,
  lastName: PropTypes.string,
  showGenericTin: PropTypes.bool,
  submissionDate: PropTypes.string,
  tinType: PropTypes.string,
  viewCompletionDate: PropTypes.bool,
};

FilingDetailsDisclosureList.defaultProps = {
  businessType: undefined,
  className: undefined,
  companyName: undefined,
  firstName: undefined,
  isMultiPartnerConfirm: undefined,
  lastName: undefined,
  showGenericTin: undefined,
  submissionDate: undefined,
  tinType: undefined,
  viewCompletionDate: undefined,
};

export default FilingDetailsDisclosureList;

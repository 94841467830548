/**
 * Description text for the payment method part of the external/partner invite flow
 * @return {string}
 */
export const getStepThreeFieldLabelDescription = () =>
  'Please add a valid payment method and click "Confirm" to process your payment.';

/**
 * Title text for the payment method part of the external/partner invite flow
 * @param isExternalPathAccept
 * @return {string}
 */
export const getStepThreeFieldLabelText = (isExternalPathAccept) => {
  if (isExternalPathAccept) {
    return 'How do you want to receive payment?';
  }

  return 'How do you want to pay?';
};

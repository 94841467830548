import React from 'react';

import { ExternalUpdatePaymentFlowState } from 'constants/ui';

import UpdatePaymentIllustrationSvg from '../UpdatePaymentIllustrationSvg';

/**
 * Renders SuccessIllustrationSvg based on external update payment flow state.
 * @return {StatelessComponent}
 */
const SuccessIllustrationSvg = () => (
  <UpdatePaymentIllustrationSvg updatePaymentState={ExternalUpdatePaymentFlowState.CONFIRMATION} />
);

export default SuccessIllustrationSvg;

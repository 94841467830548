import PropTypes from 'prop-types';
import React from 'react';

import BaseHint from 'components/hintTypes/BaseHint';
import { numberOfErrorsShownHintText } from 'components/hintTypes/constants/text';
import { getNumberOfErrorsShownHintTitle } from 'components/hintTypes/helpers/text';

import { MAX_NUMBER_OF_DISPLAYED_ERRORS } from 'constants/bulkActions';
import { Intent } from 'constants/ui';

import { isLessOrEqual } from 'helpers/utility';

/**
 * Hint used in the bulk import items flow to notify the user of the number of errors shown
 * @param {ComponentProps} props
 * @param {Object[]} props.issues
 * @param {RestOfProps} rest
 * @return {StatelessComponent|null}
 */
const NumberOfErrorsShownHint = ({ issues, ...rest }) => {
  const { total } = issues.meta;

  if (isLessOrEqual(total, MAX_NUMBER_OF_DISPLAYED_ERRORS)) {
    return null;
  }

  return (
    <BaseHint
      intent={Intent.INFO}
      text={numberOfErrorsShownHintText}
      title={getNumberOfErrorsShownHintTitle(total)}
      {...rest}
    />
  );
};

NumberOfErrorsShownHint.propTypes = {
  className: PropTypes.string,
  issues: PropTypes.shape({
    meta: PropTypes.shape({
      total: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

NumberOfErrorsShownHint.defaultProps = {
  className: undefined,
};

export default NumberOfErrorsShownHint;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './TermsScrollBox.scss';

/**
 * Overflow-y scrollbox for showing terms of service, legalese, etc.
 * @param {string} [className]
 * @param {string[]} paragraphs
 * @param {string} title
 * @returns {StatelessComponent}
 * @constructor
 */
const TermsScrollBox = ({ className, paragraphs, title }) => (
  <div
    className={classNames({
      [className]: !!className,
      'terms-scroll-box': true,
    })}
  >
    <div className="title">{title}</div>
    <div className="body">
      {paragraphs.map((paragraph, i) => (
        // Paragraphs don't change, so using the index is ok
        // eslint-disable-next-line react/no-array-index-key
        <div className="paragraph" key={i}>
          {paragraph}
        </div>
      ))}
    </div>
  </div>
);

TermsScrollBox.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  paragraphs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TermsScrollBox.defaultProps = {
  className: undefined,
};

export default TermsScrollBox;

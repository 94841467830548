import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

const withHistory = (Component) => {
  const ComponentWithHistory = ({ history, ...rest }) => <Component history={history} {...rest} />;

  ComponentWithHistory.propTypes = {
    history: PropTypes.shape().isRequired,
  };

  return ComponentWithHistory;
};

const enhance = compose(withRouter, withHistory);

export default (component) => enhance(component);

export const DISABLE_MEMBERSHIP = '@member/DISABLE_MEMBERSHIP';
export const ENABLE_MEMBERSHIP = '@member/ENABLE_MEMBERSHIP';

export const FETCH_MEMBERSHIP_CONFIG_FAILURE = 'FETCH_MEMBERSHIP_CONFIG_FAILURE';
export const FETCH_MEMBERSHIP_CONFIG_REQUEST = 'FETCH_MEMBERSHIP_CONFIG_REQUEST';
export const FETCH_MEMBERSHIP_CONFIG_SUCCESS = 'FETCH_MEMBERSHIP_CONFIG_SUCCESS';

export const FETCH_MEMBERSHIP_FAILURE = 'FETCH_MEMBERSHIP_FAILURE';
export const FETCH_MEMBERSHIP_REQUEST = 'FETCH_MEMBERSHIP_REQUEST';
export const FETCH_MEMBERSHIP_SUCCESS = 'FETCH_MEMBERSHIP_SUCCESS';

export const FETCH_MEMBERSHIPS_FAILURE = 'FETCH_MEMBERSHIPS_FAILURE';
export const FETCH_MEMBERSHIPS_REQUEST = 'FETCH_MEMBERSHIPS_REQUEST';
export const FETCH_MEMBERSHIPS_SUCCESS = 'FETCH_MEMBERSHIPS_SUCCESS';

export const UPDATE_MEMBERSHIP_FAILURE = 'UPDATE_MEMBERSHIP_FAILURE';
export const UPDATE_MEMBERSHIP_REQUEST = 'UPDATE_MEMBERSHIP_REQUEST';
export const UPDATE_MEMBERSHIP_SUCCESS = 'UPDATE_MEMBERSHIP_SUCCESS';

export const UPDATE_ONBOARDING_MEMBERSHIP_FAILURE = 'UPDATE_ONBOARDING_MEMBERSHIP_FAILURE';
export const UPDATE_ONBOARDING_MEMBERSHIP_REQUEST = 'UPDATE_ONBOARDING_MEMBERSHIP_REQUEST';
export const UPDATE_ONBOARDING_MEMBERSHIP_SUCCESS = 'UPDATE_ONBOARDING_MEMBERSHIP_SUCCESS';

import {} from 'axios';
import { AnnouncementActionType } from 'enums/announcement';
import { ANNOUNCEMENT_BASE_ENDPOINT, ANNOUNCEMENT_UPDATE_ENDPOINT } from 'services/api/announcementEndpoints';
import { createApiRoutine } from './createApiRoutine';
export const announcementFetchRoutine = createApiRoutine(AnnouncementActionType.FETCH_ANNOUNCEMENTS, {
    endpoint: ANNOUNCEMENT_BASE_ENDPOINT,
    method: 'GET',
    requireAuth: true,
    headers: {},
});
export const announcementUpdateRoutine = createApiRoutine(AnnouncementActionType.UPDATE_ANNOUNCEMENT, {
    endpoint: ANNOUNCEMENT_UPDATE_ENDPOINT,
    method: 'POST',
    requireAuth: true,
    headers: {},
}, {
    trigger: (announcement) => ({
        payload: {
            data: {
                attributes: {
                    feature_announcement: announcement,
                },
                type: 'UserFeatureAnnouncement',
            },
        },
    }),
});

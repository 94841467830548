import styled from 'styled-components';
export const Content = styled.div `
  padding: 1.5rem;
`;
export const Fieldset = styled.fieldset `
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;
export const FieldWrapper = styled.div `
  flex-grow: 1;
`;
export const Legend = styled.legend `
  color: var(--grey--xx-dark);
  font-weight: 600;
  font-size: 0.875rem;
`;
export const LegendHelper = styled.p `
  color: var(--grey--xx-dark);
  font-size: 0.813rem;
  margin-top: 0.5rem;
  margin-bottom: 0;
  line-height: 1.25rem;
`;
export const Row = styled.div `
  display: flex;
  gap: 0.625rem;
`;

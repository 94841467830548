import { combineReducers } from 'redux';

import allIds from './allIdsReducer';
import byId from './byIdReducer';

const transactionItemsReducer = combineReducers({
  allIds,
  byId,
});

export default transactionItemsReducer;

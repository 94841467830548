import { reset } from 'redux-form';

import { changeView, toggleSkipStepModal, setNextView } from 'actions/signUpFlow';

import { storeAccessor as store } from 'store/accessor';

import { signupFlowForm, SAVE_STATUSES } from '../constants';

export const triggerSkipStep = (saveStatus, onSkipPath) => {
  if (saveStatus === SAVE_STATUSES.notSaved) {
    store.dispatch(setNextView(onSkipPath));
    store.dispatch(toggleSkipStepModal());
    return;
  }
  store.dispatch(reset(signupFlowForm));
  store.dispatch(changeView(onSkipPath));
};

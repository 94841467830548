import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { FormControl, Radio, ReduxFormFieldRenderInput, Text } from 'components';

import { bankAccountFields } from 'constants/formFields';
import { FundingAccountTypes } from 'constants/funding';
import { typography } from 'constants/styles';
import { field } from 'constants/styles/formStyles';

import { digitsOnly } from 'helpers/fieldNormalizers';
import {
  bankAccountNumberValidator,
  bankRoutingNumberValidator,
  requiredValidator,
  sameAccountNumberValidator,
} from 'helpers/fieldValidation';

import { getConnectBankAccountMidFormLabelText } from 'modules/connectBank/connectBankManual/helpers/text';

/**
 * Form fields for ConnectBankManualForm.
 * Used for adding a new manual bank account.
 * @param {String} bankName
 * @param {String} formPath
 * @param {Boolean} isBankAccountNumberValid
 * @param {Boolean} isPartnerAccount
 * @return {StatelessComponent}
 */
const ConnectBankManualForm = ({ bankName, formPath, isBankAccountNumberValid, isPartnerAccount }) => (
  <React.Fragment>
    <FormControl className="display--flex margin-bottom--large">
      <Field
        component={Radio}
        id="fundingAccountTypeChecking"
        isHorizontalLayout
        name={`${formPath}.${bankAccountFields.ACCOUNT_TYPE}`}
        optionText="Add a checking account"
        type="radio"
        value={FundingAccountTypes.CHECKING}
      />

      <Field
        component={Radio}
        id="fundingAccountTypeSaving"
        isHorizontalLayout
        name={`${formPath}.${bankAccountFields.ACCOUNT_TYPE}`}
        optionText="Add a savings account"
        type="radio"
        value={FundingAccountTypes.SAVINGS}
      />
    </FormControl>

    <Text.Regular color={typography.TextColor.GREY_X_DARK}>
      {getConnectBankAccountMidFormLabelText(isPartnerAccount)}
    </Text.Regular>

    <FormControl className="flex-direction--column margin-top--m">
      <Field
        className="w-full"
        component={ReduxFormFieldRenderInput}
        dataFullStory
        isCharacterCountShown={false}
        maximumCharacterCount={9}
        name={`${formPath}.${bankAccountFields.ROUTING_NUMBER}`}
        normalize={digitsOnly}
        placeholder="Routing number"
        showValidationIcon
        type="text"
        validate={[requiredValidator, bankRoutingNumberValidator]}
      />

      {bankName && (
        <Text.Regular
          className="display--block line-height--xm--important margin-top--m"
          color={typography.TextColor.GREY_X_DARK}
          size={typography.TextSize.LEVEL_100}
        >
          {`This is a routing number for: ${bankName}`}
        </Text.Regular>
      )}
    </FormControl>

    <FormControl>
      <Field
        className={field.xl.left}
        component={ReduxFormFieldRenderInput}
        dataFullStory
        isCharacterCountShown={false}
        maximumCharacterCount={17}
        name={`${formPath}.${bankAccountFields.ACCOUNT_NUMBER}`}
        normalize={digitsOnly}
        placeholder="Account number"
        showValidationIcon={isBankAccountNumberValid}
        type="text"
        validate={[requiredValidator, bankAccountNumberValidator]}
      />

      <Field
        className={field.xl.right}
        component={ReduxFormFieldRenderInput}
        dataFullStory
        isCharacterCountShown={false}
        maximumCharacterCount={17}
        name={`${formPath}.${bankAccountFields.CONFIRM_ACCOUNT_NUMBER}`}
        normalize={digitsOnly}
        placeholder="Confirm account number"
        showValidationIcon
        type="text"
        validate={[requiredValidator, bankAccountNumberValidator, sameAccountNumberValidator]}
      />
    </FormControl>
  </React.Fragment>
);

ConnectBankManualForm.propTypes = {
  bankName: PropTypes.string,
  formPath: PropTypes.string,
  isBankAccountNumberValid: PropTypes.bool,
  isPartnerAccount: PropTypes.bool,
};

ConnectBankManualForm.defaultProps = {
  bankName: undefined,
  formPath: 'form',
  isBankAccountNumberValid: undefined,
  isPartnerAccount: undefined,
};

export default ConnectBankManualForm;

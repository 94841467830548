import PropTypes from 'prop-types';
import React from 'react';

import { RadioInput } from 'components';
import { reduxFormInputPropType } from 'components/commonProps';

import { BankAccountViewType } from 'constants/ui';

import { BankAccountHeader } from 'modules/fundingAccount/bankAccount/components';

const BankAccountRadioHeader = (props) => {
  const { id, input, isDisabled, ...rest } = props;

  return <BankAccountHeader {...rest} contentLeft={<RadioInput id={id} input={input} isDisabled={isDisabled} />} />;
};

BankAccountRadioHeader.propTypes = {
  fieldName: PropTypes.string,
  fundingAccount: PropTypes.shape({
    bank: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    ledgerRefPayable: PropTypes.string,
    ledgerRefReceivable: PropTypes.string,
    name: PropTypes.string,
  }),
  fundingInfoAddress: PropTypes.shape(),
  fundingInfoBankAccount: PropTypes.shape(),
  id: PropTypes.string.isRequired,
  input: reduxFormInputPropType.isRequired,
  inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  menu: PropTypes.func,
  onInputBlur: PropTypes.func,
  onInputChange: PropTypes.func,
  partnership: PropTypes.shape(),
  partnershipFundingAccount: PropTypes.shape(),
  viewType: PropTypes.oneOf(Object.values(BankAccountViewType)),
};

BankAccountRadioHeader.defaultProps = {
  fieldName: undefined,
  fundingAccount: {},
  fundingInfoAddress: {},
  fundingInfoBankAccount: {},
  inputValue: undefined,
  isChecked: undefined,
  isDisabled: undefined,
  menu: undefined,
  onInputBlur: undefined,
  onInputChange: undefined,
  partnership: undefined,
  partnershipFundingAccount: undefined,
  viewType: BankAccountViewType.FULL,
};

export default BankAccountRadioHeader;

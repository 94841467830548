import React from 'react';
import {} from 'redux';
import { lookupMembershipInviteEmailHandler } from 'actions/routines/inviteTeamMember';
import { EmailAlreadyInvitedError } from '../components/EmailAlreadyInvitedError';
export const alreadyInvitedAsyncValidation = (value, dispatch, props) => {
    const lookupPromise = lookupMembershipInviteEmailHandler(value, dispatch, props);
    const intoFormValidationError = (error) => {
        const { memberships = [], membershipInvites = [] } = Object(error?.warnings?.form?.email);
        const member = memberships[0] || membershipInvites[0];
        return Promise.reject({
            form: {
                email: [React.createElement(EmailAlreadyInvitedError, { key: "email-already-invited", member: member })],
            },
        });
    };
    return lookupPromise.catch(intoFormValidationError);
};

import { z } from 'zod';
import { optionsModel, valuesModel } from './common.model';
export const conditionFieldEnum = z.enum(['account', 'creator', 'department', 'location', 'vendor']);
export const conditionValuesModel = z.object({
    label: z.string().optional(),
    values: valuesModel,
    field: z.string().optional(),
    valuesUrl: z.string().optional(),
});
export const conditionModel = z.object({
    key: z.string(),
    label: z.string(),
    valuesUrl: z.string(),
});
export const conditionApiListModel = z.preprocess((raw) => {
    const resp = raw;
    return {
        ...resp,
        data: resp.data,
    };
}, z.object({
    data: conditionModel.array(),
}));
export const conditionDataApiListModel = z.preprocess((raw) => {
    const resp = raw;
    return {
        ...resp,
        data: resp.data,
    };
}, z.object({
    data: optionsModel,
}));

import {} from '@routable/shared';
export const vendorRiskCheckReport = 'VendorRiskCheckReport';
export const generateKeys = {
    bulkRiskChecks: () => [vendorRiskCheckReport, 'bulkRiskChecks'],
    latestReport: (partnershipId) => [vendorRiskCheckReport, 'latestReport', partnershipId],
    dismissLatestReportWatchlistHits: (vendorId) => [
        vendorRiskCheckReport,
        'dismissLatestReportWatchlistHits',
        vendorId,
    ],
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Dropzone } from 'complexComponents';
import Spinner from 'components/buttonV2/components/Spinner';
import { Intent } from 'constants/ui';
import { documentsIsPosting } from 'selectors/documentsSelectors';
import FilesList from './FilesList';
import FileError from './FilesList/FileError';
import { File } from './FilesList/FileItem/FileItem';
import { Container, UploadFileHint } from './UploadFile.styles';
import {} from './UploadFile.types';
const UploadFile = ({ fields, hintText, remove, addFile, supportText, accept, helpMessage, errorMessage, isDisabled, isFetching, showDropzone = true, }) => {
    const postingDocs = useSelector(documentsIsPosting);
    const { isPosting, file: documentBeingUploaded = {} } = postingDocs;
    const handleAddFile = (file) => {
        addFile(file, () => fields.push(file));
    };
    return (React.createElement(Container, null,
        hintText && React.createElement(UploadFileHint, { intent: Intent.INFO, title: hintText }),
        showDropzone && (React.createElement(Dropzone, { accept: accept, addFile: handleAddFile, errorMessage: errorMessage, helpMessage: helpMessage, isDisabled: isDisabled, supportText: supportText })),
        !isFetching && React.createElement(FilesList, { files: fields, isDisabled: isDisabled, remove: remove }),
        isPosting && (React.createElement(File, { input: {
                value: documentBeingUploaded,
            }, isUploading: true })),
        isFetching && React.createElement(Spinner, { useBlueVariant: true }),
        fields.map((field, index) => {
            const key = `${field}_${index}_error`;
            return React.createElement(FileError, { file: field, key: key });
        })));
};
export default UploadFile;

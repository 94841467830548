import * as types from 'types/maintenance';

export const handleMaintenanceMode = () => ({
  type: types.HANDLE_MAINTENANCE_MODE,
});

export const maintenanceModeModalOpen = () => ({
  type: types.MODAL_MAINTENANCE_MODE_OPEN,
});

export const maintenanceModeModalClose = () => ({
  type: types.MODAL_MAINTENANCE_MODE_CLOSE,
});

export const maintenanceModeOn = () => ({
  type: types.MAINTENANCE_MODE_ON,
});

export const maintenanceModeOff = () => ({
  type: types.MAINTENANCE_MODE_OFF,
});

import { ssoSetupFormEditSubmitRoutine, ssoSetupFormSubmitRoutine } from 'actions/routines/sso';
import { SSOActionType } from 'enums/sso';
import {} from 'interfaces/sso';
export const initialState = {
    ssoProvider: undefined,
    ssoRequired: false,
    company: undefined,
};
const ssoSetupFormReducer = (state = initialState, action = null) => {
    switch (action.type) {
        case SSOActionType.OPEN_SETUP_FORM_MODAL_WITH_PROVIDER:
            return {
                ...state,
                ssoProvider: action.payload.ssoProvider,
            };
        case ssoSetupFormSubmitRoutine.SUCCESS:
        case ssoSetupFormEditSubmitRoutine.SUCCESS:
        case ssoSetupFormSubmitRoutine.FAILURE:
        case ssoSetupFormEditSubmitRoutine.FAILURE:
            return initialState;
        default:
            return state;
    }
};
export default ssoSetupFormReducer;

import { PaymentDeliveryMethodType } from '@routable/shared';
import PropTypes from 'prop-types';
import React from 'react';

import { ItemKinds } from 'constants/item';

import { oneOfValuesFromObject } from 'helpers/propTypes';

import { getPaymentMethodsAccepted } from 'modules/createPartnerCompany/helpers/paymentMethods';

import PartnerCompanyPaymentMethodAch from '../PartnerCompanyPaymentMethodAch';
import PartnerCompanyPaymentMethodCheck from '../PartnerCompanyPaymentMethodCheck';

/**
 * Renders available ACH and Check payment method options
 * @param  {ComponentProps} props
 * @param  {String} props.fieldNamePrefix
 * @param  {FundingAccount.id} props.fundingAccount
 * @param  {Item} props.item
 * @param  {ItemKinds} [props.itemKind='receivable']
 * @param  {Function} props.onCheckFundingAccountSelected
 * @param  {Function} props.onFundingAccountAddressSelected
 * @param  {Partnership} props.partnership
 * @param  {String} props.paymentDeliveryMethod
 */
const PartnerCompanyAcceptPayment = ({
  fieldNamePrefix,
  fundingAccount,
  item,
  itemKind,
  onCheckFundingAccountSelected,
  onFundingAccountAddressSelected,
  partnership,
  paymentDeliveryMethod,
}) => {
  const paymentDeliveryMethodsAccepted = getPaymentMethodsAccepted(partnership, itemKind, item);

  // When the item is not completely loaded, paymentDeliveryMethodsAccepted will be undefined
  if (!paymentDeliveryMethodsAccepted) {
    return null;
  }

  return (
    <div className="form-container">
      <div className="margin-bottom--large">
        {paymentDeliveryMethodsAccepted.includes(PaymentDeliveryMethodType.ACH) && (
          <div className="margin-bottom--m">
            <PartnerCompanyPaymentMethodAch
              fieldNamePrefix={fieldNamePrefix}
              fundingAccount={fundingAccount}
              itemKind={itemKind}
              partnership={partnership}
              paymentDeliveryMethod={paymentDeliveryMethod}
            />
          </div>
        )}

        {paymentDeliveryMethodsAccepted.includes(PaymentDeliveryMethodType.CHECK) && (
          <PartnerCompanyPaymentMethodCheck
            fieldName={`${fieldNamePrefix || ''}paymentDeliveryMethod`}
            fundingAccount={fundingAccount}
            onFundingAccountAddressSelected={onFundingAccountAddressSelected}
            onFundingAccountSelected={onCheckFundingAccountSelected}
            paymentDeliveryMethod={paymentDeliveryMethod}
          />
        )}
      </div>
    </div>
  );
};

PartnerCompanyAcceptPayment.propTypes = {
  fieldNamePrefix: PropTypes.string,
  // formId prop used in mapStateToProps
  /* eslint-disable-next-line react/no-unused-prop-types */
  formId: PropTypes.string.isRequired,
  fundingAccount: PropTypes.string,
  item: PropTypes.shape(),
  itemKind: oneOfValuesFromObject(ItemKinds),
  partnership: PropTypes.shape().isRequired,
  onCheckFundingAccountSelected: PropTypes.func.isRequired,
  onFundingAccountAddressSelected: PropTypes.func.isRequired,
  paymentDeliveryMethod: PropTypes.string,
};

PartnerCompanyAcceptPayment.defaultProps = {
  fieldNamePrefix: undefined,
  fundingAccount: undefined,
  item: undefined,
  itemKind: ItemKinds.RECEIVABLE,
  paymentDeliveryMethod: undefined,
};

export default PartnerCompanyAcceptPayment;

import _set from 'lodash/set';

import { createItemFormFields } from 'constants/formFields';

import { firstKey } from 'helpers/utility';

/**
 * Returns the next form state to set in relevant form plugins after dispatching
 * submitItemRoutine.fulfill().
 * @param {ReduxFormState} state
 * @param {ReduxAction} action
 * @return {Object}
 */
export const getNextFormStateForCreateItemSubmitFulfilled = (state, action) => {
  const { item } = action.payload;

  const itemId = firstKey(item);

  const nextState = { ...state };
  _set(nextState.values, createItemFormFields.ITEM_ID, itemId);

  return nextState;
};

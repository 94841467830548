import { itemRelationships } from 'constants/relationships';

import { createByIdReducer } from 'store/redux';

import { FETCH_PARTNERSHIP_ITEMS_SUCCESS } from 'types/partnership';

/**
 * Action types on which the partnershipItems byId state should be updated
 * @constant
 * @type {string[]}
 */
const actionTypes = [FETCH_PARTNERSHIP_ITEMS_SUCCESS];

/**
 * Partnership Items bu Id map
 *
 * @type {ReduxReducer}
 */
const partnershipItemsByIdReducer = createByIdReducer({
  initialState: {},
  key: 'item',
  relationships: itemRelationships,
  types: actionTypes,
});

export default partnershipItemsByIdReducer;

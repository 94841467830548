import PropTypes from 'prop-types';
import React from 'react';

import { TooltipContentText } from 'components';

import { isArrayLengthEqual, isLessThan, lengthOf } from 'helpers/utility';

import * as textHelpers from '../helpers/text';

/**
 * Renders the tooltip content of the SummaryOfContactsText component
 * @param {ComponentProps} props
 * @param {PartnershipMember[]} props.partnershipMembers
 * @return {StatelessComponent}
 */
const SummaryOfContactsTooltipContent = ({ partnershipMembers }) => {
  if (isArrayLengthEqual(partnershipMembers, 0)) {
    return null;
  }

  return partnershipMembers.map((partnershipMember, index) => {
    const isNotLast = isLessThan(index + 1, lengthOf(partnershipMembers));
    const content = textHelpers.getSummaryOfContactsTooltipContent({
      isNotLast,
      partnershipMember,
    });

    return (
      <React.Fragment key={partnershipMember.id}>
        <TooltipContentText>{content}</TooltipContentText>
        {isNotLast && <br />}
      </React.Fragment>
    );
  });
};

SummaryOfContactsTooltipContent.propTypes = {
  partnershipMembers: PropTypes.arrayOf(PropTypes.shape({})),
};

SummaryOfContactsTooltipContent.defaultProps = {
  partnershipMembers: undefined,
};

export default SummaryOfContactsTooltipContent;

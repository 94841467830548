import * as routines from 'actions/routines/permissions';

import { createErrorReducer } from 'store/redux';

export const failureCases = [routines.fetchPermissionsRoutine.FAILURE];

export const successCases = [routines.fetchPermissionsRoutine.REQUEST, routines.fetchPermissionsRoutine.SUCCESS];

const errorsReducer = createErrorReducer(successCases, failureCases);

export default errorsReducer;

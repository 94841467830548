import React from 'react';

import { getFormattedTableData, getTableDataConfig } from './data';

/**
 * Hook to memoize the table values, which should essentially never re-compute after initial load.
 * @param props
 * @return {{ tableConfig: Object, tableData: Object[]}}
 */
export const useBalanceTransactionsTableData = ({
  balanceFundingAccount,
  billingDataByCode,
  fundingAccountsById,
  fundingInfoBankAccountsById,
  items,
  transactions,
}) => {
  const tableConfig = React.useMemo(() => getTableDataConfig(), []);

  const tableData = React.useMemo(
    () =>
      getFormattedTableData({
        balanceFundingAccount,
        billingDataByCode,
        fundingAccountsById,
        fundingInfoBankAccountsById,
        items,
        transactions,
      }),
    [balanceFundingAccount, billingDataByCode, fundingAccountsById, fundingInfoBankAccountsById, items, transactions],
  );

  return { tableConfig, tableData };
};

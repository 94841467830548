import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { openCreateManualAddressModal } from 'actions/createManualAddress';
import { submitFundingStateRoutineWithPayload } from 'actions/routines/external';

import { formNamesExternal } from 'constants/forms';

import withWarnAboutUnsavedChanges from 'hoc/withWarnAboutUnsavedChanges';

import { makeFormSubmitFailHandler } from 'modules/createPartnerCompany/helpers/submitFailure';
import { alertOptions } from 'modules/external/externalUpdatePaymentMethod/containers/constants';

import { isCurrentCompanyMaxBankAccountsReachedHintVisibleSelector } from 'queries/currentCompanyCompoundSelectors';
import {
  fundingAccountsAllForAchProviderSelector,
  fundingAccountsAllForAddressProviderSelector,
} from 'queries/fundingCompoundSelectors';
import {
  isFormFundingInternalAndInvalidOrPendingSelector,
  updatePaymentFormInitialValuesSelector,
} from 'queries/updatePaymentFormSelectors';

import {
  externalDefaultReceivableSelector,
  externalFormUISelector,
  externalPaymentDeliveryMethodSelector,
} from 'selectors/forms';
import { fundingAccountsByIdSelector, fundingAccountsLastUpdatedSelector } from 'selectors/fundingSelectors';
import { modalConnectBankManualIsOpenSelector, modalCreateManualAddressIsOpenSelector } from 'selectors/modalsSelector';
import { partnershipFundingStatesErrorsSelector } from 'selectors/partnershipFundingStatesSelectors';

import UpdatePaymentMethodFormWithCheckboxAndHintsDomestic from './UpdatePaymentMethodFormWithCheckboxAndHintsDomestic';

const formName = formNamesExternal.UPDATE_PAYMENT_METHOD;

export const mapStateToProps = createStructuredSelector({
  defaultReceivable: externalDefaultReceivableSelector,
  errors: partnershipFundingStatesErrorsSelector,
  formUI: externalFormUISelector,
  fundingAccounts: fundingAccountsByIdSelector,
  fundingAccountsWithAchFundingSource: fundingAccountsAllForAchProviderSelector,
  fundingAccountsWithAddressFundingSources: fundingAccountsAllForAddressProviderSelector,
  initialValues: updatePaymentFormInitialValuesSelector,
  isAddressModalOpen: modalCreateManualAddressIsOpenSelector,
  isConnectBankModalOpen: modalConnectBankManualIsOpenSelector,
  lastSubmittedFundingAccount: fundingAccountsLastUpdatedSelector,
  shouldShowMicroDepositsHint: isFormFundingInternalAndInvalidOrPendingSelector,
  shouldShowMaxPaymentMethodsHint: isCurrentCompanyMaxBankAccountsReachedHintVisibleSelector,
  paymentDeliveryMethod: externalPaymentDeliveryMethodSelector,
});

export const mapDispatchToProps = {
  onOpenCreateManualAddressModal: openCreateManualAddressModal,
  onSubmitAllExistingItems: submitFundingStateRoutineWithPayload,
  onValuesChange: change,
};

const createUpdatePaymentForm = reduxForm({
  destroyOnUnmount: false,
  form: formName,
  onSubmitFail: makeFormSubmitFailHandler(formName),
})(UpdatePaymentMethodFormWithCheckboxAndHintsDomestic);

const connectedForm = connect(mapStateToProps, mapDispatchToProps)(createUpdatePaymentForm);

export default withWarnAboutUnsavedChanges(connectedForm, formName, alertOptions);

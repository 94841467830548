import pluralize from 'pluralize';

import { getMembershipNameOrEmailOrPhoneNumber } from 'helpers/memberships';
import { hasZeroLength, lengthOf } from 'helpers/utility';

/**
 * Returns text to display for left content in ContactsInteractionBar.
 * @function
 * @param {Object} options
 * @param {Array.<PartnershipMember>} [options.contacts]
 * @param {PartnershipMember} [options.importedContact]
 * @param {LedgerIntegration} [options.ledger]
 * @param {Boolean} [options.isVendorContactOptional]
 * @returns {string}
 */
export const getContactsInteractionBarLeftContentText = ({
  contacts,
  importedContact,
  ledger,
  isVendorContactOptional,
}) => {
  const hasNoContacts = hasZeroLength(contacts);
  const numberOfContacts = lengthOf(contacts);
  const contactsText = pluralize('contact', numberOfContacts);

  if (hasNoContacts) {
    if (importedContact) {
      const identifierText = getMembershipNameOrEmailOrPhoneNumber(importedContact);
      return `${identifierText} is an existing contact on ${ledger.name}.`;
    }

    return isVendorContactOptional ? 'Contacts' : 'No contacts added';
  }

  return `${lengthOf(contacts)} ${contactsText}`;
};

/**
 * Returns text to display for left content in PastMembersInteractionBar.
 * @function
 * @param {Array.<PartnershipMember>} contacts
 * @returns {string}
 */
export const getPastItemMembersInteractionBarLeftContentText = (contacts) => {
  const numberOfContacts = lengthOf(contacts);
  const contactsText = pluralize('contact', numberOfContacts);

  return `${numberOfContacts} ${contactsText} previously notified`;
};

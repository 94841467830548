import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2, Col, Icon, IconNames, LinkNewPage, Row } from 'components';

import { ButtonSize } from 'constants/button';
import { breakpointNames } from 'constants/mediaQuery';
import { PermissionResourceAction } from 'constants/permissions';
import { colors, sizes } from 'constants/styles';

import { WithPermissionRestriction, RestrictedPermissionTooltip } from 'modules/auth';
import { text } from 'modules/fundingAccount/bankAccount/constants';
import { textHelpers } from 'modules/fundingAccount/bankAccount/helpers';

const AccountHintSectionVerifyActions = ({ accountNumber, onButtonClick }) => (
  <Row
    breakpointProps={{
      [breakpointNames.phone]: {
        flexWrap: 'no-wrap',
      },
    }}
    flexWrap="wrap"
  >
    <Col className="margin-bottom--m">
      <WithPermissionRestriction
        requiredPermissions={[PermissionResourceAction.FUNDING_ACCOUNTS_CREATE]}
        WithoutPermissionComponent={
          <RestrictedPermissionTooltip>
            <ButtonV2
              className="verify-deposits-button"
              isDisabled
              leftIconClassName="margin-right--sm"
              leftIconName={IconNames.ADD}
              size={ButtonSize.MEDIUM}
              type="button"
            >
              {text.microDepositVerificationActionButtonText}
            </ButtonV2>
          </RestrictedPermissionTooltip>
        }
        WithPermissionComponent={
          <ButtonV2
            className="verify-deposits-button"
            leftIconClassName="margin-right--sm"
            leftIconName={IconNames.ADD}
            onClick={onButtonClick}
            size={ButtonSize.MEDIUM}
            type="button"
          >
            {text.microDepositVerificationActionButtonText}
          </ButtonV2>
        }
      />
    </Col>

    <Col className="flex-col-center margin-bottom--m">
      <LinkNewPage
        className="bank-account--hint-action--link"
        href={textHelpers.getBankAccountActionsMailtoUrl(accountNumber)}
      >
        <Icon
          className="margin-right--sm"
          color={colors.colorMainJordan}
          icon={IconNames.HELP}
          size={sizes.iconSizes.LARGE}
        />
        {text.microDepositVerificationActionLinkText}
      </LinkNewPage>
    </Col>
  </Row>
);

AccountHintSectionVerifyActions.propTypes = {
  accountNumber: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
};

AccountHintSectionVerifyActions.defaultProps = {
  accountNumber: undefined,
};

export default AccountHintSectionVerifyActions;

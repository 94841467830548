import PropTypes from 'prop-types';
import React from 'react';

import { IconNames } from 'components/icon';
import { TooltipIcon } from 'components/tooltip';

import { colors, sizes } from 'constants/styles';

import { getBillOrInvoiceText } from 'helpers/items';

const LedgerStatusTooltipIcon = ({ item, ledger }) => (
  <TooltipIcon
    content={`This is the current ${getBillOrInvoiceText(item)} status on ${ledger.fullName}`}
    icon={IconNames.INFO_SIGN}
    iconClassName="relation--right-icon"
    iconColor={colors.colorDarkSilverHex}
    iconProps={{ size: sizes.iconSizes.LARGE }}
    tooltipClassName="ml-auto"
    tooltipProps={{
      statelessProps: { className: 'tooltip--item-block' },
    }}
  />
);

LedgerStatusTooltipIcon.propTypes = {
  item: PropTypes.shape().isRequired,
  ledger: PropTypes.shape({
    fullName: PropTypes.string,
  }).isRequired,
};

export default LedgerStatusTooltipIcon;

import { hasFundingAccountLedgerRef, isFundingAccountLedgerOnly } from 'helpers/funding';

export const ledgerUnmatchedFundingAccountOptionsForGetObjectsWithRelationships = {
  currentState: {},
  getAttributes: (currState, objects, objectId) => ({
    matched: !!(
      !isFundingAccountLedgerOnly(objects[objectId].attributes) &&
      hasFundingAccountLedgerRef(objects[objectId].attributes)
    ),
  }),
};

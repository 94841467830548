import { DateFormats } from '@routable/shared';
import pluralize from 'pluralize';
import { PAYMENTS_LIST_FILTERS } from 'constants/routes';
import { getFilterLabelForBreadcrumbs } from 'helpers/breadcrumbs';
import { getMembershipFullName } from 'helpers/memberships';
import { isSearchExecuted } from 'helpers/search';
import { capitalize } from 'helpers/stringHelpers';
import { formatDateWithZoneAbbr } from 'helpers/timezone';
import { isTabInbox } from 'helpers/urls';
export const isPaginationBlockButtonDisabled = (url, isFetching) => !url || isFetching;
export const getPaginationBlockResultText = (props) => {
    const { currentPage, isFetching, pageSize, searchData, totalResults } = props;
    if (isFetching || typeof currentPage === 'undefined') {
        return 'Loading ...';
    }
    if (totalResults < 1) {
        return 'No results found';
    }
    const previousPage = currentPage - 1;
    const isLastPage = currentPage * pageSize >= totalResults;
    const firstValue = totalResults > 0 ? pageSize * previousPage + 1 : 1;
    const lastValue = isLastPage ? totalResults : currentPage * pageSize;
    let resultText = `Showing ${firstValue}-${lastValue} of ${totalResults}`;
    if (isSearchExecuted(searchData)) {
        resultText += ' results';
    }
    return resultText;
};
export const getPaginationBlockFilterCountButtonLabel = (filterCount) => `+${filterCount} ${pluralize('filter', filterCount)}`;
export const getPaginationBlockTitle = (filter, tab, bulkImport) => {
    if (bulkImport) {
        const { created, user } = bulkImport;
        const name = getMembershipFullName(user);
        return `Uploaded by ${name}, ${formatDateWithZoneAbbr(created, DateFormats.SLASH_DATE_TIME_TIMEZONE)}`;
    }
    if (filter === PAYMENTS_LIST_FILTERS.ALL) {
        return isTabInbox(tab)
            ? capitalize(`${PAYMENTS_LIST_FILTERS.ALL} bills`)
            : capitalize(`${PAYMENTS_LIST_FILTERS.ALL} ${tab}`);
    }
    return getFilterLabelForBreadcrumbs(filter, tab);
};

import { sendUpdatePaymentLinkRoutine, resendPartnershipInviteRoutine } from 'actions/routines/partnership';

import {
  ResendPartnershipInviteActionsStatus,
  SendPartnershipUpdatePaymentMethodLinkActionsStatus,
} from 'constants/partnership';

import * as types from 'types/partnership';

export const actionsReducer = (state = {}, action) => {
  switch (action.type) {
    case resendPartnershipInviteRoutine.REQUEST:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          resendInvite: ResendPartnershipInviteActionsStatus.SUBMITTING,
        },
      };

    case resendPartnershipInviteRoutine.FAILURE:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          resendInvite: ResendPartnershipInviteActionsStatus.FAILURE,
        },
      };

    case resendPartnershipInviteRoutine.SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          resendInvite: ResendPartnershipInviteActionsStatus.SUBMITTED,
        },
      };

    case sendUpdatePaymentLinkRoutine.REQUEST:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          sendUpdatePaymentMethodLink: SendPartnershipUpdatePaymentMethodLinkActionsStatus.REQUEST,
        },
      };

    case sendUpdatePaymentLinkRoutine.FAILURE:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          sendUpdatePaymentMethodLink: SendPartnershipUpdatePaymentMethodLinkActionsStatus.FAILURE,
        },
      };

    case sendUpdatePaymentLinkRoutine.SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          sendUpdatePaymentMethodLink: SendPartnershipUpdatePaymentMethodLinkActionsStatus.SUCCESS,
        },
      };

    case types.SEND_PARTNERSHIP_UPDATE_PAYMENT_METHOD_URL_CLEAR:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          sendUpdatePaymentMethodLink: null,
        },
      };

    default:
      return state;
  }
};

export default actionsReducer;

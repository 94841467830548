import { transactionRelationships } from 'constants/relationships';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';
import { allKeys } from 'helpers/utility';

/**
 * Gets all transactions by id, given the transactions property
 * value from a parsed response payload.
 * @param {Object} transactions
 * @return {ByIdCollection}
 */
export const getTransactionsById = (transactions) =>
  getObjectsByIdWithRelationships(transactions, transactionRelationships);

/**
 * After fetching transactions, returns the new transactions object
 * with parsed relationships/attributes, after either appending it to state
 * or replacing state, depending on whether options.append is true/false.
 * @param {ByIdCollection} state
 * @param {Object} transactions
 * @return {ByIdCollection}
 */
export const getTransactionsByIdAfterFetch = (state, transactions) =>
  deepMergeWithArrayReplacement(state, getTransactionsById(transactions));

/**
 * After fetching transactions, returns the new transaction sub-object
 * with parsed relationships/attributes.
 * @param {ByIdCollection} state
 * @param {Object} transactionSubObject
 * @return {ByIdCollection}
 */
export const getTransactionSubObjectByIdAfterFetch = (state, transactionSubObject) =>
  deepMergeWithArrayReplacement(state, getObjectsByIdWithRelationships(transactionSubObject));

/**
 * After fetching transactions, returns the new array of transaction ids,
 * after either appending it to state or replacing state, depending on
 * whether options.append is true/false.
 * @param {string[]} state
 * @param {Object} transactions
 * @param {Object} [options={}]
 * @param {boolean} [options.append]
 * @return {string[]}
 */
export const getTransactionsAllIdsAfterFetch = (state, transactions, options = {}) => {
  const { append } = options;

  const parsedTransactions = getTransactionsByIdAfterFetch({}, transactions);
  const parsedIds = allKeys(parsedTransactions);

  if (append) {
    return [...state, ...parsedIds];
  }

  return parsedIds;
};

/**
 * After fetching transactions, returns the new array of transaction ach ids.
 * @param {string[]} state
 * @param {Object} transactionSubObject
 * @return {string[]}
 */
export const getTransactionSubObjectAllIdsAfterFetch = (state, transactionSubObject) => {
  const parsedTransactions = getTransactionSubObjectByIdAfterFetch({}, transactionSubObject);
  return allKeys(parsedTransactions);
};

import { staticImagesBasePaths } from 'constants/assets';
import { ItemPropertyDisplay } from 'constants/item';
import {
  LedgerApplicationTypes,
  LedgerContactType,
  LedgerMatchFilterTypes,
  LedgerOptionsFormValue,
  LedgerSettingsClassModeTypes,
  LedgerSettingsDiscountModeTypes,
  LedgerSettingsTaxCalculationTypes,
  LedgerSettingsTaxModeTypes,
  LedgerSides,
  LedgerTaxStyles,
  SpecialCaseTaxCodesQBO,
} from 'constants/ledger';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

import { isItemKindPayable } from 'helpers/items';
import { getPercentageRoundingToCeil, getPercentageRoundingToFloor } from 'helpers/math';
import { isEqual } from 'helpers/utility';

export const isActiveSideLedger = (activeSide) => activeSide === LedgerSides.LEDGER;

export const isActiveLedgerFilterUnmatched = (activeLedgerMatchFilter) =>
  activeLedgerMatchFilter === LedgerMatchFilterTypes.UNMATCHED;

export const isActiveLedgerFilterMatched = (activeLedgerMatchFilter) =>
  activeLedgerMatchFilter === LedgerMatchFilterTypes.MATCHED;

export const isActiveLedgerFilterArchived = (activeLedgerMatchFilter) =>
  activeLedgerMatchFilter === LedgerMatchFilterTypes.ARCHIVED;

/**
 * Helper to get the partnership vendor/customer ref based on the item kind
 * @param partnership
 * @param item
 * @return {null|string}
 */
export const getPartnershipLedgerRefByItemKind = (partnership, item) =>
  isItemKindPayable(item) ? partnership.vendorRef : partnership.customerRef;

/**
 * Checks if the LedgerOptionsFormValue is 'true'
 * @param {string} value
 */
export const isLedgerOptionsFormValueDoNotUpdateLedger = (value) =>
  isEqual(value, LedgerOptionsFormValue.DO_NOT_UPDATE_LEDGER);

// *************************************
// Application types
// *************************************

export const isApplicationTypeQBO = (applicationType) => isEqual(applicationType, LedgerApplicationTypes.QBO);
export const isApplicationTypeSageIntacct = (applicationType) =>
  isEqual(applicationType, LedgerApplicationTypes.SAGE_INTACCT);
export const isApplicationTypeXero = (applicationType) => isEqual(applicationType, LedgerApplicationTypes.XERO);
export const isApplicationTypeNetsuite = (applicationType) => isEqual(applicationType, LedgerApplicationTypes.NETSUITE);

export const isLedgerApplicationTypeQBO = (ledger) => isApplicationTypeQBO(ledger.application);
export const isLedgerApplicationTypeSageIntacct = (ledger) => isApplicationTypeSageIntacct(ledger.application);
export const isLedgerApplicationTypeXero = (ledger) => isApplicationTypeXero(ledger.application);
export const isLedgerApplicationTypeNetsuite = (ledger) => isApplicationTypeNetsuite(ledger.application);

// *************************************
// Invoice details
// *************************************

/**
 * Returns the invoice number text to show in the field label/placeholder for Payables
 * @param {object} ledger
 * @return {string}
 */
export const getLedgerInvoiceNumberLabelForPayablesByLedger = (ledger) => {
  if (!ledger) {
    return ItemPropertyDisplay.BILL_NUMBER;
  }

  switch (ledger.application) {
    case LedgerApplicationTypes.NETSUITE:
    case LedgerApplicationTypes.XERO:
      return 'Reference';

    case LedgerApplicationTypes.QBO:
    default:
      return ItemPropertyDisplay.BILL_NUMBER;
  }
};

/**
 * Returns the invoice number text to show in the field label/placeholder
 * @param {object} item
 * @param {object} ledger
 * @return {string}
 */
export const getLedgerInvoiceNumberLabel = (item, ledger = null) => {
  if (!isItemKindPayable(item)) {
    return 'Invoice No.';
  }
  return getLedgerInvoiceNumberLabelForPayablesByLedger(ledger);
};

/**
 * Returns Reference text
 * @param {object} ledger
 */
export const getLedgerReferenceText = (ledger = null) => {
  const defaultText = 'Reference';

  if (!ledger) {
    return defaultText;
  }

  switch (ledger.application) {
    case LedgerApplicationTypes.QBO:
      return 'Message on invoice';

    case LedgerApplicationTypes.XERO:
    default:
      return defaultText;
  }
};

/**
 * Returns Invoice No. text
 * @param {object} ledger
 * @param {object} item
 */
export const getLedgerInvoiceNoteText = (ledger, item) => {
  switch (ledger.application) {
    case LedgerApplicationTypes.QBO:
      if (isItemKindPayable(item)) {
        return 'Memo';
      }

      return 'Message on statement';

    case LedgerApplicationTypes.XERO:
    default:
      return 'Message (or notes)';
  }
};

/**
 * Returns text to be displayed on InvoiceGenerator totals when
 * autoTax is selected
 * @param {object} ledger
 */
export const getDeterminedByLedgerText = (ledger) => `Determined by ${ledger.name}`;

/**
 * Returns a function to be used for rounding amounts when adding a discount to invoices.
 * We follow the rounding method used by the connected ledger.
 * @param {LedgerApplicationTypes} application
 * @return {Function}
 */
export const getPercentageDiscountCalcFunctionForLedger = (application) => {
  switch (application) {
    case LedgerApplicationTypes.QBO:
      return getPercentageRoundingToCeil;

    case LedgerApplicationTypes.XERO:
    default:
      return getPercentageRoundingToFloor;
  }
};

// *************************************
// Settings
// *************************************

export const isLedgerSettingsContactTypeBoth = (settings) => settings.contactType === LedgerContactType.BOTH;

export const isLedgerSettingsContactTypeSingular = (settings) => settings.contactType === LedgerContactType.SINGULAR;

/**
 * Checks if the ledger settings discount mode is 'item'
 * @param {object} settings
 */
export const isLedgerSettingsDiscountModeItem = (settings) =>
  settings.discountMode === LedgerSettingsDiscountModeTypes.ITEM;

/**
 * Checks if the ledger settings discount mode is 'line_item'
 * @param {object} settings
 */
export const isLedgerSettingsDiscountModeLineItem = (settings) =>
  settings.discountMode === LedgerSettingsDiscountModeTypes.LINE_ITEM;

/**
 * Checks if the ledger settings tax mode is 'auto'
 * @param {object} settings
 */
export const isLedgerSettingsTaxModeAuto = (settings) => settings.taxMode === LedgerSettingsTaxModeTypes.AUTO;

/**
 * Checks if the ledger settings tax calculation is 'line_item'
 * @param {object} settings
 */
export const isLedgerSettingsTaxCalculationLineItem = (settings) =>
  settings.taxCalculation === LedgerSettingsTaxCalculationTypes.LINE_ITEM;

/**
 * Checks if the ledger settings tax calculation is 'subtotal'
 * @param {object} settings
 */
export const isLedgerSettingsTaxCalculationSubtotal = (settings) =>
  settings.taxCalculation === LedgerSettingsTaxCalculationTypes.SUBTOTAL;

/**
 * Checks if the ledger settings tax calculation is 'item'
 * @param {object} settings
 */
export const isLedgerSettingsClassModeItem = (settings) => settings.classMode === LedgerSettingsClassModeTypes.ITEM;

// *************************************
// QBO
// *************************************

/**
 * Check if QBO has customer tracking turned on
 * @param settings
 * @return {boolean}
 */
export const isLedgerCustomerTrackingOn = (settings) => !!settings.customerTracking;

/**
 * Check if QBO has expense tracking turned on
 * @param settings
 * @return {boolean}
 */
export const isLedgerExpenseTrackingOn = (settings) => !!settings.expenseTracking;

/**
 * Checks if the ledger settings for items for purchase is on
 * @param settings
 * @return {boolean}
 */
export const isLedgerItemsForPurchaseOn = (settings) => !!settings.itemsForPurchase;

/**
 * Checks if the ledger settings for items for sale is on
 * @param settings
 * @return {boolean}
 */
export const isLedgerItemsForSaleOn = (settings) => !!settings.itemsForSale;

/**
 * Checks if the ledger item has taxAuto set to true
 * @param {boolean} taxAuto
 */
export const isLedgerItemTaxAutoEnabled = (taxAuto) => taxAuto === true;

/**
 * Checks if the ledger item is taxable prior to submit
 * @param {string} taxCode
 */
export const isLedgerItemTaxable = (taxCode) => {
  const { NO_TAX } = SpecialCaseTaxCodesQBO;

  if (!taxCode) {
    return false;
  }

  return taxCode !== NO_TAX;
};

/**
 * Check if QBO has expense tracking turned on
 * @param settings
 * @return {boolean}
 */
export const isLedgerMarkupModeLineItem = (settings) => settings.markupMode === 'line_item';

// *************************************
// Xero
// *************************************

/**
 * Checks if the ledger tax style is 'exclusive'
 * @param {string} taxStyle
 */
export const isLedgerTaxStyleExclusive = (taxStyle) => taxStyle === LedgerTaxStyles.EXCLUSIVE;

/**
 * Checks if the ledger tax style is 'inclusive'
 * @param {string} taxStyle
 */
export const isLedgerTaxStyleInclusive = (taxStyle) => taxStyle === LedgerTaxStyles.INCLUSIVE;

/**
 * Checks if the ledger tax style is 'none'
 * @param {string} taxStyle
 */
export const isLedgerTaxStyleNoTax = (taxStyle) => taxStyle === LedgerTaxStyles.NO_TAX;

// *************************************
// Errors
// *************************************
/**
 * Returns relative path to ledger disconnected error graphic
 * @param {object} ledger
 * @return {string|null} imagePath or null
 */
export const getLedgerDisconnectedErrorImagePath = (ledger) => {
  if (!ledger) {
    return null;
  }

  const ledgerName = ledger.name.replace(/\s/g, '-');

  return `${staticImagesBasePaths.INTEGRATIONS}/${PLATFORM_DISPLAY_SHORT_NAME}-${ledgerName}-disconnected.svg`;
};

/**
 * Returns true if ledger is netsuite, xero, sage or qbo
 * This will become a backend flag here: DEV-2853
 * @param {LedgerApplicationTypes} ledgerApplication
 * @returns {boolean}
 */
export const doesLedgerOverrideDataWhenPatched = (ledgerApplication) =>
  [
    LedgerApplicationTypes.XERO,
    LedgerApplicationTypes.SAGE_INTACCT,
    LedgerApplicationTypes.QBO,
    LedgerApplicationTypes.NETSUITE,
  ].includes(ledgerApplication);

import _reduce from 'lodash/reduce';
export const getFiltersListFromDictionary = (filtersDictionary = {}) => Object.entries(filtersDictionary).map(([filter, value]) => ({
    filter,
    value,
}));
export const getFiltersDiff = (prev, next = {}) => {
    return _reduce({ ...prev, ...next }, (result, value, key) => {
        if (key in next && key in prev === false) {
            return {
                ...result,
                added: { ...result.added, [`filter-${key}`]: value },
            };
        }
        if (key in next === false && key in prev) {
            return {
                ...result,
                removed: { ...result.removed, [`filter-${key}`]: value },
            };
        }
        return result;
    }, { added: {}, removed: {} });
};
export const pickActiveFilters = (filterValue) => {
    if (!filterValue) {
        return false;
    }
    if (Array.isArray(filterValue)) {
        return filterValue.length > 0;
    }
    if (filterValue instanceof Date) {
        return !isNaN(filterValue.getTime());
    }
    if (typeof filterValue === 'object') {
        if (Object.keys(filterValue).length === 0) {
            return false;
        }
        return Object.values(filterValue).every(pickActiveFilters);
    }
    return Boolean(filterValue);
};

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {} from 'interfaces/global';
import { partnershipFromCurrentPartnershipRequestSelector } from 'queries/partnershipCompoundSelectors';
import UpdatePaymentMethodFundingAccounts from './UpdatePaymentMethodFundingAccounts';
import {} from './UpdatePaymentMethodFundingAccounts.types';
export const mapStateToProps = createStructuredSelector({
    partnership: partnershipFromCurrentPartnershipRequestSelector,
});
export default connect(mapStateToProps)(UpdatePaymentMethodFundingAccounts);

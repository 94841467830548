import React from 'react';
import { useDispatch } from 'react-redux';
import { Field, change } from 'redux-form';
import ReduxFormFieldRenderNumericInput from 'components/form/NumericInput/ReduxFormFieldRenderNumericInput';
import { formNamesFunding } from 'constants/forms';
import { PLATFORM_VERIFY_ACCOUNT_DEPOSIT_MAX, PLATFORM_VERIFY_ACCOUNT_DEPOSIT_MIN } from 'constants/platform';
import { field } from 'constants/styles/formStyles';
import { rejectMultiZeroesBeforeDecimalSeparator } from 'helpers/currency';
import { formatMicroDeposit } from 'helpers/fieldFormatters';
import { microdepositValidator, requiredValidator } from 'helpers/fieldValidation';
import { text } from 'modules/connectBank/verifyFundingAccount/constants';
import {} from './VerifyFundingAccountInput.types';
const VerifyFundingAccountInput = ({ name, ...rest }) => {
    const dispatch = useDispatch();
    const isAllowed = React.useCallback((input) => rejectMultiZeroesBeforeDecimalSeparator(input?.formattedValue), []);
    const onChange = React.useCallback((event) => {
        const formattedValue = formatMicroDeposit(event?.target?.value);
        dispatch(change(formNamesFunding.VERIFY_FUNDING_ACCOUNT, name, formattedValue));
    }, [dispatch, name]);
    const InputComponent = React.useMemo(() => ({ input, meta, ...inputComponentProps }) => (React.createElement(ReduxFormFieldRenderNumericInput, { ...inputComponentProps, input: {
            ...input,
            value: input.value,
            onChange,
        }, isAllowed: isAllowed, meta: meta })), [onChange, isAllowed]);
    return (React.createElement(Field, { className: `${field.xl.left} currency verify-input`, component: InputComponent, currency: "$", max: PLATFORM_VERIFY_ACCOUNT_DEPOSIT_MAX, maxLength: "6", min: PLATFORM_VERIFY_ACCOUNT_DEPOSIT_MIN, name: name, placeholder: text.microDepositFieldPlaceholder[name], type: "text", validate: [requiredValidator, microdepositValidator], ...rest }));
};
export default VerifyFundingAccountInput;

import { combineReducers } from 'redux';

import affiliateConnectionsReducer from './affiliateConnectionsReducer';
import affiliatesReducer from './affiliatesReducer';

const affiliatesRootReducer = combineReducers({
  affiliates: affiliatesReducer,
  connections: affiliateConnectionsReducer,
});

export default affiliatesRootReducer;

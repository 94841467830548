import PropTypes from 'prop-types';
import React from 'react';

import { WhiteSpace } from 'components';
import { WordBreakAll } from 'components/layout';

import { getMembershipNameOrEmail } from 'helpers/memberships';
import { apostrophiseString } from 'helpers/stringHelpers';

/**
 * Subtitle for revoke invite confirmation alert
 * @param {Membership} membership
 * @return {JSX.Element}
 */
const RevokeInviteConfirmationModalSubtitle = ({ membership }) => (
  <>
    Revoking
    <WhiteSpace />
    <WordBreakAll>{apostrophiseString(getMembershipNameOrEmail(membership))}</WordBreakAll>
    <WhiteSpace />
    invite will take effect immediately.
  </>
);

RevokeInviteConfirmationModalSubtitle.propTypes = {
  membership: PropTypes.shape({}).isRequired,
};

RevokeInviteConfirmationModalSubtitle.defaultProps = {
  membership: undefined,
};

export default RevokeInviteConfirmationModalSubtitle;

import React from 'react';
import { Text } from 'components';
import { typography } from 'constants/styles';
import {} from './UpdatePaymentFlowFormLabel.types';
const UpdatePaymentFlowFormLabel = ({ labelText }) => {
    const displayedText = labelText || 'Add another payment method';
    return (React.createElement("div", { className: "margin-bottom--m-large margin-top--larger" },
        React.createElement(Text.Bold, { color: typography.TextColor.GREY_XX_DARK, size: typography.TextSize.LEVEL_400 }, displayedText)));
};
export default UpdatePaymentFlowFormLabel;

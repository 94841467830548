import { ACCOUNT_CREATION_VERIFY_EMAIL } from 'constants/routes';
import { signupVerifyEmailCodeToastId } from 'constants/toasts';

import { REST } from 'helpers/api';
import { handleAddAuthTokenAndCurrentIds } from 'helpers/auth';
import { areDevtoolsEnabled } from 'helpers/env';

import { payloadToCamelCase, payloadToJSONAPI } from 'services/api/formatHelpers';
import { signupCreateAccountEndpoint } from 'services/api/signupEndpoints';
import { toaster } from 'services/toaster';

import {
  SUBMIT_SIGNUP_CREATE_ACCOUNT_FAILURE,
  SUBMIT_SIGNUP_CREATE_ACCOUNT_REQUEST,
  SUBMIT_SIGNUP_CREATE_ACCOUNT_SUCCESS,
  SUBMIT_SIGNUP_CREATE_ACCOUNT_PASSWORD_ERROR,
} from 'types/signup';

// TODO: change this to a saga or reduxToolkit? or keep like this thunk?
const submitAccountCreationCreateAccount =
  (payload, dispatch, { history }) =>
  async () => {
    dispatch({ type: SUBMIT_SIGNUP_CREATE_ACCOUNT_REQUEST });

    try {
      const payloadWithTOSAgreed = {
        ...payload,
        meta: {
          ...payload.meta,
          // CreateAccount form has a hint telling the user that by submitting they are accepting terms
          tosAgree: true,
        },
      };

      const res = await REST.post({
        endpoint: signupCreateAccountEndpoint,
        requireAuth: true,
        payload: payloadToJSONAPI(payloadWithTOSAgreed, 'membership', 'company', 'user'),
      });

      if (res.ok) {
        dispatch({ type: SUBMIT_SIGNUP_CREATE_ACCOUNT_SUCCESS });
        toaster.success('Your account has been successfully created.', {
          id: 'create-account-success',
        });

        const parsedResponse = payloadToCamelCase(res.data);

        // saves details in localStorage and sets the auth header for future requests
        await handleAddAuthTokenAndCurrentIds(parsedResponse.meta);

        // go to /verify step
        history.push(ACCOUNT_CREATION_VERIFY_EMAIL);

        // Add code as a toast in testing environments (including prod builds when devtools are enabled)
        if (areDevtoolsEnabled()) {
          const { verificationCode } = parsedResponse.meta;

          if (verificationCode) {
            toaster.notify(`Testing verification code: ${verificationCode}`, {
              duration: 30,
              id: signupVerifyEmailCodeToastId,
            });
          }
        }
      } else {
        // handle errorm from API call
        // - field errors
        // - non field errors (sign up code)

        const responseErrors = res?.errors?.fields;
        const missingSignupCode = responseErrors.meta?.signup_code || [];
        const errorMsg =
          'This signup link is incorrect or expired. Please use the invite link on your email or contact support.';
        const actualErrorMessage = missingSignupCode.length ? errorMsg : '';

        if (Object.hasOwn(responseErrors, 'user') || Object.hasOwn(responseErrors, 'password')) {
          const submitPasswordErrors = responseErrors.user?.password[0] || responseErrors.password[0];

          dispatch({
            type: SUBMIT_SIGNUP_CREATE_ACCOUNT_PASSWORD_ERROR,
            payload: submitPasswordErrors,
          });
        }

        dispatch({
          type: SUBMIT_SIGNUP_CREATE_ACCOUNT_FAILURE,
          payload: {
            errors: actualErrorMessage,
          },
        });

        toaster.warning(`Something went wrong. ${actualErrorMessage}`, {
          duration: 30,
          id: signupVerifyEmailCodeToastId,
        });
      }
    } catch (e) {
      // catch any other error
      toaster.warning(`Something went wrong: ${e}`, {
        duration: 30,
        id: signupVerifyEmailCodeToastId,
      });
    }
  };

export default submitAccountCreationCreateAccount;

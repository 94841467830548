import PropTypes from 'prop-types';
import React from 'react';

import { Tag } from 'components/tag';

import { tagTypeProp } from 'helpers/propTypes';

/**
 * Tag component for react-select options.
 * @param {ComponentProps} props
 * @param {Object} props.children
 * @param {string} props.type
 * @return {StatelessComponent}
 */
const OptionTag = ({ children, type }) => (
  <Tag isClosable={false} type={type}>
    {children}
  </Tag>
);

OptionTag.propTypes = {
  children: PropTypes.node,
  type: tagTypeProp,
};

OptionTag.defaultProps = {
  children: undefined,
  type: undefined,
};

export default OptionTag;

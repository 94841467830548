import { useMutation } from '@tanstack/react-query';
import { queryContext } from '@routable/shared';
import { generateKeys } from '../helpers';
import { vendorRiskChecksApi } from '../services';
export const useManuallyMoveToVerified = (vendorId) => {
    return useMutation({
        context: queryContext,
        mutationFn: async (riskReportId) => vendorRiskChecksApi.manuallyMoveToVerified(vendorId, riskReportId),
        mutationKey: generateKeys.dismissLatestReportWatchlistHits(vendorId),
    });
};

import React from 'react';

import { Intent } from 'constants/ui';

import BaseHint from '../BaseHint';

/**
 * Hint to display when the CSV file size is too large.
 * @return {StatelessComponent}
 */
const CsvMaximumFileSizeHint = () => (
  <BaseHint
    intent={Intent.DANGER}
    multiline
    text="Please split your .CSV file into multiple files smaller than 5MB and upload them separately."
    title="Your file is too big"
  />
);

export default CsvMaximumFileSizeHint;

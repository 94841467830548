import pluralize from 'pluralize';
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, submit, isInvalid, untouch, change } from 'redux-form';

import { closeSubmitForVerificationConfirmation, changeSaveStatus } from 'actions/signUpFlow';

import {
  ModalFooterButton,
  ModalFooterButtonCancel,
  Checkbox,
  Modal,
  ModalBodyContent,
  ModalFooter,
  IconNames,
  Text,
} from 'components';

import { colors } from 'constants/styles';
import { TextSize } from 'constants/styles/typography';

import { requiredValidator } from 'helpers/fieldValidation';
import { getMembershipFullName } from 'helpers/memberships';
import { listAllElementsInArray } from 'helpers/stringHelpers';
import getContactName from 'helpers/user';

import { signupFlowForm } from 'modules/signup-v3/constants';
import { fields } from 'modules/signup-v3/fields';

import {
  currentCompanySelector,
  currentCompanyBeneficiariesSelector,
  isSubmittingCurrentCompanyRepresentativesSelector,
} from 'selectors/currentCompanySelectors';
import { currentUserSelector } from 'selectors/currentUserSelectors';
import { submitForVerificationConfirmationOpen } from 'selectors/signupSelectors';

const SubmitForVerificationConfirmationModal = () => {
  const dispatch = useDispatch();
  const closeVerificationConfirmation = () => {
    dispatch(closeSubmitForVerificationConfirmation());
  };
  const beneficialOwners = useSelector(currentCompanyBeneficiariesSelector);
  const isOpen = useSelector(submitForVerificationConfirmationOpen);
  const isFormInvalid = useSelector(isInvalid(signupFlowForm));
  const currentCompany = useSelector(currentCompanySelector);
  const currentUser = useSelector(currentUserSelector);
  const isSubmitting = useSelector(isSubmittingCurrentCompanyRepresentativesSelector);
  const handleClose = () => {
    dispatch(untouch(signupFlowForm, fields.agree, fields.agreeNoBeneficiaries));
    dispatch(change(signupFlowForm, fields.agree, false));
    dispatch(change(signupFlowForm, fields.agreeNoBeneficiaries, false));
    dispatch(changeSaveStatus.saved());
    closeVerificationConfirmation();
  };

  const certifyBeneficialOwnersLabel = useMemo(() => {
    const listOfOwners = listAllElementsInArray(beneficialOwners.map(getMembershipFullName));
    const pluralOwner = pluralize('owner', beneficialOwners.length);
    const isOrAre = beneficialOwners.length === 1 ? 'is a' : 'are';

    return `I certify that ${listOfOwners} ${isOrAre} beneficial ${pluralOwner} of ${currentCompany.name}.`;
  }, [beneficialOwners, currentCompany.name]);

  return (
    <Modal
      body={
        <div>
          <ModalBodyContent>
            <div className="margin-bottom--m">
              <Text.Bold size={TextSize.LEVEL_400}>
                It&rsquo;s important that the person submitting this information for verification fits the legal
                requirement of a business representative.
              </Text.Bold>
            </div>
            <div className="margin-bottom--m">
              <Text.Regular>
                {`The added representative above must be a legal business representative for ${
                  currentCompany.name
                }, and match the name on your account, ${getContactName(currentUser)}.
                  If that is not the case, please contact support as soon as possible.`}
              </Text.Regular>
            </div>
            {!beneficialOwners.length ? (
              <Field
                className="margin-bottom--m"
                component={Checkbox}
                content="I certify that there are no beneficial owners for my company."
                isRequired
                name={fields.agreeNoBeneficiaries}
                validate={[requiredValidator]}
              />
            ) : (
              <Field
                className="margin-bottom--m"
                component={Checkbox}
                content={certifyBeneficialOwnersLabel}
                isRequired
                name={fields.confirmBeneficialOwners}
                validate={[requiredValidator]}
              />
            )}
            <Field
              className="margin-bottom--m"
              component={Checkbox}
              content={`I certify on behalf of ${currentCompany.name}, as a business representative, that all the information provided is accurate.`}
              isRequired
              name={fields.agree}
              validate={[requiredValidator]}
            />
          </ModalBodyContent>
          <ModalFooter
            style={{
              backgroundColor: colors.colorWhiteHex,
            }}
          >
            <ModalFooterButtonCancel onCloseModal={handleClose} />
            <ModalFooterButton
              isDisabled={isFormInvalid || isSubmitting}
              isLoading={isSubmitting}
              onClick={() => dispatch(submit(signupFlowForm))}
              rightIconName={IconNames.ARROW_RIGHT}
              type="submit"
            >
              Submit for verification
            </ModalFooterButton>
          </ModalFooter>
        </div>
      }
      hasInlineFooter
      modalHeader="Submit for verification"
      modalState={isOpen}
      onCloseModal={handleClose}
      overrideStyle={{
        content: {
          backgroundColor: colors.colorBlueExtraLight,
        },
      }}
    />
  );
};

export default SubmitForVerificationConfirmationModal;

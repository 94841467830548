import { combineReducers } from 'redux';

import allIdsReducer from './allReducer';
import byIdReducer from './byIdReducer';
import isFetchingReducer from './isFetchingReducer';
import lastFetchedReducer from './lastFetchedReducer';

const affiliateConnectionsReducer = combineReducers({
  allIds: allIdsReducer,
  byId: byIdReducer,
  isFetching: isFetchingReducer,
  lastFetched: lastFetchedReducer,
});

export default affiliateConnectionsReducer;

import PropTypes from 'prop-types';
import React from 'react';

import { universalRenderPropType } from 'helpers/propTypes';

import UniversalChildren from '../UniversalChildren';

/**
 * Renders children if the provided condition is truthy.
 * @param {ComponentProps} props
 * @param {*} props.condition
 * @param {*} props.children
 * @param {Boolean} props.strict
 * @return {*|null}
 */
const If = ({ condition, children, strict }) =>
  // if `condition` is (===) true, OR if strict truthiness is
  // not required and condition is (==) truthy, we'll render children.
  // note: the default here is that strictness is NOT enforced.
  condition === true || (!strict && condition) ? (
    <UniversalChildren>{children}</UniversalChildren>
  ) : // otherwise, we'll return a null node
  null;

If.propTypes = {
  // in this specific case, `any` is accurate
  /* eslint-disable-next-line react/forbid-prop-types */
  condition: PropTypes.any,
  children: universalRenderPropType,
  strict: PropTypes.bool,
};

If.defaultProps = {
  condition: undefined,
  strict: undefined,
};

export default If;

import _get from 'lodash/get';
import _includes from 'lodash/includes';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { formValueSelector } from 'redux-form';
import styled from 'styled-components';
import { formNamesItem } from 'constants/forms';
import { formatDateString } from 'helpers/date';
import { useIsOcrEnabled } from 'helpers/ocr';
import { fieldMappings } from 'helpers/ocr/constants';
import { storeAccessor as store } from 'store/accessor';
import { useItemFieldMapping } from '@routable/tablematic';
import { ItemKinds } from 'constants/item';
const hasOCRValues = (isBillView, prop, watchInclude, conditional, itemFieldMapping = fieldMappings) => {
    if (!prop && !watchInclude) {
        return false;
    }
    if (!isBillView) {
        return false;
    }
    if (watchInclude && conditional) {
        return true;
    }
    return _includes(itemFieldMapping, prop);
};
const ProvenanceWrapper = styled.div `
  // WRAPPER STYLE
  position: relative;
  width: 100%;

  // BEGIN SELECT/INPUT PROVENANCE (Lightning Icon)
  &[data-ocr='true'][data-edit='false'] .select--label:before,
  &[data-ocr='true'][data-edit='false'] .input-container label:before {
    display: inline-block;
    content: '';
    width: 12px;
    height: 12px;
    background: transparent
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23a8378f'  %3E%3Cpath d='M9 11H6a1 1 0 01-1-1L5.91.9a1 1 0 01.995-.9h6.256a.839.839 0 01.779 1.15L11.2 8h2.978a.822.822 0 01.748 1.162l-4.764 10.481A.608.608 0 019 19.392V11z' fill-rule='evenodd'%3E%3C/path%3E%3C/svg%3E")
      no-repeat center;
    position: relative;
    top: 2px;
    margin-right: 6px;
  }
  // END SELECT/INPUT PROVENANCE (Lightning Icon)

  // BEGIN LEDGER FORM FIELD
  & .column-content {
    position: relative;
    overflow: hidden;
  }

  &[data-ocr='true'][data-edit='false'] .column-content:after {
    display: inline-block;
    content: '';
    left: 0;
    top: 0;
    border-width: 12px 12px 0 0;
    border-color: #ed7cd4 transparent transparent transparent;
    border-style: solid;
    position: absolute;
    z-index: 2;
  }
  // END LEDGER FORM FIELD
`;
const Provenance = ({ children, annotationValue, watch, watchProp = '', className = '', watchInclude = false, isBillView = false, conditional = false, }) => {
    const reduxState = store?.getState?.();
    const selector = formValueSelector(formNamesItem.CREATE_ITEM);
    const [ocrEnabled, setOCREnabled] = useState(false);
    const [fieldChanged, setFieldChanged] = useState(false);
    const [fieldValue, setFieldValue] = useState();
    const [changeCount, setChangeCount] = useState(0);
    const thisRef = useRef(null);
    let baseFieldValue = null;
    if (watchProp) {
        baseFieldValue = selector(reduxState, watchProp);
    }
    const { isOcrEnabled, isInboxBillView } = useIsOcrEnabled();
    const { data: itemFieldMapping } = useItemFieldMapping({ kind: ItemKinds.PAYABLE });
    useEffect(() => {
        const propsBeingWatched = isOcrEnabled &&
            baseFieldValue &&
            hasOCRValues(isBillView, watchProp, watchInclude, conditional, itemFieldMapping);
        if (propsBeingWatched) {
            setFieldChanged(false);
            setOCREnabled(propsBeingWatched);
            const childType = _get(children, 'props');
            if (childType) {
                if (childType?.['dateFormat'] !== '') {
                    baseFieldValue = formatDateString(baseFieldValue);
                }
                setFieldValue(baseFieldValue);
                setChangeCount(1);
            }
        }
        if (annotationValue) {
            setOCREnabled(true);
        }
    }, [annotationValue, itemFieldMapping]);
    useMemo(() => {
        if (ocrEnabled) {
            if (fieldValue && changeCount > 1) {
                setFieldChanged(true);
            }
            else {
                setChangeCount(changeCount + 1);
            }
        }
    }, [baseFieldValue]);
    const shouldUseValueMatchesAnnotation = Boolean(annotationValue);
    const valueMatchesAnnotation = annotationValue === watch;
    if (!ocrEnabled || !isInboxBillView || (shouldUseValueMatchesAnnotation && !valueMatchesAnnotation)) {
        return React.createElement(React.Fragment, null, children);
    }
    return (React.createElement(ProvenanceWrapper, { className: className, "data-edit": fieldChanged, "data-ocr": ocrEnabled, ref: thisRef }, children));
};
export default Provenance;

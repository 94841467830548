import { getModalReducerOpenAction } from 'store/redux';

import { MODAL_CREATE_MANUAL_ADDRESS_CLOSE, MODAL_CREATE_MANUAL_ADDRESS_OPEN } from 'types/modals';

export const initialState = {
  open: false,
};

// Reducer
const createManualAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_CREATE_MANUAL_ADDRESS_CLOSE:
      return initialState;

    case MODAL_CREATE_MANUAL_ADDRESS_OPEN:
      return getModalReducerOpenAction(state, action);

    default:
      return state;
  }
};

export default createManualAddressReducer;

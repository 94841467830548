import styled from 'styled-components';
export const InstructionsText = styled.span.withConfig({
    displayName: 'InstructionsText',
}) `
  font-family: var(--globals-font-regular-family);
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-1);
  color: var(--color-grey60);
  display: inline-block;
`;
export const InstructionsTextSignatureWarning = styled(InstructionsText).withConfig({
    displayName: 'InstructionsTextSignatureWarning',
}) `
  margin-top: var(--spacing-3);
`;
export const InstructionsTextPdfUpload = styled(InstructionsText).withConfig({
    displayName: 'InstructionsTextPdfUpload',
}) `
  margin: var(--spacing-2) 0;
`;
export const Divider = styled.div.withConfig({ displayName: 'Divider' }) `
  height: 1px;
  background-color: var(--color-grey10);
  width: 100%;
`;

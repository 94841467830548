import { createErrorReducer } from 'store/redux';

import {
  FETCH_PLATFORM_PARTNERSHIPS_FAILURE,
  FETCH_PLATFORM_PARTNERSHIPS_REQUEST,
  FETCH_PLATFORM_PARTNERSHIPS_SUCCESS,
  FETCH_PLATFORM_PARTNERSHIPS_SEARCH_SUCCESS,
} from 'types/integrations';

export const failureCases = [FETCH_PLATFORM_PARTNERSHIPS_FAILURE];
export const successCases = [
  FETCH_PLATFORM_PARTNERSHIPS_REQUEST,
  FETCH_PLATFORM_PARTNERSHIPS_SUCCESS,
  FETCH_PLATFORM_PARTNERSHIPS_SEARCH_SUCCESS,
];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

import React from 'react';

import { MessageSupport } from 'components/error';
import { BaseHint } from 'components/hintTypes';

import { Intent } from 'constants/ui';

import { submitErrorHintText } from 'modules/external/ExternalCollectTaxInfo/constants/text';
import ExternalPromptHeader from 'modules/external/externalPrompt/global/presenters/ExternalPromptHeader';
import ExternalBody from 'modules/external/global/presenters/ExternalBody';

/**
 * Page which shows user there was an issue submitting tax info. Displays danger hint and link to message support.
 * @returns {StatelessComponent}
 * @constructor
 */
const CollectTaxFailurePage = () => (
  <ExternalBody>
    <div className="external-tax--full-width-page">
      <ExternalPromptHeader text="The information provided does not match our records." />
      <BaseHint
        className="margin-top--m-large"
        content={<MessageSupport message="I had an issue confirming my taxes..." />}
        contentProps={{ className: 'margin-top--m' }}
        intent={Intent.DANGER}
        multiline
        text={<span className="external-tax--failure-text">{submitErrorHintText}</span>}
        title="What's next?"
      />
    </div>
  </ExternalBody>
);

export default CollectTaxFailurePage;

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { WhiteSpace } from 'components';

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { COMPANIES_ROUTE } from 'constants/routes';

import { ledgerIntegrationSelector } from '../../../../../../../../../selectors/integrationsSelectors';

const PartnershipListCompleteDescription = ({ ledger }) => (
  <React.Fragment>
    You successfully synced customers and vendors between
    <WhiteSpace />
    {ledger.fullName}
    <WhiteSpace />
    and
    <WhiteSpace />
    {PLATFORM_DISPLAY_SHORT_NAME}
    . You can view and manage all customers and vendors in your
    <WhiteSpace />
    <Link className="enable-pointer-events font-color--primary" to={COMPANIES_ROUTE}>
      <span>Companies</span>
    </Link>
    <WhiteSpace />
    page.
  </React.Fragment>
);

PartnershipListCompleteDescription.propTypes = {
  ledger: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  ledger: ledgerIntegrationSelector(state),
});

export { PartnershipListCompleteDescription };
export default connect(mapStateToProps)(PartnershipListCompleteDescription);

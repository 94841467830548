import PropTypes from 'prop-types';
import React from 'react';

import DiscountToggle from '../DiscountToggle';
import DiscountToggleTeleporter from '../DiscountToggleTeleporter';

import './DiscountBlock.scss';

/**
 * Predefined dynamic table component for entering discounts. Displays as a block section with multiple inputs.
 * @param {ComponentProps} props
 * @param {Object} props.formUI
 * @param {*} children
 * @return {StatelessComponent}
 */
const DiscountBlock = ({ formUI, children }) => (
  <React.Fragment>
    <DiscountToggleTeleporter.Source>
      <DiscountToggle />
    </DiscountToggleTeleporter.Source>

    {formUI.showInvoiceDiscount && (
      <div className="invoice-discount">
        <span className="font-regular semibold font-color--slate margin-right--m">Discount</span>

        {children}
      </div>
    )}
  </React.Fragment>
);

DiscountBlock.propTypes = {
  children: PropTypes.node.isRequired,
  formUI: PropTypes.shape().isRequired,
};

export default DiscountBlock;

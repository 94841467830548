import React from 'react';
import { Route } from 'react-router-dom';
import { BrandWrapperV2 } from 'components';
import { RESET_PASSWORD, RESET_PASSWORD_COMPLETE } from 'constants/routes';
import { asPath } from 'helpers/routeHelpers';
import NotAuthenticated from 'modules/app/NotAuthenticatedRoute';
import SwitchOr404 from 'modules/app/SwitchOr404';
import { ResetPasswordComplete, ResetPasswordForm } from './components';
const ResetPassword = () => (React.createElement(BrandWrapperV2, null,
    React.createElement("div", { className: "branded--contents remove-margin" },
        React.createElement(SwitchOr404, null,
            React.createElement(NotAuthenticated, { component: ResetPasswordForm, exact: true, path: asPath(RESET_PASSWORD) }),
            React.createElement(Route, { component: ResetPasswordComplete, exact: true, path: asPath(RESET_PASSWORD_COMPLETE) })))));
export default ResetPassword;

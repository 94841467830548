import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import TableCellSubtext from '../TableCellSubtext';
import TableCellText from '../TableCellText';

/**
 * Renders text and optional subtext for a table cell.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 * @constructor
 */
const TableCellTextWithSubtext = (props) => {
  const { className, style, subtext, subtextClassName, text, textClassName } = props;

  return (
    <div
      className={classNames({
        [className]: !!className,
      })}
      style={style}
    >
      <TableCellText className={textClassName}>{text}</TableCellText>

      {!!subtext && (
        <TableCellSubtext
          className={classNames({
            'margin-top--sm': true,
            [subtextClassName]: !!subtextClassName,
          })}
        >
          {subtext}
        </TableCellSubtext>
      )}
    </div>
  );
};

TableCellTextWithSubtext.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  subtext: PropTypes.node,
  subtextClassName: PropTypes.string,
  text: PropTypes.node.isRequired,
  textClassName: PropTypes.string,
};

TableCellTextWithSubtext.defaultProps = {
  className: undefined,
  style: undefined,
  subtext: undefined,
  subtextClassName: undefined,
  textClassName: undefined,
};

export default TableCellTextWithSubtext;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

import Icon, { IconNames } from 'components/icon';

const { DropdownIndicator } = components;

const ArrowRenderer = (props) => {
  const {
    selectProps: { isLoading },
  } = props;

  if (isLoading) {
    return null;
  }

  return (
    <DropdownIndicator className={classNames({ loading: !!isLoading })} {...props}>
      <Icon icon={IconNames.CHEVRON_DOWN} />
    </DropdownIndicator>
  );
};

ArrowRenderer.propTypes = {
  onMouseDown: PropTypes.func,
  selectProps: PropTypes.shape(), // provided by react-select
};

ArrowRenderer.defaultProps = {
  onMouseDown: undefined,
  selectProps: {},
};

export default ArrowRenderer;

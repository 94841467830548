/**
 * @module sagas/createItem/api
 */

import { submitInvoiceRelationships, submitItemRelationships } from 'constants/relationships';

import { REST } from 'helpers/api';

import { FetchService } from 'services';
import { payloadToJSONAPI } from 'services/api/formatHelpers';
import {
  approvalsEvaluateItemEndpoint,
  createItemEndpoint,
  getItemEndpoint,
  processInvoiceEndpoint,
  processInvoicesEndpoint,
} from 'services/api/itemEndpoints';

export const getSubmitMultipleInvoicesConfig = (params, options) => {
  const { meta, payload } = params;

  const submitPayload = {
    ...payloadToJSONAPI(payload, ...submitInvoiceRelationships),
    meta,
  };

  const config = {
    ...options,
    accept: params.accept,
    endpoint: processInvoicesEndpoint,
    method: 'POST',
    payload: submitPayload,
    requireAuth: true,
    returnRejection: true,
    setRequester: true,
  };

  return config;
};

/**
 * POST request to submit multiple invoices.
 * @param {Object} params
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const submitMultipleInvoices = async (params, options = {}) =>
  FetchService.request(getSubmitMultipleInvoicesConfig(params, options));

export const getSubmitInvoiceConfig = (params, options) => {
  const { meta, payload } = params;

  const submitPayload = {
    ...payloadToJSONAPI(payload, ...submitInvoiceRelationships),
    meta,
  };

  const config = {
    ...options,
    accept: params.accept,
    endpoint: processInvoiceEndpoint,
    method: 'POST',
    payload: submitPayload,
    requireAuth: true,
    returnRejection: true,
    setRequester: true,
  };

  return config;
};

/**
 * POST request to submit an invoice.
 * @param {Object} params
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const submitInvoice = async (params, options = {}) =>
  FetchService.request(getSubmitInvoiceConfig(params, options));

export const getSubmitBillToItemConfig = (params, options) => {
  const { idempotencyKey, itemId, meta, payload } = params;

  // Get submit data structure
  const submitPayload = {
    ...payloadToJSONAPI({ id: itemId, ...payload }, ...submitItemRelationships),
    meta,
  };

  const config = {
    ...options,
    accept: params.accept,
    endpoint: getItemEndpoint(itemId),
    idempotencyKey,
    method: 'PATCH',
    payload: submitPayload,
    requireAuth: true,
    returnRejection: true,
    setRequester: true,
  };

  return config;
};

/**
 * POST request to create an item from a bill.
 * @param {Object} params
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const submitBillToItem = async (params, options = {}) =>
  FetchService.request(getSubmitBillToItemConfig(params, options));

export const getSubmitItemRequestConfig = (params, options = {}) => {
  const { idempotencyKey, meta, payload } = params;

  // Get submit data structure
  const submitPayload = {
    ...payloadToJSONAPI(payload, ...submitItemRelationships),
    meta,
  };

  const config = {
    ...options,
    accept: params.accept,
    endpoint: createItemEndpoint,
    idempotencyKey,
    method: 'POST',
    payload: submitPayload,
    requireAuth: true,
    returnRejection: true,
    setRequester: true,
  };

  return config;
};

/**
 * POST request to create an item.
 * @param {Object} params
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const submitItem = (params, options) => FetchService.request(getSubmitItemRequestConfig(params, options));

export const getPatchItemEditConfig = ({ payload, meta }, options) => {
  const preSubmitPayload = { ...payload };

  // Patch fix, we need a better solution to handle this for single relationships, in which the payloadToJSONAPI returns
  //  expect null or empty object to signal relationship removal.
  if (preSubmitPayload.partnerReceivableAccount === null) {
    preSubmitPayload.partnerReceivableAccount = {};
  }

  const submitPayload = {
    ...payloadToJSONAPI(preSubmitPayload, ...submitItemRelationships),
    meta,
  };

  return {
    ...options,
    endpoint: getItemEndpoint(payload.id),
    payload: submitPayload,
    requireAuth: true,
    setRequester: true,
  };
};

/**
 * Submit an item edit.
 *
 * @param {Object} payload
 * @param {ObjectMaybe} [options={}]
 * @returns {Promise<*>}
 */
export const patchItemEdit = async (params, options = {}) => REST.patch(getPatchItemEditConfig(params, options));

export const approvalsEvaluateItem = (config) =>
  REST.post({
    ...config,
    // Disable the default transformation of the response data, since it's not valid JSONAPI.
    // We only want to convert snake case to camel case
    transformers: [FetchService.transformToCamelCase],
    // intentional override the config.endpoint
    endpoint: approvalsEvaluateItemEndpoint,
  });

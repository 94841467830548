import { TransactionStatus, TransactionStep } from 'constants/transactions';

import { TransactionProvider } from 'enums/transactions';

// ==========
// Provider status/failure
// ==========

/**
 * Returns true if the transaction has a provider failure code.
 * @param {Transaction} transaction
 * @return {boolean}
 */
export const doesTransactionHaveFailureCode = (transaction) => !!transaction.providerFailureCode;

/**
 * Returns true if the given status is failed.
 * @param {TransactionStatus} status
 * @return {boolean}
 */
export const isTransactionProviderStatusFailed = (status) => status === TransactionStatus.FAILED;

/**
 * Returns true if the given transaction has a failed status.
 * @param {Transaction} transaction
 * @return {boolean}
 */
export const isTransactionFailed = (transaction) =>
  transaction && isTransactionProviderStatusFailed(transaction.providerStatus);

/**
 * Returns true if the given transaction should display an issue status.
 * This is the case when a transaction's status is not failed, but it has a failure code.
 * E.g. when the transaction originally went through, but R01'd.
 * @param {Transaction} transaction
 * @return {boolean}
 */
export const shouldTransactionDisplayIssueStatus = (transaction) =>
  !isTransactionFailed(transaction) && doesTransactionHaveFailureCode(transaction);

/**
 * Returns the status to display for the given transaction. This will normally be the transaction's
 * status attribute, but in the case of a non-failed transaction that has a failure code, we'll
 * display an issue status.
 * @param {Transaction} transaction
 * @return {TransactionStatus}
 */
export const getTransactionStatusToUse = (transaction) => {
  if (shouldTransactionDisplayIssueStatus(transaction)) {
    return TransactionStatus.ISSUE;
  }

  return transaction.providerStatus;
};

/**
 * Checks if the transaction status to use is 'ISSUE'
 * @param {Transaction} transaction
 * @return {Boolean}
 */
export const isTransactionStatusToUseIssue = (transaction) =>
  getTransactionStatusToUse(transaction) === TransactionStatus.ISSUE;

/**
 * Checks if the transaction status to use is 'FAILED'
 * @param {Transaction} transaction
 * @return {Boolean}
 */
export const isTransactionStatusToUseFailed = (transaction) =>
  getTransactionStatusToUse(transaction) === TransactionStatus.FAILED;

/**
 * Checks if the transaction status to use is 'CANCELED'
 * @param {Transaction} transaction
 * @return {Boolean}
 */
export const isTransactionStatusToUseCanceled = (transaction) =>
  getTransactionStatusToUse(transaction) === TransactionStatus.CANCELED;

/**
 * Checks if the transaction status to use is 'PROCESSED'
 * @param {Transaction} transaction
 * @return {Boolean}
 */
export const isTransactionStatusToUseProcessed = (transaction) =>
  getTransactionStatusToUse(transaction) === TransactionStatus.PROCESSED;

/**
 * Checks if the transaction status to use is 'PENDING'
 * @param {Transaction} transaction
 * @return {Boolean}
 */
export const isTransactionStatusToUsePending = (transaction) =>
  getTransactionStatusToUse(transaction) === TransactionStatus.PENDING;

/**
 * Checks if the transaction status to use is 'INITIATED'
 * @param {Transaction} transaction
 * @return {Boolean}
 */
export const isTransactionStatusToUseInitiated = (transaction) =>
  getTransactionStatusToUse(transaction) === TransactionStatus.INITIATED;

// ==========
// Date status change
// ==========

/**
 * Returns true if the given transaction has a date status change property.
 * @param {Transaction} transaction
 * @return {boolean}
 */
export const doesTransactionHaveDateStatusChange = (transaction) => !!transaction.dateStatusChange;

// ==========
// Transaction step
// ==========

/**
 * Returns true if the given transaction step is origination negative.
 * @param {TransactionStep} transactionStep
 * @return {boolean}
 */
export const isTransactionStepOriginationNegative = (transactionStep) =>
  transactionStep === TransactionStep.ORIGINATION_NEGATIVE;

/**
 * Returns true if the given transaction step is destination negative.
 * @param {TransactionStep} transactionStep
 * @return {boolean}
 */
export const isTransactionStepDestinationNegative = (transactionStep) =>
  transactionStep === TransactionStep.DESTINATION_NEGATIVE;

/**
 * Returns true if the given transaction step is destination or origination negative.
 * @param {TransactionStep} transactionStep
 * @return {boolean}
 */
export const isTransactionStepNegative = (transactionStep) =>
  isTransactionStepOriginationNegative(transactionStep) || isTransactionStepDestinationNegative(transactionStep);

/**
 * Returns true if transaction.provider is INTERNATIONAL_CONVERA
 * @param {Pick<Transaction, 'provider'>} transaction
 * @returns {Boolean}
 */
export const isTransactionProviderConvera = ({ provider }) => provider === TransactionProvider.INTERNATIONAL_CONVERA;

/**
 * Returns true if transaction.provider is INTERNATIONAL_CONVERA
 * @param {Pick<Transaction, 'provider'>} transaction
 * @returns {Boolean}
 */
export const isTransactionProviderCurrencyCloud = ({ provider }) =>
  provider === TransactionProvider.INTERNATIONAL_CURRENCYCLOUD;

import { updateCurrentUserRoutine } from 'actions/routines/user';

import { createLastUpdatedReducer } from 'store/redux';

import { GET_CURRENT_USER_REQUEST } from 'types/user';

export const successCases = [updateCurrentUserRoutine.SUCCESS];
export const requestCases = [GET_CURRENT_USER_REQUEST, updateCurrentUserRoutine.REQUEST];

const lastUpdatedReducer = createLastUpdatedReducer(successCases, requestCases);

export default lastUpdatedReducer;

import {
  getAllBulkImportsRoutine,
  getSingleBulkImportRoutine,
  uploadBulkImportFileRoutine,
} from 'actions/routines/bulkActions';

import { bulkImportRelationships } from 'constants/relationships';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import initialState from './initialState';

const byIdReducer = (state = initialState.byId, action) => {
  switch (action.type) {
    case getAllBulkImportsRoutine.SUCCESS:
    case getSingleBulkImportRoutine.SUCCESS:
    case uploadBulkImportFileRoutine.SUCCESS:
      return deepMergeWithArrayReplacement(
        state,
        getObjectsByIdWithRelationships(action.payload.bulkImport, bulkImportRelationships),
      );

    default:
      return state;
  }
};

export default byIdReducer;

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { verifyFundingAccountModalClose } from 'actions/funding';

import ModalBodyContent from 'components/modal/components/body/ModalBodyContent';
import Modal from 'components/modal/Modal';

import { modalNameVerifyFundingAccount } from 'constants/modals';

import { text } from 'modules/connectBank/verifyFundingAccount/constants';

import { modalIsOpenSelector } from 'selectors/modalsSelector';

import { VerifyFundingAccountForm, VerifyFundingAccountFormModalFooter } from './components';

const VerifyFundingAccountFormModal = (props) => {
  const { isOpen, onCloseModal } = props;

  return (
    <Modal
      body={
        <React.Fragment>
          <ModalBodyContent>
            <VerifyFundingAccountForm />
          </ModalBodyContent>

          <VerifyFundingAccountFormModalFooter onCloseModal={onCloseModal} />
        </React.Fragment>
      }
      hasInlineFooter
      modalHeader={text.verifyFundingAccountModalTitle}
      modalState={isOpen}
      onCloseModal={onCloseModal}
      testId="verify-funding-account-form-modal"
    />
  );
};

VerifyFundingAccountFormModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isOpen: modalIsOpenSelector(state, modalNameVerifyFundingAccount),
});

const mapDispatchToProps = {
  onCloseModal: verifyFundingAccountModalClose,
};

export { VerifyFundingAccountFormModal };
export default connect(mapStateToProps, mapDispatchToProps)(VerifyFundingAccountFormModal);

import { PaymentDeliveryMethodType } from '@routable/shared';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';

import { atLeastOnePaymentMethodOptionValidator } from 'helpers/fieldValidation';
import {
  areMultiplePaymentMethodsAvailable,
  filterPaymentDeliveryOptions,
  isPaymentMethodAvailableACH,
  isPaymentMethodAvailableCheck,
  isPaymentMethodAvailableInternational,
} from 'helpers/paymentMethods';

import { AcceptWithMethod } from './components';

const HideAllErrorsExceptLast = styled.div`
  .checkbox--container:not(:last-child) {
    .form-error-container {
      display: none;
    }
  }
`;

const DeliveryMethodsAccepted = ({
  item: { paymentMethodOptions: availablePaymentMethods },
  formFieldPrefix,
  isDomesticVendorFormat,
}) => {
  // Remove the type ANY from the provided methods, if exists
  const areMultipleMethodsAvailable = areMultiplePaymentMethodsAvailable(
    filterPaymentDeliveryOptions(availablePaymentMethods, PaymentDeliveryMethodType.ANY),
  );
  const isACHAvailable = isPaymentMethodAvailableACH(availablePaymentMethods);
  const isCheckAvailable = isPaymentMethodAvailableCheck(availablePaymentMethods);
  const isInternationalAvailable = isPaymentMethodAvailableInternational(availablePaymentMethods);

  return (
    <HideAllErrorsExceptLast className="flex flex-col mt-4">
      {isInternationalAvailable && (
        <Field
          component={AcceptWithMethod}
          input={{ checked: isInternationalAvailable }}
          isAvailable={isInternationalAvailable}
          isDisabled={isInternationalAvailable}
          isDomesticVendorFormat={isDomesticVendorFormat}
          name={`${formFieldPrefix}paymentDeliveryMethodsAccepted.${PaymentDeliveryMethodType.INTERNATIONAL}`}
          type="checkbox"
          validate={[atLeastOnePaymentMethodOptionValidator]}
        >
          Bank Transfer
        </Field>
      )}

      <Field
        component={AcceptWithMethod}
        isAvailable={isACHAvailable}
        isDisabled={!areMultipleMethodsAvailable}
        isDomesticVendorFormat={isDomesticVendorFormat}
        name={`${formFieldPrefix}paymentDeliveryMethodsAccepted.${PaymentDeliveryMethodType.ACH}`}
        type="checkbox"
        validate={[atLeastOnePaymentMethodOptionValidator]}
      >
        Bank Transfer
      </Field>

      <Field
        component={AcceptWithMethod}
        isAvailable={isCheckAvailable}
        isDisabled={!areMultipleMethodsAvailable}
        isDomesticVendorFormat={isDomesticVendorFormat}
        name={`${formFieldPrefix}paymentDeliveryMethodsAccepted.${PaymentDeliveryMethodType.CHECK}`}
        type="checkbox"
        validate={[atLeastOnePaymentMethodOptionValidator]}
      >
        Check
      </Field>
    </HideAllErrorsExceptLast>
  );
};

DeliveryMethodsAccepted.propTypes = {
  formFieldPrefix: PropTypes.string,
  item: PropTypes.shape({}).isRequired,
  isDomesticVendorFormat: PropTypes.bool,
};

DeliveryMethodsAccepted.defaultProps = {
  formFieldPrefix: '',
  item: { paymentMethodOptions: [] },
  isDomesticVendorFormat: undefined,
};

export default DeliveryMethodsAccepted;

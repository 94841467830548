import { z } from 'zod';
export const nonemptyString = z.string().refine((val) => val?.length > 0, { message: 'Required' });
export const uuidModel = z.string().uuid();
export const baseId = z.object({
    id: uuidModel,
});
export const labelValueModel = z.object({
    label: z.string(),
    value: z.string(),
});
export const paginationModel = z.object({
    count: z.number(),
    page: z.number(),
    pageSize: z.number(),
    pages: z.number(),
});
export const offsetPaginationModel = z.object({
    limit: z.number(),
    offset: z.number(),
    total: z.number(),
});
export const apiLinksModel = z.object({
    first: z.string().url(),
    last: z.string().url(),
    next: z.string().url().nullish(),
    prev: z.string().url().nullish(),
});

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import FormFieldErrors from 'components/error/components/FormFieldErrors';
import Icon from 'components/icon';
import { LinkNewPage } from 'components/link';

import { colors, formStyles } from 'constants/styles';

import { getObjDate } from 'helpers/date';
import { getDocumentIconName } from 'helpers/document';
import { and, hasLength } from 'helpers/utility';

import { DocumentContentRight } from './components';

import './DocumentV2.scss';

/**
 * Component rendering document preview and download URL
 * @param {ComponentProps} props
 * @returns {StatelessComponent}
 */
const DocumentV2 = (props) => {
  const { className, clearName, documentCreatedDate, documentName, documentURL, errors, isClearable } = props;

  const documentIconName = getDocumentIconName(documentName);

  // If a document date exists, and it's not clearable
  const shouldRenderDate = and(!isClearable, documentCreatedDate);

  return (
    <div className={classNames('document-v2', { [className]: Boolean(className) })}>
      <div
        className={classNames('form-style', 'form-control', 'document-form', 'remove-margin-bottom', {
          [formStyles.field.xl.full]: true,
          error: hasLength(errors.length),
        })}
      >
        <div className="document-form--left">
          <Icon color={colors.colorGreyDarkHex} icon={documentIconName} size={14} />
          <LinkNewPage
            className={classNames('dark', {
              'disable-pointer-events': !documentURL,
            })}
            href={documentURL}
          >
            <span className="truncate">{documentName}</span>
          </LinkNewPage>
        </div>

        {shouldRenderDate && (
          <div className="document-form--date">
            <span className="like-p dark-grey">{getObjDate(props, 'documentCreatedDate', 'll')}</span>
          </div>
        )}

        <div className="document-form--right">
          <DocumentContentRight {...props} />
        </div>
      </div>

      <FormFieldErrors errors={errors} fieldName={clearName} />
    </div>
  );
};

DocumentV2.propTypes = {
  className: PropTypes.string,
  clearName: PropTypes.string,
  documentCreatedDate: PropTypes.string,
  documentName: PropTypes.string,
  documentStatus: PropTypes.string,
  documentURL: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  hasStatusTag: PropTypes.bool,
  isClearable: PropTypes.bool,
  isDownloadable: PropTypes.bool,
};

DocumentV2.defaultProps = {
  className: 'w-full',
  clearName: '',
  documentCreatedDate: undefined,
  documentName: 'Uploaded file',
  documentStatus: undefined,
  documentURL: undefined,
  errors: [],
  hasStatusTag: undefined,
  isClearable: undefined,
  isDownloadable: undefined,
};

export default DocumentV2;

import { call, put, spawn, take } from 'redux-saga/effects';
import * as routines from 'actions/routines/purposeCode';
import { parseCaughtError, parseErrorResponse, getRequestErrorAction } from 'helpers/errors';
import {} from 'interfaces/actions';
import {} from 'interfaces/fetchService';
import {} from 'interfaces/purposeCode';
import * as api from './api';
export function* fetchPurposeCode(action) {
    let errorData = {};
    yield put(routines.fetchPurposeCodeRoutine.request());
    try {
        const { payload } = action;
        const response = yield call(api.fetchPurposeCode, payload);
        if (response.ok) {
            yield put(routines.fetchPurposeCodeRoutine.success(response.data));
            return;
        }
        errorData = parseErrorResponse(response);
    }
    catch (error) {
        errorData = parseCaughtError(error);
    }
    const errorAction = getRequestErrorAction(errorData);
    yield put(errorAction(routines.fetchPurposeCodeRoutine.failure, errorData));
}
export function* watch() {
    while (true) {
        const action = yield take([routines.fetchPurposeCodeRoutine.TRIGGER]);
        switch (action.type) {
            case routines.fetchPurposeCodeRoutine.TRIGGER:
                yield spawn(fetchPurposeCode, action);
                break;
            default:
                yield null;
        }
    }
}
export default function* purposeCode() {
    yield watch();
}

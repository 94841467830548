import { createSelector } from 'reselect';

import { isFetchingCurrentCompanySelector } from 'selectors/currentCompanySelectors';
import { isFetchingItemSelector } from 'selectors/itemsSelectors';
import { isFetchingMembershipSelector } from 'selectors/membershipsSelector';
import { isFetchingPartnershipSelector } from 'selectors/partnershipsSelectors';
import { isFetchingTaxesInfoSelector } from 'selectors/taxesSelectors';

/**
 * Selects isFetching from state for multiple calls to the backend
 * @param {object} state - Redux state
 * @returns {boolean}
 */
export const isFetchingRequirementsSelector = createSelector(
  [
    isFetchingCurrentCompanySelector,
    isFetchingItemSelector,
    isFetchingMembershipSelector,
    isFetchingPartnershipSelector,
    isFetchingTaxesInfoSelector,
  ],
  (...selectors) => selectors.some(Boolean),
);

import React from 'react';
import { IconNames } from 'components';
import { Details, DetailsContent, Summary, ChevronIcon } from './Accordion.styles';
import {} from './Accordion.type';
import Answer from './Answer';
const Accordion = ({ title, children, answers, containerWidth }) => (React.createElement(Details, { containerWidth: containerWidth },
    React.createElement(Summary, null,
        React.createElement("span", null,
            React.createElement(ChevronIcon, { icon: IconNames.CHEVRON_RIGHT })),
        title),
    React.createElement(DetailsContent, null,
        children,
        answers?.length &&
            !children &&
            answers.map((answer, index) => {
                const key = `answer_${index}`;
                return React.createElement(Answer, { ...answer, key: key });
            }))));
export default Accordion;

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import ModalBodyContent from 'components/modal/components/body/ModalBodyContent';

import { formNamesFunding } from 'constants/forms';

import { onSubmitFailReduxForm } from 'helpers/errors';

import ConnectBankManualConfirmation from 'modules/connectBank/connectBankManual/components/ConnectBankManualConfirmation';
import ConnectBankManualFooter from 'modules/connectBank/connectBankManual/components/ConnectBankManualFooter';
import ConnectBankManualForm from 'modules/connectBank/connectBankManual/components/ConnectBankManualForm';
import { onConnectBankRoutingNumberChange } from 'modules/connectBank/connectBankManual/helpers/bankName';
import { getInitialValues } from 'modules/connectBank/connectBankManual/helpers/initialValues';
import { handleConnectBankManualSubmit } from 'modules/connectBank/connectBankManual/helpers/submit';

import { fundingAccountBankManualLastSubmittedSelector } from 'selectors/fundingSelectors';

const ConnectBankManual = ({ handleSubmit, lastSubmitted, onConnectBankManualSubmit }) => (
  <form
    className="form"
    id={formNamesFunding.ADD_MANUAL_BANK}
    onSubmit={handleSubmit((values) => onConnectBankManualSubmit(values))}
  >
    <ModalBodyContent>
      {lastSubmitted ? (
        <ConnectBankManualConfirmation />
      ) : (
        <ConnectBankManualForm formName={formNamesFunding.ADD_MANUAL_BANK} />
      )}
    </ModalBodyContent>

    <ConnectBankManualFooter />
  </form>
);

ConnectBankManual.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  lastSubmitted: PropTypes.instanceOf(Date),
  onConnectBankManualSubmit: PropTypes.func.isRequired,
};

ConnectBankManual.defaultProps = {
  lastSubmitted: undefined,
};

const mapStateToProps = (state, ownProps) => ({
  initialValues: getInitialValues(ownProps.createCheckSource),
  lastSubmitted: fundingAccountBankManualLastSubmittedSelector(state),
});

const mapDispatchToProps = {
  onConnectBankManualSubmit: handleConnectBankManualSubmit,
};

const reduxFormConnectBankManualForm = reduxForm({
  form: formNamesFunding.ADD_MANUAL_BANK,
  onChange: onConnectBankRoutingNumberChange,
  onSubmitFail: onSubmitFailReduxForm,
})(ConnectBankManual);

export { ConnectBankManual };
export default connect(mapStateToProps, mapDispatchToProps)(reduxFormConnectBankManualForm);

import React from 'react';
import { Icon } from 'components/icon';
import FlexRow from 'components/layout/FlexRow';
import Text from 'components/text/Text';
import { colors, typography } from 'constants/styles';
import { getClassNames } from 'helpers/ui';
import {} from './SubtitleWithIcon.types';
const textProps = {
    ...typography.TitleWithSubtitle.textProps,
    color: colors.colorDarkJordanHex,
};
const SubtitleWithIcon = ({ icon, iconProps = {}, text, IconComponent = Icon, ...rest }) => (React.createElement(FlexRow, { className: getClassNames(rest, { 'align-items--center': true }), stackOnMobile: false },
    icon && React.createElement(IconComponent, { icon: icon, ...typography.TitleWithSubtitle.iconProps, ...iconProps }),
    React.createElement(Text.Regular, { ...textProps, ...rest, className: getClassNames(rest, {}, { classProp: 'textClassName' }) }, text)));
export default SubtitleWithIcon;

import { fetchSingleIntegrationLedgerPartnershipsRoutine } from 'actions/routines/integrations';

import { createErrorReducer } from 'store/redux';

import {
  FETCH_LEDGER_PARTNERSHIPS_FAILURE,
  FETCH_LEDGER_PARTNERSHIPS_REQUEST,
  FETCH_LEDGER_PARTNERSHIPS_SUCCESS,
  FETCH_PLATFORM_PARTNERSHIPS_FAILURE,
  FETCH_PLATFORM_PARTNERSHIPS_REQUEST,
  FETCH_PLATFORM_PARTNERSHIPS_SEARCH_SUCCESS,
  FETCH_PLATFORM_PARTNERSHIPS_SUCCESS,
} from 'types/integrations';

export const failureCases = [
  fetchSingleIntegrationLedgerPartnershipsRoutine.FAILURE,
  FETCH_LEDGER_PARTNERSHIPS_FAILURE,
  FETCH_PLATFORM_PARTNERSHIPS_FAILURE,
];

export const successCases = [
  fetchSingleIntegrationLedgerPartnershipsRoutine.REQUEST,
  fetchSingleIntegrationLedgerPartnershipsRoutine.SUCCESS,
  FETCH_LEDGER_PARTNERSHIPS_REQUEST,
  FETCH_LEDGER_PARTNERSHIPS_SUCCESS,
  FETCH_PLATFORM_PARTNERSHIPS_REQUEST,
  FETCH_PLATFORM_PARTNERSHIPS_SEARCH_SUCCESS,
  FETCH_PLATFORM_PARTNERSHIPS_SUCCESS,
];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

import PropTypes from 'prop-types';
import React from 'react';

import { ContactUs } from 'components/error';

import { CONTACT_SUPPORT_LABEL } from 'constants/labels';

const DisabledUserContactUsLink = ({ children, className }) => (
  <ContactUs className={className} textBefore={children}>
    {CONTACT_SUPPORT_LABEL}
  </ContactUs>
);

DisabledUserContactUsLink.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
};

DisabledUserContactUsLink.defaultProps = {
  children: undefined,
  className: undefined,
};

export default DisabledUserContactUsLink;

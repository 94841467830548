import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  closeConnectBankManualModal,
  closeConnectBankTokenModal,
  openConnectBankManualModal,
  openConnectBankTokenModal,
  updateConnectBankTokenModal,
} from 'actions/connectBank';

import { modalNameConnectBankManual, modalNameConnectBankToken } from 'constants/modals';

import ConnectBankOptions from 'modules/connectBank/global/ConnectBankOptions';

import { fundingAccountBankTokenIsSubmittingSelector } from 'selectors/fundingSelectors';
import { createModalSelector } from 'selectors/modalsSelector';

import createFundingAccountLinkToken from 'thunks/createFundingAccountLinkToken';
import submitFundingAccountBankToken from 'thunks/submitFundingAccountBankToken';

import { connectors } from './helpers';

export const connectBankManualModalSelector = createModalSelector(modalNameConnectBankManual);
export const connectBankTokenModalSelector = createModalSelector(modalNameConnectBankToken);

export const mapStateToProps = createStructuredSelector({
  connectBankManualModal: connectBankManualModalSelector,
  connectBankTokenModal: connectBankTokenModalSelector,
  isSubmitting: fundingAccountBankTokenIsSubmittingSelector,
});

// TODO: [DEV-1095] ConnectBank ecosystem needs a refactor to be saga-ized, tested, and modernized for our current codebase.

export const mapDispatchToProps = {
  /**
   * This thunk is used to create the funding account link token. This is used for the new plaid migration
   * https://plaid.com/docs/link/link-token-migration-guide/
   */
  createFundingAccountLinkToken,
  /**
   * By convention, this function should be named onSubmitFundingTokenAccount (see convention in function above).
   * ConnectBankOptions is expecting an onSubmitFundingTokenAccount function, but we can't just map this dispatcher
   * to the props like normal...we need to create the submit payload, close account modal, etc.
   * handleSubmitFundingTokenAccount is part of the onSubmitFundingTokenAccount handler created in mergeProps.
   */
  handleSubmitFundingTokenAccount: submitFundingAccountBankToken,
  onCloseManualAccountModal: closeConnectBankManualModal,
  onCloseTokenAccountModal: closeConnectBankTokenModal,
  onOpenManualAccountModal: openConnectBankManualModal,
  onOpenTokenAccountModal: openConnectBankTokenModal,
  onUpdateTokenAccountModal: updateConnectBankTokenModal,
};

export default connect(mapStateToProps, mapDispatchToProps, connectors.mergeProps)(ConnectBankOptions);

import { SubmissionError } from 'redux-form';

import { changeSaveStatus, changeView } from 'actions/signUpFlow';

import { cleanFieldsOnSubmitSuccess } from 'helpers/formChanges';
import { getCurrentCompanyId } from 'helpers/localStorage';

import { getHasAddress } from './getHasAddress';
import { viewSteps } from './viewSteps';

/**
 * Helper to submit the business validation form
 * @param {ReduxFormValues} values
 * @param {Dispatch} dispatch
 * @param {ComponentProps} props
 */
const submit = async (values = {}, dispatch, props = {}) => {
  const { onUpdateCurrentCompany } = props;

  const updatePayload = {
    id: values.currentCompanyId,
  };

  // values.physicalAddress only contains values that have been fulfilled
  // Country is pre-filled from the start, making hasPhysicalAddress true and trying to submit an invalid form
  // resulting in a submission error. Length greater than 1 ensures more than the country is filled
  // and still validates the rest is filled as a safeguard
  const hasPhysicalAddress = getHasAddress(values.physicalAddress);
  const hasMailingAddress = getHasAddress(values.mailingAddress);

  Object.assign(updatePayload, {
    companyType: values.companyType,
    info: { ...values.info },
    name: values.name,
    ...(hasPhysicalAddress && { physicalAddress: values.physicalAddress }),
    ...(hasMailingAddress && { mailingAddress: values.mailingAddress }),
  });

  dispatch(changeSaveStatus.saving());

  const successCallback = (parsedResponse) => {
    // we want to omit uploaded documents from the next form values, or
    // they'll be double rendered (by UploadDocumentInfo and UploadedDocuments)
    const nextValues = { ...values, documents: [] };
    const companyId = getCurrentCompanyId();
    const { relationships } = parsedResponse.company?.[companyId];
    nextValues.info.id = relationships?.info?.data?.id;
    nextValues.physicalAddress.id = relationships?.physicalAddress?.data?.id;
    // for the prompt-on-navigation behavior to behave in an expected way,
    // it's important that we cleanse the fields here while we have access
    // to the final values of the form, as they were when it was submitted.
    // this lets the initialize action reset all fields to their current values.
    cleanFieldsOnSubmitSuccess(nextValues, dispatch, props, {
      keepDirty: false,
      keepSubmitSucceeded: true,
      keepValues: false,
    });
    dispatch(changeSaveStatus.saved());
    return parsedResponse;
  };

  const submitOptions = {
    successCallback,
    verificationUpdateAllowed: true,
    verificationUpdateDocumentAllowed: true,
    sendAddress: hasPhysicalAddress,
  };
  const submissionErrors = await onUpdateCurrentCompany(updatePayload, submitOptions);

  if (submissionErrors && submissionErrors.errors) {
    throw new SubmissionError({ form: submissionErrors.errors.fields });
  } else {
    const next = viewSteps[props.activeView]?.next?.path;
    dispatch(changeView(next));
  }

  return submissionErrors;
};

export default submit;

import { ssoDisableRoutine } from 'actions/routines/sso';
import { SSOActionType } from 'enums/sso';
import { getModalReducerOpenAction } from 'store/redux';
export const initialState = {
    open: false,
};
const modalSSODisableReducer = (state = initialState, action) => {
    switch (action.type) {
        case SSOActionType.CLOSE_DISABLE_MODAL:
        case ssoDisableRoutine.SUCCESS:
        case ssoDisableRoutine.FAILURE:
            return initialState;
        case SSOActionType.OPEN_DISABLE_MODAL:
            return getModalReducerOpenAction(state, action);
        default:
            return state;
    }
};
export default modalSSODisableReducer;

import { all, call, put } from 'redux-saga/effects';

import { fetchSingleItemRequest } from 'actions/item';
import { createItemTagLinksRoutine } from 'actions/routines/tags';
import { closeSidePanel } from 'actions/sidePanels';
import { showSuccessUi } from 'actions/ui';

import { sidePanelNameItemThreadTags } from 'constants/sidePanels';
import { SuccessIndicatorMessages } from 'constants/ui';

import { getRequestErrorAction, parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { hasLength, hasZeroLength } from 'helpers/utility';

import * as api from './api';

/**
 * handleCreateItemTagLinksSuccess
 * @param {Object} response
 * @return {IterableIterator<*> }
 */
function* handleCreateItemTagLinksSuccess(response) {
  if (response.ok) {
    const { tag, tagLink } = response.data;
    yield put(createItemTagLinksRoutine.success({ tag, tagLink }));
  }
  return yield response;
}

/**
 * Create tagLinks connecting an item with tags
 * @params {object} props
 * @params {object} props.payload
 * @return {IterableIterator<*>}
 */
export function* createItemTagLinksSaga({ payload }) {
  yield put(createItemTagLinksRoutine.request());

  let errorData = {};

  try {
    const {
      props: { itemId },
      values,
    } = payload;

    // unfortunately the backend doesn't yet expose a batch endpoint
    // so we have to handle making successive calls
    const callCreateItemTagLinks = ({ id }) => call(api.createItemTagLinks, { tagId: id, itemId });
    const responses = yield all(values.map(callCreateItemTagLinks));

    // ...and handle all possible responses
    if (hasLength(responses)) {
      const successes = responses.filter((response) => response.ok);
      const errors = responses.filter((response) => !response.ok);

      yield all(successes.map(handleCreateItemTagLinksSuccess));

      if (hasZeroLength(errors)) {
        yield put(closeSidePanel({ name: sidePanelNameItemThreadTags }));
        yield put(fetchSingleItemRequest(itemId));
        yield put(showSuccessUi(SuccessIndicatorMessages.ADD_TAG));
        return yield successes[0];
      }

      errorData = parseErrorResponse(errors[0]);
    }
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(errorData);
  return yield put(errorAction(createItemTagLinksRoutine.failure, errorData));
}

export default createItemTagLinksSaga;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import BrandLogo from '../brand/BrandLogo';

import './BrandNav.scss';

const BrandNav = ({ children, disableLink }) => {
  const logoClassName = classNames({
    'nav--content-left': true,
    'disable-pointer-events': disableLink,
  });

  return (
    <div className="brand--nav">
      <div className={logoClassName}>
        <BrandLogo />
      </div>

      {children && <div className="nav--content-right">{children}</div>}
    </div>
  );
};

BrandNav.propTypes = {
  children: PropTypes.node,
  disableLink: PropTypes.bool,
};

BrandNav.defaultProps = {
  children: null,
  disableLink: false,
};

export default BrandNav;

import {
  fetchExternalPartnershipRequestRoutine,
  submitPartnerCompanyContactInfoRoutine,
} from 'actions/routines/external';
import { lookupMembershipInviteEmailRoutine } from 'actions/routines/inviteTeamMember';
import { disableMembershipRoutine, updateMembershipRoutine } from 'actions/routines/membership';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import {
  FETCH_MEMBERSHIPS_SUCCESS,
  FETCH_MEMBERSHIP_CONFIG_SUCCESS,
  FETCH_MEMBERSHIP_SUCCESS,
  UPDATE_MEMBERSHIP_SUCCESS,
  UPDATE_ONBOARDING_MEMBERSHIP_SUCCESS,
} from 'types/memberships';

const byIdReducer = (state = {}, action) => {
  switch (action.type) {
    case disableMembershipRoutine.SUCCESS:
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
    case FETCH_MEMBERSHIP_CONFIG_SUCCESS:
    case FETCH_MEMBERSHIP_SUCCESS:
    case FETCH_MEMBERSHIPS_SUCCESS:
    case lookupMembershipInviteEmailRoutine.FAILURE:
    case UPDATE_MEMBERSHIP_SUCCESS:
    case updateMembershipRoutine.SUCCESS:
    case UPDATE_ONBOARDING_MEMBERSHIP_SUCCESS:
    case submitPartnerCompanyContactInfoRoutine.SUCCESS:
      return deepMergeWithArrayReplacement(
        state,
        getObjectsByIdWithRelationships(action.payload.membership, [
          'address',
          'company',
          'documentsOwned',
          'notificationSettings',
          'personalInfo',
          'user',
          'role',
        ]),
      );

    default:
      return state;
  }
};

export default byIdReducer;

import PropTypes from 'prop-types';
import React from 'react';

import { useClientRect } from 'hooks';

import { getMarginHeightForChildOfNode, shouldReplaceChildrenWithLoadMoreButton } from './helpers';

/**
 * LoadMoreChild
 * This component decides whether the child should be shown
 * or whether logic should be triggered to show the LoadMore button
 * @return {FunctionComponent}
 * @constructor
 */
const LoadMoreChild = ({ children, wrapperRectTop, visibleRows, onShowLoadMore }) => {
  const [isVisible, setIsVisible] = React.useState(true);
  const { node, rect, ref } = useClientRect();

  const marginHeight = getMarginHeightForChildOfNode(node);

  React.useEffect(() => {
    const showLoadMoreButton = shouldReplaceChildrenWithLoadMoreButton({
      rect,
      marginBetweenRows: marginHeight,
      wrapperRectTop,
      visibleRows,
    });

    if (showLoadMoreButton) {
      setIsVisible(false);
      onShowLoadMore();
    }
  }, [rect, visibleRows, wrapperRectTop, onShowLoadMore, marginHeight]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="display--inline-flex" ref={ref}>
      {children}
    </div>
  );
};

LoadMoreChild.propTypes = {
  children: PropTypes.node.isRequired,
  wrapperRectTop: PropTypes.number,
  visibleRows: PropTypes.number.isRequired,
  onShowLoadMore: PropTypes.func.isRequired,
};

LoadMoreChild.defaultProps = {
  wrapperRectTop: undefined,
};

export default LoadMoreChild;

import { companyBusinessTypes } from 'constants/company';
export const businessTypeOptions = {
    'c-corporation': {
        text: 'C-Corporation',
        value: companyBusinessTypes.C_CORP,
    },
    's-corporation': {
        text: 'S-Corporation',
        value: companyBusinessTypes.S_CORP,
    },
    partnership: {
        text: 'Partnership',
        value: companyBusinessTypes.PARTNERSHIP,
    },
    'sole-proprietor': {
        text: 'Sole Proprietor',
        value: companyBusinessTypes.SOLE_PROPRIETOR,
    },
    'trust-estate': {
        text: 'Trust / Estate',
        value: companyBusinessTypes.TRUST_ESTATE,
    },
    'llc-single-member': {
        text: 'LLC (Single Member)',
        value: companyBusinessTypes.LLC_SINGLE_MEMBER,
    },
    'llc-c-corp': {
        text: 'LLC (C-Corporation)',
        value: companyBusinessTypes.LLC_C_CORP,
    },
    'llc-s-corp': {
        text: 'LLC (S-Corporation)',
        value: companyBusinessTypes.LLC_S_CORP,
    },
    'llc-partnership': {
        text: 'LLC (Partnership)',
        value: companyBusinessTypes.LLC_PARTNERSHIP,
    },
    'llc-trust-estate': {
        text: 'LLC (Trust / Estate)',
        value: companyBusinessTypes.LLC_TRUST_ESTATE,
    },
};
export const STATE_OF_INCORPORATION_OPTIONAL = [companyBusinessTypes.SOLE_PROPRIETOR, companyBusinessTypes.PARTNERSHIP];

import PropTypes from 'prop-types';
import React from 'react';

import Modal, { ModalBodyContent } from 'components/modal';

import { BankAccountViewType } from 'constants/ui';

import { BankAccount } from 'modules/fundingAccount';

const BankAccountDetailsModal = (props) => {
  const { modalState, onCloseModal } = props;

  const { fundingAccount, fundingInfoBankAccount, open, partnership, viewType } = modalState;

  return (
    <Modal
      body={
        <ModalBodyContent>
          <BankAccount
            fundingAccount={fundingAccount}
            fundingInfoBankAccount={fundingInfoBankAccount}
            partnership={partnership}
            partnershipFundingAccount={fundingAccount}
            viewType={viewType}
          />
        </ModalBodyContent>
      }
      modalHeader="Bank account details"
      modalState={open}
      onCloseModal={onCloseModal}
      testId="bank-account-details-modal"
    />
  );
};

BankAccountDetailsModal.propTypes = {
  modalState: PropTypes.shape({
    fundingAccount: PropTypes.shape().isRequired,
    fundingInfoBankAccount: PropTypes.shape(),
    open: PropTypes.bool,
    partnership: PropTypes.shape(),
    viewType: PropTypes.oneOf(Object.values(BankAccountViewType)),
  }).isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default BankAccountDetailsModal;

import { createSelector } from 'reselect';

const getState = (state) => state.taxes;

export const taxesInfoSelector = createSelector([getState], (taxes) => taxes.info);

export const isFetchingTaxesInfoSelector = createSelector([taxesInfoSelector], (info) => info.isFetching);

export const taxesInfoByIdSelector = createSelector([taxesInfoSelector], (info) => info.byId);

export const taxesMetaSelector = createSelector([taxesInfoSelector], (info) => info.meta);

export const taxesAmountSelector = createSelector([taxesMetaSelector], (meta) => meta.amount);

export const taxesInfoEditNotAllowedSelector = createSelector([taxesMetaSelector], (meta) => !meta.taxInfoEditAllowed);

export const taxesInfoSubmittedPartnership = createSelector(
  [taxesMetaSelector],
  (meta) => meta.taxInfoSubmittedPartnership,
);

export const taxesDateTaxInfoSubmittedSelector = createSelector(
  [taxesMetaSelector],
  (meta) => meta.dateTaxInfoSubmitted,
);

export const taxesDateTaxInfoSubmittedForPartnershipSelector = createSelector(
  [taxesMetaSelector],
  (meta) => meta.dateTaxInfoSubmittedForPartnership,
);

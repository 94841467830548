import { connect } from 'react-redux';
import { change } from 'redux-form';

import { createItemFormFields } from 'constants/formFields';
import { formNamesItem } from 'constants/forms';

import { createItemFormSelector } from 'selectors/forms';

import DiscountToggle from './DiscountToggle';

const mapStateToProps = (state) => ({
  formUI: createItemFormSelector(state, 'ui'),
});

const mapDispatchToProps = {
  onToggle: (nextValue) => change(formNamesItem.CREATE_ITEM, createItemFormFields.UI_SHOW_INVOICE_DISCOUNT, nextValue),
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountToggle);

import PermissionResourceAction from 'constants/permissions';

import { withIsDisabledWhenMissingPermissions } from 'hoc';

import ActionNavUploadAction from './ActionNavUploadAction';

export const requiredPermissions = [
  PermissionResourceAction.PAYABLE_CREATE,
  PermissionResourceAction.RECEIVABLE_CREATE,
];

export default withIsDisabledWhenMissingPermissions(requiredPermissions)(ActionNavUploadAction);

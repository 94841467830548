import { createAddressRoutine, updateAddressRoutine } from 'actions/routines/addresses';

import { createLastSubmittedReducer } from 'store/redux';

/**
 * Last submitted reducer's success cases
 * @type {ReduxType[]}
 */
export const successCases = [createAddressRoutine.SUCCESS, updateAddressRoutine.SUCCESS];

/**
 * Last submittied reducer's request or failure cases
 * @type {ReduxType[]}
 */
export const requestOrFailureCases = [
  createAddressRoutine.REQUEST,
  createAddressRoutine.FAILURE,
  updateAddressRoutine.REQUEST,
  updateAddressRoutine.FAILURE,
];

/**
 * Addresses last submitted reducer
 * @param {ReduxState} state
 * @param {ReduxAction} action
 * @returns {null|Timestamp}
 */
const lastSubmittedReducer = createLastSubmittedReducer(successCases, requestOrFailureCases);

export default lastSubmittedReducer;

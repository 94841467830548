import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getQueryParam } from '../../../../../helpers/queryParams';
import { partnershipSelector } from '../../../../../selectors/partnershipsSelectors';
import ExternalPromptHeader from '../../global/presenters/ExternalPromptHeader';

const PromptHeaderPayablePaid = ({ partnership }) => (
  <ExternalPromptHeader text={`Your payment from ${partnership.name} is on its way!`} />
);

PromptHeaderPayablePaid.propTypes = {
  partnership: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => {
  const partnershipId = getQueryParam('partnership_id');

  return {
    partnership: partnershipSelector(state, partnershipId),
  };
};

export { PromptHeaderPayablePaid };
export default connect(mapStateToProps)(PromptHeaderPayablePaid);

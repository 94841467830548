import styled from 'styled-components';
import { Button } from '@routable/gross-ds';
import { Renew } from '@carbon/icons-react';
export const OptionsContainer = styled.div.attrs((props) => ({
    height: props.height,
})) `
  position: relative;
  height: calc(100% - 40px);

  min-height: 36px;
  max-height: ${(props) => `${props.height ?? 200}px`};
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
`;
export const ComboBoxOption = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-4);
  font: var(--font-regular);
  font-size: var(--font-size-2);
  color: var(--color-grey70);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;

  /* copied TW styles here */
  padding: var(--spacing-2) var(--spacing-3);
  border-radius: var(--spacing-3);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  &[data-focus='true'] {
    background-color: var(--color-grey5);
    color: var(--color-blue40);
  }

  /* putting focus as false to not need to worry about build time vs TW class build time */
  &[aria-selected='true'][data-focus='false'] {
    background-color: var(--color-neutral-white);
    color: var(--color-blue40);
  }
`;
export const SearchContainer = styled.div `
  padding-bottom: 8px;
`;
export const ClearButton = styled(Button) `
  position: absolute;
  top: 6px;
  right: 4px;
  border: 0px solid rgba(0, 0, 0, 0) !important;
  outline-color: rgba(0, 0, 0, 0) !important;
  border: 0px solid rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;

  & * {
    outline-color: rgba(0, 0, 0, 0) !important;
    border: 0px solid rgba(0, 0, 0, 0) !important;
    box-shadow: none !important;
  }
`;
export const ResultsLoading = styled.div `
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: var(--color-neutral-white);
  z-index: 10;

  padding: var(--spacing-4);

  font-size: var(--font-size-2);
`;
export const EmptyResults = styled.div `
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: var(--color-neutral-white);
  z-index: 10;

  & span {
    font: var(--font-regular);
    font-size: var(--font-size-2);
    position: absolute;
    top: 50%;
    left: 50%;
    width: max-content;
    transform: translate(-50%, -50%);
    color: var(--color-grey50);
  }
`;
export const LoadingIcon = styled(Renew) `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-grey30);
  animation-name: loadingRotation;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  margin-left: -8px;
  margin-top: -8px;
  fill: var(--color-blue40) !important;

  & svg {
    fill: var(--color-blue40) !important;
  }

  @-webkit-keyframes loadingRotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes loadingRotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
export const FrequentlyUsedOptions = styled.div `
  font: var(--font-regular);
  font-size: var(--font-size-1);
  background-color: var(--color-blue02);
  border-radius: 6px;
  color: var(--color-blue40);
  position: -wk-sticky;
  position: sticky;
  top: 0px;
  padding: 6px;
`;

import { REST } from 'helpers/api';

import { getNamespaceIsValid } from 'services/api/namespaceEndpoints';

export const checkNamespaceIsValid = async (workspace, options) =>
  REST.get({
    ...options,
    endpoint: getNamespaceIsValid(workspace),
    requireAuth: true,
  });

import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';

import {
  FETCH_EXTERNAL_CONFIG,
  FETCH_EXTERNAL_PARTNERSHIP_REQUEST,
  SUBMIT_EXTERNAL_FUNDING_STATE,
  SUBMIT_EXTERNAL_PARTNER_COMPANY_CONTACT_INFO,
  SUBMIT_EXTERNAL_PARTNER_COMPANY_GENERAL_INFO,
  SUBMIT_EXTERNAL_TERMS_OF_SERVICE,
} from 'types/external';

/**
 * @type {ReduxSagaRoutine}
 */
export const fetchExternalConfigRoutine = createRoutine(FETCH_EXTERNAL_CONFIG);

/**
 * @type {ReduxSagaRoutine}
 */
export const fetchExternalPartnershipRequestRoutine = createRoutine(FETCH_EXTERNAL_PARTNERSHIP_REQUEST);

/**
 * @type {ReduxSagaRoutine}
 */
export const submitFundingStateRoutine = createRoutine(SUBMIT_EXTERNAL_FUNDING_STATE);

/**
 * We always use the submitFundingStateRoutine in a similar way, with a common payload structure. This helper formats
 * that payload.
 *
 * @param {OptionsArg} options
 * @param {Object} options.defaultReceivable
 * @param {ItemKind} options.itemKind
 * @returns {Object} dispatch payload
 */
export const submitFundingStateRoutineWithPayload = ({ defaultReceivable, itemKind }) =>
  submitFundingStateRoutine({
    defaultReceivable,
    meta: {
      kind: itemKind,
    },
  });

/**
 * @type {ReduxSagaRoutine}
 */
export const submitPartnerCompanyContactInfoRoutine = createRoutine(SUBMIT_EXTERNAL_PARTNER_COMPANY_CONTACT_INFO);

/**
 * @type {ReduxSagaRoutine}
 */
export const submitPartnerCompanyGeneralInfoRoutine = createRoutine(SUBMIT_EXTERNAL_PARTNER_COMPANY_GENERAL_INFO);

/**
 * Bound routine which we use to submit the form for adding partner company general info.
 */
export const submitPartnerCompanyGeneralInfoHandler = bindRoutineToReduxForm(submitPartnerCompanyGeneralInfoRoutine);

/**
 * @type {ReduxSagaRoutine}
 */
export const submitTermsOfServiceRoutine = createRoutine(SUBMIT_EXTERNAL_TERMS_OF_SERVICE);

import { getModalReducerOpenAction } from 'store/redux';

import * as types from 'types/funding';

export const initialState = {
  fundingAccount: null,
  open: false,
  partnership: null,
  viewType: null,
};

const bankAccountDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MODAL_BANK_ACCOUNT_DETAILS_CLOSE:
      return initialState;

    case types.MODAL_BANK_ACCOUNT_DETAILS_OPEN:
      return getModalReducerOpenAction(state, action);

    default:
      return state;
  }
};

export default bankAccountDetailsReducer;

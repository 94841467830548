import { createAddressRoutine, updateAddressRoutine } from 'actions/routines/addresses';

import { createErrorReducer } from 'store/redux';

import {
  FETCH_ADDRESS_FAILURE,
  FETCH_ADDRESSES_FAILURE,
  FETCH_ADDRESSES_REQUEST,
  FETCH_ADDRESSES_SUCCESS,
} from 'types/addresses';

export const failureCases = [
  FETCH_ADDRESS_FAILURE,
  FETCH_ADDRESSES_FAILURE,
  createAddressRoutine.FAILURE,
  updateAddressRoutine.FAILURE,
];
export const successCases = [
  FETCH_ADDRESSES_REQUEST,
  FETCH_ADDRESSES_SUCCESS,
  createAddressRoutine.REQUEST,
  createAddressRoutine.SUCCESS,
  updateAddressRoutine.REQUEST,
  updateAddressRoutine.SUCCESS,
];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

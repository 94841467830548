import { all, call, fork, put, take } from 'redux-saga/effects';

import * as actions from 'actions/apiToken';
import { getCurrentCompanyRequest } from 'actions/currentCompany';
import { handleRequestErrors } from 'actions/errors';

import { parseCaughtError, parseErrorResponse } from 'helpers/errors';

import * as types from 'types/apiToken';

import * as api from './api';

/**
 * Fetch the API tokens for this company.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* fetchTokens(action) {
  const {
    payload: { companyId },
  } = action;

  let errorData = {};

  try {
    const response = yield call(api.fetchTokens, companyId);

    if (response.ok) {
      yield put(actions.fetchApiTokensSuccess(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchApiTokensFailure, errorData));
}

/**
 * Delete the specified API token.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* deleteToken(action) {
  const {
    payload: { companyId, tokenId },
  } = action;

  let errorData = {};

  try {
    const response = yield call(api.deleteToken, companyId, tokenId);

    if (response.ok) {
      yield put(actions.deleteApiTokenSuccess(tokenId));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.deleteApiTokenFailure, errorData));
}

/**
 * Regenerate the specified API token.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* regenerateToken(action) {
  const {
    payload: { companyId, tokenId },
  } = action;

  let errorData = {};

  try {
    const response = yield call(api.regenerateToken, companyId, tokenId);

    if (response.ok) {
      yield put(actions.regenerateApiTokenSuccess({ ...response.data, tokenId }));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.regenerateApiTokenFailure, errorData));
}

/**
 * Create an API token.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* createToken(action) {
  const {
    payload: { companyId },
  } = action;

  let errorData = {};

  try {
    const response = yield call(api.createToken, companyId);

    if (response.ok) {
      yield all([
        put(actions.createApiTokenSuccess(response.data)),
        put(actions.openCreateApiTokenModal()),
        // refresh company settings to display new api version assigned
        put(getCurrentCompanyRequest()),
      ]);
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.createApiTokenFailure, errorData));
}

/**
 * Listens for redux actions related to API tokens.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([
      types.CREATE_API_TOKEN_REQUEST,
      types.DELETE_API_TOKEN_REQUEST,
      types.REGENERATE_API_TOKEN_REQUEST,
      types.FETCH_API_TOKENS_REQUEST,
    ]);

    switch (action.type) {
      case types.CREATE_API_TOKEN_REQUEST:
        yield fork(createToken, action);
        break;

      case types.DELETE_API_TOKEN_REQUEST:
        yield fork(deleteToken, action);
        break;

      case types.REGENERATE_API_TOKEN_REQUEST:
        yield fork(regenerateToken, action);
        break;

      case types.FETCH_API_TOKENS_REQUEST:
        yield fork(fetchTokens, action);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root API tokens saga.
 * @return {IterableIterator<*>}
 */
export default function* apiTokens() {
  yield watch();
}

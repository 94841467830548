import { PAYMENTS_LIST_TABS } from 'constants/routes';

export const CHECKBOX_COLUMN_ACCESSOR_ID = 'checkbox';

export const DETAILS_COLUMN_ACCESSOR_ID = 'details';

export const TABLE_BORDER_HEIGHT = 1;
export const TABLE_DEFAULT_PAGE_SIZE = 25;
export const TABLE_HEADER_HEIGHT = 40;
export const TABLE_ROW_HEIGHT = 58;

export const tableNamesCreateItems = {
  PAYABLE: 'createItemsPayableTable',
  RECEIVABLE: 'createItemsReceivableTable',
};

export const tableNamesDashboard = {
  [PAYMENTS_LIST_TABS.INBOX]: 'payablesTable',
  [PAYMENTS_LIST_TABS.PAYABLES]: 'payablesTable',
  [PAYMENTS_LIST_TABS.RECEIVABLES]: 'receivablesTable',
};

export const PAGE_SIZE_OPTIONS = [25, 50, 100].map((label) => ({
  value: label,
  label,
}));

export const CompaniesListCellDataKeys = {
  OutstandingSum: 'outstandingSum',
};

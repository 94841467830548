export const FETCH_ADDRESSES_FAILURE = 'FETCH_ADDRESSES_FAILURE';
export const FETCH_ADDRESSES_REQUEST = 'FETCH_ADDRESSES_REQUEST';
export const FETCH_ADDRESSES_SUCCESS = 'FETCH_ADDRESSES_SUCCESS';

export const FETCH_ADDRESS_FAILURE = 'FETCH_ADDRESS_FAILURE';
export const FETCH_ADDRESS_REQUEST = 'FETCH_ADDRESS_REQUEST';
export const FETCH_ADDRESS_SUCCESS = 'FETCH_ADDRESS_SUCCESS';

export const CREATE_ADDRESS = '@address/CREATE_ADDRESS';
export const UPDATE_ADDRESS = '@address/UPDATE_ADDRESS';

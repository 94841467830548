export const CREATE_API_TOKEN_FAILURE = 'CREATE_API_TOKEN_FAILURE';
export const CREATE_API_TOKEN_REQUEST = 'CREATE_API_TOKEN_REQUEST';
export const CREATE_API_TOKEN_SUCCESS = 'CREATE_API_TOKEN_SUCCESS';

export const DELETE_API_TOKEN_FAILURE = 'DELETE_API_TOKEN_FAILURE';
export const DELETE_API_TOKEN_REQUEST = 'DELETE_API_TOKEN_REQUEST';
export const DELETE_API_TOKEN_SUCCESS = 'DELETE_API_TOKEN_SUCCESS';

export const FETCH_API_TOKENS_SUCCESS = 'FETCH_API_TOKENS_SUCCESS';
export const FETCH_API_TOKENS_FAILURE = 'FETCH_API_TOKENS_FAILURE';
export const FETCH_API_TOKENS_REQUEST = 'FETCH_API_TOKENS_REQUEST';

export const MODAL_CREATE_API_TOKEN_CLOSE = 'MODAL_CREATE_API_TOKEN_CLOSE';
export const MODAL_CREATE_API_TOKEN_OPEN = 'MODAL_CREATE_API_TOKEN_OPEN';

export const REGENERATE_API_TOKEN_SUCCESS = 'REGENERATE_API_TOKEN_SUCCESS';
export const REGENERATE_API_TOKEN_FAILURE = 'REGENERATE_API_TOKEN_FAILURE';
export const REGENERATE_API_TOKEN_REQUEST = 'REGENERATE_API_TOKEN_REQUEST';

import { combineReducers } from 'redux';

import addressesByIdReducer from './addressesByIdReducer';
import allAddressesReducer from './allAddressesReducer';
import errorReducer from './errorReducer';
import fetchReducer from './fetchReducer';
import isSubmittingReducer from './isSubmittingReducer';
import lastSubmittedReducer from './lastSubmittedReducer';
import lastUpdatedReducer from './lastUpdatedReducer';

const addressesReducer = combineReducers({
  allIds: allAddressesReducer,
  byId: addressesByIdReducer,
  errors: errorReducer,
  isFetching: fetchReducer,
  isSubmitting: isSubmittingReducer,
  lastSubmitted: lastSubmittedReducer,
  lastUpdated: lastUpdatedReducer,
});

export default addressesReducer;

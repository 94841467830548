import { PartnershipMemberAccessScope } from 'constants/partnershipMember';

import { MemberAccessProps } from 'data/resources/member';

import { NotificationTypeFieldText } from '../constants';

/**
 * Returns the notification type that will be sent, for the given
 * access property (e.g. defaultItem sends a payment notification).
 * @param {MemberAccessProps} accessProperty
 * @return {string}
 */
export const getNotificationTypeTextForAccessProp = (accessProperty) => {
  switch (accessProperty) {
    case MemberAccessProps.defaultItem:
    case MemberAccessProps.accessItem:
      return NotificationTypeFieldText[PartnershipMemberAccessScope.ITEMS];

    default:
      return NotificationTypeFieldText[PartnershipMemberAccessScope.COMPANY_MANAGEMENT];
  }
};

/**
 * Returns the field label for the grouped contact select fields, for the
 * given access property (e.g. defaultItem).
 * @param {MemberAccessProps} accessProperty
 * @return {string}
 */
export const getGroupContactSelectsFieldLabelForAccessProp = (accessProperty) => {
  const notificationTypeText = getNotificationTypeTextForAccessProp(accessProperty);
  return `Who should receive this ${notificationTypeText}?`;
};

import {} from './combobox.component.types';
const regexUUIDVersions = {
    1: /^[0-9A-F]{8}-[0-9A-F]{4}-1[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
    2: /^[0-9A-F]{8}-[0-9A-F]{4}-2[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
    3: /^[0-9A-F]{8}-[0-9A-F]{4}-3[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
    4: /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
    5: /^[0-9A-F]{8}-[0-9A-F]{4}-5[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
};
export const isUUID = (uuidString, checkVersion = 4) => {
    if (typeof uuidString === 'string') {
        if (checkVersion in regexUUIDVersions) {
            const regexPattern = regexUUIDVersions[checkVersion];
            if (regexPattern instanceof RegExp) {
                return regexPattern.test(uuidString);
            }
        }
        else {
            return Object.values(regexUUIDVersions).some((pattern) => {
                if (pattern instanceof RegExp) {
                    return pattern.test(uuidString);
                }
                return false;
            });
        }
    }
    return false;
};
export const isString = (value) => typeof value === 'string';
export const uncapitalize = (text) => {
    if (!isString(text)) {
        return '';
    }
    if (isUUID(text)) {
        return text;
    }
    return text.charAt(0).toLowerCase() + text.slice(1);
};
export const getSelectPlaceholderValue = ({ hideLabel, hidePlaceholder, label, placeholder, }) => {
    if (hidePlaceholder) {
        return '';
    }
    if (placeholder) {
        return placeholder;
    }
    let placeholderValue = `Select`;
    if (label && !hideLabel) {
        placeholderValue += ` ${uncapitalize(label)}`;
    }
    return placeholderValue;
};
export const removeCachedSelectValues = (arr, valueField, labelField) => {
    const itemMap = new Map();
    arr.forEach((item) => {
        const key = `${item[valueField]}-${item[labelField]}`;
        if (!itemMap.has(key)) {
            itemMap.set(key, item);
        }
    });
    const uniqueItems = Array.from(itemMap.values());
    uniqueItems.sort((a, b) => {
        const labelA = String(a[labelField]).toLowerCase();
        const labelB = String(b[labelField]).toLowerCase();
        if (labelA < labelB)
            return -1;
        if (labelA > labelB)
            return 1;
        return 0;
    });
    return uniqueItems;
};
export const fixSelectKey = (key) => {
    return key.replace(/[^a-zA-Z]/g, '');
};

import { FEATURE_FLAG_OCR_INBOX } from 'constants/featureFlags';

import { withFeatureFlag } from 'hoc';

/**
 * Convenience wrapper around WithFeatureFlag, to gate a
 * component's render behind the "ocr inbox" feature flag.
 * Defaults the fallback children to null (overrideable).
 * @param {ComponentProps} props
 * @return {AnyComponent}
 */
const WithFeatureFlagOCRInbox = withFeatureFlag(FEATURE_FLAG_OCR_INBOX);

WithFeatureFlagOCRInbox.displayName = 'WithFeatureFlagOCRInbox';

export default WithFeatureFlagOCRInbox;

import { QueryClient } from '@tanstack/react-query';
import { systemLogger } from '../../logging';
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 3,
            cacheTime: 120_000,
            staleTime: 60_000,
        },
    },
    logger: {
        log: (...args) => {
            systemLogger.log({ level: 'INFO', message: 'QueryClient log', args });
        },
        warn: (...args) => {
            systemLogger.log({ level: 'INFO', message: 'QueryClient warn', args });
        },
        error: (...args) => {
            systemLogger.log({ level: 'INFO', message: 'QueryClient error', args });
        },
    },
});

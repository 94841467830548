import { createErrorReducer } from 'store/redux';

import {
  GET_CURRENT_COMPANY_FAILURE,
  GET_CURRENT_COMPANY_REQUEST,
  GET_CURRENT_COMPANY_SUCCESS,
  UPDATE_CURRENT_COMPANY_FAILURE,
  UPDATE_CURRENT_COMPANY_REQUEST,
  UPDATE_CURRENT_COMPANY_SUCCESS,
} from 'types/currentCompany';

export const failureCases = [GET_CURRENT_COMPANY_FAILURE, UPDATE_CURRENT_COMPANY_FAILURE];
export const successCases = [
  GET_CURRENT_COMPANY_REQUEST,
  GET_CURRENT_COMPANY_SUCCESS,
  UPDATE_CURRENT_COMPANY_REQUEST,
  UPDATE_CURRENT_COMPANY_SUCCESS,
];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

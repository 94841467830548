import { createSelector } from 'reselect';

const getState = (state) => state.oldVersionMode;

/**
 * Determine if the system is currently in oldVersion mode.
 * @param {ReduxState} state - Redux state
 * @returns {boolean} - True/false = on/off
 */
export const oldVersionModeSelector = createSelector([getState], (state) => state.on);

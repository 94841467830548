import React from 'react';
import Spinner from 'components/buttonV2/components/Spinner';
import { IconNames } from 'components/icon';
import { strengthTypes } from './strengthScores';
export const iconByStrength = {
    [strengthTypes.WEAK]: IconNames.SMALL_CROSS,
    [strengthTypes.GOOD]: IconNames.SMALL_TICK,
    [strengthTypes.STRONG]: IconNames.SMALL_TICK,
};
export const iconWhenFetching = React.createElement(Spinner, { useBlueVariant: true });

import React from 'react';

import { BulkActions } from 'context';

/**
 * When you want to get the value from BulkActions, use this simple hook to fetch the context value.
 * @function
 * @returns {Object} - When invoked, returns the value from BulkActions.Provider.
 */
const useBulkActionsContext = () => React.useContext(BulkActions);

export default useBulkActionsContext;

import React from 'react';
import { Text, ButtonV2 } from 'components';
import { ButtonSize } from 'constants/button';
import { TextColor } from 'constants/styles/typography';
import { Intent } from 'constants/ui';
import { externalPaymentsClickContext } from '../../context';
import './Invoices.scss';
const Invoices = ({ value, row: { original: payment } }) => {
    const onClick = React.useContext(externalPaymentsClickContext);
    const showViewButton = payment.type === 'Payment';
    return (React.createElement("div", { className: "invoices" },
        React.createElement(Text.Regular, { className: "circle", color: TextColor.BLUE_BOLD }, value || '--'),
        showViewButton && (React.createElement(ButtonV2, { intent: Intent.NEUTRAL, onClick: () => onClick({ type: 'view-details', payload: payment }), size: ButtonSize.SMALL, type: "button" }, "View"))));
};
export default Invoices;

import { DateFormats } from '@routable/shared';
import dayjs from 'dayjs';

/**
 * Redux Form's format function for date
 * @param {String} value
 * @return {String}
 */
export const formatDate = (value) => {
  if (/^(\d){4}-(\d){2}-(\d){2}$/.test(value)) {
    return dayjs(value, DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY).format(
      DateFormats.FULL_NUMERIC_MONTH_DAY_YEAR_SLASHED,
    );
  }
  return value;
};

/**
 * Redux Form's normalize function for date
 * @param {String} value
 * @return {String}
 */
export const normalizeDate = (value) => {
  if (/^(\d){2}\/(\d){2}\/(\d){4}$/.test(value)) {
    return dayjs(value, DateFormats.FULL_NUMERIC_MONTH_DAY_YEAR_SLASHED).format(
      DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY,
    );
  }
  return value;
};

export const TimeFrames = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  CURRENT_WEEK: 'currentWeek',
  CURRENT_MONTH: 'currentMonth',
  OLDER: 'older',
};

/**
 * @enum {string}
 * @deprecated This enum is deprecated, please use ItemDateScheduledTypes from enums/temporal
 */
export const DateScheduledTypes = {
  TODAY: 'today',
  FUTURE_DATE: 'future_date',
};

export const DateStringLength = {
  BIRTH_DATE: 10,
};

/**
 * Units of time, compatible with those accepted by moment.
 * @type {Object.<string, TimeUnitName>}
 */
export const TimeUnit = {
  MILLISECONDS: 'milliseconds',
  SECONDS: 'seconds',
  MINUTES: 'minutes',
  HOURS: 'hours',
};

/**
 * Style of formatting for time unit strings, e.g. s (short) versus sec (med) versus seconds (long).
 * @type {Object.<TimeUnitStyleName, TimeUnitStyleName>}
 */
export const TimeUnitStyle = {
  SHORT: 'SHORT',
  MED: 'MED',
  LONG: 'LONG',
};

/**
 * Time unit strings for each style.
 * @type {Object.<TimeUnitName, Object<TimeUnitStyle, string>>}
 */
export const TimeUnitsFormatted = {
  milliseconds: {
    [TimeUnitStyle.SHORT]: 'ms',
    [TimeUnitStyle.MED]: 'millis',
    [TimeUnitStyle.LONG]: 'milliseconds',
  },
  seconds: {
    [TimeUnitStyle.SHORT]: 's',
    [TimeUnitStyle.MED]: 'sec',
    [TimeUnitStyle.LONG]: 'seconds',
  },
  minutes: {
    [TimeUnitStyle.SHORT]: 'm',
    [TimeUnitStyle.MED]: 'min',
    [TimeUnitStyle.LONG]: 'minutes',
  },
  hours: {
    [TimeUnitStyle.SHORT]: 'h',
    [TimeUnitStyle.MED]: 'hours',
    [TimeUnitStyle.LONG]: 'hours',
  },
};

/**
 * Time zone identifiers.
 * @type {Object.<string, string>}
 */
export const TimeZoneId = {
  UTC: 'UTC',
  PT: 'America/Los_Angeles',
};

import * as routines from 'actions/routines/currencies';

import { createIsFetchingReducer } from 'store/redux';

export const finishCases = [routines.fetchCurrenciesRoutine.FAILURE, routines.fetchCurrenciesRoutine.SUCCESS];

export const requestCases = [routines.fetchCurrenciesRoutine.REQUEST];

const isFetchingReducer = createIsFetchingReducer(finishCases, requestCases);

export default isFetchingReducer;

import PropTypes from 'prop-types';
import React from 'react';
import { v4 } from 'uuid';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { getFieldErrors } from 'helpers/errors';
import { getPrettyFormFieldName, isErrorValid } from 'helpers/formValidation';

const FormErrorList = ({ errors, formId }) => (
  <ul>
    {Object.keys(errors).map((errorKey) => {
      const currentError = errors[errorKey];
      let errorElement;

      if (isErrorValid(currentError)) {
        errorElement = (
          <li className="margin-top--sm font-regular font-color--greyXDark" key={v4()}>
            {`${getPrettyFormFieldName(errorKey, formId)}: ${getFieldErrors(errors, errorKey)}`}
          </li>
        );
      }

      return errorElement;
    })}
  </ul>
);

FormErrorList.propTypes = {
  errors: PropTypes.shape().isRequired,
  formId: PropTypes.string.isRequired,
};

export default FormErrorList;

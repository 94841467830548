import { createSelector } from 'reselect';

import * as queryHelpers from 'helpers/queryParams';
import { allValues, isNotEqual } from 'helpers/utility';

/**
 * Selects root partnership items state
 * @param {ReduxState} state
 * @returns {Object}
 */
const getState = (state) => state.partnershipItems;

/**
 * Selects partnership items all ids
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Item.id[]}
 */
export const partnershipItemsAllIdsSelector = createSelector(
  [getState],
  (partnershipItemsState) => partnershipItemsState.allIds,
);

/**
 * Selects partnership items byId state property
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object.<Item.id, Item>}
 */
export const partnershipItemsByIdSelector = createSelector(
  [getState],
  (partnershipItemsState) => partnershipItemsState.byId,
);

/**
 * Selects all partnership items
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Item[]} All partnership items
 */
export const allPartnershipItemsSelector = createSelector([partnershipItemsByIdSelector], (partnershipItemsById) =>
  allValues(partnershipItemsById),
);

/**
 * Selects partnership items wasFetched state property
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Boolean} True if partnership items were already fetched, false otherwise
 */
export const werePartnershipItemsFetchedSelector = createSelector(
  [getState],
  (partnershipItemsState) => partnershipItemsState.wasFetched,
);

/**
 * Selects all partnership items, but without the current item in url query param
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Item[]} Actionable partnership items
 */
export const partnershipItemsExceptCurrentOneSelector = createSelector(
  [allPartnershipItemsSelector],
  (partnershipItems) => {
    const itemId = queryHelpers.getQueryParam('item_id');

    if (!itemId) {
      return partnershipItems;
    }

    return partnershipItems.filter((item) => isNotEqual(item.id, itemId));
  },
);

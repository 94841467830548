import { allKeys } from 'helpers/utility';

import { FETCH_INTEGRATIONS_SUCCESS } from 'types/integrations';

const allIntegrationsReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_INTEGRATIONS_SUCCESS:
      return Array.from(new Set([...state, ...allKeys(action.payload.integration)]));

    default:
      return state;
  }
};

export default allIntegrationsReducer;

import React from 'react';
import { Field } from 'redux-form';
import { CompanyAddressDataCard } from 'complexComponents';
import { Checkbox, FlexCol, Text } from 'components';
import { partnerFundingAccountFormFields } from 'constants/formFields';
import { formNamesFunding } from 'constants/forms';
import { typography } from 'constants/styles';
import { getCountryNameFromCode } from 'helpers/countries';
import { isInternationalPartnership } from 'helpers/partnerships';
import { useCountryCodesContext } from 'hooks';
import AddressContainer from 'modules/address/containers/AddressContainer';
const ManuallyAddBankAccountAddressDetails = ({ address, country, partnership, partnerCompanyName, shouldApplyRegisteredAddress, }) => {
    const countryCodesMap = useCountryCodesContext();
    const countryName = getCountryNameFromCode(countryCodesMap, country);
    const isInternational = isInternationalPartnership(partnership);
    const lockedCountryReason = `If you need to modify your country, please reach out to ${partnerCompanyName}`;
    const showApplyRegisteredAddress = Boolean(address) && address?.country === partnership?.countryCodeCompany;
    return (React.createElement("div", { className: "manually-add-bank-account--bank-details margin-top--x-large" },
        React.createElement(FlexCol, null,
            React.createElement(Text.Bold, { color: typography.TextColor.BLUE_DARK, size: typography.TextSize.LEVEL_475 }, "Address details"),
            React.createElement(Text.Regular, null,
                "Bank accounts in",
                ` ${countryName} `,
                "must be added with an associated address for compliance purposes.")),
        showApplyRegisteredAddress && (React.createElement(Field, { className: "margin-top--m-large", component: Checkbox, content: "Apply registered address", name: partnerFundingAccountFormFields.APPLY_REGISTERED_ADDRESS })),
        React.createElement("div", { className: "margin-top--m-large" }, shouldApplyRegisteredAddress ? (React.createElement(CompanyAddressDataCard, { address: address })) : (React.createElement(AddressContainer, { formId: formNamesFunding.ADD_MANUAL_BANK, isInternational: isInternational, lockedCountryReason: lockedCountryReason, stateKey: "partner" })))));
};
export default ManuallyAddBankAccountAddressDetails;

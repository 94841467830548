import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';

import { TABLE_ROW_HEIGHT } from 'constants/tables';

import { isNum } from 'helpers/utility';

import { getLoadMoreButtonText } from '../helpers/text';

const TableLoadMoreFooter = ({ isFetching, isShown, loadMoreNumber, onClick, shouldShowLess }) => {
  if (!isShown) {
    return null;
  }

  const isValidNumber = isNum(loadMoreNumber);
  const isNumberShown = isValidNumber && !shouldShowLess;
  const buttonText = getLoadMoreButtonText({ isValidNumber, shouldShowLess });

  return (
    <div
      className="table-row--container table-footer--load-more disable-hover"
      style={{
        height: TABLE_ROW_HEIGHT,
      }}
    >
      <ButtonV2
        className="load-more--button remove-margin-bottom"
        isLoading={isFetching}
        onClick={onClick}
        type="button"
      >
        {!isFetching && (
          <>
            <span>{buttonText}</span>

            {isNumberShown && <span className="load-more--number">{loadMoreNumber}</span>}
          </>
        )}
      </ButtonV2>
    </div>
  );
};

TableLoadMoreFooter.propTypes = {
  isFetching: PropTypes.bool,
  isShown: PropTypes.bool,
  loadMoreNumber: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  shouldShowLess: PropTypes.bool,
};

TableLoadMoreFooter.defaultProps = {
  isShown: true,
  isFetching: undefined,
  loadMoreNumber: undefined,
  shouldShowLess: undefined,
};

export default TableLoadMoreFooter;

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { InvoiceGeneratorTotalsDiscountPreTax } from '../invoiceGenerator';

/**
 * Renders the invoice generator totals panel field for discount, when discount is accounted
 * for in the subtotal amount.
 * @param {ComponentProps} props
 * @param {string} props.name
 * @returns {StatelessComponent}
 */
const TotalsPanelDiscountIncludes = ({ name, ...rest }) => (
  <Field {...rest} component={InvoiceGeneratorTotalsDiscountPreTax} name={name} text="Includes discount" />
);

TotalsPanelDiscountIncludes.propTypes = {
  name: PropTypes.string.isRequired,
};

export default TotalsPanelDiscountIncludes;

import React from 'react';
import { useDispatch } from 'react-redux';
import {} from 'redux-form';
import { toggleInviteTeamMemberModal } from 'actions/signUpFlow';
import { Modal } from 'components';
import { colors } from 'constants/styles';
import InviteTeamMemberContent from './InviteTeamMemberContent';
import InviteTeamMemberFooter from './InviteTeamMemberFooter';
import {} from './InviteTeamMemberModal.types';
import './InviteTeamMemberModal.scss';
const InviteTeamMemberModal = ({ open, handleSubmit, invalid, }) => {
    const dispatch = useDispatch();
    const onSubmit = (e) => {
        e.preventDefault();
        handleSubmit(e);
    };
    const onCloseModal = () => {
        dispatch(toggleInviteTeamMemberModal());
    };
    return (React.createElement(Modal, { body: React.createElement("form", { onSubmit: onSubmit },
            React.createElement(InviteTeamMemberContent, null),
            React.createElement(InviteTeamMemberFooter, { disabled: invalid, handleClose: onCloseModal })), hasInlineFooter: true, modalHeader: "Invite a team member", modalState: open, onCloseModal: onCloseModal, overrideStyle: {
            content: {
                backgroundColor: colors.colorBlueExtraLight,
            },
        } }));
};
export default InviteTeamMemberModal;

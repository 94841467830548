import { connect } from 'react-redux';

import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

import { externalTaxEDeliveryConsent } from 'selectors/forms';

import ElectronicDeliveryConsentHint from './ElectronicDeliveryConsentHint';

const mapStateToProps = (state, ownProps) => ({
  electronicDeliveryConsent: externalTaxEDeliveryConsent(state, 'electronicDeliveryConsent'),
  partnerCompany: partnershipFromQuerySelector(state, ownProps),
});

export default connect(mapStateToProps)(ElectronicDeliveryConsentHint);

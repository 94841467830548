import PropTypes from 'prop-types';
import React from 'react';

import { FieldEditButton } from 'components/form/FieldEditWrapper/components';
import { ReduxFormFieldRenderInput } from 'components/input';

import withInputEditButton from 'hoc/withInputEditButton';

import AsyncInput from '../AsyncInput';

const AsyncInputWithEditButton = withInputEditButton(AsyncInput, ReduxFormFieldRenderInput);

/**
 * Renders an AsyncInput with an EditButtonInput wrapper.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 * @constructor
 */
const AsyncEditButtonInput = ({ meta, ...rest }) => (
  <AsyncInputWithEditButton isWarn={Boolean(meta.warning)} meta={meta} {...rest} EditButton={FieldEditButton} />
);

AsyncEditButtonInput.propTypes = {
  meta: PropTypes.shape({
    warning: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  }).isRequired,
  // required by interior component, passed in rest props
  onEdit: PropTypes.func.isRequired,
};

export default AsyncEditButtonInput;

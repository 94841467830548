import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { InternationalPhoneInput } from 'components';

import * as propTypes from 'constants/propTypes';
import { field } from 'constants/styles/formStyles';

import { useGetBlockFieldProps } from 'modules/table/hooks';

/**
 * Renders a text input field to be rendered in a block section
 * @param {ComponentProps} props
 * @param {Object} props.formValues
 * @param {string} props.name
 * @param {string} props.placeholder
 * @param {number} props.maxLength
 * @param {Function} props.validate
 * @returns {StatelessComponent}
 */
const PhoneBlockField = ({ formValues, getIsDisabled, getIsRequired, name, placeholder, validate }) => {
  const { isDisabled, isRequired, validators } = useGetBlockFieldProps({
    formValues,
    getIsDisabled,
    getIsRequired,
    validate,
  });

  return (
    <Field
      className={field.xl.full}
      component={InternationalPhoneInput}
      debounceDelay={200}
      isDebounced
      isDisabled={isDisabled}
      isRequired={isRequired}
      name={name}
      placeholder={placeholder}
      validate={validators}
    />
  );
};

PhoneBlockField.propTypes = {
  ...propTypes.baseFieldSharedPropTypes,
  placeholder: PropTypes.string.isRequired,
};

PhoneBlockField.defaultProps = {
  ...propTypes.baseFieldSharedDefaultProps,
};

export default React.memo(PhoneBlockField);

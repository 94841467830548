import { useCallback, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { hashObject } from '../../types/network.types';
export const useSearchParams = (defaultValues, prefix) => {
    const history = useHistory();
    const preface = prefix ? `${prefix}__` : ``;
    const originalDefaults = useRef(defaultValues);
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const [values, setValues] = useState(() => {
        if (preface && searchParams && searchParams.entries) {
            const queryValues = Object.fromEntries([...searchParams.entries()]
                .filter(([key]) => key.includes(preface))
                .map(([key, value]) => [key.replace(preface, ''), value])
                .map(([key, value]) => [key, typeof defaultValues?.[key] === 'number' ? +value : value])
                .map(([key, value]) => [key, typeof defaultValues?.[key] === 'boolean' ? !!value : value]));
            return { ...defaultValues, ...queryValues };
        }
        return defaultValues;
    });
    const update = useCallback((valuesOrFunction, options) => {
        setValues((prev) => {
            const updatedValues = typeof valuesOrFunction === 'function' ? valuesOrFunction(prev) : valuesOrFunction;
            const prevHash = hashObject(prev);
            const newHash = hashObject(updatedValues);
            if (prevHash !== newHash) {
                if (preface) {
                    const params = new URLSearchParams(options?.removeExisting === false ? search : '');
                    Object.entries(updatedValues).forEach(([key, value]) => {
                        if (originalDefaults.current?.[key] === value || value === '') {
                            params.delete(`${preface}${key}`);
                        }
                        else {
                            params.set(`${preface}${key}`, `${value}`);
                        }
                    });
                    history.push({
                        pathname: history.location.pathname,
                        search: `?${params}`,
                    });
                }
                return updatedValues;
            }
            return prev;
        });
    }, [history, preface, search]);
    const merge = useCallback((val) => update((old) => ({ ...old, ...val })), [update]);
    return [values, merge, update];
};

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getQueryParam } from '../../../../../helpers/queryParams';
import { partnershipSelector } from '../../../../../selectors/partnershipsSelectors';
import ExternalPromptHeader from '../../global/presenters/ExternalPromptHeader';

const PromptHeaderReceivablePaid = ({ partnership }) => (
  <ExternalPromptHeader text={`Thanks for your payment to ${partnership.name}!`} />
);

PromptHeaderReceivablePaid.propTypes = {
  partnership: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => {
  const partnershipId = getQueryParam('partnership_id');

  return {
    partnership: partnershipSelector(state, partnershipId),
  };
};

export { PromptHeaderReceivablePaid };
export default connect(mapStateToProps)(PromptHeaderReceivablePaid);

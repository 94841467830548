import { createItemTagLinksRoutine } from 'actions/routines/tags';

import { tagLinkRelationships as relationships } from 'constants/relationships';

import { createByIdReducer } from 'store/redux';

import { FETCH_ITEM_SUCCESS } from 'types/item';

const byIdReducer = createByIdReducer({
  key: 'tagLink',
  types: [FETCH_ITEM_SUCCESS, createItemTagLinksRoutine.SUCCESS],
  relationships,
});

export default byIdReducer;

import PropTypes from 'prop-types';
import React from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';

import { CellRenderer } from 'components/table';
import { dataHelpers } from 'components/tableTypes/SettingsTable/helpers';

import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from 'constants/tables';

import { calculateTableHeight } from 'helpers/tables';
import { mapOverKeys, lengthOf } from 'helpers/utility';

/**
 * Renders the settings table.
 * @param {ComponentProps} props
 * @param {PartnershipMember[]} props.data
 * @param {?Array} props.dataAdditional
 * @param {function} props.onResendInvite
 * @param {function} props.onRevokeInvite
 * @param {Object} props.tableConfig
 * @returns {StatelessComponent}
 */
const SettingsTableRender = ({ data, dataAdditional, onResendInvite, onRevokeInvite, tableConfig }) => {
  const decoratedData = dataHelpers.addActionClickHandlers({
    data,
    dataAdditional,
    onResendInvite,
    onRevokeInvite,
  });

  return (
    <div
      style={{
        height: calculateTableHeight({ dataLength: lengthOf(decoratedData) }),
      }}
    >
      <AutoSizer>
        {({ width, height }) => (
          <Table
            className="table--borders"
            headerClassName="no-background"
            headerHeight={TABLE_HEADER_HEIGHT}
            height={height}
            rowClassName="disable-hover"
            rowCount={lengthOf(decoratedData)}
            rowGetter={({ index }) => decoratedData[index]}
            rowHeight={TABLE_ROW_HEIGHT}
            width={width}
          >
            {mapOverKeys(tableConfig, (column) => (
              <Column
                cellRenderer={CellRenderer}
                className={tableConfig[column].className}
                columnData={tableConfig[column]}
                dataKey={column}
                flexGrow={tableConfig[column].flexGrow}
                key={column}
                label={tableConfig[column].displayName}
                width={tableConfig[column].width || 100}
              />
            ))}
          </Table>
        )}
      </AutoSizer>
    </div>
  );
};

SettingsTableRender.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dataAdditional: PropTypes.arrayOf(PropTypes.shape()),
  onResendInvite: PropTypes.func,
  onRevokeInvite: PropTypes.func,
  tableConfig: PropTypes.shape({
    // This can actually be any cell name with the required props
    // see teamMembersInvitesTableConfig.js and teamMembersListTableConfig.js for reference
    cellName: PropTypes.shape({
      baseClassName: PropTypes.string,
      displayName: PropTypes.string,
      flexGrow: PropTypes.number,
      obj: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]),
      width: PropTypes.number,
    }),
  }).isRequired,
};

SettingsTableRender.defaultProps = {
  dataAdditional: undefined,
  onResendInvite: undefined,
  onRevokeInvite: undefined,
};

export default SettingsTableRender;

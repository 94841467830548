import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const PanelBody = ({ children, className }) => {
  const panelClassNames = classNames({
    'panel-body': true,
    [className]: !!className,
  });

  return <div className={panelClassNames}>{children}</div>;
};

PanelBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

PanelBody.defaultProps = {
  className: null,
};

export default PanelBody;

import { SIGNUP_CHANGE_VIEW, CHANGE_REPRESENTATIVE_LOCK } from 'types/signup';

const navigationReducer = (
  state = {
    // defined by SignUpFlowForm using either query params (?step=review)
    // or localStorage (current user view)
    // or other possible default values
    activeView: undefined,
    representativeIsLocked: true,
  },
  action,
) => {
  if (action?.type === SIGNUP_CHANGE_VIEW) {
    if (state.activeView !== action.payload.view) {
      // SUF TODO: since reducers should not have side effects, we'll
      // remove this from here as soon as we have the react-route added properly
      // in DEV-8648
      window.history.pushState(
        {
          step: action.payload.view,
        },
        action.payload.view,
        `/get-started?step=${action.payload.view}`,
      );
    }
    return {
      ...state,
      activeView: action.payload.view,
    };
  }

  if (action?.type === CHANGE_REPRESENTATIVE_LOCK) {
    return {
      ...state,
      representativeIsLocked: action.payload.isLocked,
    };
  }

  return state;
};

export default navigationReducer;

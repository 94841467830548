import React from 'react';
import { Link } from 'react-router-dom';
import { BoxV2 } from 'components';
import { RESET_PASSWORD } from 'constants/routes';
import { SSOProviders } from 'enums/sso';
import { asPath } from 'helpers/routeHelpers';
import ChangeWorkspace from '../ChangeWorkspace';
import EmailPasswordFormContainer from '../EmailPasswordForm';
import LoginWithGoogleButton from '../LoginWithGoogleButton/LoginWithGoogleButton';
import LoginWithSSOButton from '../LoginWithSSOButton/LoginWithSSOButton';
import './OptionalSSOLoginForm.scss';
import {} from './OptionalSSOLoginForm.types';
const OptionalSSOLoginForm = ({ currentSSOSettings, onSSOLogin, isSSOSubmitting, }) => {
    let buttonComponent;
    if (currentSSOSettings.ssoProvider === SSOProviders.GOOGLE_OAUTH2) {
        buttonComponent = React.createElement(LoginWithGoogleButton, { isLoading: isSSOSubmitting, onClick: onSSOLogin });
    }
    else {
        buttonComponent = React.createElement(LoginWithSSOButton, { isLoading: isSSOSubmitting, onClick: onSSOLogin });
    }
    return (React.createElement(BoxV2, { className: "box-v2--container--login optional-sso-login", subtitle: React.createElement(ChangeWorkspace, null), title: "Log in to your workspace" },
        buttonComponent,
        React.createElement("div", { className: "optional-sso-login--divider" },
            React.createElement("span", { className: "optional-sso-login--divider__text" }, "OR USE EMAIL")),
        React.createElement(EmailPasswordFormContainer, null),
        React.createElement("div", { className: "margin-top--large" },
            React.createElement(Link, { className: "font-sm semibold font-color--steel", to: asPath(RESET_PASSWORD) }, "Forgot your password?"))));
};
export default OptionalSSOLoginForm;

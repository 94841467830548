import PropTypes from 'prop-types';
import React from 'react';

import { UserTypes } from 'constants/user';

import { isCurrentCompanyTypePersonal } from 'helpers/currentCompany';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { EINInput, GenericTINInput, SSNInput } from './components';

/**
 * Display an input to collect EIN or SSN based on companyType and other props.
 * @param {ComponentProps} props
 * @returns {StatelessComponent}
 */
const TINInput = (props) => {
  const { companyType, isSSN, showGenericTIN } = props;
  const companyTypeIsPersonal = isCurrentCompanyTypePersonal({ companyType });

  // sometimes we don't know which TIN type to display, so we show this generic one
  if (showGenericTIN) {
    return <GenericTINInput {...props} />;
  }

  // personal businesses are always SSN
  // companies may use SSN as sole proprietor, which the isSSN flag helps override
  if (companyTypeIsPersonal || isSSN) {
    return <SSNInput {...props} />;
  }

  return <EINInput {...props} />;
};

TINInput.propTypes = {
  companyType: PropTypes.oneOf(Object.values(UserTypes)),
  editField: PropTypes.bool,
  fieldClassNames: PropTypes.string,
  isDisabled: PropTypes.bool,
  isSSN: PropTypes.bool,
  name: PropTypes.string.isRequired,
  showField: PropTypes.bool,
  showGenericTIN: PropTypes.bool,
};

TINInput.defaultProps = {
  companyType: undefined,
  editField: undefined,
  fieldClassNames: undefined,
  isDisabled: undefined,
  isSSN: undefined,
  showField: undefined,
  showGenericTIN: undefined,
};

export default TINInput;

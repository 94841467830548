import PropTypes from 'prop-types';
import React from 'react';

import { getItemStatusProps, getItemStatusText } from 'helpers/status';
import { hasLength } from 'helpers/utility';

import ExternalBlocksSection, { ExternalBlock, ExternalBlockItem } from 'modules/external/ExternalBlocksSection';

import ExternalReceiptAttachments from '../ExternalReceiptAttachments';
import ExternalReceiptItemStatus from '../ExternalReceiptItemStatus';

import { ExternalReceiptHeader, ExternalReceiptItem } from './components';

import './ExternalReceiptBill.scss';

/**
 * Component rendering External Receipt for single item
 * @param {Object} props
 * @param {Attachment[]} props.attachments
 * @param {import('interfaces/item').Item} props.item
 * @param {CountryCode} [props.partnershipCountryCode]
 * @returns {StatelessComponent}
 */
const ExternalReceiptBill = ({ attachments, item, partnershipCountryCode }) => {
  if (!item) {
    return null;
  }
  const { status, statusText, statusVariant } = getItemStatusProps(item);
  const text = getItemStatusText(item, status);

  return (
    <ExternalBlocksSection className="external-receipt-single-item">
      <ExternalReceiptHeader item={item} partnershipCountryCode={partnershipCountryCode} />
      <ExternalReceiptItemStatus status={statusVariant} statusText={statusText} text={text} />
      <ExternalReceiptItem item={item} />
      {hasLength(attachments) && (
        <ExternalBlock>
          <ExternalBlockItem>
            <ExternalReceiptAttachments attachments={attachments} />
          </ExternalBlockItem>
        </ExternalBlock>
      )}
    </ExternalBlocksSection>
  );
};

ExternalReceiptBill.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  item: PropTypes.shape({
    attachments: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  partnershipCountryCode: PropTypes.string,
};

ExternalReceiptBill.defaultProps = {
  item: undefined,
  partnershipCountryCode: undefined,
};

export default ExternalReceiptBill;

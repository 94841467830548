import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ButtonV2, ConfirmationSectionHeader, IconNames } from 'components';

import { ButtonSize } from 'constants/button';
import { sizes } from 'constants/styles';

import { isCurrentCompanyInInviteStage } from 'helpers/currentCompany';
import {
  isPartnershipDefaultReceivableMethodAch,
  isPartnershipDefaultReceivableMethodCheck,
} from 'helpers/partnerships';

import PartnerCompanyPaymentMethodContainer from 'modules/createPartnerCompany/containers/PartnerCompanyPaymentMethodContainer';
import ExternalPromptHeader from 'modules/external/externalPrompt/global/presenters/ExternalPromptHeader';
import ExternalPromptInstructions from 'modules/external/externalPrompt/global/presenters/ExternalPromptInstructions';
import NextSteps from 'modules/external/externalPrompt/global/presenters/NextSteps';
import { achInstructionsVendorRegistered } from 'modules/external/externalUpdatePaymentMethod/containers/constants';

import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

import { currentCompanySelector } from 'selectors/currentCompanySelectors';

class ExternalPromptVendorNoItems extends React.Component {
  renderBodyInvite = () => {
    const { currentCompany, partnership } = this.props;

    if (!isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    const instructions = [
      {
        id: 1,
        key: '1',
        text: `Register as a ${partnership.name} vendor by sharing your business info.`,
      },
      {
        id: 2,
        key: '2',
        text: 'Select a preferred payment method to receive payments.',
      },
      {
        id: 3,
        key: '3',
        text: 'Receive a notification when funds have been sent your way.',
      },
    ];

    return <ExternalPromptInstructions instructions={instructions} isAboveButton />;
  };

  renderBodyInviteExisting = () => {
    const { currentCompany, partnership } = this.props;

    if (isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    if (partnership.defaultReceivableFundingAccount) {
      return null;
    }

    return <PartnerCompanyPaymentMethodContainer />;
  };

  renderBodyConfirmationAch = () => {
    const { currentCompany, partnership } = this.props;

    if (isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    if (!partnership.defaultReceivableFundingAccount) {
      return null;
    }

    if (!isPartnershipDefaultReceivableMethodAch(partnership)) {
      return null;
    }

    return (
      <React.Fragment>
        <NextSteps />
        <ExternalPromptInstructions instructions={achInstructionsVendorRegistered} />
      </React.Fragment>
    );
  };

  renderBodyConfirmationCheck = () => {
    const { currentCompany, partnership } = this.props;

    if (isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    if (!partnership.defaultReceivableFundingAccount) {
      return null;
    }

    if (!isPartnershipDefaultReceivableMethodCheck(partnership)) {
      return null;
    }

    const instructions = [
      {
        id: 1,
        key: '1',
        text: 'You should receive an email shortly confirming your vendor registration.',
      },
      {
        id: 2,
        key: '2',
        text: 'You are set to receive your payments by check.',
      },
      {
        id: 3,
        key: '3',
        text: 'Future payments will be delivered to your selected address.',
      },
    ];

    return (
      <React.Fragment>
        <NextSteps />
        <ExternalPromptInstructions instructions={instructions} />
      </React.Fragment>
    );
  };

  renderButton = () => {
    const { currentCompany, onStart } = this.props;

    if (!isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    return (
      <ButtonV2
        className="external--light"
        leftIconClassName="margin-right--xm"
        leftIconName={IconNames.SHIELD}
        leftIconSize={sizes.iconSizes.LARGE}
        onClick={onStart}
        rightIconName={IconNames.ARROW_RIGHT}
        rightIconProps={{ style: { marginLeft: 'auto' } }}
        rightIconSize={sizes.iconSizes.LARGE}
        size={ButtonSize.LARGE}
      >
        Become our vendor
      </ButtonV2>
    );
  };

  renderHeaderInvite = () => {
    const { currentCompany, partnership } = this.props;

    if (!isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    return <ExternalPromptHeader showRequester text={`Become a ${partnership.name} vendor`} />;
  };

  renderHeaderInviteExisting = () => {
    const { currentCompany, partnership } = this.props;

    if (isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    if (partnership.defaultReceivableFundingAccount) {
      return null;
    }

    return <ExternalPromptHeader showRequester text="Select a secure payment method for receiving payments" />;
  };

  renderHeaderConfirmation = () => {
    const { currentCompany, partnership } = this.props;

    if (isCurrentCompanyInInviteStage(currentCompany)) {
      return null;
    }

    if (!partnership.defaultReceivableFundingAccount) {
      return null;
    }

    return (
      <React.Fragment>
        <ConfirmationSectionHeader text="Confirmed" />
        <ExternalPromptHeader text={`You are set up as a ${partnership.name} vendor`} />
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        {/* Prompt body */}
        {this.renderHeaderInvite()}
        {this.renderHeaderInviteExisting()}
        {this.renderHeaderConfirmation()}

        {/* Prompt body */}
        {this.renderBodyConfirmationAch()}
        {this.renderBodyConfirmationCheck()}
        {this.renderBodyInvite()}
        {this.renderBodyInviteExisting()}

        {/* Prompt button */}
        {this.renderButton()}
      </React.Fragment>
    );
  }
}

ExternalPromptVendorNoItems.propTypes = {
  currentCompany: PropTypes.shape().isRequired,
  onStart: PropTypes.func.isRequired,
  partnership: PropTypes.shape().isRequired,
};

const mapStateToProps = createStructuredSelector({
  currentCompany: currentCompanySelector,
  partnership: partnershipFromQuerySelector,
});

export default connect(mapStateToProps)(ExternalPromptVendorNoItems);

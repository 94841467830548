import PropTypes from 'prop-types';
import React from 'react';

import { FormControl } from 'components/form';
import { textHelpers } from 'components/hintTypes/helpers';
import { LinkNewPage } from 'components/link';
import { WhiteSpace } from 'components/text';

import { Intent } from 'constants/ui';

import { getCustomerOrVendorTextByPartnership } from 'helpers/partnerships';

import BaseHint from '../BaseHint';

/**
 * Hint to display information about companies with no associated contacts.
 * @return {StatelessComponent}
 * @constructor
 */
const CompanyHasNoAddedContactsHint = ({ partnership }) => {
  if (!partnership) {
    return null;
  }

  const partnershipType = getCustomerOrVendorTextByPartnership(partnership);

  return (
    <FormControl>
      <BaseHint
        intent={Intent.WARNING}
        multiline
        text={
          <>
            {textHelpers.getCompanyHasNoAddedContactsHintText(partnershipType)}
            <WhiteSpace />
            <LinkNewPage className="primary" href="url">
              Add a contact
            </LinkNewPage>
            <WhiteSpace />
            to this
            <WhiteSpace />
            {partnershipType}
            <WhiteSpace />
            to send notifications.
          </>
        }
        title={textHelpers.getCompanyHasNoAddedContactsHintTitle(partnership.name)}
      />
    </FormControl>
  );
};

CompanyHasNoAddedContactsHint.propTypes = {
  partnership: PropTypes.shape(),
};

CompanyHasNoAddedContactsHint.defaultProps = {
  partnership: undefined,
};

export default CompanyHasNoAddedContactsHint;

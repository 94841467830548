import {} from 'actions/routines/createApiRoutine';
import { FetchService } from 'services';
import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { getSplitBillEndpoint } from 'services/api/itemEndpoints';
export async function postItemSplitBill(itemId, attachmentId, options = {}) {
    const config = {
        ...options,
        endpoint: getSplitBillEndpoint(itemId),
        method: 'POST',
        payload: payloadToJSONAPI({ id: itemId, attachment: { id: attachmentId } }, 'item', {
            kind: 'ItemAttachment',
            name: 'attachment',
        }),
        requireAuth: true,
        setRequester: true,
    };
    return FetchService.request(config);
}

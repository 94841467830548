import React from 'react';
import { Text } from 'components/text';
import { DateFormats } from 'constants/date';
import { formatDateString } from 'helpers/date';
import {} from './PaymentMethodFundingAccountAddedOnDate.types';
const PaymentMethodFundingAccountAddedOnDate = ({ createdDate, }) => {
    if (!createdDate) {
        return null;
    }
    return (React.createElement(Text.Regular, null, `Added on ${formatDateString(createdDate, DateFormats.SHORT_ALPHA_MONTH_FULL_YEAR)}`));
};
export default PaymentMethodFundingAccountAddedOnDate;

import { changeSaveStatus, closeSubmitForVerificationConfirmation } from 'actions/signUpFlow';

import { PROCESSING } from 'constants/routes';

const submitForVerification = async (formValues, dispatch, props) => {
  const { onSubmitCurrentCompanyReps, representatives, history } = props;
  const payload = {
    agree: Boolean(formValues.agree),
    agree_no_beneficiaries: Boolean(formValues.agree_no_beneficiaries),
    representatives,
  };

  const submissionErrors = await onSubmitCurrentCompanyReps(payload);
  if (!submissionErrors) {
    dispatch(changeSaveStatus.saved());
    dispatch(closeSubmitForVerificationConfirmation());
    history.push(PROCESSING);
  }

  return submissionErrors;
};

export default submitForVerification;

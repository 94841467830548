import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DASHBOARD, SETTINGS_ROUTE } from 'constants/routes';
import { asPath } from 'helpers/routeHelpers';
export const useGoToLastDashboardLocation = () => {
    const history = useHistory();
    const location = useLocation();
    const [lastDashboardLocation, setLastDashboardLocation] = React.useState(null);
    const { state: { previous: previousLocation } = {} } = location;
    React.useEffect(() => {
        if (previousLocation && !previousLocation?.includes(SETTINGS_ROUTE)) {
            setLastDashboardLocation(previousLocation);
        }
    }, [previousLocation]);
    return () => {
        if (lastDashboardLocation) {
            history.push(lastDashboardLocation);
            return;
        }
        history.push(asPath(DASHBOARD));
    };
};
export default useGoToLastDashboardLocation;

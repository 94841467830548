import { PaymentDeliveryOptionType } from '@routable/shared';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isKindPayable } from 'helpers/items';
import { isDateScheduledTypeToday } from 'helpers/temporal';
import { fundingInfoBalanceIdSelector } from 'queries/fundingCompoundSelectors';
import { fundingAccountsByIdSelector } from 'selectors/fundingSelectors';
import { formatValueToCamelCase } from 'services/api/formatHelpers';
import { APPLICABLE_CUTOFF_WARNING_BILLING_CODES } from './constants';
export const useSingleItemCutoffWarning = ({ dateScheduledType, currentBillingData, }) => {
    const [hasPassedCutoffTime, setHasPassedCutoffTime] = useState(false);
    useEffect(() => {
        setHasPassedCutoffTime(false);
        const timer = setInterval(() => {
            const now = dayjs.utc();
            const cutoff = dayjs.utc(currentBillingData?.cutoff, 'HH:mm:ss');
            setHasPassedCutoffTime(cutoff.isSameOrBefore(now));
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [currentBillingData]);
    const isCurrentBillingDataWhitelisted = APPLICABLE_CUTOFF_WARNING_BILLING_CODES.includes(currentBillingData?.paymentDeliveryOption);
    const showAfterCutoffTimeWarning = hasPassedCutoffTime &&
        isDateScheduledTypeToday(dateScheduledType) &&
        !!currentBillingData &&
        isCurrentBillingDataWhitelisted;
    return showAfterCutoffTimeWarning;
};
export const useBulkActionsCutoffWarnings = ({ items, billingDataByCode, dateScheduledType, }) => {
    const fundingAccounts = useSelector(fundingAccountsByIdSelector);
    const balanceId = useSelector(fundingInfoBalanceIdSelector);
    const hasPayables = items.some(({ kind }) => isKindPayable(kind));
    if (!isDateScheduledTypeToday(dateScheduledType) || !hasPayables) {
        return {
            billingData: {},
            cutoffData: { sameDayCount: 0, nextDayCount: 0 },
        };
    }
    const accumulatedBillingCodes = Object.values(billingDataByCode).reduce((acc, billingCode) => {
        const { paymentDeliveryOption, paymentSource } = billingCode;
        if (APPLICABLE_CUTOFF_WARNING_BILLING_CODES.includes(paymentDeliveryOption)) {
            const snake = `${paymentDeliveryOption}_${paymentSource}`;
            const key = formatValueToCamelCase(snake);
            acc[key] = billingCode;
        }
        return acc;
    }, {});
    const { achSameDayBalance: sameDayBalance, achSameDayBank: sameDayBank, achNextDayBalance: nextDayBalance, achNextDayBank: nextDayBank, } = accumulatedBillingCodes;
    const counts = {
        [PaymentDeliveryOptionType.ACH_SAME_DAY]: 0,
        [PaymentDeliveryOptionType.ACH_NEXT_DAY]: 0,
    };
    items.forEach((item) => {
        if (APPLICABLE_CUTOFF_WARNING_BILLING_CODES.includes(item.paymentDeliveryOption)) {
            const fundingAccount = fundingAccounts[item.fundingAccount];
            const isBalance = fundingAccount?.balance === balanceId;
            const cutoffTimes = {
                [PaymentDeliveryOptionType.ACH_SAME_DAY]: isBalance ? sameDayBalance.cutoff : sameDayBank.cutoff,
                [PaymentDeliveryOptionType.ACH_NEXT_DAY]: isBalance ? nextDayBalance.cutoff : nextDayBank.cutoff,
            };
            const cutoff = cutoffTimes[item.paymentDeliveryOption];
            const now = dayjs.utc();
            const cutoffTime = dayjs.utc(cutoff, 'HH:mm:ss');
            if (cutoffTime.isSameOrBefore(now)) {
                counts[item.paymentDeliveryOption] += 1;
            }
        }
    });
    return {
        billingData: { sameDayBalance, sameDayBank, nextDayBalance, nextDayBank },
        cutoffData: {
            sameDayCount: counts[PaymentDeliveryOptionType.ACH_SAME_DAY],
            nextDayCount: counts[PaymentDeliveryOptionType.ACH_NEXT_DAY],
        },
    };
};

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { getCurrentCompanyRequest } from 'actions/currentCompany';
import { fetchSingleMembershipRequest } from 'actions/memberships';

import { formNamesSignup } from 'constants/forms';

import { onSubmitFailReduxForm } from 'helpers/errors';

import { isSubmittingConvertExternalToRegisteredSelector } from 'selectors/authSelectors';
import { membershipIdQuerySelector } from 'selectors/routerSelectors';

import ConvertExternal from './ConvertExternal';
import { getConvertExternalFormInitialValues } from './helpers/initialValues';

const mapStateToProps = createStructuredSelector({
  initialValues: getConvertExternalFormInitialValues,
  isSubmitting: isSubmittingConvertExternalToRegisteredSelector,
  membershipId: membershipIdQuerySelector,
});

const mapDispatchToProps = {
  onFetchCurrentCompany: getCurrentCompanyRequest,
  onFetchMembership: fetchSingleMembershipRequest,
};

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));

const reduxFormWrapperConvertExternalForm = reduxForm({
  form: formNamesSignup.CONVERT_EXTERNAL,
  onSubmitFail: onSubmitFailReduxForm,
})(ConvertExternal);

export default enhance(reduxFormWrapperConvertExternalForm);

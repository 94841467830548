import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { isExternalPathAccept } from 'helpers/external';
import { getFundingAccountMask } from 'helpers/funding';
import { isItemPaymentDeliveryMethodAch, isItemPaymentDeliveryMethodCheck } from 'helpers/items';

import { fundingInfoBankAccountForIdSelector } from 'selectors/fundingSelectors';

import ExternalReceiptRow from './ExternalReceiptRow';

class ExternalReceiptPaymentMethod extends React.Component {
  constructor(props) {
    super(props);

    this.itemPaymentMethodRowDescription = 'Payment method';
    this.itemActionVerb = isExternalPathAccept(this.props.location) ? 'Accept' : 'Pay';
  }

  renderItemReceiverBankAccount = () => {
    if (!this.props.fundingInfoBankAccount) {
      return null;
    }

    const mask = getFundingAccountMask(this.props.fundingInfoBankAccount.accountNumber);
    return <ExternalReceiptRow description="Bank account" text={`Ending in ${mask}`} />;
  };

  renderItemReceiverBank = () => {
    if (!this.props.fundingInfoBankAccount) {
      return null;
    }

    return <ExternalReceiptRow description="Bank" text={this.props.fundingInfoBankAccount.institutionName} />;
  };

  renderAchPaymentMethod = () => {
    if (!isItemPaymentDeliveryMethodAch(this.props.item)) {
      return null;
    }

    return (
      <div className="panel-body external-receipt">
        <ExternalReceiptRow
          description={this.itemPaymentMethodRowDescription}
          text={`${this.itemActionVerb} via Bank Transfer`}
        />
        {this.renderItemReceiverBankAccount()}
        {this.renderItemReceiverBank()}
      </div>
    );
  };

  renderCheckPaymentMethod = () => {
    if (!isItemPaymentDeliveryMethodCheck(this.props.item)) {
      return null;
    }

    return (
      <div className="panel-body external-receipt">
        <ExternalReceiptRow
          description={this.itemPaymentMethodRowDescription}
          text={`${this.itemActionVerb} a Check`}
        />
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderAchPaymentMethod()}
        {this.renderCheckPaymentMethod()}
      </div>
    );
  }
}

ExternalReceiptPaymentMethod.propTypes = {
  fundingInfoBankAccount: PropTypes.shape(),
  item: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
};

ExternalReceiptPaymentMethod.defaultProps = {
  fundingInfoBankAccount: null,
};

const mapStateToProps = (state, props) => {
  const retProps = {};

  if (props.fundingAccount) {
    retProps.fundingInfoBankAccount = fundingInfoBankAccountForIdSelector(state, props.fundingAccount.bank);
  }

  return retProps;
};

export default connect(mapStateToProps)(ExternalReceiptPaymentMethod);

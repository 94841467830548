import React from 'react';

import { universalRenderPropType } from 'helpers/propTypes';
import { isEqual, isUndef } from 'helpers/utility';

import If from '../If';
import UniversalChildren from '../UniversalChildren';

/**
 * Switch case conditional render component.
 * Each child's `condition` prop is evaluated in order of render.
 * The last child whose condition prop is undefined will be used as a fallback.
 *
 * @example
 * <Choose>
 *   <Choose.When condition={0}>{childrenThatWillNotRender}</Choose.When>
 *   <Choose.When condition={1}>{childrenThatWillRender}</Choose.When>
 *   <Choose.Otherwise>{() => 'fallback, as a render prop'}</Choose.Otherwise>
 * </Choose>
 *
 * @param {ComponentProps} props
 * @param {*} props.children
 * @return {*}
 */
const Choose = (props) => {
  let when = null;
  let otherwise = null;

  React.Children.forEach(props.children, (children) => {
    if (isUndef(children.props.condition)) {
      otherwise = children;
    } else if (!when && isEqual(children.props.condition, true)) {
      when = children;
    }
  });

  return when || otherwise;
};

Choose.propTypes = {
  children: universalRenderPropType,
};

/**
 * Primarily for legibility of code.
 * In pseudo code, `Choose.When props.condition is truthy`.
 * Alias for `If`, and thus can be used with the `strict` prop.
 * @type {StatelessComponent}
 */
Choose.When = If;

/**
 * Primarily for legibility of code.
 * In pseudo code, `Choose.Otherwise when no other props.condition is truthy`.
 * @type {StatelessComponent}
 */
Choose.Otherwise = UniversalChildren;

export default Choose;

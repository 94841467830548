import { formatCurrencyWithCursor } from '@routable/gross-ds';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
const CurrencyWrap = styled.div `
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  font-size: var(--font-size-1);
  gap: var(--spacing-2);

  & > input {
    height: unset;

    ::placeholder {
      color: var(--color-grey60);
    }
  }
  & > .currency-code {
    color: var(--color-grey60);
  }
`;
const CurrencyCode = styled.div `
  text-align: left;
`;
const getLocaleFromCurrency = (currency) => {
    if (currency != 'USD') {
        console.warn(`getLocaleFromCurrency(): does not support currencyCode of "${currency}", defaults to en-US`);
    }
    return 'en-US';
};
export const CurrencyInput = ({ currencyCode = 'USD', decimalScale, disabled, hidePlaceholder, name, onChange, required, value, }) => {
    const [cleanValue, setCleanValue] = useState(value);
    const [isFocused, setFocused] = useState(false);
    const locale = getLocaleFromCurrency(currencyCode);
    const innerRef = useRef(null);
    const cleanFormatter = useMemo(() => new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode,
    }), [currencyCode]);
    const maxDecimals = useMemo(() => decimalScale || cleanFormatter.resolvedOptions().maximumFractionDigits || 0, [decimalScale, cleanFormatter]);
    const formatInputValue = useCallback((inputValue) => {
        const input = innerRef.current;
        if (!input) {
            return;
        }
        if (inputValue === '') {
            input.value = inputValue;
            setCleanValue(inputValue);
            return;
        }
        const decimal = cleanFormatter.formatToParts(1000.1).find((part) => part.type === 'decimal')?.value || '.';
        const group = cleanFormatter.formatToParts(1000.1).find((part) => part.type === 'group')?.value || ',';
        const onlyGroupAndDecimal = new RegExp(`[^0-9\\${decimal}${group}-]`, 'g');
        const preVal = String(inputValue).replaceAll(onlyGroupAndDecimal, '');
        const hadDecimal = preVal.includes(decimal) ? decimal : '';
        const [ints, decimals] = preVal.split(decimal);
        const newVal = decimals ? `${ints}.${decimals.substring(0, maxDecimals)}` : `${ints}${hadDecimal}`;
        input.value = newVal;
        setCleanValue(newVal);
    }, [currencyCode]);
    const formatInputValueOnBlur = useCallback((inputValue) => {
        const input = innerRef.current;
        if (!input) {
            return {};
        }
        if (inputValue !== 0 && !inputValue) {
            return { clean: '' };
        }
        const cleanedValue = `${inputValue || input.value}`;
        const formatter = formatCurrencyWithCursor(locale, currencyCode, false, maxDecimals);
        const [formatted, _position, clean] = formatter(cleanedValue, input.selectionStart ?? cleanedValue.length);
        input.value = formatted;
        setCleanValue(clean);
        return { clean };
    }, [locale, currencyCode]);
    const formatInput = (e) => formatInputValue(e.target.value);
    const handleBlur = () => {
        setFocused(false);
        const { clean } = formatInputValueOnBlur(cleanValue);
        onChange(clean);
    };
    const handleFocus = () => {
        setFocused(true);
        const input = innerRef.current;
        if (!input) {
            return;
        }
        if (parseFloat(input.value) === 0) {
            input.value = '';
        }
    };
    useEffect(() => {
        if (value !== undefined) {
            const { clean } = formatInputValueOnBlur(value);
            if (!clean && innerRef?.current) {
                innerRef.current.value = '';
            }
            if (clean !== value) {
                onChange(clean);
            }
        }
    }, [value, formatInputValueOnBlur]);
    const showPlaceholder = !hidePlaceholder && !isFocused;
    const showCurrencyCode = showPlaceholder || (!!value && !isFocused);
    return (React.createElement(CurrencyWrap, null,
        React.createElement("input", { className: clsx({
                'text-right': !isFocused,
                'text-left': isFocused,
            }), id: name, name: name, "aria-label": name, onInput: formatInput, onBlur: handleBlur, onFocus: handleFocus, placeholder: showPlaceholder ? '0.00' : '', type: "text", ref: innerRef, disabled: disabled, required: required }),
        showCurrencyCode && (React.createElement(CurrencyCode, { className: "currency-code align-middle leading-none", onClick: () => innerRef.current?.focus() }, currencyCode)),
        showPlaceholder && required && !disabled && React.createElement("span", { className: "text-blue-40 pl-1" }, "*")));
};

import React from 'react';

import { sizes } from 'constants/styles';
import { ZIndexLayers } from 'constants/ui';

import { isGreaterThanZero, lengthOf } from 'helpers/utility';

import SummaryOfContactsTooltipContent from '../components';

/**
 * Returns props for summary of contacts tooltip or null, if there are no
 * hidden contacts
 * @param {PartnershipMember[]} hiddenPartnershipMembers
 * @return {ObjectMaybe} Tooltip props or null
 */
export const getSummaryOfContactsTooltipProps = (hiddenPartnershipMembers) => {
  // We only want to pass props to the tooltip conditional wrapper if
  // "hiddenPartnershipMembers" array is not empty (e.i. more than one partnership member,
  // which means that some members are hidden and need to be shown in the tooltip).
  if (!isGreaterThanZero(lengthOf(hiddenPartnershipMembers))) {
    // We return null if array is empty, which will result in tooltip not being rendered
    return null;
  }

  // We return tooltip props if hiddenPartnershipMembers array is not empty
  return {
    content: <SummaryOfContactsTooltipContent partnershipMembers={hiddenPartnershipMembers} />,
    contentMargin: sizes.spacing.MEDIUM,
    tooltipProps: {
      statelessProps: { maxWidth: 'unset' },
    },
    zIndex: ZIndexLayers.TOOLTIP,
  };
};

import { lookupPartnershipEmailsRoutine, submitCreatePartnershipRoutine } from 'actions/routines/partnership';
import {
  createPartnershipMemberRoutine,
  removeFormPartnershipMember,
  setFormPartnershipMembers,
  updatePartnershipMemberRoutine,
} from 'actions/routines/partnershipMember';

import { parseFormPartnershipMember } from 'data/parse/partnershipMember';
import { MemberAccessProps } from 'data/resources/member';

import { derivePartnershipMemberStatusFromAttributes } from 'helpers/partnershipMembers';
import { getNextStateForEmailOnValidateFailed, getNextStateForEmailOnValidateSuccess } from 'helpers/reducer';

export const parserOptions = {
  accessProperty: MemberAccessProps.defaultGeneral,
  getAttributes: (currentState, objects, objectId) => ({
    status: derivePartnershipMemberStatusFromAttributes(objects[objectId]),
  }),
};

const createPartnershipFormReducer = (state = undefined, action) => {
  if (!state?.values) {
    return state;
  }

  switch (action.type) {
    case createPartnershipMemberRoutine.SUCCESS:
    case updatePartnershipMemberRoutine.SUCCESS:
      return parseFormPartnershipMember.getNextFormStateForAppendSinglePartnershipMember(state, action, parserOptions);

    case removeFormPartnershipMember.TRIGGER:
      return parseFormPartnershipMember.getNextFormStateForRemoveFormPartnershipMember(state, action);

    case setFormPartnershipMembers.TRIGGER:
      return parseFormPartnershipMember.getNextFormStateForSetFormPartnershipMembers(state, action);

    case submitCreatePartnershipRoutine.SUCCESS:
      return parseFormPartnershipMember.getNextFormStateForCreateOrFetchPartnership(state, action, parserOptions);

    case lookupPartnershipEmailsRoutine.FAILURE:
      return getNextStateForEmailOnValidateFailed(state, action);

    case lookupPartnershipEmailsRoutine.SUCCESS:
      return getNextStateForEmailOnValidateSuccess(state, action);

    default:
      return state;
  }
};

export default createPartnershipFormReducer;

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { Form, FormControl } from 'components/form';

import { externalCompanyGeneralFields } from 'constants/formFields';
import { formNamesExternal } from 'constants/forms';

import { requiredValidator } from 'helpers/fieldValidation';

import { TermsOfServiceContentText } from './components';

/**
 * Returns message letting user know that by chosing to continue with external flow
 * they automatically agree with our Terms of Service. No checkbox is displayed.
 * @param {ComponentProps} props
 * @param {function} props.handleSubmit
 * @param {function} props.onTosSubmit
 * @returns {StatelessComponent}
 */
const TermsOfServiceHint = ({ handleSubmit, onTosSubmit }) => (
  <Form id={formNamesExternal.TERM_OF_SERVICE} onSubmit={handleSubmit(onTosSubmit)}>
    <FormControl>
      <Field
        component={TermsOfServiceContentText}
        isRequired
        name={externalCompanyGeneralFields.COMPANY_META_TOS_AGREE}
        type="checkbox"
        validate={requiredValidator}
      />
    </FormControl>
  </Form>
);

TermsOfServiceHint.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onTosSubmit: PropTypes.func.isRequired,
};

export default TermsOfServiceHint;

import React from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { BrandWrapperV2, ProgressBar } from 'components';
import { SIGNUP_CONNECT_BANK, SIGNUP_CREATE_ACCOUNT, SIGNUP_CREATE_NAMESPACE, SIGNUP_VERIFY_EMAIL, } from 'constants/routes';
import { getCompanyNamespaceFromUrl, getDomainWithOrWithoutCompanyNamespace } from 'helpers/urls';
import Authenticated from 'modules/app/AuthenticatedRoute';
import { Help } from 'modules/app/components';
import NotAuthenticated from 'modules/app/NotAuthenticatedRoute';
import SignupConnectBankContainer from 'modules/signup/connectBank/containers/SignupConnectBankContainer';
import CreateAccount from 'modules/signup/CreateAccount';
import SelectWorkspace from 'modules/signup/SelectWorkspace';
import VerifyEmail from 'modules/signup/VerifyEmail';
import { signupFlowSteps } from './constants/progressBar';
import { getCurrentStepPositionFromPath } from './helpers/progressBar';
const SignupV2 = () => {
    const location = useLocation();
    React.useEffect(() => {
        if (getCompanyNamespaceFromUrl()) {
            window.location.assign(`${getDomainWithOrWithoutCompanyNamespace()}${SIGNUP_CREATE_ACCOUNT}`);
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Help, null),
        React.createElement(BrandWrapperV2, { className: "has--progress-bar", showFooter: false },
            React.createElement(Switch, null,
                React.createElement(NotAuthenticated, { component: CreateAccount, exact: true, path: SIGNUP_CREATE_ACCOUNT }),
                React.createElement(Authenticated, { component: VerifyEmail, exact: true, path: SIGNUP_VERIFY_EMAIL }),
                React.createElement(Authenticated, { component: SelectWorkspace, exact: true, path: SIGNUP_CREATE_NAMESPACE }),
                React.createElement(Authenticated, { component: SignupConnectBankContainer, exact: true, path: SIGNUP_CONNECT_BANK })),
            React.createElement(ProgressBar, { currentStepPosition: getCurrentStepPositionFromPath(location.pathname), showNum: false, steps: signupFlowSteps }))));
};
export default SignupV2;

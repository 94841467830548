import React from 'react';
import { useSearchParam } from 'react-use';
import { ItemFilterUrlParams } from 'enums/itemFilters';
import { isItemKindPayable } from 'helpers/items';
import PartnershipPaymentItems from '../PartnershipPaymentItems';
import PartnershipRequestItems from '../PartnershipRequestItems';
import {} from './ExternalItemsListWrapper.types';
const ExternalItemsListWrapper = ({ fetchPartnershipItems, itemsKind, partnershipItems, wasFetched, }) => {
    const paymentId = useSearchParam(ItemFilterUrlParams.FILTER_PAYMENT_ID_URL_PARAM);
    const handleFetchPartnershipItems = React.useCallback(() => {
        if (!wasFetched) {
            fetchPartnershipItems();
        }
    }, [fetchPartnershipItems, wasFetched]);
    React.useEffect(() => {
        if (!paymentId) {
            handleFetchPartnershipItems();
        }
    }, [handleFetchPartnershipItems, paymentId]);
    if (isItemKindPayable({ kind: itemsKind })) {
        return React.createElement(PartnershipPaymentItems, { partnershipItems: partnershipItems });
    }
    return React.createElement(PartnershipRequestItems, { partnershipItems: partnershipItems });
};
export default ExternalItemsListWrapper;

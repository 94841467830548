import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { ApplyToFuturePendingPaymentsCheckbox } from 'components';

import { updatePaymentMethodFormFields } from 'constants/formFields';

import { isPathExternalV2AcceptPartnershipOrItem } from 'helpers/external';
import { isFundingAccountValid } from 'helpers/funding';

/**
 * Field for ApplyToFuturePendingPaymentsCheckbox component
 * @param {ComponentProps} props
 * @param {FundingAccount} [props.fundingAccount]
 * @param {Partnership} props.partnership
 * @returns {StatelessComponent}
 */
const ApplyToFuturePendingPaymentsCheckboxField = ({ fundingAccount, partnership }) => {
  const isInvalidFundingAccount = !isFundingAccountValid(fundingAccount);
  const isCustomerAcceptingPatnershipOrItem = isPathExternalV2AcceptPartnershipOrItem();

  if (isCustomerAcceptingPatnershipOrItem || isInvalidFundingAccount) {
    return null;
  }

  const applyToFuturePendingPaymentsCheckboxProps = {
    companyName: partnership.name,
    currencyCode: fundingAccount.currencyCode,
  };

  return (
    <Field
      className="accept-future-pending-payments-checkbox"
      component={ApplyToFuturePendingPaymentsCheckbox}
      isRequired={false}
      name={updatePaymentMethodFormFields.ACCEPT_FUTURE_PENDING_PAYMENTS}
      props={applyToFuturePendingPaymentsCheckboxProps}
      type="checkbox"
    />
  );
};

ApplyToFuturePendingPaymentsCheckboxField.propTypes = {
  fundingAccount: PropTypes.shape({
    isValid: PropTypes.bool.isRequired,
  }),
  partnership: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

ApplyToFuturePendingPaymentsCheckboxField.defaultProps = {
  fundingAccount: undefined,
};

export default ApplyToFuturePendingPaymentsCheckboxField;

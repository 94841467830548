import styled, { css } from 'styled-components';
const heightTransition = css `
  transition: height 0.3s;
`;
export const Container = styled.div `
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
`;
export const TopPanel = styled.div `
  display: block;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--color-neutral-white);
  ${heightTransition}
`;
export const BottomPanel = styled.div `
  display: block;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--color-neutral-white);
  ${heightTransition}
`;
export const VerticalBar = styled.div `
  width: 100%;
  flex-direction: column;

  cursor: ns-resize;
  position: relative;
  z-index: 100;
  display: flex;

  .resizable-handle svg {
    fill: var(--color-grey60);
    transition: fill 00.2s;
  }

  &:hover {
    & .resize-v-bar-middle {
      background-color: var(--color-grey20);
      box-shadow: rgba(60, 101, 241, 0.2) 0px 4px 8px 1px;
    }

    .resizable-handle svg {
      fill: var(--color-blue40);
    }
  }

  // The bar is resizing so handle the grip as well.
  &.resizing-panels .resizable-handle > button {
    cursor: grabbing;
  }

  &.hidden {
    display: none;
  }
`;
export const VerticalBarTop = styled.div.attrs({
    className: 'resize-v-bar-top',
}) `
  position: absolute;
  top: -18px;
  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  height: 18px;
`;
export const VerticalBarMiddle = styled.div.attrs({
    className: 'resize-v-bar-middle',
}) `
  height: 1px;
  background-color: var(--color-grey20);
  transition: background-color 0.2s;
  flex: 0 0 1px;
`;
export const VerticalBarBottom = styled.div.attrs({
    className: 'resize-v-bar-bottom',
}) `
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  width: 100%;
  position: absolute;
  height: 10px;
`;
export const DragIndicator = styled.div `
  width: 30px;
  height: 16px;
  padding: 1px 8px 1px 8px;
  border-radius: 4px;
  background-color: var(--color-neutral-white);
  border: 1px solid var(--color-grey20);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  & button {
    position: absolute;
    left: 6px;
    top: 0px;
    transform: rotate(90deg);
  }
  &.hide-drag {
    display: none;
  }
`;

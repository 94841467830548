import { CompanySearchPartnerTypes } from 'constants/company';

import { getMembershipFullName } from 'helpers/memberships';
import { isPartnershipMemberNotArchived, isPartnershipMemberFromPartnershipPartner } from 'helpers/partnershipMembers';
import { createPartnershipSelectName } from 'helpers/partnerships';

/**
 * Add companyName and contacts for display purposes on SearchCompaniesContainer
 * @param {object} partnershipData - denormalized response of a fetch partnership call
 * @return {object}
 */
export const flattenDataForSearchCompanies = (partnershipData) => {
  if (!partnershipData.partner) {
    return partnershipData;
  }

  const augmentedPartnershipData = { ...partnershipData };

  // Add companyName
  augmentedPartnershipData.companyName = createPartnershipSelectName(partnershipData);

  // Add contacts
  if (partnershipData.partnershipMembers) {
    augmentedPartnershipData.contacts = partnershipData.partnershipMembers
      .filter(isPartnershipMemberNotArchived)
      .filter((member) => isPartnershipMemberFromPartnershipPartner(member, partnershipData))
      .map((member) => ({
        id: member.id,
        email: member.email,
        firstName: member.firstName,
        lastName: member.lastName,
        text: `${getMembershipFullName(member)} (${member.email})`.trim(),
        value: member.id,
      }));
  }

  return augmentedPartnershipData;
};

/**
 * Checks if partner selected in search is partnerType 'existing'
 * @param {string} partnerType
 * @return {boolean}
 */
export const isSelectedPartnerTypeExisting = (partnerType) => partnerType === CompanySearchPartnerTypes.EXISTING;

/**
 * Checks if partner selected in search is partnerType 'new'
 * @param {string} partnerType
 * @return {boolean}
 */
export const isSelectedPartnerTypeNew = (partnerType) => partnerType === CompanySearchPartnerTypes.NEW;

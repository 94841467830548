export const strengthTypes = {
    WEAK: 'weak',
    GOOD: 'good',
    STRONG: 'strong',
};
export const strengthScores = {
    0: strengthTypes.WEAK,
    1: strengthTypes.WEAK,
    2: strengthTypes.WEAK,
    3: strengthTypes.GOOD,
    4: strengthTypes.STRONG,
};
export const strengthNeedsFeedback = {
    [strengthTypes.WEAK]: true,
    [strengthTypes.GOOD]: false,
    [strengthTypes.STRONG]: false,
};

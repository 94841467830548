import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getQueryParam } from 'helpers/queryParams';

import { getDepartmentByPath } from 'modules/external/helpers';

import { partnershipPartnerCompanySelector } from 'queries/partnershipCompoundSelectors';

import { companyBrandLogoSelector } from 'selectors/brandSelectors';

import './ExternalHeaderPartnerInfo.scss';

const ExternalHeaderPartnerInfo = ({ logo, partner: { name } }) => (
  <div className="external-header--partner-info">
    {logo ? (
      <img alt={name} className="partner-info--logo" src={logo} />
    ) : (
      <h3 className="partner-info--name">{name}</h3>
    )}
    <span className="department--divider">|</span>
    <span className="department--name">{`${getDepartmentByPath()} department`}</span>
  </div>
);

ExternalHeaderPartnerInfo.propTypes = {
  logo: PropTypes.string,
  partner: PropTypes.shape().isRequired,
};

ExternalHeaderPartnerInfo.defaultProps = {
  logo: undefined,
};

const mapStateToProps = (state) => {
  const partnershipId = getQueryParam('partnership_id');
  const partner = partnershipPartnerCompanySelector(state, partnershipId);
  const retProps = {
    partner,
  };

  if (partner.brand) {
    retProps.logo = companyBrandLogoSelector(state, partner.id);
  }

  return retProps;
};

export { ExternalHeaderPartnerInfo };
export default connect(mapStateToProps)(ExternalHeaderPartnerInfo);

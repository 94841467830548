import PropTypes from 'prop-types';
import React from 'react';

import { typography } from 'constants/styles';

import { formatItemAmount } from 'helpers/items';

import { useCurrencyContext } from 'hooks';

import { ExternalBlockItem } from 'modules/external/ExternalBlocksSection';

import { ExternalReceiptPriceRow } from './components';

/**
 * Component rendering External Receipt Single Item price details in the
 * expanded header
 * @param {ComponentProps} props
 * @param {Item} props.item
 * @returns {StatelessComponent}
 */
const ExternalReceiptPriceDetails = ({ item }) => {
  const currencyCodeMap = useCurrencyContext();

  return (
    <ExternalBlockItem isDark>
      <ExternalReceiptPriceRow
        color={typography.TextColor.BLACK}
        isBold
        label="Total"
        size={typography.TextSize.LEVEL_200}
        tooltipProps={{
          title: 'This total amount includes cost, tax, adjustments, and discounts if any are applied.',
        }}
        value={formatItemAmount(currencyCodeMap, item)}
      />
    </ExternalBlockItem>
  );
};

ExternalReceiptPriceDetails.propTypes = {
  item: PropTypes.shape({
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currencyCode: PropTypes.string,
  }).isRequired,
};

export default ExternalReceiptPriceDetails;

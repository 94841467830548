import { combineReducers } from 'redux';

import allCurrenciesReducer from './allCurrenciesReducer';
import byCurrencyReducer from './byCurrencyReducer';
import errorsReducer from './errorsReducer';
import isFetchingReducer from './isFetchingReducer';
import lastFetchedReducer from './lastFetchedReducer';

const currenciesRootReducer = combineReducers({
  allIds: allCurrenciesReducer,
  byId: byCurrencyReducer,
  errors: errorsReducer,
  isFetching: isFetchingReducer,
  lastFetched: lastFetchedReducer,
});

export default currenciesRootReducer;

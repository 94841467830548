import { createErrorReducer } from 'store/redux';

import {
  FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_FAILURE,
  FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_REQUEST,
  FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_SUCCESS,
  SEARCH_FUNDING_ACCOUNTS_FAILURE,
  SEARCH_FUNDING_ACCOUNTS_REQUEST,
  SEARCH_FUNDING_ACCOUNTS_SUCCESS,
} from 'types/integrations';

export const failureCases = [FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_FAILURE, SEARCH_FUNDING_ACCOUNTS_FAILURE];
export const successCases = [
  FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_REQUEST,
  FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_SUCCESS,
  SEARCH_FUNDING_ACCOUNTS_REQUEST,
  SEARCH_FUNDING_ACCOUNTS_SUCCESS,
];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

import { z } from 'zod';
import { approverModel, ApproverStatus } from './approver.model';
import { valuesModel } from './common.model';
export const operatorKindEnum = z.enum(['any', 'gte', 'lt', 'between']);
const betweenModel = z.object({
    between: z.number().or(z.literal('')).array(),
});
const gteModel = z.object({
    gte: z.number().or(z.literal('')),
});
const ltModel = z.object({
    lt: z.number().or(z.literal('')),
});
export const operatorModel = z.object({
    gte: z.number().or(z.string()).optional(),
    lt: z.number().or(z.string()).optional(),
    between: z.array(z.number().or(z.string())).length(2).optional(),
});
export const approvalTypeEnum = z.enum(['all', 'any']);
export const levelModel = z.object({
    approvers: valuesModel,
    expression: operatorModel,
    ordinal: z.number(),
    type: approvalTypeEnum.optional(),
});
export const operatorTypeEnum = z.enum(['between', 'equals', 'gte', 'lt']);
export const fieldTypeEnum = z.enum(['account', 'creator', 'customer', 'department', 'item', 'vendor']);
export const matcherModel = z.discriminatedUnion('field', [
    z.object({
        field: z.literal('amount'),
        label: z.string(),
        operator: operatorTypeEnum,
        values: z.string().or(z.string().array()),
    }),
    z.object({
        field: fieldTypeEnum,
        label: z.string(),
        operator: operatorTypeEnum,
        values: z.object({ id: z.string(), label: z.string() }).array(),
    }),
]);
export const approvalRuleModel = z.object({
    approvers: approverModel.array(),
    matchers: matcherModel.array(),
    type: approvalTypeEnum,
    ordinal: z.number(),
    manuallyAdded: z.boolean(),
    status: ApproverStatus,
});

import PropTypes from 'prop-types';
import React from 'react';

import { BaseHint, CannotInviteTeamMemberAlreadyUserHint } from 'components';
import EmailAlreadyInvitedHint from 'components/hintTypes/EmailAlreadyInvitedHint';

import { Intent } from 'constants/ui';

import { isMembershipMatchWarningInactive, isMembershipDuplicated } from 'helpers/memberships';

/**
 * Display the correct hint if the email has a validation warning/error when inviting a new team member.
 * @param {ComponentProps} props
 * @param {Object[]} [props.asyncErrors]
 * @param {function} props.navigateToSingleTeamMemberProfile
 * @param {function} props.navigateToTeamMemberInvites
 * @param {Object} [props.warningEmail]
 * @returns {StatelessComponent|null}
 */
const InviteTeamMemberFormEmailHints = (props) => {
  const { asyncErrors, navigateToSingleTeamMemberProfile, navigateToTeamMemberInvites, warningEmail } = props;

  if (warningEmail) {
    const { membershipInvites, memberships, warningType } = warningEmail;

    if (isMembershipDuplicated(warningType)) {
      return (
        <EmailAlreadyInvitedHint
          member={membershipInvites[0] || memberships[0]}
          onNavigate={navigateToTeamMemberInvites}
        />
      );
    }

    if (isMembershipMatchWarningInactive(warningType)) {
      return (
        <CannotInviteTeamMemberAlreadyUserHint
          member={memberships[0]}
          membershipMatchWarningType={warningType}
          onNavigate={navigateToSingleTeamMemberProfile}
        />
      );
    }
  }

  return <BaseHint intent={Intent.DANGER} text={asyncErrors[0]} />;
};

InviteTeamMemberFormEmailHints.propTypes = {
  asyncErrors: PropTypes.arrayOf(PropTypes.string),
  navigateToSingleTeamMemberProfile: PropTypes.func.isRequired,
  navigateToTeamMemberInvites: PropTypes.func.isRequired,
  warningEmail: PropTypes.shape({
    membershipInvites: PropTypes.arrayOf(PropTypes.shape({})),
    memberships: PropTypes.arrayOf(PropTypes.shape({})),
    warningType: PropTypes.string,
  }),
};

InviteTeamMemberFormEmailHints.defaultProps = {
  asyncErrors: undefined,
  warningEmail: undefined,
};

export default InviteTeamMemberFormEmailHints;

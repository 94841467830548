import PropTypes from 'prop-types';
import React from 'react';

/**
 * A custom multi-value container to handle showing appropriate values
 * in a checkbox multi-select (combines all selections into one display value).
 *
 * This renders only children that:
 * a) Have some uncheckable type (e.g. random string), -OR-
 * b) Are NOT an instance of MultiCheckValue, -OR-
 * c) Are THE FIRST instance of MultiCheckValue
 *
 * Thus, if you want all selected values to display, use a different custom
 * multi-value or container component. Otherwise, this will aggregate all
 * selections into a single displayed value.
 *
 * @param {NodeMaybe} children
 * @param {function(): Object} getStyles
 * @param {Object} rest
 * @returns {*}
 * @constructor
 */
export const MultiCheckValueContainer = ({ children, getStyles, ...rest }) => (
  <div className="Select__value-container" style={getStyles('valueContainer', rest)}>
    {React.Children.toArray(children).filter(
      (child, idx) => !child.type || child.type.name !== 'MultiCheckValue' || idx === 0,
    )}
  </div>
);

MultiCheckValueContainer.propTypes = {
  children: PropTypes.node,
  getStyles: PropTypes.func.isRequired,
};

MultiCheckValueContainer.defaultProps = {
  children: undefined,
};

export default MultiCheckValueContainer;

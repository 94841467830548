import React from 'react';
import { IconNames } from 'components/icon';
import { TooltipMUI } from 'components/tooltip';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';
import { getDisplayName } from 'helpers/hoc';
import PaymentMethodDetailItemAction from '../../PaymentMethodDetailItemAction';
import { PaymentMethodDetailAction } from '../enums';
const withLockedAction = (PaymentMethodDetailItem) => {
    const Component = (props) => {
        const newProps = { ...props };
        const { actions, tooltipContent } = props;
        const isLocked = actions.includes(PaymentMethodDetailAction.LOCKED);
        if (isLocked) {
            newProps.actionsElements = [
                React.createElement(TooltipMUI, { key: "timestamp-action", padding: TooltipPadding.SKINNY, placement: TooltipPlacement.TOP, title: tooltipContent },
                    React.createElement(PaymentMethodDetailItemAction, { icon: IconNames.LOCK, key: "timestamp-action" })),
            ];
        }
        return React.createElement(PaymentMethodDetailItem, { ...newProps });
    };
    Component.displayName = `${getDisplayName(PaymentMethodDetailItem)}WithLockedAction`;
    return Component;
};
export default withLockedAction;

import { DateFormats } from '@routable/shared';
import { ItemPaymentMethodsText } from 'constants/item';
import { formatDateString } from 'helpers/date';
import { isPaymentDeliveryMethodAch } from 'helpers/paymentMethods';
import {} from 'interfaces/transactions';
export const getTransactionDetailsHeaderTooltipText = (transaction) => {
    const dateText = formatDateString(transaction.created, DateFormats.LOCAL_DATE_SHORT);
    const timeText = formatDateString(transaction.created, DateFormats.LOCAL_TIME_AM_PM).toLowerCase();
    const transactionOrNothing = isPaymentDeliveryMethodAch(transaction.paymentDeliveryMethod) ? '' : ' transaction';
    return `This ${ItemPaymentMethodsText[transaction.paymentDeliveryMethod]}${transactionOrNothing} was initiated on ${dateText} at ${timeText}`;
};

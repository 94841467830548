import PropTypes from 'prop-types';
import React from 'react';
import Textarea from 'react-textarea-autosize';

import FormFieldErrors from 'components/error/components/FormFieldErrors';

import { getFieldErrors } from 'helpers/errors';
import { inputNameOrNameProp } from 'helpers/propTypes';

import { useDebouncedField } from 'hooks';

const AutosizeTextareaField = (props) => {
  let fieldErrors;
  let hasErrors;
  let inputName;
  let handleOnBlur;

  const { onChange: handleOnChange, value: currentValue } = useDebouncedField({
    debounceDelay: props.debounceDelay,
    isDebounced: props.isDebounced,
    input: props.input || {
      onChange: props.onInputChange,
      value: props.value,
    },
  });

  if (props.input) {
    // This is a redux-form input
    inputName = props.input.name;
    fieldErrors = props.meta.touched && props.meta.error;
    hasErrors = !!fieldErrors;
    handleOnBlur = props.input.onBlur;
  } else {
    // This is not a redux-form input
    inputName = props.name;
    fieldErrors = props.errors;
    hasErrors = !!getFieldErrors(props.errors, inputName);
    handleOnBlur = props.onBlur;
  }

  const widthClasses = [];
  const otherClasses = [];

  // Iterate over class list and split to width/other
  props.className.split(' ').forEach((cls) => {
    if (cls.startsWith('w-') || cls.startsWith('right')) {
      widthClasses.push(cls);
    } else {
      otherClasses.push(cls);
    }
  });

  if (hasErrors) {
    otherClasses.push('error');
  }

  if (props.isDisabled) {
    otherClasses.push('disabled');
  }

  return (
    <div className={widthClasses.join(' ')}>
      <div className={otherClasses.join(' ')}>
        <Textarea
          cols={props.cols}
          disabled={props.isDisabled}
          id={props.id}
          maxLength={props.maxLength}
          name={inputName}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          placeholder={props.placeholder}
          required={props.required}
          rows={props.rows}
          style={props.style}
          value={currentValue || ''}
        />
        <label htmlFor={inputName}>{props.placeholder}</label>
      </div>
      <FormFieldErrors errors={fieldErrors} fieldName={inputName} />
    </div>
  );
};

AutosizeTextareaField.propTypes = {
  className: PropTypes.string.isRequired,
  cols: PropTypes.number,
  debounceDelay: PropTypes.number,
  errors: PropTypes.shape(),
  id: PropTypes.string,
  input: PropTypes.shape(),
  isDebounced: PropTypes.bool,
  isDisabled: PropTypes.bool,
  maxLength: PropTypes.number,
  meta: PropTypes.shape(),
  name: inputNameOrNameProp,
  onBlur: PropTypes.func,
  onInputChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  style: PropTypes.shape(),
  value: PropTypes.string,
};

AutosizeTextareaField.defaultProps = {
  cols: null,
  debounceDelay: undefined,
  errors: null,
  id: null,
  input: null,
  isDebounced: undefined,
  isDisabled: false,
  maxLength: null,
  meta: null,
  name: null,
  onBlur: null,
  onInputChange: null,
  placeholder: null,
  required: false,
  rows: null,
  style: {},
  value: '',
};

export default AutosizeTextareaField;

import PropTypes from 'prop-types';
import React from 'react';

import { getBillOrPayText } from 'helpers/items';
import { getMembershipFullName } from 'helpers/memberships';
import { capitalize } from 'helpers/stringHelpers';

import ExternalReceiptRow from './ExternalReceiptRow';

const ExternalReceiptPartnerCompany = ({ currentCompany, item, member }) => (
  <div className="panel-body external-receipt">
    <ExternalReceiptRow description={`${capitalize(getBillOrPayText(item))} to`} text={currentCompany.name} />

    <ExternalReceiptRow description="Representative" text={getMembershipFullName(member)} />
  </div>
);

ExternalReceiptPartnerCompany.propTypes = {
  item: PropTypes.shape().isRequired,
  currentCompany: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  member: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};

export default ExternalReceiptPartnerCompany;

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { convertExternalHandler } from 'actions/routines/auth';

import { InvitationCodeInputTooltip } from 'complexComponents';

import {
  Box,
  BoxBody,
  BrandWrapper,
  ButtonV2,
  CheckboxTermsOfService,
  Emoji,
  Form,
  FormControl,
  FormFieldLabel,
  ReduxFormFieldRenderInput,
  WhiteSpace,
} from 'components';

import { formNamesSignup } from 'constants/forms';
import { PLATFORM_DOMAIN, PLATFORM_EMAILS } from 'constants/platform';
import { button, field } from 'constants/styles/formStyles';

import { minCharacterLengthValidator, requiredValidator, subdomainValidator } from 'helpers/fieldValidation';
import { getMailtoUrl } from 'helpers/urls';

const minLengthValidator = minCharacterLengthValidator(4);

const ConvertExternal = ({ handleSubmit, isSubmitting, membershipId, onFetchCurrentCompany, onFetchMembership }) => {
  React.useEffect(() => {
    onFetchMembership(membershipId);
    onFetchCurrentCompany();
  }, [membershipId, onFetchCurrentCompany, onFetchMembership]);

  return (
    <BrandWrapper
      isLinkNewPage
      linkText="Contact support"
      linkUrl={getMailtoUrl(PLATFORM_EMAILS.SUPPORT)}
      text="Trouble creating your account?"
    >
      <div className="branded--contents">
        <Box title="Create your account">
          <BoxBody>
            <Form id={formNamesSignup.CONVERT_EXTERNAL} onSubmit={handleSubmit(convertExternalHandler)}>
              <FormFieldLabel>Choose your company&apos;s team URL</FormFieldLabel>

              <FormControl>
                <Field
                  className={field.xl.full}
                  component={ReduxFormFieldRenderInput}
                  isDisabled
                  name="company.name"
                  placeholder="Legal business name (or DBA)"
                />
              </FormControl>

              <FormControl className="margin-top--m">
                <Field
                  className={`${field.xl.full} namespace`}
                  component={ReduxFormFieldRenderInput}
                  isDisabled={isSubmitting}
                  name="company.namespace"
                  placeholder="your-team-url"
                  validate={[requiredValidator, minLengthValidator, subdomainValidator]}
                >
                  <div className="inline-label inline-right">
                    <span>{`.${PLATFORM_DOMAIN}`}</span>
                  </div>
                </Field>
              </FormControl>

              <FormFieldLabel className="margin-top--m">
                Confirm your info and add a password
                <WhiteSpace />
                <Emoji ariaLabel="Security" text="🔐" />
              </FormFieldLabel>

              <FormControl>
                <Field
                  className={field.xl.left}
                  component={ReduxFormFieldRenderInput}
                  isDisabled={isSubmitting}
                  name="user.firstName"
                  placeholder="First name"
                  validate={[requiredValidator]}
                />
                <Field
                  className={field.xl.right}
                  component={ReduxFormFieldRenderInput}
                  isDisabled={isSubmitting}
                  name="user.lastName"
                  placeholder="Last name"
                  validate={[requiredValidator]}
                />
              </FormControl>

              <FormControl>
                <Field
                  className={field.xl.full}
                  component={ReduxFormFieldRenderInput}
                  isDisabled={isSubmitting}
                  name="user.password"
                  placeholder="Your password"
                  type="password"
                  validate={[requiredValidator]}
                />
              </FormControl>

              <FormControl>
                <Field
                  addon={<InvitationCodeInputTooltip />}
                  className={field.xl.full}
                  component={ReduxFormFieldRenderInput}
                  isDisabled={isSubmitting}
                  name="meta.signupCode"
                  placeholder="Invitation code"
                  type="text"
                />
              </FormControl>

              <FormControl>
                <Field
                  component={CheckboxTermsOfService}
                  isDisabled={isSubmitting}
                  isRequired={false}
                  name="meta.tosAgree"
                  type="checkbox"
                  validate={[requiredValidator]}
                />
              </FormControl>

              <FormControl className="remove-margin-bottom">
                <ButtonV2
                  className={`${button.full.xl.dark} remove-margin-bottom`}
                  disabled={isSubmitting}
                  hasArrow
                  hasLoading
                  htmlFor={formNamesSignup.CONVERT_EXTERNAL}
                  isFetching={isSubmitting}
                  type="submit"
                >
                  <span>Awesome, create my account</span>
                </ButtonV2>
              </FormControl>
            </Form>
          </BoxBody>
        </Box>
      </div>
    </BrandWrapper>
  );
};

ConvertExternal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  membershipId: PropTypes.string.isRequired,
  onFetchCurrentCompany: PropTypes.func.isRequired,
  onFetchMembership: PropTypes.func.isRequired,
};

ConvertExternal.defaultProps = {
  isSubmitting: undefined,
};

export default ConvertExternal;

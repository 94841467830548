import React from 'react';

import { conditionalValidator } from 'helpers/fieldValidation';

/**
 * Returns a memoized conditional validator that can be passed to redux-form's Field.
 * @param {*} conditionalValue
 * @param {Function|Function[]} validator
 * @return {function}
 */
const useMemoizedConditionalValidate = (conditionalValue, validator) =>
  React.useMemo(() => conditionalValidator(conditionalValue, validator), [conditionalValue, validator]);

export default useMemoizedConditionalValidate;

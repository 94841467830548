import { combineReducers } from 'redux';

import errorReducer from './errorReducer';
import isSubmittingReducer from './isSubmittingReducer';
import lastSubmittedReducer from './lastSubmittedReducer';

const partnershipAddressesReducer = combineReducers({
  errors: errorReducer,
  isSubmitting: isSubmittingReducer,
  lastSubmitted: lastSubmittedReducer,
});

export default partnershipAddressesReducer;

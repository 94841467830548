import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';
import styled from 'styled-components';

import { FlexCol, FlexRow } from 'components/layout';

import { getClassNames } from 'helpers/ui';

import { MultiLineOptionAddons, MultiLineOptionDetails } from './components';

const MultiLineOptionLabel = styled.div`
  color: var(--color-neutral-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

/**
 * Option component with support for multiple lines of text
 * and addons for react-select
 * @param {ComponentProps} props
 * @param {string} props.label
 * @param {object} props.data
 * @param {RestOfProps} props.rest
 * @return {StatelessComponent}
 */
const MultiLineOption = ({ data, label, ...rest }) => {
  const { details, leftAddons, rightAddons } = data;
  const { selectProps: { dataFullStory } = {} } = rest;

  return (
    <components.Option
      {...rest}
      className={getClassNames(rest, {
        'multi-line-option': true,
      })}
      data-fullstory={dataFullStory}
    >
      <FlexRow className="align-items--center" stackOnMobile={false}>
        <MultiLineOptionAddons
          addons={leftAddons}
          className="padding-right--m"
        />

        <FlexCol className="flex--1">
          <MultiLineOptionLabel data-fullstory={dataFullStory}>
            {label}
          </MultiLineOptionLabel>

          <MultiLineOptionDetails>{details}</MultiLineOptionDetails>
        </FlexCol>

        <MultiLineOptionAddons
          addons={rightAddons}
          className="padding-left--m"
        />
      </FlexRow>
    </components.Option>
  );
};

MultiLineOption.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    details: PropTypes.string,
    isDisabled: PropTypes.bool,
    leftAddons: PropTypes.node,
    rightAddons: PropTypes.node,
  }).isRequired,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

MultiLineOption.defaultProps = {
  isDisabled: undefined,
};

export default MultiLineOption;

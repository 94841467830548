import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { CheckboxTermsOfService, Form, FormControl, FormSection, MailingAddress } from 'components/form';

import { formNamesExternal } from 'constants/forms';
import { countryListOptions } from 'constants/i18n';

import { noDefaultEvent } from 'helpers/events';
import { requiredValidator } from 'helpers/fieldValidation';

import submitFilingUpdate from 'modules/external/ExternalCollectTaxInfo/helpers/submitFilingUpdates';

import { ConfirmInformationButton, FilingDetailsForm } from './components';

/**
 * Form to collect all 1099 information, including mailing address and business information.
 * @param {object} props
 * @returns {StatelessComponent}
 * @constructor
 */
const CollectTaxForm = (props) => {
  const {
    change, // from redux-form
    countrySelection,
    filingDetails,
    handleSubmit, // from redux-form
    initialValues, // from redux-form
    invalid,
    isDisabled,
    onSuccessOpenModal,
    onUpdateOnboardingCompany,
    onUpdateOnboardingCompanyType,
    onUpdateOnboardingMembership,
    submitting, // from redux-form
  } = props;

  const handleFormSubmit = async (e) => {
    noDefaultEvent(e);
    const options = {
      change, // bound to form
      onSuccessOpenModal,
      onUpdateOnboardingCompany,
      onUpdateOnboardingCompanyType,
      onUpdateOnboardingMembership,
    };
    return submitFilingUpdate(filingDetails, initialValues, options);
  };

  return (
    <Form id={formNamesExternal.COLLECT_TAX_INFO} onSubmit={handleSubmit(handleFormSubmit)}>
      <FormSection>
        <MailingAddress
          addressPath="form.company.mailingAddress."
          country={countrySelection}
          countryOptions={countryListOptions.US_AND_CANADA}
          formName={formNamesExternal.COLLECT_TAX_INFO}
          isDisabled={isDisabled}
        />
      </FormSection>

      <FormSection>
        <FilingDetailsForm formName={formNamesExternal.COLLECT_TAX_INFO} invalid={invalid} />

        {filingDetails.ui.needsTos && (
          <FormControl>
            <Field
              component={CheckboxTermsOfService}
              isRequired
              name="form.meta.tosAgree"
              type="checkbox"
              validate={requiredValidator}
            />
          </FormControl>
        )}
      </FormSection>

      <FormSection className="margin-bottom--x-large">
        <FormControl>
          <ConfirmInformationButton isDisabled={invalid} isSubmitting={submitting} />
        </FormControl>
      </FormSection>
    </Form>
  );
};

CollectTaxForm.propTypes = {
  change: PropTypes.func.isRequired,
  countrySelection: PropTypes.string,
  filingDetails: PropTypes.shape(),
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape().isRequired,
  invalid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onSuccessOpenModal: PropTypes.func.isRequired,
  onUpdateOnboardingCompany: PropTypes.func.isRequired,
  onUpdateOnboardingCompanyType: PropTypes.func.isRequired,
  onUpdateOnboardingMembership: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

CollectTaxForm.defaultProps = {
  countrySelection: undefined,
  // must have some definition for TOS checkbox rendering
  filingDetails: { ui: {} },
  invalid: true,
  isDisabled: undefined,
};

export default CollectTaxForm;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';
import { IconNames } from 'components/icon';

import { Intent } from 'constants/ui';

import { oneOfValuesFromObject } from 'helpers/propTypes';

/**
 * Component rendering Notification Bar close button
 * @param {ComponentProps} props
 * @param {Intent} props.intent
 * @param {Function} props.onCloseNotification
 * @returns {StatelessComponent}
 */
const NotificationCloseButton = ({ intent, onCloseNotification }) => (
  <ButtonV2
    className={classNames('notification--close-button', intent)}
    isIconButton
    leftIconName={IconNames.CROSS}
    onClick={onCloseNotification}
  />
);

NotificationCloseButton.propTypes = {
  intent: oneOfValuesFromObject(Intent),
  onCloseNotification: PropTypes.func.isRequired,
};

NotificationCloseButton.defaultProps = {
  intent: Intent.NEUTRAL,
};

export default NotificationCloseButton;

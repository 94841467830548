import PropTypes from 'prop-types';
import React from 'react';

import { LinkNewPage, Text, TextLinkNewInternalPage, WhiteSpace } from 'components';

import { CONTACT_SUPPORT_LOWERCASE_LABEL } from 'constants/labels';
import { SETTINGS_ACCOUNT_PAYMENT_METHODS_ROUTE } from 'constants/routes';
import { typography } from 'constants/styles';

import { getPaymentOrInvoiceText } from 'helpers/items';
import { capitalize } from 'helpers/stringHelpers';

/**
 * AmountAboveBalanceTransferLimitTooltip
 * Tooltip content for when row amount is greater than allowed max payment delivery amount
 * @param {ComponentProps} props
 * @param {Item} props.item
 * @param {Function} props.openSupportChat
 * @return {StatelessComponent}
 */
const AmountAboveBalanceTransferLimitTooltip = ({ item, openSupportChat }) => (
  <Text.Regular className="line-height--large--important" color={typography.TextColor.GREY_XX_DARK}>
    {capitalize(getPaymentOrInvoiceText(item))}
    <WhiteSpace />
    exceeds your account
    <WhiteSpace />
    <LinkNewPage
      className="font-weight--regular font-color--blue-bold--important"
      href={SETTINGS_ACCOUNT_PAYMENT_METHODS_ROUTE}
    >
      transaction limit
    </LinkNewPage>
    . Please
    <WhiteSpace />
    <TextLinkNewInternalPage onClick={openSupportChat} size={typography.TextSize.LEVEL_300}>
      {CONTACT_SUPPORT_LOWERCASE_LABEL}
    </TextLinkNewInternalPage>
    <WhiteSpace />
    to discuss how your company can increase the upper limits on your transactions.
  </Text.Regular>
);

AmountAboveBalanceTransferLimitTooltip.propTypes = {
  item: PropTypes.shape({}).isRequired,
  openSupportChat: PropTypes.func.isRequired,
};

export default AmountAboveBalanceTransferLimitTooltip;

import { createLastFetchedReducer } from 'store/redux';

import { FETCH_FUNDING_ACCOUNTS_SUCCESS } from 'types/funding';
import {
  FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_SUCCESS,
  FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_SUCCESS,
  SEARCH_FUNDING_ACCOUNTS_SUCCESS,
} from 'types/integrations';

export const successCases = [
  FETCH_FUNDING_ACCOUNTS_SUCCESS,
  FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_SUCCESS,
  FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_SUCCESS,
  SEARCH_FUNDING_ACCOUNTS_SUCCESS,
];

const lastFetchedReducer = createLastFetchedReducer(successCases);

export default lastFetchedReducer;

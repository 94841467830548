import PropTypes from 'prop-types';
import React from 'react';

import FormFieldLabel from 'components/form/FormFieldLabel';

import { countryListOptions } from 'constants/i18n';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { CityRegionPostcodeInputs, CountrySelect, StreetAddressInputs } from './components';

/**
 * Form section which collects mailing address information. Form field names are hardcoded within the subcomponents,
 * but the addressPath allows for further customization of nested form state.
 * @param {string} [addressPath]
 * @param {string} country
 * @param {string} countryOptions
 * @param {string} formName
 * @param {boolean} isDisabled
 * @returns {StatelessComponent}
 * @constructor
 */
const MailingAddress = ({ addressPath, country, countryOptions, formName, isDisabled }) => (
  <React.Fragment>
    <FormFieldLabel>Mailing Address</FormFieldLabel>

    <CountrySelect addressPath={addressPath} countryOptions={countryOptions} isDisabled={isDisabled} />
    <StreetAddressInputs
      addressPath={addressPath}
      countryOptions={countryOptions}
      formName={formName}
      isDisabled={isDisabled}
    />
    <CityRegionPostcodeInputs addressPath={addressPath} country={country} isDisabled={isDisabled} />
  </React.Fragment>
);

MailingAddress.propTypes = {
  addressPath: PropTypes.string,
  country: PropTypes.string,
  countryOptions: PropTypes.oneOf(Object.values(countryListOptions)).isRequired,
  formName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

MailingAddress.defaultProps = {
  country: undefined,
  addressPath: undefined,
  isDisabled: false,
};

export default MailingAddress;

import { combineReducers } from 'redux';

import errorReducer from './errorReducer';
import isFetchingNamespaceReducer from './isFetchingNamespaceReducer';
import isNamespaceValidReducer from './isNamespaceValidReducer';
import lastSubmittedReducer from './lastSubmittedReducer';
import submitReducer from './submitReducer';

const signupSelectNamespaceReducer = combineReducers({
  errors: errorReducer,
  isSubmitting: submitReducer,
  lastSubmitted: lastSubmittedReducer,
  isNamespaceValid: isNamespaceValidReducer,
  isFetchingNamespace: isFetchingNamespaceReducer,
});

export default signupSelectNamespaceReducer;

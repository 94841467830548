import { fetchCountriesRoutine } from 'actions/routines/countries';

import { initialState } from './initialState';

const dashboardLoadedCountriesReducer = (state = initialState.countries, action) => {
  switch (action.type) {
    case fetchCountriesRoutine.SUCCESS:
      return true;

    default:
      return state;
  }
};

export default dashboardLoadedCountriesReducer;

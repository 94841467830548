import PropTypes from 'prop-types';
import React from 'react';

const ModalBodyContentFull = ({ children }) => <div className="modal-body--full">{children}</div>;

ModalBodyContentFull.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalBodyContentFull;

import { isFn } from 'helpers/utility';

export default (props, propName, componentName) => {
  const { editAllowed, onEdit } = props;

  // if you've defined onEdit, it must be a function
  if (onEdit && !isFn(onEdit)) {
    return new Error(`onEdit must be a function in ${componentName}.`);
  }

  // if you intend on allowing the user to edit the field, onEdit must be a function
  if (editAllowed && !isFn(onEdit)) {
    return new Error(`If editAllowed is true in ${componentName}, onEdit must be a function.`);
  }

  return null;
};

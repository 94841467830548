import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { closeAddPartnerFundingAccountModal } from 'actions/funding';

import { modalNameAddPartnerFundingAccount } from 'constants/modals';

import { createModalSelector } from 'selectors/modalsSelector';

import AddPartnerFundingAccountModal from './AddPartnerFundingAccountModal';

const modalSelector = createModalSelector(modalNameAddPartnerFundingAccount);

export const mapStateToProps = createStructuredSelector({
  modalState: modalSelector,
});

export const mapDispatchToProps = {
  onCloseModal: closeAddPartnerFundingAccountModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPartnerFundingAccountModal);

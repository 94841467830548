import * as types from 'types/item';

export const initialState = {
  action: null,
  itemId: null,
  open: false,
};

const existingItemSidePanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CLOSE_EXISTING_ITEM_SIDE_PANEL:
      return initialState;

    case types.OPEN_EXISTING_ITEM_SIDE_PANEL:
      return {
        ...state,
        ...action.payload,
        open: true,
      };

    default:
      return state;
  }
};

export default existingItemSidePanelReducer;

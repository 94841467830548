import React from 'react';

import { getClassNames } from 'helpers/ui';

import Divider from '../Divider';

/**
 * A divider used in a side panel to divide form sections.
 * @param {Object} props
 * @returns {StatelessComponent}
 */
const SidePanelFormDivider = (props) => (
  <Divider {...props} className={getClassNames(props, { 'side-panel-divider': true })} />
);

export default SidePanelFormDivider;

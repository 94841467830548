import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';

import { gatherReducerCasesFromRoutines } from 'helpers/routine';

import { createIsFetchingReducer } from 'store/redux';

export const { failureCases, requestCases, successCases } = gatherReducerCasesFromRoutines([
  fetchExternalPartnershipRequestRoutine,
]);

export const finishCases = [...failureCases, ...successCases];

export default createIsFetchingReducer(finishCases, requestCases);

import PropTypes from 'prop-types';
import React from 'react';

import { isItemKindPayable } from 'helpers/items';

import ExternalReceiptRow from 'modules/external/externalReceipt/presenters/ExternalReceiptRow';

const PartnerReference = ({ item }) => {
  if (!item.partnerReference) {
    return null;
  }

  // Showing `Reference` for an invoice the external user needs to pay (i.e. Payable)
  // Showing `Description` for a payment the external user needs to accept (i.e. Receivable)
  const description = isItemKindPayable(item) ? 'Reference' : 'Description';

  return <ExternalReceiptRow description={description} text={item.partnerReference} />;
};

PartnerReference.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default PartnerReference;

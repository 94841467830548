import { TruncatableText } from '@routable/components';
import clsx from 'clsx';
import React from 'react';
import Icon from 'components/icon';
import TableCellSubtext from '../TableCellSubtext';
import TableCellText from '../TableCellText';
const TableCellTextWithSubtext = ({ className, subtext, subtextClassName, subtextIconProps, text, textClassName, textIconProps, dataFullStory, }) => {
    return (React.createElement("div", { className: clsx('overflow-hidden', 'w-full', className), "data-fullstory": dataFullStory },
        !!text && (React.createElement(TableCellText, { className: textClassName },
            textIconProps?.icon && React.createElement(Icon, { className: "margin-right--sm", ...textIconProps }),
            text)),
        !!subtext && (React.createElement(TableCellSubtext, { className: "margin-top--xs" },
            subtextIconProps?.icon && React.createElement(Icon, { className: "margin-right--sm", ...subtextIconProps }),
            React.createElement(TruncatableText, { className: subtextClassName, maxNumberOfLines: 1, position: "top", text: subtext })))));
};
export default TableCellTextWithSubtext;

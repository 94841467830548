import { z } from 'zod';
import { uuidModel } from './common.model';
export const logEntryModel = z.object({
    type: z.literal('LogEntry'),
    id: z.string(),
    attributes: z.object({
        action: z.enum(['update', 'create', 'delete', 'access']),
        changes: z.record(z.string(), z.object({ oldValue: z.unknown(), newValue: z.unknown() })),
        date: z.string().datetime(),
    }),
    relationships: z.object({
        modifiedBy: z.object({
            data: z.object({
                type: z.literal('PartnershipMember'),
                id: uuidModel,
            }),
        }),
    }),
});

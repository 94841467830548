import submitFundingAccountBankManual from 'thunks/submitFundingAccountBankManual';

/**
 * Helper to submit add/match platform partnership
 * @param {Object} values
 * @return {Thunk}
 */
export const handleConnectBankManualSubmit = (values) => {
  const { form } = values;

  const submitPayload = {
    data: {
      type: 'FundingAccount',
    },
    meta: {
      bankAccountType: form.bankAccountType,
      bankRoutingNumber: form.bankRoutingNumber,
      bankAccountNumber: form.bankAccountNumber,
      createCheckSource: form.createCheckSource,
    },
  };

  return submitFundingAccountBankManual(submitPayload);
};

import React from 'react';
import { LabelLink } from 'components/link/LabelLink/LabelLink.styles';
import { ContactSidePanelType } from 'constants/sidePanels';
import { useSelectorWithProps } from 'hooks';
import { partnershipMembersForMatchedCompanySelector } from 'selectors/partnershipMemberSelectors';
import {} from '../../ContactRequiredToAllowSelectPaymentMethodHint.types';
const CreateEditPaymentHintTextPersonalComponent = ({ isInviting = false, onOpenContactSidePanel, selectedCompany, }) => {
    const { partnershipMembers } = selectedCompany;
    const [partnershipMember] = useSelectorWithProps(partnershipMembersForMatchedCompanySelector, partnershipMembers?.map((member) => member.id));
    const updateContactOptions = partnershipMember
        ? {
            company: selectedCompany,
            initialPartnershipMember: partnershipMember,
            partnershipId: selectedCompany.id,
            partnershipMember,
            type: ContactSidePanelType.UPDATE,
        }
        : {
            company: selectedCompany,
            partnershipId: selectedCompany.id,
            type: ContactSidePanelType.CREATE,
        };
    return (React.createElement("span", { className: "contact-required-to-select-payment-hint" },
        `To allow a vendor to ${isInviting ? 'add' : 'select'} their payment method, please `,
        partnershipMember?.email ? ('include the contact above') : (React.createElement(LabelLink, { onClick: () => onOpenContactSidePanel(updateContactOptions) }, "add an email to the contact")),
        "."));
};
export default CreateEditPaymentHintTextPersonalComponent;

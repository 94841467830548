import { IconNames } from 'components/icon';

import { typography } from 'constants/styles';

/**
 * Enum with numbered External Flow Steps
 * @enum {number}
 */
export const ExternalFlowSteps = {
  INITIAL: 0,
  CONTACT_INFO: 1,
  ENTITY_INFO: 2,
  PAYMENT_INFO: 3,
};

/**
 * Enum with External Flow Step names.
 * Copied from BE repository (https://github.com/routablehq/backend/blob/be32066ce055145dfbec171ed01d4c7d9bb0f9df/entity/workflow/constants.py#L142)
 * @enum {String}
 */
export const ExternalFlowStepName = {
  COMPANY: 'company',
  CONFIRMATION: 'confirmation',
  CONTACT: 'contact',
  ERROR: 'error',
  NOT_NEEDED: 'not_needed',
  PAYMENT_METHOD: 'payment_method',
  PROMPT: 'prompt',
};

/**
 * Enum with possible values of External Step Hint Status
 * @enum {ExternalHintStatusType}
 */
export const ExternalHintStatus = {
  CANCELLED: 'canceled',
  COMPLETED: 'completed',
  FAILED_PAYMENT: 'failed_payment',
  INCOMPLETE: 'incomplete',
  PROCESSING: 'processing',
};

/**
 * Enum with different payment method hint labels
 * @enum {string}
 */
export const ExternalPaymentMethodHintLabel = {
  ITEM_PAYABLE: 'Payment method:',
  ITEM_RECEIVABLE: 'Payment sent to:',
};

/**
 * Map of IconNames for each ExternalHintStatus
 * @enum {string}
 */
export const ExternalHintStatusIconMap = {
  [ExternalHintStatus.CANCELLED]: IconNames.FULL_CIRCLE,
  [ExternalHintStatus.COMPLETED]: IconNames.TICK_CIRCLE,
  [ExternalHintStatus.INCOMPLETE]: IconNames.CIRCLE,
  [ExternalHintStatus.FAILED_PAYMENT]: IconNames.FULL_CIRCLE,
  [ExternalHintStatus.PROCESSING]: IconNames.CIRCLE,
};

/**
 * Map of colors for each ExternalHintStatus
 * @enum {string}
 */
export const ExternalHintStatusColorMap = {
  [ExternalHintStatus.CANCELLED]: typography.TextColor.RED_BOLD,
  [ExternalHintStatus.COMPLETED]: typography.TextColor.GREEN_BOLD,
  [ExternalHintStatus.INCOMPLETE]: typography.TextColor.YELLOW_MEDIUM,
  [ExternalHintStatus.FAILED_PAYMENT]: typography.TextColor.RED_BOLD,
  [ExternalHintStatus.PROCESSING]: typography.TextColor.BLUE_BOLD,
};

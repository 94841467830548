/**
 * @fileOverview Defines constants related to user authentication.
 * @module constants/auth
 */

/**
 * Components of the auth token string that aren't variable.
 * @enum {string}
 */
export const AuthTokenComponent = {
  JWT_AUTH: 'JWT_AUTH',
  JWT: 'JWT',
  TOKEN: 'Token',
};

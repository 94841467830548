import * as types from 'types/apiToken';

// Create API Token Modal open/close
export const closeCreateApiTokenModal = () => ({
  type: types.MODAL_CREATE_API_TOKEN_CLOSE,
});
export const openCreateApiTokenModal = () => ({
  type: types.MODAL_CREATE_API_TOKEN_OPEN,
});

// Fetch
export const fetchApiTokensRequest = (companyId) => ({
  payload: { companyId },
  type: types.FETCH_API_TOKENS_REQUEST,
});

export const fetchApiTokensSuccess = (payload) => ({
  payload,
  type: types.FETCH_API_TOKENS_SUCCESS,
});

export const fetchApiTokensFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_API_TOKENS_FAILURE,
});

// Delete
export const deleteApiTokenRequest = (companyId, tokenId) => ({
  payload: { companyId, tokenId },
  type: types.DELETE_API_TOKEN_REQUEST,
});

export const deleteApiTokenSuccess = (tokenId) => ({
  payload: { tokenId },
  type: types.DELETE_API_TOKEN_SUCCESS,
});

export const deleteApiTokenFailure = (errors) => ({
  payload: { errors },
  type: types.DELETE_API_TOKEN_FAILURE,
});

// Create
export const createApiTokenRequest = (companyId) => ({
  payload: { companyId },
  type: types.CREATE_API_TOKEN_REQUEST,
});

export const createApiTokenSuccess = (payload) => ({
  payload,
  type: types.CREATE_API_TOKEN_SUCCESS,
});

export const createApiTokenFailure = (errors) => ({
  payload: { errors },
  type: types.CREATE_API_TOKEN_FAILURE,
});

// Regenerate
export const regenerateApiTokenRequest = (companyId, tokenId) => ({
  payload: { companyId, tokenId },
  type: types.REGENERATE_API_TOKEN_REQUEST,
});

export const regenerateApiTokenSuccess = (payload) => ({
  payload,
  type: types.REGENERATE_API_TOKEN_SUCCESS,
});

export const regenerateApiTokenFailure = (errors) => ({
  payload: { errors },
  type: types.REGENERATE_API_TOKEN_FAILURE,
});

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { formNamesSignup } from 'constants/forms';
import { getQueryParam } from 'helpers/queryParams';
import { isSubmittingSignupCreateAccountSelector, signupCreateAccountErrorsSelector } from 'selectors/signupSelectors';
import submitAccountCreationCreateAccount from 'thunks/submitAccountCreationCreateAccount';
import CreateAccount from './CreateAccount';
export const mapStateToProps = createStructuredSelector({
    errors: signupCreateAccountErrorsSelector,
    isSubmitting: isSubmittingSignupCreateAccountSelector,
});
export const mapDispatchToProps = {
    onSubmit: submitAccountCreationCreateAccount,
};
const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));
const withForm = reduxForm({
    form: formNamesSignup.CREATE_ACCOUNT,
    initialValues: {
        company: {
            name: '',
        },
        user: {
            email: '',
            firstName: '',
            lastName: '',
            password: '',
        },
        meta: {
            tosAgree: false,
            signupCode: getQueryParam('invitation_code') || '',
        },
    },
});
export default enhance(withForm(CreateAccount));

/**
 * @fileOverview Defines constants related to bulk actions.
 * @module constants/bulkActions
 */
import { IconNames } from 'components/icon';

import { BulkActionTypes } from 'enums/bulkActions';
import { ItemKinds } from 'enums/items';

// Keeping this var for future use-cases
// ex. we add a new ledger and we want to disable CSV upload for it for the time-being
export const ledgersRestrictedFromCSVUpload = [];

/**
 * Text and title shown in the swal which protects the users from unintentionally navigating away
 * @see {BulkImportItemsSummary}
 * @type {{alertText: string, alertTitle: string}}
 */
export const alertOptions = {
  alertText: 'You will lose all progress if you leave this page.',
  alertTitle: 'Are you sure?',
};

/**
 * Passive text for specific bulk action
 */
export const BulkActionTypesPassiveText = {
  [BulkActionTypes.APPROVE]: 'approved',
  [BulkActionTypes.APPROVE_AND_SEND]: 'approved and sent',
  [BulkActionTypes.EDIT_SEND_DATE]: 'rescheduled',
  [BulkActionTypes.SEND]: 'sent',
};

/**
 * Passive text for specific bulk action
 */
export const BulkActionTypesPassiveTextForFutureScheduledDate = {
  [BulkActionTypes.APPROVE]: 'approved',
  [BulkActionTypes.APPROVE_AND_SEND]: 'approved and scheduled',
  [BulkActionTypes.EDIT_SEND_DATE]: 'rescheduled',
  [BulkActionTypes.SEND]: 'scheduled',
};

/**
 * Present tense text text for specific bulk action
 */
export const BulkActionTypesPresentTenseText = {
  [BulkActionTypes.APPROVE]: 'approve',
  [BulkActionTypes.APPROVE_AND_SEND]: 'approve and send',
  [BulkActionTypes.EDIT_SEND_DATE]: 'reschedule',
  [BulkActionTypes.SEND]: 'send',
};

/**
 * Present tense text text for specific bulk action
 */
export const BulkActionTypesPresentTenseTextForFutureScheduledDate = {
  [BulkActionTypes.APPROVE]: 'approve',
  [BulkActionTypes.APPROVE_AND_SEND]: 'approve and schedule',
  [BulkActionTypes.EDIT_SEND_DATE]: 'reschedule',
  [BulkActionTypes.SEND]: 'schedule',
};

/**
 * Action text for specific bulk action
 */
export const BulkActionTypesActionText = {
  [BulkActionTypes.APPROVE]: 'approval',
  [BulkActionTypes.APPROVE_AND_SEND]: 'approval and sending',
  [BulkActionTypes.EDIT_SEND_DATE]: 'rescheduling',
  [BulkActionTypes.SEND]: 'sending',
};

/**
 * Type for specific bulk action to use for the API call
 */
export const BulkActionTypesApiMap = {
  [BulkActionTypes.APPROVE]: 'item_approve',
  [BulkActionTypes.APPROVE_AND_SEND]: 'item_approve_and_send',
  [BulkActionTypes.EDIT_SEND_DATE]: 'item_edit_send_date',
  [BulkActionTypes.SEND]: 'item_send',
};

/**
 * Maps BE bulk action types to FE ones
 */
export const BulkActionApiTypeToTypeMap = {
  [BulkActionTypesApiMap[BulkActionTypes.APPROVE]]: BulkActionTypes.APPROVE,
  [BulkActionTypesApiMap[BulkActionTypes.APPROVE_AND_SEND]]: BulkActionTypes.APPROVE_AND_SEND,
  [BulkActionTypesApiMap[BulkActionTypes.EDIT_SEND_DATE]]: BulkActionTypes.EDIT_SEND_DATE,
  [BulkActionTypesApiMap[BulkActionTypes.SEND]]: BulkActionTypes.SEND,
};

export const CSV_TEMPLATE_FILENAME = 'template.csv';

/**
 * Maximum number of errors that can be displayed in our bulk import flow
 * @see {BulkImportItemsErrorTable}
 * @see {NumberOfErrorsShownHint}
 * @type {number}
 */
export const MAX_NUMBER_OF_DISPLAYED_ERRORS = 1000;

/**
 * Issue codes that can be used in the `code` property, on an object in the bulkImport.issues array.
 * @enum {string}
 */
export const BulkImportIssueCode = {
  FAILED_ITEMS: 'failed_items',
};

/**
 * Field-level-validation-error-strings that come from the backend.
 * @enum {string}
 */
export const BulkImportErrorCodes = {
  EMPTY_CSV: 'csv_empty',
  FILE_TOO_BIG: 'csv_file_size_too_big',
  INVALID_CSV: 'csv_invalid',
  UNSUPPORTED_ENCODING: 'csv_file_encoding',
};

/**
 * A step that the bulk import items form that may be returned by the API
 * as needing correction or some other user action, in order to continue
 * with the import.
 * These are 1:1 with enum strings returned by the API.
 * @enum {string}
 */
export const BulkImportItemsApiStep = {
  complete: 'complete',
  finalize: 'finalize',
  pending: 'pending',
  prep: 'prep',
  summary: 'summary',
  validation_data: 'validation_data',
  validation_file: 'validation_file',
  validation_ledger: 'validation_ledger',
  validation_recommended: 'validation_recommended',
  warnings: 'warnings',
};

/**
 * Any step that the bulk import items form can display.
 * @enum {string}
 */
export const BulkImportItemsStep = {
  ...BulkImportItemsApiStep,
  approvals: 'approvals',
  select_kind: 'select_kind',
  upload: 'upload',
};

/**
 * Bulk import items steps, in order.
 * @type {BulkImportItemsStep[]}
 */
export const bulkImportItemsStepsOrdered = [
  BulkImportItemsStep.upload,
  BulkImportItemsStep.validation_file,
  BulkImportItemsStep.validation_data,
  BulkImportItemsStep.validation_recommended,
  BulkImportItemsStep.validation_ledger,
  BulkImportItemsStep.prep,
  BulkImportItemsStep.warnings,
  BulkImportItemsStep.summary,
  BulkImportItemsStep.approvals,
  BulkImportItemsStep.finalize,
  BulkImportItemsStep.complete,
];

/**
 * creates the return object structure needed for BulkImportItemsStepMapper
 * @enum {string}
 */
export const getBulkImportItemsStepMapperObject = (prev, next) => ({
  prev,
  next,
});

/**
 * Returns the previous and next step in the BulkImportItems Form
 * @enum {string}
 */
export const BulkImportItemsStepMapper = {
  [BulkImportItemsStep.select_kind]: getBulkImportItemsStepMapperObject(null, BulkImportItemsStep.validation_file),
  [BulkImportItemsStep.validation_file]: getBulkImportItemsStepMapperObject(
    BulkImportItemsStep.select_kind,
    BulkImportItemsStep.validation_recommended,
  ),
  [BulkImportItemsStep.validation_recommended]: getBulkImportItemsStepMapperObject(
    BulkImportItemsStep.validation_file,
    BulkImportItemsStep.validation_data,
  ),
  [BulkImportItemsStep.validation_data]: getBulkImportItemsStepMapperObject(
    BulkImportItemsStep.validation_recommended,
    BulkImportItemsStep.summary,
  ),
  [BulkImportItemsStep.summary]: getBulkImportItemsStepMapperObject(
    BulkImportItemsStep.validation_data,
    BulkImportItemsStep.approvals,
  ),
  [BulkImportItemsStep.approvals]: getBulkImportItemsStepMapperObject(
    BulkImportItemsStep.summary,
    BulkImportItemsStep.finalize,
  ),
};

export const FileValidationStepConfig = [
  {
    completion: {
      timestamp: null,
    },
    progressBar: {
      label: 'Upload file',
      icon: IconNames.CLOUD_UPLOAD,
    },
  },
  {
    completion: {
      timestamp: null,
    },
    progressBar: {
      label: 'Review',
      icon: IconNames.FORM,
    },
  },
];

export const SummaryStepConfig = [
  {
    completion: {
      timestamp: 'timestamp',
    },
    progressBar: {
      label: 'Upload file',
      icon: IconNames.CLOUD_UPLOAD,
    },
  },
  {
    completion: {
      timestamp: null,
    },
    progressBar: {
      label: 'Review',
      icon: IconNames.FORM,
    },
    substeps: [
      {
        completion: {
          timestamp: null,
        },
        menu: {
          display: 'show',
        },
        progressBar: {
          label: 'Summary',
          icon: IconNames.SAVED,
        },
      },
    ],
  },
  {
    completion: {
      timestamp: null,
    },
    progressBar: {
      label: 'Confirmation',
      icon: IconNames.TICK_CIRCLE,
    },
  },
];

export const FundingStepConfig = [
  {
    completion: {
      timestamp: 'timestamp',
    },
    progressBar: {
      label: 'Upload file',
      icon: IconNames.CLOUD_UPLOAD,
    },
  },
  {
    completion: {
      timestamp: null,
    },
    progressBar: {
      label: 'Review',
      icon: IconNames.FORM,
      display: 'show',
    },
    substeps: [
      {
        completion: {
          timestamp: null,
        },
        menu: {
          display: 'show',
        },
        progressBar: {
          label: 'Optional fields',
          icon: IconNames.ADD_TO_ARTIFACT,
        },
      },
      {
        completion: {
          timestamp: null,
        },
        progressBar: {
          label: 'Matching',
          icon: IconNames.COMPARISON,
        },
      },
      {
        completion: {
          timestamp: null,
        },
        progressBar: {
          label: 'Delivery methods',
          icon: IconNames.SEND_TO,
        },
      },
      {
        completion: {
          timestamp: null,
        },
        progressBar: {
          label: 'Summary',
          icon: IconNames.SAVED,
        },
      },
    ],
  },
];

export const ApprovalsStepConfig = [
  SummaryStepConfig[0],
  {
    ...SummaryStepConfig[1],
    substeps: [
      {
        ...SummaryStepConfig[1].substeps[0],
        completion: {
          timestamp: 'timestamp',
        },
      },
      {
        completion: {
          timestamp: null,
        },
        menu: {
          display: 'show',
        },
        progressBar: {
          label: 'Approvals',
          icon: IconNames.TAKE_ACTION,
        },
      },
    ],
  },
  SummaryStepConfig[2],
];

export const ConfirmationStepConfig = [
  SummaryStepConfig[0],
  {
    ...SummaryStepConfig[1],
    completion: {
      timestamp: 'timestamp',
    },
    substeps: SummaryStepConfig[1].substeps.map((substep) => ({
      ...substep,
      completion: {
        timestamp: 'timestamp',
      },
    })),
  },
  {
    completion: {
      timestamp: 'timestamp',
    },
    progressBar: {
      label: 'Confirmation',
      icon: IconNames.TICK_CIRCLE,
    },
  },
];

export const BulkActionsImportTypes = {
  [ItemKinds.PAYABLE]: 'item_payable',
  [ItemKinds.RECEIVABLE]: 'item_receivable',
};

export const PayableOrReceivableByBulkActionImportType = {
  [BulkActionsImportTypes[ItemKinds.PAYABLE]]: ItemKinds.PAYABLE,
  [BulkActionsImportTypes[ItemKinds.RECEIVABLE]]: ItemKinds.RECEIVABLE,
};

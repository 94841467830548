import React from 'react';
import { ContactAutofillIcons } from 'complexComponents/ContactAutofillIcons';
import { statusCanHaveDefaultContactSettings } from 'helpers/contacts';
import {} from '../ContactCellsTypes.types';
const ContactSettingsCell = ({ rowData }) => {
    const { status } = rowData;
    if (!statusCanHaveDefaultContactSettings(status)) {
        return null;
    }
    return (React.createElement("div", { className: "flex-row" },
        React.createElement(ContactAutofillIcons, { partnershipMember: rowData })));
};
export default ContactSettingsCell;

import { call } from 'redux-saga/effects';

import { getCurrentCompanyRequest } from 'actions/currentCompany';
import { deleteCompanyDocumentRoutine, postCompanyDocumentRoutine } from 'actions/routines/documents';

import { createSaga } from 'helpers/saga';

import { handleDocumentDeleteError, handleDocumentUploadError } from 'sagas/shared/documentError';

import * as api from './api';

export function* postCompanyDocument({ payload }) {
  const { addFileToList, currentCompanyId, file } = payload;
  const options = {
    apiParams: [currentCompanyId, file],
    onSuccess: [getCurrentCompanyRequest, addFileToList],
    onError: handleDocumentUploadError,
  };

  yield call(createSaga, api.postCompanyDocument, postCompanyDocumentRoutine, options);
}

export function* deleteCompanyDocument({ payload }) {
  const { currentCompanyId, documentId, removeFileFromList } = payload;

  const options = {
    apiParams: [currentCompanyId, documentId],
    onSuccess: [getCurrentCompanyRequest, removeFileFromList],
    onError: handleDocumentDeleteError,
  };

  yield call(createSaga, api.deleteCompanyDocument, deleteCompanyDocumentRoutine, options);
}

import { createSelector } from 'reselect';
import { allValues } from 'helpers/utility';
import {} from 'interfaces/redux';
import {} from 'interfaces/sso';
const getState = (state) => state.sso;
export const ssoSetupFormSelector = createSelector([getState], (sso) => sso.setupForm);
export const ssoSetupFormProviderSelector = createSelector([ssoSetupFormSelector], (state) => state.ssoProvider);
export const ssoSettingsSelector = createSelector([getState], (sso) => sso.currentCompanySettings);
export const ssoSettingsByIdSelector = createSelector([ssoSettingsSelector], (ssoSettings) => ssoSettings.byId);
export const allSSOSettingsSelector = createSelector([ssoSettingsByIdSelector], (ssoSettingsById) => allValues(ssoSettingsById));
export const currentSSOSettingsSelector = createSelector([allSSOSettingsSelector], (allSSOSettings) => allSSOSettings?.shift?.());
export const enabledSSOSettingsSelector = createSelector([allSSOSettingsSelector], (allSSOSettings) => allSSOSettings.find((ssoSettings) => ssoSettings.enableSso === true));
export const ssoSettingsIsDisablingSelector = createSelector([ssoSettingsSelector], (ssoSettings) => ssoSettings.isDisabling);
export const ssoSettingsIsFetchingSelector = createSelector([ssoSettingsSelector], (ssoSettings) => ssoSettings.isFetching);

import PropTypes from 'prop-types';
import React from 'react';

import { IconNames } from 'components/icon';
import ToolbarButton from 'components/toolbar/components/ToolbarButton';

import { sizes } from 'constants/styles';

const ToolbarCancelButton = ({ hoveredClassName, iconName, iconSize, label, onCancel, ...props }) => (
  <ToolbarButton
    className="toolbar--cancel-button font-color--grey--x-dark--force"
    hoveredClassName={hoveredClassName}
    iconName={iconName}
    iconSize={iconSize}
    onClick={onCancel}
    {...props}
  >
    {label}
  </ToolbarButton>
);

ToolbarCancelButton.propTypes = {
  hoveredClassName: PropTypes.string,
  iconName: PropTypes.string,
  iconSize: PropTypes.number,
  label: PropTypes.string,
  onCancel: PropTypes.func,
  tooltipPosition: PropTypes.string,
  tooltipContent: PropTypes.string.isRequired,
  'data-testid': PropTypes.string,
};

ToolbarCancelButton.defaultProps = {
  hoveredClassName: 'hover--danger',
  iconName: IconNames.CROSS,
  iconSize: sizes.iconSizes.EXTRA_LARGE,
  label: 'Cancel',
  onCancel: () => {},
  tooltipPosition: undefined,
  'data-testid': undefined,
};

export default ToolbarCancelButton;

import { StatusIndicator } from '@routable/gross-ds';
import React from 'react';
import { getMembershipVerificationStatusObj } from './helpers';
const CompanyRepTableStatus = ({ membership }) => {
    const { showStatus, text } = getMembershipVerificationStatusObj({
        membership,
    });
    return (React.createElement(StatusIndicator, { className: "text-xs", status: showStatus }, text));
};
export default CompanyRepTableStatus;

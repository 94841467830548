export const getUpdatePath = (stateKeyPrefix, stateKey, fieldName) => {
  let updatePath = '';

  if (stateKeyPrefix) {
    // if stateKeyPrefix exists, append it with the following "." here
    // otherwise we may end up with a leading unnecessary "."
    updatePath = `${stateKeyPrefix}.`;
  }

  if (fieldName) {
    // if field name exists, append "." and fieldName, and return
    return `${updatePath}${stateKey}.${fieldName}`;
  }

  // we already have the "." separator by this point, if stateKeyPrefix was given
  return `${updatePath}${stateKey}`;
};

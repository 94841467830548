import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { LOGIN } from 'constants/routes';

import { hasAuthToken } from 'helpers/auth';
import { renderableProp } from 'helpers/propTypes';

const Authenticated = ({ component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!hasAuthToken()) {
        return <Redirect to={`/${LOGIN}`} />;
      }

      return React.createElement(component, { ...rest, ...props });
    }}
  />
);

Authenticated.propTypes = {
  component: renderableProp.isRequired,
};

export default Authenticated;

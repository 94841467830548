import PropTypes from 'prop-types';
import React from 'react';

import { Avatar, TooltipIcon, IconNames } from 'components';

import { margin } from 'constants/styles';
import { colorGreyMed } from 'constants/styles/colors';

/**
 * Displays member's avatar, name and email in a table cell.
 * @param {ComponentProps} props
 * @param {string} props.avatar
 * @param {string} props.email
 * @param {string} props.firstName
 * @param {boolean} props.isAffiliate
 * @param {boolean} props.isBusinessRepresentative
 * @param {string} props.lastName
 * @param {string} props.name
 * @returns {StatelessComponent}
 */
const TeamMemberListNameCell = ({
  avatar,
  email,
  firstName,
  isAffiliate,
  isBusinessRepresentative,
  lastName,
  name,
}) => (
  <div className="align-cell align-items--center justify-content--space-between">
    <div className="flex-row flex-parent-truncate">
      <Avatar
        avatar={avatar}
        email={email}
        firstName={firstName}
        lastName={lastName}
        sizeClass="avatar--sm"
        styleClass="avatar--light"
      />

      <div className="margin-left--m align-self--center truncate">
        {name && <p className="font-regular semibold font-color--darkBlue remove-margin-bottom truncate">{name}</p>}
        <p
          className="font-xs regular font-color--greyXDark line-height--large remove-margin-bottom truncate"
          data-fullstory
        >
          {email}
        </p>
      </div>
    </div>

    <div className="flex-row">
      {isBusinessRepresentative && (
        <TooltipIcon
          content="This team member is your business representative"
          contentMargin={margin.small}
          icon={IconNames.BADGE}
          iconClassName="hover--blue-bold"
          iconColor={colorGreyMed}
          iconProps={{ size: 20 }}
        />
      )}

      {isAffiliate && (
        <TooltipIcon
          content="This team member is an affiliate"
          contentMargin={margin.small}
          icon={IconNames.FLAG}
          iconClassName="margin-left--m-large hover--blue-bold"
          iconColor={colorGreyMed}
        />
      )}
    </div>
  </div>
);

TeamMemberListNameCell.propTypes = {
  avatar: PropTypes.string,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  isAffiliate: PropTypes.bool,
  isBusinessRepresentative: PropTypes.bool,
  lastName: PropTypes.string,
  name: PropTypes.string,
};

TeamMemberListNameCell.defaultProps = {
  avatar: undefined,
  firstName: undefined,
  isAffiliate: undefined,
  isBusinessRepresentative: undefined,
  lastName: undefined,
  name: undefined,
};

export default TeamMemberListNameCell;

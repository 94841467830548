/**
 * @fileOverview Shared data-testid constant values for integration connection modal.
 *
 * @module constants/dataCy/integrationConnect
 */

/**
 * @type {Object<string, string>}
 */
export const integrationConnectModal = {
  BUTTON_SUBMIT: 'button_submit',
};

import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';
import { IconNames } from 'components/icon';

import { ButtonAppearance, ButtonSize } from 'constants/button';
import { colors } from 'constants/styles';
import { Intent } from 'constants/ui';

/**
 * The "next page" button in tables pagination.
 * @param {ComponentProps} props
 * @param {boolean} props.canNextPage
 * @param {Function} props.onNextPage
 * @return {StatelessComponent}
 */
const PageNavNextButton = ({ canNextPage, dataTestId, onNextPage }) => (
  <ButtonV2
    appearance={ButtonAppearance.BORDERLESS}
    dataTestId={dataTestId}
    intent={Intent.NEUTRAL}
    isDisabled={!canNextPage}
    leftIconColor={colors.colorGreyDarkHex}
    leftIconName={IconNames.CHEVRON_RIGHT}
    onClick={onNextPage}
    size={ButtonSize.SMALL}
    type="button"
  />
);

PageNavNextButton.propTypes = {
  canNextPage: PropTypes.bool,
  dataTestId: PropTypes.string,
  onNextPage: PropTypes.func.isRequired,
};

PageNavNextButton.defaultProps = {
  canNextPage: undefined,
  dataTestId: undefined,
};

export default PageNavNextButton;

import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

import { IconNames } from 'components/icon';
import { FlexRow } from 'components/layout';
import { TableCellText } from 'components/table';
import { CurrencyAmount } from 'components/text';
import { TooltipIcon } from 'components/tooltip';

import { colors, sizes } from 'constants/styles';

/**
 * Used in transactions table for transaction amount text.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 * @constructor
 */
const BalanceTransactionCurrencyCell = ({ className, description, rowData, text }) => {
  const textValue = _get(rowData, text);
  const classNameValue = _get(rowData, className);
  const descriptionValue = _get(rowData, description);

  return (
    <TableCellText className="width--100-percent truncate">
      <FlexRow className="justify-content--space-between" stackOnMobile={false}>
        <CurrencyAmount className={classNameValue}>{textValue}</CurrencyAmount>

        {!!descriptionValue && (
          <TooltipIcon
            content={descriptionValue}
            contentMargin={sizes.spacing.MEDIUM}
            icon={IconNames.INFO_SIGN}
            iconColor={colors.colorMainJordanHex}
            iconProps={{ size: sizes.iconSizes.LARGE }}
          />
        )}
      </FlexRow>
    </TableCellText>
  );
};

BalanceTransactionCurrencyCell.propTypes = {
  className: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  rowData: PropTypes.shape().isRequired,
  text: PropTypes.string.isRequired,
};

export default BalanceTransactionCurrencyCell;

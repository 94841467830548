import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import withBankAccount from 'hoc/withBankAccount';

import { fundingAccountsIsConvertingSelector } from 'selectors/fundingSelectors';

import submitConvertFundingAccountFromExternal from 'thunks/submitConvertFundingAccountFromExternal';

import AddressAccountHintSections from './AddressAccountHintSections';

const mapStateToProps = createStructuredSelector({
  isSubmittingConvertFundingAccountFromExternal: fundingAccountsIsConvertingSelector,
});

const mapDispatchToProps = {
  onSubmitConvertFundingAccountFromExternal: submitConvertFundingAccountFromExternal,
};

const enhance = compose(withBankAccount, connect(mapStateToProps, mapDispatchToProps));

export default enhance(AddressAccountHintSections);

import * as types from 'types/files';

import initialState from './initialState';

const isFetchingReducer = (state = initialState.isFetching, action) => {
  switch (action.type) {
    case types.FETCH_FILE_FOR_OBJECT_URL_REQUEST:
    case types.FETCH_FILE_FOR_DOWNLOAD_REQUEST:
      return true;

    case types.FETCH_FILE_FOR_DOWNLOAD_FAILURE:
    case types.FETCH_FILE_FOR_DOWNLOAD_SUCCESS:
    case types.FETCH_FILE_FOR_OBJECT_URL_FAILURE:
    case types.FETCH_FILE_FOR_OBJECT_URL_SUCCESS:
      return false;

    default:
      return state;
  }
};

export default isFetchingReducer;

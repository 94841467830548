import PropTypes from 'prop-types';
import React from 'react';

import { FormSection } from 'components/form';

import FilingDetailsDisclosureList from 'modules/external/ExternalCollectTaxInfo/components/FilingDetailsDisclosureList';
import MailingAddressDisclosureList from 'modules/external/ExternalCollectTaxInfo/components/MailingAddressDisclosureList';
import {
  filingDetailsDefaultProps,
  filingDetailsSelectorPropTypes,
} from 'modules/external/ExternalCollectTaxInfo/helpers/customPropTypes';

import { ReconfirmTaxesHintWithSubmit } from './components';

import './ReconfirmTaxesSection.scss';

/**
 * In the case that the user is a vendor with multiple partnerships, they may not alter their tax collection
 * information. The best they can do is review their previous submission. This section displays a hint with a button
 * which opens in the ConfirmTaxInfoModal, because the user must re-consent to eDelivery for each partnership.
 * @param filingDetails
 * @param openModal
 * @returns {*}
 * @constructor
 */
const ReconfirmTaxesSection = ({ filingDetails, openModal }) => (
  <React.Fragment>
    <FormSection>
      <FilingDetailsDisclosureList {...filingDetails} isMultiPartnerConfirm showGenericTin viewCompletionDate />
    </FormSection>

    <FormSection>
      <MailingAddressDisclosureList address={filingDetails.mailingAddress} />
    </FormSection>

    <FormSection>
      <ReconfirmTaxesHintWithSubmit onClick={openModal} partnerCompanyName={filingDetails.partnerCompanyName} />
    </FormSection>
  </React.Fragment>
);

ReconfirmTaxesSection.propTypes = {
  filingDetails: filingDetailsSelectorPropTypes(),
  openModal: PropTypes.func.isRequired,
};

ReconfirmTaxesSection.defaultProps = {
  filingDetails: filingDetailsDefaultProps,
};

export default ReconfirmTaxesSection;

import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getPayableOrReceivableText } from 'helpers/items';
import { getItemPageUrlFromItem } from 'helpers/urls';
import { submittedItemFromCreateItemFormSelector } from 'queries/itemCompoundSelectors';
export const InvoiceListViewItemCell = () => {
    const submittedItem = useSelector(submittedItemFromCreateItemFormSelector);
    const itemUrl = getItemPageUrlFromItem(submittedItem);
    const itemKindText = getPayableOrReceivableText(submittedItem);
    return (React.createElement(Link, { className: "text-xs leading-5 text-blue-40 font-medium", to: itemUrl }, `View ${itemKindText}`));
};

import { governmentIdType } from '@routable/companies-management';
import { testUUID1, testUUID2, testUUID3 } from '@routable/shared';
import { riskCheckStatus, riskCheckType, riskSummary, } from '../models';
export const testTinCheckMatch = {
    details: null,
    type: riskCheckType.Enum.tin,
    status: riskCheckStatus.Enum.passed,
};
export const testGovCheckMatch = {
    details: null,
    type: riskCheckType.Enum.gov,
    status: riskCheckStatus.Enum.passed,
};
export const testTinCheckMismatch = {
    ...testTinCheckMatch,
    status: riskCheckStatus.Enum.issues_found,
};
export const testWatchlistHit1 = {
    fields: [{ label: 'Field label', value: 'Field value' }],
    source: {
        name: 'Watchlist Hit Name',
        region: 'Watchlist Hit Region',
    },
    subjectName: 'Watchlist Subject LLC',
    url: 'https://www.watchlist-hit-source.com',
};
export const testWatchlistCheckNoHits = {
    type: 'watchlist',
    details: { hits: [] },
    status: riskCheckStatus.Enum.passed,
    updatedAt: '2024-03-14',
};
export const testWatchlistCheckSingleHit = {
    updatedAt: '2024-03-12',
    details: { hits: [testWatchlistHit1] },
    status: riskCheckStatus.Enum.issues_found,
    type: 'watchlist',
};
export const testSubject = {
    legalName: 'Vendor Legal Name',
    governmentId: {
        type: governmentIdType.Enum.ein,
        value: '123456789',
    },
};
export const testVendorRiskReportNoIssues = {
    id: testUUID2,
    createdAt: '2024-03-11',
    summary: riskSummary.Enum.passed,
    subject: testSubject,
    checks: [testTinCheckMatch, testWatchlistCheckNoHits],
    dismissal: null,
};
export const testForeignVendorRiskReportNoIssues = {
    id: testUUID3,
    createdAt: '2024-04-06',
    summary: riskSummary.Enum.passed,
    subject: testSubject,
    checks: [testGovCheckMatch, testWatchlistCheckNoHits],
    dismissal: null,
};
export const testVendorRiskReportWatchlistHits = {
    ...testVendorRiskReportNoIssues,
    summary: riskSummary.Enum.review_required,
    checks: [testTinCheckMatch, testWatchlistCheckSingleHit],
};
export const testVendorRiskReportIssuesFound = {
    ...testVendorRiskReportWatchlistHits,
    id: testUUID1,
    checks: [testTinCheckMismatch, testWatchlistCheckSingleHit],
};
export const testVendorRiskReportWatchlistHitsDimissed = {
    ...testVendorRiskReportWatchlistHits,
    summary: riskSummary.Enum.dismissed,
    checks: [testTinCheckMatch, testWatchlistCheckSingleHit],
    dismissal: {
        at: '2024-03-28',
        name: 'Michael Scott',
        memberId: testUUID1,
    },
};

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import ExternalItemsList from 'modules/external/externalItemsList';
import ExternalPrompt from 'modules/external/externalPrompt/global/ExternalPrompt';
import ExternalReceiptContainer from 'modules/external/externalReceipt/containers/ExternalReceiptContainer';
import ExternalBody from 'modules/external/global/presenters/ExternalBody';
import { IsLoadingHasWrapper } from 'modules/isLoading/IsLoading';

const ExternalPaymentBody = ({ isFetchingRequirements, itemId, onCancel, onStart, partnership }) => {
  // Loading state for fetch calls
  if (!partnership || isFetchingRequirements) {
    return <IsLoadingHasWrapper />;
  }

  const externalItemRightClasses = classNames({
    'item--right': true,
    'no-item-id': !itemId,
  });

  return (
    <ExternalBody>
      <div className="has-item">
        <ExternalPrompt onCancel={onCancel} onStart={onStart} />

        <div className={externalItemRightClasses}>
          <ExternalReceiptContainer />
        </div>
      </div>

      <ExternalItemsList />
    </ExternalBody>
  );
};

ExternalPaymentBody.propTypes = {
  isFetchingRequirements: PropTypes.bool,
  itemId: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  partnership: PropTypes.shape(),
};

ExternalPaymentBody.defaultProps = {
  isFetchingRequirements: undefined,
  itemId: undefined,
  partnership: undefined,
};

export default ExternalPaymentBody;

import { createIsUpdatingReducer } from 'store/redux';

import {
  UPDATE_EXISTING_ITEMS_REQUEST,
  UPDATE_EXISTING_ITEMS_FAILURE,
  UPDATE_EXISTING_ITEMS_SUCCESS,
} from 'types/item';

export const finishCases = [UPDATE_EXISTING_ITEMS_FAILURE, UPDATE_EXISTING_ITEMS_SUCCESS];
export const requestCases = [UPDATE_EXISTING_ITEMS_REQUEST];

const isUpdatingReducer = createIsUpdatingReducer(finishCases, requestCases);

export default isUpdatingReducer;

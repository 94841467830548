import React from 'react';
import { useSelector } from 'react-redux';
import { ButtonV2 } from 'components';
import { ButtonSize } from 'constants/button';
import { isPartnershipTypeCustomer } from 'helpers/partnerships';
import { currentCompanySettingsIsInternationalPaymentsEnabledSelector } from 'selectors/currentCompanySelectors';
const AddInternationalPaymentMethodButton = ({ onClick, partnershipType, isDisabled, }) => {
    const isInternationalPaymentsEnabled = useSelector(currentCompanySettingsIsInternationalPaymentsEnabledSelector);
    const isCustomer = isPartnershipTypeCustomer(partnershipType);
    if (isCustomer) {
        return null;
    }
    return (React.createElement(ButtonV2, { isDisabled: !isInternationalPaymentsEnabled || isDisabled, onClick: onClick, size: ButtonSize.SMALL }, "Add a payment method"));
};
export default AddInternationalPaymentMethodButton;

import { createIsFetchingReducer } from 'store/redux';

import {
  FETCH_PARTNERSHIP_FAILURE,
  FETCH_PARTNERSHIP_ITEMS_REQUEST,
  FETCH_PARTNERSHIP_ITEMS_SUCCESS,
} from 'types/partnership';

/**
 * Action types representing request cases
 * @constant
 * @type {string[]}
 */
const requestCases = [FETCH_PARTNERSHIP_ITEMS_REQUEST];

/**
 * Action types representing request finish cases
 * @constant
 * @type {string[]}
 */
const finishCases = [FETCH_PARTNERSHIP_FAILURE, FETCH_PARTNERSHIP_ITEMS_SUCCESS];

/**
 * Partnership Items isFetching state
 * @type {ReduxReducer}
 */
const partnershipItemsIsFetchingReducer = createIsFetchingReducer(finishCases, requestCases);

export { finishCases, requestCases };
export default partnershipItemsIsFetchingReducer;

import { Dialog, Button } from '@routable/components';
import React from 'react';
export const ManuallyMoveToVerifiedDialog = ({ holdPayables, isLoading = false, onConfirm, state, }) => {
    return (React.createElement(Dialog, { footer: React.createElement(Dialog.Footer, null,
            React.createElement(Button, { "data-testid": "dismiss-hits-dialog--cancel", onPress: state.close, variant: "outline" }, "Cancel"),
            React.createElement(Button, { "data-testid": "dismiss-hits-dialog--confirm", loading: isLoading, onPress: onConfirm }, "Manually move to verified")), header: React.createElement(Dialog.Header, { label: "Manually move this vendor to verified" }), state: state },
        React.createElement("span", null,
            "Manually moving this vendor to verified will ignore all of the flagged compliance issues",
            holdPayables ? ', allow payments to initiate to this vendor,' : '',
            " and move this vendor to a 'Verified' status.")));
};

import { combineReducers } from 'redux';

import isUpdatingReducer from './isUpdatingReducer';
import lastUpdatedReducer from './lastUpdatedReducer';

const updateSettingsReducer = combineReducers({
  isUpdating: isUpdatingReducer,
  lastUpdated: lastUpdatedReducer,
});

export default updateSettingsReducer;

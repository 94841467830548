import styled, { css } from 'styled-components';
const widthTransition = css `
  &:not(.no-transition) {
    transition: width 0.3s;
  }
`;
export const Container = styled.div `
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
`;
export const LeftPanel = styled.div `
  height: 100%;
  display: block;
  box-sizing: border-box;
  background-color: var(--color-neutral-white);
  ${widthTransition}
`;
export const RightPanel = styled.div `
  display: block;
  height: 100%;
  box-sizing: border-box;
  background-color: var(--color-neutral-white);
`;
export const HorizontalBar = styled.div `
  height: 100%;
  cursor: ew-resize;
  position: relative;
  z-index: 100;
  display: flex;
  flex-wrap: nowrap;

  .resizable-handle svg {
    fill: var(--color-grey60);
    transition: fill 0.2s;
  }

  &:hover {
    & .resize-h-bar-middle {
      background-color: var(--color-grey20);
      box-shadow: rgba(60, 101, 241, 0.2) 0px 0px 8px 1px;
    }

    .resizable-handle svg {
      fill: var(--color-blue40);
    }
  }

  // The bar is resizing so handle the grip as well.
  &.resizing-panels .resizable-handle > button {
    cursor: grabbing;
  }

  &.hidden {
    display: none;
  }
`;
export const HorizontalBarLeft = styled.div.attrs({
    className: 'resize-h-bar-left',
}) `
  position: absolute;
  top: 0px;
  left: -6px;
  background-color: rgba(255, 255, 255, 0);
  height: 100%;
  width: 6px;
`;
export const HorizontalBarMiddle = styled.div.attrs({
    className: 'resize-h-bar-middle',
}) `
  background-color: var(--color-grey10);
  transition: background-color 0.2s;
  flex: 0 0 1px;
`;
export const HorizontalBarRight = styled.div.attrs({
    className: 'resize-h-bar-right',
}) `
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 100%;
  position: absolute;
  width: 6px;
`;
export const DragIndicator = styled.div `
  width: 16px;
  height: 30px;
  padding: 1px 8px 1px 8px;
  border-radius: 4px;
  background-color: var(--color-neutral-white);
  border: 1px solid var(--color-grey20);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  & button {
    position: absolute;
    left: 0px;
    top: 6px;
  }
  &.hide-drag {
    display: none;
  }
`;

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import { SelectFieldV2 } from 'components';

import { FundingSourceProviderSubClasses } from 'constants/funding';
import { modalNameConnectBankManual } from 'constants/modals';
import { field } from 'constants/styles/formStyles';
import { BankAccountViewType } from 'constants/ui';

import { requiredValidator } from 'helpers/fieldValidation';
import { isFundingSourceInvalidOrMicroDepositState } from 'helpers/funding';
import { doesMemberInclude } from 'helpers/utility';

import ConnectBankContainer from 'modules/connectBank/global/ConnectBankContainer';
import BankAccount from 'modules/fundingAccount/bankAccount';
import { IsLoadingComponent } from 'modules/isLoading/IsLoading';

import { fundingAccountsAllForProviderSubClassSelector } from 'queries/fundingCompoundSelectors';

import {
  fundingAccountForIdSelector,
  fundingAccountBankTokenIsSubmittingSelector,
  fundingSourcesAllValuesSelector,
} from 'selectors/fundingSelectors';
import { modalIsOpenSelector } from 'selectors/modalsSelector';

class PartnerCompanyConnectBank extends React.Component {
  state = {
    displayConnectBank: false,
  };

  componentDidUpdate(prevProps) {
    const { fundingAccountsWithAchFundingSource } = prevProps;
    const { connectBankModalOpen, fundingAccountsWithAchFundingSource: nextFundingAccountsWithAchFundingSource } =
      this.props;

    const currentAchAccounts = fundingAccountsWithAchFundingSource.length;
    const nextAchAccounts = nextFundingAccountsWithAchFundingSource.length;

    if (currentAchAccounts < nextAchAccounts && !connectBankModalOpen) {
      this.setState({ displayConnectBank: false });
    }
  }

  handleDisplayConnectBank = () => {
    this.setState({ displayConnectBank: true });
  };

  renderBankAccountSelectField = () => {
    const { fieldNamePrefix, fundingAccountsWithAchFundingSource } = this.props;

    if (fundingAccountsWithAchFundingSource.length <= 1) {
      return null;
    }

    return (
      <div className="form-control">
        <Field
          component={SelectFieldV2}
          isClearable={false}
          name={`${fieldNamePrefix || ''}fundingAccount`}
          props={{
            className: field.xl.full,
            dataFullStory: true,
            displayFieldName: 'name',
            options: fundingAccountsWithAchFundingSource,
            label: 'Bank account',
            isRequired: true,
            valueKey: 'id',
          }}
          validate={requiredValidator}
        />
      </div>
    );
  };

  renderSingleBankAccount = () => {
    const { fundingAccount: selectedFundingAccount, fundingAccountsWithAchFundingSource, fundingSources } = this.props;

    if (!selectedFundingAccount) {
      return null;
    }

    // Check if any of the selected funding account funding sources is invalid
    // or pending micro deposit. If so, we want to set the "startOpen" state
    // of the BankAccount component to true (auto-expand by default).
    const shouldStartOpen = fundingSources
      .filter(({ id }) => selectedFundingAccount.fundingSources?.includes(id))
      .some(isFundingSourceInvalidOrMicroDepositState);

    // check if the currently selected funding account is in the funding accounts
    // with ach source. when we switch from check to ach or vice versa, the
    // selected funding account is automatically updated to an account of that type,
    // which is not always complete the first time this render method is called
    const isSelectedAccountInAchFundingSourceAccounts = doesMemberInclude(
      fundingAccountsWithAchFundingSource,
      selectedFundingAccount.id,
      { searchKey: 'id' },
    );

    if (!isSelectedAccountInAchFundingSourceAccounts) {
      return null;
    }

    return (
      <BankAccount
        className="partner-company-connect-bank"
        fundingAccount={selectedFundingAccount}
        startOpen={shouldStartOpen}
        viewType={BankAccountViewType.SIMPLE_VERIFY}
      />
    );
  };

  renderConnectBank = () => {
    const { connectBankModalOpen, fundingAccountsWithAchFundingSource } = this.props;
    const { displayConnectBank } = this.state;

    if (fundingAccountsWithAchFundingSource.length > 0 && !displayConnectBank && !connectBankModalOpen) {
      return null;
    }

    return <ConnectBankContainer createCheckSource={false} skipSucessToast />;
  };

  renderAddBankAccount = () => {
    const { fundingAccountsWithAchFundingSource } = this.props;
    const { displayConnectBank } = this.state;

    if (fundingAccountsWithAchFundingSource.length === 0) {
      return null;
    }

    if (displayConnectBank) {
      return null;
    }

    return (
      <a
        className="font-xs semibold primary remove-hover-underline display--block"
        onClick={this.handleDisplayConnectBank}
        role="presentation"
      >
        <span className="font-xs icon-ic-plus-full margin-right--sm" />
        <span>Add another bank account</span>
      </a>
    );
  };

  render() {
    const { isSubmittingToken } = this.props;

    if (isSubmittingToken) {
      return <IsLoadingComponent hasShadow text="Loading your bank account" />;
    }

    return (
      <React.Fragment>
        {this.renderBankAccountSelectField()}
        {this.renderSingleBankAccount()}
        {this.renderConnectBank()}
        {this.renderAddBankAccount()}
      </React.Fragment>
    );
  }
}

PartnerCompanyConnectBank.propTypes = {
  connectBankModalOpen: PropTypes.bool,
  fieldNamePrefix: PropTypes.string,
  fundingAccount: PropTypes.shape(),
  fundingAccountsWithAchFundingSource: PropTypes.arrayOf(PropTypes.shape),
  fundingSources: PropTypes.arrayOf(PropTypes.shape),
  isSubmittingToken: PropTypes.bool,
};

PartnerCompanyConnectBank.defaultProps = {
  connectBankModalOpen: undefined,
  fieldNamePrefix: undefined,
  fundingAccount: undefined,
  fundingAccountsWithAchFundingSource: [{}],
  fundingSources: [],
  isSubmittingToken: undefined,
};

const mapStateToProps = (state, ownProps) => {
  const fundingAccount = fundingAccountForIdSelector(state, ownProps.fundingAccount);

  return {
    fundingAccount,
    fundingAccountsWithAchFundingSource: fundingAccountsAllForProviderSubClassSelector(
      state,
      FundingSourceProviderSubClasses.ACH,
    ),
    fundingSources: fundingSourcesAllValuesSelector(state),
    isSubmittingToken: fundingAccountBankTokenIsSubmittingSelector(state),
    connectBankModalOpen: modalIsOpenSelector(state, modalNameConnectBankManual),
  };
};

export { PartnerCompanyConnectBank };
export default connect(mapStateToProps)(PartnerCompanyConnectBank);

import PropTypes from 'prop-types';
import React from 'react';

import { InteractionBar } from 'components';

import { PartnershipTypes } from 'constants/partnership';

import { componentHelpers } from './helpers';

/**
 * The add/edit manager bar for a contact.
 * @param {ComponentProps} props
 * @param {PartnershipMember[]|ItemMember[]} props.contacts
 * @param {PartnershipMember|ItemMember} props.importedContact
 * @param {RestOfProps} props.rest
 * @return {StatelessComponent}
 */
const ContactsInteractionBar = (props) => {
  const { formType, contacts } = props;
  const isVendorContactOptional =
    formType === PartnershipTypes.VENDOR && !contacts.length;
  const barProps = componentHelpers.getBarPropsForContactsInteractionBarInner({
    ...props,
    isVendorContactOptional,
  });

  return <InteractionBar {...barProps} />;
};

ContactsInteractionBar.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.shape({})),
  importedContact: PropTypes.shape({}),
  ledger: PropTypes.shape({}),
  renderBarButtonLeft: PropTypes.func,
  renderBarButtonRight: PropTypes.func,
};

ContactsInteractionBar.defaultProps = {
  contacts: [],
  importedContact: undefined,
  ledger: undefined,
  renderBarButtonLeft: undefined,
  renderBarButtonRight: undefined,
};

export default ContactsInteractionBar;

import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';

import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';

import { oneOfValuesFromObject } from 'helpers/propTypes';

import TooltipMUI from '../TooltipMUI';

/**
 * Renders a button which on hover triggers a tooltip
 * @param {ComponentProps} props
 * @param {String} [props.padding=TooltipPadding.X_SKINNY]
 * @param {String} [props.placement=TooltipPlacement.TOP]
 * @param {String|Node} props.title
 * @param {Object} [props.tooltipProps]
 * @param {RestOfProps} rest
 * @return {StatelessComponent}
 */
const TooltipMUIButton = ({ padding, placement, title, tooltipProps, ...rest }) => (
  <TooltipMUI padding={padding} placement={placement} title={title} {...tooltipProps}>
    <ButtonV2 {...rest} />
  </TooltipMUI>
);

TooltipMUIButton.propTypes = {
  padding: oneOfValuesFromObject(TooltipPadding),
  placement: oneOfValuesFromObject(TooltipPlacement),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  tooltipProps: PropTypes.shape({}),
  ...ButtonV2.propTypes,
};

TooltipMUIButton.defaultProps = {
  padding: TooltipPadding.X_SKINNY,
  placement: TooltipPlacement.TOP,
  tooltipProps: {},
};

export default TooltipMUIButton;

import PropTypes from 'prop-types';
import React from 'react';

import { getClassNames } from 'helpers/ui';

/**
 * Table header row component for the invoice generator ledger field tables.
 * @param {*} children
 * @param {RestOfProps} rest
 * @return {StatelessComponent}
 */
const InvoiceGeneratorTableHeaderRow = ({ children, ...rest }) => (
  <li
    {...rest}
    className={getClassNames(rest, {
      'table-row--header': true,
    })}
  >
    {children}
  </li>
);

InvoiceGeneratorTableHeaderRow.propTypes = {
  children: PropTypes.node,
};

InvoiceGeneratorTableHeaderRow.defaultProps = {
  children: undefined,
};

export default InvoiceGeneratorTableHeaderRow;

import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { ProtectedRouteWarn } from 'components/routing';
import * as text from 'components/routing/ProtectedRouteWarnInvoice/text';

import { DASHBOARD, LOGOUT } from 'constants/routes';

import { asPath, getJoinedPath, getNextRouteQuery } from 'helpers/routeHelpers';
import { getUrlWithoutOrigin } from 'helpers/urls';

/**
 * The fallback component to render, should access be denied for an item.
 *
 * @param history {History}
 * @returns {*}
 * @constructor
 */
const ProtectedRouteWarnInvoice = ({ history }) => {
  const onSwitchAccounts = () => {
    const nextRoute = getJoinedPath(LOGOUT, getNextRouteQuery(getUrlWithoutOrigin()));
    history.push(nextRoute);
  };

  return (
    <ProtectedRouteWarn
      actionText={text.protectedRouteActionText}
      message={text.protectedRouteWarnMessage}
      onPrimaryAction={onSwitchAccounts}
      returnText={text.protectedRouteReturnText}
      returnUrl={asPath(DASHBOARD)}
      title={text.protectedRouteWarnTitle}
    />
  );
};

ProtectedRouteWarnInvoice.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(ProtectedRouteWarnInvoice);

import React from 'react';
import { ListEmpty, SettingsTable } from 'components';
import { TABLE_EMPTY_STATE } from 'constants/images';
import { showSwal } from 'helpers/swal';
import { hasLength } from 'helpers/utility';
import { RevokeInviteConfirmationModal } from 'modules/dashboard/settings/account/teamMembers/components/TeamMembersPage/components/TeamMembersPageSettingsTableRouter/components/InvitedTeamMembersSettingsTable/components';
import { TeamMembersInvitesTableData } from './config';
import { Container } from './InvitedMembersTable.styles';
import {} from './InvitedMembersTable.types';
const InvitedMembersTable = ({ pendingMembershipInvites, isFetchingInvites, onResendInvite, isRevokingInvite, onRevokeInvite, }) => {
    const hasMembers = hasLength(pendingMembershipInvites);
    const openRevokeInviteModal = (data) => showSwal({
        Content: (React.createElement(RevokeInviteConfirmationModal, { contentClassname: "", isLoading: isRevokingInvite, membership: data, onConfirm: () => onRevokeInvite(data), warningIcon: true })),
    });
    return (React.createElement(Container, null,
        hasMembers && (React.createElement(SettingsTable, { data: pendingMembershipInvites, isLoadingTable: isFetchingInvites, onResendInvite: onResendInvite, onRevokeInvite: openRevokeInviteModal, tableConfig: TeamMembersInvitesTableData })),
        !isFetchingInvites && !hasMembers && (React.createElement(ListEmpty, { image: TABLE_EMPTY_STATE, message: "You have not invited any team members." }))));
};
export default InvitedMembersTable;

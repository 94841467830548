import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';

import * as types from 'types/partnership';

const getAllPartnershipFundingAccounts = (partnershipFundingAccount) => {
  if (!partnershipFundingAccount) {
    return [];
  }

  return Object.keys(partnershipFundingAccount);
};

const allIdsReducer = (state = [], action) => {
  switch (action.type) {
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
      return getAllPartnershipFundingAccounts(action.payload.fundingAccount);

    case types.FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_SUCCESS:
      return Array.from(
        new Set([...state, ...getAllPartnershipFundingAccounts(action.payload.partnershipFundingAccount)]),
      );

    default:
      return state;
  }
};

export default allIdsReducer;

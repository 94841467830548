import React from 'react';
import DynamicTextBrowser from '../components/DynamicTextBrowser';
import SimpleImage from '../components/SimpleImage';
import { auxiliaryComponents } from '../constants/auxiliaryComponents';
export const componentSwitcher = (component) => {
    switch (component) {
        case auxiliaryComponents.BROWSER:
            return React.createElement(DynamicTextBrowser, null);
        case auxiliaryComponents.IMAGE:
            return React.createElement(SimpleImage, null);
        default:
            return React.createElement(SimpleImage, null);
    }
};

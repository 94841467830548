export var FilterOperator;
(function (FilterOperator) {
    FilterOperator["BETWEEN"] = "BETWEEN";
    FilterOperator["EQUALS"] = "EQUALS";
    FilterOperator["GREATER_THAN_OR_EQUAL"] = "GTE";
    FilterOperator["EXACT_MATCH_IN_COMMA_SEPARATED_LIST"] = "IS_EXACT_IN_CSL";
    FilterOperator["CONTAIN_MATCH_IN_COMMA_SEPARATED_LIST"] = "IS_CONTAINS_IN_CSL";
    FilterOperator["STARTS_WITH_MATCH_IN_COMMA_SEPARATED_LIST"] = "IS_STARTS_WITH_IN_CSL";
    FilterOperator["ENDS_WITH_MATCH_IN_COMMA_SEPARATED_LIST"] = "IS_ENDS_WITH_IN_CSL";
    FilterOperator["IS_IN_SET"] = "IS_IN_SET";
    FilterOperator["LESS_THAN_OR_EQUAL"] = "LTE";
})(FilterOperator || (FilterOperator = {}));

import _capitalize from 'lodash/capitalize';
import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'components/icon';
import { currentCompanySettingsIsInternationalPaymentsEnabledSelector } from 'selectors/currentCompanySelectors';
import { PaymentMethodStatusType } from '../../enums';
import { PaymentMethodStatusMap } from './constants';
const PaymentMethodStatus = ({ currencyCode, status }) => {
    const statusEntry = PaymentMethodStatusMap[status];
    const isInternationalPaymentsEnabled = useSelector(currentCompanySettingsIsInternationalPaymentsEnabledSelector);
    if (!statusEntry) {
        return null;
    }
    const statusText = status === PaymentMethodStatusType.PRIMARY && isInternationalPaymentsEnabled
        ? `${_capitalize(status)} for ${currencyCode}`
        : statusEntry.text;
    return (React.createElement("div", { className: `payment_method__status-container payment_method__status-container--${statusEntry.theme}` },
        React.createElement(Icon, { icon: statusEntry.icon }),
        React.createElement("span", { className: "payment_method__status-label" }, statusText)));
};
export default PaymentMethodStatus;

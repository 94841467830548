import { SubmissionError } from 'redux-form';

/**
 * Submits a balance transaction (deposit/withdraw).
 * @param {ReduxFormValues} values
 * @param {Dispatch} dispatch
 * @param {ComponentProps} props
 * @throws
 * @return {Promise<boolean|SubmissionError>}
 */
export const balanceTransactionSubmit = async (values, dispatch, props) => {
  const { onSubmitBalanceTransaction } = props;

  const result = await onSubmitBalanceTransaction(values);

  if (result && result.errors) {
    throw new SubmissionError(result.errors.fields);
  }

  return true;
};

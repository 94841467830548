import PropTypes from 'prop-types';
import React from 'react';

const CancelLink = ({ onCancel }) => (
  <div className="form-control">
    <a className="a--xs dark-grey remove-hover-underline hover-negative" onClick={onCancel} role="presentation">
      <span className="icon-ic-x-out" />
      Cancel
    </a>
  </div>
);

CancelLink.propTypes = {
  onCancel: PropTypes.func,
};

CancelLink.defaultProps = {
  onCancel: () => {},
};

export default CancelLink;

import _cloneDeep from 'lodash/cloneDeep';

import { fetchItemsRoutine, sendSubmitBillToItemRequestRoutine, submitItemRoutine } from 'actions/routines/item';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { allKeys } from 'helpers/utility';

import { SUBMIT_UPLOAD_BILL_SUCCESS } from 'types/bills';
import { SUBMIT_EXISTING_ITEMS_SUCCESS } from 'types/item';
import { SUBMIT_MESSAGE_SUCCESS } from 'types/messages';
import { FETCH_PARTNERSHIP_ITEMS_SUCCESS } from 'types/partnership';
import { FETCH_THREADS_SUCCESS, FETCH_THREAD_SUCCESS } from 'types/thread';

/**
 * Adds the messageId to the thread messages array in redux
 * @param {object} threadsByIdState - Redux state for threads byId
 * @param {string} messages - Messages returned from the submit message request
 */
const addMessage = (threadsByIdState, messages) => {
  const threadList = _cloneDeep(threadsByIdState);

  if (!messages) {
    return threadList;
  }

  allKeys(messages).forEach((messageId) => {
    if (messages[messageId].relationships && messages[messageId].relationships.thread) {
      const threadId = messages[messageId].relationships.thread.data.id;
      threadList[threadId] = {
        ...threadsByIdState[threadId],
        messages: threadsByIdState[threadId].messages.concat(messageId),
      };
    }
  });

  return threadList;
};

const threadsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case fetchItemsRoutine.SUCCESS:
    case FETCH_PARTNERSHIP_ITEMS_SUCCESS:
    case FETCH_THREADS_SUCCESS:
    case FETCH_THREAD_SUCCESS:
    case SUBMIT_UPLOAD_BILL_SUCCESS:
    case SUBMIT_EXISTING_ITEMS_SUCCESS:
    case sendSubmitBillToItemRequestRoutine.SUCCESS:
    case submitItemRoutine.FULFILL:
      return {
        ...state,
        ...getObjectsByIdWithRelationships(action.payload.thread, ['messages', 'members', 'item']),
      };

    case SUBMIT_MESSAGE_SUCCESS:
      return addMessage(state, action.payload.message);

    default:
      return state;
  }
};

export default threadsByIdReducer;

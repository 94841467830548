import { combineReducers } from 'redux';

import allFundingSourcesReducer from './allFundingSourcesReducer';
import errorReducer from './errorReducer';
import fetchReducer from './fetchReducer';
import fundingSourcesByIdReducer from './fundingSourcesByIdReducer';
import lastUpdatedReducer from './lastUpdatedReducer';
import lastVerifiedFundingSourceReducer from './lastVerifiedFundingSourceReducer';

const fundingSourcesReducer = combineReducers({
  allIds: allFundingSourcesReducer,
  byId: fundingSourcesByIdReducer,
  errors: errorReducer,
  isFetching: fetchReducer,
  lastUpdated: lastUpdatedReducer,
  lastVerifiedFundingSource: lastVerifiedFundingSourceReducer,
});

export default fundingSourcesReducer;

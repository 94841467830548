export const storeAccessor = {
    getState: () => ({}),
    dispatch: (() => ({})),
};
export const initStoreAccessor = (store) => {
    storeAccessor.dispatch = store.dispatch;
    storeAccessor.getState = () => {
        const state = store.getState();
        Object.freeze(state);
        return state;
    };
};

import { useEffect, useRef, useState } from 'react';
import { RoutableEvents } from '../../index';
export const useEvent = (eventName, fn, initialData) => {
    const fnRef = useRef(fn);
    const isMounted = useRef(false);
    fnRef.current = fn;
    const [returnData, setReturnData] = useState(initialData);
    useEffect(() => {
        const watchListener = async (ev) => {
            if (ev) {
                if (isMounted.current) {
                    const awaitedValue = await fnRef.current(ev?.detail?.data ?? ev?.detail ?? ev);
                    if (isMounted.current) {
                        setReturnData(awaitedValue);
                    }
                }
            }
        };
        if (!isMounted.current) {
            isMounted.current = true;
            RoutableEvents.Subscribe(eventName, watchListener);
        }
        return () => {
            isMounted.current = false;
            RoutableEvents.Dispose(eventName);
        };
    }, [eventName]);
    return returnData;
};
export const useDispatchEvent = () => RoutableEvents.Publish;

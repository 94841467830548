import _isEqual from 'lodash/isEqual';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as reduxForm from 'redux-form';

import { isDefined } from 'helpers/utility';

import { createFormFieldSelectors } from 'selectors/createFormSelectors';

/**
 * Returns data accessors and an onChange handler for any redux-form field,
 * given the form name, field name, and optionally, an override function for
 * the onChange handler (doesn't affect the Field component's normal
 * onChange callback, only the callback returned by this hook).
 * @param {Object} options
 * @param {ImmutableString} options.fieldName
 * @param {ImmutableString} options.formName
 * @param {ImmutableFunction|ImmutableUndefined} [options.handleChange]
 * @return {Object}
 */
const useField = ({ fieldName, formName, handleChange = undefined }) => {
  const dispatch = useDispatch();

  const fieldSelectors = createFormFieldSelectors(formName, fieldName);

  const changeCallback = React.useCallback(
    (evtOrValue) => {
      const defaultChangeHandler = () => {
        let value = evtOrValue;

        if (evtOrValue && evtOrValue.target) {
          value = isDefined(evtOrValue.target.checked) ? evtOrValue.target.checked : evtOrValue.target.value;
        }

        return dispatch(reduxForm.change(formName, fieldName, value));
      };

      if (typeof handleChange === 'function') {
        // if we've been given custom onChange behavior, call it and return
        return handleChange(defaultChangeHandler, evtOrValue, formName, fieldName);
      }

      return defaultChangeHandler();
    },
    [dispatch, fieldName, formName, handleChange],
  );

  return {
    asyncErrors: useSelector(fieldSelectors.fieldAsyncErrorsSelector, _isEqual),
    initialValue: useSelector(fieldSelectors.fieldInitialValueSelector, _isEqual),
    meta: useSelector(fieldSelectors.fieldMetaSelector, _isEqual),
    name: fieldName,
    onChange: changeCallback,
    syncErrors: useSelector(fieldSelectors.fieldSyncErrorsSelector, _isEqual),
    value: useSelector(fieldSelectors.fieldValueSelector, _isEqual),
  };
};

export default useField;

import { getModalReducerOpenAction } from 'store/redux';
import { MODAL_ADD_INTERNATIONAL_PAYMENT_CLOSE, MODAL_ADD_INTERNATIONAL_PAYMENT_OPEN } from 'types/modals';
export const initialState = {
    open: false,
    partnershipId: null,
    formContext: null,
};
const addInternationalPaymentMethodModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case MODAL_ADD_INTERNATIONAL_PAYMENT_CLOSE:
            return initialState;
        case MODAL_ADD_INTERNATIONAL_PAYMENT_OPEN:
            return getModalReducerOpenAction(state, action);
        default:
            return state;
    }
};
export default addInternationalPaymentMethodModalReducer;

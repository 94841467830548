import { sidePanelNameEditCompanyGeneralInfo } from 'constants/sidePanels';
import * as types from 'types/sidePanels';
import {} from './editCompanyGeneralInfoReducer.types';
const initialState = {
    open: false,
    partnershipId: undefined,
};
const editCompanyGeneralInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SIDE_PANEL_OPEN: {
            return action.payload.name === sidePanelNameEditCompanyGeneralInfo
                ? { ...state, ...action.payload.state, open: true }
                : state;
        }
        case types.SIDE_PANEL_CLOSE: {
            return action.payload.name === sidePanelNameEditCompanyGeneralInfo
                ? { ...state, partnershipId: undefined, open: false }
                : state;
        }
        default:
            return state;
    }
};
export { initialState };
export default editCompanyGeneralInfoReducer;

import PropTypes from 'prop-types';
import React from 'react';

import { Tag } from 'components/tag';
import { CurrencyAmount } from 'components/text';

import { TagType } from 'constants/ui';

import { text } from 'modules/dashboard/settings/account/balance/constants';

const BalanceAmountPendingChip = ({ amount, formatOptions }) => (
  <div className="balance-block--chip-wrapper">
    <Tag className="balance-block--chip" hasIcon={false} isClosable={false} readOnly type={TagType.WARNING}>
      <span className="balance-block--chip--text">
        <span className="balance-block--chip--text--label">{text.balanceAmountBlock.pendingChip}</span>
        <CurrencyAmount formatOptions={formatOptions}>{amount}</CurrencyAmount>
      </span>
    </Tag>
  </div>
);

BalanceAmountPendingChip.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  formatOptions: PropTypes.shape(),
};

BalanceAmountPendingChip.defaultProps = {
  amount: undefined,
  formatOptions: {},
};

export default BalanceAmountPendingChip;

import PropTypes from 'prop-types';
import React from 'react';

import Tag from 'components/tag/Tag';

import { TagType } from 'constants/ui';

/**
 * A coming soon tag to be shown in components or features that have not yet been released.
 * @param {ComponentProps} props
 * @param {Boolean} props.isReleased
 * @returns {StatelessComponent}
 */
const ComingSoonTag = ({ isReleased }) => {
  if (isReleased) {
    return null;
  }

  return (
    <Tag hasIcon={false} isClosable={false} readOnly type={TagType.ARCHIVED}>
      Coming soon
    </Tag>
  );
};

ComingSoonTag.propTypes = {
  isReleased: PropTypes.bool.isRequired,
};

export default ComingSoonTag;

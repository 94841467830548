import React from 'react';

import { reduxFormFieldDefaultProps, reduxFormFieldPropTypes } from 'components/commonProps';
import { ReduxFormFieldWrapper } from 'components/reduxForm';

import TagMultiSelect from './TagMultiSelect';

const ReduxFormTagMultiSelect = ({ input, meta, ...rest }) => (
  <ReduxFormFieldWrapper {...rest} Component={TagMultiSelect} input={input} meta={meta} />
);

ReduxFormTagMultiSelect.propTypes = {
  ...reduxFormFieldPropTypes,
};

ReduxFormTagMultiSelect.defaultProps = {
  ...reduxFormFieldDefaultProps,
};

export default ReduxFormTagMultiSelect;

import { apiCall } from '@routable/framework';
import { getCountriesModel } from './Countries.models';
const generateCountriesApi = (api) => ({
    getCountries: () => api({
        camelCaseResponse: true,
        url: `data/reference/countries`,
        method: 'get',
        responseModel: getCountriesModel,
    }),
});
export const countriesApi = generateCountriesApi(apiCall);

import { SearchInput } from '@routable/gross-ds';
import { useEventSync } from '@routable/framework';
import _debounce from 'lodash/debounce';
import React, { useRef } from 'react';
export const ModalLookupTableSearch = ({ searchPlaceHolderText = 'Search', setSearch: onSearch, }) => {
    const debouncedSearch = _debounce((query) => onSearch(query), 300);
    const buttonRef = useRef(null);
    useEventSync('clear-modal-lookup-table-search', () => {
        if (buttonRef?.current) {
            buttonRef.current.click();
        }
    });
    return (React.createElement(SearchInput, { "aria-label": searchPlaceHolderText, buttonRef: buttonRef, "data-testid": "modal-lookup:search", onChange: debouncedSearch, onClear: () => onSearch(''), placeholder: searchPlaceHolderText, size: "large" }));
};

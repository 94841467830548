import clsx from 'clsx';
import React from 'react';
import { SETTINGS_ACCOUNT_TEAM_MEMBERS_ROUTE } from 'constants/routes';
const RestrictedPermissionContent = ({ isTooltip = true }) => {
    const actionContext = isTooltip ? 'take this action' : 'view this page';
    return (React.createElement("span", { className: clsx({
            'text-sm': !isTooltip,
        }) },
        `You do not have permission to ${actionContext}. Review `,
        React.createElement("a", { className: "primary", href: SETTINGS_ACCOUNT_TEAM_MEMBERS_ROUTE }, "your permissions"),
        ` or contact a company administrator for access.`));
};
export default RestrictedPermissionContent;

import { ComboBox } from '@routable/components';
import PropTypes from 'prop-types';
import React from 'react';

import { reduxFormInputPropType } from 'components/commonProps';
import { AsyncSelect } from 'components/selectV2';

import { useDebouncedField } from 'hooks';

/**
 * A wrapper around our native select field to debounce value updates.
 * @param {ComponentProps} props
 * @param {number|undefined} props.debounceDelay
 * @param {Object|undefined} props.formState
 * @param {ReduxFormInput|undefined} props.input
 * @param {boolean|undefined} props.isAsync
 * @param {boolean|undefined} props.isDebounced
 * @param {string|undefined} props.name
 * @param {Function|undefined} props.onInputChange
 * @return {FunctionComponent}
 */
const DebouncedComboBoxField = (props) => {
  const { debounceDelay, input, isAsync, isDebounced, ...rest } = props;

  const { onChange, value } = useDebouncedField({
    debounceDelay,
    isDebounced,
    input,
  });

  // onLookupChange ->
  const SelectComponent = isAsync ? AsyncSelect : ComboBox;

  return <SelectComponent {...rest} input={{ ...input, onChange, value }} />;
};

DebouncedComboBoxField.propTypes = {
  debounceDelay: PropTypes.number,
  input: reduxFormInputPropType.isRequired,
  isAsync: PropTypes.bool,
  isDebounced: PropTypes.bool,
};

DebouncedComboBoxField.defaultProps = {
  debounceDelay: undefined,
  isAsync: undefined,
  isDebounced: undefined,
};

export default DebouncedComboBoxField;

import _get from 'lodash/get';

import {
  AcceptingMembershipInvitePageErrorPath,
  AcceptingMembershipInvitePageErrorType,
} from 'constants/authentication';

import { anyValues, isEqual, or } from 'helpers/utility';

/**
 * Is the value passed a generic error while accepting the MembershipInvite?
 *
 * @param {*} value
 * @returns {boolean}
 */
export const isAcceptingMembershipInvitePageErrorTypeGenericError = (value) =>
  isEqual(value, AcceptingMembershipInvitePageErrorType.GENERIC);

/**
 * Is the value passed a form error while accepting the MembershipInvite?
 *
 * @param {*} value
 * @returns {boolean}
 */
export const isAcceptingMembershipInvitePageErrorTypeFormError = (value) =>
  isEqual(value, AcceptingMembershipInvitePageErrorType.FORM);

/**
 * Is the value passed a 404 not found error while accepting the MembershipInvite?
 * @param {*} value
 * @returns {boolean}
 */
export const isAcceptingMembershipInvitePageErrorTypeNotFound = (value) =>
  isEqual(value, AcceptingMembershipInvitePageErrorType.NOT_FOUND);

/**
 * Is the value passed an unknown error while accepting the MembershipInvite?
 * @param {*} value
 * @returns {boolean}
 */
export const isAcceptingMembershipInvitePageErrorTypeUnknown = (value) =>
  isEqual(value, AcceptingMembershipInvitePageErrorType.UNKNOWN);

/**
 * Given an errors object from the backend when helping a user accept a MembershipInvite, return the information for the
 * form errors.
 *
 * @param {ObjectMaybe} [errors={}]
 * @returns {ObjectMaybe}
 */
export const getMembershipInviteFormErrors = (errors = {}) =>
  _get(errors, AcceptingMembershipInvitePageErrorPath[AcceptingMembershipInvitePageErrorType.FORM]);

/**
 * Given an errors object from the backend when helping a user accept a MembershipInvite, return the information
 * at the details key.
 *
 * @param {ObjectMaybe} [errors={}]
 * @returns {StringMaybe}
 */
export const getMembershipInviteGenericErrors = (errors = {}) =>
  _get(errors, AcceptingMembershipInvitePageErrorPath[AcceptingMembershipInvitePageErrorType.GENERIC]);

/**
 * Given an errors object from the backend when helping a user accept a MembershipInvite, return the information at the
 * deeply needed notFound key.
 *
 * @param {ObjectMaybe} [errors={}]
 * @returns {string[]|undefined}
 */
export const getMembershipInviteNotFoundErrors = (errors = {}) =>
  _get(errors, AcceptingMembershipInvitePageErrorPath[AcceptingMembershipInvitePageErrorType.NOT_FOUND]);

/**
 * Based on where the errors are in the error object, return the error type.
 *
 * @param {ObjectMaybe} [errors]
 * @returns {undefined|AcceptingMembershipInvitePageErrorType}
 */
export const getAcceptingMembershipInvitePageErrorType = (errors) => {
  // there are no errors
  if (!anyValues(errors)) {
    return undefined;
  }

  if (getMembershipInviteFormErrors(errors)) {
    return AcceptingMembershipInvitePageErrorType.FORM;
  }

  if (getMembershipInviteGenericErrors(errors)) {
    return AcceptingMembershipInvitePageErrorType.GENERIC;
  }

  if (getMembershipInviteNotFoundErrors(errors)) {
    return AcceptingMembershipInvitePageErrorType.NOT_FOUND;
  }

  return AcceptingMembershipInvitePageErrorType.UNKNOWN;
};

/**
 * Un-nest errors from the BE when helping a user accept a MembershipInvite.
 *
 * @param {ObjectMaybe} [errors]
 * @returns {*}
 */
export const getAcceptingMembershipInvitePageErrors = (errors) => {
  const errorType = getAcceptingMembershipInvitePageErrorType(errors);
  const errorPath = AcceptingMembershipInvitePageErrorPath[errorType];

  if (!errorType) {
    return undefined;
  }

  // In the case of AcceptingMembershipInvitePageErrorType.UNKNOWN, this will return undefined as well. In this case,
  // we'll just show the generic error messaging in the BrandedError component.
  return _get(errors, errorPath);
};

/**
 * If the error type is generic or unknown, show the BrandedError page.
 *
 * @param {*} errorType
 * @returns {boolean}
 */
export const acceptingMembershipInviteShouldShowBrandedErrorPage = (errorType) =>
  or(
    isAcceptingMembershipInvitePageErrorTypeGenericError(errorType),
    isAcceptingMembershipInvitePageErrorTypeUnknown(errorType),
  );

/**
 * Is the value passed invitation cannot be accepted error ?
 * @param {*} value
 * @returns {boolean}
 */
export const isAcceptingMembershipInviteErrorCannotBeAccepted = (value) =>
  isEqual(getMembershipInviteGenericErrors(value), 'This invitation cannot be accepted at this time.');

/**
 * Is the value passed token expired error ?
 * @param {*} value
 * @returns {boolean}
 */
export const isAcceptingMembershipInviteTokenExpired = (value) =>
  isEqual(
    getMembershipInviteGenericErrors(value),
    'Incorrect token or token expired. Please contact support for assistance.',
  );

/**
 * If the error type is generic and there is cannot be accepted error or token expired error -> redirect to login page.
 *
 * @param {*} errorType
 * @param {ObjectMaybe} [errors={}]
 * @returns {boolean}
 */
export const acceptingMembershipInviteShouldShowLoginPage = (errorType, errors) =>
  isAcceptingMembershipInvitePageErrorTypeGenericError(errorType) &&
  (isAcceptingMembershipInviteErrorCannotBeAccepted(errors) || isAcceptingMembershipInviteTokenExpired(errors));

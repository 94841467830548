import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ActionNavSearchClear from './ActionNavSearchClear';

import './ActionNavSearch.scss';

const ActionNavSearch = ({ inputPlaceholder, searchData, onSearchClear, onSearchExecute, onSearchInputChange }) => {
  const [keyword, setKeyword] = useState(searchData?.keyword ?? '');

  React.useEffect(() => {
    setKeyword(searchData?.keyword ?? '');
  }, [searchData]);

  if (!searchData) {
    return null;
  }

  return (
    <div className="action-nav-search">
      <form
        className="form search-form"
        id="action-nav-search-form"
        onSubmit={(e) => {
          e.preventDefault();
          onSearchInputChange({ target: { value: keyword } });
          setImmediate(onSearchExecute);
        }}
      >
        <div className="search-container form-simple form-style hide-label">
          <span className="search-icon icon-ic-search" />

          <input
            className="search-input"
            id="dashboard-search-field"
            name="search"
            onChange={(e) => setKeyword(e.target.value)}
            placeholder={inputPlaceholder}
            type="text"
            value={keyword}
          />

          <ActionNavSearchClear
            onSearchClear={() => {
              setKeyword('');
              onSearchClear();
            }}
            searchData={{ ...searchData, keyword }}
          />
        </div>
      </form>
    </div>
  );
};

ActionNavSearch.propTypes = {
  inputPlaceholder: PropTypes.string,
  onSearchClear: PropTypes.func,
  onSearchExecute: PropTypes.func,
  onSearchInputChange: PropTypes.func,
  searchData: PropTypes.shape({
    execute: PropTypes.number,
    keyword: PropTypes.string,
  }),
};

ActionNavSearch.defaultProps = {
  inputPlaceholder: 'Search',
  onSearchClear: undefined,
  onSearchExecute: undefined,
  onSearchInputChange: undefined,
  searchData: undefined,
};

export default ActionNavSearch;

import { call, put, takeEvery } from 'redux-saga/effects';
import { handleRequestErrors } from 'actions/errors';
import {} from 'actions/modifiers/meta.types';
import {} from 'actions/routines/createApiRoutine/';
import { parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { isFn } from 'helpers/utility';
import {} from 'interfaces/fetchService';
import { FetchService } from 'services';
import {} from './api.types';
export const isApiRequest = (act) => !!(act?.payload?.endpoint && act?.payload?.method && isFn(act?.payload?.onSuccess) && isFn(act?.payload?.onFailure));
export function* apiSagaWorker(action) {
    const { payload: { onSuccess, onFailure, ...request }, meta: { parseErrorResponseOptions } = {}, } = action;
    let errorData = null;
    try {
        const results = (yield call(FetchService.request, request));
        if (results.ok) {
            yield put(onSuccess(results.data));
        }
        else {
            errorData = parseErrorResponse(results, parseErrorResponseOptions);
        }
    }
    catch (error) {
        errorData = parseCaughtError(error);
    }
    if (errorData) {
        yield put(handleRequestErrors(onFailure, errorData));
    }
}
export function* apiSaga() {
    yield takeEvery(isApiRequest, apiSagaWorker);
}

export var LedgerContactType;
(function (LedgerContactType) {
    LedgerContactType["BOTH"] = "both";
    LedgerContactType["SINGULAR"] = "singular";
})(LedgerContactType || (LedgerContactType = {}));
export var IntegrationSyncOptions;
(function (IntegrationSyncOptions) {
    IntegrationSyncOptions["BASE_CURRENCY"] = "bcs";
    IntegrationSyncOptions["MULTI_CURRENCY"] = "mcs";
})(IntegrationSyncOptions || (IntegrationSyncOptions = {}));
export var TopOrEntityOptions;
(function (TopOrEntityOptions) {
    TopOrEntityOptions["TOP"] = "top level";
    TopOrEntityOptions["ENTITY"] = "entity level";
})(TopOrEntityOptions || (TopOrEntityOptions = {}));

import PropTypes from 'prop-types';
import React from 'react';

import { DateFormats } from 'constants/date';

import { getObjDate } from 'helpers/date';
import { isItemKindReceivable } from 'helpers/items';

import { ExternalBlockItem } from 'modules/external/ExternalBlocksSection';
import { ExternalReceiptItemEntry } from 'modules/external/externalReceiptV2/ExternalReceiptSingleItem/components';

/**
 * Component rendering External Receipt Item entries for item kind receivable
 * @param {ComponentProps} props
 * @param {Item} props.item
 * @returns {StatelessComponent}
 */
const ExternalReceiptReceivableItemEntries = ({ item }) => {
  const visible = isItemKindReceivable(item);

  if (!visible) {
    return null;
  }

  return (
    <ExternalBlockItem>
      <ExternalReceiptItemEntry label="Reference" value={item.reference} />
      <ExternalReceiptItemEntry
        label="Due date"
        value={getObjDate(item, 'dateDue', DateFormats.SHORT_ALPHA_MONTH_FULL_YEAR)}
      />
    </ExternalBlockItem>
  );
};

ExternalReceiptReceivableItemEntries.propTypes = {
  item: PropTypes.shape({
    dateDue: PropTypes.string,
    reference: PropTypes.string,
  }).isRequired,
};

export default ExternalReceiptReceivableItemEntries;

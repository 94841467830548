/**
 * @module reducers/tablesReducer/configsReducer/initialState
 */

import { tableNamesCreateItems } from 'constants/tables';

export const initialConfigState = {
  // defaulting the tables isLoading to true means we don't get flashes of some empty render,
  // or attempts to render a null schema, before the request is fired off
  isLoading: true,
  schema: null,
};

export default {
  configs: {
    [tableNamesCreateItems.PAYABLE]: { ...initialConfigState },
    [tableNamesCreateItems.RECEIVABLE]: { ...initialConfigState },
  },
};

import { formNamesItem } from '@routable/shared';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { ReduxFormFieldRenderNumericInput } from 'components';
import { formatNumberForFlexibleDecimalScale } from 'helpers/fieldFormatters';
import { numberOnly } from 'helpers/fieldNormalizers';
import { useDiscrepancyContext } from 'hooks';
import { useGetTableFieldProps } from 'modules/table/hooks';
import { createFieldValueSelector } from 'selectors/createFormSelectors';
import { createItemFormPurchaseOrderSelector } from 'selectors/forms';
const NumberTableField = ({ decimalScale, fixedDecimalScale, formValues, getIsDisabled, getIsRequired, minDecimalScale, name, rowIdx, sectionPath, validate, }) => {
    const fieldPath = `${sectionPath}.${rowIdx}`;
    const { isDisabled, isRequired, validators } = useGetTableFieldProps({
        formValues,
        getIsDisabled,
        getIsRequired,
        fieldPath,
        rowIdx,
        validate,
    });
    const purchaseOrder = useSelector(createItemFormPurchaseOrderSelector);
    const { hasDiscrepancyForColumn } = useDiscrepancyContext();
    const fieldSelector = createFieldValueSelector(formNamesItem.CREATE_ITEM, name.split('.').slice(0, -1).join('.'));
    const lineItem = useSelector(fieldSelector);
    const [fieldName] = name.split('.').slice(-1);
    const lineItemId = lineItem?.id || lineItem?.purchase_order_line_item_id;
    const hasDiscrepancy = purchaseOrder?.value && hasDiscrepancyForColumn(lineItemId, fieldName);
    return (React.createElement(Field, { allowNegative: true, className: "w-full ledger-form-field", component: ReduxFormFieldRenderNumericInput, contentBefore: hasDiscrepancy && (React.createElement("div", { className: clsx('absolute border border-gold-40 rounded-[13px] inset-y-[6px] -inset-x-[8px]', {
                'z-[-1]': !isDisabled,
            }), "data-testid": "discrepancy-border" })), decimalScale: decimalScale, fixedDecimalScale: fixedDecimalScale, format: (value) => formatNumberForFlexibleDecimalScale({
            decimalScale,
            fixedDecimalScale,
            minDecimalScale,
            value,
        }), hideLabel: true, isDebounced: true, isDisabled: isDisabled, isNumericString: true, isRequired: isRequired, name: name, normalize: numberOnly, onChange: (evt) => evt.preventDefault(), type: "text", validate: validators }));
};
export default React.memo(NumberTableField);

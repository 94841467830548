import { RoutableEventsSync } from '@routable/framework';
import { queryClient } from '@routable/shared';

import { fetchBillingCodesRequest } from 'actions/billing';
import { getCurrentCompanyRequest } from 'actions/currentCompany';
import { fetchFundingAccountsRequest, fetchSingleFundingAccountRequest } from 'actions/funding';
import {
  fetchLedgerUnmatchedLedgerFundingAccountsRequest,
  fetchSingleIntegrationConfigRequest,
} from 'actions/integrations';
import { fetchAllMembershipInvitesRequest } from 'actions/inviteTeamMember';
import { fetchItemAndThreadRequest, openItemHasBeenUpdatedSwal, fetchSingleItemRequest } from 'actions/item';
import { ledgerSyncStart } from 'actions/ledger';
import { fetchMembershipsRequest, fetchSingleMembershipRequest } from 'actions/memberships';
import { getSingleBulkImportRoutine } from 'actions/routines/bulkActions';
import { fetchCurrentCompanySettingsItemApprovalRoutine } from 'actions/routines/currentCompany';
import { fetchItemStructureTablesRoutine } from 'actions/routines/tables';
import { webhookSettingsFetchRoutine } from 'actions/routines/webhooks';
import { updateWebhookTest } from 'actions/webhooks';

import { IntegrationSyncingStatusTypes } from 'constants/integration';
import { PAYMENTS_LIST_TABS, PAYMENTS_ROUTE } from 'constants/routes';

import { ItemStatuses } from 'enums/items';

import { isCurrentPathItemEdit } from 'helpers/items';
import { AttachmentStatuses } from 'helpers/ocr/constants';
import { getCurrentUrlPath } from 'helpers/urls';

import { fundingAccountIdForBalanceInfoSelector } from 'queries/fundingCompoundSelectors';

import { featureFlagOCRInboxEnabled } from 'selectors/featureFlagsSelectors';

import { storeAccessor } from 'store/accessor';

export const makeSocketFundingCustomerEventHandler = (emitter) => () => {
  emitter([getCurrentCompanyRequest(), fetchMembershipsRequest(), fetchFundingAccountsRequest()]);
};

export const makeSocketFundingInfoBalanceEventHandler = (emitter) => () => {
  const accountId = fundingAccountIdForBalanceInfoSelector(storeAccessor.getState());
  if (accountId) {
    emitter(fetchSingleFundingAccountRequest(accountId));
  }
};

/**
 * Emit a request to request Company branding.
 *
 * @param {function} emitter
 * @returns {function}
 */
export const makeSocketCompanyBrandingSettingsChangedEventHandler = (emitter) => () => {
  emitter(getCurrentCompanyRequest());
};

export const makeSocketCompanySettingsItemApprovalChangeEventHandler = (emitter) => (eventData) => {
  if (!eventData.isComplete) {
    return;
  }
  emitter(fetchCurrentCompanySettingsItemApprovalRoutine.trigger(eventData));
};

export const makeSocketCompanySettingsProductChangedEventHandler = (emitter) => () => {
  // Changing the company internal settings affects the billing calculation for how long balance transfers take
  emitter(getCurrentCompanyRequest());
  emitter(fetchBillingCodesRequest());
};

export const makeSocketCompanyMembershipsChangedEventHandler = (emitter) => () => {
  emitter(fetchMembershipsRequest());
};

export const makeSocketMembershipChangedEventHandler = (emitter) => (eventData) => {
  if (!eventData?.id) {
    return;
  }

  emitter(fetchSingleMembershipRequest(eventData.id));
};

export const makeSocketMembershipInviteChangedEventHandler = (emitter, companyId) => () => {
  emitter(fetchAllMembershipInvitesRequest(companyId));
};

export const makeSocketPoDiscrepanciesRefreshedEventHandler = (props) => {
  RoutableEventsSync.Publish(`refresh-po-discrepancies-${props.id}`, {
    isLoading: false,
    shouldRefetch: true,
  });
};

export const makeSocketWebhookSettingsEventHandler = (emitter) => () => {
  emitter(webhookSettingsFetchRoutine.trigger());
};

export const makeSocketWebhookTestEventHandler = (emitter) => (eventData) => {
  emitter(updateWebhookTest(eventData));
};

export const makeSocketSyncEventHandler = (emitter) => (eventData) => {
  if (!eventData.id) {
    return;
  }

  const configId = eventData.id;

  // We only show the syncing modal when doing a manual sync
  // The auto sync shouldn't affect the end user's work
  if (eventData.isAuto) {
    return;
  }

  switch (eventData.syncingStatus) {
    case IntegrationSyncingStatusTypes.MANUAL_SYNC:
      // Dispatch redux action to signify sync start
      emitter(ledgerSyncStart(configId));
      break;

    case IntegrationSyncingStatusTypes.NO:
      // Re-fetch integration config, unmatched funding accounts and the item structure tables after sync is complete
      emitter([
        fetchSingleIntegrationConfigRequest(configId),
        fetchItemStructureTablesRoutine.trigger(),
        fetchLedgerUnmatchedLedgerFundingAccountsRequest(configId, {
          is_matched: false,
        }),
      ]);
      break;

    default:
      break;
  }
};

export const makeSocketItemStatusChangedEventHandler = (emitter) => (eventData) => {
  if (!eventData?.id) {
    return;
  }

  const currentUrl = getCurrentUrlPath();

  // we don't need to fetch itemAndThread on item edit because in item edit flow, we just care about fetching the item
  // if the version changes. We have a separate websocket event handled below for that purpose makeSocketItemVersionChangeHandler
  // We intentionally don't handle fetching here to avoid double fetches when if both web sockets come in at the same time
  if (
    !queryClient.isMutating() &&
    currentUrl.includes(eventData.id) &&
    !isCurrentPathItemEdit({ pathname: currentUrl })
  ) {
    emitter(fetchItemAndThreadRequest(eventData.id));
  }
};

/**
 * Creates the change event handler For Bulk Import
 * @function
 * @param {function} emitter
 * @return {function}
 */
export const makeBulkImportChangeEventHandler = (emitter) => (eventData) => {
  if (!eventData?.id) {
    return;
  }

  emitter(
    getSingleBulkImportRoutine.trigger({
      id: eventData.id,
    }),
  );
};

/**
 * Creates the change event handler For billing code adjustment changes
 * @function
 * @param {function} emitter
 * @return {function}
 */
export const makeSocketBillingCodeAdjustmentChangedEventHandler = (emitter) => () => {
  emitter(fetchBillingCodesRequest());
};

export const makeSocketAnnotationChangeHandler = (emitter) => (eventData) => {
  const urlPath = getCurrentUrlPath();
  const isInboxUrlPath = urlPath.includes(`${PAYMENTS_ROUTE}/${PAYMENTS_LIST_TABS.INBOX}`);
  if (isInboxUrlPath && eventData.status === AttachmentStatuses.DONE) {
    emitter(fetchSingleItemRequest(eventData.itemId));
  }
};

/**
 * Create the event handler for fetching an item when the item version change websocket comes in
 * @param emitter
 * @returns {function}
 */
export const makeSocketItemVersionChangeHandler = (emitter) => (eventData) => {
  const currentUrl = getCurrentUrlPath();
  if (isCurrentPathItemEdit({ pathname: currentUrl })) {
    emitter(openItemHasBeenUpdatedSwal({ itemId: eventData.id }));
  }
};

/**
 * Create the event handler for fetching items when some item was created
 * @param emitter
 * @returns {function}
 */
export const makeSocketItemCreateHandler = (emitter) => (eventData) => {
  if (!eventData?.id) {
    return;
  }

  const status = eventData.itemSideStatus;
  const reduxState = storeAccessor.getState();
  const isOCRInboxEnabled = featureFlagOCRInboxEnabled(reduxState);

  const urlPath = getCurrentUrlPath();
  const isInboxUrlPath = urlPath.includes(`${PAYMENTS_ROUTE}/${PAYMENTS_LIST_TABS.INBOX}`);

  if (isInboxUrlPath && (ItemStatuses.OCR === status || (ItemStatuses.CREATED === status && isOCRInboxEnabled))) {
    emitter(fetchSingleItemRequest(eventData.id));
  }
};

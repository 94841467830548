import { RoutableEventsSync, useDispatchEventSync } from '@routable/framework';
import { useEffect } from 'react';
import { useColumnSelect } from './useColumnSelect.hook';
import {} from '../table.extended.types';
import { useDispatchTableEvent, useTableEvent } from './useTableEvent.hook';
import { useTableStore } from '../table.store';
export const useTable = (tableConfig, results) => {
    const { setTableStateItem } = useTableStore(tableConfig.tableName, {
        headers: tableConfig.headers,
        selections: [],
        table: tableConfig.tableName,
        config: tableConfig,
        data: results.data,
        cache: [],
    });
    const tableName = tableConfig.tableName;
    const dispatch = useDispatchEventSync();
    const setPagination = useDispatchTableEvent({
        tableName,
        event: 'pagination',
    });
    const { columnSelect, headerConfig: headers } = useColumnSelect({ headerConfig: tableConfig.headers, tableName });
    useEffect(() => {
        setTableStateItem('headers', headers);
    }, [headers]);
    useEffect(() => {
        if (!results.isFetching) {
            if (results.pagination && !results.pagination.pages) {
                const totalPages = Math.ceil(results.pagination.total / results.pagination.pageSize);
                results.pagination.pages = isNaN(totalPages) ? 1 : totalPages;
            }
            setPagination(results.pagination);
        }
    }, [results.isFetching, results.pagination]);
    useEffect(() => {
        if (results.data) {
            setTableStateItem('cache', results.data);
            RoutableEventsSync.Publish(`table:${tableName}:data`, results.data);
            dispatch(`table:${tableName}:data`, results.data);
        }
    }, [tableName, results.data]);
    useEffect(() => {
        setTableStateItem('headers', headers);
        RoutableEventsSync.Publish(`table:${tableName}:update:config`, {
            tableId: tableName,
            lastUpdated: new Date(),
            data: headers,
        });
        if (tableConfig.defaultPageSize) {
            results.setPageSize?.(tableConfig.defaultPageSize);
        }
    }, [tableName, headers]);
    useTableEvent({
        tableName,
        event: 'page:update',
        fn: ({ page, pageSize }) => {
            if (pageSize) {
                results.setPageSize?.(pageSize);
            }
            if (page) {
                results.gotoPage?.(page);
            }
        },
    });
    useTableEvent({
        tableName,
        event: 'filter:update',
        fn: (filter) => {
            results.setFilters?.(filter);
        },
    });
    useTableEvent({
        tableName,
        event: 'sort:update',
        fn: (sort) => {
            results.setSort?.(sort);
        },
    });
    return {
        tableName,
        headers,
        fixedWidth: tableConfig.fixedWidth,
        columnSelect,
    };
};

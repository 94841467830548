import ThunkFetch from 'helpers/thunkFetch';

import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { onboardingUpdateCompanyTypeEndpoint } from 'services/api/onboardingEndpoints';

import {
  UPDATE_ONBOARDING_COMPANY_TYPE_FAILURE,
  UPDATE_ONBOARDING_COMPANY_TYPE_REQUEST,
  UPDATE_ONBOARDING_COMPANY_TYPE_SUCCESS,
} from 'types/currentCompany';

const updateOnboardingCompanyType = (updatePayload, successCallback = null) => {
  const t = new ThunkFetch({
    actions: {
      error: UPDATE_ONBOARDING_COMPANY_TYPE_FAILURE,
      request: UPDATE_ONBOARDING_COMPANY_TYPE_REQUEST,
      success: UPDATE_ONBOARDING_COMPANY_TYPE_SUCCESS,
    },
    endpoint: onboardingUpdateCompanyTypeEndpoint,
    method: 'PATCH',
    payload: payloadToJSONAPI(updatePayload, 'company'),
    requireAuth: true,
    successCallback,
  });

  return t.makeRequest();
};

export default updateOnboardingCompanyType;

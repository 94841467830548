import { taxTools } from '@routable/taxes';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryContext } from '@routable/shared';
import { generateKeys } from '../helpers';
import { vendorRiskChecksApi } from '../services';
export const useBulkRiskChecks = (filters) => {
    const client = useQueryClient({ context: queryContext });
    const handleOnSuccess = async () => {
        await client.invalidateQueries({ queryKey: [taxTools, 'list'] });
    };
    return useMutation({
        context: queryContext,
        mutationFn: async () => vendorRiskChecksApi.bulkRiskChecks(filters),
        mutationKey: generateKeys.bulkRiskChecks(),
        onSuccess: handleOnSuccess,
    });
};

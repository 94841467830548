import React from 'react';

import InputFieldInner from '../InputFieldInner';

/**
 * Renders InputFieldInner, passing its forwarded ref argument as
 * the prop `setRef`.
 */
const InputField = React.forwardRef((props, ref) => <InputFieldInner setRef={ref} {...props} />);

// setting this keeps our snapshots rendering as 'InputField' and not 'ForwardRef'
InputField.displayName = 'InputField';

export default InputField;

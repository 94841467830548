import classNames from 'classnames';
import React from 'react';
import { Icon, IconNames } from 'components';
import { margin, sizes } from 'constants/styles';
const SkipButton = ({ className, isDisabled, onClick }) => {
    const linkClassName = classNames({
        'align-items--center': true,
        'dark-grey': true,
        'display--flex': true,
        'force-underline': true,
        'hover-primary': true,
        disabled: isDisabled,
        [className]: className,
    });
    return (React.createElement("a", { className: linkClassName, onClick: onClick, role: "presentation" },
        React.createElement(Icon, { icon: IconNames.STEP_FORWARD, marginRight: margin.small, size: sizes.iconSizes.LARGE }),
        React.createElement("p", { className: "font-xs remove-margin-bottom" }, "Skip")));
};
export default SkipButton;

import React from 'react';

import { Text } from 'components/text';

/**
 * Renders the default title text on a ServerError swal.
 * @return {StatelessComponent}
 */
const ServerErrorDefaultTitle = () => (
  <Text.Regular className="alert-error-text">It looks like we ran into an issue.</Text.Regular>
);

export default ServerErrorDefaultTitle;

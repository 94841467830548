import { PaymentDeliveryMethodType } from '@routable/shared';
import _isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import { openCreateManualAddressModal } from 'actions/createManualAddress';
import { externalOnboardingChangeStep } from 'actions/externalOnboarding';
import { fetchSinglePartnershipRequest } from 'actions/partnership';

import { formNamesExternal } from 'constants/forms';
import { FundingSourceProviderSubClasses } from 'constants/funding';

import { isFundingRequirementStatusTypeKycNeeded } from 'helpers/funding';
import { isItemKindPayable } from 'helpers/items';
import { isPaymentDeliveryMethodAch, isPaymentMethodDeliveryCheck } from 'helpers/paymentMethods';
import { getQueryParam } from 'helpers/queryParams';
import { isObject, isString } from 'helpers/utility';

import { doesInvalidFundingAccountExist } from 'modules/createPartnerCompany/helpers/fundingAccount';
import {
  getAchFundingAccount,
  getCheckFundingAccount,
  getFormButtonText,
  getFundingRequirement,
  isLastSubmittedFundingAccountNew,
  shouldUpdateFundingAccount,
} from 'modules/createPartnerCompany/helpers/paymentMethodFormHelpers';
import { makeFormSubmitFailHandler } from 'modules/createPartnerCompany/helpers/submitFailure';
import submitPartnerCompanyPaymentMethod from 'modules/createPartnerCompany/helpers/submitPartnerCompanyPaymentMethod';
import PartnerCompanyPaymentMethodForm from 'modules/createPartnerCompany/presenters/PartnerCompanyPaymentMethodForm';

import { fundingAccountsAllForProviderSubClassSelector } from 'queries/fundingCompoundSelectors';

import { externalPaymentFormSelector } from 'selectors/forms';
import { fundingAccountsByIdSelector, fundingAccountsLastSubmittedSelector } from 'selectors/fundingSelectors';
import {
  isSubmittingItemSelector,
  itemSelector,
  lastSubmittedItemSelector,
  partnershipNewReceivableItemsSelector,
} from 'selectors/itemsSelectors';
import { modalIsOpenSelector } from 'selectors/modalsSelector';

import submitAllExistingItems from 'thunks/submitAllExistingItems';
import submitExistingItemOld from 'thunks/submitExistingItemOld';

const itemId = getQueryParam('item_id');
const partnershipId = getQueryParam('partnership_id');

class PartnerCompanyPaymentMethodContainer extends React.Component {
  formId = formNamesExternal.PARTNER_COMPANY_PAYMENT_METHOD;

  componentDidMount() {
    this.updateFundingAccount(this.props);
  }

  componentDidUpdate(prevProps) {
    const { isSubmittingItem, lastSubmittedFundingAccount, onValuesChange } = this.props;

    const { lastSubmittedFundingAccount: prevSubmittedFundingAccount } = prevProps;

    // Prevent funding account update when submitting - can cause the wrong funding account to show
    if (isSubmittingItem) {
      return;
    }

    if (isLastSubmittedFundingAccountNew(prevSubmittedFundingAccount, lastSubmittedFundingAccount)) {
      // if we've just added a new funding account, select it programmatically
      onValuesChange(
        formNamesExternal.PARTNER_COMPANY_PAYMENT_METHOD,
        'fundingAccount',
        lastSubmittedFundingAccount.id,
      );
    } else if (shouldUpdateFundingAccount(prevProps, this.props)) {
      this.updateFundingAccount(this.props);
    }
  }

  successCallback = (parsedResponse) => {
    const { onChangeStep, onFetchPartnership } = this.props;

    onChangeStep(0);

    onFetchPartnership(partnershipId);

    return parsedResponse;
  };

  handleFormSubmit = async (values) => {
    const { isAddressModalOpen, isConnectBankModalOpen } = this.props;

    if (!isAddressModalOpen && !isConnectBankModalOpen) {
      await submitPartnerCompanyPaymentMethod(values, {
        ...this.props,
        itemId,
        partnershipId,
        successCallback: this.successCallback,
      });
    }
  };

  updateFundingAccount = async (nextProps) => {
    const { item, formUI, fundingAccount, paymentDeliveryMethod, onValuesChange } = nextProps;

    const newFormUIState = { ...formUI };

    let updatedFundingAccount;

    if (isPaymentDeliveryMethodAch(paymentDeliveryMethod)) {
      updatedFundingAccount = getAchFundingAccount(nextProps);
    } else if (isPaymentMethodDeliveryCheck(paymentDeliveryMethod)) {
      updatedFundingAccount = getCheckFundingAccount(nextProps);
    }

    // Ensure we have an empty object for the funding account in case it's not updated
    if (!updatedFundingAccount) {
      updatedFundingAccount = {
        id: '',
        showKYC: false,
        isValid: false,
      };
    }

    // check the funding requirement (KYC) status for a request with a funding account
    if (item && isItemKindPayable(item) && updatedFundingAccount.id) {
      const fundingRequirementResponse = await getFundingRequirement(updatedFundingAccount.id, itemId);

      updatedFundingAccount.showKYC = isFundingRequirementStatusTypeKycNeeded(fundingRequirementResponse);
    } else {
      updatedFundingAccount.showKYC = false;
    }

    // Add receiver funding account or clear it if none selected
    newFormUIState.isFundingAccountExternal = updatedFundingAccount.isExternal;
    newFormUIState.isFundingAccountValid = updatedFundingAccount.isValid;
    newFormUIState.showKYC = updatedFundingAccount.showKYC;

    if (updatedFundingAccount.id !== fundingAccount) {
      onValuesChange(formNamesExternal.PARTNER_COMPANY_PAYMENT_METHOD, 'fundingAccount', updatedFundingAccount.id);
    }

    if (!_isEqual(formUI, newFormUIState)) {
      onValuesChange(formNamesExternal.PARTNER_COMPANY_PAYMENT_METHOD, 'formUI', newFormUIState);
    }
  };

  handleFundingAccountSelected = (eventOrValue) => {
    const { onValuesChange } = this.props;

    // This can get either a change event or the new value
    let value;

    if (isString(eventOrValue)) {
      value = eventOrValue;
    } else if (isObject(eventOrValue) && eventOrValue.target) {
      ({ value } = eventOrValue.target);
    }

    onValuesChange(formNamesExternal.PARTNER_COMPANY_PAYMENT_METHOD, 'fundingAccount', value);
  };

  handleFundingAccountAddressSelected = (address) => {
    const { onOpenCreateManualAddressModal } = this.props;
    onOpenCreateManualAddressModal(address);
  };

  render() {
    const { formUI, fundingAccount, handleSubmit, isSubmittingItem, paymentDeliveryMethod } = this.props;

    const hasInvalidFundingAccount = doesInvalidFundingAccountExist(this.props);

    return (
      <PartnerCompanyPaymentMethodForm
        disableFormSubmit={!fundingAccount || !paymentDeliveryMethod || hasInvalidFundingAccount}
        formButtonText={getFormButtonText(this.props)}
        formId={this.formId}
        formUIState={formUI}
        isSubmitting={isSubmittingItem}
        onCheckFundingAccountSelected={this.handleFundingAccountSelected}
        onFormSubmit={handleSubmit(this.handleFormSubmit)}
        onFundingAccountAddressSelected={this.handleFundingAccountAddressSelected}
      />
    );
  }
}

PartnerCompanyPaymentMethodContainer.propTypes = {
  onChangeStep: PropTypes.func.isRequired,
  formUI: PropTypes.shape(),
  fundingAccount: PropTypes.string,
  // fundingAccounts used in shouldUpdate helper
  /* eslint-disable react/no-unused-prop-types */
  fundingAccounts: PropTypes.shape(),
  handleSubmit: PropTypes.func.isRequired,
  isAddressModalOpen: PropTypes.bool,
  isConnectBankModalOpen: PropTypes.bool,
  isSubmittingItem: PropTypes.bool,
  /* item destructured from `nextProps` */
  /* eslint-disable-next-line react/no-unused-prop-types */
  item: PropTypes.shape(),
  lastSubmittedFundingAccount: PropTypes.shape(),
  onOpenCreateManualAddressModal: PropTypes.func.isRequired,
  onFetchPartnership: PropTypes.func.isRequired,
  // props used in submit helpers
  /* eslint-disable react/no-unused-prop-types */
  onSubmitAllExistingItems: PropTypes.func.isRequired,
  onSubmitExistingItem: PropTypes.func.isRequired,
  /* eslint-enable react/no-unused-prop-types */
  onValuesChange: PropTypes.func.isRequired,
  paymentDeliveryMethod: PropTypes.string,
};

PartnerCompanyPaymentMethodContainer.defaultProps = {
  formUI: {},
  fundingAccounts: {},
  fundingAccount: undefined,
  isAddressModalOpen: false,
  isConnectBankModalOpen: false,
  isSubmittingItem: false,
  item: null,
  lastSubmittedFundingAccount: undefined,
  paymentDeliveryMethod: undefined,
};

const createPaymentMethodForm = reduxForm({
  destroyOnUnmount: false,
  form: formNamesExternal.PARTNER_COMPANY_PAYMENT_METHOD,
  onSubmitFail: makeFormSubmitFailHandler(formNamesExternal.PARTNER_COMPANY_PAYMENT_METHOD),
})(PartnerCompanyPaymentMethodContainer);

const mapStateToProps = (state) => {
  const retProps = {
    formUI: externalPaymentFormSelector(state, 'formUI'),
    fundingAccount: externalPaymentFormSelector(state, 'fundingAccount'),
    fundingAccounts: fundingAccountsByIdSelector(state),
    fundingAccountsWithAchFundingSource: fundingAccountsAllForProviderSubClassSelector(
      state,
      FundingSourceProviderSubClasses.ACH,
    ),
    fundingAccountsWithAddressFundingSources: fundingAccountsAllForProviderSubClassSelector(
      state,
      FundingSourceProviderSubClasses.ADDRESS,
    ),
    initialValues: {
      fundingAccount: '',
      paymentDeliveryMethod: PaymentDeliveryMethodType.ACH,
      formUI: {
        isFundingAccountValid: false,
        isFundingAccountExternal: false,
        showKYC: false,
      },
    },
    isAddressModalOpen: modalIsOpenSelector(state, 'createManualAddress'),
    isConnectBankModalOpen: modalIsOpenSelector(state, 'connectBankManual'),
    isSubmittingItem: isSubmittingItemSelector(state),
    lastSubmittedFundingAccount: fundingAccountsLastSubmittedSelector(state),
    lastSubmittedItem: lastSubmittedItemSelector(state),
    paymentDeliveryMethod: externalPaymentFormSelector(state, 'paymentDeliveryMethod'),
    pendingPaymentItems: partnershipNewReceivableItemsSelector(state, partnershipId),
  };

  if (itemId) {
    retProps.item = itemSelector(state, itemId);
  }

  return retProps;
};

const mapDispatchToProps = {
  onChangeStep: externalOnboardingChangeStep,
  onFetchPartnership: fetchSinglePartnershipRequest,
  onOpenCreateManualAddressModal: openCreateManualAddressModal,
  onSubmitAllExistingItems: submitAllExistingItems,
  onSubmitExistingItem: submitExistingItemOld,
  onValuesChange: change,
};

export { PartnerCompanyPaymentMethodContainer };
export default connect(mapStateToProps, mapDispatchToProps)(createPaymentMethodForm);

import * as types from 'types/errors';

const disabledUserErrorReducer = (state = false, action) => {
  switch (action.type) {
    case types.ADD_DISABLED_USER_ERROR:
      return true;

    default:
      return state;
  }
};

export default disabledUserErrorReducer;

import { allKeys } from 'helpers/utility';

import {
  FETCH_LEDGER_PARTNERSHIPS_SUCCESS,
  FETCH_PLATFORM_PARTNERSHIPS_SEARCH_SUCCESS,
  FETCH_PLATFORM_PARTNERSHIPS_SUCCESS,
} from 'types/integrations';

const allLedgerUnmatchedPartnershipContactsReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_LEDGER_PARTNERSHIPS_SUCCESS:
    case FETCH_PLATFORM_PARTNERSHIPS_SUCCESS:
    case FETCH_PLATFORM_PARTNERSHIPS_SEARCH_SUCCESS:
      return Array.from(new Set([...state, ...allKeys(action.payload.partnershipMember)]));

    default:
      return state;
  }
};

export default allLedgerUnmatchedPartnershipContactsReducer;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './Divider.scss';

const Divider = ({ className }) => (
  <div
    className={classNames({
      'form-divider': true,
      [className]: !!className,
    })}
  />
);

Divider.propTypes = {
  className: PropTypes.string,
};

Divider.defaultProps = {
  className: undefined,
};

export default Divider;

import { all, call, put, spawn, take } from 'redux-saga/effects';

import * as permissionGroupRoutines from 'actions/routines/permissionGroups';
import * as permissionRoutines from 'actions/routines/permissions';

import { parseCaughtError, parseErrorResponse, getRequestErrorAction } from 'helpers/errors';

import * as api from './api';

/**
 * Handle fetching all Permissions. When making the call to get Permissions, you also get the PermissionGroups.
 * @returns {IterableIterator<*>}
 */
export function* fetchPermissions() {
  let errorData = {};

  yield all([
    put(permissionGroupRoutines.fetchPermissionGroupsRoutine.request()),
    put(permissionRoutines.fetchPermissionsRoutine.request()),
  ]);

  try {
    const response = yield call(api.fetchPermissions);

    if (response.ok) {
      // the response for /permissions contains both Permissions and PermissionGroups
      yield all([
        put(permissionGroupRoutines.fetchPermissionGroupsRoutine.success(response.data)),
        put(permissionRoutines.fetchPermissionsRoutine.success(response.data)),
      ]);
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(errorData);
  yield all([
    put(errorAction(permissionGroupRoutines.fetchPermissionGroupsRoutine.failure, errorData)),
    put(errorAction(permissionRoutines.fetchPermissionsRoutine.failure, errorData)),
  ]);
}

/**
 * Listens for redux actions related to permissions.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([permissionRoutines.fetchPermissionsRoutine.TRIGGER]);

    switch (action.type) {
      case permissionRoutines.fetchPermissionsRoutine.TRIGGER:
        yield spawn(fetchPermissions, action);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root permissions saga.
 * @return {IterableIterator<*>}
 */
export default function* permissions() {
  yield watch();
}

import { Button, ButtonGroup } from '@routable/gross-ds';
import { ArrowLeft, ArrowRight } from '@carbon/icons-react';
import React from 'react';
const getCurrentPageValue = (pageSize, currentPage) => {
    return pageSize * currentPage;
};
const getPageRecordLabel = (total, pageSize, currentPage) => {
    const start = Math.max(getCurrentPageValue(pageSize, currentPage - 1), 0);
    const end = Math.min(getCurrentPageValue(pageSize, currentPage), total);
    return `${Math.min(end, start + 1)}-${end} of ${total.toLocaleString('en-US')}`;
};
export const TablematicTablePagination = ({ gotoPage, pagination }) => (React.createElement(ButtonGroup, null,
    React.createElement(Button, { "data-testid": "table:back-button", disabled: !pagination.hasPrevPage, onPress: () => gotoPage('PREV'), size: "small", variant: "outline" },
        React.createElement(ArrowLeft, null)),
    React.createElement("div", { className: "gap-3 text-grey-70 text-xs px-4 h-8 p-3" }, getPageRecordLabel(pagination.total, pagination.pageSize, pagination.page)),
    React.createElement(Button, { "data-testid": "table:next-button", disabled: !pagination.hasNextPage, onPress: () => gotoPage('NEXT'), size: "small", variant: "outline" },
        React.createElement(ArrowRight, null))));

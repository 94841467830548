import PropTypes from 'prop-types';
import React from 'react';

import BaseHint from 'components/hintTypes/BaseHint';
import { text } from 'components/hintTypes/constants';
import { textHelpers } from 'components/hintTypes/helpers';
import { WhiteSpace } from 'components/text';
import TextLinkNewInternalPage from 'components/TextLinkNewInternalPage';

import { MembershipMatchWarning } from 'constants/membership';
import { Intent } from 'constants/ui';

import { oneOfValuesFromObject } from 'helpers/propTypes';

const { cannotInviteTeamMemberAlreadyUserHint: hintText } = text;

/**
 * When an RCTM tries to invite a team member, but that team member is already a user, active or inactive. Clicking
 * on the link should take them to the team member's profile page.
 * @param {ComponentProps} props
 * @param {Membership} props.member
 * @param {MembershipMatchWarning} props.membershipMatchWarningType
 * @param {function} props.onNavigate - Where should the link go on click
 * @returns {StatelessComponent}
 */
const CannotInviteTeamMemberAlreadyUserHint = ({ member, membershipMatchWarningType, onNavigate }) => {
  const { linkText, textAfterLink, textBeforeLink } = hintText[membershipMatchWarningType];

  return (
    <BaseHint
      intent={Intent.WARNING}
      multiline
      text={
        <>
          {textBeforeLink}
          <WhiteSpace />
          <TextLinkNewInternalPage onClick={onNavigate}>{linkText}</TextLinkNewInternalPage>
          {textAfterLink}
        </>
      }
      title={textHelpers.getCannotInviteTeamMemberAlreadyUserHintTitle(member)}
    />
  );
};

CannotInviteTeamMemberAlreadyUserHint.propTypes = {
  member: PropTypes.shape().isRequired,
  membershipMatchWarningType: oneOfValuesFromObject(MembershipMatchWarning).isRequired,
  onNavigate: PropTypes.func.isRequired,
};

export default CannotInviteTeamMemberAlreadyUserHint;

import { DocumentStatuses, DocumentTypes } from 'constants/document';

import { currentCompanySelector } from 'selectors/currentCompanySelectors';

import { storeAccessor as store } from 'store/accessor';

import { IconNames } from '../components/icon';
import { TagType } from '../constants/ui';

import { isCurrentCompanyAccountVerified } from './currentCompany';

// *************************************
// Document status
// *************************************
export const isDocumentStatusPending = (document) => document.status === DocumentStatuses.PENDING;
export const isDocumentStatusRejected = (document) => document.status === DocumentStatuses.REJECTED;

// *************************************
// Document verification
// *************************************

/**
 * Helper to decide which documents to show in the dropdown for a company representative
 * @param shouldShowSSNField
 * @return {array}
 */
export const getMembershipDocumentTypesList = (shouldShowSSNField) => {
  if (!shouldShowSSNField) {
    return [{ value: DocumentTypes.PASSPORT, text: 'Passport' }];
  }

  return [
    { value: DocumentTypes.IDENTIFICATION_CARD, text: 'ID' },
    { value: DocumentTypes.DRIVERS_LICENSE, text: "Driver's License" },
    { value: DocumentTypes.PASSPORT, text: 'Passport' },
  ];
};

/**
 * Helper to override pending document to show verified if the company is verified
 * @param {object} document
 * @return {string}
 */
export const getVerificationDocumentStatus = (document) => {
  const reduxState = store.getState();
  const currentCompany = currentCompanySelector(reduxState);

  if (isDocumentStatusPending(document) && isCurrentCompanyAccountVerified(currentCompany)) {
    return DocumentStatuses.VERIFIED;
  }

  return document.status;
};

/**
 * Returns tag type and text used in DocumentStatus tag based on
 * the passed document status
 * @param {string} documentStatus
 * @returns {{text: string, type: TagType}}
 */
export const getDocumentStatusTagTypeAndText = (documentStatus) => {
  let text = 'Error';
  let type = TagType.ERROR;

  switch (documentStatus) {
    case DocumentStatuses.PENDING:
      text = 'Pending';
      type = TagType.WARNING;
      break;

    case DocumentStatuses.VERIFIED:
      text = 'Verified';
      type = TagType.SUCCESS;
      break;

    case DocumentStatuses.REJECTED:
      text = 'Rejected';
      type = TagType.ERROR;
      break;

    default:
      break;
  }

  return { text, type };
};

/**
 * Returns icon name that represents the document based on the passed document
 * name
 * @param {string} documentName
 * @returns {string}
 */
export const getDocumentIconName = (documentName) => {
  let iconName = IconNames.MEDIA;

  if (documentName?.endsWith('.pdf')) {
    iconName = IconNames.DOCUMENT_OPEN;
  }

  return iconName;
};

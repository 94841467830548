import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { createPartnershipFilteredItemsSelector, itemSelector } from 'selectors/itemsSelectors';
import { itemIdQuerySelector, partnershipIdQuerySelector } from 'selectors/routerSelectors';

import PartnershipRequestItems from './PartnershipRequestItems';

const mapStateToProps = (state, ownProps) => {
  const itemId = itemIdQuerySelector(state, ownProps);
  const partnershipId = partnershipIdQuerySelector(state, ownProps);
  const selector = createPartnershipFilteredItemsSelector(itemId);

  const selectedItem = itemSelector(state, itemId);

  const retProps = {
    partnershipItems: selector(state, partnershipId),
  };

  if (selectedItem) {
    retProps.item = selectedItem;
  }

  return retProps;
};

const enhance = compose(withRouter, connect(mapStateToProps));

export default enhance(PartnershipRequestItems);

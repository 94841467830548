import React from 'react';

import { reduxFormInputPropType } from 'components/commonProps';

import ContactsInteractionList from '../ContactsInteractionList';

/**
 * Uses the `value` prop provided by redux-form's Field to provide our
 * ContactsInteractionList with its `contacts` prop.
 * @param {ComponentProps} props
 * @param {PartnershipMember[]} props.value
 * @return {StatelessComponent}
 * @constructor
 */
const ContactsInteractionListFieldBuffer = (props) => {
  const {
    input: { value },
    ...rest
  } = props;

  return <ContactsInteractionList contacts={value} {...rest} />;
};

ContactsInteractionListFieldBuffer.propTypes = {
  input: reduxFormInputPropType,
};

ContactsInteractionListFieldBuffer.defaultProps = {
  input: undefined,
};

export default ContactsInteractionListFieldBuffer;

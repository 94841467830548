import PropTypes from 'prop-types';
import React from 'react';

import AddContactButtonTooltipContent from 'complexComponents/AddContactButtonTooltipContent';

import { ButtonV2, IconNames, TooltipMUIConditionalWrapper } from 'components';

import { colors, sizes } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';
import { Intent } from 'constants/ui';

import { ADD_CONTACT_BUTTON_COPY } from './constants';

/**
 * @param {ComponentProps} props
 * @param {ReactNode} [children]
 * @param {bool} [isDisabled]
 * @param {Function} [onClick]
 * @param {Partnership} [partnership]
 * @returns {StatelessComponent}
 * @constructor
 */
export const AddContactButtonTarget = ({ isDisabled, children, onClick, partnership }) => {
  // Only tooltip the button if it's disabled
  let tooltipProps;

  if (isDisabled) {
    tooltipProps = {
      arrow: true,
      padding: TooltipPadding.LARGE,
      placement: TooltipPlacement.LEFT,
      title: <AddContactButtonTooltipContent partnership={partnership} />,
    };
  }

  return (
    <TooltipMUIConditionalWrapper tooltipProps={tooltipProps}>
      <ButtonV2
        intent={Intent.NEUTRAL}
        isDisabled={isDisabled}
        leftIconClassName="margin-right--xm"
        leftIconColor={colors.colorGreyDarkHex}
        leftIconName={IconNames.ADD}
        leftIconSize={sizes.iconSizes.MEDIUM}
        onClick={onClick}
      >
        {children}
      </ButtonV2>
    </TooltipMUIConditionalWrapper>
  );
};

AddContactButtonTarget.propTypes = {
  children: PropTypes.node,
  isDisabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  partnership: PropTypes.shape().isRequired,
};

AddContactButtonTarget.defaultProps = {
  children: ADD_CONTACT_BUTTON_COPY,
};

export default AddContactButtonTarget;

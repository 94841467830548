import React from 'react';
import CreateAccount from 'modules/signup-accountCreation/CreateAccount';
import SelectWorkspace from 'modules/signup-accountCreation/SelectWorkspace';
import VerifyEmail from 'modules/signup-accountCreation/VerifyEmail';
import { formModules } from '../../constants/formModules';
export const formModuleSelector = (formModule) => {
    switch (formModule) {
        case formModules.CREATE_ACCOUNT:
            return React.createElement(CreateAccount, null);
        case formModules.SELECT_NAMESPACE:
            return React.createElement(SelectWorkspace, null);
        case formModules.VERIFY_EMAIL:
            return React.createElement(VerifyEmail, null);
        default:
            return React.createElement(CreateAccount, null);
    }
};

import PropTypes from 'prop-types';
import React from 'react';

import { FormControl } from 'components/form';
import { textHelpers } from 'components/hintTypes/helpers';

import { Intent } from 'constants/ui';

import BaseHint from '../BaseHint';

const MultiSourcePaymentMethodHint = ({ itemKind }) => {
  const { text, title } = textHelpers.getMultiSourcePaymentHintMethodTextByItemKind(itemKind);

  return (
    <FormControl>
      <BaseHint intent={Intent.INFO} multiline text={text} title={title} />
    </FormControl>
  );
};

MultiSourcePaymentMethodHint.propTypes = {
  itemKind: PropTypes.string.isRequired,
};

export default MultiSourcePaymentMethodHint;

import { Tooltip } from '@routable/gross-ds';
import React from 'react';
import { Row } from 'components';
import { useOverflow } from 'hooks';
import { EmptyState, InvoiceButton, InvoiceLink, TextContent } from './components';
import './InvoiceListText.scss';
const InvoiceListText = ({ buttonLabel, children, dataKey, dataFullStory, fallbackText, hasLink, itemURL, rowData, }) => {
    const { isOverflowing, ref: textElementRef } = useOverflow();
    const currentData = rowData[dataKey];
    const currentItemURL = itemURL || rowData.itemURL;
    const hasURL = Boolean(hasLink && currentItemURL);
    const showEmptyState = !currentData && !hasURL;
    return (React.createElement(Tooltip, { "data-testid": "invoice-list-text-tooltip", position: "top", tooltip: isOverflowing ? currentData : undefined, variant: "light" },
        React.createElement(Row, { className: "invoice-list-text", justifyContent: "flex-start" }, showEmptyState ? (React.createElement(EmptyState, null)) : (React.createElement("div", { className: "flex items-center w-full" },
            React.createElement(InvoiceLink, { fallbackText: fallbackText, hasURL: hasURL, itemURL: currentItemURL, title: currentData }),
            React.createElement(InvoiceButton, { buttonLabel: buttonLabel, fallbackText: fallbackText, hasURL: hasURL, itemURL: currentItemURL, rowData: rowData }),
            React.createElement(TextContent, { dataFullStory: dataFullStory, dataTestId: "invoice-list-text-content", hasURL: hasURL, setRef: textElementRef }, currentData),
            children)))));
};
export default InvoiceListText;

import classNames from 'classnames';
import React from 'react';
import './TableToolbar.scss';
const TableToolbar = ({ children, className }) => (React.createElement("div", { className: "table-toolbar--wrapper" },
    React.createElement("div", { className: classNames('table-toolbar', className) }, children)));
TableToolbar.defaultProps = {
    children: undefined,
    className: undefined,
};
export default TableToolbar;

import { enableInternationalPaymentsRoutine } from 'actions/routines/currentCompany';

import { createIsUpdatingReducer } from 'store/redux';

import {
  UPDATE_CURRENT_COMPANY_SETTINGS_REQUEST,
  UPDATE_CURRENT_COMPANY_SETTINGS_FAILURE,
  UPDATE_CURRENT_COMPANY_SETTINGS_SUCCESS,
} from 'types/currentCompany';

export const finishCases = [
  enableInternationalPaymentsRoutine.FAILURE,
  enableInternationalPaymentsRoutine.SUCCESS,
  UPDATE_CURRENT_COMPANY_SETTINGS_FAILURE,
  UPDATE_CURRENT_COMPANY_SETTINGS_SUCCESS,
];
export const requestCases = [enableInternationalPaymentsRoutine.REQUEST, UPDATE_CURRENT_COMPANY_SETTINGS_REQUEST];

const isUpdatingReducer = createIsUpdatingReducer(finishCases, requestCases);

export default isUpdatingReducer;

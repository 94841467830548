import { Checkmark } from '@carbon/icons-react';
import { systemLogger } from '@routable/shared';
import React, { useRef } from 'react';
import { useTab } from 'react-aria';
import { filtersListItemStyles } from './FiltersListItem.styles';
export const FiltersListItem = ({ isActive, tab, state }) => {
    const ref = useRef(null);
    const { tabProps: listItemProps, isSelected, isDisabled } = useTab(tab, state, ref);
    const item = state.collection.getItem(tab.key);
    if (!item) {
        systemLogger.log({
            level: 'ERROR',
            error: `No item for key ${tab.key} exists!`,
        });
        return null;
    }
    return (React.createElement("div", { ...listItemProps, ref: ref, className: filtersListItemStyles({ isSelected, isDisabled }) },
        React.createElement("span", { className: "flex items-center gap-2" },
            item.props.icon,
            " ",
            item.textValue),
        isActive && !isDisabled && React.createElement(Checkmark, { "data-testid": `${item.textValue}:active-icon` })));
};

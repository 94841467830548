import { areDevtoolsEnabled } from 'helpers/env';

/**
 * In development, this sets some convenience objects on our global debug object.
 * Allows direct access to the store and its properties.
 * @param {ReduxStore} store
 */
const configureDevStore = (store) => {
  if (areDevtoolsEnabled()) {
    window.routable.debug.store = store;
    window.routable.debug.dispatch = store.dispatch;
    window.routable.debug.getState = store.getState;
  }
};

export default configureDevStore;

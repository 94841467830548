import { z } from 'zod';
import { optionsModel } from './common.model';
export const approverDataApiListModel = z.preprocess((raw) => {
    const resp = raw;
    return {
        ...resp,
        data: resp.data,
    };
}, z.object({
    data: optionsModel,
}));
export const ApproverStatus = z.enum(['approved', 'bypassed', 'dismissed', 'not_requested', 'requested']);
export const approverModel = z.object({
    id: z.string().uuid(),
    approvedAt: z.string().nullish(),
    bypassedAt: z.string().nullish(),
    dismissedAt: z.string().nullish(),
    isValid: z.boolean().nullish(),
    label: z.string(),
    requestedAt: z.string().nullish(),
    status: ApproverStatus,
});
export const approverModelWithLevelType = approverModel.extend({
    levelType: z.enum(['all', 'any']),
});

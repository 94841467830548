import { createErrorReducer } from 'store/redux';

import {
  FETCH_THREADS_FAILURE,
  FETCH_THREAD_FAILURE,
  FETCH_THREADS_REQUEST,
  FETCH_THREADS_SUCCESS,
  FETCH_THREAD_REQUEST,
  FETCH_THREAD_SUCCESS,
} from 'types/thread';

export const successCases = [FETCH_THREADS_REQUEST, FETCH_THREADS_SUCCESS, FETCH_THREAD_REQUEST, FETCH_THREAD_SUCCESS];
export const failureCases = [FETCH_THREADS_FAILURE, FETCH_THREAD_FAILURE];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

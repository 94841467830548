import {} from '@routable/fund-routes';
import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';
import {} from 'interfaces/global';
import {} from 'interfaces/partnerships';
import { CREATE_INTERNATIONAL_BANK_ACCOUNT, CREATE_INTERNATIONAL_FUNDING_ACCOUNT, FETCH_SUPPORTED_COUNTRIES, } from 'types/funding';
export const createInternationalFundingAccountRoutine = createRoutine(CREATE_INTERNATIONAL_FUNDING_ACCOUNT);
export const createInternationalFundingAccountHandler = bindRoutineToReduxForm(createInternationalFundingAccountRoutine);
export const createInternationalBankAccountRoutine = createRoutine(CREATE_INTERNATIONAL_BANK_ACCOUNT);
export const createInternationalBankAccountHandler = bindRoutineToReduxForm(createInternationalBankAccountRoutine);
export const fetchSupportedCountriesRoutine = createRoutine(FETCH_SUPPORTED_COUNTRIES);

import classNames from 'classnames';
import React from 'react';
import { Field } from 'redux-form';
import { FormControl, FormFieldLabel, ReduxFormFieldRenderInput } from 'components';
import { PLATFORM_DISPLAY_SHORT_NAME, PLATFORM_DOMAIN } from 'constants/platform';
import { formStyles } from 'constants/styles';
import { requiredValidator } from 'helpers/fieldValidation';
import { getCompanyNamespaceFromUrl } from 'helpers/urls';
import { isValueEmpty } from 'helpers/utility';
import ReduxFormFieldRenderInputWithUpdatedErrors from '../ReduxFormFieldRenderInputWithUpdatedErrors';
import {} from './AcceptMembershipInviteFormContent.types';
const AcceptMembershipInviteFormContent = ({ companySSOSettings, canManageSsoSettings, }) => {
    const isSSOTurnedOn = !isValueEmpty(companySSOSettings);
    const isSSOTurnedOnAndOptional = isSSOTurnedOn && !companySSOSettings.ssoRequired;
    const companyNamespace = getCompanyNamespaceFromUrl();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames({
                'display--none': !!companyNamespace,
            }) },
            React.createElement(FormFieldLabel, null, `Navigate here to log in to ${PLATFORM_DISPLAY_SHORT_NAME}`),
            React.createElement(FormControl, null,
                React.createElement(Field, { className: `${formStyles.field.xl.full} namespace`, component: ReduxFormFieldRenderInput, dataFullStory: true, isDisabled: true, name: "form.namespace", placeholder: "workspace-name", type: "text" },
                    React.createElement("div", { className: "inline-label inline-right disabled" },
                        React.createElement("span", null, `.${PLATFORM_DOMAIN}`))))),
        React.createElement(FormFieldLabel, { className: classNames({
                'margin-top--m': !companyNamespace,
            }) }, "Confirm your info"),
        React.createElement(FormControl, null,
            React.createElement(Field, { className: formStyles.field.xl.full, component: ReduxFormFieldRenderInput, isDisabled: true, name: "form.email", placeholder: "Email address", type: "text" })),
        React.createElement(FormControl, null,
            React.createElement(Field, { className: formStyles.field.xl.left, component: ReduxFormFieldRenderInput, name: "form.firstName", placeholder: "First name", type: "text", validate: requiredValidator }),
            React.createElement(Field, { className: formStyles.field.xl.right, component: ReduxFormFieldRenderInput, name: "form.lastName", placeholder: "Last name", type: "text", validate: requiredValidator })),
        (canManageSsoSettings || !isSSOTurnedOn) && (React.createElement(FormControl, null,
            React.createElement(Field, { className: formStyles.field.xl.full, component: ReduxFormFieldRenderInputWithUpdatedErrors, name: "form.password", placeholder: "Password", type: "password", validate: requiredValidator }))),
        !canManageSsoSettings && isSSOTurnedOnAndOptional && (React.createElement(FormFieldLabel, { className: "margin-top--m" },
            "Access your account",
            React.createElement("ul", { className: "list-disc margin-top--xm padding-left--m" },
                React.createElement("li", null, "If your company uses Gmail for business, log in with Google. You can also add a password later."),
                React.createElement("li", null, "If your company does not use Gmail for business, you'll need to log in with a password."))))));
};
export default AcceptMembershipInviteFormContent;

import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { submitPartnerCompanyContactInfoRoutine } from 'actions/routines/external';

import { formNamesExternal } from 'constants/forms';

import { partnerCompanyContactFormInitialValuesSelector } from 'queries/partnerCompanyFormCompoundSelectors';
import { partnershipFromCurrentPartnershipRequestSelector } from 'queries/partnershipCompoundSelectors';

import { currentUserSelector } from 'selectors/currentUserSelectors';

import PartnerCompanyContactForm from './PartnerCompanyContactForm';

const mapStateToProps = createStructuredSelector({
  initialValues: partnerCompanyContactFormInitialValuesSelector,
  currentUser: currentUserSelector,
  partnership: partnershipFromCurrentPartnershipRequestSelector,
});

const mapDispatchToProps = {
  onFormSubmit: submitPartnerCompanyContactInfoRoutine,
};

const WrappedPartnerCompanyContactForm = reduxForm({
  form: formNamesExternal.PARTNER_COMPANY_CONTACT,
})(PartnerCompanyContactForm);

export { mapDispatchToProps, mapStateToProps };

export default connect(mapStateToProps, mapDispatchToProps)(WrappedPartnerCompanyContactForm);

import React from 'react';

import { Card, Col, Row } from 'components';
import BrandNav from 'components/nav/BrandNav';

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

import { MaintenanceModeBody } from 'modules/maintenance/maintenanceMode/components';

import 'modules/maintenance/Maintenance.scss';
import { MaintenanceHeading } from '../components';

const MaintenanceModeFullPage = () => (
  <div className="branded--container maintenance-mode">
    <BrandNav />
    <div className="branded--contents">
      <Row cols={1} flexWrap="wrap">
        <Col>
          <MaintenanceHeading
            className="margin-bottom--large"
            title={`${PLATFORM_DISPLAY_SHORT_NAME} is currently in maintenance mode and will be back soon.`}
          />
        </Col>
        <Col>
          <Card className="generic-card">
            <MaintenanceModeBody />
          </Card>
        </Col>
      </Row>
    </div>
  </div>
);

export default MaintenanceModeFullPage;

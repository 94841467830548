import {} from 'interfaces/auth';
import { QuickswitchActionType } from 'types/quickswitch';
export const openQuickswitchModal = () => ({
    type: QuickswitchActionType.OPEN_MODAL,
});
export const closeQuickswitchModal = () => ({
    type: QuickswitchActionType.CLOSE_MODAL,
});
export const executeQuickswitchSwitchMembership = (membershipId) => ({
    type: QuickswitchActionType.SWITCH_MEMBERSHIP_REQUEST,
    payload: { membershipId },
});
export const redirectToSSOLogin = (companyNamespace) => ({
    type: QuickswitchActionType.REDIRECT_TO_SSO_LOGIN,
    payload: { companyNamespace },
});
export const fetchQuickswitchMembershipList = () => ({
    type: QuickswitchActionType.FETCH_MEMBERSHIP_LIST_REQUEST,
});
export const fetchQuickswitchMembershipSearch = (searchTerm) => ({
    type: QuickswitchActionType.FETCH_MEMBERSHIP_SEARCH_REQUEST,
    payload: { searchTerm },
});
export const switchToBrandedSubdomain = (responseMeta) => ({
    type: QuickswitchActionType.SWITCH_TO_BRANDED_SUBDOMAIN,
    payload: { meta: responseMeta },
});
export const updateMembershipList = (memberships) => ({
    type: QuickswitchActionType.UPDATE_MEMBERSHIP_LIST,
    payload: { memberships },
});
export const updateMembershipListError = () => ({
    type: QuickswitchActionType.FETCH_MEMBERSHIP_REQUEST_ERROR,
});
export const openQuickswitchErrorModal = () => ({
    type: QuickswitchActionType.OPEN_ERROR_MODAL,
});
export const closeQuickswitchErrorModal = () => ({
    type: QuickswitchActionType.CLOSE_ERROR_MODAL,
});
export const reLogin = () => ({
    type: QuickswitchActionType.RE_LOGIN,
});
export const reLoginSuccess = () => ({
    type: QuickswitchActionType.RE_LOGIN_SUCCESS,
});
export const reLoginError = () => ({
    type: QuickswitchActionType.RE_LOGIN_ERROR,
});
export const switchResetState = () => ({
    type: QuickswitchActionType.SWITCH_RESET_STATE,
});
export const setSearchTerm = (searchTerm) => ({
    type: QuickswitchActionType.SET_SEARCH_TERM,
    payload: { searchTerm },
});
export const setTotalNumberOfMemberships = (totalNumberOfMemberships) => ({
    type: QuickswitchActionType.SET_TOTAL_NUMBER_OF_MEMBERSHIPS,
    payload: { totalNumberOfMemberships },
});

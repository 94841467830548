import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { createItemBillCurrencySelector } from 'queries/createItemFormSelectors';

import { createItemFormAllValuesSelector } from 'selectors/forms';

import TotalsPanelTax from './TotalsPanelTax';

export const mapStateToProps = createStructuredSelector({
  currencyCode: createItemBillCurrencySelector,
  formValues: createItemFormAllValuesSelector,
});

export default connect(mapStateToProps)(TotalsPanelTax);

/**
 * @module helpers/routeHelpers
 */

import queryString from 'query-string';

import { ExistingItemActions } from 'constants/item';
import {
  ALLOWED_FORWARDING_ROUTES,
  APP_ROUTES,
  BULK_ACTIONS_IMPORT_ITEMS_ROUTE,
  COMPANIES_LIST_FILTERS,
  COMPANIES_ROUTE,
  CONTACTS,
  CONTACTS_ROUTE,
  CONTACTS_TABS,
  CONVERT_EXTERNAL_COMPANY_TO_REGISTERED,
  CREATE_ITEM_STATES,
  CREATE_NEW_BILL_ROUTE,
  CREATE_PAYABLE_ROUTE,
  CREATE_RECEIVABLE_ROUTE,
  DASHBOARD,
  EXTERNAL_V2,
  EXT_GET_PAID,
  EXT_PAY,
  GUIDE_ROUTE,
  PAYABLES_ROUTE,
  INBOX_ROUTE,
  PAYMENTS_ROUTE,
  SELECTED_COMPANY_TABS,
  SETTINGS_ACCOUNT_TEAM_MEMBERS_ROUTE,
  SETTINGS_ROUTE,
  RECEIVABLES_ROUTE,
} from 'constants/routes';

import { PAYMENTS_LIST_FILTERS } from 'enums/routes';

import { isPathExternalV2AcceptPartnershipOrItem } from 'helpers/external';
import { getQueryParamsFromUrl, getQueryString } from 'helpers/queryParams';
import { getCurrentLocation, getTopLevelDomain, isSubdomainNamespaceExternal, isTabCompanies } from 'helpers/urls';
import { isString, lastIndexOf, reduceKeys } from 'helpers/utility';

/**
 * Pass in a route, get back that route with a leading slash so it can
 * be used by history.
 * Can also pass undefined or empty string to get a base path back.
 * @param route {?string} String to ensure is pushable to state
 * @returns {string}
 */
export const asPath = (route = '') => (route.startsWith('/') ? route : `/${route}`);

/**
 * Pass in any number of path components, get back a route with a
 * leading slash so it can be used by history.
 * @param {string,[string]} paths Argument list of strings to join (safely pushable to history).
 * @returns {string} Full path.
 */
export const getJoinedPath = (...paths) => {
  const joined = `/${paths.join('/')}`;
  // strip additional slashes from the route (i.e. if one of the paths
  // already had a leading slash)
  return joined.replace(/(\/){2,}/g, '/');
};

/**
 * Pass in any number of path component, get back a route with a topLevelDomain as
 * a base.
 * @param {string,[string]} paths Argument list of strings to join (safely pushable to history).
 * @returns {string} Full path
 */
export const getJoinedPathWithTopLevelDomainBase = (...paths) => {
  // Remove additional forward slashes
  let validPaths = getJoinedPath(...paths);
  // Remove leading forward slash
  validPaths = validPaths.substr(1);
  // add top level domain
  return `${getTopLevelDomain()}${validPaths}`;
};

/**
 * Same as getJoinedPath, but allows the last argument provided to be a object of queries to append to the path.
 * If this value is a regular path string, falls back to returning the result of getJoinedPath.
 * @param paths {Array<string|Object>} Argument list of strings to join, but the last item can be a query object
 * @returns {string}
 */
export const getJoinedPathWithQueries = (...paths) => {
  const queriesIndex = lastIndexOf(paths);

  if (queriesIndex > -1) {
    const querySegment = paths[queriesIndex];
    const pathSegments = paths.slice(0, queriesIndex);

    if (isString(querySegment)) {
      return getJoinedPath(...pathSegments, querySegment);
    }

    const queries = queryString.stringify(paths[queriesIndex]);
    const joinedPath = getJoinedPath(...pathSegments);

    return `${joinedPath}/?${queries}`;
  }

  return '/';
};

/**
 * Instead of doing many calls to getJoinedPath, create an object which joins the initial path
 * with the specific routes from the constant.
 * @function
 * @example
 * const initialPath = 'dashboard';
 * const routeConstant = {
 *   PAYABLE: 'payable',
 * };
 * const paths = joinPathsWithRouteConstants(initialPath, routeConstant);
 * // paths[routeConstant.PAYABLE] = 'dashboard/payable'
 * @param {string} initialPath
 * @param {Object} routeConstant
 * @returns {Object}
 */
export const joinPathsWithRouteConstants = (initialPath, routeConstant) =>
  reduceKeys(
    routeConstant,
    (accumulator, path) => ({
      ...accumulator,
      [routeConstant[path]]: getJoinedPath(initialPath, routeConstant[path]),
    }),
    {},
  );

export const getPathWithQueryParams = (path, queryParams = undefined) => {
  if (!queryParams) {
    return path;
  }

  return `${path}?${queryString.stringify(queryParams)}`;
};

/**
 * Get a URL for the company-specific page based on the partnershipId. Defaults to the About tab.
 * @function
 * @param {Partnership.id} partnershipId
 * @param {StringMaybe} [subsection='/about']
 * @returns {string} URL
 */
export const getCompanyRouteByPartnershipId = (partnershipId, subsection = '/about') =>
  `/${DASHBOARD}/${CONTACTS}/${CONTACTS_TABS.COMPANIES}/all/${partnershipId}${subsection}`;

export const getConvertCompanyURL = (token, membershipId, companyId) =>
  `/${CONVERT_EXTERNAL_COMPANY_TO_REGISTERED}/?token=${token}&membership_id=${membershipId}&company_id=${companyId}`;

/**
 * Checks if the route is allowed to be used for forwarding the user
 * @param {string} route ex: /dashboard/create_bill/new?id=123
 * @returns {boolean}
 */
export const routeAllowedForForwarding = (route) =>
  ALLOWED_FORWARDING_ROUTES.some((allowedRoute) => route.startsWith(asPath(allowedRoute)));

/**
 * Return new url based on the new search qs
 * @param keyword {?string}
 * @param location {Location}
 * @returns {string}
 */
export const getSearchUrl = (keyword, location = window.location) => {
  const currentParams = getQueryParamsFromUrl(location.search);

  const newUrl = `${location.pathname}?${getQueryString({
    ...currentParams,
    search: keyword,
  })}`;

  return newUrl;
};

/**
 * Returns whether the current url is a search query url.
 * @param location {Object}
 * @returns {boolean}
 */
export const isSearchRoute = (location = window.location) => {
  const queries = queryString.parse(location.search);
  return !!queries.search;
};

/**
 * Returns query string to use for url forwarding (next) route.
 * Automatically encodes the route as a URI component.
 * @param route {string}
 * @returns {string}
 */
export const getNextRouteQuery = (route) => `?next=${encodeURIComponent(route)}`;

/**
 * Returns all app routes (i.e. non-static page routes) as a configuration
 * indicating the base path for the route, and whether it must be an exact match.
 * @return {{ path: string, isExact: boolean }[]}
 */
export const getApplicationRouteDefs = () =>
  APP_ROUTES.map((route) => ({
    path: asPath(route),
    // in the case that we eventually need to redirect from the home route ("/"),
    // this property will be necessary to avoid redirect loops
    isExact: false,
  }));

/**
 * Check whether the user in on the bulk import items page
 * @param {Object} location
 * @return {boolean}
 */
export const isBulkImportItemsRoute = (location = window.location) =>
  location.pathname?.startsWith(BULK_ACTIONS_IMPORT_ITEMS_ROUTE);

/**
 * Returns whether the user is on the companies page in the dashboard.
 * @param {Object} location
 * @returns {boolean}
 */
export const isCompaniesRoute = (location = window.location) => location.pathname?.startsWith(COMPANIES_ROUTE);

export const getVendorTaxDocRoute = (vendorId) =>
  getJoinedPath(COMPANIES_ROUTE, COMPANIES_LIST_FILTERS.VENDORS, vendorId, SELECTED_COMPANY_TABS.TAX_DOCS);

export const getVendorComplianceChecksRoute = (partnershipId) =>
  getJoinedPath(
    COMPANIES_ROUTE,
    COMPANIES_LIST_FILTERS.VENDORS,
    partnershipId,
    SELECTED_COMPANY_TABS.VENDOR_COMPLIANCE_CHECKS,
  );

/**
 * Returns whether the user is on the create payables page in the dashboard.
 * @param {Object} location
 * @returns {boolean}
 */
export const isCreatePayableRoute = (location = window.location) => location.pathname?.startsWith(CREATE_PAYABLE_ROUTE);

/**
 * Returns whether the user is on the create receivables page in the dashboard.
 * @param {Object} location
 * @returns {boolean}
 */
export const isCreateReceivableRoute = (location = window.location) =>
  location.pathname?.startsWith(CREATE_RECEIVABLE_ROUTE);

export const isCreateItemFormRoute = (location = window.location) =>
  location.pathname?.startsWith(CREATE_PAYABLE_ROUTE) ||
  location.pathname?.startsWith(CREATE_RECEIVABLE_ROUTE) ||
  location.pathname?.startsWith(CREATE_NEW_BILL_ROUTE) ||
  location.pathname?.endsWith(CREATE_ITEM_STATES.EDIT);

/**
 * Returns whether the user is on the guide page in the dashboard.
 * @param {Object} location
 * @returns {boolean}
 */
export const isGuideRoute = (location = window.location) => location?.pathname?.startsWith(GUIDE_ROUTE);

/**
 * Returns whether the user is on the payables page in the dashboard.
 * @param {Object} location
 * @returns {boolean}
 */
export const isPayablesRoute = (location = window.location) => location.pathname?.startsWith(PAYABLES_ROUTE);

/**
 * Returns whether the user is on the payables page in the dashboard.
 * @param {Object} location
 * @returns {boolean}
 */
export const isReceivablesRoute = (location = window.location) => location.pathname?.startsWith(RECEIVABLES_ROUTE);
/**
 * Returns whether the user is on the inbox page in the dashboard.
 * @param {Object} location
 * @returns {boolean}
 */
export const isInboxRoute = (location = window.location) => location.pathname?.startsWith(INBOX_ROUTE);

/**
 * Returns whether the user is on the settings page in the dashboard.
 * @param {Object} location
 * @returns {boolean}
 */
export const isSettingsRoute = (location = window.location) => location.pathname?.startsWith(SETTINGS_ROUTE);

/**
 * Get contacts or payment route based on tab
 * @param {string} tab
 * @return {string}
 */
export const getContactsOrPaymentsRouteBasedOnTab = (tab) => (isTabCompanies(tab) ? CONTACTS_ROUTE : PAYMENTS_ROUTE);

/**
 * Get the route for viewing a specific user's profile.
 * @param {Membership.id} membershipId
 * @returns {string}
 */
export const getUserSettingsProfileRoute = (membershipId) =>
  getJoinedPath(SETTINGS_ACCOUNT_TEAM_MEMBERS_ROUTE, membershipId);

/**
 * Get the route for viewing payables filtered by a specific bulk import.
 * @param {Id} bulkImportId
 * @returns {string}
 */
export const getBulkImportFilteredPayablesRoute = (bulkImportId) =>
  `${getJoinedPath(PAYABLES_ROUTE, PAYMENTS_LIST_FILTERS.ALL)}?csv_upload=${bulkImportId}`;

/**
 * Get the route for editing an existing item.
 * @param {import('interfaces/item').Item} item
 * @returns {string}
 */
export const getEditItemRoute = (item) => getJoinedPath(DASHBOARD, item.kind, item.id, ExistingItemActions.EDIT);

/**
 * Returns similar external v2 item url from given item.
 *
 * @param {import('interfaces/item').Item | import('interfaces/redux').Payment} item
 * @param {string} paramToReplace - The query param that we want to replace/update
 * @returns {string} similar item url
 */
export const getExternalV2ItemSimilarRoute = (item, paramToReplace = 'item_id') => {
  const { search } = getCurrentLocation();
  const isCustomerMakingPayment = isPathExternalV2AcceptPartnershipOrItem();

  const basepath = isSubdomainNamespaceExternal() ? '' : `${EXTERNAL_V2}/`;
  const externalBasePath = isCustomerMakingPayment ? `${basepath}${EXT_PAY}` : `${basepath}${EXT_GET_PAID}`;

  const queryParams = queryString.parse(search);

  // If we're dealing with item, we want to remove any existing `payment` query
  // param and update the item_id param with the new value
  if (paramToReplace === 'item_id') {
    delete queryParams.payment;
    queryParams.item_id = item.id;
  } else {
    // Otherwise, if dealing with payment, we want to remove any existing item_id
    // query param and update the payment query param with the new value
    delete queryParams.item_id;
    queryParams.payment = item.id;
  }

  return `/${externalBasePath}/${item.partnershipRequest}?${queryString.stringify(queryParams)}`;
};

/**
 * Get the route for viewing tax eligible vendors
 * @param {Id} bulkImportId
 * @returns {string}
 */
export const getTaxToolsEligibleRoute = (queryParams) =>
  `${COMPANIES_ROUTE}/${COMPANIES_LIST_FILTERS.ALL_TAX_ELIGIBLE_VENDORS}?${queryString.stringify(queryParams)}`;

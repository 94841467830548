import { DateFormats } from '@routable/shared';
import React from 'react';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { formatDate } from 'helpers/date';
import { parseCurrency } from 'helpers/numbers';
import { getDateTimeObjectForProbableTimezone } from 'helpers/timezone';
import {} from 'interfaces/billing';
import { BalanceFAQItemUsageAnswer, FullListOf } from '../components/Balance/components/BalanceFAQSection/components';
export const getBalanceCheckListData = ({ nextDayBillingData, rtpBillingData, sameDayBillingData, }) => {
    const data = {
        header: 'Current balance',
        items: [],
        listTitle: 'By maintaining a balance your team will be able to:',
    };
    if (!nextDayBillingData || !sameDayBillingData) {
        return data;
    }
    const { paymentDeliveryOptionExternalName: nextDayTitle } = nextDayBillingData;
    const { paymentDeliveryOptionExternalName: sameDayTitle } = sameDayBillingData;
    const { paymentDeliveryOptionExternalName: rtpTitle } = Object(rtpBillingData);
    let availableTransfers = `${sameDayTitle} and ${nextDayTitle}`;
    if (rtpTitle) {
        availableTransfers = rtpTitle;
    }
    data.items = [
        `Accelerate payments to vendors with ${availableTransfers} transfers.`,
        'Quickly fix negative balances caused by failed transactions.',
        'Ensure that funds are reserved for upcoming payments.',
    ];
    return data;
};
export const getBalanceFAQItemsStrings = (nextDayBillingData, sameDayBillingData) => {
    const { cutoff: nextDayCutoffString, rate: nextDayRate, paymentDeliveryOptionExternalName: nextDayTitle, } = nextDayBillingData;
    const { cutoff: sameDayCutoffString, rate: sameDayRate, paymentDeliveryOptionExternalName: sameDayTitle, } = sameDayBillingData;
    const sameDayCutoff = getDateTimeObjectForProbableTimezone(sameDayCutoffString);
    const sameDayCutoffFormatted = formatDate(sameDayCutoff, DateFormats.LOCAL_TIME_AM_PM_TIMEZONE);
    const nextDayCutoff = getDateTimeObjectForProbableTimezone(nextDayCutoffString);
    const nextDayCutoffFormatted = formatDate(nextDayCutoff, DateFormats.LOCAL_TIME_AM_PM_TIMEZONE);
    const exampleAmount = parseCurrency(5000);
    const nextDayAmount = parseCurrency(nextDayRate);
    const sameDayAmount = parseCurrency(sameDayRate);
    return {
        exampleAmount,
        nextDayAmount,
        nextDayCutoff: nextDayCutoffFormatted,
        nextDayTitle,
        sameDayAmount,
        sameDayCutoff: sameDayCutoffFormatted,
        sameDayTitle,
    };
};
const getBalanceFAQItemUsage = ({ exampleAmount, nextDayTitle, rtpTitle, sameDayTitle }) => {
    const question = `What is my ${PLATFORM_DISPLAY_SHORT_NAME} balance used for?`;
    const answer = (React.createElement(BalanceFAQItemUsageAnswer, { exampleAmount: exampleAmount, nextDayTitle: nextDayTitle, rtpTitle: rtpTitle, sameDayTitle: sameDayTitle }));
    return {
        question,
        answer,
    };
};
const getBalanceFAQItemFees = () => {
    const question = `How do transaction fees work for ${PLATFORM_DISPLAY_SHORT_NAME} Balance payments?`;
    const answer = React.createElement(FullListOf, { listType: "payment fees" });
    return {
        question,
        answer,
    };
};
const getBalanceFAQItemCutoff = () => {
    const question = `What are the cut-off times for ${PLATFORM_DISPLAY_SHORT_NAME} Balance payments?`;
    const answer = React.createElement(FullListOf, { listType: "cut-off times" });
    return {
        question,
        answer,
    };
};
export const getBalanceFAQItems = ({ nextDayBillingData, rtpBillingData, sameDayBillingData, }) => {
    if (!nextDayBillingData || !sameDayBillingData) {
        return [];
    }
    const { paymentDeliveryOptionExternalName: rtpTitle } = Object(rtpBillingData);
    const { exampleAmount, nextDayTitle, sameDayTitle } = getBalanceFAQItemsStrings(nextDayBillingData, sameDayBillingData);
    const faqItemUsage = getBalanceFAQItemUsage({
        exampleAmount,
        rtpTitle,
        nextDayTitle,
        sameDayTitle,
    });
    const faqItemFees = getBalanceFAQItemFees();
    const faqItemCutoff = getBalanceFAQItemCutoff();
    return [faqItemUsage, faqItemFees, faqItemCutoff];
};

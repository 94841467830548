import React from 'react';

import LinkNewPage from 'components/link/LinkNewPage';
import Text from 'components/text/Text';

import { PLATFORM_DISPLAY_SHORT_NAME, PLATFORM_URL } from 'constants/platform';

import './PoweredByWordmark.scss';

/**
 * Wordmark used in the external footer to throwback to Routable on-click, otherwise we whitelabel the external flow.
 * @returns {StatelessComponent}
 */
const PoweredByWordmark = () => (
  <LinkNewPage className="powered-by-wordmark remove-hover-underline" href={PLATFORM_URL}>
    <span className="powered-by-wordmark__logo icon-ic-logo-letter" />
    <Text.Semibold className="powered-by-wordmark__text">{`Powered by ${PLATFORM_DISPLAY_SHORT_NAME}`}</Text.Semibold>
  </LinkNewPage>
);

export default PoweredByWordmark;

import PropTypes from 'prop-types';
import React from 'react';

import { DisclosureList } from 'components/disclosureList';
import { IconNames } from 'components/icon';

import { breakpointNames } from 'constants/mediaQuery';

import { TaxFaqListItem } from './components';

import './TaxFaqDisclosureList.scss';

/**
 * Shrinkable/expandable section for displaying a FAQ. The header is the question; the body is the answer.
 * @param answer
 * @param question
 * @returns {*}
 * @constructor
 */
const TaxFaqDisclosureList = ({ answer, question }) => (
  <DisclosureList
    bodyRowProps={{
      breakpointProps: {
        [breakpointNames.allMedia]: {
          cols: 1,
        },
      },
    }}
    className="tax-faq-disclosure-list"
    headerIconName={IconNames.HELP}
    headerText={question}
    isBodyInset={false}
    items={[
      {
        component: TaxFaqListItem,
        key: question,
        value: answer,
        wrapWithCol: false,
      },
    ]}
    keyExtractor={(item) => item.key}
    startOpen={false}
    style={{ backgroundColor: 'transparent' }}
  />
);

TaxFaqDisclosureList.propTypes = {
  answer: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
};

export default TaxFaqDisclosureList;

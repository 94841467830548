import { itemRelationships } from 'constants/relationships';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import * as types from 'types/transactions';

import initialState from './initialState';

const byIdReducer = (state = initialState.byId, action) => {
  switch (action.type) {
    case types.FETCH_TRANSACTIONS_SUCCESS:
      return deepMergeWithArrayReplacement(
        state,
        getObjectsByIdWithRelationships(action.payload.item, itemRelationships),
      );

    default:
      return state;
  }
};

export default byIdReducer;

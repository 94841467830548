import { FundingAccountTypes } from 'constants/funding';

/**
 * Returns initial values for bank account, for create partnership form.
 * @return {Object}
 */
export const getInitialBankAccountValues = () => ({
  bankAccountNumber: '',
  bankAccountType: FundingAccountTypes.CHECKING,
  bankRoutingNumber: '',
  confirmBankAccountNumber: '',
});

import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

import Icon, { IconNames } from 'components/icon';

import confirmAlert from 'helpers/confirmAlert';

const { ClearIndicator } = components;

const ClearIndicatorWithConfirm = (props) => {
  const { innerProps, onConfirm, selectProps, ...rest } = props;

  const { onMouseDown, onTouchEnd } = innerProps;
  const {
    clearIndicatorAlertMessage,
    clearIndicatorAlertTitle = 'Are you sure?',
    clearIndicatorShouldConfirm = true,
  } = selectProps;

  const onMouseDownOrTouchEnd = React.useCallback(
    (evt, eventCallback) => {
      if (evt && evt.persist) {
        evt.persist();
      }

      const handleConfirm = async () => {
        // if we don't need confirmation, default shouldClear to true
        let shouldClear = !clearIndicatorShouldConfirm;

        if (clearIndicatorShouldConfirm) {
          shouldClear = await onConfirm(clearIndicatorAlertMessage, clearIndicatorAlertTitle);
        }

        if (shouldClear) {
          eventCallback(evt);
        }
      };

      handleConfirm();
    },
    [clearIndicatorAlertMessage, clearIndicatorAlertTitle, clearIndicatorShouldConfirm, onConfirm],
  );

  const handleMouseDown = React.useCallback(
    (evt) => {
      onMouseDownOrTouchEnd(evt, onMouseDown);
    },
    [onMouseDown, onMouseDownOrTouchEnd],
  );

  const handleTouchEnd = React.useCallback(
    (evt) => {
      onMouseDownOrTouchEnd(evt, onTouchEnd);
    },
    [onMouseDownOrTouchEnd, onTouchEnd],
  );

  return (
    <ClearIndicator
      {...rest}
      innerProps={{
        ...innerProps,
        onMouseDown: handleMouseDown,
        onTouchEnd: handleTouchEnd,
      }}
      selectProps={selectProps}
    >
      <Icon className="clear-indicator" icon={IconNames.CROSS} />
    </ClearIndicator>
  );
};

ClearIndicatorWithConfirm.propTypes = {
  innerProps: PropTypes.shape().isRequired,
  onConfirm: PropTypes.func,
  selectProps: PropTypes.shape({
    clearIndicatorAlertMessage: PropTypes.string.isRequired,
    clearIndicatorAlertTitle: PropTypes.string,
    clearIndicatorShouldConfirm: PropTypes.bool,
  }).isRequired,
};

ClearIndicatorWithConfirm.defaultProps = {
  // passing this as a default prop instead of using directly
  // for ease of testing this component
  onConfirm: confirmAlert,
};

export default ClearIndicatorWithConfirm;

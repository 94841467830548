import { Tooltip } from '@routable/components';
import React, {} from 'react';
const getPositionFromPlacement = (placement) => {
    if (placement?.includes?.('bottom')) {
        return 'bottom';
    }
    if (placement?.includes?.('left')) {
        return 'left';
    }
    if (placement?.includes?.('right')) {
        return 'right';
    }
    return 'top';
};
export function MuiTooltipPoly({ TooltipProps, TooltipBodyStyles, TooltipBody, TooltipContentContainerStyles, text, children, placement, position = placement, useTooltipContentContainer = true, ...props }) {
    return text ? (React.createElement(Tooltip, { "data-testid": props['data-testid'], ...TooltipProps, ...props, position: getPositionFromPlacement(position || TooltipProps?.placement), tooltip: TooltipBody || React.createElement("div", { style: TooltipBodyStyles }, text) }, children)) : (children);
}
export function LightTooltip({ text, children, TooltipBaseProps, }) {
    return (React.createElement(MuiTooltipPoly, { text: text, variant: "light", ...TooltipBaseProps }, children));
}
export function DarkTooltip({ text, children, TooltipBaseProps, }) {
    return (React.createElement(MuiTooltipPoly, { text: text, variant: "dark", ...TooltipBaseProps }, children));
}

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { lastStepCompletionTimestampSelector, lastStepUserNamesSelector } from 'queries/currentWorkflowStepSelector';

import { partnershipRequestTypeUserFriendlyOrInternalNameSelector } from 'selectors/partnershipRequestSelectors';

import RecentlyCompletedHint from './RecentlyCompletedHint';

export const mapStateToProps = createStructuredSelector({
  completedDate: lastStepCompletionTimestampSelector,
  partnershipRequestUserFriendlyOrInternalName: partnershipRequestTypeUserFriendlyOrInternalNameSelector,
  users: lastStepUserNamesSelector,
});

export default connect(mapStateToProps)(RecentlyCompletedHint);

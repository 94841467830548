import { PaymentDeliveryMethodType } from '@routable/shared';
import PropTypes from 'prop-types';
import React from 'react';

import { Modal } from 'components/modal';

import { getSidePanelHeaderTextForPaymentMethod } from 'helpers/addPartnerFundingAccount';

import { AddPartnerFundingAccountForm } from 'modules/fundingAccount/components';

const AddPartnerFundingAccountModal = ({ modalState, onCloseModal }) => {
  const { formContext, paymentDeliveryMethod, partnershipId } = modalState;

  return (
    <Modal
      body={
        <AddPartnerFundingAccountForm
          formName={formContext}
          onClose={onCloseModal}
          partnershipId={partnershipId}
          paymentDeliveryMethod={paymentDeliveryMethod}
        />
      }
      hasInlineFooter
      modalHeader={getSidePanelHeaderTextForPaymentMethod(paymentDeliveryMethod)}
      modalState={modalState.open}
      onCloseModal={onCloseModal}
      testId="add-partner-funding-account-modal"
    />
  );
};

AddPartnerFundingAccountModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  modalState: PropTypes.shape({
    formContext: PropTypes.string,
    isSubmitting: PropTypes.bool,
    open: PropTypes.bool,
    partnershipId: PropTypes.string,
    paymentDeliveryMethod: PropTypes.oneOf(Object.values(PaymentDeliveryMethodType)),
  }).isRequired,
};

export default AddPartnerFundingAccountModal;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';

import { changeView } from 'actions/signUpFlow';

import { GuideStatuses } from 'constants/guide';

import GuideSectionStatus from 'modules/dashboard/guide/global/presenters/GuideSectionStatus';
import { allFieldsHaveValues } from 'modules/signup-v3/components/ProgressBarStepperV2';
import { mapViewToFields } from 'modules/signup-v3/fields';

import { signupFlowForm, views } from '../../constants';

const formValueSelector = getFormValues(signupFlowForm);

const LegalName = () => {
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(changeView(views.legalBusinessName));
  };

  const formValues = useSelector(formValueSelector);
  const isComplete = allFieldsHaveValues({
    fields: mapViewToFields[views.legalBusinessName],
    formValues,
  });
  const status = isComplete ? GuideStatuses.COMPLETE : GuideStatuses.INCOMPLETE;

  return (
    <GuideSectionStatus
      callToAction={isComplete ? 'Update' : '+ Add'}
      description={undefined}
      isRenderedOutsideGuideList={false}
      onClick={onClick}
      sectionURL={window.location.pathname}
      showCtaWhenComplete
      status={status}
      title="Legal name"
    />
  );
};

export default LegalName;

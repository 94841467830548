import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Hint, HintTitle } from 'components/hint';

import { Intent } from 'constants/ui';

import { isExternalPathPay } from 'helpers/external';
import { isItemStatusNew } from 'helpers/items';

import ExternalItemsListTable from '../ExternalItemsListTable';

const PartnershipRequestItems = ({ item, partnershipItems, generateSimilarItemURL }) => {
  if (!isExternalPathPay(window.location)) {
    return null;
  }

  if (item && isItemStatusNew(item)) {
    return null;
  }

  return (
    <>
      <Hint
        className={classNames({
          'margin-bottom--large': true,
          'margin-top--large': true,
        })}
        intent={Intent.WARNING}
      >
        <HintTitle
          className={classNames({
            'margin-bottom--m': true,
            'font-color--greyXDark': true,
            semibold: true,
          })}
        >
          Below are invoices you may take action on:
        </HintTitle>
      </Hint>

      <div className="external-items-list">
        <ExternalItemsListTable generateSimilarItemURL={generateSimilarItemURL} items={partnershipItems} />
      </div>
    </>
  );
};

PartnershipRequestItems.propTypes = {
  item: PropTypes.shape(),
  partnershipItems: PropTypes.arrayOf(PropTypes.shape()),
  generateSimilarItemURL: PropTypes.func.isRequired,
};
PartnershipRequestItems.defaultProps = {
  item: undefined,
  partnershipItems: undefined,
};

export default PartnershipRequestItems;

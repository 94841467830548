import styled, { css } from 'styled-components';
import { getCssVariables } from '../helpers/generic.helper';
import { Displays, TableColumnWidthOptions, } from './table.types';
import clsx from 'clsx';
export const TableColumnWidth = Object.fromEntries(Object.entries(TableColumnWidthOptions).map(([name, value]) => [
    `${name.charAt(0).toUpperCase()}${name.slice(1)}`,
    css `
      ${value};
    `,
]));
const generateCellWidths = (cellWidths, hiddenCells, margins) => {
    let cellStyles = '';
    for (let cellIdx = 0; cellIdx < cellWidths.length; cellIdx++) {
        const cellWidth = cellWidths[cellIdx];
        const fixedWidth = TableColumnWidth[cellWidth];
        cellStyles = `
            ${cellStyles}
            .table-header-cell:nth-child(${cellIdx + 1}) ,
            .table-cell:nth-child(${cellIdx + 1}) {
                ${fixedWidth}
                display: ${hiddenCells[cellIdx]};
                margin: ${margins[cellIdx]};
            }
        `;
    }
    return css `
    ${cellStyles}
  `;
};
const baseCellStyle = () => {
    return css `
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    flex-wrap: nowrap;
    text-overflow: ellipsis;
    pointer-events: initial;
    touch-action: initial;
    cursor: default;
    padding: 6px 12px;
    font: var(--font-regular);
    font-size: var(--font-size-1);
    color: var(--color-neutral-black);

    &[data-align-right] {
      text-align: -webkit-right;
      justify-content: flex-end;
      padding-right: var(--spacing-8);
    }

    &[data-align-left] {
      text-align: left;
      text-align: -webkit-left;
    }

    &[data-align-center] {
      text-align: center;
      text-align: -webkit-center;
    }

    &[data-align-vertical] {
      vertical-align: middle;
      display: inline-flex;
      justify-content: center !important;
    }
  `;
};
export const TableFullScreenWrapper = styled.div `
  display: flex;
  height: 100vh;
  flex-direction: column;
`;
export const TableWrapper = styled.div.withConfig({ displayName: 'TableWrapper' }).attrs((props) => ({
    display: props.display || 'full-width',
    fixedWidth: props.fixedWidth,
})) `
    ${(props) => getCssVariables(props.display, Displays)}

    border: var(--table-border);
    border-color: var(--table-border-color);
    border-radius: var(--table-border-radius);
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;

    ${(props) => {
    if (props?.fixedWidth && props?.fixedWidth !== '') {
        return css `
          width: ${props.fixedWidth};
        `;
    }
}};

    & .routable-table {
        border-radius: var(--table-border-radius);
        min-height: var(--table-min-height);
    }

    & .table-footer-with-controls {
        border-bottom-left-radius: var(--table-border-radius);
        border-bottom-right-radius: var(--table-border-radius);
    }

    & .table-columns-header,
    & .table-header-background {
        border: var(--table-header-border);
        border-style: var(--table-header-border-style);
        border-color: var(--table-border-color);
        border-top-left-radius: var(--table-border-radius);
        border-top-right-radius: var(--table-border-radius);
    }

    & .table-header-cell:first-child {
        border-top-left-radius: var(--table-border-radius);
    }

    & .table-header-cell:last-child {
        border-top-right-radius: var(--table-border-radius);
    }

    & .table-row:nth-child(2) {
        margin-top: var(--table-first-row-top-margin);
    }
`;
export const TableContainer = styled.div.attrs((props) => ({
    fixedHeight: props.fixedHeight,
    widths: props.widths,
    hiddenCells: props.hiddenCells,
    margins: props.margins,
    reactTable: props.reactTable,
})) `
  display: grid;
  grid-auto-rows: max-content; // rows from stretching
  overflow: ${(props) => (props.isVerticalScrollDisabled ? 'unset' : 'auto')};
  background-color: var(--white);
  pointer-events: initial;
  touch-action: initial;
  cursor: default;
  flex: 1;

  &[data-dark-mode] {
    background-color: var(--black);
  }

  ${(props) => {
    if (props?.widths && props?.widths.length > 0) {
        return css `
        ${generateCellWidths(props.widths, props.hiddenCells || [], props.margins || [])}
      `;
    }
}};

  ${(props) => props.isVerticalScrollDisabled &&
    css `
      & > [role='row']:last-of-type,
      & > a:last-of-type > [role='row'] {
        border-bottom: none !important;
      }
    `}
`;
export const TableHead = styled.div.attrs(() => ({
    className: 'table-header-background',
    role: 'rowgroup',
})) `
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-grey20);
  border-top: 1px solid var(--color-grey20);
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;

  background-color: var(--table-header-bg-color);
  &[data-dark-mode] {
    background-color: var(--gray-dark6);
  }
`;
export const TableHeaderCell = styled.div.attrs((props) => ({
    className: `table-header-cell ${props.extraClassNames || ''}`,
    role: 'columnheader',
})) `
  ${baseCellStyle};
  padding: var(--spacing-3) var(--spacing-4);
  font: var(--font-semi-bold);
  font-size: var(--font-size-1);
  color: var(--base-text-color);
  background-color: var(--table-header-bg-color);
  ${({ hidden }) => (hidden ? 'display: none !important;' : '')}

  &:not(.is-sorted) {
    &:hover .header-sort-down:not(.header-sort-active) {
      display: block;
      opacity: 0.4;
    }
  }

  &.has-sort {
    display: flex;
    position: relative; // for hover arrow
    overflow: unset; // for hover arrow
    pointer-events: initial;
    touch-action: initial;
    cursor: pointer;

    & * {
      pointer-events: initial;
      touch-action: initial;
      cursor: pointer;
    }
  }
`;
export const TableCell = styled.div.attrs((p) => ({
    className: clsx('table-cell', p.className, p.extraClassNames, p.column),
})) `
  ${baseCellStyle};
  &[data-click] {
    pointer-events: initial;
    touch-action: initial;
    cursor: pointer;
  }
  ${({ hidden }) => (hidden ? 'display: none !important;' : '')}
`;
export const TableCellWrapper = styled(TableCell) `
  padding: 20px 12px;
  line-height: 1.5;
  text-overflow: ${({ truncate = true }) => (truncate ? 'ellipsis' : 'unset')};
`;
export const HeaderCell = styled.span.attrs(() => ({
    className: 'table-header-label',
})) `
  &[data-dark-mode] {
    color: var(--gray-light1);
  }
`;

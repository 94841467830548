import { createErrorReducer } from 'store/redux';

import {
  FETCH_INTEGRATION_CONFIG_FAILURE,
  FETCH_INTEGRATION_CONFIG_REQUEST,
  FETCH_INTEGRATION_CONFIG_SUCCESS,
  FETCH_INTEGRATION_CONFIGS_FAILURE,
  FETCH_INTEGRATION_CONFIGS_REQUEST,
  FETCH_INTEGRATION_CONFIGS_SUCCESS,
} from 'types/integrations';

export const failureCases = [FETCH_INTEGRATION_CONFIG_FAILURE, FETCH_INTEGRATION_CONFIGS_FAILURE];
export const successCases = [
  FETCH_INTEGRATION_CONFIG_REQUEST,
  FETCH_INTEGRATION_CONFIG_SUCCESS,
  FETCH_INTEGRATION_CONFIGS_REQUEST,
  FETCH_INTEGRATION_CONFIGS_SUCCESS,
];

export default createErrorReducer(successCases, failureCases);

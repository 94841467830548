import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';

import { simpleMetaCreator } from 'helpers/routine';

import * as types from 'types/item';

/**
 * @type {ReduxSagaRoutine}
 */
export const submitInvoicesRoutine = createRoutine(types.SUBMIT_INVOICES, null, simpleMetaCreator);

/**
 * @type {ReduxSagaRoutine}
 */
export const submitItemRoutine = createRoutine(types.SUBMIT_ITEM);
/**
 * @type {ReduxSagaRoutine}
 */
export const submitItemHandler = bindRoutineToReduxForm(submitItemRoutine);

/**
 * @type {ReduxSagaRoutine}
 */
export const submitItemEditRoutine = createRoutine(types.SUBMIT_ITEM_EDIT);

/**
 * @type {ReduxSagaRoutine}
 */
export const submitItemEditHandler = bindRoutineToReduxForm(submitItemEditRoutine);

/**
 * @type {ReduxSagaRoutine}
 */
export const sendSubmitItemRequestRoutine = createRoutine(types.SEND_SUBMIT_ITEM_REQUEST);

/**
 * @type {ReduxSagaRoutine}
 */
export const sendSubmitInvoiceRequestRoutine = createRoutine(types.SEND_SUBMIT_INVOICE_REQUEST);

/**
 * @type {ReduxSagaRoutine}
 */
export const sendSubmitMultiInvoiceRequestRoutine = createRoutine(types.SEND_SUBMIT_MULTI_INVOICE_REQUEST);

/**
 * @type {ReduxSagaRoutine}
 */
export const sendSubmitBillToItemRequestRoutine = createRoutine(types.SEND_SUBMIT_BILL_TO_ITEM_REQUEST);

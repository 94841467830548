import { useEventSync } from '@routable/framework';
import {} from '../table.extended.types';
import { useEffect, useState } from 'react';
import { useTableStore } from '../table.store';
export const useTableSelection = ({ tableName }) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const { getTableStateItem } = useTableStore(tableName);
    useEventSync(`table:${tableName}:selected:updated`, (result) => {
        setSelectedItems(result || []);
    });
    useEffect(() => {
        const storeSelectedItems = getTableStateItem('selections');
        if (storeSelectedItems) {
            setSelectedItems(storeSelectedItems || []);
        }
    }, []);
    return selectedItems;
};

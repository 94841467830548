import PropTypes from 'prop-types';
import React from 'react';

import { Radio } from 'components';
import { reduxFormInputPropType } from 'components/commonProps';

import { achDepositTime } from 'constants/paymentMethods';

import { isPaymentDeliveryMethodAch } from 'helpers/paymentMethods';

import PartnerCompanyConnectBank from 'modules/createPartnerCompany/presenters/PartnerCompanyConnectBank';

const ConnectBankRadio = ({
  errors,
  fieldNamePrefix,
  fundingAccount,
  input,
  optionText,
  paymentDeliveryMethod,
  ...rest
}) => (
  <Radio
    {...rest}
    content={
      isPaymentDeliveryMethodAch(paymentDeliveryMethod) && (
        <PartnerCompanyConnectBank errors={errors} fieldNamePrefix={fieldNamePrefix} fundingAccount={fundingAccount} />
      )
    }
    id={`connect-bank-radio--${input.name}`}
    input={input}
    optionText={optionText}
    subText={achDepositTime}
  />
);

ConnectBankRadio.propTypes = {
  errors: PropTypes.shape(),
  fieldNamePrefix: PropTypes.string,
  fundingAccount: PropTypes.string,
  input: reduxFormInputPropType.isRequired,
  optionText: PropTypes.string.isRequired,
  paymentDeliveryMethod: PropTypes.string,
};

ConnectBankRadio.defaultProps = {
  errors: {},
  fieldNamePrefix: undefined,
  fundingAccount: undefined,
  paymentDeliveryMethod: undefined,
};

export default ConnectBankRadio;

export const querySelector = (query) => document.querySelector(query);

export const scrollToElement = (element) => element?.scrollIntoView?.({ behavior: 'smooth' });

/**
 * Given an element, returns its rendered size.
 * If no element, all properties will be undefined.
 * @param {HTMLElement} [element]
 * @return {Size}
 */
export const measureElement = (element) => ({
  width: element?.offsetWidth,
  height: element?.offsetHeight,
});

/**
 * Determine if an element (specifically an input element) is truncated or not.
 * This most likely means that the input's value is longer than the width that
 * the input is given to render within.
 * @param {HTMLElement} [element]
 * @returns {boolean}
 */
export const isElementTruncated = (element) => {
  if (!element) {
    return false;
  }

  return element.scrollWidth > element.offsetWidth;
};

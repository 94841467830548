import { getFeatureSettingsRoutine } from 'actions/routines/featureSettings';
import {} from 'interfaces/actions';
import { initialState } from './initialState';
const dashboardLoadedFeatureSettingsReducer = (state = initialState.featureSettings, action) => {
    switch (action.type) {
        case getFeatureSettingsRoutine.SUCCESS:
            return true;
        default:
            return state;
    }
};
export default dashboardLoadedFeatureSettingsReducer;

import { useQuery } from '@tanstack/react-query';
import { taxTools } from '../helpers';
import { countryGroup, taxInfoStatus, taxToolsFiltersModel } from '../models';
import { taxToolsApi } from '../services';
import { queryContext } from '@routable/shared';
export const generateUseTaxToolsExportAvailable = (api, format) => ({ enabled = true, filters: selectedFilters = {} }) => {
    const countryGroupFilter = format === '1099' ? countryGroup.Enum.domestic : countryGroup.Enum.international;
    const mergedFilters = {
        ...selectedFilters,
        countryGroup: countryGroupFilter,
        taxInfoStatus: taxInfoStatus.Enum.completed,
    };
    const parsedInitialFilters = taxToolsFiltersModel.safeParse(mergedFilters);
    const filters = parsedInitialFilters.success ? parsedInitialFilters.data : {};
    const result = useQuery({
        context: queryContext,
        queryKey: [taxTools, 'TaxExportAvailable', format, filters],
        enabled,
        queryFn: async () => api.readAll({ filters, limit: 1, offset: 0 }),
        select: ({ data }) => data.length > 0,
    });
    return result;
};
export const useTaxTools1099ExportAvailable = generateUseTaxToolsExportAvailable(taxToolsApi, '1099');
export const useTaxTools1042ExportAvailable = generateUseTaxToolsExportAvailable(taxToolsApi, '1042');

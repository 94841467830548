import React from 'react';
import { ModalFooter, ButtonV2 } from 'components';
import { Text } from 'components/text';
import { ButtonSize } from 'constants/button';
import { colors, typography } from 'constants/styles';
import { TextColor, TextSize } from 'constants/styles/typography';
import { Intent } from 'constants/ui';
import { modalCopies } from '../../constants/modalCopies';
import { ModalKeepWorkingButton, ModalSaveButton } from './components/ModalBodyButtons';
import { BodyContainer, Subtext } from './SkipStepModal.styles';
import {} from './types/SkipStepModal.types';
const SkipStepModalBody = ({ invalid, onClose, onSaveChanges, onSkipStep }) => (React.createElement(React.Fragment, null,
    React.createElement(BodyContainer, null,
        React.createElement(Text.Bold, { color: TextColor.BLUE_DARK, size: TextSize.LEVEL_400 }, "Before you continue..."),
        React.createElement(Subtext, null,
            React.createElement(Text.Regular, { color: typography.TextColor.GREY_XX_DARK, lineHeight: typography.TextLineHeight.EXTRA_LARGE, size: typography.TextSize.LEVEL_200 }, invalid ? modalCopies.SKIP_STEP_INVALID_FORM : modalCopies.SKIP_STEP_VALID_FORM))),
    React.createElement(ModalFooter, { style: {
            backgroundColor: colors.colorWhiteHex,
        } },
        React.createElement("div", { className: "content--left" },
            React.createElement(ButtonV2, { id: "skip-button", intent: Intent.NEUTRAL, onClick: onSkipStep, size: ButtonSize.MEDIUM, type: "button" }, "Leave without saving")),
        React.createElement("div", { className: "content--right" }, invalid ? React.createElement(ModalKeepWorkingButton, { onClose: onClose }) : React.createElement(ModalSaveButton, { onSaveChanges: onSaveChanges })))));
export default SkipStepModalBody;

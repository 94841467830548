import { createByIdReducer } from 'store/redux';

import { FETCH_PARTNERSHIP_SUCCESS, UPDATE_PARTNERSHIP_GENERAL_INFO_SUCCESS } from 'types/partnership';

export default createByIdReducer({
  initialState: {},
  key: 'companyInfo',
  replaceState: true,
  types: [FETCH_PARTNERSHIP_SUCCESS, UPDATE_PARTNERSHIP_GENERAL_INFO_SUCCESS],
  relationships: ['info'],
});

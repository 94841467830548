import PropTypes from 'prop-types';
import React from 'react';

import { FieldEditButton } from 'components/form/FieldEditWrapper/components';

/**
 * An HOC with a result similar to `EditButtonInput` component, but 1) does not include a nested Field
 * or field wrapper by default (allowing for any composition of wrappers to be rendered), and
 * 2) will inject the FieldEditButton as an additional child of the static component (when
 * toggling edit is allowed), so it can be used in the generic case.
 * @param {function} Component
 * @param {function|undefined} [StaticComponent=undefined]
 * @returns {StatelessComponent}
 */
const withInputEditButton = (Component, StaticComponent = undefined) => {
  const ComponentWithInputEditButton = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, editAllowed, editButtonProps, editField, onEdit, showField, ...rest } = props;

    if (editField) {
      return <Component {...rest}>{children}</Component>;
    }

    // purely so we don't need to pass repetitive arguments, e.g.--
    // const MyEditableInput = withEditButtonInput(SomeComponent, SomeComponent);
    const RenderStaticComponent = StaticComponent || Component;

    return (
      <RenderStaticComponent {...rest} isDisabled>
        <>
          {children}
          {editAllowed && <FieldEditButton {...editButtonProps} onEdit={onEdit} />}
        </>
      </RenderStaticComponent>
    );
  };

  ComponentWithInputEditButton.propTypes = {
    children: PropTypes.node,
    editAllowed: PropTypes.bool,
    editButtonProps: PropTypes.shape(),
    editField: PropTypes.bool,
    onEdit: PropTypes.func.isRequired,
    showField: PropTypes.bool,
  };

  ComponentWithInputEditButton.defaultProps = {
    children: undefined,
    editAllowed: undefined,
    editButtonProps: {},
    editField: undefined,
    showField: true,
  };

  const componentDisplayName = Component.displayName || 'Component';
  ComponentWithInputEditButton.displayName = `${componentDisplayName}WithEditButton`;

  return ComponentWithInputEditButton;
};

export default withInputEditButton;

import React from 'react';
import { IconNames } from 'components/icon';
import { LinkNewPage } from 'components/link';
import { Text, WhiteSpace } from 'components/text';
import { TooltipMUIIcon } from 'components/tooltip';
import { colors } from 'constants/styles';
import { TextSize } from 'constants/styles/typography';
export const InfoIconWithTooltip = ({ link, text }) => (React.createElement(TooltipMUIIcon, { icon: IconNames.INFO_SIGN, iconColor: colors.colorGreyMed, title: React.createElement(Text.Regular, { size: TextSize.LEVEL_100 },
        text,
        React.createElement(WhiteSpace, null),
        link && (React.createElement(LinkNewPage, { className: "primary", href: link }, "Learn more"))), tooltipProps: { arrow: true } }));

import PropTypes from 'prop-types';
import React from 'react';

import { isUndef } from 'helpers/utility';

import * as commonProps from '../commonProps';

/**
 * InputFieldInner is rendered exclusively by InputField.
 * InputField passes its forwardRef argument `ref` as `setRef`,
 * allowing us to both 1) use refs on this component, and 2) use
 * propTypes and defaultProps on this component (can't use them
 * with forwardRef functions).
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 */
const InputFieldInner = (props) => {
  const {
    autoComplete,
    autoFocus,
    className,
    dataFullStory,
    dataId,
    dataTestId,
    defaultValue,
    errName,
    inputId,
    isDisabled,
    isRequired,
    label,
    max,
    maxLength,
    maximumCharacterCount,
    min,
    name,
    onBlur,
    onChange,
    onFocus,
    onKeyPress,
    pattern,
    placeholder,
    setIsRequired,
    setRef,
    step,
    style,
    type,
    validator,
    value,
  } = props;

  return (
    <input
      aria-label={label || placeholder}
      autoComplete={autoComplete}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      className={className}
      data-errname={errName}
      data-fullstory={dataFullStory}
      data-id={dataId}
      data-testid={dataTestId}
      data-validator={validator}
      disabled={isDisabled}
      id={inputId}
      max={max}
      maxLength={maximumCharacterCount || maxLength}
      min={min}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onKeyPress={onKeyPress}
      pattern={pattern}
      ref={setRef}
      required={isRequired && setIsRequired}
      step={step}
      style={style}
      type={type}
      value={isUndef(value) ? defaultValue : value}
    />
  );
};

InputFieldInner.propTypes = {
  ...commonProps.propTypes,
  autoComplete: PropTypes.string,
  setIsRequired: PropTypes.bool,
  setRef: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]),
};

InputFieldInner.defaultProps = {
  ...commonProps.defaultProps,
  autoComplete: undefined,
  setIsRequired: undefined,
  setRef: undefined,
};

export default InputFieldInner;

import { fetchItemsRoutine } from 'actions/routines/item';
import { fetchPartnershipItemsRoutine } from 'actions/routines/partnership';

import { createIsFetchingReducer } from 'store/redux';

import {
  FETCH_ITEM_AND_THREAD_REQUEST,
  FETCH_ITEM_REQUEST,
  FETCH_ITEMS_EXPORT_REQUEST,
  FETCH_ITEM_FAILURE,
  FETCH_ITEM_SUCCESS,
  FETCH_ITEMS_EXPORT_FAILURE,
  FETCH_ITEMS_EXPORT_SUCCESS,
} from 'types/item';
import {
  FETCH_PARTNERSHIP_ITEMS_REQUEST,
  FETCH_PARTNERSHIP_ITEMS_FAILURE,
  FETCH_PARTNERSHIP_ITEMS_SUCCESS,
} from 'types/partnership';

export const finishCases = [
  fetchItemsRoutine.FAILURE,
  fetchItemsRoutine.SUCCESS,
  FETCH_ITEM_FAILURE,
  FETCH_ITEM_SUCCESS,
  FETCH_ITEMS_EXPORT_FAILURE,
  FETCH_ITEMS_EXPORT_SUCCESS,
  FETCH_PARTNERSHIP_ITEMS_FAILURE,
  FETCH_PARTNERSHIP_ITEMS_SUCCESS,
  fetchPartnershipItemsRoutine.FAILURE,
  fetchPartnershipItemsRoutine.SUCCESS,
];
export const requestCases = [
  fetchPartnershipItemsRoutine.REQUEST,
  fetchItemsRoutine.REQUEST,
  FETCH_ITEMS_EXPORT_REQUEST,
  FETCH_ITEM_AND_THREAD_REQUEST,
  FETCH_ITEM_REQUEST,
  FETCH_PARTNERSHIP_ITEMS_REQUEST,
];

const isFetchingReducer = createIsFetchingReducer(finishCases, requestCases);

export default isFetchingReducer;

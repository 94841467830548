import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import withBankAccount from 'hoc/withBankAccount';

import { isCsvImportEnabledSelector } from 'queries/bulkActionsCompoundSelectors';
import { fundingAccountCreatorFromPropSelector } from 'queries/fundingCompoundSelectors';

import AccountDetailsSection from './AccountDetailsSection';

const mapStateToProps = createStructuredSelector({
  creator: fundingAccountCreatorFromPropSelector,
  enableCsvImport: isCsvImportEnabledSelector,
});

const enhance = compose(withBankAccount, connect(mapStateToProps));

export default enhance(AccountDetailsSection);

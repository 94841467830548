/**
 * When accepting a MembershipInvite, an error occurred. Which type of error?
 * @enum {string}
 */
export const AcceptingMembershipInvitePageErrorType = {
  FORM: 'form', // when the user enters a password which is too simple, the BE sends errors to help increase complexity
  GENERIC: 'generic', // when the token is expired
  NOT_FOUND: 'not found', // when the membership invite has been revoked
  UNKNOWN: 'unknown', // a catch-all for cases we haven't considered
};

/**
 * Describes the paths to find the data for various AcceptingMembershipInvitePageErrorTypes
 * @enum {string}
 */
export const AcceptingMembershipInvitePageErrorPath = {
  [AcceptingMembershipInvitePageErrorType.FORM]: 'form',
  [AcceptingMembershipInvitePageErrorType.GENERIC]: 'errors.detail',
  [AcceptingMembershipInvitePageErrorType.NOT_FOUND]: 'errors.errors.notFound',
  [AcceptingMembershipInvitePageErrorType.UNKNOWN]: '',
};

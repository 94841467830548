import {} from 'axios';
import {} from 'redux';
import { getQueryString } from 'helpers/queryParams';
import {} from 'interfaces/global';
import { createItemEndpoint } from 'services/api/itemEndpoints';
import { getPartnershipItemsEndpoint } from 'services/api/partnershipsEndpoints';
import { paymentsEndpoint, getPaymentsEndpoint } from 'services/api/paymentsEndpoint';
import { FETCH_PARTNERSHIP_PAYMENT_SUCCESS } from 'types/payment';
import { createApiRoutine } from './routines/createApiRoutine';
export const paymentsRoutine = createApiRoutine('PAYMENTS', {
    endpoint: paymentsEndpoint,
    method: 'GET',
    requireAuth: true,
    headers: {},
}, {
    trigger: ({ endpoint, ...options }) => ({
        endpoint: endpoint || getPaymentsEndpoint(options),
        ...options,
    }),
});
const TRIGGER = `PAYMENT_ITEMS/TRIGGER`;
const SUCCESS = `PAYMENT_ITEMS/SUCCESS`;
const FAILURE = `PAYMENT_ITEMS/FAILURE`;
const success = (data) => ({
    type: SUCCESS,
    payload: data,
});
const failure = (data) => ({ type: FAILURE, error: data });
const getParams = (params) => (params ? `?${getQueryString(params)}` : ``);
const getEndpoint = (partnershipId, params) => partnershipId ? getPartnershipItemsEndpoint(partnershipId, params) : `${createItemEndpoint}${getParams(params)}`;
const trigger = ({ partnershipId, params = {}, sort, }) => ({
    type: TRIGGER,
    payload: {
        endpoint: getEndpoint(partnershipId, {
            sort,
            payment__isnull: true,
            ...params,
        }),
        method: 'GET',
        requireAuth: true,
        onSuccess: success,
        onFailure: failure,
    },
});
export const paymentsItemsRoutine = {
    TRIGGER,
    trigger,
    SUCCESS,
    success,
    FAILURE,
    failure,
};
export const fetchPartnershipPaymentOptionsSuccess = (data) => ({
    type: FETCH_PARTNERSHIP_PAYMENT_SUCCESS,
    payload: data,
});

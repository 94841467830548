import { createPartnershipMemberRoutine } from 'actions/routines/partnershipMember';

import { commonFormFields } from 'constants/formFields';

import { parseFormPartnershipMember } from 'data/parse/partnershipMember';

import { getPartnershipMemberWithAccessNotificationGeneralFromDefaultGeneral } from 'helpers/partnershipMembers';

const parserOptions = {
  partnershipMembersFieldName: commonFormFields.PARTNERSHIP_MEMBERS,
  transformPartnershipMember: getPartnershipMemberWithAccessNotificationGeneralFromDefaultGeneral,
};

const sendUpdatePaymentLinkFormReducer = (state = undefined, action) => {
  if (!state?.values) {
    return state;
  }

  switch (action.type) {
    case createPartnershipMemberRoutine.SUCCESS:
      return parseFormPartnershipMember.getNextFormStateForAppendSinglePartnershipMember(state, action, parserOptions);

    default:
      return state;
  }
};

export default sendUpdatePaymentLinkFormReducer;

import * as types from 'types/transactions';

import initialState from './initialState';

export default (state = initialState.isFetching, action) => {
  switch (action.type) {
    case types.FETCH_ITEM_TRANSACTIONS_REQUEST:
    case types.FETCH_TRANSACTIONS_REQUEST:
      return true;

    case types.FETCH_ITEM_TRANSACTIONS_FAILURE:
    case types.FETCH_ITEM_TRANSACTIONS_SUCCESS:
    case types.FETCH_TRANSACTIONS_FAILURE:
    case types.FETCH_TRANSACTIONS_SUCCESS:
      return false;

    default:
      return state;
  }
};

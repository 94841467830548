/**
 * Decorate each row with click handlers to resend or revoke invite.
 * @function
 * @param {ComponentProps} props
 * @param {PartnershipMember[]} props.data
 * @param {?Array} props.dataAdditional
 * @param {function} props.onResendInvite
 * @param {function} props.onRevokeInvite
 * @returns {PartnershipMember[]}
 */
export const addActionClickHandlers = ({ data, dataAdditional, onResendInvite, onRevokeInvite }) =>
  data.map((rowData) => ({
    ...rowData,
    dataAdditional,
    onResendInvite: () => onResendInvite(rowData),
    onRevokeInvite: () => onRevokeInvite(rowData),
  }));

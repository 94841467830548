import { ADMISSION, AdmissionRoutes } from 'constants/routes';
import { routableApi, RoutableTags } from '../routableApi';
export const permissionsEndpoint = `/${ADMISSION}/${AdmissionRoutes.PERMISSIONS}/`;
const extendedPermissionsApi = routableApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getPermissions: builder.query({
            query: () => ({
                url: permissionsEndpoint,
            }),
            providesTags: [RoutableTags.Permissions],
        }),
    }),
});
export const { useGetPermissionsQuery } = extendedPermissionsApi;

import { updateCurrentCompanySettingsIntegrationRoutine } from 'actions/routines/currentCompany';

import { createLastUpdatedReducer } from 'store/redux';

export const successCases = [updateCurrentCompanySettingsIntegrationRoutine.SUCCESS];
export const requestCases = [
  updateCurrentCompanySettingsIntegrationRoutine.REQUEST,
  updateCurrentCompanySettingsIntegrationRoutine.FAILURE,
];

const lastUpdatedReducer = createLastUpdatedReducer(successCases, requestCases);

export default lastUpdatedReducer;

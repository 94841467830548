import PropTypes from 'prop-types';
import React from 'react';

import { SwalWrapper, RestrictedPermissionContent } from 'components';

import { RestrictedFeatureModalText } from 'constants/swal';

/**
 * Simple SWAL which displays an image and text explaining that the user does not have access to this page because they
 * do not have the necessary permissions.
 *
 * @param {ComponentProps} props
 * @param {function} props.onConfirm
 * @returns {StatelessComponent}
 */
const RestrictedFeatureModal = ({ onConfirm }) => (
  <SwalWrapper
    confirmButtonProps={{
      onClick: onConfirm,
      text: RestrictedFeatureModalText.CONFIRM_TEXT,
    }}
    footerClassname="footer-ghost"
    hasCloseButton={false}
    imageProps={{
      alt: RestrictedFeatureModalText.IMAGE_ALT,
      src: RestrictedFeatureModalText.IMAGE_SRC,
    }}
    subtitle={<RestrictedPermissionContent isTooltip={false} />}
    title={RestrictedFeatureModalText.TITLE}
  />
);

RestrictedFeatureModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
};

export default RestrictedFeatureModal;

import { GuideStatuses } from 'constants/guide';

import {
  isCurrentCompanyAccountVerified,
  isCurrentCompanyAchCustomerApproved,
  isCurrentCompanyKycStatusDocument,
  isCurrentCompanyKycStatusFailed,
  isCurrentCompanyKycStatusPending,
  isCurrentCompanyKycStatusVerified,
  isCurrentCompanyVerificationInfoSubmitted,
} from 'helpers/currentCompany';
import { hasLength } from 'helpers/utility';

export const hasCompanyKYBFailed = ({ currentCompany, rejectedCompanyDocuments }) =>
  currentCompany?.id && (isCurrentCompanyKycStatusFailed(currentCompany) || hasLength(rejectedCompanyDocuments));

/**
 * Determine the company status to display on the guide
 * @param {object} company
 * @param {array} rejectedDocuments
 * @return {string}
 */
export const getCompanyGuideStatus = (company, rejectedDocuments) => {
  if (isCurrentCompanyAccountVerified(company)) {
    return GuideStatuses.COMPLETE;
  }

  if (
    hasCompanyKYBFailed({
      currentCompany: company,
      rejectedCompanyDocuments: rejectedDocuments,
    })
  ) {
    return GuideStatuses.FAILED;
  }

  if (
    isCurrentCompanyVerificationInfoSubmitted(company) ||
    isCurrentCompanyKycStatusPending(company) ||
    isCurrentCompanyKycStatusDocument(company)
  ) {
    return GuideStatuses.PENDING;
  }

  if (isCurrentCompanyKycStatusFailed(company)) {
    return GuideStatuses.FAILED;
  }

  return GuideStatuses.INCOMPLETE;
};

export const hasAnyBusinessRepresentativeKYCFailed = ({ failedBeneficiaries, rejectedDocuments }) =>
  hasLength(rejectedDocuments) || hasLength(failedBeneficiaries);

/**
 * Determine the representatives status to display on the guide
 * @param {object} company
 * @param {array} rejectedDocuments
 * @param {array} failedBeneficiaries
 * @param {array} membershipsWithPersonalInfoAdded
 * @return {string}
 */
export const getRepresentativesGuideStatus = (
  company,
  rejectedDocuments,
  failedBeneficiaries,
  membershipsWithPersonalInfoAdded,
) => {
  if (isCurrentCompanyAccountVerified(company) && !hasLength(failedBeneficiaries)) {
    return GuideStatuses.COMPLETE;
  }

  if (
    hasAnyBusinessRepresentativeKYCFailed({
      failedBeneficiaries,
      rejectedDocuments,
    })
  ) {
    return GuideStatuses.FAILED;
  }

  if (
    isCurrentCompanyKycStatusPending(company) ||
    isCurrentCompanyKycStatusDocument(company) ||
    hasLength(membershipsWithPersonalInfoAdded)
  ) {
    return GuideStatuses.PENDING;
  }

  return GuideStatuses.INCOMPLETE;
};

/**
 * Determine the verification status to display on the guide
 * @param {object} company
 * @param {array} rejectedDocuments
 * @param {Membership[]} failedBeneficiaries
 * @return {string}
 */
export const getVerificationGuideStatus = (company, rejectedDocuments, failedBeneficiaries) => {
  if (isCurrentCompanyAccountVerified(company)) {
    return GuideStatuses.COMPLETE;
  }

  if (hasLength(failedBeneficiaries) || hasLength(rejectedDocuments)) {
    return GuideStatuses.FAILED;
  }

  if (isCurrentCompanyKycStatusPending(company) || isCurrentCompanyKycStatusDocument(company)) {
    return GuideStatuses.PENDING;
  }

  if (isCurrentCompanyKycStatusVerified(company) && !isCurrentCompanyAchCustomerApproved(company)) {
    return GuideStatuses.PENDING;
  }

  return GuideStatuses.INCOMPLETE;
};

/**
 * Determines whether all steps in the guide's next steps section are complete.
 * @param values {Object} Object with the relevant properties.
 * @returns {boolean}
 */
export const areAllNextStepsItemsCompleted = ({ currentUser, integrationConfigs, invites, logo }) => {
  const logoAdded = Boolean(logo);
  const invitesSent = hasLength(Object.keys(invites));
  const avatarExists = currentUser && currentUser.avatar;
  const integrationAdded = hasLength(Object.keys(integrationConfigs));
  return Boolean(logoAdded && invitesSent && avatarExists && integrationAdded);
};

/**
 * Returns whether the guide's next steps statuses have updated, and what their current
 * status is (true === all next steps completed).
 * @param prevValues
 * {?{ currentUser: Object, integrationConfigs: Object, invites: Object, logo: string }}
 * Last known values of relevant properties, or null if unknown.
 * @param nextValues
 * {{ currentUser: Object, integrationConfigs: Object, invites: Object, logo: string }}
 * Next known values of relevant properties.
 * @returns {{status, changed: boolean}}
 */
export const getNextStepsStatusAndChanged = (prevValues, nextValues) => {
  // last known state of various next steps was not completed
  // check for !prevValues here since previous values can be unknown (null)
  const prevStepsIncomplete = !prevValues || !areAllNextStepsItemsCompleted(prevValues);
  // next known state of various next steps is completed
  const nextStepsCompleted = areAllNextStepsItemsCompleted(nextValues);

  return {
    status: nextStepsCompleted,
    changed: prevStepsIncomplete && nextStepsCompleted,
  };
};

/**
 * Returns whether the guide's verified status has updated, and whether the status is now verified.
 * @param prevProps {?Object} Last known value for currentCompany, or null if unknown.
 * @param nextProps {Object} Next known value for currentCompany.
 * @returns {{status, changed: boolean}}
 */
export const getVerifiedStatusAndChanged = (prevProps, nextProps) => {
  // last known state was not verified
  // check for !prevCurrentCompany here since previous value can be unknown (null)
  const prevNotVerified =
    !prevProps || !prevProps.currentCompany || !isCurrentCompanyAccountVerified(prevProps.currentCompany);
  const prevNoValidAccounts = !prevProps || !hasLength(prevProps.validAccounts);
  const prevStatusUnverified = prevNotVerified || prevNoValidAccounts;

  // next known state is verified
  const nextIsVerified = isCurrentCompanyAccountVerified(nextProps.currentCompany);
  const nextValidAccounts = hasLength(nextProps.validAccounts);
  const nextStatusVerified = nextIsVerified && nextValidAccounts;

  return {
    status: nextStatusVerified,
    changed: prevStatusUnverified && nextStatusVerified,
  };
};

import { shouldDisplayAmountAboveLimitWarning, shouldDisplayBalanceTooLowWarning, shouldDisplayMaxTransactionFeeWarning, shouldDisplayRTPEligibilityWarning, } from './helpers';
import { useSingleItemCutoffWarning } from './useItemCutoffWarning';
import { WarningTypes } from './useItemWarnings.types';
export const useItemWarnings = ({ itemKind, dateScheduledType, partnershipFundingAccounts, paymentTotalAmount, balanceAmount, paymentMaxIndividualAmount, currentBillingData, }) => {
    const { paymentSource, paymentDeliveryOption } = currentBillingData || {};
    const showAfterCutoffTimeWarning = useSingleItemCutoffWarning({
        dateScheduledType,
        currentBillingData,
    });
    const showRTPEligibilityWarning = shouldDisplayRTPEligibilityWarning({
        partnershipFundingAccounts,
        paymentDeliveryOption,
    });
    const showBalanceTooLowWarning = shouldDisplayBalanceTooLowWarning({
        paymentTotalAmount,
        balanceAmount,
        paymentSource,
    });
    const showAmountAboveLimitWarning = shouldDisplayAmountAboveLimitWarning({
        paymentMaxIndividualAmount,
        currentBillingData,
    });
    const showMaxTransactionFeeWarning = shouldDisplayMaxTransactionFeeWarning({
        itemKind,
        showAmountAboveLimitWarning,
    });
    const hasBlockerWarnings = showRTPEligibilityWarning || showBalanceTooLowWarning || showAmountAboveLimitWarning;
    return {
        hasBlockerWarnings,
        showWarning: {
            [WarningTypes.AFTER_CUTOFF]: showAfterCutoffTimeWarning,
            [WarningTypes.RTP_ELIGIBILITY]: showRTPEligibilityWarning,
            [WarningTypes.BALANCE_TOO_LOW]: showBalanceTooLowWarning,
            [WarningTypes.AMOUNT_ABOVE_LIMIT]: showAmountAboveLimitWarning,
            [WarningTypes.MAX_FEE]: showMaxTransactionFeeWarning,
        },
    };
};

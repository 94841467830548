import * as routines from 'actions/routines/permissions';

import { createAllIdsReducer } from 'store/redux';

const allIdsReducer = createAllIdsReducer({
  key: 'permission',
  types: [routines.fetchPermissionsRoutine.SUCCESS],
});

export default allIdsReducer;

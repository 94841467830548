import { internationalBeneficiaryFieldName } from '@routable/fund-routes';
import { requiredValidator } from '@routable/shared';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';
import { Field, change, untouch } from 'redux-form';
import { SelectFieldV2 } from 'components';
import { residentialStatusSelectOptions } from './ResidentialStatusField.constants';
export const ResidentialStatusField = ({ className, form }) => {
    const dispatch = useDispatch();
    useUnmount(() => {
        dispatch(change(form, internationalBeneficiaryFieldName.Enum.residential_status, undefined));
        dispatch(untouch(form, internationalBeneficiaryFieldName.Enum.residential_status));
    });
    return (React.createElement("div", { className: className },
        React.createElement(Field, { component: SelectFieldV2, isRequired: true, label: "Residential status", name: internationalBeneficiaryFieldName.Enum.residential_status, options: residentialStatusSelectOptions, validate: [requiredValidator] })));
};

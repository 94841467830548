import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Script from 'react-load-script';
import { connect } from 'react-redux';
import { Field, change, formValueSelector } from 'redux-form';

import {
  Checkbox,
  Emoji,
  FormFieldLabel,
  ReduxFormFieldRenderInput,
  SelectFieldV2,
  TooltipMUIConditionalWrapper,
  WhiteSpace,
} from 'components';

import { countriesMapping, countryListOptions } from 'constants/i18n';
import { field } from 'constants/styles/formStyles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';

import { postalCodeValidator } from 'data/validators/addressValidators';

import { getStateOrProvinceLabelByCountry, getStatesByCountry } from 'helpers/addressHelpers';
import GenericContainer from 'helpers/containers';
import { isCountryCodeUsTerritoryOrCanada } from 'helpers/countries';
import {
  maxCharacterLengthValidator,
  oneOfManyFieldsRequiredValidator,
  requiredValidator,
} from 'helpers/fieldValidation';
import { isFn } from 'helpers/utility';

import { text } from 'modules/address/constants';
import { observeStreetAddress } from 'modules/address/helpers/streetAddress';

import { storeAccessor as store } from 'store/accessor';

const countryOptionsAllowed = Object.keys(countriesMapping);

class AddressReduxForm extends GenericContainer {
  // ************************
  // Aux methods
  // ************************

  validatePrintCompanyOrName = (_value, allValues) => {
    const { addressPath } = this.props;
    const fields = [
      { display: 'company', path: `${addressPath}.printCompany` },
      { display: 'name', path: `${addressPath}.printName` },
    ];

    return oneOfManyFieldsRequiredValidator(allValues, fields);
  };

  /**
   * Method that returns the className for the address sections
   * The last piece either has margin bottom on settings or removes all margin bottom
   * @param isLast
   * @return {string}
   */
  getSectionClassName = (isLast = false) =>
    classNames({
      'form-control': true,
      'remove-margin-bottom': isLast,
    });

  handleToggleAddressPrintOnCheck = () => {
    const { addressPath, formName, formUI, partnerName } = this.props;
    const newShowAddressPrintOnCheck = !formUI.shouldShowAddressPrintOnCheck;

    // Remove contact name if toggled off
    if (!newShowAddressPrintOnCheck) {
      store.dispatch(change(formName, `${addressPath}.printName`, ''));
    }

    // Fill in company name if toggled (either on or off)
    if (partnerName) {
      store.dispatch(change(formName, `${addressPath}.printCompany`, partnerName));
    }

    // Toggle
    store.dispatch(change(formName, 'ui.shouldShowAddressPrintOnCheck', newShowAddressPrintOnCheck));
  };

  // ************************
  // Auto complete
  // ************************

  /**
   * When the user highlights the street address field we try to get their position for better location recommendation.
   * We also limit the search results based on the country options that are passed in.
   */
  initAutoComplete = () => {
    const { addressPath, countryOptions } = this.props;

    const countriesList = countriesMapping[countryOptions];

    const input = document.getElementsByName(`${addressPath}.streetAddress`)[0];

    const options = {
      types: ['geocode'],
    };

    if (countryOptions !== countryListOptions.ALL) {
      const allowedCountries = countriesList.map(({ value }) => value.toLowerCase());
      options.componentRestrictions = { country: allowedCountries };
    }

    // Battle Google Places to turn off browser autocomplete
    observeStreetAddress(input);

    this.autocomplete = new window.google.maps.places.Autocomplete(input, options);

    // Add listener function to be triggered when place is chosen
    this.autocomplete.addListener('place_changed', this.fillInAddress);
  };

  fillInAddress = () => {
    const { countryOptions, onInvalidCountry } = this.props;

    const countriesList = countriesMapping[countryOptions];

    // Get the place details from the auto-complete object.
    // noinspection JSUnresolvedFunction
    const place = this.autocomplete.getPlace();

    if (place.address_components) {
      /**
       * @type {GoogleAddressComponent|undefined}
       */
      const countryComponent = place.address_components.find((component) => component.types.includes('country'));

      if (countryComponent) {
        const allowedCountry = countriesList.find((country) => country.value === countryComponent.short_name);

        if (!allowedCountry && isFn(onInvalidCountry)) {
          onInvalidCountry(countriesList, countryComponent);
          return;
        }
      }
    }

    // Fill in the form for the chosen address
    // with the response data from google places API.
    this.handleAddressAutoComplete(place, this.onAddressAutoCompleteSelect);
  };

  onAddressAutoCompleteSelect = (newAddress) => {
    if (!newAddress) {
      return;
    }
    const { addressPath, formName } = this.props;

    Object.keys(newAddress).forEach((key) => {
      store.dispatch(change(formName, `${addressPath}.${key}`, newAddress[key]));
    });
  };

  onStreetAddressFocus = () => {
    // if the user shares the location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        // noinspection JSUnresolvedVariable, JSUnresolvedFunction
        const circle = new window.google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy,
        });

        // noinspection JSUnresolvedFunction
        this.autocomplete.setBounds(circle.getBounds());
      });
    }
  };

  // ************************
  // Render methods
  // ************************

  renderAutoComplete = () => {
    const { showAddressFields } = this.props;
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    if (!showAddressFields) {
      return null;
    }

    // As per https://stackoverflow.com/questions/75179573/how-to-fix-loading-the-google-maps-javascript-api-without-a-callback-is-not-sup,
    // Google is enforcing their API scripts to have a callback URL to handle various cases such as
    // successfully loading script, receiving an error, etc. Since we handle that differently within
    // our app and we don't really care about this callback (but we do want to silence it), we're passing
    // `Function.prototype` to it.
    const scriptUrl = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=Function.prototype`;

    return <Script onLoad={this.initAutoComplete} url={scriptUrl} />;
  };

  renderStreetAddress = () => {
    const { addressPath, fieldClassName, isFormDisabled, showAddressFields } = this.props;

    if (!showAddressFields) {
      return null;
    }

    return (
      <div className={this.getSectionClassName()}>
        <Field
          className={`${fieldClassName} ${field.xl.twoThirds}`}
          component={ReduxFormFieldRenderInput}
          dataFullStory
          dataTestId="street-field"
          isDisabled={isFormDisabled}
          name={`${addressPath}.streetAddress`}
          onStreetAddressFocus={this.onStreetAddressFocus}
          placeholder="Street address"
          required
          type="text"
          validate={requiredValidator}
        />

        <Field
          className={`${fieldClassName} ${field.xl.third} right`}
          component={ReduxFormFieldRenderInput}
          dataFullStory
          dataTestId="apt-unit-suite-field"
          isDisabled={isFormDisabled}
          isRequired={false}
          name={`${addressPath}.streetAddressUnit`}
          placeholder="Apt/Unit/Suite"
          type="text"
        />
      </div>
    );
  };

  renderCountry = () => {
    const {
      addressPath,
      countryOptions,
      countryFieldTooltipContent,
      enableCountryFieldTooltip,
      isFormDisabled,
      isCountryFieldLocked,
      isCountryFieldDisabled,
    } = this.props;

    const countryField = (
      <div className={this.getSectionClassName()}>
        <Field
          component={SelectFieldV2}
          dataFullStory
          dataTestId="country-field"
          isDisabled={isFormDisabled || isCountryFieldDisabled}
          isLocked={isCountryFieldLocked}
          label="Country"
          name={`${addressPath}.country`}
          options={countriesMapping[countryOptions]}
          required
          type="text"
          validate={requiredValidator}
        />
      </div>
    );

    const shouldRenderTooltip = Boolean(enableCountryFieldTooltip && countryFieldTooltipContent);
    const tooltipProps = shouldRenderTooltip
      ? {
          arrow: true,
          padding: TooltipPadding.LARGE,
          placement: TooltipPlacement.TOP,
          style: { fontSize: '.75rem' },
          title: countryFieldTooltipContent,
        }
      : {};

    return <TooltipMUIConditionalWrapper tooltipProps={tooltipProps}>{countryField}</TooltipMUIConditionalWrapper>;
  };

  renderStateAndPostal = () => {
    const { address, addressPath, fieldClassName, isFormDisabled, showStateAndPostalFields, showAddressFields } =
      this.props;
    const stateOrProvince = getStateOrProvinceLabelByCountry(address);

    if (!showStateAndPostalFields || !showAddressFields) {
      return null;
    }

    const showTextInputForStateOrProvince = isCountryCodeUsTerritoryOrCanada(address?.country);

    return (
      <div className={this.getSectionClassName(true)}>
        <Field
          className={`${fieldClassName} ${field.xl.third}`}
          component={ReduxFormFieldRenderInput}
          dataFullStory
          dataTestId="city-field"
          isDisabled={isFormDisabled}
          name={`${addressPath}.city`}
          placeholder="City"
          required
          type="text"
          validate={requiredValidator}
        />

        {
          // Display select field when we have states/provinces to fill the dropdown with.
          showTextInputForStateOrProvince ? (
            <Field
              component={SelectFieldV2}
              dataFullStory
              dataTestId="state-field"
              isDisabled={isFormDisabled}
              label={stateOrProvince}
              name={`${addressPath}.state`}
              options={getStatesByCountry(address)}
              required
              validate={requiredValidator}
              wrapperClassName={`${field.xl.third} right`}
            />
          ) : (
            // Or show a text input field when we don't have information for the dropdown.
            <Field
              className={`${fieldClassName} ${field.xl.third} right`}
              component={ReduxFormFieldRenderInput}
              dataFullStory
              dataTestId="state-field"
              isDisabled={isFormDisabled}
              name={`${addressPath}.state`}
              placeholder="State or Province"
              required
              type="text"
              validate={[requiredValidator, maxCharacterLengthValidator(2)]}
            />
          )
        }

        <Field
          className={`${fieldClassName} ${field.xl.third} right`}
          component={ReduxFormFieldRenderInput}
          dataFullStory
          dataTestId="postalCode-field"
          isDisabled={isFormDisabled}
          name={`${addressPath}.postalcode`}
          placeholder="Postal code"
          required
          type="text"
          validate={[requiredValidator, postalCodeValidator]}
        />
      </div>
    );
  };

  renderPrintOnCheck = () => {
    const { addressPath, fieldClassName, formUI } = this.props;

    if (!formUI || !formUI.shouldShowAddressPrintOnCheck) {
      return null;
    }

    return (
      <React.Fragment>
        <FormFieldLabel>
          The company and name fields below will be printed on the check
          <WhiteSpace />
          <Emoji ariaLabel="Down arrow" text="⬇" />
        </FormFieldLabel>

        <div className={this.getSectionClassName()}>
          <Field
            className={`${fieldClassName} ${field.xl.left}`}
            component={ReduxFormFieldRenderInput}
            maxLength={40}
            name={`${addressPath}.printCompany`}
            placeholder="Business"
            type="text"
            validate={this.validatePrintCompanyOrName}
          />

          <Field
            className={`${fieldClassName} ${field.xl.right}`}
            component={ReduxFormFieldRenderInput}
            maxLength={40}
            name={`${addressPath}.printName`}
            placeholder="Contact name"
            type="text"
            validate={this.validatePrintCompanyOrName}
          />
        </div>
      </React.Fragment>
    );
  };

  renderPrintOnCheckToggle = () => {
    const { forceHideAddressPrintOnCheckToggle, formUI, showAddressFields } = this.props;

    if (!formUI?.showAddressPrintOnCheckToggle || forceHideAddressPrintOnCheckToggle || !showAddressFields) {
      return null;
    }

    return (
      <div className="margin-top--m">
        <Checkbox
          className="margin-bottom--m"
          content={text.editCheckNameField}
          formState={formUI}
          name="shouldShowAddressPrintOnCheck"
          onChange={this.handleToggleAddressPrintOnCheck}
        />
      </div>
    );
  };

  renderCountryFirst = () => (
    <>
      {this.renderCountry()}
      {this.renderStreetAddress()}
    </>
  );

  render() {
    const { countryBeforeStreet } = this.props;

    return (
      <React.Fragment>
        {this.renderPrintOnCheck()}
        {this.renderAutoComplete()}
        {countryBeforeStreet ? (
          this.renderCountryFirst()
        ) : (
          <>
            {this.renderStreetAddress()}
            {this.renderCountry()}
          </>
        )}
        {this.renderStateAndPostal()}
        {this.renderPrintOnCheckToggle()}
      </React.Fragment>
    );
  }
}

AddressReduxForm.propTypes = {
  address: PropTypes.shape(),
  addressPath: PropTypes.string.isRequired,
  countryOptions: (props, propName, componentName) => {
    const value = props[propName];
    if (!value) {
      return new Error(`${propName} is required`);
    }

    if (!countryOptionsAllowed.includes(value)) {
      return new Error(`Invalid option (${value}) supplied as the ${propName} to ${componentName}`);
    }
    return undefined;
  },
  enableCountryFieldTooltip: PropTypes.bool,
  countryFieldTooltipContent: PropTypes.string,
  fieldClassName: PropTypes.string,
  forceHideAddressPrintOnCheckToggle: PropTypes.bool,
  formName: PropTypes.string.isRequired,
  formUI: PropTypes.shape(),
  showAddressFields: PropTypes.bool,
  showStateAndPostalFields: PropTypes.bool,
  isFormDisabled: PropTypes.bool,
  onInvalidCountry: PropTypes.func,
  countryBeforeStreet: PropTypes.bool,
};

AddressReduxForm.defaultProps = {
  address: undefined,
  countryFieldTooltipContent: undefined,
  enableCountryFieldTooltip: false,
  fieldClassName: '',
  forceHideAddressPrintOnCheckToggle: undefined,
  formUI: null,
  showAddressFields: true, // should render by default
  showStateAndPostalFields: true, // should render by default
  isFormDisabled: false,
  onInvalidCountry: undefined,
  countryBeforeStreet: false,
};

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(props.formName);

  return {
    address: formSelector(state, props.addressPath),
    formUI: formSelector(state, 'ui'),
  };
};

export { AddressReduxForm };
export default connect(mapStateToProps)(AddressReduxForm);

import React from 'react';
import { Text } from 'components/text';
import { typography } from 'constants/styles';
import './AddPaymentMethodDropdownOption.scss';
const AddPaymentMethodDropdownOption = ({ description, onClick, onCloseMenu, title, }) => {
    const handleOnClick = React.useCallback((evt) => {
        onClick();
        onCloseMenu(evt);
    }, [onClick, onCloseMenu]);
    return (React.createElement("li", { className: "add-payment-method-dropdown-option" },
        React.createElement("a", { className: "remove-hover-underline", onClick: handleOnClick, role: "presentation", tabIndex: 0, type: "button" },
            React.createElement(Text.Semibold, { color: typography.TextColor.BLUE_DARK }, title),
            React.createElement(Text.Regular, null, description))));
};
export default AddPaymentMethodDropdownOption;

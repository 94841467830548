import classNames from 'classnames';
import React from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';
import { TooltipMUIConditionalWrapper } from 'components';
import { callWithArgsIfIsFn, isObject, lengthOf, valueOrDefault } from 'helpers/utility';
import { TableCell, TablePagination, TableHeaderCell, TableRow } from './components';
import { tableDefaultProps, tablePropTypes } from './Table.propTypes';
import TableContext from './TableContext';
import './Table.scss';
const Table = ({ children, className, columns, compact, data, dataTestId, getCellProps, getPaginationDetails, getRowProps, hasPagination, pageSize: initialPageSize, hiddenColumns, }) => {
    const [rowData, setRowData] = React.useState([]);
    const memoizedColumns = React.useMemo(() => columns, [columns]);
    React.useEffect(() => {
        setRowData(data?.filter((item) => item !== undefined) || []);
    }, [data]);
    const { canNextPage, canPreviousPage, getTableBodyProps, getTableProps, gotoPage, headerGroups, nextPage, page, pageCount, prepareRow, previousPage, rows, setPageSize, state: { pageIndex, pageSize }, } = useTable({
        columns: memoizedColumns,
        data: rowData,
        initialState: {
            pageIndex: 0,
            pageSize: initialPageSize,
            hiddenColumns,
        },
    }, useSortBy, usePagination);
    const totalResults = React.useMemo(() => lengthOf(rows), [rows]);
    React.useEffect(() => {
        setPageSize(initialPageSize);
    }, [initialPageSize, setPageSize]);
    const onPageSizeChange = (size) => {
        setPageSize(size);
        gotoPage(0);
    };
    const onGoToPage = (destinationPage) => {
        switch (destinationPage) {
            case 'PREV':
                gotoPage(pageIndex - 1);
                break;
            case 'NEXT':
                gotoPage(pageIndex + 1);
                break;
            default:
                gotoPage(destinationPage - 1);
        }
    };
    React.useEffect(() => {
        callWithArgsIfIsFn(getPaginationDetails, {
            canNextPage,
            canPreviousPage,
            currentPage: pageIndex + 1,
            nextPage,
            pageSize,
            previousPage,
            totalResults,
        });
    }, [getPaginationDetails, pageIndex, pageSize]);
    const rowsData = valueOrDefault(page, rows);
    return (React.createElement(TableContext.Provider, { value: {
            compact: !!compact,
        } },
        React.createElement("table", { ...getTableProps(), className: classNames('table-v2 horizontal-scroll-group', {
                'has-children': !!children,
                compact: !!compact,
                [className]: !!className,
            }), "data-testid": dataTestId },
            React.createElement("thead", null, headerGroups.map((headerGroup, idx) => (React.createElement("tr", { key: `header-group-${idx + 1}`, ...headerGroup.getHeaderGroupProps() }, headerGroup.headers.map((column, headerGroupIdx) => (React.createElement(TableHeaderCell, { key: `header-cell-${headerGroupIdx + 1}`, ...column.getHeaderProps(column.getSortByToggleProps()), ...column.props, className: classNames(`cell-width--${column.width}`, {
                    [column.className]: !!column.className,
                }) }, column.render('Header')))))))),
            React.createElement("tbody", { ...getTableBodyProps() }, rowsData.map((row) => {
                prepareRow(row);
                const rowProps = getRowProps(row);
                return (React.createElement(TooltipMUIConditionalWrapper, { key: row.id, tooltipProps: rowProps?.tooltipProps },
                    React.createElement(TableRow, { ...row.getRowProps(rowProps), tabIndex: "0" }, row.cells.map((cell, idx) => {
                        let cellProps = {};
                        if (isObject(cell?.value)) {
                            cellProps = cell.value;
                        }
                        const calculatedCellProps = cell.getCellProps(getCellProps(cell));
                        const { areAnyAttachmentsProcessing, doNotUseCellDisabledStyle, isDisabledByPermissions } = calculatedCellProps;
                        return (React.createElement(TableCell, { key: `table-cell-${idx + 1}}`, ...calculatedCellProps, ...cellProps, className: classNames(`cell-width--${cell.column.width}`, {
                                [cell.column.className]: !!cell.column.className,
                                'cell-disabled': (areAnyAttachmentsProcessing && !doNotUseCellDisabledStyle) || isDisabledByPermissions,
                                'attachments-cell-disabled': areAnyAttachmentsProcessing,
                            }) }, cell.render('Cell', {
                            ...getCellProps(),
                            tooltipProps: rowProps?.tooltipProps,
                        })));
                    }))));
            })),
            children,
            hasPagination && (React.createElement("tfoot", null,
                React.createElement("tr", { className: "block" },
                    React.createElement("td", { className: "block" },
                        React.createElement(TablePagination, { count: totalResults, onGoToPage: onGoToPage, onPageSizeChange: onPageSizeChange, page: pageIndex + 1, pages: pageCount, pageSize: pageSize }))))))));
};
Table.propTypes = tablePropTypes;
Table.defaultProps = tableDefaultProps;
export default React.memo(Table);

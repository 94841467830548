export const removeEventListener = (mediaQueryList, event, listener) => {
    if (!mediaQueryList) {
        return;
    }
    if ('removeEventListener' in mediaQueryList) {
        mediaQueryList.removeEventListener(event, listener);
    }
    else if ('removeListener' in mediaQueryList) {
        mediaQueryList.removeListener(listener);
    }
};
export const addEventListener = (mediaQueryList, event, listener) => {
    if (!mediaQueryList) {
        return;
    }
    if ('addEventListener' in mediaQueryList) {
        mediaQueryList.addEventListener(event, listener);
    }
    else if ('addListener' in mediaQueryList) {
        mediaQueryList.addListener(listener);
    }
};

import PropTypes from 'prop-types';
import React from 'react';
import { AutoSizer } from 'react-virtualized';

import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from 'constants/tables';

import { RenderBalanceTransactionsTable } from './components';
import { useBalanceTransactionsTableData } from './hooks';

/**
 * Table configured for displaying balance-related transactions.
 * Data is formatted at this level, and passed to child components
 * for rendering.
 * @param {ComponentProps} props
 * @return {null|StatelessComponent}
 * @constructor
 */
const BalanceTransactionsTable = (props) => {
  const { tableConfig, tableData } = useBalanceTransactionsTableData(props);

  if (tableData.length === 0) {
    return null;
  }

  const tableHeight = tableData.length * TABLE_ROW_HEIGHT + TABLE_HEADER_HEIGHT;

  return (
    <div style={{ height: tableHeight }}>
      <AutoSizer>
        {({ width, height }) => (
          <RenderBalanceTransactionsTable
            height={height}
            tableConfig={tableConfig}
            tableData={tableData}
            width={width}
          />
        )}
      </AutoSizer>
    </div>
  );
};

BalanceTransactionsTable.propTypes = {
  /* props used in data hook */
  /* eslint-disable react/no-unused-prop-types */
  balanceFundingAccount: PropTypes.shape().isRequired,
  billingDataByCode: PropTypes.shape().isRequired,
  fundingAccountsById: PropTypes.shape().isRequired,
  fundingInfoBankAccountsById: PropTypes.shape().isRequired,
  transactions: PropTypes.arrayOf(PropTypes.shape()),
  /* eslint-enable react/no-unused-prop-types */
};

BalanceTransactionsTable.defaultProps = {
  transactions: [],
};

export default BalanceTransactionsTable;

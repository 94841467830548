import { change } from 'redux-form';

import { countriesMapping } from 'constants/i18n';

import { formatAddressFromPlace } from 'helpers/addressHelpers';

import { observeStreetAddress } from 'modules/address/helpers/streetAddress';

import { storeAccessor as store } from 'store/accessor';

/**
 * The functions in this file are listed in non-alphabetical order to define the function before its use.
 */

const onAddressAutoCompleteSelect = ({ addressPath, formName, newAddress }) => {
  if (!newAddress) {
    return;
  }

  Object.keys(newAddress).forEach((key) => {
    store.dispatch(change(formName, `${addressPath}${key}`, newAddress[key]));
  });
};

const fillInAddress =
  ({ addressPath, autocompleteRef, formName }) =>
  () => {
    // Get the place details from the auto-complete object.
    // noinspection JSUnresolvedFunction
    const place = autocompleteRef.current.getPlace();

    if (!place || !place.address_components) {
      return;
    }

    const newAddress = formatAddressFromPlace(place);

    // Fill in the form for the chosen address
    // with the response data from google places API.
    onAddressAutoCompleteSelect({ addressPath, formName, newAddress });
  };

const initAutoComplete = ({ addressPath, autocompleteRef, countryOptions, formName }) => {
  const countriesList = countriesMapping[countryOptions];

  const input = document.getElementsByName(`${addressPath}streetAddress`)[0];
  const options = { types: ['geocode'] };

  if (countriesList.length <= 6) {
    options.componentRestrictions = {
      country: countriesList.map((countryOption) => countryOption.value),
    };
  }

  // Battle Google Places to turn off browser autocomplete
  observeStreetAddress(input);

  /**
   * The purpose of a ref is to be assignable.
   */
  // eslint-disable-next-line no-param-reassign
  autocompleteRef.current = new window.google.maps.places.Autocomplete(input, options);

  // Add listener function to be triggered when place is chosen
  autocompleteRef.current.addListener('place_changed', fillInAddress({ addressPath, autocompleteRef, formName }));
};

export { fillInAddress, initAutoComplete, onAddressAutoCompleteSelect };
export default initAutoComplete;

/**
 * src/redirect.js
 * @fileOverview This file is its own entry-point in the build process, and is run before
 * any app code. Determines whether a redirect of the loaded url is needed, and sets a
 * listener on the app's browser history object to re-evaluate when additional history actions
 * are fired.
 */
import _some from 'lodash/some';

import * as envHelpers from 'helpers/env';
import * as localStorageHelpers from 'helpers/localStorage';
import * as routeHelpers from 'helpers/routeHelpers';
import * as urlHelpers from 'helpers/urls';

import history from 'modules/app/history';

const appRoutes = routeHelpers.getApplicationRouteDefs();

/**
 * Returns whether the given pathname matches one that needs redirection.
 * @param {string} pathname
 * @return {Boolean}
 */
export const getIsRedirectMatchFound = (pathname) =>
  _some(appRoutes, (routeDef) => {
    const { isExact, path } = routeDef;
    return isExact ? pathname === path : pathname.startsWith(path);
  });

/**
 * Checks whether the given pathname matches one that needs redirection.
 * If so, handles the redirect.
 * @param {StringMaybe} redirectFromSubdomain
 * @param {StringMaybe} redirectToSubdomain
 * @return {void}
 */
export const redirectIfMatch = (redirectFromSubdomain, redirectToSubdomain) => {
  const { hostname, href, pathname } = urlHelpers.getCurrentLocation();

  const isSubdoredBoldirectable = Boolean(
    redirectFromSubdomain && redirectToSubdomain && hostname === redirectFromSubdomain,
  );

  if (!isSubdoredBoldirectable) {
    return;
  }

  const isPathRedirectable = getIsRedirectMatchFound(pathname);

  if (isPathRedirectable) {
    localStorageHelpers.handleClearLocalStorage();
    urlHelpers.redirectTo(href.replace(redirectFromSubdomain, redirectToSubdomain));
  }
};

/**
 * Gets env values that define the from and to subdomains for redirection, if any are set.
 * These are passed to the provided callback function, which is also set as the handler
 * for any subsequent history actions.
 * @param {function(StringMaybe, StringMaybe): void} callback
 * @return {UnregisterCallback}
 */
export const checkForRedirects = (callback) => {
  const redirectFromSubdomain = envHelpers.getEnvVariableOrDefault('REACT_APP_SUB_DOMAIN_REDIRECT_FROM', '');
  const redirectToSubdomain = envHelpers.getEnvVariableOrDefault('REACT_APP_SUB_DOMAIN_REDIRECT_TO', '');

  callback(redirectFromSubdomain, redirectToSubdomain);

  return history.listen(() => {
    callback(redirectFromSubdomain, redirectToSubdomain);
  });
};

export const unlistenToRedirects = checkForRedirects(redirectIfMatch);

import {} from 'interfaces/reducers';
import { getModalReducerOpenAction } from 'store/redux';
import * as types from 'types/fundingProviderMemo';
import {} from './modalFundingMemoProviderReducer.types';
export const initialState = {
    open: false,
};
const modalFundingMemoProviderReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FUNDING_PROVIDER_MEMO_CLOSE:
            return initialState;
        case types.FUNDING_PROVIDER_MEMO_OPEN:
            return getModalReducerOpenAction(state, action);
        default:
            return state;
    }
};
export default modalFundingMemoProviderReducer;

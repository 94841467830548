import { changePasswordRoutine, userSettingsCreatePasswordRoutine } from 'actions/routines/auth';

import { AuthActionType } from 'enums/auth';

import { gatherReducerCasesFromRoutines } from 'helpers/routine';

import { createIsSubmittingReducer } from 'store/redux';

const { failureCases, requestCases: reqCases, successCases } = gatherReducerCasesFromRoutines([changePasswordRoutine]);

export const finishCases = [
  ...failureCases,
  ...successCases,
  AuthActionType.SUBMIT_CHANGE_PASSWORD_FAILURE,
  AuthActionType.SUBMIT_CHANGE_PASSWORD_SUCCESS,
  userSettingsCreatePasswordRoutine.FAILURE,
  userSettingsCreatePasswordRoutine.SUCCESS,
];
export const requestCases = [
  ...reqCases,
  AuthActionType.SUBMIT_CHANGE_PASSWORD_REQUEST,
  userSettingsCreatePasswordRoutine.TRIGGER,
];

export default createIsSubmittingReducer(finishCases, requestCases);

import {
  defaultFormErrorIndicatorId,
  defaultFormErrorMessage,
  defaultFormSuccessIndicatorId,
  defaultFormSuccessMessage,
  defaultIndicatorDuration,
} from 'components/form/constants/indicators';

import { getShowErrorUI, getShowSuccessUI } from 'helpers/ui';

/**
 * Helper to show a error toast to indicate a form error
 * @param {string} message
 * @param {Object} options
 * @returns {*}
 */
export const showErrorIndicator = (message = defaultFormErrorMessage, options = {}) =>
  getShowErrorUI(message, {
    duration: defaultIndicatorDuration,
    id: defaultFormErrorIndicatorId,
    ...options,
  })();

/**
 * Helper to show a success toast to indicate a form success
 * @param {string} message
 * @param {Object} options
 * @returns {*}
 */
export const showSuccessIndicator = (message = defaultFormSuccessMessage, options = {}) =>
  getShowSuccessUI(message, {
    duration: defaultIndicatorDuration,
    id: defaultFormSuccessIndicatorId,
    ...options,
  })();

import React from 'react';
import { Form } from 'components';
import { DEFAULT_RECEIVABLE } from 'constants/formFields';
import { usePrevious } from 'hooks';
import { UpdatePaymentMethodInternationalLayout } from './components';
import { createSubmitHandler, getUpdatedReceivableFormValue } from './helpers';
import {} from './UpdatePaymentMethodFormInternational.types';
const UpdatePaymentMethodFormInternational = ({ defaultReceivable, form: formName, fundingAccounts, handleSubmit, isUpdatePaymentMethodFlow, lastSubmittedFundingAccountId, onSubmitAllExistingItems, onValuesChange, }) => {
    const handleSubmitExistingItems = createSubmitHandler(onSubmitAllExistingItems);
    const previousLastSubmittedFundingAccountId = usePrevious(lastSubmittedFundingAccountId);
    React.useEffect(() => {
        const { shouldUpdate, updatedReceivable } = getUpdatedReceivableFormValue({
            defaultReceivable,
            lastSubmittedFundingAccountId,
            previousLastSubmittedFundingAccountId,
        });
        if (shouldUpdate) {
            onValuesChange(formName, DEFAULT_RECEIVABLE, updatedReceivable);
        }
    }, [
        defaultReceivable,
        formName,
        lastSubmittedFundingAccountId,
        onValuesChange,
        previousLastSubmittedFundingAccountId,
    ]);
    return (React.createElement(Form, { className: "update-payment-method-form-international", id: formName, name: formName, onSubmit: handleSubmit(handleSubmitExistingItems) },
        React.createElement(UpdatePaymentMethodInternationalLayout, { fundingAccounts: fundingAccounts, isUpdatePaymentMethodFlow: isUpdatePaymentMethodFlow })));
};
export default UpdatePaymentMethodFormInternational;

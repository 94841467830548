import React from 'react';
import { BaseHint, FormControl } from 'components';
import { Intent } from 'constants/ui';
import './ContactRequiredToAllowSelectPaymentMethodHint.scss';
import { ContactRequiredHintText } from './components';
const ContactRequiredToAllowSelectPaymentMethodHint = ({ formSyncErrors, ...rest }) => {
    const HintTextComponent = React.createElement(ContactRequiredHintText, { ...rest });
    const intent = formSyncErrors?.item?.paymentDeliveryMethod ? Intent.DANGER : Intent.WARNING;
    return (React.createElement(FormControl, null,
        React.createElement(BaseHint, { intent: intent, text: HintTextComponent, title: "A contact with a valid email is required" })));
};
export default ContactRequiredToAllowSelectPaymentMethodHint;

export const addQueryParams = (queryParams, addParams) => {
  if (!addParams || typeof addParams !== 'object' || Array.isArray(addParams)) {
    return queryParams;
  }
  const newQueryParams = { ...queryParams };

  Object.keys(addParams).forEach((addParamKey) => {
    newQueryParams[addParamKey] = addParams[addParamKey];
  });

  return newQueryParams;
};

export const dropQueryParams = (queryParams, dropParams) => {
  if (!dropParams || !Array.isArray(dropParams)) {
    return queryParams;
  }
  const newQueryParams = {};

  Object.keys(queryParams).forEach((queryParam) => {
    if (dropParams.includes(queryParam)) {
      return;
    }
    newQueryParams[queryParam] = queryParams[queryParam];
  });

  return newQueryParams;
};

export var ClearingAccountTypes;
(function (ClearingAccountTypes) {
    ClearingAccountTypes["MONEY_IN"] = "moneyIn";
    ClearingAccountTypes["MONEY_OUT"] = "moneyOut";
})(ClearingAccountTypes || (ClearingAccountTypes = {}));
export var FundingAccountTypes;
(function (FundingAccountTypes) {
    FundingAccountTypes["CHECKING"] = "checking";
    FundingAccountTypes["SAVINGS"] = "savings";
})(FundingAccountTypes || (FundingAccountTypes = {}));
export var FundingAccountDirection;
(function (FundingAccountDirection) {
    FundingAccountDirection["RECEIVABLES"] = "receivables";
    FundingAccountDirection["PAYABLES"] = "payables";
    FundingAccountDirection["RECEIVABLES_AND_PAYABLES"] = "receivables_and_payables";
})(FundingAccountDirection || (FundingAccountDirection = {}));
export var FundingAccountProcessingChannels;
(function (FundingAccountProcessingChannels) {
    FundingAccountProcessingChannels["ACH"] = "ach";
    FundingAccountProcessingChannels["CHECK"] = "check";
    FundingAccountProcessingChannels["NONE"] = "none";
    FundingAccountProcessingChannels["RTP"] = "rtp";
})(FundingAccountProcessingChannels || (FundingAccountProcessingChannels = {}));
export var FundingAccountUsableStates;
(function (FundingAccountUsableStates) {
    FundingAccountUsableStates["NO_CLEARING_MATCH"] = "no_clearing_match";
    FundingAccountUsableStates["NO_DELETED"] = "no_deleted";
    FundingAccountUsableStates["NO_DISABLED"] = "no_disabled";
    FundingAccountUsableStates["NO_LEDGER_MATCH"] = "no_ledger_match";
    FundingAccountUsableStates["NO_NOT_VALID"] = "no_not_valid";
    FundingAccountUsableStates["YES"] = "yes";
})(FundingAccountUsableStates || (FundingAccountUsableStates = {}));
export var FundingSourceProviderClasses;
(function (FundingSourceProviderClasses) {
    FundingSourceProviderClasses["ADDRESS"] = "address";
    FundingSourceProviderClasses["BANK"] = "bank";
})(FundingSourceProviderClasses || (FundingSourceProviderClasses = {}));
export var FundingSourceProviderStatuses;
(function (FundingSourceProviderStatuses) {
    FundingSourceProviderStatuses["INVALID"] = "invalid";
    FundingSourceProviderStatuses["INVALID_MICRO_DEPOSITS_FAILED"] = "invalid_micro_deposits_failed";
    FundingSourceProviderStatuses["INVALID_MICRO_DEPOSITS_FAILED_VERIFICATION"] = "invalid_micro_deposits_failed_verification";
    FundingSourceProviderStatuses["INVALID_MICRO_DEPOSITS_PENDING"] = "invalid_micro_deposits_pending";
    FundingSourceProviderStatuses["INVALID_MICRO_DEPOSITS_PROCESSED"] = "invalid_micro_deposits_processed";
    FundingSourceProviderStatuses["VALID"] = "valid";
})(FundingSourceProviderStatuses || (FundingSourceProviderStatuses = {}));
export var FundingSourceProviderSubClasses;
(function (FundingSourceProviderSubClasses) {
    FundingSourceProviderSubClasses["ACH"] = "bank_ach";
    FundingSourceProviderSubClasses["ADDRESS"] = "address_mailing";
    FundingSourceProviderSubClasses["BALANCE"] = "bank_balance";
    FundingSourceProviderSubClasses["CHECK"] = "bank_check";
    FundingSourceProviderSubClasses["EXTERNAL_LEDGER"] = "external_ledger";
})(FundingSourceProviderSubClasses || (FundingSourceProviderSubClasses = {}));
export var FundingCustomerStatuses;
(function (FundingCustomerStatuses) {
    FundingCustomerStatuses["CANCELED"] = "canceled";
    FundingCustomerStatuses["DOCUMENT"] = "document";
    FundingCustomerStatuses["DEACTIVATED"] = "deactivated";
    FundingCustomerStatuses["EXEMPT"] = "exempt";
    FundingCustomerStatuses["INCOMPLETE"] = "incomplete";
    FundingCustomerStatuses["PENDING"] = "pending";
    FundingCustomerStatuses["RETRY"] = "retry";
    FundingCustomerStatuses["SUSPENDED"] = "suspended";
    FundingCustomerStatuses["UNVERIFIED"] = "unverified";
    FundingCustomerStatuses["VERIFIED"] = "verified";
})(FundingCustomerStatuses || (FundingCustomerStatuses = {}));
export var FundingRequirementStatusTypes;
(function (FundingRequirementStatusTypes) {
    FundingRequirementStatusTypes["KYC_BENEFIT"] = "kyc_benefit";
    FundingRequirementStatusTypes["KYC_ISSUE"] = "kyc_issue";
    FundingRequirementStatusTypes["KYC_NEEDED"] = "kyc_needed";
    FundingRequirementStatusTypes["KYC_PENDING"] = "kyc_pending";
    FundingRequirementStatusTypes["OKAY"] = "okay";
})(FundingRequirementStatusTypes || (FundingRequirementStatusTypes = {}));
export var TransactionDirection;
(function (TransactionDirection) {
    TransactionDirection["DEPOSIT"] = "DEPOSIT";
    TransactionDirection["WITHDRAW"] = "WITHDRAW";
})(TransactionDirection || (TransactionDirection = {}));
export var PaymentDeliveryOptionText;
(function (PaymentDeliveryOptionText) {
    PaymentDeliveryOptionText["ACH_EXPEDITED"] = "Expedited ACH";
})(PaymentDeliveryOptionText || (PaymentDeliveryOptionText = {}));

import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { InternationalPhoneInput } from 'components';
import CannotUpdateFieldHint from 'components/hintTypes/CannotUpdateFieldHint';
import { internationalPhoneNumberValidator, phoneNumberLengthValidator, phoneNumberRequiredValidator, } from 'helpers/fieldValidation';
import { currentCompanyVerificationUpdateAllowedSelector, isUpdatingCurrentCompanySelector, } from 'selectors/currentCompanySelectors';
import {} from '../constants';
import { mapViewToFields } from '../fields';
import FlowStepperMain from '../FlowStepperMain';
import { viewSteps } from '../helpers/viewSteps';
const BusinessPhoneNumber = ({ activeView, invalid }) => {
    const updatedAllowed = useSelector(currentCompanyVerificationUpdateAllowedSelector);
    const isUpdating = useSelector(isUpdatingCurrentCompanySelector);
    return (React.createElement(FlowStepperMain, { fields: React.createElement("div", null,
            !updatedAllowed && React.createElement(CannotUpdateFieldHint, null),
            mapViewToFields[activeView]?.map(({ name, ...props }) => (React.createElement(Field, { className: "mt-7 w-[242px]", component: InternationalPhoneInput, dataFullStory: true, dataTestId: "businessPhoneNumber", isDisabled: !updatedAllowed, isLocked: !updatedAllowed, isRequired: true, key: name, name: name, placeholder: "Phone number", type: "text", validate: [phoneNumberRequiredValidator, phoneNumberLengthValidator, internationalPhoneNumberValidator], ...props })))), invalid: invalid || !updatedAllowed, onBack: viewSteps[activeView].previous, onNext: {
            ...viewSteps[activeView].next,
            isLoading: isUpdating,
        }, onSkip: updatedAllowed && viewSteps[activeView].skip, title: { content: 'What’s your business phone number?' } }));
};
export default BusinessPhoneNumber;

/**
 * Get cell disabled state
 * @param columnProps
 * @param rowData
 * @return {boolean}
 */
export const getTableCellDisabledState = (columnProps, rowData) => {
  if (!columnProps) {
    return false;
  }

  if (columnProps.isDisabled) {
    return true;
  }

  if (columnProps.requires && rowData) {
    return !columnProps.requires(rowData);
  }

  return false;
};

export const getChildrenForTableColumn = (columnKey, columnChildren) =>
  columnChildren ? columnChildren[columnKey] : undefined;

import { capitalize } from 'helpers/stringHelpers';
const getContactName = (contact, options) => {
    let name = contact.email;
    if (contact.firstName && contact.lastName) {
        name = `${capitalize(contact.firstName)} ${capitalize(contact.lastName)}`;
    }
    else if (contact.firstName) {
        name = capitalize(contact.firstName);
    }
    else if (contact.lastName && options?.treatLastNameAsDisplayOption) {
        name = capitalize(contact.lastName);
    }
    return name.trim();
};
export default getContactName;

import PropTypes from 'prop-types';
import React from 'react';

import Document from 'components/document';

const ExternalReceiptAttachmentPreview = ({ attachments }) => {
  if (attachments.length === 0) {
    return null;
  }

  return (
    <div className="external-receipt--attachment">
      <div className="external-receipt--attachment-actions">
        <div className="content--left">
          <span>Attachments:</span>
        </div>
      </div>

      <div className="form">
        {attachments.map((attachment) => (
          <Document
            className="w-full document margin-bottom--sm"
            documentName={attachment.filename}
            documentURL={attachment.file}
            isClearable={false}
            isDownloadable
            key={attachment.filename}
          />
        ))}
      </div>
    </div>
  );
};

ExternalReceiptAttachmentPreview.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ExternalReceiptAttachmentPreview;

import React from 'react';
import { useController } from 'react-hook-form';
import confirmAlert from 'helpers/confirmAlert';
import ToggleSwitch from './ToggleSwitch';
const ToggleSwitchHookForm = ({ children, confirmationConfig, id, isDisabled, testid, ...controlProps }) => {
    const { field } = useController(controlProps);
    const { value, ...fieldProps } = field;
    const handleChange = async (e) => {
        e.persist();
        if (confirmationConfig && confirmationConfig.shouldPrompt(value)) {
            const hasConfirmation = await confirmAlert(confirmationConfig.text, confirmationConfig.title);
            if (!hasConfirmation) {
                return;
            }
        }
        fieldProps.onChange({ ...e, target: { ...e.target, value: !value } });
    };
    return (React.createElement(ToggleSwitch, { "data-testid": testid, id: id || controlProps.name, isChecked: !!value, isDisabled: isDisabled, name: fieldProps.name, onBlur: fieldProps.onBlur, onChange: handleChange }, children));
};
export default ToggleSwitchHookForm;

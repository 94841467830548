import { submitInvoicesRoutine } from 'actions/routines/item';

import { parseMeta } from 'data/parse';

import { valueOrDefault } from 'helpers/utility';

import * as types from 'types/item';

const metaReducer = (state = null, action) => {
  switch (action.type) {
    case submitInvoicesRoutine.SUCCESS:
      return {
        ...valueOrDefault(state, {}),
        ...action.meta,
      };

    case types.FETCH_INVOICES_SUCCESS:
      return action.payload?.meta || state;

    case types.FETCH_ADDITIONAL_INVOICES_SUCCESS:
      return parseMeta.transform.transformLoadMoreMeta(state, action.payload.meta);

    default:
      return state;
  }
};

export default metaReducer;

import { PaymentDeliveryMethodType } from '@routable/shared';
import { BASE_API_URL } from './index';
export const fundingAccountsEndpoint = `${BASE_API_URL}/funding_accounts/`;
export const addFundingAccountAddressManualEndpoint = `${fundingAccountsEndpoint}create_address_manual/`;
export const addFundingAccountBankManualEndpoint = `${fundingAccountsEndpoint}create_bank_manual/`;
export const addFundingAccountInternationalBankManualEndpoint = `${fundingAccountsEndpoint}create_international_manual/`;
export const addFundingAccountBankTokenEndpoint = `${fundingAccountsEndpoint}create_bank_token/`;
export const createFundingAccountLinkTokenEndpoint = `${fundingAccountsEndpoint}auth_link_token/`;
export const fundingSupportedCountriesEndpoint = `${BASE_API_URL}/data/funding/supported_countries/`;
export const getFundingAccountEndpoint = (fundingAccountId) => `${fundingAccountsEndpoint}${fundingAccountId}/`;
export const getFundingBalanceCorrectionEndpoint = (fundingAccountBankBalanceId) => `${fundingAccountsEndpoint}${fundingAccountBankBalanceId}/correct_balance/`;
export const getFundingBalanceTransactionEndpoint = (fundingAccountBankBalanceId) => `${fundingAccountsEndpoint}${fundingAccountBankBalanceId}/balance_transaction/`;
export const getFundingRequirementEndpoint = (fundingAccountId, itemId, paymentDeliveryMethod = PaymentDeliveryMethodType.ACH) => `${fundingAccountsEndpoint}get_funding_requirement/` +
    `?payment_delivery_method=${paymentDeliveryMethod}&funding_account_id=${fundingAccountId}&item_id=${itemId}`;
export const getVerifyFundingAccountBankManualEndpoint = (fundingAccountId, fundingSourceId) => `${fundingAccountsEndpoint}${fundingAccountId}/funding_sources/${fundingSourceId}/verify_bank_manual/`;
export const getConvertFromExternalFundingAccountEndpoint = (fundingAccountId) => `${fundingAccountsEndpoint}${fundingAccountId}/convert_from_external/`;
export const addInternationalBankAccountEndpoint = (partnershipId) => `${BASE_API_URL}/partnerships/${partnershipId}/funding_account_international_bank/`;

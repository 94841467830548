import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import { isDomesticItem } from 'helpers/items';
import { isBulkImportItemsRoute } from 'helpers/routeHelpers';
import { sortObjectArray } from 'helpers/sort';
import { allValues } from 'helpers/utility';

import { featureFlagCrossborderItemApprovalEnabled } from 'selectors/featureFlagsSelectors';
import { createItemFormItemSelector, existingItemCurrencyCodeReceiver } from 'selectors/forms';
import { itemsSelector, itemsArraySelector } from 'selectors/itemsSelectors';
import { partnershipIdPropSelector } from 'selectors/propSelectors';
import { itemIdQuerySelector, locationSelector, partnershipIdQuerySelector } from 'selectors/routerSelectors';
import { existingItemSidePanelSelector } from 'selectors/sidePanelsSelector';

import { createItemCurrencyCodeReceiverSelector } from './createItemFormSelectors';

/**
 * Using the partnership_id query param, get items for the partnership and sort them by created.
 * @function
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @returns {Item[]} - Items matching the partnership, sorted by created
 */
export const partnershipItemsFromQuerySelector = createSelector(
  [itemsSelector, partnershipIdQuerySelector],
  (items, partnershipId) => {
    const partnershipItems = allValues(items).filter((item) => item.partnership === partnershipId);

    return sortObjectArray(partnershipItems, 'created', (value) => dayjs(value));
  },
);

/**
 * Using the partnershipId props param, get items for the partnership.
 * @function
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @returns {Item[]} - Items matching the partnership
 */
export const partnershipItemsFromPropsSelector = createSelector(
  [itemsSelector, partnershipIdPropSelector],
  (items, partnershipId) => allValues(items).filter((item) => item.partnership === partnershipId),
);

/**
 * Using the partnership_id and item_id query params, get items for the partnership without the current item and
 * sort them by created.
 *
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {Item[]} - Items matching the partnership, sorted by created
 */
export const partnershipItemsFromQueryWithoutCurrentItemSelector = createSelector(
  [partnershipItemsFromQuerySelector, itemIdQuerySelector],
  (partnershipItems, itemId) => partnershipItems.filter((item) => item.id !== itemId),
);

/**
 * Filters out international items until we can support them for CSV upload.
 * This can be enabled through a feature flag
 *
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Item[]}
 */
export const itemsEligibleForApprovalsSelector = createSelector(
  [featureFlagCrossborderItemApprovalEnabled, itemsArraySelector],
  (crossborderItemApprovalEnabled, items) => (crossborderItemApprovalEnabled ? items : items.filter(isDomesticItem)),
);

/**
 * Returns all items specific to CSV upload.
 *
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Item[]}
 */
export const allBulkUploadItemsSelector = createSelector(
  [locationSelector, itemsEligibleForApprovalsSelector],
  (location, items) => (isBulkImportItemsRoute(location) ? items : []),
);

/**
 * Returns item from existing item side panel i.e. ready to send side panel
 *
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Item}
 */
export const itemFromSidePanelExistingItemSelector = createSelector(
  [itemsSelector, existingItemSidePanelSelector],
  (itemById, sidePanel) => itemById?.[sidePanel?.itemId] || {},
);

/**
 * Returns item.currencyCodeReceiver,
 * even from item being created or from existing item
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {CurrencyCode}
 */
export const itemCurrencyCodeReceiverCompoundSelector = createSelector(
  [createItemCurrencyCodeReceiverSelector, existingItemCurrencyCodeReceiver],
  (createItemCurrencyCodeReceiver, existingItemCurrencyCodeReceiver) =>
    createItemCurrencyCodeReceiver || existingItemCurrencyCodeReceiver,
);

/**
 * Returns submitted item from item form
 * @type {StandardSelector}
 * @returns {Item}
 */
export const submittedItemFromCreateItemFormSelector = createSelector(
  [createItemFormItemSelector, itemsSelector],
  (item, items) => items?.[item?.id],
);

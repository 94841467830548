import PropTypes from 'prop-types';
import React from 'react';

import { HeadingBlockDisclosureList } from 'components/disclosureList';
import { IconNames } from 'components/icon';

import AccountAddress from 'modules/fundingAccount/bankAccount/components/AccountAddress';

import './MailingAddressDisclosureList.scss';

/**
 * Statically display mailing address for a business
 * @param {object} props
 * @returns {StatelessComponent}
 * @constructor
 */
const MailingAddressDisclosureList = ({ className, ...rest }) => (
  <div className={className}>
    <HeadingBlockDisclosureList
      className="mailing-address-disclosure-list"
      headerIconName={IconNames.ENVELOPE}
      headerText="Mailing address"
      items={[
        {
          component: AccountAddress,
          data: rest.address,
          key: 'mailingAddressListItem',
        },
      ]}
      keyExtractor={(item) => item.key}
      startOpen
    />
  </div>
);

MailingAddressDisclosureList.propTypes = {
  className: PropTypes.string,
};

MailingAddressDisclosureList.defaultProps = {
  className: undefined,
};

export default MailingAddressDisclosureList;

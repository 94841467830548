import { DeactivateTeamMemberModalField } from 'constants/formFields';

import { firstKey, firstValue } from 'helpers/utility';

/**
 * When the server throws a 422 when trying to deactivate a team member, parse the errors into a more-easily consumable
 * object to add to the errorReducer.
 *
 * @example
 * const errors = {
 *   'errors': {
 *     'unprocessable': [
 *       {
 *         'acknowledge': 'acknowledge_orphaned_items' // 'acknowledge_orphaned_items' could be any string
 *         'detail': 'Disabling member will leave an item with no approvers for an approval level.',
 *       }
 *     ]
 *   }
 * };
 *
 * getDeactivateMembershipErrors(errors);
 * // Returns:
 * // {
 * //   acknowledge: 'acknowledge_orphaned_items',
 * //   detail: 'Disabling member will leave an item with no approvers for an approval level.',
 * // }
 * @param {Object} errors
 * @returns {Object}
 */
export const getDeactivateMembershipErrors = (errors) => {
  const summary = firstKey(errors);
  const fullError = errors[summary];
  const { acknowledge, detail } = firstValue(fullError);

  return {
    [DeactivateTeamMemberModalField.ERROR_ACKNOWLEDGE]: acknowledge,
    [DeactivateTeamMemberModalField.ERROR_TEXT]: detail,
  };
};

import { createSelector } from 'reselect';

import { AddressTypes } from 'constants/address';

import { valueOrEmptyString } from 'helpers/forms';
import { getCurrentCompanyId, getCurrentMembershipId } from 'helpers/localStorage';
import { getQueryParam } from 'helpers/queryParams';
import { doesCompanyHaveTin, getBusinessFormShouldShowSSN } from 'helpers/taxes';

import { isUpdatingCompanySelector } from 'selectors/companiesSelectors';
import {
  currentCompanyAddressSelector,
  currentCompanyInfoSelector,
  currentCompanySelector,
} from 'selectors/currentCompanySelectors';
import { currentUserSelector } from 'selectors/currentUserSelectors';
import { currentMembershipSelector, isUpdatingMembershipSelector } from 'selectors/membershipsSelector';

// *************************************
// General
// *************************************
export const isUpdatingCompanyOrMembershipSelector = createSelector(
  [isUpdatingCompanySelector, isUpdatingMembershipSelector],
  (isUpdatingCompany, isUpdatingMembership) => isUpdatingCompany || isUpdatingMembership,
);

// *************************************
// Step 1 - Contact
// *************************************

/**
 * Helper to build the initial values for the company form for external onboarding step 1
 * @return {object}
 */
export const getOnboardingStepOneCompanyInitialValues = (state) => {
  const currentCompany = currentCompanySelector(state);

  return {
    id: getCurrentCompanyId(),
    companyType: currentCompany.companyType,
  };
};

/**
 * Helper to build the initial values for the membership form for external onboarding step 1
 * @return {object}
 */

export const getOnboardingStepOneMembershipInitialValues = (state) => {
  const currentUser = currentUserSelector(state);
  const currentMembership = currentMembershipSelector(state);

  return {
    id: getCurrentMembershipId(),
    phoneNumber: currentMembership.phoneNumber || {},
    user: {
      firstName: valueOrEmptyString(currentUser.firstName),
      id: currentUser.id,
      lastName: valueOrEmptyString(currentUser.lastName),
    },
    meta: {
      partnershipId: getQueryParam('partnership_id'),
      tosAgree: false,
    },
  };
};

// *************************************
// Step 2 - Company
// *************************************

/**
 * Helper method for generating the onboarding step 2 companyInfo initial values
 * @param company
 * @param companyInfo
 * @returns {{tin: string, id: *, businessType: string}}
 */
export const getOnboardingStepTwoCompanyInfoInitialValues = (company, companyInfo) => ({
  businessType: valueOrEmptyString(companyInfo.businessType),
  id: companyInfo.id,
  tin: valueOrEmptyString(companyInfo.tin),
});

/**
 * Helper method for generating the onboarding step 2 company initial values
 * @param company
 * @param companyInfo
 * @param mailingAddress
 * @returns {{mailingAddress: {}, meta: {partnershipId: string}, name: (*|string), id: (*|string), info: {}}}
 */
export const getOnboardingStepTwoCompanyInitialValues = (company, companyInfo, mailingAddress = {}) => ({
  id: company.id,
  info: getOnboardingStepTwoCompanyInfoInitialValues(company, companyInfo),
  mailingAddress,
  name: valueOrEmptyString(company.name),
  meta: {
    partnershipId: getQueryParam('partnership_id'),
  },
});

/**
 * Helper method for generating the onboarding step 2 formUI initial values
 * @param companyInfo
 * @returns {{businessFormShouldShowSSN: boolean, displayInputForTin: boolean}}
 */
export const getOnboardingStepTwoFormUIInitialValues = (companyInfo) => ({
  businessFormShouldShowSSN: getBusinessFormShouldShowSSN(companyInfo),
  displayInputForTin: !doesCompanyHaveTin(companyInfo),
});

/**
 * Helper for generating the initial values for the company form for external onboarding step 2
 * @return {object}
 */
export const getOnboardingStepTwoInitialValues = (state) => {
  const company = currentCompanySelector(state);
  const companyInfo = currentCompanyInfoSelector(state);
  const companyMailingAddress = currentCompanyAddressSelector(state, AddressTypes.MAILING_ADDRESS);

  return {
    company: getOnboardingStepTwoCompanyInitialValues(company, companyInfo, companyMailingAddress),
    formUI: getOnboardingStepTwoFormUIInitialValues(companyInfo),
  };
};

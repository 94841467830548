import React from 'react';
import { LinkNewPage } from 'components/link';
import { Image } from 'components/media';
import ShadedBlock from 'components/shadedBlock';
import { Intent } from 'constants/ui';
import { hasLength, isArray, isObject, isString } from 'helpers/utility';
import ServerErrorDefaultMessage from '../ServerErrorDefaultMessage';
import ServerErrorDefaultTitle from '../ServerErrorDefaultTitle';
import ServerErrorRequestId from '../ServerErrorRequestId';
import './ServerError.scss';
const ServerError = ({ alertErrors = [], className, errorMessage, imageSrc, requestId, title, }) => (React.createElement("div", { className: className },
    imageSrc && (React.createElement("div", { className: "alert-error-image margin-bottom--m-large" },
        React.createElement(Image, { alt: "Error alert graphic", src: imageSrc }))),
    title || React.createElement(ServerErrorDefaultTitle, null),
    hasLength(alertErrors) && (React.createElement(ShadedBlock, { className: "margin-bottom--large margin-top--m", intent: Intent.NEUTRAL },
        React.createElement("ul", { className: "alert-error-list" }, alertErrors.map((error, idx) => (React.createElement("li", { key: `alert-err-${idx}` },
            isString(error) && React.createElement("span", null, error),
            isObject(error) && (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "font-bold block" }, error.title),
                React.createElement("p", { className: "mt-2" }, error.detail),
                React.createElement("span", { className: "font-bold block mt-6" }, error.resolution?.title || 'Resolution steps:'),
                React.createElement("p", { className: "mt-2" },
                    error.resolution?.header && React.createElement("span", { className: "block" }, error.resolution.header),
                    error.resolution?.steps && (React.createElement("ol", { className: "list-decimal mt-4 pl-5" }, error.resolution.steps.map((step) => (React.createElement(React.Fragment, null,
                        isString(step) && React.createElement("li", null, step),
                        isArray(step) && (React.createElement("ol", { className: "list-style--lower-alpha pl-5" }, step.map((substep, substepIdx) => (React.createElement("li", { key: `substep-${substepIdx + 1}` }, substep)))))))))),
                    error.resolution?.footer && React.createElement("span", { className: "block mt-4" }, error.resolution.footer),
                    React.createElement("div", { className: "[&>a:nth-child(1)]:mt-5 [&>a:nth-child(2)]:mt-2" },
                        error.resolution?.externalLink && (React.createElement(LinkNewPage, { className: "block primary", href: error.resolution.externalLink.link }, `${error.resolution.externalLink.text} ↗`)),
                        error.resolution?.helpLink && (React.createElement(LinkNewPage, { className: "block primary", href: error.resolution.helpLink.link }, `${error.resolution.helpLink.text} ↗`)))))))))))),
    errorMessage || React.createElement(ServerErrorDefaultMessage, null),
    requestId && React.createElement(ServerErrorRequestId, null, requestId)));
export default ServerError;

import { CANCEL_SET_EXPORT_FILE_ID, CANCEL_CLEAR_EXPORT_FILE_ID } from 'types/export';
const initialState = {
    exportFileId: null,
};
const exportFileReducer = (state = initialState, action = null) => {
    switch (action?.type) {
        case CANCEL_SET_EXPORT_FILE_ID:
            return { ...state, exportFileId: action.payload.exportFileId };
        case CANCEL_CLEAR_EXPORT_FILE_ID:
            return { ...state, exportFileId: null };
        default:
            return state;
    }
};
export default exportFileReducer;

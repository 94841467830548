import { FetchService } from 'services';

/**
 * Fetch a file that requires authentication.
 * @param {string} url
 * @param {ObjectMaybe} [options={}]
 * @return {Promise<*>}
 */
export const fetchFile = async (url, options = {}) => {
  const response = await FetchService.requestFile(url, options);

  if (!response.ok) {
    return response;
  }

  return response.blob();
};

import React from 'react';

import { WhiteSpace } from 'components';

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

import ExternalPromptHeader from './ExternalPromptHeader';

const ExternalPromptSingleItemFailed = () => (
  <React.Fragment>
    <div className="confirmation error">
      <span className="icon-ic-alert" />
      <h3>Error</h3>
    </div>

    <ExternalPromptHeader text="Your transaction failed to send." />

    <div className="alert-main note large-padding disable-pointer-events margin-top--large">
      <p className="title">Resolving your failed transaction:</p>

      <p className="text">
        We apologize for this inconvenience. Please contact our
        <WhiteSpace />
        <a className="primary enable-pointer-events" onClick={() => window.Beacon('open')} role="presentation">
          {PLATFORM_DISPLAY_SHORT_NAME}
          <WhiteSpace />
          support team
        </a>
        <WhiteSpace />
        and we&apos;ll help get this issue resolved.
      </p>

      <br />

      <p className="text">
        Note: Most transaction failures are caused by insufficient funds in a bank account, or a
        <WhiteSpace />
        mistyped account / routing number.
      </p>
    </div>
  </React.Fragment>
);

export default ExternalPromptSingleItemFailed;

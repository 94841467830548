import React from 'react';
import { Field } from 'redux-form';
import { BoxV2, ButtonV2, IconNames, ReduxFormFieldRenderInput } from 'components';
import { ButtonSize } from 'constants/button';
import { formNamesSignup } from 'constants/forms';
import { formStyles, sizes } from 'constants/styles';
import { minCharacterLengthValidator, requiredValidator, subdomainValidator } from 'helpers/fieldValidation';
import { getBrandedWorkspacesDisplayDomain } from 'helpers/urls';
import { submit } from './helpers/submit';
const minLengthValidator = minCharacterLengthValidator(4);
const SelectWorkspace = ({ handleSubmit, ...props }) => {
    const { isSubmitting } = props;
    return (React.createElement("div", { className: "branded--contents" },
        React.createElement(BoxV2, { subtitle: "The name of your workspace will appear throughout Routable. It\u2019s also the beginning of the URL your members will use to access Routable. You can change your workspace name at any time by contacting support.", title: "Create a workspace name" },
            React.createElement("form", { className: "form", id: formNamesSignup.SELECT_NAMESPACE, onSubmit: handleSubmit((values) => submit(values, props)) },
                React.createElement("div", { className: "form-control" },
                    React.createElement(Field, { className: `${formStyles.field.xl.full} namespace`, component: ReduxFormFieldRenderInput, dataFullStory: true, isDisabled: Boolean(isSubmitting), name: "company.namespace", placeholder: "workspace-name", type: "text", validate: [requiredValidator, minLengthValidator, subdomainValidator] },
                        React.createElement("div", { className: "inline-label inline-right workspace-label" },
                            React.createElement("span", null, `.${getBrandedWorkspacesDisplayDomain()}`)))),
                React.createElement("div", { className: "form-control remove-margin-bottom" },
                    React.createElement(ButtonV2, { htmlFor: formNamesSignup.SELECT_NAMESPACE, isLoading: Boolean(isSubmitting), rightIconName: IconNames.ARROW_RIGHT, rightIconProps: { style: { marginLeft: 'auto' } }, rightIconSize: sizes.iconSizes.LARGE, size: ButtonSize.LARGE, type: "submit" }, "Confirm workspace name"))))));
};
export default SelectWorkspace;

import { FETCH_TAX_INFO_SUCCESS, FETCH_VENDOR_TAX_INFO_SUCCESS } from 'types/taxes';

const metaReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TAX_INFO_SUCCESS:
    case FETCH_VENDOR_TAX_INFO_SUCCESS:
      return action.payload.meta;

    default:
      return state;
  }
};

export default metaReducer;

import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { DASHBOARD } from 'constants/routes';

import { hasAuthToken } from 'helpers/auth';
import { renderableProp } from 'helpers/propTypes';
import { getCompanyNamespaceFromUrl } from 'helpers/urls';

import { FetchService } from 'services';
import { currentUserEndpoint } from 'services/api/userEndpoints';

/**
 * Wrapper for unauthenticated routes
 */
const NotAuthenticated = ({ component, ...rest }) => {
  React.useEffect(() => {
    const config = {
      endpoint: currentUserEndpoint,
      method: 'GET',
      requireAuth: false,
      returnRejection: true,
    };

    // Make get request to /me endpoint to check if we are on a previous or invalid subdomain
    // and redirect to new one if needed (handled in FetchService)
    // This will only make the request if it's in a branded subdomain
    if (getCompanyNamespaceFromUrl()) {
      FetchService.request(config);
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (hasAuthToken()) {
          return <Redirect to={`/${DASHBOARD}`} />;
        }

        return React.createElement(component, { ...rest, ...props });
      }}
    />
  );
};

NotAuthenticated.propTypes = {
  component: renderableProp.isRequired,
};

export default NotAuthenticated;

import React from 'react';
import { WhiteSpace } from 'components';
import { Text } from 'components/text';
import { QUICKSWITCH_IMAGE } from 'constants/images';
import { typography } from 'constants/styles';
import { IsLoading } from 'modules/isLoading';
const QuickswitchSplashscreenBody = ({ companyName }) => (React.createElement("section", null,
    React.createElement("div", { className: "splashscreen--content" },
        React.createElement("div", { className: "splashscreen--image" },
            React.createElement("img", { alt: "", src: QUICKSWITCH_IMAGE }),
            React.createElement(IsLoading, { color: typography.TextColor.MAIN_BLUE })),
        React.createElement("div", { className: "splashscreen--progress-header" },
            React.createElement("h3", null,
                React.createElement(Text.Regular, { color: typography.TextColor.BLACK, lineHeight: typography.TextLineHeight.EXTRA_EXTRA_LARGE, size: typography.TextSize.LEVEL_500 },
                    "Switching to",
                    React.createElement(WhiteSpace, null),
                    companyName,
                    "..."))),
        React.createElement("div", { className: "splashscreen--progress-text" },
            React.createElement(Text.Regular, { color: typography.TextColor.GREY_XX_DARK, lineHeight: typography.TextLineHeight.EXTRA_LARGE, size: typography.TextSize.LEVEL_200 }, "It may take a few moments to switch between Routable accounts.")))));
export default QuickswitchSplashscreenBody;

import _get from 'lodash/get';

/**
 * Get the next value of formState.values.partnershipMembers, filtering out the given email address.
 * @param {Object} options
 * @param {ReduxFormValues} [options.appendValues=[]]
 * @param {string} options.fieldName
 * @param {string} options.filterEmail
 * @param {ReduxFormValues} options.values
 * @return {FormPartnershipMember[]}
 */
export const getFilteredPartnershipMembersFormValue = ({ appendValues = [], fieldName, filterEmail, values }) =>
  _get(values, fieldName, [])
    .filter((member) => member.email !== filterEmail)
    .concat(appendValues);

/**
 * Get the next value of formState.syncErrors.
 * @param {Object} options
 * @param {string} options.fieldName
 * @param {boolean} options.hasError
 * @param {Object} options.syncErrors
 * @return {Object}
 */
export const getFormSyncErrors = ({ fieldName, hasError, syncErrors }) =>
  hasError ? _get(syncErrors, fieldName) : undefined;

import { all, call, put, select } from 'redux-saga/effects';

import { routableApi } from 'ducks/routableApi';
import { RoutableTags } from 'ducks/routableApi/routableApi';

import { externalOnboardingChangeStep } from 'actions/externalOnboarding';
import { submitFundingStateRoutine } from 'actions/routines/external';

import {
  addNextStepsToResponseData,
  getLastStepIndex,
  getStepsFromResponse,
  updateSteps,
} from 'data/parse/partnershipRequest';
import { getPartnershipRequestPayload } from 'data/submitTransformers/externalFunding';

import { parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { getQueryParam } from 'helpers/queryParams';
import { sagaWatcher } from 'helpers/saga';
import { lastElementIn } from 'helpers/utility';

import { fetchSingleItemFromQueryParam } from 'sagas/item/sagas';

import { itemSelector } from 'selectors/itemsSelectors';
import {
  partnershipRequestFromLocationSelector,
  partnershipRequestsStepsSelector,
} from 'selectors/partnershipRequestSelectors';
import { paymentActivePaymentSelector } from 'selectors/paymentsSelectors';

import * as api from './api';

/**
 * Submits the update-payment-method form data to submitPaymentMethodFundingState.
 * @param {Object} action
 * @param {Object} action.payload
 * @return {IterableIterator<*>}
 */
export function* submitExternalFundingState({ payload }) {
  let errorData = {};

  yield put(submitFundingStateRoutine.request());

  let additionalMeta = {};

  const activePaymentId = yield select(paymentActivePaymentSelector);

  if (activePaymentId) {
    additionalMeta = {
      payment_id: activePaymentId.id,
      kind: activePaymentId.kind,
    };
  } else {
    const itemId = getQueryParam('item_id');
    if (itemId) {
      const item = yield select((state) => itemSelector(state, itemId));
      if (item) {
        // the BE will validate that the version is correct for this item
        additionalMeta = {
          item_id: itemId,
          version: item.version,
        };
      }
    }
  }

  try {
    const partnershipRequest = yield select(partnershipRequestFromLocationSelector);
    const response = yield call(api.submitPaymentMethodFundingState, {
      partnershipRequestId: partnershipRequest.id,
      fundingAccountRequestStateId: lastElementIn(partnershipRequest.fundingAccountStates),
      submitPayload: getPartnershipRequestPayload({
        ...payload,
        partnershipRequest,
        meta: {
          ...payload.meta,
          ...additionalMeta,
        },
      }),
    });

    if (response.ok) {
      // Fetch item to update it's status in ExternalReceiptSingleItem.
      yield fetchSingleItemFromQueryParam();

      const prevSteps = yield select(partnershipRequestsStepsSelector);
      const nextSteps = updateSteps(prevSteps, getStepsFromResponse(response));

      yield all([
        put(externalOnboardingChangeStep(getLastStepIndex(nextSteps))),
        put(submitFundingStateRoutine.success(addNextStepsToResponseData(response.data, nextSteps))),
        put(routableApi.util.invalidateTags([RoutableTags.Items])),
      ]);

      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(submitFundingStateRoutine.failure({ errors: errorData }));
}

/**
 * Root external funding states saga
 * @return {IterableIterator<*>}
 */
export default function* externalFundingStateSagas() {
  yield sagaWatcher([
    {
      type: submitFundingStateRoutine.TRIGGER,
      saga: submitExternalFundingState,
    },
  ]);
}

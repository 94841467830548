import PropTypes from 'prop-types';
import React from 'react';
import { v4 } from 'uuid';

import FormFieldErrors from 'components/error/components/FormFieldErrors';

import { DocumentAcceptString } from 'constants/document';

import { getFieldErrors } from 'helpers/errors';
import { inputNameOrNameProp } from 'helpers/propTypes';

/**
 * Input with type 'file' for uploading files
 * @param {ComponentProps} props
 * @param {string} props.accept
 * @param {node} props.children
 * @param {string} props.className
 * @param {boolean} props.disabled
 * @param {Object} props.errors
 * @param {string} props.id
 * @param {Object} props.input
 * @param {Object} props.inputRef
 * @param {String} [props.keyName]
 * @param {Object} props.meta
 * @param {boolean} props.multiple
 * @param {string} props.name
 * @param {function} props.onChange
 * @param {boolean} props.required
 * @return {StatelessComponent}
 */
const FileInput = ({
  accept,
  children,
  className,
  disabled,
  errors: propErrors,
  id,
  input,
  inputRef,
  keyName,
  meta,
  multiple,
  name,
  onChange,
  required,
}) => {
  let errors;
  let inputName;
  let handleOnChange;

  if (input) {
    // This is a redux-form input
    errors = (meta.touched || meta.submitFailed) && meta.error;
    inputName = input.name;
    handleOnChange = input.onChange;
  } else {
    // This is not a redux-form input
    errors = getFieldErrors(propErrors, name);
    inputName = name;
    handleOnChange = onChange;
  }

  const modifyChildren = (child) => {
    if (child && child.props) {
      const newClasses = `${child.props.className} ${errors ? 'error' : ''}`.trim();
      return React.cloneElement(child, { className: newClasses });
    }

    return null;
  };

  return (
    <div className={className} style={{ position: 'relative' }}>
      {React.Children.map(children, modifyChildren)}
      {!disabled && (
        <input
          accept={accept}
          data-testid={id}
          disabled={disabled}
          id={id || v4()}
          key={keyName}
          multiple={multiple}
          name={inputName}
          onChange={handleOnChange}
          ref={inputRef}
          required={required}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0,
            width: '100%',
            height: '100%',
          }}
          type="file"
        />
      )}
      <FormFieldErrors errors={errors} fieldName={inputName} />
    </div>
  );
};

FileInput.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.shape(),
  id: PropTypes.string,
  input: PropTypes.shape(),
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]).isRequired,
  keyName: PropTypes.string,
  meta: PropTypes.shape(),
  multiple: PropTypes.bool,
  name: inputNameOrNameProp,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

FileInput.defaultProps = {
  accept: DocumentAcceptString,
  children: undefined,
  className: '',
  disabled: undefined,
  errors: undefined,
  id: undefined,
  input: undefined,
  keyName: undefined,
  meta: undefined,
  multiple: undefined,
  name: undefined,
  onChange: undefined,
  required: undefined,
};

export default FileInput;

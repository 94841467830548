import { apiCall } from '@routable/framework';
import { taxToolsFiltersModel } from '@routable/taxes';
import { riskCheckReportModel, manuallyMoveToVerifiedResponseModel, bulkRiskChecksResponseModel, } from '../models';
export const generateVendorRiskChecksApi = (api) => ({
    getLatestReport: async (partnershipId) => api({
        camelCaseResponse: true,
        url: `vendors/${partnershipId}/risk-reports/latest`,
        method: 'get',
        responseModel: riskCheckReportModel,
    }),
    manuallyMoveToVerified: async (partnershipId, riskReportId) => api({
        url: `vendors/${partnershipId}/risk-reports/${riskReportId}/dismiss`,
        method: 'post',
        responseModel: manuallyMoveToVerifiedResponseModel,
    }),
    bulkRiskChecks: async (filters) => api({
        url: 'vendors/bulk-risk-checks',
        method: 'post',
        queryParams: {
            ...Object.fromEntries(Object.entries(taxToolsFiltersModel.parse(filters)).filter(([, v]) => !!v)),
        },
        responseModel: bulkRiskChecksResponseModel,
    }),
});
export const vendorRiskChecksApi = generateVendorRiskChecksApi(apiCall);

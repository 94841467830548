import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import FormFieldErrors from 'components/error/components/FormFieldErrors';
import DateInput from 'components/form/DateInput/DateInput';
import FormTooltip from 'components/form/FormTooltip';
import { inputHelpers } from 'components/form/helpers';
import { InputLabel, InputPlaceholder } from 'components/input';
import * as commonProps from 'components/input/commonProps';

import { getFieldErrors } from 'helpers/errors';
import { isUndef } from 'helpers/utility';

const DateInputWrapper = (props) => {
  const [rawValue, setRawValue] = React.useState('');

  const { children, errors, hasErrors: hasErrorsProp, inputClassName, name, placeholder, tooltip, value } = props;
  const hasErrors = !isUndef(hasErrorsProp) ? hasErrorsProp : getFieldErrors(errors, name);

  const { widthClasses, otherClasses } = inputHelpers.getInputClasses(props, {
    hasErrors,
  });

  const handleOnChange = (event) => {
    setRawValue(event.target.value);
    // eslint-disable-next-line react/destructuring-assignment
    props.onChange(event);
  };

  const hasValue = rawValue || value;

  return (
    <div className={classNames(widthClasses)}>
      <div className={classNames('input-container', otherClasses)}>
        <InputLabel name={name} placeholder={placeholder} value={hasValue} />
        <DateInput {...props} className={inputClassName} onChange={handleOnChange} />
        <InputPlaceholder isRequired placeholder={placeholder} value={hasValue} />

        {children}
      </div>
      <FormTooltip content={tooltip} />
      <FormFieldErrors errors={errors} fieldName={name} />
    </div>
  );
};

DateInputWrapper.propTypes = {
  ...commonProps.propTypes,
  inputClassName: PropTypes.string,
  tooltip: PropTypes.string,
};

DateInputWrapper.defaultProps = {
  ...commonProps.defaultProps,
  inputClassName: '',
  tooltip: '',
};

export default DateInputWrapper;

import z from 'zod';
export const lineItemModel = z
    .object({
    account: z.object({ code: z.string().nullish(), id: z.string(), name: z.string() }),
    amount: z.string().or(z.number()).nullish(),
    amount_unbilled: z.string().or(z.number()).nullish(),
    description: z.string().nullish(),
    id: z.string().nullish(),
    item: z.object({ id: z.string(), name: z.string() }).nullish(),
    purchase_order_id: z.string().nullish(),
    purchase_order_line_item_id: z.string().nullish(),
    purchaseOrderLineItemId: z.string().nullish(),
    quantity: z.number(),
    style: z.enum(['account', 'item']),
    unit_price: z.string().or(z.number()).nullish(),
})
    .passthrough();

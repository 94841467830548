import {
  fetchExternalPartnershipRequestRoutine,
  submitPartnerCompanyContactInfoRoutine,
} from 'actions/routines/external';
import { updateCurrentUserRoutine } from 'actions/routines/user';

import { getCurrentUserId } from 'helpers/localStorage';
import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { allValues, isEqual, valueForFirstKey, valueOrDefault } from 'helpers/utility';

import {
  FETCH_MEMBERSHIP_SUCCESS,
  FETCH_MEMBERSHIPS_SUCCESS,
  UPDATE_ONBOARDING_MEMBERSHIP_SUCCESS,
} from 'types/memberships';
import { GET_CURRENT_USER_SUCCESS } from 'types/user';

// TODO: DEV-1495 - FE: Clean up `currentUser` reducer and determine if it can be completely removed
const userReducer = (state = null, action) => {
  switch (action.type) {
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
    case FETCH_MEMBERSHIP_SUCCESS:
    case UPDATE_ONBOARDING_MEMBERSHIP_SUCCESS:
    case GET_CURRENT_USER_SUCCESS:
    case updateCurrentUserRoutine.SUCCESS:
    case submitPartnerCompanyContactInfoRoutine.SUCCESS:
    case FETCH_MEMBERSHIPS_SUCCESS: {
      // This returns multiple users - pick the current one
      const currentUserId = getCurrentUserId();

      if (!currentUserId) {
        // If no currentUser is set, take the first (and only) key from the user payload (used in External flow)
        const usersById = getObjectsByIdWithRelationships(action.payload.user, ['documents']);
        return valueOrDefault(valueForFirstKey(usersById), state);
      }

      let newState = null;
      allValues(action.payload.user).forEach((user) => {
        if (isEqual(user.id, currentUserId)) {
          const usersById = getObjectsByIdWithRelationships(
            {
              [currentUserId]: action.payload.user[currentUserId],
            },
            ['documents'],
          );

          newState = usersById[currentUserId];
        }
      });

      return valueOrDefault(newState, state);
    }

    default:
      return state;
  }
};

export default userReducer;

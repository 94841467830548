import { createSelector } from 'reselect';

import { createStep, getPrimaryColumnButtonText } from 'helpers/externalWorkflow';
import {
  getInitialStepIndexForExternalFlow,
  getStepCompletionCompletedBy,
  getStepCompletionTimestamp,
} from 'helpers/progressBarStepper';
import getContactName from 'helpers/user';
import { isEqual, lastElementIn } from 'helpers/utility';

import { currentCompanyMembershipsByIdSelector, currentCompanyUsersSelector } from 'selectors/currentCompanySelectors';
import {
  externalOnboardingIsSubmittingSelector,
  externalOnboardingStepSelector,
} from 'selectors/externalOnboardingSelectors';
import {
  externalPaymentFundingAccountSelector,
  partnerCompanyContactFormIsValidSelector,
  partnerCompanyGeneralFormIsValidSelector,
} from 'selectors/forms';
import { fundingAccountsByIdSelector, fundingSourcesByIdSelector } from 'selectors/fundingSelectors';
import {
  isPartnershipRequestUpdatePaymentSelector,
  partnershipRequestsStepsSelector,
  partnershipRequestW8FormIsSubmittingSelector,
  partnershipRequestW8FormIsValidSelector,
  partnershipRequestW9FormIsSubmittingSelector,
  partnershipRequestW9FormIsValidSelector,
} from 'selectors/partnershipRequestSelectors';

import { membershipFromPartnershipRequestSelector } from './membershipCompoundSelectors';

/**
 * Selects the active step in the workflow
 * @type {StandardSelector}
 * @returns {ExternalStep} step
 */
export const activeWorkflowStepSelector = createSelector(
  [partnershipRequestsStepsSelector, externalOnboardingStepSelector],
  (steps = [], activeStepIndex) => steps[activeStepIndex],
);

/**
 * Selects fundingAccount from the updatePaymentMethod form
 * @function
 * @type {StandardSelector}
 * @param {ReduxState} state - Redux State
 * @returns {FundingAccount} - fundingAccount
 */
export const fundingAccountFromUpdatePaymentMethodFormSelector = createSelector(
  [externalPaymentFundingAccountSelector, fundingAccountsByIdSelector],
  (fundingAccountId, fundingAccounts) => fundingAccounts[fundingAccountId],
);

/**
 * Selects fundingAccount from the updatePaymentMethod form
 * @function
 * @type {StandardSelector}
 * @param {ReduxState} state - Redux State
 * @returns {FundingSource[]} - fundingSources
 */
export const fundingSourcesFromUpdatePaymentMethodFormSelector = createSelector(
  [fundingAccountFromUpdatePaymentMethodFormSelector, fundingSourcesByIdSelector],
  (fundingAccount, fundingSourcesById) => {
    const { fundingSources = [] } = Object(fundingAccount);

    return fundingSources.map((fundingSourceId) => fundingSourcesById[fundingSourceId]);
  },
);

/**
 * Selects the current step in the workflow and adds additional properties
 * @type {StandardSelector}
 * @returns {ExternalStep} - step
 */
export const currentWorkflowStepSelector = createSelector(
  [
    activeWorkflowStepSelector,
    fundingAccountFromUpdatePaymentMethodFormSelector,
    fundingSourcesFromUpdatePaymentMethodFormSelector,
    partnerCompanyContactFormIsValidSelector,
    partnerCompanyGeneralFormIsValidSelector,
    partnershipRequestW9FormIsValidSelector,
    partnershipRequestW8FormIsValidSelector,
  ],
  (
    step,
    fundingAccount,
    fundingSources,
    isContactInfoFormValid,
    isBusinessInfoFormValid,
    isSubstituteW9FormValid,
    isSubstituteW8FormValid,
  ) => {
    if (!step) {
      return undefined;
    }

    return createStep({
      fundingAccount,
      fundingSources,
      isBusinessInfoFormValid,
      isContactInfoFormValid,
      isSubstituteW8FormValid,
      isSubstituteW9FormValid,
      step,
    });
  },
);

/**
 * Selects the intial button text from the primary column in the current step
 * @type {StandardSelector}
 * @returns {String} - buttonName
 */
export const currentExternalButtonStepSelector = createSelector(
  [currentWorkflowStepSelector],
  getPrimaryColumnButtonText,
);

/**
 * Selects the submitting properties of the external onboarding flow
 * and returns true if either of those properties is truthy
 * @type {StandardSelector}
 * @returns {Boolean} - isSubmitting button state
 */
export const currentExternalButtonIsSubmittingSelector = createSelector(
  [
    externalOnboardingIsSubmittingSelector,
    partnershipRequestW8FormIsSubmittingSelector,
    partnershipRequestW9FormIsSubmittingSelector,
  ],
  (externalOnboardingIsSubmitting, w8FormIsSubmitting, w9FormIsSubmitting) =>
    externalOnboardingIsSubmitting || w8FormIsSubmitting || w9FormIsSubmitting,
);

/**
 * Selects the last step in the workflow
 * @type {StandardSelector}
 * @returns {ExternalStep} - step
 */
export const lastStepSelector = createSelector([partnershipRequestsStepsSelector], lastElementIn);

/**
 * Selects the completion timestamp from last step in the workflow
 * @type {StandardSelector}
 * @returns {string} - timestamp
 */
export const lastStepCompletionTimestampSelector = createSelector([lastStepSelector], getStepCompletionTimestamp);

/**
 * Selects the membership linked from last step in the workflow
 * @type {StandardSelector}
 * @returns {Membership} - membership
 */
export const lastStepCompletionMembershipBySelector = createSelector(
  [lastStepSelector, currentCompanyMembershipsByIdSelector],
  (lastStep, membershipsById) => membershipsById[getStepCompletionCompletedBy(lastStep)],
);

/**
 * Selects the usersNames linked from last step in the workflow
 * @type {StandardSelector}
 * @returns {string[]} - usersNames
 */
export const lastStepUserNamesSelector = createSelector(
  [lastStepCompletionMembershipBySelector, membershipFromPartnershipRequestSelector, currentCompanyUsersSelector],
  (stepMembership, currentMembership, usersById) => {
    const isCurrentUser = isEqual(stepMembership?.id, currentMembership?.id);
    const contact = usersById[stepMembership?.user];

    if (isCurrentUser) {
      return ['You'];
    }

    if (contact) {
      return [getContactName(contact)];
    }

    return [];
  },
);

/**
 * @type {StandardSelector}
 * @returns {Number} - stepIndex
 */
export const initialStepIndexSelector = createSelector(
  [partnershipRequestsStepsSelector, isPartnershipRequestUpdatePaymentSelector],
  getInitialStepIndexForExternalFlow,
);

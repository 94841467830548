import { combineReducers } from 'redux';

import errorReducer from './errorReducer';
import ssoFetchingReducer from './ssoFetchingReducer';
import ssoRedirectingReducer from './ssoRedirectingReducer';
import ssoSubmitReducer from './ssoSubmitReducer';
import submitReducer from './submitReducer';

export default combineReducers({
  errors: errorReducer,
  isSubmitting: submitReducer,
  isSSOFetching: ssoFetchingReducer,
  isSSOSubmitting: ssoSubmitReducer,
  isSSORedirecting: ssoRedirectingReducer,
});

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { bankAccountDetailsModalOpen } from 'actions/funding';
import {
  clearSendPartnershipUpdatePaymentMethodUrlStatus,
  updatePartnershipPrimaryReceivableFundingAccount,
} from 'actions/partnership';

import { formItemPartnerFundingAccount, formUIIsItemEditSelector } from 'queries/createItemFormSelectors';

import { currentCompanySettingsIsInternationalPaymentsEnabledSelector } from 'selectors/currentCompanySelectors';
import {
  fundingAccountsByIdSelector,
  fundingInfoAddressesByIdSelector,
  fundingInfoBankAccountsByIdSelector,
} from 'selectors/fundingSelectors';

import submitPartnershipPrimaryReceivableFundingAccount from 'thunks/submitPartnershipPrimaryReceivableFundingAccount';

import withPartnerFundingAccounts from './withPartnerFundingAccounts';

export const mapStateToProps = createStructuredSelector({
  isInternationalPaymentsEnabled: currentCompanySettingsIsInternationalPaymentsEnabledSelector,
  isItemEdit: formUIIsItemEditSelector,
  fundingAccountsById: fundingAccountsByIdSelector,
  fundingInfoAddressesById: fundingInfoAddressesByIdSelector,
  fundingInfoBankAccounts: fundingInfoBankAccountsByIdSelector,
  partnerFundingAccount: formItemPartnerFundingAccount,
});

export const mapDispatchToProps = {
  onClearSendPartnershipUpdatePaymentMethodUrlStatus: clearSendPartnershipUpdatePaymentMethodUrlStatus,
  onOpenBankAccountDetailsModal: bankAccountDetailsModalOpen,
  onSubmitPartnershipPrimaryReceivableFundingAccount: submitPartnershipPrimaryReceivableFundingAccount,
  onUpdatePartnershipPrimaryReceivableFundingAccount: updatePartnershipPrimaryReceivableFundingAccount,
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withPartnerFundingAccounts);

export default (Component) => enhance(Component);

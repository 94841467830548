import { createSelector } from 'reselect';

import { allValues } from 'helpers/utility';

/**
 * Top level permissions selector
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
const getState = (state) => state.permissions;

/**
 * Selects all IDs of permissions from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {string[]}
 */
export const permissionsAllIdsSelector = createSelector([getState], (permissions) => permissions.allIds);

/**
 * Selects permissions by id from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object<Permission.id, Permission>}
 */
export const permissionsByIdSelector = createSelector([getState], (permissions) => permissions.byId);

/**
 * Select permissions by id and returns the values of the object from state.
 * @type {StandardSelector}
 * @param {ReduxState}
 * @returns {Permission[]}
 */
export const permissionsByIdValuesSelector = createSelector([permissionsByIdSelector], allValues);

/**
 * Selects the permissions errors from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
export const permissionsErrorsSelector = createSelector([getState], (permissions) => permissions.errors);

/**
 * Selects the isFetching boolean for permissions from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {boolean}
 */
export const isFetchingPermissionsSelector = createSelector([getState], (permissions) => permissions.isFetching);

/**
 * Selects the lastFetched data for permissions from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {DateMaybe}
 */
export const permissionsLastFetchedSelector = createSelector([getState], (permissions) => permissions.lastFetched);

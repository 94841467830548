import { combineReducers } from 'redux';

import allLedgerUnmatchedLedgerFundingAccountsReducer from './allLedgerUnmatchedLedgerFundingAccountsReducer';
import errorReducer from './errorReducer';
import fetchReducer from './fetchReducer';
import lastUpdatedReducer from './lastUpdatedReducer';
import ledgerUnmatchedLedgerFundingAccountsByIdReducer from './ledgerUnmatchedLedgerFundingAccountsByIdReducer';

const ledgerUnmatchedLedgerFundingAccountsReducer = combineReducers({
  allIds: allLedgerUnmatchedLedgerFundingAccountsReducer,
  byId: ledgerUnmatchedLedgerFundingAccountsByIdReducer,
  errors: errorReducer,
  isFetching: fetchReducer,
  lastUpdated: lastUpdatedReducer,
});

export default ledgerUnmatchedLedgerFundingAccountsReducer;

import PropTypes from 'prop-types';
import React from 'react';

import { AddedContactsSummaryTitleBlock } from 'complexComponents';

import { allValues } from 'helpers/utility';

/**
 * A summary of all PartnershipMembers added to a new Partnership, regardless of which PartnershipMembers are going to
 * (potentially) be sent an invitation to accept the Partnership.
 * @param {ComponentProps} props
 * @param {Object} props.partnershipMembers - partnershipMembersById for this Partnership
 * @param {boolean} props.partnershipSubmitted - If this Partnership has already been submitted to the BE
 * @param {PartnershipTypes} [props.partnershipType]
 * @returns {StatelessComponent|null}
 */
const SummaryOfContacts = ({ partnershipMembers, partnershipSubmitted, partnershipType }) => {
  if (!partnershipSubmitted || !partnershipMembers) {
    return null;
  }

  const flattenedPartnershipMembers = allValues(partnershipMembers);

  return (
    <AddedContactsSummaryTitleBlock
      className="margin-top--m margin-left--m-large"
      partnershipMembers={flattenedPartnershipMembers}
      partnershipType={partnershipType}
    />
  );
};

SummaryOfContacts.propTypes = {
  partnershipMembers: PropTypes.shape({}),
  partnershipSubmitted: PropTypes.bool,
  partnershipType: PropTypes.string.isRequired,
};

SummaryOfContacts.defaultProps = {
  partnershipMembers: undefined,
  partnershipSubmitted: undefined,
};

export default SummaryOfContacts;

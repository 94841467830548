import { Download, Upload } from '@carbon/icons-react';
import { View } from '@routable/components';
import clsx from 'clsx';
import React, { useState } from 'react';
import ReactDropzone from 'react-dropzone';
import { Container, DropzoneCaption } from './Dropzone.styled';
import './Dropzone.scss';
const Dropzone = ({ accept = 'image/*, application/pdf', children, className, isDropOnly, multiple, onDragEnter, onDragLeave, onFilesDrop, rejectedFiles = [], ...rest }) => {
    const hasChildren = Boolean(children);
    const [dropZoneActive, setDropzoneActive] = useState(false);
    const handleOnDragEnter = (...args) => {
        setDropzoneActive(true);
        onDragEnter?.(...args);
    };
    const handleOnDragLeave = (...args) => {
        setDropzoneActive(false);
        onDragLeave?.(...args);
    };
    const handleOnDrop = (...args) => {
        setDropzoneActive(false);
        onFilesDrop?.(...args);
    };
    const isDraggingActive = hasChildren && dropZoneActive;
    return (React.createElement(ReactDropzone, { accept: accept, className: clsx('dropzone__container', className, {
            dropzone__available: !hasChildren,
            dropzone__active: dropZoneActive,
        }), disableClick: hasChildren, multiple: multiple, onDragEnter: handleOnDragEnter, onDragLeave: handleOnDragLeave, onDrop: handleOnDrop, ...rest },
        children,
        React.createElement("div", { className: clsx('dropzone__dashed_overlay', {
                'dropzone__dashed_overlay--visible': !hasChildren,
                'dropzone__dashed_overlay--invisible': hasChildren && !dropZoneActive,
                'dropzone__dashed_overlay--dragging-active': isDraggingActive,
            }), "data-testid": "dropzone__hashed_overlay" },
            rejectedFiles?.length > 0 && (React.createElement("div", { className: "dropzone__rejected_files" },
                React.createElement("span", { className: "dropzone__rejected_files_text" }, "Whoops! We do not support this file type. Try another one!"))),
            React.createElement(View, { className: "dropzone-overlay-view", scrollable: true }, !isDropOnly && (React.createElement(Container, null,
                React.createElement(Upload, { className: "w-5 h-5 text-blue-40" }),
                React.createElement(DropzoneCaption, { className: "drag-drop-caption" }, "Drag & drop here"),
                React.createElement(DropzoneCaption, null, "or"),
                React.createElement(DropzoneCaption, { className: "browse-files-caption" }, "Browse files"),
                React.createElement(DropzoneCaption, { className: "file-types" }, "PDF, PNG, CSV, JPEG, DOCX, XLXS & TXT files are allowed"))))),
        isDropOnly && isDraggingActive && (React.createElement("div", { className: "drop-only-text" },
            React.createElement(Download, { className: "w-7 h-7 text-blue-40 mb-5" }),
            React.createElement(DropzoneCaption, { className: "drag-drop-caption !text-lg !leading-6" }, "Drop files here"),
            React.createElement(DropzoneCaption, { className: "file-types !text-base !leading-6" }, "PDF, PNG, CSV, JPEG, DOCX, XLXS & TXT files are allowed")))));
};
export default Dropzone;

import { Checkmark, Close } from '@carbon/icons-react';
import { TruncatableText, Spinner } from '@routable/components';
import PropTypes from 'prop-types';
import React from 'react';

import { colorMainGreenHex, colorRedBoldHex } from 'constants/styles/colors';

const FileUploadProgress = ({ row }) => (
  <div className="flex items-center margin-bottom--sm">
    {row.isUploading && <Spinner size="xxs" />}
    {row.isUploaded && (
      <Checkmark className="shrink-0" color={colorMainGreenHex} data-testid="file-uploaded-progress--icon--completed" />
    )}
    {row.isError && (
      <Close className="shrink-0" color={colorRedBoldHex} data-testid="file-uploaded-progress--icon--error" />
    )}
    <TruncatableText
      className="font-regular semibold font-color--dark-jordan remove-margin-bottom ml-3"
      text={row.filename}
    />
  </div>
);

FileUploadProgress.propTypes = {
  row: PropTypes.shape().isRequired,
};

export default FileUploadProgress;

import { Select, TwoLineOption } from '@routable/gross-ds';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/text';
import { TooltipIcon } from 'components/tooltip';

import { colors, sizes, typography } from 'constants/styles';
import { ZIndexLayers } from 'constants/ui';

import { getZIndex } from 'helpers/zIndex';

import InteractionBar from '../InteractionBar';

/**
 * A version of the InteractionBar, configured for selection of
 * an option or setting, with icon, title, and option menu.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 */
const SettingBar = ({ className, iconName, options, onSelectValue, text, tooltipContent, tooltipProps, value }) => {
  const mappedOptions = options.map(({ description, label, icon: Icon, value }) => ({
    label: <TwoLineOption description={description} icon={Icon && <Icon />} label={label} />,
    textValue: label,
    value,
  }));

  return (
    <InteractionBar
      buttonRight={
        <Select className="min-w-[201px]" onChange={onSelectValue} options={mappedOptions} size="small" value={value} />
      }
      className={clsx('setting-info-bar', className)}
      leftContent={
        <div className="flex items-center">
          {iconName && (
            <TooltipIcon
              content={tooltipContent}
              contentMargin={sizes.spacing.MEDIUM}
              icon={iconName}
              iconColor={colors.colorMainJordanHex}
              iconProps={{
                className: 'align-self--center',
                marginRight: sizes.spacing.EXTRA_MEDIUM,
                size: sizes.iconSizes.LARGE,
              }}
              tooltipProps={{
                zIndex: getZIndex(ZIndexLayers.SLIDEOUT_PRIME.TOOLTIP),
                ...tooltipProps,
              }}
            />
          )}
          <Text.Bold size={typography.TextSize.LEVEL_300}>{text}</Text.Bold>
        </div>
      }
    />
  );
};

SettingBar.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string,
  onSelectValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  text: PropTypes.string.isRequired,
  tooltipContent: PropTypes.node.isRequired,
  tooltipProps: PropTypes.shape(),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ]),
};

SettingBar.defaultProps = {
  className: undefined,
  tooltipProps: {},
  value: undefined,
};

export default SettingBar;

import { createSelector } from 'reselect';

import {
  partnershipFromItemPropSelector,
  partnershipFromPartnershipIdPropSelector,
  partnershipFromQuerySelector,
} from 'queries/partnershipCompoundSelectors';
import { partnershipFromParamSelector } from 'queries/partnershipRouterSelectors';

import { addressesSelector } from 'selectors/addressesSelectors';
import { companiesSelector } from 'selectors/companiesSelectors';
import { fundingAccountForIdCompanySelector } from 'selectors/fundingSelectors';
import { partnershipFromPropsSelector } from 'selectors/partnershipsSelectors';
import { companyPropSelector, partnershipPropSelector } from 'selectors/propSelectors';
import { companyIdQuerySelector } from 'selectors/routerSelectors';

/**
 * Gets the `company` object by the id in query
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {Object} props
 * @returns {Company}
 */
export const companyFromQuerySelector = createSelector(
  [companiesSelector, companyIdQuerySelector],
  (companies, companyId) => companies[companyId],
);

/**
 * Gets the `company` value from component props, and returns the
 * value of `companies.byId[company]`.
 * @function
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @return {ObjectMaybe} Company data
 */
export const companyFromCompanyPropSelector = createSelector(
  [companiesSelector, companyPropSelector],
  (companies, company) => companies[company],
);

/**
 * Given state and a funding account id, return the associated company data.
 * @function
 * @param {ReduxState} State
 * @param {string} Funding account id
 * @return {ObjectMaybe} Company data
 */
export const companyForFundingAccountIdSelector = createSelector(
  [companiesSelector, fundingAccountForIdCompanySelector],
  (companies, companyId) => companies[companyId],
);

/**
 * Given state and a partnership query param, return the partner company
 * @type {StandardSelector}
 * @param {ReduxState} State
 * @param {ComponentProps} props
 * @return {?Company} Company data
 */
export const partnerCompanyFromPartnershipQuerySelector = createSelector(
  [companiesSelector, partnershipFromQuerySelector],
  (companies, partnership) => companies[partnership.partner],
);

/**
 * Given state and a partnership query param, return the partner company name
 * @type {StandardSelector}
 * @param {ReduxState} State
 * @param {ComponentProps} props
 * @return {?Company.name} Company name
 */
export const partnerCompanyNameFromPartnershipQuerySelector = createSelector(
  [partnerCompanyFromPartnershipQuerySelector],
  (partnerCompany) => partnerCompany?.name,
);

/**
 * Given state and a partnership url param, return the partner company.
 * @function
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @return {?Company} Company data
 */
export const partnerCompanyFromPartnershipParamSelector = createSelector(
  [companiesSelector, partnershipFromParamSelector],
  (companies, partnership) => companies[partnership?.partner],
);

/**
 * Lookup a partnership using the partnershipId. With the partnership, get the corresponding company, because
 * partnership.partner = company.id.
 * @type {StandardSelector}
 * @param {ReduxState}
 * @param {ComponentProps}
 * @return {Company|undefined}
 */
export const companyFromPartnershipFromPropsSelector = createSelector(
  [companiesSelector, partnershipFromPropsSelector],
  (companies = {}, partnership = {}) => companies[partnership.partner],
);

export const itemCompanyFromPropsSelector = createSelector(
  [companiesSelector, partnershipFromItemPropSelector],
  (companies = {}, partnership) => companies[partnership?.partner],
);

/**
 * Selects partner company retrieved from the state by looking at the partnership
 * prop
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @returns {Company}
 */
export const partnerCompanyFromPartnershipPropSelector = createSelector(
  [companiesSelector, partnershipPropSelector],
  (companies = {}, partnership) => companies[partnership?.partner],
);

/**
 * Given state and a partnership prop, returns the partner company name
 * @type {StandardSelector}
 * @param {ReduxState} State
 * @param {ComponentProps} props
 * @return {?Company.name} Company name
 */
export const partnerCompanyNameFromPartnershipPropSelector = createSelector(
  [partnerCompanyFromPartnershipPropSelector],
  (partnerCompany) => partnerCompany?.name,
);

/**
 * Selects partner company's mailing address by looking at the partnership
 * prop
 * @type {StandardSelect}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @returns {?Address}
 */
export const partnerCompanyAddressFromPartnershipPropSelector = createSelector(
  [partnerCompanyFromPartnershipPropSelector, addressesSelector],
  (company, addresses) => addresses[company?.mailingAddress],
);

/**
 * Selects partner company retrieved from the state by looking at the partnershipId
 * prop
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @returns {Company}
 */
export const partnerCompanyFromPartnershipIdPropSelector = createSelector(
  [companiesSelector, partnershipFromPartnershipIdPropSelector],
  (companies = {}, partnership) => companies[partnership?.partner],
);

export const partnerCompanyAddressFromPartnershipIdPropSelector = createSelector(
  [partnerCompanyFromPartnershipIdPropSelector, addressesSelector],
  (company, addresses) => addresses[company?.mailingAddress],
);

import React from 'react';
import { PrivacyPolicyLink, TermsOfServiceLink } from 'components/link';
import { WhiteSpace } from 'components/text';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { apostrophiseString } from 'helpers/stringHelpers';
const termsOfServiceLinkClassNames = 'font-color--inherit force-underline semibold';
const TermsOfServiceContent = ({ prefix }) => (React.createElement("span", null,
    React.createElement("span", { className: "disable-pointer-events" }, `${prefix} agree to ${apostrophiseString(PLATFORM_DISPLAY_SHORT_NAME)}`),
    React.createElement(WhiteSpace, null),
    React.createElement(TermsOfServiceLink, { className: termsOfServiceLinkClassNames }),
    React.createElement(WhiteSpace, null),
    React.createElement("span", { className: "disable-pointer-events" }, "and"),
    React.createElement(WhiteSpace, null),
    React.createElement(PrivacyPolicyLink, { className: termsOfServiceLinkClassNames }),
    "."));
TermsOfServiceContent.defaultProps = {
    prefix: 'I',
};
export default TermsOfServiceContent;

import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2, IconNames } from 'components';

import { ButtonSize } from 'constants/button';
import { sizes } from 'constants/styles';

/**
 * Button used to update company, membership, and partnership info. Submits CollectTaxForm.
 * @param {boolean} isDisabled
 * @param {boolean} isSubmitting
 * @returns {StatelessComponent}
 * @constructor
 */
const ConfirmInformationButton = ({ isDisabled, isSubmitting }) => (
  <ButtonV2
    isDisabled={isDisabled}
    isLoading={isSubmitting}
    leftIconClassName="margin-right--m"
    leftIconName={IconNames.TICK_CIRCLE}
    leftIconSize={sizes.iconSizes.LARGE}
    rightIconName={IconNames.ARROW_RIGHT}
    rightIconProps={{ style: { marginLeft: 'auto' } }}
    rightIconSize={sizes.iconSizes.LARGE}
    size={ButtonSize.LARGE}
    type="submit"
  >
    Confirm information
  </ButtonV2>
);

ConfirmInformationButton.propTypes = {
  isDisabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

ConfirmInformationButton.defaultProps = {
  isDisabled: undefined,
  isSubmitting: undefined,
};

export default ConfirmInformationButton;

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { formNamesExternal } from 'constants/forms';
import { countryListOptions } from 'constants/i18n';

import AddressReduxForm from 'modules/address/presenters/AddressReduxForm';

import { externalCreateManualAddressFormSelector } from 'selectors/forms';

const CreateManualAddressForm = ({ addressValue }) => (
  <AddressReduxForm
    address={addressValue}
    addressPath="address"
    countryOptions={countryListOptions.US_AND_CANADA}
    formName={formNamesExternal.CREATE_MANUAL_ADDRESS}
  />
);

CreateManualAddressForm.propTypes = {
  addressValue: PropTypes.shape(),
};

CreateManualAddressForm.defaultProps = {
  addressValue: {},
};

const mapStateToProps = (state) => ({
  addressValue: externalCreateManualAddressFormSelector(state, 'address'),
});

export default connect(mapStateToProps)(CreateManualAddressForm);

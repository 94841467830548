import PropTypes from 'prop-types';
import React from 'react';

import { isExternalPathAccept, isExternalPathPay } from 'helpers/external';

import ExternalPromptCustomerSingleItem from 'modules/external/externalPrompt/externalPromptCustomerSingleItem/ExternalPromptCustomerSingleItem';
import ExternalPromptVendorSingleItem from 'modules/external/externalPrompt/externalPromptVendorSingleItem/ExternalPromptVendorSingleItem';
import ExternalPromptCustomerNoItems from 'modules/external/externalPrompt/global/presenters/ExternalPromptCustomerNoItems';
import ExternalPromptVendorNoItems from 'modules/external/externalPrompt/global/presenters/ExternalPromptVendorNoItems';

import './ExternalPrompt.scss';

const ExternalPrompt = ({ itemId, onCancel, onStart }) => {
  if (isExternalPathAccept(window.location)) {
    return (
      <div className="external-prompt">
        {itemId ? (
          <ExternalPromptVendorSingleItem onCancel={onCancel} onStart={onStart} />
        ) : (
          <ExternalPromptVendorNoItems onCancel={onCancel} onStart={onStart} />
        )}
      </div>
    );
  }

  if (isExternalPathPay(window.location)) {
    return (
      <div className="external-prompt">
        {itemId ? (
          <ExternalPromptCustomerSingleItem onCancel={onCancel} onStart={onStart} />
        ) : (
          <ExternalPromptCustomerNoItems onCancel={onCancel} onStart={onStart} />
        )}
      </div>
    );
  }

  return null;
};

ExternalPrompt.propTypes = {
  itemId: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
};

ExternalPrompt.defaultProps = {
  itemId: undefined,
};

export default ExternalPrompt;

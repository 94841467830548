import { createIsUpdatingReducer } from 'store/redux';

import {
  UPDATE_NOTIFICATION_SETTINGS_FAILURE,
  UPDATE_NOTIFICATION_SETTINGS_REQUEST,
  UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
} from 'types/notifications';

export const finishCases = [UPDATE_NOTIFICATION_SETTINGS_FAILURE, UPDATE_NOTIFICATION_SETTINGS_SUCCESS];
export const requestCases = [UPDATE_NOTIFICATION_SETTINGS_REQUEST];

const isUpdatingReducer = createIsUpdatingReducer(finishCases, requestCases);

export default isUpdatingReducer;

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { currentCompanyCompanyTypeSelector } from 'selectors/currentCompanySelectors';

import PartnerCompanyTaxForm from './PartnerCompanyTaxForm';

export const mapStateToProps = createStructuredSelector({
  companyType: currentCompanyCompanyTypeSelector,
});

export default connect(mapStateToProps)(PartnerCompanyTaxForm);

/**
 * @file Sagas for company sockets
 * Naming definitions:
 * Socket - websocket connection between the browser and pusher
 * Channel - a channel that we subscribe to in order to listen to updates (we can technically subscribe to more than one. We currently only subscribe to one, which is the company id).
 * Event - the instance of a notification that is happening when Pusher pushes something to the application (e.g. item update)
 */

import { eventChannel } from 'redux-saga';
import { call, put, spawn, take, takeEvery } from 'redux-saga/effects';

import * as events from 'constants/events';

import { getCurrentCompanyId } from 'helpers/localStorage';
import { bindSocketChannelEvent, disconnectSocket, subscribeToSocketChannel } from 'helpers/pusher';

import * as types from 'types/currentCompany';
import * as socketTypes from 'types/socket';

import * as socketHandlers from './socket';

const debounceSocketFlag = 10000;

function withSocketFlag(sagaFunction) {
  // eslint-disable-next-line func-names
  return function* (...args) {
    // eslint-disable-next-line no-undef
    globalThis.fromSocket = true;
    try {
      yield call(sagaFunction, ...args);
    } finally {
      // Debounce the reset because of delays in our sagas
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        globalThis.fromSocket = false;
      }, debounceSocketFlag);
    }
  };
}

/**
 * Creates an event channel connected to the company sockets.
 * Emits sockets events as saga-local actions, which are dispatched
 * as-is onto the store and other sagas by the handleSocketEvent generator.
 * @param {string} companyId
 * @return {eventChannel}
 */
export const socketChannel = (companyId) => {
  // TODO: [FRON-2666] - Add saga socketChannel tests
  const notificationChannel = subscribeToSocketChannel(companyId);

  return eventChannel((emitter) => {
    const billingCodeAdjustmentChangeEventHandler =
      socketHandlers.makeSocketBillingCodeAdjustmentChangedEventHandler(emitter);
    const bulkImportChangeEventHandler = socketHandlers.makeBulkImportChangeEventHandler(emitter);
    const companyBrandingEventHandler = socketHandlers.makeSocketCompanyBrandingSettingsChangedEventHandler(emitter);
    const companyMembershipsEventHandler = socketHandlers.makeSocketCompanyMembershipsChangedEventHandler(emitter);
    const companySettingsProductEventHandler =
      socketHandlers.makeSocketCompanySettingsProductChangedEventHandler(emitter);
    const companySettingsItemApprovalEventHandler =
      socketHandlers.makeSocketCompanySettingsItemApprovalChangeEventHandler(emitter);
    const fundingCustomerEventHandler = socketHandlers.makeSocketFundingCustomerEventHandler(emitter);
    const fundingInfoBalanceEventHandler = socketHandlers.makeSocketFundingInfoBalanceEventHandler(emitter);
    const itemStatusChangeEventHandler = socketHandlers.makeSocketItemStatusChangedEventHandler(emitter);
    const membershipsEventHandler = socketHandlers.makeSocketMembershipChangedEventHandler(emitter);
    const membershipInviteEventHandler = socketHandlers.makeSocketMembershipInviteChangedEventHandler(
      emitter,
      companyId,
    );
    const syncEventHandler = socketHandlers.makeSocketSyncEventHandler(emitter);
    const itemCreateHandler = socketHandlers.makeSocketItemCreateHandler(emitter);
    const itemVersionChangeHandler = socketHandlers.makeSocketItemVersionChangeHandler(emitter);
    const annotationChangeHandler = socketHandlers.makeSocketAnnotationChangeHandler(emitter);
    const webhookSettingsEventHandler = socketHandlers.makeSocketWebhookSettingsEventHandler(emitter);
    const webhookTestEventHandler = socketHandlers.makeSocketWebhookTestEventHandler(emitter);
    const poDiscrepanciesRefreshedHandler = socketHandlers.makeSocketPoDiscrepanciesRefreshedEventHandler;
    // [DEV-2319] @adamjaffeback remove this socket handler from DEV-2008 because it was causing a loading screen
    // to flash multiple time on the create confirmation page.

    bindSocketChannelEvent(
      notificationChannel,
      events.BILLING_CODE_ADJUSTMENT_CHANGE,
      billingCodeAdjustmentChangeEventHandler,
    );
    bindSocketChannelEvent(notificationChannel, events.COMPANY_BRAND_CHANGE, companyBrandingEventHandler);
    bindSocketChannelEvent(notificationChannel, events.COMPANY_MEMBERSHIPS_CHANGE, companyMembershipsEventHandler);
    bindSocketChannelEvent(
      notificationChannel,
      events.COMPANY_SETTINGS_ITEM_APPROVAL_CHANGE,
      companySettingsItemApprovalEventHandler,
    );
    bindSocketChannelEvent(
      notificationChannel,
      events.COMPANY_SETTINGS_PRODUCT_CHANGE,
      companySettingsProductEventHandler,
    );
    bindSocketChannelEvent(
      notificationChannel,
      [events.ITEM_APPROVAL_CHANGE, events.ITEM_STATUS_CHANGE],
      itemStatusChangeEventHandler,
    );
    bindSocketChannelEvent(notificationChannel, events.OCR_ANNOTATION_STATUS_CHANGE, itemVersionChangeHandler);
    bindSocketChannelEvent(notificationChannel, events.OCR_ANNOTATION_CHANGE, annotationChangeHandler);
    bindSocketChannelEvent(notificationChannel, events.INTEGRATION_CONFIG_SYNC, syncEventHandler);
    bindSocketChannelEvent(notificationChannel, events.FUNDING_CUSTOMER_CHANGE, fundingCustomerEventHandler);
    bindSocketChannelEvent(notificationChannel, events.FUNDING_INFO_BALANCE_CHANGE, fundingInfoBalanceEventHandler);
    bindSocketChannelEvent(notificationChannel, events.WEBHOOK_SETTINGS_CHANGE, webhookSettingsEventHandler);
    bindSocketChannelEvent(notificationChannel, events.WEBHOOK_TEST_RESULTS_CHANGE, webhookTestEventHandler);
    bindSocketChannelEvent(notificationChannel, events.MEMBERSHIP_CHANGE, membershipsEventHandler);
    bindSocketChannelEvent(notificationChannel, events.BULK_IMPORT_CHANGE, bulkImportChangeEventHandler);
    bindSocketChannelEvent(notificationChannel, events.MEMBERSHIP_INVITE_CHANGE, membershipInviteEventHandler);
    bindSocketChannelEvent(notificationChannel, events.ITEM_CREATE, itemCreateHandler);
    // [DEV-2319] @adamjaffeback See comment above where itemVersionChangeHandler is defined
    bindSocketChannelEvent(notificationChannel, events.ITEM_VERSION_CHANGE, itemVersionChangeHandler);
    bindSocketChannelEvent(notificationChannel, events.PO_DISCREPANCIES_REFRESHED, poDiscrepanciesRefreshedHandler);

    // event channel must return a function to unsubscribe
    return () => {
      notificationChannel.unsubscribe(companyId);
    };
  });
};

/**
 * Handles events emitted by the sockets event channel.
 * @return {IterableIterator<*>}
 */
export function* handleSocketEvent(action = {}) {
  try {
    yield put(action);
  } catch (e) {
    yield null;
  }
}

const handleSocketEventWithFlag = withSocketFlag(handleSocketEvent);

/**
 * Listen for a sockets disconnect trigger action.
 * @return {IterableIterator<*>}
 */
export function* handleSocketDisconnect() {
  disconnectSocket();
  yield null;
}

/**
 * Listens for actions generated by
 * events external to redux-- in this case, the company
 * websocket.
 * @return {IterableIterator<*>}
 */
export function* watchCompanySocket() {
  const companyId = getCurrentCompanyId();
  const channel = yield call(socketChannel, companyId);
  yield takeEvery(channel, handleSocketEventWithFlag);
}

/**
 * Listens for success getting the current company, then
 * opening the socket and event channel.
 * websocket.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    // await on  any dispatch of get company success
    yield take(types.GET_CURRENT_COMPANY_SUCCESS);
    // spawn a separate (non-blocked) process to watch the company socket
    yield spawn(watchCompanySocket);
    // block this saga from doing any more work until the socket disconnects
    yield take(socketTypes.DISCONNECT_SOCKET);
  }
}

/**
 * Root sockets saga.
 * @return {IterableIterator<*>}
 */
export default function* sockets() {
  yield watch();
}

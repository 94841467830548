import classNames from 'classnames';
import React from 'react';
import './MaintenanceHeading.scss';
export const MaintenanceHeading = ({ className, name, subtitle, title }) => (React.createElement("div", { className: classNames({
        'feature-heading': true,
        [className]: !!className,
    }) },
    name && React.createElement("p", { className: "feature-heading--name" }, name),
    React.createElement("h3", { className: "feature-heading--title" }, title),
    subtitle && React.createElement("p", { className: "feature-heading--subtitle" }, subtitle)));

import { Dialog } from '@routable/gross-ds';
import { cva } from 'class-variance-authority';
import styled from 'styled-components';
const LookupWindowHeaderHeight = 176;
const LookupWindowFooterHeight = 80;
const LookupWindowTabsHeight = 48;
export const LookupWindow = styled(Dialog) ``;
export const LookupBody = styled.div.attrs((props) => ({
    hasTabs: props.hasTabs,
})) `
  /* tabpanel is the panel generated by <Tabs> */
  & [role='tabpanel'] {
    padding: 0px 2px 0px 2px;
  }

  /* prevent border under tabs from extending full with of modal */
  & [role='tablist'] {
    width: fit-content;
  }

  /* Keep table within modal window */
  .routable-table {
    max-height: ${(props) => props.hasTabs
    ? `calc(
      100vh - ${LookupWindowHeaderHeight}px - ${LookupWindowFooterHeight}px - ${LookupWindowTabsHeight}px
    )`
    : `calc(
      100vh - ${LookupWindowHeaderHeight}px - ${LookupWindowFooterHeight}px
    )`};
  }
`;
export const LookupTableContainer = styled.div ``;
export const lookupWindowPanel = cva([
    'absolute',
    'shadow-2xl',
    'z-1',
    'left-1/2',
    'top-1/2',
    'min-w-[32rem]',
    'max-w-[95%]',
    'transform',
    '-translate-y-1/2',
    '-translate-x-1/2',
    'flex',
    'flex-col',
    'gap-3',
    'border-0',
    'rounded-xl',
    'intent-primary',
]);

import PropTypes from 'prop-types';
import React from 'react';

import Block from './Block';
import { flexDefaults, flexProps } from './flexProps';

const Row = ({ children, ...rest }) => (
  <Block isColumn={false} {...rest}>
    {children}
  </Block>
);

Row.propTypes = {
  ...flexProps,
  breakpointProps: PropTypes.objectOf(
    PropTypes.shape({
      ...flexProps,
      cols: PropTypes.number,
    }),
  ),
  children: PropTypes.node,
  className: PropTypes.string,
  cols: PropTypes.number,
  style: PropTypes.shape(),
};

Row.defaultProps = {
  ...flexDefaults,
  breakpointProps: undefined,
  children: undefined,
  className: undefined,
  cols: undefined,
  style: {},
};

export default Row;

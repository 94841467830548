import { combineReducers } from 'redux';

import allIntegrationsReducer from './allIntegrationsReducer';
import errorReducer from './errorReducer';
import fetchReducer from './fetchReducer';
import integrationsByIdReducer from './integrationsByIdReducer';
import lastUpdatedReducer from './lastUpdatedReducer';

const integrationsReducer = combineReducers({
  allIds: allIntegrationsReducer,
  byId: integrationsByIdReducer,
  errors: errorReducer,
  isFetching: fetchReducer,
  lastUpdated: lastUpdatedReducer,
});

export default integrationsReducer;

import { AddAlt } from '@carbon/icons-react';
import React from 'react';
import { Tag } from 'components/tag';
import { Text } from 'components/text';
import { PartnershipTypes } from 'constants/partnership';
import { typography } from 'constants/styles';
import { TagType } from 'constants/ui';
import { getCustomerOrVendorTextByPartnership } from 'helpers/partnerships';
import { capitalize } from 'helpers/stringHelpers';
import { SearchCompanyOption } from './SearchCompanyOption';
const MultiLineOptionForSearchCompanies = ({ data, ...rest }) => {
    const isCreateNewOption = Boolean(data.__isNew__);
    return (React.createElement(SearchCompanyOption, { ...rest, data: {
            ...data,
            leftAddons: isCreateNewOption ? (React.createElement("span", { className: "text-black w-3 blue-on-option-hover" },
                React.createElement(AddAlt, null))) : undefined,
            rightAddons: !isCreateNewOption && (React.createElement("div", { className: "display--flex" },
                data.isCustomer && data.isVendor && (React.createElement(Tag, { className: "cursor--inherit--important margin-right--m margin-left--xs padding-left--m padding-right--m", hasIcon: false, isClosable: false, readOnly: true, TextComponent: Text.Semibold, textSize: typography.TextSize.LEVEL_100, type: TagType.SUCCESS }, capitalize(PartnershipTypes.VENDOR))),
                React.createElement(Tag, { className: "cursor--inherit--important padding-left--m padding-right--m", hasIcon: false, isClosable: false, readOnly: true, TextComponent: Text.Semibold, textSize: typography.TextSize.LEVEL_100, type: TagType.SUCCESS }, capitalize(getCustomerOrVendorTextByPartnership(data))))),
        } }));
};
export default MultiLineOptionForSearchCompanies;

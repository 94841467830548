import { getLocation, goBack, push } from 'connected-react-router';
import { fork, put, select, take } from 'redux-saga/effects';

import * as types from 'types/navigation';

/**
 * Push to history.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* pushHistory(action) {
  const {
    payload: { url },
  } = action;

  yield put(push(url));
}

/**
 * Push to history.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* goBackIfPreviousState(action) {
  const {
    payload: { fallbackUrl },
  } = action;

  const location = yield select(getLocation);

  if (location.state && location.state.previous) {
    yield put(goBack());
    return;
  }

  yield put(
    push({
      pathname: fallbackUrl,
      state: { previous: location.pathname },
    }),
  );
}

/**
 * Listens for redux actions related to navigation.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([types.GO_BACK_IF_PREVIOUS_STATE, types.PUSH_HISTORY]);

    switch (action.type) {
      case types.PUSH_HISTORY:
        yield fork(pushHistory, action);
        break;

      case types.GO_BACK_IF_PREVIOUS_STATE:
        yield fork(goBackIfPreviousState, action);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root navigation saga.
 * @return {IterableIterator<*>}
 */
export default function* navigation() {
  yield watch();
}

export const MINIMUM_STRENGTH = 3;
export const MINIMUM_LENGTH = 10;
export const errorStyles = {
    MUTED: 'muted',
    HIGHLIGHTED: 'highlighted',
};
export const elementsToDisplay = {
    ALL: 'all',
    STRENGTH: 'strength',
    NONE: 'none',
};

import {} from 'axios';
import {} from 'actions/routines/createApiRoutine';
import { parseFeatureFlags } from 'data/parse';
import { FetchService } from 'services';
import { featureFlagsEndpoint } from 'services/api/configEndpoints';
export const getFetchFeatureFlagsApiConfig = () => {
    const responseTransformers = [
        parseFeatureFlags.transform.attachIdToFlagResponseObjects,
        ...FetchService.getTransformers({}),
        parseFeatureFlags.transform.flagsResponseToEnabledKeys,
    ];
    return {
        endpoint: featureFlagsEndpoint,
        method: 'GET',
        requireAuth: true,
        transformers: responseTransformers,
        headers: {},
    };
};

import { createRoutine } from 'redux-saga-routines';

import * as types from 'types/memberships';

/**
 * @type {ReduxSagaRoutine}
 */
export const disableMembershipRoutine = createRoutine(types.DISABLE_MEMBERSHIP);

/**
 * @type {ReduxSagaRoutine}
 */
export const enableMembershipRoutine = createRoutine(types.ENABLE_MEMBERSHIP);

/**
 * @type {ReduxSagaRoutine}
 */
export const updateMembershipRoutine = createRoutine(types.UPDATE_MEMBERSHIP_REQUEST);

import _get from 'lodash/get';

import { SORT_ORDER } from 'constants/sort';

import { firstValue, identity } from 'helpers/utility';

/**
 * Sort array with given comparison function
 * @param {array} array
 * @param {function} comparisonFunc
 * @return {object}
 */
export const sortArray = (array, comparisonFunc = undefined) => {
  if (!Array.isArray(array)) {
    return [];
  }

  return array.sort(comparisonFunc);
};

/**
 * Sort an array of objects by specific object key
 * @param {Object[]} arrayOfObjects
 * @param {string|Object} sortByKeys
 * @param {Object} [options=[]]
 * @return {Object[]} array of objects, sorted
 */
export const sortObjectArray = (arrayOfObjects, sortByKeys, options = {}) => {
  const { comparisonFunc = identity } = options;

  // Support textual (legacy) sorting instruction
  const sortKeysData = typeof sortByKeys === 'string' ? [{ [sortByKeys]: SORT_ORDER.ASC }] : sortByKeys;

  // Iterate in reverse order to keep sorting in the desired order of sorting keys give by the array's order
  return sortKeysData.reduceRight((accum, sortKeyData) => {
    const [sortKey, sortOrder] = firstValue(Object.entries(sortKeyData));
    const isSortAsc = sortOrder === SORT_ORDER.ASC;

    const compare = (a, b) => {
      const aValue = comparisonFunc(_get(a, sortKey));
      const bValue = comparisonFunc(_get(b, sortKey));

      if (aValue < bValue) {
        return isSortAsc ? -1 : 1;
      }

      if (aValue > bValue) {
        return isSortAsc ? 1 : -1;
      }

      return 0;
    };

    return sortArray(accum, compare);
  }, arrayOfObjects);
};

/**
 * We sort by ordinals often, so this is a quick helper to make that easier.
 *
 * @param {Object[]} arrayOfObjects
 * @param {string} [ordinalKey='ordinal']
 * @returns {Object[]} - sorted array
 */
export const sortByOrdinal = (arrayOfObjects, ordinalKey = 'ordinal') => sortObjectArray(arrayOfObjects, ordinalKey);

import { changePasswordRoutine } from 'actions/routines/auth';

import { AuthActionType } from 'enums/auth';

import { gatherReducerCasesFromRoutines } from 'helpers/routine';

import { createErrorReducer } from 'store/redux';

const { failureCases: failCases, requestCases, successCases } = gatherReducerCasesFromRoutines([changePasswordRoutine]);

export const failureCases = [...failCases, AuthActionType.SUBMIT_CHANGE_PASSWORD_FAILURE];
export const successAndRequestCases = [
  ...requestCases,
  ...successCases,
  AuthActionType.SUBMIT_CHANGE_PASSWORD_REQUEST,
  AuthActionType.SUBMIT_CHANGE_PASSWORD_SUCCESS,
];

export default createErrorReducer(successAndRequestCases, failureCases);

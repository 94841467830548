import ThunkFetch from 'helpers/thunkFetch';

import { getIntegrationLedgerMatchingEndpoint } from 'services/api/integrationEndpoints';

import {
  SUBMIT_LEDGER_PARTNERSHIP_MERGE_FAILURE,
  SUBMIT_LEDGER_PARTNERSHIP_MERGE_REQUEST,
  SUBMIT_LEDGER_PARTNERSHIP_MERGE_SUCCESS,
} from 'types/integrations';

const submitIntegrationLedgerPartnershipMerge = (integrationConfigId, payload, successCallback = null) => {
  const t = new ThunkFetch({
    actions: {
      error: SUBMIT_LEDGER_PARTNERSHIP_MERGE_FAILURE,
      request: SUBMIT_LEDGER_PARTNERSHIP_MERGE_REQUEST,
      success: SUBMIT_LEDGER_PARTNERSHIP_MERGE_SUCCESS,
    },
    endpoint: getIntegrationLedgerMatchingEndpoint(integrationConfigId, 'partnerships'),
    method: 'POST',
    payload: {
      data: {
        type: 'Match',
      },
      meta: {
        partnership: payload,
      },
    },
    requireAuth: true,
    successCallback,
  });

  return t.makeRequest();
};

export default submitIntegrationLedgerPartnershipMerge;

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTaxFormOptions } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.context';
import { taxFormEntryType, w8LimitationOnBenefits, } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.models';
import { ExternalLink } from 'components';
import { isCompanyTypeBusiness } from 'helpers/currentCompany';
import { getLabelValuePairsFromEnum } from '../helpers';
import RenderCheckbox from '../RenderCheckbox';
import RenderSelect from '../RenderSelect';
import RenderTextInput from '../RenderTextInput';
import { Group, GroupSlot } from '../styles';
const FieldGroupTaxTreatyBenefits = () => {
    const { watch } = useFormContext();
    const { entryType } = useTaxFormOptions();
    const claimTaxTreatyBenefits = watch('claimingTreatyBenefits');
    const companyType = watch('companyType');
    const isBusiness = isCompanyTypeBusiness(companyType);
    const treatyBenefitsHelpText = entryType === taxFormEntryType.Enum.external
        ? 'If applicable, indicate your eligibility for treaty benefits under a tax treaty between your country and the United States.'
        : `If applicable, indicate the vendor's eligibility for treaty benefits under a tax treaty between the vendor's country and the United States.`;
    const href = isBusiness
        ? 'https://www.irs.gov/forms-pubs/about-form-w-8-ben-e'
        : 'https://www.irs.gov/forms-pubs/about-form-w-8-ben';
    return (React.createElement(Group, null,
        React.createElement(GroupSlot, { className: "text-grey-60", size: 6 },
            React.createElement("p", { className: "leading-5" }, treatyBenefitsHelpText),
            React.createElement("p", { className: "leading-5" },
                "For detailed information, please refer to the IRS Instructions for",
                ' ',
                React.createElement(ExternalLink, { href: href }, isBusiness ? 'Form W-8BEN-E' : 'Form W-8BEN')),
            React.createElement("p", { className: "italic" }, "This is not tax advice. For assistance, consult a tax professional.")),
        React.createElement(GroupSlot, { size: 6 },
            React.createElement(RenderCheckbox, { label: entryType === taxFormEntryType.Enum.external
                    ? 'I claim treaty benefits'
                    : 'The vendor claims treaty benefits', name: "claimingTreatyBenefits" })),
        claimTaxTreatyBenefits && (React.createElement(React.Fragment, null,
            isBusiness && (React.createElement(GroupSlot, { size: 6 },
                React.createElement(RenderSelect, { isRequired: false, label: "Limitation on benefits provision", name: "treatyBenefitsLimitationOnBenefits", options: getLabelValuePairsFromEnum(w8LimitationOnBenefits), placeholder: "Select limitation type" }))),
            React.createElement(GroupSlot, { size: 2 },
                React.createElement(RenderTextInput, { name: "treatyBenefitsArticleAndParagraph", placeholder: "Article & Paragraph", shouldUnregister: true })),
            React.createElement(GroupSlot, { size: 2 },
                React.createElement(RenderTextInput, { name: "treatyBenefitsWithholdingRate", placeholder: "Withholding Rate", shouldUnregister: true })),
            React.createElement(GroupSlot, { size: 2 },
                React.createElement(RenderTextInput, { name: "treatyBenefitsTypeOfIncome", placeholder: "Type of Income", shouldUnregister: true }))))));
};
export default FieldGroupTaxTreatyBenefits;

import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';

import { restrictAccessToComponentWithRequiredPermissions } from 'helpers/permissions';

import withCurrentMemberPermissionSet from '../withCurrentMemberPermissionSet';

/**
 * An HOC which returns the correct component based on whether the current membership has the correct permissions.
 *
 * @param {OptionsArg} options
 * @param {RequiredPermissions} options.requiredPermissions
 * @param {AnyComponent} options.WithoutPermissionComponent
 * @param {AnyComponent} options.WithPermissionComponent
 * @returns {AnyComponent}
 */
const withPermissionRestriction = ({ requiredPermissions, WithoutPermissionComponent, WithPermissionComponent }) => {
  /**
   * Component which we connect to state to compare the required permissions against the permissions current held by the
   * current membership.
   *
   * @param {ComponentProps} props
   * @param {CurrentMemberPermissionSet} props.currentMemberPermissionSet
   * @param {RestOfProps} props.rest
   * @returns {AnyComponent}
   */
  const ComponentWithPermissionRestriction = ({ currentMemberPermissionSet, ...rest }) => {
    const Component = restrictAccessToComponentWithRequiredPermissions({
      currentMemberPermissionSet,
      requiredPermissions,
      WithoutPermissionComponent,
      WithPermissionComponent,
    });

    return <Component {...rest} />;
  };

  ComponentWithPermissionRestriction.propTypes = {
    currentMemberPermissionSet: PropTypes.instanceOf(Set).isRequired,
  };

  ComponentWithPermissionRestriction.displayName = 'WithPermissionRestriction';

  // HOCs don't forward refs on their own. This is problematic for this HOC, because if the permissions don't match
  // we may wrap one of the children in the Component with a tooltip..and the tooltip will need this ref.
  return React.forwardRef((props, ref) => <ComponentWithPermissionRestriction setRef={ref} {...props} />);
};

const enhance = compose(withCurrentMemberPermissionSet, withPermissionRestriction);

export default (componentOptions) => enhance(componentOptions);

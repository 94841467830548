import React from 'react';
import { Hint } from 'components/hint';
import { Text } from 'components/text';
import { Intent } from 'constants/ui';
import { isCompanyTypeBusiness } from 'helpers/currentCompany';
import { listAllElementsInArray } from 'helpers/stringHelpers';
import './LegalNameChangeConfirmationHint.scss';
const affectedStatuses = ['Pending', 'Ready to send', 'Scheduled', 'Needs approval'];
const LegalNameChangeConfirmationHint = ({ companyType, shouldShowTaxAutoArchiveHint, ...rest }) => {
    const isBusiness = isCompanyTypeBusiness(companyType);
    const businessOrPersonalName = isBusiness ? 'name' : 'first and last name';
    const hintTitle = isBusiness
        ? 'Make sure this legal name is correct'
        : 'Make sure your legal first and last name are correct';
    const taxAutoArchiveText = `If you make any edits to the legal entity ${businessOrPersonalName}, you'll need to request new tax information. Existing tax information will be archived.`;
    const hintBody = `We'll update the legal ${businessOrPersonalName} on all payables and receivables with the following statuses: ${listAllElementsInArray(affectedStatuses)}.`;
    return (React.createElement(Hint, { intent: Intent.WARNING, ...rest },
        React.createElement(Text.Bold, { className: "font-color--yellow-dark--important" }, hintTitle),
        shouldShowTaxAutoArchiveHint && React.createElement("p", { className: "hint--content" }, taxAutoArchiveText),
        React.createElement("p", { className: "hint--content remove-margin-bottom" }, hintBody)));
};
export default LegalNameChangeConfirmationHint;

import { CurrencyCodeUsd, CurrencyCodeMapUsd } from 'constants/currency';
import { getCurrencyFromCode } from './currency';
import { parseCurrency } from './numbers';
export const formatAmount = (amount, options) => parseCurrency(amount, { symbol: '', ...options });
export const formatCurrency = (amount, currencyCode, currencyCodeMap, options) => {
    const currencyCodeDetail = getCurrencyFromCode(currencyCodeMap, currencyCode);
    if (!currencyCodeDetail) {
        return '';
    }
    const { symbol } = currencyCodeDetail;
    return parseCurrency(amount, { symbol, ...options });
};
export const formatCurrencyExplicit = (amount, currencyCode, currencyCodeMap, options) => {
    const formattedAmount = formatCurrency(amount, currencyCode, currencyCodeMap, { ...options });
    if (!formattedAmount) {
        return '';
    }
    return `${formattedAmount} ${currencyCode}`;
};
export const formatCurrencyUSDExplicit = (amount, options) => formatCurrencyExplicit(amount, CurrencyCodeUsd, CurrencyCodeMapUsd, options);
export const formatCurrencyUSD = (amount, options) => formatCurrency(amount, CurrencyCodeUsd, CurrencyCodeMapUsd, options);
export const currencyToNumberString = (currency) => (currency || '').replace(/[^0-9.-]+/g, '');

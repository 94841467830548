import React from 'react';

import { ExternalHintStatus } from 'constants/external';

import StepHintBase from 'modules/external/externalStepHint/StepHintBase';

/**
 * Component rendering External Step Hint with hint status of INCOMPLETE
 * @see {StepHintBase}
 *
 * @return {StatelessComponent}
 */
const IncompleteStepHint = () => <StepHintBase hintStatus={ExternalHintStatus.INCOMPLETE} />;

export default IncompleteStepHint;

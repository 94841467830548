import PropTypes from 'prop-types';

import { inputNameOrNameProp } from 'helpers/propTypes';

export const propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  dataId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  decimalScale: PropTypes.number,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  displayType: PropTypes.string,
  errName: PropTypes.string,
  format: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  hideLabel: PropTypes.bool,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: inputNameOrNameProp,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onValueChange: PropTypes.func,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.shape(),
  suffix: PropTypes.string,
  thousandSeparator: PropTypes.bool,
  type: PropTypes.string.isRequired,
  validator: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const defaultProps = {
  className: '',
  currency: '',
  dataId: null,
  decimalScale: 2,
  defaultValue: '',
  displayType: 'input',
  errName: null,
  format: null,
  hideLabel: undefined,
  id: null,
  isDisabled: false,
  max: null,
  maxLength: null,
  min: null,
  minLength: null,
  name: null,
  onBlur: () => undefined,
  onClick: null,
  onChange: null,
  onFocus: () => undefined,
  onValueChange: null,
  pattern: null,
  placeholder: '',
  isRequired: true,
  step: null,
  style: {},
  suffix: '',
  thousandSeparator: true,
  validator: null,
  value: '',
};

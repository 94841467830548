import _get from 'lodash/get';
import React from 'react';
import { useSelector } from 'react-redux';
import { createFormMetaSelector } from 'selectors/createFormSelectors';
const useFieldMeta = (formName, fieldName) => {
    const formMetaSelector = React.useMemo(() => createFormMetaSelector(formName), [formName]);
    const formMeta = useSelector(formMetaSelector);
    const fieldMeta = React.useMemo(() => _get(formMeta, fieldName), [formMeta, fieldName]);
    return fieldMeta;
};
export default useFieldMeta;

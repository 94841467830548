import PropTypes from 'prop-types';
import React from 'react';

import DateInputWrapper from './DateInputWrapper';

/**
 * Helper function to render via redux-form's Field component;
 * normalizes prop names across usages.
 * e.g. - <Field component={ReduxFormFieldRenderDateInput} ... />
 * @param input {Object} Props from redux-form Field
 * @param meta {Object} Info object from redux-form Field
 * @param rest {Object} All other props passed to the component
 * @returns {DateInputWrapper}
 */
const ReduxFormFieldRenderDateInput = ({ input, meta, ...rest }) => {
  const errors = (meta.touched || meta.submitFailed) && meta.error;

  return (
    <DateInputWrapper
      {...rest}
      errors={errors}
      hasErrors={!!errors}
      input={input}
      name={input.name}
      onBlur={input.onBlur}
      onChange={input.onChange}
      onFocus={input.onFocus}
      value={input.value}
    />
  );
};

ReduxFormFieldRenderDateInput.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
};

export { ReduxFormFieldRenderDateInput };
export default DateInputWrapper;

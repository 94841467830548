import { isPathExternalV2AcceptPartnershipOrItem } from 'helpers/external';
import { isPaymentDeliveryMethodInternational } from 'helpers/paymentMethods';

/**
 * Set the payment delivery method if this is international and we're not in the update payment method flow.
 * @param {OptionsArg} options
 * @param {boolean} [options.isInternational]
 * @param {boolean} [options.isUpdatePaymentMethodFlow]
 * @param {PaymentDeliveryMethod} options.paymentDeliveryMethod
 * @returns {undefined|PaymentDeliveryMethod}
 */
export const getPaymentDeliveryMethodValueForUpdatePaymentForm = ({
  isInternational,
  isUpdatePaymentMethodFlow,
  paymentDeliveryMethod,
}) => {
  if (isInternational || !isUpdatePaymentMethodFlow) {
    return paymentDeliveryMethod;
  }

  return undefined;
};

/**
 * Get the initialValues for the update payment method form.
 * The keys leveraging isUpdatePaymentMethodFlow ternary are for unchecking the default radio buttons
 *
 * @param {FundingAccount} fundingAccount
 * @param {boolean} [isUpdatePaymentMethodFlow]
 * @param {PaymentDeliveryMethodType} paymentDeliveryMethod
 * @returns {{ui: {defaultReceivable: {isFundingAccountValid: boolean}}, defaultReceivable: {paymentDeliveryMethod, fundingAccount, acceptFuturePendingPayments: boolean}}}
 */
export const getUpdatePaymentFormInitialValues = (
  fundingAccount,
  paymentDeliveryMethod,
  isUpdatePaymentMethodFlow = false,
) => {
  const isInternational = isPaymentDeliveryMethodInternational(paymentDeliveryMethod);

  const paymentDeliveryMethodValue = getPaymentDeliveryMethodValueForUpdatePaymentForm({
    isInternational,
    isUpdatePaymentMethodFlow,
    paymentDeliveryMethod,
  });

  return {
    defaultReceivable: {
      fundingAccount: isUpdatePaymentMethodFlow ? undefined : fundingAccount,
      paymentDeliveryMethod: paymentDeliveryMethodValue,
      acceptFuturePendingPayments: !isPathExternalV2AcceptPartnershipOrItem(),
    },
    ui: {
      defaultReceivable: {
        isFundingAccountValid: false,
      },
    },
  };
};

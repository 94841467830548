/**
 * @module reducers/tablesReducer/root
 */

import { combineReducers } from 'redux';

import { PAYMENTS_LIST_TABS } from 'constants/routes';
import { tableNamesDashboard } from 'constants/tables';

import companyPaymentHistoryTableReducer from './companyPaymentHistoryTableReducer';
import configsReducer from './configsReducer';
import payablesTableReducer from './payablesTableReducer';
import receivablesTableReducer from './receivablesTableReducer';

const tablesReducer = combineReducers({
  configs: configsReducer,
  companyPaymentHistoryTable: companyPaymentHistoryTableReducer,
  [tableNamesDashboard[PAYMENTS_LIST_TABS.PAYABLES]]: payablesTableReducer,
  [tableNamesDashboard[PAYMENTS_LIST_TABS.RECEIVABLES]]: receivablesTableReducer,
});

export default tablesReducer;

/**
 * @enum {string}
 */
export const MemberAccessProps = {
  defaultGeneral: 'defaultGeneral',
  defaultItem: 'defaultItem',
  notificationGeneral: 'notificationGeneral',
  accessGeneral: 'accessGeneral',
  accessItem: 'accessItem',
};

/**
 * @enum {string}
 */
export const PartnershipMemberProps = {
  defaultGeneral: MemberAccessProps.defaultGeneral,
  defaultItem: MemberAccessProps.defaultItem,
  id: 'id',
  accessGeneral: MemberAccessProps.accessGeneral,
};

/**
 * @enum {string}
 */
export const ItemMemberProps = {
  accessItem: MemberAccessProps.accessItem,
};

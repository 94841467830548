import PropTypes from 'prop-types';
import React from 'react';

import { fullCountryNameLookup } from 'constants/i18n';

import AccountAddressLine from 'modules/fundingAccount/bankAccount/components/AccountAddress/AccountAddressLine';

const AccountAddress = (props) => {
  const { streetAddress, streetAddressUnit, city, state, postalcode, printCompany, printName, country } = props;

  let streetAddressLineText = streetAddress;
  if (streetAddressUnit) {
    streetAddressLineText += `, ${streetAddressUnit}`;
  }

  return (
    <div className="bank-account--address">
      {!!printName && (
        <React.Fragment>
          <AccountAddressLine>{printName}</AccountAddressLine>
          <br />
        </React.Fragment>
      )}

      {!!printCompany && (
        <React.Fragment>
          <AccountAddressLine>{printCompany}</AccountAddressLine>
          <br />
        </React.Fragment>
      )}

      <AccountAddressLine>{streetAddressLineText}</AccountAddressLine>
      <br />

      <AccountAddressLine>{`${city}, ${state} ${postalcode}`}</AccountAddressLine>
      <br />

      <AccountAddressLine>{fullCountryNameLookup[country]}</AccountAddressLine>
    </div>
  );
};

AccountAddress.propTypes = {
  streetAddress: PropTypes.string.isRequired,
  streetAddressUnit: PropTypes.string,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  postalcode: PropTypes.string.isRequired,
  printCompany: PropTypes.string,
  printName: PropTypes.string,
  country: PropTypes.string.isRequired,
};

AccountAddress.defaultProps = {
  printCompany: undefined,
  printName: undefined,
  streetAddressUnit: undefined,
};

export default AccountAddress;

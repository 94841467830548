import PropTypes from 'prop-types';
import React from 'react';

import './Highlight.scss';

const Highlight = ({ children, className }) => <div className={`highlight ${className}`.trim()}>{children}</div>;

Highlight.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Highlight.defaultProps = {
  className: '',
};

export default Highlight;

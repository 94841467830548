import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { CountryCodes, Currency } from 'context';

import { fetchCountriesRoutine } from 'actions/routines/countries';
import { fetchCurrenciesRoutine } from 'actions/routines/currencies';

import RoutableHead from 'components/head/RoutableHead';

import { EXTERNAL_V2, NOT_FOUND, ACTIVE_EXTERNAL_ROUTES } from 'constants/routes';

import { getJoinedPath } from 'helpers/routeHelpers';

import AllowAny from 'modules/app/AllowAny';
import SwitchOr404 from 'modules/app/SwitchOr404';
import { ExternalModals, ExternalSideSheets } from 'modules/external/global/containers/components';

import { byCountrySelector } from 'selectors/countriesSelectors';
import { byCurrencySelector } from 'selectors/currenciesSelectors';
import { notFoundErrorSelector } from 'selectors/errorsSelectors';

import ExternalRouteWrapper from './ExternalRouteWrapper';

/**
 * External Flow Routes container component.
 * @param {ComponentProps} props
 * @param {Boolean} props.notFoundError
 * @return {StatelessComponent}
 */
const ExternalContainerV2 = ({ countryCodes, currencyCodeMap, notFoundError, onFetchCountries, onFetchCurrencies }) => {
  React.useEffect(() => {
    onFetchCountries();
    onFetchCurrencies();
  }, [onFetchCountries, onFetchCurrencies]);

  if (notFoundError) {
    return <Redirect to={`/${NOT_FOUND}`} />;
  }

  return (
    <CountryCodes.Provider value={countryCodes}>
      <Currency.Provider value={currencyCodeMap}>
        <RoutableHead noIndex />

        <SwitchOr404>
          {/* /ext routes */}
          <AllowAny
            component={ExternalRouteWrapper}
            path={ACTIVE_EXTERNAL_ROUTES.map((route) => getJoinedPath(EXTERNAL_V2, route))}
          />

          {/* external subdomain routes (ex. 'external.routablehq.com/get_paid/) */}
          <AllowAny
            component={ExternalRouteWrapper}
            path={ACTIVE_EXTERNAL_ROUTES.map((route) => getJoinedPath(route))}
          />
        </SwitchOr404>

        <AllowAny component={ExternalModals} path="/" />
        <AllowAny component={ExternalSideSheets} path="/" />
      </Currency.Provider>
    </CountryCodes.Provider>
  );
};

ExternalContainerV2.propTypes = {
  countryCodes: PropTypes.shape(),
  currencyCodeMap: PropTypes.shape(),
  notFoundError: PropTypes.bool,
  onFetchCountries: PropTypes.func.isRequired,
  onFetchCurrencies: PropTypes.func.isRequired,
};

ExternalContainerV2.defaultProps = {
  countryCodes: undefined,
  currencyCodeMap: undefined,
  notFoundError: undefined,
};

const mapStateToProps = createStructuredSelector({
  countryCodes: byCountrySelector,
  currencyCodeMap: byCurrencySelector,
  notFoundError: notFoundErrorSelector,
});

export const mapDispatchToProps = {
  onFetchCountries: fetchCountriesRoutine,
  onFetchCurrencies: fetchCurrenciesRoutine,
};

export { ExternalContainerV2, mapStateToProps };
export default connect(mapStateToProps, mapDispatchToProps)(ExternalContainerV2);

import { SubmissionError } from 'redux-form';

import { SIGNUP_CONNECT_BANK } from 'constants/routes';

/**
 * Success callback function after submitting form
 */
export const getSuccessCallback = (history) => (parsedResponse) => {
  history.push(SIGNUP_CONNECT_BANK);
  return parsedResponse;
};

/**
 * Submit SelectWorkspace form
 */
export const submit = async (values, props) => {
  const { history, onSubmitSignupSelectNamespace } = props;

  const submitOptions = {
    successCallback: getSuccessCallback(history),
  };

  const submissionErrors = await onSubmitSignupSelectNamespace(values, submitOptions);

  if (submissionErrors && submissionErrors.errors) {
    throw new SubmissionError(submissionErrors.errors.fields);
  }
};

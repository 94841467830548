import { RoleTypes } from 'enums/ui';

import { isEqual } from 'helpers/utility';

import { capitalize, underscoresToSpaces } from './stringHelpers';

/**
 * Returns bool whether given role name matches admin
 * DO NOT USE these methods to gate functionality.
 * Use permissions instead:
 * https://www.notion.so/routable/Permissions-How-to-gate-a-feature-based-on-a-role-permissions-147c10aea0734ae8a2dd4147e5d05090#56be8cd5c011414db456a2cd6e62f0bb”
 * @param {string} roleName
 * @return {boolean}
 */
export const isRoleAdministrator = (roleName) => isEqual(roleName, RoleTypes.ADMINISTRATOR);

/**
 * Returns bool whether given role name matches developer
 * DO NOT USE these methods to gate functionality.
 * Use permissions instead:
 * https://www.notion.so/routable/Permissions-How-to-gate-a-feature-based-on-a-role-permissions-147c10aea0734ae8a2dd4147e5d05090#56be8cd5c011414db456a2cd6e62f0bb”
 * @param {string} roleName
 * @return {boolean}
 */
export const isRoleDeveloper = (roleName) => isEqual(roleName, RoleTypes.DEVELOPER);

/**
 * Returns bool whether given role name matches creator
 * DO NOT USE these methods to gate functionality.
 * Use permissions instead:
 * https://www.notion.so/routable/Permissions-How-to-gate-a-feature-based-on-a-role-permissions-147c10aea0734ae8a2dd4147e5d05090#56be8cd5c011414db456a2cd6e62f0bb”
 * @param {string} roleName
 * @return {boolean}
 */
export const isRoleCreator = (roleName) => isEqual(roleName, RoleTypes.CREATOR);

/**
 * Returns bool whether given role name matches it_admin
 * DO NOT USE these methods to gate functionality.
 * Use permissions instead:
 * https://www.notion.so/routable/Permissions-How-to-gate-a-feature-based-on-a-role-permissions-147c10aea0734ae8a2dd4147e5d05090#56be8cd5c011414db456a2cd6e62f0bb”
 * @param {string} roleName
 * @return {boolean}
 */
export const isRoleITAdmin = (roleName) => isEqual(roleName, RoleTypes.IT_ADMIN);

/**
 * Returns role label text, based on role
 * @param {string} roleName
 * @returns {string}
 */
export const getRoleLabelText = (roleName) => {
  if (isRoleITAdmin(roleName)) {
    return 'IT Administrator';
  }

  return capitalize(underscoresToSpaces(roleName));
};

/**
 * Returns the role object for creator
 * @param {Role[]} roles
 * @return {Role}
 */
export const getCreatorRolesFromRoles = (roles) => roles.find((role) => isRoleCreator(role.name));

/**
 * Returns the role ordinal for a given role id
 * @param {Role[]} roles
 * @param {string} roleId
 * @return {number}
 */
export const getRoleOrdinalForRoleId = (roles, roleId) => roles[roleId]?.ordinal;

/**
 * Look for some of the expectedPermissions to exist in the currentMemberPermissionSet.
 *
 * @param {OptionsArg} options
 * @param {CurrentMemberPermissionSet} options.actualMemberPermissionSet - what permissions do the current member
 * currently have?
 * @param {RequiredPermissions} options.requiredPermissions
 * @returns {boolean}
 */
export const checkMemberHasRequiredPermissions = ({ actualMemberPermissionSet, requiredPermissions }) =>
  requiredPermissions.some((permission) => actualMemberPermissionSet.has(permission));

/**
 * Function which compares the permission set against the list of required permissions, returning the correct component
 * for if permissions match or don't.
 *
 * @param {OptionsArg} options
 * @param {CurrentMemberPermissionSet} options.currentMemberPermissionSet
 * @param {RequiredPermissions} options.requiredPermissions
 * @param {AnyComponent} options.WithoutPermissionComponent
 * @param {AnyComponent} options.WithPermissionComponent
 * @returns {AnyComponent}
 */
export const restrictAccessToComponentWithRequiredPermissions = ({
  currentMemberPermissionSet,
  requiredPermissions,
  WithoutPermissionComponent,
  WithPermissionComponent,
}) => {
  const userHasExpectPermissions = checkMemberHasRequiredPermissions({
    actualMemberPermissionSet: currentMemberPermissionSet,
    requiredPermissions,
  });

  if (userHasExpectPermissions) {
    return WithPermissionComponent;
  }

  return WithoutPermissionComponent;
};

import { connect } from 'react-redux';

import { partnerFundingAccountFormFields } from 'constants/formFields';

import { addPartnerFundingAccountFormSelector } from 'selectors/forms';

import AddPartnerFundingAccountAddress from './AddPartnerFundingAccountAddress';

export const mapStateToProps = (state) => ({
  addressValue: addPartnerFundingAccountFormSelector(state, 'address'),
  shouldShowAddressPrintOnCheck: addPartnerFundingAccountFormSelector(
    state,
    partnerFundingAccountFormFields.SHOULD_SHOW_ADDRESS_PRINT_ON_CHECK,
  ),
});

export default connect(mapStateToProps)(AddPartnerFundingAccountAddress);

import _map from 'lodash/map';
import { useSelector } from 'react-redux';
import { uniqueArray } from 'helpers/utility';
import { usePartnershipPaymentCurrencies } from 'hooks';
import { useVendorReceivingCurrency } from 'modules/dashboard/vendorReceivingCurrency/hooks';
import { createItemBillCurrencySelector } from 'queries/createItemFormSelectors';
export const useGetAllAvailableCurrenciesForFundingAccounts = (partnershipId) => {
    const { data } = usePartnershipPaymentCurrencies(partnershipId);
    const { currencyCodesBillSelectable, routesPayable } = data || {};
    const options = currencyCodesBillSelectable?.reduce((currencyCodes, billCurrency) => [
        ...currencyCodes,
        ..._map(routesPayable[billCurrency.toLowerCase()], 'currencyCodeReceiver'),
    ], []) || [];
    return uniqueArray(options);
};
export const useAvailableCurrenciesForFundingAccounts = (partnership) => {
    const allAvailableCurrenciesForFundingAccounts = useGetAllAvailableCurrenciesForFundingAccounts(partnership.id);
    const itemBillCurrency = useSelector(createItemBillCurrencySelector);
    const vendorReceivingData = useVendorReceivingCurrency(() => partnership, () => itemBillCurrency);
    if (itemBillCurrency) {
        return vendorReceivingData.options.map(({ id }) => id);
    }
    return allAvailableCurrenciesForFundingAccounts;
};

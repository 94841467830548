import React, { useContext, useEffect } from 'react';
import { ExistingItem } from 'context';
import { LineItemStyles } from 'constants/lineItems';
import { useDiscrepancyContext, useRefreshDiscrepanciesContext } from 'hooks/context';
import { useGetBillDiscrepancies } from 'hooks/purchaseOrders';
import { LineItemDiscrepancyStatusPresenter } from './LineItemDiscrepancyStatusPresenter';
export const LineItemDiscrepancyStatusForItemDetails = ({ rowIdx: lineItemIndex, sectionPath, }) => {
    const item = useContext(ExistingItem);
    const lineItemStyle = sectionPath?.includes('account') ? LineItemStyles.ACCOUNT : LineItemStyles.ITEM;
    const currentSectionLineItems = (item?.lineItems || []).filter((lineItem) => lineItem.style === lineItemStyle);
    const activeLineItem = currentSectionLineItems[lineItemIndex];
    const lineItemId = activeLineItem?.id;
    const { getDiscrepancyByLineItemId, setDiscrepancies } = useDiscrepancyContext();
    const { isRefreshingDiscrepancies } = useRefreshDiscrepanciesContext();
    const { data: discrepanciesData, isFetching: isFetchingBillDiscrepancies } = useGetBillDiscrepancies({
        id: item?.id,
    });
    useEffect(() => {
        if (discrepanciesData?.discrepancies) {
            setDiscrepancies(discrepanciesData.discrepancies);
        }
    }, [discrepanciesData, setDiscrepancies]);
    const discrepancy = getDiscrepancyByLineItemId(lineItemId);
    return (React.createElement(LineItemDiscrepancyStatusPresenter, { discrepancy: discrepancy, isLoading: isFetchingBillDiscrepancies || isRefreshingDiscrepancies, purchaseOrderLineItemId: activeLineItem?.purchaseOrderLineItemId }));
};

import { createErrorReducer } from 'store/redux';

import {
  FETCH_FUNDING_ACCOUNTS_FAILURE,
  FETCH_FUNDING_ACCOUNTS_REQUEST,
  FETCH_FUNDING_ACCOUNTS_SUCCESS,
} from 'types/funding';
import {
  FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_FAILURE,
  FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_FAILURE,
} from 'types/integrations';

export const failureCases = [
  FETCH_FUNDING_ACCOUNTS_FAILURE,
  FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_FAILURE,
  FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_FAILURE,
];

export const successCases = [FETCH_FUNDING_ACCOUNTS_REQUEST, FETCH_FUNDING_ACCOUNTS_SUCCESS];

const errorReducer = createErrorReducer(successCases, failureCases);

export default errorReducer;

import * as types from 'types/connectBank';

export const initialState = {
  hasErrors: false,
  open: false,
  plaidLinkLoading: false,
  plaidLinkLoaded: false,
  plaidLinkToken: null,
};

const connectBankTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_FUNDING_ACCOUNT_LINK_TOKEN_FAILURE:
      return {
        ...state,
        hasErrors: true,
      };

    case types.MODAL_CONNECT_BANK_TOKEN_CLOSE:
      return {
        ...state,
        open: false,
      };

    case types.MODAL_CONNECT_BANK_TOKEN_OPEN:
      return {
        ...state,
        ...action.payload,
        hasErrors: false,
        open: true,
      };

    case types.MODAL_CONNECT_BANK_TOKEN_UPDATE:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default connectBankTokenReducer;

export const SUBMIT_SIGNUP_CREATE_ACCOUNT_FAILURE = 'SUBMIT_SIGNUP_CREATE_ACCOUNT_FAILURE';
export const SUBMIT_SIGNUP_CREATE_ACCOUNT_REQUEST = 'SUBMIT_SIGNUP_CREATE_ACCOUNT_REQUEST';
export const SUBMIT_SIGNUP_CREATE_ACCOUNT_SUCCESS = 'SUBMIT_SIGNUP_CREATE_ACCOUNT_SUCCESS';
export const SUBMIT_SIGNUP_CREATE_ACCOUNT_PASSWORD_ERROR = 'SUBMIT_SIGNUP_CREATE_ACCOUNT_PASSWORD_ERROR';

export const SUBMIT_SIGNUP_SELECT_NAMESPACE_FAILURE = 'SUBMIT_SIGNUP_SELECT_NAMESPACE_FAILURE';
export const SUBMIT_SIGNUP_SELECT_NAMESPACE_REQUEST = 'SUBMIT_SIGNUP_SELECT_NAMESPACE_REQUEST';
export const SUBMIT_SIGNUP_SELECT_NAMESPACE_SUCCESS = 'SUBMIT_SIGNUP_SELECT_NAMESPACE_SUCCESS';

export const SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_FAILURE = 'SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_FAILURE';
export const SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_REQUEST = 'SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_REQUEST';
export const SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_SUCCESS = 'SUBMIT_SIGNUP_RESEND_VERIFICATION_EMAIL_SUCCESS';

export const SUBMIT_SIGNUP_VERIFY_EMAIL_FAILURE = 'SUBMIT_SIGNUP_VERIFY_EMAIL_FAILURE';
export const SUBMIT_SIGNUP_VERIFY_EMAIL_REQUEST = 'SUBMIT_SIGNUP_VERIFY_EMAIL_REQUEST';
export const SUBMIT_SIGNUP_VERIFY_EMAIL_SUCCESS = 'SUBMIT_SIGNUP_VERIFY_EMAIL_SUCCESS';

export const SIGNUP_CHANGE_VIEW = 'SIGNUP_CHANGE_VIEW';
export const CHANGE_REPRESENTATIVE_LOCK = 'CHANGE_REPRESENTATIVE_LOCK';

export const SAVE_STATUS_SAVED = 'save_status_saved';
export const SAVE_STATUS_SAVING = 'save_status_saving';
export const SAVE_STATUS_NOT_SAVED = 'save_status_not_saved';

export const INVITE_TEAM_MEMBER_MODAL = 'invite_team_member_modal';
export const VIEW_TEAM_MEMBERS_MODAL = 'view_team_members_modal';
export const DEACTIVATE_TEAM_MEMBER_MODAL = 'deactivate_team_member_modal';
export const LOGOUT_MODAL = 'logout_modal';

export const OPEN_SUBMIT_SIGN_UP_FLOW_MODAL = 'open_submit_sign_up_flow_modal';
export const CLOSE_SUBMIT_SIGN_UP_FLOW_MODAL = 'close_submit_sign_up_flow_modal';

export const TOGGLE_SKIP_STEP_MODAL = 'TOGGLE_SKIP_STEP_MODAL';

export const SET_NEXT_VIEW = 'SET_NEXT_VIEW';

import _debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
const setIsTriggeredOnSizeChange = (comparisonType, entries, setIsTriggered, size) => {
    const { target } = entries[0];
    if (target.clientWidth === 0) {
        return;
    }
    if (comparisonType === '<') {
        setIsTriggered(target.clientWidth < size);
    }
    else {
        setIsTriggered(target.clientWidth > size);
    }
};
export const useResizeWidthObserver = ({ ref, size, comparisonType = '<' }) => {
    const [isTriggered, setIsTriggered] = useState(false);
    useEffect(() => {
        const element = ref.current;
        const debouncedSetIsTriggeredOnSizeChange = _debounce((entries) => setIsTriggeredOnSizeChange(comparisonType, entries, setIsTriggered, size), 600);
        const observer = new ResizeObserver(debouncedSetIsTriggeredOnSizeChange);
        if (element) {
            observer.observe(element);
        }
        return () => {
            if (element) {
                observer.unobserve(element);
            }
            observer.disconnect();
        };
    }, []);
    return isTriggered;
};

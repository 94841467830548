import throttle from 'lodash/throttle';
const INPUT_IDLE_TIMEOUT_MS = 30e3;
const THROTTLE_MS = 50;
export class UserActivityTracker {
    INPUT_ACTIVITY_EVENTS = ['mousemove', 'mousedown', 'resize', 'keydown', 'touchstart', 'wheel'];
    events = {};
    onIdleChange;
    onInternalIdleChange;
    inactiveTimeRanges = [];
    isIdle = false;
    setTimeoutHandle;
    globalScope;
    constructor({ globalScope, onIdleChange, } = {}) {
        this.globalScope = globalScope;
        this.onIdleChange = onIdleChange;
        this.onInternalIdleChange = throttle((isIdle) => {
            if (isIdle) {
                this.inactiveTimeRanges = [...this.inactiveTimeRanges, { start: performance.now(), end: null, delta: null }];
            }
            else {
                const lastUpdatedRange = this.inactiveTimeRanges.slice(-1)?.[0];
                const now = performance.now();
                if (!lastUpdatedRange) {
                    return;
                }
                this.inactiveTimeRanges = [
                    ...this.inactiveTimeRanges.slice(0, -1),
                    {
                        start: lastUpdatedRange.start,
                        end: now,
                        delta: Math.abs(now - lastUpdatedRange.start),
                    },
                ];
            }
            if (typeof this.onIdleChange === 'function') {
                this.onIdleChange(isIdle);
            }
        }, THROTTLE_MS);
        const globalScopeDocument = globalScope?.document;
        if (typeof globalScopeDocument !== 'undefined') {
            globalScopeDocument.addEventListener('visibilitychange', this.onDocumentVisibilityChange.bind(this), {
                capture: true,
                passive: true,
            });
            if (globalScopeDocument.hidden) {
                this.onDocumentVisibilityChange();
            }
        }
        if (typeof globalScope !== 'undefined') {
            for (const eventName of this.INPUT_ACTIVITY_EVENTS) {
                globalScope.addEventListener(eventName, this.onInputActivityEvent.bind(this), { capture: true, passive: true });
            }
            this.scheduleIdleCheck();
        }
    }
    scheduleIdleCheck() {
        this.setTimeoutHandle = setTimeout(() => {
            if (!this.isIdle) {
                this.isIdle = true;
                this.onInternalIdleChange(this.isIdle);
            }
        }, INPUT_IDLE_TIMEOUT_MS);
    }
    onInputActivityEvent() {
        if (this.isIdle) {
            this.isIdle = false;
            this.onInternalIdleChange(this.isIdle);
        }
        clearTimeout(this.setTimeoutHandle);
        this.scheduleIdleCheck();
    }
    onDocumentVisibilityChange() {
        if (!this.globalScope?.document?.hidden) {
            this.onInputActivityEvent();
        }
        else if (!this.isIdle) {
            this.isIdle = true;
            this.onInternalIdleChange(this.isIdle);
        }
    }
    getInactiveTimeMsBetweenTimestamps(timestampA, timestampB) {
        let inactiveTimeDelta = 0;
        for (const range of this.inactiveTimeRanges) {
            if (range.start >= timestampA && range.end <= timestampB) {
                inactiveTimeDelta += range.end - range.start;
            }
            else if (range.end > timestampA && range.start <= timestampA) {
                inactiveTimeDelta += range.end - timestampA;
            }
            else if (range.start <= timestampB && range.end > timestampB) {
                inactiveTimeDelta += timestampB - range.start;
            }
        }
        return inactiveTimeDelta;
    }
    getElapsedTimeInfo(timestampA, timestampB) {
        const elapsedClockTime = Math.abs(timestampA - timestampB);
        const elapsedInactiveTime = this.getInactiveTimeMsBetweenTimestamps(timestampA, timestampB);
        return {
            timestampA,
            timestampB,
            elapsedClockTime,
            elapsedActiveTime: elapsedClockTime - elapsedInactiveTime,
        };
    }
    trackEvent({ namespace, eventName }) {
        this.events = {
            ...this.events,
            [namespace]: [
                ...(this.events[namespace] || []),
                {
                    namespace,
                    eventName,
                    timestamp: performance.now(),
                },
            ],
        };
    }
    deleteEvents(namespace, eventName) {
        if (!namespace) {
            this.events = {};
        }
        if (namespace && !eventName) {
            delete this.events[namespace];
        }
        if (namespace && eventName) {
            const events = this.events[namespace];
            if (!events) {
                return;
            }
            this.events[namespace] = events.filter((x) => x.eventName !== eventName);
        }
    }
    getMostRecentEventByName(namespace, eventName) {
        const matchingEvents = this.events[namespace]?.filter((eventsLog) => eventsLog.eventName === eventName) || [];
        return matchingEvents.slice(-1)[0];
    }
    getEventInfo(eventPredicateFn) {
        if (!this.events || typeof eventPredicateFn !== 'function') {
            return null;
        }
        const event = eventPredicateFn({
            events: this.events,
            getMostRecentEventByName: this.getMostRecentEventByName.bind(this),
        });
        if (!event) {
            return null;
        }
        const elapsedTimeInfo = this.getElapsedTimeInfo(event.timestamp, performance.now());
        return {
            namespace: event.namespace,
            eventName: event.eventName,
            timestamp: event.timestamp,
            elapsedClockTime: elapsedTimeInfo.elapsedClockTime,
            elapsedActiveTime: elapsedTimeInfo.elapsedActiveTime,
        };
    }
}
let userActivityTracker;
export const withUserActivityTracker = (globalScope = window, onIdleChange) => {
    if (typeof userActivityTracker === 'undefined') {
        userActivityTracker = new UserActivityTracker({
            globalScope,
            onIdleChange,
        });
    }
    return userActivityTracker;
};

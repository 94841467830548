import { createSelector } from 'reselect';
import {} from 'interfaces/redux';
import {} from 'interfaces/redux/partnershipPaymentReducer.types';
import { idSelector } from 'selectors/globalSelectors';
const getState = (state) => state.partnershipPayment;
export const partnershipPaymentByIdSelector = createSelector([getState], ({ byId }) => byId);
const mapToPartnershipPayment = (partnershipPaymentById, id) => ({
    id,
    type: 'PartnershipPayment',
    ...partnershipPaymentById[id].attributes,
});
export const partnershipPaymentCurrentPartnershipSelector = createSelector([partnershipPaymentByIdSelector, idSelector], (partnershipPaymentById, id) => partnershipPaymentById[id] ? mapToPartnershipPayment(partnershipPaymentById, id) : null);

import styled from 'styled-components';
import { containerWidthMap } from 'modules/signup-v3/constants/container';
export const InvitedTeamMembersContainer = styled.div `
  align-items: center;
  border: 1px solid var(--blue--light);
  border-radius: 0.5rem;
  display: flex;
  gap: 1.25rem;
  justify-content: space-between;
  margin-top: 2.25rem;
  padding: 1.5rem;
  width: fit-content;
  flex-direction: column;
  width: 100%;
  max-width: ${containerWidthMap.large};
`;
export const InvitedTeamMembersHeadingContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const ButtonContainer = styled.div `
  display: flex;
  gap: 0.75rem;
  width: 100%;
  justify-content: flex-start;
`;
export const Title = styled.p `
  color: var(--grey--xx-dark);
  font-weight: 500;
`;
export const Description = styled.p `
  color: var(--grey);
  font-weight: 400;
  margin-bottom: 0px;
`;

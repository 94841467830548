import { isPaymentDeliveryMethodAch } from 'helpers/paymentMethods';

export const doesInvalidFundingAccountExist = (props) => {
  const { formUI, fundingAccount, paymentDeliveryMethod } = props;

  return (
    isPaymentDeliveryMethodAch(paymentDeliveryMethod) &&
    // fundingAccount is actually a string id, and empty by default
    fundingAccount.length > 0 &&
    !formUI.isFundingAccountValid
  );
};

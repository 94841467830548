import { firstKey } from 'helpers/utility';

import { SUBMIT_VERIFY_FUNDING_ACCOUNT_SUCCESS } from 'types/funding';

const lastVerifiedFundingSourceReducer = (state = null, action) => {
  switch (action.type) {
    case SUBMIT_VERIFY_FUNDING_ACCOUNT_SUCCESS:
      return firstKey(action.payload.fundingSource);

    default:
      return state;
  }
};

export default lastVerifiedFundingSourceReducer;

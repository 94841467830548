import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { fundingSourcesFromFundingAccountPropSelector } from 'queries/fundingCompoundSelectors';
import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

import { fundingInfoBankAccountForFundingAccountPropSelector } from 'selectors/fundingSelectors';

import AccountHintSection from './AccountHintSection';

const mapStateToProps = createStructuredSelector({
  fundingInfoBankAccount: fundingInfoBankAccountForFundingAccountPropSelector,
  fundingSources: fundingSourcesFromFundingAccountPropSelector,
  partnership: partnershipFromQuerySelector,
});

export default connect(mapStateToProps)(AccountHintSection);

import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { submitPartnerCompanyGeneralInfoHandler } from 'actions/routines/external';

import { formNamesExternal } from 'constants/forms';

import { shouldRunErrorValidation } from 'helpers/forms';

import { makeFormSubmitFailHandler } from 'modules/createPartnerCompany/helpers/submitFailure';

import { partnerCompanyGeneralFormInitialValuesSelector } from 'queries/acceptPartnershipCompoundSelectors';
import { partnerCompanyNameFromPartnershipQuerySelector } from 'queries/companyCompoundSelectors';
import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

import { displayInputForTinFormUISelector } from 'selectors/forms';
import { partnershipRequestCollectVendorTaxInfoForCurrentUser } from 'selectors/partnershipRequestSelectors';

import PartnershipCompanyGeneralForm from './PartnershipCompanyGeneralForm';

const formName = formNamesExternal.PARTNER_COMPANY_GENERAL;

export const mapStateToProps = createStructuredSelector({
  collectVendorTaxInfo: partnershipRequestCollectVendorTaxInfoForCurrentUser,
  displayInputForTin: displayInputForTinFormUISelector,
  initialValues: partnerCompanyGeneralFormInitialValuesSelector,
  partnerCompanyName: partnerCompanyNameFromPartnershipQuerySelector,
  partnership: partnershipFromQuerySelector,
});

const createPartnershipCompanyGeneralForm = reduxForm({
  form: formName,
  onSubmit: submitPartnerCompanyGeneralInfoHandler,
  onSubmitFail: makeFormSubmitFailHandler(formName),
  // [DEV-1757] If an address is added to the company step,
  // switching to individual enables the continue button without a SSN
  //
  // This enforces error validation when fields like 'company.tin' dynamically appear
  shouldError: shouldRunErrorValidation,
})(PartnershipCompanyGeneralForm);

export default connect(mapStateToProps)(createPartnershipCompanyGeneralForm);

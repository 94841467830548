import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';

import { signupFlowForm } from 'modules/signup-v3/constants';

export const getState = (state) => state?.signup;

export const getCreateAccount = createSelector([getState], (signup) => signup.createAccount);

export const getResendVerificationEmail = createSelector([getState], (signup) => signup.resendVerificationEmail);

export const getSelectNamespace = createSelector([getState], (signup) => signup.selectNamespace);

export const getVerifyEmail = createSelector([getState], (signup) => signup.verifyEmail);

/**
 * Selects the create account is submitting
 * @param {object} state - Redux state
 */
export const isSubmittingSignupCreateAccountSelector = createSelector(
  [getCreateAccount],
  (createAccount) => createAccount.isSubmitting,
);

/**
 * Selects the create account errors
 * @param {object} state - Redux state
 */
export const signupCreateAccountErrorsSelector = createSelector(
  [getCreateAccount],
  (createAccount) => createAccount.errors,
);

/**
 * Selects the password errors obtained after form submission
 * @param {object} state - Redux state
 */
export const signupCreateAccountPasswordErrorSelector = createSelector(
  [getCreateAccount],
  (createAccount) => createAccount.passwordError,
);

/**
 * Selects isSubmitting for verify email
 * @param {object} state - Redux state
 */
export const isSubmittingSignupVerifyEmailSelector = createSelector(
  [getVerifyEmail],
  (verifyEmail) => verifyEmail.isSubmitting,
);

/**
 * Selects the verify email errors
 * @param {object} state - Redux state
 */
export const signupVerifyEmailErrorsSelector = createSelector([getVerifyEmail], (verifyEmail) => verifyEmail.errors);

/**
 * Selects the resend verify email is last submitted
 * @param {object} state - Redux state
 */
export const lastSubmittedSignupResendVerificationEmailSelector = createSelector(
  [getResendVerificationEmail],
  (resendVerificationEmail) => resendVerificationEmail.lastSubmitted,
);

/**
 * Selects isSubmitting for select namespace
 * @param {object} state - Redux state
 */
export const isSubmittingSignupSelectNamespaceSelector = createSelector(
  [getSelectNamespace],
  (selectNamespace) => selectNamespace.isSubmitting,
);

/**
 * Selects isFetchingSignup for select namespace
 * @param {object} state - Redux state
 */
export const isFetchingNamespaceSelector = createSelector([getSelectNamespace], (selectNamespace) =>
  Boolean(selectNamespace.isFetchingNamespace),
);

/**
 * Selects isNamespaceValid for select namespace
 * @param {object} state - Redux state
 */
export const isNamespaceValidSelector = createSelector(
  [getSelectNamespace],
  (selectNamespace) => selectNamespace.isNamespaceValid,
);

/**
 * Selects for upload files view in signup v3
 * @param {object} state - Redux state
 */
export const getUploadedFiles = createSelector([getState], (signup) => signup.uploadFile.files);

/**
 * Selects active view for signup v3
 * @param {object} state - Redux state
 */
export const activeViewSelector = createSelector([getState], (state) => state?.navigation?.activeView);

export const isRepresentativeLockedSelector = createSelector(
  [getState],
  (state) => state?.navigation?.representativeIsLocked,
);

export const signUpFlowFormSelector = formValueSelector(signupFlowForm);

/**
 * Selects isSubmitting for select namespace
 * @param {object} state - Redux state
 */
export const getSaveStatus = createSelector([getState], (state) => state?.save?.status);

export const getInviteTeamMemberModal = createSelector([getState], (signup) => signup.modal?.inviteTeamMember);

export const getViewTeamMembersModal = createSelector([getState], (signup) => signup.modal?.viewTeamMembers);

export const getDeactivateTeamMemberModal = createSelector([getState], (signup) => signup.modal?.deactivateTeamMember);

export const getLogoutModal = createSelector([getState], (signup) => signup.modal?.logout);

export const submitForVerificationConfirmationOpen = createSelector(
  [getState],
  (signup) => signup.modal?.submitSignUpFlowModal,
);

export const getSkipStepModal = createSelector([getState], (signup) => signup.modal?.skipStep);

export const getNextView = createSelector([getState], (signup) => signup?.modal?.nextView);

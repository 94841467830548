import { fetchItemsRoutine, sendSubmitBillToItemRequestRoutine, submitItemRoutine } from 'actions/routines/item';

import { allKeys } from 'helpers/utility';

import { SUBMIT_UPLOAD_BILL_SUCCESS } from 'types/bills';
import { FETCH_PARTNERSHIP_ITEMS_SUCCESS } from 'types/partnership';
import { FETCH_THREADS_SUCCESS, FETCH_THREAD_SUCCESS } from 'types/thread';

const allThreadsReducer = (state = [], action) => {
  switch (action.type) {
    case fetchItemsRoutine.SUCCESS:
    case FETCH_PARTNERSHIP_ITEMS_SUCCESS:
    case FETCH_THREADS_SUCCESS:
    case FETCH_THREAD_SUCCESS:
    case SUBMIT_UPLOAD_BILL_SUCCESS:
    case sendSubmitBillToItemRequestRoutine.SUCCESS:
    case submitItemRoutine.FULFILL:
      return Array.from(new Set([...state, ...allKeys(action.payload.thread)]));

    default:
      return state;
  }
};

export default allThreadsReducer;

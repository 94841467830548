import React from 'react';
import LinkNewPage from 'components/link/LinkNewPage';
import { Text, WhiteSpace } from 'components/text';
import { TagText, TagType } from 'constants/ui';
import helpDocs from 'helpers/helpDocs';
const DashboardToDashboardRestrictionTooltipContent = () => (React.createElement(Text.Regular, { "data-testid": "r2r-tooltip-content" },
    `A ${TagText[TagType.SELF_MANAGED].toLowerCase()} company means that they are also a Routable user,
    and because of this, you are not able to require payable approval, add contacts automatically, edit
    contacts, or dictate who on their side receives/interacts with your notifications. Learn more about`,
    React.createElement(WhiteSpace, null),
    React.createElement(LinkNewPage, { className: "primary", href: helpDocs.SELF_MANAGED_COMPANIES }, `${TagText[TagType.SELF_MANAGED].toLowerCase()} companies`),
    "."));
export default DashboardToDashboardRestrictionTooltipContent;

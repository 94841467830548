import React from 'react';

import { ExternalHintStatus } from 'constants/external';

import StepHintBase from 'modules/external/externalStepHint/StepHintBase';

/**
 * Component rendering External Step Hint with hint status of CANCELLED
 * @see StepHintBase
 *
 * @return {StatelessComponent}
 */
const CancelledStepHint = () => <StepHintBase hintStatus={ExternalHintStatus.CANCELLED} />;

export default CancelledStepHint;

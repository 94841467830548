import { connect } from 'react-redux';

import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

import { fundingInfoAddressForAccountAddressSelector } from 'selectors/fundingSelectors';

import AddressFundingAccount from './AddressFundingAccount';

const mapStateToProps = (state, ownProps) => ({
  fundingInfoAddress: fundingInfoAddressForAccountAddressSelector(state, ownProps.fundingAccount),
  partnership: partnershipFromQuerySelector(state, ownProps),
});

export default connect(mapStateToProps)(AddressFundingAccount);

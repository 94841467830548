import ThunkFetch from 'helpers/thunkFetch';

import { getVerifyFundingAccountBankManualEndpoint } from 'services/api/fundingEndpoints';

import * as types from 'types/funding';

const submitVerifyFundingAccount = (payload, options = {}) => {
  const { fundingAccountId, fundingSourceId } = options;

  const endpoint = getVerifyFundingAccountBankManualEndpoint(fundingAccountId, fundingSourceId);

  const t = new ThunkFetch({
    actions: {
      error: types.SUBMIT_VERIFY_FUNDING_ACCOUNT_FAILURE,
      request: types.SUBMIT_VERIFY_FUNDING_ACCOUNT_REQUEST,
      success: types.SUBMIT_VERIFY_FUNDING_ACCOUNT_SUCCESS,
    },
    endpoint,
    method: 'POST',
    payload,
    requireAuth: true,
  });

  return t.makeRequest();
};

export default submitVerifyFundingAccount;

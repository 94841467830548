import PropTypes from 'prop-types';
import React from 'react';

import { Hint, Text } from 'components';

import { typography } from 'constants/styles';
import { Intent } from 'constants/ui';

import './MicroDepositsNeedVerificationHint.scss';

/**
 * Renders base wrapper hint for displaying information about micro deposits needing
 * verification.
 * @param {ComponentProps} props
 * @param {Node} props.children
 * @return {StatelessComponent}
 */
const MicroDepositsNeedVerificationHint = ({ children }) => (
  <Hint className="micro-deposits-need-verification-hint" intent={Intent.INFO}>
    <Text.Semibold color={typography.TextColor.YELLOW_MEDIUM} size={typography.TextSize.LEVEL_200}>
      This payment method can’t be used until micro-deposits are verified
    </Text.Semibold>
    <Text.Semibold color={typography.TextColor.BLACK} size={typography.TextSize.LEVEL_200}>
      {children}
    </Text.Semibold>
  </Hint>
);

MicroDepositsNeedVerificationHint.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MicroDepositsNeedVerificationHint;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const CheckListInnerList = ({ children, className }) => (
  <ul
    className={classNames({
      'check-list--list': true,
      [className]: !!className,
    })}
  >
    {children}
  </ul>
);

CheckListInnerList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

CheckListInnerList.defaultProps = {
  children: undefined,
  className: undefined,
};

export default CheckListInnerList;

import React from 'react';
import { ModalFooter, ModalFooterButtonCancel } from 'components';
import { colors } from 'constants/styles';
import {} from './ViewTeamMembersFooter.types';
const ViewTeamMembersFooter = ({ handleClose }) => (React.createElement(ModalFooter, { style: {
        backgroundColor: colors.colorWhiteHex,
    } },
    React.createElement("div", { className: "content--left" }),
    React.createElement("div", { className: "content--right" },
        React.createElement(ModalFooterButtonCancel, { buttonText: "Close", onCloseModal: handleClose }))));
export default ViewTeamMembersFooter;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const FlexCol = ({ children, className, style }) => (
  <div
    className={classNames({
      'flex-col': true,
      [className]: !!className,
    })}
    style={style}
  >
    {children}
  </div>
);

FlexCol.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

FlexCol.defaultProps = {
  children: undefined,
  className: undefined,
  style: undefined,
};

export default FlexCol;

import { z } from 'zod';
export const dateOperatorModel = z.enum(['BETWEEN', 'GTE', 'LTE', 'EQ']);
export const dateFilterModel = z.discriminatedUnion('operator', [
    z.object({
        operator: z.literal(dateOperatorModel.Enum.BETWEEN),
        value: z.tuple([z.date(), z.date()]),
    }),
    z.object({
        operator: z.literal(dateOperatorModel.Enum.GTE),
        value: z.date().optional(),
    }),
    z.object({
        operator: z.literal(dateOperatorModel.Enum.LTE),
        value: z.date().optional(),
    }),
    z.object({
        operator: z.literal(dateOperatorModel.Enum.EQ),
        value: z.date().optional(),
    }),
]);

import { StatusIndicator } from '@routable/gross-ds';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getItemStatusProps } from 'helpers/status';

import { currentCompanySelector } from 'selectors/currentCompanySelectors';
import { currentUserSelector } from 'selectors/currentUserSelectors';
import { fundingAccountForIdSelector } from 'selectors/fundingSelectors';
import { itemAttachmentsSelector, itemSelector } from 'selectors/itemsSelectors';

import ExternalReceiptAttachmentPreview from './ExternalReceiptAttachmentPreview';
import ExternalReceiptHeader from './ExternalReceiptHeader';
import ExternalReceiptItem from './ExternalReceiptItem';
import ExternalReceiptPartnerCompany from './ExternalReceiptPartnerCompany';
import ExternalReceiptPaymentMethod from './ExternalReceiptPaymentMethod';

const ExternalReceiptSingleItem = (props) => {
  const { attachments, currentCompany, fundingAccount, item, location, member } = props;
  const { statusVariant, statusText } = getItemStatusProps(item);

  return (
    <div>
      <div className="panel external-receipt">
        <ExternalReceiptHeader item={item} />

        <ExternalReceiptPartnerCompany currentCompany={currentCompany} item={item} member={member} />

        <ExternalReceiptItem item={item} />

        {item.paymentDeliveryMethod && (
          <ExternalReceiptPaymentMethod fundingAccount={fundingAccount} item={item} location={location} />
        )}

        <StatusIndicator status={statusVariant}>{statusText}</StatusIndicator>
      </div>

      <ExternalReceiptAttachmentPreview attachments={attachments} />
    </div>
  );
};

ExternalReceiptSingleItem.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape),
  currentCompany: PropTypes.shape().isRequired,
  fundingAccount: PropTypes.shape(),
  item: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  member: PropTypes.shape().isRequired,
};

ExternalReceiptSingleItem.defaultProps = {
  attachments: [],
  fundingAccount: undefined,
};

const mapStateToProps = (state, ownProps) => {
  let selectedItem;
  const retProps = {
    currentCompany: currentCompanySelector(state),
  };

  if (ownProps.itemId) {
    selectedItem = itemSelector(state, ownProps.itemId);
  }

  if (selectedItem) {
    retProps.fundingAccount = fundingAccountForIdSelector(state, selectedItem.fundingAccount);
    retProps.item = selectedItem;
    retProps.member = currentUserSelector(state); // TODO [FRON-1520]: use item members correctly when doing PCM
  }

  if (selectedItem.hasAttachments) {
    retProps.attachments = itemAttachmentsSelector(state, ownProps.itemId);
  }

  return retProps;
};

export default connect(mapStateToProps)(ExternalReceiptSingleItem);

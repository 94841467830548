import { reduceKeys } from 'helpers/utility';

import { FETCH_BILLING_CODES_SUCCESS } from 'types/billing';

const getBillingCodesByCode = (billingCodes) => {
  if (!billingCodes) {
    return {};
  }

  return reduceKeys(
    billingCodes,
    (obj, billingCodeId) => {
      const { attributes } = billingCodes[billingCodeId];

      return {
        ...obj,
        [attributes.code]: {
          ...attributes,
          id: billingCodeId,
        },
      };
    },
    {},
  );
};

const byCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_BILLING_CODES_SUCCESS:
      return getBillingCodesByCode(action.payload.billingCode);

    default:
      return state;
  }
};

export default byCodeReducer;

import { combineReducers } from 'redux';

import allIds from './allIdsReducer';
import byId from './byIdReducer';
import isFetching from './isFetchingReducer';

const transactionChecksReducer = combineReducers({
  allIds,
  byId,
  isFetching,
});

export default transactionChecksReducer;

import PropTypes from 'prop-types';
import React from 'react';

const ExternalReceiptRow = ({ description, text }) => (
  <div className="item external-receipt">
    <div className="left">
      <span className="font-regular font-color--dark-jordan">{description}</span>
    </div>

    <div className="right line-height--standard">
      <span className="font-regular semibold font-color--greyXDark word-break--break-all">{text}</span>
    </div>
  </div>
);

ExternalReceiptRow.propTypes = {
  description: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ExternalReceiptRow;

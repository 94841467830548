import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { openQuickswitchModal, closeQuickswitchModal, executeQuickswitchSwitchMembership, fetchQuickswitchMembershipList, fetchQuickswitchMembershipSearch, setSearchTerm, } from 'actions/quickswitchActions';
import { currentCompanyNameSelector } from 'selectors/currentCompanySelectors';
import { loadingMembershipsListSelector, membershipsSelector, isModalOpenSelector, isErrorModalOpenSelector, searchTermSelector, totalNumberOfMembershipsSelector, } from 'selectors/quickswitchSelectors';
import Quickswitch, {} from './Quickswitch';
import {} from './QuickswitchModal';
export const mapStateToProps = createStructuredSelector({
    currentCompanyName: currentCompanyNameSelector,
    loadingMembershipsList: loadingMembershipsListSelector,
    memberships: membershipsSelector,
    totalNumberOfMemberships: totalNumberOfMembershipsSelector,
    isModalOpen: isModalOpenSelector,
    isErrorModalOpen: isErrorModalOpenSelector,
    searchTerm: searchTermSelector,
});
export const mapDispatchToProps = {
    openModal: openQuickswitchModal,
    closeModal: closeQuickswitchModal,
    executeSwitchMembership: executeQuickswitchSwitchMembership,
    fetchMembershipList: fetchQuickswitchMembershipList,
    fetchMembershipSearch: fetchQuickswitchMembershipSearch,
    setSearchTerm,
};
export default connect(mapStateToProps, mapDispatchToProps)(Quickswitch);

import { fetchSupportedCountriesRoutine } from 'actions/routines/funding';
import {} from 'interfaces/actions';
import { initialState } from './initialState';
const dashboardLoadedRolesReducer = (state = initialState.supportedCountries, action) => {
    switch (action.type) {
        case fetchSupportedCountriesRoutine.SUCCESS:
            return true;
        default:
            return state;
    }
};
export default dashboardLoadedRolesReducer;

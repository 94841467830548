import React from 'react';
import ReactTooltip from 'react-tooltip';

import { ZIndexLayers } from 'constants/ui';

import { getZIndex } from 'helpers/zIndex';

import './Sandbox.scss';

const Sandbox = () => {
  if (process.env.REACT_APP_SANDBOX !== '1') {
    return null;
  }

  return (
    <div
      className="sandbox"
      data-for="Sandbox"
      data-tip="In Sandbox mode your actions won't affect normal operations"
      style={{ zIndex: getZIndex(ZIndexLayers.ALERT) }}
    >
      <span className="icon-ic-settings font-m font-color--white margin-right--sm" />
      <span className="font-regular bold font-color--white">Sandbox mode</span>

      <ReactTooltip className="tool-tip" delayHide={100} effect="solid" id="Sandbox" place="top" />
    </div>
  );
};

export default Sandbox;

import React from 'react';
const InputLabel = ({ hideLabel, isRequired, label, name, placeholder, value, }) => {
    if (hideLabel || (!label && !value)) {
        return null;
    }
    const labelValue = label || placeholder;
    return (React.createElement("label", { htmlFor: name },
        labelValue,
        isRequired && React.createElement("span", null, ' *')));
};
export default InputLabel;

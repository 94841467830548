import { LedgerSides } from 'constants/ledger';
import { FundingAccount } from 'constants/relationships';
import { REST } from 'helpers/api';
import {} from 'interfaces/fetchService';
import { FetchService } from 'services';
import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { addFundingAccountInternationalBankManualEndpoint, addInternationalBankAccountEndpoint, fundingAccountsEndpoint, fundingSupportedCountriesEndpoint, getFundingAccountEndpoint, } from 'services/api/fundingEndpoints';
import { getIntegrationLedgerMatchingEndpoint } from 'services/api/integrationEndpoints';
import {} from './funding.types';
export const createInternationalFundingAccount = async (submitPayload, options = {}) => REST.post({
    endpoint: addFundingAccountInternationalBankManualEndpoint,
    requireAuth: true,
    setRequester: true,
    payload: payloadToJSONAPI(submitPayload, FundingAccount),
    ...options,
});
export const createInternationalBankAccount = async (submitPayload, options = {}) => REST.post({
    endpoint: addInternationalBankAccountEndpoint(submitPayload.meta.partnershipId),
    requireAuth: true,
    setRequester: true,
    payload: payloadToJSONAPI(submitPayload, FundingAccount, 'fundingInfoAddress'),
    ...options,
});
export const fetchSingleFundingAccount = async (accountId, options = {}) => {
    const config = {
        ...options,
        endpoint: getFundingAccountEndpoint(accountId),
        method: 'GET',
        requireAuth: true,
    };
    return FetchService.request(config);
};
export const fetchFundingAccounts = async (options = {}) => {
    const config = {
        ...options,
        endpoint: fundingAccountsEndpoint,
        method: 'GET',
        requireAuth: true,
        pageSize: 100,
    };
    return FetchService.request(config);
};
export const fetchFundingAccountsForClearingAccountsMatching = async (integrationConfigId, filter, options = {}) => {
    const endpoint = getIntegrationLedgerMatchingEndpoint(integrationConfigId, 'clearing_accounts', {
        ...filter,
        side: LedgerSides.PLATFORM,
    });
    const config = {
        ...options,
        endpoint,
        method: 'GET',
        requireAuth: true,
    };
    return FetchService.request(config);
};
export const fetchUnmatchedLedgerClearingAccounts = async (integrationConfigId, filter, options = {}) => {
    const endpoint = getIntegrationLedgerMatchingEndpoint(integrationConfigId, 'clearing_accounts', {
        ...filter,
        side: LedgerSides.LEDGER,
    });
    const config = {
        ...options,
        endpoint,
        method: 'GET',
        requireAuth: true,
    };
    return FetchService.request(config);
};
export const fetchSupportedCountries = async (options = {}) => REST.get({
    endpoint: fundingSupportedCountriesEndpoint,
    requireAuth: true,
    ...options,
});

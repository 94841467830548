import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { change, Field, getFormValues } from 'redux-form';

import { Radio, SelectFieldV2 } from 'components';
import CannotUpdateFieldHint from 'components/hintTypes/CannotUpdateFieldHint';

import { RepresentativeIDTypes } from 'constants/document';
import { countriesAllList } from 'constants/i18n';
import { field } from 'constants/styles/formStyles';
import { Intent } from 'constants/ui';

import { digitsOnly } from 'helpers/fieldNormalizers';
import {
  maxCharacterLengthValidator,
  minCharacterLengthValidator,
  TINValidator,
  requiredValidator,
} from 'helpers/fieldValidation';
import { formatAsSSN } from 'helpers/numbers';

import { isSSNRequired } from 'modules/dashboard/settings/user/representative/helpers/logic';
import Accordion from 'modules/signup-v3/components/Accordion';
import { howRoutableUseThisInformation } from 'modules/signup-v3/constants/accordion';
import { containerWidthMap } from 'modules/signup-v3/constants/container';
import { fields } from 'modules/signup-v3/fields';

import {
  currentMembershipVerificationUpdateAllowedSelector,
  isUpdatingMembershipSelector,
} from 'selectors/membershipsSelector';

import { MaskedField } from '../components/MaskedField';
import FlowStepperMain from '../FlowStepperMain';
import { Container } from '../FlowStepperMain.styles';
import { viewSteps } from '../helpers/viewSteps';

import {
  PassportHint,
  FieldLabel,
  HelperWrapper,
  Fields,
  FieldWrapper,
  RadioFieldWrapper,
} from './RepresentativeIDNumber.styles';

const maxValidator = maxCharacterLengthValidator(9);
const minValidator = minCharacterLengthValidator(4);

const RepresentativeIDNumber = ({ activeView, form, invalid }) => {
  const ssnValidator = React.useMemo(() => TINValidator('SSN'), []);
  const formValueSelector = getFormValues(form);
  const {
    representativeDocumentType,
    personalInfo,
    ui: { hasPassportNumber, hasSsn },
  } = useSelector(formValueSelector);
  const updatedAllowed = useSelector(currentMembershipVerificationUpdateAllowedSelector);
  const isUpdating = useSelector(isUpdatingMembershipSelector);
  const ssnRequired = isSSNRequired(personalInfo);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (representativeDocumentType === RepresentativeIDTypes.PASSPORT_NUMBER) {
      dispatch(change(form, fields.ssn, null));
    }
    if (representativeDocumentType === RepresentativeIDTypes.SOCIAL_SECURITY_NUMBER) {
      dispatch(change(form, fields.passportNumber, null));
      dispatch(change(form, fields.passportCountry, null));
    }
  }, [dispatch, form, representativeDocumentType]);

  const [isSsnMasked, setIsSsnMasked] = React.useState(hasSsn);
  const [isPassportMasked, setIsPassportMasked] = React.useState(hasPassportNumber);

  const fieldsConfig = {
    [RepresentativeIDTypes.SOCIAL_SECURITY_NUMBER]: {
      placeholder: 'Social Security Number',
      name: fields.ssn,
      mask: '•••-••-••••',
      format: formatAsSSN,
      maxLength: 11,
      normalize: digitsOnly,
      validate: [requiredValidator, ssnValidator],
    },
    [RepresentativeIDTypes.PASSPORT_NUMBER]: {
      placeholder: 'Passport number',
      name: fields.passportNumber,
      maxLength: 9,
      validate: [maxValidator, minValidator, requiredValidator],
      mask: '•••••••••',
    },
  };

  return (
    <FlowStepperMain
      containerWidth={containerWidthMap.small}
      fields={
        <Container containerWidth={containerWidthMap.small}>
          {!updatedAllowed && <CannotUpdateFieldHint />}
          <RadioFieldWrapper>
            <Field
              className="margin-right--m"
              component={Radio}
              dataTestId="ssnRadio"
              id="representativeDocumentType-ssn"
              isDisabled={!updatedAllowed}
              isLocked={!updatedAllowed}
              name={fields.representativeDocumentType}
              optionText="Social Security Number"
              type="radio"
              value={RepresentativeIDTypes.SOCIAL_SECURITY_NUMBER}
            />

            <Field
              component={Radio}
              dataTestId="passportRadio"
              id="representativeDocumentType-passport"
              isDisabled={!updatedAllowed}
              isLocked={!updatedAllowed}
              name={fields.representativeDocumentType}
              optionText="Passport number"
              type="radio"
              value={RepresentativeIDTypes.PASSPORT_NUMBER}
            />
          </RadioFieldWrapper>
          <div>
            <FieldLabel>{fieldsConfig[representativeDocumentType].placeholder}</FieldLabel>
            <Fields>
              {representativeDocumentType === RepresentativeIDTypes.PASSPORT_NUMBER && (
                <Field
                  component={SelectFieldV2}
                  isDisabled={!updatedAllowed}
                  isLocked={!updatedAllowed}
                  isRequired
                  label="Country"
                  name={fields.passportCountry}
                  options={countriesAllList}
                  type="text"
                  validate={requiredValidator}
                  wrapperClassName={field.xl.left}
                />
              )}
              {representativeDocumentType === RepresentativeIDTypes.PASSPORT_NUMBER ? (
                <FieldWrapper>
                  <MaskedField
                    dataTestId="passportField"
                    {...fieldsConfig[representativeDocumentType]}
                    isDisabled={!updatedAllowed || ssnRequired}
                    isLocked={!updatedAllowed}
                    isMasked={isPassportMasked}
                    setIsMasked={setIsPassportMasked}
                  />
                </FieldWrapper>
              ) : (
                <FieldWrapper>
                  <MaskedField
                    dataTestId="ssnField"
                    {...fieldsConfig[representativeDocumentType]}
                    isDisabled={!updatedAllowed}
                    isLocked={!updatedAllowed}
                    isMasked={isSsnMasked}
                    setIsMasked={setIsSsnMasked}
                  />
                </FieldWrapper>
              )}
            </Fields>
            {ssnRequired && (
              <PassportHint
                intent={Intent.WARNING}
                title="US citizens must provide their Social Security Number (SSN)."
              />
            )}
          </div>

          <HelperWrapper>
            <Accordion {...howRoutableUseThisInformation} />
          </HelperWrapper>
        </Container>
      }
      invalid={invalid || !updatedAllowed || ssnRequired}
      onBack={viewSteps[activeView].previous}
      onNext={{
        ...viewSteps[activeView].next,
        isLoading: isUpdating,
      }}
      onSkip={updatedAllowed && viewSteps[activeView].skip}
      subTitle={{
        content: 'US citizens need to provide their Social Security Number.',
      }}
      title={{ content: 'Next, enter an identification number' }}
    />
  );
};

export default RepresentativeIDNumber;

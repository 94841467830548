import { createSelector } from 'reselect';

import { authNamespaceValueSelector } from 'selectors/forms';

/**
 * Selects the change password is submitting
 * @param {object} state - Redux state
 */
export const isSubmittingChangePasswordSelector = (state) => state.auth.changePassword.isSubmitting;

/**
 * Selects the change password last submitted
 * @param {object} state - Redux state
 */
export const lastSubmittedChangePasswordSelector = (state) => state.auth.changePassword.lastSubmitted;

/**
 * Selects the change password errors
 * @param {object} state - Redux state
 */
export const changePasswordErrorsSelector = (state) => state.auth.changePassword.errors;

/**
 * Selects the reset password is submitting
 * @param {object} state - Redux state
 */
export const isSubmittingResetPasswordSelector = (state) => state.auth.resetPassword.isSubmitting;

/**
 * Selects the reset password last submitted
 * @param {object} state - Redux state
 */
export const lastSubmittedResetPasswordSelector = (state) => state.auth.resetPassword.lastSubmitted;

/**
 * Selects the reset password errors
 * @param {object} state - Redux state
 */
export const resetPasswordErrorsSelector = (state) => state.auth.resetPassword.errors;

/**
 * Selects the log in is submitting
 * @type {StandardSelector}
 * @return {Boolean}
 */
export const isSSOFetchingSelector = (state) => Boolean(state.auth.login.isSSOFetching);

/**
 * Selects the sso log in is submitting
 * @param {object} state - Redux state
 */
export const isSSOSubmittingLoginSelector = (state) => state.auth.login.isSSOSubmitting;

/**
 * Selects the sso log in is submitting
 * @param {object} state - Redux state
 */
export const isSSORedirectingSelector = (state) => state.auth.login.isSSORedirecting;

/**
 * Selects the log in is submitting
 * @param {object} state - Redux state
 */
export const isSubmittingLoginSelector = (state) => state.auth.login.isSubmitting;

/**
 * Selects the login errors
 * @param {object} state - Redux state
 */
export const loginErrorsSelector = (state) => state.auth.login.errors;

/**
 * Selects the convert external to registered is submitting
 * @param {object} state - Redux state
 */
export const isSubmittingConvertExternalToRegisteredSelector = (state) =>
  state.auth.convertExternalToRegistered.isSubmitting;

/**
 * Selects the convert external to registered errors
 * @param {object} state - Redux state
 */
export const convertExternalToRegisteredErrorsSelector = (state) => state.auth.convertExternalToRegistered.errors;

/**
 * Selects initial values for the auth login form
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object}
 */
export const authLoginInitialValuesSelector = createSelector([authNamespaceValueSelector], (authNamespace) => ({
  form: {
    namespace: authNamespace,
    username: undefined,
    password: undefined,
  },
}));

import React from 'react';
import { isCurrencyCodeNonUSD } from 'helpers/currency';
import { isItemKindPayable, isItemKindReceivable, isPartnershipMatched } from 'helpers/items';
import { InvoiceListStatusMessage, PartnershipMatchingButtons } from './components';
const InvoiceListStatus = ({ companyIntegrationSettings, isConfirmed, rowData, ledger, shouldShowPartnerMatching = true, supportedCurrencies, }) => {
    const isCurrencyNotSupportedByVendor = rowData.isValidCurrency === false;
    const isBaseCurrencyCodeNotSupported = companyIntegrationSettings?.enableBaseCurrencyMode &&
        isItemKindPayable(rowData) &&
        rowData.currencyCode !== ledger.baseCurrencyCode;
    const isBaseCurrencyCodeSupported = companyIntegrationSettings?.enableBaseCurrencyMode &&
        isItemKindPayable(rowData) &&
        rowData.currencyCode === ledger.baseCurrencyCode;
    const isCurrencyUnsupported = !supportedCurrencies.includes(rowData.currencyCode);
    const isNonUsdInvoice = isItemKindReceivable(rowData) && isCurrencyCodeNonUSD(rowData.currencyCode);
    if (!shouldShowPartnerMatching ||
        isPartnershipMatched(rowData) ||
        isCurrencyNotSupportedByVendor ||
        isBaseCurrencyCodeNotSupported ||
        isCurrencyUnsupported ||
        isNonUsdInvoice ||
        rowData.requiresPurposeCode) {
        return (React.createElement(InvoiceListStatusMessage, { isBaseCurrencyCodeNotSupported: isBaseCurrencyCodeNotSupported, isBaseCurrencyCodeSupported: isBaseCurrencyCodeSupported, isConfirmed: isConfirmed, isCurrencyNotSupportedByVendor: isCurrencyNotSupportedByVendor, isCurrencyUnsupported: isCurrencyUnsupported, isNonUsdInvoice: isNonUsdInvoice, rowData: rowData }));
    }
    return React.createElement(PartnershipMatchingButtons, { item: rowData });
};
export default InvoiceListStatus;

import { combineReducers } from 'redux';

import allIdsReducer from './allIdsReducer';
import byIdReducer from './byIdReducer';
import deleteReducer from './deleteReducer';
import fetchReducer from './fetchReducer';
import isSubmittingEmailForwardReducer from './isSubmittingEmailForwardReducer';
import isUpdatingReducer from './isUpdatingReducer';
import lastSubmittedEmailForwardReducer from './lastSubmittedEmailForwardReducer';
import lastUpdatedReducer from './lastUpdatedReducer';

const notificationSettingsReducer = combineReducers({
  allIds: allIdsReducer,
  byId: byIdReducer,
  isDeletingEmailForward: deleteReducer,
  isFetching: fetchReducer,
  isUpdating: isUpdatingReducer,
  isSubmittingEmailForward: isSubmittingEmailForwardReducer,
  lastSubmittedEmailForward: lastSubmittedEmailForwardReducer,
  lastUpdated: lastUpdatedReducer,
});

export default notificationSettingsReducer;

import * as sidePanelNames from 'constants/sidePanels';

import * as partnershipTypes from 'types/partnership';
import * as types from 'types/sidePanels';

export const initialState = {
  formContext: null,
  isBillView: false,
  isSubmitting: false,
  open: false,
  partnershipId: null,
  paymentDeliveryMethod: null,
};

/**
 * Reducer for the contact side panel. Provides open/close, meta, and UI storage so that the sidebar is more
 * customizable for different use-cases.
 * @function
 * @param {ReduxState} state
 * @param {ReduxAction} action
 * @returns {ReduxReducer}
 */
const addPartnerFundingAccountSidePanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SIDE_PANEL_OPEN:
      return action.payload.name === sidePanelNames.sidePanelNameAddPartnerFundingAccount
        ? { ...state, ...action.payload.state, open: true }
        : state;

    case types.SIDE_PANEL_CLOSE:
      return action.payload.name === sidePanelNames.sidePanelNameAddPartnerFundingAccount ? initialState : state;

    case partnershipTypes.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_FAILURE:
    case partnershipTypes.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_FAILURE:
    case partnershipTypes.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_SUCCESS:
    case partnershipTypes.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };

    case partnershipTypes.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_REQUEST:
    case partnershipTypes.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_REQUEST:
      return {
        ...state,
        isSubmitting: true,
      };

    default:
      return state;
  }
};

export default addPartnerFundingAccountSidePanelReducer;

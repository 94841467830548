import { apiCall } from '@routable/framework';
import { queryContext } from '@routable/shared';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSelector } from 'react-redux';
import { PLATFORM_EMAILS } from 'constants/platform';
import { getMailtoUrl, isExternalZendeskRoute } from 'helpers/urls';
import { isFn } from 'helpers/utility';
import { currentMembershipSelector } from 'selectors/membershipsSelector';
import { ZENDESK_TOKEN_INITIAL_REFETCH_INTERVAL } from './constants';
import { membershipConfigResponseModel } from './CustomerService.models';
export const CustomerServiceHelper = {
    onScriptLoaded() {
        this.hide();
        window.zE('messenger:on', 'close', () => this.hide());
    },
    open() {
        window.zE?.('messenger', 'open');
    },
    close() {
        window.zE?.('messenger', 'close');
    },
    hide() {
        window.zE?.('messenger', 'hide');
    },
    show({ fallbackEmail = PLATFORM_EMAILS.SUPPORT, } = {}) {
        if (this.isWidgetAvailable()) {
            window.zE?.('messenger', 'show');
            this.open();
        }
        else {
            window.location = getMailtoUrl(fallbackEmail);
        }
    },
    showAndSetRequestId({ requestId }) {
        this.show();
        if (this.isWidgetAvailable()) {
            this.setConversationFields([
                {
                    id: process.env.ROUTABLE_FE_ZENDESK_REQUEST_FIELD_ID,
                    value: requestId,
                },
            ]);
        }
    },
    showAndSetPaymentId({ paymentId }) {
        this.show();
        if (this.isWidgetAvailable()) {
            this.setConversationFields([
                {
                    id: process.env.ROUTABLE_FE_ZENDESK_PAYMENT_FIELD_ID,
                    value: paymentId,
                },
            ]);
        }
    },
    setIdentification(jwt) {
        window.zE?.('messenger', 'loginUser', (callback) => {
            callback(jwt);
        });
    },
    setConversationFields(fields) {
        window.zE?.('messenger:set', 'conversationFields', fields);
    },
    logout() {
        window.zE?.('messenger', 'logoutUser');
    },
    isWidgetAvailable() {
        return Boolean(window.zE && isFn(window.zE));
    },
};
export const useCustomerServiceWidget = () => {
    const [loaded, setLoaded] = React.useState(false);
    const [expiration, setExpiration] = React.useState(ZENDESK_TOKEN_INITIAL_REFETCH_INTERVAL);
    const currentMembership = useSelector(currentMembershipSelector);
    useQuery({
        queryKey: ['service token', 'zendesk'],
        context: queryContext,
        queryFn: () => apiCall({
            url: `memberships/${currentMembership?.id}/config/`,
            responseModel: membershipConfigResponseModel,
            method: 'get',
            camelCaseResponse: true,
        }),
        onSuccess(data) {
            const { zendeskPayload } = data?.data?.attributes || {};
            if (!zendeskPayload) {
                return;
            }
            CustomerServiceHelper.setIdentification(zendeskPayload?.token);
            setExpiration(zendeskPayload?.tokenTtl * 1000);
        },
        refetchInterval: expiration,
        enabled: Boolean(loaded && currentMembership?.id),
    });
    React.useEffect(() => {
        let cancelled = false;
        const widgetId = isExternalZendeskRoute()
            ? process.env.ROUTABLE_FE_ZENDESK_EXTERNAL_WIDGET_ID
            : process.env.ROUTABLE_FE_ZENDESK_INTERNAL_WIDGET_ID;
        if (!widgetId) {
            return () => { };
        }
        const script = document.createElement('script');
        script.src = `https://static.zdassets.com/ekr/snippet.js?key=${widgetId}`;
        script.id = 'ze-snippet';
        script.async = true;
        script.addEventListener('load', () => {
            if (cancelled) {
                return;
            }
            CustomerServiceHelper.onScriptLoaded();
            setLoaded(true);
        });
        document.body.appendChild(script);
        return () => {
            cancelled = true;
            setLoaded(false);
            document.body.removeChild(script);
        };
    }, []);
};

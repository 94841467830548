import { useEffect, useRef, useState } from 'react';
import { RoutableEventsSync } from '../../services/events.sync.service';
export const useEventSync = (eventName, fn, initialData) => {
    const fnRef = useRef(fn);
    const isMounted = useRef(false);
    fnRef.current = fn;
    const [returnData, setReturnData] = useState(initialData);
    useEffect(() => {
        const watchListener = async (ev) => {
            if (ev) {
                if (isMounted.current) {
                    const awaitedValue = await fnRef.current(ev?.detail?.data ?? ev?.detail ?? ev);
                    if (isMounted.current) {
                        setReturnData(awaitedValue);
                    }
                }
            }
        };
        if (!isMounted.current) {
            isMounted.current = true;
            RoutableEventsSync.Subscribe(eventName, watchListener);
        }
        return () => {
            isMounted.current = false;
        };
    }, [eventName]);
    return returnData;
};
export const useDispatchEventSync = () => RoutableEventsSync.Publish;

import React from 'react';
import { Text } from 'components';
import { StaticCountryCode } from 'constants/countries';
import { TextSize } from 'constants/styles/typography';
import { getCountryNameFromCode } from 'helpers/countries';
import { firstValue } from 'helpers/utility';
import { useCountryCodesContext } from 'hooks';
import { getTooltipText } from '../helpers/text';
import {} from './CurrencyCodeBlockFieldTooltipText.types';
const CurrencyCodeBlockFieldTooltipText = ({ companyIntegrationSettings, countryCodePartner, currencyCodesBillOptions, ledger, onlyOneOptionAvailable, }) => {
    const countryCodeMap = useCountryCodesContext();
    if (!onlyOneOptionAvailable) {
        return null;
    }
    const countryCode = countryCodePartner || StaticCountryCode.US;
    const countryName = getCountryNameFromCode(countryCodeMap, countryCode);
    const currencyCode = firstValue(currencyCodesBillOptions).text;
    return (React.createElement(Text.Regular, { size: TextSize.LEVEL_100 }, getTooltipText({
        companyIntegrationSettings,
        currencyCode,
        countryName,
        ledger,
    })));
};
export default CurrencyCodeBlockFieldTooltipText;

import PropTypes from 'prop-types';
import React from 'react';

import { MultiCheckSelect } from 'components';
import { getMultiSelectOptionValue } from 'components/selectTypes/MultiCheckSelect/helpers';

import { texts } from 'constants/partnerFundingAccount';

/**
 * Renders select field for selecting which accounts to override
 * with newly added funding account inside AddPartnerFundingAccount
 * form
 * @param {ComponentProps} props
 * @param {Object[]} props.options
 * @param {Object} props.partnership
 * @return {StatelessComponent}
 */
const AddPartnerFundingAccountOptionsAccountSelect = ({ options, partnership, ...rest }) => (
  <MultiCheckSelect
    getOptionValue={getMultiSelectOptionValue}
    label={texts.FORM_REPLACE_ACCOUNTS_PLACEHOLDER}
    name="replaceFundingAccounts"
    options={options}
    {...rest}
  />
);

AddPartnerFundingAccountOptionsAccountSelect.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  valueKey: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  partnership: PropTypes.shape().isRequired,
};

export default AddPartnerFundingAccountOptionsAccountSelect;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const LabelLogo = ({ children, className }) => {
  const labelLogoClassNames = classNames({
    logo: true,
    [className]: !!className,
  });

  return <div className={labelLogoClassNames}>{children}</div>;
};

LabelLogo.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

LabelLogo.defaultProps = {
  className: null,
};

export default LabelLogo;

import React from 'react';
import { hasAuthToken } from 'helpers/auth';
import { getDomainWithOrWithoutCompanyNamespace, isTopLevelDomain } from 'helpers/urls';
export const useRedirectFromTopLevelToBrandedWorkspace = () => {
    React.useEffect(() => {
        if (!hasAuthToken() && isTopLevelDomain()) {
            const includePathname = true;
            const includeSearch = true;
            window.location.replace(getDomainWithOrWithoutCompanyNamespace(null, includePathname, includeSearch));
        }
    }, []);
};

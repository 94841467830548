import styled from 'styled-components';
export const SelectedFileContainer = styled.div `
  background-color: var(--blue--x-light);
  border: 1px solid var(--blue--bold);
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-5) var(--spacing-4);
  color: var(--blue--bold);
  font-size: var(--font-size-2);
`;

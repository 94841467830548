import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useLocalStorage } from 'react-use';
import React, {} from 'react';
import { QueryDevtoolsContext } from './QueryClient.context';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './QueryClient.client';
export const QueryDevtoolsProvider = ({ children }) => {
    const [devtoolsVisible, setDevtoolsVisible] = useLocalStorage('REACT_QUERY_DEV_TOOLS_VISIBLE', false);
    const toggleDevtools = () => {
        setDevtoolsVisible(!devtoolsVisible);
    };
    return (React.createElement(QueryDevtoolsContext.Provider, { value: { visible: devtoolsVisible, toggleDevtools } },
        children,
        devtoolsVisible && (React.createElement(ReactQueryDevtools, { position: "bottom-right", toggleButtonProps: { className: 'react-query-dev-button' } }))));
};
export const QueryProvider = ({ children }) => (React.createElement(QueryClientProvider, { client: queryClient, contextSharing: true },
    React.createElement(QueryDevtoolsProvider, null, children)));

import { combineReducers } from 'redux';

import authChangePasswordReducer from './authChangePasswordReducer';
import authConvertExternalReducer from './authConvertExternalReducer';
import authLoginReducer from './authLoginReducer';
import authResetPasswordReducer from './authResetPasswordReducer';

const authReducer = combineReducers({
  changePassword: authChangePasswordReducer,
  convertExternalToRegistered: authConvertExternalReducer,
  login: authLoginReducer,
  resetPassword: authResetPasswordReducer,
});

export default authReducer;

import { ExistingItemActions } from 'constants/item';

import { getQueryString } from 'helpers/queryParams';

import { BASE_API_URL } from 'services/api';

// *************************************
// Item
// *************************************
export const createItemEndpoint = `${BASE_API_URL}/items/`;

export const getDeleteItemEndpoint = (itemId) => `${BASE_API_URL}/items/${itemId}/delete/`;

export const getItemEndpoint = (itemId, queryParams = undefined) => {
  const baseItemEndpoint = `${BASE_API_URL}/items/${itemId}/`;

  if (!queryParams) {
    return baseItemEndpoint;
  }

  return `${baseItemEndpoint}?${getQueryString(queryParams)}`;
};

export const getItemTransactionsEndpoint = (itemId) => `${BASE_API_URL}/items/${itemId}/transactions/`;

export const getItemReminderEndpoint = (itemId) => `${BASE_API_URL}/items/${itemId}/send_reminder/`;

export const getItemListEndpoint = (queryParams) => `${BASE_API_URL}/items/?${getQueryString(queryParams)}`;

export const approvalsEvaluateItemEndpoint = `${BASE_API_URL}/approvals/evaluate/item/`;

export const getUpdateItemEndpoint = (itemId) => `${createItemEndpoint}${itemId}/${ExistingItemActions.SEND}/`;

// *************************************
// Partnership items
// *************************************
export const getSubmitAllExistingItemEndpoint = (partnershipId, action) =>
  `${BASE_API_URL}/partnerships/${partnershipId}/items/${action}/`;

export const getSubmitExistingItemEndpoint = (partnershipId, itemId, action) =>
  `${BASE_API_URL}/partnerships/${partnershipId}/items/${itemId}/${action}/`;

// *************************************
// Import (from ledger)
// *************************************
export const getExistingInvoicesAndBillsEndpoint = (direction) =>
  `${BASE_API_URL}/items/import/outstanding/${direction}/?exists=true`;

export const processInvoiceEndpoint = `${BASE_API_URL}/items/import/process_invoice/`;

export const processInvoicesEndpoint = `${BASE_API_URL}/items/import/process_invoices/`;

// *************************************
// Bills and Attachments
// *************************************

export const getSplitBillEndpoint = (itemId) => `${BASE_API_URL}/items/${itemId}/split_bill/`;

export const getItemAttachmentDeleteEndpoint = (itemId, attachmentId) =>
  `${BASE_API_URL}/items/${itemId}/attachments/${attachmentId}/delete/`;

export const postSetPrimaryAttachment = (itemId) => `${BASE_API_URL}/items/${itemId}/set_primary_attachment/`;

import PropTypes from 'prop-types';
import React from 'react';

import { IconNames, ModalFooter, ModalFooterButton, ModalFooterButtonCancel } from 'components';

import { formNamesExternal } from 'constants/forms';
import { sizes } from 'constants/styles';

/**
 * Modal footer for confirm tax info which has Cancel and Confirm information buttons.
 * @param {boolean} canSubmit - The positive version of isDisabled
 * @param {function} onCloseModal
 * @param {function} onSubmitModal
 * @param {boolean} submitting
 * @returns {StatelessComponent}
 * @constructor
 */
const ConfirmTaxInfoModalFooter = ({ canSubmit, onCloseModal, onSubmitModal, submitting }) => (
  <ModalFooter>
    <div className="modal-footer--container">
      <div className="content--left">
        <ModalFooterButtonCancel onCloseModal={onCloseModal} />
      </div>

      <div className="content--right">
        <ModalFooterButton
          htmlFor={formNamesExternal.TAX_E_DELIVERY_CONSENT}
          id="externalTaxEDeliveryConsentConfirmBtn"
          isActionButton
          isDisabled={!canSubmit}
          isLoading={submitting}
          leftIconName={IconNames.TICK_CIRCLE}
          leftIconSize={sizes.iconSizes.LARGE}
          onClick={onSubmitModal}
        >
          Confirm information
        </ModalFooterButton>
      </div>
    </div>
  </ModalFooter>
);

ConfirmTaxInfoModalFooter.propTypes = {
  canSubmit: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSubmitModal: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default ConfirmTaxInfoModalFooter;

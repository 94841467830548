import {} from '@routable/types';
import _cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useRef } from 'react';
import { TablematicTableSection } from './tablematic.table.section.component';
import { Container } from './tablematic.table.styled';
import {} from './tablematic.table.types';
export const TablematicTable = ({ activeTableSectionIds, getAreTableRowFieldValuesEqualToInitial, getTableSectionFieldSize, is3WayMatching, isOnDetailsPage, isPayable, rowDataLength, tableSections, }) => {
    const sectionRefs = useRef([]);
    useEffect(() => {
        sectionRefs.current = activeTableSectionIds
            ? activeTableSectionIds.map((_, idx) => sectionRefs.current[idx] || React.createRef())
            : [];
    }, [activeTableSectionIds]);
    return (React.createElement(Container, { className: "tablematic-table-outside-body" }, activeTableSectionIds?.map((sectionId, sectionIdx) => {
        return (React.createElement(TablematicTableSection, { getAreTableRowFieldValuesEqualToInitial: getAreTableRowFieldValuesEqualToInitial, getTableSectionFieldSize: getTableSectionFieldSize, is3WayMatching: is3WayMatching, isOnDetailsPage: isOnDetailsPage, isPayable: isPayable, key: sectionId, rowDataLength: rowDataLength, sectionRef: sectionRefs.current[sectionIdx], tableSection: tableSections[sectionId] }));
    })));
};

export const CONNECT_INTEGRATION_FAILURE = 'CONNECT_INTEGRATION_FAILURE';
export const CONNECT_INTEGRATION_REQUEST = 'CONNECT_INTEGRATION_REQUEST';

export const DISCONNECT_INTEGRATION_CONFIG_FAILURE = 'DISCONNECT_INTEGRATION_CONFIG_FAILURE';
export const DISCONNECT_INTEGRATION_CONFIG_REQUEST = 'DISCONNECT_INTEGRATION_CONFIG_REQUEST';
export const DISCONNECT_INTEGRATION_CONFIG_SUCCESS = 'DISCONNECT_INTEGRATION_CONFIG_SUCCESS';

export const FETCH_INTEGRATION_CONFIG_FAILURE = 'FETCH_INTEGRATION_CONFIG_FAILURE';
export const FETCH_INTEGRATION_CONFIG_REQUEST = 'FETCH_INTEGRATION_CONFIG_REQUEST';
export const FETCH_INTEGRATION_CONFIG_SUCCESS = 'FETCH_INTEGRATION_CONFIG_SUCCESS';

export const FETCH_INTEGRATION_CONFIGS_FAILURE = 'FETCH_INTEGRATION_CONFIGS_FAILURE';
export const FETCH_INTEGRATION_CONFIGS_REQUEST = 'FETCH_INTEGRATION_CONFIGS_REQUEST';
export const FETCH_INTEGRATION_CONFIGS_SUCCESS = 'FETCH_INTEGRATION_CONFIGS_SUCCESS';

export const FETCH_INTEGRATIONS_FAILURE = 'FETCH_INTEGRATIONS_FAILURE';
export const FETCH_INTEGRATIONS_REQUEST = 'FETCH_INTEGRATIONS_REQUEST';
export const FETCH_INTEGRATIONS_SUCCESS = 'FETCH_INTEGRATIONS_SUCCESS';

export const FETCH_LEDGER_PARTNERSHIPS_FAILURE = 'FETCH_LEDGER_PARTNERSHIPS_FAILURE';
export const FETCH_LEDGER_PARTNERSHIPS_REQUEST = 'FETCH_LEDGER_PARTNERSHIPS_REQUEST';
export const FETCH_LEDGER_PARTNERSHIPS_SUCCESS = 'FETCH_LEDGER_PARTNERSHIPS_SUCCESS';

export const FETCH_SINGLE_LEDGER_PARTNERSHIPS = 'FETCH_SINGLE_LEDGER_PARTNERSHIPS';

export const FETCH_PLATFORM_PARTNERSHIPS_FAILURE = 'FETCH_PLATFORM_PARTNERSHIPS_FAILURE';
export const FETCH_PLATFORM_PARTNERSHIPS_REQUEST = 'FETCH_PLATFORM_PARTNERSHIPS_REQUEST';
export const FETCH_PLATFORM_PARTNERSHIPS_SUCCESS = 'FETCH_PLATFORM_PARTNERSHIPS_SUCCESS';

export const FETCH_PLATFORM_PARTNERSHIPS_SEARCH_SUCCESS = 'FETCH_PLATFORM_PARTNERSHIPS_SEARCH_SUCCESS';

export const REFRESH_INTEGRATION_CONFIG_FAILURE = 'REFRESH_INTEGRATION_CONFIG_FAILURE';
export const REFRESH_INTEGRATION_CONFIG_REQUEST = 'REFRESH_INTEGRATION_CONFIG_REQUEST';
export const REFRESH_INTEGRATION_CONFIG_SUCCESS = 'REFRESH_INTEGRATION_CONFIG_SUCCESS';

export const RESET_PLATFORM_PARTNERSHIP = 'RESET_PLATFORM_PARTNERSHIP';

export const SUBMIT_LEDGER_PARTNERSHIP_MERGE_FAILURE = 'SUBMIT_LEDGER_PARTNERSHIP_MERGE_FAILURE';
export const SUBMIT_LEDGER_PARTNERSHIP_MERGE_REQUEST = 'SUBMIT_LEDGER_PARTNERSHIP_MERGE_REQUEST';
export const SUBMIT_LEDGER_PARTNERSHIP_MERGE_SUCCESS = 'SUBMIT_LEDGER_PARTNERSHIP_MERGE_SUCCESS';

export const SUBMIT_LEDGER_PARTNERSHIP_FAILURE = 'SUBMIT_LEDGER_PARTNERSHIP_FAILURE';
export const SUBMIT_LEDGER_PARTNERSHIP_REQUEST = 'SUBMIT_LEDGER_PARTNERSHIP_REQUEST';
export const SUBMIT_LEDGER_PARTNERSHIP_SUCCESS = 'SUBMIT_LEDGER_PARTNERSHIP_SUCCESS';

export const SUBMIT_PLATFORM_PARTNERSHIP_FAILURE = 'SUBMIT_PLATFORM_PARTNERSHIP_FAILURE';
export const SUBMIT_PLATFORM_PARTNERSHIP_REQUEST = 'SUBMIT_PLATFORM_PARTNERSHIP_REQUEST';
export const SUBMIT_PLATFORM_PARTNERSHIP_SUCCESS = 'SUBMIT_PLATFORM_PARTNERSHIP_SUCCESS';

export const FETCH_ADDITIONAL_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_SUCCESS =
  'FETCH_ADDITIONAL_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_SUCCESS';
export const FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_FAILURE = 'FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_FAILURE';
export const FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_REQUEST = 'FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_REQUEST';
export const FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_SUCCESS = 'FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_SUCCESS';

export const FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_FAILURE = 'FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_FAILURE';
export const FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_REQUEST = 'FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_REQUEST';
export const FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_SUCCESS = 'FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_SUCCESS';

export const SEARCH_FUNDING_ACCOUNTS_FAILURE = 'SEARCH_FUNDING_ACCOUNTS_FAILURE';
export const SEARCH_FUNDING_ACCOUNTS_REQUEST = 'SEARCH_FUNDING_ACCOUNTS_REQUEST';
export const SEARCH_FUNDING_ACCOUNTS_SUCCESS = 'SEARCH_FUNDING_ACCOUNTS_SUCCESS';

export const SUBMIT_UNMATCHED_PLATFORM_FUNDING_ACCOUNT_FAILURE = 'SUBMIT_UNMATCHED_PLATFORM_FUNDING_ACCOUNT_FAILURE';
export const SUBMIT_UNMATCHED_PLATFORM_FUNDING_ACCOUNT_REQUEST = 'SUBMIT_UNMATCHED_PLATFORM_FUNDING_ACCOUNT_REQUEST';
export const SUBMIT_UNMATCHED_PLATFORM_FUNDING_ACCOUNT_SUCCESS = 'SUBMIT_UNMATCHED_PLATFORM_FUNDING_ACCOUNT_SUCCESS';

export const SUBMIT_INTEGRATION_CONNECT_AUTH = 'SUBMIT_INTEGRATION_CONNECT_AUTH';

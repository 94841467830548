import * as types from 'types/namespace';

const isNamespaceValidReducer = (state = false, action) => {
  if (action.type === types.FETCH_NAMESPACE_SUCCESS) {
    return true;
  }

  if (action.type === types.FETCH_NAMESPACE_FAILURE) {
    return false;
  }

  return state;
};

export default isNamespaceValidReducer;

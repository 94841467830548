import React from 'react';
import { BaseHint } from 'components';
import { Intent } from 'constants/ui';
import { useExternalPaymentFundingAccountHint } from '../../hooks';
const UpdatePaymentMethodFundingAccountHint = () => {
    const message = useExternalPaymentFundingAccountHint();
    if (!message) {
        return null;
    }
    return React.createElement(BaseHint, { className: "margin-bottom--m-large", intent: Intent.DANGER, multiline: true, text: message });
};
export default UpdatePaymentMethodFundingAccountHint;

import { createWasFetchedReducer } from 'store/redux';

import { FETCH_PARTNERSHIP_ITEMS_FAILURE, FETCH_PARTNERSHIP_ITEMS_SUCCESS } from 'types/partnership';

/**
 * Action types representing finish cases
 * @constant
 * @type {ReduxType[]}
 */
const finishCases = [FETCH_PARTNERSHIP_ITEMS_SUCCESS, FETCH_PARTNERSHIP_ITEMS_FAILURE];

/**
 * Partnership Items wasFetched state
 * @type {ReduxReducer}
 */
const partnershipItemsWasFetchedReducer = createWasFetchedReducer(finishCases);

export { finishCases };
export default partnershipItemsWasFetchedReducer;

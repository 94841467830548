import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';
import { IconNames } from 'components/icon';

import { sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

const EscButton = ({ onClick }) => (
  <ButtonV2
    dataTestId="button_x_escape"
    intent={Intent.NEUTRAL}
    isIconButton
    leftIconName={IconNames.CROSS}
    leftIconSize={sizes.iconSizes.LARGE}
    onClick={onClick}
  />
);

EscButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default EscButton;

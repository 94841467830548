import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';
import { IconNames } from 'components/icon';

import { sizes } from 'constants/styles';

/**
 * Renders a primary button with icon.
 * @param {ComponentProps} props
 * @param {*} props.children
 * @param {string} props.icon
 * @param {boolean} [props.isDisabled]
 * @param {EventHandlerNonNull} props.onClick
 * @return {StatelessComponent}
 */
const PopoverSelectButton = ({ children, icon, isDisabled, onClick }) => (
  <ButtonV2
    isDisabled={isDisabled}
    leftIconClassName="margin-right--xm"
    leftIconName={icon}
    leftIconSize={sizes.iconSizes.MEDIUM}
    onClick={onClick}
  >
    {children}
  </ButtonV2>
);

PopoverSelectButton.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
};

PopoverSelectButton.defaultProps = {
  children: undefined,
  icon: IconNames.ADD,
  isDisabled: undefined,
  onClick: undefined,
};

export default PopoverSelectButton;

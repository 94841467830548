/**
 * @module data/submitTransformers/partnership
 */

/**
 * @fileOverview Transform data for a partnership and its members before sending it to the API.
 */

import { PartnershipMemberAccess } from 'enums/partnerships';

import { isFullAddress } from 'helpers/addressHelpers';
import { isCompanyTypePersonal } from 'helpers/currentCompany';
import { valueOrUndefined } from 'helpers/forms';
import {
  isPartnershipMemberNotArchived,
  isPartnershipMemberCompanyPropNotCurrentCompany,
} from 'helpers/partnershipMembers';
import { uniqueArray, flattenArray, hasLength, identity, narrow } from 'helpers/utility';

import { partnershipMemberForCreate } from './partnershipMember';

/**
 * Extracts a 1d array of known partnership ids from an array of partnership members.
 * @param {FormPartnershipMember[]} partnershipMembers
 * @return {Maybe<string[]>}
 */
export const knownPartnershipIdsForCreatePartnership = (partnershipMembers) => {
  const knownIds = partnershipMembers.map((member) => member?.meta?.knownPartnershipIds);
  const flattenedIds = flattenArray(knownIds).filter(Boolean);
  return hasLength(flattenedIds) ? uniqueArray(flattenedIds) : undefined;
};

/**
 * Given the partnership form values, returns a meta object to be submitted with form data.
 * @param {FormPartnershipMember[]} partnershipMembers
 * @return {{ knownPartnershipIds: Maybe<string[]> }}
 */
export const metaForCreatePartnership = ({ partnershipMembers, meta }) => {
  const ids = hasLength(meta?.knownPartnershipIds)
    ? meta.knownPartnershipIds
    : knownPartnershipIdsForCreatePartnership(partnershipMembers);

  return {
    knownPartnershipIds: ids,
  };
};

/**
 * Transforms data for a partnership before submitting to the API.
 * @param {Object} values - Form values
 * @return {Object}
 */
export const partnershipForCreatePartnership = (values) => {
  const {
    address,
    countryCode,
    externalRef,
    isCustomer,
    isVendor,
    ledgerCurrencyCode,
    name,
    partner,
    partnership,
    partnershipMembers: unformattedPartnershipMembers,
  } = values;

  // For business partnership types, we currently require that there is a
  // partnership member attached to the partnership at the time of creation.
  // For individual partnership types, we already have everything we need for
  // the member so we can create a membership ourselves.
  let partnershipMembers = [];
  let legalFirstName;
  let legalLastName;

  if (isCompanyTypePersonal(partner.companyType)) {
    const [partnershipMember] = unformattedPartnershipMembers;
    // Only populate partnershipMembers if there is a partnershipMember
    // This allows the contact to be optional
    if (partnershipMember) {
      partnershipMembers = [
        {
          ...partnershipMemberForCreate(partnershipMember),
          defaultGeneral: PartnershipMemberAccess.ACTIONABLE,
          defaultItem: PartnershipMemberAccess.ACTIONABLE,
        },
      ];

      // We also don't want to send partner.name value, since that is referring to the
      // legal name. Legal name is constructed from the legal first and last name when
      // dealing with individuals, so that is what we want to send along instead
      delete partner.name;
      legalFirstName = partnershipMember.firstName;
      legalLastName = partnershipMember.lastName;
    }
  } else {
    partnershipMembers = unformattedPartnershipMembers?.map(partnershipMemberForCreate);
  }

  return {
    companyMailingAddress: address && isFullAddress(address) ? address : undefined,
    countryCode,
    customerLedgerId: valueOrUndefined(partnership.customerLedgerId),
    externalRef,
    isCustomer,
    isVendor,
    ledgerCurrencyCode,
    legalFirstName,
    legalLastName,
    meta: metaForCreatePartnership(values),
    name,
    partner,
    partnershipMembers,
    vendorLedgerId: valueOrUndefined(partnership.vendorLedgerId),
  };
};

/**
 * Gets the metadata for sending a partnership invite.
 * @param {Object} values - Form values
 * @return {Object}
 */
export const metaForSendPartnershipInvite = (values) => {
  const { inviteMessage } = values;

  if (inviteMessage) {
    return { inviteMessage };
  }

  return undefined;
};

/**
 * Transforms data for sending a partnership invite.
 * @param {Object} values - Form values
 * @param {PartnershipMember[]} values.partnershipMembers
 * @param {string} [values.partnershipId]
 * @param {Object} [options]
 * @param {Function[]} [options.partnershipMemberFilters=[]]
 * @param {Function} [options.transformPartnershipMember=identity]
 * @return {Object}
 */
export const sendPartnershipInviteData = (values, options = {}) => {
  const { partnership, partnershipId, partnershipMembers } = values;

  const { partnershipMemberFilters = [], transformPartnershipMember = identity } = options;

  const filteredPartnershipMembers = narrow(
    partnershipMembers,
    isPartnershipMemberCompanyPropNotCurrentCompany,
    isPartnershipMemberNotArchived,
    ...partnershipMemberFilters,
  );

  const formattedPartnershipMembers = filteredPartnershipMembers.map(transformPartnershipMember);

  return {
    id: partnershipId || partnership.id,
    meta: metaForSendPartnershipInvite(values),
    partnershipMembers: formattedPartnershipMembers,
  };
};

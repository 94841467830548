import { combineReducers } from 'redux';

import allReducer from './allReducer';
import byIdReducer from './byIdReducer';
import fetchReducer from './fetchReducer';

const ledgerClearingAccountsReducer = combineReducers({
  allIds: allReducer,
  byId: byIdReducer,
  isFetching: fetchReducer,
});

export default ledgerClearingAccountsReducer;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components';

import { ButtonAppearance, ButtonSize } from 'constants/button';

const ActionNavAction = ({ actionOnClick, children, className = '', ...props }) => (
  <ButtonV2
    appearance={ButtonAppearance.BORDERLESS}
    className={classNames('font-size--regular', {
      [className]: !!className,
    })}
    onClick={actionOnClick}
    size={ButtonSize.MEDIUM}
    {...props}
  >
    {children}
  </ButtonV2>
);

ActionNavAction.propTypes = {
  actionOnClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};

ActionNavAction.defaultProps = {
  actionOnClick: undefined,
  children: undefined,
};

export default ActionNavAction;

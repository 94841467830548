import React from 'react';
import { useMedia } from 'react-use';
import { Table } from 'complexComponents';
import { Modal, ModalBodyContent, ModalFooter, ModalFooterButtonCancel } from 'components';
import { ButtonSize } from 'constants/button';
import { LineItemStyles } from 'constants/lineItems';
import { mobileMediaQuery } from 'constants/mediaQuery';
import { width } from 'constants/styles';
import { ZIndexLayers } from 'constants/ui';
import { getZIndex } from 'helpers/zIndex';
import { InvoicesTableConfig, LineItemsExpensesTableConfig, LineItemsItemsTableConfig, } from '../../constants/tableConfig';
import {} from './ExternalReceiptPaymentLineItemsModal.types';
import './ExternalReceiptPaymentLineItemsModal.scss';
const baseOverlay = {
    overlay: { zIndex: getZIndex(ZIndexLayers.MODAL.OVERLAY, 5) },
};
const fullModalOverride = {
    content: { width: width.contentWidth.FULL },
    ...baseOverlay,
};
const ExternalReceiptPaymentLineItemsModal = ({ item, modalState, onCloseModal, }) => {
    const isMobile = useMedia(mobileMediaQuery);
    const overrideStyle = isMobile ? baseOverlay : fullModalOverride;
    const lineItemsWithCurrencyCode = item?.lineItems.map((lineItem) => ({
        ...lineItem,
        currencyCode: item.currencyCode,
    })) || [];
    const lineItemsItems = lineItemsWithCurrencyCode.filter(({ style }) => style === LineItemStyles.ITEM);
    const lineItemsExpenses = lineItemsWithCurrencyCode.filter(({ style }) => style === LineItemStyles.ACCOUNT);
    return (React.createElement(Modal, { body: React.createElement(React.Fragment, null,
            React.createElement(ModalBodyContent, null,
                React.createElement(Table, { columns: InvoicesTableConfig({ itemKind: item?.kind }), data: item ? [item] : [] }),
                !!lineItemsExpenses.length && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "text-black text-lg font-medium leading-5 pt-7 pb-5 inline-block" }, "Expenses"),
                    React.createElement(Table, { columns: LineItemsItemsTableConfig, data: lineItemsExpenses, hasPagination: true }))),
                !!lineItemsItems.length && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "text-black text-lg font-medium leading-5 pt-7 pb-5 inline-block" }, "Items"),
                    React.createElement(Table, { columns: LineItemsExpensesTableConfig, data: lineItemsItems, hasPagination: true })))),
            React.createElement(ModalFooter, null,
                React.createElement("div", { className: "width--100-percent content--right" },
                    React.createElement(ModalFooterButtonCancel, { buttonSize: ButtonSize.MEDIUM, buttonText: "Close", onCloseModal: onCloseModal })))), className: "external-receipt-payment-line-items-modal", hasInlineFooter: true, modalHeader: "Details", modalState: modalState, onCloseModal: onCloseModal, overrideStyle: overrideStyle }));
};
export default ExternalReceiptPaymentLineItemsModal;

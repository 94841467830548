import classNames from 'classnames';
import React from 'react';
import { FlexCol } from 'components';
import {} from './ExternalBlocksSection.types';
import './ExternalBlocksSection.scss';
const ExternalBlocksSection = ({ children, className }) => (React.createElement(FlexCol, { className: classNames('external-blocks-section', {
        [className]: Boolean(className),
    }) }, children));
ExternalBlocksSection.defaultProps = {
    className: undefined,
};
export default ExternalBlocksSection;

import { closeSidePanel, openSidePanel } from 'actions/sidePanels';

import { formNamesItem, formNamesPartnership } from 'constants/forms';
import {
  ContactSidePanelType,
  sidePanelNameAddPartnerFundingAccount,
  sidePanelNameAddOrUpdateVendorRegisteredAddress,
  sidePanelNameAddVendorRegisteredAddressInForm,
  sidePanelNameEditCompanyGeneralInfo,
  sidePanelNameManuallyAddBankAccount,
} from 'constants/sidePanels';

import { isEqual } from 'helpers/utility';

export const isContactSidePanelTypeCreate = (sidePanelType) => isEqual(sidePanelType, ContactSidePanelType.CREATE);

export const isContactSidePanelTypeUpdate = (sidePanelType) => isEqual(sidePanelType, ContactSidePanelType.UPDATE);

/**
 * Creates an action for closing add partner funding account side panel
 * @return {ReduxAction}
 */
export const closeAddPartnerFundingAccountSidePanel = () =>
  closeSidePanel({
    name: sidePanelNameAddPartnerFundingAccount,
  });

/**
 * Creates an action for opening add partner funding account side panel
 * @param sidePanelState
 * @param {string} sidePanelState.formContext
 * @param {string|number} sidePanelState.partnershipId
 * @param {PaymentDeliveryMethod} sidePanelState.paymentDeliveryMethod
 * @return {ReduxAction}
 */
export const openAddPartnerFundingAccountSidePanel = (sidePanelState) =>
  openSidePanel({
    name: sidePanelNameAddPartnerFundingAccount,
    state: sidePanelState,
  });

/**
 * Creates an action for opening manually add bank account side panel
 * @param sidePanelState
 * @param {string} sidePanelState.formContext
 * @param {string|number} sidePanelState.partnershipId
 * @return {ReduxAction}
 */
export const openManuallyAddBankAccountSidePanel = (sidePanelState) =>
  openSidePanel({
    name: sidePanelNameManuallyAddBankAccount,
    state: sidePanelState,
  });

/**
 * Creates an action for opening add vendor registered address side panel
 * @param sidePanelState
 * @param {string} sidePanelState.partnershipId
 * @param {CompanyAddressSideSheetAction} sidePanelState.action
 * @return {ReduxAction}
 */

export const openAddVendorRegisteredAddressSidePanel = (sidePanelState) =>
  openSidePanel({
    name: sidePanelNameAddOrUpdateVendorRegisteredAddress,
    state: sidePanelState,
  });

/**
 * Creates an action for opening add vendor registered address side panel for
 * the Create Item form
 * @returns {ReduxAction}
 */
export const openAddVendorRegisteredAddressForCreateItemSidePanel = () =>
  openSidePanel({
    name: sidePanelNameAddVendorRegisteredAddressInForm,
    state: {
      form: formNamesItem.CREATE_ITEM,
    },
  });

/**
 * Creates an action for opening add vendor registered address side panel for
 * the Create Partnership form
 * @returns {ReduxAction}
 */
export const openAddVendorRegisteredAddressForCreatePartnershipSidePanel = () =>
  openSidePanel({
    name: sidePanelNameAddVendorRegisteredAddressInForm,
    state: {
      form: formNamesPartnership.CREATE,
    },
  });

/**
 * Creates an action for opening edit company general info side panel
 * @param {string} partnershipId
 * @returns {ReduxAction}
 */
export const openEditCompanyGeneralInfoSidePanel = (partnershipId) =>
  openSidePanel({
    name: sidePanelNameEditCompanyGeneralInfo,
    state: {
      partnershipId,
    },
  });

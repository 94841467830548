import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const ListItemValue = ({ className, children, style }) => (
  <div
    className={classNames({
      'font-color--greyXDark': true,
      'font-xs': true,
      'remove-margin-bottom': !className || !className.includes('margin-bottom'),
      [className]: !!className,
    })}
    style={style}
  >
    {children}
  </div>
);

ListItemValue.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.shape(),
};

ListItemValue.defaultProps = {
  className: undefined,
  children: undefined,
  style: {},
};

export default ListItemValue;

import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { IconNames, SelectFieldV2 } from 'components';
import CannotUpdateFieldHint from 'components/hintTypes/CannotUpdateFieldHint';
import { TooltipMUI } from 'components/tooltip';

import { dwollaBizCategories, getDwollaBizClassifications } from 'constants/dwolla';
import { colors } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';

import { requiredValidator } from 'helpers/fieldValidation';
import { sortObjectArray } from 'helpers/sort';
import { hasZeroLength } from 'helpers/utility';

import { containerWidthMap } from 'modules/signup-v3/constants/container';

import {
  currentCompanyVerificationUpdateAllowedSelector,
  isUpdatingCurrentCompanySelector,
} from 'selectors/currentCompanySelectors';
import { signUpFlowFormSelector } from 'selectors/signupSelectors';

import { mapViewToFields } from '../fields';
import FlowStepperMain from '../FlowStepperMain';
import { viewSteps } from '../helpers/viewSteps';

import { Container, FieldContainer, TooltipContainer, InputIcon } from './BusinessCategory.styles';

const BusinessField = ({ name, id, companyCategory, isDisabled }) => {
  const businessFieldProps = {
    businessCategory: {
      options: sortObjectArray(dwollaBizCategories, 'text'),
      label: 'Category',
      disabled: false,
    },
    businessClassification: {
      options: sortObjectArray(getDwollaBizClassifications(companyCategory), 'text'),
      label: 'Classification',
      disabled: hasZeroLength(getDwollaBizClassifications(companyCategory)),
    },
  };
  return (
    <FieldContainer disabled={businessFieldProps[id].disabled}>
      <Field
        component={SelectFieldV2}
        dataTestId={businessFieldProps[id].label}
        isDisabled={businessFieldProps[id].disabled || isDisabled}
        isLocked={isDisabled}
        isRequired
        label={businessFieldProps[id].label}
        name={name}
        options={businessFieldProps[id].options}
        type="text"
        validate={requiredValidator}
      />
      {businessFieldProps[id].disabled && (
        <TooltipContainer>
          <TooltipMUI
            arrow
            padding={TooltipPadding.SKINNY}
            placement={TooltipPlacement.RIGHT}
            title="The classification options depend on the category you selected above. Select a category first."
          >
            <InputIcon color={colors.colorGreyDarkHex} icon={IconNames.LOCK} size={14} />
          </TooltipMUI>
        </TooltipContainer>
      )}
    </FieldContainer>
  );
};

export const BusinessCategory = ({ activeView, companyCategory, invalid }) => {
  const updatedAllowed = useSelector(currentCompanyVerificationUpdateAllowedSelector);
  const isUpdating = useSelector(isUpdatingCurrentCompanySelector);
  return (
    <FlowStepperMain
      containerWidth={containerWidthMap.large}
      fields={
        <Container>
          {!updatedAllowed && <CannotUpdateFieldHint />}
          {mapViewToFields[activeView]?.map(({ name, ...props }) => (
            <BusinessField
              companyCategory={companyCategory}
              name={name}
              {...props}
              isDisabled={!updatedAllowed}
              key={name}
            />
          ))}
        </Container>
      }
      invalid={invalid || !updatedAllowed}
      onBack={viewSteps[activeView].previous}
      onNext={{
        ...viewSteps[activeView].next,
        isLoading: isUpdating,
      }}
      onSkip={updatedAllowed && viewSteps[activeView].skip}
      subTitle={{
        content: 'If you can’t find a perfect match, select the option that describes your business best.',
      }}
      title={{ content: 'What’s your business category and classification?' }}
    />
  );
};

const mapStateToProps = (state) => ({
  companyCategory: signUpFlowFormSelector(state, 'info').dwollaBusinessCategory,
});

export default connect(mapStateToProps)(BusinessCategory);

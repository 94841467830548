import { z } from 'zod';
export const upsertRoleModel = z.object({
    name: z
        .string()
        .trim()
        .min(1, { message: 'Role name is required.' })
        .max(64, { message: 'Ensure this field has no more than 64 characters.' }),
    description: z.string().trim().default(''),
    baseRole: z.string(),
    permissions: z.array(z.string()),
    id: z.string().uuid().nullish(),
});

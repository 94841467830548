import PropTypes from 'prop-types';
import React from 'react';

import { EmailAssociatedWithOtherCompaniesHint, ReduxFormFieldWrapper } from 'components';
import { reduxFormInputPropType } from 'components/commonProps';

import { hasLength } from 'helpers/utility';

/**
 * If entering a contact email address that is already used with other partner
 * companies, this will render the UI for confirming that this is known, and that we should
 * proceed with adding the contact.
 * Intended to be passed as `component` prop to a redux-form Field.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 */
const ContactInfoEmailAssociatedWithCompaniesHint = ({ associatedCompanies, className, input, ...rest }) => (
  <ReduxFormFieldWrapper
    {...rest}
    associatedCompanies={associatedCompanies}
    className={className}
    Component={EmailAssociatedWithOtherCompaniesHint}
    input={input}
    isChecked={hasLength(input.value)}
  />
);

ContactInfoEmailAssociatedWithCompaniesHint.propTypes = {
  associatedCompanies: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  className: PropTypes.string,
  input: reduxFormInputPropType,
};

ContactInfoEmailAssociatedWithCompaniesHint.defaultProps = {
  className: undefined,
  input: undefined,
};

export default ContactInfoEmailAssociatedWithCompaniesHint;

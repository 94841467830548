import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components';

import { typography } from 'constants/styles';

/**
 * Component rendering simple text field in External Items table
 * @param {ComponentProps} props
 * @param {string} props.value
 * @returns {StatelessComponent}
 */
const ExternalItemsListTableText = ({ value, color }) => (
  <Text.Regular color={color} size={typography.TextSize.LEVEL_100}>
    {value}
  </Text.Regular>
);

ExternalItemsListTableText.propTypes = {
  color: PropTypes.string,
  value: PropTypes.string.isRequired,
};

ExternalItemsListTableText.defaultProps = {
  color: typography.TextColor.BLUE_DARK,
};

export default ExternalItemsListTableText;

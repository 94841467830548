import { createLastSubmittedReducer } from 'store/redux';

import { CLEAR_FUNDING_ACCOUNT_ADDRESS_MANUAL, SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_SUCCESS } from 'types/funding';

export const requestCases = [CLEAR_FUNDING_ACCOUNT_ADDRESS_MANUAL];
export const successCases = [SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_SUCCESS];

const lastSubmittedReducer = createLastSubmittedReducer(successCases, requestCases);

export default lastSubmittedReducer;

import { createIsFetchingReducer } from 'store/redux';

import {
  FETCH_ADDRESS_FAILURE,
  FETCH_ADDRESSES_FAILURE,
  FETCH_ADDRESSES_REQUEST,
  FETCH_ADDRESSES_SUCCESS,
  FETCH_ADDRESS_REQUEST,
  FETCH_ADDRESS_SUCCESS,
} from 'types/addresses';

export const finishCases = [
  FETCH_ADDRESS_FAILURE,
  FETCH_ADDRESSES_FAILURE,
  FETCH_ADDRESSES_SUCCESS,
  FETCH_ADDRESS_SUCCESS,
];
export const requestCases = [FETCH_ADDRESSES_REQUEST, FETCH_ADDRESS_REQUEST];

const fetchReducer = createIsFetchingReducer(finishCases, requestCases);

export default fetchReducer;

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { currentCompanyMembersWithRoleSelector } from 'selectors/currentCompanySelectors';
import { pendingMembershipInvitesSelector } from 'selectors/membershipInvitesSelectors';
import ViewTeamMembersModal from './ViewTeamMembersModal';
const mapStateToProps = createStructuredSelector({
    currentCompanyMembers: currentCompanyMembersWithRoleSelector,
    pendingMembershipInvites: pendingMembershipInvitesSelector,
});
export default connect(mapStateToProps)(ViewTeamMembersModal);

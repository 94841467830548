import React from 'react';
import { ApprovalRequiredTooltipIcon } from 'components/tooltip/TooltipIcon';
import { isRequiredApprovalIconShown } from 'modules/dashboard/createItems/invoiceListTable/helpers/table';
import InvoiceListText from '../InvoiceListText';
const InvoiceListNumber = ({ approvalLevels, rowData, submittedItem, ...rest }) => {
    const isIconShown = isRequiredApprovalIconShown(rowData, {
        approvalLevels,
        submittedItem,
    });
    return (React.createElement(InvoiceListText, { ...rest, itemURL: rowData.ledgerLink, rowData: rowData }, isIconShown && React.createElement(ApprovalRequiredTooltipIcon, null)));
};
export default InvoiceListNumber;

import { createIsFetchingReducer } from 'store/redux';

import { GET_CURRENT_USER_FAILURE, GET_CURRENT_USER_REQUEST, GET_CURRENT_USER_SUCCESS } from 'types/user';

export const finishCases = [GET_CURRENT_USER_FAILURE, GET_CURRENT_USER_SUCCESS];
export const requestCases = [GET_CURRENT_USER_REQUEST];

const fetchReducer = createIsFetchingReducer(finishCases, requestCases);

export default fetchReducer;

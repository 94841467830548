import _set from 'lodash/set';
import React from 'react';

import {
  addDisabledUserError,
  addNotFoundError,
  handleRequestErrors,
  setFormErrors,
  showServerErrorAlert,
  showTimeoutErrorAlert,
} from 'actions/errors';
import { ledgerDisconnected } from 'actions/ledger';
import { handleMaintenanceMode } from 'actions/maintenance';
import { pushHistory } from 'actions/navigation';
import { handleOldVersionMode } from 'actions/oldVersion';

import { BouncedEmailError, defaultAlertErrors } from 'components/error';

import { ErrorTypes, GENERIC_ERROR, UnauthorizedErrors } from 'constants/error';
import { FieldsWithAuxiliaryErrors } from 'constants/formFields';
import {
  HTML_ELEMENT_STYLE_ATTRIBUTE_REGEX,
  HTML_SCRIPT_CONTENT_REGEX,
  HTML_STYLE_CONTENT_REGEX,
} from 'constants/regex';
import { LOGIN, NOT_FOUND } from 'constants/routes';

import * as baseHelpers from 'helpers/baseErrorHelpers';
import { querySelector, scrollToElement } from 'helpers/dom';
import { isNodeEnvTest } from 'helpers/env';
import { isExternalPathAny } from 'helpers/external';
import { isResponseErrorCodeTimeout } from 'helpers/http';
import { handleClearLocalStorage } from 'helpers/localStorage';
import { flattenObjectForErrors } from 'helpers/objects';
import redirectTo from 'helpers/redirectLogic';
import { getJoinedPath } from 'helpers/routeHelpers';
import { capitalize, dashTextToCamelCase } from 'helpers/stringHelpers';
import { showSwal } from 'helpers/swal';
import {
  allKeys,
  and,
  firstValue,
  hasLength,
  hasZeroLength,
  intersection,
  isArray,
  isEqual,
  isString,
} from 'helpers/utility';

import { FetchService } from 'services';
import { BASE_WEBSITE_URL } from 'services/api';
import { payloadToCamelCase } from 'services/api/formatHelpers';
import { toaster } from 'services/toaster';

// object imported for test-mocks, and re-exported as-is
export const { buildServerErrorAlert, buildServerErrorAlertV2, buildTimeoutErrorAlert } = baseHelpers;

/**
 * Removes injected code, style and HTML tags from string
 * @param {String} str
 * @returns {String}
 */
export const removeJavascriptAndCssFromString = (str) =>
  str
    .replace(HTML_SCRIPT_CONTENT_REGEX, '')
    .replace(HTML_STYLE_CONTENT_REGEX, '')
    .replace(HTML_ELEMENT_STYLE_ATTRIBUTE_REGEX, '');

/**
 * File errors have nested fields (e.g. file, fileType), which are all array of strings.
 * We need to flatten the errors to an array.
 * @param {Object} rawErrors
 * @return {Object}
 */
export const flattenFileErrors = (rawErrors) => {
  if (!rawErrors) {
    return undefined;
  }

  const errArrayReducer = (accumulator, currentValue) => accumulator.concat(currentValue);
  const errPositionReducer = (accumulator, currentPosition) => {
    // When a file is removed, the errors are cleared and so this might be missing
    const currentError = rawErrors[currentPosition];
    if (!currentError) {
      return accumulator;
    }

    return {
      ...accumulator,
      [currentPosition]: Object.values(currentError).reduce(errArrayReducer, []),
    };
  };

  return Object.keys(rawErrors).reduce(errPositionReducer, {});
};

/**
 * File errors have nested fields (e.g. file, fileType), which are all array of strings.
 * We need to flatten the errors to an array.
 * @param {Object} rawErrors
 * @return {Object}
 */
export const flattenNestedErrors = (rawErrors) => {
  if (!rawErrors) {
    return undefined;
  }

  if (isArray(rawErrors)) {
    return rawErrors;
  }

  const nestedErrorReducer = (accumulator, currentKey) => {
    const currentValue = rawErrors[currentKey].join(', ');
    return accumulator.concat(`${capitalize(currentKey)}: ${currentValue.toLowerCase()}`);
  };

  return Object.keys(rawErrors).reduce(nestedErrorReducer, []);
};

export const getFieldErrors = (errors, fieldName) => {
  if (!fieldName || !errors) {
    return null;
  }

  const camelCasedErrors = payloadToCamelCase(errors);
  const camelCasedName = dashTextToCamelCase(fieldName);

  // Check for errors in the given field name
  if (camelCasedErrors[camelCasedName]?.length > 0) {
    return camelCasedErrors[camelCasedName];
  }

  // Check for errors in nested fields e.g.:
  // fieldName = 'item.purposeCode'
  // error = { item: { purposeCode: ['Invalid code'] } t}
  const errorPath = camelCasedName.split('.');
  if (errorPath?.length > 1) {
    return errorPath.reduce((currentErrorLevel, path) => currentErrorLevel?.[path], camelCasedErrors);
  }

  return null;
};

/**
 *
 * @param {object} response
 * @param {function} dispatch
 * @param {boolean} muteAlerts
 * Expect an object with nested errors key
 * The following error types are handled:
 * - unauthorized (401)
 * - not_found (404)
 * - critical (500)
 * - general (400)
 * - ledger (400) - ledger specific errors
 * - validation (400) - to be replaced with general or fields where appropriate
 * - fields (400) - form validation errors
 */
export const parseServerErrors = (response, dispatch = () => {}, muteAlerts = false) => {
  let alertErrors = [];
  let errorsPayload = {};

  // noinspection UnusedCatchParameterJS
  try {
    errorsPayload = payloadToCamelCase(response.originalData);
  } catch (notUsedError) {
    // Ignoring the error intentionally, alertErrors is already set to the default
  }

  if (response.status === 413) {
    // Request payload size is too large
    alertErrors.push('The attachment(s) you selected are too large.');
  } else if (!errorsPayload.errors) {
    // Add default alert errors if error payload is not present
    alertErrors = defaultAlertErrors;
  } else {
    // Iterate over errors payload and add errors by type
    Object.keys(errorsPayload.errors).forEach((errorType) => {
      const currentTypeErrors = errorsPayload.errors[errorType];

      switch (errorType) {
        case ErrorTypes.FIELDS:
          // We do nothing with field errors - handled by the corresponding form
          break;

        case ErrorTypes.LEDGER_DISCONNECTED:
          // Dispatch a redux action to disconnect the ledger
          dispatch(ledgerDisconnected());
          break;

        case ErrorTypes.UNAUTHORIZED:
          // No error returned
          handleClearLocalStorage();
          if (!isNodeEnvTest()) {
            window.location.assign(redirectTo(window, `${BASE_WEBSITE_URL}/${LOGIN}`));
          }
          break;

        default:
        case ErrorTypes.CRITICAL:
        case ErrorTypes.GENERAL:
        case ErrorTypes.LEDGER:
        case ErrorTypes.NOT_FOUND:
        case ErrorTypes.VALIDATION:
          // These are errors that requires alerting the user
          if (currentTypeErrors?.resolution) {
            alertErrors.push(currentTypeErrors);
          } else if (isArray(currentTypeErrors.detail)) {
            const detailErrors = currentTypeErrors.detail.map(removeJavascriptAndCssFromString);
            alertErrors = alertErrors.concat(detailErrors);
          } else if (isString(currentTypeErrors.detail)) {
            alertErrors.push(removeJavascriptAndCssFromString(currentTypeErrors.detail));
          } else {
            alertErrors = defaultAlertErrors;
          }
          break;
      }
    });
  }

  // Show SweetAlert
  if (!muteAlerts && alertErrors.length > 0) {
    baseHelpers.buildServerErrorAlert(alertErrors, response);
  }

  return errorsPayload.errors || {};
};

export const getRequestErrorAction = (errors) => {
  if (errors.fields) {
    return setFormErrors;
  }

  return handleRequestErrors;
};

export const isFormSubmissionError = (response) => Boolean(response.errors && response.errors.fields);

/**
 * Returns an object whose `fields` key points to a camel-cased errors object,
 * with nesting that matches the nesting in the form's state, dictated
 * by `formPath`. If no formPath, `fields` points directly to the field errors.
 * @param {Object} response
 * @param {StringMaybe} formPath
 * @return {{ fields: Object }}
 */
export const parseFormSubmissionError = (response, formPath = undefined) => {
  const errs = payloadToCamelCase(response.errors.fields);

  let fieldErrs = {};

  if (formPath) {
    _set(fieldErrs, formPath, errs);
  } else {
    fieldErrs = errs;
  }

  return { fields: fieldErrs };
};

/**
 * Returns an object containing the camel-cased response errors as `parsedErrors`,
 * and the request id as `requestId`.
 * @param {Object} response
 * @return {{ parsedErrors: ObjectMaybe, requestId: StringMaybe }}
 */
export const parseErrorDataFromResponse = (response) => {
  let parsedErrors;
  let requestId;

  try {
    requestId = response.headers['request-id'];
    parsedErrors = payloadToCamelCase(response.originalData);
  } catch (err) {
    // ignoring the error intentionally
  }

  return { parsedErrors, requestId };
};

/**
 * Returns the parsed response errors as either `fields` or `parsedErrors`, depending
 * on whether this is the result of a form submission or not. If parsedErrors are returned,
 * also returns an options property, used for direction by the shared error handler.
 * @param {Object} response
 * @param {Object} options
 * @param {StringMaybe} [options.formPath]
 * @param {Boolean} [options.report]
 * @param {Boolean} [options.muteAlerts]
 * @return {{ parsedErrors: ObjectMaybe, options: Object }|{ fields: Object }}
 */
export const parseErrorResponse = (response, options = {}) => {
  const { formPath, ...setOptions } = options;

  if (isFormSubmissionError(response)) {
    return parseFormSubmissionError(response, formPath);
  }

  const { parsedErrors, requestId } = parseErrorDataFromResponse(response);

  return {
    options: {
      isTimeout: isResponseErrorCodeTimeout(response.code),
      report: false,
      requestId,
      status: response.status,
      ...setOptions,
    },
    parsedErrors,
  };
};

export const parseCaughtError = (error, options = {}) => ({
  options: {
    report: true,
    ...options,
  },
  parsedErrors: {
    name: error.name,
    message: error.message,
  },
});

/**
 * Returns parsed server errors with array of additional actions that we want to execute
 * based on the type of the error.
 * @param {Object} parsedErrors
 * @param {Object} [options={}]
 * @returns {Object}
 */
export const parseServerErrorsV2 = (parsedErrors, options = {}) => {
  const { isTimeout, requestId, muteAlerts = false, status } = options;

  let shouldMuteAlerts = !!muteAlerts;

  let alertErrors = [];
  let generalErrors;
  let forbiddenErrors;

  const additionalActions = [];

  if (isTimeout) {
    additionalActions.push(showTimeoutErrorAlert({ requestId, options }));
  } else if (!parsedErrors?.errors) {
    // Add default alert errors if error payload is not present (unless it's a rejection via timeout)
    alertErrors = [GENERIC_ERROR];
  } else if (typeof parsedErrors.errors === 'string') {
    alertErrors = [parsedErrors.errors];
  } else {
    // Iterate over errors payload and add errors by type
    allKeys(parsedErrors.errors).forEach((errorType) => {
      const currentTypeErrors = parsedErrors.errors[errorType];

      switch (errorType) {
        case ErrorTypes.FIELDS:
          // We do nothing with field errors - handled by the corresponding form
          break;

        case ErrorTypes.LEDGER_DISCONNECTED:
          // Action to update the ledger state to disconnected
          additionalActions.push(ledgerDisconnected());
          break;

        case ErrorTypes.UNAUTHORIZED:
          if (isEqual(currentTypeErrors.detail, UnauthorizedErrors.DISABLED_USER)) {
            // we need to maintain localStorage values in this case,
            // so we can still see the disabled user messages going forward
            additionalActions.push(addDisabledUserError());
          } else if (isExternalPathAny()) {
            // external user, send then to the 404 for now
            handleClearLocalStorage();
            additionalActions.push(pushHistory(getJoinedPath(NOT_FOUND)));
          } else {
            handleClearLocalStorage();
            additionalActions.push(pushHistory(getJoinedPath(LOGIN)));
          }
          break;

        default:
        case ErrorTypes.CRITICAL:
        case ErrorTypes.GENERAL:
        case ErrorTypes.LEDGER:
        case ErrorTypes.NOT_FOUND:
        case ErrorTypes.VALIDATION:
          // These are errors that requires alerting the user
          if (currentTypeErrors?.resolution) {
            alertErrors.push(currentTypeErrors);
          } else if (isArray(currentTypeErrors.detail)) {
            const detailErrors = currentTypeErrors.detail.map(removeJavascriptAndCssFromString);
            alertErrors = alertErrors.concat(detailErrors);
          } else if (isString(currentTypeErrors.detail)) {
            alertErrors.push(removeJavascriptAndCssFromString(currentTypeErrors.detail));
          } else {
            alertErrors = [GENERIC_ERROR];
          }
          break;
      }
    });

    if (parsedErrors.errors.forbidden) {
      forbiddenErrors = parsedErrors.errors.forbidden;
    }

    if (parsedErrors.errors.general) {
      generalErrors = parsedErrors.errors.general;
    }
  }

  if (FetchService.isResponseNotFound({ status })) {
    shouldMuteAlerts = true;
    additionalActions.push(addNotFoundError());
  }

  if (FetchService.isResponseMaintenanceMode({ status })) {
    shouldMuteAlerts = true;
    additionalActions.push(handleMaintenanceMode());
  }

  if (FetchService.isResponseOldVersionMode({ status })) {
    shouldMuteAlerts = true;
    additionalActions.push(handleOldVersionMode());
  }

  // push action to show the sweet-alert, if alerts have not been muted
  if (and(requestId, !shouldMuteAlerts, hasLength(alertErrors))) {
    additionalActions.push(showServerErrorAlert(alertErrors, requestId));
  }

  return {
    additionalActions,
    forbiddenErrors,
    generalErrors,
  };
};

/**
 * Method to show a form failure toast
 */
export const showFormFailureToast = (formName, options = {}) => {
  const { title = 'We found a problem', message = 'Please correct all errors to continue', ...restOptions } = options;

  toaster.danger(title, {
    description: message,
    id: formName,
    ...restOptions,
  });
};

/**
 * We may display a more specific error message in the toast. this is solely for
 * the case where both: 1) some field fails validation, and 2) the field that failed
 * validation is an atypical field that cannot display inline errors.
 * This returns the toast error message if that is the case.
 * @param {Object} flattenedErrors
 * @return {{ message: StringMaybe }}
 */
export const getSubmitFailToastOptions = (flattenedErrors) => {
  const errorFields = intersection(allKeys(flattenedErrors), FieldsWithAuxiliaryErrors);

  if (hasLength(errorFields)) {
    return {
      message: firstValue(flattenedErrors[firstValue(errorFields)]),
    };
  }

  return {};
};

/**
 * Method to scroll to errors
 * @param errors
 * @param dispatch
 * @param submitError
 * @param props
 */
export const onSubmitFailReduxForm = (errors, dispatch, submitError, props) => {
  const { form } = props;

  const flattenedErrors = flattenObjectForErrors(errors);
  const flattenedErrorFields = allKeys(flattenedErrors);

  // Break if no errors
  if (hasZeroLength(flattenedErrorFields)) {
    return;
  }

  for (let i = 0; i < flattenedErrorFields.length; i += 1) {
    // if we find an element with an errored field name, scroll to it
    const element = querySelector(`form [name="${flattenedErrorFields[i]}"]`);
    if (element) {
      scrollToElement(element);
      break;
    }
  }

  const failureToastOptions = getSubmitFailToastOptions(flattenedErrors);

  showFormFailureToast(form, failureToastOptions);
};

/**
 * showBouncedEmailErrorSWAL
 * Creates and adds the bounced email error SWAL to the DOM
 * @param {Object} props
 */
export const showBouncedEmailErrorSWAL = (props) => {
  showSwal({
    Content: <BouncedEmailError {...props} />,
  });
};

/**
 * @module sagas/createItem/sagas
 */
import { queryClient } from '@routable/shared';
import { getDynamicTableRecommendedFieldWarnings } from '@routable/tablematic';
import * as Sentry from '@sentry/react';
import { SubmissionError, stopSubmit, change } from 'redux-form';
import { all, call, delay, fork, put, select, spawn, take, takeLatest } from 'redux-saga/effects';

import { generateKeys } from 'hooks/useOutstandingItems/queryKeys.service';

import { earEvaluatedItemPayload } from 'actions/item';
import { approvalsEvaluateItemRoutine } from 'actions/routines/approvals';
import * as routines from 'actions/routines/item';

import { itemFormFields } from 'constants/formFields';
import { formNamesItem } from 'constants/forms';

import { getRequestErrorAction, parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { trackEvent, TrackEventName, TrackPageName } from 'helpers/eventTracking';
import { isSelectedPartnerTypeNew } from 'helpers/searchCompanies';
import { asArrayElement, isEmptyObject } from 'helpers/utility';

import { formUIIsItemEditSelector } from 'queries/createItemFormSelectors';
import { createItemsTableViewModelManagerForKindSelector } from 'queries/tableCompoundSelectors';

import { createItemFormAllValuesSelector, createItemFormInitialValuesSelector } from 'selectors/forms';
import { ledgerIntegrationSelector } from 'selectors/integrationsSelectors';

import { FetchService } from 'services';

import * as sharedTasks from '../shared';

import * as api from './api';
import * as editHelpers from './edit';
import * as helpers from './helpers';
import * as fx from './sideEffects';
import * as tasks from './tasks';

/**
 * Handles sending the request for multiple invoices submission.
 * At this point, data is already fully transformed and neatly
 * packaged, so we only need to handle the request and response.
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* sendSubmitMultipleInvoicesRequest(action) {
  let submitErrors = {};

  yield put(routines.submitInvoicesRoutine.request());

  try {
    const { payload: params } = action;

    const response = yield call(api.submitMultipleInvoices, params);

    if (response.ok) {
      // Invalidate data to trigger re-fetch of outstanding items
      queryClient.invalidateQueries(generateKeys.list());

      // this is a timed-out request - show error swal and disable submitting
      if (FetchService.isResponseAccepted(response)) {
        return yield call(tasks.handleItemSubmitTimeout, params.kind);
      }

      // success
      return yield all([
        // the backend returns 204, but we need the ledger refs in the reducer, so we
        // just include the provided meta in our success action
        put(routines.submitInvoicesRoutine.success(response.data, params.meta)),
        put(routines.submitItemRoutine.fulfill(response.data)),
      ]);
    }

    submitErrors = parseErrorResponse(response, { itemKind: params.kind });
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(submitErrors);
  return yield all([
    put(errorAction(routines.submitInvoicesRoutine.failure, submitErrors)),
    put(errorAction(routines.submitItemRoutine.failure, submitErrors)),
  ]);
}

/**
 * Handles sending the request for single invoice submission.
 * At this point, data is already fully transformed and neatly
 * packaged, so we only need to handle the request and response.
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* sendSubmitInvoiceRequest(action) {
  let submitErrors = {};

  yield put(routines.submitInvoicesRoutine.request());

  try {
    const { payload: params } = action;

    const response = yield call(api.submitInvoice, params);

    if (response.ok) {
      // Invalidate data to trigger re-fetch of outstanding items
      queryClient.invalidateQueries(generateKeys.list());

      // this is a timed-out request - show error swal and disable submitting
      if (FetchService.isResponseAccepted(response)) {
        return yield call(tasks.handleItemSubmitTimeout, params.kind);
      }

      // success
      return yield all([
        put(
          routines.submitInvoicesRoutine.success(response.data, {
            // pull out the singular ledger ref, so we can include it in the reducer
            // but array-ified (thus keeping logic in the reducer at a minimum)
            ledgerRefs: asArrayElement(params.meta.ledgerRef),
          }),
        ),
        put(routines.submitItemRoutine.fulfill(response.data)),
      ]);
    }

    submitErrors = parseErrorResponse(response, { itemKind: params.kind });
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(submitErrors);
  return yield all([
    put(errorAction(routines.submitInvoicesRoutine.failure, submitErrors)),
    put(errorAction(routines.submitItemRoutine.failure, submitErrors)),
  ]);
}

/**
 * Handles sending the request to create an item from a ledger import.
 * At this point, data is already fully transformed and neatly
 * packaged, so we only need to handle the request and response.
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* sendSubmitBillToItemRequest(action) {
  let submitErrors = {};

  yield put(routines.sendSubmitBillToItemRequestRoutine.request());

  try {
    const {
      payload: { viewModelManager, ...params },
    } = action;

    const { payload: item } = params;

    const response = yield call(api.submitBillToItem, params);

    if (response.ok) {
      // this is a timed-out request - show error swal and disable submitting
      if (FetchService.isResponseAccepted(response)) {
        return yield call(tasks.handleItemSubmitTimeout, item.kind);
      }

      // success
      return yield all([
        put(routines.sendSubmitBillToItemRequestRoutine.success(response.data)),
        put(routines.submitItemRoutine.fulfill(response.data)),
      ]);
    }

    submitErrors = parseErrorResponse(response, {
      // when this is a PATCH call, the `kind` property will already be packaged inside a payload object
      itemKind: params.payload.kind,
    });

    const { fields: fieldErrors, ...restErrors } = submitErrors;

    const { dynamicErrors, otherErrors } = helpers.getErrorsForDynamicAndStaticFields({
      fieldErrors,
      fieldIdMap: viewModelManager.parser.fieldKeyToIdPathMap,
      lineItems: item.lineItems,
    });

    const allErrors = {
      ...restErrors,
      fields: {
        dynamicErrors,
        otherErrors,
      },
    };

    const fieldErrorActions = fx.getActionsForSubmitItemFieldErrors(params.payload, allErrors);

    if (fieldErrorActions) {
      yield all(fieldErrorActions.map((act) => put(act)));
    }

    fx.maybeShowUnsupportedFileTypeErrorSwal({ submitErrors });
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(submitErrors);
  return yield all([
    put(errorAction(routines.sendSubmitBillToItemRequestRoutine.failure, submitErrors)),
    put(errorAction(routines.submitItemRoutine.failure, submitErrors)),
  ]);
}

/**
 * Handles sending the request to create a single new item.
 * At this point, data is already fully transformed and neatly
 * packaged, so we only need to handle the request and response.
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* sendSubmitItemRequest(action) {
  let submitErrors = {};

  yield put(routines.sendSubmitItemRequestRoutine.request());

  try {
    const {
      payload: { isItemEdit, viewModelManager, fullItem, ui, ...params },
    } = action;
    const { payload: item } = params;
    const itemKind = fullItem?.kind || item.kind;
    let response;

    if (isItemEdit) {
      response = yield call(editHelpers.submitItemEdit, action);
    } else {
      response = yield call(api.submitItem, params);
    }

    if (response.ok) {
      // this is a timed-out request - show error swal and disable submitting
      if (FetchService.isResponseAccepted(response)) {
        return yield call(tasks.handleItemSubmitTimeout, itemKind);
      }
      const ledger = yield select(ledgerIntegrationSelector);

      const itemTypes = {
        payable: TrackPageName.PAYABLE,
        receivable: TrackPageName.RECEIVABLE,
        bill: TrackPageName.BILL,
      };
      const lineItemsLength = item?.lineItems?.flat()?.length;
      trackEvent(TrackEventName.CODING_TIME_FORM_SUBMITTED, {
        page: ui?.isBillView ? itemTypes.bill : itemTypes[item?.kind],
        isItemEdit,
        ledger: ledger?.name,
        numberOfLines: lineItemsLength,
      });
      return yield put(routines.submitItemRoutine.fulfill(response.data));
    }

    // this request can trigger different types of error UX:
    // -  fields errors that result in an error message being showed next to a specific field
    // -  field errors that result in higher level error alerts/SWALs being shown

    // first, parse the API error object, and attach the item kind as options data
    submitErrors = parseErrorResponse(response, { itemKind });

    const { fields: fieldErrors, ...restErrors } = submitErrors;

    const { dynamicErrors, otherErrors } = helpers.getErrorsForDynamicAndStaticFields({
      fieldErrors,
      fieldIdMap: viewModelManager.parser.fieldKeyToIdPathMap,
      lineItems: fullItem.lineItems,
    });

    const allErrors = {
      ...restErrors,
      fields: {
        dynamicErrors,
        otherErrors,
      },
    };

    const fieldErrorActions = fx.getActionsForSubmitItemFieldErrors(fullItem, allErrors);

    if (fieldErrorActions) {
      yield all(fieldErrorActions.map((act) => put(act)));
    }

    yield call(helpers.getActionsForHigherLevelFieldErrors, {
      submitErrors,
      item: fullItem,
    });
  } catch (error) {
    Sentry.captureException(error);
    submitErrors = parseCaughtError(error);
  }

  // handle field errors
  const errorAction = getRequestErrorAction(submitErrors);
  return yield put(errorAction(routines.submitItemRoutine.failure, submitErrors));
}

/**
 * First handler for submission of the create items form.
 * Its primary job is to determine whether to actually kick off the item submission
 * flow, or whether we first need to submit a new partnership, or handle confirmation, etc.
 *
 * If entering item submission flow, determines (via a side-effects helper)
 * which flow to trigger (e.g. single item, single invoice, multiple invoice).
 * Its success resolution is dispatched here, but the final result (failure/fulfill)
 * will be dispatched by the final submission handler. This helps with both code flow in this
 * file, and also with the number of intermediate states that exist in CreateItems.
 *
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* submitCreateItems(action) {
  try {
    const { props, values } = action.payload;
    const {
      item,
      ui: { selectedCompany, showInvoiceGenerator, warningsDismissed },
    } = values;
    const { viewModelManager } = props;

    // if the selected partnership doesn't exist, trigger the create partnership flow, then return
    if (isSelectedPartnerTypeNew(selectedCompany?.type)) {
      const createPartnershipAction = yield call(fx.getActionForCreatePartnership, action);
      const createPartnershipTask = yield fork(sharedTasks.submitCreatePartnership, createPartnershipAction);
      const partnershipData = yield createPartnershipTask.toPromise();

      // if the data returned from the cretePartnershipTask has "fields" property
      // attached to it, that means that we are dealing with form errors. In that case
      // we want to manually trigger form submission error and do an early return.
      if (partnershipData?.fields) {
        yield put(routines.submitItemRoutine.failure(new SubmissionError(partnershipData.fields)));
        return;
      }

      const { fundingAccountIds } = yield call(tasks.getSubmitCreateItemsAdditionalData);
      const defaultFundingActions = yield call(fx.getActionsForDefaultFundingAccount, {
        fundingAccountIds,
        partnershipData,
        props,
        values,
      });

      yield all(defaultFundingActions.map((act) => put(act)));

      // Before existing out of the saga, we also want to stop the form submission. Because we
      // a) didn't throw any errors and
      // b) didn't actually submit the createItem form
      // the "submitting" property on the form will remain true. That will prevent us from
      // actually submitting the item further down the road, when we re-enter this saga with
      // partnership already created & trying to create an item
      yield put(stopSubmit(props.form));

      // And now we can exit out of this saga
      return;
    }

    // if we haven't already dismissed warnings, check for any dynamic field warnings at this point
    if (!warningsDismissed) {
      const warnings = getDynamicTableRecommendedFieldWarnings(viewModelManager.parser, values);
      const hasRecommendedFieldWarnings = !isEmptyObject(warnings);

      // confirm any empty fields with recommended field warnings
      if (showInvoiceGenerator && hasRecommendedFieldWarnings) {
        const confirmationResult = yield call(fx.getRecommendedFieldWarningsConfirmation, item, warnings);
        const confirmationActions = yield call(fx.getActionsForWarningFieldsConfirmation, confirmationResult);

        yield all(confirmationActions.map((act) => put(act)));

        if (!confirmationResult) {
          return;
        }
      }
    }

    // we've made sure all prerequisites are complete, so the request is officially starting
    yield put(routines.submitItemRoutine.request());

    // at this point everything checks out, and we just need to get the prepared
    // action object that, when dispatched, will fire off the api request
    const submitRequestActionParams = yield tasks.prepareSubmitRequestParams(action);
    const submitAction = yield call(tasks.getPreparedSubmitRequestAction, submitRequestActionParams);
    yield put(submitAction);

    yield put(routines.submitItemRoutine.success());
  } catch (error) {
    // if we reach this line, a try-catch somewhere threw to this function, and there's
    // a problem in the code itself; this will not contain any errors sent from the API
    const parsedError = parseCaughtError(error);
    const errorAction = getRequestErrorAction(parsedError);
    yield put(errorAction(routines.submitItemRoutine.failure, parsedError));
  }
}

export function* approvalsEvaluateItemSaga({ payload }) {
  // Sometimes tablematic and create item components can fire multiple state updates.
  // To prevent multiple API request we can debounce with the `delay` method.
  yield delay(50);

  try {
    const values = yield select(createItemFormAllValuesSelector);
    const viewModelManager = yield select(createItemsTableViewModelManagerForKindSelector);
    const initialValues = yield select(createItemFormInitialValuesSelector);

    const { rateEstimate } = payload;
    const props = { initialValues, rateEstimate, viewModelManager };
    const action = { payload: { props, values } };

    const submitRequestParams = yield tasks.prepareSubmitRequestParams(action);
    const submitAction = yield call(tasks.getPreparedSubmitRequestAction, submitRequestParams);
    const isItemEdit = yield select(formUIIsItemEditSelector);
    const ledger = yield select(ledgerIntegrationSelector);

    const itemTypes = {
      payable: TrackPageName.PAYABLE,
      receivable: TrackPageName.RECEIVABLE,
      bill: TrackPageName.BILL,
    };
    const hasOCR = !isItemEdit && values?.item?.bills.some((bill) => bill?.latestOcrAnnotation);

    if (values?.partner?.name && hasOCR) {
      trackEvent(TrackEventName.CODING_TIME_OCR_FIELD_CHANGED, {
        page: values?.ui?.isBillView ? itemTypes.bill : itemTypes[values?.item?.kind],
        ledger: ledger?.name,
        isItemEdit,
      });
    }

    // sanity check various flows
    const EvaluateApprovalsConfigMap = {
      [routines.sendSubmitInvoiceRequestRoutine.TRIGGER]: api.getSubmitInvoiceConfig,
      [routines.sendSubmitMultiInvoiceRequestRoutine.TRIGGER]: api.getSubmitMultipleInvoicesConfig,
      [routines.sendSubmitBillToItemRequestRoutine.TRIGGER]: api.getSubmitBillToItemConfig,
      [routines.sendSubmitItemRequestRoutine.TRIGGER]: api.getSubmitItemRequestConfig,
    };

    const getConfig = EvaluateApprovalsConfigMap[submitAction?.type];

    const config =
      getConfig &&
      getConfig({
        ...submitAction.payload,
        // on item edit we want parsedPayload, on createItem we just want the payload
        payload: submitAction.parsedPayload || submitAction.payload.payload,
      });

    if (!config) {
      // Reset create item form hash and approval requirements on partnership change or invalid form data
      yield all([
        put(approvalsEvaluateItemRoutine.failure()),
        put(change(formNamesItem.CREATE_ITEM, itemFormFields.META_APPROVERS_HASH, undefined)),
      ]);
      return;
    }

    yield put(earEvaluatedItemPayload(config.payload.data));

    yield put(approvalsEvaluateItemRoutine.request());
    const response = yield call(api.approvalsEvaluateItem, config);
    const isTimedOut = FetchService.isResponseAccepted(response);

    if (!response.ok || isTimedOut) {
      yield put(approvalsEvaluateItemRoutine.failure());
      return;
    }

    const hash = response?.data?.meta?.hash;
    const { data: approvalLevels, meta } = Object(response.data);

    yield all([
      put(approvalsEvaluateItemRoutine.success({ approvalLevels, meta })),
      put(change(formNamesItem.CREATE_ITEM, itemFormFields.META_APPROVERS_HASH, hash)),
    ]);
  } catch (error) {
    yield put(approvalsEvaluateItemRoutine.failure({ error }));
  }
}

/**
 * Listens for redux actions related to creating items.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  yield takeLatest(approvalsEvaluateItemRoutine.TRIGGER, approvalsEvaluateItemSaga);

  while (true) {
    const action = yield take([
      routines.sendSubmitBillToItemRequestRoutine.TRIGGER,
      routines.sendSubmitInvoiceRequestRoutine.TRIGGER,
      routines.sendSubmitItemRequestRoutine.TRIGGER,
      routines.sendSubmitMultiInvoiceRequestRoutine.TRIGGER,
      routines.submitItemRoutine.TRIGGER,
    ]);

    switch (action.type) {
      case routines.submitItemRoutine.TRIGGER:
        yield spawn(submitCreateItems, action);
        break;

      case routines.sendSubmitBillToItemRequestRoutine.TRIGGER:
        yield spawn(sendSubmitBillToItemRequest, action);
        break;

      case routines.sendSubmitInvoiceRequestRoutine.TRIGGER:
        yield spawn(sendSubmitInvoiceRequest, action);
        break;

      case routines.sendSubmitItemRequestRoutine.TRIGGER:
        yield spawn(sendSubmitItemRequest, action);
        break;

      case routines.sendSubmitMultiInvoiceRequestRoutine.TRIGGER:
        yield spawn(sendSubmitMultipleInvoicesRequest, action);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root create item saga.
 * @return {IterableIterator<*>}
 */
export default function* createItem() {
  yield watch();
}

import { GET_CURRENT_USER_SUCCESS } from 'types/user';

const ssoOnlyReducer = (state = false, action) => {
  switch (action.type) {
    case GET_CURRENT_USER_SUCCESS:
      return action.payload?.meta?.ssoOnly || false;

    default:
      return state;
  }
};

export default ssoOnlyReducer;

import React from 'react';

import { ExistingItem } from 'context';

/**
 * When you want to get the value from ExistingItem, use this simple hook to fetch the context value.
 * @function
 * @returns {object} - When invoked, returns the value from ExistingItem.Provider.
 */
const useExistingItemContext = () => React.useContext(ExistingItem);

export default useExistingItemContext;

import React from 'react';
import { all, call, put, select } from 'redux-saga/effects';
import { handleRequestErrors } from 'actions/errors';
import { resendPartnershipInviteRoutine } from 'actions/routines/partnership';
import { showSuccessUi } from 'actions/ui';
import { PartnershipResendReminderSuccessText } from 'constants/partnership';
import { partnershipMemberSubmitTransformers, partnershipSubmitTransformers } from 'data/submitTransformers';
import { isPartnershipMemberDefaultGeneralAccessSet } from 'helpers/partnershipMembers';
import { showSwal } from 'helpers/swal';
import {} from 'interfaces/partnerships';
import CompanyMenuLinkResendPartnershipInviteSwal from 'modules/dashboard/company/global/presenters/CompanyActionNav/components/CompanyActionMenu/components/CompanyMenuLinkResendPartnershipInvite/components/CompanyMenuLinkResendPartnershipInviteSwal';
import { partnershipMembersFromPartnershipIdSelector } from 'queries/partnershipMemberCompoundSelectors';
import { sendPartnershipInvite } from 'sagas/shared/api';
export const showResendPartnershipInviteSWAL = (partnership) => showSwal({
    Content: React.createElement(CompanyMenuLinkResendPartnershipInviteSwal, { partnership: partnership }),
});
export function* resendPartnershipInviteSendInviteHelper(partnershipId) {
    const partnershipMembers = yield select(partnershipMembersFromPartnershipIdSelector, partnershipId);
    const invitePayload = yield call(partnershipSubmitTransformers.sendPartnershipInviteData, {
        partnershipId,
        partnershipMembers: (partnershipMembers ?? []),
    }, {
        partnershipMemberFilters: [isPartnershipMemberDefaultGeneralAccessSet],
        transformPartnershipMember: partnershipMemberSubmitTransformers.partnershipMemberForSendInvite,
    });
    return yield call(sendPartnershipInvite, partnershipId, invitePayload);
}
export function* resendPartnershipInviteHandleSuccessHelper(invitationResponse, partnershipId) {
    const payloadWithUpdatedPartnership = {
        id: partnershipId,
        partnership: invitationResponse.data?.partnership,
    };
    yield all([
        put(resendPartnershipInviteRoutine.success(payloadWithUpdatedPartnership)),
        put(showSuccessUi(PartnershipResendReminderSuccessText)),
    ]);
    yield put(resendPartnershipInviteRoutine.fulfill(payloadWithUpdatedPartnership));
    return invitationResponse.data;
}
export function* resendPartnershipInviteHandleFailureHelper(payload, noMembershipError, errorData, partnership) {
    yield put(resendPartnershipInviteRoutine.failure(payload));
    if (noMembershipError) {
        yield call(showResendPartnershipInviteSWAL, partnership);
    }
    else {
        yield put(handleRequestErrors(null, errorData));
    }
    yield put(resendPartnershipInviteRoutine.fulfill(payload));
}

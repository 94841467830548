import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/text';

import { ERROR_UNKNOWN_IMAGE } from 'constants/images';
import { TextColor, TextSize } from 'constants/styles/typography';

import { getPaymentOrInvoiceOrInformalText } from 'helpers/items';

import ServerError from '../ServerError';
import ServerErrorDefaultItemTimeoutMessage from '../ServerErrorDefaultItemTimeoutMessage';

/**
 * Renders a ServerError specifically for request timeouts.
 * @param {ComponentProps} props
 * @param {ItemKind} props.itemKind
 * @param {string} props.ledgerName
 * @param {string} props.requestId
 * @return {StatelessComponent}
 */
const ServerTimeoutError = ({ itemKind, ledgerName, requestId }) => (
  <ServerError
    className="padding-top--l"
    errorMessage={<ServerErrorDefaultItemTimeoutMessage itemKind={itemKind} ledgerName={ledgerName} />}
    imageSrc={ERROR_UNKNOWN_IMAGE}
    requestId={requestId}
    title={
      <Text.Bold className="display--block margin-bottom--m" color={TextColor.NAVY} size={TextSize.LEVEL_400}>
        {`It’s taking longer than expected to create this ${getPaymentOrInvoiceOrInformalText({ kind: itemKind })}`}
      </Text.Bold>
    }
  />
);

// TODO: DEV-11870
// https://warrenpay.atlassian.net/browse/DEV-11870
ServerTimeoutError.propTypes = {
  itemKind: PropTypes.string,
  ledgerName: PropTypes.string, // .isRequired, You cannot say required if you don't make it required see line 43 baseErrorHelpers.js
  requestId: PropTypes.string,
};

ServerTimeoutError.defaultProps = {
  itemKind: undefined,
  requestId: undefined,
};

export default ServerTimeoutError;

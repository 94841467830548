import { createIsFetchingReducer } from 'store/redux';

import { FETCH_API_TOKENS_FAILURE, FETCH_API_TOKENS_REQUEST, FETCH_API_TOKENS_SUCCESS } from 'types/apiToken';

export const finishCases = [FETCH_API_TOKENS_FAILURE, FETCH_API_TOKENS_SUCCESS];
export const requestCases = [FETCH_API_TOKENS_REQUEST];

const isFetchingReducer = createIsFetchingReducer(finishCases, requestCases);

export default isFetchingReducer;

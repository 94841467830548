import normalize from 'json-api-normalizer';

import denormalize from 'helpers/denormalizer';
import { isLedgerSettingsContactTypeSingular } from 'helpers/ledger';
import {
  isPartnershipArchived,
  isPartnershipCustomer,
  isPartnershipTypeCustomer,
  isPartnershipTypeVendor,
  isPartnershipVendor,
} from 'helpers/partnerships';
import { flattenDataForSearchCompanies } from 'helpers/searchCompanies';

/**
 * Parse search partnerships response
 * @param {object} json
 * @return {any[]}
 */
export const parseSearchCompaniesResponse = (json) => {
  const normalizedJson = normalize(json);
  const denormalizedJson = {};

  Object.values(normalizedJson.partnership).forEach((partnership) => {
    denormalizedJson[partnership.id] = denormalize(normalizedJson, partnership);
  });

  return Object.values(denormalizedJson).map(flattenDataForSearchCompanies);
};

/**
 * Parse platform ledger matching partnerships response
 * @param {object} json
 * @param {object} ledgerPartnership
 * @return {any[]}
 */
export const parseLedgerMatchesResponse = (json, ledgerPartnership) => {
  const normalizedJsonResponse = normalize(json);
  const options = [];

  Object.values(normalizedJsonResponse.partnership).forEach((partnership) => {
    const currentPartnership = partnership.attributes;

    // Ensure the search results is a valid partnership and has only the other side set
    if (isPartnershipArchived(currentPartnership)) {
      return;
    }

    if (isPartnershipCustomer(ledgerPartnership)) {
      if (currentPartnership.customerLedgerId || !currentPartnership.vendorLedgerId) {
        return;
      }
    }

    if (isPartnershipVendor(ledgerPartnership)) {
      if (!currentPartnership.customerLedgerId || currentPartnership.vendorLedgerId) {
        return;
      }
    }

    // Add to options
    const denormalizedPartnership = denormalize(normalizedJsonResponse, partnership);
    options.push(flattenDataForSearchCompanies(denormalizedPartnership));
  });

  return options;
};

/**
 * Parse ledger only matching partnerships response
 * @param {object} json
 * @param {string} field
 * @param {object} ledger
 * @return {any[]}
 */
export const parseLedgerOnlyMatchesResponse = (json, field, ledger) => {
  const normalizedJsonResponse = normalize(json);
  const denormalizedJsonResponse = {};

  Object.values(normalizedJsonResponse.partnership).forEach((partnership) => {
    // Add if partnership type matches desired search type
    if (isLedgerSettingsContactTypeSingular(ledger.settings)) {
      if (isPartnershipTypeCustomer(field) && !isPartnershipCustomer(partnership.attributes)) {
        return;
      }

      if (isPartnershipTypeVendor(field) && !isPartnershipVendor(partnership.attributes)) {
        return;
      }
    }

    denormalizedJsonResponse[partnership.id] = denormalize(normalizedJsonResponse, partnership);
  });

  return Object.values(denormalizedJsonResponse).map((row) => flattenDataForSearchCompanies(row));
};

/**
 * Parse clearing accounts matching response
 * @param {object} json
 * @return {any[]}
 */
export const parseClearingAccountsMatchesResponse = (json) => {
  const normalizedJsonResponse = normalize(json);
  const denormalizedJsonResponse = {};

  Object.values(normalizedJsonResponse.fundingAccount).forEach((fundingAccount) => {
    denormalizedJsonResponse[fundingAccount.id] = denormalize(normalizedJsonResponse, fundingAccount);
  });

  return Object.values(denormalizedJsonResponse);
};

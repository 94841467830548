import * as routines from 'actions/routines/countries';

import { createIsFetchingReducer } from 'store/redux';

export const finishCases = [routines.fetchCountriesRoutine.FAILURE, routines.fetchCountriesRoutine.SUCCESS];

export const requestCases = [routines.fetchCountriesRoutine.REQUEST];

const isFetchingReducer = createIsFetchingReducer(finishCases, requestCases);

export default isFetchingReducer;

import { routableApi, RoutableTags } from '../routableApi';
const extendedCompanyApi = routableApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        companyForId: builder.query({
            query: ({ id }) => ({
                url: `/companies/${id}/`,
            }),
            providesTags: [RoutableTags.Company],
        }),
    }),
});
export const { useCompanyForIdQuery } = extendedCompanyApi;

import { companyType as companyTypes, governmentIdType } from '@routable/companies-management';
import { Radio } from '@routable/gross-ds';
import _get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import CannotUpdateFieldHint from 'components/hintTypes/CannotUpdateFieldHint';
import { clearFormFields } from 'helpers/formChanges';
import { signupFlowForm } from 'modules/signup-v3/constants';
import { containerWidthMap } from 'modules/signup-v3/constants/container';
import { fields } from 'modules/signup-v3/fields';
import FlowStepperMain from 'modules/signup-v3/FlowStepperMain';
import { viewSteps } from 'modules/signup-v3/helpers/viewSteps';
import { currentCompanyVerificationUpdateAllowedSelector, isUpdatingCurrentCompanySelector, } from 'selectors/currentCompanySelectors';
import { SignupTaxInfoBusinessFields, SignupTaxInfoTin } from './components';
import { STATE_OF_INCORPORATION_OPTIONAL } from './SignupTaxInfo.constants';
const SignupTaxInfo = ({ activeView, invalid }) => {
    const formValues = useSelector(getFormValues(signupFlowForm)) || {};
    const businessTypeValue = _get(formValues, fields.businessType);
    const companyType = _get(formValues, fields.companyType);
    const isUpdating = useSelector(isUpdatingCurrentCompanySelector);
    const businessWithoutEIN = _get(formValues, fields.uiBusinessWithoutEIN);
    const stateOfIncorporationIsOptional = STATE_OF_INCORPORATION_OPTIONAL.includes(businessTypeValue);
    const tinType = _get(formValues, fields.tinType);
    const updatedAllowed = useSelector(currentCompanyVerificationUpdateAllowedSelector);
    const dispatch = useDispatch();
    const setCompanyTypeToBusiness = () => {
        dispatch(change(signupFlowForm, fields.companyType, companyTypes.Enum.business));
        dispatch(change(signupFlowForm, fields.tinType, governmentIdType.enum.ein));
        clearFormFields(signupFlowForm, [fields.TIN], null);
    };
    const setCompanyTypeToPersonal = () => {
        dispatch(change(signupFlowForm, fields.companyType, companyTypes.Enum.personal));
        dispatch(change(signupFlowForm, fields.tinType, governmentIdType.enum.ssn));
        clearFormFields(signupFlowForm, [fields.businessType, fields.stateOfIncorporation, fields.TIN, fields.uiBusinessWithoutEIN], null);
    };
    return (React.createElement(FlowStepperMain, { containerWidth: containerWidthMap.small, fields: React.createElement("div", { className: "max-w-[576px] flex flex-col gap-5" },
            !updatedAllowed && React.createElement(CannotUpdateFieldHint, null),
            React.createElement("div", { className: "flex gap-5" },
                React.createElement(Radio, { checked: companyType === companyTypes.Enum.business, className: "w-1/2", id: "radio-companyType", label: "I represent a business", name: "companyType", onChange: setCompanyTypeToBusiness, value: companyTypes.Enum.business, variant: "outlined" }),
                React.createElement(Radio, { checked: companyType === companyTypes.Enum.personal, className: "w-1/2", id: "radio-companyType", label: "I am an individual", name: "companyType", onChange: setCompanyTypeToPersonal, value: companyTypes.Enum.personal, variant: "outlined" })),
            companyType === companyTypes.Enum.business && (React.createElement(SignupTaxInfoBusinessFields, { businessType: businessTypeValue, businessWithoutEIN: businessWithoutEIN, isDisabled: !updatedAllowed, stateOfIncorporationIsRequired: !stateOfIncorporationIsOptional, tinType: tinType })),
            companyType === companyTypes.Enum.personal && React.createElement(SignupTaxInfoTin, { isDisabled: !updatedAllowed })), invalid: invalid || !updatedAllowed, onBack: viewSteps[activeView].previous, onNext: {
            ...viewSteps[activeView].next,
            isLoading: isUpdating,
        }, onSkip: updatedAllowed && viewSteps[activeView].skip, subTitle: { content: 'This must match what’s on your W-9 form.' }, title: { content: 'What’s your business type?' } }));
};
export default SignupTaxInfo;

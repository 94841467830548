import ThunkFetch from 'helpers/thunkFetch';

import { getConvertFromExternalFundingAccountEndpoint } from 'services/api/fundingEndpoints';

import {
  SUBMIT_CONVERT_FUNDING_ACCOUNT_FAILURE,
  SUBMIT_CONVERT_FUNDING_ACCOUNT_REQUEST,
  SUBMIT_CONVERT_FUNDING_ACCOUNT_SUCCESS,
} from 'types/funding';

const submitConvertFundingAccountFromExternal = (fundingAccountId) => {
  const t = new ThunkFetch({
    actions: {
      error: SUBMIT_CONVERT_FUNDING_ACCOUNT_FAILURE,
      request: SUBMIT_CONVERT_FUNDING_ACCOUNT_REQUEST,
      success: SUBMIT_CONVERT_FUNDING_ACCOUNT_SUCCESS,
    },
    endpoint: getConvertFromExternalFundingAccountEndpoint(fundingAccountId),
    method: 'POST',
    requireAuth: true,
  });

  return t.makeRequest();
};

export default submitConvertFundingAccountFromExternal;

import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';
import { createInternationalFundingAccountRoutine } from 'actions/routines/funding';

import { allKeys } from 'helpers/utility';

import {
  SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_SUCCESS,
  SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_SUCCESS,
} from 'types/connectBank';
import { FETCH_FUNDING_ACCOUNTS_SUCCESS, SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_SUCCESS } from 'types/funding';
import { FETCH_ITEM_SUCCESS } from 'types/item';
import { FETCH_PARTNERSHIP_SUCCESS } from 'types/partnership';

const allFundingSourcesReducer = (state = [], action) => {
  switch (action.type) {
    case createInternationalFundingAccountRoutine.SUCCESS:
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
    case FETCH_FUNDING_ACCOUNTS_SUCCESS:
    case FETCH_ITEM_SUCCESS:
    case FETCH_PARTNERSHIP_SUCCESS:
    case SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_SUCCESS:
    case SUBMIT_FUNDING_ACCOUNT_BANK_MANUAL_SUCCESS:
    case SUBMIT_FUNDING_ACCOUNT_BANK_TOKEN_SUCCESS:
      return Array.from(new Set([...state, ...allKeys(action.payload.fundingSource)]));

    default:
      return state;
  }
};

export default allFundingSourcesReducer;

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ledgerNameSelector, isLedgerIntegrationConnectedSelector } from 'selectors/integrationsSelectors';
import PartnershipNameSettingsForm from './PartnershipNameSettingsForm';
import {} from './PartnershipNameSettingsFormProps.types';
export const mapStateToProps = createStructuredSelector({
    isLedgerIntegrationConnected: isLedgerIntegrationConnectedSelector,
    ledgerName: ledgerNameSelector,
});
export default connect(mapStateToProps)(PartnershipNameSettingsForm);

import { StatusIndicator } from '@routable/gross-ds';
import React from 'react';
import { TableCellTextWithSubtext } from 'complexComponents';
import { getInvoiceListStatus, getTableText } from 'modules/dashboard/createItems/invoiceListTable/helpers/table';
const InvoiceListStatusMessage = ({ isBaseCurrencyCodeNotSupported, isBaseCurrencyCodeSupported, isConfirmed, isCurrencyNotSupportedByVendor, isCurrencyUnsupported, isNonUsdInvoice, rowData, }) => {
    const isCurrencyInvalid = Boolean(isCurrencyNotSupportedByVendor || isNonUsdInvoice);
    const shouldDisplayWarn = Boolean(!isBaseCurrencyCodeSupported &&
        (isBaseCurrencyCodeNotSupported ||
            isCurrencyNotSupportedByVendor ||
            isBaseCurrencyCodeNotSupported ||
            isCurrencyUnsupported ||
            isNonUsdInvoice ||
            rowData.requiresPurposeCode));
    return (React.createElement(TableCellTextWithSubtext, { text: React.createElement(React.Fragment, null,
            React.createElement(StatusIndicator, { status: getInvoiceListStatus({
                    isConfirmed,
                    rowData,
                    shouldDisplayWarn,
                }) }, getTableText({
                isBaseCurrencyCodeNotSupported,
                isBaseCurrencyCodeSupported,
                isConfirmed,
                isCountryUnsupported: Boolean(rowData.requiresPurposeCode),
                isCurrencyInvalid,
                isCurrencyUnsupported,
                rowData,
            }))), textClassName: "bold" }));
};
export default InvoiceListStatusMessage;

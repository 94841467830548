import pluralize from 'pluralize';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { getCustomerOrVendorOrBothTextByPartnership } from 'helpers/partnerships';
import { capitalize, listAllElementsInArray } from 'helpers/stringHelpers';
import { lengthOf } from 'helpers/utility';
export const getDetailsTextForSearchCompanies = ({ isCrossBorderPaymentsNotEnabledTooltipShown, isCurrencyNotSupportedByLedgerTooltipShown, isCurrencyNotSupportedByPlatformTooltipShown, isLimitSendingPaymentsTooltipShown, isPreventInternationalCustomerMatchTooltipShown, ledger, partnership, }) => {
    if (isLimitSendingPaymentsTooltipShown) {
        return `Vendor isn't configured for '${ledger.baseCurrencyCode}' on ${ledger.name} account`;
    }
    if (isCrossBorderPaymentsNotEnabledTooltipShown) {
        return "You haven't enabled non-USD payments";
    }
    if (isCurrencyNotSupportedByPlatformTooltipShown) {
        const partnershipType = getCustomerOrVendorOrBothTextByPartnership(partnership);
        const currenciesText = listAllElementsInArray(partnership.ledgerCurrencies);
        const currencyOrCurrencies = pluralize('currency', lengthOf(partnership.ledgerCurrencies));
        return `${PLATFORM_DISPLAY_SHORT_NAME} doesn't yet support this ${partnershipType}'s ${currencyOrCurrencies} (${currenciesText})`;
    }
    if (isCurrencyNotSupportedByLedgerTooltipShown) {
        return `${partnership.currencyCodePartner} is not enabled in your ${ledger.name} account`;
    }
    if (isPreventInternationalCustomerMatchTooltipShown) {
        return `${PLATFORM_DISPLAY_SHORT_NAME} doesn't yet support sending invoices to international customers`;
    }
    return '';
};
export const getDetailsTextForMatchCompanies = ({ isCrossBorderDisabledTooltipIsShown, isCurrencyMismatchTooltipShown, isCurrencyNotSupportedByPlatformTooltipShown, isInternationalCustomerTooltipShown, isLimitedMatchingTooltipShown, isMatchDomesticPartnershipsTooltipShown, ledger, partnership, platformPartnershipCurrency, }) => {
    if (isLimitedMatchingTooltipShown) {
        return `Vendor isn't configured for '${ledger.baseCurrencyCode}' on ${ledger.name} account`;
    }
    if (isCrossBorderDisabledTooltipIsShown) {
        return "You haven't enabled non-USD payments";
    }
    if (isInternationalCustomerTooltipShown) {
        return 'Matching international customers is not supported yet';
    }
    if (isMatchDomesticPartnershipsTooltipShown) {
        return 'You cannot match an international vendor with a customer';
    }
    if (isCurrencyNotSupportedByPlatformTooltipShown) {
        const partnershipType = getCustomerOrVendorOrBothTextByPartnership(partnership);
        const currenciesText = listAllElementsInArray(partnership.ledgerCurrencies);
        const currencyOrCurrencies = pluralize('currency', lengthOf(partnership.ledgerCurrencies));
        return `${PLATFORM_DISPLAY_SHORT_NAME} doesn't yet support this ${partnershipType}'s ${currencyOrCurrencies} (${currenciesText})`;
    }
    if (isCurrencyMismatchTooltipShown) {
        const partnershipType = getCustomerOrVendorOrBothTextByPartnership(partnership);
        return `${capitalize(partnershipType)} isn't configured for '${platformPartnershipCurrency}' on ${ledger.name} account`;
    }
    return '';
};
export const getDetailsTextForMergeCompanies = ({ isLimitedMergingTooltipShown, isMergeDisabledWithXBFlagOffTooltipShown, isMergeDisabledWithXBFlagOnTooltipShown, ledger, partnershipType, }) => {
    if (isLimitedMergingTooltipShown) {
        return `${capitalize(partnershipType)} isn't configured for '${ledger.baseCurrencyCode}' on ${ledger.name} account`;
    }
    if (isMergeDisabledWithXBFlagOffTooltipShown || isMergeDisabledWithXBFlagOnTooltipShown) {
        return 'You cannot merge an international vendor or customer';
    }
    return '';
};

/**
 * @fileOverview Defines the initial state for featureFlags in the redux store.
 * @module reducers/featureFlags/initialState
 */

import { getFeatureFlagsFromLocalStorage } from 'helpers/featureFlags';

/**
 * @fileOverview Defines all initial state values for reduxState.featureFlags.
 * @module reducers/featureFlags
 */

export const initialState = {
  /** @type {EnabledKeys} */
  activeFlags: getFeatureFlagsFromLocalStorage() || {},
  errors: null,
  isFetching: false,
};

import React from 'react';

import { SecondaryActionLink } from 'components/link';

import helpDocs from 'helpers/helpDocs';

import { multiplePartnershipHintText } from 'modules/external/ExternalCollectTaxInfo/constants/text';

/**
 * Displays a link for the user to access the helpdocs to understand why we may have their tax info already pre-filled
 * due to having it already confirmed with another partnership.
 * @returns {StatelessComponent}
 * @constructor
 */
const WhyDoYouHaveMyTaxInfoHelpLink = () => (
  <div className="help-link">
    <SecondaryActionLink href={helpDocs.TAX_WHY_IS_INFO_ALREADY_ENTERED} toNewPage>
      {multiplePartnershipHintText.HELP}
    </SecondaryActionLink>
  </div>
);

export default WhyDoYouHaveMyTaxInfoHelpLink;

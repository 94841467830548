import React from 'react';
import { change, reset } from 'redux-form';

import {
  fetchIntegrationLedgerPartnershipsRequest,
  fetchIntegrationPlatformPartnershipsRequest,
} from 'actions/integrations';

import { formNamesMatchPartnership } from 'constants/forms';
import { LedgerMatchFilterTypes } from 'constants/ledger';
import { PartnershipCreateActions, PartnershipTypes } from 'constants/partnership';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

import { PartnershipType } from 'enums/partnerships';

import {
  isActiveSideLedger,
  isLedgerSettingsContactTypeBoth,
  isLedgerSettingsContactTypeSingular,
} from 'helpers/ledger';
import {
  isPartnershipCustomer,
  isPartnershipTypeCustomer,
  isPartnershipTypeMatched,
  isPartnershipTypeOtherMatched,
  isPartnershipVendor,
} from 'helpers/partnerships';
import { isSearchExecuted } from 'helpers/search';

import { UndoButton } from 'modules/dashboard/settings/account/integrations/ledger/global/components/buttons';
import PartnershipListCompleteDescription from 'modules/dashboard/settings/account/integrations/ledger/matching/components/list/PartnershipListCompleteDescription';
import { filterTypeToAPIParams } from 'modules/dashboard/settings/account/integrations/ledger/matching/helpers/api';
import { getMatchPlatformPartnershipInitialValues } from 'modules/dashboard/settings/account/integrations/ledger/matching/helpers/initialValues';

import {
  matchPartnershipUIActiveFilterSelector,
  matchPartnershipUIActiveSideSelector,
  matchPartnershipUIPaginationOffsetSelector,
  matchPartnershipUISearchSelector,
} from 'selectors/forms';
import { ledgerIntegrationSelector } from 'selectors/integrationsSelectors';
import {
  allMatchingPartnershipsSelector,
  lastSubmittedPlatformPartnershipSelector,
  ledgerSettingsSelector,
} from 'selectors/ledgerInfoSelectors';

import { toaster } from 'services/toaster';

import { storeAccessor as store } from 'store/accessor';

import submitIntegrationLedgerPartnership from 'thunks/submitIntegrationLedgerPartnership';
import submitIntegrationLedgerPartnershipMerge from 'thunks/submitIntegrationLedgerPartnershipMerge';
import submitIntegrationPlatformPartnership from 'thunks/submitIntegrationPlatformPartnership';

import { RESET_PLATFORM_PARTNERSHIP } from 'types/integrations';
import { MODAL_MATCH_PLATFORM_PARTNERSHIP_OPEN } from 'types/modals';

// ******************
// GENERAL
// ******************

/**
 * Text for customer or vendor, given a field (customer/vendor)
 * @param field
 * @return {string}
 */
export const getCustomerOrVendorTextByFieldType = (field) => {
  const reduxState = store.getState();
  const ledgerSettings = ledgerSettingsSelector(reduxState);

  if (isLedgerSettingsContactTypeBoth(ledgerSettings)) {
    return 'company';
  }

  if (isPartnershipTypeCustomer(field)) {
    return PartnershipTypes.CUSTOMER;
  }

  return PartnershipTypes.VENDOR;
};

/**
 * Get current active side from the form
 */
export const getActiveSide = () => {
  const reduxState = store.getState();
  return matchPartnershipUIActiveSideSelector(reduxState);
};

/**
 * Get current active filter from the form
 */
export const getActiveFilter = () => {
  const reduxState = store.getState();
  return matchPartnershipUIActiveFilterSelector(reduxState);
};

/**
 * Create partnership added/invited text to display in modal
 * @param action
 * @return {string}
 */
export const getCreatePartnershipActionText = (action) => {
  if (action === PartnershipCreateActions.INVITE) {
    return 'invited';
  }

  return 'added';
};

/**
 * Add contacts to partnership
 * @param partnership
 * @param partnershipContacts
 */
export const getPartnershipWithContacts = (partnership, partnershipContacts) => ({
  ...partnership,
  contacts: partnershipContacts,
});

/**
 * Get partnership contact email
 * @param partnershipContacts
 */
export const getPartnershipContactEmail = (partnershipContacts) => {
  const reduxState = store.getState();
  const ledger = ledgerIntegrationSelector(reduxState);

  if (!partnershipContacts) {
    return `View contact information on ${ledger.name}`;
  }

  if (partnershipContacts.length === 0 || !partnershipContacts[0].email) {
    return 'No email found';
  }

  return partnershipContacts[0].email;
};

/**
 * Update the current partnership matching active side (ledger or platform)
 * @param newActiveSide
 */
export const updateActiveSide = (newActiveSide) => {
  store.dispatch(change(formNamesMatchPartnership.UI, 'activeSide', newActiveSide));
};

/**
 * Update pagination offset
 * @param newOffset - {number} / '+1' / '-1'
 */
export const updatePartnershipsPaginationOffset = (newOffset) => {
  const reduxState = store.getState();
  const currentOffset = matchPartnershipUIPaginationOffsetSelector(reduxState);

  let offset;

  switch (newOffset) {
    case '+1':
      offset = currentOffset + 1;
      break;

    case '-1':
      offset = currentOffset - 1;
      break;

    default:
      offset = parseInt(newOffset, 10);
      break;
  }

  store.dispatch(change(formNamesMatchPartnership.UI, 'pagination.offset', offset));
};

// ******************
// LEDGER
// ******************

/**
 * Description text for ledger partnership list
 * @param {string} activeFilter
 * @return {string}
 */
export const getLedgerPartnershipsListDescriptionText = (activeFilter) => {
  const reduxState = store.getState();
  const ledger = ledgerIntegrationSelector(reduxState);
  const partnerships = allMatchingPartnershipsSelector(reduxState);

  switch (activeFilter) {
    case LedgerMatchFilterTypes.MATCHED:
      return `Companies that you have matched to ${PLATFORM_DISPLAY_SHORT_NAME} will appear below.`;

    case LedgerMatchFilterTypes.ARCHIVED:
      return `Companies available on ${ledger.fullName} and ${PLATFORM_DISPLAY_SHORT_NAME}
       that you have skipped will appear below.`;

    case LedgerMatchFilterTypes.UNMATCHED:
    default:
      if (partnerships.length === 0) {
        return <PartnershipListCompleteDescription />;
      }

      return `Companies available on ${ledger.fullName} (and not ${PLATFORM_DISPLAY_SHORT_NAME}) will show below.
       Please add them to ${PLATFORM_DISPLAY_SHORT_NAME} to interact with them.`;
  }
};

/**
 * Title text for ledger partnership list
 * @param {string} activeFilter
 * @return {string}
 */
export const getLedgerPartnershipsListTitleText = (activeFilter) => {
  const reduxState = store.getState();
  const partnerships = allMatchingPartnershipsSelector(reduxState);

  switch (activeFilter) {
    case LedgerMatchFilterTypes.MATCHED:
      return `Companies matched to ${PLATFORM_DISPLAY_SHORT_NAME}`;

    case LedgerMatchFilterTypes.ARCHIVED:
      return 'Companies you have skipped';

    case LedgerMatchFilterTypes.UNMATCHED:
    default:
      if (partnerships.length === 0) {
        return 'You are all caught up!';
      }

      return `Companies you can add to ${PLATFORM_DISPLAY_SHORT_NAME}`;
  }
};

/**
 * Get the ledger URL for a partnership
 * If the ledger contact type is both - it will use the customer data
 * @param partnership
 * @return {*}
 */
export const getLedgerLink = (partnership) => {
  const reduxState = store.getState();
  const ledgerSettings = ledgerSettingsSelector(reduxState);

  // If the ledger contact type is both, just use customer as it doesn't matter
  if (isLedgerSettingsContactTypeBoth(ledgerSettings) || partnership.isCustomer) {
    return partnership.ledgerLinkCustomer;
  }

  if (partnership.isVendor) {
    return partnership.ledgerLinkVendor;
  }

  return null;
};

/**
 * Get the ledger vendor/customer specific URL for a partnership
 * @param partnership
 * @param partnershipType
 * @return {*}
 */
export const getLedgerVendorCustomerLink = (partnership, partnershipType) => {
  if (partnershipType === 'vendor') {
    return partnership.ledgerLinkVendor;
  }
  if (partnershipType === 'customer') {
    return partnership.ledgerLinkCustomer;
  }
  return null;
};

/**
 * Get the ledger name for a partnership
 * If the ledger contact type is both - it will use the customer data
 * @param {object} partnership
 * @param forceType
 * @return {*}
 */
export const getPartnershipLedgerName = (partnership, forceType = null) => {
  // Force showing a specific name (customer or vendor)
  if (forceType === PartnershipTypes.CUSTOMER) {
    return partnership.ledgerNameCustomer;
  }

  if (forceType === PartnershipTypes.VENDOR) {
    return partnership.ledgerNameVendor;
  }

  const reduxState = store.getState();
  const ledgerSettings = ledgerSettingsSelector(reduxState);

  // If the ledger contact type is both, just use customer as it doesn't matter
  if (isLedgerSettingsContactTypeBoth(ledgerSettings) || partnership.isCustomer) {
    return partnership.ledgerNameCustomer;
  }

  if (partnership.isVendor) {
    return partnership.ledgerNameVendor;
  }

  return partnership.name;
};

/**
 * Helper to build the payload for submitting a ledger partnership (can only skip)
 * @param partnership
 * @param skip
 */
export const buildSubmitLedgerPartnershipPayload = (partnership, skip) => {
  const reduxState = store.getState();
  const ledgerSettings = ledgerSettingsSelector(reduxState);

  // Prep payload
  const payload = {
    isArchived: !!skip,
  };

  if (isLedgerSettingsContactTypeBoth(ledgerSettings) || isPartnershipCustomer(partnership)) {
    payload.customerLedgerId = partnership.id;
  }

  if (isLedgerSettingsContactTypeBoth(ledgerSettings) || isPartnershipVendor(partnership)) {
    payload.vendorLedgerId = partnership.id;
  }

  return payload;
};

/**
 * Unskip ledger partnership helper
 * @param partnership
 */
export const handleUnskipLedgerPartnership = (partnership) => {
  const reduxState = store.getState();
  const ledger = ledgerIntegrationSelector(reduxState);

  const payload = buildSubmitLedgerPartnershipPayload(partnership, false);

  // Show unskipped toast on success
  const successCallback = (parsedResponse, jsonResponse) => {
    const partnershipName = jsonResponse.data.attributes.name;
    toaster.success(`Unskipped ${partnershipName}`, {
      id: 'unskip-ledger-partnership',
    });

    updatePartnershipsPaginationOffset('+1');
    return parsedResponse;
  };

  store.dispatch(submitIntegrationLedgerPartnership(ledger.id, payload, successCallback));
};

/**
 * Skip ledger partnership helper
 * @param partnership
 */
export const handleSkipLedgerPartnership = (partnership) => {
  const reduxState = store.getState();
  const ledger = ledgerIntegrationSelector(reduxState);

  const payload = buildSubmitLedgerPartnershipPayload(partnership, true);

  // Show skipped toast on success
  const successCallback = (parsedResponse, jsonResponse) => {
    const partnershipName = jsonResponse.data.attributes.name;
    toaster.success(`Skipped ${partnershipName}`, {
      description: <UndoButton className="margin-top--sm" onClick={() => handleUnskipLedgerPartnership(partnership)} />,
      id: 'skip-ledger-partnership',
    });

    updatePartnershipsPaginationOffset('-1');

    return parsedResponse;
  };

  store.dispatch(submitIntegrationLedgerPartnership(ledger.id, payload, successCallback));
};

/**
 * Fetch ledger partnerships
 * @param {string} [ledgerRef]
 * @param {string} [newFilter]
 * @param {boolean} [resetOffset]
 * @param search
 */
export const handleLoadLedgerPartnerships = ({ ledgerRef, newFilter, resetOffset, search } = {}) => {
  const reduxState = store.getState();
  const integrationConfig = ledgerIntegrationSelector(reduxState);
  const activeSearch = search || matchPartnershipUISearchSelector(reduxState);
  const activeFilter = newFilter || matchPartnershipUIActiveFilterSelector(reduxState);
  const activeOffset = resetOffset ? 0 : matchPartnershipUIPaginationOffsetSelector(reduxState);

  // Build filter payload
  let filter;

  if (ledgerRef) {
    filter = { ledger_ref: ledgerRef };
  } else if (isSearchExecuted(activeSearch)) {
    filter = { search: activeSearch.keyword };
  } else {
    filter = filterTypeToAPIParams(activeFilter);
  }

  // Add offset
  filter['page[offset]'] = activeOffset;

  store.dispatch(fetchIntegrationLedgerPartnershipsRequest(integrationConfig.id, filter));
};

/**
 * Helper to submit a ledger partnership merge
 * @param values
 * @param dispatch
 * @param props
 */
export const handleSubmitLedgerMergePartnership = (values, dispatch, props) => {
  const {
    modal: { successCallback },
  } = props;
  const reduxState = store.getState();
  const ledger = ledgerIntegrationSelector(reduxState);

  const integrationId = ledger.id;
  const platformPartnershipId = values.partnership.id;
  const ledgerPartnership = props.partnership;

  const payload = { partnershipId: platformPartnershipId };

  if (isPartnershipCustomer(ledgerPartnership)) {
    payload.customerLedgerId = ledgerPartnership.customerLedgerId;
  }

  if (isPartnershipVendor(ledgerPartnership)) {
    payload.vendorLedgerId = ledgerPartnership.vendorLedgerId;
  }

  dispatch(submitIntegrationLedgerPartnershipMerge(integrationId, payload, successCallback));
};

// ******************
// PLATFORM
// ******************

/**
 * Helper to build the payload for matching platform to ledger
 * @param partnershipId
 * @param customer
 * @param vendor
 * @return {{partnershipId: *}}
 */
export const buildSubmitPlatformPartnershipPayload = ({ partnership, customer, vendor }) => {
  const reduxState = store.getState();
  const ledgerSettings = ledgerSettingsSelector(reduxState);

  const payload = { partnershipId: partnership.id };

  // Add
  if (customer.add) {
    payload.customerCreate = true;
  }

  if (vendor.add) {
    payload.vendorCreate = true;
  }

  // Match
  if (customer.match) {
    payload.customerLedgerId = customer.data.id;
  }

  if (vendor.match) {
    payload.vendorLedgerId = vendor.data.id;
  }

  // Skip/Unskip
  if (isLedgerSettingsContactTypeBoth(ledgerSettings)) {
    // Skip
    if (customer.archive || vendor.archive) {
      payload.isArchived = true;
    }

    // Unskip
    if (customer.archive === false || vendor.archive === false) {
      payload.isArchived = false;
    }
  } else if (isLedgerSettingsContactTypeSingular(ledgerSettings)) {
    // Skip
    if (customer.archive && vendor.archive) {
      payload.isArchived = true;
    }

    // Unskip
    if (customer.archive === false && vendor.archive === false) {
      payload.isArchived = false;
    }
  }

  return payload;
};

/**
 * Description text for platform partnership list
 * @param activeFilter
 * @return {string}
 */
export const getPlatformPartnershipsListDescriptionText = (activeFilter) => {
  const reduxState = store.getState();
  const ledger = ledgerIntegrationSelector(reduxState);

  switch (activeFilter) {
    case LedgerMatchFilterTypes.MATCHED:
      return `Companies that you have matched to your ${ledger.fullName} account will appear below.`;

    case LedgerMatchFilterTypes.ARCHIVED:
      return `Companies available on ${PLATFORM_DISPLAY_SHORT_NAME} that you have skipped will appear below.`;

    default:
      return `Companies available on ${PLATFORM_DISPLAY_SHORT_NAME} will appear below. If they're not
        synced to your ${ledger.fullName} account, please select the correct match.`;
  }
};

/**
 * Title text for platform partnership list
 * @param activeFilter
 * @return {string}
 */
export const getPlatformPartnershipsListTitleText = (activeFilter) => {
  const reduxState = store.getState();
  const ledger = ledgerIntegrationSelector(reduxState);

  switch (activeFilter) {
    case LedgerMatchFilterTypes.MATCHED:
      return `Companies matched to ${ledger.name}`;

    case LedgerMatchFilterTypes.ARCHIVED:
      return 'Companies you have skipped';

    case LedgerMatchFilterTypes.UNMATCHED:
    default:
      return `Companies you need to match to ${ledger.name}`;
  }
};

/**
 * Text for "to be ____" in the matching platform to ledger modal
 * @param partnershipState
 * @return {string}
 */
export const getPlatformToLedgerMatchToBeText = (partnershipState) => {
  if (partnershipState.add) {
    return 'added';
  }

  if (partnershipState.match) {
    return 'matched';
  }

  // Defaults to skipped
  return 'skipped';
};

/**
 * Generic helper to update redux form for platform to ledger matching modal
 * @param fieldName
 * @param payload
 */
export const handleUpdatePlatformToLedgerField = (fieldName, payload) => {
  // Decide whether to update customer or vendor or both
  const reduxState = store.getState();
  const ledgerSettings = ledgerSettingsSelector(reduxState);

  if (isLedgerSettingsContactTypeBoth(ledgerSettings)) {
    // update opposing field as well (if fieldName = customer -> update vendor and vice-versa)
    let customerOrVendorFieldName = fieldName.replace(PartnershipType.VENDOR, PartnershipType.CUSTOMER);
    if (fieldName.includes(PartnershipType.CUSTOMER)) {
      customerOrVendorFieldName = fieldName.replace(PartnershipType.CUSTOMER, PartnershipType.VENDOR);
    }

    store.dispatch(change(formNamesMatchPartnership.PLATFORM_TO_LEDGER, customerOrVendorFieldName, payload));
  }

  store.dispatch(change(formNamesMatchPartnership.PLATFORM_TO_LEDGER, fieldName, payload));
};

/**
 * Helper to add for platform to ledger modal
 * @param fieldName
 */
export const handleAddPlatformToLedger = (fieldName) => {
  const updatePayload = getMatchPlatformPartnershipInitialValues();
  updatePayload.add = true;
  handleUpdatePlatformToLedgerField(fieldName, updatePayload);
};

/**
 * Helper to match for platform to ledger modal
 * @param event
 * @param value
 * @param _prevValue
 * @param fieldName
 */
export const handleMatchPlatformToLedger = (event, value, _prevValue, fieldName) => {
  // Assume inout field name is of the form:
  // platform.partnership.${field}.data
  const updateField = fieldName.replace('.data', '');

  const updatePayload = getMatchPlatformPartnershipInitialValues();
  updatePayload.data = value;
  updatePayload.match = true;

  handleUpdatePlatformToLedgerField(updateField, updatePayload);
};

/**
 * Helper to skip for platform to ledger modal
 * @param fieldName
 */
export const handleSkipPlatformToLedger = (fieldName) => {
  const updatePayload = getMatchPlatformPartnershipInitialValues();
  updatePayload.archive = true;
  handleUpdatePlatformToLedgerField(fieldName, updatePayload);
};

/**
 * Helper to undo/clear action for platform to ledger modal
 * @param fieldName
 */
export const handleUndoPlatformToLedger = (fieldName) => {
  const updatePayload = getMatchPlatformPartnershipInitialValues();
  handleUpdatePlatformToLedgerField(fieldName, updatePayload);
};

/**
 * Helper to submit add/match platform partnership
 * @param values
 * @param dispatch
 * @param props
 */
export const handleSubmitPlatformPartnership = (values, dispatch, props) => {
  const { partnership } = props;
  const reduxState = store.getState();
  const ledger = ledgerIntegrationSelector(reduxState);

  const integrationId = ledger.id;
  const {
    platform: {
      partnership: { customer, vendor },
    },
  } = values;

  const payload = buildSubmitPlatformPartnershipPayload({
    partnership,
    customer,
    vendor,
  });

  const successCallback = (parsedResponse) => {
    updatePartnershipsPaginationOffset('-1');
    return parsedResponse;
  };

  dispatch(submitIntegrationPlatformPartnership(integrationId, payload, successCallback));
};

/**
 * Helper to unskip a platform partnership
 * @param partnership
 */
export const handleUnskipPlatformPartnership = (partnership) => {
  const reduxState = store.getState();
  const ledger = ledgerIntegrationSelector(reduxState);

  const customer = { archive: false };
  const vendor = { archive: false };
  const payload = buildSubmitPlatformPartnershipPayload({
    partnership,
    customer,
    vendor,
  });

  // Show unskipped toast on success
  const successCallback = (parsedResponse, jsonResponse) => {
    const partnershipName = jsonResponse.data.attributes.name;
    toaster.success(`Unskipped ${partnershipName}`, {
      id: 'unskip-platform-partnership',
    });

    updatePartnershipsPaginationOffset('+1');
    return parsedResponse;
  };

  store.dispatch(submitIntegrationPlatformPartnership(ledger.id, payload, successCallback));
};

/**
 * Helper to skip a platform partnership
 * @param partnership
 */
export const handleSkipPlatformPartnership = (partnership) => {
  const reduxState = store.getState();
  const ledger = ledgerIntegrationSelector(reduxState);

  const customer = { archive: true };
  const vendor = { archive: true };
  const payload = buildSubmitPlatformPartnershipPayload({
    partnership,
    customer,
    vendor,
  });

  // Show skipped toast on success
  const successCallback = (parsedResponse, jsonResponse) => {
    const partnershipName = jsonResponse.data.attributes.name;
    toaster.success(`Skipped ${partnershipName}`, {
      description: (
        <UndoButton className="margin-top--sm" onClick={() => handleUnskipPlatformPartnership(partnership)} />
      ),
      id: 'skip-platform-partnership',
    });

    updatePartnershipsPaginationOffset('-1');
    return parsedResponse;
  };

  store.dispatch(submitIntegrationPlatformPartnership(ledger.id, payload, successCallback));
};

/**
 * Helper to fetch/load platform partnerships from the server
 * @param newFilter
 * @param resetOffset
 * @param search
 */
export const handleLoadPlatformPartnerships = ({ newFilter, resetOffset, search } = {}) => {
  const reduxState = store.getState();
  const ledger = ledgerIntegrationSelector(reduxState);
  const activeSearch = search || matchPartnershipUISearchSelector(reduxState);
  const activeFilter = newFilter || matchPartnershipUIActiveFilterSelector(reduxState);
  const activeOffset = resetOffset ? 0 : matchPartnershipUIPaginationOffsetSelector(reduxState);

  const isSearch = isSearchExecuted(activeSearch);

  // Build filter payload
  let payload;
  if (isSearch) {
    payload = { search: activeSearch.keyword };
  } else {
    payload = filterTypeToAPIParams(activeFilter);
  }

  // Add offset
  payload['page[offset]'] = activeOffset;

  store.dispatch(fetchIntegrationPlatformPartnershipsRequest(ledger.id, payload, isSearch));
};

/**
 * Dispatch opening the match partnership modal, clearing the submit status and form
 * @param partnershipId
 */
export const handleOpenMatchPartnershipModal = (partnershipId) => {
  // Reset lastSubmitted
  store.dispatch({ type: RESET_PLATFORM_PARTNERSHIP });

  // Reset the form prior to opening the modal
  store.dispatch(reset(formNamesMatchPartnership.PLATFORM_TO_LEDGER));

  // Open modal
  store.dispatch({
    type: MODAL_MATCH_PLATFORM_PARTNERSHIP_OPEN,
    payload: { partnershipId },
  });
};

/**
 * Helper to determine whether a partnership is partially matched
 * @param partnership
 * @param field
 * @return {*|boolean|boolean|*}
 */
export const isPartnershipPartiallyMatched = (partnership, field) => {
  const reduxState = store.getState();
  const ledgerSettings = ledgerSettingsSelector(reduxState);

  return (
    isLedgerSettingsContactTypeSingular(ledgerSettings) &&
    !isPartnershipTypeMatched(partnership, field) &&
    isPartnershipTypeOtherMatched(partnership, field)
  );
};

/**
 * Helper to determine whether the partnership was marked to be skipped and the call was submitted.
 * It happens in the case that we match one side and skip the other on a singular ledger.
 * @param customerOrVendor
 * @return {boolean}
 */
export const isPartnershipFakeArchived = (customerOrVendor) => {
  // Ensure there's form data present
  if (!customerOrVendor) {
    return false;
  }

  const reduxState = store.getState();
  const ledgerSettings = ledgerSettingsSelector(reduxState);
  const lastSubmitted = lastSubmittedPlatformPartnershipSelector(reduxState);

  return isLedgerSettingsContactTypeSingular(ledgerSettings) && lastSubmitted && customerOrVendor.isArchived;
};

/**
 * Determine whether a platform to ledger action is selected
 * @param customerOrVendor
 * @return {boolean}
 */
export const isPlatformToLedgerMatchPartnershipActionSelected = (customerOrVendor) =>
  !!(customerOrVendor.add || customerOrVendor.archive || customerOrVendor.match);

/**
 * Get platform matching modal description text top row
 * @param partnership
 * @return {string}
 */
export const getPartnershipsMatchingModalDescriptionTextTopRow = (partnership) => {
  let customerVendorText;
  const { isCustomer, isVendor } = partnership;

  if (isCustomer && isVendor) {
    customerVendorText = 'both a customer and a vendor';
  } else if (isCustomer) {
    customerVendorText = 'a customer';
  } else if (isVendor) {
    customerVendorText = 'a vendor';
  }

  return `Our records show this company is ${customerVendorText} on ${PLATFORM_DISPLAY_SHORT_NAME}.`;
};

/**
 * Get platform matching modal description text bottom row
 * @param partnership
 * @return {string}
 */
export const getPartnershipsMatchingModalDescriptionTextBottomRow = (partnership) => {
  const reduxState = store.getState();
  const ledger = ledgerIntegrationSelector(reduxState);

  if (isLedgerSettingsContactTypeSingular(ledger)) {
    return `Since ${ledger.fullName} assigns a different company for the vendor and customer records please select how to update each one.`;
  }

  return `To communicate and transact with ${partnership.name} please first choose which company it matches with on ${ledger.fullName}.`;
};

/**
 * Helper to return the matching method for partnership list (ledger or platform)
 */
export const getPartnershipsMatchingFetchMethod = (forceActiveSide = null) => {
  const activeSide = forceActiveSide || getActiveSide();
  return isActiveSideLedger(activeSide) ? handleLoadLedgerPartnerships : handleLoadPlatformPartnerships;
};

import * as types from 'types/sidebar';

export const initialState = {
  minimizedOpen: false,
  sidebarDocked: false,
};

const sidebarReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET_SIDEBAR_DOCKED:
      return {
        ...state,
        sidebarDocked: action.payload.sidebarDocked,
        minimizedOpen: false,
      };

    case types.TOGGLE_MINIMIZED_OPEN:
      return {
        ...state,
        minimizedOpen: !state.minimizedOpen,
      };

    default:
      return state;
  }
};

export default sidebarReducer;

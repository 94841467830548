import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const EntityWidgetSection = ({ children, className, style }) => {
  const classConfig = classNames({
    'entity-widget--section': true,
    [className]: !!className,
  });

  return (
    <div className={classConfig} style={style}>
      {children}
    </div>
  );
};

EntityWidgetSection.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

EntityWidgetSection.defaultProps = {
  children: undefined,
  className: undefined,
  style: {},
};

export default EntityWidgetSection;

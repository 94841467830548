import { BASE_API_URL } from 'services/api/index';

/**
 * The base companies URL
 */
export const baseCompanyEndpoint = `${BASE_API_URL}/companies`;

/**
 * Returns a company endpoint for a given company ID
 * @param {Id} companyId
 * @returns {string}
 */
export const getCompanyEndpoint = (companyId) => `${baseCompanyEndpoint}/${companyId}/`;

/**
 * Returns endpoint for enabling international payments for given company
 * @param {Id} companyId
 * @returns {string}
 */
export const getCompanyEnableCrossborderEndpoint = (companyId) =>
  `${baseCompanyEndpoint}/${companyId}/enable_cross_border/`;

/**
 * Returns company settings endpoint for a given company and companySettings IDs
 * @param {Id} companyId
 * @param {Id} settingsId
 * @returns {string}
 */
export const getCompanySettingsEndpoint = (companyId, settingsId) =>
  `${baseCompanyEndpoint}/${companyId}/settings/${settingsId}/`;

/**
 * Returns company integration settings endpoint for a given company and companySettingsIntegration IDs
 * @param {Id} companyId
 * @param {Id} settingsIntegrationId
 * @returns {string}
 */
export const getCompanySettingsIntegrationEndpoint = (companyId, settingsIntegrationId) =>
  `${baseCompanyEndpoint}/${companyId}/settings/integration/${settingsIntegrationId}/`;

/**
 * Returns company settings items approval endpoint for a given company and
 * companySettingsItemApproval IDs
 * @param {Id} companyId
 * @param {Id} companySettingsItemApprovalId
 * @returns {string}
 */
export const getCompanySettingsItemApprovalEndpoint = (companyId, companySettingsItemApprovalId) =>
  `${baseCompanyEndpoint}/${companyId}/item_approval_settings/${companySettingsItemApprovalId}/`;

/**
 * Returns company endpoint for submitting KYC/KYB verification for a given company ID
 * @param {Id} companyId
 * @returns {string}
 */
export const submitCompanyVerificationEndpoint = (companyId) =>
  `${baseCompanyEndpoint}/${companyId}/submit_authorized/`;

/**
 * Returns endpoint for submitting company mailing address
 * @param {Id} companyId
 * @returns {string}
 */
export const createCompanyAddressEndpoint = (companyId) => `${baseCompanyEndpoint}/${companyId}/mailing_addresses/`;

/**
 * Returns single company mailing address endpoint. Can be used for reading or updating the entity.
 * @param {Id} companyId
 * @param {Id} addressId
 * @returns {string}
 */
export const singleCompanyAddressEndpoint = (companyId, addressId) =>
  `${baseCompanyEndpoint}/${companyId}/mailing_addresses/${addressId}/`;

export const getCompanyDocumentEndpoint = ({ currentCompanyId, documentId }) =>
  `${getCompanyEndpoint(currentCompanyId)}documents/${documentId}/`;

export const getCompanyDocumentsEndpoint = ({ currentCompanyId }) =>
  `${getCompanyEndpoint(currentCompanyId)}documents/`;

import mimeTypes from 'mime-types';

import { SUPPORTED_MIME_TYPES } from 'constants/mimeTypes';

/**
 * Replaces mimeTypes in a string with a given file extension
 * @param string
 * @returns string
 */
export const convertSupportedMimeTypesToExtension = (string) => {
  let parsedString = string;
  SUPPORTED_MIME_TYPES.forEach((mimeType) => {
    if (parsedString.includes(mimeType)) {
      parsedString = parsedString.replace(mimeType, `.${mimeTypes.extension(mimeType)}`);
    }
  });
  return parsedString;
};

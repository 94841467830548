import React from 'react';
import { components } from 'react-select';
import WhiteSpace from 'components/text/WhiteSpace';
import {} from 'interfaces/select';
const { SingleValue } = components;
const SingleValueWithDetails = (props) => {
    const { children, data } = props;
    return (React.createElement(SingleValue, { ...props, data: data },
        React.createElement("span", null, children),
        React.createElement(WhiteSpace, null),
        React.createElement("span", null,
            "(",
            data?.details,
            ")")));
};
export default SingleValueWithDetails;

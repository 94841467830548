import {} from 'axios';
import { getPaymentsEndpoint } from 'services/api/paymentsEndpoint';
import {} from './payment.types';
const TRIGGER = `PAYMENT/TRIGGER`;
const SUCCESS = `PAYMENT/SUCCESS`;
const FAILURE = `PAYMENT/FAILURE`;
const success = (data) => ({ type: SUCCESS, payload: data });
const failure = (data) => ({ type: FAILURE, error: data });
const trigger = ({ id, partnershipId }) => ({
    type: TRIGGER,
    payload: {
        id,
        endpoint: getPaymentsEndpoint({ partnershipId, paymentId: id }),
        method: 'GET',
        requireAuth: true,
        onSuccess: success,
        onFailure: failure,
        headers: {},
    },
});
export const paymentRoutine = {
    TRIGGER,
    trigger,
    SUCCESS,
    success,
    FAILURE,
    failure,
};

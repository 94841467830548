import React from 'react';
import { BoxV2, ButtonV2, ContactUs, IconNames, InputField, Text, WhiteSpace } from 'components';
import { ButtonSize } from 'constants/button';
import { formStyles, sizes, typography } from 'constants/styles';
import {} from './VerifyEmail.types';
const VerifyEmail = ({ errors, formState, isSubmitting, lastSubmittedVerificationEmail, onFormSubmit, onInputChange, onSubmitResendVerificationEmail, }) => (React.createElement("div", { className: "branded--contents" },
    React.createElement(BoxV2, { subtitle: "We've sent a six-digit confirmation code to your email address. It will expire in 30 minutes, so enter your code soon.", title: "Please check your email" },
        React.createElement("form", { className: "form", id: "signup-verify-email-form" },
            React.createElement("p", { className: "font-regular regular font-color--greyXDark remove-margin-bottom" }),
            React.createElement("div", { className: "form-control margin-top--m" },
                React.createElement(InputField, { className: formStyles.field.xl.full, errors: errors, maxLength: "6", name: "code", onChange: onInputChange, placeholder: "Verification code", setIsRequired: true, type: "text", value: formState.code })),
            React.createElement("div", { className: "form-control remove-margin-bottom" },
                React.createElement(ButtonV2, { isLoading: isSubmitting, onClick: (e) => {
                        e.preventDefault();
                        onFormSubmit();
                    }, rightIconName: IconNames.ARROW_RIGHT, rightIconProps: { style: { marginLeft: 'auto' } }, rightIconSize: sizes.iconSizes.LARGE, size: ButtonSize.LARGE }, "Continue"))),
        !lastSubmittedVerificationEmail && (React.createElement(Text.Regular, { className: "display--block margin-top--larger", color: typography.TextColor.GREY_XX_DARK, lineHeight: typography.TextLineHeight.LARGE, size: typography.TextSize.LEVEL_200 },
            "Didn't receive your code?",
            React.createElement(WhiteSpace, null),
            React.createElement("a", { className: "font-color--blue-bold--important font-weight--bold", onClick: onSubmitResendVerificationEmail, role: "presentation" }, "Click here"),
            React.createElement(WhiteSpace, null),
            "to have us send a new one.")),
        lastSubmittedVerificationEmail && (React.createElement(Text.Regular, { className: "display--block margin-top--larger", color: typography.TextColor.GREY_XX_DARK, lineHeight: typography.TextLineHeight.LARGE, size: typography.TextSize.LEVEL_200 },
            React.createElement("span", { className: "icon-ic-checkmark bold font-color--main-aqua margin-right--sm" }),
            "We sent a new verification code. Didn't receive it?",
            React.createElement(WhiteSpace, null),
            React.createElement(ContactUs, null))))));
VerifyEmail.defaultProps = {
    errors: {},
};
export default VerifyEmail;

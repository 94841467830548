import { useDispatchEvent, useEvent } from '@routable/framework';
import { DragHandle } from '@routable/gross-ds';
import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BottomPanel, Container, DragIndicator, TopPanel, VerticalBar, VerticalBarBottom, VerticalBarMiddle, VerticalBarTop, } from './vertical.resize.styled';
import { verticalResizeEventName } from './verticalResizeEventName';
const parseHeight = (height, containerHeight) => {
    if (typeof height === 'number') {
        return height;
    }
    if (height.endsWith('px')) {
        return parseFloat(height);
    }
    if (height.endsWith('%')) {
        return (parseFloat(height) / 100) * containerHeight;
    }
    throw new Error('Invalid height type');
};
export const VerticalResize = React.memo(({ disabled = false, resizableName = 'vertical-resize', topChildren, bottomChildren, minHeight = '140px', maxHeight = '90%', initialHeight = '80%', }) => {
    const [height, setHeight] = useState(initialHeight);
    const containerRef = useRef(null);
    const [isResizing, setIsResizing] = useState(false);
    let animationFrameId = null;
    const dispatch = useDispatchEvent();
    const onSetHeight = (newHeight) => {
        setHeight(newHeight);
        const rect = containerRef.current?.getBoundingClientRect();
        const minPx = minHeight && rect?.height ? parseHeight(minHeight, rect.height) : undefined;
        const newHeightPx = newHeight && rect?.height ? parseHeight(newHeight, rect.height) : undefined;
        dispatch(verticalResizeEventName(resizableName), {
            isLocal: true,
            isCollapsed: Math.round(newHeightPx || 0) === Math.round(minPx || 0),
            height: newHeightPx,
        });
    };
    useEvent(verticalResizeEventName(resizableName), (ev) => {
        if (ev && ev.height && !ev.isLocal) {
            onSetHeight(ev.height);
        }
    });
    const onPointerMove = useCallback((e) => {
        if (containerRef.current && !animationFrameId && !disabled) {
            animationFrameId = requestAnimationFrame(() => {
                const rect = containerRef.current.getBoundingClientRect();
                let newHeightPx = rect.bottom - e.clientY;
                const minPx = minHeight ? parseHeight(minHeight, rect.height) : undefined;
                const maxPx = maxHeight ? parseHeight(maxHeight, rect.height) : undefined;
                if (minPx !== undefined && newHeightPx < minPx) {
                    newHeightPx = minPx;
                }
                if (maxPx !== undefined && newHeightPx > maxPx) {
                    newHeightPx = maxPx;
                }
                const newHeightPercent = (newHeightPx / rect.height) * 100;
                onSetHeight(`${newHeightPercent}%`);
                animationFrameId = null;
            });
        }
    }, [minHeight, maxHeight, disabled]);
    const onPointerUp = useCallback(() => {
        window.removeEventListener('pointermove', onPointerMove);
        window.removeEventListener('pointerup', onPointerUp);
        if (containerRef.current) {
            containerRef.current.removeEventListener('pointerleave', onPointerUp);
        }
        if (animationFrameId !== null) {
            cancelAnimationFrame(animationFrameId);
            animationFrameId = null;
        }
        setIsResizing(false);
        document.body.style.userSelect = 'auto';
    }, [onPointerMove]);
    const onPointerDown = useCallback(() => {
        window.addEventListener('pointermove', onPointerMove);
        window.addEventListener('pointerup', onPointerUp);
        if (containerRef.current) {
            containerRef.current.addEventListener('pointerleave', onPointerUp);
        }
        document.body.style.userSelect = 'none';
        setIsResizing(true);
    }, [onPointerMove]);
    useEffect(() => {
        onSetHeight(initialHeight);
    }, [initialHeight]);
    const isResizingStyle = isResizing
        ? {
            transition: 'height 0s',
        }
        : {};
    return (React.createElement(Container, { ref: containerRef },
        React.createElement(TopPanel, { id: "vertical-resize-top-panel", style: {
                ...isResizingStyle,
                height: `calc(100% - ${height})`,
            } }, topChildren),
        React.createElement(VerticalBar, { "data-testid": "vertical-bar", onPointerDown: onPointerDown, className: clsx('resizable-bar', {
                'resizing-panels': isResizing,
                hidden: disabled,
            }) },
            React.createElement(VerticalBarTop, null),
            React.createElement(VerticalBarMiddle, null,
                React.createElement(DragIndicator, { className: "resizable-handle" },
                    React.createElement(DragHandle, null))),
            React.createElement(VerticalBarBottom, null)),
        React.createElement(BottomPanel, { id: "vertical-resize-bottom-panel", style: { ...isResizingStyle, height } }, bottomChildren)));
});

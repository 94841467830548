import accounting, {} from 'accounting';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { DefaultCurrencyDigitPrecision } from 'constants/currency';
import { RoundingStyle } from 'constants/numeric';
import { digitsAndLetters, digitsOnly } from 'helpers/fieldNormalizers';
import { isEqual } from 'helpers/utility';
export const parseCurrency = (amount, options = {}) => {
    const { format = '', precision = DefaultCurrencyDigitPrecision, decimal = '.', symbol = '$' } = options;
    return accounting.formatMoney(amount, {
        format,
        precision,
        decimal,
        symbol,
    });
};
export const setDecimalsOnNumber = ({ fixedDecimalScale = false, maxDecimalScale, minDecimalScale = 2, number, separator = '.', }) => {
    if (number === undefined) {
        return null;
    }
    const strNumber = number.toString();
    const [integerPart, decimalPart] = strNumber.split(separator);
    let updatedDecimalPart = decimalPart || '';
    if (maxDecimalScale) {
        updatedDecimalPart = updatedDecimalPart.slice(0, maxDecimalScale).padEnd(maxDecimalScale, '0');
    }
    if (fixedDecimalScale) {
        return `${integerPart}${separator}${updatedDecimalPart}`;
    }
    updatedDecimalPart = updatedDecimalPart.replace(/0+$/, '');
    const finalDecimal = updatedDecimalPart.padEnd(minDecimalScale, '0');
    if (finalDecimal) {
        return `${integerPart}${separator}${finalDecimal}`;
    }
    return integerPart;
};
export function formatWithPattern(str, format) {
    if (!format) {
        return str;
    }
    let formattedString = '';
    let j = 0;
    for (let i = 0; i < str.length; i += 1) {
        while (format[j] && format[j] !== '#') {
            formattedString += `${format[j]}`;
            j += 1;
        }
        if (!format[j]) {
            break;
        }
        formattedString += str[i];
        j += 1;
    }
    while (formattedString.substr(0, 1) === '-') {
        formattedString = formattedString.substr(1);
    }
    while (formattedString.substr(-1) === '-') {
        formattedString = formattedString.slice(0, -1);
    }
    return formattedString;
}
export const formatAsEIN = (num) => formatWithPattern(digitsOnly(String(num)), '##-#######');
export const formatAsGIIN = (num) => {
    if (!num) {
        return '';
    }
    return formatWithPattern(digitsAndLetters(String(num)), '######.#####.##.###');
};
export const formatAsInternationalPhoneNumber = (phoneNumber) => formatPhoneNumberIntl(phoneNumber.number);
export const formatAsSSN = (num) => formatWithPattern(digitsOnly(String(num)), '###-##-####');
export const roundValue = (value, decimalPlaces) => accounting.unformat(accounting.toFixed(value, decimalPlaces));
export const unformatCurrency = (value) => accounting.unformat(value);
export const isRoundingStyleCeil = (roundingStyle) => isEqual(roundingStyle, RoundingStyle.CEIL);
export const isRoundingStyleFloor = (roundingStyle) => isEqual(roundingStyle, RoundingStyle.FLOOR);
export const formatNumber = (num, options = {}) => {
    return accounting.formatNumber(num, options);
};

import PropTypes from 'prop-types';
import React from 'react';

/**
 * A span where the words break on every character when there's an overflow
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
const WordBreakAll = ({ children }) => <span className="word-break--break-all">{children}</span>;

WordBreakAll.propTypes = {
  children: PropTypes.node,
};

WordBreakAll.defaultProps = {
  children: undefined,
};

export default WordBreakAll;

import { z } from 'zod';
export const routeFieldDefinitionModel = z.object({
    required: z.boolean(),
    validationPattern: z.string().nullish(),
});
export const routeRulesBankFields = z.enum(['fcraRegistrationNumber', 'nationality']);
export const routeRulesBeneficiaryFields = z.enum(['businessDescription']);
export const routeRulesPaymentFields = z.enum(['todo']);
export const routeRulesModel = z.object({
    beneficiary: z.record(routeRulesBeneficiaryFields, routeFieldDefinitionModel),
    bank: z.record(routeRulesBankFields, routeFieldDefinitionModel),
    payment: z.record(routeRulesPaymentFields, routeFieldDefinitionModel),
});

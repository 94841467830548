const { REACT_APP_STATIC_BASE_PATH } = process.env;

export const EXTERNAL_IMAGES_PATH_PREFIX = `${REACT_APP_STATIC_BASE_PATH}/images/external-onboarding`;

export const EXTERNAL_ONBOARD_CUSTOMER = `${EXTERNAL_IMAGES_PATH_PREFIX}/onboard--customer.png`;

export const EXTERNAL_ONBOARD_VENDOR = `${EXTERNAL_IMAGES_PATH_PREFIX}/onboard--vendor.png`;

export const EXTERNAL_ERROR_NOT_ALLOWED = `${EXTERNAL_IMAGES_PATH_PREFIX}/external-error--not-allowed.svg`;

export const EXTERNAL_ERROR_NOT_FOUND = `${EXTERNAL_IMAGES_PATH_PREFIX}/external-error--404.svg`;

export const EXTERNAL_ONBOARD_PENDING = `${EXTERNAL_IMAGES_PATH_PREFIX}/update-payment--pending.svg`;

export const EXTERNAL_ONBOARD_CONFIRMATION = `${EXTERNAL_IMAGES_PATH_PREFIX}/update-payment--confirmation.svg`;

export const EXTERNAL_ACCEPT_PARTNERSHIP_INITIAL = `${EXTERNAL_IMAGES_PATH_PREFIX}/accept-partnership--initial.svg`;

export const EXTERNAL_ACCEPT_PARTNERSHIP_INCOMPLETE = `${EXTERNAL_IMAGES_PATH_PREFIX}/accept-partnership--incomplete.svg`;

export const EXTERNAL_ACCEPT_PARTNERSHIP_SUCCESS = `${EXTERNAL_IMAGES_PATH_PREFIX}/accept-partnership--success.svg`;

export var BankAccountViewType;
(function (BankAccountViewType) {
    BankAccountViewType["ADDRESS"] = "ADDRESS";
    BankAccountViewType["FULL"] = "FULL";
    BankAccountViewType["NEW_PARTNER"] = "NEW_PARTNER";
    BankAccountViewType["PARTNER_ACH"] = "PARTNER_ACH";
    BankAccountViewType["PARTNER_ADDRESS"] = "PARTNER_ADDRESS";
    BankAccountViewType["SIMPLE"] = "SIMPLE";
    BankAccountViewType["SIMPLE_VERIFY"] = "SIMPLE_VERIFY";
    BankAccountViewType["THREAD"] = "THREAD";
    BankAccountViewType["THREAD_PARTNER_ACH"] = "THREAD_PARTNER_ACH";
    BankAccountViewType["THREAD_PARTNER_ADDRESS"] = "THREAD_PARTNER_ADDRESS";
})(BankAccountViewType || (BankAccountViewType = {}));
export var GenericDisplayStatus;
(function (GenericDisplayStatus) {
    GenericDisplayStatus["ACTION_MISSED"] = "ACTION_MISSED";
    GenericDisplayStatus["ACTION_NEEDED"] = "ACTION_NEEDED";
    GenericDisplayStatus["ARCHIVED"] = "ARCHIVED";
    GenericDisplayStatus["AVAILABLE"] = "AVAILABLE";
    GenericDisplayStatus["CAUTION"] = "CAUTION";
    GenericDisplayStatus["COMPLETE"] = "COMPLETE";
    GenericDisplayStatus["COMPLIANCE_HOLD"] = "COMPLIANCE_HOLD";
    GenericDisplayStatus["DANGER"] = "DANGER";
    GenericDisplayStatus["DEFAULT"] = "DEFAULT";
    GenericDisplayStatus["NEUTRAL"] = "NEUTRAL";
    GenericDisplayStatus["NOT_STARTED"] = "NOT_STARTED";
    GenericDisplayStatus["POST_DANGER"] = "POST_DANGER";
    GenericDisplayStatus["POST_STARTED"] = "POST_STARTED";
    GenericDisplayStatus["READY"] = "READY";
    GenericDisplayStatus["STARTED"] = "STARTED";
    GenericDisplayStatus["SUCCESS"] = "SUCCESS";
    GenericDisplayStatus["TEMPORAL"] = "TEMPORAL";
    GenericDisplayStatus["QUEUED"] = "QUEUED";
    GenericDisplayStatus["WARN"] = "WARN";
})(GenericDisplayStatus || (GenericDisplayStatus = {}));
export var PartnershipListActionType;
(function (PartnershipListActionType) {
    PartnershipListActionType["ADD"] = "Add";
    PartnershipListActionType["INVITE"] = "Invite";
})(PartnershipListActionType || (PartnershipListActionType = {}));
export var CompanyAddressSideSheetAction;
(function (CompanyAddressSideSheetAction) {
    CompanyAddressSideSheetAction["ADD"] = "Add";
    CompanyAddressSideSheetAction["UPDATE"] = "Update";
})(CompanyAddressSideSheetAction || (CompanyAddressSideSheetAction = {}));
export var RoleTypes;
(function (RoleTypes) {
    RoleTypes["ADMINISTRATOR"] = "administrator";
    RoleTypes["APPROVER"] = "approver";
    RoleTypes["COLLABORATOR"] = "collaborator";
    RoleTypes["CREATOR"] = "creator";
    RoleTypes["DEVELOPER"] = "developer";
    RoleTypes["IT_ADMIN"] = "it_admin";
    RoleTypes["VENDOR_AND_CUSTOMER_MANAGER"] = "vendor_and_customer_manager";
})(RoleTypes || (RoleTypes = {}));
export var Position;
(function (Position) {
    Position["BOTTOM"] = "bottom";
    Position["BOTTOM_LEFT"] = "bottom-left";
    Position["BOTTOM_RIGHT"] = "bottom-right";
    Position["LEFT"] = "left";
    Position["RIGHT"] = "right";
    Position["TOP"] = "top";
    Position["TOP_LEFT"] = "top-left";
    Position["TOP_RIGHT"] = "top-right";
})(Position || (Position = {}));

import { FETCH_UNMATCHED_LEDGER_CLEARING_ACCOUNTS_SUCCESS } from 'types/clearingAccounts';

const getAllIds = (ledgerClearingAccounts) => {
  if (!ledgerClearingAccounts) {
    return [];
  }

  return Object.keys(ledgerClearingAccounts);
};

const allReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_UNMATCHED_LEDGER_CLEARING_ACCOUNTS_SUCCESS:
      return getAllIds(action.payload.fundingAccount);

    default:
      return state;
  }
};

export default allReducer;

import { createLastFetchedReducer } from 'store/redux';

import {
  FETCH_AFFILIATE_CONNECTIONS_FAILURE,
  FETCH_AFFILIATE_CONNECTIONS_REQUEST,
  FETCH_AFFILIATE_CONNECTIONS_SUCCESS,
} from 'types/affiliates';

export const requestCases = [FETCH_AFFILIATE_CONNECTIONS_FAILURE, FETCH_AFFILIATE_CONNECTIONS_REQUEST];
export const successCases = [FETCH_AFFILIATE_CONNECTIONS_SUCCESS];

const lastFetchedReducer = createLastFetchedReducer(successCases, requestCases);

export default lastFetchedReducer;

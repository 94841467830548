import PropTypes from 'prop-types';
import React from 'react';

import { BaseHint } from 'components/hintTypes';
import TermsScrollBox from 'components/termsScrollBox';

import { Intent } from 'constants/ui';
import { UserTypes } from 'constants/user';

import { electronicDeliveryConsentTerms } from 'modules/external/ExternalCollectTaxInfo/constants/text';
import {
  getDeliveryConsentText,
  getDeliveryConsentTitle,
  getTinTypeForDisplay,
} from 'modules/external/ExternalCollectTaxInfo/helpers/text';

import ElectronicDeliveryConsentForm from './ElectronicDeliveryConsentForm';

/**
 * Displays a success hint which contains a form to consent to 1099 eDelivery and collects the re-entry of the user's
 * TIN. If the user elects eDelivery, show the TOS for eDelivery.
 * @param {boolean} electronicDeliveryConsent - Whether or not the user has elected for eDelivery
 * @param {object} filingDetails - Information entered on the form underneath the modal.
 * @param {object} partnerCompany
 * @param {boolean} showGenericTIN
 * @returns {StatelessComponent}
 * @constructor
 */
const ElectronicDeliveryConsentHint = ({
  electronicDeliveryConsent,
  filingDetails,
  partnerCompany,
  showGenericTIN,
}) => {
  const { companyName, companyType, tinType } = filingDetails;
  const { name: partnerCompanyName } = partnerCompany;
  // If the vendor is consenting again for another partnership, we don't know if they originally entered an EIN or SSN.
  // As a result, we should generically show TIN for confirmation, both in the text and in the input placeholder.
  const tin = showGenericTIN ? 'tin' : tinType;
  const displayTin = getTinTypeForDisplay(tin);
  const title = getDeliveryConsentTitle(displayTin);
  const text = getDeliveryConsentText(tin);

  return (
    <BaseHint
      className="margin-top--m"
      content={
        <div className="margin-bottom--m">
          <ElectronicDeliveryConsentForm
            companyName={companyName}
            companyType={companyType}
            partnerCompanyName={partnerCompanyName}
            showGenericTIN={showGenericTIN}
            tinType={tinType}
          />

          {electronicDeliveryConsent && (
            <TermsScrollBox
              paragraphs={electronicDeliveryConsentTerms}
              title="Terms for consenting to receive tax forms electronically."
            />
          )}
        </div>
      }
      contentProps={{
        className: 'form w-full',
        marginBottom: true,
      }}
      intent={Intent.SUCCESS}
      multiline
      text={text}
      textProps={{
        marginBottom: true,
      }}
      title={title}
    />
  );
};

ElectronicDeliveryConsentHint.propTypes = {
  electronicDeliveryConsent: PropTypes.bool,
  filingDetails: PropTypes.shape({
    companyName: PropTypes.string,
    companyType: PropTypes.oneOf(Object.values(UserTypes)).isRequired,
    tinType: PropTypes.string.isRequired,
  }).isRequired,
  partnerCompany: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  showGenericTIN: PropTypes.bool,
};

ElectronicDeliveryConsentHint.defaultProps = {
  electronicDeliveryConsent: undefined,
  showGenericTIN: undefined,
};

export default ElectronicDeliveryConsentHint;

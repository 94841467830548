import { combineReducers } from 'redux';

import errorReducer from './errorReducer';
import lastSubmittedReducer from './lastSubmittedReducer';
import submitReducer from './submitReducer';

const fundingAccountAddressManualReducer = combineReducers({
  errors: errorReducer,
  isSubmitting: submitReducer,
  lastSubmitted: lastSubmittedReducer,
});

export default fundingAccountAddressManualReducer;

import { combineReducers } from 'redux';

import integrationConfigsAllIdsReducer from 'reducers/integrationConfigsReducer/allIdsReducer';
import integrationConfigsByIdReducer from 'reducers/integrationConfigsReducer/byIdReducer';
import integrationConfigsErrorReducer from 'reducers/integrationConfigsReducer/errorReducer';
import integrationConfigsFetchReducer from 'reducers/integrationConfigsReducer/fetchReducer';
import integrationConfigLedgerPartnershipsReducer from 'reducers/integrationLedgerPartnershipsReducer';
import integrationConfigLedgerUnmatchedLedgerFundingAccountsReducer from 'reducers/integrationLedgerUnmatchedLedgerFundingAccountsReducer';
import integrationConfigLedgerUnmatchedPartnershipContactsReducer from 'reducers/integrationLedgerUnmatchedPartnershipContactsReducer';
import integrationPlatformPartnershipsReducer from 'reducers/integrationPlatformPartnershipsReducer';
import integrationUnmatchedPlatformFundingAccountsReducer from 'reducers/integrationUnmatchedPlatformFundingAccountsReducer';

import integrationConfigLedgerOptionsReducer from './integrationConfigLedgerOptionsReducer';

const integrationConfigsReducer = combineReducers({
  allIds: integrationConfigsAllIdsReducer,
  byId: integrationConfigsByIdReducer,
  errors: integrationConfigsErrorReducer,
  isFetching: integrationConfigsFetchReducer,
  ledgerOptions: integrationConfigLedgerOptionsReducer,
  ledgerPartnerships: integrationConfigLedgerPartnershipsReducer,
  ledgerUnmatchedLedgerFundingAccounts: integrationConfigLedgerUnmatchedLedgerFundingAccountsReducer,
  ledgerUnmatchedPartnershipContacts: integrationConfigLedgerUnmatchedPartnershipContactsReducer,
  ledgerUnmatchedPlatformFundingAccounts: integrationUnmatchedPlatformFundingAccountsReducer,
  platformPartnerships: integrationPlatformPartnershipsReducer,
});

export default integrationConfigsReducer;

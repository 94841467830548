import React from 'react';
import { useSelector } from 'react-redux';
import { IconNames } from 'components/icon';
import { Environment } from 'constants/env';
import { formNamesSignup } from 'constants/forms';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { ENVIRONMENT_DOMAINS } from 'constants/routes';
import { LOGO_CIRCLE } from 'global/images/platform';
import { getSelectNamespace } from 'selectors/signupSelectors';
import 'modules/signup-v3/styles/variables.scss';
import { Browser, ContentIcon, NavigationIcon, TabRow, WindowControls, WindowControlButton, OpenTab, TabLogo, ControlsRow, PageControls, AddressBar, Namespace, GrayBar, ContentRow, BarContainer, MockContentContainer, MockContentHeader, MockContentBubble, ContentIconContainer, } from './DynamicTextBrowser.styles';
const DynamicTextBrowser = () => {
    const controlIcons = [IconNames.ARROW_LEFT, IconNames.ARROW_RIGHT, IconNames.REFRESH];
    const { isNamespaceValid } = useSelector(getSelectNamespace);
    const formSelector = useSelector((state) => state.form[formNamesSignup.SELECT_NAMESPACE]);
    const namespace = formSelector?.values?.company?.namespace;
    return (React.createElement(Browser, null,
        React.createElement(TabRow, null,
            React.createElement(WindowControls, null,
                React.createElement(WindowControlButton, { color: "#ED6A5E" }),
                React.createElement(WindowControlButton, { color: "#F5BD4F" }),
                React.createElement(WindowControlButton, { color: "#61C455" })),
            React.createElement(OpenTab, null,
                React.createElement(TabLogo, { alt: PLATFORM_DISPLAY_SHORT_NAME, className: "tab-icon", src: LOGO_CIRCLE.BLUE }),
                ` Routable`)),
        React.createElement(ControlsRow, null,
            React.createElement(PageControls, null, controlIcons.map((icon, idx) => (React.createElement(NavigationIcon, { icon: icon, key: `nav-icon-${idx + 1}` })))),
            React.createElement(AddressBar, { "data-testid": "address-bar" },
                namespace?.length < 30 ? 'https://' : '',
                React.createElement(Namespace, { isValid: isNamespaceValid }, namespace),
                namespace?.length < 28 ? `.${ENVIRONMENT_DOMAINS[Environment.PRODUCTION].brandedWorkspaces}` : '')),
        React.createElement(ContentRow, null,
            React.createElement(MockContentContainer, null,
                React.createElement(MockContentHeader, null,
                    React.createElement(GrayBar, { type: "small" })),
                React.createElement(MockContentBubble, null,
                    React.createElement(ContentIconContainer, null,
                        React.createElement(ContentIcon, { icon: IconNames.TICK_CIRCLE, size: 18 })),
                    React.createElement(BarContainer, null,
                        React.createElement(GrayBar, { type: "small" }),
                        React.createElement(GrayBar, { type: "large" })))))));
};
export default DynamicTextBrowser;

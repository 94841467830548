import React from 'react';
import { AddressFormat } from 'enums/addresses';
import { getAddressAsString } from 'helpers/addressHelpers';
import TableCellText from '../TableCellText';
const CompanyWithAddressCell = ({ address, partnershipName }) => {
    const partnerAddress = getAddressAsString(address, { format: AddressFormat.CITY_AND_COUNTRY });
    return (React.createElement("div", { className: "display-flex flex-direction--column" },
        React.createElement(TableCellText, { className: "font-medium", dataFullStory: true }, partnershipName),
        Boolean(partnerAddress) && (React.createElement(TableCellText, { className: "font-color--grey--x-dark", dataFullStory: true }, partnerAddress))));
};
export default CompanyWithAddressCell;

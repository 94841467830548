import classNames from 'classnames';
import React from 'react';
import { Hint } from 'components/hint';
import { Text, WhiteSpace } from 'components/text';
import { Intent } from 'constants/ui';
const InternationalPaymentApprovalsNotSupportedHint = ({ className, }) => (React.createElement(Hint, { className: classNames({ [className]: !!className }), intent: Intent.WARNING, multiline: true },
    React.createElement(Text.Bold, null, "You can't approve international payments in Routable (yet)"),
    React.createElement(Text.Regular, null,
        "Conversion rates fluctuate, so international payment amounts change too.",
        React.createElement(WhiteSpace, null),
        "That means Routable's approval rules don't work like they do domestically\u2014but we're working on it!")));
export default InternationalPaymentApprovalsNotSupportedHint;

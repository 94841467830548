import { getAllIdsReducerResult } from 'store/redux';

import { FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_SUCCESS, SEARCH_FUNDING_ACCOUNTS_SUCCESS } from 'types/integrations';

const allLedgerUnmatchedPlatformFundingAccountsReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_SUCCESS:
    case SEARCH_FUNDING_ACCOUNTS_SUCCESS:
      return getAllIdsReducerResult(state, action.payload.fundingAccount);

    default:
      return state;
  }
};

export default allLedgerUnmatchedPlatformFundingAccountsReducer;

import {} from '@routable/shared';
import {} from '../models';
export const partnershipsBaseQueryKey = 'Partnerships';
export const generatePartnershipsQueryKeys = {
    list: (partnershipType, ...args) => [
        partnershipsBaseQueryKey,
        partnershipType,
        ...args,
    ],
    read: (partnershipId) => [partnershipsBaseQueryKey, partnershipId],
};

import { Spinner } from '@routable/gross-ds';
import React, { useMemo } from 'react';
import {} from 'types/quickswitch';
import { removeCurrentMembershipFromListAndSortByName } from './helpers';
import QuickswitchMembershipList from './QuickswitchMembershipList';
import QuickswitchModalEmptySearchResults, {} from './QuickswitchModalEmptySearchResults';
const QuickswitchModalContent = ({ loadingMembershipsList, memberships, executeSwitchMembership, onUndoSearchClick, }) => {
    const membershipData = useMemo(() => removeCurrentMembershipFromListAndSortByName(memberships), [memberships]);
    if (loadingMembershipsList) {
        return (React.createElement("div", { className: "my-6 flex items-center justify-center" },
            React.createElement(Spinner, { size: "xs" })));
    }
    if (!membershipData?.length) {
        return React.createElement(QuickswitchModalEmptySearchResults, { onUndoSearchClick: onUndoSearchClick });
    }
    return React.createElement(QuickswitchMembershipList, { memberships: membershipData, onSwitchMembership: executeSwitchMembership });
};
export default QuickswitchModalContent;

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

import CompanyHasNoAddedContactsHint from './CompanyHasNoAddedContactsHint';

const mapStateToProps = createStructuredSelector({
  partnership: partnershipFromQuerySelector,
});

export default connect(mapStateToProps)(CompanyHasNoAddedContactsHint);

import { combineReducers } from 'redux';

import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';
import { allKeys } from 'helpers/utility';

import * as types from 'types/funding';

const fundingInfoBalancesByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
    case types.FETCH_FUNDING_ACCOUNTS_SUCCESS:
    case types.FETCH_SINGLE_FUNDING_ACCOUNT_SUCCESS:
      return deepMergeWithArrayReplacement(state, getObjectsByIdWithRelationships(action.payload.fundingInfoBalance));

    default:
      return state;
  }
};

const fundingInfoBalancesAllIdsReducer = (state = [], action) => {
  switch (action.type) {
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
    case types.FETCH_FUNDING_ACCOUNTS_SUCCESS:
      return Array.from(new Set([...state, ...allKeys(action.payload.fundingInfoBalance)]));

    default:
      return state;
  }
};

const fundingInfoBalancesIsFetchingReducer = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_SINGLE_FUNDING_ACCOUNT_REQUEST:
    case types.FETCH_FUNDING_ACCOUNTS_REQUEST:
      return true;

    case types.FETCH_SINGLE_FUNDING_ACCOUNT_FAILURE:
    case types.FETCH_SINGLE_FUNDING_ACCOUNT_SUCCESS:
    case types.FETCH_FUNDING_ACCOUNTS_FAILURE:
    case types.FETCH_FUNDING_ACCOUNTS_SUCCESS:
      return false;

    default:
      return state;
  }
};

const fundingInfoBalanceReducer = combineReducers({
  allIds: fundingInfoBalancesAllIdsReducer,
  byId: fundingInfoBalancesByIdReducer,
  isFetching: fundingInfoBalancesIsFetchingReducer,
});

export { fundingInfoBalancesAllIdsReducer, fundingInfoBalancesByIdReducer, fundingInfoBalancesIsFetchingReducer };
export default fundingInfoBalanceReducer;

import React from 'react';

import { formNamesSignup } from 'constants/forms';
import { SIGNUP_VERIFY_EMAIL } from 'constants/routes';
import { signupVerifyEmailCodeToastId } from 'constants/toasts';

import { handleAddAuthTokenAndCurrentIds } from 'helpers/auth';
import GenericContainer from 'helpers/containers';
import { areDevtoolsEnabled } from 'helpers/env';
import { hasErrors, validateForm } from 'helpers/formValidation';
import { getQueryParam } from 'helpers/queryParams';

import { toaster } from 'services/toaster';

import { CreateAccountCompany, CreateAccountUser } from './components';

/**
 * Create account container for both company and user details
 */
class CreateAccount extends GenericContainer {
  state = {
    errors: {},
    form: {
      role: '',
      company: {
        accountingSoftware: '',
        companySize: '',
        name: '',
      },
      user: {
        email: '',
        firstName: '',
        lastName: '',
        password: '',
      },
      meta: {
        tosAgree: false,
        signupCode: getQueryParam('invitation_code') || '',
      },
    },
    step: formNamesSignup.CREATE_ACCOUNT_COMPANY,
  };

  componentDidMount() {
    this.pushDefaultHistoryState();
    window.addEventListener('popstate', this.onPopState);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onPopState);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.genericErrorFromProps(nextProps, ['errors'], ['company', 'meta', 'user']);
  }

  onPopState = () => {
    // "redirect" user to the "Company info" part of the form if they
    // click the back button while on the "User info" part
    if (this.state.step === formNamesSignup.CREATE_ACCOUNT_USER) {
      this.setState({ step: formNamesSignup.CREATE_ACCOUNT_COMPANY });
      this.pushDefaultHistoryState();
    }
  };

  pushDefaultHistoryState = () => {
    window.history.pushState(null, document.title, window.location.href);
  };

  handleFormSubmit = () => {
    const { onSignupCreateAccount } = this.props;
    const { form } = this.state;

    // Validate user info and submit company + user info
    this.genericFormSubmit(
      form,
      onSignupCreateAccount,
      formNamesSignup.CREATE_ACCOUNT_USER,
      [this.signupCreateAccountSuccess],
      true,
    );
  };

  handleIncrementStep = () => {
    const errAccessor = this.state[this.stateErrorKey];
    // Validate company form and continue to user form
    const formId = formNamesSignup.CREATE_ACCOUNT_COMPANY;
    const formErrors = validateForm(formId, errAccessor);

    if (!hasErrors(formErrors, formId)) {
      // Clear errors and continue
      this.setState({
        errors: {},
        step: formNamesSignup.CREATE_ACCOUNT_USER,
      });
      return true;
    }

    // Add errors
    this.setState({ errors: formErrors });
    return false;
  };

  signupCreateAccountSuccess = async (parsedResponse) => {
    const { history } = this.props;

    // Add token and push user to the verify account step
    await handleAddAuthTokenAndCurrentIds(parsedResponse.meta);

    history.push(SIGNUP_VERIFY_EMAIL);

    // Add code as a toast in testing environments (including prod builds when devtools are enabled)
    if (areDevtoolsEnabled()) {
      const { verificationCode } = parsedResponse.meta;

      if (verificationCode) {
        toaster.notify(`Testing verification code: ${verificationCode}`, {
          duration: 30,
          id: signupVerifyEmailCodeToastId,
        });
      }
    }

    return parsedResponse;
  };

  renderCompanyForm = () => {
    const { errors, form, step } = this.state;

    if (step !== formNamesSignup.CREATE_ACCOUNT_COMPANY) {
      return null;
    }

    return (
      <CreateAccountCompany
        errors={errors}
        formState={form}
        onIncrementStep={this.handleIncrementStep}
        onInputChange={this.handleInputChange}
        onSelectChange={this.handleSelectChange}
      />
    );
  };

  renderUserForm = () => {
    const { isSubmitting } = this.props;
    const { errors, form, step } = this.state;

    if (step !== formNamesSignup.CREATE_ACCOUNT_USER) {
      return null;
    }

    return (
      <CreateAccountUser
        errors={errors}
        formState={form}
        isSubmitting={isSubmitting}
        onCheckboxInputChange={this.genericCheckboxInputChange}
        onFormSubmit={this.handleFormSubmit}
        onInputChange={this.handleInputChange}
        onSelectChange={this.handleSelectChange}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderCompanyForm()}
        {this.renderUserForm()}
      </>
    );
  }
}

export default CreateAccount;

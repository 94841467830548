import { PaymentSources } from 'enums/billing';
import {} from 'enums/items';
import { isFundingAccountEligibleForRTP } from 'helpers/funding';
import { isKindReceivable } from 'helpers/items';
import { isPaymentDeliveryOptionRTP } from 'helpers/paymentMethods';
export const shouldDisplayRTPEligibilityWarning = ({ partnershipFundingAccounts, paymentDeliveryOption, }) => {
    const isPartnershipEligibleForRTP = partnershipFundingAccounts.some(isFundingAccountEligibleForRTP);
    const isRTPSelected = isPaymentDeliveryOptionRTP(paymentDeliveryOption);
    return !isPartnershipEligibleForRTP && isRTPSelected;
};
export const shouldDisplayBalanceTooLowWarning = ({ paymentTotalAmount, balanceAmount, paymentSource, }) => {
    const isBalanceFundingAccountSelected = paymentSource === PaymentSources.BALANCE;
    const isFormTotalAboveBalanceAmount = paymentTotalAmount > balanceAmount;
    return isBalanceFundingAccountSelected && isFormTotalAboveBalanceAmount;
};
export const shouldDisplayAmountAboveLimitWarning = ({ paymentMaxIndividualAmount, currentBillingData, }) => {
    const isMaxAmountAboveTransactionMax = paymentMaxIndividualAmount > currentBillingData?.paymentDeliveryOptionMax;
    return isMaxAmountAboveTransactionMax;
};
export const shouldDisplayMaxTransactionFeeWarning = ({ itemKind, showAmountAboveLimitWarning, }) => isKindReceivable(itemKind) && !showAmountAboveLimitWarning;
